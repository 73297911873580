<section class="modal__input--text dm-type-ahead">
    <ng-template #rt let-r="result" let-t="term">
        <a class="typeahead-list-item">
            <span class="dm-display-block">
                <span class="typeahead-list-item--user-name">
                    <ngb-highlight [result]="r.JobSkillValue" [term]="t"></ngb-highlight>
                </span>
                    <!-- <span class="typeahead-list-item--user-type dm-display-block">
                        <span class="domain-text">Domain: </span>
                        <span *ngFor="let domain of r.Domains; let i = index">
                            <span>{{domain.DomainName}}</span>
                            <span *ngIf="(i+1) !== r.Domains.length">, </span>
                        </span>
                    </span> -->
            </span>
        </a>
    </ng-template>

    <label class="font-caption-alt" [for]="typeAheadId" *ngIf="typeAheadLabelText">{{typeAheadLabelText}}</label>
    <section class="input__label  PMTypehead pos-rel p-b-0 no-mar-tablet">
        <input type="text" #typeAheadSearchSkills [required]="isRequired?isRequired:false"
            [attr.aria-required]="isRequired?isRequired:false" [id]="typeAheadId" autocomplete="off"
            [(ngModel)]="modelValue" [placeholder]="typeAheadPlaceholder?typeAheadPlaceholder:'Start typing skill name'"
            (ngModelChange)="validateInput()" class="form-control font-caption-alt" (selectItem)="onSkillSelect($event)"
            minlength="3" [ngbTypeahead]="search" [disabled]="isDisabled" spellcheck="false"
            [attr.aria-label]="typeAheadLabelText" [name]="typeAheadId" role="combobox" [resultTemplate]="rt"
            [inputFormatter]="formatter"  (blur)="searching = false" />
        <!--ng-class="{'inp-error-border': !!errorMessage}"-->

        <i [hidden]="!loadingUsers" title="Searching.." class="glyphicon glyphicon-refresh glyphicon-spin ng-hide"></i>
        <button title="Search" [attr.aria-label]="btnSearchAriaLabelText" class="input__icon input__icon__search-btn"
            type="button" *ngIf="!searching && !modelValue">
            <i class="icon icon__ele dm-icon-search"></i>
        </button>
        <button *ngIf="searching" class="input__icon search__icon input__icon__spin-btn">
            <i title="Searching..." class="glyphicon glyphicon-refresh glyphicon-spin"></i>
        </button>
        <button title="Clear" class="input__icon search__icon input__icon__cancel-btn"
            [attr.aria-label]="btnCancelAriaLabelText" (click)="clearText()" *ngIf="modelValue && !searching" type="button"
            [disabled]="isDisabled">
            <i class="icon icon__ele icon-cancel"></i>
        </button>       
    </section>
    <div *ngIf="errorMessage && !searching">
        <div role="alert" aria-live="assertive" aria-atomic="true" class="font-caption-alt inp-validation p-t-4">
            <i class="icon icon-RemoveLegacy"></i> {{errorMessage}}
        </div>
    </div>
    <div class="multi-select-array font-caption-alt" *ngIf="isMultiselect">
        <button class="multi-select-array--group solid-border set-focus" type="button"
            (click)="removeFromSelectedSkills(skill,i)" *ngFor="let skill of selectedSkills; let index = index"
            [attr.aria-label]="'select skill '+ skill.JobSkillValue + ' delete'" id="{{'selectedSkills-'+index}}">
            <span class="multi-select-array--element" [title]="skill.JobSkillValue">{{skill.JobSkillValue}}</span>
            <i class="icon icon-close"></i>
        </button>
    </div>
</section>