import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";

import { CancelModalModule } from "../staffing-command-bar/staffing-command-bar-modals/cancel-modal/cancel-modal.module";
import { CommonComponentsModule } from "../entry/common-components.module";
import { CopyResourceModalModule } from "../staffing-command-bar/staffing-command-bar-modals/copyresource-modal/copyresource-modal.module";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { RmModalModule } from "../staffing-command-bar/staffing-command-bar-modals/rm-modal/rm-modal.module";
import { RoleRemovalService } from "../staffing-command-bar/staffing-command-bar-modals/role-removal-service/role-removal.service";
import { RuleEngineService } from "../staffing-command-bar/staffing-command-bar-common/services/rule-engine.service";
import { SplitPlannedModalModule } from "../staffing-command-bar/staffing-command-bar-modals/split-planned-modal/split-planned-modal.module";
import { StaffingActionService } from "./staffing-command-bar-common/services/staffing-action.service";
import { StaffingCommandBarComponent } from "./staffing-command-bar.component";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { SuspendModalModule } from "./staffing-command-bar-modals/suspend-modal/suspend-modal.module";
import { TruncateModalModule } from "../staffing-command-bar/staffing-command-bar-modals/truncate-modal/truncate-modal.module";
import { UnsuspendModalModule } from "../staffing-command-bar/staffing-command-bar-modals/unsuspend-modal/unsuspend-modal.module";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AddRoleModalModule } from "../new-internal-engagement/add-roles/modals/add-role-modal.module";
import { ResourceStaffingService } from "../../common/services/resource-staffing.service";

@NgModule({
    declarations: [
        StaffingCommandBarComponent
    ],
    imports: [
        AddRoleModalModule,
        CancelModalModule,
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        NgMultiSelectDropDownModule,
        RmModalModule,
        SplitPlannedModalModule,
        SuspendModalModule,
        TruncateModalModule,
        UnsuspendModalModule,
        CopyResourceModalModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        ResourceStaffingService,
        RoleRemovalService,
        RuleEngineService,
        StaffingActionService,
        SharedFunctionsService
    ],
    // In FXP Shell all the angular components are going to be loaded dynamically using Routing.
    // For that it is very imporant to make the components part of entryComponents section of angular module.
    entryComponents: [
        StaffingCommandBarComponent
    ],
    exports: [StaffingCommandBarComponent]
})
// Feature Module for bundling the dependencies. All the feature modules needs to be registered in FXP.
// Please refer main.ts file for the same.
export class StaffingCommandBarModule {

}



