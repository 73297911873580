import { Action } from "@ngrx/store";
import { IInternalEngagementFinancialsListV2 } from "../../common/services/contracts/portfolio.model";


// 1. action types
export enum InternalFinancialDetailsActionTypes {
    LOAD_INTERNAL_FINANCIAL_DETAILS = "[InternalFinancialDetails] Load",
    LOAD_MANUAL_INTERNAL_FINANCIAL_DETAILS_V2 = "[InternalFinancialDetails] Load Manual",
    LOAD_INTERNAL_FINANCIAL_DETAILS_SUCCESS = "[InternalFinancialDetails] Load Success",
    LOAD_INTERNAL_FINANCIAL_DETAILS_FAIL = "[InternalFinancialDetails] Load Fail",
    INVALIDATE_INTERNAL_FINANCIAL_DETAILS = "[InternalFinancialDetails] Invalidate"
}

// 2. action creators
export class LoadInternalFinancialDetails implements Action {
    public readonly type = InternalFinancialDetailsActionTypes.LOAD_INTERNAL_FINANCIAL_DETAILS;
    public constructor(public engagementId: string) { }
}

export class LoadManualInternalFinancialDetails implements Action {
    public readonly type = InternalFinancialDetailsActionTypes.LOAD_MANUAL_INTERNAL_FINANCIAL_DETAILS_V2;
    public constructor(public engagementId: string) { }
}

export class LoadInternalFinancialDetailsSuccess implements Action {
    public readonly type = InternalFinancialDetailsActionTypes.LOAD_INTERNAL_FINANCIAL_DETAILS_SUCCESS;
    public constructor(public engagementId: string, public financialDetails: IInternalEngagementFinancialsListV2[]) { }
}

export class LoadInternalFinancialDetailsFail implements Action {
    public readonly type = InternalFinancialDetailsActionTypes.LOAD_INTERNAL_FINANCIAL_DETAILS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateInternalFinancialDetails implements Action {
    public readonly type = InternalFinancialDetailsActionTypes.INVALIDATE_INTERNAL_FINANCIAL_DETAILS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type InternalFinancialDetailsActions =
    | LoadInternalFinancialDetails
    | LoadManualInternalFinancialDetails
    | LoadInternalFinancialDetailsSuccess
    | LoadInternalFinancialDetailsFail
    | InvalidateInternalFinancialDetails;