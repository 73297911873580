<div class="modal-body">
    <p class="font-caption-alt">
        This action will change the EBS State of the entire engagement to “Released” and create a Current
        Financial Plan with a status of “Active” for all projects under this engagement.
        <br />
        <br /> This action cannot be undone.
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="fxp-btn fxp-btn--secondary" data-dismiss="modal" (click)="cancel()">
        Cancel
    </button>
    <button type="button" class="fxp-btn fxp-btn--primary" data-dismiss="modal" (click)="submit()">
        Submit
    </button>
</div>