<section>
    <div role="grid" class="dm-fcr-labor-view-details">
        <div role="rowgroup" class="dm-grid-view-table" *ngIf="fcrLaborViewDetails">
            <div role="row" class="dm-grid-view-table__row dm-fcr-labor-view-details-table__row">
                <div role="columnheader" class="dm-grid-view-table__headerCell">EBS Description / ID </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Role
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Type of
                    <br /> Contract
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Billing
                    Role ID </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Resource
                    Location</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Existing
                    CFP Hours</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">
                    <span>
                        Additional Hours
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'" [ngbTooltipContent]="' Additional hours that are requested.'"
                            [dmIcon]="'icon icon-info'" ariaLabelToolTip="' Additional hours that are requested.'">
                        </dm-tooltip>
                    </span>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">
                    <span>DB Cost & <br /> Hours
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'" [ngbTooltipContent]="' Existing Delivery Baseline (DB) Cost & Hours'"
                            [dmIcon]="'icon icon-info'"
                            ariaLabelToolTip="' Existing Delivery Baseline (DB) Cost & Hours'">
                        </dm-tooltip>
                    </span>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell display-block-imp">
                    <span>CFP Cost & <br /> Hours
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'"
                            [ngbTooltipContent]="'Revised Current Financial Plan (CFP) Cost & Hours'"
                            [dmIcon]="'icon icon-info'"
                            ariaLabelToolTip="'Revised Current Financial Plan (CFP) Cost & Hours'">
                        </dm-tooltip>
                    </span>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Blended
                    Cost Rate
                    ({{planCurrency}}/Hour)</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell display-block-imp">
                    <span>Additional Cost <br /> ({{planCurrency}})
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'"
                            [ngbTooltipContent]="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'"
                            [dmIcon]="'icon icon-info'"
                            ariaLabelToolTip="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'">
                        </dm-tooltip>
                    </span>
                </div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-fcr-labor-view-details-table__row"
                *ngFor="let fcrRole of fcrLaborViewDetails">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>{{fcrRole?.projectName}}-{{fcrRole?.serviceName}}</strong><br />
                    <span class="dm-p-b-5 display-inline-block">{{fcrRole?.taskId}}</span> <br />
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span *ngIf="fcrRole?.existingRoleDescription">
                        {{fcrRole?.existingRoleDescription | dmDisplayDashIfEmptyOrNull}} ->
                    </span>
                    {{fcrRole?.roleDescription | dmDisplayDashIfEmptyOrNull}}
                    <br />
                    <span *ngIf="fcrRole?.demandId">Demand ID:
                        {{fcrRole?.demandId | dmDisplayDashIfEmptyOrNull}}
                    </span>
                    <span *ngIf="!fcrRole?.existingRoleDescription && !fcrRole?.roleDescription && !fcrRole?.demandI" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span class="dm-badge" [ngClass]="fcrRole?.isFixedFeeProject ? 
                                    'contract-type-ff' : 'contract-type-tm'">
                        {{ fcrRole?.isFixedFeeProject ? "FF" : "T&amp;M"}}
                    </span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{fcrRole?.billingRoleId | dmDisplayDashIfEmptyOrNull}}
                    <span *ngIf="!fcrRole?.billingRoleId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{fcrRole?.resourceLocationKey | dmDisplayDashIfEmptyOrNull}}
                    <span *ngIf="!fcrRole?.resourceLocationKey" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{fcrRole?.quantity | number : '1.2-2' | dmDisplayDashIfEmptyOrNull}}
                    <span *ngIf="!fcrRole?.quantity" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{fcrRole?.requestedQuantity | number : '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                    <span *ngIf="!fcrRole?.requestedQuantity" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span *ngIf="fcrRole?.dbCost">{{fcrRole?.dbCost | number : '1.2-2'}}
                        {{planCurrency}} </span><br />
                    <span *ngIf="fcrRole?.dbHour">{{fcrRole?.dbHour | number : '1.2-2'}} Hours</span>
                </div>
                <!--cfpCost = (additional + existing hours) * costRate-->
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span *ngIf="fcrRole?.cfpCost != null">{{fcrRole?.cfpCost | number : '1.2-2'}}
                        {{planCurrency}} </span><br />
                    <span
                        *ngIf="(fcrRole?.quantity || fcrRole?.quantity === 0) && (fcrRole?.requestedQuantity || fcrRole?.requestedQuantity === 0)">
                        {{fcrRole?.quantity + fcrRole?.requestedQuantity | number : '1.2-2'}} Hours
                    </span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{fcrRole?.costRate | number : '1.2-2' | dmDisplayDashIfEmptyOrNull}}
                    <span *ngIf="!fcrRole?.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <!--Additional Cost = dbCost - cfpCost-->
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{fcrRole?.additionalCost | number : '1.2-2'}}
                </div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-fcr-labor-view-details-table__row">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>TOTALS</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>{{fcrLaborViewDetails | sum: 'quantity' |
                        number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>{{fcrLaborViewDetails | sum:
                        'requestedQuantity' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>{{fcrLaborViewDetails | sum:
                        'additionalCost' | number : '1.2-2'}}</strong>
                </div>
            </div>
        </div>
    </div>
</section>