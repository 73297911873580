<section>
  <section class="modal-header">
    <button type="button" class="close set-focus" (click)="closeStatusModal()" id="planForecastAuditHistoryModal"
      [title]="'Plan and Forecast Audit History Modal Close'">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
    <div class="display-grid">
      <h4 class="modal-title font-subtitle pull-left">
        Audit History - {{headerTitle}}
      </h4>
      <span class="font-caption-alt">{{wbsId}}</span>
    </div>
  </section>
  <section class="modal-body">
    <dm-loading loader *ngIf="isComponentLoading && !isServerError" [loadingText]="'Audit History'"
      [showLoading]="showLoading">
    </dm-loading>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
    <section class="plan-forecast-audit-history-section" *ngIf="!isComponentLoading && !isServerError">
      <div class="time-label-container">
        <!--TODO: Will be added later-->
        <!-- <dm-dropdown-menu [id]="'dm-pnf-audit-filter-ddl'" [(value)]="selectedFilter" [dropdownSize]="compact"
                        [options]="filterDataSource" [labels]="'displayName'"
                        (change)="onSelectedFilterChanged(selectedFilter)">
          </dm-dropdown-menu> -->
        <span class="display-inline-block">
          <span class="icon icon-clock"></span>
          <span class="dm-p-x-5">Display time (PST)</span>
        </span>
        <span class="legend">
          <span class="legend__indicator current-legend"></span>
          <span class="legend__caption">Current value at that time</span>
        </span>
        <span class="legend">
          <span class="legend__indicator previous-legend"></span>
          <span class="legend__caption">Previous value at that time</span>
        </span>
      </div>
      <dm-timeline [auditList]="auditHistoryData" [selectedFilter]="selectedFilter"></dm-timeline>
    </section>
  </section>
</section>