import { Injectable, Inject, forwardRef } from "@angular/core";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { UserInfoService } from "@fxp/fxpservices";
import { IUserPreference } from "./contracts/userpreference.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";



@Injectable()
export class UserPreferenceService extends DmServiceAbstract {

    private projectServiceBaseUriv1: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.UserPreferenceService );
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });

    }

    /**
     * Get the user preferences for logged in user
     */
    public getUserPreference(): Promise<IUserPreference> {
        const url: string = `${this.projectServiceBaseUriv1}/user/preferences`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetUserPreferences);
    }

    /**
     * Get release details based on release Id
     */
    public getReleaseDetails(releaseId: string): Promise<any> {
        const url: string = `${this.projectServiceBaseUriv1}/user/releasedetails/${releaseId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetReleaseDetails);
    }

    /**
     * Get the user preferences for logged in user
     */
    public saveUserPreference(userpreference: IUserPreference): Promise<IUserPreference> {
        const url: string = `${this.projectServiceBaseUriv1}/user/preferences`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.SaveUserPreferences, userpreference);
    }

    /* Gets the default preferences from Configuration */
    public getDefaultUserPeference(): IUserPreference {
        const bpId = this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId;
        const userName = this.fxpUserInfoService.getCurrentUser();
        const customerEngagementsPageSize = this.configManagerService.getValue<any>("userPreference").defaultCustomerEngagementsPageSize;
        const internalEngagementsPageSize = this.configManagerService.getValue<any>("userPreference").defaultInternalEngagementsPageSize;
        const userPreference: IUserPreference = {          
            viewedEntities: [],
            customerEngagements: { pageSize: customerEngagementsPageSize, isComponentExpanded: true, showEngagementsExpanded: true },
            internalEngagements: { pageSize: internalEngagementsPageSize, isComponentExpanded: true, showEngagementsExpanded: true },
            userId: bpId,
            id: userName + "-" + bpId,
            feedbackList: [],
            whatsNewDisplayStatusByRelease: {},
            v2ViewDisplayPreference: {}
        };
        return userPreference;
    }

    private initializeConfig(): void {
        this.projectServiceBaseUriv1 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v1.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }

}

