import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { ManageEbsEntityDetailsComponent } from "./manage-ebs-entity-details.component";
import { EbsV2RequestStatusChangeModalModule } from "../../modals/request-status-change/request-status-change.module";
import { EbsEditEngagementDetailsModalModule } from "../../modals/wbs-engagement-edit/edit-engagement-details.module";
import { EbsEditProjectDetailsModalModule } from "../../modals/wbs-project-edit/edit-project-details.module";
import { UIRouterModule } from "@uirouter/angular";

@NgModule({
    declarations: [
        ManageEbsEntityDetailsComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        EbsV2RequestStatusChangeModalModule,
        EbsEditEngagementDetailsModalModule,
        EbsEditProjectDetailsModalModule,        
        UIRouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        ManageEbsEntityDetailsComponent,
    ],
    exports: [
        ManageEbsEntityDetailsComponent,
    ]
})
export class ManageEbsEntityDetailsModule { }