import { Component, Inject, forwardRef, Input } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DeviceFactoryProvider, ErrorSeverityLevel } from "@fxp/fxpservices";
import { FinancialService } from "../../../common/services/financial.service";
import { IEngagement } from "../../../common/services/contracts/financial.service.contracts";
import { IEngagementSearchResult } from "../../../common/services/contracts/project.service.contracts";
import { IFinancialDetail, IFinancial, IEngagementFinancial } from "../../financial-mgmt/financial.model";
import { IInternalAssociatedEngagementViewModel } from "./internal-associated-engagements-v2.model";
import { ProjectService } from "../../../common/services/project.service";
import { RouteName, Components, TooltipText, ComponentFailureMessages, SourceConstants, LogEventConstants, NoDataText, AccessibilityConstants } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";

@Component({
    selector: "dm-internal-associated-engagements-v2",
    templateUrl: "./internal-associated-engagements-v2.html",
    styleUrls: ["./internal-associated-engagements-v2.scss"]
})
export class AssociatedInternalEngagementsModalComponent extends DmModalAbstract {
    @Input() public associatedEngagementDetails: IEngagementSearchResult[];
    public RouteName = RouteName;
    public associatedInternalEngagements: IInternalAssociatedEngagementViewModel[] = [];
    public currency: string;
    public errorText: string;
    public totalActualCost: number = 0;
    public totalActualHours: number = 0;
    public totalPlannedCost: number = 0;
    public totalPlannedHours: number = 0;
    public wbsTooltipText: string = TooltipText.EBSState;
    public noAssociatedEngagements = NoDataText.NoAssociatedEngagements;
    public isLoadingInternalEngagements: boolean;
    public modalContent: IModal;
    public accessibilityConstants = AccessibilityConstants;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal
    ) {
        super(activeModal, dmLogger, Components.InternalAssociatedEngagements);
    }

    public ngOnInit(): void {
        this.errorText = ComponentFailureMessages.InternalAssociatedEngagementsV2Component;
        this.modalContent = {
            title: "Associated Internal Engagements"
        };
        this.isLoadingInternalEngagements = true;
        const engagementIdList = this.getEngagementIds(this.associatedEngagementDetails);
        if (engagementIdList && engagementIdList.length) {
            this.projectService.getFinancialsByEntityId(engagementIdList, false, false).then((engagementFinancialList: IEngagementFinancial) => {
                if (engagementFinancialList && engagementFinancialList.engagementFinancials && engagementFinancialList.engagementFinancials.length) {
                    for (const engagementDetail of this.associatedEngagementDetails) {
                        const currentBaseLineDetails: IFinancial = this.getFinancialsByBaselineType(engagementFinancialList, "Current");
                        const actualCurrentDetails: IFinancial = this.getFinancialsByBaselineType(engagementFinancialList, "ActualsCurrent");
                        this.totalActualCost = this.totalActualCost + (actualCurrentDetails ? actualCurrentDetails.cost : 0);
                        this.totalActualHours = this.totalActualHours + (actualCurrentDetails ? actualCurrentDetails.hours : 0);
                        this.totalPlannedCost = this.totalPlannedCost + (currentBaseLineDetails ? currentBaseLineDetails.cost : 0);
                        this.totalPlannedHours = this.totalPlannedHours + (currentBaseLineDetails ? currentBaseLineDetails.hours : 0);
                        if (!this.associatedInternalEngagements) {
                            this.associatedInternalEngagements = [];
                        }
                        this.associatedInternalEngagements.push({
                            engagementId: engagementDetail.engagementId,
                            engagementName: engagementDetail.name,
                            engagementEbsState: engagementDetail.stateDescription,
                            engagementStartDate: engagementDetail.startDate,
                            engagementEndDate: engagementDetail.endDate,
                            engagementPjm: engagementDetail.pPjMName,
                            engagementPjmAlias: engagementDetail.pPjMAlias,
                            engagementType: this.sharedFunctionsService.getInternalEngagementType(engagementDetail.projectTypeCode),
                            plannedCost: currentBaseLineDetails ? currentBaseLineDetails.cost : undefined,
                            plannedHours: currentBaseLineDetails ? currentBaseLineDetails.hours : undefined,
                            actualCost: actualCurrentDetails ? actualCurrentDetails.cost : undefined,
                            actualHours: actualCurrentDetails ? actualCurrentDetails.hours : undefined,
                            currency: currentBaseLineDetails ? currentBaseLineDetails.currency : undefined
                        });
                    }
                } else {
                    this.associatedEngagementDetails.forEach((engagementDetail) => {
                        this.populateEngagementDetails(engagementDetail);
                    });
                }
                if (!this.associatedInternalEngagements) {
                    /* API calls succeeded but for some reason no engagements were returned */
                    this.isLoadingInternalEngagements = false;
                } else {
                    this.currency = this.getCurrency();
                    this.isLoadingInternalEngagements = false;
                }
                this.modalContent.title = this.modalContent.title + " (" + this.associatedInternalEngagements.length + ")";
            }).catch((error) => {
                /* Getting engagement financials from PMS API for the list of associated engagement IDs failed, but
                we already have the associated engagements, so we can share them without the financial data */
                this.associatedEngagementDetails.forEach((engagementDetail) => {
                    this.populateEngagementDetails(engagementDetail);
                });
                this.currency = this.getCurrency();
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isLoadingInternalEngagements = false;
            });
        } else {
            /* Response from search API to get the associated engagement succeeded, but for some reason
            there were no provided engagement Ids--maybe none were internal engagements */
            this.isLoadingInternalEngagements = false;
        }

    }
    /**
     * Logs an event if user clicks on an engagement item on the
     * associated internal enagements list
     */
    public logEngagementClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.LogEngagementClick, LogEventConstants.EngagementNavigation);
    }

    /**
     * Logs an event if user clicks on the project manager name link
     * for an item on the associated internal enagements list
     */
    public logPJMClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.LogPJMClick, LogEventConstants.EmployeeLinkClick);
    }

    /**
     * Gets the currency type from the Associated Internal Engagements.
     * Finds the first instance of a defined currency and uses that as the type.
     */
    private getCurrency(): string {
        const currencyList: string[] = this.associatedInternalEngagements.map((x: IInternalAssociatedEngagementViewModel) => x.currency);
        if (currencyList.length) {
            return currencyList[0];
        }
        return undefined;
    }

    /**
     * Adds an associated internal engagement object to the list of associated internal engagements with the
     * information from the given engagement search result object. Used when financial information is missing, but all
     * other information about the engagement is present.
     * @param engagementDetail
     */
    private populateEngagementDetails(engagementDetail: IEngagementSearchResult): void {
        if (!this.associatedInternalEngagements) {
            this.associatedInternalEngagements = [];
        }
        this.associatedInternalEngagements.push({
            engagementId: engagementDetail.engagementId,
            engagementName: engagementDetail.name,
            engagementEbsState: engagementDetail.stateDescription,
            engagementStartDate: engagementDetail.startDate,
            engagementEndDate: engagementDetail.endDate,
            engagementPjm: engagementDetail.pPjMName,
            engagementPjmAlias: engagementDetail.pPjMAlias,
            currency: undefined,
            engagementType: this.sharedFunctionsService.getInternalEngagementType(engagementDetail.projectTypeCode)
        });
    }

    /**
     * Gets a list of engagement ID objects from the given search response.
     * @param searchResponse
     */
    private getEngagementIds(searchResponse: IEngagementSearchResult[]): IEngagement[] {
        const engagementList: IEngagement[] = [];
        if (searchResponse && searchResponse.length) {
            for (const engagement of searchResponse) {
                engagementList.push({
                    id: engagement.engagementId
                });
            }
        }
        return engagementList;
    }

    /**
     * Gets a single Financial object from the given engagement financial list with the specific baseline type.
     * @param engagementFinancialList
     * @param baselineType
     */
    private getFinancialsByBaselineType(engagementFinancialList: IEngagementFinancial, baselineType: string): IFinancial {
        const financialDetails: IFinancialDetail[] = this.financialService.getFinancialDetailForBaselineType(engagementFinancialList, baselineType);
        if (this.financialService.financialObjExists(financialDetails)) {
            return financialDetails[0].financials[0];
        }
        return undefined;
    }

}

