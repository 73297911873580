import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { combineLatest as observableCombineLatest } from "rxjs";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AddInternalEngagementProjectComponent } from "../../../../new-internal-engagement/add-internal-engagement-project/add-internal-engagement-project.component";
import { Components, TooltipText, SourceConstants, LogEventConstants, nonNbueInternalEngagementCreationCodes, AccessibilityConstants } from "../../../../../common/application.constants";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { getEntireInternalFinancialDetails } from "../../../../../store/internal-financial-details/intenal-financial-details.selector";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { IIntEngProjectEBSDetails, IIntEngServiceEBSDetails, IIntEngTaskEBSDetails } from "./internal-engagement-breakdown-structure.model";
import { IInternalEngagementDomainTypes } from "../../../../new-internal-engagement/new-internal-engagement.contracts";
import { IInternalEngagementProjectFinancialsListV2, IInternalEngagementServiceFinancialsListV2 } from "../../../../../common/services/contracts/portfolio.model";
import { IProjectDetailsV2, IEngagementDetailsV2, ITaskDetailsApiV2, IServiceDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IState } from "../../../../../store/reducers";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { WbsAddTaskModalComponent } from "../../../../manage-wbs/tiles/wbs-project/wbs-project-service-task-add/wbs-project-service-task-add.component";
import { WbsProjectEditModalInstanceComponent } from "../../../../manage-wbs/tiles/wbs-project/wbs-project-edit/wbs-project-edit.component";
import { IInternalFinancialDetailsState } from "../../../../../store/internal-financial-details/internal-financial-details.reducer";
import { WbsProjectStateModalComponent } from "../../../../manage-wbs/tiles/wbs-project/wbs-project-ebs-state/wbs-project-ebs-state.component";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";
import { ITile } from "../../../../../components/tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../../common/error.constants";
import { InvalidateWbsStructures } from "../../../../../store/wbs-structures/wbs-structures.action";
import { WBSService } from "../../../../../common/services/wbs.service";
import { IExchangeRate } from "../../../../../common/services/contracts/wbs.service.contracts";
import moment from "moment";

const INTERNALENGAGEMENTBREAKDOWNSTRUCTURE = "Internal Engagement Breakdown Structure";

@Component({
    selector: "dm-internal-eng-breakdown-structure",
    templateUrl: "./internal-engagement-breakdown-structure.html",
    styleUrls: ["./internal-engagement-breakdown-structure.scss"]
})
export class InternalEngagementBreakdownStructureComponent extends DmComponentAbstract {
    public currencyCode: string;
    public wbsTooltipText: string = TooltipText.EBSState;
    public engagementDetails: IEngagementDetailsV2;
    public internalEngagementEBSViewModel: IIntEngProjectEBSDetails[] = [];
    public selectedProject: IProjectDetailsV2;
    public selectedTask: ITaskDetailsApiV2;
    public isProjectFilterDisabled: boolean = false;
    public isTaskFilterDisabled: boolean = true;
    public placeholder: any;
    public tasksFilterData = [];
    public showProjectsExpanded: boolean = false;
    public showNbueHours: boolean = true;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.InternalEngagementStructure;
    public accessibilityConstants = AccessibilityConstants;
    private lockedUserStatus: string;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(ConfigManagerService) private configManager: ConfigManagerService,
        @Inject(DMAuthorizationService) private dmAuthorizationservice: DMAuthorizationService,
        @Inject(Store) private store: Store<IState>,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.InternalEngagementBreakdownStructure);
    }

    public ngOnInit(): void {
        const selectedInternalEngagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.tileContent = {
            title: INTERNALENGAGEMENTBREAKDOWNSTRUCTURE
        };
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(selectedInternalEngagementId));
        const financialDetails$ = this.store.select(getEntireInternalFinancialDetails(selectedInternalEngagementId));
        this.configManager.initialize().then(() => {
            this.lockedUserStatus = this.configManager.getValue<string>("lockedUserStatusCode");
        });

        observableCombineLatest(
            financialDetails$,
            engagementDetails$,
            (
                financialDetails: IInternalFinancialDetailsState,
                engagementDetails: IEngagementDetailsState,
            ) => ({
                financialDetails,
                engagementDetails,
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                financialDetails,
                engagementDetails,
            }) => {
                if (engagementDetails.loaded && financialDetails.loaded) {
                    if (engagementDetails.engagementDetails.projects && engagementDetails.engagementDetails.projects.length) {
                        this.showNbueHours = nonNbueInternalEngagementCreationCodes.indexOf(engagementDetails.engagementDetails.projects[0].projectTypeCode) < 0 ? true : false;
                    }
                    this.engagementDetails = engagementDetails.engagementDetails;
                    this.internalEngagementEBSViewModel = this.getIntEngEBSViewModel(engagementDetails.engagementDetails, financialDetails.financialDetails[0].projectFinancials);
                    this.internalEngagementEBSViewModel.map((project) => project.isExpand = this.showProjectsExpanded);
                    this.selectedProject = this.placeholder;
                    this.selectedTask = this.placeholder;
                    this.currencyCode = this.engagementDetails.currency;
                    if (this.engagementDetails) {
                        this.tileContent.title = INTERNALENGAGEMENTBREAKDOWNSTRUCTURE + " (" + this.engagementDetails.statusDescription + ")";
                    }
                }
                this.refreshOnItemInvalidation(engagementDetails, financialDetails);
                this.setLoadersBasedOnItemState(engagementDetails, financialDetails);
                this.setErrorsBasedOnItemState(engagementDetails, financialDetails);
                if (financialDetails.error || engagementDetails.error) {
                    this.isServerError = true;
                }
            });
    }

    /**
     * On selected project change, gets the tasks under project and enables task dropdown.
     *
     * @param {ICBSProjectFinancial} selectedProj
     * @memberof InternalEngagementCostBreakdownComponent
     */
    public onSelectedProjectChanged(selectedProj: IProjectDetailsV2): void {
        this.tasksFilterData = [];
        this.isTaskFilterDisabled = false;
        if (this.engagementDetails.projects && this.engagementDetails.projects.length) {
            const filteredServices = this.engagementDetails.projects.filter((project: IProjectDetailsV2) => project.id === selectedProj.id).length ? this.engagementDetails.projects.filter((project: IProjectDetailsV2) => project.id === selectedProj.id)[0].services : [];
            if (filteredServices && filteredServices.length) {
                for (const service of filteredServices) {
                    this.tasksFilterData.push(...service.tasks);
                }
            }
            this.selectedTask = this.placeholder;
        }
    }

    /**
     * Opens the modal that allows users to edit the given project.
     * @param selectedProject
     */
    public openEditProjectModal(selectedProject: IIntEngProjectEBSDetails): void {
        const modalRef: NgbModalRef = this.modalService.open(WbsProjectEditModalInstanceComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal edit-project",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        if (this.engagementDetails && this.engagementDetails.projects && this.engagementDetails.projects.length) {
            modalRef.componentInstance.selectedProject = this.engagementDetails.projects.filter((project) => project.id === selectedProject.projectId)[0];
            modalRef.componentInstance.engagementDetails = this.engagementDetails;
            modalRef.componentInstance.isInternal = true;
            modalRef.componentInstance.showNbueHours = this.showNbueHours;
        }
    }

    /**
     * Opens the modal that allows users to request state change for the given project.
     * @param selectedProject
     */
    public openRequestStateChangeModal(selectedProject: IIntEngProjectEBSDetails): void {
        const modalRef: NgbModalRef = this.modalService.open(WbsProjectStateModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal ebs-state-engagement",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        if (this.engagementDetails && this.engagementDetails.projects && this.engagementDetails.projects.length) {
            modalRef.componentInstance.selectedProject = this.engagementDetails.projects.filter((project) => project.id === selectedProject.projectId)[0];
            modalRef.componentInstance.selectedEntity = this.engagementDetails.projects.filter((project) => project.id === selectedProject.projectId)[0];
            modalRef.componentInstance.entityType = "Project";
            modalRef.componentInstance.isInternalEngagement = true;
        }
    }

    /**
     * Opens a modal that allows users to add project.
     *
     * @memberof InternalEngagementCostBreakdownComponent
     */
    public openAddProjectModal(): void {
        const internalEngagementDomains: IInternalEngagementDomainTypes[] = this.configManager.getValue<IInternalEngagementDomainTypes[]>("internalEngagementDomains");

        const modalRef: NgbModalRef = this.modalService.open(AddInternalEngagementProjectComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal add-project-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.isComponentModal = true;
        modalRef.componentInstance.addProjectModalReference = modalRef;
        modalRef.componentInstance.internalEngagementType = {
            typeDescription: this.engagementDetails.engagementTypeDescription,
            engagementCreationCode: this.engagementDetails.engagementTypeCode
        };
        modalRef.componentInstance.internalEngagementDomain = internalEngagementDomains.filter((internalEngagementDomain: IInternalEngagementDomainTypes) => internalEngagementDomain.domainValueName === this.engagementDetails.primaryDomain)[0];
        modalRef.componentInstance.internalEngagementType = this.engagementDetails.projects && this.engagementDetails.projects.length ? {
            typeDescription: this.engagementDetails.projects[0].projectTypeDescription,
            engagementCreationCode: this.engagementDetails.projects[0].projectTypeCode
        } : undefined;
        modalRef.componentInstance.engagementId = this.engagementDetails.id;
        modalRef.componentInstance.engagementStartDate = this.engagementDetails.startDate;
        modalRef.componentInstance.engagementEndDate = this.engagementDetails.endDate;
        modalRef.componentInstance.engagementDetails = this.engagementDetails;
        modalRef.componentInstance.onProjectCreated.pipe(untilDestroyed(this)).subscribe(() => this.refreshDataAfterAddingProject());
        modalRef.componentInstance.projectIndex = 1;
        this.getExchangeRateForProjects(modalRef);
    }

    /**
     * This method gets Exchange rate based on Currency Code and Engagement start date
     */
    public getExchangeRateForProjects(modalRef: NgbModalRef): void {
        const engagementStartDate = moment(this.engagementDetails.startDate).format("MM/DD/YYYY");
        if ( this.currencyCode === "USD") {
            modalRef.componentInstance.currencyCodeBasedOnCompanyCode = "USD";
            modalRef.componentInstance.exchangeRate = 1.0;
        }
        else {
            this.wbsService.getExchangeRate("USD", this.currencyCode, engagementStartDate).then((response: IExchangeRate) => {
                modalRef.componentInstance.currencyCodeBasedOnCompanyCode = this.currencyCode;
                modalRef.componentInstance.exchangeRate = response.exchangeRate;
            });
        }
    }

    /**
     * For expanding/collapsing all the Projects
     */
    public expandCollapseAllProjects(): void {
        this.showProjectsExpanded = !this.showProjectsExpanded;
        this.internalEngagementEBSViewModel.map((project) => project.isExpand = this.showProjectsExpanded);
    }

    /**
     * Manually expand/collapsing the projects and resetting the expand/collapse icon on top of the table
     *
     */
    public expandCollapseProjects(project: IIntEngProjectEBSDetails): void {
        project.isExpand = !project.isExpand;
        const expandedProjects = this.internalEngagementEBSViewModel.filter((intEngProjectEBSDetails: IIntEngProjectEBSDetails) => intEngProjectEBSDetails.isExpand === true).length;
        if (expandedProjects === this.internalEngagementEBSViewModel.length) {
            this.showProjectsExpanded = true;
        }
        const collapsedProjects = this.internalEngagementEBSViewModel.filter((intEngProjectEBSDetails: IIntEngProjectEBSDetails) => intEngProjectEBSDetails.isExpand === false).length;
        if (collapsedProjects === this.internalEngagementEBSViewModel.length) {
            this.showProjectsExpanded = false;
        }
    }

    /**
     * Opens the modal that allows users to add a task to a service.
     * @param service
     * @param entityType
     */
    public openAddTaskModal(project: IIntEngProjectEBSDetails): void {
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OpenAddTaskModal, LogEventConstants.ManageWBSTaskAddLink); // update these logs
        const modalRef: NgbModalRef = this.modalService.open(WbsAddTaskModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal add-task",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.onTaskCreated.pipe(untilDestroyed(this)).subscribe(() => this.refreshDataAfterAddingProject());
        /* Match up the financial project with the actual project object to get the project info */
        const currProj = this.engagementDetails.projects.filter((x) => x.id === project.projectId)[0];
        const projectInfo = { /* This item temp holds all the necessary data that we need from the service in the task modal */
            /* Allows temp functional code without type errors */
            minDate: currProj ? currProj.minDate : undefined,
            maxDate: currProj ? currProj.maxDate : undefined,
            startDate: currProj ? currProj.startDate : undefined,
            endDate: currProj ? currProj.endDate : undefined,
            statusCode: currProj ? currProj.statusCode : undefined,
            projectId: project.projectId,
            id: project.servicesData[0] ? project.servicesData[0].serviceId : undefined
        };
        modalRef.componentInstance.isInternal = true;
        modalRef.componentInstance.selectedService = projectInfo;
        modalRef.componentInstance.projectContextData = this.engagementDetails.projects; /* only used to get the pjms to send notification to */
    }

    /**
     * Prepares internal engagement ebs view model by getting required fields from engagement details and financial details from bulk api.
     *
     * @private
     * @param {IEngagementDetailsV2} engagementDetails
     * @param {IInternalEngagementProjectFinancialsListV2[]} financialDetails
     * @returns {IIntEngProjectEBSDetails[]}
     * @memberof InternalEngagementBreakdownStructureComponent
     */
    private getIntEngEBSViewModel(engagementDetails: IEngagementDetailsV2, financialDetails: IInternalEngagementProjectFinancialsListV2[]): IIntEngProjectEBSDetails[] {
        if (engagementDetails && engagementDetails.projects) {
            const projectDetails: IIntEngProjectEBSDetails[] = [];
            for (const project of engagementDetails.projects) {
                const projectData: IIntEngProjectEBSDetails = {
                    currency: project.currency ? project.currency : undefined,
                    projectDomain: project.primaryDomain ? project.primaryDomain : undefined,
                    projectEndDate: project.endDate ? project.endDate : undefined,
                    engagementId: project.engagementId ? project.engagementId : undefined,
                    pjm: project.pjm ? project.pjm : undefined,
                    projectId: project.id ? project.id : undefined,
                    projectName: project.name ? project.name : undefined,
                    servicesData: [],
                    projectStartDate: project.startDate ? project.startDate : undefined,
                    projectStatus: project.statusDescription ? project.statusDescription : undefined,
                    nbueHours: project.totalHours ? project.totalHours : 0,
                    projectActualCost: undefined,
                    projectPlannedCost: undefined,
                    projectActualLabor: undefined,
                    projectPlannedLabor: undefined,
                    projectLaborConsumedPercentage: undefined,
                    projectCostConsumedPercentage: undefined,
                    hasEditAccess: this.dmAuthorizationservice.isUserInEngagementLevelTeam(this.engagementDetails) || this.dmAuthorizationservice.isUserInProjectLevelTeam(project),                    
                };

                const nonExpenseService: IServiceDetailsV2 = project.services.filter((service: IServiceDetailsV2) => service.description.toLowerCase().indexOf("expense") === -1)[0];
                if (nonExpenseService && nonExpenseService.tasks && nonExpenseService.tasks.length) {
                    projectData.srCostCenter = nonExpenseService.tasks[0].costObject;
                    projectData.srCrossChargeType = nonExpenseService.tasks[0].chargeType;
                    projectData.srCrossChargeAmount = nonExpenseService.tasks[0].chargeTypeValue;
                }

                if (financialDetails && financialDetails.length) {
                    const filteredProjectFinancials: IInternalEngagementProjectFinancialsListV2 = financialDetails.filter((projectFinancials: IInternalEngagementProjectFinancialsListV2) => projectFinancials.id === project.id)[0];
                    if (filteredProjectFinancials) {
                        projectData.projectActualCost = filteredProjectFinancials.actualCost;
                        projectData.projectPlannedCost = filteredProjectFinancials.plannedCost;
                        projectData.projectActualLabor = filteredProjectFinancials.actualHours;
                        projectData.projectPlannedLabor = filteredProjectFinancials.labor;
                        if (filteredProjectFinancials.actualCost && filteredProjectFinancials.plannedCost) {
                            projectData.projectCostConsumedPercentage = (filteredProjectFinancials.actualCost / filteredProjectFinancials.plannedCost) * 100;
                        }
                        if (filteredProjectFinancials.actualHours && filteredProjectFinancials.labor) {
                            projectData.projectLaborConsumedPercentage = (filteredProjectFinancials.actualHours / filteredProjectFinancials.labor) * 100;
                        }

                        if (project && project.services && project.services.length) {
                            for (const service of project.services) {
                                const serviceData: IIntEngServiceEBSDetails = {
                                    serviceEndDate: service.endDate ? service.endDate : undefined,
                                    serviceId: service.id ? service.id : undefined,
                                    serviceName: service.name ? service.name : undefined,
                                    projectId: service.projectId ? service.projectId : undefined,
                                    tasksData: [],
                                    serviceStartDate: service.startDate ? service.startDate : undefined,
                                    serviceStatus: (service && service.userStatusCode && service.userStatusCode === this.lockedUserStatus) ? service.statusDescription + " - " + service.userStatusDescription : service.statusDescription,
                                    serviceActualCost: undefined,
                                    servicePlannedCost: undefined,
                                    serviceActualLabor: undefined,
                                    servicePlannedLabor: undefined,
                                    serviceLaborConsumedPercentage: undefined,
                                    serviceCostConsumedPercentage: undefined
                                };

                                if (filteredProjectFinancials.serviceFinancials && filteredProjectFinancials.serviceFinancials.length) {
                                    const filteredServiceFinancials: IInternalEngagementServiceFinancialsListV2 = filteredProjectFinancials.serviceFinancials.filter((servFin: IInternalEngagementServiceFinancialsListV2) => servFin.id === service.id)[0];
                                    if (filteredServiceFinancials) {
                                        serviceData.serviceActualCost = filteredServiceFinancials.actualCost;
                                        serviceData.servicePlannedCost = filteredServiceFinancials.plannedCost;
                                        serviceData.serviceActualLabor = filteredServiceFinancials.actualHours;
                                        serviceData.servicePlannedLabor = filteredServiceFinancials.labor;
                                        if (filteredServiceFinancials.actualCost && filteredServiceFinancials.plannedCost) {
                                            serviceData.serviceCostConsumedPercentage = (filteredServiceFinancials.actualCost / filteredServiceFinancials.plannedCost) * 100;
                                        }
                                        if (filteredServiceFinancials.actualHours && filteredServiceFinancials.labor) {
                                            serviceData.serviceLaborConsumedPercentage = (filteredServiceFinancials.actualHours / filteredServiceFinancials.labor) * 100;
                                        }
                                    }
                                    projectData.servicesData.push(serviceData);
                                    if (service && service.tasks && service.tasks.length) {
                                        for (const task of service.tasks) {
                                            const taskData: IIntEngTaskEBSDetails = {
                                                taskEndDate: task.endDate ? task.endDate : undefined,
                                                taskId: task.id ? task.id : undefined,
                                                serviceId: task.serviceId ? task.serviceId : undefined,
                                                taskName: task.name ? task.name : undefined,
                                                taskStartDate: task.startDate ? task.startDate : undefined,
                                                taskStatus: task.statusDescription ? task.statusDescription : undefined,
                                                taskActualCost: undefined,
                                                taskPlannedCost: undefined,
                                                taskActualLabor: undefined,
                                                taskPlannedLabor: undefined,
                                                taskLaborConsumedPercentage: undefined,
                                                taskCostConsumedPercentage: undefined
                                            };

                                            if (filteredServiceFinancials.taskFinancials && filteredServiceFinancials.taskFinancials.length) {
                                                const filteredTaskFinancials = filteredServiceFinancials.taskFinancials.filter((fin) => fin.id === task.id)[0];
                                                if (filteredTaskFinancials) {
                                                    taskData.taskActualCost = filteredTaskFinancials.actualCost;
                                                    taskData.taskPlannedCost = filteredTaskFinancials.plannedCost;
                                                    taskData.taskActualLabor = filteredTaskFinancials.actualHours;
                                                    taskData.taskPlannedLabor = filteredTaskFinancials.labor;
                                                    if (filteredTaskFinancials.actualCost && filteredTaskFinancials.plannedCost) {
                                                        taskData.taskCostConsumedPercentage = (filteredTaskFinancials.actualCost / filteredTaskFinancials.plannedCost) * 100;
                                                    }
                                                    if (filteredTaskFinancials.actualHours && filteredTaskFinancials.labor) {
                                                        taskData.taskLaborConsumedPercentage = (filteredTaskFinancials.actualHours / filteredTaskFinancials.labor) * 100;
                                                    }
                                                }
                                                serviceData.tasksData.push(taskData);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    projectDetails.push(projectData);
                }
            }
            return projectDetails;
        }
    }


    /**
     * Invalidate data in store for the engagement after adding project.
     *
     * @private
     * @param {string} projectId
     * @memberof InternalEngagementBreakdownStructureComponent
     */
    private refreshDataAfterAddingProject(): void {
        this.engagementDetailService.invalidateStoreOnRefresh(this.engagementDetails.id);
        this.store.dispatch(new InvalidateWbsStructures(this.engagementDetails.id));
    }
}

