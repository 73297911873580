import { Action } from "@ngrx/store";
import { IWeeklyForecastRecommendation } from "../../common/services/contracts/forecast-recommendation.contracts";

// 1. action types
export enum ProjectForecastRecommendationActionTypes {
    LOAD_PROJECT_FORECAST_RECOMMENDATIONS = "[ProjectForecastRecommendations] Load",
    LOAD_PROJECT_FORECAST_RECOMMENDATIONS_SUCCESS = "[ProjectForecastRecommendations] Load Success",
    LOAD_PROJECT_FORECAST_RECOMMENDATIONS_FAIL = "[ProjectForecastRecommendations] Load Fail",
    INVALIDATE_PROJECT_FORECAST_RECOMMENDATIONS = "[ProjectForecastRecommendations] Invalidate"
}

// 2. action creators
export class LoadProjectForecastRecommendations implements Action {
    public readonly type = ProjectForecastRecommendationActionTypes.LOAD_PROJECT_FORECAST_RECOMMENDATIONS;
    public constructor(public projectId: string, public engagementId?: string) { }
}
export class LoadProjectForecastRecommendationsSuccess implements Action {
    public readonly type = ProjectForecastRecommendationActionTypes.LOAD_PROJECT_FORECAST_RECOMMENDATIONS_SUCCESS;
    public constructor(public projectId: string, public forecastRecommendations: IWeeklyForecastRecommendation[]) { }
}

export class LoadProjectForecastRecommendationsFail implements Action {
    public readonly type = ProjectForecastRecommendationActionTypes.LOAD_PROJECT_FORECAST_RECOMMENDATIONS_FAIL;
    public constructor(public projectId: string, public errorMessage: string) { }
}

export class InvalidateProjectForecastRecommendations implements Action {
    public readonly type = ProjectForecastRecommendationActionTypes.INVALIDATE_PROJECT_FORECAST_RECOMMENDATIONS;
    public constructor(public projectId: string) { }
}

// 3. export the union creators
export type ProjectForecastRecommendationActions =
    | LoadProjectForecastRecommendations
    | LoadProjectForecastRecommendationsSuccess
    | LoadProjectForecastRecommendationsFail
    | InvalidateProjectForecastRecommendations;