<div class="modal-content">
    <div class="modal-header">
        <button type="button" id="closeActivationPopupButton" class="close set-focus" data-dismiss="modal" (click)="closeModal()"
            aria-label="Activate Engagement Dialog Close">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle" id="activationModalLabel">
            {{showEngagementActivationInitialScreen ? "Release and Activate" : "Automatic Release and Activation Failed"}}
        </h4>
    </div>
    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true"
    *ngIf="showActivateFinancialLoading"></dm-loading>
    <dm-activate-engagement-initial-template *ngIf="showEngagementActivationInitialScreen && !showActivateFinancialLoading"
        (cancelClicked)="closeModal()" (submitClicked)="submitForActivation()">
    </dm-activate-engagement-initial-template>

    <dm-engagement-validation-threshhold
        *ngIf="!showEngagementActivationInitialScreen && validateEngagementFailureResponse && !showActivateFinancialLoading"
        [validationResultSet]="validateEngagementFailureResponse.projectThresholdValues"
        [validationMessage]="failureValidationMessage"
        [saveButtonText] = "'Continue'"
        [cancelButtonText]= "'Close and Update Baseline'"
        [engagementDetails]= "engagementFullDetails"        
        (closeAndUpdateBaselineClicked)="closeAndUpdateBaselineClicked()"        
        (emailPJCClicked)="sendEmailToASCAndUpdateEngagementId($event)"        
        (continueClicked)="continueToRelease()">
    </dm-engagement-validation-threshhold>

</div>