import { Action } from "@ngrx/store";
import { IPlanForecastDataParameters } from "../../common/services/contracts/project.service.contracts";

// 1. action types
export enum PlanForecastParamsActionTypes {
    LOAD_PLAN_FORECAST_PARAMS = "[PlanForecastParams] Load",
    LOAD_PLAN_FORECAST_PARAMS_SUCCESS = "[PlanForecastParams] Load Success",
    LOAD_PLAN_FORECAST_PARAMS_FAIL = "[PlanForecastParams] Load Fail",
    INVALIDATE_PLAN_FORECAST_PARAMS = "[PlanForecastParams] Invalidate"
}

// 2. action creators
export class LoadPlanForecastParams implements Action {
    public readonly type = PlanForecastParamsActionTypes.LOAD_PLAN_FORECAST_PARAMS;
    public constructor(public wbsId: string) { }
}
export class LoadPlanForecastParamsSuccess implements Action {
    public readonly type = PlanForecastParamsActionTypes.LOAD_PLAN_FORECAST_PARAMS_SUCCESS;
    public constructor(public wbsId: string, public planForecastParams: IPlanForecastDataParameters) { }
}

export class LoadPlanForecastParamsFail implements Action {
    public readonly type = PlanForecastParamsActionTypes.LOAD_PLAN_FORECAST_PARAMS_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidatePlanForecastParams implements Action {
    public readonly type = PlanForecastParamsActionTypes.INVALIDATE_PLAN_FORECAST_PARAMS;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type PlanForecastParamsActions =
    | LoadPlanForecastParams
    | LoadPlanForecastParamsSuccess
    | LoadPlanForecastParamsFail
    | InvalidatePlanForecastParams;


