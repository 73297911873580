<section class="cancel-dailog">
    <label class="sr-only">Cancellation Request</label>
    <section class="modal-header">
        <button type="button" class="close set-focus" (click)="closeModal()" (keydown)="moveFocusPrev($event, 'accessibility.CancelRequest')" id="accessibility.ClosePopUp" aria-label="Cancellation Request Dialog close">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            Cancellation Request
        </h4>
    </section>
    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [errorText]="errorSummaryText"
        [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>

    <section class="modal-body" *ngIf="!isLoading">
        <form #cancelRequest>
            <section class="section__wrapper">
                <section class="input__label">
                    <label class="input__label--text" for="dm-cancel-reason-ddl">Reason Code
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <section class="input__select--icon">
                        <select id="dm-cancel-reason-ddl" [(ngModel)]="selectedCancelReason" required name="reasonType"
                            class="input__select input__select--big font-caption-alt">
                            <option [value]="'select'">Select reason code</option>
                            <option *ngFor="let reason of cancelReason" [value]="reason.Name">
                                {{reason.Name}}
                            </option>
                        </select>
                        <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                        <!-- <span class="sr-only" role="alert" aria-atomic="true" aria-live="polite" *ngIf=="cancelRequest.reasonType.$touched && cancelRequest.reasonType.$invalid">Suspend Reason is required</span> -->
                        <span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite"
                            *ngIf="cancelRequest.reasonType.touched && cancelRequest.reasonType.invalid">{{cancelErrorMessages.SuspendReasonIsRequired}}</span>
                    </section>
                </section>
                <label class="input__label--text" for="dm-cancel-reason">Comments<span class="required-mark"
                        aria-hidden="true" *ngIf="selectedCancelReason && selectedCancelReason.toLowerCase()==='other'">
                        *</span></label>
                <textarea id="dm-cancel-reason" [(ngModel)]="cancelComment" name="rejectionComments"
                    *ngIf="selectedCancelReason && selectedCancelReason.toLowerCase()==='other'" class="input_text_edge" required
                    placeholder="  Comments"></textarea>
                <textarea id="dm-cancel-reason" [(ngModel)]="cancelComment" name="rejectionComments"
                    *ngIf="!(selectedCancelReason && selectedCancelReason.toLowerCase()==='other')" class="input_text_edge"
                    placeholder="  Comments"></textarea>
                <!-- <span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite" *ngIf="cancelRequest.rejectionComments">Comments are required</span> -->
            </section>
            <section class="modal-footer footer-button">
                <button type="button" class="fxp-btn fxp-btn--secondary" id="accessibility.CancelModalRequest" (click)="closeModal()">Cancel</button>
                <button type="button" class="fxp-btn fxp-btn--primary" id="accessibility.CancelRequest" (click)="submit()" (keydown)="moveFocusNext($event, 'accessibility.ClosePopUp')">
                    Submit
                </button>
                <label id="lblMessage" class="input__label" for="dm-id-suspendRequest">
                    <span class="required-mark" role="alert" aria-atomic="true" aria-live="polite">{{lblMessage}}</span>
                </label>
            </section>
        </form>
    </section>
</section>