import { ILoadableState } from "../reducers";
import * as fromWbsCfpDetails from "./wbs-cfp.action";
import { IForecast } from "./../../components/financial-mgmt/financial.model";

export interface IWbsCfpState extends ILoadableState {
    wbsCfpDetails: IForecast;
}

export const initialState: IWbsCfpState = {
    wbsCfpDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromWbsCfpDetails.WbsCfpActions): { [wbsId: string]: IWbsCfpState } {
    switch (action.type) {
        case fromWbsCfpDetails.WbsCfpActionTypes.LOAD_WbsCfp: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromWbsCfpDetails.WbsCfpActionTypes.LOAD_WbsCfp_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                wbsCfpDetails: action.wbsCfpDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromWbsCfpDetails.WbsCfpActionTypes.LOAD_WbsCfp_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromWbsCfpDetails.WbsCfpActionTypes.INVALIDATE_WbsCfp: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}