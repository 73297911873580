<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'200px'" [tileHeight]="'100%'" [isV2Tile]="true">
    <dm-loading loader id="contacts-loader" [loadingText]="'Loading Contact Details'" [isCustomText]="true"
        [showLoading]="showLoading" *ngIf="isComponentLoading && !isServerError">
    </dm-loading>
    <a additionalActionsRight class="dm-link" [attr.aria-label]="this.tileContent?.title + ' Tile Section ' + 'Contact Details' + 'Navigate to Contact Details' " title="Navigate to Contact Details"
        [uiSref]="isProjectContext? RouteName.ProjectContactDetails: RouteName.EngagementContactDetails">
        Contact Details
    </a>
    <contacts [contactsList]="contactsList?.engagementContacts" [showContactActions]="true" [isV2Tile]="true"
        *ngIf="!isComponentLoading && !isServerError"></contacts>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>