import { Component, EventEmitter, forwardRef, Inject, Injector, Input, Output, SecurityContext, SimpleChange } from "@angular/core";
import { FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { NgbDropdown, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer } from "@angular/platform-browser";

import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { BulkUpdateScheduleOptions, Components, PlanVersionType, StaffVersionType } from "../../../../common/application.constants";
import { IBulkScheduleUpdateResult, IDemand, IForecast, IProject, IResource, IResourceDetails, IResourceFinancials, ISchedule } from "../../../../components/financial-mgmt/financial.model";
import moment from "moment";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { ProjectService } from "../../../../common/services/project.service";
import { AADGraphService } from "../../../../common/services/aad-graphapi.service";
import { IPersonDetails } from "../../../../components/manage-ebs-v2/tiles/entity-team-structure/entity-team-structure.component";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { ISelectedUserAttributes } from "../../../../components/tiles/type-ahead/type-ahead-contracts";
import { OneProfileService } from "../../../../common/services/one-profile.service";
import { BulkUpdateSchedulesModalComponent } from "../../modals/bulk-update/bulkupdate-schedules.component";
import { ResourceDetailsModalComponent } from "../../modals/resource-details/resource-details.component";
import { FilterModel } from "../../../../components/tiles/project-filter/project-filter.model";

export interface IWeek {
    weekNumber?: number;
    startDate: Date;
    endDate: Date;
}

export interface ICostHours {
    cost: number;
    hours: number;    
}

@Component({
    selector: "dm-labor-details",
    templateUrl: "./labor-details.html",
    styleUrls: ["./labor-details.scss"]
})
export class LaborDetailsComponent extends DmComponentAbstract {
    @Input() public forecastDetails: IForecast;
    @Input() public showCfpDetails: boolean = false;
    @Input() public showDbDetails: boolean = false;
    @Input() public showForecastDetails: boolean = false;
    @Output() public emitFilteredDemands = new EventEmitter<any[]>();
    public weeklySchedules: IWeek[] = [];
    public minStartDate: Date;
    public maxEndDate: Date;
    public requestStartDate: Date;
    public requestEndDate: Date;
    public maxWeeksDisplay: number = 12;
    public successPercentage: number;
    public isForcastEditable: boolean = false;
    public forecastSchedules: ISchedule[] = [];
    public startIndex: number = 0;
    public endIndex: number = 12;
    public showPreviousBtn: boolean = false;
    public showNextBtn: boolean = false;
    public demandDetails: Array<{ id: number; billRate: number; eac: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; planned: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; actuals: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; successPercentage?: number }> = [];
    public originalDemands: Array<{ scheduleId: string; hours: number }> = [];
    public currentWeekIndex: number;
    public comparisonIndex: number;
    public eacHours: number = 0;
    public plannedHours: number = 0;
    public eacCosts: number = 0;
    public plannedCosts: number = 0;
    public showCosts: boolean = false;
    public showRecommendations: boolean = false;
    public showRevenue: boolean = false;
    public loggedInUserInfo: ISelectedUserAttributes;
    public weekDisplayListOptions: Array<{ value: number }> = [];
    public selectedNumberOfWeeks: { value: number };
    public dropdownSettings = {};
    public filterDataSource: FilterModel[];
    public selectedItems = [];
    public selectedProjectForecastItems: IProject[];
    public selectedProjectCfpItems: IProject[];
    public selectedProjectDbItems: IProject[];
    private weeksToShowBeforeCurrentWeek: number;
    private currentWeekStartDate: Date;
    private changedForecastDetails: Array<{ scheduleId: string; hours: number }> = [];
    private emailPostFix: string = "@microsoft.com";
    private copiedSchedules: ISchedule[] = [];

    public constructor(@Inject(DMLoggerService) protected dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.PlanForecast);
    }

    public ngOnInit(): void {
        this.dropdownSettings = {
            singleSelection: false,
            idField: "id",
            textField: "name",
            selectAllText: "All Projects",
            unSelectAllText: "All Projects",
            itemsShowLimit: 2,
            allowSearchFilter: false,
            enableCheckAll: true,
        };
        for (let i = 6; i <= 12; i++) {
            this.weekDisplayListOptions.push({ value: i });
        }
        this.selectedNumberOfWeeks = this.selectedNumberOfWeeks ? this.selectedNumberOfWeeks : this.weekDisplayListOptions[this.weekDisplayListOptions.length - 1];
    }

    public ngOnChanges(changes: SimpleChange): void {
        if ((changes["forecastDetails"] && !changes["forecastDetails"].previousValue) || (changes["showCfpDetails"] && !changes["showCfpDetails"].previousValue) || (changes["showDbDetails"] && !changes["showDbDetails"].previousValue)) {
            this.initializeData();
        }
        if (changes["forecastDetails"] && changes["forecastDetails"].previousValue && changes["showForecastDetails"] && changes["showForecastDetails"].currentValue) {
            this.selectedItems = this.getFilterDropdownData(this.forecastDetails.projects);
            this.getDemandsAndSchedules();
        }
    }

    /**
     * expand/collapse project section
     */
    public expandCollapseProject(project: IProject): void {
        project.isExpanded = !project.isExpanded;
    }

    /**
     * expand/collapse demand section
     */
    public expandCollapseDemand(demand: IDemand): void {
        demand.isExpanded = !demand.isExpanded;
        demand.showCosts = demand.showRevenue = demand.isExpanded;
        const expandedDemands = [];
        if (this.forecastDetails && this.forecastDetails.projects && this.forecastDetails.projects.length) {
            for (const project of this.forecastDetails.projects) {
                if (!project.demand.filter((projectDemand) => projectDemand.isExpanded === false).length) {
                    expandedDemands.push(true);
                }
            }
        }
        if (this.showCosts) {
            this.showCosts = expandedDemands.length > 0;
        }
        if (this.showRevenue) {
            this.showRevenue = expandedDemands.length > 0;
        }
    }

    /**
     * Sets projects on deselection of all the options in multi-select drop-down
     */
    public onProjectsDeSelectAll(): void {
        this.selectedItems = [];
        this.forecastDetails.projects = [];
    }

    /**
     * Sets projects on deselection of an option in multi-select drop-down
     */
    public onProjectsDeSelect(item: {id: string; name: string}): void {
        this.forecastDetails.projects = this.forecastDetails.projects.filter((project) => project.projectId !== item.id);
    }

    /**
     * Sets projects on selection of all the options in multi-select drop-down
     */
    public onProjectsSelectAll(items: Array<{id: string; name: string}>): void {
        this.selectedItems = items;
        let selectedProjectItems = [];
        if (this.showForecastDetails) {
            selectedProjectItems = this.selectedProjectForecastItems;
        } else if (this.showCfpDetails) {
            selectedProjectItems = this.selectedProjectCfpItems;
        } else {
            selectedProjectItems = this.selectedProjectDbItems;
        }   
        this.forecastDetails.projects = selectedProjectItems;
        this.getDemandsAndSchedules();
    }

    /**
     * Sets projects on selection of an option in multi-select drop-down
     */
    public onProjectsSelect(): void {
        const filteredProj = [];
        let selectedProjectItems = [];
        if (this.showForecastDetails) {
            selectedProjectItems = this.selectedProjectForecastItems;
        } else if (this.showCfpDetails) {
            selectedProjectItems = this.selectedProjectCfpItems;
        } else {
            selectedProjectItems = this.selectedProjectDbItems;
        }     
        for (const selectedItem of this.selectedItems) {
            filteredProj.push(selectedProjectItems.filter((project) => project.projectId === selectedItem.id)[0]);
        }
        this.forecastDetails.projects = filteredProj.sort((a, b) => a.projectId > b.projectId ? 1 : -1);
    }

    /**
     * expand/collapse all demands
     */
    public expandCollapseAll(): void {
        this.showCostDetails();
        this.showRevenueDetails();
    }

    /**
     * expand/collapse cost details section
     */
    public showCostDetails(): void {
        this.showCosts = !this.showCosts;
        for (const project of this.forecastDetails.projects) {
            project.demand.map((demandData) => demandData.showCosts = this.showCosts);
            project.demand.map((demandData) => demandData.isExpanded = this.showCosts || this.showRevenue || demandData.showRevenue);
        }
    }

    /**
     * expand/collapse revenue details section
     */
    public showRevenueDetails(): void {
        this.showRevenue = !this.showRevenue;
        for (const project of this.forecastDetails.projects) {
            project.demand.map((demandData) => demandData.showRevenue = this.showRevenue);
            project.demand.map((demandData) => demandData.isExpanded = this.showRevenue || this.showCosts || demandData.showCosts);
        }
    }

    /**
     * Show or hide forecast recommendations
     *
     * @memberof LaborDetailsComponent
     */
    public showForecastRecommendations(): void {
        // TODO: when ready for full integration, if there are no recs show popup message to indicate same to user
        this.showRecommendations = !this.showRecommendations;
    }

    /*
     * triggers on next button click and shows the next weeks schedule
    */
    public nextWeeks(): void {
        this.startIndex = this.endIndex;
        this.endIndex = (this.startIndex + this.maxWeeksDisplay) < this.weeklySchedules.length ? (this.startIndex + this.maxWeeksDisplay) : this.weeklySchedules.length;
        this.getSlicedWeek();
    }

    /*
     * triggers on previous button click and shows the previous weeks schedule
    */
    public prevWeeks(): void {
        this.endIndex = this.startIndex;
        this.startIndex = (this.endIndex - this.maxWeeksDisplay) < 0 ? 0 : this.endIndex - this.maxWeeksDisplay;
        this.getSlicedWeek();
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     */
    public onStartDateChange(startDate: Date): void {
        this.requestStartDate = moment(startDate).day() === 6 ? moment(startDate).add(1, "d").toDate() : startDate;
        const weekStartDate = moment(this.requestStartDate).startOf("week").isoWeekday(6);
        if (this.weeklySchedules && this.weeklySchedules.length) {
            this.startIndex = this.weeklySchedules.findIndex((weeklySchedule) => moment(weeklySchedule.startDate).isSame(moment(weekStartDate)));
            this.endIndex = (this.startIndex + this.maxWeeksDisplay) < this.weeklySchedules.length ? (this.startIndex + this.maxWeeksDisplay) : this.weeklySchedules.length;
        }
        this.getSlicedWeek();
    }

    /**
     * set grid column width as per the week type
     */
    public getGridColumn(type: string, index: number): object {
        let firstValue = 1;
        let lastValue = this.endIndex;
        if (type === "forecast") {
            if (this.startIndex > this.currentWeekIndex) {
                lastValue = this.endIndex - this.startIndex + 1;
            } else {
                firstValue = index + 1;
                lastValue = firstValue + (this.endIndex - firstValue) + 1;
            }
        } else if (type === "previous") {
            lastValue = this.comparisonIndex + 1;
            if (this.endIndex < this.currentWeekIndex) {
                lastValue = this.endIndex + 1;
            }
        } else {
            firstValue = this.endIndex + 1;
            lastValue = this.maxWeeksDisplay + 1;
        }
        return {
            "grid-column": `${firstValue}/${lastValue}`
        };
    }

    /**
     * set grid template column for weeks
     */
    public getGridTemplateColumn(): object {
        const value = this.endIndex - this.startIndex;
        return {
            "grid-template-columns": `repeat(${value}, 1fr)`
        };
    }

    /**
     * on changes number of weeks from weeks dropdown
     */
    public onWeeksChange(selectedWeekCount: number): void {
        const selecteNumberOfWeeks = this.weekDisplayListOptions.filter((selectedNumber) => selectedNumber.value === selectedWeekCount);
        if (selecteNumberOfWeeks && selecteNumberOfWeeks.length) {
            this.selectedNumberOfWeeks = selecteNumberOfWeeks[0];
        }
        this.endIndex = selectedWeekCount;
        this.maxWeeksDisplay = this.endIndex;
        this.initializeData();
    }

    /**
     * closes the demand details dropdown when hover out
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public closeDemandDetailsDropdown(event: any, demandDetailsDropdown: NgbDropdown): void {
        if (event && event.toElement) {
            const elementId = event.toElement.id;
            if (elementId && elementId.indexOf("demand-details-dropdown") > -1) {
                demandDetailsDropdown.open();
            } else {
                demandDetailsDropdown.close();
            }
        }
    }

    /**
     * set grid template column for labor grid
     */
    public getGridTemplateColumnForLaborGrid(): object {
        const weeksGridLength = this.maxWeeksDisplay + "fr";
        if (this.showForecastDetails) {
            return {
                "grid-template-columns": `1fr 40px 3fr 1fr 60px 2fr ${weeksGridLength} 1.5fr 1.5fr 1.5fr`
            };
        }
        return {
            "grid-template-columns": `1fr 40px 4fr 1fr 2fr 2fr ${weeksGridLength}`
        };
    }


    /**
     * set top position for sticky header of request grid 
     */
    public setTopPositionForStickyHeader(): string {
        // added height of staffing command bar to sticky header below staffing command bar as it is also sticky. 
        if (document.getElementById("secondary-naviagtion")) {
            return document.getElementById("secondary-naviagtion").clientHeight + 1 + "px";
        } else {
            return "";
        }
    }

    /*
     * triggers when edit forecast button is click   
    */
    public editForecast(): void {
        this.isForcastEditable = true;
        for (const demand of this.demandDetails) {
            if (demand) {
                if (demand.eac && demand.eac.isEdited && demand.eac.schedules && demand.eac.schedules.length) {
                    demand.eac.schedules.map((schedule) => schedule.isEdited = false);
                    demand.eac.isEdited = false;
                }
            }
        }
    }

    /*
     * Triggers when resource details is clicked
    */
    public async loadResourceDetails(resourceInfo: IResource, demand: IDemand): Promise<void> {
        // const resourceDetailsFromOneProfile: IOneProfileAPIResponse = await this.oneProfileService.getProfileBasedOnBpid(resourceInfo.businessPartnerId);
        let resourceDetails: IResourceDetails;
        if (resourceInfo) {
            resourceDetails = {
                alias: resourceInfo.alias,
                businessPartnerId: resourceInfo.businessPartnerId,
                fullName: resourceInfo.resourceName,
                grmDemandId: demand.sapDemandId,
                resourceRequestId: resourceInfo.resourceRequestId,
                domain: "Azure Cloud & AI",
                resourceLocation: resourceInfo.resourceLocationDescription,
                deliveryLocation: resourceInfo.resourceLocationDescription,
                billingRoleId: resourceInfo.resourceType,
                plannedRole: demand.roleDescription,
                resourceId: resourceInfo.resourceId,
                resourceType: resourceInfo.resourceType,
                resourceFinancials: this.getResourceFinancials(resourceInfo, demand),
                resourcePhoto: demand.resources[0].resourcePhoto,
                personDetails: demand.resources[0].personDetails
            };
        } else {
            resourceDetails = {
                grmDemandId: demand.sapDemandId,
                domain: "Azure Cloud & AI",
                resourceLocation: demand.resourceLocationDescription,
                deliveryLocation: demand.resourceLocationDescription,
                billingRoleId: demand.resourceType,
                plannedRole: demand.roleDescription,
                resourceType: demand.resourceType,
                resourceFinancials: this.getDemandFinancials(demand)
            };
        }

        const modalRef = this.modalService.open(ResourceDetailsModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 in plan-resource-detail-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.resourceDetails = resourceDetails;
        modalRef.componentInstance.currency = this.forecastDetails.currency;
    }


    /**
     * Revert schedule hours
     */
    public revertForecast(): void {
        this.changedForecastDetails = [];
        for (const demand of this.demandDetails) {
            if (demand) {
                if (demand.eac && demand.eac.isEdited && demand.eac.schedules && demand.eac.schedules.length) {
                    demand.eac.schedules.map((schedule) => schedule.isEdited = false);
                    this.revertSchedules(demand.eac.schedules);
                    demand.eac.cost = this.getCosts(demand.eac.schedules);
                    demand.eac.hours = this.getHours(demand.eac.schedules);
                    demand.eac.revenue = demand.eac.hours * demand.billRate;
                    demand.eac.isEdited = false;
                }
                demand.successPercentage = this.showForecastDetails && demand.planned && demand.planned.hours !== 0 ? (demand.eac.hours / demand.planned.hours) * 100 : 0;
            }
        }
        this.emitFilteredDemands.emit(this.demandDetails);
    }

    /**
     * Stop Editing schedule hours
     */
    public stopEditingForecast(): void {
        this.revertForecast();
        this.isForcastEditable = false;
    }

    /**
     * Save schedules
     */
    public saveForecast(): void {
        this.projectService.updateForecast(this.changedForecastDetails, this.forecastDetails.engagementId).then(() => {
            this.changedForecastDetails = [];
            this.fxpMessageService.addMessage("Forecast changes are saved successfully", FxpConstants.messageType.success);
            this.isForcastEditable = false;
            this.forecastDetails.projects = this.selectedProjectForecastItems;
            this.onProjectsSelect();
            this.getDemandsAndSchedules();
            if (this.forecastDetails && this.forecastDetails.projects && this.forecastDetails.projects.length) {
                for (const project of this.forecastDetails.projects) {
                    project.isExpanded = true;
                    project.demand.map((demandData) => demandData.isExpanded = this.showCosts || this.showRevenue || demandData.showCosts || demandData.showRevenue);
                    project.demand.map((demandData) => demandData.isExpanded = false);
                    project.demand.map((demandData) => demandData.showCosts = false);
                    project.demand.map((demandData) => demandData.showRevenue = false);
                    const demandWithResources = project.demand.filter((demand) => demand.resources.length);
                    if (demandWithResources && demandWithResources.length) {
                        demandWithResources.map((demandData) => this.getImage(demandData));
                        demandWithResources.map((demandData) => this.getPersonDetails(demandData));
                    }
                }
                if (this.demandDetails && this.demandDetails.length) {
                    for (const demand of this.demandDetails) {
                        if (demand) {
                            if (demand.eac && demand.eac.schedules && demand.eac.schedules.length) {
                                demand.eac.schedules.map((schedule) => schedule.isEdited = false);
                                demand.eac.isEdited = false;
                            }
                        }
                    }
                }
            }
        }).catch(() => {
            this.fxpMessageService.addMessage("Failed to update forecast changes", FxpConstants.messageType.error);
        });
    }

    /**
     * Change schedule hours
     */
    public onHourChange(weekDetails: ISchedule, demandId: number): void {
        const index = this.changedForecastDetails.findIndex((weeksch) => weeksch.scheduleId === weekDetails.scheduleId);
        if (index > -1) {
            if (weekDetails.hours !== this.changedForecastDetails[index].hours) {
                this.changedForecastDetails[index].hours = weekDetails.hours ? weekDetails.hours : 0;
                weekDetails.isEdited = true;
            }
        } else {
            this.changedForecastDetails.push({
                scheduleId: weekDetails.scheduleId,
                hours: weekDetails.hours ? weekDetails.hours : 0
            });
            weekDetails.isEdited = true;
        }
        const filteredDemand = this.demandDetails.filter((demand) => demand.id === demandId);
        if (filteredDemand && filteredDemand.length) {
            if (filteredDemand[0].eac) {
                filteredDemand[0].eac.cost = this.getCosts(filteredDemand[0].eac.schedules);
                filteredDemand[0].eac.hours = this.getHours(filteredDemand[0].eac.schedules);
                filteredDemand[0].eac.revenue = filteredDemand[0].eac.hours * filteredDemand[0].billRate;
                filteredDemand[0].eac.isEdited = true;
            }
            if (filteredDemand[0].planned) {
                filteredDemand[0].planned.cost = this.getCosts(filteredDemand[0].planned.schedules);
                filteredDemand[0].planned.hours = this.getHours(filteredDemand[0].planned.schedules);
                filteredDemand[0].planned.revenue = filteredDemand[0].planned.hours * filteredDemand[0].billRate;
            }
            if (filteredDemand[0].actuals) {
                filteredDemand[0].actuals.cost = this.getCosts(filteredDemand[0].actuals.schedules);
                filteredDemand[0].actuals.hours = this.getHours(filteredDemand[0].actuals.schedules);
                filteredDemand[0].actuals.revenue = filteredDemand[0].actuals.hours * filteredDemand[0].billRate;
            }
            filteredDemand[0].successPercentage = this.showForecastDetails && filteredDemand[0].planned && filteredDemand[0].planned.hours !== 0 ? (filteredDemand[0].eac.hours / filteredDemand[0].planned.hours) * 100 : 0;
        }
        this.emitFilteredDemands.emit(this.demandDetails);
    }

    /**
     * gets the percentage value for cost progress
     */
    public getCostsProgress(demandDetail: { id: number; billRate: number; eac: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; planned: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; actuals: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; successPercentage?: number }): number {
        if (this.showForecastDetails && demandDetail && demandDetail.eac && demandDetail.eac.cost && demandDetail.planned && demandDetail.planned.cost !== 0) {
            return (demandDetail.eac.cost / demandDetail.planned.cost) * 100;
        }
        return 0;
    }

    /**
     * gets the percentage value for revenue progress
     */
    public getRevenueProgress(demandDetail: { id: number; billRate: number; eac: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; planned: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; actuals: { schedules: ISchedule[]; cost: number; hours: number; revenue: number; isEdited?: boolean }; successPercentage?: number }, billRate: number): number {
        if (this.showForecastDetails && demandDetail && demandDetail.eac && demandDetail.eac.hours && demandDetail.planned && demandDetail.planned.hours !== 0 && billRate !== 0) {
            return ((demandDetail.eac.hours * billRate) / (demandDetail.planned.hours * billRate)) * 100;
        }
        return 0;
    }

    /**
     * gets the total cost for eac and planned
     */
    public getCosts(schedules: ISchedule[]): number {
        let cost = 0;
        for (const schedule of schedules) {
            cost += schedule.hours * schedule.costRate;
        }
        return cost;
    }

    /**
     * gets the total hours for eac and planned
     */
    public getHours(schedules: ISchedule[]): number {
        let hours = 0;
        for (const schedule of schedules) {
            hours += schedule.hours;
        }
        return hours;
    }

    /**
     * gets the total hours for eac and planned
     */
    public getCostAndHours(schedules: ISchedule[]): ICostHours {
        let hours = 0;
        let cost = 0;
        for (const schedule of schedules) {
            hours += schedule.hours;
            cost += schedule.hours * schedule.costRate;
            this.originalDemands.push({
                scheduleId: schedule.scheduleId,
                hours: schedule.hours
            });
        }
        const costHours: ICostHours = {
            hours,
            cost
        };
        return costHours;
    }

    /**
     * Get the schedules to be stored when clicked on copy
     */
    public getSchedules(demandDetails: IDemand, project: IProject): void {
        project.hasSchedulesCopied = true;
        demandDetails.hasSchedulesCopied = true;
        const currentWeekStartDate = moment(new Date()).startOf("week").isoWeekday(6).toDate();
        if (demandDetails.resources && demandDetails.resources.length) {
            this.copiedSchedules = demandDetails.resources[0].schedules.filter((schedule) => schedule.version === 4 && moment(schedule.startDate).isSameOrAfter(moment(currentWeekStartDate)));
        } else {
            this.copiedSchedules = demandDetails.schedules.filter((schedule) => schedule.version === 4 && moment(schedule.startDate).isSameOrAfter(moment(currentWeekStartDate)));
        }
    }

    /**
     * Get the schedules to be stored when clicked on copy
     */
    public copySchedules(demandDetails: IDemand, project: IProject): void {
        const currentWeekStartDate = moment(new Date()).startOf("week").isoWeekday(6).toDate();
        let filterdSchedules: ISchedule[] = [];
        if (demandDetails.resources && demandDetails.resources.length) {
            filterdSchedules = demandDetails.resources[0].schedules.filter((schedule) => schedule.version === 4 && moment(schedule.startDate).isSameOrAfter(moment(currentWeekStartDate)));
        } else {
            filterdSchedules = demandDetails.schedules.filter((schedule) => schedule.version === 4 && moment(schedule.startDate).isSameOrAfter(moment(currentWeekStartDate)));
        }
        filterdSchedules.sort((a, b) => new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf());
        this.copiedSchedules.sort((a, b) => new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf());
        for (let i = 0; i < this.copiedSchedules.length; i++) {
            filterdSchedules[i].hours = this.copiedSchedules[i].hours;
            filterdSchedules[i].isEdited = true;
            this.changedForecastDetails.push({
                scheduleId: this.copiedSchedules[i].scheduleId,
                hours: this.copiedSchedules[i].hours
            });
        }
        project.hasSchedulesCopied = false;
        project.demand.forEach((demandObject) => {
            demandObject.hasSchedulesCopied = false;
        });
        const filteredDemand = this.demandDetails.filter((demand) => demand.id === demandDetails.demandId);
        if (filteredDemand && filteredDemand.length) {
            if (filteredDemand[0].eac) {
                filteredDemand[0].eac.cost = this.getCosts(filteredDemand[0].eac.schedules);
                filteredDemand[0].eac.hours = this.getHours(filteredDemand[0].eac.schedules);
                filteredDemand[0].eac.revenue = filteredDemand[0].eac.hours * filteredDemand[0].billRate;
                filteredDemand[0].eac.isEdited = true;
            }
            if (filteredDemand[0].planned) {
                filteredDemand[0].planned.cost = this.getCosts(filteredDemand[0].planned.schedules);
                filteredDemand[0].planned.hours = this.getHours(filteredDemand[0].planned.schedules);
                filteredDemand[0].planned.revenue = filteredDemand[0].planned.hours * filteredDemand[0].billRate;
            }
            if (filteredDemand[0].actuals) {
                filteredDemand[0].actuals.cost = this.getCosts(filteredDemand[0].actuals.schedules);
                filteredDemand[0].actuals.hours = this.getHours(filteredDemand[0].actuals.schedules);
                filteredDemand[0].actuals.revenue = filteredDemand[0].actuals.hours * filteredDemand[0].billRate;
            }
            filteredDemand[0].successPercentage = this.showForecastDetails && filteredDemand[0].planned && filteredDemand[0].planned.hours !== 0 ? (filteredDemand[0].eac.hours / filteredDemand[0].planned.hours) * 100 : 0;
        }
        this.emitFilteredDemands.emit(this.demandDetails);
    }

    /**
     * Apply the forecast recommendations for a given demand/resource
     *
     * @param {IDemand} demandDetails
     * @memberof LaborDetailsComponent
     */
    public applyForecastRecommendations(demandDetails: IDemand): void {
        const currentWeekStartDate = moment(new Date()).startOf("week").isoWeekday(6).toDate();
        const filteredSchedules = demandDetails.schedules.filter((schedule) => schedule.version === 4 && moment(schedule.startDate).isSameOrAfter(moment(currentWeekStartDate)));

        if (demandDetails.resources && demandDetails.resources.length) {
            demandDetails.resources.forEach((resource) => {
                const filteredResourceSchedules = resource.schedules.filter((schedule) => schedule.version === 4 && moment(schedule.startDate).isSameOrAfter(moment(currentWeekStartDate)));

                filteredResourceSchedules.map((schedule) => {
                    if (schedule.recommendedHours && schedule.hours !== schedule.recommendedHours) {
                        schedule.hours = schedule.recommendedHours;
                        this.onHourChange(schedule, demandDetails.demandId); // TODO: Check if when we want to perform updates on the resource or demand schedules?
                    }
                });
            });
        } else {
            filteredSchedules.map((schedule) => {
                if (schedule.recommendedHours && schedule.hours !== schedule.recommendedHours) {
                    schedule.hours = schedule.recommendedHours;
                    this.onHourChange(schedule, demandDetails.demandId);
                }
            });
        }
    }

    /**
     * Bulk update forecast schedules
     */
    public bulkUpdateSchedules(demandDetails: IDemand): void {
        const currentWeekStartDate = moment(new Date()).startOf("week").isoWeekday(6).toDate();
        const filterdSchedules = demandDetails.schedules.filter((schedule) => schedule.version === 4 && moment(schedule.startDate).isSameOrAfter(moment(currentWeekStartDate)));
        filterdSchedules.sort((a, b) => new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf());
        const filteredDemand = this.demandDetails.filter((demand) => demand.id === demandDetails.demandId);
        const modalInstance = this.modalService.open(BulkUpdateSchedulesModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 in",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalInstance.componentInstance.forecastSchedules = filterdSchedules;
        modalInstance.componentInstance.etcHours = filteredDemand[0].eac.hours - filteredDemand[0].actuals.hours;
        modalInstance.result.then((response: IBulkScheduleUpdateResult) => {
            if (response && response.bulkScheduleUpdateOption) {
                switch (response.bulkScheduleUpdateOption) {
                    case BulkUpdateScheduleOptions.EvenlySpreadAcrossDuration: {
                        this.updateSchedulesAcrossDuration(demandDetails, response);
                        this.updateDemandDetails(demandDetails.demandId);
                        break;
                    }
                    case BulkUpdateScheduleOptions.SpecificHoursPerWeek: {
                        this.updateSchedulesSpecificHours(demandDetails, response);
                        this.updateDemandDetails(demandDetails.demandId);
                        break;
                    }
                }
            }
        });
    }

    /**
     *  get person details
     */
    public getPersonDetails(demandData: IDemand): void {
        if (demandData && demandData.resources && demandData.resources.length && demandData.resources[0].alias && !demandData.resources[0].personDetails) {
            const personDetails: IPersonDetails = {
                displayName: demandData.resources[0].resourceName,
                alias: demandData.resources[0].alias
            };
            personDetails.mail = demandData.resources[0].alias + this.emailPostFix;
            if (this.loggedInUserInfo) {
                if (this.loggedInUserInfo.userAlias && demandData.resources[0].alias && demandData.resources[0].alias.toLowerCase() !== this.loggedInUserInfo.userAlias.toLowerCase()) {
                    personDetails.userPrincipalName = demandData.resources[0].alias + this.emailPostFix;
                }
            }
            personDetails.jobTitle = demandData.resources[0].roleDescription;
            demandData.resources[0].personDetails = personDetails;
        }
    }

    /**
     * trackby function to improve ng for functionality on the UI
     *
     * @param {*} index
     * @param {*} item
     * @returns
     * @memberof ActualsComponent
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public trackByFunction(index: number, item: any): number {
        if (!item) {
            return null;
        }
        return index;
    }

    private updateDemandDetails(demandId: number) {
        const filteredDemand = this.demandDetails.filter((demand) => demand.id === demandId);
        if (filteredDemand && filteredDemand.length) {
            if (filteredDemand[0].eac) {
                filteredDemand[0].eac.cost = this.getCosts(filteredDemand[0].eac.schedules);
                filteredDemand[0].eac.hours = this.getHours(filteredDemand[0].eac.schedules);
                filteredDemand[0].eac.revenue = filteredDemand[0].eac.hours * filteredDemand[0].billRate;
                filteredDemand[0].eac.isEdited = true;
            }
            if (filteredDemand[0].planned) {
                filteredDemand[0].planned.cost = this.getCosts(filteredDemand[0].planned.schedules);
                filteredDemand[0].planned.hours = this.getHours(filteredDemand[0].planned.schedules);
                filteredDemand[0].planned.revenue = filteredDemand[0].planned.hours * filteredDemand[0].billRate;
            }
            if (filteredDemand[0].actuals) {
                filteredDemand[0].actuals.cost = this.getCosts(filteredDemand[0].actuals.schedules);
                filteredDemand[0].actuals.hours = this.getHours(filteredDemand[0].actuals.schedules);
                filteredDemand[0].actuals.revenue = filteredDemand[0].actuals.hours * filteredDemand[0].billRate;
            }
            filteredDemand[0].successPercentage = this.showForecastDetails && filteredDemand[0].planned && filteredDemand[0].planned.hours !== 0 ? (filteredDemand[0].eac.hours / filteredDemand[0].planned.hours) * 100 : 0;
        }
        this.emitFilteredDemands.emit(this.demandDetails);
    }

    /**
     * Update Schedules across duration   
    */
    private updateSchedulesSpecificHours(demandDetails: IDemand, response: IBulkScheduleUpdateResult) {
        if (demandDetails.resources && demandDetails.resources.length) {
            demandDetails.resources[0].schedules.filter((schedule) => schedule.version === 4 && schedule.startDate >= response.weekStarting && schedule.endDate <= response.weekEnding)
                .forEach((schdeule) => (
                    schdeule.hours = response.hours,
                    schdeule.isEdited = true,
                    this.changedForecastDetails.push({
                        scheduleId: schdeule.scheduleId,
                        hours: response.hours
                    })
                ));

        } else {
            demandDetails.schedules.filter((schedule) => schedule.version === 4 && schedule.startDate >= response.weekStarting && schedule.endDate <= response.weekEnding)
                .forEach((schdeule) => (
                    schdeule.hours = response.hours,
                    schdeule.isEdited = true,
                    this.changedForecastDetails.push({
                        scheduleId: schdeule.scheduleId,
                        hours: response.hours
                    })
                ));
        }
    }

    /**
     * Update Schedules across duration   
    */
    private updateSchedulesAcrossDuration(demandDetails: IDemand, response: IBulkScheduleUpdateResult) {
        const hoursToDistribute = (response.hours / (demandDetails.schedules.filter((schedule) => schedule.startDate >= response.weekStarting && schedule.endDate <= response.weekEnding).length)).toFixed(0);
        if (demandDetails.resources && demandDetails.resources.length) {
            demandDetails.resources[0].schedules.filter((schedule) => schedule.version === 4 && schedule.startDate >= response.weekStarting && schedule.endDate <= response.weekEnding)
                .forEach((schdeule) => (
                    schdeule.hours = Number(hoursToDistribute),
                    schdeule.isEdited = true,
                    this.changedForecastDetails.push({
                        scheduleId: schdeule.scheduleId,
                        hours: Number(hoursToDistribute)
                    })
                ));

        } else {
            demandDetails.schedules.filter((schedule) => schedule.version === 4 && schedule.startDate >= response.weekStarting && schedule.endDate <= response.weekEnding)
                .forEach((schdeule) => (
                    schdeule.hours = Number(hoursToDistribute),
                    schdeule.isEdited = true,
                    this.changedForecastDetails.push({
                        scheduleId: schdeule.scheduleId,
                        hours: Number(hoursToDistribute)
                    })
                ));
        }
    }

    /**
     * prepare the weeks details to show on UI    
    */
    private getSlicedWeek(): void {
        this.showPreviousBtn = this.startIndex !== 0;
        this.showNextBtn = this.endIndex < this.weeklySchedules.length;
        this.comparisonIndex = this.currentWeekIndex - this.startIndex;
    }

    /**
     * Gets filter dropdown data for projects filter based on project data from engagement details.
     *
     * @private
     * @param {IProjectDetailsV2[]} projects
     * @returns {FilterModel[]}
     * @memberof ActualsComponent
     */
    private getFilterDropdownData(projects: IProject[]): FilterModel[] {
        const projectNodes: FilterModel[] = [];
        for (const project of projects) {            
            const projectNode = new FilterModel(project.name, project.projectId);
            projectNodes.push(projectNode);            
        }
        return projectNodes;
    }

    /**
     * intialize the data    
     */
    private initializeData(): void {
        this.loggedInUserInfo = this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr();
        this.showCosts = false;
        this.showRevenue = false;
        this.successPercentage = 0;
        this.isForcastEditable = false;
        this.weeksToShowBeforeCurrentWeek = this.configManagerService.getValue<number>("weeksToShowBeforeCurrentWeek");
        if (this.forecastDetails) {
            this.requestStartDate = this.forecastDetails.startDate;
            this.requestEndDate = this.forecastDetails.endDate;
            this.maxEndDate = this.forecastDetails.endDate;
            this.minStartDate = this.forecastDetails.startDate;
            this.populateWeeklySchedules();
            if (this.forecastDetails.projects.length) {
                if (this.showForecastDetails) {
                    this.selectedProjectForecastItems = [...this.forecastDetails.projects];
                } else if (this.showCfpDetails) {
                    this.selectedProjectCfpItems = [...this.forecastDetails.projects];
                } else {
                    this.selectedProjectDbItems = [...this.forecastDetails.projects];
                }
                this.filterDataSource = this.getFilterDropdownData(this.forecastDetails.projects);
                this.selectedItems = this.filterDataSource;                
                this.demandDetails = [];
                for (const project of this.forecastDetails.projects) {                
                    project.isExpanded = true;                    
                    for (const demand of project.demand) {
                        demand.isExpanded = false;
                        demand.showRevenue = false;
                        demand.showCosts = false;
                        if (demand.resources.length) {
                            this.getImage(demand);
                            this.getPersonDetails(demand);
                        }
                        let forecastSchedules = demand.schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Forecast);
                        let plannedSchedules = demand.schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Planned);
                        let forecastResourceScheules = [];
                        let actualsResourceScheules = [];
                        if (demand.resources && demand.resources.length) {
                            forecastResourceScheules = demand.resources[0].schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Forecast);
                            actualsResourceScheules = demand.resources[0].schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Actuals);
                        }
                        forecastSchedules = this.updateScheduleWeeks(forecastSchedules);
                        plannedSchedules = this.updateScheduleWeeks(plannedSchedules);
                        forecastResourceScheules = this.updateScheduleWeeks(forecastResourceScheules);
                        actualsResourceScheules = this.updateScheduleWeeks(actualsResourceScheules);
                        let eacHourCosts: ICostHours;                      
                        const plannedHourCosts = this.getCostAndHours(plannedSchedules);
                        const actualHoursCosts = this.getCostAndHours(actualsResourceScheules);                        
                        if (forecastResourceScheules && forecastResourceScheules.length) {
                            eacHourCosts = this.getCostAndHours(forecastResourceScheules);
                        } else {
                            eacHourCosts = this.getCostAndHours(forecastSchedules);
                        }
                        /// Do Cost Calculations and Hour Calculations in single method.
                        this.demandDetails.push({
                            id: demand.demandId,
                            billRate: demand.billRate,
                            eac: {
                                schedules: demand.resources && demand.resources.length ? forecastResourceScheules : forecastSchedules,
                                cost: eacHourCosts.cost,
                                hours: Number(eacHourCosts.hours.toFixed(0)),
                                revenue: eacHourCosts.hours * demand.billRate,
                                isEdited: false
                            },
                            planned: {
                                schedules: plannedSchedules,
                                cost: plannedHourCosts.cost,
                                hours: Number(plannedHourCosts.hours.toFixed(0)),
                                revenue: plannedHourCosts.hours * demand.billRate,
                                isEdited: false
                            },
                            actuals: {
                                schedules: actualsResourceScheules,
                                cost: actualHoursCosts.cost,
                                hours: Number(actualHoursCosts.hours.toFixed(0)),
                                revenue: actualHoursCosts.hours * demand.billRate,
                                isEdited: false
                            },
                            successPercentage: this.showForecastDetails && plannedHourCosts.hours !== 0 ? (eacHourCosts.hours / plannedHourCosts.hours) * 100 : 0
                        });                       
                    }              
                }
                this.emitFilteredDemands.emit(this.demandDetails);
            }            
            this.getSlicedWeek();            
        }
    }

    /**
     * get image by user alias.
     */
    private getImage(demandData: IDemand): void {
        if (demandData && demandData.resources && demandData.resources.length && demandData.resources[0].alias && !demandData.resources[0].resourcePhoto) {
            this.aadGraphService.getResourceThumbnailPicture(demandData.resources[0].alias).then((imageData) => {
                if (imageData) {
                    demandData.resources[0].resourcePhoto = this.domSanitizer.sanitize(SecurityContext.URL, "data:image/jpg;base64," + imageData);
                }
            }).catch(() => {
                Promise.resolve();
            });
        }
    }

    /**
     * Close the Modal Dialog and nullfy the assingment List
     */
    private populateWeeklySchedules() {
        this.eacHours = 0;
        this.plannedHours = 0;
        let weekNumber = 1;
        this.currentWeekStartDate = moment(new Date()).startOf("week").isoWeekday(6).toDate();
        let weekStartDate = moment(this.requestStartDate).startOf("week").isoWeekday(6);
        let weekEndDate = moment(weekStartDate).add(6, "d");
        const totalWeeks = moment(this.requestEndDate).diff(moment(this.requestStartDate), "week");
        const weeks = [];
        while (weekNumber <= totalWeeks) {
            weeks.push({
                weekNumber,
                startDate: weekStartDate.toDate(),
                endDate: weekEndDate.toDate()
            });
            weekStartDate = moment(weekEndDate).add(1, "d");
            weekEndDate = moment(weekStartDate).add(6, "d");
            weekNumber++;
        }
        if (moment(weeks[weeks.length - 1].endDate).isBefore(moment(this.requestEndDate))) {
            weeks.push({
                weekNumber,
                startDate: weekStartDate.toDate(),
                endDate: weekEndDate.toDate()
            });
        }
        this.weeklySchedules = weeks;
        this.currentWeekIndex = this.weeklySchedules.findIndex((weeklySchedule) => moment(weeklySchedule.startDate).isSame(moment(this.currentWeekStartDate)));
        this.startIndex = this.currentWeekIndex - this.weeksToShowBeforeCurrentWeek;
        if (this.startIndex < 0) {
            this.startIndex = 0;
        }
        this.endIndex = (this.startIndex + this.maxWeeksDisplay) < this.weeklySchedules.length ? (this.startIndex + this.maxWeeksDisplay) : this.weeklySchedules.length;        
    }

    /**
     * Get Demand and Schedules
     */
    private getDemandsAndSchedules(): void {
        if (this.forecastDetails && this.forecastDetails.projects) {
            let demands = [];
            this.demandDetails = [];
            for (const project of this.forecastDetails.projects) {
                if (this.showForecastDetails) {
                    demands = project.demand.filter((demand) => demand.planVersionType === PlanVersionType.Forecast);
                } else if (this.showCfpDetails) {
                    demands = project.demand.filter((demand) => demand.planVersionType === PlanVersionType.CurrentFinancialPlan);
                } else {
                    demands = project.demand.filter((demand) => demand.planVersionType === PlanVersionType.DeliveryBaseline);
                }
                if (demands.length) {
                    for (const demand of demands) {
                        let forecastSchedules = demand.schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Forecast);
                        let plannedSchedules = demand.schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Planned);
                        let forecastResourceScheules = [];
                        let actualsResourceScheules = [];
                        if (demand.resources && demand.resources.length) {
                            forecastResourceScheules = demand.resources[0].schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Forecast);
                            actualsResourceScheules = demand.resources[0].schedules.filter((schedule) => schedule.staffVersionType === StaffVersionType.Actuals);
                        }
                        forecastSchedules = this.updateScheduleWeeks(forecastSchedules);
                        plannedSchedules = this.updateScheduleWeeks(plannedSchedules);
                        forecastResourceScheules = this.updateScheduleWeeks(forecastResourceScheules);
                        actualsResourceScheules = this.updateScheduleWeeks(actualsResourceScheules);
                        let eacHour = this.getHours(forecastSchedules);
                        const plannedHour = this.getHours(plannedSchedules);
                        const actualHours = this.getHours(actualsResourceScheules);
                        if (forecastResourceScheules && forecastResourceScheules.length) {
                            eacHour = this.getHours(forecastResourceScheules);
                        }
                        this.demandDetails.push({
                            id: demand.demandId,
                            billRate: demand.billRate,
                            eac: {
                                schedules: demand.resources && demand.resources.length ? forecastResourceScheules : forecastSchedules,
                                cost: demand.resources && demand.resources.length ? this.getCosts(forecastResourceScheules) : this.getCosts(forecastSchedules),
                                hours: Number(eacHour.toFixed(0)),
                                revenue: eacHour * demand.billRate,
                                isEdited: false
                            },
                            planned: {
                                schedules: plannedSchedules,
                                cost: this.getCosts(plannedSchedules),
                                hours: Number(plannedHour.toFixed(0)),
                                revenue: plannedHour * demand.billRate,
                                isEdited: false
                            },
                            actuals: {
                                schedules: actualsResourceScheules,
                                cost: this.getCosts(actualsResourceScheules),
                                hours: Number(actualHours.toFixed(0)),
                                revenue: actualHours * demand.billRate,
                                isEdited: false
                            },
                            successPercentage: this.showForecastDetails && plannedHour !== 0 ? (eacHour / plannedHour) * 100 : 0
                        });
                        if (forecastSchedules && forecastSchedules.length) {
                            for (const schedule of forecastSchedules) {
                                this.originalDemands.push({
                                    scheduleId: schedule.scheduleId,
                                    hours: schedule.hours
                                });
                            }
                        }
                        if (plannedSchedules && plannedSchedules.length) {
                            for (const schedule of plannedSchedules) {
                                this.originalDemands.push({
                                    scheduleId: schedule.scheduleId,
                                    hours: schedule.hours
                                });
                            }
                        }
                        if (actualsResourceScheules && actualsResourceScheules.length) {
                            for (const schedule of actualsResourceScheules) {
                                this.originalDemands.push({
                                    scheduleId: schedule.scheduleId,
                                    hours: schedule.hours
                                });
                            }
                        }
                        if (forecastResourceScheules && forecastResourceScheules.length) {
                            for (const schedule of forecastResourceScheules) {
                                this.originalDemands.push({
                                    scheduleId: schedule.scheduleId,
                                    hours: schedule.hours
                                });
                            }
                        }
                    }
                }
            }
            this.emitFilteredDemands.emit(this.demandDetails);
        }
    }

    /**
     * if schedules are not present for engagement weeks then adding hours as 0 for those weeks
     */
    private updateScheduleWeeks(allSchedules: ISchedule[]): ISchedule[] {
        if (allSchedules && allSchedules.length && this.weeklySchedules && this.weeklySchedules.length && allSchedules.length !== this.weeklySchedules.length) {
            let startIndex = this.weeklySchedules.findIndex((weeklySchedule) => moment(weeklySchedule.startDate).isSame(moment(allSchedules[0].startDate))) - 1;
            while (startIndex >= 0) {
                allSchedules.unshift({
                    costRate: 0,
                    endDate: this.weeklySchedules[startIndex].endDate,
                    hours: 0,
                    scheduleId: null,
                    staffVersionType: allSchedules[0].staffVersionType,
                    startDate: this.weeklySchedules[startIndex].startDate,
                    version: allSchedules[0].version
                });
                startIndex--;
            }
            let endIndex = this.weeklySchedules.findIndex((weeklySchedule) => moment(weeklySchedule.endDate).isSame(moment(allSchedules[allSchedules.length - 1].endDate))) + 1;
            while (endIndex < this.weeklySchedules.length) {
                allSchedules.push({
                    costRate: 0,
                    endDate: this.weeklySchedules[endIndex].endDate,
                    hours: 0,
                    scheduleId: null,
                    staffVersionType: allSchedules[0].staffVersionType,
                    startDate: this.weeklySchedules[endIndex].startDate,
                    version: allSchedules[0].version
                });
                endIndex++;
            }
        }
        return allSchedules;
    }

    /**
     * revert schedules
     */
    private revertSchedules(schedules: ISchedule[]): void {
        if (schedules && schedules.length) {
            for (const origSchedule of this.originalDemands) {
                const filterdSchedule = schedules.filter((schedule) => schedule.scheduleId === origSchedule.scheduleId);
                if (filterdSchedule && filterdSchedule.length) {
                    filterdSchedule[0].hours = origSchedule.hours;
                }
            }
        }
    }

    private getResourceFinancials(resourceInfo: IResource, demand: IDemand): IResourceFinancials[] {
        const resourceFinancials: IResourceFinancials[] = [];
        const plannedSchedules = demand.schedules.filter((d) => d.version === 1);
        if (plannedSchedules.length) {
            const plannedFinancials: IResourceFinancials = {
                financialVersion: StaffVersionType.Planned,
                laborHours: plannedSchedules.map((d) => d.hours).reduce((sum, current) => sum + current, 0),
                costRate: plannedSchedules[0].costRate,
                cost: 0,
                billRate: demand.billRate,
                revenue: 0
            };
            plannedFinancials.cost = Number((plannedFinancials.laborHours * plannedFinancials.costRate).toFixed(2));
            plannedFinancials.revenue = Number((plannedFinancials.laborHours * plannedFinancials.billRate).toFixed(2));
            resourceFinancials.push(plannedFinancials);
        }
        const staffedSchedules = resourceInfo.schedules.filter((d) => d.version === 4);
        if (staffedSchedules.length) {
            const staffedFinancials: IResourceFinancials = {
                financialVersion: StaffVersionType.Staffed,
                laborHours: staffedSchedules.map((d) => d.hours).reduce((sum, current) => sum + current, 0),
                costRate: staffedSchedules[0].costRate,
                cost: 0,
                billRate: demand.billRate,
                revenue: 0
            };
            staffedFinancials.cost = Number((staffedFinancials.laborHours * staffedFinancials.costRate).toFixed(2));
            staffedFinancials.revenue = Number((staffedFinancials.laborHours * staffedFinancials.billRate).toFixed(2));
            resourceFinancials.push(staffedFinancials);
        }
        const actualSchedules = demand.schedules.filter((d) => d.version === 3);
        if (actualSchedules.length) {
            const actualFinancials: IResourceFinancials = {
                financialVersion: StaffVersionType.Actuals,
                laborHours: actualSchedules.map((d) => d.hours).reduce((sum, current) => sum + current, 0),
                costRate: actualSchedules[0].costRate,
                cost: 0,
                billRate: demand.billRate,
                revenue: 0
            };
            actualFinancials.cost = Number((actualFinancials.laborHours * actualFinancials.costRate).toFixed(2));
            actualFinancials.revenue = Number((actualFinancials.laborHours * actualFinancials.billRate).toFixed(2));
            resourceFinancials.push(actualFinancials);
        }
        const forecastSchedules = demand.schedules.filter((d) => d.version === 4);
        if (forecastSchedules.length) {
            const forecastFinancials: IResourceFinancials = {
                financialVersion: StaffVersionType.Forecast,
                laborHours: forecastSchedules.map((d) => d.hours).reduce((sum, current) => sum + current, 0),
                costRate: forecastSchedules[0].costRate,
                cost: 0,
                billRate: demand.billRate,
                revenue: 0
            };
            forecastFinancials.cost = Number((forecastFinancials.laborHours * forecastFinancials.costRate).toFixed(2));
            forecastFinancials.revenue = Number((forecastFinancials.laborHours * forecastFinancials.billRate).toFixed(2));
            resourceFinancials.push(forecastFinancials);
        }
        return resourceFinancials;
    }

    private getDemandFinancials(demand: IDemand): IResourceFinancials[] {
        const resourceFinancials: IResourceFinancials[] = [];
        const plannedSchedules = demand.schedules.filter((d) => d.version === 1);
        if (plannedSchedules.length) {
            const plannedFinancials: IResourceFinancials = {
                financialVersion: StaffVersionType.Planned,
                laborHours: plannedSchedules.map((d) => d.hours).reduce((sum, current) => sum + current, 0),
                costRate: plannedSchedules[0].costRate,
                cost: 0,
                billRate: demand.billRate,
                revenue: 0
            };
            plannedFinancials.cost = Number((plannedFinancials.laborHours * plannedFinancials.costRate).toFixed(2));
            plannedFinancials.revenue = Number((plannedFinancials.laborHours * plannedFinancials.billRate).toFixed(2));
            resourceFinancials.push(plannedFinancials);
        }
        const staffedFinancials: IResourceFinancials = {
            financialVersion: StaffVersionType.Staffed,
            laborHours: 0,
            costRate: 0,
            cost: 0,
            billRate: demand.billRate,
            revenue: 0
        };
        staffedFinancials.cost = Number((staffedFinancials.laborHours * staffedFinancials.costRate).toFixed(2));
        staffedFinancials.revenue = Number((staffedFinancials.laborHours * staffedFinancials.billRate).toFixed(2));
        resourceFinancials.push(staffedFinancials);

        const actualFinancials: IResourceFinancials = {
            financialVersion: StaffVersionType.Actuals,
            laborHours: 0,
            costRate: 0,
            cost: 0,
            billRate: demand.billRate,
            revenue: 0
        };
        actualFinancials.cost = Number((actualFinancials.laborHours * actualFinancials.costRate).toFixed(2));
        actualFinancials.revenue = Number((actualFinancials.laborHours * actualFinancials.billRate).toFixed(2));
        resourceFinancials.push(actualFinancials);

        const forecastSchedules = demand.schedules.filter((d) => d.version === 4);
        if (forecastSchedules.length) {
            const forecastFinancials: IResourceFinancials = {
                financialVersion: StaffVersionType.Forecast,
                laborHours: forecastSchedules.map((d) => d.hours).reduce((sum, current) => sum + current, 0),
                costRate: forecastSchedules[0].costRate,
                cost: 0,
                billRate: demand.billRate,
                revenue: 0
            };
            forecastFinancials.cost = Number((forecastFinancials.laborHours * forecastFinancials.costRate).toFixed(2));
            forecastFinancials.revenue = Number((forecastFinancials.laborHours * forecastFinancials.billRate).toFixed(2));
            resourceFinancials.push(forecastFinancials);
        }
        return resourceFinancials;
    }
}
