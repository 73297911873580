import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";

import { catchError, map, switchMap } from "rxjs/operators";
import { from as fromPromise, of } from "rxjs";
import { LoadFinancialRolesSuccess, LoadFinancialRolesFail, LoadFinancialRoles, FinancialRolesActionTypes } from "./financial-roles.action";
import { ProjectServiceFunctions } from "../../common/services/projectservice-functions.service";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class FinancialRolesEffects {

    @Effect()
    public loadFinancialRoles$ = this.actions$
        .ofType(FinancialRolesActionTypes.LOAD_FINANCIAL_ROLES)
        .pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap((action: LoadFinancialRoles) => fromPromise(this.projectServiceFunctions
                .getFinancialRoles())
                .pipe(
                    map((financialRoles: IFinancialRoles[]) => new LoadFinancialRolesSuccess(financialRoles)),
                    catchError((error) => {
                        if (error && error.data && error.InnerErrors && error.InnerErrors[0]) {
                            return of(new LoadFinancialRolesFail(error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error)));
                        }
                        const message: string = "Unable to retreive Financial Roles details from SAP API.";
                        return of(new LoadFinancialRolesFail(message + " CorrelationId: " + DataService.getCorrelationIdFromError(error)));
                    })
                )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions
    ) { }
}
