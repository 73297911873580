<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'180px'" [isLoading]="false" [showFooter]="false" [contentMarginBottom]="false">
    <!--Do not show default footer, the component below includes a custom footer-->
    <dm-engagement-validation-threshhold
    [isValidationFailedModal]="true"
    [validationMessage]="message"
    [cancelButtonText]="'Cancel'"
    [saveButtonText]="'Save and Continue'"
    [validationResultSet]="[validationResults]" 
    (closeAndUpdateBaselineClicked)="closeModal()"
    (continueClicked)="saveAndContinue()">
    </dm-engagement-validation-threshhold>
</dm-modal-v2>