import { Component, forwardRef, Inject } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../common/services/engagement-detail.service";
import { getEntireManageSuppliers } from "../../../store/manage-suppliers/manage-suppliers.selector";
import { IManageSuppliersState } from "../../../store/manage-suppliers/manage-suppliers.reducer";
import { IPoListViewModel } from "../../../common/services/contracts/poList.model.contract";
import { IState } from "../../../store/reducers";
import { LogEventConstants, RouteName, SourceConstants, Components, NoDataText, AccessibilityConstants } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";
import { ITile } from "../dm-tile/dm-tile.component";
import { DmError } from "../../../common/error.constants";

@Component({
    selector: "dm-purchase-orders-v2",
    templateUrl: "./purchase-orders-v2.html",
    styleUrls: ["./purchase-orders-v2.scss"]
})
export class PurchaseOrderV2Component extends DmComponentAbstract {
    public poOrders: IPoListViewModel[] = [];
    public myOrderLink: string;
    public noPurchaseOrdersText: string;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.ManageSuppliers;
    public accessibilityConstants = AccessibilityConstants;
    private engagementId: string;
    private projectId: string;
    private isProjectContext: boolean;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService
    ) {
        super(dmLogger, Components.PurchaseOrders);
    }

    public ngOnInit(): void {
        this.configManagerService.initialize();
        this.myOrderLink = this.configManagerService.getValue<string>("myOrderLink");
        this.noPurchaseOrdersText = NoDataText.NoPurchaseOrders;
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = this.projectId ? true : false;
        this.tileContent = {
            title: "Manage Suppliers"
        };
        this.storeDispatchService.requireManageSuppliers(this.engagementId, true).load();
        const manageSuppliers$ = this.store.select(getEntireManageSuppliers(this.engagementId));
        manageSuppliers$.subscribe((manageSuppliersState: IManageSuppliersState) => {
            this.refreshOnItemInvalidation(manageSuppliersState);
            this.setLoadersBasedOnItemState(manageSuppliersState);
            this.setErrorsBasedOnItemState(manageSuppliersState);
            if (manageSuppliersState.loaded) {
                this.poOrders = this.engagementDetailService.getPurchaseOrderViewModel(manageSuppliersState.manageSuppliers);
            }
            if (manageSuppliersState.error) {
                this.isServerError = true;
            }
        });
    }

    /**
     * Gets the correct route name for the manage suppliers page based on the context, project or engagement
     */
    public getManageSuppliersRouteName(): string {
        if (this.isProjectContext) {
            return RouteName.ProjectManageSuppliers;
        }
        return RouteName.EngagementManageSuppliers;
    }

    /**
     * Gets the correct route params for the manage suppliers page based on the context, project or engagement
     */
    public getManageSuppliersRouteParams(): { [key: string]: string } {
        if (this.isProjectContext) {
            return { projectId: this.projectId };
        }
        return { engagementId: this.engagementId };
    }

    /**
     * Logs events when purcase order link is clicked
     * @param entityType
     * @param link
     */
    public logPONumberClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.LogPONumberClick, LogEventConstants.PurchaseOrderLink);
    }

    /**
     * Logs events when manage suppliers link is clicked
     * @param entityType
     * @param link
     */
    public logSuppliersClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.LogSuppliersClick, LogEventConstants.ManagerSuppliersClick);
    }
}
