import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigManagerService } from "../../../../../../common/services/configmanager.service";
import { IExpenseDetail, IEntry, IUnapprovedExpense } from "../../../../../../common/services/contracts/unapproved-expense.contracts";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { Components, AccessibilityConstants, NoDataText } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-unapproved-expense-modal",
    templateUrl: "./unapproved-expense-modal.html",
    styleUrls: ["./unapproved-expense-modal.scss"],
})
export class UnapprovedExpenseComponent extends DmModalAbstract {

    @Input() public projectId: string;
    @Input() public unapprovedExpenseEntries: IExpenseDetail[];
    public isLoading: boolean = true;
    public noDatafoundText = NoDataText.NoDetailsFound;
    public concurUrl: string;
    public accessibilityConstants = AccessibilityConstants;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService
    ) {
        super(activeModal, dmLogger, Components.UnapprovedExpense);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ViewApprovals, true);
        this.isLoading = false;
        this.concurUrl = this.configurationService.getValue<string>("concurUrl");
    }

    /**
     * Collapse all sub entries on collapsing project data
     *
     * @param {IUnapprovedExpense} expdata
     * @memberof UnapprovedExpenseComponent
     */
    public collapseEntries(expdata: IUnapprovedExpense): void {
        expdata.isProjectExpanded = !expdata.isProjectExpanded;
        expdata.entries.forEach((entry: IEntry) => {
            entry.isSubcategoryExpanded = false;
        });
    }

    /**
     * Move focus to Next element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseButton);
        }
    }

}
