import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ActivityFeedItem } from "./activity-feed-item.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { KeyActionsV2Module } from "../key-actions-v2/key-actions-v2.module";


@NgModule({
    declarations: [
        ActivityFeedItem
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
        NgbModule,
        KeyActionsV2Module
    ],
    providers: [
        SharedFunctionsService,
        DMLoggerService
    ],
    entryComponents: [
        ActivityFeedItem
    ],
    exports: [
        ActivityFeedItem
    ]
})
export class ActivityFeedItemModule { }
