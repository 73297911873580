import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, AccessibilityConstants, WBSResponseMessages, BusinessTask } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../common/services/dmnotification.service";
import { IProjectDetailsV2, IServiceDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IWbsAddTaskDetails } from "../../../../common/services/contracts/wbs.service.contracts";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../common/services/wbs.service";
import moment from "moment";
import { DmError } from "../../../../common/error.constants";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, AbstractControl, Validators, FormBuilder } from "@angular/forms";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { INotificationMessages } from "../../../../common/services/contracts/financial.service.contracts";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { StateService } from "@uirouter/angular";
import { INavigationListItemAttribute } from "../../../../components/navigation/navigationlist.model";
import { ManageEbsService } from "../../../../common/services/manage-ebs.service";

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    templateUrl: "./add-task.html",
    styleUrls: ["./add-task.scss"]
})
export class AddTaskModalComponent extends DmModalAbstract {
    @Input() public selectedService: IServiceDetailsV2;
    @Input() public projectContextData: IProjectDetailsV2[];
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public isBeforeServiceStartDate: boolean = false;
    public isAfterServiceEndDate: boolean = false;
    public isEndDateBeforeStartDate: boolean = false;
    public minDate: Date;
    public maxDate: Date;
    public accessibilityConstants = AccessibilityConstants;
    public addTaskErrorMessages = DmError.EbsStructure.EditEbsStructure;
    public addTaskDetailsForm: FormGroup;
    public isAddingTask: boolean;
    public modalContent: IModal;
    public loadingText: string;

    public get taskName(): AbstractControl {
        return this.addTaskDetailsForm.get("taskName");
    }
    public get taskDescription(): AbstractControl {
        return this.addTaskDetailsForm.get("taskDescription");
    }
    public get taskStartDate(): AbstractControl {
        return this.addTaskDetailsForm.get("taskStartDate");
    }
    public get taskEndDate(): AbstractControl {
        return this.addTaskDetailsForm.get("taskEndDate");
    }

    private notificationMessage: INotificationMessages;
    private serviceResponseMessages: IResponseMessage;
    private readonly FXP_CONSTANTS = FxpConstants;
    private currentTab: INavigationListItemAttribute;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ManageEbsService) private manageEbsService: ManageEbsService
    ) {
        super(activeModal, dmLogger, Components.ManageEbsAddTask);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Add Task"
        };
        this.initializeAddTaskForm();
        this.configurationService.initialize().then(() => {
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
        });
        this.serviceResponseMessages = WBSResponseMessages.TaskAdded;
        this.minDate = this.selectedService.minDate;
        this.maxDate = this.selectedService.maxDate;
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param invalid
     */
    public saveButtonDisabled(invalid: boolean): boolean {
        const dates: boolean = this.isStartDateRequired ||
            this.isBeforeServiceStartDate ||
            this.isAfterServiceEndDate ||
            this.isEndDateRequired ||
            this.isEndDateBeforeStartDate;
        return invalid || dates;
    }

    /**
     * Adds the newly created task to the service by calling the API and refreshing the cache.
     */
    public addNewTask(): void {
        this.loadingText = "Adding Task";
        this.startBusinessProcessTelemetry(BusinessTask.AddOrRemoveTasks);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.AddNewTask, LogEventConstants.ManageEBSTaskAddSaveClick);
        const taskData: IWbsAddTaskDetails = {
            name: this.taskName.value,
            status: this.selectedService.statusCode,
            description: this.taskDescription.value,
            startDate: moment(this.taskStartDate.value).format(DATE_FORMAT),
            endDate: moment(this.taskEndDate.value).format(DATE_FORMAT)
        };
        this.isAddingTask = true;

        this.wbsService.addTaskDetails(taskData, this.selectedService.projectId, this.selectedService.id)
            .then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.AddNewTask, LogEventConstants.ManageEBSAddTask);
                this.currentTab = this.manageEbsService.getCurrentEbsTabToRefreshContents(this.stateService);
                if (this.currentTab && this.currentTab.refreshData) {
                    this.currentTab.refreshData();
                }
                if (!this.sharedFunctionsService.disableEmailAlertsNotifications()) {
                    let esxpNotification = this.notificationMessage.TaskAddedNotification;
                    esxpNotification = esxpNotification.replace("#", taskData.name);
                    const sendTo: string[] = this.sharedFunctionsService.getListofPjmProjectContextV2(this.projectContextData);
                    this.notificationService.sendESXPNotification(esxpNotification, sendTo);
                }
                let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                successMessage = successMessage.replace("#", taskData.name);
                this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                this.closeModal();
                this.endBusinessProcessTelemetry(BusinessTask.AddOrRemoveTasks);
            })
            .catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", taskData.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.AddNewTask, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isAddingTask = false;
            });
    }

    /**
     * Checks if the task's start date is valid
     */
    public isTaskStartDateValid(date: Date): void {
        this.taskStartDate.setValue(date);
        this.isStartDateRequired = false;
        if (!this.taskStartDate.value) {
            this.isStartDateRequired = true;
            return;
        }
        this.isBeforeServiceStartDate = false;

        if (moment(this.taskStartDate.value).isBefore(this.selectedService.startDate)) {
            this.isBeforeServiceStartDate = true;
        }
        this.isEndDateBeforeStartDate = this.isEndDateLessThanStartDate(this.taskStartDate.value, this.taskEndDate.value);
    }

    /**
     * Checks if the task's end date is valid
     */
    public isTaskEndDateValid(date: Date): void {
        this.taskEndDate.setValue(date);
        this.isEndDateRequired = false;
        if (!this.taskEndDate.value) {
            this.isEndDateRequired = true;
            return;
        }
        this.isAfterServiceEndDate = false;

        if (moment(this.taskEndDate.value).isAfter(this.selectedService.endDate)) {
            this.isAfterServiceEndDate = true;
        }

        this.isEndDateBeforeStartDate = this.isEndDateLessThanStartDate(this.taskStartDate.value, this.taskEndDate.value);
    }

    /**
     * Is the given end date less than the given start date? True if the end date is before the start date.
     * False if the end date is after the start date.
     */
    private isEndDateLessThanStartDate(startDate: Date, endDate: Date): boolean {
        if (startDate && endDate) {
            if (moment(endDate).isBefore(startDate)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Initializes contact form data
     */
    private initializeAddTaskForm(): void {
        this.addTaskDetailsForm = this.fb.group({
            taskName: ["", Validators.required],
            taskDescription: ["", Validators.required],
            taskStartDate: [this.selectedService.startDate, [Validators.required]],
            taskEndDate: [this.selectedService.endDate, [Validators.required]]
        });
    }
}
