import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "dm-refresh",
    templateUrl: "./dm-refresh.html",
    styleUrls: ["./dm-refresh.scss"]
})
export class DmRefreshComponent {
    @Input() public showSpinning: boolean;
    @Input() public enableRefresh: boolean;
    @Input() public loadingText: string;
    @Output() public onRrefresh?: EventEmitter<any> = new EventEmitter<any>();


    /**
     * Emitting Reload method to parent component to hit API and get latest response
     *
     * @memberof DmRefreshComponent
     */
    public onLoad(): void {
        this.onRrefresh.emit(true);
    }
}
