<section class="bulk-create-int-engagement">
  <section class="dm-tile">
    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0">
      <h3 class="dm-tile__header dm-p-b-10 dm-m-0">
        BULK CREATE INTERNAL ENGAGEMENT
      </h3>
    </section>
    <section class="bulk-create-int-engagement__status-summary-section display-flex-imp">
      <section class="dm-p-x-imp-10">
        <div class="dm-p-x-imp-10 display-inline-flex-imp">
          <div class="dm-p-x-imp-10 display-inline-flex-imp">
            <div class="icon icon-success icon-rounded dm-success"></div>
            <div class="bulk-create-int-engagement__status-summary-section-status-text dm-p-5">
              {{ validRecords?.length}}
              Valid </div>
          </div>
          <div class="dm-p-x-imp-10 display-inline-flex-imp">
            <span class="icon icon-exclamation icon-rounded dm-error"></span>
            <div class="bulk-create-int-engagement__status-summary-section-status-text dm-p-5">
              {{ invalidRecords?.length}} Invalid </div>
          </div>
          <div class="dm-p-x-imp-10 display-inline-flex-imp">
            <div class="icon record-progress-icon" *ngIf="inProgressRecords?.length"></div>
            <div class="bulk-create-int-engagement__status-summary-section-status-text">
              {{ inProgressRecords?.length}} In Progress </div>
          </div>
        </div>
        <span class="dm-p-x-20">|</span>
        <span class="bulk-create-int-engagement__status-summary-section-text font-caption-alt">
          Please correct and validate or discard the errors in the records below to complete your section.</span>
      </section>
      <section class="dm-p-x-10 bulk-create-int-engagement__status-summary-section-btn-holder">
        <button class="fxp-btn fxp-btn--secondary dm-m-x-10 set-focus" id="createBulkInternalEngagement" [disabled]="saveBtnDisabled"
          [title]="'Create is enabled only when all internal engagements are validated'"
          (click)="onCreateBulkInternalEngagements()">Create</button>
        <button class="fxp-btn fxp-btn--secondary dm-m-x-10 set-focus" id="cancelBulkInternalEngagement"
          (click)="openCancelBulkUploadModal(cancelModal)">Cancel</button>
      </section>
    </section>
    <section class="bulk-create-int-engagement__validator-section display-flex-imp">
      <section class="display-grid bulk-create-int-engagement__dropdown-container">
        <label class="font-caption" for="currentRecord">Current record</label>
        <section class="bulk-create-int-engagement__dropdown dropdown pull-right">
          <div data-toggle="dropdown" class="bulk-create-int-engagement__dropdown-title context-menu-button"
            id="navbarDropdownMenu">
            <div>
              <span *ngIf="selectedInternalEngagement?.status === 'ValidationFailed'"
                class="icon icon-exclamation icon-rounded dm-error"> </span>
              <span *ngIf="selectedInternalEngagement?.status === 'ValidationSuccessful'"
                class="icon icon-success icon-rounded dm-success"></span>
              <span
                *ngIf="selectedInternalEngagement?.status === 'QueuedForValidation' || selectedInternalEngagement?.status === 'ValidationInProgress'"
                class="icon record-progress-icon"></span>
            </div>
            <div class="bulk-create-int-engagement__dropdown-label">
              {{selectedInternalEngagement?.displayId}} . {{selectedInternalEngagement?.name}}</div>
            <div class="bulk-create-int-engagement__dropdown-icon icon icon-chevron-down"></div>
          </div>
          <div class="bulk-create-int-engagement__dropdown-menu dropdown-menu dropdown-menu-left font-caption-alt"
            aria-labelledby="navbarDropdownMenu">
            <div class="dropdown-item" *ngFor="let internalEngagement of internalEngagements"
              (click)="onInternalEngagementChange(internalEngagement)">
              <div>
                <span *ngIf="internalEngagement?.status === 'ValidationFailed'"
                  class="icon icon-exclamation icon-rounded dm-error"></span>
                <span *ngIf="internalEngagement?.status === 'ValidationSuccessful'"
                  class="icon icon-success icon-rounded dm-success"></span>
                <span
                  *ngIf="internalEngagement?.status === 'QueuedForValidation' || internalEngagement?.status === 'ValidationInProgress'"
                  class="icon record-progress-icon"></span></div>
              <span class="dm-p-x-10-y-5 display-inline-block text-bottom">{{internalEngagement?.displayId}} .
                {{internalEngagement?.name}}</span>
            </div>
          </div>
        </section>
      </section>
      <button class=" fxp-btn fxp-btn--primary popup-button dm-m-l-15-imp set-focus" [disabled]="validateBtnDisabled"
        (click)="validateRecord()">Validate</button>
      <button class="fxp-btn fxp-btn--secondary popup-button dm-m-l-15-imp set-focus" (click)="discardRecord()">
        Discard this record</button>
      <button class="fxp-btn fxp-btn--secondary popup-button dm-m-l-15-imp set-focus" [disabled]="discardAllBtnDisabled"
        (click)="discardAllErrorRecords()">
        Discard all errors</button>
      <!-- <button class="fxp-btn fxp-btn--secondary popup-button dm-m-l-15-imp" (click)="revertRecord()">
        Revert last record</button> -->
    </section>
  </section>
  <dm-new-internal-engagement-v2 [isBulkUploadProcess]="true">
  </dm-new-internal-engagement-v2>
  <ng-template #cancelModal let-modal>
    <div class="modal-body">
      <div class="modal-body-container">
        Are you sure you want to cancel the Bulk Engagement Creation process ?
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="fxp-btn fxp-btn--secondary set-focus" id="accessibilityConstants.Cancel" (click)="cancelModalRef.close()"
        (keydown)=" moveFocusNext($event, 'accessibilityConstants.Confirm' );">Cancel</button>
      <button class="fxp-btn fxp-btn--primary set-focus" id="accessibilityConstants.Confirm" type="button"
        (click)="goBackToPortfolio()" (keydown)="moveFocusNext($event, 'accessibilityConstants.Cancel');">Confirm</button>
    </div>
  </ng-template>
</section>