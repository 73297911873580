import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CceacComponent } from "./cceac.component";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        CceacComponent
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        CceacComponent
    ],
    exports: [
        CceacComponent
    ]
})
export class CceacModule { }
