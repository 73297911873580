import { Component, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Components, KeyAction, FeedBackEntity, InlineSurveyDataList, SourceConstants } from "../../common/application.constants";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FeedbackModalService } from "../tiles/feedback-modal/feedback-modal.service";
import { getEntireUserPreference } from "../../store/userspreferences/userpreference.selector";
import { IState } from "../../store/reducers";
import { IUserPreferenceState } from "../../store/userspreferences/userpreference.reducer";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
    selector: "dm-my-portfolio",
    templateUrl: "./my-portfolio.html",
    styleUrls: ["./my-portfolio.scss"],
})
export class MyPortfolioComponent extends DmComponentAbstract {
    public listOfKeyActions: string[] = [];
    private isComplexUnitsFeatureEnabled: boolean = false;

    public constructor(
        @Inject(Store) private store: Store<IState>,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService
    ) {
        super(dmLogger, Components.Portfolio, [{ component: Components.KeyActionsV2, isCritical: false },
            { component: Components.RecentlyViewed, isCritical: true },
            { component: Components.PortfolioGridData, isCritical: true },
            { component: Components.PortfolioInternalEngagementGridData, isCritical: true }]);
    }

    public ngOnInit(): void {
        this.listOfKeyActions.push(KeyAction.PendingResources, KeyAction.PendingLaborApproval, KeyAction.PendingExpenseReportsPortfolioContext, KeyAction.PendingEngagementsForRelease, KeyAction.ActiveMisalignemdContractualAmendments, KeyAction.SubconOnboardingStatus);
        this.isComplexUnitsFeatureEnabled = this.configurationService.isFeatureEnabled("enableComplexUnitsFeature");
        if (this.isComplexUnitsFeatureEnabled) {
            this.listOfKeyActions.push(KeyAction.PendingUnitApprovals);
        }
        const userPreference$ = this.store.select((getEntireUserPreference()));

        userPreference$
            .pipe(untilDestroyed(this))
            .subscribe((userPreferenceState: IUserPreferenceState) => {
                if (userPreferenceState.loaded) {
                    let isPortfolioFeedbackSubmitted: boolean = false;
                    const feedbackDetails =
                        userPreferenceState.userPreference.feedbackList;
                    if (
                        feedbackDetails &&
                        feedbackDetails.length > 0 &&
                        feedbackDetails.filter(
                            (obj) => obj.key === FeedBackEntity.Portfolio
                        ).length > 0
                    ) {
                        isPortfolioFeedbackSubmitted = feedbackDetails.filter(
                            (obj) => obj.key === FeedBackEntity.Portfolio
                        )[0].value;
                    }
                    if (
                        !isPortfolioFeedbackSubmitted &&
                        this.sharedFunctionsService.shouldLaunchFeedbackModal(
                            FeedBackEntity.Portfolio
                        )
                    ) {
                        this.feedbackModalService.openFeedbackModal(
                            InlineSurveyDataList.PortfolioV2Survey,
                            FeedBackEntity.Portfolio,
                            SourceConstants.Component.PortfolioPage
                        );
                    }
                }
            });
    }
}
