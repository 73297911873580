import { Component, Input } from "@angular/core";
import moment from "moment";
import { DateType, IDate, IDateProgress } from "./dm-date-progress-bar.contracts";

const dateFormat = "DD MMM YYYY";

@Component({
    selector: "dm-date-progress-bar",
    templateUrl: "./dm-date-progress-bar.html",
    styleUrls: ["./dm-date-progress-bar.scss"]
})
export class DmDateProgressBarComponent {
    @Input() public dateProgressBarData: IDateProgress;
    @Input() public progressBarWidth: string;
    public progressBar: IDate[] = [];
    public percentageCompleted: number;
    public DateType = DateType;
    public isEndDateBeforeToday: boolean;
    public isEndDateToday: boolean;
    public isStartDateToday: boolean;    

    public ngOnInit(): void {
        this.initializeData();
    }

    /**
     * Initializes data
     * @memberof
     */
    public initializeData(): void {
        if (this.dateProgressBarData) {
            this.dateProgressBarData.today = new Date();
            this.isStartDateToday = this.dateProgressBarData.startDate === this.dateProgressBarData.today;
            this.isEndDateToday = this.dateProgressBarData.endDate === this.dateProgressBarData.today;
            this.isEndDateBeforeToday = moment(this.dateProgressBarData.endDate).isBefore(this.dateProgressBarData.today);
            if (this.isStartDateToday || this.isEndDateToday || this.isEndDateBeforeToday) {
                this.progressBar = [{
                    type: this.isStartDateToday ? DateType.Today : DateType.StartDate,
                    stepDate: moment(this.dateProgressBarData.startDate).format(dateFormat)
                },               
                {
                    type: this.isEndDateToday ? DateType.Today : DateType.EndDate,
                    stepDate: moment(this.dateProgressBarData.endDate).format(dateFormat)
                }
                ];
            } else {
                this.progressBar = [{
                    type: DateType.StartDate,
                    stepDate: moment(this.dateProgressBarData.startDate).format(dateFormat)
                },
                {
                    type: DateType.Today,
                    stepDate: moment(this.dateProgressBarData.today).format(dateFormat)
                },
                {
                    type: DateType.EndDate,
                    stepDate: moment(this.dateProgressBarData.endDate).format(dateFormat)
                }
                ]; 
            }
            const distanceWhole = moment(this.dateProgressBarData.endDate).startOf("day").diff(moment(this.dateProgressBarData.startDate).startOf("day"), "days");
            const distanceLeft = moment(this.dateProgressBarData.endDate).startOf("day").diff(moment().startOf("day"), "days");
            this.percentageCompleted = ((distanceWhole - distanceLeft) / distanceWhole) * 100;
        }
    }

    public setStepWidth(step: IDate): string {
        if (step.type === DateType.Today && this.isStartDateToday) {
            return "0%";
        }
        if (step.type === DateType.StartDate) {
            return this.percentageCompleted + "%";
        }
        const percentageRemaining = (this.isStartDateToday || this.isEndDateToday)
            ? (100 - this.percentageCompleted) : (100 - this.percentageCompleted) / 2;
        return percentageRemaining + "%";
    }
}