import { Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

/**
 * Reusable dropdown component.
 * 
 * User can pass their own options if using strings for values.
 * Selected value will return string.
 *
 * <dm-dropdown-menu [name]="'viewby'" [id]="'viewby'" [(ngModel)]="viewby"
 *   (onFocus)="resetSearch(portfolioSearchModel)">
 *      <option value="all">
 *          View Engagements & Projects
 *      </option>
 *      <option value="engagements">
 *          View Engagements
 *      </option>
 *      <option value="projects">
 *          View Projects
 *      </option>
 * </dm-dropdown-menu>
 * 
 * User can also pass an array of objects if using objects for values.
 * Selected value will return object.
 * 
 * <dm-dropdown-menu [id]="'dm-wbs-projects-ddl'" [(ngModel)]="selectedProject" [options]="items"
 *   [labels]="'displayName'" [isDisabled]="isProjectContext" (change)="onSelectedProjectChanged()">
 *      <option [value]="placeholder">All projects</option>
 * </dm-dropdown-menu>
 *
 * @export
 * @class DmDropdownMenuComponent
 * @implements {ControlValueAccessor}
 */
@Component({
    selector: "dm-dropdown-menu",
    templateUrl: "./dm-dropdown-menu.html",
    styleUrls: ["./dm-dropdown-menu.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DmDropdownMenuComponent),
            multi: true
        }
    ]
})
export class DmDropdownMenuComponent implements ControlValueAccessor {

    @Input() public ariaLabel: string;
    @Input() public ariaLabelledBy: string;
    @Input() public id: string;
    @Input() public isDisabled: boolean = false;
    @Input() public isRequired: boolean = false;
    @Input() public isDropDownV2: boolean = false;
    @Input() public showErrorBorder: boolean = false;
    /**
     * The string value provided indicates the property name in
     * the object that should be used for option labels
     *
     * @type {string}
     * @memberof DmDropdownMenuComponent
     */
    @Input() public labels: string;
    @Input() public name: string;
    /**
     * An array of objects that will be used for
     * the select options
     *
     * @type {any[]}
     * @memberof DmDropdownMenuComponent
     */
    @Input() public options: any[] = [];
    @Input() public disabledoptions: string[] = [];
    // revisit dynamic class styling
    @Input() public selectNgClass;
    @Output() public onFocus: EventEmitter<any> = new EventEmitter();
    @Output() public valueChange = new EventEmitter<any>();
    public onChange: (...args: any[]) => void;
    public onTouched: (...args: any[]) => void;

    private selectedValue: any;

    @Input()
    public get value(): any {
        return this.selectedValue;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public set value(val: any) {
        this.selectedValue = val;
        this.valueChange.emit(this.selectedValue);
        if (this.onChange) {
            this.onChange(val);
        }
        if (this.onTouched) {
            this.onTouched(val);
        }
    }

    /**
     * Emits an event on component focus
     *
     */
    public focusWasChanged(): void {
        this.onFocus.emit();
    }

    /**
     * Writes a new value to the element.
     * 
     * Part of ControlValueAccessor interface.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public writeValue(value): void {
        if (value) {
            this.selectedValue = value;
        }
    }

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: (...args: any[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: (...args: any[]) => void): void {
        this.onTouched = fn;
    }
}