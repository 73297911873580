import { Injectable, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { INavigationListItemAttribute } from "../../components/navigation/navigationlist.model";
import { InvalidateActuals } from "../../store/actuals/actuals.action";
import { InvalidateContacts } from "../../store/contacts/contacts.action";
import { InvalidateEngagementChangeRequests } from "../../store/engagement-change-requests/engagement-change-requests.action";
import { InvalidateEngagementDetails } from "../../store/engagement-details/engagement-details.action";
import { InvalidateFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.action";
import { InvalidateInternalFinancialDetails } from "../../store/internal-financial-details/internal-financial-details.action";
import { InvalidateInvoices } from "../../store/invoices/invoices.action";
import { InvalidateManageSuppliers } from "../../store/manage-suppliers/manage-suppliers.action";
import { InvalidateMilestones } from "../../store/milestones/milestones.action";
import { InvalidateNpcActuals } from "../../store/npc-actuals/npc-actuals.action";
import { InvalidatePlanForecastParams } from "../../store/plan-forecast-params/plan-forecast-params.action";
import { InvalidateProjectDetails } from "../../store/project-details/project-details.action";
import { InvalidateResourceRequests } from "../../store/resource-requests/resource-requests.action";
import { InvalidateResourceRequestsProjectContext } from "../../store/resource-requests-project-context/resource-requests-project-context.action";
import { InvalidateWbsStructures } from "../../store/wbs-structures/wbs-structures.action";
import { IState } from "../../store/reducers";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { StaffingService } from "./staffing.service";
import { StoreDispatchService } from "./store-dispatch.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { APIConstants, Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";
import { InvalidateWbsForecastDetails } from "../../store/wbs-forecast-details/wbs-forecast.action";
import { InvalidateWbsCfpDetails } from "../../store/wbs-cfp-details/wbs-cfp.action";
import { InvalidateWbsDbDetails } from "../../store/wbs-db-details/wbs-db.action";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";

interface IForecastFlightingResponse {
    isInForecastFlight: boolean;
}
interface IPlanFlightingResponse {
    isInPlanFlight: boolean;
}

interface IForecastMigrationStatusResponse {
    isInMigrationFlight: boolean;
}

@Injectable()
export class NavigationService extends DmServiceAbstract {
    private projectServiceBaseUriv2: string;
    private subscriptionKey: string;
    public constructor(
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.NavigationService);
    }
    /**
     * Get the list of tabs to display for the engagement navigation bar. Additionally information about
     * which apis to load and reload on page refresh is here.
     */
    public getEngagementTabs(stateService: StateService, isNewForecastEnabled?: boolean, isNewPlanEnabled?: boolean): INavigationListItemAttribute[] {
        return [
            {
                name: "Summary",
                id: "engagement-summary-v2",
                routeName: "summaryv2",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .requireInvoices(engagementId, true)
                        .requireMilestones(engagementId, true)
                        .requirePlanForecastParams(engagementId, true)
                        .requireManageSuppliers(engagementId, true)
                        .requireEngagementStaffingDetails(engagementId, true)
                        .requireEngagementChangeRequests(engagementId, false)
                        .requireWbsStructures(engagementId, false)
                        .requireContacts(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateManageSuppliers(engagementId));
                    this.store.dispatch(new InvalidateResourceRequests(engagementId));
                    this.store.dispatch(new InvalidateEngagementChangeRequests(engagementId));
                    this.store.dispatch(new InvalidateContacts(engagementId));
                }
            },
            {
                name: "Financials",
                id: "engagement-financial",
                routeName: "financials",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requirePlanForecastParams(engagementId, true)
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .requireEngagementChangeRequests(engagementId, true)
                        .requireWbsDemandsV2WithoutSchedules(engagementId, true)
                        .requireInvoices(engagementId, false)
                        .requireMilestones(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .requireEngagementStaffingDetails(engagementId, true)
                        .requireWbsStructures(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateEngagementChangeRequests(engagementId)); // TODO: this needs to be updated to v2 when Virtuoso ready
                }
            },
            {
                name: isNewForecastEnabled || isNewPlanEnabled ? "Plan" : "Plan & Forecast",
                id: "plan-forecast",
                routeName: "planForecast",
                showTab: !isNewPlanEnabled,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requirePlanForecastParams(engagementId, true)
                        .requireEngagementDetails(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidatePlanForecastParams(engagementId));
                }
            },
            {
                name: "Plan & Forecast",
                id: "plan-forecast-v2",
                routeName: "planAndForecastv2",
                showTab: false,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .requireWbsForecastDetails(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateWbsForecastDetails(engagementId));
                    this.store.dispatch(new InvalidateWbsCfpDetails(engagementId));
                    this.store.dispatch(new InvalidateWbsDbDetails(engagementId));
                }
            },
            {
                name: isNewPlanEnabled ? "Plan & Forecast" : "Forecast",
                id: "forecast-v2",
                routeName: "engagementForecast",
                showTab: isNewForecastEnabled || isNewPlanEnabled ? true : false,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .requireWbsForecastDetails(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateWbsForecastDetails(engagementId));
                    this.store.dispatch(new InvalidateWbsCfpDetails(engagementId));
                    this.store.dispatch(new InvalidateWbsDbDetails(engagementId));
                }
            },
            {
                name: "Manage EBS",
                id: "engagement-manage-ebs",
                routeName: "ebs",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireWbsStructures(engagementId, false)
                        // consider dispatching getEntireResourceRequestsStateObject here because its used in the request state change modal, set as false
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                }
            },
            {
                name: "Staffing",
                id: "engagement-staffing",
                routeName: "staffing",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.staffingService.getEngagementStaffingData(engagementId);
                    this.storeDispatchService
                        .requireWbsStructures(engagementId, true)
                        .requirePlanForecastParams(engagementId, false)
                        .requireInvoices(engagementId, false)
                        .requireMilestones(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .requireFinancialDetailsV2(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateWbsStructures(engagementId));
                }
            },
            {
                name: "Units",
                id: "engagement-actuals",
                routeName: "units",
                showTab: false,
                loadData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireActuals(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateActuals(engagementId));
                }
            },
            {
                name: "NPM",
                id: "engagement-npc",
                routeName: "nonProcuredMaterials",
                showTab: false,
                loadData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireNpcActuals(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateNpcActuals(engagementId));
                }
            },
            {
                name: "Manage Suppliers",
                id: "engagement-manage-suppliers",
                routeName: "manageSuppliers",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requirePlanForecastParams(engagementId, true)
                        .requireEngagementDetails(engagementId, true)
                        .requireManageSuppliers(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, false)
                        .requireInvoices(engagementId, false)
                        .requireMilestones(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .requireWbsStructures(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateManageSuppliers(engagementId));
                }
            },
            {
                name: "Milestones",
                id: "engagement-milestones",
                routeName: "milestones",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requirePlanForecastParams(engagementId, true)
                        .requireEngagementDetails(engagementId, true)
                        .requireMilestones(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, false)
                        .requireInvoices(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .requireEngagementStaffingDetails(engagementId, false)
                        .requireWbsStructures(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                }
            },
            {
                name: "Customer Invoices",
                id: "engagement-invoices",
                routeName: "invoices",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requirePlanForecastParams(engagementId, false)
                        .requireEngagementDetails(engagementId, true)
                        .requireInvoices(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .requireMilestones(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .requireWbsStructures(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateManageSuppliers(engagementId));
                }
            },
            {
                name: "Amendments",
                id: "amendment-details",
                routeName: "amendmentDetails",
                showTab: false,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                }
            },
            {
                name: "Misaligned Amendments",
                id: "misaligned-amendments",
                routeName: "misalignedAmendmentDetails",
                showTab: false,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireWbsDemandsV2WithoutSchedules(engagementId, true)
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                }
            },
            {
                name: "Contact Details",
                id: "contact-details",
                routeName: "contactDetails",
                showTab: false,
                loadData: () => {
                    const wbsId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.storeDispatchService
                        .requireContacts(wbsId, true)
                        .load();
                },
                refreshData: () => {
                    const wbsId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateContacts(wbsId));
                }
            },
            {
                name: "Instructions",
                id: "workbook-instructions",
                routeName: "workbook-instructions",
                showTab: false
            },
            {
                name: "WbsDetails",
                id: "engagement-wbs-details",
                routeName: "wbsdetails",
                showTab: false,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, true)
                        .requireWbsStructures(engagementId, false)
                        .requireContacts(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateContacts(engagementId));
                }
            }
            // add safe limits here
        ];
    }

    /**
     * Get the list of tabs to display for the project navigation bar. Additionally information about
     * which apis to load and reload on page refresh is here.
     */
    public getProjectTabs(stateService: StateService, isNewForecastEnabled?: boolean, isNewPlanEnabled?: boolean): INavigationListItemAttribute[] {
        return [
            {
                name: "Summary",
                id: "project-summary-v2",
                routeName: "summaryv2",
                showTab: true,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireProjectDetails(projectId, true)
                        .requireFinancialDetailsV2(projectId, true)
                        .requireInvoices(projectId, true)
                        .requireMilestones(engagementId, true)
                        .requireManageSuppliers(engagementId, true)
                        .requireProjectStaffingDetails(projectId, true)
                        .requireContacts(engagementId, true)
                        .requireEngagementChangeRequests(engagementId, false) /* This is loaded early because it is used on the financial tab */
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.store.dispatch(new InvalidateProjectDetails(projectId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(projectId));
                    this.store.dispatch(new InvalidateResourceRequestsProjectContext(projectId));
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateManageSuppliers(engagementId));
                    this.store.dispatch(new InvalidateContacts(engagementId));
                    this.store.dispatch(new InvalidateEngagementChangeRequests(engagementId));
                }
            },
            {
                name: "Financials",
                id: "project-financial",
                routeName: "financials",
                showTab: true,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireProjectDetails(projectId, true)
                        .requireEngagementDetails(engagementId, true)
                        .requireFinancialDetailsV2(projectId, true)
                        .requireEngagementChangeRequests(engagementId, true)
                        .requireWbsDemandsV2WithoutSchedules(projectId, false)
                        .requireProjectStaffingDetails(projectId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.store.dispatch(new InvalidateProjectDetails(projectId));
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(projectId));
                }
            },
            {
                name: isNewForecastEnabled || isNewPlanEnabled ? "Plan" : "Plan & Forecast",
                id: "plan-forecast",
                routeName: "planForecast",
                showTab: !isNewPlanEnabled,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireEngagementDetails(projectId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.store.dispatch(new InvalidatePlanForecastParams(projectId));
                }
            },
            {
                name: isNewPlanEnabled ? "Plan & Forecast" : "Forecast",
                id: "forecast-v2",
                routeName: "projectForecast",
                showTab: isNewForecastEnabled || isNewPlanEnabled ? true : false,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireEngagementDetails(projectId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.store.dispatch(new InvalidatePlanForecastParams(projectId));
                }
            },
            {
                name: "Manage EBS",
                id: "project-manage-ebs",
                routeName: "ebs",
                showTab: true,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    this.storeDispatchService
                        .requireEngagementDetails(projectId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                }
            },
            {
                name: "Staffing",
                id: "project-staffing",
                routeName: "staffing",
                showTab: true,
                loadData: (toParams: any) => {
                    const projectId = toParams.projectId;
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireProjectDetails(projectId, true)
                        .requireProjectStaffingDetails(projectId, true)
                        .requireEngagementDetails(projectId, false)
                        .requireInvoices(engagementId, false)
                        .requireMilestones(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .requireEngagementStaffingDetails(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const projectId = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.store.dispatch(new InvalidateWbsStructures(projectId));
                    this.store.dispatch(new InvalidateResourceRequestsProjectContext(projectId));
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                }
            },
            {
                name: "Units",
                id: "project-actuals",
                routeName: "units",
                showTab: false,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    this.storeDispatchService
                        .requireEngagementDetails(projectId, true)
                        .requireActuals(projectId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                    this.store.dispatch(new InvalidateActuals(projectId));
                }
            },
            {
                name: "NPM",
                id: "project-npc",
                routeName: "nonProcuredMaterials",
                showTab: false,
                loadData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.storeDispatchService
                        .requireEngagementDetails(projectId, true)
                        .requireNpcActuals(projectId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                    this.store.dispatch(new InvalidateNpcActuals(projectId));
                }
            },
            {
                name: "Manage Suppliers",
                id: "project-manage-suppliers",
                routeName: "manageSuppliers",
                showTab: true,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireEngagementDetails(engagementId, true)
                        .requireManageSuppliers(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, false) // Todo Deba: After V2 API integraiton, make requireImmediately = True
                        .requireInvoices(engagementId, false)
                        .requireMilestones(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateManageSuppliers(engagementId));
                }
            },
            {
                name: "Milestones",
                id: "project-milestones",
                routeName: "milestones",
                showTab: true,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireEngagementDetails(projectId, true)
                        .requireMilestones(engagementId, true)
                        .requireFinancialDetailsV2(engagementId, false)
                        .requireInvoices(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .requireEngagementStaffingDetails(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                }
            },
            {
                name: "Customer Invoices",
                id: "project-invoices",
                routeName: "invoices",
                showTab: true,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, false)
                        .requireEngagementDetails(engagementId, true)
                        .requireInvoices(engagementId, true)
                        .requireFinancialDetailsV2(projectId, true)
                        .requireMilestones(engagementId, false)
                        .requireManageSuppliers(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateInvoices(engagementId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(projectId));
                    this.store.dispatch(new InvalidateMilestones(engagementId));
                    this.store.dispatch(new InvalidateManageSuppliers(engagementId));
                }
            },
            {
                name: "Amendments",
                id: "amendment-details",
                routeName: "amendmentDetails",
                showTab: false,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    this.storeDispatchService
                        .requireEngagementDetails(projectId, true)
                        .requireFinancialDetailsV2(projectId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(projectId));
                }
            },
            {
                name: "Contact Details",
                id: "contact-details",
                routeName: "contactDetails",
                showTab: false,
                loadData: () => {
                    const wbsId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.storeDispatchService
                        .requireContacts(wbsId, true)
                        .load();
                },
                refreshData: () => {
                    const wbsId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateContacts(wbsId));
                }
            },
            {
                name: "Snapshots",
                id: "snapshot-details",
                routeName: "snapshotDetails",
                showTab: false
            },
            {
                name: "Instructions",
                id: "workbook-instructions",
                routeName: "workbook-instructions",
                showTab: false
            },
            {
                name: "WbsDetails",
                id: "project-wbsdetails-v2",
                routeName: "wbsdetails",
                showTab: false,
                loadData: (toParams: any) => {
                    const projectId: string = toParams.projectId;
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.storeDispatchService
                        .requirePlanForecastParams(projectId, true)
                        .requireProjectDetails(projectId, true)
                        .requireFinancialDetailsV2(projectId, true)
                        .requireContacts(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const projectId: string = this.sharedFunctionsService.getSelectedProjectId(stateService);
                    const engagementId: string = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                    this.store.dispatch(new InvalidateProjectDetails(projectId));
                    this.store.dispatch(new InvalidateFinancialDetailsV2(projectId));
                    this.store.dispatch(new InvalidateContacts(engagementId));
                }
            }
            // add safelimits here
        ];
    }

    /**
     * Get the list of tabs to display for the internal engagement navigation bar. Additionally information about
     * which apis to load and reload on page refresh is here.
     */
    public getInternalEngagementTabs(stateService: StateService): INavigationListItemAttribute[] {
        return [
            {
                name: "Summary",
                id: "internal-summary",
                routeName: "summary",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(engagementId, true)
                        .requireEngagementStaffingDetails(engagementId, false)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateResourceRequests(engagementId));
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                }
            },
            {
                name: "Financials",
                id: "internal-financial",
                routeName: "financials",
                showTab: true,
                loadData: (toParams: any) => {
                    const selectedInternalEngagementId = toParams.engagementId;
                    this.storeDispatchService
                        .requireEngagementDetails(selectedInternalEngagementId, true)
                        .requireInternalFinancialDetails(selectedInternalEngagementId, true)
                        .requireEngagementStaffingDetails(selectedInternalEngagementId, false)
                        .load();
                    /* This tab does not call the requireFinancialDetails API because it instead relies on the bulk financials API that does not live in the store */
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                    this.store.dispatch(new InvalidateInternalFinancialDetails(engagementId));
                }
            },
            {
                name: "Staffing",
                id: "internal-staffing",
                routeName: "staffing",
                showTab: true,
                loadData: (toParams: any) => {
                    const engagementId: string = toParams.engagementId;
                    this.staffingService.getEngagementStaffingData(engagementId);
                    this.storeDispatchService
                        .requireWbsStructures(engagementId, true)
                        .load();
                },
                refreshData: () => {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(stateService);
                    this.store.dispatch(new InvalidateWbsStructures(engagementId));
                }
            }
        ];
    }

    public isNewForecastEnabled(wbsId: string): Promise<IForecastFlightingResponse> {
        this.projectServiceBaseUriv2 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        const url = `${this.projectServiceBaseUriv2}/forecast/flighting?wbsId=${wbsId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetForecastFlightingDetails);
    }

    public isNewPlanEnabled(wbsId: string): Promise<IPlanFlightingResponse> {
        const planServiceBaseUri = this.configManagerService.getValue<string>("planServiceBaseUri");
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        const url = `${planServiceBaseUri}plan/flighting?wbsId=${wbsId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetPlanFlightingDetails);
    }

    public isMigrationInProgress(wbsId: string): Promise<IForecastMigrationStatusResponse> {
        const forecastServiceBaseUri = this.configManagerService.getValue<string>("forecastServiceBaseUri");
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");

        const url = `${forecastServiceBaseUri}forecast/flighting/migration?wbsId=${wbsId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetForecastFlightingMigrationStatus);
    }
}
