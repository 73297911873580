import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { AADGraphService } from "../../../common/services/aad-graphapi.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { RequestsGridComponent } from "./requests-grid.component";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ResourceStaffingService } from "../../../common/services/resource-staffing.service";


@NgModule({
    declarations: [
        RequestsGridComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        UIRouterModule
    ],
    providers: [
        AADGraphService,
        ConfigManagerService,
        DMLoggerService,
        ResourceStaffingService
    ],
    entryComponents: [
        RequestsGridComponent
    ],
    exports: [
        RequestsGridComponent
    ]
})

export class RequestsGridModule { }
