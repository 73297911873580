import { Component, forwardRef, Inject } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { Components, ComponentFailureMessages, RouteName } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IState } from "../../../store/reducers";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { getEntireContacts } from "../../../store/contacts/contacts.selector";
import { IContactsState } from "../../../store/contacts/contacts.reducer";
import { IContactsResponse } from "../../../common/services/contracts/contacts.contracts";
import { ContactsService } from "../../../common/services/contacts.service";
import { ITile } from "../dm-tile/dm-tile.component";
import { DmError } from "../../../common/error.constants";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";

/**
 * DmContactsTileComponent
 *
 * @export
 * @class DmContactsTileComponent
 */
@Component({
    selector: "dm-contacts-v2",
    templateUrl: "./dm-contacts-tile-v2.html",
    styleUrls: ["./dm-contacts-tile-v2.scss"],
})
export class DmContactsTileV2Component extends DmComponentAbstract {
    public contactsList: IContactsResponse;
    public RouteName = RouteName;
    public isProjectContext: boolean;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.Contact;
    public tileContent: ITile;
    /**
     * Creates an instance of Contacts tile component.
     * @param {DeviceFactoryProvider} deviceFactory
     * @memberof InvoicesComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ContactsService) private contactsService: ContactsService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(dmLogger, Components.ContactDetails);
    }

    public ngOnInit(): void {
        this.errorText = ComponentFailureMessages.ContactsComponent;
        const selectedId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = projectId ? true : false;       
        this.tileContent = {
            title: "Customer Contacts",
            itemsCount: 0
        };
        
        this.storeDispatchService
            .requireContacts(selectedId, true).load();
        const contacts$ = this.store.select(getEntireContacts(selectedId));
        contacts$.pipe(untilDestroyed(this)).subscribe((contacts: IContactsState) => {
            this.setLoadersBasedOnItemState(contacts);
            this.setErrorsBasedOnItemState(contacts);
            this.refreshOnItemInvalidation(contacts);
            if (contacts.loaded) {
                this.contactsList = contacts.contactsResponse;                         
                this.contactsList.engagementContacts = this.contactsService.updateContactsTypeAndPriority(this.contactsList.engagementContacts);
                // Contacts are displayed based on contact priority i.e contact with higher value is displayed on top. 
                this.sharedFunctionsService.sortListByPropertyBasedOnOrder("priority", false, this.contactsList.engagementContacts, "name");
                if (this.contactsList && this.contactsList.engagementContacts) {
                    this.tileContent.itemsCount = this.contactsList.engagementContacts.length;
                }
            }
            if (contacts.error) {
                this.isServerError = true;
            }
        });
       
    }
}
