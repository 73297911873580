import { Component, Input, Inject, forwardRef } from "@angular/core";
import { UserProfileService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer } from "@angular/platform-browser";

import { AADGraphService } from "../../../../../../common/services/aad-graphapi.service";
import { ConfigManagerService } from "../../../../../../common/services/configmanager.service";
import { IUnapprovedLaborList } from "../../../../../../common/services/contracts/unapproved-labor.contracts";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { Components, AccessibilityConstants, NoDataText } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { IProjectDetailsV2, IEngagementDetailsV2 } from "../../../../../../common/services/contracts/wbs-details-v2.contracts";

@Component({
    selector: "dm-unapproved-labor-modal",
    templateUrl: "./unapproved-labor-modal.html",
    styleUrls: ["./unapproved-labor-modal.scss"],
})
export class UnapprovedLaborComponent extends DmModalAbstract {

    @Input() public unapprovedLaborList: IUnapprovedLaborList[];
    @Input() public projectId: string;
    @Input() public engagementDetails: IEngagementDetailsV2;
    @Input() public resources: string[];
    public chronosUrl: string;
    public isLoading: boolean = true;
    public msApprovalsUrl: string;
    public noDatafoundText: string = "No details found";
    public defaultProject: { id: string; name: string } = { id: "", name: "" };
    public selectedProject: { id: string; name: string } = this.defaultProject; // todo add interface based on API response
    public selectedProjectDisabled: boolean = false;
    public selectedResource: any = ""; // todo add interface based on API response    
    public totalProjectHours: number = 0;
    public totalWeekHours: number = 0;
    public accessibilityConstants = AccessibilityConstants;
    public noPendingLabor = NoDataText.NoPendingLabor;

    public constructor(
        @Inject(forwardRef(() => UserProfileService)) public userProfileService: UserProfileService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService
    ) {
        super(activeModal, dmLogger, Components.UnapprovedLabor);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ViewApprovals, true);
        this.chronosUrl = this.configurationService.getValue<string>("chronosUrl");
        this.msApprovalsUrl = this.configurationService.getValue<string>("msApprovalsPendingUrl");
        this.isLoading = false;
        if (this.projectId) {
            if (this.unapprovedLaborList && this.unapprovedLaborList.length > 0) {
                this.unapprovedLaborList = this.unapprovedLaborList.filter((item) => item.projectId = this.projectId);
            }
            this.selectedProjectDisabled = true;
            this.selectedProject = this.engagementDetails.projects.filter((project: IProjectDetailsV2) => project.id = this.projectId)[0] ? this.engagementDetails.projects.filter((project: IProjectDetailsV2) => project.id = this.projectId)[0] : this.defaultProject;
        }
        /// Bind ResourceImages and Details
        this.retrieveResourceImagesandDetails();
    }

    /**
     * Close Modal Popup
     * @memberof UnapprovedLaborComponent
     */
    public closeModal(): void {
        this.unapprovedLaborList = [];
        super.closeModal();
    }

    /**
     * Move focus to Next element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseButton);
        }
    }

    /**
     * For  Bind ResourceImages and Details
     *
     * @memberof UnapprovedLaborComponent
     */
    private retrieveResourceImagesandDetails(): void {
        if (this.resources && this.resources.length) {
            this.resources.forEach((resource) => {
                this.getResourceDetails(resource);
                this.getResourceImages(resource);
            });
        }
    }

    /**
     * Get resource thumb pictures and bind it to object.
     * @param assignedResource
     */
    private getResourceImages(assignedResource: string): void {
        this.aadGraphService.getResourceThumbnailPicture(assignedResource)
            .then((resourcePhotoResponse) => {
                if (resourcePhotoResponse) {
                    this.bindImagesToUnApprovedLaborList(resourcePhotoResponse, assignedResource);
                }
            });
    }

    /**
     * Bind Resource Images to UnApproved LaborList.
     * @param assignedResource
     */
    private bindImagesToUnApprovedLaborList(response, resourceAlias: string): void {
        if (this.unapprovedLaborList && this.unapprovedLaborList.length) {
            this.unapprovedLaborList.forEach((entity) => {
                if (entity.laborWeeklyData && entity.laborWeeklyData.length) {
                    entity.laborWeeklyData.forEach((laborData) => {
                        if (laborData.laborDetails && laborData.laborDetails.length) {
                            laborData.laborDetails.forEach((laborDetail) => {
                                if (laborDetail.resourceAlias === resourceAlias) {
                                    laborDetail.resourceImage = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + response);
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    /**
     * Get resource thumb pictures and add it to array.
     * @param assignedResource
     */
    private getResourceDetails(assignedResource: string): void {
        this.userProfileService.getBasicProfileByAlias(assignedResource).then((userInfo) => {
            this.bindDetailsToUnApprovedLaborList(userInfo, assignedResource);
        });
    }


    /**
     * Bind Resource Images to UnApproved LaborList.
     * @param assignedResource
     */
    private bindDetailsToUnApprovedLaborList(response, resourceAlias: string): void {
        if (this.unapprovedLaborList && this.unapprovedLaborList.length) {
            this.unapprovedLaborList.forEach((entity) => {
                if (entity.laborWeeklyData && entity.laborWeeklyData.length) {
                    entity.laborWeeklyData.forEach((laborData) => {
                        if (laborData.laborDetails && laborData.laborDetails.length) {
                            laborData.laborDetails.forEach((laborDetail) => {
                                if (laborDetail.resourceAlias === resourceAlias) {
                                    laborDetail.resourceName = response.fullName;
                                    laborDetail.resourceRole = response.businessRole;
                                }
                            });
                        }
                    });
                }
            });
        }
    }
}