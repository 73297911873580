import { AddInternalEngagementProjectModule } from "../../../../new-internal-engagement/add-internal-engagement-project/add-internal-engagement-project.module";
import { CceacModule } from "../../../../financial-mgmt/tiles/cost-consumed/cceac/cceac.module";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { FormsModule } from "@angular/forms";
import { InternalEngagementBreakdownStructureComponent } from "./internal-engagement-breakdown-structure.component";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WbsAddTaskModalModule } from "../../../../manage-wbs/tiles/wbs-project/wbs-project-service-task-add/wbs-project-service-task-add.module";
import { WbsProjectEditModalModule } from "../../../../manage-wbs/tiles/wbs-project/wbs-project-edit/wbs-project-edit.module";
import { WbsProjectStateModalModule } from "../../../../manage-wbs/tiles/wbs-project/wbs-project-ebs-state/wbs-project-ebs-state.module";
import { DmUserProfileModule } from "../../../../../components/shared/user-name/user-name.module";
import { WBSService } from "../../../../../common/services/wbs.service";

@NgModule({
    declarations: [
        InternalEngagementBreakdownStructureComponent,
    ],
    imports: [
        AddInternalEngagementProjectModule,
        CceacModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        FormsModule,
        NgbModule,
        WbsAddTaskModalModule,
        WbsProjectEditModalModule,
        WbsProjectStateModalModule,
        DmUserProfileModule
    ],
    providers: [
        DMLoggerService,
        DMAuthorizationService,
        SharedFunctionsService,
        ConfigManagerService,
        EngagementDetailService,
        DMAuthorizationService,
        WBSService
    ],
    entryComponents: [
        InternalEngagementBreakdownStructureComponent,
    ],
    exports: [
        InternalEngagementBreakdownStructureComponent,
    ]
})
export class InternalEngagementBreakdownStructureModule { }
