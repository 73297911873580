
import { switchMap, catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";

import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";
import { ProjectService } from "../../common/services/project.service";
import * as changeRequestActions from "./change-requests.action";
import { DataService } from "../../common/services/data.service";


@Injectable()
export class ChangeRequestEffect {

    @Effect()
    public loadChangeRequest$ = this.actions$
        .ofType(changeRequestActions.ChangeRequestActionTypes.LOAD_CHANGE_REQUESTS).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap((action: changeRequestActions.LoadChangeRequests) =>
                fromPromise(this.projectService.getNonContractualAmendments())
                    .pipe(
                        map((changeRequests: IChangeRequest[]) => new changeRequestActions.LoadChangeRequestsSuccess(changeRequests)),
                        catchError((error) => of(new changeRequestActions.LoadChangeRequestsFail(error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error)))
                        )
                    )
            ));
    public constructor(
        private actions$: Actions,
        private projectService: ProjectService
    ) { }
}