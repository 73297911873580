<div id="nav-bar-notification" (clickOutside)="closeNotifications()">
    <span class="icon-full icon-date-time nav-notification" id="badge-notification" (keyup.enter)="toggleNotifications($event)" (click)="toggleNotifications($event)"></span>
    <span class="sr-only">button</span>
    <span class="badge badge-notification notifications-count set-focus nav-notification" (keyup.enter)="toggleNotifications($event)" (click)="toggleNotifications($event)" role="button" 
    [attr.aria-label]="getNotificationCount() + 'notifications'" id="notification001" tabindex="0">
        {{getNotificationCount()}}
    </span>
    <div id="dm-notification" class="col-sm-12 dropdown-menu" role="dialog" aria-modal="true" aria-label="notifications"
        [ngClass]="showNotifications ? 'display-block-imp' : 'display-none-imp'" (keyup.Escape)="closeNotifications($event)">
        <div class="notification-modal-container">
            <ul class="dm-p-l-20 dm-p-t-10 dm-flex-align-center" role="menu">
                <li class="dm-p-r-10 dm-flex-align-center" *ngFor="let navigationOption of navigationOptions" role="menuitem">
                    <button class="dm-p-3 dm-tile__large-tile-title dm-transparent-btn set-focus"
                        [ngClass]="{'active': navigationOption.name === currentOption.name}"
                        (click)="naviagteToNewOption(navigationOption)">{{navigationOption.name}}</button>
                    <span class="badge badge-notification dm-m-l-4" *ngIf="navigationOption.itemCount >= 0">
                        {{navigationOption.itemCount}}
                    </span>
                </li>
            </ul>
            <button type="button" title="Close" class="close set-focus" id="noticationCloseButton001" aria-label="notification Dialog Close" (click)="closeNotifications($event)" (keypress.enter)="closeNotifications($event)">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </div>
        <dm-key-actions-v2 [ngClass]="{'display-none-imp': currentOption.name !== 'Key Actions'}"
            [isNotification]="true" [listOfActions]="listOfKeyActions"></dm-key-actions-v2>
        <activity-feed *ngIf="wbsAuditEntries.length > 0" [wbsAuditEntries]="wbsAuditEntries"
            [ngClass]="{'display-none-imp': currentOption.name !== 'Activity Feed'}"></activity-feed>
    </div>
</div>