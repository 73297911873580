import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";

import { AdvancedSearchComponent } from "./advanced-search.component";
import { AdvancedSearchFilterModalModule } from "./modals/filter-modal/advanced-search-filter-modal.module";
import { CommonComponentsModule } from "../entry/common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { ProjectService } from "../../common/services/project.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { DelegationDetailsModalModule } from "../tiles/delegation-details-modal/delegation-details-modal.module";

@NgModule({
    declarations: [
        AdvancedSearchComponent,
    ],
    imports: [
        AdvancedSearchFilterModalModule,
        CommonComponentsModule,
        CommonModule,
        DelegationDetailsModalModule,
        FormsModule,
        NgbModule,
        UIRouterModule
    ],
    providers: [
        ConfigManagerService,
        DMAuthorizationService,
        DMLoggerService,
        EngagementDetailService,
        ProjectService,
        ProjectService,
        SharedFunctionsService
    ],
    exports: [
        AdvancedSearchComponent,
    ],
    entryComponents: [
        AdvancedSearchComponent,
    ]
})
export class AdvancedSearchModule { }
