import { Component, Inject, Injector, Input, forwardRef } from "@angular/core";
import { Components, EntityType, ForecastNotificationType, RouteName } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IProcessNotification } from "../../../common/services/contracts/process-notifications.contracts";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { OpenSyncEACToPlanThresholdDialogComponent } from "../open-sync-eac-to-plan-threshold-dialog/open-sync-eac-to-plan-threshold-dialog.component";
import { FxpConstants, FxpEventBroadCastService, FxpMessageService, FxpRouteService, UserInfoService } from "@fxp/fxpservices";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { IState } from "../../../store/reducers";
import { Store } from "@ngrx/store";
import { UpdateWriteOperationNotifications } from "../../../store/write-operation-notifications/write-operation-notifications.action";
import moment from "moment";
import { RemoveNotification } from "../../../store/notification-subscriptions/notification-subscriptions.action";
import { getWriteOperationNotificationByInstanceId } from "../../../store/write-operation-notifications/write-operation-notifications.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { SyncEACToPlanStatus } from "../../../common/services/contracts/sync-eac-to-plan-status.enum";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { StateService } from "@uirouter/angular";

@Component({
    selector: "dm-sync-eac-to-plan-notification-v2",
    templateUrl: "./sync-eac-to-plan-notification-v2.component.html",
    styleUrls: ["./sync-eac-to-plan-notification-v2.component.scss"]
})
export class SyncEACToPlanNotificationComponentV2 extends DmComponentAbstract {
    @Input() public subscriptionId: string;
    public readonly SYNCEAC_COMPLETED_MESSAGE = "Sync EAC to Plan is completed, Please refresh the screen for plan changes to get reflected";
    public operation: IProcessNotification;
    public syncEACToPlanStatus = SyncEACToPlanStatus;
    public isSyncInPorogress = false;
    public isSyncEACCompleted = false;
    public isThresholdBreached = false;
    public showThresholdDialogComponent = false;
    public timeAgo: string;
    public showCloseOption: boolean;
    private loggedInUserData: any;    
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(UserInfoService) private userInfoService: UserInfoService,
        @Inject(FxpEventBroadCastService) private fxpBroadCastService: FxpEventBroadCastService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FxpEventBroadCastService) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(StateService) private stateService: StateService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
    ) {
        super(dmLogger, Components.SyncEACToPlanNotificationComponent);
    }

    public ngOnInit(): void {
        this.loggedInUserData = this.userInfoService.getCurrentUserData();

        this.notificationService.subscribeToBulkInternalEngagementStatus({ GroupName: this.subscriptionId });

        // make get call for status
        this.configManagerService.initialize().then(() => {
            this.notificationService.getForecastNotificationSteps(ForecastNotificationType.SyncEACStatusChange, this.subscriptionId)
                .then((notification: IProcessNotification) => {
                    if (notification) {
                        this.showCloseOption = true;
                        this.store.dispatch(new UpdateWriteOperationNotifications(notification));
                        //  this.refreshWbsStatusChangeDone(notification, this.operation);
                        const oldOperationsObject = this.operation;
                        this.operation = notification;

                        if (this.operation && this.operation.LastUpdateTime) {
                            const stillUtc = moment.utc(this.operation.LastUpdateTime).toDate();
                            this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                        }

                        if (!oldOperationsObject || !oldOperationsObject.isItemExpanded || (!oldOperationsObject.Done && this.operation.Done)) {
                            this.operation.isItemExpanded = false;
                        } else {
                            this.operation.isItemExpanded = oldOperationsObject.isItemExpanded;
                        }
                    } else {
                        this.store.dispatch(new RemoveNotification(this.loggedInUserData.alias, this.subscriptionId));
                    }
                });
        });

        const notifications$ = this.store.select(getWriteOperationNotificationByInstanceId(this.subscriptionId));
        notifications$.pipe(untilDestroyed(this)).subscribe((processNotification: IProcessNotification) => {
            if (processNotification) {
                //  Check if notification is appropriate
                let showNotification = false;

                if (
                    (this.sharedFunctionsService.getCurrentEntityContext() === EntityType.Project
                        && this.sharedFunctionsService.getCurrentEntityId() === processNotification.WbsId)
                    || (this.sharedFunctionsService.getCurrentEntityContext() === EntityType.Engagement
                        && processNotification.WbsId.includes(this.sharedFunctionsService.getCurrentEntityId()))
                ) {
                    showNotification = true;
                }

                if (showNotification) {
                    this.isSyncInPorogress = true;
                    this.isThresholdBreached = false;
                    this.operation = processNotification;

                    if (processNotification.Steps.every((step) => step.StatusCode === this.syncEACToPlanStatus.Success)) {
                        this.isSyncInPorogress = false;
                        // Get the current UTC date
                        const currentUtcDate = moment.utc();
                     
                        const momentlastUpdateddate = moment(processNotification.LastUpdateTime);
                        // Calculate the difference in seconds
                        const differenceInSeconds = currentUtcDate.diff(momentlastUpdateddate, "seconds");
                        if (moment.utc().isAfter(momentlastUpdateddate) && differenceInSeconds < 10 && differenceInSeconds >= 0) {
                            this.isSyncEACCompleted = true;
                            setTimeout(() => this.isSyncEACCompleted = false, 10000);
                        }
                        if (moment.utc().isAfter(momentlastUpdateddate) && differenceInSeconds < 4 && differenceInSeconds >= 0) {
                            this.fxpMessageService.addMessage("Sync EAC to Plan completed sucessfully. Refresh the browser", this.FXP_CONSTANTS.messageType.success, false);
                        }
                    }
                    if (processNotification.Steps && processNotification.Steps
                        .findIndex((step) => step.StatusCode === this.syncEACToPlanStatus.ValidationFailed) >= 0) {
                        this.isSyncInPorogress = false;
                        this.isThresholdBreached = true;
                    }
                    else if (processNotification.Steps.findIndex((step) => step.StatusCode === this.syncEACToPlanStatus.Failed) >= 0) {
                        this.isSyncInPorogress = false;
                        //  this.fxpMessageService.addMessage(`An error has ocurred while synching to plan.`, this.FXP_CONSTANTS.messageType.error, false);
                    }
                }
            }
        });
    }

    /**
    * Opens the dialog for clicked notification step
    * @param step Step information on which dialog will open
    */
    public openThresholdDialog(): void {
        const modalRef: NgbModalRef = this.modalService.open(OpenSyncEACToPlanThresholdDialogComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 sync-eac-to-plan-threshold-modal in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.loadModalData(this.subscriptionId);
    }

    public handleUserActionCallback(input: string): void {
        if (input === "completed") {
            this.showThresholdDialogComponent = false;
            this.isSyncInPorogress = true;
        }
    }

    public getInProgressText(): string {
        if (this.isSyncInPorogress && this.operation) {
            if (this.operation.DemandId && this.operation.TaskId && this.operation.WbsId) {
                return `Sync EAC to Plan in Progress for the Demand ${this.operation.DemandId}...`;
            }
            else if (this.operation.DemandId && this.operation.TaskId && !this.operation.WbsId) {
                return `Sync EAC to Plan in Progress for the Task ${this.operation.TaskId}...`;
            }
            else {
                return `Sync EAC to Plan in Progress for the Project ${this.operation.WbsId}...`;
            }
        }

        return "";
    }

    public getVaildationFailedText(): string {
        if (this.isThresholdBreached && this.operation) {
            if (this.operation.DemandId && this.operation.TaskId && this.operation.WbsId) {
                return `Sync EAC to Plan is incomplete for the Demand- ${this.operation.DemandId}.`;
            }
            else if (this.operation.DemandId && this.operation.TaskId && !this.operation.WbsId) {
                return `Sync EAC to Plan is incomplete for the Task ${this.operation.TaskId}`;
            }
            else {
                return `Sync EAC to Plan is incomplete for the Project ${this.operation.WbsId}.`;
            }
        }

        return "";
    }
}