<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'350px'">
    <section additionalActionsRight class="display-flex-imp" *ngIf="!isServerError">
        <button title="FVR Feedback Survey" class="dm-m-l-imp-20 dm-transparent-btn"
            aria-label="opens fvr feedback modal" (click)="openFvrFeedbackModal()">
            <span class="icon-full icon-feedback dm-p-x-5"></span>
            <span class="dm-link">Feedback</span>
        </button>
        <button *ngIf="enableCrCreation" role="link"
            class="dm-link set-focus dm-m-l-imp-20" type="button"
            (click)="openFcrForm()" aria-label="New Planned Financial Change Request Form."
            title="Planned Financial Change Request can be made once Current Financial Plan is available">
            <span><i class="icon icon-AddLegacy"></i>
                New Planned Financial Change Request
            </span>
        </button>
    </section>
    <dm-loading loader *ngIf="isComponentLoading && !isServerError" [loadingText]="loadingText" [isCustomText]="true"
    [showLoading]="showLoading"></dm-loading>
    <section class="dm-p-imp-0 display-inline-block-imp col-md-12 col-sm-12 col-xs-12 display-flex"
        *ngIf="filteredApprovals.length > 0 && !isServerError">
        <ul class="nav nav-tabs display-inline-block-imp" role="tablist">
            <!-- <li role="presentation">
                <button type="button" role="tab" aria-selected="isSetTab('RiskReserve')" data-toggle="tab"
                    (click)="setTab('RiskReserve')">Risk Reserve</button>
            </li> -->
            <li role="presentation" class="active">
                <button type="button" role="tab" tabindex="-1" [attr.aria-selected]="isSetTab('PlannedLabor')" data-toggle="tab"
                    (click)="setTab('PlannedLabor')">Planned Labor</button>
            </li>
        </ul>
        <section *ngIf="filteredApprovals.length > 0" class="pull-right pos-rel display-inline-block-imp">
            <label for="viewby" class="input__label--text font-caption-alt">Filter by status</label>
            <div class="input__select--icon">
                <select name="viewby" id="viewby" [(ngModel)]="filterBy" (change)="onFilterChange($event.target.value)"
                    class="input__select text-capitilize font-caption-alt">
                    <option class="text-capitilize" value="">
                        All
                    </option>
                    <option *ngFor="let option of filterByOptions" [value]="option">{{option}}</option>
                </select>
                <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
            </div>
        </section>
    </section>

  
    <section class="tab-content" *ngIf="!isComponentLoading && !isServerError">
        <section role="tabpanel" class="tab-pane" id="RiskReserve" *ngIf="isSetTab('RiskReserve')">
        </section>
        <section role="tabpanel" class="tab-pane active" id="PlannedLabor"
            *ngIf="isSetTab('PlannedLabor') && filteredApprovals.length > 0">
            <section>
                <div id="plannedLaborCaption" class="sr-only">Planned Labor</div>
                <div class="dm-financial-plan-approvals__grid dm-p-t-imp-0" role="grid">
                    <!-- CSS grid, Add grid column width in scss if you add a new field to grid -->
                    <div role="row" class="dm-financial-plan-approvals__grid-row">
                        <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">ID</div>
                        <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">Title</div>
                        <!-- Will be replaced in future with some project level breakdown
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">Engagement Name / ID</div> -->
                        <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">
                            Requested<br />Hours</div>
                        <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">Requested
                            Cost</div>
                        <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">Request Date
                        </div>
                        <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">Requestor
                        </div>
                        <div role="gridcell" class="dm-financial-plan-approvals__grid-header-cell">Approval
                            Status</div>
                    </div>
                    <ng-container *ngFor="let approval of filteredApprovals">
                        <div role="row" class="dm-financial-plan-approvals__grid-row">
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                <a [uiSref]="isProjectContext ? RouteName.ProjectFinancialChangeRequest : RouteName.EngagementFinancialChangeRequest"
                                    [uiParams]="{changeRequest: approval, crId: approval.crNumber}"
                                    class="dm-link set-focus">
                                    {{approval.crNumber}}
                                </a>
                            </div>
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                {{approval.crDescription}}
                            </div>
                            <!-- Will be replaced in future with some project level breakdown
                                <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                <span>{{approval.engagementDescription}}</span><br />
                                <span>{{approval.engagementId}}</span>
                            </div> -->
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                {{approval.requestedHours}}
                            </div>
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                {{approval.requestedCost | number: '1.2-2'}} {{approval.planCurrency}}
                            </div>
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                {{approval.createdOn | date: 'dd-MMM-yyyy'}}
                            </div>
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                {{approval.requestorName ? approval.requestorName : approval.requestorAlias}}
                            </div>
                            <div role="gridcell" class="dm-financial-plan-approvals__grid-body-cell">
                                <ng-container
                                    *ngIf="approval.showProgressBar && approval.approvalProgress; else approvalStatus">
                                    <dm-progress-bar [progressBarData]="approval.approvalProgress">
                                    </dm-progress-bar>
                                </ng-container>
                                <ng-template #approvalStatus>
                                    {{approval.uiStatusDescription | replaceLineBreaks}}
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </section>
        </section>
        <dm-no-data *ngIf="!isComponentLoading && filteredApprovals.length === 0" [noDataText]="noFCRText" [showImage]="true"></dm-no-data>
    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>