import { Component, Input, forwardRef, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FxpEventBroadCastService } from "@fxp/fxpservices";
import { Components, AccessibilityConstants  } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-view-workbook-modal",
    templateUrl: "./view-workbook-modal.html",
    styleUrls: ["./view-workbook-modal.scss"]
})
export class ViewWorkBookModalComponent extends DmComponentAbstract { // todo this should be DmModal instead of DmComponentAbstract

    @Input() public contextId: string;
    public instructionsUrl: string;
    public isInvoiceLink: boolean;
    public isNewAOInstallerAvail: boolean = false;
    public popUpMessage: string = "View Workbook";
    public installerLink: string;
    public stepsLink: string;
    public fullStepsLink: string;
    public updatedAOVersionNotificationFrom: string;
    public accessibilityConstants = AccessibilityConstants ;
    protected dontShow: boolean = false;

    public constructor(
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpEventBroadCastService: FxpEventBroadCastService,
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) public configmanagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Components.FinancialAmendmentsViewWorkbookModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.installerLink = this.configmanagerService.getValue<string>("InstallerLinkForEditFinancialPlan");
        this.stepsLink = this.configmanagerService.getValue<string>("StepsLinkForEditFinancialPlan");
        this.fullStepsLink = this.configmanagerService.getValue<string>("FullStepsLinkForEditFinancialPlan");
        this.updatedAOVersionNotificationFrom = this.configmanagerService.getValue<string>("updatedAOVersionNotificationFrom");
        this.endComponentLoad();
    }
    /**
     * Close Interestitial modal popup
     */
    public closeInterstitialModal(): void {
        this.activeModal.close(false);
    }

    /**
     * Continue on click
     */
    public continue(): void {
        this.activeModal.close(true);
    }

    /**
     * Show OpsRequest
     */
    public showOpsRequest(): void {
        this.fxpEventBroadCastService.emit("createAskOpsRequest", "");
        this.activeModal.close();
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }
}
