
import { mergeMap, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Effect, Actions } from "@ngrx/effects";
import { of, from as fromPromise } from "rxjs";

import * as Demands from "./demands.action";
import { ProjectServiceV2 } from "../../common/services/project.v2.service";
import { IWbsDemand } from "../../common/services/contracts/project.service.v2.contracts";

@Injectable()
export class DemandsV2Effects {

    @Effect()
    public loadWbsDemands$ = this.actions$
        .ofType(Demands.DemandsActionTypes.LOAD_DEMANDS).pipe(
            mergeMap((action: Demands.LoadDemands) => fromPromise(this.projectServiceV2.getWbsDemands(action.wbsId, true, false))
                .pipe(
                    map((response: IWbsDemand) => new Demands.LoadDemandsSuccess(action.wbsId, response)),
                    catchError((error) => of(new Demands.LoadDemandsFail(action.wbsId, error)))
                )));

    public constructor(
        private actions$: Actions,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
    ) { }
}
