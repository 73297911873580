
import { mergeMap, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Effect, Actions } from "@ngrx/effects";
import { of, from as fromPromise } from "rxjs";

import * as Wbsforecast from "./wbs-forecast.action";
import { ProjectService } from "../../common/services/project.service";
import { IForecast } from "./../../components/financial-mgmt/financial.model";
import { PlanVersionType } from "./../../common/application.constants";

@Injectable()
export class WbsForecastEffect {

    @Effect()
    public loadWbsForecastDetails$ = this.actions$
        .ofType(Wbsforecast.WbsForecastActionTypes.LOAD_WbsForecast).pipe(
            mergeMap((action: Wbsforecast.LoadWbsForecast) => fromPromise(this.projectService.getForecast(action.wbsId, PlanVersionType.Forecast))
                .pipe(
                    map((response: IForecast) => new Wbsforecast.LoadWbsForecastSuccess(action.wbsId, response)),
                    catchError((error) => of(new Wbsforecast.LoadWbsForecastFail(action.wbsId, error)))
                )));

    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService,
    ) { }
}
