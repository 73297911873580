
import { switchMap, withLatestFrom, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { from as fromPromise, of } from "rxjs";
import { getMyPortfolioEngagementListState } from "../my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IEngagementFinancialsList } from "../../../common/services/contracts/portfolio.model";
import { IState } from "../../reducers";
import { MyPortfolioService } from "../../../common/services/my-portfolio.service";
import * as myPortfolioFinancialsActions from "./my-portfolio-financials-list.action";


@Injectable()
export class MyPortfolioFinancialsEffect {
    @Effect()
    public loadMyPortfolioEngagements$ = this.actions$
        .ofType(myPortfolioFinancialsActions.MyPortfolioFinancialActionTypes.LOAD_MY_PORTFOLIO_FINANCIALS_LIST).pipe(
            withLatestFrom(this.store),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap(([action, store]) => {
                /* Action is necessary here due to the switchmap/with latest from; in other cases we use it to rip the engagement ID, 
                but here we just have it as a placeholder to get the store.*/
                return fromPromise(
                    this.myPortfolioService.getFinancialsDataForMyPortfolioEngagementList(
                        getMyPortfolioEngagementListState(store).engagementList
                    )
                );

            }))
        .pipe(
            map((engagementFinancialsList: IEngagementFinancialsList[]) => new myPortfolioFinancialsActions.LoadMyPortfolioFinancialsListSuccess(engagementFinancialsList, undefined)),
            catchError((error) => of(new myPortfolioFinancialsActions.LoadMyPortfolioFinancialsListFail(error)))
        );

    public constructor(
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        private actions$: Actions,
        private store: Store<IState>
    ) { }
}