<div class="bulk-create-notification">
  <div class="bulk-create-notification__item-header">
    <div class="notification-bar__item-title" [attr.title]="'Bulk Upload (' + timeAgo + ')'"> Bulk Upload <span
        class="bulk-create-notification__item-time">({{timeAgo}})</span></div>
    <div class="pull-right">
      <button aria-label="Expand" class="bulk-create-notification__item-btn" title="expand" type="button"
        (click)="openBulkCreateIntEngagementStatusModal()">
        <span class="icon"
          [ngClass]="{'icon-chevron-up': !isNotificationExpanded, 'icon-chevron-down': isNotificationExpanded}"></span>
      </button>
      <button *ngIf="shouldDisplayCloseOption" aria-label="Close" class="bulk-create-notification__item-btn"
        title="close" type="button" (click)="closeNotification()">
        <span aria-hidden="true" class="icon icon-close"></span>
      </button>
    </div>
  </div>
  <div *ngIf="!shouldDisplayCloseOption" class="bulk-create-notification__progress-bar">
    <ngb-progressbar [value]="processedCount" [max]="totalCount">
    </ngb-progressbar>
  </div>
  <div class="bulk-create-notification__item-body">
    <div class="bulk-create-notification__item-body-content">
      <span *ngIf="!shouldDisplayCloseOption" class="dm-p-x-5"> Requests {{ processedCount }} of {{ totalCount }}
        processed.</span>
      <ng-container *ngIf="shouldDisplayCloseOption">
        <span class="icon icon-check icon-rounded-outline icon-success"></span>
        <span class="dm-p-x-5">Bulk Upload completed.</span>
      </ng-container>
    </div>
  </div>
</div>