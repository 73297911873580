import { Component, Input } from "@angular/core";
import { ICceac } from "../../../../project-summary-v2/tiles/project-summary-financials-v2/project.financials.model";
declare let $: any;

@Component({
    selector: "dm-cceac-v2",
    templateUrl: "./cceac-v2.html",
    styleUrls: ["./cceac-v2.scss"]
})
export class CceacV2Component {

    @Input() public values: ICceac;
    @Input() public isInternalEngagement: boolean;
    public percentageConsumed: number = 0;

    public ngAfterViewInit(): void {
        const values = this.values;
        const elem = ".cceac--template";
        if (!values) {
            return;
        }
        const animationTime = 2000;
        let maxValue;

        if (!this.isInternalEngagement) {
            if (values.currentFinancialPlan && values.deliveryBaseline && values.contractBaseline && values.eac) {
                maxValue = Math.max(values.currentFinancialPlan, values.deliveryBaseline, values.contractBaseline, values.eac);
            } else {
                maxValue = values.eac;
            }
        } else {
            maxValue = Math.max(values.consumed ? values.consumed : 0, values.deliveryBaseline ? values.deliveryBaseline : 0, values.eac ? values.eac : 0);
        }

        if (values.consumed !== undefined) {
            if (this.isInternalEngagement) {
                let ml: number = 0;
                if (values.deliveryBaseline) {
                    ml = (values.consumed / values.deliveryBaseline);
                } else if (values.eac) {
                    ml = (values.consumed / values.eac);
                }
                ml = ml * 100;
                if (ml >= 100) {
                    $(elem).find(".cceac--consumed").animate({ width: "100%" }, animationTime);
                } else {
                    $(elem).find(".cceac--consumed").animate({ width: ml + "%" }, animationTime);
                }
                this.percentageConsumed = +ml.toFixed(2);
            } else {
                const consumedValue = (values.consumed * values.eac) / 100;
                if (consumedValue >= maxValue) {
                    $(elem).find(".cceac--consumed").animate({ width: "100%" }, animationTime);
                } else {
                    const ml = (consumedValue / maxValue) * 100;
                    $(elem).find(".cceac--consumed").animate({ width: ml + "%" }, animationTime);
                }
                this.percentageConsumed = values.consumed;
            }
        }
        if (values.currentFinancialPlan !== undefined) {
            if (values.currentFinancialPlan <= maxValue) {
                let ml = (values.currentFinancialPlan / maxValue) * 100;
                ml = isNaN(ml) ? -1 : ml - 2;
                $(elem).find(".cceac--currentFinancialPlan").animate({ "margin-left": ml + "%" }, animationTime);
            }
        }
        if (values.deliveryBaseline !== undefined) {
            if (values.deliveryBaseline <= maxValue) {
                let ml = (values.deliveryBaseline / maxValue) * 100;
                if (this.isInternalEngagement) {
                    ml = isNaN(ml) ? -1 : ml - 1;
                } else {
                    ml = isNaN(ml) ? -1 : ml - 2;
                }
                $(elem).find(".cceac--deliveryBaseline").animate({ "margin-left": ml + "%" }, animationTime);
            }
        }
        if (values.contractBaseline !== undefined) {
            if (values.contractBaseline <= maxValue) {
                let ml = (values.contractBaseline / maxValue) * 100;

                ml = isNaN(ml) ? 0.5 : ml - 1.5;
                $(elem).find(".cceac--contractBaseline").animate({ "margin-left": ml + "%" }, animationTime);
            }
        }
        if (values.eac !== undefined) {
            if (values.eac <= maxValue) {
                const ml = (values.eac / maxValue) * 100;
                $(elem).find(".cceac--eacvalue").animate({ width: ml + "%" }, animationTime);
            } else {
                $(elem).find(".cceac--eacvalue").animate({ width: "100%" }, animationTime);
            }
        }
    }
}
