import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components, LogEventConstants, NotificationEvent, SourceConstants, TooltipText, WBSResponseMessages, AccessibilityConstants, NONFTE_REGEX } from "../../../../../common/application.constants";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { DMNotificationService, NotificationModelsForProject } from "../../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { EnumUpdateLevel } from "../../../../../common/services/contracts/wbs-engagement.contracts";
import { IChangedProperties } from "../../../../../common/services/contracts/dmnotification.service.contract";
import { IDemandSourceObject, StaffingService } from "../../../../../common/services/staffing.service";
import { INotificationMessages } from "../../../../../common/services/contracts/financial.service.contracts";
import { IProjectDetailsV2, ITeamDetailsV2, IServiceDetailsV2, IEngagementDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IWbsEditProjectDetailsV2, ITeamStructureForEditWBS, IDateValidationRequest, WbsLevel } from "../../../../../common/services/contracts/wbs.service.contracts";
import { IScheduledDates, IResourceRequestResponse, IProjectRequest } from "../../../../../common/services/contracts/staffing.service.contract";
import { ISelectedUserAttributes } from "../../../../tiles/type-ahead/type-ahead-contracts";
import { MyPortfolioService } from "../../../../../common/services/my-portfolio.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../../common/services/wbs.service";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { NotificationType } from "../../../../../common/services/contracts/notification-bar.contracts";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { DmNotificationService } from "../../../../../common/services/dm-notification.service";
import { DmError } from "../../../../../common/error.constants";

@Component({
    selector: "dm-wbs-project-edit",
    templateUrl: "./wbs-project-edit.html",
    styleUrls: ["./wbs-project-edit.scss"]
})
export class WbsProjectEditModalInstanceComponent extends DmModalAbstract {

    @Input() public selectedProject: IProjectDetailsV2;
    @Input() public engagementDetails: IEngagementDetailsV2;
    @Input() public isInternal: boolean = false;
    @Input() public showNbueHours: boolean = true;

    public engagementEndDate: Date;
    public engagementStartDate: Date;
    public updatedProject: IProjectDetailsV2;
    public isStartDatePopup: boolean = false;
    public isEndDatePopup: boolean = false;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public isDateCompare: boolean = false;
    public noResults: boolean = false;
    public typeAheadId: string;
    public taSearchAriaLblTxt: string;
    public typeAheadLabelText: string;
    public taCancelAriaLblTxt: string;
    public taRequiredMsg: string;
    public selectedUser: ISelectedUserAttributes;
    public additionalTypeAheadId: string;
    public additionalSearchAriaLblTxt: string;
    public additionalTypeAheadLabelText: string;
    public additionalCancelAriaLblTxt: string;
    public selectedUsers: ISelectedUserAttributes[];
    public multiSelectedUsers: ISelectedUserAttributes[];
    public isBeforeEngagementStartDate: boolean = false;
    public isAfterEngagementEndDate: boolean = false;
    public isAfterChildServiceStartDate: boolean = false;
    public isBeforeChildServiceEndDate: boolean = false;
    public childServiceStartDate: Date;
    public childServiceEndDate: Date;
    public minDate: Date;
    public minEndDate: Date;
    public maxStartDate: Date;
    public disablePJMUpdate: boolean;
    public disableAPJMUpdate: boolean;
    public disableEBSStartDateEndDateUpdates: boolean = false;
    public viewResourceEnable: boolean = false;
    public noOfConflictResources: number;
    public filteredResourceView: IProjectRequest[] = [];
    public showConflictingResources: boolean;
    public showActualsStartDateConflictMessage: boolean = false;
    public showActualsEndDateConflictMessage: boolean = false;
    public loadingText: string;
    public showIODateValidationMessage: boolean;
    public updateLevel: EnumUpdateLevel;
    public showCascadeRadioButtons: boolean;
    public disableProjectOnlyOption: boolean;
    public EnumUpdateLevel: typeof EnumUpdateLevel = EnumUpdateLevel;
    public ebsTooltipText: string = TooltipText.EBSState;
    public isResourceRequestsLoading: boolean;
    public accessibilityConstants = AccessibilityConstants;
    public editProjectErrorMessages = DmError.EbsStructure;
    public descriptionRequired: string = "Description";
    public showNonfteValidationMessage: boolean;

    private notificationMessage: INotificationMessages;
    private isModelValid: boolean = false;
    private tempStartDate: Date;
    private tempEndDate: Date;
    private originalAdditionalPJMBpIds: number[];
    private originalAdditionalPPJMBpIds: number[] = [];
    private updatedAdditionalPJMBpIds: number[];
    private APJMChanged: boolean = false;
    private originalAPJMNames: string[] = [];
    private addedAPJMNames: string[] = [];
    private deletedAPJMNames: string[] = [];
    private readonly FXP_CONSTANTS = FxpConstants;
    private serviceResponseMessages: IResponseMessage;
    private conflictResourceRequestStatus: string[] = ["Complete", "Assigned"];
    private validProjectDates: IScheduledDates;
    private engagementFullDetailsV2: IEngagementDetailsV2;
    private resourceRequests: IProjectRequest[] = [];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsProjService: WBSService,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DmDisplayDateOrDashPipe) private dmDisplayDateOrDashPipe: DmDisplayDateOrDashPipe,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions
    ) {
        super(activeModal, dmLogger, Components.ManageWBSEditProject);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.serviceResponseMessages = WBSResponseMessages.Project;
        this.notificationMessage = this.configurationService.getValue<any>("Notification");
        this.disableEBSStartDateEndDateUpdates = this.configurationService.getValue<boolean>("disableEBSStartDateEndDateUpdates");
        this.setTypeAheadText();
        this.updatedProject = { ...this.selectedProject };
        this.engagementStartDate = this.engagementDetails.startDate;
        this.engagementEndDate = this.engagementDetails.endDate;

        this.updatedProject.startDate = this.selectedProject.startDate;
        this.updatedProject.endDate = this.selectedProject.endDate;
        this.isComponentLoading = false;

        /* Used for the datepicker to limit the dates that can be selected */
        /* Start date
             > min date is the start date of the engagement. Can always be moved back in time to match the engagement; set in the view as engagementStartDate
             > max date is the current selected project end date (will change if the user changes it in the UI); set in the view as updatedProject.endDate
            End date
             > min date is the current selected project start date (will change if the user changes it in the UI); set in the view as updatedProject.startDate
             > max date is the end date of the engagement; set in the view as engagementEndDate
        */

        // Creating Primary Manager for UI (single user typehead)
        if (this.selectedProject && this.selectedProject.pjm) {
            this.selectedUser = {
                userAlias: this.selectedProject.pjm.alias,
                userName: this.selectedProject.pjm.name,
                bpId: this.selectedProject.pjm.bpid,
                emailName: "",
                firstName: "",
                lastName: "",
                fullName: this.selectedProject.pjm.alias
            };
        }

        // Creating Additional PJMs for UI (multiple users typeahead)
        this.selectedUsers = [];
        this.originalAdditionalPJMBpIds = [];
        if (this.selectedProject.adPjm) {
            for (const singleAPJM of this.selectedProject.adPjm) {
                if (this.originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpid)) < 0) {
                    this.originalAdditionalPJMBpIds.push(Number(singleAPJM.bpid));
                    this.originalAPJMNames.push(singleAPJM.name);
                }
                // Create new object for the user in order to match object types used in typeahead component
                const newAPJM: ISelectedUserAttributes = {
                    userAlias: singleAPJM.alias,
                    userName: singleAPJM.name,
                    bpId: singleAPJM.bpid,
                    emailName: "",
                    firstName: "",
                    lastName: "",
                    fullName: singleAPJM.alias
                };
                this.selectedUsers.push(newAPJM);
            }
        }

        this.multiSelectedUsers = this.selectedUsers;


        this.showConflictingResources = false;
        this.noOfConflictResources = 0;
        this.updateLevel = EnumUpdateLevel.projectLevel;

        if (this.engagementDetails) {
            this.engagementFullDetailsV2 = this.engagementDetails;
            if (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.adPPjm && this.engagementFullDetailsV2.adPPjm.length) {
                for (const singleAPPJM of this.engagementFullDetailsV2.adPPjm) {
                    if (singleAPPJM && singleAPPJM.bpid) {
                        if (this.originalAdditionalPPJMBpIds.indexOf(Number(singleAPPJM.bpid)) < 0) {
                            this.originalAdditionalPPJMBpIds.push(Number(singleAPPJM.bpid));
                        }
                    }
                }
            }
        }

        this.setDisableManagerChange();

        const projectDetails = this.selectedProject;
        const demandSourceObjects: IDemandSourceObject[] = [{ projectId: projectDetails.id, compassOnePackageId: projectDetails.compassOnePackageId }];
        this.disableEBSStartDateEndDateUpdates = true;
        this.isResourceRequestsLoading = true;
        this.staffingService.getGRMRequestsProjectIdV2(demandSourceObjects).then((resRequests) => {
            this.resourceRequests = resRequests.ProjectRequests;
            this.getResourceConflictStartEndDates(resRequests).then((result: IScheduledDates) => {
                this.isResourceRequestsLoading = false;
                this.validProjectDates = result;
                this.disableEBSStartDateEndDateUpdates = false;
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isResourceRequestsLoading = false;
            });
        });
    }

    /**
     * Creates the message that the user cannot change the date due to a resource conflict.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getCannotChangeDateResourceConflictMessage(): string {
        return DmError.EbsStructure.CannotChangeTheDateDueToResourcingConflict +
            ` (${this.noOfConflictResources}
        ${this.sharedFunctionsService.getWordPluralWithS("Resource", this.noOfConflictResources, false)}).`;
    }

    /**
     * Creates the message that the project end date must be after or the same as the actuals max date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateMustBeAfterActualsMessage(): string {
        return DmError.EbsStructure.ProjectEndDateSameOrLaterThanActualsPostedDate + ` (${this.dmDisplayDateOrDashPipe.transform(this.selectedProject.maxDate)}.`;
    }

    /**
     * Creates the message that the project start date must be before or same as the actuals min date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeBeforeActualsMessage(): string {
        return DmError.EbsStructure.ProjectStartDateSameOrEarlierThanActualsDate + ` (${this.dmDisplayDateOrDashPipe.transform(this.selectedProject.minDate)}).`;
    }

    /**
     * Creates the message that the project end date cannot be earlier than or the same as the service end date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateCannotBeBeforeServiceEndMessage(): string {
        return DmError.EbsStructure.ProjectEndDateCannotBeEarlierOrSameAsService + ` (${this.dmDisplayDateOrDashPipe.transform(this.childServiceEndDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeTheDateAtServiceLevel;
    }

    /**
     * Creates the message that the project start date must be later than or the same as the engagement start date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeAfterEngStartMessage(): string {
        return DmError.EbsStructure.ProjectStartDateMustBeSameOrLaterThanEngagement + ` (${this.dmDisplayDateOrDashPipe.transform(this.engagementStartDate)})`;
    }

    /**
     * Creates the message that the project end date must be earlier then or the same as the engagement end date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateMustBeBeforeEngEndMessage(): string {
        return DmError.EbsStructure.ProjectEndDateMustBeSameOrEarlierThanEngagement + ` (${this.dmDisplayDateOrDashPipe.transform(this.engagementEndDate)}).`;
    }

    /**
     * Creates the message that the project start date must be earlier than or the same as the service
     * start date. Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeEarlierThanServiceMessage(): string {
        return DmError.EbsStructure.ProjectStartDateMustSameOrEarlierThanService + ` (${this.dmDisplayDateOrDashPipe.transform(this.childServiceStartDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeTheDateAtServiceLevel;
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalid: boolean): boolean {
        // TODO: should be removed later as this needs to reactive form and these boolean flags should be not be used for validations.
        if (NONFTE_REGEX.test(this.selectedUser && this.selectedUser.userAlias)) {
            this.showNonfteValidationMessage = true;
            return true;
        }
        this.showNonfteValidationMessage = false;

        if (formInvalid || this.noResults) {
            return true;
        }
        if (this.updatedProject.name && this.updatedProject.name.length < 3) {
            return true;
        }
        const dates: boolean = this.isStartDateRequired
            || this.isEndDateRequired
            || this.isDateCompare
            || this.isBeforeEngagementStartDate
            || this.isAfterEngagementEndDate;
        if (dates) {
            return true;
        }

        if (
            this.showActualsStartDateConflictMessage || this.showConflictingResources || this.showActualsEndDateConflictMessage || this.showIODateValidationMessage
        ) {
            return true;
        }

        let PJMPristine: boolean = true;
        if (this.selectedUser && this.selectedUser.userAlias) {
            if (this.updatedProject.pjm) { /* Check for an existing PJM, in some data issue cases there is none*/
                PJMPristine = (Number(this.selectedUser.bpId) === Number(this.updatedProject.pjm.bpid));
            } else { /* If existing PJM is missing due to error issue, we can still have a selecteUser as the new PJM */
                PJMPristine = false;
            }
        } else {
            /* Only if three is no selected user
            (or, in if statement above, if the selected pjm id the same as the updated pjm)
             is this considered pristine */
            return true;
        }

        let isNbuehoursPristine: boolean = true;
        const isNamePristine: boolean = this.updatedProject.name === this.selectedProject.name;
        const isDescriptionPristine: boolean = this.updatedProject.description === this.selectedProject.description;
        const isStartDatePristine: boolean = moment(this.selectedProject.startDate).isSame(this.updatedProject.startDate);
        const isEndDatePristine: boolean = moment(this.selectedProject.endDate).isSame(this.updatedProject.endDate);
        if (this.isInternal) {
            isNbuehoursPristine = this.updatedProject.totalHours === this.selectedProject.totalHours;
        }
        let APJMPristine: boolean = true;
        this.updatedAdditionalPJMBpIds = [];
        for (const singleAPJM of this.selectedUsers) {
            if (singleAPJM && this.updatedAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpId)) < 0) {
                this.updatedAdditionalPJMBpIds.push(Number(singleAPJM.bpId));
            }
        }
        if (this.originalAdditionalPJMBpIds.sort().join(",") !== this.updatedAdditionalPJMBpIds.sort().join(",")) {
            APJMPristine = false;
        }

        const isFormPristine: boolean = isNamePristine
            && isDescriptionPristine
            && isStartDatePristine
            && isEndDatePristine
            && APJMPristine
            && PJMPristine
            && isNbuehoursPristine;

        return isFormPristine;
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Updates the Project Details by calling the API, sending any notifications, etc.
     */
    public updateProjectDetails(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.UpdateProjectDetails, LogEventConstants.ManageWBSProjectEditSaveClick);
        if (!this.selectedUser) {
            return;
        }
        this.loadingText = "Updating Project Details";
        this.isComponentLoading = true;
        this.isModelValid = true;
        this.tempStartDate = this.updatedProject.startDate;
        this.tempEndDate = this.updatedProject.endDate;
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        const modifiedPJMList: ITeamStructureForEditWBS[] = [];
        // Add newly selected additional managers
        for (const singleAPJM of this.selectedUsers) {
            if (this.originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpId)) < 0) {
                modifiedPJMList.push({
                    projectRole: "ADPJM",
                    bpId: singleAPJM.bpId,
                    isDelete: false // New APJM added, set isDelete to False
                });
                this.addedAPJMNames.push(singleAPJM.userName);
                this.APJMChanged = true;
            }
        }

        // Delete removed additional managers
        for (const origBpId of this.originalAdditionalPJMBpIds) {
            if (this.updatedAdditionalPJMBpIds.indexOf(Number(origBpId)) < 0) {
                modifiedPJMList.push({
                    projectRole: "ADPJM",
                    bpId: origBpId.toString(),
                    isDelete: true // An original APJM is no longer selected, set isDelete to True
                });
                if (this.selectedProject.adPjm && this.selectedProject.adPjm.length) {
                    const apjm = this.selectedProject.adPjm.filter((x: ITeamDetailsV2) => Number(x.bpid) === origBpId)[0];
                    this.deletedAPJMNames.push(apjm.name);
                    this.APJMChanged = true;
                }
            }
        }

        /* The keys on the left hand side must align with the selectedProject/updatedProject keys
        The values on the right hand side must align with the API's object names */
        const keysToCheck = {
            name: "name",
            statusCode: "status",
            pjm: "pjMbpId",
            description: "description",
            totalHours: "nbuehours"
        };

        const editProjectDataRequest: IWbsEditProjectDetailsV2 = {};
        let editProjectHasProperty: boolean = false;
        let editProjectHasDate: boolean = false;
        const keysConfirmedChanged: string[] = [];

        for (const key in keysToCheck) {
            if (key === "pjm") {
                if (this.selectedUser && /* Sanity check */
                    (!this.selectedProject[key] && this.selectedUser.bpId) || /* Original PJM was missing due to data issues but has since been updated. */
                    (this.selectedProject[key] && this.selectedProject[key].bpid !== this.selectedUser.bpId) /* Happy path */
                ) {
                    modifiedPJMList.push({
                        projectRole: "PJM",
                        bpId: this.selectedUser.bpId
                    });
                    keysConfirmedChanged.push(key);
                    editProjectHasProperty = true;
                }
            }

            if (this.selectedProject[key] !== this.updatedProject[key]) {
                editProjectDataRequest[keysToCheck[key]] = this.updatedProject[key];
                keysConfirmedChanged.push(key);
                editProjectHasProperty = true;
            }
        }

        const dateKeysToCheck = {
            startDate: "startDate",
            endDate: "endDate"
        };

        for (const key in dateKeysToCheck) {
            const convertedUpdateDate = this.updatedProject[key] as Date;
            if (!moment(this.selectedProject[key]).isSame(convertedUpdateDate, "day")) {
                editProjectDataRequest[dateKeysToCheck[key]] = moment(convertedUpdateDate).format("YYYY-MM-DD");
                editProjectDataRequest.shouldCascadeUpdate = this.updateLevel !== EnumUpdateLevel.projectLevel;
                keysConfirmedChanged.push(key);
                editProjectHasProperty = true;
                editProjectHasDate = true;
            }
        }

        /* Cannot use the keysToCheck array for additional managers due to inability to check array equality */
        if (this.APJMChanged) {
            editProjectHasProperty = true;
            keysConfirmedChanged.push("Additional Project Manager(s)");
        }
        if (modifiedPJMList.length > 0) {
            editProjectDataRequest.teamStructure = [];
            editProjectDataRequest.teamStructure = modifiedPJMList;
        }

        // Sends a request if any field has been modified
        if (editProjectHasDate && !this.isInternal) {
            this.isComponentLoading = true;
            const dateValidationRequest: IDateValidationRequest = {
                startDate: editProjectDataRequest.startDate ? editProjectDataRequest.startDate : moment(this.updatedProject.startDate).format("YYYY-MM-DD"),
                endDate: editProjectDataRequest.endDate ? editProjectDataRequest.endDate : moment(this.updatedProject.endDate).format("YYYY-MM-DD"),
                cascadeDown: editProjectDataRequest.shouldCascadeUpdate
            };

            this.wbsProjService.validateWbsDates(dateValidationRequest, this.selectedProject.id).then((response: any) => {
                if (response.status === 200) {
                    // we are good to create notification and call orchestrator
                    const orchestrationId: string = uuid();
                    editProjectDataRequest.startDate = dateValidationRequest.startDate;
                    editProjectDataRequest.endDate = dateValidationRequest.endDate;

                    this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.DateChange, loggedInUserData.BusinessPartnerId, this.updatedProject.id, orchestrationId).then(() => {
                        this.projectServiceFunction.orchestrateDateChange(editProjectDataRequest, this.updatedProject.id, this.updatedProject.name, orchestrationId, WbsLevel.Project, loggedInUserData.alias).then(() => {
                            this.fxpMessageService.addMessage("Date Change has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                            // Displays the new notification in the notification bar
                            this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.updatedProject.id, orchestrationId, NotificationType.DateChange);
                            this.isComponentLoading = false;
                            this.closeModal();
                        });
                    });
                }
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.updatedProject.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.UpdateProjectDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isModelValid = false;
                this.isComponentLoading = false;
            });
        } else if (editProjectHasProperty && (!editProjectHasDate || this.isInternal)) {
            this.wbsProjService.updateProjectDetailsV2(editProjectDataRequest, this.selectedProject.id)
                .then((response) => {
                    this.engagementDetailService.invalidateStoreOnRefresh(this.selectedProject.engagementId);
                    this.myPortfolioService.refreshMyPortfolioEngagementList();
                    const changedProperties: IChangedProperties[] = [];
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Name", this.selectedProject.name, this.updatedProject.name));
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("State", this.selectedProject.statusCode, this.updatedProject.statusCode));
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Start Date", moment(this.selectedProject.startDate).format("YYYY-MM-DD"), moment(this.updatedProject.startDate).format("YYYY-MM-DD")));
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("End Date", moment(this.selectedProject.endDate).format("YYYY-MM-DD"), moment(this.updatedProject.endDate).format("YYYY-MM-DD")));
                    const oldDescription = this.selectedProject.description ? this.selectedProject.description : "No previous description provided";
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Description", oldDescription, this.updatedProject.description));

                    if (editProjectDataRequest.teamStructure && editProjectDataRequest.teamStructure.length > 0 && editProjectDataRequest.teamStructure.filter((team) => team.projectRole === "PJM").length > 0) {
                        /* In case the selected project did not have a PJM due to data issues */
                        let originalName: string = "None";
                        if (this.selectedProject.pjm) {
                            originalName = this.selectedProject.pjm.name;
                        }
                        this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Domain Project Manager", originalName, this.selectedUser.userName));
                    }

                    if (editProjectDataRequest.teamStructure && editProjectDataRequest.teamStructure.length > 0 && editProjectDataRequest.teamStructure.filter((team) => team.projectRole === "ADPJM").length > 0) {
                        let updateMessage: string = "";
                        let origMessage: string = "";
                        for (const name of this.originalAPJMNames) {
                            origMessage += name + ", ";
                            if (this.deletedAPJMNames.indexOf(name) < 0) {
                                updateMessage += name + ", ";
                            }
                        }
                        for (const name of this.addedAPJMNames) {
                            updateMessage += name + ", ";
                        }
                        updateMessage = !updateMessage ? "No Additional Domain Project Managers" : updateMessage.slice(0, -2); /* remove trailing comma and space */
                        origMessage = !origMessage ? "No previous Additional Domain Project Managers" : origMessage.slice(0, -2);
                        this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Additional Domain Project Managers", origMessage, updateMessage));
                    }

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotificationsUpdateEBS(editProjectDataRequest)) {
                        this.createLogAndSendNotification(changedProperties);
                    }
                    if (response && response.status && response.status === 206) {
                        let partialSuccessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                        partialSuccessMessage = partialSuccessMessage.replace("#", this.updatedProject.name);
                        this.fxpMessageService.addMessage(partialSuccessMessage, this.FXP_CONSTANTS.messageType.warning);
                    } else {
                        let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                        successMessage = successMessage.replace("#", this.updatedProject.name);
                        let updatedDetails: string = "";
                        for (let i = 0; i < keysConfirmedChanged.length; i++) {
                            updatedDetails += keysConfirmedChanged[i];
                            if (i !== keysConfirmedChanged.length - 1) {
                                updatedDetails += ", ";
                            }
                        }
                        successMessage = successMessage.replace("*", updatedDetails); // CodeQL [SM02383] Need to replace the single occurrence of *.
                        this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                    }
                    this.isComponentLoading = false;
                    this.activeModal.close();
                }).catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.updatedProject.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.UpdateProjectDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isModelValid = false;
                    this.isComponentLoading = false;
                });
        } else {
            this.activeModal.close();
        }
    }

    /**
     * Updates the selected user list with a callback from the typeahead.
     * @param users
     */
    public onAdditionalManagersSelected(users: ISelectedUserAttributes[]): void {
        this.selectedUsers = users;
    }

    /**
     * Selected User from typeahead
     *
     * @param {ISelectedUserAttributes} selectedUser
     */
    public onSelectUser(selectedUser: ISelectedUserAttributes): void {
        this.selectedUser = selectedUser;
    }

    /**
     * Disable save button on user clear
     *
     * @param {boolean} isClearPjM
     */
    public onUserCleared(isClearPjM: boolean): void {
        this.selectedUser = undefined;
        this.saveButtonDisabled(isClearPjM);
    }

    /**
     * Toggles if the view resource is enabled
     */
    public toggleResources(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.ToggleResources, this.viewResourceEnable ? LogEventConstants.EBSEditToggleResourceCollapse : LogEventConstants.EBSEditToggleResourceExpand);
        this.viewResourceEnable = !this.viewResourceEnable;
    }

    /**
     * Capturing project start date
     *
     * @param {Date} startDate
     * @memberof WbsProjectEditModalInstanceComponent
     */
    public onStartDateChange(startDate: Date): void {
        this.updatedProject.startDate = startDate;
        this.showConflictingResources = false;
        if (this.validProjectDates && this.validProjectDates.scheduledStartDate) {
            if (moment(startDate).isAfter(this.validProjectDates.scheduledStartDate, "day")) {
                this.showConflictingResources = true;
            }
        }
        this.isProjectStartDateValid();
    }

    /**
     * Checks validity of the start date by checking with GRM API
     */
    public isProjectStartDateValid(): void {
        if (this.isModelValid) {
            this.updatedProject.startDate = this.tempStartDate;
            this.updatedProject.endDate = this.tempEndDate;
            return;
        }
        if (this.maxStartDate && (moment(this.updatedProject.startDate) > moment(this.maxStartDate))) {
            return;
        }
        this.updateLevel = EnumUpdateLevel.projectLevel;
        this.isStartDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableProjectOnlyOption = false;
        if (!this.updatedProject.startDate) {
            this.updatedProject.startDate = this.selectedProject.startDate;
            this.isStartDateRequired = true;
            return;
        }

        this.isBeforeEngagementStartDate = false;
        this.isAfterChildServiceStartDate = false;

        if (moment(this.updatedProject.startDate).isBefore(this.engagementStartDate)) {
            this.isBeforeEngagementStartDate = true;
            return;
        }

        if (!this.isEndDateGreaterThanStartDate(this.updatedProject.startDate, this.updatedProject.endDate)) {
            return;
        }
        if (!this.validateLowerLevelConflicts(this.updatedProject.startDate, this.updatedProject.endDate)) {
            return;
        }

        let returnValue = true;
        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.updatedProject.startDate, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.updatedProject.endDate) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.updatedProject.endDate, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }
        this.showActualsStartDateConflictMessage = false;
        if (this.selectedProject.minDate && moment(this.updatedProject.startDate).isAfter(this.selectedProject.minDate)) {
            this.showActualsStartDateConflictMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedProject.startDate, this.updatedProject.startDate) || this.haveDatesChanged(this.selectedProject.endDate, this.updatedProject.endDate);
    }


    /**
     * Capturing project end date
     *
     * @param {Date} endDate
     * @memberof WbsProjectEditModalInstanceComponent
     */
    public onEndDateChange(endDate: Date): void {
        this.updatedProject.endDate = endDate;
        this.showConflictingResources = false;
        if (this.validProjectDates && this.validProjectDates.scheduledEndDate) {
            if (moment(endDate).isBefore(this.validProjectDates.scheduledEndDate, "day")) {
                this.showConflictingResources = true;
            }
        }
        this.isProjectEndDateValid();
    }

    /**
     * Checks validity of the end date by checking with GRM API
     */
    public isProjectEndDateValid(): void {
        if (this.isModelValid) {
            this.updatedProject.startDate = this.tempStartDate;
            this.updatedProject.endDate = this.tempEndDate;
            return;
        }
        if (this.minEndDate && (moment(this.updatedProject.endDate) < moment(this.minEndDate))) {
            return;
        }

        this.updateLevel = EnumUpdateLevel.projectLevel;
        this.isEndDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableProjectOnlyOption = false;
        if (!this.updatedProject.endDate) {
            this.updatedProject.endDate = this.selectedProject.endDate;
            this.isEndDateRequired = true;
        }

        this.isAfterEngagementEndDate = false;
        this.isBeforeChildServiceEndDate = false;

        if (moment(this.updatedProject.endDate).isAfter(this.engagementEndDate)) {
            this.isAfterEngagementEndDate = true;
            return;
        }

        if (!this.isEndDateGreaterThanStartDate(this.updatedProject.startDate, this.updatedProject.endDate)) {
            return;
        }
        if (!this.validateLowerLevelConflicts(this.updatedProject.startDate, this.updatedProject.endDate)) {
            return;
        }

        let returnValue = true;
        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.updatedProject.endDate, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.updatedProject.startDate) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.updatedProject.startDate, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }

        this.showActualsEndDateConflictMessage = false;
        if (this.selectedProject.maxDate && moment(this.updatedProject.endDate).isBefore(this.selectedProject.maxDate)) {
            this.showActualsEndDateConflictMessage = true;
            returnValue = false;
        }
        this.showIODateValidationMessage = false;

        /* check if the project is of type T&M BIF [using statuscode] and if enddate is greater than IO end date throw this validation */
        const isProjectBIF = this.sharedFunctionsService.isProjectECIF(this.selectedProject.userStatusCode);
        if (isProjectBIF && this.selectedProject.ioDate && moment(this.updatedProject.endDate).isAfter(this.selectedProject.ioDate)) {
            this.showIODateValidationMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedProject.startDate, this.updatedProject.startDate) || this.haveDatesChanged(this.selectedProject.endDate, this.updatedProject.endDate);

    }

    /**
     * Hides the error messages related to updating different date levels.
     */
    public hideErrorMessages(): void {
        if (this.updateLevel === EnumUpdateLevel.allLowerLevels) {
            this.isAfterChildServiceStartDate = false;
            this.isBeforeChildServiceEndDate = false;
        }
    }

    /**
     * Gets the resource conflict start and end dates from staffing data.
     *
     * @private
     * @returns {Promise<IScheduledDates>}
     * @memberof WbsProjectEditModalInstanceComponent
     */
    private getResourceConflictStartEndDates(grmStaffingResponse: IResourceRequestResponse): Promise<IScheduledDates> {
        const scheduledStartDates = [];
        const scheduledEndDates = [];
        if (grmStaffingResponse && grmStaffingResponse.ProjectRequests && grmStaffingResponse.ProjectRequests.length > 0) {
            const filteredProjectRequest = grmStaffingResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === this.selectedProject.id)[0];
            if (filteredProjectRequest) {
                const filteredResourceRequests = filteredProjectRequest.ResourceRequests.filter((resRequests) => this.conflictResourceRequestStatus.indexOf(resRequests.ResourceRequestStatusEnum) >= 0);
                if (filteredResourceRequests.length > 0) {
                    this.noOfConflictResources = filteredResourceRequests.length;
                    filteredResourceRequests.forEach((request) => {
                        scheduledStartDates.push(moment(request.ScheduledStartDate));
                        scheduledEndDates.push(moment(request.ScheduledEndDate));
                    });
                }
            }
        }
        if (scheduledStartDates.length && scheduledEndDates.length) {
            const scheduledDates = {
                scheduledStartDate: moment.min(scheduledStartDates).toDate(),
                scheduledEndDate: moment.max(scheduledEndDates).toDate()
            };
            return Promise.resolve(scheduledDates);
        } else {
            return Promise.resolve(undefined);
        }
    }


    /**
     * Gets the earliest (min) date from a list of services.
     */
    private getMinServiceStartDate(listOfServices: IServiceDetailsV2[]): Date {
        if (listOfServices && listOfServices.length) {
            let minStartDate: Date = listOfServices[0].startDate;
            this.updatedProject.services.forEach((service: IServiceDetailsV2) => {
                if (moment(service.startDate).isBefore(minStartDate)) {
                    minStartDate = service.startDate;
                }
            });
            return minStartDate;
        }
        return undefined;
    }

    /**
     * Gets the most recent (max) date from a list of services
     * @param listOfServices
     */
    private getMaxServiceEndDate(listOfServices: IServiceDetailsV2[]): Date {
        if (listOfServices && listOfServices.length) {
            let maxEndDate: Date = listOfServices[0].endDate;
            listOfServices.forEach((service: IServiceDetailsV2) => {
                if (moment(service.endDate).isAfter(maxEndDate)) {
                    maxEndDate = service.endDate;
                }
            });
            return maxEndDate;
        }
        return undefined;
    }

    /**
     * Is the given end date later than the given start date? True if the end date is later/greater than the start date.
     * False if the end date is earlier/less than the start date.
     */
    private isEndDateGreaterThanStartDate(startDate: Date, endDate: Date): boolean {
        if (startDate && endDate) {
            this.isDateCompare = false;
            if (moment(endDate).isBefore(startDate)) {
                this.isDateCompare = true;
                return false;
            }
        }
        return true;
    }

    /**
     * If the given objects are different, creates and returns a changed property object.
     * Returns undefined if the objects have not changed.
     * @param attributeName
     * @param originalValue
     * @param updatedValue
     */
    private createChangedPropertyObject(attributeName: string, originalValue: string, updatedValue: string): IChangedProperties {
        if (originalValue !== updatedValue) {
            return {
                name: attributeName,
                oldValue: originalValue,
                newValue: updatedValue
            };
        }
        return undefined;
    }

    /**
     * Creates a log string from the given changed properties: combines all the changed attribute names into a single, comma seperated string.
     * @param changedProperties
     */
    private createLogStringFromChangedProperties(changedProperties: IChangedProperties[]): string {
        let logString: string = "";
        changedProperties.forEach((property, index) => {
            logString += property.name;
            if (index !== changedProperties.length - 1) {
                logString += ",";
            }
        });
        return logString;
    }

    /**
     * Pushes the given value to the given array if the value evaluates as truthy.
     * (If the value is not null or undefined, false, or the number 0.)
     * Modifies the given array by reference, and does not need to return.
     */
    private pushToArrayIfTrue(array: IChangedProperties[], value: IChangedProperties): void {
        if (value) {
            array.push(value);
        }
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.ManageWBSProjectEdit, propertyBag);

        const notification = new NotificationModelsForProject();
        notification.engagementId = this.updatedProject.engagementId;
        notification.engagementName = this.engagementDetails ? this.engagementDetails.name : this.updatedProject.name;
        notification.eventName = NotificationEvent.ProjectUpdated;
        notification.projectId = this.updatedProject.id;
        notification.projectName = this.updatedProject.name;
        notification.eventName = NotificationEvent.ProjectUpdated;

        let sendTo: string[];
        if (this.engagementDetails) {
            if (this.APJMChanged) { // Notify PJM, Additional PJM of the project where data has changed with data from engagement level
                const listOfPJMEngagementOnly: string[] = this.sharedFunctionsService.getListofPjmEngagementOnlyV2(this.engagementDetails);
                const listOfPJMProjectOnly: string[] = this.sharedFunctionsService.getListofPjmProjectContextV2(this.engagementDetails.projects.filter((project) => project.id === this.updatedProject.id), true);
                listOfPJMEngagementOnly.forEach((pjm: string) => {
                    const index = listOfPJMProjectOnly.indexOf(pjm);
                    if (index >= 0) {
                        listOfPJMProjectOnly.splice(index, 1);
                    }
                });
                sendTo = listOfPJMEngagementOnly.concat(listOfPJMProjectOnly).concat(userAlias);
            } else { // Notify PJM and PPJM only of change
                sendTo = this.sharedFunctionsService.getListofPjmV2(this.engagementDetails).concat(userAlias);
            }
        }
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;
        let esxpNotification = this.notificationMessage.ProjectNotification;
        esxpNotification = esxpNotification.replace("#", this.updatedProject.name);
        this.notificationService.sendNotification(notification, false, esxpNotification);
    }

    /**
     * Sets the disabled flag for editing the manager and additional manager fields based on
     * the logged in user and the users within the team.
     */
    private setDisableManagerChange(): void {
        const currentUser: number = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        const pjmBpid: number = (this.updatedProject && this.updatedProject.pjm && this.updatedProject.pjm.bpid) ? Number(this.updatedProject.pjm.bpid) : undefined;
        const delPjmBpid: number = (this.updatedProject && this.updatedProject.delPjm && this.updatedProject.delPjm.bpid) ? Number(this.updatedProject.delPjm.bpid) : undefined;
        const pPjmBpid: number = (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.pPjm && this.engagementFullDetailsV2.pPjm.bpid) ? Number(this.engagementFullDetailsV2.pPjm.bpid) : undefined;
        const delPPjmBpid: number = (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.delPPjm && this.engagementFullDetailsV2.delPPjm.bpid) ? Number(this.engagementFullDetailsV2.delPPjm.bpid) : undefined;
        const dmmBpid: number = (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.dmmPPjm && this.engagementFullDetailsV2.dmmPPjm.bpid) ? Number(this.engagementFullDetailsV2.dmmPPjm.bpid) : undefined; 

        /* If current user is a PPJM, then user can edit PJM, APJM values else disable */
        if (pPjmBpid && currentUser === pPjmBpid) {
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (delPPjmBpid && currentUser === delPPjmBpid) {
            /* If current user is a delegated PPJM, then user can edit PJM, APJM values else disable */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.indexOf(currentUser) > -1) {
            /* If current user is an APPJM, then user can edit PJM, APJM else disable */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (pjmBpid && currentUser === pjmBpid) {
            /* If current user is a PJM, then user can edit PJM, APJM values */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (delPjmBpid && currentUser === delPjmBpid) {
            /* If current user is a delegated PJM, then user can edit PJM, APJM values */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (this.originalAdditionalPJMBpIds && this.originalAdditionalPJMBpIds.indexOf(currentUser) > -1) {
            /* If current user is a APJM, then user can edit APJM value else disable */
            this.disablePJMUpdate = true;
            this.disableAPJMUpdate = false;
        } else if (dmmBpid && currentUser === dmmBpid) {
            /* If current user is a DMM, then user can edit PJM, APJM values */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else {
            /* If current user is not a PJM, APJM, delegated PJM, PPJM, APPJM, delegated PPJM then disable flag is set to true */
            this.disablePJMUpdate = true;
            this.disableAPJMUpdate = true;
        }
    }

    /**
     * Compares the given start and end dates. Returns true if the dates have changed.
     * Note that the original date is a Date object, and the second date is a MomentInputObject. Todo
     * update this function if we ever improve the date type problem.
     * @param originalDate
     * @param newDate
     */
    private haveDatesChanged(originalDate: Date, newDate: Date): boolean {
        return !moment(originalDate).isSame(newDate);
    }

    /**
     * Validates any lower level conflicts by comparing dates. Returns true if valid, false if invalid
     * @param startDate
     * @param endDate
     */
    private validateLowerLevelConflicts(startDate: Date, endDate: Date): boolean {
        let isValid = true;
        this.isAfterChildServiceStartDate = false;
        if (this.updatedProject.services && this.updatedProject.services.length) {
            if (!this.childServiceStartDate) {
                this.childServiceStartDate = this.getMinServiceStartDate(this.updatedProject.services);
            }
            if (moment(startDate).isAfter(this.childServiceStartDate) && this.updateLevel === EnumUpdateLevel.projectLevel) {
                this.isAfterChildServiceStartDate = true;
                isValid = false;
            }
        }
        if (this.updatedProject.services && this.updatedProject.services.length) {
            if (!this.childServiceEndDate) {
                this.childServiceEndDate = this.getMaxServiceEndDate(this.updatedProject.services);
            }
            if (moment(endDate).isBefore(this.childServiceEndDate) && this.updateLevel === EnumUpdateLevel.projectLevel) {
                this.isBeforeChildServiceEndDate = true;
                isValid = false;
            }
        }

        if (this.isAfterChildServiceStartDate || this.isBeforeChildServiceEndDate) {
            this.isBeforeChildServiceEndDate = false;
            this.isAfterChildServiceStartDate = false;
            this.showCascadeRadioButtons = true;
            this.disableProjectOnlyOption = true;
            this.updateLevel = EnumUpdateLevel.allLowerLevels;
            isValid = true;
        }
        return isValid;
    }

    /**
     * Populates all the type ahead text values
     */
    private setTypeAheadText(): void {
        this.typeAheadId = "projTypeAheadForManager";
        this.taSearchAriaLblTxt = "Search manager by name or alias";
        this.typeAheadLabelText = "Domain Project Manager";
        this.taCancelAriaLblTxt = "Remove Domain Project Manager";
        this.taRequiredMsg = "Domain Project Manager is required";
        this.additionalTypeAheadId = "projTypeAheadForAdditionalManager";
        this.additionalSearchAriaLblTxt = "Search Additional Domain Project Manager - Optional";
        this.additionalTypeAheadLabelText = "Additional Domain Project Manager - Optional";
        this.additionalCancelAriaLblTxt = "Remove Additional Domain Project Manager name or alias";
    }
}
