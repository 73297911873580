import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CostConsumedModule } from "../tiles/cost-consumed/cost-consumed.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { FinancialNavModule } from "../tiles/financial-nav/financial-nav.module";
import { FinancialPlanApprovalModule } from "../../financial-plan-approvals/financial-plan-approvals.module";
import { FinancialSummaryModule } from "../tiles/financial-summary/financial-summary.module";
import { KeyIndicatorsModule } from "../tiles/key-indicators/key-indicators.module";
import { ProjectFinancialComponent } from "./project-financial.component";


@NgModule({
    declarations: [
        ProjectFinancialComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        CostConsumedModule,
        FinancialNavModule,
        FinancialPlanApprovalModule,
        FinancialSummaryModule,
        KeyIndicatorsModule
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        ProjectFinancialComponent
    ],
    exports: [
        ProjectFinancialComponent
    ]
})
export class ProjectFinancialModule { }
