import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
    /**
     * Sorts a given array from either largest to smallest (descending) 
     * or from smallest to largest (ascending, default). 
     * Can sort either the items in the array, or can sort by a property of objects in the array, based on the field.
     * @param array 
     * @param field 
     * @param isDescending 
     */
    public transform(array: any[], field: string, isDescending: boolean): any[] {
        if (!array || !array.length) {
            return array;
        }
        let counter: number = 1;
        if (!isDescending) {
            counter = -1;
        }
        if (field) {
            array.sort((a: any, b: any) => {
                if (a[field] < b[field]) {
                    return counter;
                } else if (a[field] > b[field]) {
                    return counter * -1;
                } else {
                    return 0;
                }
            });
        } else {
            array.sort((a: any, b: any) => {
                if (a < b) {
                    return counter;
                } else if (a > b) {
                    return counter * -1;
                } else {
                    return 0;
                }
            });
        }
        return array;
    }
}