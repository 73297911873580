<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'430px'" [hasNonOverflowContent]="true"
  [showFooter]="false">
  <div>
    <label class="sr-only">ECIF IO</label>
    <div>      
      <div class="row wbsDetail">        
        <section class="checkbox__section">
          <fieldset>
            <div class="input__checkbox">
              <input type="checkbox" [checked]="isFilterCheckEnable" (change)="onFilterChange()"
                class="input__checkbox--focus set-focus" value="None" id="viewIO" name="check"
                aria-label="Only show IO expiring in the next 30 days" />
              <label for="viewIO"></label>
            </div>
            <label for="viewIO" class="font-caption-alt input__checkbox--text">
              Only show IO expiring in the next 30 days
            </label>
          </fieldset>
        </section>
      </div>

      <span class="sr-only" id="ecifIOConsumedTable">ECIF IO Details</span>
      <div role="grid" aria-labelledby="ecifIOConsumedTable" class="io-details-grid">
        <div role="rowgroup" class="io-details-table">
          <div role="row" class="io-details-table__row">
            <div role="gridcell" class="io-details-table__headerCell text-align--left">
              IO Number
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              <dm-tooltip role="tooltip" [dmPlacement]="'bottom-right'" id="dm-tooltip-totalAmount"
                [ngbTooltipContent]="'This data reflects the total IO amount. \n The same IO can be used across more than one Engagement or Project at one time.'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="ECIF total amount information">
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </dm-tooltip>
              Total Amount
              <br />
              ({{currency}})
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              <dm-tooltip role="tooltip" [dmPlacement]="'bottom-right'" id="dm-tooltip-totalConsumedAmount"
                [ngbTooltipContent]="'This data reflects the total consumed amount of the IO. \n The same IO can be used across more than one Engagement or Project at one time.'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="ECIF total consumed amount information">
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </dm-tooltip>
              Total Consumed Amount
              <br />
              ({{currency}})
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              IO End Date
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              IO Expiration Date
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              <dm-tooltip role="tooltip" [dmPlacement]="'bottom-right'" id="dm-tooltip-totalConsumedAmount"
                [ngbTooltipContent]="'This action will only notify the ADE to take action in \n submitting an IO extension request.'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="ECIF total consumed amount information">
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </dm-tooltip>
              Action
            </div>
          </div>
          <div class="io-details-table__dataGrid">
            <div *ngFor="let entity of filteredIoList">
              <div role="row" class="io-details-table__row io-details-table__projectDetailsRow">
                <div role="gridcell">
                  <span class="dm-p-r-15 pointer">
                    <button id="{{'projectCollapse' + entity.projectId}}" type="button"
                      title="Collapse Project Details Section" aria-label="Project Details Section Expanded"
                      class="split-btn" (click)="toggleEcifIoProject(entity.projectId)" *ngIf="entity?.isExpanded">
                      <span class="icon icon-chevron-up"></span>
                    </button>
                    <button id="{{'projectExpand' + entity.projectId}}" type="button"
                      title="Expand Project Details Section" aria-label="Project Details Section Collapsed"
                      class="split-btn" (click)="toggleEcifIoProject(entity.projectId)" *ngIf="!entity?.isExpanded">
                      <span class="icon icon-chevron-down"></span>
                    </button>
                  </span>
                  Project: <span>{{entity?.projectName}}</span>
                </div>
              </div>
              <div *ngIf="entity?.isExpanded">
                <div class="io-details-table__body">
                  <ng-container *ngFor="let ioDetail of entity?.ioDetails">
                    <div scope="row" role="row" class="io-details-table__row">
                      <div role="gridcell" class="io-details-table__bodyCell">
                        <span class="sr-only">IO Number</span>
                        {{ioDetail.ioNumber}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right">
                        <span class="sr-only">Total Amount</span>
                        {{ioDetail.total}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right">
                        <span class="sr-only">Consumed Amount</span>
                        {{ioDetail.consumed}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right">
                        <span class="sr-only">IO End Date</span>
                        {{ioDetail.endDate | dmDisplayDateOrDashPipe}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right"
                        [ngClass]="{'text-danger':isNearExpiry(ioDetail.daysBeforeExpiry) && !isExpired(ioDetail.daysBeforeExpiry), 'text-safe': !isNearExpiry(ioDetail.daysBeforeExpiry)}">
                        <span class="sr-only">IO Expiration Status</span>
                        {{getAbsoluteDays(ioDetail.daysBeforeExpiry)}} days {{isExpired(ioDetail.daysBeforeExpiry)?
                        'past'
                        : 'to'}} expiration
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--center">
                        <span class="sr-only">Action</span>
                        <button type="button" id="submitExtension" class="extendbtn"
                          (click)="openIoExtensionModal(ioDetail.ioNumber, entity.projectId)">
                          Notify
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dm-modal-v2>