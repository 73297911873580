import { Inject, forwardRef, Component, Injector, ElementRef, ViewChild } from "@angular/core";

import { FxpEventBroadCastService, FxpRouteService, DeviceFactoryProvider, UserInfoService, UserProfileService } from "@fxp/fxpservices";
import { NgbDropdownConfig, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { CacheService } from "../../common/services/cache.service";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { ContextStorageService } from "../../common/services/contextstorage.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FeedbackModalService } from "./../tiles/feedback-modal/feedback-modal.service";
import { getEntireUserPreference } from "../../store/userspreferences/userpreference.selector";
import { getMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { INavigationListItemAttribute } from "./navigationlist.model";
import { IState } from "../../store/reducers";
import { IUserPreference, IViewedEntity } from "../../common/services/contracts/userpreference.contract";
import { IUserPreferenceState } from "../../store/userspreferences/userpreference.reducer";
import { MyPortfolioService } from "../../common/services/my-portfolio.service";
import { NavigationService } from "../../common/services/navigation.service";
import { ProjectService } from "../../common/services/project.service";
import { RouteName, Components, LogEventConstants, EntityType, DeliveryType, SearchAttribute, LocalStorageKey, LogEventName, InlineSurveyDataList, FeedBackEntity, NavHeight, V2ViewName, SourceConstants, AccessibilityConstants } from "../../common/application.constants";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UpdateUserPreference } from "../../store/userspreferences/userpreference.action";
import { UserPreferenceService } from "../../common/services/userpreferences.service";
import { WhatsNewModal } from "../shared/whats-new/whats-new-modal.component";
import { DmNotificationService } from "../../common/services/dm-notification.service";
import moment from "moment";
import { NavBarNotificationComponent } from "../tiles/nav-bar-notification/nav-bar-notification.component";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IProjectDetailsState } from "../../store/project-details/project-details.reducer";
import { getEntireProjectDetails } from "../../store/project-details/project-details.selector";

const Engagement: string = "Engagement";
const Project: string = "Project";
@Component({
    selector: "dm-navigation",
    templateUrl: "./nav.html",
    styleUrls: ["./nav.scss"]
})
export class NavigationComponent extends DmComponentAbstract {
    @ViewChild("portFolioFeedback", { static: false }) public portFolioFeedback: ElementRef;
    @ViewChild(NavBarNotificationComponent, { static: false }) public navBarNotificationComponent: NavBarNotificationComponent;
    public showMyPortfolioTabs: boolean;
    public isDropDownLoading: boolean;
    public projectDetails: IProjectDetailsV2;
    public isProjectContext: boolean;
    public selectedProjectId: string;
    public hideDrop: boolean = false;
    public searchText: string = "";
    public selectedGlobalSearchItems: string[] = [];
    public toggleNavTabs: boolean = true;
    public portfolioSurveyModalRef: NgbModalRef;
    public engagementSummaryRoute: string;
    public projectSummaryRoute: string;
    public accessibilityConstants = AccessibilityConstants;
    public loadingComplete: string = "";
    public pageRefreshCount: number = 0;
    public engagementDetails: IEngagementDetailsV2;
    public showTabNavigationHeader: boolean = true;
    private currentTab: INavigationListItemAttribute; /* (For engagement/project tabs) Used to maintain dispatch and refresh functionality for the tab the user is on */
    private userPreference: IUserPreference;
    private maxLimitForUsersInLocalStorage: number;
    private viewedEntitiesLimit: number;
    private currentReleaseId: string;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => UserProfileService)) public fxpUserProfileService: UserProfileService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => UserInfoService)) private userInfoService: UserInfoService,
        @Inject(EngagementDetailService)
        private engagementDetailService: EngagementDetailService,
        @Inject(NgbDropdownConfig) private dropDownConfig: NgbDropdownConfig,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(NavigationService) private navigationService: NavigationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
        @Inject(ContextStorageService) private contextStorageService: ContextStorageService,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService
    ) {

        super(dmLogger, Components.Nav,
            [
                { component: Components.TabNav, isCritical: true },
                { component: Components.DropDown, isCritical: true }
            ]
        );

    }

    public ngOnInit(): void {
        this.selectedProjectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = this.selectedProjectId ? true : false;
        this.showMyPortfolioTabs = this.shouldStateShowPortfolioTabs(this.stateService.current.name);
        this.showTabNavigationHeader = this.shouldShowTabNavigationHeader(this.stateService.current.name);
        this.findCurrentState();

        this.dropDownConfig.autoClose = "outside"; // this allows us to expand menus in the dropdown without it closing
        this.subscribe(
            this.fxpBroadcastService.On("$stateChangeStart", this.uiRouterStateChanged.bind(this))
        );
        this.subscribe(
            this.fxpBroadcastService.On("$stateChangeSuccess", this.findCurrentState.bind(this))
        );
        this.getSearchItemsFromStorage();

        this.configManagerService.initialize().then(() => {
            this.projectService.initializeConfig();
            this.maxLimitForUsersInLocalStorage = this.configManagerService.getValue<number>("maxLimitForUsersInLocalStorage");
            this.viewedEntitiesLimit = this.configManagerService.getValue<any>("userPreference").maxLimitForRecentlyViewedList;
            this.currentReleaseId = this.configManagerService.getValue<string>("whatsNewReleaseId").toLowerCase();
        });
        this.engagementSummaryRoute = RouteName.EngagementSummaryV2;
        this.projectSummaryRoute = RouteName.ProjectSummaryV2;
        const userPreferences$ = this.store.select((getEntireUserPreference()));
        userPreferences$.pipe(untilDestroyed(this)).subscribe((userPreferenceState: IUserPreferenceState) => {
            if (userPreferenceState.loaded && userPreferenceState.userPreference !== null) {
                this.userPreference = userPreferenceState.userPreference;
                let isWhatsNewLaunched: boolean = false;
                const whatsNewDisplayStatusByReleases = userPreferenceState.userPreference.whatsNewDisplayStatusByRelease;
                if (whatsNewDisplayStatusByReleases && whatsNewDisplayStatusByReleases[this.currentReleaseId]) {
                    isWhatsNewLaunched = whatsNewDisplayStatusByReleases[this.currentReleaseId];
                }
                if (!isWhatsNewLaunched) {
                    this.openWhatsNewModal();
                    this.updateUserPreferenceWhatsNewDisplayStatus();
                }
                this.engagementSummaryRoute = userPreferenceState.userPreference && userPreferenceState.userPreference.v2ViewDisplayPreference && userPreferenceState.userPreference.v2ViewDisplayPreference[V2ViewName.Engagement.Summary.toLowerCase()] === false ? RouteName.EngagementSummary : RouteName.EngagementSummaryV2;
                this.projectSummaryRoute = userPreferenceState.userPreference && userPreferenceState.userPreference.v2ViewDisplayPreference && userPreferenceState.userPreference.v2ViewDisplayPreference[V2ViewName.Project.Summary.toLowerCase()] === false ? RouteName.ProjectSummary : RouteName.ProjectSummaryV2;
            }
        });

        const selectedId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

        if (!this.isProjectContext) {
            const engagementDetails$ = this.store.select(
                getEntireEngagementDetails(selectedId)
            );
            engagementDetails$
                .pipe(untilDestroyed(this))
                .subscribe((engagementDetails: IEngagementDetailsState) => {
                    /* Listen for invalidation that indicates an internal refresh. This will refresh the data and manage telemetry if the event is detected.
                    You no longer need to listen for the refresh event off of FXP Broadcast Service*/
                    this.refreshOnItemInvalidation(engagementDetails);

                    /* set attributes when content successfully loads */
                    if (engagementDetails.loaded) {
                        this.engagementDetails = engagementDetails.engagementDetails;
                        const contractType = this.sharedFunctionsService.getContractType(this.engagementDetails.projects);
                        this.engagementDetails.typeOfContract = contractType;
                    }
                });
        } else {
            this.store.select(getEntireProjectDetails(this.selectedProjectId)).subscribe((projectDetailsState: IProjectDetailsState) => {
                this.refreshOnItemInvalidation(projectDetailsState);
                this.setLoadersBasedOnItemState(projectDetailsState);
                this.setErrorsBasedOnItemState(projectDetailsState);
                if (projectDetailsState.loaded) {
                    this.projectDetails = projectDetailsState.projectDetails.projectFullDetails;
                }
            });
        }
    }

    /**
     * toggles the notification dropdown
     */
    public toggleNotifications(event?: { [key: string]: any}): void {
        if (this.navBarNotificationComponent) {
            this.navBarNotificationComponent.toggleNotifications(event);
        }
    }

    /**
     * Navigation method for keyboard input for accessibility tooling
     * @param event
     */
    public keyNav(event: KeyboardEvent): void {
        if (event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 9) {
            const keyDownUpList = Array.prototype.slice.call(document.getElementsByClassName("nxItem"));
            let currentIndex;
            currentIndex = keyDownUpList.indexOf(event.currentTarget);
            if (event.keyCode === 40 && currentIndex < keyDownUpList.length) {
                event.stopPropagation();
                event.preventDefault();
                currentIndex += 1;
            } else if (event.keyCode === 38 && currentIndex > 0) {
                event.stopPropagation();
                event.preventDefault();
                currentIndex -= 1;
            }
            if (event.keyCode === 40 || event.keyCode === 38) {
                window.setTimeout(() => {
                    if (currentIndex === keyDownUpList.length) {
                        keyDownUpList[1].focus();
                    } else {
                        keyDownUpList[currentIndex].focus();
                    }
                }, 0);
            }
        } else if (event.keyCode === 37 || event.keyCode === 39) {
            let currentIndex;
            const keyLeftRightList = Array.prototype.slice.call(document.getElementsByClassName("tabNav"));
            event.stopPropagation();
            event.preventDefault();
            currentIndex = keyLeftRightList.indexOf(event.currentTarget);
            if (event.keyCode === 39 && currentIndex < keyLeftRightList.length) {
                currentIndex += 1;
            } else if (event.keyCode === 37 && currentIndex > 0) {
                currentIndex -= 1;
            }
            window.setTimeout(() => {
                if (currentIndex !== keyLeftRightList.length && currentIndex !== -1) {
                    keyLeftRightList[currentIndex].focus();
                }
            }, 0);
        }
    }

    /**
     * Refresh the current entity by repopulating the data in the cache. Alert the rest of the application that the cache has been updated
     * and the component should pull info from the cache again.
     *
     * @memberof NavigationComponent
     */
    public refreshEntity(): void {
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.RefreshEntity, LogEventName.EntityTabRefresh);
        if (this.currentTab.refreshData) {
            this.currentTab.refreshData();
            this.sharedFunctionsService.delayExecution(this.accessibilityConstants.LoadingCompleteMessageDelay).then(() => {
                if (this.pageRefreshCount++ % 2 === 0) {
                    this.loadingComplete = "loading complete";
                    document.getElementById("loadingCompletes").innerHTML = this.loadingComplete;
                }
                else {
                    this.loadingComplete = "Loading Complete";
                    document.getElementById("loadingCompletes").innerHTML = this.loadingComplete;
                }
            });
        }
    }

    /**
     * Refreshes the myportfolio data from the SAP instead of cache*
     * @memberof NavigationComponent
     */
    public refreshMyPortfolio(): void {
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.RefreshMyPortfolio, LogEventName.PortFolioRefresh,
            { userAlias: this.userInfoService.getCurrentUser() });
        this.myPortfolioService.refreshMyPortfolioEngagementList();

        /* Clears cache entries of internal engagement financials list on refresh btn click */
        this.cacheService.clearMatchedCachedKeys(["int_eng_financials_list"]);
        this.cacheService.clearMatchedCachedKeys(["engagements_financials_byEntityId"]);
        this.sharedFunctionsService.delayExecution(this.accessibilityConstants.LoadingCompleteMessageDelay).then(() => {
            if (this.pageRefreshCount++ % 2 === 0) {
                this.loadingComplete = "loading complete";
                document.getElementById("loadingCompletes").innerHTML = this.loadingComplete;
            }
            else {
                this.loadingComplete = "Loading Complete";
                document.getElementById("loadingCompletes").innerHTML = this.loadingComplete;
            }
        });
    }

    /**
     * Move the focus to element for accessibility tooling
     * @param id
     */
    public focus(id: string): void {
        this.sharedFunctionsService.focus(id, true);
    }

    /**
     * Search Engagements/Projects in portfolio
     */
    public globalSearch(): void {
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.GlobalSearch, LogEventName.GlobalSearch);
        const searchTextTrimmed = this.searchText ? this.searchText.trim() : "";
        if (searchTextTrimmed.length >= 3) {
            if (this.isInputEngagementId(searchTextTrimmed)) {
                if (this.checkIfEntityExistsInStore(searchTextTrimmed, Engagement)) {
                    this.sharedFunctionsService.isEngagementIdInternal(searchTextTrimmed) ? this.fxpRouteService.navigatetoSpecificState(RouteName.InternalEngagementSummary, { engagementId: searchTextTrimmed })
                        : this.fxpRouteService.navigatetoSpecificState(this.engagementSummaryRoute, { engagementId: searchTextTrimmed });
                } else {
                    this.navigateToSearch(searchTextTrimmed, SearchAttribute.EngagementId);
                }
            } else if (this.isInputProjectId(searchTextTrimmed)) {
                if (this.checkIfEntityExistsInStore(searchTextTrimmed, Project)) {
                    this.fxpRouteService.navigatetoSpecificState(this.projectSummaryRoute, { projectId: searchTextTrimmed });
                } else {
                    this.navigateToSearch(searchTextTrimmed, SearchAttribute.ProjectId);
                }
            } else {
                this.searchText = "";
                this.navigateToSearch(searchTextTrimmed, SearchAttribute.EngagementName);
            }
            this.addSearchTextToLocalStorage(searchTextTrimmed);
            this.searchText = "";
        }
        this.toggleNavTabs = true;
    }

    /**
     * Navigates to advanced search
     *
     * @memberof NavigationComponent
     */
    public navigateToAdvancedSearch(): void {
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.NavigateToAdvancedSearch, LogEventConstants.SelectAdvancedSearch);
        this.fxpRouteService.navigatetoSpecificState(RouteName.Search, { isAdvancedSearch: true });
        this.toggleNavTabs = true;
    }

    /**
     * Logs an event if the search history results from the 
     * search bar dropdown was used
     * @param searchText text pssed in to search bar
     */

    public searchHistoryUsageLogger(searchText: string): void {
        const propertyBag = {};
        propertyBag[LogEventConstants.SearchText] = searchText;
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.SearchHistoryUsageLogger, LogEventConstants.SearchHistoryResultUsed, propertyBag);
    }

    /**
     * Toggles nav tabs
     */
    public togglingNavTabs(isNavTabToggled: boolean): void {
        this.toggleNavTabs = isNavTabToggled;
    }

    /**
     * Opens portfolio feedback modal
     *
     * @memberof NavigationComponent
     */
    public openPortfolioFeedbackModal(): void {
        this.feedbackModalService.openFeedbackModal(InlineSurveyDataList.PortfolioV2Survey, FeedBackEntity.Portfolio, SourceConstants.Component.NavigationComponent);
    }


    /**
     *  Opens what's new modal
     * @memberof NavigationComponent
     */
    public openWhatsNewModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.OpenWhatsNewModal, LogEventName.WhatsNewIconClick);
        this.modalService.open(WhatsNewModal, {
            backdrop: "static",
            windowClass: "dm-modal in active whats-new-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
    }

    /**
     * Navigate to home page i.e portfolio page
     *
     * @memberof NavigationComponent
     */
    public navigateToHomePage(): void {
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.NavigateToHomePage, LogEventName.HomePageIconClick);
        this.fxpRouteService.navigatetoSpecificState(RouteName.Portfolio);
    }

    /**
     * On pressing Tab on item in global search drop-down
     * close the recently search item drop down if the tab is pressed on the last item. 
     */
    public onTabbingItemInGlobalSearchDropDown(globalSearchItem: string): void {
        const lastItemInGlobalSearchList: string = this.selectedGlobalSearchItems[this.selectedGlobalSearchItems.length - 1];
        if (globalSearchItem === lastItemInGlobalSearchList) {
            this.toggleNavTabs = true;
        }
    }

    /**
     * set navigation height for portfolio tab navigation bar equal to fixed secondary navigation bar
     */
    public setHeightForPortfolioTabNavigation(): string {
        if (document.getElementById("secondary-naviagtion")) {
            return document.getElementById("secondary-naviagtion").clientHeight + "px";
        } else {
            return NavHeight;
        }
    }

    /**
     * returns whether to show the notification from the naviagtion bar or not
     */
    public shouldShowNavBarNotifications(): boolean {
        if (this.stateService.current.name && (this.stateService.current.name === RouteName.EngagementSummaryV2 || this.stateService.current.name === RouteName.ProjectSummaryV2)) {
            return true;
        }
        return false;
    }

    /**
     * returns whether to show the tab navigation header or not
     */
    private shouldShowTabNavigationHeader(currentStateName: string): boolean {
        if (currentStateName && currentStateName === RouteName.BulkCreateInternalEngagement) {
            return false;
        }
        return true;
    }

    /**
     * Updates UserPreference What's New
     */
    private updateUserPreferenceWhatsNewDisplayStatus(): void {
        if (this.userPreference) {
            if (!this.userPreference.whatsNewDisplayStatusByRelease) {
                this.userPreference.whatsNewDisplayStatusByRelease = {};
            }
            this.userPreference.whatsNewDisplayStatusByRelease[this.currentReleaseId] = true;
            this.userPreferenceService.saveUserPreference(this.userPreference).then((response: IUserPreference) => {
                this.store.dispatch(new UpdateUserPreference(response));
            });
        }
    }

    /**
     * Checks for Data in Portfolio and redirects to Engagement/Project/Search based on results
     * @param input
     */
    private checkIfEntityExistsInStore(entityId: string, entityType: string): boolean {
        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
        let isExistsInPortfolio: boolean = false;
        myPortfolioEngagementList$.pipe(untilDestroyed(this)).take(1).subscribe((engagementList: IMyPortfolioEngagementListState) => {
            if (engagementList.loaded) {
                let filterFunction;
                if (entityType.toLowerCase() === Project.toLowerCase()) {
                    filterFunction = { predicateFn: (engagement) => engagement.projects.filter((project) => project.projectId.indexOf(entityId) > -1).length > 0 };
                } else {
                    filterFunction = { predicateFn: (engagement) => engagement.engagementId.indexOf(entityId) > -1 };
                }
                const portfolioEngagementDetails = engagementList.engagementList.filter((item) => filterFunction.predicateFn(item));
                if (portfolioEngagementDetails.length > 0) {
                    isExistsInPortfolio = true;
                }
            }
        });
        return isExistsInPortfolio;
    }

    /*
        Redirect to Search
    */
    private navigateToSearch(searchText: string, searchAttribute: string) {
        const propertyBag = {};
        propertyBag[LogEventConstants.SearchParameters] = searchAttribute;
        propertyBag[LogEventConstants.SearchText] = searchText;
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.NavigateToSearch, LogEventName.GlobalSearch, propertyBag);
        this.fxpRouteService.navigatetoSpecificState(RouteName.Search, { searchText, searchAttribute });
    }

    /**
     * Is the given input a Project ID?
     * Checks for 1 C or I, then a period, then 10 digits, another period, and then 6 digits
     * @param input
     */
    private isInputProjectId(input: string): boolean {
        const projIdPattern = /^[ciCI]\.[0-9]{10}\.[0-9]{6}$/;
        return projIdPattern.test(input);
    }

    /**
     * Is the given input an Engagement ID?
     * Checks for 1 C or I, then period, then 10 digits
     * @param input
     */
    private isInputEngagementId(input: string): boolean {
        const engIdPattern = /^[ciCI]\.[0-9]{10}$/;
        return engIdPattern.test(input);
    }

    /**
     * Gets the current state name (current tab) by parsing the URL on the browser.
     * Uses this information to set the current tab internally by matching to the navigation JSON.
     * Tied to a listener for $stateChangeSuccess.
     */
    private findCurrentState(): void {
        let attributes: INavigationListItemAttribute[] = [];
        const currentState = this.stateService.current.name;
        const routeName = currentState.substring(currentState.lastIndexOf(".") + 1);
        if (this.stateService.current.name.startsWith(RouteName.InternalEngagementDetails)) {
            attributes = this.navigationService.getInternalEngagementTabs(this.stateService);
            this.updateUserPreferenceViewedEntity(this.stateService.params.engagementId, EntityType.Engagement, DeliveryType.Internal);
        } else if (this.stateService.current.name.startsWith(RouteName.EngagementDetails)) {
            attributes = this.navigationService.getEngagementTabs(this.stateService);
            this.updateUserPreferenceViewedEntity(this.stateService.params.engagementId, EntityType.Engagement, DeliveryType.Customer);
        } else if (this.stateService.current.name.startsWith(RouteName.ProjectDetails)) {
            attributes = this.navigationService.getProjectTabs(this.stateService);
            this.updateUserPreferenceViewedEntity(this.stateService.params.projectId, EntityType.Project, DeliveryType.Customer);
        }
        if (attributes.length) {
            const filteredAttributes = attributes.filter((item: INavigationListItemAttribute) => item.routeName === routeName);
            if (filteredAttributes.length) {
                this.currentTab = filteredAttributes[0];
            }
        }
    }

    /**
     * When the state on the UI Router changes, this method will be called.
     * Handles setting tab visibility based on current route
     */
    private uiRouterStateChanged(event, toState: any, toParams, fromState: any, fromParams): void {
        // todo fix types, any type used to be angular.ui.IState
        this.showMyPortfolioTabs = this.shouldStateShowPortfolioTabs(toState.name);
        this.showTabNavigationHeader = this.shouldShowTabNavigationHeader(toState.name);
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.UiRouterStateChanged, LogEventName.GlobalNavigationChange,
            {
                fromState: fromState ? fromState.name : undefined,
                fromParams: fromParams ? JSON.stringify(fromParams) : {},
                toState: toState ? toState.name : undefined,
                toParams: toParams ? JSON.stringify(toParams) : {}
            });
    }

    /**
     * Returns a boolean for if the given state name indicates that My Portfolio and Search tabs should be shown,
     * as opposed to the Engagement/Project page tabs.
     * Used in conjunction with showMyPortfolioTabs flag on the UI.
     * @param name
     */
    private shouldStateShowPortfolioTabs(name: string): boolean {
        return (
            name.startsWith(RouteName.Portfolio) ||
            name.startsWith(RouteName.Search) ||
            name.startsWith(RouteName.Unauthorized)
        );
    }

    /*
    * Updates UserPreference Viewed Entity
    */
    private updateUserPreferenceViewedEntity(entityId: string, entityType: EntityType, deliveryType: DeliveryType): void {
        if (this.userPreference) {
            const viewedEntities: IViewedEntity[] = this.userPreference.viewedEntities;
            if (viewedEntities.filter((obj) => obj.entityId === entityId).length > 0) {
                viewedEntities.filter((obj) => obj.entityId === entityId)[0].lastViewed = moment.utc().toDate();
            } else {
                viewedEntities.push({
                    entityId,
                    lastViewed: moment.utc().toDate(),
                    entityType,
                    deliveryType
                });
            }
            this.userPreference.viewedEntities = viewedEntities.sort((a, b) => new Date(b.lastViewed).getTime() - new Date(a.lastViewed).getTime());
            if (this.userPreference.viewedEntities.length > this.viewedEntitiesLimit) {
                this.userPreference.viewedEntities = this.userPreference.viewedEntities.slice(0, this.viewedEntitiesLimit);
            }
            this.userPreferenceService.saveUserPreference(this.userPreference).then((response: IUserPreference) => {
                this.store.dispatch(new UpdateUserPreference(response));
            });
        }
    }

    /**
     * Retrive and return the search items stored in fxp context
     */
    private getSearchItemsFromStorage(): void {
        this.selectedGlobalSearchItems = [];
        this.contextStorageService.readContent(LocalStorageKey.GlobalSearchHistory).then((data) => {
            this.selectedGlobalSearchItems = JSON.parse(data);
        });
    }

    /**
     * Add selected search in the local storage
     * @param selectedSearch
     */
    private addSearchTextToLocalStorage(selectedSearch: string): void {
        const isSelectedSearchItemInLocalStorage = this.selectedGlobalSearchItems && this.selectedGlobalSearchItems.some((globalSearchItem) => globalSearchItem.toLowerCase() === selectedSearch.toLowerCase());
        if (!isSelectedSearchItemInLocalStorage) {
            this.selectedGlobalSearchItems.unshift(selectedSearch);
        }
        if (this.selectedGlobalSearchItems && this.selectedGlobalSearchItems.length > this.maxLimitForUsersInLocalStorage) {
            this.selectedGlobalSearchItems.pop();
        }
        this.contextStorageService.saveContent(LocalStorageKey.GlobalSearchHistory, JSON.stringify(this.selectedGlobalSearchItems));
    }
}
