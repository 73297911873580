import * as fromInvoices from "./invoices.action";
import { IInvoiceItemModel } from "../../components/invoices/invoice-table-data/invoice-table-data.contract";
import { ILoadableState } from "../reducers";

export interface IInvoicesState extends ILoadableState {
    invoices: IInvoiceItemModel[];
}

export const initialState: IInvoicesState = {
    invoices: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromInvoices.InvoicesAction): { [engagementId: string]: IInvoicesState } {
    switch (action.type) {
        case fromInvoices.InvoicesActionTypes.LOAD_INVOICES: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromInvoices.InvoicesActionTypes.LOAD_MANUAL_INVOICES: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromInvoices.InvoicesActionTypes.LOAD_INVOICES_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                invoices: action.invoices,
                loaded: true,
            };
            return newState;
        }

        case fromInvoices.InvoicesActionTypes.LOAD_INVOICES_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                error: action.errorMessage
            };
            return newState;
        }

        case fromInvoices.InvoicesActionTypes.INVALIDATE_INVOICES: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}