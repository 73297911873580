<section clas="inline-survey-modal">
    <dm-loading [loadingText]="'Submitting Feedback'" [showLoading]="showLoading" [isCustomText]="true"
        *ngIf="isComponentLoading" [errorText]="errorText"></dm-loading>
    <section class="inline-survey-modal__header" *ngIf="!isComponentLoading">
        <h4 class="inline-survey-modal__title" id="modal-title">Share your feedback</h4>
        <button class="dm-transparent-btn margin-left-auto" (click)="closeFeedbackModal()" role="button">
            <span class="icon icon-close cursor-pointer" title="Share your Feedback Close"></span>
        </button>
    </section>
    <ng-container *ngIf="!isComponentLoading">
        <shared-component-control appName="DMApp" componentName="InlineSurveyComponent"
            [componentInputs]="[{key : 'surveyData', value : feedbackData }]"
            [componentOutputKeys]="[{key : 'submitFeedbackSurvey'}]" (componentOutput)="onSubmitFeedback($event)">
        </shared-component-control>
    </ng-container>
</section>