import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ICountryDetails, IStateDetails, ICityDetails } from "../services/contracts/one-profile.contracts";
  
/* Checks the value of the dropdown value, returns validation error if value = select */
export function CountryStateCityDropDownValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: ValidationErrors} | null => {
        const value: ICountryDetails | IStateDetails | ICityDetails = control.value;
        if (!value || ((value as ICountryDetails).CountryName && (value as ICountryDetails).CountryName.toLowerCase() === "select")
            || ((value as IStateDetails).StateName && (value as IStateDetails).StateName.toLowerCase() === "select")
            || ((value as ICityDetails).CityName && (value as ICityDetails).CityName.toLowerCase() === "select")) {
            return { "Invalid Value": { value: control.value } };
        } else {
            return null;
        }      
    };
}