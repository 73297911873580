<section id="edit-engagement-ebs-state-modal">
    <div role="dialog" aria-labelledby="ebsStateEditEngagementHeading" aria-modal="true">
        <div role="document">
            <form name="editEbsStateForm">
                <div class="popUpModalDialog editEbsStateForm">
                    <button *ngIf="deviceFactory.isMobile()" type="button"
                        aria-label="Engagement Request State Change Dialog Close" class="close mar-6 set-focus"
                        id="accessibilityConstants.ClosePopUp" (click)="closeModal()">
                        <span aria-hidden="true" class="icon icon-close"></span>
                    </button>

                    <div class="modal-header modal-noborder">
                        <h4 class="modal-title font-subtitle pull-left" id="ebsStateEditEngagementHeading">
                            Request for EBS State Change
                        </h4>
                        <button *ngIf="!deviceFactory.isMobile()" type="button"
                            aria-label="Engagement Request State Change Dialog Close" class="close set-focus"
                            id="accessibilityConstants.ClosePopUp" (click)="closeModal()"
                            (keydown)="moveFocusPrev($event, 'accessibilityConstants.Submit')">
                            <span aria-hidden="true" class="icon icon-close"></span>
                        </button>
                    </div>
                    <dm-loading [loadingText]="'Updating Engagement Details'" [isCustomText]="true" [errorText]=""
                        [showLoading]="true" *ngIf="isComponentLoading"></dm-loading>
                    <div class="modal-body" *ngIf="!isComponentLoading && selectedEngagement">
                        <section class="modal-body-container">
                            <section class="dm-tile__nopad dm-tile--m-b-20">
                                <h5 class="dm-tile__title dm-tile--p-0-mobile dm-tile--m-b-4 dm-tile--m-t-2">
                                    EBS Name
                                </h5>
                                <p class="font-caption-alt dm-tile--m-b-0 dm-tile__content text-capitilize">
                                    Engagement: {{selectedEngagement.name}}
                                </p>
                            </section>
                            <section class="dm-tile__nopad dm-tile--m-b-20">
                                <h5 class="dm-tile__title dm-tile--p-0-mobile dm-tile--m-b-4 dm-tile--m-t-2">
                                    Current EBS State
                                </h5>
                                <p class="font-caption-alt dm-tile--m-b-0 dm-tile__content text-capitilize">
                                    {{statusDescription}}
                                </p>
                            </section>
                            <section class="dm-tile__nopad ebs-section__wrapper">
                                <section class="input__label dm-tile--m-b-12">
                                    <label class="input__label--text" for="dm-ebs-state-engagement">
                                        <span>Request Change to</span>
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4"
                                            [dmPlacement]="deviceFactory.isMobile() ? 'bottom' : 'bottom-left'"
                                            [ngbTooltipContent]="requestStateChangeText"
                                            [dmIcon]="'tile__icon--info icon icon-info'"
                                            ariaLabelToolTip="Request Change to Information"
                                            (click)="tooltipClicked(LogEventConstants.RequestStateChangeTooltip)">
                                        </dm-tooltip>
                                        <span class="required-mark" aria-hidden="true">*</span>
                                    </label>
                                    <section class="input__select--icon">
                                        <select id="dm-ebs-state-engagement" #ebsStateForm="ngModel" name="ebsStateForm"
                                            class="input__select input__select--big set-focus" [(ngModel)]="ebsState"
                                            (change)="onEbsStateChange()" [disabled]="!!userRequestedEbsState" required
                                            [ngClass]="{'disabled-class': userRequestedEbsState}">
                                            <option class="text-capitilize" tabindex="0" value="">Select</option>
                                            <option *ngFor="let item of deliveryStateListOptions"
                                                class="text-capitilize" [ngValue]="item.value">{{item.displayText}}
                                            </option>
                                        </select>
                                        <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                                    </section>
                                </section>
                            </section>
                            <section class="dm-tile__nopad font-caption-alt clearfix">
                                <p class="dm-tile--m-b-12 dm-tile--p-t-12-tab no-mar-tablet"
                                    *ngIf="ebsState === 'TECO'">
                                    Before you submit your request for Technically Complete you MUST complete the steps
                                    outlined in this
                                    <a href="https://aka.ms/pjm-job-aid/teco" target="_blank" class="dm-link"
                                        aria-label="job aid. open link in new tab"
                                        (click)="tooltipClicked(LogEventConstants.LearnMoreTecoClick)">job aid. </a>
                                    Failure to complete the steps will result in delays in getting your change
                                    processed.
                                </p>
                                <p class="dm-tile--m-b-12 dm-tile--p-t-12-tab no-mar-tablet"
                                    *ngIf="ebsState === 'Closed'">
                                    Please ensure that you have completed the following checklist before setting a
                                    project to Closed:
                                    <a href class="dm-link" aria-label="closed checklist">Closed Checklist</a>
                                </p>
                            </section>
                            <section class="font-caption-alt">
                                <p class="dm-tile--m-b-0 dm-tile--p-t-12-tab">
                                    * Changing the EBS State field on an engagement will change all of its projects',
                                    services', and tasks' EBS States as well.
                                </p>
                            </section>
                            <section class="font-caption-alt" *ngIf="isStateChangeErrorMessage" style="color:red;">
                                <p class="dm-tile--m-b-0 dm-tile--p-t-12-tab error-message">
                                    Engagement is in created state. Please click Release and Activate on Financials tab.
                                </p>
                            </section>
                            <section class="font-caption-alt pubsec_errortext-wrapper dm-tile--p-8 dm-tile--m-t-6"
                                *ngIf="userRequestedEbsState">
                                <span class="pubsec_errortext-icon icon icon-info"></span>
                                <span class="pubsec_errortext">
                                    An EBS State change to "{{userRequestedEbsState}}" is currently pending approval.
                                </span>
                            </section>
                        </section>
                    </div>
                </div>
                <div class="modal-footer modal-noborder" *ngIf="!deviceFactory.isMobile() && !isComponentLoading">
                    <button type="button" id="accessibilityConstants.Closebutton" class="fxp-btn fxp-btn--secondary"
                        (click)="closeModal()"
                        (keydown)="moveFocusNext($event, 'accessibilityConstants.Submit')">Cancel</button>
                    <button class="fxp-btn fxp-btn--primary" id="accessibilityConstants.Submit" type="button"
                        (click)="updateEbsState()"
                        (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp')"
                        [disabled]="isSubmitBtnDisabled">
                        Submit
                    </button>
                </div>
                <div class="modal-footer" *ngIf="deviceFactory.isMobile()">
                    <button class="fxp-btn fxp-btn--primary popup-button" id="accessibilityConstants.Submit"
                        type="button" (click)="updateEbsState()" [disabled]="isSubmitBtnDisabled">
                        Submit
                    </button>
                    <button type="button" class="fxp-btn fxp-btn--secondary" (click)="closeModal()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</section>