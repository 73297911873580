<section id="recently-viewed" aria-label="Recently Viewed">
    <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'280px'" [tileHeight]="'280px'">
        <dm-loading loader [loadingText]="loadingText" [showLoading]="showLoading" *ngIf="isComponentLoading">
        </dm-loading>
        <div role="grid" class="dm-p-b-10" *ngIf="!isComponentLoading && !isServerError" aria-label="Recently Viewed">
            <div role="rowgroup" *ngIf="recentlyViewedEntityList.length">
                <div role="row" class="recently-viewed-row">
                    <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-5"> Name
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-5">Last Viewed
                    </div>
                </div>
                <div role="row" class="recently-viewed-row" *ngFor="let recentViewList of recentlyViewedEntityList">
                    <ng-container
                        *ngIf="recentViewList.entityType.toLowerCase() === entityType.Engagement.toLowerCase()">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-5"
                            *ngIf="recentViewList.deliveryType.toLowerCase() === deliveryType.Customer.toLowerCase()">
                            <b>Engagement:
                                <a [uiSref]="RouteName.EngagementSummaryV2" class="dm-link"
                                    (click)="logRecentlyViewedClicks(recentViewList.entityName)"
                                    [uiParams]="{engagementId: recentViewList.entityId, eventName: LogEventName.PortfolioNavigationFromRecentlyViewed}"
                                    id="{{ 'recentlyViewed-' + recentViewList.entityId }}">{{recentViewList.entityName}}</a></b>
                            <p class="font-caption-alt">EBS:
                                <span>{{recentViewList.entityId}}</span>
                            </p>
                            <span class="sr-only">press tab to navigate to link</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-5"
                            *ngIf="recentViewList.deliveryType.toLowerCase() === deliveryType.Internal.toLowerCase()">
                            <b>Internal:
                                <a [uiSref]="RouteName.InternalEngagementSummary" class="dm-link"
                                    (click)="logRecentlyViewedClicks(recentViewList.entityName)"
                                    [uiParams]="{engagementId: recentViewList.entityId, eventName: LogEventName.PortfolioNavigationFromRecentlyViewed}"
                                    id="{{ 'recentlyViewed-' + recentViewList.entityId }}">{{recentViewList.entityName}}</a></b>
                            <p class="font-caption-alt">EBS:
                                <span>{{recentViewList.entityId}}</span>
                            </p>
                            <span class="sr-only">press tab to navigate to link</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-10">
                            {{recentViewList.timeElapsed}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="recentViewList.entityType.toLowerCase() === entityType.Project.toLowerCase()">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-5 "><b>Project:
                                <a [uiSref]="RouteName.ProjectSummaryV2" class="dm-link"
                                    (click)="logRecentlyViewedClicks(recentViewList.entityName)"
                                    [uiParams]="{projectId: recentViewList.entityId, eventName: LogEventName.PortfolioNavigationFromRecentlyViewed}"
                                    id="{{ 'recentlyViewed-' + recentViewList.entityId }}">{{recentViewList.entityName}}
                                </a></b>
                            <p class="font-caption-alt">EBS:
                                <span>{{recentViewList.entityId}}</span>
                            </p>
                            <span class="sr-only">press tab to navigate to link</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-10">
                            {{recentViewList.timeElapsed}}
                        </div>
                    </ng-container>
                </div>
            </div>
            <dm-no-data *ngIf="!recentlyViewedEntityList.length" [noDataText]="noItemsMessage"></dm-no-data>
        </div>
        <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
    </dm-tile>
</section>