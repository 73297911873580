import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WbsResourceRequestsComponent } from "./wbs-resource-requests.component";
import { WBSService } from "../../../../common/services/wbs.service";
import { MyPortfolioService } from "../../../../common/services/my-portfolio.service";
import { TruncateModalModule } from "../../../staffing-command-bar/staffing-command-bar-modals/truncate-modal/truncate-modal.module";
import { RmModalModule } from "../../../staffing-command-bar/staffing-command-bar-modals/rm-modal/rm-modal.module";
import { DmUserProfileModule } from "../../../../components/shared/user-name/user-name.module";

@NgModule({
    declarations: [
        WbsResourceRequestsComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        TruncateModalModule,
        RmModalModule,
        NgbModule,
        DmUserProfileModule
    ],
    providers: [
        ConfigManagerService,
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        DMNotificationService,
        EngagementDetailService,
        MyPortfolioService,
        SharedFunctionsService,
        WBSService
    ],
    entryComponents: [
        WbsResourceRequestsComponent,
    ],
    exports: [
        WbsResourceRequestsComponent,
    ]
})
export class WbsResourceRequestsModule { }