<section id="engagement-teco-prevalidation" class="teco-prevalidation-section">
    <dm-loading loader [loadingText]="'Validating items...'" [isCustomText]="true" [showLoading]="showLoading"
        *ngIf="isComponentLoading">
    </dm-loading>
    <section *ngIf="!isComponentLoading" role="grid">
        <div role="rowgroup" class="prevalidationsTable">
            <div role="row" class="prevalidationsTable__row">
                <div role="columnheader" class="prevalidationsTable__headerCell text-align--left">
                    Validation
                </div>
                <div role="columnheader" class="prevalidationsTable__headerCell text-align--left">
                    Details
                </div>
                <div role="columnheader" class="prevalidationsTable__headerCell text-align--left">
                    Status
                </div>
                <div role="columnheader" class="prevalidationsTable__headerCell text-align--center">
                    Comments
                </div>
            </div>
        </div>
        <div role="rowgroup" class="prevalidationsTable__dataGrid">
            <div *ngIf="engagementPoccValidationViewModel && engagementPoccValidationViewModel.isActive" role="row"
                class="prevalidationsTable__row">
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    {{engagementPoccValidationViewModel.title}}
                    <span class="font-weight-610">(FF Engagement)</span>
                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'bottom-left'"
                        [ngbTooltipContent]="poccValidationInfo" [dmIcon]="'tile__icon--info icon icon-info'"
                        ariaLabelToolTip="POCC Validation Information">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    POCC:
                    <span class="font-weight-610">{{engagementPoccValidationViewModel.pocc}}%</span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--center dm-p-t-20"
                    [ngSwitch]="engagementPoccValidationViewModel?.status">
                    <span *ngSwitchCase="'Passed'">
                        <i class="icon icon-check icon-rounded dm-success"></i>
                    </span>
                    <span *ngSwitchCase="'Evaluating'">
                        <i class="icon record-progress-icon"></i>
                    </span>
                    <span *ngSwitchCase="'ApprovedWithComment'">
                        <i class="icon icon-exclamation icon-rounded dm-warning-round"></i>
                    </span>
                    <span *ngSwitchCase="'Failed'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                    <span *ngSwitchCase="'Invalid'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <button *ngIf="shouldShowCommentIcon(engagementPoccValidationViewModel)" role="button"
                        class="action__trigger--edit-comment"
                        (click)="onClickEditComment(engagementPoccValidationViewModel)"
                        [attr.aria-label]="'EditengagementPoccValidationViewModelComment.'">
                        <i class="icon-full icon-comment"></i>
                    </button>
                    <div *ngIf="shouldShowCommentInput(engagementPoccValidationViewModel)">
                        <div role="row">
                            <textarea id="engagementPoccValidation_comment" maxlength="255"
                                class="prevalidationsTable__commentInput text-align--left"
                                [(ngModel)]="engagementPoccValidationViewModel.comment"
                                aria-label="enter comment"></textarea>
                        </div>
                        <div role="row" class="comment-btn-section">
                            <div>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(engagementPoccValidationViewModel)"
                                    [attr.aria-label]="'EditengagementPoccValidationViewModelComment.'">
                                    Close
                                </button>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(engagementPoccValidationViewModel)"
                                    [attr.aria-label]="'EditengagementPoccValidationViewModelComment.'">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>

                    <div role="row" *ngIf="shouldShowCommentText(engagementPoccValidationViewModel)">
                        {{engagementPoccValidationViewModel.comment}}
                        <button role="button" class="action__trigger--edit-comment"
                            (click)="onClickEditComment(engagementPoccValidationViewModel)"
                            [attr.aria-label]="'EditengagementPoccValidationViewModelComment.'">
                            <i class="icon-full icon-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="unearnedBalanceViewModel && unearnedBalanceViewModel.isActive" role="row"
                class="prevalidationsTable__row">
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    {{unearnedBalanceViewModel.title}}
                    <span *ngIf="unearnedBalanceViewModel?.isMandatory" class="bif--wrapper dm-m-r-4 dm-badge-v2">
                        <span class="bif--content">Mandatory</span>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    Invoiced Revenue:
                    <span class="font-weight-610">{{unearnedBalanceViewModel.invoicedRevenue | currency: planCurrency :
                        'symbol-narrow'}}</span>
                    <br />
                    Recognized Revenue:
                    <span class="font-weight-610">{{unearnedBalanceViewModel.recognizedRevenue | currency: planCurrency
                        : 'symbol-narrow'}}</span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--center dm-p-t-20"
                    [ngSwitch]="unearnedBalanceViewModel?.status">
                    <span *ngSwitchCase="'Passed'">
                        <i class="icon icon-check icon-rounded dm-success"></i>
                    </span>
                    <span *ngSwitchCase="'Evaluating'">
                        <i class="icon record-progress-icon"></i>
                    </span>
                    <span *ngSwitchCase="'ApprovedWithComment'">
                        <i class="icon icon-exclamation icon-rounded dm-warning-round"></i>
                    </span>
                    <span *ngSwitchCase="'Failed'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                    <span *ngSwitchCase="'Invalid'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <button *ngIf="shouldShowCommentIcon(unearnedBalanceViewModel)" role="button"
                        class="action__trigger--edit-comment" (click)="onClickEditComment(unearnedBalanceViewModel)"
                        [attr.aria-label]="'unearnedBalanceViewModelComment.'">
                        <i class="icon-full icon-comment"></i>
                    </button>
                    <div *ngIf="shouldShowCommentInput(unearnedBalanceViewModel)">
                        <div role="row">
                            <textarea id="unearnedBalanceView_comment" maxlength="255"
                                class="prevalidationsTable__commentInput text-align--left"
                                [(ngModel)]="unearnedBalanceViewModel.comment" aria-label="enter comment"></textarea>
                        </div>
                        <div role="row" class="comment-btn-section">
                            <div>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(unearnedBalanceViewModel)"
                                    [attr.aria-label]="'unearnedBalanceViewModelComment.close'">
                                    Close
                                </button>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(unearnedBalanceViewModel)"
                                    [attr.aria-label]="'unearnedBalanceViewModelComment.add'">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div role="row" *ngIf="shouldShowCommentText(unearnedBalanceViewModel)">
                        {{unearnedBalanceViewModel.comment}}
                        <button role="button" class="action__trigger--edit-comment"
                            (click)="onClickEditComment(unearnedBalanceViewModel)"
                            [attr.aria-label]="'unearnedBalanceViewModelComment.edit'">
                            <i class="icon-full icon-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="unbilledBalanceViewModel && unbilledBalanceViewModel.isActive" role="row"
                class="prevalidationsTable__row">
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    {{unbilledBalanceViewModel.title}}
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    Contract Revenue:
                    <span class="font-weight-610">{{unbilledBalanceViewModel.contractualRevenue | currency: planCurrency
                        : 'symbol-narrow'}}</span>
                    <br />
                    Recognized Revenue:
                    <span class="font-weight-610">{{unbilledBalanceViewModel.recognizedRevenue | currency: planCurrency
                        : 'symbol-narrow'}}</span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--center dm-p-t-20"
                    [ngSwitch]="unbilledBalanceViewModel?.status">
                    <span *ngSwitchCase="'Passed'">
                        <i class="icon icon-check icon-rounded dm-success"></i>
                    </span>
                    <span *ngSwitchCase="'Evaluating'">
                        <i class="icon record-progress-icon"></i>
                    </span>
                    <span *ngSwitchCase="'ApprovedWithComment'">
                        <i class="icon icon-exclamation icon-rounded dm-warning-round"></i>
                    </span>
                    <span *ngSwitchCase="'Failed'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                    <span *ngSwitchCase="'Invalid'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <button *ngIf="shouldShowCommentIcon(unbilledBalanceViewModel)" role="button"
                        class="action__trigger--edit-comment" (click)="onClickEditComment(unbilledBalanceViewModel)"
                        [attr.aria-label]="'unbilledBalanceViewModelComment.'">
                        <i class="icon-full icon-comment"></i>
                    </button>
                    <div *ngIf="shouldShowCommentInput(unbilledBalanceViewModel)">
                        <div role="row">
                            <textarea id="unbilledBalanceViewModel_comment" maxlength="255"
                                class="prevalidationsTable__commentInput text-align--left"
                                [(ngModel)]="unbilledBalanceViewModel.comment" aria-label="enter comment"></textarea>
                        </div>
                        <div role="row" class="comment-btn-section">
                            <div>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(unbilledBalanceViewModel)"
                                    [attr.aria-label]="'unbilledBalanceViewModelComment.close'">
                                    Close
                                </button>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(unbilledBalanceViewModel)"
                                    [attr.aria-label]="'unbilledBalanceViewModelComment.add'">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div role="row" *ngIf="shouldShowCommentText(unbilledBalanceViewModel)">
                        {{unbilledBalanceViewModel.comment}}
                        <button role="button" class="action__trigger--edit-comment"
                            (click)="onClickEditComment(unbilledBalanceViewModel)"
                            [attr.aria-label]="'unbilledBalanceViewModelComment.edit'">
                            <i class="icon-full icon-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="revenueLtdValidationViewModel && revenueLtdValidationViewModel.isActive" role="row"
                class="prevalidationsTable__row">
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    {{revenueLtdValidationViewModel.title}}
                    <span class="font-weight-610">(T&M Engagement)</span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    Actual Revenue:
                    <span class="font-weight-610">{{revenueLtdValidationViewModel.actualRevenue | currency: planCurrency
                        : 'symbol-narrow'}}</span>
                    <br />
                    Planned Revenue:
                    <span class="font-weight-610">{{revenueLtdValidationViewModel.plannedRevenue | currency:
                        planCurrency : 'symbol-narrow'}}</span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--center dm-p-t-20"
                    [ngSwitch]="revenueLtdValidationViewModel?.status">
                    <span *ngSwitchCase="'Passed'">
                        <i class="icon icon-check icon-rounded dm-success"></i>
                    </span>
                    <span *ngSwitchCase="'Evaluating'">
                        <i class="icon record-progress-icon"></i>
                    </span>
                    <span *ngSwitchCase="'ApprovedWithComment'">
                        <i class="icon icon-exclamation icon-rounded dm-warning-round"></i>
                    </span>
                    <span *ngSwitchCase="'Failed'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                    <span *ngSwitchCase="'Invalid'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <button *ngIf="shouldShowCommentIcon(revenueLtdValidationViewModel)" role="button"
                        class="action__trigger--edit-comment"
                        (click)="onClickEditComment(revenueLtdValidationViewModel)"
                        [attr.aria-label]="'revenueLtdValidationViewModelComment.'">
                        <i class="icon-full icon-comment"></i>
                    </button>
                    <div *ngIf="shouldShowCommentInput(revenueLtdValidationViewModel)">
                        <div role="row">
                            <textarea id="revenueLtdValidationViewModel_comment" maxlength="255"
                                class="prevalidationsTable__commentInput text-align--left"
                                [(ngModel)]="revenueLtdValidationViewModel.comment"
                                aria-label="enter comment"></textarea>
                        </div>
                        <div role="row" class="comment-btn-section">
                            <div>
                                <button class="comment-btn" role="button"
                                    [attr.aria-label]="'revenueLtdValidationViewModelComment.close'">
                                    Close
                                </button>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(revenueLtdValidationViewModel)"
                                    [attr.aria-label]="'revenueLtdValidationViewModelComment.add'">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div role="row" *ngIf="shouldShowCommentText(revenueLtdValidationViewModel)">
                        {{revenueLtdValidationViewModel.comment}}
                        <button role="button" class="action__trigger--edit-comment"
                            (click)="onClickEditComment(revenueLtdValidationViewModel)"
                            [attr.aria-label]="'revenueLtdValidationViewModelComment.edit'">
                            <i class="icon-full icon-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="subconValidationViewModel && subconValidationViewModel.isActive" role="row"
                class="prevalidationsTable__row">
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    {{subconValidationViewModel.title}}
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <div role="row">
                        PO Amount:
                        <span class="font-weight-610">{{subconValidationViewModel.ffPO | currency: planCurrency :
                            'symbol-narrow'}}</span>
                        <br />
                        Consumed Amount:
                        <span class="font-weight-610">{{subconValidationViewModel.gr | currency: planCurrency :
                            'symbol-narrow'}}</span>
                        <br />
                        Invoiced Amount:
                        <span class="font-weight-610">{{subconValidationViewModel.invoiced | currency:
                            planCurrency : 'symbol-narrow'}}</span>
                    </div>
                    <div role="row"
                        *ngIf="subconValidationViewModel.purchaseOrders && subconValidationViewModel.purchaseOrders.length > 0">
                        <div role="grid">
                            <div role="rowgroup" class="subconTable">
                                <div role="row" class="subconTable__row">
                                    <div role="columnheader" class="subconTable__headerCell text-align--left">
                                        PO #
                                    </div>
                                    <div role="columnheader" class="subconTable__headerCell text-align--right">
                                        <div role="row">Total</div>
                                        <div role="row" class="text-align--right">({{planCurrency}})</div>
                                    </div>
                                    <div role="columnheader" class="subconTable__headerCell text-align--right">
                                        <div role="row">Consumed</div>
                                        <div role="row" class="text-align--right">({{planCurrency}})</div>
                                    </div>
                                    <div role="columnheader" class="subconTable__headerCell text-align--right">
                                        <div role="row">Invoiced</div>
                                        <div role="row" class="text-align--right">({{planCurrency}})</div>
                                    </div>
                                </div>
                                <div role="rowgroup" class="subconTable__fixedHeight">
                                    <div *ngFor="let purchaseOrder of subconValidationViewModel.purchaseOrders"
                                        style="display: block">
                                        <div role="row" class="subconTable__row">
                                            <div role="gridcell" class="subconTable__bodyCell">
                                                {{purchaseOrder.poNumber}}
                                            </div>
                                            <div role="gridcell"
                                                class="text-align--right subconTable__bodyCell font-weight-610">
                                                {{purchaseOrder.totalAmount}}
                                            </div>
                                            <div role="gridcell"
                                                class="text-align--right subconTable__bodyCell font-weight-610">
                                                {{purchaseOrder.consumedAmount}}
                                            </div>
                                            <div role="gridcell"
                                                class="text-align--right subconTable__bodyCell font-weight-610">
                                                {{purchaseOrder.invoicedAmount}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--center dm-p-t-20"
                    [ngSwitch]="subconValidationViewModel?.status">
                    <span *ngSwitchCase="'Passed'">
                        <i class="icon icon-check icon-rounded dm-success"></i>
                    </span>
                    <span *ngSwitchCase="'Evaluating'">
                        <i class="icon record-progress-icon"></i>
                    </span>
                    <span *ngSwitchCase="'ApprovedWithComment'">
                        <i class="icon icon-exclamation icon-rounded dm-warning-round"></i>
                    </span>
                    <span *ngSwitchCase="'Failed'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                    <span *ngSwitchCase="'Invalid'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <button *ngIf="shouldShowCommentIcon(subconValidationViewModel)" role="button"
                        class="action__trigger--edit-comment" (click)="onClickEditComment(subconValidationViewModel)"
                        [attr.aria-label]="'subconValidationViewModelComment.'">
                        <i class="icon-full icon-comment"></i>
                    </button>
                    <div *ngIf="shouldShowCommentInput(subconValidationViewModel)">
                        <div role="row">
                            <textarea id="subconValidationViewModel_comment" maxlength="255"
                                class="prevalidationsTable__commentInput text-align--left"
                                [(ngModel)]="subconValidationViewModel.comment" aria-label="enter comment"></textarea>
                        </div>
                        <div role="row" class="comment-btn-section">
                            <div>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(subconValidationViewModel)"
                                    [attr.aria-label]="'subconValidationViewModelComment.close'">
                                    Close
                                </button>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(subconValidationViewModel)"
                                    [attr.aria-label]="'subconValidationViewModelComment.add'">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div role="row" *ngIf="shouldShowCommentText(subconValidationViewModel)">
                        {{subconValidationViewModel.comment}}
                        <button role="button" class="action__trigger--edit-comment"
                            (click)="onClickEditComment(subconValidationViewModel)"
                            [attr.aria-label]="'subconValidationViewModel.edit'">
                            <i class="icon-full icon-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="costHoursValidationViewModel && costHoursValidationViewModel.isActive" role="row"
                class="prevalidationsTable__row">
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    {{costHoursValidationViewModel.title}}
                    <span *ngIf="costHoursValidationViewModel?.isMandatory" class="bif--wrapper dm-m-r-4 dm-badge-v2">
                        <span class="bif--content">Mandatory</span>
                    </span>
                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'bottom-left'"
                        [ngbTooltipContent]="costHoursValidationInfo" [dmIcon]="'tile__icon--info icon icon-info'"
                        ariaLabelToolTip="Cost Hours Validation Information">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <div role="row">
                        Planned Hours:
                        <span class="font-weight-610">{{costHoursValidationViewModel.plannedHours}}</span>
                        <br />
                        Actual Hours:
                        <span class="font-weight-610">{{costHoursValidationViewModel.actualHours}}</span>
                    </div>
                    <dm-loading loader [loadingText]="'loading'" [showLoading]="'true'" [isCustomText]="true"
                        *ngIf="projectLaborConsumptionViewModel.failedProjects.length == 0 && costHoursValidationViewModel.status === 'Failed'">
                    </dm-loading>
                    <div role="row"
                        *ngIf="projectLaborConsumptionViewModel.failedProjects.length > 0 && costHoursValidationViewModel.status === 'Failed'">
                        <div role="grid">
                            <div role="rowgroup" class="projectActualsTable">
                                <div role="row" class="projectActualsTable__row">
                                    <div role="columnheader" class="projectActualsTable__headerCell text-align--left">
                                        Project ID
                                    </div>
                                    <div role="columnheader" class="projectActualsTable__headerCell text-align--right">
                                        <div role="row">CFP</div>
                                        <div role="row" class="text-align--right">(Hours)</div>
                                    </div>
                                    <div role="columnheader" class="projectActualsTable__headerCell text-align--right">
                                        <div role="row">Actuals</div>
                                        <div role="row" class="text-align--right">(Hours)</div>
                                    </div>
                                </div>
                            </div>
                            <div role="rowgroup" class="projectActualsTable__fixedHeight">
                                <div *ngFor="let project of getAllLoadedProjectLaborConsumption()"
                                    style="display: block">
                                    <div role="row" class="projectActualsTable__row">
                                        <div role="gridcell" class="projectActualsTable__bodyCell">
                                            {{project.projectId}}
                                        </div>
                                        <div role="gridcell"
                                            class="text-align--right projectActualsTable__bodyCell font-weight-610">
                                            {{project.cfpLabor}}
                                        </div>
                                        <div role="gridcell"
                                            class="text-align--right projectActualsTable__bodyCell font-weight-610">
                                            {{project.actualLabor}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--center dm-p-t-20"
                    [ngSwitch]="costHoursValidationViewModel?.status">
                    <span *ngSwitchCase="'Passed'">
                        <i class="icon icon-check icon-rounded dm-success"></i>
                    </span>
                    <span *ngSwitchCase="'Evaluating'">
                        <i class="icon record-progress-icon"></i>
                    </span>
                    <span *ngSwitchCase="'ApprovedWithComment'">
                        <i class="icon icon-exclamation icon-rounded dm-warning-round"></i>
                    </span>
                    <span *ngSwitchCase="'Failed'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                    <span *ngSwitchCase="'Invalid'">
                        <i class="icon icon-close icon-rounded dm-error"></i>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell text-align--left dm-p-t-20">
                    <button *ngIf="shouldShowCommentIcon(costHoursValidationViewModel)" role="button"
                        class="action__trigger--edit-comment" (click)="onClickEditComment(costHoursValidationViewModel)"
                        [attr.aria-label]="'costHoursValidationViewModelComment.'">
                        <i class="icon-full icon-comment"></i>
                    </button>
                    <div *ngIf="shouldShowCommentInput(costHoursValidationViewModel)">
                        <div role="row">
                            <textarea id="costHoursValidation_comment" maxlength="255"
                                class="prevalidationsTable__commentInput text-align--left"
                                [(ngModel)]="costHoursValidationViewModel.comment"
                                aria-label="enter comment"></textarea>
                        </div>
                        <div role="row" class="comment-btn-section">
                            <div>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(costHoursValidationViewModel)"
                                    [attr.aria-label]="'costHoursValidationViewModel.close'">
                                    Close
                                </button>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(costHoursValidationViewModel)"
                                    [attr.aria-label]="'costHoursValidationViewModel.add'">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div role="row" *ngIf="shouldShowCommentText(costHoursValidationViewModel)">
                        {{costHoursValidationViewModel.comment}}
                        <button role="button" class="action__trigger--edit-comment"
                            (click)="onClickEditComment(costHoursValidationViewModel)"
                            [attr.aria-label]="'costHoursValidationViewModel.edit'">
                            <i class="icon-full icon-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="engagementECIFconsumedValidation && engagementECIFconsumedValidation.isActive" role="row"
                class="prevalidationsTable__row">
                <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--left dm-p-t-20">
                    {{engagementECIFconsumedValidation.title}}
                    <span *ngIf="engagementECIFconsumedValidation?.isMandatory"
                        class="bif--wrapper dm-m-r-4 dm-badge-v2">
                        <span class="bif--content">Mandatory</span>
                    </span>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--left dm-p-t-20">
                    <dm-slider [sliderId]="ecifToggle" [popoverText]="Yes"
                        [isToggleChecked]="engagementECIFconsumedValidation?.status === 'Passed'"
                        [unCheckedLabel]="'No'" [checkedLabel]="'Yes'" [attr.aria-label]="isECIFConsumed"
                        [isLabelClickable]="false" [isDisabled]="'false'"
                        (onToggleChange)="validationToggled(engagementECIFconsumedValidation, $event)">
                    </dm-slider>
                </div>
                <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--center dm-p-t-20"></div>
                <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--left dm-p-t-20">
                    <button *ngIf="shouldShowCommentIcon(engagementECIFconsumedValidation)" role="button"
                        class="action__trigger--edit-comment"
                        (click)="onClickEditComment(engagementECIFconsumedValidation)"
                        [attr.aria-label]="'engagementECIFconsumedValidationViewModelComment.'">
                        <i class="icon-full icon-comment"></i>
                    </button>
                    <div *ngIf="shouldShowCommentInput(engagementECIFconsumedValidation)">
                        <div role="row">
                            <textarea id="costHoursValidation_comment" maxlength="255"
                                class="prevalidationsTable__commentInput text-align--left"
                                [(ngModel)]="engagementECIFconsumedValidation.comment"
                                aria-label="enter comment"></textarea>
                        </div>
                        <div role="row" class="comment-btn-section">
                            <div>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(engagementECIFconsumedValidation)"
                                    [attr.aria-label]="'engagementECIFconsumedValidation.close'">
                                    Close
                                </button>
                                <button class="comment-btn" role="button"
                                    (click)="onClickOkComment(engagementECIFconsumedValidation)"
                                    [attr.aria-label]="'engagementECIFconsumedValidation.add'">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div role="row" *ngIf="shouldShowCommentText(engagementECIFconsumedValidation)">
                        {{engagementECIFconsumedValidation.comment}}
                        <button role="button" class="action__trigger--edit-comment"
                            (click)="onClickEditComment(engagementECIFconsumedValidation)"
                            [attr.aria-label]="'engagementECIFconsumedValidation.edit'">
                            <i class="icon-full icon-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="engagementTEsubmittedValidation && engagementTEsubmittedValidation.isActive" role="row"
            class="prevalidationsTable__row">
            <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--left dm-p-t-20">
                {{engagementTEsubmittedValidation.title}}
                <span class="bif--wrapper dm-m-r-4 dm-badge-v2">
                    <span class="bif--content">Mandatory</span>
                </span>
            </div>
            <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--left dm-p-t-20">
                <dm-slider [sliderId]="timeAndExpenseToggle" [popoverText]="Yes"
                    [isToggleChecked]="engagementTEsubmittedValidation?.status === 'Passed'" [unCheckedLabel]="'No'"
                    [checkedLabel]="'Yes'" [attr.aria-label]="isTimeAndExpenseSubmitted" [isLabelClickable]="false"
                    [isDisabled]="'false'"
                    (onToggleChange)="validationToggled(engagementTEsubmittedValidation, $event)">
                </dm-slider>
            </div>
            <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--center dm-p-t-20"></div>
            <div role="gridcell" class="prevalidationsTable__bodyCell-no-border text-align--left dm-p-t-20">
                <button *ngIf="shouldShowCommentIcon(engagementTEsubmittedValidation)" role="button"
                    class="action__trigger--edit-comment" (click)="onClickEditComment(engagementTEsubmittedValidation)"
                    [attr.aria-label]="'engagementTEsubmittedValidationComment.'">
                    <i class="icon-full icon-comment"></i>
                </button>
                <div *ngIf="shouldShowCommentInput(engagementTEsubmittedValidation)">
                    <div role="row">
                        <textarea id="costHoursValidation_comment" maxlength="255"
                            class="prevalidationsTable__commentInput text-align--left"
                            [(ngModel)]="engagementTEsubmittedValidation.comment" aria-label="enter comment"></textarea>
                    </div>
                    <div role="row" class="comment-btn-section">
                        <div>
                            <button class="comment-btn" role="button"
                                (click)="onClickOkComment(engagementTEsubmittedValidation)"
                                [attr.aria-label]="'engagementTEsubmittedValidation.close'">
                                Close
                            </button>
                            <button class="comment-btn" role="button"
                                (click)="onClickOkComment(engagementTEsubmittedValidation)"
                                [attr.aria-label]="'engagementTEsubmittedValidation.add'">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <div role="row" *ngIf="shouldShowCommentText(engagementTEsubmittedValidation)">
                    {{engagementTEsubmittedValidation.comment}}
                    <button role="button" class="action__trigger--edit-comment"
                        (click)="onClickEditComment(engagementTEsubmittedValidation)"
                        [attr.aria-label]="'engagementTEsubmittedValidation.edit'">
                        <i class="icon-full icon-edit"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="display-grid">
            <p class="dm-info-msg--primary dm-m-b-5">
                Mandatory validations needs to be passed and cannot be attested in order
                to submit the request
            </p>
            <p class="dm-info-msg--primary">All failed non-mandatory checks require a comment.</p>
        </div>
    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</section>