<section class="dm-add-project" role="dialog" aria-label="Add Project"
    [ngClass]="isComponentModal && !isComponentLoading? 'dm-p-20 display-inline-block' : ''">
    <dm-loading [isCustomText]="true" [loadingText]="loadingText" [errorText]="errorText" [showLoading]="showLoading"
        *ngIf="isComponentLoading"></dm-loading>
    <section class="col-sm-12 dm-p-x-imp-0 dm-add-project__header display-flex-imp" *ngIf="!isComponentModal">

        <button attr.id="projectSection-{{projectIndex}}" (click)="toggleProject()" [attr.aria-expanded]="showProject"
            class="font-caption-alt dm-transparent-btn" tabindex="0"
            role="button" aria-label="showProject ? Collapse Project Details Section : Expand Project Details Section">
            <div title="Expand Project Details Section"
                class="dm-icon-holder" *ngIf="!showProject">
                <span class="icon icon-chevron-up"></span>
            </div>
            <div title="Collapse Project Details Section"
                class="dm-icon-holder" *ngIf="showProject">
                <span class="icon icon-chevron-down"></span>
            </div>
            Project {{projectIndex}}
            / <strong *ngIf="projectForm && projectName">{{projectFormValue?.projectName}}</strong>
        </button>
        <div class="dm-add-project__toolbar">
            <button [attr.aria-label]="'Delete Project' + projectIndex + '/' + projectFormValue?.projectName"
                class="dm-transparent-btn dm-p-5 dm-m-r-imp-4" (click)="onDeleteProject(projectIndex)">
                <span title="Delete Project" class="icon icon-delete pull-right"></span>
            </button>
        </div>
    </section>
    <section class="col-sm-12 dm-p-x-imp-0" *ngIf="isComponentModal && !isComponentLoading">
        <h4>Add Project</h4>
    </section>

    <section class="col-sm-12 dm-p-x-imp-0" *ngIf="showProject && !isComponentLoading"
        [ngClass]="{'add-project-component-section': !isComponentModal}">
        <form *ngIf="projectForm" [formGroup]="projectForm" (ngSubmit)="createProject()">
            <section class="dm-p-l-imp-0" [ngClass]="isComponentModal ? 'col-sm-12' : 'col-sm-9'">
                <section class="col-sm-12 dm-p-l-imp-0">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <section>
                            <label class="font-caption" for="projectName">
                                <span>Project Name</span>
                                <span class="font-label">(max 40 characters)</span>
                                <span class="required-mark" aria-hidden="true">*</span>
                            </label>
                            <input aria-required="true" class="font-caption-alt dm-input" type="text"
                                formControlName="projectName" attr.id="projectName-{{projectIndex}}" maxlength="40"
                                [ngClass]="{'input-error-border': !(projectName?.dirty || projectName?.touched) && (projectDetails?.errorDetails?.Name)}"
                                name="projectName" aria-label="Project name" placeholder="Project Name">
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(projectName?.dirty || projectName?.touched) && projectName.errors?.minlength">
                                {{internalProjectErrorMessages.MinimumCharacterLength}}
                            </p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(projectName?.dirty || projectName?.touched) && projectName?.errors?.maxlength">
                                {{internalProjectErrorMessages.ProjectNameMaximumCharacterLength}}
                            </p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(projectName?.dirty || projectName?.touched) && projectName?.errors?.required">
                                {{internalProjectErrorMessages.ProjectNameIsRequired}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="!(projectName?.dirty || projectName?.touched) && (projectDetails?.errorDetails?.Name)">
                                {{projectDetails?.errorDetails?.Name}}</p>
                        </section>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0 project-dates">
                        <section [ngClass]="isComponentModal ? 'add-project-modal-dm-datepicker' : 'dm-datepicker'">
                            <section>
                                <label class="font-caption" for="newStartDate">
                                    Start Date
                                </label>
                                <dm-datepicker id="projectStartDate-{{projectIndex}}" name="projectStartDate"
                                    formControlName="projectStartDate" [modelDate]="projectStartDate.value"
                                    ariaLabelButton="ProjectStartDate-{{projectIndex}}" [placeholder]="'dd-mm-yyyy'"
                                    ariaLabelCalendar="ProjectStartDate-{{projectIndex}}"
                                    (onDateChange)="selectProjectStartDate($event)"
                                    previosFousingElementId="projectName-{{projectIndex}}">
                                </dm-datepicker>
                            </section>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite">
                                {{startDateValidationMessage}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(projectStartDate.dirty || projectStartDate.touched) && projectStartDate.errors?.required">
                                {{internalProjectErrorMessages.ProjectStartDateIsRequired}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="!(projectStartDate?.dirty || projectStartDate?.touched) && (projectDetails?.errorDetails?.StartDate)">
                                {{projectDetails?.errorDetails?.StartDate}}</p>
                        </section>
                        <section
                            [ngClass]="isComponentModal ? 'add-project-modal-dm-datepicker dm-m-imp-0' : 'dm-datepicker dm-m-imp-0'">
                            <section>
                                <label class="font-caption" for="newEndDate">
                                    End Date
                                </label>
                                <dm-datepicker id="endDate-{{projectIndex}}" name="projectEndDate"
                                    [modelDate]="projectEndDate?.value" formControlName="projectEndDate"
                                    [placeholder]="'dd-mm-yyyy'" ariaLabelButton="ProjectEndDate-{{projectIndex}}"
                                    ariaLabelCalendar="ProjectEndDate-{{projectIndex}}"
                                    (onDateChange)="selectProjectEndDate($event)"
                                    previosFousingElementId="calenderBtn_ProjectStartDate-{{projectIndex}}">
                                </dm-datepicker>
                            </section>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite">
                                {{endDateValidationMessage}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(projectEndDate?.dirty || projectEndDate?.touched) && projectEndDate?.errors?.required">
                                {{internalProjectErrorMessages.ProjectEndDateIsRequired}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="!(projectEndDate?.dirty || projectEndDate?.touched) && (projectDetails?.errorDetails?.EndDate)">
                                {{projectDetails?.errorDetails?.EndDate}}</p>
                        </section>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <section>
                            <label class="font-caption" for="internalProjectDomain">Solution Area <span class="required-mark" aria-hidden="true">*</span></label>
                            <dm-dropdown-menu class="dm-m-r-20" id="domain-{{projectIndex}}" name="engagementType"
                                [ariaLabel]="'Domains'"
                                [ngClass]="{'input-error-border': !(projectDomain?.dirty || projectDomain?.touched) && (projectDetails?.errorDetails?.PrimaryDomain)}"
                                [options]="internalProjectDomains" formControlName="projectDomain"
                                [labels]="'domainValueName'" [isRequired]="true">
                                <option [value]="placeholder">Select Solution Area</option>
                            </dm-dropdown-menu>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(projectDomain?.dirty || projectDomain?.touched) && projectDomain?.errors?.required">
                                {{internalProjectErrorMessages.ProjectDomainIsRequired}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf=" !(projectDomain?.dirty || projectDomain?.touched) && (projectDetails?.errorDetails?.PrimaryDomain)">
                                {{projectDetails?.errorDetails?.PrimaryDomain}}</p>
                        </section>
                    </section>
                </section>
            </section>
            <section class="dm-p-l-imp-0" [ngClass]="isComponentModal ? 'col-sm-12' : 'col-sm-9'">
                <section class="col-sm-12 dm-p-l-imp-0">
                    <section class="col-sm-8 dm-p-l-imp-0">
                        <label class="font-caption" for="projectDescription">
                            Description
                            <span class="font-label">(max 255 characters)</span>
                        </label>
                        <textarea class="font-caption-alt dm-p-10 dm-textarea" type="text"
                            id="projectDescription-{{projectIndex}}" maxlength="255"
                            [ngClass]="{'input-error-border': !(projectDescription?.dirty || projectDescription?.touched) && (projectDetails?.errorDetails?.Description)}"
                            name="projectDescription" placeholder="Description" formControlName="projectDescription"
                            aria-label="Project Description (max 255 characters)"></textarea>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="(projectDescription?.dirty || projectDescription?.touched) && projectDescription?.errors?.maxlength">
                            {{internalProjectErrorMessages.ProjectDescriptionMaximumCharacterLength}}</p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="!(projectDescription?.dirty || projectDescription?.touched) && (projectDetails?.errorDetails?.Description)">
                            {{projectDetails?.errorDetails?.Description}}</p>
                    </section>
                </section>
            </section>

            <section class="dm-p-l-imp-0" *ngIf="showNBUEFields || showSRBasedFields || showECIFPresalesBasedFields"
                [ngClass]="isComponentModal ? 'col-sm-12' : 'col-sm-9'">
                <h4 class="dm-add-project__section-header">Finances
                </h4>
                <section class="col-sm-12 dm-p-l-imp-0" *ngIf="showNBUEFields">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class=" font-caption" for="nbueHours">
                            Approved NBUE Hours
                            <span class="required-mark" aria-hidden="true">*</span>
                        </label>
                        <input aria-required="true" type="number" id="nbueHours-{{projectIndex}}" name="nbueHours"
                            (ngModelChange)="onNbueUpdate()" formControlName="projectNBUEHours"
                            class="font-caption-alt dm-input" aria-label="Approved NBUE Hours Value"
                            [ngClass]="{'input-error-border': !(projectNBUEHours?.dirty || projectNBUEHours?.touched) && (projectDetails?.errorDetails?.NbueApprovedHours)}">
                        <p role=" alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="(projectNBUEHours?.dirty || projectNBUEHours?.touched) && projectNBUEHours?.invalid">
                            {{internalProjectErrorMessages.EnterValidNBUEHours}}
                        </p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="!(projectNBUEHours?.dirty || projectNBUEHours?.touched) && (projectDetails?.errorDetails?.NbueApprovedHours)">
                            {{projectDetails?.errorDetails?.NbueApprovedHours}}</p>
                    </section>
                    <ng-container *ngIf="!hideNbueUploadField">
                        <section class="col-sm-4 dm-p-l-imp-0">
                            <label class="font-caption" for="uploadedProjectNBUEFile">
                                NBUE Approval Documentation
                                <span class="custom-tooltip icon icon-info" tabindex="0">
                                    <div class="custom-tooltiptext font-caption-alt" placement="top">
                                        Supported File Formats: {{ fileFormatsAllowed }}
                                    </div>
                                </span>
                            </label>
                            <section>
                                <input class="font-caption-alt dm-input nbue-input" type="text"
                                    id="uploadedProjectNBUEFile-{{projectIndex}}" name="uploadedProjectNBUEFile"
                                    formControlName="uploadedProjectNBUEFileName"
                                    [readonly]="isProjectCostCenterReadOnly"
                                    placeholder="Attach NBUE Approval Documentation">
                                <input class="hide-element" id="uploadProjectFileBtn"
                                    formControlName="uploadedProjectNBUEFile" name="uploadProjectFileBtn"
                                    (change)="uploadProjectNBUEFileBtnClick($event)" type="file" #projectNBUEFileUpload
                                    [accept]="fileFormatsAllowed" />
                            </section>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite" *ngIf="showFileUploadValidationMessage">
                                {{internalProjectErrorMessages.UploadOnlyOutlookMessageFileOrPdfFile}}
                            </p>
                        </section>
                        <button type="button" class="fxp-btn fxp-btn--primary browse-btn"
                            [ngClass]="{'browse-btn-align': !isComponentModal}" (click)="uploadProjectFileBtnClick()">
                            Browse
                        </button>
                    </ng-container>
                </section>
                <section class="col-sm-12 dm-p-l-imp-0" *ngIf="showSRBasedFields">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption" for="chargeType">
                            Charge Type
                        </label>
                        <section>
                            <dm-dropdown-menu class="dm-m-r-20" id="projectChargeType-{{projectIndex}}"
                                [ariaLabel]="'Project Charge Types Dropdown'" name="projectChargeType"
                                formControlName="projectChargeType" (change)="onChargeTypeUpdate()">
                                <option value="CostCenter">Cost Center</option>
                                <option value="InternalOrder">Internal Order</option>
                            </dm-dropdown-menu>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="!(projectChargeType?.dirty || projectChargeType?.touched) && (projectDetails?.errorDetails?.CostObjectType)">
                                {{projectDetails?.errorDetails?.CostObjectType}}</p>
                        </section>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption" for="srOut">Charge Type Value</label>
                        <section>
                            <input aria-required="true" type="text" id="srOut-{{projectIndex}}" name="srOut"
                                [readonly]="isProjectCostCenterReadOnly"
                                [ngClass]="{'input-error-border': !(projectCostCenter?.dirty || projectCostCenter?.touched) && (projectDetails?.errorDetails?.CostObject)}"
                                formControlName="projectCostCenter" class="dm-input font-caption-alt"
                                placeholder="Cost Center\Internal Order"
                                (keydown.enter)="validateProjectCostCenter(projectFormValue.projectCostCenter)"
                                (blur)="validateProjectCostCenter(projectFormValue.projectCostCenter)" />
                            <div>
                                <i id="srOutbtn" aria-label="search" class="icon icon-search icon__ele icon-charge-type"
                                    [ngClass]="{'icon-charge-type-top-component': isComponentModal}"
                                    *ngIf="!isProjectCostCenterLoading  && !isProjectCostCenterReadOnly && !projectCostCenter?.valid"
                                    type="button"
                                    (click)="validateProjectCostCenter(projectFormValue.projectCostCenter)"></i>
                                <i *ngIf="isProjectCostCenterLoading" aria-label="Validating..."
                                    [ngClass]="{'glyphicon glyphicon-refresh glyphicon-spin icon-charge-type' : isProjectCostCenterLoading, 'icon-charge-type-top-component': isComponentModal}"></i>
                                <i *ngIf="!isProjectCostCenterLoading && projectCostCenter?.valid" aria-label="Clear"
                                    (click)="clearProjectCostCenter()"
                                    class="icon icon__ele icon-cancel icon-charge-type"
                                    [ngClass]="{'icon-charge-type-top-component': isComponentModal}"></i>
                            </div>
                        </section>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="(projectCostCenter?.dirty || projectCostCenter?.touched) && projectCostCenter?.errors?.required">
                            {{internalProjectErrorMessages.EnterRequiredSROutCostCenterOrInternalOrderValue}}
                        </p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite">
                            {{projectCostCenterErrorMsg}}</p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="!(projectCostCenter?.dirty || projectCostCenter?.touched) && (projectDetails?.errorDetails?.CostObject)">
                            {{projectDetails?.errorDetails?.CostObject}}</p>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0 sr-cross-charge-methods">
                        <section class="dm-p-l-imp-0" [ngClass]="isComponentModal ? 'col-sm-8' : 'col-sm-7'">
                            <!--==== Radio buttons =====-->
                            <label class="font-caption"> SR Cross Charge Method</label>
                            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'right'"
                                [ngbTooltipContent]="'Labor costs are settled to either an internal order or cost center on a monthly basis. For SR Out Engagements, determine a % of the original cost rate to be settled across all resources on the project or settle based on a fixed monthly amount. Default cross charge is 90%'"
                                [dmIcon]="'tile__icon--info icon icon-info'"
                                ariaLabelToolTip="SR Cross Charge Method Information">
                            </dm-tooltip>
                            <section>
                                <label for="projectPercentUplift-{{projectIndex}}" focus="true" class="dm-p-t-5">
                                    <div class="input-radio input-radio--focus">
                                        <input id="projectPercentUplift-{{projectIndex}}" type="radio"
                                            name="projectSrCrossChargeType" formControlName="projectSrCrossChargeType"
                                            value="Percentage" />
                                        <span class="slider round" aria-hidden="true"></span>
                                    </div>
                                    <span class="radiobtn-label font-caption-alt">Cross Charge Percentage</span>
                                </label>
                                <label for="projectFixedAmount-{{projectIndex}}" focus="true" class="dm-p-t-5">
                                    <div class="input-radio input-radio--focus">
                                        <input id="projectFixedAmount-{{projectIndex}}" type="radio"
                                            name="projectSrCrossChargeType" formControlName="projectSrCrossChargeType"
                                            value="FixedAmount" [attr.disabled]="disableMonthlyFixedCharge? disableMonthlyFixedCharge: null" />
                                        <span class="slider round" aria-hidden="true"></span>
                                    </div>
                                    <span class="radiobtn-label font-caption-alt">Monthly Fixed Charge</span>
                                </label>
                            </section>
                        </section>
                        <section class="col-sm-6 dm-p-imp-0">
                            <section>
                                <label class="font-caption" for="projectSrCrossChargePercent">
                                    <span>{{projectSrCrossChargeType.value === 'Percentage' ? 'Cross Charge Percentage'
                                        : 'Monthly Fixed Charge'}}</span>
                                </label>
                                <section>
                                    <input type="number" id="projectSrCrossChargeValue-{{projectIndex}}"
                                        aria-label="Project SrCrossCharge Value" name="projectSrCrossChargeValue"
                                        [ngClass]="{'input-error-border': !(projectSrCrossChargeValue?.dirty || projectSrCrossChargeValue?.touched) && (projectDetails?.errorDetails?.ChargeTypeValue)}"
                                        formControlName="projectSrCrossChargeValue" (blur)="false"
                                        class="dm-input font-caption-alt charge-input">
                                    <span class="font-caption dm-p-y-imp-10">
                                        <span>{{currencyCodeBasedOnCompanyCode}}</span>
                                    </span>
                                </section>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite"
                                    *ngIf="(projectSrCrossChargeValue?.dirty || projectSrCrossChargeValue?.touched) && projectSrCrossChargeValue?.errors?.required">
                                    {{internalProjectErrorMessages.EnterRequiredSRCrossChargeValue}}
                                </p>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite" *ngIf="projectSrCrossChargeValue?.errors?.SrOutValue">
                                    {{projectSrCrossChargeValue.errors.SrOutValue}}
                                </p>
                            </section>
                        </section>
                    </section>
                </section>
                <section class="col-sm-12 dm-p-l-imp-0" *ngIf="showECIFPresalesBasedFields">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption" for="chargeAccount">
                            Charge Account
                        </label>
                        <section>
                            <dm-dropdown-menu class="dm-m-r-20" id="projectChargeAccount-{{projectIndex}}"
                                name="projectChargeAccountType" formControlName="projectChargeAccountType"
                                [labels]="'chargeAccountName'" [options]="projectChargeAccountTypes">
                            </dm-dropdown-menu>
                        </section>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption" for="srOut">ECIF Internal Order</label>
                        <section>
                            <input type="text" id="ecifInternalOrder-{{projectIndex}}" name="ecifInternalOrder"
                                [readonly]="isProjectCostCenterReadOnly"
                                [ngClass]="{'input-error-border': !(projectCostCenter?.dirty || projectCostCenter?.touched) && (projectDetails?.errorDetails?.CostObject)}"
                                formControlName="projectCostCenter" class="dm-input font-caption-alt"
                                placeholder="Internal Order"
                                (keydown.enter)="validateProjectCostCenter(projectFormValue.projectCostCenter)"
                                (blur)="validateProjectCostCenter(projectFormValue.projectCostCenter)" />
                            <div>
                                <i id="srOutbtn" aria-label="search" class="icon icon-search icon__ele icon-charge-type"
                                    [ngClass]="{'icon-charge-type-top-component': isComponentModal}"
                                    *ngIf="!isProjectCostCenterLoading  && !isProjectCostCenterReadOnly && !projectCostCenter?.valid"
                                    type="button"
                                    (click)="validateProjectCostCenter(projectFormValue.projectCostCenter)"></i>
                                <i *ngIf="isProjectCostCenterLoading" aria-label="Validating..."
                                    [ngClass]="{'glyphicon glyphicon-refresh glyphicon-spin icon-charge-type' : isProjectCostCenterLoading, 'icon-charge-type-top-component': isComponentModal}"></i>
                                <i *ngIf="!isProjectCostCenterLoading && projectCostCenter?.valid" aria-label="Clear"
                                    (click)="clearProjectCostCenter()"
                                    class="icon icon__ele icon-cancel icon-charge-type"
                                    [ngClass]="{'icon-charge-type-top-component': isComponentModal}"></i>
                            </div>
                        </section>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="(projectCostCenter?.dirty || projectCostCenter?.touched) && projectCostCenter?.errors?.required">
                            {{internalProjectErrorMessages.EnterRequiredInternalOrderValue}}
                        </p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite">
                            {{projectInternalOrderErrorMsg}}</p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="!(projectCostCenter?.dirty || projectCostCenter?.touched) && (projectDetails?.errorDetails?.CostObject)">
                            {{projectDetails?.errorDetails?.CostObject}}</p>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0 sr-cross-charge-methods">
                        <section class="col-sm-6 dm-p-imp-0">
                            <section>
                                <label class="font-caption" for="ECIFamountUSD">
                                    <span>ECIF Amount (USD)</span>
                                </label>
                                <section>
                                    <input type="number" id="projectECIFAmountUSD-{{projectIndex}}"
                                        name="projectECIFAmountUSD"
                                        formControlName="projectECIFAmountUSD"
                                        [ngClass]="{'input-error-border': !(projectECIFAmountUSD?.dirty || projectECIFAmountUSD?.touched) && (projectDetails?.errorDetails?.ChargeTypeValue)}"
                                        (input)="updateLocalECIFValue()"
                                        (blur)="false"
                                        class="dm-input font-caption-alt charge-input">
                                </section>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(projectECIFAmountUSD?.dirty || projectECIFAmountUSD?.touched) && projectECIFAmountUSD?.errors?.required">
                                {{internalProjectErrorMessages.EnterRequiredECIFAmountValue}}
                            </p>
                            </section>
                        </section>
                        <section class="col-sm-6 dm-p-imp-0" *ngIf="currencyCodeBasedOnCompanyCode!=='USD' && currencyCodeBasedOnCompanyCode!=='' && currencyCodeBasedOnCompanyCode!==undefined">
                          <section>
                              <label class="font-caption" for="ECIFamountLocal">
                                  <span>ECIF Amount ({{currencyCodeBasedOnCompanyCode}})</span>
                              </label>
                              <dm-tooltip role="tooltip"
                                    [dmPlacement]="'right'"
                                    class="dm-tooltipinline dm-tile--m-r-4"
                                    [ngbTooltipContent]="ecifAmountInfoTooltip"
                                    [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="projectECIFAmountLocalCurrency">
                              </dm-tooltip>
                              <ng-template #ecifAmountInfoTooltip>
                                <div class="display-grid font-caption-alt">
                                  <span>ECIF Amount ({{currencyCodeBasedOnCompanyCode}}) = ECIF Amount (USD) * Exchange Rate ({{exchangeRate}})</span>
                                </div>
                              </ng-template>
                              <section>
                                  <input type="number" id="projectECIFAmountLocalCurrency-{{projectIndex}}"
                                      name="projectECIFAmountLocalCurrency"
                                      disabled
                                      formControlName="projectECIFAmount" (blur)="false"
                                      class="dm-input font-caption-alt charge-input">
                              </section>
                          </section>
                      </section>
                    </section>
                </section>
            </section>

            <section class="dm-p-l-imp-0" [ngClass]="isComponentModal ? 'col-sm-12' : 'col-sm-9'">
                <h4 class="dm-add-project__section-header">Owners</h4>
                <section class="col-sm-12 dm-p-l-imp-0">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption" for="internalEngTypeAheadProjectManager">
                            Acting PjM (Time & Expense approver)
                            <span class="font-label"></span>
                        </label>

                        <dm-type-ahead [typeAheadId]="'internalEngTypeAheadProjectManager' + projectIndex"
                            [ngClass]="{'input-error-border': !(selectedPjm?.dirty || selectedPjm?.touched) && (projectDetails?.errorDetails?.PJM)}"
                            [btnSearchAriaLabelText]="pjmSearchAriaLblTxt" [typeAheadLabelText]=""
                            [btnCancelAriaLabelText]="pjmCancelAriaLblTxt"
                            [typeAheadUserValidationRequiredMessage]="'Project Manager is required'" [isRequired]="true"
                            [isMultiselect]="false" [fteOnly]="true" [typeaheadMinLength]="3"
                            [ignoredExistingManagers]="selectedAdPjmsList" formControlName="selectedPjm"
                            (selectedUserUpdated)="selectedUserUpdated($event)">
                        </dm-type-ahead>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="(selectedPjm?.dirty || selectedPjm?.touched) && selectedPjm?.errors?.required">
                            {{internalProjectErrorMessages.PrimaryProjectManagerIsRequired}}</p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite" *ngIf="invalidPjmMessage">
                            {{invalidPjmMessage}}</p>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption" for="projectmanagersTypeAheadProject">
                            Additional Project Managers
                            <span class="font-label">(optional)</span>
                        </label>
                        <dm-type-ahead [typeAheadId]="'projectmanagersTypeAheadProject'+projectIndex"
                            [ngClass]="{'input-error-border': !(selectedADPJM?.dirty || selectedADPJM?.touched) && (projectDetails?.errorDetails?.ADPJM)}"
                            [btnSearchAriaLabelText]="adPjmSearchAriaLblTxt" [typeAheadLabelText]=""
                            [btnCancelAriaLabelText]="adPjmCancelAriaLblTxt" [selectedUsers]="selectedAdPjmsList"
                            [isRequired]="false" [isMultiselect]="true" [fteOnly]="false"
                            formControlName="selectedADPJM" (multiSelectedUsersUpdated)="onAdpjmUpdate($event)"
                            [ignoredExistingManagers]="[selectedPjm]">
                        </dm-type-ahead>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite" *ngIf="invalidAdpjmMessage">
                            {{invalidAdpjmMessage}}</p>
                    </section>
                </section>
            </section>

            <section class="col-sm-12 dm-p-l-imp-0">
                <h4 class="dm-add-project__section-header">Staffing</h4>
                <section class="pull-left col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0" *ngIf="internalEngagementType">
                    <section class="pull-left col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-p-y-imp-10">
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite" *ngIf="showRoleDetailsValidationMessage">
                            Enter role details
                        </p>
                    </section>
                    <dm-add-roles [approvedNbueHours]="projectNBUEHours?.value" [newRoles]="newRolesDetails"
                        [projectDomain]="internalEngagementDomain?.domainValueName"
                        [engType]="internalEngagementType?.typeDescription"
                        [engTypeCode]="internalEngagementType?.engagementCreationCode"
                        [engStartDate]="projectStartDate?.value" [engEndDate]="projectEndDate?.value"
                        [selectedCompanyCode]="engagementCompany?.companyCode"
                        [isRoleDateRestrictionDisabled]="isFromBulkProcess"
                        [showRolesRequiredMessage]="showRoleDetailsRequiredMessage">
                    </dm-add-roles>
                </section>
            </section>
            <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0" *ngIf="isComponentModal">
                <section class="pull-right">
                    <button class="fxp-btn fxp-btn--secondary" type="button" id="cancelInternalEngagementProject" (click)="closeModal()">Cancel</button>
                    <button class="fxp-btn fxp-btn--primary" type="submit"
                        [disabled]="!projectForm.valid || isCreateProjectDisabled">Create</button>
                </section>
            </section>
        </form>
    </section>
</section>
