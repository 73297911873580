import * as fromEngagementChangeRequests from "./engagement-change-requests.reducer";
import { IState } from "../reducers";
import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";
import { IChangeRequestsState } from "../change-requests/change-requests.reducer";

export const getEngagementChangeRequestsState = (engagementId: string) => (store: IState): IChangeRequestsState => store.engagementChangeRequests[engagementId] ? store.engagementChangeRequests[engagementId] : fromEngagementChangeRequests.initialState;
export const getEngagementChangeRequests = (engagementId: string) => (store: IState): IChangeRequest[] => getEngagementChangeRequestsState(engagementId)(store).changeRequests;
export const getEngagementChangeRequestsLoading = (engagementId: string) => (store: IState): boolean => getEngagementChangeRequestsState(engagementId)(store).loading;
export const getEngagementChangeRequestsLoaded = (engagementId: string) => (store: IState): boolean => getEngagementChangeRequestsState(engagementId)(store).loaded;
export const getEngagementChangeRequestsError = (engagementId: string) => (store: IState): string => getEngagementChangeRequestsState(engagementId)(store).error;
