import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { ActivateEngagementComponent } from "./activate-engagement-modal.component";
import { ActivateEngagementTemplatesModule } from "./activate-engagement-templates/activate-engagement-templates.module";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../../common/services/dmnotification.service";
import { ProjectService } from "../../../../../common/services/project.service";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { CosmicServiceFunctions } from "../../../../../common/services/cosmic-function.service";

@NgModule({
    declarations: [
        ActivateEngagementComponent,
    ],
    imports: [
        ActivateEngagementTemplatesModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        NgbModule,
    ],
    providers: [
        ConfigManagerService,
        CosmicServiceFunctions,
        DMLoggerService,
        DMNotificationService,
        ProjectService,
        ProjectServiceFunctions,
        SharedFunctionsService,
    ],
    entryComponents: [
        ActivateEngagementComponent,
    ],
    exports: [
        ActivateEngagementComponent
    ],
})
export class ActivateEngagementModalModule { }
