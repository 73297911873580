import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { IEntityFinancials, IFinancialPlanV2, IEntityFinancialSummary } from "../../financial.model";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { IEngagementDetailsApiV2, IProjectDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { IState, ILoadableState } from "../../../../store/reducers";
import { RouteName, Components, BaseLineType, FinancialType, ComponentFailureMessages, NoDataText, LogEventConstants, SourceConstants, AccessibilityConstants } from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import moment from "moment";
import { DmError } from "../../../../common/error.constants";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EditRiskReserveModalComponent } from "./edit-risk-reserve-modal/edit-risk-reserve-modal.component";

@Component({
    selector: "dm-financial-plan",
    templateUrl: "./financial-plan.html",
    styleUrls: ["./financial-plan.scss"]
})
export class FinancialPlanComponent extends DmComponentAbstract {
    public actualCurrentDetails: IEntityFinancialSummary;
    public actualDetails: IEntityFinancialSummary;
    public actualsCurrentText: string;
    public canEditFinancials: boolean = false;
    public contractBaseLineDetails: IEntityFinancialSummary;
    public contractBaseLinePlanDetails: IFinancialPlanV2;
    public currency: string;
    public currentBaseLineDetails: IEntityFinancialSummary;
    public currentBaseLinePlanDetails: IFinancialPlanV2;
    public deliveryBaseLineDetails: IEntityFinancialSummary;
    public deliveryBaseLinePlanDetails: IFinancialPlanV2;
    public doption: string;
    public eacDetails: IEntityFinancialSummary;
    public errorText: string;
    public etcDetails: IEntityFinancialSummary;
    public isDesktopView: boolean;
    public isMobileView: boolean;
    public isProjectTypeFF: boolean;
    public lastValuationActualsDate: Date;
    public lastValuationDate: Date;
    public RouteName = RouteName;
    public selectedMenu: string;
    public showFinacials: boolean;
    public showLoading: boolean = true;
    public showSentForActivationMessage: boolean;
    public valuateAndSaveMessage: string = "The financials shown are of last valuation date. Please Valuate & Save for latest financials on the forecast workbook.";
    public isUnitBasedContract: boolean = false;
    public isFinancialPlanData: boolean = false;
    public noFinancialPlanDataText: string;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.FinancialForecast;
    public isForecastInitiated: boolean = false;
    public isFixedFee: boolean;
    public showEditRiskReserveBtn: boolean = false;
    public accessibilityConstants = AccessibilityConstants;
    private isProjectContext: boolean; // code in view is currently commented out

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) private deviceFactory: DeviceFactoryProvider,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService
    ) {
        super(dmLogger, Components.FinancialPlan);
    }

    public ngOnInit(): void {
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isMobileView = this.deviceFactory.isMobile();
        this.noFinancialPlanDataText = NoDataText.NoFinancialPlan;
        this.actualsCurrentText = "Cost, Hours, and Risk Reserve amount shown is as date shown. Revenue may be up to 3 days old as it is dependent on Results Analysis execution in SAP, which occurs every 3 days.";
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

        if (projectId) {
            this.isProjectContext = true;
            this.storeDispatchService
                .requireProjectDetails(projectId, true)
                .requireFinancialDetailsV2(projectId, true)
                .load();
            const projectDetails$ = this.store.select(getEntireProjectDetails(projectId));
            const projectFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(projectId));
            observableCombineLatest(
                projectDetails$,
                projectFinancialDetails$,
                (
                    projectDetails: IProjectDetailsState,
                    projectFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    projectDetails,
                    projectFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    projectDetails,
                    projectFinancialDetails,
                }) => {
                    this.checkLoadingStatus(projectDetails, projectFinancialDetails);
                    if (projectDetails.loaded && projectFinancialDetails.loaded) {
                        const projectDetailsResonse = projectDetails.projectDetails.projectFullDetails;
                        this.getDetailsForProject(projectFinancialDetails.financialDetails, projectDetailsResonse);
                        this.showEditRiskReserveBtn = projectDetailsResonse.statusCode === "REL";
                        if (projectDetailsResonse && projectDetailsResonse.contractType) {
                            this.isFixedFee = projectDetailsResonse.contractType === "FF" ? true : false;
                        }                       
                    }
                    if (projectDetails.error || projectFinancialDetails.error) {
                        this.isServerError = true;
                    }
                });
        } else {
            this.storeDispatchService
                .requireEngagementDetails(engagementId, true)
                .requireFinancialDetailsV2(engagementId, true)
                .load();
            const engagementDetails$ = this.store.select(getEntireEngagementDetails(engagementId));
            const engagementfinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(engagementId));
            observableCombineLatest(
                engagementDetails$,
                engagementfinancialDetails$,
                (
                    engagementDetails: IEngagementDetailsState,
                    engagementFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    engagementDetails,
                    engagementFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    engagementDetails,
                    engagementFinancialDetails,
                }) => {
                    this.checkLoadingStatus(engagementDetails, engagementFinancialDetails);
                    if (engagementDetails.loaded && engagementFinancialDetails.loaded) {
                        this.getDetailsForEngagement(engagementFinancialDetails.financialDetails, engagementDetails.engagementDetails);
                        if (engagementDetails.engagementDetails) {
                            const contractType = this.sharedFunctionsService.getContractType(engagementDetails.engagementDetails.projects);
                            this.showEditRiskReserveBtn = engagementDetails.engagementDetails.statusCode === "REL";
                            this.isFixedFee = contractType === "FF" || contractType === "Mixed" ? true : false;
                        }
                    }
                    if (engagementFinancialDetails.error || engagementDetails.error) {
                        this.isServerError = true;
                    }
                });
        }
    }

    /**
     * Open Edit Risk reserve modal popup
     */
    public openEditRiskReserveModal(): void {
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const wbsId = this.isProjectContext ? projectId : engagementId;
        const modalRef: NgbModalRef = this.modalService.open(EditRiskReserveModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 edit-risk-reserve-modal in active",
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.wbsId = wbsId;
        modalRef.componentInstance.isProjectContext = this.isProjectContext;
        modalRef.componentInstance.currency = this.currency;
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OpenEditRiskReserveModal, LogEventConstants.DeliveryBaseLineClick);
    }

    public getRowClass(): string {
        let className: string;
        if (this.isFixedFee && !this.isUnitBasedContract) {
            className = "dm-financial-plans-table__rowWithFF";
        }
        if (!this.isUnitBasedContract && !this.isFixedFee) {
            className = "dm-financial-plans-table__row";
        }
        if (this.isUnitBasedContract && !this.isFixedFee) {
            className = "dm-financial-plans-table__rowWithUnits";
        }
        if (this.isUnitBasedContract && this.isFixedFee) {
            className = "dm-financial-plans-table__rowWithUnitsFF";
        }
        return className;
    }

    /**
     * Manages the loading state and errors of the given loadable state items.
     *
     * @private
     * @param {...ILoadableState[]} items
     * @memberof FinancialPlanComponent
     */
    private checkLoadingStatus(...items: ILoadableState[]): void {
        this.refreshOnItemInvalidation(...items);
        this.setLoadersBasedOnItemState(...items);
        this.setErrorsBasedOnItemState(...items);
    }

    /**
     * Manipulates the info for the page based on the given engagement full details, which are retrieved or created for the cache. This will only be called when
     * the page is in engagement context, NOT in project context.
     * @param engagementFullDetails
     * @param engagementDetails
     */
    private getDetailsForEngagement(engagementFinancialDetails: IEntityFinancials, engagementDetails: IEngagementDetailsApiV2): void {
        this.currency = engagementDetails.currency;

        /* This variable covers both FF and Mixed engagements. Risk Reserve content is only valid if the engagement is FF or Mixed */
        this.isProjectTypeFF = engagementDetails.projects.filter((p) => p.contractType === "FF").length > 0;
        this.isUnitBasedContract = engagementDetails.hasUnitBasedDemands;

        if (engagementFinancialDetails && engagementFinancialDetails.financialSummary && engagementFinancialDetails.financialSummary.length) {
            this.isFinancialPlanData = true;
            this.contractBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails, FinancialType.ContractBaseline);
            this.deliveryBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails, FinancialType.DeliveryBaseline);
            this.currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails, FinancialType.CurrentFinancialPlan);
            this.eacDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails, FinancialType.EAC);
            this.etcDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails, FinancialType.ETC);
            this.actualDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails, FinancialType.ACTUALS);
            this.contractBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.ContractBaseline, engagementFinancialDetails);
            this.deliveryBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.DeliveryBaseline, engagementFinancialDetails);
            this.currentBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.CurrentFinancialPlan, engagementFinancialDetails);
            this.actualCurrentDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails, FinancialType.ActualsCurrent);
            this.lastValuationDate = (engagementFinancialDetails.planHeader) ? engagementFinancialDetails.planHeader.lastValuatedOn : null;
            /* For actuals take the last date of n-1 period [friday] and compare it with last valuation date returned by SAP and display the smallest of two. */
            this.lastValuationActualsDate = this.getLastValuationActualsDate(this.lastValuationDate, this.getLastFridayDate());
            this.isForecastInitiated = this.financialService.IsEACETCFinancialsExists(this.eacDetails, this.etcDetails);            
        }
    }

    /**
     * Manipulates the info for the page based on the given project full details, which are retrieved or created for the cache.
     * This will ONLY be called when the page is in project context.
     * @param engagementFullDetails
     */
    private getDetailsForProject(projectFinancialDetails: IEntityFinancials, projectDetails: IProjectDetailsV2): void {
        this.currency = projectDetails.currency;
        this.isUnitBasedContract = projectDetails.hasUnitBasedDemands;
        if (projectFinancialDetails && projectFinancialDetails.financialSummary && projectFinancialDetails.financialSummary.length) {
            this.isFinancialPlanData = true;
            this.contractBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails, FinancialType.ContractBaseline);
            this.deliveryBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails, FinancialType.DeliveryBaseline);
            this.currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails, FinancialType.CurrentFinancialPlan);
            this.eacDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails, FinancialType.EAC);
            this.etcDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails, FinancialType.ETC);
            this.actualDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails, FinancialType.ACTUALS);
            this.actualCurrentDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails, FinancialType.ActualsCurrent);
            this.lastValuationDate = (projectFinancialDetails.planHeader && projectFinancialDetails.planHeader.lastValuatedOn) ? projectFinancialDetails.planHeader.lastValuatedOn : null;
            /// For actuals take the last date of n-1 period [friday] and compare it with last valuation date returned by SAP and display the smallest of two.
            this.lastValuationActualsDate = this.getLastValuationActualsDate(this.lastValuationDate, this.getLastFridayDate());

            this.isProjectTypeFF = (projectDetails.contractType === "FF");
            this.contractBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.ContractBaseline, projectFinancialDetails);
            this.deliveryBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.DeliveryBaseline, projectFinancialDetails);
            this.currentBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.CurrentFinancialPlan, projectFinancialDetails);
            this.isForecastInitiated = this.financialService.IsEACETCFinancialsExists(this.eacDetails, this.etcDetails);
        }
    }

    /**
     * Gets the last valuation actuals date by comparing the given last valuation date and the given last Friday date.
     * @param lastValuationDate
     * @param lastFriday
     */
    private getLastValuationActualsDate(lastValuationDate: Date, lastFriday: Date): Date {
        return (moment(lastValuationDate).isSameOrBefore(lastFriday)) ? lastValuationDate : lastFriday;
    }

    /**
     * Show loading error on the view by toggling different loading flags.
     */
    private showLoadingError(): void {
        this.showLoading = false;
        const engagementFinancialError = ComponentFailureMessages.EngagementFinancialComponent;
        const projectFinancialError = ComponentFailureMessages.ProjectFinancialComponent;
        this.errorText = ((this.isProjectContext) ? projectFinancialError : engagementFinancialError);
        this.isComponentLoading = false;
    }

    /**
     * Get Last Friday Date
     */
    private getLastFridayDate(): Date {
        const lastFridayDate = new Date();
        if (lastFridayDate.getDay() === 5) {
            lastFridayDate.setDate(lastFridayDate.getDate() - 7);
            return lastFridayDate;
        }
        while (lastFridayDate.getDay() !== 5) {
            lastFridayDate.setDate(lastFridayDate.getDate() - 1);
        }
        return lastFridayDate;
    }

}

