import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DmUserProfileComponent } from "./user-name";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { PersonCardModule } from "../person-card/person-card.module";

@NgModule({
    declarations: [
        DmUserProfileComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        UIRouterModule,
        PersonCardModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
        DmUserProfileComponent
    ],
    exports: [
        DmUserProfileComponent
    ],
})
export class DmUserProfileModule { }
