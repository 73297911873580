import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { CostConsumedModule } from "../tiles/cost-consumed/cost-consumed.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { EngagementFinancialComponent } from "./engagement-financial.component";
import { FinancialNavModule } from "../tiles/financial-nav/financial-nav.module";
import { FinancialPlanApprovalModule } from "../../financial-plan-approvals/financial-plan-approvals.module";
import { FinancialSummaryModule } from "../tiles/financial-summary/financial-summary.module";
import { KeyIndicatorsModule } from "../tiles/key-indicators/key-indicators.module";


@NgModule({
    declarations: [
        EngagementFinancialComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        CostConsumedModule,
        FinancialNavModule,
        FinancialPlanApprovalModule,
        FinancialSummaryModule,
        FormsModule,
        KeyIndicatorsModule
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        EngagementFinancialComponent,
    ],
    exports: [
        EngagementFinancialComponent,
    ]
})
export class EngagementFinancialModule { }
