import * as fromCustomerFinancialsOverview from "./customer-financial-overview.action";
import { IEngagementFinancialsListV2 } from "../../common/services/contracts/portfolio.model";
import { ILoadableState } from "../reducers";

export interface ICustomerFinancialsOverviewState extends ILoadableState {
    financialDetails: IEngagementFinancialsListV2[];
}

export const initialState: ICustomerFinancialsOverviewState = {
    financialDetails: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromCustomerFinancialsOverview.CustomerFinancialOverviewActions): { [engagementId: string]: ICustomerFinancialsOverviewState } {
    switch (action.type) {
        case fromCustomerFinancialsOverview.CustomerFinancialOverviewActionTypes.LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromCustomerFinancialsOverview.CustomerFinancialOverviewActionTypes.LOAD_MANUAL_CUSTOMER_FINANCIALOVERVIEW_DETAILS_V2: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromCustomerFinancialsOverview.CustomerFinancialOverviewActionTypes.LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                financialDetails: action.financialDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromCustomerFinancialsOverview.CustomerFinancialOverviewActionTypes.LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromCustomerFinancialsOverview.CustomerFinancialOverviewActionTypes.INVALIDATE_CUSTOMER_FINANCIALOVERVIEW_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}