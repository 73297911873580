import { Component, Output, EventEmitter, Input, Inject } from "@angular/core";
import { ConfigManagerService } from "../../../../../../common/services/configmanager.service";
import { EngagementDetailService } from "../../../../../../common/services/engagement-detail.service";
import { IAmendmentThresholdValues, IContractType, IProjectThresholdValues } from "../../../../../../common/services/contracts/project.service.contracts";
import { MisalignmentValidationErrorTypeEnum } from "../../../../../../components/amendments/amendments.contract";
import { IEngagementDetailsApiV2 } from "../../../../../../common/services/contracts/wbs-details-v2.contracts";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { AccessibilityConstants } from "../../../../../../common/application.constants";

@Component({
    selector: "dm-engagement-validation-threshhold",
    templateUrl: "./engagement-validation-threshhold.html",
    styleUrls: ["./engagement-validation-threshhold.scss"]
})
export class EngagementValidationThreshholdComponent {
    @Input() public validationResultSet: IProjectThresholdValues[];
    @Input() public validationMessage: string;
    @Input() public cancelButtonText: string;
    @Input() public saveButtonText: string;
    @Input() public isValidationFailedModal: boolean = false;
    @Input() public engagementDetails: IEngagementDetailsApiV2;
    @Output() public closeAndUpdateBaselineClicked = new EventEmitter<void>();
    @Output() public emailPJCClicked = new EventEmitter<void>();
    @Output() public continueClicked = new EventEmitter<void>();    
    public readonly MisalignmentValidationErrorTypeEnum: any = MisalignmentValidationErrorTypeEnum;
    public showContinueRequest: boolean = false;
    public showEmailPJC: boolean = false;
    public accessibilityConstants = AccessibilityConstants;
    private Mixed: string = "Mixed";
    private FF: string = "FF";
    private engagementContractType: string;

    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        // super(activeModal, dmLogger, Components.MisalignedAmendmentValidationFailedModal);
    }

    public ngOnInit(): void {
        const contractTypeColors: IContractType[] = this.configManagerService.getValue<IContractType[]>("projEngContractType");
        this.setTypeColorCode(this.validationResultSet, contractTypeColors);
        if (!this.isValidationFailedModal) { // this flag determines whether its from Release Activate or Amendment Validations
            this.engagementContractType = this.sharedFunctionsService.getContractType(this.engagementDetails.projects);
            const isEngagementContainsFFProject: boolean = this.engagementContractType === this.Mixed || this.engagementContractType === this.FF;
            this.showEmailPJC = this.validationForSendToPJC(this.validationResultSet, isEngagementContainsFFProject);
            this.showContinueRequest = !this.showEmailPJC;
        }
        else {           
            this.showEmailPJC = false;
            this.showContinueRequest = true;          
        }      
    }

    /**
     * emits an event to close the model and continue to update the baseline in plan and forecast.
     */
    public cancelButtonClick(): void {
        // todo add telemetry here for cancellation
        this.closeAndUpdateBaselineClicked.emit();
    }

    /**
     * emits an event to send email to PJC.
     */
    public emailPJC(): void {
        // todo add click telemetry
        this.emailPJCClicked.emit();
    }

    /**
     * emits an event on continue click to continue releasing the package.
     */
    public continueRequest(): void {
        // todo add click telemetry
        this.continueClicked.emit();
    }

    /**
     * Check if the email PJC button should be shown or not based on the send to PJC flag and amendment validations.
     * Returns true if the button should be shown, false otherwise.
     *
     * @private
     * @param {boolean} isSendToPJC
     * @param {IAmendmentThresholdValues[]} amendmentValidations
     * @returns {boolean}
     * @memberof EngagementValidationThreshholdComponent
     */
    private showEmailPJCCheck(isSendToPJC: boolean, amendmentValidations: IAmendmentThresholdValues[]): boolean {
        return isSendToPJC && (!amendmentValidations || !amendmentValidations.length);
    }

    /**
     * Gets the amendment validations list out of the validation result set list for faster referencing.
     * Returns the list if valid, an empty list if valid results but it didn't include amendments, or undefined if the results were not valid.
     *
     * @private
     * @param {IProjectThresholdValues[]} validationResultSet
     * @returns {IAmendmentThresholdValues[]}
     * @memberof EngagementValidationThreshholdComponent
     */
    private getAmendmentValidations(validationResultSet: IProjectThresholdValues[]): IAmendmentThresholdValues[] {
        if (!validationResultSet || !validationResultSet.length) {
            return undefined;
        }
        let amendmentValidations: IAmendmentThresholdValues[] = [];
        for (const validation of validationResultSet) {
            if (validation.amendmentThresholds && validation.amendmentThresholds.length) {
                amendmentValidations = amendmentValidations.concat(validation.amendmentThresholds);
            }
        }
        return amendmentValidations;
    }


    /**
     * Set the type color code for the validation data based on the contract type of the validation data
     */
    private setTypeColorCode(validationResultSet: IProjectThresholdValues[], colorCodes: IContractType[]): void {
        if (validationResultSet) {
            for (const item of validationResultSet) {
                if (item) {
                    item.typeColorCode = this.engagementDetailService.getTypeColorCode(colorCodes, item.contractType);
                }
            }
        }
    }

    /**
     * validates and check whether the notification need to be send to PJC based on the contract type and threshold values.
     */
    private validationForSendToPJC(validationResultSet: IProjectThresholdValues[], isEngagementContainsFFProject: boolean): boolean {
        if (isEngagementContainsFFProject) {
            const ffContractSet = validationResultSet.filter((x: IProjectThresholdValues) => x.contractType.toLowerCase() === "ff");
            for (const ffContract of ffContractSet) {
                if (!ffContract.isRevenueValid || !ffContract.isCostValid) {
                    return true;
                }
            }
        }     
        return false;
    }
    
}