<section>
  <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="tileMinHeight" [hasNonOverflowContent]="true">
    <button additionalActionsLeft id="internalEngagementsSectionToggleButton"
      class="dm-transparent-btn expandCollapseBtn" [attr.aria-expanded]="isInternalEngagementsExpanded"
      (click)="onToggleExpandCollapse()" [title]="setTitleOrAriaLabelForExpandCollapseInternalGrid()"
      *ngIf="!isServerError">
      <i class="icon icon-chevron-down cursor-pointer text-top"
        [ngClass]="{'icon-chevron-down': !isInternalEngagementsExpanded, 'icon-chevron-up': isInternalEngagementsExpanded}">
      </i>
    </button>
    <section additionalActionsRight class="display-flex-imp" *ngIf="!isServerError">
      <div class="dm-m-l-imp-20">
        <a id="newinternalEngagement" [uiSref]="RouteName.CreateInternalEngagementV2"
          (click)="logNewInternalEngagementClick()" class="dm-link">
          <span class="icon icon-AddLegacy"></span> Add New Internal Engagement
        </a>
      </div>
      <div class="dm-m-l-imp-20 display-flex-imp bulk-upload-link" *ngIf="showBulkUploadButton">
        <button id="bulkUploadDropdown" class="cursor-pointer dm-transparent-btn" type="button" tabindex="0"
          aria-label="Bulk Upload" (click)="toggleUploadDropdown()"
          (keydown.shift.tab)="showUploadDropdown = false; focus('newinternalEngagement')" [attr.aria-expanded]="showUploadDropdown">
          Bulk Upload <span class="icon icon-chevron-down upload-icon-holder"
            [ngClass]="{'icon-chevron-up': showUploadDropdown, 'icon-chevron-down': !showUploadDropdown}"></span>
        </button>
      </div>
      <section *ngIf="showUploadDropdown">
        <div class="bulk-upload-dropdown dm-p-b-10">
          <div class="bulk-upload-dropdown-text set-focus" role="button" tabindex="0" (keyup.enter)="openUploadModal()"
            (keyup.Space)="openUploadModal()" (click)="openUploadModal()">
            <p class="bulk-upload-link cursor-pointer" (click)="logFileUpload()"> Upload file</p>
          </div>
          <p class="bulk-upload-link"><a [href]="bulkUploadTemplateUrl" (click)="uploadTemplateClick()" class="dm-link"
              (keydown.Tab)="showUploadDropdown = false; focus('internalEngagementNameColumnHeader')"> Get bulk upload
              template </a></p>
        </div>
      </section>
    </section>
    <section *ngIf="isInternalEngagementsExpanded && !isServerError">
      <article class="grid-data">
        <ng-container *ngIf="!showAddNewInternalEngagementView">
          <dm-internal-engagement-overview-tab-v2></dm-internal-engagement-overview-tab-v2>
        </ng-container>
        <ng-container *ngIf="showAddNewInternalEngagementView">
          <dm-no-data [noDataText]="noInternalEngagementFoundText"></dm-no-data>
          <p class="col-xs-12 col-md-12 col-sm-12 dm-p-15 text-center dm-b-80">
            <a [uiSref]="RouteName.CreateInternalEngagement" class="dm-tile--active-link" data-toggle="tab">
              Want to add a new Internal Engagement?
            </a>
          </p>
          <p class="col-xs-12 col-md-12 col-sm-12 dm-p-15 text-center dm-b-80">
            <a href="https://aka.ms/pjm-job-aid/create-internal-engagement" target="_blank" aria-label="open link in new tab"
              (click)="learnMoreIntEngLinkClick()" class="dm-link" role="link"
              title="Learn more about creating Internal Engagements">
              <span aria-hidden="true" class="icon icon-education"></span>
              Learn more
            </a>
          </p>
        </ng-container>
      </article>
    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="toolTipErrorMessage"></dm-service-error>
  </dm-tile>
</section>