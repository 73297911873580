import { Component, Inject, forwardRef, Input, EventEmitter, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorSeverityLevel, FxpConstants, FxpMessageService, FxpRouteService } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { AccessibilityConstants, Components, CacheKeys, CacheStorageOptions, LogEventConstants, SourceConstants } from "../../../../common/application.constants";
import { CacheService } from "../../../../common/services/cache.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmGrmService } from "../../../../common/services/dmgrm.service";
import { OneProfileService } from "../../../../common/services/one-profile.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { IState } from "../../../../store/reducers";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { ProjectServiceV2 } from "../../../../common/services/project.v2.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { IEngineRuleVerificationResponse, IRule } from "../../staffing-command-bar-common/services/contracts/rule-engine.service.contract";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { ICustomerEngagementDomain } from "../../../../common/services/contracts/add-roles.contracts";
import { ICityDetails, IDomainDetails, ILocation, IOneProfileAPIResponse, IRoleDomainSkill, IRoleSkills } from "../../../../common/services//contracts/one-profile.contracts";
import { ISelectedUserAttributes } from "../../../../components/tiles/type-ahead/type-ahead-contracts";
import { IAssignment, IReasonCodeProblemType } from "../../staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { DmError } from "../../../../common/error.constants";
import { v4 as uuid } from "uuid";
import { IAddResourceToGRMProject, IEditDraftResourceRequests, IResourceRequest, IUpdateResourceRequest } from "../../../../common/services/contracts/staffing.service.contract";
import { IFinancialRoles } from "../../../../common/services/contracts/projectservice-functions.contract";
import { IWeek } from "../../../tiles/dm-schedule/dm-schedule.contracts";
import { ISchedulePattern, ISkill } from "../../../new-internal-engagement/internal-engagement-grm.contracts";
import moment from "moment";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IDemandDetails, IWbsDemand } from "../../../../common/services/contracts/project.service.v2.contracts";
import { untilDestroyed } from "ngx-take-until-destroy";
import { getDemandsWithSchedulesState } from "../../../../store/demands-withschedules/demands-withschedules.selector";
import { IDemandsWithSchedulesState } from "../../../../store/demands-withschedules/demands-withschedules.reducer";

declare let require: any;
const DATE_FORMAT: string = "YYYY-MM-DD";
const DATE_FORMAT2: string = "T00:00:00.000Z";
const ACTIVERESOURCESTATUS = ["Submitted", "Assigned", "Complete", "Committed", "Closed"];
const AREAACCOUNTALIGNED = "AREA ACCOUNT ALIGNED";
const DAY = "day";
@Component({
    selector: "copyresource-modal",
    templateUrl: "./copyresource-modal.html",
    styleUrls: ["./copyresource-modal.scss"]
})
export class CopyResourceModalComponent extends DmModalAbstract {
    @Input() public assignmentList: IAssignment[];
    @Input() public wbsId: string;
    @Input() public isModalComponent?: boolean = true;
    @Input() public resourceId: string;
    @Output() public hideTabComponent: EventEmitter<{}> = new EventEmitter();

    public filterEndDatePlaceholder: string;
    public isLoading: boolean = false;
    public showLoading: boolean = true;
    public loadingText: string = "Loading Copy Resource Request";
    public errorSummaryText: string;
    public truncateDate: Date;
    public truncateReasons: IReasonCodeProblemType[] = [];
    public selectedTruncateReason: string = "select";
    public truncateComment: string = "";
    public accessibilityConstants = AccessibilityConstants;
    public deliveryType = [];
    public taSkillsSearchAriaLblTxt: string;
    public taSkillsLabelText: string;
    public taSkillsCancelAriaLblTxt: string;
    public taSkillsRequiredMsg: string;
    public taCancelAriaLblTxt: string;
    public taSearchAriaLblTxt: string;
    public taSkillsNoResultsModel: IRoleSkills[] = [];
    public roleValues: IFinancialRoles[] = [];
    public taRoleSkills: IRoleDomainSkill[] = [];
    public taRequestedResource: ISelectedUserAttributes;
    public noResults: boolean = false;
    public requiredMsg: string;
    public cityList: ICityDetails[] = [];
    public cityCancelAriaLblTxt: string;
    public citySearchAriaLblTxt: string;
    public cityTypeAheadLabelText: string;
    public isDomainSkillInvalid: boolean = false;
    public selectedUser: ISelectedUserAttributes;
    public isRequestApproved: boolean;
    public isClearanceRequired: boolean;
    public requestStartDate: Date;
    public requestEndDate: Date;
    public copyFromResourceRequest: IResourceRequest;
    public copyToResourceRequest: IResourceRequest;
    public draftResourceRequests: IResourceRequest[] = [];
    public draftResourceRequestIds: Array<{ id: string; resourceRequestId: string }> = [];
    public roleIds: Array<{ id: string }> = [];
    public selectedResourceRequestId: string;
    public selectedRole;
    public customerEngagementDomains: ICustomerEngagementDomain[] = [];
    public selectedDomain: ICustomerEngagementDomain;
    public selectedDeliveryType;
    public selectedCountryName: string;
    public selectedCountryCode: string;
    public selectedCity: string;
    public selectedState: string;
    public selectedCityModel: string;
    public selectedLocation: ILocation;
    public additionalDetails: string;
    public requestedHours: number;
    public weeklySchedules: IWeek[] = [];
    public demandDetailsSelected: IWbsDemand;
    public staffingErrorMessages = DmError.Staffing;
    public startDateRequiredMessage: string;
    public endDateRequiredMessage: string;
    public endDateEarlierMessage: string;
    public showStartDateRequiredMessage: boolean;
    public showEndDateRequiredMessage: boolean;
    public showEndDateEarlierMessage: boolean;
    public invalidHoursEnteredMessage: string;
    public isPlannedHoursOfDemandExceed: boolean;
    public scheduleDurationHours: number;
    public minStartDate: Date;
    public maxEndDate: Date;
    public isAutoCommitRole: boolean;
    public autoCommitRoleToolTipText: string = "AutoCommit Role";
    public showMessageForRequestedResource: boolean;
    public requestedResourceMessage: string;
    public showDeliveryLocationRequiredMessage: boolean;
    public deliveryLocationRequiredMessage: string;
    public isPreApprovedRequestDisabled: boolean;
    public isSecurityClearanceDisabled: boolean;
    public isSkillsRequired: boolean = true;
    private selectedResourceRequest: IResourceRequest;
    private validationResults: IEngineRuleVerificationResponse;
    private FXP_CONSTANTS = FxpConstants;
    private defaultGracePeriod: number;
    private plannedHoursLeftUnderDemand: number;
    private selectedUserAlias: string;




    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(RuleEngineService) private ruleEngineService: RuleEngineService,
        @Inject(DmGrmService) private grmService: DmGrmService,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
    ) {
        super(activeModal, dmLogger, Components.StaffingCommandBarSplitPlannedModal);
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.taSkillsRequiredMsg = "At least one skill should be selected";
        this.taSearchAriaLblTxt = "Search Resource by name or alias";
        this.taCancelAriaLblTxt = "Remove Requested Resource.";
        this.taSkillsCancelAriaLblTxt = "Cancel Skills Search Text";
        this.cityCancelAriaLblTxt = "Remove Selected City";
        this.citySearchAriaLblTxt = "City Name";
        this.requiredMsg = this.staffingErrorMessages.RequestedResourceRequired;
        this.startDateRequiredMessage = this.staffingErrorMessages.StartDateIsRequired;
        this.endDateRequiredMessage = this.staffingErrorMessages.EndDateRequired;
        this.endDateEarlierMessage = this.staffingErrorMessages.EndDateCannotBeEarlierThanTheStartDate;
        this.invalidHoursEnteredMessage = this.staffingErrorMessages.InvalidRequestedHoursEntered;
        this.deliveryLocationRequiredMessage = this.staffingErrorMessages.DeliveryLocationRequired;
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        // Check for Input data      
        if (!this.assignmentList || this.assignmentList.length <= 0) {
            this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataMissing, "error", false, uuid());
            this.closeModal();
        }
        if (this.assignmentList.length > 1) {
            this.fxpMessageService.addMessage(DmError.Staffing.MultipleResourcesHasBeenSelected, "error", false, uuid());
            this.closeModal();
        }
        this.validationResults = {
            assignmentIds: [],
            validationMessage: "",
            isActionable: false
        };
        const rulePath: IRule[] = require("../../staffing-command-bar-common/Rules/copyResourceRules.json");
        this.ruleEngineService.addRules(rulePath);
        this.ruleEngineService.runRules(this.assignmentList)
            .then((result: IEngineRuleVerificationResponse) => {
                this.validationResults = result;
                if (!this.validationResults.isActionable) {
                    return Promise.reject(result);
                }
                this.draftResourceRequests = this.getDraftResourceRequestsFromInput(this.assignmentList);
                if (this.draftResourceRequests.length === 0) {
                    this.fxpMessageService.addMessage(DmError.Staffing.NoDraftResourceExists, "error", true);
                    this.closeModal();
                }
                return this.configManagerService.initialize();
            }).then(() => {
                this.deliveryType = [{ id: "Remote", name: "Remote" }, { id: "Onsite", name: "Onsite" }, { id: "Onsite-Shadow", name: "Onsite-Shadow" }, { id: "Phone Advisory", name: "Phone Advisory" }, { id: "Phone Advisory-Shadow", name: "Phone Advisory-Shadow" }];
                this.storeDispatchService.requireWbsDemandsV2WithSchedules(this.assignmentList[0].projectIdSelected, true).load();
                const promises = [this.getFinancialRoles(), this.getDomainValues()];
                return Promise.all(promises);
            }).then(() => {
                this.roleValues.forEach((res) => {
                    this.roleIds.push({ id: res.roleName.toString() });
                });
                this.customerEngagementDomains = this.customerEngagementDomains.filter((engagement) => engagement.IsActive);
                this.copyFromResourceRequest = this.getCopyFromResourceRequest(this.assignmentList);
                if (this.copyFromResourceRequest) {
                    this.draftResourceRequests.forEach((res) => {
                        this.draftResourceRequestIds.push({ id: "Resource ID: " + res.ResourceRequestId.toString() + " (" + res.Role + ")", resourceRequestId: res.ResourceRequestId.toString() });
                    });
                    if (this.copyFromResourceRequest.AssignedResourceName) {
                        this.taRequestedResource = {
                            userAlias: this.copyFromResourceRequest.AssignedResource,
                            userName: this.copyFromResourceRequest.AssignedResourceName,
                            bpId: this.copyFromResourceRequest.AssignedResourceBPID,
                            emailName: "",
                            firstName: "",
                            lastName: "",
                            fullName: this.copyFromResourceRequest.AssignedResource
                        };
                    }
                    const requestDomain = this.customerEngagementDomains.filter((domain) => this.copyFromResourceRequest.RequestDomain && domain.ServiceDeliveryOrganizationName.toLowerCase() === this.copyFromResourceRequest.RequestDomain.toLowerCase());
                    if (requestDomain && requestDomain.length) {
                        this.selectedDomain = requestDomain[0];
                    }
                    if (this.copyFromResourceRequest.Skills && this.copyFromResourceRequest.Skills.length) {
                        this.populateSkills(this.copyFromResourceRequest.Skills);
                    }
                    this.isClearanceRequired = this.copyFromResourceRequest.IsClearanceRequired;
                    this.isRequestApproved = this.copyFromResourceRequest.IsPreApprovedProposal;
                    this.selectedDeliveryType = this.deliveryType.filter((d) => d.name.toLowerCase() === this.copyFromResourceRequest.DeliveryTypeEnum.toLowerCase())[0];
                    this.selectedCountryCode = this.copyFromResourceRequest.DeliveryCountryCode;
                    this.selectedCountryName = this.copyFromResourceRequest.DeliveryCountryName;
                    this.selectedState = this.copyFromResourceRequest.DeliveryState;
                    this.selectedCity = this.copyFromResourceRequest.DeliveryCity;
                    this.selectedLocation = {
                        CountryAlpha2Code: this.selectedCountryCode,
                        Country: this.selectedCountryName,
                        State: this.selectedState,
                        City: this.selectedCity
                    };
                    this.selectedCityModel = this.copyFromResourceRequest.DeliveryCity;
                    this.additionalDetails = this.copyFromResourceRequest.TravelNotes;
                    this.plannedHoursLeftUnderDemand = this.getPlannedHoursLeftUnderDemand(this.assignmentList[0]);
                    this.requestStartDate = this.assignmentList[0].projectDetails.startDate;
                    this.requestEndDate = this.assignmentList[0].projectDetails.endDate;
                }
                else {
                    this.closeModal();
                }
                this.isLoading = false;
            }).catch((error) => {
                // ToDo Log to Application Insight
                // To Show errors if rule validation fails
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                if (error && error.validationMessage) {
                    this.logError(SourceConstants.Method.NgOnInit, error, error.validationMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.fxpMessageService.addMessage(error.validationMessage, "error", true);
                } else {
                    this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                }
                this.closeModal();
            });
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string, defaultId: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, defaultId);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string, defaultId: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, defaultId);
        }
    }

    /**
     * Update skill validity value.
     *
     * @param {boolean} value
     * @memberof AddRoleModalComponent
     */
    public updateSkillsValidity(value: boolean): void {
        this.isDomainSkillInvalid = value;
    }

    /**
     * Update isRequestApproved value
     */
    public toggleRequestApproved(value: boolean): void {
        this.isRequestApproved = value;
    }

    /**
     * Update isClearanceRequired value
     */
    public toggledClearanceRequired(value: boolean): void {
        this.isClearanceRequired = value;
    }

    /**
     * triggered on city change in the form, sets selected city value.
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onLocationChange(location: ILocation): void {
        this.showDeliveryLocationRequiredMessage = false;
        if (location) {
            this.selectedCountryCode = location.CountryAlpha2Code;
            this.selectedCountryName = location.Country;
            this.selectedState = location.State;
            this.selectedCity = location.City;
        } else {
            this.showDeliveryLocationRequiredMessage = true;
        }
    }

    /**
     * On Role Change from the dropdown.
     */
    public onSelectedRoleChange(): void {
        this.isSecurityClearanceDisabled = false;
        this.isPreApprovedRequestDisabled = false;
        if (this.selectedRole) {
            this.isAutoCommitRole = this.verifyIsRoleAutoCommit(this.selectedRole);
            this.validateResource();
        }
        if (this.isAutoCommitRole) {
            this.isPreApprovedRequestDisabled = true;
            this.isRequestApproved = false;
        }
        const isClearedRole = this.verifyIsRoleCleared(this.selectedRole);
        if (isClearedRole) {
            this.isSecurityClearanceDisabled = true;
            this.isClearanceRequired = true;
        }
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     */
    public onStartDateChange(startDate: Date): void {
        this.showStartDateRequiredMessage = false;
        if (startDate) {
            this.showEndDateEarlierMessage = moment(startDate).isSameOrAfter(moment(this.requestEndDate));
            if (moment(startDate) < moment(this.requestEndDate)) {
                this.adjustSchedulesBasedOnStartDate(startDate);
            }
            this.requestStartDate = startDate;
        } else {
            this.showStartDateRequiredMessage = true;
        }
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     */
    public onEndDateChange(endDate: Date): void {
        this.showEndDateRequiredMessage = false;
        if (endDate) {
            if (moment(this.requestStartDate) < moment(endDate)) {
                this.adjustSchedulesBasedOnEndDate(endDate);
            }
            this.requestEndDate = endDate;
            this.showEndDateEarlierMessage = moment(endDate).isSameOrBefore(moment(this.requestStartDate));
        } else {
            this.showEndDateRequiredMessage = true;
        }
    }

    /**
     * Sets the selected user of the search component to be the user retreived from the type ahead callback.
     * @param user
     */
    public setSelectedUser(user: ISelectedUserAttributes): void {
        this.selectedUser = user;
        this.selectedUserAlias = user.userAlias;
        this.validateResource();
    }

    /**
     * On User Cleared
     */
    public onUserCleared(): void {
        this.selectedUser = null;
    }

    /**
     * Once the resource selected,triggers the event
     * @private
     * @memberof CopyResourceModalComponent
     */
    public onSelectedResourceChanged(resourceRequestDetails: { id: string; resourceRequestId: string }): void {
        const demandsWithSchedules$ = this.store.select((getDemandsWithSchedulesState(this.assignmentList[0].projectIdSelected)));
        demandsWithSchedules$
            .pipe(untilDestroyed(this))
            .subscribe((demandState: IDemandsWithSchedulesState) => {
                if (demandState.loaded) {
                    this.demandDetailsSelected = demandState.wbsDemands;
                    this.loadDetailsBasedOnResourceSelected(resourceRequestDetails.resourceRequestId);
                }
            });
    }

    /**
     * Load Resource Related details based on Resource Selected
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    public loadDetailsBasedOnResourceSelected(resourceRequestId: string): void {
        this.isLoading = true;
        this.weeklySchedules = [];
        this.isPreApprovedRequestDisabled = false;
        this.isSecurityClearanceDisabled = false;
        const filteredResourceRequestDetails = this.draftResourceRequests.filter((rr) => rr.ResourceRequestId.toString() === resourceRequestId);
        if (filteredResourceRequestDetails && filteredResourceRequestDetails.length > 0) {
            this.selectedRole = this.roleIds.filter((r) => r.id.toLowerCase() === filteredResourceRequestDetails[0].Role.toLowerCase())[0];
            this.isAutoCommitRole = this.verifyIsRoleAutoCommit(this.selectedRole);
            this.validateResource();
            this.selectedResourceRequest = filteredResourceRequestDetails[0];
            this.minStartDate = this.assignmentList[0].projectDetails.startDate;
            this.requestStartDate = this.getRequestedStartDate(this.assignmentList[0].projectDetails.startDate, this.assignmentList[0].projectDetails.endDate);
            this.requestEndDate = this.assignmentList[0].projectDetails.endDate;
            this.maxEndDate = this.assignmentList[0].projectDetails.endDate;
            this.isRequestApproved = filteredResourceRequestDetails[0].IsPreApprovedProposal;
            this.isClearanceRequired = filteredResourceRequestDetails[0].IsClearanceRequired;
            if (this.isAutoCommitRole) {
                this.isPreApprovedRequestDisabled = true;
                this.isRequestApproved = false;
            }
            const isClearedRole = this.verifyIsRoleCleared(this.selectedRole);
            if (isClearedRole) {
                this.isSecurityClearanceDisabled = true;
                this.isClearanceRequired = true;
            }
            // if (this.selectedDomain && this.selectedDomain.ServiceDeliveryOrganizationName) {
            //     this.isSkillsRequired = this.selectedDomain.ServiceDeliveryOrganizationName.toLowerCase() !== AREAACCOUNTALIGNED;
            // }
            this.additionalDetails = filteredResourceRequestDetails[0].TravelNotes;
            const selectedDemandDetails = this.demandDetailsSelected.details.filter((details) => details.demandId === filteredResourceRequestDetails[0].DemandResourceRequest.DemandId);
            if (selectedDemandDetails && selectedDemandDetails.length) {
                this.requestedHours = this.copyFromResourceRequest.RequestedDuration;
                this.plannedHoursLeftUnderDemand = selectedDemandDetails[0].planned.plannedHours - this.getPlannedHoursLeftUnderDemand(this.assignmentList[0]);
                this.isPlannedHoursOfDemandExceed = (this.plannedHoursLeftUnderDemand <= 0) || (this.requestedHours > this.plannedHoursLeftUnderDemand);
                this.populateWeeklySchedules(selectedDemandDetails[0], this.requestStartDate, this.requestEndDate, this.requestedHours);
            }
        }
        this.isLoading = false;
    }

    /**
     * Close the Modal Dialog and nullfy the assingment List
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    public closeModal(): void {
        this.assignmentList = [];
        if (this.isModalComponent) {
            super.closeModal();
            const dropDownId = "Role Actions Dropdown " + this.resourceId;
            this.sharedFunctionsService.focus(dropDownId, true);
        } else {
            this.hideTabComponent.emit();
        }
    }

    /**
     * Handle the requested hours change event
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    public onRequestedHoursChange(): void {
        this.isPlannedHoursOfDemandExceed = (this.plannedHoursLeftUnderDemand - this.requestedHours < 0);
    }

    /**
     * Handle the domain change event
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    public onSelectedDomainChanged(): void {
        if (this.selectedDomain && this.selectedDomain.ServiceDeliveryOrganizationName) {
            this.isSkillsRequired = this.selectedDomain.ServiceDeliveryOrganizationName.toLowerCase() !== AREAACCOUNTALIGNED.toLowerCase();
        }
    }

    /**
     * Saves draft resource request and redirects to GRM
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    public saveDraftandRedirect(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.SaveDraftandRedirect, LogEventConstants.CopyResourceEditDetailsOnGRMLink);
        const updatedResourceRequest: IUpdateResourceRequest = this.getUpdatedResourceRequest();
        const addResourceToGRMProject: IEditDraftResourceRequests = {
            DraftResourceRequests: [updatedResourceRequest]
        };
        let grmProjectId;
        const filteredProjectRequests = this.assignmentList[0].grmSearchResponse.ProjectRequests.filter((proj) => proj.DemandSourceId === this.assignmentList[0].projectIdSelected);
        if (filteredProjectRequests && filteredProjectRequests.length) {
            grmProjectId = filteredProjectRequests[0].ProjectId;
        }
        const navigateUrl = `#/project/${grmProjectId}/resource/${updatedResourceRequest.DraftResourceRequestId}/true/requestor`;
        this.isLoading = true;
        this.loadingText = "Saving Draft Resource Request";
        this.grmService.editDraftResourceRequests(addResourceToGRMProject)
            .then(() => {
                this.fxpMessageService.addMessage("Successfully saved resource request", "success", false);
                this.fxpRouteService.navigateToSpecificUrl(navigateUrl);
                this.closeModal();
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.SaveDraftandRedirect, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.closeModal();
            });
    }

    /**
    * Submit request to truncate request for assignment
    */
    public submit(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.SubmitCopyResourceRequest);
        const updatedResourceRequest: IUpdateResourceRequest = this.getUpdatedResourceRequest();
        const grmProjectId = this.selectedResourceRequest.ProjectId;
        const addResourceToGRMProject: IAddResourceToGRMProject = {
            ResourceRequests: [updatedResourceRequest]
        };
        this.isLoading = true;
        this.loadingText = "Copying Resource Request..";
        this.grmService.addResourcesToGRMProject(addResourceToGRMProject, grmProjectId.toString())
            .then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.CopyResourceRequestSuccess);
                this.fxpMessageService.addMessage("Successfully Copied resource request", "success", false);
                this.staffingService.invalidateProjectStaffingData([this.assignmentList[0].projectIdSelected]);
                this.draftResourceRequestIds = this.draftResourceRequestIds.filter((req) => req.resourceRequestId.toString() !== addResourceToGRMProject.ResourceRequests[0].DraftResourceRequestId.toString());
                this.draftResourceRequests = this.draftResourceRequests.filter((req) => req.ResourceRequestId !== addResourceToGRMProject.ResourceRequests[0].DraftResourceRequestId);
                if (this.draftResourceRequests && this.draftResourceRequests.length) {
                    this.selectedResourceRequestId = this.draftResourceRequests[0].ResourceRequestId.toString();
                    this.loadDetailsBasedOnResourceSelected(this.selectedResourceRequestId);
                } else {
                    this.closeModal();
                }
            }).catch((error) => {
                this.isLoading = false;
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.Submit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }

    /**
     * Checks if Submit button can be enabled or disabled
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    public isSubmitDisabled(): boolean {
        if (!this.selectedResourceRequest) {
            return true;
        }
        if (!this.selectedRole || this.showMessageForRequestedResource || !this.requestStartDate || !this.requestEndDate || this.isPlannedHoursOfDemandExceed
            || this.requestedHours <= 0 || this.showEndDateEarlierMessage || this.showDeliveryLocationRequiredMessage || (this.scheduleDurationHours !== this.requestedHours)) {
            return true;
        }
        if (this.isSkillsRequired && !this.taRoleSkills.length) {
            return true;
        }
        return false;
    }

    /**
     * Finds if selected role is autocommit or not
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private verifyIsRoleAutoCommit(selectedRole: { id: string }): boolean {
        if (selectedRole && selectedRole.id) {
            const autoCommitRoles = this.configManagerService.getValue<any>("CustomerEngagementAutocommitRolesValues");
            return autoCommitRoles.filter((role) => role.roleName.trim().toLowerCase() === selectedRole.id.trim().toLowerCase()).length > 0;
        }
        return false;
    }

    /**
     * Finds if selected role is cleared or not
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private verifyIsRoleCleared(selectedRole: { id: string }): boolean {
        if (selectedRole && selectedRole.id) {
            const selectedRoleDetails = this.roleValues.filter((role) => role.roleName.toLowerCase() === selectedRole.id.toLowerCase());
            if (selectedRoleDetails && selectedRoleDetails.length) {
                return selectedRoleDetails[0].isCleared;
            }
        }
        return false;
    }

    /**
     * Validate Resource and show the message for the resource request
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private validateResource(): void {
        this.showMessageForRequestedResource = false;
        if (this.isAutoCommitRole && (!this.selectedUser || !this.selectedUser.userAlias)) {
            this.showMessageForRequestedResource = true;
            this.requestedResourceMessage = DmError.Staffing.RequestedResourceRequired;
        } else if (this.isAutoCommitRole && this.selectedUser && this.selectedUser.userAlias) {
            const cacheKey = CacheKeys.UserProfile.KeyName + `${this.selectedUser.userAlias}`;
            this.cacheService.get(cacheKey, () => this.oneProfileService.getProfile(this.selectedUser.userAlias), CacheKeys.UserProfile.Duration).then((profile: IOneProfileAPIResponse) => {
                if (!(profile && profile.SDODomain && profile.SDODomain.toLowerCase() === AREAACCOUNTALIGNED.toLowerCase())) {
                    this.showMessageForRequestedResource = true;
                    this.requestedResourceMessage = DmError.InternalEngagement.AddRole.DomainAlignedCannotBeAssignedToAccountAlignedRoles;
                }
            });
        }
    }


    /**
     * Close the Modal Dialog and nullfy the assingment List
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private populateWeeklySchedules(selectedDemand: IDemandDetails, requestStartDate: Date, requestEndDate: Date, plannedHours: number) {
        if (selectedDemand.planned.weeklySchedules && selectedDemand.planned.weeklySchedules.length) {
            let weekNumber = 1;
            for (const weeklyschedule of selectedDemand.planned.weeklySchedules) {
                for (const schedule of weeklyschedule.schedules) {
                    const weekStartDate_new = moment(schedule.startDate);
                    const weekEndDate_new = moment(schedule.startDate).add(6, "d");
                    let schedulePatternStartDate = weekStartDate_new;
                    let schedulePatternEndDate = weekEndDate_new;
                    if (weekStartDate_new <= moment(requestStartDate) && weekEndDate_new >= moment(requestStartDate)) {
                        schedulePatternStartDate = moment(requestStartDate);
                    } else {
                        schedulePatternStartDate = weekStartDate_new;
                    }
                    if (weekStartDate_new <= moment(requestEndDate) && weekEndDate_new >= moment(requestEndDate)) {
                        schedulePatternEndDate = moment(requestEndDate);
                    } else {
                        schedulePatternEndDate = weekEndDate_new;
                    }
                    if ((schedulePatternStartDate >= moment(requestStartDate) && schedulePatternStartDate <= moment(requestEndDate)) && (schedulePatternEndDate >= moment(requestStartDate) && schedulePatternEndDate <= moment(requestEndDate))) {
                        this.weeklySchedules.push({
                            startDate: new Date(schedulePatternStartDate.toDate()),
                            endDate: new Date(schedulePatternEndDate.toDate()),
                            hours: schedule.quantity,
                            weekNumber
                        });
                        weekNumber = weekNumber + 1;
                    }
                }
            }
            if (this.weeklySchedules.length >= 2) {
                this.weeklySchedules = this.weeklySchedules.sort(this.sharedFunctionsService.sortEntitiesByColumnName("startDate"));
                this.adjustWeeklySchedulesIndex();
                if (!moment(this.weeklySchedules[0].startDate).isSame(requestStartDate, DAY)) {
                    this.adjustSchedulesForStartDate();
                }
                if (!moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate).isSame(requestEndDate, DAY)) {
                    this.adjustSchedulesForEndDate();
                }
            }
        }
        if (this.weeklySchedules.length <= 2) {
            this.weeklySchedules = [];
            let scheduleStartDate: Date = new Date(moment(requestStartDate).toDate());
            let scheduleEndDate: Date;
            const noOfWeeks = moment(requestEndDate).diff(moment(requestStartDate), "week");
            let schedulehours = (selectedDemand.planned.plannedHours / noOfWeeks).toFixed(0);
            const dayOfWeek = moment(requestStartDate).day();
            let weekNumber = 1;
            if (dayOfWeek === 5) {
                scheduleEndDate = new Date(scheduleStartDate);
            } else if (dayOfWeek < 5) {
                scheduleEndDate = new Date(moment(scheduleStartDate).add(5 - dayOfWeek, "d").toDate());
            } else if (dayOfWeek > 5) {
                scheduleEndDate = new Date(moment(scheduleStartDate).add(6, "d").toDate());
            }
            do {
                if (this.weeklySchedules.reduce((sum, current) => sum + current.hours, 0) >= selectedDemand.planned.plannedHours) {
                    schedulehours = "0";
                }
                this.weeklySchedules.push({
                    startDate: scheduleStartDate,
                    endDate: scheduleEndDate,
                    hours: Number(schedulehours),
                    weekNumber
                });
                weekNumber = weekNumber + 1;
                scheduleEndDate = new Date(moment(scheduleEndDate).add(7, "d").toDate());
                scheduleStartDate = new Date(moment(scheduleEndDate).add(-6, "d").toDate());
            } while (moment(scheduleEndDate) <= moment(requestEndDate));

            if (!moment(this.weeklySchedules[0].startDate).isSame(requestStartDate, DAY)) {
                this.adjustSchedulesForStartDate();
            }
            if (!moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate).isSame(requestEndDate, DAY)) {
                this.adjustSchedulesForEndDate();
            }
        }
        const hoursPerWeek: number = Number((plannedHours / this.weeklySchedules.length).toFixed(0));
        if (plannedHours < this.weeklySchedules.length) {
            for (const week of this.weeklySchedules) {
                week.weekNumber < plannedHours ? week.hours = 1 : week.hours = 0;
            }
        } else {
            for (const week of this.weeklySchedules) {
                week.hours = hoursPerWeek;
            }
        }
        if (selectedDemand.planned.plannedHours !== this.weeklySchedules.reduce((sum, current) => sum + current.hours, 0)) {
            this.weeklySchedules[0].hours = this.weeklySchedules[0].hours + (plannedHours - this.weeklySchedules.reduce((sum, current) => sum + current.hours, 0));
        }
        this.scheduleDurationHours = this.weeklySchedules.reduce((sum, current) => sum + current.hours, 0);
    }


    /**
     * Gets the fromCopy Resource from AssignmentList
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private getCopyFromResourceRequest(assignmentList: IAssignment[]): IResourceRequest {
        const resourceRequestId = assignmentList[0].assignmentId;
        if (assignmentList[0].grmProjectRequest && assignmentList[0].grmProjectRequest.ResourceRequests && assignmentList[0].grmProjectRequest.ResourceRequests.length > 0) {
            const selectedResourceRequest = assignmentList[0].grmProjectRequest.ResourceRequests.filter((resource) => resource.ResourceRequestId === resourceRequestId)[0];
            if (selectedResourceRequest) {
                return selectedResourceRequest;
            }
        }
        return undefined;
    }

    /**
     * Gets the draft Resource requests from AssignmentList
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private getDraftResourceRequestsFromInput(assignmentList: IAssignment[]): IResourceRequest[] {
        let draftResourceRequests: IResourceRequest[] = [];
        let subConRoles = this.configManagerService.getValue<string[]>("restrictedRoles");
        if (subConRoles && subConRoles.length) {
            subConRoles = subConRoles.map((role) => role.toLowerCase());
        }
        if (assignmentList[0].grmSearchResponse && assignmentList[0].grmSearchResponse && assignmentList[0].grmSearchResponse.ProjectRequests.length > 0) {
            const filteredProjectRequests = assignmentList[0].grmSearchResponse.ProjectRequests.filter((proj) => proj.DemandSourceId === assignmentList[0].projectIdSelected);
            for (const project of filteredProjectRequests) {
                if (project.ResourceRequests.length > 0 && project.ResourceRequests.filter((res) => res.IsDraft).length > 0) {
                    draftResourceRequests = draftResourceRequests.concat(project.ResourceRequests.filter((res) => res.IsDraft && res.DemandResourceRequest && res.DemandResourceRequest.DemandId && subConRoles && res.Role && (subConRoles.indexOf(res.Role.toLowerCase()) === -1) && draftResourceRequests.filter((draft) => draft.ResourceRequestId === res.ResourceRequestId).length === 0));
                }
            }
        }
        return draftResourceRequests;
    }


    /**
     * Handle changes on weekly schedule update
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private onWeeklySchedulesUpdated(updatedWeek: IWeek): void {
        this.weeklySchedules.filter((schedule) => schedule.weekNumber === updatedWeek.weekNumber)[0].hours = updatedWeek.hours;
        this.updateRequestedHours();
    }

    /**
     * Update Requested Hours
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private updateRequestedHours(): void {
        const scheduledDuration: number = this.weeklySchedules.reduce((sum, current) => sum + current.hours, 0);
        if (Number.isNaN(scheduledDuration)) {
            this.scheduleDurationHours = 0;
        } else {
            this.scheduleDurationHours = scheduledDuration;
        }
    }

    /**
     * Formats the selected resource request to update model
     */
    private getUpdatedResourceRequest(): IUpdateResourceRequest {
        const updateResourceRequest: IUpdateResourceRequest = {
            DraftResourceRequestId: this.selectedResourceRequest.ResourceRequestId,
            IsProjectInDraft: false,
            AssignmentTypeEnum: this.selectedResourceRequest.AssignmentTypeEnum,
            ContractType: this.selectedResourceRequest.ContractType,
            DeliveryTypeEnum: (this.selectedDeliveryType) ? this.selectedDeliveryType.name : undefined,
            OfferingTypeEnum: this.selectedResourceRequest.OfferingTypeEnum,
            EngagementType: null,
            Technology: this.selectedResourceRequest.Technology,
            OfferingId: this.selectedResourceRequest.OfferingId,
            OfferingName: this.selectedResourceRequest.OfferingName,
            IsPreApprovedProposal: this.isRequestApproved ? true : false,
            RequestedStartDate: new Date(moment(this.requestStartDate).format(DATE_FORMAT) + DATE_FORMAT2),
            RequestedEndDate: new Date(moment(this.requestEndDate).format(DATE_FORMAT) + DATE_FORMAT2),
            RequestedDuration: this.requestedHours,
            DurationUnitEnum: this.selectedResourceRequest.DurationUnitEnum,
            SchedulePatterns: this.getSchedulePatterns(),
            ScheduledStartDate: this.selectedResourceRequest.ScheduledStartDate,
            ScheduledEndDate: this.selectedResourceRequest.ScheduledEndDate,
            TravelDays: this.selectedResourceRequest.TravelDays,
            SchedulePatternUnitEnum: this.selectedResourceRequest.SchedulePatternUnitEnum,
            Role: this.roleValues.filter((role) => role.roleName === this.selectedRole.id)[0].roleName,
            DeliveryCountryCode: this.selectedCountryCode,
            DeliveryState: this.selectedState,
            DeliveryCity: this.selectedCity,
            DeliveryPostalCode: this.selectedResourceRequest.DeliveryPostalCode,
            IsCitizenshipRequired: this.selectedResourceRequest.IsCitizenshipRequired,
            CitizenshipCountryCode: this.selectedResourceRequest.CitizenshipCountryCode,
            IsClearanceRequired: this.isClearanceRequired,
            ClearanceCountryCode: this.selectedResourceRequest.ClearanceCountryCode,
            ClearanceLevelEnum: this.selectedResourceRequest.ClearanceLevelEnum,
            RmNotes: this.additionalDetails,
            PublicNotes: this.selectedResourceRequest.PublicNotes,
            IsContiguous: this.selectedResourceRequest.IsContiguous,
            RequestedResource: this.selectedUserAlias ? this.selectedUserAlias : undefined,
            RequestedResourceName: this.selectedUser ? this.selectedUser.fullName : undefined,
            CanResourceDecline: this.selectedResourceRequest.CanResourceDecline,
            IsCrossBorder: this.selectedResourceRequest.IsCrossBorder,
            IsRemoteWorkAllowed: this.selectedResourceRequest.IsRemoteWorkAllowed,
            MaxDaystoExpire: this.selectedResourceRequest.MaxDaystoExpire,
            TravelNotes: this.additionalDetails,
            Skills: this.getSkillDetails(),
            Certifications: undefined,
            Accreditations: undefined,
            IsPartnerAllowed: this.selectedResourceRequest.IsPartnerAllowed,
            IsPartnerCurrentlyAtAccount: this.selectedResourceRequest.IsPartnerCurrentlyAtAccount,
            IsCustomerInterviewRequired: undefined,
            RequestedPartnerName: undefined,
            ReasonCode: undefined,
            Comments: undefined,
            DemandResourceRequest: this.selectedResourceRequest.DemandResourceRequest,
            Attachments: undefined,
            PoCostAmount: undefined,
            PoCostCurrencyCode: undefined,
            RequestedLoadType: undefined,
            ClosureDuration: this.selectedResourceRequest.ClosureDuration,
            ServicesTeamName: undefined,
            RolePartNumber: this.roleValues.filter((role) => role.roleName === this.selectedRole.id)[0].rolePartNumber,
            TimeZone: this.selectedResourceRequest.TimeZone,
            AssignedResourceBPID: this.selectedUser && this.isAutoCommitRole ? this.selectedUser.bpId : undefined,
            AssignedResource: null,
            AssignedResourceName: null,
            RDCCaseId: undefined,
            IsAutoCommit: this.isAutoCommitRole,
            IsFinancialCheck: false,
            RequestBookingTypeEnum: this.selectedResourceRequest.RequestBookingTypeEnum,
            IsScheduleFree: false,
        };
        return updateResourceRequest;
    }

    /**
     * Gets the Skills model for resource request submission from skills type-ahead
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private getSkillDetails(): ISkill[] {
        const tempSkillsList: ISkill[] = [];
        const grmTenantNameSkillOrgType = this.configManagerService.getValue<string>("GrmTenantNameSkillOrgType");
        if (this.taRoleSkills && this.taRoleSkills.length) {
            // if (this.selectedDomain.ServiceDeliveryOrganizationName.toLowerCase() === AREAACCOUNTALIGNED.toLowerCase()) {
            //     this.taRoleSkills = this.getFilteredSkills(this.taRoleSkills);
            // }
            this.taRoleSkills.forEach((skill, key) => {
                const tempSkill: ISkill = {
                    OneProfileJobSkillId: skill.JobSkillId,
                    OneProfileSkillName: skill.JobSkillValue,
                    ProficiencyLevel: 3,
                    IsPrimary: key === 0,
                    TenantName : grmTenantNameSkillOrgType
                };
                tempSkillsList.push(tempSkill);
            });
        }
        return tempSkillsList;
    }

    /**
     * Gets filtered skills based on the sdodomain id
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private getFilteredSkills(taRoleSkills: IRoleDomainSkill[]): IRoleDomainSkill[] {
        const skills: IRoleDomainSkill[] = [];
        for (const skillObject of taRoleSkills) {
            if (skillObject.Domains.filter((t) => t.DomainId === this.selectedDomain.SDODomainId).length > 0) {
                skills.push(skillObject);
            }
        }
        return skills;
    }

    /**
     * Gets the Schedule patternsfor resource request based on submission
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private getSchedulePatterns(): ISchedulePattern[] {
        const schedulePatterns: ISchedulePattern[] = [];
        for (const weeklyschedule of this.weeklySchedules) {
            schedulePatterns.push({
                ScheduleDuration: weeklyschedule.hours,
                StartDate: moment(weeklyschedule.startDate).format(DATE_FORMAT) + DATE_FORMAT2,
                EndDate: moment(weeklyschedule.endDate).format(DATE_FORMAT) + DATE_FORMAT2,
            });
        }
        return schedulePatterns;
    }

    /**
     * Purpose of this promise is to get the Financial Roles, resolving in both then and catch cases as main objective is to get the api calls done.
     */
    private getFinancialRoles(): Promise<any> {
        return this.cacheService.get(CacheKeys.FinancialRoles.KeyName,
            () => this.projectServiceFunctions.getFinancialRoles(), CacheKeys.FinancialRoles.Duration, CacheStorageOptions.LocalStorage)
            .then((roles: IFinancialRoles[]) => {
                this.roleValues = roles;
                this.roleValues = this.roleValues.filter((role: IFinancialRoles) => this.configManagerService.getValue<string>("restrictedRoles").indexOf(role.roleName) < 0);
                this.sharedFunctionsService.sortListByProperty(this.roleValues, "roleName");
            });
    }

    /**
     * Purpose of this promise is to get the Customer Engagement domains.
     */
    private getDomainValues(): Promise<any> {
        return this.cacheService.get(CacheKeys.CustomerEngagementsDomains.KeyName,
            () => this.oneProfileService.getCustomerEngagementDomains(), CacheKeys.CustomerEngagementsDomains.Duration, CacheStorageOptions.LocalStorage)
            .then((response: ICustomerEngagementDomain[]) => {
                this.customerEngagementDomains = response;
            });
    }

    /**
     * Gets the planned hours left under demand
     */
    private getPlannedHoursLeftUnderDemand(assignmentDetails: IAssignment): number {
        let consumedHours = 0;
        if (assignmentDetails.grmSearchResponse && assignmentDetails.grmSearchResponse && assignmentDetails.grmSearchResponse.ProjectRequests.length > 0) {
            let filteredProjectRequests = assignmentDetails.grmSearchResponse.ProjectRequests.filter((proj) => proj.DemandSourceId === assignmentDetails.projectIdSelected);
            filteredProjectRequests = filteredProjectRequests.filter((v, i, a) => a.findIndex((t) => (t.ProjectId === v.ProjectId)) === i);
            for (const project of filteredProjectRequests) {
                const selectedDemandId = (this.selectedResourceRequest && this.selectedResourceRequest.DemandResourceRequest) ? this.selectedResourceRequest.DemandResourceRequest.DemandId : undefined;
                if (project.ResourceRequests.length > 0 && project.ResourceRequests.filter((res) => selectedDemandId && ACTIVERESOURCESTATUS.indexOf(res.ResourceRequestStatusEnum) > -1 && res.DemandResourceRequest && res.DemandResourceRequest.DemandId === selectedDemandId).length > 0) {
                    const activeResources = project.ResourceRequests.filter((res) => selectedDemandId && ACTIVERESOURCESTATUS.indexOf(res.ResourceRequestStatusEnum) > -1 && res.DemandResourceRequest && res.DemandResourceRequest.DemandId === selectedDemandId);
                    consumedHours = consumedHours + activeResources.reduce((sum, current) => sum + current.RequestedDuration, 0);
                }
            }
        }
        return consumedHours;
    }

    /**
     * Populate skills in the model
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private populateSkills(Skills: ISkill[]): void {
        for (const skill of Skills) {
            this.taRoleSkills.push({
                Domains: [],
                JobSkillId: skill.OneProfileJobSkillId,
                JobSkillValue: skill.OneProfileSkillName
            });
        }

    }


    private getDomainDetails(selectedDomain: ICustomerEngagementDomain): IDomainDetails[] {
        const domainDetails: IDomainDetails[] = [];
        domainDetails.push({
            DomainId: Number(selectedDomain.SDODomainId),
            DomainName: selectedDomain.ServiceDeliveryOrganizationName,
        });
        return domainDetails;
    }

    /**
     * Adjust schedules based on start date
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private adjustSchedulesBasedOnStartDate(requestStartDate: Date): void {
        if (moment(requestStartDate).isAfter(moment(this.requestStartDate), DAY)) {
            for (const week of this.weeklySchedules) {
                if (moment(requestStartDate).isAfter(moment(week.startDate), DAY) && moment(requestStartDate).isBefore(moment(week.endDate), DAY)) {
                    week.startDate = new Date(moment(requestStartDate).toDate());
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber >= week.weekNumber);
                    break;
                } else if (moment(requestStartDate).isSame(moment(week.startDate), DAY)) {
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber >= week.weekNumber);
                    break;
                } else if (moment(requestStartDate).isSame(moment(week.endDate), DAY)) {
                    week.startDate = week.endDate;
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber >= week.weekNumber);
                    break;
                }
            }
        } else if (moment(requestStartDate).isBefore(moment(this.requestStartDate), DAY)) {
            const firstWeek = this.weeklySchedules[0];
            if (firstWeek && moment(requestStartDate).isSameOrAfter(moment(firstWeek.endDate).add(-6, "d"), DAY)) {
                firstWeek.startDate = requestStartDate;
            } else {
                this.weeklySchedules[0].startDate = new Date(moment(firstWeek.endDate).add(-6, "d").toDate());
                let startDate = new Date(moment(this.weeklySchedules[0].startDate).add(-7, "d").toDate());
                let endDate = new Date(moment(this.weeklySchedules[0].endDate).add(-7, "d").toDate());
                if (moment(requestStartDate).isBefore(this.weeklySchedules[0].startDate, DAY)) {
                    do {
                        this.weeklySchedules.unshift({
                            startDate,
                            endDate,
                            hours: 0
                        });
                        startDate = new Date(moment(startDate).add(-7, "d").toDate());
                        endDate = new Date(moment(endDate).add(-7, "d").toDate());
                    } while (moment(requestStartDate).isBefore(moment(startDate), DAY));
                }
                if (moment(this.weeklySchedules[0].startDate).isSameOrBefore(requestStartDate, DAY) && moment(this.weeklySchedules[0].endDate).isSameOrAfter(requestStartDate, DAY)) {
                    this.weeklySchedules[0].startDate = requestStartDate;
                } else if (!moment(requestStartDate).isSame(moment(startDate), DAY)) {
                    this.weeklySchedules.unshift({
                        startDate: new Date(moment(requestStartDate).toDate()),
                        endDate: new Date(moment(endDate).toDate()),
                        hours: 0
                    });
                }
                /// for toggling will remove
                this.weeklySchedules.push({
                    startDate,
                    endDate,
                    hours: 0,
                    weekNumber: 9000
                });
                this.weeklySchedules = this.weeklySchedules.filter((t) => t.weekNumber !== 9000);
            }
        }
        this.adjustWeeklySchedulesIndex();
        this.updateRequestedHours();
    }

    /**
     * Adjust schedules based on start date
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private adjustSchedulesForStartDate(): void {
        if (moment(this.weeklySchedules[0].startDate).isAfter(moment(this.requestStartDate), DAY)) {
            const firstWeek = this.weeklySchedules[0];
            let startDate = new Date(moment(firstWeek.endDate).add(-6, "d").toDate());
            let endDate = new Date(moment(firstWeek.endDate).toDate());
            if (moment(this.weeklySchedules[0].startDate).isAfter(moment(startDate), DAY)) {
                firstWeek.startDate = startDate;
            }
            else {
                startDate = new Date(moment(startDate).add(-7, "d").toDate());
                endDate = new Date(moment(endDate).add(-7, "d").toDate());
                do {
                    this.weeklySchedules.unshift({
                        startDate,
                        endDate,
                        hours: 0
                    });
                    startDate = new Date(moment(startDate).add(-7, "d").toDate());
                    endDate = new Date(moment(endDate).add(-7, "d").toDate());
                } while (moment(startDate).isAfter(moment(this.requestStartDate), DAY));
                if (moment(this.weeklySchedules[0].startDate).isSameOrBefore(this.requestStartDate, DAY) && moment(this.weeklySchedules[0].endDate).isSameOrAfter(this.requestStartDate, DAY)) {
                    this.weeklySchedules[0].startDate = this.requestStartDate;
                } else if (!moment(this.weeklySchedules[0].startDate).isSame(moment(this.requestStartDate), DAY)) {
                    this.weeklySchedules.unshift({
                        startDate: new Date(moment(this.requestStartDate).toDate()),
                        endDate: new Date(moment(endDate).toDate()),
                        hours: 0
                    });
                }
            }

        } else if (moment(this.weeklySchedules[0].startDate).isBefore(moment(this.requestStartDate), DAY)) {
            for (const week of this.weeklySchedules) {
                if (moment(this.requestStartDate).isAfter(moment(week.startDate), DAY) && moment(this.requestStartDate).isBefore(moment(week.endDate), DAY)) {
                    week.startDate = new Date(moment(this.requestStartDate).toDate());
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber >= week.weekNumber);
                    break;
                } else if (moment(this.requestStartDate).isSame(moment(week.startDate), DAY)) {
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber >= week.weekNumber);
                    break;
                } else if (moment(this.requestStartDate).isSame(moment(week.endDate), DAY)) {
                    week.startDate = week.endDate;
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber >= week.weekNumber);
                    break;
                }
            }
        }
        /// for toggling will remove
        this.weeklySchedules.push({
            startDate: new Date(),
            endDate: new Date(),
            hours: 0,
            weekNumber: 9000
        });
        this.weeklySchedules = this.weeklySchedules.filter((t) => t.weekNumber !== 9000);
        this.adjustWeeklySchedulesIndex();
        this.updateRequestedHours();
    }


    /**
     * Adjust schedules based on start date
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private adjustSchedulesBasedOnEndDate(requestEndDate: Date): void {
        if (moment(requestEndDate).isAfter(moment(this.requestEndDate), DAY)) {
            let startDate = this.weeklySchedules[this.weeklySchedules.length - 1].startDate;
            let endDate = new Date(moment(startDate).add(6, "d").toDate());
            if (moment(requestEndDate).isSameOrBefore(endDate, DAY)) {
                const week = this.weeklySchedules[this.weeklySchedules.length - 1];
                week.endDate = requestEndDate;
            } else {
                if (!moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate).isSame(endDate, DAY)) {
                    this.weeklySchedules[this.weeklySchedules.length - 1].endDate = endDate;
                }
                startDate = new Date(moment(startDate).add(7, "d").toDate());
                endDate = new Date(moment(endDate).add(7, "d").toDate());
                do {
                    this.weeklySchedules.push({
                        startDate: new Date(moment(startDate).toDate()),
                        endDate: new Date(moment(endDate).toDate()),
                        hours: 0
                    });
                    startDate = new Date(moment(startDate).add(7, "d").toDate());
                    endDate = new Date(moment(endDate).add(7, "d").toDate());
                } while (moment(endDate).isSameOrBefore(requestEndDate, DAY));
                if (moment(this.weeklySchedules[this.weeklySchedules.length - 1].startDate).isSameOrBefore(requestEndDate, DAY) && moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate).isSameOrAfter(requestEndDate, DAY)) {
                    this.weeklySchedules[this.weeklySchedules.length - 1].endDate = requestEndDate;
                } else if (!moment(requestEndDate).isSame(moment(endDate), DAY)) {
                    this.weeklySchedules.push({
                        startDate: new Date(moment(startDate).toDate()),
                        endDate: new Date(moment(requestEndDate).toDate()),
                        hours: 0
                    });
                }
            }
        } else if (moment(requestEndDate).isBefore(moment(this.requestEndDate), DAY)) {
            for (let index = this.weeklySchedules.length - 1; index >= 0; index--) {
                const week = this.weeklySchedules[index];
                if (moment(requestEndDate).isAfter(moment(week.startDate), DAY) && moment(requestEndDate).isBefore(moment(week.endDate), DAY)) {
                    week.endDate = new Date(moment(requestEndDate).toDate());
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber <= week.weekNumber);
                    break;
                } else if (moment(requestEndDate).isSame(moment(week.endDate), DAY)) {
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber <= week.weekNumber);
                    break;
                } else if (moment(requestEndDate).isSame(moment(week.startDate), DAY)) {
                    week.endDate = requestEndDate;
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber <= week.weekNumber);
                    break;
                }
            }

        }
        this.weeklySchedules.push({
            startDate: new Date(),
            endDate: new Date(),
            hours: 0,
            weekNumber: 9000
        });
        this.weeklySchedules = this.weeklySchedules.filter((t) => t.weekNumber !== 9000);
        this.adjustWeeklySchedulesIndex();
        this.updateRequestedHours();
    }

    /**
     * Adjust schedules based on start date
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private adjustSchedulesForEndDate(): void {
        if (moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate).isAfter(moment(this.requestEndDate), DAY)) {
            for (let index = this.weeklySchedules.length - 1; index >= 0; index--) {
                const week = this.weeklySchedules[index];
                if (moment(this.requestEndDate).isAfter(moment(week.startDate), DAY) && moment(this.requestEndDate).isBefore(moment(week.endDate), DAY)) {
                    week.endDate = new Date(moment(this.requestEndDate).toDate());
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber <= week.weekNumber);
                    break;
                } else if (moment(this.requestEndDate).isSame(moment(week.endDate), DAY)) {
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber <= week.weekNumber);
                    break;
                } else if (moment(this.requestEndDate).isSame(moment(week.startDate), DAY)) {
                    week.startDate = week.endDate;
                    this.weeklySchedules = this.weeklySchedules.filter((w) => w.weekNumber <= week.weekNumber);
                    break;
                }
            }
        } else if (moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate).isBefore(moment(this.requestEndDate), DAY)) {
            let startDate = this.weeklySchedules[this.weeklySchedules.length - 1].startDate;
            let endDate = new Date(moment(startDate).add(6, "d").toDate());
            if (moment(this.requestEndDate).isSameOrBefore(endDate, DAY)) {
                const week = this.weeklySchedules[this.weeklySchedules.length - 1];
                week.endDate = this.requestEndDate;
            } else {
                startDate = new Date(moment(startDate).add(7, "d").toDate());
                endDate = new Date(moment(endDate).add(7, "d").toDate());
                do {
                    this.weeklySchedules.push({
                        startDate: new Date(moment(startDate).toDate()),
                        endDate: new Date(moment(endDate).toDate()),
                        hours: 0
                    });
                    startDate = new Date(moment(startDate).add(7, "d").toDate());
                    endDate = new Date(moment(endDate).add(7, "d").toDate());
                } while (moment(endDate).isSameOrBefore(this.requestEndDate, DAY));
                if (moment(this.weeklySchedules[this.weeklySchedules.length - 1].startDate).isSameOrBefore(this.requestEndDate, DAY) && moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate).isSameOrAfter(this.requestEndDate, DAY)) {
                    this.weeklySchedules[this.weeklySchedules.length - 1].endDate = this.requestEndDate;
                } else if (!moment(this.requestEndDate).isSame(moment(this.weeklySchedules[this.weeklySchedules.length - 1].endDate), DAY)) {
                    this.weeklySchedules.push({
                        startDate: new Date(moment(startDate).toDate()),
                        endDate: new Date(moment(this.requestEndDate).toDate()),
                        hours: 0
                    });
                }
            }
        }
        this.weeklySchedules.push({
            startDate: new Date(),
            endDate: new Date(),
            hours: 0,
            weekNumber: 9000
        });
        this.weeklySchedules = this.weeklySchedules.filter((t) => t.weekNumber !== 9000);
        this.adjustWeeklySchedulesIndex();
        this.updateRequestedHours();
    }

    /**
     * Adjust weeklyschedules index
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private adjustWeeklySchedulesIndex(): void {
        this.weeklySchedules.findIndex((week, index) => {
            week.weekNumber = index + 1;
        });
    }

    /**
     * Get Requested Start Date based on project start date and current date
     *
     * @private
     * @memberof CopyResourceModalComponent
     */
    private getRequestedStartDate(projectStartDate: Date, projectEndDate: Date): Date {
        const bufferDuration = this.configManagerService.getValue<number>("resourceRequestBufferDurationInDays");
        let requestedStartDate;
        if (moment(projectStartDate).isAfter(new Date(), DAY)) {
            requestedStartDate = projectStartDate;
        } else {
            requestedStartDate = new Date();
        }
        if (moment(requestedStartDate).add(bufferDuration, "d").isBefore(projectEndDate, DAY)) {
            requestedStartDate = new Date(moment(requestedStartDate).add(bufferDuration, "d").toDate());
            return requestedStartDate;
        } else {
            return projectStartDate;
        }
    }
}