
import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { Components, BaseLineType, FinancialType, CacheKeys, NoDataText, SourceConstants, AccessibilityConstants } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { CacheService } from "../../../../common/services/cache.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IFinancialPlanV2, IEntityFinancialSummary } from "../..//financial.model";
import { InvalidateFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.action";
import { IOnSave } from "../../../../common/services/contracts/financial.service.contracts";
import { IPlanForecastDataParameters } from "../../../../common/services/contracts/project.service.contracts";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { IState, ILoadableState } from "../../../../store/reducers";
import { NewSnapshotModalComponent } from "./snapshot-modals/new-snapshot/new-snapshot.component";
import { ProjectService } from "../../../../common/services/project.service";
import { RenameSnapshotModalComponent } from "./snapshot-modals/rename-snapshot/rename-snapshot.component";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { DmError } from "../../../../common/error.constants";

@Component({
    selector: "dm-snapshot",
    templateUrl: "./snapshot.html",
    styleUrls: ["./snapshot.scss"]
})
export class SnapshotComponent extends DmComponentAbstract {
    public currency: string;
    public currentBaseLineDetails: IEntityFinancialSummary;
    public currentBaseLinePlanDetails: IFinancialPlanV2;
    public isComponentLoading: boolean;
    public snapShotPlanDetails: IFinancialPlanV2[];
    public numberOfSnapshotsToDisplay: number = 5;
    public gridItemsDisplay: number = 5;
    public slicedItemsNumber: number = 0;
    public currentPageOfSnapshots: number = 1;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.Snapshots;
    public noSnapshots = NoDataText.NoSnapshots;
    public accessibilityConstants = AccessibilityConstants;
    private snapshotVersionId: string; // used for naming snapshots, can't delete yet

    private appInsightsKey: string;
    private engagementId: string;
    private engagementName: string;
    private failureMessages: IOnSave;
    private FXP_CONSTANTS = FxpConstants;
    private isProjectContext: boolean;
    private projectId: string;
    private snapshotBaseUrl: string;
    private successMessages: IOnSave;
    private updatefailureMessages: IOnSave;
    private updatesuccessMessages: IOnSave;


    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Injector) private injector: Injector,
        @Inject(CacheService) private cacheService: CacheService
    ) {
        super(dmLogger, Components.FinancialSnapshots);
    }

    /**
     *  Update the Snapshot Urls on load
     */
    public ngOnInit(): void {
        this.successMessages = this.configManagerService.getValue<any>("SuccessMessages").CopyFinancial;
        this.failureMessages = this.configManagerService.getValue<any>("FailureMessages").CopyFinancial;
        this.updatesuccessMessages = this.configManagerService.getValue<any>("SuccessMessages").UpdateCopyFinancial;
        this.updatefailureMessages = this.configManagerService.getValue<any>("FailureMessages").UpdateCopyFinancial;
        this.snapshotBaseUrl = this.configManagerService.getValue<string>("snapShotViewUrl");
        this.appInsightsKey = this.configManagerService.getValue<string>("planForecastAppInsightsKey");

        this.projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        if (this.projectId) {
            this.isProjectContext = true;
            this.storeDispatchService
                .requireProjectDetails(this.projectId, true)
                .requireFinancialDetailsV2(this.projectId, true)
                .load();
            const projectDetails$ = this.store.select(getEntireProjectDetails(this.projectId));
            const projectFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.projectId));
            observableCombineLatest(
                projectDetails$,
                projectFinancialDetails$,
                (
                    projectDetails: IProjectDetailsState,
                    projectFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    projectDetails,
                    projectFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    projectDetails,
                    projectFinancialDetails,
                }) => {
                    this.checkLoadingStatus(projectDetails, projectFinancialDetails);
                    if (projectDetails.loaded && projectFinancialDetails.loaded) {
                        this.currency = projectDetails.projectDetails.projectFullDetails.currency;
                        this.engagementName = projectDetails.projectDetails.engagementFullDetails.name; /// TODO for engagement name we are relying on api can be optimized
                        this.currentBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.CurrentFinancialPlan, projectFinancialDetails.financialDetails);
                        this.currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails.financialDetails, FinancialType.CurrentFinancialPlan);
                        this.snapShotPlanDetails = this.financialService.getSortedSnapShoDetailsForV2(projectFinancialDetails.financialDetails.financialPlanVersions);
                        this.snapshotVersionId = this.currentBaseLineDetails.versionKey;
                        this.updateSnapshotViewUrls(this.snapShotPlanDetails);
                    }
                    if (projectDetails.error || projectFinancialDetails.error) {
                        this.isServerError = true;
                    }
                });
        } else {
            this.storeDispatchService
                .requireEngagementDetails(this.engagementId, true)
                .requireFinancialDetailsV2(this.engagementId, true)
                .load();
            const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
            const engagementfinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.engagementId));
            observableCombineLatest(
                engagementDetails$,
                engagementfinancialDetails$,
                (
                    engagementDetails: IEngagementDetailsState,
                    engagementFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    engagementDetails,
                    engagementFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    engagementDetails,
                    engagementFinancialDetails,
                }) => {
                    this.checkLoadingStatus(engagementDetails, engagementFinancialDetails);
                    if (engagementDetails.loaded && engagementFinancialDetails.loaded) {
                        this.currency = engagementDetails.engagementDetails.currency;
                        this.engagementName = engagementDetails.engagementDetails.name;
                        this.currentBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.CurrentFinancialPlan, engagementFinancialDetails.financialDetails);
                        this.currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails.financialDetails, FinancialType.CurrentFinancialPlan);
                        this.snapShotPlanDetails = this.financialService.getSortedSnapShoDetailsForV2(engagementFinancialDetails.financialDetails.financialPlanVersions);
                        this.snapshotVersionId = this.currentBaseLineDetails.versionKey;
                        this.updateSnapshotViewUrls(this.snapShotPlanDetails);
                    }
                    if (engagementFinancialDetails.error || engagementDetails.error) {
                        this.isServerError = true;
                    }
                });
        }
    }

    /**
     * Changes the current page, grid items to display and sliced number on changing the page
     *
     * @param {number} currentPage
     * @memberof GridDataComponent
     */
    public currentPageChangedHandler(currentPage: number): void { // todo consider moving this into a shared function.
        if (currentPage === 1) {
            this.slicedItemsNumber = 0;
            this.gridItemsDisplay = 5;
        } else if (this.currentPageOfSnapshots < currentPage) {
            this.slicedItemsNumber = this.gridItemsDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay + this.numberOfSnapshotsToDisplay;
        } else {
            this.slicedItemsNumber = this.slicedItemsNumber - this.numberOfSnapshotsToDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay - this.numberOfSnapshotsToDisplay;
        }
        this.currentPageOfSnapshots = currentPage;
    }

    /**
     * Rename SnapShot | modal popup
     * @param snapshot
     */
    public renameSnapShot(snapshot: IFinancialPlanV2): void {
        const modalRef: NgbModalRef = this.modalService.open(RenameSnapshotModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal in active rename-snapshot manage-wbs-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.snapshotDetails = snapshot;
        modalRef.componentInstance.projectId = this.engagementId;

        modalRef.result.then((response: { isSuccess: boolean; snapshotName: string }) => {
            if (!response) {
                /* Rename was cancelled, do nothing. */
            } else if (response.isSuccess) {
                let sucessMessage: string = this.updatesuccessMessages.OnSave;
                sucessMessage = sucessMessage.replace("#", this.engagementName);
                this.fxpMessageService.addMessage(sucessMessage, this.FXP_CONSTANTS.messageType.success);
                const entityId: string = this.isProjectContext ? this.projectId : this.engagementId;
                this.store.dispatch(new InvalidateFinancialDetailsV2(entityId));
            } else {
                let failureMessage: string = this.updatefailureMessages.OnSave;
                failureMessage = failureMessage.replace("#", this.engagementName);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
            }
        });

    }

    /**
     * Opens the modal where users create a new snapshot.
     */
    public openNewSnapshotModal(): void {
        let lastCreatedSnapshotVersionId: number;
        if (this.snapShotPlanDetails && this.snapShotPlanDetails.length) {
            const versionIdList: number[] = this.snapShotPlanDetails.map((item) => {
                return Number(item.versionId);
            });
            lastCreatedSnapshotVersionId = Math.max.apply(null, versionIdList) + 1;
        } else {
            lastCreatedSnapshotVersionId = Number(this.snapshotVersionId) + 1;
        }

        const modalRef: NgbModalRef = this.modalService.open(NewSnapshotModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal in active newsnapshotModal manage-wbs-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.snapshotName = this.engagementName;
        modalRef.componentInstance.engagementId = this.engagementId;
        modalRef.componentInstance.engagementName = this.engagementName;
        modalRef.componentInstance.lastCreatedSnapshotVersionId = lastCreatedSnapshotVersionId;

        modalRef.result.then((response) => {
            if (!response) {
                return;
            }

            if (response.isSuccess) {
                let sucessMessage: string = this.successMessages.OnSave;
                sucessMessage = sucessMessage.replace("#", response.snapshotName);
                this.store.dispatch((this.isProjectContext ? new InvalidateFinancialDetailsV2(this.projectId) : new InvalidateFinancialDetailsV2(this.engagementId)));
                this.fxpMessageService.addMessage(sucessMessage, this.FXP_CONSTANTS.messageType.success);
            } else {
                let failureMessage: string = this.failureMessages.OnSave;
                failureMessage = failureMessage.replace("#", response.snapshotName);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
            }
        });

    }

    /**
     * Manages the loading state and errors of the given loadable state items.
     *
     * @private
     * @param {...ILoadableState[]} items
     * @memberof FinancialPlanComponent
     */
    private checkLoadingStatus(...items: ILoadableState[]): void {
        this.refreshOnItemInvalidation(...items);
        this.setLoadersBasedOnItemState(...items);
        this.setErrorsBasedOnItemState(...items);
    }

    /**
     * Updates the SnapShot URLs in-place. We're not using the Workbook Urls returned by the API; instead Lumira Snapshot View Urls will be used.
     * @param engagementId
     * @param snapShotDetails
     */
    private updateSnapshotViewUrls(snapShotDetails: IFinancialPlanV2[]): void {
        if (!snapShotDetails || !snapShotDetails.length) {
            /* Snapshot Details are Missing */
            return;
        }
        this.isComponentLoading = true;

        const cacheKey = CacheKeys.PlanForecastParamsBasedOnEngagementId.KeyName + this.engagementId;
        this.cacheService.get(cacheKey, () => this.projectService.getPlanForecastParamsForEngagementOrProject(this.engagementId), CacheKeys.PlanForecastParamsBasedOnEngagementId.Duration)
            .then((planForecastData: IPlanForecastDataParameters) => {
                const extendedProperties: string[] = [];
                if (Array.isArray(planForecastData.extension)) {
                    planForecastData.extension.forEach(({ key, value }) => {
                        if ($.type(value) === "string" && $.type(key) === "string" && key !== "FILTERBYWBSID") {
                            // Add the remaining key-value pairs in extension to Query string params (F_START_WK,F_END_WK,F_EXCL_PERIODS1 etc.). Don't need FILTERBYWBSID as in Project View we show Engagement Snapshots
                            extendedProperties.push(`&${key}=${value}`);
                        }
                    });
                }

                for (const snapshot of snapShotDetails) {

                    const snapshotViewUrl = `${this.snapshotBaseUrl}`
                        + `&XPLANID=${planForecastData.period.planId}`
                        + `&XVERSION=${snapshot.versionId}`
                        + `&XFISCALPERIOD=${planForecastData.period.startPeriod} - ${planForecastData.period.endPeriod}`
                        + `&XVARIANT=${planForecastData.period.variant}`
                        + "&XCLIENT=300"
                        + `&XAPPINSIGHTSKEY=${this.appInsightsKey}`
                        + `${extendedProperties.join("")}`
                        + "&noDetailsPanel=true";

                    snapshot.planWorkBookLink = snapshotViewUrl;
                }
                this.isComponentLoading = false;

            }).catch((error) => {
                // If the user doesn't have access (which is unlikely in production as all PJMs have access) to load Lumira then the best option is to remove the hyperlink.
                for (const snapshot of snapShotDetails) {
                    snapshot.planWorkBookLink = "";
                }
                this.logError(SourceConstants.Method.UpdateSnapshotViewUrls, error, "User does not have access", ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
            });
    }

}
