import { Pipe, PipeTransform } from "@angular/core";

/**
 * Custom pipe/filter that will convert null/undefined/empty values into a dash
 */
@Pipe({
    name: "dmDisplayDashIfEmptyOrNull"
})
export class DmDisplayDashIfEmptyOrNullPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public transform(value: any): string {
        if (!value) {
            return "-";
        }
        return value;
    }
}