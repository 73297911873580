import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalV2Component } from "./dm-modal-v2.component";

@NgModule({
    declarations: [
        DmModalV2Component
    ],
    imports: [
        CommonModule,
        NgbModule
    ],
    providers: [
        DMLoggerService,
    ],
    exports: [
        DmModalV2Component,
    ],
    entryComponents: [
        DmModalV2Component,
    ]
})
export class DmModalV2Module { }
