import { Component, forwardRef, Inject, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { CacheService } from "../../../../../common/services/cache.service";
import { ConcurService } from "../../../../../common/services/concur.service";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { DMNotificationService, NotificationModel } from "../../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { getEntireResourceRequestsStateObject } from "../../../../../store/resource-requests/resource-requests.selector";
import { IChangedProperties } from "../../../../../common/services/contracts/dmnotification.service.contract";
import { IConcurPendingReportsResponse } from "../../../../../common/services/contracts/concur.contracts";
import { IDeliveryStateValue, IDistributionListObject, IResponseMessage, IDeliveryState } from "../../../../../common/services/contracts/wbs.service.contracts";
import { IEngagementDetailsV2, ITeamDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { ILaborApprovalCountBasedOnAssignments } from "../../../../../common/services/contracts/labor-management.contract";
import { INotificationMessages } from "../../../../../common/services/contracts/financial.service.contracts";
import { IOneProfileBulkAPIResponse } from "../../../../../common/services/contracts/one-profile.contracts";
import { IResourceRequestResponse } from "../../../../../common/services/contracts/staffing.service.contract";
import { IResourceRequestsDetailsState } from "../../../../../store/resource-requests/resource-requests.reducer";
import { IState } from "../../../../../store/reducers";
import { LaborManagementService } from "../../../../../common/services/labor-management.service";
import { LoadResourceRequests } from "../../../../../store/resource-requests/resource-requests.action";
import { LogEventConstants, NotificationEvent, SourceConstants, Components, WBSResponseMessages, CacheKeys, RequestedState, AccessibilityConstants } from "../../../../../common/application.constants";
import { MyPortfolioService } from "../../../../../common/services/my-portfolio.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OneProfileService } from "../../../../../common/services/one-profile.service";
import { ProjectServiceV2 } from "../../../../../common/services/project.v2.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../../common/services/wbs.service";
import { CosmicServiceFunctions } from "../../../../../common/services/cosmic-function.service";
import { ICosmicFunctionResponse } from "../../../../../common/services/contracts/cosmic-function.contracts";
import { IWbsSettings } from "../../../../../common/services/contracts/project.service.v2.contracts";
import { DmError } from "../../../../../common/error.constants";

const RELEASED_STATUS_CODE = "REL";
const TECO_STATUS_CODE = "RECO";
const TECO_EBS_STATE = "Complete";
const pendingApprovalStatus = [
    "Committed",
    "Assigned",
    "Complete",
    "Closed"
];

@Component({
    selector: "dm-wbs-engagement-ebs-state",
    templateUrl: "./engagement-request-state-change-modal.html"
})
export class WbsEngagementStateModalComponent extends DmModalAbstract {
    @Input() public selectedEngagement: IEngagementDetailsV2;
    public deliveryStateListOptions: IDeliveryStateValue[];
    public ebsState: string = "";
    public isStateChangeErrorMessage: boolean = false;
    public isSubmitBtnDisabled: boolean = true;
    public requestStateChangeText: string;
    public statusDescription: string;
    public userRequestedEbsState: string;
    public accessibilityConstants = AccessibilityConstants;
    private allDeliveryStates: IDeliveryState[];
    private grmRequestList: IResourceRequestResponse;
    private hasPendingExpensesForEngagement: boolean;
    private hasPendingLaborForEngagement: boolean;
    private notificationMessage: INotificationMessages;
    private readonly FXP_CONSTANTS = FxpConstants;
    private serviceResponseMessages: IResponseMessage;
    private wbsSettings: IWbsSettings;


    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(WBSService) private wbsEngUpdateService: WBSService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(ConcurService) private concurService: ConcurService,
        @Inject(Store) private store: Store<IState>,
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(LaborManagementService) private laborManagementService: LaborManagementService,
        @Inject(CosmicServiceFunctions) private cosmicService: CosmicServiceFunctions,
        @Inject(CacheService) private cacheService: CacheService
    ) {
        super(activeModal, dmLogger, Components.ManageWBSEngagementRequestStateChange);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.notificationMessage = this.configurationService.getValue<any>("Notification");
        this.serviceResponseMessages = WBSResponseMessages.Engagement;
        this.requestStateChangeText = "Requesting an EBS State change will notify the Assistant Services Controller (ASC) to review and change the EBS State. The requester will be notified when this activity is completed.";
        this.statusDescription = this.selectedEngagement.statusDescription;

        /* Get the available options for the current ebs state */
        const configString: string = this.selectedEngagement.isInternalEngagment ? "deliveryStateListInternal" : "deliveryStateListCustomerFacing";
        this.allDeliveryStates = this.configurationService.getValue<IDeliveryState[]>(configString);
        if (this.allDeliveryStates) {
            const filteredOptions: IDeliveryState = this.allDeliveryStates.filter((item: IDeliveryState) => item && item.state && item.state === this.selectedEngagement.statusCode)[0];
            this.deliveryStateListOptions = filteredOptions ? filteredOptions.values : undefined;
        }

        this.wbsSettings = undefined;
        if (!this.selectedEngagement.isInternalEngagment) { /* Only customer facing engagements need to check for an existing request on EBS state change; internal engagements do not sit in a pending state */
            this.projectServiceV2.getWbsSettings(this.selectedEngagement.id).then((wbsSettings: IWbsSettings) => {
                this.wbsSettings = wbsSettings;
                if ((this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForRelease.toLowerCase() || this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForTechnicallyCompleted.toLowerCase())
                    && ((this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForRelease.toLowerCase() && this.selectedEngagement.statusCode !== RELEASED_STATUS_CODE)
                        || (this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForTechnicallyCompleted.toLowerCase() && this.selectedEngagement.statusCode !== TECO_STATUS_CODE))) {
                    this.userRequestedEbsState = wbsSettings.requestedState;
                }
            }).catch((error) => {
                let errorMessage: string;
                if (error.status === 404) {
                    this.wbsSettings = this.projectServiceV2.getDefaultWbsSettings(this.selectedEngagement.id);
                } else {
                    errorMessage = DmError.EbsRequestChange.ErrorOccuredInGettingRequestedEBSState;
                    this.fxpMessageService.addMessage((DmError.EbsRequestChange.ErrorOccuredInGettingRequestedEBSState + error), this.FXP_CONSTANTS.messageType.error);
                }
                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
        }
        this.setLoadersBasedOnItemState();
    }

    /**
     * Sends a request to update the EBS state
     */
    public updateEbsState(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.UpdateEbsState, LogEventConstants.EBSRequestStateChange);
        this.isComponentLoading = true;
        const engagementId = this.selectedEngagement.id;
        if (!this.selectedEngagement.isInternalEngagment) {
            const updatedState = this.deliveryStateListOptions.filter((item) => item.value === this.ebsState)[0].displayText;
            const changedProperties: IChangedProperties[] = [];
            this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Engagement EBS State", this.statusDescription, updatedState));
            this.createLogAndSendNotification(this.selectedEngagement, changedProperties);
            /* modal is closed in create log and send notification */
        } else {
            const selectedEbsAction = this.allDeliveryStates.filter((item) => item.state === this.ebsState)[0].action;
            /* If updating to TECO, need to first check for pending labor and expenses */
            if (selectedEbsAction.toLowerCase() === TECO_EBS_STATE.toLowerCase()) {
                this.hasPendingExpensesForEngagement = false;
                this.hasPendingLaborForEngagement = false;
                this.store.dispatch(new LoadResourceRequests(engagementId));
                const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsStateObject(engagementId));
                resourceRequestDetail$.pipe(untilDestroyed(this)).subscribe((resourceRequestDetail: IResourceRequestsDetailsState) => {
                    if (resourceRequestDetail.loaded) {
                        this.grmRequestList = resourceRequestDetail.grmSearchApiResponse;
                        const pjmAliases: string[] = this.getPjmAliases();
                        const promises: Array<Promise<any>> = [];
                        if (pjmAliases.length > 1) {
                            for (const projectDetails of this.selectedEngagement.projects) {
                                const pjmDetails: ITeamDetailsV2 = this.sharedFunctionsService.getPjmInfoL1("PJM", projectDetails)[0];
                                const assignmentIds: string[] = this.getAssignmentsFromGRMResponse(this.grmRequestList, projectDetails.id);
                                if (assignmentIds.length > 0) {
                                    promises.push(this.getPendingLaborDetails(assignmentIds, this.selectedEngagement.id, pjmDetails.alias));
                                }
                            }
                        } else if (pjmAliases.length === 1) {
                            const assignmentIds: string[] = this.getAssignmentsFromGRMResponse(this.grmRequestList);
                            if (assignmentIds.length > 0) {
                                promises.push(this.getPendingLaborDetails(assignmentIds, this.selectedEngagement.id, pjmAliases[0]));
                            }
                        }
                        // TODO: Skip expense check until issue is fixed
                        // promises.push(this.checkForPendingExpenses());
                        Promise.all(promises).then(() => {
                            if (this.hasPendingExpensesForEngagement || this.hasPendingLaborForEngagement) {
                                this.isComponentLoading = false;
                                this.fxpMessageService.addMessage(DmError.EbsRequestChange.ThereArePendingLaborOrExpenses, "error", true);
                            } else {
                                this.callUpdateEbsStateApi(engagementId, selectedEbsAction);
                            }
                        }).catch((error) => {
                            this.isComponentLoading = false;
                            this.fxpMessageService.addMessage(DmError.EbsRequestChange.UnableToRetrievePendingLaborOrExpensesDetails, "error", true);
                            this.logError(SourceConstants.Method.UpdateEbsState, error, DmError.EbsRequestChange.UnableToRetrievePendingLaborOrExpensesDetails, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        });
                    }

                });
            } else {
                this.callUpdateEbsStateApi(engagementId, selectedEbsAction);
            }
        }
    }

    /**
     * Method triggers on ebs state change.
     *
     * @memberof WbsEngagementStateModalComponent
     */
    public onEbsStateChange(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.OnEbsStateChange, LogEventConstants.EbsStateSelected, { selectedState: this.statusDescription });
        this.isSubmitBtnDisabled = true;
        if (this.ebsState && this.ebsState !== this.selectedEngagement.statusDescription) {
            this.isSubmitBtnDisabled = false;
        }
        if (!this.selectedEngagement.isInternalEngagment) {
            const updatedState = this.deliveryStateListOptions.filter((item) => item.value === this.ebsState)[0].displayText;
            // Submit is disabled if user changes state from created to released and show appropriate message.
            if (this.statusDescription === "Created" && updatedState === "Released") {
                this.isStateChangeErrorMessage = true;
                this.isSubmitBtnDisabled = true;
            }
        }
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseButton);
        }
    }

    /**
     * Logs the event for when a tooltip is clicked, including the event constant for the specific tooltip (passed in)
     *
     * @param {string} logEventConstant the specific event constant for the type of tooltip
     */
    public tooltipClicked(logEventConstant: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.TooltipClicked, logEventConstant);
    }

    /**
     * Gets the assignments from GRM Response
     * @param grmRequestList
     */
    private getAssignmentsFromGRMResponse(grmRequestList: IResourceRequestResponse, projectId?: string): string[] {
        const assignmentIds: string[] = [];
        if (grmRequestList.ProjectRequests && grmRequestList.ProjectRequests.length > 0) {
            for (const projectRequest of grmRequestList.ProjectRequests) {
                if (projectRequest.ResourceRequests && projectRequest.ResourceRequests.length > 0) {
                    for (const resourceRequest of projectRequest.ResourceRequests) {
                        if (pendingApprovalStatus.indexOf(resourceRequest.ResourceRequestStatusEnum) > -1 && !projectId) {
                            assignmentIds.push(resourceRequest.ResourceRequestId.toString());
                        } else if (projectId && projectRequest.DemandSourceId && projectRequest.DemandSourceId.substring(0, 19) === projectId && pendingApprovalStatus.indexOf(resourceRequest.ResourceRequestStatusEnum) > -1) {
                            assignmentIds.push(resourceRequest.ResourceRequestId.toString());
                        }
                    }
                }
            }
        }
        return assignmentIds;
    }

    /**
     * Call the Update api for internal Engagement
     * @param engagementId
     * @param selectedEbsAction
     */
    private callUpdateEbsStateApi(engagementId: string, selectedEbsAction: string): void {
        let updateAction = selectedEbsAction;
        this.isSubmitBtnDisabled = true;
        if (selectedEbsAction === "Release" && this.selectedEngagement.statusCode !== "TECO") {
            // only TECO can be changed to Release
            updateAction = "Complete";
        }
        this.wbsEngUpdateService.updateStatus(engagementId, updateAction)
            .then((response) => {
                this.isComponentLoading = false;
                if (response && response.status && response.status === 206) {
                    let partialSuccessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                    partialSuccessMessage = partialSuccessMessage.replace("#", this.selectedEngagement.name);
                    this.fxpMessageService.addMessage(partialSuccessMessage, this.FXP_CONSTANTS.messageType.warning);
                } else {
                    let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                    successMessage = successMessage.replace("#", this.selectedEngagement.name);
                    successMessage = successMessage.replace("*", "EBS State"); // CodeQL [SM02383] Need to replace the single occurrence of *.
                    this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                }

                let newStatus: string;
                /* If a user requests a state change to Technically Complete, send a TECO request,
                then after it is successful (notify the user), send a CLSD request (don't notify the user)*/
                if (this.ebsState === "TECO") {
                    newStatus = "Close";
                    /* If a user is reopening a closed engagement, after it moves into TECO state from CLSD, 
                    it must then go TECO to REL (don't notify the user) */
                } else if (this.ebsState === "REL" && this.selectedEngagement.statusCode !== "TECO") {
                    newStatus = selectedEbsAction;
                }
                if (newStatus) {
                    this.wbsEngUpdateService.updateStatus(engagementId, newStatus);
                }
                this.engagementDetailService.invalidateStoreOnRefresh(engagementId);
                this.myPortfolioService.refreshMyPortfolioEngagementList();
                this.isSubmitBtnDisabled = false;
                this.closeModal();
            })
            .catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.selectedEngagement.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.CallUpdateEbsStateApi, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
                this.isSubmitBtnDisabled = false;
            });
    }

    /**
     * Pushes the given value to the given array if the value evaluates as truthy.
     * (If the value is not null or undefined, false, or the number 0.)
     * Modifies the given array by reference, and does not need to return.
     */
    private pushToArrayIfTrue(array: any[], value: any): void {
        if (value) {
            array.push(value);
        }
    }

    /**
     * Get Unapproved expense from concur API and build expense data
     */
    private checkForPendingExpenses(): Promise<any> {
        /* Get the personel number of the pjms */
        const pjmAliases: string[] = this.getPjmAliases();
        const cacheKey = CacheKeys.BulkUserProfile.KeyName + JSON.stringify(pjmAliases);
        return this.cacheService.get(cacheKey,
            () => this.oneProfileService.getBulkProfiles(pjmAliases), CacheKeys.BulkUserProfile.Duration)
            .then((pjmUserDetails: IOneProfileBulkAPIResponse[]) => {
                const promises: Array<Promise<any>> = [];
                for (const pjmAlias of pjmAliases) {
                    const pjmPersonnelNumber = pjmUserDetails.filter((userInfo) => userInfo.Alias.toLowerCase() === pjmAlias.toLowerCase())[0].PersonnelNumber;
                    const queryString = "employeeId=" + pjmPersonnelNumber.toString();
                    promises.push(this.getPendingExpensesFromConcur(queryString));
                }
                return Promise.all(promises);
            });
    }

    /**
     * Get Pjm Aliases from Engagement Details Object
     */
    private getPjmAliases(): string[] {
        const pjmAliases: string[] = [];
        for (const projectDetails of this.selectedEngagement.projects) {
            const pjmDetails: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("PJM", projectDetails);
            if (pjmDetails && pjmDetails.length && pjmAliases.indexOf(pjmDetails[0].alias) === -1) {
                pjmAliases.push(pjmDetails[0].alias);
            }
        }
        return pjmAliases;
    }

    /**
     * Gets the list of pending expense reports by calling the Concur Service.
     * @param queryString
     */
    private getPendingExpensesFromConcur(queryString: string): Promise<void> {
        const key = `${CacheKeys.ConcurPendingReportsApproval.KeyName}-${queryString}`;
        return this.cacheService.get(key, () => this.concurService.getPendingReportsforApproval(queryString), CacheKeys.ConcurPendingReportsApproval.Duration)
            .then((data: IConcurPendingReportsResponse[]) => {
                if (data && data.length > 0) {
                    const filteredExpenses = data.filter((detail) => detail.engagementId === this.selectedEngagement.id);
                    if (filteredExpenses.length > 0) {
                        this.hasPendingExpensesForEngagement = true;
                    }
                }
            });
    }

    /**
     * Gets details of pending labor reports by calling the Time2.0 labor Service.
     * @param queryString
     */
    private getPendingLaborDetails(assignmentIds: string[], engagementId: string, approverAlias: string): Promise<any> {
        const requestPayLoad = {
            assignmentIds,
            engagementId
        };
        const key = `${CacheKeys.PendingLaborApprovalCountBasedOnAssignments.KeyName}-${JSON.stringify(requestPayLoad)}`;
        return this.cacheService.get(key, () => this.laborManagementService.getPendingLaborApprovalCountBasedOnAssignments(assignmentIds, engagementId, approverAlias), CacheKeys.PendingLaborApprovalCountBasedOnAssignments.Duration)
            .then((data: ILaborApprovalCountBasedOnAssignments) => {
                if (data && data.count > 0) {
                    this.hasPendingLaborForEngagement = true;
                }
            });
    }

    /**
     * If the given objects are different, creates and returns a changed property object.
     * Returns undefined if the objects have not changed.
     * @param attributeName
     * @param originalValue
     * @param updatedValue
     */
    private createChangedPropertyObject(attributeName: string, originalValue: string, updatedValue: string): IChangedProperties {
        if (originalValue !== updatedValue) {
            return {
                name: attributeName,
                oldValue: originalValue,
                newValue: updatedValue
            };
        }
        return undefined;
    }

    /**
     * Creates a log string from the given changed properties: combines all the changed attribute names into a single, comma seperated string.
     * @param changedProperties
     */
    private createLogStringFromChangedProperties(changedProperties: IChangedProperties[]): string {
        let logString: string = "";
        changedProperties.forEach((property, index) => {
            logString += property.name;
            if (index !== changedProperties.length - 1) {
                logString += ",";
            }
        });
        return logString;
    }

    /**
     *  Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     *  relevant people.
     * @private
     * @param {IEngagementDetailsV2} engagementDetailsV2
     * @param {IChangedProperties[]} changedProperties
     * @memberof WbsEngagementStateModalComponent
     */
    private createLogAndSendNotification(engagementDetailsV2: IEngagementDetailsV2, changedProperties: IChangedProperties[]): void {
        this.isSubmitBtnDisabled = true;
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.EBSRequestStateChange, propertyBag);

        const currEngagement: IEngagementDetailsV2 = engagementDetailsV2;
        const notification = new NotificationModel();
        const wbsDetails = {
            wbsId: currEngagement.id,
            properties: []
        };
        wbsDetails.properties.push({
            state: changedProperties[0].newValue.toString()
        });
        notification.engagementId = currEngagement.id;
        notification.engagementName = currEngagement.name;
        notification.eventName = NotificationEvent.EBSStateChangeRequest;
        const securityGroups: IDistributionListObject[] = this.configurationService.getDistributionList(currEngagement.isConfidential);

        let sendTo: string[] = [];
        const cc: string[] = this.sharedFunctionsService.getListofPjmV2(currEngagement)  // gets project managers and delegated project managers for the engagement and its children projects
            .concat(this.sharedFunctionsService.getListofAdPjmV2(currEngagement))  // gets additional project managers for the engagement and its children projects
            .concat(userAlias);

        if (currEngagement.companyCode) {
            const secGroup = securityGroups.filter((x) => x.CompanyCode === Number(currEngagement.companyCode))[0];
            if (secGroup && secGroup.SecurityGroup) {
                sendTo = sendTo.concat(secGroup.SecurityGroup);
            }
        }
        if (!sendTo.length) {
            const defaultSecurityGroup: string = securityGroups.filter((x) => x.CompanyCode === 0)[0].SecurityGroup;
            sendTo.push(defaultSecurityGroup);
        }
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(cc);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;
        notification.subject = "EBS State Change Requested for Engagement [" + currEngagement.id + ": " + currEngagement.name + "]";
        notification.attributes = {
            Type: "Engagement",
            Name: currEngagement.name,
            Id: currEngagement.id,
            Company: currEngagement.companyCode + (currEngagement.companyName ? " - " + currEngagement.companyName : ""),
            Country: currEngagement.customerCountry,
            IsConfidential: currEngagement.isConfidential
        };
        let esxpNotification = this.notificationMessage.EngagementNotification;
        esxpNotification = esxpNotification.replace("#", this.selectedEngagement.name);
        this.cosmicService.createCosmicTicketForNotification(notification)
            .then((cosmicFunctionResponse: ICosmicFunctionResponse) => {
                notification.subject = cosmicFunctionResponse.title;
                /* tslint:disable:no-string-literal */
                notification.attributes["CosmicTicketNumber"] = cosmicFunctionResponse.ticketNumber;
                notification.attributes["CosmicTicketLink"] = cosmicFunctionResponse.incidentId;
                this.notificationService.sendNotification(notification, true, esxpNotification).then(() => {
                    this.wbsSettings.requestedState = this.sharedFunctionsService.getRequestedState(changedProperties[0].newValue.toString());
                    this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                        this.isComponentLoading = false;
                        this.isSubmitBtnDisabled = false;
                        this.engagementDetailService.invalidateStoreOnRefresh(currEngagement.id);
                        this.fxpMessageService.addMessage("EBS State Change has been requested.", this.FXP_CONSTANTS.messageType.success);
                        this.closeModal();
                    });
                });
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.selectedEngagement.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.CreateLogAndSendNotification, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
                this.isSubmitBtnDisabled = false;
            });
    }
}




