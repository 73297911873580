import { Action } from "@ngrx/store";
import { IWbsDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";

// 1. action types
export enum WbsDetailsV2ActionTypes {
    LOAD_WBS_DETAILS = "[WbsDetails] Load",
    LOAD_WBS_DETAILS_SUCCESS = "[WbsDetails] Load Success",
    LOAD_WBS_DETAILS_FAIL = "[WbsDetails] Load Fail",
    INVALIDATE_WBS_DETAILS = "[WbsDetails] Invalidate"
}

// 2. action creators
export class LoadWbsDetails implements Action {
    public readonly type = WbsDetailsV2ActionTypes.LOAD_WBS_DETAILS;
    public constructor(public wbsId: string, public includeEngagements: boolean, public includeProjects?: boolean, public includeServices?: boolean,
        public includeTasks?: boolean, public includeTeams?: boolean) { }
}

export class LoadWbsDetailsSuccess implements Action {
    public readonly type = WbsDetailsV2ActionTypes.LOAD_WBS_DETAILS_SUCCESS;
    public constructor(public wbsId: string, public wbsDetails: IWbsDetailsV2) { }
}

export class LoadWbsDetailsFail implements Action {
    public readonly type = WbsDetailsV2ActionTypes.LOAD_WBS_DETAILS_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateWbsDetails implements Action {
    public readonly type = WbsDetailsV2ActionTypes.INVALIDATE_WBS_DETAILS;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type WbsDetailsActionsV2 =
    | LoadWbsDetails
    | LoadWbsDetailsSuccess
    | LoadWbsDetailsFail
    | InvalidateWbsDetails;