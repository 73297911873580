import { Action } from "@ngrx/store";
import { IInvoiceItemModel } from "../../components/invoices/invoice-table-data/invoice-table-data.contract";

// 1. action types
export enum InvoicesActionTypes {
    LOAD_INVOICES = "[Invoices] Load",
    LOAD_MANUAL_INVOICES = "[Invoices] Load Manual",
    LOAD_INVOICES_SUCCESS = "[Invoices] Load Success",
    LOAD_INVOICES_FAIL = "[Invoices] Load Fail",
    INVALIDATE_INVOICES = "[Invoices] Invalidate"
}

// 2. action creators
export class LoadInvoices implements Action {
    public readonly type = InvoicesActionTypes.LOAD_INVOICES;
    public constructor(public engagementId: string) { }
}

export class LoadManualInvoices implements Action {
    public readonly type = InvoicesActionTypes.LOAD_MANUAL_INVOICES;
    public constructor(public engagementId: string) { }
}

export class LoadInvoicesSuccess implements Action {
    public readonly type = InvoicesActionTypes.LOAD_INVOICES_SUCCESS;
    public constructor(public engagementId: string, public invoices: IInvoiceItemModel[]) { }
}

export class LoadInvoicesFail implements Action {
    public readonly type = InvoicesActionTypes.LOAD_INVOICES_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateInvoices implements Action {
    public readonly type = InvoicesActionTypes.INVALIDATE_INVOICES;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type InvoicesAction =
    | LoadInvoices
    | LoadManualInvoices
    | LoadInvoicesSuccess
    | LoadInvoicesFail
    | InvalidateInvoices;