import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PersonCardComponent } from "./person-card.component";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        PersonCardComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        CommonComponentsModule
    ],
    providers: [
        SharedFunctionsService
    ],
    entryComponents: [
        PersonCardComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        PersonCardComponent
    ]
})
export class PersonCardModule { }
