
import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject, AfterContentChecked } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { IFinancialDetail, IEntityFinancials, IEntityFinancialSummary } from "../../../financial-mgmt/financial.model";
import { IFinancialViewModel, ICceac } from "../../../project-summary-v2/tiles/project-summary-financials-v2/project.financials.model";
import { IState, ILoadableState } from "../../../../store/reducers";
import { LogEventConstants, SourceConstants, Components, FinancialType, NoDataText, ComponentFailureMessages } from "../../../../common/application.constants";
import { RouteName } from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";

@Component({
    selector: "dm-engagement-summary-financials-v2",
    templateUrl: "./engagement-summary-financials-v2.html",
    styleUrls: ["./engagement-summary-financials-v2.scss"]
})
export class EngagementSummaryFinancialsV2Component extends DmComponentAbstract implements AfterContentChecked {
    public isUnitBasedContract: boolean = false;
    public engagementFinancialDetails: IEntityFinancials;
    public currentConsumed: number = 0;
    public financialsData: IFinancialViewModel[] = [];
    public currencyType: string;
    public financialDetails: IFinancialDetail[];
    public hideConsumedGraph: boolean;
    public graphClass: string;
    public cceacValues: ICceac;
    public isBaseLineActive: boolean = false;
    public errorText: string;
    public noFinancialText: string;
    public errorGettingFinancials: boolean;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public tileContent: ITile;
    public isServerError: boolean;
    public eacDetails: IEntityFinancialSummary;
    public etcDetails: IEntityFinancialSummary;
    public isForecastInitiated: boolean;
    private componentsMounted: boolean;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService

    ) {
        super(dmLogger, Components.EngagementSummaryFinancials,
            [{ component: Components.EngagementSummaryFinancials, isCritical: true }, { component: Components.Cceac, isCritical: true }]);
    }

    public ngOnInit(): void {
        this.noFinancialText = NoDataText.NoFinancialDataLoadedText;
        this.errorText = ComponentFailureMessages.EngagementFinancialComponent;
        this.errorGettingFinancials = false;
        this.tileContent = {
            title: "Financials"
        };
        const selectedId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.storeDispatchService
            .requireEngagementDetails(selectedId, true)
            .requireFinancialDetailsV2(selectedId, true)
            .load();
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(selectedId));
        const engagementfinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(selectedId));
        observableCombineLatest(
            engagementDetails$,
            engagementfinancialDetails$,
            (
                engagementDetails: IEngagementDetailsState,
                engagementFinancialDetails: IFinancialDetailsV2State,
            ) => ({
                engagementDetails,
                engagementFinancialDetails,
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                engagementDetails,
                engagementFinancialDetails,
            }) => {
                this.checkLoadingStatus(engagementDetails, engagementFinancialDetails);
                if (engagementDetails.loaded && engagementFinancialDetails.loaded) {
                    this.isUnitBasedContract = engagementDetails.engagementDetails.hasUnitBasedDemands;
                    this.engagementFinancialDetails = engagementFinancialDetails.financialDetails;
                    this.loadEngagementFinancialDetails();
                    this.currencyType = this.financialService.getCurrencyTypeForV2(engagementFinancialDetails.financialDetails.financialSummary);
                }
                if (engagementDetails.error || engagementFinancialDetails.error) {
                    this.isServerError = true;
                }
            });
    }

    public ngAfterContentChecked(): void {
        if (
            !this.componentsMounted &&
            (this.deviceFactory.isSmallScreen() || this.deviceFactory.isDesktop()) &&
            this.engagementFinancialDetails &&
            this.engagementFinancialDetails.financialSummary &&
            this.isBaseLineActive
        ) {
            this.addNewChildComponent(Components.Cceac);
            this.componentsMounted = true;
        }
    }


    /**
     * Load financial engagement details and build Baseline, cceac and financial data.
     *
     * @memberof EngagementSummaryFinancialsComponent
     */
    public loadEngagementFinancialDetails(): void {
        if (this.engagementFinancialDetails.financialSummary && this.engagementFinancialDetails.financialSummary.length) {
            const engagementSummaryFinancialDetails: IEntityFinancialSummary[] = this.engagementFinancialDetails.financialSummary;
            this.isBaseLineActive = this.financialService.getIsBaselineActiveForV2(engagementSummaryFinancialDetails);
            this.eacDetails = this.financialService.getFinancialDetailsFromParentForV2Object(this.engagementFinancialDetails, FinancialType.EAC);
            this.etcDetails = this.financialService.getFinancialDetailsFromParentForV2Object(this.engagementFinancialDetails, FinancialType.ETC);
            this.isForecastInitiated = this.financialService.IsEACETCFinancialsExists(this.eacDetails, this.etcDetails);
            this.cceacValues = this.financialService.getCceacValuesForV2(engagementSummaryFinancialDetails);
            this.financialsData = this.loadFinancialsData(engagementSummaryFinancialDetails);
        }
    }

    /**
     * Routes the user to the financials tab within the engagement detail component.
     * Logs info for application insights telemetry.
     */
    public logFinancialsClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.LogFinancialsClick, LogEventConstants.FinancialsLinkClicked);
    }

    /**
     * Loads the Financial View Model array from the given financial details. Filters the financial details based on
     * its baselineType. Creates cceac values and sets isBaseLineActive. Returns the financial data that is ultimately reflected on the view.
     * @param financialDetailsArray
     */
    private loadFinancialsData(financialDetailsArray: IEntityFinancialSummary[]): IFinancialViewModel[] {
        const financialData: IFinancialViewModel[] = [];
        if (financialDetailsArray) {
            if (this.isForecastInitiated) {
                this.financialService.addFinancialsToFinancialsViewModelListForV2(financialData, financialDetailsArray.filter((c) => c.versionKey === FinancialType.EAC), "EAC");
            }
            this.financialService.addFinancialsToFinancialsViewModelListForV2(financialData, financialDetailsArray.filter((c) => c.versionKey === FinancialType.CurrentFinancialPlan), "Current");
            this.financialService.addFinancialsToFinancialsViewModelListForV2(financialData, financialDetailsArray.filter((c) => c.versionKey === FinancialType.DeliveryBaseline), "Delivery");
            this.financialService.addFinancialsToFinancialsViewModelListForV2(financialData, financialDetailsArray.filter((c) => c.versionKey === FinancialType.ContractBaseline), "Bid");
        }
        return financialData;
    }

    /**
     * Manages the loading state and errors of the given loadable state items.
     *
     * @private
     * @param {...ILoadableState[]} items
     * @memberof FinancialPlanComponent
     */
    private checkLoadingStatus(...items: ILoadableState[]): void {
        this.refreshOnItemInvalidation(...items);
        this.setLoadersBasedOnItemState(...items);
        this.setErrorsBasedOnItemState(...items);
    }

}
