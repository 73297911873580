<section class="dm-modal" role="dialog" aria-modal="true" [attr.aria-label]="modalHeaderContent?.title"
    [ngStyle]="{'min-height':modalMinHeight, 'height':modalHeight}">
    <div class="dm-modal__close-btn">
        <ng-content select="[additionalActionsRight]"></ng-content>
        <button type="button" id="closeModalv2" [title]="'Close ' + modalHeaderContent?.title" class="close set-focus" (click)="closeModal()">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
    </div>
    <div class="dm-modal__header modal-header modal-noborder" *ngIf="!isLoading">
        <div class="display-grid dm-p-t-20">
            <h3 class="modal-title">
                {{modalHeaderContent?.title}}
            </h3>
            <span class="dm-modal__subTitle">{{modalHeaderContent?.subTitle}}</span>
            <ng-content select="[additionalActionsLeft]"></ng-content>
        </div>
    </div>
    <div class="dm-modal__content modal-body"
        [ngClass]="{'dm-modal__content-not-overflow': hasNonOverflowContent, 'dm-modal__content-loading': isLoading, 'dm-m-b-30': !showFooter && contentMarginBottom}">
        <div class="dm-modal__content-body">
            <ng-content></ng-content>
        </div>
        <div class="dm-modal__loader">
            <ng-content select="[loader]"></ng-content>
        </div>
        <div class="dm-modal__info-bar">
            <ng-content select="[infoBar]"></ng-content>
        </div>
    </div>
    <div class="dm-modal__footer modal-footer" *ngIf="showFooter">
        <ng-content select="[additionalFooterButtonsLeft]"></ng-content>
        <button type="button" [attr.aria-label]="'Cancel ' + modalHeaderContent?.title" id="cancel" class="fxp-btn fxp-btn--secondary dm-btn" (click)="closeModal()"
            *ngIf="!isLoading">
            Cancel
        </button>
        <ng-content select="[additionalFooterButtons]"></ng-content>
    </div>
</section>