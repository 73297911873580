<div class="col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad dm-p-x-imp-0">
    <div>
        <p class="error-msg msg-font" *ngIf="isSubmitFailed">
            {{ getFormattedErrorText() }}
        </p>
    </div>
    <button *ngIf="!isSubmitInProgress" type="button" id="syncEACToPlan" class="fxp-btn fxp-btn--primary"
        (click)="handleSyncEACToPlanSubmit()">
        Sync
    </button>
    <button *ngIf="isSubmitInProgress" type="button" id="submitSyncEACToPlan" class="fxp-btn fxp-btn--primary" disabled>
        Submitting sync EAC to plan request...
    </button>
</div>