import { Inject, Component, Input } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { Components, RouteName} from "../../../common/application.constants";
import { ITeamContacts } from "../../../common/services/contracts/team-contacts.contracts";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../store/reducers";

@Component({
    selector: "team-structure",
    templateUrl: "./team-structure.html",
    styleUrls: ["./team-structure.scss"]
})
export class TeamStructureModalComponent extends DmModalAbstract {

    @Input() public teamContacts: ITeamContacts[] = [];
    public modalContent: IModal;
    public RouteName = RouteName;
    public internalEngagementSummaryRouteParams: { engagementId: string };
    
    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(activeModal, dmLogger, Components.TeamStructure);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Team Structure"
        };
        this.getTeamStructureContacts();
    }

    /**
     * gets team structure contacts
     */
    private getTeamStructureContacts(): void {
        for (const contact of this.teamContacts) {
            for (const person of contact.contacts) { 
                person.personDetails = this.sharedFunctionsService.getPersonDetails(person);
            }
        }
    }
}