import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BulkCreateInternalEngagementComponent } from "./bulk-create-internal-engagement.component";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ProjectService } from "../../../common/services/project.service";
import { AddInternalEngagementProjectModule } from "../../new-internal-engagement/add-internal-engagement-project/add-internal-engagement-project.module";
import { BulkUploadInternalEngagementService } from "../../../common/services/bulk-upload-internal-engagement.service";
import { NewInternalEngagementModuleV2 } from "../../new-internal-engagement/new-internal-engagement-v2.module";

@NgModule({
    declarations: [
        BulkCreateInternalEngagementComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AddInternalEngagementProjectModule,
        NewInternalEngagementModuleV2,
        NgbModule,
    ],
    providers: [
        ConfigManagerService,
        SharedFunctionsService,        
        BulkUploadInternalEngagementService,
        ProjectService,
    ],
    entryComponents: [
        BulkCreateInternalEngagementComponent,
    ],
    exports: [
        BulkCreateInternalEngagementComponent,
    ]
})
export class BulkCreateInternalEngagementModule { }
