<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'330px'" [tileHeight]="'100%'" [isV2Tile]="true">
    <a additionalActionsRight [uiSref]="financialsRouteName"
        [attr.aria-label]="this.tileContent?.title +' tile section ' + 'Go to Details' + 'Navigate to Financial Details' " (click)="logFinancialsClick()"
        title="Navigate to Financial Details" class="dm-link" role="link">
        Go to Details
    </a>
    <dm-loading loader [isCustomText]="true" [loadingText]="loadingText" [showLoading]="showLoading"
        *ngIf="isComponentLoading && !isServerError">
    </dm-loading>
    <ng-container *ngIf="!isComponentLoading && !isServerError">
        <div *ngIf="!hideFinancialBreakdownDetails" [ngClass]="{'dm-overflow': deviceFactory.isSmallScreen()}">
            <div class="small-tiles" [ngClass]="{'small-tiles--onethird-view': !isTwoThirdTile}">
                <div *ngFor="let financial of financialsBreakdownData; let deliveryCostIndex = last"
                    class="small-tiles-section dm-m-r-4"
                    [ngClass]="{'small-tiles-section-delivery-cost': !isTwoThirdTile && deliveryCostIndex, 'small-tiles-section--onethird-view': !isTwoThirdTile}">
                    <dm-inner-tile [data]="financial" [isTwoThirdTile]="isTwoThirdTile">
                    </dm-inner-tile>
                    <div class="dm-flex-align-center symbol" *ngIf="financial.title !== 'CFP Planned Cost'"
                        [ngClass]="{'symbol--onethird-view': !isTwoThirdTile}">
                        <span aria-label="plus" *ngIf="financial.title === 'Contract Baseline Cost'"
                            class="icon icon-AddLegacy">
                            <span class="sr-only">plus</span>
                        </span>
                        <span aria-label="minus" *ngIf="financial.title === 'Additional Cost'"
                            class="icon icon-DashKey">
                            <span class="sr-only">minus</span>
                        </span>
                        <span aria-label="equal" *ngIf="financial.title === 'Cost Savings'"
                            class="icon-full icon-equal dm-p-t-5">
                            <span class="sr-only">equal</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="section-grid">
                <span class="sr-only" id="financialsBreakdownTable">Financials Breakdown</span>
                <div role="grid" aria-labelledby="financialsBreakdownTable">
                    <div role="rowgroup" class="dm-grid-view-table">
                        <div role="row" class="dm-grid-view-table__row">
                            <div role="gridcell">
                            </div>
                            <div role="gridcell" class="font-caption-alt dm-font-weight-500">
                                Contract Baseline Cost
                            </div>
                            <div role="gridcell" class="budget-cost text-align-right dm-font-weight-500">
                                {{ budgetCost | currency: currencyType: 'symbol-narrow' }}
                            </div>
                        </div>
                        <div role="row" class="dm-grid-view-table__row">
                            <div role="gridcell">
                                <button tabindex="0" role="button" class="dm-transparent-btn"
                                    *ngIf="additionalCost !== 0" (click)="expandCollapseBreakdown('Additional Cost')"
                                    (keyup.Space)="expandCollapseBreakdown('Additional Cost')"
                                    [attr.aria-label]="!isAdditionalCostExpanded ? 'Collapsed additional cost' : 'Expanded additional cost'"
                                    [title]="isAdditionalCostExpanded ? 'Press enter to collapsed additional cost' : 'Press enter to expanded additional cost'">
                                    <span class="icon"
                                        [ngClass]="{'icon-ChevronUp': isAdditionalCostExpanded, 'icon-ChevronDown': !isAdditionalCostExpanded}"></span>
                                </button>
                            </div>
                            <div role="gridcell" class="dm-detail__value">
                                Additional Cost
                            </div>
                            <div role="gridcell" class="display-flex-imp additional-cost dm-font-weight-500">
                                <span *ngIf="!isAdditionalCostExpanded">+
                                    <span *ngIf="additionalCost !== 0">
                                        {{ additionalCost | currency: currencyType: 'symbol-narrow' }}
                                    </span>
                                    <span *ngIf="additionalCost === 0">
                                        {{ 0 | currency: currencyType: 'symbol-narrow' }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <ng-container *ngIf="isAdditionalCostExpanded">
                            <ng-container
                                *ngTemplateOutlet="additonalOrSavingsCost; context: {costType: {isAdditionalCost: true, isCostSavings: false}}">
                            </ng-container>
                        </ng-container>
                        <div role="row" class="dm-grid-view-table__row">
                            <div role="gridcell">
                                <button role="button" class="dm-transparent-btn" *ngIf="hasCostSavings"
                                    (click)="expandCollapseBreakdown('Cost Savings')"
                                    (keyup.Space)="expandCollapseBreakdown('Cost Savings')"
                                    [attr.aria-label]="!isCostSavingsExpanded ? 'Collapsed cost savings' : 'Expanded cost savings'"
                                    [title]="isCostSavingsExpanded ? 'Press enter to collapsed cost savings' : 'Press enter to expanded cost savings'">
                                    <span class="icon"
                                        [ngClass]="{'icon-ChevronUp': isCostSavingsExpanded, 'icon-ChevronDown': !isCostSavingsExpanded}"></span>
                                </button>
                            </div>
                            <div role="gridcell" class="dm-detail__value">
                                Cost Savings
                            </div>
                            <div role="gridcell" class="display-flex-imp cost-savings dm-font-weight-500"
                                *ngIf="!isCostSavingsExpanded">
                                <span *ngIf="costSavings !== 0">
                                    {{ costSavings | currency: currencyType: 'symbol-narrow' }}
                                </span>
                                <span *ngIf="costSavings === 0">
                                    {{ 0 | currency: currencyType: 'symbol-narrow' }}
                                </span>
                            </div>
                        </div>
                        <ng-container *ngIf="isCostSavingsExpanded">
                            <ng-container
                                *ngTemplateOutlet="additonalOrSavingsCost; context: {costType: {isAdditionalCost: false, isCostSavings: true}}">
                            </ng-container>
                        </ng-container>
                        <div role="row" class="dm-grid-view-table__row delivery-cost division">
                            <div role="gridcell">
                            </div>
                            <div role="gridcell" class="font-caption-alt dm-font-weight-500">
                                CFP Planned Cost
                            </div>
                            <div role="gridcell" class="delivery-cost__value text-align-right dm-font-weight-500">
                                {{ deliveryCost | currency: currencyType: 'symbol-narrow' }}
                            </div>
                        </div>
                    </div>
                </div>
                <section class="dm-risk-reserve-progress" *ngIf="hasRiskReserve">
                    <div class="display-grid justify-content-center">
                        <div class="display-flex justify-content-center dm-font-weight-500">Risk Reserve</div>
                        <div class="dm-risk-reserve-progress-bar justify-content-center">
                            <dm-circular-progress [percent]="consumedAmtPercentage" [value]="riskReserve.total"
                                [showValue]="true" [units]="''" [subtitle]="'Total'" [subtitleLineTwo]="'Risk Reserve'"
                                [radius]="50" [space]="-10" [outerStrokeWidth]="10" [innerStrokeWidth]="10"
                                [outerStrokeColor]="'#9885e4'" [innerStrokeColor]="'#e7e8ea'" [titleFontWeight]="600"
                                [subtitleFontSize]="10" [subtitleFontWeight]="600" [valueFontSize]="20"
                                [subtitleDistance]="20">
                            </dm-circular-progress>
                        </div>
                    </div>
                    <section class="dm-risk-reserve-progress-info dm-p-t-8">
                        <section class="dm-risk-reserve-progress-info__item dm-p-b-imp-8">
                            <section class="dm-risk-reserve-progress-info__item-description">
                                <span
                                    class="dm-risk-reserve-progress-info__legend dm-risk-reserve-progress-info__legend--consumed"></span>
                                <span class="dm-risk-reserve-progress-info__description">Projected Risk Reserve</span>
                            </section>
                            <div class="dm-detail__value justify-self-right">
                                {{ riskReserve.consumed | currency: currencyType: 'symbol-narrow' }}
                            </div>
                        </section>
                        <section class="dm-risk-reserve-progress-info__item dm-p-b-imp-8">
                            <section class="dm-risk-reserve-progress-info__item-description">
                                <span
                                    class="dm-risk-reserve-progress-info__legend dm-risk-reserve-progress-info__legend--remaining"></span>
                                <span class="dm-risk-reserve-progress-info__description">
                                    Remaining Risk Reserve
                                </span>
                            </section>
                            <div class="dm-detail__value justify-self-right">
                                {{ riskReserve.remainingCfpRr | currency: currencyType: 'symbol-narrow' }}
                            </div>
                        </section>
                        <div class="dm-risk-reserve-progress-info__item dm-p-t-10 division">
                            <div class="font-caption-alt">
                                Approved Risk Reserve
                            </div>
                            <div class="dm-detail__value justify-self-right">
                                {{ riskReserve.approved | currency: currencyType: 'symbol-narrow' }}
                            </div>
                        </div>
                        <div class="dm-risk-reserve-progress-info__item">
                            <div class="font-caption-alt">
                                Net Cost Overrun
                            </div>
                            <div class="dm-detail__value justify-self-right">
                                {{ riskReserve.grossCostOverRun | currency: currencyType: 'symbol-narrow' }}
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </div>
        <div *ngIf="hideFinancialBreakdownDetails">
            <dm-no-data [noDataText]="noFinancialBreakdownDetailsText" showImage="true">
            </dm-no-data>
        </div>
    </ng-container>
    <dm-service-error *ngIf="!isComponentLoading && isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
    <ng-template #additonalOrSavingsCost let-costType="costType">
        <div role="row" class="dm-grid-view-table__row dm-p-b-8"
            *ngIf="(costType.isAdditionalCost && hoursCharge > 0) || (costType.isCostSavings && hoursCharge <= 0)">
            <div role="gridcell">
            </div>
            <div role="gridcell" class="font-caption-alt">
                Labor
            </div>
            <div role="gridcell" class="display-flex-imp"
                [ngClass]="(hoursCharge > 0) ? 'additional-cost' : 'cost-savings'">
                <span *ngIf="costType.isAdditionalCost">+</span>
                <span *ngIf="hoursCharge === 0">-</span>
                {{ hoursCharge | currency: currencyType: 'symbol-narrow' }}
            </div>
        </div>
        <div role="row" class="dm-grid-view-table__row dm-p-b-8"
            *ngIf="(costType.isAdditionalCost && subconFFCost > 0) || (costType.isCostSavings && subconFFCost <= 0)">
            <div role="gridcell">
            </div>
            <div role="gridcell" class="font-caption-alt">
                SubconFF
            </div>
            <div role="gridcell" class="display-flex-imp"
                [ngClass]="(subconFFCost > 0) ? 'additional-cost' : 'cost-savings'">
                <span *ngIf="costType.isAdditionalCost">+</span>
                <span *ngIf="subconFFCost === 0">-</span>
                {{ subconFFCost | currency: currencyType: 'symbol-narrow' }}
            </div>
        </div>
        <div role="row" class="dm-grid-view-table__row dm-p-b-8"
            *ngIf="(costType.isAdditionalCost && resourcesCost > 0) || (costType.isCostSavings && resourcesCost <= 0)">
            <div role="gridcell">
            </div>
            <div role="gridcell" class="font-caption-alt">
                Resource
            </div>
            <div role="gridcell" class="display-flex-imp"
                [ngClass]="(resourcesCost > 0) ? 'additional-cost' : 'cost-savings'">
                <span *ngIf="costType.isAdditionalCost">+</span>
                <span *ngIf="resourcesCost === 0">-</span>
                {{ resourcesCost | currency: currencyType: 'symbol-narrow' }}
            </div>
        </div>
        <div role="row" class="dm-grid-view-table__row dm-p-b-8"
            *ngIf="(costType.isAdditionalCost && unitsCost > 0) || (costType.isCostSavings && unitsCost <= 0)">
            <div role="gridcell">
            </div>
            <div role="gridcell" class="font-caption-alt">
                Units
            </div>
            <div role="gridcell" class="display-flex-imp"
                [ngClass]="(unitsCost > 0) ? 'additional-cost' : 'cost-savings'">
                <span *ngIf="costType.isAdditionalCost">+</span>
                <span *ngIf="unitsCost === 0">-</span>
                {{ unitsCost | currency: currencyType: 'symbol-narrow' }}
            </div>
        </div>
        <div role="row" class="dm-grid-view-table__row dm-p-b-8"
            *ngIf="(costType.isAdditionalCost && expenses > 0) || (costType.isCostSavings && expenses <= 0)">
            <div role="gridcell">
            </div>
            <div role="gridcell" class="font-caption-alt">
                Expenses
            </div>
            <div role="gridcell" class="display-flex-imp"
                [ngClass]="(expenses > 0) ? 'additional-cost' : 'cost-savings'">
                <span *ngIf="costType.isAdditionalCost">+</span>
                <span *ngIf="expenses === 0">-</span>
                {{ expenses | currency: currencyType: 'symbol-narrow' }}
            </div>
        </div>
        <div role="row" class="dm-grid-view-table__row dm-p-b-8"
            *ngIf="(costType.isAdditionalCost && remainingRrDiff > 0) || (costType.isCostSavings && remainingRrDiff <= 0)">
            <div role="gridcell">
            </div>
            <div role="gridcell" class="font-caption-alt">
                Projected Risk Reserve
            </div>
            <div role="gridcell" class="display-flex-imp"
                [ngClass]="(remainingRrDiff > 0) ? 'additional-cost' : 'cost-savings'">
                <span *ngIf="costType.isAdditionalCost">+</span>
                <span *ngIf="remainingRrDiff === 0">-</span>
                {{ remainingRrDiff | currency: currencyType: 'symbol-narrow' }}
            </div>
        </div>
    </ng-template>
</dm-tile>