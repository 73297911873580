import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DmDisplayDateOrDashPipe } from "../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { PlanForecastAuditHistoryComponent } from "./plan-forecast-audit-history-modal.component";
import { TimelineModule } from "../../shared/timeline/timeline.module";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { AADGraphService } from "../../../common/services/aad-graphapi.service";
import { AuditService } from "../../../common/services/audit.service";

@NgModule({
    declarations: [
        PlanForecastAuditHistoryComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        TimelineModule
    ],
    providers: [
        AuditService,
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        OneProfileService,
        AADGraphService
    ],
    entryComponents: [
        PlanForecastAuditHistoryComponent,
    ],
    exports: [
        PlanForecastAuditHistoryComponent,
    ]
})
export class PlanForecastAuditHistoryModule { }
