import "./date.extension";
import "./string.extension";

declare global {
    interface Date {
        toUtcFormat(): string;
    }

    interface String {
        toDate(): Date;
    }

    interface String {
        toValueOnEmptyOrUndefined(toValue: string): string;
    }
}

export { };