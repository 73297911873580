import { forwardRef, Inject, Injectable } from "@angular/core";
import { ErrorSeverityLevel, FxpConstants, FxpMessageService, FxpRouteService, UserInfoService } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";

import { OperationName, ResourceName, RouteName, NoDataText, Services, SourceConstants } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "../services/data.service";
import { DMLoggerService } from "./dmlogger.service";
import { DMAuthorizationService } from "./dmauthorization.service";
import { IBillingMilestoneModel, IGetBillingMileStoneListApiParameterModel } from "./contracts/dmmilestone.service.contract";
import { IProjectDetails, ITypeColorCode, IContractType } from "./contracts/project.service.contracts";
import { IEngagementDetailsApiV2, IEngagementDetailsV2, IWbsDetailsV2, IProjectDetailsV2, ITeamDetailsV2, IWbsDetailsV2Model, IProjectDetailsApiV2 } from "./contracts/wbs-details-v2.contracts";
import { IEngagementFinancial } from "../../components/financial-mgmt/financial.model";
import { IEngagementList, IProjectList } from "./contracts/portfolio.model";
import { IEngagementViewModel } from "../../components/engagement-detail/engagement.model";
import { IInvoiceItemDataModel, IInvoiceItemModel, IProjectDataToFilter } from "../../components/invoices/invoice-table-data/invoice-table-data.contract";
import { ILineItem, IPurchaseOrder } from "./contracts/po.service.contracts";
import { InvalidateChangeRequests } from "../../store/change-requests/change-requests.action";
import { InvalidateEngagementDetails } from "../../store/engagement-details/engagement-details.action";
import { InvalidateFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.action";
import { InvalidateInvoices } from "../../store/invoices/invoices.action";
import { InvalidateInternalFinancialDetails } from "../../store/internal-financial-details/internal-financial-details.action";
import { InvalidateManageSuppliers } from "../../store/manage-suppliers/manage-suppliers.action";
import { InvalidateMilestones } from "../../store/milestones/milestones.action";
import { InvalidateResourceRequests } from "../../store/resource-requests/resource-requests.action";
import { InvalidateWbsDetails } from "../../store/wbs-details/wbs-details.action";
import { InvoiceService } from "./invoices.service";
import { IPoListViewModel } from "./contracts/poList.model.contract";
import { IServiceTypeList } from "./contracts/dmgrm.contracts";
import { IState } from "../../store/reducers";
import { MilestonesService } from "./milestones.service";
import { ProjectService } from "./project.service";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";

@Injectable()
export class EngagementDetailService extends DmServiceAbstract {
    public projectDetails: IProjectDetails;
    private lockedUserStatus: string;
    private serviceTypeList: IServiceTypeList[];
    private FXP_CONSTANTS = FxpConstants;
    private loggedInUserBPID: number;

    /**
     * Creates an instance of EngagementDetailService.
     * @param {FxpMessageService} fxpMessageService
     * @param {UserInfoService} fxpUserInfoService
     * @param {ProjectService} projectService
     * @param {DMAuthorizationService} dmauthorizationservice
     * @param {ConfigManagerService} configurationService
     * @param {PurchaseOrderService} purchaseOrderService     
     * @param {InvoiceService} invoiceService
     * @memberof EngagementDetailService
     */
    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMAuthorizationService) private dmauthorizationservice: DMAuthorizationService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(InvoiceService) private invoiceService: InvoiceService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(MilestonesService) private milestonesService: MilestonesService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(dmLogger, Services.EngagementDetailService );
        this.lockedUserStatus = this.configurationService.getValue<string>("lockedUserStatusCode");
        this.loggedInUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        this.serviceTypeList = this.configurationService.getValue<IServiceTypeList[]>("serviceTypeList");
    }

    /**
     * Invalidates the store for the specifically selected engagement by dispatching the invalidate actions
     * for each of the items in the store.
     * This function should be called on internal refresh.
     * Invalidating the store means each ILoadableState item will return to its original state
     * such that loading and loaded are both false, error is an empty string, and the data is null.
     *
     * This function currently only calls for the engagement context and is still a WIP.
     *
     * @param {string} engagementId
     * @memberof EngagementDetailService
     */
    public invalidateStoreOnRefresh(engagementId: string): void {
        this.store.dispatch(new InvalidateEngagementDetails(engagementId));
        this.store.dispatch(new InvalidateInternalFinancialDetails(engagementId));
        this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
        this.store.dispatch(new InvalidateMilestones(engagementId));
        this.store.dispatch(new InvalidateManageSuppliers(engagementId));
        this.store.dispatch(new InvalidateInvoices(engagementId));       
        this.store.dispatch(new InvalidateChangeRequests());
        this.store.dispatch(new InvalidateResourceRequests(engagementId));
        this.store.dispatch(new InvalidateWbsDetails(engagementId));
    }   

    /**
     * Gets wbs details from api and appends all necessary fields along with it.
     *
     * @param {string} wbsId
     * @param {boolean} includeEngagements
     * @param {boolean} includeProjects
     * @param {boolean} includeServices
     * @param {boolean} includeTasks
     * @param {boolean} includeTeams
     * @returns {Promise<IWbsDetailsV2>}
     * @memberof EngagementDetailService
     */
    public getWbsFullDetailsV2(wbsId: string, includeEngagements: boolean, includeProjects: boolean, includeServices: boolean, includeTasks: boolean, includeTeams: boolean): Promise<IWbsDetailsV2Model> {
        return this.projectService.getWbsDetailsV2(wbsId, includeEngagements, includeProjects, includeServices, includeTasks, includeTeams)
            .then((response: IWbsDetailsV2) => {
                /* We want to take the API responses and format them into extended detail objects to return. */
                /* These extended objects include auth info, shared info across eng and proj, flattened info such as team data, and other derrived information */
                let engagementFullDetailsV2: IEngagementDetailsV2;
                let projectFullDetailsV2: IProjectDetailsV2;
                if (response.engagementData) {
                    engagementFullDetailsV2 = { ...response.engagementData }; /* Copy the API object into the new extended object we will manipulate */
                    if (engagementFullDetailsV2.projects) {
                        for (const index in engagementFullDetailsV2.projects) {
                            const project: IProjectDetailsV2 = this.createExtendedProject(engagementFullDetailsV2.projects[index], engagementFullDetailsV2, includeTeams);
                            if (!project) {
                                this.fxpRouteService.navigatetoSpecificState(RouteName.Unauthorized);
                                return;
                            }
                            engagementFullDetailsV2.projects[index] = project;
                            engagementFullDetailsV2.canViewEngagement = engagementFullDetailsV2.canViewEngagement || this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Engagement, OperationName.view, engagementFullDetailsV2, project);
                            engagementFullDetailsV2.canViewChronosLabor = this.dmauthorizationservice.hasPermissionV2(ResourceName.Chronos_Labor, OperationName.view, engagementFullDetailsV2, project);
                            engagementFullDetailsV2.canViewInvoice = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Invoice, OperationName.view, engagementFullDetailsV2, project);
                            engagementFullDetailsV2.canViewFinance = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Financials, OperationName.view, engagementFullDetailsV2, project);
                            engagementFullDetailsV2.canViewEBS = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_EBS, OperationName.view, engagementFullDetailsV2, project);
                            engagementFullDetailsV2.canViewStaffing = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Staffing, OperationName.view, engagementFullDetailsV2, project);
                        }
                    }
                    const canViewEngagement: boolean = engagementFullDetailsV2.canViewEngagement || this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Engagement, OperationName.view, engagementFullDetailsV2, undefined);

                    /* If the user does not have access to the engagement, reroute them and stop processing*/
                    /* We do this check after checking all the projects, because if a user has access to a project, they should already have access to the engagement,
                    but if the user does not have access to any projects, they still might have access to the engagement alone. */
                    if (!canViewEngagement) {
                        this.fxpRouteService.navigatetoSpecificState(RouteName.Unauthorized);
                        return;
                    }

                    if (includeTeams && engagementFullDetailsV2.teamStructure && engagementFullDetailsV2.teamStructure.length) {
                        engagementFullDetailsV2.pPjm = this.sharedFunctionsService.getPjmInfoL0("PPJM", engagementFullDetailsV2)[0];
                        const addlPpjmsL0 = this.sharedFunctionsService.getPjmInfoL0("ADPPJM", engagementFullDetailsV2);
                        engagementFullDetailsV2.adPPjm = addlPpjmsL0 && addlPpjmsL0.length ? addlPpjmsL0 : undefined;
                        engagementFullDetailsV2.delPPjm = this.sharedFunctionsService.getPjmInfoL0("DPPJM", engagementFullDetailsV2)[0];
                        engagementFullDetailsV2.relManager = this.sharedFunctionsService.getPjmInfoL0("RELMAN", engagementFullDetailsV2)[0];
                        engagementFullDetailsV2.dmmPPjm = this.sharedFunctionsService.getPjmInfoL0("DMM", engagementFullDetailsV2)[0];
                    }
                    engagementFullDetailsV2.canViewEngagement = canViewEngagement;
                    engagementFullDetailsV2.canEditEnagagement = this.dmauthorizationservice.hasEditPermissionV2(ResourceName.SAP_Engagement, OperationName.edit, engagementFullDetailsV2, null);
                    engagementFullDetailsV2.canRequestEbsStateChange = this.dmauthorizationservice.hasEditPermissionV2(ResourceName.SAP_Engagement, OperationName.edit, engagementFullDetailsV2, null);
                    engagementFullDetailsV2.isUsPubSec = this.sharedFunctionsService.verifyIsUsPubSec(engagementFullDetailsV2.publicSectorCode);
                    engagementFullDetailsV2.entityType = "engagement";
                    const childEntitiesMinMaxDates = this.sharedFunctionsService.getChildMinStartMaxEndDate(engagementFullDetailsV2);
                    if (childEntitiesMinMaxDates) {
                        engagementFullDetailsV2.childEntitiesMinStartDate = childEntitiesMinMaxDates.minStartDate ? childEntitiesMinMaxDates.minStartDate : undefined;
                        engagementFullDetailsV2.childEntitiesMaxEndDate = childEntitiesMinMaxDates.maxEndDate ? childEntitiesMinMaxDates.maxEndDate : undefined;
                    }
                } else if (response.projectData) {
                    /* Use an else if instead of an else or if because of we have the engagement object, then we only want to manipulate that level,
                    so we skip the project data. But if we don't have engagement object, then we want to focus on projects. Manipulating both objects
                    can just get a little messy downstream by not knowing which piece of data should be used. We ideally should not have multiple objects returned from the API anyways.*/
                    projectFullDetailsV2 = this.createExtendedProject(response.projectData, undefined, includeTeams);  /* Copy the API object into the new extended object we will manipulate */
                    if (!projectFullDetailsV2) {
                        /* If the user does not have access to the project or project is missing, function returns undefined. Stop processing and reroute.*/
                        this.fxpRouteService.navigatetoSpecificState("entry.unauthorized");
                    }

                }
                const extendedWbsDetails: IWbsDetailsV2Model = {
                    engagementData: engagementFullDetailsV2,
                    projectData: projectFullDetailsV2,
                    servicesData: response.servicesData, // todo may need to convert these to the new models
                    tasksData: response.tasksData
                };
                return extendedWbsDetails;

            }).catch((error) => { /* format the error */
                let returnMessage: string = "Unable to retrieve Engagement Details.";
                if (error.data) {
                    error = error.data;
                    if (error.InnerErrors && error.InnerErrors.length && error.InnerErrors[0].Messages && error.InnerErrors[0].Messages.length) {
                        returnMessage = error.InnerErrors[0].Messages[0];
                        if (returnMessage.indexOf("{\"Message\":") === 0) {
                            returnMessage = JSON.parse(error.InnerErrors[0].Messages[0]).Message;
                        }
                    } else if (error.Message) {
                        returnMessage = error.Message;
                    }
                }
                this.logError(SourceConstants.Method.GetWbsFullDetailsV2, error, returnMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                returnMessage += " Correlation ID: " + DataService.getCorrelationIdFromError(error);
                return Promise.reject(returnMessage);
            });
    }

    /**
     * Gets engagement details by pulling information off of the given engagement full details object
     *
     * @param {IEngagementDetails} engagementFullDetails
     * @returns {IEngagementViewModel}
     * @memberof EngagementDetailService
     */
    public getEngagementDetailsV2(engagementFullDetails: IEngagementDetailsApiV2): IEngagementViewModel {
        const pjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("PPJM", engagementFullDetails);
        let pjm: ITeamDetailsV2;
        if (pjmObj) {
            pjm = pjmObj[0];
        }

        const delegatedPjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("DPPJM", engagementFullDetails);
        let delegatedPjm: ITeamDetailsV2;
        if (delegatedPjmObj) {
            delegatedPjm = delegatedPjmObj[0];
        }

        const relationshipManagerObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("RELMAN", engagementFullDetails);
        let relationshipManager: ITeamDetailsV2;
        if (relationshipManagerObj) {
            relationshipManager = relationshipManagerObj[0];
        }

        return {
            name: engagementFullDetails.name,
            description: engagementFullDetails.description,
            customerName: engagementFullDetails.customerName,
            primaryDomain: engagementFullDetails.primaryDomain,
            pPjMAlias: pjm ? pjm.alias : undefined,
            pPjMName: pjm ? pjm.name : undefined,
            pjMAlias: pjm ? pjm.alias : undefined,
            pjMName: pjm ? pjm.name : undefined, // todo flatten this we dont need both pjm and ppjm
            pPjMbpId: pjm ? pjm.bpid : undefined,
            typeOfContract: this.sharedFunctionsService.getContractType(engagementFullDetails.projects),
            startDate: engagementFullDetails.startDate,
            endDate: engagementFullDetails.endDate,
            engagementId: engagementFullDetails.id,
            dealId: engagementFullDetails.dealId,
            status: engagementFullDetails.statusDescription,
            isConfidentialDeal: engagementFullDetails.isConfidential,
            hasAssociatedEngagements: engagementFullDetails.hasAssociatedEngagements,
            opportunityId: engagementFullDetails.opportunityId,
            isPublicSector: engagementFullDetails.isPublicSector,
            isUsPubSec: this.sharedFunctionsService.verifyIsUsPubSec(engagementFullDetails.publicSectorCode),
            relationshipManagerAlias: relationshipManager ? relationshipManager.alias : undefined,
            relationshipManagerName: relationshipManager ? relationshipManager.name : undefined,
            delegatedPPjMbpId: delegatedPjm ? delegatedPjm.bpid : undefined,
            delegatedPPjMName: delegatedPjm ? delegatedPjm.name : undefined,
            currentStatus: engagementFullDetails.currentStatus,
            currentStatusCode: engagementFullDetails.currentStatusCode
        };
    }

    /**
     * Gets the project info for a given project ID by pulling the information out of the component's engagement list
     *
     * @private
     * @param {string} projectId
     * @returns {IProjectList}
     * @memberof GridDataComponent
     */
    public getProjectFromEngagementList(projectId: string, engagementList: IEngagementList[]): IProjectList {
        let projectInfo: IProjectList;
        const engagementId = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
        if (engagementList && engagementList.length) {
            const engagementInfo = engagementList.filter((engagement: IEngagementList) => engagement.engagementId === engagementId);
            if (engagementInfo && engagementInfo.length && engagementInfo[0].projects) {
                const filter: IProjectList[] = engagementInfo[0].projects.filter((project: IProjectList) => project.projectId === projectId);
                if (filter.length) {
                    projectInfo = filter[0];
                }
            }
        }
        return projectInfo;
    }

    /**
     * Load financial details for the selected engagement or project via the Project Service API
     *
     * @param {string} engagementOrProjectId
     * @returns {Promise<IEngagementFinancial>}
     * @memberof EngagementDetailService
     */
    public getEntityFinancialDetails(engagementOrProjectId: string): Promise<IEngagementFinancial> {
        const failureMessage: string = NoDataText.NoFinancialAvailableText;

        return this.projectService.getFinancialsByEntityId(engagementOrProjectId, true, true)
            .then((response: IEngagementFinancial) => {
                if (response.engagementFinancials.length < 1) {
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    return {
                        engagementFinancials: [],
                        errorMessage: failureMessage,
                    };
                } else {
                    return {
                        engagementFinancials: response.engagementFinancials,
                        errorMessage: undefined
                    };
                }
            }).catch((error) => {
                let errorText: string;
                if (error && error.status === 404 && error.data && error.data.InnerErrors) {
                    for (const innerError of error.data.InnerErrors) {
                        if (innerError.Messages) {
                            for (const message of innerError.Messages) {
                                /* Stacks the error messages on top of each other in case of multiple errors. */
                                errorText = message + errorText;
                            }
                        }
                    }
                    /* error text for this log is a stack trace */
                    this.logError(SourceConstants.Method.GetEntityFinancialDetails, error, errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                } else if (error.messages) { /* If the error had a message, process the message and pass it forward. */
                    if (Array.isArray(error.messages)) {
                        errorText = error.messages[0];
                        const messageRegex = /^(For|for) .*, /;
                        /* For an array of messages, we stack the messages on top of each other,
                        but we pre-process them to remove the (expected) duplicate text */
                        for (let i = 1; i < error.messages.length; i++) {
                            if (messageRegex.test(error.messages[i])) { /* Removes prefixes like "For C.1234567890, ..." */
                                const cutIndex: number = error.messages[i].indexOf(",") + 2;
                                errorText = errorText + "; " + error.messages[i].substr(cutIndex, error.messages[i].length);
                            } else { /* If we don't find expected duplicated text, just stack the messages as-is */
                                errorText = errorText + "; " + error.messages[i];
                            }
                        }
                        /* error text for this log is a stack trace */
                        this.logError(SourceConstants.Method.GetEntityFinancialDetails, error, errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    } else {
                        /* If the error message is a string, return the message. */
                        errorText = error.message;
                        this.logError(SourceConstants.Method.GetEntityFinancialDetails, error, errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    }
                } else { /* If there is no error message, use the default. */
                    errorText = NoDataText.NoFinancialDataLoadedText;
                    this.logError(SourceConstants.Method.GetEntityFinancialDetails, error, errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                }
                errorText += " Correlation ID: " + DataService.getCorrelationIdFromError(error);
                return Promise.resolve({
                    engagementFinancials: [],
                    errorMessage: errorText,
                });
            });
    }

    /**
     * Gets the list of Purchase Order View Model objects, given a list of Purchase Orders (such as the response from the Purchase Orders API.)
     * Uses and formats only the necessary information for the view model to create this object, rather than having to use the entire PO list from the response.
     * @param purchaseOrders
     */
    public getPurchaseOrderViewModel(purchaseOrders: IPurchaseOrder[]): IPoListViewModel[] {
        const purchaseOrdersViewModel: IPoListViewModel[] = [];
        if (purchaseOrders) {
            for (const po of purchaseOrders) {
                purchaseOrdersViewModel.push({
                    poNumber: po.poNumber,
                    poTitle: po.description,
                    supplier: po.vendorName,
                    status: this.getPoStatus(po.status),
                    companyCode: po.companyCode,
                    tmConsumption: this.getConsumption(po, "T&M"),
                    expConsumption: this.getConsumption(po, "EXP"),
                    ffConsumption: this.getConsumption(po, "FFS"),
                    matConsumption: this.getConsumption(po, "MAT"),
                    poConsumption: this.getPOConsumption(po.lineItems, po.poTotalAmount),
                });
            }
        }
        return purchaseOrdersViewModel;
    }

    /**
     * Load milestone data for the selected WBS and Logged in user via the Milestone Service API
     *
     * @param {string} entityId
     * @param {string} loggedInUserBPID
     * @returns {Promise<any>}
     * @memberof EngagementDetailService
     */
    public getMilestoneData(engagementId: string): Promise<IBillingMilestoneModel[]> {
        const parameter: IGetBillingMileStoneListApiParameterModel = {
            WbsId: engagementId,
            BPId: this.loggedInUserBPID.toString(),
        };
        const milestonesPromise = this.milestonesService.getMilestoneListByEngagementId(parameter);
        return milestonesPromise;
    }


    /**
     * Gets invoices by calling the Sales Order API. Then formats the invoice response with the information our UI needs.
     *
     * @param {IEngagementDetails} engagementDetails
     * @param {string} navigationType
     * @returns {Promise<IInvoiceItemModel[]>}
     * @memberof EngagementDetailService
     */
    public getInvoices(engagementDetails: IEngagementDetailsApiV2): Promise<IInvoiceItemModel[]> {
        const invoiceList: IInvoiceItemModel[] = [];
        return this.invoiceService.getInvoicesDataByEngagementID(engagementDetails.id, this.loggedInUserBPID)
            .then((invoiceData: IInvoiceItemDataModel[]) => {
                for (const invoice of invoiceData) {
                    const invoiceItem: IInvoiceItemModel = {
                        approveDate: invoice.approvedDate,
                        contractAmount: invoice.contractAmount,
                        currencySymbol: invoice.currency,
                        documentType: invoice.documentType,
                        endDate: invoice.endDate,
                        invoiceNumber: invoice.number,
                        invoiceComments: invoice.comment,
                        invoiceNumberLink: invoice.url,
                        invoiceTotal: invoice.amount,
                        paidDate: invoice.paidDate,
                        project: [],
                        referenceNumber: invoice.referenceNumber,
                        startDate: invoice.startDate,
                        status: invoice.status,
                        timestamp: invoice.timestamp ? new Date(invoice.timestamp) : undefined,
                    };

                    if (invoice.wbsL1.indexOf(",") > -1) {
                        const projectIds: string[] = invoice.wbsL1.toLowerCase().split(",");

                        if (engagementDetails.projects) {
                            for (const project of engagementDetails.projects) {
                                if (projectIds.indexOf(project.id.toLowerCase()) > -1) {
                                    const projDet: IProjectDataToFilter = {
                                        projectId: project.id,
                                        projectName: project.name,
                                    };
                                    invoiceItem.project.push(projDet);
                                }
                            }
                        }
                    } else {
                        const projectId = invoice.wbsL1;

                        //  invoice.wbsL1 is always coming in blank from sales order management api, so nothing will ever match up. Currently WIP
                        // Seems like invoice.wbsL1 comes as a comma seperated list of project IDs with C., can't confirm if there is a scenario if only 1 project id is returned
                        if (engagementDetails.projects) {
                            for (const project of engagementDetails.projects) {
                                if (projectId.toLowerCase() === project.id.toLowerCase()) {
                                    const projDet: IProjectDataToFilter = {
                                        projectId: project.id,
                                        projectName: project.name,
                                    };
                                    invoiceItem.project.push(projDet);
                                }
                            }
                        }
                    }
                    invoiceList.push(invoiceItem);
                }
                return invoiceList;
            });

    }

    /**
     * Gets consumption value for a given purchase order and its type
     *
     * @private
     * @param {IPurchaseOrder} po
     * @param {string} type
     * @returns {number}
     * @memberof EngagementDetailService
     */
    public getConsumption(po: IPurchaseOrder, type: string): number {
        const filterItem = po.lineItems.filter((item) => item.type === type);
        if (filterItem.length <= 0) {
            return null;
        }

        // For MAT and T&M => Delivered Quanity/Ordered Quanitty * 100
        if (type === "MAT" || type === "T&M") {
            let tdqty = 0;
            let tqty = 0;
            for (const item of filterItem) {
                tdqty = tdqty + (item.deliveredQuantity != null ? Number(item.deliveredQuantity.toFixed(2)) : 2);
                tqty = tqty + (item.quantity != null ? Number(item.quantity.toFixed(2)) : 2);
            }
            return (tdqty / tqty) * 100;
        }

        // For Fixed Fee => Total Delivered quantity
        if (type === "FFS") {
            let tdqty = 0;
            for (const item of filterItem) {
                tdqty = tdqty + (item.deliveredQuantity != null ? Number(item.deliveredQuantity.toFixed(2)) : 2);
            }
            return tdqty / filterItem.length;
        }

        // For Expense => Cost complete/Ordered Amount * 100
        if (type === "EXP") {
            let tcqty = 0;
            let tamount = 0;
            for (const item of filterItem) {
                tcqty = tcqty + (item.costComplete != null ? Number(item.costComplete.toFixed(2)) : 2);
                tamount = tamount + (item.orderedAmount != null ? Number(item.orderedAmount.toFixed(2)) : 2);
            }
            return (tcqty / tamount) * 100;
        }
    }

    /**
     * Gets Purchase Order consumption value for a collection of PO Line items and the total consumption value
     *
     * @param {ILineItem[]} lineItems
     * @param {number} totalConsumption
     * @returns {number}
     * @memberof EngagementDetailService
     */
    public getPOConsumption(lineItems: ILineItem[], totalConsumption: number): number {
        if (totalConsumption <= 0) {
            return 0;
        }
        let lineItemTotal: number = 0;
        for (const item of lineItems) {
            lineItemTotal += item.costComplete !== null ? Number(item.costComplete.toFixed(2)) : 0;
        }
        return Number(((lineItemTotal / totalConsumption) * 100).toFixed(2));
    }

    /**
     * Gets the Purchase Order status from the Purchase Order and rebrands it for the UI
     *
     * @private
     * @param {string} poStatus
     * @returns {string}
     * @memberof EngagementDetailService
     */
    public getPoStatus(poStatus: string): string {
        if (poStatus && poStatus.toLowerCase() === "open") {
            // todo enum
            poStatus = "Inprocess";
        } else if (poStatus && poStatus.toLowerCase() === "blocked") {
            poStatus = "Closed";
        }
        return poStatus;
    }

    /**
     * Gets the type color code object for the given contract type list and the type of contract.
     * Filters the config file to grab the right object. If no match is found, it returns a color of black for styling.
     *
     * @param {IContractType[]} contractTypeList
     * @param {string} typeOfContract
     * @returns {ITypeColorCode}
     * @memberof EngagementDetailService
     */
    public getTypeColorCode(contractTypeList: IContractType[], typeOfContract: string): ITypeColorCode {
        let typeColorCode: ITypeColorCode = { color: "black" };
        if (typeOfContract && contractTypeList) {
            const filterContract = contractTypeList.filter((item) => item.typeShortForm === typeOfContract)[0];
            if (filterContract) {
                typeColorCode = filterContract.typeColorCode;
            }
        }
        return typeColorCode;
    }

    /**
     * Take the projectAPI response and engagementAPI response and create the extended project details object.
     * Returns undefined if project is missing or if the user does not have permission to view the project.
     */
    private createExtendedProject(projectAPIResponse: IProjectDetailsApiV2, engagementAPIResponse: IEngagementDetailsApiV2, includeTeams: boolean): IProjectDetailsV2 {
        if (!projectAPIResponse) { /* Project was missing, something is wrong */
            return undefined;
        }
        const canViewProject: boolean = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Project, OperationName.view, engagementAPIResponse, projectAPIResponse);
        /* If the user does not have access to the project return undefined and reroute upstream */
        if (!canViewProject) {
            return undefined;
        }

        const project: IProjectDetailsV2 = { ...projectAPIResponse };

        /* Apply the team structure to a flat object for convenience */
        if (includeTeams && project.teamStructure && project.teamStructure.length) {
            project.pjm = this.sharedFunctionsService.getPjmInfoL1("PJM", project)[0];
            project.delPjm = this.sharedFunctionsService.getPjmInfoL1("DPJM", project)[0];
            project.delPPjm = this.sharedFunctionsService.getPjmInfoL1("DPPJM", project)[0];
            const addlPjmsL1 = this.sharedFunctionsService.getPjmInfoL1("ADPJM", project);
            project.adPjm = addlPjmsL1 && addlPjmsL1.length ? addlPjmsL1 : undefined;
            const uSubmitterL1 = this.sharedFunctionsService.getPjmInfoL1("ZUNI", project);
            project.uSubmitter = uSubmitterL1 && uSubmitterL1.length ? uSubmitterL1 : undefined;
            project.pPjm = this.sharedFunctionsService.getPjmInfoL1("PPJM", project)[0];
            project.dmmPjm = this.sharedFunctionsService.getPjmInfoL1("DMM", project)[0];
        }
        /* Set the service type description if it matches the task type */
        for (const service of project.services) {
            let serviceTypeDesc: string;
            if (service.tasks && service.tasks.length) {
                for (const value of this.serviceTypeList) {
                    if (value.Code === service.tasks[0].workPackageType) {
                        serviceTypeDesc = value.Name;
                    }
                }
            }
            service.serviceType = serviceTypeDesc;
            if (service.description.includes("Product Fulfillment Dates")){
                service.serviceType = "NuanceServiceType";
            }
        }

        /* Set all model-based pieces of information that do not come directly from the PMS API */
        project.entityType = "project"; // not clear why we even need this?
        project.isConfidentialDeal = engagementAPIResponse.isConfidential;
        project.isMarkedForDeletion = projectAPIResponse.userStatusCode && projectAPIResponse.userStatusCode.toUpperCase().includes("MDL") ? true : false;
        project.pubSecCode = engagementAPIResponse.publicSectorCode;
        project.isUsPubSec = this.sharedFunctionsService.verifyIsUsPubSec(engagementAPIResponse.publicSectorCode);
        project.canViewProject = canViewProject;
        project.canViewChronosLabor = this.dmauthorizationservice.hasPermissionV2(ResourceName.Chronos_Labor, OperationName.view, engagementAPIResponse, project);
        project.canViewInvoice = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Invoice, OperationName.view, engagementAPIResponse, project);
        project.canViewFinance = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Financials, OperationName.view, engagementAPIResponse, project);
        project.canViewEBS = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_EBS, OperationName.view, engagementAPIResponse, project);
        project.canViewStaffing = this.dmauthorizationservice.hasPermissionV2(ResourceName.SAP_Staffing, OperationName.view, engagementAPIResponse, project);
        project.canEditProject = this.dmauthorizationservice.hasEditPermissionV2(ResourceName.SAP_Project, OperationName.edit, null, project)
                                  || this.dmauthorizationservice.hasEditPermissionV2(ResourceName.SAP_Project, OperationName.edit, engagementAPIResponse, null);
        // this was being deprecated
        project.canRequestEbsStateChange = this.dmauthorizationservice.hasEditPermissionV2(ResourceName.SAP_Project, OperationName.edit, null, project)
                                  || this.dmauthorizationservice.hasEditPermissionV2(ResourceName.SAP_Project, OperationName.edit, engagementAPIResponse, null);
        return project;
    }
}
