import { Component, Inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BulkUpdateScheduleOptions, Components } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IBulkScheduleUpdateResult, ISchedule } from "../../../../components/financial-mgmt/financial.model";

@Component({
    templateUrl: "./bulkupdate-schedules.html",
    styleUrls: ["./bulkupdate-schedules.scss"]
})
export class BulkUpdateSchedulesModalComponent extends DmModalAbstract {
    @Input() public forecastSchedules: ISchedule[];
    @Input() public etcHours: number;
    public modalContent: IModal;
    public weekStartDate: Date;
    public weekEndDate: Date;
    public hours: number;
    public bulkScheduleUpdateOptionSelected: string;
    public bulkUpdateScheduleOptions = BulkUpdateScheduleOptions;
    public bulkScheduleUpdateResult: IBulkScheduleUpdateResult;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(activeModal, dmLogger, Components.BulkUpdateSchedulesModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Bulk Update Schedules"
        };
        this.weekStartDate = this.forecastSchedules[0].startDate;
        this.weekEndDate = this.forecastSchedules[this.forecastSchedules.length - 1].endDate;
        this.hours = this.etcHours;
        this.bulkScheduleUpdateOptionSelected = BulkUpdateScheduleOptions.EvenlySpreadAcrossDuration;
    }

    public onBulkUpdateScheduleOptions(value: string): void {
        this.bulkScheduleUpdateOptionSelected = value;
    }

    public bulkUpdateDetails(): void {
        this.bulkScheduleUpdateResult = {
            weekStarting: this.weekStartDate,
            weekEnding: this.weekEndDate,
            bulkScheduleUpdateOption: this.bulkScheduleUpdateOptionSelected,
            hours: this.hours
        };
        this.activeModal.close(this.bulkScheduleUpdateResult);
    }
}
