import { Component, forwardRef, Inject } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/core";

import { Components } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-internal-engagement-financial",
    templateUrl: "./internal-engagement-financial.html",
    styleUrls: ["./internal-engagement-financial.scss"]
})
export class InternalEngagementFinancialComponent extends DmComponentAbstract {

    public engagementId: string;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService
    ) {
        super(dmLogger, Components.InternalEngagementFinancial,
            [
                { component: Components.IntEngCostConsumption, isCritical: true },
                { component: Components.ManageWBSInternalEngagement, isCritical: true },
                { component: Components.CostBreakdown, isCritical: true }
            ]);
    }

    public ngOnInit(): void {
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
    }

}
