<div class="contacts-list" *ngIf="contactsList?.length" [ngClass]="{'contacts-list-v2': isV2Tile}">
    <div class="contact" *ngFor="let contact of contactsList">
        <div *ngIf="contact?.image">
            <img [src]="contact?.image" class="contact-image" />
        </div>
        <div *ngIf="!contact?.image && showUserIconBadge" class="badge">
            <i class="icon icon-user"></i>
        </div>
        <div class="contact__details" *ngIf="!isV2Tile">
            <div class="contact__details-name">
                <dm-user-name *ngIf="contact?.alias" [name]="contact?.name" [alias]="contact?.alias"
                    [showUserNameAndAlias]="true" [shouldDisplayChatIcon]="false"></dm-user-name>
                <dm-user-name *ngIf="!contact?.alias" [name]="contact?.name" [alias]="contact?.alias"></dm-user-name>
            </div>
            <div>{{contact?.company}}</div>
            <ng-container *ngFor="let role of contact?.roles">
                <span>{{role?.contactType}}</span>
            </ng-container>
            <span *ngIf="contact?.role">{{contact?.role}}</span>
        </div>
        <div *ngIf="isV2Tile" class="contact__details contact__details-v2">
            <dm-user-name [name]="contact?.name" [alias]="contact?.alias" [role]="contact?.role"
                [showPersonCardWithViewAvatar]="true">
            </dm-user-name>
            <div>        
                <div class="contact__details-name">
                    <dm-user-name *ngIf="contact?.alias" [name]="contact?.name" [alias]="contact?.alias"
                        [showUserNameAndAlias]="true" [shouldDisplayChatIcon]="false"></dm-user-name>
                    <dm-user-name *ngIf="!contact?.alias" [name]="contact?.name" [alias]="contact?.alias"></dm-user-name>
                </div>
                <div>{{contact?.company}}</div>
                <ng-container *ngFor="let role of contact?.roles">
                    <div>{{role?.contactType}}</div>
                </ng-container>
                <div *ngIf="contact?.role">{{contact?.role}}</div>
            </div>
        </div>
        <div class="contact__actions-row" *ngIf="showContactActions" [ngClass]="{'display-flex': isV2Tile}">
            <a *ngIf="contact?.telephone" tabindex="0" [attr.aria-label]="'Call ' + contact?.name + ' ' + contact?.telephone" [title]="'Call ' + contact?.name + ' ' + contact?.telephone" role="link" [href]="'tel:' + contact?.telephone"
              class="icon icon-phone"></a>
            <div *ngIf="!contact?.telephone" title="Phone icon" class="icon icon-phone">
                <span class="sr-only">phone icon</span>
            </div>
            <a *ngIf="contact?.email" [title]="contact?.email" [href]="'mailto:' + contact?.email"
                class="icon icon-email"  tabindex="0" [attr.aria-label]="contact?.email" role="link"></a>
            <div *ngIf="!contact?.email" title="email icon" class="icon icon-email">
                <span class="sr-only">email icon</span>
            </div>
        </div>
    </div>
</div>
<dm-no-data *ngIf="!contactsList?.length" [noDataText]="noContactText" [showImage]="true"></dm-no-data>