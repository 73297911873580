<label class="sr-only" aria-labelledby="addTaskHeading"></label>
<form name="addtask" #addtask="ngForm">
    <div class="popUpModalDialog">
        <button *ngIf="deviceFactory.isMobile()" type="button" id="closeRejectButton" class="set-focus close mar-6"
            data-dismiss="modal" aria-label="Add Task Dialog Close" (click)="closeModal()">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <div class="modal-header reject-popUp-noBorder">
            <h4 class="modal-title pull-left" id="addTaskHeading">
                Add Task
            </h4>
            <button *ngIf="!deviceFactory.isMobile()" type="button" id="accessibilityConstants.CloseRejectButton" class="set-focus close"
                data-dismiss="modal" aria-label="Add Task Dialog Close"
                (keydown)="moveFocusPrev($event, 'accessibilityConstants.Submit')" (click)="closeModal()">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </div>
        <dm-loading [showLoading]="true" *ngIf="isComponentLoading">
        </dm-loading>
        <section class="modal-body" *ngIf="!isComponentLoading">
            <section class="modal__input--text">
                <label for="wbsL3Name">Name</label>
                <input [(ngModel)]="wbsL3Name" type="text" name="wbsL3Name" #wbsL3NameForm="ngModel" id="wbsL3Name"
                    class="set-focus" maxlength="40" required
                    [ngClass]="{'inp-error-border' : wbsL3NameForm.touched && wbsL3NameForm.invalid}"
                    placeholder="Please name this Task."
                    [attr.aria-label]="'Please name this Task: ' + (40-wbsL3Name.length) + ' of 40 characters remaining'">
                <div class="row">
                    <p class="remaining-char font-caption col-sm-6 col-md-6 col-xs-7" aria-hidden="true">
                        {{40 - wbsL3Name.length}}/40 characters remaining
                    </p>
                    <span class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                        *ngIf="wbsL3NameForm.touched && wbsL3NameForm.invalid">
                        {{addTaskErrorMessages.TaskNameRequired}}
                    </span>
                </div>
            </section>
            <section class="modal__input--text">
                <label for="wbsl3Description">Description</label>
                <textarea class="set-focus" [(ngModel)]="wbsl3Description" name="wbsl3Description"
                    #wbsl3DescriptionForm="ngModel" id="wbsl3Description" maxlength="250" required
                    [ngClass]="{'inp-error-border' : wbsl3DescriptionForm.touched && wbsl3DescriptionForm.invalid}"
                    [attr.aria-label]="'Description Required: ' + (250 - wbsl3Description.length) + ' of 250 characters remaining ' + wbsl3Description"></textarea>
                <div class="row">
                    <p class="remaining-char font-caption col-sm-6 col-md-6 col-xs-7" aria-hidden="true">
                        {{250 - wbsl3Description.length}}/250 characters remaining
                    </p>
                    <span class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                        *ngIf="wbsl3DescriptionForm.touched && wbsl3DescriptionForm.invalid">
                        {{addTaskErrorMessages.DescriptionIsRequired}}
                    </span>
                </div>
            </section>
            <section class="col-md-12 col-sm-12 pad0 modal__input--calendar" *ngIf="!deviceFactory.isMobile()">
                <section class="col-md-6 col-sm-6 editCal pad0 rightpad">
                    <label for="startDate">Start Date</label>
                    <p class="input-group"
                        [ngClass]="{'inp-error-border' : isStartDateRequired || isBeforeServiceStartDate}">
                        <dm-datepicker [minDate]="minDate" [maxDate]="maxDate" [modelDate]="taskStartDate"
                            ariaLabelButton="Start date" ariaLabelCalendar="Start date"
                            (onDateChange)="isTaskStartDateValid($event)" previosFousingElementId="wbsl3Description"></dm-datepicker>
                    </p>
                    <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                        *ngIf="isStartDateRequired && deviceFactory.isDesktop()">
                        {{addTaskErrorMessages.StartDateIsRequired}}
                    </span>
                    <span class="font-caption-alt inp-validation errorMsg" *ngIf="isStartDateRequired">
                        {{addTaskErrorMessages.StartDateIsRequired}}
                    </span>
                    <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeServiceStartDate && deviceFactory.isDesktop()">
                        {{addTaskErrorMessages.TaskStartDateShouldBeWithinServiceStartDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isBeforeServiceStartDate">
                        {{addTaskErrorMessages.TaskStartDateShouldBeWithinServiceStartDate}}
                    </span>
                </section>
                <section class="col-md-6 col-sm-6 editCal pad0 leftpad">
                    <label for="endDate">End Date</label>
                    <p class="input-group"
                        [ngClass]="{'inp-error-border' : isEndDateRequired || isDateCompare || isAfterServiceEndDate}">
                        <dm-datepicker [minDate]="minDate" [maxDate]="maxDate" [modelDate]="taskEndDate"
                            ariaLabelButton="End date" ariaLabelCalendar="End date"
                            (onDateChange)="isTaskEndDateValid($event)" previosFousingElementId="calenderBtn_Start date"></dm-datepicker>
                    </p>
                    <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                        *ngIf="isEndDateRequired && deviceFactory.isDesktop()">
                        {{addTaskErrorMessages.EndDateRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isEndDateRequired">
                        {{addTaskErrorMessages.EndDateRequired}}
                    </span>
                    <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                        *ngIf="isDateCompare && deviceFactory.isDesktop()">
                        {{addTaskErrorMessages.EndDateCannotBeEarlierThanTheStartDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isDateCompare">
                        {{addTaskErrorMessages.EndDateCannotBeEarlierThanTheStartDate}}
                    </span>
                    <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterServiceEndDate && deviceFactory.isDesktop()">
                        {{addTaskErrorMessages.TaskEndDateMustBeBeforeTheServiceEndDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isAfterServiceEndDate">
                        {{addTaskErrorMessages.TaskEndDateMustBeBeforeTheServiceEndDate}}
                    </span>
                </section>
            </section>
            <section class="col-xs-12 pad0 modal__input--calendar modal__input--calendar-mobile"
                *ngIf="deviceFactory.isMobile()">
                <section class="col-xs-12 editCal pad0">
                    <label for="startDate">Start Date</label>
                    <p class="input-group"
                        [ngClass]="{'inp-error-border' : isStartDateRequired || isBeforeServiceStartDate}">
                        <dm-datepicker [minDate]="minDate" [maxDate]="maxDate" [modelDate]="taskStartDate"
                            ariaLabelButton="Start date" ariaLabelCalendar="Start date"
                            (onDateChange)="isTaskStartDateValid($event)" previosFousingElementId="wbsl3Description"></dm-datepicker>
                    </p>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isStartDateRequired">
                        {{addTaskErrorMessages.StartDateIsRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isBeforeServiceStartDate">
                        {{addTaskErrorMessages.TaskStartDateMustBeAfterTheServiceStartDate}}
                    </span>
                </section>
                <section class="col-xs-12 editCal p-t-24">
                    <label for="endDate">End Date</label>
                    <p class="input-group"
                        [ngClass]="{'inp-error-border' : isEndDateRequired || isDateCompare || isAfterServiceEndDate}">
                        <dm-datepicker [minDate]="minDate" [maxDate]="maxDate" [modelDate]="taskEndDate"
                            ariaLabelButton="End date" ariaLabelCalendar="End date"
                            (onDateChange)="isTaskEndDateValid($event)" previosFousingElementId="calenderBtn_Start date"></dm-datepicker>
                    </p>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isEndDateRequired">
                        {{addTaskErrorMessages.EndDateRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="isDateCompare">
                        {{addTaskErrorMessages.EndDateCannotBeEarlierThanTheStartDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                        aria-live="polite" *ngIf="!isAfterServiceEndDate">
                        {{addTaskErrorMessages.TaskEndDateMustBeAfterTheServiceEndDate}}
                    </span>
                </section>
            </section>
            <section *ngIf="!isInternal">
                <div class="font-caption-alt">
                    To be able to view this task in the Current Finanical Plan (under the Plan and Forecast tab) you
                    must add a role in the CFP associated to the EBS item.
                </div>
            </section>
        </section>
    </div>
    <ng-container *ngIf="!isComponentLoading">
        <div class="modal-footer reject-popUp-noBorder" *ngIf="!deviceFactory.isMobile()">
            <button type="button" class="fxp-btn fxp-btn--secondary" id="accessibilityConstants.Cancel"
                (click)="closeModal()" (keydown)="moveFocusNext($event, 'accessibilityConstants.Submit')">
                Cancel
            </button>
            <button type="button" id="accessibilityConstants.Submit" class="fxp-btn fxp-btn--primary"
                (click)="addNewTask()" [disabled]="saveButtonDisabled(addtask.invalid)"
                (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseRejectButton')">
                Save
            </button>
        </div>
        <div class="modal-footer reject-popUp-noBorder" *ngIf="deviceFactory.isMobile()">
            <button type="button" id="addTaskSubmit" class="fxp-btn fxp-btn--primary"
                (click)="addNewTask()" [disabled]="saveButtonDisabled(addtask.invalid)">
                Save
            </button>
            <button type="button" class="fxp-btn fxp-btn--secondary"
                (click)="closeModal()">
                Cancel
            </button>
        </div>
    </ng-container>
</form>