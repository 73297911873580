<section class="fcr-units-form">
    <dm-loading [loadingText]="loadingMessage" [isCustomText]="true" [showLoading]="!fcrUnitsFormControlData"
        *ngIf="!fcrUnitsFormControlData">
    </dm-loading>
    <ng-container *ngIf="fcrUnitsFormControlDataError && !fcrUnitsFormControlData">
        <dm-service-error class="server-error" *ngIf="fcrUnitsFormControlDataError"
            [dataText]="fcrUnitsFormControlDataError" [showReloadMessage]="false"
            [ngbTooltipContent]="fcrUnitsFormControlDataError">
        </dm-service-error>
    </ng-container>
    <ng-container *ngIf="fcrUnitsFormControlData">
        <section class="justify-space-between">
            <section *ngIf="isEditable" class="dm-btn-holder dm-p-y-10">
                <button type="button" class="fxp-btn fxp-btn--primary dm-btn dm-btn--big" id="addNewUnits"
                    (click)="addNewUnits()">
                    <i class="icon icon-AddLegacy"></i>
                    <span>Add new units </span>
                </button>
                <button class="fxp-btn fxp-btn--primary dm-btn dm-btn--big" id="adjustExistingUnits" type="button"
                    (click)="adjustExisting()">
                    <i class="icon icon-AddLegacy"></i>
                    <span>Adjust existing units </span>
                </button>
            </section>
            <section class="pull-right display-grid">
                <div class="font-caption-alt" style="font-style: italic;">Auto Populated Role<span
                        class="color-legend auto-populated-role"></span>
                </div>
                <div class="font-caption-alt" style="font-style: italic;">Manually Added Role<span
                        class="color-legend manually-added-role"></span>
                </div>
                <div class="font-caption-alt" style="font-style: italic;">Plan & Forecast Edited Role<span
                        class="color-legend pnf-edit-role"></span>
                </div>
            </section>
        </section>
        <div *ngIf="isEditable">
            <span style="font-style: italic;">
                <ng-container *ngIf="getExpiredProjects().size > 0">
                    <span class="required-mark" aria-hidden="true">*</span>
                    FCRs cannot be raised for the projects with past end dates, in case you want to extend the end date
                    <span (click)="onEditProjectEndDate()"
                        style="cursor: pointer; text-decoration: underline; color: blue;">
                        click here
                    </span>
                    .
                </ng-container>
            </span>
        </div>
        <div class="fcr-units-form-table__grid" role="grid">
            <div id="requestedUnitsCaption" class="sr-only">Requested units </div>
            <div role="row" class="fcr-units-form-table__grid-row" aria-labelledby="requestedUnitsCaption">
                <div role="gridcell" class="fcr-units-form-table__grid-identifier-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">EBS Description / ID
                    <span class="required-mark"  aria-hidden="true">*</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">Unit Role
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">
                    Type of Contract
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">Billing Role
                    ID<span class="required-mark"  aria-hidden="true">*</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">
                    Existing CFP Units
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell text-align--right">
                    Additional Units
                    <span>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'" [ngbTooltipContent]="'Enter additional units'"
                            [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Enter additional units'">
                        </dm-tooltip>
                        <span class="required-mark dm-p-l-5" aria-hidden="true">*</span>
                    </span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">
                    DB Cost & Units
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="' Existing Delivery Baseline (DB) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="' Existing Delivery Baseline (DB) Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">
                    CFP Cost & Units
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan (CFP) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan (CFP) Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">
                    Unit Cost Rate ({{fcrUnitsFormControlData?.planCurrency}}/ea)
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell">
                    Additional Units Cost ({{fcrUnitsFormControlData?.planCurrency}})
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'"
                        [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-header-cell" *ngIf="isEditable">
                    Actions
                </div>
            </div>
            <ng-container *ngFor="let unitsLine of value; trackBy: trackByFn; let i = index"
                class="ver-al-top fcr-units-form-table">
                <div role="row" *ngIf="unitsLine" class="fcr-units-form-table__grid-row text-size"
                    [ngClass]="{'bg-clr':unitsLine.editModeEnabled}">
                    <div role="gridcell" class="fcr-units-form-table__grid-identifier-cell">
                        <section class="identifier-highlight"
                            [ngClass]="{ 'auto-populated-role': unitsLine?.isAutoPopulated && !unitsLine?.isPnfEdit, 'manually-added-role': !unitsLine?.isAutoPopulated, 'pnf-edit-role': unitsLine?.isAutoPopulated && unitsLine?.isPnfEdit }">
                        </section>
                    </div>

                    <!--WBS-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section *ngIf="!unitsLine?.editModeEnabled">
                            <ng-container *ngIf="unitsLine.currentState?.assignedTask">
                                <span class="font-weight-600">{{unitsLine.currentState?.assignedTask?.projectName + '
                                    - '
                                    +
                                    unitsLine.currentState?.assignedTask?.serviceName}}</span>
                                <br>
                                <span class="color-07"
                                    [textContent]="unitsLine.currentState?.assignedTask?.wbsl3Id"></span>
                            </ng-container>
                        </section>
                        <section
                            *ngIf="unitsLine.editModeEnabled && !unitsLine.isNewRequest  && unitsLine.currentState.existingResource">
                            <ng-container *ngIf="unitsLine.currentState?.assignedTask">
                                <span class="font-weight-500">{{unitsLine.currentState?.assignedTask?.projectName + ' -
                                    '
                                    +
                                    unitsLine.currentState?.assignedTask?.serviceName}}</span>
                                <br>
                                <span class="color-07"
                                    [textContent]="unitsLine.currentState?.assignedTask?.wbsl3Id"></span>
                            </ng-container>
                        </section>
                        <section class="w-100" *ngIf="unitsLine.isNewRequest && unitsLine.editModeEnabled">
                            <select id="addNewUnitEBSId_{{i}}" class="input__select font-caption-alt"
                                aria-label="Add EBS ID" [ngModel]="unitsLine?.currentState.assignedTask"
                                (ngModelChange)="onSelectedTaskChange($event, unitsLine, fcrUnitsFormControlData.engagementDetails)"
                                [name]="'assignedTask-' + unitsLine.uuid" required>
                                <option *ngFor="let item of fcrUnitsFormControlData.existingTasks"
                                    [ngClass]="{'disabled-color': item.isCrPendingInProject || !validateProjectEndDate(item)}"
                                    [disabled]="!validateProjectEndDate(item)"
                                    [title]="!validateProjectEndDate(item) ? 'This project has an expired end date' : ''"
                                    [ngValue]="item">
                                    {{item.wbsl3Id}} | {{item.projectName}} - {{item.serviceName}}
                                </option>
                            </select>
                        </section>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite" *ngIf="unitsLine.currentState.isCrPendingInProject">
                            {{fcrErrorMessages.PendingCrInProject}}
                        </p>
                    </div>

                    <!--Unit Role-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section *ngIf="!unitsLine.editModeEnabled">
                            <span
                                *ngIf="unitsLine.currentState.existingResource || unitsLine.currentState.role || unitsLine?.isRoleOrResourceLocationChanged">
                                {{unitsLine.currentState.role?.roleName ||
                                unitsLine.currentState.existingResource?.roleDescription | titlecase}}
                                <p *ngIf="unitsLine.currentState?.existingResource?.demandId">Demand ID:
                                    {{unitsLine.currentState.existingResource.demandId}}</p>
                            </span>
                        </section>
                        <section
                            *ngIf="unitsLine?.isNewRequest && unitsLine?.editModeEnabled && unitsLine?.currentState?.assignedTask">
                            <select id="addNewRoleName_{{i}}" class="input__select font-caption-alt"
                                aria-label="Add Role Name" [ngModel]="unitsLine?.currentState?.role"
                                (ngModelChange)="onRoleChange($event, unitsLine)" [name]="'role-' + unitsLine?.uuid"
                                required>
                                <option *ngFor="let role of fcrUnitsFormControlData?.roles | orderBy : 'roleName'"
                                    [ngValue]="role">
                                    {{role.roleName | titlecase}}
                                </option>
                            </select>
                        </section>

                        <section *ngIf="!unitsLine.isNewRequest && unitsLine.editModeEnabled">
                            <section class="input__select--icon">
                                <select id="adjustExistingUnitRole_{{i}}"
                                    class="input__select input__select--big font-caption-alt"
                                    aria-label="Role Description" [ngModel]="unitsLine.currentState.existingResource"
                                    [name]="'existingUnitResource-' + unitsLine.uuid"
                                    (ngModelChange)="onExistingUnitDemandChange($event, unitsLine, fcrUnitsFormControlData.engagementDetails, fcrUnitsFormControlData.billRates, fcrUnitsFormControlData.roles)">
                                    <option
                                        *ngFor="let existingResource of getUndisplayedExistingUnitResourcesPlusCurrent(unitsLine.currentState.existingResource) | orderBy : 'role'"
                                        [ngValue]="existingResource"
                                        [ngClass]="{'disabled-color': existingResource.isCrPendingInProject || !validateResourceProjectEndDate(existingResource.structureId)}"
                                        [disabled]="!validateResourceProjectEndDate(existingResource.structureId)"
                                        [title]="!validateResourceProjectEndDate(existingResource.structureId) ? 'This project has an expired end date' : ''">
                                        <div>
                                            {{existingResource.roleDescription | titlecase}} | Demand ID:
                                            {{existingResource.demandId}} | Task:
                                            {{existingResource.structureId}}
                                        </div>
                                    </option>
                                </select>

                            </section>
                        </section>
                    </div>

                    <!--Contract Type-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <span *ngIf="unitsLine.currentState.assignedTask" class="dm-badge dm-badge-v2 dm-contract-type"
                            [ngClass]="unitsLine.currentState.assignedTask && unitsLine?.currentState?.assignedTask?.isFixedFeeProject ? 'contract-type-ff' : 'contract-type-tm'">{{
                            unitsLine.currentState.assignedTask?.isFixedFeeProject ? "FF" : "T&amp;M"}}</span>
                    </div>

                    <!--Billing Role ID-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section *ngIf="!unitsLine.editModeEnabled">
                            {{unitsLine.currentState.billingInfo?.billingRoleId}}
                        </section>
                        <section class="input__select--icon"
                            *ngIf="unitsLine.editModeEnabled && unitsLine.isNewRequest && unitsLine.currentState.assignedTask?.isFixedFeeProject && !unitsLine.currentState.isProjectPFPOrPFF">
                            <select id="editNewBillingRoleId_{{i}}"
                                class="input__select input__select--big font-caption-alt"
                                aria-label="Edit Billing Role ID" [ngModel]="unitsLine?.currentState?.billingInfo"
                                (ngModelChange)="onBillingRoleChange($event, unitsLine)"
                                [name]="'billingRoleIdUnits-' + unitsLine.uuid">
                                <option *ngFor="let item of fcrUnitsFormControlData.FFBillRates" [ngValue]="item">
                                    {{item.billingRoleId}}
                                    <span *ngIf="item.description">|</span>
                                    {{item.description | titlecase}}
                                </option>
                            </select>
                        </section>
                        <section
                            *ngIf="unitsLine.editModeEnabled && unitsLine.isNewRequest && !unitsLine.currentState.assignedTask?.isFixedFeeProject">
                            {{fcrUnitsFormControlData?.nonBill?.billingRoleId}}
                        </section>
                        <section
                            *ngIf="unitsLine.editModeEnabled && !unitsLine.isNewRequest && unitsLine.currentState.existingResource && (!unitsLine.currentState.isProjectPFPOrPFF || unitsLine.currentState.isProjectPFPOrPFF)">
                            {{unitsLine?.currentState?.billingInfo?.billingRoleId}}
                        </section>
                    </div>

                    <!--Exisiting units-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section
                            [textContent]="unitsLine?.currentState.existingResource ? unitsLine?.currentState?.existingResource?.existingUnits : 0">
                        </section>
                    </div>

                    <!--Additional units-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section *ngIf="!unitsLine.editModeEnabled" class="text-align-right"
                            [textContent]="unitsLine?.currentState?.newUnits">
                        </section>
                        <section
                            *ngIf="unitsLine.editModeEnabled && (unitsLine.currentState.assignedTask || unitsLine.currentState.existingResource)">
                            <input type="text" id="additionalUnits_{{i}}" aria-label="Enter Additional Units"
                                class="dm-input font-caption-alt text-align-right" required dmLimitNumeric
                                wholeNumberLength="9" [(ngModel)]="unitsLine.currentState.newUnits"
                                [name]="'additionalUnits_-' + unitsLine.uuid"
                                (ngModelChange)="onAdditionalUnitsChange(unitsLine, i)">
                            <!-- <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite" *ngIf="!unitsLine?.currentState?.isAdditionalUnitsQuantityAllowed">
                                {{fcrErrorMessages?.AdditionalUnitsQuantityAllowed}}
                            </p> -->
                            <p role="alert" *ngIf="unitsLine?.currentState?.isNewUnitsLessThanActuals"
                                class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite">
                                {{fcrErrorMessages?.AdditionalUnitsCantBeLessThanActuals}}
                                <span *ngIf="unitsLine?.currentState?.existingResource?.exisitingActuals">
                                    {{unitsLine?.currentState?.existingResource?.exisitingActuals}} </span>
                                Units.
                            </p>
                        </section>
                    </div>

                    <!--DB Cost & Units-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section *ngIf="unitsLine.currentState.existingResource">
                            {{unitsLine?.currentState?.existingResource?.dbCost | number : '1.2-2'}}
                            {{unitsLine?.currentState?.existingResource?.dbCost ?
                            fcrUnitsFormControlData?.planCurrency
                            : ""}}
                            <p>
                                {{unitsLine?.isDbOnlyDemand ? (0 | number : '1.2-2') :
                                ((unitsLine?.currentState?.existingResource?.dbUnits) | number : '1.2-2')}} EA
                            </p>
                        </section>
                    </div>

                    <!--CFP Cost & Units-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section *ngIf="unitsLine.currentState.isCostRateResolved">
                            {{unitsLine?.isDbOnlyDemand ? (0 | number : '1.2-2') : ((unitsLine?.currentState?.costRate
                            *
                            (unitsLine?.currentState?.existingResource?.existingUnits +
                            unitsLine?.currentState?.newUnits))
                            |
                            number : '1.2-2')}}
                            {{fcrUnitsFormControlData.planCurrency}}
                            <p>
                                {{unitsLine?.isDbOnlyDemand ? (0 | number : '1.2-2') :
                                ((unitsLine?.currentState?.existingResource?.existingUnits +
                                unitsLine?.currentState?.newUnits) | number : '1.2-2')}} EA
                            </p>
                        </section>
                        <section *ngIf="!unitsLine?.currentState.isCostRateResolved">
                            <i title="Calculating Cost Rate..." class="glyphicon glyphicon-refresh glyphicon-spin"></i>
                        </section>
                    </div>

                    <!--Unit Cost rate-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section *ngIf="unitsLine.currentState.isCostRateResolved">
                            {{unitsLine.currentState.costRate | number : '1.2-2'}}
                        </section>
                        <section *ngIf="!unitsLine.currentState.isCostRateResolved">
                            <i title="Calculating Cost Rate..." class="glyphicon glyphicon-refresh glyphicon-spin"></i>
                        </section>
                    </div>

                    <!--Additional Cost-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <section>
                            {{(unitsLine?.isDbOnlyDemand ? 0 :
                            (unitsLine?.currentState?.costRate
                            *
                            (unitsLine?.currentState?.existingResource?.existingUnits +
                            unitsLine?.currentState?.newUnits))) -
                            (unitsLine?.currentState?.existingResource?.dbCost) | number : '1.2-2'}}
                        </section>
                    </div>

                    <!--Actions-->
                    <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                        <div class="dm-btn-holder" *ngIf="!unitsLine.editModeEnabled && isEditable">
                            <button *ngIf="!unitsLine.isDbOnlyDemand" type="button" id="editBtn_{{i}}" aria-label="Edit"
                                class="dm-transparent-btn dm-accent-color" (click)="onEditClick(unitsLine, i)">
                                <i class="icon icon-editLegacy"></i>
                                <span>Edit</span>
                            </button>
                            <dm-tooltip *ngIf="unitsLine.isDbOnlyDemand" role="tooltip" class="dm-tooltipinline"
                                [dmPlacement]="'left'" [dmTriggers]="'hover'"
                                [ngbTooltipContent]="'This autopopulated demand cannot be edited. This demand was autopopulated to reconcile DB and CFP for a demand which exists on Delivery Baseline but does not exist on Current Financial Plan. This will remove the demand in Delivery Baseline.'"
                                [dmIcon]="'icon icon-info'" ariaLabelToolTip="' Delivery Baseline (DB) Demand'">
                            </dm-tooltip>
                            <button type="button" id="deleteBtn_{{i}}" aria-label="Delete"
                                class="dm-transparent-btn dm-accent-color"
                                [disabled]="unitsLine.isPnfEdit || unitsLine.isAutoPopulated"
                                (click)="deleteRequest($event, i);">
                                <i class="icon icon-delete"></i>
                                <span>Delete</span>
                            </button>
                        </div>
                        <div class="dm-btn-holder" *ngIf="(unitsLine.editModeEnabled) && isEditable">
                            <button type="button" id="saveBtn_{{i}}" aria-label="Save"
                                class="dm-transparent-btn dm-accent-color" [disabled]="!unitsLine.isSaveable"
                                (click)="saveRequest(i);">
                                <i class="icon icon-save"></i>
                                <span>Save<span class="required-field">*</span></span>
                            </button>
                            <button type="button" id="cancelBtn_{{i}}" aria-label="Cancel"
                                class="dm-transparent-btn dm-accent-color"
                                (click)="unitsLine.editModeEnabled=false; discardChanges($event, i)">
                                <i class="icon icon-close"></i>
                                <span>Cancel</span>
                            </button>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite" *ngIf="!unitsLine.currentState.isContractTypeValid">
                                {{invalidContractTypeMessage}}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div role="row" class="fcr-units-form-table__grid-row" aria-labelledby="requestedUnitsCaption">
                <div role="gridcell" class="fcr-units-form-table__grid-identifier-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="font-weight-600">Totals</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="font-weight-600">{{totalExistingUnits | number : '1.2-2'}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell text-align--right">
                    <span class="font-weight-600">{{totalAdditionalUnits | number : '1.2-2'}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell">
                    <span class="font-weight-600">{{totalAdditionalCost | number : '1.2-2'}}</span>
                </div>
                <div role="gridcell" class="fcr-units-form-table__grid-body-cell" *ngIf="isEditable">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <span class="sr-only" tabindex="-1" role="alert" *ngIf="statusMessage">{{statusMessage}}</span>
</section>