import { Component, forwardRef, Inject, Input, Output, EventEmitter, SimpleChanges, Injector } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ContractType, IEngagementDetailsApiV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IBillRate, IBlendedCostRequest, ICrDemandChangeEvent, ICrDemandOutput, ICrResource, ICrUnitOutput, IExistingUnit, ITask } from "../../../common/services/contracts/changerequest.contract";
import { ErrorSeverityLevel, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { ChangeRequestDemandService } from "../../../common/services/change-request-demand.service";
import { DmError } from "../../../common/error.constants";
import { IFcrUnitsFormControlData } from "../../../common/services/contracts/changerequestv2.contract";
import { IFinancialRoles } from "../../../common/services/contracts/projectservice-functions.contract";
import { ProjectService } from "../../../common/services/project.service";
import { DataService } from "../../../common/services/data.service";
import { AccessibilityConstants, ComponentPrefix, RouteName, SourceConstants } from "../../../common/application.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { StateService } from "@uirouter/angular";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CancelOrContinueModal } from "../modals/cancel-or-continue-modal/cancel-or-continue-modal.component";

@Component({
    selector: "dm-fcr-units-form-control",
    templateUrl: "./fcr-units-form-control.html",
    styleUrls: ["./fcr-units-form-control.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FcrUnitsFormControlComponent),
            multi: true
        }
    ]
})
export class FcrUnitsFormControlComponent implements ControlValueAccessor {
    @Input() public get value(): ICrUnitOutput[] {
        return this.state;
    }
    public set value(val: ICrUnitOutput[]) {
        this.state = [...val];
        this.propagateChange(this.state);
    }

    @Input() public fvrContractType: ContractType;
    @Input() public fcrFormControlsTotalValue: ICrResource[];
    @Input() public fcrUnitsFormControlData: IFcrUnitsFormControlData;
    @Input() public fcrUnitsFormControlDataError: string;
    @Input() public populateRoles: ICrUnitOutput[] = null;
    @Input() public engagementDetails: IEngagementDetailsApiV2;

    @Output() public contractTypeChange = new EventEmitter<ContractType>();
    @Output() public demandChangeEvent = new EventEmitter<ICrDemandChangeEvent>();
    @Output() public unitRequestSaveDeleteEvent = new EventEmitter<ICrUnitOutput>();
    public invalidContractTypeMessage: string;
    public isEditable: boolean = true;
    public isPubSecEngagement: boolean = false;
    public loadingMessage: string = "Loading Units Details...";
    public statusMessage: string;
    public title: string;
    public totalCost: number = 0;
    public totalExistingUnits: number = 0;
    public totalAdditionalUnits: number = 0;
    public totalAdditionalCost: number = 0;
    public fcrErrorMessages = DmError.FinancialChangeRequest;
    public accessibilityConstants = AccessibilityConstants;

    private state: ICrUnitOutput[] = [];

    public constructor(
        @Inject(ChangeRequestDemandService) public crDemandService: ChangeRequestDemandService,
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) private dmLoggerService: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) public stateService: StateService,
        @Inject(Injector) private injector: Injector,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
    ) { }

    public ngOnChanges(allInputChanges: SimpleChanges): void {

        /* This's the recommeded way to access SimpleChanges */
        if (allInputChanges["populateRoles"] && Array.isArray(allInputChanges["populateRoles"].currentValue)) {
            // this.state = [...allInputChanges["populateRoles"].currentValue] as ICrUnitOutput[];
            const autopopulatedLineItems = this.calculateBlendedRateForEdits([...allInputChanges["populateRoles"].currentValue]);
            this.state = autopopulatedLineItems;
            this.recalculate();
        }

        if (allInputChanges["fvrContractType"] && allInputChanges["fvrContractType"].currentValue) {
            this.fvrContractType = allInputChanges["fvrContractType"].currentValue;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateChange = (...args: any[]): void => { return; };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateTouch = (...args: any[]): void => { return; };

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: (...args: any[]) => void): void {
        this.propagateChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: (...args: any[]) => void): void {
        this.propagateTouch = fn;
    }


    /**
     * Writes a new value to the element.
     * 
     * Part of ControlValueAccessor interface.
     */
    public writeValue(value: ICrUnitOutput[]): void {
        if (value !== undefined) {
            this.value = [...value];
        }
    }

    /**
   * Update role request details on change of task.
   *
   * @param {ITask} task
   * @param {ICrUnitOutput} rowLineItem
   * @param {IEngagementDetailsApiV2} engagementDetails
   * @memberof FcrUnitsFormControlComponent
   */
    public onSelectedTaskChange(task: ITask, rowLineItem: ICrUnitOutput): void {
        rowLineItem.currentState.isCrPendingInProject = this.crDemandService.isCrPendingInProject(task.projectId, this.fcrUnitsFormControlData.projectsWithPendingCr);
        rowLineItem.currentState.isAdditionalUnitsQuantityAllowed = true;
        this.retrieveCostRateData(rowLineItem);
        if (!task.isFixedFeeProject) {
            const filteredBillRoles = this.fcrUnitsFormControlData.billRates && this.fcrUnitsFormControlData.billRates.length && this.fcrUnitsFormControlData.billRates.filter((m) => m.billingRoleId === "NON-BILL");
            rowLineItem.currentState.billingInfo = filteredBillRoles && filteredBillRoles.length && filteredBillRoles[0]; // Set non billable role for T&M
        }
        if (!rowLineItem.currentState.isCrPendingInProject) {
            rowLineItem.currentState.assignedTask = task;
        } else {
            rowLineItem.isSaveable = this.crDemandService.isUnitRequestSaveable(rowLineItem);
        }
    }

    /**
     * Retrieves the list of existing unit resources to display in the dropdown. Should be the currently undisplayed existing resources
     * plus the existing resource for the current row.
     */
    public getUndisplayedExistingUnitResourcesPlusCurrent(currentExistingResource: IExistingUnit): IExistingUnit[] {
        let existingResourcesToDisplay: IExistingUnit[];

        if (this.fcrUnitsFormControlData && this.fcrUnitsFormControlData.existingResources) {
            existingResourcesToDisplay = this.fcrUnitsFormControlData.existingResources.filter((existingResource: IExistingUnit) => (!existingResource.isDisplayed && existingResource.demandId && existingResource.demandId !== "#"));
        } else {
            existingResourcesToDisplay = [];
        }

        if (currentExistingResource) {
            existingResourcesToDisplay.push(currentExistingResource);
        }
        return existingResourcesToDisplay.sort((a: IExistingUnit, b: IExistingUnit) => {
            return parseInt(a.demandId, 10) - parseInt(b.demandId, 10);
        });
    }

    /**
     * Set contract type error message based on request contract type.
     *
     * @param {boolean} isFixedFee
     * @memberof FcrRolesFormControlComponent
     */
    public setContractTypeErrorMessage(isFixedFee: boolean): void {
        this.invalidContractTypeMessage = `Cannot add a ${isFixedFee ? "Fixed Fee" : "Time and Material"} task to the FCR with an existing ${isFixedFee ? "Time and Material" : "Fixed Fee"} task.`;
    }

    /**
     * When user wants to save the current state, copy the current state into the saved state.
     */
    public saveRequest(index: number): void {
        this.validateCrUnitOutput(this.state[index], this.fvrContractType, index);
        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        const currentUnitsRequests = this.state.filter((m) => m.currentState).length;
        const savedUnitsRequests = this.state.filter((m) => m.savedState).length;
        const rowToSave = this.state.filter((m) => this.state[index].uuid === m.uuid)[0];
        let isValidOverRide: boolean = false;
        if ((savedUnitsRequests === 1 && currentUnitsRequests === 1 && numberOfSavedRequests === 1) || (savedUnitsRequests === 1 && rowToSave.savedState && numberOfSavedRequests === 1)) {
            this.state[index].currentState.isContractTypeValid = true;
            isValidOverRide = true;
        } else if (this.fvrContractType && !this.crDemandService.validateFvrContractType(this.state[index], this.fvrContractType)) {
            this.state[index].currentState.isContractTypeValid = false;
            const isRequestFixedFee: boolean = this.state[index].currentState.assignedTask.isFixedFeeProject;
            this.setContractTypeErrorMessage(isRequestFixedFee);
        } else {
            this.state[index].currentState.isContractTypeValid = true;
            isValidOverRide = true;
        }

        if (this.state[index].currentState.isContractTypeValid && this.state[index].currentState.isAdditionalUnitsQuantityAllowed && !this.state[index].currentState.isNewUnitsLessThanActuals) {
            this.emitContractTypeChange(this.state[index], isValidOverRide);
            this.state[index].editModeEnabled = false;
            this.state[index].savedState = { ...this.state[index].currentState };
            this.sharedFunctionsService.focus("editBtn_" + index, true);
            this.emitUnitsRequestSaveOrDelete(this.state[index]);
            this.value = this.state;
        }
    }

    /**
     * Validates a unit request before saving.
     *
     * @param {ICrUnitOutput} request
     * @param {ContractType} fvrContractType
     */
    public validateCrUnitOutput(request: ICrUnitOutput, fvrContractType: ContractType, index: number): void {
        request.currentState.isAdditionalUnitsQuantityAllowed = true;

        const existingUnits = request.currentState.existingResource && request.currentState.existingResource.plannedHours ? +request.currentState.existingResource.plannedHours : 0;
        const newUnits = +request.currentState.newUnits;
        const existingActuals = request.currentState.existingResource ? +request.currentState.existingResource.exisitingActuals : 0;
        const revisedCfpUnits = newUnits + existingUnits;
        // Additional units should not bring existing units below actual units posted if present
        if (revisedCfpUnits < existingActuals) {
            request.currentState.isNewUnitsLessThanActuals = true;
            this.sharedFunctionsService.focus("addAdditionalHours_" + index, true);
        } else {
            request.currentState.isNewUnitsLessThanActuals = false;
        }

        request.currentState.isAdditionalUnitsQuantityAllowed = this.sharedFunctionsService.validateCrNumberValue(request.currentState.newUnits) ? true : false;
    }


    public deleteRequest(event: KeyboardEvent, index: number): void {
        if (this.state[index].currentState.existingResource) {
            // we need to update the currently displayed existing resources
            this.state[index].currentState.existingResource.isDisplayed = false;
        }

        const isDeletedRoleFixedFee: boolean = this.state[index].currentState.assignedTask ? this.state[index].currentState.assignedTask.isFixedFeeProject : undefined;
        const deletedContractType: ContractType = isDeletedRoleFixedFee ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        const deletedRowLineItem: ICrUnitOutput = this.state[index];
        this.state.splice(index, 1);
        this.value = this.state;
        this.recalculate();

        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        if (!this.state.length && numberOfSavedRequests === 1) {
            this.fvrContractType = undefined;
        } else if ((isDeletedRoleFixedFee !== undefined && this.fvrContractType === deletedContractType) && !this.fcrFormControlsTotalValue.some((resourceRequest: ICrResource) => resourceRequest.savedState.assignedTask && resourceRequest.savedState.assignedTask.isFixedFeeProject === isDeletedRoleFixedFee)) {
            this.fvrContractType = undefined;
        }

        // Only emit delete if there was a saved state
        if (deletedRowLineItem.savedState) {
            // Only emit contract type on delete if we are clearing the overall request type
            if (!this.fvrContractType) {
                this.emitContractTypeChange(undefined);
            }
            this.emitUnitsRequestSaveOrDelete(this.state[index]);
        }

        if (this.state.length > 0 && index !== this.state.length && this.state[index]) {
            if (this.state[index].savedState) {
                this.sharedFunctionsService.focus("editBtn_" + index, true);
            }
            // else {
            //     this.state[index].currentState.existingDemand ?
            //         this.sharedFunctionsService.focus("adjustExistingUnitRole_" + index, true) :
            //         this.sharedFunctionsService.focus("addNewEBSId_" + index, true);
            // }
        } else {
            // this.isSubmittable() ? this.sharedFunctionsService.focus("submit", true) : this.sharedFunctionsService.focus("closeUpdateButton", true);
        }
    }

    /**
     * revert to previously saved state
     */
    public discardChanges(event: KeyboardEvent, index: number): void {
        if (this.state[index] && this.state[index].savedState) {
            if (this.state[index].currentState.existingResource) {
                this.state[index].currentState.existingResource.isDisplayed = false;
                this.state[index].savedState.existingResource.isDisplayed = true;
            }
            this.state[index].currentState = { ...this.state[index].savedState };
            this.recalculate();
            this.sharedFunctionsService.focus("editBtn_" + index, true);
        }
        if (this.state[index] && !this.state[index].savedState) {
            this.deleteRequest(event, index);
        }
    }

    /**
     * Validate Project End Date
     * 
     * @param {ITask} task
     * @memberof LaborRequestModalComponent
     */
    public validateProjectEndDate(task: ITask): boolean {
        if (!task || !task.projectEndDate) {
            return false;
        }

        const projectEndDate = new Date(task.projectEndDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
     * Redirects users to Engagement's Manage EBS screen
     * 
     * @memberof LaborRequestModalComponent
     */
    public redirectToEngagementManageEBS(): void {
        if (this.engagementDetails) {
            const engagementId = this.engagementDetails.id;
            this.stateService.go(RouteName.EngagementManageEBS, { engagementId }, { reload: RouteName.EngagementManageEBS });
        }
    }

    /**
     * Retrieves the expired projects
     * 
     * @memberof LaborRequestModalComponent
     */
    public getExpiredProjects(): Set<string> {
        const filteredTasks = this.fcrUnitsFormControlData && this.fcrUnitsFormControlData.existingTasks && this.fcrUnitsFormControlData.existingTasks.filter((task) => {
            const projectEndDate = new Date(task.projectEndDate);
            const currentDate = new Date();

            return projectEndDate < currentDate;

        });

        const expiredProjects = new Set<string>();
        if (filteredTasks) {
            filteredTasks.forEach((task) => {
                expiredProjects.add(task.projectId);
            });
        }

        return expiredProjects;
    }

    /**
     * Proceed with project's end date change.
     * 
     */
    public onEditProjectEndDate(): void {

        // First shows up a modal to confirm the date change action from user.
        const cancelOrContinueModalRef: NgbModalRef = this.modalService.open(
            CancelOrContinueModal,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }

        );

        cancelOrContinueModalRef.componentInstance.modalMessage = "The details entered in this FCR form will be erased while changing the project dates. Do you wish to continue?";
        cancelOrContinueModalRef.componentInstance.continueFunction = () => {
            this.redirectToEngagementManageEBS();
        };

    }

    /**
    * Validates End Date of Projects having existing demands
    * 
    * @param {ITask} task
    * @memberof LaborRequestModalComponent
    */
    public validateResourceProjectEndDate(taskId: string): boolean {
        if (!taskId) {
            return false;
        }
        const projectId = this.projectService.getProjectIdFromTaskId(taskId);
        const projectDetails = this.projectService.getProjectFromWbsId(projectId, this.engagementDetails);
        if (!projectDetails || !projectDetails.endDate) {
            return false;
        }
        const projectEndDate = new Date(projectDetails.endDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
    * Emit an event when a role request is successfully saved or deleted.
    *
    * @param {ICrUnitOutput} rowLineItem
    * @memberof FcrUnitsFormControlComponent
    */
    public emitUnitsRequestSaveOrDelete(rowLineItem: ICrUnitOutput): void {
        this.unitRequestSaveDeleteEvent.emit(rowLineItem);
    }

    public onAdditionalUnitsChange(UnitsLine: ICrUnitOutput, index: number): void {
        this.validateCrUnitOutput(this.state[index], this.fvrContractType, index);
        UnitsLine.isSaveable = this.crDemandService.isUnitRequestSaveable(UnitsLine);
        UnitsLine.currentState.isAdditionalUnitsQuantityAllowed = this.sharedFunctionsService.validateCrNumberValue(UnitsLine.currentState.newUnits) ? true : false;
        this.calculateBlendedCostRate(UnitsLine);
        this.recalculate();
    }

    /**
     * Add new Units
     *
     * @memberof LaborRequestModalComponent
     */
    public addNewUnits(): void {
        const newUnits = this.crDemandService.getAddUnitInitialState();
        this.state.push(newUnits);
    }

    public adjustExisting(): void {
        const existingUnits = this.crDemandService.getAdjustUnitInitialState();
        this.state.push(existingUnits);
    }

    /**
     * used for angular optimization of rendering this.state list (trackBy)
     */
    public trackByFn(index: number, item: ICrDemandOutput): string {
        return item.uuid;
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        const length: number = this.state.length;
        let defaultId: string = "adjustExisting";
        if (length > 0 && this.state[length - 1]) {
            defaultId = this.state[length - 1].savedState ? "deleteBtn_" + (length - 1) : "cancelBtn_" + (length - 1);
        }
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, defaultId);
        }
    }

    /**
     * Sets the focus on the first editable element after edit button is clicked.
     */
    public moveFocusToEditableElement(index: string, isExistingResource: boolean): void {
        const focusingId: string = isExistingResource ? "adjustExistingRole_" + index : "addNewEBSId_" + index;
        this.sharedFunctionsService.focus(focusingId, true);
    }


    /**
     * Sets role on unit role change
     *
     * @param {*} role
     * @param {ICrUnitOutput} rowLine
     * @memberof FcrUnitsFormControlComponent
     */
    public onRoleChange(role: IFinancialRoles, rowLine: ICrUnitOutput): void {
        rowLine.currentState.role = role;
        rowLine.currentState.isCostRateResolved = false;
        rowLine.isSaveable = false;

        this.retrieveCostRateData(rowLine);
    }

    /**
     * Update unit request details and validity on change of billing info.
     *
     * @param {IBillRate} billingInfo
     * @param {ICrUnitOutput} rowLineItem
     * @memberof FcrUnitsFormControlComponent
     */
    public onBillingRoleChange(billingInfo: IBillRate, rowLineItem: ICrUnitOutput): void {
        rowLineItem.currentState.billingInfo = billingInfo;
        rowLineItem.isSaveable = this.crDemandService.isUnitRequestSaveable(rowLineItem);
    }

    /**
     * Update request details on change of existing expense item.
     *
     * @param {*} role
     * @param {ICrUnitOutput} rowLine
     * @memberof FcrUnitsFormControlComponent
     */
    public onExistingUnitDemandChange(existingUnitDemand: IExistingUnit, rowLineItem: ICrUnitOutput, engagementDetails: IEngagementDetailsApiV2, billRates: IBillRate[], unitRoles?: IFinancialRoles[]): void {
        this.crDemandService.setExistingUnitResource(existingUnitDemand, rowLineItem, engagementDetails, this.fcrUnitsFormControlData.existingTasks, billRates, unitRoles);
        rowLineItem.currentState.isCrPendingInProject = existingUnitDemand.isCrPendingInProject;
        rowLineItem.isSaveable = this.crDemandService.isUnitRequestSaveable(rowLineItem);
        if (!rowLineItem.currentState.isCrPendingInProject) {
            this.retrieveCostRateData(rowLineItem);
        } else {
            rowLineItem.isSaveable = this.crDemandService.isUnitRequestSaveable(rowLineItem);
        }
    }

    /**
     * Recalculate relevant values given a change in the state.
     */
    public recalculate(): void {
        let totalExistingUnits: number = 0;
        let totalAdditionalUnits: number = 0;
        let totalCost: number = 0;
        let totalAdditionalCost: number = 0;
        this.state.forEach((item: ICrUnitOutput) => {
            if (!isNaN(item.currentState.newUnits)) {
                item.currentState.newUnits = +item.currentState.newUnits;
                const existingUnits = item.currentState.existingResource ? item.currentState.existingResource.existingUnits : 0;
                const cfpCost = item.isDbOnlyDemand ? 0 : item.currentState.costRate * (existingUnits + +item.currentState.newUnits);
                const additionalCost = cfpCost - (item.currentState.existingResource && item.currentState.existingResource.dbCost ? item.currentState.existingResource.dbCost : 0);

                totalExistingUnits += existingUnits;
                totalAdditionalUnits += item.currentState.newUnits;
                totalCost += cfpCost;
                totalAdditionalCost += additionalCost;
            }
        });
        this.totalExistingUnits = totalExistingUnits;
        this.totalCost = totalCost;
        this.totalAdditionalUnits = totalAdditionalUnits;
        this.totalAdditionalCost = totalAdditionalCost;
        this.value = this.state;
    }


    public onEditClick(event: KeyboardEvent, index: number): void {
        this.state[index].editModeEnabled = true;
        this.moveFocusToEditableElement(index.toString(), !!this.state[index].currentState.newUnits);
        this.validateCrUnitOutput(this.state[index], this.fvrContractType, index);
        if (!this.state[index].currentState.costPeriodList || this.state[index].currentState.costPeriodList.length < 1) {
            this.retrieveCostRateData(this.state[index]);
        }
    }

    /**
     * Emit when the contract type of role requests has changed.
     *
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public emitContractTypeChange(rowLineItem: ICrUnitOutput, isValidOverRide?: boolean): void {
        let laborContractType: ContractType;
        if (rowLineItem) {
            laborContractType = rowLineItem.currentState.assignedTask.isFixedFeeProject ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        }

        if (!this.fvrContractType || isValidOverRide) {
            this.contractTypeChange.emit(laborContractType);
        }
    }

    /**
     * Retrieve cost rate data and calculate blended cost rate for given role request.
     *
     * @private
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    private retrieveCostRateData(rowLineItem: ICrUnitOutput, isAutoPop?: boolean): void {

        if ((rowLineItem.currentState.role || rowLineItem.currentState.existingResource) && rowLineItem.currentState.assignedTask) {
            this.projectService.retrieveUnitCostRate(rowLineItem).then((response) => {

                rowLineItem.currentState.costPeriodList = response.costRatePeriod;
                rowLineItem.currentState.apiCostRate = response.costRate;
                this.calculateBlendedCostRate(rowLineItem);

                rowLineItem.currentState.isCostRateResolved = true;
                this.recalculate();
                rowLineItem.isSaveable = this.crDemandService.isUnitRequestSaveable(rowLineItem);

                if (isAutoPop) {
                    rowLineItem.savedState = this.crDemandService.cloneUnitsCurrentState(rowLineItem);
                }
            }).catch((err) => {
                rowLineItem.currentState.isCostRateResolved = true;
                rowLineItem.isSaveable = false;
                const correlationID: string = DataService.getCorrelationIdFromError(err);
                this.fxpMessageService.addMessage(DmError.FinancialChangeRequest.CannotRetrieveCostRate + " CorrelationId: " + correlationID, FxpConstants.messageType.error);
                this.dmLoggerService.logError(ComponentPrefix + "Fcr Roles Form Control", SourceConstants.Method.RetrieveCostRateData, err, DmError.FinancialChangeRequest.CannotRetrieveCostRate, null, undefined, correlationID, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
        }
    }

    private calculateBlendedCostRate(rowLineItem: ICrUnitOutput): void {
        rowLineItem.currentState.isCostRateResolved = false;
        const currentDate = new Date();
        const existingHours = rowLineItem.currentState.existingResource ? rowLineItem.currentState.existingResource.plannedHours : 0;
        let isCurrentDateStartDate: boolean = false;
        const projectStartDate = new Date(rowLineItem.currentState.assignedTask.projectStartDate).getTime();
        const projectEndDate = new Date(rowLineItem.currentState.assignedTask.projectEndDate).getTime();

        // if project end date is in the past, take staffed (if available) or planned cost rate as blended cost rate
        if ((projectEndDate < currentDate.getTime())) {
            if (rowLineItem.currentState.existingResource && rowLineItem.currentState.existingResource.plannedCostRate && !rowLineItem.isRoleOrResourceLocationChanged) {
                rowLineItem.currentState.costRate = parseFloat(rowLineItem.currentState.existingResource.plannedCostRate.toFixed(3));
            } else {
                rowLineItem.currentState.costRate = parseFloat(rowLineItem.currentState.apiCostRate.toFixed(3));
            }
        } else {
            let startDate: Date;
            if (projectStartDate < currentDate.getTime()) {
                startDate = currentDate;
                isCurrentDateStartDate = true;
            } else {
                startDate = rowLineItem.currentState.assignedTask.projectStartDate;
                isCurrentDateStartDate = false;
            }

            const blendedCostRequest: IBlendedCostRequest = {
                startDate,
                endDate: rowLineItem.currentState.assignedTask.projectEndDate,
                existingHours,
                currentFinancialPlanCost: rowLineItem.currentState.existingResource ? (rowLineItem.currentState.existingResource.plannedCostRate * rowLineItem.currentState.existingResource.plannedHours) : 0, // Applicable only for Adjust Existing Role
                additionalHours: +rowLineItem.currentState.newUnits,
                costPeriodList: rowLineItem.currentState.costPeriodList,
                staffedHours: 0,
                staffedCost: 0,
                isCurrentDateStartDate
            };

            rowLineItem.currentState.costRate = parseFloat(this.projectService.getBlendedCostRate(blendedCostRequest).toFixed(3));
        }

        rowLineItem.currentState.isCostRateResolved = true;
    }

    /**
     * Calculate the blended rate for any autopopulated plan and forecast edits.
     *
     * @private
     * @param {ICrDemandOutput[]} autopopulatedLineItems
     * @returns {void}
     * @memberof FcrRolesFormControlComponent
     */
    private calculateBlendedRateForEdits(autopopulatedLineItems: ICrUnitOutput[]): ICrUnitOutput[] {
        const autopopLineItemsWithRates = autopopulatedLineItems.map((lineItem: ICrUnitOutput) => {
            if (lineItem.isPnfEdit && !lineItem.isNewRequest) {
                this.retrieveCostRateData(lineItem, true);
                return lineItem;
            } else {
                return lineItem;
            }
        });

        return autopopLineItemsWithRates;
    }
}
