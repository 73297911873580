import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AddRoleModalModule } from "./modals/add-role-modal.module";
import { AddRolesComponent } from "./add-roles.component";
import { AddRolesService } from "../../../common/services/add-roles.service";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { InternalEngagementService } from "../../../common/services/internal-engagement-service";
import { ResourceRolesService } from "../../../common/services/resource-roles.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        AddRolesComponent,
    ],
    imports: [
        AddRoleModalModule,
        CommonComponentsModule,
        CommonModule,
        NgbModule
    ],
    providers: [
        AddRolesService,
        InternalEngagementService,
        ResourceRolesService,
        SharedFunctionsService
    ],
    entryComponents: [
        AddRolesComponent,
    ],
    exports: [
        AddRolesComponent,
    ]
})
export class AddRolesModule { }
