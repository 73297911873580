import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components } from "../../../common/application.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";

export interface IModal {
    title: string;
    subTitle?: string;
    iconTitle?: string;
}

@Component({
    selector: "dm-modal-v2",
    templateUrl: "./dm-modal-v2.html",
    styleUrls: ["./dm-modal-v2.scss"],
})
export class DmModalV2Component extends DmModalAbstract {
    @Input() public modalHeaderContent: IModal;
    @Input() public modalMinHeight: string = "auto";
    @Input() public modalHeight: string = "auto";
    @Input() public hasNonOverflowContent: boolean;
    @Input() public isLoading: boolean;
    @Input() public showFooter: boolean = true;
    @Input() public contentMarginBottom: boolean = true;

    public constructor(
        protected activeModal: NgbActiveModal,
        protected dmLogger: DMLoggerService,
    ) {
        super(activeModal, dmLogger, Components.Modal);
    }

    public ngOnInit(): void {
        /* Purposefully left blank */
    }

}