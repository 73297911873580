import { Component, Inject, Input, forwardRef, EventEmitter, Output } from "@angular/core";
import { FxpOCVFeedbackService, IFxpFeedbackMessage, UserInfoService } from "@fxp/fxpservices";
import { FormGroup, Validators, FormBuilder, AbstractControl } from "@angular/forms";
import { DmError } from "../../../common/error.constants";

export interface ISurveyData {
    title?: string;                   // Survey Title
    feedbackManifestType: string;     // Feedback Manifest type like "Fps"
    feedbackType: string;             // Feedback Survey type like "Survey"
    partnerTelemetryName: string;     // Partner Telemetry Name
    ratingQuestion?: string;          // (Optional) Rating question that can be displayed above rating stars.
    surveyId: string;                 // Survey Id
    surveyQuestion: string;           // Survey Question
}

@Component({
    selector: "inline-survey",
    templateUrl: "./inline-survey.html",
    styleUrls: ["./inline-survey.scss"]
})
export class InlineSurveyComponent {
    @Input() public maxRating: number;
    @Input() public surveyData: ISurveyData;
    @Output() public clearFeedback = new EventEmitter<void>();
    @Output() public submitFeedbackSurvey = new EventEmitter<Promise<any>>();

    public surveyFeedbackForm: FormGroup;
    public dmInlineSurveyErrorMessage = DmError.InlineSurvey;
    public get surveyResponse(): AbstractControl {
        return this.surveyFeedbackForm.get("surveyResponse");
    }

    public get surveyRating(): AbstractControl {
        return this.surveyFeedbackForm.get("surveyRating");
    }

    public get isEmailConsentChecked(): AbstractControl {
        return this.surveyFeedbackForm.get("isEmailConsentChecked");
    }

    public get surveyUserEmail(): AbstractControl {
        return this.surveyFeedbackForm.get("surveyUserEmail");
    }

    public constructor(
        @Inject(forwardRef(() => FxpOCVFeedbackService)) private feedbackService: FxpOCVFeedbackService,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(FormBuilder) private fb: FormBuilder
    ) { }

    public ngOnInit(): void {
        const userData = this.fxpUserInfoService.getCurrentUserData();
        this.surveyData.ratingQuestion = this.surveyData && this.surveyData.ratingQuestion ? this.surveyData.ratingQuestion : `How would you rate your experience with ${this.surveyData.title}?`;
        this.maxRating = this.maxRating ? this.maxRating : 5;
        this.surveyFeedbackForm = this.fb.group({
            surveyResponse: ["", [Validators.required, Validators.maxLength]],
            isEmailConsentChecked: [false],
            surveyRating: ["", Validators.required],
            surveyUserEmail: ["", Validators.email]
        });
        this.surveyUserEmail.setValue(userData.EmailName);
    }

    /**
     * Method invoked on submit of survey form
     *
     * @memberof InlineSurveyComponent
     */
    public onSubmitSurveyFeedback(): void {
        if (this.surveyFeedbackForm.invalid) {
            this.surveyRating.markAsTouched();
            this.surveyResponse.markAsTouched();
            return;
        }
        const feedbackMessage: IFxpFeedbackMessage = {
            feedbackManifestType: this.surveyData.feedbackManifestType, // Manifest type for survey
            feedbackMessage: this.surveyResponse.value, // user message
            feedbackType: this.surveyData.feedbackType, // Feedback type
            partnerName: this.surveyData.partnerTelemetryName, // sub area info
            rating: this.surveyRating.value / this.maxRating, // normalized rating 0-1
            surveyId: this.surveyData.surveyId, // survey id
            email: this.isEmailConsentChecked ? this.surveyUserEmail.value : ""
        };

        if (this.surveyFeedbackForm.valid) {
            const postResponse = this.feedbackService.SubmitFeedback(feedbackMessage);
            this.submitFeedbackSurvey.emit(postResponse);
            this.clearSurveyFeedback();
        }
    }

    /**
     * Clears survey feedback form
     *
     * @memberof InlineSurveyComponent
     */
    public clearSurveyFeedback(): void {
        this.surveyFeedbackForm.reset();
    }
}


