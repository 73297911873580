import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, NotificationEvent, WBSResponseMessages, BusinessTask } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService, NotificationModelsForProject } from "../../../../common/services/dmnotification.service";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { ITaskDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IDateValidationRequest, WbsLevel, IWbsEditTaskDetails } from "../../../../common/services/contracts/wbs.service.contracts";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../common/services/wbs.service";
import moment from "moment";
import { DmNotificationService } from "../../../../common/services/dm-notification.service";
import { NotificationType } from "../../../../common/services/contracts/notification-bar.contracts";
import { v4 as uuid } from "uuid";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { DmError } from "../../../../common/error.constants";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, AbstractControl, Validators, FormBuilder } from "@angular/forms";
import { INotificationMessages } from "../../../../common/services/contracts/financial.service.contracts";
import { IStaffingResource, IStaffingTaskModel } from "../../../..//common/services/contracts/staffing-details.contract";
import { IState } from "../../../..//store/reducers";
import { Store } from "@ngrx/store";
import { getEntireEngagementDetails } from "../../../..//store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../..//store/engagement-details/engagement-details.reducer";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { StateService } from "@uirouter/angular";
import { INavigationListItemAttribute } from "../../../../components/navigation/navigationlist.model";
import { ManageEbsService } from "../../../../common/services/manage-ebs.service";

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    templateUrl: "./edit-task-details.html",
    styleUrls: ["./edit-task-details.scss"]
})
export class EbsEditTaskDetailsModalComponent extends DmModalAbstract {
    @Input() public selectedTask: ITaskDetailsV2;
    @Input() public serviceName: string;
    @Input() public serviceStartDate: Date;
    @Input() public serviceEndDate: Date;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public showActualsStartDateConflictMessage: boolean = false;
    public showActualsEndDateConflictMessage: boolean = false;
    public loadingText: string;
    public disableEBSStartDateEndDateUpdates: boolean = false;
    public viewResourceEnable: boolean = false;
    public showConflictingResources: boolean;
    public noOfConflictResources: number;
    public minDate: Date;
    public maxDate: Date;
    public isUpdateActive: boolean = false;
    public editTaskErrorMessages = DmError.EbsStructure.EditEbsStructure;
    public editTaskDetailsForm: FormGroup;

    public get taskName(): AbstractControl {
        return this.editTaskDetailsForm.get("taskName");
    }
    public get taskDescription(): AbstractControl {
        return this.editTaskDetailsForm.get("taskDescription");
    }
    public get taskStartDate(): AbstractControl {
        return this.editTaskDetailsForm.get("taskStartDate");
    }
    public get taskEndDate(): AbstractControl {
        return this.editTaskDetailsForm.get("taskEndDate");
    }

    public modalContent: IModal;
    public isUpdatingTask: boolean;
    public isBeforeServiceStartDate: boolean = false;
    public isAfterServiceEndDate: boolean = false;
    public filteredResourceView: IStaffingTaskModel[] = [];
    public isNamePristine: boolean = true;
    public isDescriptionPristine: boolean = true;
    public isStartDatePristine: boolean = true;
    public isEndDatePristine: boolean = true;

    private serviceResponseMessages: IResponseMessage;
    private notificationMessage: INotificationMessages;
    private readonly FXP_CONSTANTS = FxpConstants;
    private loadingStaffingPromise: boolean = false;
    private conflictResourceStatus: string[];
    private currentTab: INavigationListItemAttribute;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(Store) private store: Store<IState>,
        @Inject(StateService) private stateService: StateService,
        @Inject(ManageEbsService) private manageEbsService: ManageEbsService) {
        super(activeModal, dmLogger, Components.ManageEbsEditTaskDetails);
    }

    public ngOnInit(): void {
        this.initializeEditTaskDetailsForm();
        this.modalContent = {
            title: "Edit Task Details"
        };
        this.disableEBSStartDateEndDateUpdates = this.configurationService.getValue<boolean>("disableEBSStartDateEndDateUpdates");
        this.notificationMessage = this.configurationService.getValue<any>("Notification");
        this.serviceResponseMessages = WBSResponseMessages.Task;
        this.loadingText = "Loading Resource Details";
        this.conflictResourceStatus = this.configurationService.getValue<string[]>("conflictResourceStatus");
        this.showConflictingResources = false;
        this.noOfConflictResources = 0;

        /* Used for the datepicker to limit the dates that can be selected */
        this.minDate = this.serviceStartDate;
        this.maxDate = this.serviceEndDate;

        /* If description is missing, set the description to be the task name*/
        if (!this.selectedTask.description) {
            this.taskDescription.setValue(this.selectedTask.description);
        }
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalidOrPristine: boolean): boolean {
        if (this.loadingStaffingPromise || this.isStartDateRequired || this.isEndDateRequired || this.isBeforeServiceStartDate || this.isAfterServiceEndDate) {
            return true;
        }
        if (this.showActualsStartDateConflictMessage || this.showConflictingResources || this.showActualsEndDateConflictMessage) {
            return true;
        }

        this.isNamePristine = this.taskName.value === this.selectedTask.name;
        this.isDescriptionPristine = this.taskDescription.value === this.selectedTask.description;
        this.isStartDatePristine = moment(this.selectedTask.startDate).isSame(this.taskStartDate.value);
        this.isEndDatePristine = moment(this.selectedTask.endDate).isSame(this.taskEndDate.value);
        const isFormPristine: boolean = this.isNamePristine &&
            this.isDescriptionPristine &&
            this.isStartDatePristine &&
            this.isEndDatePristine;
        return isFormPristine || formInvalidOrPristine;
    }

    /**
     * Updates the task from data on the UI by calling the API and refreshing the cache with the newly received data.
     */
    public updateTaskDetails(): void {
        this.startBusinessProcessTelemetry(BusinessTask.AddOrRemoveTasks);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.UpdateTaskDetails, LogEventConstants.ManageEBSTaskEditSubmitClick);
        this.loadingText = "Updating Task Details";
        this.isUpdatingTask = true;
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        let editTaskHasDate: boolean = false;

        const editTaskDataRequest: IWbsEditTaskDetails = {};
        if (this.taskName.value !== this.selectedTask.name) {
            editTaskDataRequest.name = this.taskName.value;
        }
        if (this.taskDescription.value !== this.selectedTask.description) {
            editTaskDataRequest.description = this.taskDescription.value;
        }
        if (!moment(this.taskStartDate.value as Date).isSame(this.selectedTask.startDate as Date, "day")) {
            editTaskHasDate = true;
            editTaskDataRequest.startDate = moment(this.taskStartDate.value as Date).format(DATE_FORMAT);
        }
        if (!moment(this.taskEndDate.value as Date).isSame(this.selectedTask.endDate as Date, "day")) {
            editTaskHasDate = true;
            editTaskDataRequest.endDate = moment(this.taskEndDate.value as Date).format(DATE_FORMAT);
        }

        // Sends a request if any field has been modified
        // Run disconnected date change process for customer services where date is updated
        if (editTaskHasDate) {
            this.isUpdatingTask = true;
            const dateValidationRequest: IDateValidationRequest = {
                startDate: editTaskDataRequest.startDate ? editTaskDataRequest.startDate : moment(this.selectedTask.startDate).format(DATE_FORMAT),
                endDate: editTaskDataRequest.endDate ? editTaskDataRequest.endDate : moment(this.selectedTask.endDate).format(DATE_FORMAT),
            };

            this.wbsService.validateWbsDates(dateValidationRequest, this.selectedTask.id).then((response: any) => {
                if (response.status === 200) {
                    const orchestrationId: string = uuid();
                    editTaskDataRequest.startDate = dateValidationRequest.startDate;
                    editTaskDataRequest.endDate = dateValidationRequest.endDate;

                    this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.DateChange, loggedInUserData.BusinessPartnerId, this.selectedTask.id, orchestrationId).then(() => {
                        this.projectServiceFunction.orchestrateDateChange(editTaskDataRequest, this.selectedTask.id, this.selectedTask.name, orchestrationId, WbsLevel.Task, loggedInUserData.alias).then(() => {
                            this.fxpMessageService.addMessage("Date Change has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                            // Displays the new notification in the notification bar
                            this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.selectedTask.id, orchestrationId, NotificationType.DateChange);
                            this.isUpdatingTask = false;
                            this.closeModal();
                        });
                    });
                }
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.selectedTask.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.UpdateTaskDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isUpdatingTask = false;
                this.isUpdateActive = false;
            });
        } else if (editTaskDataRequest && editTaskDataRequest.name || editTaskDataRequest.description) {
            this.isUpdatingTask = true;
            this.wbsService.updateTaskDetailsV2(editTaskDataRequest, this.selectedTask.id)
                .then((response: any) => {
                    this.currentTab = this.manageEbsService.getCurrentEbsTabToRefreshContents(this.stateService);
                    if (this.currentTab && this.currentTab.refreshData) {
                        this.currentTab.refreshData();
                    }
                    const changedProperties: IChangedProperties[] = [];
                    const newName = editTaskDataRequest.name ? editTaskDataRequest.name : this.selectedTask.name;
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Name", this.selectedTask.name, newName));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Description", this.selectedTask.description, this.taskDescription.value));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("State", this.selectedTask.statusCode, this.selectedTask.statusCode));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Start Date", moment(this.selectedTask.startDate).format(DATE_FORMAT), moment(this.selectedTask.startDate).format(DATE_FORMAT)));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("End Date", moment(this.selectedTask.endDate).format(DATE_FORMAT), moment(this.selectedTask.endDate).format(DATE_FORMAT)));

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotifications()) {
                        this.createLogAndSendNotification(this.selectedTask.id, changedProperties);
                    }
                    if (response && response.status && response.status === 206) {
                        let partialSuccessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                        partialSuccessMessage = partialSuccessMessage.replace("#", newName);
                        this.fxpMessageService.addMessage(partialSuccessMessage, this.FXP_CONSTANTS.messageType.warning);
                    } else {
                        let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                        successMessage = successMessage.replace("#", newName);
                        this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                    }
                    this.closeModal();
                    this.endBusinessProcessTelemetry(BusinessTask.AddOrRemoveTasks);
                }).catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.selectedTask.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, failureMessage);
                    this.logError(SourceConstants.Method.UpdateTaskDetails, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUpdatingTask = false;
                });
        }
    }

    /**
     * Converts simple words to have an S at the end if the counter has a value greater than 1.
     * Can also hand capital words, based on caps boolean
     * For example engagement -> engagements. ENGAGEMENT -> ENGAGEMENTS (if caps is true)
     * @param word
     * @param count
     * @param caps
     */
    public getWordPluralWithS(word: string, count: number, caps: boolean): string {
        return this.sharedFunctionsService.getWordPluralWithS(word, count, caps);
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     */
    public onStartDateChange(startDate: Date): void {
        this.taskStartDate.setValue(startDate);
        this.isTaskStartDateValid();
    }

    /**
     * Checks validity of the start date by checking with GRM API
     */
    public isTaskStartDateValid(): void {
        this.loadingStaffingPromise = true;
        // todo turn this back on once staffing promise has been fixed
        // this.resourceData.staffingDataPromise.then((grmStaffingResponse: IStaffingTaskModel[]) => {
        const staffingResponse: IStaffingTaskModel[] = [];
        this.loadingStaffingPromise = false;
        this.isStartDateRequired = false;
        if (!this.taskStartDate.value) {
            this.isStartDateRequired = true;
            return;
        }
        this.isBeforeServiceStartDate = false;
        if (moment(this.taskStartDate.value).isBefore(this.serviceStartDate)) {
            this.isBeforeServiceStartDate = true;
            return;
        }
        this.noOfConflictResources = this.filterResourcesBasedOnDates(true, this.taskStartDate.value, staffingResponse);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            return;
        }
        if (this.taskEndDate.value) {
            this.noOfConflictResources = this.filterResourcesBasedOnDates(false, this.taskEndDate.value, staffingResponse);
            if (this.noOfConflictResources > 0) {
                this.showConflictingResources = true;
                return;
            }
        }
        this.showConflictingResources = false;
        this.showActualsStartDateConflictMessage = false;
        if (this.selectedTask.minDate && moment(this.taskStartDate.value).isAfter(this.selectedTask.minDate)) {
            this.showActualsStartDateConflictMessage = true;
        }

        // }).catch((error) => {
        //     /*An error has occurred */
        // });

    }

    /**
     * Toggles if the view resource is enabled
     */
    public toggleResources(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.ToggleResources, this.viewResourceEnable ? LogEventConstants.EBSEditToggleResourceCollapse : LogEventConstants.EBSEditToggleResourceExpand);
        this.viewResourceEnable = !this.viewResourceEnable;
    }

    /**
     * Capturing emitted end date value
     *
     * @param {Date} endDate
     */
    public onEndDateChange(endDate: Date): void {
        this.taskEndDate.setValue(endDate);
        this.isTaskEndDateValid();
    }

    /**
     * Checks validity of the end date by checking with GRM API
     */
    public isTaskEndDateValid(): void {
        this.loadingStaffingPromise = true;
        // todo turn this promise back on once staffing has been finished
        // this.resourceData.staffingDataPromise.then((grmStaffingResponse) => {
        const staffingResponse: IStaffingTaskModel[] = [];
        this.loadingStaffingPromise = false;
        this.isEndDateRequired = false;
        if (!this.taskEndDate.value) {
            this.isEndDateRequired = true;
            return;
        }
        this.isAfterServiceEndDate = false;
        if (moment(this.taskEndDate.value).isAfter(this.serviceEndDate)) {
            this.isAfterServiceEndDate = true;
            return;
        }
        this.noOfConflictResources = this.filterResourcesBasedOnDates(false, this.taskEndDate.value, staffingResponse);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            return;
        }
        if (this.taskStartDate.value) {
            this.noOfConflictResources = this.filterResourcesBasedOnDates(true, this.taskStartDate.value, staffingResponse);
            if (this.noOfConflictResources > 0) {
                this.showConflictingResources = true;
                return;
            }
        }
        this.showConflictingResources = false;
        this.showActualsEndDateConflictMessage = false;
        if (this.selectedTask.maxDate && moment(this.taskEndDate.value).isBefore(this.selectedTask.maxDate)) {
            this.showActualsEndDateConflictMessage = true;
        }

        // }).catch((error) => {
        //     /* An error has occurred */
        // });
    }

    /**
     * Is the given end date later than the given start date? True if the end date is later/greater than the start date.
     * False if the end date is earlier/less than the start date.
     */
    public isEndDateGreaterThanStartDate(startDate: Date, endDate: Date): boolean {
        if (startDate && endDate) {
            if (moment(endDate).isBefore(startDate)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Logs an event when employee link is clicked
     * @param link
     */
    public logEmployeeClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.LogEmployeeClick, LogEventConstants.EmployeeLinkClick);
    }


    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(engagementId: string, changedProperties: IChangedProperties[]): void {
        const currentUser = this.fxpUserInfoService.getCurrentUser();
        const propertyBag = {};
        propertyBag[LogEventConstants.ChangedValues] = this.wbsService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.ManageEBSTaskEdit, propertyBag);

        this.store.select(getEntireEngagementDetails(engagementId)).subscribe((engagementDetailsState: IEngagementDetailsState) => {
            if (engagementDetailsState.loaded) {
                // todo should consider adding engagement name to the api response so we dont have to call a whole new API here just to get the name
                const notification = new NotificationModelsForProject();
                notification.engagementId = engagementId;
                notification.engagementName = engagementDetailsState.engagementDetails.name;
                notification.projectId = this.selectedTask.id;
                notification.projectName = this.selectedTask.name;
                notification.attributes = {
                    ServiceId: this.selectedTask.serviceId,
                    ServiceName: this.serviceName,
                    TaskId: this.selectedTask.id,
                    TaskName: this.selectedTask.name
                };
                notification.eventName = NotificationEvent.TaskUpdated;
                notification.changedProperties = changedProperties;
                notification.sendTo = this.sharedFunctionsService.getListofPjmV2(engagementDetailsState.engagementDetails);
                notification.modifiedBy = currentUser;
                notification.modifiedDate = new Date();
                let esxpNotification = this.notificationMessage.ProjectNotification;
                esxpNotification = esxpNotification.replace("#", this.taskName.value);
                this.notificationService.sendNotification(notification, false, esxpNotification);
            }
        });
    }

    /**
     * Gets the number of conflict resources based on filtering on the given date.
     */
    private filterResourcesBasedOnDates(checkForStartDate: boolean, dateModified: Date, grmResponse: IStaffingTaskModel[]): number {
        Object.assign(this.filteredResourceView, grmResponse);
        let resourcesWithConflict = 0;
        if (grmResponse && grmResponse.length) {
            if (!grmResponse[0].isPresignature) {
                this.filteredResourceView = grmResponse.filter((object) => object.taskId === this.selectedTask.id);
            }
            this.filteredResourceView.forEach((taskDetails) => {
                taskDetails.demands.forEach((demandDetails, index) => {
                    let resourceList: IStaffingResource[];
                    if (checkForStartDate) {
                        resourceList = demandDetails.resources.filter((resourceObject) => (moment(resourceObject.resourceStartDate).isBefore(dateModified)) && (this.conflictResourceStatus.indexOf(resourceObject.resourceStatus) > 0));
                    } else {
                        resourceList = demandDetails.resources.filter((resourceObject) => (moment(resourceObject.resourceEndDate).isAfter(dateModified)) && (this.conflictResourceStatus.indexOf(resourceObject.resourceStatus) > 0));
                    }
                    resourcesWithConflict = resourcesWithConflict + (resourceList ? resourceList.length : 0);
                    taskDetails.demands[index].resources = resourceList;
                });
                taskDetails.demands = taskDetails.demands.filter((demandDetails) => demandDetails.resources.length > 0);
            });
            this.filteredResourceView = this.filteredResourceView.filter((object) => object.demands.length > 0);
        }
        return resourcesWithConflict;
    }


    /**
     * Initializes edit task form data
     */
    private initializeEditTaskDetailsForm(): void {
        this.editTaskDetailsForm = this.fb.group({
            taskName: [(this.selectedTask && this.selectedTask.name) ? this.selectedTask.name : "", Validators.required],
            taskDescription: [(this.selectedTask && this.selectedTask.description) ? this.selectedTask.description : "", Validators.required],
            taskStartDate: [this.selectedTask.startDate, [Validators.required]],
            taskEndDate: [this.selectedTask.endDate, [Validators.required]]
        });
    }
}
