<dm-tile class="col-md-12 col-sm-12 col-lg-12" [tileHeaderContent]="tileContent"
    [tileMinHeight]="'calc(100vh - 125px)'">
    <dm-loading loader [loadingText]="loadingText" [errorText]="errorText" [showLoading]="showLoading"
        [isCustomText]="true" *ngIf="isComponentLoading"></dm-loading>
    <section class="dm-non-procured-materials__tile" *ngIf="!isComponentLoading">
        <div class="dm-no-data-section" *ngIf="!npcActualsList || !npcActualsList.length">
            <dm-no-data [noDataText]="noNpcActualsPostedText" [showImage]="true"></dm-no-data>
        </div>
        <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="toolTipErrorMessage"></dm-service-error>
        <ng-container *ngIf="npcActualsList && npcActualsList.length">
            <section class="display-inline-flex-imp dm-p-t-20">
                <label class="dm-non-procured-materials__filter-section-label dm-p-5" for="project"
                    [ngClass]="{'disabled-text': isProjectContext || !npcActualsList || !npcActualsList.length}">
                    Filter By Project
                </label>
                <section class="dm-p-r-20">
                    <dm-dropdown-menu [id]="'dm-npc-projects-ddl'" [(value)]="selectedProject"
                        [ariaLabel]="'Filter By Project'" [options]="filterDataSource" [labels]="'displayName'"
                        [isDisabled]="isProjectContext || !npcActualsList || !npcActualsList.length"
                        (change)="onSelectedProjectChanged(selectedProject)">
                    </dm-dropdown-menu>
                </section>
            </section>
            <div role="grid" class="dm-non-procured-materials__grid">
                <caption class="sr-only">Non-Procured Materials</caption>
                <div role="rowgroup" class="materialsTable">
                    <div role="row" class="materialsTable__row">
                        <div role="columnheader" class="materialsTable__headerCell text-align--left"
                            aria-label="Expand/Collapse">
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--left col-rev">
                            Name
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--left col-rev">
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right">
                            Planned<br />Quantity
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right">
                            Planned<br />Cost Rate
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right col-rev">
                            Total Plan<br />Cost
                        </div>
                        <div role="columnheader"
                            class="materialsTable__headerCell text-align--right display-inline-block-imp">
                            Consumed/<br />Actual Quantity
                            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-t-8" [dmPlacement]="'right'"
                                [ngbTooltipContent]="consumedQuantityTooltipText" [dmIcon]="'icon icon-info'"
                                ariaLabelToolTip="Consumed Quantity Information">
                            </dm-tooltip>
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right">
                            Consumed<br />Effective Date
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right">
                            LTD Actual <br />Cost
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right">
                            Bill Rate
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right">
                            Total<br />Revenue
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--center">
                            Progress
                        </div>
                        <div role="columnheader" class="materialsTable__headerCell text-align--right">
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div class="materialsTable__dataGrid">
                            <div
                                *ngFor="let project of npcActualsList | filter: selectedProject ? {id : selectedProject.id} : undefined; let projectIndex = index; trackBy: trackByFunction">
                                <div role="row" class="materialsTable__projectDetailsRow w-100">
                                    <div role="gridcell">
                                        <span class="dm-p-r-15 cursor-pointer">
                                            <button id="{{'projectCollapse' + project.id}}" type="button"
                                                title="Collapse Project Details Section"
                                                aria-label="Project Details Section Expanded" class="split-btn"
                                                (click)="toggleUnitProject(project, 'projectExpand')"
                                                *ngIf="project.isProjectExpanded">
                                                <span class="icon icon-chevron-up"></span>
                                            </button>
                                            <button id="{{'projectExpand' + project.id}}" type="button"
                                                title="Expand Project Details Section"
                                                aria-label="Project Details Section Collapsed" class="split-btn"
                                                (click)="toggleUnitProject(project, 'projectCollapse')"
                                                *ngIf="!project.isProjectExpanded">
                                                <span class="icon icon-chevron-down"></span>
                                            </button>
                                        </span>
                                        <span>{{project.name}}</span> | <span>{{project.id}}</span>
                                    </div>
                                </div>
                                <div *ngIf="project.isProjectExpanded">
                                    <div
                                        *ngFor="let task of project.tasks; let taskIndex = index; trackBy: trackByFunction">
                                        <div role="row" class="materialsTable__taskDetailsRow w-100">
                                            <div role="gridcell" class="dm-p-l-20">
                                                <span class="dm-p-r-15 cursor-pointer">
                                                    <button id="{{'taskCollapse' + task.id}}" type="button"
                                                        title="Collapse task Details Section"
                                                        aria-label="Task Details Section Expanded" class="split-btn"
                                                        (click)="toggleUnitTask(task, 'taskExpand')"
                                                        *ngIf="task.isTaskExpanded">
                                                        <span class="icon icon-chevron-up"></span>
                                                    </button>
                                                    <button id="{{'taskExpand' + task.id}}" type="button"
                                                        title="Expand task Details Section"
                                                        aria-label="Task Details Section Collapsed" class="split-btn"
                                                        (click)="toggleUnitTask(task, 'taskCollapse')"
                                                        *ngIf="!task.isTaskExpanded">
                                                        <span class="icon icon-chevron-down"></span>
                                                    </button>
                                                </span>
                                                <span>EBS: {{task.id}}</span> | <span> Task: {{task.name}}</span>
                                            </div>
                                        </div>
                                        <div class="materialsTable">
                                            <div class="materialsTable__body">
                                                <div role="row" class="materialsTable__row"
                                                    *ngFor="let actual of task.actuals; let actualIndex = index; trackBy: trackByFunction"
                                                    [ngClass]="{'demand-loading': actual.isUpdateLoading}">
                                                    <ng-container *ngIf="task.isTaskExpanded" scope="row" role="rowheader">
                                                        <div
                                                            [ngClass]="{'demand-error': actual.isDemandUpdateStatus === 'Error', 'demand-success' : actual.isDemandUpdateStatus === 'Success' }">
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--left dm-p-t-20">
                                                            {{actual.resourceDescription}}
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--left dm-p-t-20">
                                                            <span class="dm-badge npm-badge non-billable"
                                                                *ngIf="!actual.isBillable" title="Non-Billable">NB</span>
                                                            <span class="dm-badge npm-badge billable dm-p-5"
                                                                *ngIf="actual.isBillable" title="Billable">B</span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right dm-p-t-20"
                                                            [ngClass]="{'pad-right-30': hasEditPermissions}">
                                                            {{actual.plannedQuantity | number:"1.0-2" }}
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right dm-p-t-20"
                                                            [ngClass]="{'pad-right-30': hasEditPermissions}">
                                                            {{actual.plannedCostRate | dmDisplayCurrencyOrDash:
                                                            currencySymbol}}
                                                            <span *ngIf="!actual.plannedCostRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right dm-p-t-20"
                                                            [ngClass]="{'pad-right-30': hasEditPermissions}">
                                                            {{actual.totalPlannedCostRate | dmDisplayCurrencyOrDash:
                                                            currencySymbol }}
                                                            <span *ngIf="!actual.totalPlannedCostRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right"
                                                            [ngClass]="hasEditPermissions ? 'dm-p-t-10 pad-right-30': 'dm-p-t-20'">
                                                            <span
                                                                *ngIf="!hasEditPermissions">{{actual.actualQuantity}}</span>
                                                            <ng-container *ngIf="hasEditPermissions">
                                                                <input
                                                                    id="{{project.name}}-{{projectIndex}}-{{task.name}}-{{taskIndex}}-consumed-quantity-{{actualIndex}}"
                                                                    min="0" max="actual.plannedQuantity" type="number"
                                                                    appTwoDigitDecimalPositiveNumber
                                                                    class="materialsTable__consumedQuantityInput text-align--right"
                                                                    [(ngModel)]="actual.actualQuantity"
                                                                    (ngModelChange)="onInputChange(actual, $event)"
                                                                    aria-label="enter consumed quantity" />
                                                                <div role="alert" *ngIf="actual.isConsumedQuantityExceeded"
                                                                    class="error-msg">
                                                                    *
                                                                    {{npcErrorMessages.consumedQuantityGreaterThanPlannedQuantity}}
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right dm-p-x-imp-0"
                                                            [ngClass]="hasEditPermissions ? 'dm-p-t-10': 'dm-p-t-20'">
                                                            <div class="actuals-datepicker text-align--right">
                                                                <dm-datepicker *ngIf="hasEditPermissions"
                                                                    [minDate]="minDate" [maxDate]="maxDate"
                                                                    [placeholder]="'dd-mm-yyyy'"
                                                                    [modelDate]="actual.consumedDate"
                                                                    ariaLabelButton="{{project.name}}-{{projectIndex}}-{{task.name}}-{{taskIndex}}-consumed-effective-date-{{actualIndex}}"
                                                                    ariaLabelCalendar="{{project.name}}-{{projectIndex}}-{{task.name}}-{{taskIndex}}-consumed-effective-date-{{actualIndex}}"
                                                                    (onDateChange)="onActualDateChange(actual, $event)"
                                                                    previosFousingElementId="{{project.name}}-{{projectIndex}}-{{task.name}}-{{taskIndex}}-consumed-quantity-{{actualIndex}}">
                                                                </dm-datepicker>
                                                                <span *ngIf="!hasEditPermissions">
                                                                    {{actual.consumedDate | date:'dd-MMM-yyyy'}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right dm-p-t-20">
                                                            {{actual.totalActualCost | dmDisplayCurrencyOrDash:
                                                            currencySymbol}}
                                                            <span *ngIf="!actual.totalActualCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right dm-p-t-20">
                                                            {{actual.billRate | dmDisplayCurrencyOrDash: currencySymbol}}
                                                            <span *ngIf="!actual.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--right dm-p-t-20">
                                                            {{actual.totalRevenue | dmDisplayCurrencyOrDash:
                                                            currencySymbol}}
                                                            <span *ngIf="!actual.totalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="materialsTable__bodyCell text-align--center dm-p-t-4">
                                                            <div class="display-grid">
                                                                <div class="dm-p-r-20">
                                                                    {{actual.consumedQuantityPercentage | number:'1.0-2'}} %
                                                                </div>
                                                                <ngb-progressbar type="success" [max]="100"
                                                                    [value]="actual.consumedQuantityPercentage">
                                                                </ngb-progressbar>
                                                            </div>
                                                        </div>
                                                        <div class="dm-p-y-10 status-icon-holder" role="gridcell">
                                                            <span *ngIf="actual.isUpdateLoading"
                                                                class="glyphicon glyphicon-refresh glyphicon-spin"></span>
                                                            <dm-tooltip role="tooltip" class="dm-tooltipinline"
                                                                *ngIf="(!actual.isUpdateLoading && actual.updateStatus === 'Error')"
                                                                [dmPlacement]="'left'"
                                                                [ngbTooltipContent]="actual.updateMessage"
                                                                [dmIcon]="'icon icon-warning'"
                                                                ariaLabelToolTip="not updated successfully">
                                                            </dm-tooltip>
                                                            <dm-tooltip role="tooltip" class="dm-tooltipinline"
                                                                *ngIf="(!actual.isUpdateLoading && actual.updateStatus === 'Success')"
                                                                [dmPlacement]="'left'"
                                                                [ngbTooltipContent]="actual.updateMessage"
                                                                [dmIcon]="'icon icon-check icon-rounded-outline'"
                                                                ariaLabelToolTip="updated successfully">
                                                            </dm-tooltip>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
            <section *ngIf="hasEditPermissions" class="dm-non-procured-materials__footer">
                <button class="dm-p-x-12-y-imp-0 fxp-btn fxp-btn--primary" (click)="onSaveChangesClick()"
                    [disabled]="this.saveBtnDisabled">
                    <span>Save Changes</span>
                </button>
            </section>
        </ng-container>
    </section>
</dm-tile>