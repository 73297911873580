import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";

import { UnsuspendModalComponent } from "./unsuspend-modal.component";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { RuleEngineService } from "../../../staffing-command-bar/staffing-command-bar-common/services/rule-engine.service";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";

@NgModule({
    declarations: [
        UnsuspendModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService,
        RuleEngineService,
        StaffingActionService
    ],
    entryComponents: [
        UnsuspendModalComponent
    ],
    exports: [UnsuspendModalComponent]
})
export class UnsuspendModalModule { }




