import { Component, Inject, forwardRef, OnInit, OnDestroy } from "@angular/core";
import { UserProfileService, UserInfoService } from "@fxp/fxpservices";
// All the Fxp services are available in npm package @fxp/fxpservices. This is how you import a FXP Service.

@Component({
    selector: "app-myapp-helloworld",
    templateUrl: "./hello-world.component.html",
    styleUrls: ["./hello-world.component.scss"]
})
export class HelloWorldComponent implements OnInit, OnDestroy {
    public UserName: string;
    public ProfileImage: string;
    public EngagementName: string;
    public currBpID: number;

    /* This is how you inject Fxp Service in a compoennt or Service.
    Please note for Fxp Services @Inject() and forwardRef() is mandatory. */
    public constructor(
        @Inject(forwardRef(() => UserProfileService)) public userProfileService: UserProfileService,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
    ) {
    }

    public ngOnInit(): void {
        this.currBpID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);

        // Method call to promised based method in FXP Service.
        this.userProfileService.getBasicUserProfile(null).then((data: any) => {
            this.UserName = data.firstName + " " + data.lastName;
        });
    }

    public ngOnDestroy(): void {
        /* Placeholder for testing. */
    }
}
