import moment from "moment";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";


export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
    public constructor(private momentFormat: string) {
        super();
    }

    /**
     * Formats the given date struct to the specific moment format (declared in the module). Offsets the
     * month correctly for the display. Overwrites the original ngb formatter.
     * @param date 
     */
    public format(date: NgbDateStruct): string {
        if (date === null) {
            return "";
        }
        const d = moment({
            year: date.year,
            month: date.month - 1,
            date: date.day
        });
        return d.isValid() ? d.format(this.momentFormat) : "";
    }

    /**
     * Parses a normal date string and returns the correct date structure, with the month offset correctly. 
     * Used to overwrite the original ngb parser.
     * @param value 
     */
    public parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }
        const d = moment(value, this.momentFormat);
        return d.isValid() ? {
            year: d.year(),
            month: d.month() + 1,
            day: d.date()
        } : null;
    }
}