import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SlideViewComponent } from "./slide-view.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        SlideViewComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        SlideViewComponent
    ],
    exports: [
        SlideViewComponent
    ],
})
export class SlideViewModule { }
