import { Component, Input, Inject, forwardRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import moment from "moment";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { IBillingMilestoneModel, IConfirmMilestonePatchData, IMilestoneDateModel, IMilestoneDateUpdateData } from "../../../common/services/contracts/dmmilestone.service.contract";
import { InvalidateMilestones } from "../../../store/milestones/milestones.action";
import { IState } from "../../../store/reducers";
import { LogEventConstants, SourceConstants, Components, AccessibilityConstants } from "../../../common/application.constants";
import { MilestonesService } from "../../../common/services/milestones.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { FormGroup, AbstractControl, Validators, FormBuilder, FormControl } from "@angular/forms";
import { DmError } from "../../../common/error.constants";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    selector: "dm-milestones-modal",
    templateUrl: "./milestones-modal.html",
    styleUrls: ["../milestones.scss"]
})
export class MilestonesModalComponent extends DmModalAbstract {
    @Input() public milestone: IBillingMilestoneModel;
    @Input() public isConfirm: boolean;
    @Input() public bpId: number;
    @Input() public isManage: boolean;
    @Input() public engagementContractSignatureDate: Date;
    public loadingText: string;
    public isDisplayLoading: boolean;
    public accessibilityConstants = AccessibilityConstants;
    public isUpdatingMilestoneDate: boolean;
    public disableEBSStartDateEndDateUpdates: boolean = false;
    public editManageMilestoneForm: FormGroup;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public isBeforeProjectStartDate: boolean = false;
    public minDate: Date;
    public maxDate: Date;
    public isComponentLoading: boolean = false;
    public isComponentLoadingManage: boolean = false;
    public milestoneDates: IMilestoneDateModel;
    public milestoneContractedDueDate: Date;
    public isMilestonePlannedCompletionDateValid: boolean = true;
    public isMilestoneActualCompletionDateValid: boolean = true;
    public isMilestoneActualCompletionPresentDate: boolean = true;
    public isUpdatingServiceProductFulfillmentDate: boolean;
    public isMilestonePlannedCompletionDatePristine: boolean = true;
    public isMilestoneActualCompletionDatePristine: boolean = true;
    public editMilestoneDateErrorMessages = DmError.MilestoneDateErrorMessages;
    public modalContent: IModal;
    private readonly FXP_CONSTANTS = FxpConstants;
    
    public get milestoneName(): AbstractControl {
        return this.editManageMilestoneForm.get("milestoneName");
    }
    public get milestoneBillingValue(): AbstractControl {
        return this.editManageMilestoneForm.get("milestoneBillingValue");
    }
    public get milestonePlannedCompletionDate(): AbstractControl {
        return this.editManageMilestoneForm.get("milestonePlannedCompletionDate");
    }
    public get milestoneActualCompletionDate(): AbstractControl {
        return this.editManageMilestoneForm.get("milestoneActualCompletionDate");
    }


    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(MilestonesService) private milestonesService: MilestonesService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(FormBuilder) private fb: FormBuilder
    ) {
        super(activeModal, dmLogger, Components.MilestoneConfirmationModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseRejectButton, true);
        if (!this.isManage) {
            this.loadingText = (this.isConfirm ? "Confirming" : "Unconfirming") + " Milestone";
        } else {
            this.loadingText = "Loading Milestone Dates";
        }
        this.isComponentLoading = true;
        this.editManageMilestoneForm = this.fb.group({
            milestoneName: new FormControl(null),
            milestoneBillingValue: new FormControl(null),
            milestonePlannedCompletionDate: new FormControl(null),
            milestoneActualCompletionDate: new FormControl(null)
        });

        if (this.isManage) {
            this.modalContent = {
                title: "Manage Milestone dates"
            };
            this.isComponentLoadingManage = true;
            this.milestonesService.getMilestoneDates(this.milestone.engagementId, this.milestone.billingPlanNumber, this.milestone.billingPlanItem.toString()).then((milestoneDatesResponse: IMilestoneDateModel) => {
                this.milestoneDates = milestoneDatesResponse;
                this.milestoneName.setValue(this.milestone.description);
                this.milestoneBillingValue.setValue(this.milestoneDates.billingValue.toString());
                this.milestoneContractedDueDate = this.milestoneDates.contractedDueDate;
                this.milestonePlannedCompletionDate.setValue(this.milestoneDates.plannedCompletionDate);
                this.milestoneActualCompletionDate.setValue(this.milestoneDates.actualCompletionDate);
                this.isComponentLoadingManage = false;
            });
        }
        this.setLoadersBasedOnItemState();
    }

    /**
     * Proceed to confirm or unconfirm the milestone.
     */
    public confirmContinue(): void {
        this.dmLogger.logEvent(SourceConstants.Component.MilestonesPage, SourceConstants.Method.ConfirmContinue, this.isConfirm ? LogEventConstants.MilestoneConfirmed : LogEventConstants.MilestoneUnconfirmed);
        this.isComponentLoading = true;
        const milestoneDetails: IConfirmMilestonePatchData = {
            bpId: this.bpId.toString(),
            engagementId: this.milestone.engagementId,
            billingPlanNumber: this.milestone.billingPlanNumber,
            milestoneNumber: this.milestone.billingPlanItem.toString(),
            setOrRemove: this.isConfirm
        };

        this.milestonesService.setConfirmOrUnconfirmMilestone(milestoneDetails)
            .then(() => {
                const event: string = this.isConfirm ? LogEventConstants.MilestonesConfirmBilling : LogEventConstants.MilestonesUnconfirmBilling;
                this.dmLogger.logEvent(SourceConstants.Component.MilestonesPage, SourceConstants.Method.ConfirmContinue, event);
                this.store.dispatch(new InvalidateMilestones(milestoneDetails.engagementId));
                this.fxpMessageService.addMessage(event + " for milestone " + this.milestone.description, this.FXP_CONSTANTS.messageType.success);
                this.closeModal();
            })
            .catch((error: any) => {
                const event: string = this.isConfirm ? "confirm" : "unconfirm";
                const errorMessage: string = "Failed to " + event + " milestone " + this.milestone.description;
                this.fxpMessageService.addMessage(errorMessage + "; Error: " + error, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.ConfirmContinue, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.closeModal();
            });

    }

    /**
     * Proceed to confirm or unconfirm the milestone.
    */
    public confirmMilestoneDates(): void {
        this.dmLogger.logEvent(SourceConstants.Component.MilestonesPage, SourceConstants.Method.ConfirmContinue, this.isConfirm ? LogEventConstants.MilestoneConfirmed : LogEventConstants.MilestoneUnconfirmed);
        this.isComponentLoadingManage = true;
        
        
        const milestoneDateUpdateDetails: IMilestoneDateUpdateData = {};
        milestoneDateUpdateDetails.bpId = this.bpId.toString();
        milestoneDateUpdateDetails.engagementId = this.milestone.engagementId;
        milestoneDateUpdateDetails.billingPlanNumber = this.milestone.billingPlanNumber;
        milestoneDateUpdateDetails.billingItemNumber = this.milestone.billingPlanItem.toString();
        if (this.milestonePlannedCompletionDate && this.milestonePlannedCompletionDate.value) {
            milestoneDateUpdateDetails.plannedCompletionDate = moment(this.milestonePlannedCompletionDate.value as Date).format(DATE_FORMAT);
        }
        if (this.milestoneActualCompletionDate && this.milestoneActualCompletionDate.value) {
            milestoneDateUpdateDetails.actualCompletionDate = moment(this.milestoneActualCompletionDate.value as Date).format(DATE_FORMAT);
            milestoneDateUpdateDetails.actionType = "C";
            this.isConfirm = true;
        } else {
            if (this.milestone.status === "Pending") {
                milestoneDateUpdateDetails.actionType = "";
            } else if (this.milestone.status === "Submitted") {
                milestoneDateUpdateDetails.actionType = "U";
            }
            this.isConfirm = false;
        }
        
        this.milestonesService.updateMilestoneDates(milestoneDateUpdateDetails, this.milestone.engagementId)
            .then(() => this.handleMilestoneUpdateSuccess())
            .catch((error: any) => this.handleMilestoneUpdateError(error));
    }

    /**
     * Capturing emitted plannedCompletionDate value
     * @param plannedCompletionDate
    */
    public onMilestonePlannedCompletionDateChange(plannedCompletionDate: Date): void {
        this.milestonePlannedCompletionDate.setValue(plannedCompletionDate);
        if (moment(this.milestonePlannedCompletionDate.value).isBefore(this.engagementContractSignatureDate)) {
            this.isMilestonePlannedCompletionDateValid = false;
            return;
        }
        this.isMilestonePlannedCompletionDateValid = true;
    } 

    /**
     * Capturing emitted actualCompletionDate value
     * @param actualCompletionDate
    */
    public onMilestoneActualCompletionDateChange(actualCompletionDate: Date): void {
        this.milestoneActualCompletionDate.setValue(actualCompletionDate);
        const currentDate = new Date();
        if (moment(this.milestoneActualCompletionDate.value).isAfter(currentDate)) {
            this.isMilestoneActualCompletionPresentDate = false;
            return;
        }
        this.isMilestoneActualCompletionPresentDate = true;
        if (moment(this.milestoneActualCompletionDate.value).isBefore(this.engagementContractSignatureDate)) {
            this.isMilestoneActualCompletionDateValid = false;
            return;
        }
        this.isMilestoneActualCompletionDateValid = true;
    } 

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     */
    public saveMilestoneDateButtonDisabled(): boolean {
        // this.isMilestonePlannedCompletionDatePristine = moment(this.milestoneDates.plannedCompletionDate).isSame(this.milestonePlannedCompletionDate.value);
        // this.isMilestoneActualCompletionDatePristine = moment(this.milestoneDates.actualCompletionDate).isSame(this.milestoneActualCompletionDate.value);
        // const isFormPristine: boolean = this.isMilestonePlannedCompletionDatePristine && this.isMilestoneActualCompletionDatePristine;

        return !this.isMilestoneActualCompletionPresentDate || !this.isMilestonePlannedCompletionDateValid || !this.isMilestoneActualCompletionDateValid;
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseRejectButton);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }
    
    private handleMilestoneUpdateSuccess(): void {
        const event: string = LogEventConstants.MilestonesDateConfirmation;
        this.dmLogger.logEvent(SourceConstants.Component.MilestonesPage, SourceConstants.Method.ConfirmMilestoneDatesSave, event);
        this.store.dispatch(new InvalidateMilestones(this.milestone.engagementId));
        this.fxpMessageService.addMessage(event + " for milestone " + this.milestone.description, this.FXP_CONSTANTS.messageType.success);
        this.isComponentLoadingManage = false;
        this.closeModal();
    }
    
    private handleMilestoneUpdateError(error: any): void {
        const event: string = LogEventConstants.MilestonesDateConfirmation;
        const errorMessage: string = "Failed to " + event + " milestone " + this.milestone.description;
        this.fxpMessageService.addMessage(errorMessage + "; Error: " + error, this.FXP_CONSTANTS.messageType.error);
        this.logError(SourceConstants.Method.ConfirmMilestoneDatesSave, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        this.isComponentLoadingManage = false;
        this.closeModal();
    }
}
