export interface IProgressBar {
    status: ProgressBarStatus;
    content: string;
}

export enum ProgressBarStatus {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
    Failed = "Failed",
    Warning = "Warning",
}