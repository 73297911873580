import { Action } from "@ngrx/store";
import { IWbsDemand } from "./../../common/services/contracts/project.service.v2.contracts";

// 1. action types
export enum DemandsWithSchedulesActionTypes {
    LOAD_DEMANDSWITHSCHEDULES = "[DemandsWithSchedules] Load",
    LOAD_DEMANDSWITHSCHEDULES_SUCCESS = "[DemandsWithSchedules] Load Success",
    LOAD_DEMANDSWITHSCHEDULES_FAIL = "[DemandsWithSchedules] Load Fail",
    INVALIDATE_DEMANDSWITHSCHEDULES = "[DemandsWithSchedules] Invalidate"
}

// 2. action creators
export class LoadDemandsWithSchedules implements Action {
    public readonly type = DemandsWithSchedulesActionTypes.LOAD_DEMANDSWITHSCHEDULES;
    public constructor(public wbsId: string) { }
}

export class LoadDemandsWithSchedulesSuccess implements Action {
    public readonly type = DemandsWithSchedulesActionTypes.LOAD_DEMANDSWITHSCHEDULES_SUCCESS;
    public constructor(public wbsId: string, public wbsDemands: IWbsDemand) { }
}

export class LoadDemandsWithSchedulesFail implements Action {
    public readonly type = DemandsWithSchedulesActionTypes.LOAD_DEMANDSWITHSCHEDULES_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateDemandsWithSchedules implements Action {
    public readonly type = DemandsWithSchedulesActionTypes.INVALIDATE_DEMANDSWITHSCHEDULES;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type DemandsWithSchedulesActions =
    | LoadDemandsWithSchedules
    | LoadDemandsWithSchedulesSuccess
    | LoadDemandsWithSchedulesFail
    | InvalidateDemandsWithSchedules;