import { Component, Inject, forwardRef } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { StateService } from "@uirouter/angular";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";


@Component({
    selector: "dm-engagement-financial",
    templateUrl: "./engagement-financial.html",
    styleUrls: ["./engagement-financial.scss"]
})
export class EngagementFinancialComponent extends DmComponentAbstract {

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(dmLogger, Components.EngagementFinancials, [            
            { component: Components.FinancialNavigation, isCritical: true },
            { component: Components.FinancialsApprovals, isCritical: true },
            { component: Components.FinancialSummary, isCritical: true },
            { component: Components.FinancialSummaryDetails, isCritical: true },
            { component: Components.FinancialPlan, isCritical: true },
            { component: Components.KeyIndicators, isCritical: true }
        ]);
    }

    public ngOnInit(): void {
        /// Currently nothing to do here
    }
}
