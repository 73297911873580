import { Component, forwardRef, Inject } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Components, RouteName, KeyAction, FeedBackEntity, InlineSurveyDataList, SourceConstants } from "../../common/application.constants";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { getEntireUserPreference } from "../../store/userspreferences/userpreference.selector";
import { IUserPreferenceState } from "../../store/userspreferences/userpreference.reducer";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IState } from "../../store/reducers";
import { Store } from "@ngrx/store";
import { FeedbackModalService } from "../tiles/feedback-modal/feedback-modal.service";

@Component({
    selector: "dm-project-summary-v2",
    styleUrls: ["./project-summary-v2.scss"],
    templateUrl: "./project-summary-v2.html"
})
export class ProjectSummaryV2Component extends DmComponentAbstract {

    public contextTile = "ProjectDetail";
    public isDesktopView: boolean;
    public isTabletView: boolean;
    public isSmallScreenView: boolean;
    public RouteName = RouteName;
    public listOfKeyActions: string[] = [];
    public currentRouteName: string;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService
    ) {
        super(dmLogger, Components.ProjectSummaryV2, [
            { component: Components.EntitySummaryDetails, isCritical: true },
            { component: Components.ProjectSummaryFinancialsV2, isCritical: true },
            { component: Components.SummaryFinancialsBreakdownComponent, isCritical: true },
            { component: Components.ProjectSummaryServicesV2, isCritical: true },
            { component: Components.SummaryInvoicesV2, isCritical: true },
            { component: Components.SummaryPurchaseOrdersV2, isCritical: true },
            { component: Components.SummaryKeyLinksV2, isCritical: true },
            { component: Components.SummaryContactsV2, isCritical: true },
        ]);
    }

    public ngOnInit(): void {
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isTabletView = this.deviceFactory.isTablet();
        this.isSmallScreenView = this.deviceFactory.isSmallScreen();
        this.configManagerService.initialize();
        this.listOfKeyActions.push(KeyAction.PendingExpenseReportsBasedOnEntityId, KeyAction.PendingLaborBasedOnAssignments, KeyAction.PendingMilestonesForConfirmation, KeyAction.EngagementStartDateLessContractStartDate);
        const userPreference$ = this.store.select((getEntireUserPreference()));
        userPreference$
            .pipe(untilDestroyed(this))
            .subscribe((userPreferenceState: IUserPreferenceState) => {
                this.currentRouteName = this.stateService.current.name;
                if (userPreferenceState.loaded && this.currentRouteName.toLowerCase() === (RouteName.ProjectSummaryV2.toLowerCase())) {
                    let isSummaryV2FeedbackSubmitted: boolean = false;
                    const feedbackDetails =
                            userPreferenceState.userPreference.feedbackList;
                    if (
                        feedbackDetails &&
                            feedbackDetails.length > 0 &&
                            feedbackDetails.filter(
                                (obj) => obj.key === FeedBackEntity.SummaryV2
                            ).length > 0
                    ) {
                        isSummaryV2FeedbackSubmitted = feedbackDetails.filter(
                            (obj) => obj.key === FeedBackEntity.SummaryV2
                        )[0].value;
                    }
                    if (
                        !isSummaryV2FeedbackSubmitted &&
                            this.sharedFunctionsService.shouldLaunchFeedbackModal(
                                FeedBackEntity.SummaryV2
                            )
                    ) {
                        this.feedbackModalService.openFeedbackModal(
                            InlineSurveyDataList.SummaryV2Survey,
                            FeedBackEntity.SummaryV2,
                            SourceConstants.Component.ProjectSummaryV2Page
                        );
                    }
                }
            });
    }
}
