<section id="engagement-reco-prevalidation">
    <dm-loading loader [loadingText]="'Validating items...'" [isCustomText]="true" [showLoading]="showLoading"
        *ngIf="isComponentLoading">
    </dm-loading>
    <section *ngIf="!isComponentLoading" role="grid">
        <fxp-shared-component-control appname="SampleApplication"
            componentname="sampleapplication-recovalidatorcomponent" [componentinputs]="componentinputs"
            getcallbacks="onCancel,onSubmit,onRefresh" (oncallback)="onRecoComponentCallback($event)">
        </fxp-shared-component-control>
    </section>
</section>