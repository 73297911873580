import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { MilestonesModalComponent } from "./milestones-modal.component";
import { MilestonesService } from "../../../common/services/milestones.service";
import { DmModalV2Module } from "../../modals/dm-modal-v2/dm-modal-v2.module";

@NgModule({
    declarations: [
        MilestonesModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        DmModalV2Module
    ],
    providers: [
        DMLoggerService,
        MilestonesService
    ],
    exports: [
        MilestonesModalComponent
    ],
    entryComponents: [
        MilestonesModalComponent
    ]
})
export class MilestonesModalModule { }
