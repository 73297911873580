import { forwardRef, Inject, Component, SimpleChanges, OnChanges, Input, Injector, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";
import { combineLatest as observableCombineLatest } from "rxjs";
import { ConfigManagerService } from "../../../../../../common/services/configmanager.service";
import { CustomerEngagementFinancialsModal } from "../financial-modal/customer-engagement-financials-modal.component";
import { DelegationDetailsModalComponent } from "../../../../../tiles/delegation-details-modal/delegation-details-modal.component";
import { DmComponentAbstract } from "../../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { getEntireUserPreference } from "../../../../../../store/userspreferences/userpreference.selector";
import { getMyPortfolioEngagementListState } from "../../../../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IContractType } from "../../../../../../common/services/contracts/project.service.contracts";
import { IDelegationViewModel } from "../../../../../engagement-detail/engagement.model";
import { IEngagementList, IProjectList, IDelegationPortFolioDetails } from "../../../../../../common/services/contracts/portfolio.model.contract";
import { IMyPortfolioEngagementListState } from "../../../../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IState } from "../../../../../../store/reducers";
import { IUserPreference } from "../../../../../../common/services/contracts/userpreference.contract";
import { IUserPreferenceState } from "../../../../../../store/userspreferences/userpreference.reducer";
import { PaginationV2Component } from "../../../pagination-v2/dm-pagination-v2.component";
import { RouteName, Components, TooltipText, DelegationTitleText, NoDataText, ComponentFailureMessages, LogEventName, SourceConstants, EngagementType, LogEventConstants, DestinationPage, SortColumnName, ItemCount, AccessibilityConstants } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UpdateUserPreference } from "../../../../../../store/userspreferences/userpreference.action";
import { UserPreferenceService } from "../../../../../../common/services/userpreferences.service";

@Component({
    selector: "dm-customer-engagement-grid-data-overview-tab",
    templateUrl: "./customer-engagement-grid-data-overview-tab.html",
    styleUrls: ["./customer-engagement-grid-data-overview-tab.scss"]
})
export class CustomerEngagementGridDataOverviewTab extends DmComponentAbstract implements OnChanges {
    @ViewChild(PaginationV2Component, { static: false }) public paginationV2Component: PaginationV2Component;
    @Input() public viewby: string;
    public userPreference: IUserPreference;
    public currentPage: number = 1;
    public ebsStateText: string;
    public engagementList: IEngagementList[];
    public engConfidentialText: string;
    public gridItemsDisplay: number = 5;
    public portfolioItemsDisp: number = 5;
    public hidePinnedColumn: boolean;
    public isPubSecEnabled: boolean;
    public isSortASC: boolean = false;
    public isViewby: boolean = true;
    public noEngagementFoundText: string;
    public projConfidentialText: string;
    public projectList: IProjectList[];
    public pubSecText: string;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public selectedProjects: IProjectList[];
    public slicedItemsNumber: number = 0;
    public sortState: string;
    public typeOfContracts: IContractType[];
    public isMobileView: boolean;
    public isDesktopView: boolean;
    public isTabletView: boolean;
    public delegationData: IDelegationViewModel;
    public delegationTitleText = DelegationTitleText;
    public showFinancialsTab: boolean;
    public sortOrder: string;
    public tableCaption: string = "Customer Delivery Engagements Overview Details";
    public highlightText: string;
    public loadingText: string = "Engagements";
    public columnHeaderTitleorAriaLabel: string = "";
    public delegationTitleorAriaLabel: string = "";
    public itemsPerPage: number[] = [5, 10, 15];
    public isPaginationRequired: boolean = false;
    public showEngagementsExpanded: boolean = false;
    public LogEventName = LogEventName;
    public accessibilityConstants = AccessibilityConstants;
    public noEngagemmentsOrProjects = NoDataText.NoEngagementsOrProjects;

    /**
     * Creates an instance of GridDataComponent.
     * @param {DeviceFactoryProvider} deviceFactory
     * @param {SettingsServiceProvider} settingsService
     * @param {UserInfoService} fxpUserInfoService
     * @param {FxpMessageService} fxpMessageService
     * @param {SharedFunctionsService} sharedFunctionsService
     * @param {ProjectService} projectService
     * @param {DMLoggerService} dmLogger
     * @param {ConfigManagerService} configmanagerService
     * @param {NgbModal} modalService
     * @memberof GridDataComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Injector) private injector: Injector,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
    ) {
        super(dmLogger, Components.PortfolioGridDataOverviewTab);

    }

    public ngOnInit(): void {
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isMobileView = this.deviceFactory.isMobile();
        this.isTabletView = this.deviceFactory.isTablet();
        this.setTooltipText();
        this.configmanagerService.initialize().then(() => {
            this.errorText = ComponentFailureMessages.MyPortfolioGridData;
            this.typeOfContracts = this.configmanagerService.getValue<IContractType[]>("projEngContractType");
            this.noEngagementFoundText = NoDataText.NoEngagements;
            this.isPubSecEnabled = this.configmanagerService.isFeatureEnabled("Pubsec");
            this.hidePinnedColumn = this.configmanagerService.isFeatureEnabled("hidePinnedEntitiesInPortfolio");
            this.showFinancialsTab = this.configmanagerService.isFeatureEnabled("showFinancialsTabPortfolio");

            const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
            const userPreference$ = this.store.select((getEntireUserPreference()));

            this.storeDispatchService
                .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
                .load();

            observableCombineLatest(
                myPortfolioEngagementList$,
                userPreference$,
                (
                    engagementList: IMyPortfolioEngagementListState,
                    userPreferenceState: IUserPreferenceState,
                ) => ({
                    engagementList,
                    userPreferenceState,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    engagementList,
                    userPreferenceState,
                }) => {
                    if (engagementList.loaded && userPreferenceState.loaded) {
                        this.engagementList = engagementList.engagementList.filter((x: IEngagementList) => x.type.toLowerCase() === "engagement");
                        this.projectList = [];
                        for (const engagement of this.engagementList) {
                            engagement.isExpand = false;
                            this.projectList = this.projectList.concat(engagement.projects);
                        }
                        this.userPreference = userPreferenceState.userPreference;
                        this.showEngagementsExpanded = this.userPreference.customerEngagements.showEngagementsExpanded;
                        this.engagementList.map((engagement) => engagement.isExpand = !this.showEngagementsExpanded);
                        this.isPaginationRequired = this.paginationRequired();
                        this.portfolioItemsDisp = Number(this.userPreference.customerEngagements.pageSize);
                        this.currentPageChangedHandler(this.currentPage);
                    }
                    this.setLoadersBasedOnItemState(engagementList);
                    this.setErrorsBasedOnItemState(engagementList);
                });
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (propName === "viewby" && changes[propName].previousValue) {
                this.currentPage = 1;
                if (changes[propName].previousValue.toLowerCase() !== changes[propName].currentValue.toLowerCase()) {
                    this.portfolioItemsDisp = Number(this.userPreference.customerEngagements.pageSize);
                    this.isPaginationRequired = this.paginationRequired();
                    if (this.paginationV2Component) {
                        this.paginationV2Component.itemsDisplay(this.portfolioItemsDisp);
                    }
                }
                this.currentPageChangedHandler(this.currentPage);
            } else if (propName === "userPreference" && this.userPreference) {
                this.portfolioItemsDisp = Number(this.userPreference.customerEngagements.pageSize);
                if (this.paginationV2Component) {
                    this.paginationV2Component.itemsDisplay(this.portfolioItemsDisp);
                }
            }
        }
    }

    /**
     * Changes the current page, grid items to display and sliced number on changing the page
     *
     * @param {number} currentPageValue
     * @memberof GridDataComponent
     */
    public currentPageChangedHandler(currentPageValue: number): void { // todo consider moving this into a shared function.
        const totalLength = (this.viewby === "all" || this.viewby === "engagements") ? this.engagementList.length : this.projectList.length;
        const lastPage = Math.ceil(totalLength / this.portfolioItemsDisp);
        if (currentPageValue === 1) {
            this.slicedItemsNumber = 0;
            this.gridItemsDisplay = this.portfolioItemsDisp;
        } else if (this.currentPage < currentPageValue && currentPageValue !== lastPage) {
            this.slicedItemsNumber = this.gridItemsDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay + this.portfolioItemsDisp;
        } else if (currentPageValue === lastPage) {
            this.slicedItemsNumber = (lastPage - 1) * this.portfolioItemsDisp;
            this.gridItemsDisplay = totalLength;
        } else {
            let displayValue = this.portfolioItemsDisp;
            if ((this.gridItemsDisplay - this.slicedItemsNumber) < this.portfolioItemsDisp) {
                displayValue = this.gridItemsDisplay - this.slicedItemsNumber;
            }
            this.slicedItemsNumber = this.slicedItemsNumber - this.portfolioItemsDisp;
            this.gridItemsDisplay = this.gridItemsDisplay - displayValue;
        }
        this.currentPage = currentPageValue;
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.CurrentPageChangedHandler, LogEventConstants.PortfolioPaginationPageChanged);
    }

    /**
     * Fetches the item and assigning it to grid
     *
     */
    public itemPerPageChangedHandler(itemCount: string): void {
        const propertyBag = {};
        propertyBag[ItemCount] = itemCount;
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.ItemPerPageChangedHandler, LogEventName.PortfolioCustomerEngagementsPaginationSet, propertyBag);
        this.gridItemsDisplay = Number(itemCount);
        if (this.userPreference) {
            this.userPreference.customerEngagements.pageSize = itemCount;
            this.saveUserPreference(this.userPreference);
        }
    }

    /**
     * Sets the selected projects to view on a non-desktop view when the screen cannot show all projects at once
     *
     * @param {IProjectList[]} projects
     * @memberof GridDataComponent
     */
    public setSelectedProjects(projects: IProjectList[]): void {
        this.selectedProjects = projects;
    }

    /**
     * Make dropdowns list items accessibile with Keyboard Up/Down Arrow Keys for both Engagement and Project
     * @param type 
     * @param Id 
     * @param action 
     */
    public moveFocus(type: string, id: string, action: string): void {
        const dropdownId = "dm-grid-" + type + "-moreoptions" + id + action;
        this.sharedFunctionsService.focus(dropdownId, true);
    }

    /**
     * Opens the delegation modal for the specific project and its delegation information
     *
     * @param {IDelegationPortFolioDetails} project
     * @param {string} delegatedType
     * @memberof GridDataComponent
     */
    public openDelegationModal(project: IDelegationPortFolioDetails, delegatedType: string): void {
        const newModalInstance = this.modalService.open(DelegationDetailsModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        newModalInstance.componentInstance.delegationDetails = project;
        newModalInstance.componentInstance.delegationType = delegatedType;
    }

    /**
     * Sort the Engagements based on the column selected and order designated
     * First click on the button will make it sort in ascending order. Consecutive second click will make it sort in descending order
     * @param {string} selectedColumn
     * @public
     * @memberof GridDataComponent
     */
    public sortData(selectedColumn: string): void {
        if (!this.engagementList || !selectedColumn) {
            return;
        }

        // Determine sort in ascending order or descending order
        this.isSortASC = (this.sortState === selectedColumn) ? !this.isSortASC : true;
        this.sortState = selectedColumn;
        this.sortOrder = this.isSortASC ? "Ascending" : "Descending";
        this.tableCaption = "Customer Delivery Engagements Overview Details sorted by " + this.sortState + " in " + this.sortOrder + " order.";

        // sort the engagement array with ascending order
        this.engagementList = this.engagementList.sort(this.sharedFunctionsService.sortEntitiesByColumnName(this.getFieldNameForEngagement(selectedColumn)));

        // sort the project array within each engagement array
        this.engagementList.forEach((engagement) => {
            engagement.projects = engagement.projects.sort(this.sharedFunctionsService.sortEntitiesByColumnName(this.getFieldNameForProject(selectedColumn)));

            if (!this.isSortASC) {
                engagement.projects = engagement.projects.reverse();
            }
        });

        if (!this.projectList) {
            return;
        }

        // sort the project array for project only view.
        this.projectList = this.projectList.sort(this.sharedFunctionsService.sortEntitiesByColumnName(this.getFieldNameForProject(selectedColumn)));

        // reverse the array if descending order is desired
        if (!this.isSortASC) {
            this.engagementList = this.engagementList.reverse();
            this.projectList = this.projectList.reverse();
        }

        if (this.sortState) {
            this.columnHeaderTitleorAriaLabel = this.sortState + " sorted in " + this.sortOrder + " order";
        }
        const propertyBag = {};
        propertyBag[SortColumnName] = selectedColumn;
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.SortData, LogEventConstants.SortEngagementsList, propertyBag);
    }

    /**
     * Close Engagement/Project Dropdown On Tab of Last Element
     *
     */
    public closeDropdownOnTab(id: string): void {
        document.getElementById(id).classList.remove("open");
    }

    /**
     * Sets the title or aria-label for drop down
     */
    public setTitleOrAriaLabelForDropDown(dropdownType: string, id: string): string {
        return dropdownType + " Dropdown " + id;
    }

    /**
     * Sets the title or aria-label for financials overview
     */
    public setTitleOrAriaLabelForFinancialOverview(engagementId: string): string {
        return "Financial Overview for Customer Engagement " + engagementId;
    }

    /**
     * Sets the title or aria-label for customer engagement grid column headers
     */
    public setTitleOrAriaLabelForColumnHeader(columnName: string): string {
        if (this.sortState && (this.sortState.toLowerCase() === columnName.toLowerCase())) {
            return this.columnHeaderTitleorAriaLabel;
        } else {
            return "Sort by " + columnName;
        }
    }

    /**
     * Sets the title or aria-label on collapsing and expanding customer engagements
     */
    public setTitleOrAriaLabelForExpandCollapseEngagement(isEngagementExpanded: boolean, engagementId: string): string {
        return (isEngagementExpanded ? "Collapse " : "Expand ") + "Customer Engagement " + engagementId + " Section";
    }

    /**
     * Checks whether to render delegation button and
     * sets the title for delegation icon.
     */
    public isShowDelegation(delegationData: IDelegationViewModel, delegatedType: string): boolean {
        if (delegationData) {
            this.delegationTitleorAriaLabel = delegationData.delegationType + delegationData.delegationFullName;
            return delegationData.delegationTitle.toLowerCase() === delegatedType.toLowerCase();
        }
        return false;
    }

    /**
     * Logs event when the dropdown menu for a specfic 
     * engagement item on the engagement list is used
     */
    public logMoreOptionsClick(): void {
        const propertyBag = {};
        propertyBag[EngagementType] = "Customer";
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogMoreOptionsClick, LogEventConstants.SpecificEngagementMoreOptionsClick, propertyBag);
    }

    /**
     * Logs event when the expand/collapse arrow for a specfic 
     * engagement item on the engagement list is used
     */
    public logExpandCollapseClick(isExpand: boolean): void {
        const propertyBag = {};
        propertyBag[EngagementType] = "Customer";
        if (isExpand) {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogExpandCollapseClick, LogEventConstants.SpecificEngagementExpand, propertyBag);
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogExpandCollapseClick, LogEventConstants.SpecificEngagementCollapse, propertyBag);
        }

    }
    /**
     * Logs event when user navigates to an engagement page 
     * using the engagement list on the portfolio page
     */
    public logEngagementSelect(destination: string): void {
        const propertyBag = {};
        propertyBag[DestinationPage] = destination;
        propertyBag[EngagementType] = "Customer";
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogEngagementSelect, LogEventConstants.EngagementNavigation, propertyBag);
    }

    /**
     * Open Fiancials Modal Overview
     *
     */
    public loadFinancialOverview(engagementDetails: IEngagementList): void {
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LoadFinancialOverview, LogEventName.PortfolioCustomerFinancialSummary);
        const newModalInstance = this.modalService.open(CustomerEngagementFinancialsModal, {
            backdrop: "static",
            windowClass: "dm-modal in active customer-engagement-financials-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        newModalInstance.componentInstance.engagementDetails = engagementDetails;
    }

    /**
     * For expanding/collapsing all the Engagements
     */
    public expandCollapseEngagements(): void {
        this.showEngagementsExpanded = !this.showEngagementsExpanded;
        this.engagementList.map((engagement) => engagement.isExpand = !engagement.isExpand);
        this.userPreference.customerEngagements.showEngagementsExpanded = this.showEngagementsExpanded;
        this.saveUserPreference(this.userPreference);
        const propertyBag = {};
        propertyBag[EngagementType] = "Customer";
        if (this.showEngagementsExpanded) {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.ExpandCollapseEngagements, LogEventConstants.EngagementListExpanded, propertyBag);
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.ExpandCollapseEngagements, LogEventConstants.EngagementListCollapsed, propertyBag);
        }
    }


    /**
     * Get the sorting field variable for engagements by the label name of click action
     *
     * @param {string} selectedColumn
     * @memberof GridDataComponent
     */
    private getFieldNameForEngagement(selectedColumn: string): string {
        switch (selectedColumn) {
            case "Name":
                return "engagementName";
            case "EBS State":
                return "ebsState";
            case "Customer":
                return "customerName";
            case "Start Date":
                return "startDate";
            case "End Date":
                return "endDate";
            case "Contract Type":
                return "typeOfContract";
            case "Domain Project Manager":
                return "pPjMName";
        }
    }

    /**
     * Get the sorting field variable for projects by the label name of click action
     *
     * @param {string} selectedColumn
     * @memberof GridDataComponent
     */
    private getFieldNameForProject(selectedColumn: string): string {
        switch (selectedColumn) {
            case "Name":
                return "projectName";
            case "EBS State":
                return "ebsState";
            case "Customer":
                return "customerName";
            case "Start Date":
                return "startDate";
            case "End Date":
                return "endDate";
            case "Contract Type":
                return "typeOfContract";
            case "Domain Project Manager":
                return "pjMName";
        }
    }

    /**
     * set text in tooltip
     *
     * @private
     * @memberof GridDataComponent
     */
    private setTooltipText(): void {
        this.engConfidentialText = "This engagement is marked as confidential.";
        this.projConfidentialText = "This project is marked as confidential.";
        this.pubSecText = "Pub Sec";
        this.ebsStateText = TooltipText.EBSState;
    }

    /**
     * validating to call pagination component
     *
     */
    private paginationRequired(): boolean {
        const listForPagination = (this.viewby === "all" || this.viewby === "engagements") ? this.engagementList : this.projectList;
        if (listForPagination && listForPagination.length > this.itemsPerPage[0]) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Saves UsersPreference     
     */
    private saveUserPreference(userPreference: IUserPreference): void {
        this.userPreferenceService.saveUserPreference(userPreference).then((response: IUserPreference) => {
            this.store.dispatch(new UpdateUserPreference(response));
        });
    }

}
