import { Component, Input, Inject, forwardRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";

import { Components, SourceConstants, LogEventConstants, AccessibilityConstants } from "../../../../common/application.constants";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { ProjectServiceV2 } from "../../../../common/services/project.v2.service";
import { IUserPreferenceLinksViewModel } from "../../../../common/services/contracts/userpreferencelinksmgmt.contract";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { ICustomLink, IWbsSettings } from "../../../../common/services/contracts/project.service.v2.contracts";
import { DmError } from "../../../../common/error.constants";

@Component({
    selector: "user-preference-links-mgmt-modal",
    templateUrl: "./user-preference-links-mgmt-modal.component.html",
    styleUrls: ["./user-preference-links-mgmt-modal.component.scss"],
})

export class UserpreferencelinksMgmtModalComponent extends DmComponentAbstract { // todo this should be dmModal instead of component
    @Input() public userPreferenceLinks: ICustomLink[];
    @Input() public wbsId: string;

    /* Regex for url validation checks for http or https and a dot symbol */
    public urlValidationPattern = "https?://.+";
    public isAddLinksDisabled: boolean = false;
    public isUrlFieldInFocus: boolean[] = [];
    public isUserlinksLoading: boolean = false;
    public showValidationMessage: boolean = false;
    public validationMessageText: string;
    public statusMessage: string;
    public usersLinkName: string[] = [];
    public accessibilityConstants = AccessibilityConstants;
    public userPreferenceLinksErrorMessages = DmError.UserPreferenceLinks;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(dmLogger, Components.StaffingCommandBarSplitPlannedModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        if (this.userPreferenceLinks && this.userPreferenceLinks.length === 0) {
            this.addLinkEmptyRows();
        }
    }

    /**
     * Close Modal Popup
     * @memberof UserpreferencelinksMgmtModelComponent
     */
    public closeModalPopUp(reload: boolean = false): void {
        this.userPreferenceLinks = [];
        this.activeModal.close(reload);
    }

    /**
     * Add link -- add empty rows to the object
     * @memberof UserpreferencelinksMgmtModelComponent
     */
    public addLinkEmptyRows(): void {
        this.isAddLinksDisabled = false;
        if (this.userPreferenceLinks.length < 7) {
            const userLink: ICustomLink = {
                name: "",
                url: ""
            };
            this.userPreferenceLinks.length === 0 ? userLink.id = 1 : userLink.id = this.userPreferenceLinks[this.userPreferenceLinks.length - 1].id + 1;
            this.userPreferenceLinks.push(userLink);
        } else {
            this.isAddLinksDisabled = true;
        }
    }

    /**
     * Save Links
     * @memberof UserpreferencelinksMgmtModelComponent
     */
    public saveUserPreferenceLinks(): void {
        /// Pending
        /// Check for Validations if any        
        this.showValidationMessage = false;
        if (this.userPreferenceLinks.length > 0) {
            this.userPreferenceLinks.forEach((link) => {
                if (!link.url || !link.name) {
                    if (this.userPreferenceLinks.length === 1) {
                        this.validationMessageText = DmError.UserPreferenceLinks.TheEntryHasAnInvalidURLOrName;
                    } else {
                        this.validationMessageText = DmError.UserPreferenceLinks.OneOfTheEntriesHasAnInvalidURLOrName;
                    }
                    this.showValidationMessage = true;
                    return;
                }
            });
        }
        if (!this.showValidationMessage) {
            this.isUserlinksLoading = true;
            this.projectServiceV2.getWbsSettings(this.wbsId).then((response: IWbsSettings) => {
                const wbsSettings = response;
                wbsSettings.customLinks = this.userPreferenceLinks;
                this.projectServiceV2.saveWbsSettings(wbsSettings).then(() => {
                    this.fxpMessageService.addMessage("Successfully saved links", "success", false);
                    this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.SaveUserPreferenceLinks, LogEventConstants.CustomLinkSubmit);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(true);
                }).catch((error) => {
                    this.fxpMessageService.addMessage(DmError.UserPreferenceLinks.UnableToSaveLinks, "error", true);
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, DmError.UserPreferenceLinks.UnableToSaveLinks, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(false);
                });
            }).catch((error) => {
                if (error.status === 404) {
                    const wbsSettings = this.projectServiceV2.getDefaultWbsSettings(this.wbsId);
                    wbsSettings.customLinks = this.userPreferenceLinks;
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, "Successfully saved link but 404 status", ErrorSeverityLevel && ErrorSeverityLevel.Medium);
                    this.projectServiceV2.saveWbsSettings(wbsSettings).then(() => {
                        this.fxpMessageService.addMessage("Successfully saved links", "success", false);
                        this.isUserlinksLoading = false;
                        this.closeModalPopUp(true);
                    });

                } else {
                    this.fxpMessageService.addMessage(DmError.UserPreferenceLinks.UnableToSaveLinks, "error", true);
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, DmError.UserPreferenceLinks.UnableToSaveLinks, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(false);
                }
            });
        }
    }

    /**
     * Delete Links -Soft Delete will mark the item in deleted state later for submission
     * @memberof UserpreferencelinksMgmtModelComponent
     */
    public deleteUserPreferenceLinks(link: ICustomLink): void {
        this.userPreferenceLinks = this.userPreferenceLinks.filter((obj) => !(obj.id === link.id));
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Set alert message readable for screen readers on deletion of link
     * @param action 
     * @param link 
     */
    public setStatusMessage(action: string, link: IUserPreferenceLinksViewModel): void {
        if (action === "Delete") {
            this.statusMessage = "Link " + (link.name ? ("with title" + link.name) : "without title") + " has been deleted successfully";
        }
        this.sharedFunctionsService.delayExecution(4000).then(() => {
            this.statusMessage = "";
        });
    }
}
