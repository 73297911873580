<dm-modal-v2 class="dm-edit-rr-modal" [modalHeaderContent]="modalContent" [modalMinHeight]="'430px'"
  [hasNonOverflowContent]="true" [showFooter]="showRiskReserve && isEditClicked">
  <div class="dm-edit-rr-modal__loaderContainer" [style.display]="isLoadingRiskReserveDetails ? 'block' : 'none'">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" class="dm-p-10"
      *ngIf="isLoadingRiskReserveDetails">
    </dm-loading>
  </div>
  <div *ngIf="!isLoadingRiskReserveDetails">
    <div class="dm-info-msg--primary">
      Please adhere to Guidance on  <a href="https://sdmplus2.azurewebsites.net/topics/consulting/377" target="_blank" role="link"
      title="RR Release Process in SDMPlus - Risk Reserve Management and Approvals for more info" class="dm-link"
      aria-label="open link in new tab">
      <span aria-hidden="true"></span>
      RR Release Process in SDMPlus - Risk Reserve Management and Approvals for more info
    </a>
    </div>
    <div class="dm-edit-rr-modal__headerContainer">
      <div class="dm-edit-rr-modal__tabs">
        <div *ngFor="let tab of tabsContent">
          <span [id]="tab.id" class="set-focus" [ngClass]="{'dm-edit-rr-modal__active-tab' : tab?.isActive}" tabindex="0"
            (click)="toggleTab(tab)"
            (keyup.enter)="toggleTab(tab)"
            (keyup.Space)="toggleTab(tab)"
            role="button"
            [attr.aria-label]="tab?.displayName + (tab?.isActive ? 'is Selected' : 'not selected')"
            [title]="tab?.displayName"
            (keyup.arrowright)="focusForNavBarArrowKey(tab.id, 'right')" (keyup.arrowleft)="focusForNavBarArrowKey(tab.id, 'left')">{{tab?.displayName}}</span>
        </div>
      </div>
      <div class="dm-edit-rr-modal__editBtnHolder" *ngIf="showRiskReserve && hasEditPermissions && riskReserveDetailsList?.projects && riskReserveDetailsList?.projects?.length && !isEditClicked">
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" (click)="editRRDetails()">
          Edit
        </button>
      </div>
    </div>

    <section *ngIf="showRiskReserve">
      <div role="grid" class="dm-edit-rr-modal-grid">
        <div role="rowgroup" class="dm-grid-view-table dm-edit-rr-modal-table" *ngIf="riskReserveDetailsList?.projects && riskReserveDetailsList?.projects?.length">
          <div role="row" class="dm-grid-view-table__row">
            <div role="columnheader" class="dm-grid-view-table__headerCell"></div>
            <div role="columnheader" class="dm-grid-view-table__headerCell">Name
            </div>
            <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Total Risk Reserve <br /> 
              <span class="unitLabel">({{currency}}) <dm-tooltip [dmPlacement]="'bottom-right'"
                                  [ngbTooltipContent]="totalRRTooltip"
                                  [dmIcon]="'tile__icon--info icon icon-info'"
                                  ariaLabelToolTip="Total Risk Reserve Information">
              </dm-tooltip>
              <ng-template #totalRRTooltip>
                <div class="display-grid font-caption-alt">
                  <span>In order to release unused risk reserve, Please update Total Risk Reserve value equal to consumed Risk Reserve.</span>
                </div>
              </ng-template>   </span>
              
            </div>
            <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Remaining Risk Reserve <br />
              <div class="display-inline-block">
                <span class="unitLabel">({{currency}})</span>
                <dm-tooltip [dmPlacement]="'bottom-right'"
                                  [ngbTooltipContent]="remainingRRTooltip"
                                  [dmIcon]="'tile__icon--info icon icon-info'"
                                  ariaLabelToolTip="Remaining Risk Reserve Information">
                </dm-tooltip>
                <ng-template #remainingRRTooltip>
                  <div class="display-grid font-caption-alt">
                    <span>Total Risk Reserve Amount in Current Financial Plan - Consumed Risk Reserve in Current Financial Plan</span>
                  </div>
                </ng-template> 
              </div>
            </div>
            <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Consumed Risk Reserve <br />
              <div class="display-inline-block">
                <span class="unitLabel">({{currency}})</span>
                <dm-tooltip [dmPlacement]="'bottom-right'"
                                  [ngbTooltipContent]="consumedRRTooltip"
                                  [dmIcon]="'tile__icon--info icon icon-info'"
                                  ariaLabelToolTip="Consumed Risk Reserve Information">
                </dm-tooltip>
                <ng-template #consumedRRTooltip>
                  <div class="display-grid font-caption-alt">
                    <span>Current Financial Plan Cost Excl Risk Reserve - Contract Baseline Cost Excl Risk Reserve</span>
                  </div>
                </ng-template>
              </div>
            </div>
            <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Net Cost Overrun <br />
              <div class="display-inline-block">
                <span class="unitLabel">({{currency}})</span>
                <dm-tooltip [dmPlacement]="'bottom-right'"
                                [ngbTooltipContent]="costOverRunTooltip"
                                [dmIcon]="'tile__icon--info icon icon-info'"
                                ariaLabelToolTip="Net Cost Overrun Information">
                </dm-tooltip>
                <ng-template #costOverRunTooltip>
                  <div class="display-grid font-caption-alt">
                    <span>[Current Financial Plan Cost excl. Risk Reserve - Remaining Current Financial Plan Risk Reserve] - [Contract Baseline Cost excl. Risk Reserve + Risk Reserve from Contract Baseline]</span>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <ng-container *ngIf="riskReserveDetailsList?.projects && riskReserveDetailsList?.projects?.length">
            <div *ngFor="let projectDetails of riskReserveDetailsList?.projects">
              <div role="row" class="dm-grid-view-table__row dm-grid-view-table__projectsRow">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  <span class="dm-p-x-10">
                    <span class="icon set-focus" tabindex="0" role="button"
                      (click)="expandCollapse(projectDetails)"
                      (keyup.enter)="expandCollapse(projectDetails)" (keyup.Space)="expandCollapse(projectDetails)"
                      [title]="projectDetails?.isExpanded ? 'Collapse expanded project' : 'Expand collapsed project'"
                      [ngClass]="{'icon-ChevronUp': projectDetails?.isExpanded, 'icon-ChevronDown': !projectDetails?.isExpanded}"
                      [attr.aria-expanded]="projectDetails?.isExpanded ? true : false"></span>
                  </span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">Project: {{projectDetails?.name}} <br />
                  <span class="ebs-id">EBS: {{projectDetails?.wbsId}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  {{projectDetails?.totalRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!projectDetails?.totalRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  {{projectDetails?.remainingRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!projectDetails?.remainingRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  {{projectDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!projectDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  {{projectDetails?.costOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!projectDetails?.costOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>
              <ng-container *ngIf="projectDetails?.isExpanded">
                <div *ngFor="let taskDetails of projectDetails?.tasks">
                  <div role="row" class="dm-grid-view-table__row">
                    <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell">Task: {{taskDetails?.name}}
                      <br />
                      <span class="ebs-id">EBS: {{taskDetails?.wbsId}}</span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                      <span *ngIf="!isEditClicked">{{taskDetails?.totalRiskReserve | dmDisplayCurrencyOrDash : currency}}</span>
                      <ng-container *ngIf="isEditClicked">
                        <input id="{{taskDetails.wbsId}}-total-rr-amount" min="0" type="number"
                          appTwoDigitDecimalPositiveNumber
                          class="dm-edit-rr-modal-table__totalRRInput text-align--right"
                          [(ngModel)]="taskDetails.totalRiskReserve"
                          (ngModelChange)="onInputChange(taskDetails, $event)" aria-label="enter task total reserve" />
                      </ng-container>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    </div>
                  </div>
                  <div role="row" class="dm-grid-view-table__row dm-grid-view-table__errorsRow"
                    *ngIf="taskDetails?.errorMessage">
                    <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell">
                      <div role="alert" class="error-msg">
                        {{taskDetails?.errorMessage}}
                      </div>
                    </div>
                  </div>
                </div>

              </ng-container>
            </div>
          </ng-container>
          <div role="row"  *ngIf="riskReserveDetailsList?.projects && riskReserveDetailsList?.projects?.length" class="dm-grid-view-table__row dm-grid-view-table__totalsRow">
            <div role="gridcell" class="dm-grid-view-table__bodyCell">
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell">TOTALS
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
              {{riskReserveDetailsList?.projects | sum: 'totalRiskReserve' | dmDisplayCurrencyOrDash : currency}}
              <span *ngIf="!riskReserveDetailsList?.projects" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
              {{riskReserveDetailsList?.projects | sum: 'remainingRiskReserve' | dmDisplayCurrencyOrDash : currency}}
              <span *ngIf="!riskReserveDetailsList?.projects" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
              {{riskReserveDetailsList?.projects | sum: 'consumedRiskReserve' | dmDisplayCurrencyOrDash : currency}}
              <span *ngIf="!riskReserveDetailsList?.projects" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
              {{riskReserveDetailsList?.projects | sum: 'costOverrun' | dmDisplayCurrencyOrDash : currency}}
              <span *ngIf="!riskReserveDetailsList?.projects" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
          </div>
        </div>
        <dm-no-data *ngIf="riskReserveDetailsList?.length <=0 || showNoRiskReserveDetailsMessage" class="dm-p-10" [noDataText]="noTransactionsText"
          [showImage]="true">
        </dm-no-data>
      </div>
      <section class="dm-p-x-imp-0 dm-p-t-20" *ngIf="riskReserveDetailsList?.projects && riskReserveDetailsList?.projects?.length && isEditClicked">
         <p class="upload-file-label">Upload Risk Reserve approval document <span class="required-mark dm-m-r-5" aria-hidden="true">*</span> 
          <dm-tooltip [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="attachmentInfoTooltip"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Approval Document Information">
          </dm-tooltip>
        </p>
          <ng-template #attachmentInfoTooltip>
              <div class="display-grid font-caption-alt">
                <span>Upload approval email from Project Controller or DME. Please refer Guidance on
                  <a href="https://sdmplus2.azurewebsites.net/topics/consulting/377" target="_blank" role="link"
                    title="RR Release Process in SDMPlus - Risk Reserve Management and Approvals for more info" class="dm-link"
                    aria-label="open link in new tab">
                    <span aria-hidden="true"></span>
                    RR Release Process in SDMPlus - Risk Reserve Management and Approvals for more info
                  </a>
                </span>
              </div>
          </ng-template>    
        <div>
          <input class="font-caption-alt input__text" [disabled]="uploadedFileObj" type="text" id="uploadFileInput"
          name="uploadFileInput" [(ngModel)]="uploadedFileName" placeholder="Upload file">
          <button type="button" (click)="uploadFileBtnClick()" class="fxp-btn fxp-btn--primary">
            Browse
          </button>
          <input class="fxp-btn fxp-btn--primary hide-element" #uploadRRAttachmentFileInput id="uploadFileBtn"
            name="uploadFileBtn" (change)="onFileUpload($event)" type="file" accept="all" />
        </div>
        <div [style.display]="(showFileUploadApiValidationMessage || showFileUploadSuccessMessage) ? 'block' : 'none'"> 
            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
            *ngIf="showFileUploadValidationMessage">
            Only Allowed file formats can be uploaded.
            </p>
            <span role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
              *ngIf="showFileUploadApiValidationMessage">
              {{warningMessage}}
            </span>
            <span class="icon icon-exclamation icon-rounded dm-error max-width: 50%"
              *ngIf="showFileUploadApiValidationMessage">
            </span>
            <span class="success-msg" *ngIf="showFileUploadSuccessMessage">File has been uploaded successfully</span>
        </div>       
      </section>
    </section>

    <div *ngIf="showAudit" class="risk-reserve-audit-section dm-p-t-20">
      <dm-timeline [auditList]="auditHistoryData"></dm-timeline>
    </div>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
  </div>
  <div additionalFooterButtons *ngIf="showRiskReserve && isEditClicked">
    <button type="submit" id="update" aria-label="Submit edited risk reserve details" class="fxp-btn fxp-btn--primary dm-btn" (click)="updateRRDetails()" [disabled]="isSubmitBtnDisabled">
      Submit
    </button>
  </div>
</dm-modal-v2>