
export class StaffingActionConstants {
    public static ResourceDomainDataAPI = "GRM.ResourceDomainData";
    public static UpdateResourceDetailsAPI = "GRM.UpdateResourceDetails";
    public static ActionSuspend = "suspended";
    public static ActionCancel = "canceled";
    public static ActionUnSuspend = "unsuspended";
    public static ActionTruncate = "truncated";
    public static ActionRoleRemoval = "role removed";
    public static ActionSplit = "role split";
    public static ActionRoleMaintenenace = "role maintenance";
    public static ActionEditResourceRequests = "GRM.EditResourceRequests";
    public static GetResourceComittedHours = "GRM.GetResourceComittedHours";
}
