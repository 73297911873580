import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IEngagementFinancialsListV2 } from "../../common/services/contracts/portfolio.model";
import { ProjectService } from "../../common/services/project.service";
import * as customerFinancialOverviewActions from "./customer-financial-overview.action";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class CustomerFinancialsOverviewEffect {

    @Effect()
    public loadInternalFinancialDetails$ = this.actions$
        .ofType(customerFinancialOverviewActions.CustomerFinancialOverviewActionTypes.LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS)
        .pipe(
            switchMap((action: customerFinancialOverviewActions.CustomerFinancialOverviewActions) => fromPromise(
                this.projectService.getCustomerEngagementFinancialsListV2([{ id: action.engagementId }])
            )
                .pipe(
                    map((response: IEngagementFinancialsListV2[]) => new customerFinancialOverviewActions.LoadCustomerFinancialOverviewSuccess(action.engagementId, response)),
                    catchError((error) => of(new customerFinancialOverviewActions.LoadCustomerFinancialOverviewFail(action.engagementId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService,
    ) { }
} 