import { Component, Inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, AccessibilityConstants } from "../../../../../common/application.constants";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { IModal } from "../../../../modals/dm-modal-v2/dm-modal-v2.component";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { MisalignmentValidationErrorTypeEnum } from "../../../amendments.contract";
import { IProjectThresholdValues } from "../../../../../common/services/contracts/project.service.contracts";

@Component({
    templateUrl: "./validation-failed-modal.html",
    styleUrls: ["./validation-failed-modal.scss"]
})
export class ValidationFailedModalComponent extends DmModalAbstract {
    @Input() public validationResults: IProjectThresholdValues;
    public message: string;
    public modalContent: IModal;

    public constructor(@Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.MisalignedAmendmentValidationFailedModal);
    }

    public ngOnInit(): void {
        /* Modal was opened without any validation errors, close it. */
        if (!this.validationResults || !this.validationResults.amendmentThresholds || !this.validationResults.amendmentThresholds.length) {
            this.closeModal();
        }
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        let title: string = "Validation error";
        const amendmentThresholds = this.validationResults.amendmentThresholds;
        if (amendmentThresholds.length === 1 && amendmentThresholds[0].amendmentErrorType === MisalignmentValidationErrorTypeEnum.Warning) {
            title = "Validation warning";
            this.message = "The following errors have occurred. Would you like to save and continue anyways?";
        }
        else {
            this.message = "Unable to save the updated amendments due to the following errors. Please address the errors and try again.";
        }
        this.modalContent = {
            title
        };
    }

    /**
     * Ignore the warning and save anyways.
     *
     * @memberof ChangeExistingDemandModalComponent
     */
    public saveAndContinue(): void {
        this.activeModal.close(true);
    }

}