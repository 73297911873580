import { Component, Inject, forwardRef, ViewChild, ElementRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorSeverityLevel, FxpRouteService } from "@fxp/fxpservices";
import { Components, RouteName, AccessibilityConstants, SourceConstants } from "../../../common/application.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { BulkUploadInternalEngagementService } from "../../../common/services/bulk-upload-internal-engagement.service";
import { IBulkUploadIntEngUploadResponse } from "../../../common/services/contracts/bulk-upload-int-engagement.service.contracts";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmError } from "../../../common/error.constants";

@Component({
    selector: "int-eng-bulk-upload-modal",
    templateUrl: "./internal-engagement-bulk-upload-modal.html",
    styleUrls: ["./internal-engagement-bulk-upload-modal.scss"]
})
export class IntEngBulkUploadModalComponent extends DmModalAbstract {
    @ViewChild("bulkInternalEngagementFileUpload", { static: false }) public bulkInternalEngagementFileUpload: ElementRef;
    public uploadedFileName: string;
    public uploadedFileObj: any;
    public showFileUploadValidationMessage: boolean = false;
    public showFileUploadApiValidationMessage: boolean = false;
    public uploadApiValidationErrorMessages: string[];
    public uploadTemplateVersion: string = "version 1.0";
    public warningMessage: string;
    public accessibilityConstants = AccessibilityConstants;

    public constructor(
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(BulkUploadInternalEngagementService) public bulkUploadInternalEngagementService: BulkUploadInternalEngagementService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
    ) {
        super(activeModal, dmLogger, Components.InternalEngagementBulkUploadModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.uploadTemplateVersion = this.configManagerService.getValue<string>("bulkCreateUploadVersion");
        this.setLoadersBasedOnItemState();
    }
    /**
     * File upload on button click
     */
    public uploadFileBtnClick(): void {
        this.bulkInternalEngagementFileUpload.nativeElement.click();
        this.showFileUploadApiValidationMessage = false;
    }

    /**
     * To get the uploaded bulk upload excel file from user upload.
     * @param fileInput
     */
    public onBulkUploadFileChange(fileInput: Event): void {
        this.uploadedFileName = "";
        const fileObject = (fileInput.target as HTMLInputElement).files[0];
        this.uploadedFileObj = fileObject;
        this.uploadedFileName = fileObject.name;
    }

    /**
     * Move focus to next element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Move the focus to element for accessibility tooling
     * @param id
     */
    public focus(id: string): void {
        this.sharedFunctionsService.focus(id, true);
    }

    /**
     * Uploads the uploaded excel file.
     *
     * @memberof IntEngBulkUploadModalComponent
     */
    public uploadBulkIntEngExcel(): void {
        const formData = new FormData();
        this.isComponentLoading = true;
        formData.append("file", this.uploadedFileObj);
        this.bulkUploadInternalEngagementService.uploadBulkUploadIntEngExcel(formData).then((response: IBulkUploadIntEngUploadResponse) => {
            // route to bulk create internal engagement page with the upload excel response as params in route.
            this.fxpRouteService.navigatetoSpecificState(RouteName.BulkCreateInternalEngagement, {
                uploadedBulkIntEngData: response
            });
            this.isComponentLoading = false;
            this.closeModal();
        }).catch((error: any) => {
            this.showFileUploadApiValidationMessage = true;
            this.warningMessage = DmError.InternalEngagement.FileUpload;
            this.uploadApiValidationErrorMessages = [];
            if (error && error.data && Array.isArray(error.data.innerErrors) && Array.isArray(error.data.innerErrors[0].messages)) {
                this.warningMessage = DmError.InternalEngagement.MismatchOfUploadedFile;
            }
            this.logError(SourceConstants.Method.UploadBulkIntEngExcel, error, this.warningMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.isComponentLoading = false;
            this.sharedFunctionsService.focus("bulkUploadIntEngagementBrowse", true);
        });
    }
}
