import { Component, EventEmitter, Input, Output, Inject, Injector, forwardRef } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";

import { CancelModalComponent } from "../../staffing-command-bar/staffing-command-bar-modals/cancel-modal/cancel-modal.component";
import { Components, RouteName, AccessibilityConstants, SourceConstants, LogEventConstants, NoDataText } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IProject } from "../../../common/services/contracts/wbsStructures.contracts";
import { IProjectDetails } from "../../../common/services/contracts/project.service.contracts";
import { IResourceAcceptDecline, IStaffingDemandModel, IStaffingResource, IStaffingTaskModel, IStaffingViewModel } from "../../../common/services/contracts/staffing-details.contract";
import { IResourceRequestForGRMInput, ISplitDemandDetails, IProjectRequest, IResourceRequest } from "../../../common/services/contracts/staffing.service.contract";
import { PlanForecastComponent } from "../../plan-forecast/planForecast.component";
import { RmModalComponent } from "../../staffing-command-bar//staffing-command-bar-modals/rm-modal/rm-modal.component";
import { RoleRemovalService } from "../../staffing-command-bar/staffing-command-bar-modals/role-removal-service/role-removal.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { SplitPlannedModalComponent } from "../../staffing-command-bar//staffing-command-bar-modals/split-planned-modal/split-planned-modal.component";
import { SuspendModalComponent } from "../../staffing-command-bar/staffing-command-bar-modals/suspend-modal/suspend-modal.component";
import { TruncateModalComponent } from "../../staffing-command-bar//staffing-command-bar-modals/truncate-modal/truncate-modal.component";
import { UnsuspendModalComponent } from "../../staffing-command-bar//staffing-command-bar-modals/unsuspend-modal/unsuspend-modal.component";
import { ResourceStaffingService } from "../../../common/services/resource-staffing.service";
import { CopyResourceModalComponent } from "../../staffing-command-bar/staffing-command-bar-modals/copyresource-modal/copyresource-modal.component";
import { DeviceFactoryProvider } from "@fxp/fxpservices";

const WINDOW_CLASS = "dm-modal popup-modal in active";
@Component({
    selector: "dm-requests-grid",
    templateUrl: "./requests-grid.html",
    styleUrls: ["./requests-grid.scss"]
})
export class RequestsGridComponent extends DmComponentAbstract {
    @Input() public grmProjectDetails: IProjectRequest[];
    @Input() public isInternalEngagement: boolean;
    @Input() public projectData: IProject[] | IProjectDetails[];
    @Input() public rolesRequests: IStaffingTaskModel[];
    @Input() public rolesViewModelRequests: IStaffingViewModel[];
    @Input() public searchText: string;
    @Input() public selectedProjectId: string;
    @Input() public selectedServiceId: string;
    @Input() public selectedTaskId: string;

    @Input() public typeOfRequests: string;
    @Input() public isLoading: boolean;
    @Input() public wbsCurrency: string;
    @Input() public showNoDemandsMessage: boolean;
    @Input() public showUSPubSecRMActions: boolean;
    @Input() public isProjectMarkedForDeletion: boolean;

    @Output() public onDemandSelected: EventEmitter<{}> = new EventEmitter();
    @Output() public onResourceAcceptDecline: EventEmitter<{}> = new EventEmitter();

    public showMessageForNonTeamMembers: boolean = false; // this is hardcoded to false
    public allDemandsExpanded: boolean = true;
    public noDemandsMessageText = NoDataText.ViewStaffingDetails;
    public projectMarkedForDeletion = NoDataText.ProjectMarkedForDeletion;
    public riskReserveTypeCode: string;
    public RouteName = RouteName;
    public showNoRecordsMessage: boolean = true;
    public staffedCostToolTip: string;
    public accessibilityConstants = AccessibilityConstants;
    private isProjectContext: boolean = false;
    private wbsId: string;


    public constructor(
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(RoleRemovalService) private roleRemovalService: RoleRemovalService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(ResourceStaffingService) private resourceStaffingService: ResourceStaffingService,
        @Inject(Injector) private injector: Injector,
        @Inject(forwardRef(() => DeviceFactoryProvider))
        public deviceFactory: DeviceFactoryProvider,
    ) {
        super(dmLogger, Components.StaffingRequestGrid);
    }

    public ngOnInit(): void {
       
        this.riskReserveTypeCode = this.configManagerService.getValue<string>("RiskReserveTypeCode");
        this.wbsId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        if (!this.wbsId) {
            this.wbsId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        } else { this.isProjectContext = true; }
        this.staffedCostToolTip = "Cost based on the quantity of hours and staffed cost rate, which is based on the role and delivery resource's location";
        this.endComponentLoad();
        // todo on refresh, expand all demands
    }

    /**
     * Checks if the given action is in the configured command bar actions list.
     *
     * @param {string} action
     * @returns {boolean}
     * @memberof RequestsGridComponent
     */
    public commandBarActionsContains(action: string): boolean {
        return this.resourceStaffingService.commandBarActionsContains(action);
    }

    /**
     * Return demand status Complete/Incomplete
     * @param demand
     */
    public getDemandStatus(demand: IStaffingDemandModel): string {
        let demandStatus: string = "Complete";
        let isIncomplete: boolean = false;
        if ((Math.round(demand.demandStaffedQuantity) >= Math.round(demand.demandPlannedQuantity)) && (demand.resources && demand.resources.length)) {
            demand.resources.forEach((resource) => {
                if (!(resource.resourceStatus === "Closed" || resource.resourceStatus === "Cancelled" || resource.resourceStatus === "Complete" || resource.resourceStatus === "Assigned" || resource.resourceStatus === "Committed")) {
                    isIncomplete = true;
                    demandStatus = "Incomplete";
                    return;
                }
            });
            if (!isIncomplete) { demandStatus = "Complete"; }
        } else {
            demandStatus = "Incomplete";
        }
        return demandStatus;
    }

    /**
     *
     *
     * @param {*} event
     * @param {string} projectId
     * @param {string} demandId
     * @param {number} grmRequestId
     * @memberof RequestsGridComponent
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public onDemandChange(event: any, projectId: string, demandId: string, grmRequestId: number): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OnDemandChange, LogEventConstants.StaffingResourceIdSelected);
        const inputObj = {
            isChecked: event.target.checked,
            projectId,
            demandId,
            grmRequestId
        };
        this.onDemandSelected.emit(inputObj);
    }

    /**
     * Close Dropdown On Tab of Last Element
     *
     */
    public closeDropdownOnTab(id: string): void {
        document.getElementById(id).parentElement.parentElement.classList.remove("open");
    }

    /**
     * Make dropdowns list items accessibile with Keyboard Up/Down Arrow Keys for both Engagement and Project
     * @param type
     * @param Id
     * @param action
     */
    public moveFocus(id: string, action: string): void {
        const dropdownId = "staffing-grid-" + id + action;
        this.sharedFunctionsService.focus(dropdownId, true);
    }

    /**
     * Opens up modal PopUp for Suspend
     *
     * @memberof StaffingCommandBarComponent
     */
    public loadSuspendPopup(resourceInfo: IStaffingResource, projectId: string, demandId: string, resourceId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadSuspendPopup, LogEventConstants.SuspendRoleButton );
        const modalRef: NgbModalRef = this.modalService.open(SuspendModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: WINDOW_CLASS,
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.resourceId = resourceId;
    }

    /**
     * Opens up modal PopUp for CopyResource
     *
     * @memberof StaffingCommandBarComponent
     */
    public loadCopyResourcePopup(resourceInfo: IStaffingResource, projectId: string, demandId: string, resourceId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadCopyResourcePopup, LogEventConstants.CopyResourceRequestButton );
        const modalRef: NgbModalRef = this.modalService.open(CopyResourceModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "manage-wbs-modal copy-request-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.resourceId = resourceId;
    }

    /**
     * Opens up modal PopUp for UnSuspend
     *
     * @memberof StaffingCommandBarComponent
     */
    public loadUnsuspendPopup(resourceInfo: IStaffingResource, projectId: string, demandId: string, resourceId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadUnsuspendPopup, LogEventConstants.UnsuspendRoleButton);


        const modalRef: NgbModalRef = this.modalService.open(UnsuspendModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: WINDOW_CLASS,
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.resourceId = resourceId;
    }

    /**
     * Opens up modal PopUp for Request Role Maintenance
     *
     * @memberof StaffingCommandBarComponent
     */
    public loadRquestRoleMaintenancePopup(resourceInfo: IStaffingResource, projectId: string, demandId: string, resourceId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadRquestRoleMaintenancePopup, LogEventConstants.RequestMaintenanceButton);
        const modalRef: NgbModalRef = this.modalService.open(RmModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: WINDOW_CLASS,
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.resourceId = resourceId;
    }

    /**
     *
     * Opens up modal PopUp for cancel ResourceRequest
     * @memberof StaffingCommandBarComponent
     */
    public loadCancelPopup(resourceInfo: IStaffingResource, projectId: string, demandId: string, resourceId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadCancelPopup, LogEventConstants.CancelRoleButton);
        const modalRef: NgbModalRef = this.modalService.open(CancelModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: WINDOW_CLASS,
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.resourceId = resourceId;
    }

    /**
     * Submit to remove the draft role
     *
     * @param {IStaffingResource} resourceInfo
     * @param {string} projectId
     * @memberof RequestsGridComponent
     */
    public submitRemoveRole(resourceInfo: IStaffingResource, projectId: string, demandId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.SubmitRemoveRole, LogEventConstants.RemoveRoleButtonClicked);
        const assignmentList = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        this.roleRemovalService.submitRemoveRole(assignmentList);
    }

    /**
     * Open truncate modal popup
     */
    public truncatePopup(resourceInfo: IStaffingResource, projectId: string, demandId: string, resourceId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.TruncatePopup, LogEventConstants.TruncateRoleLink);
        const modalRef: NgbModalRef = this.modalService.open(TruncateModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: WINDOW_CLASS,
            injector: this.injector
        });
        const resourceInfoResult: IResourceRequestForGRMInput[] = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        modalRef.componentInstance.assignmentList = resourceInfoResult;
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.resourceId = resourceId;
        if (resourceInfoResult && resourceInfoResult.length > 0) {
            for (const result of resourceInfoResult) {
                if (result.demandDetails && result.demandDetails.demandId === demandId) {
                    modalRef.componentInstance.requestEndDate = result.demandDetails.endDate;
                }
            }
        }
    }

    /**
     * Open Split planned modal popup
     */
    public splitPlannedPopup(resourceInfo: IStaffingResource, projectId: string, demandId: string, resourceId: string): void { // ISplitData
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.SplitPlannedPopup, LogEventConstants.SpiltRoleLink);
        const modalRef: NgbModalRef = this.modalService.open(SplitPlannedModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active splitPlanned",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.getResourceInfoAsPerModalPopup(resourceInfo, projectId, demandId);
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.resourceId = resourceId;
    }

    /**
     * Send Event to the parent component for Resource Accept Decline
     */
    public sendEventEmitter(resourceInfo: IStaffingResource, resourceAction: string, projectIdRetrieved: string): void {
        const eventData: IResourceAcceptDecline = {
            resourceDetail: resourceInfo,
            resourceActionType: resourceAction,
            projectId: projectIdRetrieved
        };
        this.onResourceAcceptDecline.emit(eventData);
    }

    /**
     * For expanding/collapsing all the demands
     */
    public expandCollapseAllDemands(areDemandsExpanded: boolean): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.ExpandCollapseAllDemands, LogEventConstants.ExpandCollapseDemands);
        areDemandsExpanded ? this.expandAllDemands() : this.collapseAllDemands();
    }

    /**
     * Logs create request click to App Insights 
     */
    public logCreateClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LogCreateClick, LogEventConstants.CreateRequestStaffing);
    }

    /**
     * Logs edit role click to App Insights 
     */
    public logEditRoleClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LogEditRoleClick, LogEventConstants.EditRoleStaffing);
    }

    /**
     * Logs role dropdown click to App Insights 
     */
    public logDropdownClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LogDropdownClick, LogEventConstants.RoleActionsDropdown);
    }

    /**
     * For Resetting the grid Expand Icon on Top of the table
     *
     * @private
     * @memberof RequestsGridComponent
     */
    public gridExpandIcon(item: IStaffingViewModel): void {
        if (item.isTasksExpanded) {
            this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.GridExpandIcon, LogEventConstants.StaffingProjectsCollapsed);
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.GridExpandIcon, LogEventConstants.StaffingProjectsExpanded);
        }
        item.isTasksExpanded = !item.isTasksExpanded;
        let projectExpanded: number = 0;
        let projectCollapsed: number = 0;
        for (const obj of this.rolesViewModelRequests) {
            if (obj.isTasksExpanded) {
                projectExpanded++;
            } else {
                projectCollapsed++;
            }
        }
        if (projectExpanded === this.rolesViewModelRequests.length) {
            this.allDemandsExpanded = false;
        } else if (projectCollapsed === this.rolesViewModelRequests.length) {
            this.allDemandsExpanded = true;
        }
    }

    /**
     * Change state to plan&forecast and land on the Current Financial Plan
     */
    public openCurrentFinancialPlan(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OpenCurrentFinancialPlan, LogEventConstants.CurrentFinancialPlanClick);
        PlanForecastComponent.financialPlanToOpen = "CurrentFinancialPlan";
        this.stateService.go(this.isProjectContext ? RouteName.ProjectPlanForecast : RouteName.EngagementPlanForecast);
    }

    /**
     * set top position for sticky header of request grid 
     */
    public setTopPositionForStickyHeaderOfRequestGrid(): string {
        // added height of staffing command bar to sticky header below staffing command bar as it is also sticky. 
        if (!this.deviceFactory.isSmallScreen() && document.getElementById("secondary-naviagtion") && document.getElementById("staffingCommandBar")) {
            return (document.getElementById("secondary-naviagtion").clientHeight + document.getElementById("staffingCommandBar").clientHeight) + "px";
        } else {
            return "";
        }        
    }

    /**
     * Prepare modal data for the input popups
     */
    private getResourceInfoAsPerModalPopup(resourceInfo: IStaffingResource, projectId: string, demandId: string): IResourceRequestForGRMInput[] {
        const resourceRequestInput: IResourceRequestForGRMInput[] = [];
        const projectDetails: IProject | IProjectDetails = ((this.projectData as IProject[]).filter((project) => project.id === projectId)[0]);
        const projectRequests: IProjectRequest = this.grmProjectDetails.filter((projectRequest) => projectRequest.DemandSourceId === projectId)[0];
        const resourceRequests: IResourceRequest[] = projectRequests.ResourceRequests.filter((resourceDet) => resourceDet.DemandResourceRequest && resourceDet.DemandResourceRequest.DemandId === demandId);
        const resourceDetails: IResourceRequest = resourceRequests.filter((resource) => resource.ResourceRequestId === resourceInfo.resourceId)[0];

        resourceRequestInput.push({
            persona: "Requestor",
            assignmentId: resourceInfo.resourceId,
            demandSourceType: "Delivery",
            assignmentStatus: resourceInfo.resourceStatus,
            assignmentSubStatus: resourceInfo.resourceSubStatus,
            assignmentStartDate: resourceInfo.resourceStartDate,
            assignmentEndDate: resourceInfo.resourceEndDate,
            demandDetails: this.retrieveDemandDetailsForSplit(demandId, this.rolesRequests),
            resourceInformation: resourceDetails,
            grmProjectStatus: projectRequests.IsDraft,
            resourcesUnderDemand: resourceRequests,
            isGRMProjectInDraft: projectRequests.IsDraft,
            projectIdSelected: (projectDetails as IProject).id,
            isInternal: this.isInternalEngagement,
            showUSPubsecRMActions: this.showUSPubSecRMActions,
            grmProjectRequest: projectRequests,
            internalEngagementType: this.isInternalEngagement ? (projectDetails as IProject).projectTypeCode : undefined,
            grmSearchResponse: {
                ProjectRequests: this.grmProjectDetails              
            },
            projectDetails: this.projectData && this.projectData[0] ? this.projectData[0] as IProject : undefined
        });
        return resourceRequestInput;
    }

    /**
     * Retrieving Demanddetails for Split
     * @param demandId
     */
    private retrieveDemandDetailsForSplit(demandId: string, rolesRequests: IStaffingTaskModel[]): ISplitDemandDetails {
        let demandDetail: ISplitDemandDetails;
        if (rolesRequests && rolesRequests.length) {
            rolesRequests.forEach((task) => {
                if (task.demands && task.demands.length) {
                    const filteredDemand = task.demands.filter((object) => object.demandId === demandId);
                    if (filteredDemand && filteredDemand.length) {
                        // todo, we iterate this in a for loop, but for every match we will always overwrite the existing demandDetail
                        // is this the correct behavior?
                        demandDetail = {
                            demandId: filteredDemand[0].demandId,
                            roleDescription: filteredDemand[0].demandRole,
                            plannedDuration: filteredDemand[0].demandPlannedQuantity,
                            startDate: task.projectStartDate,
                            endDate: task.projectEnddate,
                            isDemandBillable: filteredDemand[0].isDemandBillable
                        };
                    }
                }
            });
        }
        return demandDetail;
    }

    /**
     * For expanding all the demands
     *
     * @private
     * @memberof RequestsGridComponent
     */
    private expandAllDemands(): void {
        for (const obj of this.rolesViewModelRequests) {
            obj.isTasksExpanded = true;
        }
        this.allDemandsExpanded = false;
    }

    /**
     * For collapsing all the demands
     *
     * @private
     * @memberof RequestsGridComponent
     */
    private collapseAllDemands(): void {
        for (const obj of this.rolesViewModelRequests) {
            obj.isTasksExpanded = false;
        }
        this.allDemandsExpanded = true;
    }

}
