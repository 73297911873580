import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CircularProgressComponent } from "./circular-progress.component";
import { CommonComponentsModule } from "../../entry/common-components.module";

@NgModule({
    declarations: [
        CircularProgressComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule
    ],
    providers: [],
    entryComponents: [
        CircularProgressComponent
    ],
    exports: [
        CircularProgressComponent
    ]
})
export class CircularProgressModule { }
