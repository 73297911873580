import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { FeedbackModalComponent } from "./feedback-modal.component";
import { InlineSurveyModule } from "../../shared/inline-survey/inline-survey.module";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedComponentsModule } from "@fxp/angularcontrols";

@NgModule({
    declarations: [
        FeedbackModalComponent
    ],
    imports: [
        CommonModule,
        SharedComponentsModule,
        CommonComponentsModule,
        NgbModule,
        InlineSurveyModule
    ],
    providers: [],
    entryComponents: [
        FeedbackModalComponent
    ],
    exports: [
        FeedbackModalComponent
    ]
})
export class FeedbackModalModule { }
