import { CommonComponentsModule } from "../../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { CreateCSATContactModalModule } from "../create-csat-contact/create-csat-contact.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "../../../../common/services/project.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { UpdateContactLanguageModalComponent } from "./update-contact-language.component";
import { DmUserProfileModule } from "../../../shared/user-name/user-name.module";

@NgModule({
    declarations: [
        UpdateContactLanguageModalComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        FormsModule,
        NgbModule,
        DmUserProfileModule,
        CreateCSATContactModalModule
    ],
    providers: [
        ProjectService,
        SharedFunctionsService,
        DMLoggerService
    ],
    entryComponents: [
        UpdateContactLanguageModalComponent,
    ],
    exports: [
        UpdateContactLanguageModalComponent
    ]
})
export class UpdateContactLanguageModalModule { }
