<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'300px'" [tileHeight]="'100%'" [isV2Tile]="true">
    <dm-loading loader *ngIf="isComponentLoading" [loadingText]="'Key Links'" [showLoading]="true">
    </dm-loading>
    <ng-container *ngIf="!isComponentLoading">   
        <div *ngIf="projectManagementKeyLinks && projectManagementKeyLinks.length">
            <div class="dm-p-b-10 dm-detail__value">Standard Links</div>
            <ul class="dm-m-0" [ngClass]="{'twoThird-tile': isTwoThirdTile}">
                <li *ngFor="let pl of projectManagementKeyLinks">
                    <a [href]="pl.link" target="_blank" class="dm-link" [attr.aria-label]="pl.name + 'open link in new tab'"
                        *ngIf="pl.name !== 'SAFE Limits'" (click)="logEvent(pl.name)">{{pl.name}}</a>                   
                </li>
            </ul>
        </div>
        <div class="dm-p-b-10" *ngIf="userPreferenceLinks && userPreferenceLinks.length">
            <div class="dm-p-b-10 dm-detail__value">Custom Links
                <dm-tooltip role="tooltip" class="dm-tooltipinline"
                    [dmPlacement]="'right'" [ngbTooltipContent]="'Upto 7 custom links can be added'" [dmIcon]="'tile__icon--info icon icon-info'"
                    ariaLabelToolTip="Custom Links Information">
                </dm-tooltip>
                <span class="sr-only">press tab to navigate to tooltip button</span>
            </div>
            <ul class="dm-m-0" [ngClass]="{'dm-m-b-imp-30': !(isInternalEngagement && !this.isSrOut), 'twoThird-tile': isTwoThirdTile, 'dm-paddingBottom': deviceFactory.isSmallScreen()}">
                <li *ngFor="let pl of userPreferenceLinks">
                    <a [href]="pl.url" target="_blank" class="dm-link font-caption-alt"
                        (click)="logEvent(pl.name)" aria-label="open link in new tab">{{pl.name}}
                    </a>
                </li>
            </ul>
        </div>
        <div *ngIf="isInternalEngagement && !this.isSrOut" class="dm-p-b-10">
            <div class="dm-p-b-10 dm-detail__value">Project NBUE Documents</div>
            <ul class="dm-m-0 dm-m-b-imp-30" [ngClass]="{'twoThird-tile': isTwoThirdTile, 'dm-paddingBottom': deviceFactory.isSmallScreen()}">
                <li *ngFor="let nbueLink of projectNBUELinks">
                    <a [href]="nbueLink.link" target="_blank" class="dm-link font-caption-alt"
                        (click)="logEvent(nbueLink.name)" aria-label="open link in new tab">
                        {{getNbueDocName(nbueLink.name)}}
                    </a>
                </li>
                <li *ngIf="!projectNBUELinks.length && !nbueError"
                    class="dm-p-l-imp-0 dm-p-r-10 dm-p-b-10">
                    <span class="font-caption-alt">
                        {{keyLinksErrorMessage.NoNBUEDocuments}}
                    </span>
                </li>
                <li *ngIf="nbueError"
                    class="dm-p-l-imp-0 dm-p-r-10 dm-p-b-10">
                    <span role="alert" sria-atomic="true" aria-live="polite" class="font-caption-alt error-msg">
                        <i class="icon icon-error"></i>{{keyLinksErrorMessage.ProblemFetchingNBUEdocuments}}
                    </span>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-container tileFooter *ngIf="!isComponentLoading">
        <div class="dm-footer-container" [ngClass]="{'dm-overflow': deviceFactory.isSmallScreen()}">
            <button type="button" class="dm-btn" (click)="editKeyLinksModal()" id="editKeyLinksButton001" aria-label="Edit Links" *ngIf="hasEditPermissions && userPreferenceLinks && userPreferenceLinks.length">
                <span class="dm-btn--icon icon icon-editLegacy"></span>
                <span class="dm-btn--text">Edit Links</span>
            </button>
            <button type="button" class="dm-btn" (click)="addKeyLinksModal()" id="addKeyLinksButton001" aria-label="Add Custom Link" *ngIf="hasEditPermissions && userPreferenceLinks && userPreferenceLinks.length < 7">
                <span class="dm-btn--icon icon icon-AddLegacy"></span>
                <span class="dm-btn--text">Add Custom Link</span>
            </button>
        </div>
    </ng-container>
</dm-tile>