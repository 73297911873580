import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../common/services/wbs.service";
import { MyPortfolioService } from "../../../../common/services/my-portfolio.service";
import { EditTeamStructureModalComponent } from "./edit-team-structure.component";
import { DmModalV2Module } from "../../../modals/dm-modal-v2/dm-modal-v2.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

@NgModule({
    declarations: [
        EditTeamStructureModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        DmModalV2Module,
        NgMultiSelectDropDownModule
    ],
    providers: [
        ConfigManagerService,
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        DMNotificationService,
        EngagementDetailService,
        MyPortfolioService,
        SharedFunctionsService,
        WBSService
    ],
    entryComponents: [
        EditTeamStructureModalComponent,
    ],
    exports: [
        EditTeamStructureModalComponent,
    ]
})
export class EditTeamStructureModalModule { }