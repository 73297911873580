import { Component, Inject, Input } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { Components, PlanVersionType } from "../../../common/application.constants";
import { IPlan } from "../plan-forecast-v2.component";
import { IForecast } from "../../financial-mgmt/financial.model";
import { ProjectService } from "../../../common/services/project.service";
import { IState } from "../../../store/reducers";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { getWbsForecastState } from "../../../store/wbs-forecast-details/wbs-forecast.selector";
import { IWbsForecastState } from "../../../store/wbs-forecast-details/wbs-forecast.reducer";
import { IWbsCfpState } from "../../../store/wbs-cfp-details/wbs-cfp.reducer";
import { getWbsCfpState } from "../../../store/wbs-cfp-details/wbs-cfp.selector";
import { LoadWbsCfp } from "../../../store/wbs-cfp-details/wbs-cfp.action";
import { getWbsDbState } from "../../../store/wbs-db-details/wbs-db.selector";
import { IWbsDbState } from "../../../store/wbs-db-details/wbs-db.reducer";
import { LoadWbsDb } from "../../../store/wbs-db-details/wbs-db.action";
import { IWeeklyForecastRecommendation } from "../../../common/services/contracts/forecast-recommendation.contracts";

@Component({
    selector: "dm-plan-forecast-details",
    templateUrl: "./plan-forecast-details.html",
    styleUrls: ["./plan-forecast-details.scss"]
})
export class PlanForecastDetailsComponent extends DmComponentAbstract {
    @Input() public planDetails: IPlan[] = [];
    @Input() public planCurrency: string;
    @Input() public wbsId: string;
    public forecastDetails: IForecast;
    public showForecastDetails: boolean = false;
    public showCfpDetails: boolean = false;
    public showDbDetails: boolean = false;
    public isLoadingForecast: boolean;
    public loadingText: string;
    public etcforecastDetails: IForecast;
    public cfpforecastDetails: IForecast;
    public dbforecastDetails: IForecast;
    public isPlanUpdated: boolean = false;
    public isBetaUser: boolean = false;
    public isRecsLoaded: boolean = false;
    public isRecsError: boolean = false;
    public mlForecastRecommendations: IWeeklyForecastRecommendation[] = [];

    private version: string;

    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Components.PlanForecastDetails);
    }

    public ngOnInit(): void {
        this.onForecastClick();       
    }

    /**
     * Show forecast details.
     */
    public onCfpClick(): void {
        this.showCfpDetails = true;
        this.showForecastDetails = false;
        this.showDbDetails = false;
        this.version = PlanVersionType.CurrentFinancialPlan;
        if (!this.cfpforecastDetails) {
            this.getDetails(this.version);
        } else {
            this.forecastDetails = this.cfpforecastDetails;
        }
    }

    /**
     * Show forecast details.
     */
    public onDbClick(): void {
        this.showDbDetails = true;
        this.showForecastDetails = false;
        this.showCfpDetails = false;
        this.version = PlanVersionType.DeliveryBaseline;
        if (!this.dbforecastDetails) {
            this.getDetails(this.version);
        } else {
            this.forecastDetails = this.dbforecastDetails;
        }
    }

    /**
     * Show forecast details.
     */
    public onForecastClick(): void {
        this.showForecastDetails = true;
        this.showCfpDetails = false;
        this.showDbDetails = false;
        this.version = PlanVersionType.Forecast;
        if (!this.etcforecastDetails) {
            this.getDetails(this.version);
        } else {
            this.forecastDetails = this.etcforecastDetails;
        }        
    }

    /**
     * Check whether forecast details tab selected.
     */
    public getLaborTabSelected(event: boolean): void {
        this.showForecastDetails = event;
    }

    /**
     * Update plan details on changing of forecast schedules
     */
    public getUpdatedDemands(demands: any[]): void {
        let eacHours = 0;
        let eacCost = 0;
        let eacRevenue = 0;
        let actualsHours = 0;
        let actualsCost = 0;
        let actualsRevenue = 0;
        this.isPlanUpdated = demands.filter((demand) => demand.eac.isEdited === true).length > 0;
        for (const demand of demands) {
            eacHours += demand.eac.hours;
            eacCost += demand.eac.cost;
            eacRevenue += demand.eac.revenue;
            actualsHours += demand.actuals.hours;
            actualsCost += demand.actuals.cost;
            actualsRevenue += demand.actuals.revenue;
        }
        for (const plan of this.planDetails) {
            if (plan.category === "labor") {
                plan.eac = eacHours;
                plan.actuals = actualsHours;
                plan.forecast = plan.eac - plan.actuals;
            }
            if (plan.category === "cost") {
                plan.eac = eacCost;
                plan.actuals = actualsCost;
                plan.forecast = plan.eac - plan.actuals;
            }
            if (plan.category === "revenue") {
                plan.eac = eacRevenue;
                plan.actuals = actualsRevenue;
                plan.forecast = plan.eac - plan.actuals;
            }
            if (plan.category === "margin") {
                plan.eac = eacRevenue !== 0 ? ((eacRevenue - eacCost) / eacRevenue) * 100 : 0;
                plan.actuals = actualsRevenue !== 0 ? ((actualsRevenue - actualsCost) / actualsRevenue) * 100 : 0;
                plan.forecast = plan.eac - plan.actuals;
            }
        }
    }

    /**
     * Show forecast details.
     */
    private getDetails(version?: string): void {
        this.isLoadingForecast = true;
        this.loadingText = "Loading Forecast Details";
        this.forecastDetails = null;
        
        // TODO: Placeholder, will introduce once full integration is ready
        // let forecastRecommendations$;
        // if (this.sharedFunctionsService.isProjectContext(this.stateService)) {
        //     const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        //     // this.storeDispatchService.requireProjectForecastRecommendations(projectId, true).load();
        //     this.store.dispatch(new LoadProjectForecastRecommendations(projectId));
        //     forecastRecommendations$ = this.store.select(getProjectForecastRecommendationsState(projectId));
        // } else {
        //     // this.storeDispatchService.requireEngagementForecastRecommendations(engagementId, true).load();
        //     this.store.dispatch(new LoadEngagementForecastRecommendations(this.wbsId));
        //     forecastRecommendations$ = this.store.select(getEngagementForecastRecommendationsState(this.wbsId));
        // }

        // forecastRecommendations$.pipe(untilDestroyed(this)).subscribe((forecastRecommendationState) => {
        //     if (forecastRecommendationState.loaded) {
        //         this.isRecsLoaded = true;
        //         this.mlForecastRecommendations = forecastRecommendationState.forecastRecommendations;
        //     }

        //     // handle error from API
        //     if (forecastRecommendationState.error) {
        //         this.isRecsError = true;
        //         const errorMessage = this.sharedFunctionsService.getErrorMessage(forecastRecommendationState.error, "");
        //         // this.logError(SourceConstants.Method.NgOnInit, forecastRecommendationState.error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        //         if (forecastRecommendationState.error.status && forecastRecommendationState.error.status === 404) {
        //             this.mlForecastRecommendations = [];
        //         }
        //     }
        // });

        // combineLatest([entireFinancialDetails$, forecastRecommendations$]) 

        if (version === PlanVersionType.Forecast && !this.etcforecastDetails) {
            const entireForecastDetails$ = this.store.select(getWbsForecastState(this.wbsId));
            entireForecastDetails$
                .pipe(untilDestroyed(this))
                .subscribe((forecastDetailsState: IWbsForecastState) => {
                    // if (forecastDetailsState && !forecastDetailsState.loaded && !forecastDetailsState.loading) {
                    //     this.store.dispatch(new LoadWbsForecast(this.wbsId));
                    // }
                    if (forecastDetailsState && forecastDetailsState.loaded) {
                        this.isLoadingForecast = false;
                        const forecastDetailsResponse = forecastDetailsState.wbsForecastDetails;

                        // map forecast recommendations to demand schedules here
                        const forecastDetailsWithRecommendations = this.mapForecastRecommendationsToForecastData(forecastDetailsResponse, this.mlForecastRecommendations);

                        if (forecastDetailsResponse) {
                            this.forecastDetails = forecastDetailsWithRecommendations;
                            this.etcforecastDetails = this.forecastDetails;
                        }
                    }
                    this.refreshOnItemInvalidation(forecastDetailsState);
                    this.setLoadersBasedOnItemState(forecastDetailsState);
                    this.setErrorsBasedOnItemState(forecastDetailsState);
                });
        } else if (version === PlanVersionType.CurrentFinancialPlan && !this.cfpforecastDetails) {
            const entireForecastDetails$ = this.store.select(getWbsCfpState(this.wbsId));
            entireForecastDetails$
                .pipe(untilDestroyed(this))
                .subscribe((forecastDetailsState: IWbsCfpState) => {
                    if (forecastDetailsState && !forecastDetailsState.loaded && !forecastDetailsState.loading) {
                        this.store.dispatch(new LoadWbsCfp(this.wbsId));
                    }
                    if (forecastDetailsState && forecastDetailsState.loaded) {
                        this.isLoadingForecast = false;
                        const forecastDetailsResponse = forecastDetailsState.wbsCfpDetails;
                        if (forecastDetailsResponse) {
                            this.forecastDetails = forecastDetailsResponse;
                            this.cfpforecastDetails = this.forecastDetails;
                        }
                    }
                    this.refreshOnItemInvalidation(forecastDetailsState);
                    this.setLoadersBasedOnItemState(forecastDetailsState);
                    this.setErrorsBasedOnItemState(forecastDetailsState);
                });
        } else if (version === PlanVersionType.DeliveryBaseline && !this.dbforecastDetails) {
            const entireForecastDetails$ = this.store.select(getWbsDbState(this.wbsId));
            entireForecastDetails$
                .pipe(untilDestroyed(this))
                .subscribe((forecastDetailsState: IWbsDbState) => {
                    if (forecastDetailsState && !forecastDetailsState.loaded && !forecastDetailsState.loading) {
                        this.store.dispatch(new LoadWbsDb(this.wbsId));
                    }
                    if (forecastDetailsState && forecastDetailsState.loaded) {
                        this.isLoadingForecast = false;
                        const forecastDetailsResponse = forecastDetailsState.wbsDbDetails;
                        if (forecastDetailsResponse) {
                            this.forecastDetails = forecastDetailsResponse;
                            this.dbforecastDetails = this.forecastDetails;
                        }
                    }
                    this.refreshOnItemInvalidation(forecastDetailsState);
                    this.setLoadersBasedOnItemState(forecastDetailsState);
                    this.setErrorsBasedOnItemState(forecastDetailsState);
                });
        }
    }

    private mapForecastRecommendationsToForecastData(forecastDetails: IForecast, forecastRecommendations: IWeeklyForecastRecommendation[]): IForecast {
        const forecastDetailsWithRecs: IForecast = forecastDetails;

        for (const project of forecastDetailsWithRecs.projects) {
            for (const demand of project.demand) {
                const demandForecastRecs = forecastRecommendations.filter((recData) => `000000${recData.demandId}` === demand.sapDemandId);
                if (demandForecastRecs.length) {
                    demand.hasForecastRecommendations = true;
                    
                    for (const weekSchedule of demand.schedules) {
                        const weekRecommendation = demandForecastRecs.filter((data) => data.weekStartDate && (new Date(weekSchedule.startDate).getTime() === new Date(data.weekStartDate).getTime()))[0];
    
                        if (weekRecommendation) {
                            weekSchedule.recommendedHours = weekRecommendation.forecastHours;
                        }
                    }

                    demand.resources.forEach((resource) => {
                        if (resource.businessPartnerId) {
                            const resourceForecastRecs = demandForecastRecs.filter((resourceRec) => resourceRec.BPId.toString() === resource.businessPartnerId.slice(-9));

                            if (resourceForecastRecs.length) {
                                for (const weekSchedule of resource.schedules) {
                                    const weekRecommendation = resourceForecastRecs.filter((data) => data.weekStartDate && (new Date(weekSchedule.startDate).getTime() === new Date(data.weekStartDate).getTime()))[0];

                                    if (weekRecommendation) {
                                        weekSchedule.recommendedHours = weekRecommendation.forecastHours;
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }

        return forecastDetailsWithRecs;
    }
}
