<section class="dm-p-x-12-y-imp-0">
    <!-- ===================== Not Mobile View Start=============== -->
    <section class="row" *ngIf="!deviceFactory.isMobile()">
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section
                class="col-md-7 col-sm-7 col-xs-7 dm-p-imp-0 dm-m-r-15">
                <dm-engagement-summary-details>
                </dm-engagement-summary-details>
            </section>
            <section class="col-lg-5 col-md-5 col-sm-5 dm-p-imp-0">
                <dm-key-actions-v2 *ngIf="listOfKeyActions.length > 0" [listOfActions]="listOfKeyActions">
                </dm-key-actions-v2>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-m-b-12">
            <dm-internal-engagement-projects></dm-internal-engagement-projects>
        </section>
        <section class="col-md-12 col-sm-12 col-lg-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12"
            [ngClass]="{'dm-order--ord5':deviceFactory.isSmallScreen()}">
            <section
                class="col-md-5 col-sm-5 col-lg-5 dm-p-imp-0 dm-m-r-15">
                <dm-labor-consumption></dm-labor-consumption>
            </section>
            <section class="col-md-7 col-sm-7 col-lg-7 dm-p-imp-0">
                <dm-key-links></dm-key-links>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-m-b-12" *ngIf="engagementDetails">
            <dm-customer-engagement *ngIf="associatedEngagementList && associatedEngagementList.length"
                [associatedCustomerEngagementList]="associatedEngagementList"></dm-customer-engagement>
        </section>
    </section>
    <!-- ===================== Not Mobile View End=============== -->
    <!-- ===================== Mobile View Start=============== -->
    <section class="row" *ngIf="deviceFactory.isMobile()">
        <section class="col-xs-12 dm-tile__displayflex dm-p-imp-0">
            <section class="col-xs-12 dm-tile__displayflex--inline ">
                <dm-key-actions-v2 *ngIf="listOfKeyActions.length > 0" [listOfActions]="listOfKeyActions">
                </dm-key-actions-v2>
            </section>
            <section class="col-xs-12 dm-tile__displayflex--inline">
                <section>
                    <dm-labor-consumption></dm-labor-consumption>
                </section>
            </section>
            <section class="col-xs-12 dm-tile__displayflex dm-p-imp-0">
                <section class="col-xs-12 dm-tile__displayflex--inline">
                    <dm-engagement-summary-details class="dm-tile__componentflex">
                    </dm-engagement-summary-details>
                </section>
                <section class="col-xs-12 dm-tile__displayflex--inline">
                    <dm-key-links class="dm-tile__componentflex"></dm-key-links>
                </section>
            </section>
            <section class="col-md-12 col-sm-12 col-xs-12" *ngIf="engagementDetails">
                <dm-customer-engagement *ngIf="associatedEngagementList && associatedEngagementList.length"
                    [associatedCustomerEngagementList]="associatedEngagementList"></dm-customer-engagement>
            </section>

        </section>
    </section>
    <!-- ===================== Mobile View End=============== -->
</section>