import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { InternalEngagementProjectsComponent } from "../internal-engagement-projects/internal-engagement-projects.components";
import { DmUserProfileModule } from "../../../../../components/shared/user-name/user-name.module";

@NgModule({
    declarations: [
        InternalEngagementProjectsComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        DmUserProfileModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        InternalEngagementProjectsComponent,
    ],
    exports: [
        InternalEngagementProjectsComponent,
    ]
})
export class InternalEngagementProjectsModule { }
