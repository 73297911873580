<!-- ========================== Desktop View ===================== -->
<section *ngIf="!deviceFactory.isSmallScreen()" class="manage-suppliers-section">
  <section class="col-md-12 col-sm-12 col-xs-12 table-responsive dm-tile__overflow-visible dm-p-imp-0"
    *ngIf="rows && rows.length">
    <section>
      <caption class="sr-only" id="manageSuppliersTable">Manage Suppliers details</caption>
      <table class="repeatItems table-responsive table borderless" aria-labelledby="manageSuppliersTable" width="100%"
        style="display: table;">
        <thead [ngStyle]="{'top': setTopPositionForStickyTableHeader()}">
          <tr class="borderlessHeader">
            <th scope="col"><p>Line Item</p></th>
            <th scope="col" class="width30Perct">
              <p>Line Item Description / EBS Assignment</p>
            </th>
            <th id="type" scope="col" class="pad-r-6">
              <p>Type
              <ng-template #resourcePopup>
                <div class="type-dialogBox">
                  <h4 class="type-dialogBox--header text-left">Purchase Orders Types</h4>
                  <ul class="type-dialogBox__ul row">
                    <li *ngFor="let data of typeOfResources" class="type-dialogBox__ul--li col-md-6 no-pad">
                      <div class="col-md-4">
                        <span class="dm-badge dm-contract-type" [ngStyle]="data.typeColorCode">
                          {{data.typeShortForm}}
                        </span>
                      </div>
                      <div class="col-md-8 text-left v-a-m fullformLbl">
                        <span>{{data.typeFullForm}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-template>
              <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type" [dmPlacement]="'bottom'"
                [dmIcon]="'tile__icon--info icon icon-info'" [ngbTooltipContent]="resourcePopup"
                ariaLabelToolTip="Type Information">
              </dm-tooltip></p>
              <span class="sr-only">press tab to navigate to tooltip button</span>
            </th>
            <th scope="col" class="rightAlignRow">
              <p>Line Item
              <br> Amount</p>
            </th>
            <th scope="col" class="rightAlignRow">
              <p>Delivery
              <br> Due Date</p>
            </th>
            <th scope="col" class="centerAlignRow">
              <p>UoM
              <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom-left'"
                [dmIcon]="'tile__icon--info icon icon-info'" [ngbTooltipContent]="uomTool"
                ariaLabelToolTip="UoM Information">
              </dm-tooltip></p>
              <ng-template #uomTool class="uomWrap">
                <p class="uomWrap--p">Unit of Measurement</p>
              </ng-template>
              <span class="sr-only">press tab to navigate to tooltip button</span>
            </th>
            <th scope="col" class="text-align-right">
            <p>Ordered
              <br> Quantity</p>
            </th>
            <th scope="col" class="text-align-right">
              <p>Cost Rate<dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type"
                [dmPlacement]="'bottom'" [dmIcon]="'tile__icon--info icon icon-info'"
                [ngbTooltipContent]="'Cost rate is only applicable for T&M PO Types'"
                ariaLabelToolTip="Cost rate is only applicable for T&M PO Types">
              </dm-tooltip></p>
            </th>
            <th scope="col" class="text-align-right">
            <p>Consumed
              <br> Quantity
              <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type" [dmPlacement]="'bottom'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                [ngbTooltipContent]="'This consumed quantity is incremental'"
                ariaLabelToolTip="this consumed quantity is incremental">
              </dm-tooltip></p>
              <span class="sr-only">press tab to navigate to tooltip button</span>
            </th>
            <th scope="col" class="text-align-right">
            <p>Posting
              <br> Quantity</p>
            </th>
            <th scope="col" class="text-align-right">
            <p>Consumed
              <br> Cost</p>
            </th>
            <th scope="col" class="text-align-right">
            <p>Invoiced Amount/
              <br> Pending</p>
            </th>
            <th scope="col" class="rightAlignRow">
            <p>Remaining
              <br> Amount</p>
            </th>
            <th scope="col" class="rightAlignRow">
            <p>PO
              <br> History</p>
            </th>
          </tr>
        </thead>
        <ng-container
          *ngFor="let row of rows | filter: (selectedPo ? {purchaseorderno: selectedPo} : selectedPo); let rowsIndex = index">
          <div class="greybckgrd contentTable" role="row">
            <div class="breadcrum" role="gridcell">
              <span class="breadcrum__potitle">
                <b>
                  Supplier PO #
                  <a [href]="myOrderLink + row.purchaseorderno" target="_blank" (click)="logEvent()"
                    [attr.aria-label]="'Supplier PO #' + row.purchaseorderno + 'link, open link in new tab'">
                    {{row.purchaseorderno}}
                  </a> 
                  <span class="sr-only">press tab to navigate to link</span>
                  <span>{{row.purchaseordertitle}}</span>
                </b>
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </span>
              <ng-template #poDetails class="purchaseOrder">
                <div class="purchaseOrder__section">
                  <h4 class="purchaseOrder__section--header">
                    Purchase Order #
                    <span>{{row.purchaseorderno}}</span>
                    details
                  </h4>
                  <div class="purchaseOrder__section--data first-data">
                    <h4 class="purchaseOrder__section--data__header">Description</h4>
                    <p class="font-caption-alt">
                      {{row.purchaseordertitle | dmDisplayDashIfEmptyOrNull}}
                    </p>
                  </div>
                  <div class="purchaseOrder__section--data">
                    <h4 class="purchaseOrder__section--data__header">Supplier</h4>
                    <p class="font-caption-alt">
                      {{row.vendor | dmDisplayDashIfEmptyOrNull}}
                    </p>
                  </div>
                </div>
              </ng-template>
              <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                [dmIcon]="'tile__icon--info icon icon-info'" [ngbTooltipContent]="poDetails"
                ariaLabelToolTip="more Information. press enter to activate tooltip.">
              </dm-tooltip>
            </div>
            <div class="pull-right">
              <span class="breadcrum p-r-50" role="gridcell">
                Supplier:
                <span>{{row.vendor}}</span>
              </span>
              <span class="breadcrum text-right pull-right total-po-tooltip" role="gridcell">
                Total PO Consumed:
                <span *ngIf="!isProjectContext">{{getPOConsumed(row.lineitems, row.poTotalAmount) |
                  number: '1.0-2'}}</span>
                <span *ngIf="isProjectContext">{{row.poConsumed | number : '1.0-2'}}</span> %
                <dm-tooltip role="tooltip" class="dm-tooltipinline p-l-4" [dmPlacement]="'left'"
                  [dmIcon]="'tile__icon--info icon icon-info'"
                  [ngbTooltipContent]="'Percent of cost consumed through approved labor/expense and/or goods receipts for entire purchase order'"
                  ariaLabelToolTip="Total PO Consumed Information">
                </dm-tooltip>
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </span>
            </div>
          </div>
          <tbody>
            <ng-container *ngFor="let lineitem of row.lineitems; let lineItemIndex = index">
              <tr *ngIf="!isFilterCheckEnable || lineitem.goodsreceipt" id="{{lineitem.poLineItemId}}" class="subRows">
                <td class="rightAlignRow p-l-12">{{lineitem.lineItem}}</td>
                <td class="width30Perct">
                  <p *ngIf="lineitem.lineItemShowWbs && lineitem.longDesc" class="description_ellipsis">
                    {{lineitem.longDesc}}
                  </p>
                  <p *ngIf="!lineitem.lineItemShowWbs || !lineitem.longDesc" class="description_ellipsis">
                    {{lineitem.shortDesc}}
                  </p>
                  <button type="button"
                    [attr.aria-label]="lineitem.shortDesc + 'More details button Press space bar to expand'"
                    id="{{row.purchaseorderno+'more-btn-'+lineItemIndex}}" class="m-t-4 dm-link set-focus"
                    *ngIf="!lineitem.lineItemShowWbs && lineitem.type !== 'EXP'"
                    (click)="toggleLineItemShowWbs(lineitem, row.purchaseorderno+'less-btn-'+lineItemIndex)">
                    More
                  </button>
                  <span class="sr-only">press tab to navigate to more button</span>
                  <div *ngIf="lineitem.lineItemShowWbs">
                    <div>
                      <ng-container *ngFor="let wbsElement of lineitem.wbsElements">
                        <p class="dm-tile__opacity06"
                          *ngIf="(wbsElement.l1Description || wbsElement.l2Description || wbsElement.l3Description)">
                          {{wbsElement.l1Description | dmDisplayDashIfEmptyOrNull}} /
                          {{wbsElement.l2Description | dmDisplayDashIfEmptyOrNull}} /
                          {{wbsElement.l3Description | dmDisplayDashIfEmptyOrNull}}
                          ({{wbsElement.wbsL2 | dmDisplayDashIfEmptyOrNull}})
                        </p>
                      </ng-container>
                    </div>
                  </div>
                  <button type="button"
                    [attr.aria-label]="lineitem.shortDesc + 'less details button Press space bar to collapse'"
                    class="m-t-4 dm-link set-focus" *ngIf="lineitem.lineItemShowWbs"
                    id="{{row.purchaseorderno+'less-btn-'+lineItemIndex}}"
                    (click)="toggleLineItemShowWbs(lineitem, row.purchaseorderno+'more-btn-'+lineItemIndex)">
                    Less
                  </button>
                </td>
                <td class="centerAlignRow">
                  <span [ngStyle]="lineitem.typeColorCode" class="dm-badge dm-contract-type">
                    {{lineitem.type}}
                  </span>
                </td>
                <td class="rightAlignRow pd-l-14">
                  <span>
                    {{lineitem.lineItemAmount | dmDisplayCurrencyOrDash: lineitem.currency}}
                  </span>
                  <span *ngIf="!lineitem.lineItemAmount"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="rightAlignRow p-l-4">
                  {{lineitem.deliveryDueDate | dmDisplayDateOrDashPipe}}
                  <span *ngIf="!lineitem.deliveryDueDate"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="centerAlignRow p-l-12">{{lineitem.uom}}</td>
                <td class="text-align-right">{{lineitem.quantity}}</td>
                <td class="text-align-right">
                  <span>{{lineitem.type === PurchaseOrderType.TM ? lineitem.itemRate : '-'}}</span>
                </td>
                <td class="text-align-right">
                  {{lineitem.deliveredText}}
                </td>
                <!-- Only the L1 PJM, L1 Delegated PJM, or L1 Additional PJM can edit the 
                                      consumed quantity (these people would have created the PO). 
                                      Not even the L0 PJM can edit the consumed quantity-->
                <td class="text-align-right pos-rel">
                  <input class="input__number" type="number" id="{{lineitem.id}}"
                    *ngIf="lineitem.deliveredMode ==='draft'"
                    [ngClass]="{'inputErrorHighlight': lineitem.showValidationMsg}"
                    [(ngModel)]="lineitem.postedQuantity"
                    min="0" max="{{lineitem.orderedAmount - 1}}"
                    [attr.aria-label]="lineitem.showValidationMsg ? lineitem.validationMsg : 'Delivered amount should be greater than or equal to the'+ lineitem.amountInvoiced + ' and less than' + lineitem.orderedAmount + '.'"
                    [title]="lineitem.showValidationMsg ? lineitem.validationMsg : 'Delivered amount should be greater than or equal to the'+ lineitem.amountInvoiced + ' and less than' + lineitem.orderedAmount + '.'"
                    (ngModelChange)="checkInputChanges(rowsIndex, lineItemIndex, $event, lineitem)">
                  <span *ngIf="lineitem.showValidationMsg" class="errorMsg" role="alert" aria-atomic="true"
                    aria-live="assertive" [attr.aria-label]="lineitem.validationMsg">
                    {{lineitem.validationMsg}}
                  </span>
                  <span *ngIf="lineitem.deliveredMode !=='draft'">-</span>
                </td>
                <td class="text-align-right">
                  {{lineitem.costComplete | dmDisplayCurrencyOrDash: lineitem.currency}}
                  <span *ngIf="!lineitem.costComplete"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="text-align-right">
                  {{lineitem.amountInvoiced | dmDisplayCurrencyOrDash: lineitem.currency}}
                  <span class="info-pending" *ngIf="lineitem.parkAmount">
                    {{lineitem.parkAmount | dmDisplayCurrencyOrDash: lineitem.currency}}
                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'left'"
                      [ngbTooltipContent]="'There is a pending invoice amount that needs to be approved'"
                      [dmIcon]="'info-triangle icon icon-info-triangle'" ariaLabelToolTip="Park Amount Information">
                    </dm-tooltip>
                    <span class="sr-only">press tab to navigate to tooltip button</span>
                  </span>
                </td>
                <td class="rightAlignRow">
                  {{lineitem.amountRemaining | dmDisplayCurrencyOrDash: lineitem.currency}}
                  <span *ngIf="!lineitem.amountRemaining"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="transactions-icon-holder rightAlignRow">
                  <button type="button" class="transactions-icon-button set-focus" role="button" aria-label="View PO Transaction Details" [title]="'View PO Transaction Details'" (click)="openManageSuppliersTransactionsModal(row, lineitem)" tabindex="0" (keyup)="handleKeyup($event)">
                    <a class="icon-full icon-more-vertical cursor-pointer"></a>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </ng-container>
      </table>
    </section>
  </section>
  <section class="dm-no-data-section">
    <dm-no-data *ngIf="rows === undefined || isEmpty(rows)" [noDataText]="noPurchaseOrders" [showImage]="true">
    </dm-no-data>
  </section>
</section>
<section *ngIf="deviceFactory.isSmallScreen()" class="manage-suppliers-section">
  <section class="col-md-12 col-sm-12 col-xs-12 table-responsive dm-tile__overflow-visible dm-p-imp-0"
    *ngIf="rows && rows.length">
    <section>
      <div class="div-wrapper">
      <caption class="sr-only" id="manageSuppliersTable">Manage Suppliers details</caption>
      <table class="repeatItems table-responsive table borderless" aria-labelledby="manageSuppliersTable" width="100%"
        style="display: table;">
        <thead>
          <tr class="borderlessHeader">
            <th scope="col">Line Item</th>
            <th scope="col" class="width30Perct">
              Line Item Description / EBS Assignment
            </th>
            <th id="type" scope="col" class="pad-r-6">
              Type
              <ng-template #resourcePopup>
                <div class="type-dialogBox">
                  <h4 class="type-dialogBox--header text-left">Purchase Orders Types</h4>
                  <ul class="type-dialogBox__ul row">
                    <li *ngFor="let data of typeOfResources" class="type-dialogBox__ul--li col-md-6 no-pad">
                      <div class="col-md-4">
                        <span class="dm-badge dm-contract-type" [ngStyle]="data.typeColorCode">
                          {{data.typeShortForm}}
                        </span>
                      </div>
                      <div class="col-md-8 text-left v-a-m fullformLbl">
                        <span>{{data.typeFullForm}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-template>
              <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type" [dmPlacement]="'bottom'"
                [dmIcon]="'tile__icon--info icon icon-info'" [ngbTooltipContent]="resourcePopup"
                ariaLabelToolTip="Type Information">
              </dm-tooltip>
              <span class="sr-only">press tab to navigate to tooltip button</span>
            </th>
            <th scope="col" class="rightAlignRow">
              Line Item
              <br> Amount
            </th>
            <th scope="col" class="rightAlignRow">
              Delivery
              <br> Due Date
            </th>
            <th scope="col" class="centerAlignRow">
              UoM
              <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom-left'"
                [dmIcon]="'tile__icon--info icon icon-info'" [ngbTooltipContent]="uomTool"
                ariaLabelToolTip="UoM Information">
              </dm-tooltip>
              <ng-template #uomTool class="uomWrap">
                <p class="uomWrap--p">Unit of Measurement</p>
              </ng-template>
              <span class="sr-only">press tab to navigate to tooltip button</span>
            </th>
            <th scope="col" class="text-align-right">
              Ordered
              <br> Quantity
            </th>
            <th scope="col" class="text-align-right">
              Cost Rate <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type"
                [dmPlacement]="'bottom'" [dmIcon]="'tile__icon--info icon icon-info'"
                [ngbTooltipContent]="'Cost rate is only applicable for T&M PO Types'"
                ariaLabelToolTip="Cost rate is only applicable for T&M PO Types">
              </dm-tooltip>
            </th>
            <th scope="col" class="text-align-right">
              Consumed
              <br> Quantity
              <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type" [dmPlacement]="'bottom'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                [ngbTooltipContent]="'This consumed quantity is incremental'"
                ariaLabelToolTip="this consumed quantity is incremental">
              </dm-tooltip>
              <span class="sr-only">press tab to navigate to tooltip button</span>
            </th>
            <th scope="col" class="text-align-right">
              Posting
              <br> Quantity
            </th>
            <th scope="col" class="text-align-right">
              Consumed
              <br> Cost
            </th>
            <th scope="col" class="text-align-right">
              Invoiced Amount/
              <br> Pending
            </th>
            <th scope="col" class="rightAlignRow">
              Remaining
              <br> Amount
            </th>
            <th scope="col" class="rightAlignRow">
              PO
              <br> History
            </th>
          </tr>
        </thead>
        <ng-container
          *ngFor="let row of rows | filter: (selectedPo ? {purchaseorderno: selectedPo} : selectedPo); let rowsIndex = index">
          <div class="greybckgrd contentTable" role="row">
            <div class="breadcrum" role="gridcell">
              <span class="breadcrum__potitle">
                <b>
                  Supplier PO #
                  <a [href]="myOrderLink + row.purchaseorderno" target="_blank" (click)="logEvent()"
                    [attr.aria-label]="'Supplier PO #' + row.purchaseorderno + 'link, open link in new tab'">
                    {{row.purchaseorderno}}
                  </a> 
                  <span class="sr-only">press tab to navigate to link</span>
                  <span>{{row.purchaseordertitle}}</span>
                </b>
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </span>
              <ng-template #poDetails class="purchaseOrder">
                <div class="purchaseOrder__section">
                  <h4 class="purchaseOrder__section--header">
                    Purchase Order #
                    <span>{{row.purchaseorderno}}</span>
                    details
                  </h4>
                  <div class="purchaseOrder__section--data first-data">
                    <h4 class="purchaseOrder__section--data__header">Description</h4>
                    <p class="font-caption-alt">
                      {{row.purchaseordertitle | dmDisplayDashIfEmptyOrNull}}
                    </p>
                  </div>
                  <div class="purchaseOrder__section--data">
                    <h4 class="purchaseOrder__section--data__header">Supplier</h4>
                    <p class="font-caption-alt">
                      {{row.vendor | dmDisplayDashIfEmptyOrNull}}
                    </p>
                  </div>
                </div>
              </ng-template>
              <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                [dmIcon]="'tile__icon--info icon icon-info'" [ngbTooltipContent]="poDetails"
                ariaLabelToolTip="more Information. press enter to activate tooltip.">
              </dm-tooltip>
            </div>
            <div class="pull-right">
              <span class="breadcrum p-r-50" role="gridcell">
                Supplier:
                <span>{{row.vendor}}</span>
              </span>
              <span class="breadcrum text-right pull-right total-po-tooltip" role="gridcell">
                Total PO Consumed:
                <span *ngIf="!isProjectContext">{{getPOConsumed(row.lineitems, row.poTotalAmount) |
                  number: '1.0-2'}}</span>
                <span *ngIf="isProjectContext">{{row.poConsumed | number : '1.0-2'}}</span> %
                <dm-tooltip role="tooltip" class="dm-tooltipinline p-l-4" [dmPlacement]="'left'"
                  [dmIcon]="'tile__icon--info icon icon-info'"
                  [ngbTooltipContent]="'Percent of cost consumed through approved labor/expense and/or goods receipts for entire purchase order'"
                  ariaLabelToolTip="Total PO Consumed Information">
                </dm-tooltip>
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </span>
            </div>
          </div>
          <tbody>
            <ng-container *ngFor="let lineitem of row.lineitems; let lineItemIndex = index">
              <tr *ngIf="!isFilterCheckEnable || lineitem.goodsreceipt" id="{{lineitem.poLineItemId}}" class="subRows">
                <td class="rightAlignRow p-l-12">{{lineitem.lineItem}}</td>
                <td class="width30Perct">
                  <p *ngIf="lineitem.lineItemShowWbs && lineitem.longDesc" class="description_ellipsis">
                    {{lineitem.longDesc}}
                  </p>
                  <p *ngIf="!lineitem.lineItemShowWbs || !lineitem.longDesc" class="description_ellipsis">
                    {{lineitem.shortDesc}}
                  </p>
                  <button type="button"
                    [attr.aria-label]="lineitem.shortDesc + 'More details button Press space bar to expand'"
                    id="{{row.purchaseorderno+'more-btn-'+lineItemIndex}}" class="m-t-4 dm-link set-focus"
                    *ngIf="!lineitem.lineItemShowWbs && lineitem.type !== 'EXP'"
                    (click)="toggleLineItemShowWbs(lineitem, row.purchaseorderno+'less-btn-'+lineItemIndex)">
                    More
                  </button>
                  <span class="sr-only">press tab to navigate to more button</span>
                  <div *ngIf="lineitem.lineItemShowWbs">
                    <div>
                      <ng-container *ngFor="let wbsElement of lineitem.wbsElements">
                        <p class="dm-tile__opacity06"
                          *ngIf="(wbsElement.l1Description || wbsElement.l2Description || wbsElement.l3Description)">
                          {{wbsElement.l1Description | dmDisplayDashIfEmptyOrNull}} /
                          {{wbsElement.l2Description | dmDisplayDashIfEmptyOrNull}} /
                          {{wbsElement.l3Description | dmDisplayDashIfEmptyOrNull}}
                          ({{wbsElement.wbsL2 | dmDisplayDashIfEmptyOrNull}})
                        </p>
                      </ng-container>
                    </div>
                  </div>
                  <button type="button"
                    [attr.aria-label]="lineitem.shortDesc + 'less details button Press space bar to collapse'"
                    class="m-t-4 dm-link set-focus" *ngIf="lineitem.lineItemShowWbs"
                    id="{{row.purchaseorderno+'less-btn-'+lineItemIndex}}"
                    (click)="toggleLineItemShowWbs(lineitem, row.purchaseorderno+'more-btn-'+lineItemIndex)">
                    Less
                  </button>
                </td>
                <td class="centerAlignRow">
                  <span [ngStyle]="lineitem.typeColorCode" class="dm-badge dm-contract-type">
                    {{lineitem.type}}
                  </span>
                </td>
                <td class="rightAlignRow pd-l-14">
                  <span>
                    {{lineitem.lineItemAmount | dmDisplayCurrencyOrDash: lineitem.currency}}
                  </span>
                  <span *ngIf="!lineitem.lineItemAmount"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="rightAlignRow p-l-4">
                  {{lineitem.deliveryDueDate | dmDisplayDateOrDashPipe}}
                  <span *ngIf="!lineitem.deliveryDueDate"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="centerAlignRow p-l-12">{{lineitem.uom}}</td>
                <td class="text-align-right">{{lineitem.quantity}}</td>
                <td class="text-align-right">
                  <span>{{lineitem.type === PurchaseOrderType.TM ? lineitem.itemRate : '-'}}</span>
                </td>
                <td class="text-align-right">
                  {{lineitem.deliveredText}}
                </td>
                <!-- Only the L1 PJM, L1 Delegated PJM, or L1 Additional PJM can edit the 
                                      consumed quantity (these people would have created the PO). 
                                      Not even the L0 PJM can edit the consumed quantity-->
                <td class="text-align-right pos-rel">
                  <input class="input__number" type="number" id="{{lineitem.id}}"
                    *ngIf="lineitem.deliveredMode ==='draft'"
                    [ngClass]="{'inputErrorHighlight': lineitem.showValidationMsg}"
                    [(ngModel)]="lineitem.postedQuantity"
                    min="0" max="{{lineitem.orderedAmount - 1}}"
                    [attr.aria-label]="lineitem.showValidationMsg ? lineitem.validationMsg : 'Delivered amount should be greater than or equal to the'+ lineitem.amountInvoiced + ' and less than' + lineitem.orderedAmount + '.'"
                    (ngModelChange)="checkInputChanges(rowsIndex, lineItemIndex, $event, lineitem)">
                  <span *ngIf="lineitem.showValidationMsg" class="errorMsg" role="alert" aria-atomic="true"
                    aria-live="assertive" [attr.aria-label]="lineitem.validationMsg">
                    {{lineitem.validationMsg}}
                  </span>
                  <span *ngIf="lineitem.deliveredMode !=='draft'">-</span>
                </td>
                <td class="text-align-right">
                  {{lineitem.costComplete | dmDisplayCurrencyOrDash: lineitem.currency}}
                  <span *ngIf="!lineitem.costComplete"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="text-align-right">
                  {{lineitem.amountInvoiced | dmDisplayCurrencyOrDash: lineitem.currency}}
                  <span class="info-pending" *ngIf="lineitem.parkAmount">
                    {{lineitem.parkAmount | dmDisplayCurrencyOrDash: lineitem.currency}}
                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'left'"
                      [ngbTooltipContent]="'There is a pending invoice amount that needs to be approved'"
                      [dmIcon]="'info-triangle icon icon-info-triangle'" ariaLabelToolTip="Park Amount Information">
                    </dm-tooltip>
                    <span class="sr-only">press tab to navigate to tooltip button</span>
                  </span>
                </td>
                <td class="rightAlignRow">
                  {{lineitem.amountRemaining | dmDisplayCurrencyOrDash: lineitem.currency}}
                  <span *ngIf="!lineitem.amountRemaining"
                    class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </td>
                <td class="transactions-icon-holder rightAlignRow">
                  <button type="button" class="transactions-icon-button set-focus" role="button" [title]="'View PO Transaction Details'" (click)="openManageSuppliersTransactionsModal(row, lineitem)" tabindex="0" (keyup)="handleKeyup($event)">
                    <a class="icon-full icon-more-vertical cursor-pointer"></a>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </ng-container>
      </table>
    </div>
    </section>
  </section>
  <section class="dm-no-data-section">
    <dm-no-data *ngIf="rows === undefined || isEmpty(rows)" [noDataText]="noPurchaseOrders" [showImage]="true">
    </dm-no-data>
  </section>
</section>
<section class="recipet-bottom-fixed">
  <button class="fxp-btn fxp-btn--primary pull-right" [disabled]="this.saveBtnDisabled" (click)="saveButtonClick()">
    Save
  </button>
</section>



<!--todo activate small screen view-->
<!--=============================== Small Screen View ===================================-->
<!--section id="gr-mobile" *ngIf="deviceFactory.isSmallScreen()" scroll-table-data ng-init="isOpen=false" class="dm-tile--b-t-t">
        <div ng-repeat="(key, value) in rows
             | filter:(!!selectedItem.poNumber ? {purchaseorderno : selectedItem.poNumber} : undefined): true
                        track by $index"
            class="repeatItems" ng-init="tableIndex = $index" width="100%" ng-class="{'dm-tile--border':value.isOpen}">
            <div class="invoice-md">
                <div class="breadcrum_header col-sm-12" role="application" ng-class="{'top-position dm-tile--bg-grey':value.isOpen}">
                    <div class="gr-left">
                        <button type="button" ng-click="value.isOpen=!value.isOpen" ng-class="{'icon-chevron-up': value.isOpen, 'icon-chevron-down': !value.isOpen}"
                            aria-expanded="{{value.isOpen}}" class="icon pull-right icon-chevron-down dm-tile--chevron-btn">
                            <span class="sr-only">PO Details</span>
                        </button>
                    </div>
                    <div class="gr-right">
                        <div class="col-xs-12 pd-b-12-mobile col-sm-5 gr-mobile--font-11">
                            <div>
                                <p class="breadcrum__potitle gr-mobile--font-11 pd-b-6 pd-t-5">
                                    Supplier PO # and Description
                                </p>
                                <p class="gr-mobile--font-13 text-bold">
                                    <a ng-href="{{myOrderLink}}{{value.purchaseorderno}}" target="_blank" ng-bind="value.purchaseorderno"></a>
                                    <span class="gr-mobile--font-13" ng-bind="value.purchaseordertitle"></span>
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-6 pd-b-12-mobile col-sm-3">
                            <label class="breadcrum p-r-60 gr-mobile--font-11">Supplier</label>
                            <p class="gr-mobile--font-13" ng-bind="value.vendor"></p>
                        </div>
                        <div class="col-xs-6 pd-b-12-mobile col-sm-4 pd-r-12 text-right">

                            <label class="breadcrum gr-mobile--font-11">
                                Total PO Consumed
                            </label>
                            <p class="gr-mobile--font-13">
                                <span *ngIf="!isProjectContext" ng-bind="getPOConsumed(value.lineitems, value.poTotalAmount) | number : 2"></span>
                                <span *ngIf="isProjectContext" ng-bind="value.poConsumed | number : 2"></span>
                                <span>%</span>
                                <dm-uib-popover role="application" class="dm-tooltipinline p-l-4" dm-aria="Total PO Consumed"
                                    dm-tooltip-aria="Total PO Consumed popup opened" dm-template="Percent of cost consumed through approved labor/expense and/or goods receipts for entire purchase order"
                                    dm-placement="bottom-right" dm-trigger="outsideClick" dm-icon="tile__icon--info icon icon-info"
                                    dm-back="totalpo{{$index}}"></dm-uib-popover>
                            </p>
                        </div>
                    </div>
                </div>
                <section class="panel-collapse" *ngIf="value.isOpen">
                    <div ng-repeat="item in value.lineitems track by $index" class="gr-right--inner" ng-hide="isFilterCheckEnable && !item.goodsreceipt">
                        <div class="gr-items">
                            <div class="col-sm-12 col-xs-12 gr-i-row">
                                <p class="gr-mobile--font-11 pd-t-12 pd-b-6">Line Item and Description/EBS Assignment</p>
                                <div class="gr-right--item-count">
                                    <p class="gr-mobile--font-13" ng-bind="item.lineItem"></p>
                                </div>
                                <div class="gr-right--item-desc">
                                    <div class="gr-mobile--font-13">
                                        <p class="gr-mobile--font-13" *ngIf="item.lineItemShowWbs" ng-bind="item.longDesc"></p>
                                        <p *ngIf="!item.lineItemShowWbs" class="gr-mobile--font-13 description-button"
                                            ng-bind="item.shortDesc"></p>
                                        <button type="button" id="{{'more-btn-'+$index}}" class="m-t-4 p-b-0 dm-link"
                                            *ngIf="!item.lineItemShowWbs && item.type != 'EXP'" ng-click="item.lineItemShowWbs=true;focus('less-btn-'+$index,true)">More</button>
                                        <div *ngIf="item.lineItemShowWbs">
                                            <p class="dm-tile__opacity06" *ngIf="(wbsElement.l1Description || wbsElement.l2Description || wbsElement.l3Description)"
                                                ng-repeat="wbsElement in item.wbsElements">
                                                {{wbsElement.l1Description}}/{{wbsElement.l2Description}}/{{wbsElement.l3Description}}({{wbsElement.wbsL2}})
                                            </p>
                                        </div>
                                        <button type="button" id="{{'less-btn-'+$index}}" class="m-t-4 p-b-0 dm-link"
                                            *ngIf="item.lineItemShowWbs" ng-click="item.lineItemShowWbs=false;focus('more-btn-'+$index,true)">Less</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-xs-12 pd-b-12-t">
                                <ariticle class="col-sm-1 col-xs-6 gr-item-data pd-l-0">
                                    <div class="pad0--smallscreen">
                                        <div class="col-sm-6 col-xs-12 pd-l-0">
                                            <div class="gr-mobile--font-11 col-xs-12 gr-mobile-tooltip pd-t-12-t pd-l-r-0"
                                                ng-init="item.show = false">
                                                <span class="pull-right-m">Type</span>
                                                <dm-uib-popover role="application" class="dm-tooltipinline type-tooltip"
                                                    dm-back="typeinfoicontip-{{$index}}" dm-aria="Resource info window"
                                                    dm-tooltip-aria="Resource Types popup opened" dm-template="#descContactPopup{{$index}}"
                                                    dm-placement="{{deviceFactory.isMobile()?'bottom-center':'bottom-left'}}"
                                                    dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="col-xs-12">
                                                <p ng-style="item.typeColorCode" class="font-caption-alt" ng-bind="item.type"
                                                    ng-class="{'c-type':deviceFactory.isMobile(),'contract-type':deviceFactory.isTablet()}"></p>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="type-dialogBox hidden" id="descContactPopup{{$index}}" ng-show="item.show">
                                                <h4 class="type-dialogBox--header text-left">Resource Types</h4>
                                                <ul class="type-dialogBox__ul col-md-12">
                                                    <li ng-repeat="data in typeOfResources track by $index" class="type-dialogBox__ul--li col-md-6 col-xs-6">
                                                        <div class="col-xs-12 text-center">
                                                            <span class="shortformLbl" ng-style="data.typeColorCode"
                                                                ng-bind="data.typeShortForm"></span>
                                                            <div class="col-md-8 col-xs-8 text-left v-a-m fullformLbl">
                                                                <span ng-bind="data.typeFullForm"></span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </ariticle>
                                <ariticle class="col-sm-2 col-xs-6 gr-item-data pd-b-24-m">
                                    <h4 class="gr-mobile--font-11 text-right">
                                        Line Item Amount
                                        <span *ngIf="item.currency" ng-bind="'('+item.currency+')'"></span>
                                    </h4>
                                    <p class="font-caption-alt" ng-bind="(item.lineItemAmount | number : 2)"></p>
                                </ariticle>
                                <ariticle class="col-sm-2 col-xs-6 col-sm-offset-0 pd-b-24-m cl-xs-offset-2 gr-item-data pd-t-14-m">
                                    <h4 class="gr-mobile--font-11">Ordered<span> Quantity</span></h4>
                                    <p class="font-caption-alt" ng-bind="item.quantity"></p>
                                </ariticle>
                                <ariticle class="col-sm-3 col-xs-6 pd-b-20-m gr-item-data" *ngIf="deviceFactory.isMobile()">
                                    <h4 class="gr-mobile--font-11 " ng-class="[item.parkAmount?'pd-r-23':'']">
                                        Invoiced Amount/
                                        <span class="d-block">Pending <span *ngIf="item.currency" ng-bind="::'('+item.currency+')'"></span></span>
                                    </h4>
                                    <p class="font-caption-alt">
                                        <span ng-bind="(item.amountInvoiced | number : 2)" ng-class="[item.parkAmount?'pd-r-23':'']"></span>
                                        <span class="info-pending" ng-class="[item.parkAmount?'visible':'invisible']">
                                            <span class="pd-r-4-m">{{item.parkAmount | currency:''}}</span>
                                            <dm-uib-popover role="application" class="dm-tooltipinline pull-right" dm-aria="Pending invoice amount"
                                                dm-tooltip-aria="There is a pending invoice amount that needs to be approved"
                                                dm-template="There is a pending invoice amount that needs to be approved"
                                                dm-placement="bottom-right" dm-trigger="outsideClick" dm-back="invoicedAmt{{$index}}"
                                                dm-icon="info-triangle icon  icon-info-triangle"></dm-uib-popover>
                                        </span>
                                    </p>
                                </ariticle>
                                <ariticle class="col-xs-6 gr-item-data pd-b-24-m" *ngIf="item.deliveredMode !='draft' && deviceFactory.isMobile()">
                                    <h4 class="gr-mobile--font-11 pd-t-2">Consumed Quantity</h4>
                                    <p class="font-caption-alt text-right" ng-bind="(item.deliveredText)"></p>
                                </ariticle>
                                <ariticle class="col-sm-2 pd-b-20-m col-xs-6 col-sm-offset-0  text-right" ng-class="{'col-xs-offset-6':item.deliveredMode==='draft'}">
                                    <h4 class="gr-mobile--font-11 pd-b-4">
                                        Consumed Cost
                                        <span><span *ngIf="item.currency" ng-bind="'('+item.currency+')'"></span></span>
                                    </h4>
                                    <p class="font-caption-alt" ng-bind="item.costComplete "></p>
                                </ariticle>
                                <ariticle class="col-sm-3 col-xs-6 gr-item-data" *ngIf="deviceFactory.isTablet()">
                                    <h4 class="gr-mobile--font-11 pd-r-23">
                                        Invoiced Amount/
                                        <span>
                                            Pending
                                            <span class="dm-tile__displayflex" *ngIf="item.currency" ng-bind="'('+item.currency+')'"></span>
                                        </span>
                                    </h4>
                                    <p class="font-caption-alt">
                                        <span class="pd-r-23" ng-bind="(getCurrencySymbol(lineitem.currency)) + (item.amountInvoiced | number : 2)"></span>
                                        <span class="info-pending" *ngIf="item.parkAmount">
                                            <span>{{item.parkAmount | currency:''}}</span>
                                            <dm-uib-popover role="application" class="dm-tooltipinline" dm-aria="Pending invoice amount"
                                                dm-tooltip-aria="There is a pending invoice amount that needs to be approved"
                                                dm-template="There is a pending invoice amount that needs to be approved"
                                                dm-placement="bottom-right" dm-trigger="outsideClick" dm-back="invoicedAmt{{$index}}"
                                                dm-icon="info-triangle icon icon-info-triangle">
                                            </dm-uib-popover>
                                        </span>
                                    </p>
                                </ariticle>
                                <ariticle class="col-sm-2 col-xs-6 gr-item-data pull-right pd-l-r-0" *ngIf="deviceFactory.isTablet()">
                                    <h4 class="gr-mobile--font-11">
                                        Remaining Amount
                                        <span *ngIf="item.currency" ng-bind="'('+item.currency+')'"></span>
                                    </h4>
                                    <p class="font-caption-alt" ng-bind="(item.amountRemaining | number : 2)"></p>
                                </ariticle>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-xs-12 pd-r-0 pd-b-12-t">
                                <ariticle class="col-sm-7 col-xs-12 pd-r-0 pull-right">
                                    <ariticle class="col-sm-12 col-xs-12 pd-r-40  pd-b-20-m" *ngIf="item.deliveredMode !='draft' && deviceFactory.isTablet()">
                                        <h4 class="col-sm-8 gr-mobile--font-11 pd-t-2 text-right">Consumed Quantity</h4>
                                        <p class="col-sm-4 font-caption-alt text-left" ng-bind="(item.deliveredText)"></p>
                                    </ariticle>
                                    <form name="lineItemForm_{{item.id}}" class="gr-item-data-form" *ngIf="item.deliveredMode==='draft'">
                                        <div class="col-sm-8 col-xs-6 pd-r-0" ng-init="item.saveBtnDisabled = false">
                                            <div class="col-xs-12 no-pad">
                                                <div class="dm-fin-deliv-block pull-right">
                                                    <label class="gr-mobile--font-11 dm-fin-deliv pd-r-12 l-h-30-t" for="{{item.id}}">Consumed
                                                        Quantity</label>
                                                    <div class="dm-fin-deliv d-inline">
                                                        <input class="input__number" type="number" id="{{item.id}}" name="deliveredForm_{{item.id}}"
                                                            ng-class="{'inputErrorHighlight': item.showValidationMsg}"
                                                            ng-model="item.deliveredText" ng-keyup="deliveredValidation(item.deliveredText,item,$event, rows)"
                                                            ng-value="{{ item.deliveredText}}" aria-label="Delivered amount should be greater than or equal to the {{item.amountInvoiced}} and less than {{item.orderedAmount}}" />
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 pd-r-0 col-xs-6 gr-item-data no-pd-right pd-t-23">
                                            <button class="fxp-btn mr-r-0 fxp-btn--primary" ng-disabled="lineItemForm_{{item.id}}.deliveredForm_{{item.id}}.$untouched || rows === undefined"
                                                ng-click="saveButtonClick()" ng-class="lineItemForm_{{item.id}}.deliveredForm_{{item.id}}.$untouched || rows === undefined ? 'btn-Save-Disabled':'btn-Save'">Save</button>
                                        </div>
                                    </form>
                                </ariticle>
                                <div class="col-sm-7 col-xs-12 col-sm-offset-2">
                                    <span class="errorMsg col-sm-12 col-xs-12 pull-right" role="alert" aria-atomic="true"
                                        aria-live="polite" ng-show="item.showValidationMsg" ng-bind="item.validationMsg"></span>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div *ngIf="row|| isObjectEmpty(rows)" class="no-data-mobile">
            <br>
            <p class="font-caption-alt text-center dm-tile__table__td">
                No purchase orders created.<button class="no-data--text__reset" ng-click="resetfilter()">Reset
                    Filters</button>
            </p>
        </div>
    </section-->