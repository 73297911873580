import { Component, forwardRef, Inject, Input, Output, EventEmitter } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { AccessibilityConstants, BulkMaintenanceStatus, Components, RouteName } from "../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IBulkMaintenanceStatus, IBulkRoleMaintenanceDocument, IResourceRequestSearchInput, IResourceRequestSearchOutput } from "../../../common/services/contracts/bulk-maintenance.service.contract";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";
import { DmGrmService } from "../../../common/services/dmgrm.service";
import { INotification } from "../../../common/services/contracts/notification-bar.contracts";
import { IAutoBulkMaintenanceErrorDetails, IAutoBulkMaintenanceStatus, IAutoBulkMaintenanceStatusResponse } from "../../../common/services/contracts/auto-bulk-maintenance.service.contract";

@Component({
    selector: "dm-auto-bulk-maintenance-status-modal",
    templateUrl: "./auto-bulk-maintenance-status-modal.html",
    styleUrls: ["./bulk-maintenance-status-modal.scss"]
})
export class AutoBulkMaintenanceStatusModalComponent extends DmModalAbstract {
    @Input() public bulkMaintenanceResponse: IAutoBulkMaintenanceStatusResponse;
    @Input() public notificationDetail: INotification;
    @Output() public onStatusModalClose: EventEmitter<void> = new EventEmitter<void>();
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public accessibilityConstants = AccessibilityConstants;
    public searchResponse: IBulkRoleMaintenanceDocument[];
    public modalContent: IModal;
    public loadingText: string;
    public BulkMaintenanceStatus = BulkMaintenanceStatus;
    private resourceRequestIds: string[];
    private unsubscribeFunction: any;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DmGrmService) private grmService: DmGrmService,
    ) {
        super(activeModal, dmLogger, Components.BulkCreateInternalEngagementStatusModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Bulk Role Maintenance Status Summary",
            subTitle: this.notificationDetail.entityId 
        };
        this.loadingText = "Loading Role Maintnance Summary";
        if (this.bulkMaintenanceResponse && this.bulkMaintenanceResponse.serviceResponse && this.bulkMaintenanceResponse.serviceResponse.length > 0) {
            this.resourceRequestIds = this.bulkMaintenanceResponse.serviceResponse.map((r) => r.eventId);
        }
        const filterString: string =
            "search.in(ResourceRequestId," +
            "'" +
            this.resourceRequestIds.join(",").toString() +
            "',',')";
        const searchInputPayload: IResourceRequestSearchInput = {
            select: [
                "ResourceRequestId",
                "AssignedResourceName",
                "Role"
            ],
            filter: filterString
        };
        this.grmService.searchResourceRequests(searchInputPayload)
            .then((response: IResourceRequestSearchOutput) => {
                if (response && response.documents && response.documents.length) {
                    this.searchResponse = response.documents;
                    for (const resource of this.searchResponse) {
                        const resourceStatus = this.bulkMaintenanceResponse.serviceResponse.filter((r) => r.eventId === resource.ResourceRequestId);
                        if (resourceStatus.length > 0) {
                            resource.Status = resourceStatus[0].status;
                            if (resourceStatus[0].validationErrors !== null && resourceStatus[0].validationErrors !== ""){
                                const statusDetails: IAutoBulkMaintenanceStatus = JSON.parse(resourceStatus[0].validationErrors);
                                if (statusDetails) {
                                    resource.IsSuccess = statusDetails.IsSuccess;
                                    resource.ResourceRequestErrorMessage = statusDetails.ErrorMessage[0].Message;
                                }

                            }
                            else{
                                resource.IsSuccess = true;
                            }
                            
                        }
                    }
                }
            });
        this.setLoadersBasedOnItemState();
    }

    /**
     * Copy failure message to the clipboard
     */
    public copyMessage(): void {
        this.sharedFunctionsService.copyToClipboard("creationErrorDetailsPopoverMsg");
    }
}

