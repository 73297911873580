<section class="col-md-12 col-sm-12 col-xs-12 dm-reload-page">
    <div id="dataText" class="text-center">
        <div>
            <h5>{{dataText}}</h5>
            <p *ngIf="showReloadMessage">{{reloadPage}}</p>
        </div>
        <div>
            <p>
                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                    [ngbTooltipContent]="ngbTooltipContent" [dmIcon]="'icon icon-error error-msg'"
                    ariaLabelToolTip="Error Information">
                </dm-tooltip>
                If problem persists
                <a [href]="reportProblemLink" target="_blank" role="link" class="dm-link" title="Report the problem" aria-label="open link in new tab">
                    report the problem
                </a>
            </p>
        </div>
    </div>
</section>