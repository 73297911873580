import { Component, Input } from "@angular/core";
import { IEngagementFinancialPlanSummary } from "../../../common/services/contracts/changerequest.contract";

@Component({
    selector: "dm-engagement-financial-plan-summary",
    templateUrl: "./engagement-financial-plan-summary.html",
    styleUrls: ["./engagement-financial-plan-summary.scss"]
})

export class EngagementFinancialPlanSummaryComponent {
    @Input() public engagementFinancialSummary: IEngagementFinancialPlanSummary;
    @Input() public engagementFinancialSummaryError: string;
    public loadingMessage: string = "Loading Financial Plan Summary...";
}