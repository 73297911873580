<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'300px'" [isLoading]="isLoadingOrUpdatingRequest"
    [showFooter]="isCosmicCancellationWorkflow || !isProjectClsoureFeatureEnabled">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true"
        *ngIf="isLoadingOrUpdatingRequest"></dm-loading>
    <div *ngIf="!isLoadingOrUpdatingRequest && selectedEntity" class="request-status-change">
        <div class="dm-entity__name">{{selectedEntity?.name}}</div>
        <div class="dm-entity__id dm-p-b-10">{{selectedEntity?.id}}</div>
        <div class="dm-form-entity">
            <label class="dm-form-entity__label" for="currentState">
                Current EBS State
            </label>
            <div class="dm-form-entity__value">{{selectedEntity.currentStatus}}</div>
        </div>
        <div class="dm-form-entity">
            <label class="dm-form-entity__label" for="dm-ebs-status-change-ddl">
                Request Change to
            </label>
            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="requestStateChangeText" [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Request Change to Information"
                (click)="tooltipClicked(LogEventConstants.RequestStateChangeTooltip)">
            </dm-tooltip>
            <section class="input__select--icon" *ngIf="!isProjectClsoureFeatureEnabled">
                <dm-dropdown-menu  [id]="'dm-ebs-status-change-ddl'" [ariaLabel]="'Status Change'" [isDropDownV2]="true"
                    [(value)]="displayText" [options]="deliveryStateListOptions" [labels]="'displayText'"
                    (change)="onStatusChange(displayText)" [isDisabled]="shouldDisableStateDropdown()">
                    <option [value]="placeholder">Select</option>
                </dm-dropdown-menu>
            </section>
            <section class="input__select--icon" *ngIf="isProjectClsoureFeatureEnabled">
                <dm-dropdown-menu [id]="'dm-ebs-status-change-ddl'" [ariaLabel]="'Status Change'" [isDropDownV2]="true"
                    [(value)]="displayText" [options]="allDeliveryStates" [labels]="'displayText'"
                    (change)="onStatusChange(displayText)" [isDisabled]="shouldDisableStateDropdown()"
                    [disabledoptions]="disableStateListOptions">
                    <option [value]="placeholder">Select</option>
                </dm-dropdown-menu>
            </section>
        </div>
    </div>
    <div *ngIf="!isLoadingOrUpdatingRequest">
        <p *ngIf="selectedRequestStatus === 'Closed'" class="dm-info-msg--primary">
            Please ensure that you have completed the following checklist before setting a project
            to Closed:
            <a href class="dm-link" aria-label="closed checklist">Closed Checklist</a>
        </p>
        <div *ngIf="entityType === 'Engagement'">
            <p class="dm-info-msg--primary">
                * Changing the EBS State field on an engagement will change all of its projects', services', and tasks'
                EBS
                States as well.
            </p>
            <p class="error-msg" *ngIf="isStateChangeErrorMessage">
                Engagement is in created state. Please click Release and Activate on Financials tab.
            </p>
        </div>
        <div *ngIf="entityType !== 'Engagement'">
            <p class="dm-info-msg--primary">
                *Changing the EBS State field on a project will change all of its services' and tasks' EBS
                state as well.
            </p>
            <p class="error-msg" *ngIf="isStateChangeErrorMessage">
                Project is in created state. Please click Release and Activate on Financials tab.
            </p>
            <p class="error-msg" *ngIf="!isPJCStatusChangeAllowed">
                Engagement is in Deco State, Project Can't be moved to Release State.
            </p>
        </div>

    </div>
    <div infoBar
        *ngIf="!isLoadingOrUpdatingRequest && !userRequestedEbsState && selectedEntity.currentStatusCode!=='RECO' && selectedEntity.currentStatusCode!=='DECO' && associatedActiveWbsIncidentsViewModel && !associatedActiveWbsIncidentsViewModel.isIncidentAtCurrentWbsLevel">
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>An EBS State change is currently pending approval at the engagement level.</span>
        </div>
    </div>
    <div infoBar
        *ngIf="!isLoadingOrUpdatingRequest && ( userRequestedEbsState==='RequestedForTechnicallyCompleted' || (userRequestedEbsState==='ReadyToClose' && selectedRequestStatus==='') || (selectedEntity.currentStatusCode==='RECO' && selectedRequestStatus==='')) && associatedActiveWbsIncidentsViewModel && associatedActiveWbsIncidentsViewModel.isIncidentAtCurrentWbsLevel && associatedActiveWbsIncidentsViewModel.currentActiveWbsIncident">
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>The EBS change to <b>"Technically Complete"</b> is currently in progress and pending CFS team processing and approval. DateOfSubmission:
                {{getTimeStampInPDT(associatedActiveWbsIncidentsViewModel.currentActiveWbsIncident.incident.createdOn)}}
                PST,<br /> Cosmic ID: <a
                    href="{{associatedActiveWbsIncidentsViewModel.currentActiveWbsIncident.incident.url}}">{{associatedActiveWbsIncidentsViewModel.currentActiveWbsIncident.id}}</a>
                <br /> <span *ngIf="userRequestedEbsState!=='ReadyToClose' && !isCosmicCancellationWorkflow">To Cancel this request, click <button
                        class="cancel-teco-button" (click)="toggleCancellationWorkflow()"> <span aria-hidden="true"
                            class="icon icon-close"></span> Cancel Request</button></span>
            </span>
        </div>
    </div>
    <div infoBar
        *ngIf="!isLoadingOrUpdatingRequest && userRequestedEbsState === 'RequestedForTechnicallyCompleted' && !associatedActiveWbsIncidentsViewModel">
        <div class="dm-modal-v2-change-information-section" *ngIf="userRequestedEbsState" role="alert"
            aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>An EBS State change to "{{userRequestedEbsState}}"
                is currently pending approval.</span>
        </div>
    </div>
    <div infoBar
        *ngIf="!isLoadingOrUpdatingRequest && userRequestedEbsState === 'RequestedForTechnicallyCompleted' && associatedActiveWbsIncidentsViewModel && !associatedActiveWbsIncidentsViewModel.isIncidentAtCurrentWbsLevel">
        <div class="dm-modal-v2-change-information-section" *ngIf="userRequestedEbsState" role="alert"
            aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>An EBS State change to "{{userRequestedEbsState}}"
                is currently pending approval.</span>
        </div>
    </div>
    <div infoBar
        *ngIf="!isLoadingOrUpdatingRequest && userRequestedEbsState && (userRequestedEbsState !== 'RequestedForTechnicallyCompleted' && userRequestedEbsState !== 'ReadyToClose')">
        <div class="dm-modal-v2-change-information-section" *ngIf="userRequestedEbsState" role="alert"
            aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>An EBS State change to "{{userRequestedEbsState}}"
                is currently pending approval.</span>
        </div>
    </div>
    <div infoBar
        *ngIf="!userHasAccessForDecoReco &&  !isLoadingOrUpdatingRequest && (entityType === 'Engagement' || entityType === 'Project') && !isInternalEngagement">
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>User doesn't have permission to update engagement/project state.</span>
        </div>
    </div>

    <div infoBar
        *ngIf="isProjectMarkedForDeletion &&  !isLoadingOrUpdatingRequest && (entityType === 'Project') && !isInternalEngagement">
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Project is Marked for Deletion, can't update project state.</span>
        </div>
    </div>
    <div *ngIf="!isLoadingOrUpdatingRequest" role="grid">
        <dm-loading loader [loadingText]="'Checking for in progress request'" [isCustomText]="true" [showLoading]="true"
            *ngIf="isCheckingPJCInProgressRequests"></dm-loading>
    </div>
    <div *ngIf="!isLoadingOrUpdatingRequest" role="grid">
        <dm-loading loader [loadingText]="'Submitting request'" [isCustomText]="true" [showLoading]="true"
            *ngIf="isOrchestrationTriggered"></dm-loading>
    </div>
    <div *ngIf="!isLoadingOrUpdatingRequest" role="grid">
        <dm-loading loader [loadingText]="'Getting ETM Details'" [isCustomText]="true" [showLoading]="true"
            *ngIf="isLoadingETMDetails && isStateReversal"></dm-loading>
    </div>
    <div infoBar
        *ngIf="isProjectClosureOrchestrationAlreadyTriggered && userHasAccessForDecoReco && !isLoadingOrUpdatingRequest && (entityType === 'Engagement' || entityType === 'Project') && !isInternalEngagement">
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>An EBS State change is currently in progress.</span>
        </div>
    </div>
    <div *ngIf="entityType === 'Engagement' && !isInternalEngagement">
        <p class="error-msg" *ngIf="isPJCOrchestrationCallFailed">
            Engagement status update to "{{selectedRequestStatus}}" failed!. Please try Again.
        </p>
        <p class="success-msg" *ngIf="isPJCOrchestrationCallSuccessful">
            Engagement status update to "{{selectedRequestStatus}}" submitted successfully!. Please check the notification bar below for
            completion status.
        </p>
    </div>
    <div *ngIf="entityType === 'Project' && !isInternalEngagement">
        <p class="error-msg" *ngIf="isPJCOrchestrationCallFailed">
            Project status update to "{{selectedRequestStatus}}" failed!. Please try Again.
        </p>
        <p class="success-msg" *ngIf="isPJCOrchestrationCallSuccessful">
            Project status update to "{{selectedRequestStatus}}" submitted successfully!. Please check the notification bar below for
            completion status.
        </p>
    </div>
    <div *ngIf="isCosmicCancellationWorkflow">
        <div>
            <label class="dm-form-entity__label" for="cancel_teco_request_comment">
                Cancel TECO Request
            </label>
            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="cancelTecoRequestText" [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Cancel TECO Request Information"
                (click)="tooltipClicked(LogEventConstants.CancelTecoRequestTooltip)">
            </dm-tooltip>
            <section class="input__select--icon">
                <input type="text" id="'cancel_teco_request_comment'" aria-label="Enter Cancellation Comment"
                    class="dm-input" [(ngModel)]="tecoCancellationComment" [name]="cancel_teco_request_comment"
                    placeholder="Enter comment here" (ngModelChange)="updateTecoCancellationComment(comment)">
            </section>
        </div>

    </div>
    <section
        *ngIf="loadDecoComponent  && selectedRequestStatusCode === 'DECO' && !isLoadingOrUpdatingRequest && (entityType === 'Engagement' || entityType === 'Project') && !isInternalEngagement && !isOrchestrationTriggered">
        <dm-deco-prevalidation [selectedEngagement]="selectedEngagement" [selectedProject]="selectedProject"
            [selectedStatusCode]="selectedRequestStatusCode" (modalCloseEvent)="closeModal()"
            (submitEvent)="submitProjectClosureRequest($event)">
        </dm-deco-prevalidation>
    </section>
    <section
        *ngIf="loadRecoComponent && selectedRequestStatusCode === 'RECO' && !isLoadingOrUpdatingRequest && entityType === 'Engagement' && !isInternalEngagement && !isOrchestrationTriggered">
        <dm-reco-prevalidation [selectedEngagement]="selectedEngagement"
            [selectedStatusCode]="selectedRequestStatusCode" (modalCloseEvent)="closeModal()"
            (submitEvent)="submitProjectClosureRequest($event)">
        </dm-reco-prevalidation>
    </section>
    <div id="engagement-teco-prevalidation" class="teco-prevalidation-section" *ngIf="!isLoadingOrUpdatingRequest && isStateReversal && (entityType === 'Engagement' || entityType === 'Project') && !isInternalEngagement && !isOrchestrationTriggered"  role="grid">
        <fxp-shared-component-control  appname="SampleApplication"
            componentname="sampleapplication-statereversalcomponent"
            [componentinputs]="reversal_shared_input"
            getcallbacks="onCancel,onSubmit" (oncallback)="onStateReversalComponentCallback($event)">
        </fxp-shared-component-control>
    </div>
    <section *ngIf="selectedRequestStatus === 'Technically Completed' && !isLoadingOrUpdatingRequest && entityType === 'Engagement' && !isInternalEngagement && !isProjectClsoureFeatureEnabled">
        <dm-engagement-teco-prevalidation (isValidEvent) = "setTecoValidationResult($event)"></dm-engagement-teco-prevalidation>
    </section>
    <div additionalFooterButtons *ngIf="isCosmicCancellationWorkflow || !isProjectClsoureFeatureEnabled">
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn"
            (click)="isCosmicCancellationWorkflow? sendCosmicCancellation(): updateEbsState()"
            *ngIf="!isLoadingOrUpdatingRequest" [disabled]="isSubmitBtnDisabled">
            Submit
        </button>
    </div>
</dm-modal-v2>