import { PlanForecastParamsActions, PlanForecastParamsActionTypes } from "./plan-forecast-params.action";
import { IPlanForecastDataParameters } from "../../common/services/contracts/project.service.contracts";
import { ILoadableState } from "../reducers";


export interface IPlanForecastParams extends ILoadableState {
    planForecastParams: IPlanForecastDataParameters;
}

export const initialState: IPlanForecastParams = {
    planForecastParams: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: PlanForecastParamsActions): { [wbsId: string]: IPlanForecastParams } {
    switch (action.type) {
        case PlanForecastParamsActionTypes.LOAD_PLAN_FORECAST_PARAMS: {
            const newState = {...state};
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case PlanForecastParamsActionTypes.LOAD_PLAN_FORECAST_PARAMS_SUCCESS: {
            const newState = {...state};
            newState[action.wbsId] = {
                ...initialState,
                planForecastParams: action.planForecastParams,
                loaded: true,
                loading: false
            };
            return newState;
        }

        case PlanForecastParamsActionTypes.LOAD_PLAN_FORECAST_PARAMS_FAIL: {
            const newState = {...state};
            newState[action.wbsId] = {
                ...initialState,
                error: action.errorMessage
            };
            return newState;
        }

        case PlanForecastParamsActionTypes.INVALIDATE_PLAN_FORECAST_PARAMS: {
            const newState = {...state};
            newState[action.wbsId] = initialState;
            return newState;
        }
    }
    return state;
}