import { Component, Inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, SourceConstants, AccessibilityConstants } from "../../../../../common/application.constants";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { IModal } from "../../../../modals/dm-modal-v2/dm-modal-v2.component";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { IMisalignedAmendmentViewModel } from "../../../amendments.contract";
import { IDemandDetails } from "../../../../../common/services/contracts/project.service.v2.contracts";

@Component({
    templateUrl: "./change-existing-demand.html",
    styleUrls: ["./change-existing-demand.scss"]
})
export class ChangeExistingDemandModalComponent extends DmModalAbstract {
    @Input() public demand: IMisalignedAmendmentViewModel;
    @Input() public existingDemands: IDemandDetails[];
    public locationName: string = "WIP";
    public formattedExistingDemands: Array<{
        value: string;
        roleDescription: string;
        demandId: string;
        taskId: string;
    }> = [];
    public selectedExistingDemand: {
        value: string;
        roleDescription: string;
        demandId: string;
        taskId: string;
    };
    public modalContent: IModal;
    public accessibilityConstants = AccessibilityConstants;

    public constructor(@Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.ChangeExistingDemandForMisalignedAmendmentModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.modalContent = {
            title: "Change existing demand",
            subTitle: "The role must be associated with a demand from CFP."
        };
        this.formattedExistingDemands = this.getExistingDemandList(this.existingDemands, this.demand.demandId);
    }

    /**
     * Change and submit the assigned demand for the given task.
     *
     * @memberof ChangeExistingDemandModalComponent
     */
    public changeAssignedDemandForAmendment(): void {
        let message: string;
        if (this.selectedExistingDemand) {
            if (this.selectedExistingDemand.demandId) {
                // split existing demand up to make it as needed
                // on receive, the new demand needs to be assigned to this item
                this.activeModal.close(this.selectedExistingDemand.demandId);
            }
            else {
                message = `Unable to change assigned demand for CR ${this.demand.crNumber}, selected demand ID is missing.`;
            }
        } else {
            message = `Unable to change assigned demand for CR ${this.demand.crNumber}, selected demand undefined.`;
        }
        this.logError(SourceConstants.Method.ChangeAssignedDemandForAmendment, message, 400);
    }

    /**
     * Gets the existing demand list by formatting the list of demands from the API into the values that the dropdown on the UI can read.
     * It strips unnecessary information and creates a readable value.
     *
     * @private
     * @param {IDemandDetails[]} existingDemands The list of existing demands available, ultimately these come from an API call upstream.
     * @returns {Array<{
     *         value: string;
     *         roleDescription: string;
     *         demandId: string;
     *         taskId: string;
     *     }>} An array of relevant detail information, including the readable value for the UI, but also independently, the demand Id, role description, and task ID.
     * @memberof ChangeExistingDemandModalComponent
     */
    private getExistingDemandList(existingDemands: IDemandDetails[], currentDemandId: string): Array<{
        value: string;
        roleDescription: string;
        demandId: string;
        taskId: string;
    }> {
        if (!existingDemands || !existingDemands.length) {
            this.logError(SourceConstants.Method.GetExistingDemandList, `Unable to create existing demands list for CR ${this.demand.crNumber}, demands list is empty or undefined.`, 400);
            return [];
        }

        return existingDemands.filter((x: IDemandDetails) => {
            return x.demandId && x.demandId !== currentDemandId; /* Filter out demands missing demand ID (error from SAP), and the currently selected demand */
        }).map((x: IDemandDetails) => {
            return {
                value: `${x.planned.roleDescription} | ${x.planned.demandId} | ${x.planned.structureId}`,
                roleDescription: x.planned.roleDescription,
                demandId: x.planned.demandId,
                taskId: x.planned.structureId
            };
        });
    }

}
