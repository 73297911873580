import { Component, forwardRef, Inject } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { Components, LogEventConstants, SourceConstants, TooltipText, DelegationTypeText } from "../../../../../common/application.constants";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { IContractType } from "../../../../../common/services/contracts/project.service.contracts";
import { IDelegationViewModel } from "../../../../engagement-detail/engagement.model";
import { IEngagementDetailsApiV2, ITeamDetailsV2, IServiceDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { IEngagementViewModel } from "../../../../engagement-detail/engagement.model";
import { IState } from "../../../../../store/reducers";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IInternalEngagementCreationCode } from "../../../../../common/services/contracts/portfolio.model";
import { ITile } from "../../../../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../../common/error.constants";

@Component({
    selector: "dm-engagement-summary-details",
    templateUrl: "./engagement-summary-details.html",
    styleUrls: ["./engagement-summary-details.scss"]
})

export class EngagementSummaryDetailsComponent extends DmComponentAbstract {
    public isInternalEngagement: boolean;
    public compassOneLink: string;
    public engagementDetails: IEngagementViewModel;
    public hasAssociatedEngagementTemplateText: string;
    public ebsStateTooltipText: string = TooltipText.EBSState;
    public isPubSecEnabled: boolean;
    public contractTypeList: IContractType[];
    public toolTipPlacement: string;
    public delegationData: IDelegationViewModel;
    public delegationTypeText = DelegationTypeText;
    public isPreQualOrPreSales: boolean = false;
    public isSrOut: boolean = false;
    public hasCostCenterOrIONumber: boolean = false;
    public tooltipContent: string;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.EngagementDetails;
    private internalEngagementTypes: IInternalEngagementCreationCode[];
   
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(EngagementDetailService) public engagementDetailService: EngagementDetailService
    ) {
        super(dmLogger, Components.EngagementSummaryDetails);
    }

    public ngOnInit(): void {
        this.toolTipPlacement = this.getEbsTooltipPlacement();
        this.hasAssociatedEngagementTemplateText = "This Engagement has associated Internal Engagements";
        this.tooltipContent = "Billing method stated in the contract. Can be a single billing method or combination of two or more methods.";
        this.isInternalEngagement = false;
        this.configManagerService.initialize();
        this.compassOneLink = this.configManagerService.getValue<string>("compassOneBaseURL");
        this.isPubSecEnabled = this.configManagerService.isFeatureEnabled("Pubsec");
        this.internalEngagementTypes = this.configManagerService.getValue<IInternalEngagementCreationCode[]>("internalEngagementCreationCodes");
        this.contractTypeList = this.configManagerService.getValue<IContractType[]>("projEngContractType");
        this.tileContent = {
            title: "Engagement Details",
            link: { name: "", url: "", tooltipText: "" }            
        };
        const selectedId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(selectedId));
        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
            if (engagementDetails.loaded) {
                this.engagementDetails = this.loadEngagementDetails(engagementDetails.engagementDetails);
                this.engagementDetails.ebsStateProgress = this.sharedFunctionsService.getProgressForEntityBeforePJC(this.engagementDetails.status);
                if (this.engagementDetails && this.tileContent.title.indexOf("|") < 0) {
                    if (this.isInternalEngagement) {
                        this.tileContent.title = "Internal " + this.tileContent.title; 
                    }
                    if (this.engagementDetails.engagementId) {
                        this.tileContent.title = this.tileContent.title + " | " + this.engagementDetails.engagementId;
                    }
                    if (!this.isInternalEngagement && this.engagementDetails.dealId) {
                        this.tileContent.link = { name: "View CompassOne Deal", url: this.compassOneLink + this.engagementDetails.dealId, tooltipText: `View ${this.engagementDetails.dealId} Deal in CompassOne` };
                    }
                }
            }
            this.refreshOnItemInvalidation(engagementDetails);
            this.setLoadersBasedOnItemState(engagementDetails);
            this.setErrorsBasedOnItemState(engagementDetails); 
            if (engagementDetails.error) {
                this.isServerError = true;
            }
        });     
    }

    /**
     * Load Engagement details to bind in view
     *
     * @memberof EngagementSummaryDetailsComponent
     */
    public loadEngagementDetails(engagement: IEngagementDetailsApiV2): IEngagementViewModel {
        if (!engagement) {
            return undefined;
        }
        this.isInternalEngagement = this.sharedFunctionsService.isEngagementInternal(engagement);
        this.hasAssociatedEngagementTemplateText = "This Engagement has associated " + this.isInternalEngagement ? "Customer Delivery Engagements" : "Internal Engagements";
        const contractType = this.isInternalEngagement ? this.getInternalEngagementContractType(engagement) : this.sharedFunctionsService.getContractType(engagement.projects);
        const pjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("PPJM", engagement);
        const delegatedPjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("DPPJM", engagement);
        const relationshipManagerObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("RELMAN", engagement);
        let srCrossCharge: string;
        let srCrossChargeType: string;
        let srCostCenter: string;
        let projectTypeCode: string;
        if (engagement.projects && engagement.projects[0]) {
            projectTypeCode = engagement.projects[0].projectTypeCode;
        }
        this.setProjectTypeCodeValue(projectTypeCode);

        if (this.isSrOut && engagement.projects && engagement.projects[0] && engagement.projects[0].services) {
            /* Internal Engagement info is referenced on the labor service/task. The expense service/task should be ignored in this instance. */
            /* There will only be one labor service/task on the engagement, so we can take the [0] index for it. */
            const nonExpenseService: IServiceDetailsV2 = engagement.projects[0].services.filter((service: IServiceDetailsV2) => service.description.toLowerCase().indexOf("expense") === -1)[0];
            if (nonExpenseService && nonExpenseService.tasks && nonExpenseService.tasks.length) {
                srCrossCharge = nonExpenseService.tasks[0].chargeTypeValue; /* This is actually a number but comes in as a string, ie "100.00" */
                srCrossChargeType = nonExpenseService.tasks[0].chargeType;
                srCostCenter = nonExpenseService.tasks[0].costObject;
            }
        }
        const engagementDetails: IEngagementViewModel = {
            costCenter: engagement.costCenter,
            currency: engagement.currency,
            customerName: engagement.customerName,
            dealId: engagement.dealId,
            delegatedPPjMbpId: (delegatedPjmObj && delegatedPjmObj[0]) ? delegatedPjmObj[0].bpid : undefined,
            delegatedPPjMName: (delegatedPjmObj && delegatedPjmObj[0]) ? delegatedPjmObj[0].name : undefined,
            description: engagement.description,
            endDate: engagement.endDate,
            engagementId: engagement.id,
            hasUnitBasedDemands: engagement.hasUnitBasedDemands,
            hasAssociatedEngagements: engagement.hasAssociatedEngagements,
            isConfidentialDeal: engagement.isConfidential,
            isPublicSector: engagement.isPublicSector,
            isUsPubSec: engagement.isPublicSector,
            name: engagement.name,
            nbueApprovalDocumentLink: engagement.nbueApprovalDocumentLink,
            opportunityId: engagement.opportunityId,
            pjMAlias: (pjmObj && pjmObj[0]) ? pjmObj[0].alias : undefined,  // todo we don't need this pjm L1 data here, it's wrong and unused
            pjMName: (pjmObj && pjmObj[0]) ? pjmObj[0].name : undefined,
            pPjMAlias: (pjmObj && pjmObj[0]) ? pjmObj[0].alias : undefined,
            pPjMbpId: (pjmObj && pjmObj[0]) ? pjmObj[0].bpid : undefined,
            pPjMName: (pjmObj && pjmObj[0]) ? pjmObj[0].name : undefined,
            primaryDomain: engagement.primaryDomain,
            projectTypeCode,
            relationshipManagerAlias: (relationshipManagerObj && relationshipManagerObj[0]) ? relationshipManagerObj[0].alias : undefined,
            relationshipManagerName: (relationshipManagerObj && relationshipManagerObj[0]) ? relationshipManagerObj[0].name : undefined,
            srCrossCharge,
            srCrossChargeType,
            srCostCenter,
            startDate: engagement.startDate,
            status: engagement.statusDescription,
            topParentId: engagement.topParentId,
            typeColorCode: this.isInternalEngagement ? undefined : this.engagementDetailService.getTypeColorCode(this.contractTypeList, contractType),
            typeOfContract: contractType,
            currentStatus: engagement.currentStatus,
            currentStatusCode: engagement.currentStatusCode
        };

        /* use pPjM and delegatedPPjMName from engagement detail to determine delegation information to show */
        this.delegationData = this.sharedFunctionsService.setDelegationInformation(engagementDetails.pPjMbpId, engagementDetails.delegatedPPjMbpId, engagementDetails.pPjMName, engagementDetails.delegatedPPjMName);
        return engagementDetails;
    }

    /**
     * Logs an event when employee link is clicked
     * @param link
     */
    public logEmployeeClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.LogEmployeeClick, LogEventConstants.EmployeeLinkClick);
    }

    /**
     * Logs an event when CompassOne link is clicked
     */
    public logCompassEvent(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.LogCompassEvent, LogEventConstants.CompassLinkClick);
    }

    /**
     *
     * @param e Function to get internal engagement contact types.
     */
    private getInternalEngagementContractType(e: IEngagementDetailsApiV2): string {
        const projectTypeCode: string  = e.projects[0].projectTypeCode;
        const codeInfo: IInternalEngagementCreationCode[] = this.internalEngagementTypes.filter((types) => types.engagementCreationCode.toString() === projectTypeCode);
        if (codeInfo[0]) {
            return codeInfo[0].typeDescription;
        }
        return undefined;
    }

    /**
     * Gets the tooltip placement for the EBS State tooltip based on the current device factory view.
     * Returns undefined if the device factory does not register as desktop, tablet, or mobile.
     *
     * @returns {string}
     * @memberof EngagementSummaryDetailsComponent
     */
    private getEbsTooltipPlacement(): string {
        if (this.deviceFactory.isDesktop() || this.deviceFactory.isTablet()) {
            return "bottom-right";
        }
        if (this.deviceFactory.isMobile()) {
            return "bottom-left";
        }
        return undefined;
    }

    /**
     * Sets a handful of flags for the view based on the project type code.
     *
     * @private
     * @param {string} projectTypeCode, retrieved from the engagement object
     * @memberof EngagementSummaryDetailsComponent
     */
    private setProjectTypeCodeValue(projectTypeCode: string): void {
        // projectTypeCode :
        //        91 indicates project is type of "Pre-Qualification",
        //        92 indicates project is type of "Pre-Sales",
        //        93 indicates project is type of "Demand Gen-Generic",
        //        94 indicates project is type of "Demand Gen-Account",
        //        95 indicates project is type of "SR Out Corp HQ",
        //        96 indicates project is type of "MS-Internal",
        //        97 indicates project is type of "Unit-Based"
        //        98 indicates project is type of "SR Out Other BG"

        this.isPreQualOrPreSales = projectTypeCode === "92" || projectTypeCode === "91";
        this.isSrOut = this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(projectTypeCode);
        this.hasCostCenterOrIONumber = projectTypeCode === "93"
            || projectTypeCode === "96"
            || projectTypeCode === "97"
            || projectTypeCode === "94"
            || projectTypeCode === "Z2";
    }
}

