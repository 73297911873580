import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ConcurService } from "../../../common/services/concur.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { KeyActionsV2Component } from "./key-actions-v2.component";
import { MilestonesService } from "../../../common/services/milestones.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { StaffingService } from "../../../common/services/staffing.service";
import { ManageSuppliersService } from "../../../common/services/manage-suppliers.service";
import { ReturnedResourceRequestModalModule } from "../returned-resource-modal/returned-resource-modal.module";
import { LaborManagementService } from "../../../common/services/labor-management.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { UnitsService } from "../../../common/services/unit.service";

@NgModule({
    declarations: [
        KeyActionsV2Component
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
        ReturnedResourceRequestModalModule
    ],
    providers: [
        ConcurService,
        ConfigManagerService,
        DMAuthorizationService,
        LaborManagementService,
        MilestonesService,
        ManageSuppliersService,
        SharedFunctionsService,
        StaffingService,
        DMLoggerService,
        UnitsService
    ],
    entryComponents: [
        KeyActionsV2Component
    ],
    exports: [
        KeyActionsV2Component
    ]
})
export class KeyActionsV2Module { }
