
import { switchMap, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { ContactsService } from "../../common/services/contacts.service";
import * as contactsActions from "./contacts.action";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class ContactsEffect {

    @Effect()
    public loadContacts$ = this.actions$
        .ofType(contactsActions.ContactsActionTypes.LOAD_CONTACTS).pipe(
            switchMap((action: contactsActions.LoadContacts) =>
                fromPromise(this.contactsService.getContactsByWbsId(action.wbsId, false))
                    .pipe(
                        map((contactsResponse: any) => new contactsActions.LoadContactsSuccess(action.wbsId, contactsResponse)),
                        catchError((error) => of(new contactsActions.LoadContactsFail(action.wbsId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error)))
                        )
                    )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(ContactsService) private contactsService: ContactsService
    ) { }
}