import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../../common/application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { ILaborManagementApprovalResponse, IBulkLaborEntries, ILaborApprovalCountBasedOnAssignments, IAssignmentDetails, ILaborDetailsResponse } from "./contracts/labor-management.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";


const additionalOptions = {
    "x-originating-system-name": "PJM-XP",
    "x-core-correlation-request-id": ""
};

@Injectable()
export class LaborManagementService extends DmServiceAbstract {

    private laborManagementServiceBaseUrl: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService    
    ) {
        super(dmLogger, Services.LaborManagementService );
        this.initializeConfig();
    }

    /**
     * Returns the pending labor count per required approval date for a given assigned or delegated approver.
     *
     * @param {string} approverAlias
     * @returns {Promise<ILaborManagementApprovalResponse>}
     * @memberof LaborManagementService
     */
    public getPendingApprovalCountByDateForApprover(approverAlias: string): Promise<ILaborManagementApprovalResponse> {
        const url = this.laborManagementServiceBaseUrl + "v1/labor" + `/${approverAlias}/pendingapprovalcountbydate?partners=aurora`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.LaborManagementApprovalsApi, [401, 500], undefined, undefined, undefined, undefined, undefined, additionalOptions);
    }


    /**
     * Returns the bulk labor entries based on assignment id and additional filters.
     *
     * @param {string} assignmentId
     * @returns {Promise<IBulkLaborEntries>}
     * @memberof LaborManagementService
     */
    public getBulkLaborEntries(assignmentId: string, submittedFor: string): Promise<IBulkLaborEntries> {
        const url = this.laborManagementServiceBaseUrl + "v1/assignments" + `/${assignmentId}/labor?submittedFor=${submittedFor}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetBulkLaborDetails, [401, 500], undefined, undefined, undefined, undefined, undefined, additionalOptions);
    }

    /**
     *  Returns the pending labor count per required approval date for a given assigned with additional filters by passing assignment ids and engagement id
     *
     * @param {string[]} assignmentIds
     * @param {string} engagementId
     * @param {approverAlias} engagementId
     * @returns {Promise<ILaborApprovalCountBasedOnAssignments>}
     * @memberof LaborManagementService
     */
    public getPendingLaborApprovalCountBasedOnAssignments(assignmentIds: string[], engagementId: string, approverAlias: string): Promise<ILaborApprovalCountBasedOnAssignments> {
        const url = this.laborManagementServiceBaseUrl + "v1/labor/" + `${approverAlias.toLowerCase()}/pendingapprovalcount`;
        const requestPayLoad = {
            assignmentIds,
            engagementId
        };
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetBulkLaborDetails, requestPayLoad, false, additionalOptions);
    }

    /**
     * Returns the bulk labor entries based on assignment id and submitter.
     *
     * @param {string} assignmentId
     * @returns {Promise<IBulkLaborEntries>}
     * @memberof LaborManagementService
     */
    public getBulkLaborEntriesBasedOnAssigments(assignmentIds: IAssignmentDetails[]): Promise<ILaborDetailsResponse> {
        const url = this.laborManagementServiceBaseUrl + "v2/assignments/labor";
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetBulkLaborDetails, assignmentIds, false, additionalOptions);
    }

    /**
     * Initialize the customer service with URIs and subscription keys.
     */
    private initializeConfig(): void {
        this.laborManagementServiceBaseUrl = this.configManagerService.getValue<string>("laborManagementServiceBaseUri");
        this.subscriptionKey = this.configManagerService.getValue<string>("laborManagementServiceSubscriptionKey");
    }
}
