import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IFinancialPlanV2 } from "../../../../financial.model";
import { Components, LogEventConstants, AccessibilityConstants, SourceConstants } from "../../../../../../common/application.constants";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { ProjectService } from "../../../../../../common/services/project.service";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { DmError } from "../../../../../../common/error.constants";
import { ErrorSeverityLevel } from "@fxp/fxpservices";

@Component({
    selector: "dm-rename-snapshot",
    templateUrl: "./rename-snapshot.html",
    styleUrls: ["./rename-snapshot.scss"]
})
export class RenameSnapshotModalComponent extends DmModalAbstract {

    @Input() public snapshotDetails: IFinancialPlanV2;
    @Input() public projectId: string;
    public newSnapshotName: string;
    public isCopyLoading: boolean = false;
    public accessibilityConstants = AccessibilityConstants;
    public dmSnapshotErrorMessage = DmError.Snapshot;

    public constructor(
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
    ) {
        super(activeModal, dmLogger, Components.RenameSnapshot);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.newSnapshotName = this.snapshotDetails.versionDescription;
    }

    /**
     * Updates the snapshot name with the newly given name.
     */
    public changeSnapshotName(): void {
        const newNameWithoutQuotes = this.newSnapshotName;
        this.newSnapshotName = '"' + this.newSnapshotName + '"';
        const versionId = this.snapshotDetails.versionId;
        this.isCopyLoading = true;
        this.projectService.updateFinancialSnapshotName(this.projectId, versionId, newNameWithoutQuotes).then(() => {
            const newSnapshotMsg = {
                isSuccess: true,
                snapshotName: newNameWithoutQuotes
            };
            // todo update the parent close function to accept a response input
            this.dmLogger.logEvent(SourceConstants.Component.RenameSnapshotModal, SourceConstants.Method.ChangeSnapshotName, LogEventConstants.ModalClosed);
            this.activeModal.close(newSnapshotMsg);
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.ChangeSnapshotName, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            const newSnapshotMsg = {
                isSuccess: false,
                snapshotName: newNameWithoutQuotes
            };
            this.dmLogger.logEvent(SourceConstants.Component.RenameSnapshotModal, SourceConstants.Method.ChangeSnapshotName, LogEventConstants.ModalClosed);
            this.activeModal.close(newSnapshotMsg);
        });
    }

    /**
     * Clear text on clicking clear icon
     */
    public clearText(): void {
        this.newSnapshotName = "";
        this.sharedFunctionsService.focus("newSnapshotName", false);
    }

    /**
     * Move focus to Next element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

}
