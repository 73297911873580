<!--================================ Edit Project Modal =================================== -->
<section>
    <form name="editProjectForm" #editProjectForm="ngForm" *ngIf="!viewResourceEnable">
        <label class="sr-only" aria-labelledby="editProjectHeading"></label>
        <button *ngIf="deviceFactory.isMobile()" title="Close" type="button" aria-label="Edit Project Dialog Close"
            class="close set-focus mar-6" (click)="closeModal()" id="closeUpdateButton"><span aria-hidden="true"
                class="icon icon-close"></span></button>
        <div class="popUpModalDialog">
            <div class="modal-header modal-noborder">
                <h4 class="modal-title font-subtitle pull-left" id="editProjectHeading">Edit Project</h4>
                <button *ngIf="!deviceFactory.isMobile()" title="Close" type="button"
                    aria-label="Edit Project Dialog Close" class="close set-focus"
                    (keydown)="moveFocusPrev($event, 'accessibilityConstants.UpdateProject')" (click)="closeModal()"
                    id="accessibilityConstants.CloseUpdateButton"><span aria-hidden="true"
                        class="icon icon-close"></span></button>
            </div>
            <dm-loading [loadingText]="'Updating Project Details'" [isCustomText]="true" [errorText]=""
                [showLoading]="true" *ngIf="isComponentLoading"></dm-loading>
            <div class="modal-body" *ngIf="!isComponentLoading">
                <section class="modal-body-container">
                    <section class="modal__input--text">
                        <label class="font-caption-alt" for="name">Name
                            <span class="required-mark" aria-hidden="true">*</span>
                        </label>
                        <input class="set-focus" type="text" #name="ngModel" id="name" name="name"
                            placeholder="Name is required" [(ngModel)]="updatedProject.name" required maxlength="40"
                            [ngClass]="{'inp-error-border' : name.invalid && !name.pristine}"
                            [attr.aria-label]="'Name ' + (40 - updatedProject.name.length) + ' of 40 characters remaining'" />
                        <div class="row">
                            <p class="remaining-char font-caption col-sm-6 col-md-6 col-xs-7">
                                {{40 - updatedProject.name.length}}/40 characters remaining
                            </p>
                            <span role="alert" class="sr-only" aria-atomic="true"
                                *ngIf="name.invalid && !name.pristine && deviceFactory.isDesktop()">
                                {{editProjectErrorMessages.ProjectNameIsRequired}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                                aria-atomic="true" *ngIf="name.invalid && !name.pristine">
                                {{editProjectErrorMessages.ProjectNameIsRequired}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                                aria-atomic="true" *ngIf="updatedProject.name && updatedProject.name.length < 3">
                                {{editProjectErrorMessages.MinimumCharacterLength}}
                            </span>
                        </div>
                    </section>
                    <section class="modal__input--text">
                        <label class="font-caption-alt" for="description">Description
                            <span class="required-mark" aria-hidden="true">*</span>
                        </label>
                        <textarea class="set-focus" maxlength="255" required #description="ngModel" name="description"
                            [placeholder]="descriptionRequired" id="description"
                            [(ngModel)]="updatedProject.description"
                            [ngClass]="{'inp-error-border' : description.invalid}"
                            [attr.aria-label]="updatedProject.description ? 'Description Required ' + (255 - updatedProject.description.length) + ' of 255 characters remaining. ' + updatedProject.description: descriptionRequired"></textarea>
                        <div class="remaining-char font-caption">
                            {{255 - updatedProject.description.length}}/255 characters remaining
                        </div>
                        <span role="alert" class="sr-only" aria-live="polite"
                            *ngIf="description.invalid && deviceFactory.isDesktop()">
                            {{editProjectErrorMessages.DescriptionRequired}}
                        </span>
                        <span role="alert" class="font-caption-alt inp-validation" aria-live="polite"
                            *ngIf="description.invalid">
                            {{editProjectErrorMessages.DescriptionRequired}}
                        </span>
                    </section>

                    <!--========= Non-mobile view of Edit Project Dates Modal =============-->
                    <section class="col-md-12 pad0 modal__input--calendar" *ngIf="!deviceFactory.isMobile()">
                        <section class="disp-flex m-b-10">
                            <section class="w-48 p-r-10">
                                <label class="font-caption-alt" for="startDate">Start Date</label>
                                <p class="input-group"
                                    [ngClass]="{'inp-error-border' : isStartDateRequired || isBeforeEngagementStartDate || isAfterChildServiceStartDate}">
                                    <dm-datepicker [minDate]="minDate"
                                        [maxDate]="maxStartDate ? maxStartDate : updatedProject.endDate"
                                        [modelDate]="updatedProject.startDate"
                                        [disabled]="disableEBSStartDateEndDateUpdates" ariaLabelButton="Start date"
                                        ariaLabelCalendar="Start date" (onDateChange)="onStartDateChange($event)"
                                        previosFousingElementId="description">
                                    </dm-datepicker>
                                </p>
                            </section>
                            <section class="w-48 p-r-10">
                                <label class="font-caption-alt" for="endDate">End Date</label>
                                <p class="input-group"
                                    [ngClass]="{'inp-error-border' : isEndDateRequired || isDateCompare || isAfterEngagementEndDate || isBeforeChildServiceEndDate}">
                                    <dm-datepicker [minDate]="minEndDate ? minEndDate : updatedProject.startDate"
                                        [maxDate]="engagementEndDate" [modelDate]="updatedProject.endDate"
                                        [disabled]="disableEBSStartDateEndDateUpdates" ariaLabelButton="End date"
                                        ariaLabelCalendar="End date" (onDateChange)="onEndDateChange($event)"
                                        previosFousingElementId="calenderBtn_Start date">
                                    </dm-datepicker>
                                </p>
                            </section>
                            <section class="w-4 m-t-25" *ngIf="isResourceRequestsLoading">
                                <dm-refresh *ngIf="isResourceRequestsLoading" [showSpinning]="isResourceRequestsLoading"
                                    [enableRefresh]="false" [loadingText]="'Loading resource request details'">
                                </dm-refresh>
                            </section>
                        </section>
                        <section>
                            <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                                *ngIf="isStartDateRequired && deviceFactory.isDesktop()">
                                {{editProjectErrorMessages.StartDateRequired}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isStartDateRequired">
                                {{editProjectErrorMessages.StartDateRequired}}
                            </span>
                            <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                                *ngIf="isBeforeEngagementStartDate && deviceFactory.isDesktop()">
                                {{getStartDateMustBeAfterEngStartMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isBeforeEngagementStartDate">
                                {{getStartDateMustBeAfterEngStartMessage()}}
                            </span>
                            <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                                *ngIf="isAfterChildServiceStartDate && deviceFactory.isDesktop()">
                                {{getStartDateMustBeEarlierThanServiceMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isAfterChildServiceStartDate">
                                {{getStartDateMustBeEarlierThanServiceMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="showActualsStartDateConflictMessage">
                                {{editProjectErrorMessages.ProjectStartDateMustBeEalierOrSameAsActualDate}}
                                ({{selectedProject.minDate | dmDisplayDateOrDashPipe}}).
                            </span>

                            <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                                *ngIf="isEndDateRequired && deviceFactory.isDesktop()">
                                {{editProjectErrorMessages.EndDateIsRequired}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isEndDateRequired">
                                {{editProjectErrorMessages.EndDateIsRequired}}
                            </span>
                            <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                                *ngIf="isDateCompare && deviceFactory.isDesktop()">
                                {{editProjectErrorMessages.EndDateCannotBeEarlierThanStartDate}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isDateCompare">
                                {{editProjectErrorMessages.EndDateCannotBeEarlierThanStartDate}}
                            </span>
                            <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                                *ngIf="isAfterEngagementEndDate && deviceFactory.isDesktop()">
                                {{getEndDateMustBeBeforeEngEndMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isAfterEngagementEndDate">
                                {{getEndDateMustBeBeforeEngEndMessage()}}
                            </span>
                            <span role="alert" class="sr-only" aria-atomic="true" aria-live="polite"
                                *ngIf="isBeforeChildServiceEndDate && deviceFactory.isDesktop()">
                                {{getEndDateCannotBeBeforeServiceEndMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isBeforeChildServiceEndDate">
                                {{getEndDateCannotBeBeforeServiceEndMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="showActualsEndDateConflictMessage">
                                {{getEndDateMustBeAfterActualsMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="showIODateValidationMessage">
                                {{editProjectErrorMessages.ProjectEndDateCannotBeLaterThanIOEndDate}} ({{selectedProject.ioDate |
                                dmDisplayDateOrDashPipe}}).
                            </span>
                        </section>
                        <section class="font-label pad-b-12 p-t-4" role="alert" aria-atomic="true" aria-live="polite"
                            *ngIf="showConflictingResources">
                            <span class="font-caption-alt inp-validation errorMsg">
                                {{getCannotChangeDateResourceConflictMessage()}}
                            </span>
                            <button class="view-resource-button" type="button" (click)="toggleResources()"
                                aria-label="View resource dialogue.">
                                View resources
                            </button>
                        </section>
                        <div class="clearfix"></div>

                        <section class="col-md-12 col-sm-12 pad-12 radio__section" *ngIf="showCascadeRadioButtons">
                            <section class="col-md-12 col-sm-12 pad0 modal__input--radio">
                                <section class="pad-b-12">
                                    <span class="input__label--text input__label--d-b font-caption mr-b-10">
                                        Apply date changes to
                                    </span>
                                </section>
                                <section class="col-md-6 col-sm-6 rightpad pad0">
                                    <label for="checkProject" ng-focus="true" class="disp-in-flex">
                                        <span class="input-radio input-radio--focus">
                                            <input id="checkProject" type="radio" name="projectEdit"
                                                [disabled]="disableProjectOnlyOption" [(ngModel)]="updateLevel"
                                                [value]="EnumUpdateLevel.projectLevel">
                                            <span class="slider round" aria-hidden="true"></span>
                                        </span>
                                        <span class="radiobtn-label font-caption-alt pad-l-8">Project Only</span>
                                    </label>
                                </section>
                                <section class="col-md-6 col-sm-6 leftpad pad0">
                                    <label for="checkAll" ng-focus="true" class="disp-in-flex">
                                        <span class="input-radio input-radio--focus">
                                            <input id="checkAll" type="radio" name="projectEdit"
                                                (change)="hideErrorMessages()" [(ngModel)]="updateLevel"
                                                [value]="EnumUpdateLevel.allLowerLevels">
                                            <span class="slider round" aria-hidden="true"></span>
                                        </span>
                                        <span class="radiobtn-label font-caption-alt pad-l-8">
                                            Project{{isInternal ? "" : ", Services,"}} and Tasks
                                        </span>
                                    </label>
                                </section>
                                <div class="clearfix"></div>
                            </section>

                        </section>


                    </section>
                    <!--===============Mobile view of Edit Project Date Modal ============-->
                    <section
                        class="col-xs-12 pad-12 pad-b-0 mar-b-12 modal__input--calendar modal__input--calendar-mobile calendar-section--wrapper"
                        *ngIf="deviceFactory.isMobile()">
                        <section class="col-xs-12 editCal pad0">
                            <label class="font-caption-alt" for="startDate">Start Date</label>
                            <p class="input-group"
                                [ngClass]="{'inp-error-border' : isStartDateRequired || isBeforeEngagementStartDate || isAfterChildServiceStartDate}">
                                <dm-datepicker [minDate]="engagementStartDate"
                                    [maxDate]="maxStartDate ? maxStartDate : updatedProject.endDate"
                                    [modelDate]="updatedProject.startDate"
                                    [disabled]="disableEBSStartDateEndDateUpdates" ariaLabelButton="Start date"
                                    ariaLabelCalendar="Start date" (onDateChange)="onStartDateChange($event)"
                                    previosFousingElementId="description">
                                </dm-datepicker>
                            </p>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isStartDateRequired">
                                {{editProjectErrorMessages.StartDateRequired}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isBeforeEngagementStartDate">
                                {{getStartDateMustBeAfterEngStartMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isAfterChildServiceStartDate">
                                {{getStartDateMustBeEarlierThanServiceMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="showActualsStartDateConflictMessage">
                                {{getStartDateMustBeBeforeActualsMessage()}}
                            </span>
                        </section>
                        <section class="col-xs-12 editCal p-t-12 pad0 pad-b-14">
                            <label class="font-caption-alt" for="endDate">End Date</label>
                            <p class="input-group"
                                [ngClass]="{'inp-error-border' : isEndDateRequired || isDateCompare || isAfterEngagementEndDate || isBeforeChildServiceEndDate}">
                                <dm-datepicker [minDate]="minEndDate ? minEndDate : updatedProject.startDate"
                                    [maxDate]="engagementEndDate" [modelDate]="updatedProject.endDate"
                                    [disabled]="disableEBSStartDateEndDateUpdates" ariaLabelButton="End date"
                                    ariaLabelCalendar="End date" (onDateChange)="onEndDateChange($event)"
                                    previosFousingElementId="calenderBtn_Start date">
                                </dm-datepicker>
                            </p>

                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isEndDateRequired">
                                {{editProjectErrorMessages.EndDateIsRequired}}
                            </span>

                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isDateCompare">
                                {{editProjectErrorMessages.EndDateCannotBeEarlierThanStartDate}}
                            </span>

                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isAfterEngagementEndDate">
                                {{getEndDateMustBeBeforeEngEndMessage()}}
                            </span>

                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="isBeforeChildServiceEndDate">
                                {{getEndDateCannotBeBeforeServiceEndMessage()}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                aria-live="polite" *ngIf="showActualsEndDateConflictMessage">
                                {{getEndDateMustBeAfterActualsMessage()}}
                            </span>
                        </section>

                        <section class="col-md-12 col-sm-12 pad0 clearfix modal__input--radio">
                            <section class="pad-b-14">
                                <span class="input__label--text input__label--d-b font-caption mr-b-10">
                                    Apply date changes to
                                </span>
                            </section>
                            <section class="pad-b-14 pad0" ng-init="updateLevel = 'projectLevelUpdateOnly'">
                                <section class="col-md-6 col-sm-6 pad-b-14 pad0">
                                    <label for="checkProject" ng-focus="true" class="disp-in-flex">
                                        <span class="input-radio input-radio--focus">
                                            <input id="checkProject" type="radio" name="projectEdit"
                                                [(ngModel)]="updateLevel" [value]="EnumUpdateLevel.projectLevel"
                                                [disabled]="disableProjectOnlyOption">
                                            <span class="slider round" aria-hidden="true"></span>
                                        </span>
                                        <span class="radiobtn-label font-caption-alt pad-l-8">Project Only</span>
                                    </label>
                                </section>
                                <section class="col-md-6 col-sm-6 pad0">
                                    <label for="checkAll" ng-focus="true" class="disp-in-flex">
                                        <span class="input-radio input-radio--focus">
                                            <input id="checkAll" type="radio" name="srCrossCharge"
                                                [(ngModel)]="updateLevel" [value]="EnumUpdateLevel.allLowerLevels">
                                            <span class="slider round" aria-hidden="true"></span>
                                        </span>
                                        <span class="radiobtn-label font-caption-alt pad-l-8">
                                            Project{{isInternal ? "" : ", Services,"}} and Tasks
                                        </span>
                                    </label>
                                </section>
                                <div class="clearfix"></div>
                            </section>

                            <section class="font-label pad-b-12 p-t-4" role="alert" aria-atomic="true"
                                aria-live="polite" *ngIf="showConflictingResources">
                                <span class="font-caption-alt inp-validation errorMsg">
                                    {{getCannotChangeDateResourceConflictMessage()}}
                                </span>
                                <button class="view-resource-button" type="button" (click)="toggleResources()"
                                    aria-label="View resource dialogue.">
                                    View resources
                                </button>
                            </section>
                        </section>
                    </section>
                    <section *ngIf="isInternal && showNbueHours" class="modal__input--text">
                        <label class="font-caption-alt" for="nbuehours">NBUE Hours</label>
                        <input class="set-focus" type="number" #nbuehours="ngModel" id="nbuehours" name="nbuehours"
                            placeholder="NBUE Hours is required" [(ngModel)]="updatedProject.totalHours" required
                            [ngClass]="{'inp-error-border' : nbuehours.invalid && !nbuehours.pristine}"
                            [attr.aria-label]="'NBUE hours'" />
                        <div class="row">
                            <span role="alert" class="sr-only" aria-atomic="true"
                                *ngIf="nbuehours.invalid && !nbuehours.pristine && deviceFactory.isDesktop()">
                                {{editProjectErrorMessages.NBUEHoursAreRequired}}
                            </span>
                            <span role="alert" class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                                aria-atomic="true" *ngIf="nbuehours.invalid && !nbuehours.pristine">
                                {{editProjectErrorMessages.NBUEHoursAreRequired}}
                            </span>
                        </div>
                    </section>
                    <dm-type-ahead [typeAheadId]="typeAheadId" [btnSearchAriaLabelText]="taSearchAriaLblTxt"
                        [typeAheadLabelText]="typeAheadLabelText" [btnCancelAriaLabelText]="taCancelAriaLblTxt"
                        [typeAheadUserValidationRequiredMessage]="taRequiredMsg" [noResults]="noResults"
                        [isMandatory]="true" [selectedUser]="selectedUser" [isRequired]="true" [isMultiselect]="false"
                        [fteOnly]="true" [modelValue]="selectedUser" (selectedUserUpdated)="onSelectUser($event)"
                        [disabled]="disablePJMUpdate" (onUserCleared)="onUserCleared($event)">
                    </dm-type-ahead>
                    <span role="alert" class="font-caption-alt inp-validation errorMsg dm-p-b-5" aria-atomic="true"
                        aria-live="polite" *ngIf="showNonfteValidationMessage">
                        {{editProjectErrorMessages.OnlyFteCanFill}}
                    </span>
                    <dm-type-ahead [typeAheadId]="additionalTypeAheadId"
                        [btnSearchAriaLabelText]="additionalSearchAriaLblTxt"
                        [typeAheadLabelText]="additionalTypeAheadLabelText" [isMandatory]="false"
                        [btnCancelAriaLabelText]="additionalCancelAriaLblTxt" [selectedUsers]="selectedUsers"
                        [typeAheadUserValidationRequiredMessage]="taRequiredMsg" [isRequired]="false"
                        [isMultiselect]="true" [fteOnly]="false" [modelValue]="multiSelectedUsers"
                        [disabled]="disableAPJMUpdate"
                        (multiSelectedUsersUpdated)="onAdditionalManagersSelected($event)">
                    </dm-type-ahead>
                </section>
            </div>
        </div>

        <div class="modal-footer" *ngIf="!deviceFactory.isMobile() && !isComponentLoading"
            [ngClass]="{'modal-noborder': !deviceFactory.isMobile()}">
            <button type="button" class="fxp-btn fxp-btn--secondary"
                [ngClass]="{'btn-block': deviceFactory.isMobile()}" id="accessibilityConstants.Cancel"
                (click)="closeModal()" (keydown)="moveFocusNext($event, 'accessibilityConstants.UpdateProject')">
                Cancel
            </button>
            <button type="button" class="fxp-btn fxp-btn--primary"
                [ngClass]="{'btn-block': deviceFactory.isMobile()}" (click)="updateProjectDetails()"
                id="accessibilityConstants.UpdateProject" [disabled]="saveButtonDisabled(editProjectForm.invalid)"
                (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseUpdateButton')">
                Save
            </button>
        </div>
        <div class="modal-footer" *ngIf="deviceFactory.isMobile()">
            <button type="button" class="fxp-btn fxp-btn--primary"
                [ngClass]="{'btn-block': deviceFactory.isMobile()}" (click)="updateProjectDetails()"
                [disabled]="saveButtonDisabled(editProjectForm.invalid)" id="updateProject">
                Save
            </button>
            <button type="button" class="fxp-btn fxp-btn--secondary"
                [ngClass]="{'btn-block': deviceFactory.isMobile()}" id="cancelMapping" (click)="closeModal()">
                Cancel
            </button>
        </div>
    </form>

    <section class="resource-details-list" *ngIf="viewResourceEnable">
        <div class="popUpModalDialog editEngagementForm">
            <section class="modal-header modal-noborder">
                <a class="dm-link font-caption-alt cursor-pointer" type="button" (click)="toggleResources()">
                    <i class="icon icon-Back"></i>
                    <span class="dm-tile--p-r-10"> Return to Edit Project</span>
                </a>
            </section>
            <div class="modal-body resources-section">
                <dm-wbs-resource-requests [startDate]="updatedProject.startDate" [endDate]="updatedProject.endDate"
                    [wbsId]="selectedProject.id"></dm-wbs-resource-requests>
            </div>
        </div>
    </section>
</section>