import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AddRoleModalComponent } from "./add-role-modal.component";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmGrmService } from "../../../../common/services/dmgrm.service";
import { OneProfileService } from "../../../../common/services/one-profile.service";
import { ProjectService } from "../../../../common/services/project.service";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { TypeAheadSkillsModule } from "../../../tiles/type-ahead-skills/type-ahead-skills.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { ResourceRolesService } from "../../../../common/services/resource-roles.service";

@NgModule({
    declarations: [
        AddRoleModalComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        FormsModule,
        NgbModule,
        TypeAheadSkillsModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        DmGrmService,
        OneProfileService,
        ProjectService,
        ProjectServiceFunctions,
        ResourceRolesService,
        SharedFunctionsService,
    ],
    entryComponents: [
        AddRoleModalComponent,
    ],
    exports: [
        AddRoleModalComponent,
    ]
})
export class AddRoleModalModule { }
