import { Component, Inject, forwardRef, Injector } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { FxpLoggerService, FxpEventBroadCastService, FxpMessageService, FxpConstants, FxpRouteService, UserInfoService, ErrorSeverityLevel } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { FxpIrisBotService, FxpContext } from "@fxp/fxpservices";
import { AuditService } from "../../common/services/audit.service";
import { combineLatest } from "rxjs";
import { Components, BroadcastEvent, InlineSurveyDataList, FeedBackEntity, FxpPartnerApplicationName, FeatureNames, DmFxpBannerMessages } from "../../common/application.constants";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FeedbackModalService } from "./../tiles/feedback-modal/feedback-modal.service";
import { getPlanForecastParamsState } from "../../store/plan-forecast-params/plan-forecast-params.selector";
import { IDemandDiff } from "../../common/services/contracts/changerequestv2.contract";
import { IExcludedPeriod, IForecastVersion, IVersionStatus, IExportToExcelResult, IFinancialPlanUpdate, IReportFlags, ReportType, SapDataLevel } from "../../common/services/contracts/project.service.contracts";
import { InvalidateFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.action";
import { InvalidatePlanForecastParams } from "../../store/plan-forecast-params/plan-forecast-params.action";
import { IPlanForecastParams } from "../../store/plan-forecast-params/plan-forecast-params.reducer";
import { IPlanForecastSavedDataResponse, IPlanForecastAuditPayload } from "../../common/services/contracts/audit.contracts";
import { IState } from "../../store/reducers";
import { PlanForecastAuditHistoryComponent } from "./plan-forecast-audit-history/plan-forecast-audit-history-modal.component";
import { ProjectServiceFunctions } from "../../common/services/projectservice-functions.service";
import { RouteName, LogEventConstants, SourceConstants } from "../../../app/common/application.constants";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IWeeklyForecastRecommendation } from "../../common/services/contracts/forecast-recommendation.contracts";
import { getEngagementForecastRecommendationsState } from "../../store/engagement-forecast-recommendations/engagement-forecast-recommendations.selector";
import { getProjectForecastRecommendationsState } from "../../store/project-forecast-recommendations/project-forecast-recommendations.selector";
import { ExportToExcelService } from "../../common/services/export-to-excel.service";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";
import { InvalidateDemands } from "../../store/demands/demands.action";
import { InvalidateDBDemands } from "../../store/db-demands/demands.action";
import { GrmForecastService } from "../../common/services/grm-forecast.service";
import { IGrmForecastAggregateHoursRequest, IGrmGetForecastRequest, IGrmGetForecastResponse } from "../../common/services/contracts/dmgrm.contracts";
import { getMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IEngagementList } from "../../common/services/contracts/portfolio.model";
import { NavigationService } from "../../common/services/navigation.service";
import { FxpFlightingService } from "../../common/services/fxpflighting.service";

@Component({
    selector: "dm-plan-forecast",
    templateUrl: "./planForecast.html",
    styleUrls: ["./planForecast.scss"]
})
export class PlanForecastComponent extends DmComponentAbstract {

    // open plan&forecast to `pageToOpen`, if `null` open to default page
    // this attribute should ONLY be set if at the same time uirouter state changes to plan&forecast
    public static financialPlanToOpen: "ContractBaseline" | "DeliveryBaseline" | "CurrentFinancialPlan" | "Forecast" = null;

    public planForecastUrl: SafeResourceUrl;
    public planForecastLoginHelperUrl: SafeResourceUrl;
    public isLoading = true;
    public errorMessage: string;
    public isToolboxOpen: boolean;
    public planForecastSurveyRef: NgbModalRef;
    public loginIssuesModal: NgbModalRef;
    public mlRecsModal: NgbModalRef;
    public showExpiredSAPAccessBotButton: boolean;
    public showMissingSAPRoleBotButton: boolean;
    public showTroubleshootLoginIssuesButton: boolean = true;
    public planForecastLoginHelperAppUrl: string;
    public planForecastLoginHelperV2Url: string = "https://sapbobjblx.microsoft.com/docs/login.html";
    public mlForecastRecommendations: IWeeklyForecastRecommendation[];
    public isBetaUser: boolean = false;
    public isRecsLoaded: boolean = false;
    public isRecsError: boolean = false;
    public isUserAuthenticatedToLumira: boolean = false;
    public userEngagementsList: IEngagementList[];
    public isInMigrationFlight: boolean = false;

    private exportFinancialsEnabled: boolean = false;
    private baseUrl: string;
    private appInsightsKey: string;
    private lumiraFrameLoadStartedAt: Date;
    private additionalTelemetryProperties: Record<string, string | number | boolean>;
    private readonly FXP_CONSTANTS = FxpConstants;
    private popupWindow: Window;

    public constructor(
        @Inject(forwardRef(() => FxpLoggerService)) private fxpLoggerService: FxpLoggerService,
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpEventBroadcastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpFlightingService)) private fxpFlightingService: FxpFlightingService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        @Inject(DMLoggerService) protected dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(AuditService) private auditService: AuditService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
        @Inject(Injector) private injector: Injector,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => FxpIrisBotService)) private fxpIrisBotService: FxpIrisBotService,
        @Inject(forwardRef(() => FxpContext)) private fxpContext: FxpContext,
        @Inject(ExportToExcelService) private exportToExcelService: ExportToExcelService,
        @Inject(NavigationService) private navigationService: NavigationService,
        @Inject(GrmForecastService) private grmForecastService: GrmForecastService
    ) {
        super(dmLogger, Components.PlanForecast);

        this.planForecastUrl = this.sanitizer.bypassSecurityTrustResourceUrl("about:blank");
    }

    public static getFinancialPlanToOpen(): "ContractBaseline" | "DeliveryBaseline" | "CurrentFinancialPlan" | "Forecast" {
        const financialPageToOpen = PlanForecastComponent.financialPlanToOpen;
        PlanForecastComponent.financialPlanToOpen = null;
        return financialPageToOpen;
    }

    public ngOnInit(): void {
        this.configManagerService.initialize();
        this.baseUrl = this.configManagerService.getValue<string>("planForecastUrl");
        this.appInsightsKey = this.configManagerService.getValue<string>("planForecastAppInsightsKey");
        this.planForecastLoginHelperAppUrl = this.configManagerService.getValue<string>("planForecastLoginHelperAppUrl");

        window.addEventListener("message", this.onPostMessageListener);

        const appName = FxpPartnerApplicationName;
        const params = { Alias: this.fxpUserInfoService.getCurrentUser() };
        const flightingServiceEnvironment = this.configManagerService.getValue<string>("flightingServiceEnvironment");
        this.fxpFlightingService.getFeatureFlags(appName, flightingServiceEnvironment, FeatureNames, params).then((response) => {
            if (response && response["ml-forecast-recs"]) {
                this.isBetaUser = true;
            }

            if (response && response["export-excel-v2"]) {
                this.exportFinancialsEnabled = true;
            }
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        }).then(() => {
            // open plan&forecast to specific plan if required
            const financialPageToOpen = PlanForecastComponent.getFinancialPlanToOpen();
            this.loadLumiraParams(this.getSelectedEntityId(), financialPageToOpen);
            this.planForecastLoginHelperUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.planForecastLoginHelperAppUrl);

            this.subscribe(
                this.fxpEventBroadcastService.On(BroadcastEvent.RefreshPlanAndForeCast, (event, args) => {
                    PlanForecastComponent.financialPlanToOpen = args.column;
                    this.store.dispatch(new InvalidatePlanForecastParams(this.getSelectedEntityId()));
                })
            );
            this.onLoadUIRouterStateChanged();
            this.subscribe(
                this.fxpEventBroadcastService.On("$stateChangeStart", this.uiRouterStateChanged)
            );

            const engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

            if (this.isBetaUser) {
                let forecastRecommendations$;
                if (this.sharedFunctionsService.isProjectContext(this.stateService)) {
                    const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
                    this.storeDispatchService.requireProjectForecastRecommendations(projectId, true).load();
                    forecastRecommendations$ = this.store.select(getProjectForecastRecommendationsState(projectId));
                } else {
                    this.storeDispatchService.requireEngagementForecastRecommendations(engagementId, true).load();
                    forecastRecommendations$ = this.store.select(getEngagementForecastRecommendationsState(engagementId));
                }

                forecastRecommendations$.pipe(untilDestroyed(this)).subscribe((forecastRecommendationState) => {
                    if (forecastRecommendationState.loaded) {
                        this.isRecsLoaded = true;
                        this.mlForecastRecommendations = forecastRecommendationState.forecastRecommendations;
                    }

                    // handle error from API
                    if (forecastRecommendationState.error) {
                        this.isRecsError = true;
                        const errorMessage = this.sharedFunctionsService.getErrorMessage(forecastRecommendationState.error, "");
                        this.logError(SourceConstants.Method.NgOnInit, forecastRecommendationState.error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        if (forecastRecommendationState.error.status && forecastRecommendationState.error.status === 404) {
                            this.mlForecastRecommendations = [];
                        }
                    }
                });
            }

            // get engagement list data for filtering
            this.storeDispatchService.requireMyPortfolioEngagementList(true, true).load();

            const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
            myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
                if (engagementList && engagementList.loaded) {
                    if (engagementList.engagementList && engagementList.engagementList.length) {
                        this.userEngagementsList = engagementList.engagementList.filter((x: IEngagementList) => x.type.toLowerCase() === "engagement");
                    }
                }
                if (engagementList.error) {
                    this.logError("getEngagementListForLoggedInUserV2", engagementList.error);
                }
            });
        });
    }

    public onLoadUIRouterStateChanged(): void {
        const currentRoute: string = this.stateService.current.name;
        if (currentRoute === RouteName.EngagementPlanForecast || currentRoute === RouteName.ProjectPlanForecast) {
            const wbsId: string = this.getSelectedEntityId();
            this.checkForecastFlightingEligibility(wbsId);
            this.checkIfMigrationIsInProgress(wbsId);
        }
    }

    /**
     * Toggles toolbar on plan and forecast tab contains feedback, faq etc.
     *
     * @memberof PlanForecastComponent
     */
    public togglePlanForecastToolbar(): void {
        this.isToolboxOpen = !this.isToolboxOpen;
    }

    /**
     * Garbage collects the window event and resets the planForecast URL when the
     * page is destroyed on the Angular lifecycle hook.
     */
    public ngOnDestroy(): void {
        this.planForecastUrl = this.sanitizer.bypassSecurityTrustResourceUrl("about:blank");
        window.removeEventListener("message", this.onPostMessageListener);
        super.ngOnDestroy();
    }


    /**
     *  Open Login issues modal popup
     *
     * @param {*} loginIssuesModal
     * @memberof PlanForecastComponent
     */
    public openLoginIssuesModal(loginIssuesModal: NgbModal): void {
        this.dmLogger.logEvent(SourceConstants.Component.PlanForecastComponent, SourceConstants.Method.OpenLoginIssuesModal, "TroubleshootLoginIssuesModalOpened");

        this.loginIssuesModal = this.modalService.open(loginIssuesModal, {
            backdrop: "static",
            windowClass: "dm-modal in active faq-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
    }

    /**
     * Opens Plan and Forecast feedback modal
     *
     * @memberof PlanForecastComponent
     */
    public openPlanForecastFeebackModal(): void {
        this.feedbackModalService.openFeedbackModal(InlineSurveyDataList.PlanAndForecastSurvey, FeedBackEntity.PlanAndForecast, SourceConstants.Component.PlanForecastComponent);
    }

    public openAuditHistoryModal(selectedPlanContext: string): void {
        const auditHistoryModalRef: NgbModalRef = this.modalService.open(PlanForecastAuditHistoryComponent, {
            backdrop: "static",
            windowClass: "dm-modal in active plan-forecast-audit-history-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        auditHistoryModalRef.componentInstance.selectedPlanContext = selectedPlanContext;
    }

    /**
     * For opening labor request modal popup
     *
     * @memberof PlanForecastComponent
     */
    public openLaborRequestModal(): void {
        // TODO: Navigate to new FCR form
    }

    /**
     * Open IRIS bot with proper params/context for issue with SAP account validity.    
     *
     * @memberof PlanForecastComponent
     */
    public onSapAccountValidityExpired(): void {
        this.dmLogger.logEvent(SourceConstants.Component.PlanForecastComponent, SourceConstants.Method.OnBotIconClicked, LogEventConstants.ExpiredSAPAccessIcon);
        this.fxpContext.saveContext("activityKey", JSON.stringify(["Expired SAP User Access", "Self"]))
            .then(() => {
                this.fxpIrisBotService.launchIrisBot();
            });
    }

    /**
     * Open IRIS bot with proper params/context for issue with proper SAP role missing.     
     *
     * @memberof PlanForecastComponent
     */
    public onSapValidRoleMissing(): void {
        this.dmLogger.logEvent(SourceConstants.Component.PlanForecastComponent, SourceConstants.Method.OnBotIconClicked, LogEventConstants.RequestSAPAccessIcon);
        this.fxpContext.saveContext("activityKey", JSON.stringify(["Request SAP Access", "Self"]))
            .then(() => {
                this.fxpIrisBotService.launchIrisBot();
            });
    }

    /**
     * Launch launchLumiraLoginHelper page in a popup so that user select from multiple AAD accounts
     */
    public launchLumiraLoginHelper(): void {
        this.popupWindow = window.open(this.planForecastLoginHelperAppUrl, "lumirapopup", "width=800,height=800");
        this.dmLogger.logEvent(SourceConstants.Component.PlanForecastComponent, SourceConstants.Method.LaunchLumiraLoginHelper, "LoginHelperLaunched");
    }

    /**
     * Launch launchLumiraLoginHelper page in a popup so that user select from multiple AAD accounts
     */
    public launchLumiraLoginHelperV2(): void {
        this.popupWindow = window.open(this.planForecastLoginHelperV2Url, "lumirapopup", "width=800,height=800");
        this.dmLogger.logEvent(SourceConstants.Component.PlanForecastComponent, SourceConstants.Method.LaunchLumiraLoginHelper, "LoginHelperLaunched");
    }

    /**
     * Log the signout click event to telemetry
     */
    public trackSignoutLinkClicked(): void {
        this.dmLogger.logEvent(SourceConstants.Component.PlanForecastComponent, SourceConstants.Method.TrackSignoutLinkClicked, "AADSignoutLinkClicked");
    }

    /**
     * Opens the modal which allows users to select which reports they want exported to Excel.
     *
     * @param {string} selectedPlanContext
     * @memberof PlanForecastComponent
     */
    private exportToExcel(eventData: IExportToExcelResult): void {
        const wbsId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService) || this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const reportFlags: IReportFlags = eventData.reportFlags;

        /* Grab all selected items from the reports array and make one call for each, including the selection as the report type;
        eventData otherwise does not change and will be saved to the blob each time. */
        const promiseArray: Array<Promise<any>> = [];
        if (reportFlags.financialPlanReport) {
            promiseArray.push(this.exportToExcelService.sendExcelDataToService(eventData, wbsId));
            /* If user is exporting their Forecast, also send an export of the financials report. */
            if (this.exportFinancialsEnabled && eventData.financialPlan === ReportType.Forecast) {
                const reportGenerationLevel: SapDataLevel = eventData.isProjectContext ? SapDataLevel.Project : SapDataLevel.Engagement;
                const localData: IExportToExcelResult = { ...eventData, financialPlan: ReportType.Financials, reportGenerationLevel };
                promiseArray.push(this.exportToExcelService.sendExcelDataToService(localData, wbsId));
            }
        }
        if (reportFlags.tmStatusReport) {
            const localData: IExportToExcelResult = { ...eventData, financialPlan: ReportType.TimeAndMaterial };
            promiseArray.push(this.exportToExcelService.sendExcelDataToService(localData, wbsId));
        }
        if (reportFlags.ffStatusReport) {
            const localData: IExportToExcelResult = { ...eventData, financialPlan: ReportType.FixedFee };
            promiseArray.push(this.exportToExcelService.sendExcelDataToService(localData, wbsId));
        }
        if (reportFlags.forecastDataDumpReport) {
            const localData: IExportToExcelResult = { ...eventData, financialPlan: ReportType.CostAndBillRates };
            promiseArray.push(this.exportToExcelService.sendExcelDataToService(localData, wbsId));
        }

        Promise.all(promiseArray).catch((error) => {
            /* Any errors with banners are caught and displayed in the export to excel service upstream */
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.Submit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        });
    }

    /**
     * change the current displayed plan in the forecast lumira application to PlanForecastComponent.financialPlanToOpen
     * 
     * PlanForecastComponent.financialPlanToOpen is set by other parts of pjm before making a state switch to
     * plan&forecast
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private uiRouterStateChanged = (evt, toState, toParams, fromState, fromParams): void => {
        // open plan&forecast to specific plan if required
        if ((toState.name === RouteName.EngagementPlanForecast || toState.name === RouteName.ProjectPlanForecast)) {
            const wbsId = this.getSelectedEntityId();
            this.checkForecastFlightingEligibility(wbsId);
            this.checkIfMigrationIsInProgress(wbsId);

            if (PlanForecastComponent.financialPlanToOpen) {
                const financialPlanToOpen = PlanForecastComponent.getFinancialPlanToOpen();
                (document.getElementById("pjm-plan-forecast-frame") as HTMLIFrameElement)
                    .contentWindow.postMessage({ eventName: "LumiraChangePlan", requestedPlan: financialPlanToOpen }, "*");
            }
        }
    };

    /**
     * Gets the selected entity ID. If the user is viewing from a project context, it gets the project ID.
     * Otherwise, the user is viewing from an engagement context and it gets the engagement ID.
     */
    private getSelectedEntityId(): string {
        // check if project id is defined, if not it means that user has selected an engagement
        let selectedId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        if (!selectedId) {
            selectedId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        }
        return selectedId;
    }

    /*
    * Set Frame Window when Financial Summary Component is initialized.
    */
    private onPostMessageListener = (event: MessageEvent): void => {
        // ensure the event is from the iframe
        if (this.baseUrl.toLowerCase().indexOf(event.origin.toLowerCase()) === -1) {
            return; // do nothing
        }

        if (event.data === "LumiraFinancialSummaryComponentInitialized") {

            this.showTroubleshootLoginIssuesButton = false; // User launched Lumira successfully

            const lumiraFrameLoadEndedAt = new Date();
            const propertyBag = this.fxpLoggerService.createPropertyBag(); // fxp will only log properties if it's defined in this `(*>﹏<*)′

            Object.keys(this.additionalTelemetryProperties).forEach((key) => propertyBag.addToBag(key, this.additionalTelemetryProperties[key]));

            this.fxpLoggerService.logMetric("DM.PlanForecastFrameLoadTime", "frameLoadTimeInMs", (lumiraFrameLoadEndedAt.getTime() - this.lumiraFrameLoadStartedAt.getTime()), propertyBag);

        } else if (event.data === "esxpthresholdexceptionrequest") {
            this.openLaborRequestModal();
        } else if (typeof (event.data) === "object" && event.data && event.data.eventName) {

            const eventName = event.data.eventName as string;
            const eventData = event.data.eventData;

            if (eventName === "lumiralogincomplete") {
                // this's the message that's sent by the Lumira App which hosts the loginnotifier component. This indicates user launched the popup to select the account and AAD login is complete. 
                // Now the iframe cab be reloaded so that the login will work. 
                this.dmLogger.logEvent(SourceConstants.Component.PlanForecastComponent, SourceConstants.Method.OnPostMessageListener, "LoginHelperLoginComplete");

                if (this.popupWindow) {
                    this.popupWindow.close();
                }

                if (this.loginIssuesModal) {
                    this.loginIssuesModal.close();
                }

                const lumiraIframe = (document.getElementById("pjm-plan-forecast-frame") as HTMLIFrameElement);
                // eslint-disable-next-line no-self-assign
                lumiraIframe.src = lumiraIframe.src; // reload the iframe

                this.isUserAuthenticatedToLumira = true;
            } else if (eventName === "ExportToExcel") {
                /* When User clicks on Export to Excel in Lumira */
                this.exportToExcel(eventData);

            } else if (eventName === "OpenAuditHistory") {
                if (event && event.data && event.data.financialPlan) {
                    this.openAuditHistoryModal(event.data.financialPlan);
                }
            } else if (eventName === "esxpthresholdexceptionrequest") {
                /* Triggered from Lumira Threshold popup. eventData includes a list of failed projectids */

                const projectsThatFailedThresholdCheck: string[] = eventData;
                const demandDiffData: IDemandDiff[] = event.data.diffData || [];

                this.store.dispatch(new InvalidateDemands(this.getSelectedEntityId()));
                this.store.dispatch(new InvalidateDBDemands(this.getSelectedEntityId()));

                if (this.sharedFunctionsService.isProjectContext(this.stateService)) {
                    this.fxpRouteService.navigatetoSpecificState(RouteName.ProjectFinancialChangeRequestForm,
                        {
                            projectsThatFailedThresholdCheck,
                            demandDiffData,
                            initializedFromPlanAndForecast: true,
                            initializedFromNewForecastExperience: false
                        }, { reload: RouteName.ProjectFinancialChangeRequestForm });
                } else {
                    this.fxpRouteService.navigatetoSpecificState(RouteName.EngagementFinancialChangeRequestForm,
                        {
                            projectsThatFailedThresholdCheck,
                            demandDiffData,
                            initializedFromPlanAndForecast: true,
                            initializedFromNewForecastExperience: false
                        }, { reload: RouteName.EngagementFinancialChangeRequestForm });
                }

            } else if (eventName.indexOf("Telemetry : ") > -1) {
                /* All Telemetry events has the event name prefix. This is generated by the lumira Telemetry component */

                const telemetryEventName = eventName.replace("Telemetry : ", ""); // Extract the original telemetry event name
                const planVersion = eventData.PlanVersion as string;

                switch (telemetryEventName.toLowerCase()) {
                    case "savesuccessful": // "SaveSuccessful" . Called when a Financial Plan(CFP/Forecast/DB) is saved in Lumira
                        this.refreshFinancials();
                        this.savePlanForecastData(eventData);
                        // eslint-disable-next-line no-case-declarations
                        const savedViews: string = eventData.SavedViews || "Labor"; // default to Labor 
                        this.saveFinancialPlanUpdates(this.getSelectedEntityId(), planVersion, savedViews.split(",").filter((v) => v /* filter out any trailing commas */));
                        break;
                    default:
                        break;
                }

            } else if (eventName === "ForecastRecommendationsRequested") {
                if (this.mlForecastRecommendations && this.mlForecastRecommendations.length) {
                    (document.getElementById("pjm-plan-forecast-frame") as HTMLIFrameElement).contentWindow.postMessage({ eventName: "ForecastRecommendationsReceived", eventData: this.mlForecastRecommendations }, "*");
                } else {
                    if (this.isRecsLoaded || this.isRecsError) {
                        this.fxpMessageService.addMessage("Forecast Recommendation is not available. Please create a support ticket.", this.FXP_CONSTANTS.messageType.error, true);
                    } else {
                        this.fxpMessageService.addMessage("Forecast Recommendation is not available yet. Please recheck after 2 minutes.", this.FXP_CONSTANTS.messageType.warning, true);
                    }
                }
            } else if (eventName === "RequestGetForecastWithProjectDetails") {
                const grmRequestPayload = event.data.request as IGrmGetForecastRequest;

                if (grmRequestPayload) {
                    this.grmForecastService.getForecastWithProjectDetails(grmRequestPayload).then((response) => {
                        let lumiraPayload: IGrmGetForecastResponse = response;
                        if (response.project.length) {
                            const projectsData = response.project.map((projectResponse) => {
                                if (projectResponse.projectDetails &&
                                    (
                                        (projectResponse.projectDetails.IsConfidential && projectResponse.projectDetails.IsConfidential.toLowerCase() === "yes") ||
                                        (projectResponse.projectDetails.IsPublicSector && projectResponse.projectDetails.IsPublicSector.toLowerCase() === "yes")
                                    )
                                ) {
                                    const confidentialProjectData = this.grmForecastService.getConfidentialProjectDataFromUserList(projectResponse.projectDetails.DemandSourceId, this.userEngagementsList);
                                    if (confidentialProjectData) {
                                        return { ...projectResponse, projectDetails: { ...projectResponse.projectDetails, ProjectName: confidentialProjectData.projectName, CustomerName: confidentialProjectData.customerName } };
                                    }
                                }

                                return projectResponse;
                            });
                            lumiraPayload = { ...response, project: projectsData };
                        }

                        (document.getElementById("pjm-plan-forecast-frame") as HTMLIFrameElement).contentWindow.postMessage({ eventName: "ResponseRequestGetForecastWithProjectDetails", eventData: lumiraPayload }, "*");
                    }).catch((error) => {
                        this.fxpMessageService.addMessage("Error trying to get resource forecast.", this.FXP_CONSTANTS.messageType.error, true);
                        this.logError("GrmGetForecastWithProjectDetails", error);
                    });
                } else {
                    this.fxpMessageService.addMessage("Error trying to get resource forecast. Invalid request.", this.FXP_CONSTANTS.messageType.error, true);
                }
            } else if (eventName === "RequestGetAggregateForecastHours") {
                const grmRequestPayload = event.data.request as IGrmForecastAggregateHoursRequest;

                if (grmRequestPayload) {
                    this.grmForecastService.getTotalForecastedHours(grmRequestPayload).then((response) => {
                        (document.getElementById("pjm-plan-forecast-frame") as HTMLIFrameElement).contentWindow.postMessage({ eventName: "ResponseRequestGetAggregateForecastHours", eventData: response }, "*");
                    }).catch((error) => {
                        this.fxpMessageService.addMessage("Error trying to get resource aggregate forecast hours.", this.FXP_CONSTANTS.messageType.error, true);
                        this.logError("GrmGetAggregateForecastHours", error);
                    });
                } else {
                    this.fxpMessageService.addMessage("Error trying to get resource aggregate forecast hours. Invalid request.", this.FXP_CONSTANTS.messageType.error, true);
                }
            }
        }
    };

    /**
     * Save Plan and Forecast data to save to audit history
     *
     * @private
     * @param {IPlanForecastSavedDataResponse} savedDataResponse
     * @memberof PlanForecastComponent
     */
    private savePlanForecastData(savedDataResponse: IPlanForecastSavedDataResponse) {
        if (savedDataResponse) {
            if (savedDataResponse.PlanVersion === "Forecast") {
                const eacAuditPayload: IPlanForecastAuditPayload = {
                    version: "EAC",
                    margin: savedDataResponse.eacMargin && +savedDataResponse.eacMargin.split("%")[0],
                    labor: savedDataResponse.eacLabor && parseFloat(savedDataResponse.eacLabor.replace(/,/g, "")), // removes all commas and converts to number
                    cost: savedDataResponse.eacCost && parseFloat(savedDataResponse.eacCost.replace(/,/g, "")), // removes all commas and converts to number
                    savedView: savedDataResponse.SavedViews,
                    revenue: savedDataResponse.eacRevenue && parseFloat(savedDataResponse.eacRevenue.replace(/,/g, "")), // removes all commas and converts to number
                    createdByAlias: savedDataResponse.createdBy ? savedDataResponse.createdBy : this.fxpUserInfoService.getCurrentUserData().alias
                };
                this.auditService.postPlanForecastAuditItem(savedDataResponse.Id, eacAuditPayload);
                const etcAuditPayload = this.getAuditPayload(savedDataResponse);
                etcAuditPayload.version = "Forecast";
                this.auditService.postPlanForecastAuditItem(savedDataResponse.Id, etcAuditPayload);
            }
            else {
                this.auditService.postPlanForecastAuditItem(savedDataResponse.Id, this.getAuditPayload(savedDataResponse));
            }
        }
    }

    /**
     * Gets audit payload that needs to be posted to post audit api.
     *
     * @private
     * @param {IPlanForecastSavedDataResponse} response
     * @return {*}  {IPlanForecastAuditPayload}
     * @memberof PlanForecastComponent
     */
    private getAuditPayload(response: IPlanForecastSavedDataResponse): IPlanForecastAuditPayload {
        return {
            version: response.PlanVersion,
            margin: response.margin && +response.margin.split("%")[0],
            labor: response.labor ? response.labor && parseFloat(response.labor.replace(/,/g, "")) : 0, // removes all commas and converts to number
            cost: response.cost && parseFloat(response.cost.replace(/,/g, "")), // removes all commas and converts to number
            savedView: response.SavedViews,
            revenue: response.revenue && parseFloat(response.revenue.replace(/,/g, "")), // removes all commas and converts to number
            createdByAlias: response.createdBy ? response.createdBy : this.fxpUserInfoService.getCurrentUserData().alias
        };
    }


    /**
     * Save the financial plan updates to Project Service
     * @param eventData 
     */
    private saveFinancialPlanUpdates(wbsId: string, planVersion: string, savedViews: string[]): void {
        this.projectServiceFunction.saveFinancialPlanSaveAction(wbsId, new Date(), planVersion, savedViews);
    }

    /**
     * refresh financials.
     */
    private refreshFinancials(): void {
        const wbsId = this.getSelectedEntityId();
        this.store.dispatch(new InvalidateFinancialDetailsV2(wbsId));
    }

    /**
     *
     * Create the associated plan and forecast lumira url from an engagement/project Id.
     * @param entityId: The Id of either an engagement or project
     */
    private loadLumiraParams(entityId: string, columnToSelect: string = null): void {

        this.sharedFunctionsService.isProjectContext(this.stateService) ? this.storeDispatchService.requireProjectDetails(entityId, true) : this.storeDispatchService.requireEngagementDetails(entityId, true);
        this.storeDispatchService
            .requirePlanForecastParams(entityId, true)
            .load();
        combineLatest([this.store.select<IPlanForecastParams>(getPlanForecastParamsState(entityId))])
            .pipe(untilDestroyed(this))
            .subscribe(([planForecastParamsState]) => {
                this.refreshOnItemInvalidation(planForecastParamsState);
                this.setLoadersBasedOnItemState(planForecastParamsState);
                this.setErrorsBasedOnItemState(planForecastParamsState);

                if (planForecastParamsState.error && (planForecastParamsState.error.indexOf("SAP GRC") >= 0 || planForecastParamsState.error.indexOf("does not exist") >= 0)) {
                    this.showExpiredSAPAccessBotButton = true;
                } else if (planForecastParamsState.error && (planForecastParamsState.error.indexOf("doesn't have necessary role to access") >= 0)) {
                    this.showMissingSAPRoleBotButton = true;
                }

                if (planForecastParamsState.loaded) {
                    const planForecastParams = planForecastParamsState.planForecastParams;
                    let planCurrency: string = "";
                    const filterByWbsid: string[] = [];
                    let engagementStartDate = "";

                    const restOfExtendedProperties: string[] = [];

                    planForecastParams.extension.forEach(({ key, value }) => {
                        if (key === "XCURR") {
                            planCurrency = value;
                        } else if (key === "FILTERBYWBSID") {
                            filterByWbsid.push(value);
                        } else if (key === "XCRDATE") {
                            engagementStartDate = value;
                        } else if ($.type(value) === "string" && $.type(key) === "string") {
                            // Add the remaining key-value pairs in extension to Query string params (F_START_WK,F_END_WK,F_EXCL_PERIODS1 etc.)

                            // ignore the following filtering query-strings until the FQR20 upgrade issue with week filtering is solved. 
                            if (["XP_START_WK", "XP_END_WK", "XP_EXCL_PERIODS1"].filter((ignoreKey) => ignoreKey === key).length === 0) {
                                restOfExtendedProperties.push(`&${key}=${value}`);
                            }

                        }

                    });

                    let version: string = planForecastParams.period.version;
                    if (!version) {
                        version = "1";
                    }

                    let currentFiscalPeriod: string = planForecastParams.period.forecastPeriod;
                    if (!currentFiscalPeriod || currentFiscalPeriod === "000/0000") {
                        // if fiscal period is not set, copy the plan start period to fiscalPeriod
                        // For Contract & Delivery Baseline this will be 000/0000 , still it's better to populate this value with plan start period.
                        currentFiscalPeriod = planForecastParams.period.startPeriod;
                    }
                    const excludedPeriods = this.getExcludedPeriods(planForecastParams.excludedPeriods);
                    const { contractBaselineStatus, deliveryBaselineStatus, currentFinancialPlanStatus } = this.getVersionStatus(planForecastParams.versions);

                    if (!columnToSelect) {
                        columnToSelect = version === "3" ? "Forecast" : (version === "2") ? "DeliveryBaseline" : "ContractBaseline";
                    }

                    let url = `${this.baseUrl}`
                        + `&XPLANID=${planForecastParams.period.planId}`
                        + `&XVERSION=${version}`
                        + `&XFISCALPERIOD=${planForecastParams.period.startPeriod} - ${planForecastParams.period.endPeriod}${excludedPeriods}`
                        + `&XPERIOD=${currentFiscalPeriod}`
                        + `&XVARIANT=${planForecastParams.period.variant}`
                        + `&XCOLUMN=${columnToSelect}`
                        + "&XCLIENT=300"
                        + `&XCURR=${planCurrency}`
                        + `&XLASTVER=${version}`
                        + `&XEBSID=${entityId}`
                        + `&XAPPINSIGHTSKEY=${this.appInsightsKey}`
                        + `&XCBSTATUS=${contractBaselineStatus}`
                        + `&XDBSTATUS=${deliveryBaselineStatus}`
                        + `&XCFPSTATUS=${currentFinancialPlanStatus}`
                        + `&XFILTERBYWBSID=${filterByWbsid.join(";")}`
                        + "&XEXPORTTOEXCEL=Y" // Feature flag to show export to excel button
                        + `&XCRDATE=${engagementStartDate}`
                        + `${restOfExtendedProperties.join("")}`
                        + `${this.getPlanSavedQueryParams(planForecastParams.financialPlanUpdates)}`
                        + "&noDetailsPanel=true";

                    // beta flight
                    if (this.isBetaUser) {
                        url = `${url}&XBETA=true`;
                    }

                    this.planForecastUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

                    this.fxpLoggerService.logInformation("DM.PlanForecastUrl", url);
                    this.lumiraFrameLoadStartedAt = new Date();

                    try {
                        const startDate = new Date(planForecastParams.period.startDate);
                        const endDate = new Date(planForecastParams.period.endDate);

                        this.additionalTelemetryProperties = {
                            CurrentView: columnToSelect,
                            IsProjectView: entityId.split(".").length > 2, // For projects the length will be 3.
                            PlanDurationInMonths: (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth())
                        };
                    } catch {
                        // ignore
                    }

                } else if (!planForecastParamsState.error) {
                    this.planForecastUrl = this.sanitizer.bypassSecurityTrustResourceUrl("about:blank");
                }
            });
    }

    /**
     * Convert versions into required query string form.
     */
    private getVersionStatus(versions: IForecastVersion[]): IVersionStatus {

        const status: IVersionStatus = {
            contractBaselineStatus: "",
            deliveryBaselineStatus: "",
            currentFinancialPlanStatus: ""
        };

        if (versions && Array.isArray(versions)) {
            versions.filter((v) => v.versionType === "ZBIDBASEL") // the version types are managed in SAP
                .forEach((v) => {
                    status.contractBaselineStatus = v.status; // New or Finalized or Active
                });

            versions.filter((v) => v.versionType === "ZDLVRBASEL") // the version types are managed in SAP
                .forEach((v) => {
                    status.deliveryBaselineStatus = v.status; // New or Finalized or Active
                });

            versions.filter((v) => v.versionType === "ZCURRENT")
                .forEach((v) => {
                    status.currentFinancialPlanStatus = v.status; // New or Finalized or Active
                });
        }

        return status;
    }

    /**
     * Convert excluded periods into required query string form.
     */
    private getExcludedPeriods(excludedPeriods: IExcludedPeriod[]): string {

        if (excludedPeriods && Array.isArray(excludedPeriods)) {
            const lumiraExcludedPeriods = [];
            excludedPeriods.forEach((ex) => {
                if ($.isNumeric(ex.period) && $.isNumeric(ex.year)) {
                    lumiraExcludedPeriods.push(`; !${String("000" + ex.period).slice(-3)}/${ex.year}`); //  !000/2019
                }
            });

            return lumiraExcludedPeriods.join(""); // output=>  ; !000/2019; !000/2020; !000/2021
        }
        return "";
    }

    /**
     * Create query string param for plan saved dates
     * @param financialPlanUpdates 
     */
    private getPlanSavedQueryParams(financialPlanUpdates: IFinancialPlanUpdate[]): string {

        if (financialPlanUpdates && Array.isArray(financialPlanUpdates)) {
            const updateQString: string[] = financialPlanUpdates.map((u) => {

                const planVersion = (u.planVersion || "").toLowerCase();
                const viewName = (u.savedView || "Labor").toUpperCase();
                const savedByAlias = (u.savedby || "");
                // Dates are stored in simplified extended ISO format : "2020-01-05T03:45:46.300Z"

                if (planVersion === "CurrentFinancialPlan".toLowerCase()) {
                    return `&XP_LAST_SAVED_ON_${viewName}=${this.getPacificDateTime(u.savedOnUtc)}&XP_LAST_SAVED_BY_USERALIAS=${savedByAlias}`;
                } else if (planVersion === "Forecast".toLowerCase()) {
                    return `&XF_LAST_SAVED_ON_${viewName}=${this.getPacificDateTime(u.savedOnUtc)}&XF_LAST_SAVED_BY_USERALIAS=${savedByAlias}`;
                } else if (planVersion === "DeliveryBaseline".toLowerCase()) {
                    return `&XDB_LAST_SAVED_ON_${viewName}=${this.getPacificDateTime(u.savedOnUtc)}&XDB_LAST_SAVED_BY_USERALIAS=${savedByAlias}`;
                }
            });

            return updateQString.join("");
        }
        return "";
    }

    /**
     * Convert UTC date time('2018-07-19T12:40:38.818Z') to Pacific Time "‎7‎/‎19‎/‎2018‎ ‎5‎:‎40‎:‎38‎ ‎AM (PT)" 
     * @param utcDateTime 
     */
    private getPacificDateTime(utcDateTime: Date): string {
        try {
            const dateString = new Date(utcDateTime).toLocaleString("en-US", { timeZone: "America/Los_Angeles" });

            if (dateString.indexOf("PST") === -1 || dateString.indexOf("PDT") === -1 || dateString.indexOf("PT") === -1) {
                return dateString + " (PT)";
            }
            return dateString;
        } catch (error) {
            // Timezone has excellent support across all modern browsers. But in case, it doesn't work (IE 11), fall back to LocaleString
            this.logError(SourceConstants.Method.GetPacificDateTime, error, "Timezone did not work", ErrorSeverityLevel && ErrorSeverityLevel.High);
            return new Date(utcDateTime).toLocaleString("en-US");
        }
    }


    /**
     * Error handling for retrieving project/engagement parameters.
     */
    private handleServiceError(serviceError: angular.IHttpPromiseCallbackArg<any>): void {
        let serviceErrorMessage = "Unknown Error";
        if (serviceError.data) {
            serviceErrorMessage = serviceError.statusText;
            if (serviceError.data.Message || serviceError.data.message) {
                serviceErrorMessage = serviceError.data.Message || serviceError.data.message;
            }
            if (serviceError.data.ErrorMessage) {
                serviceErrorMessage = serviceError.data.ErrorMessage;
            }
            if (Array.isArray(serviceError.data) && serviceError.data.length) {
                serviceErrorMessage = "";
                serviceError.data.forEach((errorMessage) => {
                    serviceErrorMessage = errorMessage.error + serviceErrorMessage;
                });
            }
            if (Array.isArray(serviceError.data.InnerErrors)) {
                serviceError.data.InnerErrors.forEach((innerError) => {
                    if (innerError.Messages) {
                        serviceErrorMessage = "";
                        innerError.Messages.forEach((message) => {
                            if (message === "User is not authorized") {
                                this.errorMessage = "You do not have access to View or Update Planning & Forecasting data.";
                            }
                            serviceErrorMessage = message + ". " + serviceErrorMessage;
                        });
                    }
                });
            }
            if (serviceError.data.length > 200 || (serviceErrorMessage && serviceErrorMessage.length > 200)) {
                serviceErrorMessage = " Service Failed" + " (" + serviceError.status + " " + serviceError.statusText + ") ";
            }
            if (!this.errorMessage) {
                this.errorMessage = serviceErrorMessage;
            }
        }
        this.endComponentLoad();
    }


    /**
     * Checks the engagement/project's eligibility for forecast 2.0 pilot flighting group.
     * Adds banner message if eligible.
     *
     * @private
     * @param {string} wbsId
     * @memberof PlanForecastComponent
     */
    private checkForecastFlightingEligibility(wbsId: string): void {
        this.navigationService.isNewForecastEnabled(wbsId).then((flightingResponse) => {
            if (flightingResponse && flightingResponse.isInForecastFlight) {
                this.fxpMessageService.addMessage(DmFxpBannerMessages.ForecastFlightingMessage, this.FXP_CONSTANTS.messageType.warning, true);
            }
        });

        this.navigationService.isNewPlanEnabled(wbsId).then((flightingResponse) => {
            if (flightingResponse && flightingResponse.isInPlanFlight) {
                this.fxpMessageService.addMessage(DmFxpBannerMessages.PlanFlightingMessage, this.FXP_CONSTANTS.messageType.warning, true);
            }
        });
    }

    /**
     * Checks if there is forecast data migration is in progress for passed wbsId
     * @param wbsId 
     */
    private checkIfMigrationIsInProgress(wbsId: string): void {
        this.navigationService.isMigrationInProgress(wbsId)
            .then((flightingResponse) => {
                if (flightingResponse && flightingResponse.isInMigrationFlight) {
                    const loggedInUserBPID = Number(this.fxpUserInfoService.getLoggedInUserProfile().BusinessPartnerId);
                    const migrationFlightAccessBPIds = this.configManagerService.getValue<number[]>("MigrationFlightAccessBPIds");
                    this.isInMigrationFlight = !migrationFlightAccessBPIds.find((bpid) => loggedInUserBPID === bpid);
                    this.fxpMessageService.addMessage(DmFxpBannerMessages.ForecastMigrationInProgressMessage, this.FXP_CONSTANTS.messageType.warning, true);
                } else {
                    this.isInMigrationFlight = false;
                }
            });
    }
}
