import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { Injectable, Inject } from "@angular/core";
import { DataService } from "./data.service";
import { IAuditEntriesApiResponse, IPlanForecastAuditPayload, IWbsAuditPayload } from "./contracts/audit.contracts";
import { DMLoggerService } from "./dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { IRiskReserveAuditPayload } from "./contracts/changerequest.contract";
@Injectable()
export class AuditService extends DmServiceAbstract {
    private projectServiceBaseUri: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.AuditService);
        this.projectServiceBaseUri = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v1.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }

    /**
     * Gets audit history based on wbs id and audit type.
     *
     * @param {string} wbsId
     * @param {string} auditType
     * @return {*}  {Promise<IAuditEntriesApiResponse>}
     * @memberof AuditService
     */
    public getAuditHistory(wbsId: string, auditType: string): Promise<IAuditEntriesApiResponse> {
        const url = `${this.projectServiceBaseUri}/wbs/${wbsId}/audit?auditType=${auditType}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetAuditHistory);
    }

    /**
     * Gets audit history based on wbs id list and audit type.
     *
     * @param {string} wbsIdList
     * @param {string} auditType
     * @return {*}  {Promise<IAuditEntriesApiResponse>}
     * @memberof AuditService
     */
    public getAuditHistoryForWbsList(wbsIdList: string[], pjmAuditType: string): Promise<IAuditEntriesApiResponse> {
        const auditPayload = {
            wbsIds: wbsIdList,
            auditType: pjmAuditType
        };
        const url = `${this.projectServiceBaseUri}/wbs/audit/search`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetAuditHistoryByWbsIdList, auditPayload);
    }

    /**
     * Post plan and forecast audit item
     *
     * @param {string} wbsId
     * @param {IPlanForecastAuditPayload} auditPayload
     * @return {*}  {Promise<any>}
     * @memberof AuditService
     */
    public postPlanForecastAuditItem(wbsId: string, auditPayload: IPlanForecastAuditPayload): Promise<any> {
        const url = `${this.projectServiceBaseUri}/wbs/${wbsId}/audit/Forecast`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.PostAuditItem, auditPayload);
    }

    public postRiskResreveAuditItem(wbsId: string, auditPayload: IRiskReserveAuditPayload): Promise<any> {
        const url = `${this.projectServiceBaseUri}/wbs/${wbsId}/audit/riskReserve`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.PostAuditItem, auditPayload);
    }

    /**
     * Post plan and forecast audit item
     *
     * @param {string} wbsId
     * @param {IWbsAuditPayload} auditPayload
     * @return {*}  {Promise<any>}
     * @memberof AuditService
     */
    public postWbsAuditItem(wbsId: string, auditPayload: IWbsAuditPayload): Promise<any> {
        const url = `${this.projectServiceBaseUri}/wbs/${wbsId}/audit`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.PostAuditItem, auditPayload);
    }
}