<section>
    <div role="grid">
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row" class="dm-grid-view-table__row dm-grid-view-table__row-header">
                <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-12-y-8">
                    Category
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell display-block-imp text-align-right justify-content-flex-end dm-p-x-12-y-8">
                    <span>
                        Contract Baseline
                    </span>
                    <br />
                    (Finalized)
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell text-align-right justify-content-flex-end dm-p-x-12-y-8"
                    [ngClass]="{'active-column-header': showDbDetails}">
                    <div class="cursor-pointer db-title" [ngClass]="{'dm-link': !showDbDetails}"
                    (click)="onDbClick()">
                        Delivery Baseline
                    </div>
                    <br />
                    (Finalized)
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell display-block-imp text-align-right justify-content-flex-end dm-p-x-12-y-8"
                    [ngClass]="{'active-column-header': showCfpDetails}">
                    <span class="cursor-pointer" [ngClass]="{'dm-link': !showCfpDetails}"
                    (click)="onCfpClick()">
                        Current
                        <br />
                        Financial Plan
                    </span>
                    <br />
                    (Active)
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell text-align-right justify-content-flex-end dm-p-x-12-y-8">
                    Actuals
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell text-align-right justify-content-flex-end dm-p-x-12-y-8"
                    [ngClass]="{'active-column-header': showForecastDetails}">
                    <span class="cursor-pointer" [ngClass]="{'dm-link': !showForecastDetails}"
                        (click)="onForecastClick()">Forecast / ETC</span>
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell text-align-right justify-content-flex-end dm-p-x-12-y-8">
                    EAC
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell text-align-right justify-content-flex-end dm-p-x-12-y-8">
                    EAC Variance to
                    <br />
                    Delivery Baseline
                </div>
            </div>
        </div>
        <div role="rowgroup" class="dm-grid-view-table">
            <div class="dm-grid-view-table__body" *ngFor="let plan of planDetails">
                <div role="row" class="dm-grid-view-table__row">
                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitalize">
                        <span class="dm-p-r-5" *ngIf="plan.category === 'cost' || plan.category === 'revenue'">
                            <span class="icon icon-ChevronDown set-focus" tabindex="0" role="button"></span>
                        </span>
                        {{plan.category}}
                        <span class="dm-p-r-5" *ngIf="plan.category === 'cost' || plan.category === 'revenue'">
                            ({{planCurrency}})
                        </span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8">
                        <span *ngIf="plan.category === 'margin'">
                            <!-- dividing by 100 to use percent pipe to show percentage as the value is already coming in percetage from the response -->
                            {{plan.contractBaseline/100 | percent: '1.2-2'}}
                        </span>
                        <span *ngIf="plan.category !== 'margin'">
                            {{plan.contractBaseline | number: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                        </span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8"
                        [ngClass]="{'active-column-row': showDbDetails}">
                        <span *ngIf="plan.category === 'margin'">
                            {{plan.deliveryBaseline/100 | percent: '1.2-2'}}
                        </span>
                        <span *ngIf="plan.category !== 'margin'">
                            {{plan.deliveryBaseline | number: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                        </span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8"
                        [ngClass]="{'active-column-row': showCfpDetails}">
                        <span *ngIf="plan.category === 'margin'">
                            {{plan.currentFinancialPlan/100 | percent: '1.2-2'}}
                        </span>
                        <span *ngIf="plan.category !== 'margin'">
                            {{plan.currentFinancialPlan | number: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                        </span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8">
                        <span *ngIf="plan.category === 'margin'">
                            {{plan.actuals/100 | percent: '1.2-2'}}
                        </span>
                        <span *ngIf="plan.category !== 'margin'">
                            {{plan.actuals | number: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                        </span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8"
                        [ngClass]="{'active-column-row': showForecastDetails, 'changed-value': isPlanUpdated}">
                        <span *ngIf="plan.category === 'margin'">
                            {{plan.forecast/100 | percent: '1.2-2'}}
                        </span>
                        <span *ngIf="plan.category !== 'margin'">
                            {{plan.forecast | number: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                        </span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8" [ngClass]="{'changed-value': isPlanUpdated}">
                        <span *ngIf="plan.category === 'margin'">
                            {{plan.eac/100 | percent: '1.2-2'}}
                        </span>
                        <span *ngIf="plan.category !== 'margin'">
                            {{plan.eac | number: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                        </span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8" [ngClass]="{'changed-value': isPlanUpdated}">
                        <span *ngIf="plan.category === 'margin'">
                            {{(plan.eac - plan.deliveryBaseline)/100 | percent: '1.2-2'}}
                        </span>
                        <span *ngIf="plan.category !== 'margin'">
                            {{plan.eac - plan.deliveryBaseline | number: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <forecast-navigation-bar *ngIf="!isLoadingForecast && !showCfpDetails && !showDbDetails" (isLaborTabSelected)="getLaborTabSelected($event)"></forecast-navigation-bar>
    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isLoadingForecast">
    </dm-loading>
    <dm-labor-details [showCfpDetails]="showCfpDetails" [showDbDetails]="showDbDetails" [showForecastDetails]="showForecastDetails" [forecastDetails]="forecastDetails" *ngIf="!isLoadingForecast && showCfpDetails || showDbDetails || showForecastDetails" (emitFilteredDemands)="getUpdatedDemands($event)"></dm-labor-details>
</section>