import { Component, forwardRef, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { AccessibilityConstants, Components, TooltipText } from "../../../../../common/application.constants";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsApiV2, IProjectDetailsV2, IServiceDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { IState } from "../../../../../store/reducers";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../../../../../components/tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../../common/error.constants";
import { IProjectChargeTypes } from "../../../../../common/services/contracts/add-roles.contracts";

@Component({
    selector: "dm-internal-engagement-projects",
    templateUrl: "./internal-engagement-projects.html",
    styleUrls: ["./internal-engagement-projects.scss"],
})
export class InternalEngagementProjectsComponent extends DmComponentAbstract {
    public engagementDetails: IEngagementDetailsApiV2;
    public wbsTooltipText: string = TooltipText.EBSState;
    public internalEngagementProjects: IProjectDetailsV2[];
    public isSrOut: boolean = false;
    public isECIFPreSales: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.ProjectDetails;
    public accessibilityConstants = AccessibilityConstants;
    private projectChargeAccountTypes: IProjectChargeTypes[];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(dmLogger, Components.InternalEngagementProjects);
    }

    public ngOnInit(): void {
        this.configurationService.initialize()
            .then(() => {
                const engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
                const engagementDetails$ = this.store.select(getEntireEngagementDetails(engagementId));
                this.projectChargeAccountTypes = this.configurationService.getValue<IProjectChargeTypes[]>("chargeTypeAccounts");
                engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
                    if (engagementDetails.loaded) {
                        this.engagementDetails = engagementDetails.engagementDetails;
                        if (this.engagementDetails && this.engagementDetails.projects && this.engagementDetails.projects.length) {
                            // for each project add sr out details if applicable
                            if (this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(this.engagementDetails.projects[0].projectTypeCode)) {
                                this.isSrOut = true;
                                for (const project of this.engagementDetails.projects) {
                                    const nonExpenseService: IServiceDetailsV2 = project.services.filter((service: IServiceDetailsV2) => service.description.toLowerCase().indexOf("expense") === -1)[0];
                                    if (nonExpenseService && nonExpenseService.tasks && nonExpenseService.tasks.length) {
                                        project.srCostCenter = nonExpenseService.tasks[0].costObject;
                                        project.srCrossChargeType = nonExpenseService.tasks[0].chargeType;
                                        project.srCrossChargeAmount = nonExpenseService.tasks[0].chargeTypeValue;
                                    }
                                }
                            }
                            if (this.sharedFunctionsService.isECIFPreSalesEngagementBasedOnCreationCode(this.engagementDetails.projects[0].projectTypeCode)) {
                                this.isECIFPreSales = true;
                                for (const project of this.engagementDetails.projects) {
                                    const nonExpenseService: IServiceDetailsV2 = project.services.filter((service: IServiceDetailsV2) => service.description.toLowerCase().indexOf("expense") === -1)[0];
                                    if (nonExpenseService && nonExpenseService.tasks && nonExpenseService.tasks.length) {
                                        if (this.projectChargeAccountTypes && this.projectChargeAccountTypes.length) {
                                            const filteredChargeAccounts = this.projectChargeAccountTypes.filter((obj) => obj.chargeAccountNumber === nonExpenseService.tasks[0].chargeAccount);
                                            if (filteredChargeAccounts && filteredChargeAccounts.length) {
                                                project.ecifChargeAccount = filteredChargeAccounts[0].chargeAccountName;
                                            }
                                        }                                      
                                        project.srCostCenter = nonExpenseService.tasks[0].costObject;
                                        project.srCrossChargeType = nonExpenseService.tasks[0].chargeType;
                                        project.srCrossChargeAmount = nonExpenseService.tasks[0].chargeTypeValue;
                                    }
                                }
                            }
                            this.internalEngagementProjects = this.engagementDetails.projects;
                        }
                    }
                    this.refreshOnItemInvalidation(engagementDetails);
                    this.setLoadersBasedOnItemState(engagementDetails);
                    this.setErrorsBasedOnItemState(engagementDetails);
                    if (engagementDetails.error) {
                        this.isServerError = true;
                    }
                });
            });
        this.tileContent = {
            title: "Projects"
        };
    }
}
