import * as fromResourceRequests from "./resource-requests.action";
import { IResourceRequestResponse } from "../../common/services/contracts/staffing.service.contract";
import { ILoadableState } from "../reducers";

export interface IResourceRequestsDetailsState extends ILoadableState {
    grmSearchApiResponse: IResourceRequestResponse;
}

export const initialState: IResourceRequestsDetailsState = {
    grmSearchApiResponse: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromResourceRequests.ResourceRequestsActions): { [engagementId: string]: IResourceRequestsDetailsState } {
    switch (action.type) {
        case fromResourceRequests.ResourceRequestsActionTypes
            .LOAD_RESOURCE_REQUESTS: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }
        case fromResourceRequests.ResourceRequestsActionTypes
            .LOAD_RESOURCE_REQUESTS_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                grmSearchApiResponse: action.grmSearchApiResponse,
                loaded: true,
                loading: false,
            };
            return newState;
        }
        case fromResourceRequests.ResourceRequestsActionTypes
            .LOAD_RESOURCE_REQUESTS_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }
        case fromResourceRequests.ResourceRequestsActionTypes
            .INVALIDATE_RESOURCE_REQUESTS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}