import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ResourceManagementModalComponent } from "./resource-management-modal.component";
import { DMLoggerService } from "../../../common/services/dmlogger.service";


@NgModule({
    declarations: [
        ResourceManagementModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        DMLoggerService,
    ],
    entryComponents: [
        ResourceManagementModalComponent
    ],
    exports: [
        ResourceManagementModalComponent
    ]
})

export class ResourceManagementModalModule { }
