import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { InlineSurveyComponent } from "./inline-survey.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        InlineSurveyComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    providers: [
    ],
    entryComponents: [
        InlineSurveyComponent
    ],
    exports: [
        InlineSurveyComponent
    ],
})
export class InlineSurveyModule { }
