<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'250px'" [tileHeight]="'100%'" [isV2Tile]="true">
  <a additionalActionsRight *ngIf="!isComponentLoading && !isServerError" class="dm-link"
    title="Navigate to Manage Suppliers" [attr.aria-label]="this.tileContent?.title + ' tile section ' + 'Go to Manage Suppliers' " [uiSref]="getManageSuppliersRouteName()"
    [uiParams]="getManageSuppliersRouteParams()" (click)="logSuppliersClick()" role="link">
    Go to Manage Suppliers
  </a>
  <dm-loading loader *ngIf="isComponentLoading && !isServerError" [showLoading]="showLoading"
    [loadingText]="'Purchase Order Details'"></dm-loading>
  <caption class="sr-only" id="purchaseOrderTable">Purchase Orders</caption>
  <div *ngIf="!isComponentLoading && poOrders && poOrders.length" role="grid" [ngClass]="{'dm-overflow': deviceFactory.isSmallScreen()}" aria-labelledby="purchaseOrderTable">
    <div role="rowgroup" class="dm-grid-view-table">
      <div role="row" class="dm-grid-view-table__row">
        <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 dm-p-8">
          PO Number
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 dm-p-8">
          PO Title
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 dm-p-8">
          Supplier
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 dm-p-8">
          PO Status
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 dm-p-8 text-align--right">
          <dm-tooltip role="tooltip" class="dm-tooltipinline projectToolTip" [dmPlacement]="'bottom'"
            [ngbTooltipContent]="'Expense: Total Subcon Expenses approved by the PjM(s) in Concur from all Projects associated with that Subcon PO divided by Total line item Expenses (Automatic two-way match in Goods Receipt compares Total Expenses on all Supplier Invoices against the Total line item Expenses). Hours: Total Subcon Hours approved by the PjM(s) in Chronos from all Projects associated with that Subcon PO divided by Total line item Hours.  (Automatic three-way match in Goods Receipt compares those two sums against the Total Hours on all Supplier Invoices against that PO).'"
            [dmIcon]="'tile__icon--info icon icon-info'"
            ariaLabelToolTip="Subcon Expense/Hours percentage Consumed Information">
          </dm-tooltip>
          Subcon
          <br>
          % Consumed
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 dm-p-8 text-align--right">
          <dm-tooltip role="tooltip" class="dm-tooltipinline projectToolTip" [dmPlacement]="'bottom-right'"
            [ngbTooltipContent]="'Total Subcon FF amount consumed for that Subcon PO as estimated by the PjM(s) in Goods Receipt (sumproduct of %complete times FF PO amounts for each FF line item) divided by Total line item FF amounts.  (Automatic three-way match in Goods Receipt compares those two sums against the Total FF amounts on all Supplier Invoices against that PO).'"
            [dmIcon]="'tile__icon--info icon icon-info'"
            ariaLabelToolTip="Subcon Fixed Fee percentage Completed Information">
          </dm-tooltip>
          Subcon FF
          <br>
          % Completed
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 dm-p-8 text-align--right">
          <dm-tooltip role="tooltip" class="dm-tooltipinline projectToolTip" [dmPlacement]="'bottom-right'"
            [ngbTooltipContent]="'Total Materials received by the PjM(s) in Goods Receipt from all Projects associated with that Supplier PO divided by Total line item Materials.  (Automatic three-way match in Goods Receipt compares those two sums against the Total Materials on all Supplier Invoices against that PO).'"
            [dmIcon]="'tile__icon--info icon icon-info'"
            ariaLabelToolTip="Supplier Materials percentage Received Information">
          </dm-tooltip>
          Materials
          <br>
          % Received
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2  dm-p-8">
          PO % Consumed
        </div>
      </div>
      <div class="overflow-content">
        <div role="row" class="dm-grid-view-table__row" *ngFor="let po of poOrders">
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8">
            <a [href]="myOrderLink+po.poNumber" (click)="logPONumberClick()" target="_blank" class="dm-link"
            [attr.aria-label]="'Purchase Order Number '+po.poNumber + 'open link in new tab'">
              {{po.poNumber}}
            </a>
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8">
            {{po.poTitle | dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!po.poTitle" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8">
            {{po.supplier | dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!po.supplier" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8">
            {{(po.status | dmDisplayDashIfEmptyOrNull)}}
            <span *ngIf="!po.status" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
          <div role="gridcell"
            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8 text-align--right">
            <div class="subcon-consumed text-left">
              <span class="dm-detail__value">Expenses</span>
              {{((po.expConsumption | number : '1.2-2') | dmDisplayDashIfEmptyOrNull)}}
            </div>
            <div class="subcon-consumed text-left">
              <span class="dm-detail__value">Hours</span>
              {{((po.tmConsumption | number : '1.2-2') | dmDisplayDashIfEmptyOrNull)}}
            </div>
          </div>
          <div role="gridcell"
            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8 text-align--right">
            {{((po.ffConsumption| number : '1.2-2') | dmDisplayDashIfEmptyOrNull)}}
            <span *ngIf="!po.ffConsumption" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
          <div role="gridcell"
            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8 text-align--right">
            {{((po.matConsumption| number : '1.2-2') | dmDisplayDashIfEmptyOrNull)}}
            <span *ngIf="!po.matConsumption" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8">
            <div class="display-grid poConsumption">
              <div class="margin-auto">
                {{po.poConsumption | number : '1.2-2'}} %
              </div>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" [ngStyle]="{'width': po.poConsumption + '%'}" [attr.aria-valuenow]="po.poConsumption" aria-valuemin="0" aria-valuemax="100" aria-label="po consumption indicator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section *ngIf="!isComponentLoading && poOrders && !poOrders.length" class="col-md-12 col-sm-12 col-xs-12">
    <dm-no-data [noDataText]="noPurchaseOrdersText" [showImage]="true"></dm-no-data>
  </section>
  <section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0" *ngIf="isServerError">
    <dm-service-error [ngbTooltipContent]="errorText"></dm-service-error>
  </section>
</dm-tile>