import { ErrorSeverityLevel, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { Injectable, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Services, SourceConstants } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { getEntireMilestones } from "../../store/milestones/milestones.selector";
import { IExportToExcelResult, ReportType } from "./contracts/project.service.contracts";
import { IMilestonesState } from "../../store/milestones/milestones.reducer";
import { IState } from "../../store/reducers";
import { ProjectService } from "./project.service";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { StoreDispatchService } from "./store-dispatch.service";

@Injectable()
export class ExportToExcelService extends DmServiceAbstract {

    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(FxpMessageService) private fxpMessageService: FxpMessageService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService
    ) {
        super(dmLogger, Services.ExportToExcelService);
    }

    /**
     * Prepares the excel data to be sent to project service API. Includes preparing milestones for FF engagements. (Temporary)
     * 
     * @param {IExportToExcelResult} exportToExcelModel the excel info to be converted into reports
     * @param {string} wbsId the WBS ID of the data
     */
    public sendExcelDataToService(exportToExcelModel: IExportToExcelResult, wbsId: string): Promise<any> {
        /* Temporary workaround: add milestones to the FF report data in the UI due to auth problems with calling the API on the backend.
        This will be removed once we can call milestones from the function app. */
        if (exportToExcelModel.financialPlan === ReportType.FixedFee) {
            this.storeDispatchService
                .requireMilestones(wbsId, true)
                .load();
            const milestones$ = this.store.select(getEntireMilestones(wbsId));
            milestones$.subscribe((milestonesState: IMilestonesState) => {
                if (milestonesState.loaded) {
                    if (milestonesState.milestones && !milestonesState.milestones.length) {
                        const warning: string = "This engagement does not currently contain any milestones. The exported Fixed Fee Status Report will be blank.";
                        this.fxpMessageService.addMessage(warning, this.FXP_CONSTANTS.messageType.warning);
                    }
                    exportToExcelModel.milestones = milestonesState.milestones;
                    return this.exportData(exportToExcelModel, wbsId);
                }
                if (milestonesState.error || (milestonesState.loaded && !milestonesState.milestones)) {
                    const error: string = "Unable to export the Fixed Fee Status Report due to the inability to fetch milestones.";
                    this.fxpMessageService.addMessage(error, this.FXP_CONSTANTS.messageType.error);
                    this.dmLogger.logError(Services.ExportToExcelService, SourceConstants.Method.SendExcelDataToService, error, "400");
                }
                return Promise.resolve();
            });
        } else {
            return this.exportData(exportToExcelModel, wbsId);
        }
    }

    /**
     * Serializes the date objects in export excel payload. This is to prevent any timezone conversion issues by preserving date string.
     * Bugfix for Prod Bug 6243120
     *
     * @param {*} obj
     * @returns {*}
     * @memberof ExportToExcelService
     */
    public serializeDates(obj: IExportToExcelResult): IExportToExcelResult {
        /* extract just year, month, and day due to timezone issue caused in JSON.stringify */
        if (!obj) { return obj; }
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                if (obj[prop] instanceof Date) {
                    obj[prop] = this.sharedFunctionsService.transformDate(obj[prop], "yyyy-MM-dd");
                }
                if (typeof obj[prop] === "object") {
                    this.serializeDates(obj[prop]);
                }
            }
        }
        return obj;
    }

    /**
     * Exports the excel data by calling the Project Service API to upload the content to the blob and then let Project Service Functions handle it.
     *
     * @private
     * @param {IExportToExcelResult} exportToExcelModel the excel info to be converted into reports
     * @param {string} wbsId the WBS ID of the data
     * @returns {Promise<any>}
     * @memberof ExportToExcelService
     */
    private exportData(exportToExcelModel: IExportToExcelResult, wbsId: string): Promise<any> {
        if (!exportToExcelModel.labor) { /* Debugging check, ensuring labor and labor data isn't missing prior to serialization, common issue in Export to Excel */
            this.dmLogger.logError(Services.ExportToExcelService, SourceConstants.Method.ExportData, "Labor object is missing for export to excel. WBS ID " + wbsId, "400");
        } else if (exportToExcelModel.labor && exportToExcelModel.labor.data) {
            this.dmLogger.logError(Services.ExportToExcelService, SourceConstants.Method.ExportData, "Labor.Data object is missing for export to excel. WBS ID " + wbsId, "400");
        }
        else {
            this.dmLogger.logEvent(Services.ExportToExcelService, SourceConstants.Method.ExportData, "All labor data present for export to excel prior to serialization. WBS ID " + wbsId);
        }

        const serializedExcelModel = this.serializeDates(exportToExcelModel);
        return this.projectService.postExportToExcel(
            wbsId,
            exportToExcelModel.financialPlan, /* This is a string indicating what type of report to create, it matches with the ReportType in Project Service */
            serializedExcelModel
        ).then(() => {
            this.fxpMessageService.addMessage("You will receive the exported Excel as an attachment in your email", this.FXP_CONSTANTS.messageType.success, false);
        }).catch((error) => {
            let weekLength: number = null;
            if (exportToExcelModel.labor && exportToExcelModel.labor.data && Array.isArray(exportToExcelModel.labor.data.byWeek)) {
                weekLength = exportToExcelModel.labor.data.byWeek.length;
            }
            const errorMessage: string = `An error occurred while trying to generate the Excel. You can try to reduce the number of weeks requested ${weekLength ? "(# of weeks : " + weekLength + " )" : ""} `;
            this.fxpMessageService.addMessage(errorMessage, this.FXP_CONSTANTS.messageType.error, false);
            this.logError(SourceConstants.Method.ExportData, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        });
    }

}