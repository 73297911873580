import { forwardRef, Inject, Component, Injector } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";

import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getMyPortfolioEngagementListState } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IEngagementList } from "../../../common/services/contracts/portfolio.model";
import { IMyPortfolioEngagementListState } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IState } from "../../../store/reducers";
import { RouteName, SourceConstants, LogEventConstants, Components, NoDataText, EngagementType, CacheKeys } from "../../../common/application.constants";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IntEngBulkUploadModalComponent } from "../internal-engagement-bulk-upload-modal/internal-engagement-bulk-upload-modal.component";
import { BulkUploadInternalEngagementService } from "../../../common/services/bulk-upload-internal-engagement.service";
import { IBulkUploadTemplateUrlLinksResponse, IBulkUploads } from "../../../common/services/contracts/bulk-upload-int-engagement.service.contracts";
import { BulkCreateIntEngagementStatusModalComponent } from "../bulk-create-internal-engagement/bulk-create-int-engagement-status-modal/bulk-create-int-engagement-status-modal.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IUserPreference } from "../../../common/services/contracts/userpreference.contract";
import { getEntireUserPreference } from "../../../store/userspreferences/userpreference.selector";
import { IUserPreferenceState } from "../../../store/userspreferences/userpreference.reducer";
import { UserPreferenceService } from "../../../common/services/userpreferences.service";
import { UpdateUserPreference } from "../../../store/userspreferences/userpreference.action";
import { ITile } from "../../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../common/error.constants";
import { ProjectServiceFunctions } from "../../../common/services/projectservice-functions.service";
import { IUserMembership, ICheckUserMembershipResponse } from "../../../common/services/contracts/projectservice-functions.contract";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { CacheService } from "../../../common/services/cache.service";

@Component({
    selector: "dm-internal-engagement-grid-data-v2",
    templateUrl: "internal-engagement-grid-data-v2.html",
    styleUrls: ["internal-engagement-grid-data-v2.scss"]
})
export class InternalEngagementGridDataV2Component extends DmComponentAbstract {

    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public isMobileView: boolean;
    public showAddNewInternalEngagementView: boolean = false;
    public showUploadDropdown: boolean = false;
    public showBulkUploadButton: boolean = false;
    public bulkUploadTemplateUrl: string;
    public bulkUploadEditTemplateUrl: string;
    public activeBulkUploads: IBulkUploads[];
    public isInternalEngagementsExpanded: boolean = false;
    public internalEngagementsCount: number = 0;
    public noInternalEngagementFoundText: string;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.ManageInternalEngagement;
    public tileContent: ITile;
    public tileMinHeight: string;
    private userPreference: IUserPreference;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) private deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(BulkUploadInternalEngagementService) private bulkUploadInternalEngagementService: BulkUploadInternalEngagementService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Injector) private injector: Injector,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(CacheService) private cacheService: CacheService,
    ) {
        super(dmLogger, Components.PortfolioInternalEngagementGridData, [{ component: Components.PortfolioInternalEngagementOverviewTab, isCritical: true }]);
    }

    public ngOnInit(): void {
        this.isMobileView = this.deviceFactory.isMobile();
        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
        this.noInternalEngagementFoundText = NoDataText.NoInternalEngagements;
        this.tileContent = {
            title: "Internal Engagements",
            itemsCount: 0
        };
        this.getBulkUploadIntEngTemplateLinks();
        this.storeDispatchService
            .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
            .load();

        /* We observe this part of the state only to confirm if we have engagements or not. If we don't have any engagements, we will
        toggle a flag to show a different component */
        myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
            if (engagementList.loaded) {
                const internalEngagementList: IEngagementList[] = engagementList.engagementList.filter((x: IEngagementList) => x.type.toLowerCase() === "internal");
                this.internalEngagementsCount = internalEngagementList.length;
                this.tileContent.itemsCount = this.internalEngagementsCount;
                if (!this.internalEngagementsCount) {
                    this.showAddNewInternalEngagementView = true;
                }
            }
            if (engagementList.error) {
                this.isServerError = true;
            }
        });

        const userPreferences$ = this.store.select((getEntireUserPreference()));
        userPreferences$.pipe(untilDestroyed(this)).subscribe((userPreferenceState: IUserPreferenceState) => {
            if (userPreferenceState.loaded) {
                this.userPreference = userPreferenceState.userPreference;
                this.isInternalEngagementsExpanded = this.userPreference.internalEngagements.isComponentExpanded;
                this.tileMinHeight = this.isInternalEngagementsExpanded ? "287px" : "auto";
            }
            if (userPreferenceState.error) {
                this.isServerError = true;
            }
        });

        if (this.stateService.params && this.stateService.params.showBulkIntEngSummary && this.stateService.params.bulkReferenceId) {
            const showBulkIntEngSummary: string = this.stateService.params.showBulkIntEngSummary;
            const bulkReferenceId: string = this.stateService.params.bulkReferenceId;
            if (showBulkIntEngSummary && bulkReferenceId) {
                this.openBulkCreateIntEngagementStatusModal(bulkReferenceId);
            }
        }

        this.showBulkUpload();
    }

    /**
     * toggles bulk upload dropdown which shows template links, upload excel etc.
     *
     * @memberof InternalEngagementGridDataComponent
     */
    public toggleUploadDropdown(): void {
        this.showUploadDropdown = !this.showUploadDropdown;
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.ToggleUploadDropdown, LogEventConstants.InternalEngagementBulkUploadOpen);
    }

    /**
     * ISD Users rules
     *
     * @memberof InternalEngagementGridDataComponent
     */
    public showBulkUpload(): void {
        this.showBulkUploadButton = false;
        const bulkUploadAccessIds = this.configManagerService.getValue<string[]>("BulkUploadAccessIds");
        const { userAlias, bpId } = this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr();
        if (bulkUploadAccessIds && bulkUploadAccessIds.includes(bpId)) {
            this.showBulkUploadButton = true;
        } else {
            this.projectServiceFunctions.checkUserHierarchy(userAlias).then((isUserInISDHierarchy) => {
                this.showBulkUploadButton = !isUserInISDHierarchy;
            });
        }
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.ShowBulkUpload, LogEventConstants.InternalEngagementBulkUploadOpen);
    }

    /**
     * Opens bulk upload internal engagement upload modal
     *
     * @memberof InternalEngagementGridDataComponent
     */
    public openUploadModal(): void {
        this.showUploadDropdown = false;
        this.modalService.open(IntEngBulkUploadModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal bulk-create-int-eng-upload-modal popup-modal in active",
            injector: this.injector
        });
    }

    /**
     * Logs an event if user selects Upload File Option
     * for the bulk upload/edit option
     */
    public logFileUpload(): void {
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogFileUpload, LogEventConstants.UploadFileClick);
    }

    /**
     * Logs an event if user selects upload template option
     * for the bulk upload/edit option
     */
    public uploadTemplateClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.UploadTemplateClick, LogEventConstants.UploadTemplateClick);
    }

    /**
     * Logs an event if user selects Learn More Link
     * for creating new internal engagements
     */
    public learnMoreIntEngLinkClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.CreateInternalEngagementPage, SourceConstants.Method.LearnMoreIntEngLinkClick, LogEventConstants.LearnMoreIntEngLink);
    }

    /**
     * Opens bulk upload internal engagement upload modal
     *
     * @memberof InternalEngagementGridDataComponent
     */
    public openBulkCreateIntEngagementStatusModal(referenceId: string): void {
        const modalRef: NgbModalRef = this.modalService.open(BulkCreateIntEngagementStatusModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal bulk-create-int-eng-status-popup-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.bulkUploadReferenceId = referenceId;
    }

    /**
     * logs event when user clicks on New Internal Engagement Add link
     */
    public logNewInternalEngagementClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogNewInternalEngagementClick, LogEventConstants.NewIntEngagementClick);
    }


    /**
     * Move the focus to element for accessibility tooling
     * @param id
     */
    public focus(id: string): void {
        this.sharedFunctionsService.focus(id, true);
    }

    /**
     * Toggles expand collapse of internal engagements     
     */
    public onToggleExpandCollapse(): void {
        this.userPreference.internalEngagements.isComponentExpanded = !this.isInternalEngagementsExpanded;
        this.userPreferenceService.saveUserPreference(this.userPreference).then((response: IUserPreference) => {
            this.store.dispatch(new UpdateUserPreference(response));
        });
        const propertyBag = {};
        propertyBag[EngagementType] = "Internal";
        if (this.userPreference.internalEngagements.isComponentExpanded) {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.OnToggleExpandCollapse, LogEventConstants.EngagementListExpanded, propertyBag);
            this.tileMinHeight = "287px";
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.OnToggleExpandCollapse, LogEventConstants.EngagementListCollapsed, propertyBag);
            this.tileMinHeight = "auto";
        }

    }

    /**
     * Sets the title or aria-label on collapsing and expanding internal engagements
     */
    public setTitleOrAriaLabelForExpandCollapseInternalGrid(): string {
        return (this.isInternalEngagementsExpanded ? "Collapse " : "Expand ") + this.internalEngagementsCount + " Internal Engagements";
    }

    /**
     * Gets the bulk upload internal engagement template excel links
     *
     * @private
     * @memberof InternalEngagementGridDataComponent
     */
    private getBulkUploadIntEngTemplateLinks(): void {
        this.bulkUploadInternalEngagementService.getBulkUploadExcelTemplateLinks().then(
            (templateLinksResponse: IBulkUploadTemplateUrlLinksResponse[]) => {
                if (templateLinksResponse && templateLinksResponse.length) {
                    const filteredCreateLinkResponse = templateLinksResponse.filter((response: IBulkUploadTemplateUrlLinksResponse) => response.name === "Create")[0];
                    if (filteredCreateLinkResponse && filteredCreateLinkResponse.url) {
                        this.bulkUploadTemplateUrl = filteredCreateLinkResponse.url;
                    }
                    const filteredEditLinkResponse = templateLinksResponse.filter((response: IBulkUploadTemplateUrlLinksResponse) => response.name === "Edit")[0];
                    if (filteredEditLinkResponse && filteredEditLinkResponse.url) {
                        this.bulkUploadEditTemplateUrl = filteredEditLinkResponse.url;
                    }
                }
            }
        );
    }
}
