<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'322px'" [tileHeight]="'322px'">
    <dm-loading loader [isCustomText]="true" [loadingText]="loadingText" [showLoading]="showLoading"
        *ngIf="isComponentLoading && !isServerError">
    </dm-loading>
    <ng-container *ngIf="!isComponentLoading">
        <section>
            <section class="dm-p-imp-0"
                [ngClass]="(isUnapprovedExpenseEnabled || isUnapprovedLaborEnabled)? 'col-md-9 col-sm-9' : 'col-md-12 col-sm-12'">
                <section class="col-md-12 col-lg-12 col-sm-12 dm-p-imp-0 dm-tile--m-b-50">
                    <section class="text-left col-xs-12 col-md-4 col-sm-4 text-align--center-tablet dm-p-l-imp-0">
                        <p class="tile-title">
                            EAC Cost vs.<br class="hidden-xs" />
                            Contract Baseline
                        </p>
                        <p class="font-subtitle">
                            <span>{{eacCostVsContractBaseLine | number: '1.2-2'}} %</span>
                        </p>
                        <p class="font-11">
                            <a (click)="openContractBaseline()" class="view-all-amt-btn dm-link"
                                href="javascript:void(0)">
                                View Contract Baseline
                            </a>
                        </p>
                    </section>
                    <section class="text-left col-xs-12 col-md-4 col-sm-4 text-align--center-tablet">
                        <p class="tile-title">
                            EAC Cost vs.<br class="hidden-xs" />
                            Delivery Baseline
                        </p>
                        <p class="font-subtitle">
                            <span>{{eacCostVsDeliveryBaseLine | number: '1.2-2'}} %</span>
                        </p>
                        <p class="font-11">
                            <a (click)="openDeliveryBaseline()" class="view-all-amt-btn dm-link"
                                href="javascript:void(0)">
                                View Delivery Baseline
                            </a>
                        </p>
                    </section>
                    <section class="text-left col-xs-12 col-md-4 col-sm-4 text-align--center-tablet dm-p-r-imp-0">
                        <p class="tile-title">
                            EAC Cost vs.<br class="hidden-xs" />
                            Current Financial Plan
                        </p>
                        <p class="font-subtitle">
                            <span>{{eacCostVsCurrentFinancialPlan | number: '1.2-2'}} %</span>
                        </p>
                        <p class="font-11">
                            <a (click)="openCurrentFinancialPlan()" class="view-all-amt-btn" href="javascript:void(0)">
                                View Current Financial Plan
                            </a>
                        </p>
                    </section>
                </section>
                <section class="col-md-12 col-lg-12 col-sm-12 dm-p-imp-0"
                    *ngIf="(riskReserveDetails && riskReserveDetails.length) || capAmountDetails || (isClinSlinFeatureEnabled && showClinSlin) || showECIFConsumption">
                    <section class="col-md-12 col-lg-12 dm-p-imp-0 dm-tile--m-b-12">
                        <h3 class="dm-tile__large-tile-title dm-fin-mobile-line-height-18">Consumptions</h3>
                    </section>
                    <section *ngIf="riskReserveDetails && riskReserveDetails.length"
                        class="text-left col-md-4 col-sm-4 col-xs-12 text-align--center-tablet dm-p-l-imp-0 display-inline-flex-imp">
                        <section class="bar-graph--wrapper">
                            <span class="bar-graph--content"
                                [ngStyle]="{'height': riskReserveConsumedPercentage + '%'}">
                            </span>
                        </section>
                        <section>
                            <p class="tile-title line-height-9">
                                Projected<br class="hidden-xs" />
                                Risk Reserve
                                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                                    [ngbTooltipContent]="'Projected RR Percent = Projected RR Cost / RR Cost Amount. \n NST amount (if applicable) is also included in Risk Reserve Amount'"
                                    [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="Projected Risk Reserve Information">
                                </dm-tooltip>
                            </p>
                            <p class="font-subtitle">
                                {{riskReserveConsumedPercentage | number: '1.2-2'}} %
                            </p>
                            <p class="font-11">
                                <button type="button" title="View details" [attr.aria-label]="'Projected Risk Reserve ' + (riskReserveConsumedPercentage | number: '1.2-2') + ' Percentage'" class="view-all-amt-btn dm-link" (click)="openRiskReserveModal()">
                                    View details
                                </button>
                            </p>
                        </section>
                    </section>
                    <section *ngIf="capAmountDetails && !isProjectContext"
                        class="text-left col-md-4 col-sm-4 col-xs-12  text-align--center-tablet display-inline-flex-imp">
                        <section class="bar-graph--wrapper">
                            <span class="bar-graph--content" [ngStyle]="{'height': tmCapConsumedPercentage + '%'}">
                            </span>
                        </section>
                        <section>
                            <p class="tile-title line-height-9">
                                T&M Cap<br class="hidden-xs" />
                                Consumed
                                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                                    [ngbTooltipContent]="'Approved Billable Amount to date divided by Total T&E Cap Amount.'"
                                    [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="T&M Cap Consumed Information">
                                </dm-tooltip>
                            </p>
                            <p class="font-subtitle">
                                <span class="legend-text"
                                    [ngClass]="isCapAmtExceeds ? 'red-legent-text' : 'legend-text' ">
                                    {{ tmCapConsumedPercentage | number: '1.2-2' }} %
                                </span>
                                <i *ngIf="isCapAmtExceeds" class="red-legend"></i>
                            </p>
                            <p class="font-11">
                                <button type="button" class="view-all-amt-btn dm-link"
                                    (click)="openViewCapAmountsModal()" title="View details" [attr.aria-label]="'T&M Cap Consumed ' + (tmCapConsumedPercentage | number: '1.2-2') + ' Percentage'">
                                    View details
                                </button>
                            </p>
                        </section>
                    </section>
                    <section *ngIf="isClinSlinFeatureEnabled && showClinSlin"
                        class="text-left col-md-4 col-sm-4 col-xs-12 text-align--center-tablet display-inline-flex-imp">
                        <section class="bar-graph--wrapper">
                            <span class="bar-graph--content" [ngStyle]="{'height': clinSlinConsumedPercentage + '%'}">
                            </span>
                        </section>
                        <section>
                            <p class="tile-title line-height-9">
                                CLIN/SLIN<br class="hidden-xs">
                                Consumed
                                <span title="Loading ClinSlin Details"
                                    class="input__search-icon input__icon__search-btn" *ngIf="isClinSlinLoading"
                                    aria-label="Loading ClinSlin Details">
                                    <i
                                        [ngClass]="isClinSlinLoading ? 'glyphicon glyphicon-refresh glyphicon-spin' : ''"></i>
                                </span>
                            </p>
                            <p class="font-subtitle">
                                {{clinSlinConsumedPercentage | number: '1.2-2'}} %
                            </p>
                            <p class="font-11">
                                <button type="button" class="view-all-amt-btn dm-link"
                                    (click)="openClinSlinModal()" [attr.aria-label]="'CLIN/SLIN Consumed ' + (clinSlinConsumedPercentage | number: '1.2-2') + ' Percentage'">View
                                    details</button>
                            </p>
                        </section>
                    </section>
                    <section *ngIf="showECIFConsumption"
                        class="text-left col-md-4 col-sm-4 col-xs-12 text-align--center-tablet display-inline-flex-imp">
                        <section class="bar-graph--wrapper">
                            <span class="bar-graph--content" [ngStyle]="{'height': ecifConsumedPercentage + '%'}">
                            </span>
                        </section>
                        <section>
                            <p class="tile-title line-height-9">
                                ECIF<br class="hidden-xs">
                                Consumed
                                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                                    [ngbTooltipContent]="'ECIF Consumption Percent = Total Consumed Amount on the IO / Total ECIF Amount allocated to the Engagement or Project.'"
                                    [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="ECIF Consumed Information">
                                </dm-tooltip>
                                <span title="Loading ECIF Details"
                                    class="input__search-icon input__icon__search-btn" *ngIf="isECIFLoading"
                                    aria-label="Loading ECIF Details">
                                    <i
                                        [ngClass]="isECIFLoading ? 'glyphicon glyphicon-refresh glyphicon-spin' : ''"></i>
                                </span>
                            </p>
                            <p class="font-subtitle">
                                {{ecifConsumedPercentage | number: '1.2-2'}} %
                            </p>
                            <p class="font-11">
                                <button type="button" class="view-all-amt-btn dm-link"
                                    (click)="openECIFConsumedModal()" title="View details" [attr.aria-label]="'ECIF Consumed ' + (ecifConsumedPercentage | number: '1.2-2') + ' Percentage'">View
                                    details</button>
                            </p>
                        </section>
                    </section>
                </section>
            </section>
            <section *ngIf="isUnapprovedExpenseEnabled || isUnapprovedLaborEnabled"
                class="col-md-3 col-sm-3 dm-p-imp-0 unapproved-costs">
                <section *ngIf="isUnapprovedLaborEnabled"
                    class="text-left col-md-12 col-sm-12 dm-p-imp-0 col-xs-12 text-align--center-tablet dm-tile--m-b-86">
                    <!-- Todo remove this *ngIf condition when integrating with API-->
                    <p class="tile-title line-height-9">
                        Unapproved<br />
                        Labor
                        <span title="Loading Unapproved Labor Details"
                            class="input__search-icon input__icon__search-btn" *ngIf="isLaborDetailsLoading"
                            aria-label="Loading  Unapproved Labor Details">
                            <i
                                [ngClass]="isLaborDetailsLoading ? 'glyphicon glyphicon-refresh glyphicon-spin' : ''"></i>
                        </span>
                    </p>
                    <p class="font-subtitle">
                        <span [textContent]="totalUnApprovedLaborHours | number: '1.0-2'"></span>
                        <sub class="sub-text">hours</sub>
                    </p>
                    <p class="font-11">
                        <button type="button" [disabled]="isLaborDetailsLoading" class="view-all-amt-btn dm-link"
                            (click)="openUnapprovedLaborModal()" title="View details" [attr.aria-label]="'Unapproved Labor ' + (totalUnApprovedLaborHours | number: '1.0-2') + ' hours'">
                            View details
                        </button>
                    </p>
                </section>
                <section *ngIf="isUnapprovedExpenseEnabled"
                    class="text-left col-md-12 col-sm-12 dm-p-imp-0 col-xs-12 text-align--center-tablet">
                    <p class="tile-title line-height-9">
                        Unapproved <br />Expense
                        <span title="Loading Expense Details" class="input__search-icon input__icon__search-btn"
                            *ngIf="isExpenseDetailsLoading" aria-label="Loading Expense Details">
                            <i
                                [ngClass]="isExpenseDetailsLoading ? 'glyphicon glyphicon-refresh glyphicon-spin' : ''"></i>
                        </span>
                    </p>
                    <p class="font-subtitle">
                        <span [textContent]="unapprovedExpense | number: '1.0-2'"></span>
                        <sub class="sub-text">{{currency}}</sub>
                    </p>
                    <p class="font-11">
                        <button type="button" class="view-all-amt-btn dm-link" [disabled]="isExpenseDetailsLoading"
                            (click)="openUnapprovedExpenseModal()" title="View details" [attr.aria-label]="'Unapproved Expense ' + (unapprovedExpense | number: '1.0-2') + currency">
                            View details
                        </button>
                    </p>
                </section>
            </section>
        </section>
    </ng-container>
    <section *ngIf="isServerError">
        <dm-service-error [ngbTooltipContent]="errorText"></dm-service-error>
    </section>
</dm-tile>