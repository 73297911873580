import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IContractType } from "../../../../common/services/contracts/project.service.contracts";
import { IState } from "../../../../store/reducers";
import { IWbsEngagementModel } from "../../../../common/services/contracts/wbs-engagement.contracts";
import {
    LogEventConstants,
    SourceConstants,
    Components,
    TooltipText,
    NoDataText,
    RouteName,
    EntityType,
    AccessibilityConstants,
    ServiceTypeNames,
} from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
    IProjectDetailsV2,
    ITaskDetailsApiV2,
    IServiceDetailsV2,
    IEngagementDetailsV2,
} from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { EbsV2RequestStatusChangeModalComponent } from "../../modals/request-status-change/request-status-change.component";
import { AddTaskModalComponent } from "../../modals/add-task/add-task.component";
import { EbsEditTaskDetailsModalComponent } from "../../modals/wbs-task-edit/edit-task-details.component";
import { EbsEditServiceDetailsModalComponent } from "../../modals/wbs-service-edit/edit-service-details.component";
import { EbsEditProjectDetailsModalComponent } from "../../modals/wbs-project-edit/edit-project-details.component";
import { EditProjectTeamStructureModalComponent } from "../../modals/edit-project-team-structure/edit-project-team-structure.component";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";

export interface IMinAndMaxDates {
    minDate: Date;
    maxDate: Date;
}

@Component({
    selector: "dm-engagement-breakdown-structure",
    templateUrl: "./engagement-breakdown-structure.html",
    styleUrls: ["./engagement-breakdown-structure.scss"],
})
export class EngagementBreakdownStructureComponent extends DmComponentAbstract {
    public projectDataV2: IProjectDetailsV2[];
    public engagementFullDetailsV2: IEngagementDetailsV2;
    public selectedProjectId: string;
    public selectedServiceId: string;
    public selectedTaskId: string;
    public isInternalEngagement: boolean;
    public isShowL2Details: boolean = true;
    public expenseTypeName: string;
    public laborTypeName: string;
    public statusCodeBIF: string;
    public ECIFStatus: string = "ECIF";
    public setFocus: string;
    public requestStateChangeText: string;
    public showLastUpdated: boolean;
    public tooltipText: string = TooltipText.EBSState;
    public wbsEngagementModel: IWbsEngagementModel;
    public showProjectsExpanded: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public RouteName = RouteName;
    public ebsStateText: string;
    public isPubSecEnabled: boolean;
    public projConfidentialText: string;
    public pubSecText: string;
    public contractTypeColors: IContractType[];
    public recRevTypeList: IContractType[];
    public LogEventConstants = LogEventConstants;
    public accessibilityConstants = AccessibilityConstants;
    public serviceTypeNames = ServiceTypeNames;
    public areMXDRChangesEnabled = false;
    public isProjectClsoureFeatureEnabled: boolean = false;
    public nuanceServiceTypeName: string;

    private isProjectContext: boolean = false;
    private projectUserStatusToShowBadges: string[] = [];
    private serviceUserStatusToShowBadges: string[] = [];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider))
        public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService)
        private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService)
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector,
        @Inject(EngagementDetailService)
        private engagementDetailService: EngagementDetailService,
        @Inject(StoreDispatchService)
        private storeDispatchService: StoreDispatchService
    ) {
        super(dmLogger, Components.ManageEbsEngagementBreakdownStructure);
    }

    public ngOnInit(): void {
        this.expenseTypeName =
            this.configManagerService.getValue<string>("expenseTypeName");
        this.laborTypeName =
            this.configManagerService.getValue<string>("laborTypeName");
        this.statusCodeBIF = this.configManagerService.getValue<string>(
            "BIFServiceStatusCode"
        );
        this.showLastUpdated = this.configManagerService.isFeatureEnabled(
            "ShowLastUpdatedColumn"
        );
        this.isPubSecEnabled = this.configManagerService.isFeatureEnabled("Pubsec");
        this.areMXDRChangesEnabled = this.configManagerService.isFeatureEnabled("enableMXDRChanges");
        this.isProjectClsoureFeatureEnabled = this.configManagerService.isFeatureEnabled("enableProjectClosureFeature");
        this.requestStateChangeText =
            "Requesting an EBS State change will notify the Assistant Services Controller (ASC) to review and change the EBS State. The requester will be notified when this activity is completed.";
        this.isShowL2Details = true;
        this.selectedProjectId = this.sharedFunctionsService.getSelectedProjectId(
            this.stateService
        );
        this.isProjectContext = this.selectedProjectId ? true : false;
        this.tileContent = {
            title: "Engagement Breakdown Structure",
            iconTitle: "icon-full icon-org",
        };
        this.ebsStateText = TooltipText.EBSState;
        this.projConfidentialText = "This project is marked as confidential.";
        this.pubSecText = "Pub Sec";
        this.nuanceServiceTypeName = "NuanceServiceType";
        this.configManagerService.initialize().then(() => {
            this.errorText = NoDataText.NoEBSDataText;
            this.projectUserStatusToShowBadges = this.configManagerService.getValue<
            string[]
            >("projectUserStatusToShowBadges");
            this.serviceUserStatusToShowBadges = this.configManagerService.getValue<
            string[]
            >("serviceUserStatusToShowBadges");
            this.contractTypeColors = this.configManagerService.getValue<
            IContractType[]
            >("projEngContractType");
            this.recRevTypeList =
                this.configManagerService.getValue<IContractType[]>(
                    "projEngRecRevType"
                );
            const wbsId: string = this.isProjectContext
                ? this.selectedProjectId
                : this.sharedFunctionsService.getSelectedEngagementId(
                    this.stateService
                );
            this.storeDispatchService.requireEngagementDetails(wbsId, true).load();

            const engagementDetails$ = this.store.select(
                getEntireEngagementDetails(wbsId)
            );

            engagementDetails$
                .pipe(untilDestroyed(this))
                .subscribe((engagementDetails: IEngagementDetailsState) => {
                    if (engagementDetails.loaded) {
                        this.engagementFullDetailsV2 = engagementDetails.engagementDetails;
                        this.projectDataV2 = engagementDetails.engagementDetails.projects;
                        this.projectDataV2.map(
                            (project) => (project.isExpand = this.showProjectsExpanded)
                        );
                        this.projectDataV2.map(
                            (project) =>
                                (project.typeColorCode =
                                this.engagementDetailService.getTypeColorCode(
                                    this.contractTypeColors,
                                    project.contractType
                                ))
                        );

                        this.projectDataV2.map((project) => (project.recRevType = this.sharedFunctionsService.getRecRevType(project.contractType, project.userStatusCode, project.projectTypeCode)));
                        // project.typeColorCode = this.engagementDetailService.getTypeColorCode(this.contractTypeColors, project.contractType);
                        if (
                            engagementDetails.engagementDetails &&
                            engagementDetails.engagementDetails.isInternalEngagment
                        ) {
                            this.isInternalEngagement =
                                engagementDetails.engagementDetails.isInternalEngagment;
                        }
                        if (this.isInternalEngagement) {
                            this.isShowL2Details = false;
                        } else {
                            this.setProjectData(this.projectDataV2);
                        }
                    }
                    this.refreshOnItemInvalidation(engagementDetails);
                    this.setLoadersBasedOnItemState(engagementDetails);
                    this.setErrorsBasedOnItemState(engagementDetails);
                    if (engagementDetails.error) {
                        this.isServerError = true;
                    }
                });
        });
    }

    /**
* Listener for callback event from the project filter. Updates the selected project ID with the one selected in the filter.
* @param newId
*/
    public setSelectedProjectId(newId: string): void {
        this.selectedProjectId = newId;
    }
    /**
* Listener for callback event from the project filter. Updates the selected service ID with the one selected in the filter.
* @param newId
*/
    public setSelectedServiceId(newId: string): void {
        this.selectedServiceId = newId;
    }
    /**
* Listener for callback event from the project filter. Updates the selected task ID with the one selected in the filter.
* @param newId
*/
    public setSelectedTaskId(newId: string): void {
        this.selectedTaskId = newId;
    }

    /**
* For expanding/collapsing all the Projects
*/
    public expandCollapseAllProjects(): void {
        this.showProjectsExpanded = !this.showProjectsExpanded;
        if (this.showProjectsExpanded) {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.ExpandCollapseAllProjects,
                LogEventConstants.ProjectListExpanded
            );
        } else {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.ExpandCollapseAllProjects,
                LogEventConstants.ProjectListCollapsed
            );
        }
        this.projectDataV2.map(
            (project) => (project.isExpand = this.showProjectsExpanded)
        );
    }

    /**
*  Manually expand/collapsing the project and resetting the expand/collapse icon on top the table
*/
    public expandCollapseProjects(project: IProjectDetailsV2): void {
        project.isExpand = !project.isExpand;
        const expandedProjects = this.projectDataV2.filter(
            (projectDetails: IProjectDetailsV2) => projectDetails.isExpand === true
        ).length;
        if (expandedProjects === this.projectDataV2.length) {
            this.showProjectsExpanded = true;
        }
        const collapsedProjects = this.projectDataV2.filter(
            (projectDetails: IProjectDetailsV2) => projectDetails.isExpand === false
        ).length;
        if (collapsedProjects === this.projectDataV2.length) {
            this.showProjectsExpanded = false;
        }
        if (project.isExpand) {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.ExpandCollapseProjects,
                LogEventConstants.SpecificProjectExpand
            );
        } else {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.ExpandCollapseProjects,
                LogEventConstants.SpecificProjectCollapse
            );
        }
    }

    /**
*  Manually expand/collapsing the service
*/
    public expandCollapseServices(service: IServiceDetailsV2): void {
        service.isExpand = !service.isExpand;
        if (service.isExpand) {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.ExpandCollapseServices,
                LogEventConstants.SpecificServiceExpand
            );
        } else {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.ExpandCollapseServices,
                LogEventConstants.SpecificServiceCollapse
            );
        }
    }

    /**
* Opens the modal that allows users to request an EBS state change for the project, service, or task.
* @param data
* @param wbsType
*/
    public openStatusChangeModal(
        data: IProjectDetailsV2 | IServiceDetailsV2 | ITaskDetailsApiV2,
        wbsType: string
    ): void {
        if (!data) {
            return;
        }
        const modalRef = this.modalService.open(
            EbsV2RequestStatusChangeModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        modalRef.componentInstance.engagementDetailsV2 =
            this.engagementFullDetailsV2;
        if (wbsType.toLowerCase() === EntityType.Project.toLowerCase()) {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenStatusChangeModal,
                LogEventConstants.ManageEBSProjectRequestStatusChangeLinkClicked
            );
            modalRef.componentInstance.selectedProject = data as IProjectDetailsV2;
            modalRef.result.then(() => {
                const id = "project-" + data.id + "-dropdown-btn";
                this.sharedFunctionsService.focus(id, true);
                this.dmLogger.logEvent(
                    SourceConstants.Component.ManageEBSPage,
                    SourceConstants.Method.OpenStatusChangeModal,
                    LogEventConstants.ManageEBSProjectRequestStatusChangeModalClosed
                );
            });
        }
        if (wbsType.toLowerCase() === EntityType.Service.toLowerCase()) {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenStatusChangeModal,
                LogEventConstants.ManageEBSServiceRequestStatusChangeLinkClicked
            );
            modalRef.componentInstance.selectedService = data as IServiceDetailsV2;
            modalRef.result.then(() => {
                const id = "service-" + data.id + "-dropdown-btn";
                this.sharedFunctionsService.focus(id, true);
                this.dmLogger.logEvent(
                    SourceConstants.Component.ManageEBSPage,
                    SourceConstants.Method.OpenStatusChangeModal,
                    LogEventConstants.ManageEBSServiceRequestStatusChangeModalClosed
                );
            });
        }
        if (wbsType.toLowerCase() === EntityType.Task.toLowerCase()) {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenStatusChangeModal,
                LogEventConstants.ManageEBSTaskRequestStatusChangeLinkClicked
            );
            modalRef.componentInstance.selectedTask = data as ITaskDetailsApiV2;
            modalRef.result.then(() => {
                const id = "task-" + data.id + "-dropdown-btn";
                this.sharedFunctionsService.focus(id, true);
                this.dmLogger.logEvent(
                    SourceConstants.Component.ManageEBSPage,
                    SourceConstants.Method.OpenStatusChangeModal,
                    LogEventConstants.ManageEBSTaskRequestStatusChangeModalClosed
                );
            });
        }
    }

    /**
*  Opens add task modal
*/
    public addTaskModal(service: IServiceDetailsV2): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.AddTaskModal,
            LogEventConstants.ManageEBSTaskAddLink
        );
        const modalRef = this.modalService.open(AddTaskModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 in",
            keyboard: true,
            centered: true,
            injector: this.injector,
        });
        modalRef.componentInstance.selectedService = service;
        modalRef.componentInstance.projectContextData = this.projectDataV2; // only used to get the pjms to send notification to
        modalRef.result.then(() => {
            const id = "service-" + service.id + "-dropdown-btn";
            this.sharedFunctionsService.focus(id, true);
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.AddTaskModal,
                LogEventConstants.ManageEBSAddTaskModalClosed
            );
        });
    }

    /**
* Opens the modal that allows users to edit the given project.
* @param selectedProject
*/
    public openEditProjectModal(selectedProject: IProjectDetailsV2): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.OpenEditProjectModal,
            LogEventConstants.ManageEBSProjectEditLink
        );
        const modalRef: NgbModalRef = this.modalService.open(
            EbsEditProjectDetailsModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        modalRef.componentInstance.selectedProject = selectedProject;
        modalRef.componentInstance.engagementDetails = this.engagementFullDetailsV2;
        modalRef.result.then(() => {
            const id = "project-" + selectedProject.id + "-dropdown-btn";
            this.sharedFunctionsService.focus(id, true);
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenEditProjectModal,
                LogEventConstants.ManageEBSEditProjectDetailsModalClosed
            );
        });
    }

    /**
* Opens the modal that allows users to edit the team structure for a project.
* @param selectedProject
*/
    public openEditProjectTeam(selectedProject: IProjectDetailsV2): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.OpenEditProjectTeam,
            LogEventConstants.ManageEBSEditProjectTeamStructureLinkClicked
        );
        const modalRef = this.modalService.open(
            EditProjectTeamStructureModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        modalRef.componentInstance.selectedProject = selectedProject;
        modalRef.componentInstance.engagementDetails = this.engagementFullDetailsV2;
        modalRef.result.then(() => {
            const id = "project-" + selectedProject.id + "-dropdown-btn";
            this.sharedFunctionsService.focus(id, true);
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenEditProjectTeam,
                LogEventConstants.ManageEBSEditProjectTeamStructureModalClosed
            );
        });
    }

    /**
* Opens the modal that allows users to edit the given service.
* @param selectedService
* @param projectStartDate
* @param projectEndDate
* @param ioEndDate
*/
    public openEditServiceModal(
        selectedService: IServiceDetailsV2,
        projectStartDate: Date,
        projectEndDate: Date,
        ioEndDate: Date,
        projectTypeCode: string
    ): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.OpenEditServiceModal,
            LogEventConstants.ManageEBSServiceEditLink
        );
        const modalRef: NgbModalRef = this.modalService.open(
            EbsEditServiceDetailsModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        modalRef.componentInstance.selectedService = selectedService;
        modalRef.componentInstance.projectStartDate = projectStartDate;
        modalRef.componentInstance.projectEndDate = projectEndDate;
        modalRef.componentInstance.ioEndDate = ioEndDate;
        modalRef.componentInstance.projectTypeCode = projectTypeCode;
        modalRef.componentInstance.engagementDetails = this.engagementFullDetailsV2;
        modalRef.result.then(() => {
            const id = "service-" + selectedService.id + "-dropdown-btn";
            this.sharedFunctionsService.focus(id, true);
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenEditServiceModal,
                LogEventConstants.ManageEBSEditServiceModalClosed
            );
        });
    }

    /**
* Opens the edit task modal for the given task
* @param task
* @param serviceName
* @param selectedEntityType
* @param serviceStartDate
* @param serviceEndDate
*/
    public openEditTaskModal(
        selectedTask: ITaskDetailsApiV2,
        serviceName: string,
        serviceStartDate: Date,
        serviceEndDate: Date
    ): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.OpenEditTaskModal,
            LogEventConstants.ManageEBSTaskEditLink
        );
        const modalRef: NgbModalRef = this.modalService.open(
            EbsEditTaskDetailsModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        modalRef.componentInstance.selectedTask = selectedTask;
        modalRef.componentInstance.serviceName = serviceName;
        modalRef.componentInstance.serviceStartDate = serviceStartDate;
        modalRef.componentInstance.serviceEndDate = serviceEndDate;
        modalRef.result.then(() => {
            const id = "task-" + selectedTask.id + "-dropdown-btn";
            this.sharedFunctionsService.focus(id, true);
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenEditTaskModal,
                LogEventConstants.ManageEBSEditTaskModalClosed
            );
        });
    }

    /**
* Logs an event when employee link is clicked
* @param link
*/
    public logEmployeeClick(): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.LogEmployeeClick,
            LogEventConstants.EmployeeLinkClick
        );
    }

    /**
* Logs an event when an action dropdown is clicked
* @param link
*/
    public logClick(logEventConstant: string): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.LogClick,
            logEventConstant
        );
    }

    /**
* Make dropdowns list items accessibile with Keyboard Up/Down Arrow Keys for both Engagement and Project
* @param type
* @param Id
* @param action
*/
    public moveFocus(type: string, id: string, action: string): void {
        const dropdownId = type + "-" + id + "-" + action;
        this.sharedFunctionsService.focus(dropdownId, true);
    }

    /**
* Close Engagement/Project Dropdown On Tab of Last Element
*
*/
    public closeDropdownOnTab(id: string): void {
        document.getElementById(id).classList.remove("open");
    }

    /**
* Sets the editable flag, set the badge content to either true or false in project data.
* @param projectDataV2
*/
    private setProjectData(projectDataV2: IProjectDetailsV2[]): void {
        for (const proj of projectDataV2) {
            proj.showPjmField = true;
            const filteredUserStatus = this.projectUserStatusToShowBadges.filter(
                (item) => proj.userStatusCode.toLocaleUpperCase().indexOf(item) > -1
            );

            if (filteredUserStatus && filteredUserStatus.length) {
                proj.showBadge = true;
                proj.badgeText = this.getBadgeText(filteredUserStatus[0]);
                proj.canEditProject =
                    proj.canEditProject &&
                    !this.sharedFunctionsService.isProjectReadOnly(proj);
                proj.showPjmField =
                    !this.sharedFunctionsService.isProjectReadOnly(proj);
            }
            for (const service of proj.services) {
                const filteredUserServiceStatus =
                    this.serviceUserStatusToShowBadges.filter(
                        (item) => proj.userStatusCode.toLocaleUpperCase().indexOf(item) > -1
                    );
                if (filteredUserServiceStatus && filteredUserServiceStatus.length) {
                    service.showBadge = true;
                    service.badgeText = this.getBadgeText(filteredUserServiceStatus[0]);
                    /* We compare badgeText on the UI to ECIFStatus to determine when to show some add task buttons */
                }
            }
        }
    }

    /**
* Returns the badge text based on the code
* @param item
*/
    private getBadgeText(item: string): string {
        let returnValue = "";
        switch (item) {
            case "DIR":
                returnValue = "ECIF";
                break;
            case "PRP":
                returnValue = "T&M Prepayment";
                break;
            case "PER":
                returnValue = "Periodic Billing";
                break;
            case "IND":
                returnValue = "ECIF";
                break;
            default:
                break;
        }
        return returnValue;
    }
}
