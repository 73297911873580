import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UIRouterModule } from "@uirouter/angular";

import { CampusService } from "../../../common/services/campus.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DataService } from "../../../common/services/data.service";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { KeyLinksV2Component } from "./key-links-v2.component";
import { ProjectServiceV2 } from "../../../common/services/project.v2.service";
import { EditKeyLinksModalModule } from "./modals/edit-key-links/edit-key-links.module";
import { AddKeyLinksModalModule } from "./modals/add-key-links/add-key-links.module";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { DmGrmService } from "../../../common/services/dmgrm.service";

@NgModule({
    declarations: [
        KeyLinksV2Component
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
        AddKeyLinksModalModule,
        EditKeyLinksModalModule
    ],
    providers: [
        CampusService,
        ConfigManagerService,
        DataService,
        DMAuthorizationService,
        DMLoggerService,
        ProjectService,
        SharedFunctionsService,
        ProjectServiceV2,
        OneProfileService,
        DmGrmService
    ],
    entryComponents: [KeyLinksV2Component],
    exports: [KeyLinksV2Component]
})
export class KeyLinksV2Module { }
