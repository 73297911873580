import { Inject, Injectable } from "@angular/core";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { INpcProjectActualsViewModel, INpcTaskActualsViewModel, INpcProjectActuals, INpcResourceActualsViewModel, INpcActualsUpdateObject } from "./contracts/npc.contract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class NpcActualsService extends DmServiceAbstract {

    public originalResourceActuals: INpcResourceActualsViewModel[] = [];

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(dmLogger, Services.NPCActualsService );
    }

    /**
     * Takes in the given npc actuals array from the API response and transforms it into the viewmodel used on the UI.
     * Expands all projects/tasks and calculates their quantity consumed percentage.
     *
     * @param {INpcProjectActuals[]} npcActuals
     * @returns {INpcProjectActualsViewModel[]} the view model of the NPC Actuals that can be used on the UI
     * @memberof NpcActualsService
     */
    public formatNpcActualsViewModel(npcActuals: INpcProjectActuals[]): INpcProjectActualsViewModel[] {
        this.originalResourceActuals = []; /* Reset original resource actuals since we are formatting new actuals */
        if (!npcActuals || !npcActuals.length) {
            return undefined;
        }
        for (const project of npcActuals) {
            (project as INpcProjectActualsViewModel).isProjectExpanded = true;
            for (const task of project.tasks) {
                (task as INpcTaskActualsViewModel).isTaskExpanded = true;
                for (const actual of task.actuals) {
                    const consumedPercentage = (actual.actualQuantity / actual.plannedQuantity) * 100;
                    (actual as INpcResourceActualsViewModel).consumedQuantityPercentage = consumedPercentage ? consumedPercentage : 0;
                    (actual as INpcResourceActualsViewModel).isConsumedQuantityExceeded = actual.actualQuantity > actual.plannedQuantity;
                    (actual as INpcResourceActualsViewModel).consumedDate = new Date();
                    (actual as INpcResourceActualsViewModel).taskId = task.id;
                    /* Set original resource actuals here so we don't have to run through the loop again */
                    this.originalResourceActuals.push(Object.assign({}, (actual as INpcResourceActualsViewModel)));
                }
            }
        }
        return npcActuals as INpcProjectActualsViewModel[];
    }

    /**
     * Checks if two given actuals have the same matching unique Ids.
     * resource actuals have a resource ID but they are not unique within a project. Task Id/L3 Id for a resource actual must also match
     * 
     * @param {INpcResourceActualsViewModel} actual1
     * @param {INpcResourceActualsViewModel} actual2
     * @returns {boolean}
     * @memberof NpcActualsService
     */
    public doResourceActualsMatch(actual1: INpcResourceActualsViewModel | INpcActualsUpdateObject, actual2: INpcResourceActualsViewModel | INpcActualsUpdateObject): boolean {
        const actual1Wbs: string = (actual1 as INpcResourceActualsViewModel).taskId ||  (actual1 as INpcActualsUpdateObject).wbsId;
        const actual2Wbs: string = (actual2 as INpcResourceActualsViewModel).taskId ||  (actual2 as INpcActualsUpdateObject).wbsId;
        return (actual1.resourceId === actual2.resourceId) && (actual1Wbs === actual2Wbs);
    }

}