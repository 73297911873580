<div class="dm-snapshot-modal">
    <label id="snapShotDetails" class="sr-only">Rename Snapshot</label>
    <form #renameSnapshot>
        <div class="modal-header modal-noborder">
            <h4 class="modal-title font-subtitle pull-left">Rename Snapshot</h4>
            <button type="button" title="Close" class="close set-focus" aria-label="Rename Snapshot Dialog Close"
                (click)="closeModal()" id="accessibilityConstants.CloseUpdateButton"
                (keydown)="moveFocusPrev($event, 'accessibilityConstants.ContinueMapping')">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </div>
        <dm-loading [isCustomText]="true" [loadingText]="'Renaming Snapshot to '+newSnapshotName" *ngIf="isCopyLoading"
            [showLoading]="true">
        </dm-loading>
        <div class="modal-body" *ngIf="!isCopyLoading">
            <section class="mr-b-10">
                <p class="font-label m-b-4">Current Name</p>
                <p class="font-caption-alt" *ngIf="snapshotDetails.versionDescription">
                    {{snapshotDetails.versionDescription}}</p>
            </section>

            <section class="snapshotName">
                <label class="input__label p-b-4 snapinput__text" for="newSnapshotName">Snapshot Name</label>

                <section class="input_label_rel">
                    <input class="input__text input_text_edge" [required]="true" id="newSnapshotName"
                        name="newSnapshotName" type="text" [(ngModel)]="newSnapshotName" maxlength="100"
                        aria-labelledby="textMsg" aria-invalid="false"
                        [ngClass]="{'input_error': !newSnapshotName ,  'dmsearchfield_txt': newSnapshotName.length>0}" />
                    <label id="textMsg" class="sr-only" aria-hidden="true">
                        Snapshot Name required {{100 - newSnapshotName?.length}} of 100 characters remaining
                    </label>
                    <button type="button" class="no-btn close-icon" aria-label="snapshot name clear"
                        (click)="clearText()" *ngIf="newSnapshotName">
                        <i class="icon icon-cancel"></i>
                    </button>
                    <div class="row">
                        <p class="remaining-char font-caption col-sm-6 col-md-6 col-xs-7">
                            ({{100 - newSnapshotName?.length}}/100 characters remaining)
                        </p>
                    </div>
                </section>
                <span *ngIf="!newSnapshotName" role="alert" aria-atomic="true" aria-live="polite"
                    class="font-caption-alt input_error_msg errorMsg">
                    {{dmSnapshotErrorMessage.SnapshotNameRequired}}
                </span>

            </section>
        </div>
        <div class="modal-footer" *ngIf="!isCopyLoading">
            <button type="button" value="cancel" id="accessibilityConstants.Cancel" class="fxp-btn fxp-btn--secondary"
                [disabled]="isCopyLoading" (click)="closeModal()"
                (keydown)="moveFocusNext($event, 'accessibilityConstants.ContinueMapping')">
                Cancel
            </button>

            <button type="button" value="Continue" id="accessibilityConstants.ContinueMapping"
                class="fxp-btn fxp-btn--primary"
                [disabled]="!newSnapshotName || newSnapshotName === snapshotDetails.versionDescription || isCopyLoading"
                (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseUpdateButton')"
                (click)="changeSnapshotName()">
                Submit
            </button>
        </div>
    </form>
</div>