import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UnapprovedExpenseComponent } from "./unapproved-expense-modal.component";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        UnapprovedExpenseComponent,
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        UnapprovedExpenseComponent,
    ],
    exports: [
        UnapprovedExpenseComponent,
    ]
})
export class UnapprovedExpenseModalModule { }
