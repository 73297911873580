import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CceacV2Component } from "./cceac-v2.component";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        CceacV2Component
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        CceacV2Component
    ],
    exports: [
        CceacV2Component
    ]
})
export class CceacV2Module { }
