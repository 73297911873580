<section class="inline-survey">
    <form #surveyForm="ngForm" id="surveyForm" [formGroup]="surveyFeedbackForm" (ngSubmit)="onSubmitSurveyFeedback()">
        <span>
            <span id="rating-question">{{surveyData?.ratingQuestion}}</span>
            <span class="required-mark" aria-hidden="true"> *</span>
        </span>
        <p role="alert"
            *ngIf="(surveyRating?.dirty || surveyRating?.touched) && surveyRating?.invalid && surveyRating?.errors?.required"
            class="error-msg" aria-atomic="true" aria-live="polite">
            {{dmInlineSurveyErrorMessage.SelectRatingBeforeSubmitting}}</p>
        <ngb-rating aria-labelledby="rating-question" class="text-align--center"
            [attr.title]="surveyRating?.value + ' of ' + maxRating + ' stars'" formControlName="surveyRating"
            [max]="maxRating" required></ngb-rating>
        <div>
            <span id="survey-response-label">{{surveyData?.surveyQuestion ? surveyData?.surveyQuestion : 'Have additional feedback or
                suggestions?'}}</span>
            <span class="required-mark" aria-hidden="true"> *</span>
            <div id="survey-response-desc" class="info-label">Do not include any personal or confidential information.</div>
        </div>
        <p role="alert"
            *ngIf="(surveyResponse?.dirty || surveyResponse?.touched) && surveyResponse?.invalid && surveyResponse?.errors?.required"
            class="error-msg" aria-atomic="true" aria-live="polite">
            {{dmInlineSurveyErrorMessage.EnterFeedbackBeforeSubmitting}}</p>
        <p role="alert" *ngIf="(surveyResponse?.dirty || surveyResponse?.touched) && surveyResponse?.errors?.maxlength"
            class="error-msg" aria-atomic="true" aria-live="polite">
            {{dmInlineSurveyErrorMessage.Max1000CharactersAllowed}}</p>
        <textarea aria-describedby="survey-response-desc" name="surveyResponse" placeholder="Leave a comment" formControlName="surveyResponse"
            aria-labelledby="survey-response-label" maxlength="1000" required aria-invalid="false"></textarea>
        <div class="inline-survey__email-consent">
            <input class="inline-survey__email-consent-checkbox" name="emailConsent" aria-labelledby="email-consent"
                id="emailConsent" type="checkbox" formControlName="isEmailConsentChecked" />
            <label id="email-consent" class="inline-survey__email-consent-label" for="emailConsent">You can contact me about this
                feedback.</label>
        </div>
        <input name="surveyUserEmail" class="email-input" type="text" formControlName="surveyUserEmail"
            aria-label="survey-email" />
        <p role="alert" *ngIf="surveyUserEmail?.invalid" class="error-msg" aria-atomic="true" aria-live="polite">
            {{dmInlineSurveyErrorMessage.EnterValidEmail}}</p>
        <div class="dm-p-t-10">
            <a class="font-caption-alt dm-p-y-10" href="https://go.microsoft.com/fwlink/?LinkId=518021"
                target="_blank" aria-label="open link in new tab">Your
                privacy is important to us.</a>
        </div>
        <a class="button-container">
            <button class="btn clear-btn" type="button" (click)="surveyFeedbackForm.reset()">Clear</button>
            <button class="btn submit-btn" type="button" (click)="onSubmitSurveyFeedback()">Submit</button>
        </a>
    </form>
</section>