import { Component, Input, Inject, Output, EventEmitter, ViewChild, ElementRef, forwardRef } from "@angular/core";
import { Components, LogEventName, CacheKeys, CacheStorageOptions } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { FaultControlService } from "../../../common/services/faultcontrol.service";
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { IBulkIntProjectDetails, IDict, IRoleRequestDetailsV2 } from "../../../common/services/contracts/bulk-upload-int-engagement.service.contracts";
import { IDomainValues, IProjectChargeTypes, IRoleDetail, RoleDeliveryType } from "../../../common/services/contracts/add-roles.contracts";
import { IInternalEngagementCreationCode } from "../../../common/services/contracts/portfolio.model";
import { IInternalEngagementDomainTypes, ICostCenterValidationOutput, ICreateInternalEngRoleData, INewInternalEngagementProjectInput, INewCreateInternalEngagementOutput, INewCreateInternalEngagementProjectOutput } from "../new-internal-engagement.contracts";
import { IOneProfileCompanyCodeAttr, IOneProfileCompanyCodeAttrV2 } from "../../tiles/type-ahead-companycode/type-ahead-companycode-contracts";
import { IGrmProjectCreateFunctionRequest, IInternalEngagementInfo, IInternalProjectDetail, ITeam, IRoleRequest, ISkillData } from "../internal-engagement-grm.contracts";
import { IRoleSkills, IRoleDomainSkill, IDomainDetails } from "../../../common/services/contracts/one-profile.contracts";
import { ISelectedUserAttributes } from "../../tiles/type-ahead/type-ahead-contracts";
import { ITeamDetailsV2, IEngagementDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { LogEventConstants, SourceConstants } from "../../../common/application.constants";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ProjectService } from "../../../common/services/project.service";
import { ProjectServiceFunctions } from "../../../common/services/projectservice-functions.service";
import { ProjectServiceV2 } from "../../../common/services/project.v2.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { InternalEngagementService } from "../../../common/services/internal-engagement-service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UserInfoService, FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { IFinancialRoles } from "../../../common/services/contracts/projectservice-functions.contract";
import { CacheService } from "../../../common/services/cache.service";
import { DmError } from "../../../common/error.constants";
import moment from "moment";
import { OneProfileService } from "../../../common/services/one-profile.service";

const unitBasedTypeCode: string = "97";
// const msInternalTypeCode: number = 96;
const srOutCorpHQ: string = "95";
const srOutOtherBG: string = "98";
const srOutUsFedConsulting: string = "82";
const srOutUsFedSupport: string = "83";
const ECIF_EngagementCreationCode: string = "Z2";
const DATE_FORMAT: string = "YYYY-MM-DD";
const DATE_FORMAT2: string = "T00:00:00.000";
const INTERNALORDER: string = "InternalOrder";
const FIXEDAMOUNT: string = "FixedAmount";

@Component({
    selector: "dm-add-internal-engagement-project",
    templateUrl: "./add-internal-engagement-project.html",
    styleUrls: ["./add-internal-engagement-project.scss"]
})
export class AddInternalEngagementProjectComponent extends DmComponentAbstract {

    // convenience getter for easy access to form fields
    public get projectFormControl(): any {
        return this.projectForm.controls;
    }

    public get projectName(): any {
        return this.projectForm.get("projectName");
    }
    public get projectDescription(): any {
        return this.projectForm.get("projectDescription");
    }
    public get projectNBUEHours(): any {
        return this.projectForm.get("projectNBUEHours");
    }
    public get projectDomain(): any {
        return this.projectForm.get("projectDomain");
    }
    public get projectChargeType(): any {
        return this.projectForm.get("projectChargeType");
    }
    public get projectSrCrossChargeType(): any {
        return this.projectForm.get("projectSrCrossChargeType");
    }
    public get uploadedProjectNBUEFileName(): any {
        return this.projectForm.get("uploadedProjectNBUEFileName");
    }
    public get projectStartDate(): any {
        return this.projectForm.get("projectStartDate");
    }
    public get projectEndDate(): any {
        return this.projectForm.get("projectEndDate");
    }
    public get projectSrCrossChargeValue(): any {
        return this.projectForm.get("projectSrCrossChargeValue");
    }
    public get projectCostCenter(): AbstractControl {
        return this.projectForm.get("projectCostCenter");
    }
    public get selectedPjm(): any {
        return this.projectForm.get("selectedPjm");
    }
    public get selectedADPJM(): any {
        return this.projectForm.get("selectedADPJM");
    }
    public get projectFormValue(): any {
        return this.projectForm ? this.projectForm.value : undefined;
    }
    public get projectChargeAccountType(): any {
        return this.projectForm.get("projectChargeAccountType");
    }
    public get projectECIFAmount(): any {
        return this.projectForm.get("projectECIFAmount");
    }
    public get projectECIFAmountUSD(): any {
        return this.projectForm.get("projectECIFAmountUSD");
    }

    @ViewChild("projectNBUEFileUpload", { static: false }) public projectNBUEFileUpload: ElementRef;
    @Input() public isComponentModal: boolean = false;
    @Input() public projectData: any;
    @Input() public internalEngagementType: IInternalEngagementCreationCode;
    @Input() public isEngagementConfidential: boolean;
    @Input() public engagementCompany: IOneProfileCompanyCodeAttrV2;
    @Input() public engagementId: string;
    @Input() public engagementStartDate: Date;
    @Input() public engagementEndDate: Date;
    @Input() public projectIndex: number;
    @Input() public addProjectModalReference?: NgbModalRef;
    @Input() public engagementDetails: IEngagementDetailsV2;
    @Output() public onProjectDeleted = new EventEmitter<number>();
    @Output() public onProjectCreated = new EventEmitter<string>();
    public newRolesDetails: IRoleDetail[] = [];
    public internalProjectDomain: IInternalEngagementDomainTypes;
    public internalProjectDomains: IInternalEngagementDomainTypes[] = [];
    public isCreateProjectDisabled: boolean = false;
    public selectedAdPjmsList: ISelectedUserAttributes[] = [];
    public showProject: boolean = true;
    public showLoading: boolean = false;
    public startDateValidationMessage: string = "";
    public endDateValidationMessage: string = "";
    public showFileUploadValidationMessage: boolean;
    public pjmSearchAriaLblTxt: string;
    public pjmTypeAheadLabelText: string;
    public pjmCancelAriaLblTxt: string;
    public adPjmSearchAriaLblTxt: string;
    public adPjmTypeAheadLabelText: string;
    public adPjmCancelAriaLblTxt: string;
    public uploadedProjectFileObj: any;
    public showSRBasedFields: boolean;
    public disableMonthlyFixedCharge: boolean;
    public showECIFPresalesBasedFields: boolean;
    public showNBUEFields: boolean;
    public hideNbueUploadField: boolean;
    public projectForm: FormGroup;
    public isFromBulkProcess: boolean = false;
    public isProjectCostCenterLoading: boolean = false;
    public isProjectCostCenterReadOnly: boolean = false;
    public projectCostCenterErrorMsg: string = "";
    public projectInternalOrderErrorMsg: string = "";
    public showRoleDetailsRequiredMessage: boolean = false;
    public showRoleDetailsValidationMessage: boolean = false;
    public projectDetails: IBulkIntProjectDetails;
    public loadingText: string;
    public invalidPjmMessage: string;
    public invalidAdpjmMessage: string;
    public internalProjectErrorMessages = DmError.InternalEngagement;
    public fileFormatsAllowed: string;
    public projectChargeAccountTypes: IProjectChargeTypes[];
    public currencyCodeBasedOnCompanyCode: string;
    public exchangeRate = 1;
    private FXP_CONSTANTS = FxpConstants;
    private nonDecimalRegex: string;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(FaultControlService) private faultControlService: FaultControlService,
        @Inject(InternalEngagementService) private internalEngagementService: InternalEngagementService,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(CacheService) private cacheService: CacheService
    ) {
        super(dmLogger, Components.AddInternalEngagementProject);

        this.pjmSearchAriaLblTxt = "Search Project Managers";
        this.pjmTypeAheadLabelText = "Project Manager required";
        this.pjmCancelAriaLblTxt = "Remove Project Manager Name";

        this.adPjmSearchAriaLblTxt = "Search Project Managers";
        this.adPjmTypeAheadLabelText = "Additional Project Managers";
        this.adPjmCancelAriaLblTxt = "Remove Additonal Primary Project Manager Name";
        this.nonDecimalRegex = "[0-9]*";
    }

    public ngOnInit(): void {
        this.configManagerService.initialize().then(() => {
            this.internalProjectDomains = this.configManagerService.getValue<IDomainValues[]>("internalEngagementDomains");
            this.projectChargeAccountTypes = this.configManagerService.getValue<IProjectChargeTypes[]>("chargeTypeAccounts");
            this.fileFormatsAllowed = this.sharedFunctionsService.supportedUploadFileFormats.join(", ");
            /* Register all the form controls through form builder and you can initialize the values and validators. */
            this.projectForm = this.fb.group({
                projectName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                projectDescription: ["", Validators.maxLength(255)],
                projectDomain: ["", Validators.required],
                projectNBUEHours: [null, [Validators.required, Validators.pattern(this.nonDecimalRegex)]],
                projectCostCenter: [null, Validators.required],
                projectSrCrossChargeValue: [null],
                projectSrCrossChargeType: [null],
                uploadedProjectNBUEFile: [null],
                uploadedProjectNBUEFileName: [""],
                projectChargeType: [""],
                projectStartDate: [null, Validators.required],
                projectEndDate: [null, Validators.required],
                selectedPjm: [null, Validators.required],
                selectedADPJM: [null],
                projectChargeAccountType: [null],
                projectECIFAmount: [null],
                projectECIFAmountUSD: [null]
            });

            if (this.engagementStartDate) {
                this.projectStartDate.setValue(new Date(this.engagementStartDate));
            }
            if (this.engagementEndDate) {
                this.projectEndDate.setValue(new Date(this.engagementEndDate));
            }
            if (this.internalEngagementType) {
                this.toggleFieldsOnEngagementType(this.internalEngagementType);
            }
            if (!this.engagementCompany && this.engagementDetails) {
                this.engagementCompany = {
                    companyCode: +this.engagementDetails.companyCode,
                    companyName: this.engagementDetails.companyName
                };
            }

            if (this.projectData && this.projectData.hideNbueUploadField) {
                this.hideNbueUploadField = true;
            }

            if (this.projectData && this.projectData.isProjectFromBulkProcess) {
                this.isFromBulkProcess = true;
                this.hideNbueUploadField = true;
                this.setProjectInitValues(this.projectData);
            } else {
                this.selectedPjm.setValue(this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr());
            }

            this.projectSrCrossChargeType.valueChanges.pipe(untilDestroyed(this))
                .subscribe((srCrossChargeType) => {
                    // Listen for sr cross charge type changes and change fixed value to null if it user has not touched
                    // the value field as we have default value that need to be set if it is percentage but not fixed amount.
                    if (srCrossChargeType === "FixedAmount") {
                        if (!this.projectSrCrossChargeValue.dirty) {
                            this.projectSrCrossChargeValue.setValue("");
                        }
                    }
                });
        });

        this.setLoadersBasedOnItemState();
        if (this.isComponentModal) {
            this.sharedFunctionsService.focus("projectName-" + this.projectIndex, true);
        }
    }

    /**
     * This closes the add project module if it is opened as modal popup and modal reference
     * is passed through input.
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    public closeModal(): void {
        if (this.addProjectModalReference) {
            this.addProjectModalReference.close();
        }
    }

    /**
     * Emits an output event on click of delete
     *
     * @param {string} id
     * @memberof AddInternalEngagementProjectComponent
     */
    public onDeleteProject(id: number): void {
        this.onProjectDeleted.emit(id);
    }

    /**
     * Clicks upload project file button
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    public uploadProjectFileBtnClick(): void {
        this.projectNBUEFileUpload.nativeElement.click();
    }

    /**
     * Gets the uploaded NBUE file documentation object
     *
     * @param {Event} fileInput
     * @memberof AddInternalEngagementProjectComponent
     */
    public uploadProjectNBUEFileBtnClick(fileInput: Event): void {
        const fileObject = (fileInput.target as HTMLInputElement).files[0];
        this.uploadedProjectFileObj = fileObject;

        const formData = new FormData();
        formData.append("file", this.uploadedProjectFileObj);

        this.projectService.uploadFileToBlob(this.uploadedProjectFileObj ? formData : null, this.internalEngagementType.engagementCreationCode)
            .then((outputFileName) => {
                this.projectFormControl.uploadedProjectNBUEFileName.setValue(outputFileName);
            });
    }

    /**
     * Toggle project based on click.
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    public toggleProject(): void {
        this.showProject = !this.showProject;
    }


    /**
     * Transform USD ECIF value to local currency
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    public updateLocalECIFValue(): void {
        const usdECIFValue = this.projectECIFAmountUSD.value;
        const localECIFValue = usdECIFValue * this.exchangeRate;
        this.projectECIFAmount.setValue(localECIFValue);
        this.projectECIFAmount.markAsDirty();
    }

    /**
     * Called upon project start date change.
     *
     * @param {Date} date
     * @memberof AddInternalEngagementProjectComponent
     */
    public selectProjectStartDate(date: Date): void {
        this.projectStartDate.setValue(date);
        this.validateDates();
    }

    /**
     * This method is called on project end date change.
     *
     * @param {Date} date
     * @memberof AddInternalEngagementProjectComponent
     */
    public selectProjectEndDate(date: Date): void {
        this.projectEndDate.setValue(date);
        this.validateDates();
    }

    /**
     * This method is called on project manager change.
     *
     * @param {ISelectedUserAttributes} value
     * @memberof AddInternalEngagementProjectComponent
     */
    public selectedUserUpdated(value: ISelectedUserAttributes): void {
        this.selectedPjm.setValue(value);
    }

    /**
     * Clears cost center/IO id on user click.
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    public clearProjectCostCenter(): void {
        this.projectCostCenter.patchValue(null);
        this.isProjectCostCenterReadOnly = false;
    }

    /**
     * Validates cost center/IO id.
     *
     * @param {string} costCenterId
     * @returns {Promise<void>}
     * @memberof AddInternalEngagementProjectComponent
     */
    public validateProjectCostCenter(costCenterId: string): void {
        if (costCenterId) {
            // Invalidate project cost center field and only remove error in successful case
            this.projectCostCenter.setErrors({ invalidCostCenter: true });
            this.isProjectCostCenterReadOnly = true;
            this.isProjectCostCenterLoading = true;
            this.projectCostCenterErrorMsg = "";
            this.projectInternalOrderErrorMsg = "";
            const chargeType: string = this.showECIFPresalesBasedFields ? INTERNALORDER : this.projectChargeType.value;
            this.projectService.validateCostCenter(costCenterId, chargeType)
                .then((response: ICostCenterValidationOutput) => {
                    if (response) {
                        this.isProjectCostCenterReadOnly = true;
                        this.isProjectCostCenterLoading = false;
                        if (response.costObjectDesc && response.costObjectId) {
                            this.projectFormControl.projectCostCenter.patchValue(response.costObjectId + "-" + response.costObjectDesc);
                        }
                        // Validate cost center/IO company code and selected company code are equal and show error message if they are not equal.
                        if ((response.companyCode && this.engagementCompany && this.engagementCompany.companyCode)) {
                            if (response.companyCode !== this.engagementCompany.companyCode.toString()) {
                                this.projectCostCenterErrorMsg = DmError.InternalEngagement.CostCenterOrInternalOrderMustBeInTheCompanyCode;
                                this.projectInternalOrderErrorMsg = DmError.InternalEngagement.InternalOrderMustBeInTheCompanyCode;
                            } else {
                                this.projectCostCenter.setErrors(null);
                            }
                        } else {
                            this.projectCostCenterErrorMsg = DmError.InternalEngagement.CompanyCodeIsRequiredForValidation;
                            this.projectInternalOrderErrorMsg = DmError.InternalEngagement.CompanyCodeIsRequiredForValidation;
                        }
                    }
                }).catch((error: any) => {
                    this.isProjectCostCenterReadOnly = false;
                    this.isProjectCostCenterLoading = false;
                    if (error.status === 400 || error.status === 404) {
                        this.projectCostCenterErrorMsg = DmError.InternalEngagement.EnterAValidCostCenterOrInternalOrder;
                        this.projectInternalOrderErrorMsg = DmError.InternalEngagement.EnterAValidInternalOrder;
                    } else {
                        if (error && error.data && error.data.Message && error.data.Target) {
                            this.projectCostCenterErrorMsg = DmError.InternalEngagement.ErrorWhileValidatingCostCenterOrInternalOrder;
                            this.projectInternalOrderErrorMsg = DmError.InternalEngagement.ErrorWhileValidatingInternalOrder;
                        }
                    }
                    this.logError(SourceConstants.Method.ValidateProjectCostCenter, error, this.projectCostCenterErrorMsg, ErrorSeverityLevel && ErrorSeverityLevel.High);
                });
        }
    }

    /**
     * Validate file type for file upload.
     * Returns true if the file type is NOT valid. Returns false if valid or the file was not uploaded (because it's optional.)
     * TODO: Will be used later for nbue file upload.
     */
    public validateFileType(): boolean {
        // file upload is not part of unit based engagement.
        if (this.internalEngagementType && this.internalEngagementType.engagementCreationCode && this.internalEngagementType.engagementCreationCode !== unitBasedTypeCode) {
            if (this.uploadedProjectFileObj && this.uploadedProjectFileObj.name) {
                const fileExtension = this.uploadedProjectFileObj.name.substring(this.uploadedProjectFileObj.name.lastIndexOf(".") + 1, this.uploadedProjectFileObj.name.length);
                const acceptedFileExtensions = ["msg", "pdf"];
                if (acceptedFileExtensions.filter((extensionName: string) => extensionName === fileExtension).length === 0) {
                    this.showFileUploadValidationMessage = true;
                    return true;
                } else if (fileExtension === "msg" || fileExtension === "pdf") {
                    this.showFileUploadValidationMessage = false;
                    return false;
                }
            } else if (!this.uploadedProjectFileObj || !this.uploadedProjectFileObj.name) {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Create a new project with the values provided in the form.
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    public async createProject(): Promise<void> {
        this.loadingText = `Creating SAP Project: "${this.projectName.value}"`;
        this.isCreateProjectDisabled = true;
        this.showLoading = true;
        this.isComponentLoading = true;
        this.projectServiceV2.addProjectToExistingInternalEngagement(this.engagementId, this.getProjectDetails()).then(async (internalEngagementResponse: INewCreateInternalEngagementOutput) => {
            const projectId: string = internalEngagementResponse ? internalEngagementResponse.id : "";
            const projectResponse: INewCreateInternalEngagementProjectOutput = internalEngagementResponse.projects && internalEngagementResponse.projects.length ? internalEngagementResponse.projects[0] : undefined;
            this.fxpMessageService.addMessage("Successfully created SAP project " + projectId, this.FXP_CONSTANTS.messageType.success, false);
            const newGrmProjectCreationObject: IGrmProjectCreateFunctionRequest = await this.getGrmCreationInstanceForProject(projectResponse);
            this.projectServiceFunction.createInternalProjectToGrm(newGrmProjectCreationObject)
                .then(() => {
                    this.fxpMessageService.addMessage("Successfully created GRM project for " + projectId, this.FXP_CONSTANTS.messageType.success, false);
                    this.onProjectCreated.emit(projectId);
                    this.closeModal();
                }).catch((error: Promise<any>) => {
                    this.faultControlService.logGrmCreateInternalProjectFault(error);
                    this.fxpMessageService.addMessage(DmError.InternalEngagement.ErrorsInResourceRequest, this.FXP_CONSTANTS.messageType.warning, true);
                    const propertyBag = {
                        engagementType: this.internalEngagementType.typeDescription,
                        engagementId: internalEngagementResponse.id,
                        grmApiInput: JSON.stringify(newGrmProjectCreationObject)
                    };
                    this.logError(SourceConstants.Method.CreateProject, error, DmError.InternalEngagement.ErrorsInResourceRequest, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.dmLogger.logEvent(SourceConstants.Component.CreateInternalEngagementPage, SourceConstants.Method.CreateProject, LogEventName.InternalEngagementGrmProjectionCreationFailure, propertyBag);
                    this.onProjectCreated.emit(projectId);
                    this.closeModal();
                });
        }).catch((error: Promise<any>) => {
            this.showLoading = false;
            this.isComponentLoading = false;
            this.isCreateProjectDisabled = false;
            const propertyBag = {
                engagementType: this.internalEngagementType.typeDescription,
                result: LogEventConstants.SAPApiFailed,
                sapApiInput: JSON.stringify(this.getProjectDetails()),
                grmApiInput: ""
            };
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.CreateProject, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.dmLogger.logEvent(SourceConstants.Component.CreateInternalEngagementPage, SourceConstants.Method.CreateProject, LogEventConstants.CreateInternalEngagement, propertyBag);
        });
    }

    /**
     * Toggles certain finance fields based on engagement type change.
     * Public and used at the create engagement level for this project, do not make private.
     *
     * @param {IInternalEngagementCreationCode} engType
     * @memberof AddInternalEngagementProjectComponent
     */
    public toggleFieldsOnEngagementType(engType: IInternalEngagementCreationCode): void {
        /* Hide NBUE hours for these types */
        const hideNBUEHoursTypeArray: string[] = [unitBasedTypeCode, srOutCorpHQ, srOutOtherBG, srOutUsFedConsulting, srOutUsFedSupport, ECIF_EngagementCreationCode];
        if (engType && hideNBUEHoursTypeArray.indexOf(engType.engagementCreationCode) < 0) {
            this.showNBUEFields = true;
            this.showSRBasedFields = false;
            this.showECIFPresalesBasedFields = false;
            this.projectChargeType.setValidators(null);
            this.projectSrCrossChargeType.setValidators(null);
            this.projectNBUEHours.setValidators([Validators.required, Validators.pattern(this.nonDecimalRegex)]);
            this.projectCostCenter.setValidators(null);
            this.projectSrCrossChargeValue.setValidators(null);
            this.projectECIFAmountUSD.setValidators(null);
            this.projectECIFAmountUSD.setValue(null);
        } else if (this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(engType.engagementCreationCode.toString())) {
            this.showNBUEFields = false;
            this.showSRBasedFields = true;
            /* Disable monthly fixed charge option for SR Out engagements */
            const srOutProjectTypes: string[] = [srOutCorpHQ, srOutOtherBG, srOutUsFedConsulting, srOutUsFedSupport];
            this.disableMonthlyFixedCharge =  srOutProjectTypes.indexOf(engType.engagementCreationCode.toString()) > -1;
            this.showECIFPresalesBasedFields = false;
            this.projectSrCrossChargeType.setValue("Percentage");
            this.projectSrCrossChargeValue.setValue("90");
            this.projectChargeType.setValidators([Validators.required]);
            this.projectSrCrossChargeType.setValidators([Validators.required]);
            this.projectSrCrossChargeValue.setValidators([Validators.required, this.SROutFormValidator()]);
            this.projectNBUEHours.setValidators(null);
            this.projectCostCenter.setValidators(Validators.required);
            this.projectECIFAmountUSD.setValidators(null);
            this.projectECIFAmountUSD.setValue(null);
        } else if (engType.engagementCreationCode && this.sharedFunctionsService.isECIFPreSalesEngagementBasedOnCreationCode(engType.engagementCreationCode.toString())) {
            this.showNBUEFields = false;
            this.showECIFPresalesBasedFields = true;
            this.projectChargeAccountType.setValue(this.projectChargeAccountTypes[0]);
            this.projectChargeAccountType.setValidators(Validators.required);
            this.projectNBUEHours.setValidators(null);
            this.projectCostCenter.setValidators(Validators.required);
            this.projectECIFAmountUSD.setValidators(Validators.required);
        } else {
            this.showNBUEFields = false;
            this.showSRBasedFields = false;
            this.showECIFPresalesBasedFields = false;
            this.projectNBUEHours.setValidators(null);
            this.projectCostCenter.setValidators(null);
            this.projectECIFAmountUSD.setValidators(null);
            this.projectECIFAmountUSD.setValue(null);
        }
        this.projectChargeType.updateValueAndValidity();
        this.projectSrCrossChargeType.updateValueAndValidity();
        this.projectNBUEHours.updateValueAndValidity();
        this.projectCostCenter.updateValueAndValidity();
        this.projectSrCrossChargeValue.updateValueAndValidity();
    }

    /**
     * Sets currency based on the company code selected
     * Public and used at the engagement level for this project.
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    public setCurrencyBasedOnCompanyCode(currencyCode: string, exchangeRate: number): void {
        this.exchangeRate = exchangeRate;
        this.currencyCodeBasedOnCompanyCode = currencyCode;
        this.updateLocalECIFValue();
    }

    /**
     * Gets project details from the form
     * Public and used at the engagement level for this project.
     *
     * @returns {IInternalEngagementProject}
     * @memberof AddInternalEngagementProjectComponent
     */
    public getProjectDetails(): INewInternalEngagementProjectInput {
        let teamStructure: ITeamDetailsV2[];
        if (this.selectedPjm && this.selectedPjm.value) {
            if (this.selectedPjm.pristine) {
                teamStructure = this.sharedFunctionsService.createTeamStructure(this.selectedPjm.value, this.selectedAdPjmsList, "Project");
            } else {
                teamStructure = this.sharedFunctionsService.createTeamStructureIfDirty(this.selectedPjm.value, this.selectedAdPjmsList, "Project");
            }
        }
        if (this.projectForm.valid) {
            const projectDetails = {
                id: this.projectIndex,
                name: this.projectName.value,
                description: this.projectDescription.value,
                startDate: moment(this.projectStartDate.value).format(DATE_FORMAT + DATE_FORMAT2),
                endDate: moment(this.projectEndDate.value).format(DATE_FORMAT + DATE_FORMAT2),
                domain: this.projectDomain.value.mddDomainValueId,
                nbueApprovedHours: this.projectNBUEHours.value ? this.projectNBUEHours.value : 0, // TODO: Checkwhy service is throwing error for empty
                teams: teamStructure ? teamStructure : [],
                roles: this.getRoleDetails(this.newRolesDetails),
                documentName: this.uploadedProjectNBUEFileName.value,
                costObject: this.projectCostCenter.value ? this.projectCostCenter.value.split("-")[0] : "",
                costObjectType: this.showECIFPresalesBasedFields ? INTERNALORDER : this.projectChargeType.value,
                chargeType: this.showECIFPresalesBasedFields ? FIXEDAMOUNT : this.projectSrCrossChargeType.value,
                chargeTypeValue: this.showECIFPresalesBasedFields ? this.projectECIFAmount.value : this.projectSrCrossChargeValue.value,
                chargeAccountCode: this.projectChargeAccountType.value ? (this.projectChargeAccountType.value as IProjectChargeTypes).chargeAccountNumber : null
            };
            if (projectDetails.roles.length <= 0) {
                this.showRoleDetailsValidationMessage = true;
                return;
            }
            return projectDetails;
        } else {
            return;
        }
    }

    /**
     * Get Project details that are required for bulk internal engagmeent update.
     * Public and used for the add engagement component for this project.
     *
     * @returns {IBulkIntProjectDetails}
     * @memberof AddInternalEngagementProjectComponent
     */
    public getProjectDetailsFromBulk(): IBulkIntProjectDetails {
        let projectDetailsForBulk: any = {};
        if (this.projectDetails) {
            projectDetailsForBulk = { ...this.projectDetails };
        }
        projectDetailsForBulk.name = this.projectName && this.projectName.value ? this.projectName.value : undefined;
        projectDetailsForBulk.description = this.projectDescription && this.projectDescription.value ? this.projectDescription.value : undefined;
        projectDetailsForBulk.startDate = this.projectStartDate.value ? moment(this.projectStartDate.value).format(DATE_FORMAT + DATE_FORMAT2) : undefined;
        projectDetailsForBulk.endDate = this.projectEndDate.value ? moment(this.projectEndDate.value).format(DATE_FORMAT + DATE_FORMAT2) : undefined;
        projectDetailsForBulk.primaryDomain = this.projectDomain.value ? { name: this.projectDomain.value.domainValueName, id: +this.projectDomain.value.mddDomainValueId } : undefined;
        projectDetailsForBulk.nbueApprovedHours = this.projectNBUEHours && this.projectNBUEHours.value ? this.projectNBUEHours.value : undefined;

        if (this.selectedPjm && this.selectedPjm.value) {
            let teamStructure: ITeamDetailsV2[];
            if (this.selectedPjm.pristine) {
                teamStructure = this.sharedFunctionsService.createTeamStructure(this.selectedPjm.value, this.selectedAdPjmsList, "Project");
            } else {
                teamStructure = this.sharedFunctionsService.createTeamStructureIfDirty(this.selectedPjm.value, this.selectedAdPjmsList, "Project");
            }
            projectDetailsForBulk.teamStructure = teamStructure ? teamStructure : undefined;
        }

        if (this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(this.internalEngagementType.engagementCreationCode.toString())) {
            projectDetailsForBulk.costObjectId = this.projectCostCenter.value ? this.projectCostCenter.value.split("-")[0] : undefined;
            projectDetailsForBulk.costObjectType = this.projectChargeType.value ? this.projectChargeType.value : undefined;
            if (this.internalEngagementType.engagementCreationCode.toString() === srOutCorpHQ && this.projectChargeType.value && this.projectChargeType.value === FIXEDAMOUNT) {
                projectDetailsForBulk.chargeType = undefined;
                projectDetailsForBulk.chargeTypeValue = undefined;
            }
            else {
                projectDetailsForBulk.chargeType = this.projectSrCrossChargeType.value ? this.projectSrCrossChargeType.value : undefined;
                projectDetailsForBulk.chargeTypeValue = this.projectSrCrossChargeValue.value ? this.projectSrCrossChargeValue.value : undefined;
            }
        }

        if (this.projectDetails) {
            projectDetailsForBulk.roleRequests = this.getRoleDetailsForBulk();
            projectDetailsForBulk = { ...this.projectDetails, ...projectDetailsForBulk };
            return projectDetailsForBulk;
        } else {
            projectDetailsForBulk.temporaryId = `P${this.projectIndex}`;
            projectDetailsForBulk.roleRequests = this.getRolesForBulkAddedManual(this.newRolesDetails);
            return projectDetailsForBulk;
        }
    }

    // todo add docs
    // public because it's used in the engagement level for this project
    public getGrmProjectCreationObject(): IInternalProjectDetail {
        const projectDetail: IInternalProjectDetail = {
            projectName: this.projectName.value,
            projectDescription: this.projectDescription.value,
            projectDomain: this.projectDomain.value.domainValueName,
            projectStartDate: moment(this.projectStartDate.value).format(DATE_FORMAT + DATE_FORMAT2),
            projectEndDate: moment(this.projectEndDate.value).format(DATE_FORMAT + DATE_FORMAT2),
            creatorAlias: this.fxpUserInfoService.getCurrentUser() ? this.fxpUserInfoService.getCurrentUser() : this.selectedPjm.value.userAlias,
            deliveryManager: this.selectedPjm.value && this.selectedPjm.value.userAlias ? this.selectedPjm.value.userAlias : this.selectedPjm.value.Alias,
            projectManager: this.selectedPjm.value && this.selectedPjm.value.userAlias ? this.selectedPjm.value.userAlias : this.selectedPjm.value.Alias,
            team: this.getGrmProjectTeam()
        };
        return projectDetail;
    }

    /**
     * Get roles for bulk process if added through add project manually.
     * Public and used in the add engagement component for this project.
     *
     * @param {IRoleDetail[]} newRoles
     * @returns {IRoleRequestDetailsV2[]}
     * @memberof AddInternalEngagementProjectComponent
     */
    public getRolesForBulkAddedManual(newRoles: IRoleDetail[]): IRoleRequestDetailsV2[] {
        const roleRequests: IRoleRequestDetailsV2[] = [];
        for (const newRole of newRoles) {
            const tempSkills: IDict[] = [];
            for (const skill of newRole.roleSkills) {
                if (skill.JobSkillId) {
                    tempSkills.push({
                        id: skill.JobSkillId.toString(),
                        name: skill.JobSkillValue,
                        domainIds: skill.Domains.map((domain: IDomainDetails) => Number(domain.DomainId))
                    });
                }
            }
            const roleRequest: IRoleRequestDetailsV2 = {
                temporaryId: "",
                skills: tempSkills,
                startDate: moment(newRole.roleStartDate).format(DATE_FORMAT + DATE_FORMAT2),
                endDate: moment(newRole.roleEndDate).format(DATE_FORMAT + DATE_FORMAT2),
                requestedHours: newRole.roleRequestedHours,
                travelNotes: newRole.roleAdditionalDetails,
                deliveryType: newRole.deliveryType,
                isScheduleFree: newRole.isResourceScheduleFree,
                shouldFreeResourceCalendar: newRole.isResourceScheduleFree,
                primaryDomain: { id: "", name: newRole.roleDomainName },
                requestedResource: {
                    alias: newRole.roleRequestedResource && newRole.roleRequestedResource.userAlias ? newRole.roleRequestedResource.userAlias : null,
                    bpId: newRole.roleRequestedResource && newRole.roleRequestedResource.bpId ? newRole.roleRequestedResource.bpId : null,
                    name: newRole.roleRequestedResource && newRole.roleRequestedResource.userName ? newRole.roleRequestedResource.userName : null
                },
                role: {
                    name: newRole.roleName,
                    partNumber: newRole.rolePartNumber,
                    partNumberStatus: {
                        id: 0,
                        value: "",
                    },
                    resourceType: {
                        id: 0,
                        value: ""
                    },
                    activityType: {
                        isCostRateRegistrationAllowed: false,
                        id: 0,
                        value: "",
                        code: "",
                        status: true,
                        attributes: null
                    },
                    requiredClearance: {
                        id: 0,
                        value: "",
                        code: "",
                    },
                },
                deliveryCountryCode: newRole.deliveryCountryCode,
                deliveryState: newRole.deliveryState,
                deliveryCity: newRole.deliveryCity
            };
            roleRequests.push(roleRequest);
        }
        return roleRequests;
    }

    /**
     * Get role details for bulk process if it is from excel data.
     * Public and used in the add engagement component for this project.
     *
     * @returns {IRoleRequestDetailsV2[]}
     * @memberof AddInternalEngagementProjectComponent
     */
    public getRoleDetailsForBulk(): IRoleRequestDetailsV2[] {
        let roleRequests: IRoleRequestDetailsV2[] = [];
        const rolesAddedManually = this.newRolesDetails.filter((x) => !x.isRoleFromExcelUpload);
        const roleRequestsAddedManually: IRoleRequestDetailsV2[] = this.getRolesForBulkAddedManual(rolesAddedManually);
        let roleRequestsFromExcel: IRoleRequestDetailsV2[] = [];
        if (this.projectDetails.roleRequests) {
            roleRequestsFromExcel = this.projectDetails.roleRequests.map((x) => {
                const filtReq = this.newRolesDetails.filter((t) => t.temporaryId === x.temporaryId)[0];
                if (filtReq) {
                    const tempSkills: IDict[] = [];
                    if (filtReq.roleSkills && filtReq.roleSkills.length) {
                        for (const skill of filtReq.roleSkills) {
                            if (skill.JobSkillId) {
                                tempSkills.push({
                                    id: skill.JobSkillId.toString(),
                                    name: skill.JobSkillValue,
                                    domainIds: (skill.Domains &&  skill.Domains.length > 0) ? skill.Domains.map((domain: IDomainDetails) => Number(domain.DomainId)) : [],
                                });
                            }
                        }
                    }

                    return {
                        ...x,
                        skills: tempSkills,
                        startDate: moment(filtReq.roleStartDate).format(DATE_FORMAT + DATE_FORMAT2),
                        endDate: moment(filtReq.roleEndDate).format(DATE_FORMAT + DATE_FORMAT2),
                        requestedHours: filtReq.roleRequestedHours,
                        travelNotes: filtReq.roleAdditionalDetails,
                        deliveryType: filtReq.deliveryType,
                        primaryDomain: { id: x.primaryDomain.id, name: filtReq.roleDomainName },
                        isScheduleFree: filtReq.isResourceScheduleFree,
                        shouldFreeResourceCalendar: filtReq.isResourceScheduleFree,
                        requestedResource: {
                            alias: filtReq.roleRequestedResource && filtReq.roleRequestedResource.userAlias ? filtReq.roleRequestedResource.userAlias : null,
                            bpId: filtReq.roleRequestedResource && filtReq.roleRequestedResource.bpId ? filtReq.roleRequestedResource.bpId : null,
                            name: filtReq.roleRequestedResource && filtReq.roleRequestedResource.userName ? filtReq.roleRequestedResource.userName : null
                        },
                        role: {
                            ...x.role,
                            name: filtReq.roleName,
                            partNumber: filtReq.rolePartNumber,
                        },
                        deliveryCountryCode: filtReq.deliveryCountryCode,
                        deliveryState: filtReq.deliveryState,
                        deliveryCity: filtReq.deliveryCity
                    };
                }
            });
        }
        roleRequests = [...roleRequestsAddedManually, ...roleRequestsFromExcel];
        roleRequests = roleRequests.filter((role: IRoleRequestDetailsV2) => role);
        return roleRequests;
    }

    /**
     * Get resource requests todo docs
     * Public and used by the add engagement component for this project.
     */
    public async getGrmResourceRequests(): Promise<IRoleRequest[]> {
        const resourceRequest: IRoleRequest[] = [];
        const financialRoles: IFinancialRoles[] = await this.cacheService.get(CacheKeys.FinancialRoles.KeyName,
            () => this.projectServiceFunction.getFinancialRoles(), CacheKeys.FinancialRoles.Duration, CacheStorageOptions.LocalStorage);
        if (this.newRolesDetails && this.newRolesDetails.length) {
            this.newRolesDetails.forEach((newRole) => {
                const resourceReq: IRoleRequest = {
                    deliveryTypeEnum: newRole.deliveryType,
                    roleStartDate: moment(newRole.roleStartDate).format(DATE_FORMAT + DATE_FORMAT2),
                    roleEndDate: moment(newRole.roleEndDate).format(DATE_FORMAT + DATE_FORMAT2),
                    roleRequestedHours: newRole.roleRequestedHours,
                    roleName: newRole.roleName,
                    requestedResource: (newRole.roleRequestedResource && newRole.roleRequestedResource.userAlias ? newRole.roleRequestedResource.userAlias : null),
                    assignedResourceBPID: (newRole.roleRequestedResource && newRole.roleRequestedResource.bpId ? newRole.roleRequestedResource.bpId : null),
                    skills: this.getGrmRoleSkills(newRole),
                    requestDomain: newRole.roleDomainName,
                    rolePartNumber: newRole.rolePartNumber,
                    isAutoCommit: newRole.isSpecialRole,
                    requiredClearanceValue: this.sharedFunctionsService.isClearedRole(newRole.rolePartNumber, financialRoles) || this.sharedFunctionsService.isFederalSrOutEngagementBasedOnCreationCode(this.internalEngagementType.engagementCreationCode.toString()) ? "Cleared" : null,
                    isScheduleFree: newRole.isResourceScheduleFree,
                    isResourceBillable: this.getIsResourceBillable(this.internalEngagementType.engagementCreationCode, newRole.isResourceBillable),
                    deliveryCountryCode: newRole.deliveryType === RoleDeliveryType.Remote ? newRole.deliveryCountryCode : undefined,
                    deliveryState: newRole.deliveryType === RoleDeliveryType.Remote ? newRole.deliveryState : undefined,
                    deliveryCity: newRole.deliveryType === RoleDeliveryType.Remote ? newRole.deliveryCity : undefined
                };
                resourceRequest.push(resourceReq);
            });
        }
        return resourceRequest;
    }

    /**
     * Updates value of adpjm control when deletion occurs from multiselect typeahead.
     *
     * @param {ISelectedUserAttributes[]} selectedAdpjms
     * @memberof AddInternalEngagementProjectComponent
     */
    public onAdpjmUpdate(selectedAdpjms: ISelectedUserAttributes[]): void {
        this.selectedADPJM.setValue(selectedAdpjms);
        this.selectedAdPjmsList = selectedAdpjms;
    }

    /**
     * pass updated nbue value
     */
    public onNbueUpdate(): void {
        this.internalEngagementService.updateNbue(this.projectNBUEHours._pendingValue);
    }

    /**
     * Clear project cost center field if a value is present when charge type is changed.
     */
    public onChargeTypeUpdate(): void {
        if (this.projectCostCenter.value) {
            if (this.isFromBulkProcess || (this.projectCostCenter.dirty || this.projectCostCenter.touched)) {
                this.clearProjectCostCenter();
            }
        }
    }

    /**
     * Custom validator to be used with the form group.
     * If the project is SR Out, validates if the SR Cross Charge values are valid (>0) or not (<=0)
     *
     * @private
     * @returns {ValidatorFn}
     * @memberof AddInternalEngagementProjectComponent
     */
    private SROutFormValidator(): ValidatorFn {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (control: AbstractControl): {
            [key: string]: any;
        } | null => {
            const value = this.projectSrCrossChargeValue.value; // todo may want to pass these values in as params instead of direct access
            if (value > 0) { /* Values greater than 0 are valid, return null, no error */
                return null;
            }
            /* Return an error with content based on the type of SR Out Value. */
            /* This error will be shown on the UI */
            const type: string = this.projectSrCrossChargeType.value;
            return { SrOutValue: type === "Percentage" ? "SR Cross Charge percentage must be greater than 0%." : "SR Cross Charge monthly fixed charge must be greater than 0." };

        };
    }

    /**
     * Get role details and prepare role object for engagement creation.
     */
    private getRoleDetails(roles: IRoleDetail[]): ICreateInternalEngRoleData[] {
        if (!roles || !roles.length) {
            return [];
        }
        const tempRoleList: ICreateInternalEngRoleData[] = roles.map((newRole: IRoleDetail) => {
            return {
                role: newRole.rolePartNumber,
                startDate: moment(newRole.roleStartDate).format(DATE_FORMAT + DATE_FORMAT2),
                endDate: moment(newRole.roleEndDate).format(DATE_FORMAT + DATE_FORMAT2),
                requestedHours: newRole.roleRequestedHours,
                isBillable: false
            };
        });
        return tempRoleList;
    }

    /**
     * Validates project start date and project end date.
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    private validateDates(): void {
        this.startDateValidationMessage = "";
        this.endDateValidationMessage = "";
        if (!this.projectStartDate.value) {
            this.startDateValidationMessage = DmError.InternalEngagement.ProjectStartDateIsRequired;
        } else if (!this.projectEndDate.value) {
            this.endDateValidationMessage = DmError.InternalEngagement.ProjectEndDateIsRequired;
        } else if (this.projectStartDate.value && this.projectEndDate.value && moment(this.projectStartDate.value).isAfter(this.projectEndDate.value)) {
            this.endDateValidationMessage = DmError.InternalEngagement.StartDateCannotBeGreaterThanEndDate;
        } else if ((this.projectStartDate.value && this.engagementStartDate && moment(this.projectStartDate.value).isBefore(this.engagementStartDate))
            || (this.projectStartDate.value && !this.engagementStartDate)) {
            this.startDateValidationMessage = DmError.InternalEngagement.ProjectStartDateCannotBeEarlierThanEngagement;
        } else if ((this.projectEndDate.value && this.engagementEndDate && moment(this.projectEndDate.value).isAfter(this.engagementEndDate))
            || (this.projectEndDate.value && !this.engagementEndDate)) {
            this.endDateValidationMessage = DmError.InternalEngagement.ProjectEndDateCannotBeGreaterThanEngagement;
        }
    }

    /**
     * Get role skills
     */
    private getGrmRoleSkills(role: IRoleDetail): ISkillData[] {
        const tempSkillsList: ISkillData[] = [];
        if (role.roleSkills && role.roleSkills.length) {
            role.roleSkills.forEach((skill: IRoleSkills) => {
                const tempSkill: ISkillData = {
                    id: skill.JobSkillId.toString(),
                    name: skill.JobSkillValue.indexOf("(Retiring)") ? skill.JobSkillValue.replace("(Retiring)", "").trim() : skill.JobSkillValue,
                };
                tempSkillsList.push(tempSkill);
            });
        }
        return tempSkillsList;
    }

    /**
     * Get Engagement Info
     */
    private getEngagementInfo(internalEngagementProjectOutput: INewCreateInternalEngagementProjectOutput): IInternalEngagementInfo {
        const engagementInfo: IInternalEngagementInfo = {
            id: internalEngagementProjectOutput.id,
            name: this.engagementDetails.name,
            countryCode: this.engagementDetails.customerCountry,
            state: this.engagementDetails.customerState,
            city: this.engagementDetails.customerCity,
            isConfidential: this.isEngagementConfidential,
            requestTypeCode: this.internalEngagementType.engagementCreationCode.toString(),
            customerName: this.engagementDetails.customerName,
            customerId: this.engagementDetails.customerId
        };
        return engagementInfo;
    }

    /**
     * Get project team todo docs
     */
    private getGrmProjectTeam(): ITeam[] {
        const apjmList: ITeam[] = [];
        if (this.selectedAdPjmsList && this.selectedAdPjmsList.length) {
            for (const apjm of this.selectedAdPjmsList) {
                const tempAddManager: ITeam = {
                    alias: apjm.userAlias,
                    userName: apjm.userName,
                    role: "ADPJM"
                };
                apjmList.push(tempAddManager);
            }
        }
        return apjmList;
    }

    /**
     * Get role creation instance for project.
     */
    private async getGrmCreationInstanceForProject(internalEngagementProjectOutput: INewCreateInternalEngagementProjectOutput): Promise<IGrmProjectCreateFunctionRequest> {
        const newProjectCreationObject: IGrmProjectCreateFunctionRequest = {
            engagementInfo: this.getEngagementInfo(internalEngagementProjectOutput),
            projectDetail: this.getGrmProjectCreationObject(),
            roleDetail: await this.getGrmResourceRequests(),
            projectResponse: internalEngagementProjectOutput,
            shouldDisableSystemNotification: false
        };
        return newProjectCreationObject;
    }

    /**
     * Sets Project Initial values in the form if a project data is being passed from engagement.
     *
     *
     * @param {*} projDetails
     * @memberof AddInternalEngagementProjectComponent
     */
    private setProjectInitValues(projDetails: IBulkIntProjectDetails) {
        this.projectDetails = projDetails;
        this.toggleFieldsOnEngagementType(this.internalEngagementType);
        this.projectName.setValue(projDetails.name ? projDetails.name : undefined);
        this.projectDescription.setValue(projDetails.description ? projDetails.description : undefined);
        this.projectNBUEHours.setValue(projDetails.nbueApprovedHours ? projDetails.nbueApprovedHours : undefined);
        this.projectStartDate.setValue(projDetails.startDate ? projDetails.startDate : undefined);
        this.projectEndDate.setValue(projDetails.endDate ? projDetails.endDate : undefined);
        if (this.internalEngagementType.engagementCreationCode.toString() === srOutCorpHQ && projDetails.chargeType && projDetails.chargeType.toString() === FIXEDAMOUNT) {
            this.projectSrCrossChargeType.setValue("Percentage");
            this.projectSrCrossChargeValue.setValue(undefined);
        }
        else {
            this.projectSrCrossChargeType.setValue(projDetails.chargeType ? projDetails.chargeType : undefined);
            this.projectSrCrossChargeValue.setValue(projDetails.chargeTypeValue ? projDetails.chargeTypeValue : undefined);
        }

        this.projectChargeType.setValue(projDetails.costObjectType ? projDetails.costObjectType : undefined);
        this.projectCostCenter.setValue(projDetails.costCenter && projDetails.costCenter.costObjectId ? projDetails.costCenter : undefined);
        this.projectECIFAmount.setValue(projDetails.chargeTypeValue ? projDetails.chargeTypeValue : undefined);
        this.projectChargeAccountType.setValue(projDetails.chargeAccountCode && this.projectChargeAccountTypes.filter((obj: IProjectChargeTypes) => obj.chargeAccountNumber === projDetails.chargeAccountCode).length ? this.projectChargeAccountTypes.filter((obj: IProjectChargeTypes) => obj.chargeAccountNumber === projDetails.chargeAccountCode)[0] : undefined);
        this.projectDomain.setValue(projDetails.primaryDomain && projDetails.primaryDomain.name ? this.internalProjectDomains.filter((domain) => domain.mddDomainValueId === projDetails.primaryDomain.id.toString())[0] : undefined);

        this.validateProjectCostCenter(projDetails.costCenter.costObjectId);

        const teamStructure = projDetails.teamStructure;
        if (teamStructure && teamStructure.length) {
            const PJM = teamStructure.filter((x) => x.role === "PJM")[0];
            if (PJM && PJM.bpid) {
                this.selectedPjm.setValue(this.sharedFunctionsService.getUserInfoObject(PJM));
            }

            let ADPJM = teamStructure.filter((t) => t.role === "ADPJM");
            ADPJM = ADPJM.filter((f) => f.bpid);
            if (ADPJM && ADPJM.length) {
                this.selectedADPJM.setValue(ADPJM);
                this.selectedAdPjmsList = ADPJM.map((x) => this.sharedFunctionsService.getUserInfoObject(x));
            }

            // If an error exists in team structure, set error and invalidate proper form control
            for (const member in teamStructure) {
                if (projDetails.errorDetails && projDetails.errorDetails[`Teams[${member}],Bpid`]) {
                    if (teamStructure[member].role === "PJM") {
                        this.invalidPjmMessage = DmError.InternalEngagement.UploadedPPJMIsInvalid;
                    } else {
                        this.invalidAdpjmMessage = DmError.InternalEngagement.UploadedAdditionalPPJMsIsInvalid;
                    }
                }
            }
        }

        // Show Role Details required message if there are no role requests
        if (!projDetails.roleRequests || (projDetails.roleRequests && projDetails.roleRequests.length <= 0 && this.isFromBulkProcess)) {
            this.showRoleDetailsRequiredMessage = true;
        }

        if (projDetails.roleRequests) {
            projDetails.roleRequests.forEach((roleRequest: IRoleRequestDetailsV2, index) => {
                const roleErrors = {};
                Object.entries(projDetails.errorDetails).forEach(([key, value]) => {
                    if (key.includes(`RoleRequests[${index}]`) || key.includes(`Roles[${index}]`)) {
                        roleErrors[key.split(/[,]+/).splice(1).toString()] = value;
                    }
                });
                const skills: IRoleDomainSkill[] = [];
                const invalidSkills: string[] = [];
                const domainUnalignedSkills: string[] = [];
                roleRequest.skills.forEach((skill, roleSkillIndex) => {
                    if (skill.id && skill.isDomainAligned) {
                        skills.push({
                            JobSkillId: +skill.id,
                            JobSkillValue: skill.name,
                            Domains: this.getDomainIds(skill)
                        });
                    } else if (skill.id && skill.name && !skill.isDomainAligned) {
                        domainUnalignedSkills.push(skill.name);
                    } else {
                        invalidSkills.push(skill.name);
                    }
                    let isResourceRequested: boolean = true;
                    Object.entries(roleErrors).forEach(([key]) => {
                        if (key.includes(`Skills[${roleSkillIndex}],IsDomainAligned`)) {
                            roleErrors[`${key.split(`[${roleSkillIndex}],`)[0]}Domain`] = `One or more uploaded skills are not part of the ${roleRequest.primaryDomain.name} domain: ${domainUnalignedSkills.join()}`;
                            delete roleErrors[key];
                        } else if (key.includes(`Skills[${roleSkillIndex}],Id`)) {
                            roleErrors[key.split(`[${roleSkillIndex}],`)[0]] = `${DmError.InternalEngagement.OneOrMoreSkillsAreInvalid}: ${invalidSkills.join()}`;
                            delete roleErrors[key];
                        } else if (key.includes("RequestedResource,BusinessDomain")) {
                            roleErrors["RequestedResourceBusinessDomain"] = DmError.InternalEngagement.ProvidedResourceAliasIsNotAnAreaAccountAlignedResource;
                            delete roleErrors[key];
                        } else if (key.includes("RequestedResource,ShouldFreeResourceCalendar")) {
                            roleErrors["ShouldFreeResourceCalendar"] = DmError.InternalEngagement.RequestorMustBeInTheApprovedSecurityGroups;
                            delete roleErrors[key];
                        } else if (key.includes("RequestedResource,IsTrainingCompleted")) {
                            roleErrors["RequestedResourceIsTrainingCompleted"] = DmError.InternalEngagement.WWTKTrainingDateForResourceHasExpired;
                            delete roleErrors[key];
                        } else if (key.includes("RequestedResource,BpId")) {
                            isResourceRequested = false;
                            roleErrors["RequestedResourceBpId"] = roleErrors[key];
                            delete roleErrors[key];
                        }
                    });

                    // do not show business domain and training error message if there is no resource request
                    if (!isResourceRequested) {
                        Object.entries(roleErrors).forEach(([key]) => {
                            if (key === "RequestedResourceBusinessDomain" || key === "RequestedResourceIsTrainingCompleted") {
                                delete roleErrors[key];
                            }
                        });
                    }
                });
                const roleDetail: IRoleDetail = {
                    temporaryId: roleRequest.temporaryId,
                    isRoleFromExcelUpload: true,
                    deliveryType: roleRequest.deliveryType ? roleRequest.deliveryType : undefined,
                    JobSkillValue: "",
                    isClearedRole: true,
                    isResourceScheduleFree: roleRequest.shouldFreeResourceCalendar,
                    isSpecialRole: false,
                    roleAccordionFlag: null,
                    roleAdditionalDetails: roleRequest.travelNotes ? roleRequest.travelNotes : undefined,
                    roleDomainName: roleRequest.primaryDomain ? roleRequest.primaryDomain.name : undefined,
                    roleDomainId: roleRequest.primaryDomain ? roleRequest.primaryDomain.id : undefined,
                    roleEndDate: roleRequest.endDate ? roleRequest.endDate.toString() : undefined,
                    roleName: roleRequest.role ? roleRequest.role.name : undefined,
                    rolePartNumber: roleRequest.role ? roleRequest.role.partNumber : undefined,
                    roleRequestedHours: roleRequest.requestedHours ? roleRequest.requestedHours : undefined,
                    roleRequestedResource: {
                        userAlias: roleRequest.requestedResource.alias,
                        userName: roleRequest.requestedResource.name,
                        bpId: roleRequest.requestedResource.bpId,
                        emailName: "",
                        fullName: "",
                        firstName: "",
                        lastName: ""
                    },
                    roleSequence: null,
                    roleSkills: skills,
                    roleStartDate: roleRequest.startDate ? roleRequest.startDate.toString() : undefined,
                    errorDetails: Object.keys(roleErrors).length > 0 ? roleErrors : undefined, // checking if object is empty,
                    isResourceBillable: this.getIsResourceBillable(this.internalEngagementType.engagementCreationCode, roleRequest.isResourceBillable)
                };
                this.newRolesDetails.push(roleDetail);
            });
        }
    }

    /**
     * Get domain ids from skill details
     */
    private getDomainIds(skill: IDict): IDomainDetails[] {
        const domainDetails: IDomainDetails[] = [];
        for (const domainId of skill.domainIds) {
            domainDetails.push({
                DomainId: domainId
            });
        }
        return domainDetails;
    }


    /**
     * Get role creation instance for project.
     */
    private getIsResourceBillable(engagementTypeCode: string, isResourceBillable: boolean): boolean {
        if (this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(engagementTypeCode.toString()) || this.sharedFunctionsService.isECIFPreSalesEngagementBasedOnCreationCode(engagementTypeCode.toString())) {
            return true;
        } else if (engagementTypeCode === unitBasedTypeCode) {
            return isResourceBillable;
        }
        return false;
    }

}
