import { NgModule } from "@angular/core";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CommonComponentsModule } from "../entry/common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { DmSliderModule } from "../tiles/dm-slider/dm-slider.module";
import { DropDownModule } from "./drop-down/drop-down.module";
import { FeedbackModalModule } from "../tiles/feedback-modal/feedback-modal.module";
import { FeedbackModalService } from "../tiles/feedback-modal/feedback-modal.service";
import { DmNotificationService } from "../../common/services/dm-notification.service";
import { InlineSurveyModule } from "../shared/inline-survey/inline-survey.module";
import { MyPortfolioService } from "../../common/services/my-portfolio.service";
import { NavigationComponent } from "./nav.component";
import { ProjectDetailService } from "../../common/services/project-detail.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { TabNavigationModule } from "./tab-navigation/tab-navigation.module";
import { WhatsNewModalModule } from "../shared/whats-new/whats-new-modal.module";
import { NavBarNotificationModule } from "../tiles/nav-bar-notification/nav-bar-notification.module";

@NgModule({
    declarations: [
        NavigationComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        DmSliderModule,
        DropDownModule,
        FeedbackModalModule,
        FormsModule,
        InlineSurveyModule,
        TabNavigationModule,
        WhatsNewModalModule,
        NavBarNotificationModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        FeedbackModalService,
        MyPortfolioService,
        NgbDropdownConfig,
        ProjectDetailService,
        SharedFunctionsService,
        DmNotificationService
    ],
    entryComponents: [
        NavigationComponent
    ],
    exports: [
        NavigationComponent
    ]
})
export class NavigationModule { }
