import { Component, Inject, forwardRef, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FxpMessageService, UserInfoService, FxpConstants, FxpEventBroadCastService, FxpRouteService, ErrorSeverityLevel } from "@fxp/fxpservices";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { CosmicServiceFunctions } from "../../../../../common/services/cosmic-function.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { DMNotificationService, NotificationModel } from "../../../../../common/services/dmnotification.service";
import { IValidateEngagementResponse, IProjectThresholdValues } from "../../../../../common/services/contracts/project.service.contracts";
import { ICosmicFunctionResponse } from "../../../../../common/services/contracts/cosmic-function.contracts";
import { IDistributionListObject } from "../../../../../common/services/contracts/wbs.service.contracts";
import { IEngagementDetailsApiV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { InvalidateEngagementDetails } from "../../../../../store/engagement-details/engagement-details.action";
import { InvalidateFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.action";
import { IOnSuccess } from "../../../../../common/services/contracts/financial.service.contracts";
import { IState } from "../../../../../store/reducers";
import { ISuccessMessages } from "../../../../../common/services/contracts/financial.service.contracts";
import { NotificationEvent, Components, RouteName, BroadcastEvent, SourceConstants } from "../../../../../common/application.constants";
import { ProjectService } from "../../../../../common/services/project.service";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { ProjectServiceV2 } from "../../../../../common/services/project.v2.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { DmNotificationService } from "../../../../../common/services/dm-notification.service";
import { NotificationType } from "../../../../../common/services/contracts/notification-bar.contracts";
import { v4 as uuid } from "uuid";
import { IWbsSettings } from "../../../../../common/services/contracts/project.service.v2.contracts";

@Component({
    selector: "dm-activate-engagement-modal",
    templateUrl: "./activate-engagement-modal.html",
    styleUrls: ["./activate-engagement-modal.scss"]
})
export class ActivateEngagementComponent extends DmModalAbstract {

    @Input() public engagementFullDetails: IEngagementDetailsApiV2;
    public validateEngagementFailureResponse: IValidateEngagementResponse;
    public loadingText: string;
    public showEngagementActivationInitialScreen: boolean = true;
    public showActivateFinancialLoading: boolean = false;
    public failureValidationMessage: string;
    private notificationSuccessMessages: IOnSuccess;
    private FXP_CONSTANTS: any = FxpConstants;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FxpEventBroadCastService) private fxpBroadCastService: FxpEventBroadCastService,
        @Inject(Store) private store: Store<IState>,
        @Inject(CosmicServiceFunctions) private cosmicService: CosmicServiceFunctions,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
    ) {
        super(activeModal, dmLogger, Components.ActivateEngagement);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus("closeActivationPopupButton", true);
        this.notificationSuccessMessages = this.configmanagerService.getValue<ISuccessMessages>("SuccessMessages").Notification;
        this.failureValidationMessage = "Your engagement could not be Released and Activated automatically due to the failed criteria below. Please adjust your project financials accordingly or send this engagement to the Project Controller (PJC) for review.";
        this.fxpBroadCastService.On(BroadcastEvent.SendFailureNotification, (event, args) => {
            if (args.engagementId === this.engagementFullDetails.id) {
                this.sendEmailNotificationForFailure();
            }
        });
    }

    /**
     * Send Email to ASC and update the submission indicator for the engagement ID.
     * This method should only be called in case of failures.
     * 
     */
    public sendEmailToASCAndUpdateEngagementId(): void {
        this.loadingText = "Sending Email to Controller";
        this.showActivateFinancialLoading = true;
        const promises = [this.sendEmailNotificationToASC()];
        Promise.all(promises).then(() => {
            this.store.dispatch(new InvalidateFinancialDetailsV2(this.engagementFullDetails.id));
            this.store.dispatch(new InvalidateEngagementDetails(this.engagementFullDetails.id));
            this.activeModal.close();
        });
    }

    /**
     * Submit for Acivation
     */
    public submitForActivation(): void {
        this.showEngagementActivationInitialScreen = true;
        this.showActivateFinancialLoading = true;
        this.loadingText = "Activating Engagement Financial Plan";

        this.projectService.validateRelease(this.engagementFullDetails.id)
            .then((response) => {
                if (response.status === 200) {
                    this.continueToRelease();
                } else if (response.status === 206 && response.data && response.data.projectThresholdValues) {
                    this.validateEngagementFailureResponse = response.data;
                    this.addContractTypeToProjectThreshold();
                    this.showEngagementActivationInitialScreen = false;
                    this.showActivateFinancialLoading = false;
                }
            }).catch((error) => {
                /* if error contains the validation error details display them else send an email notification. */
                if (error.data && error.data.projectThresholdValues && error.data.projectThresholdValues.length) {
                    this.logError(SourceConstants.Method.SubmitForActivation, error, error.data, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.validateEngagementFailureResponse = error.data;
                    this.addContractTypeToProjectThreshold();
                    this.showEngagementActivationInitialScreen = false;
                    this.showActivateFinancialLoading = false;
                } else {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.SubmitForActivation, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.showActivateFinancialLoading = false;
                    this.sendEmailNotificationForFailure();
                    this.activeModal.close();
                }
            });

    }

    /**
     * Close the modal and navigate to Plan & Forecast view
     */
    public closeAndUpdateBaselineClicked(): void {
        this.activeModal.close();
        this.fxpRouteService.navigatetoSpecificState(RouteName.EngagementPlanForecast);
    }

    /**
     * Continue to release in case of:
     * 1. success response from validate api
     * 2. warning status for revenue variance while releasing T&M package
     */
    public continueToRelease(): void {
        this.showEngagementActivationInitialScreen = true;
        this.showActivateFinancialLoading = true;
        this.loadingText = "Releasing Engagement Financial Plan";
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        const orchestrationId: string = uuid();
        this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.ReleaseAndActivate, loggedInUserData.BusinessPartnerId, this.engagementFullDetails.id, orchestrationId).then(() => {
            this.projectServiceFunction.orchestrateReleaseAndActivate(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.engagementFullDetails.id, this.engagementFullDetails.name, orchestrationId).then(() => {
                this.fxpMessageService.addMessage("Release and Activate has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                // Displays the new notification in the notification bar
                this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.engagementFullDetails.id, orchestrationId, NotificationType.ReleaseAndActivate);
                this.showMessage();
                this.activeModal.close(true);
            });
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.ContinueToRelease, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.activeModal.close(false);
        });
    }

    /**
     * Add the contract type to project threshold values of engagement failure response
     */
    private addContractTypeToProjectThreshold(): void {
        for (const projectThresholdValue of this.validateEngagementFailureResponse.projectThresholdValues) {
            projectThresholdValue.revenueThresholdRange = projectThresholdValue.revenueThresholdRange.split("to")[0];
            const thresholdProject = this.engagementFullDetails.projects.filter((project) => project.id === projectThresholdValue.projectId);
            if (thresholdProject && thresholdProject.length) {
                projectThresholdValue.contractType = thresholdProject[0].contractType;
            }
        }
    }

    /**
     * Displays Success Message on successful activation of engagement
     */
    private showMessage(): void {
        const entityName = (this.engagementFullDetails as IEngagementDetailsApiV2).name;
        let message = this.notificationSuccessMessages.OnValidationSuccessOrchestrationTriggered;
        message = message.replace("#", entityName);
        this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.success);
    }

    /**
     * Send Email Notification For Failure
     */
    private sendEmailNotificationForFailure(): void {
        const engagementId = this.engagementFullDetails.id;
        const entityName = this.engagementFullDetails.name;
        this.sendNotification(engagementId, NotificationEvent.EngagementActivationFailed).then(() => {
            let message = this.notificationSuccessMessages.OnActivationFailure;
            message = message.replace("#", entityName);
            this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.success);
        });
    }

    /**
     * Send Email Notofication to ASC
     */
    private sendEmailNotificationToASC(): Promise<any> {
        const engagementId = this.engagementFullDetails.id;
        const entityName = this.engagementFullDetails.name;
        const emailTemplateAttributes = this.validateEngagementFailureResponse.projectThresholdValues;
        return this.sendNotification(engagementId, NotificationEvent.EngagementActivationFailedWithRules, emailTemplateAttributes).then(() => {
            let message = this.notificationSuccessMessages.OnActivationPartialSuccess;
            message = message.replace("#", entityName);
            this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.success);
        });
    }


    /**
     * Send Notification
     * @param engagementId 
     * @param entityName 
     * @param eventName 
     * @param thresholdValuesHtml 
     */
    private sendNotification(engagementId: string, eventName: string, emailTemplateAttributes?: IProjectThresholdValues[]): Promise<any> {
        const currentUser = this.fxpUserInfoService.getCurrentUser();
        const skipEsxpNotification: boolean = true;
        const securityGroups: IDistributionListObject[] = this.configmanagerService.getDistributionList(this.engagementFullDetails.isConfidential);
        const notification = new NotificationModel();
        let sendTo: string[] = [];
        const cc: string[] = this.sharedFunctionsService.getListofPjmV2(this.engagementFullDetails as IEngagementDetailsApiV2)   // gets project managers and delegated project managers for the engagement and its children projects
            .concat(this.sharedFunctionsService.getListofAdPjmV2(this.engagementFullDetails as IEngagementDetailsApiV2)) // gets additional project managers for the engagement and its children projects
            .concat(currentUser); // include the sender in the CC

        if (this.engagementFullDetails.companyCode) {
            const secGroup = securityGroups.filter((x) => x.CompanyCode === Number(this.engagementFullDetails.companyCode))[0];
            if (secGroup && secGroup.SecurityGroup) {
                sendTo = sendTo.concat(secGroup.SecurityGroup);
            }
        }
        if (!sendTo.length) { // If sendTo was never populated, then send it to the default security group
            const defaultSecurityGroup: string = securityGroups.filter((x) => x.CompanyCode === 0)[0].SecurityGroup;
            sendTo.push(defaultSecurityGroup);
        }
        notification.engagementId = engagementId;
        notification.engagementName = (this.engagementFullDetails as IEngagementDetailsApiV2).name;
        notification.eventName = eventName;
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(cc);
        notification.modifiedBy = currentUser;
        notification.modifiedDate = new Date();
        if (emailTemplateAttributes && emailTemplateAttributes.length) {
            notification.attributes = {
                Id: engagementId,
                Company: this.engagementFullDetails.companyCode + (this.engagementFullDetails.companyName ? " - " + this.engagementFullDetails.companyName : ""),
                Country: this.engagementFullDetails.customerCountry,
                IsConfidential: this.engagementFullDetails.isConfidential,
                AdditionalDetails: JSON.stringify(emailTemplateAttributes)
            };
        } else {
            notification.attributes = {
                Id: engagementId,
                Company: this.engagementFullDetails.companyCode + (this.engagementFullDetails.companyName ? " - " + this.engagementFullDetails.companyName : ""),
                Country: this.engagementFullDetails.customerCountry,
                IsConfidential: this.engagementFullDetails.isConfidential,
            };
        }
        return this.cosmicService.createCosmicTicketForNotification(notification)
            .then((cosmicFunctionResponse: ICosmicFunctionResponse) => {
                notification.subject = cosmicFunctionResponse.title;
                /* tslint:disable:no-string-literal */
                notification.attributes["CosmicTicketNumber"] = cosmicFunctionResponse.ticketNumber;
                notification.attributes["CosmicTicketLink"] = cosmicFunctionResponse.incidentId;
                return this.notificationService.sendNotification(notification, skipEsxpNotification);
            });
    }

    /**
     * Update EngagementId For Submission Indicator
     */
    private updateEngagementIdForSubmissionIndicator(): Promise<any> {
        const engagementId = this.engagementFullDetails.id;
        return this.projectServiceV2.getWbsSettings(engagementId).then((response: IWbsSettings) => {
            response.isSubmittedForManualActivation = true;
            return this.projectServiceV2.saveWbsSettings(response);
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.UpdateEngagementIdForSubmissionIndicator, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            if (error.status === 404) {
                const wbsSettings = this.projectServiceV2.getDefaultWbsSettings(engagementId);
                wbsSettings.isSubmittedForManualActivation = true;
                return this.projectServiceV2.saveWbsSettings(wbsSettings);
            }
        });
    }
}
