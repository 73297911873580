import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../../common/services/dmnotification.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../../common/services/wbs.service";
import { DmModalV2Module } from "../../../../modals/dm-modal-v2/dm-modal-v2.module";
import { EditKeyLinksModalComponent } from "./edit-key-links.component";

@NgModule({
    declarations: [
        EditKeyLinksModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        DmModalV2Module
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        DMNotificationService,
        SharedFunctionsService,
        WBSService
    ],
    entryComponents: [
        EditKeyLinksModalComponent,
    ],
    exports: [
        EditKeyLinksModalComponent,
    ]
})
export class EditKeyLinksModalModule { }