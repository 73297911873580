<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'287px'">
    <div class="tab-content col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 pd-t-24">
        <div role="tabpanel" class="tab-pane active">
            <dm-loading loader [loadingText]="'Associated Customer Engagement/Project Details'"
                [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>
            <section class="col-md-12 col-sm-12 col-xs-12 dm-portfolio-grid dm-p-l-imp-0">
                <!--=================================Overview mobile view=============================================-->
                <section *ngIf="deviceFactory.isMobile()">
                    <ng-container *ngIf="!selectedProjects">
                        <section class="project-info pull-left" *ngFor="let engagement of customerEngagementDetails">
                            <section class="project-info--wrapper pull-left">
                                <section class="col-xs-11 dm-p-x-imp-0">
                                    <p class="font-caption-alt">
                                        <b>
                                            Engagement:
                                            <a *ngIf="!engagement.isExternalLink && engagement.showLinkToUser" [uiSref]="RouteName.EngagementSummary"
                                                [uiParams]="{engagementId:engagement.engagementId }" [innerHTML]="engagement.name" class="dm-link"></a>
                                            <span *ngIf="engagement.isExternalLink || !engagement.showLinkToUser"
                                                [innerHTML]="engagement.name"></span>
                                            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4 confidentialicon"
                                                *ngIf="engagement.isConfidential" [dmPlacement]="'bottom'"
                                                [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                [dmIcon]="'tile__icon--info mr-r-8 confidentialkey'" ariaLabelToolTip="confidentiality information">
                                            </dm-tooltip>
                                            <button class="inward" [title]="project.delegationAllocationType" *ngIf="engagement.delegationAllocationType === 'delegatedInward'"
                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                    <span class="sr-only">{{engagement.name}} Project delegated to me</span>
                                                </span>
                                            </button>
                                            <button class="outward" [title]="project.delegationAllocationType" *ngIf="engagement.delegationAllocationType === 'delegatedOutward'"
                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                    <span class="sr-only">{{engagement.name}} Project delegated to
                                                        others</span>
                                                </span>
                                            </button>
                                        </b>
                                    </p>
                                    <p class="font-caption-alt">EBS: <span [innerHTML]="engagement.engagementId"></span></p>
                                    <p class="font-caption-alt" *ngIf="engagement.isExternalLink">
                                        External Domain: <span [innerHTML]="engagement.primaryDomain | dmDisplayDashIfEmptyOrNull"></span>
                                    </p>

                                </section>
                            </section>
                            <section class="col-xs-12 dm-p-x-imp-0">
                                <section class="dm-p-b-20 dm-p-x-imp-0 col-xs-8">
                                    <h5 class="font-caption-label">Duration</h5>
                                    <p class="font-caption-alt"><span [innerHTML]="engagement.startDate | dmDisplayDateOrDashPipe"></span>
                                        to <span [innerHTML]="engagement.endDate | dmDisplayDateOrDashPipe"></span></p>
                                </section>
                                <section class="dm-p-b-20 type-dialogBox__ul--mobile col-xs-4 dm-p-x-imp-0">
                                    <h5 class="font-caption-label">
                                        EBS State
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom-right'"
                                            [ngbTooltipContent]="tooltipText"
                                            [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="EBS State Information">
                                        </dm-tooltip>

                                    </h5>
                                    <p class="font-caption-alt"> <span [innerHTML]="engagement.stateDescription | dmDisplayDashIfEmptyOrNull"></span></p>
                                </section>
                            </section>
                            <section class="col-xs-12 dm-p-x-imp-0">
                                <section class="dm-p-b-20 dm-p-x-imp-0 col-xs-8">
                                    <h5 class="font-caption-label">Domain Project Manager</h5>
                                    <p class="font-caption-alt"><span [innerHTML]="engagement.pPjMName | dmDisplayDashIfEmptyOrNull"></span></p>
                                </section>
                                <section class="dm-p-b-20 type-dialogBox__ul--mobile col-xs-4 dm-p-x-imp-0">
                                    <h5 class="font-caption-label">
                                        Contract Type
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                                            [ngbTooltipContent]="profileDescContactPopupMobInt" [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Contract Type Information">
                                        </dm-tooltip>
                                    </h5>

                                    <ng-template #profileDescContactPopupMobInt>
                                        <div class="type-dialogBox">
                                            <h4 class="type-dialogBox--header font-caption-alt text-left m-b-12">Contract
                                                Types</h4>
                                            <ul class="type-dialogBox__ul col-xs-12 no-pad">

                                                <li *ngFor=" let  data of contractTypeColors" class="type-dialogBox__ul--li col-xs-6 dm-p-l-imp-0 m-b-6">
                                                    <div class="col-xs-4">
                                                        <span class="dm-badge dm-contract-type" [ngStyle]="data.typeColorCode"
                                                            [innerHTML]="data.typeShortForm"></span>
                                                    </div>
                                                    <div class="col-xs-8 text-left v-a-m fullformLbl">
                                                        <span [innerHTML]="data.typeFullForm"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>
                                    <p class="text-left text-capitilize">
                                        <span class="dm-badge dm-contract-type" [innerHTML]="engagement.contractType"
                                            [ngStyle]="engagement.typeColorCode"></span>
                                    </p>

                                </section>
                            </section>
                            <section class="col-xs-12 dm-p-x-imp-0">
                                <section class="dm-p-b-20">
                                    <h5 class="font-caption-label">Customer</h5>
                                    <p class="font-caption-alt"><span [innerHTML]="engagement.customerName | dmDisplayDashIfEmptyOrNull"></span></p>
                                </section>
                            </section>
                            <section class="pull-right dm-tile--m-t-24" *ngIf="engagement.projectDetails.length">
                                <a href class="dm-link" (click)="selectedProjects=engagement.projectDetails">
                                    View Projects
                                    <span>({{engagement.projectDetails.length}})</span>
                                    <span class="icon icon-chevron-right dm-tile--view-chevron dm-tile--t-2"></span>
                                </a>
                            </section>
                        </section>
                    </ng-container>
                    <section class="col-md-12 col-sm-12 col-xs-12 dm-no-actions-tile-data mr-t-20 dm-no-actions-tile-data--height-200"
                        *ngIf="!customerEngagementDetails.length && (!isProjectsLoading)">
                        <p class="font-title col-xs-12 col-md-12 col-sm-12 dm-tile--p-15 text-center">
                            {{noAssociatedEngagementFoundText}}</p>
                    </section>

                    <section class="col-xs-12" *ngIf="selectedProjects">

                        <a href class="dm-link pull-left m-t-12" (click)="selectedProjects=undefined">
                            <i class="icon icon-chevron-left dm-tile--m-t-5 dm-tile--view-chevron"></i>
                            <span>
                                Engagements
                            </span>

                        </a>

                        <section class="project-info pull-left" *ngFor="let  project of selectedProjects">
                            <section class="project-info--wrapper pull-left">
                                <section class="col-xs-11 dm-p-x-imp-0">
                                    <p class="font-caption-alt">
                                        <b>
                                            Project:
                                            <a *ngIf="!project.isExternalLink && project.showLinkToUser" [uiSref]="RouteName.ProjectSummary"
                                                [uiParams]="{projectId: project.projectId }" [innerHTML]="project.name" class="dm-link"></a>
                                            <span *ngIf="project.isExternalLink || !project.showLinkToUser" [innerHTML]="project.name"></span>
                                            <button class="inward" [title]="project.delegationAllocationType" *ngIf="project.delegationAllocationType === 'delegatedInward'"
                                                (click)="openDelegationModal(project.delegationDetails, 'delegatedInward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                    <span class="sr-only">{{project.name}} Project delegated to me</span>
                                                </span>
                                            </button>
                                            <button class="outward" [title]="project.delegationAllocationType" *ngIf="project.delegationAllocationType === 'delegatedOutward'"
                                                (click)="openDelegationModal(project.delegationDetails, 'delegatedOutward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                    <span class="sr-only">{{project.name}} Project delegated to others</span>
                                                </span>
                                            </button>
                                        </b>
                                    </p>
                                    <p>EBS: <span [innerHTML]="project.projectId"></span></p>
                                    <p *ngIf="project.isExternalLink">
                                        External Domain: <span [innerHTML]="project.domain  | dmDisplayDashIfEmptyOrNull"></span>
                                    </p>
                                </section>
                            </section>
                            <section class="col-xs-12 dm-p-x-imp-0">
                                <section class="dm-p-b-20 dm-p-x-imp-0 col-xs-8">
                                    <h5 class="font-caption-label">Duration</h5>

                                    <p class="font-caption-alt"><span [innerHTML]="project.startDate | dmDisplayDateOrDashPipe"></span>
                                        to <span [innerHTML]="project.endDate | dmDisplayDateOrDashPipe"></span></p>
                                </section>
                                <section class="dm-p-b-20 type-dialogBox__ul--mobile col-xs-4 dm-p-x-imp-0">
                                    <h5 class="font-caption-label">
                                        EBS State
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom-right'"
                                            [ngbTooltipContent]="tooltipText"
                                            [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="EBS State Information">
                                        </dm-tooltip>
                                    </h5>
                                    <p class="font-caption-alt"> <span [innerHTML]="project.stateDescription | dmDisplayDashIfEmptyOrNull"></span></p>
                                </section>
                            </section>
                            <section class="col-xs-12 dm-p-x-imp-0">
                                <section class="dm-p-b-20 dm-p-x-imp-0 col-xs-8">
                                    <h5 class="font-caption-label">Domain Project Manager</h5>
                                    <p class="font-caption-alt"><span [innerHTML]="project.pjMName | dmDisplayDashIfEmptyOrNull"></span></p>
                                </section>
                                <section class="dm-p-b-20 type-dialogBox__ul--mobile col-xs-4 dm-p-x-imp-0">
                                    <h5 class="font-caption-label">
                                        Contract Type
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                                            [ngbTooltipContent]="profileDescContactPopupMobProjInt" [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Contract Type Information">
                                        </dm-tooltip>
                                    </h5>
                                    <ng-template #profileDescContactPopupMobProjInt>
                                        <div class="type-dialogBox">
                                            <h4 class="type-dialogBox--header font-caption-alt text-left m-b-12">Contract
                                                Types</h4>
                                            <ul class="type-dialogBox__ul col-xs-12 no-pad">
                                                <li *ngFor="let  data of contractTypeColors" class="type-dialogBox__ul--li col-xs-6 dm-p-l-imp-0 m-b-6">
                                                    <div class="col-xs-4">
                                                        <span class="dm-badge dm-contract-type" [ngStyle]="data.typeColorCode"
                                                            [innerHTML]="data.typeShortForm"></span>
                                                    </div>
                                                    <div class="col-xs-8 text-left v-a-m fullformLbl">
                                                        <span [innerHTML]="data.typeFullForm"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>
                                    <p class="text-left text-capitilize">
                                        <span class="dm-badge dm-contract-type" [innerHTML]="project.contractType"
                                            [ngStyle]="project.typeColorCode"></span>
                                    </p>

                                </section>
                            </section>
                            <section class="col-xs-12 dm-p-x-imp-0">
                                <section class="dm-p-b-20">
                                    <h5 class="font-caption-label">Customer</h5>
                                    <p class="font-caption-alt"><span [innerHTML]="engagement.customerName | dmDisplayDashIfEmptyOrNull"></span></p>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
                <!--=================================Overview Desktop view=============================================-->
                <section *ngIf="deviceFactory.isDesktop()">
                    <div id="deliveryEngagementCaption" class="sr-only">ASSOCIATED CUSTOMER DELIVERY ENGAGEMENTS</div>
                    <table class="font-caption-alt" width="100%" aria-labelledby="deliveryEngagementCaption">                        
                        <thead>
                            <tr class="dm-tile__table__th">
                                <th scope="col" class="text-left">Name</th>
                                <th scope="col" class="text-left">
                                    <span (click)="orderByField='EBSState'; reverseSort = !reverseSort" class="sorting-header-engmt">
                                        <span class="dm-project-data__title--tooltip--opacity06 sorting-th">EBS State</span>
                                    </span>
                                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom-left'"
                                        [ngbTooltipContent]="tooltipText"
                                        [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="EBS State Information">
                                    </dm-tooltip>
                                </th>
                                <th scope="col" class="text-left">Customer</th>
                                <th scope="col" class="text-left">Duration</th>
                                <th scope="col" class="text-left">
                                    Contract<br />Type
                                    <ng-template #profileDescContactPopupIint>
                                        <div class="type-dialogBox">
                                            <h4 class="type-dialogBox--header font-caption-alt text-left m-b-12">Contract
                                                Types</h4>
                                            <ul class="type-dialogBox__ul col-md-12 no-pad">
                                                <li *ngFor="let  data of contractTypeColors" class="type-dialogBox__ul--li col-md-6 m-b-6 dm-p-l-imp-0">
                                                    <div class="col-md-4">
                                                        <span class="dm-badge dm-contract-type" [ngStyle]="data.typeColorCode"
                                                            [innerHTML]="data.typeShortForm"></span>
                                                    </div>
                                                    <div class="col-md-8 text-left v-a-m fullformLbl">
                                                        <span [innerHTML]="data.typeFullForm"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>
                                    <!-- #profile-descContactPopup-int -->
                                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom-right'"
                                        [ngbTooltipContent]="profileDescContactPopupIint" [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Contract Types Information">
                                    </dm-tooltip>
                                </th>
                                <th scope="col" class="text-left">Domain Project<br />Manager</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let  engagement of customerEngagementDetails | slice:slicedItemsNumber:gridItemsDisplay"
                            [ngClass]="{'grey_background':engagement.overallStatus==='Closed'}">
                            <tr class="dm-tile__table__tr">
                                <th scope="row" class="text-left text-capitilize">
                                    <p>
                                        <b>
                                            Engagement:
                                            <a *ngIf="!engagement.isExternalLink && engagement.showLinkToUser" [uiSref]="RouteName.EngagementSummary"
                                                [uiParams]="{engagementId:engagement.engagementId }" [innerHTML]="engagement.name" class="dm-link"></a>
                                            <span *ngIf="engagement.isExternalLink || !engagement.showLinkToUser"
                                                [innerHTML]="engagement.name"></span>
                                            <dm-tooltip role="tooltip" class="dm-tooltipinline confidentialicon" *ngIf="engagement.isConfidential"
                                                [dmPlacement]="'bottom'" [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                [dmIcon]="'tile__icon--info mr-r-8 confidentialkey'" ariaLabelToolTip="confidentiality information">
                                            </dm-tooltip>
                                            <button class="inward" [title]="project.delegationAllocationType" *ngIf="engagement.delegationAllocationType === 'delegatedInward'"
                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                    <span class="sr-only">{{engagement.name}} Project delegated to me</span>
                                                </span>
                                            </button>
                                            <button class="outward" [title]="project.delegationAllocationType" *ngIf="engagement.delegationAllocationType === 'delegatedOutward'"
                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                    <span class="sr-only">{{engagement.name}} Project delegated to
                                                        others</span>
                                                </span>
                                            </button>
                                        </b>

                                    </p>

                                    <p>EBS: <span [innerHTML]="engagement.engagementId"></span></p>
                                    <p>
                                        Domain: <span [innerHTML]="engagement.primaryDomain  | dmDisplayDashIfEmptyOrNull"></span>
                                    </p>
                                </th>
                                <td class="text-left text-capitilize" [innerHTML]="engagement.stateDescription | dmDisplayDashIfEmptyOrNull"></td>
                                <td class="text-left text-capitilize" [innerHTML]="engagement.customerName | dmDisplayDashIfEmptyOrNull"></td>
                                <td class="text-left text-capitilize"><span [innerHTML]="engagement.startDate | dmDisplayDateOrDashPipe"></span>
                                                                      -<br />
                                                                      <span [innerHTML]="engagement.endDate | dmDisplayDateOrDashPipe"></span></td>
                                <td class="text-left text-capitilize">
                                    <span class="dm-badge dm-contract-type" [ngStyle]="engagement.typeColorCode"
                                        [innerHTML]="engagement.contractType"></span>
                                </td>
                                <td class="text-left text-capitilize" [innerHTML]="engagement.pPjMName | dmDisplayDashIfEmptyOrNull"></td>
                            </tr>

                            <tr class="dm-tile__table__tr" *ngFor="let  project of engagement.projectDetails">

                                <th scope="row" class="text-left pd-l-24">
                                    <p>
                                        <b>
                                            Project:
                                            <a *ngIf="!project.isExternalLink && project.showLinkToUser" [uiSref]="RouteName.ProjectSummary"
                                                [uiParams]="{projectId: project.projectId }" [innerHTML]="project.name" class="dm-link"></a>
                                            <span *ngIf="project.isExternalLink || !project.showLinkToUser" [innerHTML]="project.name"></span>
                                            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4 confidentialicon"
                                                *ngIf="project.isConfidential" [dmPlacement]="'bottom'"
                                                [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                [dmIcon]="'tile__icon--info mr-r-8 confidentialkey'" ariaLabelToolTip="confidentiality information">
                                            </dm-tooltip>
                                            <button class="inward" [title]="project.delegationAllocationType" *ngIf="project.delegationAllocationType === 'delegatedInward'"
                                                (click)="openDelegationModal(project.delegationDetails, 'delegatedInward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                    <span class="sr-only">{{project.name}} Project delegated to me</span>
                                                </span>
                                            </button>
                                            <button class="outward" [title]="project.delegationAllocationType" *ngIf="project.delegationAllocationType === 'delegatedOutward'"
                                                (click)="openDelegationModal(project.delegationDetails, 'delegatedOutward')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                    <span class="sr-only">{{project.name}} Project delegated to others</span>
                                                </span>
                                            </button>
                                        </b>
                                    </p>
                                    <p>EBS: <span [innerHTML]="project.projectId"></span></p>
                                    <p>
                                        Domain: <span [innerHTML]="project.domain  | dmDisplayDashIfEmptyOrNull"></span>
                                    </p>

                                </th>
                                <td class="text-left" [innerHTML]="project.stateDescription | dmDisplayDashIfEmptyOrNull"></td>
                                <td class="text-left" [innerHTML]="engagement.customerName | dmDisplayDashIfEmptyOrNull"></td>
                                <td class="text-left"><span [innerHTML]="project.startDate | dmDisplayDateOrDashPipe"></span>
                                                      -<br />
                                                      <span [innerHTML]="project.endDate | dmDisplayDateOrDashPipe"></span></td>
                                <td class="text-left">
                                    <span class="dm-badge dm-contract-type" [ngStyle]="project.typeColorCode"
                                        [innerHTML]="project.contractType"></span>
                                </td>
                                <td class="text-left" [innerHTML]="project.pjMName | dmDisplayDashIfEmptyOrNull"></td>
                            </tr>


                        </tbody>
                    </table>
                    <!-- portfolio-data="customerEngagementDetails" -->
                    <section class="col-md-12 dm-pagination">
                        <dm-pagination [hidden]="customerEngagementDetails.length < 5" [portfolioItemsDisp]="portfolioItemsDisp"
                            [portfolioDataLengthAfterFilter]="customerEngagementDetails.length" [currentPage]="currentPage"
                            (currentPageChanged)="currentPageChangedHandler($event)"></dm-pagination>
                    </section>
                    <section class="col-md-12 col-sm-12 col-xs-12">
                        <dm-no-data  *ngIf="!customerEngagementDetails.length && (!isProjectsLoading)" [noDataText]="noAssociatedEngagementFoundText"></dm-no-data>
                    </section>
                </section>
                <!--=================================Overview Tablet view=============================================-->
                <section *ngIf="deviceFactory.isTablet()">
                    <table class="font-caption-alt" width="100%" role="presentation">
                        <caption class="sr-only"></caption>
                        <thead>
                            <tr class="dm-tile__table__th">
                                <th scope="col" class="w-54 p-l-46">Name</th>
                                <th scope="col" class="w-26">Customer</th>
                                <th scope="col" class="w-20">Domain<br />Project Manager</th>
                            </tr>
                        </thead>
                    </table>
                    <section *ngFor="let  engagement of customerEngagementDetails">                        
                        <div id="engagementOverviewCaption" class="sr-only">Overview details</div>
                        <table class="font-caption-alt" width="100%" aria-labelledby="engagementOverviewCaption">
                            <thead class="sr-only">
                                <tr class="sr-only dm-tile__table__th">
                                    <th scope="col" class="w-54 p-l-46">Name</th>
                                    <th scope="col" class="w-26">Customer</th>
                                    <th scope="col" class="w-20">Domain<br />Project Manager</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="dm-tile__table__tr" [ngClass]="{'expanded':engagement.openEngagement}">
                                    <th scope="row" class="text-left w-54 text-capitilize dm-p-l-imp-0">

                                        <button role="button" class="projects-btn pd-l-12" (click)="engagement.openEngagement=!engagement.openEngagement"
                                            aria-label="Spacebar to toggle">
                                            <i class="icon icon-chevron-up animation-rotation" [ngClass]="{'down': !engagement.openEngagement}"></i>
                                            <span class="sr-only" *ngIf="!engagement.openEngagement" aria-live="polite">{{engagement.openEngagement?'Expanded':'Collapsed'}}</span>
                                        </button>
                                        <div>
                                            <p>
                                                <b>
                                                    Engagement:
                                                    <a *ngIf="!engagement.isExternalLink && engagement.showLinkToUser" class="dm-link"
                                                        [uiSref]="RouteName.EngagementSummary" [uiParams]="{engagementId:engagement.engagementId }"
                                                        [innerHTML]="engagement.name"></a>
                                                    <span *ngIf="engagement.isExternalLink || !engagement.showLinkToUser"
                                                        [innerHTML]="engagement.name"></span>
                                                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4 confidentialicon"
                                                        *ngIf="engagement.isConfidential" [dmPlacement]="'bottom'"
                                                        [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                        [dmIcon]="'tile__icon--info mr-r-8 confidentialkey'" ariaLabelToolTip="confidentiality information">
                                                    </dm-tooltip>
                                                    <button class="inward" [title]="project.delegationAllocationType"
                                                        *ngIf="engagement.delegationAllocationType === 'delegatedInward'"
                                                        (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward')">
                                                        <span class="delegated" aria-hidden="true">D</span>
                                                        <span>
                                                            <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                            <span class="sr-only">{{engagement.name}} Project delegated
                                                                to me</span>
                                                        </span>
                                                    </button>
                                                    <button class="outward" [title]="project.delegationAllocationType"
                                                        *ngIf="engagement.delegationAllocationType === 'delegatedOutward'"
                                                        (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward')">
                                                        <span class="delegated" aria-hidden="true">D</span>
                                                        <span>
                                                            <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                            <span class="sr-only">{{engagement.name}} Project delegated
                                                                to others</span>
                                                        </span>
                                                    </button>
                                                </b>
                                            </p>


                                            <p>EBS: <span [innerHTML]="engagement.engagementId"></span></p>
                                            <p *ngIf="engagement.isExternalLink">
                                                External Domain: <span [innerHTML]="engagement.primaryDomain  | dmDisplayDashIfEmptyOrNull"></span>
                                            </p>
                                        </div>
                                    </th>
                                    <td class="text-left text-capitilize w-26" [innerHTML]="engagement.customerName | dmDisplayDashIfEmptyOrNull"></td>
                                    <td class="text-left text-capitilize w-20" [innerHTML]="engagement.pPjMName | dmDisplayDashIfEmptyOrNull"></td>
                                </tr>
                            </tbody>
                        </table>
                        <section class="col-sm-12 col-md-12 b-b font-caption-alt animate-accordion animate-accordion--min-w-220 dm-p-x-imp-0"
                            [hidden]="!engagement.openEngagement" [ngClass]="{'animate':engagement.openEngagement}">
                            <section class="pd-b-20 pd-l-42 pd-t-20 pull-left w-100">
                                <section class="col-sm-3 col-md-3">
                                    <h4 class="section-header pd-b-6">
                                        Contract Type
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" *ngIf="engagement.isConfidential"
                                            [dmPlacement]="'bottom-left'" [ngbTooltipContent]="profileDescContactPopupTabInt"
                                            [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Contract Type Information">
                                        </dm-tooltip>
                                    </h4>
                                    <ng-template #profileDescContactPopupTabInt>
                                        <div class="type-dialogBox">
                                            <h4 class="type-dialogBox--header text-left m-b-12">Contract Types</h4>
                                            <ul class="type-dialogBox__ul col-md-12 col-sm-12 no-pad">
                                                <li *ngFor="let  data of contractTypeColors" class="type-dialogBox__ul--li col-md-6 col-sm-6 col-md-6 m-b-6 dm-p-l-imp-0">
                                                    <div class="col-md-4 col-sm-4">
                                                        <span class="dm-badge dm-contract-type" [ngStyle]="data.typeColorCode"
                                                            [innerHTML]="data.typeShortForm"></span>
                                                    </div>
                                                    <div class="col-md-8 col-sm-8 text-left v-a-m fullformLbl">
                                                        <span [innerHTML]="data.typeFullForm"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>
                                    <p class="text-left text-capitilize">
                                        <span class="dm-badge dm-contract-type" [innerHTML]="engagement.contractType"
                                            [ngStyle]="engagement.typeColorCode"></span>
                                    </p>
                                </section>
                                <section class="col-sm-3 col-md-3">
                                    <h4 class="section-header pd-b-6">
                                        EBS State
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4" *ngIf="engagement.isConfidential"
                                            [dmPlacement]="'bottom-left'" [ngbTooltipContent]="tooltipText"
                                            [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="EBS State Information">
                                        </dm-tooltip>
                                    </h4>
                                    <p [innerHTML]="engagement.stateDescription">

                                    </p>
                                </section>
                                <section class="col-sm-4 col-md-4">
                                    <h4 class="section-header pd-b-6">
                                        Duration
                                    </h4>
                                    <p>
                                        <span [innerHTML]="engagement.startDate | dmDisplayDateOrDashPipe"></span>
                                        -
                                        <span [innerHTML]="engagement.endDate | dmDisplayDateOrDashPipe"></span>
                                    </p>
                                </section>
                            </section>
                        </section>

                        <section *ngFor="let  project of engagement.projectDetails">
                            <div id="projectOverviewCaption" class="sr-only">Overview details</div>
                            <table class="font-caption-alt" width="100%" aria-labelledby="projectOverviewCaption">
                                <thead class="sr-only">
                                    <tr class="sr-only dm-tile__table__th">
                                        <th scope="col" class="w-54 p-l-46">Name</th>
                                        <th scope="col" class="w-26">Customer</th>
                                        <th scope="col" class="w-20">Domain<br />Project Manager</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="dm-tile__table__tr" [ngClass]="{'expanded':project.openProject}">
                                        <th scope="row" class="text-left w-54 text-capitilize dm-p-l-imp-0">
                                            <button role="button" class="projects-btn pd-l-12 m-r-12" (click)="project.openProject=!project.openProject"
                                                aria-label="Spacebar to toggle">
                                                <i class="icon icon-chevron-up animation-rotation" [ngClass]="{'down': !project.openProject}"></i>
                                                <span class="sr-only" *ngIf="!project.openProject" aria-live="polite">{{project.openProject?'Expanded':'Collapsed'}}</span>
                                            </button>
                                            <div>
                                                <p>
                                                    <b>
                                                        Project:
                                                        <a *ngIf="!project.isExternalLink && project.showLinkToUser" class="dm-link"
                                                            [uiSref]="RouteName.ProjectSummary" [uiParams]="{projectId: project.projectId}"
                                                            [innerHTML]="project.name"></a>
                                                        <span *ngIf="project.isExternalLink || !project.showLinkToUser"
                                                            [innerHTML]="project.name"></span>
                                                        <button class="inward" [title]="project.delegationAllocationType"
                                                            *ngIf="project.delegationAllocationType === 'delegatedInward'"
                                                            (click)="openDelegationModal(project.delegationDetails, 'delegatedInward')">
                                                            <span class="delegated" aria-hidden="true">D</span>
                                                            <span>
                                                                <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                                <span class="sr-only">{{project.name}} Project
                                                                    delegated to me</span>
                                                            </span>
                                                        </button>
                                                        <button class="outward" [title]="project.delegationAllocationType"
                                                            *ngIf="project.delegationAllocationType === 'delegatedOutward'"
                                                            (click)="openDelegationModal(project.delegationDetails, 'delegatedOutward')">
                                                            <span class="delegated" aria-hidden="true">D</span>
                                                            <span>
                                                                <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                                <span class="sr-only">{{project.name}} Project
                                                                    delegated to others</span>
                                                            </span>
                                                        </button>
                                                    </b>
                                                </p>
                                                <p><span>EBS:</span> <span [innerHTML]="project.projectId"></span></p>
                                                <p *ngIf="project.isExternalLink">
                                                    <span>External Domain:</span> <span [innerHTML]="project.domain  | dmDisplayDashIfEmptyOrNull"></span>
                                                </p>
                                            </div>
                                        </th>
                                        <td class="text-left text-capitilize w-26" [innerHTML]="engagement.customerName | dmDisplayDashIfEmptyOrNull"></td>
                                        <td class="text-left text-capitilize w-20" [innerHTML]="project.pjMName | dmDisplayDashIfEmptyOrNull"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <section class="col-sm-12 col-md-12 b-b font-caption-alt animate-accordion--min-w-220 b-b"
                                *ngIf="project.openProject">
                                <section class="pd-b-20 pd-l-42 pd-t-20 pull-left w-100">
                                    <section class="col-sm-3 col-md-3">
                                        <h4 class="section-header pd-b-6">
                                            Contract Type
                                            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4"
                                                *ngIf="engagement.isConfidential" [dmPlacement]="'bottom-left'"
                                                [ngbTooltipContent]="tooltipText"
                                                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Contract Type Information">
                                            </dm-tooltip>
                                        </h4>
                                        <ng-template #profileDescContactPopupProjMobInt>
                                            <div class="type-dialogBox">
                                                <h4 class="type-dialogBox--header text-left m-b-12">Contract Types</h4>
                                                <ul class="type-dialogBox__ul col-md-12 col-sm-12 no-pad">
                                                    <li *ngFor="let  data of contractTypeColors" class="type-dialogBox__ul--li col-md-6 col-sm-6 dm-p-l-imp-0 m-b-6">
                                                        <div class="col-md-4 col-sm-4">
                                                            <span class="dm-badge dm-contract-type" [ngStyle]="data.typeColorCode"
                                                                [innerHTML]="data.typeShortForm"></span>
                                                        </div>
                                                        <div class="col-md-8 col-sm-8 text-left v-a-m fullformLbl">
                                                            <span [innerHTML]="data.typeFullForm"></span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </ng-template>
                                        <p class="text-left text-capitilize">
                                            <span class="dm-badge dm-contract-type" [innerHTML]="project.contractType"
                                                [ngStyle]="project.typeColorCode"></span>
                                        </p>
                                    </section>
                                    <section class="col-sm-3 col-md-3">
                                        <h4 class="section-header pd-b-6">
                                            EBS State
                                            <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4"
                                                [dmPlacement]="'bottom-left'" [ngbTooltipContent]="tooltipText"
                                                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="EBS State Information">
                                            </dm-tooltip>
                                        </h4>
                                        <p [innerHTML]="project.stateDescription">

                                        </p>
                                    </section>
                                    <section class="col-sm-4 col-md-4">
                                        <h4 class="section-header pd-b-6">
                                            Duration
                                        </h4>
                                        <p>
                                            <span [innerHTML]="project.startDate | dmDisplayDateOrDashPipe"></span>
                                            -
                                            <span [innerHTML]="project.endDate | dmDisplayDateOrDashPipe"></span>
                                        </p>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                    <section class="col-md-12 col-sm-12 col-xs-12 dm-no-actions-tile-data dm-no-actions-tile-data--height-200"
                        *ngIf="!customerEngagementDetails.length && (!isProjectsLoading)">
                        <p class="font-title col-xs-12 col-md-12 col-sm-12 dm-tile--p-15 text-center">
                            {{noAssociatedEngagementFoundText}}</p>
                    </section>
                </section>
            </section>
        </div>

    </div>
</dm-tile>