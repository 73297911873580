<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'320px'" [isLoading]="false">
    <!-- <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true"
        *ngIf="false"></dm-loading> -->
    <div *ngIf="true">
        <div class="dm-m-b-20 dm-flex-align-center">
            <div class="col-md-6 dm-p-imp-0">
                <div class="dm-detail__label">Project Forecast Duration</div>
                <div class="dm-detail__label">{{ forecastSchedules[0].startDate | date:'shortDate' }} to {{
                    forecastSchedules[forecastSchedules.length -1].endDate | date:'shortDate' }}</div>
            </div>
            <div>
                <div class="dm-detail__label">Remaining ETC Hours</div>
                <div class="dm-detail__label">{{etcHours}} hours</div>
            </div>
        </div>
        <div class="dm-m-b-12">
            <label for="hoursPerWeek" focus="true" class="dm-inline-flex-align-center">
                <span class="input-radio input-radio--focus">
                    <input id="hoursPerWeek" type="radio" name="updateScheduleOptions"
                        [ngModel]="bulkScheduleUpdateOptionSelected"
                        (ngModelChange)="onBulkUpdateScheduleOptions(bulkUpdateScheduleOptions.SpecificHoursPerWeek)"
                        [value]="bulkUpdateScheduleOptions.SpecificHoursPerWeek">
                    <span class="slider round" aria-hidden="true"></span>
                </span>
                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                    Specific hours per week
                </span>
            </label>
        </div>
        <div class="dm-m-b-20">
            <label for="spreadHours" focus="true" class="dm-inline-flex-align-center">
                <span class="input-radio input-radio--focus">
                    <input id="spreadHours" type="radio" name="updateScheduleOptions"
                        [ngModel]="bulkScheduleUpdateOptionSelected"
                        (ngModelChange)="onBulkUpdateScheduleOptions(bulkUpdateScheduleOptions.EvenlySpreadAcrossDuration)"
                        [value]="bulkUpdateScheduleOptions.EvenlySpreadAcrossDuration">
                    <span class="slider round" aria-hidden="true"></span>
                </span>
                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                    Spread planned hours evenly across project duration
                </span>
            </label>
        </div>
        <div class="dm-m-b-20 dm-flex-align-center"
            *ngIf="bulkScheduleUpdateOptionSelected === bulkUpdateScheduleOptions.SpecificHoursPerWeek">
            <div class="dm-detail col-md-6 dm-p-imp-0">
                <div class="dm-detail__label">Week Starting*</div>
                <dm-datepicker [modelDate]="weekStartDate" [placeholder]="'dd-mm-yyyy'" [(ngModel)]="weekStartDate"
                    name="WeekStartDate" ariaLabelButton="Week Starting date" ariaLabelCalendar="Week Start date"
                    previosFousingElementId="">
                </dm-datepicker>
            </div>
            <div class="dm-detail">
                <div class="dm-detail__label">Week Ending*</div>
                <dm-datepicker [modelDate]="weekEndDate" [placeholder]="'dd-mm-yyyy'" [(ngModel)]="weekEndDate"
                    name="WeekStartDate" ariaLabelButton="Week Ending date" ariaLabelCalendar="Week Ending date"
                    previosFousingElementId="">
                </dm-datepicker>
            </div>
        </div>
        <div class="dm-m-b-20 dm-flex-align-center">
            <div class="dm-detail__label dm-m-r-4">Hours* :</div>
            <input id="hour" min="0" type="number" appTwoDigitDecimalPositiveNumber [(ngModel)]="hours"
                class="hours-input dm-p-2" aria-label="enter hours" />
        </div>
    </div>
    <div additionalFooterButtons>
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" (click)="bulkUpdateDetails()"
            [disabled]="hours === 0">
            Update
        </button>
    </div>
</dm-modal-v2>