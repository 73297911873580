import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AuditService } from "../../common/services/audit.service";
import { CommonComponentsModule } from "../entry/common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { InlineSurveyModule } from "../shared/inline-survey/inline-survey.module";
import { PlanForecastAuditHistoryModule } from "./plan-forecast-audit-history/plan-forecast-audit-history-modal.module";
import { PlanForecastComponent } from "./planForecast.component";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { ForecastRecommendationService } from "../../common/services/forecast-recommendation.service";
import { ExportToExcelService } from "../../common/services/export-to-excel.service";
import { GrmForecastService } from "../../common/services/grm-forecast.service";
import { NavigationService } from "../../common/services/navigation.service";
import { FxpFlightingService } from "../../common/services/fxpflighting.service";

@NgModule({
    declarations: [
        PlanForecastComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        InlineSurveyModule,
        PlanForecastAuditHistoryModule,
        NgbModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
        AuditService,
        ForecastRecommendationService,
        ExportToExcelService,
        GrmForecastService,
        NavigationService,
        FxpFlightingService
    ],
    entryComponents: [
        PlanForecastComponent
    ],
    exports: [
        PlanForecastComponent
    ]
})
export class PlanForecastModule { }
