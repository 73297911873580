import { Component, Inject } from "@angular/core";
import { RouteName } from "../../../common/application.constants";
import { UIRouter } from "@uirouter/angular";

@Component({
    selector: "dm-internal-engagement-detail",
    templateUrl: "./internal-engagement-detail.html",
    styleUrls: ["./internal-engagement-detail.scss"]
})
export class InternalEngagementDetailComponent {
    public readonly routeName = RouteName;

    public constructor(@Inject(UIRouter) public uiRouter: UIRouter) { }

}

