import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../../common/application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class FxpFlightingService extends DmServiceAbstract {
    private fxpFlightingServiceBaseUri: string;

    /**
     * @param  {DataService} privatedataService
     * @param  {ConfigManagerService} privateconfigManagerService
     */
    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.FxpFlightingService );
        this.fxpFlightingServiceBaseUri = this.configManagerService.getValue<string>("fxpFlightingServiceBaseUri");
    }

    /**
     * Retrieves feature flags for the specified application.
     * @param appName - The name of the application.
     * @param flightingServiceEnvironment - The environment of the flighting service.
     * @param features - An array of feature names to retrieve flags for.
     * @param params - Additional parameters for the flighting service.
     * @returns A Promise that resolves to the retrieved feature flags.
     */
    public getFeatureFlags(appName: string, flightingServiceEnvironment: string, features: string[], params: {
        Alias: string;
    }): Promise<any> {
        const url = `${this.fxpFlightingServiceBaseUri}/Evaluate?featureNames=${features.join()}`;
        const additionalOptions = {
            "X-FlightContext": JSON.stringify(params),
            "X-Application": appName,
            "X-Environment": flightingServiceEnvironment
        };
        return this.dataService.getData(url, "", APIConstants.GetFeatureFlag, undefined, undefined, undefined, undefined, undefined, undefined, additionalOptions);
    }
}
