import { Pipe, PipeTransform, Inject } from "@angular/core";
import { CurrencyPipe, DecimalPipe } from "@angular/common";

/**
 * Custom pipe/filter that will display the given value as a the specific currency type.
 * If the value is 0 or does not exist, it will display a dash instead.
 * If the currency type is undefined or missing, then the number will be formatted as money (rounded to two decimal points)
 * but will not have any symbol with it.
 * This is a combo of the display dash if empty or null pipe and the built-in Currency pipe.
 * Example: 2749.9304 | dmDisplayCurrencyOrDash: 'USD'
 * This will display $2,749.93
 *
 * If the value is less than 0 (negative) we're converting -$54,898.00 to $-54,898.00 and -CA$0.26 to CA$-00.26
 */
@Pipe({
    name: "dmDisplayCurrencyOrDash"
})
export class DmDisplayCurrencyOrDashPipe implements PipeTransform {

    public constructor(@Inject(CurrencyPipe) private currencyPipe: CurrencyPipe, @Inject(DecimalPipe) private numberPipe: DecimalPipe, ) { }

    public transform(value: number, currencyCode?: string): string {
        if (!value) {
            return "-";
        }

        if (!currencyCode) {
            return this.numberPipe.transform(value, "1.2-2");
        }

        const result: string = this.currencyPipe.transform(value, currencyCode, "symbol-narrow");
        if (result[0] === "-") {
            let newResult = "";
            let flag = true;
            for (let i = 1; i < result.length; i++) {
                if (flag && !isNaN(Number(result[i]))) {
                    newResult += "-" + result[i];
                    flag = false;
                } else {
                    newResult += result[i];
                }
            }
            return newResult;
        }

        return result;
    }
}
