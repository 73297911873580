<section class="dm-slider">
    <label class="switch" [attr.title]="popoverText">
        <div tabindex="0" [id]="'slider-' + sliderId" class="set-focus" (keyup.enter)="toggleChange()" (keyup.Space)="toggleChange()">
            <input tabindex="-1" type="checkbox" (click)="toggleChange()" [(ngModel)]="isToggleChecked"
                [disabled]="isDisabled" [attr.aria-label]="labelInformation + '' + (isToggleChecked ? checkedLabel : unCheckedLabel) + ' toggle button ' + (isToggleChecked ? 'checked' : 'unchecked')">
            <span class="slider round"></span>
            <span role="status" aria-atomic="true" class="sr-only">
                {{labelInformation}} {{isToggleChecked ? checkedLabel : unCheckedLabel}} toggle button {{isToggleChecked ? 'checked' : 'unchecked'}}
            </span>
        </div>
    </label>
    <span class="toggle-label dm-p-x-10-y-5"
        *ngIf="!isLabelClickable && showLabel">{{ isToggleChecked ? checkedLabel : unCheckedLabel}}</span>
    <a class="toggle-label toggle-label-clickable dm-p-x-10-y-5 dm-link" *ngIf="isLabelClickable && showLabel"
        (click)="toggleChange()">{{ isToggleChecked ? checkedLabel : unCheckedLabel}}</a>
</section>