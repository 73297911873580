import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IGetUserPreferenceLinks, IAddUserPreferenceLinks } from "../services/contracts/userpreferencelinksmgmt.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class UserPreferenceLinksManagementService extends DmServiceAbstract {

    private projectServiceBaseUri: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.UserPreferenceLinksManagementService );
        this.configManagerService.initialize().then(() => {
            this.projectServiceBaseUri = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v1.0";
            this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        });
    }

    /**
     * Get Keylinks based on wbsID    
     */
    public getUserPreferenceLinksBasedOnWbsId(wbsId: string): Promise<IGetUserPreferenceLinks[]> {
        const url = `${this.projectServiceBaseUri}/user/wbs/${wbsId}/links`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetUserPreferenceLinks);
    }

    /**
     * Add Custom Key Links based on name and url     
     */
    public addUserPreferenceLinks(wbsId: string, customLinks: IAddUserPreferenceLinks[]): Promise<any> {
        const url = `${this.projectServiceBaseUri}/user/wbs/${wbsId}/links`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.AddUserPreferenceLinks, customLinks);
    }

    /**
     * Delete Keylinks based on id    
     */
    public deleteUserPreferenceLinks(wbsId: string, ids: number[]): Promise<any> {
        const url = `${this.projectServiceBaseUri}/user/wbs/${wbsId}/links`;
        return this.dataService.deleteData(url, this.subscriptionKey, ids);
    }
}
