import * as fromMyPortfolioFinancialList from "./my-portfolio-financials-list.action";
import { ILoadableState } from "../../reducers";
import { IPinnedEntities, IEngagementFinancialsList, IInternalEngagementFinancialsList } from "../../../common/services/contracts/portfolio.model";

export interface IMyPortfolioFinancialListState extends ILoadableState {
    financialList: Array<IEngagementFinancialsList | IInternalEngagementFinancialsList>;
    pinnedEntities: IPinnedEntities;
}

export const initialState: IMyPortfolioFinancialListState = {
    financialList: null,
    pinnedEntities: null, /* This will always remain null/undefined because pinning functionality is currently turned off. This is here in case we want to use it in the future. 5/2019 */
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = initialState, action: fromMyPortfolioFinancialList.MyPortfolioFinancialAction): IMyPortfolioFinancialListState {
    switch (action.type) {
        case fromMyPortfolioFinancialList.MyPortfolioFinancialActionTypes
            .LOAD_MY_PORTFOLIO_FINANCIALS_LIST: {
            return { ...initialState, loading: true };
        }

        case fromMyPortfolioFinancialList.MyPortfolioFinancialActionTypes
            .LOAD_MY_PORTFOLIO_FINANCIALS_LIST_SUCCESS: {
            return {
                ...initialState,
                financialList: action.engagementFinancialList,
                loaded: true,
                loading: false,
            };
        }

        case fromMyPortfolioFinancialList.MyPortfolioFinancialActionTypes
            .LOAD_MY_PORTFOLIO_FINANCIALS_LIST_FAIL: {
            return {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
        }

        case fromMyPortfolioFinancialList.MyPortfolioFinancialActionTypes
            .INVALIDATE_MY_PORTFOLIO_FINANCIALS_LIST: {
            return initialState;
        }
    }
    return state;
}
