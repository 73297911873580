import { ILoadableState } from "../reducers";
import { IClinSlinApiResponse } from "../../common/services/contracts/project.service.contracts";
import { ClinslinAction, ClinslinActionTypes } from "./clinslin.action";

export interface IClinslinState extends ILoadableState {
    clinSlinResponse: IClinSlinApiResponse;
}

export const initialState: IClinslinState = {
    clinSlinResponse: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: ClinslinAction): { [wbsId: string]: IClinslinState } {
    switch (action.type) {
        case ClinslinActionTypes.LOAD_Clinslin: {
            state[action.wbsId] = { ...initialState, loading: true };
            return state;
        }
        case ClinslinActionTypes.LOAD_MANUAL_Clinslin: {
            state[action.wbsId] = { ...initialState, loading: true };
            return state;
        }
        case ClinslinActionTypes.LOAD_Clinslin_SUCCESS: {
            state[action.wbsId] = {
                ...initialState,
                clinSlinResponse: action.clinSlinResponse,
                loaded: true,
            };
            return state;
        }

        case ClinslinActionTypes.LOAD_Clinslin_FAIL: {
            state[action.wbsId] = {
                ...initialState,
                error: action.errorMessage
            };
            return state;
        }

        case ClinslinActionTypes.INVALIDATE_Clinslin: {
            state[action.wbsId] = initialState;
            return state;
        }
    }

    return state;
}