import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { combineLatest as observableCombineLatest } from "rxjs";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components, SourceConstants, LogEventConstants, RouteName, AccessibilityConstants, DmFxpBannerMessages } from "../../../common/application.constants";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UpdateCSATContactModalComponent } from "./update-csat-contact/update-csat-contact.component";
import { UpdateContactLanguageModalComponent } from "./update-contact-language/update-contact-language.component";
import { IContactsResponse, ICsatContactType, ICsatContact, IContact, IDeleteCsatContactRequest, ICsatContactLanguage} from "../../../common/services/contracts/contacts.contracts";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../store/reducers";
import { getEntireContacts } from "../../../store/contacts/contacts.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IContactsState } from "../../../store/contacts/contacts.reducer";
import { ContactsService } from "../../../common/services/contacts.service";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { IEngagementDetailsApiV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { DmError } from "../../../common/error.constants";
import { ITile } from "../dm-tile/dm-tile.component";
import { CsatContactMode } from "./update-csat-contact/update-csat-contact.contract";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";
import { InvalidateContacts } from "../../../store/contacts/contacts.action";
import { ErrorSeverityLevel, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { DeleteCsatContactModalComponent } from "./delete-csat-contact/delete-csat-contact.component";

@Component({
    selector: "dm-contact-details",
    templateUrl: "./dm-contact-details.html",
    styleUrls: ["./dm-contact-details.scss"]
})
export class DmContactDetailsComponent extends DmComponentAbstract {
    public loadingText: string = "Loading Contact Details";
    public errorText: string;
    public showLoading: boolean;
    public wbsId: string;
    public RouteName = RouteName;
    public isProjectContext: boolean;
    public contactDetailsList: IContactsResponse;
    public hasEditPermissions: boolean;
    public isAddCsatValid: boolean;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.Contact;
    public tileContent: ITile;
    public csatContacts: ICsatContact[];
    public contactsWithNonCsatRoles: IContact[];
    public accessibilityConstants = AccessibilityConstants;
    public isDeleteCSATFeatureEnabled: boolean;
    private maxCsatContacts: number;
    private validCsatContactTypes: ICsatContactType[];
    private validCsatContactLanguages: ICsatContactLanguage[];
    private FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(ContactsService) private contactsService: ContactsService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Injector) private injector: Injector,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Components.StaffingCommandBar);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus("backToSummaryLink", true);
        this.validCsatContactTypes = this.configurationService.getValue<ICsatContactType[]>("csatContactTypes");
        this.validCsatContactLanguages = this.configurationService.getValue<ICsatContactLanguage[]>("csatContactLanguages");
        this.isDeleteCSATFeatureEnabled = this.configurationService.isFeatureEnabled("deleteCSATContact");
        this.maxCsatContacts = this.configurationService.getValue<number>("maxCsatContacts");
        this.wbsId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);

        this.isProjectContext = projectId ? true : false;
        this.tileContent = {
            title: "Contacts"
        };

        this.storeDispatchService
            .requireEngagementDetails(this.wbsId, true)
            .requireContacts(this.wbsId, true)
            .load();
        const contacts$ = this.store.select(getEntireContacts(this.wbsId));
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.wbsId));

        observableCombineLatest(
            engagementDetails$,
            contacts$,
            (
                engagementDetails: IEngagementDetailsState,
                contacts: IContactsState,
            ) => ({
                engagementDetails,
                contacts,
            })
        ).pipe(untilDestroyed(this)).subscribe(({
            engagementDetails,
            contacts,
        }) => {
            if (engagementDetails.loaded) {
                const engagementDetailsResult: IEngagementDetailsApiV2 = engagementDetails.engagementDetails;
                // Checks if user is in team structure and allows edit permissions else read only.
                if ((this.dmAuthorizationService.isUserInEngagementLevelTeam(engagementDetailsResult) || this.dmAuthorizationService.isUserInProjectLevelTeam(engagementDetailsResult.projects.filter((proj) => proj.id === projectId)[0]))) {
                    this.hasEditPermissions = true;
                } else {
                    this.hasEditPermissions = false;
                }
            }

            if (contacts.loaded) {
                this.contactsWithNonCsatRoles = [];
                this.contactDetailsList = contacts.contactsResponse;
                this.processCsatContacts();
            }

            this.refreshOnItemInvalidation(engagementDetails, contacts);
            this.setErrorsBasedOnItemState(engagementDetails, contacts);
            this.setLoadersBasedOnItemState(engagementDetails, contacts);
            if (engagementDetails.error || contacts.error) {
                this.isServerError = true;
            }
        });

        contacts$.pipe(untilDestroyed(this)).subscribe((contacts: IContactsState) => {
            this.setLoadersBasedOnItemState(contacts);
            this.setErrorsBasedOnItemState(contacts);
        });
    }

    /**
     * Getter for the CSAT enum for UI
     */
    public get getModeEnum(): typeof CsatContactMode {
        return CsatContactMode;
    }

    /**
     * Opens update CSAT contact modal when engagment id is passed
     *
     * @param {string} wbsId
     * @memberof DmContactDetailsComponent
     */
    public updateCSATContactModal(mode: CsatContactMode, editingContact: ICsatContact): void {
        this.dmLogger.logEvent(SourceConstants.Component.ContactsPage, SourceConstants.Method.UpdateCSATContactModal, LogEventConstants.UpdateCSATContactModal);
        const modalRef: NgbModalRef = this.modalService.open(UpdateCSATContactModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal update-contacts-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.mode = mode;
        if (mode === CsatContactMode.edit && editingContact) {
            modalRef.componentInstance.allowedCsatContactTypes = [editingContact.type];
            modalRef.componentInstance.csatContactToBeEdited = editingContact;
        }
        else {
            modalRef.componentInstance.allowedCsatContactTypes = this.getFilteredCsatContactTypes();
        }

    }

    /**
     * Opens update CSAT contact modal when engagment id is passed
     *
     * @param {string} wbsId
     * @memberof DmContactDetailsComponent
     */
    public updateContactLanguageModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ContactsPage, SourceConstants.Method.UpdateContactLanguageModal, LogEventConstants.UpdateContactLanguageModal);
        const modalRef: NgbModalRef = this.modalService.open(UpdateContactLanguageModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal update-contacts-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.wbsId = this.wbsId;
        
    }


    /**
     * Delete CSAT Contact 
     * @param csatContact ICsatContact
     * @returns void
     */
    public deleteCsatContact(csatContact: ICsatContact): void {
        if (this.csatContacts.length === 1) {
            this.fxpMessageService.addMessage(DmFxpBannerMessages.AtLeastOneContact, this.FXP_CONSTANTS.messageType.error, false);
            return;
        }
        const modalRef: NgbModalRef = this.modalService.open(DeleteCsatContactModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 delete-csat-contact-modal in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.confirmationMessage = DmFxpBannerMessages.ConfirmationMessage + csatContact.contact.name + "?";
        modalRef.result.then((isConfirmed: boolean) => {
            if (isConfirmed) {
                this.isComponentLoading = true;
                const deleteCsatContactRequest: IDeleteCsatContactRequest = {
                    deleteCsatContact: []
                };
                deleteCsatContactRequest.deleteCsatContact.push({
                    existingCsatCustomerId: csatContact.contact.roles[0].customerId,
                    csatContactTypeCode: csatContact.type.code
                });
                this.contactsService.deleteCSATContact(this.wbsId, deleteCsatContactRequest).then(() => {
                    this.isComponentLoading = false;
                    this.store.dispatch(new InvalidateContacts(this.wbsId));
                    this.fxpMessageService.addMessage(DmFxpBannerMessages.DeleteCSATContactSuccess, this.FXP_CONSTANTS.messageType.success, false);
                }).catch((error) => {
                    this.isComponentLoading = false;
                    this.fxpMessageService.addMessage(DmFxpBannerMessages.DeleteCSATContactFailure, this.FXP_CONSTANTS.messageType.error, false);
                    this.logError(SourceConstants.Method.DeleteCSATContact, error, DmFxpBannerMessages.DeleteCSATContactFailure, ErrorSeverityLevel && ErrorSeverityLevel.High);
                });
            }
        });
    }

    /**
     * Process and extract CSAT contacts
     */
    private processCsatContacts(): void {
        let csatContactCount = 0;
        this.csatContacts = [];
        this.contactsWithNonCsatRoles = [];
        for (const contact of this.contactDetailsList.engagementContacts) {
            const nonCsatRoles = [];
            const csatContactLanguageList = this.validCsatContactLanguages.filter((x) => x.languageCode === contact.languageCode);
            let csatContactLanguage;
            if (csatContactLanguageList && csatContactLanguageList.length > 0) {
                csatContactLanguage = csatContactLanguageList[0];
                contact.language = csatContactLanguage;
            }
            for (const role of contact.roles) {
                // Check if role is CSAT contact                
                const csatContactType = this.validCsatContactTypes.filter((x) => x.code === role.contactTypeCode);
                if (csatContactType && csatContactType.length > 0) {
                    csatContactCount++;
                    const csatContact: ICsatContact = {
                        contact,
                        type: csatContactType[0]
                    };
                    this.csatContacts.push(csatContact);
                }
                else {
                    nonCsatRoles.push(role);
                }
            }
            if (nonCsatRoles && nonCsatRoles.length > 0) {
                const nonCsatContact = { ...contact };
                nonCsatContact.roles = nonCsatRoles;
                nonCsatContact.language = csatContactLanguage;
                this.contactsWithNonCsatRoles.push(nonCsatContact);
            }
        }

        if (csatContactCount < this.maxCsatContacts) {
            this.isAddCsatValid = true;
        }
        else {
            this.isAddCsatValid = false;
        }

        this.csatContacts.sort((left, right) => left.type.description > right.type.description ? 1 : -1);
        this.contactsWithNonCsatRoles = this.contactsService.updateContactsTypeAndPriority(this.contactsWithNonCsatRoles);
        this.sharedFunctionsService.sortListByPropertyBasedOnOrder("priority", true, this.contactsWithNonCsatRoles, "name");
    }

    /**
     * Gets filtered CSAT contact types to display in the dropdown menu
     * @returns array of ICsatContactType
     */
    private getFilteredCsatContactTypes(): ICsatContactType[] {
        let filteredCsatContactTypes: ICsatContactType[];
        filteredCsatContactTypes = this.validCsatContactTypes;
        if (this.csatContacts) {
            this.csatContacts.forEach((csatContact) => {
                filteredCsatContactTypes = filteredCsatContactTypes.filter((x) => x.code !== csatContact.type.code);
            });
        }
        return filteredCsatContactTypes;
    }
}
