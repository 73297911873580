import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { StaffingService } from "../../../../common/services/staffing.service";
import { getInitialWbsStructures } from "../../../../store/wbs-structures/wbs-structures.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IWbsStructuresState } from "../../../../store/wbs-structures/wbs-structures.reducer";
import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { IWbsStructure, IServiceStructure, IProject } from "../../../../common/services/contracts/wbsStructures.contracts";
import { getDemandsState } from "../../../../store/demands/demands.selector";
import { getEntireResourceRequestsProjectContextStateObject } from "../../../../store/resource-requests-project-context/resource-requests-project-context.selector";
import { IResourceRequestsDetailsProjectState } from "../../../../store/resource-requests-project-context/resource-requests-project-context.reducer";
import { IDemandsState } from "../../../../store/demands/demands.reducer";
import { IWbsDemand } from "../../../../common/services/contracts/project.service.v2.contracts";
import { IStaffingViewModel, IStaffingTaskModel, IStaffingDemandModel, IStaffingResource } from "../../../../common/services/contracts/staffing-details.contract";
import { IResourceRequestResponse } from "../../../../common/services/contracts/staffing.service.contract";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import moment from "moment";

@Component({
    selector: "dm-wbs-resource-requests",
    templateUrl: "./wbs-resource-requests.html",
    styleUrls: ["./wbs-resource-requests.scss"]
})
export class WbsResourceRequestsComponent extends DmModalAbstract {
    @Input() public wbsId: string;
    @Input() public startDate: string;
    @Input() public endDate: string;

    public engagementStructureDetails: IWbsStructure;
    public projectStructureDetails: IProject;
    public grmRequestList: IResourceRequestResponse;
    public isResourceRequestsLoading: boolean;
    public disableCommands: boolean = true;
    public showRequestRoleMaintenance: boolean = false;
    public showTruncate: boolean = false;
    public rolesViewModelRequests: IStaffingViewModel[] = [];
    public selectedDemandList: any = []; // todo type
    public showUSPubSecRMActions: boolean;
    public isProjectContext: boolean;
    public isResourcesLoading: boolean = true;
    public conflictResourceRequestStatus: string[];
    private queuedProjects: Array<{ projectId: string; isDataLoaded?: boolean }> = [];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(activeModal, dmLogger, Components.WbsResourceRequests);
    }

    public ngOnInit(): void {
        const projectIdRegex = /^[a-zA-Z]{1}\.[0-9]{10}\.[0-9]{6}$/;
        this.isProjectContext = this.wbsId.match(projectIdRegex) ? true : false;
        this.selectedDemandList = [];
        this.grmRequestList = {
            ProjectRequests: []
        };
        this.conflictResourceRequestStatus = this.configManagerService.getValue<string[]>("conflictResourceStatus");
        this.getRolesViewModelRequests();
    }

    /**
     * This method gets the roles view model requests
     * Most of the methods are from staffing component and been used here with some changes as we don't need some attributes and as it cannot be used as is
     * @memberof WbsResourceRequestsComponent
     */
    public getRolesViewModelRequests(): void {
        this.storeDispatchService.requireWbsStructures(this.wbsId, true).load();
        let initialSetOfProjects: string[] = [];
        const engagementStructureDetails$ = this.store.select(getInitialWbsStructures(this.wbsId));
        engagementStructureDetails$.pipe(untilDestroyed(this)).subscribe((engagementStructureDetails: IWbsStructuresState) => {
            if (engagementStructureDetails.loaded) {
                if (this.isProjectContext) {
                    // In project context just send a single project id to get resource requests, roles etc.
                    this.projectStructureDetails = engagementStructureDetails.wbsStructures as IProject;
                    this.dispatchActionsForSetOfProjects([this.wbsId]);
                } else {
                    this.engagementStructureDetails = engagementStructureDetails.wbsStructures as IWbsStructure;
                    // Get the inital data of first 3 projects and then queue other projects as in staffing component.
                    if (this.engagementStructureDetails.statusCode !== "CREATED") {
                        if (this.engagementStructureDetails.projects.length <= 3) {
                            // this.addToStaffingModel(this.engagementStructureDetails.projects);
                            initialSetOfProjects = this.engagementStructureDetails.projects.map((value) => value.id);
                        } else {
                            const assignedProjects = this.engagementStructureDetails.projects.filter((project) => project.isAssigned).sort(this.staffingService.sortEntitiesByDateDifference());
                            const unassignedProjects = this.engagementStructureDetails.projects.filter((project) => !project.isAssigned).sort(this.staffingService.sortEntitiesByDateDifference());
                            const projectDetails = assignedProjects.concat(unassignedProjects);
                            // this.addToStaffingModel(projectDetails);
                            // Get the top 3 projects to load on to the request grid
                            initialSetOfProjects = projectDetails
                                .map((value) => value.id)
                                .slice(0, 3);

                            // Get the rest of the projects to lazy load request grid.
                            const projectsToQueue = projectDetails.filter((project) => initialSetOfProjects.indexOf(project.id) === -1);
                            if (projectsToQueue.length > 0) {
                                for (const data of projectsToQueue) {
                                    this.queuedProjects.push({
                                        projectId: data.id,
                                        isDataLoaded: false
                                    });
                                }
                            }
                        }
                        if (initialSetOfProjects.length === 0) {
                            this.dispatchActionsForSetOfProjects(this.queuedProjects.map((val) => val.projectId).slice(0, 3));
                        } else {
                            this.dispatchActionsForSetOfProjects(initialSetOfProjects);
                        }
                    }
                }
                this.showUSPubSecRMActions = this.engagementStructureDetails && this.engagementStructureDetails.isPublicSector && this.engagementStructureDetails.publicSectorCode === "001";
            }
        });
    }

    /**
     * Get selectedDemand from event Emitter as output data
     *
     * @param {ISelectedDemand} selectedDemand
     * @memberof StaffingComponent
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public onDemandChange(event: any, projectId: string, demandId: string, grmRequestId: number, changedDemand: IStaffingDemandModel, changedTask: IStaffingTaskModel): void {
        const selectedDemand = {
            isChecked: event.target.checked,
            projectId,
            demandId,
            grmRequestId
        };
        if (selectedDemand && selectedDemand.isChecked) {
            const filteredProjectId = this.isProjectContext ? this.projectStructureDetails.id : this.engagementStructureDetails.projects.filter((project) => project.id === selectedDemand.projectId)[0].id;
            if (this.grmRequestList.ProjectRequests && this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === selectedDemand.projectId).length > 0) {
                const projectRequests = this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === selectedDemand.projectId)[0];
                if (projectRequests.ResourceRequests && projectRequests.ResourceRequests.length > 0) {
                    const resourceRequests = projectRequests.ResourceRequests.filter((resourceDetails) => resourceDetails.DemandResourceRequest && resourceDetails.DemandResourceRequest.DemandId === selectedDemand.demandId);
                    if (resourceRequests && resourceRequests.filter((resource) => resource.ResourceRequestId === selectedDemand.grmRequestId).length > 0) {
                        const resourceDetails = resourceRequests.filter((resource) => resource.ResourceRequestId === selectedDemand.grmRequestId)[0];
                        const demandDetail = {
                            demandId: changedDemand.demandId,
                            roleDescription: changedDemand.demandRole,
                            plannedDuration: changedDemand.demandPlannedQuantity,
                            startDate: changedTask.projectStartDate,
                            endDate: changedTask.projectEnddate
                        };
                        this.selectedDemandList.push(
                            {
                                persona: this.staffingService.hasPermissionsForEditActions(filteredProjectId, this.engagementStructureDetails, this.projectStructureDetails, this.isProjectContext) ? "Requestor" : "NonRequestor",
                                assignmentId: resourceDetails.ResourceRequestId,
                                demandSourceType: "Delivery",
                                assignmentStatus: resourceDetails.ResourceRequestStatusEnum,
                                assignmentSubStatus: resourceDetails.ResourceRequestSubStatusEnum,
                                assignmentStartDate: resourceDetails.ScheduledStartDate ? resourceDetails.ScheduledStartDate : resourceDetails.RequestedStartDate,
                                assignmentEndDate: resourceDetails.ScheduledEndDate ? resourceDetails.ScheduledEndDate : resourceDetails.RequestedEndDate,
                                resourceInformation: resourceDetails,
                                demandDetails: demandDetail, // this.retrieveDemandDetailsForSplit(selectedDemand.demandId),
                                grmProjectStatus: projectRequests.IsDraft,
                                resourcesUnderDemand: resourceRequests,
                                isGRMProjectInDraft: projectRequests.IsDraft,
                                projectIdSelected: filteredProjectId,
                                isInternal: false,
                                showUSPubsecRMActions: this.showUSPubSecRMActions
                            }
                        );
                    }
                }
            }
        } else {
            this.selectedDemandList = this.selectedDemandList.filter((demand) => demand.assignmentId !== selectedDemand.grmRequestId);
        }
        if (this.selectedDemandList && this.selectedDemandList.length) {
            this.disableCommands = false;
        }
    }

    /**
     * Hides command action tabs such as truncate, role maintenance.
     *
     * @memberof WbsResourceRequestsComponent
     */
    public hideCommandActionTabs(): void {
        this.showTruncate = false;
        this.showRequestRoleMaintenance = false;
    }

    /**
     * Toggles between tabs on click 
     *
     * @param {string} tab
     * @memberof WbsResourceRequestsComponent
     */
    public toggleTabs(tab: string): void {
        if (tab === "truncate") {
            this.showTruncate = true;
            this.showRequestRoleMaintenance = false;
        } else if (tab === "role-maintenance") {
            this.showTruncate = false;
            this.showRequestRoleMaintenance = true;
        }
    }

    /**
     * Triggers load actions for given set of project ids and start building the model once the response is recieved
     *
     * @private
     * @param {string[]} inputProjectIds
     * @memberof WbsResourceRequestsComponent
     */
    private dispatchActionsForSetOfProjects(inputProjectIds: string[]): void {
        inputProjectIds.forEach((projectId, index) => {
            this.storeDispatchService.requireWbsDemandsV2WithoutSchedules(projectId, true).load();
            this.storeDispatchService.requireProjectStaffingDetails(projectId, true).load();
            const wbsDemandDetails$ = this.store.select(getDemandsState(projectId));
            const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsProjectContextStateObject(projectId));
            observableCombineLatest(
                resourceRequestDetail$,
                wbsDemandDetails$,
                (
                    resourceRequestDetail: IResourceRequestsDetailsProjectState,
                    wbsDemandDetails: IDemandsState,
                ) => ({
                    resourceRequestDetail,
                    wbsDemandDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    resourceRequestDetail,
                    wbsDemandDetails,
                }) => {
                    if (resourceRequestDetail.loaded && wbsDemandDetails.loaded) {
                        if (resourceRequestDetail.grmSearchApiResponse && resourceRequestDetail.grmSearchApiResponse.ProjectRequests && resourceRequestDetail.grmSearchApiResponse.ProjectRequests.length) {
                            this.grmRequestList.ProjectRequests = this.grmRequestList.ProjectRequests.concat(resourceRequestDetail.grmSearchApiResponse.ProjectRequests);
                        }
                        wbsDemandDetails.wbsDemands.wbsId = projectId;
                        this.loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails.wbsDemands, resourceRequestDetail.grmSearchApiResponse);
                        if (this.queuedProjects.length && this.queuedProjects.filter((proj) => proj.projectId === projectId).length > 0) {
                            this.queuedProjects.filter((proj) => proj.projectId === projectId)[0].isDataLoaded = true;
                        }
                        if (index === inputProjectIds.length - 1) {
                            if (this.queuedProjects.length > 0 &&
                                (this.queuedProjects.filter((project) => project.isDataLoaded).length !== this.queuedProjects.length)) {
                                const projectsTobeDispatched = this.queuedProjects.filter((project) => !project.isDataLoaded).map((val) => val.projectId).slice(0, 3);
                                if (projectsTobeDispatched && projectsTobeDispatched.length > 0) {
                                    this.dispatchActionsForSetOfProjects(projectsTobeDispatched);
                                }
                            }
                        }
                        this.isResourcesLoading = false;
                    }
                });
        });
    }


    /**
     * Builds the model/grid based on the demanddetails and grmresponse
     *
     * @private
     * @param {IWbsDemand} wbsDemandDetails
     * @param {IResourceRequestResponse} grmResponse
     * @memberof WbsResourceRequestsComponent
     */
    private loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails: IWbsDemand, grmResponse: IResourceRequestResponse): void {
        const filteredProjectStructureDetails: IProject[] = this.isProjectContext ? [this.projectStructureDetails] : this.engagementStructureDetails.projects.filter((project) => project.id === wbsDemandDetails.wbsId);
        const staffingExclusionWbsL3Types = this.configManagerService.getValue<string[]>("StaffingExclusionWbsL3Types");
        if (filteredProjectStructureDetails && filteredProjectStructureDetails.length > 0) {
            filteredProjectStructureDetails[0].services.forEach((serviceDetail: IServiceStructure) => {
                if (serviceDetail && serviceDetail.tasks && serviceDetail.tasks.length) {
                    for (const task of serviceDetail.tasks) {
                        if (staffingExclusionWbsL3Types.indexOf(task.workPackageType) === -1) {
                            const staffingTaskViewModel: IStaffingTaskModel = {
                                taskEngagementName: filteredProjectStructureDetails[0].engagementId,
                                taskProjectName: filteredProjectStructureDetails[0].name,
                                taskProjectId: filteredProjectStructureDetails[0].id,
                                projectStartDate: filteredProjectStructureDetails[0].startDate,
                                projectEnddate: filteredProjectStructureDetails[0].endDate,
                                taskCompassoneId: filteredProjectStructureDetails[0].compassOnePackageId,
                                taskServiceName: serviceDetail.name,
                                taskServiceId: serviceDetail.id,
                                taskName: task.name,
                                taskId: task.id,
                                taskTypeCode: task.workPackageType,
                                currency: filteredProjectStructureDetails[0].currency,
                                taskCompassPackageId: filteredProjectStructureDetails[0].compassOnePackageId,
                                isDemandsExpanded: true,
                                demands: this.staffingService.getDemandDetailsBasedOnWbsDemands(task, wbsDemandDetails, grmResponse, filteredProjectStructureDetails[0].id, this.engagementStructureDetails, this.projectStructureDetails, this.isProjectContext)
                            };
                            if (staffingTaskViewModel.demands.length > 0) {
                                staffingTaskViewModel.demands.forEach((demand, demandIndex) => {
                                    demand.resources.forEach((resource) => {
                                        let filteredDemands = demand.resources.filter(((item: IStaffingResource) => item.resourceStartDate && this.startDate && (moment(item.resourceStartDate).isBefore(this.startDate, "day") && this.conflictResourceRequestStatus.indexOf(item.resourceStatus) >= 0)));
                                        if (filteredDemands.length === 0) {
                                            filteredDemands = demand.resources.filter(((item: IStaffingResource) => item.resourceEndDate && this.endDate && (moment(item.resourceEndDate).isAfter(this.endDate, "day") && this.conflictResourceRequestStatus.indexOf(item.resourceStatus) >= 0)));
                                        }
                                        if (this.conflictResourceRequestStatus.indexOf(resource.resourceStatus) >= 0) {
                                            staffingTaskViewModel.demands[demandIndex].resources = filteredDemands;
                                            this.addToStaffingViewModel(staffingTaskViewModel);
                                        }
                                    });
                                });
                            }
                        }
                    }
                }
            });
        }
    }


    /**
     * Add the taskModel to the View Model object containing the project details , so the grid data can be iterated based on the view model.
     *
     * @private
     * @param {IStaffingTaskModel} staffingTaskViewModel
     * @memberof WbsResourceRequestsComponent
     */
    private addToStaffingViewModel(staffingTaskViewModel: IStaffingTaskModel): void {
        const filteredViewModel = this.rolesViewModelRequests.filter((entity) => entity.taskProjectId === staffingTaskViewModel.taskProjectId);
        if (filteredViewModel && filteredViewModel.length) {
            filteredViewModel[0].taskModelDetails = filteredViewModel[0].taskModelDetails.filter((taskDetails) => taskDetails.taskId !== staffingTaskViewModel.taskId);
            filteredViewModel[0].taskModelDetails.push(staffingTaskViewModel);
        } else {
            const rolesViewModelRequest: IStaffingViewModel = {
                taskProjectName: staffingTaskViewModel.taskProjectName,
                taskProjectId: staffingTaskViewModel.taskProjectId,
                projectStartDate: staffingTaskViewModel.projectStartDate,
                projectEndDate: staffingTaskViewModel.projectEnddate,
                isTasksExpanded: false,
                taskModelDetails: []
            };

            rolesViewModelRequest.taskModelDetails.push(staffingTaskViewModel);
            this.rolesViewModelRequests.push(rolesViewModelRequest);
        }
    }

}
