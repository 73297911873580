import { UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { FinancialService } from "../../../common/services/financial.service";
import { AssociatedInternalEngagementsModalComponent } from "./internal-associated-engagements-v2.component";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DmUserProfileModule } from "../../../components/shared/user-name/user-name.module";
import { DmModalV2Module } from "../../modals/dm-modal-v2/dm-modal-v2.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        AssociatedInternalEngagementsModalComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule,
        DmUserProfileModule,
        NgbModule,
        DmModalV2Module
    ],
    providers: [
        DMLoggerService,
        FinancialService,
        ProjectService,
        SharedFunctionsService,
    ],
    entryComponents: [
        AssociatedInternalEngagementsModalComponent
    ],
    exports: [
        AssociatedInternalEngagementsModalComponent
    ],
})
export class AssociatedInternalEngagementsModalModule { }
