import { Component, Input } from "@angular/core";

@Component({
    selector: "dm-loading",
    templateUrl: "./dm-loading.html",
    styleUrls: ["./dm-loading.scss"]
})
export class DmLoadingComponent {

    @Input() public loadingText: string = "";
    @Input() public errorText: string;
    @Input() public showLoading: boolean;
    @Input() public isCustomText: boolean = false;
    @Input() public isCircularLoader: boolean = true;
}
