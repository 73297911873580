<section class="copy-resource-request-dailog">
    <form #copyResourceForm="ngForm" name="copyResourceForm" class="copyResourceForm">
        <label class="sr-only">Copy Resource Request</label>
        <section class="modal-header">
            <button type="button" class="close set-focus" (click)="closeModal()" id="closePopUp"
                aria-label="Copy Resource Request Dialog close"
                (keydown.Tab)="moveFocusNext($event, 'dm-resources-ddl', 'dm-resources-ddl')"
                (keydown.shift.Tab)="moveFocusPrev($event, 'submitRr', 'cancelRequest')">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
            <h4 class="modal-title pull-left">
                Copy Resource Request
            </h4>
        </section>
        <dm-loading [loadingText]="loadingText" [isCustomText]="true" [errorText]="errorSummaryText"
            [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>
        <section class="popUpModalDialog">
            <section *ngIf="!isLoading" class="modal-body">
                <div class="copy-request-grid">
                    <div class="copy-request-grid-row">
                        <div class="copy-request-header">
                            <span>Copy </span>
                            <div class="copyFromResourceRequest">
                                <span>Resource ID:
                                    <span
                                        class="copyFromResourceRequest__id">{{copyFromResourceRequest?.ResourceRequestId}}</span>
                                </span>
                                <span class="copyFromResourceRequest__name">{{copyFromResourceRequest?.Role}}</span>
                            </div>
                            <span> to</span>
                        </div>

                        <section class="input__select--icon resource-container">
                            <dm-dropdown-menu [id]="'dm-resources-ddl'" [ariaLabel]="'Resource Id'"
                                [options]="draftResourceRequestIds" [(value)]="selectedResourceRequestId"
                                (change)="onSelectedResourceChanged(selectedResourceRequestId)" [labels]="'id'"
                                [isDropDownV2]="true">
                            </dm-dropdown-menu>
                        </section>
                        <div>
                            <label for="role">
                                Role
                                <dm-tooltip *ngIf="isAutoCommitRole" role="tooltip" class="dm-tooltipinline"
                                    [dmPlacement]="'right'" [ngbTooltipContent]="autoCommitRoleToolTipText"
                                    [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="AutoCommit Role">
                                </dm-tooltip>
                            </label>
                            <section class="input__select--icon">
                                <dm-dropdown-menu [id]="'dm-roles-ddl'" [ariaLabel]="'Resource Id'" [options]="roleIds"
                                    [(value)]="selectedRole" [labels]="'id'" (change)="onSelectedRoleChange()"
                                    [isDropDownV2]="true">
                                </dm-dropdown-menu>
                            </section>
                        </div>
                        <div class="copy-request-grid-column-width">
                            <label for="resourceName">
                                Resource Name
                                <span class="required-mark" *ngIf="isAutoCommitRole" aria-hidden="true">*</span>
                            </label>
                            <dm-type-ahead [typeAheadId]="'ResourceName'" [btnSearchAriaLabelText]="taSearchAriaLblTxt"
                                [btnCancelAriaLabelText]="taCancelAriaLblTxt"
                                [typeAheadUserValidationRequiredMessage]="" [noResults]="noResults" [disabled]="false"
                                [selectedUser]="taRequestedResource" [isRequired]="isAutoCommitRole"
                                [isMultiselect]="false" [fteAndContingentStaff]="true" [typeaheadMinLength]="3"
                                [ignoredExistingManagers]="false" [modelValue]="taRequestedResource"
                                (selectedUserUpdated)="setSelectedUser($event)" (onUserCleared)="onUserCleared()">
                            </dm-type-ahead>
                            <div class="font-caption-alt inp-validation" *ngIf="showMessageForRequestedResource">
                                {{requestedResourceMessage}}</div>
                        </div>
                        <div class="copy-request-grid-column-width">
                            <label for="requestedHrs">
                                Requested
                            </label>
                            <div>
                                <input type="number" id="requestedHours" min="1" name="requestedHours"
                                    class="requested-hours set-focus" (change)="onRequestedHoursChange()"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    [(ngModel)]="requestedHours" placeholder="Requested Labor" required />
                                Hours
                            </div>
                            <div *ngIf="isPlannedHoursOfDemandExceed" class="font-caption-alt inp-validation">
                                Requested hours cannot exceed planned hours left ({{plannedHoursLeftUnderDemand}}) of
                                the demand.</div>
                            <div *ngIf="requestedHours <= 0" class="font-caption-alt inp-validation">
                                {{invalidHoursEnteredMessage}}</div>
                        </div>
                        <div class="dm-datepicker dm-p-l-imp-0 copy-request-grid-column-width">
                            <label for="requestStartDate">
                                Start Date
                            </label>
                            <dm-datepicker id="startDate" name="requestStartDate" [minDate]="minStartDate"
                                [disabled]="!selectedResourceRequest" [maxDate]="maxEndDate"
                                [modelDate]="requestStartDate" ariaLabelButton="Start date" placeholder="dd-mmm-yyyy"
                                ariaLabelCalendar="Start date" [showErrorBorder]="false"
                                (onDateChange)="onStartDateChange($event)" previosFousingElementId="requestedHours"
                                [isDatePickerV2]="true">
                            </dm-datepicker>
                            <div class="font-caption-alt inp-validation" *ngIf="showStartDateRequiredMessage">
                                {{startDateRequiredMessage}}</div>
                            <div class="font-caption-alt inp-validation" *ngIf="showEndDateEarlierMessage">
                                {{endDateEarlierMessage}}</div>
                        </div>
                        <div class="dm-datepicker dm-p-imp-0 copy-request-grid-column-width">
                            <label for="requestEndDate">
                                End Date
                            </label>
                            <dm-datepicker id="endDate" name="requestEndDate" [modelDate]="requestEndDate"
                                [disabled]="!selectedResourceRequest" [minDate]="minStartDate" [maxDate]="maxEndDate"
                                placeholder="dd-mmm-yyyy" ariaLabelButton="End date" ariaLabelCalendar="End date"
                                (onDateChange)="onEndDateChange($event)"
                                previosFousingElementId="calenderBtn_Start date" [showErrorBorder]="false"
                                [isDatePickerV2]="true">
                            </dm-datepicker>
                            <div class="font-caption-alt inp-validation" *ngIf="showEndDateRequiredMessage">
                                {{endDateRequiredMessage}}</div>
                        </div>
                        <span></span>
                        <!-- <div>
                            <label for="domain">
                                Domain
                            </label>
                            <dm-dropdown-menu [id]="'dm-domain-ddl'" [ariaLabel]="'Resource Id'"
                                [options]="customerEngagementDomains" [(value)]="selectedDomain"
                                [labels]="'ServiceDeliveryOrganizationName'" (change)="onSelectedDomainChanged()"
                                [isDropDownV2]="true">
                            </dm-dropdown-menu>
                        </div> -->
                        <section class="skills-container">
                            <label for="taSkills">Skills
                                <span class="font-label">
                                    (10 maximum)
                                </span>
                                <span class="required-mark" *ngIf="isSkillsRequired" aria-hidden="true">*</span>
                            </label>
                            <dm-type-ahead-skills-v2 [typeAheadId]="'taSkills'"
                                [btnSearchAriaLabelText]="taSkillsSearchAriaLblTxt"
                                [typeAheadLabelText]="taSkillsLabelText"
                                [btnCancelAriaLabelText]="taSkillsCancelAriaLblTxt"
                                [typeAheadUserValidationRequiredMessage]="taSkillsRequiredMsg"
                                [selectedSkills]="taRoleSkills" [isRequired]="isSkillsRequired" [isMultiselect]="true"
                                [maxSkillsAllowToSelect]="10" [fteOnly]="false" [modelValue]="taSkillsNoResultsModel"
                                [domain]="selectedDomain" (skillValidityUpdated)="updateSkillsValidity($event)">
                            </dm-type-ahead-skills-v2>
                        </section>
                    </div>
                    <div class="schedule-container" *ngIf="weeklySchedules && weeklySchedules.length">
                        <label for="schedule">Schedule</label>
                        <dm-schedule *ngIf="weeklySchedules && weeklySchedules.length"
                            [weeklyScheduleData]="weeklySchedules" (onHourChange)="onWeeklySchedulesUpdated($event)">
                        </dm-schedule>
                        <div class="dm-p-l-20 font-caption-alt total-hours">Total {{scheduleDurationHours}} of
                            {{requestedHours}}</div>
                        <div class="dm-p-l-20 dm-p-t-10 font-caption-alt inp-validation"
                            *ngIf="scheduleDurationHours !== requestedHours">Proposed Hours and Duration are not equal.
                            Please adjust the Proposed Hours to match the Duration.</div>
                    </div>
                    <div class="copy-request-grid-row">
                        <div class="delivery-type">
                            <label for="deliveryType">
                                Delivery Type
                            </label>
                            <dm-dropdown-menu [id]="'dm-delivery-type-ddl'" [ariaLabel]="'Delivery Type'"
                                [isDisabled]="false" [(value)]="selectedDeliveryType" [options]="deliveryType"
                                [labels]="'name'" [isDropDownV2]="true">
                            </dm-dropdown-menu>
                        </div>
                        <div class="delivery-loaction">
                            <label for="deliveryLoaction">
                                Delivery Location
                            </label>
                            <dm-type-ahead-city-v2 [typeAheadId]="'cityName'"
                                [btnSearchAriaLabelText]="citySearchAriaLblTxt" [typeAheadLabelText]=""
                                [btnCancelAriaLabelText]="cityCancelAriaLblTxt" [noResults]="noResults"
                                [selectedCityText]="selectedCity" (selectedCityUpdated)="onLocationChange($event)"
                                [isRequired]="true" [typeaheadMinLength]="3" [isDisabled]="false"
                                [modelValue]="selectedLocation">
                            </dm-type-ahead-city-v2>
                            <div *ngIf="showDeliveryLocationRequiredMessage" class="font-caption-alt inp-validation">
                                {{deliveryLocationRequiredMessage}}</div>
                        </div>
                        <span></span>
                        <div class="additional-notes-grid-column">
                            <label for="additionalDetails">Additional Details & Travel Notes (optional)</label>
                            <textarea name="additionalDetails" id="additionalDetails"
                                class="dm-textarea copy-request-grid-column-width" [(ngModel)]="additionalDetails"
                                aria-label="Additional Details"></textarea>
                        </div>
                        <div>
                            <label for="approveRequest">Approve Resource Requests</label>
                            <dm-slider [sliderId]="'approveResourceRequest'" [popoverText]="Yes" [labelInformation]="'Addition Resource Request'"
                                [isToggleChecked]="isRequestApproved" [unCheckedLabel]="'No'" [checkedLabel]="'Yes'"
                                [attr.aria-label]="isRequestApproved" [isLabelClickable]="false"
                                [isDisabled]="isPreApprovedRequestDisabled"
                                (onToggleChange)="toggleRequestApproved($event)">
                            </dm-slider>
                            <label for="clearance" class="slider-label-clearance">Security Clearance Required</label>
                            <dm-slider [sliderId]="'clearance'" [popoverText]="Yes"
                                [isToggleChecked]="isClearanceRequired" [unCheckedLabel]="'No'" [checkedLabel]="'Yes'" [labelInformation]="'Security Clearance Required'"
                                [attr.aria-label]="isClearanceRequired" [isDisabled]="isSecurityClearanceDisabled"
                                [isLabelClickable]="false" (onToggleChange)="toggleClearanceRequired($event)"
                                (keydown.Tab)="selectedResourceRequest ? moveFocusNext($event, 'resourceManagementLink', 'resourceManagementLink') : moveFocusNext($event, 'cancelRequest', 'cancelRequest')">
                            </dm-slider>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedResourceRequest" class="information-section">
                    <span class="icon icon-info dm-p-r-5"></span>
                    <span>If you want to edit more details go to <a tabindex="0" id="resourceManagementLink"
                            (keydown.Tab)="moveFocusNext($event, 'cancelRequest', 'cancelRequest')"
                            (click)="saveDraftandRedirect()" class="dm-link">Resource management</a></span>
                </div>
            </section>
        </section>
        <section class="modal-footer" [ngClass]="{'tab-footer': !isModalComponent}" *ngIf="!isLoading">
            <button type="button" class="dm-btn fxp-btn fxp-btn--primary" id="submitRr" [disabled]="isSubmitDisabled()"
                (click)="submit()" (keydown.Tab)="moveFocusNext($event, 'closePopUp', 'closePopUp')"
                (keydown.shift.Tab)="moveFocusPrev($event, 'cancelRequest', 'cancelRequest')">
                Submit
            </button>
            <button tabindex="0" type="button" class="dm-btn fxp-btn fxp-btn--secondary" id="cancelRequest"
                (click)="closeModal()" (keydown.Tab)="moveFocusNext($event, 'submitRr', 'closePopUp')"
                (keydown.shift.Tab)="selectedResourceRequest ? moveFocusPrev($event, 'resourceManagementLink', 'resourceManagementLink') : moveFocusPrev($event, 'slider-clearance', 'slider-clearance')">Cancel</button>
        </section>
    </form>
</section>