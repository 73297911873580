import { Component, Input, Output, EventEmitter, Inject, AfterViewInit, ViewChild, ElementRef, Renderer2, forwardRef } from "@angular/core";
import { debounceTime, tap, distinctUntilChanged, switchMap, catchError } from "rxjs/operators";
import { IOneProfileCompanyCodeAttr } from "./type-ahead-companycode-contracts";
import { Observable } from "rxjs";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { DataService } from "../../../common/services/data.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ComponentPrefix, SourceConstants } from "../../../common/application.constants";
import { ErrorSeverityLevel } from "@fxp/fxpservices";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-type-ahead-companycode",
    templateUrl: "./type-ahead-companycode.html",
    styleUrls: ["./type-ahead-companycode.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypeAheadCompanyCodeComponent),
            multi: true
        }
    ]
})
export class TypeAheadCompanyCodeComponent implements AfterViewInit, ControlValueAccessor {

    @Input() public btnSearchAriaLabelText: string;
    @Input() public btnCancelAriaLabelText: string;
    @Input() public typeAheadId: string;
    @Input() public typeAheadLabelText: string;
    @Input() public typeAheadUserValidationRequiredMessage: string;
    @Input("selectedUser") public selectedCompany: IOneProfileCompanyCodeAttr; // todo we can change this name to selectedCompany
    
    @Input() public isDisabled: boolean;
    @Input() public isRequired: boolean;
    @Input() public typeAheadPlaceholder: string;
    @Input() public typeaheadMinLength: number;
    @Output() public selectedCompanyCodeUpdated: EventEmitter<IOneProfileCompanyCodeAttr> = new EventEmitter();
    @Output() public setDefaultCurrencyCode: EventEmitter<any> = new EventEmitter();
    
    @ViewChild("typeAheadSearchCompanyCode", { static: false }) public searchInputText: ElementRef;
    @Input() public get value(): any { /* Used in the view for this file, fetches the modelValue and ensures it's updated */
        return this.modelValue;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public set value(val: any) {
        if (val) {
            this.modelValue = val;
        }

        this.onChange(this.modelValue);
        this.onTouched(this.modelValue);
    }
    public modelValue: IOneProfileCompanyCodeAttr;
    
    public searchFailed: boolean;
    public searching: boolean;
    public errorMessage: string;
    public onChange: (...args: any[]) => void;
    public onTouched: (...args: any[]) => void;
    

    public constructor(
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(Renderer2) private renderer: Renderer2,
        @Inject(DMLoggerService) private dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) { }

    public ngAfterViewInit(): void {
        if (this.searchInputText !== undefined && this.searchInputText !== null) {
            this.renderer.removeAttribute(this.searchInputText.nativeElement, "aria-multiline");
        }
    }

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }
    
    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * This is a basic setter that the forms API is going to use
     *
     * @param {*} companyCodeFormvalue
     * @memberof TypeAheadCompanyCodeComponent
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public writeValue(companyCodeFormvalue: any): void {
        if (companyCodeFormvalue !== null && companyCodeFormvalue !== undefined) {
            this.modelValue = {
                CompanyCode: companyCodeFormvalue.CompanyCode,
                CompanyName: companyCodeFormvalue.CompanyName,
                CountryCode: companyCodeFormvalue.CountryCode,
                CurrencyCode: companyCodeFormvalue.CurrencyCode
            };
        }
    }

    /**
     * To clearing the input filed on clicking clear icon
     */
    public clearText(): void {
        this.modelValue = null;
        this.pullFocusToElement(this.typeAheadId, true);
        this.validateInput();
        this.setDefaultCurrencyCode.emit("USD");
    }

    /**
     * Validating input
     */
    public validateInput(): void { // Called on every input, including inputs less than 3 chars
        this.errorMessage = this.getErrorMessage(this.modelValue ? this.modelValue.CompanyName : "");
    }

    /**
     * Search list on typing input
     * @param text$
     */
    public search = (text$: Observable<string>): Observable<any> =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term) => term.length < 3 ? [] :
                this.getCompanyCodeList(term)
            ),
            tap(() => this.searching = false)
        );

    /**
     * getCompanyCodeList
     * @param searchValue
     */
    public getCompanyCodeList(searchValue: string): Observable<IOneProfileCompanyCodeAttr[]> {
        this.searching = true;
        if (this.selectedCompany) {
            for (const key of Object.keys(this.selectedCompany)) {
                this.selectedCompany[key] = null;
            }
        }

        if (!this.getErrorMessage(searchValue)) {
            return this.oneProfileService.searchCompanyCodesList(searchValue).pipe(
                tap(() => { this.searchFailed = false; this.setHasResults(); }),
                catchError((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.dmLogger.logError(ComponentPrefix + "Type-Ahead-CompanyCode", SourceConstants.Method.GetCompanyCodeList, error, errorMessage, null, undefined, DataService.getCorrelationIdFromError(error), ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.searchFailed = true;
                    this.searching = false;
                    this.setNoResults(searchValue);
                    return [];
                }));
        }
    }

    /**
     * Formatting user input on base selection
     * @param x
     */
    public formatter = (x: { CompanyCode: string; CompanyName: string }): string => x.CompanyCode + "-" + x.CompanyName;

    /**
     * Selecting item on company code select
     * @param item
     */
    public onUserSelect(item: {item: IOneProfileCompanyCodeAttr }): void {
        const selectitem = item.item;
        this.modelValue = {
            CompanyCode: selectitem.CompanyCode,
            CompanyName: selectitem.CompanyName,
            CountryCode: selectitem.CountryCode,
            CurrencyCode: selectitem.CurrencyCode
        };
        this.selectedCompanyCodeUpdated.emit(this.modelValue);
    }

    /**
     * Get and Return error message
     * @param value
     */
    private getErrorMessage(value: string): string {
        if (!value) {
            return this.typeAheadUserValidationRequiredMessage;
        }
        return "";
    }

    /**
     * Set no results text
     * @param searchText
     */
    private setNoResults(searchText: string): void {
        this.errorMessage = "No results found for '" + searchText + "'";
    }

    /**
     * Set has results text
     */
    private setHasResults(): void {
        this.errorMessage = "";
    }

    /**
     * Pull focus to search list on data load
     * @param id
     * @param isTimeOut
     */
    private pullFocusToElement(id: string, isTimeOut: boolean): void {
        if (isTimeOut) {
            setTimeout(() => {
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.focus();
                }
            });
        } else {
            const element = window.document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    }
}


