import { Inject, forwardRef, Input, Component, Output, EventEmitter } from "@angular/core";
import { FxpEventBroadCastService, DeviceFactoryProvider, FxpRouteService } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getMyPortfolioEngagementListState } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IEngagementList, IProjectList } from "../../../common/services/contracts/portfolio.model";
import { IMenuViewModel, IMenuItem, IEngagementProjectComboObj } from "../engagement.list.model";
import { IMyPortfolioEngagementListState } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IState } from "../../../store/reducers";
import { LogEventConstants, RouteName, SourceConstants, Components, DelegationTypeText, LogEventName } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
    selector: "dm-drop-down",
    templateUrl: "./drop-down.html",
    styleUrls: ["./drop-down.scss"]
})
export class DropDownComponent extends DmComponentAbstract {
    @Output() public keyNavigation: EventEmitter<any> = new EventEmitter(); // used in html, callback todo
    @Input() public hideDropDown: boolean;
    @Input() public isDropdownLoading: boolean;
    @Input() public showMyPortfolioTabs: boolean;
    public placeholder: string;
    public delegationTypeText = DelegationTypeText;
    public dropDownItems: IMenuViewModel[];
    public selectedState: string = "";
    public isPubSecEnabled: boolean;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public isSmallScreen: boolean;
    public searchText: string = "";
    public highlightText: string;
    public LogEventName = LogEventName;
    public searchListCount: string;
    public menuStatus: string = "";
    private projectList: IProjectList[];
    private engagementList: IEngagementList[];
    private engagementListCopy: IEngagementList[] = [];
    private projectListCopy: IProjectList[] = [];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) private deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
    ) {
        super(dmLogger, Components.DropDown);
    }

    public ngOnInit(): void {
        this.menuStatus = "Press space to toggle the menu. Then press Tab key to move through items. collapsed";
        this.isSmallScreen = this.deviceFactory.isSmallScreen();
        this.placeholder = this.setDropDownPlaceholderText(); /* sets the placeholder on page start */
        this.subscribe(
            this.fxpBroadcastService.On("$stateChangeSuccess", this.routeChanged.bind(this)) /* sets the placeholder on route change */
        );
        this.configurationService.initialize().then(() => {
            this.isPubSecEnabled = this.configurationService.isFeatureEnabled("Pubsec");
        });

        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);

        this.storeDispatchService
            .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
            .load();

        myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {

            // this.refreshOnItemInvalidation(engagementList);
            this.setLoadersBasedOnItemState(engagementList);
            this.setErrorsBasedOnItemState(engagementList);
            if (engagementList.loaded) {

                this.dropDownItems = this.getDropDownItems(engagementList.engagementList, "engagement");
                this.engagementList = engagementList.engagementList;
                this.placeholder = this.setDropDownPlaceholderText();
                const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
                if (engagementId) {
                    const engagement = { engagementId };
                    this.expandMenu(this.dropDownItems, engagement, true);
                }

                this.projectList = [];
                for (const engagement of this.engagementList) {
                    this.projectList = this.projectList.concat(engagement.projects);
                }
                this.engagementListCopy = [...this.engagementList];
                this.projectListCopy = [...this.projectList];
            }
        });
    }

    /**
     * Keyboard input to close the accordian dropdown menu when hitting the tab key, for accessibility
     * @param element
     */
    public closeDropDownOnTab(element: KeyboardEvent): void {
        if (element.keyCode === 39) {
            // this.dropdownOpen = false;
        }
        if (element.keyCode === 27) {
            this.sharedFunctionsService.moveFocus(element, "simple-btn-keyboard-nav", "simple-btn-keyboard-nav");
        }
        // Arrow key navigation for list items(li)
        if (element.keyCode === 40 || element.keyCode === 38 || element.keyCode === 9) {
            const keyDownUpList = Array.prototype.slice.call(document.getElementsByClassName("nxItem"));
            let currentIndex;
            currentIndex = keyDownUpList.indexOf(element.currentTarget);
            if (element.keyCode === 40 && currentIndex < keyDownUpList.length) {
                element.stopPropagation();
                element.preventDefault();
                currentIndex += 1;
            } else if (element.keyCode === 38 && currentIndex > 0) {
                element.stopPropagation();
                element.preventDefault();
                currentIndex -= 1;
            }
            if (element.keyCode === 40 || element.keyCode === 38) {
                window.setTimeout(() => {
                    if (currentIndex === keyDownUpList.length) {
                        keyDownUpList[1].focus();
                    } else {
                        keyDownUpList[currentIndex].focus();
                    }
                }, 0);
            }
        } else if (element.keyCode === 37 || element.keyCode === 39) {
            let currentIndex;
            const keyLeftRightList = Array.prototype.slice.call(document.getElementsByClassName("tabNav"));
            element.stopPropagation();
            element.preventDefault();
            currentIndex = keyLeftRightList.indexOf(element.currentTarget);
            if (element.keyCode === 39 && currentIndex < keyLeftRightList.length) {
                currentIndex += 1;
            } else if (element.keyCode === 37 && currentIndex > 0) {
                currentIndex -= 1;
            }
            window.setTimeout(() => {
                if (currentIndex !== keyLeftRightList.length && currentIndex !== -1) {
                    keyLeftRightList[currentIndex].focus();
                }
            }, 0);
        }
    }

    /**
     * Keyboard input to close the accordian dropdown menu when hitting the tab key, for accessibility
     * @param event
     */
    public toggleDropDown(event: boolean): void {
        if (event) {
            this.menuStatus = "expanded";            
            this.dmLogger.logEvent(SourceConstants.Component.NavigationDropdown, SourceConstants.Method.ToggleDropDown, LogEventName.GlobalNavigationExpand);
        }
        else{
            this.menuStatus = "Press space to toggle the menu. Then press Tab key to move through items. collapsed";
        }
    }

    /**
     * Keyboard input navigation to expand the accordian portion of the dropdown menu, for accessibility
     * @param data
     * @param engagement
     * @param event
     */
    public keyNavigationForAccordion(data: IMenuViewModel[], engagement: {engagementId: string}, event: KeyboardEvent): void {
        if (event.keyCode === 32) {
            event.stopPropagation();
            event.preventDefault();
            this.expandMenu(data, engagement);
        }
    }

    /**
     * Expands the accordian portion of the dropdown menu
     * @param data
     * @param engagement
     * @param fromRoute
     */
    public expandMenu(data: IMenuViewModel[], engagement: {engagementId: string}, fromRoute: boolean = false): void {
        if (!data) {
            return;
        }
        data.forEach((item: IMenuViewModel) => {
            if (item.engagementId !== engagement.engagementId) {
                item.isMenuOpen = false;  // this changes caption property of item
            } else if (fromRoute) { item.isMenuOpen = true; } else {
                item.isMenuOpen = !item.isMenuOpen;
            }
        });
    }

    /**
     * Set selected Engagement name or project name or internal engagement name in drop down after selection
     * todo: not clear if this function is used anymore. Consider deleting.
     * @param pageType
     */
    public setValue(pageType: IMenuViewModel | IMenuItem): void {
        if (this.isSmallScreen) {
            this.hideDropDown = true;
        }
        if (pageType.type === "internal") {
            this.selectedState = (pageType as IMenuViewModel).engagementId;
        } else {
            this.selectedState = (pageType as IMenuItem).id;
        }
        setTimeout(() => {
            if (document.getElementById("simple-btn-keyboard-nav")) {
                document.getElementById("simple-btn-keyboard-nav").focus();
            }
        }, 100);
    }

    /**
     * Indicates the selected type of page has changed to the new value on the menu
     * @param value
     */
    public selectedTypeChanged(value: string): void {
        if (value === "My Portfolio") {
            this.fxpRouteService.navigatetoSpecificState(RouteName.Portfolio);
        }
        if (value === "Search") {
            this.fxpRouteService.navigatetoSpecificState(RouteName.Search);
        }
        this.selectedState = "";
        document.getElementById("simple-btn-keyboard-nav").focus();
    }

    /**
     *  removing the search text and the search results.
     */
    public clearText(): void {
        this.searchText = "";
        this.dropDownItems = this.getDropDownItems(this.engagementListCopy, "engagement");
        this.highlightText = this.searchText;
        this.searchListCount = "search";
        this.sharedFunctionsService.focus("Search", true);
    }

    /**
     * Functions to search engagement and project by the id entered in the search text box.
     */
    public searchEngagementOrProject(): void {
        this.highlightText = this.searchText;
        this.engagementList = [...this.engagementListCopy];
        this.projectList = [...this.projectListCopy];
        if (this.searchText !== "") {
            /**
             * filter the enagagement list against the provided id in the search text box and
             * update the list with the searched result.
             */
            if (this.engagementList && this.engagementList.length) {
                this.engagementList = this.engagementList.filter((engagement) => {
                    return (engagement.engagementId.toLowerCase().includes(this.searchText.toLowerCase()) || engagement.engagementName.toLowerCase().includes(this.searchText.toLowerCase()));
                });
            }

            /**
             * filter the project list against the provided id in the search text box and
             * update the project list with the searched result and
             * enagagement list with the enagagement corresponding to the filtered project.
             */
            if (!this.engagementList.length && this.projectList && this.projectList.length) {
                this.projectList = this.projectList.filter((project) => {
                    return (project.projectId.toLowerCase().includes(this.searchText.toLowerCase()) || project.projectName.toLowerCase().includes(this.searchText.toLowerCase()));
                });
                if (this.projectList.length) {
                    this.engagementList = this.engagementListCopy.filter((engagement) => {
                        return engagement.engagementId.toLowerCase() === this.projectList[0].engagementId.toLowerCase();
                    });
                }
            }
            this.dropDownItems = this.getDropDownItems(this.engagementList, "engagement");
            if (this.dropDownItems && this.dropDownItems.length) {
                this.dropDownItems[0].isMenuOpen = true;
            }
            const propertyBag = {};
            this.searchListCount = this.dropDownItems.length + " results are found";
            document.getElementById("searchResultMsg").innerHTML = this.searchListCount;
            propertyBag[LogEventConstants.SearchText] = this.searchText;
            this.dmLogger.logEvent(SourceConstants.Component.NavigationDropdown, SourceConstants.Method.SearchEngagementOrProject, LogEventConstants.SearchGlobalNavigationDropdown, propertyBag);
        }
    }

    /**
     * trackby function
     *
     * @param {*} index
     * @param {*} engagement
     * @returns
     * @memberof DropDownComponent
     */
    public trackDropDownItemsByEngagementId(index: number, engagement: IMenuViewModel): string {
        return engagement ? engagement.engagementId : null;
    }

    /**
     * trackby function
     *
     * @param {*} index
     * @param {*} entity
     * @returns
     * @memberof DropDownComponent
     */
    public trackEntityById(index: number, entity: IMenuItem): string {
        return entity ? entity.id : null;
    }

    /**
     * trackby function
     *
     * @param {*} index
     * @param {*} enagagementProjectCombo
     * @returns
     * @memberof DropDownComponent
     */
    public trackEnagagementProjectComboById(index: number, enagagementProjectCombo: IEngagementProjectComboObj): string {
        if (enagagementProjectCombo && enagagementProjectCombo.eng && enagagementProjectCombo.eng.length) {
            return enagagementProjectCombo.eng[0].id;
        } else {
            return null;
        }
    }

    /**
     * Activated when the route has changed.
     * When the route changes, change the placeholder text to match with the newly selected route.
     */
    private routeChanged(): void {
        this.placeholder = this.setDropDownPlaceholderText();
        const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const engagement = { engagementId };
        this.expandMenu(this.dropDownItems, engagement, true);
    }

    /**
     * Checks the route and existing information about the route in order to set the placeholder text on the dropdown menu.
     * If the route is for a project/engagement and the list of entities has not loaded yet, the placeholder will say "loading..." and
     * will be reset to the entity name once the entity list has loaded.
     */
    private setDropDownPlaceholderText(): string {
        const routeName: string = this.stateService.current.name;
        if (routeName.startsWith(RouteName.Search)) {
            return "Search";
        }
        if (routeName.startsWith(RouteName.Portfolio)) {
            return "My Portfolio";
        }

        const projId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const engId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

        if (projId) {
            if (!this.engagementList) {
                return "Project Loading...";
            }
            if (this.engagementList) {
                const filteredEng: IEngagementList[] = this.engagementList.filter((x) => x.engagementId === engId);
                if (filteredEng.length && filteredEng[0].projects) {
                    const filteredProj = filteredEng[0].projects.filter((x) => x.projectId === projId);
                    if (filteredProj.length) {
                        this.selectedState = projId;
                        return "(Project) " + filteredProj[0].projectName;
                    }
                }
            }
            return "(Project)";

        }
        if (routeName.startsWith(RouteName.EngagementDetails)) {
            if (engId) {

                if (!this.engagementList) {
                    return "Engagement loading...";
                }
                if (this.engagementList) {
                    const filteredEng: IEngagementList[] = this.engagementList.filter((x) => x.engagementId === engId);
                    if (filteredEng.length) {
                        this.selectedState = engId;
                        return "(Engagement) " + filteredEng[0].engagementName;
                    }
                }
                return "(Engagement)";
            }
        }
        if (routeName.startsWith(RouteName.InternalEngagementDetails)) {
            if (engId) {
                if (!this.engagementList) {
                    return "Internal Engagement loading...";
                }
                if (this.engagementList) {
                    const filteredEng: IEngagementList[] = this.engagementList.filter((x) => x.engagementId === engId);
                    if (filteredEng.length) {
                        this.selectedState = engId;
                        return "(Internal) " + filteredEng[0].engagementName;
                    }
                }

                return "(Internal)";

            }
        }
        return "My Portfolio";

    }

    /**
     * Gets the drop down items by parsing the needed information from the given engagement list.
     * @param engagementList
     * @param type
     */
    private getDropDownItems(engagementList: IEngagementList[], type: string): IMenuViewModel[] {

        const engagementsForDropDown: IMenuViewModel[] = [];
        let totalProjects = 0;
        for (const engagement of engagementList) {
            const projectsData: IEngagementProjectComboObj[] = [];
            const inlineEng: IMenuItem[] = [];
            const inlineProject: IMenuItem[] = [];
            if (type === "engagement" || type === "internal-engagement") { // todo enum
                inlineEng.push({
                    name: "Engagement: " + engagement.engagementName,
                    description: engagement.engagementName, // todo: why are we passing the name as the description? do we use the description at all?
                    type,
                    id: engagement.engagementId,
                    isDelegated: (engagement.delegationAllocationType) ? true : false,
                    delegationType: (engagement.delegationAllocationType) ? engagement.delegationAllocationType : "none",
                    isPinned: (engagement.isPinned) ? true : false,
                    delegationDetails: engagement.delegationDetails,
                    isConfidentialEntity: (engagement.isConfidential) ? true : false,
                    isPublicSector: engagement.isPublicSector,
                    isUsPubSec: engagement.isUsPubSec,
                    delegationData: engagement.delegationData
                });
            }
            if (engagement.projects) {
                for (const project of engagement.projects) {
                    totalProjects++;
                    inlineProject.push({
                        name: project.projectName,
                        description: project.projectName,
                        type: "project",
                        id: project.projectId,
                        isDelegated: (project.delegationAllocationType) ? true : false,
                        delegationType: (project.delegationAllocationType) ? project.delegationAllocationType : "none",
                        isPinned: (project.isPinned) ? true : false,
                        delegationDetails: project.delegationDetails,
                        isConfidentialEntity: (project.isConfidential) ? true : false,
                        isPublicSector: project.isPublicSector,
                        isUsPubSec: project.isUsPubSec,
                        isMarkedForDeletion: project.isMarkedForDeletion,
                        delegationData: project.delegationData
                    });

                }
            }
            projectsData.push({ eng: inlineEng, project: inlineProject });
            engagementsForDropDown.push({
                engagementId: engagement.engagementId,
                name: engagement.engagementName,
                isMenuOpen: false,
                projects: projectsData,
                isDelegated: (engagement.delegationAllocationType) ? true : false,
                delegationType: (engagement.delegationAllocationType) ? engagement.delegationAllocationType : "none",
                isPinned: (engagement.isPinned) ? true : false,
                delegationDetails: engagement.delegationDetails,
                isConfidential: (engagement.isConfidential) ? true : false,
                type: engagement.type.toLowerCase(),
                hasAssociatedEngagements: engagement.hasAssociatedEngagements,
                isPublicSector: engagement.isPublicSector,
                isUsPubsec: engagement.isUsPubSec,
                delegationData: engagement.delegationData
            });
        }
        const propertyBag = {};
        propertyBag[LogEventConstants.DropdownTotalNumberOfEntitiesListed] = (engagementsForDropDown.length + totalProjects);
        propertyBag[LogEventConstants.DropdownNumberOfEngagementsListed] = engagementsForDropDown.length;
        propertyBag[LogEventConstants.DropdownNumberOfProjectsListed] = totalProjects;
        // propertyBag[LogEventConstants.DropdownNumberOfDelegationProjectsListed] = delegation count
        this.dmLogger.logEvent(SourceConstants.Component.NavigationDropdown, SourceConstants.Method.GetDropDownItems, LogEventConstants.DropdownCreated, propertyBag);
        this.sharedFunctionsService.sortEngagementListByEngagementId(engagementsForDropDown);
        return engagementsForDropDown;
    }

}
