<section class="dm-finaci-snap col-xs-12 no-pad-left no-pad-right dm-finaci-amendments loading-amendments"
    *ngIf="!deviceFactory.isMobile()">
    <section class="dm-snapshot dm-amendments" *ngIf="!isServerError">
        <div id="financialPlanCaption" class="sr-only">Current Financial Plan</div>
        <table class="dm-s-header dm-table__header" aria-labelledby="financialPlanCaption" *ngIf="!isComponentLoading">
            <tr class="dm-am-header-border">
                <th scope="rowgroup" rowspan="2" class="dm-s-white dm-s-min-250 dm-s-w-30">
                    <p>
                        Current Financial Plan
                        <span *ngIf="currentBaseLinePlanDetails">
                            ({{currentBaseLinePlanDetails.statusDescription | dmDisplayDashIfEmptyOrNull}})
                            <span *ngIf="!currentBaseLinePlanDetails.statusDescription" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </span>
                    </p>
                </th>
                <th scope="col" class="dm-s-min-46 dm-s-w-8">
                    <p class="dm-s-th-heading">Margin</p>
                </th>
                <th scope="col" class="dm-s-min-172 dm-s-w-14">
                    <p class="dm-s-th-heading">Cost
                        <span *ngIf="currency">({{currency}})</span>
                    </p>
                </th>
                <th scope="col" class="dm-s-min-172 dm-s-w-15">
                    <p class="dm-s-th-heading">Revenue
                        <span *ngIf="currency">({{currency}})</span>
                    </p>
                </th>
                <th scope="col" class="dm-s-min-172 dm-s-w-15">
                    <p class="dm-s-th-heading">
                        Labor
                        <span>(hours)</span>
                    </p>
                </th>
                <th scope="col" class="dm-s-min-172">
                    <p class="dm-s-th-heading">
                        Risk Reserve
                        <span *ngIf="currency">({{currency}})</span>
                    </p>
                </th>
            </tr>
            <tr class="dm-am-border" *ngIf="currentBaseLineDetails">
                <td class="dm-s-min-46">
                    <p>
                        {{currentBaseLineDetails.margin ? (currentBaseLineDetails.margin | number : '1.0-2') + '%':'-'}}
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p class="pull-left">
                        {{currentBaseLineDetails.cost | dmDisplayCurrencyOrDash: currency}}
                        <span *ngIf="!currentBaseLineDetails.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p>
                        {{currentBaseLineDetails.revenue | dmDisplayCurrencyOrDash: currency}}
                        <span *ngIf="!currentBaseLineDetails.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p>
                        {{currentBaseLineDetails.labor ? (currentBaseLineDetails.labor | number : '1.0-2'): '-'}}
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p class="dm-s-th-wrap">
                        {{currentBaseLineDetails.riskReserveAmount | dmDisplayCurrencyOrDash: currency}}
                        <span *ngIf="!currentBaseLineDetails.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </p>
                </td>
            </tr>
        </table>
        <dm-loading [loadingText]="'Amendments'" *ngIf="isComponentLoading" [showLoading]="showLoading"></dm-loading>
        <section class="dm-s-results dm-am-results" *ngIf="!isComponentLoading">
            <section class="table-responsive">
                <div id="amendmentCaption" class="sr-only">amendments</div>
                <table *ngIf="crDetailsList && crDetailsList.length >= 0"
                    class="dm-table__header dm-tile--plan-table dm-tile__snap_shot_details b-b-none"
                    aria-labelledby="amendmentCaption">
                    <thead>
                        <tr class="dm-tile--b-b-03">
                            <th scope="col" class="font-caption-alt dm-s-th-heading no-pad-left">
                                <span class="show">CR ID/</span>
                                <span class="show">Description</span>

                            </th>
                            <th scope="col" class="font-caption-alt dm-s-th-heading">
                                Status

                                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                                    [ngbTooltipContent]="amedmentsTooltip1" [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="Status Information">
                                </dm-tooltip>

                                <ng-template #amedmentsTooltip1>
                                    <div>
                                        <p>
                                            <strong>Approved:</strong> Signed (Internal or External)
                                        </p>
                                        <p>
                                            <strong>In-Process:</strong> Action needed by user
                                        </p>
                                        <p>
                                            <strong>New:</strong> Newly created CR/Amendment
                                        </p>
                                    </div>
                                </ng-template>
                            </th>
                            <th scope="col" class="font-caption-alt dm-s-th-heading">
                                <span class="show text-nowrap" *ngIf="contractType === 'FF'">Planned Cost</span>
                                <span class="show text-nowrap" *ngIf="contractType === 'Mixed'">Planned Cost</span>
                                <span class="show">Change ({{currency}})</span>
                            </th>
                            <th scope="col" class="font-caption-alt dm-s-th-heading">
                                <span class="show text-nowrap">Planned Revenue</span>
                                <span class="show">Change ({{currency}})</span>
                            </th>
                            <th scope="col" class="font-caption-alt dm-s-th-heading">
                                <span *ngIf="deviceFactory.isDesktop()">
                                    <span class="show">Labor</span>
                                    <span class="show">Hours Change</span>
                                </span>
                                <span *ngIf="deviceFactory.isTablet()">
                                    <span class="show text-nowrap">Labor Hours</span>
                                    <span class="show">Change</span>
                                </span>
                            </th>
                            <th scope="col" class="font-caption-alt dm-s-th-heading">
                                <span class="show text-nowrap">Risk Reserve</span>
                                <span class="show text-nowrap">Change ({{currency}})</span>
                            </th>
                            <th scope="col">
                                <span class="sr-only">More Details</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="font-caption-alt dm-tile--b-b-015"
                            *ngFor="let amendments of crDetailsList | orderBy: 'createdOn' : true">
                            <ng-container *ngIf="amendments">
                                <th scope="row">
                                        <a class="amendment-button dm-link" [uiSref]="isAmendmentMisalignmentResoulutionInProgress(amendments) ? misalignedAmendmentDetailsRoute: amendmentDetailsRoute"
                                        [uiParams]="{amendmentId: isAmendmentMisalignmentResoulutionInProgress(amendments) ? amendments.misalignment.misalignedChangeRequestId : amendments.crNumber}"
                                        *ngIf="amendments.crNumber"
                                        aria-label="amendment details navigation item">
                                            {{amendments.crNumber | dmDisplayDashIfEmptyOrNull}}
                                        </a> 
                                        <span *ngIf="!amendments.crNumber"> <!-- If crNumber is missing, do not show link-->
                                            {{amendments.crNumber | dmDisplayDashIfEmptyOrNull}}
                                        </span>
                                        <span *ngIf="isAmendmentMisaligned(amendments)"
                                        class="icon icon-close icon-rounded dm-error" title="Select the CR ID to review and resolve errors"></span>
                                    <span class="show">
                                        {{amendments.crDescription | dmDisplayDashIfEmptyOrNull}}
                                    </span>
                                </th>
                                <td>
                                    {{amendments.statusDescription | dmDisplayDashIfEmptyOrNull}}
                                    <span *ngIf="!amendments.statusDescription" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </td>
                                <td [ngClass]="(amendments.requestedCost<0)?'text-nowrap':'text-space'">
                                    <span>
                                        {{amendments.requestedCost | dmDisplayCurrencyOrDash: currency}}
                                        <span *ngIf="!amendments.requestedCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </span>
                                </td>
                                <td [ngClass]="(amendments.revenueChange<0)?'text-danger text-nowrap':'text-space'">
                                    <span>
                                        {{amendments.revenueChange | dmDisplayCurrencyOrDash: currency}}
                                        <span *ngIf="!amendments.revenueChange" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </span>
                                    <b class="dm-tile__icon--circle icon icon-circle"
                                        *ngIf="amendments.revenueChange < 0"></b>
                                </td>
                                <td [ngClass]="(amendments.requestedHours<0)?'text-danger text-nowrap':'text-space'">
                                    <span>
                                        {{(amendments.requestedHours)? (amendments.requestedHours | number: '1.0-2') :'-'}}
                                    </span>
                                    <b class="dm-tile__icon--circle icon icon-circle"
                                        *ngIf="amendments.requestedHours < 0"></b>
                                </td>
                                <td [ngClass]="(amendments.riskReserveChange<0)?'text-danger text-nowrap':'text-space'">
                                    <span>
                                        {{amendments.riskReserveChange | dmDisplayCurrencyOrDash: currency}}
                                        <span *ngIf="!amendments.riskReserveChange" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </span>
                                    <b class="dm-tile__icon--circle icon icon-circle"
                                        *ngIf="amendments.riskReserveChange < 0"></b>
                                </td>
                                <td>
                                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                                        [ngbTooltipContent]="dmDynamic"
                                        [dmIcon]="'dm-tile--more-circle icon icon-moreLegacy'"
                                        ariaLabelToolTip="more Information">
                                    </dm-tooltip>
                                    <ng-template #dmDynamic>
                                        <div id="dmDynamic{{amendments.crNumber}}">
                                            <section class="amendment-body">
                                                <label class="font-label">Description</label>
                                                <p class="font-caption-alt pd-b-10">
                                                    {{amendments.crDescription | dmDisplayDashIfEmptyOrNull}}
                                                    <span *ngIf="!amendments.crDescription" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                </p>
                                                <label class="font-label">Last Updated By:</label>
                                                <p class="font-caption-alt pd-b-10">
                                                    {{amendments.lastChangedBy | dmDisplayDashIfEmptyOrNull}}
                                                    <span *ngIf="!amendments.lastChangedBy" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                </p>
                                                <label class="font-label">Timestamp (PST)</label>
                                                <p class="font-caption-alt pd-b-10">
                                                    {{(amendments.createdOn)?(amendments.createdOn | date: 'dd-MMM-yyyy
                                                    hh:mm a'):'-'}}
                                                </p>
                                            </section>
                                        </div>
                                    </ng-template>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </section>
            <dm-no-data *ngIf="crDetailsList && !crDetailsList.length && !isComponentLoading"
                [noDataText]="noAmendments"></dm-no-data>
        </section>

    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</section>

<!--==========================================Mobile View ===================================================-->
<section class="amendments-smallscreen animate-right col-xs-12 dm-p-x-imp-0" *ngIf="deviceFactory.isMobile()">
    <section>
        <section class="dm-tile--p-b-12 dm-tile-mar-4">
            <a href class="dm-link dm-link--small" id="viewfinacialsbtn" (click)="showAmendmentsData()">
                <span class="icon icon-chevron-left"></span>
                <span>Financials</span>
            </a>
        </section>
    </section>
    <section class="dm-tile col-xs-12 dm-tile--no-pad">
        <section class="dm-tile--b-b-015">
            <h6 class="font-label dm-tile--p-b-12">AMENDMENTS</h6>
        </section>
        <section class="col-xs-12 dm-tile--no-pad">
            <table class="dm-table__header dm-tile--plan-table font-caption-alt dm-tile--b-b-015">
                <thead>
                </thead>
                <tbody *ngIf="currentBaseLineDetails">
                    <tr class="dm-tile--b-b-015">
                        <th scope="row">Margin</th>
                        <td class="text-right">
                            {{currentBaseLineDetails.marginInPercentage?(currentBaseLineDetails.marginInPercentage |
                            number : '1.0-2') + '%':'-'}}
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row">Cost
                            <span *ngIf="currency">({{currency}})</span>
                        </th>
                        <td class="text-right">
                            {{currentBaseLineDetails.cost | dmDisplayCurrencyOrDash: currency}}
                            <span *ngIf="!currentBaseLineDetails.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row">Revenue
                            <span *ngIf="currency">({{currency}})</span>
                        </th>
                        <td class="text-right">
                            {{currentBaseLineDetails.revenue | dmDisplayCurrencyOrDash: currency}}
                            <span *ngIf="!currentBaseLineDetails.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row">Labor (Hours)</th>
                        <td class="text-right">
                            {{currentBaseLineDetails.hours ? (currentBaseLineDetails.hours | number: '1.0-2') : '-'}}
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row">Risk Reserve
                            <span *ngIf="currency">({{currency}})</span>
                        </th>
                        <td class="text-right">
                            {{currentBaseLineDetails.riskReserve | dmDisplayCurrencyOrDash: currency}}
                            <span *ngIf="!currentBaseLineDetails.riskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </section>


    <section class="dm-tile dm-tile--p-b-0" *ngFor="let amendments of crDetailsList">
        <section class="col-xs-12 dm-tile--p-b-12 amedmentsTooltip-mobile-wrapper pad0">
            <h6 class="pd-b-6">
                CR ID:
                <span>
                    {{amendments.crNumber | dmDisplayDashIfEmptyOrNull}}
                </span>
            </h6>
            <p class="font-label pd-b-6">
                Description:
                <span class="font-label">
                    {{amendments.crDescription | dmDisplayDashIfEmptyOrNull}}
                </span>
            </p>
            <p class="font-label">
                Status:
                <span class="font-label">
                    {{amendments.statusDescription | dmDisplayDashIfEmptyOrNull}}
                </span>
                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                    [ngbTooltipContent]="amedmentsTooltip1Mobile" [dmIcon]="'tile__icon--info icon icon-info'"
                    ariaLabelToolTip="Status Information">
                </dm-tooltip>
            </p>
            <ng-template #amedmentsTooltip1Mobile>
                <div>
                    <p>
                        <strong>Approved:</strong> Signed (Internal or External)
                    </p>
                    <p>
                        <strong>In-Process:</strong> Action needed by user
                    </p>
                    <p>
                        <strong>New:</strong> Newly created CR/Amendment
                    </p>
                </div>
            </ng-template>
        </section>
        <section class="col-xs-12 dm-tile--p-b-12  pad0">
            <section class="col-xs-6 text-right pad0 dm-tile--p-r-16">
                <h6 class="font-label">
                    Planned cost
                    <br /> Change ({{currency}})
                </h6>
                <span class="font-caption-alt">
                    {{amendments.requestedCost | dmDisplayCurrencyOrDash}}
                </span>
            </section>
            <section class="col-xs-6 text-right pad0 dm-tile--p-r-16">
                <h6 class="font-label">
                    Planned Revenue
                    <br /> Change ({{currency}})
                </h6>
                <span class="font-caption-alt" [ngClass]="{'text-danger' : amendments.revenueChange < 0}">
                    {{amendments.revenueChange | dmDisplayCurrencyOrDash}}
                </span>
                <b class="dm-tile__icon--circle icon icon-circle" *ngIf="amendments.revenueChange < 0"></b>
            </section>
        </section>
        <section class="col-xs-12 dm-tile--p-b-12 dm-tile--b-b-015  pad0">
            <section class="col-xs-6 text-right pad0 dm-tile--p-r-16">
                <h6 class="font-label dm-tile--p-t-12">
                    Labor Hours Change
                </h6>
                <span class="font-caption-alt" [ngClass]="{'text-danger' : amendments.requestedHours < 0}">
                    {{amendments.requestedHours | dmDisplayCurrencyOrDash}}
                </span>
                <b class="dm-tile__icon--circle icon icon-circle" *ngIf="amendments.requestedHours < 0"></b>
            </section>
            <section class="col-xs-6 text-right pad0 dm-tile--p-r-16">
                <h6 class="font-label">
                    Risk Reserve
                    <br /> Change ({{currency}})
                </h6>
                <span class="font-caption-alt" [ngClass]="{'text-danger' : amendments.riskReserveChange < 0}">
                    {{amendments.riskReserveChange | dmDisplayCurrencyOrDash}}
                </span>
                <b class="dm-tile__icon--circle icon icon-circle" *ngIf="amendments.riskReserveChange < 0"></b>
            </section>
        </section>
        <section class="col-xs-12 dm-tile--p-t-12 pad0">
            <section class="col-xs-6 text-right pad0 dm-tile--p-r-16">
                <h6 class="font-label">
                    Last updated By:
                </h6>
                <span class="font-caption-alt">
                    {{amendments.lastChangedBy | dmDisplayDashIfEmptyOrNull}}
                </span>
            </section>
            <section class="col-xs-6 text-right pad0 dm-tile--p-r-16">
                <h6 class="font-label">
                    Timestamp
                </h6>
                <span class="font-caption-alt show text-right">
                    {{amendments.createdOn | dmDisplayDateOrDashPipe}}
                </span>
                <span class="font-caption-alt show text-right">
                    {{amendments.createdOn | date: 'hh:mm a'}}
                </span>
                <span class="sr-only">
                    {{amendments.createdOn | date: 'dd-MMM-yyyy hh:mm a'}}
                </span>
            </section>
        </section>

        <!--=================Amendment details=====================-->
        <section class="pull-right dm-tile--p-t-b-16">
            <a [uiSref]="amendmentDetailsRoute" [uiParams]="{amendmentId: amendments.crNumber}"
                class="dm-link dm-link--small" id="viewamendmentsdetails"
                aria-label="amendment details navigation item">
                View Details
                <span class="icon icon-chevron-right top-3"></span>
            </a>
        </section>

    </section>

</section>