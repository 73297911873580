import { Pipe, PipeTransform } from "@angular/core";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isUndefined(value: any): boolean {
    return typeof value === "undefined";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function extractDeepPropertyByMapKey(obj: any, map: string): any {
    const keys = map.split(".");
    const head = keys.shift();

    return keys.reduce((prop: any, key: string) => !isUndefined(prop) && !isUndefined(prop[key]) ? prop[key] : undefined, obj[head || ""]);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isObject(value: any): boolean {
    return value !== null && typeof value === "object";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isString(value: any): boolean {
    return typeof value === "string";
}

@Pipe({ name: "unique" })
export class UniquePipe implements PipeTransform {
    public transform(input: any[], args?: string | undefined): any[];
    public transform<T>(input: T, args?: string | undefined): T;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public transform(input: any, propertyName?: string | undefined): any {
        const uniques: boolean[] = [];

        return Array.isArray(input)
            ? isUndefined(propertyName)
                ? input.filter((e, i) => input.indexOf(e) === i)
                : input.filter((e) => {
                    let value = extractDeepPropertyByMapKey(e, propertyName);
                    value = isObject(value) ? JSON.stringify(value) : value;

                    if (isUndefined(value) || uniques[value]) {
                        return false;
                    }

                    uniques[value] = true;

                    return true;
                })
            : input;
    }
}