import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { CustomerEngagementModule } from "./tiles/customer-engagement/customer-engagement.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { EngagementSummaryDetailsModule } from "./tiles/engagement-summary-details/engagement-summary-details.module";
import { InternalEngagementSummaryComponent } from "./internal-engagement-summary.component";
import { LaborConsumptionModule } from "./tiles/labor-consumption/labor-consumption.module";
import { ProjectLinksModule } from "../../tiles/key-links/key-links.module";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { InternalEngagementProjectsModule } from "./tiles/internal-engagement-projects/internal-engagement-projects.module";
import { KeyActionsV2Module } from "../../tiles/key-actions-v2/key-actions-v2.module";

@NgModule({
    declarations: [
        InternalEngagementSummaryComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        CustomerEngagementModule,
        EngagementSummaryDetailsModule,
        KeyActionsV2Module,
        LaborConsumptionModule,
        ProjectLinksModule,
        InternalEngagementProjectsModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        ProjectService,
        SharedFunctionsService,
    ],
    entryComponents: [
        InternalEngagementSummaryComponent,
    ],
    exports: [
        InternalEngagementSummaryComponent,
    ]
})
export class InternalEngagementSummaryModule { }
