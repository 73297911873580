import { Component, forwardRef, Inject, Input, Output, EventEmitter, SimpleChanges, Injector } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ContractType, IEngagementDetailsApiV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { ICrDemandChangeEvent, ICrResource, ICrSubconOutput, IExistingSubcon, ITask } from "../../../common/services/contracts/changerequest.contract";
import { FxpMessageService } from "@fxp/fxpservices";
import { ChangeRequestDemandService } from "../../../common/services/change-request-demand.service";
import { DmError } from "../../../common/error.constants";
import { StateService } from "@uirouter/angular";
import { IFcrSubconFormControlData } from "../../../common/services/contracts/changerequestv2.contract";
import { AccessibilityConstants, RouteName } from "../../../common/application.constants";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CancelOrContinueModal } from "../modals/cancel-or-continue-modal/cancel-or-continue-modal.component";
import { ProjectService } from "../../../common/services/project.service";

@Component({
    selector: "dm-fcr-subconff-form-control",
    templateUrl: "./fcr-subconff-form-control.html",
    styleUrls: ["./fcr-subconff-form-control.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FcrSubconFfFormControlComponent),
            multi: true
        }
    ]
})
export class FcrSubconFfFormControlComponent implements ControlValueAccessor {
    @Input() public get value(): ICrSubconOutput[] {
        return this.state;
    }
    public set value(val: ICrSubconOutput[]) {
        this.state = [...val];
        this.propagateChange(this.state);
    }

    @Input() public fvrContractType: ContractType;
    @Input() public fcrFormControlsTotalValue: ICrResource[];
    @Input() public fcrSubconFormControlData: IFcrSubconFormControlData;
    @Input() public fcrSubconFormControlDataError: string;
    @Input() public engagementDetails: IEngagementDetailsApiV2;
    @Input() public populateRoles: ICrSubconOutput[] = null;

    @Output() public contractTypeChange = new EventEmitter<ContractType>();
    @Output() public demandChangeEvent = new EventEmitter<ICrDemandChangeEvent>();
    @Output() public subconRequestSaveDeleteEvent = new EventEmitter<ICrSubconOutput>();
    public totalPlannedSubconCost: number;
    public totalAdditionalSubconCost: number;
    public invalidContractTypeMessage: string;
    public isEditable: boolean = true;
    public isPubSecEngagement: boolean = false;
    public loadingMessage: string = "Loading Subcon FF Details...";
    public statusMessage: string;
    public title: string;
    public totalCost: number = 0;
    public fcrErrorMessages = DmError.FinancialChangeRequest;
    public accessibilityConstants = AccessibilityConstants;

    private state: ICrSubconOutput[] = [];

    public constructor(
        @Inject(ChangeRequestDemandService) public crDemandService: ChangeRequestDemandService,
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) public stateService: StateService,
        @Inject(Injector) private injector: Injector,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
    ) { }

    public ngOnChanges(allInputChanges: SimpleChanges): void {

        /* This's the recommeded way to access SimpleChanges */
        if (allInputChanges["populateRoles"] && Array.isArray(allInputChanges["populateRoles"].currentValue)) {
            // const subconLineItems = allInputChanges["populateRoles"];

            this.state = [...allInputChanges["populateRoles"].currentValue] as ICrSubconOutput[];
            this.recalculate();
        }

        if (allInputChanges["fvrContractType"] && allInputChanges["fvrContractType"].currentValue) {
            this.fvrContractType = allInputChanges["fvrContractType"].currentValue;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateChange = (...args: any[]): void => { return; };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateTouch = (...args: any[]): void => { return; };

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: (...args: any[]) => void): void {
        this.propagateChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: (...args: any[]) => void): void {
        this.propagateTouch = fn;
    }


    /**
     * Writes a new value to the element.
     * 
     * Part of ControlValueAccessor interface.
     */
    public writeValue(value: ICrSubconOutput[]): void {
        if (value !== undefined) {
            this.value = [...value];
        }
    }

    /**
     * Update role request details on change of task.
     *
     * @param {ITask} task
     * @param {ICrResource} rowLineItem
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @memberof FcrSubconFormControlComponent
     */
    public onSelectedTaskChange(task: ITask, rowLineItem: ICrSubconOutput): void {
        rowLineItem.currentState.isCrPendingInProject = this.crDemandService.isCrPendingInProject(task.projectId, this.fcrSubconFormControlData.projectsWithPendingCr);

        if (!rowLineItem.currentState.isCrPendingInProject) {
            rowLineItem.currentState.assignedTask = task;
        } else {
            rowLineItem.isSaveable = this.crDemandService.isSubconRequestSaveable(rowLineItem);
        }
    }

    /**
      * Update subcon request line item on change of existing subcon.
      *
      * @param {IExistingSubcon} existingSubcon
      * @param {ICrSubconOutput} rowLineItem
      * @param {IEngagementDetailsApiV2} engagementDetails
      * @memberof FcrSubconFfFormControlComponent
      */
    public onExistingSubconChange(existingSubcon: IExistingSubcon, rowLineItem: ICrSubconOutput, engagementDetails: IEngagementDetailsApiV2): void {
        this.crDemandService.setExistingSubconResource(existingSubcon, rowLineItem, engagementDetails, this.fcrSubconFormControlData.existingTasks);
        rowLineItem.currentState.isCrPendingInProject = existingSubcon.isCrPendingInProject;

        rowLineItem.isSaveable = this.crDemandService.isSubconRequestSaveable(rowLineItem);
    }

    /**
     * Set contract type error message based on request contract type.
     *
     * @param {boolean} isFixedFee
     * @memberof FcrRolesFormControlComponent
     */
    public setContractTypeErrorMessage(isFixedFee: boolean): void {
        this.invalidContractTypeMessage = `Cannot add a ${isFixedFee ? "Fixed Fee" : "Time and Material"} task to the FCR with an existing ${isFixedFee ? "Time and Material" : "Fixed Fee"} task.`;
    }

    /**
     * When user wants to save the current state, copy the current state into the saved state.
     */
    public saveRequest(index: number): void {
        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        const currentSubconRequests = this.state.filter((m) => m.currentState).length;
        const savedSubconRequests = this.state.filter((m) => m.savedState).length;
        const rowToSave = this.state.filter((m) => this.state[index].uuid === m.uuid)[0];
        let isValidOverRide: boolean = false;
        if ((currentSubconRequests === 1 && savedSubconRequests === 1 && numberOfSavedRequests === 1) || savedSubconRequests === 1 && rowToSave.savedState && numberOfSavedRequests === 1) {
            this.state[index].currentState.isContractTypeValid = true;
            this.emitContractTypeChange(this.state[index]);
            isValidOverRide = true;
            this.state[index].editModeEnabled = false;
            this.state[index].savedState = { ...this.state[index].currentState };
            this.sharedFunctionsService.focus("editBtn_" + index, true);
            this.emitSubconRequestSaveOrDelete(this.state[index]);
            this.value = this.state;
            this.recalculate();
        } else if (this.fvrContractType && !this.crDemandService.validateFvrContractType(this.state[index], this.fvrContractType)) {
            this.state[index].currentState.isContractTypeValid = false;
            const isRequestFixedFee: boolean = this.state[index].currentState.assignedTask.isFixedFeeProject;
            this.setContractTypeErrorMessage(isRequestFixedFee);
        } else {
            this.state[index].currentState.isContractTypeValid = true;
            isValidOverRide = true;
            this.emitContractTypeChange(this.state[index], isValidOverRide);

            this.state[index].editModeEnabled = false;
            this.state[index].savedState = { ...this.state[index].currentState };
            this.sharedFunctionsService.focus("editBtn_" + index, true);

            this.emitSubconRequestSaveOrDelete(this.state[index]);
            this.value = this.state;
        }
    }


    public deleteRequest(event: KeyboardEvent, index: number): void {
        if (this.state[index].currentState.existingResource) {
            // we need to update the currently displayed existing resources
            this.state[index].currentState.existingResource.isDisplayed = false;
        }

        const isDeletedRoleFixedFee: boolean = this.state[index].currentState.assignedTask ? this.state[index].currentState.assignedTask.isFixedFeeProject : undefined;
        const deletedContractType: ContractType = isDeletedRoleFixedFee ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        const deletedRowLineItem: ICrSubconOutput = this.state[index];
        this.state.splice(index, 1);
        this.value = this.state;
        this.recalculate();

        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        if (!this.state.length && numberOfSavedRequests === 1) {
            this.fvrContractType = undefined;
        } else if ((isDeletedRoleFixedFee !== undefined && this.fvrContractType === deletedContractType) && !this.fcrFormControlsTotalValue.some((resourceRequest: ICrResource) => resourceRequest.savedState && resourceRequest.savedState.assignedTask && resourceRequest.savedState.assignedTask.isFixedFeeProject === isDeletedRoleFixedFee)) {
            this.fvrContractType = undefined;
        }

        // Only emit delete if there was a saved state
        if (deletedRowLineItem.savedState) {
            // Only emit contract type on delete if we are clearing the overall request type
            if (!this.fvrContractType) {
                this.emitContractTypeChange(undefined);
            }

            this.emitSubconRequestSaveOrDelete(this.state[index]);
        }

        if (this.state.length > 0 && index !== this.state.length && this.state[index]) {
            if (this.state[index].savedState) {
                this.sharedFunctionsService.focus("editBtn_" + index, true);
            }
            // else {
            //     this.state[index].currentState.existingDemand ?
            //         this.sharedFunctionsService.focus("adjustExistingRole_" + index, true) :
            //         this.sharedFunctionsService.focus("addNewEBSId_" + index, true);
            // }
        } else {
            // this.isSubmittable() ? this.sharedFunctionsService.focus("submit", true) : this.sharedFunctionsService.focus("closeUpdateButton", true);
        }
    }

    /**
     * revert to previously saved state
     */
    public discardChanges(event: KeyboardEvent, index: number): void {
        if (this.state[index] && this.state[index].savedState) {
            if (this.state[index].currentState.existingResource) {
                this.state[index].currentState.existingResource.isDisplayed = false;
                this.state[index].savedState.existingResource.isDisplayed = true;
            }
            this.state[index].currentState = { ...this.state[index].savedState };
            this.recalculate();
            this.sharedFunctionsService.focus("editBtn_" + index, true);
        }
        if (this.state[index] && !this.state[index].savedState) {
            this.deleteRequest(event, index);
        }
    }

    /**
    * Emit an event when a role request is successfully saved or deleted.
    *
    * @param {ICrSubconOutput} rowLineItem
    * @memberof FcrSubconFormControlComponent
    */
    public emitSubconRequestSaveOrDelete(rowLineItem: ICrSubconOutput): void {
        this.subconRequestSaveDeleteEvent.emit(rowLineItem);
    }

    /**
     * executes on planned cost change
     *
     * @param {ICrSubconOutput} subconLine
     * @memberof FcrSubconFfFormControlComponent
     */
    public onPlannedCostChange(subconLine: ICrSubconOutput): void {
        this.recalculate();
        this.validateSubconData(subconLine);
    }

    /**
     * Add new Subcon
     *
     * @memberof LaborRequestModalComponent
     */
    public addNewSubcon(): void {
        const newSubcon = this.crDemandService.getAddSubconInitialState();
        this.state.push(newSubcon);
    }

    /**
     * used for angular optimization of rendering this.state list (trackBy)
     */
    public trackByFn(index: number, item: ICrResource): string {
        return item.uuid;
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        const length: number = this.state.length;
        let defaultId: string = "adjustExisting";
        if (length > 0 && this.state[length - 1]) {
            defaultId = this.state[length - 1].savedState ? "deleteBtn_" + (length - 1) : "cancelBtn_" + (length - 1);
        }
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, defaultId);
        }
    }

    /**
     * Sets the focus on the first editable element after edit button is clicked.
     */
    public moveFocusToEditableElement(index: string, isExistingResource: boolean): void {
        const focusingId: string = isExistingResource ? "adjustExistingRole_" + index : "addNewEBSId_" + index;
        this.sharedFunctionsService.focus(focusingId, true);
    }

    /**
     * Recalculate relevant values given a change in the state.
     */
    public recalculate(): void {
        let totalPlannedSubconCost: number = 0;
        let totalAdditionalSubconCost: number = 0;
        this.state.forEach((item: ICrSubconOutput) => {
            if (!isNaN(item.currentState.newPlannedCost)) {
                item.currentState.newPlannedCost = +item.currentState.newPlannedCost;
                const cfpCost = item.isDbOnlyDemand ? 0 : item.currentState.existingResource.cfpCost + item.currentState.newPlannedCost;
                const additionalCost = cfpCost - (item.currentState.existingResource && item.currentState.existingResource.dbCost ? item.currentState.existingResource.dbCost : 0);
                totalAdditionalSubconCost += additionalCost;
                totalPlannedSubconCost += item.currentState.newPlannedCost;
            }
        });
        this.totalAdditionalSubconCost = totalAdditionalSubconCost;
        this.totalPlannedSubconCost = totalPlannedSubconCost;
        this.value = this.state;
    }


    public onEditClick(event: KeyboardEvent, index: string): void {
        this.state[index].editModeEnabled = true;
        this.moveFocusToEditableElement(index, !!this.state[index].currentState.existingSubcon);
    }

    /**
     * Validate Project End Date
     * 
     * @param {ITask} task
     */
    public validateProjectEndDate(task: ITask): boolean {
        if (!task || !task.projectEndDate) {
            return false;
        }

        const projectEndDate = new Date(task.projectEndDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
     * Redirects users to Engagement's Manage EBS screen
     * 
     */
    public redirectToEngagementManageEBS(): void {
        if (this.engagementDetails) {
            const engagementId = this.engagementDetails.id;
            this.stateService.go(RouteName.EngagementManageEBS, { engagementId }, { reload: RouteName.EngagementManageEBS });
        }
    }

    /**
     * Retrieves the expired projects
     * 
     */
    public getExpiredProjects(): Set<string> {
        const filteredTasks = this.fcrSubconFormControlData && this.fcrSubconFormControlData.existingTasks && this.fcrSubconFormControlData.existingTasks.filter((task) => {
            const projectEndDate = new Date(task.projectEndDate);
            const currentDate = new Date();

            return projectEndDate < currentDate;

        });

        const expiredProjects = new Set<string>();
        if (filteredTasks) {
            filteredTasks.forEach((task) => {
                expiredProjects.add(task.projectId);
            });
        }

        return expiredProjects;
    }

    /**
     * Proceed with project's end date change.
     * 
     */
    public onEditProjectEndDate(): void {

        // First shows up a modal to confirm the date change action from user.
        const cancelOrContinueModalRef: NgbModalRef = this.modalService.open(
            CancelOrContinueModal,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }

        );

        cancelOrContinueModalRef.componentInstance.modalMessage = "The details entered in this FCR form will be erased while changing the project dates. Do you wish to continue?";
        cancelOrContinueModalRef.componentInstance.continueFunction = () => {
            this.redirectToEngagementManageEBS();
        };

    }

    /**
    * Validates End Date of Projects having existing demands
    * 
    * @param {ITask} task
    * @memberof LaborRequestModalComponent
    */
    public validateResourceProjectEndDate(taskId: string): boolean {
        if (!taskId) {
            return false;
        }
        const projectId = this.projectService.getProjectIdFromTaskId(taskId);
        const projectDetails = this.projectService.getProjectFromWbsId(projectId, this.engagementDetails);
        if (!projectDetails || !projectDetails.endDate) {
            return false;
        }

        const projectEndDate = new Date(projectDetails.endDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
     * Emit when the contract type of role requests has changed.
     *
     * @param {ICrResource} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public emitContractTypeChange(rowLineItem: ICrSubconOutput, isValidOverRide?: boolean): void {
        let laborContractType: ContractType;
        if (rowLineItem) {
            laborContractType = rowLineItem.currentState.assignedTask.isFixedFeeProject ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        }

        if (!this.fvrContractType || isValidOverRide) {
            this.contractTypeChange.emit(laborContractType);
        }
    }

    /**
     * validate subcon request
     *
     * @private
     * @param {ICrSubconOutput} subconLine
     * @memberof FcrSubconFfFormControlComponent
     */
    private validateSubconData(subconLine: ICrSubconOutput) {
        if (subconLine.currentState.newPlannedCost + subconLine.currentState.existingResource.plannedCost < 0) {
            subconLine.currentState.isPlannedCostLessThanTotalPo = true;
        } else {
            subconLine.currentState.isPlannedCostLessThanTotalPo = false;
        }
        subconLine.isSaveable = this.crDemandService.isSubconRequestSaveable(subconLine);
    }
}
