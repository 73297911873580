import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { TypeAheadCompanyCodeComponent } from "./type-ahead-companycode.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        TypeAheadCompanyCodeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        OneProfileService
    ],
    entryComponents: [
        TypeAheadCompanyCodeComponent
    ],
    exports: [
        TypeAheadCompanyCodeComponent
    ],
})
export class TypeAheadCompanyCodeModule { }
