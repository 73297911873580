import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ClinSlinModalModule } from "../estimate-at-complete/estimate-at-complete-modals/clin-slin/clin-slin-modal.module";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { KeyIndicatorsComponent } from "./key-indicators.component";
import { ProjectService } from "../../../../common/services/project.service";
import { RiskReserveModalModule } from "../estimate-at-complete/estimate-at-complete-modals/risk-reserve/risk-reserve.module";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { UnapprovedExpenseModalModule } from "./unapproved-modals/unapproved-expense-modal/unapproved-expense-modal.module";
import { UnapprovedLaborModalModule } from "./unapproved-modals/unapproved-labor-modal/unapproved-labor-modal.module";
import { ViewCapAmountsModalModule } from "../estimate-at-complete/estimate-at-complete-modals/view-cap-amounts/view-cap-amounts.module";
import { ConcurService } from "../../../../common/services/concur.service";
import { CapAmountService } from "../../../../common/services/cap-amount.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { LaborManagementService } from "../../../../common/services/labor-management.service";
import { ChangeRequestService } from "../../../../common/services/change-request.service";
import { EcifIOConsumedModalModule } from "../estimate-at-complete/estimate-at-complete-modals/ecif/ecif-io-consumed-modal.module";

@NgModule({
    declarations: [
        KeyIndicatorsComponent,
    ],
    imports: [
        ClinSlinModalModule,
        EcifIOConsumedModalModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule,
        FormsModule,
        NgbModule,
        RiskReserveModalModule,
        UnapprovedExpenseModalModule,
        UnapprovedLaborModalModule,
        ViewCapAmountsModalModule
    ],
    providers: [
        ChangeRequestService,
        ConcurService,
        ConfigManagerService,
        CapAmountService,
        DMLoggerService,
        FinancialService,
        LaborManagementService,
        ProjectService,
        SharedFunctionsService,
    ],
    entryComponents: [
        KeyIndicatorsComponent,
    ],
    exports: [
        KeyIndicatorsComponent,
    ]
})
export class KeyIndicatorsModule { }
