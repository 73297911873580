import * as fromResourceProjectContext from "./resource-requests-project-context.action";
import { IResourceRequestResponse } from "../../common/services/contracts/staffing.service.contract";

export interface IResourceRequestsDetailsProjectState {
    grmSearchApiResponse: IResourceRequestResponse;
    loaded: boolean;
    loading: boolean;
    error: string;
}

export const initialState: IResourceRequestsDetailsProjectState = {
    grmSearchApiResponse: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromResourceProjectContext.ResourceRequestProjectContextActions): { [projectId: string]: IResourceRequestsDetailsProjectState } {
    switch (action.type) {
        case fromResourceProjectContext.ResourceRequestsProjectContextActionTypes.LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT: {
            const newState = { ...state };
            newState[action.projectId] = { ...initialState, loading: true };
            return newState;
        }
        case fromResourceProjectContext.ResourceRequestsProjectContextActionTypes.LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT_SUCCESS: {
            const newState = { ...state };
            newState[action.projectId] = {
                ...initialState,
                grmSearchApiResponse: action.grmSearchApiResponse,
                loaded: true,
                loading: false,
            };
            return newState;
        }
        case fromResourceProjectContext.ResourceRequestsProjectContextActionTypes.LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT_FAIL: {
            const newState = { ...state };
            newState[action.projectId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }
        case fromResourceProjectContext.ResourceRequestsProjectContextActionTypes.INVALIDATE_RESOURCEREQUESTS_PROJECT_CONTEXT: {
            const newState = { ...state };
            newState[action.projectId] = initialState;
            return newState;
        }
    }
    return state;
}
