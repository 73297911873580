
import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, Inject, AfterContentChecked, forwardRef } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { FxpIrisBotService, FxpContext } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";

import { Components, FinancialType, SourceConstants, LogEventConstants } from "../../../../common/application.constants";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IEntityFinancials, IEntityFinancialSummary } from "../../financial.model";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IState } from "../../../../store/reducers";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../common/error.constants";

@Component({
    selector: "dm-financial-summary",
    templateUrl: "./financial-summary.html",
    styleUrls: ["./financial-summary.scss"]
})
export class FinancialSummaryComponent extends DmComponentAbstract implements AfterContentChecked {
    public isBaselineActive: boolean = false;
    public isProjectContext: boolean = false;
    public isInDecoRecoState: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.FinancialSummary;
    private haveMountedComponents: boolean = false;
    private engagementId: string;

    public constructor(
        @Inject(forwardRef(() => FxpIrisBotService)) private fxpIrisBotService: FxpIrisBotService,
        @Inject(forwardRef(() => FxpContext)) private fxpContext: FxpContext,        
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(FinancialService) private financialService: FinancialService,
    ) {
        super(dmLogger, Components.FinancialSummary);
    }

    public ngOnInit(): void {
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        let isProjectContext: boolean = false;
        if (projectId) {
            isProjectContext = true;
        }
        this.tileContent = {
            title: "Summary"  
        };
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        const financialDetails$ = isProjectContext ? this.store.select(getEntireFinancialDetailsV2(projectId))
            : this.store.select(getEntireFinancialDetailsV2(this.engagementId));

        observableCombineLatest(
            financialDetails$,
            engagementDetails$,
            (
                financialDetails: IFinancialDetailsV2State,
                engagementDetails: IEngagementDetailsState,
            ) => {
                return ({
                    financialDetails,
                    engagementDetails,
                });
            }
        ).pipe(untilDestroyed(this))
            .subscribe(({
                financialDetails,
                engagementDetails,
            }) => {
                this.refreshOnItemInvalidation(financialDetails, engagementDetails);
                this.setLoadersBasedOnItemState(financialDetails, engagementDetails);
                this.setErrorsBasedOnItemState(financialDetails, engagementDetails);
                if (financialDetails.loaded && engagementDetails.loaded) { // todo, is engagement details needed? or only financial
                    if (isProjectContext) {
                        const projectFinancials: IEntityFinancials = financialDetails.financialDetails;
                        const eacFinancials: IEntityFinancialSummary[] = this.financialService.getFinancialDetailForBaselineTypeForV2Response(projectFinancials, FinancialType.EAC);
                        this.isBaselineActive = this.financialService.IsEntityFinancialDetailsExists(eacFinancials);
                        const projectDetails = engagementDetails.engagementDetails.projects.filter((project) => project.id.toUpperCase() === projectId.toUpperCase())[0];
                        this.isInDecoRecoState = ["DECO", "RECO"].includes(projectDetails.currentStatusCode.toUpperCase());
                    } else {
                        const engagementFinancials: IEntityFinancials = (financialDetails as IFinancialDetailsV2State).financialDetails;
                        const eacFinancials: IEntityFinancialSummary[] = this.financialService.getFinancialDetailForBaselineTypeForV2Response(engagementFinancials, FinancialType.EAC);                        
                        this.isBaselineActive = this.financialService.IsEntityFinancialDetailsExists(eacFinancials);
                        this.isInDecoRecoState = ["DECO", "RECO"].includes(engagementDetails.engagementDetails.currentStatusCode.toUpperCase());
                    }
                }
                if (financialDetails.error || engagementDetails.error) {
                    this.isServerError = true;
                }
            });
    }

    /**
     * Checks the current status of mounted components and adds child components to the 
     * child array to track loading telemetry.
     *
     * @memberof FinancialSummaryComponent
     */
    public ngAfterContentChecked(): void {
        if (!this.haveMountedComponents && this.isBaselineActive) {
            this.addNewChildComponent(Components.FinancialSummaryDetails);
            this.haveMountedComponents = true;
        }
        if (!this.haveMountedComponents && !this.isBaselineActive) {
            this.addNewChildComponent(Components.FinancialReleaseAndActivateSummary);
            this.haveMountedComponents = true;
        }
    }


    /**
     * Event on Bot Icon Clicked     
     *
     * @memberof FinancialSummaryComponent
     */
    public onBotIconClicked(): void {   
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OnBotIconClicked, LogEventConstants.ContactPJCIcon);     
        this.fxpContext.saveContext("activityKey", JSON.stringify(["Project Controller", this.engagementId]))
            .then(() => {
                this.fxpIrisBotService.launchIrisBot();               
            });        
    }
}
