<div class="dm-create-contact-modal" (keydown.Escape)="closeModal()">
    <div class="modal-header modal-noborder">
        <h4 class="modal-title font-subtitle pull-left">New Customer Contact Request</h4>
        <button type="button" title="Close" class="close set-focus" aria-label="New Customer Contact Request Modal Close"
            (click)="closeModal()" id="closeCreateButton">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
    </div>
    <div class="modal-body display-flex-imp">
        <form [formGroup]="createCSATContactForm">
            <div class="col-lg-12 dm-p-l-imp-0 dm-p-b-10">
                <div class="col-lg-6 dm-p-l-imp-0">
                    <label class="font-caption" for="contactFirstName">
                        <span>First Name</span>
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <input aria-required="true" class="font-caption-alt dm-input" type="text" formControlName="contactFirstName"
                        maxlength="40"
                        [ngClass]="{'input-error-border': (contactFirstName?.dirty || contactFirstName?.touched) && contactFirstName.invalid}"
                        id="contactFirstName" name="contactFirstName" [attr.aria-label]="'First name' + (contactFirstName?.dirty || contactFirstName?.touched) && contactFirstName?.errors?.required ? dmCsatContactErrorMessages.ContactFirstNameRequired : ''" placeholder="First Name">
                    <p class="font-caption-alt inp-validation error-msg"
                        *ngIf="contactFirstName?.dirty || contactFirstName?.touched">
                        <span *ngIf="contactFirstName?.errors?.required">{{dmCsatContactErrorMessages.ContactFirstNameRequired}}</span>
                    </p>
                </div>
                <div class="col-lg-6 dm-p-l-imp-0 dm-p-r-imp-0">
                    <label class="font-caption" for="contactLastName">
                        <span>Last Name</span>
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <input aria-required="true" class="font-caption-alt dm-input" type="text" formControlName="contactLastName"
                        maxlength="40"
                        [ngClass]="{'input-error-border': (contactFirstName?.dirty || contactLastName?.touched) && contactLastName.invalid}"
                        id="contactLastName" name="contactLastName" [attr.aria-label]="'Last name' + (contactLastName?.dirty || contactLastName?.touched) && contactLastName?.errors?.required ? dmCsatContactErrorMessages.ContactLastNameRequired : ''" placeholder="Last Name">
                    <p class="font-caption-alt inp-validation error-msg"
                        *ngIf="contactLastName?.dirty || contactLastName?.touched">
                        <span *ngIf="contactLastName?.errors?.required">{{dmCsatContactErrorMessages.ContactLastNameRequired}}</span>
                    </p>
                </div>
            </div>

            <div class="col-lg-12 dm-p-l-imp-0 dm-p-b-10">
                <div class="col-lg-6 dm-p-l-imp-0">
                    <label class="font-caption" for="contactEmail">
                        <span>Email</span>
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <input aria-required="true" class="font-caption-alt dm-input" type="text" formControlName="contactEmail" maxlength="140"
                        [ngClass]="{'input-error-border': (contactEmail?.dirty || contactEmail?.touched) && contactEmail.invalid }"
                        id="contactEmail" name="contactEmail" [attr.aria-label]="'Email ' + contactEmail?.dirty || contactEmail?.touched ? contactEmail?.errors?.required ? dmCsatContactErrorMessages.ContactEmailRequired : '' + contactEmail?.errors?.email ? dmCsatContactErrorMessages.EmailIsInvalid : '' : ''" placeholder="Email">
                    <p class="font-caption-alt inp-validation error-msg"
                        *ngIf="contactEmail?.dirty || contactEmail?.touched">
                        <span *ngIf="contactEmail?.errors?.required">{{dmCsatContactErrorMessages.ContactEmailRequired}}</span>
                        <span *ngIf="contactEmail?.errors?.email">{{dmCsatContactErrorMessages.EmailIsInvalid}}</span>
                    </p>
                </div>
                <div class="col-lg-6 dm-p-l-imp-0 dm-p-r-imp-0">
                    <div class="col-lg-9 dm-p-l-imp-0">
                        <label class="font-caption" for="phoneNumber">
                            <span>Phone</span>
                        </label>
                        <input class="font-caption-alt dm-input" type="text" formControlName="phoneNumber"
                            maxlength="30"
                            [ngClass]="{'input-error-border': (phoneNumber?.dirty || phoneNumber?.touched) && phoneNumber.invalid}"
                            id="phoneNumber" name="phoneNumber" aria-label="Phone Number" placeholder="Phone Number">
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite" *ngIf="phoneNumber?.dirty || phoneNumber?.touched">                          
                            <span *ngIf="phoneNumber?.errors?.pattern">{{dmCsatContactErrorMessages.EnterValidPhoneNumber}}</span>
                        </p>
                    </div>
                    <div class="col-lg-3 dm-p-l-imp-0">
                        <label class="font-caption" for="phoneExtension">
                            <span>Extension</span>
                        </label>
                        <input class="font-caption-alt dm-input" type="text" formControlName="phoneExtension"
                            maxlength="5" [attr.aria-label]="(phoneExtension?.dirty || phoneExtension?.touched) && phoneExtension.invalid && phoneExtension?.errors?.pattern ? 'Extension ' + dmCsatContactErrorMessages.EnterValidExtension : 'Extension'"
                            [ngClass]="{'input-error-border': (phoneExtension?.dirty || phoneExtension?.touched) && phoneExtension.invalid}"
                            id="phoneExtension" name="phoneExtension" aria-label="Phone Extension" placeholder="">
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="(phoneExtension?.dirty || phoneExtension?.touched) && phoneExtension.invalid">
                            <span *ngIf="phoneExtension?.errors?.pattern">{{dmCsatContactErrorMessages.EnterValidExtension}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" id="cancelCreate" class="fxp-btn fxp-btn--secondary" (click)="closeModal()">
            Cancel
        </button>
        <button type="button" id="createCSAT" class="fxp-btn fxp-btn--primary" (click)="getContactFormData()"
            [disabled]="createCSATContactForm.invalid">
            Submit
        </button>     
    </div>
</div>