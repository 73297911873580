import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ForecastNavigationBarComponent } from "./forecast-navigation-bar.component";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { LaborDetailsModule } from "../forecast-details/labor-details/labor-details.module";

@NgModule({
    declarations: [
        ForecastNavigationBarComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        LaborDetailsModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        ForecastNavigationBarComponent
    ],
    exports: [
        ForecastNavigationBarComponent
    ]
})
export class ForecastNavigationBarModule { }
