import { Pipe, PipeTransform, Inject } from "@angular/core";
import { DatePipe } from "@angular/common";

/**
 * Custom pipe/filter that will display the given date value formatted as dd-MMM-yyy
 * If the date does not exist, return -
 * If the date value passed in is 01-Jan-0001 then return -
 * If the passed in value is undefined, or "", or null, return -
 * Example: we originally write:
 * <span [innerHtml]="engagement.endDate | date:'dd-MMM-yyyy'"></span> or
 * <p class="pull-left" [innerHtml]="items.startDate ? (items.startDate | date:'dd-MMM-yyyy') : '-'"></p>
 * Now for both those cases, we can use:
 * <p [innerHtml]="items.endDate | dmDisplayDateOrDashPipe"></p>
 */
@Pipe({
    name: "dmDisplayDateOrDashPipe"
})
export class DmDisplayDateOrDashPipe implements PipeTransform {

    public constructor(@Inject(DatePipe) private datePipe: DatePipe) { }

    public transform(date: Date): string {
        if (!date) {
            return "-";
        }

        const dateString = this.datePipe.transform(date, "dd-MMM-yyyy");

        if (dateString === "01-Jan-0001") {
            return "-";
        } else {
            return dateString;
        }
    }
}


