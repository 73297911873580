import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DmComponentAbstract } from "./dm-component.abstract";
import { DMLoggerService } from "../services/dmlogger.service";
import { IChildComponent, IComponent } from "../application.constants";

/* Since this is an abstract component, it does not need the @Component annotation. */
export abstract class DmModalAbstract extends DmComponentAbstract {

    public constructor(
        protected activeModal: NgbActiveModal,
        protected dmLogger: DMLoggerService,
        protected component: IComponent,
        public children?: IChildComponent[]
    ) {
        super(dmLogger, component, children);
    }

    abstract ngOnInit(): void;

    /**
     * Logs closing modal telemetry and then closes the modal.
     * @param newSnapshotMsg optional object passed in from its chidren
     * @memberof DmModalAbstract
     */
    public closeModal(): void { // todo make an optional input
        this.activeModal.close();
    }

}