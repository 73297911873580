import { Component, Input } from "@angular/core";

export interface ILink {
    name: string;
    url: string;
    icon?: string;
    tooltipText: string;
}

export interface ITile { 
    title: string;    
    iconTitle?: string;
    itemsCount?: number;
    link?: ILink;
} 

@Component({
    selector: "dm-tile",
    templateUrl: "./dm-tile.html",
    styleUrls: ["./dm-tile.scss"],
})
export class DmTileComponent {
    @Input() public tileHeaderContent: ITile;
    @Input() public tileMinHeight: string = "auto";
    @Input() public tileHeight: string = "auto";
    @Input() public hasNonOverflowContent: boolean;
    @Input() public isV2Tile: boolean;
    @Input() public showHeader: boolean = true;
}
