import { ILoadableState } from "../reducers";
import { IExistingCrRequest } from "../../common/services/contracts/changerequest.contract";
import { WbsIdRolesActions, WbsIdRolesActionTypes } from "./wbs-id-roles.action";

// slice of wbsIdRoles state
export interface IWbsIdRolesState extends ILoadableState {
    wbsIdRoles: IExistingCrRequest;
}

export const initialState: IWbsIdRolesState = {
    wbsIdRoles: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: WbsIdRolesActions): { [engagementId: string]: IWbsIdRolesState } {
    switch (action.type) {
        case WbsIdRolesActionTypes.LOAD_WBS_ID_ROLES: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }
      
        case WbsIdRolesActionTypes.LOAD_WBS_ID_ROLES_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                wbsIdRoles: action.existingCrRequest,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case WbsIdRolesActionTypes.LOAD_WBS_ID_ROLES_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case WbsIdRolesActionTypes.INVALIDATE_WBS_ID_ROLES: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}