
import { switchMap, withLatestFrom, map, catchError } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { from as fromPromise, of } from "rxjs";

import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { IInvoiceItemModel } from "../../components/invoices/invoice-table-data/invoice-table-data.contract";
import * as invoicesActions from "./invoices.action";
import { IState } from "../reducers";

@Injectable()
export class InvoicesEffect {

    @Effect()
    public loadInvoices$ = this.actions$
        .ofType(invoicesActions.InvoicesActionTypes.LOAD_INVOICES).pipe(
            map((action: invoicesActions.LoadInvoices) => action.engagementId),
            withLatestFrom(this.store),
            switchMap(([engagementId, storeState]) => fromPromise(
                this.engagementDetailService.getInvoices(storeState.engagementDetails[engagementId].engagementDetails)
            )
                .pipe(
                    map((invoices: IInvoiceItemModel[]) => new invoicesActions.LoadInvoicesSuccess(engagementId, invoices)),
                    catchError((error) => of(new invoicesActions.LoadInvoicesFail(engagementId, error)))
                )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        private store: Store<IState>
    ) { }
}