import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { ManageSuppliersTableDataComponent } from "./manage-suppliers-table-data.component";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ManageSuppliersTransactionModalModule } from "../manage-suppliers-transaction-modal/manage-suppliers-transaction-modal.module";

@NgModule({
    declarations: [
        ManageSuppliersTableDataComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        ManageSuppliersTransactionModalModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService
    ],
    exports: [
        ManageSuppliersTableDataComponent
    ],
    entryComponents: [
        ManageSuppliersTableDataComponent
    ]
})
export class ManageSuppliersTableDataModule { }
