import { Component, Input } from "@angular/core";
import { IProgressBar, ProgressBarStatus } from "./dm-progress-bar.contracts";

@Component({
    selector: "dm-progress-bar",
    templateUrl: "./dm-progress-bar.html",
    styleUrls: ["./dm-progress-bar.scss"]
})
export class DmProgressBarComponent {
    @Input() public progressBarData: IProgressBar[] = [];
    public progressBarStatus: ProgressBarStatus;

    public constructor() {
        this.initializeData();
    }

    /**
     * TODO: Will be removed --> Initializes sample data
     *
     * @memberof DmProgressBarComponent
     */
    public initializeData(): void {
        if (!this.progressBarData || !this.progressBarData.length) {
            this.progressBarData = [{
                content: "Test 1",
                status: ProgressBarStatus.Completed
            },
            {
                content: "Test 2",
                status: ProgressBarStatus.Failed
            },
            {
                content: "Test 3",
                status: ProgressBarStatus.InProgress
            },
            {
                content: "Test 4",
                status: ProgressBarStatus.NotStarted
            }
            ];
        }
    }

    /**
     *  Get class by progress bar status
     *
     * @param {string} status
     * @returns
     * @memberof DmProgressBarComponent
     */
    public getClassByStatus(status: string): string {
        switch (status) {
            case ProgressBarStatus.Completed: return "completed";
            case ProgressBarStatus.Failed: return "failed";
            case ProgressBarStatus.NotStarted: return "not-started";
            case ProgressBarStatus.InProgress: return "in-progress";
            case ProgressBarStatus.Warning: return "warning";
        }
    }
    
    /**
     *  Get Icon by progress bar status
     *
     * @param {string} status
     * @returns
     * @memberof DmProgressBarComponent
     */
    public getIconByStatus(status: string): string {
        switch (status) {
            case ProgressBarStatus.Completed: return "icon-check";
            case ProgressBarStatus.Failed: return "icon-close";
            case ProgressBarStatus.NotStarted: return "icon-lock";
            case ProgressBarStatus.InProgress: return "icon-timer";
            case ProgressBarStatus.Warning: return "icon-warning";
        }
    }
}