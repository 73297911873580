import { Component, Inject, ViewChild } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components, KeyAction, LogEventName } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { KeyActionsV2Component } from "../key-actions-v2/key-actions-v2.component";
import { AuditService } from "../../../common/services/audit.service";
import { IAuditEntriesApiResponse, IAuditResponse, IWbsAuditValues } from "../../../common/services/contracts/audit.contracts";
import moment from "moment";

export interface IOption {
    name: string;
    itemCount?: number;
}
const WBS_AUDIT_TYPE = "Wbs";
@Component({
    selector: "dm-nav-bar-notification",
    templateUrl: "./nav-bar-notification.html",
    styleUrls: ["./nav-bar-notification.scss"]
})
export class NavBarNotificationComponent extends DmComponentAbstract {
    @ViewChild(KeyActionsV2Component, { static: true }) public keyActionsV2Component: KeyActionsV2Component;
    public listOfKeyActions: string[] = [];
    public LogEventName = LogEventName;
    public navigationOptions: IOption[] = [];
    public currentOption: IOption;
    public isProjectContext: boolean = false;
    public totalKeyActions: number = 0;
    public showNotifications: boolean = false;
    public selectedEngagementId: string;
    public wbsAuditEntries: IAuditResponse[] = [];

    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(AuditService) private auditService: AuditService
    ) {
        super(dmLogger, Components.KeyActionsV2);
    }

    public ngOnInit(): void {
        this.isProjectContext = this.sharedFunctionService.getSelectedProjectId(this.stateService) ? true : false;
        this.initializeVariables();
        this.auditService.getAuditHistory(this.selectedEngagementId, WBS_AUDIT_TYPE)
            .then((response: IAuditEntriesApiResponse) => {
                if (response && response.wbsAuditEntries && response.wbsAuditEntries.length > 0) {
                    let auditEntries = response.wbsAuditEntries;
                    auditEntries = this.getDistinctEntries(auditEntries);
                    this.sortAuditDetailsByCreatedDate(auditEntries);
                    this.wbsAuditEntries = auditEntries;
                }
            });
    }

    /**
     * Navigates to the new option
     */
    public naviagteToNewOption(attribute: IOption): void {
        this.currentOption = attribute;
    }

    /**
     * get the notification count
     */
    public getNotificationCount(): number {
        if (this.keyActionsV2Component) {
            this.totalKeyActions = this.keyActionsV2Component.expenseKeyActions.length +
            (this.keyActionsV2Component.pendingLaborApprovals ? this.keyActionsV2Component.pendingLaborApprovals.length : 0) +
            (this.keyActionsV2Component.totalUpcomingMileStone && this.keyActionsV2Component.milestoneDayDiff && this.keyActionsV2Component.isPrimaryProjectManager ? this.keyActionsV2Component.totalUpcomingMileStone : 0) +
            ((this.keyActionsV2Component.contractSignatureAfterEngagementStartDateKeyAction && this.keyActionsV2Component.contractSignatureAfterEngagementStartDateKeyAction.isContractSignatureAfterEngagementStartDate) ? 1 : 0) +
            this.keyActionsV2Component.engagementKeyActions.length + this.keyActionsV2Component.contractualMisalignmentKeyActions.length + this.keyActionsV2Component.expiringEcifIoKeyActions.length + (this.keyActionsV2Component.subconStatusKeyActions ? this.keyActionsV2Component.subconStatusKeyActions.length : 0);
            this.navigationOptions[0].itemCount = this.totalKeyActions;
        }
        return this.totalKeyActions;
    }

    /**
     * toggles the notification dropdown
     */
    public toggleNotifications(event?: { [key: string]: any}): void {
        if (event && event.currentTarget && event.currentTarget.className && event.currentTarget.className.includes("nav-notification")) {
            this.showNotifications = !this.showNotifications;
            this.sharedFunctionService.focus("noticationCloseButton001", true);
        }
    }

    /**
     * close the notification dropdown
     */
    public closeNotifications(event?: { [key: string]: any}): void {
        if (event) {
            this.sharedFunctionService.focus(
                "notification001",
                true
            );
        }
        this.showNotifications = false;
    }

    /**
     * Initialize Variables for the Component
     *
     * @memberof KeyActionsComponent
     */
    private initializeVariables(): void {
        this.selectedEngagementId = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        if (this.isProjectContext) {
            this.listOfKeyActions.push(KeyAction.PendingExpenseReportsBasedOnEntityId, KeyAction.PendingLaborBasedOnAssignments, KeyAction.PendingMilestonesForConfirmation, KeyAction.EngagementStartDateLessContractStartDate, KeyAction.ExpiringEcifIO, KeyAction.SubconOnboardingStatus);
        } else {
            this.listOfKeyActions.push(KeyAction.EngagementPendingForRelease, KeyAction.PendingExpenseReportsBasedOnEntityId, KeyAction.PendingLaborBasedOnAssignments, KeyAction.PendingMilestonesForConfirmation, KeyAction.EngagementStartDateLessContractStartDate, KeyAction.ActiveMisalignemdContractualAmendments, KeyAction.ExpiringEcifIO, KeyAction.SubconOnboardingStatus);
        }
        this.navigationOptions = [{
            name: "Key Actions",
            itemCount: 0
        },
        {
            name: "Activity Feed"
        }];
        this.currentOption = this.navigationOptions[0];
    }

    /**
     * Get distinct entries from wbsAuditEntries
     * @param wbsAuditEntries
     */

    private getDistinctEntries(wbsAuditEntries: IAuditResponse[]): IAuditResponse[] {
        const distinctEntries: IAuditResponse[] = [];
        const eventTypes: string[] = [];
        for (const wbsAuditEntry of wbsAuditEntries) {
            const eventType = (wbsAuditEntry.audit as IWbsAuditValues).eventType;
            if (eventTypes.indexOf(eventType) === -1) {
                eventTypes.push(eventType);
                distinctEntries.push(wbsAuditEntry);
            }
        }
        return distinctEntries;
    }

    /**
     * Sorts a Audit list by created in ascending order
     * @param list
     */
    private sortAuditDetailsByCreatedDate(list: IAuditResponse[]): void {
        if (list && list.length && list.length > 1) {
            list.sort((a, b): number => {
                const date1 = new Date(a.createdOn);
                const date2 = new Date(b.createdOn);
                if (moment(date1).isAfter(date2)) {
                    return 1;
                } else if (moment(date1).isBefore(date2)) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
    }
}
