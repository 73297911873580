<section id="tab-navigation">
    <section class="dm-navigation-header-section w-100">
        <div [ngClass]="isProjectContext ? 'entity-wrapper__project' : 'entity-wrapper__engagement'"
            class="entity-wrapper display-flex">
            <span [ngClass]="isProjectContext ? 'icon-dial-shape icon-project' : 'icon-cube-shape icon-engagement'"
                class="icon-full dm-p-x-5"></span>
            <div>
                <span class="dm-font-weight-500 dm-p-r-5">{{isProjectContext ? "Project":
                    "Engagement"}}</span>
                <span>{{isProjectContext ? projectDetails?.id :engagementDetails?.id}}</span>
            </div>
        </div>
        <div class="display-flex dm-p-t-5">
            <div role="heading" aria-level="2">
                <span class="icon icon-Back prev-icon cursor-pointer set-focus" title="My Portfolio Home Page" aria-label="Go Back to my Portfolio Home Page" tabindex="0"
                    role="button" (keyup.enter)="navigateToHomePage()" (keyup.Space)="navigateToHomePage()"
                    (click)="navigateToHomePage()"></span>
                <span class="entity-name">{{isProjectContext ? projectDetails?.name :
                    engagementDetails?.name}}</span>
            </div>
        </div>
        <!--This is commented to remove these details from header bar-->
        <!-- <div class="entity-details-container display-flex">
            <div>
                <span class="entity-label">Status</span>
                <span class="entity-value">{{isProjectContext ? projectDetails?.statusDescription :
                    engagementDetails?.statusDescription}}</span>
            </div>
            <div>
                <span class="entity-label">Customer</span>
                <span class="entity-value">{{isProjectContext ? projectDetails?.customerName :
                    engagementDetails?.customerName}}</span>
            </div>
            <div>
                <span class="entity-label">Domain</span>
                <span class="entity-value">{{isProjectContext ? projectDetails?.primaryDomain :
                    engagementDetails?.primaryDomain}}</span>
            </div>
            <div>
                <span class="entity-label">Country</span>
                <span class="entity-value">{{isProjectContext ? projectDetails?.customerCountry :
                    engagementDetails?.customerCountry}}</span>
            </div>
            <div *ngIf="!isInternalEngagement">
                <span class="entity-label">Type</span>
                <span class="contract-type-wrapper" *ngIf="!isProjectContext"
                    [ngClass]="{'contract-type-wrapper--ff': engagementDetails?.typeOfContract === 'FF', 'contract-type-wrapper--mixed': (engagementDetails?.typeOfContract !== ('FF' || 'T&M')), 'contract-type-wrapper--tm': engagementDetails?.typeOfContract === 'T&M'}">
                    {{engagementDetails?.typeOfContract}}
                </span>
                <span class="contract-type-wrapper" *ngIf="isProjectContext"
                    [ngClass]="{'contract-type-wrapper--ff': projectDetails?.contractType === 'FF', 'contract-type-wrapper--mixed': (projectDetails?.contractType !== ('FF' || 'T&M')), 'contract-type-wrapper--tm': projectDetails?.contractType === 'T&M'}">
                    {{projectDetails?.contractType}}
                </span>
            </div>
            <div>
                <span class="entity-label">Duration</span>
                <span class="entity-value" *ngIf="isProjectContext">{{projectDetails?.startDate |
                    dmDisplayDateOrDashPipe}} to {{projectDetails?.endDate | dmDisplayDateOrDashPipe}}</span>
                <span class="entity-value" *ngIf="!isProjectContext">{{engagementDetails?.startDate |
                    dmDisplayDateOrDashPipe}} to {{engagementDetails?.endDate | dmDisplayDateOrDashPipe}}</span>
            </div>
        </div> -->
    </section>
    <div role="menu" id="dm-main-tab-nav" *ngIf="tabObjects?.length" class="dm-tabs-wrapper">
        <ng-container *ngFor="let tabObject of tabObjects">
            <div *ngIf="tabObject.showTab" (click)="navigateToNewRoute(tabObject, $event)" role="menuitem"
                class="font-caption-alt item dm-tabs-item" style="display:inline-block"
                [ngClass]="currentTab ? {'active':tabObject.name===currentTab.name} : {}">
                <button class="tabNav" data-parent="#tab-navigation" [id]="tabObject.id"
                    [attr.data-target]="'#'+tabObject.id" tabindex="0" data-toggle="tab"
                    (keyup.arrowright)="focus(tabObject.id, 'right')" (keyup.arrowleft)="focus(tabObject.id, 'left')"
                    [attr.aria-label]="tabObject.name===currentTab?.name ? currentTab?.name+'selected' : tabObject.name+'Not selected'"
                    [textContent]="tabObject.name">
                </button>
                <!-- todo  (keydown)="keyNavigation({event:$event})" -->
            </div>
        </ng-container>
    </div>
</section>