import { Injectable, Inject } from "@angular/core";

import { IEngagementFinancialPlan, IProjectFinancialPlan, IProjectFinancialPlanResponse, IProjectRiskReserveData, IFinancialPlanResponse, IEngagementRiskReserveData } from "./contracts/financial-plan.contracts";
import { BaseLineType, FinancialType, Services } from "../application.constants";
import { ICostSummary } from "./contracts/cost-summary.contracts";
import { IEngagementFinancialPlanSummary, IProjectApprovedFinancial } from "./contracts/changerequest.contract";
import { IHoursSummary } from "./contracts/hours-summary.contracts";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class FinancialPlanService extends DmServiceAbstract {

    public constructor(
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.FinancialPlanService);
    }

    /**
     * GEt financial plan summary
     *
     * @param {IEngagementFinancialPlan} engagementFinancialPlan
     * @returns {IEngagementFinancialPlanSummary}
     * @memberof FinancialPlanService
     */
    public getFinancialPlanSummary(engagementFinancialPlan: IEngagementFinancialPlan[]): IEngagementFinancialPlanSummary {
        const contractBaselinePlanDetails = this.getEngagementFinancialPlansByType(engagementFinancialPlan, BaseLineType.ContractBaseline);
        const deliveryBaselinePlanDetails = this.getEngagementFinancialPlansByType(engagementFinancialPlan, BaseLineType.DeliveryBaseline);
        const currentFinancialPlanDetails = this.getEngagementFinancialPlansByType(engagementFinancialPlan, BaseLineType.CurrentFinancialPlan);

        // TODO: Add null checks
        const fcrFinancialSummary: IEngagementFinancialPlanSummary = {
            finalizedContractBaseline: {
                margin: contractBaselinePlanDetails.marginInPercentage,
                revenue: contractBaselinePlanDetails.revenue,
                cost: contractBaselinePlanDetails.cost,
                hours: contractBaselinePlanDetails.labor,
                status: "Finalized"
            },
            finalizedDeliveryBaseline: {
                margin: deliveryBaselinePlanDetails.marginInPercentage,
                revenue: deliveryBaselinePlanDetails.revenue,
                cost: deliveryBaselinePlanDetails.cost,
                hours: deliveryBaselinePlanDetails.labor,
                status: "Finalized"
            },
            revisedDeliveryBaseline: {
                margin: deliveryBaselinePlanDetails.marginInPercentage,
                revenue: deliveryBaselinePlanDetails.revenue,
                cost: deliveryBaselinePlanDetails.cost,
                hours: deliveryBaselinePlanDetails.labor
            },
            currentFinanicalPlan: {
                margin: currentFinancialPlanDetails.marginInPercentage,
                revenue: currentFinancialPlanDetails.revenue,
                cost: currentFinancialPlanDetails.cost,
                hours: currentFinancialPlanDetails.labor
            },
            revisedFinancialPlan: {
                margin: currentFinancialPlanDetails.marginInPercentage,
                revenue: currentFinancialPlanDetails.revenue,
                cost: currentFinancialPlanDetails.cost,
                hours: currentFinancialPlanDetails.labor
            },
            planId: contractBaselinePlanDetails.planId,
            planCurrency: contractBaselinePlanDetails.planCurrency,
            laborUnits: contractBaselinePlanDetails.laborUnits
        };
        return fcrFinancialSummary;
    }

    /**
     *  Gets project
     *
     * @param {IProjectFinancialPlanResponse} projectFinancials
     * @param {number} requestedHours
     * @returns {IHoursSummary}
     * @memberof FinancialPlanService
     */
    public getProjectHoursSummary(projectFinancials: IProjectFinancialPlanResponse, requestedHours: number): IHoursSummary {
        const projectFinancialsPlan = projectFinancials.projectPlanDetails;
        const contractBaselinePlanDetails = this.getProjectFinancialPlansByType(projectFinancialsPlan, BaseLineType.ContractBaseline);
        const deliveryBaselinePlanDetails = this.getProjectFinancialPlansByType(projectFinancialsPlan, BaseLineType.DeliveryBaseline);
        const hoursSummary: IHoursSummary = {
            projectId: projectFinancials.projectId,
            newHoursVariance: deliveryBaselinePlanDetails.labor + requestedHours,
            oldHoursVariance: deliveryBaselinePlanDetails.labor,
            approvalsNeeded: requestedHours,
            budgetHours: contractBaselinePlanDetails ? contractBaselinePlanDetails.labor : 0,
            hoursVariance: (deliveryBaselinePlanDetails.labor + requestedHours) - (contractBaselinePlanDetails ? contractBaselinePlanDetails.labor : 0)
        };
        return hoursSummary;
    }

    public getProjectCostSummary(projectFinancials: IProjectFinancialPlanResponse, requestedCost: number, approvedFinancials: IProjectApprovedFinancial[]): ICostSummary {
        const projectFinancialsPlan = projectFinancials.projectPlanDetails;
        const contractBaselinePlanDetails = this.getProjectFinancialPlansByType(projectFinancialsPlan, BaseLineType.ContractBaseline);
        const deliveryBaselinePlanDetails = this.getProjectFinancialPlansByType(projectFinancialsPlan, BaseLineType.DeliveryBaseline);
        const projectRiskReserveData = this.getProjectRiskReserveData(projectFinancials.projectId, approvedFinancials);
        const oldPlannedCost = (deliveryBaselinePlanDetails && deliveryBaselinePlanDetails.cost) - (deliveryBaselinePlanDetails && deliveryBaselinePlanDetails.riskReserveAmount);
        const grossCostOverRun = oldPlannedCost + requestedCost - ((contractBaselinePlanDetails ? contractBaselinePlanDetails.cost : 0) - (contractBaselinePlanDetails ? contractBaselinePlanDetails.riskReserveAmount : 0));
        const netCostOverrun = oldPlannedCost + requestedCost - (contractBaselinePlanDetails ? contractBaselinePlanDetails.cost : 0);
        const costSummary: ICostSummary = {
            projectId: projectFinancials.projectId,
            newPlannedCost: oldPlannedCost + requestedCost,
            oldPlannedCost,
            approvalsNeeded: requestedCost,
            budgetCostExcludingRiskReserve: (contractBaselinePlanDetails ? contractBaselinePlanDetails.cost : 0) - (contractBaselinePlanDetails ? contractBaselinePlanDetails.riskReserveAmount : 0),
            grossCostOverRun: grossCostOverRun < 0 ? 0 : grossCostOverRun,
            budgetCostIncludingRiskReserve: contractBaselinePlanDetails ? contractBaselinePlanDetails.cost : 0,
            netCost: netCostOverrun,
            remainingUnassignedRiskReserve: projectRiskReserveData ? projectRiskReserveData.totalRiskReserve - projectRiskReserveData.approvedRiskReserve : undefined,
        };
        return costSummary;
    }

    /**
     * Updates financial plan summary with revised db and cfp details.
     *
     * @param {IEngagementFinancialPlanSummary} fcrFinancialSummary
     * @param {number} totalAdditionalCost
     * @param {number} totalAdditionalHours
     * @param {number} totalAdditionalRevenue
     * @param {number} totalRevisedCfpCost
     * @param {number} totalExistingCfpCost
     * @param {number} [totalReconciledHours]
     * @returns {IEngagementFinancialPlanSummary}
     * @memberof FinancialPlanService
     */
    public getRevisedFinancialPlanSumamry(
        fcrFinancialSummary: IEngagementFinancialPlanSummary,
        totalAdditionalCost: number,
        totalAdditionalHours: number,
        totalAdditionalRevenue: number,
        totalRevisedCfpCost: number,
        totalExistingCfpCost: number,
        totalReconciledHours?: number): IEngagementFinancialPlanSummary {

        const finalizedDeliveryBaselineDetails = fcrFinancialSummary ? fcrFinancialSummary.finalizedDeliveryBaseline : undefined;
        const finalizedCfpDetails = fcrFinancialSummary ? fcrFinancialSummary.currentFinanicalPlan : undefined;

        // TODO: Add all null checks
        if (fcrFinancialSummary && fcrFinancialSummary.revisedDeliveryBaseline && finalizedDeliveryBaselineDetails) {
            fcrFinancialSummary.revisedDeliveryBaseline.cost = finalizedDeliveryBaselineDetails.cost + totalAdditionalCost;
            fcrFinancialSummary.revisedDeliveryBaseline.hours = finalizedDeliveryBaselineDetails.hours + totalAdditionalHours;
            fcrFinancialSummary.revisedDeliveryBaseline.revenue = finalizedDeliveryBaselineDetails.revenue + totalAdditionalRevenue;
            fcrFinancialSummary.revisedDeliveryBaseline.margin = fcrFinancialSummary.revisedDeliveryBaseline.revenue
                ? 100 * ((fcrFinancialSummary.revisedDeliveryBaseline.revenue - fcrFinancialSummary.revisedDeliveryBaseline.cost) / fcrFinancialSummary.revisedDeliveryBaseline.revenue) : 0;
        }

        if (fcrFinancialSummary && fcrFinancialSummary.revisedFinancialPlan && finalizedCfpDetails) {
            fcrFinancialSummary.revisedFinancialPlan.cost = finalizedCfpDetails.cost - totalExistingCfpCost + totalRevisedCfpCost;
            fcrFinancialSummary.revisedFinancialPlan.hours = finalizedCfpDetails.hours + totalAdditionalHours - (totalReconciledHours ? totalReconciledHours : 0);
            fcrFinancialSummary.revisedFinancialPlan.revenue = finalizedCfpDetails.revenue + totalAdditionalRevenue;
            fcrFinancialSummary.revisedFinancialPlan.margin = fcrFinancialSummary.revisedFinancialPlan.revenue
                ? 100 * ((fcrFinancialSummary.revisedFinancialPlan.revenue - fcrFinancialSummary.revisedFinancialPlan.cost) / fcrFinancialSummary.revisedFinancialPlan.revenue) : 0;
            return fcrFinancialSummary;
        }
    }

    /**
     * Gets/filters engagement financial plans based on financials type version id
     *
     * @param {IEngagementFinancialPlan[]} engagementFinancialPlan
     * @param {string} financialsType
     * @returns {IEngagementFinancialPlan}
     * @memberof FinancialPlanService
     */
    public getEngagementFinancialPlansByType(engagementFinancialPlan: IEngagementFinancialPlan[], financialsType: string): IEngagementFinancialPlan {
        const filteredFinancialsPlan = engagementFinancialPlan.filter((plan) => plan.planVersionId === financialsType);
        if (filteredFinancialsPlan && filteredFinancialsPlan.length) {
            return filteredFinancialsPlan[0];
        }
    }

    /**
     * Gets/filters project financial plans based on financials type version id
     *
     * @param {IProjectFinancialPlan[]} projectFinancialsPlan
     * @param {string} financialsType
     * @returns {IProjectFinancialPlan}
     * @memberof FinancialPlanService
     */
    public getProjectFinancialPlansByType(projectFinancialsPlan: IProjectFinancialPlan[], financialsType: string): IProjectFinancialPlan {
        const filteredFinancialsPlan = projectFinancialsPlan.filter((plan) => plan.planVersionId === financialsType);
        if (filteredFinancialsPlan && filteredFinancialsPlan.length) {
            return filteredFinancialsPlan[0];
        }
    }

    /**
     * Add approved risk reserve to contract baseline plan data.
     *
     * @param {IFinancialPlanResponse} financialPlanData
     * @param {IProjectApprovedFinancial[]} approvedFinancialData
     * @returns {IFinancialPlanResponse}
     * @memberof FinancialPlanService
     */
    public addApprovedFinancialDataToFinancialPlan(financialPlanData: IFinancialPlanResponse, approvedFinancialData: IProjectApprovedFinancial[]): IFinancialPlanResponse {
        const financialPlanWithApprovedFinancials = { ...financialPlanData };

        for (const projectFinancialPlan of financialPlanWithApprovedFinancials.projects) {
            const projectRiskReserveData = this.getProjectRiskReserveData(projectFinancialPlan.projectId, approvedFinancialData);

            const contractBaselinePlan = this.getProjectFinancialPlansByType(projectFinancialPlan.projectPlanDetails, FinancialType.ContractBaseline);
            if (contractBaselinePlan) {
                contractBaselinePlan.approvedRiskReserve = projectRiskReserveData.approvedRiskReserve;
            }
        }

        return financialPlanWithApprovedFinancials;
    }

    /**
     * Get engagement level total and approved risk reserve amounts from financial plan data.
     *
     * @param {IFinancialPlanResponse} financialPlanData
     * @returns {IEngagementRiskReserveData}
     * @memberof FinancialPlanService
     */
    public getEngagementRiskReserveFromFinancialPlan(financialPlanData: IFinancialPlanResponse): IEngagementRiskReserveData {
        let engagementTotalRiskReserve: number = 0;
        let engagementApprovedRiskReserve: number = 0;

        for (const projectFinancialPlan of financialPlanData.projects) {
            const contractBaselinePlan = this.getProjectFinancialPlansByType(projectFinancialPlan.projectPlanDetails, FinancialType.ContractBaseline);

            if (contractBaselinePlan) {
                if (contractBaselinePlan.approvedRiskReserve) {
                    engagementApprovedRiskReserve = engagementApprovedRiskReserve + contractBaselinePlan.approvedRiskReserve;
                }

                if (contractBaselinePlan.riskReserveAmount) {
                    engagementTotalRiskReserve = engagementTotalRiskReserve + contractBaselinePlan.riskReserveAmount;
                }
            }
        }

        return { totalRiskReserve: engagementTotalRiskReserve, approvedRiskReserve: engagementApprovedRiskReserve };
    }

    /**
     * Get project risk reserve data from task financial data.
     *
     * @private
     * @param {string} projectId
     * @param {IProjectApprovedFinancial[]} approvedFinancials
     * @returns {IProjectRiskReserveData}
     * @memberof FinancialPlanService
     */
    private getProjectRiskReserveData(projectId: string, approvedFinancials: IProjectApprovedFinancial[]): IProjectRiskReserveData {
        let projectTotalRiskReserve: number = 0;
        let projectApprovedRiskReserve: number = 0;

        for (const projFinancials of approvedFinancials) {
            if (projFinancials.wbsId === projectId) {
                projectTotalRiskReserve = projectTotalRiskReserve + projFinancials.totalRiskReserve;
                projectApprovedRiskReserve = projectApprovedRiskReserve + projFinancials.approvedRiskReserve;
            }
        }

        return { totalRiskReserve: projectTotalRiskReserve, approvedRiskReserve: projectApprovedRiskReserve };
    }
}