<section class="dm-add-rules">
  <section class="pull-left col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-p-y-imp-10">
    <p role="alert" class="font-caption-alt inp-validation error-msg error-msg-color" aria-atomic="true"
      aria-live="polite" *ngIf="showRolesRequiredMessage">
      Role Details are required. Please add a role.
    </p>
  </section>
  <section class="col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad dm-p-x-imp-0">
    <button type="button" (click)="openAddRolesModal()" class="fxp-btn fxp-btn--primary">
      <i class="icon-AddLegacy icon"></i>
      Add Role
    </button>
  </section>
  <section class="col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad table-responsive dm-p-x-imp-0">
    <table class="font-caption-alt" width="100%">
      <caption class="sr-only">Added Roles</caption>
      <thead>
        <tr>
          <th scope="col" class="w-20 pd-l-70 text-left"> Role </th>
          <th scope="col" class="w-9 text-left"> Start Date </th>
          <th scope="col" class="w-9 text-left"> End Date </th>
          <th scope="col" class="w-14 text-left pd-r-20">
            Planned/Requested
            <br>
            Labor(hours)
          </th>
          <th scope="col" class="w-15 pd-l-20 text-left"> Skills </th>
          <th scope="col" class="w-18 text-left"> Requested Resources </th>
          <th scope="col" class="text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let roles of newRoles">
          <th scope="row" class="display-flex-imp">
            <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
              [style.visibility]="!isEmptyObject(roles?.errorDetails) && roles?.errorDetails ? 'visible' : 'hidden'"
              [ngbTooltipContent]="rolesErrorDetailsPopup" [dmIcon]="'icon icon-exclamation icon-rounded dm-error'"
              ariaLabelToolTip="Roles error details">
            </dm-tooltip>

            <ng-template #rolesErrorDetailsPopup>
              <div id="roleErrorDetailsPopover" class="type-dialogBox">
                <ul>
                  <!--TODO: errors will be moved to components and mapped there-->
                  <li *ngIf="roles?.errorDetails?.StartDate">{{roles?.errorDetails?.StartDate}}</li>
                  <li *ngIf="roles?.errorDetails?.IsAutoCommit">{{roles?.errorDetails?.IsAutoCommit}}</li>
                  <li *ngIf="roles?.errorDetails?.EndDate">{{roles?.errorDetails?.EndDate}}</li>
                  <li *ngIf="roles?.errorDetails?.Role">{{roles?.errorDetails?.Role}}</li>
                  <li *ngIf="roles?.errorDetails?.RequestedHours">{{roles?.errorDetails?.RequestedHours}}</li>
                  <li *ngIf="roles?.errorDetails?.DeliveryType">{{roles?.errorDetails?.DeliveryType}}</li>
                  <li *ngIf="roles?.errorDetails?.RequestedResourceBpId">{{roles?.errorDetails?.RequestedResourceBpId}}
                  </li>
                  <li *ngIf="roles?.errorDetails?.RequestedResourceBusinessDomain">
                    {{roles?.errorDetails?.RequestedResourceBusinessDomain}}</li>
                  <li *ngIf="roles?.errorDetails?.RequestedResourceIsTrainingCompleted">
                    {{roles?.errorDetails?.RequestedResourceIsTrainingCompleted}}</li>
                  <li *ngIf="roles?.errorDetails?.Skills">{{roles?.errorDetails?.Skills}}</li>
                  <li *ngIf="roles?.errorDetails?.SkillsDomain">{{roles?.errorDetails?.SkillsDomain}}</li>
                  <li *ngIf="roles?.errorDetails?.ShouldFreeResourceCalendar">
                    {{roles?.errorDetails?.ShouldFreeResourceCalendar}}</li>
                </ul>
              </div>
            </ng-template>
            <button [style.visibility]="roles.roleSkills.length>1 ? 'visible' : 'hidden'" type="button"
              class="pull-left role-accordionbtn" (click)="roles.roleAccordionFlag=!roles.roleAccordionFlag"
              aria-label="Role details" [attr.aria-expanded]="roles.roleAccordionFlag">
              <span class="icon" [ngClass]="[roles.roleAccordionFlag ? 'icon-chevron-up': 'icon-chevron-down']"></span>
              <span class="sr-only">Role details</span>
            </button>
            <div class="dm-p-x-10-y-5 pd-t-0 pd-l-20">
              <span *ngIf="roles.isSpecialRole || roles.isResourceScheduleFree" tabindex="0" class="autoCommit-icon"
                title="Autocommit role"></span>
              {{roles.roleName}}
            </div>
          </th>
          <td class="w-8">
            <span
              [attr.aria-label]="roles.roleStartDate?(roles.roleStartDate | date : 'dd MMMM yyyy'):'Role start date not available'">{{roles.roleStartDate
                            | dmDisplayDateOrDashPipe}}</span>
          </td>
          <td class="w-8">
            <span
              [attr.aria-label]="roles.roleEndDate?(roles.roleEndDate | date : 'dd MMMM yyyy'):'Role end date not available'">{{roles.roleEndDate
                            | dmDisplayDateOrDashPipe}}</span>
          </td>
          <td class="w-11 pd-r-20">
            <span>{{roles.roleRequestedHours}}</span>
          </td>
          <td class="w-30 pd-l-20 pd-r-20" [ngClass]="{'expanded-view' : !roles.roleAccordionFlag}">
            <span *ngFor="let roles of roles?.roleSkills; let last = last">
              <span [title]="roles.JobSkillValue">{{roles.JobSkillValue}}</span>
              <span *ngIf="!last">,</span>
            </span>
          </td>
          <td class="w-15"> <span>{{ roles && roles.roleRequestedResource &&
                            roles.roleRequestedResource.userName? roles.roleRequestedResource.userName : '-'}}</span>
          </td>
          <td>
            <button type="button" class="action-btn add-btn font-caption-alt"
              (click)="openAddRolesModal(roles, 'edit')" aria-label="edit button press tab to navigate to delete button">
              <i class="icon icon-editLegacy"></i>
              <span>Edit</span>
            </button>
            <button type="button" class="action-btn icon-close" (click)="deleteRole(roles)">
              <i class="icon icon-close"></i>
              <span>Delete</span>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="totalLaborHours > 0">
        <tr>
          <td class="pd-l-70">Total Requested Hours</td>
          <td><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
          <td><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
          <td [ngClass]="totalLaborHours > approvedNbueHours ? 'pd-r-0' : 'pd-r-20'">
            <span [ngClass]="showNBUEHoursErrorMsg ? 'red-legend-text' : 'legend-text'">
              {{totalLaborHours}}
            </span>
            <i *ngIf="showNBUEHoursErrorMsg" class="red-legend"></i>
          </td>
          <td [ngClass]="{'pd-l-20' : totalLaborHours > approvedNbueHours}">
            <span *ngIf="showNBUEHoursErrorMsg" class="caption-small red-legend-text">
              Total Requested hours exceeds Approved NBUE Hours.
            </span>
          </td>
          <td><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
          <td><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
          <td><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
        </tr>
      </tfoot>
    </table>
  </section>
  <section class="col-sm-12 col-md-12 col-xs-12 font-title text-center pd-20" *ngIf="!newRoles.length">
    No Roles Created
  </section>
  <span *ngIf="addRoleInformation" role="alert" class="sr-only">{{addRoleInformation}}</span>
</section>