<div class="dm-hidden-placeholder"></div>
<section class="row dm-m-imp-0 dm-my-portfolio">
    <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-l-imp-12 dm-p-r-imp-24 dm-m-b-12">
        <section class="col-md-6 col-sm-6 col-xs-6 dm-p-imp-0 dm-m-r-12">
            <recently-viewed>
            </recently-viewed>
        </section>
        <section class="col-md-6 col-sm-6 col-xs-6 dm-p-imp-0">
            <dm-key-actions-v2 *ngIf="listOfKeyActions.length > 0" [listOfActions]="listOfKeyActions">
            </dm-key-actions-v2>
        </section>
    </section>
    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-12-y-imp-0">
        <dm-customer-engagement-grid-data></dm-customer-engagement-grid-data>
    </section>
    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-12 dm-m-b-30">
        <dm-internal-engagement-grid-data-v2></dm-internal-engagement-grid-data-v2>
    </section>
</section>