import { Component, Input, Inject, forwardRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { Components, AccessibilityConstants, NotificationEvent } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfigManagerService } from "../../../../../../common/services/configmanager.service";
import { DMNotificationService, NotificationModel } from "../../../../../../common/services/dmnotification.service";
import { FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { IState } from "../../../../../../store/reducers";
import { Store } from "@ngrx/store";
import { getEntireEngagementDetails } from "../../../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../../../store/engagement-details/engagement-details.reducer";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IFailureMessages, IOnFailure, IOnSuccess, ISuccessMessages } from "../../../../../../common/services/contracts/financial.service.contracts";
import { IDistributionListObject } from "../../../../../../common/services/contracts/wbs.service.contracts";
import moment from "moment";
import { IModal } from "../../../../../../components/modals/dm-modal-v2/dm-modal-v2.component";

@Component({
    selector: "app-io-enddate-extension-modal",
    templateUrl: "./io-enddate-extension-modal.component.html",
    styleUrls: ["./io-enddate-extension-modal.component.scss"],
})

export class EcifIOEndDateExtensionModalComponent extends DmModalAbstract {
    @Input() public engagementId: string;
    @Input() public wbsId: string;
    @Input() public ioNumber: string;
    @Input() public currentEndDate: Date;

    public isLoading: boolean = true; // todo remove these, they're already declared in parent component
    public showLoading: boolean = true;
    public accessibilityConstants = AccessibilityConstants;
    public notificationMessage: any;
    public extendIoEndDateSendTo: string[];
    public extendIoEndDateForm: FormGroup;
    public newIoEndDate: Date;
    public isSaveButtonDisabled: boolean;
    public modalContent: IModal;

    private notificationSuccessMessages: IOnSuccess;
    private notificationFailureMessages: IOnFailure;
    private FXP_CONSTANTS = FxpConstants;




    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(Store) private store: Store<IState>,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
    ) {
        super(activeModal, dmLogger, Components.ECIFIOEndDateExtension);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "IO End Date Extension Notification"
        };
        this.newIoEndDate = this.currentEndDate;
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.configurationService.initialize().then(() => {
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
            this.extendIoEndDateSendTo = this.configurationService.getValue<string[]>("extendIoEndDateSendTo");
            this.notificationSuccessMessages = this.configurationService.getValue<ISuccessMessages>("SuccessMessages").ExtendIoEndDate;
            this.notificationFailureMessages = this.configurationService.getValue<IFailureMessages>("FailureMessages").ExtendIoEndDate;
        });

    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseClinModal);
        }
    }

    /**
     * Send email notification to extend io end date
     */
    public sendNotification(): void {
        const userAlias = this.fxpUserInfoService.getCurrentUser();

        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
            if (engagementDetails.loaded) {
                const notification = new NotificationModel();                
                notification.engagementId = this.engagementId;
                notification.engagementName = engagementDetails.engagementDetails.name;
                const currentEndDateString = this.currentEndDate.toString();
                const newIoEndDateString = this.newIoEndDate.toString();
                notification.attributes = {
                    WbsId: this.wbsId,
                    IoNumber: this.ioNumber,
                    CurrentIoEndDate: currentEndDateString,
                    NewIoEndDate: newIoEndDateString
                };
                notification.eventName = NotificationEvent.ExtendIoEndDate;
                const securityGroups: IDistributionListObject[] = this.configurationService.getDistributionList(engagementDetails.engagementDetails.isConfidential);                
                let sendTo: string[] = [];
                let pjc: string[] = [];
                const cc: string[] = this.sharedFunctionsService.getListofPjmV2(engagementDetails.engagementDetails)  // gets project managers and delegated project managers for the engagement and its children projects
                    .concat(this.sharedFunctionsService.getListofAdPjmV2(engagementDetails.engagementDetails))  // gets additional project managers for the engagement and its children projects
                    .concat(userAlias);

                if (engagementDetails.engagementDetails.companyCode) {
                    const secGroup = securityGroups.filter((x) => x.CompanyCode === Number(engagementDetails.engagementDetails.companyCode))[0];
                    if (secGroup && secGroup.SecurityGroup) {
                        pjc = pjc.concat(secGroup.SecurityGroup);
                    }
                }
                if (!pjc.length) {
                    const defaultSecurityGroup: string = securityGroups.filter((x) => x.CompanyCode === 0)[0].SecurityGroup;
                    pjc.push(defaultSecurityGroup);
                }                
                const relManager = engagementDetails.engagementDetails.relManager ? engagementDetails.engagementDetails.relManager.alias : undefined;
                sendTo = sendTo.concat(relManager, pjc);
                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);                
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(cc);
                notification.modifiedBy = userAlias;
                notification.modifiedDate = moment.utc().toDate();
                notification.subject = `Request for extension of ECIF IO [${this.ioNumber}]`;
                this.notificationService.sendNotification(notification, true).then(() => {
                    this.fxpMessageService.addMessage(this.notificationSuccessMessages.OnSuccess, this.FXP_CONSTANTS.messageType.success);
                    this.closeModal();
                })
                    .catch(() => {
                        this.fxpMessageService.addMessage(this.notificationFailureMessages.OnFailure, this.FXP_CONSTANTS.messageType.error);
                    });

            } else {
                this.fxpMessageService.addMessage(this.notificationFailureMessages.OnFailure, this.FXP_CONSTANTS.messageType.error);
            }
        });
    }
}

