import { Component, Input, OnInit, Inject, OnDestroy, Injector } from "@angular/core";
import { IRoleDetail } from "../../../common/services/contracts/add-roles.contracts";
import { AddRoleModalComponent } from "./modals/add-role-modal.component";
import { AddRolesService } from "../../../common/services/add-roles.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { InternalEngagementType } from "../new-internal-engagement.contracts";
import { Subscription } from "rxjs";
import { InternalEngagementService } from "../../../common/services/internal-engagement-service";
import { ResourceRolesService } from "../../../common/services/resource-roles.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { AccessibilityConstants } from "../../../common/application.constants";

const unitBasedTypeCode: string = "97";
const msInternalTypeCode: string = "96";
const ecifPreSalesTypeCode: string = "Z2";
/* Left for reference
const srOutCorpHQTypeCode: number = 95;
const srOutOtherBGTypeCode: number = 98;
*/

@Component({
    selector: "dm-add-roles",
    templateUrl: "./add-roles.html",
    styleUrls: ["./add-roles.scss"]
})
export class AddRolesComponent implements OnInit, OnDestroy {

    @Input() public approvedNbueHours: number;
    @Input() public engStartDate: Date;
    @Input() public engEndDate: Date;
    @Input() public projectDomain: string;
    @Input() public engType: string;
    @Input() public engTypeCode: string;
    @Input() public isRoleDateRestrictionDisabled: boolean = false;
    @Input() public showRolesRequiredMessage: boolean = false;
    @Input() public selectedCompanyCode: string;
    @Input() public set newRoles(value: IRoleDetail[]) {
        this.addMissingRoleIndicatorsForBulk(value);

        this.newRolesValue = value;
        this.calcHoursAndValidate();
    }
    public get newRoles(): IRoleDetail[] {
        return this.newRolesValue;
    }
    public totalLaborHours: number = 0;
    public showNBUEHoursErrorMsg: boolean = false;
    public newRolesDetails: IRoleDetail[] = [];
    public addRoleInformation: string;
    public editRoleSubscription: Subscription;
    public accessibilityConstants = AccessibilityConstants;
    private newRolesValue: IRoleDetail[] = [];
    private nbueSubscription: Subscription;

    public constructor(
        @Inject(AddRolesService) private addRolesService: AddRolesService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(InternalEngagementService) private internalEngagementService: InternalEngagementService,
        @Inject(ResourceRolesService) private resourceRolesService: ResourceRolesService,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
        @Inject(Injector) private injector: Injector
    ) { }

    public ngOnInit(): void {
        this.addRolesService.updatingEngStartDate(this.engStartDate);
        this.addRolesService.updatingengEndDate(this.engEndDate);
        this.addRolesService.updatingprojectDomain(this.projectDomain);
        this.addRolesService.updatingengType(this.engType);
        this.addRolesService.updatingapprovedNbueHours(this.approvedNbueHours);
        this.addRolesService.updatingnewRoles(this.newRoles);
        this.addRolesService.updateSelectedCompanyCode(this.selectedCompanyCode);
    }

    public ngOnDestroy(): void {
        if (this.editRoleSubscription) {
            this.editRoleSubscription.unsubscribe();
        }
        if (this.nbueSubscription) {
            this.nbueSubscription.unsubscribe();
        }
    }

    /**
     * Open add/edit role modal popup
     * @param roleData
     * @param roletype
     */
    public openAddRolesModal(roleData?: IRoleDetail, roletype?: string): void {
        const modalRef: NgbModalRef = this.modalService.open(AddRoleModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal edit-engagement add-role-int-eng",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.roleDetailForEdit = roleData;
        modalRef.componentInstance.addedRoleDetails = this.newRoles;
        modalRef.componentInstance.engStartDate = this.engStartDate;
        modalRef.componentInstance.engEndDate = this.engEndDate;
        modalRef.componentInstance.projectDomain = this.projectDomain;
        modalRef.componentInstance.engType = this.engType;
        modalRef.componentInstance.engTypeCode = this.engTypeCode;
        modalRef.componentInstance.isRoleDateRestrictionDisabled = this.isRoleDateRestrictionDisabled;
        modalRef.componentInstance.selectedCompanyCode = this.selectedCompanyCode;
        this.nbueSubscription = this.internalEngagementService.updatedNbue$.subscribe(
            (updatedNbueHours: number) => {
                this.approvedNbueHours = updatedNbueHours;
                this.calcHoursAndValidate();
            });
        this.editRoleSubscription = modalRef.componentInstance.onRoleEditCompleted.subscribe((roleSequenceNumber: number) => {
            // TODO: Validation error handling/removal logic should be extracted to a service and cleaned up.
            // Remove skill or resource error if it exists after successful edit
            const roleErrorToRemove: IRoleDetail = this.newRoles.filter((role: IRoleDetail) => role.roleSequence === roleSequenceNumber)[0];
            if (roleErrorToRemove && roleErrorToRemove.errorDetails && roleErrorToRemove.errorDetails.Skills) {
                delete roleErrorToRemove.errorDetails.Skills;
            }
            if (roleErrorToRemove && roleErrorToRemove.errorDetails && roleErrorToRemove.errorDetails.SkillsDomain) {
                delete roleErrorToRemove.errorDetails.SkillsDomain;
            }
            if (roleErrorToRemove && roleErrorToRemove.errorDetails && roleErrorToRemove.errorDetails.RequestedResourceBusinessDomain) {
                delete roleErrorToRemove.errorDetails.RequestedResourceBusinessDomain;
            }
            if (roleErrorToRemove && roleErrorToRemove.errorDetails && roleErrorToRemove.errorDetails.ShouldFreeResourceCalendar) {
                delete roleErrorToRemove.errorDetails.ShouldFreeResourceCalendar;
            }
            if (roleErrorToRemove && roleErrorToRemove.errorDetails && roleErrorToRemove.errorDetails.RequestedResourceIsTrainingCompleted) {
                delete roleErrorToRemove.errorDetails.RequestedResourceIsTrainingCompleted;
            }
            if (roleErrorToRemove && roleErrorToRemove.errorDetails && roleErrorToRemove.errorDetails.RequestedResourceBpId) {
                delete roleErrorToRemove.errorDetails.RequestedResourceBpId;
            }
        });
        if (roletype !== undefined) {
            modalRef.componentInstance.roletype = roletype;
        }
        modalRef.result.then(() => { // todo why do we have multiple modalRef.result.then?
            this.calcHoursAndValidate();
        });
        modalRef.result.then(() => {
            this.addRolesService.getNewRoles(this.newRoles);
            if (this.newRoles && this.newRoles.length) {
                this.showRolesRequiredMessage = false;
            } else {
                this.showRolesRequiredMessage = true;
            }
            let newRoleName = "";
            if (this.newRoles && this.newRoles.length > 0) {
                newRoleName = this.newRoles[this.newRoles.length - 1].roleName;
            }
            const roleName = (roletype === "edit") ? roleData.roleName : newRoleName;
            this.addRoleInformation = roleName + " table saved successfully";
        });
        this.addRoleInformation = "";
    }

    /**
     * Delete Role from list
     * @param roleData
     */
    public deleteRole(roleData: IRoleDetail): void {
        this.newRoles.splice(this.newRoles.findIndex((role: IRoleDetail) => role.roleSequence === roleData.roleSequence), 1);
        this.calcHoursAndValidate();
        if (document.getElementById("cancelInternalEngagementProject")) {
            this.sharedFunctionService.focus("cancelInternalEngagementProject", true);
        } else {
            this.sharedFunctionService.focus("cancelInternalEngagementCreation", true);
        }
    }


    /**
     * Checks if object is empty and returns true
     *
     * @private
     * @param {*} obj
     * @returns
     * @memberof AddRolesComponent
     */
    public isEmptyObject(obj: object): boolean {
        if (obj) {
            return (obj && (Object.keys(obj).length === 0)) ? true : false;
        }
        return true;
    }

    /**
     * Show total labor hours
     */
    private calcHoursAndValidate(): void {
        this.totalLaborHours = 0;
        this.newRoles.forEach((role: IRoleDetail) => {
            this.totalLaborHours = this.totalLaborHours + role.roleRequestedHours;
        });

        // Should use engagement type code rather than type string for comparisons in future as strings are not coming as expected
        if (this.engTypeCode) {
            this.showNBUEHoursErrorMsg = (this.totalLaborHours > this.approvedNbueHours)
                && !this.sharedFunctionService.isSrOutEngagementBasedOnCreationCode(this.engTypeCode.toString())
                && (this.engTypeCode !== unitBasedTypeCode)
                && (this.engTypeCode !== ecifPreSalesTypeCode)
                && !(this.engTypeCode === msInternalTypeCode && this.approvedNbueHours === 0);
        } else {
            this.showNBUEHoursErrorMsg = (this.totalLaborHours > this.approvedNbueHours)
                && (this.engType !== InternalEngagementType.srOutCorpHQ)
                && (this.engType !== InternalEngagementType.srOutOtherBG)
                && (this.engType !== InternalEngagementType.srOutFedConsulting)
                && (this.engType !== InternalEngagementType.srOutFedSupport)
                && (this.engType !== InternalEngagementType.ecifPreSales)
                && (this.engType !== InternalEngagementType.unitBased)
                && !(this.engType === InternalEngagementType.msInternal && this.approvedNbueHours === 0);
        }
    }

    /**
     * Add missing indicators (role sequence number, isSpecialRole) to all roles provided to the component.
     * Useful in bulk upload scenario where the roles are given with some 
     * missing identifiers which are needed for proper display.
     *
     * @private
     * @param {IRoleDetail[]} roles
     * @memberof AddRolesComponent
     */
    private addMissingRoleIndicatorsForBulk(roles: IRoleDetail[]): void {
        roles.forEach((item, index) => {
            if (!item.roleSequence) {
                item.roleSequence = index;
            }
            if (!item.isSpecialRole) {
                item.isSpecialRole = this.resourceRolesService.isRoleAutoCommit(item, this.engTypeCode);
            }
        });
    }

    // /**
    //  * Get role details
    //  */
    // private getRoleDetails(): ICreateInternalEngRoleData[] {
    //     const tempRoleList: ICreateInternalEngRoleData[] = [];
    //     this.newRolesDetails.forEach((newRole: IRoleDetail) => {
    //         const tempRole: ICreateInternalEngRoleData = {
    //             role: newRole.rolePartNumber,
    //             startDate: newRole.roleStartDate,
    //             endDate: newRole.roleEndDate,
    //             requestedHours: newRole.roleRequestedHours
    //         };
    //         tempRoleList.push(tempRole);
    //     });

    //     return tempRoleList;
    // }

    // /**
    //  * Get role skills
    //  * @param role
    //  */
    // private getRoleSkills(role: IRoleDetail): ISkill[] {
    //     const tempSkillsList: ISkill[] = [];
    //     if (role.roleSkills && role.roleSkills.length) {
    //         role.roleSkills.forEach((skill, key) => {
    //             const tempSkill: ISkill = {
    //                 OneProfileJobSkillId: skill.JobSkillId,
    //                 OneProfileSkillName: skill.JobSkillValue.indexOf("(Retiring)") ? skill.JobSkillValue.replace("(Retiring)", "").trim() : skill.JobSkillValue,
    //                 ProficiencyLevel: 3,
    //                 IsPrimary: (key === 0) ? true : false
    //             };
    //             tempSkillsList.push(tempSkill);
    //         });
    //     }

    //     return tempSkillsList;
    // }

}
