import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AdvancedSearchFilterModalComponent } from "./advanced-search-filter-modal.component";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        AdvancedSearchFilterModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService
    ],
    exports: [
        AdvancedSearchFilterModalComponent
    ],
    entryComponents: [
        AdvancedSearchFilterModalComponent
    ]
})
export class AdvancedSearchFilterModalModule { }