import { take } from "rxjs/operators";
import { Injectable, Inject, forwardRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { ErrorSeverityLevel, UserInfoService } from "@fxp/fxpservices";

import { APIConstants, Services, SourceConstants } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";
import { IProjectList as IProjectListPortfolio } from "./contracts/portfolio.model";
import { IWbsStructure, IProject, ITaskStructure } from "./contracts/wbsStructures.contracts";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { IGRMUpdateResponse, IRejectResponseDomainData, IResourceRequestResponse, IProjectRequest, IResourceRequest, IPendingResourceDetails } from "./contracts/staffing.service.contract";

import { getEntireResourceRequestsProjectContextStateObject } from "../../store/resource-requests-project-context/resource-requests-project-context.selector";
import { getInitialWbsStructures } from "../../store/wbs-structures/wbs-structures.selector";
import { getMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { getDemandsState } from "../../store/demands/demands.selector";
import { IMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IResourceRequestsDetailsProjectState } from "../../store/resource-requests-project-context/resource-requests-project-context.reducer";
import { IState } from "../../store/reducers";
import { IDemandsState } from "../../store/demands/demands.reducer";
import { IWbsStructuresState } from "../../store/wbs-structures/wbs-structures.reducer";
import { LoadResourceRequestsProjectContext, InvalidateResourceRequestsProjectContext } from "../../store/resource-requests-project-context/resource-requests-project-context.action";
import { LoadDemands, InvalidateDemands } from "../../store/demands/demands.action";
import { IWbsDemand, IDemandDetails, IResourceData } from "./contracts/project.service.v2.contracts";
import { IStaffingDemandModel, IStaffingResource } from "./contracts/staffing-details.contract";
import { IProjectDetailsV2 } from "./contracts/wbs-details-v2.contracts";
import { DMAuthorizationService } from "./dmauthorization.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
const CREATED_STATUSCODE = "CRTD";

export interface IDemandSourceObject {
    projectId: string;
    compassOnePackageId: string;
}

@Injectable()
export class StaffingService extends DmServiceAbstract {

    public grmRequestList: IResourceRequestResponse;
    public domainData: IRejectResponseDomainData;

    private grmBaseUri: string;
    private grmSubscriptionKey: string;
    private isMyPortfolioDataAvailable: boolean = true;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(dmLogger, Services.StaffingService);
        this.grmSubscriptionKey = this.configManagerService.getValue<string>("grmSubscriptionKey");
        this.grmBaseUri = this.configManagerService.getValue<string>("grmbaseURL");
    }

    /**
     * Get Staffing Data of an engagement
     * Staffing Data = WbsStructure + WbsDemands + GRM Resource Request
     *
     * @param {string} engagementId
     * @param {number} [delayIntervalInSecs=0]
     * @memberof StaffingService
     */
    public getEngagementStaffingData(engagementId: string, delayIntervalInSecs: number = 0): void {
        this.isMyPortfolioDataAvailable = true;
        this.sharedFunctionsService.delayExecution(delayIntervalInSecs * 1000).then(() => {
            // Get the Staffing Data based on MyPortfolio Store
            this.getDataBasedOnMyPortfolioStore(engagementId);
            // Get the Staffing Data based on WbsStructure
            this.getDataBasedonWbsStructureStore(engagementId);
        });
    }

    /**
     * Invalidate Project Staffing data
     * Staffing Data = WbsStructure + WbsDemands + GRM Resource Request
     *
     * @param {string[]} projectIds
     * @memberof StaffingService
     */
    public invalidateProjectStaffingData(projectIds: string[]): void {
        projectIds = projectIds.filter((item, pos, ar) => ar.indexOf(item) === pos);
        for (const id of projectIds) {
            this.store.dispatch(new InvalidateDemands(id));
            this.store.dispatch(new InvalidateResourceRequestsProjectContext(id));
        }
        for (const id of projectIds) {
            this.store.dispatch(new LoadDemands(id));
            this.store.dispatch(new LoadResourceRequestsProjectContext(id));
        }
    }

    /**
     * Gets the GRM Request Project ID by making a post request to the GRM API
     * @param projectData     
     */
    public getGRMRequestsProjectId(projectData: IProjectDetailsV2[]): Promise<IResourceRequestResponse> {
        const userInfo: string = this.fxpUserInfoService.getCurrentUser();
        const demandSourceObject = this.sharedFunctionsService.getInputObjectForSearchApi(projectData);
        const data = {
            ResourceRequestFilter: {},
            DateRangeFilter: {},
            ProjectRequestFilter: {
                DemandSourceId: [],
                requestor: [userInfo]
            },
            DemandSourceType: ["Delivery", "Deal"],
            scheduledDurationRequired: true
        };
        demandSourceObject.forEach((object) => {
            data.ProjectRequestFilter.DemandSourceId.push(object.ProjectID);
            if (object.CompassOnePackageID) {
                data.ProjectRequestFilter.DemandSourceId.push(object.CompassOnePackageID);
            }
        });
        const url = `${this.grmBaseUri}/v1/Request/SearchResources/Projects`;
        return this.dataService.postData(url, this.grmSubscriptionKey, APIConstants.GetResourcesAPI, data)
            .then((response: IResourceRequestResponse) => {
                return Promise.resolve(response);
            }).catch((error) => {
                if (error.status === 404) {
                    const responseObject: IResourceRequestResponse = {
                        ProjectRequests: []
                    };
                    return Promise.resolve(responseObject);
                } else {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "Unable to get the GRM request project id.");
                    this.logError(SourceConstants.Method.GetGRMRequestsProjectId, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return Promise.reject(error);
                }
            });
    }


    /**
     * Gets the GRM Request Project ID by making a post request to the GRM API
     * Updated this method, added filter in response to get only planned requests
     * @param projectData     
     */
    public getGRMRequestsProjectIdV2(demandSourceObjects: IDemandSourceObject[]): Promise<IResourceRequestResponse> {
        const userInfo: string = this.fxpUserInfoService.getCurrentUser();
        const data = {
            ResourceRequestFilter: {},
            DateRangeFilter: {},
            ProjectRequestFilter: {
                DemandSourceId: [],
                requestor: [userInfo]
            },
            DemandSourceType: ["Delivery", "Deal"],
            scheduledDurationRequired: true
        };
        if (demandSourceObjects && demandSourceObjects.length) {
            for (const demandSourceObject of demandSourceObjects) {
                data.ProjectRequestFilter.DemandSourceId.push(demandSourceObject.projectId);
                if (demandSourceObject.compassOnePackageId) {
                    data.ProjectRequestFilter.DemandSourceId.push(demandSourceObject.compassOnePackageId.trim());
                }
            }
        }
        const url = `${this.grmBaseUri}/v1/Request/SearchResources/Projects`;
        return this.dataService.postData(url, this.grmSubscriptionKey, APIConstants.GetResourcesAPI, data)
            .then((response: IResourceRequestResponse) => {
                response.ProjectRequests = response.ProjectRequests.filter((p) => p.ResourceRequests.filter((r) => r.DemandResourceRequest && r.DemandResourceRequest.DemandId && r.DemandResourceRequest.DemandId.length > 0));
                return Promise.resolve(response);
            }).catch((error) => {
                if (error.status === 404) {
                    const responseObject: IResourceRequestResponse = {
                        ProjectRequests: []
                    };
                    return Promise.resolve(responseObject);
                } else {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "Unable to get the GRM request project id.");
                    this.logError(SourceConstants.Method.GetGRMRequestsProjectIdV2, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return Promise.reject(error);
                }
            });
    }

    /**
     * Gets the GRM Request Project ID by making a post request to the GRM API
     * @param projectData     
     */
    public getGRMRequestsBasedOnProjectId(projectId: string): Promise<IResourceRequestResponse> {
        const userInfo: string = this.fxpUserInfoService.getCurrentUser();
        const data = {
            ResourceRequestFilter: {},
            DateRangeFilter: {},
            ProjectRequestFilter: {
                DemandSourceId: [projectId],
                requestor: [userInfo]
            },
            DemandSourceType: ["Delivery", "Deal"],
            scheduledDurationRequired: true
        };
        const url = `${this.grmBaseUri}/v1/Request/SearchResources/Projects`;
        return this.dataService.postData(url, this.grmSubscriptionKey, APIConstants.GetResourcesAPI, data)
            .then((response: IResourceRequestResponse) => {
                return Promise.resolve(response);
            }).catch((error) => {
                if (error.status === 404) {
                    const responseObject: IResourceRequestResponse = {
                        ProjectRequests: []
                    };
                    return Promise.resolve(responseObject);
                } else {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "Unable to get the GRM request based on project id.");
                    this.logError(SourceConstants.Method.GetGRMRequestsBasedOnProjectId, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return Promise.reject(error);
                }
            });
    }


    /**
     * Updates GRM resource requests via a patch request to the GRM API
     * @param resourceID
     * @param reasonCode
     * @param comments
     * @param actionType
     */
    public updateGRMResourceRequests(resourceID: number, reasonCode: string, comments: string, actionType: string): Promise<IGRMUpdateResponse> {
        const inputData: any = {};
        inputData.Action = actionType;
        inputData.ActionForRequests = [];
        if (actionType === "RequestorApproved") {
            inputData.ActionForRequests.push({
                Id: resourceID
            });
        } else {
            inputData.ActionForRequests.push({
                Id: resourceID,
                ReasonCode: reasonCode,
                Comments: comments,
                IsTandEIncurred: false
            });
        }
        const url = `${this.grmBaseUri}/v1/Request/Resource/Actions`;
        return this.dataService.patchData(url, this.grmSubscriptionKey, APIConstants.UpdateResourceDetailsAPI, inputData);
    }

    /**
     * Gets GRM Reject Resource domain data from existing domain data, or by calling the GRM API
     */
    public getGRMRejectResourceDomainData(): Promise<IRejectResponseDomainData> {
        if (this.domainData && this.domainData.DomainDataResult && this.domainData.DomainDataResult.ReasonCodeProblemTypes && this.domainData.DomainDataResult.ReasonCodeProblemTypes.length) {
            return Promise.resolve(this.domainData);
        } else {
            return this.getDomainData().then((response) => {
                this.domainData = response;
                return Promise.resolve(this.domainData);
            });
        }
    }

    /**
     * Gets domain data from the GRM API
     */
    public getDomainData(): Promise<IRejectResponseDomainData> {
        const inputData = {
            DomainDataLists: ["ReasonCodeProblemTypes"]
        };
        const url = `${this.grmBaseUri}/v1/Domain/GetDomainData`;
        return this.dataService.postData(url, this.grmSubscriptionKey, APIConstants.ResourceDomainDataAPI, inputData);
    }

    /**
     * Gets pending resource details from th GRM API
     */
    public getGRMPendingResourceDetails(): Promise<IPendingResourceDetails> {
        const suppressErrorCode = 403;
        const url = `${this.grmBaseUri}/v2/Request/RequestPendingActionDetails?requestActionType=All`;
        return this.dataService.getData(url, this.grmSubscriptionKey, APIConstants.ResourcePendingActionDetailsAPI, [suppressErrorCode]);
    }

    /**
     * Sort Projects based on the daysDifferenceStartDateCurrentDate field
     *
     * @memberof StaffingService
     */
    public sortEntitiesByDateDifference(): (entity1: IProject | IProjectListPortfolio, entity2: IProject | IProjectListPortfolio) => number {
        return (entity1: IProject | IProjectListPortfolio, entity2: IProject | IProjectListPortfolio): number => {
            return entity1.daysDiffProjectStartCurrentDate - entity2.daysDiffProjectStartCurrentDate;
        };
    }

    /**
     * Gets the Demand Details based on the wbsdetails retrived from the API.
     *
     * @param {ITaskStructure} task
     * @param {IWbsDemand} wbsDemandDetails
     * @param {IResourceRequestResponse} grmResponse
     * @param {string} projectId
     * @memberof StaffingComponent
     */
    public getDemandDetailsBasedOnWbsDemands(task: ITaskStructure, wbsDemandDetails: IWbsDemand, grmResponse: IResourceRequestResponse, projectId: string, engagementStructureDetails?: IWbsStructure, projectStructureDetails?: IProject, isProjectContext?: boolean): IStaffingDemandModel[] {
        const staffingDemandModel: IStaffingDemandModel[] = [];
        if (wbsDemandDetails && wbsDemandDetails.details && wbsDemandDetails.details.length
            && wbsDemandDetails.details.filter((demand) => demand.planned && demand.planned.structureId && demand.planned.structureId === task.id).length > 0) {
            const plannedWbsDemandDetailsForTask: IDemandDetails[] = wbsDemandDetails.details.filter((demand) => demand.planned && demand.planned.structureId && demand.planned.structureId === task.id);
            for (const demandDetail of plannedWbsDemandDetailsForTask) {
                if (staffingDemandModel.filter((demand) => demand.demandId === demandDetail.demandId).length === 0) {
                    staffingDemandModel.push({
                        engagementId: this.sharedFunctionsService.getEngagementIdFromProjectId(projectId),
                        demandId: demandDetail.demandId,
                        demandLocation: demandDetail.planned.deliveryLocation,
                        demandRole: demandDetail.planned.roleDescription,
                        demandCostRate: demandDetail.planned.plannedCostRate,
                        demandPlannedQuantity: demandDetail.planned.plannedHours,
                        demandPlannedCost: demandDetail.planned.plannedCost,
                        demandBillRate: demandDetail.planned.billRate,
                        resourceAccordionFlag: true,
                        isRolesExpanded: true,
                        isDemandBillable: !demandDetail.planned.isNonBillable,
                        resources: this.getResourceRequestsForWbsDemandModel(demandDetail, grmResponse, projectId, engagementStructureDetails, projectStructureDetails, isProjectContext)
                    });
                } else {
                    const staffingDemandObject = staffingDemandModel.filter((demand) => demand.demandId === demandDetail.demandId)[0];
                    const resourceDetails: IStaffingResource[] = this.getResourceRequestsForWbsDemandModel(demandDetail, grmResponse, projectId, engagementStructureDetails, projectStructureDetails, isProjectContext);
                    if (resourceDetails && resourceDetails.length > 0) {
                        staffingDemandObject.resources.concat(resourceDetails);
                    }
                }
            }
        }
        return staffingDemandModel;
    }


    /**
     * Function to decide that logged in user has permission for project or not.
     *
     * @private
     * @param {string} projectId
     * @returns {boolean}
     * @memberof StaffingService
     */
    public hasPermissionsForEditActions(projectId: string, engagementStructureDetails?: IWbsStructure, projectStructureDetails?: IProject, isProjectContext?: boolean): boolean {
        if (!isProjectContext && engagementStructureDetails
            && (this.dmAuthorizationService.isUserInEngagementLevelTeam(engagementStructureDetails)
                || this.dmAuthorizationService.isUserInProjectLevelTeam(engagementStructureDetails.projects.filter((proj) => proj.id === projectId)[0]))) {
            return true;
        }
        if (isProjectContext && projectStructureDetails && this.dmAuthorizationService.isUserInProjectLevelTeam(projectStructureDetails)) {
            return true;
        }
        return false;
    }

    /**
     * This method is tweaked for manage ebs conflict resources tab as it doesn't need bill rates, cost variances etc.
     *
     * @private
     * @param {IDemandDetails} wbsDemandDetailsForTask
     * @param {IResourceRequestResponse} grmResponse
     * @param {string} projectId
     * @returns {IStaffingResource[]}
     * @memberof StaffingService
     */
    private getResourceRequestsForWbsDemandModel(wbsDemandDetailsForTask: IDemandDetails, grmResponse: IResourceRequestResponse, projectId: string, engagementStructureDetails?: IWbsStructure, projectStructureDetails?: IProject, isProjectContext?: boolean): IStaffingResource[] {
        const resourceRequestsForViewModel: IStaffingResource[] = [];
        if (grmResponse && grmResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId).length > 0) {
            const projectRequests: IProjectRequest = grmResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId)[0];
            if (projectRequests.ResourceRequests && projectRequests.ResourceRequests.length > 0) {
                const resourceRequests: IResourceRequest[] = projectRequests.ResourceRequests.filter((resourceDetails) => resourceDetails.DemandResourceRequest && resourceDetails.DemandResourceRequest.DemandId === wbsDemandDetailsForTask.demandId);
                if (resourceRequests && resourceRequests.length > 0) {
                    resourceRequests.forEach((resourceRequest) => {
                        // if (resourceRequest.AssignedResource) {
                        //     this.bindResourceImageToStaffingTasks(resourceRequest.AssignedResource, wbsDemandDetailsForTask.planned.structureId, wbsDemandDetailsForTask.demandId);
                        // } else if (resourceRequest.RequestedResource) {
                        //     this.bindResourceImageToStaffingTasks(resourceRequest.RequestedResource, wbsDemandDetailsForTask.planned.structureId, wbsDemandDetailsForTask.demandId);
                        // }
                        const plannedDetails: IResourceData = wbsDemandDetailsForTask.planned;
                        const staffedDetails: IResourceData[] = (wbsDemandDetailsForTask.staffed && wbsDemandDetailsForTask.staffed.length > 0) ? wbsDemandDetailsForTask.staffed.filter((resData) => resData.resourceRequestId === (resourceRequest.ResourceRequestId).toString()) : [];
                        const resourcePopulated: IStaffingResource = {
                            resourceId: resourceRequest.ResourceRequestId,
                            resourceGrmProjectID: projectRequests.ProjectId,
                            resourceLocation: resourceRequest.DeliveryCountryName,
                            resourceRole: resourceRequest.Role,
                            resourceAlias: resourceRequest.AssignedResource ? resourceRequest.AssignedResource : resourceRequest.RequestedResource,
                            // resourceBillRate: this.isInternal ? 0 : plannedDetails.billRate,
                            // resourceCostRate: this.calculateResourceCostRateV2(resourceRequest, wbsDemandDetailsForTask),
                            resourceStaffedQuantity: resourceRequest.scheduledDurationInHours,
                            resourcePlannedQuantity: resourceRequest.RequestedDuration,
                            resourceStaffedCost: (staffedDetails && staffedDetails.length > 0) ? staffedDetails[0].staffedCost : 0,
                            resourcePlannedCost: plannedDetails.plannedCostRate * resourceRequest.RequestedDuration,
                            resourceStartDate: resourceRequest.ScheduledStartDate ? resourceRequest.ScheduledStartDate : resourceRequest.RequestedStartDate,
                            resourceEndDate: resourceRequest.ScheduledEndDate ? resourceRequest.ScheduledEndDate : resourceRequest.RequestedEndDate,
                            resourceStatus: this.getResourceStatus(resourceRequest.ResourceRequestStatusEnum),
                            resourceSubStatus: resourceRequest.ResourceRequestSubStatusEnum,
                            resourceName: resourceRequest.AssignedResourceName ? resourceRequest.AssignedResourceName : resourceRequest.RequestedResourceName,
                            resourceActions: this.getActions(resourceRequest),
                            enableResourceActions: this.hasPermissionsForEditActions(projectId, engagementStructureDetails, projectStructureDetails, isProjectContext),
                            purchaseOrderId: resourceRequest.DemandResourceRequest.PoNumber,
                            poLineItemNumber: resourceRequest.DemandResourceRequest.PoLineItem,
                            rolePartNumber: resourceRequest.RolePartNumber
                        };
                        // resourcePopulated.resourceCostVariance = this.calculateCostVariance(resourcePopulated.resourceStaffedCost, resourcePopulated.resourcePlannedCost);
                        resourceRequestsForViewModel.push(resourcePopulated);
                    });
                }
            }
        }
        return resourceRequestsForViewModel;
    }

    /**
     * To get the actions of resources.
     * @param resource
     */
    private getActions(resource: IResourceRequest): string {
        if (resource.ResourceRequestStatusEnum === "Proposed" && resource.ResourceRequestSubStatusEnum === "Ready") {
            return "AcceptDecline";
        } else {
            return "Edit";
        }
    }

    /**
     * To get the resource status enum values.
     * @param resourceStatusEnum
     */
    private getResourceStatus(resourceStatusEnum: string): string {
        if (resourceStatusEnum === "Assigned") {
            resourceStatusEnum = "Committed";
        } else if (resourceStatusEnum === "Complete") {
            resourceStatusEnum = "Assigned";
        }
        return resourceStatusEnum;
    }


    /**
     *
     * Step 1: Extract the list of Projects from My Portfolio data if available
     * Step 2: Call the WbsDemands and GRM Search API to get the data assocaited to the Projects
     *
     * @private
     * @param {string} engagementId
     * @memberof StaffingService
     */
    private getDataBasedOnMyPortfolioStore(engagementId: string): void {
        let listPrimaryProjects: string[] = [];
        let listSecondaryProjects: string[] = [];

        // Check if MyPortfolio Data is available
        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);

        myPortfolioEngagementList$.pipe(
            take(1))
            .subscribe((data: IMyPortfolioEngagementListState) => {
                if (data.loaded) {
                    // As data is available extract the list of project based on the engagament id
                    const currentEngagementDetails = data.engagementList.filter((eng) => eng.engagementId === engagementId)[0];
                    if (this.isMyPortfolioDataAvailable && currentEngagementDetails && currentEngagementDetails.ebsState !== CREATED_STATUSCODE) {

                        if (currentEngagementDetails.projects.length <= 3) {
                            listPrimaryProjects = currentEngagementDetails.projects.map((value) => value.projectId);
                        } else {
                            const assignedProjects = currentEngagementDetails.projects.filter((project) => project.isAssigned).sort(this.sortEntitiesByDateDifference());
                            const unassignedProjects = currentEngagementDetails.projects.filter((project) => !project.isAssigned).sort(this.sortEntitiesByDateDifference());
                            const projectDetails = assignedProjects.concat(unassignedProjects);
                            // Get the top 3 projects to load on to the request grid
                            listPrimaryProjects = projectDetails
                                .map((value) => value.projectId)
                                .slice(0, 3);

                            // Get the rest of the projects to lazy load request grid.
                            listSecondaryProjects = projectDetails
                                .filter((project) => listPrimaryProjects.indexOf(project.projectId) === -1)
                                .map((value) => value.projectId);

                        }
                        // Call the WBS Demands and GRM Resource Request
                        this.getWbsDemandAndGrmResourceRequest(listPrimaryProjects);
                        this.getWbsDemandAndGrmResourceRequest(listSecondaryProjects);
                    }
                } else {
                    this.isMyPortfolioDataAvailable = false;
                }
            });
    }



    /**
     *
     * Step 1: Extract the list of Projects from WbsStructure
     * Step 2: Call the WbsDemands and GRM Search API to get the data assocaited to the Projects
     *
     * @private
     * @param {string} engagementId
     * @param {number} loggedInUserBPID
     * @memberof StaffingService
     */
    private getDataBasedonWbsStructureStore(engagementId: string): void {

        let wbsStructure: IWbsStructure;
        let listPrimaryProjects: string[] = [];
        let listSecondaryProjects: string[] = [];

        const wbsStructureDetails$ = this.store.select(getInitialWbsStructures(engagementId));

        wbsStructureDetails$
            .subscribe((engagementStructureDetails: IWbsStructuresState) => {
                if (engagementStructureDetails.loaded) {
                    wbsStructure = engagementStructureDetails.wbsStructures as IWbsStructure;
                    if (!this.isMyPortfolioDataAvailable && wbsStructure.statusCode !== CREATED_STATUSCODE) {
                        if (wbsStructure.projects.length <= 3) {
                            listPrimaryProjects = wbsStructure.projects.map((value) => value.id);
                        } else {
                            const assignedProjects = wbsStructure.projects.filter((project) => project.isAssigned).sort(this.sortEntitiesByDateDifference());
                            const unassignedProjects = wbsStructure.projects.filter((project) => !project.isAssigned).sort(this.sortEntitiesByDateDifference());
                            const projectDetails = assignedProjects.concat(unassignedProjects);
                            // Get the top 3 projects to load on to the request grid
                            listPrimaryProjects = projectDetails
                                .map((value) => value.id)
                                .slice(0, 3);

                            // Get the rest of the projects to lazy load request grid.
                            listSecondaryProjects = projectDetails
                                .filter((project) => listPrimaryProjects.indexOf(project.id) === -1)
                                .map((value) => value.id);

                        }
                        // Call the WBS Demands and GRM Resource Request
                        this.getWbsDemandAndGrmResourceRequest(listPrimaryProjects);
                        this.getWbsDemandAndGrmResourceRequest(listSecondaryProjects);
                    }
                }
            });
    }

    /**
     * Call the WbsDemands & GRM Search API to get staffing Data
     *
     * @private
     * @param {string[]} projectIds
     * @memberof StaffingService
     */
    private getWbsDemandAndGrmResourceRequest(projectIds: string[]): void {
        if (projectIds) {
            for (const projectId of projectIds) {
                const wbsDemandDetails$ = this.store.select(getDemandsState(projectId));
                const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsProjectContextStateObject(projectId));

                wbsDemandDetails$.pipe(
                    take(1))
                    .subscribe((data: IDemandsState) => {
                        if (!data.loaded && !data.loading) { this.store.dispatch(new LoadDemands(projectId)); }
                    });

                resourceRequestDetail$.pipe(
                    take(1))
                    .subscribe((data: IResourceRequestsDetailsProjectState) => {
                        if (!data.loaded && !data.loading) { this.store.dispatch(new LoadResourceRequestsProjectContext(projectId)); }
                    });
            }
        }
    }
}