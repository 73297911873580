<div class="associate-modal">
    <label id="projectDetails" class="sr-only">View Project Details</label>
    <div class="modal-header modal-noborder">
        <button type="button" class="close set-focus" aria-label="View Project Details Dialog Close" (click)="closeModal()" (keydown)="moveFocusPrev($event, 'accessibilityConstants.Cancel')" id="accessibilityConstants.CloseUpdateButton">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle">View Project Details</h4>
    </div>
    <div class="modal-body">
        <p class="font-body unplanned-description">Select a project to view details.</p>
        <div *ngIf="!grmProjectDetails && isUsPubSecDeal" class="associate-list_wrapper">
            <ul>
                <li *ngFor="let project of projectDetails">
                    <a [href]="'/#/Delivery/compassPackage/' + project.compassOnePackageId + '/sapPackage/' + project.id"
                        (click)="closeModal()">
                        {{project.name}}
                    </a>
                </li>
            </ul>
        </div>
        <div *ngIf="!grmProjectDetails && !isUsPubSecDeal" class="associate-list_wrapper">
            <ul>
                <li *ngFor="let project of projectDetails">
                    <a [href]="'#/workbench/Requestor?SapProjectId='+project.id+'&C1PackageId='+ project.compassOnePackageId"
                        (click)="closeModal()">
                        {{project.name}}
                    </a>
                </li>
            </ul>
        </div>
        <div *ngIf="grmProjectDetails" class="associate-list_wrapper">            
            <ul>
                <li *ngFor="let project of grmProjectDetails.ProjectRequests">
                    <a [href]="'#/project/'+ project.ProjectId+'/false/Requestor'"
                        (click)="closeModal()">
                        {{project.ProjectName}}
                    </a>                    
                </li>
            </ul>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" value="cancel" id="accessibilityConstants.Cancel" (keydown)="moveFocusNext($event, 'accessibilityConstants.closeUpdateButton')" class="fxp-btn fxp-btn--secondary" (click)="closeModal()">
            Cancel
        </button>
    </div>
</div>