import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TphRrDetailsComponent } from "./tph-rr-details.component";

@NgModule({
    declarations: [
        TphRrDetailsComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    providers: [
    ],
    entryComponents: [
        TphRrDetailsComponent
    ],
    exports: [
        TphRrDetailsComponent
    ]
})
export class TphRrDetailsModule { }