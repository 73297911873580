<section class="rrm-dailog"
    [ngClass]="{'rrm-dailog--height-additional-hours': (selectedRequestReason && selectedRequestReason ==='Additional Funds Received')}">
    <label class="sr-only">Request for Role Maintenance</label>
    <section class="modal-header" *ngIf="isModalComponent">
        <button type="button" class="close set-focus" (click)="closeModal()" (keydown)="moveFocusPrev($event, 'accessibilityConstants.RmRequest')"
            id="accessibilityConstants.ClosePopUp" aria-label="Request For Role Maintenance Dialog close">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            Request for Role Maintenance
        </h4>
    </section>
    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [errorText]="errorSummaryText"
        [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>

    <section class="modal-body" *ngIf="!isLoading">
        <form #rmRequest>
            <section class="section__wrapper display-grid">
                <section class="input__label">
                    <label class="input__label--text font-caption-alt" for="dm-rrm-action-ddl">Action
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <section class="input__select--icon">
                        <select id="dm-rrm-action-ddl" [(ngModel)]="selectedAction" required name="actionType"
                            (ngModelChange)="loadReasonForSelectedAction($event)"
                            class="input__select input__select--big font-caption-alt">
                            <option [ngValue]="defaultAction">{{defaultAction.Name}}</option>
                            <option *ngFor="let action of rmAction" [ngValue]="action">
                                {{action.Name}}
                            </option>
                        </select>
                        <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                        <!-- <span class="sr-only" role="alert" aria-atomic="true" aria-live="polite" *ngIf=="rmRequest.reasonType.$touched && rmRequest.reasonType.$invalid">Suspend Reason is required</span> -->
                        <span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite"
                            *ngIf="rmRequest.actionType.touched && rmRequest.actionType.invalid">{{rmModalErrorMessages.SuspendActionIsRequired}}</span>
                    </section>
                </section>
                <section class="input__label">
                    <label class="input__label--text font-caption-alt" for="dm-rrm-reason-ddl">Reason Code
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <section class="input__select--icon">
                        <select id="dm-rrm-reason-ddl" [(ngModel)]="selectedRequestReason" name="reasonType" required
                            class="input__select input__select--big font-caption-alt">
                            <option [value]="'select'">Select Reason Code</option>
                            <option *ngFor="let reason of requestReasons" [value]="reason.Name">
                                {{reason.Name}}
                            </option>
                        </select>
                        <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                        <!-- <span class="sr-only" role="alert" aria-atomic="true" aria-live="polite" *ngIf=="rmRequest.reasonType.$touched && rmRequest.reasonType.$invalid">Suspend Reason is required</span> -->
                        <span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite"
                            *ngIf="rmRequest.reasonType.touched && rmRequest.reasonType.invalid">{{rmModalErrorMessages.SuspendReasonIsRequired}}</span>
                    </section>
                </section>
                <section class="input__label"
                    *ngIf="selectedRequestReason && selectedRequestReason ==='Additional Funds Received' ">
                    <div>
                        <label class="input__label--text font-caption-alt" for="dm-rrm-reason">Requested Hours
                            <span class="required-mark" aria-hidden="true">*</span>
                        </label>
                    </div>
                    <div>
                        <input class="additional-hours" type="number" id="additionalHours" name="additionalHours" required
                            [(ngModel)]="requestedHours" aria-label="enter additional hours" />
                    </div>
                    <span class="font-caption-alt inp-validation required-mark" role="alert" aria-atomic="true"
                        aria-live="polite">{{lblRequestedHoursValidation}}</span>
                </section>
                <section class="input__label">
                    <label class="input__label--text font-caption-alt" for="dm-rrm-reason">Comments<span
                            class="required-mark" aria-hidden="true"
                            *ngIf="selectedRequestReason && selectedRequestReason.toLowerCase()==='other'">
                            *</span></label>
                    <textarea id="dm-rrm-reason" [(ngModel)]="requestComment" name="rejectionComments" required
                        *ngIf="selectedRequestReason && selectedRequestReason.toLowerCase()==='other'" class="input_text_edge" placeholder="  Comments"></textarea>
                    <textarea id="dm-rrm-reason" [(ngModel)]="requestComment" name="rejectionComments"
                        *ngIf="!(selectedRequestReason && selectedRequestReason.toLowerCase()==='other')" class="input_text_edge" placeholder="  Comments"></textarea>
                </section>
                <!-- [(ngModel)]="suspendComment"<span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite" *ngIf="rmRequest.commentsValidation">Comments are required</span> -->
                <label id="lblMessage" class="error-msg" for="dm-id-suspendRequest">
                    <span class="required-mark" role="alert" aria-atomic="true" aria-live="polite">{{lblMessage}}</span>
                </label>
            </section>
            <section class="modal-footer footer-button" [ngClass]="{'tab-footer': !isModalComponent}">
                <button type="button" class="fxp-btn fxp-btn--secondary" id="cancelRequest"
                    (click)="closeModal()">Cancel</button>
                <button type="button" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.RmRequest" [disabled]="isSubmitDisabled"
                    (click)="submit()" (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp')">
                    Submit
                </button>
            </section>
        </form>
    </section>
</section>