import { Component, Inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AccessibilityConstants, Components } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IResourceDetails, IResourceFinancials } from "../../../../components/financial-mgmt/financial.model";


@Component({
    templateUrl: "./resource-details.html",
    styleUrls: ["./resource-details.scss"]
})
export class ResourceDetailsModalComponent extends DmModalAbstract {
    @Input() public resourceDetails: IResourceDetails;
    @Input() public currency: string;
    public modalContent: IModal;
    public eacFinancials: IResourceFinancials;
    public etcFinancials: IResourceFinancials;
    public plannedFinancials: IResourceFinancials;
    public staffedFinancials: IResourceFinancials;
    public actualFinancials: IResourceFinancials;
    public accessibilityConstants = AccessibilityConstants;
    
    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(activeModal, dmLogger, Components.ResourceDetails);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Resource details"
        };
        if (this.resourceDetails && this.resourceDetails.fullName) {
            this.resourceDetails.personDetails.displayName = this.resourceDetails.fullName;
        }       
        if (this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Forecast").length) {
            this.eacFinancials = this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Forecast")[0];
        }
        if (this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Planned").length) {
            this.plannedFinancials = this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Planned")[0];
        }
        if (this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Staffed").length) {
            this.staffedFinancials = this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Staffed")[0];
        }
        if (this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Actuals").length) {
            this.actualFinancials = this.resourceDetails.resourceFinancials.filter((r) => r.financialVersion === "Actuals")[0];
        }
        if (this.eacFinancials && this.actualFinancials) {
            this.etcFinancials = {
                financialVersion: "ETC",
                laborHours: this.eacFinancials.laborHours - this.actualFinancials.laborHours,
                costRate: this.eacFinancials.costRate,
                cost: this.eacFinancials.cost - this.actualFinancials.cost,
                billRate: this.eacFinancials.billRate,
                revenue: this.eacFinancials.revenue - this.actualFinancials.revenue
            };
        }
    }
}
