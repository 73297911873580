import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WbsProjectEditModalInstanceComponent } from "./wbs-project-edit.component";
import { WBSService } from "../../../../../common/services/wbs.service";
import { MyPortfolioService } from "../../../../../common/services/my-portfolio.service";
import { WbsResourceRequestsModule } from "../../wbs-resource-requests/wbs-resource-requests.module";

@NgModule({
    declarations: [
        WbsProjectEditModalInstanceComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        WbsResourceRequestsModule,
        NgbModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        DMNotificationService,
        EngagementDetailService,
        MyPortfolioService,
        SharedFunctionsService,
        WBSService
    ],
    entryComponents: [
        WbsProjectEditModalInstanceComponent,
    ],
    exports: [
        WbsProjectEditModalInstanceComponent,
    ]
})
export class WbsProjectEditModalModule { }
