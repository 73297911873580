import { Component, Inject, Input, ViewChild } from "@angular/core";
import { NgbCarousel } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ISlide } from "../../../common/services/contracts/whats-new.contracts";

@Component({
    selector: "slide-view",
    templateUrl: "./slide-view.html",
    styleUrls: ["./slide-view.scss"]
})
export class SlideViewComponent {

    @Input() public slideList: ISlide[];
    @ViewChild("pjmCarousel", { static: true }) public carousel: NgbCarousel;

    public constructor(
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
    }

    public ngOnInit(): void {
        this.carousel.pause(); // Pauses cycling through the slides.
    }

    public ngAfterViewInit(): void {
        const carouselIndicatorElements = document.getElementsByClassName("carousel-indicators");
        if (carouselIndicatorElements && carouselIndicatorElements.length) {
            if (carouselIndicatorElements[0]) {
                carouselIndicatorElements[0].setAttribute("role", "tablist");
                const slideElements = Array.from(carouselIndicatorElements[0].getElementsByTagName("li"));
                if (slideElements && slideElements.length) {
                    for (const slideElement of slideElements) {
                        if (slideElement) {
                            const slideNumber = slideElements.indexOf(slideElement) > -1 ? slideElements.indexOf(slideElement) + 1 : 1;
                            const selectedSlideText = "Slide " + slideNumber + " button selected";
                            const unselectedSlideText = "Slide " + slideNumber + " button not selected";
                            slideElement.setAttribute("role", "tab");
                            if (slideNumber === 1) {
                                slideElement.setAttribute("aria-label", selectedSlideText);
                            } else {
                                slideElement.setAttribute("aria-label", unselectedSlideText);
                            }
                        }
                    }
                }
            }            
        }

        const carouselControlPreviousElements = document.getElementsByClassName("carousel-control-prev");
        const carouselControlNextElements = document.getElementsByClassName("carousel-control-next");
        if (carouselControlPreviousElements && carouselControlNextElements) {
            const carouselControlPreviousElementsArray = Array.from(carouselControlPreviousElements);
            const carouselControlNextElementsArray = Array.from(carouselControlNextElements);
            if (carouselControlPreviousElementsArray && carouselControlPreviousElementsArray.length) {
                for (const carouselControlPreviousElement of carouselControlPreviousElementsArray) {
                    if (carouselControlPreviousElement) {
                        carouselControlPreviousElement.setAttribute("aria-hidden", "true");
                    }
                }
            }
            if (carouselControlNextElementsArray && carouselControlNextElementsArray.length) {
                for (const carouselControlNextElement of carouselControlNextElementsArray) {
                    if (carouselControlNextElement) {
                        carouselControlNextElement.setAttribute("aria-hidden", "true");
                    }
                }
            }
        }
    }

    /*
     * Triggers on transition of slides
     */
    public transitionSlide(event: {
        current: string;
        direction: string;
        paused: boolean;
        prev: string;
        source: string;
    }): void {
        if (event && event.current) {
            this.sharedFunctionsService.removeAttribute(event.current, "id", "aria-label");
            if (document.getElementById(event.current)) {
                const slideNumber = event.current.length ? event.current.charAt(event.current.length - 1) : 1;
                const selectedSlideText = "Slide " + slideNumber + " selected";
                document.getElementById(event.current).setAttribute("aria-label", selectedSlideText);
            }
        }
        if (event && event.prev) {
            if (document.getElementById(event.prev)) {
                const slideNumber = event.prev.length ? event.prev.charAt(event.prev.length - 1) : 1;
                const unselectedSlideText = "Slide " + slideNumber + " not selected";
                document.getElementById(event.prev).setAttribute("aria-label", unselectedSlideText);
            }
        }
    }

    /*
     * Navigates to the previous slide when previous button is clicked
     */
    public navigateToPreviousSlide(sliderValue: number): void {
        this.carousel.prev();
        if (sliderValue > 0) {
            this.sharedFunctionsService.focus("previous-slide-" + sliderValue, true);
        } else {
            this.sharedFunctionsService.focus("closeWhatsNewButton", true);
        }
    }

    /*
     * Navigates to the next slide when next button is clicked
     */
    public navigateToNextSlide(sliderValue: number): void {
        this.carousel.next();
        if (this.slideList && sliderValue < this.slideList.length - 1) {
            this.sharedFunctionsService.focus("next-slide-" + sliderValue, true);
        } else {
            this.sharedFunctionsService.focus("closeWhatsNewButton", true);
        }
    }

    /*
     * Show/Hide navigation indicators
     */
    public displayNavigationIndicators(): boolean {
        return (this.slideList && this.slideList.length > 1) ? true : false;
    }

    /**
     * Move focus to the specified id
     */
    public moveFocus(event: KeyboardEvent, id: string): void {
        this.sharedFunctionsService.moveFocus(event, id, id);
    }

}
