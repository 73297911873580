import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as forecastRecommendationsActions from "./engagement-forecast-recommendations.action";
import { ForecastRecommendationService } from "../../common/services/forecast-recommendation.service";
import { IWeeklyForecastRecommendation } from "../../common/services/contracts/forecast-recommendation.contracts";


@Injectable()
export class EngagementForecastRecommendationsEffects {

    @Effect()
    public loadForecastRecommendations$ = this.actions$
        .ofType(forecastRecommendationsActions.ForecastRecommendationActionTypes.LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS)
        .pipe(
            switchMap((action: forecastRecommendationsActions.LoadEngagementForecastRecommendations) => fromPromise(
                this.forecastRecommendationService.getForecastRecommendationsByEngagementId(action.engagementId))
                .pipe(
                    map((response: IWeeklyForecastRecommendation[]) => new forecastRecommendationsActions.LoadEngagementForecastRecommendationsSuccess(action.engagementId, response)),
                    catchError((error) => of(new forecastRecommendationsActions.LoadEngagementForecastRecommendationsFail(action.engagementId, error)))
                )
            ));

    public constructor(
        private actions$: Actions,
        @Inject(ForecastRecommendationService) private forecastRecommendationService: ForecastRecommendationService,
    ) { }
}
