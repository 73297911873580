import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ViewWorkBookModalComponent } from "./view-workbook-modal.component";


@NgModule({
    declarations: [
        ViewWorkBookModalComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
    ],
    entryComponents: [
        ViewWorkBookModalComponent
    ],
    exports: [
        ViewWorkBookModalComponent
    ]
})
export class ViewWorkbookModalModule { }
