import { Component, Input, Inject } from "@angular/core";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { IHoursSummary } from "../../../common/services/contracts/hours-summary.contracts";
@Component({
    selector: "dm-hours-summary",
    templateUrl: "./hours-summary.html",
    styleUrls: ["./hours-summary.scss"]
})
export class HoursSummaryComponent extends DmComponentAbstract {

    @Input() public hoursSummaryError: string;
    @Input() public hoursSummaryData: IHoursSummary[] = [];
    public loadingMessage: string = "Loading Hours Summary...";

    public constructor( @Inject(DMLoggerService) dmLogger: DMLoggerService ) {
        super(dmLogger, Components.HoursSummary);
    }

    public ngOnInit(): void {
        // this.isComponentLoading = false;  
    }

}
