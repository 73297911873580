import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../../common/application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IVirtuosoDetails, IRiskReserveDetails, IVirtuosoEngagementApprovers, VirtuosoRole, IVirtuosoApprovalStatus, VirtuosoApprovalStatus, IVirtuosoUser, VirtuosoChangeRequestStatus, IRiskAndIssuesResponse, IVirtuosoEngagementApproversV2, IVirtuosoApprovalStatusV2 } from "./contracts/virtuoso.contracts";
import { Persona } from "./contracts/changerequest.contract";
import { ProgressBarStatus } from "../../components/tiles/dm-progress-bar/dm-progress-bar.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class VirtuosoService extends DmServiceAbstract {
    private virtuosoServiceBaseUri: string;

    /**
     * @param  {DataService} privatedataService
     * @param  {ConfigManagerService} privateconfigManagerService
     */
    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.VirtuosoService );
        this.virtuosoServiceBaseUri = this.configManagerService.getValue<string>("virtuosoServiceBaseUri");
    }

    /**
     * Get virtuoso link id by passing project id, DataService calls getData passing the url with the project id
     * if there's a response return the projectId wrap in a promise else return return an error wrap in a promise
     * @param  {string} projectId
     * @returns Promise
     */
    public getVirtuosoLinkId(projectId: string): Promise<string> {
        const url = `${this.virtuosoServiceBaseUri}/engagement/FindByMasterProjectId?id=${projectId}`;

        return this.dataService.getData(url, "", APIConstants.VirtuosoLinkAPI)
            .then((response: IVirtuosoDetails) => {
                if (response && response.Projects && response.Projects.length) {
                    return response.Projects[0].ProjectId;
                }
                /* Successful response but Projects array was likely empty. */
                return undefined;
            });
    }

    /**
     * Get risk reserve approve ammount by passing virtuoso link id. DataService calls getData passing the url with the virtuoso link id
     * if there's a response return the risk reserve approve ammount wrap in a promise else return return an error wrap in a promise
     * @param  {string} virtuosoLinkId
     * @returns Promise
     */
    public getApprovedRiskReserveAmount(virtuosoLinkId: string): Promise<IRiskReserveDetails> {
        const url = `${this.virtuosoServiceBaseUri}/engagement/GetApprovedRiskReserve?ProjectId=${virtuosoLinkId}`;
        return this.dataService.getData(url, "", APIConstants.VirtuosoLinkAPI);
    }

    /**
     * Gets the change request approvers for a given financial engagement. Pending Virtuoso contract and
     * endpoint finalization.
     *
     * @param {string} changeRequestId
     * @returns {Promise<IVirtuosoEngagementApprovers[]>}
     * @memberof VirtuosoService
     */
    public getVirtuosoApprovers(engagementId?: string): Promise<IVirtuosoEngagementApprovers[]> {
        const url = `${this.virtuosoServiceBaseUri}/Engagement/GetTPHRRApprovers?engagementId=${engagementId}`;
        return this.dataService.getData(url, "", APIConstants.GetVirtuosoApproverRoles);
    }

    /**
     * Gets the change request approvers for a given financial engagement. Pending Virtuoso contract and
     * endpoint finalization.
     *
     * @param {string} changeRequestId
     * @returns {Promise<IVirtuosoEngagementApproversV2[]>}
     * @memberof VirtuosoService
     */
    public getVirtuosoApproversV2(engagementId?: string): Promise<IVirtuosoEngagementApproversV2> {
        const url = `${this.virtuosoServiceBaseUri}/Engagement/GetTPHRRApproversV2?engagementId=${engagementId}`;
        return this.dataService.getData(url, "", APIConstants.GetVirtuosoApproverRoles);
    }

    /**
     * Gets the approval status for the given change requests.
     *
     * @param {string} changeRequestId
     * @returns {Promise<IVirtuosoApprovalStatus[]>}
     * @memberof VirtuosoService
     */
    public getBulkChangeRequestApprovalStatus(changeRequestIds: string[]): Promise<IVirtuosoApprovalStatus[]> {
        const url = `${this.virtuosoServiceBaseUri}/Request/GetStatus?requestIds=${changeRequestIds.join(",")}`;
        return this.dataService.getData(url, "", APIConstants.GetVirtuosoApprovalStatus);
    }

    /**
     * Gets the approval status for the given change requests. V2 introduces possibility of
     * delegated approver.
     *
     * @param {string} changeRequestId
     * @returns {Promise<IVirtuosoApprovalStatus[]>}
     * @memberof VirtuosoService
     */
    public getBulkChangeRequestApprovalStatusV2(changeRequestIds: string[]): Promise<IVirtuosoApprovalStatusV2[]> {
        const url = `${this.virtuosoServiceBaseUri}/Request/GetStatusV2?requestIds=${changeRequestIds.join(",")}`;
        return this.dataService.getData(url, "", APIConstants.GetVirtuosoApprovalStatus);
    }

    /**
     * Gets the risks and issues for a given Virtuoso project ID.
     *
     * @returns {Promise<IRiskAndIssuesResponse>}
     * @memberof VirtuosoService
     */
    public getEngagementRisksIssues(virtuosoProjectId: string): Promise<IRiskAndIssuesResponse> {
        const url = `${this.virtuosoServiceBaseUri}/risk/ProjectRiskAndIssues?projectId=${virtuosoProjectId}`;
        return this.dataService.getData(url, "", APIConstants.GetVirtuosoRisksAndIssues);
    }

    /**
     * Get users along with their role from virtuoso approvers API response.
     *
     * @private
     * @param {IVirtuosoEngagementApprovers[]} virtuosoResponse
     * @returns {IVirtuosoUser[]}
     * @memberof LaborRequestModalComponent
     */
    public getApproversFromVirtuosoResponse(virtuosoResponse: IVirtuosoEngagementApprovers[]): IVirtuosoUser[] {
        let virtuosoUserProfiles: IVirtuosoUser[] = [];
        for (const projectApprovers of virtuosoResponse) {
            for (const projectRoles of projectApprovers.UserRoles) {
                // Skip PJM as we get PJM from SAP
                if (projectRoles.RoleName !== VirtuosoRole.ProjectManager && projectRoles.RoleName !== VirtuosoRole.LeadProjectManager && projectRoles.Users) {
                    const approverPersona: Persona = this.mapPersonaToVirtuosoRole(projectRoles.RoleName);
                    const vUsers: IVirtuosoUser[] = projectRoles.Users.map((user: IVirtuosoUser) => {
                        return {
                            ...user,
                            roleName: approverPersona
                        };
                    });
                    virtuosoUserProfiles = [...virtuosoUserProfiles, ...vUsers];
                }
            }
        }
        return virtuosoUserProfiles;
    }

    /**
     * Get users along with their role from virtuoso approvers API response.
     *
     * @private
     * @param {IVirtuosoEngagementApproversV2[]} virtuosoResponse
     * @returns {IVirtuosoUser[]}
     * @memberof LaborRequestModalComponent
     */
    public getApproversFromVirtuosoResponseV2(virtuosoResponse: IVirtuosoEngagementApproversV2): IVirtuosoUser[] {
        let virtuosoUserProfiles: IVirtuosoUser[] = [];
        for (const approverLevels of virtuosoResponse.Approvers) {
            // Skip PJM as we get PJM from SAP
            if (approverLevels.User) {
                const approverPersona: Persona = this.mapPersonaToVirtuosoRole(approverLevels.User.RoleName);
                const vUser: IVirtuosoUser = {
                    UserAlias: approverLevels.User.Alias,
                    UserName: approverLevels.User.Name,
                    roleName: approverPersona,
                    level: approverLevels.Level
                };
                virtuosoUserProfiles = [...virtuosoUserProfiles, vUser];
            }
        }
        return virtuosoUserProfiles;
    }

    /**
     * Map virtuoso role to PJM persona.
     *
     * @private
     * @param {VirtuosoRole} virtuosoRole
     * @returns {Persona}
     * @memberof LaborRequestModalComponent
     */
    public mapPersonaToVirtuosoRole(virtuosoRole: VirtuosoRole): Persona {
        switch (virtuosoRole) {
            case VirtuosoRole.LeadProjectManager:
                return Persona.LeadProjectManager;
            case VirtuosoRole.ProjectManager:
                return Persona.ProjectManager;
            case VirtuosoRole.DomainManagerV2:
                return Persona.DomainManager;
            case VirtuosoRole.DomainLeader:
                return Persona.DomainLeader;
            case VirtuosoRole.SplManager:
                return Persona.DeliverySpl;
            case VirtuosoRole.TimezoneLeader:
                return Persona.TimezoneLeader;
            case VirtuosoRole.DeliveryDirector:
                return Persona.DeliveryDirector;
            case VirtuosoRole.DeliveryManagementExecutive:
                return Persona.DeliveryManagementExecutive;
            case VirtuosoRole.ConsultingPracticeLeader:
                return Persona.ConsultingPracticeLeader;
            case VirtuosoRole.AreaDeliveryLeader:
                return Persona.AreaDeliveryLeader;
            case VirtuosoRole.RegionalDeliveryLeader:
                return Persona.RegionalDeliveryLeader;
            default:
                return undefined;
        }
    }

    /**
     * Gets the progress bar status for the virtuoso status given.
     *
     * @private
     * @param {VirtuosoApprovalStatus} virtuosoStatus
     * @returns {ProgressBarStatus}
     * @memberof LaborRequestModalComponent
     */
    public getProgressStatusForVirtuosoStatus(virtuosoStatus: VirtuosoApprovalStatus): ProgressBarStatus {
        switch (virtuosoStatus) {
            case VirtuosoApprovalStatus.Approved:
                return ProgressBarStatus.Completed;
            case VirtuosoApprovalStatus.Pending:
                return ProgressBarStatus.NotStarted;
            case VirtuosoApprovalStatus.Rejected:
                return ProgressBarStatus.Failed;
        }
    }

    /**
     * Determines the current approval level based on the change request status. If not pending with
     * any approver, will return null.
     * 
     *
     * @param {string} topLevelStatus
     * @returns {VirtuosoRole}
     * @memberof VirtuosoService
     */
    public determineCurrentLevel(topLevelStatus: string): VirtuosoRole {
        switch (topLevelStatus) {
            case VirtuosoChangeRequestStatus.PendingWithDmm:
                return VirtuosoRole.DomainManager;
            case VirtuosoChangeRequestStatus.PendingWithDomainLeader:
                return VirtuosoRole.DomainLeader;
            case VirtuosoChangeRequestStatus.PendingWithLeadPjm:
                return VirtuosoRole.LeadProjectManager;
            case VirtuosoChangeRequestStatus.PendingWithSpl:
                return VirtuosoRole.SplManager;
            case VirtuosoChangeRequestStatus.PendingWithTzLeader:
                return VirtuosoRole.TimezoneLeader;
            default:
                return null;
        }

    }

    
    /**
     * Determines the current approval level based on the change request status. If not pending with
     * any approver, will return null.
     *
     * @param {string} topLevelStatus
     * @returns {number}
     * @memberof VirtuosoService
     */
    public determineCurrentLevelV2(topLevelStatus: string): number {
        switch (topLevelStatus) {
            case VirtuosoChangeRequestStatus.PendingLevel1:
                return 1;
            case VirtuosoChangeRequestStatus.PendingLevel2:
                return 2;
            case VirtuosoChangeRequestStatus.PendingLevel3:
                return 3;
            case VirtuosoChangeRequestStatus.PendingLevel4:
                return 4;
            case VirtuosoChangeRequestStatus.PendingLevel5:
                return 5;
            default:
                return null;
        }

    }
}
