<section class="dm-p-x-12-y-imp-0">
    <section class="row">
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-lg-6 col-md-6 col-sm-6 dm-p-imp-0 dm-m-r-15">
                <dm-manage-ebs-entity-details></dm-manage-ebs-entity-details>
            </section>
            <section class="col-lg-6 col-md-6 col-sm-6 dm-p-imp-0">
                <dm-entity-team-structure></dm-entity-team-structure>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-lg-4 col-md-4 col-sm-4 dm-p-imp-0 dm-m-r-15">
                <dm-summary-financials-breakdown></dm-summary-financials-breakdown>
            </section>
            <section class="col-lg-8 col-md-8 col-sm-8 dm-p-imp-0">
                <dm-engagement-summary-financials-v2 *ngIf="!isProjectContext"></dm-engagement-summary-financials-v2>
                <dm-project-summary-financials-v2 *ngIf="isProjectContext"></dm-project-summary-financials-v2>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-m-b-12">
            <dm-engagement-breakdown-structure></dm-engagement-breakdown-structure>
        </section>
    </section>
</section>