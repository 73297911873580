import * as fromActuals from "../actuals/actuals.reducer";
import * as fromBulkIntEngStatusNotifications from "../bulk-internal-engagement-status-notifications/bulk-internal-engagement-status-notifications.reducer";
import * as fromChangeRequests from "../change-requests/change-requests.reducer";
import * as fromClinslin from "../clinslin/clinslin.reducer";
import * as fromContacts from "../contacts/contacts.reducer";
import * as fromCrIdRoles from "../cr-id-roles/cr-id-roles.reducer";
import * as fromCrScreenDetails from "../cr-screen-details/cr-screen-details.reducer";
import * as fromCustomerFinancialsOverview from "../customer-financial-overview/customer-financial-overview.reducer";
import * as fromEngagementChangeRequests from "../engagement-change-requests/engagement-change-requests.reducer";
import * as fromEngagementChangeRequestsV2 from "../engagement-change-requests-v2/engagement-change-requests-v2.reducer";
import * as fromEngagementDetails from "../engagement-details/engagement-details.reducer";
import * as fromEngagementResourceDetails from "../engagement-resource-details/engagement-resource-details.reducer";
import * as fromFinancialDetailsv2 from "../financial-details-v2/financial-details-v2.reducer";
import * as fromFinancialRoles from "../financial-roles/financial-roles.reducer";
import * as fromInternalFinancialDetails from "../internal-financial-details/internal-financial-details.reducer";
import * as fromInvoices from "../invoices/invoices.reducer";
import * as fromManageSuppliers from "../manage-suppliers/manage-suppliers.reducer";
import * as fromEngagementForecastRecommendations from "../engagement-forecast-recommendations/engagement-forecast-recommendations.reducer";
import * as fromProjectForecastRecommendations from "../project-forecast-recommendations/project-forecast-recommendations.reducer";
import * as fromMilestones from "../milestones/milestones.reducer";
import * as fromMyPortfolioEngagementList from "../my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import * as fromMyPortfolioFinancialsList from "../my-portfolio/my-portfolio-financials-list/my-portfolio-financials-list.reducer";
import * as fromNotificationSubscriptions from "../notification-subscriptions/notification-subscriptions.reducer";
import * as fromNpcActuals from "../npc-actuals/npc-actuals.reducer";
import * as fromPlanForecastParams from "../plan-forecast-params/plan-forecast-params.reducer";
import * as fromProjectDetails from "../project-details/project-details.reducer";
import * as fromResourceRequests from "../resource-requests/resource-requests.reducer";
import * as fromResourcesRequestsProjectContext from "../resource-requests-project-context/resource-requests-project-context.reducer";
import * as fromUserPreference from "../userspreferences/userpreference.reducer";
import * as fromWbsDemandsWithoutSchedules from "../demands/demands.reducer";
import * as fromWbsDemandsWithSchedules from "../demands-withschedules/demands-withschedules.reducer";
import * as fromDbDemands from "../db-demands/demands.reducer";
import * as fromWbsDetailsV2 from "../wbs-details/wbs-details.reducer";
import * as fromWbsIdRoles from "../wbs-id-roles/wbs-id-roles.reducer";
import * as fromWbsStructures from "../wbs-structures/wbs-structures.reducer";
import * as fromWriteOperationNotifications from "../write-operation-notifications/write-operation-notifications.reducer";
import * as fromUnitRoles from "../unit-roles/unit-roles.reducer";
import * as fromWbsForecastDetails from "../wbs-forecast-details/wbs-forecast.reducer";
import * as fromWbsCfpDetails from "../wbs-cfp-details/wbs-cfp.reducer";
import * as fromWbsDbDetails from "../wbs-db-details/wbs-db.reducer";

import { ActionReducerMap } from "@ngrx/store";
import { IProcessNotification } from "../../common/services/contracts/process-notifications.contracts";

// compose slice of state -> global app state
export interface IState {
    bulkIntEngStatusNotifications: {
        [referenceId: string]: fromBulkIntEngStatusNotifications.IBulkCreateNotificationState;
    };
    changeRequests: fromChangeRequests.IChangeRequestsState;
    clinslin: {
        [wbsId: string]: fromClinslin.IClinslinState;
    };
    contactsResponse: {
        [wbsId: string]: fromContacts.IContactsState;
    };
    crIdRoles: {
        [crId: string]: fromCrIdRoles.ICrIdRolesState;
    };
    crScreenDetails: {
        [engagementId: string]: fromCrScreenDetails.ICrScreenDetailsState;
    };
    customerFinancialsOverview: {
        [engagementId: string]: fromCustomerFinancialsOverview.ICustomerFinancialsOverviewState;
    };
    db_demands: {
        [wbsId: string]: fromDbDemands.IDemandsState;
    };
    demands: {
        [wbsId: string]: fromWbsDemandsWithoutSchedules.IDemandsState;
    };
    demandsWithschedules: {
        [wbsId: string]: fromWbsDemandsWithSchedules.IDemandsWithSchedulesState;
    };
    engagementChangeRequests: {
        [engagementId: string]: fromChangeRequests.IChangeRequestsState;
    };
    engagementChangeRequestsV2: {
        [engagementId: string]: fromChangeRequests.IChangeRequestsState;
    };
    engagementDetails: {
        [engagementId: string]: fromEngagementDetails.IEngagementDetailsState;
    };    
    engagementForecastRecommendations: {
        [engagementId: string]: fromEngagementForecastRecommendations.IMlForecastRecommendationsState;
    };
    engagementResourceDetails: {
        [engagementId: string]: fromEngagementResourceDetails.IEngagementResourceDetailsState;
    };
    engagementList: fromMyPortfolioEngagementList.IMyPortfolioEngagementListState;
    financialDetailsV2: {
        [engagementId: string]: fromFinancialDetailsv2.IFinancialDetailsV2State;
    };
    financialList: fromMyPortfolioFinancialsList.IMyPortfolioFinancialListState;
    financialRoles: fromFinancialRoles.IFinancialRolesState;
    internalFinancialDetails: {
        [engagementId: string]: fromInternalFinancialDetails.IInternalFinancialDetailsState;
    };
    invoices: {
        [engagementId: string]: fromInvoices.IInvoicesState;
    };
    manageSuppliers: {
        [engagementId: string]: fromManageSuppliers.IManageSuppliersState;
    };
    milestones: {
        [engagementId: string]: fromMilestones.IMilestonesState;
    };
    notificationSubscriptions: {
        [userId: string]: fromNotificationSubscriptions.INotificationState;
    };
    npcActuals: {
        [wbsId: string]: fromNpcActuals.INpcActualsState;
    };
    planForecastParams: {
        [wbsId: string]: fromPlanForecastParams.IPlanForecastParams;
    };
    projectDetails: {
        [projectId: string]: fromProjectDetails.IProjectDetailsState;
    };
    projectForecastRecommendations: {
        [projectId: string]: fromEngagementForecastRecommendations.IMlForecastRecommendationsState;
    };
    resourceRequests: {
        [engagementId: string]: fromResourceRequests.IResourceRequestsDetailsState;
    };
    resourcesRequestsProjectContext: {
        [projectId: string]: fromResourcesRequestsProjectContext.IResourceRequestsDetailsProjectState;
    };
    units: {
        [wbsId: string]: fromActuals.IActualsState;
    };
    unitRoles: fromUnitRoles.IUnitRolesState;
    userPreference: fromUserPreference.IUserPreferenceState;
    wbsDetails: {
        [wbsId: string]: fromWbsDetailsV2.IWbsDetailsV2State;
    };
    wbsIdRoles: {
        [wbsIdRoles: string]: fromWbsIdRoles.IWbsIdRolesState;
    };
    wbsStructures: {
        [wbsId: string]: fromWbsStructures.IWbsStructuresState;
    };
    writeOperationNotifications: {
        [instanceId: string]: IProcessNotification;
    }; // formatted differently than others since it's a notification
    wbsForecastDetails: {
        [wbsId: string]: fromWbsForecastDetails.IWbsForecastState;
    };
    wbsCfpDetails: {
        [wbsId: string]: fromWbsCfpDetails.IWbsCfpState;
    };
    wbsDbDetails: {
        [wbsId: string]: fromWbsDbDetails.IWbsDbState;
    };
}

// register the reducer functions
export const reducers: ActionReducerMap<IState> = {
    bulkIntEngStatusNotifications: fromBulkIntEngStatusNotifications.reducer,
    changeRequests: fromChangeRequests.reducer,
    clinslin: fromClinslin.reducer,
    contactsResponse: fromContacts.reducer,
    crIdRoles: fromCrIdRoles.reducer,
    crScreenDetails: fromCrScreenDetails.reducer,
    customerFinancialsOverview: fromCustomerFinancialsOverview.reducer,
    db_demands: fromDbDemands.reducer,
    demands: fromWbsDemandsWithoutSchedules.reducer,
    demandsWithschedules: fromWbsDemandsWithSchedules.reducer,
    engagementChangeRequests: fromEngagementChangeRequests.reducer,
    engagementChangeRequestsV2: fromEngagementChangeRequestsV2.reducer,
    engagementDetails: fromEngagementDetails.reducer,    
    engagementForecastRecommendations: fromEngagementForecastRecommendations.reducer,
    engagementResourceDetails: fromEngagementResourceDetails.reducer,
    engagementList: fromMyPortfolioEngagementList.reducer,
    financialDetailsV2: fromFinancialDetailsv2.reducer,
    financialList: fromMyPortfolioFinancialsList.reducer,
    financialRoles: fromFinancialRoles.reducer,
    internalFinancialDetails: fromInternalFinancialDetails.reducer,
    invoices: fromInvoices.reducer,
    manageSuppliers: fromManageSuppliers.reducer,
    milestones: fromMilestones.reducer,
    notificationSubscriptions: fromNotificationSubscriptions.reducer,
    npcActuals: fromNpcActuals.reducer,
    planForecastParams: fromPlanForecastParams.reducer,
    projectDetails: fromProjectDetails.reducer,
    projectForecastRecommendations: fromProjectForecastRecommendations.reducer,
    resourceRequests: fromResourceRequests.reducer,
    resourcesRequestsProjectContext: fromResourcesRequestsProjectContext.reducer,
    units: fromActuals.reducer,
    unitRoles: fromUnitRoles.reducer,
    userPreference: fromUserPreference.reducer,
    wbsDetails: fromWbsDetailsV2.reducer,
    wbsIdRoles: fromWbsIdRoles.reducer,
    wbsStructures: fromWbsStructures.reducer,
    writeOperationNotifications: fromWriteOperationNotifications.reducer,
    wbsForecastDetails: fromWbsForecastDetails.reducer,
    wbsCfpDetails: fromWbsCfpDetails.reducer,
    wbsDbDetails: fromWbsDbDetails.reducer
};

export interface ILoadableState {
    loaded: boolean;
    loading: boolean;
    error: any; /* Use any because different APIs may return diff error response types*/
}
