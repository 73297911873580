import { forwardRef, Inject, Component, Input } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { getEntireCustomerFinancialsOverview } from "../../../../../../store/customer-financial-overview/customer-financial-overview.selector";
import { IEngagementList, IEngagementFinancialsListV2, IProjectFinancialsListV2 } from "../../../../../../common/services/contracts/portfolio.model";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { IState } from "../../../../../../store/reducers";
import { RouteName, Components, AccessibilityConstants } from "../../../../../../common/application.constants";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MyPortfolioService } from "../../../../../../common/services/my-portfolio.service";
import { ICustomerFinancialsOverviewState } from "../../../../../../store/customer-financial-overview/customer-financial-overview.reducer";
import { LoadCustomerFinancialOverview } from "../../../../../../store/customer-financial-overview/customer-financial-overview.action";

@Component({
    selector: "customer-engagement-financials-modal",
    templateUrl: "./customer-engagement-financials-modal.html",
    styleUrls: ["./customer-engagement-financials-modal.scss"]
})
export class CustomerEngagementFinancialsModal extends DmModalAbstract {
    @Input() public engagementDetails: IEngagementList;
    public engagementList: IEngagementList[];
    public engagementFinancialList: IEngagementFinancialsListV2[];
    public noEngagementFoundText: string;
    public RouteName = RouteName;
    public projectFinancialList: IProjectFinancialsListV2[];
    public loadingText = "Financials";
    public ariaLabelForCloseBtn: string = "Financials Summary Dialog Close";
    public ariaLabelForFinancialModal: string = "Customer Engagement Financials Overview";
    public tableCaption: string = "Customer Engagement Financials Overview Table";
    public accessibilityConstants = AccessibilityConstants;

    /**
     * Creates an instance of GridDataComponent.
     * @param {DeviceFactoryProvider} deviceFactory
     * @param {SettingsServiceProvider} settingsService
     * @param {UserInfoService} fxpUserInfoService
     * @param {FxpMessageService} fxpMessageService
     * @param {SharedFunctionsService} sharedFunctionsService
     * @param {DMLoggerService} dmLogger
     * @param {ConfigManagerService} configmanagerService
     * @param {NgbModal} modalService
     * @memberof GridDataComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal
    ) {
        super(activeModal, dmLogger, Components.PortfolioGridDataFinancialsTab);
    }

    public ngOnInit(): void {
        this.getCustomerEngagementFinancialsList();
    }

    /**
     * Sets the id for entity name
     */
    public setIdForEntityName(entityType: string, id: string): string {
        return entityType + "-" + id + "-financials";
    }

    /**
     * gets list of financial data for current page items.
     *
     * @private
     * @memberof GridDataComponent
     */
    private getCustomerEngagementFinancialsList(): void {
        this.engagementFinancialList = [];
        this.projectFinancialList = [];
        const customerFinancialOverview$ = this.store.select((getEntireCustomerFinancialsOverview(this.engagementDetails.engagementId)));
        customerFinancialOverview$.pipe(untilDestroyed(this)).subscribe((financialDetails: ICustomerFinancialsOverviewState) => {
            this.setLoadersBasedOnItemState(financialDetails);
            if (financialDetails.loaded) {
                this.engagementFinancialList = financialDetails.financialDetails;
                for (const engagementFinancial of this.engagementFinancialList) {
                    this.projectFinancialList = this.projectFinancialList.concat(engagementFinancial.projectFinancials);
                }
            }
            if (!financialDetails.loading && !financialDetails.loaded && !financialDetails.error) {
                /**
                 * Triggering the dispatch [load call], only after checking that data does not exist in the store,
                 * as this is a modal popup we want to leverage if it already exists in the store.
                 */
                this.store.dispatch(new LoadCustomerFinancialOverview(this.engagementDetails.engagementId));
            }
        });
    }
}
