import { Inject } from "@angular/core";
import { Component, Input } from "@angular/core";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ChangeRequestService } from "../../../common/services/change-request.service";

@Component({
    selector: "dm-timeline",
    templateUrl: "./timeline.html",
    styleUrls: ["./timeline.scss"]
})
export class TimelineComponent {
    @Input() public auditList: any;
    @Input() public selectedFilter: any;
    public itemsPerPage: number[] = [2, 5, 10];
    public isPaginationRequired: boolean = false;
    public slicedItemsNumber: number = 0;
    public gridItemsDisplay: number = 2;
    public activityFeedItemsDisp: number = 2;
    public currentPage: number = 1;

    public Math = Math;

    public constructor(@Inject(ChangeRequestService) public changeRequestService: ChangeRequestService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService) {
        this.sharedFunctionsService.focus("AUDIT", true);
        // this.isPaginationRequired = this.sharedFunctionsService.isPaginationRequired(this.auditList);
    }

    public ngOnInit(): void{
        if (this.auditList) {
            this.isPaginationRequired = this.sharedFunctionsService.isPaginationRequired(this.auditList, 2);
        }
        else {
            this.isPaginationRequired = true;
        }
    }

    public downloadRRAttachment(fileId: string, engagementId: string): void {
        this.changeRequestService.getRRFileAttachment(engagementId, fileId).then((fileUrl) => {
            window.open(fileUrl);
        });
    }
    /**
     * Changes the current page, grid items to display and sliced number on changing the page
     *
     * @param {number} currentPageValue
     */
    public currentPageChangedHandler(currentPageValue: number): void {
        const totalLength = this.auditList.length;
        const lastPage = Math.ceil(totalLength / this.activityFeedItemsDisp);
        if (currentPageValue === 1) {
            this.slicedItemsNumber = 0;
            this.gridItemsDisplay = this.activityFeedItemsDisp;
        } else if (this.currentPage < currentPageValue && currentPageValue !== lastPage) {
            this.slicedItemsNumber = this.gridItemsDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay + this.activityFeedItemsDisp;
        } else if (currentPageValue === lastPage) {
            this.slicedItemsNumber = (lastPage - 1) * this.activityFeedItemsDisp;
            this.gridItemsDisplay = totalLength;
        } else {
            let displayValue = this.activityFeedItemsDisp;
            if ((this.gridItemsDisplay - this.slicedItemsNumber) < this.activityFeedItemsDisp) {
                displayValue = this.gridItemsDisplay - this.slicedItemsNumber;
            }
            this.slicedItemsNumber = this.slicedItemsNumber - this.activityFeedItemsDisp;
            this.gridItemsDisplay = this.gridItemsDisplay - displayValue;
        }
        this.currentPage = currentPageValue;
    }

    /**
     * Fetches the item and assigning it to grid
     */
    public itemPerPageChangedHandler(itemCount: string): void {
        this.gridItemsDisplay = Number(itemCount);
        this.activityFeedItemsDisp = this.gridItemsDisplay;
    }
}


