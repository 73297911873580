import { Action } from "@ngrx/store";
import { IEngagementDetailsApiV2, ITaskDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IWbsEditEngagementDetails } from "../../common/services/contracts/wbs.service.contracts";

// 1. action types
export enum EngagementDetailsActionTypes {
    LOAD_ENGAGEMENT_DETAILS = "[EngagementDetails] Load",
    UPDATE_LOCALLY_ENGAGEMENT_DETAILS = "[EngagementDetails] Update Locally",
    UPDATE_LOCALLY_ADD_TASK = "[EngagementDetails] Update Locally - Task Added",
    LOAD_ENGAGEMENT_DETAILS_SUCCESS = "[EngagementDetails] Load Success",
    LOAD_ENGAGEMENT_DETAILS_FAIL = "[EngagementDetails] Load Fail",
    INVALIDATE_ENGAGEMENT_DETAILS = "[EngagementDetails] Invalidate"
}

// 2. action creators
export class LoadEngagementDetails implements Action {
    public readonly type = EngagementDetailsActionTypes.LOAD_ENGAGEMENT_DETAILS;
    public constructor(public engagementId: string) { }
}

export class UpdateLocallyEngagementDetails implements Action {
    public readonly type = EngagementDetailsActionTypes.UPDATE_LOCALLY_ENGAGEMENT_DETAILS;
    public constructor(public engagementId: string, public editEngagementDetails: IWbsEditEngagementDetails) { }
}

export class UpdateLocallyAddTaskDetails implements Action {
    public readonly type = EngagementDetailsActionTypes.UPDATE_LOCALLY_ADD_TASK;
    public constructor(public engagementId: string, public projectID: string, public serviceId: string, public newTaskDetails: ITaskDetailsV2) { }
}

export class LoadEngagementDetailsSuccess implements Action {
    public readonly type = EngagementDetailsActionTypes.LOAD_ENGAGEMENT_DETAILS_SUCCESS;
    public constructor(public engagementId: string, public engagementDetails: IEngagementDetailsApiV2) { }
}
export class LoadEngagementDetailsFail implements Action {
    public readonly type = EngagementDetailsActionTypes.LOAD_ENGAGEMENT_DETAILS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateEngagementDetails implements Action {
    public readonly type = EngagementDetailsActionTypes.INVALIDATE_ENGAGEMENT_DETAILS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type EngagementDetailsAction =
    | LoadEngagementDetails
    | UpdateLocallyEngagementDetails
    | UpdateLocallyAddTaskDetails
    | LoadEngagementDetailsSuccess
    | LoadEngagementDetailsFail
    | InvalidateEngagementDetails;