import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ReleaseAndActivateSummaryComponent } from "./release-and-activate-summary.component";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { ActivateEngagementModalModule } from "../../financial-plan/activate-engagement-modal/activate-engagement-modal.module";
import { FinancialService } from "../../../../../common/services/financial.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { DmNotificationService } from "../../../../../common/services/dm-notification.service";
import { DmUserProfileModule } from "../../../../../components/shared/user-name/user-name.module";
import { NavigationService } from "../../../../../common/services/navigation.service";

@NgModule({
    declarations: [
        ReleaseAndActivateSummaryComponent,
    ],
    imports: [
        CommonModule,
        ActivateEngagementModalModule,
        NgbModule,
        UIRouterModule,
        DmUserProfileModule
    ],
    providers: [
        DMAuthorizationService,
        DMLoggerService,
        DmNotificationService,
        FinancialService,
        NavigationService,
        ProjectServiceFunctions,
        SharedFunctionsService
    ],
    entryComponents: [
        ReleaseAndActivateSummaryComponent,
    ],
    exports: [
        ReleaseAndActivateSummaryComponent,
    ]
})
export class ReleaseAndActivateSummaryModule { }
