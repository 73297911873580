import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { ContactsComponent } from "./contacts.component";
import { DmUserProfileModule } from "../user-name/user-name.module";
import { CommonComponentsModule } from "../../entry/common-components.module";

@NgModule({
    declarations: [
        ContactsComponent
    ],
    imports: [
        CommonModule,
        DmUserProfileModule,
        CommonComponentsModule
    ],
    providers: [
    ],
    entryComponents: [
        ContactsComponent
    ],
    exports: [
        ContactsComponent
    ],
})
export class ContactsModule { }
