import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { ContactsModule } from "../../shared/contacts/contacts.module";
import { ContactsService } from "../../../common/services/contacts.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmContactsTileV2Component } from "./dm-contacts-tile-v2.component";
import { NgModule } from "@angular/core";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { UIRouterModule } from "@uirouter/angular";

@NgModule({
    declarations: [
        DmContactsTileV2Component
    ],
    imports: [
        UIRouterModule,
        CommonComponentsModule,
        CommonModule,
        ContactsModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
        ContactsService
    ],
    exports: [
        DmContactsTileV2Component,
    ],
    entryComponents: [
        DmContactsTileV2Component,
    ]
})
export class DmContactsTileV2Module { }
