import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProjectSummaryServiceV2Component } from "./project-summary-service-v2.component";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ProjectDetailService } from "../../../../common/services/project-detail.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { UIRouterModule } from "@uirouter/angular";
import { FeedbackModalService } from "../../../../components/tiles/feedback-modal/feedback-modal.service";

@NgModule({
    declarations: [
        ProjectSummaryServiceV2Component
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule
    ],
    providers: [
        DMLoggerService,
        ProjectDetailService,
        SharedFunctionsService,
        FeedbackModalService
    ],
    entryComponents: [
        ProjectSummaryServiceV2Component
    ],
    exports: [
        ProjectSummaryServiceV2Component
    ]
})
export class ProjectSummaryServiceV2Module { }
