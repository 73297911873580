import { Persona } from "./changerequest.contract";

export interface IProject {
    ProjectName: string;
    ProjectId: string;
    SourceProjectCodes: string[];
}

export interface IVirtuosoDetails {
    EngagementName: string;
    SourceEngagementCode: string;
    EngagementId: string;
    Projects: IProject[];
}


export interface IRiskReserveDetails {
    ProjectName: string;
    ProjectId: string;
    SourceProjectCodes: string[];
    RiskReserveApprovedAmount: string;
    RiskReserveCurrency: string;
}

export interface IVirtuosoEngagementApprovers {
    ProjectId: number;
    EngagementId: string;
    UserRoles: IVirtuosoRole[];
}

export interface IVirtuosoRole {
    RoleId: number;
    RoleName: VirtuosoRole;
    Users: IVirtuosoUser[];
}

export interface IVirtuosoUser {
    UserName: string;
    UserAlias: string;
    roleName?: Persona;
    level?: number;
}

export interface IVirtuosoEngagementApproversV2 {
    ProjectId: number;
    EngagementID: string;
    Approvers: IVirtuosoApproverLevel[];
}

export interface IVirtuosoApproverLevel {
    Level: number;
    User: IVirtuosoUserV2;
}

export interface IVirtuosoUserV2 {
    Alias: string;
    Name: string;
    RoleId: number;
    RoleName: VirtuosoRole;
}

export interface IVirtuosoApprovalStatus {
    RequestId: string;
    Status: VirtuosoChangeRequestStatus;
    ProjectId: number;
    EngagementId: string;
    ApprovalLevels: IApprovalLevel[];
}

export interface IApprovalLevel {
    RoleId: number;
    RoleName: VirtuosoRole;
    UserName: string;
    UserAlias: string;
    ApprovalStatus: VirtuosoApprovalStatus;
    ApprovalDate: Date;
}

export interface IVirtuosoApprovalStatusV2 {
    RequestId: string;
    Status: VirtuosoChangeRequestStatus;
    ProjectId: number;
    EngagementId: string;
    ApprovalLevels: IApprovalLevelV2[];
}

export interface IApprovalLevelV2 {
    RoleId: number;
    RoleName: VirtuosoRole;
    AssignedToUserName: string;
    AssignedToUserAlias: string;
    ApprovedByUserName: string;
    ApprovedByUserAlias: string;
    ApprovalStatus: VirtuosoApprovalStatus;
    ApprovalDate: Date;
    Level: number;
}

export interface IRiskAndIssuesResponse {
    risks: IRiskAndIssueData;
    issues: IRiskAndIssueData;
}

export interface IRiskAndIssueData {
    data: IRiskIssue[];
}

export interface IRiskIssue {
    id: string;
    title: string;
}

export enum VirtuosoRole {
    DomainLeader = "Domain Leader",
    DomainManager = "DMM - Domain",
    DomainManagerV2 = "Lead Domain DMM",
    LeadProjectManager = "Lead Project Manager",
    ProjectManager = "Project Manager",
    SplManager = "SPL - Domain",
    TimezoneLeader = "TZ Leader - Domain",
    DeliveryDirector = "Delivery Director",
    DeliveryManagementExecutive = "Delivery Management Executive",
    ConsultingPracticeLeader = "Consulting Practice Leader",
    AreaDeliveryLeader = "Area Delivery Leader",
    RegionalDeliveryLeader = "Regional Delivery Leader"
}

export enum VirtuosoApprovalStatus {
    Approved = "Approved",
    Pending = "Pending",
    Rejected = "Rejected",
    SentBack = "Sent Back",
}

export enum VirtuosoChangeRequestStatus {
    Approved = "Approved",
    Cancelled = "Cancelled",
    Closed = "Closed",
    Open = "Open",
    PendingWithDmm = "Pending With DMM",
    PendingWithDomainLeader = "Pending With Domain Leader",
    PendingWithLeadPjm = "Pending With Lead Project Manager",
    PendingWithSpl = "Pending With SPL",
    PendingWithTzLeader = "Pending With TZ Leader",
    PendingLevel1 = "Pending with Level 1 Approver",
    PendingLevel2 = "Pending with Level 2 Approver",
    PendingLevel3 = "Pending with Level 3 Approver",
    PendingLevel4 = "Pending with Level 4 Approver",
    PendingLevel5 = "Pending with Level 5 Approver",
    Rejected = "Rejected",
}