import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ChangeRequestDemandService } from "../../common/services/change-request-demand.service";
import { ChangeRequestService } from "../../common/services/change-request.service";
import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { CostSummaryModule } from "./cost-summary/cost-summary.module";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { EngagementFinancialPlanSummaryModule } from "./engagement-financial-plan-summary/engagement-financial-plan-summary.module";
import { FcrDetailsModule } from "./fcr-details/fcr-details.module";
import { FinancialChangeRequestComponent } from "./financial-change-request.component";
import { FinancialPlanService } from "../../common/services/financial-plan.service";
import { HoursSummaryModule } from "./hours-summary/hours-summary.module";
import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { ToggleSectionModule } from "../shared/toggle-section/toggle-section.module";
import { TphRrDetailsModule } from "./tph-rr-details/tph-rr-details.module";
import { UIRouterModule } from "@uirouter/angular";
import { VirtuosoService } from "../../common/services/virtuoso.service";
import { FcrUnitsFormControlModule } from "./fcr-units-form-control/fcr-units-form-control.module";
import { FcrRolesFormControlModule } from "./fcr-roles-form-control/fcr-roles-form-control.module";
import { FcrSubconFfFormControlModule } from "./fcr-subconff-form-control/fcr-subconff-form-control.module";
import { FcrExpensesFormControlModule } from "./fcr-expenses-form-control/fcr-expenses-form-control.module";
import { CancelOrContinueModal } from "./modals/cancel-or-continue-modal/cancel-or-continue-modal.component";

@NgModule({
    declarations: [
        FinancialChangeRequestComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        ToggleSectionModule,
        HoursSummaryModule,
        CostSummaryModule,
        EngagementFinancialPlanSummaryModule,
        FcrDetailsModule,
        FcrRolesFormControlModule,
        FcrUnitsFormControlModule,
        FcrSubconFfFormControlModule,
        FcrExpensesFormControlModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        TphRrDetailsModule, 
        NgbModule,
        UIRouterModule.forChild(),
    ],
    providers: [
        ChangeRequestDemandService,
        ConfigManagerService,
        SharedFunctionsService,
        ChangeRequestService,
        DMLoggerService,
        VirtuosoService,
        FinancialPlanService      
    ],
    entryComponents: [
        FinancialChangeRequestComponent,
        CancelOrContinueModal
    ],
    exports: [
        FinancialChangeRequestComponent
    ]
})
export class FinancialChangeRequestModule { }
