import { Action } from "@ngrx/store";
import { IUnitsViewModel } from "../../common/services/contracts/actuals.contracts";

// 1. action types
export enum ActualsActionTypes {
    LOAD_ACTUALS = "[Actuals] Load",
    LOAD_ACTUALS_SUCCESS = "[Actuals] Load Success",
    UPDATE_ACTUALS_LOCAL = "[Actuals] Update Local",
    LOAD_ACTUALS_FAIL = "[Actuals] Load Fail",
    INVALIDATE_ACTUALS = "[Actuals] Invalidate"
}

// 2. action creators
export class LoadActuals implements Action {
    public readonly type = ActualsActionTypes.LOAD_ACTUALS;
    public constructor(public wbsId: string) { }
}
export class LoadActualsSuccess implements Action {
    public readonly type = ActualsActionTypes.LOAD_ACTUALS_SUCCESS;
    public constructor(public wbsId: string, public units: IUnitsViewModel[]) { }
}

export class UpdateActualsLocal implements Action {
    public readonly type = ActualsActionTypes.UPDATE_ACTUALS_LOCAL;
    public constructor(public wbsId: string, public units: IUnitsViewModel[]) { }
}

export class LoadActualsFail implements Action {
    public readonly type = ActualsActionTypes.LOAD_ACTUALS_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateActuals implements Action {
    public readonly type = ActualsActionTypes.INVALIDATE_ACTUALS;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type ActualsAction =
    | LoadActuals
    | LoadActualsSuccess
    | UpdateActualsLocal
    | LoadActualsFail
    | InvalidateActuals;