import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReturnedResourceRequestComponent } from "./returned-resource-modal.component";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        ReturnedResourceRequestComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        ReturnedResourceRequestComponent
    ],
    exports: [
        ReturnedResourceRequestComponent
    ]
})
export class ReturnedResourceRequestModalModule { }
