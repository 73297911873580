<div class="whats-new-modal display-grid">
    <div class="modal-header modal-noborder dm-p-10">
        <h4 class="modal-title pull-left text-uppercase" id="whatsNewHeading">
            What's New
        </h4>
        <button type="button" class="close set-focus" title="Close" id="closeWhatsNewButton" (click)="closeModal()" (keydown.shift.tab)="moveFocus($event, 'next-slide')">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
    </div>
    <section *ngIf="whatsNewList && whatsNewList.features" class="modal-body">
        <div class="dm-m-b-20 whats-new-body-header">
            <div class="display-flex-imp">
                <span title="What's New" class="icon-full icon-news dm-m-t-imp-2"></span>
                <span class="dm-tile__large-tile-title dm-p-x-10">{{whatsNewList.title}}</span>
            </div>
            <button *ngIf="showSlideView" class="dm-link dm-m-t-imp-2" [title]="titleForBacktoSummaryView"
                (click)="switchToFeatureSummaryView()">
                <span class="icon icon-Back"></span>
                Back to Summary View
            </button>
        </div>
        <section *ngIf="!showSlideView" class="whats-new-section dm-m-imp-0">
            <section class="feature" *ngFor="let whatsNewFeature of whatsNewList.features; let index = index;">
                <span class="dm-badge feature-tag" *ngIf="whatsNewFeature.tag"
                    [ngClass]="{'tag-new': whatsNewFeature.tag === whatsNewTag.New, 'tag-performance': whatsNewFeature.tag === whatsNewTag.Performance, 'tag-maintenance': whatsNewFeature.tag === whatsNewTag.Maintenance }">
                    {{whatsNewFeature.tag}}
                </span>
                <h5 class="dm-m-t-imp-6">
                    <span class="feature-title">{{whatsNewFeature.name}}</span>
                </h5>
                <span class="font-caption-alt dm-p-r-imp-4" [innerHtml]="whatsNewFeature.description">
                </span>
                <span *ngIf="whatsNewFeature.jobAidLink"><br /></span>
                <button [id]="'more-slide-view-' + index" *ngIf="whatsNewFeature.slides && whatsNewFeature.slides.length" class="dm-link whatsNewFeatureButton"
                    (click)="switchToFeatureSlideView(whatsNewFeature)" [attr.aria-label]="setTitleOrAriaLabelForKnowMore(whatsNewFeature.name)"> 
                    <span [attr.title]="setTitleOrAriaLabelForKnowMore(whatsNewFeature.name)">More...</span>
                </button>
                <a *ngIf="whatsNewFeature.jobAidLink"
                    class="dm-link line-height-12 no-pad-right font-caption-alt pull-right"
                    [title]="setTitleOrAriaLabelForKnowMore(whatsNewFeature.name)" target="_blank"
                    href="{{whatsNewFeature.jobAidLink}}" aria-label="open link in new tab">
                    <span class="icon icon-education"></span> Learn more</a>
            </section>
        </section>
        <section *ngIf="showSlideView">
            <h5 class="dm-p-b-10" *ngIf="!hideSlideName">
                <span class="feature-title">{{featureTitleForSlideView}}</span>
            </h5>
            <slide-view [slideList]="slideListForFeature"></slide-view>
        </section>
    </section>
</div>