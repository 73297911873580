import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { NewSnapshotModalModule } from "./snapshot-modals/new-snapshot/new-snapshot.module";
import { ProjectService } from "../../../../common/services/project.service";
import { RenameSnapshotModalModule } from "./snapshot-modals/rename-snapshot/rename-snapshot.module";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { SnapshotComponent } from "./snapshot.component";

@NgModule({
    declarations: [
        SnapshotComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        NewSnapshotModalModule,
        NgbModule,
        RenameSnapshotModalModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        FinancialService,
        ProjectService,
        SharedFunctionsService,
    ],
    entryComponents: [
        SnapshotComponent
    ],
    exports: [
        SnapshotComponent
    ]
})
export class SnapshotsModule { }
