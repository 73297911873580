import { Component, forwardRef, Inject, Input, Output, EventEmitter } from "@angular/core";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { Components, RouteName } from "../../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BulkUploadInternalEngagementService } from "../../../../common/services/bulk-upload-internal-engagement.service";
import { IBulkUploadSummaryStatusResponse, IBulkIntEngSummaryEngagementStatus, BulkIntEngStatus, BulkIntEngStatusForDisplay } from "../../../../common/services/contracts/bulk-upload-int-engagement.service.contracts";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-bulk-create-int-engagement-status-modal",
    templateUrl: "./bulk-create-int-engagement-status-modal.html",
    styleUrls: ["./bulk-create-int-engagement-status-modal.scss"]
})
export class BulkCreateIntEngagementStatusModalComponent extends DmModalAbstract {
    @Input() public bulkUploadReferenceId: string;
    @Output() public onStatusModalClose: EventEmitter<void> = new EventEmitter<void>();
    public intEngStatusDetails: IBulkIntEngSummaryEngagementStatus[];
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public BulkIntEngStatusForDisplay = BulkIntEngStatusForDisplay;
    public displayStatusIcon: boolean = false;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(BulkUploadInternalEngagementService) private bulkUploadInternalEngagementService: BulkUploadInternalEngagementService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.BulkCreateInternalEngagementStatusModal);
    }

    public ngOnInit(): void {
        this.setLoadersBasedOnItemState();
        this.bulkUploadInternalEngagementService.getAllBulkIntEngUploadStatus(this.bulkUploadReferenceId).then((response: IBulkUploadSummaryStatusResponse) => {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            // Adding engagement link - TODO: change using uirouter to route.
            that.intEngStatusDetails = response.engagements.map((eng) => {
                return {
                    ...eng,
                    link: `#/pjm/internalEngagement/${eng.engagementId}/summary`
                };
            });
            that.sharedFunctionsService.sortListByProperty(this.intEngStatusDetails, "engagementId");
        });
        this.shouldDisplayStatusIcon();
    }
    /**
     * Closes modal and emits an event to notify notification component of closure.
     *
     * @memberof BulkCreateIntEngagementStatusModalComponent
     */
    public closeStatusModal(): void {
        this.onStatusModalClose.emit();
        this.closeModal();
    }

    public onRetry(id: string): void {
        const intEngagementStatusDetail = this.intEngStatusDetails.find((e) => {
            return e.id === id;
        });
        intEngagementStatusDetail.status = BulkIntEngStatus.QueuedForCreation;
        const updateStatusRequest = {
            status: BulkIntEngStatus.QueuedForCreation,
            engagementIds: [id]
        };
        this.bulkUploadInternalEngagementService.updateEngagementStatus(this.bulkUploadReferenceId, updateStatusRequest);
    }

    /**
     * Filter the internal engagement statuses and 
     * tell whether status icon need to be shown or not
     */
    private shouldDisplayStatusIcon(): void {
        if (this.intEngStatusDetails && this.intEngStatusDetails.length) {
            this.displayStatusIcon = this.intEngStatusDetails.filter((intEngStatusDetail) => intEngStatusDetail.status === (BulkIntEngStatus.CreationFailed || BulkIntEngStatus.CreationSuccessful || BulkIntEngStatus.ValidationInProgress || BulkIntEngStatus.QueuedForCreation || BulkIntEngStatus.Discarded)).length > 0 ? true : false;
        }
    }
}

