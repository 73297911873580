<section class="dm-plan-forecast">
    <div *ngIf="!isInMigrationFlight">
        <dm-loading [loadingText]="'Plan & Forecast'" [errorText]="!isInMigrationFlight ? errorText : ''" [showLoading]="showLoading"
            *ngIf="isComponentLoading">
        </dm-loading>
        <div *ngIf="isComponentLoading && showMissingSAPRoleBotButton" class="bot-btn dm-m-t-10">
            <button aria-label="Bot" class="dm-link display-flex font-caption-alt" title="Bot" type="button"
                (click)="onSapValidRoleMissing()">
                <i class="icon iris-bot-icon"></i><span class="dm-p-t-5 dm-p-l-5">Request SAP Access via Bot</span>
            </button>
        </div>
        <div *ngIf="isComponentLoading && showExpiredSAPAccessBotButton" class="bot-btn dm-m-t-10">
            <button aria-label="Bot" class="dm-link display-flex font-caption-alt" title="Bot" type="button"
                (click)="onSapAccountValidityExpired()">
                <i class="icon iris-bot-icon"></i><span class="dm-p-t-5 dm-p-l-5">Request SAP Access via Bot</span>
            </button>
        </div>
        <div class="toolbox display-flex">
            <button type="button" class="dm-transparent-btn dm-p-x-5 set-focus" *ngIf="showTroubleshootLoginIssuesButton" (click)="openLoginIssuesModal(loginIssuesModal)">
                <span class="icon-full icon icon-Repair troubleshoot">Troubleshoot Login Issues</span>
            </button>
            <button type="button" class="dm-transparent-btn dm-p-x-5 set-focus" title="Plan & Forecast Feedback Survey" (click)="openPlanForecastFeebackModal()">
                <span class="icon-full icon-feedback"></span>
            </button>        
        </div>
    
        <div *ngIf="!isComponentLoading && !isUserAuthenticatedToLumira" class="bot-btn dm-m-10">
            <button aria-label="Login" class="fxp-btn fxp-btn--primary font-caption-alt" title="Login" type="button"
                (click)="launchLumiraLoginHelper()">Login to Plan &amp;
                Forecast
            </button>
        </div>
    
        <!-- CodeQL [SM02170] Our threat model renders this safe -->
        
        <iframe *ngIf="!isUserAuthenticatedToLumira" id="pjm-plan-forecast-login-frame" title="Plan & Forecast Login"
            width="100%" style="height: calc(100vh - 160px); display: none"
            sandbox="allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-forms allow-modals allow-pointer-lock allow-popups allow-popups-to-escape-sandbox"
            [src]="planForecastLoginHelperUrl">
        </iframe>
        
        <!-- CodeQL [SM02170] Our threat model renders this safe -->
    
        <iframe [style.visibility]="isUserAuthenticatedToLumira ? 'visible' : 'hidden'" id="pjm-plan-forecast-frame"
            title="Plan & Forecast" width="100%" style="height: calc(100vh - 160px)"
            sandbox="allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-forms allow-modals allow-pointer-lock allow-popups allow-popups-to-escape-sandbox"
            [src]="planForecastUrl">
        </iframe>
    
        <ng-template #loginIssuesModal let-modal>
            <div class="faq-modal">
                <div class="modal-header modal-noborder">
                    <h4 class="modal-title font-subtitle dm-m-imp-0" id="faqModalHeading">
                        Troubleshoot Login Issues
                    </h4>
                    <button type="button" aria-label="FAQ Dialog Close" class="close set-focus margin-left-auto"
                        id="closeFAQ" (click)="modal.close()">
                        <span aria-hidden="true" class="icon icon-close"></span>
                    </button>
                </div>
                <div class="modal-body dm-plan-forecast-faq-modal">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                        <ngb-panel title="Are you seeing a blank page?">
                            <ng-template ngbPanelContent>
                                <img src="https://dmui.azureedge.net/email-images/login-microsoftonline-refused-to-connect.png"
                                    alt="Blank Page" loading="lazy">
                                <br>
                                It could be because you're logged into Azure Active Directory with more than one account.
                                <a [href]="planForecastLoginHelperAppUrl" target="lumirapopup"
                                    (click)="launchLumiraLoginHelper()" style="font-weight: bold;">
                                    Click here
                                </a>
                                to open the popup where you can select the <i>microsoft.com</i> account. Once account
                                selection
                                is complete the popup will close itself and application will reload.
                                <p>If see you any error during this process, please take a screenshot and open a support
                                    ticket.
                                </p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <span>Are you seeing a 404 Error Page?</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <img src="https://dmui.azureedge.net/email-images/plan-forecast-404-missing-page.png"
                                    alt="404 Missing Page Error" loading="lazy">
                                <br>
                                It could be because the Azure AD Authentication Token need to be refreshed.
                                <p>
                                    Go to <a href="https://login.microsoftonline.com/logout.srf" target="_blank"
                                        (click)="trackSignoutLinkClicked()">https://login.microsoftonline.com/logout.srf</a>
                                    to signout of Azure AD Accounts.
                                    After signout is complete, close the browser and open again.
                                </p>
                                Or, in Chrome, try the following the steps to clear the Cookies
                                <ul>
                                    <li class="dot-list">Press <kbd>Ctrl+Shift+Del</kbd>, which will bring up Clear Browsing
                                        Data popup</li>
                                    <li class="dot-list"> On Basic Tab , select “All Time” in Time Range and Select “Cookies
                                        and
                                        other site data” (other options need not be checked)
                                    </li>
                                    <li class="dot-list">Click Clear data and reload the app.</li>
                                </ul>
                                <p>
                                    You could also try opening ESXP in an in-private window.
                                </p>
                                If you are still facing issues and currently using latest edge browser version.
                                <br>
    
                                <ul>
                                    <li class="dot-list">Go to <a href="https://login.microsoftonline.com/logout.srf"
                                            target="_blank"
                                            (click)="trackSignoutLinkClicked()">https://login.microsoftonline.com/logout.srf</a>
                                        to signout of Azure AD Accounts.
                                        After signout is complete, close the browser.
                                    </li>
                                    <li class="dot-list">Right click on your Start Menu icon and select "Settings".
                                        <br>
                                        <img src="https://pjmuiprod.azureedge.net/plan-forecast-issues/start-menu-right-click.png"
                                            alt="Edge dev issue 1" loading="lazy">
                                    </li>
                                    <li class="dot-list">Select "Accounts".
                                        <br>
                                        <img src="https://pjmuiprod.azureedge.net/plan-forecast-issues/accounts.png"
                                            alt="Edge dev issue 2" loading="lazy">
                                    </li>
                                    <li class="dot-list">Select "Email & accounts" on the side bar.
                                    </li>
                                    <li class="dot-list">Click on "Add a work or school account" (even if you are already
                                        logged in).
                                        <br>
                                        <img src="https://pjmuiprod.azureedge.net/plan-forecast-issues/email-and-accounts.png"
                                            alt="Edge dev issue 3" loading="lazy">
                                    </li>
                                    <li class="dot-list">Follow the steps and sign into your Microsoft work account.
                                    </li>
                                    <li class="dot-list">Reopen Plan & Forecast.
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
    
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <span>Screen is stuck on “Loading modules…” ?</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <img src="https://dmui.azureedge.net/email-images/plan-forecast-loading-modules.jpg"
                                    alt="Loading Stuck on Modules" loading="lazy">
                                <br />
                                Follow the steps below to clear browser cache to load the modules again from the server.
                                <br>
    
                                <ul>
                                    <li class="dot-list">In the browser , press <kbd>F12</kbd> (or More Tools -> Developer
                                        Tools)</li>
                                    <li class="dot-list">Once the Developer Tools window is open, right-click on refresh and
                                        select “Empty cache and hard refresh”
                                        <img src="https://dmui.azureedge.net/email-images/browser-reload-options.png"
                                            alt="Browser Reload Options" loading="lazy" style="padding-left: 10%;">
    
                                    </li>
                                    <li class="dot-list">This will reload all the client side component again from server.
                                        You
                                        can close the Developer Tools window by pressing F12.</li>
                                </ul>
                                <p>
                                    You could also try opening ESXP in an in-private window.
                                </p>
                            </ng-template>
                        </ngb-panel>
    
                    </ngb-accordion>
                </div>
            </div>
    
        </ng-template>
    </div>
    <div *ngIf="isInMigrationFlight">
        <div class="migrationFlightText">
            Your Engagement is being upgraded to the new Forecasting Experience. Thank you for your patience!
        </div>
    </div>

</section>