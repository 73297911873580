import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ActivityFeed } from "./activity-feed.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { KeyActionsV2Module } from "../key-actions-v2/key-actions-v2.module";
import { ActivityFeedItemModule } from "../activity-feed-item/activity-feed-item.module";

@NgModule({
    declarations: [
        ActivityFeed
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
        NgbModule,
        KeyActionsV2Module,
        ActivityFeedItemModule
    ],
    providers: [
        SharedFunctionsService,
        DMLoggerService
    ],
    entryComponents: [
        ActivityFeed
    ],
    exports: [
        ActivityFeed
    ]
})
export class ActivityFeedModule { }
