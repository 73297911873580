<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'200px'" [hasNonOverflowContent]="true"
    [isLoading]="isUpdatingProject">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isUpdatingProject">
    </dm-loading>
    <div *ngIf="!isUpdatingProject">
        <!-- Form section -->
        <form [formGroup]="editProjectTeamDetailsForm">
            <div class="dm-entity__name">{{selectedProject?.name}}</div>
            <div class="dm-entity__id dm-p-b-12">{{selectedProject?.id}}</div>
            <div class="dm-form-entity">
                <label class="dm-form-entity__label">
                    Project Manager
                </label>
                <dm-type-ahead [typeAheadId]="typeAheadId" [btnSearchAriaLabelText]="taSearchAriaLblTxt"
                    [btnCancelAriaLabelText]="taCancelAriaLblTxt" formControlName="pjm" [typeAheadLabelText]="'Project Manager'"
                    [typeAheadUserValidationRequiredMessage]="taRequiredMsg" [disabled]="disablePJMUpdate"
                    [selectedUser]="selectedUser" [isRequired]="true" [isMultiselect]="false" [fteOnly]="true"
                    [modelValue]="selectedUser" [isMandatory]="true" [isTypeAheadV2]="true" [showTypeAheadLabel]="false"
                    (selectedUserUpdated)="selectedUserUpdated($event)" (onUserCleared)="onUserCleared($event)">
                </dm-type-ahead>
                <span role="alert" class="font-caption-alt inp-validation dm-p-b-5" aria-atomic="true"
                    aria-live="polite" *ngIf="showNonfteValidationMessage">
                    {{editProjectErrorMessages.OnlyFteCanFill}}
                </span>
            </div>
            <div class="dm-form-entity">
                <label class="dm-form-entity__label">
                    Additional project managers
                </label>
                <dm-type-ahead (multiSelectedUsersUpdated)="onAdditionalManagersUpdate($event)"
                    (multiSelectedRemovedUser)="onAdditionalManagerRemoved($event)" [showTypeAheadLabel]="false"
                    (selectedUserUpdated)="onAdditionalManagerAdded($event)" [typeAheadId]="additionalTypeAheadId" [typeAheadLabelText]="'Additional Project Managers'"
                    [btnSearchAriaLabelText]="additionalSearchAriaLblTxt" [isMandatory]="false" formControlName="adPjm"
                    [btnCancelAriaLabelText]="additionalCancelAriaLblTxt" [disabled]="disableAPJMUpdate"
                    [selectedUsers]="selectedUsers" [isRequired]="false" [isMultiselect]="true" [fteOnly]="false"
                    [isTypeAheadV2]="true">
                </dm-type-ahead>
            </div>            
            <div *ngIf="selectedProject.hasUnitBasedDemands && isComplexUnitsFeatureEnabled" class="dm-form-entity">
                <label class="dm-form-entity__label">
                    Unit Submitters
                </label>
                <dm-type-ahead (multiSelectedUsersUpdated)="onUnitSubmitterUpdate($event)"
                    (multiSelectedRemovedUser)="onUnitSubmitterRemoved($event)" [showTypeAheadLabel]="false"
                    (selectedUserUpdated)="onUnitSubmitterAdded($event)" [typeAheadId]="uSubmitterTypeAheadId" [typeAheadLabelText]="'Unit Submitters'"
                    [btnSearchAriaLabelText]="uSubmitterSearchAriaLblTxt" [isMandatory]="false" formControlName="uSubmitters"
                    [btnCancelAriaLabelText]="uSubmitterCancelAriaLblTxt" [disabled]="disableUnitSubmitterUpdate"
                    [selectedUsers]="selectedUnitSubmitters" [isRequired]="false" [isMultiselect]="true" [fteOnly]="false"
                    [isTypeAheadV2]="true">
                </dm-type-ahead>
            </div>
        </form>
    </div>
    <div infoBar *ngIf="!isUpdatingProject && (!PJMPristine || pjmDeleted || !APJMPristine || !isUnitSubmitterPristine)">
        <div class="dm-modal-v2-change-information-section" *ngIf="!PJMPristine || pjmDeleted" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Project manager has been changed from '{{selectedProject.pjm.name}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="!APJMPristine && deletedManagers && deletedManagers.length">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                <span *ngFor="let deletedManager of deletedManagers; last as isLast">
                    {{deletedManager?.name}}
                    <span *ngIf="!isLast">, </span>
                </span> are removed from Additional project managers.
            </span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="!APJMPristine && addedManagers && addedManagers.length">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                <span *ngFor="let addedManager of addedManagers; last as isLast">
                    {{addedManager?.name}}
                    <span *ngIf="!isLast">, </span>
                </span>are added as Additional project managers.
            </span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="!isUnitSubmitterPristine && deletedUnitSubmitters && deletedUnitSubmitters.length">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                <span *ngFor="let deletedManager of deletedUnitSubmitters; last as isLast">
                    {{deletedManager?.name}}
                    <span *ngIf="!isLast">, </span>
                </span> are removed from Unit Submitters.
            </span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="!isUnitSubmitterPristine && addedUnitSubmitters && addedUnitSubmitters.length">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                <span *ngFor="let addedManager of addedUnitSubmitters; last as isLast">
                    {{addedManager?.name}}
                    <span *ngIf="!isLast">, </span>
                </span>are added as Unit Submitters.
            </span>
        </div>
    </div>
    <div additionalFooterButtons>
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" *ngIf="!isUpdatingProject"
            [disabled]="saveButtonDisabled(!editProjectTeamDetailsForm.valid)" (click)="updateProjectDetails()">
            Submit
        </button>
    </div>
</dm-modal-v2>