
import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.selector";
import { ICceac } from "../../../../project-summary-v2/tiles/project-summary-financials-v2/project.financials.model";
import { IEngagementDetailsApiV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { IEntityFinancials, IEntityFinancialSummary } from "../../../financial.model";
import { IFinancialDetailsV2State } from "../../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IState } from "../../../../../store/reducers";
import { RouteName, Components, FinancialType } from "../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
    selector: "dm-financial-summary-details",
    templateUrl: "./financial-summary-details.html",
    styleUrls: ["./financial-summary-details.scss"]
})
export class FinancialSummaryDetailsComponent extends DmComponentAbstract {
    public cceacValues: ICceac;
    public isInternalEngagement: boolean = false;
    public isProjectTypeFF: boolean;
    public isProjectECIF: boolean = false;
    public POCCPercentage: number;
    public revenueAmountRecognized: number;
    public RouteName = RouteName;
    public isPoccIndicatorApplicable: boolean = false;
    public planCurrency: string;

    private isProjectContext: boolean = false;
    private engagementDetails: IEngagementDetailsApiV2;
    private engagementId: string;

    public constructor(
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(FinancialService) private financialService: FinancialService,
    ) {
        super(dmLogger, Components.FinancialSummaryDetails, [{component: Components.Cceac, isCritical: true}]);
    }

    public ngOnInit(): void {
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        if (projectId) {
            this.isProjectContext = true;
        }
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        if (this.isProjectContext) {
            this.storeDispatchService
                .requireEngagementDetails(this.engagementId, true)
                .requireFinancialDetailsV2(projectId, true)
                .load();
        } else {
            this.storeDispatchService
                .requireEngagementDetails(this.engagementId, true)
                .requireFinancialDetailsV2(this.engagementId, true)
                .load();
        }
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        const financialDetails$ = this.isProjectContext ? this.store.select(getEntireFinancialDetailsV2(projectId))
            : this.store.select(getEntireFinancialDetailsV2(this.engagementId));

        observableCombineLatest(
            financialDetails$,
            engagementDetails$,
            (
                financialDetails: IFinancialDetailsV2State,
                engagementDetails: IEngagementDetailsState,
            ) => {
                return ({
                    financialDetails,
                    engagementDetails,
                });
            }
        ).pipe(untilDestroyed(this))
            .subscribe(({
                financialDetails,
                engagementDetails,
            }) => {
                this.refreshOnItemInvalidation(financialDetails, engagementDetails);
                this.setLoadersBasedOnItemState(financialDetails, engagementDetails);
                this.setErrorsBasedOnItemState(financialDetails, engagementDetails);
                if (financialDetails.loaded && engagementDetails.loaded) {
                    this.engagementDetails = engagementDetails.engagementDetails;
                    if (financialDetails && financialDetails.financialDetails && financialDetails.financialDetails.financialSummary
                        && financialDetails.financialDetails.financialSummary.length > 0 && financialDetails.financialDetails.financialSummary[0]) {
                        this.planCurrency = financialDetails.financialDetails.financialSummary[0].planCurrency;
                    }
                    this.POCCPercentage = 0;
                    this.revenueAmountRecognized = 0;
                    let currentBaseLineDetails: IEntityFinancialSummary;
                    let actualDetails: IEntityFinancialSummary;
                    let bifAmount: number;
                    if (this.isProjectContext) {
                        const projectDetails = this.engagementDetails.projects.filter((project) => project.id === projectId)[0];
                        this.isProjectTypeFF = projectDetails.contractType === "FF";
                        this.isProjectECIF = this.sharedFunctionsService.isProjectECIF(projectDetails.userStatusCode);
                        const projectFinancials: IEntityFinancials = financialDetails.financialDetails;
                        const eacFinancials: IEntityFinancialSummary[] = this.financialService.getFinancialDetailForBaselineTypeForV2Response(projectFinancials, FinancialType.EAC);
                        if (this.financialService.financialObjExistsForV2(eacFinancials)) {
                            this.cceacValues = this.financialService.getCceacValuesForV2(projectFinancials.financialSummary);
                        }
                        currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancials, FinancialType.CurrentFinancialPlan);
                        actualDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancials, FinancialType.ACTUALS);
                        bifAmount = Number(projectDetails.bifAmount);
                    } else {
                        this.isProjectTypeFF = this.engagementDetails.projects.filter((p) => p.contractType === "FF").length > 0;
                        const engagementFinancials: IEntityFinancials = (financialDetails as IFinancialDetailsV2State).financialDetails;
                        const isBaselineActive = this.financialService.getIsBaselineActiveForV2(engagementFinancials.financialSummary);
                        currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.CurrentFinancialPlan);
                        actualDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ACTUALS);
                        if (isBaselineActive) {
                            this.cceacValues = this.financialService.getCceacValuesForV2(engagementFinancials.financialSummary);
                        }
                    }
                    if (this.isProjectTypeFF) {
                        this.isPoccIndicatorApplicable = true;
                        this.setPOCCPercentRevenueAndGraphDetails(actualDetails, currentBaseLineDetails);
                    } else if (!this.isProjectTypeFF && this.isProjectECIF && this.isProjectContext && bifAmount > 0) {
                        // use BIF amount instead of planned revenue from contract baselines
                        this.isPoccIndicatorApplicable = true;
                        this.setPOCCPercentRevenueAndGraphDetails(actualDetails, currentBaseLineDetails, bifAmount);
                    }
                }

            });
    }

    /**
     *
     * Get POCC Percentage
     * @private
     * @param {IEntityFinancialSummary} actualsDetails
     * @param {IEntityFinancialSummary} eacDetails
     * @memberof FinancialSummaryComponent
     */
    private setPOCCPercentRevenueAndGraphDetails(actualDetails: IEntityFinancialSummary, currentBaseLineDetails: IEntityFinancialSummary, bifAmount?: number): void {
        this.POCCPercentage = 0;
        this.revenueAmountRecognized = 0;
        if (actualDetails && actualDetails.cost && currentBaseLineDetails && currentBaseLineDetails.cost && currentBaseLineDetails.revenue) {
            this.POCCPercentage = actualDetails.cost / currentBaseLineDetails.cost * 100;

            // Cap the POCCPercentage at 100
            if (this.POCCPercentage > 100) {
                this.POCCPercentage = 100;
            }

            let revenueForGraph = 0;
            if (bifAmount && bifAmount > 0) {
                revenueForGraph = bifAmount;
            } else {
                revenueForGraph = currentBaseLineDetails.revenue;
                this.revenueAmountRecognized = Math.round(this.POCCPercentage * revenueForGraph / 100);
            }
        }
    }
}
