<div class="dm-schedule">
  <div class="dm-schedule-navigation">
    <button class="dm-link dm-schedule-navigation-button" *ngIf="showPreviousBtn" (click)="prevWeeks()">
      <span class="icon-full icon-chevron-left"></span>
      Prev
    </button>
  </div>
  <div class="dm-schedule-week-grid">
    <ng-container *ngFor="let week of slicedWeeks; let index = index">
      <div>
        <span class="dm-schedule-week">Week {{week.weekNumber}}</span>
        <div class="dm-schedule-week-start">
          {{week.startDate | dmDisplayDateOrDashPipe}}
        </div>
        <div class="dm-schedule-week-start">
          {{week.endDate | dmDisplayDateOrDashPipe}}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="dm-schedule-navigation">
    <button class="dm-link dm-schedule-navigation-button" *ngIf="showNextBtn" (click)="nextWeeks()">
      Next
      <span class="icon-full icon-chevron-right"></span>
    </button>
  </div>
  <div class="dm-schedule-navigation-hours">Hours</div>
  <div class="dm-schedule-week-grid">
    <ng-container *ngFor="let week of slicedWeeks; let index = index">
        <input type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="dm-schedule-hours" [(ngModel)]="week.hours" (change)="hourChange(week)" />
    </ng-container>
  </div>
  <div class="dm-schedule-navigation"></div>
</div>