/* tslint:disable:no-empty */

import { Action } from "@ngrx/store";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";

// 1. action types
export enum UnitRolesActionTypes {
    LOAD_UNIT_ROLES = "[UnitRoles] Load",
    LOAD_UNIT_ROLES_SUCCESS = "[UnitRoles] Load Success",
    LOAD_UNIT_ROLES_FAIL = "[UnitRoles] Load Fail",
    INVALIDATE_UNIT_ROLES = "[UnitRoles] Invalidate"
}

// 2. action creators
export class LoadUnitRoles implements Action {
    public readonly type = UnitRolesActionTypes.LOAD_UNIT_ROLES;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() { } // todo: demands default false
}

export class LoadUnitRolesSuccess implements Action {
    public readonly type = UnitRolesActionTypes.LOAD_UNIT_ROLES_SUCCESS;
    public constructor(public unitRoles: IFinancialRoles[]) { }
}

export class LoadUnitRolesFail implements Action {
    public readonly type = UnitRolesActionTypes.LOAD_UNIT_ROLES_FAIL;
    public constructor(public errorMessage: string) { }
}

export class InvalidateUnitRoles implements Action {
    public readonly type = UnitRolesActionTypes.INVALIDATE_UNIT_ROLES;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() { }
}

// 3. export the union creators
export type UnitRolesActions =
    | LoadUnitRoles
    | LoadUnitRolesSuccess
    | LoadUnitRolesFail
    | InvalidateUnitRoles;