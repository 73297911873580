import { IState } from "../reducers";
import { IProcessNotification } from "../../common/services/contracts/process-notifications.contracts";

export const getWriteOperationNotifications = (store: IState): { [instanceId: string]: IProcessNotification } => store.writeOperationNotifications;

export const getWriteOperationNotificationByInstanceId = (instanceId: string) => (store: IState): IProcessNotification => {
    if (store.writeOperationNotifications && Object.keys(store.writeOperationNotifications).length > 0) {
        return store.writeOperationNotifications[instanceId];
    } else {
        return null;
    }
};
