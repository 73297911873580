import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AmendmentsComponent } from "./amendments.component";
import { AmendmentsService } from "../../common/services/amendments.service";
import { CommonComponentsModule } from "../entry/common-components.module";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FinancialService } from "../../common/services/financial.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { ViewWorkbookModalModule } from "./view-workbook-modal/view-workbook-modal.module";

@NgModule({
    declarations: [
        AmendmentsComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule,
        ViewWorkbookModalModule,
        NgbModule,
    ],
    providers: [
        AmendmentsService,
        DMLoggerService,
        SharedFunctionsService,
        FinancialService
    ],
    entryComponents: [
        AmendmentsComponent,
    ],
    exports: [
        AmendmentsComponent,
    ]
})
export class AmendmentsModule { }
