import { Component, forwardRef, Inject, Input, EventEmitter, Output } from "@angular/core";
import { DeviceFactoryProvider, } from "@fxp/fxpservices";
import { Components } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IInvoiceItemModel, IProjectDataToFilter } from "../invoice-table-data/invoice-table-data.contract";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

/**
 * InvoiceFilterComponent
 *
 * @export
 * @class InvoiceFilterComponent
 */
@Component({
    selector: "dm-invoice-filter",
    templateUrl: "./invoice-filter.html",
    styleUrls: ["./invoice-filter.scss"],
})
export class InvoiceFilterComponent extends DmComponentAbstract {
    @Input() public invoiceList: IInvoiceItemModel[];
    @Input() public filteredStatus: string;
    @Input() public projects: IProjectDataToFilter[];
    @Input() public selectedProjectId: string;
    @Output() public readonly statusChange = new EventEmitter<string>();
    @Output() public readonly invoiceNumberChange = new EventEmitter<string>();
    @Output() public readonly projectChange = new EventEmitter<string>();
    public invoiceSearch: string;
    public invoiceItems: any; // todo determine if this is used
    public statusList: string[];
    public selectedStatus: string = "";
    public selectedSearch: string; // todo double check that this is a string
    public isProjectContext: boolean = false;

    /**
     * Creates an instance of InvoiceFilterComponent.
     * @param {DeviceFactoryProvider} deviceFactory
     * @param {SharedFunctionsService} sharedFunctionsService
     * @param {ConfigManagerService} configurationService
     * @param {StateService} stateService
     * @param {EngagementDetailService} engagementDetailService
     * @memberof InvoiceFilterComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService
    ) {
        super(dmLogger, Components.CustomerInvoicesFilter);
    }

    public ngOnInit(): void {
        this.configurationService.initialize().then(() => {
            this.statusList = this.configurationService.getValue<string[]>("InvoiceStatusList");
        });
        if (this.selectedProjectId) {
            this.isProjectContext = true;
        }
        this.endComponentLoad();
    }

    /**
     * Publishes projectChange event to parent to inform of the newly selected project.
     *
     * @param {*} event
     * @memberof InvoiceFilterComponent
     */
    public onProjectChange(): void {
        this.projectChange.emit(this.selectedProjectId);
    }

    /**
     * Publishes statusChange event to parent to inform of the newly selected state.
     *
     * @param {*} event
     * @memberof InvoiceFilterComponent
     */
    public onStatusChange(): void {
        this.statusChange.emit(this.filteredStatus);
    }

    /**
     * Publishes invoiceNumberChange event to parent to inform
     * of the newly selected invoice number input.
     *
     * @param {*} event
     * @memberof InvoiceFilterComponent
     */
    public onInvoiceNumberChange(): void {
        this.invoiceNumberChange.emit(this.invoiceSearch);
    }

    /**
     * Clear the input field for invoice number search
     *
     * @memberof InvoiceFilterComponent
     */
    public clearInvoiceSearch(): void {
        this.invoiceSearch = "";
        this.invoiceNumberChange.emit(this.invoiceSearch);
        this.sharedFunctionsService.focus("invoiceSearch", true);
    }
}
