import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TeamStructureModalComponent } from "./team-structure.component";
import { ContactsModule } from "../contacts/contacts.module";
import { DmModalV2Module } from "../../modals/dm-modal-v2/dm-modal-v2.module";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { PersonCardModule } from "../person-card/person-card.module";

@NgModule({
    declarations: [
        TeamStructureModalComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        UIRouterModule,
        ContactsModule,
        DmModalV2Module,
        PersonCardModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
        TeamStructureModalComponent
    ],
    exports: [
        TeamStructureModalComponent
    ],
})
export class TeamStructureModalModule { }
