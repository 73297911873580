
import { mergeMap, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Effect, Actions } from "@ngrx/effects";
import { of, from as fromPromise } from "rxjs";

import * as Wbsdb from "./wbs-db.action";
import { ProjectService } from "../../common/services/project.service";
import { IForecast } from "./../../components/financial-mgmt/financial.model";
import { PlanVersionType } from "./../../common/application.constants";

@Injectable()
export class WbsDbEffect {

    @Effect()
    public loadWbsDbDetails$ = this.actions$
        .ofType(Wbsdb.WbsDbActionTypes.LOAD_WbsDb).pipe(
            mergeMap((action: Wbsdb.LoadWbsDb) => fromPromise(this.projectService.getForecast(action.wbsId, PlanVersionType.DeliveryBaseline))
                .pipe(
                    map((response: IForecast) => new Wbsdb.LoadWbsDbSuccess(action.wbsId, response)),
                    catchError((error) => of(new Wbsdb.LoadWbsDbFail(action.wbsId, error)))
                )));

    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService,
    ) { }
}
