import { Component, Input } from "@angular/core";
import { IContact } from "../../../common/services/contracts/contacts.contracts";
import { NoDataText } from "../../../common/application.constants";
import { IPersonDetails } from "../../manage-ebs-v2/tiles/entity-team-structure/entity-team-structure.component";

export interface ISimpleContact {
    alias: string;
    company?: string;
    email?: string;
    image?: string;
    firstName?: string;
    lastName?: string;
    telephone?: string;
    role: string;
    name: string;
    personDetails?: IPersonDetails;
}

@Component({
    selector: "contacts",
    templateUrl: "./contacts.component.html",
    styleUrls: ["./contacts.component.scss"]
})
export class ContactsComponent {

    @Input() public contactsList: IContact[] | ISimpleContact[] = [];
    @Input() public showUserIconBadge: boolean = false;
    @Input() public showContactActions: boolean;
    @Input() public isV2Tile: boolean = false;
    public noContactText = NoDataText.NoContacts;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() { }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public ngOnInit(): void { }
}
