<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'280px'" [tileHeight]="'280px'" *ngIf="!isNotification">
    <ng-container loader [ngTemplateOutlet]="keyActionsLoader"></ng-container>
    <ng-container *ngIf="!showLoadingForComponent() && !isServerError">
        <section id="dm-key-action" class="col-sm-12" aria-label="Key Actions">
            <ng-container *ngTemplateOutlet="keyActions"></ng-container>
        </section>
    </ng-container>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>
<ng-container [ngTemplateOutlet]="keyActionsLoader" *ngIf="isNotification"></ng-container>
<ng-container [ngTemplateOutlet]="keyActions" *ngIf="isNotification && !showLoadingForComponent() && !isServerError">
</ng-container>
<ng-template #keyActionsLoader>
    <dm-loading *ngIf="showLoadingForComponent() && !isServerError"
        [ngClass]="{'key-action-notification-loader': isNotification}" [loadingText]="'Key Actions'"
        [showLoading]="showLoading"></dm-loading>
</ng-template>
<ng-template #keyActions>
    <!--===================Key Actions For Returned Resource Request===================-->
    <div role="grid" *ngIf="totalReturnedRequests">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button"
                        class="dm-transparent-btn icon-btn-section set-focus"
                        [attr.aria-expanded]="showReturnedResourceRequestSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Returned Resource Request', showReturnedResourceRequestSection)"
                        (click)="this.showReturnedResourceRequestSection = !this.showReturnedResourceRequestSection">
                        <span class="icon"
                            [ngClass]="showReturnedResourceRequestSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title">Returned Resource Request
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{totalReturnedRequests}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding"
                    *ngIf="showReturnedResourceRequestSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8 returned-resource-bodyCell">
                            <button class="dm-link--button font-caption-alt text-left set-focus"
                                (click)="openReturnedResourceModal()" title="View Returned Resource Request"
                                aria-label="View Returned Resource Request">
                                Returned Resource Request ({{totalReturnedRequests}})
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!--===================Key Actions For Resource Proposals===================-->
    <div role="grid" *ngIf="resourceProposalCount">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button"
                        class="dm-transparent-btn icon-btn-section set-focus" [attr.aria-expanded]="showResourceProposalsSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Resource Proposals', showResourceProposalsSection)"
                        (click)="this.showResourceProposalsSection = !this.showResourceProposalsSection">
                        <span class="icon"
                            [ngClass]="showResourceProposalsSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title">Resource Proposals
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{resourceProposalCount}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showResourceProposalsSection">
                    <ng-container scope="row" role="rowheader" *ngIf="resourceProposalCount">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a [href]="'#/request/action'" target="_blank" class="font-caption-alt text-left"
                                aria-label="open link in new tab" title="Resource Proposals">
                                Resource Proposal
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            {{resourceDueHours}} hrs
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!--===================Key Actions Expense Reports===================-->
    <div role="grid" *ngIf="expenseKeyActions && expenseKeyActions.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button"
                        class="dm-transparent-btn icon-btn-section text-left set-focus"
                        [attr.aria-expanded]="showExpenseReportsSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Expense Reports', showExpenseReportsSection)"
                        (click)="this.showExpenseReportsSection = !this.showExpenseReportsSection">
                        <span class="icon"
                            [ngClass]="showExpenseReportsSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Expense Reports
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{expenseKeyActions.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row" *ngFor="let pendingExpenseReports of expenseKeyActions">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showExpenseReportsSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a [href]="pendingExpenseReports.concurLink" target="_blank"
                                aria-label="open link in new tab" (click)="logKeyActionsClickEvent()"
                                class="dm-link font-caption-alt text-left" title="Pending Expense Reports">
                                {{pendingExpenseReports.description}}
                                ({{pendingExpenseReports.pendingCount}})
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <span class="font-caption-alt red-legend-text p-l-0">
                                Now
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--===================Key Actions Labor Approvals===================-->
    <div role="grid" *ngIf="pendingLaborApprovals && pendingLaborApprovals.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button" class="dm-transparent-btn icon-btn-section set-focus"
                        [attr.aria-expanded]="showLaborApprovalsSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Labor Approvals', showLaborApprovalsSection)"
                        (click)="this.showLaborApprovalsSection = !this.showLaborApprovalsSection">
                        <span class="icon"
                            [ngClass]="showLaborApprovalsSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Labor Approvals
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{pendingLaborApprovals.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row" *ngFor="let pendingApproval of pendingLaborApprovals">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showLaborApprovalsSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a [href]="pendingApproval.msApprovalsLink" target="_blank"
                                aria-label="open link in new tab" (click)="logKeyActionsClickEvent()"
                                class="dm-link font-caption-alt text-left" title="Pending Approvals">
                                Time Approvals
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <span class="font-caption-alt"
                                [ngClass]="{'red-legend-text':pendingApproval.daysUntilDue < 0}">
                                {{pendingApproval.daysUntilDueMessage | dmDisplayDashIfEmptyOrNull}}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--===================Key Actions For Release And Activate Engagements===================-->
    <div role="grid" *ngIf="engagementKeyActions && engagementKeyActions.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button"
                        class="dm-transparent-btn icon-btn-section set-focus" [attr.aria-expanded]="showReleaseAndActivateSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Release And Activate', showReleaseAndActivateSection)"
                        (click)="this.showReleaseAndActivateSection = !this.showReleaseAndActivateSection">
                        <span class="icon"
                            [ngClass]="showReleaseAndActivateSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Engagements Ready For
                        Release And Activate
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{engagementKeyActions.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row" *ngFor="let engagementKeyAction of engagementKeyActions">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showReleaseAndActivateSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a class="dm-link font-caption-alt text-left" [uiSref]="RouteName.EngagementFinancials"
                                [uiParams]="{engagementId: engagementKeyAction.engagementId, eventName: LogEventName.PortfolioKeyActionsLinkClick}"
                                title="Release and Activate Engagement">
                                {{engagementKeyAction.engagementId}}
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            {{engagementKeyAction.daysDue}} days
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--===================Key Actions Receipts Due===================-->
    <div role="grid" *ngIf="receiptsDueKeyActions && receiptsDueKeyActions.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button" class="dm-transparent-btn icon-btn-section set-focus"
                        [attr.aria-expanded]="showReceiptsDueSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Receipts Due', showReceiptsDueSection)"
                        (click)="this.showReceiptsDueSection = !this.showReceiptsDueSection">
                        <span class="icon"
                            [ngClass]="showReceiptsDueSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Receipts Due
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{receiptsDueKeyActions.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row" *ngFor="let receiptsDueKeyAction of receiptsDueKeyActions">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showReceiptsDueSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a [href]="receiptsDueKeyAction.link" target="_blank"
                                (click)="logKeyActionsClickEvent()" class="dm-link font-caption-alt text-left"
                                title="Receipts Due">
                                {{receiptsDueKeyAction.description}}
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <span class="font-caption-alt"
                                [ngClass]="{'red-legend-text':receiptsDueKeyAction.due < 0}">
                                {{receiptsDueKeyAction.due | dmDisplayDashIfEmptyOrNull}} days
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--===================Key Actions showContractSignatureAfterEngStartDate===================-->
    <div role="grid"
        *ngIf="contractSignatureAfterEngagementStartDateKeyAction && contractSignatureAfterEngagementStartDateKeyAction.isContractSignatureAfterEngagementStartDate">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button"
                        class="dm-transparent-btn icon-btn-section set-focus" [attr.aria-expanded]="showContractSignatureSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Contract Signature', showContractSignatureSection)"
                        (click)="this.showContractSignatureSection = !this.showContractSignatureSection">
                        <span class="icon"
                            [ngClass]="showContractSignatureSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Engagement Start Date is earlier
                        than Contract Signature Date
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            1
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showContractSignatureSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a [uiSref]="RouteName.EngagementManageEBS"
                                [uiParams]="{engagementId: contractSignatureAfterEngagementStartDateKeyAction.engagementId}"
                                (click)="logKeyActionsClickEvent()" class="dm-link font-caption-alt text-left"
                                title="Manage Engagement Start Date">
                                {{contractSignatureAfterEngagementStartDateKeyAction.engagementId}}
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <span class="font-caption-alt red-legend-text">
                                Now
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--===================Key Actions Milestones===================-->
    <div role="grid" *ngIf="totalUpcomingMileStone && milestoneDayDiff && isPrimaryProjectManager">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button" class="dm-transparent-btn icon-btn-section set-focus"
                        [attr.aria-expanded]="showMilestonesSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Milestones', showMilestonesSection)"
                        (click)="this.showMilestonesSection = !this.showMilestonesSection">
                        <span class="icon" [ngClass]="showMilestonesSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Milestones
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{totalUpcomingMileStone}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showMilestonesSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a [uiSref]="milestoneRoute" (click)="logKeyActionsClickEvent()"
                                class="dm-link font-caption-alt text-left" title="Confirm Milestone">
                                Confirm
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <span class="font-caption-alt" [ngClass]="{'red-legend-text':isDueDateOver}">
                                {{milestoneDayDiff}} days
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--===================Key Actions For Misalignmed Contractual Amendments===================-->
    <div role="grid" *ngIf="contractualMisalignmentKeyActions && contractualMisalignmentKeyActions.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button" class="dm-transparent-btn icon-btn-section set-focus"
                        [attr.aria-expanded]="showContractualMisalignmentSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Contractual Misalignment', showContractualMisalignmentSection)"
                        (click)="this.showContractualMisalignmentSection = !this.showContractualMisalignmentSection">
                        <span class="icon"
                            [ngClass]="showContractualMisalignmentSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Contractual Misalignments for Resolution
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{contractualMisalignmentKeyActions.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row"
            *ngFor="let contractualMisalignmentKeyAction of contractualMisalignmentKeyActions">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding"
                    *ngIf="showContractualMisalignmentSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a class="dm-link font-caption-alt text-left"
                                [uiSref]="RouteName.EngagementMisalignedAmendmentDetails"
                                [uiParams]="{engagementId: contractualMisalignmentKeyAction.engagementId, amendmentId: contractualMisalignmentKeyAction.pendingChangeRequestId,eventName: LogEventName.PortfolioKeyActionsLinkClick}"
                                title="Resolve contractual misalignment">
                                {{contractualMisalignmentKeyAction.processedChangeRequestId}}
                            </a>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell font-caption-alt red-legend-text">
                            {{contractualMisalignmentKeyAction.daysDue}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--===================Key Actions For Expiring ECIF IO===================-->

    <div role="grid" *ngIf="expiringEcifIoKeyActions && expiringEcifIoKeyActions.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button" class="dm-transparent-btn icon-btn-section set-focus"
                        [attr.aria-expanded]="showExpiringEcifIo"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Milestones', showExpiringEcifIo)"
                        (click)="this.showExpiringEcifIo = !this.showExpiringEcifIo">
                        <span class="icon" [ngClass]="showExpiringEcifIo ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Expiring ECIF IO
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{expiringEcifIoKeyActions.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row"
            *ngFor="let expiringEcifIoKeyAction of expiringEcifIoKeyActions">
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding"
                    *ngIf="showExpiringEcifIo">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a class="dm-link font-caption-alt text-left"
                            [uiSref]="financialRoute" (click)="logKeyActionsClickEvent()"
                            title="IONumber">
                                {{expiringEcifIoKeyAction.ioNumber}}
                            </a>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell font-caption-alt red-legend-text dm-p-x-12-y-8">
                            {{getAbsoluteDays(expiringEcifIoKeyAction.daysTillExpiry)}} days {{isEndDateWithinRange(expiringEcifIoKeyAction.daysTillExpiry, -1)? 'past': 'till'}} expiration
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

     <!--===================Key Actions For Pending Subcon Onboarding===================-->
     <div role="grid" *ngIf="subconStatusKeyActions && subconStatusKeyActions.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button" class="dm-transparent-btn icon-btn-section set-focus"
                        [attr.aria-expanded]="showSubconOnboardingKeyActionSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Subcon Onboarding', showSubconOnboardingKeyActionSection)"
                        (click)="this.showSubconOnboardingKeyActionSection = !this.showSubconOnboardingKeyActionSection">
                        <span class="icon"
                            [ngClass]="showSubconOnboardingKeyActionSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Pending Subcon Onboarding
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{subconStatusKeyActions.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row"
            *ngFor="let subconStatusKeyAction of subconStatusKeyActions">
            <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding"
                *ngIf="showSubconOnboardingKeyActionSection">
                <ng-container scope="row" role="rowheader">
                    <div role="gridcell"
                        class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                        <div role="row">
                            <a class="font-caption-alt text-left pendingSubconOnboarding-title" [href]="subconStatusKeyAction.subconOnboardingPendingDasboardUrl">
                                {{subconStatusKeyAction.projectName}}
                            </a>
                        </div>
                        <div role="row">
                            <span class="font-caption-alt text-left pendingSubconOnboarding-subtitle">
                            {{subconStatusKeyAction.projectId}}
                            </span>
                        </div>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__bodyCell font-caption-alt red-legend-text dm-p-x-12-y-8">
                        <span class="font-caption-alt red-legend-text">
                            {{subconStatusKeyAction.pendingOnboarding}} pending
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        </div>
    </div>
     <!--===================Key Actions For Pending Unit Approvals===================-->
     <div role="grid" *ngIf="pendingUnitApprovalsKeyActions && pendingUnitApprovalsKeyActions.length">
        <caption class="sr-only">{{tableCaption}}</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row">
                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                    <button type="button"
                        class="dm-transparent-btn icon-btn-section text-left set-focus"
                        [attr.aria-expanded]="showPendingUnitApprovalsSection"
                        [title]="setTitleOrAriaLabelForExpandCollapseKeyAction('Unit Approvals', showPendingUnitApprovalsSection)"
                        (click)="showPendingUnitApprovalsSection = !showPendingUnitApprovalsSection">
                        <span class="icon"
                            [ngClass]="showPendingUnitApprovalsSection ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                    </button>
                    <h4 class="dm-p-t-4 dm-tile__large-tile-title text-left">Pending Unit Approvals
                        <span class="badge dm-p-imp-x-2-y-6 dm-m-l-4">
                            {{pendingUnitApprovalsKeyActions.length}}
                        </span>
                    </h4>
                </div>
            </div>
            <div class="dm-grid-view-table__body full-width" role="row" *ngFor="let pendingUnitApprovals of pendingUnitApprovalsKeyActions">
                <div *ngIf="!showPendingUnitApprovalsSection" role="rowheader"></div>
                <div class="dm-grid-view-table__row dm-p-l-50 mobile-padding" *ngIf="showPendingUnitApprovalsSection">
                    <ng-container scope="row" role="rowheader">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <a [href]="unitsApprovalLink" target="_blank"
                                aria-label="open link in new tab" (click)="logKeyActionsClickEvent()"
                                class="dm-link font-caption-alt text-left" title="Pending Unit Approvals">
                                {{pendingUnitApprovals.engagementId}}                              
                            </a>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell font-caption-alt text-left dm-p-x-12-y-8">
                            <span class="font-caption-alt red-legend-text p-l-0">
                                {{pendingUnitApprovals.pendingCount}} pending
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <dm-no-data *ngIf="!isDataAvailableForKeyActions()" [noDataText]="noKeyActionText"></dm-no-data>
</ng-template>
