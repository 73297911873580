import { Inject, Injectable } from "@angular/core";
import { APIConstants, Services } from "../../common/application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { ICosmicFunctionResponse } from "./contracts/cosmic-function.contracts";
import { NotificationModelsForProject, NotificationModel, DMNotificationService } from "./dmnotification.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";
import { ICosmicCancellationRequest } from "../../components/manage-ebs-v2/modals/request-status-change/request-status-change.contract";


@Injectable()
export class CosmicServiceFunctions extends DmServiceAbstract {
    private projectServiceFunctionUri: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService    
    ) { 
        super(dmLogger, Services.CosmicService );
    }

    /**
     * Creates the cosmic ticket by calling the azure function for sending ticket details to Notification api
     * @param inputData
     */
    public createCosmicTicketForNotification(inputData: NotificationModel | NotificationModelsForProject): Promise<ICosmicFunctionResponse> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV2BaseUrl");
        this.projectServiceFunctionUri = this.projectServiceFunctionUri + "/v1/cosmic/ticket";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        const apiInputModel = this.notificationService.convertToApiModel(inputData);

        return this.dataService.postData(this.projectServiceFunctionUri, this.subscriptionKey, APIConstants.CreateCosmicTicketForNotification, apiInputModel);
    }

    public cancelCosmicTicket(inputData: ICosmicCancellationRequest): Promise<string> {
        this.projectServiceFunctionUri = this.configManagerService.getValue<string>("projectServiceFunctionV2BaseUrl");
        this.projectServiceFunctionUri = this.projectServiceFunctionUri + "/v1/cosmic/ticket/cancel";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");

        return this.dataService.postData(this.projectServiceFunctionUri, this.subscriptionKey, APIConstants.CreateCosmicTicketForNotification, inputData);    
    }

}
