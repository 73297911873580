import { Component, Inject, forwardRef, ViewChild, Injector } from "@angular/core";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components, RouteName, AccessibilityConstants } from "../../../common/application.constants";
import { FxpRouteService, FxpMessageService, FxpConstants, UserInfoService } from "@fxp/fxpservices";
import { StateService } from "@uirouter/core";
import { IBulkIntEngStatusResponse, BulkIntEngStatus, IBulkIntEngDetailsResponse, IBulkUploadIntEngCreationObject } from "../../../common/services/contracts/bulk-upload-int-engagement.service.contracts";
import { BulkUploadInternalEngagementService } from "../../../common/services/bulk-upload-internal-engagement.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../store/reducers";
import { getBulkIntEngStatusNotificationsByReferenceId } from "../../../store/bulk-internal-engagement-status-notifications/bulk-internal-engagement-status-notifications.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { NewInternalEngagementComponentV2 } from "../../new-internal-engagement/new-internal-engagement-v2.component";
import { NotificationType } from "../../../common/services/contracts/notification-bar.contracts";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { IBulkCreateNotificationState } from "../../../store/bulk-internal-engagement-status-notifications/bulk-internal-engagement-status-notifications.reducer";


@Component({
    selector: "dm-bulk-create-internal-engagement",
    templateUrl: "./bulk-create-internal-engagement.html",
    styleUrls: ["./bulk-create-internal-engagement.scss"]
})
export class BulkCreateInternalEngagementComponent extends DmComponentAbstract {
    @ViewChild(NewInternalEngagementComponentV2, { static: false }) public newInternalEngagementComponentV2: NewInternalEngagementComponentV2;
    public selectedInternalEngagement: IBulkIntEngStatusResponse;
    public internalEngagements: IBulkIntEngStatusResponse[] = [];
    public lastDiscardedRecord: IBulkIntEngStatusResponse;
    public validRecords: IBulkIntEngStatusResponse[];
    public invalidRecords: IBulkIntEngStatusResponse[];
    public inProgressRecords: IBulkIntEngStatusResponse[];
    public cancelModalRef: NgbModalRef;
    public bulkUploadReferenceId: string;
    public updateConfirmModalRef: NgbModalRef;
    public saveBtnDisabled: boolean = false;
    public validateBtnDisabled: boolean = false;
    public discardAllBtnDisabled: boolean = false;
    public accessibilityConstants = AccessibilityConstants;
    private FXP_CONSTANTS = FxpConstants;
    public constructor(
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(Store) private store: Store<IState>,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(BulkUploadInternalEngagementService) private bulkUploadInternalEngagementService: BulkUploadInternalEngagementService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.BulkCreateInternalEngagement);
    }

    public ngOnInit(): void {
        if (this.stateService.params && this.stateService.params.uploadedBulkIntEngData) {
            const uploadedBulkIntEngagementsDetails = this.stateService.params.uploadedBulkIntEngData;
            this.bulkUploadReferenceId = this.stateService.params.uploadedBulkIntEngData.referenceId;

            if (uploadedBulkIntEngagementsDetails && uploadedBulkIntEngagementsDetails.engagements && uploadedBulkIntEngagementsDetails.notificationGroupName) {
                const uploadedEngagements: IBulkIntEngStatusResponse[] = uploadedBulkIntEngagementsDetails.engagements;
                const groupName = uploadedBulkIntEngagementsDetails.notificationGroupName;

                this.notificationService.subscribeToBulkInternalEngagementStatus({ GroupName: groupName });

                if (uploadedEngagements && uploadedEngagements.length) {
                    this.internalEngagements = this.getEngagementsWithDisplayId(uploadedEngagements);

                    this.selectedInternalEngagement = this.internalEngagements[0];
                    this.updateEngagementStatusChanges();
                }
                if (this.saveBtnDisabled) {
                    this.sharedFunctionsService.focus("cancelBulkInternalEngagement", true);
                } else {
                    this.sharedFunctionsService.focus("createBulkInternalEngagement", true);
                }
                const notifications$ = this.store.select(getBulkIntEngStatusNotificationsByReferenceId(this.bulkUploadReferenceId));

                notifications$.pipe(untilDestroyed(this)).subscribe((bulkCreateNotification: IBulkCreateNotificationState) => {
                    // eslint-disable-next-line @typescript-eslint/no-this-alias
                    const that = this;

                    if (bulkCreateNotification && bulkCreateNotification.Engagements && bulkCreateNotification.Engagements.length) {
                        // Change the status of all engagements based on the notification
                        this.internalEngagements = that.internalEngagements.map((temp) => {
                            const filteredEng = bulkCreateNotification.Engagements.filter((eng) => eng.EngagementId === temp.id)[0];
                            return {
                                ...temp,
                                status: filteredEng && filteredEng.Status ? filteredEng.Status : temp.status
                            };
                        });

                        if (that.internalEngagements && that.internalEngagements.length) {
                            const filteredEngs = that.internalEngagements.filter((selEng) => selEng.id === that.selectedInternalEngagement.id);

                            if (filteredEngs && filteredEngs.length) {
                                const filteredEng = filteredEngs[0];
                                const previousEngagementStatus = that.selectedInternalEngagement.status;
                                that.selectedInternalEngagement.status = filteredEng.status ? filteredEng.status : undefined;
                                that.updateEngagementStatusChanges();

                                if (filteredEng && filteredEng.id && that.selectedInternalEngagement && that.selectedInternalEngagement.id === filteredEng.id) {
                                    if (!(that.selectedInternalEngagement.status === BulkIntEngStatus.ValidationInProgress ||
                                        that.selectedInternalEngagement.status === BulkIntEngStatus.QueuedForValidation ||
                                        previousEngagementStatus === BulkIntEngStatus.ValidationSuccessful ||
                                        previousEngagementStatus === BulkIntEngStatus.ValidationFailed
                                    )) {
                                        this.getEngagementDetails(this.bulkUploadReferenceId, this.selectedInternalEngagement.id);
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }
    }

    /**
     * Opens cancel confirm modal for dismissing the bulk create internal engagement process.
     *
     * @param {*} cancelModal
     * @memberof BulkCreateInternalEngagementComponent
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public openCancelBulkUploadModal(cancelModal: any): void {
        this.cancelModalRef = this.modalService.open(cancelModal, {
            backdrop: "static",
            windowClass: "manage-wbs-modal edit-engagement add-role-int-eng",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
    }

    /**
     * TODO: placeholder method added for validate records.
     *
     * @memberof BulkCreateInternalEngagementComponent
     */
    public validateRecord(): void {
        // Change the status of the validated engagement and disable the validate button
        if (this.newInternalEngagementComponentV2) {
            if (this.selectedInternalEngagement && this.selectedInternalEngagement.status) {
                this.selectedInternalEngagement.status = BulkIntEngStatus.QueuedForValidation;
                this.enableOrDisableValidateAction();
            }

            // Change the status to queued validation and post it to the server.
            const engDetails: IBulkIntEngDetailsResponse = this.newInternalEngagementComponentV2.getInternalEngagementDetails();
            engDetails.status = BulkIntEngStatus.QueuedForValidation;

            this.bulkUploadInternalEngagementService.updateUploadedInternalEngagement(this.bulkUploadReferenceId, engDetails.id, engDetails);
        }
    }

    /**
     * Discards a record/engagement from dropdown.
     *
     * @memberof BulkCreateInternalEngagementComponent
     */
    public discardRecord(): void {
        if (this.selectedInternalEngagement && this.internalEngagements && this.internalEngagements.length) {
            const engagementIdList: string[] = new Array(this.selectedInternalEngagement.id);
            const index = this.internalEngagements.findIndex((x) => x.id === this.selectedInternalEngagement.id);

            if (index >= 0) {
                this.internalEngagements.splice(index, 1);
            }

            if (this.internalEngagements && this.internalEngagements.length > 0) {
                this.selectedInternalEngagement = this.internalEngagements[0];
            } else {
                this.selectedInternalEngagement = null;
            }

            this.updateBulkUploadStatus(engagementIdList, BulkIntEngStatus.Discarded);
        }

    }

    /**
     * Reverts the last discarded record. it only reverts one record.
     *
     * @memberof BulkCreateInternalEngagementComponent
     */
    public revertRecord(): void {
        if (this.lastDiscardedRecord) {
            this.internalEngagements.push(this.lastDiscardedRecord);
        }
        this.lastDiscardedRecord = undefined;
    }

    /**
     * Discards all the records/engagements which have invalid data or errors.
     *
     * @memberof BulkCreateInternalEngagementComponent
     */
    public discardAllErrorRecords(): void {
        if (this.internalEngagements && this.internalEngagements.length) {
            const validationFailedengagementIdList: string[] = [];

            this.internalEngagements.forEach((eng: IBulkIntEngStatusResponse) => {
                if (eng.status === BulkIntEngStatus.ValidationFailed) {
                    validationFailedengagementIdList.push(eng.id);
                }
            });

            this.internalEngagements = this.internalEngagements.filter((eng) => eng.status !== BulkIntEngStatus.ValidationFailed);

            if (this.internalEngagements && this.internalEngagements.length > 0) {
                this.selectedInternalEngagement = this.internalEngagements[0];
            } else {
                this.selectedInternalEngagement = null;
            }

            this.updateBulkUploadStatus(validationFailedengagementIdList, BulkIntEngStatus.Discarded);
        }
    }

    /**
     * Closes cancel confirm modal and redirect to portfolio.
     *
     * @memberof BulkCreateInternalEngagementComponent
     */
    public goBackToPortfolio(): void {
        this.cancelModalRef.close();
        this.fxpRouteService.navigatetoSpecificState(RouteName.Portfolio, {
            loadFromCache: false
        });
    }

    /**
     * This method is called on internal engagement change from dropdown.
     *
     * @param {IInternalEngagement} internalEngagement
     * @memberof BulkCreateInternalEngagementComponent
     */
    public onInternalEngagementChange(internalEngagement: IBulkIntEngStatusResponse): void {
        if (internalEngagement && internalEngagement.id) {
            this.selectedInternalEngagement = internalEngagement;
            this.enableOrDisableValidateAction();
            this.getEngagementDetails(this.bulkUploadReferenceId, internalEngagement.id);
        }
    }

    /**
     * Move focus to element for accessibility tooling
     *
     * @param {KeyboardEvent} event
     * @param {string} id
     * @memberof BulkCreateInternalEngagementComponent
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * on click of create internal engagements, it changes the status of all engagements to queued for creation
     * and post the data to api for bulk upload engagements creation.
     * Create is enabled only when all the engageements are validated.
     *
     * @memberof BulkCreateInternalEngagementComponent
     */
    public onCreateBulkInternalEngagements(): void {
        const createIntEngDataObject = this.getBulkInternalEngagementsCreationObject();
        const referenceId = this.bulkUploadReferenceId;
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        const entityId = this.stateService.params.uploadedBulkIntEngData.referenceId;
        const notificationId = this.stateService.params.uploadedBulkIntEngData.notificationGroupName;

        this.bulkUploadInternalEngagementService.updateEngagementStatus(referenceId, createIntEngDataObject).then(() => {
            // TODO: may have to change the banner message.
            this.fxpMessageService.addMessage("Successfully initiated bulk upload internal engagements process.", this.FXP_CONSTANTS.messageType.success, false);
            // Displays the new notification in the notification bar
            this.notificationService.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, entityId, notificationId, NotificationType.BulkUpload);

            this.fxpRouteService.navigatetoSpecificState(RouteName.Portfolio);
        });
    }

    /**
     * Move focus to element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, "");
        }
    }

    /**
     * This prepares the internal engagement object that needs to be posted back for validation.
     *
     * @returns {IBulkUploadIntEngUploadResponse}
     * @memberof BulkCreateInternalEngagementComponent
     */
    private getBulkInternalEngagementsCreationObject(): IBulkUploadIntEngCreationObject {
        if (this.internalEngagements && this.internalEngagements.length) {
            const intEngagementsIds: string[] = [];
            for (const engagement of this.internalEngagements) {
                intEngagementsIds.push(engagement.id);
            }
            return {
                status: BulkIntEngStatus.QueuedForCreation,
                engagementIds: intEngagementsIds
            };
        }
    }


    /**
     * updates that should be done on engagement status change.
     *
     * @memberof BulkCreateInternalEngagementComponent
     */
    private updateEngagementStatusChanges(): void {
        this.saveBtnDisabled = false;
        this.validateBtnDisabled = false;
        this.discardAllBtnDisabled = false;

        if (this.selectedInternalEngagement && this.selectedInternalEngagement.id) {
            this.enableOrDisableValidateAction();
        }

        if (this.internalEngagements && this.internalEngagements.length) {
            this.validRecords = this.internalEngagements.filter((eng) => eng.status === BulkIntEngStatus.ValidationSuccessful);
            this.invalidRecords = this.internalEngagements.filter((eng) => eng.status === BulkIntEngStatus.ValidationFailed);
            this.inProgressRecords = this.internalEngagements.filter((eng) => (eng.status === BulkIntEngStatus.ValidationInProgress || eng.status === BulkIntEngStatus.QueuedForValidation));
        } else {
            // TODO: we probably should just discard the whole page
            this.validRecords = [];
            this.invalidRecords = [];
            this.inProgressRecords = [];
        }

        for (const eng of this.internalEngagements) {
            if (eng.status === BulkIntEngStatus.ValidationFailed ||
                eng.status === BulkIntEngStatus.ValidationInProgress ||
                eng.status === BulkIntEngStatus.QueuedForValidation) {
                this.saveBtnDisabled = true;
            }
        }

        // disable the dicard-all-errors button if there is no failed engagement
        const failedEngagement = this.internalEngagements.filter((eng) => eng.status === BulkIntEngStatus.ValidationFailed);
        if (!failedEngagement || failedEngagement.length < 1) {
            this.discardAllBtnDisabled = true;
        }
    }

    /**
     * Gets uploaded engagement details of engagement that user has uploaded through
     * bulk upload.
     * @param {string} referenceId
     * @param {string} engagementId
     * @memberof BulkCreateInternalEngagementComponent
     */
    private getEngagementDetails(referenceId: string, engagementId: string): void {
        this.bulkUploadInternalEngagementService.getUploadedInternalEngagement(referenceId, engagementId).then((response: IBulkIntEngDetailsResponse) => {
            // Gets the json object and sets the initial values of new internal engagement form that user has filled out.
            if (this.newInternalEngagementComponentV2) {
                this.newInternalEngagementComponentV2.setInitValues(response);
            }
        });
    }

    /**
     * Creates payload for status update
     * @param {string} referenceId
     * @param {string} engagementId
     * @memberof BulkCreateInternalEngagementComponent
     */
    private updateBulkUploadStatus(engagementIdList: string[], newStatus: BulkIntEngStatus): void {
        const payload: IBulkUploadIntEngCreationObject = {
            engagementIds: engagementIdList,
            status: newStatus
        };

        this.bulkUploadInternalEngagementService.updateEngagementStatus(this.bulkUploadReferenceId, payload)
            .then(() => {
                this.updateEngagementStatusChanges();
                if (this.selectedInternalEngagement) {
                    this.getEngagementDetails(this.bulkUploadReferenceId, this.selectedInternalEngagement.id);
                }
            });
    }

    /**
     * Creates the engagements with the display id for better readibility in the UI
     * @param {IBulkIntEngStatusResponse[]} uploadEngagements
     * @memberof BulkCreateInternalEngagementComponent
     */
    private getEngagementsWithDisplayId(uploadEngagements: IBulkIntEngStatusResponse[]): IBulkIntEngStatusResponse[] {
        const uploadEngagementsWithDisplayId = uploadEngagements.map((x) => {
            return {
                ...x,
                displayId: +x.id.split(/[-]+/).pop()
            };
        });

        return uploadEngagementsWithDisplayId;
    }

    private enableOrDisableValidateAction() {
        this.validateBtnDisabled = (this.selectedInternalEngagement.status === BulkIntEngStatus.QueuedForValidation
            || this.selectedInternalEngagement.status === BulkIntEngStatus.ValidationInProgress);
    }
}

