import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PersonCardModule } from "../../../../components/shared/person-card/person-card.module";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DmModalV2Module } from "../../../modals/dm-modal-v2/dm-modal-v2.module";
import { ResourceDetailsModalComponent } from "./resource-details.component";

@NgModule({
    declarations: [
        ResourceDetailsModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        DmModalV2Module,
        PersonCardModule
    ],
    providers: [
    ],
    entryComponents: [
        ResourceDetailsModalComponent,
    ],
    exports: [
        ResourceDetailsModalComponent,
    ]
})
export class ResourceDetailsModalModule { }