<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'calc(100vh - 125px)'">
  <dm-loading loader *ngIf="isComponentLoading" [showLoading]="showLoading"
    [loadingText]="'Engagement Breakdown Structure'"></dm-loading>
  <section class="col-md-12 dm-p-x-imp-10">
    <div class="dm-int-ebs__actions-section" *ngIf="!isComponentLoading">
      <button aria-label="Add Project"
        *ngIf="engagementDetails && engagementDetails.statusDescription.toLowerCase() === 'released' && engagementDetails?.canEditEnagagement"
        class="fxp fxp-btn--primary font-caption dm-p-10" type="button" (click)="openAddProjectModal()"><i
          class="icon icon-AddLegacy"></i> <span class="font-caption-alt">Add Project</span></button>
      <div class="col-md-5 dm-p-x-imp-0 dm-int-ebs__filters-section">
        <span class="dm-p-10 font-caption-alt">Projects</span>
        <dm-dropdown-menu [ariaLabel]="'Projects Dropdown'" class="dm-int-ebs__filter-dropdown"
          [id]="'dm-wbs-projects-filter-ddl'" [(value)]="selectedProject" [options]="engagementDetails?.projects"
          [labels]="'name'" [isDisabled]="isProjectFilterDisabled" (change)="onSelectedProjectChanged(selectedProject)">
          <option [value]="placeholder">All projects</option>
        </dm-dropdown-menu>
        <span class="dm-p-10 font-caption-alt">Tasks</span>
        <dm-dropdown-menu [ariaLabel]="'Tasks Dropdown'" class="dm-int-ebs__filter-dropdown"
          [id]="'dm-wbs-tasks-filter-ddl'" [(value)]="selectedTask" [options]="tasksFilterData" [labels]="'name'"
          [isDisabled]="isTaskFilterDisabled">
          <option [value]="placeholder">All</option>
        </dm-dropdown-menu>
      </div>
    </div>
  </section>
  <section class="dm-int-ebs__grid" role="grid" *ngIf="!isComponentLoading">
    <!-- CSS grid, Add grid column width in scss if you add a new field to grid -->
    <div role="row" class="dm-int-ebs__grid-header-row dm-int-ebs__grid-row">
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">
        <button type="button" id="toggle" class="acc-btn" (click)="expandCollapseAllProjects()"
          [title]="showProjectsExpanded ? 'Collapse Projects' : 'Expand Projects'"
          [attr.aria-expanded]="!showProjectsExpanded">
          <span class="icon checkmark-left" [ngClass]="showProjectsExpanded ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
        </button> Name
      </div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Duration</div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Planned Cost <br />
        ({{currencyCode | dmDisplayDashIfEmptyOrNull}})
        <span *ngIf="!currencyCode" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
      </div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Actual Cost <br />
        ({{currencyCode | dmDisplayDashIfEmptyOrNull}})
        <span *ngIf="!currencyCode" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
      </div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Planned Labor <br /> (hours)</div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Actual Labor <br /> (hours)</div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Labor Hours % <br /> Complete</div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Total Cost % <br /> Complete</div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Request for EBS <br /> State Change <dm-tooltip
          role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [ngbTooltipContent]="wbsTooltipText"
          ariaLabelToolTip="Ebs States Information" [dmIcon]="'tile__icon--info icon icon-info'">
        </dm-tooltip>
      </div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Edit</div>
      <div role="gridcell" class="dm-int-ebs__grid-header-cell">Add Task</div>
    </div>
    <ng-container
      *ngFor="let projectDetails of internalEngagementEBSViewModel | filter: (selectedProject ? {projectId : selectedProject.id} : undefined) ">
      <div role="row" class="dm-int-ebs__grid-row">
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          <span class="icon cursor-pointer" tabindex="0" [attr.aria-expanded]="projectDetails.isExpand"
            (keyup.enter)="expandCollapseProjects(projectDetails)" (click)="expandCollapseProjects(projectDetails)"
            (keyup.Space)="expandCollapseProjects(projectDetails)"
            [ngClass]="{ 'icon-chevron-up' : projectDetails.isExpand, 'icon-chevron-down' : !projectDetails.isExpand }"></span>
          <section class="dm-m-t-18 dm-m-l-8">
            <strong>Project: {{projectDetails?.projectName | dmDisplayDashIfEmptyOrNull}}</strong>
            <dm-tooltip *ngIf="projectDetails?.srCrossChargeAmount || projectDetails?.srCostCenter" role="tooltip"
              [dmPlacement]="'bottom-left'" [ngbTooltipContent]="additionalProjectInfo"
              [dmIcon]="'dm-tile--more-circle icon icon-more'" ariaLabelToolTip="more Project Information">
            </dm-tooltip>
            <ng-template #additionalProjectInfo>
              <section>
                <p>Additional Project Information</p>
              </section>
              <section>
                <ng-container *ngIf="projectDetails?.srCrossChargeAmount">
                  <label class="font-label">
                    SR Cross Charge
                  </label>
                  <p>
                    <span>{{projectDetails?.srCrossChargeAmount}}</span>
                    <span>{{projectDetails?.srCrossChargeType === "A" || projectDetails?.srCrossChargeType === "a" ?
                      (currencyCode ? currencyCode : "") : "%"}}</span>
                  </p>
                </ng-container>
                <ng-container *ngIf="projectDetails?.srCostCenter">
                  <label class="font-label">
                    SR Out Cost Center / Internal Order #
                  </label>
                  <p>
                    {{projectDetails?.srCostCenter}}
                  </p>
                </ng-container>
              </section>
            </ng-template>
            <br />
            Solution Area: {{projectDetails?.projectDomain | dmDisplayDashIfEmptyOrNull}} <br />
            EBS: {{projectDetails?.projectId | dmDisplayDashIfEmptyOrNull}} <br />
            <span>Project Manager
              <dm-user-name *ngIf="projectDetails.pjm" [name]="projectDetails?.pjm?.name"
                [alias]="projectDetails.pjm.alias"></dm-user-name>
            </span> <br />
            <span *ngIf="showNbueHours">NBUE Hours: {{projectDetails?.nbueHours | dmDisplayDashIfEmptyOrNull}}</span>
          </section>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          {{ projectDetails?.projectStartDate | date : 'dd-MMM-yyyy' | dmDisplayDateOrDashPipe}} to <br />
          {{ projectDetails?.projectEndDate | date : 'dd-MMM-yyyy' | dmDisplayDateOrDashPipe}} <br />
          <span *ngIf="!projectDetails?.projectStartDate && !projectDetails?.projectEndDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          {{projectDetails?.projectPlannedCost | dmDisplayCurrencyOrDash}}
          <span *ngIf="!projectDetails?.projectPlannedCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          {{projectDetails?.projectActualCost | dmDisplayCurrencyOrDash}}
          <span *ngIf="!projectDetails?.projectActualCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          {{projectDetails?.projectPlannedLabor | dmDisplayDashIfEmptyOrNull}}
          <span *ngIf="!projectDetails?.projectPlannedLabor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          {{projectDetails?.projectActualLabor | dmDisplayDashIfEmptyOrNull}}
          <span *ngIf="!projectDetails?.projectActualLabor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          {{projectDetails?.projectLaborConsumedPercentage | dmDisplayCurrencyOrDash}}
          <span *ngIf="!projectDetails?.projectLaborConsumedPercentage" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          {{projectDetails?.projectCostConsumedPercentage | dmDisplayCurrencyOrDash}}
          <span *ngIf="!projectDetails?.projectCostConsumedPercentage" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          <a *ngIf="projectDetails?.hasEditAccess" class="action-state-btn dm-link"
            [attr.aria-label]="'Request Status Change' + projectDetails?.projectName + '.'"
            (click)="openRequestStateChangeModal(projectDetails)"
            [title]="'Request state change for ' + projectDetails.projectName">
            <span>Request</span>
          </a>
        </div>
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          <a *ngIf="projectDetails?.hasEditAccess" class="action-state-btn dm-link"
            (click)="openEditProjectModal(projectDetails)"
            [attr.aria-label]="'Edit' + projectDetails?.projectName + '.'">
            <span><i class="icon icon-EditLegacy"></i>Edit</span>
          </a>
        </div>
        <!-- Users who can edit can also add tasks -->
        <div role="gridcell" class="dm-int-ebs__grid-body-cell">
          <button *ngIf="projectDetails?.hasEditAccess" class="action-state-btn" type="button"
            (click)="openAddTaskModal(projectDetails)" [title]="'Add task for ' + projectDetails.projectName"
            [attr.aria-label]="'Add task for ' + projectDetails.projectName">
            <span><i class="icon icon-AddLegacy"></i></span>
          </button>
        </div>
      </div>
      <div *ngFor="let serviceDetails of projectDetails.servicesData">
        <div
          *ngFor="let taskDetails of serviceDetails.tasksData | filter:(selectedTask ? {taskId : selectedTask.id} : undefined)">
          <div role="row" class="dm-int-ebs__grid-row" *ngIf="projectDetails.isExpand">
            <div role="gridcell" class="dm-int-ebs__grid-body-cell dm-p-l-imp-30">
              <strong>Task: {{taskDetails?.taskName | dmDisplayDashIfEmptyOrNull}}</strong> <br />
              EBS: {{taskDetails?.taskId | dmDisplayDashIfEmptyOrNull}} <br />
              <span *ngIf="!taskDetails?.taskName && !taskDetails?.taskId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
              {{ taskDetails?.taskStartDate | date : 'dd-MMM-yyyy' | dmDisplayDateOrDashPipe}} to <br />
              {{ taskDetails?.taskEndDate | date : 'dd-MMM-yyyy' | dmDisplayDateOrDashPipe}} <br />
              <span *ngIf="!taskDetails?.taskStartDate && !taskDetails?.taskEndDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
              {{taskDetails?.taskPlannedCost | dmDisplayCurrencyOrDash}}
              <span *ngIf="!taskDetails?.taskPlannedCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
              {{taskDetails?.taskActualCost | dmDisplayCurrencyOrDash}}
              <span *ngIf="!taskDetails?.taskActualCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
              {{taskDetails?.taskPlannedLabor | dmDisplayDashIfEmptyOrNull}}
              <span *ngIf="!taskDetails?.taskPlannedLabor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
              {{taskDetails?.taskActualLabor | dmDisplayDashIfEmptyOrNull}}
              <span *ngIf="!taskDetails?.taskActualLabor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
              {{taskDetails?.taskLaborConsumedPercentage | dmDisplayCurrencyOrDash}}
              <span *ngIf="!taskDetails?.taskLaborConsumedPercentage" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
              {{taskDetails?.taskCostConsumedPercentage | dmDisplayCurrencyOrDash}}
              <span *ngIf="!taskDetails?.taskCostConsumedPercentage" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
            </div>
            <div role="gridcell" class="dm-int-ebs__grid-body-cell">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
  <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>