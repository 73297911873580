import { WriteOperationActions, WriteOperationNotificationsActionTypes } from "./write-operation-notifications.action";
import { IProcessNotification } from "../../common/services/contracts/process-notifications.contracts";

export function reducer(state = {}, action: WriteOperationActions): { [instanceId: string]: IProcessNotification } {
    switch (action.type) {
        case WriteOperationNotificationsActionTypes.UPDATE_WRITE_OPERATION: {
            const newState = {
                ...state,
            };
            newState[action.operation.InstanceId] = action.operation;
            return newState;
        }
    }

    return state;
}