<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'322px'" [tileHeight]="'322px'">
    <!-- <button additionalActionsRight aria-label="Bot. Contact your PJC" class="dm-link pull-right display-flex font-caption-alt" title="Bot"
        type="button" (click)="onBotIconClicked()">
        <i class="icon iris-bot-icon"></i><span class="dm-p-t-5 dm-p-l-5">Contact your PJC</span>
    </button> -->
    <dm-loading loader *ngIf="isComponentLoading && !isServerError" [showLoading]="showLoading"
        [loadingText]="'Financial Summary Details'"></dm-loading>
    <ng-container *ngIf="!isComponentLoading">
        <section>
            <dm-release-and-activate-summary *ngIf="!isBaselineActive && !isInDecoRecoState"></dm-release-and-activate-summary>
        </section>
        <dm-financial-summary-details *ngIf="isBaselineActive || isInDecoRecoState"></dm-financial-summary-details>
    </ng-container>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>
