import { Component, Input, Inject, AfterViewInit, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from "@angular/core";
import { debounceTime, map } from "rxjs/operators";
import { IRoleSkills, IRoleDomainSkill } from "../../../common/services/contracts/one-profile.contracts";
import { Observable } from "rxjs";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { IDomainValues } from "../../../common/services/contracts/add-roles.contracts";
const DASH_REGEX = /^\s*(([abvt]-)|(partners~)).*/;

@Component({
    selector: "dm-type-ahead-skills",
    templateUrl: "./type-ahead-skills.html",
    styleUrls: ["./type-ahead-skills.scss"]
})
export class TypeAheadSkillsComponent implements AfterViewInit {

    @Input() public btnSearchAriaLabelText: string; // search Manager name
    @Input() public btnCancelAriaLabelText: string; // cancel Manager name
    @Input() public typeAheadId: string;
    @Input() public typeAheadLabelText: string;
    @Input() public typeAheadUserValidationRequiredMessage: string;
    @Input() public isRequired: boolean;
    @Input() public fteOnly: boolean;
    @Input() public typeAheadPlaceholder: string;
    @Input() public maxSkillsAllowToSelect: number;
    @Input() public isMultiselect: boolean;
    @Input() public selectedSkills: IRoleDomainSkill[] = [];
    @Input() public modelValue: any;
    @Input() public isDisabled: boolean = false;
    @Input() public set domain(value: IDomainValues) {
        this.domainValue = value;
        // this.validateSelectedSkills();
    }
    public get domain(): IDomainValues {
        return this.domainValue;
    }
    @Input() public isDomainSkillInvalid: boolean = false;
    @Output() public skillValidityUpdated: EventEmitter<boolean> = new EventEmitter();

    @ViewChild("typeAheadSearchSkills", { static: false }) public searchInputText: ElementRef;

    public errorMessage: string;
    public loadingUsers: string;
    public isDomainValid: boolean = true;
    private filterResult: IRoleDomainSkill[] = [];
    private domainValue: IDomainValues;

    public constructor(
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(Renderer2) private renderer: Renderer2
    ) { }

    public ngAfterViewInit(): void {
        if (this.searchInputText !== undefined) {
            this.renderer.removeAttribute(this.searchInputText.nativeElement, "aria-multiline");
        }
    }

    /**
     * To clearing the input filed on clicking clear icon
     */
    public clearText(): void {
        this.modelValue = null;
        this.pullFocusToElement(this.typeAheadId, true);
        this.isDomainValid = true;
        this.validateInput();
    }

    /**
     * Removing skill item from selected skills list
     * @param skill
     */
    public removeFromSelectedSkills(skill: IRoleSkills, index: number): void {
        if (this.selectedSkills && (this.selectedSkills.length - 1) > index) {
            this.pullFocusToElement("selectedSkills-" + (index + 1), false);
        } else if (this.selectedSkills && (this.selectedSkills.length - 1) > 0 && (this.selectedSkills.length - 1) <= index) {
            this.pullFocusToElement("selectedSkills-" + (index - 1), false);
        } else {
            this.pullFocusToElement(this.typeAheadId, false);
        }
        const tempSkills = this.selectedSkills;
        for (let i = 0; i < tempSkills.length; i++) { /* Remove the matching skill from the list */
            if (tempSkills[i].JobSkillValue === skill.JobSkillValue) {
                tempSkills.splice(i, 1);
            }
        }
        this.selectedSkills = tempSkills;
        // this.validateSelectedSkills();

        if (!(this.selectedSkills && this.selectedSkills.length)) {
            this.errorMessage = this.typeAheadUserValidationRequiredMessage;
        }

        if (this.selectedSkills && this.selectedSkills.length >= this.maxSkillsAllowToSelect) {
            this.isDisabled = true;
        } else {
            this.isDisabled = false;
        }
    }

    /**
     * Validating input
     */
    public validateInput(): void { // Called on every input, including inputs less than 3 chars
        this.errorMessage = this.getErrorMessage(this.modelValue ? this.modelValue.JobSkillValue : "");
    }

    /**
     * Load skills list
     * @param value 
     */
    public loadSkillsList(value: string): IRoleDomainSkill[] {
        if ((!this.getErrorMessage(value) || !this.isDomainValid) && (this.selectedSkills && this.selectedSkills.length < this.maxSkillsAllowToSelect)) {
            const roleSkills: IRoleDomainSkill[] = this.oneProfileService.getRoleSkills();
            if (roleSkills && roleSkills.length) {
                this.filterResult = roleSkills.filter((skill) => skill.JobSkillValue.toLowerCase().indexOf(value.toLowerCase()) > -1).slice(0, 7);
                this.setHasResults();
            } else {
                this.setNoResults();
            }
            return this.filterResult;
        }

        if (this.selectedSkills && this.selectedSkills.length >= this.maxSkillsAllowToSelect) {
            this.isDisabled = true;
        }
    }

    /**
     * Search list on typing input
     * @param text$
     */
    public search = (text$: Observable<string>): Observable<any> =>
        text$.pipe(
            debounceTime(200),
            map((term) => term === "" ? []
                : this.loadSkillsList(term)
            )
        );

    /**
     * Formatting user input on base selection
     * @param x
     */
    public formatter = (x: { JobSkillValue: string }): string => x.JobSkillValue;

    /**
     * Selecting item on skill select
     * @param item
     */
    public onSkillSelect(item: {item: IRoleDomainSkill}): void {
        // if (!this.isSkillDomainValid(item.item as IRoleDomainSkill)) {
        //     this.errorMessage = `Selected skill does not belong to the ${this.domainValue.domainValueName} Domain`;
        //     this.isDomainValid = false;
        //     return;
        // }

        const selectedItem = item.item as IRoleDomainSkill;
        this.isDomainValid = true;
        if (!this.isMultiselect) {
            this.modelValue = {
                JobSkillId: selectedItem.JobSkillId,
                JobSkillValue: selectedItem.JobSkillValue,
                Domains: selectedItem.Domains
            };
        } else {
            this.modelValue = null;
            this.formatter = (x: { JobSkillValue: string }) => x.JobSkillValue = "";
            let index = -1;
            if (this.selectedSkills && this.selectedSkills.length) {
                this.selectedSkills.some((obj, i) => {
                    return obj.JobSkillValue === selectedItem.JobSkillValue ? index = i : false;
                });
            }
            if (index < 0) {
                this.selectedSkills.push(
                    {
                        JobSkillId: selectedItem.JobSkillId,
                        JobSkillValue: selectedItem.JobSkillValue,
                        Domains: selectedItem.Domains,
                    });

                if (this.selectedSkills && this.selectedSkills.length >= this.maxSkillsAllowToSelect) {
                    this.isDisabled = true;
                } else {
                    this.isDisabled = false;
                }
            }
        }
    }

    /**
     * Validates current selected skills in terms of currently selected domain value.
     *
     * @returns {boolean}
     * @memberof AddRoleModalComponent
     */
    public validateSelectedSkills(): void {
        // const invalidSkills: string[] = [];

        // for (const skill of this.selectedSkills) {
        //     if (skill.Domains.filter((s) => s.DomainId.toString() !== this.domainValue.mddDomainValueId.toString()).length > 0) {              
        //         invalidSkills.push(skill.JobSkillValue);
        //     }
        // }

        // if (invalidSkills && invalidSkills.length > 0) {
        //     this.isDomainSkillInvalid = true;
        //     this.errorMessage = `The following skills do not belong to the ${this.domainValue.domainValueName} domain, remove them to proceed: ${invalidSkills.join()}`;
        //     this.skillValidityUpdated.emit(true);
        // } else {
        //     this.isDomainSkillInvalid = false;
        //     this.errorMessage = "";
        //     this.skillValidityUpdated.emit(false);
        // }
    }

    /**
     * Get and Return error message
     * @param value
     */
    private getErrorMessage(value: string): string {
        if (!this.isDomainValid || this.isDomainSkillInvalid) {
            return this.errorMessage;
        }

        if (value === "" && !(this.selectedSkills && this.selectedSkills.length)) {
            return this.typeAheadUserValidationRequiredMessage;
        }

        if (this.fteOnly && DASH_REGEX.test(value)) {
            return "Only FTEs can fill this skills";
        }

        return "";
    }

    /**
     * Set no results text
     */
    private setNoResults(): void {
        this.errorMessage = "No results found";
    }

    /**
     * Set has results text
     */
    private setHasResults(): void {
        this.errorMessage = "";
    }

    /**
     * Pull focus to search list on data load
     * @param id
     * @param isTimeOut
     */
    private pullFocusToElement(id: string, isTimeOut: boolean): void {
        if (isTimeOut) {
            setTimeout(() => {
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.focus();
                }
            });
        } else {
            const element = window.document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    }

    /**
     * Is skill domain matching the domain value passed into the typeahead (if any).
     *
     * @private
     * @param {IRoleDomainSkill} skill
     * @returns {boolean}
     * @memberof TypeAheadSkillsComponent
     */
    private isSkillDomainValid(skill: IRoleDomainSkill): boolean {
        if (this.domainValue && (skill.Domains.filter((s) => s.DomainId.toString() === this.domainValue.mddDomainValueId.toString()).length === 0)) {
            return false;
        }

        return true;
    }
}
