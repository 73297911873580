
import { combineLatest as observableCombineLatest } from "rxjs";
import { FxpRouteService, FxpEventBroadCastService, UserInfoService, FxpConstants, FxpMessageService, ErrorSeverityLevel } from "@fxp/fxpservices";
import { Component, Inject, forwardRef, Injector } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { v4 as uuid } from "uuid";
import { ActivateEngagementComponent } from "../../financial-plan/activate-engagement-modal/activate-engagement-modal.component";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmNotificationService } from "../../../../../common/services/dm-notification.service";
import { FinancialService } from "../../../../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.selector";
import { IEngagementDetailsApiV2, IProjectDetailsApiV2, ITeamDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { IEntityFinancials, IEntityFinancialSummary } from "../../../financial.model";
import { IFinancialDetailsV2State } from "../../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IState } from "../../../../../store/reducers";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { ProjectServiceV2 } from "../../../../../common/services/project.v2.service";
import { RouteName, Components, ResourceName, OperationName, FinancialType, BroadcastEvent, SourceConstants } from "../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { NotificationType } from "../../../../../common/services/contracts/notification-bar.contracts";
import { PlanForecastComponent } from "../../../../plan-forecast/planForecast.component";
import { IWbsSettings } from "../../../../../common/services/contracts/project.service.v2.contracts";
import { NavigationService } from "../../../../../common/services/navigation.service";

@Component({
    selector: "dm-release-and-activate-summary",
    templateUrl: "./release-and-activate-summary.html",
    styleUrls: ["./release-and-activate-summary.scss"]
})
export class ReleaseAndActivateSummaryComponent extends DmComponentAbstract {
    public canEditFinancialsOfEntity: boolean = false;
    public haveCurrentBaselineDetails: boolean = false;
    public isUserEngagementPjm: boolean = false;
    public isBaselineActive: boolean = false;
    public isProjectContext: boolean = false;
    public isProjectTypeFF: boolean = false;
    public isOrchestrationTriggered: boolean = false;
    public isInForecast2Pilot: boolean = false;
    public isInPlan2Pilot: boolean = false;
    public RouteName = RouteName;
    public showInitiateForecastLink: boolean = false;
    public showSentForActivationMessage: boolean = false;
    public isUserAdditionalTeammate: boolean = false; /* Is the user a Project/L1 PJM or additional PJM on any level. Does not include Engagement/L0 PJM */
    public primaryPjmAlias: string;
    public primaryPjmName: string;

    private engagementDetails: IEngagementDetailsApiV2;
    private engagementId: string;
    private isCurrentFinancialPlanActive: boolean;
    private isDeliveryBaseLineFinalized: boolean;
    private projectId: string;
    private FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(FxpEventBroadCastService) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(NavigationService) private navigationService: NavigationService,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.FinancialReleaseAndActivateSummary);
    }

    public ngOnInit(): void {
        this.projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        if (this.projectId) {
            this.isProjectContext = true;
        }
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

        this.navigationService.isNewForecastEnabled(this.engagementId).then((flightingResponse) => {
            if (flightingResponse && flightingResponse.isInForecastFlight) {
                this.isInForecast2Pilot = true;
            }
        });

        this.navigationService.isNewPlanEnabled(this.engagementId).then((flightingResponse) => {
            if (flightingResponse && flightingResponse.isInPlanFlight) {
                this.isInPlan2Pilot = true;
            }
        });

        if (this.isProjectContext) {
            this.storeDispatchService
                .requireEngagementDetails(this.engagementId, true)
                .requireFinancialDetailsV2(this.projectId, true)
                .load();
        } else {
            this.storeDispatchService
                .requireEngagementDetails(this.engagementId, true)
                .requireFinancialDetailsV2(this.engagementId, true)
                .load();
        }
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        const financialDetails$ = this.isProjectContext ? this.store.select(getEntireFinancialDetailsV2(this.projectId))
            : this.store.select(getEntireFinancialDetailsV2(this.engagementId));

        observableCombineLatest(
            financialDetails$,
            engagementDetails$,
            (
                financialDetails: IFinancialDetailsV2State,
                engagementDetails: IEngagementDetailsState,
            ) => {
                return ({
                    financialDetails,
                    engagementDetails,
                });
            }
        ).pipe(untilDestroyed(this))
            .subscribe(({
                financialDetails,
                engagementDetails,
            }) => {
                this.refreshOnItemInvalidation(financialDetails, engagementDetails);
                this.setLoadersBasedOnItemState(financialDetails, engagementDetails);
                this.setErrorsBasedOnItemState(financialDetails, engagementDetails);
                if (financialDetails.loaded && engagementDetails.loaded) {
                    this.engagementDetails = engagementDetails.engagementDetails;
                    const pjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("PPJM", this.engagementDetails);
                    if (pjmObj && pjmObj[0]) {
                        this.primaryPjmAlias = pjmObj[0].alias;
                        this.primaryPjmName = pjmObj[0].name;
                    }
                    this.isUserEngagementPjm = this.dmAuthorizationService.isLoggedinUserPpjm(this.engagementDetails);
                    this.isCurrentFinancialPlanActive = false;
                    this.isDeliveryBaseLineFinalized = false;
                    this.isOrchestrationTriggered = false;
                    this.checkIfEngagementIsSubmittedForActivation();
                    let currentBaseLineDetails: IEntityFinancialSummary;
                    let eacDetails: IEntityFinancialSummary;
                    let etcDetails: IEntityFinancialSummary;
                    if (this.isProjectContext) {
                        const projectFinancials: IEntityFinancials = financialDetails.financialDetails;
                        const projectDetails = this.engagementDetails.projects.filter((project: IProjectDetailsApiV2) => project.id === this.projectId)[0];
                        this.canEditFinancialsOfEntity = this.dmAuthorizationService.hasEditPermission(ResourceName.SAP_Engagement, OperationName.edit, null, projectDetails);  // todo double check this
                        this.isProjectTypeFF = projectDetails.contractType === "FF";
                        currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancials, FinancialType.CurrentFinancialPlan);
                        const delBaseLineFinancialPlanDetails = projectFinancials.financialPlanVersions.filter((plan) => plan.baseLineType === FinancialType.DeliveryBaseline)[0];
                        this.isDeliveryBaseLineFinalized = this.financialService.verifyIfDeliveryBaselineIsFinalized(delBaseLineFinancialPlanDetails);
                        if (currentBaseLineDetails) {
                            const currentFinancialPlanDetails = projectFinancials.financialPlanVersions.filter((plan) => plan.baseLineType === FinancialType.CurrentFinancialPlan)[0];
                            this.isCurrentFinancialPlanActive = this.financialService.verifyIfCurrentFinancialIsActive(currentFinancialPlanDetails);
                        }
                        eacDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancials, FinancialType.EAC);
                        etcDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancials, FinancialType.ETC);
                    } else {
                        const engagementFinancials: IEntityFinancials = (financialDetails as IFinancialDetailsV2State).financialDetails;
                        if (!this.isUserEngagementPjm) {
                            this.isUserAdditionalTeammate = this.dmAuthorizationService.isUserInEngagementLevelTeam(this.engagementDetails);
                            if (!this.isUserAdditionalTeammate) { /* If user is not in the engagement level team, check the projects */
                                for (const proj of this.engagementDetails.projects) {
                                    if (this.dmAuthorizationService.isUserInProjectLevelTeam(proj)) {
                                        this.isUserAdditionalTeammate = true;
                                        break;
                                    }
                                }
                            }
                        }
                        this.canEditFinancialsOfEntity = this.dmAuthorizationService.hasEditPermission(ResourceName.SAP_Engagement, OperationName.edit, this.engagementDetails, null);
                        const delBaseLineFinancialPlanDetails = engagementFinancials.financialPlanVersions.filter((plan) => plan.baseLineType === FinancialType.DeliveryBaseline)[0];
                        this.isDeliveryBaseLineFinalized = this.financialService.verifyIfDeliveryBaselineIsFinalized(delBaseLineFinancialPlanDetails);
                        this.isProjectTypeFF = this.engagementDetails.projects.filter((p) => p.contractType === "FF").length > 0;
                        currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.CurrentFinancialPlan);
                        if (currentBaseLineDetails) {
                            const currentFinancialPlanDetails = engagementFinancials.financialPlanVersions.filter((plan) => plan.baseLineType === FinancialType.CurrentFinancialPlan)[0];
                            this.isCurrentFinancialPlanActive = this.financialService.verifyIfCurrentFinancialIsActive(currentFinancialPlanDetails);
                        }
                        eacDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.EAC);
                        etcDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ETC);
                    }
                    this.showInitiateForecastLink = (currentBaseLineDetails && this.isCurrentFinancialPlanActive && !this.financialService.IsEACETCFinancialsExistsToShowForecast(eacDetails, etcDetails)) ? true : false;
                    this.haveCurrentBaselineDetails = currentBaseLineDetails && this.isCurrentFinancialPlanActive ? true : false;
                }
            });
        this.fxpBroadcastService.On(BroadcastEvent.ShowReleaseAndActivate, (event, args) => {
            if (args.engagementId === this.engagementId) {
                this.isOrchestrationTriggered = false;
            }
        });
    }

    /**
     * Navigate to plan & Forecast, if engagement is in new forecast pilot, route to new forecast experience.
     *
     * @memberof FinancialSummaryComponent
     */
    public navigateToForecast(): void {
        PlanForecastComponent.financialPlanToOpen = "Forecast";
        if (this.isProjectContext) {
            if (this.isInForecast2Pilot) {
                this.fxpRouteService.navigatetoSpecificState("projectForecast", {
                    wbsId: this.stateService.params.projectId
                });
            } else {
                this.fxpRouteService.navigatetoSpecificState(RouteName.ProjectPlanForecast);
            }
        } else {
            if (this.isInForecast2Pilot) {
                this.fxpRouteService.navigatetoSpecificState("engagementForecast", {
                    wbsId: this.stateService.params.engagementId
                });
            } else {
                this.fxpRouteService.navigatetoSpecificState(RouteName.EngagementPlanForecast);
            }
        }
    }

    /**
     * Ready for activation modal popup
     */
    public activateFinancialPlan(): void {
        if (this.isDeliveryBaseLineFinalized) {
            const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();  // include null check for bpid
            this.isOrchestrationTriggered = true;
            const orchestrationId: string = uuid();
            this.notificationService.createNotificationSubscriptionEntry(NotificationType.ReleaseAndActivate, loggedInUserData.BusinessPartnerId, this.engagementDetails.id, orchestrationId).then(() => {
                this.projectServiceFunction.orchestrateReleaseAndActivate(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.engagementDetails.id, this.engagementDetails.name, orchestrationId).then(() => {
                    this.fxpMessageService.addMessage("Release and Activate has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                    // Displays the new notification in the notification bar
                    this.notificationService.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.engagementDetails.id, orchestrationId, NotificationType.ReleaseAndActivate);
                });
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.ActivateFinancialPlan, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isOrchestrationTriggered = false;
                throw error;
            });
        } else {
            const modalRef: NgbModalRef = this.modalService.open(ActivateEngagementComponent, {
                backdrop: "static",
                windowClass: "release-activate-confirmation-modal dm-modal in",
                keyboard: true,
                centered: true,
                injector: this.injector
            });
            modalRef.componentInstance.engagementFullDetails = this.engagementDetails;
            modalRef.result.then((isOrchestrationSuccess: boolean) => {
                if (isOrchestrationSuccess) {
                    this.isOrchestrationTriggered = true;
                }
            });
        }
    }

    /**
     *
     * Calls the API to check if engagement is submitted for activation
     * @memberof FinancialSummaryComponent
     */
    private checkIfEngagementIsSubmittedForActivation(): void {
        this.projectServiceV2.getWbsSettings(this.engagementDetails.id)
            .then((response: IWbsSettings) => {
                this.showSentForActivationMessage = response.isSubmittedForManualActivation;
            }).catch((error) => {
                this.showSentForActivationMessage = false;
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.CheckIfEngagementIsSubmittedForActivation, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }
}
