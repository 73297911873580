import { ILoadableState } from "../reducers";
import * as fromWbsDemandsV2 from "./demands-withschedules.action";
import { IWbsDemand } from "../../common/services/contracts/project.service.v2.contracts";

export interface IDemandsWithSchedulesState extends ILoadableState {
    wbsDemands: IWbsDemand;
}

export const initialState: IDemandsWithSchedulesState = {
    wbsDemands: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromWbsDemandsV2.DemandsWithSchedulesActions): { [wbsId: string]: IDemandsWithSchedulesState } {
    switch (action.type) {
        case fromWbsDemandsV2.DemandsWithSchedulesActionTypes.LOAD_DEMANDSWITHSCHEDULES: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromWbsDemandsV2.DemandsWithSchedulesActionTypes.LOAD_DEMANDSWITHSCHEDULES_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                wbsDemands: action.wbsDemands,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromWbsDemandsV2.DemandsWithSchedulesActionTypes.LOAD_DEMANDSWITHSCHEDULES_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromWbsDemandsV2.DemandsWithSchedulesActionTypes.INVALIDATE_DEMANDSWITHSCHEDULES: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}