import { Component, EventEmitter, Input, Output, Inject, ViewChild, ElementRef, Renderer2, OnChanges, SimpleChanges, forwardRef } from "@angular/core";
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError } from "rxjs/operators";
import { Observable, from } from "rxjs";
import { CustomerService } from "../../../common/services/customer.service";
import { ICustomer } from "../../../common/services/contracts/customer.contract";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { DataService } from "../../../common/services/data.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ComponentPrefix, SourceConstants } from "../../../common/application.constants";
import { ErrorSeverityLevel } from "@fxp/fxpservices";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-type-ahead-tpid",
    templateUrl: "./type-ahead-tpid.html",
    styleUrls: ["./type-ahead-tpid.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DmTypeAheadTpidComponent),
            multi: true
        }
    ]
})
export class DmTypeAheadTpidComponent implements OnChanges, ControlValueAccessor {
    @Input() public btnSearchAriaLabelText: string;
    @Input() public btnCancelAriaLabelText: string;
    @Input() public typeAheadId: string;
    @Input() public typeAheadLabelText: string;
    @Input() public typeAheadCustomerValidationRequiredMessage: string;
    @Input() public selectedCustomer: ICustomer;
    @Input() public modelValue: ICustomer;
    @Input() public noResults: string;
    @Input() public isDisabled: boolean;
    @Input() public isRequired: boolean;
    @Input() public typeAheadPlaceholder: string;
    @Input() public typeaheadMinLength: number;
    @Output() public selectedCustomerUpdated: EventEmitter<any> = new EventEmitter();
    @ViewChild("typeAheadSearchTpid", { static: false }) public searchInputText: ElementRef;
    public searchFailed: boolean;
    public searching: boolean;
    public errorMessage: string;
    public loadingCustomers: string;
    public onChange: (...args: any[]) => void;
    public onTouched: (...args: any[]) => void;
    @Input() public get value(): ICustomer {
        return this.modelValue;
    }
    public set value(val: ICustomer) {
        this.modelValue = val;
        this.onChange(val);
        this.onTouched();
    }

    private selectedCustomerProperty: string = "selectedCustomer";

    public constructor(
        @Inject(CustomerService) protected customerService: CustomerService,
        @Inject(Renderer2) private renderer: Renderer2,
        @Inject(DMLoggerService) private dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) { }

    /**
     * If selectedCustomer value changes, then update typeahead model value.
     *
     * @param {SimpleChanges} changes
     * @memberof DmTypeAheadTpidComponent
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes[this.selectedCustomerProperty]) {
            this.modelValue = changes[this.selectedCustomerProperty].currentValue;
        }
    }

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }


    /**
     * This is a basic setter that the forms API is going to use
     *
     * @param {*} value
     * @memberof DmTypeAheadTpidComponent
     */
    public writeValue(value: ICustomer): void {
        if (value !== null && value !== undefined) {
            this.errorMessage = "";
            this.modelValue = value;
        }
    }

    public ngAfterViewInit(): void {
        if (this.searchInputText) {
            this.renderer.removeAttribute(this.searchInputText.nativeElement, "aria-multiline");
        }
    }

    /**
     * To clearing the input filed on clicking clear icon
     */
    public clearText(): void {
        this.modelValue = null;
        this.pullFocusToElement(this.typeAheadId, true);
        this.validateInput();
        this.selectedCustomerUpdated.emit(this.modelValue);
    }

    /**
     * Validating input
     */
    public validateInput(): void { // Called on every input, including inputs less than 3 chars
        this.errorMessage = this.getErrorMessage(this.modelValue ? this.modelValue.name : "");
    }

    /**
     * Search list on typing input
     * @param text$
     */
    public search = (text$: Observable<string>): Observable<any> =>
        text$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term) => term.length < 3 ? [] :
                this.getCustomerList(term)
            ),
            tap(() => this.searching = false)
        );

    /**
     * getCustomerList
     * @param searchValue
     */
    public getCustomerList(searchValue: string): Observable<ICustomer[]> {
        this.searching = true;
        const isNumeric = !isNaN(+searchValue);

        if (this.selectedCustomer) {
            this.selectedCustomer.name = "";
            this.selectedCustomer.topParentId = null;
        }

        if (!this.getErrorMessage(searchValue)) {
            let response: Promise<ICustomer[]>;

            // If search value is numeric tpid is provided, otherwise name was provided
            if (isNumeric) {
                response = this.customerService.searchCustomerByTpid(searchValue);
            } else {
                response = this.customerService.searchCustomerByName(searchValue);
            }

            return from(response).pipe(
                tap((result) => {
                    this.searchFailed = false;
                    result.length > 0 ? this.setHasResults() : this.setNoResults(searchValue);
                }),
                catchError((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.dmLogger.logError(ComponentPrefix + "Type-Ahead-Tpid", SourceConstants.Method.GetCustomerList, error, errorMessage, null, undefined, DataService.getCorrelationIdFromError(error), ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.searchFailed = true;
                    this.searching = false;
                    this.setNoResults(searchValue);
                    return [];
                })
            );
        }
    }

    /**
     * Formatting customer input on selection
     * @param x
     */
    public formatter = (x: { name: string; topParentId: string }): string => {
        if (x && x.name && x.topParentId) {
            return `${x.topParentId} - ${x.name}`;
        } else if (x && x.name && !x.topParentId) {
            return x.name;
        } else {
            return "";
        }
    };

    /**
     * Selecting item on customer select
     * @param item
     */
    public onCustomerSelect(item: {item: ICustomer}): void {
        const selectitem = item.item as ICustomer;
        this.modelValue = {
            name: selectitem.name,
            id: selectitem.id,
            topParentId: selectitem.topParentId,
            street: selectitem.street,
            city: selectitem.city,
            region: selectitem.region,
            country: selectitem.country,
            postalCode: selectitem.postalCode,
            isConfidential: selectitem.isConfidential
        };
        this.selectedCustomerUpdated.emit(this.modelValue);
    }

    /**
     * Get and Return error message
     * @param value
     */
    private getErrorMessage(value: string): string {
        if (!value) {
            return this.typeAheadCustomerValidationRequiredMessage;
        }
        return "";
    }

    /**
     * Set no results text
     * @param searchText
     */
    private setNoResults(searchText: string): void {
        this.errorMessage = "No results found for '" + searchText + "'";
    }

    /**
     * Set has results text
     */
    private setHasResults(): void {
        this.errorMessage = "";
    }

    /**
     * Pull focus to search list on data load
     * @param id
     * @param isTimeOut
     */
    private pullFocusToElement(id: string, isTimeOut: boolean): void {
        if (isTimeOut) {
            setTimeout(() => {
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.focus();
                }
            });
        } else {
            const element = window.document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    }

}
