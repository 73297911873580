<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'150px'"
    [isLoading]="isUpdatingService || viewResourceEnable || isUpdatingServiceProductFulfillmentDate">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isUpdatingService">
    </dm-loading>
    <div *ngIf="!isUpdatingService && !viewResourceEnable && !isNuanceServiceType">
        <!-- Form section -->
        <form [formGroup]="editServiceDetailsForm">
            <div class="dm-entity__name">{{selectedService?.name}}</div>
            <div class="dm-entity__id dm-p-b-10">{{selectedService?.id}}</div>

            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="startDate">
                        Start Date
                    </label>
                    <dm-datepicker id="startDate" name="serviceStartDate" formControlName="serviceStartDate"
                        [disabled]="disableEBSStartDateEndDateUpdates" [modelDate]="serviceStartDate.value"
                        [minDate]="minDate" [maxDate]="maxDate" ariaLabelButton="Start date"
                        [placeholder]="'Select a date...'" ariaLabelCalendar="Start date"
                        (onDateChange)="onStartDateChange($event)" previosFousingElementId="closeModalv2"
                        [showErrorBorder]="isStartDateRequired || isBeforeProjectStartDate || isAfterChildTaskStartDate"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isStartDateRequired">
                        {{editServiceErrorMessages.StartDateIsRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt error-msg" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeProjectStartDate">
                        {{editServiceErrorMessages.ServiceStartDateCannotBeEarlier}}
                        {{projectStartDate | dmDisplayDateOrDashPipe}}.
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterChildTaskStartDate">
                        {{editServiceErrorMessages.ServiceStartDateEarlierthanTaskStartDate}}
                        {{childTaskStartDate | dmDisplayDateOrDashPipe}}.
                        <span class="sr-only">{{editServiceErrorMessages.EitherUseCascadeUpdateOrUpdateAtTask}}</span>
                    </span>
                </div>
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="newEndDate">
                        End Date
                    </label>
                    <dm-datepicker id="endDate" name="serviceEndDate" [minDate]="minDate" [maxDate]="maxDate"
                        [modelDate]="serviceEndDate.value" [disabled]="disableEBSStartDateEndDateUpdates"
                        formControlName="serviceEndDate" [placeholder]="'Select a date...'" ariaLabelButton="End date"
                        ariaLabelCalendar="End date" (onDateChange)="onEndDateChange($event)"
                        previosFousingElementId="calenderBtn_Start date"
                        [showErrorBorder]="isEndDateRequired || isDateCompare || isAfterProjectEndDate || isBeforeChildTaskEndDate"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isEndDateRequired">
                        {{editServiceErrorMessages.EndDateRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isDateCompare">
                        {{editServiceErrorMessages.EndDateCannotBeEarlierThanTheStartDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterProjectEndDate">
                        {{editServiceErrorMessages.ServiceEndDateEarlierthanProjectEndDate}}
                        {{projectEndDate | dmDisplayDateOrDashPipe}}.
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeChildTaskEndDate">
                        {{editServiceErrorMessages.ServiceEndDateEarlierthanTaskEndDate}}
                        {{childTaskEndDate}}. {{editServiceErrorMessages.EitherUseCascadeUpdateOrUpdateAtTask}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showIODateValidationMessage">
                        {{editServiceErrorMessages.ServiceEndDateCannotBeGreaterIoEndDate}}
                        {{ioEndDate | dmDisplayDateOrDashPipe}}.
                    </span>
                </div>
            </div>
            <div class="clearfix"></div>
            <section class="col-md-12 col-sm-12 dm-p-12 radio__section" *ngIf="showCascadeRadioButtons">
                <section class="col-md-12 col-sm-12 dm-p-imp-0 clearfix modal__input--radio">
                    <section>
                        <span class="input__label--text input__label--d-b font-caption dm-m-b-10">
                            Apply date changes to
                        </span>
                    </section>
                    <section class="dm-p-imp-0">
                        <section class="col-md-6 col-sm-6 dm-p-imp-0">
                            <label for="checkService" focus="true" class="display-inline-flex-imp">
                                <span class="input-radio input-radio--focus" *ngIf="!disableServiceOnlyOption">
                                    <input id="checkService" type="radio" name="updateLevel"
                                        formControlName="updateLevel" (change)="onUpdateLevelChange(updateLevel.value)"
                                        [value]="EnumUpdateLevel.serviceLevel">
                                    <span class="slider round" aria-hidden="true"></span>
                                </span>
                                <input id="checkService" type="radio" name="updateLevel" *ngIf="disableServiceOnlyOption"
                                        formControlName="updateLevel" (change)="onUpdateLevelChange(updateLevel.value)"
                                        disabled
                                        [value]="EnumUpdateLevel.serviceLevel">
                                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                                    Service Only
                                </span>
                            </label>
                        </section>
                        <section class="col-md-6 col-sm-6 dm-p-imp-0">
                            <label for="checkAll" focus="true" class="display-inline-flex-imp">
                                <span class="input-radio input-radio--focus">
                                    <input id="checkAll" type="radio" name="updateLevel" formControlName="updateLevel"
                                        (change)="onUpdateLevelChange(updateLevel.value)"
                                        [value]="EnumUpdateLevel.allLowerLevels">
                                    <span class="slider round" aria-hidden="true"></span>
                                </span>
                                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                                    Services and Tasks
                                </span>
                            </label>
                        </section>
                    </section>
                </section>
            </section>
            <section class="font-label dm-p-b-l2 dm-p-t-4" role="alert" aria-atomic="true" aria-live="polite"
                *ngIf="showConflictingResources">
                <span class="font-caption-alt inp-validation">
                    {{editServiceErrorMessages.CannotChangeDateDueToResourcingConflict}}
                    ({{noOfConflictResources}}
                    {{getWordPluralWithS("Resource", noOfConflictResources, false)}}).
                </span>
                <button class="dm-link set-focus" type="button" (click)="toggleResources()"
                    aria-label="View resource dialogue.">
                    View resources
                </button>
            </section>
        </form>
    </div>
    <div infoBar *ngIf="!isUpdatingService && !viewResourceEnable && (!isStartDatePristine || !isEndDatePristine) && !isNuanceServiceType">
        <div class="dm-modal-v2-change-information-section" *ngIf="!isStartDatePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Service start date has been changed from
                '{{selectedService.startDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isEndDatePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Service end date has been changed from
                '{{selectedService.endDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
    </div>
    <div additionalFooterButtons *ngIf="!viewResourceEnable && !isNuanceServiceType">
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" *ngIf="!isUpdatingService"
            [disabled]="saveButtonDisabled(!editServiceDetailsForm.valid)" (click)="updateServiceDetails()">
            Submit
        </button>
    </div>
    <div additionalFooterButtons *ngIf="isNuanceServiceType">
        <button type="button" id="submitNuance" class="fxp-btn fxp-btn--primary dm-btn" *ngIf="!isUpdatingServiceProductFulfillmentDate"
            [disabled]="saveServiceProductFulfillmentDateButtonDisabled()" (click)="updateServiceProductFulfillmentDetails()">
            Submit
        </button>
    </div>
    <section class="resource-details-list" *ngIf="viewResourceEnable && !isNuanceServiceType">

        <div class="popUpModalDialog editEngagementForm">
            <section class="modal-header modal-noborder">
                <a href class="dm-link font-caption-alt" type="button" (click)="toggleResources()">
                    <i class="icon icon-Back"></i>
                    <span class="dm-p-r-10"> Return to Edit Service</span>
                </a>
            </section>

            <div class="modal-body">
                <section class="header__wrapper">
                    <div scope="col" class="header__resource">Resource</div>
                    <div scope="col" class="header__duration text-right">Duration</div>
                    <div scope="col" class="header__status">Status</div>
                </section>
                <div class="resource-details-list__wrapper">
                    <section class="font-caption-alt" *ngFor="let tasks of filteredResourceView">
                        <section class="caption">
                            <div>
                                <span class="caption__text caption__text--40"
                                    [textContent]="tasks.taskProjectName"></span>
                                <span class="caption__text"> / </span>
                                <span class="caption__text caption__text--30" [textContent]="tasks.taskname"></span>
                                <span class="caption__text"> / </span>
                                <span class="caption__text caption__text--20" [textContent]="tasks.taskName"></span>
                            </div>
                            <div>
                                EBS: <span [textContent]="tasks.taskId"></span>
                            </div>
                        </section>

                        <section class="border-solid dm-m-b-12" *ngIf="tasks.demands.length">
                            <div *ngFor="let demand of tasks.demands">
                                <div class="list-header">
                                    {{demand.demandRole}}
                                </div>
                                <section class="border-solid--bottom" *ngFor="let value of demand.resources">
                                    <div class="header__resource">
                                        <p [textContent]="value.resourceName"></p>
                                        <dm-user-name [name]="value.resourceAlias" [alias]="value.resourceAlias"
                                            (onNameClick)="logEmployeeClick()"></dm-user-name>
                                    </div>
                                    <div class="header__duration text-right">
                                        <p [textContent]="value.resourceStartDate | dmDisplayDateOrDashPipe"></p>
                                        <p [textContent]="value.resourceEndDate | dmDisplayDateOrDashPipe"></p>
                                    </div>
                                    <div class="header__status">
                                        <p [textContent]="value.resourceStatus"></p>
                                    </div>
                                    <div class="date__resource">
                                        <h5 class="date__resource--title">Start Date</h5>
                                        <p [textContent]="value.resourceStartDate | dmDisplayDateOrDashPipe"></p>
                                    </div>
                                    <div class="date__resource">
                                        <h5 class="date__resource--title">End Date</h5>
                                        <p [textContent]="value.resourceEndDate | dmDisplayDateOrDashPipe"></p>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </div>
    </section>
    <div *ngIf="isNuanceServiceType">
        
        <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isUpdatingServiceProductFulfillmentDate">
        </dm-loading>
        <div *ngIf="!isUpdatingServiceProductFulfillmentDate">
            <div class="dm-form-entity col-sm-6 col-lg-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                <label class="dm-form-entity__label">
                    {{isProductFulfillmentTermDuration ? "Term Duration Based" : "Fixed End date based"}}
                </label>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="contractStartDate"> 
                    {{isPostProductFulfillmentSolution ? "Project Start Date" : "Contract Go-Live Date"}}
                    </label>
                    <dm-datepicker id="contractStartDate" name="serviceContractStartDate"
                        [modelDate]="serviceContractStartDate"
                        [disabled]="true" ariaLabelButton="Contract Start date"
                        ariaLabelCalendar="Contract Start date"
                        previosFousingElementId="closeModalv2"
                        [isDatePickerV2]="false">
                    </dm-datepicker>
                </div>
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="contractEndDate">
                    {{isPostProductFulfillmentSolution ? "Project End Date" : "Contract Term End Date"}}
                    </label>
                    <dm-datepicker id="contractEndDate" name="serviceContractEndDate" 
                        [modelDate]="serviceContractEndDate" [disabled]="true"
                        ariaLabelButton="Contract End date"
                        ariaLabelCalendar="Contract End date" 
                        previosFousingElementId="calenderBtn_Start date"
                        [isDatePickerV2]="false">
                    </dm-datepicker>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="plannedStartdate">
                        Planned Go-Live Date
                    </label>
                    <dm-datepicker id="plannedStartdate" name="servicePlannedStartDate"
                        [disabled]="disableServiceActualStartDate" [modelDate]="plannedStartdate.value" ariaLabelButton="Planned Start Date"
                        [placeholder]="'Select a date...'" ariaLabelCalendar="Planned Start Date"
                        previosFousingElementId="closeModalv2" (onDateChange)="onPlannedStartDateChange($event)"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="!isPlannedStartDateValid">
                        {{editServiceProductFulfillmentErrorMessages.ServicePlannedStartDateMustBeFutureDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isPlannedStartDateRequired">
                        {{editServiceProductFulfillmentErrorMessages.ServicePlannedStartDateIsRequired}}
                    </span>
                </div>
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="plannedEndDate">
                        Planned Term End Date
                    </label>
                    <dm-datepicker id="plannedEndDate" name="servicePlannedEndDate" 
                        [modelDate]="servicePlannedEndDate" [disabled]="true"
                        [placeholder]="'Select a date...'" ariaLabelButton="Planned End date"
                        ariaLabelCalendar="Planned End date" 
                        previosFousingElementId="calenderBtn_Start date"
                        [isDatePickerV2]="false">
                    </dm-datepicker>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="actualStartdate">
                        Actual Go-Live Date
                    </label>
                    <dm-datepicker id="actualStartdate" name="serviceActualStartDate"
                        [disabled]="disableServiceActualStartDate" [modelDate]="actualStartdate.value"
                        ariaLabelButton="Actual Start date"
                        ariaLabelCalendar="Actual Start date"
                        previosFousingElementId="closeModalv2" (onDateChange)="onActualStartDateChange($event)"
                        [showErrorBorder]="!isActualStartDateValid"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="!isActualStartDateValid">
                        {{editServiceProductFulfillmentErrorMessages.ServiceActualStartDateMustBePastDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isServiceActualDateBeforeEngagementStartDate">
                        {{editServiceProductFulfillmentErrorMessages.ServiceActualStartDateCannotBeEarlierThanEngagement}}
                    </span>
                </div>
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="actualEndDate">
                        Actual Term End Date
                    </label>
                    <dm-datepicker id="actualEndDate" name="serviceActualEndDate" 
                        [modelDate]="serviceActualEndDate" [disabled]="true"
                        [placeholder]="'Select a date...'" ariaLabelButton="Actual End date"
                        ariaLabelCalendar="Actual End date" 
                        previosFousingElementId="calenderBtn_Start date"
                        [isDatePickerV2]="false">
                    </dm-datepicker>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0" *ngIf="isShowActualStartDateCheckBox">
                    <input type="checkbox" [checked]="isEnableCheckboxForActualStartDate" (change)="onCheckboxChange($event.target.checked)"
                      class="input__checkbox" value="None" id="enableActualStartDateCheckbox" name="check"
                      aria-label="I acknowledge that Actual start date cannot be changed once it is submitted. " />
                      <label for="checkboxShowForActualStartDate" class="font-caption-alt input__checkbox--text">
                          I acknowledge that Actual start date cannot be changed once it is submitted.
                      </label>
            </div>
        </div>
    </div>
</dm-modal-v2>