import * as fromMyPortfolio from "./my-portfolio-engagement-list.action";
import { ILoadableState } from "../../reducers";
import { IEngagementList, IPinnedEntities } from "../../../common/services/contracts/portfolio.model";
import moment from "moment";

export interface IMyPortfolioEngagementListState extends ILoadableState {
    engagementList: IEngagementList[];
    pinnedEntities: IPinnedEntities;
}

export const initialState: IMyPortfolioEngagementListState = {
    engagementList: null,
    pinnedEntities: null, /* This will always remain null/undefined because pinning functionality is currently turned off. This is here in case we want to use it in the future. 5/2019 */
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = initialState, action: fromMyPortfolio.MyPortfolioAction): IMyPortfolioEngagementListState {
    switch (action.type) {
        case fromMyPortfolio.MyPortfolioActionTypes
            .LOAD_MY_PORTFOLIO_ENGAGEMENTS: {
            return { ...initialState, loading: true };
        }

        case fromMyPortfolio.MyPortfolioActionTypes
            .LOAD_MY_PORTFOLIO_ENGAGEMENTS_SUCCESS: {
            return {
                ...initialState,
                engagementList: action.engagementList,
                loaded: true,
                loading: false,
            };
        }
        
        case fromMyPortfolio.MyPortfolioActionTypes
            .UPDATE_LOCALLY_MY_PORTFOLIO_ENGAGEMENTS: {
            const index = state.engagementList.findIndex((engagementItem) => engagementItem.engagementId === action.engagementId);
            if (index >= 0) {
                const newState = {...state};
                newState.engagementList = [...newState.engagementList];
                const engagementItem = newState.engagementList[index];
                const newEngagementItem = {...engagementItem};
                newState.engagementList[index] = newEngagementItem;
                newEngagementItem.engagementName = action.editEngagementDetails.name ? action.editEngagementDetails.name : newEngagementItem.engagementName;
                if (!action.editEngagementDetails.shouldCascadeUpdate) {
                    // if cascade is required then do reload instead of local change since async operation
                    newEngagementItem.startDate = action.editEngagementDetails.startDate ? moment(action.editEngagementDetails.startDate).toDate() : newEngagementItem.startDate;
                    newEngagementItem.endDate = action.editEngagementDetails.endDate ? moment(action.editEngagementDetails.endDate).toDate() : newEngagementItem.endDate;
                }
                newEngagementItem.ebsState = action.editEngagementDetails.status ? action.editEngagementDetails.status : newEngagementItem.ebsState;
                return newState;
            }
            return state;
        }

        case fromMyPortfolio.MyPortfolioActionTypes
            .LOAD_MY_PORTFOLIO_ENGAGEMENTS_FAIL: {
            return {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
        }

        case fromMyPortfolio.MyPortfolioActionTypes
            .INVALIDATE_MY_PORTFOLIO_ENGAGEMENTS: {
            return initialState;
        }
    }
    return state;
}
