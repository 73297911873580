
import { mergeMap, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { IResourceRequestResponse } from "../../common/services/contracts/staffing.service.contract";
import { StaffingService } from "../../common/services/staffing.service";
import * as resourceActionsProjectContext from "./resource-requests-project-context.action";

@Injectable()
export class ResourceRequestsProjectContextEffects {
    @Effect()
    public loadResourceRequestsProjectContext$ = this.actions$
        .ofType(resourceActionsProjectContext.ResourceRequestsProjectContextActionTypes.LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT).pipe(
            mergeMap((action: resourceActionsProjectContext.LoadResourceRequestsProjectContext) => fromPromise(this.staffingService
                .getGRMRequestsBasedOnProjectId(action.projectId))
                .pipe(
                    map((response: IResourceRequestResponse) => new resourceActionsProjectContext.LoadResourceRequestsProjectContextSuccess(action.projectId, response)),
                    catchError((error) => of(new resourceActionsProjectContext.LoadResourceRequestsProjectContextFail(action.projectId, error))
                    )
                )));
    public constructor(
        private actions$: Actions,
        @Inject(StaffingService) private staffingService: StaffingService
    ) { }
}
