import { Component, EventEmitter, forwardRef, Inject, Input, Output } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { Components, ProjectClosureModelEventTypes, ProjectClosureSubmitFormData, ProjectUserStatusTypes } from "../../../../../common/application.constants";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../..//common/services/sharedfunctions.service";
import { WBSService } from "../../../../../common/services/wbs.service";
import { DecoDetails, EngagementBillStatusDetails, PackageBillStatus, WbsETMDetails } from "../../../../../common/services/contracts/wbs.service.contracts";
import { ECIFDetails, InvoiceFFDetails, InvoiceTMDetails, InvoiceTMPrePayDetails, IODetails, RatablePartialECIFProjectDetails, RatablePartialECIFProjectRevenueDetails, RecoContract, RevenueTMDetails, ValidateFFBillingDetails, ValidateTMBillingDetails } from "../../../../../common/services/contracts/reco.contracts";
import { ProjectService } from "../../../../../common/services/project.service";
import { SharedComponentCallBackEventArgs, UserInfoService } from "@fxp/fxpservices";
import { IEcifIoConsumptionAPI } from "../../../../../common/services/contracts/ecif-io-consumed-modal.contracts";
import { IEngagementFinancial } from "../../../../../components/financial-mgmt/financial.model";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { isUndefined } from "util";
import { DmDisplayDateOrDashPipe } from "../../../../../common/services/filters/display-date-or-dash.pipe";

@Component({
    selector: "dm-reco-prevalidation",
    templateUrl: "./reco-prevalidation.html"
})

export class RecoPrevalidation extends DmComponentAbstract {
    @Input() public selectedEngagement: IEngagementDetailsV2;
    @Input() public selectedStatusCode: string;
    @Output() public modalCloseEvent = new EventEmitter();
    @Output() public submitEvent = new EventEmitter<FormData>();

    public engagementBillStatusDetails: EngagementBillStatusDetails;
    public recoContract: RecoContract;
    public invoiceFFDetails: InvoiceFFDetails;
    public invoiceRatableDetails: InvoiceFFDetails;
    public validateRatableBillingDetails: ValidateFFBillingDetails;
    public revenueTMDetails: RevenueTMDetails;
    public invoiceTMDetails: InvoiceTMDetails;
    public validateFFBillingDetails: ValidateFFBillingDetails;
    public validateTMBillingDetails: ValidateTMBillingDetails;
    public invoiceTMPrePayDetails: InvoiceTMPrePayDetails;
    public ecifDetails: ECIFDetails;
    public decoDetails: DecoDetails;
    public componentinputs: string;
    public isComponentLoading = false;
    public hasAnyECIFProjects = false;
    public hasAnyRatableProjects = false;
    public hasAnyRatableECIFProjects = false;
    public ratableECIFProjectDetails: RatablePartialECIFProjectDetails;
    private wbsId: string;
    

    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
        @Inject(CurrencyPipe) private currencyPipe: CurrencyPipe,
        @Inject(DecimalPipe) private numberPipe: DecimalPipe,
        @Inject(DmDisplayDateOrDashPipe) private dmDisplayDateOrDashPipe: DmDisplayDateOrDashPipe, ) {
        super(dmLogger, Components.ManageEbsEngagementDetails);
    }

    public ngOnInit(): void {
        this.constructRecoContract();
    }

    public constructRecoContract(): void {
        this.configurationService.initialize().then(() => {
            this.wbsId = this.sharedFunctionsService.getSelectedEngagementId(
                this.stateService
            );

            if (this.wbsId) {
                this.isComponentLoading = true;
                this.wbsService.GetEngagementBillStatusDetails(this.wbsId)
                    .then(async (billStatusDetails) => {
                        this.engagementBillStatusDetails = billStatusDetails;

                        this.hasAnyECIFProjects = this.isAnyProjectECIF(this.selectedEngagement.projects);
                        const rarProjects = this.selectedEngagement.projects.filter((p) => p.userStatusCode.toLocaleUpperCase().includes("RAR")
                            && !p.userStatusCode.toLocaleUpperCase().includes("MDL"));

                        if (rarProjects && rarProjects.length > 0) {
                            this.hasAnyRatableProjects = true;
                            this.hasAnyRatableECIFProjects = this.isAnyProjectRatableECIF(this.selectedEngagement.projects);
                            await this.getRatableProjectDetails();
                        }
                        this.hasAnyRatableECIFProjects = this.isAnyProjectRatableECIF(this.selectedEngagement.projects);

                        await this.GetWBSFinancialsDetails();

                        if (this.hasAnyECIFProjects) {
                            await this.GetIOConsumptionDetails();
                        }

                        await this.GetEngagementETMAndRunFFSeqDetails();

                        this.isComponentLoading = false;

                        this.recoContract = {
                            wbsId: this.wbsId,
                            wbsType: this.selectedEngagement.typeOfContract,
                            wbsStartDate: this.selectedEngagement.startDate,
                            wbsEndDate: this.selectedEngagement.endDate,
                            stateFrom: this.selectedEngagement.currentStatusCode,
                            stateTo: this.selectedStatusCode,
                            ecifDetails: this.ecifDetails,
                            invoiceFFDetails: this.invoiceFFDetails,
                            invoiceTMDetails: this.invoiceTMDetails,
                            invoiceTMPrePayDetails: this.invoiceTMPrePayDetails,
                            revenueTMDetails: this.revenueTMDetails,
                            validateFFBillingDetails: this.validateFFBillingDetails,
                            validateTMBillingDetails: this.validateTMBillingDetails,
                            invoiceRatableDetails: this.invoiceRatableDetails,
                            validateRatableBillingDetails: this.validateRatableBillingDetails,
                            ecifRatableDetails: this.ratableECIFProjectDetails,
                            isAnyProjectECIF: this.hasAnyECIFProjects,
                            isAnyProjectJPMVLFF: this.validateFFBillingDetails ? true : false,
                            isAnyProjectJPMVLTM: this.validateTMBillingDetails ? true : false,
                            isAnyProjectPrepayTM: this.invoiceTMPrePayDetails ? true : false,
                            hasAnyRARProjects: this.hasAnyRatableProjects,
                            hasAnyRARPartialECIFProjects: this.hasAnyRatableECIFProjects,
                            isAnyProjectJPMVLRatable: this.validateRatableBillingDetails ? true : false,
                            includeFFRules: this.selectedEngagement.projects.filter((p) => p.contractType === "FF" && !p.userStatusCode.toLocaleUpperCase().includes("MDL") && !p.userStatusCode.toLocaleUpperCase().includes("RAR")).length > 0
                        };

                        this.componentinputs = JSON.stringify({ input: this.recoContract, decoDetails: this.decoDetails });
                    })
                    .catch(() => this.isComponentLoading = false);
            }
        });
    }

    public onRecoComponentCallback($eventArgs: SharedComponentCallBackEventArgs): void {
        const payload = $eventArgs.detail;
        if (payload.eventName === ProjectClosureModelEventTypes.OnCancel) {
            this.modalCloseEvent.emit();
        }
        else if (payload.eventName === ProjectClosureModelEventTypes.OnSubmit) {
            const formData = new FormData();
            formData.append(ProjectClosureSubmitFormData.PJCStatusContract, JSON.stringify(payload.data[0]));

            this.submitEvent.emit(formData);
        }
        else if (payload.eventName === ProjectClosureModelEventTypes.OnRefresh) {
            this.constructRecoContract();
        }
    }

    private async GetWBSFinancialsDetails() {
        if (this.wbsId) {
            // Common result sets 
            const FFTypeProjectsInEngagement = this.selectedEngagement.projects
                .filter((project) => project.contractType.toLocaleUpperCase() === "FF" && !project.userStatusCode.toLocaleUpperCase().includes("MDL") && !project.userStatusCode.toLocaleUpperCase().includes("RAR"));

            const ProjectIdsOfFFTypeProjectsInEngagement = FFTypeProjectsInEngagement
                .map((project) => { return project.id; });

            const PackageBillStatusOfFFTypeProjects = this.engagementBillStatusDetails.packageBillStatus
                .filter((project) => ProjectIdsOfFFTypeProjectsInEngagement.includes(project.packageId));

            const TMTypeProjectsInEngagement = this.selectedEngagement.projects
                .filter((project) => project.contractType.toLocaleUpperCase() === "T&M" && !project.userStatusCode.toLocaleUpperCase().includes("MDL"));

            const ProjectIdsOfTMTypeProjectsInEngagement = TMTypeProjectsInEngagement
                .map((project) => { return project.id; });

            const PackageBillStatusOfTMTypeProjects = this.engagementBillStatusDetails.packageBillStatus
                .filter((project) => ProjectIdsOfTMTypeProjectsInEngagement.includes(project.packageId));

            const RatableProjectsInEngagement = this.selectedEngagement.projects
                .filter((project) => project.userStatusCode.toLocaleUpperCase().includes("RAR") && !project.userStatusCode.toLocaleUpperCase().includes("MDL"));

            const ProjectIdsOfRatableTypeProjectsInEngagement = RatableProjectsInEngagement
                .map((project) => { return project.id; });

            const PackageBillStatusORatableTypeProjects = this.engagementBillStatusDetails.packageBillStatus
                .filter((project) => ProjectIdsOfRatableTypeProjectsInEngagement.includes(project.packageId));

            await this.projectService.getFinancialsByEntityId(this.wbsId, true, false)
                .then((engagementFinancialsDetails: IEngagementFinancial) => {

                    if (engagementFinancialsDetails && engagementFinancialsDetails.engagementFinancials
                        && engagementFinancialsDetails.engagementFinancials[0] && engagementFinancialsDetails.engagementFinancials[0].projectsFinancials) {

                        // Rule 1
                        this.invoiceFFDetails = {
                            plannedCustomerBilling: this.transform(PackageBillStatusOfFFTypeProjects
                                .reduce((acc, curr) => { return acc + curr.totalPlanned; }, 0)),
                            actualCustomerBilling: this.transform(PackageBillStatusOfFFTypeProjects
                                .reduce((acc, curr) => { return acc + curr.totalBilled; }, 0)),
                            errors: []
                        };

                        // Rule 2
                        let plannedRevenue = 0;
                        let JpmOrVlemFFplannedBilling = 0;
                        const JpmOrVlemTypeFFProjects = FFTypeProjectsInEngagement.filter((project) =>
                            !project.userStatusCode.toLocaleUpperCase().includes("RAR") &&
                            project.services.some((service) => {
                                return this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.JPM
                                    || this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.VLEA;
                            }) || this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.JPM
                            || this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.VLEA)
                            .map((project) => { return project.id; });


                        const financialSummaryOfJpmOrVlemTypeFFProjects = engagementFinancialsDetails.engagementFinancials[0].projectsFinancials
                            .filter((project) => { return JpmOrVlemTypeFFProjects.includes(project.projectId); });

                        if (financialSummaryOfJpmOrVlemTypeFFProjects && financialSummaryOfJpmOrVlemTypeFFProjects.length) {
                            financialSummaryOfJpmOrVlemTypeFFProjects.forEach((projectFinancialDetails) => {
                                projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "ActualsCurrent")
                                    .forEach((financial) => {
                                        if (financial && financial.financials) {
                                            plannedRevenue += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                                        }
                                    });
                                projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "Bid")
                                    .forEach((financial) => {
                                        if (financial && financial.financials) {
                                            JpmOrVlemFFplannedBilling += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                                        }
                                    });

                            });

                            this.validateFFBillingDetails = {
                                plannedRevenue: this.transform(plannedRevenue),
                                plannedBilling: this.transform(JpmOrVlemFFplannedBilling),
                                errors: []
                            };
                        }

                        // Rule 3
                        let actualRevenue = 0;
                        let JpmOrVlemTMplannedBilling = 0;
                        const JpmOrVlemTypeTMProjects = TMTypeProjectsInEngagement.filter((project) =>
                            !project.userStatusCode.toLocaleUpperCase().includes("RAR") &&
                            project.services.some((service) => {
                                return this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.JPM
                                    || this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.VLEA;
                            }) ||
                            this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.JPM
                            || this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.VLEA)
                            .map((project) => { return project.id; });

                        const financialSummaryOfJpmOrVlemTypeTMProjects = engagementFinancialsDetails.engagementFinancials[0].projectsFinancials
                            .filter((project) => JpmOrVlemTypeTMProjects.includes(project.projectId));

                        if (financialSummaryOfJpmOrVlemTypeTMProjects && financialSummaryOfJpmOrVlemTypeTMProjects.length) {
                            financialSummaryOfJpmOrVlemTypeTMProjects.forEach((projectFinancialDetails) => {
                                projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "ActualsCurrent")
                                    .forEach((financial) => {
                                        if (financial && financial.financials) {
                                            actualRevenue += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                                        }
                                    });
                                projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "Bid")
                                    .forEach((financial) => {
                                        if (financial && financial.financials) {
                                            JpmOrVlemTMplannedBilling += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                                        }
                                    });
                            });

                            this.validateTMBillingDetails = {
                                actualRevenue: this.transform(actualRevenue),
                                plannedBilling: this.transform(JpmOrVlemTMplannedBilling),
                                errors: []
                            };
                        }

                        // Rule 4
                        this.revenueTMDetails = {
                            recognizedRevenue: this.GetTMRecognizedRevenue(ProjectIdsOfTMTypeProjectsInEngagement, engagementFinancialsDetails),
                            customerBilling: this.transform(this.GetCustomerBillingAmount(PackageBillStatusOfTMTypeProjects)),
                            errors: this.engagementBillStatusDetails.engagementBillStatus.openDmr > 0 ? ["Open Debit Memo Requests exist on this engagement. Billing action is required on DMRs before moving to RECO state."] : []
                        };

                        // Rule 5
                        let contractbaselineTypeRevenue = 0;

                        const financialSummaryOfTMProjects = engagementFinancialsDetails
                            .engagementFinancials[0]
                            .projectsFinancials
                            .filter((project) => ProjectIdsOfTMTypeProjectsInEngagement.includes(project.projectId));

                        financialSummaryOfTMProjects.forEach((projectFinancialDetails) => {
                            projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "Bid")
                                .forEach((financial) => {
                                    if (financial && financial.financials) {
                                        contractbaselineTypeRevenue += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                                    }
                                });
                        });

                        this.invoiceTMDetails = {
                            contractValue: this.transform(contractbaselineTypeRevenue),
                            customerBilling: this.transform(this.GetCustomerBillingAmount(PackageBillStatusOfTMTypeProjects)),
                            contractValueCost: contractbaselineTypeRevenue,
                            customerBillingCost: this.GetCustomerBillingAmount(PackageBillStatusOfTMTypeProjects),
                            errors: []
                        };

                        // Rule 6
                        const TMPrepayProjects = this.selectedEngagement.projects.filter((project) =>
                            this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.Prepay && !project.userStatusCode.toLocaleUpperCase().includes("MDL"))
                            .map((project) => { return project.id; });

                        if (TMPrepayProjects && TMPrepayProjects.length) {
                            this.invoiceTMPrePayDetails = {
                                recognizedRevenuee: this.GetPrepayRecognizedRevenue(ProjectIdsOfTMTypeProjectsInEngagement, engagementFinancialsDetails),
                                deliveryInvoicing: this.transform(this.engagementBillStatusDetails.engagementBillStatus.totalConsumed),
                                prepaidOrPeriodicbilling: this.transform(this.engagementBillStatusDetails.engagementBillStatus.totalPrePeriodic),
                                errors: this.engagementBillStatusDetails.engagementBillStatus.openDmr > 0 ? ["Open Debit Memo Requests exist on this engagement. Billing action is required on DMRs before moving to RECO state."] : []
                            };
                        }

                        // Rule 8

                        this.invoiceRatableDetails = {
                            plannedCustomerBilling: this.transform(PackageBillStatusORatableTypeProjects
                                .reduce((acc, curr) => { return acc + curr.totalPlanned; }, 0)),
                            actualCustomerBilling: this.transform(PackageBillStatusORatableTypeProjects
                                .reduce((acc, curr) => { return acc + curr.totalBilled; }, 0)),
                            errors: []
                        };

                        // Rule 9
                        let PlannedRatableRevenue = 0;
                        let JpmOrVlemRatableplannedBilling = 0;
                        const JpmOrVlemTypeRARProjects = RatableProjectsInEngagement.filter((project) =>
                            project.services.some((service) => {
                                return this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.JPM
                                    || this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.VLEA;
                            }) || this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.JPM
                            || this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.VLEA)
                            .map((project) => { return project.id; });


                        const financialSummaryOfJpmOrVlemTypeRARProjects = engagementFinancialsDetails.engagementFinancials[0].projectsFinancials
                            .filter((project) => { return JpmOrVlemTypeRARProjects.includes(project.projectId); });

                        if (financialSummaryOfJpmOrVlemTypeRARProjects && financialSummaryOfJpmOrVlemTypeRARProjects.length) {
                            financialSummaryOfJpmOrVlemTypeRARProjects.forEach((projectFinancialDetails) => {
                                projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "ActualsCurrent")
                                    .forEach((financial) => {
                                        if (financial && financial.financials) {
                                            PlannedRatableRevenue += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                                        }
                                    });
                                projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "Bid")
                                    .forEach((financial) => {
                                        if (financial && financial.financials) {
                                            JpmOrVlemRatableplannedBilling += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                                        }
                                    });

                            });

                            this.validateRatableBillingDetails = {
                                plannedRevenue: this.transform(PlannedRatableRevenue),
                                plannedBilling: this.transform(JpmOrVlemRatableplannedBilling),
                                errors: []
                            };
                        }


                    }
                })
                .catch(() => {
                    this.invoiceFFDetails = {
                        actualCustomerBilling: undefined,
                        plannedCustomerBilling: undefined,
                        errors: ["An error has occured while retrieving Complete invoicing (FF) Actuals."]
                    };
                    this.invoiceTMDetails = {
                        contractValue: undefined,
                        customerBilling: undefined,
                        customerBillingCost: undefined,
                        contractValueCost: undefined,
                        errors: ["An error has occured while retrieving Validate invoicing (TM) Actuals."]
                    };
                    this.invoiceTMPrePayDetails = {
                        deliveryInvoicing: undefined,
                        prepaidOrPeriodicbilling: undefined,
                        recognizedRevenuee: undefined,
                        errors: ["An error has occured while retrieving TM Prepay Actuals."]
                    };
                    this.revenueTMDetails = {
                        customerBilling: undefined,
                        recognizedRevenue: undefined,
                        errors: ["An error has occured while retrieving Complete invoicing and validate revenue (TM) Actuals."]
                    };
                    this.validateFFBillingDetails = {
                        plannedRevenue: undefined,
                        plannedBilling: undefined,
                        errors: ["An error has occured while retrieving Validate FF billing (JPM/VL FF) Actuals."]
                    };
                    this.validateTMBillingDetails = {
                        actualRevenue: undefined,
                        plannedBilling: undefined,
                        errors: ["An error has occured while retrieving Validate TM billing (JPM/VL TM) Actuals."]
                    };
                    this.invoiceRatableDetails = {
                        actualCustomerBilling: undefined,
                        plannedCustomerBilling: undefined,
                        errors: ["An error has occured while retrieving Complete invoicing (Ratable) Actuals."]
                    };
                    this.validateRatableBillingDetails = {
                        plannedRevenue: undefined,
                        plannedBilling: undefined,
                        errors: ["An error has occured while retrieving Validate Ratable billing (JPM/VL Ratable) Actuals."]
                    };
                });
        }
    }

    private async GetIOConsumptionDetails() {
        if (this.wbsId) {
            await this.projectService.getEcifIoConsumption(this.wbsId)
                .then((ioConsumptionDetails: IEcifIoConsumptionAPI[]) => {

                    // Rule 7

                    const ecifTypeProjects = {};

                    this.selectedEngagement.projects.forEach((project) => {
                        if (!project.userStatusCode.toLocaleUpperCase().includes("MDL")) {
                            if (this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.ECIF) {
                                ecifTypeProjects[project.id] = project.id;

                            }
                            const ecifPartialServices = project.services.filter((service) => this.sharedFunctionsService.getProjectOrServiceUserStatus(service.userStatusCode) === ProjectUserStatusTypes.ECIFPartial);

                            if (ecifPartialServices && ecifPartialServices.length > 0) {
                                ecifPartialServices.forEach((service) => {
                                    ecifTypeProjects[service.id] = project.id;
                                });
                            }
                        }

                    });

                    const ecifTypeProjectsIds = Object.keys(ecifTypeProjects);


                    const ECIFTypeProjects = ioConsumptionDetails.filter((res) => ecifTypeProjectsIds.includes(res.wbsId));
                    let consumedFunds = 0;
                    let totalFunds = 0;
                    ECIFTypeProjects.forEach((res) => consumedFunds += res.ioDetails.reduce((acc, curr) => { return acc += curr.consumedFunds; }, 0));
                    ECIFTypeProjects.forEach((res) => totalFunds += res.ioDetails.reduce((acc, curr) => { return acc += curr.totalFunds; }, 0));

                    const ioDetailsData: IODetails[] = [];
                    ECIFTypeProjects.forEach((wbs) => {
                        wbs.ioDetails.forEach((ioDetails) => {
                            ioDetailsData.push(
                                {
                                    "Project Id": ecifTypeProjects[wbs.wbsId],
                                    "IO Number": ioDetails.ioNumber,
                                    "Status": ioDetails.userStatusDescription,
                                    "Total Funds": ioDetails.totalFunds,
                                    "Consumed Funds": ioDetails.consumedFunds,
                                    "Currency": ioDetails.currency,
                                });
                        });

                    });

                    this.ecifDetails = {
                        consumedFunds: this.transform(consumedFunds, ioDetailsData[0].Currency),
                        totalFunds: this.transform(totalFunds, ioDetailsData[0].Currency),
                        ioDetails: ioDetailsData,
                        errors: []
                    };
                })
                .catch(() => {
                    this.ecifDetails = {
                        consumedFunds: undefined,
                        totalFunds: undefined,
                        ioDetails: [],
                        errors: ["An error has occured while retrieving IO Status and values (ECIF) Actuals."]
                    };
                });
        }
    }

    private async GetEngagementETMAndRunFFSeqDetails() {
        if (this.wbsId) {
            await this.wbsService.GetEngagementETMAndRunFFSeqDetails(this.wbsId)
                .then((wbsETMDetails: WbsETMDetails) => {
                    if (wbsETMDetails) {
                        this.decoDetails = {
                            isETM: wbsETMDetails.isETM,
                            runFFSeq: wbsETMDetails.runFFSeq,
                            errors: []
                        };
                    }
                })
                .catch(() => {
                    this.decoDetails = {
                        isETM: undefined,
                        runFFSeq: undefined,
                        errors: ["There was a network error while getting the DECO information from API. Please try again later."]
                    };
                });
        }
    }

    private async getRatableProjectDetails() {
        await this.projectService.getRatableProjectDetails(this.wbsId).then((response) => {
            if (response && response.ratableProjectDetails && response.ratableProjectDetails.length > 0) {
                const ratableECIFProjects = response.ratableProjectDetails.filter((project) => project.revType.toUpperCase() === "PARTIAL ECIF");
                const ratableECIFProjectRevDetails: RatablePartialECIFProjectRevenueDetails[] = [];
                if (ratableECIFProjects && ratableECIFProjects.length > 0) {
                    ratableECIFProjects.forEach((ratableECIFproject) => {
                        const ratableECIFProject: RatablePartialECIFProjectRevenueDetails = {
                            serviceID: ratableECIFproject.wbsId,
                            ContractEndDate: this.dmDisplayDateOrDashPipe.transform(ratableECIFproject.projectEndDate),
                            CurrentDate: this.dmDisplayDateOrDashPipe.transform(ratableECIFproject.currentDate),
                            contractRevenue: this.transform(ratableECIFproject.plannedRevenue, ratableECIFproject.currency),
                            recognizedRevenue: this.transform(ratableECIFproject.actualRevenue, ratableECIFproject.currency),
                            recognizedRevenuePercentage: ratableECIFproject.revenueRecognizedPercentage,
                            ConsumedFund: this.transform(ratableECIFproject.consumedFund, ratableECIFproject.currency),
                            PlannedFund: this.transform(ratableECIFproject.plannedFund, ratableECIFproject.currency),
                            Currency: ratableECIFproject.currency,
                            IONumber: ratableECIFproject.internalOrderNumber,
                            IOStatus: ratableECIFproject.ioStatusText,

                        };
                        ratableECIFProjectRevDetails.push(ratableECIFProject);
                    });

                    this.ratableECIFProjectDetails = {
                        ContractEndDate: this.dmDisplayDateOrDashPipe.transform(response.projectEndDate),
                        CurrentDate: this.dmDisplayDateOrDashPipe.transform(response.currentDate),
                        ContractEndDateVal: response.projectEndDate,
                        CurrentDateVal: response.currentDate,
                        contractRevenue: this.transform(ratableECIFProjects.reduce((revenue, ratableproj) => revenue + ratableproj.plannedRevenue, 0), ratableECIFProjects[0].currency),
                        recognizedRevenue: this.transform(ratableECIFProjects.reduce((revenue, ratableproj) => revenue + ratableproj.actualRevenue, 0), ratableECIFProjects[0].currency),
                        // recognizedRevenuePercentage: ratableECIFProjects.reduce((revenue, ratableproj) => revenue + ratableproj.revenueRecognizedPercentage, 0) / ratableProjects.length,
                        ConsumedFund: this.transform(ratableECIFProjects.reduce((consumedFund, ratableproj) => consumedFund + ratableproj.consumedFund, 0), ratableECIFProjects[0].currency),
                        PlannedFund: this.transform(ratableECIFProjects.reduce((plannedFund, ratableproj) => plannedFund + ratableproj.plannedFund, 0), ratableECIFProjects[0].currency),
                        ioDetails: ratableECIFProjectRevDetails

                    };
                }


            }

        }).catch(() => {
            this.ratableECIFProjectDetails = {
                ContractEndDate: undefined,
                CurrentDate: undefined,
                contractRevenue: undefined,
                recognizedRevenue: undefined,
                ContractEndDateVal: undefined,
                CurrentDateVal: undefined,
                // recognizedRevenuePercentage: undefined,
                ConsumedFund: undefined,
                PlannedFund: undefined,
                ioDetails: undefined,
                errors: ["Error in retrieving ratable project details"]
            };
        });

    }

    /**
     * Checks all Projects and returns true if any one is ECIF
     * @param projects List of Projects
     */
    private isAnyProjectECIF(projects: IProjectDetailsV2[]): boolean {
        let isProjectECIF = false;

        projects.forEach((project) => {
            if (project && !project.userStatusCode.toLocaleUpperCase().includes("MDL") &&  !project.userStatusCode.toLocaleUpperCase().includes("RAR")) {
                isProjectECIF = isProjectECIF || this.sharedFunctionsService.isProjectECIF(project.userStatusCode);
            }
        });
        return isProjectECIF;
    }

    private isAnyProjectRatableECIF(projects: IProjectDetailsV2[]): boolean {
        let isProjectRatableECIF = false;

        projects.forEach((project) => {
            if (project && !project.userStatusCode.toLocaleUpperCase().includes("MDL") && project.userStatusCode.toLocaleUpperCase().includes("RAR")) {
                isProjectRatableECIF = isProjectRatableECIF ||
                    project.services.some((service) => { return service.userStatusCode.toLocaleUpperCase().includes("ECP"); });
            }
        });
        return isProjectRatableECIF;

    }

    private GetCustomerBillingAmount(packageBillStatusOfTMTypeProjects: PackageBillStatus[]): number {
        const prePayTypeProjects = this.selectedEngagement.projects.filter((project) =>
            this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.Prepay && !project.userStatusCode.toLocaleUpperCase().includes("MDL"));

        if (prePayTypeProjects && prePayTypeProjects.length) {
            const totalBilledAmountForTNMProjects = packageBillStatusOfTMTypeProjects.reduce((acc, curr) => { return acc + curr.totalBilled; }, 0);

            if (totalBilledAmountForTNMProjects > this.engagementBillStatusDetails.engagementBillStatus.totalConsumed && this.engagementBillStatusDetails.engagementBillStatus.creditBalance === 0
                && this.engagementBillStatusDetails.engagementBillStatus.totalPrePeriodic === this.engagementBillStatusDetails.engagementBillStatus.totalConsumed) {
                return totalBilledAmountForTNMProjects - this.engagementBillStatusDetails.engagementBillStatus.totalConsumed;
            }
            else {
                return 0;
            }
        }

        return packageBillStatusOfTMTypeProjects
            .reduce((acc, curr) => { return acc + curr.totalBilled; }, 0);
    }

    private GetPrepayRecognizedRevenue(projectIdsOfTMTypeProjectsInEngagement: string[], engagementFinancialsDetails: IEngagementFinancial): string {
        let TMPrepayRecognizedRevenue = 0;

        const financialSummaryOfTMProjects = engagementFinancialsDetails
            .engagementFinancials[0]
            .projectsFinancials
            .filter((project) => projectIdsOfTMTypeProjectsInEngagement.includes(project.projectId));

        financialSummaryOfTMProjects.forEach((projectFinancialDetails) => {
            projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "ActualsCurrent")
                .forEach((financial) => {
                    if (financial && financial.financials) {
                        TMPrepayRecognizedRevenue += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                    }
                });
        });

        if (TMPrepayRecognizedRevenue > this.engagementBillStatusDetails.engagementBillStatus.totalPrePeriodic) {
            return this.transform(this.engagementBillStatusDetails.engagementBillStatus.totalPrePeriodic);
        }
        else {
            return this.transform(TMPrepayRecognizedRevenue);
        }
    }

    private GetTMRecognizedRevenue(projectIdsOfTMTypeProjectsInEngagement: string[], engagementFinancialsDetails: IEngagementFinancial): string {

        let recognizedRevenue = 0;

        const prePayTypeProjects = this.selectedEngagement.projects.filter((project) =>
            this.sharedFunctionsService.getProjectOrServiceUserStatus(project.userStatusCode) === ProjectUserStatusTypes.Prepay);

        const financialSummaryOfTMProjects = engagementFinancialsDetails
            .engagementFinancials[0]
            .projectsFinancials
            .filter((project) => projectIdsOfTMTypeProjectsInEngagement.includes(project.projectId));

        financialSummaryOfTMProjects.forEach((projectFinancialDetails) => {
            projectFinancialDetails.financialDetails.filter((financial) => financial.baseLineType === "ActualsCurrent")
                .forEach((financial) => {
                    if (financial && financial.financials) {
                        recognizedRevenue += financial.financials.reduce((acc, curr) => { return acc + curr.revenue; }, 0);
                    }
                });
        });

        if (prePayTypeProjects && prePayTypeProjects.length) {
            return this.transform(recognizedRevenue - this.engagementBillStatusDetails.engagementBillStatus.totalPrePeriodic);
        }
        else {
            return this.transform(recognizedRevenue);
        }
    }

    private transform(value: number, currencyCode?: string): string {
        if (!value) {
            return "0.00";
        }

        if (!currencyCode) {
            return this.numberPipe.transform(value, "1.2-2");
        }

        const result: string = this.currencyPipe.transform(value, currencyCode, "symbol-narrow");
        if (result[0] === "-") {
            let newResult = "";
            let flag = true;
            for (let i = 1; i < result.length; i++) {
                if (flag && !isNaN(Number(result[i]))) {
                    newResult += "-" + result[i];
                    flag = false;
                } else {
                    newResult += result[i];
                }
            }
            return newResult;
        }

        return result;
    }
}