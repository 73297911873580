import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ProjectService } from "../../common/services/project.service";
import * as clinSlinActions from "./clinslin.action";

@Injectable()
export class ClinslinEffect {

    @Effect()
    public loadClinSlin$ = this.actions$
        .ofType(clinSlinActions.ClinslinActionTypes.LOAD_Clinslin)
        .pipe(
            switchMap((action: clinSlinActions.LoadClinslin) => fromPromise(
                (action.wbsId.length > 12) ? this.projectService.getClinSlinForProjectId(action.wbsId, true) :
                    this.projectService.getClinSlinForEngagementId(action.wbsId, true)
            )
                .pipe(
                    switchMap((clisSlinResponse) => [
                        new clinSlinActions.LoadClinslinSuccess(action.wbsId, clisSlinResponse),
                    ]),
                    catchError((error) => of(new clinSlinActions.LoadClinslinFail(action.wbsId, error)))
                )
            ));

    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService
    ) { }
}
