import { Component, Inject } from "@angular/core";
import { RouteName } from "../../common/application.constants";
import { UIRouter } from "@uirouter/angular";

@Component({
    selector: "dm-engagement-detail",
    templateUrl: "./engagement-detail.html",
    styleUrls: ["./engagement-detail.scss"]
})
export class EngagementDetailComponent {

    public readonly routeName = RouteName;

    public constructor(
        @Inject(UIRouter) public uiRouter: UIRouter
    ) { }

}
