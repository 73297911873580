import { Component, forwardRef, Inject, Input, Output, EventEmitter, Injector } from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Components } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { FilterModalComponent } from "./project-filter-modal/project-filter-modal.component";
import { FilterModel } from "./project-filter.model";
import { ICBSProjectFinancial } from "../../financial-mgmt/financial.model";
import { IProjectDetails } from "../../../common/services/contracts/project.service.contracts";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IProjectDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";


@Component({
    selector: "dm-project-filter",
    templateUrl: "./project-filter.html",
    styleUrls: ["./project-filter.scss"]
})
export class ProjectFilterComponent extends DmComponentAbstract {
    @Input() public selectedProjectId: string;
    @Input() public selectedServiceId: string;
    @Input() public selectedTaskId: string;
    @Input() public sourceData: IProjectDetails[] | ICBSProjectFinancial[];
    @Input() public sourceDataV2?: IProjectDetailsV2[];
    @Output() public updateSelectedProjectId = new EventEmitter<string>();
    @Output() public updateSelectedServiceId = new EventEmitter<string>();
    @Output() public updateSelectedTaskId = new EventEmitter<string>();
    public items: FilterModel[];
    public selectedProject: FilterModel = new FilterModel(undefined, undefined);
    public selectedService: FilterModel = new FilterModel(undefined, undefined);
    public selectedTask: FilterModel = new FilterModel(undefined, undefined);
    public placeholder: FilterModel = new FilterModel(undefined, undefined);
    public isProjectContext: boolean; // Is the user viewing this as a single project, or is the user viewing this from the engagement and wants access to all projects

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.ProjectFilter);
    }

    public ngOnInit(): void {
        if (this.sourceDataV2 && this.sourceDataV2.length > 0) {
            this.items = this.getFilterDataV2(this.sourceDataV2);
        } else {
            this.items = this.getFilterData(this.sourceData);
        }

        if (this.selectedProjectId) {
            this.isProjectContext = true;
            this.items = this.items.filter((x) => x.id === this.selectedProjectId);
            this.selectedProject = this.items[0];
        }
        this.endComponentLoad();
    }

    /**
     * The selected project has changed. Update the selected project and its available tasks and services.
     */
    public onSelectedProjectChanged(filteredProject: FilterModel): void {
        this.selectedProject = filteredProject;
        this.selectedService = this.placeholder;
        this.selectedTask = this.placeholder;
        this.selectedProjectChanged();
        this.selectedServiceChanged();
        this.selectedTaskChanged();
    }

    /**
     * The selected service has changed. Update the selected service and its available tasks.
     */
    public onSelectedServiceChanged(filteredService: FilterModel): void {
        this.selectedService = filteredService;
        this.selectedTask = this.placeholder;
        this.selectedServiceChanged();
        this.selectedTaskChanged();
    }

    /**
     * The selected task has been changed. Update the selected task.
     */
    public onSelectedTaskChange(filteredTask: FilterModel): void {
        this.selectedTask = filteredTask;
        this.selectedTaskChanged();
    }

    /**
     * Opens the Edit Filter Modal that allows users to select
     * filters in a modal window. Used on mobile views only.
     */
    public openEditFiltersModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(FilterModalComponent, {
            backdrop: "static",
            windowClass: "in active dm-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.selectedProject = this.selectedProject;
        modalRef.componentInstance.selectedService = this.selectedService;
        modalRef.componentInstance.selectedTask = this.selectedTask;
        modalRef.componentInstance.items = this.items;
        modalRef.componentInstance.isProjectContext = this.isProjectContext;

        // todo call backs to this controller to indicate filter selections have been made
        // }).result.then((selected) => {
        //     this.ngProjectchanged(selected[0]);
        //     this.ngServicechanged(selected[1]);
        //     this.ngTaskChanged(selected[2]);
        // });
    }

    /**
     * Checks if the input item should be disabled based on its children. Returns true if it should be disabled,
     * returns false if it should be enabled (not disabled).
     * @param item 
     */
    public isDisabled(item: FilterModel): boolean {
        return !(item && item.children && item.children.length > 0);
    }

    /**
     * Emits an event back to the parent component indicating the selected project ID has been changed.
     */
    private selectedProjectChanged(): void {
        this.updateSelectedProjectId.emit(this.selectedProject.id);
    }

    /**
     * Emits an event back to the parent component indicating the selected service ID has been changed.
     */
    private selectedServiceChanged(): void {
        this.updateSelectedServiceId.emit(this.selectedService.id);
    }

    /**
     * Emits an event back to the parent component indicating the selected task ID has been changed.
     */
    private selectedTaskChanged(): void {
        this.updateSelectedTaskId.emit(this.selectedTask.id);
    }

    /**
     * Gets a list of filter model objects for the given projects.
     * @param projects 
     */
    private getFilterData(projects: IProjectDetails[] | ICBSProjectFinancial[]): FilterModel[] {
        const projectNodes: FilterModel[] = [];
        for (const project of projects) {
            const projectNode = new FilterModel(project.projectName, project.projectId);
            if (projectNode) {
                if ((project as IProjectDetails).services) {
                    projectNode.children = (project as IProjectDetails).services
                        .map((service) => {
                            const node = new FilterModel(service.serviceDescription + " " + service.serviceName, service.serviceId);
                            if (node && service.wbsL3s) {
                                node.children = service.wbsL3s.map((l3) => new FilterModel(l3.wbsL3Name, l3.wbsl3Id));
                            }
                            return node;
                        });
                } else if ((project as ICBSProjectFinancial).servicesFinancials) {
                    projectNode.children = (project as ICBSProjectFinancial).servicesFinancials
                        .map((service) => {
                            const node = new FilterModel(service.status + " " + service.serviceName, service.serviceId);
                            if (node && service.wbsL3sFinancials) {
                                node.children = service.wbsL3sFinancials.map((l3) => new FilterModel(l3.shortDesc, l3.wbsL3Id));
                            }
                            return node;
                        });
                }
                projectNodes.push(projectNode);
            }
        }
        return projectNodes;
    }


    /**
     * Gets a list of filter model objects for the given projects.
     * @param projects 
     */
    private getFilterDataV2(projects: IProjectDetailsV2[]): FilterModel[] {
        const projectNodes: FilterModel[] = [];
        for (const project of projects) {
            const projectNode = new FilterModel(project.name, project.id);
            if (projectNode) {
                if ((project as IProjectDetailsV2).services) {
                    projectNode.children = (project as IProjectDetailsV2).services
                        .map((service) => {
                            const node = new FilterModel(service.description + " " + service.name, service.id);
                            if (node && service.tasks) {
                                node.children = service.tasks.map((task) => new FilterModel(task.name, task.id));
                            }
                            return node;
                        });
                }
                projectNodes.push(projectNode);
            }
        }
        return projectNodes;
    }
}