<section id="edit-engagement-modal">
    <div role="dialog" aria-labelledby="ebsEditEngagementHeading" aria-modal="true">
        <div role="document">
            <form #editEngagementForm="ngForm" name="editEngagementForm"
                *ngIf="updatedEngagement && !viewResourceEnable">
                <section class="popUpModalDialog editEngagementForm">
                    <button *ngIf="deviceFactory.isMobile()" type="button" aria-label="Edit Engagement Dialog Close"
                        class="close mar-6 set-focus" id="accessibilityConstants.CloseUpdateButton"
                        (click)="closeModal()">
                        <span aria-hidden="true" class="icon icon-close"></span>
                    </button>
                    <div class="modal-header modal-noborder">
                        <h4 class="modal-title font-subtitle pull-left" id="ebsEditEngagementHeading">
                            Edit Engagement
                        </h4>
                        <button *ngIf="!deviceFactory.isMobile()" type="button"
                            aria-label="Edit Engagement Dialog Close" class="close set-focus"
                            id="accessibilityConstants.CloseUpdateButton"
                            (keydown)="moveFocusPrev($event, 'accessibilityConstants.Submit')" (click)="closeModal()">
                            <span aria-hidden="true" class="icon icon-close"></span>
                        </button>
                    </div>
                    <dm-loading [loadingText]="'Updating Engagement Details'" [isCustomText]="true" [errorText]=""
                        [showLoading]="true" *ngIf="isComponentLoading"></dm-loading>
                    <div class="modal-body" *ngIf="!isComponentLoading">
                        <section class="modal-body-container">
                            <section class="modal__input--text">
                                <label class="font-caption-alt" for="name">
                                    Name
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <input class="set-focus col-md-12" type="text" id="name" name="name" #name="ngModel"
                                    maxlength="40" placeholder="Name is required" [(ngModel)]="updatedEngagement.name"
                                    required [attr.aria-label]="updatedEngagement.name"
                                    [ngClass]="{'inp-error-border' :  name.invalid && !name.pristine}">
                                <label class="sr-only" id="msg">
                                    Name {{40 - updatedEngagement.name.length}} of 40 characters remaining
                                </label>
                                <div class="row">
                                    <p class="remaining-char font-caption col-sm-6 col-md-6 col-xs-7"
                                        aria-hidden="true">
                                        {{40 - updatedEngagement.name.length}}/40 characters remaining
                                    </p>
                                    <span role="alert" class="sr-only" aria-live="polite"
                                        *ngIf="name.invalid && !name.pristine && deviceFactory.isDesktop()">
                                        {{editEngagementErrorMessages.EngagementNameRequired}}
                                    </span>
                                    <span role="alert"
                                        class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                                        aria-live="polite" *ngIf="name.invalid && !name.pristine">
                                        {{editEngagementErrorMessages.EngagementNameRequired}}
                                    </span>
                                    <span role="alert"
                                        class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                                        aria-live="polite"
                                        *ngIf="updatedEngagement.name && updatedEngagement.name.length < 3">
                                        {{editEngagementErrorMessages.MinimumCharacterLength}}
                                    </span>
                                </div>
                            </section>
                            <section class="modal__input--text">
                                <label class="font-caption-alt" for="description">
                                    Description
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <textarea class="set-focus col-md-12" id="description" name="description"
                                    #description="ngModel" maxlength="255" [placeholder]="descriptionRequired"
                                    [(ngModel)]="updatedEngagement.description"
                                    [ngClass]="{'inp-error-border' : description.invalid}" required
                                    [attr.aria-label]=" updatedEngagement.description ? 'Description Required ' +  (255 - updatedEngagement.description.length) +  ' of 255 characters remaining.' + updatedEngagement.description : descriptionRequired "></textarea>
                                <div class="row">
                                    <p class="remaining-char font-caption col-sm-6 col-md-6 col-xs-7"
                                        aria-hidden="true">
                                        {{255 - updatedEngagement.description.length}}/255 characters remaining
                                    </p>
                                    <span role="alert" class="sr-only" aria-live="polite"
                                        *ngIf="description.invalid && deviceFactory.isDesktop()">
                                        {{editEngagementErrorMessages.DescriptionRequired}}
                                    </span>
                                    <span role="alert"
                                        class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                                        aria-live="polite" *ngIf="description.invalid">
                                        {{editEngagementErrorMessages.DescriptionRequired}}
                                    </span>
                                </div>
                            </section>
                            <!--===========================Non-mobile view========================================-->
                            <section class="col-md-12 col-sm-12 pad0 modal__input--calendar clearfix"
                                *ngIf="!deviceFactory.isMobile()">
                                <section class="disp-flex m-b-10">
                                    <section class="w-48 p-r-10">
                                        <label class="font-caption-alt" for="startDate">
                                            Start Date
                                        </label>
                                        <p class="input-group"
                                            [ngClass]="{'inp-error-border':isStartDateRequired || isAfterChildProjectStartDate}">
                                            <dm-datepicker [minDate]="minStartDate" [maxDate]="maxStartDate"
                                                [modelDate]="updatedEngagement.startDate"
                                                [disabled]="disableEBSStartDateEndDateUpdates"
                                                (onDateChange)="onStartDateChange($event)" ariaLabelButton="Start date"
                                                ariaLabelCalendar="Start date" placeholder="dd-mmm-yyyy"
                                                previosFousingElementId="description">
                                            </dm-datepicker>
                                        </p>

                                    </section>
                                    <section class="w-48 p-r-10">
                                        <label class="font-caption-alt" for="endDate">
                                            End Date
                                        </label>
                                        <p class="input-group"
                                            [ngClass]="{'inp-error-border':isEndDateRequired || showEndDateGreaterThanStartDateMessage || isBeforeChildProjectEndDate}">
                                            <dm-datepicker [minDate]="minEndDate" [maxDate]="maxEndDate"
                                                [disabled]="disableEBSStartDateEndDateUpdates"
                                                [modelDate]="updatedEngagement.endDate"
                                                (onDateChange)="onEndDateChange($event)" ariaLabelButton="End date"
                                                ariaLabelCalendar="End date" placeholder="dd-mmm-yyyy"
                                                previosFousingElementId="calenderBtn_Start date">
                                            </dm-datepicker>
                                        </p>
                                    </section>
                                    <section class="w-4 m-t-25" *ngIf="isResourceRequestsLoading">
                                        <dm-refresh *ngIf="isResourceRequestsLoading"
                                            [showSpinning]="isResourceRequestsLoading" [enableRefresh]="false"
                                            [loadingText]="'Loading resource request details'">
                                        </dm-refresh>
                                    </section>
                                </section>
                                <!-- All the error validation messages are shown here -->
                                <section>

                                    <!--Start Date and End Date required validations -->
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isStartDateRequired">
                                        {{editEngagementErrorMessages.StartDateRequired}}
                                    </span>

                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isEndDateRequired">
                                        {{editEngagementErrorMessages.EndDateIsRequired}}
                                    </span>

                                    <!--Start Date and End Date basic comparison validations -->
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite"
                                        *ngIf="showEndDateGreaterThanStartDateMessage && deviceFactory.isDesktop()">
                                        {{editEngagementErrorMessages.EndDateCannotBeEarlierThanStartDate}}
                                    </span>

                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isAfterChildProjectStartDate">
                                        {{getStartDateMustBeEarlierThanProjectMessage()}}
                                    </span>

                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite"
                                        *ngIf="showActualsStartDateConflictMessage">
                                        Engagement Start Date must be earlier than or same as the Actual Posted Date
                                        {{updatedEngagement.minDate | dmDisplayDateOrDashPipe}}.
                                    </span>

                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isBeforeChildProjectEndDate">
                                        {{getEndDateCannotBeEarlierThanProjectMessage()}}
                                    </span>

                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="showActualsEndDateConflictMessage">
                                        Engagement End date should be later than actuals posted date
                                        {{updatedEngagement.maxDate | dmDisplayDateOrDashPipe}}.
                                    </span>
                                </section>
                                <div class="clearfix"></div>

                                <section class="font-caption-alt pubsec_errortext-wrapper dm-tile--p-8 dm-tile--m-t-6"
                                    *ngIf="isDisabledPubSecCode">
                                    <span class="pubsec_errortext-icon icon icon-info"></span>
                                    <span class="pubsec_errortext">
                                        Start and End Date cannot be changed for {{pubSecCountry}} Pubsec Engagements.
                                    </span>
                                </section>

                                <section class="font-label pad-b-12 p-t-4" role="alert" aria-atomic="true"
                                    aria-live="polite" *ngIf="showConflictingResources">
                                    <span class="font-caption-alt inp-validation errorMsg">
                                        Cannot change date due to resourcing conflict ({{noOfConflictResources}}
                                        {{noOfConflictResources > 1 ? "Resources" : "Resource"}}).
                                    </span>
                                    <button class="view-resource-button" type="button" (click)="toggleResources()"
                                        aria-label="View resource dialogue.">
                                        View resources
                                    </button>
                                </section>

                                <section class="col-md-12 col-sm-12 pad-12 radio__section"
                                    *ngIf="showCascadeRadioButtons">
                                    <section class="col-md-12 col-sm-12 pad0 modal__input--radio">
                                        <section class="pad-b-12">
                                            <span class="input__label--text input__label--d-b font-caption mr-b-10">
                                                Apply date changes to
                                            </span>
                                        </section>
                                        <section class="col-md-6 col-sm-6 rightpad pad0">
                                            <label for="checkEngagement" focus="true" class="disp-in-flex">
                                                <span class="input-radio input-radio--focus">
                                                    <input id="checkEngagement" type="radio" name="engagementEdit"
                                                        [disabled]="disableEngagementOnlyOption"
                                                        [(ngModel)]="updateLevel"
                                                        [value]="EnumUpdateLevel.engagementLevel">
                                                    <span class="slider round" aria-hidden="true"></span>
                                                </span>
                                                <span class="radiobtn-label font-caption-alt pad-l-8">
                                                    Engagement Only
                                                </span>
                                            </label>
                                        </section>
                                        <section class="col-md-6 col-sm-6 leftpad pad0">
                                            <label for="checkAll" focus="true" class="disp-in-flex">
                                                <span class="input-radio input-radio--focus">
                                                    <input id="checkAll" type="radio" name="srCrossCharge"
                                                        (change)="hideErrorMessages()" [(ngModel)]="updateLevel"
                                                        [value]="EnumUpdateLevel.allLowerLevels">
                                                    <span class="slider round" aria-hidden="true"></span>
                                                </span>
                                                <span class="radiobtn-label font-caption-alt pad-l-8">
                                                    Engagement, Projects,
                                                    <br>
                                                    {{!updatedEngagement.isInternalEngagment ? "Services, " : ""}} and
                                                    Tasks
                                                </span>
                                            </label>
                                        </section>
                                        <div class="clearfix"></div>
                                    </section>
                                </section>

                            </section>

                            <!--========================================== Mobile Version ===================================-->
                            <section
                                class="col-xs-12 pad-12 pad-b-0 mar-b-12 modal__input--calendar modal__input--calendar-mobile calendar-section--wrapper"
                                *ngIf="deviceFactory.isMobile()">
                                <section class="col-xs-12 editCal pad0">
                                    <label class="font-caption-alt" for="startDate">
                                        Start Date
                                    </label>
                                    <p class="input-group"
                                        [ngClass]="{'inp-error-border':isStartDateRequired || isAfterChildProjectStartDate}">
                                        <dm-datepicker [minDate]="minStartDate" [maxDate]="maxStartDate"
                                            [disabled]="disableEBSStartDateEndDateUpdates"
                                            [modelDate]="updatedEngagement.endDate"
                                            (onDateChange)="onStartDateChange($event)" ariaLabelButton="Start date"
                                            ariaLabelCalendar="Start date" placeholder="dd-mmm-yyyy"
                                            previosFousingElementId="description"></dm-datepicker>
                                    </p>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isStartDateRequired">
                                        {{editEngagementErrorMessages.StartDateRequired}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isAfterChildProjectStartDate">
                                        {{getStartDateMustBeEarlierThanProjectMessage()}}
                                    </span>
                                </section>
                                <section class="col-xs-12 editCal p-t-12 pad0 pad-b-14">
                                    <label class="font-caption-alt" for="endDate">
                                        End Date
                                    </label>
                                    <p class="input-group"
                                        [ngClass]="{'inp-error-border':isEndDateRequired || showEndDateGreaterThanStartDateMessage || isBeforeChildProjectEndDate}">
                                        <dm-datepicker [minDate]="minEndDate" [maxDate]="maxEndDate"
                                            [disabled]="disableEBSStartDateEndDateUpdates"
                                            [modelDate]="updatedEngagement.endDate"
                                            (onDateChange)="onEndDateChange($event)" ariaLabelButton="End date"
                                            ariaLabelCalendar="End date" placeholder="dd-mmm-yyyy"
                                            previosFousingElementId="calenderBtn_Start date">
                                        </dm-datepicker>
                                    </p>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isEndDateRequired">
                                        {{editEngagementErrorMessages.EndDateIsRequired}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite"
                                        *ngIf="showEndDateGreaterThanStartDateMessage">
                                        {{editEngagementErrorMessages.EndDateCannotBeEarlierThanStartDate}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isBeforeChildProjectEndDate">
                                        {{getEndDateCannotBeEarlierThanProjectMessage}}
                                    </span>
                                </section>
                                <section class="pubsec_errortext-wrapper--mobile" *ngIf="isDisabledPubSecCode">
                                    <span class="icon icon-info"></span>
                                    Start and End Date cannot be changed for {{pubSecCountry}} Pubsec Engagements.
                                </section>
                                <section class="col-md-12 col-sm-12 pad0 clearfix modal__input--radio">
                                    <section class="pad-b-14">
                                        <span class="input__label--text input__label--d-b font-caption mr-b-10">
                                            Apply date changes to
                                        </span>
                                    </section>
                                    <section class="pad-b-14 pad0">
                                        <section class="col-md-6 col-sm-6 pad-b-14 pad0">
                                            <label for="checkEngagement" focus="true" class="disp-in-flex">
                                                <span class="input-radio input-radio--focus">
                                                    <input id="checkEngagement" type="radio" name="engagementEdit"
                                                        [(ngModel)]="updateLevel"
                                                        [disabled]="disableEngagementOnlyOption"
                                                        [value]="EnumUpdateLevel.engagementLevel">
                                                    <span class="slider round" aria-hidden="true"></span>
                                                </span>
                                                <span class="radiobtn-label font-caption-alt pad-l-8">
                                                    Engagement Only
                                                </span>
                                            </label>
                                        </section>
                                        <section class="col-md-6 col-sm-6 pad0">
                                            <label for="checkAll" focus="true" class="disp-in-flex">
                                                <span class="input-radio input-radio--focus">
                                                    <input id="checkAll" type="radio" name="engagementEdit"
                                                        [(ngModel)]="updateLevel"
                                                        [value]="EnumUpdateLevel.allLowerLevels">
                                                    <span class="slider round" aria-hidden="true"></span>
                                                </span>
                                                <span class="radiobtn-label font-caption-alt pad-l-8">
                                                    Engagement, Projects,
                                                    <br>
                                                    {{!updatedEngagement.isInternalEngagment ? "Services, " : ""}} and
                                                    Tasks
                                                </span>
                                            </label>
                                        </section>
                                        <div class="clearfix"></div>
                                    </section>
                                    <section class="font-label pad-b-12 p-t-4" role="alert" aria-atomic="true"
                                        aria-live="polite" *ngIf="showConflictingResources">
                                        <span class="font-caption-alt inp-validation errorMsg">
                                            {{editEngagementErrorMessages.CannotChangeTheDateDueToResourcingConflict}}
                                            ({{noOfConflictResources}}
                                            {{noOfConflictResources > 1 ? "Resources" : "Resource"}}).
                                        </span>
                                        <button class="view-resource-button" type="button" (click)="toggleResources()"
                                            aria-label="View resource dialog.">
                                            View resources
                                        </button>
                                    </section>
                                </section>
                            </section>
                            <dm-type-ahead [typeAheadId]="typeAheadId" [btnSearchAriaLabelText]="taSearchAriaLblTxt"
                                [typeAheadLabelText]="typeAheadLabelText" [btnCancelAriaLabelText]="taCancelAriaLblTxt"
                                [typeAheadUserValidationRequiredMessage]="taRequiredMsg" [disabled]="disablePPJMUpdate"
                                [selectedUser]="selectedUser" [isRequired]="true" [isMultiselect]="false"
                                [fteOnly]="true" [modelValue]="selectedUser" [isMandatory]="true"
                                (selectedUserUpdated)="onSelectUser($event)" (onUserCleared)="onUserCleared($event)">
                            </dm-type-ahead>
                            <span role="alert" class="font-caption-alt inp-validation errorMsg dm-p-b-5" aria-atomic="true"
                                aria-live="polite" *ngIf="showNonfteValidationMessage">
                                {{editEngagementErrorMessages.OnlyFteCanFill}}
                            </span>
                            <dm-type-ahead (multiSelectedUsersUpdated)="onAdditionalManagersUpdate($event)"
                                [typeAheadId]="additionalTypeAheadId"
                                [btnSearchAriaLabelText]="additionalSearchAriaLblTxt"
                                [typeAheadLabelText]="additionalTypeAheadLabelText" [isMandatory]="false"
                                [btnCancelAriaLabelText]="additionalCancelAriaLblTxt" [disabled]="disableAPPJMUpdate"
                                [selectedUsers]="selectedUsers" [isRequired]="false" [isMultiselect]="true"
                                [fteOnly]="false">
                            </dm-type-ahead>
                        </section>
                    </div>
                </section>
                <div class="modal-footer modal-noborder" *ngIf="!deviceFactory.isMobile() && !isComponentLoading">
                    <button type="button" class="fxp-btn fxp-btn--secondary"
                        id="accessibilityConstants.Cancel" (click)="closeModal()"
                        (keydown)="moveFocusNext($event, 'accessibilityConstants.Submit')">
                        Cancel
                    </button>
                    <button class="fxp-btn fxp-btn--primary" id="accessibilityConstants.Submit"
                        type="button" [disabled]="saveButtonDisabled(editEngagementForm.invalid)"
                        (click)="updateEngagementDetails()"
                        (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseUpdateButton')">
                        Save
                    </button>
                </div>
                <div class="modal-footer" *ngIf="deviceFactory.isMobile()">
                    <button class="fxp-btn fxp-btn--primary"
                        id="accessibilityConstants.Submit" type="button" (click)="updateEngagementDetails()"
                        [disabled]="saveButtonDisabled(editEngagementForm.invalid)">
                        Save
                    </button>
                    <button type="button" class="fxp-btn fxp-btn--secondary"
                        (click)="closeModal()">
                        Cancel
                    </button>
                </div>
            </form>

            <section class="resource-details-list" *ngIf="viewResourceEnable">
                <div class="popUpModalDialog editEngagementForm">
                    <section class="modal-header modal-noborder">
                        <a class="dm-link font-caption-alt cursor-pointer" type="button"
                            (click)="toggleResources()">
                            <i class="icon icon-Back"></i>
                            <span class="dm-tile--p-r-10"> Return to Edit Engagement</span>
                        </a>
                    </section>
                    <div class="modal-body resources-section">
                        <dm-wbs-resource-requests [startDate]="updatedEngagement.startDate"
                            [endDate]="updatedEngagement.endDate" [wbsId]="selectedEngagement.id">
                        </dm-wbs-resource-requests>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>