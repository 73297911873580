import { Action } from "@ngrx/store";
import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";

// 1. action types
export enum ChangeRequestV2ActionTypes {
    LOAD_ENGAGEMENT_CHANGE_REQUESTSV2 = "[EngagementChangeRequestsV2] Load",
    LOAD_ENGAGEMENT_CHANGE_REQUESTSV2_SUCCESS = "[EngagementChangeRequestsV2] Load Success",
    LOAD_ENGAGEMENT_CHANGE_REQUESTSV2_FAIL = "[EngagementChangeRequestsV2] Load Fail",
    INVALIDATE_ENGAGEMENT_CHANGE_REQUESTSV2 = "[EngagementChangeRequestsV2] Invalidate"
}

// 2. action creators
export class LoadEngagementChangeRequestsV2 implements Action {
    public readonly type = ChangeRequestV2ActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTSV2;
    public constructor(public engagementId: string) { }
}
export class LoadEngagementChangeRequestsV2Success implements Action {
    public readonly type = ChangeRequestV2ActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTSV2_SUCCESS;
    public constructor(public engagementId: string, public changeRequests: IChangeRequest[]) { }
}

export class LoadEngagementChangeRequestsV2Fail implements Action {
    public readonly type = ChangeRequestV2ActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTSV2_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateEngagementChangeRequestsV2 implements Action {
    public readonly type = ChangeRequestV2ActionTypes.INVALIDATE_ENGAGEMENT_CHANGE_REQUESTSV2;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type ChangeRequestActions =
    | LoadEngagementChangeRequestsV2
    | LoadEngagementChangeRequestsV2Success
    | LoadEngagementChangeRequestsV2Fail
    | InvalidateEngagementChangeRequestsV2;