<section class="toggle-section">
  <div class="toggle-section__header set-focus dm-flex-align-center" role="button" tabindex="0"
    (click)="toggleSection()" (keyup.enter)="toggleSection()" (keyup.Space)="toggleSection()"
    [attr.aria-expanded]="showSection">
    <span class="pull-left"><i class="icon icon-chevron-down" aria-hidden="true"
        [ngClass]="{'icon-chevron-up': !showSection, 'icon-chevron-down': showSection}">
      </i><span class="toggle-section__header-title">{{title}}</span>
      <span class="toggle-section__header-title" *ngIf="subTitle && !showSection">- {{subTitle}}</span></span>
    <span class="margin-left-auto">
      <ng-content select="[rightSideContent]"></ng-content>
    </span>
  </div>
  <ng-content *ngIf="showSection"></ng-content>
</section>