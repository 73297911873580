<article class="dm-tile" id="cost-consume">
    <section class="col-md-12 dm-tile__nopad">
        <h3 class="dm-tile__large-tile-title">COST CONSUMED OF ESTIMATE AT COMPLETE (EAC)</h3>

    </section>
    <section class="col-md-12 dm-tile__nopad auto-height-adjust">
        <div class="col-md-8 col-sm-8 col-xs-12 dm-tile__nopad dm-tile--p-r-10">
            <div class="col-md-12 col-sm-12 col-xs-12 cceac--graph-wrapper">
                <dm-cceac [isInternalEngagement]="isInternalEngagement" [values]="cceacValues"></dm-cceac>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 cceac--reference">
                <div class="col-lg-5 col-md-3 col-sm-5 col-xs-4">
                    <p class="font-caption-alt"><span class="cceac--reference--consumed"></span> Consumed</p>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-8 dm-tile__nopad">
                    <p class="font-caption-alt"><span class="cceac--reference--eac"></span> Estimate at Complete</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 font-caption-alt">
            <ul class="cceac--reference-list">
                <li>
                    <div>
                        <p class="magnifying-glass cceac--reference-list-item"></p>
                        <p class="cceac--reference-list-text">Current Financial Plan</p>
                    </div>
                </li>
                <li>
                    <div>
                        <p class="diamond-narrow cceac--reference-list-item"></p>
                        <p class="cceac--reference-list-text">Delivery Baseline</p>
                    </div>
                </li>
                <li>
                    <div>
                        <p class="talkbubble cceac--reference-list-item"></p>
                        <p class="cceac--reference-list-text">Contract Baseline</p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</article>