import { Component, Inject, Input, Output, forwardRef, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { v4 as uuid } from "uuid";
import { Store } from "@ngrx/store";

import { Components, AccessibilityConstants, LogEventConstants, SourceConstants } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IActionNoteType, IApiResponseMessage, IAssignment, IReasonCodeProblemType, IResourceComittedHoursResponse } from "../../staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { IClinSlinApiResponse } from "../../../../common/services/contracts/project.service.contracts";
import { IEngineRuleVerificationResponse } from "../../staffing-command-bar-common/services/contracts/rule-engine.service.contract";
import { IFinancialProjectTypeName } from "../../../../common/services/contracts/projectservice-functions.contract";
import { IState } from "../../../../store/reducers";
import { ProjectService } from "../../../../common/services/project.service";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StaffingActionConstants } from "../../staffing-command-bar-common/services/staffing-action.constants";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { DmError } from "../../../../common/error.constants";


declare let require;

@Component({
    selector: "scb-rrm-modal",
    templateUrl: "./rm-modal.component.html",
    styleUrls: ["./rm-modal.component.scss"]
})
export class RmModalComponent extends DmModalAbstract {
    @Input() public assignmentList: IAssignment[];
    @Input() public wbsId: string;
    @Input() public isModalComponent?: boolean = true;
    @Input() public resourceId: string;
    @Output() public hideTabComponent: EventEmitter<{}> = new EventEmitter();

    public selectedRequestReason = "select";
    public validationResults: IEngineRuleVerificationResponse;
    public rmAction: IActionNoteType[] = [];
    public defaultAction: IActionNoteType = {
        Id: 0,
        Name: "Select",
        Category: "default"
    };
    public errorSummaryText: string;
    public isLoading: boolean = false;
    public isSubmitDisabled: boolean = false;
    public lblMessage = "";
    public lblRequestedHoursValidation = "";
    public loadingText: string = "Loading";
    public requestComment = "";
    public requestReasons: IReasonCodeProblemType[] = [];
    public requestedHours: number = 0;
    public selectedAction: IActionNoteType = this.defaultAction;
    public showLoading: boolean = true;
    public accessibilityConstants = AccessibilityConstants;
    public rmModalErrorMessages = DmError.Staffing;

    private commitedHoursResponse: IResourceComittedHoursResponse;
    private clinSlinData: IClinSlinApiResponse;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(StaffingActionService) private staffingActionService: StaffingActionService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(RuleEngineService) private ruleEngineService: RuleEngineService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
    ) {
        super(activeModal, dmLogger, Components.StaffingCommandBarRoleMaintenanceModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        if (!this.assignmentList || this.assignmentList.length <= 0) {
            this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataMissing, "error", false, uuid());
            this.closeModal();
        } else if (this.assignmentList[0].isInternal && this.checkIfAutoCommitRole(this.assignmentList)) {
            this.fxpMessageService.addMessage(DmError.Staffing.RoleMaintenancePerformanceMessage, "error", false, uuid());
            this.closeModal();
        } else {
            this.isLoading = true;
            const rulePath = require("../../staffing-command-bar-common/Rules/roleMaintenanceRules.json");
            this.ruleEngineService.addRules(rulePath);
            const loadRmPromise: Promise<void> = this.loadRMActions().then((result: IActionNoteType[]) => {
                this.isLoading = false;
                this.rmAction = Object.assign([], result);
                this.rmAction = this.rmAction.filter((action) => action.Name.toLowerCase() !== "requested a csa");
                this.rmAction = this.rmAction.filter((action) => action.Name.toLowerCase() !== "request for swap");
                this.requestedHours = this.assignmentList[0].resourceInformation.RequestedDuration;
                if (!this.shouldRequestForCommitActionBeEnabled(this.assignmentList)) {
                    this.rmAction = this.rmAction.filter((action) => action.Name.toLowerCase() !== "request for commit");
                }
            });
            const runRulesPromise: Promise<void> = this.ruleEngineService.runRules(this.assignmentList)
                .then((result: IEngineRuleVerificationResponse) => {
                    this.validationResults = result;
                    if (!this.validationResults.isActionable) {
                        return Promise.reject(result);
                    }
                }).catch((error) => {
                    this.isLoading = false;
                    const messageDisplayed = (this.validationResults && this.validationResults.validationMessage) ? this.validationResults.validationMessage : DmError.Staffing.ResourceRequestIsNotInAStateForRoleMaintenance;
                    this.logError(SourceConstants.Method.NgOnInit, error, messageDisplayed, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.fxpMessageService.addMessage(messageDisplayed, "error", true);
                    this.closeModal();
                });
            Promise.all([loadRmPromise, runRulesPromise]).then(() => {
                this.endComponentLoad();
            });
        }
    }

    /**
     * Close the Modal Dialog and nullfy the assingment List
     *
     * @private
     * @memberof RmModalComponent
     */
    public closeModal(): void {
        this.assignmentList = [];
        if (this.isModalComponent) {
            super.closeModal();
            const dropDownId = "Role Actions Dropdown " + this.resourceId;
            this.sharedFunctionsService.focus(dropDownId, true);
        } else {
            this.hideTabComponent.emit();
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CancelRequest);
        }
    }

    /**
     * Submit request to cancel request for assignment
     */
    public submit(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.SubmitRequestRoleMaintenance);
        this.lblMessage = "";
        this.lblRequestedHoursValidation = "";
        if (!this.selectedAction || this.selectedAction.Name.toLowerCase() === "select") {
            this.lblMessage = DmError.Staffing.ActionisRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-rrm-action-ddl", true);
            });
            return;
        }

        if (!this.selectedRequestReason || this.selectedRequestReason.toLowerCase() === "select") {
            this.lblMessage = DmError.Staffing.ReasonRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-rrm-reason-ddl", true);
            });
            return;
        }
        if (this.selectedRequestReason.toLowerCase() === "other" && !this.requestComment) {
            this.lblMessage = DmError.Staffing.CommentsAreRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-rrm-reason", true);
            });
            return;
        }
        if (this.selectedRequestReason.toLowerCase() === "additional funds received" && (!this.requestedHours || this.requestedHours <= 0)) {
            this.lblMessage = DmError.Staffing.RequestedHoursCannotBeZero;
            this.sharedFunctionsService.delayExecution(2500).then(() => {
                this.sharedFunctionsService.focus("additionalHours", true);
            });
            return;
        }
        if (this.selectedRequestReason.toLowerCase() === "additional funds received") {
            this.isLoading = true;
            this.showLoading = true;
            this.loadingText = "Validating";
            this.commitedHoursResponse = null;
            this.clinSlinData = null;
            Promise.all([
                this.getResourceCommitedHours(),
                this.getClinSlinData()
            ]).then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.RequestRoleMaintenanceSuccess);
                const resourceItemId = this.assignmentList[0].resourceInformation.DemandResourceRequest.ResourceItemId;
                const fundingDetails = this.clinSlinData.fundingDetails.filter((obj) => obj.resourceItemId === resourceItemId.toString());
                if (this.commitedHoursResponse && this.commitedHoursResponse.requestedHoursSum && fundingDetails.length > 0) {
                    const commitedHours = this.commitedHoursResponse.requestedHoursSum.ResourceItemBasedCommittedHours;
                    const fundedHours = fundingDetails.map((details) => details.fundedQuantity).reduce((a, b) => a + b);
                    if (this.requestedHours > (fundedHours - commitedHours)) {
                        this.lblRequestedHoursValidation = DmError.Staffing.FundedHoursRemaining + (fundedHours - commitedHours) + "hrs only";
                    } else {
                        this.submitForRoleMaintainenceAction();
                    }
                }
                this.isLoading = false;
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.Submit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isLoading = false;
            });
        } else {
            this.submitForRoleMaintainenceAction();
        }
    }

    /**
     * Load reason as per selected action
     */
    public loadReasonForSelectedAction(actionSelected: IActionNoteType): void {
        this.isSubmitDisabled = false;
        this.isLoading = true;
        if (actionSelected.Name.toLowerCase() === "select") {
            this.isSubmitDisabled = true;
            this.isLoading = false;
            return;
        }
        if (actionSelected.Name.toLowerCase() === "request for commit" && this.assignmentList.length > 1) {
            this.fxpMessageService.addMessage(DmError.Staffing.CommitOperationOnMultipleAssignments, "warning", true);
            this.isSubmitDisabled = true;
            this.isLoading = false;
            return;
        }
        let rulePath;
        switch (actionSelected.Name.toLowerCase()) {
            case "request for swap":
                rulePath = require("../../staffing-command-bar-common/Rules/requestForSwapRules.json");
                break;
            case "request for attribute changes":
                rulePath = require("../../staffing-command-bar-common/Rules/requestForAttributeChangesRules.json");
                break;
            case "request for commit":
                rulePath = require("../../staffing-command-bar-common/Rules/roleMaintenanceRules.json");
                break;
        }
        this.ruleEngineService.addRules(rulePath);
        this.ruleEngineService.runRules(this.assignmentList)
            .then((result: IEngineRuleVerificationResponse) => {
                if (result.isActionable) {
                    this.staffingActionService.getReasonsForRequestMaintenance(Number(this.selectedAction.Id))
                        .then((data) => {
                            this.requestReasons = data;
                            this.isLoading = false;
                        });
                } else {
                    this.isSubmitDisabled = true;
                    this.isLoading = false;
                    this.fxpMessageService.addMessage(result.validationMessage, "error", true);
                }
            }).catch((result: IEngineRuleVerificationResponse) => {
                this.isSubmitDisabled = true;
                this.isLoading = false;
                this.fxpMessageService.addMessage(result.validationMessage, "error", true);
            });
    }


    /**
     * Get the resource commited hours
     */
    private getResourceCommitedHours(): Promise<any> {
        return this.staffingActionService.getResourceComittedHours(this.assignmentList[0].demandDetails.demandId, undefined, this.assignmentList[0].assignmentId.toString())
            .then((data: IResourceComittedHoursResponse) => {
                this.commitedHoursResponse = data;
            }).catch((error) => {
                this.fxpMessageService.addMessage(DmError.Staffing.UnableToRetrieveCommittedHours, "error", true);
                this.logError(SourceConstants.Method.GetResourceCommitedHours, error, DmError.Staffing.UnableToRetrieveCommittedHours, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }

    /**
     * Get the clinslin data
     */
    private getClinSlinData(): Promise<any> {
        const engagementId = this.wbsId.substring(0, 12);
        return this.projectService.getClinSlinForEngagementId(engagementId)
            .then((data: IClinSlinApiResponse) => {
                this.clinSlinData = data;
            }).catch((error) => {
                this.fxpMessageService.addMessage(DmError.Staffing.UnableToRetrieveFundedHours, "error", true);
                this.logError(SourceConstants.Method.GetClinSlinData, error, DmError.Staffing.UnableToRetrieveFundedHours, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }


    /**
     * Load actions for which request can make
     */
    private loadRMActions(): Promise<IActionNoteType[]> {
        return this.staffingActionService.getActionsForRequestMaintenance();
    }

    /**
     * Submit data for Role Maintainance action
     */
    private submitForRoleMaintainenceAction(): void {
        this.isLoading = true;
        this.showLoading = true;
        this.loadingText = "Role maintenance";
        this.lblMessage = "";
        let responseMessage: IApiResponseMessage;
        if (this.selectedRequestReason.toLowerCase() === "additional funds received") {
            this.requestComment = (this.requestComment && this.requestComment.length) ? " Total hours requested: " + this.requestedHours.toString() + "<br/>" + this.requestComment
                : " Total hours requested: " + this.requestedHours.toString();
        }
        const response = this.staffingActionService.rmActionOnAssignments(this.selectedAction.Name, this.validationResults.assignmentIds, this.selectedRequestReason, this.requestComment);
        response
            .then((r) => {
                responseMessage = this.staffingActionService.onSuccessAPICall(StaffingActionConstants.ActionRoleMaintenenace, r);
                const projectIds = this.assignmentList.map((assignment) => assignment.projectIdSelected);
                this.staffingService.invalidateProjectStaffingData(projectIds);
                this.isLoading = false;
                this.showLoading = true;
                this.staffingActionService.displayMessages(responseMessage, this.validationResults.validationMessage);
                this.closeModal();
            })
            .catch((error) => {
                this.staffingActionService.onErrorAPICall(StaffingActionConstants.ActionRoleMaintenenace, error);
                this.errorSummaryText = DmError.Staffing.ErrorWhileSubmittingRequest;
                this.showLoading = false;
                this.logError(SourceConstants.Method.SubmitForRoleMaintainenceAction, error, this.errorSummaryText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.closeModal();
            });
    }

    /**
     * Check if Request for Commit action needs to be displayed in the role maintenance actions
     * @param assignmentList 
     */
    private shouldRequestForCommitActionBeEnabled(assignmentList: IAssignment[]): boolean {
        const contractType = (this.assignmentList[0].grmProjectRequest && this.assignmentList[0].grmProjectRequest.AdditionalProperties) ? this.assignmentList[0].grmProjectRequest.AdditionalProperties.ContractType : undefined;
        return assignmentList[0].showUSPubsecRMActions && assignmentList[0].resourceInformation.RequestBookingTypeId === 2 &&
            contractType !== "FF" &&
            assignmentList[0].grmProjectRequest.FundingTypeEnum !== "NonBillable-Customer" &&
            assignmentList[0].demandDetails.isDemandBillable;
    }

    /**
     * Check if Role Selected for maintainenance is autocommit or not
     * @param assignmentList 
     */
    private checkIfAutoCommitRole(assignmentList: IAssignment[]): boolean {
        const configTypesWithRoles = this.configManagerService.getValue<IFinancialProjectTypeName[]>("rolesAssociationWithType");
        const autoCommitRoles = configTypesWithRoles.filter((type: IFinancialProjectTypeName) => type.EngagementCreationCode.toString() === assignmentList[0].internalEngagementType);
        if (autoCommitRoles.length > 0) {
            const autoCommitRoleNames = autoCommitRoles[0].RolesValues.map((roleInfo) => roleInfo.roleName.replace("-", "").toLowerCase());
            for (const assignment of assignmentList) {
                if (autoCommitRoleNames.indexOf(assignment.resourceInformation.Role.replace("-", "").toLowerCase()) > -1) {
                    return true;
                }
            }
        }
        return false;
    }

}
