import { Component, forwardRef, Inject, Input, Output, EventEmitter } from "@angular/core";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Components, AccessibilityConstants, LogEventConstants, SourceConstants } from "../../../../common/application.constants";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IApiResponseMessage, IAssignment, IReasonCodeProblemType, IActionResponse } from "../../staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { IEngineRuleVerificationResponse } from "../../staffing-command-bar-common/services/contracts/rule-engine.service.contract";
import { IState } from "../../../../store/reducers";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StaffingActionConstants } from "../../staffing-command-bar-common/services/staffing-action.constants";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { v4 as uuid } from "uuid";
import { LaborManagementService } from "../../../../common/services/labor-management.service";

import moment from "moment";
import { DmError } from "../../../../common/error.constants";

declare let require;

@Component({
    selector: "scb-truncate-modal",
    templateUrl: "./truncate-modal.component.html",
    styleUrls: ["./truncate-modal.component.scss"]
})
export class TruncateModalComponent extends DmModalAbstract {
    @Input() public assignmentList: IAssignment[];
    @Input() public requestEndDate: Date;
    @Input() public wbsId: string;
    @Input() public isModalComponent?: boolean = true;
    @Input() public resourceId: string;
    @Output() public hideTabComponent: EventEmitter<{}> = new EventEmitter();

    public filterEndDatePlaceholder: string;
    public isLoading: boolean = false;
    public showLoading: boolean = true;
    public loadingText: string = "Loading";
    public errorSummaryText: string;
    public truncateDate: Date;
    public truncateReasons: IReasonCodeProblemType[] = [];
    public selectedTruncateReason: string = "select";
    public truncateComment: string = "";
    public lblMessage: string = "";
    public truncateMinDate: Date;
    public truncateMaxDate: Date;
    public lastLoggedLaborDate: Date;
    public isMinMaxTruncateDateLoaded: boolean = false;
    public accessibilityConstants = AccessibilityConstants;
    public truncateErrorMessages = DmError.Staffing;
    private validationResults: IEngineRuleVerificationResponse;
    private assignment: IAssignment;
    private laborDeliveryDate: Date;
    private scheduleStartDate: Date;
    private scheduleEndDate: Date;
    private resourceLoggedHours: boolean = false;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(StaffingActionService) private staffingActionService: StaffingActionService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(RuleEngineService) private ruleEngineService: RuleEngineService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(LaborManagementService) private laborManagementService: LaborManagementService,
        @Inject(DmDisplayDateOrDashPipe) private dmDisplayDateOrDashPipe: DmDisplayDateOrDashPipe,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService
    ) {
        super(activeModal, dmLogger, Components.StaffingCommandBarTruncateModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        // cannot truncate if no or multiple assigment(s) selected
        if (!this.assignmentList || this.assignmentList.length !== 1) {
            if (this.assignmentList.length <= 0) {
                this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataMissing, "error", false, uuid());
            } else {
                this.fxpMessageService.addMessage(DmError.Staffing.TruncateOperationsPerformanceMessaage, "error", false, uuid());
            }
            this.closeModal();
            return;
        }
        this.configManagerService.initialize();
        this.showLoading = true;
        this.isLoading = true;
        this.loadingText = "Loading details";
        this.errorSummaryText = DmError.Staffing.NotAbleToLoadTruncateDetails;
        this.filterEndDatePlaceholder = "Select date";
        this.assignment = this.assignmentList[0];
        this.scheduleStartDate = this.assignment.assignmentStartDate;
        this.scheduleEndDate = this.assignment.assignmentEndDate;

        // Check for Input data
        if (!this.assignment && !this.assignment.assignmentStatus) {
            this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataIsIncomplete, "error", false, uuid());
            this.closeModal();
        } else {
            if (this.assignment.assignmentStatus === "Committed" && !(this.requestEndDate && this.scheduleEndDate)) {
                this.fxpMessageService.addMessage(DmError.Staffing.RequestDateIsRequired, "error", false, uuid());
                this.closeModal();
            } else if (this.assignment.assignmentStatus === "Committed" && !(this.scheduleStartDate)) {
                this.fxpMessageService.addMessage(DmError.Staffing.ScheduleStartDateRequired, "error", false, uuid());
                this.closeModal();
            }
        }

        const rulePath = require("../../staffing-command-bar-common/Rules/truncateRules.json");
        this.ruleEngineService.addRules(rulePath);
        this.ruleEngineService.runRules(this.assignmentList)
            .then((result: IEngineRuleVerificationResponse) => {
                this.validationResults = result;
                if (!this.validationResults.isActionable) {
                    return Promise.reject(result);
                }
                return Promise.all([
                    this.getLaborDeliveryDate(this.assignment.assignmentId, this.assignment.resourceInformation.AssignedResource),
                    this.loadTruncateReasonCode()
                ]);
            }).then(() => {
                this.truncateMinDate = this.getMinTruncateDate();
                this.truncateMaxDate = this.getMaxTruncateDate();
                this.truncateDate = this.truncateMinDate;
                this.isMinMaxTruncateDateLoaded = true;
                this.isLoading = false;
                if (this.resourceLoggedHours && (moment(this.truncateMinDate) < moment((this.scheduleEndDate)))) {
                    this.fxpMessageService.addMessage(DmError.Staffing.ResourceHasLoggedHoursInChronos
                        + this.dmDisplayDateOrDashPipe.transform(this.truncateMinDate)
                        + " to "
                        + this.dmDisplayDateOrDashPipe.transform(this.truncateMaxDate), "warning", false, uuid());
                }
            }).catch((result: IEngineRuleVerificationResponse) => {
                this.isLoading = false;
                this.showLoading = false;
                this.fxpMessageService.addMessage(result.validationMessage, "error", true);
                this.closeModal();
            });
    }

    /**
     * Close the Modal Dialog and nullfy the assingment List
     *
     * @private
     * @memberof TruncateModalComponent
     */
    public closeModal(): void {
        this.assignmentList = [];
        if (this.isModalComponent) {
            super.closeModal();
            const dropDownId = "Role Actions Dropdown " + this.resourceId;
            this.sharedFunctionsService.focus(dropDownId, true);
        } else {
            this.hideTabComponent.emit();
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CancelRequest);
        }
    }

    /**
     * function to set truncate date to the date picked on the calendar
     */
    public setTruncateDate(selectedDate: Date): void {
        this.truncateDate = selectedDate;
    }

    /**
     * Submit request to truncate request for assignment
     */
    public submit(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.SubmitTruncate);
        let responseMessage: IApiResponseMessage;
        if (!this.selectedTruncateReason || this.selectedTruncateReason.toLowerCase() === "select") {
            this.lblMessage = DmError.Staffing.ReasonRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-truncate-reason-ddl", true);
            });
            return;
        }

        if (!this.truncateDate && this.selectedTruncateReason.toLowerCase() === "other" && !this.truncateComment) {
            this.lblMessage = DmError.Staffing.ScheduleEndDateAndCommentRequired;
            this.sharedFunctionsService.delayExecution(3000).then(() => {
                this.sharedFunctionsService.focus("calenderBtn_End date", true);
            });
            return;
        }

        if (!this.truncateDate) {
            this.lblMessage = DmError.Staffing.ScheduleEndDateRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("calenderBtn_End date", true);
            });
            return;
        }

        if (this.selectedTruncateReason.toLowerCase() === "other" && !this.truncateComment) {
            this.lblMessage = DmError.Staffing.CommentsAreRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-truncate-reason", true);
            });
            return;
        }

        this.isLoading = true;
        this.showLoading = true;
        this.loadingText = "Truncating";
        this.lblMessage = "";

        const truncateResponse = this.staffingActionService.truncateActionOnAssignment("TruncateRequest", this.validationResults.assignmentIds[0], this.selectedTruncateReason, this.truncateDate, this.truncateComment);
        truncateResponse
            .then((r: IActionResponse) => {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.TruncateRequestSuccess);
                responseMessage = this.staffingActionService.onSuccessAPICall(StaffingActionConstants.ActionTruncate, r);
                const projectIds = this.assignmentList.map((assignment) => assignment.projectIdSelected);
                this.staffingService.invalidateProjectStaffingData(projectIds);
                this.isLoading = false;
                this.showLoading = true;
                this.staffingActionService.displayMessages(responseMessage, this.validationResults.validationMessage);
                this.closeModal();
            })
            .catch((error) => {
                this.staffingActionService.onErrorAPICall(StaffingActionConstants.ActionTruncate, error);
                this.errorSummaryText = DmError.Staffing.ErrorWhileTruncating;
                this.logError(SourceConstants.Method.Submit, error, this.errorSummaryText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.showLoading = false;
                this.closeModal();
            });
    }

    /**
     * load reason code list for truncate action
     */
    private loadTruncateReasonCode(): Promise<IReasonCodeProblemType[]> {
        return this.staffingActionService.getTruncateReasons()
            .then((reasonCodeResponse: IReasonCodeProblemType[]) => {
                this.truncateReasons = reasonCodeResponse;
                return Promise.resolve(this.truncateReasons);
            });
    }

    /**
     * function to get Minimum truncate date.
     */
    private getMinTruncateDate(): Date {
        if (!this.laborDeliveryDate) {
            this.laborDeliveryDate = moment("0001-01-01").toDate();
        }
        const formatedScheduleStartDate = moment(this.scheduleStartDate);
        const formatedLaborDeliveryDate = moment.min(moment(this.laborDeliveryDate), moment(this.getMaxTruncateDate()));
        return moment.max(formatedScheduleStartDate, formatedLaborDeliveryDate).toDate();
    }

    /**
     * function to get Maximum truncate date.
     */
    private getMaxTruncateDate(): Date {
        const formatedScheduleEndDate = moment(this.scheduleEndDate);
        /// commmenting this logic till we get confirmation from GRM team until then we  will have this method..
        /// const formatedRequestEndDate = moment(this.requestEndDate);
        return formatedScheduleEndDate.toDate();
    }

    /**
     * function to get and set current assignment's labor delivery date if exists
     * If API returned 500 error, we will block truncate pop up and show banner error message
     * If API returned empty value or other error, we treat laborDeliveryDate = 01/01/0001
     */
    private getLaborDeliveryDate(assignmentId: number, resourceAlias: string): Promise<void> {
        return this.laborManagementService.getBulkLaborEntries(assignmentId.toString(), resourceAlias)
            .then((laborData) => {
                if (laborData.response && laborData.response.laborDeliveryDate) {
                    this.laborDeliveryDate = laborData.response.laborDeliveryDate;
                    this.resourceLoggedHours = true;
                }
            }).catch((error: any) => {
                if (error.status === 500) {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.fxpMessageService.addMessage("Couldn't load data", "error", false, uuid());
                    this.logError(SourceConstants.Method.GetLaborDeliveryDate, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.endComponentLoad();
                    this.activeModal.close();
                }
            });
    }
}
