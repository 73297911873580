import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UIRouterModule } from "@uirouter/angular";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DmDatepickerComponent } from "../../components/tiles/datepicker/datepicker.component";
import { DmDateProgressBarComponent } from "../tiles/dm-date-progress-bar/dm-date-progress-bar.component";
import { DmDisplayCurrencyOrDashPipe } from "../../common/services/filters/display-currency-or-dash.pipe";
import { DmDisplayDashIfEmptyOrNullPipe } from "../../common/services/filters/display-dash-if-empty-or-null.pipe";
import { DmDisplayDateOrDashPipe } from "../../common/services/filters/display-date-or-dash.pipe";
import { DmDropdownMenuComponent } from "../tiles/dm-dropdown-menu/dm-dropdown-menu.component";
import { DmLoadingComponent } from "../tiles/dm-loading/dm-loading.component";
import { DmNoDataTextComponent } from "../tiles/no-data-text/no-data-text.component";
import { DmProgressBarComponent } from "../tiles/dm-progress-bar/dm-progress-bar.component";
import { DmRefreshComponent } from "../tiles/dm-refresh/dm-refresh.component";
import { DmServiceErrorComponent } from "../tiles/dm-service-error/dm-service-error.component";
import { DmSummaryProgressBarComponent } from "../tiles/dm-summary-progress-bar/dm-summary-progress-bar.component";
import { DmTooltipComponent } from "../tiles/dm-tooltip/dm-tooltip.component";
import { DmTypeAheadComponent } from "../tiles/type-ahead/type-ahead.component";
import { FilterPipe } from "../../common/services/filters/filter.pipe";
import { HighlightPipe } from "../../common/services/filters/highlight.pipe";
import { LimitNumericDirective } from "../../common/directives/limit-numeric.directive";
import { OneProfileService } from "../../common/services/one-profile.service";
import { OrderByPipe } from "../../common/services/filters/order-by.pipe";
import { PaginationComponent } from "../../components/tiles/pagination/dmpagination.component";
import { PaginationV2Component } from "../my-portfolio/tiles/pagination-v2/dm-pagination-v2.component";
import { ReplaceLineBreaks } from "../../common/services/filters/safeHtml.pipe";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { SumPipe } from "../../common/services/filters/sum.pipe";
import { TwoDigitDecimalPositiveNumberDirective } from "../../common/directives/two-digit-decimal-positive-number.directive";
import { UniquePipe } from "../../common/services/filters/filterUnique.pipe";
import { DmTileComponent } from "../tiles/dm-tile/dm-tile.component";
import { DmScheduleComponent } from "../tiles/dm-schedule/dm-schedule.component";
import { ClickOutsideDirective } from "../../common/directives/click-outside.directive";
import { NumberSuffixPipe } from "../../common/services/filters/number-suffix.pipe";

@NgModule({
    declarations: [
        DmDatepickerComponent,
        DmDropdownMenuComponent,
        DmDisplayCurrencyOrDashPipe,
        DmDisplayDashIfEmptyOrNullPipe,
        DmDisplayDateOrDashPipe,
        DmLoadingComponent,
        DmProgressBarComponent,
        DmRefreshComponent,
        DmTooltipComponent,
        DmTypeAheadComponent,
        FilterPipe,
        OrderByPipe,
        SumPipe,
        ReplaceLineBreaks,
        HighlightPipe,
        UniquePipe,
        NumberSuffixPipe,
        TwoDigitDecimalPositiveNumberDirective,
        LimitNumericDirective,
        PaginationComponent,
        PaginationV2Component,
        DmSummaryProgressBarComponent,
        DmNoDataTextComponent,
        DmServiceErrorComponent,
        DmDateProgressBarComponent,
        DmTileComponent,
        DmScheduleComponent,
        ClickOutsideDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        UIRouterModule,
    ],
    providers: [
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        OneProfileService,
        SharedFunctionsService,
    ],
    exports: [
        DmDatepickerComponent,
        DmDisplayCurrencyOrDashPipe,
        DmDisplayDashIfEmptyOrNullPipe,
        DmDisplayDateOrDashPipe,
        DmDropdownMenuComponent,
        DmLoadingComponent,
        DmProgressBarComponent,
        DmRefreshComponent,
        DmTooltipComponent,
        DmTypeAheadComponent,
        FilterPipe,
        OrderByPipe,
        SumPipe,
        ReplaceLineBreaks,
        HighlightPipe,
        UniquePipe,
        NumberSuffixPipe,
        TwoDigitDecimalPositiveNumberDirective,
        LimitNumericDirective,
        PaginationComponent,
        PaginationV2Component,
        DmSummaryProgressBarComponent,
        DmNoDataTextComponent,
        DmServiceErrorComponent,
        DmDateProgressBarComponent,
        DmTileComponent,
        DmScheduleComponent,
        ClickOutsideDirective
    ],
    entryComponents: []
})
export class CommonComponentsModule { }
