import * as fromManageSuppliers from "./manage-suppliers.action";
import { IPurchaseOrder } from "../../common/services/contracts/po.service.contracts";
import { ILoadableState } from "../reducers";

export interface IManageSuppliersState extends ILoadableState {
    manageSuppliers: IPurchaseOrder[];
}

export const initialState: IManageSuppliersState = {
    manageSuppliers: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromManageSuppliers.ManageSuppliersActions): { [engagmentId: string]: IManageSuppliersState } {
    switch (action.type) {
        case fromManageSuppliers.ManageSuppliersActionTypes.LOAD_MANAGE_SUPPLIERS: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }

        case fromManageSuppliers.ManageSuppliersActionTypes.LOAD_MANUAL_MANAGE_SUPPLIERS: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }

        case fromManageSuppliers.ManageSuppliersActionTypes.LOAD_MANAGE_SUPPLIERS_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                manageSuppliers: action.manageSuppliers,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromManageSuppliers.ManageSuppliersActionTypes.LOAD_MANAGE_SUPPLIERS_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromManageSuppliers.ManageSuppliersActionTypes.INVALIDATE_MANAGE_SUPPLIERS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}