import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { WbsEngagementEditModalModule } from "../../manage-wbs/tiles/wbs-engagement/wbs-engagement-edit/wbs-engagement-edit.module";
import { WbsEngagementStateModalModule } from "../../manage-wbs/tiles/wbs-engagement/wbs-engagement-ebs-state/engagement-request-state-change-modal.module";
import { WbsInternalEngagementComponent } from "./wbs-internal-engagement.component";
import { TeamStructureModalModule } from "../../shared/team-structure/team-structure.module";
import { ManageEbsService } from "../../../common/services/manage-ebs.service";
import { DmUserProfileModule } from "../../../components/shared/user-name/user-name.module";

@NgModule({
    declarations: [
        WbsInternalEngagementComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        WbsEngagementEditModalModule,
        WbsEngagementStateModalModule,
        TeamStructureModalModule,
        DmUserProfileModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        DMAuthorizationService,
        SharedFunctionsService,
        ManageEbsService
    ],
    entryComponents: [
        WbsInternalEngagementComponent,
    ],
    exports: [
        WbsInternalEngagementComponent,
    ]
})
export class WbsInternalEngagementModule { }