import { Inject, Injectable } from "@angular/core";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { APIConstants, Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";
import { DataService } from "./data.service";
import { ConfigManagerService } from "./configmanager.service";
import { UnitSubmissionsResponse } from "./contracts/unit-service.contracts";


@Injectable()
export class UnitsService extends DmServiceAbstract {
    
    private projectServiceBaseUriv1: string;
    private subscriptionKey: string;
    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService) {
        super(dmLogger, Services.UnitsService );
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Gets the Pending Units Approvals for the loggedin User.     
     *     
     * @returns {IUnitDetails[]} the view model of the Units that can be used on the UI
     * @memberof UnitsService
     */
    public getPendingUnitApprovalsForLoggedInUser(): Promise<{ [key: string]: number }> {
        return this.configManagerService.initialize().then(() => {
            this.initializeConfig();
            const url: string = `${this.projectServiceBaseUriv1}/units/pendingapprovals`;
            return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetPendingUnitApprovals);
        });   
        
    }
    /**
     * Gets the units submitted for given WBS Id
     * @param wbsId 
     * @returns Array of UnitSubmissionsResponse
     */
    public getPendingUnitApprovalsForWbsId(wbsId: string): Promise<UnitSubmissionsResponse[]> {
        const url: string = `${this.projectServiceBaseUriv1}/units/${wbsId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetPendingUnitApprovals);
    }


    private initializeConfig(): void {
        this.projectServiceBaseUriv1 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }

}