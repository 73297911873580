import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalV2Module } from "../../../modals/dm-modal-v2/dm-modal-v2.module";
import { DeleteCsatContactModalComponent } from "./delete-csat-contact.component";

@NgModule({
    declarations: [
        DeleteCsatContactModalComponent,
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        DmModalV2Module
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        DeleteCsatContactModalComponent,
    ],
    exports: [
        DeleteCsatContactModalComponent,
    ]
})
export class DeleteCsatContactModalModule { }