<section id="'financial-summary-table'+{{engagementFinancialSummary?.planId}}" class="grid-data clear-fix" aria-label="Financial Summary">
    <dm-loading [loadingText]="loadingMessage" [isCustomText]="true"
        [showLoading]="!engagementFinancialSummary && !engagementFinancialSummaryError"
        *ngIf="!engagementFinancialSummary">
    </dm-loading>
    <table *ngIf="engagementFinancialSummary" class="dm-table__header dm-table--border-collapse" width="100%" role="table">
        <thead class="dm-table__thead">
            <tr class="dm-accordion">
                <th scope="col" class="dm-accordion--width-15 text-left font-caption-alt">Category</th>
                <th scope="col" class="dm-accordion--width-10 text-right font-caption-alt">Contract
                    Baseline <br> (<span
                        [textContent]="engagementFinancialSummary.finalizedContractBaseline.status"></span>)
                </th>
                <th scope="col" class="dm-accordion--width-10 text-right font-caption-alt">
                    Delivery Baseline <br /> (<span
                        [textContent]="engagementFinancialSummary.finalizedDeliveryBaseline.status"></span>)
                </th>
                <th scope="col" class="dm-accordion--width-10 text-right font-caption-alt">Revised
                    <br />
                    Delivery Baseline
                </th>
                <th scope="col" class="dm-accordion--width-10 text-right font-caption-alt">Revised
                    Delivery <br />Baseline
                    Variance </th>
                <th scope="col" class="dm-accordion--width-14 text-right font-caption-alt">Current
                    Financial Plan
                </th>
                <th scope="col" class="dm-accordion--width-12 text-right font-caption-alt">Revised
                    <br /> Financial Plan
                </th>
                <th scope="col" class="dm-accordion--width-12 text-right font-caption-alt">Revised
                    Financial Plan Variance</th>
            </tr>
        </thead>
        <tbody class="dm-table__tbody">
            <tr>
                <th class="dm-accordion--width-15 text-left" scope="row">
                    <p class="font-caption-alt">Margin</p>
                </th>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedContractBaseline.margin | number: '1.2-2'}} %</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedDeliveryBaseline.margin | number: '1.2-2'}} %</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedDeliveryBaseline.margin | number: '1.2-2'}} %
                </td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    <i class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedDeliveryBaseline.margin < engagementFinancialSummary.finalizedDeliveryBaseline.margin}"></i>
                    <span>
                        {{(engagementFinancialSummary.revisedDeliveryBaseline.margin -
                        engagementFinancialSummary.finalizedDeliveryBaseline.margin) | number: '1.2-2'}}
                        %
                    </span>
                </td>
                <td class="dm-accordion--width-14 text-right font-caption-alt">
                    {{engagementFinancialSummary.currentFinanicalPlan.margin | number: '1.2-2'}} %
                </td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedFinancialPlan.margin | number: '1.2-2'}} %
                </td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    <i class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedFinancialPlan.margin < engagementFinancialSummary.currentFinanicalPlan.margin}"></i>
                    <span>
                        {{(engagementFinancialSummary.revisedFinancialPlan.margin -
                        engagementFinancialSummary.currentFinanicalPlan.margin) | number: '1.2-2'}}
                        %
                    </span>
                </td>
            </tr>
            <tr>
                <th class="dm-accordion--width-15 text-left" scope="row">
                    <p class="font-caption-alt">Labor (hours)</p>
                </th>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedContractBaseline.hours | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedDeliveryBaseline.hours | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedDeliveryBaseline.hours | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background"><i
                        class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedDeliveryBaseline.hours < engagementFinancialSummary.finalizedDeliveryBaseline.hours}"></i>
                    <span>{{(engagementFinancialSummary.revisedDeliveryBaseline.hours -
                        engagementFinancialSummary.finalizedDeliveryBaseline.hours) | number: '1.2-2'}}</span>
                </td>
                <td class="dm-accordion--width-14 text-right font-caption-alt">
                    {{engagementFinancialSummary.currentFinanicalPlan.hours | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedFinancialPlan.hours | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    <i class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedFinancialPlan.hours < engagementFinancialSummary.currentFinanicalPlan.hours}"></i>
                    <span>{{(engagementFinancialSummary.revisedFinancialPlan.hours -
                        engagementFinancialSummary.currentFinanicalPlan.hours) | number: '1.2-2'}}</span>
                </td>
            </tr>
            <tr>
                <th class="dm-accordion--width-15 text-left" scope="row">
                    <p class="font-caption-alt">Cost ({{engagementFinancialSummary.planCurrency}})</p>
                </th>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedContractBaseline.cost | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedDeliveryBaseline.cost | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedDeliveryBaseline.cost | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    <i class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedDeliveryBaseline.cost < engagementFinancialSummary.finalizedDeliveryBaseline.cost}"></i>
                    <span>{{(engagementFinancialSummary.revisedDeliveryBaseline.cost -
                        engagementFinancialSummary.finalizedDeliveryBaseline.cost) | number: '1.2-2'}}</span>
                </td>
                <td class="dm-accordion--width-14 text-right font-caption-alt">
                    {{engagementFinancialSummary.currentFinanicalPlan.cost | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedFinancialPlan.cost | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    <i class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedFinancialPlan.cost < engagementFinancialSummary.currentFinanicalPlan.cost}"></i>
                    <span>{{(engagementFinancialSummary.revisedFinancialPlan.cost -
                        engagementFinancialSummary.currentFinanicalPlan.cost) | number: '1.2-2'}}</span>
                </td>
            </tr>
            <tr>
                <th class="dm-accordion--width-15 text-left" scope="row">
                    <p class="font-caption-alt">Revenue ({{engagementFinancialSummary.planCurrency}})</p>
                </th>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedContractBaseline.revenue | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt">
                    {{engagementFinancialSummary.finalizedDeliveryBaseline.revenue | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedDeliveryBaseline.revenue | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    <i class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedDeliveryBaseline.revenue < engagementFinancialSummary.finalizedDeliveryBaseline.revenue}"></i>
                    <span>{{(engagementFinancialSummary.revisedDeliveryBaseline.revenue -
                        engagementFinancialSummary.finalizedDeliveryBaseline.revenue) | number: '1.2-2'}}</span>
                </td>
                <td class="dm-accordion--width-14 text-right font-caption-alt">
                    {{engagementFinancialSummary.currentFinanicalPlan.revenue | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    {{engagementFinancialSummary.revisedFinancialPlan.revenue | number: '1.2-2'}}</td>
                <td class="dm-accordion--width-12 text-right font-caption-alt grey-background">
                    <i class="icon icon-StatusTriangle"
                        [ngClass]="{'icon-statusTriangle-reverted': engagementFinancialSummary.revisedFinancialPlan.revenue < engagementFinancialSummary.currentFinanicalPlan.revenue}"></i>
                    <span>{{(engagementFinancialSummary.revisedFinancialPlan.revenue -
                        engagementFinancialSummary.currentFinanicalPlan.revenue) | number: '1.2-2'}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</section>