import { Action } from "@ngrx/store";
import { IPurchaseOrder } from "../../common/services/contracts/po.service.contracts";

// 1. action types
export enum ManageSuppliersActionTypes {
    LOAD_MANAGE_SUPPLIERS = "[ManageSuppliers] Load",
    LOAD_MANUAL_MANAGE_SUPPLIERS = "[ManageSuppliers] Load Manual",
    LOAD_MANAGE_SUPPLIERS_SUCCESS = "[ManageSuppliers] Load Success",
    LOAD_MANAGE_SUPPLIERS_FAIL = "[ManageSuppliers] Load Fail",
    INVALIDATE_MANAGE_SUPPLIERS = "[ManageSuppliers] Invalidate"
}

// 2. action creators
export class LoadManageSuppliers implements Action {
    public readonly type = ManageSuppliersActionTypes.LOAD_MANAGE_SUPPLIERS;
    public constructor(public engagementId: string) { }
}

export class LoadManualManageSuppliers implements Action {
    public readonly type = ManageSuppliersActionTypes.LOAD_MANUAL_MANAGE_SUPPLIERS;
    public constructor(public engagementId: string) { }
}

export class LoadManageSuppliersSuccess implements Action {
    public readonly type = ManageSuppliersActionTypes.LOAD_MANAGE_SUPPLIERS_SUCCESS;
    public constructor(public engagementId: string, public manageSuppliers: IPurchaseOrder[]) { }
}

export class LoadManageSuppliersFail implements Action {
    public readonly type = ManageSuppliersActionTypes.LOAD_MANAGE_SUPPLIERS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateManageSuppliers implements Action {
    public readonly type = ManageSuppliersActionTypes.INVALIDATE_MANAGE_SUPPLIERS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type ManageSuppliersActions =
    | LoadManageSuppliers
    | LoadManualManageSuppliers
    | LoadManageSuppliersSuccess
    | LoadManageSuppliersFail
    | InvalidateManageSuppliers;