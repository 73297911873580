import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { FormsModule } from "@angular/forms";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../../common/services/configmanager.service";
import { CustomerEngagementFinancialsModalModule } from "../financial-modal/customer-engagement-financials-modal.module";
import { CustomerEngagementGridDataOverviewTab } from "./customer-engagement-grid-data-overview-tab.component";
import { DelegationDetailsModalModule } from "../../../../../tiles/delegation-details-modal/delegation-details-modal.module";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { DmUserProfileModule } from "../../../../../shared/user-name/user-name.module";
import { ProjectService } from "../../../../../../common/services/project.service";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        CustomerEngagementGridDataOverviewTab
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        NgbModule,
        UIRouterModule,
        DelegationDetailsModalModule,
        CustomerEngagementFinancialsModalModule,
        DmUserProfileModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
        ProjectService,
    ],
    entryComponents: [
        CustomerEngagementGridDataOverviewTab
    ],
    exports: [
        CustomerEngagementGridDataOverviewTab
    ],
})
export class CustomerEngagementGridDataOverviewTabModule { }