import * as fromChangeRequest from "./change-requests.action";
import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";
import { ILoadableState } from "../reducers";

export interface IChangeRequestsState extends ILoadableState {
    changeRequests: IChangeRequest[];
}

export const initialState: IChangeRequestsState = {
    changeRequests: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = initialState, action: fromChangeRequest.ChangeRequestActions): IChangeRequestsState {
    switch (action.type) {
        case fromChangeRequest.ChangeRequestActionTypes.LOAD_CHANGE_REQUESTS: {
            return {
                ...state,
                loading: true
            };
        }

        case fromChangeRequest.ChangeRequestActionTypes.LOAD_CHANGE_REQUESTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                changeRequests: action.changeRequests
            };
        }

        case fromChangeRequest.ChangeRequestActionTypes.LOAD_CHANGE_REQUESTS_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.errorMessage
            };
        }

        case fromChangeRequest.ChangeRequestActionTypes.INVALIDATE_CHANGE_REQUESTS: {
            return initialState;
        }
    }

    return state;
}