import { Component, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";

@Component({
    selector: "dm-entry",
    templateUrl: "./entry.html",
})
export class EntryComponent {

    public constructor(
        @Inject(StateService) public stateService: StateService
    ) { }

}