import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { OpenSyncEACToPlanThresholdDialogComponent } from "./open-sync-eac-to-plan-threshold-dialog.component";
import { DmModalV2Module } from "../../modals/dm-modal-v2/dm-modal-v2.module";

@NgModule({
    declarations: [
        OpenSyncEACToPlanThresholdDialogComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        DmModalV2Module
    ],
    providers: [
        DMLoggerService,
    ],
    exports: [
        OpenSyncEACToPlanThresholdDialogComponent
    ],
    entryComponents: [
        OpenSyncEACToPlanThresholdDialogComponent
    ]
})
export class OpenSynEACToPlanThresholdModule { }