<section class="unapproved-dailog">
    <label class="sr-only">Unapproved Expenses</label>
    <section class="modal-header">
        <section class="pull-right">
            <a [href]="concurUrl" target="_blank" class="dm-link pull-left" id="accessibilityConstants.ViewApprovals" (keydown)="moveFocusPrev($event, 'accessibilityConstants.CloseButton')" aria-label="open link in new tab">
                <span class="icon icon-Forward mar-r-8"></span>
                View approvals in Concur
            </a>
            <button type="button" class="close set-focus" (click)="closeModal()" id="accessibilityConstants.ClosePopUp" aria-label="Unapproved Expenses Dialog close">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </section>
        <h4 id="unapprovedExpenseHeading" class="modal-title font-subtitle pull-left">
            Unapproved Expenses
        </h4>
    </section>

    <!-- <dm-loading [loadingText]="'Getting Unapproved Expenses Details'" [showLoading]="showLoading" *ngIf="isLoading"></dm-loading> -->

    <section class="modal-body font-caption-alt">
        <table width="100%" class="expense-table" aria-labelledby="unapprovedExpenseHeading" *ngIf="unapprovedExpenseEntries.length > 0">
            <thead class="expense-table--thead">
                <tr>
                    <th scope="col" aria-label="Expand/Collapse"></th>
                    <th scope="col" class="text-left font-caption-alt width-28 pad-l-0">Report Title / Expense line
                    </th>
                    <th scope="col" class="text-left font-caption-alt width-18">Submitter</th>
                    <th scope="col" class="text-right font-caption-alt width-18">Submitted Date</th>
                    <th scope="col" class="text-right font-caption-alt width-18">Unapproved <br>
                        amount for this <br>
                        proj/eng</th>
                </tr>
            </thead>
            <tbody class="expense-table--tbody" *ngFor="let expdata of unapprovedExpenseEntries">
                <tr class="project-title">
                    <th scope="row" colspan="1" class="dm-accordion__arrowicon">
                        <button role="button" class="pull-right" (click)="collapseEntries(expdata);"
                            aria-label="Spacebar to toggle">
                            <i class="icon"
                                [ngClass]="{'icon-ChevronUp': expdata.isProjectExpanded, 'icon-ChevronDown': !expdata.isProjectExpanded}"></i>
                            <span class="sr-only" *ngIf="expdata.isProjectExpanded" aria-live="polite">
                                Expanded Entity
                                <span [textContent]="expdata.projectName"></span>
                            </span>
                            <span class="sr-only" *ngIf="!expdata.isProjectExpanded" aria-live="polite">Collapsed</span>
                        </button>
                    </th>
                    <td class="pad-l-0" colspan="4">
                        Project: {{expdata.projectName | dmDisplayDashIfEmptyOrNull}}
                        <span *ngIf="!expdata.projectName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </td>
                </tr>
                <ng-container *ngIf="expdata.isProjectExpanded">
                    <ng-container *ngFor="let entry of expdata.entries">
                        <tr class="exp-data">
                            <th class="dm-accordion__arrowicon">
                                <button role="button" class="pull-right"
                                    (click)="entry.isSubcategoryExpanded=!entry.isSubcategoryExpanded;"
                                    aria-label="Spacebar to toggle">
                                    <i class="icon"
                                        [ngClass]="{'icon-ChevronUp': entry.isSubcategoryExpanded, 'icon-ChevronDown': !entry.isSubcategoryExpanded}"></i>
                                    <span class="sr-only" *ngIf="entry.isSubcategoryExpanded" aria-live="polite">
                                        Expanded Entity
                                        <span [textContent]="entry.subCategory"></span>
                                    </span>
                                    <span class="sr-only" *ngIf="!entry.isSubcategoryExpanded"
                                        aria-live="polite">Collapsed</span>
                                </button>
                            </th>
                            <td scope="row" class="text-left exp-data-th" [title]="entry.reportTitle">
                                {{entry.reportTitle | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!entry.reportTitle" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </td>
                            <td class="text-left exp-data-td">
                                {{entry.submitterName  | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!entry.submitterName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </td>
                            <td class="text-right exp-data-td">
                                {{entry.submittedDate | dmDisplayDateOrDashPipe}}
                                <span *ngIf="!entry.submittedDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </td>
                            <td class="text-right exp-data-td">
                                {{entry.projectAmount | number : '1.2-2'}} {{entry.currencyCode}}
                            </td>
                        </tr>
                        <tr *ngIf="entry.isSubcategoryExpanded" class="sub-catogory">
                            <td colspan="2" class="text-left">
                                <span class="m-l-60">
                                    {{entry.subCategory | dmDisplayDashIfEmptyOrNull}}
                                    <span *ngIf="!entry.subCategory" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </span>
                            </td>
                            <td colspan="1"></td>
                            <td colspan="2" class="text-right">
                                {{entry.projectAmount | number : '1.2-2'}} {{entry.currencyCode}}
                            </td>
                        </tr>
                    </ng-container>

                </ng-container>
            </tbody>
        </table>
        <dm-no-data *ngIf="unapprovedExpenseEntries && unapprovedExpenseEntries.length == 0" [noDataText]="noDatafoundText"></dm-no-data>
    </section>
    <section class="modal-footer footer-button">
        <button type="button" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.CloseButton" (keydown)="moveFocusNext($event, 'accessibilityConstants.ViewApprovals')" (click)="closeModal()">Close</button>
    </section>
</section>