<div class="dm-update-contact-language-modal">
    <label id="updateContactModal" class="sr-only">{{contactLanguageModalTitle}}</label>
    <div class="modal-header modal-noborder display-flex">
        <div class="display-grid">
            <h4 class="modal-title font-subtitle pull-left">{{contactLanguageModalTitle}}</h4>
        </div>
        <button type="button" title="Close" class="dm-transparent-btn modal-close-icon"
            aria-label="Update CSAT Contact Language Modal Close" (click)="closeModal()"
            id="accessibilityConstants.CloseUpdateButton"
            (keydown)="moveFocusPrev($event, 'accessibilityConstants.UpdateCSATContactLanguage')">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
    </div>
    <div>
        <span class="icon icon-info"></span>
        <span class="info-text">{{contactLanguageModalInfo}}</span>
        <br>
        <span class="font-caption-italic">{{contactLanguageModalExtraInfo}}</span>
        <br>
        <br>
        <span class="error-msg" *ngIf="!isComponentLoading && noContactAvailable">{{noContactAvailableInfo}}</span>
    </div>
    <dm-loading [isCustomText]="true" [loadingText]="loadingText" *ngIf="isComponentLoading" [showLoading]="true">
    </dm-loading>
    <ng-container  *ngIf="!isComponentLoading" >
    <div class="modal-body">
        <div class="dm-update-contact-language-modal__dropdown dm-m-t-10" ngbDropdown #contactsDropdown="ngbDropdown"
            auto-close="outsideClick">
            <button class="btn dm-update-contact-language-modal__dropdown-btn set-focus" id="dropdownManual" ngbDropdownToggle
                (click)="contactsDropdown.open()" [disabled]="noContactAvailable">
                <span dm-update-contact-language-modal__dropdown-btn-label>
                    <span *ngIf="!selectedContact">Select Customer Contact</span>
                    <span *ngIf="selectedContact">{{selectedContact?.name}} ( {{selectedContact?.language?.languageDescription}} )</span>
                </span>
                <span class="icon icon-chevron-down"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownManual">
                <button ngbDropdownItem class="dm-update-contact-language-modal__dropdown-item dropdown-item" *ngFor="let contact of filteredContactDetailList"
                    (click)="contactSelected(contact); contactsDropdown.close();">
                    <strong>{{contact?.name}}</strong>
                    <div>{{contact?.language?.languageDescription}}</div>
                </button>
            </div>
        </div>
        <div class="dm-update-contact-language-modal__dropdown dm-m-t-10" ngbDropdown #contactsLanguageDropdown="ngbDropdown"
            auto-close="outsideClick">
            <button class="btn dm-update-contact-language-modal__dropdown-btn set-focus" id="dropdownManual1" ngbDropdownToggle
                (click)="contactsLanguageDropdown.open()" [disabled]="noContactAvailable">
                <span dm-update-contact-language-modal__dropdown-btn-label>
                    <span *ngIf="!selectedLanguage">Select Customer Language</span>
                    <span *ngIf="selectedLanguage">{{selectedLanguage?.languageDescription}} ({{selectedLanguage?.languageCode}})</span>
                </span>
                <span class="icon icon-chevron-down"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownManual1">
                <button ngbDropdownItem class="dm-update-contact-language-modal__dropdown-item dropdown-item" *ngFor="let lng of languages"
                    (click)="languageSelected(lng); contactsLanguageDropdown.close();">
                    <div>{{lng?.languageDescription}} ({{lng?.languageCode}})</div>
                </button>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="modal-footer">
        <button type="button" value="cancel" id="cancelUpdate" class="fxp-btn fxp-btn--secondary" (click)="closeModal()"
            (keydown)="moveFocusNext($event, 'accessibilityConstants.UpdateCSATContactLanguage')">
            Cancel
        </button>

        <button type="button" value="Continue" id="accessibilityConstants.UpdateCSATContactLanguage" class="fxp-btn fxp-btn--primary"
            [disabled]="isComponentLoading || shouldDisableLanguageChangeSelection()"
            (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseUpdateButton')" (click)="updateContactLanguage()">
            Submit
        </button>
    </div>
</ng-container>
</div>