import { Component, Input, Inject, forwardRef } from "@angular/core";
import { FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components, LogEventConstants, SourceConstants } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmError } from "../../../common/error.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { IOnSave } from "../../../common/services/contracts/financial.service.contracts";
import { IRejectResponseDomainData, IReasonCodeProblemType } from "../../../common/services/contracts/staffing.service.contract";
import { IStaffingResource } from "../../../common/services/contracts/staffing-details.contract";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { StaffingService } from "../../../common/services/staffing.service";

@Component({
    selector: "resource-rejection-modal",
    templateUrl: "./resource-rejection-modal.component.html",
    styleUrls: ["./resource-rejection-modal.component.scss"],
})

export class ResourceRejectionModalComponent extends DmModalAbstract {

    @Input() public resourceInfo: IStaffingResource;
    public isLoading = false;
    public isSubmitButtonDisabled = true;
    public lblMessage = "";
    public rejectionComment = "";
    public rejectionReasons: string[] = [];
    public selectedReason = "select";
    public resourceRejectionErrors = DmError.Staffing;

    private FXP_CONSTANTS = FxpConstants;
    private failureMessages: IOnSave;
    private successMessages: IOnSave;


    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.ResourceRejection);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus("closeSuspendPopUp", true);
        this.loadRejectReasons();
        this.successMessages = this.configManagerService.getValue<any>("SuccessMessages").RejectResource;
        this.failureMessages = this.configManagerService.getValue<any>("FailureMessages").RejectResource;
    }

    /**
     * OnChange of Reason
     */
    public onReasonChange(): void {
        this.isSubmitButtonDisabled = false;
        this.lblMessage = "";
        if (!this.selectedReason || this.selectedReason.toLowerCase() === "select") {
            this.isSubmitButtonDisabled = true;
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OnReasonChange, LogEventConstants.StaffingResourceRejectionReasonSelected);
        }
    }

    /**
     * Submit request to suspend request for assignment
     */
    public submit(): void {
        this.lblMessage = "";
        if (this.selectedReason.toLowerCase() === "other" && this.rejectionComment.length === 0) {
            this.lblMessage = DmError.Staffing.CommentsAreRequired;
            return;
        }
        this.isLoading = true;
        this.staffingService.updateGRMResourceRequests(this.resourceInfo.resourceId, this.selectedReason, this.rejectionComment, "RequestorDeclined")
            .then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.StaffingDeclineResourceRequest, {reason: this.selectedReason});
                this.isLoading = false;
                let sucessMessage: string = this.successMessages.OnSave;
                sucessMessage = sucessMessage.replace("#", (this.resourceInfo.resourceRole + " " + this.resourceInfo.resourceAlias));
                this.fxpMessageService.addMessage(sucessMessage, this.FXP_CONSTANTS.messageType.success);
                this.activeModal.close(true);
            }).catch((error) => {
                const message = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.Submit, error, message, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isLoading = false;
                let errorMessage: string = this.failureMessages.OnSave;
                errorMessage = errorMessage.replace("#", (this.resourceInfo.resourceRole + " " + this.resourceInfo.resourceAlias));
                this.fxpMessageService.addMessage(errorMessage, this.FXP_CONSTANTS.messageType.error);
                this.activeModal.close(false);
            });
    }

    /**
     * Function to load pop up to reject resource reason.
     */
    private loadRejectReasons(): void {
        this.isLoading = true;
        let rejectFilteredData: IReasonCodeProblemType[];
        this.staffingService.getGRMRejectResourceDomainData()
            .then((response: IRejectResponseDomainData) => {
                this.rejectionReasons = [];
                this.isLoading = false;
                if (response
                    && response.DomainDataResult
                    && response.DomainDataResult.ReasonCodeProblemTypes
                    && response.DomainDataResult.ReasonCodeProblemTypes.length) {
                    rejectFilteredData = response.DomainDataResult.ReasonCodeProblemTypes.filter((rejectdata) => rejectdata.ParentName === "Requestor Declined");
                }
                rejectFilteredData.forEach((rejectReason) => {
                    this.rejectionReasons.push(rejectReason.Name);
                });
            });
    }
}
