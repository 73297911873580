import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";

import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { UserpreferencelinksMgmtModalComponent } from "./user-preference-links-mgmt-modal.component";
import { UserPreferenceLinksManagementService } from "../../../../common/services/userpreferencelinksmgmt.service";


@NgModule({
    declarations: [
        UserpreferencelinksMgmtModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService,
        UserPreferenceLinksManagementService
    ],
    entryComponents: [
        UserpreferencelinksMgmtModalComponent
    ],
    exports: [UserpreferencelinksMgmtModalComponent]
})
export class UserpreferencelinksMgmtModalModule { }




