import { ServicePrefix, SourceConstants } from "../application.constants";
import { DataService } from "../services/data.service";
import { DMLoggerService } from "../services/dmlogger.service";

/* Since this is an abstract component, it does not need the @Component annotation. */
export abstract class DmServiceAbstract  {

    public constructor(
        protected dmLogger: DMLoggerService,
        protected serviceName: string
    ) {
        this.dmLogger = dmLogger;
        this.serviceName = serviceName;
    }

    /**
     * Logs errors on the service layer and sends them to FXP's App Insights in the exceptions table.
     * Everything is prefixed with "Service" for easier finding.
     *
     * @param {*} error
     * @param {string} [errorCode]
     * @memberof DmServiceAbstract
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public logError(methodName: string, error: any, errorCode?: string, errorSeverity?: any): void{
        this.dmLogger.logError(ServicePrefix + this.serviceName, methodName, error, errorCode, undefined, undefined, DataService.getCorrelationIdFromError(error), errorSeverity);
    }

    /**
     * Logs custom events for the service layer and sends them to FXP's App Insights in the custom events table.
     * Everything is prefixed with "Service" for easier finding.
     *
     * @param {string} event
     * @param {{}} [propertyBag]
     * @memberof DmServiceAbstract
     */
    public logEvent(event: string, propertyBag?: {}): void {
        this.dmLogger.logEvent(ServicePrefix + this.serviceName, SourceConstants.Method.LogEvent, event, propertyBag);
    }

}