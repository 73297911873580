import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IMicrosoftGraphDirectoryObjectResponse, IGraphData, IGraphResponse } from "./contracts/microsoft-graph.service.contract";
import { Observable } from "rxjs";
import { CollectionIterator } from "./collection-iterator";
import { IResourceImage } from "./contracts/resource-profile.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

/**
 * This Service Connects to Azure AAD Graph API
 *
 * @export
 * @class AADGraphService
 */
@Injectable()
export class AADGraphService extends DmServiceAbstract {
    private resourceImages: IResourceImage[] = [];
    private baseMicrosoftGraphUrl: string;
    private emailPostFix = "@microsoft.com";
    private resourceAliasesBatchSize: number;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.AADGraphService);
        this.baseMicrosoftGraphUrl = this.configManagerService.getValue<string>("microsoftGraphUri");
        this.resourceAliasesBatchSize = this.configManagerService.getValue<number>("aliasesBatchSizeToGetProfilePhoto");
    }

    /**
     * Retrieving Userpic based on the alias
     * @param alias
     */
    public getResourceThumbnailPicture(alias: string): Promise<string> {
        const resourceImageInCache = this.resourceImages.filter((resource) => resource.resourceAlias.toLowerCase() === alias.toLowerCase())[0];

        if (resourceImageInCache) {
            return Promise.resolve(resourceImageInCache.resourceImage);
        }

        const graphApiRequests = {
            requests: [{
                url: `/users/${alias}${this.emailPostFix}/photos/48x48/$value`,
                id: alias,
                method: "GET"
            }]
        };
        const apiName = "GraphV1ApiBatchPhoto";
        const url = this.baseMicrosoftGraphUrl + "$batch";
        return this.dataService.postData(url, "", apiName, graphApiRequests).then((response: IGraphResponse) => {
            const graphApiBatchPhotoResponseData = response.responses[0];
            if (graphApiBatchPhotoResponseData && graphApiBatchPhotoResponseData.status === 200) {
                this.resourceImages.push({
                    resourceAlias: alias.toLowerCase(),
                    resourceImage: graphApiBatchPhotoResponseData.body
                });
                return graphApiBatchPhotoResponseData.body;
            }
            return "";
        });
    }


    /**
     * Check if user is part of Azure AD Security Group
     * @param groupId GUID of the group
     * @param alias 
     */
    public isUserPartOfSecurityGroup(groupId: string, alias: string): Promise<boolean> {

        // Note: filtering by "id" is the only option supported for directory object, so it's important to get the GUID of the SG.
        // $select is only used as an optimization to reduce the data size returned by the query
        // To test this api : https://developer.microsoft.com/en-us/graph/graph-explorer/preview
        // https://docs.microsoft.com/en-us/graph/api/user-list-memberof?view=graph-rest-1.0&tabs=http
        const graphApiUri = `${this.baseMicrosoftGraphUrl}users/${alias}@microsoft.com/memberOf?$filter=id eq '${groupId}'&$select=id,displayName`;

        return this.dataService.getData(graphApiUri, undefined, APIConstants.MicrosoftGraphApi)
            .then((response: IMicrosoftGraphDirectoryObjectResponse) => {

                if (response && Array.isArray(response.value) && response.value.length > 0) {
                    return Promise.resolve(true);
                }
                return Promise.resolve(false);
            })
            .catch(() => Promise.resolve(false));
    }

    /**
     * returns responses from graph api for the aliases passed
     * @param aliases
     */
    public loadUserPhotosByAlias(aliases: string[]): Observable<IGraphData[]> {
        aliases = aliases.filter((alias) => alias);
        const batchRequestTrackerPromiseCollection = new Array<Promise<boolean>>();

        return Observable.create((observer) => {
            const collectionIterator = new CollectionIterator(aliases, this.resourceAliasesBatchSize);

            let requestSequence = 1;
            while (collectionIterator.hasMoreItems()) {
                const batchRequestTrackerPromise = new Promise<boolean>(() => {
                    const nextBatch = collectionIterator.getNextBatch();

                    this.getUserPhotosByAlias(nextBatch, requestSequence).then(
                        (webResponse) => {
                            if (webResponse && webResponse.responses && webResponse.responses.length) {
                                const graphResponses = webResponse.responses;
                                observer.next(graphResponses);
                            }
                        }
                    );
                });
                batchRequestTrackerPromiseCollection.push(batchRequestTrackerPromise);
                requestSequence++;
            }
            Promise.all(batchRequestTrackerPromiseCollection).then(() => {
                observer.complete();
            });
        });
    }

    /**
     * Makes a Post call to the graph API with the given batch of aliases.
     * @param batchOfAlias
     * @param requestSequenceForLogs
     */
    private getUserPhotosByAlias(batchOfAlias: string[], requestSequenceForLogs: number): Promise<any> {
        const requestList = [];
        for (const alias of batchOfAlias) {
            const request = {
                url: `/users/${alias}${this.emailPostFix}/photos/48x48/$value`,
                id: alias,
                method: "GET"
            };
            requestList.push(request);
        }
        const graphApiRequests = {
            requests: requestList
        };
        const apiNameForLogs = "GraphV1ApiBatchPhotos" + requestSequenceForLogs.toString();
        const url = this.baseMicrosoftGraphUrl + "$batch";
        return this.dataService.postData(url, "", apiNameForLogs, graphApiRequests);
    }
}