import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { Injectable, Inject } from "@angular/core";
import {
    IGetBillingMileStoneListApiParameterModel,
    IConfirmMilestonePatchData,
    IBillingMilestoneModel,
    IMilestoneDateModel,
    IMilestoneDateUpdateData
} from "./contracts/dmmilestone.service.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

/**
 *
 *
 * @export
 * @class MilestonesService
 */
@Injectable()
export class MilestonesService extends DmServiceAbstract {
    private readonly salesOrderServiceBaseurl: string;
    private readonly salesOrderSubscriptionKey: string;
    private wbsSubscriptionKey: string;
    private wbsServiceBaseUri: string;
    private wbsServiceV2BaseUri: string;

    /**
     * Creates an instance of MilestonesService
     * @param {DataService} dataService
     * @param {ConfigManagerService} configmanagerService
     * @memberof MilestonesService
     */
    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.MilestoneService);
        this.salesOrderServiceBaseurl = this.configmanagerService.getValue<string>("salesOrderServiceBaseUri");
        this.salesOrderSubscriptionKey = this.configmanagerService.getValue<string>("salesOrderServiceSubscriptionKey");
        const projectServiceBaseUri = this.configmanagerService.getValue<string>("projectServiceBaseUri");
        this.wbsSubscriptionKey = this.configmanagerService.getValue<string>("projectServiceSubscriptionKey");
        this.wbsServiceBaseUri = projectServiceBaseUri + "v1.0";
        this.wbsServiceV2BaseUri = projectServiceBaseUri + "v2.0";
    }

    /**
     * get list of milestones from BillingMilestones endpoint/API
     *
     * @param {IGetBillingMileStoneListApiParameterModel} apiCallParameter
     * @param {boolean} loadFromCache
     * @returns {Promise<IBillingMilestoneModel[]>}
     * @memberof MilestonesService
     */
    public getMilestoneListByEngagementId(apiCallParameter: IGetBillingMileStoneListApiParameterModel): Promise<IBillingMilestoneModel[]> {
        const url = `${this.salesOrderServiceBaseurl}/BillingMilestones?WBSId=${apiCallParameter.WbsId}&BPId=${apiCallParameter.BPId}`;
        return this.dataService.getData(url, this.salesOrderSubscriptionKey, APIConstants.GetBillingMilestoneApi);
    }

    /**
     * Raises a PATCH or DELETE request to SOBG to either confirm (delete) or unconfirm (patch) the given milestone.
     *
     * @param {IConfirmMilestonePatchData} milestoneDetails
     * @returns {Promise<any>}
     * @memberof MilestonesService
     */
    public setConfirmOrUnconfirmMilestone(milestoneDetails: IConfirmMilestonePatchData): Promise<any> {
        if (milestoneDetails.setOrRemove) {
            return this.dataService.deleteData(
                `${this.salesOrderServiceBaseurl}/BillingMilestones/BillingBlock`,
                this.salesOrderSubscriptionKey,
                milestoneDetails
            );
        } else {
            return this.dataService.patchData(
                `${this.salesOrderServiceBaseurl}/BillingMilestones/BillingBlock`,
                this.salesOrderSubscriptionKey,
                APIConstants.GetBillingMilestoneApi,
                milestoneDetails
            );
        }
    }

    /**
     * Updates milestone service dates engagement with the new data via Patch request to the Project Management API
     * @param serviceDetails
     * @param engagementId
     */
    public updateMilestoneDates(milestoneDates: IMilestoneDateUpdateData, engagementId: string): Promise<any> {
        return this.dataService.postData(`${this.wbsServiceV2BaseUri}/salesorderbilling/updatebillingmilestonedates/${engagementId}`,
            this.wbsSubscriptionKey, APIConstants.WbsEngPatchRequest, milestoneDates);
    }

    /**
     * Get Milestone List By Engagement Id
     * @param serviceDetails
     * @param serviceId
     */
    public getMilestoneDates(engagementId: string, billingPlanNumber: string, billingItemNumber: string): Promise<any> {
        return this.dataService.getData(`${this.wbsServiceV2BaseUri}/salesorderbilling/getbillingmilestonedates/${engagementId}?billingPlanNumber=${billingPlanNumber}&billingItemNumber=${billingItemNumber}`,
            this.wbsSubscriptionKey, APIConstants.WbsProjPatchRequest);
    }
}
