import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject, Injector } from "@angular/core";
import {
    DeviceFactoryProvider,
    FxpEventBroadCastService,
    FxpMessageService,
    FxpConstants,
    ErrorSeverityLevel,
} from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import {
    Components,
    ComponentFailureMessages,
    NoDataText,
    LogEventConstants,
    SourceConstants,
    AccessibilityConstants,
} from "../../common/application.constants";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FilterModel } from "../tiles/project-filter/project-filter.model";
import { getEntireActuals } from "../../store/actuals/actuals.selector";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { IActualsState } from "../../store/actuals/actuals.reducer";
import { IProjectDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { IState } from "../../store/reducers";
import {
    IUnitsViewModel,
    IUnitDemand,
    IUnitEditRequestDetails,
    IUnitTask,
} from "../../common/services/contracts/actuals.contracts";
import { ProjectService } from "../../common/services/project.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UpdateActualsLocal } from "../../store/actuals/actuals.action";
import moment from "moment";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../common/error.constants";
import {
    NgbModal,
    NgbModalRef,
    NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { ActualsTransactionModalComponent } from "./actuals-transaction-modal/actuals-transaction-modal.component";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";
// import { ActualsNotesModalComponent } from "./actuals-notes-modal/actuals-notes-modal.component";

/**
 * Actuals Component
 *
 * @export
 * @class ActualsComponent
 */
@Component({
    selector: "dm-units",
    templateUrl: "./actuals.html",
    styleUrls: ["./actuals.scss"],
})
export class ActualsComponent extends DmComponentAbstract {
    public consumedPercentage: number;
    public wbsId: string;
    public filterDataSource: FilterModel[];
    public isProjectContext: boolean;
    public maxDate: Date;
    public minDate: Date;
    public noActualsPostedText: string;
    public noActualsForUnitsEngagement: string;
    public originalUnits: IUnitDemand[];
    public selectedProject: FilterModel = new FilterModel(undefined, undefined);
    public showConsumedQuantityError: boolean = false;
    public showNegativeConsumedQuanityError: boolean = false;
    public unitsViewModelList: IUnitsViewModel[];
    public updatedUnits: IUnitDemand[] = [];
    public actualsSaveBtnDisabled: boolean = true;
    public loadingText: string = "Loading units";
    public currencySymbol: string;
    public isUnitsBasedEngagement: boolean;
    public hasEditPermissions: boolean;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.Units;
    public selectedDefaultDate: NgbDateStruct;
    public ngbMinDate: NgbDateStruct;
    public ngbMaxDate: NgbDateStruct;
    public accessibilityConstants = AccessibilityConstants;
    public arrayForRepeatingElement = [];
    private readonly FXP_CONSTANTS = FxpConstants;
    /**
     * Creates an instance of ActualsComponent.
     * @param {DeviceFactoryProvider} deviceFactory
     * @memberof ActualsComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider))
        public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpEventBroadCastService))
        public fxpEventBroadcastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => FxpMessageService))
        private fxpMessageService: FxpMessageService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(StateService) private stateService: StateService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService)
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Components.Actuals, []);
    }

    public ngOnInit(): void {
        const numberOfElementsToRepeat = 13;
        this.arrayForRepeatingElement = new Array(numberOfElementsToRepeat);
        const engagementId = this.sharedFunctionsService.getSelectedEngagementId(
            this.stateService
        );
        const projectId = this.sharedFunctionsService.getSelectedProjectId(
            this.stateService
        );
        this.isProjectContext = projectId ? true : false;
        this.wbsId = this.isProjectContext ? projectId : engagementId;
        this.errorText = this.isProjectContext
            ? ComponentFailureMessages.ProjectActualsComponent
            : ComponentFailureMessages.EngagementActualsComponent;
        this.noActualsPostedText =
            NoDataText.NoUnits + (projectId ? "project." : "engagement.");
        this.noActualsForUnitsEngagement = NoDataText.NoActualsForUnitsEngagement;
        this.tileContent = {
            title: "Units",
        };
        this.selectedDefaultDate =
            this.sharedFunctionsService.convertDateToNgbDateStruct(new Date());

        const engagementDetails$ = this.store.select(
            getEntireEngagementDetails(this.wbsId)
        );
        const actuals$ = this.store.select(getEntireActuals(this.wbsId));
        this.storeDispatchService
            .requireEngagementDetails(this.wbsId, true)
            .requireActuals(this.wbsId, true)
            .load();

        observableCombineLatest(
            engagementDetails$,
            actuals$,
            (engagementDetails: IEngagementDetailsState, actuals: IActualsState) => ({
                engagementDetails,
                actuals,
            })
        )
            .pipe(untilDestroyed(this))
            .subscribe(({ engagementDetails, actuals }) => {
                if (engagementDetails.loaded) {
                    const engagementDetailsResult = engagementDetails.engagementDetails;
                    this.currencySymbol = engagementDetailsResult.currency;
                    this.isUnitsBasedEngagement =
                        engagementDetailsResult.hasUnitBasedDemands;
                    const currentDate = new Date();
                    this.maxDate = currentDate;
                    this.minDate = engagementDetails.engagementDetails.startDate;
                    /* In edge cases/data issues, if the engagement start date is in the future, set the min
                                    and max date both to the future engagement start date. */
                    if (moment(this.maxDate).isBefore(this.minDate)) {
                        this.maxDate = this.minDate;
                    }
                    this.ngbMinDate =
                        this.sharedFunctionsService.convertDateToNgbDateStruct(
                            this.minDate
                        );
                    this.ngbMaxDate =
                        this.sharedFunctionsService.convertDateToNgbDateStruct(
                            this.maxDate
                        );
                    if (!this.isProjectContext) {
                        this.hasEditPermissions =
                            engagementDetailsResult &&
                                engagementDetailsResult.canEditEnagagement && !(["DECO", "RECO"].includes(engagementDetailsResult.currentStatusCode))
                                ? engagementDetailsResult.canEditEnagagement
                                : false;
                    } else {
                        if (
                            engagementDetailsResult &&
                            engagementDetailsResult.projects &&
                            engagementDetailsResult.projects.length
                        ) {
                            const selectedProjectDetails =
                                engagementDetailsResult.projects.filter(
                                    (proj) => proj.id === projectId
                                )[0];
                            this.minDate = selectedProjectDetails.startDate;
                            this.hasEditPermissions =
                                selectedProjectDetails && selectedProjectDetails.canEditProject && !(["DECO", "RECO"].includes(selectedProjectDetails.currentStatusCode))
                                    ? selectedProjectDetails.canEditProject
                                    : false;
                        }
                    }

                    // Special case where edit permissions are disabled if start date is a future date than current date.
                    if (moment(engagementDetailsResult.startDate).isAfter(currentDate)) {
                        this.hasEditPermissions = false;
                    }
                    if (engagementDetailsResult && engagementDetailsResult.projects) {
                        this.filterDataSource = this.getFilterDropdownData(
                            engagementDetailsResult.projects.filter(
                                (project) => project.hasUnitBasedDemands
                            )
                        );
                        if (this.isProjectContext) {
                            this.selectedProject = this.filterDataSource.filter(
                                (proj) => proj.id === projectId
                            )[0];
                        } else {
                            this.selectedProject = this.filterDataSource.filter(
                                (proj) => proj.name === "All Projects"
                            )[0];
                        }
                    }
                }

                if (actuals.loaded) {
                    this.unitsViewModelList = actuals.units;
                    this.originalUnits = [];
                    this.updatedUnits = [];
                    if (this.unitsViewModelList && this.unitsViewModelList.length) {
                        for (const project of this.unitsViewModelList) {
                            project.isProjectExpanded = true;
                            for (const task of project.tasks) {
                                task.isTaskExpanded = true;
                                for (const unit of task.demands) {
                                    const unitCopy = Object.assign({}, unit);
                                    this.originalUnits.push(unitCopy);
                                    unit.consumedDate = new Date();
                                    if (
                                        !(
                                            unit.isDemandUpdateStatus &&
                                            unit.isDemandUpdateStatus.toLowerCase() === "error"
                                        )
                                    ) {
                                        const consumedPercentage =
                                            (unit.actualQuantity / unit.plannedQuantity) * 100;
                                        unit.consumedQuantityPercentage = consumedPercentage
                                            ? consumedPercentage
                                            : 0;
                                    }
                                    unit.isConsumedQuantityExceeded =
                                        unit.actualQuantity > unit.plannedQuantity ? true : false;
                                    unit.deliveredQuantity = unit.actualQuantity;
                                    unit.postingQuantity = 0; // setting to zero
                                }
                            }
                        }
                    }
                }

                this.refreshOnItemInvalidation(engagementDetails, actuals);
                this.setErrorsBasedOnItemState(engagementDetails, actuals);
                this.setLoadersBasedOnItemState(engagementDetails, actuals);
                if (
                    (engagementDetails.loaded && engagementDetails.error) ||
                    (actuals.loaded && actuals.error)
                ) {
                    this.isServerError = true;
                }
            });
    }

    /**
     * Updates and saves unit changes on button click.
     *
     * @returns {void}
     * @memberof ActualsComponent
     */
    public onSaveChangesClick(): void {
        if (this.updatedUnits && this.updatedUnits.length) {
            this.actualsSaveBtnDisabled = true;
            let saveChangesInProgress: boolean;
            const promiseArray = [];

            // Return if save changes are in progress i.e previous posted calls are in progress.
            if (saveChangesInProgress) {
                return;
            }

            for (let i = 0; i < this.updatedUnits.length; i++) {
                // Prepare updated unit request object
                const unitEditRequestDetails: IUnitEditRequestDetails = {
                    activityType: this.updatedUnits[i].unitActivityTypeCode,
                    demandId: this.updatedUnits[i].demandId,
                    quantity: this.updatedUnits[i].postingQuantity, // updatedActualQuantity - originalActualQuantity,
                    resourceItemId: this.updatedUnits[i].billingRoleId,
                    updatedDate: moment(this.updatedUnits[i].consumedDate).format("MM/DD/YYYY"),
                    /* Use this format as a moment object to remove the timezone. Moments do not need to have timezones, but a date does */
                    longText: this.updatedUnits[i].longText,
                };

                const originalUnit = this.originalUnits.filter(
                    (x) => x.demandId === unitEditRequestDetails.demandId
                )[0];
                this.unitsViewModelList.map((item) =>
                    item.tasks.map((unit) =>
                        unit.demands.map((demand) => {
                            if (demand.demandId === unitEditRequestDetails.demandId) {
                                demand.isDemandUpdateLoading = true;
                            }
                        })
                    )
                );
                const promise = this.projectService
                    .updateActuals(unitEditRequestDetails, this.updatedUnits[i].taskId)
                    .then(() => {
                        // Filtering the changed demand and updating the store
                        this.unitsViewModelList.map((item) =>
                            item.tasks.map((unit) =>
                                unit.demands.map((demand) => {
                                    if (demand.demandId === unitEditRequestDetails.demandId) {
                                        demand.deliveredQuantity =
                                            demand.deliveredQuantity + demand.postingQuantity;
                                        demand.actualQuantity = demand.deliveredQuantity;
                                        demand.isDemandUpdateLoading = false;
                                        demand.isDemandUpdateStatus = "Success";
                                        demand.totalActualCost =
                                            demand.unitCostRate * demand.deliveredQuantity;
                                        demand.demandUpdateMessage = `${demand.postingQuantity} Units have been updated`;
                                        demand.postingQuantity = 0;
                                    }
                                })
                            )
                        );
                        // updating actualQuantity locally after success
                        originalUnit.postingQuantity = 0; // updatedActualQuantity;
                        const successMessage = "Success: Units have been updated.";
                        this.fxpMessageService.addMessage(
                            successMessage,
                            this.FXP_CONSTANTS.messageType.success
                        );
                        // Remove the unit from updated units after success.
                        this.updatedUnits.splice(i, 1);
                        const nextId =
                            this.unitsViewModelList[0].projectId +
                            "-" +
                            this.unitsViewModelList[0].tasks[0].taskId +
                            "-consumed-quantity-0";
                        if (this.hasEditPermissions) {
                            this.sharedFunctionsService.focus(nextId, true);
                        } else {
                            this.sharedFunctionsService.focus(
                                "dm-tooltip-actualQuantity",
                                true
                            );
                        }
                    })
                    .catch((error) => {
                        this.unitsViewModelList.map((item) =>
                            item.tasks.map((unit) =>
                                unit.demands.map((demand) => {
                                    if (demand.demandId === unitEditRequestDetails.demandId) {
                                        demand.isDemandUpdateStatus = "Error";
                                        demand.isDemandUpdateLoading = false;
                                        if (
                                            error &&
                                            error.data &&
                                            error.data.InnerErrors &&
                                            error.data.InnerErrors.length &&
                                            error.data.InnerErrors[0].Messages[0]
                                        ) {
                                            this.logError(
                                                SourceConstants.Method.OnSaveChangesClick,
                                                error,
                                                error.data.InnerErrors[0].Messages[0],
                                                ErrorSeverityLevel && ErrorSeverityLevel.High
                                            );
                                            demand.demandUpdateMessage =
                                                "Error occured: " +
                                                error.data.InnerErrors[0].Messages[0];
                                        }
                                    }
                                })
                            )
                        );
                    });
                // Set flag if save changes are in progress
                saveChangesInProgress = true;
                promiseArray.push(promise);
            }

            Promise.all([...promiseArray])
                .then(() => {
                    this.store.dispatch(
                        new UpdateActualsLocal(this.wbsId, this.unitsViewModelList)
                    );
                    // disable save button after all the post calls are successfull.
                    saveChangesInProgress = false;
                    this.actualsSaveBtnDisabled = true;
                    this.selectedDefaultDate =
                        this.sharedFunctionsService.convertDateToNgbDateStruct(new Date());
                })
                .catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(
                        error,
                        "Unable to get the updates and saves unit changes."
                    );
                    this.logError(
                        SourceConstants.Method.OnSaveChangesClick,
                        error,
                        errorMessage,
                        ErrorSeverityLevel && ErrorSeverityLevel.High
                    );
                    this.store.dispatch(
                        new UpdateActualsLocal(this.wbsId, this.unitsViewModelList)
                    );
                    saveChangesInProgress = false;
                    this.actualsSaveBtnDisabled = false;
                    this.selectedDefaultDate =
                        this.sharedFunctionsService.convertDateToNgbDateStruct(new Date());
                });
        }
        const nextFocusableId =
            this.unitsViewModelList[0].projectId +
            "-" +
            this.unitsViewModelList[0].tasks[0].taskId +
            "-consumed-quantity-0";
        if (this.hasEditPermissions) {
            this.sharedFunctionsService.focus(nextFocusableId, true);
        } else {
            this.sharedFunctionsService.focus("dm-tooltip-actualQuantity", true);
        }
    }

    /**
     * This method is triggered when actual quantity is changed by user
     *
     * @param {number} projectIndex
     * @param {number} taskIndex
     * @param {number} unitIndex
     * @param {IUnitDemand} unit
     * @param {number} newValue
     * @memberof ActualsComponent
     */
    public onInputChange(unit: IUnitDemand, newValue: number): void {
        let originalUnit: IUnitDemand;
        if (this.originalUnits && this.originalUnits.length) {
            originalUnit = this.originalUnits.filter(
                (x) => x.demandId === unit.demandId
            )[0];
        }

        // Add a unit to updated units if all validations are true. Else add it to changed units to keep track
        // of changed units if it has validation error inorder to disable save btn.
        if (newValue !== null && newValue !== undefined && originalUnit) {
            this.actualsSaveBtnDisabled = false;
            unit.isConsumedQuantityExceeded =
                newValue + unit.deliveredQuantity > unit.plannedQuantity ? true : false;
            if (Math.sign(newValue) === -1) {
                unit.isLessThanDeliveredQuantity =
                    Math.abs(newValue) > unit.deliveredQuantity ? true : false;
            }
            this.addOrReplaceDemand(unit, this.updatedUnits);
        } else {
            this.actualsSaveBtnDisabled = true;
        }

        // Disables save btn even if one of changed unit has validation error.
        if (this.updatedUnits && this.updatedUnits.length) {
            // filtering the one having consumed quantity greater than the planned quantity
            const erroredUnits = this.updatedUnits.filter(
                (unitItem) => unitItem.isConsumedQuantityExceeded === true
            );
            if (erroredUnits.length) {
                this.actualsSaveBtnDisabled = true;
            }
            // reset the actual quantity if the consumed quantity greater than the planned quantity so that the original instance will not change
            for (const erroredUnit of erroredUnits) {
                const originalDemandUnit = this.originalUnits.filter(
                    (x) => x.demandId === erroredUnit.demandId
                )[0];
                if (originalDemandUnit) {
                    erroredUnit.actualQuantity = originalDemandUnit.actualQuantity;
                }
            }
        }

        for (const updatedUnit of this.updatedUnits) {
            if (
                updatedUnit.postingQuantity === 0 ||
                updatedUnit.isConsumedQuantityExceeded ||
                updatedUnit.isLessThanDeliveredQuantity
            ) {
                this.actualsSaveBtnDisabled = true;
                this.removeDemand(updatedUnit, this.updatedUnits);
            }
        }
        if (unit.isDemandUpdateStatus) {
            unit.isDemandUpdateStatus = undefined;
        }
    }

    /**
     * Opens unit transaction modal based on demandId
     *
     * @param {string} demandId
     * @memberof ActualsComponent
     */
    public openUnitTransactionsModal(unit: IUnitDemand, task: IUnitTask): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ActualsPage,
            SourceConstants.Method.OpenUnitTransactionsModal,
            LogEventConstants.UnitTransactionDetailsClicked
        );
        const modalRef: NgbModalRef = this.modalService.open(
            ActualsTransactionModalComponent,
            {
                backdrop: "static",
                centered: true,
                windowClass: "dm-modal-v2 unit-transactions-modal in active",
                injector: this.injector,
            }
        );
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.componentInstance.taskId = task.taskId;
        modalRef.componentInstance.demandId = unit.demandId;
        modalRef.componentInstance.taskName = task.taskName;
        modalRef.componentInstance.currency = this.currencySymbol;
    }

    public handleKeyup(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Opens unit notes modal where user can enter notes.
     *
     * @param {IUnitDemand} unit
     * @param {IUnitTask} task
     * @memberof ActualsComponent
     */
    // public openNotesModal(unit: IUnitDemand, task: IUnitTask): void {
    //     this.dmLogger.logEvent(SourceConstants.Component.ActualsPage, SourceConstants.Method.OpenUnitTransactionsModal, LogEventConstants.UnitTransactionDetailsClicked);
    //     const modalRef: NgbModalRef = this.modalService.open(ActualsNotesModalComponent, {
    //         backdrop: "static",
    //         centered: true,
    //         windowClass: "dm-modal-v2 unit-notes-modal in active",
    //         injector: this.injector
    //     });
    //     modalRef.componentInstance.wbsId = this.wbsId;
    //     modalRef.componentInstance.taskId = task.taskId;
    //     modalRef.componentInstance.currency = this.currencySymbol;
    //     modalRef.componentInstance.onSubmitNotes.subscribe((notes: string) => {
    //         unit.longText = notes;
    //     });
    // }

    /**
     * trackby function to improve performance of repeat looping on the UI
     *
     * @param {*} index
     * @param {*} item
     * @returns
     * @memberof ActualsComponent
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public trackByFunction(index: number, item: any): number {
        if (!item) {
            return null;
        }
        return index;
    }

    /**
     * method triggers on consumed date change
     *
     * @param {*} unit
     * @param {Date} updatedDate
     * @memberof ActualsComponent
     */
    public onUnitDateChange(unit: IUnitDemand, updatedDate: Date): void {
        const currentDate = new Date();
        if (updatedDate !== currentDate) {
            unit.consumedDate = new Date(updatedDate);
            this.addOrReplaceDemand(unit, this.updatedUnits);
        }
    }

    /**
     * Toggles tasks in the grid
     *
     * @param {*} unit
     * @memberof ActualsComponent
     */
    public toggleUnitTask(task: IUnitTask, id: string): void {
        task.isTaskExpanded = !task.isTaskExpanded;
        this.sharedFunctionsService.focus(id + task.taskId, true);
    }

    /**
     * Sets default date for all unit demands that can be posted.
     *
     * @param {*} defaultDate
     * @memberof ActualsComponent
     */
    public setDefaultDate(defaultDate: NgbDateStruct): void {
        if (defaultDate) {
            const convertedDateStruct: NgbDateStruct = {
                ...defaultDate,
                month: defaultDate.month - 1,
            };
            const date = moment(
                moment.utc(convertedDateStruct).toISOString(),
                "YYYY-MM-DD HH:mm"
            ).toDate();
            for (const project of this.unitsViewModelList) {
                for (const task of project.tasks) {
                    for (const unit of task.demands) {
                        unit.consumedDate = new Date(date);
                    }
                }
            }
        }
    }

    /**
     * Sets the selected date to today. Can be activated by clicking enter.
     */
    public setDateToToday(event?: KeyboardEvent): void {
        if (!event || (event && event.keyCode === 13)) {
            this.selectedDefaultDate =
                this.sharedFunctionsService.convertDateToNgbDateStruct(new Date());
        }
    }

    /**
     * Toggles projects in the grid
     *
     * @param {*} unit
     * @memberof ActualsComponent
     */
    public toggleUnitProject(project: IUnitsViewModel, id: string): void {
        project.isProjectExpanded = !project.isProjectExpanded;
        this.sharedFunctionsService.focus(id + project.projectId, true);
    }

    /**
     * Gets filter dropdown data for projects filter based on project data from engagement details.
     *
     * @private
     * @param {IProjectDetailsV2[]} projects
     * @returns {FilterModel[]}
     * @memberof ActualsComponent
     */
    private getFilterDropdownData(projects: IProjectDetailsV2[]): FilterModel[] {
        const projectNodes: FilterModel[] = [];
        for (const project of projects) {
            const projectNode = new FilterModel(project.name, project.id);
            projectNodes.push(projectNode);
        }
        const allProjectsPlaceHolderNode = new FilterModel(
            "All Projects",
            undefined
        );
        projectNodes.unshift(allProjectsPlaceHolderNode);
        return projectNodes;
    }

    /**
     * Removes unit demand from list of given array.
     *
     * @private
     * @param {IUnitDemand} unit
     * @memberof ActualsComponent
     */
    private removeDemand(unit: IUnitDemand, array: IUnitDemand[]): void {
        if (unit && array && array.length) {
            const index = array.findIndex((x) => x.demandId === unit.demandId);
            if (index >= 0) {
                array.splice(index, 1);
            }
        }
    }

    /**
     * Adds or replaces the unit demand to list of given array.
     *
     * @private
     * @param {IUnitDemand} unit
     * @memberof ActualsComponent
     */
    private addOrReplaceDemand(unit: IUnitDemand, array: IUnitDemand[]): void {
        if (unit && array && array.length === 0) {
            array.push(unit);
        }
        if (unit && array && array.length > 0) {
            const index = array.findIndex((x) => x.demandId === unit.demandId);
            if (index === -1) {
                array.push(unit);
            } else {
                array[index] = unit;
            }
        }
    }
}
