import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { FormsModule } from "@angular/forms";
import { AmendmentsService } from "../../../common/services/amendments.service";
import { ChangeExistingDemandModalModule } from "./modals/change-existing-demand/change-existing-demand.module";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { MisalignedAmendmentDetailsComponent } from "./misaligned-amendment-details.component";
import { MisalignedAmendmentLineitemsModule } from "./misaligned-amendment-lineitems/misaligned-amendment-lineitems.module";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ValidationFailedModalComponentModule } from "./modals/validation-failed-modal/validation-failed-modal.module";

@NgModule({
    declarations: [
        MisalignedAmendmentDetailsComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        NgbModule,
        UIRouterModule,
        FormsModule,
        ChangeExistingDemandModalModule,
        MisalignedAmendmentLineitemsModule,
        ValidationFailedModalComponentModule
    ],
    providers: [
        AmendmentsService,
        DMAuthorizationService,
        DMLoggerService,
        SharedFunctionsService,
    ],
    entryComponents: [
        MisalignedAmendmentDetailsComponent,
    ],
    exports: [
        MisalignedAmendmentDetailsComponent,
    ]
})
export class MisalignedAmendmentDetailsModule { }
