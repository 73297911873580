import { Action } from "@ngrx/store";
import { IWbsDemand } from "./../../common/services/contracts/project.service.v2.contracts";

// 1. action types
export enum DemandsActionTypes {
    LOAD_DEMANDS = "[Demands] Load",
    LOAD_DEMANDS_SUCCESS = "[Demands] Load Success",
    LOAD_DEMANDS_FAIL = "[Demands] Load Fail",
    INVALIDATE_DEMANDS = "[Demands] Invalidate"
}

// 2. action creators
export class LoadDemands implements Action {
    public readonly type = DemandsActionTypes.LOAD_DEMANDS;
    public constructor(public wbsId: string) { }
}

export class LoadDemandsSuccess implements Action {
    public readonly type = DemandsActionTypes.LOAD_DEMANDS_SUCCESS;
    public constructor(public wbsId: string, public wbsDemands: IWbsDemand) { }
}

export class LoadDemandsFail implements Action {
    public readonly type = DemandsActionTypes.LOAD_DEMANDS_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateDemands implements Action {
    public readonly type = DemandsActionTypes.INVALIDATE_DEMANDS;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type DemandsActions =
    | LoadDemands
    | LoadDemandsSuccess
    | LoadDemandsFail
    | InvalidateDemands;