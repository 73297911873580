import { Action } from "@ngrx/store";
import { IEngagementList, IPinnedEntities } from "../../../common/services/contracts/portfolio.model";
import { IWbsEditEngagementDetails } from "../../../common/services/contracts/wbs.service.contracts";

// 1. action types
export enum MyPortfolioActionTypes {
    LOAD_MY_PORTFOLIO_ENGAGEMENTS = "[MyPortfolio] Load",
    LOAD_MY_PORTFOLIO_ENGAGEMENTS_SUCCESS = "[My Portfolio] Load Success",
    UPDATE_LOCALLY_MY_PORTFOLIO_ENGAGEMENTS = "[MyPortfolio] Update Locally",
    LOAD_MY_PORTFOLIO_ENGAGEMENTS_FAIL = "[My Portfolio] Load Fail",
    INVALIDATE_MY_PORTFOLIO_ENGAGEMENTS = "[My Portfolio] Invalidate"
}

// 2. action creators
export class LoadMyPortfolioEngagements implements Action {
    public readonly type = MyPortfolioActionTypes.LOAD_MY_PORTFOLIO_ENGAGEMENTS;
    public constructor(public loadFromCache: boolean) { }
}

export class LoadMyPortfolioEngagementsSuccess implements Action {
    public readonly type = MyPortfolioActionTypes.LOAD_MY_PORTFOLIO_ENGAGEMENTS_SUCCESS;
    public constructor(public engagementList: IEngagementList[], public pinnedEntities: IPinnedEntities) { }
}

export class UpdateLocallyMyPortfolioEngagements implements Action {
    public readonly type = MyPortfolioActionTypes.UPDATE_LOCALLY_MY_PORTFOLIO_ENGAGEMENTS;
    public constructor(public engagementId: string, public editEngagementDetails: IWbsEditEngagementDetails) { }
}

export class LoadMyPortfolioEngagementsFail implements Action {
    public readonly type = MyPortfolioActionTypes.LOAD_MY_PORTFOLIO_ENGAGEMENTS_FAIL;
    public constructor(public errorMessage: string) { }
}

export class InvalidateMyPortfolioEngagements implements Action {
    public readonly type = MyPortfolioActionTypes.INVALIDATE_MY_PORTFOLIO_ENGAGEMENTS;
}

// 3. export the union creators
export type MyPortfolioAction =
    | LoadMyPortfolioEngagements
    | UpdateLocallyMyPortfolioEngagements
    | LoadMyPortfolioEngagementsSuccess
    | LoadMyPortfolioEngagementsFail
    | InvalidateMyPortfolioEngagements;