import { Inject, Injectable } from "@angular/core";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import {
    IGrmForecastAggregateHoursResponse,
    IGrmGetForecastRequest,
    IGrmGetForecastResponse,
    IGrmForecastAggregateHoursRequest,
} from "./contracts/dmgrm.contracts";
import { IEngagementList } from "./contracts/portfolio.model";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class GrmForecastService extends DmServiceAbstract {
    private grmBaseUri: string;
    private grmSubscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService)
        private configManagerService: ConfigManagerService
    ) {
        super(dmLogger, Services.GrmForecastService);
        this.grmSubscriptionKey = this.configManagerService.getValue<string>(
            "grmForecastSubscriptionKey"
        );
        this.grmBaseUri = this.configManagerService.getValue<string>("grmForecastBaseUrl");
    }

    /**
     * Get GRM forecast with project details.
     *
     * @param {IGrmGetForecastRequest} requestPayload
     * @returns {Promise<IGrmGetForecastResponse>}
     * @memberof GrmForecastService
     */
    public getForecastWithProjectDetails(
        requestPayload: IGrmGetForecastRequest
    ): Promise<IGrmGetForecastResponse> {
        const url = `${this.grmBaseUri}/Resource/GetForecastWithProjectDetails`;
        return this.dataService.postData(
            url,
            this.grmSubscriptionKey,
            APIConstants.GetForecastWithProjectDetails,
            requestPayload
        );
    }

    /**
     * Get total/aggregate hours for resources in payload.
     *
     * @param {IGrmForecastAggregateHoursRequest} requestPayload
     * @returns {Promise<IGrmForecastAggregateHoursResponse>}
     * @memberof GrmForecastService
     */
    public getTotalForecastedHours(
        requestPayload: IGrmForecastAggregateHoursRequest
    ): Promise<IGrmForecastAggregateHoursResponse> {
        const url = `${this.grmBaseUri}/Resource/GetAggregateForecastHours`;
        return this.dataService.postData(
            url,
            this.grmSubscriptionKey,
            APIConstants.GetForecastWithProjectDetails,
            requestPayload
        );
    }


    /**
     * Get project name and customer name for a confidential engagement in current user's engagement list.
     * If it is not in user's list return null.
     *
     * @param {string} projectId
     * @param {IEngagementList[]} engagementList
     * @returns {{ projectName: string; customerName: string }}
     * @memberof GrmForecastService
     */
    public getConfidentialProjectDataFromUserList(projectId: string, engagementList: IEngagementList[]): { projectName: string; customerName: string } {
        for (const engagement of engagementList) {
            const selectedProject = engagement.projects.filter((project) => project.projectId === projectId);

            if (selectedProject.length) {
                return {
                    projectName: selectedProject[0].projectName,
                    customerName: selectedProject[0].customerName
                };
            }
        }

        return null;
    }
}
