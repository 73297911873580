import { Injectable, Inject } from "@angular/core";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class CapAmountService extends DmServiceAbstract {
    private capAmountSubscriptionKey: string;
    private capAmountServiceBaseUri: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.CapAmountService );
        this.capAmountServiceBaseUri = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.capAmountSubscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }

    /**
     * Get Cap Amount details via get request to the Project Management API
     * @param engagementId
     */
    public getCapAmountDetails(engagementId: string): Promise<any> {
        return this.dataService.getData(`${this.capAmountServiceBaseUri}/wbs/${engagementId}/capamount`,
            this.capAmountSubscriptionKey, APIConstants.CapAmount);
    }

}
