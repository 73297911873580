<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'300px'" [showFooter]="false">
    <div class="team-structure-grid" *ngFor="let teamContact of teamContacts">
        <div class="team-structure-grid__header" *ngIf="teamContact.contacts.length">
            <a *ngIf="!teamContact.isInternalEntity" [uiSref]="teamContact.entityType === 'Engagement'? RouteName.EngagementSummaryV2 : RouteName.ProjectSummaryV2"
                class="dm-entity__name" (click)="closeModal()" [uiParams]="teamContact.entityType === 'Engagement' ? {engagementId: teamContact.entityId} : {projectId: teamContact.entityId}">
                {{teamContact?.entityName}}
            </a>
            <a *ngIf="teamContact.isInternalEntity" class="dm-entity__name" [uiSref]="RouteName.InternalEngagementSummary"
                [uiParams]="internalEngagementSummaryRouteParams" (click)="closeModal()">
                {{teamContact?.entityName}}
            </a>
            <div class="dm-entity__id">EBS: {{teamContact?.entityId}}
            </div>
        </div>
        <div class="team-structure-grid__row">
            <ng-container *ngFor="let item of teamContact.contacts">
                <div class="team-structure-grid__contact">
                    <dm-person-card [personDetails]="item.personDetails" [personImage]="item.image" [avatarOnly]="true" [personCardLabel]="teamContact.entityType + '-' + teamContact.entityId"></dm-person-card>
                    <div *ngIf="item?.alias" class="team-structure-grid__contact-details">
                        <span class="dm-form-entity__label">{{item?.name | dmDisplayDashIfEmptyOrNull}}
                            ({{item?.alias | dmDisplayDashIfEmptyOrNull}})</span>
                        <span class="dm-form-entity__value">{{item?.role | dmDisplayDashIfEmptyOrNull}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div tabindex="0" class="focus-trap" aria-hidden="true"></div>
</dm-modal-v2>