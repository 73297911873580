import { Injectable, Inject, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { IInlineSurveyConfigData } from "../../shared/inline-survey/inline-survey-config.model";
import { FxpPjmTelemetryName, FeedBackEntity, LogEventConstants, SourceConstants } from "../../../common/application.constants";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FeedbackModalComponent } from "./feedback-modal.component";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { UserPreferenceService } from "../../../common/services/userpreferences.service";
import { IState } from "../../../store/reducers";
import { getEntireUserPreference } from "../../../store/userspreferences/userpreference.selector";
import { IUserPreferenceState } from "../../../store/userspreferences/userpreference.reducer";
import { UpdateUserPreference } from "../../../store/userspreferences/userpreference.action";


@Injectable()
export class FeedbackModalService {

    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(Store) private store: Store<IState>,
        @Inject(DMLoggerService) private dmLogger: DMLoggerService,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
    ) {
    }

    public openFeedbackModal(surveyName: string, entityName: FeedBackEntity, component: string): NgbModalRef {
        this.dmLogger.logEvent(component, SourceConstants.Method.OpenFeedbackModal, LogEventConstants.FeedbackClick);
        const surveyConfigData: IInlineSurveyConfigData = this.configManagerService.getValue<IInlineSurveyConfigData>(surveyName);
        const surveyData = {
            title: surveyConfigData.surveyTitle,
            partnerTelemetryName: FxpPjmTelemetryName,
            surveyQuestion: surveyConfigData.surveyQuestion,
            isEmailConsentRequired: true,
            surveyId: surveyConfigData.surveyId,
            feedbackManifestType: surveyConfigData.feedbackManifestType,
            feedbackType: surveyConfigData.feedbackType
        };
        this.updateUserPreference(entityName);
        const surveyModalRef: NgbModalRef = this.modalService.open(FeedbackModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal in active inline-survey-modal",
            keyboard: true,
            centered: true,
            ariaLabelledBy: "modal-title"
        });

        surveyModalRef.componentInstance.feedbackData = surveyData;
        surveyModalRef.componentInstance.feedbackEntity = entityName;

        return surveyModalRef;
    }

    /**
     * Updates userpreference store ab
     * @param event
     * @param id
     */
    public updateUserPreference(entityName: FeedBackEntity): void {
        const userPreference$ = this.store.select((getEntireUserPreference()));
        userPreference$.take(1).subscribe((userPreferenceState: IUserPreferenceState) => {
            if (userPreferenceState.loaded) {
                const userPreference = userPreferenceState.userPreference;
                if (!userPreference.feedbackList) {
                    userPreference.feedbackList = [];
                }
                let isEntityFeedbackSubmitted: boolean = false;
                const feedBackDetails = userPreferenceState.userPreference.feedbackList;
                if (feedBackDetails && feedBackDetails.length > 0 && feedBackDetails.filter((obj) => obj.key === entityName).length > 0) {
                    isEntityFeedbackSubmitted = feedBackDetails.filter((obj) => obj.key === entityName)[0].value;
                }
                if (!isEntityFeedbackSubmitted) {
                    if (userPreference.feedbackList.filter((obj) => obj.key === entityName).length > 0) {
                        userPreference.feedbackList.filter((obj) => obj.key === entityName)[0].value = true;
                    } else {
                        userPreference.feedbackList.push({
                            key: entityName,
                            value: true
                        });
                    }
                    this.userPreferenceService.saveUserPreference(userPreference).then((response) => {
                        this.store.dispatch(new UpdateUserPreference(response));
                    });
                }

            }
        });
    }
}
