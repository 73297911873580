
import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject } from "@angular/core";
import { DeviceFactoryProvider, FxpEventBroadCastService, FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { Components, ComponentFailureMessages, NoDataText, SourceConstants, AccessibilityConstants } from "../../common/application.constants";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DmError } from "../../common/error.constants";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FilterModel } from "../tiles/project-filter/project-filter.model";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { getEntireNpcActuals } from "../../store/npc-actuals/npc-actuals.selector";
import { IEngagementDetailsApiV2, IProjectDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { INpcActualsState } from "../../store/npc-actuals/npc-actuals.reducer";
import { INpcProjectActualsViewModel, INpcResourceActualsViewModel, INpcActualsUpdateObject, INpcTaskActualsViewModel } from "../../common/services/contracts/npc.contract";
import { IState } from "../../store/reducers";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { IUnitsViewModel, IUnitTask } from "../../common/services/contracts/actuals.contracts";
import { NpcActualsService } from "../../common/services/npcActuals.service";
import { ProjectService } from "../../common/services/project.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UpdateNpcActualsLocal } from "../../store/npc-actuals/npc-actuals.action";
import moment from "moment";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";

/**
 * Non-Procured Materials Component/NPC
 *
 * @export
 * @class NonProcuredMaterialsComponent
 */
@Component({
    selector: "dm-non-procured-materials",
    templateUrl: "./non-procured-materials.html",
    styleUrls: ["./non-procured-materials.scss"],
})
export class NonProcuredMaterialsComponent extends DmComponentAbstract {
    public filterDataSource: FilterModel[];
    public isProjectContext: boolean;
    public maxDate: Date;
    public minDate: Date;
    public noNpcActualsPostedText: string;
    public selectedProject: FilterModel = new FilterModel(undefined, undefined);
    public saveBtnDisabled: boolean = true;
    public loadingText: string = "Loading non-procured materials";
    public currencySymbol: string;
    public npcActualsList: INpcProjectActualsViewModel[];
    public hasEditPermissions: boolean;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.Npc;
    public npcErrorMessages = DmError.NpcComponent;
    public consumedQuantityTooltipText: string;
    public accessibilityConstants = AccessibilityConstants;
    private readonly FXP_CONSTANTS = FxpConstants;
    private wbsId: string;
    private originalResourceActuals: INpcResourceActualsViewModel[] = [];
    private updatedNpcResourceActuals: INpcResourceActualsViewModel[] = [];

    /**
     * Creates an instance of Non-procured materials component/NPC actuals component
     * @param {DeviceFactoryProvider} deviceFactory
     * @memberof ActualsComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpEventBroadCastService)) public fxpEventBroadcastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NpcActualsService) private npcActualsService: NpcActualsService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(dmLogger, Components.NonProcuredMaterials);
    }

    public ngOnInit(): void {
        const engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = projectId ? true : false;
        this.wbsId = this.isProjectContext ? projectId : engagementId;
        this.errorText = this.isProjectContext ? ComponentFailureMessages.ProjectNpcActualsComponent : ComponentFailureMessages.EngagementNpcActualsComponent;
        this.noNpcActualsPostedText = NoDataText.NoNpcActials + (this.isProjectContext ? "project." : "engagement.");
        this.tileContent = {
            title: "Non-Procured Materials"
        };
        this.consumedQuantityTooltipText = "Cummulative Quantity"; // todo: update tooltip message once given
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.wbsId));
        const npcActuals$ = this.store.select(getEntireNpcActuals(this.wbsId));
        this.storeDispatchService
            .requireEngagementDetails(this.wbsId, true)
            .requireNpcActuals(this.wbsId, true)
            .load();

        observableCombineLatest(
            engagementDetails$,
            npcActuals$,
            (
                engagementDetails: IEngagementDetailsState,
                npcActuals: INpcActualsState
            ) => ({
                engagementDetails,
                npcActuals
            })
        ).pipe(untilDestroyed(this)).subscribe(({
            engagementDetails,
            npcActuals
        }) => {
            if (engagementDetails.loaded) {
                const engagementDetailsResult: IEngagementDetailsApiV2 = engagementDetails.engagementDetails;
                this.currencySymbol = engagementDetailsResult.currency;
                this.maxDate = new Date(); /* Set to today */
                this.minDate = engagementDetails.engagementDetails.startDate;
                /* In edge cases/data issues, if the engagement start date is in the future, set the min
                and max date both to the future engagement start date. */
                if (moment(this.maxDate).isBefore(this.minDate)) {
                    this.maxDate = this.minDate;
                }

                /* Checks if user is in team structure and allows edit permissions else read only. */
                if ((this.dmAuthorizationService.isUserInEngagementLevelTeam(engagementDetailsResult) || this.dmAuthorizationService.isUserInProjectLevelTeam(engagementDetailsResult.projects.filter((proj) => proj.id === projectId)[0]))) {
                    if (!["DECO", "RECO"].includes(engagementDetailsResult.currentStatusCode)){
                        this.hasEditPermissions = true;
                    }
                    else{
                        this.hasEditPermissions = false;
                    }
                    
                } else {
                    this.hasEditPermissions = false;
                }
                if (engagementDetailsResult && engagementDetailsResult.projects) {
                    this.filterDataSource = this.getFilterDropdownData(engagementDetailsResult.projects);
                    if (this.isProjectContext) {
                        this.selectedProject = this.filterDataSource.filter((proj) => proj.id === projectId)[0];
                    } else {
                        this.selectedProject = this.filterDataSource.filter((proj) => proj.name === "All Projects")[0];
                    }
                }
                /* NPC actuals will not be visible until eng is released */
                if (engagementDetailsResult.statusCode === "CRTD") {
                    this.noNpcActualsPostedText = NoDataText.NoNpcActualsForUnreleasedEngagement;
                }
            }

            if (npcActuals.loaded) {
                this.npcActualsList = this.npcActualsService.formatNpcActualsViewModel(npcActuals.npcActuals);
                this.originalResourceActuals = this.npcActualsService.originalResourceActuals;
            }

            this.refreshOnItemInvalidation(engagementDetails, npcActuals);
            this.setErrorsBasedOnItemState(engagementDetails, npcActuals);
            this.setLoadersBasedOnItemState(engagementDetails, npcActuals);
            if ((engagementDetails.loaded && engagementDetails.error) || (npcActuals.loaded && npcActuals.error)) {
                this.isServerError = true;
            }
        });
    }

    /**
     * Updates and saves unit changes on button click.
     *
     * @returns {void}
     * @memberof ActualsComponent
     */
    public onSaveChangesClick(): void {
        this.saveBtnDisabled = true;

        let saveChangesInProgress: boolean;
        const promiseArray = [];
        /* Setting time to 00:00:00 because local time is being converted to utc by http client methods
        resulting date sync issues and passing date only which is required */
        const currentDate: Date = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();

        /* Return if save changes are in progress i.e previous posted calls are in progress. */
        if (saveChangesInProgress) {
            return;
        }

        for (let i = 0; i < this.updatedNpcResourceActuals.length; i++) {
            const currActual: INpcResourceActualsViewModel = this.updatedNpcResourceActuals[i];
            const originalActualQuantity: number = this.originalResourceActuals.filter((x) => this.npcActualsService.doResourceActualsMatch(x, currActual))[0].actualQuantity;
            const updatedActualQuantity: number = Number(parseFloat(currActual.actualQuantity.toString()).toFixed(2));
            const updatedUnitDate = moment(currActual.consumedDate).isSame(currentDate, "day") ? currentDate : currActual.consumedDate;
            const updatedQuantity = updatedActualQuantity - originalActualQuantity;
            const formattedUpdatedQuantity = parseFloat(updatedQuantity.toFixed(2));
            /* Prepare updated actual request object */
            const updateObject: INpcActualsUpdateObject = {
                wbsId: currActual.taskId,
                quantity: formattedUpdatedQuantity, /* API wants the delta of the quantities */
                costRate: currActual.plannedCostRate,  /* submit the original costrate from the API response. This is not editable. Do not submit 0, API will throw an error.  */
                resourceId: currActual.resourceId,
                dateTime: updatedUnitDate
            };

            const originalActual = this.originalResourceActuals.filter((x) => this.npcActualsService.doResourceActualsMatch(x, currActual))[0];
            /* Takes the actual that is being updated and then flags it as loading */
            this.npcActualsList.map((proj: INpcProjectActualsViewModel) => proj.tasks.map((task: INpcTaskActualsViewModel) => task.actuals.map((actual: INpcResourceActualsViewModel) => {
                if (this.npcActualsService.doResourceActualsMatch(actual, updateObject)) {
                    actual.isUpdateLoading = true;
                }
            })));

            const promise = this.projectService.updateNpcActuals(updateObject, this.wbsId).then(() => {
                /* Filtering the changed actual and updating the store */
                this.npcActualsList.map((proj: INpcProjectActualsViewModel) => proj.tasks.map((task: INpcTaskActualsViewModel) => task.actuals.map((actual: INpcResourceActualsViewModel) => {
                    if (this.npcActualsService.doResourceActualsMatch(actual, updateObject)) {
                        actual.actualQuantity = updatedActualQuantity;
                        actual.isUpdateLoading = false;
                        actual.updateStatus = "Success";
                        actual.updateMessage = `NPC Actual has been updated from ${originalActual.actualQuantity} to ${updatedActualQuantity}`;
                        actual.totalActualCost = actual.actualQuantity * actual.plannedCostRate;
                    }
                })));
                /* updating actualQuantity locally after success */
                originalActual.actualQuantity = updatedActualQuantity;
                const successMessage = "Success: NPC Actuals have been updated.";
                this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                /* Remove the actual from updated list after success. */
                this.updatedNpcResourceActuals.splice(i, 1);
            }).catch((error) => {
                this.npcActualsList.map((proj: INpcProjectActualsViewModel) => proj.tasks.map((task: INpcTaskActualsViewModel) => task.actuals.map((actual: INpcResourceActualsViewModel) => {
                    if (this.npcActualsService.doResourceActualsMatch(actual, updateObject)) {
                        actual.updateStatus = "Error";
                        actual.isUpdateLoading = false;
                        if (error && error.data && error.data.InnerErrors && error.data.InnerErrors.length && error.data.InnerErrors[0].Messages[0]) {
                            this.logError(SourceConstants.Method.OnSaveChangesClick, error, error.data.InnerErrors[0].Messages[0], ErrorSeverityLevel && ErrorSeverityLevel.High);
                            actual.updateMessage = "Error occured: " + error.data.InnerErrors[0].Messages[0];
                        }
                    }
                })));
            });
            /* Set flag if save changes are in progress */
            saveChangesInProgress = true;
            promiseArray.push(promise);
        }

        Promise.all([...promiseArray])
            .then(() => {
                this.store.dispatch(new UpdateNpcActualsLocal(this.wbsId, this.npcActualsList));
                /* disable save button after all the post calls are successfull. */
                saveChangesInProgress = false;
                this.saveBtnDisabled = true;
            })
            .catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.OnSaveChangesClick, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.store.dispatch(new UpdateNpcActualsLocal(this.wbsId, this.npcActualsList));
                saveChangesInProgress = false;
                this.saveBtnDisabled = false;
            });
    }

    /**
     * This method is triggered when actual quantity is changed by user
     *
     * @param {number} projectIndex
     * @param {number} taskIndex
     * @param {number} unitIndex
     * @param {IUnitDemand} unit
     * @param {number} newValue
     * @memberof ActualsComponent
     */
    public onInputChange(actual: INpcResourceActualsViewModel, newValue: number): void {
        let originalResourceActual: INpcResourceActualsViewModel;
        if (this.originalResourceActuals && this.originalResourceActuals.length) {
            originalResourceActual = this.originalResourceActuals.filter((x: INpcResourceActualsViewModel) => this.npcActualsService.doResourceActualsMatch(x, actual))[0];
        }
        /* Add an actual to the updated actuals list if all validations are true. 
        Else add it to changed units to keep track of changed units if it has validation error in order to disable save btn. */
        /* Check newValue for null and undefined because 0 is a valid input */
        if (newValue !== null && newValue !== undefined && originalResourceActual) {
            actual.isConsumedQuantityExceeded = newValue > actual.plannedQuantity;
            if (newValue > originalResourceActual.plannedQuantity) {
                /* If new value is more than planned quantity, disallow save */
                this.saveBtnDisabled = true;
            } else if (newValue !== originalResourceActual.actualQuantity) {
                this.addOrReplaceResourceActual(actual, this.updatedNpcResourceActuals);
                this.saveBtnDisabled = false;
            } else if (newValue === originalResourceActual.actualQuantity) {
                /* The value is set back to the original, so remove it from the updated list */
                this.removeResourceActual(actual, this.updatedNpcResourceActuals);
                this.saveBtnDisabled = (this.updatedNpcResourceActuals && this.updatedNpcResourceActuals.length) ? false : true;
            }
        } else {
            this.saveBtnDisabled = true;
        }

        if (actual.updateStatus) {
            actual.updateStatus = undefined;
        }
    }

    /**
     * trackby function to improve ng for functionality on the UI
     *
     * @param {*} index
     * @param {*} item
     * @returns
     * @memberof ActualsComponent
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public trackByFunction(index: number, item: any): number {
        if (!item) {
            return null;
        }
        return index;
    }

    /**
     * Triggered when the date is changed for an actual via the datepicker on the UI.
     * If the date has been changed to a new date, then add the actual to the updated actuals list.
     * Date change will inly trigger the save changes button if the consumed quantity has been changed as well.
     *
     * @param {*} unit
     * @param {Date} updatedDate
     * @memberof ActualsComponent
     */
    public onActualDateChange(actual: INpcResourceActualsViewModel, updatedDate: Date): void {
        let originalActual: INpcResourceActualsViewModel;
        if (this.originalResourceActuals && this.originalResourceActuals.length) {
            originalActual = this.originalResourceActuals.filter((x: INpcResourceActualsViewModel) => this.npcActualsService.doResourceActualsMatch(x, actual))[0];
        }
        const currentDate = new Date();
        if ((updatedDate !== currentDate) && originalActual && (originalActual.actualQuantity !== actual.actualQuantity)) {
            actual.consumedDate = new Date(updatedDate);
            this.addOrReplaceResourceActual(actual, this.updatedNpcResourceActuals);
        }
    }

    /**
     * Toggles tasks in the grid
     *
     * @param {*} unit
     * @memberof ActualsComponent
     */
    public toggleUnitTask(task: IUnitTask, id: string): void {
        task.isTaskExpanded = !task.isTaskExpanded;
        this.sharedFunctionsService.focus(id + task.taskId, true);
    }

    /**
     * Toggles projects in the grid
     *
     * @param {*} unit
     * @memberof ActualsComponent
     */
    public toggleUnitProject(project: IUnitsViewModel, id: string): void {
        project.isProjectExpanded = !project.isProjectExpanded;
        this.sharedFunctionsService.focus(id + project.projectId, true);
    }

    /**
     * On selected project change, gets the selected project.
     */
    public onSelectedProjectChanged(selectedProject: FilterModel): void {
        this.selectedProject = selectedProject;
    }

    /**
     * Gets filter dropdown data for projects filter based on project data from engagement details.
     *
     * @private
     * @param {IProjectDetailsV2[]} projects
     * @returns {FilterModel[]}
     * @memberof ActualsComponent
     */
    private getFilterDropdownData(projects: IProjectDetailsV2[]): FilterModel[] {
        const projectNodes: FilterModel[] = [];
        for (const project of projects) {
            const projectNode = new FilterModel(project.name, project.id);
            projectNodes.push(projectNode);
        }
        const allProjectsPlaceHolderNode = new FilterModel("All Projects", undefined);
        projectNodes.unshift(allProjectsPlaceHolderNode);
        return projectNodes;
    }

    /**
     * Removes the resource actual from the given array. 
     * Used to remove items from the updated array whent he item has been changed back to its original state
     *
     * @private
     * @param {IUnitDemand} unit
     * @memberof ActualsComponent
     */
    private removeResourceActual(actual: INpcResourceActualsViewModel, array: INpcResourceActualsViewModel[]): void {
        if (actual && array && array.length) {
            const index = array.findIndex((x: INpcResourceActualsViewModel) => this.npcActualsService.doResourceActualsMatch(x, actual));
            if (index >= 0) {
                array.splice(index, 1);
            }
        }
    }

    /**
     * Adds ore replaces the resource actual from the given array.
     * Used to add newly updated items to the updated array.
     *
     * @private
     * @param {IUnitDemand} unit
     * @memberof ActualsComponent
     */
    private addOrReplaceResourceActual(actual: INpcResourceActualsViewModel, array: INpcResourceActualsViewModel[]): void {
        if (actual && array && array.length === 0) {
            array.push(actual);
        }
        if (actual && array && array.length > 0) {
            const index = array.findIndex((x: INpcResourceActualsViewModel) => this.npcActualsService.doResourceActualsMatch(x, actual));
            if (index === -1) {
                array.push(actual);
            } else {
                array[index] = actual;
            }
        }
    }
}
