
import { combineLatest as observableCombineLatest } from "rxjs";

import { take } from "rxjs/operators";
import { Component, forwardRef, Inject, ViewChild, Injector } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService, UserProfileService } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";

import moment from "moment";
// import { isArray } from "util";

/// NGRX Imports
import { IState } from "../../store/reducers";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";

import { ResourceStaffingService } from "../../common/services/resource-staffing.service";
import { AADGraphService } from "../../common/services/aad-graphapi.service";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FaultControlService } from "../../common/services/faultcontrol.service";
import { ICostRateApiOutput, ICostRateInput, IDemandDetail, IProjectDetails, IResource, IResourceDetail, IEngagementDetails } from "../../common/services/contracts/project.service.contracts";
import { IProjectRequest, IResourceRequest, IResourceRequestForGRMInput, IResourceRequestResponse } from "../../common/services/contracts/staffing.service.contract";
import { IOnSave } from "../../common/services/contracts/financial.service.contracts";
import { IPurchaseOrder } from "../../common/services/contracts/po.service.contracts";
import { IResourceAcceptDecline, IResourceCostRate, ISelectedDemand, IStaffingDemandModel, IStaffingResource, IStaffingTaskModel, IStaffingViewModel } from "../../common/services/contracts/staffing-details.contract";
import { IWbsDemand, IDemandDetails, IResourceData } from "../../common/services/contracts/project.service.v2.contracts";
import { LogEventConstants, ResourceRequestStatus, SAPApiResponseResourceDetailTypeFilters, SourceConstants, Components, NavHeight } from "../../common/application.constants";
import { ProjectService } from "../../common/services/project.service";
import { ResourceManagementModalComponent } from "./resource-management-modal/resource-management-modal.component";
import { ResourceRejectionModalComponent } from "./resource-rejection-modal/resource-rejection-modal.component";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { StaffingService } from "../../common/services/staffing.service";
import { CacheService } from "../../common/services/cache.service";
import { OneProfileService } from "../../common/services/one-profile.service";
/// Imports from Store
import { getEntireResourceRequestsProjectContextStateObject } from "../../store/resource-requests-project-context/resource-requests-project-context.selector";
import { getDemandsState } from "../../store/demands/demands.selector";
import { getInitialWbsStructures } from "../../store/wbs-structures/wbs-structures.selector";
import { IResourceRequestsDetailsProjectState } from "../../store/resource-requests-project-context/resource-requests-project-context.reducer";
import { IStaffingPlannedRequestModel } from "../../common/services/contracts/staffing-filter.contracts";
import { IDemandsState } from "../../store/demands/demands.reducer";
import { IWbsStructure, IProject, IServiceStructure, ITaskStructure } from "../../common/services/contracts/wbsStructures.contracts";
import { IWbsStructuresState } from "../../store/wbs-structures/wbs-structures.reducer";
import { LoadResourceRequests, InvalidateResourceRequests } from "../../store/resource-requests/resource-requests.action";
import { LoadResourceRequestsProjectContext, InvalidateResourceRequestsProjectContext } from "../../store/resource-requests-project-context/resource-requests-project-context.action";
import { LoadDemands, InvalidateDemands } from "../../store/demands/demands.action";
import { LoadWbsStructures } from "../../store/wbs-structures/wbs-structures.action";
import { StaffingActionService } from "../staffing-command-bar/staffing-command-bar-common/services/staffing-action.service";
import { RequestsGridComponent } from "./requests-grid/requests-grid.component";
import { IResourceImage } from "../../common/services/contracts/resource-profile.contracts";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../common/error.constants";
import { DmNotificationService } from "../../common/services/dm-notification.service";
import { ProjectServiceFunctions } from "../../common/services/projectservice-functions.service";
import { ISendRequestEmail } from "../../common/services/contracts/projectservice-functions.contract";

const CREATED_STATUSCODE = "CRTD";
const CANCELLED_STATUS: string = "Cancelled";
@Component({
    selector: "dm-staffing",
    templateUrl: "./staffing.html",
    styleUrls: ["./staffing.scss"]
})
export class StaffingComponent extends DmComponentAbstract {

    @ViewChild(RequestsGridComponent, { static: false }) public requestsGridComponent: RequestsGridComponent;

    public currency: string;
    public engagementStructureDetails: IWbsStructure;
    public engagementDetails: IEngagementDetails; // todo will be deleted once we remove v1, use engagementStructureDetails instead
    public filterData: IProject[] = [];
    public filteredProjectId: string;
    public filteredServiceId: string;
    public filteredTaskId: string;
    public grmRequestList: IResourceRequestResponse = { ProjectRequests: [] };
    public isInternal: boolean = false;
    public isLoading: boolean = true; // TODO Once the modal popups integration with NGRX is done remove this
    public isProjectContext: boolean = false;
    public showNoDemandsMessage: boolean = false;
    public projectData: IProjectDetails[] = [];
    public projectStructureDetails: IProject;
    public searchText: string;
    public selectedDemandList: IResourceRequestForGRMInput[] = [];
    public selectedProjectId: string;
    public showLoadingForRequestGrid: boolean = false;
    public showUSPubSecRMActions: boolean;
    public staffingTasks: IStaffingTaskModel[] = [];
    public staffingViewModel: IStaffingViewModel[] = [];
    public staffingViewModelDeepCopy: IStaffingViewModel[] = [];
    public viewProjectInGrmLinkText: string;
    public viewSubconLinkText: string;
    public subconOnboardingLink: string;   
    public showStaffingCommandBar: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.StaffingDetails;
    public isProjectMarkedForDeletion: boolean = false;
    public isEmailOverViewDisabled: boolean = false;
    public isEmailOverViewClicked: boolean = false;
    public isStaffingSearchResultsFound: boolean[] = [];
    private failureMessages: IOnSave;
    private FXP_CONSTANTS = FxpConstants;
    private initialSetOfProjects: string[] = [];
    private loggedInUserBPID: number;
    private purchaseOrderList: IPurchaseOrder[];
    private queuedProjects: Array<{ projectId: string; isDataLoaded?: boolean }> = [];
    private resourceAliasArray: string[] = [];
    private resourceCostRateDetails: IResourceCostRate[] = [];
    private resourceImageAndCostRatePromises = [];
    private resourceImages: IResourceImage[] = [];
    private selectedEngagementId: string;
    private staffingExclusionWbsL3Types: string[];
    private subConRolePartNumbers: string[];
    private successMessages: IOnSave;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserProfileService)) public fxpUserProfileService: UserProfileService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FaultControlService) private faultControlService: FaultControlService,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingActionService) private staffingActionService: StaffingActionService,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(ResourceStaffingService) private resourceStaffingService: ResourceStaffingService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(Injector) private injector: Injector) {
        super(dmLogger, Components.Staffing, [
            { component: Components.ProjectFilter, isCritical: true },
            { component: Components.StaffingCommandBar, isCritical: true },
            { component: Components.StaffingRequestGrid, isCritical: true }
        ]);
    }

    public ngOnInit(): void {
        this.initializeVariables();
        this.staffingActionService.getReasonCodeProblemTypesDomainData(); // this calls an API but the data isn't saved anywhere?
        this.loadStaffingData(this.filteredProjectId, this.isProjectContext, true, this.isInternal);
    }


    /**
     * To assigned filtered project id to get filtered data based on project id
     * @param value
     */
    public filteredProjectIdChanged(value: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.FilteredProjectIdChanged, LogEventConstants.ProjectFilterStaffing);
        this.selectedDemandList = [];
        this.filteredProjectId = value;
        this.showLoadingForRequestGrid = false;
        this.selectedProjectId = value;
        if (value && !this.isInternal && !this.isProjectContext && this.engagementStructureDetails.statusCode !== CREATED_STATUSCODE) {
            this.selectedProjectId = this.initialSetOfProjects[0] ? this.initialSetOfProjects[0] : this.queuedProjects[0].projectId;
            this.showLoadingForRequestGrid = true;
            const wbsDemandDetails$ = this.store.select(getDemandsState(value));
            const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsProjectContextStateObject(value));
            observableCombineLatest(
                resourceRequestDetail$,
                wbsDemandDetails$,
                (
                    resourceRequestDetail: IResourceRequestsDetailsProjectState,
                    wbsDemandDetails: IDemandsState,
                ) => ({
                    resourceRequestDetail,
                    wbsDemandDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    resourceRequestDetail,
                    wbsDemandDetails,
                }) => {
                    if (resourceRequestDetail.loaded && wbsDemandDetails.loaded) {
                        if (resourceRequestDetail.grmSearchApiResponse && resourceRequestDetail.grmSearchApiResponse.ProjectRequests && resourceRequestDetail.grmSearchApiResponse.ProjectRequests.length > 0) {
                            this.grmRequestList.ProjectRequests = this.grmRequestList.ProjectRequests.concat(resourceRequestDetail.grmSearchApiResponse.ProjectRequests);
                        }
                        wbsDemandDetails.wbsDemands.wbsId = value;
                        this.loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails.wbsDemands, resourceRequestDetail.grmSearchApiResponse);
                        this.showLoadingForRequestGrid = false;
                        this.selectedProjectId = value;
                    } else if (resourceRequestDetail.error && wbsDemandDetails.loaded) {
                        this.loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails.wbsDemands, resourceRequestDetail.grmSearchApiResponse);
                        this.showLoadingForRequestGrid = false;
                    }
                    if (!resourceRequestDetail.loading && !resourceRequestDetail.loaded) {
                        this.store.dispatch(new LoadResourceRequestsProjectContext(value));
                    }
                    if (!wbsDemandDetails.loaded && !wbsDemandDetails.loading) {
                        this.store.dispatch(new LoadDemands(value));
                    }
                });
        }
    }

    /**
     * To assigned filtered project id to get filtered data based on service id
     * @param value
     */
    public filteredServiceIdChanged(value: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.FilteredServiceIdChanged, LogEventConstants.ServiceFilterStaffing);
        this.selectedDemandList = [];
        this.filteredServiceId = value;
    }

    /**
     * To assigned filtered project id to get filtered data based on task id
     * @param value
     */
    public filteredTaskIdChanged(value: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.FilteredTaskIdChanged, LogEventConstants.TaskFilterStaffing);
        this.selectedDemandList = [];
        this.filteredTaskId = value;
    }

    /**
     * Open projects list modal popup for resource management
     *
     * @memberof StaffingComponent
     */
    public selectResourceManagementDetails(): void {
        this.logGrmClick();
        const modalRef: NgbModalRef = this.modalService.open(ResourceManagementModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal dm-staffing-view-details-popup in active manage-wbs-modal",
            injector: this.injector
        });

        modalRef.componentInstance.projectDetails = this.engagementStructureDetails.projects;
        modalRef.componentInstance.grmProjectDetails = this.isInternal ? this.grmRequestList : undefined;
        modalRef.componentInstance.engagementStructureDetails = this.engagementStructureDetails;
    }

    /**
     * Logs the user's click to open the project details in GRM.
     */
    public logGrmClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LogGrmClick, LogEventConstants.StaffingViewResourceManagement);
    }
    
    /**
     * Sends the email overview of the projects information
     * This functionality is called when project manager clicks on 'Email Book of Business'
     */
    public sendEmailOverviewOfrProjects(): void {
        this.isEmailOverViewDisabled = true;
        this.isEmailOverViewClicked = true;
        const inputData: ISendRequestEmail = {
            EmailAlias: [this.fxpUserInfoService.getCurrentUser()]
        };
        this.projectServiceFunctions.sendEmailNotificationAboutProjects(inputData)
            .then((response) => {
                this.isEmailOverViewDisabled = false;
                this.fxpMessageService.addMessage(DmError.Staffing.SendEmailNotificationAboutProjectsSuccess, "success", false);
            }).catch((error) => {
                this.isEmailOverViewDisabled = false;
                this.fxpMessageService.addMessage(DmError.Staffing.SendEmailNotificationAboutProjectsFailure, "error", true);
            });       
    }  

    /**
     * Logs the user's click to open the subcon onboarding in one profile.
     */
    public logSubconClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LogSubconClick, LogEventConstants.StaffingViewResourceManagement);
    }

    /**
     * Handle the event emitter on resource accept decline
     *
     * @param {IResourceAcceptDecline} inputDetail
     * @memberof StaffingComponent
     */
    public onResourceAcceptDecline(inputDetail: IResourceAcceptDecline): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OnResourceAcceptDecline, LogEventConstants.StaffingResourceAcceptedOrDeclined);
        if (inputDetail.resourceActionType === "Accept") {
            this.approveResource(inputDetail.resourceDetail, inputDetail.projectId);
        } else {
            this.loadResourceRejectionPopup(inputDetail.resourceDetail, inputDetail.projectId);
        }
    }

    /**
     * Functions to filter resource details.
     */
    public filterStaffingTasks(criteria: IStaffingPlannedRequestModel): void {
        if (!criteria) {
            return;
        }
        this.staffingViewModel = this.staffingViewModelDeepCopy;
        if (this.staffingViewModel) {
            for (const project of this.staffingViewModel) {
                if (project) {
                    project.isTasksExpanded = false;
                    if (project.taskModelDetails) {
                        for (const task of project.taskModelDetails) {
                            if (task) {
                                task.isDemandsExpanded = false;
                                if (task.demands) {
                                    for (const demand of task.demands) {
                                        if (demand) {
                                            demand.isRolesExpanded = false;
                                            if (demand.resources) {
                                                for (const resource of demand.resources) {
                                                    if (resource) {
                                                        if (moment(resource.resourceStartDate).isSame(moment(criteria.filterStartDate).format("dd-MMM-yyyy")) && moment(resource.resourceEndDate).isSame(moment(criteria.filterEndDate).format("dd-MMM-yyyy"))) {
                                                            if (criteria.statusSelected) {
                                                                for (const status of criteria.statusSelected) {
                                                                    if (resource.resourceStatus.toLowerCase() === status.toLowerCase()) {
                                                                        project.isTasksExpanded = true;
                                                                        task.isDemandsExpanded = true;
                                                                        demand.isRolesExpanded = true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Get selectedDemand from event Emitter as output data
     *
     * @param {ISelectedDemand} selectedDemand
     * @memberof StaffingComponent
     */
    public onDemandSelected(selectedDemand: ISelectedDemand): void {
        if (selectedDemand && selectedDemand.isChecked) {
            let projectId: string;
            if (this.isProjectContext) {
                projectId = this.projectStructureDetails.id;
            } else {
                projectId = this.engagementStructureDetails.projects.filter((project) => project.id === selectedDemand.projectId)[0].id;
            }
            if (this.grmRequestList && this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === selectedDemand.projectId).length > 0) {
                const projectRequests = this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === selectedDemand.projectId)[0];
                if (projectRequests.ResourceRequests && projectRequests.ResourceRequests.length > 0) {
                    const resourceRequests = projectRequests.ResourceRequests.filter((resourceDetails) => resourceDetails.DemandResourceRequest && resourceDetails.DemandResourceRequest.DemandId === selectedDemand.demandId);
                    if (resourceRequests && resourceRequests.filter((resource) => resource.ResourceRequestId === selectedDemand.grmRequestId).length > 0) {
                        const resourceDetails = resourceRequests.filter((resource) => resource.ResourceRequestId === selectedDemand.grmRequestId)[0];
                        this.selectedDemandList.push(
                            {
                                persona: this.hasPermissionsForEditActions(projectId) ? "Requestor" : "NonRequestor",
                                assignmentId: resourceDetails.ResourceRequestId,
                                demandSourceType: "Delivery",
                                assignmentStatus: resourceDetails.ResourceRequestStatusEnum,
                                assignmentSubStatus: resourceDetails.ResourceRequestSubStatusEnum,
                                assignmentStartDate: resourceDetails.ScheduledStartDate ? resourceDetails.ScheduledStartDate : resourceDetails.RequestedStartDate,
                                assignmentEndDate: resourceDetails.ScheduledEndDate ? resourceDetails.ScheduledEndDate : resourceDetails.RequestedEndDate,
                                resourceInformation: resourceDetails,
                                demandDetails: this.resourceStaffingService.retrieveDemandDetailsForSplit(this.staffingTasks, selectedDemand.demandId),
                                grmProjectStatus: projectRequests.IsDraft,
                                resourcesUnderDemand: resourceRequests,
                                isGRMProjectInDraft: projectRequests.IsDraft,
                                projectIdSelected: projectId,
                                isInternal: this.isInternal,
                                internalEngagementType: this.isInternal ? this.engagementStructureDetails.engagementTypeCode : undefined,
                                showUSPubsecRMActions: this.showUSPubSecRMActions,
                                grmProjectRequest: projectRequests,
                                grmSearchResponse: this.grmRequestList,
                                projectDetails: this.isInternal ? undefined : this.engagementStructureDetails.projects.filter((project) => project.id === selectedDemand.projectId)[0]
                            }
                        );
                    }
                }
            }
        } else {
            this.selectedDemandList = this.selectedDemandList.filter((demand) => demand.assignmentId !== selectedDemand.grmRequestId);
        }
    }

    /**
     * set top position for sticky staffing command bar
     */
    public setTopPositionForStickyStaffingCommandBar(): string {
        if (document.getElementById("secondary-naviagtion")) {
            return document.getElementById("secondary-naviagtion").clientHeight + "px";
        } else {
            return NavHeight;
        }
    }

    /**
     * Functions to search text in demands and resources.
     */
    public searchByText(searchText: string): void {
        this.searchText = searchText;
        this.isStaffingSearchResultsFound = [];
        this.staffingViewModel = this.staffingViewModelDeepCopy;
        if (this.staffingViewModel && this.staffingViewModel.length) {
            this.staffingViewModel.forEach((project) => {
                project.isTasksExpanded = false;
                if (project && project.taskModelDetails && project.taskModelDetails.length) {
                    project.taskModelDetails.forEach((task) => {
                        task.isDemandsExpanded = false;
                        const projectStartDate: string = moment(task.projectStartDate).format("DD-MMM-YYYY");
                        const projectEndDate: string = moment(task.projectEnddate).format("DD-MMM-YYYY");
                        if (task && task.demands && task.demands.length) {
                            task.demands.forEach((demand) => {
                                demand.isRolesExpanded = false;
                                if (!demand.demandStatus) {
                                    demand.demandStatus = this.requestsGridComponent.getDemandStatus(demand);
                                }
                                if (Object.keys(demand).some((k) => demand[k] != null && demand[k].toString().toLowerCase().includes(searchText.toLowerCase())) || (projectStartDate).toLowerCase().includes(searchText.toLowerCase()) || (projectEndDate).toLowerCase().includes(searchText.toLowerCase())) {
                                    project.isTasksExpanded = true;
                                    task.isDemandsExpanded = true;
                                    demand.isRolesExpanded = true;
                                    this.isStaffingSearchResultsFound.push(demand.isRolesExpanded);
                                }
                                if (demand && demand.resources && demand.resources.length) {
                                    demand.resources.forEach((resource) => {
                                        const resourceStartDate: string = moment(resource.resourceStartDate).format("DD-MMM-YYYY");
                                        const resourceEndDate: string = moment(resource.resourceEndDate).format("DD-MMM-YYYY");
                                        if (Object.keys(resource).some((k) => resource[k] != null && resource[k].toString().toLowerCase().includes(searchText.toLowerCase())) || (resourceStartDate).toLowerCase().includes(searchText.toLowerCase()) || (resourceEndDate).toLowerCase().includes(searchText.toLowerCase())) {
                                            project.isTasksExpanded = true;
                                            task.isDemandsExpanded = true;
                                            demand.isRolesExpanded = true;
                                            this.isStaffingSearchResultsFound.push(demand.isRolesExpanded);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    /**
     *
     * @param reactAppState
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private reactAppStateChanged(reactAppState) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const test = "Handle React State Change";
    }

    /**
     * Start Building Model for Staffing
     */
    private loadStaffingData(projectId: string, isProjectContext: boolean, isLoadFromOnInit: boolean, isInternal: boolean): void {
        if (isInternal) {
            this.showLoadingForRequestGrid = true;
            const wbsStructureDetails$ = this.store.select(getInitialWbsStructures(this.selectedEngagementId));
            wbsStructureDetails$.pipe(untilDestroyed(this)).subscribe((engagementStructureDetails: IWbsStructuresState) => {
                if (engagementStructureDetails.loaded) {
                    this.showStaffingCommandBar = false;
                    this.staffingViewModel = [];
                    this.engagementStructureDetails = engagementStructureDetails.wbsStructures as IWbsStructure;
                    this.showStaffingCommandBar = this.dmAuthorizationService.isUserInEngagementLevelTeam(this.engagementStructureDetails);
                    if (!this.showStaffingCommandBar) {
                        for (const project of this.engagementStructureDetails.projects) {
                            if (!this.showStaffingCommandBar) {
                                this.showStaffingCommandBar = this.dmAuthorizationService.isUserInProjectLevelTeam(project);
                            }
                        }
                    }
                    if (!isLoadFromOnInit) {
                        const projectIds = (engagementStructureDetails.wbsStructures as IWbsStructure).projects.map((value) => value.id);
                        this.invalidateProjectStaffingData(projectIds);
                    }
                    this.filterData = this.resourceStaffingService.removeExpenseTypeV2(this.engagementStructureDetails.projects);
                    this.currency = this.engagementStructureDetails.currency;
                    if (this.engagementStructureDetails.projects.length <= 3) {
                        this.resourceStaffingService.addItemToStaffingModel(this.engagementStructureDetails.projects, this.staffingViewModel);
                        this.initialSetOfProjects = this.engagementStructureDetails.projects.map((value) => value.id);
                    } else {
                        this.queuedProjects = [];
                        const assignedProjects = this.engagementStructureDetails.projects.filter((project) => project.isAssigned).sort(this.staffingService.sortEntitiesByDateDifference());
                        const unassignedProjects = this.engagementStructureDetails.projects.filter((project) => !project.isAssigned).sort(this.staffingService.sortEntitiesByDateDifference());
                        const projectDetails = assignedProjects.concat(unassignedProjects);
                        this.resourceStaffingService.addItemToStaffingModel(projectDetails, this.staffingViewModel);
                        // Get the top 3 projects to load on to the request grid
                        this.initialSetOfProjects = projectDetails
                            .map((value) => value.id)
                            .slice(0, 3);

                        // Get the rest of the projects to lazy load request grid.
                        const projectsToQueue = projectDetails.filter((project) => this.initialSetOfProjects.indexOf(project.id) === -1);
                        if (projectsToQueue.length > 0) {
                            projectsToQueue.forEach((data) => {
                                this.queuedProjects.push({
                                    projectId: data.id,
                                    isDataLoaded: false
                                });
                            });
                        }
                    }
                    if (this.initialSetOfProjects.length === 0 && isLoadFromOnInit) {
                        this.dispatchActionsForSetOfProjects(this.queuedProjects.map((val) => val.projectId).slice(0, 3));
                    } else if (isLoadFromOnInit) {
                        this.dispatchActionsForSetOfProjects(this.initialSetOfProjects);
                    }
                    this.showLoadingForRequestGrid = false;
                    isLoadFromOnInit = false;
                }
                if (engagementStructureDetails.error) {
                    this.isServerError = true;
                }
            });

        } else if (!isProjectContext) {
            /// Subscribe to the wbsStructure Details by Passing the engagementid
            this.showLoadingForRequestGrid = true;
            const wbsStructureDetails$ = this.store.select(getInitialWbsStructures(this.selectedEngagementId));
            // TODO: dispatch if structures is still loading or not loaded (forecast 2.0)
            // this.store.dispatch(new LoadWbsStructures(this.selectedEngagementId));
            // this.storeDispatchService
            //     .requireWbsStructures(this.selectedEngagementId, true)
            //     .load();
            wbsStructureDetails$.pipe(untilDestroyed(this)).subscribe((engagementStructureDetails: IWbsStructuresState) => {
                if (engagementStructureDetails.loaded) {
                    this.showStaffingCommandBar = false;
                    this.staffingViewModel = [];
                    this.engagementStructureDetails = engagementStructureDetails.wbsStructures as IWbsStructure;
                    this.showStaffingCommandBar = this.dmAuthorizationService.isUserInEngagementLevelTeam(this.engagementStructureDetails);
                    if (!this.showStaffingCommandBar) {
                        for (const project of this.engagementStructureDetails.projects) {
                            if (!this.showStaffingCommandBar) {
                                this.showStaffingCommandBar = this.dmAuthorizationService.isUserInProjectLevelTeam(project);
                            }
                        }
                    }
                    this.showUSPubSecRMActions = this.engagementStructureDetails && this.engagementStructureDetails.isPublicSector && this.engagementStructureDetails.publicSectorCode === "001";
                    if (!isLoadFromOnInit) {
                        const projectIds = (engagementStructureDetails.wbsStructures as IWbsStructure).projects.map((value) => value.id);
                        this.invalidateProjectStaffingData(projectIds);
                    }
                    /* Bind the FilterData */
                    /* Remove any readonly projects */
                    this.engagementStructureDetails.projects = this.engagementStructureDetails.projects.filter((project: IProject) => !this.sharedFunctionsService.isProjectReadOnly(project));
                    this.engagementStructureDetails.projects = this.engagementStructureDetails.projects.filter((project: IProject) => project.userStatusCode && (project.userStatusCode.toUpperCase().includes("MDL") === false));
                    this.filterData = this.resourceStaffingService.removeExpenseTypeV2(this.engagementStructureDetails.projects);
                    this.currency = this.engagementStructureDetails.currency;
                    if (this.engagementStructureDetails.statusCode !== CREATED_STATUSCODE) {
                        if (this.engagementStructureDetails.projects.length <= 3) {
                            this.resourceStaffingService.addItemToStaffingModel(this.engagementStructureDetails.projects, this.staffingViewModel);
                            this.initialSetOfProjects = this.engagementStructureDetails.projects.map((value) => value.id);
                        } else {
                            this.queuedProjects = [];
                            const assignedProjects = this.engagementStructureDetails.projects.filter((project) => project.isAssigned).sort(this.staffingService.sortEntitiesByDateDifference());
                            const unassignedProjects = this.engagementStructureDetails.projects.filter((project) => !project.isAssigned).sort(this.staffingService.sortEntitiesByDateDifference());
                            const projectDetails = assignedProjects.concat(unassignedProjects);
                            this.resourceStaffingService.addItemToStaffingModel(projectDetails, this.staffingViewModel);
                            // Get the top 3 projects to load on to the request grid
                            this.initialSetOfProjects = projectDetails
                                .map((value) => value.id)
                                .slice(0, 3);

                            // Get the rest of the projects to lazy load request grid.
                            const projectsToQueue = projectDetails.filter((project) => this.initialSetOfProjects.indexOf(project.id) === -1);
                            if (projectsToQueue.length > 0) {
                                projectsToQueue.forEach((data) => {
                                    this.queuedProjects.push({
                                        projectId: data.id,
                                        isDataLoaded: false
                                    });
                                });
                            }
                        }
                        if (this.initialSetOfProjects.length === 0 && isLoadFromOnInit) {
                            this.dispatchActionsForSetOfProjects(this.queuedProjects.map((val) => val.projectId).slice(0, 3));
                        } else if (isLoadFromOnInit) {
                            this.dispatchActionsForSetOfProjects(this.initialSetOfProjects);
                        }
                    } else {
                        this.showNoDemandsMessage = true;
                    }
                    this.showLoadingForRequestGrid = false;
                    isLoadFromOnInit = false;
                } else if (engagementStructureDetails.loading) {
                    this.showLoadingForRequestGrid = true;
                }
                if (engagementStructureDetails.error) {
                    this.isServerError = true;
                }
            });
        } else {
            const wbsStructureDetails$ = this.store.select(getInitialWbsStructures(this.selectedEngagementId));
            const wbsDemandDetails$ = this.store.select(getDemandsState(projectId));
            const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsProjectContextStateObject(projectId));
            this.store.dispatch(new LoadResourceRequestsProjectContext(projectId));
            this.store.dispatch(new LoadDemands(projectId));
            this.store.dispatch(new LoadWbsStructures(this.selectedEngagementId));
            observableCombineLatest(wbsStructureDetails$, wbsDemandDetails$, resourceRequestDetail$)
                .pipe(untilDestroyed(this))
                .subscribe(([wbsStructureDetails, wbsDemandDetails, resourceRequestDetail]) => {
                    // Store will return IProject or IWbsStructure depending on what value it's called with.
                    // Project ID sends back IProject
                    if (resourceRequestDetail.loaded && wbsDemandDetails.loaded && wbsStructureDetails.loaded) {
                        this.engagementStructureDetails = wbsStructureDetails.wbsStructures as IWbsStructure;
                        this.projectStructureDetails = this.engagementStructureDetails.projects.filter((project) => project.id === projectId)[0];
                        this.isProjectMarkedForDeletion = this.projectStructureDetails.userStatusCode && this.projectStructureDetails.userStatusCode.toUpperCase().includes("MDL") ? true : false;

                        this.showStaffingCommandBar = false;
                        this.showStaffingCommandBar = this.dmAuthorizationService.isUserInProjectLevelTeam(this.projectStructureDetails);
                        this.showUSPubSecRMActions = this.projectStructureDetails && this.projectStructureDetails.isPublicSector && this.projectStructureDetails.publicSectorCode === "001";
                        /// Bind the FilterData
                        this.filterData = this.resourceStaffingService.removeExpenseTypeV2([this.projectStructureDetails]);
                        this.currency = this.projectStructureDetails.currency;
                        this.grmRequestList = resourceRequestDetail.grmSearchApiResponse;
                        if (this.projectStructureDetails.statusCode !== CREATED_STATUSCODE && !this.isProjectMarkedForDeletion) {
                            this.loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails.wbsDemands, resourceRequestDetail.grmSearchApiResponse);
                        } else {
                            this.showNoDemandsMessage = this.isProjectMarkedForDeletion ? false : true;
                        }
                        this.refreshOnItemInvalidation(resourceRequestDetail, wbsDemandDetails, wbsStructureDetails);
                        this.setLoadersBasedOnItemState(resourceRequestDetail, wbsDemandDetails, wbsStructureDetails);
                        this.setErrorsBasedOnItemState(resourceRequestDetail, wbsDemandDetails, wbsStructureDetails);
                    }
                    if (resourceRequestDetail.error || wbsDemandDetails.error || wbsStructureDetails.error) {
                        this.isServerError = true;
                    }
                });

        }
    }

    /**
     * Invalidate Project Staffing data
     * Staffing Data = WbsStructure + WbsDemands + GRM Resource Request
     *
     * @param {string[]} projectIds
     * @memberof StaffingService
     */
    private invalidateProjectStaffingData(projectIds: string[]): void {
        this.sharedFunctionsService.delayExecution(1000).then(() => {
            projectIds = projectIds.filter((item, pos, ar) => ar.indexOf(item) === pos);
            for (const id of projectIds) {
                this.store.dispatch(new InvalidateDemands(id));
                this.store.dispatch(new InvalidateResourceRequestsProjectContext(id));
            }
            this.dispatchLoadProjectStaffingData(projectIds);
        });
    }

    /**
     * Dispatch Load for Project Staffing Data
     * Staffing Data = WbsStructure + WbsDemands + GRM Resource Request
     *
     * @param {string[]} projectIds
     * @memberof StaffingService
     */
    private dispatchLoadProjectStaffingData(projectIds: string[]): void {
        this.sharedFunctionsService.delayExecution(1000).then(() => {
            for (const projectId of projectIds) {
                const wbsDemandDetails$ = this.store.select(getDemandsState(projectId));
                const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsProjectContextStateObject(projectId));

                wbsDemandDetails$.pipe(
                    take(1))
                    .subscribe((data: IDemandsState) => {
                        if (!data.loaded && !data.loading) {
                            this.store.dispatch(new LoadDemands(projectId));
                        }
                    });

                resourceRequestDetail$.pipe(
                    take(1))
                    .subscribe((data: IResourceRequestsDetailsProjectState) => {
                        if (!data.loaded && !data.loading) {
                            this.store.dispatch(new LoadResourceRequestsProjectContext(projectId));
                        }
                    });
            }
        });
    }

    /**
     * Verify if the Current Engagement is Internal or Customer Delivery
     */
    private isEngagementInternal(): boolean { // todo we should not use this function, we should use the function in shared func services
        return (!this.isProjectContext && this.selectedEngagementId.charAt(0) === "I");
    }


    /**
     * Triggers load actions for given set of project ids and start building the model once the response is recieved
     *
     * @param {string[]} inputProjectIds
     * @memberof StaffingComponent
     */
    private dispatchActionsForSetOfProjects(inputProjectIds: string[]): void {
        inputProjectIds.forEach((projectId, index) => {
            const wbsDemandDetails$ = this.store.select(getDemandsState(projectId));
            const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsProjectContextStateObject(projectId));
            // TODO: dispatch if demands or resource requests is still loading or not loaded (forecast 2.0)
            // this.store.dispatch(new LoadDemands(projectId));
            // this.store.dispatch(new LoadResourceRequestsProjectContext(projectId));
            // this.storeDispatchService
            //     .requireWbsDemandsV2WithoutSchedules(projectId, true)
            //     .requireProjectStaffingDetails(projectId, true)
            //     .load();
            observableCombineLatest(
                resourceRequestDetail$,
                wbsDemandDetails$,
                (
                    resourceRequestDetail: IResourceRequestsDetailsProjectState,
                    wbsDemandDetails: IDemandsState,
                ) => ({
                    resourceRequestDetail,
                    wbsDemandDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    resourceRequestDetail,
                    wbsDemandDetails,
                }) => {
                    if (resourceRequestDetail.loaded && wbsDemandDetails.loaded) {
                        this.selectedDemandList = [];
                        this.showLoadingForRequestGrid = false;
                        if (resourceRequestDetail.grmSearchApiResponse && resourceRequestDetail.grmSearchApiResponse.ProjectRequests && resourceRequestDetail.grmSearchApiResponse.ProjectRequests.length > 0) {
                            this.grmRequestList.ProjectRequests = this.grmRequestList.ProjectRequests.filter((req) => req.ProjectId !== resourceRequestDetail.grmSearchApiResponse.ProjectRequests[0].ProjectId);
                            this.grmRequestList.ProjectRequests = this.grmRequestList.ProjectRequests.concat(resourceRequestDetail.grmSearchApiResponse.ProjectRequests);
                        }
                        wbsDemandDetails.wbsDemands.wbsId = projectId;
                        this.loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails.wbsDemands, resourceRequestDetail.grmSearchApiResponse);
                        if (this.queuedProjects.length && this.queuedProjects.filter((proj) => proj.projectId === projectId).length > 0) {
                            this.queuedProjects.filter((proj) => proj.projectId === projectId)[0].isDataLoaded = true;
                        }
                        if (index === inputProjectIds.length - 1) {
                            if (this.queuedProjects.length > 0 &&
                                (this.queuedProjects.filter((project) => project.isDataLoaded).length !== this.queuedProjects.length)) {
                                const projectsTobeDispatched = this.queuedProjects.filter((project) => !project.isDataLoaded).map((val) => val.projectId).slice(0, 3);
                                if (projectsTobeDispatched && projectsTobeDispatched.length > 0) {
                                    this.dispatchActionsForSetOfProjects(projectsTobeDispatched);
                                }
                            }
                        }
                    } else if (resourceRequestDetail.error && wbsDemandDetails.loaded) {
                        this.showLoadingForRequestGrid = false;
                        this.loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails.wbsDemands, resourceRequestDetail.grmSearchApiResponse);
                    } else if (wbsDemandDetails.error) {
                        const filteredViewModel = this.staffingViewModel.filter((entity) => entity.taskProjectId === projectId);
                        if (filteredViewModel && filteredViewModel.length) {
                            filteredViewModel[0].showLoading = false;
                        }
                    }
                });
        });
    }


    /**
     * Opens up modal PopUp for ResourceRejection
     * @param resourceInfo
     */
    private loadResourceRejectionPopup(resourceInfo: IStaffingResource, projectId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadResourceRejectionPopup, LogEventConstants.StaffingResourceRejectionModalOpened);
        const modalRef: NgbModalRef = this.modalService.open(ResourceRejectionModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.resourceInfo = resourceInfo;
        modalRef.result.then((isReloadRequired: boolean) => {
            if (isReloadRequired) {
                if (this.isInternal) {
                    this.store.dispatch(new InvalidateResourceRequests(this.selectedEngagementId));
                    this.store.dispatch(new LoadResourceRequests(this.selectedEngagementId));
                } else {
                    this.staffingService.invalidateProjectStaffingData([projectId]);
                }
            }
        });
    }

    /**
     * Builds the model/grid based on the demanddetails and grmresponse
     *
     * @param {IWbsDemand} wbsDemandDetails
     * @param {IResourceRequestResponse} grmResponse
     * @memberof StaffingComponent
     */
    private loadModelBasedOnWbsDemandsGrmApiResponse(wbsDemandDetails: IWbsDemand, grmResponse: IResourceRequestResponse) {
        const filteredProjectStructureDetails: IProject[] = this.isProjectContext ? [this.projectStructureDetails] : this.engagementStructureDetails.projects.filter((project) => project.id === wbsDemandDetails.wbsId);
        if (filteredProjectStructureDetails && filteredProjectStructureDetails.length > 0) {
            filteredProjectStructureDetails[0].services.forEach((serviceDetail: IServiceStructure) => {
                if (serviceDetail && serviceDetail.tasks && serviceDetail.tasks.length) {
                    serviceDetail.tasks.forEach((task) => {
                        this.staffingTasks = this.staffingTasks.filter((taskDetails) => taskDetails.taskId !== task.id);
                        if (this.staffingExclusionWbsL3Types.indexOf(task.workPackageType) === -1) {
                            const staffingTaskViewModel: IStaffingTaskModel = {
                                taskEngagementName: this.isProjectContext ? filteredProjectStructureDetails[0].name : this.engagementStructureDetails.name,
                                taskProjectName: filteredProjectStructureDetails[0].name,
                                taskProjectId: filteredProjectStructureDetails[0].id,
                                projectStartDate: filteredProjectStructureDetails[0].startDate,
                                projectEnddate: filteredProjectStructureDetails[0].endDate,
                                taskCompassoneId: filteredProjectStructureDetails[0].compassOnePackageId,
                                taskServiceName: serviceDetail.name,
                                taskServiceId: serviceDetail.id,
                                taskName: task.name,
                                taskId: task.id,
                                taskTypeCode: task.workPackageType,
                                currency: filteredProjectStructureDetails[0].currency,
                                taskCompassPackageId: filteredProjectStructureDetails[0].compassOnePackageId,
                                isDemandsExpanded: true,
                                demands: this.getDemandDetailsBasedOnWbsDemands(task, wbsDemandDetails, grmResponse, filteredProjectStructureDetails[0].id)
                            };
                            this.staffingTasks.push(staffingTaskViewModel);
                            this.addToStaffingViewModel(staffingTaskViewModel);
                        }
                    });
                }
            });
            const filteredViewModel = this.staffingViewModel.filter((entity) => entity.taskProjectId === wbsDemandDetails.wbsId);
            if (filteredViewModel && filteredViewModel.length) {
                filteredViewModel[0].showLoading = false;
            }
            this.resourceStaffingService.populateDemandStaffedCostLabor(this.staffingTasks);
        }
    }

    /**
     * Add the taskModel to the View Model object containing the project details , so the grid data can be iterated based on the view model.
     *
     * @param {IStaffingTaskModel} staffingTaskViewModel
     * @memberof StaffingComponent
     */
    private addToStaffingViewModel(staffingTaskViewModel: IStaffingTaskModel): void {
        const filteredViewModel = this.staffingViewModel.filter((entity) => entity.taskProjectId === staffingTaskViewModel.taskProjectId);
        if (filteredViewModel && filteredViewModel.length) {
            filteredViewModel[0].taskModelDetails = filteredViewModel[0].taskModelDetails.filter((taskDetails) => taskDetails.taskId !== staffingTaskViewModel.taskId);
            filteredViewModel[0].taskModelDetails.push(staffingTaskViewModel);
            filteredViewModel[0].showLoading = false;
        } else {
            const staffingViewModel: IStaffingViewModel = {
                taskProjectName: staffingTaskViewModel.taskProjectName,
                taskProjectId: staffingTaskViewModel.taskProjectId,
                projectStartDate: staffingTaskViewModel.projectStartDate,
                projectEndDate: staffingTaskViewModel.projectEnddate,
                isTasksExpanded: false,
                showLoading: false,
                taskModelDetails: []
            };
            staffingViewModel.taskModelDetails.push(staffingTaskViewModel);
            this.staffingViewModel.push(staffingViewModel);
        }
        this.staffingViewModelDeepCopy = this.staffingViewModel;
    }

    /**
     * Gets the Demand Details based on the wbsdetails retrived from the API.
     *
     * @param {ITaskStructure} task
     * @param {IWbsDemand} wbsDemandDetails
     * @param {IResourceRequestResponse} grmResponse
     * @param {string} projectId
     * @memberof StaffingComponent
     */
    private getDemandDetailsBasedOnWbsDemands(task: ITaskStructure, wbsDemandDetails: IWbsDemand, grmResponse: IResourceRequestResponse, projectId: string): IStaffingDemandModel[] {
        const staffingDemandModel: IStaffingDemandModel[] = [];
        if (wbsDemandDetails && wbsDemandDetails.details && wbsDemandDetails.details.length
            && wbsDemandDetails.details.filter((demand) => demand.planned && demand.planned.structureId && demand.planned.structureId === task.id).length > 0) {
            const plannedWbsDemandDetailsForTask: IDemandDetails[] = wbsDemandDetails.details.filter((demand) => demand.planned && demand.planned.structureId && demand.planned.structureId === task.id);
            for (const demandDetail of plannedWbsDemandDetailsForTask) {
                if (staffingDemandModel.filter((demand) => demand.demandId === demandDetail.demandId).length === 0) {
                    staffingDemandModel.push({
                        engagementId: this.selectedEngagementId,
                        demandId: demandDetail.demandId,
                        demandLocation: demandDetail.planned.deliveryLocation,
                        demandRole: demandDetail.planned.roleDescription,
                        demandCostRate: demandDetail.planned.plannedCostRate,
                        demandPlannedQuantity: demandDetail.planned.plannedHours,
                        demandPlannedCost: demandDetail.planned.plannedCost,
                        demandBillRate: demandDetail.planned.billRate,
                        resourceAccordionFlag: true,
                        isRolesExpanded: true,
                        isDemandBillable: !demandDetail.planned.isNonBillable,
                        resources: this.getResourceRequestsForWbsDemandModel(demandDetail, grmResponse, projectId)
                    });
                } else {
                    const staffingDemandObject = staffingDemandModel.filter((demand) => demand.demandId === demandDetail.demandId)[0];
                    const resourceDetails: IStaffingResource[] = this.getResourceRequestsForWbsDemandModel(demandDetail, grmResponse, projectId);
                    if (resourceDetails && resourceDetails.length > 0) {
                        staffingDemandObject.resources.concat(this.getResourceRequestsForWbsDemandModel(demandDetail, grmResponse, projectId));
                    }
                }
            }
        }
        return staffingDemandModel;
    }


    /**
     * Gets the Resource Requests based on the wbddemands and grmapiresponse.
     *
     * @param {IDemandDetails} wbsDemandDetailsForTask
     * @param {IResourceRequestResponse} grmResponse
     * @param {string} projectId
     * @memberof StaffingComponent
     */
    private getResourceRequestsForWbsDemandModel(wbsDemandDetailsForTask: IDemandDetails, grmResponse: IResourceRequestResponse, projectId: string): IStaffingResource[] {
        const resourceRequestsForViewModel: IStaffingResource[] = [];
        if (grmResponse && grmResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId).length > 0) {
            const projectRequests: IProjectRequest = grmResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId)[0];
            if (projectRequests.ResourceRequests && projectRequests.ResourceRequests.length > 0) {
                const resourceRequests: IResourceRequest[] = projectRequests.ResourceRequests.filter((resourceDetails) => resourceDetails.DemandResourceRequest && resourceDetails.DemandResourceRequest.DemandId === wbsDemandDetailsForTask.demandId);
                if (resourceRequests && resourceRequests.length > 0) {
                    resourceRequests.forEach((resourceRequest) => {
                        if (resourceRequest.ResourceRequestStatusEnum.toLowerCase() !== CANCELLED_STATUS.toLowerCase()) {
                            if (resourceRequest.AssignedResource) {
                                this.resourceStaffingService.bindResourceImageToStaffingTasks(resourceRequest.AssignedResource, wbsDemandDetailsForTask.planned.structureId, wbsDemandDetailsForTask.demandId, this.staffingTasks);
                            } else if (resourceRequest.RequestedResource) {
                                this.resourceStaffingService.bindResourceImageToStaffingTasks(resourceRequest.RequestedResource, wbsDemandDetailsForTask.planned.structureId, wbsDemandDetailsForTask.demandId, this.staffingTasks);
                            }
                            const plannedDetails: IResourceData = wbsDemandDetailsForTask.planned;
                            const staffedDetails: IResourceData[] = (wbsDemandDetailsForTask.staffed && wbsDemandDetailsForTask.staffed.length > 0) ? wbsDemandDetailsForTask.staffed.filter((resData) => resData.resourceRequestId === (resourceRequest.ResourceRequestId).toString() && resData.staffedIndicator) : [];
                            const resourcePopulated: IStaffingResource = {
                                resourceId: resourceRequest.ResourceRequestId,
                                resourceGrmProjectID: projectRequests.ProjectId,
                                resourceLocation: resourceRequest.DeliveryCountryName,
                                resourceRole: (staffedDetails && staffedDetails.length > 0) ? staffedDetails[0].roleDescription : resourceRequest.Role,
                                resourceAlias: resourceRequest.AssignedResource ? resourceRequest.AssignedResource : resourceRequest.RequestedResource,
                                resourceBillRate: this.isInternal ? 0 : this.getBillRate(resourceRequest, wbsDemandDetailsForTask),
                                resourceCostRate: this.calculateResourceCostRateV2(resourceRequest, wbsDemandDetailsForTask),
                                resourceStaffedQuantity: this.isInternal ? resourceRequest.scheduledDurationInHours : (staffedDetails && staffedDetails.length > 0) ? staffedDetails[0].staffedHours : 0,
                                resourcePlannedQuantity: resourceRequest.RequestedDuration,
                                resourceStaffedCost: (staffedDetails && staffedDetails.length > 0) ? staffedDetails[0].staffedCost : 0,
                                resourcePlannedCost: plannedDetails.plannedCostRate * resourceRequest.RequestedDuration,
                                resourceStartDate: resourceRequest.ScheduledStartDate ? resourceRequest.ScheduledStartDate : resourceRequest.RequestedStartDate,
                                resourceEndDate: resourceRequest.ScheduledEndDate ? resourceRequest.ScheduledEndDate : resourceRequest.RequestedEndDate,
                                resourceStatus: this.resourceStaffingService.getResourceStatus(resourceRequest.ResourceRequestStatusEnum),
                                resourceSubStatus: resourceRequest.ResourceRequestSubStatusEnum,
                                resourceName: resourceRequest.AssignedResourceName ? resourceRequest.AssignedResourceName : (staffedDetails && staffedDetails.length > 0) ? staffedDetails[0].resourceName : resourceRequest.AssignedResource,
                                resourceActions: this.resourceStaffingService.getActions(resourceRequest),
                                enableResourceActions: (this.hasPermissionsForEditActions(projectId)),
                                purchaseOrderId: resourceRequest.DemandResourceRequest.PoNumber,
                                poLineItemNumber: resourceRequest.DemandResourceRequest.PoLineItem,
                                rolePartNumber: resourceRequest.RolePartNumber
                            };
                            resourcePopulated.resourceCostVariance = this.resourceStaffingService.calculateCostVariance(resourcePopulated.resourceStaffedCost, resourcePopulated.resourcePlannedCost);
                            resourceRequestsForViewModel.push(resourcePopulated);
                        }
                    });
                }
            }
        }
        return resourceRequestsForViewModel;
    }

    // /**
    //  * Functions to execute fault based on engagement id.
    //  */
    // private checkAndReprocessFaults(engagementId: string): void {
    //     this.faultControlService.getFaults(engagementId)
    //         .then((faultResponse) => {
    //             if (!faultResponse || !isArray(faultResponse.faults) || faultResponse.faults.length === 0) {
    //                 // Nothing to process . return
    //                 return;
    //             }
    //             const shouldReloadStaffing: Array<Promise<any>> = [];
    //             faultResponse.faults.
    //                 filter((fault) => fault.actionRequired.toLowerCase() === "re-process")
    //                 .forEach((fault) => {

    //                     this.fxpMessageService.addMessage(`Reprocessing Failed Role request submitted by '${fault.faultDetail.initiatedBy}' . Id : ${fault.id} `,
    //                         this.FXP_CONSTANTS.messageType.info);


    //                     shouldReloadStaffing.push(new Promise((res, rej) => {
    //                         this.faultControlService.reprocessFault(fault.id)
    //                             .then(() => {
    //                                 this.fxpMessageService.addMessage(`Fault: ${fault.id} processed Successfully `,
    //                                     this.FXP_CONSTANTS.messageType.success);

    //                                 return true;
    //                             })
    //                             .catch((error) => {
    //                                 this.logError(error, this.validationResults.validationMessage);
    //                                 return false;
    //                             });
    //                     }));
    //                 });

    //             Promise.all(shouldReloadStaffing)
    //                 .then((results) => {
    //                     for (const faultId in results) {
    //                         // if at least one fault was processed successfully reload the data model
    //                         if (results.hasOwnProperty(faultId) && results[faultId] === true) {
    //                             this.loadDemandDetails();
    //                             break;
    //                         }
    //                     }
    //                 });
    //         });
    // }



    /**
     * Functions to load demand details.
     */
    private loadDemandDetails(): void {
        this.staffingTasks = [];
        this.resourceImages = [];
        this.resourceAliasArray = [];
        this.resourceCostRateDetails = [];
        for (const project of this.projectData) {
            for (const service of project.services) {
                for (const task of service.wbsL3s) {
                    if (this.staffingExclusionWbsL3Types.indexOf(task.wbsl3Type) === -1) {
                        const staffingTaskViewModel: IStaffingTaskModel = {
                            taskEngagementName: project.engagementName,
                            taskProjectName: project.projectName,
                            taskProjectId: project.projectId,
                            projectStartDate: project.startDate,
                            projectEnddate: project.endDate,
                            taskCompassoneId: service.compassOneServiceId,
                            taskServiceName: service.serviceName,
                            taskServiceId: service.serviceId,
                            taskName: task.wbsL3Name,
                            taskId: task.wbsl3Id,
                            taskTypeCode: task.wbsl3Type,
                            taskCompassPackageId: project.compassOnePackageId,
                            isDemandsExpanded: true,
                            demands: task.demandDetails.length > 0 ? this.loadDemands(task.demandDetails, project.projectId, project) : []
                            /* The demands API does not work for internal engagements, which is why we have not yet retired v1,
                            because V2 does not have demands and we can't call the demands API */
                        };
                        this.staffingTasks.push(staffingTaskViewModel);
                        this.addToStaffingViewModel(staffingTaskViewModel);
                    }
                }
            }
        }
        this.resourceStaffingService.populateDemandStaffedCostLabor(this.staffingTasks);

        if (this.resourceAliasArray && this.resourceAliasArray.length) {
            this.resourceAliasArray = this.resourceAliasArray.filter((item, pos, ar) => ar.indexOf(item) === pos);
            this.populateResourceImages(this.resourceAliasArray);
        }
        Promise.all(this.resourceImageAndCostRatePromises).then(() => {
            this.resourceStaffingService.bindImageDataAndCostRateToResources(this.resourceImages, this.staffingTasks, this.resourceCostRateDetails, this.purchaseOrderList);
            this.resourceStaffingService.populateDemandStaffedCostLabor(this.staffingTasks);
        });
    }

    /**
     * Functions to load demands.
     * @param demands
     * @param projectId
     * @param project
     */
    private loadDemands(demands: IDemandDetail[], projectId: string, project: IProjectDetails): IStaffingDemandModel[] {
        const demandDetails: IStaffingDemandModel[] = [];
        demands.forEach((demand) => {
            if (demand.resourceDetails && demand.resourceDetails.length > 0) {
                const resDetails: IResourceDetail = demand.resourceDetails.filter((res) => res.type.toLowerCase() === "planned")[0];
                if (resDetails.resources && resDetails.resources.length) {
                    const resource: IResource = resDetails.resources[0];
                    demandDetails.push({
                        engagementId: demand.engagementId,
                        demandId: resource.demandId,
                        demandLocation: resource.deliveryLocCountryCode,
                        demandRole: resource.rolePartNumberDescription,
                        demandCostRate: resource.plannedCostRate,
                        demandPlannedQuantity: this.resourceStaffingService.calculateRoleDetailsHours(resource),
                        demandPlannedCost: resource.plannedCost,
                        demandBillRate: resource.billRate,
                        resourceAccordionFlag: true,
                        demandStatus: demand.wbsl3StatusDescription,
                        isRolesExpanded: true,
                        isDemandBillable: resource.isBillable,
                        resources: this.getDemandResourceRequests(demand, projectId, demand.resourceDetails, resource.plannedCostRate, project)
                    });
                }
            }
        });
        return demandDetails;
    }

    /**
     * To get demand resource from service
     * @param demandId
     * @param projectId
     * @param resDetails
     * @param plannedCostRate
     * @param project
     */
    private getDemandResourceRequests(demand: IDemandDetail, projectId: string, resDetails: IResourceDetail[], plannedCostRate: number, project: IProjectDetails): IStaffingResource[] {
        const staffingResources: IStaffingResource[] = [];
        let projectRequests: IProjectRequest = null;
        let resourceRequests: IResourceRequest[] = [];
        if (this.grmRequestList && this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId).length > 0) {
            projectRequests = this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId)[0];
            if (projectRequests.ResourceRequests && projectRequests.ResourceRequests.length > 0) {
                resourceRequests = projectRequests.ResourceRequests.filter((resourceDetails) => resourceDetails.DemandResourceRequest && resourceDetails.DemandResourceRequest.DemandId === demand.demandId);
                if (resourceRequests && resourceRequests.length > 0) {
                    resourceRequests.forEach((resourceRequest) => {
                        if (resourceRequest.ResourceRequestStatusEnum.toLowerCase() !== CANCELLED_STATUS.toLowerCase()) {
                            if (resourceRequest.AssignedResource) {
                                this.resourceAliasArray.push(resourceRequest.AssignedResource);
                            } else if (resourceRequest.RequestedResource) {
                                this.resourceAliasArray.push(resourceRequest.RequestedResource);
                            }
                            const resourcePopulated: IStaffingResource = {
                                resourceId: resourceRequest.ResourceRequestId,
                                resourceGrmProjectID: projectRequests.ProjectId,
                                resourceLocation: resourceRequest.DeliveryCountryName,
                                resourceRole: resourceRequest.Role,
                                resourceAlias: resourceRequest.AssignedResource ? resourceRequest.AssignedResource : resourceRequest.RequestedResource,
                                resourceBillRate: this.isInternal ? 0 : this.resourceStaffingService.getResourceBillRate(resDetails),
                                resourceCostRate: this.calculateResourceCostRate(resourceRequest, demand), // this.getResourceStaffedCostRate(resDetails, resourceRequest.ResourceRequestId, resourceRequest.scheduledDurationInHours),
                                resourceStaffedQuantity: resourceRequest.scheduledDurationInHours,
                                resourcePlannedQuantity: resourceRequest.RequestedDuration,
                                resourceStaffedCost: this.resourceStaffingService.getResourceStaffedCost(resDetails, resourceRequest.ResourceRequestId, resourceRequest.ResourceRequestStatusEnum),
                                resourcePlannedCost: plannedCostRate * resourceRequest.RequestedDuration,
                                resourceStartDate: resourceRequest.ScheduledStartDate ? resourceRequest.ScheduledStartDate : resourceRequest.RequestedStartDate,
                                resourceEndDate: resourceRequest.ScheduledEndDate ? resourceRequest.ScheduledEndDate : resourceRequest.RequestedEndDate,
                                resourceStatus: this.resourceStaffingService.getResourceStatus(resourceRequest.ResourceRequestStatusEnum),
                                resourceSubStatus: resourceRequest.ResourceRequestSubStatusEnum,
                                resourceName: resourceRequest.AssignedResourceName ? resourceRequest.AssignedResourceName : resourceRequest.RequestedResourceName,
                                resourceActions: this.resourceStaffingService.getActions(resourceRequest),
                                enableResourceActions: (this.hasPermissionsForEditActions(project.projectId)),
                                purchaseOrderId: resourceRequest.DemandResourceRequest.PoNumber,
                                poLineItemNumber: resourceRequest.DemandResourceRequest.PoLineItem,
                                rolePartNumber: resourceRequest.RolePartNumber
                            };
                            resourcePopulated.resourceCostVariance = this.resourceStaffingService.calculateCostVariance(resourcePopulated.resourceStaffedCost, resourcePopulated.resourcePlannedCost);
                            staffingResources.push(resourcePopulated);
                        }
                    });
                }
            }
        }
        return staffingResources;
    }

    /**
     * Calculating resource cost rate for different status
     *
     * @private
     * @param {IResourceRequest} role
     * @param {IDemandDetail} demandDetail
     * @returns {number}
     * @memberof StaffingComponent
     */
    private calculateResourceCostRate(resourceDetail: IResourceRequest, demandDetail: IDemandDetail): number {
        if (this.isInternal) {
            if (!resourceDetail.AssignedResourceBPID) {
                return 0;
            }
            const costRateApiInput: ICostRateInput[] = [{
                resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
                projectId: this.engagementDetails.engagementId,
                rolePartNumber: resourceDetail.RolePartNumber,
                currency: this.engagementDetails.currency
            }];
            this.resourceImageAndCostRatePromises.push(this.retrieveResourceCostRate(costRateApiInput, resourceDetail.ResourceRequestId));
        } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Complete) {
            return this.resourceStaffingService.getResourceStaffedCostRate(demandDetail.resourceDetails, resourceDetail.ResourceRequestId, SAPApiResponseResourceDetailTypeFilters.staffed);
        } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Assigned || resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Proposed) {
            /* Check if its SubContracter Role or not */
            if (this.subConRolePartNumbers.indexOf(resourceDetail.RolePartNumber) < 0) {
                /* Check if Data is present in SAP Response*/
                if (demandDetail && demandDetail.resourceDetails && demandDetail.resourceDetails.length) {
                    const resDetails: IResourceDetail = demandDetail.resourceDetails.filter((res) => res.type.toLowerCase() === SAPApiResponseResourceDetailTypeFilters.proposed)[0];
                    if (resDetails && resDetails.resources && resDetails.resources.length) {
                        const resSelected: IResource[] = resDetails.resources.filter((resfilter) => Number(resfilter.resourceRequestId) === Number(resourceDetail.ResourceRequestId));
                        if (resSelected[0]) {
                            return resSelected[0].staffedCostRate ? Number(resSelected[0].staffedCostRate.toFixed(2)) : 0.00;
                        } else {
                            if (resourceDetail.AssignedResourceBPID) {
                                const costRateApiInput: ICostRateInput[] = [{
                                    resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
                                    projectId: this.engagementStructureDetails.id, // should this be engId?
                                    rolePartNumber: resourceDetail.RolePartNumber,
                                    currency: this.engagementStructureDetails.currency
                                }];
                                this.resourceImageAndCostRatePromises.push(this.retrieveResourceCostRate(costRateApiInput, resourceDetail.ResourceRequestId));
                            }
                        }
                    }
                }
            }
        }
        return 0;
    }

    /**
     * Calculating resource bill rate for different status
     *
     * @private
     * @param {IResourceRequest} role
     * @param {IDemandDetail} demandDetail
     * @returns {number}
     * @memberof StaffingComponent
     */
    private getBillRate(resourceDetail: IResourceRequest, demandDetail: IDemandDetails): number {
        let billRate = demandDetail.planned.billRate;
        const staffedDetails: IResourceData[] = (demandDetail.staffed && demandDetail.staffed.length > 0) ? demandDetail.staffed.filter((resData: IResourceData) => resData.staffedIndicator && resData.resourceRequestId === String(resourceDetail.ResourceRequestId)) : [];
        if (staffedDetails && staffedDetails.length > 0) {
            billRate = staffedDetails[0].billRate;
        }
        return billRate;
    }

    /**
     * Calculating resource cost rate for different status
     *
     * @private
     * @param {IResourceRequest} role
     * @param {IDemandDetail} demandDetail
     * @returns {number}
     * @memberof StaffingComponent
     */
    private calculateResourceCostRateV2(resourceDetail: IResourceRequest, demandDetail: IDemandDetails): number {
        const staffedDetails: IResourceData[] = (demandDetail.staffed && demandDetail.staffed.length > 0) ? demandDetail.staffed.filter((resData: IResourceData) => resData.staffedIndicator && resData.resourceRequestId === String(resourceDetail.ResourceRequestId)) : [];
        const staffedCostRate: number = (staffedDetails && staffedDetails.length) ? staffedDetails[0].staffedCostRate : 0;
        if (this.isInternal) {
            if (!resourceDetail.AssignedResourceBPID) {
                return 0;
            }
            const costRateApiInput: ICostRateInput[] = [{
                resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
                projectId: this.selectedEngagementId,
                rolePartNumber: resourceDetail.RolePartNumber,
                currency: this.currency
            }];
            this.retrieveResourceCostRateV2(costRateApiInput, resourceDetail.ResourceRequestId, demandDetail);
            return 0;
        } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Complete) {
            return staffedCostRate;
        } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Assigned || resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Proposed) {
            /* Check if its SubContracter Role or not */
            if (this.subConRolePartNumbers.indexOf(resourceDetail.RolePartNumber) < 0) {
                /* Check if Data is present in SAP Response*/
                if (staffedCostRate) {
                    return staffedCostRate;
                } else {
                    if (!resourceDetail.AssignedResourceBPID) {
                        return 0;
                    }
                    const costRateApiInput: ICostRateInput[] = [{
                        resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
                        projectId: this.selectedEngagementId,
                        rolePartNumber: resourceDetail.RolePartNumber,
                        currency: this.engagementStructureDetails.currency
                    }];
                    this.retrieveResourceCostRateV2(costRateApiInput, resourceDetail.ResourceRequestId, demandDetail);
                    return 0;
                }
            }
        } else {
            return 0;
        }
    }

    /**
     * Function to decide that logged in user has permission for project or not.
     * @param projectId
     */
    private hasPermissionsForEditActions(projectId: string): boolean {
        if (!this.isProjectContext && (this.dmAuthorizationService.isUserInEngagementLevelTeam(this.engagementStructureDetails) || this.dmAuthorizationService.isUserInProjectLevelTeam(this.engagementStructureDetails.projects.filter((proj) => proj.id === projectId)[0]))) {
            return true;
        }
        if (this.isProjectContext && (this.dmAuthorizationService.isUserInEngagementLevelTeam(this.engagementStructureDetails) || this.dmAuthorizationService.isUserInProjectLevelTeam(this.projectStructureDetails))) {
            return true;
        }
        return false;
    }

    /**
     * Function to approve resource request.
     * @param resource
     */
    private approveResource(resource: IStaffingResource, projectId: string): void {
        this.isLoading = true;
        this.staffingService.updateGRMResourceRequests(resource.resourceId, "", "", "RequestorApproved")
            .then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.ApproveResource, LogEventConstants.StaffingAcceptResourceRequest);
                let successMessage: string = this.successMessages.OnSave;
                successMessage = successMessage.replace("#", (resource.resourceRole + " " + resource.resourceAlias));
                this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                if (this.isInternal) {
                    this.store.dispatch(new InvalidateResourceRequests(this.selectedEngagementId));
                    this.store.dispatch(new LoadResourceRequests(this.selectedEngagementId));
                } else {
                    this.staffingService.invalidateProjectStaffingData([projectId]);
                }
            }).catch((error) => {
                this.isLoading = false;
                let errorMessage: string = this.failureMessages.OnSave;
                errorMessage = errorMessage.replace("#", (resource.resourceRole + " " + resource.resourceAlias));
                this.fxpMessageService.addMessage(errorMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.ApproveResource, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }

    /**
     * TO fill the image for resource.
     * @param resourceAliasArray
     */
    private populateResourceImages(resourceAliasArray: string[]) {
        if (resourceAliasArray && resourceAliasArray.length) {
            resourceAliasArray.forEach((resourceAlias) => {
                this.resourceImageAndCostRatePromises.push(this.bindResourceImageToArray(resourceAlias));
            });
        }
    }

    /**
     * Get resource thumb pictures and add it to array.
     * @param assignedResource
     */
    private bindResourceImageToArray(assignedResource: string): Promise<any> {
        return this.aadGraphService.getResourceThumbnailPicture(assignedResource)
            .then((response) => {
                this.resourceImages.push({
                    resourceAlias: assignedResource,
                    resourceImage: response
                });
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.BindResourceImageToArray, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.resolve();
            });
    }

    /**
     * Retrieve CostRate from API
     * @param costRateApiInput
     * @param resourceRequestId
     */
    private retrieveResourceCostRateV2(costRateApiInput: ICostRateInput[], resourceRequestId: number, demandDetail: IDemandDetails): Promise<any> {
        return this.projectService.getCostRate(costRateApiInput)
            .then((response: ICostRateApiOutput) => {
                const retrievedCostRate = this.resourceStaffingService.getCostRateFromApiOutput(response, this.isInternal, this.isProjectContext, this.projectStructureDetails, this.engagementDetails, this.engagementStructureDetails);
                this.resourceStaffingService.bindCostRateToStaffingTasks(this.staffingTasks, resourceRequestId, demandDetail.planned.structureId, demandDetail.demandId, retrievedCostRate);
                if (this.isInternal) {
                    this.resourceStaffingService.bindImageDataAndStaffedCostToResources(this.resourceImages, this.staffingTasks, retrievedCostRate, this.purchaseOrderList);
                    this.resourceStaffingService.populateDemandStaffedCostLabor(this.staffingTasks);
                }
            });
    }

    /**
     * Retrieve CostRate from API
     * @param costRateApiInput
     * @param resourceRequestId
     */
    private retrieveResourceCostRate(costRateApiInput: ICostRateInput[], resourceRequestId: number): Promise<number> {
        return this.projectService.getCostRate(costRateApiInput)
            .then((response: ICostRateApiOutput) => {
                const retrievedCostRate = this.resourceStaffingService.getCostRateFromApiOutput(response, this.isInternal, this.isProjectContext, this.projectStructureDetails, this.engagementDetails, this.engagementStructureDetails);
                const resourceInfo: IResourceCostRate[] = this.resourceCostRateDetails.filter(((resourceObject) => resourceObject.resourceId === resourceRequestId));
                if (resourceInfo && resourceInfo.length) {
                    resourceInfo[0].resourceCostRate = retrievedCostRate;
                } else {
                    this.resourceCostRateDetails.push({
                        resourceId: resourceRequestId,
                        resourceCostRate: retrievedCostRate
                    });
                }
                return retrievedCostRate;
            });
    }

    /**
     * Initialize the variables used in the component
     *
     * @memberof StaffingComponent
     */
    private initializeVariables(): void {
        this.tileContent = {
            title: "Staffing"
        };        
        this.viewProjectInGrmLinkText = "View Project Details" + (!this.deviceFactory.isSmallScreen() ? " in Resource Management" : "");       
        this.viewSubconLinkText = "View Subcon Dashboard" ;        	
        this.subconOnboardingLink = this.configManagerService.getValue<string>("subconOnboardingPendingDashboardLink");
        this.staffingExclusionWbsL3Types = this.configManagerService.getValue<string[]>("StaffingExclusionWbsL3Types");
        this.successMessages = this.configManagerService.getValue<any>("SuccessMessages").AcceptResource;
        this.failureMessages = this.configManagerService.getValue<any>("FailureMessages").AcceptResource;
        this.subConRolePartNumbers = this.configManagerService.getValue<string[]>("subConRolePartNumbers");
        this.loggedInUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        this.filteredProjectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        if (this.filteredProjectId) {
            this.isProjectContext = true;
        }
        this.selectedEngagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.isInternal = this.isEngagementInternal();
        this.isComponentLoading = false;
    }

}
