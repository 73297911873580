
import { Component, Input } from "@angular/core";

@Component({
    selector: "dm-cost-consumed",
    templateUrl: "./cost-consumed.html",
    styleUrls: ["./cost-consumed.scss"]
})

export class CostConsumedComponent {

    @Input() public cceacValues: any;
    @Input() public isInternalEngagement: boolean;

}
