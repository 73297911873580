import * as fromEngagementDetails from "./engagement-details.action";
import { ILoadableState } from "../reducers";
import { IEngagementDetailsV2, IProjectDetailsV2, IServiceDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import moment from "moment";

export interface IEngagementDetailsState extends ILoadableState {
    engagementDetails: IEngagementDetailsV2;
}

export const initialState: IEngagementDetailsState = {
    engagementDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromEngagementDetails.EngagementDetailsAction): { [engagementId: string]: IEngagementDetailsState } {
    switch (action.type) {
        case fromEngagementDetails.EngagementDetailsActionTypes
            .LOAD_ENGAGEMENT_DETAILS: {
            const newState = { ...state };
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromEngagementDetails.EngagementDetailsActionTypes
            .UPDATE_LOCALLY_ENGAGEMENT_DETAILS: {
            const newState = { ...state };
            if (newState[action.engagementId] && newState[action.engagementId].loaded) {
                const engagementDetails = newState[action.engagementId].engagementDetails;
                const newEngagementDetails: IEngagementDetailsV2 = { ...engagementDetails };
                newEngagementDetails.description = action.editEngagementDetails.description ? action.editEngagementDetails.description : newEngagementDetails.description;
                newEngagementDetails.name = action.editEngagementDetails.name ? action.editEngagementDetails.name : newEngagementDetails.name;
                newEngagementDetails.statusCode = action.editEngagementDetails.status ? action.editEngagementDetails.status : newEngagementDetails.statusCode;
                if (!action.editEngagementDetails.shouldCascadeUpdate) {
                    // if cascade is required then do reload instead of local change since async operation
                    newEngagementDetails.startDate = action.editEngagementDetails.startDate ? moment(action.editEngagementDetails.startDate).toDate() : newEngagementDetails.startDate;
                    newEngagementDetails.endDate = action.editEngagementDetails.endDate ? moment(action.editEngagementDetails.endDate).toDate() : newEngagementDetails.endDate;
                }
                newState[action.engagementId] = {
                    ...initialState,
                    loaded: true,
                    engagementDetails: newEngagementDetails
                };
            }
            return newState;
        }

        case fromEngagementDetails.EngagementDetailsActionTypes
            .UPDATE_LOCALLY_ADD_TASK: {
            const newState = { ...state };
            if (newState[action.engagementId] && newState[action.engagementId].loaded) { /* get the current engagement state from the store */
                const engagementDetails: IEngagementDetailsV2 = newState[action.engagementId].engagementDetails;
                const newEngagementDetails: IEngagementDetailsV2 = { ...engagementDetails }; /* Make edits on a new reference */
                const selectedProjects: IProjectDetailsV2 = newEngagementDetails.projects.filter((p) => p.id === action.projectID)[0];
                if (selectedProjects) {
                    const proj: IProjectDetailsV2 = { ...selectedProjects }; /* Creates a new reference of the project */
                    const selectedServices: IServiceDetailsV2 = proj.services.filter((s) => s.id === action.serviceId)[0];
                    if (selectedServices) {
                        const service: IServiceDetailsV2 = { ...selectedServices }; /* Creates a new reference of the service */
                        service.tasks.push(action.newTaskDetails);
                        newState[action.engagementId] = {
                            ...initialState,
                            loaded: true,
                            engagementDetails: newEngagementDetails /* Change the state to use this new, edited reference. */
                        };
                    }
                }
            }
            return newState;
        }

        case fromEngagementDetails.EngagementDetailsActionTypes
            .LOAD_ENGAGEMENT_DETAILS_SUCCESS: {
            const newState = { ...state };
            newState[action.engagementId] = {
                ...initialState,
                engagementDetails: action.engagementDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromEngagementDetails.EngagementDetailsActionTypes
            .LOAD_ENGAGEMENT_DETAILS_FAIL: {
            const newState = { ...state };
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromEngagementDetails.EngagementDetailsActionTypes
            .INVALIDATE_ENGAGEMENT_DETAILS: {
            const newState = { ...state };
            newState[action.engagementId] = initialState;
            return newState;
        }

    }

    return state;
}