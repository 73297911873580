import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DmInnerTileComponent } from "./inner-tile.component";
import { CommonComponentsModule } from "../../entry/common-components.module";

@NgModule({
    declarations: [
        DmInnerTileComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule
    ],
    providers: [
    ],
    entryComponents: [
        DmInnerTileComponent
    ],
    exports: [
        DmInnerTileComponent
    ],
})
export class DmInnerTileModule { }
