<div class="dm-inner-tile text-align-center" [ngClass]="{'dm-inner-tile-onethird-view': !isTwoThirdTile,
'dm-inner-tile-budget-cost': data.title === 'Contract Baseline Cost', 
'dm-inner-tile-additional-cost': data.title === 'Additional Cost',
 'dm-inner-tile-cost-savings': data.title === 'Cost Savings',
  'dm-inner-tile-projected-delivery-cost': data.title === 'CFP Planned Cost'}">
  <div class="dm-inner-tile-cost" [title]="data.cost" [ngClass]="{'dm-inner-tile-budget-cost_value': data.title === 'Contract Baseline Cost', 
   'dm-inner-tile-additional-cost_value': data.title === 'Additional Cost',
    'dm-inner-tile-cost-savings_value': data.title === 'Cost Savings',
     'dm-inner-tile-projected-delivery-cost_value': data.title === 'CFP Planned Cost'}">{{ data.cost | numberSuffix: 2
    }}</div>
  <div class="dm-inner-tile-title">{{data.title}}</div>
</div>