import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FilterModalComponent } from "./project-filter-modal.component";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";


@NgModule({
    declarations: [
        FilterModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        FilterModalComponent
    ],
    exports: [
        FilterModalComponent
    ]
})
export class FilterModalModule { }