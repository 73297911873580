import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IInvoiceItemDataModel } from "../../components/invoices/invoice-table-data/invoice-table-data.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

/**
 * InvoiceService
 *
 * @export
 * @class InvoiceService
 */
@Injectable()
export class InvoiceService extends DmServiceAbstract {
    private invoiceServiceBaseUri: string;
    private invoiceSubscriptionKey: string;

    /**
     * Creates an instance of InvoiceService
     *
     * @param {DataService} dataService
     * @param {ConfigManagerService} configmanagerService
     * @memberof InvoiceService
     */
    public constructor(@Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.InvoiceService );
        this.invoiceServiceBaseUri = this.configmanagerService.getValue<string>("salesOrderServiceBaseUri");
        this.invoiceSubscriptionKey = this.configmanagerService.getValue<string>("salesOrderServiceSubscriptionKey");
    }

    /**
     * get invoices data by passing engagement id
     *
     * @param {string} engId
     * @param {number} bpid
     * @returns {Promise<any>}
     * @memberof InvoiceService
     */
    public getInvoicesDataByEngagementID(engId: string, bpid: number): Promise<IInvoiceItemDataModel[]> {
        const url = `${this.invoiceServiceBaseUri}/invoices?wbsID=${engId}&bpId=${bpid}`;
        return this.dataService.getData(url, this.invoiceSubscriptionKey, APIConstants.InvoiceAPI, null, true, true);
    }

    /**
     * get invoices pdf by passing document id
     *
     * @param {number} docID
     * @returns {Promise<any>}
     * @memberof InvoiceService
     */
    public getInvoicesPDFByDocumentID(docID: number): Promise<any> {
        const url = `${this.invoiceServiceBaseUri}/invoices/pdf/${docID}`;
        return this.dataService.getBlobData(url, this.invoiceSubscriptionKey, APIConstants.InvoiceGetPdf);
    }
}
