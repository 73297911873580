import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";


import { IInternalEngagementFinancialsListV2 } from "../../common/services/contracts/portfolio.model";
import { ProjectService } from "../../common/services/project.service";
import * as internalFinancialDetailsActions from "./internal-financial-details.action";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class InternalFinancialDetailsEffect {

    @Effect()
    public loadInternalFinancialDetails$ = this.actions$
        .ofType(internalFinancialDetailsActions.InternalFinancialDetailsActionTypes.LOAD_INTERNAL_FINANCIAL_DETAILS)
        .pipe(
            switchMap((action: internalFinancialDetailsActions.LoadInternalFinancialDetails) => fromPromise(
                this.projectService.getInternalEngagementFinancialsListV2([action.engagementId])
            )
                .pipe(
                    map((response: IInternalEngagementFinancialsListV2[]) => new internalFinancialDetailsActions.LoadInternalFinancialDetailsSuccess(action.engagementId, response)),
                    catchError((error) => of(new internalFinancialDetailsActions.LoadInternalFinancialDetailsFail(action.engagementId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService,
    ) { }
} 