<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'190px'" [tileHeight]="'190px'">
    <a additionalActionsRight *ngIf="!isComponentLoading && engagementDetails?.canEditEnagagement"
        class="action-trigger action-trigger--edit-state dm-link" type="button" (click)="openEditModal()"
        [attr.aria-label]="'Edit' + engagementDetails.name + 'dailog.'">
        <i class="icon icon-editLegacy"></i>
        Edit
    </a>
    <dm-loading loader *ngIf="isComponentLoading || !engagementId && !isServerError" [loadingText]="'Engagement Details'"
        [showLoading]="showLoading">
    </dm-loading>
    <section *ngIf="!isComponentLoading && engagementDetails">
        <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-p-b-10">
            <section class="col-md-6 col-sm-6 col-xs-12 dm-p-x-imp-0" ngClass="{ 'm-b-20':deviceFactory.isMobile()}">
                <h5 class="dm-tile__section-title">Engagement</h5>
                <p class="font-caption-alt text-capitilize">
                    <span>{{engagementDetails.name}}</span>
                </p>
            </section>
            <section class="dm-tile--no-pad col-md-6 col-sm-6 col-xs-12 dm-p-x-imp-0">
                <h5 class="dm-tile__section-title">
                    EBS State
                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4"
                        [dmPlacement]="deviceFactory.isDesktop() ? 'bottom-right' : 'bottom-left'"
                        [ngbTooltipContent]="tooltipText" [dmIcon]="'tile__icon--info icon icon-info'"
                        ariaLabelToolTip="EBS State Information">
                    </dm-tooltip>
                </h5>
                <p class="font-caption-alt text-capitilize">
                    <span>{{engagementDetails.statusDescription}}</span>
                    <a *ngIf="engagementDetails?.canRequestEbsStateChange" class="action-trigger"
                        (click)="openRequestModal()"
                        [attr.aria-label]="engagementDetails.name + 'request EBS State change dailog.'">
                        <span>Change</span>
                    </a>
                </p>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-p-b-10">
            <section class="col-md-6 col-sm-6 col-xs-12 dm-p-x-imp-0" ngClass="{ 'm-b-20':deviceFactory.isMobile()}">
                <h5 class="dm-tile__section-title">
                    Project Manager
                </h5>
                <dm-user-name *ngIf="engagementDetails.pPjm" [name]="engagementDetails.pPjm.name"
                    [alias]="engagementDetails.pPjm.alias" [showEllipses]="true" [shouldDisplayChatIcon]="true">
                </dm-user-name>
                <button class="dm-transparent-btn" type="button" (click)="openTeamStructureModal()"
                    title="Team Structure">
                    <span class="dm-tile--more-circle icon icon-moreLegacy cursor-pointer"></span>
                </button>
            </section>
            <section class="col-md-3 col-sm-6 col-xs-12 dm-p-x-imp-0" *ngIf="!deviceFactory.isMobile()">
                <h5 class="dm-tile__section-title">Start Date</h5>
                <p class="font-caption-alt text-capitilize">
                    <span [attr.aria-label]="engagementDetails.startDate | date : 'dd MMMM yyyy'">
                        {{engagementDetails.startDate | dmDisplayDateOrDashPipe}}
                    </span>
                </p>
            </section>
            <section class="col-md-3 col-sm-6 col-xs-12 dm-p-x-imp-0" *ngIf="!deviceFactory.isMobile()">
                <h5 class="dm-tile__section-title">End Date</h5>
                <p class="font-caption-alt text-capitilize">
                    <span [attr.aria-label]="engagementDetails.endDate | date : 'dd MMMM yyyy'">
                        {{engagementDetails.endDate | dmDisplayDateOrDashPipe}}
                    </span>
                </p>
            </section>
            <section class="col-md-6 col-sm-6 col-xs-12 dm-p-x-imp-0"
                *ngIf="deviceFactory.isMobile() && showLastUpdated">
                <h5 class="dm-tile__section-title">Last Updated</h5>
                <p class="font-caption-alt text-capitilize">
                    <span [attr.aria-label]="engagementDetails.lastUpdatedDate | date: 'dd MMMM yyyy hh:mm a'">
                        {{engagementDetails.lastUpdatedDate | date: 'dd-MMM-yyyy hh:mm a'}}
                    </span>
                    by
                    <span>{{engagementDetails.lastUpdatedBy | dmDisplayDashIfEmptyOrNull}}</span>
                </p>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-p-b-10">
            <section class="col-md-3 col-sm-3 col-xs-12 dm-p-x-imp-0"
                *ngIf="!deviceFactory.isMobile() && showLastUpdated">
                <h5 class="dm-tile__section-title">Last Updated</h5>
                <p class="font-caption-alt text-capitilize">
                    <span [attr.aria-label]="engagementDetails.lastUpdatedDate | date: 'dd MMMM yyyy hh:mm a'">
                        {{engagementDetails.lastUpdatedDate | date: 'dd-MMM-yyyy hh:mm a'}}
                    </span>
                    by
                    <span>{{engagementDetails.lastUpdatedBy}}</span>
                </p>
            </section>
            <section class="col-md-3 col-sm-6 col-xs-12 dm-p-x-imp-0" *ngIf="deviceFactory.isMobile()">
                <h5 class="dm-tile__section-title">Start Date</h5>
                <p class="font-caption-alt text-capitilize">
                    <span [attr.aria-label]="engagementDetails.startDate | date : 'dd MMMM yyyy'">
                        {{engagementDetails.startDate | dmDisplayDateOrDashPipe}}
                    </span>
                </p>
            </section>
            <section class="col-md-3 col-sm-6 col-xs-12 dm-p-x-imp-0" *ngIf="deviceFactory.isMobile()">
                <h5 class="dm-tile__section-title">End Date</h5>
                <p class="font-caption-alt text-capitilize">
                    <span [attr.aria-label]="engagementDetails.endDate | date : 'dd MMMM yyyy'">
                        {{engagementDetails.endDate | dmDisplayDateOrDashPipe}}
                    </span>
                </p>
            </section>
        </section>
    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>