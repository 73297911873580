<section class="clin-dailog">
    <label class="sr-only">CLIN/SLIN Associations</label>
    <section class="modal-header">
        <button type="button" class="close set-focus" (click)="closeModal()" id="accessibilityConstants.ClosePopUp"
            aria-label="CLIN/SLIN Dialog Close" (keydown)="moveFocusPrev($event, 'accessibilityConstants.CloseClinModal')">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            CLIN/SLIN Associations
        </h4>
    </section>

    <dm-loading [loadingText]="'Clin Slin Details'" [showLoading]="showLoading" *ngIf="isLoading"
        [errorText]="errorText"></dm-loading>    
    <section class="modal-body">
        <section class="row">
            <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="clin-slin-grid" width="100%" role="presentation">                   
                    <thead class="clin-slin-grid--header">
                        <tr>
                            <th scope="col" class="width-20">                                
                                <span class="dis-block">Funding</span>
                                <span class="dis-block">View</span>
                            </th>
                            <th scope="col" class="width-8 text-right">
                                <span class="dis-block">CLIN-SLIN/</span>
                                <span class="dis-block">Funding ID</span>
                            </th>
                            <th scope="col" class="width-7 text-right">
                                <span class="dis-block">Resource</span>
                                <span class="dis-block">Item ID</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Rate</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Funded</span>
                                <span class="dis-block">Hrs</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Funded</span>
                                <span class="dis-block">Amt ({{currency}})</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Planned</span>
                                <span class="dis-block">Hrs</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Consumed</span>
                                <span class="dis-block">Hrs</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Consumed</span>
                                <span class="dis-block">Amt ({{currency}})</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">%</span>
                                <span class="dis-block">Consumed</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Unbilled</span>
                                <span class="dis-block">Hrs</span>
                            </th>
                            <th scope="col" class="width-6 text-right">
                                <span class="dis-block">Unbilled</span>
                                <span class="dis-block">Amt ({{currency}})</span>
                            </th>
                            <th scope="col" class="width-5 text-right">
                                <span class="dis-block">Billed</span>
                                <span class="dis-block">Hrs</span>
                            </th>
                            <th scope="col" class="width-5 text-right">
                                <span class="dis-block">Billed</span>
                                <span class="dis-block">Amt ({{currency}})</span>
                            </th>
                        </tr>
                    </thead>
                </table>
                <section class="scrollable-section">
                    <table class="clin-slin-grid clin-slin-requests-content grid-auto-width"
                        *ngFor="let csData of clinSlinFundingInformation">
                        <caption
                            class="clin-slin-grid--caption-project font-caption-alt request-border-high-contrast break-word">
                            <table width="100%" class="clin-slin-grid">      
                                <tbody>                          
                                    <tr class="p-t-5 col-ff no-pad-right">
                                            <td class="col-ff pad-l-8 width-20">
                                                    <button type="button" class="acc-btn" [title]="'CLIN/ARCN: '+ csData.clinSlinFundingId" [attr.aria-expanded]="csData.isTasksExpanded"
                                                        (click)="csData.isTasksExpanded = !csData.isTasksExpanded">
                                                        <span class="icon"
                                                            [ngClass]="csData.isTasksExpanded ? 'icon-ChevronUp' : 'icon-ChevronDown'"></span>
                                                    </button>
                                                    <span class="clin-slin-text-right pad">
                                                        {{csData.clinSlinFundingId}}
                                                    </span>
                                                </td>
                                        <td class="pad-l-4 width-8 text-right">{{csData.clinSlinFundingId}}</td>                              
                                        <td class="pad-l-4 width-7 text-right" scope="row"></td>                                        
                                        <td class="pad-l-4 width-6 text-right" scope="row"></td>
                                        <td class="pad-l-4 width-6 text-right" scope="row">
                                            {{csData.fundedHours  | dmDisplayDashIfEmptyOrNull}}
                                            <span *ngIf="!csData.fundedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </td>
                                        <td class="pad-l-4 width-6 text-right" scope="row">
                                            {{csData.fundedAmount  | dmDisplayDashIfEmptyOrNull}}
                                            <span *ngIf="!csData.fundedAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </td>
                                        <td class="pad-l-4 width-6 text-right" scope="row">
                                            {{csData.plannedHours  | dmDisplayDashIfEmptyOrNull}}
                                            <span *ngIf="!csData.plannedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </td>
                                        <td class="pad-l-4 width-6 text-right" scope="row"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                        <td class="pad-l-4 width-6 text-right" scope="row"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                        <td class="pad-l-4 width-6 text-right" scope="row"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                        <td class="pad-l-4 width-6 text-right" scope="row"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                        <td class="pad-l-4 width-6 text-right" scope="row"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                        <td class="pad-l-4 width-5 text-right" scope="row"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                        <td class="pad-l-4 width-5 text-right" scope="row"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </caption>

                        <thead class="clin-slin-grid--header sr-only">
                                <tr>
                                    <th scope="col" class="width-20">                                
                                        <span class="dis-block">Funding</span>
                                        <span class="dis-block">View</span>
                                    </th>
                                    <th scope="col" class="width-8">
                                        <span class="dis-block">CLIN-SLIN/</span>
                                        <span class="dis-block">Funding ID</span>
                                    </th>
                                    <th scope="col" class="width-7">
                                        <span class="dis-block">Resource</span>
                                        <span class="dis-block">Item ID</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Rate</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Funded</span>
                                        <span class="dis-block">Hrs</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Funded</span>
                                        <span class="dis-block">Amt</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Planned</span>
                                        <span class="dis-block">Hrs</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Consumed</span>
                                        <span class="dis-block">Hrs</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Consumed</span>
                                        <span class="dis-block">Amt</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">%</span>
                                        <span class="dis-block">Consumed</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Unbilled</span>
                                        <span class="dis-block">Hrs</span>
                                    </th>
                                    <th scope="col" class="width-6">
                                        <span class="dis-block">Unbilled</span>
                                        <span class="dis-block">Amt</span>
                                    </th>
                                    <th scope="col" class="width-5">
                                        <span class="dis-block">Billed</span>
                                        <span class="dis-block">Hrs</span>
                                    </th>
                                    <th scope="col" class="width-5">
                                        <span class="dis-block">Billed</span>
                                        <span class="dis-block">Amt</span>
                                    </th>
                                </tr>
                            </thead>
                        <ng-container *ngIf="csData.isTasksExpanded">                          
                            <tbody *ngFor="let role of csData.clinSlinRoleDetails">
                                <tr class="clin-slin-grid--caption request-border-high-contrast break-word">
                                    <section class="pad-12">
                                        <table class="clin-slin-grid clin-slin-requests-content"> 
                                            <tbody>                                           
                                                <tr class="p-t-7 no-pad-right">
                                                        <td class="pad-l-8 width-20">
                                                                <button type="button" class="acc-btn"
                                                                    [attr.aria-expanded]="role.isDemandsExpanded" 
                                                                    [title]="'Billing Role:' + role.roleName"
                                                                    (click)="role.isDemandsExpanded = !role.isDemandsExpanded">
                                                                    <span class="icon"
                                                                        [ngClass]="role.isDemandsExpanded ? 'icon-ChevronUp' : 'icon-ChevronDown'">
                                                                    </span>
                                                                </button>
                                                                <span class="col-2a clin-slin-text-right">
                                                                    {{role.roleName}}
                                                                </span>
                                                            </td>   
                                                    <td class="pad-l-4 width-8 text-right"></td>                                             
                                                    <td class="pad-l-4 width-7 text-right">{{role.resourceItemId  | dmDisplayDashIfEmptyOrNull}}</td>
                                                    <td class="pad-l-4 width-6 text-right"></td>                                                    
                                                    <td class="pad-l-4 width-6 text-right">
                                                        {{role.fundedHours  | dmDisplayDashIfEmptyOrNull}}
                                                        <span *ngIf="!role.fundedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                    </td>
                                                    <td class="pad-l-4 width-6 text-right">
                                                        {{role.fundedAmount  | dmDisplayDashIfEmptyOrNull}}
                                                        <span *ngIf="!role.fundedAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                    </td>
                                                    <td class="pad-l-4 width-6 text-right">
                                                        {{role.plannedHours  | dmDisplayDashIfEmptyOrNull}}
                                                        <span *ngIf="!role.plannedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                    </td>
                                                    <td class="pad-l-4 width-6 text-right">
                                                        {{role.consumedHours  | dmDisplayDashIfEmptyOrNull}}
                                                        <span *ngIf="!role.consumedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                    </td>
                                                    <td class="pad-l-4 width-6 text-right">
                                                        {{role.consumedAmount  | dmDisplayDashIfEmptyOrNull}}
                                                        <span *ngIf="!role.consumedAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                    </td>
                                                    <td class="pad-l-4 width-6 text-right">
                                                        {{role.percentageConsumed  | dmDisplayDashIfEmptyOrNull}}
                                                        <span *ngIf="!role.percentageConsumed" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                    </td>
                                                    <td class="pad-l-4 width-6 text-right"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                                    <td class="pad-l-4 width-6 text-right"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                                    <td class="pad-l-4 width-5 text-right"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                                    <td class="pad-l-4 width-5 text-right"><span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </tr>
                                <ng-container *ngIf="role.isDemandsExpanded">
                                    <tr class="font-caption-alt">
                                        <td colspan="12" class="p-6-0">
                                            <div class="font-title no-resource-text text-center pad-12 m-b-0"
                                                *ngIf="!role.associatedProjectDetails || role.associatedProjectDetails.length === 0">
                                                
                                                <p class="font-subtitle dm-tile--noRecords">
                                                   There are no projects associated with this CLIN-SLIN.
                                                </p>
                                            </div>
                                            <section *ngFor="let project of role.associatedProjectDetails">
                                                <section>
                                                    <div id="projectCaption" class="sr-only">{{project.projectName}}</div>
                                                    <table
                                                        class="clin-slin-grid--project clin-slin-requests font-caption-alt"
                                                        width="100%" aria-labelledby="projectCaption">                                                        
                                                        <thead class="clin-slin-grid--header sr-only">
                                                                <tr>
                                                                    <th scope="col" class="width-20">                                
                                                                        <span class="dis-block">Funding</span>
                                                                        <span class="dis-block">View</span>
                                                                    </th>
                                                                    <th scope="col" class="width-8">
                                                                        <span class="dis-block">CLIN-SLIN/</span>
                                                                        <span class="dis-block">Funding ID</span>
                                                                    </th>
                                                                    <th scope="col" class="width-7">
                                                                        <span class="dis-block">Resource</span>
                                                                        <span class="dis-block">Item ID</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Rate</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Funded</span>
                                                                        <span class="dis-block">Hrs</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Funded</span>
                                                                        <span class="dis-block">Amt</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Planned</span>
                                                                        <span class="dis-block">Hrs</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Consumed</span>
                                                                        <span class="dis-block">Hrs</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Consumed</span>
                                                                        <span class="dis-block">Amt</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">%</span>
                                                                        <span class="dis-block">Consumed</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Unbilled</span>
                                                                        <span class="dis-block">Hrs</span>
                                                                    </th>
                                                                    <th scope="col" class="width-6">
                                                                        <span class="dis-block">Unbilled</span>
                                                                        <span class="dis-block">Amt</span>
                                                                    </th>
                                                                    <th scope="col" class="width-5">
                                                                        <span class="dis-block">Billed</span>
                                                                        <span class="dis-block">Hrs</span>
                                                                    </th>
                                                                    <th scope="col" class="width-5">
                                                                        <span class="dis-block">Billed</span>
                                                                        <span class="dis-block">Amt</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        <tbody>
                                                            <tr class="request-border-top-high-contrast font-caption-alt">
                                                                <td scope="row" class="align-top p-l-40" colspan="14">
                                                                    <section
                                                                        class="clin-slin-grid--project--role-wrapper">
                                                                        <section
                                                                            class="clin-slin-grid--project--checkbox">                                                                           
                                                                            <strong class="col-2a">
                                                                                    {{project.projectName}} - {{project.projectId}}
                                                                            </strong>
                                                                        </section>
                                                                    </section>
                                                                </td>
                                                            </tr>
                                                            <ng-container>
                                                                <tr
                                                                    *ngIf="!project.associatedDemandDetails || project.associatedDemandDetails.length === 0" class="font-caption-alt">
                                                                    <td colspan="12">
                                                                        <div
                                                                            class="font-title text-center pad-12 m-b-0">
                                                                            <p class="font-subtitle dm-tile--noRecords">
                                                                                There are no demands associated with this CLIN-SLIN.
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr
                                                                    *ngFor="let demand of project.associatedDemandDetails" class="font-caption-alt break-word">
                                                                    <td class="width-20 p-l-50" scope="row">
                                                                        <p>
                                                                            {{demand.demandRoleName}}
                                                                            {{demand.demandId}}
                                                                        </p>
                                                                    </td>
                                                                    <td class="pad-l-4 width-8 text-right" scope="row"></td>
                                                                    <td class="pad-l-4 width-7 text-right" scope="row"></td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row"></td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row"></td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row"></td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row">
                                                                        {{demand.plannedHours  | dmDisplayDashIfEmptyOrNull}}
                                                                        <span *ngIf="!demand.plannedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row">
                                                                        {{demand.consumedHours  | dmDisplayDashIfEmptyOrNull}}
                                                                        <span *ngIf="!demand.consumedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row">
                                                                        {{demand.consumedAmount  | dmDisplayDashIfEmptyOrNull}}
                                                                        <span *ngIf="!demand.consumedAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row">
                                                                        <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>                                                                       
                                                                    <td class="pad-l-4 width-6 text-right" scope="row">
                                                                        {{demand.unBilledHours  | dmDisplayDashIfEmptyOrNull}}
                                                                        <span *ngIf="!demand.unBilledHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>
                                                                    <td class="pad-l-4 width-6 text-right" scope="row">
                                                                        {{demand.unBilledAmount  | dmDisplayDashIfEmptyOrNull}}
                                                                        <span *ngIf="!demand.unBilledAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>
                                                                    <td class="pad-l-4 width-5 text-right" scope="row">
                                                                        {{demand.billedHours  | dmDisplayDashIfEmptyOrNull}}
                                                                        <span *ngIf="!demand.billedHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>
                                                                    <td class="pad-l-4 width-5 text-right" scope="row">
                                                                        {{demand.billedAmount  | dmDisplayDashIfEmptyOrNull}}
                                                                        <span *ngIf="!demand.billedAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </section>
                                            </section>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </ng-container>
                    </table>
                </section>
                <section *ngIf="clinSlinFundingInformation.length == 0">
                    <p class="font-title text-center p-t-50">{{noClinSlinAssociated}}</p>
                </section>
            </section>
        </section>
    </section>
    <section class="modal-footer footer-button">
        <button type="button" id="accessibilityConstants.CloseClinModal" class="fxp-btn fxp-btn--primary" (click)="closeModal()"
            (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp')">Close</button>
    </section>
</section>