import { BulkIntEngStatusNotificationsActionTypes, BulkIntEngStatusNotificationsActions } from "./bulk-internal-engagement-status-notifications.action";
import { IBulkCreationEngagement } from "../../common/services/contracts/notification-bar.contracts";

export interface IBulkCreateNotificationState {
    ReferenceId: string;
    Engagements: IBulkCreationEngagement[];
}

export function reducer(state = {}, action: BulkIntEngStatusNotificationsActions): { [referenceId: string]: IBulkCreateNotificationState } {
    switch (action.type) {

        case BulkIntEngStatusNotificationsActionTypes.UPDATE_BULK_INT_ENG_STATUS: {
            const newState = {
                ...state,
            };
            newState[action.statusNotification.ReferenceId] = action.statusNotification;
            return newState;
        }
    }
    return state;
}
