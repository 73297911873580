import { ILoadableState } from "../reducers";
import { IFinancialSummaryDetails } from "../../common/services/contracts/changerequest.contract";
import { CrScreenDetailsActionTypes, CrScreenDetailsActions } from "./cr-screen-details.action";

// slice of crScreenDetails state
export interface ICrScreenDetailsState extends ILoadableState {
    crScreenDetails: IFinancialSummaryDetails[];
}

export const initialState: ICrScreenDetailsState = {
    crScreenDetails: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: CrScreenDetailsActions): { [engagementId: string]: ICrScreenDetailsState } {
    switch (action.type) {
        case CrScreenDetailsActionTypes.LOAD_CR_SCREEN_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }
      
        case CrScreenDetailsActionTypes.LOAD_CR_SCREEN_DETAILS_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                crScreenDetails: action.financialSummaryDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case CrScreenDetailsActionTypes.LOAD_CR_SCREEN_DETAILS_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case CrScreenDetailsActionTypes.INVALIDATE_CR_SCREEN_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}