import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { from as fromPromise, of } from "rxjs";
import { IEngagementList } from "../../../common/services/contracts/portfolio.model";
import { MyPortfolioService } from "../../../common/services/my-portfolio.service";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import * as myPortfolioActions from "./my-portfolio-engagement-list.action";

@Injectable()
export class MyPortfolioEffect {
    @Effect()
    public loadMyPortfolioEngagements$ = this.actions$
        .ofType(myPortfolioActions.MyPortfolioActionTypes.LOAD_MY_PORTFOLIO_ENGAGEMENTS)
        .pipe(
            switchMap((action: myPortfolioActions.LoadMyPortfolioEngagements) =>
                fromPromise( /* Call the API promise here */
                    Promise.all([this.projectService.getEngagementListForLoggedInUserV2(action.loadFromCache), this.sharedFunctionsService.getAllActiveDelegatedByListV2(), this.sharedFunctionsService.getAllActiveDelegatedToListV2()])
                ).pipe(
                    map(([engagementList, delegatedByList, delegatedToList]) =>
                        /* Prepayment projects are filetered out in this function */
                        this.myPortfolioService.populateEngagmentViewModelForPortfolioV2(engagementList.engagements, undefined, delegatedByList, delegatedToList)
                    ),
                    map((engagementList: IEngagementList[]) =>
                        new myPortfolioActions.LoadMyPortfolioEngagementsSuccess(engagementList, undefined) // todo pass pinned entities
                    ),
                    catchError((error) => of(new myPortfolioActions.LoadMyPortfolioEngagementsFail(error)))
                )
            ));

    public constructor(
        private actions$: Actions,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) { }
}
