import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../entry/common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { DMNotificationService } from "../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { ManageSuppliersComponent } from "./manage-suppliers.component";
import { ManageSuppliersFilterModule } from "./manage-suppliers-filter/manager-suppliers-filter.module";
import { ManageSuppliersTableDataModule } from "./manage-suppliers-table-data/manager-suppliers-table-data.module";
import { PurchaseOrderService } from "../../common/services/po.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";

@NgModule({
    declarations: [
        ManageSuppliersComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        ManageSuppliersFilterModule,
        ManageSuppliersTableDataModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        DMNotificationService,
        EngagementDetailService,
        PurchaseOrderService,
        SharedFunctionsService,
        StoreDispatchService
    ],
    exports: [
        ManageSuppliersComponent,
    ],
    entryComponents: [
        ManageSuppliersComponent,
    ]
})
export class ManageSuppliersModule { }
