<section id="financials-plan-details" class="dm-p-l-imp-0 no-pad-right col-xs-12 min-height-150">
  <section class="dm-finci-plan col-xs-12 dm-p-l-imp-0 no-pad-right" *ngIf="!isServerError">
    <dm-loading loader [loadingText]="'Financial Forecast'" [showLoading]="showLoading" *ngIf="isComponentLoading">
    </dm-loading>
    <ng-container *ngIf="!isComponentLoading">

      <!-- ===================== Start of Non-Mobile view ==================== -->

      <section class="financials-desktop table-responsive" *ngIf="!isMobileView">

        <section class="dm-tile__financial-card">
          <div class="sr-only" id="financialForecastCaption">Financial Forecast</div>

          <div role="grid" class="dm-financial-plans-grid">
            <div role="rowgroup" class="dm-grid-view-table dm-financial-plans-table">
              <div role="row" class="dm-grid-view-table__row" [ngClass]="getRowClass()">
                <div role="gridcell" class="dm-grid-view-table__headerCell"></div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Margin
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Cost ({{currency}})
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">
                  Total <br/>  <div class="display-inline-block"></div>
                  <div class="display-inline-block">
                  Revenue ({{currency}})
                    <dm-tooltip role="tooltip" [dmPlacement]="'left'"
                                  [ngbTooltipContent]="'Customer Funded + Internal Funded Revenue'"
                                  [dmIcon]="'tile__icon--info icon icon-info'"
                                  ariaLabelToolTip="Total Revenue">
                    </dm-tooltip>
                  </div>
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Customer Funded
                  <br/>  <div class="display-inline-block"></div>
                  <div class="display-inline-block">
                  Revenue ({{currency}})
                  </div>
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Internally Funded
                  <br/>  <div class="display-inline-block"></div>
                  <div class="display-inline-block">
                  Revenue ({{currency}})
                  <dm-tooltip role="tooltip" [dmPlacement]="'left'"
                                [ngbTooltipContent]="'Includes ECIF Revenue'"
                                [dmIcon]="'tile__icon--info icon icon-info'"
                                ariaLabelToolTip="Internally Funded Revenue">
                  </dm-tooltip>
                  </div>
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Labor (hours)</div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right"
                  *ngIf="isUnitBasedContract">Units (EA)</div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right" *ngIf="isFixedFee">
                  Remaining Risk<br/>  <div class="display-inline-block"></div>
                  <div class="display-inline-block">
                     Reserve ({{currency}})
                    <dm-tooltip role="tooltip" [dmPlacement]="'left'"
                                  [ngbTooltipContent]="'Total Risk Reserve Amount in Current Financial Plan - Consumed Risk Reserve in Current Financial Plan'"
                                  [dmIcon]="'tile__icon--info icon icon-info'"
                                  ariaLabelToolTip="Remaining Risk Reserve">
                    </dm-tooltip>
                  </div>
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right" *ngIf="isFixedFee">
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right" *ngIf="isFixedFee">
                  Consumed Risk<br/>
                  <div class="display-inline-block">
                     Reserve ({{currency}})
                    <dm-tooltip role="tooltip" [dmPlacement]="'left'"
                                  [ngbTooltipContent]="'Current Financial Plan Cost Excl Risk Reserve - Contract Baseline Cost Excl Risk Reserve'"
                                  [dmIcon]="'tile__icon--info icon icon-info'"
                                  ariaLabelToolTip="Consumed Risk Reserve">
                    </dm-tooltip>
                  </div>
                </div>
                <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right dm-m-r-4" *ngIf="isFixedFee">Net
                  Cost Overrun <br />
                  <div class="display-inline-block">
                    ({{currency}})
                    <dm-tooltip role="tooltip" [dmPlacement]="'left'"
                                  [ngbTooltipContent]="'[Current Financial Plan Cost excl. Risk Reserve - Remaining Current Financial Plan Risk Reserve] - [Contract Baseline Cost excl. Risk Reserve + Risk Reserve from Contract Baseline]'"
                                  [dmIcon]="'tile__icon--info icon icon-info'"
                                  ariaLabelToolTip="Net Cost Overrun">
                    </dm-tooltip>
                  </div>
                </div>
              </div>

              <div role="row" class="dm-grid-view-table__row" [ngClass]="getRowClass()" *ngIf="eacDetails && isForecastInitiated">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  Estimate at Complete (EAC)
                  <span class="dm-tile--text-font disp-block" *ngIf="lastValuationDate">
                    (<span>as of {{lastValuationDate |date : 'dd-MMMM-yyyy'}}</span>)
                  </span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Margin</span>
                  {{ eacDetails?.margin | number : '1.0-2' | dmDisplayDashIfEmptyOrNull}}<span> %</span>
                  <span *ngIf="!eacDetails?.margin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Cost</span>
                  {{ eacDetails?.cost | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!eacDetails?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Revenue</span>
                  {{ eacDetails?.revenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!eacDetails?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">External Revenue</span>
                  {{ eacDetails?.externalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!eacDetails?.externalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Internal Revenue</span>
                  {{ eacDetails?.internalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!eacDetails?.internalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Labor</span>
                  {{ eacDetails?.labor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!eacDetails?.labor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isUnitBasedContract">
                  <span class="sr-only">Unit</span>
                  {{ eacDetails?.unit | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!eacDetails?.unit" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Remaining Risk Reserve</span>
                  {{ eacDetails?.riskReserveAmount | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!eacDetails?.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Consumed Risk Reserve</span>
                  {{ eacDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!eacDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Net Cost Overrun</span>
                  {{ eacDetails?.netCostOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!eacDetails?.netCostOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>

              <div role="row" class="dm-grid-view-table__row" [ngClass]="getRowClass()" *ngIf="etcDetails && isForecastInitiated">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  Estimate to Complete (ETC)
                  <span class="dm-tile--text-font disp-block" *ngIf="lastValuationDate">
                    (<span>as of {{lastValuationDate | date : 'dd-MMMM-yyyy'}}</span>)
                  </span>
                </div>

                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Margin</span>
                  {{ etcDetails?.margin | number : '1.0-2' | dmDisplayDashIfEmptyOrNull}}<span> %</span>
                  <span *ngIf="!etcDetails?.margin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Cost</span>
                  {{ etcDetails?.cost | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!etcDetails?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Revenue</span>
                  {{ etcDetails?.revenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!etcDetails?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">External Revenue</span>
                  {{ etcDetails?.externalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!etcDetails?.externalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Internal Revenue</span>
                  {{ etcDetails?.internalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!etcDetails?.internalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Labor</span>
                  {{ etcDetails?.labor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!etcDetails?.labor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isUnitBasedContract">
                  <span class="sr-only">Unit</span>
                  {{ etcDetails?.unit | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!etcDetails?.unit" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Remaining Risk Reserve</span>
                  {{ etcDetails?.riskReserveAmount | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!etcDetails?.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Consumed Risk Reserve</span>
                  {{ etcDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!etcDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Net Cost Overrun</span>
                  {{ etcDetails?.netCostOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!etcDetails?.netCostOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>

              <div role="row" class="dm-grid-view-table__row" [ngClass]="getRowClass()" *ngIf="eacDetails && isForecastInitiated">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  Actuals
                  <span class="dm-tile--text-font disp-block" *ngIf="lastValuationActualsDate">
                    (<span>as of {{lastValuationActualsDate |date : 'dd-MMMM-yyyy'}}</span>)
                  </span>
                </div>

                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Margin</span>
                  {{ actualDetails?.margin | number : '1.0-2' | dmDisplayDashIfEmptyOrNull}}<span> %</span>
                  <span *ngIf="!actualDetails?.margin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Cost</span>
                  {{ actualDetails?.cost | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualDetails?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Revenue</span>
                  {{ actualDetails?.revenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualDetails?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">External Revenue</span>
                  {{ actualDetails?.externalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualDetails?.externalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Internal Revenue</span>
                  {{ actualDetails?.internalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualDetails?.internalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Labor</span>
                  {{ actualDetails?.labor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!actualDetails?.labor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isUnitBasedContract">
                  <span class="sr-only">Unit</span>
                  {{ actualDetails?.unit | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!actualDetails?.unit" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Remaining Risk Reserve</span>
                  {{ actualDetails?.riskReserveAmount | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualDetails?.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Consumed Risk Reserve</span>
                  {{ actualDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Net Cost Overrun</span>
                  {{ actualDetails?.netCostOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualDetails?.netCostOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>

              <div role="row" class="dm-grid-view-table__row" [ngClass]="getRowClass()" *ngIf="eacDetails && isForecastInitiated">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  Actuals (Current)
                  <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                    [ngbTooltipContent]="actualsCurrentText" [dmIcon]="'tile__icon--info icon icon-info'"
                    ariaLabelToolTip="Actuals (Current) Information">
                  </dm-tooltip>
                </div>

                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Margin</span>
                  {{ actualCurrentDetails?.margin | number : '1.0-2' | dmDisplayDashIfEmptyOrNull}}<span> %</span>
                  <span *ngIf="!actualCurrentDetails?.margin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Cost</span>
                  {{ actualCurrentDetails?.cost | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualCurrentDetails?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Revenue</span>
                  {{ actualCurrentDetails?.revenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualCurrentDetails?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">External Revenue</span>
                  {{ actualCurrentDetails?.externalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualCurrentDetails?.externalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Internal Revenue</span>
                  {{ actualCurrentDetails?.internalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualCurrentDetails?.internalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">labor</span>
                  {{ actualCurrentDetails?.labor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!actualCurrentDetails?.labor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isUnitBasedContract">
                  <span class="sr-only">Unit</span>
                  {{ actualCurrentDetails?.unit | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!actualCurrentDetails?.unit" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Remaining Risk Reserve</span>
                  {{ actualCurrentDetails?.riskReserveAmount | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualCurrentDetails?.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Consumed Risk Reserve</span>
                  {{ actualCurrentDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualCurrentDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Net Cost Overrun</span>
                  {{ actualCurrentDetails?.netCostOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!actualCurrentDetails?.netCostOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>

              <div role="row" class="dm-grid-view-table__row"
                [ngClass]="getRowClass()"
                *ngIf="currentBaseLinePlanDetails && currentBaseLineDetails">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  Current Financial Plan ({{currentBaseLinePlanDetails.statusDescription}})
                </div>

                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Margin</span>
                  {{currentBaseLineDetails?.margin | number : '1.0-2' | dmDisplayDashIfEmptyOrNull}}<span> %</span>
                  <span *ngIf="!currentBaseLineDetails?.margin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Cost</span>
                  {{ currentBaseLineDetails?.cost | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!currentBaseLineDetails?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Revenue</span>
                  {{ currentBaseLineDetails?.revenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!currentBaseLineDetails?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">External Revenue</span>
                  {{ currentBaseLineDetails?.externalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!currentBaseLineDetails?.externalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Internal Revenue</span>
                  {{ currentBaseLineDetails?.internalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!currentBaseLineDetails?.internalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">labor</span>
                  {{ currentBaseLineDetails?.labor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!currentBaseLineDetails?.labor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isUnitBasedContract">
                  <span class="sr-only">Unit</span>
                  {{ currentBaseLineDetails?.unit | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!currentBaseLineDetails?.unit" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Remaining Risk Reserve</span>
                    {{ currentBaseLineDetails?.riskReserveAmount | dmDisplayCurrencyOrDash : currency}}
                    <span *ngIf="!currentBaseLineDetails?.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <div class="edit-rr-btn-holder">
                    <button [style.display]="showEditRiskReserveBtn ? 'flex' : 'none'" type="button" class="dm-btn" (click)="openEditRiskReserveModal()"
                      aria-label="Edit Risk Reserve">
                      <span class="dm-btn--icon icon icon-editLegacy"></span>
                      <span class="dm-btn--text">Edit</span>
                    </button>
                  </div>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Consumed Risk Reserve</span>
                  {{ currentBaseLineDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!currentBaseLineDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Net Cost Overrun</span>
                  {{ currentBaseLineDetails?.netCostOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!currentBaseLineDetails?.netCostOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>

              <div role="row" class="dm-grid-view-table__row"
                [ngClass]="getRowClass()"
                *ngIf="deliveryBaseLinePlanDetails && deliveryBaseLineDetails">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  Delivery Baseline ({{deliveryBaseLinePlanDetails?.statusDescription}})
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Margin</span>
                  {{deliveryBaseLineDetails?.margin | number : '1.0-2' | dmDisplayDashIfEmptyOrNull}}<span> %</span>
                  <span *ngIf="!deliveryBaseLineDetails?.margin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Cost</span>
                  {{ deliveryBaseLineDetails?.cost | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!deliveryBaseLineDetails?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Revenue</span>
                  {{ deliveryBaseLineDetails?.revenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!deliveryBaseLineDetails?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">External Revenue</span>
                  {{ deliveryBaseLineDetails?.externalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!deliveryBaseLineDetails?.externalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Internal Revenue</span>
                  {{ deliveryBaseLineDetails?.internalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!deliveryBaseLineDetails?.internalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Labor</span>
                  {{ deliveryBaseLineDetails?.labor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!deliveryBaseLineDetails?.labor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isUnitBasedContract">
                  <span class="sr-only">Unit</span>
                  {{ deliveryBaseLineDetails?.unit | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!deliveryBaseLineDetails?.unit" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <div class="edit-rr-btn-holder">
                    <span class="sr-only">Remaining Risk Reserve</span>
                    {{ deliveryBaseLineDetails?.riskReserveAmount | dmDisplayCurrencyOrDash : currency}}
                    <span *ngIf="!deliveryBaseLineDetails?.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                  </div>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Consumed Risk Reserve</span>
                  {{ deliveryBaseLineDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!deliveryBaseLineDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Net Cost Overrun</span>
                  {{ deliveryBaseLineDetails?.netCostOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!deliveryBaseLineDetails?.netCostOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>

              <div role="row" class="dm-grid-view-table__row"
                [ngClass]="getRowClass()"
                *ngIf="contractBaseLinePlanDetails && contractBaseLineDetails">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  Contract Baseline ({{contractBaseLinePlanDetails?.statusDescription}})
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Margin</span>
                  {{contractBaseLineDetails?.margin | number : '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span> %</span>
                  <span *ngIf="!contractBaseLineDetails?.margin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Cost</span>
                  {{ contractBaseLineDetails?.cost | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!contractBaseLineDetails?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Revenue</span>
                  {{ contractBaseLineDetails?.revenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!contractBaseLineDetails?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">External Revenue</span>
                  {{ contractBaseLineDetails?.externalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!contractBaseLineDetails?.externalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Internal Revenue</span>
                  {{ contractBaseLineDetails?.internalRevenue | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!contractBaseLineDetails?.internalRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">labor</span>
                  {{contractBaseLineDetails?.labor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!contractBaseLineDetails?.labor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isUnitBasedContract">
                  <span class="sr-only">Unit</span>
                  {{contractBaseLineDetails?.unit | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!contractBaseLineDetails?.unit" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Remaining Risk Reserve</span>
                  {{ contractBaseLineDetails?.riskReserveAmount | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!contractBaseLineDetails?.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Consumed Risk Reserve</span>
                  {{ contractBaseLineDetails?.consumedRiskReserve | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!contractBaseLineDetails?.consumedRiskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right" *ngIf="isFixedFee">
                  <span class="sr-only">Net Cost Overrun</span>
                  {{ contractBaseLineDetails?.netCostOverrun | dmDisplayCurrencyOrDash : currency}}
                  <span *ngIf="!contractBaseLineDetails?.netCostOverrun" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12" *ngIf="!isFinancialPlanData">
          <dm-no-data [noDataText]="noFinancialPlanDataText"></dm-no-data>
        </section>
      </section>
    </ng-container>
  </section>
  <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</section>
