<section [ngClass]="{'dm-p-b-10' : isCustomerEngagementsExpanded}">
    <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="tileMinHeight" [hasNonOverflowContent]="true">
        <button additionalActionsLeft id="customerEngagementsSectionToggleButton"
            class="dm-transparent-btn expandCollapseBtn" [attr.aria-expanded]="isCustomerEngagementsExpanded"
            (click)="onToggleExpandCollapse()" [title]="setTitleOrAriaLabelForExpandCollapseCustomerGrid()">
            <i class="icon icon-chevron-down cursor-pointer"
                [ngClass]="{'icon-chevron-down': !isCustomerEngagementsExpanded, 'icon-chevron-up': isCustomerEngagementsExpanded}"></i>
        </button>
        <dm-dropdown-menu additionalActionsRight *ngIf="isCustomerEngagementsExpanded"
            class="dm-int-ebs__filter-dropdown" [id]="'dm-wbs-projects-ddl'" [ariaLabel]="ariaLabelForFilterDropDown"
            [(value)]="selectedFilter" [options]="viewFilterLabels" [labels]="'key'"
            (change)="onSelectedFilterChanged(selectedFilter)">
        </dm-dropdown-menu>
        <article class="grid-data" id="dm-my-portfolio-customer-engagements" *ngIf="isCustomerEngagementsExpanded">
            <ng-container *ngIf="customerEngagementsList?.length">
                <dm-customer-engagement-grid-data-overview-tab [viewby]="viewby">
                </dm-customer-engagement-grid-data-overview-tab>
            </ng-container>
            <ng-container *ngIf="!customerEngagementsList?.length">
                <section class="col-md-12 col-sm-12 col-xs-12">
                    <dm-no-data [noDataText]="noCustomerEngagementFoundText"></dm-no-data>
                </section>
            </ng-container>
        </article>
        <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="toolTipErrorMessage"></dm-service-error>
    </dm-tile>
</section>