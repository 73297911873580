import { Component, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import moment from "moment";
import { combineLatest as observableCombineLatest } from "rxjs";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { EntityType, RouteName, DeliveryType, Components, LogEventName, SourceConstants, DestinationPage, NoDataText } from "../../common/application.constants";
import { getEntireUserPreference } from "../../store/userspreferences/userpreference.selector";
import { getMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IEngagementList, IProjectList } from "../../common/services/contracts/portfolio.model";
import { IMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IRecentlyViewedEntity, IViewedEntity } from "../../common/services/contracts/userpreference.contract";
import { IState } from "../../store/reducers";
import { IUserPreferenceState } from "../../store/userspreferences/userpreference.reducer";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../common/error.constants";


@Component({
    selector: "recently-viewed",
    templateUrl: "./recently-viewed.html",
    styleUrls: ["./recently-viewed.scss"]
})
export class RecentlyViewedComponent extends DmComponentAbstract {
    public recentlyViewedEntityList: IRecentlyViewedEntity[] = [];
    public noItemsMessage: string;
    public isComponentLoading: boolean;
    public entityType = EntityType;
    public RouteName = RouteName;
    public deliveryType = DeliveryType;
    public loadingText: string = "Recently Viewed";
    public LogEventName = LogEventName;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.RecentlyViewed;
    public tileContent: ITile;

    public constructor(
        @Inject(Store) private store: Store<IState>,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
    ) {
        super(dmLogger, Components.RecentlyViewed);
    }

    public ngOnInit(): void {
        const recentlyViewedListLimit: number = this.configmanagerService.getValue<any>("userPreference").maxLimitForRecentlyViewedList;
        this.noItemsMessage = NoDataText.NoRecentlyViewedEntityMessage;
        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
        const userPreference$ = this.store.select((getEntireUserPreference()));
        this.recentlyViewedEntityList = [];
        observableCombineLatest(
            myPortfolioEngagementList$,
            userPreference$,
            (
                engagementList: IMyPortfolioEngagementListState,
                userPreferenceState: IUserPreferenceState,
            ) => ({
                engagementList,
                userPreferenceState,
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                engagementList,
                userPreferenceState,
            }) => {
                if (engagementList.loaded && userPreferenceState.loaded) {
                    const engListFromStore: IEngagementList[] = engagementList.engagementList;
                    let projectList: IProjectList[] = [];
                    for (const engagement of engListFromStore) {
                        projectList = projectList.concat(engagement.projects);
                    }
                    const entityList: IViewedEntity[] = userPreferenceState.userPreference.viewedEntities;
                    for (const viewedEntity of entityList) {
                        if (viewedEntity.entityType.toLowerCase() === EntityType.Engagement.toLowerCase()) {
                            const recentlyViewedEngagement = engListFromStore.filter((x: IEngagementList) => x.engagementId === viewedEntity.entityId);
                            if (recentlyViewedEngagement && recentlyViewedEngagement.length) {
                                this.addEntityToRecentlyViewedList(recentlyViewedEngagement[0].engagementName, viewedEntity);
                            }
                        }
                        if (viewedEntity.entityType.toLowerCase() === EntityType.Project.toLowerCase()) {
                            const recentlyViewedProject = projectList.filter((x: IProjectList) => x.projectId === viewedEntity.entityId);
                            if (recentlyViewedProject && recentlyViewedProject.length) {
                                this.addEntityToRecentlyViewedList(recentlyViewedProject[0].projectName, viewedEntity);
                            }
                        }
                    }
                    if (this.recentlyViewedEntityList.length > recentlyViewedListLimit) {
                        this.recentlyViewedEntityList = this.recentlyViewedEntityList.slice(0, recentlyViewedListLimit);
                    }
                    this.endComponentLoad();
                }
                this.isComponentLoading = engagementList.loading;
                if (engagementList.error || userPreferenceState.error) {
                    this.isServerError = true;
                }
            });
        this.tileContent = {
            title: "Recently Viewed",
            iconTitle: "icon-eye"
        };    
    }
    /**
     * Logs event when user navigates to a new page
     * using Recently Viewed Tab
     * @param destination new page the link naviagtes to
     */
    public logRecentlyViewedClicks(destination: string): void {
        const propertyBag = {};
        propertyBag[DestinationPage] = destination;
        this.dmLogger.logEvent(SourceConstants.Component.RecentlyViewed, SourceConstants.Method.LogRecentlyViewedClicks, LogEventName.PortfolioNavigationFromRecentlyViewed, propertyBag);
    }

    /**
     * Adds the given viewed entity to the recently viewed entity list and then sorts the list based on viewed by date.
     * @param entityName 
     * @param viewedEntity 
     */
    private addEntityToRecentlyViewedList(entityName: string, viewedEntity: IViewedEntity): void {
        if (this.recentlyViewedEntityList.filter((entity) => entity.entityId === viewedEntity.entityId).length) {
            this.recentlyViewedEntityList.filter((entity) => entity.entityId === viewedEntity.entityId)[0].timeElapsed = moment(viewedEntity.lastViewed).local().startOf("second").fromNow();
        } else {
            this.recentlyViewedEntityList.push({
                entityId: viewedEntity.entityId,
                entityName,
                entityType: viewedEntity.entityType,
                lastViewed: viewedEntity.lastViewed,
                deliveryType: viewedEntity.deliveryType,
                timeElapsed: moment(viewedEntity.lastViewed).local().startOf("second").fromNow()
            });
        }
        this.sortRecentlyViewedListBasedOnDate();
    }


    /**
     * Sorts the recently viewed entity list based on date--most recent date will be at the front of the list.
     */
    private sortRecentlyViewedListBasedOnDate(): void {
        this.recentlyViewedEntityList.sort((a, b) => new Date(b.lastViewed).getTime() - new Date(a.lastViewed).getTime());
    }
    
}


