import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DmDisplayDateOrDashPipe } from "../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalV2Module } from "../../modals/dm-modal-v2/dm-modal-v2.module";
import { ManageSuppliersTransactionModalComponent } from "./manage-suppliers-transaction-modal.component";
import { DmSliderModule } from "../../tiles/dm-slider/dm-slider.module";
import { ProjectService } from "../../../common/services/project.service";
import { PurchaseOrderService } from "../../../common/services/po.service";

@NgModule({
    declarations: [
        ManageSuppliersTransactionModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        DmSliderModule,
        DmModalV2Module
    ],
    providers: [
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        ProjectService,
        PurchaseOrderService
    ],
    entryComponents: [
        ManageSuppliersTransactionModalComponent,
    ],
    exports: [
        ManageSuppliersTransactionModalComponent,
    ]
})
export class ManageSuppliersTransactionModalModule { }
