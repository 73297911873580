
import { Injectable, Inject, forwardRef } from "@angular/core";
import { FxpMessageService } from "@fxp/fxpservices";
import {
    IReasonCodeProblemType, IApiResponseMessage, IDomainDataResult,
    IActionNoteType, IActionResponse, IActionMetadata,
    IInputForEditDraftRequests, IActionInputForService, IErrorObject, IResourceComittedHoursResponse
} from "./contracts/staffing-action.service.contract";
import { StaffingActionConstants } from "./staffing-action.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DataService } from "./../../../../common/services/data.service";
import { CacheService } from "../../../../common/services/cache.service";
import { CacheKeys } from "../../../../common/application.constants";
import { IResourceRequest } from "../../../../common/services/contracts/staffing.service.contract";

@Injectable()
export class StaffingActionService {

    private grmBaseUri: string;
    private grmSubscriptionKey: string;
    private staffingActions: IActionMetadata[] = [];
    private staffingRMActionsIds: number[] = [];

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(CacheService) private cacheService: CacheService) {
        this.grmSubscriptionKey = this.configManagerService.getValue<string>("grmSubscriptionKey");
        this.grmBaseUri = this.configManagerService.getValue<string>("grmbaseURL");
        this.staffingActions = (this.configManagerService.getValue<IActionMetadata[]>("StaffingActionsId"));
        this.staffingRMActionsIds = (this.configManagerService.getValue<number[]>("staffingRMActionsIds"));
    }

    /**
     * Gets GRM Suspend ReasonCode domain data from existing domain data, or by calling the GRM API
     */
    public getSuspendReasons(): Promise<IReasonCodeProblemType[]> {
        const suspendId = this.staffingActions.filter((sn) => sn.name === "suspend")[0].id;
        return this.getReasonCodeProblemTypesDomainData()
            .then((response) => {
                return Promise.resolve(response.DomainDataResult.ReasonCodeProblemTypes.filter((r) => r.ParentId === suspendId));
            });
    }

    /**
     * Gets GRM UnSuspend ReasonCode domain data from existing domain data, or by calling the GRM API
     */
    public getUnSuspendReasons(): Promise<IReasonCodeProblemType[]> {
        const unSuspendId = this.staffingActions.filter((sn) => sn.name === "unSuspend")[0].id;

        return this.getReasonCodeProblemTypesDomainData()
            .then((response) => {
                return Promise.resolve(response.DomainDataResult.ReasonCodeProblemTypes.filter((r) => r.ParentId === unSuspendId));
            });
    }

    /**
     * Gets GRM UnSuspend ReasonCode domain data from existing domain data, or by calling the GRM API
     */
    public getTruncateReasons(): Promise<IReasonCodeProblemType[]> {
        const truncateId = this.staffingActions.filter((sn) => sn.name === "Truncate Request")[0].id;

        return this.getReasonCodeProblemTypesDomainData()
            .then((response) => {
                return Promise.resolve(response.DomainDataResult.ReasonCodeProblemTypes.filter((r) => r.ParentId === truncateId));
            });
    }

    /**
     * Gets GRM Cancel ReasonCode domain data from existing domain data, or by calling the GRM API
     */
    public getCancelReasons(): Promise<IReasonCodeProblemType[]> {
        const cancelId = this.staffingActions.filter((sn) => sn.name === "cancel")[0].id;

        return this.getReasonCodeProblemTypesDomainData()
            .then((response) => {
                return Promise.resolve(response.DomainDataResult.ReasonCodeProblemTypes.filter((r) => r.ParentId === cancelId));
            });
    }

    /**
     * Gets GRM Cancel ReasonCode domain data from existing domain data, or by calling the GRM API
     */
    public getActionsForRequestMaintenance(): Promise<IActionNoteType[]> {
        return this.getReasonCodeProblemTypesDomainData()
            .then((response) => {
                return Promise.resolve(response.DomainDataResult.ActionNoteTypes.filter((r) => this.staffingRMActionsIds.indexOf(r.Id) > -1));
            });
    }

    /**
     * Gets GRM Cancel ReasonCode domain data from existing domain data, or by calling the GRM API
     */
    public getReasonsForRequestMaintenance(actionId: number): Promise<IReasonCodeProblemType[]> {
        return this.getReasonCodeProblemTypesDomainData()
            .then((response) => {
                return Promise.resolve(response.DomainDataResult.ReasonCodeProblemTypes.filter((r) => r.ParentId === actionId));
            });
    }

    /**
     *
     *
     * @param {string} actionName
     * @param {number[]} assignmentId
     * @param {string} reasonCode
     * @param {string} [comments]
     * @returns {Promise<IActionResponse>}
     * @memberof StaffingActionService
     */
    public actionOnAssignments(actionName: string, assignmentId: number[], reasonCode: string, comments?: string): Promise<IActionResponse> {
        const requestPayload: IActionInputForService = {
            Action: "",
            ActionForRequests: []
        };
        requestPayload.Action = actionName;
        assignmentId.forEach((element) => {
            requestPayload.ActionForRequests.push({
                Id: element,
                ReasonCode: reasonCode,
                comments: comments ? comments : null
            });
        });

        return this.dataService.patchData(`${this.grmBaseUri}/v1/Request/Resource/Actions`, this.grmSubscriptionKey, StaffingActionConstants.UpdateResourceDetailsAPI, requestPayload);
    }

    /**
     *
     *
     * @param {string} actionName
     * @param {number[]} assignmentId
     * @param {string} reasonCode
     * @param {string} [comments]
     * @returns {Promise<IActionResponse>}
     * @memberof StaffingActionService
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public rmActionOnAssignments(actionName: string, assignmentId: number[], reasonCode: string, comments?: string, persona?: string): Promise<IActionResponse> {
        const requestPayload: IActionInputForService = {
            Action: "",
            ActionForRequests: []
        };
        requestPayload.Action = actionName;
        assignmentId.forEach((element) => {
            requestPayload.ActionForRequests.push({
                Id: element,
                ReasonCode: reasonCode,
                comments: comments ? comments : null,
                Persona: "requestor" /// Its hardcoded purposefully as from PJM-XP it would be requestor from PJM-Xp always
            });
        });

        return this.dataService.patchData(`${this.grmBaseUri}/v1/Request/Resource/Actions`, this.grmSubscriptionKey, StaffingActionConstants.UpdateResourceDetailsAPI, requestPayload);
    }


    public truncateActionOnAssignment(actionName: string, assignmentId: number, reasonCode: string, scheduleDate: Date, comments?: string): Promise<IActionResponse> {
        const requestPayload: any = {};
        requestPayload.Action = actionName;
        requestPayload.ActionForRequests = [];

        requestPayload.ActionForRequests.push({
            Id: assignmentId,
            ReasonCode: reasonCode,
            comments: comments ? comments : null,
            ActionDate: scheduleDate
        });

        return this.dataService.patchData(`${this.grmBaseUri}/v1/Request/Resource/Actions`, this.grmSubscriptionKey, StaffingActionConstants.UpdateResourceDetailsAPI, requestPayload);
    }

    /**
     * Staffing Action to Remove the Draft Role from GRM System
     *
     * @param {number[]} assignmentId
     * @returns {Promise<IActionResponse>}
     * @memberof StaffingActionService
     */
    public removeRole(assignmentId: number[]): Promise<IActionResponse> {
        let queryString: string = "";
        assignmentId.forEach((element) => {
            queryString += `&ids=${element}`;
        });

        return this.dataService.deleteData(`${this.grmBaseUri}/v1/Request/Resource/Draft?${queryString}`, this.grmSubscriptionKey);
    }

    /**
     * Staffing Action to Edit the Draft Role from GRM System
     * @pa
     * @memberof StaffingActionService
     */
    public editResourceRequests(assignmentDetails: IInputForEditDraftRequests): Promise<any> {
        return this.dataService.patchData(`${this.grmBaseUri}/v1/Request/Resources/Draft`, this.grmSubscriptionKey, StaffingActionConstants.ActionEditResourceRequests, assignmentDetails);
    }

    /**
     * Staffing Action to add role
     *
     * @param {IResourceRequest[]} requestPayload
     * @param {string} grmProjectId
     * @memberof StaffingActionService
     */
    public addRoleToExistingProject(requestPayload: { [key: string]: IResourceRequest[] }, grmProjectId: string): Promise<boolean> {
        return this.dataService.postData(`${this.grmBaseUri}/v1/Request/Project/${grmProjectId}/Resources?isDraft=true`, this.grmSubscriptionKey, StaffingActionConstants.ActionSplit, requestPayload);
    }


    public onSuccessAPICall(actionType: string, httpResponseMessage: IActionResponse): IApiResponseMessage {
        let successMessage = "";
        const message: IApiResponseMessage = {
            successMessage: "",
            failureMessage: ""
        };

        let successCount = 0;
        let failureCount = 0;
        let failureMessage = "";

        let responseMsg: any;
        httpResponseMessage.data ?
            responseMsg = httpResponseMessage.data :
            responseMsg = httpResponseMessage;

        responseMsg.EditResponses.forEach((element) => {
            if (element.IsSuccess) {
                successMessage = successCount >= 1 ? successMessage + " ; " : successMessage;
                successMessage += element.Id.toString();
                successCount++;
            } else {
                failureMessage = failureCount >= 1 ? failureMessage + " ; " : failureMessage;
                failureMessage += element.Id.toString() + ", Message: " + element.ErrorMessage;
                failureCount++;
            }
        });

        if (successCount > 0) {
            successMessage = "Assignment Id(s): " + successMessage + " " + actionType;
        }

        if (failureCount > 0) {
            failureMessage = "Assignment Id(s): " + failureMessage;
        }

        message.successMessage = successMessage;
        message.failureMessage = failureMessage;

        return message;
    }

    /**
     * Display respective message on validation based
     *
     * @param {IApiResponseMessage} apiMessages
     * @param {string} validationMessage
     * @memberof StaffingActionService
     */
    public displayMessages(apiMessages: IApiResponseMessage, validationMessage: string): void {

        if (apiMessages.successMessage) {
            this.fxpMessageService.addMessage(apiMessages.successMessage, "success", false);
        }

        if (validationMessage) {
            this.fxpMessageService.addMessage(validationMessage + " " + apiMessages.failureMessage, "error", false);
        } else if (apiMessages.failureMessage) {
            this.fxpMessageService.addMessage(apiMessages.failureMessage, "error", false);
        }
    }

    /**
     * Display error message if any error when calling API
     *
     * @param {string} actionType
     * @param {*} errorObject
     * @memberof StaffingActionService
     */
    public onErrorAPICall(actionType: string, errObj: IErrorObject): void {
        // todo log telemetry about the actionType
        if (errObj && errObj.data.ErrorMessage) {
            this.fxpMessageService.addMessage(errObj.data.ErrorMessage, "error", true, errObj.data.ExceptionIdentifier);
        } else {
            this.fxpMessageService.addMessage(JSON.stringify(errObj), "error", true);
        }
    }

    /**
     * Gets domain data from the GRM API
     */
    public getReasonCodeProblemTypesDomainData(): Promise<IDomainDataResult> {
        const inputData = {
            DomainDataLists: ["ReasonCodeProblemTypes", "ActionNoteTypes"]
        };
        return this.cacheService.get(
            CacheKeys.DomainDataReasonCodeActionNotes.KeyName,
            () => this.dataService.postData(`${this.grmBaseUri}/v1/Domain/GetDomainData`, this.grmSubscriptionKey, StaffingActionConstants.ResourceDomainDataAPI, inputData),
            CacheKeys.DomainDataReasonCodeActionNotes.Duration
        );
    }

    /**
     * Gets the resource committed hours based on demandid, resourcerequestid, resourceitemid
     *  
     * @param {string} demandId
     * @param {string} resourceItemId
     * @param {string} requestId
     * @memberof StaffingActionService
     */
    public getResourceComittedHours(demandId: string, resourceItemId?: string, requestId?: string): Promise<IResourceComittedHoursResponse> {
        let url: string = this.grmBaseUri + "/v1/Request/GetSumOfRequestDuration?demandId=" + demandId;
        const delimeter = "&";
        if (resourceItemId) {
            url = url.concat(delimeter + "resourceItemId=" + resourceItemId);
        }
        if (requestId) {
            url = url.concat(delimeter + "requestId=" + requestId);
        }
        return this.dataService.postData(url, this.grmSubscriptionKey, StaffingActionConstants.GetResourceComittedHours, undefined);
    }
}
