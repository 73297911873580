import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddInternalEngagementProjectComponent } from "./add-internal-engagement-project.component";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ProjectService } from "../../../common/services/project.service";
import { AddRolesModule } from "../add-roles/add-roles.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { OneProfileService } from "../../../common/services/one-profile.service";

@NgModule({
    declarations: [
        AddInternalEngagementProjectComponent,
    ],
    imports: [
        AddRolesModule,
        CommonComponentsModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    providers: [
        ConfigManagerService,
        SharedFunctionsService,
        DMLoggerService,
        ProjectService,
        OneProfileService
    ],
    entryComponents: [
        AddInternalEngagementProjectComponent,
    ],
    exports: [
        AddInternalEngagementProjectComponent,
    ]
})
export class AddInternalEngagementProjectModule { }
