<section class="dm-p-x-12-y-imp-0">
    <section class="row">
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-lg-6 col-md-6 col-sm-6 dm-p-imp-0 dm-m-r-15">
                <dm-manage-ebs-entity-details></dm-manage-ebs-entity-details>
            </section>
            <section class="col-lg-6 col-md-6 col-sm-6 dm-p-imp-0">
                <dm-entity-team-structure></dm-entity-team-structure>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-m-b-40">
            <dm-engagement-breakdown-structure></dm-engagement-breakdown-structure>
        </section>
    </section>
</section>