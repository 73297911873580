<section class="col-md-12 col-sm-12 col-lg-12">
    <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'calc(100vh - 125px)'" [hasNonOverflowContent]="true">
        <section *ngIf="!isComponentLoading">       
            <section class="col-lg-12 col-md-12 col-sm-12 p-0" *ngIf="invoiceList.length > 0"
                [ngClass]="{'dm-tile--p-b-12': deviceFactory.isSmallScreen}">
                <section class="col-lg-9 col-md-12 col-sm-12 p-0">
                    <!-- If the API for getting invoices fails (so showLoading is set to false), do not show the filter. -->
                    <dm-invoice-filter *ngIf="!isComponentLoading && projects && projects.length" class="dm-invoice-filter"
                        [selectedProjectId]="selectedProjectId" [projects]="projects" [filteredStatus]="selectedStatus"
                        [invoiceList]="invoiceFilteredList" (statusChange)="onStatusChange($event)"
                        (invoiceNumberChange)="onInvoiceNumberChange($event)" (projectChange)="onProjectChange($event)">
                    </dm-invoice-filter>
                    <p role="status" class="sr-only" aria-atomic="true">
                        <span id="filterResultMsg"></span>
                    </p>
                </section>
                <section class="col-lg-3 col-md-12 col-sm-12 p-0 dm-invoices-progress">
                    <section class="dm-tile__invoices-progress-refresh">
                        <dm-refresh *ngIf="isInvoicesProgressBarLoading" [showSpinning]="isInvoicesProgressBarLoading"
                            [enableRefresh]="false" [loadingText]="'Invoices Progress'"></dm-refresh>
                    </section>
                    <section *ngIf="!isInvoicesProgressBarLoading">
                        <dm-circular-progress [class]="['col-md-6 col-sm-6 p-0']" [percent]="invoicedAmtPercentage"
                            [subtitle]="'Invoiced'" [radius]="45" [space]="-13" [outerStrokeWidth]="13"
                            [innerStrokeWidth]="13" [outerStrokeColor]="'#0078D6'" [innerStrokeColor]="'#909090'" [valueFontSize]="15" [subtitleDistance]="14">
                        </dm-circular-progress>
                        <section class="col-md-6 col-sm-6 p-0 dm-invoices-progress-info">
                            <section class="dm-invoices-progress-info__item"
                                [ngClass]="{'pull-right': deviceFactory.isMobile()}">
                                <section
                                    class="dm-invoices-progress-info__legend dm-invoices-progress-info__legend--completed">
                                </section>
                                <section class="dm-invoices-progress-info__description">
                                    {{totalInvoicedAmount | currency: currencySymbol}}
                                    Invoiced
                                </section>
                            </section>
                            <section class="dm-invoices-progress-info__item">
                                <section class="dm-invoices-progress-info__legend dm-invoices-progress-info__legend--total">
                                </section>
                                <section class="dm-invoices-progress-info__description">
                                    {{totalContractAmount - totalInvoicedAmount | currency: currencySymbol}}
                                    Remaining
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <dm-invoice-table-data *ngIf="invoiceList.length > 0" class="dm-invoice-table-data" [selectedStatus]="selectedStatus"
                [invoiceNumber]="invoiceNumber" [selectedProjectId]="selectedProjectId" [limitInvoices]="limitInvoices"
                [showCaption]="true" [sortVal]="invoiceSortVal"
                [isCurrentUserPartOfTeamStructure]="isCurrentUserPartOfTeamStructure">
            </dm-invoice-table-data>
             <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-no-data-section" *ngIf="invoiceList.length===0">
               <dm-no-data [noDataText]="noInvoicesText" showImage="true">
               </dm-no-data>
             </section>
             <section class="col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad dm-m-b-10" *ngIf="isServerError">
               <dm-service-error [ngbTooltipContent]="errorText"></dm-service-error>
             </section>
        </section>
        <dm-loading loader [loadingText]="'Invoices'" [showLoading]="showLoading"
            *ngIf="isComponentLoading && !isServerError"></dm-loading>
    </dm-tile>
</section>