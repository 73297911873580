import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "filter"
})
export class FilterPipe implements PipeTransform {
    /**
     * Filters an array for values that match the given object keys and values.
     * If the array is empty, undefined, or if the object is empty, the array is returned without filtering
     * 
     * Example: myArray | filter: {name: 'Sara', age: 20}   
     * This will return a subset of myArray where the values in myArray have BOTH property name of 'Sara' and age of 20.
     * @param array 
     * @param field 
     * @param isDescending 
     */
    public transform(array: any[], filterObject: object): any[] {
        if (!array || !array.length || !filterObject || !Object.keys(filterObject).length) {
            return array;
        }

        const filteredArray: any[] = array.filter((x) => {
            let valueMatches: boolean = false;
            Object.keys(filterObject).forEach((key) => {
                if (!filterObject[key]) {
                    valueMatches = true;
                } else {
                    valueMatches = (x[key] === filterObject[key]);
                }
            });
            return valueMatches;
        });
        return filteredArray;
    }
}