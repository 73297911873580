import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToggleSectionComponent } from "./toggle-section.component";

@NgModule({
    declarations: [
        ToggleSectionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
    ],
    entryComponents: [
        ToggleSectionComponent
    ],
    exports: [
        ToggleSectionComponent
    ],
})
export class ToggleSectionModule { }
