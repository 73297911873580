import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { TypeAheadSkillsComponent } from "./type-ahead-skills.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        TypeAheadSkillsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        OneProfileService
    ],
    entryComponents: [
        TypeAheadSkillsComponent
    ],
    exports: [
        TypeAheadSkillsComponent
    ],
})
export class TypeAheadSkillsModule { }
