import { Component, Input, Inject, forwardRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { Components, CacheKeys, CacheStorageOptions, AccessibilityConstants, SourceConstants, LogEventConstants } from "../../../../common/application.constants";
import { CacheService } from "../../../../common/services/cache.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IFinancialRoles } from "../../../../common/services/contracts/projectservice-functions.contract";
import { InvalidateResourceRequestsProjectContext, LoadResourceRequestsProjectContext } from "./../../../../store/resource-requests-project-context/resource-requests-project-context.action";
import { IResourceRequest } from "../../../../common/services/contracts/staffing.service.contract";
import { IEngineRuleVerificationResponse, IRule } from "../../staffing-command-bar-common/services/contracts/rule-engine.service.contract";
import { ISplitAssignment, IDemandDetails, ISplitAssigmentModel, IInputForEditDraftRequests } from "../../staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { IState } from "../../../../store/reducers";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmError } from "../../../../common/error.constants";

declare const require: any;

@Component({
    selector: "scb-split-planned-modal",
    templateUrl: "./split-planned-modal.component.html",
    styleUrls: ["./split-planned-modal.component.scss"],
})
export class SplitPlannedModalComponent extends DmModalAbstract {
    @Input() public assignmentList: ISplitAssignment[];
    @Input() public wbsId: string;
    @Input() public resourceId: string;

    public demandDetails: IDemandDetails;
    public errorSummaryText: string;
    public isLoading: boolean = false;
    public isSubmitDisabled: boolean = false;
    public lblMessage: string;
    public loadingText: string = "Loading Data for Split Role";
    public roleDetails: ISplitAssigmentModel[];
    public newRoleDetails: ISplitAssigmentModel[];
    public roleValues: IFinancialRoles[] = [];
    public showLoading: boolean = true;
    public splitResourceEndDate: Date = new Date();
    public splitResourceEndDatePlaceholder: string;
    public splitResourceHours: number;
    public splitResourceRole: string;
    public splitResourceStartDate: Date = new Date();
    public splitResourceStartDatePlaceholder: string;
    public totalHours: number = 0;
    public splitInformation: string = "";
    public accessibilityConstants = AccessibilityConstants;
    public splitPlannedErrorMessages = DmError.Staffing;
    public validationResults: IEngineRuleVerificationResponse;   


    private idForNewRoles: number = 1;
    private invalidResourceRequestStatus: string[] = ["Closed", "Cancelled"];

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(StaffingActionService) private staffingActionService: StaffingActionService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(RuleEngineService) private ruleEngineService: RuleEngineService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(CacheService) private cacheService: CacheService
    ) {
        super(activeModal, dmLogger, Components.StaffingCommandBarSplitPlannedModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        // Check for Input data      
        if (!this.assignmentList || this.assignmentList.length <= 0) {
            this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataMissing, "error", false, uuid());
            this.closeModal();
        } else if (this.assignmentList.length > 1) {
            this.fxpMessageService.addMessage(DmError.Staffing.MultipleResourcesHasBeenSelected, "error", false, uuid());
            this.closeModal();
        }
        this.isLoading = true;
        const rulePath: IRule[] = require("../../staffing-command-bar-common/Rules/splitRules.json");
        this.ruleEngineService.addRules(rulePath);
        this.ruleEngineService.runRules(this.assignmentList)
            .then((result: IEngineRuleVerificationResponse) => {
                this.validationResults = result;
                if (!this.validationResults.isActionable) {
                    return Promise.reject(result);
                }
                return this.configManagerService.initialize();
                
            }).then(() => {
                return this.cacheService.get(CacheKeys.FinancialRoles.KeyName, () => this.projectServiceFunctions.getFinancialRoles(), CacheKeys.FinancialRoles.Duration, CacheStorageOptions.LocalStorage);
            }).then((roles: IFinancialRoles[]) => {
                if (roles && roles.length > 0) {
                    this.roleValues = roles;
                }
                return this.validateInputModel();
            }).then((result: boolean) => {
                if (result) {
                    this.fxpMessageService.addMessage(DmError.Staffing.ResourceManagementBusinessDaysToStaff, "warning", true);
                    this.demandDetails = this.assignmentList[0].demandDetails;
                    this.buildAssignmentModel();
                    this.addSplitRow();
                }
                this.isLoading = false;
            }).catch((error) => {
                // Todo Show errors if rule validation fails
                if (error && error.validationMessage) {
                    this.logError(SourceConstants.Method.NgOnInit, error, error.validationMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.fxpMessageService.addMessage(error.validationMessage, "error", true);
                }
                this.closeModal();
            });

    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CancelRequest);
        }
    }

    /**
     * Logs the "split further" click event.
     *
     * @memberof SplitPlannedModalComponent
     */
    public logSplitFurther(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LogSplitFurther, LogEventConstants.StaffingSplitFurtherClicked);
    }

    /**
     * This add an empty row to the Split Grid
     *
     * @private
     * @memberof SplitPlannedModalComponent
     */
    public addSplitRow(): void {
        const newRole: ISplitAssigmentModel = {
            assignmentId: null,
            endDate: this.assignmentList[0].demandDetails.endDate,
            startDate: this.addDays(),
            isAdded: true,
            requestedDuration: 0,
            roleDescription: this.roleValues.filter((roleValue) => roleValue.roleName.toLowerCase() === this.assignmentList[0].demandDetails.roleDescription.toLowerCase())[0],
            id: this.idForNewRoles,
            showDateValidationMessage: false
        };
        if (!newRole.roleDescription) {
            newRole.roleDescription = this.roleValues[0];
        }
        newRole.showDateValidationMessage = moment(newRole.endDate).isSameOrBefore(newRole.startDate);
        this.newRoleDetails.push(newRole);
        this.enableSubmitButton();
        this.idForNewRoles = this.idForNewRoles + 1;
        this.onHoursEntered();
        this.splitInformation = DmError.Staffing.SplitInformation;
        this.sharedFunctionsService.delayExecution(4000).then(() => {
            this.splitInformation = "";
        });
    }

    /**
     * This calculates in the grid     
     * @private
     * @param rowNumber
     */
    public deleteRow(rowNumber: number): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.DeleteRow, LogEventConstants.SplitRoleDeleted);
        const addedRows = this.newRoleDetails.filter((role) => role.isAdded === true);
        if (addedRows && addedRows.length > 1) {
            this.newRoleDetails = this.newRoleDetails.filter((object) => object.id !== rowNumber);
            this.onHoursEntered();
        }
    }

    /**
     * Submit split role
     *
     * @memberof SplitPlannedModalComponent
     */
    public submitForSplit(): void {
        this.createAssignmentForExistingProject();
    }

    /**
     * On Role Change Selection
     *
     * @memberof SplitPlannedModalComponent
     */
    public onChangeRole(newValue: string, index: number): void {
        if (this.roleDetails && this.roleDetails.length && this.roleDetails[index]) {
            const filteredRoleValues = this.roleValues.filter((roleValue) => roleValue.roleName.toLowerCase() === newValue.toLowerCase());
            if (filteredRoleValues.length) {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OnChangeRole, LogEventConstants.SplitPlanRoleSelected, {role: newValue});
                this.roleDetails[index].roleDescription = filteredRoleValues[0];
            }
        }
    }

    /**
     * On Role Change Selection
     *
     * @memberof SplitPlannedModalComponent
     */
    public onChangeRoleForNewRoles(newValue: string, index: number): void {
        if (this.newRoleDetails && this.newRoleDetails.length && this.newRoleDetails[index]) {
            const filteredRoleValues = this.roleValues.filter((roleValue) => roleValue.roleName.toLowerCase() === newValue.toLowerCase());
            if (filteredRoleValues.length) {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OnChangeRoleForNewRoles, LogEventConstants.SplitPlanRoleSelected, {role: newValue});
                this.newRoleDetails[index].roleDescription = filteredRoleValues[0];
            }
        }
    }

    /**
     * Close Popup*
     * @memberof SplitPlannedModalComponent
     */
    public closeModal(): void {
        this.assignmentList = [];
        super.closeModal();
        const dropDownId = "Role Actions Dropdown " + this.resourceId;
        this.sharedFunctionsService.focus(dropDownId, true);
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     * @memberof SplitPlannedModalComponent
     */
    public onStartDateChange(startDate: Date, newRoleDetail: ISplitAssigmentModel): void {
        newRoleDetail.startDate = startDate;
        newRoleDetail.showDateValidationMessage = moment(newRoleDetail.endDate).isSameOrBefore(newRoleDetail.startDate);
        this.enableSubmitButton();
    }

    /**
     * Capturing emitted end date value
     * @param {Date} endDate
     * @memberof SplitPlannedModalComponent
     */
    public onEndDateChange(endDate: Date, newRoleDetail: ISplitAssigmentModel): void {
        newRoleDetail.endDate = endDate;
        newRoleDetail.showDateValidationMessage = moment(newRoleDetail.endDate).isSameOrBefore(newRoleDetail.startDate);
        this.enableSubmitButton();
    }

    /**
     * Check to enable submit button    
     * @memberof SplitPlannedModalComponent
     */
    public enableSubmitButton(): void {
        const rolesFilter = [...this.roleDetails, ...this.newRoleDetails].filter((object) => object.showDateValidationMessage === true);
        if (rolesFilter.length) {
            this.isSubmitDisabled = true;
        } else {
            this.isSubmitDisabled = false;
        }
    }

    /**
     * Build the Assignment Model for binding
     *
     * @private
     * @memberof SplitPlannedModalComponent
     */
    private buildAssignmentModel(): void {
        this.newRoleDetails = [];
        this.roleDetails = [];
        if (this.assignmentList.length && this.assignmentList[0].resourcesUnderDemand && this.assignmentList[0].resourcesUnderDemand.length > 0) {
            this.assignmentList[0].resourcesUnderDemand.forEach((item) => {
                if (this.invalidResourceRequestStatus.indexOf(item.ResourceRequestStatusEnum) === -1) {
                    this.roleDetails.push({
                        assignmentId: item.ResourceRequestId,
                        endDate: item.ScheduledEndDate ? item.ScheduledEndDate : item.RequestedEndDate,
                        startDate: item.ScheduledEndDate ? item.ScheduledStartDate : item.RequestedStartDate,
                        isAdded: item.ResourceRequestStatusEnum === "Draft",
                        requestedDuration: item.RequestedDuration ? item.RequestedDuration : item.scheduledDurationInHours,
                        roleDescription: this.roleValues.filter((roleValue) => roleValue.roleName.toLowerCase() === item.Role.toLowerCase())[0],
                        id: item.ResourceRequestId,
                        showDateValidationMessage: false
                    });
                }
            });
            this.onHoursEntered();
        }
    }

    /**
     * Add seven days to the split role and convert to NgbDateStruct
     *
     * @private
     * @param {Date} date
     * @returns
     * @memberof SplitPlannedModalComponent
     */
    private addDays(): Date {
        const result = new Date();
        result.setDate(result.getDate() + 10);
        if (moment(result).isSameOrAfter(this.demandDetails.endDate)) {
            return new Date();
        }
        return result;
    }

    /**
     * This adds the hours in the grid     
     * @private
     * @param rowNumber
     */
    private onHoursEntered(): void {
        this.totalHours = [...this.roleDetails, ...this.newRoleDetails].reduce((hours, role) => hours + role.requestedDuration, 0);
    }

    /**     
     *  Check only distinct of one demand is sent if multiple demand throw error
     * @private
     * @returns {Promise<boolean>}
     * @memberof SplitPlannedModalComponent
     */
    private validateInputModel(): Promise<boolean> {
        /* Check only distinct of one demand is sent if multiple demand throw error */
        const demandIds = Array.from(new Set(this.assignmentList.map((item: ISplitAssignment) => item.demandDetails.demandId)));

        if (demandIds.length > 1) {
            this.fxpMessageService.addMessage(DmError.Staffing.MultipleDemandsHasBeenSelected, "error", false);
            return Promise.reject(false);
        }
        return Promise.resolve(true);
    }

    /**     
     *  Calls the api and triggers the submit   
     *  @memberof SplitPlannedModalComponent
     */
    private createAssignmentForExistingProject(): void {
        const listOfNewRoleToAdd = this.newRoleDetails.filter((item) => item.isAdded === true);
        const assignmentListToBeCreated: IResourceRequest[] = [];

        listOfNewRoleToAdd.forEach((item) => {
            assignmentListToBeCreated.push(this.buildAssigmentForCreation(
                item.requestedDuration,
                item.startDate,
                item.endDate,
                item.roleDescription.roleName,
                item.roleDescription.rolePartNumber));
        });
        this.isLoading = true;
        const draftResourceRequests = this.getDraftResourceRequests();
        const grmProjectId = this.assignmentList[0].resourceInformation.ProjectId.toString();
        const promises = draftResourceRequests.length > 0 ? [this.addRoleToExistingProject(assignmentListToBeCreated, grmProjectId), this.editDraftRolesinGRM(draftResourceRequests)] :
            [this.addRoleToExistingProject(assignmentListToBeCreated, grmProjectId)];
        Promise.all(promises)
            .then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.CreateAssignmentForExistingProject, LogEventConstants.StaffingRequestSplitSuccess);
                this.isLoading = false;
                this.fxpMessageService.addMessage("Successfully Split the resource request", "success", false);
                this.staffingService.invalidateProjectStaffingData([this.assignmentList[0].projectIdSelected]);
                this.closeModal();
            })
            .catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.CreateAssignmentForExistingProject, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.store.dispatch(new InvalidateResourceRequestsProjectContext(this.assignmentList[0].projectIdSelected));
                this.store.dispatch(new LoadResourceRequestsProjectContext(this.assignmentList[0].projectIdSelected));
                this.closeModal();
            });
    }

    /**
     * Add Role to existing Service Promise
     */
    private addRoleToExistingProject(resourceRequests: IResourceRequest[], projectId: string): Promise<any> {
        const payloadObject: { [key: string]: IResourceRequest[] } = {
            ResourceRequests: resourceRequests
        };
        return this.staffingActionService.addRoleToExistingProject(payloadObject, projectId);
    }

    /**
     * Edit Draft Resource Requests Promise
     */
    private editDraftRolesinGRM(draftResourceRequests: IResourceRequest[]): Promise<any> {
        const payloadObject: IInputForEditDraftRequests = {
            DraftResourceRequests: draftResourceRequests
        };
        return this.staffingActionService.editResourceRequests(payloadObject);
    }

    /**
     * Edit Draft Resource Requests Promise
     */
    private getDraftResourceRequests(): IResourceRequest[] {
        const rolesForEdit: ISplitAssigmentModel[] = this.roleDetails.filter((role) => role.isAdded === true);
        const draftResourceRequests: IResourceRequest[] = [];
        rolesForEdit.forEach((role) => {
            const resourceRequestArray = this.assignmentList[0].resourcesUnderDemand.filter((res) => res.ResourceRequestId === role.assignmentId);
            if (resourceRequestArray.length && this.checkIfResourceModified(resourceRequestArray[0], role)) {
                resourceRequestArray[0].RequestedStartDate = role.startDate;
                resourceRequestArray[0].RequestedEndDate = role.endDate;
                resourceRequestArray[0].RequestedDuration = role.requestedDuration;
                resourceRequestArray[0].DurationUnitEnum = "Hours";
                resourceRequestArray[0].Role = role.roleDescription.roleName;
                resourceRequestArray[0].RolePartNumber = role.roleDescription.rolePartNumber;
                resourceRequestArray[0].DraftResourceRequestId = role.assignmentId;
                resourceRequestArray[0].ScheduledStartDate = undefined;
                resourceRequestArray[0].ScheduledEndDate = undefined;
                draftResourceRequests.push(resourceRequestArray[0]);
            }
        });
        return draftResourceRequests;
    }

    /**
     * Check if resource request is modified
     */
    private checkIfResourceModified(draftResourceRequest: IResourceRequest, roleDetails: ISplitAssigmentModel): boolean {
        if (draftResourceRequest.RequestedDuration === roleDetails.requestedDuration
            && draftResourceRequest.Role === roleDetails.roleDescription.roleName
            && moment(draftResourceRequest.RequestedStartDate).isSame(roleDetails.startDate)
            && moment(draftResourceRequest.RequestedEndDate).isSame(roleDetails.endDate)) {
            return false;
        }
        return true;
    }


    /**
     * Builds the data for assignment creation
     */
    private buildAssigmentForCreation(duration: number, startDate: Date, endDate: Date, roleDescription: string, rolePartNumber: string): IResourceRequest {
        const copyOfExistingResource = JSON.parse(JSON.stringify(this.assignmentList[0].resourceInformation));
        copyOfExistingResource.Attachments = [];
        copyOfExistingResource.IsDraft = true;
        copyOfExistingResource.ResourceRequestId = 0;
        copyOfExistingResource.IsProjectInDraft = this.assignmentList[0].grmProjectStatus;
        copyOfExistingResource.AssignedResource = null;
        copyOfExistingResource.RequestedResource = null;
        copyOfExistingResource.ResourceManager = null;
        copyOfExistingResource.ResourceManagerName = null;
        copyOfExistingResource.SchedulePatterns = [];
        if (copyOfExistingResource.DemandResourceRequest && copyOfExistingResource.DemandResourceRequest.AdditionalFields) {
            copyOfExistingResource.DemandResourceRequest.AdditionalFields.BlobContent = null;
        }
        copyOfExistingResource.Role = roleDescription;
        copyOfExistingResource.RolePartNumber = rolePartNumber;
        copyOfExistingResource.RequestedStartDate = startDate;
        copyOfExistingResource.RequestedEndDate = endDate;
        copyOfExistingResource.RequestedDuration = duration;
        copyOfExistingResource.DurationUnitEnum = "Hours";
        copyOfExistingResource.ResourceRequestStatusEnum = "Draft";
        return copyOfExistingResource;
    }
}
