<section>
    <div role="grid" class="dm-fcr-expense-view-details">
        <div role="rowgroup" class="dm-grid-view-table" *ngIf="fcrExpenseViewDetails">
            <div role="row" class="dm-grid-view-table__row dm-fcr-expense-view-details-table__row">
                <div role="columnheader" class="dm-grid-view-table__headerCell">EBS Description / ID </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Role
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Type of
                    <br /> Contract
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Planned Cost
                    (({{planCurrency}})</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">DB Expense Cost
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="' Existing Delivery Baseline (DB) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="' Existing Delivery Baseline (DB) Cost'">
                    </dm-tooltip>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">CFP Expense Cost <dm-tooltip
                        role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan (CFP) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan (CFP) Cost'">
                    </dm-tooltip>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">
                    Additional Expense Cost <br /> ({{planCurrency}})
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'"
                        [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'">
                    </dm-tooltip>
                </div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-fcr-expense-view-details-table__row"
                *ngFor="let fcrRole of fcrExpenseViewDetails">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>{{fcrRole?.projectName}}-{{fcrRole?.serviceName}}</strong><br />
                    <span class="dm-p-b-5 display-inline-block">{{fcrRole?.taskId}}</span> <br />
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span>
                        {{fcrRole?.role === ExpenseBillableCode.Billable ? "Billable" : "Non Billable"|
                        dmDisplayDashIfEmptyOrNull}}
                    </span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span class="dm-badge" [ngClass]="fcrRole?.isFixedFeeProject ? 
                                    'contract-type-ff' : 'contract-type-tm'">
                        {{ fcrRole?.isFixedFeeProject ? "FF" : "T&amp;M"}}
                    </span>
                </div>
                <!--Planned Cost-->
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{fcrRole?.requestedCost | number : '1.2-2' | dmDisplayDashIfEmptyOrNull}}
                    <span *ngIf="!fcrRole?.requestedCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <!--DB Cost-->
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span *ngIf="fcrRole?.dbCost">{{fcrRole?.dbCost | number : '1.2-2'}}
                        {{planCurrency}} </span>
                </div>
                <!--cfpCost -->
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span *ngIf="fcrRole?.cfpCost">{{fcrRole?.cfpCost | number : '1.2-2'}}
                        {{planCurrency}} </span>
                </div>
                <!--Additional Cost = revised cfpCost - dbCost-->
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{ fcrRole?.additionalCost | number : '1.2-2'}}
                </div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-fcr-expense-view-details-table__row">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>TOTALS</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>{{fcrExpenseViewDetails | sum: 'requestedCost' |
                        number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong>{{fcrExpenseViewDetails | sum:
                        'additionalCost' | number : '1.2-2'}}</strong>
                </div>
            </div>
        </div>
    </div>
</section>