<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'287px'">
    <dm-loading loader class="dm-int-eng-projects-loader" *ngIf="isComponentLoading && !isServerError" [showLoading]="showLoading"
      [loadingText]="'Projects'"></dm-loading>
    <section class="dm-int-eng-projects__grid" role="grid" *ngIf="!isComponentLoading">
      <!-- CSS grid, Add grid column width in scss if you add a new field to grid -->
      <div role="row" class="dm-int-eng-projects__grid-row"
        [ngClass]="{'dm-int-eng-projects__grid-row-sr': isSrOut, 'dm-int-eng-projects__grid-row-ecif-presales': isECIFPreSales}">
        <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">Name</div>
        <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">EBS State<dm-tooltip role="tooltip"
            class="dm-tooltipinline" [dmPlacement]="'right'" [ngbTooltipContent]="wbsTooltipText"
            [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="EBS State Information">
          </dm-tooltip>
        </div>
        <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">Solution Area</div>
        <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">Start Date</div>
        <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">End Date</div>
        <ng-container *ngIf="isSrOut">
          <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">SR Cross Charge</div>
          <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">SR Out Cost Center/Internal Order #</div>
        </ng-container>
        <ng-container *ngIf="isECIFPreSales">
          <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">Charge Account</div>
          <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">ECIF Amount</div>
          <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">Internal Order #</div>
        </ng-container>
        <div role="gridcell" class="dm-int-eng-projects__grid-header-cell">Domain Project Manager</div>
      </div>
  
      <div role="row" *ngFor="let projectDetails of internalEngagementProjects"
        class="dm-int-eng-projects__grid-row" [ngClass]="{'dm-int-eng-projects__grid-row-sr': isSrOut, 'dm-int-eng-projects__grid-row-ecif-presales': isECIFPreSales}">
        <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">
          <strong>{{projectDetails?.name | dmDisplayDashIfEmptyOrNull}}</strong> <br />
          EBS: {{projectDetails?.id | dmDisplayDashIfEmptyOrNull}}
          <span *ngIf="!projectDetails?.name && !projectDetails?.id" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">
          {{ projectDetails?.statusDescription | dmDisplayDashIfEmptyOrNull}}
          <span *ngIf="!projectDetails?.statusDescription" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">
          {{projectDetails?.primaryDomain | dmDisplayDashIfEmptyOrNull}}
          <span *ngIf="!projectDetails?.primaryDomain" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
     
        <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">
          {{projectDetails?.startDate | date : 'dd-MMM-yyyy'}}</div>
        <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">{{projectDetails.endDate | date : 'dd-MMM-yyyy'}}
        </div>
        <ng-container *ngIf="isSrOut || isECIFPreSales">
          <div role="gridcell" class="dm-int-eng-projects__grid-body-cell" *ngIf="isECIFPreSales">
            {{projectDetails?.ecifChargeAccount}}
          </div>
          <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">
            {{projectDetails?.srCrossChargeAmount}}
            <span>{{projectDetails?.srCrossChargeType.toLowerCase() === "a"  ? projectDetails?.currency : "%"}}</span>
          </div>
          <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">
            {{projectDetails?.srCostCenter}}
          </div>
        </ng-container>
        <div role="gridcell" class="dm-int-eng-projects__grid-body-cell">
          <dm-user-name *ngIf="projectDetails.pjm" [name]="projectDetails?.pjm?.name" [alias]="projectDetails.pjm.alias"></dm-user-name>
        </div>
      </div>
    </section>
    <section *ngIf="isServerError">
        <dm-service-error [ngbTooltipContent]="errorText"></dm-service-error>
    </section>
</dm-tile>
