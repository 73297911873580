import { Component, forwardRef, Inject, Input } from "@angular/core";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, AccessibilityConstants } from "../../../../../common/application.constants";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { DmError } from "../../../../../common/error.constants";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { ICustomLink, IWbsSettings } from "../../../../../common/services/contracts/project.service.v2.contracts";
import { ProjectServiceV2 } from "../../../../../common/services/project.v2.service";
import { IUserPreferenceLinksViewModel } from "../../../../../common/services/contracts/userpreferencelinksmgmt.contract";
import { IModal } from "../../../../../components/modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";

@Component({
    templateUrl: "./add-key-links.html",
    styleUrls: ["./add-key-links.scss"]
})
export class AddKeyLinksModalComponent extends DmModalAbstract {
    @Input() public userPreferenceLinks: ICustomLink[] = [];
    @Input() public wbsId: string;

    /* Regex for url validation checks for http or https and a dot symbol */
    public urlValidationPattern = "https?://.+";
    public isAddLinksDisabled: boolean = false;
    public isUrlFieldInFocus: boolean[] = [];
    public isUserlinksLoading: boolean = false;
    public showValidationMessage: boolean = false;
    public validationMessageText: string;
    public statusMessage: string;
    public usersLinkName: string[] = [];
    public accessibilityConstants = AccessibilityConstants;
    public userPreferenceLinksErrorMessages = DmError.UserPreferenceLinks;
    public modalContent: IModal;
    public userpreferenceLinksAddForm: FormGroup;

    public get linksFormArray(): FormArray {
        return this.userpreferenceLinksAddForm.get("linksFormArray") as FormArray;
    }


    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FormBuilder) private fb: FormBuilder
    ) {
        super(activeModal, dmLogger, Components.AddKeyLinksModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Add Key Links"
        };
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.userpreferenceLinksAddForm = new FormGroup({
            linksFormArray: this.fb.array([])
        });
        this.addLinkEmptyRows();
    }

    /**
     * Close Modal Popup
     */
    public closeModalPopUp(reload: boolean = false): void {
        this.userPreferenceLinks = [];
        this.activeModal.close(reload);
    }

    /**
     * Add link -- add empty rows to the object
     */
    public addLinkEmptyRows(): void {
        this.isAddLinksDisabled = this.userPreferenceLinks.length >= 6 ? true : false;

        if (this.userPreferenceLinks.length < 7) {
            const newId = this.userPreferenceLinks.length === 0 ? 1 : this.userPreferenceLinks[this.userPreferenceLinks.length - 1].id + 1;
            this.linksFormArray.push(
                this.fb.group({
                    name: new FormControl("", Validators.required),
                    url: new FormControl("", [Validators.required, Validators.pattern(this.urlValidationPattern)]),
                    id: new FormControl(newId)
                })
            );

            if (this.linksFormArray && this.linksFormArray.value && this.linksFormArray.value.length && this.userPreferenceLinks) {
                const newLink = this.userPreferenceLinks.filter((link) => link.id === newId);
                if (newLink && !newLink.length) {
                    this.userPreferenceLinks.push(this.linksFormArray.value.filter((link) => link.id === newId)[0]);
                }
            }
        }
        const lastLinkId = this.userPreferenceLinks.length - 1;
        this.sharedFunctionsService.focus("userPreferencLinksName" + lastLinkId, true);
    }

    /**
     * Save Links
     */
    public saveUserPreferenceLinks(): void {
        /// Pending
        /// Check for Validations if any
        this.showValidationMessage = false;
        if (this.linksFormArray && this.linksFormArray.value && this.linksFormArray.value.length && this.userPreferenceLinks) {
            for (const linkForm of this.linksFormArray.value) {
                const index = this.userPreferenceLinks.findIndex((link) => link.id === linkForm.id);
                if (index > -1) {
                    this.userPreferenceLinks[index] = linkForm;
                }
            }
        }
        if (this.userPreferenceLinks.length > 0) {
            this.userPreferenceLinks.forEach((link) => {
                if (!link.url || !link.name) {
                    if (this.userPreferenceLinks.length === 1) {
                        this.validationMessageText = DmError.UserPreferenceLinks.TheEntryHasAnInvalidURLOrName;
                    } else {
                        this.validationMessageText = DmError.UserPreferenceLinks.OneOfTheEntriesHasAnInvalidURLOrName;
                    }
                    this.showValidationMessage = true;
                    return;
                }
            });
        }
        if (!this.showValidationMessage) {
            this.isUserlinksLoading = true;
            this.projectServiceV2.getWbsSettings(this.wbsId).then((response: IWbsSettings) => {
                const wbsSettings = response;
                wbsSettings.customLinks = this.userPreferenceLinks;
                this.projectServiceV2.saveWbsSettings(wbsSettings).then(() => {
                    this.fxpMessageService.addMessage("Successfully saved links", "success", false);
                    this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.SaveUserPreferenceLinks, LogEventConstants.CustomLinkSubmit);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(true);
                }).catch((error) => {
                    this.fxpMessageService.addMessage(DmError.UserPreferenceLinks.UnableToSaveLinks, "error", true);
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, DmError.UserPreferenceLinks.UnableToSaveLinks, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(false);
                });
            }).catch((error) => {
                if (error.status === 404) {
                    const wbsSettings = this.projectServiceV2.getDefaultWbsSettings(this.wbsId);
                    wbsSettings.customLinks = this.userPreferenceLinks;
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, "Successfully saved link but 404 status", ErrorSeverityLevel && ErrorSeverityLevel.Medium);
                    this.projectServiceV2.saveWbsSettings(wbsSettings).then(() => {
                        this.fxpMessageService.addMessage("Successfully saved links", "success", false);
                        this.isUserlinksLoading = false;
                        this.closeModalPopUp(true);
                    });

                } else {
                    this.fxpMessageService.addMessage(DmError.UserPreferenceLinks.UnableToSaveLinks, "error", true);
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, DmError.UserPreferenceLinks.UnableToSaveLinks, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(false);
                }
            });
        }
        this.sharedFunctionsService.focus("addKeyLinksButton001", true);
    }

    /**
     * Delete Links -Soft Delete will mark the item in deleted state later for submission
     */
    public deleteUserPreferenceLinks(index: number): void {
        const linkId = this.linksFormArray.value[index].id;
        this.linksFormArray.removeAt(index);
        this.userPreferenceLinks = this.userPreferenceLinks.filter((obj) => !(obj.id === linkId));
        this.isAddLinksDisabled = this.userPreferenceLinks.length > 6 ? true : false;
        this.sharedFunctionsService.focus("addLinkBtn", true);
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Set alert message readable for screen readers on deletion of link
     * @param action 
     * @param link 
     */
    public setStatusMessage(action: string, link: IUserPreferenceLinksViewModel): void {
        if (action === "Delete") {
            this.statusMessage = "Link " + (link.name ? ("with title" + link.name) : "without title") + " has been deleted successfully";
        }
        this.sharedFunctionsService.delayExecution(4000).then(() => {
            this.statusMessage = "";
        });
    }
}
