
import { switchMap, withLatestFrom, map, catchError } from "rxjs/operators";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { Injectable, Inject } from "@angular/core";
import { Store } from "@ngrx/store";

import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { IResourceRequestResponse } from "../../common/services/contracts/staffing.service.contract";
import { IState } from "../reducers";
import { StaffingService } from "../../common/services/staffing.service";
import * as resourceRequestsAction from "./resource-requests.action";
import { getEngagementDetails } from "../engagement-details/engagement-details.selector";

@Injectable()
export class ResourceRequestsEffects {

    @Effect()
    public loadEngagementDetails$ = this.actions$
        .ofType(resourceRequestsAction.ResourceRequestsActionTypes.LOAD_RESOURCE_REQUESTS).pipe(
            map((action: resourceRequestsAction.LoadResourceRequests) => action.engagementId),
            withLatestFrom(this.store),
            switchMap(([engagmentId, store]) => fromPromise(this.staffingService
                .getGRMRequestsProjectId(getEngagementDetails(engagmentId)(store).projects))
                .pipe(
                    map((grmResponseObject: IResourceRequestResponse) => new resourceRequestsAction.LoadResourceRequestsSuccess(engagmentId, grmResponseObject)),
                    catchError((error) => of(new resourceRequestsAction.LoadResourceRequestsFail(engagmentId, error)))
                )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        private store: Store<IState>
    ) { }
}