import { Action } from "@ngrx/store";
import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";

// 1. action types
export enum ChangeRequestActionTypes {
    LOAD_CHANGE_REQUESTS = "[ChangeRequests] Load",
    LOAD_CHANGE_REQUESTS_SUCCESS = "[ChangeRequests] Load Success",
    LOAD_CHANGE_REQUESTS_FAIL = "[ChangeRequests] Load Fail",
    INVALIDATE_CHANGE_REQUESTS = "[ChangeRequests] Invalidate"
}

// 2. action creators
export class LoadChangeRequests implements Action {
    public readonly type = ChangeRequestActionTypes.LOAD_CHANGE_REQUESTS;
}
export class LoadChangeRequestsSuccess implements Action {
    public readonly type = ChangeRequestActionTypes.LOAD_CHANGE_REQUESTS_SUCCESS;
    public constructor(public changeRequests: IChangeRequest[]) { }
}

export class LoadChangeRequestsFail implements Action {
    public readonly type = ChangeRequestActionTypes.LOAD_CHANGE_REQUESTS_FAIL;
    public constructor(public errorMessage: string) { }
}

export class InvalidateChangeRequests implements Action {
    public readonly type = ChangeRequestActionTypes.INVALIDATE_CHANGE_REQUESTS;
}

// 3. export the union creators
export type ChangeRequestActions =
    | LoadChangeRequests
    | LoadChangeRequestsSuccess
    | LoadChangeRequestsFail
    | InvalidateChangeRequests;