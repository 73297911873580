import {
    Component,
    forwardRef,
    Inject,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DeviceFactoryProvider, ErrorSeverityLevel } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import {
    AccessibilityConstants,
    Components,
    SourceConstants,
} from "../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";
import moment from "moment";
import {
    IUnitMonthlyTranasctions,
    IUnitTransactionDetail,
    IUnitTransactionResponse,
    IUnitTransactions,
} from "../../../common/services/contracts/actuals.contracts";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-actuals-transaction-modal",
    templateUrl: "./actuals-transaction-modal.html",
    styleUrls: ["./actuals-transaction-modal.scss"],
})
export class ActualsTransactionModalComponent extends DmModalAbstract {
    @Input() public demandId: string;
    @Input() public wbsId: string;
    @Input() public taskId: string;
    @Input() public currency: string;
    @Input() public taskName: string;
    @Output() public onStatusModalClose: EventEmitter<void> = new EventEmitter<void>();
    public isLoadingUnitTransactionDetails: boolean = true;
    public modalContent: IModal;
    public unitTransactionDetails: IUnitMonthlyTranasctions[];
    public unitActivityTypeCode: string;
    public unitActivityTypeName: string;
    public noTransactionsText: string = "There are no transactions available for this demand";
    public showOnlyInvoiced: boolean;
    public showOnlyBillable: boolean;
    public accessibilityConstants = AccessibilityConstants;
    private unitTransactionsApiResponse: IUnitTransactionResponse;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider))
        public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ProjectService) public projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService)
        private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.UnitTransactionDetails);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Unit Transaction Details",
            subTitle: `${this.taskId} | ${this.demandId}`,
        };

        this.projectService
            .getActualsTransactionDetails(this.taskId, this.demandId)
            .then((response: IUnitTransactionResponse) => {
                const sortedResponse = response.actuals.sort(
                    (a, b) =>
                        new Date(b.postingDate).getTime() -
                        new Date(a.postingDate).getTime()
                );
                this.unitActivityTypeCode = response.actuals[0].unitActivityTypeCode;
                this.unitActivityTypeName = response.actuals[0].unitActivityTypeName;
                this.unitTransactionsApiResponse = { actuals: sortedResponse };
                this.unitTransactionDetails =
                    this.mapUnitTransactionsViewModel(sortedResponse);
                this.isLoadingUnitTransactionDetails = false;
            })
            .catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(
                    error,
                    "Unable to get the updates and saves unit changes."
                );
                this.logError(
                    SourceConstants.Method.NgOnInit,
                    error,
                    errorMessage,
                    ErrorSeverityLevel && ErrorSeverityLevel.High
                );
                this.isLoadingUnitTransactionDetails = false;
            });
    }

    /**
     * Filters billable status transactions
     * TODO: needs to be refactored later
     * @param {boolean} showOnlyBillable
     * @memberof ActualsTransactionModalComponent
     */
    public filterBillableStatus(selectedValue: string): void {
        const unitsTransactions = this.unitTransactionsApiResponse.actuals;
        let filteredBillableList: IUnitTransactions[];
        if (unitsTransactions && unitsTransactions.length) {
            if (selectedValue === "billable") {
                filteredBillableList = this.unitTransactionsApiResponse.actuals.filter(
                    (m) => m.isBillable
                );
            } else if (selectedValue === "nonBillable") {
                filteredBillableList = this.unitTransactionsApiResponse.actuals.filter(
                    (m) => !m.isBillable
                );
            }
            this.unitTransactionDetails =
                selectedValue !== "all"
                    ? this.mapUnitTransactionsViewModel(filteredBillableList)
                    : this.mapUnitTransactionsViewModel(
                        this.unitTransactionsApiResponse.actuals
                    );
            if (selectedValue === "all" || filteredBillableList.length) {
                this.sharedFunctionsService.focus("filterBillableStatus", true);
            } else if (!filteredBillableList || !filteredBillableList.length) {
                this.sharedFunctionsService.focus("closeModalv2", true);
            }
        }
        else {
            this.sharedFunctionsService.focus("closeModalv2", true);
        }
    }

    /**
     * Filters invoiced status transactions
     * TODO: needs to be refactored later
     * @param {boolean} showOnlyBillable
     * @memberof ActualsTransactionModalComponent
     */
    public filterInvoicedStatus(selectedValue: string): void {
        const unitsTransactions = this.unitTransactionsApiResponse.actuals;
        let filteredInvoicedList: IUnitTransactions[];
        if (unitsTransactions && unitsTransactions.length) {
            if (selectedValue === "invoiced") {
                filteredInvoicedList = this.unitTransactionsApiResponse.actuals.filter(
                    (m) => m.isInvoiced
                );
            } else if (selectedValue === "notInvoiced") {
                filteredInvoicedList = this.unitTransactionsApiResponse.actuals.filter(
                    (m) => !m.isInvoiced
                );
            }
            this.unitTransactionDetails =
                selectedValue !== "all"
                    ? this.mapUnitTransactionsViewModel(filteredInvoicedList)
                    : this.mapUnitTransactionsViewModel(
                        this.unitTransactionsApiResponse.actuals
                    );
            if (selectedValue === "all" || filteredInvoicedList.length) {
                this.sharedFunctionsService.focus("filterInvoicedStatus", true);
            } else if (!filteredInvoicedList || !filteredInvoicedList.length) {
                this.sharedFunctionsService.focus("closeModalv2", true);
            }
        } else {
            this.sharedFunctionsService.focus("closeModalv2", true);
        }
    }

    /**
     * Expand and collapse monthly group
     *
     * @param {IUnitMonthlyTranasctions} item
     * @memberof ActualsTransactionModalComponent
     */
    public expandCollapse(item: IUnitMonthlyTranasctions): void {
        item.isExpanded = !item.isExpanded;
    }

    /**
     * Closes modal and emits an event to notify notification component of closure.
     *
     * @memberof ActualsTransactionModalComponent
     */
    public closeStatusModal(): void {
        this.onStatusModalClose.emit();
        this.closeModal();
    }

    /**
     * Close Filter Dropdown On Tab of Last Element
     */
    public closeDropdownOnTab(id: string): void {
        document.getElementById(id).classList.remove("open");
    }

    /**
     * move focus to specific id
     */
    public moveFocus(id: string): void {
        this.sharedFunctionsService.focus(id, true);
    }

    /**
     * Map transactions response to view model
     *
     * @private
     * @param {IUnitTransactions[]} unitTransactionsDetails
     * @return {*}  {IUnitMonthlyTranasctions[]}
     * @memberof ActualsTransactionModalComponent
     */
    private mapUnitTransactionsViewModel(
        unitTransactionsDetails: IUnitTransactions[]
    ): IUnitMonthlyTranasctions[] {
        const unitTransactions: IUnitTransactionDetail[] = [];
        if (unitTransactionsDetails && unitTransactionsDetails.length) {
            for (const response of unitTransactionsDetails) {
                const unitTransaction: IUnitTransactionDetail = {
                    postedDate: response.postingDate,
                    incurredDate: response.documentDate,
                    actualQuantity: response.actualQuantity,
                    costRate: response.costRate,
                    costAmount: response.actualCost,
                    invoicedQuantity: response.invoicedQuantity,
                    billRate: response.billRate,
                    actualRevenue: response.actualRevenue,
                    isBillable: response.isBillable,
                    isInvoiced: response.isInvoiced,
                };
                unitTransactions.push(unitTransaction);
            }
        }

        const unitMonthlyTransactions = this.groupEntriesByMonth(unitTransactions);
        return unitMonthlyTransactions;
    }

    /**
     * Groups transactions by month,year
     *
     * @private
     * @param {IUnitTransactionDetail[]} unitTransactions
     * @return {*}  {IUnitMonthlyTranasctions[]}
     * @memberof ActualsTransactionModalComponent
     */
    private groupEntriesByMonth(
        unitTransactions: IUnitTransactionDetail[]
    ): IUnitMonthlyTranasctions[] {
        // this gives an object with dates as keys
        const reducedUnitTransactions = unitTransactions.reduce((groups, item) => {
            const monthYear = moment(item.postedDate).format("MMM-YY");
            if (!groups[monthYear]) {
                groups[monthYear] = [];
            }
            groups[monthYear].push(item);
            return groups;
        }, {});

        // Add it to array with date as key
        const groupArrays = Object.keys(reducedUnitTransactions).map(
            (monthYear) => {
                return {
                    monthYear,
                    isExpanded: true,
                    unitTransactions: reducedUnitTransactions[monthYear],
                };
            }
        );
        return groupArrays;
    }
}
