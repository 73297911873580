import { Injectable, Inject } from "@angular/core";
import { ICrDemandOutput, IBillRate, ICrDemandEditViewModel, IExistingDemand, ITask, ICrExpenseOutput, ICrExpenseViewModel, ICrSubconViewModel, ICrSubconOutput, ICrUnitOutput, ICrUnitViewModel, ResourceType, ResourceTypeCode, IExistingSubcon, IExistingExpense, IExistingUnit, IEngagementFinancialPlanSummary, IChangeRequestDetails, ICrCostResource, ICrResource, IChangeRequestResourcePayloadDetails } from "./contracts/changerequest.contract";
import { ContractType, IEngagementDetailsApiV2 } from "./contracts/wbs-details-v2.contracts";
import * as uuid from "uuid/v1";
import { IFinancialRoles } from "./contracts/projectservice-functions.contract";
import { IWbsDemand } from "./contracts/project.service.v2.contracts";
import { IFcrRolesFormControlData } from "../../components/financial-change-request/fcr-roles-form-control/fcr-roles-form-control.component";
import { ConfigManagerService } from "./configmanager.service";
import { IDemandDiff, IFcrExpensesFormControlData, IFcrSubconFormControlData, IFcrUnitsFormControlData } from "./contracts/changerequestv2.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { FinancialType, BaseLineType, ExpenseBillableTypes, Services, ExpenseBillableCode } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";
import { DmError } from "../error.constants";
import { DMAuthorizationService } from "./dmauthorization.service";
import { AbstractControl } from "@angular/forms";
import { ProjectServiceV2 } from "./project.v2.service";
import { IUnitDemand, IUnitsViewModel } from "./contracts/actuals.contracts";

@Injectable()
export class ChangeRequestDemandService extends DmServiceAbstract {

    private subConRolePartNumbers: string[];
    private subconRoleInfoStrings: string[] = ["ZEXS/ESSUC", "ZEXS/ESUDCS"];
    private nonBillResourceItemId: string = "0000000000";
    private nonBillValue: string = "NON-BILL";

    public constructor(
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMAuthorizationService) private dmAuthService: DMAuthorizationService
    ) {
        super(dmLogger, Services.ChangeRequestDemandService);
        this.configurationService.initialize().then(() => {
            this.subConRolePartNumbers = this.configurationService.getValue<string[]>("subConRolePartNumbers");
        });
    }

    /**
     * Returns initial state for ICrDemandOutput when adding role.
     *
     * @returns {ICrDemandOutput}
     * @memberof ChangeRequestDemandService
     */
    public getAddRoleInitialState(): ICrDemandOutput {
        const initialEditState: ICrDemandEditViewModel = {
            hours: 0,
            resourceLocationKey: "",
            blendedCostRate: 0,
            isCostRateResolved: true,
            isProjectPFPOrPFF: false,
            existingDemand: null,
            assignedTask: null,
            billingInfo: null,
            isAdditionalHoursQuantityAllowed: true,
            isContractTypeValid: true,
            isAdditionalTimeValid: true,
            isNewHoursLessThanStaffedHours: false
        };

        return {
            uuid: uuid(),
            resourceType: ResourceType.Labor,
            isNewRequest: true,
            isSaveable: false,
            editModeEnabled: true,
            currentState: initialEditState,
            savedState: undefined,
        };
    }

    public getAddUnitInitialState(): ICrUnitOutput {
        const initialState: ICrUnitViewModel = {
            newUnits: 0,
            costRate: 0,
            isCostRateResolved: true,
            isProjectPFPOrPFF: false,
            existingResource: null,
            assignedTask: null,
            billingInfo: null,
            isContractTypeValid: true
        };

        return {
            uuid: uuid(),
            resourceType: ResourceType.Unit,
            editModeEnabled: true,
            isSaveable: false,
            isNewRequest: true,
            currentState: initialState,
            savedState: null
        };
    }

    public getAddSubconInitialState(): ICrSubconOutput {
        const intialState: ICrSubconViewModel = {
            newPlannedCost: 0,
            additionalCost: 0,
            isContractTypeValid: true
        };
        return {
            uuid: uuid(),
            resourceType: ResourceType.SubconFF,
            editModeEnabled: true,
            isSaveable: false,
            isNewRequest: true,
            currentState: intialState,
            savedState: null
        };
    }

    public getAddExpenseInitialState(): ICrExpenseOutput {
        const intialState: ICrExpenseViewModel = {
            newPlannedCost: 0,
            additionalCost: 0,
            isContractTypeValid: true
        };
        return {
            uuid: uuid(),
            resourceType: ResourceType.Expense,
            editModeEnabled: true,
            isSaveable: false,
            isNewRequest: true,
            currentState: intialState,
            savedState: null
        };
    }

    /**
     * Returns initial state for ICrDemandOutput when adjusting a role.
     *
     * @returns {ICrDemandOutput}
     * @memberof ChangeRequestDemandService
     */
    public getAdjustRoleInitialState(): ICrDemandOutput {
        const initialEditState: ICrDemandEditViewModel = {
            hours: 0,
            resourceLocationKey: "",
            blendedCostRate: 0,
            isCostRateResolved: true,
            isProjectPFPOrPFF: false,
            existingDemand: null,
            assignedTask: null,
            billingInfo: null,
            isAdditionalHoursQuantityAllowed: true,
            isContractTypeValid: true,
            isAdditionalTimeValid: true,
            isNewHoursLessThanStaffedHours: false
        };

        return {
            uuid: uuid(),
            resourceType: ResourceType.Labor,
            isNewRequest: false,
            isSaveable: false,
            editModeEnabled: true,
            currentState: initialEditState,
            savedState: undefined,
        };
    }

    /**
     * Returns initial state for ICrDemandOutput when adjusting a role.
     *
     * @returns {ICrDemandOutput}
     * @memberof ChangeRequestDemandService
     */
    public getAdjustUnitInitialState(): ICrUnitOutput {
        const initialEditState: ICrUnitViewModel = {
            newUnits: 0,
            costRate: 0,
            isCostRateResolved: true,
            isProjectPFPOrPFF: false,
            existingResource: null,
            assignedTask: null,
            billingInfo: null,
            isContractTypeValid: true,
            // isAdditionalTimeValid: true,
            // isNewHoursLessThanStaffedHours: false
        };

        return {
            uuid: uuid(),
            resourceType: ResourceType.Unit,
            isNewRequest: false,
            isSaveable: false,
            editModeEnabled: true,
            currentState: initialEditState,
            savedState: undefined,
        };
    }

    /**
     * Returns the initial state for ICrSubconOutput when adjusting a subcon FF line item.
     *
     * @returns {ICrSubconOutput}
     * @memberof ChangeRequestDemandService
     */
    public getAdjustSubconInitialState(): ICrSubconOutput {
        const intialEditState: ICrSubconViewModel = {
            newPlannedCost: 0,
            additionalCost: 0,
            existingResource: null,
            isContractTypeValid: true
        };
        return {
            uuid: uuid(),
            resourceType: ResourceType.SubconFF,
            editModeEnabled: true,
            isSaveable: false,
            isNewRequest: false,
            currentState: intialEditState,
            savedState: undefined
        };
    }

    /**
     * Returns the initial state for ICrExpenseOutput when adjusting an expense line item.
     *
     * @returns {ICrExpenseOutput}
     * @memberof ChangeRequestDemandService
     */
    public getAdjustExpenseInitialState(): ICrExpenseOutput {
        const intialEditState: ICrExpenseViewModel = {
            newPlannedCost: 0,
            additionalCost: 0,
            existingResource: null,
            isContractTypeValid: true
        };
        return {
            uuid: uuid(),
            resourceType: ResourceType.Expense,
            editModeEnabled: true,
            isSaveable: false,
            isNewRequest: false,
            currentState: intialEditState,
            savedState: undefined
        };
    }

    /**
     * Clone current state of demand edit and set saved state.
     *
     * @param {ICrDemandOutput} request
     * @returns {ICrDemandOutput}
     * @memberof ChangeRequestDemandService
     */
    public cloneCurrentState(request: ICrDemandOutput): ICrDemandEditViewModel {
        return {
            blendedCostRate: request.currentState.blendedCostRate,
            hours: request.currentState.hours,
            resourceLocationKey: request.currentState.resourceLocationKey,
            role: request.currentState.role,
            assignedTask: request.currentState.assignedTask,
            billingInfo: request.currentState.billingInfo,
            existingDemand: request.currentState.existingDemand,
            isCostRateResolved: request.currentState.isCostRateResolved,
            isAdditionalHoursQuantityAllowed: request.currentState.isAdditionalHoursQuantityAllowed,
            isContractTypeValid: request.currentState.isContractTypeValid,
            isAdditionalTimeValid: request.currentState.isAdditionalTimeValid,
            isNewHoursLessThanStaffedHours: request.currentState.isNewHoursLessThanStaffedHours,
            roleValues: request.currentState.roleValues,
            costPeriodList: request.currentState.costPeriodList
        };
    }

    /**
     * Clone saved state of demand edit and set current state.
     *
     * @param {ICrDemandOutput} request
     * @returns {ICrDemandOutput}
     * @memberof ChangeRequestDemandService
     */
    public cloneSavedState(request: ICrDemandOutput): ICrDemandEditViewModel {
        return {
            blendedCostRate: request.savedState.blendedCostRate,
            hours: request.savedState.hours,
            resourceLocationKey: request.savedState.resourceLocationKey,
            role: request.savedState.role,
            assignedTask: request.savedState.assignedTask,
            billingInfo: request.savedState.billingInfo,
            existingDemand: request.savedState.existingDemand,
            isCostRateResolved: request.savedState.isCostRateResolved,
            isAdditionalHoursQuantityAllowed: request.savedState.isAdditionalHoursQuantityAllowed,
            isContractTypeValid: request.savedState.isContractTypeValid,
            isAdditionalTimeValid: request.savedState.isAdditionalTimeValid,
            isNewHoursLessThanStaffedHours: request.savedState.isNewHoursLessThanStaffedHours,
            roleValues: request.savedState.roleValues,
            costPeriodList: request.savedState.costPeriodList
        };
    }

    /**
     * Clone current state of subcon and set saved state.
     *
     * @param {ICrSubconOutput} request
     * @returns {ICrSubconViewModel}
     * @memberof ChangeRequestDemandService
     */
    public cloneSubconCurrentState(request: ICrSubconOutput): ICrSubconViewModel {
        return {
            assignedTask: request.currentState.assignedTask,
            existingResource: request.currentState.existingResource,
            additionalCost: request.currentState.additionalCost,
            newPlannedCost: request.currentState.newPlannedCost
        };
    }

    /**
     * Clone current state of expense and set saved state.
     *
     * @param {ICrExpenseOutput} request
     * @returns {ICrExpenseViewModel}
     * @memberof ChangeRequestDemandService
     */
    public cloneExpenseCurrentState(request: ICrExpenseOutput): ICrExpenseViewModel {
        return {
            assignedTask: request.currentState.assignedTask,
            existingResource: request.currentState.existingResource,
            additionalCost: request.currentState.additionalCost,
            newPlannedCost: request.currentState.newPlannedCost,
            billableType: request.currentState.billableType
        };
    }

    /**
     * Clone current state of unit edit and set saved state.
     *
     * @param {ICrUnitOutput} request
     * @returns {ICrUnitViewModel}
     * @memberof ChangeRequestDemandService
     */
    public cloneUnitsCurrentState(request: ICrUnitOutput): ICrUnitViewModel {
        return {
            newUnits: request.currentState.newUnits,
            costRate: request.currentState.costRate,
            role: request.currentState.role,
            assignedTask: request.currentState.assignedTask,
            billingInfo: request.currentState.billingInfo,
            existingResource: request.currentState.existingResource,
            isCostRateResolved: request.currentState.isCostRateResolved,
            isContractTypeValid: request.currentState.isContractTypeValid,
            roleValues: request.currentState.roleValues
        };
    }

    public setAssignedTask(editedDemand: ICrDemandOutput, task: ITask, engagementDetails: IEngagementDetailsApiV2, nonBill: IBillRate, ffRoles: IFinancialRoles[], tmRoles: IFinancialRoles[]): void {
        editedDemand.currentState.assignedTask = task;
        editedDemand.currentState.billingInfo = !task.isFixedFeeProject ? nonBill : undefined;  // T&M billing role should be non bill
        editedDemand.currentState.resourceLocationKey = task.resourceLocation;  // set default value based on task project company code
        this.determineIfProjectIsPFPOrPFF(editedDemand, engagementDetails, nonBill);
        editedDemand.currentState.roleValues = this.getDropdownValidRoles(editedDemand, engagementDetails, ffRoles, tmRoles);  // get valid dropdown role values
    }

    /**
     * when creating a labor request that is an existing resource, set all relevant attributes.
     */
    public setExistingLaborResource(existingResource: IExistingDemand, editedDemand: ICrDemandOutput, engagementDetails: IEngagementDetailsApiV2, existingTasks: ITask[], billRates: IBillRate[], ffRoles?: IFinancialRoles[], tmRoles?: IFinancialRoles[]): void {
        if (editedDemand.currentState.existingDemand) {  // previous demand is no longer displayed
            editedDemand.currentState.existingDemand.isDisplayed = false;
        }

        if (existingResource) {  // we need to update the currently displayed existing resources
            existingResource.isDisplayed = true;
        }
        editedDemand.currentState.existingDemand = existingResource;
        editedDemand.currentState.existingDemand.isDisplayed = true;
        if (this.subConRolePartNumbers.indexOf(existingResource.rolePartNumber) > -1) {
            editedDemand.isSubconRole = true;
        }

        editedDemand.currentState.assignedTask = existingTasks.filter((l) => existingResource.taskId === l.wbsl3Id)[0];

        editedDemand.currentState.resourceLocationKey = existingResource.resourceLocationKey.slice(-4);  // get last four digits for use on UI
        editedDemand.currentState.billingInfo = this.searchBillRates(existingResource.billingRoleId, billRates);

        if (ffRoles || tmRoles) {
            editedDemand.currentState.roleValues = this.getDropdownValidRoles(editedDemand, engagementDetails, ffRoles, tmRoles);
        }
    }

    /**
     * when creating a subcon request that is an existing subcon resource, set all relevant attributes.
     */
    public setExistingSubconResource(existingResource: IExistingSubcon, editedSubcon: ICrSubconOutput, engagementDetails: IEngagementDetailsApiV2, existingTasks: ITask[]): void {
        if (editedSubcon.currentState.existingResource) {  // previous demand is no longer displayed
            editedSubcon.currentState.existingResource.isDisplayed = false;
        }

        if (existingResource) {  // we need to update the currently displayed existing resources
            existingResource.isDisplayed = true;
        }
        editedSubcon.currentState.existingResource = existingResource;
        editedSubcon.currentState.existingResource.isDisplayed = true;

        editedSubcon.currentState.assignedTask = existingTasks.filter((l) => existingResource.structureId === l.wbsl3Id)[0];
        editedSubcon.currentState.existingCost = existingResource.plannedCost;
    }

    /**
     * when creating an expense request that is an existing expense resource, set all relevant attributes.
     */
    public setExistingExpenseResource(existingResource: IExistingExpense, editedExpense: ICrExpenseOutput, engagementDetails: IEngagementDetailsApiV2, existingTasks: ITask[]): void {
        if (editedExpense.currentState.existingResource) {  // previous demand is no longer displayed
            editedExpense.currentState.existingResource.isDisplayed = false;
        }

        if (existingResource) {  // we need to update the currently displayed existing resources
            existingResource.isDisplayed = true;
        }
        editedExpense.currentState.existingResource = existingResource;
        editedExpense.currentState.existingResource.isDisplayed = true;

        editedExpense.currentState.assignedTask = existingTasks.filter((l) => existingResource.structureId === l.wbsl3Id)[0];
        editedExpense.currentState.existingCost = existingResource.plannedCost;
        // TODO: need to set billability type here
    }

    /**
     * When creating a unit request that is an existing unit demand, set all relevant attributes
     *
     * @param {IExistingUnit} existingUnitDemand
     * @param {ICrUnitOutput} editedUnitDemand
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {ITask[]} existingTasks
     * @param {import("./contracts/changerequestv2.contract").IBillRate[]} billRates
     * @param {IFinancialRoles[]} unitRoles
     * @memberof ChangeRequestDemandService
     */
    public setExistingUnitResource(existingUnitDemand: IExistingUnit, editedUnitDemand: ICrUnitOutput, engagementDetails: IEngagementDetailsApiV2, existingTasks: ITask[], billRates: IBillRate[], unitRoles: IFinancialRoles[]): void {
        if (editedUnitDemand.currentState.existingResource) {  // previous demand is no longer displayed
            editedUnitDemand.currentState.existingResource.isDisplayed = false;
        }

        if (existingUnitDemand) {  // we need to update the currently displayed existing resources
            existingUnitDemand.isDisplayed = true;
        }
        editedUnitDemand.currentState.existingResource = existingUnitDemand;
        editedUnitDemand.currentState.existingResource.isDisplayed = true;

        editedUnitDemand.currentState.assignedTask = existingTasks.filter((l) => existingUnitDemand.structureId === l.wbsl3Id)[0];
        editedUnitDemand.currentState.role = unitRoles.filter((r) => r.activityCode === existingUnitDemand.roleId)[0];
        editedUnitDemand.currentState.billingInfo = this.searchBillRates(existingUnitDemand.resourceItemId, billRates);
        editedUnitDemand.currentState.costRate = existingUnitDemand.plannedCostRate;
        editedUnitDemand.currentState.isAdditionalUnitsQuantityAllowed = true;
        // editedUnitDemand.currentState.roleValues = this.getDropdownValidRoles(editedDemand, engagementDetails, ffRoles, tmRoles);
    }

    public getRoleName(editedDemand: ICrDemandOutput): string {
        if (editedDemand.currentState.role && editedDemand.currentState.role.roleName) {
            return editedDemand.currentState.role.roleName;
        } else {
            return editedDemand.currentState.existingDemand ? editedDemand.currentState.existingDemand.roleDescription : "";
        }
    }

    public getBillingRoleId(editedDemand: ICrDemandOutput): string {
        if (editedDemand.currentState.existingDemand) {
            return editedDemand.currentState.existingDemand.billingRoleId;
        } else {
            return editedDemand.currentState.billingInfo ? editedDemand.currentState.billingInfo.billingRoleId : "";
        }
    }

    public getBillRate(editedDemand: ICrDemandOutput): number {
        if (editedDemand.currentState.existingDemand) {
            return editedDemand.currentState.existingDemand.billRate;
        } else {
            return editedDemand.currentState.billingInfo ? editedDemand.currentState.billingInfo.billRate : null;
        }
    }

    public getWbsl3Id(editedDemand: ICrDemandOutput): string {
        if (editedDemand.currentState.existingDemand) {
            return editedDemand.currentState.existingDemand.taskId;
        } else {
            return editedDemand.currentState.assignedTask ? editedDemand.currentState.assignedTask.wbsl3Id : "";
        }
    }

    /**
     * Get activity code from edited demand.
     *
     * @param {ICrDemandOutput} editedDemand
     * @returns {string}
     * @memberof ChangeRequestDemandService
     */
    public getActivityCode(editedDemand: ICrDemandOutput): string {
        if (editedDemand.currentState.role) {
            return editedDemand.currentState.role.activityCode;
        } else if (editedDemand.currentState.existingDemand) {
            return editedDemand.currentState.existingDemand.roleActivityCode;
        }
    }

    public getResourceLocation(editedDemand: ICrDemandOutput): string {
        if (editedDemand.currentState.existingDemand) {
            return editedDemand.currentState.existingDemand.resourceLocationKey;
        } else if (editedDemand.currentState.role) {
            return editedDemand.currentState.resourceLocationKey;
        }
    }

    public getResourceType(editedDemand: ICrDemandOutput): string {
        if (editedDemand.currentState.role) {
            return editedDemand.currentState.role.isFte ? "0ACT" : "ZEXS";
        } else if (editedDemand.currentState.existingDemand) {
            return editedDemand.currentState.existingDemand.resourceType;
        }
    }

    public setResourceLocation(resourceLocation: string, editedDemand: ICrDemandOutput): void {
        editedDemand.currentState.resourceLocationKey = resourceLocation;
    }

    public setRole(role: IFinancialRoles, editedDemand: ICrDemandOutput): void {
        editedDemand.currentState.role = role;

        if (this.subConRolePartNumbers.indexOf(role.rolePartNumber) > -1) {
            editedDemand.isSubconRole = true;
        } else {
            editedDemand.isSubconRole = false;
        }
    }

    /**
     * search bill rates list given a billingRoleId
     */
    public searchBillRates(billingRoleId: string, billRates: IBillRate[]): IBillRate {
        for (const billRate of billRates) {
            if (billingRoleId === billRate.billingRoleId) { return billRate; }
        }
        return undefined;
    }

    /**
     * Validates that a saved labor request has the same contract type as
     * any existing labor requests.
     *
     * @public
     * @param {ICrResource} laborRequest
     * @param {ContractType} fvrContractType
     * @returns {boolean}
     * @memberof ChangeRequestDemandService
     */
    public validateFvrContractType(laborRequest: ICrResource, fvrContractType: ContractType): boolean {
        const laborContractType: ContractType = laborRequest.currentState.assignedTask.isFixedFeeProject ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        return fvrContractType === laborContractType || !fvrContractType;
    }

    /**
     * Compare Demands between CFP and DB for automation
     * @param dbDemands 
     * @param fcrRoleData 
     * @param engagementDetails 
     * @param projectsThatFailedThresholdCheck 
     */
    public compareDemandsBetweenDbAndCfp(dbDemands: IWbsDemand, fcrRoleData: IFcrRolesFormControlData, engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrDemandOutput[] {

        const roles: ICrDemandOutput[] = [];

        const tasksToCompare = fcrRoleData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id);

        for (const cfpDemand of fcrRoleData.existingResources.filter((demand) => tasksToCompare.includes(demand.taskId))) {

            const dbDemand = dbDemands.details.filter((d) => d.demandId === cfpDemand.demandId)[0];

            if (!dbDemand) {

                // new role to be added to DB
                const newdbRole = this.getAdjustRoleInitialState();

                newdbRole.currentState.existingHours = cfpDemand.planHours;
                newdbRole.currentState.assignedTask = fcrRoleData.existingTasks.filter((l) => cfpDemand.taskId === l.wbsl3Id)[0];
                newdbRole.currentState.billingInfo = cfpDemand.billingRoleId === this.nonBillResourceItemId ? fcrRoleData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0] : fcrRoleData.billRates.filter((b) => b.billingRoleId === cfpDemand.billingRoleId)[0];
                newdbRole.currentState.existingDemand = fcrRoleData.existingResources.filter((l) => l.demandId === cfpDemand.demandId)[0];
                newdbRole.currentState.existingDemand.isDisplayed = true;
                newdbRole.currentState.resourceLocationKey = cfpDemand.resourceLocationKey;
                newdbRole.currentState.blendedCostRate = cfpDemand.planCostRate;
                newdbRole.editModeEnabled = false;
                newdbRole.isAutoPopulated = true;

                if (this.subConRolePartNumbers.indexOf(cfpDemand.rolePartNumber) > -1) {
                    newdbRole.isSubconRole = true;
                }

                newdbRole.savedState = this.cloneCurrentState(newdbRole);

                roles.push(newdbRole);

            } else if (Math.round(dbDemand.planned.plannedCost) !== Math.round(cfpDemand.planCost)) {

                const existingRoleAdjustment = this.getAdjustRoleInitialState();

                const existingLineItem = fcrRoleData.existingResources.filter((l) => l.demandId === cfpDemand.demandId)[0];

                existingRoleAdjustment.currentState.existingHours = cfpDemand.planHours;
                existingRoleAdjustment.currentState.hours = 0;
                existingRoleAdjustment.currentState.assignedTask = fcrRoleData.existingTasks.filter((l) => cfpDemand.taskId === l.wbsl3Id)[0];
                existingRoleAdjustment.currentState.billingInfo = cfpDemand.billingRoleId === this.nonBillResourceItemId ? fcrRoleData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0] : fcrRoleData.billRates.filter((b) => b.billingRoleId === cfpDemand.billingRoleId)[0];
                existingRoleAdjustment.currentState.existingDemand = existingLineItem;
                existingRoleAdjustment.currentState.existingDemand.isDisplayed = true;
                existingRoleAdjustment.currentState.resourceLocationKey = cfpDemand.resourceLocationKey;
                existingRoleAdjustment.currentState.blendedCostRate = cfpDemand.planCostRate;
                existingRoleAdjustment.editModeEnabled = false;
                existingRoleAdjustment.isAutoPopulated = true;

                if (this.subConRolePartNumbers.indexOf(cfpDemand.rolePartNumber) > -1) {
                    existingRoleAdjustment.isSubconRole = true;
                }

                existingRoleAdjustment.savedState = this.cloneCurrentState(existingRoleAdjustment);

                roles.push(existingRoleAdjustment);
            }

        }

        // Check if there are any existing db demands which do not have a corresponding cfp demand
        const filteredDbDemands = fcrRoleData.existingDbOnlyResources.filter((demand) => tasksToCompare.includes(demand.taskId));
        for (const dbDemand of filteredDbDemands) {
            // new role to be zero out line item in DB
            const extraDbRole = this.getAdjustRoleInitialState();

            extraDbRole.currentState.existingHours = 0;
            extraDbRole.currentState.hours = 0;
            extraDbRole.currentState.assignedTask = fcrRoleData.existingTasks.filter((l) => dbDemand.taskId === l.wbsl3Id)[0];
            extraDbRole.currentState.billingInfo = dbDemand.billingRoleId === this.nonBillResourceItemId ? fcrRoleData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0] : fcrRoleData.billRates.filter((b) => b.billingRoleId === dbDemand.billingRoleId)[0];
            extraDbRole.currentState.existingDemand = dbDemand;
            extraDbRole.currentState.existingDemand.planCost = 0;
            extraDbRole.currentState.existingDemand.planHours = 0;
            extraDbRole.currentState.existingDemand.isDisplayed = true;
            extraDbRole.currentState.resourceLocationKey = dbDemand.resourceLocationKey;
            extraDbRole.currentState.blendedCostRate = dbDemand.planCostRate;
            extraDbRole.editModeEnabled = false;
            extraDbRole.isAutoPopulated = true;
            extraDbRole.isDbOnlyDemand = true;

            if (this.subConRolePartNumbers.indexOf(dbDemand.rolePartNumber) > -1) {
                extraDbRole.isSubconRole = true;
            }

            extraDbRole.savedState = this.cloneCurrentState(extraDbRole);

            roles.push(extraDbRole);
        }

        return roles;
    }

    /**
     * Compare Unit Demands between CFP and DB for automation
     * @param unitDemands 
     * @param fcrUnitFormData 
     * @param engagementDetails 
     * @param projectsThatFailedThresholdCheck 
     */
    public compareUnitDemandsBetweenDbAndCfp(unitDemands: IExistingUnit[], fcrUnitFormData: IFcrUnitsFormControlData, engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrUnitOutput[] {

        const roles: ICrUnitOutput[] = [];

        const tasksToCompare = fcrUnitFormData.existingTasks ? fcrUnitFormData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id) : [];

        const existingDbUnits = unitDemands.filter((dbUnit) => dbUnit.version === Number(BaseLineType.DeliveryBaseline));
        const filteredDbUnits = existingDbUnits.filter((dbUnit) => tasksToCompare.filter((task) => task === dbUnit.structureId) && tasksToCompare.filter((task) => task === dbUnit.structureId).length);


        for (const cfpUnitDemand of fcrUnitFormData.existingResources.filter((demand) => tasksToCompare.includes(demand.structureId))) {

            const dbUnitDemand = filteredDbUnits.filter((d) => d.demandId === cfpUnitDemand.demandId)[0];

            if (!dbUnitDemand) {

                // new role to be added to DB
                const newdbUnit = this.getAdjustUnitInitialState();

                newdbUnit.currentState.assignedTask = fcrUnitFormData.existingTasks.filter((l) => cfpUnitDemand.structureId === l.wbsl3Id)[0];
                newdbUnit.currentState.billingInfo = cfpUnitDemand.resourceItemId === this.nonBillResourceItemId ? fcrUnitFormData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0] : fcrUnitFormData.billRates.filter((b) => b.billingRoleId === cfpUnitDemand.resourceItemId)[0];
                newdbUnit.currentState.existingResource = fcrUnitFormData.existingResources.filter((l) => l.demandId === cfpUnitDemand.demandId)[0];
                newdbUnit.currentState.existingResource.isDisplayed = true;
                newdbUnit.currentState.costRate = cfpUnitDemand.plannedCostRate;
                newdbUnit.editModeEnabled = false;
                newdbUnit.isAutoPopulated = true;

                newdbUnit.savedState = this.cloneUnitsCurrentState(newdbUnit);

                roles.push(newdbUnit);

            } else if (Math.round(dbUnitDemand.plannedCost) !== Math.round(cfpUnitDemand.plannedCost)) {

                const existingRoleAdjustment = this.getAdjustUnitInitialState();

                const existingLineItem = fcrUnitFormData.existingResources.filter((l) => l.demandId === cfpUnitDemand.demandId)[0];

                existingRoleAdjustment.currentState.newUnits = 0;
                existingRoleAdjustment.currentState.assignedTask = fcrUnitFormData.existingTasks.filter((l) => cfpUnitDemand.structureId === l.wbsl3Id)[0];
                existingRoleAdjustment.currentState.billingInfo = cfpUnitDemand.resourceItemId === this.nonBillResourceItemId ? fcrUnitFormData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0] : fcrUnitFormData.billRates.filter((b) => b.billingRoleId === cfpUnitDemand.resourceItemId)[0];
                existingRoleAdjustment.currentState.existingResource = existingLineItem;
                existingRoleAdjustment.currentState.existingResource.isDisplayed = true;
                existingRoleAdjustment.currentState.costRate = cfpUnitDemand.plannedCostRate;
                existingRoleAdjustment.editModeEnabled = false;
                existingRoleAdjustment.isAutoPopulated = true;

                existingRoleAdjustment.savedState = this.cloneUnitsCurrentState(existingRoleAdjustment);

                roles.push(existingRoleAdjustment);
            }

        }

        // Check if there are any existing db demands which do not have a corresponding cfp demand
        const filteredDbUnitDemands = fcrUnitFormData.existingDbOnlyResources.filter((demand) => tasksToCompare.includes(demand.structureId));
        for (const dbUnitDemand of filteredDbUnitDemands) {
            // new role to be zero out line item in DB
            const extraDbRole = this.getAdjustUnitInitialState();

            extraDbRole.currentState.newUnits = 0;
            extraDbRole.currentState.assignedTask = fcrUnitFormData.existingTasks.filter((l) => dbUnitDemand.structureId === l.wbsl3Id)[0];
            extraDbRole.currentState.billingInfo = dbUnitDemand.resourceItemId === this.nonBillResourceItemId ? fcrUnitFormData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0] : fcrUnitFormData.billRates.filter((b) => b.billingRoleId === dbUnitDemand.resourceItemId)[0];
            extraDbRole.currentState.existingResource = dbUnitDemand;
            extraDbRole.currentState.existingResource.plannedCost = 0;
            extraDbRole.currentState.existingResource.plannedHours = 0;
            extraDbRole.currentState.existingResource.isDisplayed = true;
            extraDbRole.currentState.costRate = dbUnitDemand.plannedCostRate;
            extraDbRole.editModeEnabled = false;
            extraDbRole.isAutoPopulated = true;
            extraDbRole.isDbOnlyDemand = true;

            extraDbRole.savedState = this.cloneUnitsCurrentState(extraDbRole);

            roles.push(extraDbRole);
        }

        return roles;
    }

    /**
     * Compare Subcon Demands between CFP and DB for automation
     * @param subconDemands 
     * @param fcrSubconFormData 
     * @param engagementDetails 
     * @param projectsThatFailedThresholdCheck 
     */
    public compareSubconDemandsBetweenDbAndCfp(subconDemands: IExistingSubcon[], fcrSubconFormData: IFcrSubconFormControlData, engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrSubconOutput[] {

        const subcons: ICrSubconOutput[] = [];

        const tasksToCompare = fcrSubconFormData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id);

        const existingDbSubcons = subconDemands.filter((dbUnit) => dbUnit.version === Number(BaseLineType.DeliveryBaseline));
        const filteredDbSubcons = existingDbSubcons.filter((dbUnit) => tasksToCompare.filter((task) => task === dbUnit.structureId) && tasksToCompare.filter((task) => task === dbUnit.structureId).length);


        for (const cfpSubconDemand of fcrSubconFormData.existingResources.filter((demand) => tasksToCompare.includes(demand.structureId))) {

            const dbSubconDemand = filteredDbSubcons.filter((d) => d.structureId === cfpSubconDemand.structureId)[0];

            if (!dbSubconDemand) {

                // new role to be added to DB
                const newdbSubcon = this.getAdjustSubconInitialState();

                newdbSubcon.currentState.assignedTask = fcrSubconFormData.existingTasks.filter((l) => cfpSubconDemand.structureId === l.wbsl3Id)[0];
                newdbSubcon.currentState.existingResource = fcrSubconFormData.existingResources.filter((l) => l.structureId === cfpSubconDemand.structureId)[0];
                newdbSubcon.currentState.existingResource.isDisplayed = true;
                newdbSubcon.editModeEnabled = false;
                newdbSubcon.isAutoPopulated = true;

                newdbSubcon.savedState = this.cloneSubconCurrentState(newdbSubcon);

                subcons.push(newdbSubcon);

            } else if (Math.round(dbSubconDemand.plannedCost) !== Math.round(cfpSubconDemand.plannedCost)) {

                const existingSubconAdjustment = this.getAdjustSubconInitialState();

                const existingLineItem = fcrSubconFormData.existingResources.filter((l) => l.structureId === cfpSubconDemand.structureId)[0];

                existingSubconAdjustment.currentState.newPlannedCost = 0;
                existingSubconAdjustment.currentState.assignedTask = fcrSubconFormData.existingTasks.filter((l) => cfpSubconDemand.structureId === l.wbsl3Id)[0];
                existingSubconAdjustment.currentState.existingResource = existingLineItem;
                existingSubconAdjustment.currentState.existingResource.isDisplayed = true;
                existingSubconAdjustment.editModeEnabled = false;
                existingSubconAdjustment.isAutoPopulated = true;

                existingSubconAdjustment.savedState = this.cloneSubconCurrentState(existingSubconAdjustment);

                subcons.push(existingSubconAdjustment);
            }

        }

        // Check if there are any existing db demands which do not have a corresponding cfp demand
        const filteredDbSubconDemands = fcrSubconFormData.existingDbOnlyResources.filter((demand) => tasksToCompare.includes(demand.structureId));
        for (const dbSubconDemand of filteredDbSubconDemands) {
            // new role to be zero out line item in DB
            const extraDbRole = this.getAdjustSubconInitialState();

            extraDbRole.currentState.newPlannedCost = 0;
            extraDbRole.currentState.assignedTask = fcrSubconFormData.existingTasks.filter((l) => dbSubconDemand.structureId === l.wbsl3Id)[0];
            extraDbRole.currentState.existingResource = dbSubconDemand;
            extraDbRole.currentState.existingResource.plannedCost = 0;
            extraDbRole.currentState.existingResource.isDisplayed = true;
            extraDbRole.editModeEnabled = false;
            extraDbRole.isAutoPopulated = true;
            extraDbRole.isDbOnlyDemand = true;

            extraDbRole.savedState = this.cloneSubconCurrentState(extraDbRole);

            subcons.push(extraDbRole);
        }

        return subcons;
    }

    /**
     * Compare Expense Demands between CFP and DB for automation
     * @param expenseDemands 
     * @param fcrExpenseFormData 
     * @param engagementDetails 
     * @param projectsThatFailedThresholdCheck 
     */
    public compareExpenseDemandsBetweenDbAndCfp(expenseDemands: IExistingExpense[], fcrExpenseFormData: IFcrExpensesFormControlData, engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrExpenseOutput[] {

        const expenses: ICrExpenseOutput[] = [];

        const tasksToCompare = fcrExpenseFormData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id);

        const existingDbExpenses = expenseDemands.filter((dbUnit) => dbUnit.version === Number(BaseLineType.DeliveryBaseline));
        const filteredDbExpenses = existingDbExpenses.filter((dbUnit) => tasksToCompare.filter((task) => task === dbUnit.structureId) && tasksToCompare.filter((task) => task === dbUnit.structureId).length);


        for (const cfpExpenseDemand of fcrExpenseFormData.existingResources.filter((demand) => tasksToCompare.includes(demand.structureId))) {

            const dbExpenseDemand = filteredDbExpenses.filter((d) => d.structureId === cfpExpenseDemand.structureId)[0];

            if (!dbExpenseDemand) {

                // new role to be added to DB
                const newdbExpense = this.getAdjustExpenseInitialState();

                newdbExpense.currentState.assignedTask = fcrExpenseFormData.existingTasks.filter((l) => cfpExpenseDemand.structureId === l.wbsl3Id)[0];
                newdbExpense.currentState.existingResource = fcrExpenseFormData.existingResources.filter((l) => l.structureId === cfpExpenseDemand.structureId)[0];
                newdbExpense.currentState.existingResource.isDisplayed = true;
                newdbExpense.currentState.billableType = ExpenseBillableTypes.filter((billType) => billType.code === newdbExpense.currentState.existingResource.roleId)[0];
                newdbExpense.editModeEnabled = false;
                newdbExpense.isAutoPopulated = true;

                newdbExpense.savedState = this.cloneExpenseCurrentState(newdbExpense);

                expenses.push(newdbExpense);

            } else if (Math.round(dbExpenseDemand.plannedCost) !== Math.round(cfpExpenseDemand.plannedCost)) {

                const existingRoleAdjustment = this.getAdjustExpenseInitialState();

                const existingLineItem = fcrExpenseFormData.existingResources.filter((l) => l.structureId === cfpExpenseDemand.structureId)[0];

                existingRoleAdjustment.currentState.newPlannedCost = 0;
                existingRoleAdjustment.currentState.assignedTask = fcrExpenseFormData.existingTasks.filter((l) => cfpExpenseDemand.structureId === l.wbsl3Id)[0];
                existingRoleAdjustment.currentState.existingResource = existingLineItem;
                existingRoleAdjustment.currentState.existingResource.isDisplayed = true;
                existingRoleAdjustment.currentState.billableType = ExpenseBillableTypes.filter((billType) => billType.code === existingRoleAdjustment.currentState.existingResource.roleId)[0];
                existingRoleAdjustment.editModeEnabled = false;
                existingRoleAdjustment.isAutoPopulated = true;

                existingRoleAdjustment.savedState = this.cloneExpenseCurrentState(existingRoleAdjustment);

                expenses.push(existingRoleAdjustment);
            }

        }

        // Check if there are any existing db demands which do not have a corresponding cfp demand
        const filteredDbExpenseDemands = fcrExpenseFormData.existingDbOnlyResources.filter((demand) => tasksToCompare.includes(demand.structureId));
        for (const dbExpenseDemand of filteredDbExpenseDemands) {
            // new role to be zero out line item in DB
            const extraDbRole = this.getAdjustExpenseInitialState();

            extraDbRole.currentState.newPlannedCost = 0;
            extraDbRole.currentState.assignedTask = fcrExpenseFormData.existingTasks.filter((l) => dbExpenseDemand.structureId === l.wbsl3Id)[0];
            extraDbRole.currentState.existingResource = dbExpenseDemand;
            extraDbRole.currentState.existingResource.plannedCost = 0;
            extraDbRole.currentState.existingResource.isDisplayed = true;
            extraDbRole.editModeEnabled = false;
            extraDbRole.isAutoPopulated = true;
            extraDbRole.isDbOnlyDemand = true;

            extraDbRole.savedState = this.cloneExpenseCurrentState(extraDbRole);

            expenses.push(extraDbRole);
        }

        return expenses;
    }

    public filterNonBillableExistingTasks(existingExpenseData: IExistingExpense[], existingTasks: ITask[]): ITask[] {
        if (existingTasks && existingTasks.length && existingExpenseData && existingExpenseData.length) {
            return existingTasks.filter((item) => {
                if (!item.isFixedFeeProject) {
                    const filteredExistingExpenseData = existingExpenseData.find((expenseData) => expenseData.structureId === item.wbsl3Id);
                    if (filteredExistingExpenseData) {
                        return filteredExistingExpenseData.roleId === ExpenseBillableCode.NonBillable;
                    }
                    return false;
                }
                return true;
            });
        }
    }

    public getExpensesFormData(existingExpenseData: IExistingExpense[], engagementDetails: IEngagementDetailsApiV2, projectsWithPendingCr: string[]): IFcrExpensesFormControlData {
        const authorizedTasks = this.getAuthorizedTasks(engagementDetails, ResourceType.Expense);
        const existingTasks = this.identifyTasksWithPendingCr(authorizedTasks, projectsWithPendingCr);

        const existingCfpExpenses = existingExpenseData.filter((cfpExpense) => cfpExpense.version === Number(BaseLineType.CurrentFinancialPlan));
        const existingDbExpenses = existingExpenseData.filter((dbExpense) => dbExpense.version === Number(BaseLineType.DeliveryBaseline));

        const filteredCfpExpenses = existingCfpExpenses.filter((cfpExpense) => existingTasks.filter((task) => task.wbsl3Id === cfpExpense.structureId) && existingTasks.filter((task) => task.wbsl3Id === cfpExpense.structureId).length);
        const filteredDbExpenses = existingDbExpenses.filter((dbExpense) => existingTasks.filter((task) => task.wbsl3Id === dbExpense.structureId) && existingTasks.filter((task) => task.wbsl3Id === dbExpense.structureId).length);

        let expenseLineItems = filteredCfpExpenses.map((cfpExpenseData) => {
            const dbExpense = filteredDbExpenses.filter((dbSubconData) => dbSubconData.structureId === cfpExpenseData.structureId && dbSubconData.roleId === cfpExpenseData.roleId)[0];

            const expense: IExistingExpense = {
                ...cfpExpenseData,
                plannedCost: cfpExpenseData && +cfpExpenseData.plannedCost,
                plannedRevenue: cfpExpenseData && +cfpExpenseData.plannedRevenue,
                cfpCost: cfpExpenseData && +cfpExpenseData.plannedCost,
                dbCost: dbExpense && +dbExpense.plannedCost,
                // TODO: check with Mukesh on correct logic to populate billability where applicable
            };

            return expense;
        });
        expenseLineItems = [...this.identifyDemandsWithPendingCr(expenseLineItems, engagementDetails, projectsWithPendingCr)] as IExistingExpense[];

        // Check if there are any existing db expense demands which do not have a corresponding cfp expense demand
        const dbOnlyDemandLineItems: IExistingExpense[] = [];
        for (const dbExpenseDemand of filteredDbExpenses) {
            const filteredDemands = expenseLineItems.filter((demand) => demand.structureId === dbExpenseDemand.structureId);

            if (filteredDemands.length === 0) {
                const expense: IExistingExpense = {
                    ...dbExpenseDemand,
                    plannedCost: 0,
                    plannedRevenue: 0,
                    cfpCost: 0,
                    dbCost: +dbExpenseDemand.plannedCost
                };

                dbOnlyDemandLineItems.push(expense);
            }
        }

        return {
            engagementDetails,
            existingResources: expenseLineItems.filter((lineItem) => !lineItem.isFixedFeeProject ? lineItem.roleId === ExpenseBillableCode.NonBillable : true),
            existingTasks: this.filterNonBillableExistingTasks(existingExpenseData, existingTasks),
            planCurrency: engagementDetails && engagementDetails.currency,
            projectsWithPendingCr,
            existingDbOnlyResources: dbOnlyDemandLineItems
        };
    }


    public getSubconFormData(existingSubconFfData: IExistingSubcon[], engagementDetails: IEngagementDetailsApiV2, projectsWithPendingCr: string[]): IFcrSubconFormControlData {
        const authorizedTasks = this.getAuthorizedTasks(engagementDetails, ResourceType.Labor);
        const existingApiTasks: ITask[] = this.identifyTasksWithPendingCr(authorizedTasks, projectsWithPendingCr);

        let existingTasks: ITask[] = [];
        for (const task of existingApiTasks) {
            for (const existingResource of existingSubconFfData) {
                if (existingResource.structureId === task.wbsl3Id) {
                    existingTasks.push(task);
                }
            }
        }
        existingTasks = [...new Set(existingTasks.filter((item) => item.wbsl3Id))];

        const existingCfpSubcons = existingSubconFfData.filter((cfpSubcon) => cfpSubcon.version === Number(BaseLineType.CurrentFinancialPlan));
        const existingDbSubcons = existingSubconFfData.filter((dbSubcon) => dbSubcon.version === Number(BaseLineType.DeliveryBaseline));

        // Sum up total planned cost for given task ID for CFP (DB does not have concept of multiple PO line items)
        const cfpSubConsWithTotalPo = Array.from(existingCfpSubcons.reduce((acc, { plannedCost, ...r }) => {
            const key = r.structureId;
            const current = acc.get(key) || { ...r, plannedCost: 0 };
            return acc.set(key, { ...current, plannedCost: current.plannedCost + +plannedCost });
        }, new Map()).values());

        const filteredCfpSubcons = cfpSubConsWithTotalPo.filter((cfpSubcon) => existingTasks.filter((task) => task.wbsl3Id === cfpSubcon.structureId) && existingTasks.filter((task) => task.wbsl3Id === cfpSubcon.structureId).length);
        const filteredDbSubcons = existingDbSubcons.filter((dbSubcon) => existingTasks.filter((task) => task.wbsl3Id === dbSubcon.structureId) && existingTasks.filter((task) => task.wbsl3Id === dbSubcon.structureId).length);

        let subconLineItems = filteredCfpSubcons.map((cfpSubconData) => {
            const dbSubcon = filteredDbSubcons.filter((dbSubconData) => dbSubconData.structureId === cfpSubconData.structureId)[0];

            const subcon: IExistingSubcon = {
                ...cfpSubconData,
                plannedCost: cfpSubconData && +cfpSubconData.plannedCost,
                plannedRevenue: cfpSubconData && +cfpSubconData.plannedRevenue,
                existingCost: cfpSubconData ? +cfpSubconData.plannedCost : 0,
                cfpCost: cfpSubconData ? +cfpSubconData.plannedCost : 0,
                dbCost: dbSubcon && +dbSubcon.plannedCost,
                totalPOAmount: cfpSubconData && cfpSubconData.totalPOAmount ? +cfpSubconData.totalPOAmount : 0
            };

            return subcon;
        });
        subconLineItems = [...this.identifyDemandsWithPendingCr(subconLineItems, engagementDetails, projectsWithPendingCr)] as IExistingSubcon[];

        // Check if there are any existing db subcon demands which do not have a corresponding cfp subcon demand
        const dbOnlyDemandLineItems: IExistingSubcon[] = [];
        for (const dbSubconDemand of filteredDbSubcons) {
            const filteredDemands = subconLineItems.filter((demand) => demand.structureId === dbSubconDemand.structureId);

            if (filteredDemands.length === 0) {
                const subcon: IExistingSubcon = {
                    ...dbSubconDemand,
                    plannedCost: 0,
                    plannedRevenue: +dbSubconDemand.plannedRevenue,
                    existingCost: 0,
                    cfpCost: 0,
                    dbCost: +dbSubconDemand.plannedCost
                };

                dbOnlyDemandLineItems.push(subcon);
            }
        }

        return {
            engagementDetails,
            existingTasks,
            existingResources: subconLineItems,
            planCurrency: engagementDetails && engagementDetails.currency,
            projectsWithPendingCr,
            existingDbOnlyResources: dbOnlyDemandLineItems
        };
    }

    public getUnitsFormData(existingUnitData: IExistingUnit[], engagementDetails: IEngagementDetailsApiV2, unitRoles: IFinancialRoles[], projectsWithPendingCr: string[], unitDetails: IUnitsViewModel[]): IFcrUnitsFormControlData {
        const authorizedTasks = this.getAuthorizedTasks(engagementDetails, ResourceType.Unit);
        const existingTasks = this.identifyTasksWithPendingCr(authorizedTasks, projectsWithPendingCr);
        const planCurrency = engagementDetails.currency;

        const existingCfpUnits = existingUnitData.filter((cfpUnit) => cfpUnit.version === Number(BaseLineType.CurrentFinancialPlan));
        const existingDbUnits = existingUnitData.filter((dbUnit) => dbUnit.version === Number(BaseLineType.DeliveryBaseline));

        const filteredCfpUnits = existingCfpUnits.filter((cfpUnit) => existingTasks.filter((task) => task.wbsl3Id === cfpUnit.structureId) && existingTasks.filter((task) => task.wbsl3Id === cfpUnit.structureId).length);
        const filteredDbUnits = existingDbUnits.filter((dbUnit) => existingTasks.filter((task) => task.wbsl3Id === dbUnit.structureId) && existingTasks.filter((task) => task.wbsl3Id === dbUnit.structureId).length);

        let unitLineItems = filteredCfpUnits.map((cfpUnitData) => {
            const dbUnit = filteredDbUnits.filter((dbUnitData) => dbUnitData.demandId === cfpUnitData.demandId && dbUnitData.structureId === cfpUnitData.structureId)[0];
            let filteredActualsDemand: IUnitDemand;
            if (unitDetails && unitDetails.length) {
                unitDetails.filter((m) => m.tasks && m.tasks.length && m.tasks.filter((t) => t.demands && t.demands.length && t.demands.filter((demand) => {
                    if (demand.demandId === cfpUnitData.demandId) {
                        filteredActualsDemand = demand;
                    }
                })));
            }

            const roleInfo = unitRoles.filter((r) => r.activityCode === cfpUnitData.roleId)[0];

            const unit: IExistingUnit = {
                ...cfpUnitData,
                plannedCost: cfpUnitData && +cfpUnitData.plannedCost,
                plannedHours: cfpUnitData && +cfpUnitData.plannedHours,
                existingUnits: cfpUnitData && +cfpUnitData.plannedHours,
                plannedCostRate: cfpUnitData && +cfpUnitData.plannedCostRate,
                plannedRevenue: cfpUnitData && +cfpUnitData.plannedRevenue,
                cfpCost: cfpUnitData && +cfpUnitData.plannedCost,
                dbCost: dbUnit && +dbUnit.plannedCost,
                dbUnits: dbUnit && +dbUnit.plannedHours,
                dbCostRate: dbUnit && +dbUnit.plannedCostRate,
                roleInfo: roleInfo ? roleInfo : undefined,
                exisitingActuals: filteredActualsDemand && filteredActualsDemand.actualQuantity
            };

            return unit;
        });
        unitLineItems = [...this.identifyDemandsWithPendingCr(unitLineItems, engagementDetails, projectsWithPendingCr)] as IExistingUnit[];

        const billRates = filteredCfpUnits.map((unitsData) => {
            const billRate: IBillRate = {
                billingRoleId: unitsData.resourceItemId === this.nonBillResourceItemId ? this.nonBillValue : unitsData.resourceItemId,
                billRate: unitsData && unitsData.billRate,
                billRateCurrency: planCurrency,
                description: unitsData && unitsData.roleDescription,
                isTMBillRate: !existingTasks.filter((t) => t.wbsl3Id === unitsData.structureId)[0].isFixedFeeProject,
                validFrom: null,
                validTo: null
            };

            return billRate;
        });

        billRates.push({
            billingRoleId: this.nonBillValue,
            billRate: 0,
            billRateCurrency: planCurrency,
            description: null,
            isTMBillRate: null,
            validFrom: null,
            validTo: null
        });

        // Check if there are any existing db unit demands which do not have a corresponding cfp unit demand
        const dbOnlyDemandLineItems: IExistingUnit[] = [];
        for (const dbUnitDemand of filteredDbUnits) {
            const filteredDemands = unitLineItems.filter((demand) => demand.demandId === dbUnitDemand.demandId);

            if (filteredDemands.length === 0) {
                const roleInfo = unitRoles.filter((r) => r.activityCode === dbUnitDemand.roleId)[0];

                const unit: IExistingUnit = {
                    ...dbUnitDemand,
                    plannedCost: +dbUnitDemand.plannedCost,
                    plannedHours: +dbUnitDemand.plannedHours,
                    existingUnits: +dbUnitDemand.plannedHours,
                    plannedCostRate: +dbUnitDemand.plannedCostRate,
                    plannedRevenue: +dbUnitDemand.plannedRevenue,
                    dbCost: +dbUnitDemand.plannedCost,
                    dbUnits: +dbUnitDemand.plannedHours,
                    dbCostRate: +dbUnitDemand.plannedCostRate,
                    roleInfo: roleInfo || undefined
                };

                dbOnlyDemandLineItems.push(unit);
            }
        }

        return {
            engagementDetails,
            existingTasks,
            existingResources: unitLineItems,
            planCurrency: engagementDetails && engagementDetails.currency,
            roles: unitRoles,
            billRates,
            nonBill: billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0],
            FFBillRates: billRates.filter((b) => b.isTMBillRate === false),
            TAndMBillRates: billRates.filter((b) => b.isTMBillRate === null || b.isTMBillRate),
            existingDbOnlyResources: dbOnlyDemandLineItems,
            projectsWithPendingCr
        };
    }

    /**
     * retrieve the list of existing resources and bill rates from Demands
     */
    public getFcrFormData(cfpDemands: IWbsDemand, dbDemands: IWbsDemand, engagementDetails: IEngagementDetailsApiV2, roleDomainData: IFinancialRoles[]): IFcrRolesFormControlData {
        const planCurrency = engagementDetails.currency;

        const existingTasks = this.getAuthorizedTasks(engagementDetails, ResourceType.Labor);

        const invalidDemands = cfpDemands.details.filter((cfpDemand) => !cfpDemand.planned);

        if (invalidDemands && invalidDemands.length) {
            return {
                engagementDetails,
                existingTasks: [],
                existingResources: [],
                FFBillRates: [],
                TAndMBillRates: [],
                nonBill: null,
                billRates: [],
                error: DmError.FinancialChangeRequest.DemandDataCorrupted
            };
        }

        // ensure we are only considering labor demands in authorized tasks
        const filteredCfpDemands = cfpDemands.details.filter((demandData) => demandData.demandId && demandData.planned && demandData.planned.structureId &&
            existingTasks.filter((task) => task.wbsl3Id === demandData.planned.structureId) && existingTasks.filter((task) => task.wbsl3Id === demandData.planned.structureId).length);
        const filteredDbDemands = dbDemands.details.filter((dbDemandData) => dbDemandData.demandId && dbDemandData.planned && dbDemandData.planned.structureId &&
            existingTasks.filter((task) => task.wbsl3Id === dbDemandData.planned.structureId) && existingTasks.filter((task) => task.wbsl3Id === dbDemandData.planned.structureId).length);

        const demandLineItems = filteredCfpDemands.map((d) => {
            const dbDemand = filteredDbDemands.filter((db) => db.demandId === d.demandId && db.planned.structureId === d.planned.structureId)[0];

            const roleInfo = roleDomainData.filter((r) => r.rolePartNumber === d.planned.roleId)[0];

            const resource: IExistingDemand = {
                billRate: d.planned.billRate,
                billingRoleId: d.planned.resourceItemId === this.nonBillResourceItemId ? this.nonBillValue : d.planned.resourceItemId,
                planCostRate: d.planned.plannedCostRate,
                deliveryLocationKey: d.planned.deliveryLocation,
                demandId: d.demandId,
                domainKey: d.planned.domainKey,
                laborUnits: "H",
                taskDescription: null,
                planCost: d.planned.plannedCost,
                planCurrency,
                planRevenue: d.planned.plannedRevenue,
                planHours: d.planned.plannedHours,
                resourceType: roleInfo && roleInfo.isFte ? "0ACT" : "ZEXS",
                resourceLocationKey: d.planned.resourceLocation,
                resourceTypeDescription: d.planned.resourceLocationDescription,
                roleActivityCode: roleInfo ? roleInfo.activityCode : "",
                rolePartNumber: d.planned.roleId, // "AAA-66320"
                roleDescription: d.planned.roleDescription, // "Domain Solution Architect"
                taskId: d.planned.structureId, // task id,
                staffedHours: d.staffed && d.staffed.map((s) => s.staffedHours).reduce((accumulator, currentValue) => accumulator + currentValue),
                staffedCost: d.staffed && d.staffed.map((s) => s.staffedCost).reduce((accumulator, currentValue) => accumulator + currentValue),
                dbHours: dbDemand && dbDemand.planned.plannedHours,
                dbCostRate: dbDemand && dbDemand.planned.plannedCostRate,
                dbCost: dbDemand && dbDemand.planned.plannedCost,
                isDemandNonBillable: dbDemand && dbDemand.planned.isNonBillable
            };

            return resource;
        });

        const billRates = filteredCfpDemands.map((d) => {
            const billRate: IBillRate = {
                billingRoleId: d.planned.resourceItemId === this.nonBillResourceItemId ? this.nonBillValue : d.planned.resourceItemId,
                billRate: d.planned.billRate,
                billRateCurrency: planCurrency,
                description: d.planned.roleDescription,
                isTMBillRate: !existingTasks.filter((t) => t.wbsl3Id === d.planned.structureId)[0].isFixedFeeProject,
                validFrom: null,
                validTo: null
            };

            return billRate;
        });

        billRates.push({
            billingRoleId: this.nonBillValue,
            billRate: 0,
            billRateCurrency: planCurrency,
            description: null,
            isTMBillRate: true,
            validFrom: null,
            validTo: null
        });

        billRates.push({
            billingRoleId: this.nonBillValue,
            billRate: 0,
            billRateCurrency: planCurrency,
            description: null,
            isTMBillRate: false,
            validFrom: null,
            validTo: null
        });

        // Check if there are any existing db demands which do not have a corresponding cfp demand
        const dbOnlyDemandLineItems: IExistingDemand[] = [];
        for (const dbDemand of filteredDbDemands) {
            const filteredDemands = demandLineItems.filter((demand) => demand.demandId === dbDemand.demandId);
            // add sanity check for null/undefined planned data
            if (filteredDemands.length === 0) {
                const roleInfo = roleDomainData.filter((r) => r.rolePartNumber === dbDemand.planned.roleId)[0];

                const resource: IExistingDemand = {
                    billRate: dbDemand.planned.billRate,
                    billingRoleId: dbDemand.planned.resourceItemId === this.nonBillResourceItemId ? this.nonBillValue : dbDemand.planned.resourceItemId,
                    planCostRate: dbDemand.planned.plannedCostRate,
                    deliveryLocationKey: dbDemand.planned.deliveryLocation,
                    demandId: dbDemand.demandId,
                    domainKey: dbDemand.planned.domainKey,
                    laborUnits: "H",
                    taskDescription: null,
                    planCost: dbDemand.planned.plannedCost,
                    planCurrency,
                    planRevenue: dbDemand.planned.plannedRevenue,
                    planHours: dbDemand.planned.plannedHours,
                    resourceType: roleInfo && roleInfo.isFte ? "0ACT" : "ZEXS",
                    resourceLocationKey: dbDemand.planned.resourceLocation,
                    resourceTypeDescription: dbDemand.planned.resourceLocationDescription,
                    roleActivityCode: roleInfo ? roleInfo.activityCode : "",
                    rolePartNumber: dbDemand.planned.roleId, // "AAA-66320"
                    roleDescription: dbDemand.planned.roleDescription, // "Domain Solution Architect"
                    taskId: dbDemand.planned.structureId, // task id,
                    staffedHours: dbDemand.staffed && dbDemand.staffed.map((s) => s.staffedHours).reduce((accumulator, currentValue) => accumulator + currentValue),
                    staffedCost: dbDemand.staffed && dbDemand.staffed.map((s) => s.staffedCost).reduce((accumulator, currentValue) => accumulator + currentValue),
                    dbHours: dbDemand && dbDemand.planned.plannedHours,
                    dbCostRate: dbDemand && dbDemand.planned.plannedCostRate,
                    dbCost: dbDemand && dbDemand.planned.plannedCost,
                    isDemandNonBillable: dbDemand && dbDemand.planned.isNonBillable
                };

                dbOnlyDemandLineItems.push(resource);
            }
        }

        return {
            engagementDetails,
            existingResources: demandLineItems,
            existingTasks,
            planCurrency,
            billRates,
            FFBillRates: billRates.filter((b) => b.isTMBillRate === null || (!b.isTMBillRate)),
            TAndMBillRates: billRates.filter((b) => b.isTMBillRate === null || b.isTMBillRate),
            nonBill: billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0],
            existingDbOnlyResources: dbOnlyDemandLineItems
        };
    }

    /**
     * Determine which demands have been edited based on demand diff data from plan and forecast screen and
     * autopopulate these changes on the form.
     *
     * @param {IDemandDiff[]} demandDiffData
     * @param {IFcrRolesFormControlData} fcrRoleData
     * @param {IFinancialRoles[]} roleDomainData
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {string[]} projectsThatFailedThresholdCheck
     * @returns {ICrDemandOutput[]}
     * @memberof ChangeRequestDemandService
     */
    public determineDemandDiff(demandDiffData: IDemandDiff[], fcrRoleData: IFcrRolesFormControlData, roleDomainData: IFinancialRoles[], engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrDemandOutput[] {
        const demandsWithChanges: ICrDemandOutput[] = [];

        const tasksToCompare = fcrRoleData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id);
        const demandsForFailedProjects = fcrRoleData.existingResources.filter((demand) => tasksToCompare.includes(demand.taskId));

        // TODO: will refactor and extract to method in next PR for filtering by resource type
        const filteredLaborDemandDiffs = demandDiffData.filter((dDiff) => dDiff.grmDemandId && dDiff.grmDemandId !== "#" && (dDiff.role.split("/")[0] === ResourceTypeCode.Labor || (dDiff.role.split("/")[0] === ResourceTypeCode.SubconFF && dDiff.uom.toUpperCase() === "H")));

        for (const demandDiff of filteredLaborDemandDiffs) {
            const existingDemand = fcrRoleData.existingResources.filter((demand) => demandDiff.grmDemandId === demand.demandId)[0];

            if (existingDemand && (demandDiff.quantity !== existingDemand.planHours || Math.round(demandDiff.cost) !== Math.round(existingDemand.planCost))) {
                const isPartOfFailedProj = demandsForFailedProjects.filter((demand) => existingDemand.demandId === demand.demandId).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }

                // edit of an existing role on pnf
                const existingRoleWithEdit = this.getAdjustRoleInitialState();

                existingRoleWithEdit.currentState.existingHours = existingDemand.planHours;
                existingRoleWithEdit.currentState.hours = demandDiff.quantity - existingDemand.planHours;
                existingRoleWithEdit.currentState.assignedTask = fcrRoleData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                existingRoleWithEdit.currentState.billingInfo = fcrRoleData.billRates.filter((b) => b.billingRoleId === demandDiff.billingRoleId)[0];
                existingRoleWithEdit.currentState.existingDemand = existingDemand;
                existingRoleWithEdit.currentState.existingDemand.isDisplayed = true;

                if (existingRoleWithEdit.currentState.existingDemand) {
                    existingRoleWithEdit.currentState.isNewHoursLessThanStaffedHours = existingRoleWithEdit.currentState.hours < existingRoleWithEdit.currentState.existingDemand.staffedHours;
                }

                // If resource location from edit is different than demand resource location, use the edit
                if (this.getShortenedResourceLocation(demandDiff.resourceLocation) !== this.getShortenedResourceLocation(existingDemand.resourceLocationKey)) {
                    existingRoleWithEdit.isRoleOrResourceLocationChanged = true;
                    existingRoleWithEdit.currentState.resourceLocationKey = this.getShortenedResourceLocation(demandDiff.resourceLocation);
                } else {
                    existingRoleWithEdit.currentState.resourceLocationKey = this.getShortenedResourceLocation(existingDemand.resourceLocationKey);
                }

                // If role from edit is different than demand role, use role edit
                const autopopulatedRole = this.getRoleInfoFromString(demandDiff.role, roleDomainData);
                if (autopopulatedRole.activityCode.toUpperCase() !== existingDemand.roleActivityCode.toUpperCase()) {
                    existingRoleWithEdit.isRoleOrResourceLocationChanged = true;
                    existingRoleWithEdit.currentState.role = autopopulatedRole;
                }

                existingRoleWithEdit.editModeEnabled = false;
                existingRoleWithEdit.isAutoPopulated = true;
                existingRoleWithEdit.isPnfEdit = true;

                if (this.subConRolePartNumbers.indexOf(existingDemand.rolePartNumber) > -1) {
                    existingRoleWithEdit.isSubconRole = true;
                    existingRoleWithEdit.currentState.blendedCostRate = demandDiff.cost / demandDiff.quantity;
                } else {
                    existingRoleWithEdit.currentState.blendedCostRate = existingDemand.planCostRate;
                }

                existingRoleWithEdit.savedState = this.cloneCurrentState(existingRoleWithEdit);

                demandsWithChanges.push(existingRoleWithEdit);
            } else if (!existingDemand) {
                const isPartOfFailedProj = tasksToCompare.filter((task) => demandDiff.id === task).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }

                // new role added from plan and forecast
                const newRole = this.getAddRoleInitialState();

                newRole.currentState.existingHours = 0;
                newRole.currentState.hours = demandDiff.quantity;
                newRole.currentState.assignedTask = fcrRoleData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                newRole.currentState.resourceLocationKey = this.getShortenedResourceLocation(demandDiff.resourceLocation);
                newRole.currentState.blendedCostRate = parseFloat((demandDiff.cost / demandDiff.quantity).toFixed(3));
                newRole.currentState.billingInfo = fcrRoleData.billRates.filter((b) => b.billingRoleId === demandDiff.billingRoleId)[0];
                if (!newRole.currentState.billingInfo) {
                    newRole.currentState.billingInfo = fcrRoleData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0];
                }

                newRole.editModeEnabled = false;
                newRole.isAutoPopulated = true;
                newRole.isPnfEdit = true;
                newRole.currentState.roleValues = this.getDropdownValidRoles(newRole, engagementDetails, fcrRoleData.roleValuesFF, fcrRoleData.roleValuesTM);
                newRole.currentState.role = this.getRoleInfoFromString(demandDiff.role, newRole.currentState.roleValues);

                if (this.subconRoleInfoStrings.indexOf(demandDiff.role) > -1) {
                    newRole.isSubconRole = true;
                }

                newRole.savedState = this.cloneCurrentState(newRole);

                demandsWithChanges.push(newRole);
            }
        }

        // See if there are any demands that are not in demand diff but are in existing resources
        const deletedDemands = fcrRoleData.existingResources.filter((demand) => !filteredLaborDemandDiffs.some((demandDiffInfo) => demandDiffInfo.grmDemandId === demand.demandId));

        for (const deletedDemand of deletedDemands) {
            const isPartOfFailedProj = tasksToCompare.filter((task) => deletedDemand.taskId === task).length > 0;
            if (!isPartOfFailedProj) {
                continue;
            }

            // edit of an existing role on pnf
            const existingRoleToBeRemoved = this.getAdjustRoleInitialState();

            existingRoleToBeRemoved.currentState.existingHours = deletedDemand.planHours;
            existingRoleToBeRemoved.currentState.hours = deletedDemand.planHours * -1;
            existingRoleToBeRemoved.currentState.assignedTask = fcrRoleData.existingTasks.filter((l) => deletedDemand.taskId === l.wbsl3Id)[0];
            existingRoleToBeRemoved.currentState.billingInfo = fcrRoleData.billRates.filter((b) => b.billingRoleId === deletedDemand.billingRoleId)[0];
            existingRoleToBeRemoved.currentState.existingDemand = deletedDemand;
            existingRoleToBeRemoved.currentState.existingDemand.isDisplayed = true;
            existingRoleToBeRemoved.currentState.resourceLocationKey = this.getShortenedResourceLocation(deletedDemand.resourceLocationKey);

            existingRoleToBeRemoved.editModeEnabled = false;
            existingRoleToBeRemoved.isAutoPopulated = true;
            existingRoleToBeRemoved.isPnfEdit = true;

            if (this.subConRolePartNumbers.indexOf(deletedDemand.rolePartNumber) > -1) {
                existingRoleToBeRemoved.isSubconRole = true;
            }
            existingRoleToBeRemoved.currentState.blendedCostRate = deletedDemand.planCostRate;

            existingRoleToBeRemoved.savedState = this.cloneCurrentState(existingRoleToBeRemoved);

            demandsWithChanges.push(existingRoleToBeRemoved);
        }

        return demandsWithChanges;
    }

    /**
     * Determine which demands have been edited based on demand diff data from plan and forecast screen and
     * autopopulate these changes on the form.
     *
     * @param {IDemandDiff[]} demandDiffData
     * @param {IFcrUnitsFormControlData} fcrUnitFormData
     * @param {IFinancialRoles[]} roleDomainData
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {string[]} projectsThatFailedThresholdCheck
     * @returns {ICrDemandOutput[]}
     * @memberof ChangeRequestDemandService
     */
    public determineUnitsDemandDiff(demandDiffData: IDemandDiff[], fcrUnitFormData: IFcrUnitsFormControlData, roleDomainData: IFinancialRoles[], engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrUnitOutput[] {
        const unitsWithChanges: ICrUnitOutput[] = [];

        const tasksToCompare = fcrUnitFormData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id);
        const unitDemandsForFailedProjects = fcrUnitFormData.existingResources.filter((demand) => tasksToCompare.includes(demand.structureId));

        // TODO: will refactor and extract to method in next PR for filtering by resource type
        const filteredUnitDemandDiffs = demandDiffData.filter((dDiff) => dDiff.grmDemandId && dDiff.grmDemandId !== "#" && (dDiff.role.split("/")[0] === ResourceTypeCode.Unit));

        for (const demandDiff of filteredUnitDemandDiffs) {
            const existingUnitDemand = fcrUnitFormData.existingResources.filter((demand) => demandDiff.grmDemandId === demand.demandId)[0];

            if (existingUnitDemand && (demandDiff.quantity !== existingUnitDemand.plannedHours || Math.round(demandDiff.cost) !== Math.round(existingUnitDemand.plannedCost))) {
                const isPartOfFailedProj = unitDemandsForFailedProjects.filter((demand) => existingUnitDemand.demandId === demand.demandId).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }

                // edit of an existing role on pnf
                const existingUnitWithEdit = this.getAdjustUnitInitialState();

                existingUnitWithEdit.currentState.newUnits = demandDiff.quantity - existingUnitDemand.plannedHours;
                existingUnitWithEdit.currentState.assignedTask = fcrUnitFormData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                existingUnitWithEdit.currentState.billingInfo = fcrUnitFormData.billRates.filter((b) => b.billingRoleId === demandDiff.billingRoleId)[0];

                existingUnitWithEdit.currentState.role = this.getRoleInfoFromString(demandDiff.role, roleDomainData);
                // Temporary check for valid unit role which exists on deal until Lumira change is ready (Post MQR 2021)
                if (!existingUnitWithEdit.currentState.role) {
                    continue;
                }

                existingUnitWithEdit.currentState.existingResource = existingUnitDemand;
                existingUnitWithEdit.currentState.existingResource.isDisplayed = true;

                existingUnitWithEdit.editModeEnabled = false;
                existingUnitWithEdit.isAutoPopulated = true;
                existingUnitWithEdit.isPnfEdit = true;

                existingUnitWithEdit.currentState.costRate = existingUnitDemand.plannedCostRate;
                existingUnitWithEdit.savedState = this.cloneUnitsCurrentState(existingUnitWithEdit);
                unitsWithChanges.push(existingUnitWithEdit);
            } else if (!existingUnitDemand) {
                const isPartOfFailedProj = tasksToCompare.filter((task) => demandDiff.id === task).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }

                // new role added from plan and forecast
                const newRole = this.getAddUnitInitialState();

                newRole.currentState.newUnits = demandDiff.quantity;
                newRole.currentState.assignedTask = fcrUnitFormData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                newRole.currentState.costRate = parseFloat((demandDiff.cost / demandDiff.quantity).toFixed(3));

                newRole.currentState.role = this.getRoleInfoFromString(demandDiff.role, roleDomainData);
                // Temporary check for valid unit role which exists on deal until Lumira change is ready (Post MQR 2021)
                if (!newRole.currentState.role) {
                    continue;
                }

                newRole.currentState.billingInfo = fcrUnitFormData.billRates.filter((b) => b.billingRoleId === demandDiff.billingRoleId)[0];
                if (!newRole.currentState.billingInfo) {
                    newRole.currentState.billingInfo = fcrUnitFormData.billRates.filter((b) => b.billingRoleId === this.nonBillValue)[0];
                }

                newRole.editModeEnabled = false;
                newRole.isAutoPopulated = true;
                newRole.isPnfEdit = true;
                // newRole.currentState.roleValues = this.getDropdownValidRoles(newRole, engagementDetails, fcrUnitFormData.roleValuesFF, fcrUnitFormData.roleValuesTM);

                newRole.savedState = this.cloneUnitsCurrentState(newRole);
                unitsWithChanges.push(newRole);
            }
        }

        return unitsWithChanges;
    }

    /**
     * Determine which Expense items have been edited based on diff data from plan and forecast screen and
     * autopopulate these changes on the form.
     *
     * @param {IDemandDiff[]} demandDiffData
     * @param {IFcrExpensesFormControlData} fcrExpenseFormData
     * @param {IFinancialRoles[]} roleDomainData
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {string[]} projectsThatFailedThresholdCheck
     * @memberof ChangeRequestDemandService
     */
    public determineExpenseDiffs(demandDiffData: IDemandDiff[], fcrExpenseFormData: IFcrExpensesFormControlData, roleDomainData: IFinancialRoles[], engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrExpenseOutput[] {
        const expensesWithChanges: ICrExpenseOutput[] = [];

        const tasksToCompare = fcrExpenseFormData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id);
        const expensesForFailedProjects = fcrExpenseFormData.existingResources.filter((expenseData) => tasksToCompare.includes(expenseData.structureId));

        // TODO: will refactor and extract to method in next PR for filtering by resource type
        const filteredExpenseDiffs = demandDiffData.filter((dDiff) => dDiff.id && (dDiff.role.split("/")[0] === ResourceTypeCode.Expense));

        for (const demandDiff of filteredExpenseDiffs) {
            const existingExpense = fcrExpenseFormData.existingResources.filter((expense) => demandDiff.id === expense.structureId)[0];

            if (existingExpense) {
                const isPartOfFailedProj = expensesForFailedProjects.filter((expenseItem) => existingExpense.structureId === expenseItem.structureId).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }
            } else {
                const isPartOfFailedProj = tasksToCompare.filter((task) => demandDiff.id === task).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }
            }

            if (existingExpense && (demandDiff.cost !== Number(existingExpense.plannedCost))) {
                // edit of an existing expense on pnf
                const existingExpenseWithEdit = this.getAdjustExpenseInitialState();

                existingExpenseWithEdit.currentState.assignedTask = fcrExpenseFormData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                existingExpenseWithEdit.currentState.existingCost = existingExpense.plannedCost;
                existingExpenseWithEdit.currentState.newPlannedCost = parseFloat((demandDiff.cost - existingExpense.plannedCost).toFixed(2));
                existingExpenseWithEdit.currentState.billableType = ExpenseBillableTypes.filter((billType) => billType.code === demandDiff.role.split("/")[1])[0];

                existingExpenseWithEdit.currentState.existingResource = existingExpense;
                existingExpenseWithEdit.currentState.existingResource.isDisplayed = true;

                existingExpenseWithEdit.editModeEnabled = false;
                existingExpenseWithEdit.isAutoPopulated = true;
                existingExpenseWithEdit.isPnfEdit = true;

                existingExpenseWithEdit.savedState = this.cloneExpenseCurrentState(existingExpenseWithEdit);

                expensesWithChanges.push(existingExpenseWithEdit);
            } if (!existingExpense) {
                // new expense added from plan and forecast
                const newExpense = this.getAddExpenseInitialState();

                newExpense.currentState.assignedTask = fcrExpenseFormData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                newExpense.currentState.existingCost = 0;
                newExpense.currentState.newPlannedCost = demandDiff.cost;
                newExpense.currentState.billableType = ExpenseBillableTypes.filter((billType) => billType.code === demandDiff.role.split("/")[1])[0];

                newExpense.editModeEnabled = false;
                newExpense.isAutoPopulated = true;
                newExpense.isPnfEdit = true;

                newExpense.savedState = this.cloneExpenseCurrentState(newExpense);

                expensesWithChanges.push(newExpense);
            }
        }

        return expensesWithChanges;
    }

    /**
     * Determine which Subcon FF items have been edited based on diff data from plan and forecast screen and
     * autopopulate these changes on the form.
     *
     * @param {IDemandDiff[]} demandDiffData
     * @param {IFcrSubconFormControlData} fcrSubconFormData
     * @param {IFinancialRoles[]} roleDomainData
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {string[]} projectsThatFailedThresholdCheck
     * @memberof ChangeRequestDemandService
     */
    public determineSubconDiffs(demandDiffData: IDemandDiff[], fcrSubconFormData: IFcrSubconFormControlData, roleDomainData: IFinancialRoles[], engagementDetails: IEngagementDetailsApiV2, projectsThatFailedThresholdCheck: string[]): ICrSubconOutput[] {
        const subconsWithChanges: ICrSubconOutput[] = [];

        const tasksToCompare = fcrSubconFormData.existingTasks.filter((task) => projectsThatFailedThresholdCheck.includes(task.projectId)).map((t) => t.wbsl3Id);
        const subconsForFailedProjects = fcrSubconFormData.existingResources.filter((subconData) => tasksToCompare.includes(subconData.structureId));

        // TODO: will refactor and extract to method in next PR for filtering by resource type
        const filteredSubconDiffs = demandDiffData.filter((dDiff) => dDiff.id && (dDiff.role.split("/")[0] === ResourceTypeCode.SubconFF && dDiff.uom.toUpperCase() === "%"));

        for (const demandDiff of filteredSubconDiffs) {
            const existingSubcon = fcrSubconFormData.existingResources.filter((subcon) => demandDiff.id === subcon.structureId)[0];

            if (existingSubcon) {
                const isPartOfFailedProj = subconsForFailedProjects.filter((subconItem) => existingSubcon.structureId === subconItem.structureId).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }
            } else {
                const isPartOfFailedProj = tasksToCompare.filter((task) => demandDiff.id === task).length > 0;
                if (!isPartOfFailedProj) {
                    continue;
                }
            }

            if (existingSubcon && (demandDiff.cost !== Number(existingSubcon.plannedCost))) {
                // edit of an existing subcon on pnf
                const existingSubconWithEdit = this.getAdjustSubconInitialState();

                existingSubconWithEdit.currentState.assignedTask = fcrSubconFormData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                existingSubconWithEdit.currentState.existingCost = existingSubcon.plannedCost;
                existingSubconWithEdit.currentState.newPlannedCost = parseFloat((demandDiff.cost - existingSubcon.plannedCost).toFixed(2));
                existingSubconWithEdit.currentState.existingResource = existingSubcon;
                existingSubconWithEdit.currentState.existingResource.isDisplayed = true;

                existingSubconWithEdit.editModeEnabled = false;
                existingSubconWithEdit.isAutoPopulated = true;
                existingSubconWithEdit.isPnfEdit = true;

                existingSubconWithEdit.savedState = this.cloneSubconCurrentState(existingSubconWithEdit);

                subconsWithChanges.push(existingSubconWithEdit);
            } if (!existingSubcon) {
                // new subcon added from plan and forecast
                const newSubcon = this.getAddSubconInitialState();

                newSubcon.currentState.assignedTask = fcrSubconFormData.existingTasks.filter((l) => demandDiff.id === l.wbsl3Id)[0];
                newSubcon.currentState.existingCost = 0;
                newSubcon.currentState.newPlannedCost = demandDiff.cost;

                newSubcon.editModeEnabled = false;
                newSubcon.isAutoPopulated = true;
                newSubcon.isPnfEdit = true;

                newSubcon.savedState = this.cloneSubconCurrentState(newSubcon);

                subconsWithChanges.push(newSubcon);
            }
        }

        return subconsWithChanges;
    }

    /**
     * Construct array with edited roles and roles for reconciliation and remove any duplicate data favoring edited roles
     * from plan and forecast.
     *
     * @param {ICrDemandOutput[]} rolesForReconciliation
     * @param {ICrDemandOutput[]} rolesWithEdits
     * @returns {ICrDemandOutput[]}
     * @memberof ChangeRequestDemandService
     */
    public removeLaborAutopopulateDuplicateData(rolesForReconciliation: ICrDemandOutput[], rolesWithEdits: ICrDemandOutput[]): ICrDemandOutput[] {
        const autopopulateRoles: ICrDemandOutput[] = [...rolesWithEdits];

        for (const reconcileRole of rolesForReconciliation) {
            const editedRole = rolesWithEdits.filter((role) => role.currentState.existingDemand && reconcileRole.currentState.existingDemand && (role.currentState.existingDemand.demandId === reconcileRole.currentState.existingDemand.demandId))[0];

            if (!editedRole) {
                autopopulateRoles.push(reconcileRole);
            }
        }

        return autopopulateRoles;
    }

    /**
     * Construct array with edited unit items and unit items for reconciliation and remove any duplicate data favoring edited unit items
     * from plan and forecast.
     * 
     * TODO: Combine with above with some base class due to similarity of fields
     *
     * @param {ICrUnitOutput[]} unitsForReconciliation
     * @param {ICrUnitOutput[]} unitsWithEdits
     * @returns {ICrUnitOutput[]}
     * @memberof ChangeRequestDemandService
     */
    public removeUnitAutopopulateDuplicateData(unitsForReconciliation: ICrUnitOutput[], unitsWithEdits: ICrUnitOutput[]): ICrUnitOutput[] {
        const autopopulateUnitItems: ICrUnitOutput[] = [...unitsWithEdits];

        for (const reconcileUnit of unitsForReconciliation) {
            const editedUnit = unitsWithEdits.filter((unitItem) => unitItem.currentState.existingResource && reconcileUnit.currentState.existingResource && (unitItem.currentState.existingResource.demandId === reconcileUnit.currentState.existingResource.demandId))[0];

            if (!editedUnit) {
                autopopulateUnitItems.push(reconcileUnit);
            }
        }

        return autopopulateUnitItems;
    }

    /**
     * Construct array with edited expense/subcon items and expense/subcon items for reconciliation and remove any duplicate data favoring edited expense/subcon items
     * from plan and forecast.
     *
     * @param {ICrExpenseOutput[]} dataForReconciliation
     * @param {ICrExpenseOutput[]} dataWithEdits
     * @returns {ICrExpenseOutput[]}
     * @memberof ChangeRequestDemandService
     */
    public removeAutopopulateDuplicateData(dataForReconciliation: ICrResource[], dataWithEdits: ICrResource[]): ICrResource[] {
        const autopopulateItems: ICrResource[] = [...dataWithEdits];

        for (const reconcileItem of dataForReconciliation) {
            const editedItem = dataWithEdits.filter((item) => item.currentState.existingResource && reconcileItem.currentState.existingResource && (item.currentState.existingResource.structureId === reconcileItem.currentState.existingResource.structureId))[0];

            if (!editedItem) {
                autopopulateItems.push(reconcileItem);
            }
        }

        return autopopulateItems;
    }

    /**
     * Returns true if the object has all the required information to enter the non-edit state. Labor
     */
    public isRoleRequestSaveable(rowLineItem: ICrDemandOutput): boolean {
        if (rowLineItem.currentState.isCrPendingInProject) {
            return false;
        }

        if (rowLineItem.currentState.existingDemand) {

            const cfpHours = rowLineItem.currentState.existingDemand.planHours || 0;
            const blendedCostRate = rowLineItem.currentState.blendedCostRate || 0;
            const dbCost = rowLineItem.currentState.existingDemand.dbCost || 0;

            /* to seperate the 2 conditions properly */
            const hasAdditionalHours_Or_CfpDbCostAreNotSame = (+rowLineItem.currentState.hours !== 0 || (dbCost !== (cfpHours * blendedCostRate)));

            if ((hasAdditionalHours_Or_CfpDbCostAreNotSame || (rowLineItem.currentState.resourceLocationKey !== this.getShortenedResourceLocation(rowLineItem.currentState.existingDemand.resourceLocationKey)))) {
                return true;
            } else {
                return false;
            }
        } else {
            if (rowLineItem.currentState.assignedTask.isFixedFeeProject && !rowLineItem.currentState.billingInfo) {
                return false;
            }
            if (rowLineItem.currentState.assignedTask && rowLineItem.currentState.role && +rowLineItem.currentState.hours > 0 && rowLineItem.currentState.isCostRateResolved) {
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     * Returns true if the object has all the required information to enter the non-edit state for Units.
     */
    public isUnitRequestSaveable(rowLineItem: ICrUnitOutput): boolean {
        if (rowLineItem.currentState.isCrPendingInProject) {
            return false;
        }

        // TODO: Add/Finish unit validations
        if (rowLineItem.currentState.existingResource) {

            const cfpUnits = rowLineItem.currentState.existingResource.plannedHours || 0;
            const costRate = rowLineItem.currentState.costRate || 0;
            const dbCost = rowLineItem.currentState.existingResource.dbCost || 0;

            /* to seperate the 2 conditions properly */
            const hasAdditionalUnits_Or_CfpDbCostAreNotSame = (+rowLineItem.currentState.newUnits !== 0 || (dbCost !== (cfpUnits * costRate)));

            if (hasAdditionalUnits_Or_CfpDbCostAreNotSame) {
                return true;
            } else {
                return false;
            }
        } else {
            if (rowLineItem.currentState.billingInfo && rowLineItem.currentState.assignedTask && rowLineItem.currentState.role && +rowLineItem.currentState.newUnits !== 0 && rowLineItem.currentState.isCostRateResolved) {
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     * Returns true if the object has all the required information to enter the non-edit state for Expenses.
     */
    public isExpenseRequestSaveable(rowLineItem: ICrExpenseOutput): boolean {
        if (rowLineItem.currentState.isCrPendingInProject) {
            return false;
        }
        if (rowLineItem.currentState.newPlannedCost !== 0 && rowLineItem.currentState.billableType && rowLineItem.currentState.billableType.code) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Returns true if the object has all the required information to enter the non-edit state for Subcon FF.
     */
    public isSubconRequestSaveable(rowLineItem: ICrSubconOutput): boolean {
        if (rowLineItem.currentState.isCrPendingInProject) {
            return false;
        }

        if (rowLineItem.currentState.existingResource && rowLineItem.currentState.newPlannedCost !== 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Returns shortened resource location code (last 4 digits).
     *
     * @param {string} resourceLocation
     * @returns {string}
     * @memberof ChangeRequestDemandService
     */
    public getShortenedResourceLocation(resourceLocation: string): string {
        return resourceLocation.slice(-4);
    }

    /**
     * Returns the long format of resource location code used by SAP (10 digits).
     *
     * @param {string} resourceLocation
     * @returns {string}
     * @memberof ChangeRequestDemandService
     */
    public getLongResourceLocation(shortResourceLocation: string): string {
        return "000000" + shortResourceLocation;
    }

    /**
     * Return a boolean to indicate if project ID is in pending CR list.
     *
     * @param {string} projectId
     * @param {string[]} projectsWithPendingCr
     * @returns {boolean}
     * @memberof ChangeRequestDemandService
     */
    public isCrPendingInProject(projectId: string, projectsWithPendingCr: string[]): boolean {
        return projectsWithPendingCr && projectsWithPendingCr.includes(projectId);
    }

    /**
     * Identify tasks which are part of a project with pending cr and return task data with
     * indicator flag set.
     *
     * @param {ITask[]} tasks
     * @param {string[]} projectsWithPendingCr
     * @returns {ITask[]}
     * @memberof ChangeRequestDemandService
     */
    public identifyTasksWithPendingCr(tasks: ITask[], projectsWithPendingCr: string[]): ITask[] {
        return tasks.map((taskData) => {
            taskData.isCrPendingInProject = projectsWithPendingCr.includes(taskData.projectId);
            return taskData;
        });
    }

    public getFcrLaborRequestDetails(financialSummary: IEngagementFinancialPlanSummary, formRequestControl: AbstractControl, fcrRolesFormControlData: IFcrRolesFormControlData): IChangeRequestResourcePayloadDetails {
        let hours: number = 0;
        let cost: number = 0;
        const resourceRequestDetails: IChangeRequestDetails[] = [];
        formRequestControl.value.map((laborRequest: ICrDemandOutput) => {
            const task = fcrRolesFormControlData.existingResources.filter((t) => t.taskId === this.getWbsl3Id(laborRequest))[0];
            const existingHours = laborRequest.savedState.existingDemand ? laborRequest.savedState.existingDemand.planHours : 0;
            const planCost = laborRequest.isDbOnlyDemand ? 0 : laborRequest.savedState.blendedCostRate * (existingHours + +laborRequest.savedState.hours);
            const planRevenue = (laborRequest.savedState.billingInfo ? laborRequest.savedState.billingInfo.billRate : 0) * (existingHours + +laborRequest.savedState.hours);
            const additionalCost = planCost - (laborRequest.currentState.existingDemand && laborRequest.currentState.existingDemand.dbCost ? laborRequest.currentState.existingDemand.dbCost : 0);

            hours += laborRequest.isDbOnlyDemand ? 0 : +laborRequest.savedState.hours;
            cost += additionalCost;

            resourceRequestDetails.push({
                planId: financialSummary.planId,
                planVersionId: FinancialType.CurrentFinancialPlan,
                planCurrency: financialSummary.planCurrency,
                structureElement: task ? task.taskId : laborRequest.savedState.assignedTask.wbsl3Id,
                billingRoleId: this.getBillingRoleId(laborRequest),
                resourceType: this.getResourceType(laborRequest),
                roleId: this.getActivityCode(laborRequest),
                roleDescription: this.getRoleName(laborRequest),
                existingRoleDescription: laborRequest.currentState.existingDemand && laborRequest.currentState.role ? laborRequest.currentState.existingDemand.roleDescription : null,
                laborUnits: financialSummary.laborUnits,
                quantity: existingHours,
                requestedQuantity: laborRequest.isDbOnlyDemand ? 0 : laborRequest.savedState.hours,
                costRate: parseFloat(laborRequest.savedState.blendedCostRate.toFixed(3)),
                planCost: parseFloat(planCost.toFixed(2)),
                planRevenue: parseFloat(planRevenue.toFixed(2)),
                billRate: this.getBillRate(laborRequest),
                resourceLocationKey: this.getLongResourceLocation(laborRequest.savedState.resourceLocationKey),
                demandId: laborRequest.savedState.existingDemand ? laborRequest.savedState.existingDemand.demandId : null,
                dbCost: laborRequest.savedState.existingDemand ? laborRequest.savedState.existingDemand.dbCost : null,
                dbHour: laborRequest.savedState.existingDemand ? laborRequest.savedState.existingDemand.dbHours : null,
                existingCfpCost: laborRequest.savedState.existingDemand ? laborRequest.savedState.existingDemand.planCost : null,
            });
        });
        return {
            resourceRequestDetails,
            totalAdditionalCost: +cost,
            totalAdditionalHours: +hours
        };
    }

    public getFcrExpenseRequestDetails(financialSummary: IEngagementFinancialPlanSummary, formRequestControl: AbstractControl): IChangeRequestResourcePayloadDetails {
        let cost: number = 0;
        const resourceRequestDetails: IChangeRequestDetails[] = [];
        formRequestControl.value.map((expenseRequest: ICrExpenseOutput) => {
            const existingResource = expenseRequest.currentState && expenseRequest.currentState.existingResource;
            const existingPlanCost = expenseRequest.savedState.existingResource ? expenseRequest.savedState.existingResource.plannedCost : 0;
            const planCost = expenseRequest.savedState.newPlannedCost + existingPlanCost;
            const additionalCost = planCost - (expenseRequest.currentState.existingResource && expenseRequest.currentState.existingResource.dbCost ? expenseRequest.currentState.existingResource.dbCost : 0);

            cost += additionalCost;

            resourceRequestDetails.push({
                planId: financialSummary.planId,
                planVersionId: FinancialType.CurrentFinancialPlan,
                planCurrency: financialSummary.planCurrency,
                structureElement: expenseRequest.savedState.assignedTask && expenseRequest.savedState.assignedTask.wbsl3Id,
                billingRoleId: null,
                resourceType: ResourceTypeCode.Expense,
                roleId: expenseRequest.savedState.assignedTask.isFixedFeeProject ? (existingResource ? existingResource.roleId : expenseRequest.savedState.billableType.code) : ExpenseBillableCode.NonBillable,
                roleDescription: null,
                existingRoleDescription: null,
                laborUnits: "H",
                quantity: null,
                requestedQuantity: null,
                costRate: null,
                planCost: expenseRequest.savedState.existingResource ? +expenseRequest.savedState.existingResource.plannedCost : 0,
                requestedCost: expenseRequest.savedState.newPlannedCost,
                planRevenue: null,
                billRate: null,
                resourceLocationKey: null,
                demandId: null,
                dbCost: expenseRequest.savedState.existingResource ? expenseRequest.savedState.existingResource.dbCost : 0,
                cfpCost: expenseRequest.savedState.existingResource ? expenseRequest.savedState.existingResource.cfpCost : expenseRequest.savedState.newPlannedCost
            });
        });

        return {
            resourceRequestDetails,
            totalAdditionalCost: +cost
        };
    }

    public getFcrSubconRequestDetails(financialSummary: IEngagementFinancialPlanSummary, formRequestControl: AbstractControl): IChangeRequestResourcePayloadDetails {
        let cost: number = 0;
        const resourceRequestDetails: IChangeRequestDetails[] = [];
        formRequestControl.value.map((subconRequest: ICrSubconOutput) => {
            const existingPlanCost = subconRequest.savedState.existingResource ? subconRequest.savedState.existingResource.plannedCost : 0;
            const planCost = subconRequest.savedState.newPlannedCost + existingPlanCost;
            const additionalCost = planCost - (subconRequest.currentState.existingResource && subconRequest.currentState.existingResource.dbCost ? subconRequest.currentState.existingResource.dbCost : 0);

            cost += additionalCost;

            resourceRequestDetails.push({
                planId: financialSummary.planId,
                planVersionId: FinancialType.CurrentFinancialPlan,
                planCurrency: financialSummary.planCurrency,
                structureElement: subconRequest.savedState.assignedTask && subconRequest.savedState.assignedTask.wbsl3Id,
                billingRoleId: null,
                resourceType: ResourceTypeCode.SubconFF,
                roleId: "ESSUC",
                roleDescription: null,
                existingRoleDescription: null,
                laborUnits: "%",
                quantity: null,
                requestedQuantity: null,
                costRate: null,
                planCost: subconRequest.savedState.existingResource ? +subconRequest.savedState.existingResource.plannedCost : 0,
                requestedCost: subconRequest.savedState.newPlannedCost,
                planRevenue: null,
                billRate: null,
                resourceLocationKey: null,
                demandId: null,
                cfpCost: subconRequest.savedState.existingResource ? +subconRequest.savedState.existingResource.cfpCost : subconRequest.savedState.newPlannedCost,
                dbCost: subconRequest.savedState.existingResource ? +subconRequest.savedState.existingResource.dbCost : 0,
                dbHour: null
            });
        });
        return {
            resourceRequestDetails,
            totalAdditionalCost: +cost
        };
    }

    public getFcrUnitRequestDetails(financialSummary: IEngagementFinancialPlanSummary, formRequestControl: AbstractControl): IChangeRequestResourcePayloadDetails {
        // let hours: number = 0;  // Do we send for units?
        let cost: number = 0;
        const resourceRequestDetails: IChangeRequestDetails[] = [];
        formRequestControl.value.map((unitRequest: ICrUnitOutput) => {
            const existingResource = unitRequest.savedState && unitRequest.savedState.existingResource ? unitRequest.savedState.existingResource : null;
            const existingQuantity = existingResource ? existingResource.plannedHours : 0;
            const planCost = unitRequest.isDbOnlyDemand ? 0 : unitRequest.savedState.costRate * (+existingQuantity + +unitRequest.savedState.newUnits);
            const planRevenue = (unitRequest.savedState.billingInfo ? unitRequest.savedState.billingInfo.billRate : 0) * (existingQuantity + +unitRequest.savedState.newUnits);
            const additionalCost = planCost - (existingResource && existingResource.dbCost ? existingResource.dbCost : 0);

            cost += additionalCost;

            resourceRequestDetails.push({
                planId: financialSummary.planId,
                planVersionId: FinancialType.CurrentFinancialPlan,
                planCurrency: financialSummary.planCurrency,
                structureElement: unitRequest.savedState.assignedTask.wbsl3Id,
                billingRoleId: unitRequest.savedState.billingInfo ? unitRequest.savedState.billingInfo.billingRoleId : "",
                resourceType: ResourceTypeCode.Unit,
                roleId: existingResource ? existingResource.roleInfo && existingResource.roleInfo.activityCode : unitRequest.savedState.role.activityCode,
                roleDescription: existingResource ? existingResource.roleDescription : unitRequest.savedState.role.roleName,
                laborUnits: "EA",
                quantity: existingQuantity,
                requestedQuantity: unitRequest.savedState && unitRequest.savedState.newUnits,
                costRate: unitRequest.savedState && unitRequest.savedState.costRate,
                planCost: parseFloat(planCost.toFixed(2)),
                requestedCost: 0,
                planRevenue: parseFloat(planRevenue.toFixed(2)),
                billRate: unitRequest.savedState && unitRequest.savedState.billingInfo ? unitRequest.savedState.billingInfo.billRate : 0,
                resourceLocationKey: this.getLongResourceLocation(unitRequest.savedState.assignedTask.resourceLocation),
                demandId: existingResource ? existingResource.demandId : "",
                dbCost: existingResource ? existingResource.dbCost : 0,
                dbHour: existingResource ? existingResource.dbUnits : 0,
                existingCfpCost: existingResource ? existingResource.plannedCost : null
            });
        });

        return {
            resourceRequestDetails,
            totalAdditionalCost: +cost
        };
    }

    /**
     * Get all existing subcontractor cost rates from existing CFP demands.
     *
     * @param {IWbsDemand} cfpDemands
     * @returns {any[]}
     * @memberof ChangeRequestDemandService
     */
    public getSubconCostRates(cfpDemands: IWbsDemand): any[] {
        const subconDemands = cfpDemands.details.filter((subconDemand) => subconDemand.planned && this.subConRolePartNumbers.indexOf(subconDemand.planned.roleId) > -1);
        // Ensure no duplicate cost rates
        return subconDemands.map((subcon) => subcon.planned.plannedCostRate)
            .filter((elem, index, self) => {
                return index === self.indexOf(elem);
            });
    }

    /**
     * Determine if the project this resource is being assigned to is PFP or PFF. If so then billing role id is only allowed to be non-bill
     */
    private determineIfProjectIsPFPOrPFF(laborRequest: ICrDemandOutput, engagementDetails: IEngagementDetailsApiV2, nonBill: IBillRate): void {
        laborRequest.currentState.isProjectPFPOrPFF = false;
        for (const project of engagementDetails.projects) {
            if (project.id === laborRequest.currentState.assignedTask.projectId) {
                laborRequest.currentState.isProjectPFPOrPFF = project.userStatusCode.indexOf("PFP") >= 0 || project.userStatusCode.indexOf("PFF") >= 0;
                for (const service of project.services) {
                    if (service.name && service.name.indexOf("PFG") >= 0) {
                        laborRequest.currentState.isProjectPFPOrPFF = service.userStatusCode.indexOf("PFP") >= 0 || service.userStatusCode.indexOf("PFF") >= 0;
                    }
                }
                if (laborRequest.currentState.isProjectPFPOrPFF) {
                    laborRequest.currentState.billingInfo = nonBill;
                }
            }
        }
    }

    private getDropdownValidRoles(laborRequest: ICrDemandOutput, engagementDetails: IEngagementDetailsApiV2, ffRoles: IFinancialRoles[], tmRoles: IFinancialRoles[]): IFinancialRoles[] {
        for (const project of engagementDetails.projects) {
            if (project.id === laborRequest.currentState.assignedTask.projectId) {
                if (project.contractType === "FF") {
                    return ffRoles;
                } else if (project.contractType === "T&M") {
                    return tmRoles;
                }
            }
        }
    }

    /**
     * retrieve a list of all unique tasks for an engagement that current user is allowed to access.
     */
    private getAuthorizedTasks(engagementDetails: IEngagementDetailsApiV2, resourceType?: string): ITask[] {
        const tasks: ITask[] = [];
        const validWorkPackageTypes = this.getWorkPackageTypeCodes(resourceType);
        // engagement level access check
        const isUserAuthorizedAtEngagementLevel = this.dmAuthService.isUserInEngagementLevelTeamV2(engagementDetails);

        for (const project of engagementDetails.projects) {
            // project level access check
            let isUserAuthorizedAtProjectLevel = false;
            if (!isUserAuthorizedAtEngagementLevel) {
                isUserAuthorizedAtProjectLevel = this.dmAuthService.isUserInProjectLevelTeamV2(project);
            }

            if (isUserAuthorizedAtEngagementLevel || isUserAuthorizedAtProjectLevel) {
                for (const service of project.services) {
                    for (const task of service.tasks) {
                        const taskData = {
                            wbsl3Id: task.id,
                            wbsl3Name: task.name,
                            projectId: project.id,
                            projectName: project.name,
                            isFixedFeeProject: project.contractType === "FF",
                            serviceName: service.name,
                            resourceLocation: project.companyCode,
                            projectStartDate: project.startDate,
                            projectEndDate: project.endDate
                        };
                        if (validWorkPackageTypes && validWorkPackageTypes.includes(task.workPackageType)) {
                            tasks.push(taskData);
                        }
                    }
                }
            }
        }
        return tasks;
    }

    private getWorkPackageTypeCodes(resourceType: string): string[] {
        switch (resourceType) {
            case ResourceType.Labor:
                return this.configurationService.getValue<string[]>("ValidLaborWorkPackageTypesForFcrRequest");
                break;
            case ResourceType.Expense:
                return this.configurationService.getValue<string[]>("ExpensesWorkPackageType");
                break;
            case ResourceType.Unit:
                return this.configurationService.getValue<string[]>("UnitsWorkPackageType");
                break;
            case ResourceType.SubconFF:
                return this.configurationService.getValue<string[]>("SubconWorkPackageType");
                break;
        }
    }

    /**
     * Get financial role by role description.
     *
     * @param {string} roleDescription
     * @returns {IFinancialRoles}
     * @memberof LaborRequest
     */
    private getRoleByDescription(roleDescription: string, roleValues: IFinancialRoles[]): IFinancialRoles {
        return roleValues.filter((role: IFinancialRoles) => role.roleName.toLowerCase() === roleDescription.toLowerCase())[0];
    }

    /**
     * Parse resource type and activity code from role string given in SAP Demand Diff data.
     * 
     * Example Format: "0ACT/ESOFC" (resource type/activity code)
     *
     * @private
     * @param {string} role
     * @returns {IFinancialRole}
     * @memberof ChangeRequestDemandService
     */
    private getRoleInfoFromString(role: string, roleValues: IFinancialRoles[]): IFinancialRoles {
        const roleStringData = role.split("/");
        let activityCode;

        if (roleStringData.length === 2) {
            activityCode = roleStringData[1];
        }

        return roleValues.filter((r) => r.activityCode === activityCode)[0];
    }

    /**
     * Identify demands which are part of a project with pending CR. This is used purely
     * for styling purposes to clearly distiguish demands which have a pending CR in project.
     *
     * @private
     * @param {IExistingDemand[]} demandData
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {string[]} projectsWithPendingCr
     * @returns {IExistingDemand[]}
     * @memberof FinancialChangeRequestComponent
     */
    private identifyDemandsWithPendingCr(demandData: ICrCostResource[], engagementDetails: IEngagementDetailsApiV2, projectsWithPendingCr: string[]): ICrCostResource[] {
        return demandData.map((demand) => {
            const projectInfo = this.projectServiceV2.extractWbsDetailsFromTaskId(demand.structureId, engagementDetails);
            demand.isCrPendingInProject = projectsWithPendingCr.includes(projectInfo.projectId);
            demand.isFixedFeeProject = projectInfo.isFixedFeeProject;
            return demand;
        });
    }
}