import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";

import { catchError, map, switchMap } from "rxjs/operators";
import { from as fromPromise, of } from "rxjs";
import { IExistingCrRequest } from "../../common/services/contracts/changerequest.contract";
import { WbsIdRolesActionTypes, LoadWbsIdRoles, LoadWbsIdRolesFail, LoadWbsIdRolesSuccess } from "./wbs-id-roles.action";
import { DataService } from "../../common/services/data.service";
import { ProjectService } from "../../common/services/project.service";

@Injectable()
export class WbsIdRolesEffects {

    @Effect()
    public loadWbsIdRoles$ = this.actions$
        .ofType(WbsIdRolesActionTypes.LOAD_WBS_ID_ROLES)
        .pipe(
            switchMap((action: LoadWbsIdRoles) => fromPromise(this.projectService
                .getRoleDetailsByWbsId(action.engagementId))
                .pipe(
                    map((wbsIdRoles: IExistingCrRequest) => new LoadWbsIdRolesSuccess(action.engagementId, wbsIdRoles)),
                    catchError((error) => of(new LoadWbsIdRolesFail(action.engagementId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService
    ) { }
}
