import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { IBillingMilestoneModel } from "../../common/services/contracts/dmmilestone.service.contract";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import * as milestonesActions from "./milestones.action";

@Injectable()
export class MilestonesEffect {

    @Effect()
    public loadMilestones$ = this.actions$
        .ofType(milestonesActions.MilestonesActionTypes.LOAD_MILESTONES)
        .pipe(
            switchMap((action: milestonesActions.LoadMilestones) => fromPromise(
                this.engagementDetailService.getMilestoneData(action.engagementId)
            )
                .pipe(
                    map((milestones: IBillingMilestoneModel[]) => new milestonesActions.LoadMilestonesSuccess(action.engagementId, milestones)),
                    catchError((error) => of(new milestonesActions.LoadMilestonesFail(action.engagementId, error)))
                )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
    ) { }
}
