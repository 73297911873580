import { Action } from "@ngrx/store";
import { IPinnedEntities, IEngagementFinancialsList } from "../../../common/services/contracts/portfolio.model";

// 1. action types
export enum MyPortfolioFinancialActionTypes {
    LOAD_MY_PORTFOLIO_FINANCIALS_LIST = "[My Portfolio Financials] Load",
    LOAD_MY_PORTFOLIO_FINANCIALS_LIST_SUCCESS = "[My Portfolio Financials] Load Success",
    LOAD_MY_PORTFOLIO_FINANCIALS_LIST_FAIL = "[My Portfolio Financials] Load Fail",
    INVALIDATE_MY_PORTFOLIO_FINANCIALS_LIST = "[My Portfolio Financials] Invalidate"
}
// 2. action creators
export class LoadMyPortfolioFinancialsList implements Action {
    public readonly type = MyPortfolioFinancialActionTypes.LOAD_MY_PORTFOLIO_FINANCIALS_LIST;
}

export class LoadMyPortfolioFinancialsListSuccess implements Action {
    public readonly type = MyPortfolioFinancialActionTypes.LOAD_MY_PORTFOLIO_FINANCIALS_LIST_SUCCESS;
    public constructor(public engagementFinancialList: IEngagementFinancialsList[], public pinnedEntities: IPinnedEntities) { } // don't know if we need pinned entities here
}

export class LoadMyPortfolioFinancialsListFail implements Action {
    public readonly type = MyPortfolioFinancialActionTypes.LOAD_MY_PORTFOLIO_FINANCIALS_LIST_FAIL;
    public constructor(public errorMessage: string) { }
}

export class InvalidateMyPortfolioFinancialsList implements Action {
    public readonly type = MyPortfolioFinancialActionTypes.INVALIDATE_MY_PORTFOLIO_FINANCIALS_LIST;
}

// 3. export the union creators
export type MyPortfolioFinancialAction =
    | LoadMyPortfolioFinancialsList
    | LoadMyPortfolioFinancialsListSuccess
    | LoadMyPortfolioFinancialsListFail
    | InvalidateMyPortfolioFinancialsList;