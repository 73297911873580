import { Component, Input } from "@angular/core";

import { ITphRrSummary } from "../../../common/services/contracts/changerequest.contract";

@Component({
    selector: "dm-tph-rr-details",
    templateUrl: "./tph-rr-details.html",
    styleUrls: ["./tph-rr-details.scss"]
})
export class TphRrDetailsComponent {

    @Input() public tphRrSummary: ITphRrSummary;
    @Input() public tphRrSummaryError: string;
    public loadingMessage: string = "Loading TPH RR Details...";
}