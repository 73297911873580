import * as fromForecastRecommendations from "./engagement-forecast-recommendations.action";
import { IWeeklyForecastRecommendation } from "../../common/services/contracts/forecast-recommendation.contracts";
import { ILoadableState } from "../reducers";

export interface IMlForecastRecommendationsState extends ILoadableState {
    forecastRecommendations: IWeeklyForecastRecommendation[];
}

export const initialState: IMlForecastRecommendationsState = {
    forecastRecommendations: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromForecastRecommendations.ForecastRecommendationsActions): { [engagementId: string]: IMlForecastRecommendationsState } {
    switch (action.type) {
        case fromForecastRecommendations.ForecastRecommendationActionTypes.LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }

        case fromForecastRecommendations.ForecastRecommendationActionTypes.LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                forecastRecommendations: action.forecastRecommendations,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromForecastRecommendations.ForecastRecommendationActionTypes.LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromForecastRecommendations.ForecastRecommendationActionTypes.INVALIDATE_ENGAGEMENT_FORECAST_RECOMMENDATIONS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}