import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { Components } from "../../../../common/application.constants";

@Component({
    selector: "dm-advanced-search-filter-modal",
    templateUrl: "./advanced-search-filter-modal.html"
})
export class AdvancedSearchFilterModalComponent extends DmModalAbstract {
    @Input() public statusSelected: string;
    @Input() public filterStartDate: Date;
    @Input() public filterEndDate: Date;
    public statusList: string[];
    public isResetFilterLinkVisible: boolean;
    public isStartDatePopup: boolean = false;
    public isEndDatePopup: boolean = false;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(activeModal, dmLogger, Components.AdvancedSearchFilter);
    }

    public ngOnInit(): void {
        this.statusList = ["Released", "Created", "Closed", "Technically Completed"];
    }

    /**
     * Should the apply filters button be disabled?
     * Returns true if the button should be disabled (if the filter is missing the start or end date.)
     * Returns false if the button should be enabled.
     */
    public applyFiltersButtonDisabled(): boolean {
        if (!this.filterStartDate || !this.filterStartDate) {
            return true;
        }
        return false;
    }

    /**
     * Apply the selected filters, make a callback to the modal parent and close the filter modal.
     */
    public applyFilter(): void {
        const resolveObject = {
            statusSelected: this.statusSelected,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate
        };
        this.activeModal.close(resolveObject);
    }

}
