import { Injectable, Inject, forwardRef } from "@angular/core";
import { UserInfoService, UserClaimsService } from "@fxp/fxpservices";
import { ConfigManagerService } from "./configmanager.service";
import { IDmAuthClaimsV2 } from "./contracts/dmauthorization.service.contracts";
import { IEngagementDetails, IProjectDetails, IProjectSearchResult, IEngagementSearchResult, IAdditionalPJM, IAdditionalPPJM } from "./contracts/project.service.contracts";
import { IEngagementDetailsV2, IProjectDetailsV2, ITeamDetailsV2, IEngagementDetailsApiV2 } from "./contracts/wbs-details-v2.contracts";
import { IWbsStructure, IProject } from "./contracts/wbsStructures.contracts";
import { ResourceName, OperationName, ResourceNameV2, Services } from "../application.constants";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class DMAuthorizationService extends DmServiceAbstract {
    private currentUserBPID: number;
    private userClaimsV2: IDmAuthClaimsV2;

    public constructor(
        @Inject(forwardRef(() => UserInfoService)) private userInfoService: UserInfoService,
        @Inject(forwardRef(() => UserClaimsService)) private userClaimsService: UserClaimsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.AuthService);
        this.userClaimsV2 = this.userClaimsService.getUserTenantClaims("PS");
    }

    /**
     * Does the current user have edit permissions for the specific resource/operation/engagement/project
     * @param resourceName
     * @param operationName
     * @param engagementDetails
     * @param projectDetails
     */
    public hasEditPermission(resourceName: ResourceName, operationName: OperationName, engagementDetails: IEngagementDetails | IEngagementDetailsApiV2, projectDetails: IProjectDetails | IProjectDetailsV2): boolean {
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        let hasPermission: boolean = false;

        const resourceNameV2 = this.getV2ClaimsResourceName(resourceName, operationName);
        hasPermission = this.userClaimsV2.claims.resourcePermissions.filter((resourcePermission) => resourcePermission.resource === resourceNameV2 && (resourcePermission.permissions.filter((x) => x.toLowerCase() === operationName.toLowerCase()).length >= 1)).length >= 1;

        // type 1 check
        if (!hasPermission) {
            return hasPermission;
        }

        if (this.configurationService.getValue<string>("enableType2Check") === "false") {
            return hasPermission;
        }

        // type 2 check
        if (engagementDetails) {
            hasPermission = this.isUserInEngagementLevelTeam(engagementDetails);
        }

        if (!hasPermission && projectDetails) {
            hasPermission = this.isUserInProjectLevelTeam(projectDetails);
        }
        return hasPermission;
    }

    /**
     * Does user have permission to view the specific resource/operation/engagement/project
     * @param resourceName
     * @param operationName
     * @param engagementDetails
     * @param projectDetails
     */
    public hasPermission(resourceName: ResourceName, operationName: OperationName, engagementDetails: IEngagementDetails, projectDetails: IProjectDetails): boolean {
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        let hasPermission: boolean = false;

        const resourceNameV2 = this.getV2ClaimsResourceName(resourceName, operationName);
        hasPermission = this.userClaimsV2.claims.resourcePermissions.filter((resourcePermission) => resourcePermission.resource === resourceNameV2 && (resourcePermission.permissions.filter((x) => x.toLowerCase() === operationName.toLowerCase()).length >= 1)).length >= 1;


        // type 1 check
        if (!hasPermission) {
            return hasPermission;
        }

        if (this.configurationService.getValue<string>("enableType2Check") === "false") {
            return hasPermission;
        }

        // type 2 check
        if (engagementDetails) {
            if (engagementDetails.isConfidentialDeal) {
                hasPermission = this.isUserInEngagementLevelTeam(engagementDetails);

                if (!hasPermission) {
                    if (engagementDetails.projects.filter((x) => (this.isUserInProjectLevelTeam(x))).length >= 1) {
                        hasPermission = true;
                    }
                }

            }
        }

        if ((engagementDetails === null || hasPermission === false) && projectDetails) {
            if (engagementDetails && engagementDetails.isConfidentialDeal) {
                hasPermission = this.isUserInProjectLevelTeam(projectDetails);
                if (!hasPermission) {
                    hasPermission = this.isUserInEngagementLevelTeam(projectDetails);
                }
            }
        }
        return hasPermission;
    }

    /**
     * Is the current logged in user a PPJM or delegated PPJM (Engagement/L0)
     * @param engagement
     */
    public isLoggedinUserPpjm(engagement: IEngagementDetails | IEngagementSearchResult | IEngagementDetailsApiV2): boolean {
        if (engagement) {
            this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
            if ((engagement as IEngagementDetailsApiV2).teamStructure) {
                const pjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("PPJM", engagement as IEngagementDetailsApiV2);
                const delegatedPjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("DPPJM", engagement as IEngagementDetailsApiV2);
                if (pjmObj || delegatedPjmObj) {
                    const pjm: ITeamDetailsV2 = pjmObj ? pjmObj[0] : undefined;
                    const delegatedPjm: ITeamDetailsV2 = delegatedPjmObj ? delegatedPjmObj[0] : undefined;
                    return (
                        ((pjm && Number(pjm.bpid) === this.currentUserBPID) || false) ||
                        ((delegatedPjm && Number(delegatedPjm.bpid) === this.currentUserBPID) || false)
                    );
                }

            } else { /* This else statement will likely be deprecated as we further integreate the new team structure */
                return (
                    (Number((engagement as IEngagementDetails).pPjMbpId) === this.currentUserBPID) ||
                    (Number((engagement as IEngagementDetails).delegatedPPjMbpId) === this.currentUserBPID)
                );
            }
        }
        return false;
    }


    /**
     * Is the current logged in user a PPJM or Additional PPJM (Engagement/L0)
     * @param engagement
     */
    public isLoggedinUserPpjmOrApjm(engagement: IEngagementDetails | IEngagementDetailsApiV2): boolean {
        if (engagement) {
            this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
            if ((engagement as IEngagementDetailsApiV2).teamStructure) {
                const pjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("PPJM", engagement as IEngagementDetailsApiV2);
                const addlPjm: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("ADPPJM", engagement as IEngagementDetailsApiV2);
                const pjm: ITeamDetailsV2 = pjmObj ? pjmObj[0] : undefined;
                return (
                    ((pjm && Number(pjm.bpid) === this.currentUserBPID) || false) ||
                    ((addlPjm && addlPjm.filter((x: ITeamDetailsV2) => Number(x.bpid) === this.currentUserBPID).length > 0) || false)
                );
            } else { /* This else statement will likely be deprecated as we further integreate the new team structure */
                return (
                    (Number((engagement as IEngagementDetails).pPjMbpId) === this.currentUserBPID) ||
                    (engagement as IEngagementDetails).additionalPPJMs &&
                    (engagement as IEngagementDetails).additionalPPJMs.filter((x: IAdditionalPPJM) => Number(x.pPjMBpId) === this.currentUserBPID).length > 0
                );
            }
        }
        return false;
    }

    /**
     * Does user have permission to view the specific resource/operation/engagement/project
     * @param resourceName
     * @param operationName
     * @param engagementDetails
     * @param projectDetails
     */
    public hasPermissionV2(resourceName: ResourceName, operationName: OperationName, engagementDetailsV2: IEngagementDetailsV2, projectDetailsV2: IProjectDetailsV2): boolean {
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        let hasPermission: boolean = false;

        const resourceNameV2 = this.getV2ClaimsResourceName(resourceName, operationName);
        hasPermission = this.userClaimsV2.claims.resourcePermissions.filter((resourcePermission) => resourcePermission.resource === resourceNameV2 && (resourcePermission.permissions.filter((x) => x.toLowerCase() === operationName.toLowerCase()).length >= 1)).length >= 1;


        // type 1 check
        if (!hasPermission) {
            return hasPermission;
        }

        if (this.configurationService.getValue<string>("enableType2Check") === "false") {
            return hasPermission;
        }

        // type 2 check - check for confidential deals
        if (engagementDetailsV2 && engagementDetailsV2.isConfidential) {
            hasPermission = this.isUserInEngagementLevelTeamV2(engagementDetailsV2);

            if (!hasPermission) {
                if (engagementDetailsV2.projects.filter((x) => (this.isUserInProjectLevelTeamV2(x))).length >= 1) {
                    hasPermission = true;
                }
            }
        }

        if ((engagementDetailsV2 === null || hasPermission === false) && projectDetailsV2) {
            if (engagementDetailsV2 && engagementDetailsV2.isConfidential) {
                hasPermission = this.isUserInProjectLevelTeamV2(projectDetailsV2);
                if (!hasPermission) {
                    hasPermission = this.isUserInEngagementLevelTeamV2(projectDetailsV2);
                }
            }
        }
        return hasPermission;
    }

    /**
     *  Checks whether user has edit permission.
     *
     * @param {ResourceName} resourceName
     * @param {OperationName} operationName
     * @param {IEngagementDetailsV2} engagementDetailsV2
     * @param {IProjectDetailsV2} projectDetailsV2
     * @returns {boolean}
     * @memberof DMAuthorizationService
     */
    public hasEditPermissionV2(resourceName: ResourceName, operationName: OperationName, engagementDetailsV2: IEngagementDetailsV2, projectDetailsV2: IProjectDetailsV2): boolean {
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        let hasPermission: boolean = false;

        const resourceNameV2 = this.getV2ClaimsResourceName(resourceName, operationName);
        hasPermission = this.userClaimsV2.claims.resourcePermissions.filter((resourcePermission) => resourcePermission.resource === resourceNameV2 && (resourcePermission.permissions.filter((x) => x.toLowerCase() === operationName.toLowerCase()).length >= 1)).length >= 1;

        // type 1 check
        if (!hasPermission) {
            return hasPermission;
        }

        if (this.configurationService.getValue<string>("enableType2Check") === "false") {
            return hasPermission;
        }

        // type 2 check
        if (engagementDetailsV2) {
            hasPermission = this.isUserInEngagementLevelTeamV2(engagementDetailsV2);
        } else if (projectDetailsV2) {
            hasPermission = this.isUserInProjectLevelTeamV2(projectDetailsV2);
        }
        return hasPermission;
    }


    /**
     * Is the current logged in user in the Engagement team (PPJM, APPJM, or Delegated PPJM)
     * @param entity
     */
    public isUserInEngagementLevelTeam(entity: IEngagementDetails | IEngagementSearchResult | IProjectDetails | IEngagementDetailsApiV2 | IWbsStructure): boolean {
        if (!entity) {
            return false;
        }
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        let searchResult: IEngagementSearchResult;
        let engOrProj: any; // can't apply a type since IEngagementDetails and IProjectDetails conflict when explicitly set.
        if ((entity as IEngagementDetailsApiV2).teamStructure) {
            const pjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("PPJM", entity as IEngagementDetailsApiV2);
            const delegatedPjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("DPPJM", entity as IEngagementDetailsApiV2);
            const addlPjm: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL0("ADPPJM", entity as IEngagementDetailsApiV2);
            if (pjmObj || delegatedPjmObj || addlPjm) {
                const pjm: ITeamDetailsV2 = pjmObj ? pjmObj[0] : undefined;
                const delegatedPjm: ITeamDetailsV2 = delegatedPjmObj ? delegatedPjmObj[0] : undefined;
                return (
                    (pjm && Number(pjm.bpid) === this.currentUserBPID) ||
                    (delegatedPjm && Number(delegatedPjm.bpid) === this.currentUserBPID) ||
                    (addlPjm && addlPjm.filter((x: ITeamDetailsV2) => Number(x.bpid) === this.currentUserBPID).length > 0)
                );
            }

        } else if ((entity as IEngagementSearchResult).opportunityID) {
            searchResult = entity as IEngagementSearchResult;
        } else {
            engOrProj = entity;
        }

        const isuserInEngagementLevelTeam = (engOrProj.pPjMbpId && Number(engOrProj.pPjMbpId) === this.currentUserBPID)
            || (engOrProj.delegatedPPjMbpId && Number(engOrProj.delegatedPPjMbpId) === this.currentUserBPID)
            || (engOrProj && engOrProj.additionalPPJMs && engOrProj.additionalPPJMs.filter((x: IAdditionalPPJM) => Number(x.pPjMBpId) === this.currentUserBPID).length >= 1
                || (searchResult && searchResult.additionalPpjMs && searchResult.additionalPpjMs.filter((x: IAdditionalPPJM) => Number(x.pPjMBpId) === this.currentUserBPID).length >= 1)
            );
        return isuserInEngagementLevelTeam ? isuserInEngagementLevelTeam : false;
    }

    /**
     * Is the current logged in user in the Project team (PJM, APJM, or Delegated PJM)
     * @param project
     */
    public isUserInProjectLevelTeam(project: IProjectDetails | IProjectSearchResult | IProjectDetailsV2 | IProject): boolean {
        if (!project) {
            return false;
        }
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        let searchResult: IProjectSearchResult;
        let proj: IProjectDetails;

        if ((project as IProjectDetailsV2).teamStructure) {
            const pjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("PJM", project as IProjectDetailsV2);
            const delegatedPjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("DPJM", project as IProjectDetailsV2);
            const addlPjm: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("ADPJM", project as IProjectDetailsV2);
            if (pjmObj || delegatedPjmObj || addlPjm) {
                const pjm: ITeamDetailsV2 = pjmObj ? pjmObj[0] : undefined;
                const delegatedPjm: ITeamDetailsV2 = delegatedPjmObj ? delegatedPjmObj[0] : undefined;
                return (
                    ((pjm && Number(pjm.bpid) === this.currentUserBPID) || false) ||
                    ((delegatedPjm && Number(delegatedPjm.bpid) === this.currentUserBPID) || false) ||
                    ((addlPjm && addlPjm.filter((x: ITeamDetailsV2) => Number(x.bpid) === this.currentUserBPID).length > 0) || false)
                );
            }
            return false;
        }

        if ((project as IProjectSearchResult).additionalPjMs) {
            searchResult = project as IProjectSearchResult;
        } else {
            proj = project as IProjectDetails;
        }

        const isUserInProjectLevelTeam = (proj &&
            ((proj.pjMbpId && Number(proj.pjMbpId) === this.currentUserBPID)
                || (proj.delegatedPjMbpId && Number(proj.delegatedPjMbpId) === this.currentUserBPID)
                || (proj && proj.additionalPJMs && proj.additionalPJMs.filter((a: IAdditionalPJM) => Number(a.pjMbpId) === this.currentUserBPID).length > 0)
            ))
            || (searchResult && searchResult.additionalPjMs && searchResult.additionalPjMs.filter((a: IAdditionalPJM) => Number(a.pjMbpId) === this.currentUserBPID).length > 0);
        return isUserInProjectLevelTeam;
    }

    /**
     * Checks whether user is in tema in project level
     *
     * @param {IProjectDetailsV2} project
     * @returns {boolean}
     * @memberof DMAuthorizationService
     */
    public isUserInProjectLevelTeamV2(project: IProjectDetailsV2): boolean {
        if (!project) {
            return false;
        }
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        const userFoundInTeams = project.teamStructure.filter((m: ITeamDetailsV2) => Number(m.bpid) === this.currentUserBPID);
        if (userFoundInTeams && userFoundInTeams.length) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Checks whether user is in teams in engagement level 
     *
     * @param {(IEngagementDetailsV2 | IProjectDetailsV2)} engOrProj
     * @returns {boolean}
     * @memberof DMAuthorizationService
     */
    public isUserInEngagementLevelTeamV2(engOrProj: IEngagementDetailsV2 | IProjectDetailsV2): boolean {
        if (!engOrProj) {
            return false;
        }
        this.currentUserBPID = Number(this.userInfoService.getCurrentUserData().BusinessPartnerId);
        const userFoundInTeams = engOrProj.teamStructure.filter((m: ITeamDetailsV2) => Number(m.bpid) === this.currentUserBPID);
        if (userFoundInTeams && userFoundInTeams.length) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Checks whether user is in Engagement Team
     *
     * @param {(IEngagementDetailsV2)} engagementDetails
     * @returns {boolean}
     * @memberof DMAuthorizationService
     */
    public isUserPartOfTeamStructure(engagementDetails: IEngagementDetailsApiV2): boolean {

        if (!engagementDetails) {
            return false;
        }

        const userInEngagementLevelTeam = this.isUserInEngagementLevelTeamV2(engagementDetails);

        if (userInEngagementLevelTeam) {
            return true;
        }

        for (const project of engagementDetails.projects) {
            if (this.isUserInProjectLevelTeamV2(project)) {
                return true;
            }
        }

        return false;

    }

    /**
     * Gets the V2 Claims Resource Name based on the inputs
     * @param resourceName
     * @param OperationName
     */
    private getV2ClaimsResourceName(resourceName: ResourceName, operationName: OperationName): string {
        // Since new claims does not contain EBS and View we are converting to Engagement
        if (resourceName === ResourceName.SAP_EBS && operationName === OperationName.view) {
            return ResourceNameV2.Engagement;
        }
        let resourceNameV2: string;
        switch (resourceName) {
            case ResourceName.SAP_Engagement:
                resourceNameV2 = ResourceNameV2.Engagement;
                break;
            case ResourceName.SAP_Project:
                resourceNameV2 = ResourceNameV2.Project;
                break;
            case ResourceName.SAP_Financials:
                resourceNameV2 = ResourceNameV2.ProjectFinancials;
                break;
            case ResourceName.SAP_EBS:
                resourceNameV2 = ResourceNameV2.EngagementBreakdownStructure;
                break;
            case ResourceName.SAP_PurchaseOrder:
                resourceNameV2 = ResourceNameV2.PurchaseOrder;
                break;
            case ResourceName.Chronos_Labor:
                resourceNameV2 = ResourceNameV2.Labor;
                break;
            /// Since New Claims does not contain Invoice, Staffing we are defaulting to Engagement
            case ResourceName.SAP_Invoice:
                resourceNameV2 = ResourceNameV2.Engagement;
                break;
            case ResourceName.SAP_Staffing:
                resourceNameV2 = ResourceNameV2.Engagement;
                break;
            default:
                resourceNameV2 = ResourceNameV2.Engagement;
                break;
        }
        return resourceNameV2;
    }
}
