import { ICustomer } from "./customer.contract";
import { ITeamDetailsV2 } from "./wbs-details-v2.contracts";
import { IOneProfileCompanyCodeAttrV2 } from "../../../components/tiles/type-ahead-companycode/type-ahead-companycode-contracts";
import { ICostCenterValidationOutput } from "../../../components/new-internal-engagement/new-internal-engagement.contracts";

export interface IBulkUploadTemplateUrlLinksResponse {
    name: string;
    url: string;
}

export interface IBulkIntEngStatusResponse {
    displayId?: number;
    endDate: string;
    id: string;
    updatedOn: string;
    startDate: string;
    engagementId: string;
    name: string;
    engagementType: string;
    requestType: string;
    status: BulkIntEngStatus;
}
export interface IBulkUploads {
    createdBy: string;
    createdTime: string;
    id: string;
    isCreationInitiated: boolean;
    notificationGroupName: string;
    status: string;
}
export interface IBulkIntEngSummaryEngagementStatus {
    createdBy: string;
    endDate: string;
    engagementId: string;
    errorDetails: any;
    id: string;
    name: string;
    requestType: string;
    startDate: string;
    status: string;
    updatedOn: string;
    link?: string;
}

export interface IBulkUploadSummaryStatusResponse {
    engagements: IBulkIntEngSummaryEngagementStatus[];
    referenceId: string;
    notificationGroupName?: string;
}

export enum BulkIntEngStatus {
    QueuedForValidation = "QueuedForValidation",
    CreationInProgress = "CreationInProgress",
    ValidationInProgress = "ValidationInProgress",
    ValidationSuccessful = "ValidationSuccessful",
    ValidationFailed = "ValidationFailed",
    QueuedForCreation = "QueuedForCreation",
    CreationSuccessful = "CreationSuccessful",
    CreationFailed = "CreationFailed",
    QueuedForEdit = "QueuedForEdit",
    EditSuccessful = "EditSuccessful",
    EditFailed = "EditFailed",
    Discarded = "Discarded"
}

export interface IBulkUploadIntEngUploadResponse {
    engagements: IBulkIntEngStatusResponse[];
    referenceId: string;
    notificationGroupName?: string;
}
export interface IBulkUploadIntEngCreationObject {
    engagementIds: string[];
    status: BulkIntEngStatus;
}

export interface IBulkIntEngDetailsResponse {
    engagement: IBulkIntEngagementDetails;
    id: string;
    referenceId: string;
    status: BulkIntEngStatus;
    createdBy: string;
    updatedOn: string;
    errorDetails: any;
}

export interface IBulkIntEngagementDetails {
    temporaryId: string;
    description: string;
    opportunityId: string;
    primaryDomain: {
        name: string;
        id: number;
    };
    customer: ICustomer;
    customerCountry: {
        countryCode: string;
        countryName: string;
    };
    inputState: string;
    customerState: string;
    inputCity: string;
    customerCity: string;
    teamStructure: ITeamDetailsV2[];
    company: IOneProfileCompanyCodeAttrV2;
    isConfidential: boolean;
    projects: IBulkIntProjectDetails[];
    engagementId: string;
    requestType: string;
    requestTypeCode: string;
    name: string;
    startDate: Date | string;
    endDate: Date | string;
}

export interface IBulkIntProjectDetails {
    temporaryId?: string;
    name: string;
    description: string;
    primaryDomain: {
        name: string;
        id: number;
    };
    startDate: Date | string;
    endDate: Date | string;
    costObjectId: number;
    costObjectType: number;
    costCenter: ICostCenterValidationOutput;
    chargeType: number;
    chargeTypeValue: string;
    chargeAccountCode: string;
    chargeAccountName: string;
    teamStructure: ITeamDetailsV2[];
    nbueApprovedHours: number;
    roleRequests: IRoleRequestDetailsV2[];
    errorDetails?: any;
}

export interface IRoleRequestDetailsV2 {
    temporaryId: string;
    role: IRoleDetailsV2;
    startDate: Date | string;
    endDate: Date | string;
    requestedHours: number;
    skills: IDict[];
    primaryDomain: IDict;
    travelNotes: string;
    deliveryType: string;
    shouldFreeResourceCalendar: boolean;
    isScheduleFree?: boolean;
    requestedResource: {
        alias: string;
        bpId: string;
        name: string;
    };
    isResourceBillable?: boolean;
    deliveryCountryCode?: string;
    deliveryState?: string;
    deliveryCity?: string;
}

export interface IRoleDetailsV2 {
    name: string;
    partNumber: string;
    partNumberStatus?: {
        id: number;
        value: string;
    };
    resourceType?: {
        id: number;
        value: string;
    };
    activityType?: {
        isCostRateRegistrationAllowed: boolean;
        id: number;
        value: string;
        code: string;
        status: boolean;
        attributes: [
            {
                typeId: {};
            }
        ];
    };
    requiredClearance?: {
        id: number;
        value: string;
        code: string;
    };
}

export interface IDict {
    id: string;
    name: string;
    isDomainAligned?: boolean;
    domainIds?: number[];
}

export enum BulkIntEngStatusForDisplay {
    QueuedForValidation = "Queued For Validation",
    CreationInProgress = "Creation In Progress",
    ValidationInProgress = "Validation In Progress",
    ValidationSuccessful = "Validation Successful",
    ValidationFailed = "Validation Failed",
    QueuedForCreation = "Queued For Creation",
    CreationSuccessful = "Creation Successful",
    CreationFailed = "Creation Failed",
    QueuedForEdit = "Queued For Edit",
    EditSuccessful = "Edit Successful",
    EditFailed = "Edit Failed",
    Discarded = "Discarded"
}