import { Component, forwardRef, Inject, Input } from "@angular/core";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, AccessibilityConstants } from "../../../../../common/application.constants";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { DmError } from "../../../../../common/error.constants";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { ICustomLink, IWbsSettings } from "../../../../../common/services/contracts/project.service.v2.contracts";
import { IUserPreferenceLinksViewModel } from "../../../../../common/services/contracts/userpreferencelinksmgmt.contract";
import { ProjectServiceV2 } from "../../../../../common/services/project.v2.service";
import { IModal } from "../../../../../components/modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";

@Component({
    templateUrl: "./edit-key-links.html",
    styleUrls: ["./edit-key-links.scss"]
})
export class EditKeyLinksModalComponent extends DmModalAbstract {
    @Input() public userPreferenceLinks: ICustomLink[];
    @Input() public wbsId: string;

    /* Regex for url validation checks for http or https and a dot symbol */
    public urlValidationPattern = "https?://.+";
    public isAddLinksDisabled: boolean = false;
    public isUrlFieldInFocus: boolean[] = [];
    public isUserlinksLoading: boolean = false;
    public showValidationMessage: boolean = false;
    public validationMessageText: string;
    public statusMessage: string;
    public usersLinkName: string[] = [];
    public accessibilityConstants = AccessibilityConstants;
    public userPreferenceLinksErrorMessages = DmError.UserPreferenceLinks;
    public modalContent: IModal;
    public userpreferenceLinksEditForm: FormGroup;
    public deletedLinks: string[] = [];

    public get linksFormArray(): FormArray {
        return this.userpreferenceLinksEditForm.get("linksFormArray") as FormArray;
    }

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FormBuilder) private fb: FormBuilder
    ) {
        super(activeModal, dmLogger, Components.AddKeyLinksModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Edit Key Links"
        };
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.userpreferenceLinksEditForm = new FormGroup({
            linksFormArray: this.fb.array([])
        });

        this.initializeKeyLinksForm();
    }

    /**
     * Close Modal Popup
     */
    public closeModalPopUp(reload: boolean = false): void {
        this.userPreferenceLinks = [];
        this.activeModal.close(reload);
    }

    /**
     * trigreed when an url or a link is changed
     */
    public onLinkChange(index: number, type: string): void {
        const linkId = this.linksFormArray.value[index].id;
        const originalLink = this.userPreferenceLinks.filter((obj) => (obj.id === linkId));
        if (originalLink && originalLink.length) {
            if (type === "title" && this.linksFormArray.value[index].name !== "" && !this.userpreferenceLinksEditForm.get("linksFormArray")["controls"][index].controls.name.errors && originalLink[0].name !== this.linksFormArray.value[index].name) {
                originalLink[0].name = this.linksFormArray.value[index].name;
            }
            if (type === "url" && this.linksFormArray.value[index].url !== "" && !this.userpreferenceLinksEditForm.get("linksFormArray")["controls"][index].controls.url.errors && originalLink[0].url !== this.linksFormArray.value[index].url) {
                originalLink[0].url = this.linksFormArray.value[index].url;
            }
        }
    }

    /**
     * Save Links
     */
    public saveUserPreferenceLinks(): void {
        /// Pending
        /// Check for Validations if any        
        this.showValidationMessage = false;
        if (this.userPreferenceLinks.length > 0) {
            this.userPreferenceLinks.forEach((link) => {
                if (!link.url || !link.name) {
                    if (this.userPreferenceLinks.length === 1) {
                        this.validationMessageText = DmError.UserPreferenceLinks.TheEntryHasAnInvalidURLOrName;
                    } else {
                        this.validationMessageText = DmError.UserPreferenceLinks.OneOfTheEntriesHasAnInvalidURLOrName;
                    }
                    this.showValidationMessage = true;
                    return;
                }
            });
            this.sharedFunctionsService.focus("editKeyLinksButton001", true);
        } else {
            this.sharedFunctionsService.focus("addKeyLinksButton001", true);
        }
        if (!this.showValidationMessage) {
            this.isUserlinksLoading = true;
            this.projectServiceV2.getWbsSettings(this.wbsId).then((response: IWbsSettings) => {
                const wbsSettings = response;
                wbsSettings.customLinks = this.userPreferenceLinks;
                this.projectServiceV2.saveWbsSettings(wbsSettings).then(() => {
                    this.fxpMessageService.addMessage("Successfully saved links", "success", false);
                    this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.SaveUserPreferenceLinks, LogEventConstants.CustomLinkSubmit);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(true);
                }).catch((error) => {
                    this.fxpMessageService.addMessage(DmError.UserPreferenceLinks.UnableToSaveLinks, "error", true);
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, DmError.UserPreferenceLinks.UnableToSaveLinks, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(false);
                });
            }).catch((error) => {
                if (error.status === 404) {
                    const wbsSettings = this.projectServiceV2.getDefaultWbsSettings(this.wbsId);
                    wbsSettings.customLinks = this.userPreferenceLinks;
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, "Successfully saved link but 404 status", ErrorSeverityLevel && ErrorSeverityLevel.Medium);
                    this.projectServiceV2.saveWbsSettings(wbsSettings).then(() => {
                        this.fxpMessageService.addMessage("Successfully saved links", "success", false);
                        this.isUserlinksLoading = false;
                        this.closeModalPopUp(true);
                    });

                } else {
                    this.fxpMessageService.addMessage(DmError.UserPreferenceLinks.UnableToSaveLinks, "error", true);
                    this.logError(SourceConstants.Method.SaveUserPreferenceLinks, error, DmError.UserPreferenceLinks.UnableToSaveLinks, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUserlinksLoading = false;
                    this.closeModalPopUp(false);
                }
            });
        }
    }

    /**
     * Delete Links -Soft Delete will mark the item in deleted state later for submission
     */
    public deleteUserPreferenceLinks(index: number): void {
        const linkId = this.linksFormArray.value[index].id;
        this.deletedLinks.push(this.linksFormArray.value[index]);
        this.linksFormArray.removeAt(index);
        this.userPreferenceLinks = this.userPreferenceLinks.filter((obj) => !(obj.id === linkId));
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Set alert message readable for screen readers on deletion of link
     * @param action 
     * @param link 
     */
    public setStatusMessage(action: string, link: IUserPreferenceLinksViewModel): void {
        if (action === "Delete") {
            this.statusMessage = "Link " + (link.name ? ("with title" + link.name) : "without title") + " has been deleted successfully";
        }
        this.sharedFunctionsService.delayExecution(4000).then(() => {
            this.statusMessage = "";
        });
    }

    /**
     * Initializes form fields with validations etc.
     */
    private initializeKeyLinksForm(): void {
        for (const link of this.userPreferenceLinks) {
            this.linksFormArray.push(
                this.fb.group({
                    id: new FormControl(link.id),
                    name: new FormControl(link.name, Validators.required),
                    url: new FormControl(link.url, [Validators.required, Validators.pattern(this.urlValidationPattern)])
                })
            );
        }
    }
}
