
import { switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";

import { AmendmentType, IChangeRequest } from "../../common/services/contracts/changerequest.contract";
import * as changeRequestActions from "./engagement-change-requests.action";
import { DataService } from "../../common/services/data.service";
import { ChangeRequestService } from "../../common/services/change-request.service";
import { IVirtuosoApprovalStatus, IApprovalLevel } from "../../common/services/contracts/virtuoso.contracts";
import { VirtuosoService } from "../../common/services/virtuoso.service";
import { IProgressBar, ProgressBarStatus } from "../../components/tiles/dm-progress-bar/dm-progress-bar.contracts";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { FvrApproversStatus } from "../../common/services/contracts/changerequestv2.contract";


@Injectable()
export class EngagementChangeRequestsEffect {

    @Effect()
    public loadChangeRequestByEngagementId$ = this.actions$
        .ofType(changeRequestActions.ChangeRequestActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTS).pipe(
            switchMap((action: changeRequestActions.LoadEngagementChangeRequests) =>
                fromPromise(this.changeRequestService.getAmendmentsByWbsIdV2(action.engagementId, AmendmentType.NonContractual))
                    .pipe(
                        mergeMap(
                            (changeRequests: IChangeRequest[]) => this.getApprovalStatusForChangeRequests(changeRequests)
                                .catch((errorObject) => {
                                    if (errorObject && errorObject.status === 404) {
                                        Promise.resolve([]);
                                    } else {
                                        Promise.reject(errorObject);
                                    }
                                }),
                            (changeRequestData: IChangeRequest[], approvalStatusData: IVirtuosoApprovalStatus[]) => {
                                return (approvalStatusData && approvalStatusData.length) ? this.addStatusToChangeRequest(changeRequestData, approvalStatusData) : changeRequestData;
                            }
                        ),
                        map((changeRequests: IChangeRequest[]) => new changeRequestActions.LoadEngagementChangeRequestsSuccess(action.engagementId, changeRequests)),
                        catchError((error) => of(new changeRequestActions.LoadEngagementChangeRequestsFail(action.engagementId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                    )
            ));
    public constructor(
        private actions$: Actions,
        private changeRequestService: ChangeRequestService,
        private virtuosoService: VirtuosoService,
        private sharedFunctionsService: SharedFunctionsService
    ) { }

    /**
     * Retrieves Virtuoso approval status for the given change request IDs. Only get Virtuoso 
     * approval status if the change request is Pending in SAP.
     *
     * @private
     * @param {IChangeRequest[]} changeRequests
     * @returns {Promise<IVirtuosoApprovalStatus[]>}
     * @memberof EngagementChangeRequestsEffect
     */
    private getApprovalStatusForChangeRequests(changeRequests: IChangeRequest[]): Promise<IVirtuosoApprovalStatus[]> {
        const changeRequestIds: string[] = changeRequests.filter((fvr: IChangeRequest) => (fvr.statusDescription === FvrApproversStatus.Pending.valueOf()) || (fvr.statusDescription === FvrApproversStatus.PendingApproval.valueOf())).map((cr: IChangeRequest) => cr.crNumber);
        if (changeRequestIds && changeRequestIds.length) {
            return this.virtuosoService.getBulkChangeRequestApprovalStatus(changeRequestIds);
        } else {
            return Promise.resolve([]);
        }
    }

    private addStatusToChangeRequest(changeRequestData: IChangeRequest[], changeRequestStatuses: IVirtuosoApprovalStatus[]): IChangeRequest[] {
        if (changeRequestData && changeRequestData.length && changeRequestStatuses && changeRequestStatuses.length) {
            const changeRequestsWithStatus: IChangeRequest[] = changeRequestData.map((changeRequest: IChangeRequest) => {
                const crApprovalProgress: IVirtuosoApprovalStatus = changeRequestStatuses.filter((approvalStatus: IVirtuosoApprovalStatus) => approvalStatus.RequestId === changeRequest.crNumber)[0];

                let crProgressData: IProgressBar[];
                if (crApprovalProgress) {
                    crProgressData = crApprovalProgress.ApprovalLevels.map((level: IApprovalLevel) => {
                        const progressBarStatus: ProgressBarStatus = this.virtuosoService.getProgressStatusForVirtuosoStatus(level.ApprovalStatus);

                        return {
                            content: `${level.UserName ? level.UserName : level.UserAlias} (${this.virtuosoService.mapPersonaToVirtuosoRole(level.RoleName)}) ${level.ApprovalDate ? `on ${this.sharedFunctionsService.transformDate(level.ApprovalDate, "dd-MMM-yyyy")}` : ""}`,
                            status: level.RoleName === this.virtuosoService.determineCurrentLevel(crApprovalProgress.Status) ? ProgressBarStatus.InProgress : progressBarStatus
                        };
                    });
                }

                return {
                    ...changeRequest,
                    approvalProgress: crProgressData,
                    virtuosoStatus: crApprovalProgress ? crApprovalProgress.Status : undefined
                };
            });

            return changeRequestsWithStatus;
        } else {
            return changeRequestData;
        }
    }
}