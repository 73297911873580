import { Component, forwardRef, Inject, Input } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { IDeliveryState, IDeliveryStateValue, IDistributionListObject, IResponseMessage, IWbsState } from "../../../../common/services/contracts/wbs.service.contracts";

import { SourceConstants, LogEventConstants, NotificationEvent, WBSResponseMessages, RequestedState, Components, EntityType, BusinessTask, ProjectClosureSubmitFormData, ProjectClosureModelEventTypes, BroadcastEvent } from "../../../../common/application.constants";
import { ContractType, IEngagementDetailsV2, IProjectDetailsV2, IServiceDetailsV2, ITaskDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { WBSService } from "../../../../common/services/wbs.service";
import { DMNotificationService, NotificationModel } from "../../../../common/services/dmnotification.service";
import { ErrorSeverityLevel, FxpConstants, FxpEventBroadCastService, FxpMessageService, SharedComponentCallBackEventArgs, UserInfoService } from "@fxp/fxpservices";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { CosmicServiceFunctions } from "../../../../common/services/cosmic-function.service";
import { INotificationMessages } from "../../../../common/services/contracts/financial.service.contracts";
import { OneProfileService } from "../../../../common/services/one-profile.service";
import { DmError } from "../../../../common/error.constants";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { ICosmicFunctionResponse } from "../../../../common/services/contracts/cosmic-function.contracts";
import { IWbsIncident, IWbsSettings } from "../../../../common/services/contracts/project.service.v2.contracts";
import { ProjectServiceV2 } from "../../../../common/services/project.v2.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IValidationResultEmmiter } from "./engagement-teco-prevalidation/engagement-teco-prevalidation.contract";
import moment from "moment";
import momentTz from "moment-timezone";
import { IAssociatedIncidentViewModel, ICosmicCancellationRequest } from "./request-status-change.contract";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { IState } from "../../../../store/reducers";
import { NotificationType } from "../../../../common/services/contracts/notification-bar.contracts";
import { v4 as uuid } from "uuid";
import { DmNotificationService } from "../../../../common/services/dm-notification.service";
import { ProjectService } from "../../../../common/services/project.service";
import { IProjectClosureRequest, RuleResultType } from "../../../../common/services/contracts/project.closure.contract";
import { IDecoNotificationContract } from "../../../../common/services/contracts/deco-notification.contract";
import { IRecoNotificationContract } from "../../../../common/services/contracts/reco-notification.contract";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";

const RELEASED_STATUS_CODE = "REL";
const TECO_STATUS_CODE = "RECO";
const RECO_STATUS_CODE = "RECO";
const LOCKED_STATUS_CODE = "LCD";
const UNLOCKED_STATUS_CODE = "ULCD";

@Component({
    templateUrl: "./request-status-change.html",
    styleUrls: ["./request-status-change.scss"]
})
export class EbsV2RequestStatusChangeModalComponent extends DmModalAbstract {
    @Input() public selectedEngagement: IEngagementDetailsV2;
    @Input() public selectedProject: IProjectDetailsV2;
    @Input() public selectedService: IServiceDetailsV2;
    @Input() public selectedTask: ITaskDetailsV2;
    @Input() public engagementDetailsV2: IEngagementDetailsV2;
    public selectedEntity: IEngagementDetailsV2 | IProjectDetailsV2 | IServiceDetailsV2 | ITaskDetailsV2;
    public loadingText: string;
    public disableStateListOptions: string[];
    public selectedRequestStatus: string = "";
    public selectedRequestStatusCode: string = "";
    public isStateChangeErrorMessage: boolean;
    public isSubmitBtnDisabled: boolean = true;
    public modalContent: IModal;
    public statusDescription: string;
    public isLoadingOrUpdatingRequest: boolean = false;
    public entityType: string;
    public userRequestedEbsState: string;
    public requestStateChangeText: string;
    public LogEventConstants = LogEventConstants;
    public tecoValidationResult: IValidationResultEmmiter;
    public isInternalEngagement: boolean;
    public associatedActiveWbsIncidentsViewModel: IAssociatedIncidentViewModel;
    public isCosmicCancellationWorkflow: boolean;
    public tecoCancellationComment: string;
    public isProjectClosureOrchestrationAlreadyTriggered: boolean;
    public userHasAccessForDecoReco: boolean;
    public loadDecoComponent: boolean;
    public loadRecoComponent = false;
    public isCheckingPJCInProgressRequests: boolean = false;
    public isPJCOrchestrationCallSuccessful: boolean;
    public isPJCOrchestrationCallFailed: boolean;
    public isLoadingETMDetails: boolean;
    public isOrchestrationTriggered: boolean = false;
    public isStateReversal: boolean;
    public reversal_shared_input: string;
    public isPJCStatusChangeAllowed: boolean;
    public decoNotificationContract: IDecoNotificationContract;
    public recoNotificationContract: IRecoNotificationContract;
    public isProjectMarkedForDeletion: boolean = false;
    public deliveryStateListOptions: IDeliveryStateValue[];
    public isProjectClsoureFeatureEnabled: boolean = false;


    private serviceResponseMessages: IResponseMessage;
    private readonly FXP_CONSTANTS = FxpConstants;
    private allDeliveryStates: IWbsState[];
    private statusCode: string;
    private allDeliveryStatesBeforePJC: IDeliveryState[];

    private wbsSettings: IWbsSettings;
    private notificationMessage: INotificationMessages;

    public constructor(
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DmNotificationService) private dmNotificationService: DmNotificationService,
        @Inject(CosmicServiceFunctions) private cosmicService: CosmicServiceFunctions,
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(FxpEventBroadCastService) private fxpBroadcastService: FxpEventBroadCastService) {
        super(activeModal, dmLogger, Components.ManageEbsRequestStateChange);
    }

    public ngOnInit(): void {
        this.isCosmicCancellationWorkflow = false;
        this.loadDecoComponent = false;
        this.userHasAccessForDecoReco = true;
        this.isProjectClosureOrchestrationAlreadyTriggered = false;
        this.isPJCStatusChangeAllowed = true;
        this.modalContent = {
            title: "Request for EBS State Change"
        };
        this.isLoadingOrUpdatingRequest = true;
        this.loadingText = "Loading Request Change Form";
        this.requestStateChangeText = "Requesting an EBS State change will notify the Project Controller (PJC) to review and change the EBS State. The requester will be notified when this activity is completed.";
        this.serviceResponseMessages = WBSResponseMessages.Engagement;
        this.isInternalEngagement = false;
        if (this.selectedEngagement) {
            this.entityType = EntityType.Engagement;
            this.statusCode = this.selectedEngagement.currentStatusCode;
            this.selectedEntity = this.selectedEngagement;
            this.isInternalEngagement = this.selectedEngagement.isInternalEngagment;
            this.engagementDetailsV2 = this.selectedEngagement;
            this.modalContent.title = "Request for Engagement Status Change";
            this.requestStateChangeText = "Requesting an EBS State change will notify the Assistant Services Controller (ASC) to review and change the EBS State. The requester will be notified when this activity is completed.";
        } else if (this.selectedProject) {
            this.entityType = EntityType.Project;
            this.statusCode = this.selectedProject.currentStatusCode;
            this.selectedEntity = this.selectedProject;
            this.modalContent.title = "Request for Project Status Change";
        } else if (this.selectedService) {
            this.entityType = EntityType.Service;
            this.statusCode = this.selectedService.statusCode;
            this.selectedEntity = this.selectedService;
            this.modalContent.title = "Request for Service Status Change";
        } else if (this.selectedTask) {
            this.entityType = EntityType.Task;
            this.statusCode = this.selectedTask.statusCode;
            this.selectedEntity = this.selectedTask;
            this.modalContent.title = "Request for Task Status Change";
        }
        this.statusDescription = this.selectedEntity.currentStatus;

        this.isProjectClsoureFeatureEnabled = this.configurationService.isFeatureEnabled("enableProjectClosureFeature");

        const configString: string = this.isInternalEngagement ? "deliveryStateListInternal" : this.isProjectClsoureFeatureEnabled ? "EngagementStates" : "deliveryStateListCustomerFacing";
        this.notificationMessage = this.configurationService.getValue<any>("Notification");


        if (this.isProjectClsoureFeatureEnabled) {
            this.allDeliveryStates = this.configurationService.getValue<IWbsState[]>(configString);
            if (this.allDeliveryStates && !this.isInternalEngagement) {
                const states = this.allDeliveryStates.filter((item: IWbsState) => item && item.value && item.value === this.statusCode)[0];
                let disabledOptions: string[] = states ? states.disableStatus : undefined;

                if ((!this.selectedEngagement) && this.statusCode === "DECO") {
                    disabledOptions = disabledOptions.concat("RECO");
                }
                if ((!this.selectedEngagement) && this.statusCode === "RECO") {
                    disabledOptions = disabledOptions.concat("DECO");
                }
                this.disableStateListOptions = disabledOptions;

            }

        }
        else {
            this.allDeliveryStatesBeforePJC = this.configurationService.getValue<IDeliveryState[]>(configString);
            if (this.allDeliveryStatesBeforePJC) {
                const filteredOptions: IDeliveryState = this.allDeliveryStatesBeforePJC.filter((item: IDeliveryState) => item && item.state && item.state === this.statusCode)[0];
                this.deliveryStateListOptions = filteredOptions ? filteredOptions.values : undefined;
            }
        }



        if (!this.isInternalEngagement) {
            this.projectServiceV2.getWbsSettings(this.selectedEntity.id).then((wbsSettings: IWbsSettings) => {
                this.wbsSettings = wbsSettings;
                const requestedState = this.wbsSettings ? this.wbsSettings.requestedState.toLowerCase() : "";
                if (requestedState && (requestedState === RequestedState.RequestedForRelease.toLowerCase() || requestedState === RequestedState.RequestedForTechnicallyCompleted.toLowerCase() || requestedState === RequestedState.RequestForLocked.toLowerCase() || requestedState === RequestedState.RequestForUnlocked.toLowerCase() || requestedState === RequestedState.ReadyToClose.toLowerCase())
                    && ((requestedState === RequestedState.RequestedForRelease.toLowerCase() && this.selectedEntity.statusCode !== RELEASED_STATUS_CODE)
                        || (requestedState === RequestedState.RequestedForTechnicallyCompleted.toLowerCase() && this.selectedEntity.statusCode !== TECO_STATUS_CODE)
                        || (requestedState === RequestedState.ReadyToClose.toLowerCase() && this.selectedEntity.currentStatusCode === RECO_STATUS_CODE))
                    || (requestedState === RequestedState.RequestForLocked.toLowerCase() && this.selectedEntity.statusCode !== LOCKED_STATUS_CODE)
                    || (requestedState === RequestedState.RequestForUnlocked.toLowerCase() && this.selectedEntity.statusCode !== UNLOCKED_STATUS_CODE)) {
                    this.userRequestedEbsState = wbsSettings.requestedState;
                }
                if (this.selectedEntity.currentStatusCode.toUpperCase() === "RECO"){
                    this.userRequestedEbsState = RequestedState.ReadyToClose;
                }
                this.isLoadingOrUpdatingRequest = false;
            }).catch((error) => {
                let errorMessage: string;
                if (error.status === 404) {
                    this.wbsSettings = this.projectServiceV2.getDefaultWbsSettings(this.selectedEntity.id);
                    if (this.selectedEntity.currentStatusCode.toUpperCase() === "RECO"){
                        this.userRequestedEbsState = RequestedState.ReadyToClose;
                    }
                } else {
                    errorMessage = DmError.EbsRequestChange.ErrorOccuredInGettingRequestedEBSState;
                    this.fxpMessageService.addMessage((DmError.EbsRequestChange.ErrorOccuredInGettingRequestedEBSState + error), this.FXP_CONSTANTS.messageType.error);
                }
                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isLoadingOrUpdatingRequest = false;
            }).then(() => {
                this.projectServiceV2.getActiveWbsIncidents(this.selectedEntity.id)
                    .then((activeWbsIncidents: IWbsIncident[]) => {
                        if (activeWbsIncidents && activeWbsIncidents.length) {
                            const tecoWbsIncidents = activeWbsIncidents.filter((incident) => incident.incident.type === "TECO" || incident.incident.type === "RECO");
                            if (tecoWbsIncidents && tecoWbsIncidents.length) {
                                const isIncidentAtCurrentWbsLevel = (this.userRequestedEbsState === RequestedState.RequestedForTechnicallyCompleted || this.userRequestedEbsState === RequestedState.ReadyToClose) && tecoWbsIncidents.some((v) => v.wbsId === this.selectedEntity.id);
                                this.associatedActiveWbsIncidentsViewModel = {
                                    isIncidentAtCurrentWbsLevel,
                                    currentActiveWbsIncident: isIncidentAtCurrentWbsLevel ? tecoWbsIncidents.filter((v) => v.wbsId === this.selectedEntity.id)[0] : null,
                                    allAssociatedIncidents: tecoWbsIncidents
                                };
                            }
                        }
                    });
            });
        }

        this.fxpBroadcastService.On(BroadcastEvent.ProjectClosureOrchestrationCompleted, (event, args) => {

            this.closeModal();

            /*

            if (!args.eventProcessed) {
                args.eventProcessed = true;

                let engagementData: IEngagementDetailsV2;
                let projectData: IProjectDetailsV2;
                let projectClosureRequestData: IProjectClosureRequest;
                let triggerNotification = true;

                this.projectService.getPJCMasterData(args.orchestrationId)
                    .then((data: IProjectClosureRequest) => {
                        projectClosureRequestData = data;

                        if (args.wbsId && args.wbsId.length > 12) {
                            const projectDetails$ = this.store.select(getEntireProjectDetails(args.wbsId));

                            projectDetails$.pipe(untilDestroyed(this))
                                .subscribe((projectDetailsState: IProjectDetailsState) => {
                                    if (projectDetailsState.loaded) {
                                        projectData = projectDetailsState.projectDetails.projectFullDetails;
                                    }
                                });
                        }

                        const engagementDetails$ = this.store.select(getEntireEngagementDetails(args.wbsId));

                        engagementDetails$.pipe(untilDestroyed(this))
                            .subscribe((engagementDetails: IEngagementDetailsState) => {
                                if (engagementDetails.loaded) {
                                    engagementData = engagementDetails.engagementDetails;

                                    // We are using this triggerNotification flag to make sure email 
                                    // notifications are only being sent once. As we invalidate the
                                    // store post successful orchestration completion control can
                                    // come to this reference many times. 
                                    if (triggerNotification) {
                                        triggerNotification = false;
                                        this.handleProjectClosureNotification(projectClosureRequestData, engagementData, projectData);
                                    }
                                }
                            });
                    })
                    .catch((error) => {
                        const errorMessage = DmError.EbsRequestChange.ErrorOccuredInGettingPJCMasterData;
                        this.logError(SourceConstants.Method.GetPJCMasterData, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    });
            }
            */
        });
    }

    /**
     * Update the selected status
     */
    public async onStatusChange(selectedStatus: IWbsState): Promise<void> {
        this.isStateReversal = false;
        this.isPJCStatusChangeAllowed = true;
        this.selectedRequestStatus = selectedStatus ? selectedStatus.displayText : "";
        this.selectedRequestStatusCode = selectedStatus ? selectedStatus.value : "";
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.OnStatusChange, LogEventConstants.EbsStateSelected, { selectedState: this.statusDescription });
        this.isSubmitBtnDisabled = true;
        if (this.selectedRequestStatus && this.selectedRequestStatus !== this.statusDescription) {
            this.isSubmitBtnDisabled = false;
        }
        // Submit is disabled if user changes state from created to released and show appropriate message.
        if (this.statusDescription === "Created" && this.selectedRequestStatus === "Released") {
            this.isStateChangeErrorMessage = true;
            this.isSubmitBtnDisabled = this.entityType.toLowerCase() === EntityType.Engagement.toLowerCase();
        }

        // Only check for New TECO state at the Engagement level. Allow previous TECO at the project level.
        if (this.selectedRequestStatus === "Technically Completed") {
            if (this.selectedEngagement && !this.isInternalEngagement) {
                this.isSubmitBtnDisabled = this.tecoValidationResult ? this.tecoValidationResult.isValid : true;
            }
        }

        if ((this.statusCode.toUpperCase() === "REL" && this.selectedRequestStatusCode.toUpperCase() === "DECO") ||
            (this.statusCode.toUpperCase() === "DECO" && this.selectedRequestStatusCode.toUpperCase() === "REL") ||
            (this.statusCode.toUpperCase() === "DECO" && this.selectedRequestStatusCode.toUpperCase() === "RECO") ||
            (this.statusCode.toUpperCase() === "RECO" && this.selectedRequestStatusCode.toUpperCase() === "DECO")) {


            if (this.selectedProject && this.selectedProject.userStatusCode.toLocaleUpperCase().includes("MDL")) {
                this.isProjectMarkedForDeletion = true;

            }

            this.isPJCStatusChangeAllowed = this.isStatusChangeAllowed(this.selectedRequestStatusCode);

            if (this.isPJCStatusChangeAllowed && !this.isProjectMarkedForDeletion) {

                this.userHasAccessForDecoReco = this.IsUserHasAccessForDecoReco(this.statusCode, this.selectedRequestStatusCode);

                if (this.userHasAccessForDecoReco) {
                    this.isCheckingPJCInProgressRequests = true;
                    const wbsId = this.selectedEngagement ? this.selectedEngagement.id : this.selectedProject.id;
                    this.projectServiceFunctions.checkProjectClosureOrchestrationRun(wbsId, this.statusCode.toUpperCase()).
                        then((isInProgress: boolean) => {
                            this.isCheckingPJCInProgressRequests = false;
                            this.isProjectClosureOrchestrationAlreadyTriggered = isInProgress;
                            if (!isInProgress && this.statusCode.toUpperCase() === "REL" && this.selectedRequestStatusCode.toUpperCase() === "DECO") {
                                this.loadDecoComponent = true;
                            }
                            else if (!isInProgress && this.statusCode.toUpperCase() === "DECO" && this.selectedRequestStatusCode.toUpperCase() === "RECO") {
                                this.loadRecoComponent = true;
                            }
                            else if (!isInProgress && ((this.statusCode.toUpperCase() === "DECO" && this.selectedRequestStatusCode.toUpperCase() === "REL")
                                || (this.statusCode.toUpperCase() === "RECO" && this.selectedRequestStatusCode.toUpperCase() === "DECO"))) {
                                this.isStateReversal = true;
                                const inputs = {
                                    reversalMessage: `Are you sure you want to change status from ${this.statusDescription} to ${this.selectedRequestStatus}?`,
                                    reversalDescription: `This will be a permanent change. To move the status back to ${this.statusDescription} from ${this.selectedRequestStatus}, you will have to complete all the criteria again.`
                                };
                                this.reversal_shared_input = JSON.stringify(inputs);
                            }

                        }).catch((error: Promise<any>) => {
                            this.isCheckingPJCInProgressRequests = false;
                            const errorMessage = DmError.EbsRequestChange.ErrorOccuredInGettingProgressRequest;
                            this.logError(SourceConstants.Method.ProjectClosureInProgressRequestCheck, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        });
                }
            }

        }
    }

    public onStateReversalComponentCallback($eventArgs: SharedComponentCallBackEventArgs): void {
        const payload = $eventArgs.detail;
        if (payload.eventName === ProjectClosureModelEventTypes.OnCancel) {
            this.closeModal();
        }
        else if (payload.eventName === ProjectClosureModelEventTypes.OnSubmit) {
            this.isStateReversal = false;
            this.isLoadingETMDetails = true;
            this.projectService.getETMDetails(this.selectedEntity.id).then((response) => {
                const formData = new FormData();
                const pjcStatusContract: IProjectClosureRequest = {
                    wbsId: this.selectedEntity.id,
                    wbsType: this.selectedProject ? this.selectedProject.contractType : this.selectedEngagement.typeOfContract,
                    stateFrom: this.selectedEntity.currentStatusCode,
                    stateTo: this.selectedRequestStatusCode,
                    wbsStartDate: this.selectedEntity.startDate,
                    wbsEndDate: this.selectedEntity.endDate,
                    isEtm: response.isETM,
                    runFFSeq: response.runFFSeq
                };
                formData.append("pjcStatusContract", JSON.stringify(pjcStatusContract));
                this.submitProjectClosureRequest(formData);
                this.isLoadingETMDetails = false;
            }).catch((error) => {
                this.fxpMessageService.addMessage("Failed to Fetch ETM and RunFFSeq Details", FxpConstants.messageType.error);
                this.isLoadingETMDetails = false;
            });
        }

    }

    /**
     * Sends a request to update the EBS state
     */
    public updateEbsState(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.UpdateEbsState, LogEventConstants.EBSRequestStateChange);
        if (!this.isInternalEngagement) {
            this.isLoadingOrUpdatingRequest = true;
            this.loadingText = "Updating Status";
            const updatedState = this.selectedRequestStatus;
            const changedProperties: IChangedProperties[] = [];
            this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject(this.entityType + " EBS State", this.statusDescription, updatedState));
            this.startBusinessProcessTelemetry(BusinessTask.EbsStateChangeTeco);
            this.createLogAndSendNotification(changedProperties);
        }
    }
    /**
     * Logs the event for when a tooltip is clicked, including the event constant for the specific tooltip (passed in)
     *
     * @param {string} logEventConstant the specific event constant for the type of tooltip
     */
    public tooltipClicked(logEventConstant: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.TooltipClicked, logEventConstant);
    }

    /**
     * Listens for the TecoValidationREsult event
     * @param result result of the Teco Validations
     */
    public setTecoValidationResult(result: IValidationResultEmmiter): void {
        this.tecoValidationResult = result;
        this.isSubmitBtnDisabled = !this.tecoValidationResult.isValid && !this.userRequestedEbsState;
    }

    public closeModal(): void {
        this.activeModal.close();
    }

    public submitProjectClosureRequest(formData: FormData): void {

        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        const orchestrationId: string = uuid();
        this.isOrchestrationTriggered = true;
        formData.append(ProjectClosureSubmitFormData.OrchestrationId, orchestrationId);

        this.projectService.orchestrateStatusUpdate(formData).then(() => {
            this.dmNotificationService.createNotificationSubscriptionEntry(NotificationType.ProjectClsoure, loggedInUserData.BusinessPartnerId, this.selectedEntity.id, orchestrationId).then(() => {
                this.isPJCOrchestrationCallSuccessful = true;
                this.fxpMessageService.addMessage(`Engagement status update to ${this.selectedRequestStatus} submitted successfully!`, this.FXP_CONSTANTS.messageType.success, false);
                
                // Displays the new notification in the notification bar
                this.dmNotificationService.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.selectedEntity.id, orchestrationId, NotificationType.ProjectClsoure);
                this.isOrchestrationTriggered = false;
                this.loadDecoComponent = false;
                this.loadRecoComponent = false;
                this.isStateReversal = false;
            });
        }).catch(() => {
            this.fxpMessageService.addMessage(`Engagement status update to ${this.selectedRequestStatus} failed!. Please try again.`, this.FXP_CONSTANTS.messageType.error, false);
            this.dmNotificationService.deleteNotificationSubscription(loggedInUserData.alias, orchestrationId);
            this.isOrchestrationTriggered = false;
            this.isPJCOrchestrationCallFailed = true;
        });
    }

    public handleProjectClosureNotification(projectClosureRequest: IProjectClosureRequest, currEngagement: IEngagementDetailsV2, currProject: IProjectDetailsV2): void {
        const changedProperties: IChangedProperties[] = [];

        const fromDeliveryState = this.allDeliveryStates.find((state) => state.value === projectClosureRequest.stateFrom);
        const fromState = `${fromDeliveryState.displayText} (${fromDeliveryState.value})`;
        const toDeliveryState = this.allDeliveryStates.find((state) => state.value === projectClosureRequest.stateTo);
        const toState = `${toDeliveryState.displayText} (${toDeliveryState.value})`;

        const entityType = currProject ? EntityType.Project : EntityType.Engagement;
        this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject(entityType + " EBS State", fromState, toState));

        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.wbsService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.EBSRequestStateChange, propertyBag);

        const notification = new NotificationModel();
        const wbsDetails = {
            wbsId: "",
            properties: []
        };
        wbsDetails.properties.push({
            state: changedProperties[0].newValue.toString()
        });
        notification.engagementId = currEngagement.id;
        notification.engagementName = currEngagement.name;
        notification.eventName = NotificationEvent.EBSStateChangeRequest;
        const securityGroups: IDistributionListObject[] = this.configurationService.getDistributionList(currEngagement.isConfidential);

        notification.modifiedBy = userAlias;
        notification.modifiedDate = moment.utc().toDate();
        notification.changedProperties = changedProperties;
        let esxpNotification = currProject ? this.notificationMessage.ProjectNotification : this.notificationMessage.EngagementNotification;

        if (currProject) {
            wbsDetails.wbsId = currProject.id;
            notification.engagementId = currEngagement.id;
            notification.engagementName = currEngagement.name;
            notification.attributes = {
                Id: currProject.id,
                Name: currProject.name,
                Type: EntityType.Project,
                Company: currProject.companyCode + (currProject.companyName ? " - " + currProject.companyName : ""),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential,
                PPJM: currEngagement.pPjm.alias
            };

            if (projectClosureRequest.stateFrom.toUpperCase() === "REL" && projectClosureRequest.stateTo.toUpperCase() === "DECO") {
                this.startBusinessProcessTelemetry(BusinessTask.EbsStateChangeDeco);
                // To: RM, PJMs
                // CC: Additinal PJMs
                let toList: string[] = [];
                if (currEngagement.relManager && currEngagement.relManager.alias) {
                    toList.push(currEngagement.relManager.alias);
                }

                toList = toList.concat(this.sharedFunctionsService.getListofPjmV2(currEngagement));

                const ccList: string[] = this.sharedFunctionsService.getListofAdPjmV2(currEngagement) // gets additional project managers for the engagement and its children projects
                    .concat(userAlias).concat(this.sharedFunctionsService.getListofDMM(currEngagement));

                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(toList);
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(ccList);

                notification.eventName = NotificationEvent.EbsStateChangeRequestDeco;
                notification.decoValidationResult = this.getDecoNotificationContractValue(projectClosureRequest);
            }
            else if (projectClosureRequest.stateFrom.toUpperCase() === "DECO" && projectClosureRequest.stateTo.toUpperCase() === "REL") {
                this.startBusinessProcessTelemetry(BusinessTask.EbsStateReversal);
                // To: RM, PJMs
                // CC: Additinal PJMs
                let toList: string[] = [];
                if (currEngagement.relManager && currEngagement.relManager.alias) {
                    toList.push(currEngagement.relManager.alias);
                }

                toList = toList.concat(this.sharedFunctionsService.getListofPjmV2(currEngagement));

                const ccList: string[] = this.sharedFunctionsService.getListofAdPjmV2(currEngagement)  // gets additional project managers for the engagement and its children projects
                    .concat(userAlias).concat(this.sharedFunctionsService.getListofDMM(currEngagement));

                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(toList);
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(ccList);

                notification.eventName = NotificationEvent.EbsStateReversalRequest;
            }

            notification.subject = `EBS State Change (${projectClosureRequest.stateFrom} to ${projectClosureRequest.stateTo}) submitted for Project [${currProject.id} : ${currProject.name}]`;
            esxpNotification = esxpNotification.replace("#", currProject.name);
        }
        else {
            wbsDetails.wbsId = currEngagement.id;
            notification.attributes = {
                Type: EntityType.Engagement,
                Name: currEngagement.name,
                Id: currEngagement.id,
                Company: currEngagement.companyCode + (currEngagement.companyName ? " - " + currEngagement.companyName : ""),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential,
                PPJM: currEngagement.pPjm.alias
            };

            if (projectClosureRequest.stateFrom.toUpperCase() === "REL" && projectClosureRequest.stateTo.toUpperCase() === "DECO") {
                this.startBusinessProcessTelemetry(BusinessTask.EbsStateChangeDeco);
                // To: RM
                // To: RM, PJMs
                // CC: Additinal PJMs
                let toList: string[] = [];
                if (currEngagement.relManager && currEngagement.relManager.alias) {
                    toList.push(currEngagement.relManager.alias);
                }

                toList = toList.concat(this.sharedFunctionsService.getListofPjmV2(currEngagement));

                const ccList: string[] = this.sharedFunctionsService.getListofAdPjmV2(currEngagement)  // gets additional project managers for the engagement and its children projects
                    .concat(userAlias).concat(this.sharedFunctionsService.getListofDMM(currEngagement));

                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(toList);
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(ccList);

                notification.eventName = NotificationEvent.EbsStateChangeRequestDeco;
                notification.decoValidationResult = this.getDecoNotificationContractValue(projectClosureRequest);
            }
            else if (projectClosureRequest.stateFrom.toUpperCase() === "DECO" && projectClosureRequest.stateTo.toUpperCase() === "RECO") {
                this.startBusinessProcessTelemetry(BusinessTask.EbsStateChangeReco);
                // To: PJC
                // CC: RM, PJMs and Additinal PJMs
                let sendTo = [];
                let ccList: string[] = [];
                ccList = this.sharedFunctionsService.getListofPjmV2(currEngagement)
                    .concat(this.sharedFunctionsService.getListofAdPjmV2(currEngagement))
                    .concat(userAlias).concat(this.sharedFunctionsService.getListofDMM(currEngagement));
                if (currEngagement.relManager && currEngagement.relManager.alias) {
                    ccList.push(currEngagement.relManager.alias);
                }

                if (currEngagement.companyCode) {
                    const secGroup = securityGroups.filter((x) => x.CompanyCode === Number(currEngagement.companyCode))[0];
                    if (secGroup && secGroup.SecurityGroup) {
                        sendTo = sendTo.concat(secGroup.SecurityGroup);
                    }
                }
                if (!sendTo.length) {
                    const defaultSecurityGroup: string = securityGroups.filter((x) => x.CompanyCode === 0)[0].SecurityGroup;
                    sendTo.push(defaultSecurityGroup);
                }

                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(ccList);

                notification.eventName = NotificationEvent.EbsStateChangeRequestReco;
                notification.recoValidationResult = this.getRecoNotificationContractValue(projectClosureRequest);
            }
            else if ((projectClosureRequest.stateFrom.toUpperCase() === "RECO" && projectClosureRequest.stateTo.toUpperCase() === "DECO")
                || (projectClosureRequest.stateFrom.toUpperCase() === "DECO" && projectClosureRequest.stateTo.toUpperCase() === "REL")) {
                this.startBusinessProcessTelemetry(BusinessTask.EbsStateReversal);
                // To: RM, PJMs
                // CC: Additinal PJMs
                let toList: string[] = [];
                if (currEngagement.relManager && currEngagement.relManager.alias) {
                    toList.push(currEngagement.relManager.alias);
                }

                toList = toList.concat(this.sharedFunctionsService.getListofPjmV2(currEngagement));

                const ccList: string[] = this.sharedFunctionsService.getListofAdPjmV2(currEngagement)  // gets additional project managers for the engagement and its children projects
                    .concat(userAlias).concat(this.sharedFunctionsService.getListofDMM(currEngagement));

                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(toList);
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(ccList);

                notification.eventName = NotificationEvent.EbsStateReversalRequest;
            }

            notification.subject = `EBS State Change (${projectClosureRequest.stateFrom} to ${projectClosureRequest.stateTo}) submitted for Engagement [${currEngagement.id} : ${currEngagement.name}]`;
            esxpNotification = esxpNotification.replace("#", currEngagement.name);
        }

        if (projectClosureRequest.stateFrom.toUpperCase() === "DECO" && projectClosureRequest.stateTo.toUpperCase() === "RECO") {
            // 1. Create a cosmic ticket,
            // 2. Send an email notification from PJMXP
            this.cosmicService.createCosmicTicketForNotification(notification)
                .then((cosmicFunctionResponse: ICosmicFunctionResponse) => {
                    notification.attributes["CosmicTicketNumber"] = cosmicFunctionResponse.ticketNumber;
                    notification.attributes["CosmicTicketLink"] = cosmicFunctionResponse.incidentId;
                    this.fxpMessageService.addMessage("Cosmic ticket has been created successfully.", this.FXP_CONSTANTS.messageType.success);

                    this.notificationService.sendNotification(notification, true, esxpNotification)
                        .then(() => {
                            this.wbsSettings.requestedState = RequestedState.ReadyToClose;
                            this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                                this.endBusinessProcessTelemetry(BusinessTask.EbsStateChangeReco);
                            });
                        });
                }).catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", currEngagement.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.CreateLogAndSendNotification, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                });
        }
        else if (projectClosureRequest.stateFrom.toUpperCase() === "RECO" && projectClosureRequest.stateTo.toUpperCase() === "DECO") {

            // 1. Cancel the created cosmic ticket,
            // 2. Send an email notification from PJMXP

            // Get the created COSMIC ticket Details
            this.projectServiceV2.getActiveWbsIncidents(currEngagement.id)
                .then((activeWbsIncidents: IWbsIncident[]) => {
                    if (activeWbsIncidents && activeWbsIncidents.length) {
                        const recoIncident = activeWbsIncidents.filter((incident) => incident.incident.type === "RECO" && incident.wbsId === currEngagement.id)[0];

                        if (recoIncident) {
                            const cosmicCancelRequest: ICosmicCancellationRequest = {
                                incidentId: recoIncident.id,
                                wbsId: currEngagement.id,
                                wbsName: currEngagement.name,
                                reason: "Ebs state was moved from RECO to DECO",
                                cancelledBy: this.fxpUserInfoService.getCurrentUser()
                            };

                            this.cosmicService.cancelCosmicTicket(cosmicCancelRequest)
                                .then(() => {
                                    this.notificationService.sendNotification(notification, true, esxpNotification)
                                        .then(() => {
                                            this.wbsSettings.requestedState = RequestedState.DeliveryComplete;
                                            this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                                                this.endBusinessProcessTelemetry(BusinessTask.EbsStateReversal);
                                            });
                                        });
                                }).catch((error) => {
                                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                                    failureMessage = failureMessage.replace("#", currEngagement.name);
                                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                                    this.logError(SourceConstants.Method.GetActiveWbsIncidents, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                                });
                        }
                    }
                })
                .catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", currEngagement.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.SendCosmicCancellation, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                });
        }
        else {
            // 1. Send an email notification from PJMXP
            this.notificationService.sendNotification(notification, true, esxpNotification)
                .then(() => {
                    if (projectClosureRequest.stateFrom.toUpperCase() === "DECO" && projectClosureRequest.stateTo.toUpperCase() === "REL") {
                        this.wbsSettings.requestedState = RequestedState.Released;
                        this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                            this.endBusinessProcessTelemetry(BusinessTask.EbsStateReversal);
                        });
                    }
                    else if (projectClosureRequest.stateFrom.toUpperCase() === "REL" && projectClosureRequest.stateTo.toUpperCase() === "DECO") {
                        this.wbsSettings.requestedState = RequestedState.DeliveryComplete;
                        this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                            this.endBusinessProcessTelemetry(BusinessTask.EbsStateChangeDeco);
                        });
                    }
                });
        }
    }

    public getTimeStampInPDT(utcDate: string): string {
        const pstTimezone: string = "America/Los_Angeles";
        const pdtDate = momentTz(utcDate).tz(pstTimezone, true).format("YYYY-MM-DD HH:mm:ss");
        return pdtDate;
    }

    public shouldDisableStateDropdown(): boolean {
        if (this.userRequestedEbsState) {
            if (this.userRequestedEbsState === RequestedState.ReadyToClose){
                return false;
            }
            // Commenting this as Locked/Unlocked logic is a bit complicated and should be revisited later
            // if (this.userRequestedEbsState === RequestedState.RequestForLocked || this.userRequestedEbsState === RequestedState.RequestForUnlocked) {
            //     return false;
            // }
            return true;
        }
        else if (this.associatedActiveWbsIncidentsViewModel && this.associatedActiveWbsIncidentsViewModel.allAssociatedIncidents.length) {
            return true;
        }

        return false;
    }

    public toggleCancellationWorkflow(): void {
        this.isCosmicCancellationWorkflow = true;
    }

    public updateTecoCancellationComment(): void {
        if (this.tecoCancellationComment.length) {
            this.isSubmitBtnDisabled = false;
        }
        else {
            this.isSubmitBtnDisabled = true;
        }
    }

    public sendCosmicCancellation(): void {
        this.isLoadingOrUpdatingRequest = true;
        this.loadingText = "Cancelling cosmic ticket";
        if (this.isCosmicCancellationWorkflow) {
            const cosmiCancelRequest: ICosmicCancellationRequest = {
                incidentId: this.associatedActiveWbsIncidentsViewModel.currentActiveWbsIncident.id,
                wbsId: this.selectedEntity.id,
                wbsName: this.selectedEntity.name,
                reason: this.tecoCancellationComment,
                cancelledBy: this.fxpUserInfoService.getCurrentUser()
            };
            this.cosmicService.cancelCosmicTicket(cosmiCancelRequest)
                .then(() => {
                    this.wbsSettings.requestedState = RequestedState.Released;
                    this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                        this.isLoadingOrUpdatingRequest = false;
                        this.engagementDetailService.invalidateStoreOnRefresh(this.engagementDetailsV2.id);
                        this.fxpMessageService.addMessage("Cosmic ticket has been cancelled.", this.FXP_CONSTANTS.messageType.success);
                        this.closeModal();
                    });
                }).catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.engagementDetailsV2.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.SendCosmicCancellation, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isLoadingOrUpdatingRequest = false;
                });
        }
    }

    private getDecoNotificationContractValue(projectClosureRequest: IProjectClosureRequest): IDecoNotificationContract {
        const decoNotificationContract: IDecoNotificationContract = {
            isMarkedForEarlyTermination: projectClosureRequest.isEtm,
            laborExpenseDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 1) >= 0
                ? {
                    labor: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).displayAttributes.find((attribute) => attribute.displayName === "Labor").value,
                    expenses: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).displayAttributes.find((attribute) => attribute.displayName === "Expenses").value,
                    units: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).displayAttributes.find((attribute) => attribute.displayName === "Units").value,
                    status: "Completed",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).action.elementDisplayMessage
                } : null,
            subConFFDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 2) >= 0
                ? {
                    pOAmount: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 2).displayAttributes.find((attribute) => attribute.displayName === "Subcon PO Amount").value,
                    gRAmount: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 2).displayAttributes.find((attribute) => attribute.displayName === "Goods Receipt").value,
                    invoicedAmount: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 2).displayAttributes.find((attribute) => attribute.displayName === "Invoice Amount").value,
                    status: "Completed",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 2).action.elementDisplayMessage
                } : null,
            subConTMDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 3) >= 0
                ? {
                    pOAmount: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 3).displayAttributes.find((attribute) => attribute.displayName === "Subcon PO Amount").value,
                    gRAmount: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 3).displayAttributes.find((attribute) => attribute.displayName === "Goods Receipt").value,
                    invoicedAmount: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 3).displayAttributes.find((attribute) => attribute.displayName === "Invoice Amount").value,
                    status: "Completed",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 3).action.elementDisplayMessage
                } : null,
            cFPFixedFeeDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 4) >= 0
                ? {
                    contractRevenue: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).displayAttributes.find((attribute) => attribute.displayName === "Contract revenue (FF)").value,
                    recognizedRevenue: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).displayAttributes.find((attribute) => attribute.displayName === "Recognized revenue (FF)").value,
                    pocc: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).displayAttributes.find((attribute) => attribute.displayName === "POCC").value,
                    status: "Completed",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).action.elementDisplayMessage
                } : null,
            cFPECIFDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 5) >= 0
                ? {
                    eCIFBudget: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 5).displayAttributes.find((attribute) => attribute.displayName === "ECIF budget/planned").value,
                    eCIFConsumed: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 5).displayAttributes.find((attribute) => attribute.displayName === "ECIF consumed (SAP actuals - pocc)").value,
                    status: "Completed",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 5).action.elementDisplayMessage
                } : null,
            costRecognitationDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 6) >= 0
                ? {
                    subconFF: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).displayAttributes.find((attribute) => attribute.displayName === "Subcon FF").value,
                    materials: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).displayAttributes.find((attribute) => attribute.displayName === "Materials").value,
                    nPM: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).displayAttributes.find((attribute) => attribute.displayName === "NPM").value,
                    units: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).displayAttributes.find((attribute) => attribute.displayName === "Units").value,
                    status: "Completed",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).action.elementDisplayMessage
                } : null
        };

        return decoNotificationContract;
    }

    private getRecoNotificationContractValue(projectClosureRequest: IProjectClosureRequest): IRecoNotificationContract {
        const recoNotificationContract: IRecoNotificationContract = {
            isMarkedForEarlyTermination: projectClosureRequest.isEtm,
            invoiceFFDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 1) >= 0
                ? {
                    plannedCustomerBilling: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).displayAttributes.find((attribute) => attribute.displayName === "Planned Customer Billing").value,
                    actualCustomerBilling: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).displayAttributes.find((attribute) => attribute.displayName === "Actual Customer Billing").value,
                    status: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).isRulePassed === RuleResultType.Passed ? "Completed" : "Incomplete",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).action.elementValueSelected ?
                        (projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).action.elementValue ?
                            projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).action.elementValueSelected + ", " + projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).action.elementValue
                            : projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 1).action.elementValueSelected)
                        : "",
                } : null,
            validateFFBillingDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 2) >= 0
                ? {
                    plannedRevenue: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 2).displayAttributes.find((attribute) => attribute.displayName === "Planned Revenue").value,
                    status: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 2).isRulePassed === RuleResultType.Passed ? "Completed" : "Incomplete",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 2).action.elementDisplayMessage
                } : null,
            validateTMBillingDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 3) >= 0
                ? {
                    actualRevenue: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 3).displayAttributes.find((attribute) => attribute.displayName === "Actual Revenue").value,
                    status: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 3).isRulePassed === RuleResultType.Passed ? "Completed" : "Incomplete",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 3).action.elementDisplayMessage
                } : null,
            revenueTMDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 4) >= 0
                ? {
                    recognizedRevenue: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).displayAttributes.find((attribute) => attribute.displayName === "Recognized Revenue").value,
                    customerBilling: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).displayAttributes.find((attribute) => attribute.displayName === "Customer Billing").value,
                    status: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).isRulePassed === RuleResultType.Passed ? "Completed" : "Incomplete",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 4).action.elementDisplayMessage
                } : null,
            invoiceTMDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 5) >= 0
                ? {
                    contractValue: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 5).displayAttributes.find((attribute) => attribute.displayName === "Contract value").value,
                    customerBilling: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 5).displayAttributes.find((attribute) => attribute.displayName === "Customer Billing").value,
                    status: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 5).isRulePassed === RuleResultType.Passed ? "Completed" : "Incomplete",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 5).action.elementDisplayMessage
                } : null,
            invoiceTMPrePayDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 6) >= 0
                ? {
                    recognizedRevenuee: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).displayAttributes.find((attribute) => attribute.displayName === "Recognized Revenue").value,
                    deliveryInvoicing: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).displayAttributes.find((attribute) => attribute.displayName === "Delivery Invoicing").value,
                    prepaidOrPeriodicbilling: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 7).displayAttributes.find((attribute) => attribute.displayName === "Prepaid / Periodic Billing").value,
                    status: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).isRulePassed === RuleResultType.Passed ? "Completed" : "Incomplete",
                    action: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 6).action.elementDisplayMessage,
                    subStatus: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 7).isRulePassed === RuleResultType.Passed ? "Completed" : "Incomplete",
                    subAction: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 7).action.elementDisplayMessage,
                } : null,
            ecifDetails: projectClosureRequest.ruleResults.findIndex((rule) => rule.ruleId === 8) >= 0
                ? {
                    consumedFunds: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 8).displayAttributes.find((attribute) => attribute.displayName === "Consumed Funds").value,
                    totalFunds: projectClosureRequest.ruleResults.find((rule) => rule.ruleId === 8).displayAttributes.find((attribute) => attribute.displayName === "Total Funds").value,
                    status: "",
                    action: ""
                } : null,
        };

        return recoNotificationContract;
    }

    /**
     *  Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     *  relevant people.
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.wbsService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.EBSRequestStateChange, propertyBag);

        const currEngagement: IEngagementDetailsV2 = this.engagementDetailsV2;
        const notification = new NotificationModel();
        const wbsDetails = {
            wbsId: "",
            properties: []
        };
        wbsDetails.properties.push({
            state: changedProperties[0].newValue.toString()
        });
        notification.engagementId = currEngagement.id;
        notification.engagementName = currEngagement.name;
        notification.eventName = NotificationEvent.EBSStateChangeRequest;
        const securityGroups: IDistributionListObject[] = this.configurationService.getDistributionList(currEngagement.isConfidential);

        let currProject: IProjectDetailsV2;
        if (this.selectedProject) { // If we are working with a project, then use the raw project object
            currProject = this.selectedProject;
        } else if (this.selectedService) {
            currProject = currEngagement.projects.filter((x) => x.id === this.selectedService.projectId)[0];
        }

        let sendTo: string[] = [];
        const cc: string[] = this.sharedFunctionsService.getListofPjmV2(currEngagement)  // gets project managers and delegated project managers for the engagement and its children projects
            .concat(this.sharedFunctionsService.getListofAdPjmV2(currEngagement))  // gets additional project managers for the engagement and its children projects
            .concat(userAlias);

        if (currEngagement.companyCode) {
            const secGroup = securityGroups.filter((x) => x.CompanyCode === Number(currEngagement.companyCode))[0];
            if (secGroup && secGroup.SecurityGroup) {
                sendTo = sendTo.concat(secGroup.SecurityGroup);
            }
        }
        if (!sendTo.length) {
            const defaultSecurityGroup: string = securityGroups.filter((x) => x.CompanyCode === 0)[0].SecurityGroup;
            sendTo.push(defaultSecurityGroup);
        }
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(cc);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = moment.utc().toDate();
        notification.changedProperties = changedProperties;
        let esxpNotification = this.selectedEngagement ? this.notificationMessage.EngagementNotification : this.notificationMessage.ProjectNotification;
        if (this.selectedEngagement) {
            wbsDetails.wbsId = currEngagement.id;
            notification.attributes = {
                Type: EntityType.Engagement,
                Name: currEngagement.name,
                Id: currEngagement.id,
                Company: currEngagement.companyCode + (currEngagement.companyName ? " - " + currEngagement.companyName : ""),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential,
                PPJM: currEngagement.pPjm.alias
            };
            if (this.tecoValidationResult && this.tecoValidationResult.isValid) {
                notification.tecoValidationResult = this.tecoValidationResult.result;

                // Give proper TECO event name to notification
                switch (this.sharedFunctionsService.getContractType(currEngagement.projects)) {
                    case ContractType.FixedFee: {
                        notification.eventName = NotificationEvent.EBSStateChangeRequestFFTeco;
                        break;
                    }
                    case ContractType.TimeAndMaterial: {
                        notification.eventName = NotificationEvent.EBSStateChangeRequestTMTeco;
                        break;
                    }
                    case ContractType.Mixed: {
                        notification.eventName = NotificationEvent.EBSStateChangeRequestMixedTeco;
                        break;
                    }
                }
            }
            notification.subject = "EBS State Change Requested for Engagement [" + currEngagement.id + ": " + currEngagement.name + "]";
            esxpNotification = esxpNotification.replace("#", this.selectedEngagement.name);
        } else if (this.selectedProject) {
            wbsDetails.wbsId = currProject.id;
            notification.attributes = {
                Id: currProject.id,
                Name: currProject.name,
                Type: EntityType.Project,
                Company: currProject.companyCode + (currProject.companyName ? " - " + currProject.companyName : ""),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential,
                PPJM: currEngagement.pPjm.alias
            };
            notification.subject = "EBS State Change Requested for Project [" + currProject.id + ": " + currProject.name + "]";
        } else if (this.selectedService) {
            wbsDetails.wbsId = this.selectedService.id;
            notification.attributes = {
                Id: this.selectedService.id,
                Name: this.selectedService.name,
                Type: EntityType.Service,
                Company: this.getProjectCompanyCodeV2(currEngagement, this.selectedService.projectId),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential,
                PPJM: currEngagement.pPjm.alias
            };
            notification.subject = "EBS State Change Requested for Service [" + this.selectedService.id + ": " + this.selectedService.name + "]";
        } else if (this.selectedTask) {
            wbsDetails.wbsId = this.selectedTask.id;
            notification.attributes = {
                Id: this.selectedTask.id,
                Name: this.selectedTask.name,
                Type: EntityType.Task,
                Company: this.getProjectCompanyCodeV2(currEngagement, this.selectedTask.serviceId),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential,
                PPJM: currEngagement.pPjm.alias
            };
            notification.subject = "EBS State Change Requested for Task [" + this.selectedTask.id + ": " + this.selectedTask.name + "]";
        }

        if (!this.selectedEngagement) {
            notification.engagementId = currEngagement.id;
            notification.engagementName = currEngagement.name;
            notification.eventName = NotificationEvent.EBSStateChangeRequest;
            esxpNotification = esxpNotification.replace("#", this.selectedTask ? this.selectedTask.name : currProject.name);
        }


        this.cosmicService.createCosmicTicketForNotification(notification)
            .then((cosmicFunctionResponse: ICosmicFunctionResponse) => {
                notification.subject = cosmicFunctionResponse.title;
                /* tslint:disable:no-string-literal */
                notification.attributes["CosmicTicketNumber"] = cosmicFunctionResponse.ticketNumber;
                notification.attributes["CosmicTicketLink"] = cosmicFunctionResponse.incidentId;
                this.notificationService.sendNotification(notification, true, esxpNotification).then(() => {
                    this.wbsSettings.requestedState = this.sharedFunctionsService.getRequestedState(changedProperties[0].newValue.toString());
                    this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                        this.isLoadingOrUpdatingRequest = false;
                        this.engagementDetailService.invalidateStoreOnRefresh(this.engagementDetailsV2.id);
                        this.fxpMessageService.addMessage("EBS State Change has been requested.", this.FXP_CONSTANTS.messageType.success);
                        this.endBusinessProcessTelemetry(BusinessTask.EbsStateChangeTeco);
                        this.closeModal();
                    });
                });
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.engagementDetailsV2.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.CreateLogAndSendNotification, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isLoadingOrUpdatingRequest = false;
            });
    }

    /**
     * Gets the project company code for the given project ID under the given engagement
     */
    private getProjectCompanyCodeV2(engagement: IEngagementDetailsV2, projectId: string): string {
        let company: string = "";
        if (engagement && engagement.projects && engagement.projects.length) {
            const project = engagement.projects.filter((p) => p.id === projectId || (p.id.substring(0, 16) === projectId.substring(0, 16)));
            if (project.length) {
                company = project[0].companyCode + (project[0].companyName ? " - " + project[0].companyName : "");
            }
        }
        return company;
    }

    private isStatusChangeAllowed(selectedStatusCode: string): boolean {
        if (this.selectedProject && selectedStatusCode.toUpperCase() === "REL" && this.engagementDetailsV2.currentStatusCode.toUpperCase() === "DECO") {
            return false;
        }
        else {
            return true;
        }

    }

    private IsUserHasAccessForDecoReco(currentStatusCode: string, selectedStatusCode: string): boolean {
        const currentUser: number = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        let engagementAllowedRoles = [];
        let projectAllowedRoles = [];

        if ((currentStatusCode.toUpperCase() === "REL" && selectedStatusCode.toUpperCase() === "DECO")
            ||
            (currentStatusCode.toUpperCase() === "DECO" && selectedStatusCode.toUpperCase() === "REL")) {
            if (this.selectedEngagement) {
                engagementAllowedRoles = this.configurationService.getValue<string[]>("DecoAllowedRoleAtEngagement");

            }
            if (this.selectedProject) {
                projectAllowedRoles = this.configurationService.getValue<string[]>("DecoAllowedRoleAtProject");
                engagementAllowedRoles = this.configurationService.getValue<string[]>("DecoAllowedRoleAtEngagement");

            }

        }

        if ((currentStatusCode.toUpperCase() === "DECO" && selectedStatusCode.toUpperCase() === "RECO")
            ||
            (currentStatusCode.toUpperCase() === "RECO" && selectedStatusCode.toUpperCase() === "DECO")
        ) {
            engagementAllowedRoles = this.configurationService.getValue<string[]>("RecoAllowedRole");
        }

        if (this.selectedEngagement) {
            for (const role of engagementAllowedRoles) {
                const bpidValues = this.sharedFunctionsService.getPjmInfoL0(role, this.selectedEngagement).map((teamInfo) => Number(teamInfo.bpid));
                if (bpidValues.includes(currentUser)) {
                    return true;
                }
            }

        }

        if (this.selectedProject) {
            for (const role of projectAllowedRoles) {
                const bpidValues = this.sharedFunctionsService.getPjmInfoL1(role, this.selectedProject).map((teamInfo) => Number(teamInfo.bpid));
                if (bpidValues.includes(currentUser)) {
                    return true;
                }
            }

            for (const role of engagementAllowedRoles) {
                const bpidValues = this.sharedFunctionsService.getPjmInfoL0(role, this.engagementDetailsV2).map((teamInfo) => Number(teamInfo.bpid));
                if (bpidValues.includes(currentUser)) {
                    return true;
                }
            }
        }
        return false;
    }
}

