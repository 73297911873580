import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimelineComponent } from "./timeline.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ChangeRequestService } from "../../../common/services/change-request.service";
@NgModule({
    declarations: [
        TimelineComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        NgbModule
    ],
    providers: [
        ChangeRequestService
    ],
    entryComponents: [
        TimelineComponent
    ],
    exports: [
        TimelineComponent
    ],
})
export class TimelineModule { }
