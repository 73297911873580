<div class="cceac--template">
    <div class="cceac--consumedvalue">
        <p class="font-subtitle">{{percentageConsumed | number: '1.2-2'}}%</p>
    </div>
    <div class="cceac--graph">
        <div class="cceac" *ngIf="values" title="cost consumed summary graph">
            <div class="cceac--eacvalue" [hidden]="values.eac===undefined">
                <div class="cceac--consumed"></div>
            </div>
            <div class="cceac--currentFinancialPlan" [hidden]="values.currentFinancialPlan===undefined">
                <p class="magnifying-glass"></p>
            </div>
            <div class="cceac--deliveryBaseline" [hidden]="values.deliveryBaseline===undefined">
                <p class="diamond-narrow"></p>
            </div>
            <div class="cceac--contractBaseline" [hidden]="values.contractBaseline===undefined">
                <p class="talkbubble"></p>
            </div>
        </div>
    </div>
</div>