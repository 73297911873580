import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { WbsDetailsComponent } from "./wbs-details.component";
import { NgModule } from "@angular/core";
import { EngagementBreakdownStructureModule } from "../manage-ebs-v2/tiles/engagement-breakdown-structure/engagement-breakdown-structure.module";
import { EngagementSummaryFinancialsV2Module } from "../engagement-summary-v2/tiles/engagement-summary-financials-v2/engagement-summary-financials-v2.module";
import { EntityTeamStructureModule } from "../manage-ebs-v2/tiles/entity-team-structure/entity-team-structure.module";
import { ManageEbsEntityDetailsModule } from "../manage-ebs-v2/tiles/manage-ebs-entity-details/manage-ebs-entity-details.module";
import { ProjectSummaryFinancialsV2Module } from "../project-summary-v2/tiles/project-summary-financials-v2/project-summary-financials-v2.module";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { SummaryFinancialsBreakdownModule } from "../engagement-summary-v2/tiles/summary-financials-breakdown/summary-financials-breakdown.module";

@NgModule({
    declarations: [
        WbsDetailsComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        ManageEbsEntityDetailsModule,
        EntityTeamStructureModule,
        EngagementBreakdownStructureModule,
        EngagementSummaryFinancialsV2Module,
        ProjectSummaryFinancialsV2Module,
        SummaryFinancialsBreakdownModule
    ],
    providers: [
        SharedFunctionsService
    ],
    entryComponents: [
        WbsDetailsComponent
    ],
    exports: [
        WbsDetailsComponent
    ]
})
export class WbsDetailsModule { }
