import { Component, Input, Inject } from "@angular/core";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { ICostSummary } from "../../../common/services/contracts/cost-summary.contracts";

@Component({
    selector: "dm-cost-summary",
    templateUrl: "./cost-summary.html",
    styleUrls: ["./cost-summary.scss"]
})
export class CostSummaryComponent extends DmComponentAbstract {

    @Input() public costSummaryError: string;
    @Input() public costSummaryData: ICostSummary[] = [];
    public loadingMessage: string = "Loading Cost Summary...";

    public constructor( @Inject(DMLoggerService) dmLogger: DMLoggerService ) {
        super(dmLogger, Components.CostSummary);
    }

    public ngOnInit(): void {
        const test  = false;
    }
}
