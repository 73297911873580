import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AADGraphService } from "../../common/services/aad-graphapi.service";
import { CommonComponentsModule } from "../entry/common-components.module";
import { ProjectFilterModule } from "../tiles/project-filter/project-filter.module";
import { RequestsGridModule } from "./requests-grid/requests-grid.module";
import { ResourcerejectionModalModule } from "./resource-rejection-modal/resource-rejection-modal.module";
import { StaffingCommandBarModule } from "../staffing-command-bar/staffing-command-bar.module";
import { StaffingComponent } from "./staffing.component";
import { ResourceManagementModalModule } from "./resource-management-modal/resource-management-modal.module";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { StaffingActionService } from "../staffing-command-bar/staffing-command-bar-common/services/staffing-action.service";
import { ResourceStaffingService } from "../../common/services/resource-staffing.service";
import { CacheService } from "../../common/services/cache.service";
import { OneProfileService } from "../../common/services/one-profile.service";
import { ProjectServiceFunctions } from "../../common/services/projectservice-functions.service";

@NgModule({
    declarations: [
        StaffingComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ProjectFilterModule,
        StaffingCommandBarModule,
        RequestsGridModule,
        ResourcerejectionModalModule,
        ResourceManagementModalModule
    ],
    providers: [
        AADGraphService,
        DMLoggerService,
        StaffingActionService,
        ResourceStaffingService,
        CacheService,
        OneProfileService,
        ProjectServiceFunctions
    ],
    entryComponents: [
        StaffingComponent
    ],
    exports: [
        StaffingComponent
    ]
})

export class StaffingModule {
}
