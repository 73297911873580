import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, WBSResponseMessages } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IEngagementDetailsV2, IServiceDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IDateValidationRequest, WbsLevel, IWbsEditServiceDetails, IServiceProductFulfillmentDate, IServiceProductFulfillmentDateUpdateRequest } from "../../../../common/services/contracts/wbs.service.contracts";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../common/services/wbs.service";
import moment from "moment";
import { DmNotificationService } from "../../../../common/services/dm-notification.service";
import { NotificationType } from "../../../../common/services/contracts/notification-bar.contracts";
import { v4 as uuid } from "uuid";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { DmError } from "../../../../common/error.constants";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, AbstractControl, Validators, FormBuilder } from "@angular/forms";
import { EnumUpdateLevel } from "../../../../common/services/contracts/wbs-engagement.contracts";
import { IStaffingDemandModel, IStaffingResource, IStaffingTaskModel } from "../../../../common/services/contracts/staffing-details.contract";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    templateUrl: "./edit-service-details.html",
    styleUrls: ["./edit-service-details.scss"]
})
export class EbsEditServiceDetailsModalComponent extends DmModalAbstract {
    @Input() public selectedService: IServiceDetailsV2;
    @Input() public projectStartDate: Date;
    @Input() public projectEndDate: Date;
    @Input() public ioEndDate: Date;
    @Input() public engagementDetails: IEngagementDetailsV2;
    @Input() public projectTypeCode: string;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public isBeforeProjectStartDate: boolean = false;
    public isAfterProjectEndDate: boolean = false;
    public isAfterChildTaskStartDate: boolean = false;
    public isBeforeChildTaskEndDate: boolean = false;
    public isDateCompare: boolean = false;
    public showActualsStartDateConflictMessage: boolean = false;
    public showActualsEndDateConflictMessage: boolean = false;
    public loadingText: string;
    public disableEBSStartDateEndDateUpdates: boolean = false;
    public viewResourceEnable: boolean = false;
    public showConflictingResources: boolean;
    public noOfConflictResources: number;
    public EnumUpdateLevel: typeof EnumUpdateLevel = EnumUpdateLevel;
    public minDate: Date;
    public maxDate: Date;
    public isUpdateActive: boolean = false;
    public editServiceErrorMessages = DmError.EbsStructure.EditEbsStructure;
    public editServiceProductFulfillmentErrorMessages = DmError.EbsStructure.EditServiceProductFulfillmentDates;
    public editServiceDetailsForm: FormGroup;
    public filteredResourceView: IStaffingTaskModel[] = [];
    /* nuance related */
    public serviceContractStartDate: Date;
    public serviceContractEndDate: Date;
    public servicePlannedStartDate: Date;
    public servicePlannedEndDate: Date;
    public serviceActualStartDate: Date;
    public serviceActualEndDate: Date;
    public isNuanceServiceType: boolean = false;
    public serviceProductFulfillment: IServiceProductFulfillmentDate;
    public isProductFulfillmentTermDuration: boolean = false;
    public isPostProductFulfillmentSolution: boolean = false;
    public engagementStartDate: Date;
    public get serviceStartDate(): AbstractControl {
        return this.editServiceDetailsForm.get("serviceStartDate");
    }
    public get serviceEndDate(): AbstractControl {
        return this.editServiceDetailsForm.get("serviceEndDate");
    }
    public get updateLevel(): AbstractControl {
        return this.editServiceDetailsForm.get("updateLevel");
    }
    public get plannedStartdate(): AbstractControl {
        return this.editServiceDetailsForm.get("plannedStartdate");
    }
    public get actualStartdate(): AbstractControl {
        return this.editServiceDetailsForm.get("actualStartdate");
    }


    public modalContent: IModal;
    public showCascadeRadioButtons: boolean;
    public disableServiceOnlyOption: boolean;
    public isAfterChildServiceStartDate: boolean = false;
    public initialStartDate: Date;
    public initialEndDate: Date;
    public isUpdatingService: boolean;
    public childTaskStartDate: Date;
    public childTaskEndDate: Date;
    public showIODateValidationMessage: boolean;
    public isStartDatePristine: boolean = true;
    public isEndDatePristine: boolean = true;
    public isPlannedStartDateValid: boolean = true;
    public isPlannedStartDateRequired: boolean = false;
    public isActualStartDateValid: boolean = true;
    public isUpdatingServiceProductFulfillmentDate: boolean = true;
    public isServiceActualDateBeforeEngagementStartDate: boolean = false;
    public isEnableCheckboxForActualStartDate: boolean = false;
    public isSubmitButtonEnabledFromCheckBoxForActualStartDate: boolean = true;
    public enableActualStartDate: boolean = false;
    public isShowActualStartDateCheckBox: boolean = false;
    public disableServiceActualStartDate: boolean = false;
    private serviceResponseMessages: IResponseMessage;
    private readonly FXP_CONSTANTS = FxpConstants;
    private conflictResourceStatus: string[];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(FormBuilder) private fb: FormBuilder) {
        super(activeModal, dmLogger, Components.ManageEbsEditServiceDetails);
    }

    public ngOnInit(): void {
        this.isNuanceServiceType = false;
        this.initializeEditServiceDetailsForm();
        this.modalContent = {
            title: "Edit Service Details"
        };
        this.disableEBSStartDateEndDateUpdates = this.configurationService.getValue<boolean>("disableEBSStartDateEndDateUpdates");
        this.serviceResponseMessages = WBSResponseMessages.Service;
        this.updateLevel.setValue(EnumUpdateLevel.serviceLevel); 
        

        if (this.selectedService.serviceType === "NuanceServiceType") {
            this.isNuanceServiceType = true;
            this.engagementStartDate = this.engagementDetails.startDate;
        }

        /* Used for the datepicker to limit the dates that can be selected */
        this.minDate = this.projectStartDate;
        this.maxDate = this.projectEndDate;

        if (this.isNuanceServiceType) {
            this.modalContent.title = "Product Fulfilment dates";
            this.wbsService.getServiceProductFulfillmentDate(this.selectedService.id).then((response: IServiceProductFulfillmentDate) => {
                this.serviceProductFulfillment = response;
                if (response) {
                    if (response.contractStartDate) {
                        this.serviceContractStartDate = response.contractStartDate;
                    }
                    if (response.contractEndDate) {
                        this.serviceContractEndDate = response.contractEndDate;
                    }
                    if (response.plannedStartDate) {
                        this.servicePlannedStartDate = response.plannedStartDate;
                    }
                    if (response.plannedEndDate) {
                        this.servicePlannedEndDate = response.plannedEndDate;
                    }
                    if (response.actualStartDate) {
                        this.serviceActualStartDate = response.actualStartDate;
                    }
                    if (response.actualEndDate) {
                        this.serviceActualEndDate = response.actualEndDate;
                    }
                }
                this.plannedStartdate.setValue(this.servicePlannedStartDate);
                if (!this.servicePlannedStartDate || this.servicePlannedStartDate === null) {
                    this.isPlannedStartDateRequired = true;
                } else {
                    this.isPlannedStartDateRequired = false;
                }
                this.actualStartdate.setValue(this.serviceActualStartDate);
                if (this.serviceActualStartDate && this.serviceActualStartDate !== null) {
                    this.disableServiceActualStartDate = true;
                } else {
                    this.disableServiceActualStartDate = false;
                }
                if (this.serviceProductFulfillment.termType && this.serviceProductFulfillment.termType === "T") {
                    this.isProductFulfillmentTermDuration = true;
                    if (this.servicePlannedStartDate) {
                        this.servicePlannedEndDate = this.calculateEndDate(this.servicePlannedStartDate, this.serviceProductFulfillment.termDuration, this.serviceProductFulfillment.termDurationUom);
                    }
                    if (this.serviceActualStartDate) {
                        this.serviceActualEndDate = this.calculateEndDate(this.serviceActualStartDate, this.serviceProductFulfillment.termDuration, this.serviceProductFulfillment.termDurationUom);
                    }
                } else {
                    this.isProductFulfillmentTermDuration = false;
                    this.servicePlannedEndDate = this.serviceContractEndDate;
                    this.serviceActualEndDate = this.serviceContractEndDate;
                }
                if (this.projectTypeCode && this.projectTypeCode.toUpperCase() === "Z6") {
                    this.isPostProductFulfillmentSolution = true;
                    this.modalContent.title = "Manage Post Product Fulfilment dates";
                } else {
                    this.isPostProductFulfillmentSolution = false;
                    this.modalContent.title = "Manage Product Fulfilment dates";
                }
                this.isUpdatingServiceProductFulfillmentDate = false;
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.selectedService.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.UpdateServiceDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isUpdatingServiceProductFulfillmentDate = false;
            });
        }
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalid: boolean): boolean {
        this.isStartDatePristine = moment(this.selectedService.startDate).isSame(this.serviceStartDate.value);
        this.isEndDatePristine = moment(this.selectedService.endDate).isSame(this.serviceEndDate.value);

        const dates: boolean = this.isStartDateRequired ||
            this.isBeforeProjectStartDate ||
            this.isAfterProjectEndDate ||
            this.isBeforeChildTaskEndDate ||
            this.isAfterChildTaskStartDate ||
            this.isEndDateRequired ||
            this.isDateCompare;
        /* If we are showing the option to cascade, then it does not matter if the dates are before/after the child start/end dates.
        But if we are not showing the option to cascade, then these dates should block the ability to save. */

        const aqrDateValidations: boolean = this.showActualsStartDateConflictMessage || this.showConflictingResources || this.showActualsEndDateConflictMessage || this.showIODateValidationMessage;

        const isFormPristine: boolean = this.isStartDatePristine && this.isEndDatePristine;

        return formInvalid || dates || isFormPristine || aqrDateValidations;
    }

    /**
     * Updates the Service Details by calling the API, sending any notifications, etc.
     */
    public updateServiceDetails(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.UpdateServiceDetails, LogEventConstants.ManageEBSServiceEditSubmitClick);

        this.loadingText = "Updating Service Details";
        this.isUpdateActive = true;
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        let editServiceHasDate: boolean = false;

        const editServiceDataRequest: IWbsEditServiceDetails = {};
        if (!moment(this.serviceStartDate.value as Date).isSame(this.selectedService.startDate as Date, "day")) {
            editServiceHasDate = true;
            editServiceDataRequest.startDate = moment(this.serviceStartDate.value as Date).format(DATE_FORMAT);
            editServiceDataRequest.shouldCascadeUpdate = this.updateLevel.value !== EnumUpdateLevel.serviceLevel;
        }
        if (!moment(this.serviceEndDate.value as Date).isSame(this.selectedService.endDate as Date, "day")) {
            editServiceHasDate = true;
            editServiceDataRequest.endDate = moment(this.serviceEndDate.value as Date).format(DATE_FORMAT);
            editServiceDataRequest.shouldCascadeUpdate = this.updateLevel.value !== EnumUpdateLevel.serviceLevel;
        }

        // Sends a request if any field has been modified
        // Run disconnected date change process for customer services where date is updated
        if (editServiceHasDate) {
            this.isUpdatingService = true;
            const dateValidationRequest: IDateValidationRequest = {
                startDate: editServiceDataRequest.startDate ? editServiceDataRequest.startDate : moment(this.selectedService.startDate).format(DATE_FORMAT),
                endDate: editServiceDataRequest.endDate ? editServiceDataRequest.endDate : moment(this.selectedService.endDate).format(DATE_FORMAT),
                cascadeDown: editServiceDataRequest.shouldCascadeUpdate
            };

            this.wbsService.validateWbsDates(dateValidationRequest, this.selectedService.id).then((response: any) => {
                if (response.status === 200) {
                    const orchestrationId: string = uuid();
                    editServiceDataRequest.startDate = dateValidationRequest.startDate;
                    editServiceDataRequest.endDate = dateValidationRequest.endDate;

                    this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.DateChange, loggedInUserData.BusinessPartnerId, this.selectedService.id, orchestrationId).then(() => {
                        this.projectServiceFunction.orchestrateDateChange(editServiceDataRequest, this.selectedService.id, this.selectedService.name, orchestrationId, WbsLevel.Service, loggedInUserData.alias).then(() => {
                            this.fxpMessageService.addMessage("Date Change has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                            // Displays the new notification in the notification bar
                            this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.selectedService.id, orchestrationId, NotificationType.DateChange);
                            this.isUpdatingService = false;
                            this.closeModal();
                        });
                    });
                }
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.selectedService.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.UpdateServiceDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isUpdatingService = false;
                this.isUpdateActive = false;
            });
        }
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveServiceProductFulfillmentDateButtonDisabled(): boolean {
        this.isStartDatePristine = moment(this.selectedService.startDate).isSame(this.serviceStartDate.value);
        this.isEndDatePristine = moment(this.selectedService.endDate).isSame(this.serviceEndDate.value);
        const formInvalid = false;
        const dates: boolean = this.isStartDateRequired ||
            this.isBeforeProjectStartDate ||
            this.isAfterProjectEndDate ||
            this.isBeforeChildTaskEndDate ||
            this.isAfterChildTaskStartDate ||
            this.isEndDateRequired ||
            this.isDateCompare;
        /* If we are showing the option to cascade, then it does not matter if the dates are before/after the child start/end dates.
        But if we are not showing the option to cascade, then these dates should block the ability to save. */

        const isFormPristine: boolean = this.isStartDatePristine && this.isEndDatePristine;
        let isUpdatingServiceProductFulfillmentDateDisabled: boolean = false;
        isUpdatingServiceProductFulfillmentDateDisabled =  !this.isActualStartDateValid || !this.isPlannedStartDateValid || this.isPlannedStartDateRequired || this.isServiceActualDateBeforeEngagementStartDate;
        if (!isUpdatingServiceProductFulfillmentDateDisabled) {
            if (this.actualStartdate.value && !this.isSubmitButtonEnabledFromCheckBoxForActualStartDate) {
                isUpdatingServiceProductFulfillmentDateDisabled = true;
            }
        }
        return isUpdatingServiceProductFulfillmentDateDisabled;
    }

    

    /**
     * Updates the Service Product fulfilment Details by calling the API, sending any notifications, etc.
     */
    public updateServiceProductFulfillmentDetails(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.UpdateServiceDetails, LogEventConstants.ManageEBSServiceEditSubmitClick);

        this.loadingText = "Updating Service Product fulfilment Details";
        this.isUpdateActive = true;
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        const editServiceProductFulfillmentDataRequest: IServiceProductFulfillmentDateUpdateRequest = {};
        editServiceProductFulfillmentDataRequest.serviceId = this.selectedService.id;
        // Need to always send details to SAP in the format YYYY-MM-DD

        editServiceProductFulfillmentDataRequest.contractStartDate = moment(this.serviceContractStartDate as Date).format(DATE_FORMAT);
        editServiceProductFulfillmentDataRequest.contractEndDate = moment(this.serviceContractEndDate as Date).format(DATE_FORMAT);
        editServiceProductFulfillmentDataRequest.plannedStartDate = moment(this.plannedStartdate.value as Date).format(DATE_FORMAT);
        editServiceProductFulfillmentDataRequest.plannedEndDate = moment(this.servicePlannedEndDate as Date).format(DATE_FORMAT);
        if (this.actualStartdate && this.actualStartdate.value) {
            editServiceProductFulfillmentDataRequest.actualStartDate = moment(this.actualStartdate.value as Date).format(DATE_FORMAT);
            editServiceProductFulfillmentDataRequest.actualEndDate = moment(this.serviceActualEndDate as Date).format(DATE_FORMAT);
        }

        // Sends a request if any field has been modified
        // Run disconnected date change process for customer services where date is updated
        this.isUpdatingServiceProductFulfillmentDate = true;
        this.wbsService.updateServiceProductFulfillmentDate(editServiceProductFulfillmentDataRequest, this.selectedService.id).then((response: any) => {
            this.fxpMessageService.addMessage("Service Product Fulfilment Date Change has been successfully completed.", this.FXP_CONSTANTS.messageType.success, false);
            this.isUpdatingServiceProductFulfillmentDate = false;
            this.closeModal();
        }).catch((error) => {
            let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
            failureMessage = failureMessage.replace("#", this.selectedService.name);
            this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
            this.logError(SourceConstants.Method.UpdateServiceDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.isUpdatingServiceProductFulfillmentDate = false;
            this.isUpdateActive = false;
        });
    }


    /**
     * Toggles if the view resource is enabled
     */
    public toggleResources(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.ToggleResources, this.viewResourceEnable ? LogEventConstants.EBSEditToggleResourceCollapse : LogEventConstants.EBSEditToggleResourceExpand);
        this.viewResourceEnable = !this.viewResourceEnable;
    }

    /**
     * Converts simple words to have an S at the end if the counter has a value greater than 1.
     */
    public getWordPluralWithS(word: string, count: number, caps: boolean): string {
        return this.sharedFunctionsService.getWordPluralWithS(word, count, caps);
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     */
    public onStartDateChange(startDate: Date): void {
        this.serviceStartDate.setValue(startDate);
        this.isServiceStartDateValid();
    }

    /**
     * Checks validity of the start date by checking with GRM API
     */
    public isServiceStartDateValid(): void {
        const staffingResponse: IStaffingTaskModel[] = [];
        this.updateLevel.setValue(EnumUpdateLevel.serviceLevel);
        this.isStartDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableServiceOnlyOption = false;
        if (!this.serviceStartDate.value) {
            this.isStartDateRequired = true;
            return;
        }

        this.isBeforeProjectStartDate = false;
        this.isAfterChildTaskStartDate = false;

        if (moment(this.serviceStartDate.value).isBefore(this.projectStartDate)) {
            this.isBeforeProjectStartDate = true;
            return;
        }

        let returnValue = true;
        this.noOfConflictResources = this.filterResourcesBasedOnDates(true, this.serviceStartDate.value, staffingResponse);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.serviceEndDate.value) {
                this.noOfConflictResources = this.filterResourcesBasedOnDates(false, this.serviceEndDate.value, staffingResponse);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
            this.showConflictingResources = false;
        }
        this.showActualsStartDateConflictMessage = false;
        if (this.selectedService.minDate && moment(this.serviceStartDate.value).isAfter(this.selectedService.minDate)) {
            this.showActualsStartDateConflictMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return;
        }

        if (!this.isEndDateGreaterThanStartDate(this.serviceStartDate.value, this.serviceEndDate.value)) {
            return;
        }

        if (!this.validateLowerLevelConflicts(this.serviceStartDate.value, this.serviceEndDate.value)) {
            return;
        }
        this.showCascadeRadioButtons = this.wbsService.haveDatesChanged(this.selectedService.startDate, this.serviceStartDate.value)
            || this.wbsService.haveDatesChanged(this.selectedService.endDate, this.serviceEndDate.value);
    }

    /**
     * Capturing emitted end date value
     *
     * @param {Date} endDate
     */
    public onEndDateChange(endDate: Date): void {
        this.serviceEndDate.setValue(endDate);
        this.isServiceEndDateValid();
    }

    /**
     * Checks validity of the end date by checking with GRM API
     */
    public isServiceEndDateValid(): void {
        const staffingResponse: IStaffingTaskModel[] = [];

        this.updateLevel.setValue(EnumUpdateLevel.serviceLevel);
        this.isEndDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableServiceOnlyOption = false;
        if (!this.serviceEndDate.value) {
            this.isEndDateRequired = true;
            return;
        }

        this.isAfterProjectEndDate = false;
        this.isBeforeChildTaskEndDate = false;

        if (moment(this.serviceEndDate.value).isAfter(this.projectEndDate)) {
            this.isAfterProjectEndDate = true;
            return;
        }

        let returnValue = true;
        this.noOfConflictResources = this.filterResourcesBasedOnDates(false, this.serviceStartDate.value, staffingResponse);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.serviceStartDate.value) {
                this.noOfConflictResources = this.filterResourcesBasedOnDates(true, this.serviceStartDate.value, staffingResponse);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
            this.showConflictingResources = false;
        }
        this.showActualsEndDateConflictMessage = false;
        if (this.selectedService.maxDate && moment(this.serviceEndDate.value).isBefore(this.selectedService.maxDate)) {
            this.showActualsEndDateConflictMessage = true;
            this.showCascadeRadioButtons = true;
            this.disableServiceOnlyOption = true;
            returnValue = false;
        }
        this.showIODateValidationMessage = false;
        const userStatusCodeArray: string[] = this.selectedService.userStatusCode.split("/");
        /// check if the service is of type BIF [using statuscode] and if enddate is greater than IO end date throw this validation
        if (userStatusCodeArray
            && userStatusCodeArray.length
            && (userStatusCodeArray.indexOf("IND") > -1)
            && this.ioEndDate
            && moment(this.serviceEndDate.value).isAfter(this.ioEndDate)) {
            this.showIODateValidationMessage = true;
            returnValue = false;
        }

        if (!returnValue) {
            return;
        }

        if (!this.validateLowerLevelConflicts(this.serviceStartDate.value, this.serviceEndDate.value)) {
            return;
        }
        this.showCascadeRadioButtons = this.wbsService.haveDatesChanged(this.selectedService.startDate, this.serviceStartDate.value)
            || this.wbsService.haveDatesChanged(this.selectedService.endDate, this.serviceEndDate.value);
        this.isEndDateGreaterThanStartDate(this.serviceStartDate.value, this.serviceEndDate.value);
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     */
    public onPlannedStartDateChange(plannedDate: Date): void {
        this.plannedStartdate.setValue(plannedDate);
        this.isPlannedStartDateRequired = false;
        this.isPlannedStartDateValid = true;
        if (!this.plannedStartdate.value) {
            this.isPlannedStartDateRequired = true;
            return;
        }
        const currentDate = new Date();
        if (!moment(this.plannedStartdate.value).startOf("day").isSameOrAfter(moment(currentDate).startOf("day"))) {
            this.isPlannedStartDateValid = false;
            return;
        }
        if (this.isProductFulfillmentTermDuration && this.isProductFulfillmentTermDuration === true) { 
            const dt = new Date(plannedDate);
            this.servicePlannedEndDate = this.calculateEndDate(dt, this.serviceProductFulfillment.termDuration, this.serviceProductFulfillment.termDurationUom);
        }
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     */
    public onActualStartDateChange(actualDate: Date): void {
        this.actualStartdate.setValue(actualDate);
        this.isActualStartDateValid = true;
        this.isServiceActualDateBeforeEngagementStartDate = false;
        const currentDate = new Date();
        if (moment(this.actualStartdate.value).isAfter(currentDate)) {
            this.isActualStartDateValid = false;
            return;
        }
        if (moment(this.actualStartdate.value).isBefore(this.engagementStartDate)) {
            this.isServiceActualDateBeforeEngagementStartDate = true;
            return;
        }
        this.updateSubmitButtonState();
        if (this.isProductFulfillmentTermDuration && this.isProductFulfillmentTermDuration === true) { 
            if (actualDate) {        
                const dt = new Date(actualDate);
                this.serviceActualEndDate = this.calculateEndDate(dt, this.serviceProductFulfillment.termDuration, this.serviceProductFulfillment.termDurationUom);
            } else {
                this.serviceActualEndDate = null;
            }
        }
    }
    
    public onCheckboxChange(event: boolean): void {
        this.enableActualStartDate = event;
        this.updateSubmitButtonState();
    }


    /**
     * Hides the error messages related to updating different date levels.
     */
    public hideErrorMessages(): void {
        if (this.updateLevel.value === EnumUpdateLevel.allLowerLevels) {
            this.isAfterChildTaskStartDate = false;
            this.isBeforeChildTaskEndDate = false;
        }
    }

    /**
     * Logs an event when employee link is clicked
     * @param link
     */
    public logEmployeeClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.LogEmployeeClick, LogEventConstants.EmployeeLinkClick);
    }


    /**
     * Gets the earliest task start date from the tasks under the selected serive
     */
    private getMinTaskStartDate(): Date {
        let firstTaskChildStartDate: Date = this.selectedService.tasks[0].startDate;
        for (const task of this.selectedService.tasks) {
            if (moment(task.startDate).isBefore(firstTaskChildStartDate)) {
                firstTaskChildStartDate = task.startDate;
            }
        }
        return firstTaskChildStartDate;
    }

    /**
     * Gets the latest task end date from the task under the selected service
     */
    private getMaxTaskEndDate(): Date {
        let lastTaskChildEndDate: Date = this.selectedService.tasks[0].endDate;
        for (const task of this.selectedService.tasks) {
            if (moment(task.endDate).isAfter(lastTaskChildEndDate)) {
                lastTaskChildEndDate = task.endDate;
            }
        }
        return lastTaskChildEndDate;
    }

    /**
     * Is the given end date later than the given start date? True if the end date is later/greater than the start date.
     * False if the end date is earlier/less than the start date.
     */
    private isEndDateGreaterThanStartDate(startDate: Date, endDate: Date): boolean {
        if (startDate && endDate) {
            this.isDateCompare = false;
            if (!moment(startDate).isBefore(endDate)) {
                this.isDateCompare = true;
                return false;
            }
        }
        return true;
    }

    /**
     * Created a filtered resource view item by filtering the given staffing view model
     * @param checkForStartDate
     * @param dateModified
     * @param staffingViewModel
     */
    private filterResourcesBasedOnDates(checkForStartDate: boolean, dateModified: Date, staffingViewModel: IStaffingTaskModel[]): number {
        let resourcesWithConflict = 0;
        if (staffingViewModel && staffingViewModel.length) {
            if (!staffingViewModel[0].isPresignature) { // filters the staffingViewModel if the task is not presignature
                this.filteredResourceView = staffingViewModel.filter((object) => object.taskServiceId === this.selectedService.id);
            } else { // sets the staffingViewModel as the filtered resource view
                Object.assign(this.filteredResourceView, staffingViewModel);
            }
            this.filteredResourceView.forEach((taskDetails) => {
                taskDetails.demands.forEach((demandDetails, index) => {
                    let resourceList: IStaffingResource[] = [];
                    if (checkForStartDate) {
                        resourceList = demandDetails.resources
                            .filter((resourceObject: IStaffingResource) => {
                                return (moment(resourceObject.resourceStartDate).isBefore(dateModified))
                                    && (this.conflictResourceStatus.indexOf(resourceObject.resourceStatus) > 0);
                            });
                    } else {
                        resourceList = demandDetails.resources
                            .filter((resourceObject: IStaffingResource) => {
                                return (moment(resourceObject.resourceEndDate).isAfter(dateModified))
                                    && (this.conflictResourceStatus.indexOf(resourceObject.resourceStatus) > 0);
                            });
                    }
                    resourcesWithConflict = resourcesWithConflict + resourceList.length;
                    taskDetails.demands[index].resources = resourceList;
                });
                taskDetails.demands = taskDetails.demands.filter((demandDetails: IStaffingDemandModel) => demandDetails.resources.length > 0);
            });
            this.filteredResourceView = this.filteredResourceView.filter((object: IStaffingTaskModel) => object.demands.length > 0);
        }
        return resourcesWithConflict;
    }

    /**
     * Validates lower level conflicts by confirming the start and end dates are between the min and max dates.
     * @param startDate
     * @param endDate
     */
    private validateLowerLevelConflicts(startDate: Date, endDate: Date): boolean {
        let isValid = true;
        if (this.selectedService.tasks && this.selectedService.tasks.length) {
            if (!this.childTaskEndDate) {
                this.childTaskEndDate = this.getMaxTaskEndDate();
            }
            // is service's end date >= task's end date
            if (moment(endDate).isBefore(this.childTaskEndDate) && this.updateLevel.value === EnumUpdateLevel.serviceLevel) {
                this.isBeforeChildTaskEndDate = true;
                isValid = false;
            }
            if (!this.childTaskStartDate) {
                this.childTaskStartDate = this.getMinTaskStartDate();
            }
            if (moment(startDate).isAfter(this.childTaskStartDate) && this.updateLevel.value === EnumUpdateLevel.serviceLevel) {
                this.isAfterChildTaskStartDate = true;
                isValid = false;
            }
        }
        if (this.isBeforeChildTaskEndDate || this.isAfterChildTaskStartDate) {
            this.isBeforeChildTaskEndDate = false;
            this.isAfterChildTaskStartDate = false;
            this.showCascadeRadioButtons = true;
            this.disableServiceOnlyOption = true;
            this.updateLevel.setValue(EnumUpdateLevel.allLowerLevels);
            isValid = true;
        }
        return isValid;
    }

    /**
     * Initializes edit service form data
     */
    private initializeEditServiceDetailsForm(): void {
        this.editServiceDetailsForm = this.fb.group({
            serviceStartDate: [this.selectedService.startDate, [Validators.required]],
            serviceEndDate: [this.selectedService.endDate, [Validators.required]],
            plannedStartdate: [this.selectedService.startDate],
            actualStartdate: [this.selectedService.endDate],
            updateLevel: [EnumUpdateLevel.serviceLevel]
        });
    }

    /**
     * Calcualted end date for service fulfillment nuance
     */
    private calculateEndDate(startDate: Date, termDuration: number, termDurationUom: string): Date {    
        if (!termDurationUom) {
            throw new Error("termDurationUom is null or undefined");
        }
        let endDate = new Date(startDate);
        if (termDuration > 0) {
            if (termDurationUom === "MO") {
                endDate = new Date(endDate.setMonth(endDate.getMonth() + termDuration));    
                endDate.setDate(endDate.getDate() - 1);
            } else if (termDurationUom === "DAY") {
                endDate = new Date(endDate.setDate(endDate.getDate() + termDuration));    
                endDate.setDate(endDate.getDate());
            } else {
                throw new Error("Unexpected termDurationUom value: ${termDurationUom}");
            }
        } else {
            endDate.setDate(startDate.getDate());
        }
        return endDate;
    }

    private updateSubmitButtonState(): void {
        if (this.enableActualStartDate && this.actualStartdate.value) {
            this.isSubmitButtonEnabledFromCheckBoxForActualStartDate = true;
        } else {
            this.isSubmitButtonEnabledFromCheckBoxForActualStartDate = false;
        }

        if (this.actualStartdate === null || this.actualStartdate === undefined || this.actualStartdate.value === null || this.actualStartdate.value === undefined) {
            this.isSubmitButtonEnabledFromCheckBoxForActualStartDate = true;
            this.isShowActualStartDateCheckBox = false;
        } else if (this.actualStartdate.value) {
            this.isShowActualStartDateCheckBox = true;
        }
    }
}
