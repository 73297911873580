import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { ActivateEngagementModalModule } from "./activate-engagement-modal/activate-engagement-modal.module";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMAuthorizationService } from "../../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { FinancialPlanComponent } from "./financial-plan.component";
import { FinancialService } from "../../../../common/services/financial.service";
import { ProjectDetailService } from "../../../../common/services/project-detail.service";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { EditRiskReserveModalModule } from "./edit-risk-reserve-modal/edit-risk-reserve-modal.module";

@NgModule({
    declarations: [
        FinancialPlanComponent
    ],
    imports: [
        ActivateEngagementModalModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        EditRiskReserveModalModule,
        UIRouterModule
    ],
    providers: [
        DMAuthorizationService,
        DMLoggerService,
        DMNotificationService,
        EngagementDetailService,
        FinancialService,
        ProjectDetailService,
        ProjectServiceFunctions,
        SharedFunctionsService
    ],
    entryComponents: [
        FinancialPlanComponent
    ],
    exports: [
        FinancialPlanComponent
    ]
})
export class FinancialPlanModule { }
