<section class="unapproved-dailog">
    <label class="sr-only">Unapproved Labor</label>
    <section class="modal-header">
        <section class="pull-right">
            <a [href]="msApprovalsUrl" target="_blank" class="dm-link pull-left" id="accessibilityConstants.ViewApprovals"
                (keydown)="moveFocusPrev($event, 'accessibilityConstants.CloseButton')" aria-label="open link in new tab">
                <span class="icon icon-Forward mar-r-8"></span>
                View approvals in MS Approvals
            </a>
            <button type="button" class="close set-focus" (click)="closeModal()" id="accessibilityConstants.ClosePopUp"
                aria-label="Unapproved Labor Dialog close">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </section>
        <h4 id="unapprovedLaborHeading" class="modal-title font-subtitle pull-left" aria-hidden="true">
            Unapproved Labor
        </h4>
    </section>

    <!-- <dm-loading [loadingText]="'Getting Unapproved Labor Details...'" [showLoading]="showLoading" *ngIf="isLoading"></dm-loading> -->

    <section class="modal-body">
        <section class="labor-filter" *ngIf="unapprovedLaborList.length > 0">
            <section class="tile--no-pad">
                <section class="section__wrapper">
                    <section class="input__label">
                        <label class="input__label--text font-caption-alt" for="project-filter">View projects</label>
                        <section class="input__select--icon">
                            <select id="project-filter" class="input__select input__select--big font-caption-alt"
                                [(ngModel)]="selectedProject" [disabled]="selectedProjectDisabled"
                                [ngClass]="{'disabled-class': selectedProjectDisabled}">
                                <option [ngValue]="defaultProject">View all projects</option>
                                <option [ngValue]="item" *ngFor="let item of engagementDetails.projects">
                                    {{item.name}}</option>
                            </select>
                            <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                        </section>
                    </section>
                </section>
                <section class="section__wrapper">
                    <section class="input__label">
                        <label class="input__label--text font-caption-alt" for="resource-filter">View resources</label>
                        <section class="input__select--icon">
                            <select id="resource-filter" class="input__select input__select--big font-caption-alt"
                                [(ngModel)]="selectedResource">
                                <option value="">View all resources</option>
                                <option [ngValue]="resource" *ngFor="let resource of resources">{{resource}}
                                </option>
                            </select>
                            <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <section class="labor-table" *ngIf="unapprovedLaborList.length > 0">
            <div>
                <caption class="sr-only" id="unapprovedLaborTable">Unapproved Labor</caption>
                <table width="100%" aria-labelledby="unapprovedLaborTable">
                    <thead class="labor-table--thead">
                        <tr>
                            <th scope="col" class="text-left font-caption-alt" colspan="4">Submitter</th>
                            <th scope="col" class="text-right font-caption-alt">Hours</th>
                        </tr>
                    </thead>
                    <tbody class="labor-table--tbody"
                        *ngFor="let lbrdata of unapprovedLaborList | filter: (selectedProject ? {projectId : selectedProject.id} : undefined) ">
                        <tr class="project-title">
                            <th scope="row" colspan="4">
                                Project: <span [textContent]="lbrdata.projectName"></span>
                            </th>
                            <td class="text-right">
                                <span [textContent]="lbrdata.totalHours"></span>
                            </td>
                        </tr>
                        <ng-container *ngFor="let lbr of lbrdata.laborWeeklyData">
                            <tr class="week-detail lbr-data">
                                <th scope="row" class="font-caption-alt" colspan="4">
                                    Week <span [textContent]="lbr.weekNumber"></span> :
                                    <span [textContent]="(lbr.weekStartDate) + ' through ' + (lbr.weekEndDate) "></span>
                                </th>
                                <td class="font-caption-alt text-right">
                                    <span [textContent]="lbr.totalHours"></span>
                                </td>
                            </tr>
                            <tr *ngFor="let res of lbr.laborDetails | filter: (selectedResource ? {resourceAlias : selectedResource} : undefined)"
                                class="font-caption-alt resource-content">
                                <th scope="row" class="text-left" colspan="4">
                                    <section class="labor-table--role-wrapper">
                                        <section class="labor-table--user-photo-wrapper">
                                            <section class="userPhoto userPhoto--dashed-border"
                                                *ngIf="!res.resourceImage">
                                                <span class="icon icon-user icon--big"></span>
                                            </section>
                                            <section class="userPhoto" *ngIf="res.resourceImage">
                                                <img [src]="res.resourceImage" alt="res.resourceRole">
                                            </section>
                                        </section>
                                        <section class="font-caption-alt labor-table--role-details">
                                            <span> {{ res.resourceName ? res.resourceName : res.resourceAlias }}</span>
                                            <span class="role-text" [textContent]="res.resourceRole"></span>
                                        </section>
                                    </section>
                                </th>
                                <td class="text-right">
                                    <span [textContent]="res.laborHours"></span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </section>
        <dm-no-data *ngIf="(unapprovedLaborList | filter: (selectedProject ? {projectId : selectedProject.id} : undefined)).length === 0" [noDataText]="noPendingLabor"></dm-no-data>
    </section>

    <section class="modal-footer footer-button">
        <button type="button" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.CloseButton"
            (keydown)="moveFocusNext($event, 'accessibilityConstants.ViewApprovals')" (click)="closeModal()">Close</button>
    </section>
</section>