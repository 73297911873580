import { Component, Input, Inject } from "@angular/core";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { IProcessNotification } from "../../../common/services/contracts/process-notifications.contracts";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../store/reducers";
import { UserInfoService, FxpEventBroadCastService } from "@fxp/fxpservices";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { Components, BroadcastEvent, BusinessTask } from "../../../common/application.constants";
import { UpdateWriteOperationNotifications } from "../../../store/write-operation-notifications/write-operation-notifications.action";
import { RemoveNotification } from "../../../store/notification-subscriptions/notification-subscriptions.action";
import { getWriteOperationNotificationByInstanceId } from "../../../store/write-operation-notifications/write-operation-notifications.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { NotificationType } from "../../../common/services/contracts/notification-bar.contracts";
import { InvalidateFinancialDetailsV2 } from "../../../store/financial-details-v2/financial-details-v2.action";
import { InvalidateEngagementDetails } from "../../../store/engagement-details/engagement-details.action";
import { InvalidateWbsStructures } from "../../../store/wbs-structures/wbs-structures.action";
import moment from "moment";
import { InvalidateProjectDetails } from "../../../store/project-details/project-details.action";

@Component({
    selector: "dm-date-change-notification",
    templateUrl: "./date-change-notification.component.html",
    styleUrls: ["./date-change-notification.component.scss"]
})
export class DateChangeNotificationComponent extends DmComponentAbstract {

    @Input() public subscriptionId: string;
    @Input() public entityId: string;

    public operation: IProcessNotification;
    public timeAgo: string;
    public showCloseOption: boolean;
    private loggedInUserData: any;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(Store) private store: Store<IState>,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(FxpEventBroadCastService) private fxpBroadCastService: FxpEventBroadCastService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
    ) {
        super(dmLogger, Components.DateChangeNotification);
    }

    public ngOnInit(): void {
        this.loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        this.notificationService.subscribeToBulkInternalEngagementStatus({ GroupName: this.subscriptionId });

        // make get call for status
        this.configManagerService.initialize().then(() => {
            this.notificationService.getNotificationStatus(this.subscriptionId, NotificationType.DateChange).then((notification: IProcessNotification) => {
                if (notification) {
                    this.showCloseOption = true;
                    this.store.dispatch(new UpdateWriteOperationNotifications(notification));
                    this.refreshDateChangeDone(notification, this.operation);
                    const oldOperationsObject = this.operation;
                    this.operation = notification;

                    if (this.operation && this.operation.LastUpdateTime) {
                        const stillUtc = moment.utc(this.operation.LastUpdateTime).toDate();
                        this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                    }

                    if (!oldOperationsObject || !oldOperationsObject.isItemExpanded || (!oldOperationsObject.Done && this.operation.Done)) {
                        this.operation.isItemExpanded = false;
                    } else {
                        this.operation.isItemExpanded = oldOperationsObject.isItemExpanded;
                    }
                } else {
                    this.store.dispatch(new RemoveNotification(this.loggedInUserData.alias, this.subscriptionId));
                }
            });
        });

        const notifications$ = this.store.select(getWriteOperationNotificationByInstanceId(this.subscriptionId));
        notifications$.pipe(untilDestroyed(this)).subscribe((dateChangeNotification: IProcessNotification) => {
            if (dateChangeNotification) {
                this.showCloseOption = true;
                this.refreshDateChangeDone(dateChangeNotification, this.operation); // do we want to refresh on date change completion?
                const oldOperationsObject = this.operation;
                this.operation = dateChangeNotification;

                if (this.operation && this.operation.LastUpdateTime) {
                    const stillUtc = moment.utc(this.operation.LastUpdateTime).toDate();
                    this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                }

                if (!oldOperationsObject || !oldOperationsObject.isItemExpanded || (!oldOperationsObject.Done && this.operation.Done)) {
                    this.operation.isItemExpanded = false;
                } else {
                    this.operation.isItemExpanded = oldOperationsObject.isItemExpanded;
                }
            }
        });
    }

    /**
     * Close and remove notification subscription for the current user.
     *
     * @memberof ReleaseActivateNotificationComponent
     */
    public closeNotification(): void {
        this.showCloseOption = false;
        const loggedInUserAlias = this.fxpUserInfoService.getCurrentUserData().alias;
        this.notificationService.deleteNotificationSubscription(loggedInUserAlias, this.subscriptionId);
    }

    /**
     * Toggle whether notification is collapsed or expanded.
     *
     * @memberof DateChangeNotificationComponent
     */
    public toggleProcessNotificationItem(): void {
        this.operation.isItemExpanded = !this.operation.isItemExpanded;
    }

    /**
     * Trigger refresh if old operation was not done and new operation is done.
     *
     * @private
     * @param {IProcessNotification} newOperation
     * @param {IProcessNotification} oldOperation
     * @memberof DateChangeNotificationComponent
     */
    private refreshDateChangeDone(newOperation: IProcessNotification, oldOperation: IProcessNotification): void {
        if (newOperation && oldOperation && newOperation.InstanceId === oldOperation.InstanceId) {
            if (newOperation.Done && !oldOperation.Done) {
                let engagementId: string;
                let projectId: string;
                if (newOperation.WbsId && newOperation.WbsId.length > 12) {
                    engagementId = newOperation.WbsId.substring(0, 12);
                    if (newOperation.WbsId.length >= 19) {
                        projectId = this.getProjectId(newOperation.WbsId);
                    }
                } else {
                    engagementId = newOperation.WbsId;
                }

                this.store.dispatch(new InvalidateFinancialDetailsV2(engagementId));
                this.store.dispatch(new InvalidateEngagementDetails(engagementId));
                this.store.dispatch(new InvalidateWbsStructures(engagementId));
                if (projectId !== "" || projectId !== undefined) {
                    this.store.dispatch(new InvalidateProjectDetails(projectId));

                    // TODO: This hack has been done because for some reason in manage ebs project, we store the data in the engagementdetails store slice. This should be corrected once we move data to its correct place in the store
                    this.store.dispatch(new InvalidateEngagementDetails(projectId));
                }
            }
            if (!newOperation.Done && newOperation.Attempt === 3 && oldOperation.Attempt === 2) {
                /* if its a third attempt for retry send an email notification */
                this.fxpBroadCastService.emit(BroadcastEvent.SendFailureNotification, { engagementId: newOperation.WbsId });
            }
        }
    }

    private getProjectId(wbsId: string): string {
        // Return projectId from service/taskId
        return wbsId.substring(0, 16).concat("000");
    }

}
