import { Component, forwardRef, Inject, Output, EventEmitter } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { Components } from "../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";
import { ProjectService } from "../../../common/services/project.service";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "dm-actuals-notes-modal",
    templateUrl: "./actuals-notes-modal.html",
    styleUrls: ["./actuals-notes-modal.scss"]
})
export class ActualsNotesModalComponent extends DmModalAbstract {

    @Output() public onSubmitNotes: EventEmitter<void> = new EventEmitter<void>();
    public actualNotesForm: FormGroup;
    public modalContent: IModal;

    public get actualNotes(): AbstractControl {
        return this.actualNotesForm.get("actualNotes");
    }

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ProjectService) public projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FormBuilder) private fb: FormBuilder,
    ) {
        super(activeModal, dmLogger, Components.UnitTransactionDetails);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Edit Notes"
        };

        this.actualNotesForm = this.fb.group({
            actualNotes: ["", Validators.required],
        });
    }

    /**
     * On submit notes emit notes to actuals.
     *
     * @memberof ActualsNotesModalComponent
     */
    public submitNotes(): void {
        if (this.actualNotesForm.valid) {
            this.onSubmitNotes.emit(this.actualNotes.value);
            this.activeModal.close();
        }
    }
}

