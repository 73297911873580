import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { InvoiceFilterComponent } from "./invoice-filter.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        InvoiceFilterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService
    ],
    exports: [
        InvoiceFilterComponent
    ],
    entryComponents: [
        InvoiceFilterComponent
    ]
})
export class InvoiceFilterModule { }
