import * as $ from "jquery";

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { PageChangeInformation } from "../../../../common/application.constants";

@Component({
    selector: "dm-pagination-v2",
    templateUrl: "./dm-pagination-v2.html",
    styleUrls: ["dm-pagination-v2.scss"]
})
export class PaginationV2Component implements OnInit {
    @Input() public pageSize: number;
    @Input() public currentPageNumber: number;
    @Input() public totalItemCount: number;
    @Input() public itemsPerPage: number[];
    @Output() public pageChanged: EventEmitter<number> = new EventEmitter();
    @Output() public pageSizeChanged: EventEmitter<number> = new EventEmitter();
    public itemPerPageValue: number = 5;
    public displayItems: string;
    public titleForNavigateToPreviousPage: string;
    public titleForNavigateToNextPage: string;
    public titleForNavigateToFirstPage: string;
    public titleForNavigateToLastPage: string;
    public ariaLabelForItemsPerPageBox: string;
    private initialItemValue: number;
    private finalItemValue: number;
    private pageChangeInformation: string;


    public ngOnInit(): void {
        this.itemPerPageValue = this.pageSize;
        this.titleForNavigateToPreviousPage = "Previous button to traverse previous records";
        this.titleForNavigateToNextPage = "Next button to traverse further records";
        this.titleForNavigateToFirstPage = "Traverse to first page";
        this.titleForNavigateToLastPage = "Traverse to last page";
        this.ariaLabelForItemsPerPageBox = "change items per page";
    }

    public ngOnChanges(): void {
        this.itemsDisplay(this.pageSize);
    }

    /**
     * Get the number of pages based on the length filter and number of items displayed
     */
    public numberOfPages(): number {
        return Math.ceil(this.totalItemCount / this.itemPerPageValue);
    }

    /**
     * Move to previous page of results
     * @param event
     */
    public prevPage(event: MouseEvent, isFirstPage: boolean): void {
        this.currentPageNumber = isFirstPage ? 1 : this.currentPageNumber;
        if (this.currentPageNumber > 1) {
            this.currentPageNumber = Number(this.currentPageNumber) - 1;
            this.pageChangeInformation = PageChangeInformation.PreviousButton;
        }
        if (this.currentPageNumber === 1) {
            setTimeout(() => {
                $(event.target).closest(".paginationSection").find(".dmpagenext").focus();
            });
        }
        this.pageChanged.emit(this.currentPageNumber);
    }


    /**
     * Move to next page of results
     * @param event
     */
    public nextPage(event: MouseEvent, isLastPage: boolean): void {
        this.currentPageNumber = isLastPage ? this.numberOfPages() : this.currentPageNumber;
        if (this.currentPageNumber < this.numberOfPages()) {
            this.currentPageNumber = Number(this.currentPageNumber) + 1;
            this.pageChangeInformation = PageChangeInformation.NextButton;
        }
        if (this.currentPageNumber >= this.numberOfPages()) {
            this.currentPageNumber = this.numberOfPages();
            setTimeout(() => {
                $(event.target).closest(".paginationSection").find(".dmpageprev").focus();
            });
        }
        this.pageChanged.emit(this.currentPageNumber);
    }

    /**
     * item select from dropdown value
     * 
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public itemSelectedPerPage(event: any): void {
        this.itemPerPageValue = event.target.value;
        if (this.currentPageNumber > this.numberOfPages()) {
            this.currentPageNumber = this.numberOfPages();
        }
        this.prevPage(event, true);
        this.pageSizeChanged.emit(this.itemPerPageValue);
    }

    /**
     * sets aria label on page change
     */
    public setArialLabelOnPageChange(): string {
        return "Page " + this.currentPageNumber + " of " + this.numberOfPages() + " " + this.pageChangeInformation;
    }

    /**
     * Reset back to page current page 1 in instances of impossible paging
     */
    public pageNumberChange(): void {
        if (this.currentPageNumber <= 0 || isNaN(this.currentPageNumber) || (this.currentPageNumber > this.numberOfPages())) {
            this.currentPageNumber = 1;
            this.pageChanged.emit(this.currentPageNumber);
        }
    }

    /**
     * Displays key buttons of Next and last
     */
    public disableLastPage(): boolean {
        return this.currentPageNumber >= this.totalItemCount / this.itemPerPageValue;
    }

    /**
     * Disables key buttons of Previous
     */
    public disableFirstPage(): boolean {
        return this.currentPageNumber === 1;
    }

    /**
     * Displays page initial and last value of a page 
     */
    public itemsDisplay(itemsPerPage: number): void {
        this.itemPerPageValue = itemsPerPage;
        this.initialItemValue = ((this.currentPageNumber - 1) * this.itemPerPageValue + 1);
        this.finalItemValue = (this.currentPageNumber * this.itemPerPageValue) > this.totalItemCount ? this.totalItemCount : (this.currentPageNumber * this.itemPerPageValue);
        this.displayItems = this.initialItemValue + " - " + this.finalItemValue + " of " + this.totalItemCount + " Items";
    }

    /**
     * Page changes on pressing keys up and down 
     */

    public changePageOnKeyUpdown(): void {
        if (this.currentPageNumber && this.currentPageNumber !== null && this.currentPageNumber > 0) {
            if (this.currentPageNumber > this.numberOfPages()) {
                this.currentPageNumber = this.numberOfPages();
            }
            this.pageChanged.emit(this.currentPageNumber);
        }
    }

    /**
     * input requires integer value not decimal values
     */
    public isRequiredIntegerValue(event: KeyboardEvent): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode >= 48 && charCode <= 57) {
            return true;
        } else {
            return false;
        }
    }
}