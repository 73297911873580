import { Component, Inject } from "@angular/core";
import { UIRouter } from "@uirouter/angular";
import { RouteName } from "../../common/application.constants";

@Component({
    selector: "dm-project-detail",
    styleUrls: ["./project-detail.scss"],
    templateUrl: "./project-detail.html"
})
export class ProjectDetailComponent {
    public readonly routeName = RouteName;
    public constructor(@Inject(UIRouter) public uiRouter: UIRouter) { }
}
