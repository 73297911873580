import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { MyPortfolioComponent } from "./my-portfolio.component";
import { InternalEngagementGridDataV2Module } from "../internal-engagement/internal-engagement-grid-data-v2/internal-engagement-grid-data-v2.module";
import { RecentlyViewedModule } from "../recently-viewed/recently-viewed.module";
import { KeyActionsV2Module } from "../tiles/key-actions-v2/key-actions-v2.module";
import { CustomerEngagementGridDataModule } from "./tiles/grid-data/customer-engagement-grid-data.module";

@NgModule({
    declarations: [
        MyPortfolioComponent
    ],
    imports: [
        CommonModule,
        CustomerEngagementGridDataModule,
        InternalEngagementGridDataV2Module,
        RecentlyViewedModule,
        KeyActionsV2Module
    ],
    providers: [
        DMLoggerService,
    ],
    entryComponents: [
        MyPortfolioComponent
    ],
    exports: [
        MyPortfolioComponent
    ],
})
export class MyPortfolioModule { }
