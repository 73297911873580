import { forwardRef, Inject, Component, Input } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IEngagementList, IInternalEngagementFinancialsListV2 } from "../../../../common/services/contracts/portfolio.model";
import { IState } from "../../../../store/reducers";
import { RouteName, Components } from "../../../../common/application.constants";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ProjectService } from "../../../../common/services/project.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { getEntireInternalFinancialDetails } from "../../../../store/internal-financial-details/intenal-financial-details.selector";
import { IInternalFinancialDetailsState } from "../../../../store/internal-financial-details/internal-financial-details.reducer";
import { LoadInternalFinancialDetails } from "../../../../store/internal-financial-details/internal-financial-details.action";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "dm-internal-engagement-financial-modal",
    templateUrl: "internal-engagement-financial-modal.html",
    styleUrls: ["./internal-engagement-financial-modal.scss"]
})
export class InternalEngagementFinancialModalComponent extends DmModalAbstract {
    @Input() public engagementId: string;
    public engagementList: IEngagementList[];
    public engagementFinancialList: IInternalEngagementFinancialsListV2[];
    public noEngagementFoundText: string;
    public RouteName = RouteName;
    public loadingText = "Financials";
    public ariaLabelForCloseBtn: string = "Financials Summary Dialog Close";
    public ariaLabelForFinancialModal: string = "Internal Engagement Financials Overview";
    public tableCaption: string = "Internal Engagement Financials Overview Table";

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) private deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal
    ) {
        super(activeModal, dmLogger, Components.PortfolioInternalEngagementFinancialsTab);
    }

    public ngOnInit(): void {
        this.getInternalEngagementFinancialsList();
    }

    /**
     * Sets the id for entity name
     */
    public setIdForEntityName(entityType: string, id: string): string {
        return entityType + "-" + id + "-financials";
    }

    /**
     * gets list of financial data for internal engagement.
     *
     * @private
     * @memberof GridDataComponent
     */
    private getInternalEngagementFinancialsList(): void {
        this.engagementFinancialList = [];
        const financialDetails$ = this.store.select(getEntireInternalFinancialDetails(this.engagementId));
        financialDetails$.pipe(untilDestroyed(this)).subscribe((financialDetails: IInternalFinancialDetailsState) => {
            this.setLoadersBasedOnItemState(financialDetails);
            if (financialDetails.loaded) {
                this.engagementFinancialList = financialDetails.financialDetails;
            }
            if (!financialDetails.loading && !financialDetails.loaded && !financialDetails.error) {
                /**
                 * Triggering the dispatch [load call], only after checking that data does not exist in the store,
                 * as this is a modal popup we want to leverage if it already exists in the store.
                 */
                this.store.dispatch(new LoadInternalFinancialDetails(this.engagementId));
            }
        });
    }
}
