import { Component, Inject, forwardRef, Injector, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceFactoryProvider, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { AmendmentsService } from "../../../../common/services/amendments.service";
import { AccessibilityConstants, Components, SourceConstants } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { ContractType } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { DataService } from "../../../../common/services/data.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { getFinancialRolesState } from "../../../../store/financial-roles/financial-roles.selector";
import { ICompanyCode, IMisalignedAmendmentCallbackObject, IMisalignedAmendmentViewModel } from "../../amendments.contract";
import { ICostRateInfo } from "../../../../common/services/contracts/changerequest.contract";
import { IDemandDetails } from "../../../../common/services/contracts/project.service.v2.contracts";
import { IEntityFinancialSummary } from "../../../financial-mgmt/financial.model";
import { IFinancialRoles } from "../../../../common/services/contracts/projectservice-functions.contract";
import { IFinancialRolesState } from "../../../../store/financial-roles/financial-roles.reducer";
import { IState } from "../../../../store/reducers";
import { ProjectService } from "../../../../common/services/project.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";

/**
 * Used only within this component, assists with formatting the callback object.
 * Includes optional callback parameters that can be appended to the existing callback item
 * to alert upstream services about notification changes.
 */
interface ICallbackNotificationDetails {
    newDemandCreated?: boolean;
    existingDemandAssigned?: boolean;
}
@Component({
    selector: "dm-misaligned-amendment-lineitems",
    templateUrl: "./misaligned-amendment-lineitems.html",
    styleUrls: ["./misaligned-amendment-lineitems.scss"]
})
export class MisalignedAmendmentLineitemsComponent extends DmComponentAbstract implements OnChanges{

    @Input() public originalAmendment: IMisalignedAmendmentViewModel;
    @Input() public misalignedAmendment: IMisalignedAmendmentViewModel;
    @Input() public taskIds: string[] = [];
    @Input() public pairId: string;
    @Input() public projectType: ContractType;
    @Input() public existingDemands: IDemandDetails[];
    @Input() public engagementSubmitted: boolean;
    @Input() public hasEditAccess: boolean = false;
    @Input() public isSplittedLineItem: boolean = false;
    @Input() public isSplitOptionDisabled: boolean = false;
    @Output() public amendmentPairSelected = new EventEmitter<IMisalignedAmendmentCallbackObject>();
    @Output() public newExistingDemandAssigned = new EventEmitter<IMisalignedAmendmentCallbackObject>();
    @Output() public onChange = new EventEmitter<IMisalignedAmendmentCallbackObject>();
    @Output() public splitDemand = new EventEmitter<IMisalignedAmendmentCallbackObject>();
    @Output() public deleteSplittedDemand = new EventEmitter<IMisalignedAmendmentCallbackObject>();
    @Output() public changeExistingDemand = new EventEmitter<IMisalignedAmendmentCallbackObject>();    

    public currentFinancialDetails: IEntityFinancialSummary;
    public loadingAmendmentsText: string;
    public roleIds: Array<{ id: string }> = [];
    public originalRole: string;
    public plannedLaborHours: number;
    public selectedTaskId: { id: string };
    public formattedTaskIds: Array<{ id: string; key: string }> = [];
    public formattedRoles: IFinancialRoles[];
    public formattedBillingRoleIds: Array<{ id: string; billRate: number }> = [];
    public formattedLocationIds: Array<{ companyCode: ICompanyCode; key: string }>;
    public selectedLocation: { companyCode: ICompanyCode; key: string };
    public selectedRole: IFinancialRoles;
    public selectedBillingRole: { id: string; billRate: number };
    public isDemandChecked: boolean = false;
    public errorTitleText: string;
    public companyCodeList: ICompanyCode[];
    public ContractType: any = ContractType;
    public showPlannedLaborError: boolean = false;
    public plannedLaborErrorMessage: string = "Sum of existing and planned hours should be > 0";
    public editDisabled: boolean = true;
    public accessibilityConstants = AccessibilityConstants;
    private newDemandSet: boolean = false;
    private nonBillResourceItemId: string = "0000000000";
    private nonBillValue: string = "NON-BILL";
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) private stateService: StateService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(AmendmentsService) private amendmentsService: AmendmentsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(FxpMessageService) private fxpMessageService: FxpMessageService
    ) {
        super(dmLogger, Components.MisalignedAmendmentLineitems);
    }

    public ngOnInit(): void {
        const currentCrNumber: number = Number(this.stateService.params.amendmentId);
        this.loadingAmendmentsText = "Loading Misaligned Amendment Details for CR " + currentCrNumber;
        this.errorTitleText = this.originalAmendment ? "This task needs to align with CFP" : "Convert to a new demand";
        this.companyCodeList = this.configurationService.getValue<ICompanyCode[]>("companyCodeList");
        this.editDisabled = this.checkEditDisabled();

        this.storeDispatchService
            .requireFinancialRoles(true)
            .load();

        const financialRoles$ = this.store.select<IFinancialRolesState>(getFinancialRolesState);
        financialRoles$.subscribe((financialRolesState) => {
            if (financialRolesState.loaded) {
                const roles: IFinancialRoles[] = financialRolesState.financialRoles;
                this.formatMisalignedData(this.misalignedAmendment, this.taskIds, roles, this.existingDemands);
                this.callOnChangeEmitter();
            }
            if (financialRolesState.error) {
                const roles: IFinancialRoles[] = [];
                this.logError(SourceConstants.Method.NgOnInit, "Unable to fetch financial roles, user will be unable to make alignment changes for financial roles. Error: " + financialRolesState.error, 400);
                this.formatMisalignedData(this.misalignedAmendment, this.taskIds, roles, this.existingDemands);
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.originalAmendment) {
            const updatedOriginalAmendment = changes.originalAmendment;
            if (!this.taskIds.includes(updatedOriginalAmendment.currentValue.taskId)) {
                this.taskIds.push(updatedOriginalAmendment.currentValue.taskId);
            }
            if (this.taskIds.length !== this.formattedTaskIds.length) {                
                this.formattedTaskIds.push( {id: updatedOriginalAmendment.currentValue.taskId, key: this.formattedTasks(updatedOriginalAmendment.currentValue.taskId, updatedOriginalAmendment.currentValue.taskDescription)});
            }
        }
        this.callOnChangeEmitter();
    }

    /**
     * Shows the error for the entire lineitem. Will return true to show the error
     * if any of the other data errors is turned on. Will return false if all other errors
     * are off.
     */
    public showLineitemError(): boolean {
        const lineitemError: boolean = this.showSelectedRoleError() ||
            this.showPlannedLaborError ||
            this.showSelectedBillingRoleIdError() ||
            this.showSelectedLocationError() ||
            this.isAmendmentOrphaned();
        return lineitemError;
    }

    /**
     * Show an error for the selected task ID
     *
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public showSelectedTaskIdError(): boolean {
        const preCheck = this.errorPrecheck(); /* Returns undefined if all prechecks are satisfied, otherwise is returned */
        if (preCheck !== undefined) {
            return preCheck;
        }
        return this.originalAmendment.taskId !== this.selectedTaskId.id;
    }

    /**
     * Show an error for the selected role
     *
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public showSelectedRoleError(): boolean {
        const preCheck = this.errorPrecheck(); /* Returns undefined if all prechecks are satisfied, otherwise is returned */
        if (preCheck !== undefined) {
            return preCheck;
        }
        return this.originalAmendment.role.activityCode !== this.selectedRole.activityCode;
    }

    /**
     * Show an error for the selected billing role ID
     *
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public showSelectedBillingRoleIdError(): boolean {
        const preCheck = this.errorPrecheck(); /* Returns undefined if all prechecks are satisfied, otherwise is returned */
        if (preCheck !== undefined) {
            return preCheck;
        }
        return this.originalAmendment.billingRoleId !== this.selectedBillingRole.id;
    }

    /**
     * Show an error for the selected location.
     *
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public showSelectedLocationError(): boolean {
        const preCheck = this.errorPrecheck(); /* Returns undefined if all prechecks are satisfied, otherwise is returned */
        if (preCheck !== undefined) {
            return preCheck;
        }
        if (this.originalAmendment.resourceLocation !== undefined){
            return this.originalAmendment.resourceLocation.companyCode !== this.selectedLocation.companyCode.companyCode;

        }
        return true;
        
        
    }

    /**
     * On the task ID change. Emits an event up a level to be detected by the save feature.
     *
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public onSelectedTaskIdChange(): void {
        this.misalignedAmendment.taskId = this.selectedTaskId.id;
        this.recalculateCostRate();
        this.callOnChangeEmitter();
        // todo log
    }

    /**
     * On Role Change from the dropdown.
     */
    public onSelectedRoleChange(): void {
        this.misalignedAmendment.role = this.selectedRole;
        this.recalculateCostRate();
        this.callOnChangeEmitter();
        // todo log
    }

    /**
     * On the planned labor value change. Emits an event up a level to be detected by the save feature.
     *
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public onPlannedLaborChange(): void {
        // todo log
        this.misalignedAmendment.plannedLabor = this.plannedLaborHours;
        this.validatePlannedLaborWithCFP();
        this.recalculateAdditionalCost();
        this.recalculateAdditionalRevenue();
        this.callOnChangeEmitter();
    }

    /**
     * On Billing Role Change from the dropdown. Emits an event up a level to be detected by the save feature.
     */
    public onSelectedBillingRoleIdChange(): void {
        this.misalignedAmendment.billingRoleId = this.selectedBillingRole.id;

        // Ignore billrate change and recalculation for FF projects
        if (this.projectType === ContractType.TimeAndMaterial) {
            this.misalignedAmendment.billRate = this.selectedBillingRole.billRate;
            this.recalculateAdditionalRevenue();
        }
        this.callOnChangeEmitter();
        // todo log
    }

    /**
     * On Location Change from the dropdown. Emits an event up a level to be detected by the save feature.
     */
    public onSelectedLocationChange(): void {
        this.misalignedAmendment.resourceLocation = this.selectedLocation.companyCode;
        this.recalculateCostRate();
        this.callOnChangeEmitter();
        // todo log
    }

    /**
     * Amendment pair has been selected via the radio button. Calls back upstream to alert about the new selection.
     */
    public misalignedAmendmentRadioSelected(): void {
        this.amendmentPairSelected.emit(this.getCallbackObject());
        // todo log
    }

    /**
     * open modal pop up to Change Existing Demand
     */
    public openChangeExistingDemandModal(): void {
        this.changeExistingDemand.emit(this.getCallbackObject());
        return;
    }  


    /**
     * Creates a new demand for the line item by changing the demand Id to "new".
     *
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public createNewDemand(): void {
        this.misalignedAmendment.demandId = "New";
        this.originalAmendment = undefined;
        this.newDemandSet = true;
        this.callOnChangeEmitter({ newDemandCreated: true });
    }

    public splitDemandFn(): void {
        this.splitDemand.emit(this.getCallbackObject());
        return;
    }

    public deleteSplitDemandFn(): void {
        this.deleteSplittedDemand.emit(this.getCallbackObject());
        return;
    }

    public IsSplittedLineItem(): boolean {
        if (this.misalignedAmendment && this.isSplittedLineItem) {
            return true;
        }
        return false;
    }

    /**
     * Is a valid Create New Demand scneario
     * @param selectedAmendmentPair selectedAmendmentPair
     * @returns boolean
     */
    public isValidNewDemandScenario(): boolean {
        if (this.newDemandSet) {
            return false;
        }
        if (this.misalignedAmendment && this.isSplittedLineItem) {
            return false;
        }
        if (this.misalignedAmendment && this.misalignedAmendment.plannedLabor) {
            return this.misalignedAmendment.plannedLabor > 0;
        }
        return false;
    }

    /**
     * Validates planned labor change with CFO planned labor. Total change in demand has to be > 0
     */
    public validatePlannedLaborWithCFP(): boolean {
        if (this.originalAmendment && this.originalAmendment.plannedLabor) {
            if (this.originalAmendment.plannedLabor + this.misalignedAmendment.plannedLabor <= 0) {
                this.showPlannedLaborError = true;
                return true;
            }
            else {
                this.showPlannedLaborError = false;
                return false;
            }
        }
        return false;
    }

    /**
     * Formats the resource location
     * @param code code
     * @param company company
     * @returns formatted resource location
     */
    public formatResourceLocation(code: string, company: string): string {
        return `${code}-${company}`;
    }

    /**
     * Formats the tasks
     * @param id id
     * @param description description
     * @returns formatted task with id and description
     */
    public formattedTasks(id: string, description: string): string {
        return `${id}-${description}`;
    }

    /**
     * Checks if the amendment is an orphan, that it was retreived from the misaligned data without a paired demand. This is different
     * from creating a new demand for the item
     *
     * @private
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    public isAmendmentOrphaned(): boolean {
        return this.amendmentsService.isAmendmentOrphaned(this.misalignedAmendment, this.originalAmendment);
    }

    /**
     * Is the ability to edit disables? Returns true if edit should be disabled, false if the edit should be enabled (not disabled).
     * Checks are based on if the project has already been saved, and if not, checks if the user has edit access based on their role with the project.
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    private checkEditDisabled(): boolean {
        return this.engagementSubmitted || !this.hasEditAccess;
    }

    /**
     * Precheck that occurs for each of the UI errors. If one of the prechecks are satisfied, then the value is returned.
     * Otherwise, this function returns undefined. When calling this function, check that the value is undefined. If it is undefined, then
     * continue processing. If it is not undefined, then return this function
     *
     * @private
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    private errorPrecheck(): boolean {
        if (this.isDemandNew() || this.isAmendmentOrphaned()) {
            return false;
        }
        if (!this.originalAmendment) {
            return true;
        }
        return undefined;
    }

    /**
     * Checks if the amendment is a newly created demand because this will impact the error messages
     *
     * @private
     * @returns {boolean}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    private isDemandNew(): boolean {
        return this.amendmentsService.isAmendmentAssignedToNewlyCreatedDemand(this.misalignedAmendment);
    }

    /**
     * Emits the change of the onchange callback.
     * Sends out the pairId for the amendment being updated (since all other identifiers can be changed),
     * project Id, the updated amdendment, and the error resolution status.
     *
     * @private
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    private callOnChangeEmitter(callbackDetails?: ICallbackNotificationDetails): void {
        this.onChange.emit(this.getCallbackObject(callbackDetails));
    }

    /**
     * Gets the callback object for the selected amendment pair.
     *
     * @private
     * @returns {IMisalignedAmendmentCallbackObject}
     * @memberof MisalignedAmendmentLineitemsComponent
     */
    private getCallbackObject(callbackDetails?: ICallbackNotificationDetails): IMisalignedAmendmentCallbackObject {
        let newDemandCreated: boolean = false;
        let existingDemandAssigned: boolean = false;
        if (callbackDetails) {
            if (callbackDetails.newDemandCreated) {
                newDemandCreated = true;
            }
            if (callbackDetails.existingDemandAssigned) {
                existingDemandAssigned = true;
            }
        }
        return {
            pairId: this.pairId,
            newDemandCreated,
            existingDemandAssigned,
            projectId: this.misalignedAmendment.projectId,
            misalignedAmendment: this.misalignedAmendment,
            errorsResolved: !this.showLineitemError(),
            splittedLineItem: this.isSplittedLineItem
        };
    }

    /**
     * Recalculate cost rate from API
     */
    private recalculateCostRate(): void {
        const resourceType = this.misalignedAmendment.role.isFte ? "0ACT" : "ZEXS";
        const laborUom = "H";
        this.projectService.getCostRateByTaskId(this.misalignedAmendment.taskId, this.misalignedAmendment.role.activityCode, resourceType, this.misalignedAmendment.resourceLocation.companyCode, laborUom)
            .then((response: ICostRateInfo) => {
                this.misalignedAmendment.costRate = response.costRate;
                this.recalculateAdditionalCost();
            }).catch((error) => {
                this.misalignedAmendment.costRate = undefined;
                this.recalculateAdditionalCost();
                const message: string = "Unable to recalculate amendment cost rate: " + error;
                this.logError(SourceConstants.Method.RecalculateCostRate, message, 500);
                const correlationId: string = DataService.getCorrelationIdFromError(error);
                this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.error, true, correlationId);
            });
    }

    /**
     * Recalculate cost
     */
    private recalculateAdditionalCost(): void {
        if (this.misalignedAmendment.plannedLabor === undefined || !this.misalignedAmendment.costRate === undefined) { /* 0 is a valid value, so check only for undefined */
            this.misalignedAmendment.addtlCost = undefined;
        }
        this.misalignedAmendment.addtlCost = this.misalignedAmendment.plannedLabor * this.misalignedAmendment.costRate;
    }

    /**
     * Recalculate Revenue
     */
    private recalculateAdditionalRevenue(): void {
        if (this.misalignedAmendment.plannedLabor === undefined || !this.misalignedAmendment.billRate === undefined) { /* 0 is a valid value, so check only for undefined */
            this.misalignedAmendment.addtlRevenue = undefined;
        }
        this.misalignedAmendment.addtlRevenue = this.misalignedAmendment.plannedLabor * this.misalignedAmendment.billRate;
    }

    /**
     * Formats the misaligned data to be shown on the UI, including selected items and dropdown lists.
     */
    private formatMisalignedData(misalignedAmendment: IMisalignedAmendmentViewModel, taskIds: string[], roles: IFinancialRoles[], existingDemands: IDemandDetails[]): void {
        this.formattedTaskIds = taskIds.map((x) => { return { id: x, key: this.formattedTasks(x, misalignedAmendment.taskDescription) }; });
        this.selectedTaskId = this.formattedTaskIds.filter((x) => x.id === misalignedAmendment.taskId)[0];
        this.formattedRoles = roles;
        this.selectedRole = this.formattedRoles.filter((x) => x.activityCode === misalignedAmendment.role.activityCode)[0];
        this.plannedLaborHours = misalignedAmendment.plannedLabor;
        this.formattedLocationIds = this.companyCodeList.map((c) => { return { companyCode: c, key: this.formatResourceLocation(c.companyCode, c.companyName) }; });
        this.selectedLocation = this.formattedLocationIds.filter((x) => x.companyCode.companyCode === misalignedAmendment.resourceLocation.companyCode)[0];
        const billingRoleIds: Array<{ id: string; billRate: number }> = [];
        if (existingDemands) {
            for (const demand of existingDemands) {
                if (demand && demand.planned) {
                    // Check if billingroleid is nonbill thenreplace with NON-BILL text
                    const newBillingRoleId: string = demand.planned.resourceItemId === this.nonBillResourceItemId ? this.nonBillValue : demand.planned.resourceItemId;
                    if (newBillingRoleId && !billingRoleIds.some((b) => b.id === newBillingRoleId)) {
                        billingRoleIds.push({ id: newBillingRoleId, billRate: demand.planned.billRate });
                    }
                }
            }
        }
        if (!billingRoleIds.some((b) => b.id === misalignedAmendment.billingRoleId)) {
            billingRoleIds.push({ id: misalignedAmendment.billingRoleId, billRate: misalignedAmendment.billRate });
        }
        this.formattedBillingRoleIds = billingRoleIds;
        this.selectedBillingRole = this.formattedBillingRoleIds.filter((b) => b.id === misalignedAmendment.billingRoleId)[0];
    }
}
