import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HelloWorldComponent } from "./hello-world.component";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [
        HelloWorldComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
    ],
    providers: [ /* Add non-FXP injected services here */    ],
    
    /* In FXP Shell all the angular components are going to be loaded dynamically using Routing.
    For that it is very imporant to make the components part of entryComponents section of angular module. */
    entryComponents: [HelloWorldComponent],
    exports: [HelloWorldComponent]
})
// Feature Module for bundling the dependencies. All the feature modules needs to be registered in FXP.
// Please refer main.ts file for the same.
export class HelloWorldModule {

}



