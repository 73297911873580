import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { AmendmentsService } from "../../../../common/services/amendments.service";
import { ChangeExistingDemandModalModule } from "../modals/change-existing-demand/change-existing-demand.module";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { MisalignedAmendmentLineitemsComponent } from "./misaligned-amendment-lineitems.component";
import { ProjectService } from "../../../../common/services/project.service";

@NgModule({
    declarations: [
        MisalignedAmendmentLineitemsComponent,
    ],
    imports: [
        ChangeExistingDemandModalModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        AmendmentsService,
        ConfigManagerService,
        DMLoggerService,
        ProjectService,
    ],
    entryComponents: [
        MisalignedAmendmentLineitemsComponent,
    ],
    exports: [
        MisalignedAmendmentLineitemsComponent,
    ]
})
export class MisalignedAmendmentLineitemsModule { }
