import { ILoadableState } from "../reducers";

import * as fromWbsDemandsV2 from "./demands.action";
import { IWbsDemand } from "../../common/services/contracts/project.service.v2.contracts";

export interface IDemandsState extends ILoadableState {
    wbsDemands: IWbsDemand;
}

export const initialState: IDemandsState = {
    wbsDemands: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromWbsDemandsV2.DemandsActions): { [wbsId: string]: IDemandsState } {
    switch (action.type) {
        case fromWbsDemandsV2.DemandsActionTypes.LOAD_DB_DEMANDS: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromWbsDemandsV2.DemandsActionTypes.LOAD_DB_DEMANDS_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                wbsDemands: action.wbsDemands,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromWbsDemandsV2.DemandsActionTypes.LOAD_DB_DEMANDS_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromWbsDemandsV2.DemandsActionTypes.INVALIDATE_DB_DEMANDS: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}