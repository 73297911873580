/* eslint-disable @typescript-eslint/no-unused-vars */
/* This service is a WIP migrating functionality from the staffing page and its related pages, so much of the content is commented out. 
I am disabling some ES Linting rules for this file as a result and will reenable once the service is more complete.*/
import { Injectable, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import moment from "moment";

import { AADGraphService } from "./aad-graphapi.service";
import { ConfigManagerService } from "./configmanager.service";
import { ICostRateInput, ICostRateApiOutput, IDemandDetail, IResourceDetail, IResource, IProjectDetails, IService, IEngagementDetails } from "./contracts/project.service.contracts";
import { IDemandDetails, IResourceData, IWbsDemand } from "./contracts/project.service.v2.contracts";
import { IResourceRequest, IProjectRequest, IResourceRequestResponse, ISplitDemandDetails } from "./contracts/staffing.service.contract";
import { IStaffingTaskModel, IStaffingResource, IResourceCostRate, IStaffingDemandModel, IStaffingViewModel } from "./contracts/staffing-details.contract";
import { ITaskStructure, IProject, IServiceStructure, IWbsStructure } from "./contracts/wbsStructures.contracts";
import { ProjectService } from "./project.service";
import { SAPApiResponseResourceDetailTypeFilters, ResourceRequestStatus, Services } from "../application.constants";
import { IPurchaseOrder } from "./contracts/po.service.contracts";
import { IResourceImage } from "./contracts/resource-profile.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

const CANCELLED_STATUS: string = "Cancelled";

@Injectable()
export class ResourceStaffingService extends DmServiceAbstract{

    private staffingExclusionWbsL3Types: string[];
    private subConRolePartNumbers: string[];
    private commandBarActions: string[];


    public constructor(
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ResourceStaffingService );
        this.staffingExclusionWbsL3Types = this.configManagerService.getValue<string[]>("StaffingExclusionWbsL3Types");
        this.subConRolePartNumbers = this.configManagerService.getValue<string[]>("subConRolePartNumbers");
        this.commandBarActions = this.configManagerService.getValue<string[]>("staffingCommandBarActions");
    }


    /**
     * Checks if the configured command bar actions contains the given action.
     * Returns true if the action is in the list, false otherwise
     *
     * @param {string} action
     * @returns {boolean}
     * @memberof ResourceStaffingService
     */
    public commandBarActionsContains(action: string): boolean {
        return this.commandBarActions && this.commandBarActions.indexOf(action) > -1;
    }

    /**
     * Get resource thumb pictures and add it to array.
     * @param assignedResource
     */
    public bindResourceImageToStaffingTasks(assignedResource: string, taskId: string, demandId: string, rolesRequests: IStaffingTaskModel[]): Promise<void> {
        return this.aadGraphService.getResourceThumbnailPicture(assignedResource)
            .then((resourcePhotoResponse) => {
                if (resourcePhotoResponse) {
                    const filteredTasks = rolesRequests.filter((task) => task.taskId === taskId);
                    if (filteredTasks.length) {
                        const filteredDemands = filteredTasks[0].demands.filter((demand) => demand.demandId === demandId);
                        if (filteredDemands.length) {
                            const resourceDetails = filteredDemands[0].resources.filter((res) => res.resourceAlias === assignedResource);
                            for (const res of resourceDetails) {
                                res.resourcePhoto = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + resourcePhotoResponse);
                            }
                        }
                    }
                }
            });
    }

    /**
     * To get the actions of resources.
     * @param resource
     */
    public getActions(resource: IResourceRequest): string {
        if (resource.ResourceRequestStatusEnum === "Proposed" && resource.ResourceRequestSubStatusEnum === "Ready" && resource.RequestBookingTypeId !== 2) {
            return "AcceptDecline";
        } else {
            return "Edit";
        }
    }

    /**
     * Functions to load StaffedCost and Staffed Labor for each Demand.
     */ // todo rename this func
    public populateDemandStaffedCostLabor(staffingTasks: IStaffingTaskModel[]): void {
        if (!staffingTasks || !staffingTasks.length) {
            return;
        }
        for (const task of staffingTasks) {
            if (task && task.demands) {
                for (const demand of task.demands) {
                    let demandStaffedLabor = 0;
                    let demandStaffedCost = 0;
                    if (demand && demand.resources) {
                        for (const resource of demand.resources) {
                            if (resource.resourceStatus === ResourceRequestStatus.Assigned
                                || resource.resourceStatus === ResourceRequestStatus.Complete
                                || resource.resourceStatus === ResourceRequestStatus.Closed) {
                                demandStaffedCost += resource.resourceStaffedCost;
                                demandStaffedLabor += resource.resourceStaffedQuantity;
                            }
                        }
                    }
                    demand.demandStaffedCost = demandStaffedCost;
                    demand.demandStaffedQuantity = demandStaffedLabor;
                    demand.demandCostVariance = this.calculateCostVariance(demand.demandStaffedCost, demand.demandPlannedCost);
                }
            }
        }
    }

    /**
     * To get the resource status enum values.
     * @param resourceStatusEnum
     */
    public getResourceStatus(resourceStatusEnum: string): string {
        if (resourceStatusEnum === "Assigned") {
            resourceStatusEnum = "Committed";
        } else if (resourceStatusEnum === "Complete") {
            resourceStatusEnum = "Assigned";
        }
        return resourceStatusEnum;
    }

    /**
     * Retrieving Calculate Cost Variance
     * @param staffedCost
     * @param plannedCost
     */
    public calculateCostVariance(staffedCost: number, plannedCost: number): number {
        if (staffedCost && plannedCost) {
            const costVariance = ((staffedCost - plannedCost) / plannedCost) * 100;
            return Number(costVariance.toFixed(2));
        }
        return 0;
    }

    /**
     * Retrieving Demanddetails for Split
     * @param demandId
     */
    public retrieveDemandDetailsForSplit(staffingTasks: IStaffingTaskModel[], demandId: string): ISplitDemandDetails {
        let demandDetail: ISplitDemandDetails;
        if (staffingTasks) {
            for (const task of staffingTasks) {
                if (task.demands) {
                    const filteredDemand: IStaffingDemandModel = task.demands.filter((object: IStaffingDemandModel) => object.demandId === demandId)[0];
                    if (filteredDemand) {
                        demandDetail = {
                            demandId: filteredDemand.demandId,
                            roleDescription: filteredDemand.demandRole,
                            plannedDuration: filteredDemand.demandPlannedQuantity,
                            startDate: task.projectStartDate,
                            endDate: task.projectEnddate,
                            isDemandBillable: filteredDemand.isDemandBillable
                        };
                    }
                }
            }
        }
        return demandDetail;
    }

    /**
     * To calculate role details hours
     */
    public calculateRoleDetailsHours(resource: IResource): number {
        let totalHours = 0;
        if (resource.monthlySchedules) {
            for (const mSchedule of resource.monthlySchedules) {
                for (const key in mSchedule) {
                    if (key !== "year") {
                        totalHours += mSchedule[key];
                    }
                }
            }
        } else if (resource.weeklySchedules) {
            for (const wSchedule of resource.weeklySchedules) {
                for (const key in wSchedule) {
                    if (key !== "year") {
                        totalHours += wSchedule[key];
                    }
                }
            }
        }
        return Math.round(totalHours);
    }

    /**
     * Remove Expense type for wbstructures object
     */
    public removeExpenseTypeV2(projectDetails: IProject[]): IProject[] {
        if (projectDetails && projectDetails.length > 0) {
            for (const project of projectDetails) {
                const projectServices: IServiceStructure[] = [];
                for (const service of project.services) {
                    for (const task of service.tasks) {
                        if ((this.staffingExclusionWbsL3Types.indexOf(task.workPackageType) === -1) && !projectServices.some((service2: IServiceStructure) => service.id === service2.id)) {
                            projectServices.push(service);
                        }
                    }
                }
                project.services = projectServices;
            }
        }
        return projectDetails;
    }

    /**
     * Remove Expense type services
     */
    public removeExpenseType(projectData: IProjectDetails[]): void {
        for (const project of projectData) {
            const projectServices: IService[] = [];
            for (const service of project.services) {
                for (const task of service.wbsL3s) {
                    if ((this.staffingExclusionWbsL3Types.indexOf(task.wbsl3Type) === -1) && !projectServices.some((service2: IService) => service.serviceId === service2.serviceId)) {
                        projectServices.push(service);
                    }
                }
            }
            project.services = projectServices;
        }
    }

    /**
     * To get resource bill rate.
     * @param resourceDetails
     */
    public getResourceBillRate(resourceDetails: IResourceDetail[]): number {
        let billRate = 0;
        const resDetails: IResourceDetail = resourceDetails.filter((res) => res.type.toLowerCase() === "planned")[0];
        if (resDetails && resDetails.resources && resDetails.resources.length) {
            billRate = resDetails.resources[0].billRate;
        }
        return billRate;
    }

    /**
     * To get resource staffed cost rate.
     * @param resourceDetails
     * @param resourceId
     * @param resourceDetailType
     */
    public getResourceStaffedCostRate(resourceDetails: IResourceDetail[], resourceId: number, resourceDetailType: SAPApiResponseResourceDetailTypeFilters): number {
        let staffedCostRate = 0;
        const resDetails: IResourceDetail = resourceDetails.filter((res) => res.type.toLowerCase() === resourceDetailType)[0];
        if (resDetails && resDetails.resources && resDetails.resources.length) {
            const resSelected: IResource[] = resDetails.resources.filter((resfilter) => Number(resfilter.resourceRequestId) === Number(resourceId));
            if (resSelected && resSelected.length && resSelected[0].staffedCostRate) {
                staffedCostRate = Number(resSelected[0].staffedCostRate);
            }
        }
        return Number(staffedCostRate.toFixed(2));
    }

    /**
     * Get resource thumb pictures and add it to array.
     * @param assignedResource
     */
    public bindCostRateToStaffingTasks(staffingTasks: IStaffingTaskModel[], resourceRequestId: number, taskId: string, demandId: string, costRate: number): void {
        const filteredTasks = staffingTasks.filter((task: IStaffingTaskModel) => task.taskId === taskId)[0];
        if (filteredTasks) {
            const filteredDemands = filteredTasks.demands.filter((demand) => demand.demandId === demandId)[0];
            if (filteredDemands) {
                const resourceDetails = filteredDemands.resources.filter((res) => res.resourceId === resourceRequestId)[0];
                if (resourceDetails) {
                    resourceDetails.resourceCostRate = costRate;
                }
            }
        }
    }

    /**
     * To calculate resource staffed cost.
     * @param resourceDetails
     * @param resourceIdbindImageDataAndCostRateToResources
     */
    public getResourceStaffedCost(resourceDetails: IResourceDetail[], resourceId: number, resourceStatus: string): number {
        if (resourceStatus === ResourceRequestStatus.Assigned
            || resourceStatus === ResourceRequestStatus.Closed
            || resourceStatus === ResourceRequestStatus.Complete) {
            return this.getStaffedCost(resourceDetails, resourceId, SAPApiResponseResourceDetailTypeFilters.staffed);
        } else {
            return this.getStaffedCost(resourceDetails, resourceId, SAPApiResponseResourceDetailTypeFilters.planned);
        }
    }

    /**
     * To get cost rate from cost rate api response.
     * @param costRateResponse
     */
    public getCostRateFromApiOutput(costRateResponse: ICostRateApiOutput, isInternal: boolean, isProjectContext: boolean, projectStructureDetails: IProject, engagementDetails: IEngagementDetails, engagementStructureDetails: IWbsStructure): number {
        const staffedCostRate = 0;
        const startDate: Date = this.getStartDateForCostRate(isProjectContext, projectStructureDetails, engagementDetails, engagementStructureDetails);
        if (costRateResponse && costRateResponse.costRateResourceDetails && costRateResponse.costRateResourceDetails.length && costRateResponse.costRateResourceDetails[0].resourceCostRates && costRateResponse.costRateResourceDetails[0].resourceCostRates.length) {
            const fiscalYear = moment(startDate).year();
            const costRateObjectForFiscalYear = costRateResponse.costRateResourceDetails[0].resourceCostRates.filter((object) => object.fiscalYear === fiscalYear);
            if (costRateObjectForFiscalYear.length) {
                const monthNumber = moment(startDate).month() + 1; /// as moment returns month index from zero
                const monthNumberString = (monthNumber < 10) ? "0" + monthNumber.toString() : monthNumber.toString();
                return costRateObjectForFiscalYear[0]["period" + monthNumberString];
            }
        }
        return staffedCostRate;
    }

    /**
     * Bind image to data resource.
     * @param resourceImageArray
     */
    public bindImageDataAndCostRateToResources(resourceImageArray: IResourceImage[], staffingTasks: IStaffingTaskModel[], resourceCostRateDetails: IResourceCostRate[], purchaseOrderList: IPurchaseOrder[]): void {
        if (((resourceImageArray && resourceImageArray.length) || (resourceCostRateDetails && resourceCostRateDetails.length)) && staffingTasks) {
            for (const task of staffingTasks) {
                if (task && task.demands) {
                    for (const demand of task.demands) {
                        if (demand && demand.resources) {
                            for (const resource of demand.resources) {
                                if (resource && resource.resourceAlias) {
                                    const resourceImageInfo: IResourceImage[] = resourceImageArray.filter(((resourceObject) => resourceObject.resourceAlias.toLowerCase() === resource.resourceAlias.toLowerCase()));
                                    if (resourceImageInfo && resourceImageInfo.length && resourceImageInfo[0].resourceImage) {
                                        resource.resourcePhoto = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + resourceImageInfo[0].resourceImage);
                                    }
                                }
                                const resourceCostRateInfo: IResourceCostRate = resourceCostRateDetails.filter((object) => object.resourceId === resource.resourceId)[0];
                                if (resourceCostRateInfo) {
                                    if (this.subConRolePartNumbers.indexOf(resource.rolePartNumber) < 0) {
                                        resource.resourceCostRate = resourceCostRateInfo.resourceCostRate;
                                    } else {
                                        resource.resourceCostRate = this.retrieveCostRateFromPurchaseOrderAPIResponse(resource.purchaseOrderId, resource.poLineItemNumber, purchaseOrderList);
                                    }
                                    resource.resourceStaffedCost = resource.resourceCostRate * resource.resourceStaffedQuantity;
                                    resource.resourceCostVariance = this.calculateCostVariance(resource.resourceStaffedCost, resource.resourcePlannedCost);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Bind image to data resource.
     * @param resourceImageArray
     */
    public bindImageDataAndStaffedCostToResources(resourceImageArray: IResourceImage[], staffingTasks: IStaffingTaskModel[], resourceCostRate: number, purchaseOrderList: IPurchaseOrder[]): void {
        if (((resourceImageArray && resourceImageArray.length) || resourceCostRate) && staffingTasks) {
            for (const task of staffingTasks) {
                if (task && task.demands) {
                    for (const demand of task.demands) {
                        if (demand && demand.resources) {
                            for (const resource of demand.resources) {
                                if (resource && resource.resourceAlias) {
                                    const resourceImageInfo: IResourceImage[] = resourceImageArray.filter(((resourceObject) => resourceObject.resourceAlias.toLowerCase() === resource.resourceAlias.toLowerCase()));
                                    if (resourceImageInfo && resourceImageInfo.length && resourceImageInfo[0].resourceImage) {
                                        resource.resourcePhoto = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + resourceImageInfo[0].resourceImage);
                                    }
                                }                               
                                resource.resourceStaffedCost = resource.resourceCostRate * resource.resourceStaffedQuantity;
                                resource.resourceCostVariance = this.calculateCostVariance(resource.resourceStaffedCost, resource.resourcePlannedCost);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Add the project details to the staffing model
     */
    public addItemToStaffingModel(projectDetails: IProject[], staffingViewModel: IStaffingViewModel[]): void {
        for (const proj of projectDetails) {
            const singleStaffingViewModel: IStaffingViewModel = {
                taskProjectName: proj.name,
                taskProjectId: proj.id,
                projectStartDate: proj.startDate,
                projectEndDate: proj.endDate,
                isTasksExpanded: false,
                showLoading: true,
                taskModelDetails: []
            };
            staffingViewModel.push(singleStaffingViewModel);
        }
    }

    /**
     * To get resource staffed cost rate.
     * @param resourceDetails
     * @param resourceId
     * @param resourceDetailType
     */
    private getStaffedCost(resourceDetails: IResourceDetail[], resourceId: number, resourceDetailType: SAPApiResponseResourceDetailTypeFilters): number {
        let staffedCost = 0;
        const filteredResDetails: IResourceDetail[] = resourceDetails.filter((res) => res.type.toLowerCase() === resourceDetailType);
        if (filteredResDetails.length) {
            const resDetails: IResourceDetail = filteredResDetails[0];
            if (resDetails && resDetails.resources && resDetails.resources.length) {
                const resSelected: IResource[] = resDetails.resources.filter((resfilter) => Number(resfilter.resourceRequestId) === Number(resourceId));
                if (resSelected && resSelected.length && resSelected[0].staffedCostRate) {
                    staffedCost = Number(resSelected[0].staffedCost);
                }
            }
        }
        return Number(staffedCost.toFixed(2));
    }

    /**
     * Retrieving Cost Rate from Purchase Order Details Response
     * @param engagementId
     */
    private retrieveCostRateFromPurchaseOrderAPIResponse(purchaseOrderId: string, poLineItemNumber: number, purchaseOrderList: IPurchaseOrder[]): number {
        const resourceFilteredPurchaseOrders: IPurchaseOrder[] = purchaseOrderList.filter((po) => po.poNumber === purchaseOrderId);
        if (resourceFilteredPurchaseOrders.length && purchaseOrderId && poLineItemNumber) {
            const resourcePurchaseOrder: IPurchaseOrder = resourceFilteredPurchaseOrders[0];
            if (resourcePurchaseOrder && resourcePurchaseOrder.lineItems && resourcePurchaseOrder.lineItems.length) {
                const resourceLineItem = resourcePurchaseOrder.lineItems.filter((lineitem) => lineitem.lineItemNumber === poLineItemNumber)[0];
                return resourceLineItem.itemRate;
            }
        }
        return 0;
    }

    /* ------ All functions below here are WIP (commented out), still too coupled to the original component ------ */

    // /**
    //  * Functions to load demand details.
    //  */
    // public getStaffingTaskModelList(projects: IProjectDetails[], isInternal: boolean): IStaffingTaskModel[] { // loadDemandDetails(): void {
    //     // this.staffingTasks = [];
    //     // this.resourceImages = [];
    //     // this.resourceAliasArray = [];
    //     // this.resourceCostRateDetails = [];
    //     const staffingTasks: IStaffingTaskModel[] = [];
    //     // const resourceAliasArray: string[] = [];
    //     const resourceImages: IResourceImage[] = [];
    //     for (const project of projects) {
    //         for (const service of project.services) {
    //             for (const task of service.wbsL3s) {
    //                 if (this.staffingExclusionWbsL3Types.indexOf(task.wbsl3Type) === -1) {
    //                     const staffingTaskViewModel: IStaffingTaskModel = {
    //                         taskEngagementName: project.engagementName,
    //                         taskProjectName: project.projectName,
    //                         taskProjectId: project.projectId,
    //                         projectStartDate: project.startDate,
    //                         projectEnddate: project.endDate,
    //                         taskCompassoneId: service.compassOneServiceId,
    //                         taskServiceName: service.serviceName,
    //                         taskServiceId: service.serviceId,
    //                         taskName: task.wbsL3Name,
    //                         taskId: task.wbsl3Id,
    //                         taskTypeCode: task.wbsl3Type,
    //                         taskCompassPackageId: project.compassOnePackageId,
    //                         isDemandsExpanded: true,
    //                         demands: task.demandDetails.length > 0 ? this.loadDemands(task.demandDetails, project.projectId, project, isInternal) : []
    //                     };
    //                     staffingTasks.push(staffingTaskViewModel);
    //                     this.addToStaffingViewModel(staffingTaskViewModel);
    //                 }
    //             }
    //         }
    //     }
    //     //  this.populateDemandStaffedCostLabor();

    //     // need to return staffing tasks

    //     // for (const resourceAlias of resourceAliasArray) { // we dont need to call this here because it will be empty rn
    //     //     this.resourceImageAndCostRatePromises.push(this.bindResourceImageToArray(resourceAlias));
    //     // this needs to be adding stuff to resourceImages
    //     // }
    //     // return Promise.all(this.resourceImageAndCostRatePromises).then(() => {
    //     this.bindImageDataAndCostRateToResources(staffingTasks, resourceImages);
    //     this.populateDemandStaffedCostLabor(staffingTasks);
    //     return staffingTasks;
    //     // });
    // }

    // /**
    //  * Functions to load demands.
    //  * @param demands
    //  * @param projectId
    //  * @param project
    //  */
    // public loadDemands(demands: IDemandDetail[], projectId: string, project: IProjectDetails, isInternal: boolean): IStaffingDemandModel[] {
    //     const demandDetails: IStaffingDemandModel[] = [];
    //     for (const demand of demands) {
    //         if (demand.resourceDetails && demand.resourceDetails.length > 0) {
    //             const resDetails: IResourceDetail = demand.resourceDetails.filter((res) => res.type.toLowerCase() === "planned")[0];
    //             if (resDetails.resources && resDetails.resources.length) {
    //                 const resource: IResource = resDetails.resources[0];
    //                 demandDetails.push({
    //                     engagementId: demand.engagementId,
    //                     demandId: resource.demandId,
    //                     demandLocation: resource.deliveryLocCountryCode,
    //                     demandRole: resource.rolePartNumberDescription,
    //                     demandCostRate: resource.plannedCostRate,
    //                     demandPlannedQuantity: this.calculateRoleDetailsHours(resource),
    //                     demandPlannedCost: resource.plannedCost,
    //                     demandBillRate: resource.billRate,
    //                     resourceAccordionFlag: true,
    //                     demandStatus: demand.wbsl3StatusDescription,
    //                     isRolesExpanded: true,
    //                     isDemandBillable: resource.isBillable,
    //                     resources: this.getDemandResourceRequests(demand, projectId, demand.resourceDetails, resource.plannedCostRate, project, isInternal)
    //                 });
    //             }
    //         }
    //     }
    //     return demandDetails;
    // }

    // /**
    //  * Get resource thumb pictures and add it to array.
    //  * @param assignedResource
    //  */
    // private bindResourceImageToArray(assignedResource: string): Promise<any> {
    //     return this.aadGraphService.getResourceThumbnailPicture(assignedResource)
    //         .then((response) => {
    //             this.resourceImages.push({
    //                 resourceAlias: assignedResource,
    //                 resourceImage: response
    //             });
    //         }).catch(() => {
    //             return Promise.resolve();
    //         });
    // }

    // /**
    //  * Retrieve CostRate from API
    //  * @param costRateApiInput
    //  * @param resourceRequestId
    //  */
    // public retrieveResourceCostRate(costRateApiInput: ICostRateInput[], resourceRequestId: number): Promise<number> {
    //     return this.projectService.getCostRate(costRateApiInput)
    //         .then((response: ICostRateApiOutput) => {
    //             const retrievedCostRate = this.getCostRateFromApiOutput(response);
    //             const resourceInfo: IResourceCostRate = this.resourceCostRateDetails.filter(((resourceObject) => resourceObject.resourceId === resourceRequestId))[0];
    //             if (resourceInfo) {
    //                 resourceInfo.resourceCostRate = retrievedCostRate;
    //             } else {
    //                 this.resourceCostRateDetails.push({
    //                     resourceId: resourceRequestId,
    //                     resourceCostRate: retrievedCostRate
    //                 });
    //             }
    //             return retrievedCostRate;
    //         });
    // }

    // /**
    //  * To get demand resource from service
    //  * @param demandId
    //  * @param projectId
    //  * @param resDetails
    //  * @param plannedCostRate
    //  * @param project
    //  */
    // private getDemandResourceRequests(demand: IDemandDetail, projectId: string, resDetails: IResourceDetail[], plannedCostRate: number, project: IProjectDetails, isInternal: boolean): IStaffingResource[] {
    //     const staffingResources: IStaffingResource[] = [];
    //     let projectRequests: IProjectRequest = null;
    //     let resourceRequests: IResourceRequest[] = [];
    //     if (this.grmRequestList && this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId).length > 0) {
    //         projectRequests = this.grmRequestList.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId)[0];
    //         if (projectRequests.ResourceRequests && projectRequests.ResourceRequests.length > 0) {
    //             resourceRequests = projectRequests.ResourceRequests.filter((resourceDetails) => resourceDetails.DemandResourceRequest && resourceDetails.DemandResourceRequest.DemandId === demand.demandId);
    //             if (resourceRequests && resourceRequests.length > 0) {
    //                 resourceRequests.forEach((resourceRequest) => {
    //                     if (resourceRequest.ResourceRequestStatusEnum.toLowerCase() !== CANCELLED_STATUS.toLowerCase()) {
    //                         if (resourceRequest.AssignedResource) {
    //                             this.resourceAliasArray.push(resourceRequest.AssignedResource);
    //                         } else if (resourceRequest.RequestedResource) {
    //                             this.resourceAliasArray.push(resourceRequest.RequestedResource);
    //                         }
    //                         const resourcePopulated: IStaffingResource = {
    //                             resourceId: resourceRequest.ResourceRequestId,
    //                             resourceGrmProjectID: projectRequests.ProjectId,
    //                             resourceLocation: resourceRequest.DeliveryCountryName,
    //                             resourceRole: resourceRequest.Role,
    //                             resourceAlias: resourceRequest.AssignedResource ? resourceRequest.AssignedResource : resourceRequest.RequestedResource,
    //                             resourceBillRate: isInternal ? 0 : this.getResourceBillRate(resDetails),
    //                             resourceCostRate: this.calculateResourceCostRate(resourceRequest, demand, isInternal), // this.getResourceStaffedCostRate(resDetails, resourceRequest.ResourceRequestId, resourceRequest.scheduledDurationInHours),
    //                             resourceStaffedQuantity: resourceRequest.scheduledDurationInHours,
    //                             resourcePlannedQuantity: resourceRequest.RequestedDuration,
    //                             resourceStaffedCost: this.getResourceStaffedCost(resDetails, resourceRequest.ResourceRequestId, resourceRequest.ResourceRequestStatusEnum),
    //                             resourcePlannedCost: plannedCostRate * resourceRequest.RequestedDuration,
    //                             resourceStartDate: resourceRequest.ScheduledStartDate ? resourceRequest.ScheduledStartDate : resourceRequest.RequestedStartDate,
    //                             resourceEndDate: resourceRequest.ScheduledEndDate ? resourceRequest.ScheduledEndDate : resourceRequest.RequestedEndDate,
    //                             resourceStatus: this.getResourceStatus(resourceRequest.ResourceRequestStatusEnum),
    //                             resourceSubStatus: resourceRequest.ResourceRequestSubStatusEnum,
    //                             resourceName: resourceRequest.AssignedResourceName ? resourceRequest.AssignedResourceName : resourceRequest.RequestedResourceName,
    //                             resourceActions: this.getActions(resourceRequest),
    //                             enableResourceActions: (this.hasPermissionsForEditActions(project.projectId)),
    //                             purchaseOrderId: resourceRequest.DemandResourceRequest.PoNumber,
    //                             poLineItemNumber: resourceRequest.DemandResourceRequest.PoLineItem,
    //                             rolePartNumber: resourceRequest.RolePartNumber
    //                         };
    //                         resourcePopulated.resourceCostVariance = this.calculateCostVariance(resourcePopulated.resourceStaffedCost, resourcePopulated.resourcePlannedCost);
    //                         staffingResources.push(resourcePopulated);
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //     return staffingResources;
    // }

    // /**
    //  * Calculating resource cost rate for different status
    //  *
    //  * @private
    //  * @param {IResourceRequest} role
    //  * @param {IDemandDetail} demandDetail
    //  * @returns {number}
    //  * @memberof StaffingComponent
    //  */
    // private calculateResourceCostRateV2(staffingTasks: IStaffingTaskModel[], resourceDetail: IResourceRequest, demandDetail: IDemandDetails, engagementId: string, isInternal: boolean): number {
    //     const staffedDetails: IResourceData[] = (demandDetail.staffed && demandDetail.staffed.length > 0) ? demandDetail.staffed.filter((resData) => resData.resourceRequestId === String(resourceDetail.ResourceRequestId)) : [];
    //     const staffedCostRate: number = (staffedDetails && staffedDetails.length) ? staffedDetails[0].staffedCostRate : 0;
    //     if (isInternal) {
    //         if (!resourceDetail.AssignedResourceBPID) {
    //             return 0;
    //         }
    //         const costRateApiInput: ICostRateInput[] = [{
    //             resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
    //             projectId: engagementId, // is engId right?
    //             rolePartNumber: resourceDetail.RolePartNumber
    //         }];
    //         this.retrieveResourceCostRateV2(staffingTasks, costRateApiInput, resourceDetail.ResourceRequestId, demandDetail);
    //         return 0;
    //     } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Complete) {
    //         return staffedCostRate;
    //     } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Assigned || resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Proposed) {
    //         /* Check if its SubContracter Role or not */
    //         if (this.subConRolePartNumbers.indexOf(resourceDetail.RolePartNumber) < 0) {
    //             /* Check if Data is present in SAP Response*/
    //             if (staffedCostRate) {
    //                 return staffedCostRate;
    //             } else {
    //                 if (!resourceDetail.AssignedResourceBPID) {
    //                     return 0;
    //                 }
    //                 const costRateApiInput: ICostRateInput[] = [{
    //                     resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
    //                     projectId: engagementId, // is engId right?
    //                     rolePartNumber: resourceDetail.RolePartNumber
    //                 }];
    //                 this.retrieveResourceCostRateV2(staffingTasks, costRateApiInput, resourceDetail.ResourceRequestId, demandDetail);
    //                 return 0;
    //             }
    //         }
    //     } else {
    //         return 0;
    //     }
    // }

    // /**
    //  * Retrieve CostRate from API
    //  * @param costRateApiInput
    //  * @param resourceRequestId
    //  */
    // private retrieveResourceCostRateV2(staffingTasks: IStaffingTaskModel[], costRateApiInput: ICostRateInput[], resourceRequestId: number, demandDetail: IDemandDetails): Promise<any> {
    //     return this.projectService.getCostRate(costRateApiInput)
    //         .then((response: ICostRateApiOutput) => {
    //             const retrievedCostRate = this.getCostRateFromApiOutput(response);
    //             this.bindCostRateToStaffingTasks(staffingTasks, resourceRequestId, demandDetail.planned.structureId, demandDetail.demandId, retrievedCostRate);
    //         });
    // }

    private getStartDateForCostRate(isProjectContext: boolean, projectStructureDetails: IProject, engagementDetails: IEngagementDetails, engagementStructureDetails: IWbsStructure): Date {
        if (isProjectContext) {
            return projectStructureDetails.startDate;
        }
        return engagementStructureDetails.startDate;
    }



    // /**
    //  * Gets the Resource Requests based on the wbddemands and grmapiresponse.
    //  *
    //  * @param {IDemandDetails} wbsDemandDetailsForTask
    //  * @param {IResourceRequestResponse} grmResponse
    //  * @param {string} projectId
    //  * @memberof StaffingComponent
    //  */
    // private getResourceRequestsForWbsDemandModel(staffingTasks: IStaffingTaskModel[], wbsDemandDetailsForTask: IDemandDetails, grmResponse: IResourceRequestResponse, projectId: string, isInternal: boolean): IStaffingResource[] {
    //     const resourceRequestsForViewModel: IStaffingResource[] = [];
    //     if (grmResponse && grmResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId).length > 0) {
    //         const projectRequests: IProjectRequest = grmResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === projectId)[0];
    //         if (projectRequests.ResourceRequests && projectRequests.ResourceRequests.length > 0) {
    //             const resourceRequests: IResourceRequest[] = projectRequests.ResourceRequests.filter((resourceDetails) => resourceDetails.DemandResourceRequest && resourceDetails.DemandResourceRequest.DemandId === wbsDemandDetailsForTask.demandId);
    //             if (resourceRequests && resourceRequests.length > 0) {
    //                 resourceRequests.forEach((resourceRequest) => {
    //                     if (resourceRequest.ResourceRequestStatusEnum.toLowerCase() !== CANCELLED_STATUS.toLowerCase()) {
    //                         if (resourceRequest.AssignedResource) {
    //                             this.bindResourceImageToStaffingTasks(resourceRequest.AssignedResource, wbsDemandDetailsForTask.planned.structureId, wbsDemandDetailsForTask.demandId, staffingTasks);
    //                         } else if (resourceRequest.RequestedResource) {
    //                             this.bindResourceImageToStaffingTasks(resourceRequest.RequestedResource, wbsDemandDetailsForTask.planned.structureId, wbsDemandDetailsForTask.demandId, staffingTasks);
    //                         }
    //                         const plannedDetails: IResourceData = wbsDemandDetailsForTask.planned;
    //                         const staffedDetails: IResourceData[] = (wbsDemandDetailsForTask.staffed && wbsDemandDetailsForTask.staffed.length > 0) ? wbsDemandDetailsForTask.staffed.filter((resData) => resData.resourceRequestId === (resourceRequest.ResourceRequestId).toString()) : [];
    //                         const resourcePopulated: IStaffingResource = {
    //                             resourceId: resourceRequest.ResourceRequestId,
    //                             resourceGrmProjectID: projectRequests.ProjectId,
    //                             resourceLocation: resourceRequest.DeliveryCountryName,
    //                             resourceRole: resourceRequest.Role,
    //                             resourceAlias: resourceRequest.AssignedResource ? resourceRequest.AssignedResource : resourceRequest.RequestedResource,
    //                             resourceBillRate: isInternal ? 0 : plannedDetails.billRate,
    //                             resourceCostRate: this.calculateResourceCostRateV2(resourceRequest, wbsDemandDetailsForTask, engagementId, isInternal),
    //                             resourceStaffedQuantity: resourceRequest.scheduledDurationInHours,
    //                             resourcePlannedQuantity: resourceRequest.RequestedDuration,
    //                             resourceStaffedCost: (staffedDetails && staffedDetails.length > 0) ? staffedDetails[0].staffedCost : 0,
    //                             resourcePlannedCost: plannedDetails.plannedCostRate * resourceRequest.RequestedDuration,
    //                             resourceStartDate: resourceRequest.ScheduledStartDate ? resourceRequest.ScheduledStartDate : resourceRequest.RequestedStartDate,
    //                             resourceEndDate: resourceRequest.ScheduledEndDate ? resourceRequest.ScheduledEndDate : resourceRequest.RequestedEndDate,
    //                             resourceStatus: this.getResourceStatus(resourceRequest.ResourceRequestStatusEnum),
    //                             resourceSubStatus: resourceRequest.ResourceRequestSubStatusEnum,
    //                             resourceName: resourceRequest.AssignedResourceName ? resourceRequest.AssignedResourceName : resourceRequest.RequestedResourceName,
    //                             resourceActions: this.getActions(resourceRequest),
    //                             enableResourceActions: (this.hasPermissionsForEditActions(projectId)),
    //                             purchaseOrderId: resourceRequest.DemandResourceRequest.PoNumber,
    //                             poLineItemNumber: resourceRequest.DemandResourceRequest.PoLineItem,
    //                             rolePartNumber: resourceRequest.RolePartNumber
    //                         };
    //                         resourcePopulated.resourceCostVariance = this.calculateCostVariance(resourcePopulated.resourceStaffedCost, resourcePopulated.resourcePlannedCost);
    //                         resourceRequestsForViewModel.push(resourcePopulated);
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //     return resourceRequestsForViewModel;
    // }

    // /**
    //  * Calculating resource cost rate for different status
    //  *
    //  * @private
    //  * @param {IResourceRequest} role
    //  * @param {IDemandDetail} demandDetail
    //  * @returns {number}
    //  * @memberof StaffingComponent
    //  */
    // private calculateResourceCostRate(resourceDetail: IResourceRequest, demandDetail: IDemandDetail, isInternal: boolean): number {
    //     if (isInternal) {
    //         if (!resourceDetail.AssignedResourceBPID) {
    //             return 0;
    //         }
    //         const costRateApiInput: ICostRateInput[] = [{
    //             resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
    //             projectId: this.engagementDetails.engagementId,
    //             rolePartNumber: resourceDetail.RolePartNumber
    //         }];
    //         this.resourceImageAndCostRatePromises.push(this.retrieveResourceCostRate(costRateApiInput, resourceDetail.ResourceRequestId));
    //         return 0;
    //     } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Complete) {
    //         return this.getResourceStaffedCostRate(demandDetail.resourceDetails, resourceDetail.ResourceRequestId, SAPApiResponseResourceDetailTypeFilters.staffed);
    //     } else if (resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Assigned || resourceDetail.ResourceRequestStatusEnum === ResourceRequestStatus.Proposed) {
    //         /* Check if its SubContracter Role or not */
    //         if (this.subConRolePartNumbers.indexOf(resourceDetail.RolePartNumber) < 0) {
    //             /* Check if Data is present in SAP Response*/
    //             if (demandDetail && demandDetail.resourceDetails && demandDetail.resourceDetails.length) {
    //                 const resDetails: IResourceDetail = demandDetail.resourceDetails.filter((res) => res.type.toLowerCase() === SAPApiResponseResourceDetailTypeFilters.proposed)[0];
    //                 if (resDetails && resDetails.resources && resDetails.resources.length) {
    //                     const resSelected: IResource[] = resDetails.resources.filter((resfilter) => Number(resfilter.resourceRequestId) === Number(resourceDetail.ResourceRequestId));
    //                     if (resSelected && resSelected.length) {
    //                         return resSelected[0].staffedCostRate ? Number(resSelected[0].staffedCostRate.toFixed(2)) : 0.00;
    //                     } else {
    //                         if (!resourceDetail.AssignedResourceBPID) {
    //                             return 0;
    //                         }
    //                         const costRateApiInput: ICostRateInput[] = [{
    //                             resourceBpid: resourceDetail.AssignedResourceBPID, /// Not sure if it is coming always from GRM.
    //                             projectId: this.engagementDetails.engagementId,
    //                             rolePartNumber: resourceDetail.RolePartNumber
    //                         }];
    //                         this.resourceImageAndCostRatePromises.push(this.retrieveResourceCostRate(costRateApiInput, resourceDetail.ResourceRequestId));
    //                         return 0;
    //                     }
    //                 } else {
    //                     return 0;
    //                 }
    //             }
    //         }
    //     } else {
    //         return 0;
    //     }
    //     return 0;
    // }

    // /**
    //  * Gets the Demand Details based on the wbsdetails retrived from the API.
    //  *
    //  * @param {ITaskStructure} task
    //  * @param {IWbsDemand} wbsDemandDetails
    //  * @param {IResourceRequestResponse} grmResponse
    //  * @param {string} projectId
    //  * @memberof StaffingComponent
    //  */
    // private getDemandDetailsBasedOnWbsDemands(staffingTasks: IStaffingTaskModel[], task: ITaskStructure, wbsDemandDetails: IWbsDemand, grmResponse: IResourceRequestResponse, projectId: string, isInternal: boolean): IStaffingDemandModel[] {
    //     const staffingDemandModel: IStaffingDemandModel[] = [];
    //     if (wbsDemandDetails && wbsDemandDetails.details && wbsDemandDetails.details.length
    //         && wbsDemandDetails.details.filter((demand) => demand.planned && demand.planned.structureId && demand.planned.structureId === task.id).length > 0) {
    //         const plannedWbsDemandDetailsForTask: IDemandDetails[] = wbsDemandDetails.details.filter((demand) => demand.planned && demand.planned.structureId && demand.planned.structureId === task.id);
    //         for (const demandDetail of plannedWbsDemandDetailsForTask) {
    //             if (staffingDemandModel.filter((demand) => demand.demandId === demandDetail.demandId).length === 0) {
    //                 staffingDemandModel.push({
    //                     engagementId: this.sharedFunctionsService.getEngagementIdFromProjectId(projectId),
    //                     demandId: demandDetail.demandId,
    //                     demandLocation: demandDetail.planned.deliveryLocation,
    //                     demandRole: demandDetail.planned.roleDescription,
    //                     demandCostRate: demandDetail.planned.plannedCostRate,
    //                     demandPlannedQuantity: demandDetail.planned.plannedHours,
    //                     demandPlannedCost: demandDetail.planned.plannedCost,
    //                     demandBillRate: demandDetail.planned.billRate,
    //                     resourceAccordionFlag: true,
    //                     isRolesExpanded: true,
    //                     isDemandBillable: !demandDetail.planned.isNonBillable,
    //                     resources: this.getResourceRequestsForWbsDemandModel(staffingTasks, demandDetail, grmResponse, projectId, isInternal)
    //                 });
    //             } else {
    //                 const staffingDemandObject = staffingDemandModel.filter((demand) => demand.demandId === demandDetail.demandId)[0];
    //                 const resourceDetails: IStaffingResource[] = this.getResourceRequestsForWbsDemandModel(staffingTasks, demandDetail, grmResponse, projectId, isInternal);
    //                 if (resourceDetails && resourceDetails.length > 0) {
    //                     staffingDemandObject.resources.concat(this.getResourceRequestsForWbsDemandModel(staffingTasks, demandDetail, grmResponse, projectId, isInternal));
    //                 }
    //             }
    //         }
    //     }
    //     return staffingDemandModel;
    // }

    // /**
    //  * Builds the model/grid based on the demanddetails and grmresponse
    //  *
    //  * @param {IWbsDemand} wbsDemandDetails
    //  * @param {IResourceRequestResponse} grmResponse
    //  * @memberof StaffingComponent
    //  */
    // //        const filteredProjects: IProject[] = this.isProjectContext ? [this.projectStructureDetails] : this.engagementStructureDetails.projects.filter((project) => project.id === wbsDemandDetails.wbsId);
    // private loadModelBasedOnWbsDemandsGrmApiResponse(staffingTasks: IStaffingTaskModel[], wbsDemandDetails: IWbsDemand, grmResponse: IResourceRequestResponse, filteredProjects: IProject[], taskDescription: string, isInternal: boolean) {
    //     if (filteredProjects && filteredProjects.length > 0) {
    //         for (const serviceDetail of filteredProjects[0].services) {
    //             if (serviceDetail && serviceDetail.tasks && serviceDetail.tasks.length) {
    //                 for (const task of serviceDetail.tasks) {
    //                     staffingTasks = staffingTasks.filter((taskDetails) => taskDetails.taskId !== task.id);
    //                     if (this.staffingExclusionWbsL3Types.indexOf(task.workPackageType) === -1) {
    //                         const staffingTaskViewModel: IStaffingTaskModel = {
    //                             taskEngagementName: taskDescription, // isProjectContext ? filteredProjects[0].name : this.engagementStructureDetails.name,
    //                             taskProjectName: filteredProjects[0].name,
    //                             taskProjectId: filteredProjects[0].id,
    //                             projectStartDate: filteredProjects[0].startDate,
    //                             projectEnddate: filteredProjects[0].endDate,
    //                             taskCompassoneId: filteredProjects[0].compassOnePackageId,
    //                             taskServiceName: serviceDetail.name,
    //                             taskServiceId: serviceDetail.id,
    //                             taskName: task.name,
    //                             taskId: task.id,
    //                             taskTypeCode: task.workPackageType,
    //                             currency: filteredProjects[0].currency,
    //                             taskCompassPackageId: filteredProjects[0].compassOnePackageId,
    //                             isDemandsExpanded: true,
    //                             demands: this.getDemandDetailsBasedOnWbsDemands(staffingTasks, task, wbsDemandDetails, grmResponse, filteredProjects[0].id, isInternal)
    //                         };
    //                         staffingTasks.push(staffingTaskViewModel);
    //                         this.addToStaffingViewModel(staffingTaskViewModel);
    //                     }
    //                 }
    //             }
    //         }
    //         this.populateDemandStaffedCostLabor(staffingTasks);
    //     }
    // }

    // /**
    //  * Add the taskModel to the View Model object containing the project details , so the grid data can be iterated based on the view model.
    //  *
    //  * @param {IStaffingTaskModel} staffingTaskViewModel
    //  * @memberof StaffingComponent
    //  */
    // private addToStaffingViewModel(staffingTaskViewModel: IStaffingTaskModel): void {
    //     const filteredViewModel = this.rolesViewModelRequests.filter((entity) => entity.taskProjectId === staffingTaskViewModel.taskProjectId);
    //     if (filteredViewModel && filteredViewModel.length) {
    //         filteredViewModel[0].taskModelDetails = filteredViewModel[0].taskModelDetails.filter((taskDetails) => taskDetails.taskId !== staffingTaskViewModel.taskId);
    //         filteredViewModel[0].taskModelDetails.push(staffingTaskViewModel);
    //     } else {
    //         const rolesViewModelRequests: IStaffingViewModel = {
    //             taskProjectName: staffingTaskViewModel.taskProjectName,
    //             taskProjectId: staffingTaskViewModel.taskProjectId,
    //             projectStartDate: staffingTaskViewModel.projectStartDate,
    //             projectEndDate: staffingTaskViewModel.projectEnddate,
    //             isTasksExpanded: false,
    //             taskModelDetails: [staffingTaskViewModel]
    //         };
    //         this.rolesViewModelRequests.push(rolesViewModelRequests);
    //     }
    //     // this.staffingViewModelDeepCopy = this.rolesViewModelRequests;
    // }


}