import * as fromInternalFinancialDetails from "./internal-financial-details.action";
import { IInternalEngagementFinancialsListV2 } from "../../common/services/contracts/portfolio.model";
import { ILoadableState } from "../reducers";

export interface IInternalFinancialDetailsState extends ILoadableState {
    financialDetails: IInternalEngagementFinancialsListV2[];
}

export const initialState: IInternalFinancialDetailsState = {
    financialDetails: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromInternalFinancialDetails.InternalFinancialDetailsActions): { [engagementId: string]: IInternalFinancialDetailsState } {
    switch (action.type) {
        case fromInternalFinancialDetails.InternalFinancialDetailsActionTypes.LOAD_INTERNAL_FINANCIAL_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromInternalFinancialDetails.InternalFinancialDetailsActionTypes.LOAD_MANUAL_INTERNAL_FINANCIAL_DETAILS_V2: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromInternalFinancialDetails.InternalFinancialDetailsActionTypes.LOAD_INTERNAL_FINANCIAL_DETAILS_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                financialDetails: action.financialDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromInternalFinancialDetails.InternalFinancialDetailsActionTypes.LOAD_INTERNAL_FINANCIAL_DETAILS_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromInternalFinancialDetails.InternalFinancialDetailsActionTypes.INVALIDATE_INTERNAL_FINANCIAL_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}