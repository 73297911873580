import { Action } from "@ngrx/store";
import { IProcessNotification } from "../../common/services/contracts/process-notifications.contracts";

export enum WriteOperationNotificationsActionTypes {
    UPDATE_WRITE_OPERATION = "[WriteOperationNotifications] Update",
}

export class UpdateWriteOperationNotifications implements Action {
    public readonly type = WriteOperationNotificationsActionTypes.UPDATE_WRITE_OPERATION;
    public constructor(public operation: IProcessNotification) { }
}

// 3. export the union creators
export type WriteOperationActions = UpdateWriteOperationNotifications;
