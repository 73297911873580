import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, NotificationEvent, WBSResponseMessages, AccessibilityConstants, EbsEditMessages } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService, NotificationModelsForProject } from "../../../../common/services/dmnotification.service";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { IEngagementDetailsV2, IProjectDetailsV2, IServiceDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IWbsEditProjectDetailsV2, IDateValidationRequest, WbsLevel, IDateValidationResult } from "../../../../common/services/contracts/wbs.service.contracts";
import { IScheduledDates, IResourceRequestResponse, IProjectRequest } from "../../../../common/services/contracts/staffing.service.contract";
import { MyPortfolioService } from "../../../../common/services/my-portfolio.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StaffingService, IDemandSourceObject } from "../../../../common/services/staffing.service";
import { WBSService } from "../../../../common/services/wbs.service";
import moment from "moment";
import { DmNotificationService } from "../../../../common/services/dm-notification.service";
import { NotificationType } from "../../../../common/services/contracts/notification-bar.contracts";
import { v4 as uuid } from "uuid";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { DmError } from "../../../../common/error.constants";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, AbstractControl, Validators, FormBuilder } from "@angular/forms";
import { INotificationMessages } from "../../../../common/services/contracts/financial.service.contracts";
import { EnumUpdateLevel } from "../../../../common/services/contracts/wbs-engagement.contracts";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { INavigationListItemAttribute } from "../../../../components/navigation/navigationlist.model";
import { StateService } from "@uirouter/angular";
import { ManageEbsService } from "../../../../common/services/manage-ebs.service";

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    templateUrl: "./edit-project-details.html",
    styleUrls: ["./edit-project-details.scss"]
})
export class EbsEditProjectDetailsModalComponent extends DmModalAbstract {
    @Input() public selectedProject: IProjectDetailsV2;
    @Input() public engagementDetails: IEngagementDetailsV2;
    @Input() public isInternal: boolean = false;
    @Input() public showNbueHours: boolean = true;
    public engagementEndDate: Date;
    public engagementStartDate: Date;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public showActualsStartDateConflictMessage: boolean = false;
    public showActualsEndDateConflictMessage: boolean = false;
    public loadingText: string;
    public disableEBSStartDateEndDateUpdates: boolean = false;
    public viewResourceEnable: boolean = false;
    public showConflictingResources: boolean;
    public noOfConflictResources: number;
    public EnumUpdateLevel: typeof EnumUpdateLevel = EnumUpdateLevel;
    public minDate: Date;
    public maxStartDate: Date;
    public minStartDate: Date;
    public minEndDate: Date;
    public maxEndDate: Date;
    public isUpdateActive: boolean = false;
    public isResourceRequestsLoading: boolean;
    public editProjectErrorMessages = DmError.EbsStructure;
    public editProjectDetailsForm: FormGroup;

    public get projectName(): AbstractControl {
        return this.editProjectDetailsForm.get("projectName");
    }
    public get projectDescription(): AbstractControl {
        return this.editProjectDetailsForm.get("projectDescription");
    }
    public get projectStartDate(): AbstractControl {
        return this.editProjectDetailsForm.get("projectStartDate");
    }
    public get projectEndDate(): AbstractControl {
        return this.editProjectDetailsForm.get("projectEndDate");
    }
    public get updateLevel(): AbstractControl {
        return this.editProjectDetailsForm.get("updateLevel");
    }

    public modalContent: IModal;
    public showCascadeRadioButtons: boolean;
    public showCascadeRadioButtonsBlock: boolean = false;
    public disableProjectOnlyOption: boolean;
    public isUpdatingProject: boolean;
    public isBeforeEngagementStartDate: boolean = false;
    public isAfterEngagementEndDate: boolean = false;
    public isAfterChildServiceStartDate: boolean = false;
    public isBeforeChildServiceEndDate: boolean = false;
    public childServiceStartDate: Date;
    public childServiceEndDate: Date;
    public isDateCompare: boolean = false;
    public showIODateValidationMessage: boolean;
    public isNamePristine: boolean = true;
    public isDescriptionPristine: boolean = true;
    public isStartDatePristine: boolean = true;
    public isEndDatePristine: boolean = true;
    public editProjectDataRequest: IWbsEditProjectDetailsV2 = {};
    public displayConfirmationButtons = false;
    public validationMessages: string[] = [];
    public isUserValidationRequired: boolean = false;
    public isChangeAllowed: boolean = true;
    public showLoader: boolean = false;

    private validProjectDates: IScheduledDates;
    private conflictResourceRequestStatus: string[];
    private serviceResponseMessages: IResponseMessage;
    private notificationMessage: INotificationMessages;
    private readonly FXP_CONSTANTS = FxpConstants;
    private resourceRequests: IProjectRequest[] = [];
    private currentTab: INavigationListItemAttribute;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DmDisplayDateOrDashPipe) private dmDisplayDateOrDashPipe: DmDisplayDateOrDashPipe,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(StateService) private stateService: StateService,
        @Inject(ManageEbsService) private manageEbsService: ManageEbsService) {
        super(activeModal, dmLogger, Components.ManageEbsEditProjectDetails);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.modalContent = {
            title: "Edit Project Details"
        };
        this.serviceResponseMessages = WBSResponseMessages.Project;
        this.notificationMessage = this.configurationService.getValue<any>("Notification");
        this.disableEBSStartDateEndDateUpdates = this.configurationService.getValue<boolean>("disableEBSStartDateEndDateUpdates");
        this.notificationMessage = this.configurationService.getValue<any>("Notification");
        this.conflictResourceRequestStatus = this.configurationService.getValue<string[]>("conflictResourceStatus");
        this.engagementStartDate = this.engagementDetails.startDate;
        this.engagementEndDate = this.engagementDetails.endDate;

        this.initializeEditProjectDetailsForm();
        this.showConflictingResources = false;
        this.noOfConflictResources = 0;
        this.updateLevel.setValue(EnumUpdateLevel.allLowerLevels);

        const projectDetails = this.selectedProject;
        const demandSourceObjects: IDemandSourceObject[] = [{ projectId: projectDetails.id, compassOnePackageId: projectDetails.compassOnePackageId }];
        this.disableEBSStartDateEndDateUpdates = true;
        this.isResourceRequestsLoading = true;
        this.showLoader = false;
        this.staffingService.getGRMRequestsProjectIdV2(demandSourceObjects).then((resRequests) => {
            this.resourceRequests = resRequests.ProjectRequests;
            this.getResourceConflictStartEndDates(resRequests).then((result: IScheduledDates) => {
                this.isResourceRequestsLoading = false;
                this.validProjectDates = result;
                this.disableEBSStartDateEndDateUpdates = false;
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isResourceRequestsLoading = false;
            });
        }).finally(()  => this.isResourceRequestsLoading = false);
    }

    /**
     * Creates the message that the user cannot change the date due to a resource conflict.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getCannotChangeDateResourceConflictMessage(): string {
        return DmError.EbsStructure.CannotChangeTheDateDueToResourcingConflict +
            ` (${this.noOfConflictResources}
        ${this.sharedFunctionsService.getWordPluralWithS("Resource", this.noOfConflictResources, false)}).`;
    }

    /**
     * Creates the message that the project end date must be after or the same as the actuals max date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateMustBeAfterActualsMessage(): string {
        return DmError.EbsStructure.ProjectEndDateSameOrLaterThanActualsPostedDate + ` (${this.dmDisplayDateOrDashPipe.transform(this.selectedProject.maxDate)}.`;
    }

    /**
     * Creates the message that the project start date must be before or same as the actuals min date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeBeforeActualsMessage(): string {
        return DmError.EbsStructure.ProjectStartDateSameOrEarlierThanActualsDate + ` (${this.dmDisplayDateOrDashPipe.transform(this.selectedProject.minDate)}).`;
    }

    /**
     * Creates the message that the project end date cannot be earlier than or the same as the service end date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateCannotBeBeforeServiceEndMessage(): string {
        return DmError.EbsStructure.ProjectEndDateCannotBeEarlierOrSameAsService + ` (${this.dmDisplayDateOrDashPipe.transform(this.childServiceEndDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeTheDateAtServiceLevel;
    }

    /**
     * Creates the message that the project start date must be later than or the same as the engagement start date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeAfterEngStartMessage(): string {
        return DmError.EbsStructure.ProjectStartDateMustBeSameOrLaterThanEngagement + ` (${this.dmDisplayDateOrDashPipe.transform(this.engagementStartDate)})`;
    }

    /**
     * Creates the message that the project end date must be earlier then or the same as the engagement end date.
     * Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateMustBeBeforeEngEndMessage(): string {
        return DmError.EbsStructure.ProjectEndDateMustBeSameOrEarlierThanEngagement + ` (${this.dmDisplayDateOrDashPipe.transform(this.engagementEndDate)}).`;
    }

    /**
     * Creates the message that the project start date must be earlier than or the same as the service
     * start date. Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeEarlierThanServiceMessage(): string {
        return DmError.EbsStructure.ProjectStartDateMustSameOrEarlierThanService + ` (${this.dmDisplayDateOrDashPipe.transform(this.childServiceStartDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeTheDateAtServiceLevel;
    }

    public onUpdateLevelChange(updatedLevel: EnumUpdateLevel): void {
        this.updateLevel.setValue(updatedLevel);
        this.hideErrorMessages();
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalid: boolean): boolean { // TODO: should be removed later as this needs to reactive form and these boolean flags should be not be used for validations.
        if (formInvalid) {
            return true;
        }
        if (this.projectName.value && this.projectName.value.length < 3) {
            return true;
        }

        const dates: boolean = this.isStartDateRequired
            || this.isEndDateRequired
            || this.isDateCompare
            || this.isBeforeEngagementStartDate
            || this.isAfterEngagementEndDate;
        if (dates) {
            return true;
        }

        if (
            this.showActualsStartDateConflictMessage || this.showConflictingResources || this.showActualsEndDateConflictMessage || this.showIODateValidationMessage
        ) {
            return true;
        }

        this.isNamePristine = this.projectName.value === this.selectedProject.name;
        this.isDescriptionPristine = this.projectDescription.value === this.selectedProject.description;
        this.isStartDatePristine = moment(this.selectedProject.startDate).isSame(this.projectStartDate.value);
        this.isEndDatePristine = moment(this.selectedProject.endDate).isSame(this.projectEndDate.value);

        const isFormPristine: boolean = this.isNamePristine
            && this.isDescriptionPristine
            && this.isStartDatePristine
            && this.isEndDatePristine;

        return isFormPristine;
    }

    // Helper function to check the response structure of Date Change Validation API
    public isValidResponseStructure = (response: IDateValidationResult): boolean => {
        return response &&
            response.dbResponse &&
            typeof response.dbResponse.isChangeAllowed !== "undefined" &&
            typeof response.dbResponse.statusMessage !== "undefined";
    };
    

    /**
     * Updates the Project Details by calling the API, sending any notifications, etc.
     */
    public validateAndUpdateProject(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.ValidateAndUpdateProject, LogEventConstants.ManageEBSEditProjectDetailsSubmitClicked);

        let editProjectHasDate: boolean = false;
        this.isUpdateActive = true;

        this.editProjectDataRequest = {};
        if (this.projectName.value !== this.selectedProject.name) {
            this.editProjectDataRequest.name = this.projectName.value;
        }
        if (this.projectDescription.value !== this.selectedProject.description) {
            this.editProjectDataRequest.description = this.projectDescription.value;
        }
        if (!moment(this.projectStartDate.value as Date).isSame(this.selectedProject.startDate as Date, "day")) {
            editProjectHasDate = true;
            this.editProjectDataRequest.startDate = moment(this.projectStartDate.value as Date).format(DATE_FORMAT);
            this.editProjectDataRequest.shouldCascadeUpdate = this.updateLevel.value !== EnumUpdateLevel.projectLevel;
        }
        if (!moment(this.projectEndDate.value as Date).isSame(this.selectedProject.endDate as Date, "day")) {
            editProjectHasDate = true;
            this.editProjectDataRequest.endDate = moment(this.projectEndDate.value as Date).format(DATE_FORMAT);
            this.editProjectDataRequest.shouldCascadeUpdate = this.updateLevel.value !== EnumUpdateLevel.projectLevel;
        }

        // Sends a request if any field has been modified
        // Run disconnected date change process for customer projects where date is updated
        if (editProjectHasDate) {
            const dateValidationRequest: IDateValidationRequest = {
                startDate: this.editProjectDataRequest.startDate ? this.editProjectDataRequest.startDate : moment(this.selectedProject.startDate).format(DATE_FORMAT),
                endDate: this.editProjectDataRequest.endDate ? this.editProjectDataRequest.endDate : moment(this.selectedProject.endDate).format(DATE_FORMAT),
                cascadeDown: this.editProjectDataRequest.shouldCascadeUpdate
            };
            this.showLoader = true;
            this.loadingText = "Validating Project Details";

            this.wbsService.validateWbsDates(dateValidationRequest, this.selectedProject.id).then((response: IDateValidationResult) => {
                this.showLoader = false;
                if (this.isValidResponseStructure(response)) {
                    const { isChangeAllowed, userValidationRequired, statusMessage } = response.dbResponse;
                
                    this.validationMessages = statusMessage;
                    this.isChangeAllowed = isChangeAllowed;
                    this.isUserValidationRequired = userValidationRequired;
                
                    if (!isChangeAllowed || userValidationRequired) {
                        this.displayConfirmationButtons = true;
                    } else {
                        this.editProjectDataRequest.startDate = dateValidationRequest.startDate;
                        this.editProjectDataRequest.endDate = dateValidationRequest.endDate;
                        this.executeProjectUpdate();
                    }
                } else {
                    const failureMessage: string = "Invalid response structure from Date Change Validation API.";
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.ValidateAndUpdateProject, new Error(failureMessage), failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                }
            }).catch((error) => {
                this.isUpdateActive = false;
                this.showLoader = false;
                if (error && error.status === 400 && error.data && error.data.InnerErrors && error.data.InnerErrors.length > 0) {
                    // Catch and handle SAP API validation errors.
                    this.validationMessages = [];
                    error.data.InnerErrors.forEach((innerError) => {
                        this.validationMessages.push(...innerError.Messages);
                    });
                    this.isChangeAllowed = false;
                    this.isUserValidationRequired = false;
                    this.displayConfirmationButtons = true;
                } else {
                    // Handle cases where the error structure is not as expected or other types of errors
                    const failureMessage = this.serviceResponseMessages.OnSaveFailure.replace("#", this.selectedProject.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.ValidateAndUpdateProject, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                }
            });
        } else if (this.editProjectDataRequest && this.editProjectDataRequest.name || this.editProjectDataRequest.description) {
            this.showLoader  = true;
            this.loadingText = "Updating Project Details";
            this.wbsService.updateProjectDetailsV2(this.editProjectDataRequest, this.selectedProject.id)
                .then((response: any) => {
                    this.currentTab = this.manageEbsService.getCurrentEbsTabToRefreshContents(this.stateService);
                    if (this.currentTab && this.currentTab.refreshData) {
                        this.currentTab.refreshData();
                    }
                    this.myPortfolioService.refreshMyPortfolioEngagementList();

                    const changedProperties: IChangedProperties[] = [];
                    const newName = this.editProjectDataRequest.name ? this.editProjectDataRequest.name : this.selectedProject.name;
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Name", this.selectedProject.name, newName));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("State", this.selectedProject.statusCode, this.selectedProject.statusCode));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Start Date", moment(this.selectedProject.startDate).format(DATE_FORMAT), moment(this.selectedProject.startDate).format(DATE_FORMAT)));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("End Date", moment(this.selectedProject.endDate).format(DATE_FORMAT), moment(this.selectedProject.endDate).format(DATE_FORMAT)));
                    const oldDescription = this.selectedProject.description ? this.selectedProject.description : "No previous description provided";
                    const newDescription = this.editProjectDataRequest.description ? this.editProjectDataRequest.description : this.selectedProject.description;
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Description", oldDescription, newDescription));

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotificationsUpdateEBS(this.editProjectDataRequest)) {
                        this.createLogAndSendNotification(changedProperties);
                    }
                    if (response && response.status && response.status === 206) {
                        let partialSucessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                        partialSucessMessage = partialSucessMessage.replace("#", newName);
                        this.fxpMessageService.addMessage(partialSucessMessage, this.FXP_CONSTANTS.messageType.warning);
                    } else {
                        let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                        successMessage = successMessage.replace("#", newName);
                        this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                    }
                    this.closeModal();
                })
                .catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.selectedProject.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.ValidateAndUpdateProject, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.showLoader = false;
                    this.isUpdateActive = false;
                });
        }
    }

    /**
     * Updates Project details post-confirmation.
     */
    public executeProjectUpdate(): void {
        this.loadingText = "Updating Project Details";
        this.showLoader = true;
        const orchestrationId: string = uuid();
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.DateChange, loggedInUserData.BusinessPartnerId, this.selectedProject.id, orchestrationId).then(() => {
            this.projectServiceFunction.orchestrateDateChange(this.editProjectDataRequest, this.selectedProject.id, this.selectedProject.name, orchestrationId, WbsLevel.Project, loggedInUserData.alias).then(() => {
                this.fxpMessageService.addMessage("Date Change has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                // Displays the new notification in the notification bar
                this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.selectedProject.id, orchestrationId, NotificationType.DateChange);
                this.showLoader = false;
                this.closeModal();
            });
        });
    }
    
    /**
     * Confirms and applies date changes.
     */
    public onConfirmUpdate(): void {
        this.displayConfirmationButtons = false;
        this.executeProjectUpdate();
    }
    
    /**
     * Cancels the date change process.
     */
    public onCancelUpdate(): void {
        this.editProjectDetailsForm.controls["projectStartDate"].setValue(this.selectedProject.startDate);
        this.editProjectDetailsForm.controls["projectEndDate"].setValue(this.selectedProject.endDate);
        this.isUpdateActive = false;    
        this.displayConfirmationButtons = false;
        this.validationMessages = [];
    }    


    /**
     * Toggles if the view resource is enabled
     */
    public toggleResources(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.ToggleResources, this.viewResourceEnable ? LogEventConstants.EBSEditToggleResourceCollapse : LogEventConstants.EBSEditToggleResourceExpand);
        this.viewResourceEnable = !this.viewResourceEnable;
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     * @memberof WbsProjectEditModalComponent
     */
    public onStartDateChange(startDate: Date): void {
        this.projectStartDate.setValue(startDate);
        this.showConflictingResources = false;
        if (this.validProjectDates && this.validProjectDates.scheduledStartDate) {
            if (moment(startDate).isAfter(this.validProjectDates.scheduledStartDate, "day")) {
                this.showConflictingResources = true;
            }
        }
        this.isProjectStartDateValid();
    }

    /**
     * Checks validity of the start date by checking with GRM API
     */
    public isProjectStartDateValid(): void {
        if (this.maxStartDate && (moment(this.projectStartDate.value) > moment(this.maxStartDate))) {
            return;
        }
        this.isStartDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableProjectOnlyOption = false;
        if (!this.projectStartDate.value) {
            this.isStartDateRequired = true;
            return;
        }

        this.isBeforeEngagementStartDate = false;
        this.isAfterChildServiceStartDate = false;

        if (moment(this.projectStartDate.value).isBefore(this.engagementStartDate)) {
            this.isBeforeEngagementStartDate = true;
            return;
        }

        if (!this.isEndDateGreaterThanStartDate(this.projectStartDate.value, this.projectEndDate.value)) {
            return;
        }
        if (!this.validateLowerLevelConflicts(this.projectStartDate.value, this.projectEndDate.value)) {
            return;
        }

        let returnValue = true;
        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.projectStartDate.value, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.projectEndDate.value) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.projectEndDate.value, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }
        this.showActualsStartDateConflictMessage = false;
        if (this.selectedProject.minDate && moment(this.projectStartDate.value).isAfter(this.selectedProject.minDate)) {
            this.showActualsStartDateConflictMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedProject.startDate, this.projectStartDate.value) || this.haveDatesChanged(this.selectedProject.endDate, this.projectEndDate.value);
    }


    /**
     * Capturing project end date
     *
     * @param {Date} endDate
     * @memberof WbsProjectEditModalInstanceComponent
     */
    public onEndDateChange(endDate: Date): void {
        this.projectEndDate.setValue(endDate);
        this.showConflictingResources = false;
        if (this.validProjectDates && this.validProjectDates.scheduledEndDate) {
            if (moment(endDate).isBefore(this.validProjectDates.scheduledEndDate, "day")) {
                this.showConflictingResources = true;
            }
        }
        this.isProjectEndDateValid();
    }

    /**
     * Checks validity of the end date by checking with GRM API
     */
    public isProjectEndDateValid(): void {
        if (this.minEndDate && (moment(this.projectEndDate.value) < moment(this.minEndDate))) {
            return;
        }
        this.isEndDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableProjectOnlyOption = false;
        if (!this.projectEndDate.value) {
            this.isEndDateRequired = true;
            return;
        }

        this.isAfterEngagementEndDate = false;
        this.isBeforeChildServiceEndDate = false;

        if (moment(this.projectEndDate.value).isAfter(this.engagementEndDate)) {
            this.isAfterEngagementEndDate = true;
            return;
        }

        if (!this.isEndDateGreaterThanStartDate(this.projectStartDate.value, this.projectEndDate.value)) {
            return;
        }
        if (!this.validateLowerLevelConflicts(this.projectStartDate.value, this.projectEndDate.value)) {
            return;
        }

        let returnValue = true;
        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.projectEndDate.value, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.projectStartDate.value) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.projectStartDate.value, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }

        this.showActualsEndDateConflictMessage = false;
        if (this.selectedProject.maxDate && moment(this.projectEndDate.value).isBefore(this.selectedProject.maxDate)) {
            this.showActualsEndDateConflictMessage = true;
            returnValue = false;
        }
        this.showIODateValidationMessage = false;

        /* check if the project is of type T&M BIF [using statuscode] and if enddate is greater than IO end date throw this validation */
        const isProjectBIF = this.sharedFunctionsService.isProjectECIF(this.selectedProject.userStatusCode);
        if (isProjectBIF && this.selectedProject.ioDate && moment(this.projectEndDate.value).isAfter(this.selectedProject.ioDate)) {
            this.showIODateValidationMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedProject.startDate, this.projectStartDate.value) || this.haveDatesChanged(this.selectedProject.endDate, this.projectEndDate.value);

    }

    /**
     * Hides the error messages related to updating different date levels.
     */
    public hideErrorMessages(): void {
        if (this.updateLevel.value === EnumUpdateLevel.allLowerLevels) {
            this.isAfterChildServiceStartDate = false;
            this.isBeforeChildServiceEndDate = false;
        }
    }

    /**
     * Gets the resource conflict start and end dates from staffing data.
     *
     * @private
     * @returns {Promise<IScheduledDates>}
     * @memberof WbsProjectEditModalInstanceComponent
     */
    private getResourceConflictStartEndDates(grmStaffingResponse: IResourceRequestResponse): Promise<IScheduledDates> {
        const scheduledStartDates = [];
        const scheduledEndDates = [];
        if (grmStaffingResponse && grmStaffingResponse.ProjectRequests && grmStaffingResponse.ProjectRequests.length > 0) {
            const filteredProjectRequest = grmStaffingResponse.ProjectRequests.filter((projectRequest) => projectRequest.DemandSourceId === this.selectedProject.id)[0];
            if (filteredProjectRequest) {
                const filteredResourceRequests = filteredProjectRequest.ResourceRequests.filter((resRequests) => { 
                    if (resRequests && resRequests.ResourceRequestStatusEnum) { 
                        return this.conflictResourceRequestStatus.indexOf(resRequests.ResourceRequestStatusEnum) >= 0;
                    }
                });
                if (filteredResourceRequests.length > 0) {
                    this.noOfConflictResources = filteredResourceRequests.length;
                    filteredResourceRequests.forEach((request) => {
                        scheduledStartDates.push(moment(request.ScheduledStartDate));
                        scheduledEndDates.push(moment(request.ScheduledEndDate));
                    });
                }
            }
        }
        if (scheduledStartDates.length && scheduledEndDates.length) {
            const scheduledDates = {
                scheduledStartDate: moment.min(scheduledStartDates).toDate(),
                scheduledEndDate: moment.max(scheduledEndDates).toDate()
            };
            return Promise.resolve(scheduledDates);
        } else {
            return Promise.resolve(undefined);
        }
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.wbsService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.ManageEBSProjectDetailsEdit, propertyBag);

        const notification = new NotificationModelsForProject();
        notification.engagementId = this.selectedProject.engagementId;
        notification.engagementName = this.engagementDetails ? this.engagementDetails.name : this.projectName.value;
        notification.eventName = NotificationEvent.ProjectUpdated;
        notification.projectId = this.selectedProject.id;
        notification.projectName = this.projectName.value;
        notification.eventName = NotificationEvent.ProjectUpdated;
        let sendTo = this.sharedFunctionsService.getListofPjmV2(this.engagementDetails).concat(userAlias);
        if (this.engagementDetails) {
            // Notify PJM and PPJM only of change
            sendTo = this.sharedFunctionsService.getListofPjmV2(this.engagementDetails).concat(userAlias);
        }

        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;
        let esxpNotification = this.notificationMessage.ProjectNotification;
        esxpNotification = esxpNotification.replace("#", this.projectName.value);
        this.notificationService.sendNotification(notification, false, esxpNotification);
    }

    /**
     * Compares the given start and end dates. Returns true if the dates have changed.
     * Note that the original date is a Date object, and the second date is a MomentInputObject. Todo
     * update this function if we ever improve the date type problem.
     * @param originalDate
     * @param newDate
     */
    private haveDatesChanged(originalDate: Date, newDate: Date): boolean {
        return !moment(originalDate).isSame(newDate);
    }

    /**
     * Validates any lower level conflicts by comparing dates. Returns true if valid, false if invalid
     * @param startDate
     * @param endDate
     */
    private validateLowerLevelConflicts(startDate: Date, endDate: Date): boolean {
        let isValid = true;
        this.isAfterChildServiceStartDate = false;
        if (this.selectedProject.services && this.selectedProject.services.length) {
            if (!this.childServiceStartDate) {
                this.childServiceStartDate = this.getMinServiceStartDate(this.selectedProject.services);
            }
            if (moment(startDate).isAfter(this.childServiceStartDate) && this.updateLevel.value === EnumUpdateLevel.projectLevel) {
                this.isAfterChildServiceStartDate = true;
                isValid = false;
            }
        }
        if (this.selectedProject.services && this.selectedProject.services.length) {
            if (!this.childServiceEndDate) {
                this.childServiceEndDate = this.getMaxServiceEndDate(this.selectedProject.services);
            }
            if (moment(endDate).isBefore(this.childServiceEndDate) && this.updateLevel.value === EnumUpdateLevel.projectLevel) {
                this.isBeforeChildServiceEndDate = true;
                isValid = false;
            }
        }

        if (this.isAfterChildServiceStartDate || this.isBeforeChildServiceEndDate) {
            this.isBeforeChildServiceEndDate = false;
            this.isAfterChildServiceStartDate = false;
            this.showCascadeRadioButtons = true;
            this.disableProjectOnlyOption = true;
            isValid = true;
        }
        return isValid;
    }

    /**
     * Gets the earliest (min) date from a list of services.
     */
    private getMinServiceStartDate(listOfServices: IServiceDetailsV2[]): Date {
        if (listOfServices && listOfServices.length) {
            let minStartDate: Date = listOfServices[0].startDate;
            this.selectedProject.services.forEach((service: IServiceDetailsV2) => {
                if (moment(service.startDate).isBefore(minStartDate)) {
                    minStartDate = service.startDate;
                }
            });
            return minStartDate;
        }
        return undefined;
    }

    /**
     * Gets the most recent (max) date from a list of services
     * @param listOfServices
     */
    private getMaxServiceEndDate(listOfServices: IServiceDetailsV2[]): Date {
        if (listOfServices && listOfServices.length) {
            let maxEndDate: Date = listOfServices[0].endDate;
            listOfServices.forEach((service: IServiceDetailsV2) => {
                if (moment(service.endDate).isAfter(maxEndDate)) {
                    maxEndDate = service.endDate;
                }
            });
            return maxEndDate;
        }
        return undefined;
    }

    /**
     * Is the given end date later than the given start date? True if the end date is later/greater than the start date.
     * False if the end date is earlier/less than the start date.
     */
    private isEndDateGreaterThanStartDate(startDate: Date, endDate: Date): boolean {
        if (startDate && endDate) {
            this.isDateCompare = false;
            if (moment(endDate).isBefore(startDate)) {
                this.isDateCompare = true;
                return false;
            }
        }
        return true;
    }

    /**
     * Initializes edit project form data
     */
    private initializeEditProjectDetailsForm(): void {
        this.editProjectDetailsForm = this.fb.group({
            projectName: [(this.selectedProject && this.selectedProject.name) ? this.selectedProject.name : "", Validators.required],
            projectDescription: [(this.selectedProject && this.selectedProject.description) ? this.selectedProject.description : "", Validators.required],
            projectStartDate: [this.selectedProject.startDate, [Validators.required]],
            projectEndDate: [this.selectedProject.endDate, [Validators.required]],
            updateLevel: [EnumUpdateLevel.allLowerLevels]
        });
    }

    private isErrorMessage(message: string): boolean {
        // Treat as error unless it's explicitly a known warning message
        return !this.isWarningMessage(message);
    }
    
    private isWarningMessage(message: string): boolean {
        // Check specifically for the known warning message
        return message.toLowerCase() === EbsEditMessages.FORECAST_REMOVAL_WARNING.toLowerCase();
    }
}
