import { CommonComponentsModule } from "../../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { LaborDetailsComponent } from "./labor-details.component";
import { FormsModule } from "@angular/forms";
import { ProjectService } from "../../../../common/services/project.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { PersonCardModule } from "../../../../components/shared/person-card/person-card.module";
import { ResourceDetailsModalModule } from "../../modals/resource-details/resource-details.module";
import { BulkUpdateSchedulesModalModule } from "../../modals/bulk-update/bulkupdate-schedules.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

@NgModule({
    declarations: [
        LaborDetailsComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        FormsModule,
        PersonCardModule,
        ResourceDetailsModalModule,
        BulkUpdateSchedulesModalModule,
        NgMultiSelectDropDownModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
        ProjectService
    ],
    entryComponents: [
        LaborDetailsComponent
    ],
    exports: [
        LaborDetailsComponent
    ]
})
export class LaborDetailsModule { }
