import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";

import { Components, LogEventConstants, SourceConstants, NotificationEvent, WBSResponseMessages, AccessibilityConstants, NONFTE_REGEX, BusinessTask } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService, NotificationModel, NotificationModelsForProject } from "../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IWbsEditEngagementDetailsV2, ITeamStructureForEditWBS, IWbsEditProjectDetailsV2 } from "../../../../common/services/contracts/wbs.service.contracts";
import { AuditService } from "../../../../common/services/audit.service";
import { MyPortfolioService } from "../../../../common/services/my-portfolio.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../common/services/wbs.service";
import { DmError } from "../../../../common/error.constants";
import { IOneProfileSerResAttr, ISelectedUserAttributes } from "../../../../components/tiles/type-ahead/type-ahead-contracts";
import { FilterModel } from "../../../../components/tiles/project-filter/project-filter.model";
import { IModal } from "../../../../components/modals/dm-modal-v2/dm-modal-v2.component";
import { INotificationMessages } from "../../../../common/services/contracts/financial.service.contracts";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IWbsAuditPayload, WbsAuditType } from "../../../../common/services/contracts/audit.contracts";


export interface IManager {
    name: string;
    bpId: string;
}

@Component({
    templateUrl: "./edit-team-structure.html",
    styleUrls: ["./edit-team-structure.scss"]
})
export class EditTeamStructureModalComponent extends DmModalAbstract {
    @Input() public selectedEngagement: IEngagementDetailsV2;
    public updatedEngagement: IEngagementDetailsV2; // engagement to be updated
    public filterDataSource: FilterModel[];
    public unitBasedProjects: FilterModel[];
    public modalContent: IModal;
    public isPMRequired: boolean = false;
    public typeAheadId: string;
    public taSearchAriaLblTxt: string;
    public typeAheadLabelText: string;
    public taCancelAriaLblTxt: string;
    public taRequiredMsg: string;
    public selectedUser: ISelectedUserAttributes;
    public additionalTypeAheadId: string;
    public additionalSearchAriaLblTxt: string;
    public unitSubmitterSearchAriaLblTxt: string;
    public additionalTypeAheadLabelText: string;
    public additionalCancelAriaLblTxt: string;
    public unitSubmitterCancelAriaLblTxt: string;
    public multiSelectedUsers: ISelectedUserAttributes[];
    public selectedUsers: ISelectedUserAttributes[];
    public selectedUnitSubmitters: ISelectedUserAttributes[] = [];
    public disablePPJMUpdate: boolean = false;
    public disableAPPJMUpdate: boolean = false;
    public loadingText: string;
    public isResourceRequestsLoading: boolean;
    public accessibilityConstants = AccessibilityConstants;
    public editEngagementErrorMessages = DmError.EbsStructure;
    public showNonfteValidationMessage: boolean;
    public editTeamStructureForm: FormGroup;
    public isUpdatingTeamStructure: boolean;
    public PPJMPristine: boolean = true;
    public APPJMPristine: boolean = true;

    public get ppjm(): AbstractControl {
        return this.editTeamStructureForm.get("ppjm");
    }
    public get adPPjm(): AbstractControl {
        return this.editTeamStructureForm.get("adPPjm");
    }
    public get uSubmitters(): AbstractControl {
        return this.editTeamStructureForm.get("uSubmitters");
    }
    public get pjmProjects(): AbstractControl {
        return this.editTeamStructureForm.get("pjmProjects");
    }
    public get adpjmProjects(): AbstractControl {
        return this.editTeamStructureForm.get("adpjmProjects");
    }
    public get unitProjects(): AbstractControl {
        return this.editTeamStructureForm.get("unitProjects");
    }
    public dropdownSettings = {};
    public partialSuccessProjects: string[] = [];
    public successProjects: string[] = [];
    public deletedManagers: IManager[] = [];
    public addedManagers: IManager[] = [];
    public addedUnitSubmitters: IManager[] = [];
    public ppjmDeleted: boolean = false;
    public isProjectManagerChanged: boolean;
    public isAdditonalProjectManagerChanged: boolean;
    public isUnitSubmittersAdded: boolean;
    public showUnitSubmittersUpdation: boolean;
    public isComplexUnitsFeatureEnabled: boolean = false;

    private serviceResponseMessages: IResponseMessage;
    private notificationMessage: INotificationMessages;
    private originalAdditionalPPJMBpIds: number[];
    private updatedAdditionalPPJMBpIds: number[];
    private APPJMsChanged: boolean = false;
    private originalAPPJMNames: string[] = [];
    private addedAPPJMNames: string[] = [];
    private deletedAPPJMNames: string[] = [];
    private originalAPJMNames: string[] = [];
    private addedAPJMNames: string[] = [];
    private deletedAPJMNames: string[] = [];
    private readonly FXP_CONSTANTS = FxpConstants;
    private APJMsChanged: boolean = false;
    private editEngagementDataRequest: IWbsEditEngagementDetailsV2;
    private isRefreshed: boolean = false;
    private projectUserStatusToShowBadges: string[] = [];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsEngService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(AuditService) private auditService: AuditService,
        @Inject(FormBuilder) private fb: FormBuilder
    ) {
        super(activeModal, dmLogger, Components.ManageEbsEditTeamStructure);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Edit Team Structure"
        };
        // settings for multiselect drop-down
        this.dropdownSettings = {
            singleSelection: false,
            idField: "id",
            textField: "name",
            selectAllText: "All Projects",
            unSelectAllText: "All Projects",
            itemsShowLimit: 2,
            allowSearchFilter: false,
            enableCheckAll: true,
        };
        this.isUpdatingTeamStructure = false;
        this.configurationService.initialize().then(() => {
            this.serviceResponseMessages = WBSResponseMessages.Engagement;
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
            this.isComplexUnitsFeatureEnabled = this.configurationService.isFeatureEnabled("enableComplexUnitsFeature");
            this.projectUserStatusToShowBadges = this.configurationService.getValue<string[]>("projectUserStatusToShowBadges");

            this.updatedEngagement = { ...this.selectedEngagement };
            this.setTypeAheadText();

            // Domain PPJM (single user)
            if (this.updatedEngagement.pPjm) {
                this.selectedUser = {
                    userAlias: this.updatedEngagement.pPjm.alias,
                    userName: this.updatedEngagement.pPjm.name,
                    bpId: this.updatedEngagement.pPjm.bpid,
                    emailName: "",
                    firstName: "",
                    lastName: "",
                    fullName: this.updatedEngagement.pPjm.alias
                };
            }

            // Additional PPJM (multiple users)
            this.selectedUsers = [];
            this.originalAdditionalPPJMBpIds = [];
            const adPpjmList = this.selectedEngagement.adPPjm;
            if (adPpjmList && adPpjmList.length) {
                for (const singleAdPpjm of adPpjmList) {
                    if (this.originalAdditionalPPJMBpIds.indexOf(Number(singleAdPpjm.bpid)) < 0) {
                        this.originalAdditionalPPJMBpIds.push(Number(singleAdPpjm.bpid));
                        this.originalAPPJMNames.push(singleAdPpjm.name);
                    }
                    // Create new object for the user in order to match object types used in typeahead component
                    const newAPPJM: ISelectedUserAttributes = {
                        userAlias: singleAdPpjm.alias,
                        userName: singleAdPpjm.name,
                        bpId: singleAdPpjm.bpid,
                        emailName: "",
                        firstName: "",
                        lastName: "",
                        fullName: singleAdPpjm.name
                    };
                    this.selectedUsers.push(newAPPJM);
                }
            }
            this.multiSelectedUsers = this.selectedUsers;
            this.setDisableManagerChange();
            this.setProjectData(this.selectedEngagement.projects);
            this.filterDataSource = this.getFilterDropdownData(this.selectedEngagement.projects);
            this.unitBasedProjects = this.getFilterDropdownData(this.selectedEngagement.projects.filter((p: IProjectDetailsV2) => p.hasUnitBasedDemands));
            this.showUnitSubmittersUpdation = this.unitBasedProjects.length > 0 && this.configurationService.isFeatureEnabled("isComplexUnitsFeaturesEnabled");
            this.initializeFcrForm();
        });
    }

    /**
     * Updates the selected user list with a callback from the typeahead.
     * @param users
     */
    public onAdditionalManagersUpdate(users: ISelectedUserAttributes[]): void {
        this.selectedUsers = users;
        this.adPPjm.setValue(users);
    }

    /**
     * selectedUserUpdated
     * @param value
     */
    public selectedUserUpdated(value: ISelectedUserAttributes): void {
        this.ppjm.setValue(value);
        this.selectedUser = value;
    }

    /**
     * Disable save button on user clear
     *
     * @param {boolean} isClearPjM
     * @memberof WbsEngagementEditModalComponent
     */
    public onUserCleared(isClearPjM: boolean): void {
        this.ppjmDeleted = true;
        this.ppjm.setValue({});
        this.selectedUser = undefined;
        this.saveButtonDisabled(isClearPjM);
    }

    /**
     * Added managers
     */
    public onAdditionalManagerAdded(user: IOneProfileSerResAttr): void {
        if (this.deletedManagers && this.deletedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.BusinessPartnerId)) > -1) {
            this.deletedManagers.splice(this.deletedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.BusinessPartnerId)), 1);
        }
        if (this.adPPjm.value) {
            this.addedManagers = [];
            for (const singleAPPJM of this.adPPjm.value) {
                if (singleAPPJM.bpId) {
                    if (this.originalAdditionalPPJMBpIds.indexOf(Number(singleAPPJM.bpId)) < 0) {
                        this.addedManagers.push({
                            name: singleAPPJM.fullName,
                            bpId: singleAPPJM.bpId
                        });
                    }
                }
            }
        }
    }

    /**
     * Deleted managers
     */
    public onAdditionalManagerRemoved(user: ISelectedUserAttributes): void {
        if (this.addedManagers && this.addedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.bpId)) > -1) {
            this.addedManagers.splice(this.addedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.bpId)), 1);
        }
        if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.length) {
            this.deletedManagers = [];
            for (const orignalAPPJM of this.originalAdditionalPPJMBpIds) {
                if (orignalAPPJM) {
                    if (this.adPPjm.value && this.adPPjm.value.findIndex((adPPjm) => Number(adPPjm.bpId) === orignalAPPJM) < 0) {
                        if (this.updatedEngagement.adPPjm) {
                            const appjm = this.updatedEngagement.adPPjm.filter((x) => Number(x.bpid) === orignalAPPJM)[0];
                            if (appjm) {
                                this.deletedManagers.push({
                                    name: appjm.name,
                                    bpId: appjm.bpid
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalid: boolean): boolean {
        if (NONFTE_REGEX.test(this.selectedUser && this.selectedUser.userAlias)) {
            this.showNonfteValidationMessage = true;
            return true;
        }
        this.showNonfteValidationMessage = false;

        this.PPJMPristine = true;
        if (this.selectedUser) {
            this.PPJMPristine = Number(this.selectedUser.bpId) === Number(this.updatedEngagement.pPjm.bpid);
            this.ppjmDeleted = !this.PPJMPristine;
        }
        this.APPJMPristine = true;
        this.updatedAdditionalPPJMBpIds = [];
        if (this.selectedUsers) {
            for (const singleAPPJM of this.selectedUsers) {
                if (this.updatedAdditionalPPJMBpIds.indexOf((Number(singleAPPJM.bpId))) < 0) {
                    this.updatedAdditionalPPJMBpIds.push(Number(singleAPPJM.bpId));
                }
            }
        }

        if (this.originalAdditionalPPJMBpIds) {
            if ((this.originalAdditionalPPJMBpIds.length
                && ((this.updatedAdditionalPPJMBpIds.length && this.originalAdditionalPPJMBpIds.sort().join(",") !== this.updatedAdditionalPPJMBpIds.sort().join(","))
                    || (this.adPPjm && this.adPPjm.value && !this.adPPjm.value.length)))
                || (!this.originalAdditionalPPJMBpIds.length && this.updatedAdditionalPPJMBpIds.length)) {
                this.APPJMPristine = false;
            }
        }

        this.isProjectManagerChanged = this.pjmProjects && this.pjmProjects.value && this.pjmProjects.value.length && this.isPjmChanged();
        this.isAdditonalProjectManagerChanged = this.adpjmProjects && this.adpjmProjects.value && this.adpjmProjects.value.length && this.isAdPjmChanged();
        this.isUnitSubmittersAdded = this.unitProjects && this.unitProjects.value && this.unitProjects.value.length;

        const isFormPristineNew: boolean = this.APPJMPristine && this.PPJMPristine && !this.isProjectManagerChanged && !this.isAdditonalProjectManagerChanged && !this.isUnitSubmittersAdded;
        return formInvalid || isFormPristineNew;
    }

    /**
     * Updates the Engagement Details by calling the API, sending any notifications, etc.
     */
    public async updateTeamDetails(): Promise<void> {
        this.loadingText = "Updating Team Structure";
        this.startBusinessProcessTelemetry(BusinessTask.AddOrRemoveTeamStructure);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.UpdateTeamDetails, LogEventConstants.ManageEBSEditTeamStructureSubmitClicked);

        const modifiedPPJMList: ITeamStructureForEditWBS[] = [];
        let ppjmChanged: boolean = false;
        if (this.ppjm.value && Number(this.ppjm.value.bpId) !== Number(this.selectedEngagement.pPjm.bpid)) {
            modifiedPPJMList.push({
                role: "PPJM",
                bpId: this.ppjm.value.bpId
            });
            ppjmChanged = true;
        }

        // if id is not present in new but it is present in original then manager is deleted
        if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.length) {
            for (const orignalAPPJM of this.originalAdditionalPPJMBpIds) {
                if (orignalAPPJM) {
                    if (this.adPPjm.value && this.adPPjm.value.findIndex((adPPjm) => Number(adPPjm.bpId) === orignalAPPJM) < 0) {
                        modifiedPPJMList.push({
                            role: "ADPPJM",
                            bpId: orignalAPPJM.toString(),
                            isDelete: true // An original APJM is no longer selected, set isDelete to True
                        });
                        if (this.updatedEngagement.adPPjm) {
                            const appjm = this.updatedEngagement.adPPjm.filter((x) => Number(x.bpid) === orignalAPPJM)[0];
                            if (appjm) {
                                this.deletedAPPJMNames.push(appjm.name);
                                this.APPJMsChanged = true;
                            }
                        }
                    }
                }
            }
        }

        // if id is present in new but it is not present in original then manager is added
        if (this.adPPjm.value) {
            for (const singleAPPJM of this.adPPjm.value) {
                if (singleAPPJM.bpId) {
                    if (this.originalAdditionalPPJMBpIds.indexOf(Number(singleAPPJM.bpId)) < 0) {
                        modifiedPPJMList.push({
                            role: "ADPPJM",
                            bpId: singleAPPJM.bpId,
                            isDelete: false // New APJM added, set isDelete to False
                        });
                        this.addedAPPJMNames.push(singleAPPJM.userName);
                        this.APPJMsChanged = true;
                    }
                }
            }
        }

        this.editEngagementDataRequest = {};
        if (modifiedPPJMList.length > 0) {
            this.editEngagementDataRequest.teamStructure = [];
            this.editEngagementDataRequest.teamStructure = modifiedPPJMList;
        }
        if (ppjmChanged || this.APPJMsChanged) {
            this.isUpdatingTeamStructure = true;
            this.wbsEngService.updateEngagementDetailsV2(this.editEngagementDataRequest, this.selectedEngagement.id)
                .then((response: any) => {
                    if (this.editEngagementDataRequest.teamStructure && this.editEngagementDataRequest.teamStructure.length) {
                        this.engagementDetailService.invalidateStoreOnRefresh(this.selectedEngagement.id);
                        this.myPortfolioService.refreshMyPortfolioEngagementList();
                        this.isRefreshed = false;
                    }

                    const changedProperties: IChangedProperties[] = [];
                    if (this.editEngagementDataRequest.teamStructure && this.editEngagementDataRequest.teamStructure.length > 0 && this.editEngagementDataRequest.teamStructure.filter((team) => team.role === "PPJM").length > 0) {
                        this.wbsEngService.pushToArrayIfTrue(changedProperties, this.wbsEngService.createChangedPropertyObject("Primary Domain Project Manager", this.selectedEngagement.pPjm.name, this.ppjm.value.userName));
                    }

                    if (this.editEngagementDataRequest.teamStructure && this.editEngagementDataRequest.teamStructure.length > 0 && this.editEngagementDataRequest.teamStructure.filter((team) => team.role === "ADPPJM").length > 0) {
                        let updateMessage: string = "";
                        let origMessage: string = "";
                        for (const name of this.originalAPPJMNames) {
                            origMessage += name + ", ";
                            if (this.deletedAPPJMNames.indexOf(name) < 0) {
                                updateMessage += name + ", ";
                            }
                        }
                        for (const name of this.addedAPPJMNames) {
                            updateMessage += name + ", ";
                        }
                        updateMessage = !updateMessage ? "No Additional Primary Domain Project Managers" : updateMessage.slice(0, -2);
                        origMessage = !origMessage ? "No previous Additional Primary Domain Project Managers" : origMessage.slice(0, -2);
                        this.wbsEngService.pushToArrayIfTrue(changedProperties, this.wbsEngService.createChangedPropertyObject("Additional Primary Domain Project Managers", origMessage, updateMessage));
                    }

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotificationsUpdateEBS(this.editEngagementDataRequest)) {
                        this.createLogAndSendNotification(changedProperties);
                    }
                    if (response && response.status && response.status === 206) {
                        let partialSucessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                        partialSucessMessage = partialSucessMessage.replace("#", this.updatedEngagement.name);
                        this.fxpMessageService.addMessage(partialSucessMessage, this.FXP_CONSTANTS.messageType.warning);
                    } else {
                        let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                        successMessage = successMessage.replace("#", this.updatedEngagement.name);
                        this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                    }                    
                    this.cascadeChangesToProjects();
                    this.logEngagementTeamStructureUpdateToAudit(this.editEngagementDataRequest);
                    this.closeModal();
                    this.endBusinessProcessTelemetry(BusinessTask.AddOrRemoveTeamStructure);
                })
                .catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.updatedEngagement.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.UpdateTeamDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUpdatingTeamStructure = false;
                });
        } else if ((this.pjmProjects && this.pjmProjects.value && this.pjmProjects.value.length) || (this.adpjmProjects && this.adpjmProjects.value && this.adpjmProjects.value.length) || ((this.unitProjects && this.unitProjects.value && this.unitProjects.value.length))) {
            this.cascadeChangesToProjects();
            this.endBusinessProcessTelemetry(BusinessTask.AddOrRemoveTeamStructure);
        } else {
            this.closeModal();
        }
    }

    /**
     * Sets pjm projects on selection of all the options in multi-select drop-down
     */
    public onPjmProjectsSelectAll(item: string[]): void {
        this.pjmProjects.setValue(item);
    }

    /**
     * Sets pjm projects on deselection of all the options in multi-select drop-down
     */
    public onPjmProjectsDeSelectAll(): void {
        this.pjmProjects.setValue([]);
    }

    /**
     * Sets pjm projects on selection/deselection of all the options in multi-select drop-down
     */
    public onPjmProjectDeSelect(item: string[]): void {
        const items = this.pjmProjects.value;
        const index = items.indexOf(item);
        if (index >= 0) {
            items.splice(index, 1);
        }
        this.pjmProjects.setValue(items);
    }


    /**
     * Sets additional pjm projects on selection of all the options in multi-select drop-down
     */
    public onAdPjmProjectsSelectAll(item: string[]): void {
        this.adpjmProjects.setValue(item);
    }

    /**
     * Sets additional pjm projects on deselection of all the options in multi-select drop-down
     */
    public onAdPjmProjectsDeSelectAll(): void {
        this.adpjmProjects.setValue([]);
    }

    /**
     * Sets additional pjm projects on selection/deselection of all the options in multi-select drop-down
     */
    public onAdpjmProjectDeSelect(item: string[]): void {
        const items = this.adpjmProjects.value;
        const index = items.indexOf(item);
        if (index >= 0) {
            items.splice(index, 1);
        }
        this.adpjmProjects.setValue(items);
    }

    /**
     * returns whether the pjm is changed for selected projects
     */
    public isPjmChanged(): boolean {
        let pjmChanged: boolean = false;
        if (this.pjmProjects && this.pjmProjects.value && this.pjmProjects.value.length) {
            for (const project of this.pjmProjects.value) {
                const filteredProject = this.selectedEngagement.projects.filter((selectedProject) => selectedProject.id === project.id)[0];
                if (filteredProject) {
                    if (this.ppjm && this.ppjm.value && filteredProject.pjm && Number(this.ppjm.value.bpId) !== Number(filteredProject.pjm.bpid)) {
                        pjmChanged = true;
                        break;
                    }
                }
            }
        }
        return pjmChanged;
    }

    /**
     * * returns whether the additional pjm is changed for selected projects
     */
    public isAdPjmChanged(): boolean {
        let additionalPjmChanged = false;
        if (this.adpjmProjects && this.adpjmProjects.value && this.adpjmProjects.value.length) {
            for (const project of this.adpjmProjects.value) {
                const filteredProject = this.selectedEngagement.projects.filter((selectedProject) => selectedProject.id === project.id)[0];
                if (filteredProject) {
                    // Creating Additional PJMs for UI (multiple users typeahead)
                    const originalAdditionalPJMBpIds = [];
                    if (filteredProject.adPjm && filteredProject.adPjm.length) {
                        for (const singleAPJM of filteredProject.adPjm) {
                            if (originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpid)) < 0) {
                                originalAdditionalPJMBpIds.push(Number(singleAPJM.bpid));
                            }
                        }
                    }

                    if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.length) {
                        for (const orignalAPPJM of this.originalAdditionalPPJMBpIds) {
                            if (orignalAPPJM) {
                                if (this.adPPjm.value && this.adPPjm.value.findIndex((adPPjm) => Number(adPPjm.bpId) === orignalAPPJM) < 0) {
                                    if (originalAdditionalPJMBpIds && originalAdditionalPJMBpIds.length) {
                                        for (const orignalAPJM of originalAdditionalPJMBpIds) {
                                            if (orignalAPJM) {
                                                if (orignalAPPJM === orignalAPJM) {
                                                    additionalPjmChanged = true;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else if (originalAdditionalPJMBpIds && originalAdditionalPJMBpIds.length && this.adPPjm && this.adPPjm.value && this.adPPjm.value.length) {
                        additionalPjmChanged = true;
                        break;
                    }

                    if (this.adPPjm && this.adPPjm.value && this.adPPjm.value.length) {
                        for (const singleAPJM of this.adPPjm.value) {
                            if (singleAPJM.bpId) {
                                if (originalAdditionalPJMBpIds && ((originalAdditionalPJMBpIds.length && originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpId)) < 0)
                                    || !originalAdditionalPJMBpIds.length)) {
                                    additionalPjmChanged = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return additionalPjmChanged;
    }
   
    /* Events/Methods related to Unit Submitters */

    /**
     * Updates the selected user list with a callback from the typeahead.
     * @param users
     */
    public onUnitSubmittersUpdate(users: ISelectedUserAttributes[]): void {
        this.selectedUnitSubmitters = users;        
    }

    /**
     * Deleted managers
     */
    public onUnitSubmitterRemoved(user: ISelectedUserAttributes): void {
        this.addedUnitSubmitters = this.addedUnitSubmitters.filter((u) => u.bpId !== user.bpId);
    }

    /**
     * Added managers
     */
    public onUnitSubmitterAdded(): void {       
        this.updateUnitSubmittersInfo();
    }

   

    /**
     * Sets unit projects on selection of all the options in multi-select drop-down
     */
    public onUnitProjectsSelectAll(item: string[]): void {
        this.unitProjects.setValue(item);
    }

    /**
     * Sets unit projects on deselection of all the options in multi-select drop-down
     */
    public onUnitProjectsDeSelectAll(): void {
        this.unitProjects.setValue([]);
    }

    /**
     * Sets unit projects on selection/deselection of all the options in multi-select drop-down
     */
    public onUnitProjectDeSelect(item: string[]): void {
        const items = this.unitProjects.value;
        const index = items.indexOf(item);
        if (index >= 0) {
            items.splice(index, 1);
        }
        this.unitProjects.setValue(items);
    }

    /**
     * Cascade changes to selected projects
     */
    private async cascadeChangesToProjects(): Promise<void> {
        let selectedCascadeProjects = [];
        let unitCascadeProjects = [];
        if (this.adpjmProjects && this.adpjmProjects.value && this.adpjmProjects.value.length) {
            selectedCascadeProjects = [... this.adpjmProjects.value];
        }
        if (this.unitProjects && this.unitProjects.value && this.unitProjects.value.length) {
            unitCascadeProjects = [... this.unitProjects.value];
        }
        if (this.pjmProjects && this.pjmProjects.value && this.pjmProjects.value.length) {
            for (const project of this.pjmProjects.value) {
                const filteredProject = this.selectedEngagement.projects.filter((selectedProject) => selectedProject.id === project.id)[0];
                const modifiedPJMList: ITeamStructureForEditWBS[] = [];
                if (filteredProject) {
                    let pjmChanged: boolean = false;
                    // Creating Additional PJMs for UI (multiple users typeahead)
                    const originalAdditionalPJMBpIds = [];
                    this.originalAPJMNames = [];
                    if (filteredProject.adPjm) {
                        for (const singleAPJM of filteredProject.adPjm) {
                            if (originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpid)) < 0) {
                                originalAdditionalPJMBpIds.push(Number(singleAPJM.bpid));
                                this.originalAPJMNames.push(singleAPJM.name);
                            }
                        }
                    }
                    if (this.ppjm.value && Number(this.ppjm.value.bpId) !== Number(filteredProject.pjm.bpid)) {
                        modifiedPJMList.push({
                            projectRole: "PJM",
                            bpId: this.ppjm.value.bpId
                        });
                        pjmChanged = true;
                    }
                    if (this.adpjmProjects && this.adpjmProjects.value && this.adpjmProjects.value.length) {
                        const cascadeAdPjm = this.adpjmProjects.value.filter((adpjmProject) => adpjmProject.id === project.id);

                        if (cascadeAdPjm && cascadeAdPjm.length) {
                            this.createAPJMList(filteredProject, originalAdditionalPJMBpIds, modifiedPJMList);
                            selectedCascadeProjects.splice(this.adpjmProjects.value.indexOf(cascadeAdPjm[0]), 1);
                        }
                    }
                    if (this.unitProjects && this.unitProjects.value && this.unitProjects.value.length) {
                        const unitProjectpjmDetails = this.unitProjects.value.filter((unitProj) => unitProj.id === project.id);

                        if (unitProjectpjmDetails && unitProjectpjmDetails.length) {
                            // this.updateModifiedPJMList(this.addedUnitSubmitters, modifiedPJMList);
                            unitCascadeProjects.splice(this.unitProjects.value.indexOf(unitProjectpjmDetails[0]), 1);
                        }
                    }
                    if (pjmChanged || this.APJMsChanged) {
                        this.isUpdatingTeamStructure = true;
                        await this.updateProjectTeamStructure(modifiedPJMList, filteredProject);
                        this.APJMsChanged = false;
                    }
                }
            }
        }

        if (selectedCascadeProjects && selectedCascadeProjects.length) {
            for (const project of selectedCascadeProjects) {
                const filteredProject = this.selectedEngagement.projects.filter((selectedProject) => selectedProject.id === project.id)[0];
                if (filteredProject) {
                    // Creating Additional PJMs for UI (multiple users typeahead)
                    const originalAdditionalPJMBpIds = [];
                    this.originalAPJMNames = [];
                    if (filteredProject.adPjm) {
                        for (const singleAPJM of filteredProject.adPjm) {
                            if (originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpid)) < 0) {
                                originalAdditionalPJMBpIds.push(Number(singleAPJM.bpid));
                                this.originalAPJMNames.push(singleAPJM.name);
                            }
                        }
                    }
                    const modifiedPJMList: ITeamStructureForEditWBS[] = [];
                    this.createAPJMList(filteredProject, originalAdditionalPJMBpIds, modifiedPJMList);
                    if (this.unitProjects && this.unitProjects.value && this.unitProjects.value.length) {
                        const unitProjectpjmDetails = this.unitProjects.value.filter((unitProj) => unitProj.id === project.id);

                        if (unitProjectpjmDetails && unitProjectpjmDetails.length) {
                            this.updateModifiedPJMList(this.addedUnitSubmitters, modifiedPJMList);
                            unitCascadeProjects.splice(this.unitProjects.value.indexOf(unitProjectpjmDetails[0]), 1);
                        }
                    }
                    if (this.APJMsChanged || modifiedPJMList.length > 0 ) {
                        this.isUpdatingTeamStructure = true;
                        await this.updateProjectTeamStructure(modifiedPJMList, filteredProject);
                        this.APJMsChanged = false;
                    }
                }
            }
        }

        if (unitCascadeProjects && unitCascadeProjects.length > 0) {
            const modifiedPJMList: ITeamStructureForEditWBS[] = [];            
            for (const project of unitCascadeProjects) {
                const filteredProject = this.selectedEngagement.projects.filter((selectedProject) => selectedProject.id === project.id)[0];
                if (filteredProject) {
                    if (this.unitProjects && this.unitProjects.value && this.unitProjects.value.length) {
                        const unitProjectpjmDetails = this.unitProjects.value.filter((unitProj) => unitProj.id === project.id);

                        if (unitProjectpjmDetails && unitProjectpjmDetails.length) {
                            this.updateModifiedPJMList(this.addedUnitSubmitters, modifiedPJMList);                            
                        }
                        if (modifiedPJMList.length > 0) {
                            this.isUpdatingTeamStructure = true;
                            await this.updateProjectTeamStructure(modifiedPJMList, filteredProject);                           
                        }
                    }
                }
            }                 
        }

        if (this.partialSuccessProjects && this.partialSuccessProjects.length) {
            let partialSuccessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
            const projects = this.partialSuccessProjects.join(",");
            partialSuccessMessage = partialSuccessMessage.replace("#", projects);
            this.fxpMessageService.addMessage(partialSuccessMessage, this.FXP_CONSTANTS.messageType.warning);
            if (!this.isRefreshed) {
                this.engagementDetailService.invalidateStoreOnRefresh(this.selectedEngagement.id);
                this.myPortfolioService.refreshMyPortfolioEngagementList();
                this.isRefreshed = true;
            }
        }

        if (this.successProjects && this.successProjects.length) {
            let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
            const projects = this.successProjects.join(",");
            successMessage = successMessage.replace("#", projects);
            this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
            if (!this.isRefreshed) {
                this.engagementDetailService.invalidateStoreOnRefresh(this.selectedEngagement.id);
                this.myPortfolioService.refreshMyPortfolioEngagementList();
                this.isRefreshed = true;
            }
        }

    }

    /**
     * Update the Modified PJM List to Include Unit Submitters
     */
    private updateModifiedPJMList(addedUnitSubmitters: IManager[], modifiedPJMList: ITeamStructureForEditWBS[]) {
        if (addedUnitSubmitters && addedUnitSubmitters.length > 0) {
            for (const unitSubmitter of addedUnitSubmitters) {
                modifiedPJMList.push({
                    projectRole: "ZUNI",
                    bpId: unitSubmitter.bpId,
                    isDelete: false 
                });
            }
        }     
    }
    
    /**
     * Update the UnitSubmitters Object to display data on info
     */
    private updateUnitSubmittersInfo(): void {
        if (this.uSubmitters.value) {
            this.addedUnitSubmitters = [];
            for (const unitSubmitter of this.uSubmitters.value) {
                if (unitSubmitter.bpId) {
                    this.addedUnitSubmitters.push({
                        name: unitSubmitter.fullName,
                        bpId: unitSubmitter.bpId
                    });
                }
            }
        }
    }

    /**
     * Sets the disabled flag for editing the manager and additional manager fields based on
     * the logged in user and the users within the team.
     */
    private setDisableManagerChange(): void {
        const currentUser: number = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        const pPjmBpid = (this.updatedEngagement && this.updatedEngagement.pPjm && this.updatedEngagement.pPjm.bpid) ? Number(this.updatedEngagement.pPjm.bpid) : undefined;
        const delPPjmBpid = (this.updatedEngagement && this.updatedEngagement.delPPjm && this.updatedEngagement.delPPjm.bpid) ? Number(this.updatedEngagement.delPPjm.bpid) : undefined;
        const dmmBpid = (this.updatedEngagement && this.updatedEngagement.dmmPPjm && this.updatedEngagement.dmmPPjm.bpid) ? Number(this.updatedEngagement.dmmPPjm.bpid) : undefined;
        
        /* If Current user is a PPJM, then user can update the PPJM, APPJM values else disable update */
        if (pPjmBpid && currentUser === pPjmBpid) {
            this.disableAPPJMUpdate = false;
            this.disablePPJMUpdate = false;
        } else if (delPPjmBpid && currentUser === delPPjmBpid) {
            /* If Current user is a DelPPJM, then user can update the APPJM values */
            this.disablePPJMUpdate = true;
            this.disableAPPJMUpdate = false;
        } else if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.indexOf(currentUser) > -1) {
            /* If Current user is an APPJM, then user can update the APPJM values but not the PPJM */
            this.disablePPJMUpdate = true;
            this.disableAPPJMUpdate = false;
        } else if (dmmBpid && currentUser === dmmBpid) {
            /* If current user is a DMM, then user can update the PPJM, APPJM values */
            this.disableAPPJMUpdate = false;
            this.disablePPJMUpdate = false;
        } else {
            /* If Current user is not an PPJM or APPJM or delegated PPJM, then disable update is set to true */
            this.disableAPPJMUpdate = true;
            this.disablePPJMUpdate = true;
        }
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.wbsEngService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.ManageEBSEngagementTeamStructureEdit, propertyBag);

        const notification = new NotificationModel();
        notification.engagementId = this.selectedEngagement.id;
        notification.engagementName = this.selectedEngagement.name;
        notification.eventName = NotificationEvent.EngagementUpdated;
        let sendTo: string[];
        if (this.APPJMsChanged) { // Notify PJM, PPJM, Additional PJM, and PPJM of change
            const listOfPJM: string[] = this.sharedFunctionsService.getListofPjmV2(this.selectedEngagement);
            const listOfAPJM: string[] = this.sharedFunctionsService.getListofAdPjmV2(this.selectedEngagement);
            listOfPJM.forEach((pjm: string) => {
                const index = listOfAPJM.indexOf(pjm);
                if (index >= 0) {
                    listOfAPJM.splice(index, 1);
                }
            });
            sendTo = listOfPJM.concat(listOfAPJM)
                .concat(userAlias);

        } else { // Notify PJM and PPJM only of change
            sendTo = this.sharedFunctionsService.getListofPjmV2(this.selectedEngagement)
                .concat(userAlias);
        }
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;

        let esxpNotification = this.notificationMessage.EngagementNotification;
        esxpNotification = esxpNotification.replace("#", this.selectedEngagement.name);
        this.notificationService.sendNotification(notification, false, esxpNotification);
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotificationForProjectTeamChange(changedProperties: IChangedProperties[], filteredProject: IProjectDetailsV2): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.wbsEngService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.CreateLogAndSendNotificationForProjectTeamChange, LogEventConstants.ManageEBSEngagementTeamStructureCascaded, propertyBag);


        const notification = new NotificationModelsForProject();
        notification.engagementId = this.selectedEngagement.id;
        notification.engagementName = this.selectedEngagement.name;
        notification.eventName = NotificationEvent.ProjectUpdated;
        notification.projectId = filteredProject.id;
        notification.projectName = filteredProject.name;
        notification.eventName = NotificationEvent.ProjectUpdated;

        let sendTo: string[];
        if (this.selectedEngagement && filteredProject) {
            if (this.APJMsChanged) { // Notify PJM, Additional PJM of the project where data has changed with data from engagement level
                const listOfPJMEngagementOnly: string[] = this.sharedFunctionsService.getListofPjmEngagementOnlyV2(this.selectedEngagement);
                const listOfPJMProjectOnly: string[] = this.sharedFunctionsService.getListofPjmProjectContextV2(this.selectedEngagement.projects.filter((project) => project.id === filteredProject.id), true);
                listOfPJMEngagementOnly.forEach((pjm: string) => {
                    const index = listOfPJMProjectOnly.indexOf(pjm);
                    if (index >= 0) {
                        listOfPJMProjectOnly.splice(index, 1);
                    }
                });
                sendTo = listOfPJMEngagementOnly.concat(listOfPJMProjectOnly).concat(userAlias);
            } else { // Notify PJM and PPJM only of change
                sendTo = this.sharedFunctionsService.getListofPjmV2(this.selectedEngagement).concat(userAlias);
            }
        }
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;

        let esxpNotification = this.notificationMessage.ProjectNotification;
        esxpNotification = esxpNotification.replace("#", filteredProject.name);
        this.notificationService.sendNotification(notification, false, esxpNotification);
    }

    /**
     * Populates all the type ahead text values
     */
    private setTypeAheadText(): void {
        this.typeAheadId = "engTypeAheadForPrimaryDomainManager";
        this.taSearchAriaLblTxt = "Search manager by name or alias";
        this.additionalTypeAheadId = "engTypeAheadForAdditionalManager";
        this.typeAheadLabelText = "Primary Domain Project Manager";
        this.taCancelAriaLblTxt = "Remove Primary Domain Project Manager";
        this.taRequiredMsg = "Primary Domain Project Manager is required";
        this.additionalSearchAriaLblTxt = "Search Additional Primary Domain Project Manager - Optional";
        this.unitSubmitterSearchAriaLblTxt = "Search Unit Submitters - Optional";
        this.additionalTypeAheadLabelText = "Additional Primary Domain Project Manager - Optional";
        this.additionalCancelAriaLblTxt = "Remove Additional Primary Domain Project Manager name or alias";
        this.unitSubmitterCancelAriaLblTxt = "Remove Unit Submitter name or alias";
    }

    /**
     * Gets filter dropdown data for projects filter based on project data from engagement details.
     *
     * @private
     * @param {IProjectDetailsV2[]} projects
     * @returns {FilterModel[]}
     * @memberof ActualsComponent
     */
    private getFilterDropdownData(projects: IProjectDetailsV2[]): FilterModel[] {
        const projectNodes: FilterModel[] = [];
        for (const project of projects) {
            if (!project.badgeText || (project.badgeText && project.badgeText !== "T&M Prepayment" && project.badgeText !== "Periodic Billing")) {
                const projectNode = new FilterModel(project.name, project.id);
                projectNodes.push(projectNode);
            }
        }
        return projectNodes;
    }

    /**
     * Initializes fcr form fields with validations etc.
     *
     * @memberof FinancialChangeRequestComponent
     */
    private initializeFcrForm(): void {
        this.editTeamStructureForm = this.fb.group({
            ppjm: [this.selectedUser ? this.selectedUser : this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr()],
            adPPjm: [this.selectedUsers ? this.selectedUsers : []],
            uSubmitters: [this.selectedUnitSubmitters ? this.selectedUnitSubmitters : []],
            pjmProjects: [[]],
            adpjmProjects: [[]],
            unitProjects: [[]]
        });
    }

    

    /**
     * creates addition project list for updation 
     */
    private createAPJMList(filteredProject: IProjectDetailsV2, originalAdditionalPJMBpIds: number[], modifiedPJMList: ITeamStructureForEditWBS[]): void {
        this.addedAPJMNames = [];
        this.deletedAPJMNames = [];

        // original additional ppjm not present in updated additonal ppjm and the same additional ppjm present in original additonal pjm then delete addtional pjm
        if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.length) {
            for (const orignalAPPJM of this.originalAdditionalPPJMBpIds) {
                if (orignalAPPJM) {
                    if (this.adPPjm.value && this.adPPjm.value.findIndex((adPPjm) => Number(adPPjm.bpId) === orignalAPPJM) < 0) {
                        if (originalAdditionalPJMBpIds && originalAdditionalPJMBpIds.length) {
                            for (const orignalAPJM of originalAdditionalPJMBpIds) {
                                if (orignalAPJM) {
                                    if (orignalAPPJM === orignalAPJM) {
                                        modifiedPJMList.push({
                                            projectRole: "ADPJM",
                                            bpId: orignalAPJM.toString(),
                                            isDelete: true // An original APJM is no longer selected, set isDelete to True
                                        });
                                        if (filteredProject && filteredProject.adPjm) {
                                            const apjm = filteredProject.adPjm.filter((x) => Number(x.bpid) === orignalAPJM)[0];
                                            if (apjm) {
                                                this.deletedAPJMNames.push(apjm.name);
                                                this.APJMsChanged = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // nothing in original additonal ppjm and nothing is updated then don't delete additonal pjm
        } else if (originalAdditionalPJMBpIds && originalAdditionalPJMBpIds.length && this.adPPjm && this.adPPjm.value && !this.adPPjm.value.length) {
            for (const orignalAPJM of originalAdditionalPJMBpIds) {
                if (orignalAPJM) {
                    modifiedPJMList.push({
                        projectRole: "ADPJM",
                        bpId: orignalAPJM.toString(),
                        isDelete: true // An original APJM is no longer selected, set isDelete to True
                    });
                    if (filteredProject && filteredProject.adPjm) {
                        const apjm = filteredProject.adPjm.filter((x) => Number(x.bpid) === orignalAPJM)[0];
                        if (apjm) {
                            this.deletedAPJMNames.push(apjm.name);
                            this.APJMsChanged = true;
                        }
                    }
                }
            }
        }

        // updated additonal ppjm names not present in exisiting additional pjm's then add additonal pjm
        if (this.adPPjm && this.adPPjm.value && this.adPPjm.value.length) {
            for (const singleAPJM of this.adPPjm.value) {
                if (singleAPJM.bpId) {
                    if (originalAdditionalPJMBpIds && ((originalAdditionalPJMBpIds.length && originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpId)) < 0)
                        || !originalAdditionalPJMBpIds.length)) {
                        modifiedPJMList.push({
                            projectRole: "ADPJM",
                            bpId: singleAPJM.bpId,
                            isDelete: false // New APJM added, set isDelete to False
                        });
                        this.addedAPJMNames.push(singleAPJM.userName);
                        this.APJMsChanged = true;
                    }
                }
            }
        }
    }

    /**
     * updates team structure for selected projects
     */
    private updateProjectTeamStructure(modifiedPJMList: ITeamStructureForEditWBS[], filteredProject: IProjectDetailsV2): Promise<void> {
        const editProjectDataRequest: IWbsEditProjectDetailsV2 = {};
        if (modifiedPJMList.length > 0) {
            editProjectDataRequest.teamStructure = [];
            editProjectDataRequest.teamStructure = modifiedPJMList;
        }

        if (editProjectDataRequest) {
            return this.wbsEngService.updateProjectDetailsV2(editProjectDataRequest, filteredProject.id)
                .then((response) => {
                    const changedProperties: IChangedProperties[] = [];

                    if (editProjectDataRequest.teamStructure && editProjectDataRequest.teamStructure.length > 0 && editProjectDataRequest.teamStructure.filter((team) => team.projectRole === "PJM").length > 0) {
                        let originalName: string = "None";
                        if (filteredProject.pjm) {
                            originalName = filteredProject.pjm.name;
                        }
                        this.wbsEngService.pushToArrayIfTrue(changedProperties, this.wbsEngService.createChangedPropertyObject("Domain Project Manager", originalName, this.ppjm.value.userName));
                    }

                    if (editProjectDataRequest.teamStructure && editProjectDataRequest.teamStructure.length > 0 && editProjectDataRequest.teamStructure.filter((team) => team.projectRole === "ADPJM").length > 0) {
                        let updateMessage: string = "";
                        let origMessage: string = "";
                        for (const name of this.originalAPJMNames) {
                            origMessage += name + ", ";
                            if (this.deletedAPJMNames.indexOf(name) < 0) {
                                updateMessage += name + ", ";
                            }
                        }
                        for (const name of this.addedAPJMNames) {
                            updateMessage += name + ", ";
                        }
                        updateMessage = !updateMessage ? "No Additional Domain Project Managers" : updateMessage.slice(0, -2); /* remove trailing comma and space */
                        origMessage = !origMessage ? "No previous Additional Domain Project Managers" : origMessage.slice(0, -2);
                        this.wbsEngService.pushToArrayIfTrue(changedProperties, this.wbsEngService.createChangedPropertyObject("Additional Domain Project Managers", origMessage, updateMessage));
                    }

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotificationsUpdateEBS(editProjectDataRequest)) {
                        this.createLogAndSendNotificationForProjectTeamChange(changedProperties, filteredProject);
                    }
                    if (response && response.status && response.status === 206) {
                        this.partialSuccessProjects.push(filteredProject.name);
                    } else {
                        this.successProjects.push(filteredProject.name);
                    }
                    this.closeModal();
                }).catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", filteredProject.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.UpdateProjectTeamStructure, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUpdatingTeamStructure = false;
                });
        }
    }

    /**
     * Sets the editable flag, set the badge content to either true or false in project data.
     * @param projectDataV2
     */
    private setProjectData(projectDataV2: IProjectDetailsV2[]): void {
        for (const proj of projectDataV2) {
            proj.showPjmField = true;
            const filteredUserStatus = this.projectUserStatusToShowBadges.filter((item) => (proj.userStatusCode.toLocaleUpperCase().indexOf(item) > -1));

            if (filteredUserStatus && filteredUserStatus.length) {
                proj.showBadge = true;
                proj.badgeText = this.getBadgeText(filteredUserStatus[0]);
                proj.canEditProject = proj.canEditProject && !this.sharedFunctionsService.isProjectReadOnly(proj);
                proj.showPjmField = !this.sharedFunctionsService.isProjectReadOnly(proj);
            }
        }
    }

    /**
     * Returns the badge text based on the code
     * @param item
     */
    private getBadgeText(item: string): string {
        let returnValue = "";
        switch (item) {
            case "DIR":
                returnValue = "ECIF";
                break;
            case "PRP":
                returnValue = "T&M Prepayment";
                break;
            case "PER":
                returnValue = "Periodic Billing";
                break;
            case "IND":
                returnValue = "ECIF";
                break;
            default:
                break;
        }
        return returnValue;
    }

    /**
     * Logs Engagementdetails Update to Audit    
     */
    private logEngagementTeamStructureUpdateToAudit(editEngagementDetailsRequest: IWbsEditEngagementDetailsV2): void {
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData(); 
        if (editEngagementDetailsRequest.teamStructure.length > 0 && editEngagementDetailsRequest.teamStructure.filter((req) => req.role === "PPJM").length) {
            const logDetails: IWbsAuditPayload = {
                currentValue: this.ppjm.value.fullName,
                previousValue: this.selectedEngagement.pPjm.name,
                eventType: WbsAuditType.EngagementPPJMUpdate,
                createdByAlias: loggedInUserData.alias
            };
            this.auditService.postWbsAuditItem(this.selectedEngagement.id, logDetails);
        } 
        if (editEngagementDetailsRequest.teamStructure.filter((req) => req.role === "ADPPJM").length) {
            const additionalPPJMUpdates: ITeamStructureForEditWBS[] = editEngagementDetailsRequest.teamStructure.filter((req) => req.role === "ADPPJM");
            for (const update of additionalPPJMUpdates) {
                let changedValue = "";
                if (this.selectedEngagement.teamStructure.filter((req) => req.bpid === update.bpId).length > 0) {
                    changedValue = this.selectedEngagement.teamStructure.filter((req) => req.bpid === update.bpId)[0].name;
                }
                if (update.isDelete) {                    
                    const logDetails: IWbsAuditPayload = {
                        currentValue: "",
                        previousValue: changedValue,
                        eventType: WbsAuditType.EngagementAdditionalPJMRemoved,
                        createdByAlias: loggedInUserData.alias
                    };
                    this.auditService.postWbsAuditItem(this.selectedEngagement.id, logDetails);
                } else {
                    const logDetails: IWbsAuditPayload = {
                        currentValue: changedValue,
                        previousValue: "",
                        eventType: WbsAuditType.EngagementAdditionalPJMAdded,
                        createdByAlias: loggedInUserData.alias
                    };
                    this.auditService.postWbsAuditItem(this.selectedEngagement.id, logDetails);
                }
            }
        }
    }
}
