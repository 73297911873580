<section class="dm-financial-cr-approvers">
    <dm-loading [loadingText]="loadingMessage" [errorText]="FinancialCrApproversListError" [isCustomText]="true"
        [showLoading]="!FinancialCrApproversList && !FinancialCrApproversListError" *ngIf="!FinancialCrApproversList">
    </dm-loading>

    <section class="display-flex-imp" *ngIf="FinancialCrApproversList && FinancialCrApproversList.length">
        <ng-container *ngFor="let approver of FinancialCrApproversList">
            <div class="dm-financial-cr-approvers__item"
                [ngClass]="{'active-item': approver?.status === ProgressBarStatus.InProgress}">
                <div class="dm-financial-cr-approvers__item-top">
                    <div class="dm-financial-cr-approvers__item-role pull-left">
                        <span>{{approver?.role}}</span>
                    </div>
                    <div class="display-flex pull-right">
                        <div *ngIf="approver && approver.status" class="approval-step-icon"
                            [ngClass]="[getClassByStatus(approver?.status)]">
                            <span class="icon" [ngClass]="[getIconByStatus(approver?.status)]"></span>
                        </div>
                        <div class="dm-financial-cr-approvers__item-status dm-p-l-5">{{approver?.status}}</div>
                    </div>
                </div>
                <div class="dm-financial-cr-approvers__item-bottom">
                    <div class="dm-financial-cr-approvers__item-name pull-left">
                        <span class="font-caption-alt">
                            <strong>{{approver?.name}}</strong>
                            <span class="outward dm-p-x-5" *ngIf="approver?.delegatedApprover">
                                <span [title]="'Delegated Approver: ' + approver?.delegatedApprover" class="delegated"
                                    aria-hidden="true">D</span>
                            </span>
                        </span>
                    </div>
                    <div class="dm-financial-cr-approvers__item-date  pull-right">
                        <span *ngIf="approver?.updatedOn" class="font-caption-alt">{{approver?.updatedOn}}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </section>
</section>