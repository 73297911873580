<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'250px'" [isLoading]="isUserlinksLoading">
    <dm-loading loader [loadingText]="'Saving Details'" [isCustomText]="true" [errorText]="errorText"
        [showLoading]="true" *ngIf="isUserlinksLoading">
    </dm-loading>
    <div *ngIf="!isUserlinksLoading">
        <form [formGroup]="userpreferenceLinksAddForm">
            <div>
                <div role="grid" class="dm-grid-view-table" aria-busy="true"
                    *ngIf="linksFormArray && linksFormArray.value && linksFormArray.value.length">
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="columnheader"
                            class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                            Title
                        </div>
                        <div role="columnheader"
                            class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                            Url
                        </div>
                        <div role="columnheader"
                            class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8"
                            aria-label="Delete">
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div class="dm-grid-view-table" role="none">
                            <div class="dm-grid-view-table__body" formArrayName="linksFormArray">
                                <div role="row" class="dm-grid-view-table__row"
                                    *ngFor="let link of linksFormArray.controls; let i = index">
                                    <div role="gridcell" [formGroupName]="i"
                                        class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                                        <input type="text" id="userPreferencLinksName{{i}}" name="userPreferencLinksName{{i}}"
                                            formControlName="name" placeholder="Enter the name of link"
                                            class="font-caption-alt dm-input" [attr.aria-label]="i > 0 ? 'A new link has been added. Title User Preference Link Name' : 'Title User Preference Link Name'"
                                            required aria-invalid="false" />
                                        <span role="alert" aria-live="assertive" aria-atomic="true" [attr.aria-label]="userPreferenceLinksErrorMessages.EnterTheTitle"
                                            *ngIf="userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.name.errors && (userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.name.dirty || userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.name.touched)"
                                            class="font-caption-alt inp-validation">
                                            {{userPreferenceLinksErrorMessages.EnterTheTitle}}
                                        </span>
                                    </div>
                                    <div role="gridcell" [formGroupName]="i"
                                        class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                                        <input type="url" id="userPreferencLinksUrl{{i}}" [pattern]="urlValidationPattern"
                                            name="userPreferencLinksUrl{{i}}" formControlName="url"
                                            (blur)="isUrlFieldInFocus[i]=true" class="font-caption-alt dm-input"
                                            placeholder="http://microsoft.com" aria-label="Url User Preference Link Url"
                                            required aria-invalid="false" />
                                        <div *ngIf="(isUrlFieldInFocus[i] && userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.url && userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.url.errors && (userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.url.dirty || userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.url.touched))"
                                            class="font-caption-alt inp-validation">
                                            <span
                                                *ngIf="userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.url.errors.required"
                                                role="alert" aria-live="assertive" aria-atomic="true" [attr.aria-label]="userPreferenceLinksErrorMessages.EnterValidUrl">
                                                {{userPreferenceLinksErrorMessages.EnterValidUrl}}
                                            </span>
                                            <span role="alert" aria-live="assertive" aria-atomic="true" [attr.aria-label]="userPreferenceLinksErrorMessages.UrlMustBeginWithHttpOrHttps"
                                                *ngIf="(userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.url.errors.pattern && !userpreferenceLinksAddForm.get('linksFormArray').controls[i].controls.url.errors.required)">
                                                {{userPreferenceLinksErrorMessages.UrlMustBeginWithHttpOrHttps}}
                                            </span>
                                        </div>
                                    </div>
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                                        <button type="button"
                                            [title]="link.value && link.value.name ? ('Delete ' + link.value.name) : 'Delete'"
                                            [attr.aria-label]="link.value && link.value.name ? ('Delete ' + link.value.name) : 'Delete'"
                                            (click)="setStatusMessage('Delete',link.value);deleteUserPreferenceLinks(i)"
                                            class="dm-transparent-btn btn-color delete-btn">
                                            <span class="icon icon-delete m-t-8"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="dm-p-l-imp-12">
                        <span class="font-caption-alt">
                            <button id="addLinkBtn" *ngIf="!isAddLinksDisabled" (click)="addLinkEmptyRows()"
                                class="dm-transparent-btn btn-color">
                                <i class="icon icon-AddLegacy"></i> Add Links
                            </button>
                        </span>
                    </section>
                    <label id="lblValidationMessage" class="font-caption-alt inp-validation dm-p-l-imp-12">
                        <span *ngIf="showValidationMessage" class="font-caption-alt inp-validation"
                            aria-hidden="true">{{validationMessageText}}</span>
                    </label>
                    <span class="sr-only" tabindex="-1" role="alert" *ngIf="statusMessage">{{statusMessage}}</span>
                </div>
            </div>
        </form>
    </div>
    <div infoBar *ngIf="!isUserlinksLoading && isAddLinksDisabled">
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>More than 7 custom key links can't be added</span>
        </div>
    </div>
    <div additionalFooterButtons>
        <button type="button" id="accessibilityConstants.SaveUserPreferenceLinks"
            class="fxp-btn fxp-btn--primary dm-btn" (click)="saveUserPreferenceLinks()" *ngIf="!isUserlinksLoading"
            [disabled]="!userpreferenceLinksAddForm.valid || (linksFormArray && linksFormArray.value && !this.linksFormArray.value.length)">
            Submit
        </button>
    </div>
</dm-modal-v2>