import { Action } from "@ngrx/store";
import { IUserPreference } from "../../common/services/contracts/userpreference.contract";

// 1. action types
export enum UserPreferenceActionTypes {
    LOAD_USERPREFERENCE = "[UserPreference] Load",
    UPDATE_USERPREFERENCE = "[UserPreference] Update",
    LOAD_USERPREFERENCE_SUCCESS = "[UserPreference] Load Success",
    LOAD_USERPREFERENCE_FAIL = "[UserPreference] Load Fail",
    INVALIDATE_USERPREFERENCE = "[UserPreference] Invalidate"
}

// 2. action creators
export class LoadUserPreference implements Action {
    public readonly type = UserPreferenceActionTypes.LOAD_USERPREFERENCE;
}

export class UpdateUserPreference implements Action {
    public readonly type = UserPreferenceActionTypes.UPDATE_USERPREFERENCE;
    public constructor(public userPreferenceApiResponse: IUserPreference) { }
}

export class LoadUserPreferenceSuccess implements Action {
    public readonly type = UserPreferenceActionTypes.LOAD_USERPREFERENCE_SUCCESS;
    public constructor(public userPreferenceApiResponse: IUserPreference) { }
}

export class LoadUserPreferenceFail implements Action {
    public readonly type = UserPreferenceActionTypes.LOAD_USERPREFERENCE_FAIL;
    public constructor(public errorMessage: string) { }
}

export class InvalidateUserPreference implements Action {
    public readonly type = UserPreferenceActionTypes.INVALIDATE_USERPREFERENCE;
}

// 3. export the union creators
export type UserPreferenceAction =
    | LoadUserPreference
    | UpdateUserPreference
    | LoadUserPreferenceSuccess
    | LoadUserPreferenceFail
    | InvalidateUserPreference;