import { Component, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { Components } from "../../common/application.constants";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-wbs-details",
    templateUrl: "./wbs-details.html",
    styleUrls: ["./wbs-details.scss"]
})
export class WbsDetailsComponent extends DmComponentAbstract {
    public currentRouteName: string;
    public isProjectContext: boolean = false;
    public teamContext: boolean = true;
    private selectedProjectId: string;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService
    ) {
        super(dmLogger, Components.ManageEbsEngagementDetails, [
            { component: Components.ManageEbsEngagementTeamStructure, isCritical: true },
            { component: Components.EngagementSummaryFinancialsV2, isCritical: true },
            { component: Components.ManageEbsEngagementBreakdownStructure, isCritical: true }
        ]);
    }

    public ngOnInit(): void {
        this.selectedProjectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = this.selectedProjectId ? true : false;
    }
}
