import { Component, forwardRef, Inject, Input, Output, EventEmitter, SimpleChanges, Injector } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ContractType, IEngagementDetailsApiV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { ICrDemandChangeEvent, ICrResource, ICrExpenseOutput, ITask, IExistingExpense } from "../../../common/services/contracts/changerequest.contract";
import { FxpMessageService } from "@fxp/fxpservices";
import { ProjectService } from "../../../common/services/project.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { ChangeRequestDemandService } from "../../../common/services/change-request-demand.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmError } from "../../../common/error.constants";
import { StateService } from "@uirouter/angular";
import { IBillablityType, IFcrExpensesFormControlData } from "../../../common/services/contracts/changerequestv2.contract";
import { AccessibilityConstants, ExpenseBillableCode, ExpenseBillableTypes, RouteName } from "../../../common/application.constants";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CancelOrContinueModal } from "../modals/cancel-or-continue-modal/cancel-or-continue-modal.component";
@Component({
    selector: "dm-fcr-expenses-form-control",
    templateUrl: "./fcr-expenses-form-control.html",
    styleUrls: ["./fcr-expenses-form-control.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FcrExpensesFormControlComponent),
            multi: true
        }
    ]
})
export class FcrExpensesFormControlComponent implements ControlValueAccessor {
    @Input() public get value(): ICrExpenseOutput[] {
        return this.state;
    }
    public set value(val: ICrExpenseOutput[]) {
        this.state = [...val];
        this.propagateChange(this.state);
    }

    @Input() public fvrContractType: ContractType;
    @Input() public fcrExpensesFormControlData: IFcrExpensesFormControlData;
    @Input() public fcrExpensesFormControlDataError: string;
    @Input() public fcrFormControlsTotalValue: ICrResource[];
    @Input() public resourceLocationAndRoleEditFeatureFlag: boolean;
    @Input() public engagementDetails: IEngagementDetailsApiV2;

    @Input() public populateRoles: ICrExpenseOutput[] = null;

    @Output() public contractTypeChange = new EventEmitter<ContractType>();
    @Output() public demandChangeEvent = new EventEmitter<ICrDemandChangeEvent>();
    @Output() public expenseRequestSaveDeleteEvent = new EventEmitter<ICrExpenseOutput>();
    public totalPlannedExpenseCost: number;
    public totalAdditionalExpenseCost: number;
    public invalidContractTypeMessage: string;
    public isEditable: boolean = true;
    public isPubSecEngagement: boolean = false;
    public loadingMessage: string = "Loading Expense Details...";
    public statusMessage: string;
    public title: string;
    public totalCost: number = 0;
    public fcrRolesErrorMessages = DmError.FinancialChangeRequest;
    public billableTypes: IBillablityType[];
    public nonBillableType: IBillablityType;
    public accessibilityConstants = AccessibilityConstants;

    private state: ICrExpenseOutput[] = [];


    public constructor(
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(ChangeRequestDemandService) public crDemandService: ChangeRequestDemandService,
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) private dmLoggerService: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) public stateService: StateService,
        @Inject(Injector) private injector: Injector,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
    ) { }

    public ngOnInit(): void {
        this.billableTypes = ExpenseBillableTypes;
        this.nonBillableType = this.billableTypes && this.billableTypes.length && this.billableTypes.filter((type) => type.code === ExpenseBillableCode.NonBillable)[0];
    }

    public ngOnChanges(allInputChanges: SimpleChanges): void {

        /* This's the recommeded way to access SimpleChanges */
        if (allInputChanges["populateRoles"] && Array.isArray(allInputChanges["populateRoles"].currentValue)) {
            // const subconLineItems = allInputChanges["populateRoles"];

            this.state = [...allInputChanges["populateRoles"].currentValue] as ICrExpenseOutput[];
            this.recalculate();
        }

        if (allInputChanges["fvrContractType"] && allInputChanges["fvrContractType"].currentValue) {
            this.fvrContractType = allInputChanges["fvrContractType"].currentValue;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateChange = (...args: any[]): void => { return; };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateTouch = (...args: any[]): void => { return; };

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: (...args: any[]) => void): void {
        this.propagateChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: (...args: any[]) => void): void {
        this.propagateTouch = fn;
    }


    /**
     * Writes a new value to the element.
     * 
     * Part of ControlValueAccessor interface.
     */
    public writeValue(value: ICrExpenseOutput[]): void {
        if (value !== undefined) {
            this.value = [...value];
        }
    }

    /**
     * Update request details on change of task.
     *
     * @param {ITask} task
     * @param {ICrExpenseOutput} rowLineItem
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @memberof FcrExpensesFormControlComponent
     */
    public onSelectedTaskChange(task: ITask, rowLineItem: ICrExpenseOutput): void {
        rowLineItem.currentState.isCrPendingInProject = this.crDemandService.isCrPendingInProject(task.projectId, this.fcrExpensesFormControlData.projectsWithPendingCr);
        if (!task.isFixedFeeProject) {
            rowLineItem.currentState.billableType = this.nonBillableType; // Set non billable role for T&M
        } else {
            if (!rowLineItem.currentState.existingResource) {
                rowLineItem.currentState.billableType = undefined;
            }
        }
        if (!rowLineItem.currentState.isCrPendingInProject) {
            rowLineItem.currentState.assignedTask = task;
        }
        rowLineItem.isSaveable = this.crDemandService.isExpenseRequestSaveable(rowLineItem);
    }

    /**
     * Update request details on change of existing expense item.
     *
     * @param {IExistingExpense} existingExpense
     * @param {ICrExpenseOutput} rowLineItem
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @memberof FcrExpensesFormControlComponent
     */
    public onSelectedExistingExpenseChange(existingExpense: IExistingExpense, rowLineItem: ICrExpenseOutput, engagementDetails: IEngagementDetailsApiV2): void {
        rowLineItem.currentState.existingResource = existingExpense;
        rowLineItem.currentState.billableType = this.billableTypes.filter((m) => m.code === existingExpense.roleId)[0];
        rowLineItem.currentState.assignedTask = this.fcrExpensesFormControlData.existingTasks.filter((task) => task.wbsl3Id === existingExpense.structureId)[0];
        this.crDemandService.setExistingExpenseResource(existingExpense, rowLineItem, engagementDetails, this.fcrExpensesFormControlData.existingTasks);
        rowLineItem.currentState.isCrPendingInProject = existingExpense.isCrPendingInProject;
        rowLineItem.isSaveable = this.crDemandService.isExpenseRequestSaveable(rowLineItem);
    }

    /**
     * Set contract type error message based on request contract type.
     *
     * @param {boolean} isFixedFee
     * @memberof FcrRolesFormControlComponent
     */
    public setContractTypeErrorMessage(isFixedFee: boolean): void {
        this.invalidContractTypeMessage = `Cannot add a ${isFixedFee ? "Fixed Fee" : "Time and Material"} task to the FCR with an existing ${isFixedFee ? "Time and Material" : "Fixed Fee"} task.`;
    }

    /**
     * When user wants to save the current state, copy the current state into the saved state.
     */
    public saveRequest(index: number): void {
        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        const savedExpenseRequests = this.state.filter((m) => m.savedState).length;
        const currentExpenseRequests = this.state.filter((m) => m.currentState).length;
        const rowToSave = this.state.filter((m) => this.state[index].uuid === m.uuid)[0];
        let isValidOverRide: boolean = false;
        if ((savedExpenseRequests === 1 && currentExpenseRequests === 1 && numberOfSavedRequests === 1) || (savedExpenseRequests === 1 && rowToSave.savedState && numberOfSavedRequests === 1)) {
            this.state[index].currentState.isContractTypeValid = true;
            this.emitContractTypeChange(this.state[index]);
            isValidOverRide = true;
            this.state[index].editModeEnabled = false;
            this.state[index].savedState = { ...this.state[index].currentState };
            this.sharedFunctionsService.focus("editBtn_" + index, true);
            this.emitExpenseRequestSaveOrDelete(this.state[index]);
            this.value = this.state;
            this.recalculate();
        } else if (this.fvrContractType && !this.crDemandService.validateFvrContractType(this.state[index], this.fvrContractType)) {
            this.state[index].currentState.isContractTypeValid = false;
            const isRequestFixedFee: boolean = this.state[index].currentState.assignedTask.isFixedFeeProject;
            this.setContractTypeErrorMessage(isRequestFixedFee);
        } else {
            this.state[index].currentState.isContractTypeValid = true;
            isValidOverRide = true;
            this.emitContractTypeChange(this.state[index], isValidOverRide);
            this.state[index].editModeEnabled = false;
            this.state[index].savedState = { ...this.state[index].currentState };
            this.sharedFunctionsService.focus("editBtn_" + index, true);
            this.emitExpenseRequestSaveOrDelete(this.state[index]);
            this.value = this.state;
            this.recalculate();
        }
    }


    public deleteRequest(event: KeyboardEvent, index: number): void {
        if (this.state[index].currentState.existingResource) {
            // we need to update the currently displayed existing resources
            this.state[index].currentState.existingResource.isDisplayed = false;
        }

        const isDeletedRoleFixedFee: boolean = this.state[index].currentState.assignedTask ? this.state[index].currentState.assignedTask.isFixedFeeProject : undefined;
        const deletedContractType: ContractType = isDeletedRoleFixedFee ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        const deletedRowLineItem: ICrExpenseOutput = this.state[index];
        this.state.splice(index, 1);
        this.value = this.state;
        this.recalculate();

        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        if (!this.state.length && numberOfSavedRequests === 1) {
            this.fvrContractType = undefined;
        } else if ((isDeletedRoleFixedFee !== undefined && this.fvrContractType === deletedContractType) && !this.fcrFormControlsTotalValue.some((resourceRequest: ICrResource) => resourceRequest.savedState && resourceRequest.savedState.assignedTask && resourceRequest.savedState.assignedTask.isFixedFeeProject === isDeletedRoleFixedFee)) {
            this.fvrContractType = undefined;
        }

        // Only emit delete if there was a saved state
        if (deletedRowLineItem.savedState) {
            // Only emit contract type on delete if we are clearing the overall request type
            if (!this.fvrContractType) {
                this.emitContractTypeChange(undefined);
            }
            this.emitExpenseRequestSaveOrDelete(this.state[index]);
        }

        if (this.state.length > 0 && index !== this.state.length && this.state[index]) {
            if (this.state[index].savedState) {
                this.sharedFunctionsService.focus("editBtn_" + index, true);
            }
            // else {
            //     this.state[index].currentState.existingDemand ?
            //         this.sharedFunctionsService.focus("adjustExistingRole_" + index, true) :
            //         this.sharedFunctionsService.focus("addNewEBSId_" + index, true);
            // }
        } else {
            // this.isSubmittable() ? this.sharedFunctionsService.focus("submit", true) : this.sharedFunctionsService.focus("closeUpdateButton", true);
        }
    }

    /**
     * revert to previously saved state
     */
    public discardChanges(event: KeyboardEvent, index: number): void {
        if (this.state[index] && this.state[index].savedState) {
            if (this.state[index].currentState.existingResource) {
                this.state[index].currentState.existingResource.isDisplayed = false;
                this.state[index].savedState.existingResource.isDisplayed = true;
            }
            this.state[index].currentState = { ...this.state[index].savedState };
            this.recalculate();
            this.sharedFunctionsService.focus("editBtn_" + index, true);
        }
        if (this.state[index] && !this.state[index].savedState) {
            this.deleteRequest(event, index);
        }
    }

    public onBillableTypeChange(billableType: IBillablityType, expenseLine: ICrExpenseOutput): void {
        expenseLine.currentState.billableType = billableType;
        expenseLine.isSaveable = this.crDemandService.isExpenseRequestSaveable(expenseLine);
    }

    public onPlannedCostChange(expenseLine: ICrExpenseOutput): void {
        expenseLine.isSaveable = this.crDemandService.isExpenseRequestSaveable(expenseLine);
        this.recalculate();
    }

    /**
     * Add new Expense
     *
     * @memberof LaborRequestModalComponent
     */
    public addNewExpense(): void {
        const newExpense = this.crDemandService.getAddExpenseInitialState();
        newExpense.currentState.billableType = undefined;
        this.state.push(newExpense);
    }

    public adjustExisting(): void {
        const existingExpense = this.crDemandService.getAdjustExpenseInitialState();
        this.state.push(existingExpense);
    }

    /**
     * used for angular optimization of rendering this.state list (trackBy)
     */
    public trackByFn(index: number, item: ICrResource): string {
        return item.uuid;
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        const length: number = this.state.length;
        let defaultId: string = "adjustExisting";
        if (length > 0 && this.state[length - 1]) {
            defaultId = this.state[length - 1].savedState ? "deleteBtn_" + (length - 1) : "cancelBtn_" + (length - 1);
        }
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, defaultId);
        }
    }

    /**
     * Sets the focus on the first editable element after edit button is clicked.
     */
    public moveFocusToEditableElement(index: string, isExistingResource: boolean): void {
        const focusingId: string = isExistingResource ? "adjustExistingRole_" + index : "addNewEBSId_" + index;
        this.sharedFunctionsService.focus(focusingId, true);
    }

    /**
     * Recalculate relevant values given a change in the state.
     */
    public recalculate(): void {
        let totalPlannedExpenseCost: number = 0;
        let totalAdditionalExpenseCost: number = 0;
        this.state.forEach((item: ICrExpenseOutput) => {
            if (!isNaN(item.currentState.newPlannedCost)) {
                item.currentState.newPlannedCost = +item.currentState.newPlannedCost;
                const existingPlannedCost = item.currentState.existingResource ? item.currentState.existingResource.plannedCost : 0;
                const cfpCost = item.isDbOnlyDemand ? 0 : existingPlannedCost + +item.currentState.newPlannedCost;
                const additionalCost = cfpCost - (item.currentState.existingResource && item.currentState.existingResource.dbCost ? item.currentState.existingResource.dbCost : 0);
                totalAdditionalExpenseCost += additionalCost;
                totalPlannedExpenseCost += item.currentState.newPlannedCost;
            }
        });
        this.totalAdditionalExpenseCost = totalAdditionalExpenseCost;
        this.totalPlannedExpenseCost = totalPlannedExpenseCost;
        this.value = this.state;
    }


    public onEditClick(event: KeyboardEvent, index: string): void {
        this.state[index].editModeEnabled = true;
        this.moveFocusToEditableElement(index, !!this.state[index].currentState.existingResource);
    }

    /**
     * Emit when the contract type of role requests has changed.
     *
     * @param {ICrResource} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public emitContractTypeChange(rowLineItem: ICrExpenseOutput, isValidOverRide?: boolean): void {
        let laborContractType: ContractType;
        if (rowLineItem) {
            laborContractType = rowLineItem.currentState.assignedTask.isFixedFeeProject ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        }

        if (!this.fvrContractType || isValidOverRide) {
            this.contractTypeChange.emit(laborContractType);
        }
    }

    /**
     * Validate Project End Date
     * 
     * @param {ITask} task
     */
    public validateProjectEndDate(task: ITask): boolean {
        if (!task || !task.projectEndDate) {
            return false;
        }

        const projectEndDate = new Date(task.projectEndDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
     * Redirects users to Engagement's Manage EBS screen
     */
    public redirectToEngagementManageEBS(): void {
        if (this.engagementDetails) {
            const engagementId = this.engagementDetails.id;
            this.stateService.go(RouteName.EngagementManageEBS, { engagementId }, { reload: RouteName.EngagementManageEBS });
        }
    }

    /**
     * Retrieves the expired projects
     */
    public getExpiredProjects(): Set<string> {
        const filteredTasks = this.fcrExpensesFormControlData && this.fcrExpensesFormControlData.existingTasks && this.fcrExpensesFormControlData.existingTasks.filter((task) => {
            const projectEndDate = new Date(task.projectEndDate);
            const currentDate = new Date();

            return projectEndDate < currentDate;

        });

        const expiredProjects = new Set<string>();
        if (filteredTasks) {
            filteredTasks.forEach((task) => {
                expiredProjects.add(task.projectId);
            });
        }

        return expiredProjects;
    }

    /**
     * Proceed with project's end date change.
     */
    public onEditProjectEndDate(): void {
        // First shows up a modal to confirm the date change action from user.
        const cancelOrContinueModalRef: NgbModalRef = this.modalService.open(
            CancelOrContinueModal,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }

        );

        cancelOrContinueModalRef.componentInstance.modalMessage = "The details entered in this FCR form will be erased while changing the project dates. Do you wish to continue?";
        cancelOrContinueModalRef.componentInstance.continueFunction = () => {
            this.redirectToEngagementManageEBS();
        };
    }


    /**
     * Validates End Date of Projects having existing demands
     * 
     * @param {ITask} task
     * @memberof LaborRequestModalComponent
     */
    public validateResourceProjectEndDate(taskId: string): boolean {
        if (!taskId) {
            return false;
        }

        const projectId = this.projectService.getProjectIdFromTaskId(taskId);
        // In case expense task has a different type of WBS ID (eg. - C.XXXXXXXXXX.9XXXXX.XX) then pass the service Id to get the project details
        const projectDetails = this.projectService.getProjectFromWbsId(projectId, this.engagementDetails) || this.projectService.getProjectFromWbsId(taskId.slice(0, -3), this.engagementDetails);
        if (!projectDetails || !projectDetails.endDate) {
            return false;
        }
        const projectEndDate = new Date(projectDetails.endDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
     * Emit an event when a role request is successfully saved or deleted.
     *
     * @param {ICrResource} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public emitExpenseRequestSaveOrDelete(rowLineItem: ICrExpenseOutput): void {
        this.expenseRequestSaveDeleteEvent.emit(rowLineItem);
    }
}
