import { Component, Input } from "@angular/core";

@Component({
    selector: "toggle-section",
    templateUrl: "./toggle-section.html",
    styleUrls: ["./toggle-section.scss"]
})
export class ToggleSectionComponent {
    @Input() public title: string;
    @Input() public subTitle: string;
    @Input() public showSection: boolean = true;

    public constructor() {
        // This component toggles the section enclosed inside it.
    }

    public ngOnInit(): void {
        // empty
    }

    /**
     * Toggles the section enclosed.
     *
     * @memberof ToggleSectionComponent
     */
    public toggleSection(): void {
        this.showSection = !this.showSection;
    }
}


