import { ILoadableState } from "../reducers";
import { IExistingCrRequest } from "../../common/services/contracts/changerequest.contract";
import { CrIdRolesActions, CrIdRolesActionTypes } from "./cr-id-roles.action";

// slice of crIdRoles state
export interface ICrIdRolesState extends ILoadableState {
    crIdRoles: IExistingCrRequest;
}

export const initialState: ICrIdRolesState = {
    crIdRoles: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: CrIdRolesActions): { [engagementId: string]: ICrIdRolesState } {
    switch (action.type) {
        case CrIdRolesActionTypes.LOAD_CRID_ROLES: {
            const newState = {...state};
            newState[action.crId] = {...initialState, loading: true };
            return newState;
        }
      
        case CrIdRolesActionTypes.LOAD_CRID_ROLES_SUCCESS: {
            const newState = {...state};
            newState[action.crId] = {
                ...initialState,
                crIdRoles: action.crRequest,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case CrIdRolesActionTypes.LOAD_CRID_ROLES_FAIL: {
            const newState = {...state};
            newState[action.crId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case CrIdRolesActionTypes.INVALIDATE_CRID_ROLES: {
            const newState = {...state};
            newState[action.crId] = initialState;
            return newState;
        }
    }

    return state;
}