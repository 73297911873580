import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { DeviceFactoryProvider, UserInfoService } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { combineLatest as observableCombineLatest } from "rxjs";
import { Components, NoDataText, SourceConstants, LogEventConstants, AccessibilityConstants } from "../../common/application.constants";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DmError } from "../../common/error.constants";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { getEntireMilestones } from "../../store/milestones/milestones.selector";
import { IBillingMilestoneModel, IBillingMilestoneYearsModel, IMilestoneDateModel } from "../../common/services/contracts/dmmilestone.service.contract";
import { IEngagementDetailsApiV2, IProjectDetailsApiV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { IMilestonesState } from "../../store/milestones/milestones.reducer";
import { IState } from "../../store/reducers";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { MilestonesModalComponent } from "./milestone-modal/milestones-modal.component";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import moment from "moment";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";
import { FormGroup, AbstractControl, Validators, FormBuilder } from "@angular/forms";

@Component({
    selector: "dm-milestones",
    templateUrl: "./milestones.html",
    styleUrls: ["./milestones.scss"]
})
export class MilestonesComponent extends DmComponentAbstract {
    public milestoneData: IBillingMilestoneYearsModel[];
    public isLoggedInUserPpjmOrApjm: boolean = false;
    public hideConfirmMilestoneDescriptions: string[] = [];
    public milestoneMonths: string[] = [];
    public noMilestonesText: string;
    public isProjectFF: boolean;
    public isDesktopView: boolean;
    public isSmallScreenView: boolean;
    public isTabletView: boolean;
    public isMobileView: boolean;
    public showMilestonesData: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.MilestoneDetails;
    public accessibilityConstants = AccessibilityConstants;
    public engagementContractSignatureDate: Date;

    private bpId: number;

    /**
     * Creates an instance of MilestonesComponent
     * @param {DeviceFactoryProvider} deviceFactory
     * @param {DMAuthorizationService} dmauthorizationservice
     * @param {ConfigManagerService} configurationService
     * @param {SharedFunctionsService} sharedFunctionsService
     * @param {StateService} stateService
     * @memberof MilestonesComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) public fxpUserInfoService: UserInfoService,
        @Inject(DMAuthorizationService) private dmauthorizationservice: DMAuthorizationService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.Milestones);
    }

    public ngOnInit(): void {
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isSmallScreenView = this.deviceFactory.isSmallScreen();
        this.isTabletView = this.deviceFactory.isTablet();
        this.isMobileView = this.deviceFactory.isMobile();
        this.tileContent = {
            title: "Billing Milestones"
        };
        this.configurationService.initialize().then(() => {
            this.hideConfirmMilestoneDescriptions = this.configurationService.getValue<string[]>("hideConfirmMilestoneDescriptions");
            this.bpId = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
            const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
            const isProjectContext = projectId ? true : false;
            const engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
            const wbsId = isProjectContext ? projectId : engagementId;

            this.noMilestonesText = NoDataText.NoFixedMilestones + (projectId ? "project." : "engagement.");
            const engagementDetails$ = this.store.select(getEntireEngagementDetails(wbsId));
            const milestoneDetails$ = this.store.select(getEntireMilestones(engagementId));
            this.storeDispatchService
                .requireEngagementDetails(engagementId, true)
                .requireMilestones(engagementId, true)
                .load();

            observableCombineLatest(
                engagementDetails$, milestoneDetails$,
                (
                    engagementDetails: IEngagementDetailsState,
                    milestoneDetails: IMilestonesState,
                ) => ({
                    engagementDetails,
                    milestoneDetails
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    engagementDetails,
                    milestoneDetails
                }) => {
                    this.refreshOnItemInvalidation(engagementDetails, milestoneDetails);
                    this.setLoadersBasedOnItemState(engagementDetails, milestoneDetails);
                    this.setErrorsBasedOnItemState(engagementDetails, milestoneDetails);

                    if (milestoneDetails.loaded) {
                        this.milestoneData = this.manipulateBillingMilestoneData(milestoneDetails.milestones);
                    }

                    if (engagementDetails.loaded) {
                        this.isProjectFF = this.checkIfProjectIsFF(engagementDetails.engagementDetails, projectId);
                        // Show milestones data if it is engagement context or project is FF
                        this.showMilestonesData = (this.isProjectFF || !isProjectContext) ? true : false;
                        this.isLoggedInUserPpjmOrApjm = this.dmauthorizationservice.isLoggedinUserPpjmOrApjm(engagementDetails.engagementDetails);
                        this.engagementContractSignatureDate = engagementDetails.engagementDetails.signatureDate;
                    }
                    if (engagementDetails.error || milestoneDetails.error) {
                        this.isServerError = true;
                    }
                });
        });
    }

    /**
     * Check milestone due date is greater than confirmed data and return boolean
     * @param milestone
     */
    public checkDate(milestone: IBillingMilestoneModel): boolean {
        return moment(milestone.dueDate).isAfter(milestone.confirmedDate);
    }

    /**
     * Open modal to prompt user to either confirm or unconfirm
     * @param {IBillingMilestoneModel} milestone
     * @param {boolean} isConfirm
     * @param {boolean} isManage
     */
    public openConfirmationModal(milestone: IBillingMilestoneModel, isConfirm: boolean, isManage: boolean, engagementContractSignatureDate: Date): void {
        this.dmLogger.logEvent(SourceConstants.Component.MilestonesPage, SourceConstants.Method.OpenConfirmationModal, LogEventConstants.OpenMilestonesConfirmationModal, { isConfirm });
        const modalRef: NgbModalRef = this.modalService.open(MilestonesModalComponent, {
            backdrop: "static",
            windowClass: "manage-wbs-modal confirm-modal in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.milestone = milestone;
        modalRef.componentInstance.isConfirm = isConfirm;
        modalRef.componentInstance.isManage = isManage;
        modalRef.componentInstance.bpId = this.bpId;
        modalRef.componentInstance.engagementContractSignatureDate = engagementContractSignatureDate;
    }

    /**
     * Returns boolean based on the milestonedescription
     * @param {string} mileStoneDescription
     */
    public hideMileStoneActions(mileStoneDescription: string): boolean {
        if (this.hideConfirmMilestoneDescriptions && this.hideConfirmMilestoneDescriptions.length > 0) {
            return (this.hideConfirmMilestoneDescriptions.indexOf(mileStoneDescription) === -1);
        }
        return false;
    }

    /**
     * Returns whether actios needs to displayed based on inputs
     * @param {string} mileStoneDescription
     */
    public hideMileStoneActionsBasedOnSelections(mileStoneDescription: string): boolean {
        return this.isLoggedInUserPpjmOrApjm && mileStoneDescription !== null && this.hideMileStoneActions(mileStoneDescription);
    }

    /**
     * Logs an event when employee link is clicked
     * @param link
     */
    public logEmployeeClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.MilestonesPage, SourceConstants.Method.LogEmployeeClick, LogEventConstants.EmployeeLinkClick);
    }


    /**
     * Builiding milestone array for manipulating
     *
     * @private
     * @param {IBillingMilestoneModel[]} billingMilestones
     * @returns {IBillingMilestoneYearsModel[]}
     * @memberof MilestonesComponent
     */
    private manipulateBillingMilestoneData(billingMilestones: IBillingMilestoneModel[]): IBillingMilestoneYearsModel[] {
        if (!billingMilestones || billingMilestones.length === 0) {
            return undefined;
        }

        /* sort Billing milestone array according to duedate (Ascending) */
        // todo check if this is done in the shared functions
        billingMilestones.sort((a: IBillingMilestoneModel, b: IBillingMilestoneModel) => {
            return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        });

        this.milestoneMonths = this.getMilestoneMonthName(billingMilestones);

        let milestoneArray: IBillingMilestoneYearsModel[] = [];

        billingMilestones.forEach((billingMilestone: IBillingMilestoneModel) => {
            const billingMilestoneArr: IBillingMilestoneModel[] = new Array(billingMilestone); // todo look at flattening
            const billingMilestoneMonth = this.getMonthName(billingMilestone.dueDate);

            if (billingMilestoneArr[0].confirmedDate.toString() === "0001-01-01T00:00:00") {
                billingMilestoneArr[0].confirmedDate = new Date();
            }

            /* Month of the year already has a milestone */
            if (milestoneArray[billingMilestoneMonth]) {
                const milestone = milestoneArray[billingMilestoneMonth].milestone;
                milestone.push(billingMilestoneArr[0]);
            } else if (billingMilestoneMonth && billingMilestoneArr) { /* this was previously in an if statement instead of an else if */
                milestoneArray[billingMilestoneMonth] = {
                    monthName: billingMilestoneMonth,
                    milestone: billingMilestoneArr
                };
            }
        });

        /* Get milestone for whole year i.e Add month in the array which don't have milestone */
        milestoneArray = this.getMilestoneForWholeYear(milestoneArray);
        return milestoneArray;
    }

    /**
     * Gets the month name for the given date
     * @param dueDate
     */
    private getMonthName(dueDate: Date): string {
        return dueDate ? moment(dueDate).format("MMMM YYYY") : undefined;
    }

    /**
     * Gets the milestone year model for the given milestone array
     * @param milestoneArray
     */
    private getMilestoneForWholeYear(milestoneArray: IBillingMilestoneYearsModel[]): IBillingMilestoneYearsModel[] {
        const milestoneForYear: IBillingMilestoneYearsModel[] = [];
        if (milestoneArray) {
            this.milestoneMonths.forEach((month) => {
                const milestoneMonth = month;
                if (milestoneArray[milestoneMonth]) {
                    const monthName = month + " ("
                        + milestoneArray[milestoneMonth].milestone.length + " "
                        + this.sharedFunctionsService.getWordPluralWithS("Milestone", milestoneArray[milestoneMonth].milestone.length, false)
                        + ")";

                    milestoneForYear.push(
                        {
                            monthName,
                            milestone: milestoneArray[milestoneMonth].milestone
                        }
                    );
                }
            });
        }
        return milestoneForYear;
    }

    /**
     * getting milestone month name
     *
     * @private
     * @param {IBillingMilestoneModel[]} billingMilestones
     * @returns {string[]}
     * @memberof MilestonesComponent
     */
    private getMilestoneMonthName(billingMilestones: IBillingMilestoneModel[]): string[] {
        const startDate = moment(billingMilestones[0].dueDate).date(1);
        const endDate = moment(billingMilestones[billingMilestones.length - 1].dueDate);
        const result: string[] = [];

        while (startDate.isSameOrBefore(endDate)) {
            result.push(moment(startDate).format("MMMM YYYY"));
            startDate.add(1, "month");
        }
        return result;
    }

    /**
     * Determine if the this is project view and if this project has contract type of Fixed Fee
     * If this is Engagement view or project view with project has contract type of Fixed Fee, then milestones should be shown
     * Otherwise, milestones should not be shown
     *
     * @private
     * @param {IEngagementDetails} engagementDetails
     * @param {string} projectId
     * @returns {boolean}
     * @memberof MilestonesComponent
     */
    private checkIfProjectIsFF(engagementDetails: IEngagementDetailsApiV2, projectId: string): boolean {
        if (projectId && engagementDetails.projects.length > 0) {
            const filteredProjectDetailArray = engagementDetails.projects.filter((proj: IProjectDetailsApiV2) => proj.id === projectId);
            if (filteredProjectDetailArray.length && filteredProjectDetailArray[0].contractType !== "FF") {
                return false;
            }
        }
        return true;
    }
}
