import { ILoadableState } from "../reducers";
import * as fromWbsDbDetails from "./wbs-db.action";
import { IForecast } from "./../../components/financial-mgmt/financial.model";

export interface IWbsDbState extends ILoadableState {
    wbsDbDetails: IForecast;
}

export const initialState: IWbsDbState = {
    wbsDbDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromWbsDbDetails.WbsDbActions): { [wbsId: string]: IWbsDbState } {
    switch (action.type) {
        case fromWbsDbDetails.WbsDbActionTypes.LOAD_WbsDb: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromWbsDbDetails.WbsDbActionTypes.LOAD_WbsDb_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                wbsDbDetails: action.wbsDbDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromWbsDbDetails.WbsDbActionTypes.LOAD_WbsDb_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromWbsDbDetails.WbsDbActionTypes.INVALIDATE_WbsDb: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}