<section class="fcr-expenses-form">
    <dm-loading [loadingText]="loadingMessage" [isCustomText]="true"
        [showLoading]="!fcrExpensesFormControlData && !fcrExpensesFormControlDataError"
        *ngIf="!fcrExpensesFormControlData">
    </dm-loading>
    <ng-container *ngIf="fcrExpensesFormControlDataError && !fcrExpensesFormControlData">
        <dm-service-error class="server-error" *ngIf="fcrExpensesFormControlDataError"
            [dataText]="fcrExpensesFormControlDataError" [showReloadMessage]="false"
            [ngbTooltipContent]="fcrExpensesFormControlDataError">
        </dm-service-error>
    </ng-container>
    <ng-container *ngIf="fcrExpensesFormControlData">
        <section class="justify-space-between">
            <section *ngIf="isEditable" class="dm-btn-holder dm-p-y-10">
                <button type="button" class="fxp-btn fxp-btn--primary dm-btn dm-btn--big" id="addNewExpense"
                    (click)="addNewExpense()">
                    <i class="icon icon-AddLegacy"></i>
                    <span>Add new expense</span>
                </button>
                <button class="fxp-btn fxp-btn--primary dm-btn dm-btn--big" id="adjustExistingExpense" type="button"
                    (click)="adjustExisting()">
                    <i class="icon icon-AddLegacy"></i>
                    <span>Adjust existing expense</span>
                </button>
            </section>
            <section class="pull-right display-grid">
                <div class="font-caption-alt" style="font-style: italic;">Auto Populated Role<span
                        class="color-legend auto-populated-role"></span>
                </div>
                <div class="font-caption-alt" style="font-style: italic;">Manually Added Role<span
                        class="color-legend manually-added-role"></span>
                </div>
                <div class="font-caption-alt" style="font-style: italic;">Plan & Forecast Edited Role<span
                        class="color-legend pnf-edit-role"></span>
                </div>
            </section>
        </section>
        <div *ngIf="isEditable">
            <span style="font-style: italic;">
                <ng-container *ngIf="getExpiredProjects().size > 0">
                    <span class="required-mark" aria-hidden="true">*</span>
                    FCRs cannot be raised for the projects with past end dates, in case you want to extend the end date
                    <span (click)="onEditProjectEndDate()"
                        style="cursor: pointer; text-decoration: underline; color: blue;">
                        click here
                    </span>
                    .
                </ng-container>
            </span>
        </div>
        <div class="fcr-expenses-form-table__grid" role="grid">
            <div id="requestedExpenseCaption" class="sr-only">Requested Expense</div>
            <div role="row" class="fcr-expenses-form-table__grid-row" aria-labelledby="requestedExpenseCaption">
                <div role="gridcell" class="fcr-expenses-form-table__grid-identifier-cell"></div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell">EBS Description / ID
                    <span class="required-mark"  aria-hidden="true">*</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell">Billable Type
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell">
                    Type of Contract
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell">
                    Existing Cost ({{fcrExpensesFormControlData?.planCurrency}})
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell text-align--right">
                    Planned Cost ({{fcrExpensesFormControlData?.planCurrency}})
                    <span>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'" [ngbTooltipContent]="'Enter planned cost'"
                            [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Enter planned cost'">
                        </dm-tooltip>
                        <span class="required-mark dm-p-l-5" aria-hidden="true">*</span>
                    </span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell">
                    DB Expense Cost
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="' Existing Delivery Baseline (DB) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="' Existing Delivery Baseline (DB) Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell">
                    CFP Expense Cost
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan (CFP) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan (CFP) Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell">
                    Additional Expense Cost ({{fcrExpensesFormControlData?.planCurrency}})
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'"
                        [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-header-cell" *ngIf="isEditable">
                    Actions
                </div>
            </div>
            <ng-container *ngFor="let expenseLine of value; trackBy: trackByFn; let i = index"
                class="ver-al-top fcr-expenses-form-table">
                <div role="row" *ngIf="expenseLine" class="fcr-expenses-form-table__grid-row text-size"
                    [ngClass]="{'bg-clr':expenseLine.editModeEnabled}">
                    <div role="gridcell" class="fcr-expenses-form-table__grid-identifier-cell">
                        <section class="identifier-highlight"
                            [ngClass]="{ 'auto-populated-role': expenseLine?.isAutoPopulated && !expenseLine?.isPnfEdit, 'manually-added-role': !expenseLine?.isAutoPopulated, 'pnf-edit-role': expenseLine?.isAutoPopulated && expenseLine?.isPnfEdit }">
                        </section>
                    </div>
                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <section *ngIf="!expenseLine?.editModeEnabled">
                            <ng-container *ngIf="expenseLine.currentState?.assignedTask">
                                <span class="font-weight-600">{{expenseLine.currentState?.assignedTask?.projectName + '
                                    - '
                                    +
                                    expenseLine.currentState?.assignedTask?.serviceName}}</span>
                                <br>
                                <span class="color-07"
                                    [textContent]="expenseLine.currentState?.assignedTask?.wbsl3Id"></span>
                            </ng-container>
                        </section>
                        <section class="w-100" *ngIf="!expenseLine.isNewRequest && expenseLine.editModeEnabled">
                            <select id="adjustExpenseEBSId_{{i}}" class="input__select font-caption-alt"
                                aria-label="Add EBS ID" [ngModel]="expenseLine?.currentState.existingResource"
                                (ngModelChange)="onSelectedExistingExpenseChange($event, expenseLine, fcrExpensesFormControlData.engagementDetails)"
                                [name]="'assignedTask-' + expenseLine.uuid" required>
                                <option *ngFor="let item of fcrExpensesFormControlData.existingResources"
                                    [ngClass]="{'disabled-color': item.isCrPendingInProject || !validateResourceProjectEndDate(item.structureId)}"
                                    [disabled]="!validateResourceProjectEndDate(item.structureId)"
                                    [title]="!validateResourceProjectEndDate(item.structureId) ? 'This project has an expired end date' : ''"
                                    [ngValue]="item">
                                    Task: {{item.structureId}} | Cost: {{item.plannedCost}} | {{item.roleDescription}}
                                </option>
                            </select>
                        </section>
                        <section class="w-100" *ngIf="expenseLine.isNewRequest && expenseLine.editModeEnabled">
                            <select id="addNewExpenseEBSId_{{i}}" class="input__select font-caption-alt"
                                aria-label="Add EBS ID" [ngModel]="expenseLine?.currentState.assignedTask"
                                (ngModelChange)="onSelectedTaskChange($event, expenseLine, fcrExpensesFormControlData.engagementDetails)"
                                [name]="'assignedTask-' + expenseLine.uuid" required>
                                <option *ngFor="let item of fcrExpensesFormControlData.existingTasks"
                                    [ngClass]="{'disabled-color': item.isCrPendingInProject || !validateProjectEndDate(item)}"
                                    [disabled]="!validateProjectEndDate(item)"
                                    [title]="!validateProjectEndDate(item) ? 'This project has an expired end date' : ''"
                                    [ngValue]="item">
                                    {{item.wbsl3Id}} | {{item.projectName}} - {{item.serviceName}}
                                </option>
                            </select>
                        </section>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite" *ngIf="expenseLine.currentState.isCrPendingInProject">
                            {{fcrRolesErrorMessages.PendingCrInProject}}
                        </p>
                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <section *ngIf="!expenseLine.editModeEnabled || expenseLine.currentState.existingResource"
                            [textContent]="expenseLine?.currentState?.billableType?.displayName">
                        </section>
                        <section
                            *ngIf="expenseLine?.isNewRequest && !expenseLine.currentState.assignedTask?.isFixedFeeProject">
                            {{expenseLine?.currentState?.billableType?.displayName}}
                        </section>

                        <section class="input__select--icon"
                            *ngIf="expenseLine?.editModeEnabled && ((expenseLine?.isNewRequest && expenseLine?.currentState?.assignedTask && expenseLine.currentState.assignedTask?.isFixedFeeProject) && billableTypes)">
                            <select id="addNewBillableTypeName_{{i}}"
                                class="input__select input__select--big font-caption-alt billable-type-select"
                                aria-label="Add Billable Type" [ngModel]="expenseLine.currentState.billableType"
                                (ngModelChange)="onBillableTypeChange($event, expenseLine)"
                                [name]="'role-' + expenseLine?.uuid" required>
                                <option *ngFor="let billableType of billableTypes" [ngValue]="billableType">
                                    {{billableType.displayName}}
                                </option>
                            </select>
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <span *ngIf="expenseLine.currentState.assignedTask"
                            class="dm-badge dm-badge-v2 dm-contract-type"
                            [ngClass]="expenseLine.currentState.assignedTask && expenseLine.currentState.assignedTask?.isFixedFeeProject ? 'contract-type-ff' : 'contract-type-tm'">{{
                            expenseLine.currentState.assignedTask?.isFixedFeeProject ? "FF" : "T&amp;M"}}</span>
                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <section *ngIf="expenseLine.currentState.assignedTask"
                            [textContent]="expenseLine?.currentState.existingCost || 0">
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <section *ngIf="!expenseLine.editModeEnabled" class="text-align-right"
                            [textContent]="expenseLine?.currentState.newPlannedCost">
                        </section>
                        <section
                            *ngIf="expenseLine.editModeEnabled && (expenseLine.currentState.assignedTask || expenseLine.currentState.existingResource)">
                            <input type="text" id="plannedCost_{{i}}" aria-label="Enter Planned Cost"
                                class="dm-input font-caption-alt text-align-right" required dmLimitNumeric
                                wholeNumberLength="9" [(ngModel)]="expenseLine.currentState.newPlannedCost"
                                [name]="'plannedCost_-' + expenseLine.uuid"
                                (ngModelChange)="onPlannedCostChange(expenseLine)">
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <section *ngIf="expenseLine.currentState.existingResource">
                            {{expenseLine.currentState.existingResource?.dbCost | number : '1.2-2'}}
                            {{expenseLine.currentState.existingResource?.dbCost ?
                            fcrExpensesFormControlData?.planCurrency
                            : ""}}
                        </section>

                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <section>
                            {{expenseLine.isDbOnlyDemand ? (0 | number : '1.2-2') :
                            (((expenseLine.currentState.existingResource?.cfpCost +
                            expenseLine.currentState.newPlannedCost)) |
                            number :
                            '1.2-2')}} {{fcrExpensesFormControlData?.planCurrency}}
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <section>
                            {{(expenseLine.isDbOnlyDemand ? 0 :
                            (expenseLine.currentState.existingResource?.cfpCost +
                            expenseLine.currentState.newPlannedCost)) -
                            (expenseLine.currentState.existingResource?.dbCost || 0) | number : '1.2-2'}}
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                        <div class="dm-btn-holder" *ngIf="!expenseLine.editModeEnabled && isEditable">
                            <button *ngIf="!expenseLine.isDbOnlyDemand" type="button" id="editBtn_{{i}}"
                                aria-label="Edit" class="dm-transparent-btn dm-accent-color"
                                (click)="onEditClick(expenseLine, i)">
                                <i class="icon icon-editLegacy"></i>
                                <span>Edit</span>
                            </button>
                            <dm-tooltip *ngIf="expenseLine.isDbOnlyDemand" role="tooltip" class="dm-tooltipinline"
                                [dmPlacement]="'left'" [dmTriggers]="'hover'"
                                [ngbTooltipContent]="'This autopopulated demand cannot be edited. This demand was autopopulated to reconcile DB and CFP for a demand which exists on Delivery Baseline but does not exist on Current Financial Plan. This will remove the demand in Delivery Baseline.'"
                                [dmIcon]="'icon icon-info'" ariaLabelToolTip="' Delivery Baseline (DB) Demand'">
                            </dm-tooltip>
                            <button type="button" id="deleteBtn_{{i}}" aria-label="Delete"
                                class="dm-transparent-btn dm-accent-color"
                                [disabled]="expenseLine.isPnfEdit || expenseLine.isAutoPopulated"
                                (click)="deleteRequest($event, i);">
                                <i class="icon icon-delete"></i>
                                <span>Delete</span>
                            </button>
                        </div>
                        <div class="dm-btn-holder" *ngIf="(expenseLine.editModeEnabled) && isEditable">
                            <button type="button" id="saveBtn_{{i}}" aria-label="Save"
                                class="dm-transparent-btn dm-accent-color" [disabled]="!expenseLine.isSaveable"
                                (click)="saveRequest(i);">
                                <i class="icon icon-save"></i>
                                <span>Save<span class="required-field">*</span></span>
                            </button>
                            <button type="button" id="cancelBtn_{{i}}" aria-label="Cancel"
                                class="dm-transparent-btn dm-accent-color"
                                (click)="expenseLine.editModeEnabled=false; discardChanges($event, i)">
                                <i class="icon icon-close"></i>
                                <span>Cancel</span>
                            </button>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite" *ngIf="!expenseLine.currentState.isContractTypeValid">
                                {{invalidContractTypeMessage}}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div role="row" class="fcr-expenses-form-table__grid-row" aria-labelledby="requestedExpenseCaption">
                <div role="gridcell" class="fcr-expenses-form-table__grid-identifier-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                    <span class="font-weight-600">Totals</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell text-align--right">
                    <span class="font-weight-600">{{totalPlannedExpenseCost | number : '1.2-2'}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell">
                    <span class="font-weight-600">{{totalAdditionalExpenseCost | number : '1.2-2'}}</span>
                </div>
                <div role="gridcell" class="fcr-expenses-form-table__grid-body-cell" *ngIf="isEditable">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <span class="sr-only" tabindex="-1" role="alert" *ngIf="statusMessage">{{statusMessage}}</span>
</section>