import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[dmLimitNumeric]"
})
export class LimitNumericDirective {
    @Input("decimals") public decimals: number = 0;
    @Input("wholeNumberLength") public wholeNumberLength: number;
    @Input("negativesAllowed") public negativesAllowed: boolean = true;

    private specialKeys: string[] = ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight", "Del", "Delete"];

    public constructor(private el: ElementRef) { }

    /**
     * Listen for key down events and run check for numeric format.
     *
     * @param {KeyboardEvent} event
     * @returns
     * @memberof LimitNumericDirective
     */
    @HostListener("keydown", ["$event"])
    public onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        this.runForKeyboard(event);
    }

    /**
     * Listen for clipboard paste event and run check for numeric format.
     *
     * @param {ClipboardEvent} event
     * @memberof LimitNumericDirective
     */
    @HostListener("paste", ["$event"])
    public onPaste(event: ClipboardEvent): void {
        this.runForClipboard(event);
    }

    /**
     * Check numeric format based on directive input parameters.
     *
     * @private
     * @param {string} value
     * @returns
     * @memberof LimitNumericDirective
     */
    private check(value: string) {
        if (this.decimals <= 0) {
            const nonDecimalRegex = `^${this.negativesAllowed ? "-?" : ""}\\d{0,${this.wholeNumberLength ? this.wholeNumberLength : ""}}$`;
            return String(value).match(new RegExp(nonDecimalRegex, "g"));
        } else {
            const decimalRegex = `^${this.negativesAllowed ? "-?" : ""}(\\d{0,${this.wholeNumberLength ? this.wholeNumberLength : ""}})(\\.\\d{0,${this.decimals}})?$`;
            return String(value).match(new RegExp(decimalRegex, "g"));
        }
    }

    /**
     * Process keyboard event.
     *
     * @private
     * @param {*} oldValue
     * @param {KeyboardEvent} event
     * @memberof LimitNumericDirective
     */
    private runForKeyboard(event: KeyboardEvent) {
        const currentValue: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const nextValue: string = [currentValue.slice(0, position), event.key, currentValue.slice(position)].join("");
        if (currentValue !== "" && !this.check(nextValue)) {
            event.preventDefault();
        }
    }

    /**
     * Process clipboard event.
     *
     * @private
     * @param {*} oldValue
     * @param {ClipboardEvent} event
     * @memberof LimitNumericDirective
     */
    private runForClipboard(event: ClipboardEvent) {
        const currentValue: string = event.clipboardData.getData("text");
        if (currentValue !== "" && !this.check(currentValue)) {
            event.preventDefault();
        }
    }
}