import * as fromContacts from "./contacts.action";

import { IContactsResponse } from "../../common/services/contracts/contacts.contracts";
import { ILoadableState } from "../reducers";

export interface IContactsState extends ILoadableState {
    contactsResponse: IContactsResponse;
}

export const initialState: IContactsState = {
    contactsResponse: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromContacts.ContactsAction): { [wbsId: string]: IContactsState } {
    switch (action.type) {
        case fromContacts.ContactsActionTypes.LOAD_CONTACTS: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromContacts.ContactsActionTypes.LOAD_CONTACTS_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                contactsResponse: action.contactsResponse,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromContacts.ContactsActionTypes.LOAD_CONTACTS_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromContacts.ContactsActionTypes.INVALIDATE_CONTACTS: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}