import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject, Input, Injector } from "@angular/core";
import { UserInfoService, FxpEventBroadCastService, FxpRouteService, ErrorSeverityLevel } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { ConcurService } from "../../../common/services/concur.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { getEntireManageSuppliers } from "../../../store/manage-suppliers/manage-suppliers.selector";
import { getEntireMilestones } from "../../../store/milestones/milestones.selector";
import { getEntireProjectDetails } from "../../../store/project-details/project-details.selector";
import { getEntireResourceRequestsProjectContextStateObject } from "../../../store/resource-requests-project-context/resource-requests-project-context.selector";
import { getEntireResourceRequestsStateObject } from "../../../store/resource-requests/resource-requests.selector";
import { getMyPortfolioEngagementListState } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IBillingMilestoneModel } from "../../../common/services/contracts/dmmilestone.service.contract";
import { IConcurPendingReportsResponse } from "../../../common/services/contracts/concur.contracts";
import { IEngagementDetailsApiV2, ITeamDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { IEngagementList } from "../../../common/services/contracts/portfolio.model";
import { IKeyActionsExpenseReports, IKeyActionsModel, IKeyActionsEngagements, IKeyActionContractSignatureAfterEngagementStartDate, IMisalignmentNotificationKeyActionsModel, ISubconStatusKeyActionsModel, IExpiringECIFIOKeyActionsModel, IPendingUnitApprovalsKeyActionsModel } from "../../../common/services/contracts/wwtk.service.contracts";
import { ILaborManagementApprovalResponse, ILaborManagementApproval, ILaborApprovalCountBasedOnAssignments } from "../../../common/services/contracts/labor-management.contract";
import { IManageSuppliersState } from "../../../store/manage-suppliers/manage-suppliers.reducer";
import { IMilestonesState } from "../../../store/milestones/milestones.reducer";
import { IMyPortfolioEngagementListState } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IProjectDetailsState } from "../../../store/project-details/project-details.reducer";
import { IPurchaseOrder } from "../../../common/services/contracts/po.service.contracts";
import { IResourceRequestsDetailsState } from "../../../store/resource-requests/resource-requests.reducer";
import { IReturnedResourceRequest, IResourceRequestResponse, IPendingResourceDetails } from "../../../common/services/contracts/staffing.service.contract";
import { LaborManagementService } from "../../../common/services/labor-management.service";
import { ManageSuppliersService } from "../../../common/services/manage-suppliers.service";
import { ReturnedResourceRequestComponent } from "../returned-resource-modal/returned-resource-modal.component";
import { RouteName, Components, KeyAction, BroadcastEvent, LogEventName, NoDataText, SourceConstants } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { StaffingService } from "../../../common/services/staffing.service";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import moment from "moment";
import { DmError } from "../../../common/error.constants";
import { ITile } from "../dm-tile/dm-tile.component";
import { IKeyActionV2 } from "../../../common/services/contracts/key-actions-v2.contract";
import { AmendmentsService } from "../../../common/services/amendments.service";
import { UnitsService } from "../../../common/services/unit.service";
import { IMisalignmentNotification } from "../../amendments/amendments.contract";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { ISubconOnboardingSnapshot } from "../../../common/services/contracts/one-profile.contracts";
import { ProjectService } from "../../../common/services/project.service";

const pendingApprovalStatus = [
    "Committed",
    "Assigned",
    "Complete",
    "Closed"
];

/*
This mapping aligns the input key actions from the parent tile. Depending on where the key actions component is called, the
functionality will be different, so the parent component can pass in any of these actions and this component will then
map the actions to the methods with the same name.
*/
const KeyActionMappings: IKeyActionV2[] = [
    {
        actionName: KeyAction.PendingEngagementsForRelease,
        methodName: "getPendingEngagementsForRelease"
    },
    {
        actionName: KeyAction.PendingExpenseReportsPortfolioContext,
        methodName: "getPendingExpenseReportsPortfolioContext"
    },
    {
        actionName: KeyAction.PendingLaborApproval,
        methodName: "getPendingApprovalCountForLoggedInUser"
    },
    {
        actionName: KeyAction.PendingResources,
        methodName: "getPendingResources"
    },
    {
        actionName: KeyAction.RecieptsDue,
        methodName: "loadRecieptsDueKeyActions"
    },
    {
        actionName: KeyAction.PendingMilestonesForConfirmation,
        methodName: "loadPendingMilestonesForConfirmation"
    },
    {
        actionName: KeyAction.PendingLaborBasedOnAssignments,
        methodName: "getPendingLaborApprovalCountBasedOnAssignments"
    },
    {
        actionName: KeyAction.PendingExpenseReportsBasedOnEntityId,
        methodName: "getPendingExpenseReportsBasedOnEntityId"
    },
    {
        actionName: KeyAction.EngagementPendingForRelease,
        methodName: "getEngagementPendingForReleaseKeyAction"
    },
    {
        actionName: KeyAction.EngagementStartDateLessContractStartDate,
        methodName: "getEngagementStartDateLessThanContractStartDate"
    },
    {
        actionName: KeyAction.ActiveMisalignemdContractualAmendments,
        methodName: "getActiveMisalignedContractualAmendments"
    },
    {
        actionName: KeyAction.SubconOnboardingStatus,
        methodName: "getSubconOnboardingStatus"
    },
    {
        actionName: KeyAction.ExpiringEcifIO,
        methodName: "getExpiringEcifIO"
    },
    {
        actionName: KeyAction.PendingUnitApprovals,
        methodName: "getPendingUnitApprovals"
    }
];

@Component({
    selector: "dm-key-actions-v2",
    templateUrl: "./key-actions-v2.html",
    styleUrls: ["./key-actions-v2.scss"]
})
export class KeyActionsV2Component extends DmComponentAbstract {
    @Input() public listOfActions: string[];
    @Input() public isNotification: boolean = false;
    public engagementKeyActions: IKeyActionsEngagements[] = [];
    public expenseKeyActions: IKeyActionsExpenseReports[] = [];
    public contractualMisalignmentKeyActions: IMisalignmentNotificationKeyActionsModel[] = [];
    public subconStatusKeyActions: ISubconStatusKeyActionsModel[] = [];
    public expiringEcifIoKeyActions: IExpiringECIFIOKeyActionsModel[] = [];
    public receiptsDueKeyActions: IKeyActionsModel[] = [];
    public noKeyActionText: string;
    public pendingLaborApprovals: ILaborManagementApproval[];
    public resourceDueHours: string;
    public resourceProposalCount: number;
    public returnedRequestList: IReturnedResourceRequest[] = [];
    public RouteName = RouteName;
    public contractSignatureAfterEngagementStartDateKeyAction: IKeyActionContractSignatureAfterEngagementStartDate;
    public showExpenseReportsSection: boolean = true;
    public showLaborApprovalsSection: boolean = true;
    public showReleaseAndActivateSection: boolean = true;
    public showContractualMisalignmentSection: boolean = true;
    public showResourceProposalsSection: boolean = true;
    public showReturnedResourceRequestSection: boolean = true;
    public showTimeApprovalSection: boolean = true;
    public showReceiptsDueSection: boolean = true;
    public showContractSignatureSection: boolean = true;
    public showMilestonesSection: boolean = true;
    public showSubconOnboardingKeyActionSection: boolean = true;
    public showExpiringEcifIo: boolean = true;
    public totalReturnedRequests: number;
    public LogEventName = LogEventName;
    public milestoneRoute: string;
    public financialRoute: string;
    public isPrimaryProjectManager: boolean = false;
    public milestoneDayDiff: number = 0;
    public totalUpcomingMileStone: number;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.KeyActions;
    public isDueDateOver: boolean = false;
    public tileContent: ITile;
    public isEcifIoConsumptionFeatureEnabled: boolean = false;
    public pendingUnitApprovalsKeyActions: IPendingUnitApprovalsKeyActionsModel[] = [];
    public unitsApprovalLink: string;
    private concurUrl: string;
    private engagementList: IEngagementList[] = [];
    private hasReturnedResource: boolean = false;
    private msApprovalsPendingUrl: string;
    private isPortfolioApiLoading: boolean;
    private isExpenseReportsApiLoading: boolean;
    private isPendingApprovalApiLoading: boolean;
    private isPendingResourcesApiLoading: boolean;
    private isManageSuppliersApiLoading: boolean;
    private isMilestonesApiLoading: boolean;
    private isEngagementPendingForReleaseLoading: boolean;
    private isContractSignatureAfterEngStartDateLoading: boolean;
    private keyActionActivities: IKeyActionV2[];
    private subconOnboardingPendingDasboardBaseUrl: string;    
    public constructor(
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(AmendmentsService) private amendmentService: AmendmentsService,
        @Inject(UnitsService) private unitService: UnitsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(ManageSuppliersService) private manageSuppliersService: ManageSuppliersService,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
        @Inject(ConcurService) private concurService: ConcurService,
        @Inject(StateService) private stateService: StateService,
        @Inject(LaborManagementService) private laborManagementService: LaborManagementService,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        @Inject(Store) private store, /* : Store<IState>*/ // when we type this, FXP throws a linting error during build process that untilDestroyed expects 0 arguments but got 1
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Injector) private injector: Injector,
        @Inject(FxpEventBroadCastService) private fxpBroadCastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService
    ) {
        super(dmLogger, Components.KeyActionsV2);
        this.keyActionActivities = [];
    }

    public ngOnInit(): void {
        this.initializeVariables();
        this.loadActions();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.fxpBroadCastService.On(BroadcastEvent.PortFolioRefresh, (event, args) => {
            this.loadActions();
        });
        if (this.stateService.params && this.stateService.params.isnavigationfromsubcon && this.stateService.params.isnavigationfromsubcon === "true") {
            this.showExpenseReportsSection = false;
            this.showLaborApprovalsSection = false;
            this.showReleaseAndActivateSection = false;
            this.showContractualMisalignmentSection = false;
            this.showResourceProposalsSection = false;
            this.showReturnedResourceRequestSection = false;
            this.showTimeApprovalSection = false;
            this.showReceiptsDueSection = false;
            this.showContractSignatureSection = false;
            this.showMilestonesSection = false;
            this.showExpiringEcifIo = false;
        }
        this.tileContent = {
            title: "Key Actions",
            iconTitle: "icon-timer"
        };
    }


    /**
     * Open Unapproved labor modal popup
     *
     * @memberof KeyActionsComponent
     */
    public openReturnedResourceModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.KeyActions, SourceConstants.Method.OpenReturnedResourceModal, LogEventName.PortfolioKeyActionsLinkClick);
        const modalRef: NgbModalRef = this.modalService.open(ReturnedResourceRequestComponent, {
            backdrop: "static",
            centered: true,
            keyboard: true,
            windowClass: "in active dm-modal returned-resource",
            injector: this.injector
        });
        modalRef.componentInstance.returnedResourceRequestList = this.returnedRequestList;
    }

    /**
     * Checks if any key actions are available.
     * Will display a message if no key actions are available.
     * Will not display a message if there are key actions.
     */
    public isDataAvailableForKeyActions(): boolean {
        if (!this.hasReturnedResource && (!this.engagementKeyActions || !this.engagementKeyActions.length) && (!this.returnedRequestList || !this.returnedRequestList.length) && (!this.expenseKeyActions || !this.expenseKeyActions.length) && (!this.pendingLaborApprovals || !this.pendingLaborApprovals.length) && (!this.receiptsDueKeyActions || !this.receiptsDueKeyActions.length) && (!this.contractSignatureAfterEngagementStartDateKeyAction || !this.contractSignatureAfterEngagementStartDateKeyAction.isContractSignatureAfterEngagementStartDate) && (!this.totalUpcomingMileStone || !this.milestoneDayDiff || !this.isPrimaryProjectManager) && (this.expiringEcifIoKeyActions && this.expiringEcifIoKeyActions.length)) {
            return false;
        }
        return true;
    }

    /**
     * Checks if any key actions are being processed.
     * Returns false if any of the key actions are loaded.
     */
    public showLoadingForComponent(): boolean {
        if (this.isPortfolioApiLoading && this.isPendingApprovalApiLoading && this.isPendingResourcesApiLoading && this.isExpenseReportsApiLoading && this.isManageSuppliersApiLoading && this.isMilestonesApiLoading && this.isEngagementPendingForReleaseLoading && this.isContractSignatureAfterEngStartDateLoading) {
            return true;
        } else if ((this.engagementKeyActions && this.engagementKeyActions.length) || (this.returnedRequestList && this.returnedRequestList.length) || (this.expenseKeyActions && this.expenseKeyActions.length) || (this.pendingLaborApprovals && this.pendingLaborApprovals.length) || (this.receiptsDueKeyActions && this.receiptsDueKeyActions.length)) {
            return false;
        } else if (!this.isPendingApprovalApiLoading && !this.isPendingResourcesApiLoading && !this.isPortfolioApiLoading && !this.isExpenseReportsApiLoading && !this.isManageSuppliersApiLoading && !this.isMilestonesApiLoading && !this.isEngagementPendingForReleaseLoading && !this.isContractSignatureAfterEngStartDateLoading) {
            return false;
        }
        return true;
    }

    /**
     * Sets the title or aria-label on collapsing and expanding key action
     */
    public setTitleOrAriaLabelForExpandCollapseKeyAction(keyAction: string, isExpanded: boolean): string {
        return (isExpanded ? "Collapse " : "Expand ") + keyAction;
    }


    /**
     * Logs Event of links clicked from Key Actions.
     */
    public logKeyActionsClickEvent(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryV2Page, SourceConstants.Method.LogKeyActionsClickEvent, LogEventName.PortfolioKeyActionsLinkClick);
    }

    /**
     * Check if day is within range
     * @param days days before expiry
     * @returns {boolean}
     */
    public isEndDateWithinRange(days: string, thresholdDays: number): boolean {
        if (isNaN(parseInt(days, 10))) {
            return false;
        }
        else if (parseInt(days, 10) <= thresholdDays) {
            return true;
        }
        return false;
    }

    /**
     * Get absolute days
     * @param days days
     * @returns absolute days
     */
    public getAbsoluteDays(days: string): string {
        if (isNaN(parseInt(days, 10))) {
            return days;
        }
        else {
            return Math.abs(parseInt(days, 10)).toString();
        }
    }

    /**
     * Load key actions based on parent page action inputs.
     * Will load different types of data based on the page the tile is called from.
     *
     * @memberof KeyActionsComponent
     */
    private loadActions(): void {
        if (this.keyActionActivities && this.keyActionActivities.length > 0) {
            for (const keyActionActivity of this.keyActionActivities) {
                this[keyActionActivity.methodName]();
            }
        }
    }

    /**
     * Initialize Variables for the Component
     *
     * @memberof KeyActionsComponent
     */
    private initializeVariables(): void {
        this.noKeyActionText = NoDataText.NoKeyActionText;
        this.concurUrl = this.configManagerService.getValue<string>("concurUrl");
        this.unitsApprovalLink = this.configManagerService.getValue<string>("unitApprovalsLink");
        this.msApprovalsPendingUrl = this.configManagerService.getValue<string>("msApprovalsPendingUrl");
        /* Checks the list of actions that was passed in, will depend on where the key actions tile is placed, either on Portfolio or Eng Summary */
        if (this.listOfActions && this.listOfActions.length > 0) {
            for (const action of this.listOfActions) {
                const filteredActionDetails = KeyActionMappings.filter((entity) => entity.actionName === action);
                /* For the actions passed in, find the mapping that aligns with the function name, and then push it to the activity array.*/
                if (filteredActionDetails[0] && this[filteredActionDetails[0].methodName]) {
                    this.keyActionActivities.push(filteredActionDetails[0]);
                }
            }
        }
        this.subconOnboardingPendingDasboardBaseUrl = this.configManagerService.getValue<string>("subconOnboardingPendingDashboardLink");        
        this.isEcifIoConsumptionFeatureEnabled = this.configManagerService.isFeatureEnabled("ecifIoExtension");
    }

    /**
     * Gets message based on difference between approval date and current date.
     *
     * @private
     * @param {Date} approvalDate
     * @returns {string}
     * @memberof KeyActionsComponent
     */
    private getDaysDueMessage(days: number): string {
        if (days === 1) {
            return `${days} Day`;
        } else if (days === 0) {
            return "Now";
        } else if (days > 0) {
            return `${days} Days`;
        } else {
            return `${Math.abs(days)} Days Past Due`;
        }
    }

    /**
     * Returns the pending labor count per required approval date for a given assigned or delegated approver.
     */
    private getPendingSubconOnboardingByUser(userAlias): Promise<ISubconStatusKeyActionsModel[]> {
        return this.oneProfileService.getSubconOnboardingStatus(userAlias).then((onboardingSnapshotResponse: ISubconOnboardingSnapshot[]) => {
            if (onboardingSnapshotResponse && onboardingSnapshotResponse.length) {
                return onboardingSnapshotResponse.map((s) => {
                    return {
                        projectId: s.demandSourceId,
                        projectName: s.projectName,
                        pendingOnboarding: s.pendingOnboarding,
                        engagementId: s.engagementId,
                        grmProjectId: s.projectId,
                        subconOnboardingPendingDasboardUrl:                            
                            this.subconOnboardingPendingDasboardBaseUrl + s.engagementId + "?packageId=" + s.demandSourceId
                    };
                }).filter((i) => i.pendingOnboarding > 0);
            }
            else {
                return [];
            }
        })
            .catch((error) => {
                const errorMessage = this.sharedFunctionService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.GetPendingApprovalCountByDateForApprover, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.resolve([]);
            });
    }


    private getSubconOnboardingStatus() {
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const projectId: string = this.sharedFunctionService.getSelectedProjectId(this.stateService);
        const userAlias = this.sharedFunctionService.getCurrentUserInfoAsSelectedUserAttr().userAlias;
        const entityId = engagementId ? engagementId : projectId;
        // Check if summary
        if (entityId) {
            const entityDetails$ = projectId ? this.store.select(getEntireProjectDetails(projectId)) : this.store.select(getEntireEngagementDetails(engagementId));
            entityDetails$.pipe(untilDestroyed(this)).subscribe((entityDetailState: IEngagementDetailsState | IProjectDetailsState) => {
                if (userAlias) {
                    this.getPendingSubconOnboardingByUser(userAlias).then((filteredSubconOnboardingKeyActions: ISubconStatusKeyActionsModel[]) => {
                        if (filteredSubconOnboardingKeyActions && filteredSubconOnboardingKeyActions.length) {
                            if (engagementId) {
                                this.subconStatusKeyActions = filteredSubconOnboardingKeyActions.filter((i) => this.sharedFunctionService.getEngagementIdFromProjectId(i.projectId) === engagementId);
                            }
                            else if (projectId) {
                                this.subconStatusKeyActions = filteredSubconOnboardingKeyActions.filter((i) => i.projectId === projectId);
                            }
                        }
                    });
                }
            });
        }
        // For portfolio
        else {
            const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
            this.storeDispatchService
                .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
                .load();
            myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
                if (engagementList.loaded && engagementList.engagementList.length) {
                    if (userAlias) {
                        this.getPendingSubconOnboardingByUser(userAlias).then((filteredSubconOnboardingKeyActions: ISubconStatusKeyActionsModel[]) => {
                            if (filteredSubconOnboardingKeyActions && filteredSubconOnboardingKeyActions.length) {
                                this.subconStatusKeyActions = filteredSubconOnboardingKeyActions;
                            }
                        });
                    }
                }
            });

        }
    }

    private getExpiringEcifIO(): void {
        if (this.isEcifIoConsumptionFeatureEnabled) {
            const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
            const projectId: string = this.sharedFunctionService.getSelectedProjectId(this.stateService);
            this.financialRoute = projectId ? RouteName.ProjectFinancials : RouteName.EngagementFinancials;
            const entityId = engagementId ? engagementId : projectId;
            const entityDetails$ = projectId ? this.store.select(getEntireProjectDetails(projectId)) : this.store.select(getEntireEngagementDetails(engagementId));
            entityDetails$.pipe(untilDestroyed(this)).subscribe((entityDetailState: IEngagementDetailsState | IProjectDetailsState) => {
                if (entityDetailState.loaded) {
                    this.projectService.getEcifIoConsumption(entityId).then((response) => {
                        if (response && response.length) {
                            response.forEach((w) => {
                                w.ioDetails.forEach((i) => {
                                    if (this.isEndDateWithinRange(i.daysBeforeExpiry, 30)) {
                                        if (!this.expiringEcifIoKeyActions.some((x) => x.ioNumber === i.ioNumber)) {
                                            this.expiringEcifIoKeyActions.push({
                                                ioNumber: i.ioNumber,
                                                daysTillExpiry: i.daysBeforeExpiry
                                            });
                                        }
                                    }

                                });

                            });
                        }
                    });
                }
            });
        }
    }


    private getActiveMisalignedContractualAmendments(): void {
        // Determine if this is being called in Summary or Portfolio
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        if (engagementId) {
            this.amendmentService.getActiveMisalignedAmendments([engagementId])
                .then((misalignmentNotification: IMisalignmentNotification[]) => {
                    if (misalignmentNotification && misalignmentNotification.length) {
                        const keyActions = misalignmentNotification.map((m) => { return { engagementId: m.engagementId, processedChangeRequestId: m.pendingChangeRequestId, pendingChangeRequestId: m.pendingChangeRequestId, daysDue: "Now" }; });
                        this.contractualMisalignmentKeyActions.push(...keyActions);
                    }
                });
        }

        else {
            const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);

            this.storeDispatchService
                .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
                .load();
            myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
                this.isPortfolioApiLoading = engagementList.loading;
                if (engagementList.loaded) {
                    const engagementIdList: string[] = engagementList.engagementList.filter((x: IEngagementList) => x.type.toLowerCase() === "engagement").map((engagement) => { return engagement.engagementId; });
                    if (engagementIdList && engagementIdList.length > 0) {
                        this.amendmentService.getActiveMisalignedAmendments(engagementIdList)
                            .then((misalignmentNotification: IMisalignmentNotification[]) => {
                                if (misalignmentNotification && misalignmentNotification.length) {
                                    const keyActions = misalignmentNotification.map((m) => { return { engagementId: m.engagementId, processedChangeRequestId: m.pendingChangeRequestId, pendingChangeRequestId: m.pendingChangeRequestId, daysDue: "Now" }; });
                                    this.contractualMisalignmentKeyActions.push(...keyActions);
                                }
                            });
                    }

                    this.markActivityForCompletion(KeyAction.ActiveMisalignemdContractualAmendments);
                }
                if (engagementList.error) {
                    this.isServerError = true;
                    this.markActivityForCompletion(KeyAction.ActiveMisalignemdContractualAmendments);
                }
            });
        }
    }

    private getPendingUnitApprovals(): void {
        // Determine if this is being called in Engagement Summary or Portfolio
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        this.unitService.getPendingUnitApprovalsForLoggedInUser()
            .then((data: { [key: string]: number }) => {
                this.pendingUnitApprovalsKeyActions = [];
                if (data ) {
                    if (engagementId) {
                        const indexOfEngagementId = Object.keys(data).indexOf(engagementId);
                        if (indexOfEngagementId > -1) {
                            const filteredObject = Object.entries(data)[indexOfEngagementId];
                            this.pendingUnitApprovalsKeyActions.push({
                                engagementId: filteredObject[0],
                                pendingCount: filteredObject[1]
                            });
                        }                      
                    } else {
                        Object.entries(data).forEach(([key, value]) => {
                            this.pendingUnitApprovalsKeyActions.push({
                                engagementId: key,
                                pendingCount: value
                            });
                        });                      
                    }
                  
                }
            });
        
    }


    /*
        Get key showing that engagement start date is less than contract start date
    */
    private getEngagementStartDateLessThanContractStartDate(): void {
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(engagementId));
        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetailState: IEngagementDetailsState) => {
            this.isContractSignatureAfterEngStartDateLoading = engagementDetailState.loading;
            if (engagementDetailState.loaded) {
                const currentUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
                const pjmDetails: ITeamDetailsV2[] = this.sharedFunctionService.getPjmInfoL0("PPJM", engagementDetailState.engagementDetails);
                const addlPjm: ITeamDetailsV2[] = this.sharedFunctionService.getPjmInfoL0("ADPPJM", engagementDetailState.engagementDetails);
                const showContractSignatureAfterEngStartDate = ((pjmDetails[0] && Number(pjmDetails[0].bpid) === currentUserBPID) || (addlPjm && addlPjm.filter((x: ITeamDetailsV2) => Number(x.bpid) === currentUserBPID).length > 0)) &&
                    moment(engagementDetailState.engagementDetails.startDate).isBefore(engagementDetailState.engagementDetails.signatureDate, "day");
                this.contractSignatureAfterEngagementStartDateKeyAction = {
                    engagementId,
                    isContractSignatureAfterEngagementStartDate: showContractSignatureAfterEngStartDate
                };
                this.markActivityForCompletion(KeyAction.EngagementStartDateLessContractStartDate);
            }
            else if (engagementDetailState.error) {
                this.markActivityForCompletion(KeyAction.EngagementStartDateLessContractStartDate);
            }
        });
    }

    /**
     * Get Pending expense reports based on EntityId;
     * Used as part of the Key Actions Mapping.
     */
    private getEngagementPendingForReleaseKeyAction(): void {
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(engagementId));
        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetailState: IEngagementDetailsState) => {
            this.isEngagementPendingForReleaseLoading = engagementDetailState.loading;
            if (engagementDetailState.loaded) {
                if (engagementDetailState.engagementDetails && engagementDetailState.engagementDetails.statusDescription.toLowerCase() === "created" && engagementDetailState.engagementDetails.pPjm.alias.toLowerCase() === this.sharedFunctionService.getCurrentUserInfoAsSelectedUserAttr().userAlias.toLowerCase()
                    && this.engagementKeyActions.filter((eng) => eng.engagementId === engagementDetailState.engagementDetails.id).length === 0) {
                    this.engagementKeyActions.push({
                        engagementId: engagementDetailState.engagementDetails.id,
                        daysDue: moment().startOf("day").diff(moment(engagementDetailState.engagementDetails.startDate).subtract(14, "days").toDate(), "days")
                    });
                }
                this.markActivityForCompletion(KeyAction.EngagementPendingForRelease);
            }
            if (engagementDetailState.error) {
                this.isServerError = true;
                this.markActivityForCompletion(KeyAction.EngagementPendingForRelease);
            }
        });
    }

    /**
     * Get Pending expense reports based on EntityId
     * Used as part of the Key Actions Mapping.
     */
    private getPendingExpenseReportsBasedOnEntityId(): void {
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const projectId: string = this.sharedFunctionService.getSelectedProjectId(this.stateService);
        const entityDetails$ = projectId ? this.store.select(getEntireProjectDetails(projectId)) : this.store.select(getEntireEngagementDetails(engagementId));
        entityDetails$.pipe(untilDestroyed(this)).subscribe((entityDetailState: IEngagementDetailsState | IProjectDetailsState) => {
            this.isExpenseReportsApiLoading = entityDetailState.loading;
            if (entityDetailState.loaded) {
                const projectDetails = projectId ? [(entityDetailState as IProjectDetailsState).projectDetails.projectFullDetails]
                    : (entityDetailState as IEngagementDetailsState).engagementDetails.projects;
                const expenseWbsIds: string[] = this.sharedFunctionService.getExpenseTypeWbsIds(projectDetails);
                const queryStringForExpenseReports = this.getQueryStringBasedOnTile(expenseWbsIds);
                this.getPendingExpenseReports(queryStringForExpenseReports).then((expenseReportResponse: IKeyActionsExpenseReports[]) => {
                    this.expenseKeyActions = expenseReportResponse;
                }).finally(() => {
                    this.markActivityForCompletion(KeyAction.PendingExpenseReportsBasedOnEntityId);
                });
            }
            if (entityDetailState.error) {
                this.isServerError = true;
                this.markActivityForCompletion(KeyAction.PendingExpenseReportsBasedOnEntityId);
            }
        });
    }

    /**
     * Gets the Pending labor based on assignments
     * Used as part of the Key Actions Mapping.
     */
    private getPendingLaborApprovalCountBasedOnAssignments(): void {
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const projectId: string = this.sharedFunctionService.getSelectedProjectId(this.stateService);
        const resourceRequests$ = projectId ? this.store.select(getEntireResourceRequestsProjectContextStateObject(projectId))
            : this.store.select(getEntireResourceRequestsStateObject(engagementId));
        const entityDetails$ = projectId ? this.store.select(getEntireProjectDetails(projectId))
            : this.store.select(getEntireEngagementDetails(engagementId));
        observableCombineLatest(
            resourceRequests$,
            entityDetails$,
            (
                resourceRequestsState: IResourceRequestsDetailsState,
                entityDetailState: IEngagementDetailsState | IProjectDetailsState,
            ) => ({
                resourceRequestsState,
                entityDetailState,
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                resourceRequestsState,
                entityDetailState,
            }) => {
                if (resourceRequestsState.loaded && entityDetailState.loaded) {
                    const userAlias = this.sharedFunctionService.getCurrentUserInfoAsSelectedUserAttr().userAlias;
                    let isLoggedinUserPpjm = false;
                    let engagementDetails;
                    if (projectId) {
                        engagementDetails = (entityDetailState as IProjectDetailsState).projectDetails.engagementFullDetails;
                    } else {
                        engagementDetails = (entityDetailState as IEngagementDetailsState).engagementDetails;
                    }
                    isLoggedinUserPpjm = engagementDetails && engagementDetails.pPjm && engagementDetails.pPjm.alias.toLowerCase() === userAlias.toLowerCase();
                    if (isLoggedinUserPpjm) {
                        const assignmentIds: string[] = this.getAssignmentsFromGRMResponse(resourceRequestsState.grmSearchApiResponse);
                        this.pendingLaborApprovals = [];
                        this.isPendingApprovalApiLoading = true;
                        this.laborManagementService.getPendingLaborApprovalCountBasedOnAssignments(assignmentIds, engagementId, userAlias).then((data: ILaborApprovalCountBasedOnAssignments) => {
                            if (data.count) {
                                const engagementApproval: ILaborManagementApproval = {
                                    pendingLaborCount: data.count,
                                    msApprovalsLink: this.msApprovalsPendingUrl
                                };
                                this.pendingLaborApprovals.push(engagementApproval);
                            }
                            this.isPendingApprovalApiLoading = false;
                        }).catch((error) => {
                            this.isPendingApprovalApiLoading = false;
                            const errorMessage = this.sharedFunctionService.getErrorMessage(error, "");
                            this.logError(SourceConstants.Method.GetPendingLaborApprovalCountBasedOnAssignments, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        }).finally(() => {
                            this.markActivityForCompletion(KeyAction.PendingLaborBasedOnAssignments);
                        });
                    }
                }
                if (resourceRequestsState.error && entityDetailState.error) {
                    this.isServerError = true;
                    this.markActivityForCompletion(KeyAction.PendingLaborBasedOnAssignments);
                }
            });
    }


    /**
     * Gets the Milestone details and calculates the due date and indicates in key actions
     * Used as part of the Key Actions Mapping.
     */
    private loadPendingMilestonesForConfirmation(): void {
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const projectId: string = this.sharedFunctionService.getSelectedProjectId(this.stateService);
        this.milestoneRoute = projectId ? RouteName.ProjectMilestones : RouteName.EngagementMilestones;
        const milestoneDetails$ = this.store.select(getEntireMilestones(engagementId));
        const entityDetails$ = projectId ? this.store.select(getEntireProjectDetails(projectId))
            : this.store.select(getEntireEngagementDetails(engagementId));
        observableCombineLatest(
            milestoneDetails$,
            entityDetails$,
            (
                milestoneDetailsState: IMilestonesState,
                entityDetailState: IEngagementDetailsState | IProjectDetailsState,
            ) => ({
                milestoneDetailsState,
                entityDetailState,
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                milestoneDetailsState,
                entityDetailState,
            }) => {
                this.isMilestonesApiLoading = milestoneDetailsState.loading && entityDetailState.loading;
                if (milestoneDetailsState.loaded && entityDetailState.loaded) {
                    this.keyActionForMilestone(projectId ? (entityDetailState as IProjectDetailsState).projectDetails.engagementFullDetails : (entityDetailState as IEngagementDetailsState).engagementDetails, milestoneDetailsState.milestones);
                    this.markActivityForCompletion(KeyAction.PendingMilestonesForConfirmation);
                }
                if (milestoneDetailsState.error && entityDetailState.error) {
                    this.isServerError = true;
                    this.markActivityForCompletion(KeyAction.PendingMilestonesForConfirmation);
                }
            });
    }

    /**
     * Load RecieptDue Key Actions
     * Used as part of the Key Actions Mapping.
     */
    private loadRecieptsDueKeyActions(): void {
        const engagementId: string = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const projectId: string = this.sharedFunctionService.getSelectedProjectId(this.stateService);
        const manageSuppliers$ = this.store.select(getEntireManageSuppliers(engagementId));
        manageSuppliers$.pipe(untilDestroyed(this)).subscribe((manageSuppliersState: IManageSuppliersState) => {
            this.isManageSuppliersApiLoading = manageSuppliersState.loading;
            if (manageSuppliersState.loaded) {
                const validPurchaseOrders = this.returnCountOfEditableGR(manageSuppliersState.manageSuppliers);
                this.loadDueDateKeyActions(validPurchaseOrders, engagementId, projectId);
                this.markActivityForCompletion(KeyAction.RecieptsDue);
            }
            if (manageSuppliersState.error) {
                this.isServerError = true;
                this.markActivityForCompletion(KeyAction.RecieptsDue);
            }
        });
    }

    /**
     * Creates due date key action items adds them to the key actions list for the view.
     * Will only do this if the context is not Portfolio, user has purchase orders for the parent engagement, and
     * there are receipts due for the key actions.
     * @param purchaseOrderCount
     */
    private loadDueDateKeyActions(purchaseOrderCount: number, engagementId: string, projectId: string): void {
        if (purchaseOrderCount > 0 && !this.receiptsDueKeyActions.filter((item: IKeyActionsModel) => item.description === "Receipts Due").length
        ) {
            let invoicesLink: string;
            if (projectId) {
                invoicesLink = `#/pjm/project/${projectId}/managesuppliers`;
            } else {
                invoicesLink = `#/pjm/engagement/${engagementId}/managesuppliers`;
            }
            this.receiptsDueKeyActions.push(this.manageSuppliersService.getGoodsReceiptDueDays(invoicesLink));
        }
    }

    /**
     * Gets the list of pending expense reports by calling the Concur Service.
     * @param queryString
     */
    private getPendingExpenseReports(queryString: string): Promise<IKeyActionsExpenseReports[]> {
        return this.concurService.getPendingReportsforApproval(queryString)
            .then((response: IConcurPendingReportsResponse[]) => {
                if (response && response.length) {
                    return [
                        {
                            description: "Expense Reports Awaiting Approvals",
                            concurLink: this.concurUrl,
                            pendingCount: response.length
                        }
                    ];
                } else {
                    return [];
                }
            }).catch((error) => {
                const errorMessage = this.sharedFunctionService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.GetPendingExpenseReports, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.resolve([]);
            });
    }

    /**
     * Gets the list of pending expense reports by calling the Concur Service for Portfolio context
     * Used as part of the Key Actions Mapping.
     * @param queryString
     */
    private getPendingExpenseReportsPortfolioContext(): void {
        /* Get Pending Expense Reports */
        this.isExpenseReportsApiLoading = true;
        const queryStringForExpenseReports = "employeeId=" + this.fxpUserInfoService.getCurrentUserData().personnelNumber.toString();
        this.getPendingExpenseReports(queryStringForExpenseReports).then((expenseReportResponse: IKeyActionsExpenseReports[]) => {
            this.expenseKeyActions = expenseReportResponse;
            this.isExpenseReportsApiLoading = false;
        }).finally(() => {
            this.markActivityForCompletion(KeyAction.PendingExpenseReportsPortfolioContext);
        });
    }

    /**
     * Returns the pending approval for logged in user
     * Used as part of the Key Actions Mapping.
     */
    private getPendingApprovalCountForLoggedInUser(): void {
        this.isPendingApprovalApiLoading = true;
        this.getPendingApprovalCountByDateForApprover().then((results: ILaborManagementApproval[]) => {
            this.pendingLaborApprovals = results;
            this.isPendingApprovalApiLoading = false;
        }).finally(() => {
            this.markActivityForCompletion(KeyAction.PendingLaborApproval);
        });
    }


    /**
     * Returns the pending labor count per required approval date for a given assigned or delegated approver.
     * @param approverAlias
     */
    private getPendingApprovalCountByDateForApprover(): Promise<ILaborManagementApproval[]> {
        const userAlias = this.sharedFunctionService.getCurrentUserInfoAsSelectedUserAttr().userAlias;
        return this.laborManagementService.getPendingApprovalCountByDateForApprover(userAlias)
            .then((data: ILaborManagementApprovalResponse) => {
                if (data && data.response.length) {
                    return data.response.map((item) => {
                        // add msApprovalsLink to API response
                        const daysUntilDue = moment(item.requiredApprovalDate).diff(moment().startOf("day"), "days");

                        return {
                            ...item,
                            daysUntilDue,
                            daysUntilDueMessage: this.getDaysDueMessage(daysUntilDue),
                            msApprovalsLink: this.msApprovalsPendingUrl
                        };
                    });
                } else {
                    return [];
                }
            }).catch((error) => {
                const errorMessage = this.sharedFunctionService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.GetPendingApprovalCountByDateForApprover, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return [];
            });
    }

    /**
     * Creates a query string for the Concur Service based on the context tile Key Actions is being called from.
     * @param expenseIds
     */
    private getQueryStringBasedOnTile(expenseIds: string[]): string {
        let queryString = "employeeId=" + this.fxpUserInfoService.getCurrentUserData().personnelNumber.toString();
        if (expenseIds && expenseIds.length) {
            for (const expenseId of expenseIds) {
                queryString = queryString + "&expenseWbsId=" + expenseId;
            }
        }
        return queryString;
    }

    /**
     * Sets information about the pending resources by calling the GRM API to get resource details.
     * This function appears unused but is part of the key actions mapping. Do not delete.
     */
    private getPendingResources(): void {
        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
        this.storeDispatchService
            .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
            .load();
        myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
            if (engagementList.loaded && engagementList.engagementList.length) {
                this.isPendingResourcesApiLoading = true;
                this.staffingService.getGRMPendingResourceDetails().then((response: IPendingResourceDetails) => {
                    if (response) {
                        if (response.ResourceProposals && response.ResourceProposals.length) {
                            const resourceProposals = response.ResourceProposals;
                            this.resourceProposalCount = resourceProposals.length;
                            this.resourceDueHours = Math.min(...resourceProposals.map((x) => x.TimeRemaininginHours)).toString(); /* we set it as a string because 0 is a valid response, but 0 would fail a truthiness check as a number*/
                        }

                        // set resources with Returned status
                        if (response.ReturnedRequestsInfo && response.ReturnedRequestsInfo.length > 0) {
                            this.hasReturnedResource = true;
                            this.totalReturnedRequests = response.ReturnedRequestsInfo.length;
                            this.returnedRequestList = response.ReturnedRequestsInfo;
                        }
                    }
                    this.isPendingResourcesApiLoading = false;
                }).catch((error) => {
                    this.isPendingResourcesApiLoading = false;
                    const errorMessage = this.sharedFunctionService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.GetPendingResources, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                }).finally(() => {
                    this.markActivityForCompletion(KeyAction.PendingResources);
                });
            }
        });
    }

    /**
     * Calculates milestone day diff and toggles due date over flag by checking and comparing the milestones list
     * for due dates.
     */
    private keyActionForMilestone(engagementDetail: IEngagementDetailsApiV2, milestoneData: IBillingMilestoneModel[]): void {
        if (milestoneData && milestoneData.length) {
            /* return true if logged in user is the Primary project manager of engagement */
            this.isPrimaryProjectManager = this.dmAuthorizationService.isLoggedinUserPpjm(engagementDetail);
            const todayDate = new Date();
            todayDate.setDate(todayDate.getDate() + 5);
            const milestoneList = milestoneData.filter((milestone: IBillingMilestoneModel) =>
                moment(milestone.dueDate).isSameOrBefore(todayDate) && milestone.status === "Pending");
            const dayDiffArray: number[] = [];
            milestoneList.forEach((milestone: IBillingMilestoneModel) => {
                const daysDiff = moment(milestone.dueDate).diff(moment(), "days");
                dayDiffArray.push(daysDiff);
            });
            if (dayDiffArray.length > 0) {
                this.milestoneDayDiff = Math.min(...dayDiffArray);
            }
            this.totalUpcomingMileStone = milestoneList.length;
            if (this.milestoneDayDiff < 0) {
                this.isDueDateOver = true;
            }
        } else {
            this.milestoneDayDiff = 0;
        }
    }

    /**
     * Check if the logged in User has editable GR
     */
    private returnCountOfEditableGR(purchaseOrderDetails: IPurchaseOrder[]): number {
        let countOfEditableGr = 0;
        const loggedInUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        if (purchaseOrderDetails && purchaseOrderDetails.length) {
            purchaseOrderDetails.forEach((po) => {
                if (po.lineItems && po.lineItems.length) {
                    po.lineItems.forEach((lineItem) => {
                        if (lineItem.wbsElements && lineItem.wbsElements.length
                            && lineItem.wbsElements.filter((wbselement) => Number(wbselement.wbsL1_Pjm_BpID) === loggedInUserBPID).length > 0
                            && (lineItem.type === "MAT" || lineItem.type === "FFS")
                            && lineItem.quantity > 0
                            && (lineItem.quantity !== lineItem.invoicedQuantity ||
                                lineItem.quantity !== lineItem.invoicedQuantity + lineItem.parkQuantity)) {
                            countOfEditableGr = countOfEditableGr + 1;
                        }
                    });
                }
            });
        }
        return countOfEditableGr;
    }

    /**
     * Gets the assignments from GRM Response
     * @param grmRequestList
     */
    private getAssignmentsFromGRMResponse(grmRequestList: IResourceRequestResponse, projectId?: string): string[] {
        const assignmentIds: string[] = [];
        if (grmRequestList.ProjectRequests && grmRequestList.ProjectRequests.length > 0) {
            for (const projectRequest of grmRequestList.ProjectRequests) {
                if (projectRequest.ResourceRequests && projectRequest.ResourceRequests.length > 0) {
                    for (const resourceRequest of projectRequest.ResourceRequests) {
                        if (pendingApprovalStatus.indexOf(resourceRequest.ResourceRequestStatusEnum) > -1 && !projectId) {
                            assignmentIds.push(resourceRequest.ResourceRequestId.toString());
                        } else if (projectId && projectRequest.DemandSourceId && projectRequest.DemandSourceId.substring(0, 19) === projectId && pendingApprovalStatus.indexOf(resourceRequest.ResourceRequestStatusEnum) > -1) {
                            assignmentIds.push(resourceRequest.ResourceRequestId.toString());
                        }
                    }
                }
            }
        }
        return assignmentIds;
    }

    /**
     * Gets the customer engagements.
     * Creates key actions list for the customer engagements which are in created state and
     * the current user is primary project manager for the engagement.
     */
    private getPendingEngagementsForRelease(): void {
        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);

        this.storeDispatchService
            .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
            .load();
        myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
            this.isPortfolioApiLoading = engagementList.loading;
            if (engagementList.loaded) {
                this.engagementList = engagementList.engagementList.filter((x: IEngagementList) => x.type.toLowerCase() === "engagement");
                for (const engagement of this.engagementList) {
                    if (engagement.ebsState.toLowerCase() === "created" && engagement.pPjMAlias.toLowerCase() === this.sharedFunctionService.getCurrentUserInfoAsSelectedUserAttr().userAlias.toLowerCase()) {
                        this.engagementKeyActions.push({
                            engagementId: engagement.engagementId,
                            daysDue: moment().startOf("day").diff(moment(engagement.startDate).subtract(14, "days").toDate(), "days")
                        });
                    }
                }
                this.markActivityForCompletion(KeyAction.PendingEngagementsForRelease);
            }
            if (engagementList.error) {
                this.isServerError = true;
                this.markActivityForCompletion(KeyAction.PendingEngagementsForRelease);
            }
        });
    }

    /**
     * Key Actions has multiple actions that execute and the completion should be tracked only once all key actions are completed
     * This maintains a dictionary of all the actions initialised in this component load and tracks individual completion
     * @param activity completed activity
     */
    private markActivityForCompletion(activity: string): void {
        const index = this.keyActionActivities.findIndex((e) => e.actionName === activity, 0);
        if (index > -1) {
            this.keyActionActivities.splice(index, 1);
        }
        if (this.keyActionActivities.length === 0) {
            this.endComponentLoad();
        }
    }

}
