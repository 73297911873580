<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="tileMinHeight" [isV2Tile]="true" [hasNonOverflowContent]="true">
    <div class="dm-flex-horizontal-align-center" additionalActionsRight>
        <a href="https://aka.ms/pjm-job-aid/manage-wbs-2" target="_blank" (click)="linkClicked(LogEventConstants.LearnMoreManageEBS)" aria-label="Learn more about Manage EBS, open link in new tab"
            class="dm-link dm-flex-horizontal-align-center dm-p-l-5" role="link" title="Learn more about Manage EBS">
            <span class="icon icon-education"></span><span class="dm-p-l-5">Learn More</span></a>
    </div>
    <dm-loading loader *ngIf="isComponentLoading && !isServerError" [loadingText]="'Team Structure'"
        [showLoading]="showLoading">
    </dm-loading>
    <section *ngIf="!isComponentLoading && !isServerError" class="engagement-details">
        <div class="team-structure-grid" *ngIf="!isProjectContext">
            <ng-container *ngTemplateOutlet="teamStructure; context: ppjmTeamData"></ng-container>
            <ng-container *ngTemplateOutlet="teamStructure; context: dmmTeamData"></ng-container>
            <div class="image-grid">
                <span class="icon icon-people"></span>
            </div>
            <div *ngIf="additionalPpjmPersonDetails && additionalPpjmPersonDetails.length">
                <div class="dm-p-b-5 grid-header">
                    Additional Primary Project Managers
                </div>
                <div class="team-structure-grid__additionalPpjms">
                    <span *ngFor="let person of additionalPpjmPersonDetails; let personIndex = index"
                        class="dm-p-r-5 additional-ppjm-person-card">
                        <dm-person-card [personDetails]="person" [personImage]="additionalPpjmSlicedContacts[personIndex]?.image" [avatarOnly]="true"></dm-person-card>
                    </span>
                    <span *ngIf="additionalPpjmContacts.length > 5" class="team-structure-grid__additionalPpjms-number"
                        (click)="openTeamStructureModal()">+{{additionalPpjmContacts.length - additionalPpjmSlicedContacts.length}}</span>
                </div>

            </div>
            <ng-container *ngTemplateOutlet="teamStructure; context: relmanTeamData">
            </ng-container>
        </div>
        <div class="team-structure-grid" *ngIf="isProjectContext">
            <ng-container *ngTemplateOutlet="teamStructure; context: pjmTeamData"></ng-container>
            <ng-container *ngTemplateOutlet="teamStructure; context: dmmTeamData"></ng-container>
            <div class="image-grid">
                <span class="icon icon-people"></span>
            </div>
            <div *ngIf="additionalPjmPersonDetails && additionalPjmPersonDetails.length">
                <div class="dm-p-b-5 grid-header">
                    Additional Project Managers
                </div>
                <div class="display-inline-flex-imp">
                    <span *ngFor="let person of additionalPjmPersonDetails; let personIndex = index"
                        class="dm-p-r-5 additional-ppjm-person-card">
                        <dm-person-card [personDetails]="person" [personImage]="additionalPjmSlicedContacts[personIndex]?.image" [avatarOnly]="true"></dm-person-card>
                    </span>
                </div>
                <span *ngIf="additionalPjmContacts.length > 5" class="additonalPpjms"
                    (click)="openTeamStructureModal()">+{{additionalPjmContacts.length - additionalPjmSlicedContacts.length}}</span>
            </div>
            <div *ngIf="unitSubmitterPersonDetails && unitSubmitterPersonDetails.length">
                <div class="dm-p-b-5 grid-header">
                    Unit Submitters
                </div>
                <div class="display-inline-flex-imp">
                    <span *ngFor="let person of unitSubmitterPersonDetails; let personIndex = index"
                        class="dm-p-r-5 additional-ppjm-person-card">
                        <dm-person-card [personDetails]="person" [personImage]="unitSubmitterSlicedContacts[personIndex]?.image" [avatarOnly]="true"></dm-person-card>
                    </span>
                </div>
                <span *ngIf="unitSubmitterContacts.length > 5" class="additonalPpjms"
                    (click)="openTeamStructureModal()">+{{unitSubmitterContacts.length - unitSubmitterSlicedContacts.length}}</span>
            </div>
        </div>
    </section>
    <ng-container tileFooter *ngIf="!isComponentLoading && !isServerError">
        <button type="button" class="dm-btn" (click)="editTeamStructure()" aria-label="Edit Team"
            *ngIf="(engagementDataV2?.canEditEnagagement && !isProjectContext) || (isProjectContext && projectFullDetails?.canEditProject)">
            <span class="dm-btn--icon icon icon-editLegacy"></span>
            <span class="dm-btn--text">Edit Team</span>
        </button>
        <button type="button" class="dm-btn dm-m-imp-0" (click)="openTeamStructureModal()" aria-label="View Team">
            <span class="dm-btn--icon icon-full icon-people"></span>
            <span class="dm-btn--text">View Team</span>
        </button>
        <!--TODO: To be added later-->
        <!-- <button type="button" class="dm-btn dm-m-imp-0" (click)="createTeamStructure()" aria-label="Create in Teams"
            *ngIf="engagementDataV2.canEditEnagagement && !isProjectContext">
            <span class="dm-btn--icon icon-full icon-teams"></span>
            <span class="dm-btn--text">Create in Teams</span>
        </button> -->
    </ng-container>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
    <ng-template #teamStructure let-teamRole="data">
        <div *ngIf="teamRole && teamRole?.alias">
            <div class="dm-p-b-5 grid-header">
                {{teamRole?.role | dmDisplayDashIfEmptyOrNull}}
            </div>
            <div class="display-flex">
                <div class="dm-p-r-10"
                    *ngFor="let person of personDetails | filter: teamRole.role ? {jobTitle : teamRole.role} : undefined;">
                    <dm-person-card [personDetails]="person" [personImage]="teamRole?.image" [avatarOnly]="true" [canChat]="loggedInUserInfo?.userAlias.toLowerCase() !== teamRole?.alias.toLowerCase()"></dm-person-card>
                </div>
                <div *ngIf="teamRole?.alias">
                    <div class="font-caption-alt">{{teamRole?.name | dmDisplayDashIfEmptyOrNull}}</div>
                    <div class="icon-container">
                        <a class="icon-full icon-chat set-focus"
                            *ngIf="loggedInUserInfo?.userAlias.toLowerCase() !== teamRole?.alias.toLowerCase()"
                            [href]="'https://teams.microsoft.com/l/chat/0/0?users='+teamRole?.alias+'@microsoft.com'"
                            target="_blank" title="Chat" (click)="linkClicked(LogEventConstants.ChatIconClicked)" [attr.aria-label]="'Chat with' + teamRole?.alia + '@microsoft.com'"></a>
                        <a class="icon-full icon-email set-focus" [href]="'mailto:' + teamRole?.alias + '@microsoft.com'"
                            title="Mail" (click)="linkClicked(LogEventConstants.MailIconClicked)" [attr.aria-label]="'Mail to' + teamRole?.alias + '@microsoft.com'"></a>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</dm-tile>