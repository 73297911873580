<dm-tile class="col-md-12 col-sm-12 col-lg-12 dm-p-10" [tileHeaderContent]="tileContent"
    [tileMinHeight]="'calc(100vh - 125px)'">
    <button additionalActionsRight class="font-caption-alt dm-tile__right-link dm-transparent-btn"
        (click)="navigateToPreviousScreen()" *ngIf="!isServerError">
        <span class="icon icon-chevron-left"></span>
        Back
    </button>
    <dm-loading loader [loadingText]="loadingMessage" [isCustomText]="true" [showLoading]="showLoading"
        *ngIf="isComponentLoading && !isServerError">
    </dm-loading>

    <section *ngIf="!isComponentLoading && !isServerError">
        <!-- <toggle-section *ngIf="fcrFinancialSummary" [title]="'Financial Summary'"
            [showSection]="showFinancialSummarySection">
            <dm-engagement-financial-plan-summary [engagementFinancialSummary]="fcrFinancialSummary"
                [engagementFinancialSummaryError]="fcrFinancialSummaryError"></dm-engagement-financial-plan-summary>
        </toggle-section> -->

        <section [style.display]="isFixedFee ? 'block' : 'none'">
            <toggle-section [title]="'TPH / Risk Reserve Details'" [showSection]="showRiskReserveDetailsSection">
                <dm-tph-rr-details class="col-md-12 dm-p-imp-20" [tphRrSummary]="tphRrSummary">
                </dm-tph-rr-details>
            </toggle-section>
        </section>

        <section [style.display]="isFixedFee ? 'block' : 'none'">
            <toggle-section *ngIf="costSummary && costSummary.length" [title]="'Cost Summary'"
                [showSection]="showCostSummarySection">
                <dm-cost-summary [costSummaryData]="costSummary"></dm-cost-summary>
            </toggle-section>
        </section>

        <section [style.display]="isFixedFee ? 'block' : 'none'">
            <toggle-section *ngIf="hoursSummary && hoursSummary.length" [title]="'Hours Summary'"
                [showSection]="showHoursSummarySection">
                <dm-hours-summary [hoursSummaryData]="hoursSummary"></dm-hours-summary>
            </toggle-section>
        </section>

        <toggle-section *ngIf="!isNewFcrRequest" [title]="'Financial Change Request Details'"
            [showSection]="showFvrDetailsSection">
            <dm-fcr-details [fcrDetails]="fcrDetails" [crId]="changeRequestId" [fcrAttachmentLink]="fcrAttachmentLink"
                [approversStatusData]="crApproversStatusData" [approversStatusError]="crApproversStatusError"
                [engagementDomain]="engagementDetails?.primaryDomain">
            </dm-fcr-details>
        </toggle-section>

        <section class="actions-footer-section" *ngIf="!isNewFcrRequest">
            <button *ngIf="allowCrCancellation" type="button"
                class="fxp-btn fxp-btn--secondary fcr-form--text pull-right fcr-main-btn" (click)="cancelCr()"
                title="Cancel the Financial Change Request">
                Discard Request
            </button>
            <button class="fxp-btn fxp-btn--secondary fcr-form--text pull-right fcr-main-btn" id="back" type="button"
                title="Navigate back to previous screen" (click)="navigateToPreviousScreen()">
                Back
            </button>
        </section>

        <!-- Form section -->
        <form [formGroup]="fcrForm" *ngIf="isNewFcrRequest">
            <toggle-section [title]="'Financial Change Request Details'" [showSection]="showFvrDetailsSection">
                <section class="col-md-12 pad-0 dm-p-y-imp-10 dm-p-x-imp-0">
                    <section formGroupName="fcrDetails">
                        <section class="col-md-12 pad-0 dm-p-y-imp-10 dm-p-x-imp-0">
                            <section class="col-md-6 pad-0 pad-right-6 fcr-form--text">
                                <label class="font-caption-alt" for="title">
                                    Title
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <input class="col-md-12 dm-input"
                                    [ngClass]="{'inp-error-border' :  titleControl.invalid && (titleControl.touched || titleControl.dirty)}"
                                    type="text" id="title" name="title" formControlName="title" aria-required="true" required aria-invalid="false">
                                <div class="row">
                                    <span role="alert"
                                        class="font-caption-alt inp-validation error-msg col-sm-6 col-md-6 col-xs-5 p-t-4"
                                        aria-live="polite"
                                        *ngIf="titleControl.invalid && (titleControl.touched || titleControl.dirty)">
                                        {{dmFcrErrorMessages.TitleIsRequired}}
                                    </span>
                                </div>
                            </section>
                            <section class="col-md-6 pad-0 pad-left-6 fcr-form--text">
                                <label class="font-caption-alt" for="reason">
                                    Reason
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <section class="input__select--icon">
                                    <select class="input__select input__select--big font-caption-alt"
                                        aria-label="Reason Options" placeholder="Select Reason"
                                        formControlName="reason" aria-required="true">
                                        <option *ngFor="let reason of reasonOptions" [ngValue]="reason">
                                            {{ reason.text }}
                                        </option>
                                    </select>
                                    <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                                </section>
                                <div class="row">
                                    <span role="alert"
                                        class="font-caption-alt inp-validation error-msg col-sm-6 col-md-6 col-xs-5 p-t-4 dm-p-l-imp-0"
                                        aria-live="polite"
                                        *ngIf="reasonControl.invalid && (reasonControl.touched || reasonControl.dirty)">
                                        {{dmFcrErrorMessages.ReasonIsRequired}}
                                    </span>
                                </div>
                            </section>
                        </section>
                        <section class="col-md-12 pad-0 dm-p-y-imp-10 dm-p-x-imp-0">
                            <section class="col-md-6 pad-0 pad-right-6 fcr-form--text">
                                <label class="font-caption-alt" for="executiveSummary">
                                    Executive Summary/Business Justification
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <textarea class="col-md-12 dm-textarea dm-p-x-0-y-5 fcr-textarea" aria-describedby="executive-summary-remaining-text-desc" id="executiveSummary"
                                    name="executiveSummary" formControlName="executiveSummary" aria-required="true"></textarea>
                                <div class="row">
                                    <span id="executive-summary-remaining-text-desc" role="alert" aria-live="polite" class="remaining-char font-caption col-sm-12" aria-hidden="true">
                                        {{250 -
                                        executiveSummaryControl.value.length}}{{dmFcrErrorMessages.ExecutiveSummaryTotalCharactersRemaining}}
                                    </span>
                                    <span role="alert"
                                        class="font-caption-alt inp-validation error-msg col-sm-6 col-md-6 col-xs-5 p-t-4"
                                        aria-live="polite"
                                        *ngIf="executiveSummaryControl.invalid && (executiveSummaryControl.touched || executiveSummaryControl.dirty) && executiveSummaryControl.errors?.required">
                                        {{dmFcrErrorMessages.ExecutiveSummaryRequired}}
                                    </span>
                                    <span role="alert"
                                        class="font-caption-alt inp-validation error-msg col-sm-7 col-md-7 col-xs-6 p-t-4"
                                        aria-live="polite"
                                        *ngIf="executiveSummaryControl.invalid && (executiveSummaryControl.touched || executiveSummaryControl.dirty) && executiveSummaryControl.errors?.maxlength">
                                        {{dmFcrErrorMessages.ExecutiveSummaryMaxLength}}
                                    </span>
                                </div>
                            </section>
                            <section class="col-md-6 pad-0 pad-left-6 fcr-form--text">
                                <label class="font-caption-alt" for="comments">
                                    Comments                                    
                                </label>
                                <textarea class="col-md-12 dm-textarea dm-p-x-0-y-5 fcr-textarea"
                                    id="comments" name="comments" aria-describedby="comment-remaining-text-desc"
                                    formControlName="comments" aria-required="false"></textarea>
                                <div class="row">
                                    <span id="comment-remaining-text-desc" role="alert" aria-live="polite" class="remaining-char font-caption col-sm-12" aria-hidden="true">
                                        {{255 -
                                        commentsControl.value.length}}{{dmFcrErrorMessages.CommentsTotalCharactersRemaining}}
                                    </span>
                                    <span role="alert"
                                        class="font-caption-alt inp-validation error-msg col-sm-7 col-md-7 col-xs-6 p-t-4"
                                        aria-live="polite"
                                        *ngIf="commentsControl.invalid && (commentsControl.touched || commentsControl.dirty) && commentsControl.errors?.maxlength">
                                        {{dmFcrErrorMessages.CommentsMaxLength}}
                                    </span>
                                </div>
                            </section>
                        </section>
                        <!-- <section class="col-md-12 pad-0 dm-p-y-imp-10 dm-p-x-imp-0">
                            <section class="col-md-6 pad-0 pad-right-6 fcr-form--text">
                                <label class="font-caption-alt" for="risks">
                                    Add Related Risks
                                </label>
                                <section class="input__select--icon">
                                    <ng-select class="dm-multi-select font-caption-alt" [items]="virtuosoRisks" aria-owns="risks-accessibility-fix-option"
                                        [multiple]="true" [closeOnSelect]="false" [searchable]="false" [inputAttrs]="{'aria-label':'Add Related Risks', 'autocomplete':'off'}" bindLabel="title"
                                        placeholder="Select Risks" name="risks" formControlName="risks" aria-label="risks">
                                    </ng-select>
                                    <option hidden tabindex="-1" id="risks-accessibility-fix-option">Select</option>
                                </section>
                            </section>
                            <section class="col-md-6 pad-0 pad-left-6 fcr-form--text">
                                <label class="font-caption-alt" for="issues">
                                    Add Related Issues
                                </label>
                                <section class="input__select--icon">
                                    <ng-select class="dm-multi-select font-caption-alt" [items]="virtuosoIssues" aria-owns="issues-accessibility-fix-option"
                                        [multiple]="true" [closeOnSelect]="false" [searchable]="false" [inputAttrs]="{'aria-label':'Add Related Issues', 'autocomplete':'off'}" bindLabel="title"
                                        placeholder="Select Issues" name="issues" formControlName="issues" aria-label="issues">
                                    </ng-select>
                                    <option hidden tabindex="-1" id="issues-accessibility-fix-option">Select</option>
                                </section>
                            </section>
                        </section> -->
                        <section class="col-md-12 pad-0 dm-p-y-imp-10 dm-p-x-imp-0">
                            <section class="col-md-4 pad-0 fcr-form--text">
                                <label class="font-caption" for="attachmentFileName">
                                    Attach File
                                    <span class="sr-only">press tab or down arrow to navigate to info icon</span>
                                    <div class="custom-tooltip icon icon-info" [attr.aria-label]="'Attach File Info Supported File Formats:' + fileFormatsAllowed" role="button" tabindex="0">
                                        <div class="custom-tooltiptext font-caption-alt" placement="top">
                                            Supported File Formats: {{ fileFormatsAllowed }}
                                        </div>
                                    </div>
                                    <!-- <span class="font-label">({{fileFormatsAllowed}})</span> -->
                                </label>
                                <section>
                                    <input tabindex="-1" class="font-caption-alt dm-input" type="text" id="attachmentFileName"
                                        name="attachmentFileName" formControlName="attachmentFileName"
                                        placeholder="Choose File" readonly>
                                    <input class="hide-element" id="attachmentId" name="attachmentId" type="file"
                                        (change)="uploadFcrFileBtnClick($event)" #fcrFileUploadField
                                        [accept]="fileFormatsAllowed" />
                                </section>
                            </section>
                            <section class="col-md-2 pad-0 pad-right-6 fcr-form--text">
                            <p role="status" class="sr-only" aria-atomic="true">
                                    <span id="uploadedFileMsg"></span>
                                </p>
                                <button type="button" class="fxp-btn fxp-btn--primary browse-btn" aria-label="Choose File Browse"
                                    (click)="uploadFcrFileBtnClicked()">
                                    Browse
                                </button>
                            </section>
                            <!-- <section class="col-md-6 pad-0 pad-left-6 fcr-form--text">
                                <label class="font-caption-alt" for="comments">
                                    Comments
                                </label>
                                <textarea class="col-md-12 dm-textarea dm-p-x-0-y-5 fcr-textarea" aria-describedby="comment-remaining-text-desc" id="comments"
                                    name="comments" formControlName="comments"></textarea>
                                <div class="row">
                                    <span id="comment-remaining-text-desc" role="alert" aria-live="polite" class="remaining-char font-caption col-sm-12" aria-hidden="true">
                                        {{255 -
                                        commentsControl.value.length}}{{dmFcrErrorMessages.CommentsTotalCharactersRemaining}}
                                    </span>
                                    <span role="alert"
                                        class="font-caption-alt inp-validation error-msg col-sm-7 col-md-7 col-xs-6 p-t-4"
                                        aria-live="polite"
                                        *ngIf="commentsControl.invalid && (commentsControl.touched || commentsControl.dirty) && commentsControl.errors?.maxlength">
                                        {{dmFcrErrorMessages.CommentsMaxLength}}
                                    </span>
                                </div>
                            </section> -->
                        </section>
                        <section class="col-md-12 pad-0 dm-p-y-imp-10">
                            <label *ngIf="crApprovers && crApprovers.length" class="font-caption-alt approver-title">
                                Approvers for Request {{engagementDetails && engagementDetails.primaryDomain ?
                                "(Engagement Primary Domain: " + engagementDetails.primaryDomain + ")" : ""}}
                            </label>
                        </section>
                        <section class="col-md-12 pad-0">
                            <section class="approver-section pad-top-12">
                                <section *ngFor="let crApprover of crApprovers; let i = index;"
                                    class="approver-section__item">
                                    <label class="font-caption-alt" for="{{'approver' + (i + 1)}}">
                                        Approver {{i + 1}} ({{crApprover?.persona}})
                                        <span class="required-mark" aria-hidden="true">*</span>
                                    </label>
                                    <div *ngIf="crApprover.approverList.length > 1; else approverLabel"
                                        class="input__select--icon">
                                        <select id="{{'approver' + (i + 1)}}" name="{{'approver' + (i + 1)}}"
                                            [formControl]="approversFormArray.controls[i]"
                                            class="col-md-12 input__select input__select--big font-caption-alt"
                                            required>
                                            <option *ngFor="let option of crApprover.approverList" [ngValue]="option">
                                                {{option.UserName + " (" + option.UserAlias + ")"}}</option>
                                        </select>
                                        <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                                    </div>
                                    <ng-template #approverLabel>
                                        <p *ngIf="crApprover.approverList[0]" id="{{'approver' + (i + 1)}}"
                                            class="approver-label font-caption-alt">
                                            {{crApprover.approverList[0].UserName + " (" +
                                            crApprover.approverList[0].UserAlias + ")"}}
                                        </p>
                                    </ng-template>
                                </section>
                            </section>
                            <section *ngIf="showNoApproversError">
                                <p role="alert" class="font-caption-alt inp-validation error-msg dm-p-y-imp-10"
                                    aria-atomic="true" aria-live="polite">{{ noApproversErrorMessage }}
                                    <span class="pull-right">
                                        <a [href]="virtuosoLink" target="_blank" aria-label="Open in Virtuoso. open link in new tab"
                                            class="dm-link font-caption-alt dm-m-x-5-y-0">
                                            <i class="icon icon-link"></i> Open in Virtuoso
                                        </a>
                                        <button type="button" class="dm-link--button font-caption-alt dm-m-x-5-y-0"
                                            id="refreshApprovers" aria-label="Refresh Approvers"
                                            (click)="getVirtuosoApproverDataV2(engagementId, true)">
                                            <i class="icon icon-refresh"></i>
                                            Refresh Approvers
                                        </button>
                                    </span>
                                </p>
                            </section>
                            <section *ngIf="showNoPpjmError">
                                <p role="alert" class="font-caption-alt inp-validation error-msg dm-p-y-imp-10"
                                    aria-atomic="true" aria-live="polite">{{ dmFcrErrorMessages.MissingPpjm }}
                                    <span class="pull-right">
                                        <button type="button" class="dm-link--button font-caption-alt dm-m-x-5-y-0"
                                            id="refreshApprovers" aria-label="Refresh Approvers"
                                            (click)="getVirtuosoApproverDataV2(engagementId, true)">
                                            <i class="icon icon-refresh"></i>
                                            Refresh Approvers
                                        </button>
                                    </span>
                                </p>
                            </section>
                        </section>
                    </section>
                </section>
            </toggle-section>


            <toggle-section [title]="'Requested Roles'" [showSection]="showFvrRolesSection">
                <div rightSideContent class="dm-p-x-10">
                    <span>Total Additional Cost
                        ({{tphRrSummary?.planCurrency}})</span>
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="totalsContent" [dmIcon]="'totals-info-icon icon icon-info'"
                        ariaLabelToolTip="Status Information">
                    </dm-tooltip> :
                    <span>{{totalAdditionalCost | number : '1.2-2'}}</span>
                </div>

                <div class="dm-nav__tabs dm-p-t-10-l-10" role="tablist" aria-orientation="horizontal">
                    <div *ngFor="let tab of tabsContent">
                        <span [id]="tab.id" class="set-focus" [ngClass]="{'dm-nav__active-tab' : tab?.isActive}" role="tab"
                            tabindex="0" (click)="toggleTab(tab)" (keyup.enter)="toggleTab(tab)" [attr.aria-selected]="tab?.isActive"
                            (keyup.Space)="toggleTab(tab)" (keyup.arrowright)="focusForNavBarArrowKey(tab.id, 'right')"
                            (keyup.arrowleft)="focusForNavBarArrowKey(tab.id, 'left')">{{tab?.displayName}}
                            <span class="dm-m-imp-0"
                                *ngIf="tab?.id === ResourceType.Labor">({{(requestedRolesControl.value?.length)}})</span>
                            <span class="dm-m-imp-0"
                                *ngIf="tab?.id === ResourceType.Unit">({{(requestedUnitsControl.value?.length)}})</span>
                            <span class="dm-m-imp-0"
                                *ngIf="tab?.id === ResourceType.Expense">({{(requestedExpensesControl.value?.length)}})</span>
                            <span class="dm-m-imp-0"
                                *ngIf="tab?.id === ResourceType.SubconFF">({{(requestedSubconControl?.value?.length)}})</span>
                        </span>
                    </div>
                </div>
                <section class="dm-p-imp-20" [style.display]="showLabor ? 'block' : 'none'">
                    <section formGroupName="fcrRoles">
                        <dm-fcr-roles-form-control formControlName="requestedRoles"
                            [formControlData]="fcrRolesFormControlData"
                            [formControlDataError]="fcrRolesFormControlDataError"
                            [resourceLocationAndRoleEditFeatureFlag]="resourceLocationAndRoleEditFeatureFlag"
                            (contractTypeChange)="updateRequestedContractType($event)"
                            (roleRequestSaveDeleteEvent)="roleRequestSavedOrDeleted()" [populateRoles]="populateRoles"
                            [fvrContractType]="fvrContractType" [fcrFormControlsTotalValue]="fcrFormControlsTotalValue"
                            [engagementDetails]="engagementDetails"
                            [subconCostRates]="subconCostRates">
                        </dm-fcr-roles-form-control>
                        <div *ngIf="requestedRolesControl.invalid && requestedRolesControl.errors?.additionalCostMaxValue"
                            class="pull-right">
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite">
                                {{dmFcrErrorMessages.InvalidCostErrorMessage}}
                            </p>
                        </div>
                    </section>
                </section>
                <section class="dm-p-imp-20" [style.display]="showUnits ? 'block' : 'none'">
                    <section formGroupName="fcrUnits">
                        <dm-fcr-units-form-control formControlName="requestedUnits"
                            [fcrUnitsFormControlData]="fcrUnitsFormControlData"
                            [fcrUnitsFormControlDataError]="fcrUnitsFormControlDataError"
                            (contractTypeChange)="updateRequestedContractType($event)"
                            (unitRequestSaveDeleteEvent)="roleRequestSavedOrDeleted()" [populateRoles]="populateRoles4"
                            [fvrContractType]="fvrContractType" [fcrFormControlsTotalValue]="fcrFormControlsTotalValue"
                            [engagementDetails]="engagementDetails">
                        </dm-fcr-units-form-control>
                    </section>
                </section>
                <section class="dm-p-imp-20" [style.display]="showSubconFF ? 'block' : 'none'">
                    <section formGroupName="fcrSubcon">
                        <dm-fcr-subconff-form-control formControlName="requestedSubcon"
                            [fcrSubconFormControlData]="fcrSubconFormControlData"
                            [fcrSubconFormControlDataError]="fcrSubconFormControlDataError"
                            (contractTypeChange)="updateRequestedContractType($event)"
                            (subconRequestSaveDeleteEvent)="roleRequestSavedOrDeleted()"
                            [populateRoles]="populateRoles2" [fvrContractType]="fvrContractType"
                            [fcrFormControlsTotalValue]="fcrFormControlsTotalValue"
                            [engagementDetails]="engagementDetails">
                        </dm-fcr-subconff-form-control>
                    </section>
                </section>
                <section class="dm-p-imp-20" [style.display]="showExpenses ? 'block' : 'none'">
                    <section formGroupName="fcrExpenses">
                        <dm-fcr-expenses-form-control formControlName="requestedExpenses"
                            [fcrExpensesFormControlData]="fcrExpensesFormControlData"
                            [fcrExpensesFormControlDataError]="fcrExpensesFormControlDataError"
                            (contractTypeChange)="updateRequestedContractType($event)"
                            (expenseRequestSaveDeleteEvent)="roleRequestSavedOrDeleted()"
                            [populateRoles]="populateRoles3" [fvrContractType]="fvrContractType"
                            [fcrFormControlsTotalValue]="fcrFormControlsTotalValue"
                            [engagementDetails]="engagementDetails">
                        </dm-fcr-expenses-form-control>
                    </section>
                </section>
                <div *ngIf="requestedRolesControl.invalid && requestedRolesControl.errors?.allRolesNotSaved"
                    class="pull-right">
                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                        aria-live="polite">
                        {{dmFcrErrorMessages.RolesNotSavedErrorMessage}}
                    </p>
                </div>
            </toggle-section>
            <section class="col-md-12 pad-0 dm-p-y-imp-10 dm-p-x-imp-0">
                <div class="pull-right">
                    <button class="fxp-btn fxp-btn--primary fcr-form--text fcr-main-btn set-focus" id="submit"
                    type="button" title="Click to create a new Financial Change Request and submit that for approval"
                    (click)="submitFcr()" [disabled]="!fcrForm.valid || showNoApproversError || showNoPpjmError">
                    Submit
                </button>
                <button class="fxp-btn fxp-btn--secondary fcr-form--text fcr-main-btn set-focus" id="back"
                    type="button" title="Cancel new change request" (click)="navigateToPreviousScreen()">
                    Cancel
                </button>
                </div>
            </section>
        </form>
    </section>
    <dm-service-error class="server-error" *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>

<ng-template #roleAutopopulatedTemplate let-modal>
    <div class="modal-body label-text">
        The Cost/TPH differences between Current Financial Plan & Delivery Baseline are auto-filled in Requested Roles
        section. You can edit these or add more.
        <br>
        <p style="padding-top: 10px;">Note that only saved changes in Project(s):
            {{stateService.params.projectsThatFailedThresholdCheck.join(", ")}} are considered for auto-fill.
        </p>
        <ng-container *ngIf="projectsWithPendingChangeRequests.length">
            <br>
            <p>
                Note that any changes in {{pendingCrMessage}} will not be allowed to submit since they have a pending change request.
            </p>
        </ng-container>
        <ng-container *ngIf="engagementDetails?.hasUnitBasedDemands">
            <br>
            <p>Any changes which have new unit roles which did not already exist on the deal will not be auto filled.
            </p>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus class="fxp-btn fxp-btn--primary fcr-form--text pull-right fcr-main-btn"
            (click)="modal.close()">Ok</button>
    </div>
</ng-template>

<ng-template #totalsContent>
    <div class="fcr-totals-section">
        <div class="dm-font-weight-500 justify-space-between">
            <span>Total Additional Cost ({{tphRrSummary?.planCurrency}}) </span>
            <span>{{totalAdditionalCost | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Labor</span>
            <span>{{fcrTotals?.labor | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Subcon</span>
            <span>{{fcrTotals?.subcon | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Expense</span>
            <span>{{fcrTotals?.expense | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Units</span>
            <span>{{fcrTotals?.unit | number : '1.2-2'}}</span>
        </div>
    </div>
</ng-template>