import { Component, Inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { Components } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";

@Component({
    templateUrl: "./delete-csat-contact.html",
    styleUrls: ["./delete-csat-contact.scss"]
})
export class DeleteCsatContactModalComponent extends DmModalAbstract {
    @Input() public confirmationMessage: string;
    public modalContent: IModal;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(activeModal, dmLogger, Components.DeleteCsatContactModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Delete CSAT Contact"
        };
    }

    public deleteCsatContact(): void {
        this.activeModal.close(true);
    }
}
