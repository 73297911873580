<ngb-carousel aria-live="polite" #pjmCarousel [showNavigationArrows]="false" [showNavigationIndicators]="displayNavigationIndicators()" (slide)="transitionSlide($event)">
    <ng-template ngbSlide *ngFor="let slide of slideList; first as isFirst; last as isLast; index as i" [id]="'slide-'+ (i + 1)">
        <div *ngIf="slide.nameAboveMedia" class="dm-p-b-5">
            <h3 class="text-center slide-title">{{slide.nameAboveMedia}}</h3>
        </div>
        <div *ngIf="slide.descriptionAboveMedia" class="dm-p-b-5">
            <span class="slide-description" [innerHtml]="slide.descriptionAboveMedia"></span>
        </div>
        <div *ngIf="slide.media">
            <img [src]="slide.media" class="display-block-imp margin-auto slide-image" [alt]="'slide media ' + (i+1)">
        </div>
        <div class="dm-p-b-12">
            <h3 class="text-center slide-title">{{slide.name}}</h3>
            <span class="slide-description" [innerHtml]="slide.description"></span>
        </div>
        <div>
            <button [id]="'previous-slide-' + i" class="fxp-btn fxp-btn--primary pull-left set-focus" *ngIf="!isFirst" (click)="navigateToPreviousSlide(i-1)">
                Previous
            </button>
            <button [id]="'next-slide-' + i" class="fxp-btn fxp-btn--primary pull-right set-focus" *ngIf="!isLast" (click)="navigateToNextSlide(i+1)" (keydown.Tab)="moveFocus($event, 'closeWhatsNewButton')">
                Next
            </button>
        </div>
    </ng-template>
  </ngb-carousel>