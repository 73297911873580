<div class="dm-update-contact-modal">
    <label id="updateContactModal" class="sr-only">{{csatContactModalTitle}}</label>
    <div class="modal-header modal-noborder display-flex">
        <div class="display-grid">
            <h4 class="modal-title font-subtitle pull-left">{{csatContactModalTitle}}</h4>
            <div class="info-bar">
                <span class="icon icon-info"></span>
                <span class="info-text">{{csatContactModlaInfo}}</span>
            </div>
        </div>
        <button type="button" title="Close" class="dm-transparent-btn modal-close-icon"
            aria-label="Update CSAT Contact Modal Close" (click)="closeModal()"
            id="accessibilityConstants.CloseUpdateButton"
            (keydown)="moveFocusPrev($event, 'accessibilityConstants.UpdateCSAT')">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
    </div>
    <dm-loading [isCustomText]="true" [loadingText]="loadingText" *ngIf="isComponentLoading" [showLoading]="true">
    </dm-loading>
    <ng-container  *ngIf="!isComponentLoading" >
    <div class="modal-body">
        <div class="dm-update-contact-modal__dropdown dm-m-t-10" ngbDropdown #contactTypeDropdown="ngbDropdown"
            auto-close="outsideClick">
            <button class="btn dm-update-contact-modal__dropdown-btn set-focus" id="dropdownManual1" ngbDropdownToggle
                (click)="contactTypeDropdown.open()" [disabled]="shouldDisableContactTypeSelection()">
                <span dm-update-contact-modal__dropdown-btn-label>
                    <span *ngIf="!selectedContactType">Select Customer Contact Type</span>
                    <span *ngIf="selectedContactType">{{selectedContactType?.description}}</span>
                </span>
                <span class="icon icon-chevron-down"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownManual1">
                <button ngbDropdownItem class="dm-update-contact-modal__dropdown-item dropdown-item"
                    *ngFor="let contactType of filteredCsatContactTypeList"
                    (click)="contactTypeSelected(contactType); contactTypeDropdown.close();">
                    <span><strong>{{contactType?.description}}</strong></span>
                </button>
            </div>
        </div>
        <div class="dm-update-contact-modal__dropdown dm-m-t-10" ngbDropdown #contactsDropdown="ngbDropdown"
            auto-close="outsideClick">
            <button class="btn dm-update-contact-modal__dropdown-btn set-focus" id="dropdownManual" ngbDropdownToggle
                (click)="contactsDropdown.open()" [disabled]="shouldDisableContactSelection()">
                <span dm-update-contact-modal__dropdown-btn-label>
                    <span *ngIf="!selectedContact">Select Customer Contact</span>
                    <span *ngIf="selectedContact">{{selectedContact?.name}}</span>
                </span>
                <span class="icon icon-chevron-down"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownManual">
                <button ngbDropdownItem class="dm-update-contact-modal__dropdown-item dropdown-item" *ngFor="let contact of filteredContactDetailList"
                    (click)="contactSelected(contact); contactsDropdown.close();" [attr.aria-label]="contact?.name + '' + contact?.email + '' + contact?.telephone">
                    <strong>{{contact?.name}}</strong>
                    <div aria-hidden="true">{{contact?.email}}</div>
                    <div aria-hidden="true">{{contact?.telephone}}</div>
                </button>
            </div>
        </div>
        <div>
            <span class="error-msg" *ngIf="!isComponentLoading && sameContactTypeContactAlreadySelected">{{sameContactTypeContactAlreadySelectedErrorMessage}}</span>
        </div>
        <div class="info-bar">
            <span class="icon icon-info"></span>
            <span class="info-text">Looking for a different contact? <a class="dm-link" href="javascript:void(0)"
                    (click)="openInitiateNewCSATContactRequestModal()">Initiate a new contact request.</a></span>
        </div>
        <div class="multi-select-array font-caption-alt" *ngIf="mode === getModeEnum.add">
            <button class="button-element" *ngFor="let contact of selectedContactsList; let index = index"
                (click)="removeFromSelectedContacts(contact,index)">
                <table class="contact-selection-table">
                    <tr>
                        <td class="contact-element">
                            <dm-user-name [name]="contact.contact?.name" [alias]="contact.contact?.alias"
                                [role]="contact.contact?.role" [showPersonCardWithViewAvatar]="true">
                            </dm-user-name>
                        </td>
                        <td class="contact-element">
                            <div class="contact__details contact__details-v2">
                                <table class="contact-name-table">
                                    <tr>
                                        <div class="contact__details-name">
                                            <dm-user-name [name]="contact.contact?.name"></dm-user-name>
                                        </div>
                                    </tr>
                                    <tr>
                                        <ng-container>
                                            {{contact.type?.description}}
                                        </ng-container>
                                    </tr>
                                </table>
                            </div>
                        </td>
                        <td class="contact-element">
                            <div>
                                <i class="icon icon-close"></i>
                            </div>
                        </td>
                    </tr>
                </table>
            </button>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" value="cancel" id="cancelUpdate" class="fxp-btn fxp-btn--secondary" (click)="closeModal()"
            (keydown)="moveFocusNext($event, 'accessibilityConstants.UpdateCSAT')">
            Cancel
        </button>

        <button type="button" value="Continue" id="accessibilityConstants.UpdateCSAT" class="fxp-btn fxp-btn--primary"
            [disabled]="isComponentLoading"
            (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseUpdateButton')" (click)="updateCSATContact()">
            Submit
        </button>
    </div>
</ng-container>
</div>