export class CollectionIterator<T> {
    private offset: number = 0;
    private length: number = 0;

    public constructor(private keys: T[], private batchSize: number) {
        this.keys = keys.filter((value, index, array) => array.indexOf(value) === index);
        this.length = keys.length;
    }

    public hasMoreItems(): boolean {
        return this.offset < this.length;
    }

    public getNextBatch(): T[] {
        const begin: number = this.offset;
        const end = begin + this.batchSize;
        this.offset = end;
        return this.keys.slice(begin, end);
    }
}