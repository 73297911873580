import { Component, forwardRef, Inject, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { CacheService } from "../../../../../common/services/cache.service";
import { ConcurService } from "../../../../../common/services/concur.service";
import { CosmicServiceFunctions } from "../../../../../common/services/cosmic-function.service";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { DMNotificationService, NotificationModel } from "../../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { getEntireResourceRequestsStateObject } from "../../../../../store/resource-requests/resource-requests.selector";
import { IChangedProperties } from "../../../../../common/services/contracts/dmnotification.service.contract";
import { IConcurPendingReportsResponse } from "../../../../../common/services/contracts/concur.contracts";
import { IDeliveryStateValue, IDistributionListObject, IResponseMessage, IDeliveryState } from "../../../../../common/services/contracts/wbs.service.contracts";
import { IEngagementDetails } from "../../../../../common/services/contracts/project.service.contracts";
import { ILaborApprovalCountBasedOnAssignments } from "../../../../../common/services/contracts/labor-management.contract";
import { INotificationMessages } from "../../../../../common/services/contracts/financial.service.contracts";
import { IOneProfileBulkAPIResponse } from "../../../../../common/services/contracts/one-profile.contracts";
import { ICosmicFunctionResponse } from "../../../../../common/services/contracts/cosmic-function.contracts";
import { IProjectDetailsV2, IServiceDetailsV2, ITaskDetailsV2, IEngagementDetailsV2, ITeamDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResourceRequestResponse } from "../../../../../common/services/contracts/staffing.service.contract";
import { IResourceRequestsDetailsState } from "../../../../../store/resource-requests/resource-requests.reducer";
import { IState } from "../../../../../store/reducers";
import { LaborManagementService } from "../../../../../common/services/labor-management.service";
import { LoadResourceRequests } from "../../../../../store/resource-requests/resource-requests.action";
import { LogEventConstants, NotificationEvent, SourceConstants, Components, WBSResponseMessages, CacheKeys, RequestedState, AccessibilityConstants } from "../../../../../common/application.constants";
import { MyPortfolioService } from "../../../../../common/services/my-portfolio.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OneProfileService } from "../../../../../common/services/one-profile.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { UserInfoService, DeviceFactoryProvider, FxpConstants, FxpMessageService, ErrorSeverityLevel } from "@fxp/fxpservices";
import { WBSService } from "../../../../../common/services/wbs.service";
import { ProjectServiceV2 } from "../../../../../common/services/project.v2.service";
import { IWbsSettings } from "../../../../../common/services/contracts/project.service.v2.contracts";
import { DmError } from "../../../../../common/error.constants";

const RELEASED_STATUS_CODE = "REL";
const TECO_STATUS_CODE = "RECO";
const TECO_EBS_STATE = "Complete";
const pendingApprovalStatus = [
    "Committed",
    "Assigned",
    "Complete",
    "Closed"
];

@Component({
    selector: "dm-wbs-ebs-state",
    templateUrl: "./wbs-project-ebs-state.html"
})
export class WbsProjectStateModalComponent extends DmModalAbstract {
    @Input() public selectedProject: IProjectDetailsV2;
    @Input() public selectedService: IServiceDetailsV2;
    @Input() public selectedTask: ITaskDetailsV2;
    @Input() public entityType: string;
    @Input() public selectedEntity: IProjectDetailsV2 | IServiceDetailsV2 | ITaskDetailsV2;
    @Input() public engagementDetailsV2: IEngagementDetailsV2;
    @Input() public isInternalEngagement: boolean = false;

    public allDeliveryStates: IDeliveryState[];
    public deliveryStateListOptions: IDeliveryStateValue[];
    public ebsState: string = "";
    public isStateChangeErrorMessage: boolean;
    public isSubmitBtnDisabled: boolean = true;
    public requestStateChangeText: string;
    public userRequestedEbsState: string;
    public accessibilityConstants = AccessibilityConstants;
    public LogEventConstants = LogEventConstants;
    private FXP_CONSTANTS = FxpConstants;
    private grmRequestList: IResourceRequestResponse;
    private hasPendingExpensesForEngagement: boolean;
    private hasPendingLaborForEngagement: boolean;
    private notificationMessage: INotificationMessages;
    private serviceResponseMessages: IResponseMessage;
    private wbsSettings: IWbsSettings;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(ConcurService) private concurService: ConcurService,
        @Inject(Store) private store: Store<IState>,
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(LaborManagementService) private laborManagementService: LaborManagementService,
        @Inject(CosmicServiceFunctions) private cosmicService: CosmicServiceFunctions,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
    ) {
        super(activeModal, dmLogger, Components.ManageWBSProjectRequestStateChange);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.notificationMessage = this.configurationService.getValue<any>("Notification");
        this.allDeliveryStates = this.configurationService.getValue<IDeliveryState[]>("deliveryStateListCustomerFacing");
        this.serviceResponseMessages = WBSResponseMessages.Engagement;
        this.requestStateChangeText = "Requesting an EBS State change will notify the Project Controller (PJC) to review and change the EBS State. The requester will be notified when this activity is completed.";

        /* Check the object that was passed in, since this component has 3 different objects that could be passed in.*/
        let statusCode: string;
        if (this.selectedProject) {
            statusCode = this.selectedProject.statusCode;
        } else if (this.selectedService) {
            statusCode = this.selectedService.statusCode;
        } else if (this.selectedTask) {
            statusCode = this.selectedTask.statusCode;
        }

        /* get the Status dropdown values from Configuration file and remove the current status */
        const configString: string = this.isInternalEngagement ? "deliveryStateListInternal" : "deliveryStateListCustomerFacing";
        this.allDeliveryStates = this.configurationService.getValue<IDeliveryState[]>(configString);
        if (this.allDeliveryStates) {
            const filteredOptions: IDeliveryState = this.allDeliveryStates.filter((item: IDeliveryState) => item && item.state && item.state === statusCode)[0];
            this.deliveryStateListOptions = filteredOptions ? filteredOptions.values : undefined;
        }
        if (!this.isInternalEngagement) {
            this.projectServiceV2.getWbsSettings(this.selectedEntity.id).then((wbsSettings: IWbsSettings) => {
                this.wbsSettings = wbsSettings;
                if ((this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForRelease.toLowerCase() || this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForTechnicallyCompleted.toLowerCase())
                    && ((this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForRelease.toLowerCase() && this.selectedEntity.statusCode !== RELEASED_STATUS_CODE)
                        || (this.wbsSettings.requestedState.toLowerCase() === RequestedState.RequestedForTechnicallyCompleted.toLowerCase() && this.selectedEntity.statusCode !== TECO_STATUS_CODE))) {
                    this.userRequestedEbsState = wbsSettings.requestedState;
                }
            }).catch((error) => {
                let failureMessage: string;
                if (error.status === 404) {
                    this.wbsSettings = this.projectServiceV2.getDefaultWbsSettings(this.selectedEntity.id);
                } else {
                    failureMessage = DmError.EbsRequestChange.ErrorOccuredInGettingRequestedEBSState;
                    this.fxpMessageService.addMessage((DmError.EbsRequestChange.ErrorOccuredInGettingRequestedEBSState + error), this.FXP_CONSTANTS.messageType.error);
                }
                this.logError(SourceConstants.Method.NgOnInit, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);

            });
        }
        this.setLoadersBasedOnItemState();
    }

    /**
     * Sends a request to update the EBS state
     */
    public updateEbsState(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.UpdateEbsState, LogEventConstants.EBSRequestStateChange);
        this.isComponentLoading = true;
        if (!this.isInternalEngagement) {
            const updatedState = this.deliveryStateListOptions.filter((item) => item.value === this.ebsState)[0].displayText;
            const changedProperties: IChangedProperties[] = [];
            this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject(this.entityType + " EBS State", this.selectedEntity.statusDescription, updatedState));
            this.createLogAndSendNotification(this.selectedEntity.id, changedProperties);
            this.isComponentLoading = false;
            /* modal is closed in create log and send notification */
        } else {
            const projectId = this.selectedProject.id;
            const selectedEbsAction = this.allDeliveryStates.filter((item) => item.state === this.ebsState)[0].action;
            const pjmDetails: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("PJM", this.selectedProject);
            if (!pjmDetails) {
                this.fxpMessageService.addMessage(DmError.EbsRequestChange.SelectedProjectDoesNotHavePJMDetails, "error", true);
                this.isComponentLoading = false;
            }
            if (selectedEbsAction.toLowerCase() === TECO_EBS_STATE.toLowerCase()) {
                this.hasPendingExpensesForEngagement = false;
                this.hasPendingLaborForEngagement = false;
                this.store.dispatch(new LoadResourceRequests(this.selectedProject.engagementId));
                const resourceRequestDetail$ = this.store.select(getEntireResourceRequestsStateObject(this.selectedProject.engagementId));
                resourceRequestDetail$.pipe(untilDestroyed(this)).subscribe((resourceRequestDetail: IResourceRequestsDetailsState) => {
                    if (resourceRequestDetail.loaded) {
                        this.grmRequestList = resourceRequestDetail.grmSearchApiResponse;
                        const promises: Array<Promise<any>> = [];

                        const assignmentIds: string[] = this.getAssignmentsFromGRMResponse(this.grmRequestList, this.selectedProject.id);
                        if (assignmentIds.length > 0) {
                            promises.push(this.getPendingLaborDetails(assignmentIds, this.selectedProject.engagementId, pjmDetails[0].alias));
                        }
                        promises.push(this.checkForPendingExpenses(pjmDetails[0].alias));
                        Promise.all(promises).then(() => {
                            if (this.hasPendingExpensesForEngagement || this.hasPendingLaborForEngagement) {
                                this.isComponentLoading = false;
                                this.fxpMessageService.addMessage(DmError.EbsRequestChange.ThereArePendingLaborOrExpensesAssociatedWithProject, "error", true);
                            } else {
                                this.callUpdateApi(projectId, selectedEbsAction);
                            }
                        }).catch((error) => {
                            this.isComponentLoading = false;
                            this.fxpMessageService.addMessage(DmError.EbsRequestChange.UnableToRetrievePendingLaborOrExpensesDetails, "error", true);
                            this.logError(SourceConstants.Method.UpdateEbsState, error, DmError.EbsRequestChange.UnableToRetrievePendingLaborOrExpensesDetails, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        });
                    }
                });
            } else {
                this.callUpdateApi(projectId, selectedEbsAction);
            }

        }
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Method triggers on project's ebs state change in project context
     *
     * @memberof WbsProjectStateModalComponent
     */
    public onEbsStateChangeProject(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.OnEbsStateChangeProject, LogEventConstants.EbsStateSelected, { selectedState: this.selectedEntity.statusDescription });
        this.isSubmitBtnDisabled = true;
        const updatedState = this.deliveryStateListOptions.filter((item) => item.value === this.ebsState)[0].displayText;
        // Submit is disabled if user changes state from created to released and show appropriate message.
        if (this.selectedEntity.statusDescription === "Created" && updatedState === "Released") {
            this.isStateChangeErrorMessage = true;
            this.isSubmitBtnDisabled = true;
        }
        if (this.ebsState && this.ebsState !== this.selectedEntity.statusDescription) {
            this.isSubmitBtnDisabled = false;
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseButton);
        }
    }

    /**
     * Logs the event for when a tooltip is clicked, including the event constant for the specific tooltip (passed in)
     *
     * @param {string} logEventConstant the specific event constant for the type of tooltip
     */
    public tooltipClicked(logEventConstant: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.TooltipClicked, logEventConstant);
    }

    /**
     * Pushes the given value to the given array if the value evaluates as truthy.
     * (If the value is not null or undefined, false, or the number 0.)
     * Modifies the given array by reference, and does not need to return.
     */
    private pushToArrayIfTrue(array: any[], value: any): void {
        if (value) {
            array.push(value);
        }
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(engagementId: string, changedProperties: IChangedProperties[]): void {
        this.isSubmitBtnDisabled = true;
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.EBSRequestStateChange, propertyBag);

        const currEngagement: IEngagementDetailsV2 = this.engagementDetailsV2;
        const wbsDetails = {
            wbsId: "",
            properties: []
        };
        wbsDetails.properties.push({
            state: changedProperties[0].newValue.toString()
        });
        const notification = new NotificationModel();
        const securityGroups: IDistributionListObject[] = this.configurationService.getDistributionList(currEngagement.isConfidential);

        let currProject: IProjectDetailsV2;
        if (this.selectedProject) { // If we are working with a project, then use the raw project object
            currProject = this.selectedProject;
        } else if (this.selectedService) {
            currProject = currEngagement.projects.filter((x) => x.id === this.selectedService.projectId)[0];
        }
        let sendTo: string[] = [];
        const cc: string[] = this.sharedFunctionsService.getListofAdPjmV2(currEngagement)
            .concat(this.sharedFunctionsService.getListofPjmV2(currEngagement))
            .concat(userAlias);

        if (currEngagement.companyCode) {
            const secGroup = securityGroups.filter((x) => x.CompanyCode === Number(currEngagement.companyCode))[0];
            if (secGroup && secGroup.SecurityGroup) {
                sendTo = sendTo.concat(secGroup.SecurityGroup);
            }
        }
        if (!sendTo.length) {
            const defaultSecurityGroup: string = securityGroups.filter((x) => x.CompanyCode === 0)[0].SecurityGroup;
            sendTo.push(defaultSecurityGroup);
        }
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(cc);
        notification.modifiedBy = userAlias;
        notification.engagementId = engagementId;
        notification.engagementName = currEngagement.name;
        notification.modifiedDate = new Date();
        notification.eventName = NotificationEvent.EBSStateChangeRequest;

        if (this.selectedProject) {
            wbsDetails.wbsId = currProject.id;
            notification.attributes = {
                Id: currProject.id,
                Name: currProject.name,
                Type: "Project",
                Company: currProject.companyCode + (currProject.companyName ? " - " + currProject.companyName : ""),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential
            };
            notification.subject = "EBS State Change Requested for Project [" + currProject.id + ": " + currProject.name + "]";
        } else if (this.selectedService) {
            wbsDetails.wbsId = this.selectedService.id;
            notification.attributes = {
                Id: this.selectedService.id,
                Name: this.selectedService.name,
                Type: "Service",
                Company: this.getProjectCompanyCodeV2(currEngagement, this.selectedService.projectId),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential
            };
            notification.subject = "EBS State Change Requested for Service [" + this.selectedService.id + ": " + this.selectedService.name + "]";
        } else if (this.selectedTask) {
            wbsDetails.wbsId = this.selectedTask.id;
            notification.attributes = {
                Id: this.selectedTask.id,
                Name: this.selectedTask.name,
                Type: "Task",
                Company: this.getProjectCompanyCodeV2(currEngagement, this.selectedTask.serviceId),
                Country: currEngagement.customerCountry,
                IsConfidential: currEngagement.isConfidential
            };
            notification.subject = "EBS State Change Requested for Task [" + this.selectedTask.id + ": " + this.selectedTask.name + "]";
        }
        notification.changedProperties = changedProperties;
        let esxpNotification = this.notificationMessage.ProjectNotification;
        esxpNotification = esxpNotification.replace("#", this.selectedTask ? this.selectedTask.name : currProject.name);
        this.isComponentLoading = true;
        this.cosmicService.createCosmicTicketForNotification(notification)
            .then((cosmicFunctionResponse: ICosmicFunctionResponse) => {
                notification.subject = cosmicFunctionResponse.title;
                /* tslint:disable:no-string-literal */
                notification.attributes["CosmicTicketNumber"] = cosmicFunctionResponse.ticketNumber;
                notification.attributes["CosmicTicketLink"] = cosmicFunctionResponse.incidentId;
                this.notificationService.sendNotification(notification, true, esxpNotification).then(() => {
                    this.wbsSettings.requestedState = this.sharedFunctionsService.getRequestedState(changedProperties[0].newValue.toString());
                    this.projectServiceV2.saveWbsSettings(this.wbsSettings).then(() => {
                        this.isComponentLoading = false;
                        this.engagementDetailService.invalidateStoreOnRefresh(this.engagementDetailsV2.id);
                        this.fxpMessageService.addMessage("EBS State Change has been requested.", this.FXP_CONSTANTS.messageType.success);
                        this.isSubmitBtnDisabled = false;
                        this.closeModal();
                    });
                });
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.engagementDetailsV2.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.CreateLogAndSendNotification, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
                this.isSubmitBtnDisabled = false;
            });
    }

    /**
     * If the given objects are different, creates and returns a changed property object.
     * Returns undefined if the objects have not changed.
     * @param attributeName
     * @param originalValue
     * @param updatedValue
     */
    private createChangedPropertyObject(attributeName: string, originalValue: string, updatedValue: string): IChangedProperties {
        if (originalValue !== updatedValue) {
            return {
                name: attributeName,
                oldValue: originalValue,
                newValue: updatedValue
            };
        }
        return undefined;
    }

    /**
     * Creates a log string from the given changed properties: combines all the changed attribute names into a single, comma seperated string.
     * @param changedProperties
     */
    private createLogStringFromChangedProperties(changedProperties: IChangedProperties[]): string {
        let logString: string = "";
        changedProperties.forEach((property, index) => {
            logString += property.name;
            if (index !== changedProperties.length - 1) {
                logString += ",";
            }
        });
        return logString;
    }

    /**
     * Gets the project company code for the given project ID under the given engagement
     */
    private getProjectCompanyCode(engagement: IEngagementDetails, projectId: string): string {
        let company: string = "";
        if (engagement && engagement.projects && engagement.projects.length) {
            const project = engagement.projects.filter((p) => p.projectId === projectId);
            if (project.length) {
                company = project[0].companyCode + (project[0].companyName ? " - " + project[0].companyName : "");
            }
        }
        return company;
    }

    /**
     * Gets the project company code for the given project ID under the given engagement
     */
    private getProjectCompanyCodeV2(engagement: IEngagementDetailsV2, projectId: string): string {
        let company: string = "";
        if (engagement && engagement.projects && engagement.projects.length) {
            const project = engagement.projects.filter((p) => p.id === projectId || (p.id.substring(0, 16) === projectId.substring(0, 16)));
            if (project.length) {
                company = project[0].companyCode + (project[0].companyName ? " - " + project[0].companyName : "");
            }
        }
        return company;
    }

    /**
     * Call the Update API by passing the projectId and ebsaction
     * * @param projectId
     * * @param selectedEbsAction
     */
    private callUpdateApi(projectId: string, selectedEbsAction: string): void {
        this.isSubmitBtnDisabled = true;
        let updateAction = selectedEbsAction;
        if (selectedEbsAction === "Release" && this.selectedProject.statusCode !== "TECO") {
            // only TECO can be changed to Release
            updateAction = "Complete";
        }
        this.wbsService.updateStatus(projectId, updateAction)
            .then((response) => {
                this.isComponentLoading = false;
                if (response && response.status && response.status === 206) {
                    let partialSuccessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                    partialSuccessMessage = partialSuccessMessage.replace("#", this.selectedProject.name);
                    this.fxpMessageService.addMessage(partialSuccessMessage, this.FXP_CONSTANTS.messageType.warning);
                } else {
                    let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                    successMessage = successMessage.replace("#", this.selectedProject.name);
                    successMessage = successMessage.replace("*", "EBS State"); // CodeQL [SM02383] Need to replace the single occurrence of *.
                    this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                }
                /* If a user requests a state change to Technically Complete, send a TECO request,
                then after it is successful (notify the user), send a CLSD request (don't notify the user)*/
                if (this.ebsState === "TECO") {
                    const closedState: string = "Close";
                    this.wbsService.updateStatus(projectId, closedState);
                } else if (this.ebsState === "REL" && this.selectedProject.statusCode !== "TECO") { // second call TECO->REL when original status is not TECO
                    this.wbsService.updateStatus(projectId, selectedEbsAction);
                }
                this.isComponentLoading = false;
                this.isSubmitBtnDisabled = false;
                this.engagementDetailService.invalidateStoreOnRefresh(projectId);
                this.myPortfolioService.refreshMyPortfolioEngagementList();
                this.closeModal();
            })
            .catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.selectedProject.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.CallUpdateApi, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
                this.isSubmitBtnDisabled = false;
            });
    }

    /**
     * Gets the assignments from GRM Response
     * @param grmRequestList
     * @param projectId
     */
    private getAssignmentsFromGRMResponse(grmRequestList: IResourceRequestResponse, projectId: string): string[] {
        const assignmentIds: string[] = [];
        if (grmRequestList.ProjectRequests && grmRequestList.ProjectRequests.length > 0) {
            for (const projectRequest of grmRequestList.ProjectRequests) {
                if (projectRequest.ResourceRequests && projectRequest.ResourceRequests.length > 0) {
                    for (const resourceRequest of projectRequest.ResourceRequests) {
                        if (projectId && projectRequest.DemandSourceId && projectRequest.DemandSourceId.substring(0, 19) === projectId && pendingApprovalStatus.indexOf(resourceRequest.ResourceRequestStatusEnum) > -1) {
                            assignmentIds.push(resourceRequest.ResourceRequestId.toString());
                        }
                    }
                }
            }
        }
        return assignmentIds;
    }

    /**
     * Get Unapproved expense from concur API and build expense data
     * @param pjmAlias
     */
    private checkForPendingExpenses(pjmAlias: string): Promise<any> {
        /* Get the personel number of the pjm */
        const cacheKey = CacheKeys.BulkUserProfile.KeyName + JSON.stringify([pjmAlias]);
        return this.cacheService.get(cacheKey,
            () => this.oneProfileService.getBulkProfiles([pjmAlias]), CacheKeys.BulkUserProfile.Duration)
            .then((pjmUserDetails: IOneProfileBulkAPIResponse[]) => {
                const pjmPersonnelNumber = pjmUserDetails.filter((userInfo) => userInfo.Alias.toLowerCase() === pjmAlias.toLowerCase())[0].PersonnelNumber;
                let queryString = "employeeId=" + pjmPersonnelNumber.toString();
                const expenseWbsIds: string[] = this.sharedFunctionsService.getExpenseTypeWbsIds([this.selectedProject]);
                for (const expenseId of expenseWbsIds) {
                    queryString = queryString + "&expenseWbsId=" + expenseId;
                }
                return this.getPendingExpensesFromConcur(queryString);
            });

    }

    /**
     * Gets the list of pending expense reports by calling the Concur Service.
     * @param queryString
     */
    private getPendingExpensesFromConcur(queryString: string): Promise<any> {
        const key = `${CacheKeys.ConcurPendingReportsApproval.KeyName}-${queryString}`;
        return this.cacheService.get(key, () => this.concurService.getPendingReportsforApproval(queryString), CacheKeys.ConcurPendingReportsApproval.Duration)
            .then((data: IConcurPendingReportsResponse[]) => {
                if (data && data.length > 0) {
                    this.hasPendingExpensesForEngagement = true;
                }
            });
    }

    /**
     * Gets details of pending labor reports by calling the Time2.0 labor Service.
     * @param queryString
     */
    private getPendingLaborDetails(assignmentIds: string[], engagementId: string, approverAlias: string): Promise<any> {
        const requestPayLoad = {
            assignmentIds,
            engagementId
        };
        const key = `${CacheKeys.PendingLaborApprovalCountBasedOnAssignments.KeyName}-${JSON.stringify(requestPayLoad)}`;
        return this.cacheService.get(key, () => this.laborManagementService.getPendingLaborApprovalCountBasedOnAssignments(assignmentIds, engagementId, approverAlias), CacheKeys.PendingLaborApprovalCountBasedOnAssignments.Duration)
            .then((data: ILaborApprovalCountBasedOnAssignments) => {
                if (data && data.count > 0) {
                    this.hasPendingLaborForEngagement = true;
                }
            });
    }
}



