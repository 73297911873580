<section class="dm-tile" [ngClass]="{'dm-tile-v2': isV2Tile, 'col-md-12 col-xs-12 col-sm-12': !isV2Tile}"
    [ngStyle]="{'min-height':tileMinHeight, 'height':tileHeight}">
    <div class="dm-tile__header dm-p-b-10" [ngClass]="{'dm-tile__header-v2': isV2Tile}" *ngIf="showHeader">
        <ng-content select="[additionalActionsLeft]"></ng-content>
        <span *ngIf="!isV2Tile && tileHeaderContent?.iconTitle" class="icon dm-p-x-5"
            [ngClass]="tileHeaderContent?.iconTitle"></span>
        <span *ngIf="isV2Tile && tileHeaderContent?.iconTitle" class="icon icon-v2"
            [ngClass]="tileHeaderContent?.iconTitle"></span>
        <h3 *ngIf="!(tileHeaderContent?.isPageHeading)" class="dm-tile__title dm-p-r-5 display-inline">{{tileHeaderContent?.title}}</h3>
        <span *ngIf="tileHeaderContent?.itemsCount >= 0" class="dm-tile__title-count">({{tileHeaderContent?.itemsCount}})</span>
        <div class="dm-tile__right-container" [ngClass]="{'margin-left-auto': isV2Tile}">
            <ng-content select="[additionalActionsRight]"></ng-content>
            <a *ngIf="tileHeaderContent?.link" [id]="tileHeaderContent?.link?.tooltipText" class="dm-link dm-m-l-imp-20"
                [attr.aria-label]="tileHeaderContent?.title + ' tile section. ' + tileHeaderContent?.link?.name + tileHeaderContent?.link?.tooltipText + 'open link in new tab'" [href]="tileHeaderContent?.link?.url" target="_blank"
                [title]="tileHeaderContent?.link?.tooltipText"
                >
                <span *ngIf="!isV2Tile && tileHeaderContent?.link?.icon" class="icon"
                    [ngClass]="tileHeaderContent?.link?.icon"></span>
                <span *ngIf="isV2Tile && tileHeaderContent?.link?.icon" class="icon icon-v2"
                    [ngClass]="tileHeaderContent?.link?.icon"></span>
                <span>{{tileHeaderContent?.link?.name}}</span>
            </a>
        </div>
    </div>
    <div class="dm-tile__content"
        [ngClass]="{'dm-tile__content-not-overflow': hasNonOverflowContent, 'dm-tile__content-v2': isV2Tile}">
        <ng-content></ng-content>
        <div class="dm-tile__loader">
            <ng-content select="[loader]"></ng-content>
        </div>
    </div>
    <div class="dm-tile__footer">
        <ng-content select="[tileFooter]"></ng-content>
    </div>
</section>