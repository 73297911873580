import { Inject } from "@angular/core";
import { Component, Input } from "@angular/core";
import { RouteName } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";

@Component({
    selector: "dm-service-error",
    templateUrl: "./dm-service-error.html",
    styleUrls: ["./dm-service-error.scss"]
})
export class DmServiceErrorComponent {
    @Input() public ngbTooltipContent: string;
    @Input() public dataText: string = "Something went wrong :(";
    @Input() public showReloadMessage: boolean = true;
    public reloadPage: string = "Please reload the page";
    public RouteName = RouteName;
    public reportProblemLink: string;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor(@Inject(ConfigManagerService) private configManagerService: ConfigManagerService) {
        
    }

    public ngOnInit(): void {
        this.configManagerService.initialize().then(() => {
            this.reportProblemLink = this.configManagerService.getValue<string>("reportProblemLink");
        });
    }
}
