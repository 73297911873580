import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "highlight"
})

export class HighlightPipe implements PipeTransform {
    /** 
     * Highlights a given args by replacing the value with the returned value.
     * Example: we originally write:
     * <span innerHTML="{{request.demandRole | highlight : searchText}}"></span>
     * value in the searchText will get highlighted in the request.demandRole value      
     * @param value 
     * @param args
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public transform(value: any, args: any): any {
        if (args && value) {
            const startIndex = value.toString().toLowerCase().indexOf(args.toString().toLowerCase());
            if (startIndex !== -1) {
                const endLength = args.toString().length;
                const matchingString = value.toString().substr(startIndex, endLength);
                return value.toString().replace(matchingString, "<mark>" + matchingString + "</mark>");
            }
        }
        return value;
    }
}