<div class="bulk-maintenance-notification">
  <div class="bulk-maintenance-notification__item--collapsed">
    <div class="bulk-maintenance-notification__item-header">
      <div class="notification-bar__item-title" [attr.title]="'Bulk Maintenance (' + timeAgo + ')'">
        {{notificationDetail.entityDescription ? notificationDetail.entityDescription : 'Bulk Maintenance'}} <span
          class="bulk-maintenance-notification__item-time">({{bulkMaintenanceResponse?.bulkProcessItems?.length == 0 ?
          'Not Started' : timeAgo}})</span></div>
      <div class="pull-right">
        <button *ngIf="bulkMaintenanceResponse?.bulkProcessItems?.length > 0" aria-label="Expand"
          class="bulk-create-notification__item-btn" title="expand" type="button"
          (click)="openBulkCreateIntEngagementStatusModal()">
          <span class="icon"
            [ngClass]="{'icon-chevron-up': !isNotificationExpanded, 'icon-chevron-down': isNotificationExpanded}"></span>
        </button>
        <button *ngIf="shouldDisplayCloseOption" aria-label="Close" class="bulk-maintenance-notification__item-btn"
          title="close" type="button" (click)="closeNotification()">
          <span aria-hidden="true" class="icon icon-close"></span>
        </button>
      </div>
    </div>
    <div *ngIf="!responseSubmitted && !shouldDisplayCloseOption" class="bulk-maintenance-notification__progress-bar">
      <ngb-progressbar [value]="processedCount" [max]="totalCount">
      </ngb-progressbar>
    </div>
    <div class="bulk-maintenance-notification__item-body">
      <div class="bulk-maintenance-notification__item-body-content">
        <div *ngIf="responseSubmitted">
          <span>
            <i class="icon record-progress-icon"></i>
          </span>
          <span class="dm-p-x-5" title="Bulk Maintenance in progress.">Bulk Maintenance in progress.
          </span>
        </div>
        <div *ngIf="!responseSubmitted">
          <span *ngIf="!shouldDisplayCloseOption" class="dm-p-x-5">Requests {{ processedCount }} of {{ totalCount }}
            processed.</span>
          <ng-container *ngIf="shouldDisplayCloseOption">
            <span class="icon icon-check icon-rounded-outline icon-success"></span>
            <span class="dm-p-x-5" title="Bulk Maintenance completed.">Bulk Maintenance completed.</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>