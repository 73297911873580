import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { WbsAddTaskModalComponent } from "./wbs-project-service-task-add.component";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../../common/services/dmnotification.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../../common/services/wbs.service";

@NgModule({
    declarations: [
        WbsAddTaskModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        DMNotificationService,
        SharedFunctionsService,
        WBSService
    ],
    entryComponents: [
        WbsAddTaskModalComponent,
    ],
    exports: [
        WbsAddTaskModalComponent,
    ]
})
export class WbsAddTaskModalModule { }
