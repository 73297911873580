import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { CustomerEngagementComponent } from "./customer-engagement.component";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        CustomerEngagementComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        EngagementDetailService,        
        DMAuthorizationService,
        SharedFunctionsService
    ],
    entryComponents: [
        CustomerEngagementComponent,
    ],
    exports: [
        CustomerEngagementComponent,
    ]
})
export class CustomerEngagementModule { }
