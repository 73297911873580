import { NgModule } from "@angular/core";
/**
 * plan & forecast route does not have an associated component, since the plan&forecast component
 * is embedded in the EngagementDetailsComponent. therefore the module associated with this
 * route does not need to have anything in it.
 */
@NgModule({
    declarations: [],
    imports: [],
    providers: [],
    entryComponents: [],
    exports: []
})
export class PlanForecastDummyModule { }