import { forwardRef, Inject, Component, ViewChild } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";
import { Components, NoDataText, LogEventName, SourceConstants, LogEventConstants, EngagementType } from "../../../../common/application.constants";
import { CustomerEngagementGridDataOverviewTab } from "./customer-engagement-grid-data-tabs/overview-tab/customer-engagement-grid-data-overview-tab.component";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DmError } from "../../../../common/error.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { getEntireUserPreference } from "../../../../store/userspreferences/userpreference.selector";
import { getMyPortfolioEngagementListState } from "../../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IEngagementList } from "../../../../common/services/contracts/portfolio.model";
import { IMyPortfolioEngagementListState } from "../../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IState } from "../../../../store/reducers";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { IUserPreference } from "../../../../common/services/contracts/userpreference.contract";
import { IUserPreferenceState } from "../../../../store/userspreferences/userpreference.reducer";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UpdateUserPreference } from "../../../../store/userspreferences/userpreference.action";
import { UserPreferenceService } from "../../../../common/services/userpreferences.service";

export interface IDictionary {
    key: string;
    value: string;
}
@Component({
    selector: "dm-customer-engagement-grid-data",
    templateUrl: "./customer-engagement-grid-data.html",
    styleUrls: ["customer-engagement-grid-data.scss"]
})
export class CustomerEngagementGridDataComponent extends DmComponentAbstract {
    @ViewChild(CustomerEngagementGridDataOverviewTab, { static: false }) public gridDataOverviewTab: CustomerEngagementGridDataOverviewTab;
    public viewby: string = "all";
    public showNoEngagementsView: boolean = false;
    public isCustomerEngagementsExpanded: boolean = false;
    public customerEngagementsCount: number = 0;
    public customerEngagementsList: IEngagementList[];
    public viewFilterLabels: IDictionary[];
    public selectedFilter: IDictionary;
    public noCustomerEngagementFoundText: string;
    public ariaLabelForFilterDropDown: string;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.CustomerDelivery;
    public tileContent: ITile;
    public tileMinHeight: string;
    private userPreference: IUserPreference;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StateService) private stateService: StateService,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService
    ) {
        super(dmLogger, Components.PortfolioGridData, [{ component: Components.PortfolioGridDataOverviewTab, isCritical: true }]);
    }

    public ngOnInit(): void {
        this.noCustomerEngagementFoundText = NoDataText.NoEngagements;
        this.ariaLabelForFilterDropDown = "filter customer engagements and projects";
        this.tileContent = {
            title: "Customer Delivery Engagements",
            itemsCount: 0
        };
        this.storeDispatchService
            .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
            .load();

        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);

        /* We observe this part of the state only to confirm if we have engagements or not. If we don't have any engagements, we will
        toggle a flag to show a different component */
        myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
            if (engagementList && engagementList.loaded) {
                if (engagementList.engagementList && engagementList.engagementList.length) {
                    this.customerEngagementsList = engagementList.engagementList.filter((x: IEngagementList) => x.type.toLowerCase() === "engagement");
                    this.tileContent.itemsCount = this.customerEngagementsList && this.customerEngagementsList.length;
                }
            }
            if (engagementList.error) {
                this.isServerError = true;
            }
        });
        const userPreferences$ = this.store.select((getEntireUserPreference()));
        userPreferences$.pipe(untilDestroyed(this)).subscribe((userPreferenceState: IUserPreferenceState) => {
            if (userPreferenceState.loaded) {
                this.userPreference = userPreferenceState.userPreference;
                this.isCustomerEngagementsExpanded = this.userPreference.customerEngagements.isComponentExpanded;
                this.tileMinHeight = this.isCustomerEngagementsExpanded ? "287px" : "auto";
            }
            if (userPreferenceState.error) {
                this.isServerError = true;
            }
        });
        this.viewFilterLabels = [
            { key: "View Engagements & Projects", value: "all" },
            { key: "View Engagements", value: "engagements" },
            { key: "View Projects", value: "projects" }
        ];
        this.selectedFilter = this.viewFilterLabels[0];
    }

    /**
     * Toggles expand collapse of internal engagements     
     */
    public onToggleExpandCollapse(): void {
        this.userPreference.customerEngagements.isComponentExpanded = !this.isCustomerEngagementsExpanded;
        this.userPreferenceService.saveUserPreference(this.userPreference).then((response: IUserPreference) => {
            this.store.dispatch(new UpdateUserPreference(response));
        });
        const propertyBag = {};
        propertyBag[EngagementType] = "Customer";
        if (this.userPreference.internalEngagements.isComponentExpanded) {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.OnToggleExpandCollapse, LogEventConstants.EngagementListExpanded, propertyBag);
            this.tileMinHeight = "287px";
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.OnToggleExpandCollapse, LogEventConstants.EngagementListCollapsed, propertyBag);
            this.tileMinHeight = "auto";
        }
    }

    /**
     * Changes Customer Engagements filter as requested
     * @param filter 
     */
    public onSelectedFilterChanged(filter: IDictionary): void {
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.OnSelectedFilterChanged, LogEventName.PortfolioCustomerEngagementsFilterChanged);
        this.viewby = filter.value;
    }

    /**
     * Sets the title or aria-label on collapsing and expanding customer engagements
     */
    public setTitleOrAriaLabelForExpandCollapseCustomerGrid(): string {
        return (this.isCustomerEngagementsExpanded ? "Collapse " : "Expand ") + this.tileContent.itemsCount + " Customer Engagements";
    }
}
