import { Injector } from "@angular/core";
import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { Components, LogEventConstants, SourceConstants, NoDataText, PurchaseOrderType, AccessibilityConstants, NavHeight } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { IContractType } from "../../../common/services/contracts/project.service.contracts";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ManageSuppliersTransactionModalComponent } from "../manage-suppliers-transaction-modal/manage-suppliers-transaction-modal.component";
import { IDeliveredTextCheckingObj, IPoLineitem, IPoList } from "./lineitems.contract";

/**
 * ManageSuppliersTableDataComponent
 *
 * @export
 * @class ManageSuppliersTableDataComponent
 */
@Component({
    selector: "dm-manage-suppliers-table-data",
    templateUrl: "./manage-suppliers-table-data.html",
    styleUrls: ["./manage-suppliers-table-data.scss"]
})
export class ManageSuppliersTableDataComponent extends DmComponentAbstract implements OnChanges {
    @Input() public isFilterCheckEnable: boolean;
    @Input() public rows: IPoList[];
    @Input() public isProjectContext: boolean;
    @Input() public selectedPo: string;
    @Output() public onSaveProject = new EventEmitter<IPoList[]>();
    public saveBtnDisabled: boolean = true;
    public myOrderLink: string;
    public typeOfResources: IContractType[] = [];
    public tableIndex: number = 0;
    public originalRows: IDeliveredTextCheckingObj[];
    public noPurchaseOrders = NoDataText.NoPurchaseOrdersCreated;
    public PurchaseOrderType = PurchaseOrderType;
    public accessibilityConstants = AccessibilityConstants;

    /**
     * creates an instance of ManageSuppliersTableDataComponent.
     * @param {DeviceFactoryProvider} deviceFactory
     * @param {ConfigManagerService} configmanagerService
     * @param {SharedFunctionsService} sharedFunctionsService
     * @param {DMLoggerService} logger
     * @memberof ManageSuppliersTableDataComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Components.CustomerInvoicesTableData);
    }

    /**
     * Captures the OnInit event
     */
    public ngOnInit(): void {
        this.configmanagerService.initialize()
            .then(() => {
                this.myOrderLink = this.configmanagerService.getValue<string>("myOrderLink");
                this.typeOfResources = this.configmanagerService.getValue<IContractType[]>("poContractTypes")
                    .filter((item: any) => (!(item.typeShortForm === "FTE" || item.typeShortForm === "EXC")));
            });

        this.originalRows = this.getOriginalRows(this.rows);
        this.endComponentLoad();
    }

    /**
     * Listen for changes on the rows PoList object from the parent, after any updates occur.
     * Reset the 'original' copy of the rows to be this newly retrieved version, and recheck the input to disable the save button
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (propName === "rows" && changes[propName].previousValue) { /* Listening for changes on the rows @input variable only, don't want the oninit event */
                this.originalRows = this.getOriginalRows(this.rows);
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].lineitems) {
                        for (let j = 0; j < this.rows[i].lineitems.length; j++) {
                            this.checkInputChanges(i, j, this.rows[i].lineitems[j].deliveredText, this.rows[i].lineitems[j]);
                        }
                    }
                }
            }

        }
    }

    /**
     * Creates a simplified array of the 'original rows' that only checks for the lineitem's delivered text to compare any changes against.
     * @param currentRows
     */
    public getOriginalRows(currentRows: IPoList[]): IDeliveredTextCheckingObj[] {
        const deliveredTextObj: IDeliveredTextCheckingObj[] = [];
        if (currentRows) {
            for (const row of currentRows) {
                if (row.lineitems) {
                    const singleItemArray: number[] = [];
                    for (const item of row.lineitems) {
                        singleItemArray.push(item.postedQuantity);
                    }
                    const lineItem = { lineItemDeliveredText: singleItemArray };
                    deliveredTextObj.push(lineItem);
                } else { /* if lineitems doesnt exist on the row, then add an undefined value into the array because we don't need to traverse it */
                    deliveredTextObj.push(undefined);
                }
            }
        }
        return deliveredTextObj;
    }


    /**
     * save user entered data for line items
     *
     * @memberof ManageSuppliersTableDataComponent
     */
    public saveButtonClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageSuppliersPage, SourceConstants.Method.SaveButtonClick, LogEventConstants.ManageSupppliersSaveGoodsReceiptButtonClicked);
        this.onSaveProject.emit(this.rows);
    }

    /**
     * Checks if the input value is new or valid. If the input is new or valid, then the save button will become enabled.
     * If the input is invalid (blank), or has not changed from when the page was opened, then the save button will be disabled.
     * @param rowsIndex
     * @param lineItemIndex
     * @param newValue
     */
    public checkInputChanges(rowsIndex: number, lineItemIndex: number, newValue: number, lineitem: IPoLineitem): void {
        if (lineitem) {
            if (this.isValidInput(lineitem)) {
                lineitem.isValueChanged = true;
                lineitem.validationMsg = "";
                lineitem.showValidationMsg = false;
                this.saveBtnDisabled = false;
            } else {
                lineitem.isValueChanged = false;
                lineitem.showValidationMsg = true;
                this.saveBtnDisabled = true;
            }
        }
    }

    public isValidInput(lineitem: IPoLineitem): boolean {
        if (this.getDecimalPlaces(lineitem.postedQuantity) > 3) {
            lineitem.validationMsg = "Posting quantity should not exceed 3 decimal values.";
            return false;
        }
        if ((lineitem.postedQuantity + +lineitem.deliveredText) > +lineitem.quantity) {
            lineitem.validationMsg = "Sum of consumed quantity and posting quantity should not exceed ordered quantity.";
            return false;
        } else if (Math.sign(lineitem.postedQuantity) === -1 && Math.abs(lineitem.postedQuantity) > (lineitem.deliveredText - +lineitem.invoicedQuantity)) {
            lineitem.validationMsg = "Posting quantity should not be less than remaining invoiced quantity.";
            return false;
        } else {
            return true;
        }
    }

    /**
     * Opens unit transaction modal based on demandId
     *
     * @param {string} demandId
     * @memberof ActualsComponent
     */
    public openManageSuppliersTransactionsModal(poDetails: IPoList, lineItem: IPoLineitem): void {
        this.modalService.dismissAll();
        this.dmLogger.logEvent(SourceConstants.Component.ActualsPage, SourceConstants.Method.OpenUnitTransactionsModal, LogEventConstants.UnitTransactionDetailsClicked);
        const modalRef: NgbModalRef = this.modalService.open(ManageSuppliersTransactionModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal-v2 unit-transactions-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.poDetails = poDetails;
        modalRef.componentInstance.poLineItemDetails = lineItem;
    }

    public handleKeyup(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * to get PO consumed % and return number value
     *
     * @param {IPoLineitem[]} lineitems
     * @param {number} poTotalAmount
     * @returns {number}
     * @memberof ManageSuppliersTableDataComponent
     */
    public getPOConsumed(lineitems: IPoLineitem[], poTotalAmount: number): number {
        let poConsumed = 0;
        if (poTotalAmount && lineitems && lineitems.length) {
            let costConsumed = 0;
            lineitems.forEach((lineitem: IPoLineitem) => {
                costConsumed = costConsumed + lineitem.costComplete;
            });
            poConsumed = (costConsumed / poTotalAmount) * 100;
        }
        return poConsumed;
    }

    /**
     * Toggles the visibility of the line item (Less/More details) and shifts focus to the given id.
     * @param {IPoLineitem} lineItem
     * @param {string} id
     * @memberof ManageSuppliersTableDataComponent
     */
    public toggleLineItemShowWbs(lineItem: IPoLineitem, id: string): void {
        lineItem.lineItemShowWbs = !lineItem.lineItemShowWbs;
        this.sharedFunctionsService.focus(id, true);
    }

    /**
     * check Object empty or not
     *
     * @param {*} object
     * @returns {boolean}
     * @memberof ManageSuppliersTableDataComponent
     */
    public isEmpty(obj: {}): boolean {
        return Object.keys(obj).length === 0;
    }

    /**
     * log event for tracking
     *
     * @private
     * @memberof ManageSuppliersTableDataComponent
     */
    public logEvent(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageSuppliersPage, SourceConstants.Method.LogEvent, LogEventConstants.ManageSuppliersPurchaseOrderLinkClicked);
    }

    /**
     * set top position for sticky table header
     */
    public setTopPositionForStickyTableHeader(): string {
        if (document.getElementById("secondary-naviagtion")) {
            return document.getElementById("secondary-naviagtion").clientHeight + "px";
        } else {
            return NavHeight;
        }
    }

    private getDecimalPlaces(value: number): number {
        if  (Math.floor(value) === value) {
            return 0;
        }
        return value.toString().split(".")[1].length || 0;
    }
}
