<section class="dm-p-x-12-y-imp-0">
    <section class="row">
        <section class="col-md-12 col-sm-12 col-xs-12 dm-m-b-12">
            <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'500px'" [tileHeight]="'100%'" [isV2Tile]="true"
                [hasNonOverflowContent]="true">
                <dm-loading loader [loadingText]="loadingText" [showLoading]="showLoading" *ngIf="isComponentLoading">
                </dm-loading>
                <dm-plan-forecast-details [planDetails]="planDetails" [planCurrency]="planCurrency" [wbsId]="wbsId" *ngIf="!isComponentLoading">
                </dm-plan-forecast-details>
            </dm-tile>
        </section>
    </section>
</section>