import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { ChangeExistingDemandModalComponent } from "./change-existing-demand.component";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalV2Module } from "../../../../modals/dm-modal-v2/dm-modal-v2.module";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        ChangeExistingDemandModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        DmModalV2Module,
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
    ],
    entryComponents: [
        ChangeExistingDemandModalComponent,
    ],
    exports: [
        ChangeExistingDemandModalComponent,
    ]
})
export class ChangeExistingDemandModalModule { }