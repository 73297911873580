import { Action } from "@ngrx/store";
import { IClinSlinApiResponse } from "../../common/services/contracts/project.service.contracts";


// 1. action types
export enum ClinslinActionTypes {
    LOAD_Clinslin = "[Clinslin] Load",
    LOAD_MANUAL_Clinslin = "[Clinslin] Load Manual",
    LOAD_Clinslin_SUCCESS = "[Clinslin] Load Success",
    LOAD_Clinslin_FAIL = "[Clinslin] Load Fail",
    INVALIDATE_Clinslin = "[Clinslin] Invalidate"
}

// 2. action creators
export class LoadClinslin implements Action {
    public readonly type = ClinslinActionTypes.LOAD_Clinslin;
    public constructor(public wbsId: string) { }
}

export class LoadManualClinslin implements Action {
    public readonly type = ClinslinActionTypes.LOAD_MANUAL_Clinslin;
    public constructor(public wbsId: string) { }
}

export class LoadClinslinSuccess implements Action {
    public readonly type = ClinslinActionTypes.LOAD_Clinslin_SUCCESS;
    public constructor(public wbsId: string, public clinSlinResponse: IClinSlinApiResponse) { }
}

export class LoadClinslinFail implements Action {
    public readonly type = ClinslinActionTypes.LOAD_Clinslin_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateClinslin implements Action {
    public readonly type = ClinslinActionTypes.INVALIDATE_Clinslin;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type ClinslinAction =
    | LoadClinslin
    | LoadManualClinslin
    | LoadClinslinSuccess
    | LoadClinslinFail
    | InvalidateClinslin;