import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as forecastRecommendationsActions from "./project-forecast-recommendations.action";
import { ForecastRecommendationService } from "../../common/services/forecast-recommendation.service";
import { IWeeklyForecastRecommendation } from "../../common/services/contracts/forecast-recommendation.contracts";


@Injectable()
export class ProjectForecastRecommendationsEffects {

    @Effect()
    public loadForecastRecommendations$ = this.actions$
        .ofType(forecastRecommendationsActions.ProjectForecastRecommendationActionTypes.LOAD_PROJECT_FORECAST_RECOMMENDATIONS)
        .pipe(
            switchMap((action: forecastRecommendationsActions.LoadProjectForecastRecommendations) => fromPromise(
                this.forecastRecommendationService.getForecastRecommendationsByProjectId(action.projectId, action.engagementId))
                .pipe(
                    map((response: IWeeklyForecastRecommendation[]) => new forecastRecommendationsActions.LoadProjectForecastRecommendationsSuccess(action.projectId, response)),
                    catchError((error) => of(new forecastRecommendationsActions.LoadProjectForecastRecommendationsFail(action.projectId, error)))
                )
            ));

    public constructor(
        private actions$: Actions,
        @Inject(ForecastRecommendationService) private forecastRecommendationService: ForecastRecommendationService,
    ) { }
}
