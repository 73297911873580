import { Component, Input } from "@angular/core";
import { RouteName } from "../../../common/application.constants";

@Component({
    selector: "dm-no-data",
    templateUrl: "./no-data-text.html",
    styleUrls: ["./no-data-text.scss"]
})
export class DmNoDataTextComponent {

    @Input() public noDataText: string;
    @Input() public showImage: boolean;
    public RouteName = RouteName;
}
