<div class="dm-hidden-placeholder"></div>
<ui-view name="summary" [hidden]="!uiRouter.stateService.is(routeName.EngagementSummary)"></ui-view>
<ui-view name="financials" [hidden]="!uiRouter.stateService.is(routeName.EngagementFinancials)"></ui-view>
<ui-view name="ebs" [hidden]="!uiRouter.stateService.is(routeName.EngagementManageEBS)"></ui-view>
<ui-view name="staffing" [hidden]="!uiRouter.stateService.is(routeName.EngagementStaffing)"></ui-view>
<ui-view name="manageSuppliers" [hidden]="!uiRouter.stateService.is(routeName.EngagementManageSuppliers)"></ui-view>
<ui-view name="milestones" [hidden]="!uiRouter.stateService.is(routeName.EngagementMilestones)"></ui-view>
<ui-view name="invoices" [hidden]="!uiRouter.stateService.is(routeName.EngagementInvoices)"></ui-view>
<ui-view name="changeRequestForm" [hidden]="!uiRouter.stateService.is(routeName.EngagementFinancialChangeRequestForm)">
</ui-view>
<ui-view name="changeRequest" [hidden]="!uiRouter.stateService.is(routeName.EngagementFinancialChangeRequest)">
</ui-view>
<ui-view name="amendmentDetails" [hidden]="!uiRouter.stateService.is(routeName.EngagementAmendmentDetails)"></ui-view>
<ui-view name="units" [hidden]="!uiRouter.stateService.is(routeName.EngagementActuals)"></ui-view>
<ui-view name="nonProcuredMaterials" [hidden]="!uiRouter.stateService.is(routeName.EngagementNonProcuredMaterials)">
</ui-view>
<ui-view name="contactDetails" [hidden]="!uiRouter.stateService.is(routeName.EngagementContactDetails)"></ui-view>
<ui-view name="summaryv2" [hidden]="!uiRouter.stateService.is(routeName.EngagementSummaryV2)"></ui-view>
<ui-view name="wbsdetails" [hidden]="!uiRouter.stateService.is(routeName.EngagementWbsDetails)"></ui-view>
<ui-view name="planAndForecastv2" [hidden]="!uiRouter.stateService.is(routeName.PlanAndForecastV2)"></ui-view>
<ui-view name="misalignedAmendmentDetails" [hidden]="!uiRouter.stateService.is(routeName.EngagementMisalignedAmendmentDetails)"></ui-view>
<!-- plan&forecast tab must be inside a div rather than a ui-view since it is the one tab that should start loading immediately
when user clicks into the engagement -->
<div [style.display]="uiRouter.stateService.includes(routeName.EngagementPlanForecast) ? 'block' : 'none'">
    <dm-plan-forecast></dm-plan-forecast>
</div>