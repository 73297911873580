import { NgModule } from "@angular/core";
import { ProjectDetailComponent } from "./project-detail.component";
import { UIRouterModule } from "@uirouter/angular";
import { PlanForecastModule } from "../plan-forecast/planForecast.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        ProjectDetailComponent
    ],
    imports: [    
        UIRouterModule,
        PlanForecastModule,
        CommonModule
    ],
    entryComponents: [
        ProjectDetailComponent
    ],
    exports: [ProjectDetailComponent]
})
export class ProjectDetailModule {}
