import { CommonModule } from "@angular/common";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { NotificationBarComponent } from "./notification-bar.component";
import { BulkCreateNotificationComponent } from "./bulk-create-notification/bulk-create-notification.component";
import { ReleaseActivateNotificationComponent } from "./release-activate-notification/release-activate-notification.component";
import { CommonComponentsModule } from "../entry/common-components.module";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmGrmService } from "../../common/services/dmgrm.service";
import { BulkUploadInternalEngagementService } from "../../common/services/bulk-upload-internal-engagement.service";
import { DateChangeNotificationComponent } from "./date-change-notification/date-change-notification.component";
import { RiskReserveNotificationComponent } from "./risk-reserve-notification/risk-reserve-notification.component";
import { BulkMaintenanceComponent } from "./bulk-maintenance-notification/bulk-maintenance-notification.component";
import { WbsStatusChangeNotificationComponent } from "./wbs-status-change-notification/wbs-status-change.notification.component";
import { OpenSynEACToPlanThresholdModule } from "./open-sync-eac-to-plan-threshold-dialog/open-sync-eac-to-plan-threshold-dialog.module";
import { SyncEACToPlanNotificationComponent } from "./sync-eac-to-plan-notification/sync-eac-to-plan-notification.component";
import { AutoBulkMaintenanceComponent } from "./auto-bulk-maintenance-notification/auto-bulk-maintenance-notification.component";


@NgModule({
    declarations: [
        NotificationBarComponent,
        BulkCreateNotificationComponent,
        ReleaseActivateNotificationComponent,
        DateChangeNotificationComponent,
        RiskReserveNotificationComponent,
        BulkMaintenanceComponent,
        WbsStatusChangeNotificationComponent,
        SyncEACToPlanNotificationComponent,
        AutoBulkMaintenanceComponent
    ],
    imports: [
        NgbProgressbarModule,
        CommonComponentsModule,
        CommonModule,
        OpenSynEACToPlanThresholdModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
        DmGrmService,
        BulkUploadInternalEngagementService
    ],
    entryComponents: [
        NotificationBarComponent
    ],
    exports: [
        NotificationBarComponent,
        BulkCreateNotificationComponent,
        ReleaseActivateNotificationComponent,
        DateChangeNotificationComponent,
        RiskReserveNotificationComponent,
        WbsStatusChangeNotificationComponent,
        SyncEACToPlanNotificationComponent
    ]
})
export class DmNotificationBarModule { }
