import { Action } from "@ngrx/store";
import { IEntityFinancials } from "../../components/financial-mgmt/financial.model";

// 1. action types
export enum FinancialDetailsV2ActionTypes {
    LOAD_FINANCIAL_DETAILS_V2 = "[FinancialDetails V2] Load",
    LOAD_MANUAL_FINANCIAL_DETAILS_V2 = "[FinancialDetails V2] Load Manual",
    LOAD_FINANCIAL_DETAILS_V2_SUCCESS = "[FinancialDetails V2] Load Success",
    LOAD_FINANCIAL_DETAILS_V2_FAIL = "[FinancialDetails V2] Load Fail",
    INVALIDATE_FINANCIAL_V2_DETAILS = "[FinancialDetails V2] Invalidate"
}

// 2. action creators
export class LoadFinancialDetailsV2 implements Action {
    public readonly type = FinancialDetailsV2ActionTypes.LOAD_FINANCIAL_DETAILS_V2;
    public constructor(public engagementId: string) { }
}

export class LoadManualFinancialDetailsV2 implements Action {
    public readonly type = FinancialDetailsV2ActionTypes.LOAD_MANUAL_FINANCIAL_DETAILS_V2;
    public constructor(public engagementId: string) { }
}

export class LoadFinancialDetailsSuccessV2 implements Action {
    public readonly type = FinancialDetailsV2ActionTypes.LOAD_FINANCIAL_DETAILS_V2_SUCCESS;
    public constructor(public engagementId: string, public financialDetails: IEntityFinancials) { }
}

export class LoadFinancialDetailsFailV2 implements Action {
    public readonly type = FinancialDetailsV2ActionTypes.LOAD_FINANCIAL_DETAILS_V2_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateFinancialDetailsV2 implements Action {
    public readonly type = FinancialDetailsV2ActionTypes.INVALIDATE_FINANCIAL_V2_DETAILS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type FinancialDetailsActionsV2 =
    | LoadFinancialDetailsV2
    | LoadManualFinancialDetailsV2
    | LoadFinancialDetailsSuccessV2
    | LoadFinancialDetailsFailV2
    | InvalidateFinancialDetailsV2;