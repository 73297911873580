import { CommonModule } from "@angular/common";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmGrmService } from "../../../common/services/dmgrm.service";
import { BulkUploadInternalEngagementService } from "../../../common/services/bulk-upload-internal-engagement.service";
import { SyncEACToPlanNotificationV2Module } from "../sync-eac-to-plan-notification-V2/sync-eac-to-plan-notification-v2.module";
import { SyncEACToPlanNotificationWrapperComponent } from "./sync-eac-to-plan-notification-wrapper.component";


@NgModule({
    declarations: [
        SyncEACToPlanNotificationWrapperComponent
    ],
    imports: [
        NgbProgressbarModule,
        CommonComponentsModule,
        CommonModule,
        SyncEACToPlanNotificationV2Module
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
        DmGrmService,
        BulkUploadInternalEngagementService
    ],
    entryComponents: [
        SyncEACToPlanNotificationWrapperComponent
    ],
    exports: [
        SyncEACToPlanNotificationWrapperComponent
    ]
})

export class SyncEACToPlanNotificationWrapperModule { }
