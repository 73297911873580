<section class="requested-labor clear-fix dm-p-y-imp-10">
  <dm-loading [loadingText]="loadingMessage" [isCustomText]="true"
    [showLoading]="!formControlData && !formControlDataError" *ngIf="!formControlData">
  </dm-loading>
  <ng-container *ngIf="formControlDataError && !formControlData">
    <dm-service-error class="server-error" *ngIf="formControlDataError" [dataText]="formControlDataError"
      [showReloadMessage]="false" [ngbTooltipContent]="formControlDataError">
    </dm-service-error>
  </ng-container>
  <ng-container *ngIf="formControlData">
    <section class="justify-space-between">
      <section *ngIf="isEditable" class="dm-btn-holder dm-p-y-10">
        <p role="status" class="sr-only" aria-atomic="true">
          <span id="addRoleMsg"></span>
        </p>
        <button type="button" class="fxp-btn fxp-btn--primary dm-btn dm-btn--big" id="addNewRole"
          (click)="addNewRole()">
          <i class="icon icon-AddLegacy"></i>
          <span>Add new role </span>
        </button>
        <button class="fxp-btn fxp-btn--primary dm-btn dm-btn--big" id="adjustExistingRole" type="button"
          (click)="adjustExisting()">
          <i class="icon icon-AddLegacy"></i>
          <span>Adjust existing role </span>
        </button>
      </section>
      <section class="pull-right display-grid">
        <div class="font-caption-alt" style="font-style: italic;">Auto Populated Role<span
            class="color-legend auto-populated-role"></span>
        </div>
        <div class="font-caption-alt" style="font-style: italic;">Manually Added Role<span
            class="color-legend manually-added-role"></span>
        </div>
        <div class="font-caption-alt" style="font-style: italic;">Plan & Forecast Edited Role<span
            class="color-legend pnf-edit-role"></span>
        </div>
      </section>
    </section>
    <div *ngIf="isEditable">
      <span class="required-mark" aria-hidden="true">*</span>
      <span style="font-style: italic;">For T&M projects, only Non-Bill roles can be added or adjusted.</span>
      <br />
      <span style="font-style: italic;">
        <ng-container *ngIf="getExpiredProjects().size > 0">
          <span class="required-mark" aria-hidden="true">*</span>
          FCRs cannot be raised for the projects with past end dates, in case you want to extend the end date
          <span (click)="onEditProjectEndDate()" style="cursor: pointer; text-decoration: underline; color: blue;">
            click here
          </span>
          .
        </ng-container>
      </span>
    </div>
    <caption id="requestedLaborCaption" class="sr-only">Requested Labor</caption>
    <div class="roles-table__grid clear-fix" role="grid">
      <div role="row" class="roles-table__grid-row" aria-labelledby="requestedLaborCaption">
        <div role="gridcell" class="roles-table__grid-header-cell">EBS
          Description / ID
          <span class="required-mark" aria-hidden="true">*</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">Role
          <span class="required-mark" aria-hidden="true">*</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          Type of Contract
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          Billing Role ID
          <span class="required-mark" aria-hidden="true">*</span>
          <span>
            <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
              [ngbTooltipContent]="'For T&M Contract type FCR should only used for NB effort addition or changes.'"
              [dmIcon]="'icon icon-info'"
              ariaLabelToolTip="'For T&M Contract type FCR should only used for NB effort addition or changes.'">
            </dm-tooltip>
          </span>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          Resource Location
          <span class="required-mark" aria-hidden="true">*</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          Existing CFP Hours
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell text-align--right">
          Additional Hours
          <span>
            <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
              [ngbTooltipContent]="'Enter hours to add them, enter hours with a - (minus sign) to remove them.'"
              [dmIcon]="'icon icon-info'"
              ariaLabelToolTip="'Enter hours to add them, enter hours with a - (minus sign) to remove them.'">
            </dm-tooltip>
            <span class="required-mark dm-p-l-5" aria-hidden="true">*</span>
          </span>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          DB Cost & Hours
          <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
            [ngbTooltipContent]="' Existing Delivery Baseline (DB) Cost & Hours'" [dmIcon]="'icon icon-info'"
            ariaLabelToolTip="' Existing Delivery Baseline (DB) Cost & Hours'">
          </dm-tooltip>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          CFP Cost & Hours
          <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
            [ngbTooltipContent]="'Revised Current Financial Plan (CFP) Cost & Hours'" [dmIcon]="'icon icon-info'"
            ariaLabelToolTip="'Revised Current Financial Plan (CFP) Cost & Hours'">
          </dm-tooltip>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          Blended Cost Rate ({{formControlData.planCurrency}}/Hr)</div>
        <div role="gridcell" class="roles-table__grid-header-cell">
          Additional Cost ({{formControlData.planCurrency}})
          <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
            [ngbTooltipContent]="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'"
            [dmIcon]="'icon icon-info'"
            ariaLabelToolTip="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'">
          </dm-tooltip>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
        <div role="gridcell" class="roles-table__grid-header-cell" *ngIf="isEditable">
          Actions
        </div>
      </div>
      <ng-container *ngFor="let rlItem of value; trackBy: trackByFn; let i = index" class="ver-al-top roles-table">
        <div role="row" *ngIf="rlItem" class="roles-table__grid-row text-size"
          [ngClass]="{'bg-clr':rlItem.editModeEnabled}">
          <div role="gridcell" class="roles-table__grid-body-cell"
            [ngClass]="{ 'auto-populated-role': rlItem.isAutoPopulated && !rlItem.isPnfEdit, 'manually-added-role': !rlItem.isAutoPopulated, 'pnf-edit-role': rlItem.isAutoPopulated && rlItem.isPnfEdit }">
            <section *ngIf="!rlItem.editModeEnabled">
              <ng-container *ngIf="rlItem.currentState?.assignedTask" class="text-weight">
                <span class="text-weight">{{rlItem.currentState?.assignedTask?.projectName + ' - ' +
                  rlItem.currentState?.assignedTask?.serviceName}}</span>
                <br>
                <span class="color-07" [textContent]="rlItem.currentState?.assignedTask?.wbsl3Id"></span>
              </ng-container>
            </section>
            <section *ngIf="rlItem.editModeEnabled && !rlItem.isNewRequest && rlItem.currentState.existingDemand">
              <ng-container *ngIf="rlItem.currentState?.assignedTask" class="text-weight">
                <span class="text-weight">{{rlItem.currentState?.assignedTask?.projectName + ' - ' +
                  rlItem.currentState?.assignedTask?.serviceName}}</span>
                <br>
                <span class="color-07" [textContent]="rlItem.currentState?.assignedTask?.wbsl3Id"></span>
              </ng-container>
            </section>
            <section>
              <section class="input__select--icon" *ngIf="rlItem.isNewRequest && rlItem.editModeEnabled">
                <select id="addNewEBSId_{{i}}" class="input__select input__select--big font-caption-alt"
                  [disabled]="!rlItem.currentState.isCostRateResolved" aria-label="Add EBS ID"
                  [ngModel]="rlItem?.currentState.assignedTask"
                  (ngModelChange)="onSelectedTaskChange($event, rlItem, formControlData.engagementDetails, formControlData.nonBill, formControlData.roleValuesFF, formControlData.roleValuesTM)"
                  [name]="'assignedTask-' + rlItem.uuid" required>
                  <option *ngFor="let item of formControlData.existingTasks" [ngValue]="item"
                    [ngClass]="{'disabled-color': item.isCrPendingInProject || !validateProjectEndDate(item)}"
                    [disabled]="!validateProjectEndDate(item)"
                    [title]="!validateProjectEndDate(item) ? 'This project has an expired end date' : ''">
                    {{item.wbsl3Id}} | {{item.projectName}} - {{item.serviceName}}
                  </option>
                </select>
              </section>
              <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
                *ngIf="rlItem.currentState.isCrPendingInProject">
                {{fcrRolesErrorMessages.PendingCrInProject}}
              </p>
            </section>
          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <section *ngIf="!rlItem.editModeEnabled">
              <span
                *ngIf="!rlItem.currentState.existingDemand || !rlItem.currentState.role || rlItem.isRoleOrResourceLocationChanged">
                {{crDemandService.getRoleName(rlItem) | titlecase}}
                <p *ngIf="rlItem.currentState?.existingDemand?.demandId">Demand ID:
                  {{rlItem.currentState.existingDemand.demandId}}</p>
              </span>

              <span
                *ngIf="resourceLocationAndRoleEditFeatureFlag && rlItem.currentState.existingDemand && rlItem.currentState.role && (rlItem.currentState.role.roleName.toLowerCase() !== rlItem.currentState.existingDemand.roleDescription.toLowerCase())">
                {{rlItem.currentState.existingDemand.roleDescription | titlecase}}
                <br>
                ->
                {{rlItem.currentState.role.roleName}}
                <p *ngIf="rlItem.currentState?.existingDemand?.demandId">Demand ID:
                  {{rlItem.currentState.existingDemand.demandId}}</p>
              </span>
            </section>
            <section class="input__select--icon"
              *ngIf="rlItem.isNewRequest && rlItem.editModeEnabled && rlItem.currentState.assignedTask">
              <select id="addNewRoleName_{{i}}" class="input__select input__select--big font-caption-alt"
                [disabled]="!rlItem.currentState.isCostRateResolved" aria-label="Add Role Name"
                [ngModel]="rlItem.currentState.role" (ngModelChange)="onRoleChange($event, rlItem)"
                [name]="'role-' + rlItem.uuid" required>
                <option *ngFor="let role of rlItem.currentState.roleValues | orderBy : 'roleName'" [ngValue]="role">
                  {{role.roleName}}
                </option>
              </select>
            </section>

            <section *ngIf="!rlItem.isNewRequest && rlItem.editModeEnabled">
              <section class="input__select--icon">

                <select id="adjustExistingRole_{{i}}" class="input__select input__select--big font-caption-alt"
                  aria-label="Role Description" [ngModel]="rlItem.currentState.existingDemand"
                  [name]="'existingResource-' + rlItem.uuid"
                  (ngModelChange)="onExistingDemandChange($event, rlItem, formControlData.engagementDetails, formControlData.billRates, formControlData.roleValuesFF, formControlData.roleValuesTM)">
                  <option
                    *ngFor="let existingResource of getUndisplayedExistingResourcesPlusCurrent(rlItem.currentState.existingDemand) | orderBy : 'role'"
                    [ngValue]="existingResource"
                    [ngClass]="{'disabled-color': existingResource.isCrPendingInProject || !validateResourceProjectEndDate(existingResource.taskId)}"
                    [disabled]="!validateResourceProjectEndDate(existingResource.taskId)"
                    [title]="!validateResourceProjectEndDate(existingResource.taskId) ? 'This project has an expired end date' : ''">
                    <div>
                      {{existingResource.roleDescription}} | Demand ID:
                      {{existingResource.demandId}} | Task:
                      {{existingResource.taskId}}
                    </div>
                  </option>
                </select>

              </section>
              <ng-container *ngIf="resourceLocationAndRoleEditFeatureFlag && rlItem.currentState.existingDemand">
                <span class="dm-task-data--old-role">Existing</span>
                <br>
                <section class="input__select--icon">
                  <select id="changeExistingRole_{{i}}" class="input__select input__select--big font-caption-alt"
                    [disabled]="!rlItem.currentState.isCostRateResolved" aria-label="Change Role"
                    [ngModel]="rlItem.currentState.role" (ngModelChange)="onRoleChange($event, rlItem)"
                    [name]="'role-' + rlItem.uuid" required>
                    <option *ngFor="let role of rlItem.currentState.roleValues | orderBy : 'roleName'" [ngValue]="role">
                      {{role.roleName}}
                    </option>
                  </select>
                </section>
                <span class="dm-task-data--new-role">New</span>
              </ng-container>
            </section>
          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <span *ngIf="rlItem.currentState.assignedTask" class="dm-task-data--contract-type"
              [ngClass]="rlItem.currentState.assignedTask && rlItem.currentState.assignedTask?.isFixedFeeProject ? 'contract-type-ff' : 'contract-type-tm'">{{
              rlItem.currentState.assignedTask?.isFixedFeeProject ? "FF" : "T&amp;M"}}</span>
          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <section *ngIf="!rlItem.editModeEnabled">
              {{rlItem.currentState.existingDemand ? rlItem.currentState.existingDemand?.billingRoleId :
              rlItem.currentState.billingInfo?.billingRoleId}}
            </section>
            <section class="input__select--icon"
              *ngIf="rlItem.editModeEnabled && rlItem.isNewRequest && rlItem.currentState.assignedTask?.isFixedFeeProject && !rlItem.currentState.isProjectPFPOrPFF">
              <select id="editNewBillingRoleId_{{i}}" class="input__select input__select--big font-caption-alt"
                aria-label="Edit Billing Role ID" [ngModel]="rlItem.currentState.billingInfo"
                (ngModelChange)="onBillingRoleChange($event, rlItem)" [name]="'billingRoleId-' + rlItem.uuid">
                <option *ngFor="let item of formControlData.FFBillRates" [ngValue]="item">
                  {{item.billingRoleId}}
                  <span *ngIf="item.description">|</span>
                  {{item.description | titlecase}}
                </option>
              </select>
            </section>
            <section
              *ngIf="rlItem.editModeEnabled && rlItem.isNewRequest && !rlItem.currentState.assignedTask?.isFixedFeeProject">
              {{formControlData.nonBill.billingRoleId}}
            </section>
            <section
              *ngIf="rlItem.editModeEnabled && !rlItem.isNewRequest && rlItem.currentState.existingDemand && (!rlItem.currentState.isProjectPFPOrPFF || rlItem.currentState.isProjectPFPOrPFF)">
              {{rlItem.currentState.existingDemand ? rlItem.currentState.existingDemand.billingRoleId :
              rlItem.currentState.billingInfo.billingRoleId}}
            </section>
          </div>
          <div role="gridcell" class="roles-table__grid-body-cell">
            <section class="input__select--icon"
              *ngIf="rlItem.editModeEnabled && ((rlItem.isNewRequest && rlItem.currentState.assignedTask) || (resourceLocationAndRoleEditFeatureFlag && rlItem.currentState.existingDemand))">
              <select id="addResourceLocation_{{i}}" class="input__select input__select--big font-caption-alt"
                aria-label="Add Resource Location" (ngModelChange)="onResourceLocationChange($event, rlItem)"
                [ngModel]="rlItem.currentState.resourceLocationKey" [name]="'resourceLocation-' + rlItem.uuid" required>
                <option *ngFor="let company of companyCodeList" [ngValue]="company.companyCode">
                  {{ company.companyCode }}-{{ company.companyName }}
                </option>
              </select>
            </section>
            <section
              *ngIf="!rlItem.editModeEnabled || (!resourceLocationAndRoleEditFeatureFlag && rlItem.editModeEnabled && rlItem.currentState.existingDemand)"
              [textContent]="rlItem.currentState.resourceLocationKey"></section>
          </div>
          <div role="gridcell" class="roles-table__grid-body-cell">
            <section>
              {{ rlItem?.currentState.existingDemand ? rlItem?.currentState.existingDemand.planHours : 0}}
            </section>
          </div>
          <div role="gridcell" class="roles-table__grid-body-cell">
            <section *ngIf="!rlItem.editModeEnabled" style="float:right;" [textContent]="rlItem?.currentState.hours">
            </section>
            <section class="rr-input--text"
              *ngIf="rlItem.editModeEnabled && (rlItem.currentState.assignedTask || rlItem.currentState.existingDemand)"
              style="float:right;">
              <input type="text" id="addAdditionalHours_{{i}}" autocomplete="off" aria-label="Add Additional Hours"
                class="input_text_edge" required dmLimitNumeric wholeNumberLength="9"
                [disabled]="!rlItem.currentState.isCostRateResolved" [(ngModel)]="rlItem.currentState.hours"
                [name]="'additionalHours-' + rlItem.uuid" (ngModelChange)="onAdditionalHoursChange(rlItem, i)">
              <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
                *ngIf="!rlItem.currentState.isAdditionalTimeValid">
                {{fcrRolesErrorMessages.AdditionalTimeErrorMessage}}
              </p>
              <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
                *ngIf="!rlItem.currentState.isAdditionalHoursQuantityAllowed">
                {{fcrRolesErrorMessages.AdditionalHoursQuantityAllowed}}
              </p>
              <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
                *ngIf="rlItem.currentState.isNewHoursLessThanStaffedHours">
                {{fcrRolesErrorMessages.AdditionalHoursCantBeLessThanStaffedHours}}
                ({{rlItem.currentState?.existingDemand?.staffedHours}} Hours).
              </p>
            </section>
          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <section *ngIf="rlItem.currentState.existingDemand">
              {{rlItem.currentState.existingDemand?.dbCost | number : '1.2-2'}}
              {{rlItem.currentState.existingDemand?.dbCost ? formControlData.planCurrency : ""}}

              <p *ngIf="rlItem.currentState.existingDemand?.dbHours">
                {{rlItem.currentState.existingDemand?.dbHours | number : '1.2-2'}} Hours</p>
            </section>

          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <section *ngIf="rlItem.currentState.isCostRateResolved">
              {{rlItem.isDbOnlyDemand ? (0 | number : '1.2-2') : ((+rlItem.currentState.blendedCostRate *
              (+rlItem.currentState.existingDemand?.planHours + +rlItem.currentState.hours)) | number : '1.2-2')}}
              {{formControlData.planCurrency}}
              <p>
                {{rlItem.isDbOnlyDemand ? (0 | number : '1.2-2') : ((+rlItem.currentState.existingDemand?.planHours +
                +rlItem.currentState.hours) | number : '1.2-2')}}
                Hours
              </p>
            </section>
            <section *ngIf="!rlItem.currentState.isCostRateResolved">
              <i title="Calculating Cost Rate..." class="glyphicon glyphicon-refresh glyphicon-spin"></i>
            </section>
          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <ng-container *ngIf="rlItem.isSubconRole; else nonSubconBlock">
              <section class="subcon-costrate-input--text" *ngIf="rlItem.editModeEnabled && rlItem.isNewRequest">
                <input id="customSubconCostRate_{{i}}" type="number" [attr.autocomplete]="'off'"
                  aria-label="Enter Subcontractor Cost Rate" class="input_text_edge" required
                  appTwoDigitDecimalPositiveNumber [attr.list]="'subconCostRates' + i"
                  [ngModel]="rlItem.currentState.blendedCostRate" [name]="'customCostRate-' + rlItem.uuid"
                  (ngModelChange)="onSubconCostRateChange($event, rlItem, i)">
                <select [name]="'customCostRate-' + rlItem.uuid" [ngModel]="rlItem.currentState.blendedCostRate"
                  (ngModelChange)="onSubconCostRateChange($event, rlItem, i)"
                  aria-label="Select Subcontractor Rate From List">
                  <option *ngFor="let costRate of subconCostRates" [ngValue]="costRate">{{costRate}}</option>
                </select>
              </section>
              <section *ngIf="!rlItem.editModeEnabled || !rlItem.isNewRequest">
                {{+rlItem.currentState.blendedCostRate | number : '1.2-2'}}
              </section>
            </ng-container>
            <ng-template #nonSubconBlock>
              <section *ngIf="rlItem.currentState.isCostRateResolved">
                {{+rlItem.currentState.blendedCostRate | number : '1.2-2'}}
              </section>
              <section *ngIf="!rlItem.currentState.isCostRateResolved">
                <i title="Calculating Cost Rate..." class="glyphicon glyphicon-refresh glyphicon-spin"></i>
              </section>
            </ng-template>
          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <section *ngIf="rlItem.currentState.isCostRateResolved">
              {{(rlItem.isDbOnlyDemand ? 0 : (+rlItem.currentState.blendedCostRate *
              (+rlItem.currentState.existingDemand?.planHours + +rlItem.currentState.hours))) -
              (rlItem.currentState.existingDemand?.dbCost || 0) | number : '1.2-2'}}
            </section>
            <section *ngIf="!rlItem.currentState.isCostRateResolved">
              <i title="Calculating Cost Rate..." class="glyphicon glyphicon-refresh glyphicon-spin"></i>
            </section>
          </div>

          <div role="gridcell" class="roles-table__grid-body-cell">
            <p role="status" class="sr-only" aria-atomic="true">
              <span id="saveRoleMsg"></span>
            </p>
            <div class="text-left font-caption-alt" *ngIf="!rlItem.editModeEnabled && isEditable">
              <span class="sr-only">Actions</span>
              <button *ngIf="!rlItem.isDbOnlyDemand" type="button" id="editBtn_{{i}}" aria-label="Edit"
                class="action-btn add-btn font-caption-alt set-focus" (click)="onEditClick(rlItem, i)">
                <i class="icon icon-editLegacy"></i>
                <span>Edit</span>
              </button>
              <dm-tooltip *ngIf="rlItem.isDbOnlyDemand" role="tooltip" class="dm-tooltipinline requested-role-tooltip"
                [dmPlacement]="'left'" [dmTriggers]="'hover'"
                [ngbTooltipContent]="'This autopopulated demand cannot be edited. This demand was autopopulated to reconcile DB and CFP for a demand which exists on Delivery Baseline but does not exist on Current Financial Plan. This will remove the demand in Delivery Baseline.'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="' Delivery Baseline (DB) Demand'">
              </dm-tooltip>
              <button type="button" id="deleteBtn_{{i}}" title="Delete" class="action-btn icon-close set-focus"
                [disabled]="rlItem.isPnfEdit || rlItem.isAutoPopulated" (click)="deleteRole($event, i);">
                <i class="icon icon-delete"></i>
                <span aria-hidden="true">Delete</span>
              </button>
              <span class="sr-only">press tab to navigate to delete button</span>
            </div>
            <div class="text-left font-caption-alt" *ngIf="(rlItem.editModeEnabled) && isEditable">
              <button type="button" id="saveBtn_{{i}}" aria-label="Save"
                class="action-btn add-btn font-caption-alt set-focus" [disabled]="!rlItem.isSaveable"
                (click)="saveRequest(i);">
                <i class="icon icon-save"></i>
                <span>Save<span class="required-field">*</span></span>
              </button>
              <button type="button" id="cancelBtn_{{i}}" aria-label="Cancel" class="action-btn icon-close set-focus"
                (click)="rlItem.editModeEnabled=false; discardChanges($event, i)">
                <i class="icon icon-close"></i>
                <span>Cancel</span>
              </button>
              <span class="sr-only">press tab to navigate to cancel button</span>
              <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
                *ngIf="!rlItem.currentState.isContractTypeValid">
                {{invalidContractTypeMessage}}
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <div role="row" class="roles-table__grid-row text-left text-size" aria-labelledby="requestedLaborCaption">
        <div role="gridcell" class="roles-table__grid-body-cell" style="font-weight:bold;">
          TOTALS
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell">
          <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell">
          <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell">
          <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell">
          <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell" style="font-weight:bold;">
          {{existingHours | number : '1.0-0'}}
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell text-align--right" style="font-weight:bold;">
          {{additionalHours | number : '1.2-2'}}
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell">
          <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell">
          <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell">
          <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell" style="font-weight:bold;">
          {{additionalCost | number : '1.2-2'}}
        </div>
        <div role="gridcell" class="roles-table__grid-body-cell" *ngIf="isEditable"></div>
      </div>
    </div>
  </ng-container>
  <span class="sr-only" tabindex="-1" role="alert" *ngIf="statusMessage">{{statusMessage}}</span>
</section>