import { Inject, Injectable } from "@angular/core";
import { ConfigManagerService } from "./configmanager.service";
import { Services, SourceConstants } from "../application.constants";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";
import { EngagementDetailService } from "./engagement-detail.service";
import { IContractType } from "./contracts/project.service.contracts";
import { IProjectDetailsV2, ITeamDetailsV2, IWbsDetailsV2Model } from "./contracts/wbs-details-v2.contracts";
import { IProjectDetailsViewModel } from "../../components/project-detail/project.model";
import { IProjectEngagementDetails } from "./contracts/cache.contracts";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { ErrorSeverityLevel } from "@fxp/fxpservices";

@Injectable()
export class ProjectDetailService extends DmServiceAbstract {

    private lockedUserStatus: string;

    public constructor(
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
    ) {
        super(dmLogger, Services.ProjectDetailService );
        this.lockedUserStatus = this.configurationService.getValue<string>("lockedUserStatusCode");
    }

    /**
     * Gets the full project details by grabbing the full engagement details and filtering for the selected project ID.
     */
    public getProjectFullDetails(projectId: string): Promise<IProjectEngagementDetails> {
        return this.engagementDetailService.getWbsFullDetailsV2(projectId, true, true, true, true, true)
            .then((wbs: IWbsDetailsV2Model) => {
                const projectFullDetails: IProjectDetailsV2 = wbs.projectData || (wbs.engagementData && wbs.engagementData.projects ? wbs.engagementData.projects[0] : undefined);
                const projectEngDetails: IProjectEngagementDetails = {
                    projectFullDetails,
                    engagementFullDetails: wbs.engagementData // todo is this even used?
                };
                return projectEngDetails;
            }).catch((error) => { /* Format the error*/
                const errorMessage: string = "Unable to retrieve Project Details.";
                if (error) { /* error has already been formatted upstream, can just bubble it up */
                    /* Pass in error info to the log method below, once the error is formatted*/
                    this.logError(SourceConstants.Method.GetProjectFullDetails, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return Promise.reject(error);
                }
                const message = this.sharedFunctionService.getErrorMessage(error, errorMessage);
                this.logError(SourceConstants.Method.GetProjectFullDetails, error, message, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.reject(errorMessage +
                    " Correlation ID: " + DataService.getCorrelationIdFromError(error));

            });
    }

    /**
     * Builds the project details view model object by parsing the wanted values off the project
     * full details.
     * @param projectFullDetails
     */
    public getProjectDetails(projectFullDetails: IProjectDetailsV2): IProjectDetailsViewModel {
        if (!projectFullDetails) {
            return undefined;
        }
        const contractTypeList = this.configurationService.getValue<IContractType[]>("projEngContractType");
        const pPjmObj: ITeamDetailsV2[] = this.sharedFunctionService.getPjmInfoL1("PPJM", projectFullDetails);
        const pjmObj: ITeamDetailsV2[] = this.sharedFunctionService.getPjmInfoL1("PJM", projectFullDetails);
        const delegatedPjmObj: ITeamDetailsV2[] = this.sharedFunctionService.getPjmInfoL1("DPJM", projectFullDetails);
        const relManager: ITeamDetailsV2[] = this.sharedFunctionService.getPjmInfoL1("RELMAN", projectFullDetails);

        const projectDetails: IProjectDetailsViewModel = {
            actualEndDate: projectFullDetails.endDate,
            actualStartDate: projectFullDetails.startDate,
            contractType: projectFullDetails.contractType,
            customerName: projectFullDetails.customerName,
            dealId: projectFullDetails.dealId,
            delegatedPjMbpId: delegatedPjmObj && delegatedPjmObj[0] ? delegatedPjmObj[0].bpid : undefined,
            delegatedPjMName: delegatedPjmObj && delegatedPjmObj[0] ? delegatedPjmObj[0].name : undefined,
            description: projectFullDetails.description,
            domain: projectFullDetails.primaryDomain,
            engagementId: projectFullDetails.engagementId,
            isConfidentialDeal: projectFullDetails.isConfidentialDeal,
            isPublicSector: projectFullDetails.isPublicSector,
            isUsPubSec: projectFullDetails.isUsPubSec,
            isMarkedForDeletion: projectFullDetails.userStatusCode && projectFullDetails.userStatusCode.toUpperCase().includes("MDL") ? true : false,
            hasUnitBasedDemands: projectFullDetails.hasUnitBasedDemands,
            name: projectFullDetails.name,
            originalEndDate: projectFullDetails.originalEndDate,
            originalStartDate: projectFullDetails.originalStartDate,
            pjMAlias: pjmObj && pjmObj[0] ? pjmObj[0].alias : undefined, // we can get these from the object directrly
            pjMbpId: pjmObj && pjmObj[0] ? pjmObj[0].bpid : undefined,
            pjMName: pjmObj && pjmObj[0] ? pjmObj[0].name : undefined,
            pPjMAlias: pPjmObj && pPjmObj[0] ? pPjmObj[0].alias : undefined,
            projectId: projectFullDetails.id,
            services: [],
            status: projectFullDetails.statusDescription,
            relationshipManagerAlias: relManager && relManager[0] ? relManager[0].alias : undefined,
            relationshipManagerName: relManager && relManager[0] ? relManager[0].name : undefined,
            typeColorCode: this.engagementDetailService.getTypeColorCode(contractTypeList, projectFullDetails.contractType),
            currentStatus: projectFullDetails.currentStatus,
            currentStatusCode: projectFullDetails.currentStatusCode
        };

        for (const service of projectFullDetails.services) {
            projectDetails.services.push({
                serviceDescription: service.description,
                startDate: service.startDate,
                endDate: service.endDate,
                projectId: projectFullDetails.id,
                serviceId: service.id,
                statusDescription: (service.userStatusCode && service.userStatusCode === this.lockedUserStatus) ? service.statusDescription + " - " + service.userStatusDescription : service.statusDescription,
                serviceType: service.serviceType,
                serviceName: service.name,
                currentStatus: service.currentStatus,
                currentStatusCode: service.currentStatusCode
            });
        }
        return projectDetails;
    }

}
