<section class="dm-tile--p-l-r-0 dm-p-t-10"
    [ngClass]="isPoccIndicatorApplicable ? 'col-md-6 col-sm-6 col-xs-12' : 'col-md-12 col-sm-12 col-xs-12 align-auto'">
    <section class="max-width-273">
        <div>
            <p class="font-caption-alt">Cost Consumed of Estimate at Complete (EAC)</p>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 cceac--graph-wrapper dm-tile--p-l-0">
            <dm-cceac [isInternalEngagement]="isInternalEngagement" [values]="cceacValues"></dm-cceac>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 cceac--reference dm-tile--p-l-r-0">
            <div class="col-lg-5 col-md-3 col-sm-5 col-xs-4">
                <p class="font-caption-alt"><span class="cceac--reference--consumed"></span>Consumed</p>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-8 dm-tile__nopad">
                <p class="font-caption-alt disp-flex"><span class="cceac--reference--eac disp-flex__inline"></span><span
                        class="disp-flex__inline">Estimate at Complete (EAC)</span></p>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 font-caption-alt">
            <ul class="cceac--reference-list dm-tile--p-l-r-0">
                <li>
                    <div>
                        <p class="magnifying-glass cceac--reference-list-item"></p>
                        <p class="cceac--reference-list-text">Current Financial Plan</p>
                    </div>
                </li>
                <li>
                    <div>
                        <p class="diamond-narrow cceac--reference-list-item"></p>
                        <p class="cceac--reference-list-text">Delivery Baseline</p>
                    </div>
                </li>
                <li>
                    <div>
                        <p class="talkbubble cceac--reference-list-item"></p>
                        <p class="cceac--reference-list-text">Contract Baseline</p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</section>
<section class="col-md-6 col-sm-6 col-xs-12 flex-disp dm-p-t-10" *ngIf="isPoccIndicatorApplicable ">
    <div class="text-center">
        <p class="font-caption-alt">Percentage of Revenue Recognition (FF and ECIF only)</p>
    </div>
    <dm-circular-progress [class]="['disp-contents']" [percent]="POCCPercentage" [toFixed] = "1" [subtitle]="'Revenue Recognition'"
        [radius]="65" [space]="-15" [outerStrokeWidth]="15" [innerStrokeWidth]="15" [outerStrokeColor]="'#33CCCC'"
        [innerStrokeColor]="'#e7e8ea'" [subtitleFontSize]="10" [valueFontSize]="15" [subtitleDistance]="14">
    </dm-circular-progress>
    <section class="info__item">
        <section class="info__legend">
        </section>
        <section class="info__description">
            Revenue
            {{revenueAmountRecognized | currency: planCurrency}}
        </section>
    </section>
</section>
