import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditRiskReserveModalComponent } from "./edit-risk-reserve-modal.component";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmDisplayDateOrDashPipe } from "../../../../../common/services/filters/display-date-or-dash.pipe";
import { ProjectService } from "../../../../../common/services/project.service";
import { DmModalV2Module } from "../../../../modals/dm-modal-v2/dm-modal-v2.module";
import { TimelineModule } from "../../../../../components/shared/timeline/timeline.module";
import { ChangeRequestService } from "../../../../../common/services/change-request.service";
import { AADGraphService } from "../../../../../common/services/aad-graphapi.service";
import { OneProfileService } from "../../../../../common/services/one-profile.service";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { DmNotificationService } from "../../../../../common/services/dm-notification.service";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";

@NgModule({
    declarations: [
        EditRiskReserveModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        DmModalV2Module,
        TimelineModule,
    ],
    providers: [
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        ProjectService,
        OneProfileService,
        AADGraphService,
        ChangeRequestService,
        DmNotificationService,
        DMAuthorizationService,
        ProjectServiceFunctions
    ],
    entryComponents: [
        EditRiskReserveModalComponent,
    ],
    exports: [
        EditRiskReserveModalComponent,
    ]
})
export class EditRiskReserveModalModule { }
