import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";

import { catchError, map, switchMap } from "rxjs/operators";
import { from as fromPromise, of } from "rxjs";
import { IExistingCrRequest } from "../../common/services/contracts/changerequest.contract";
import { CrIdRolesActionTypes, LoadCrIdRoles, LoadCrIdRolesSuccess, LoadCrIdRolesFail } from "./cr-id-roles.action";
import { DataService } from "../../common/services/data.service";
import { ChangeRequestService } from "../../common/services/change-request.service";

@Injectable()
export class CrIdRolesEffects {

    @Effect()
    public loadCrIdRoles$ = this.actions$
        .ofType(CrIdRolesActionTypes.LOAD_CRID_ROLES)
        .pipe(
            switchMap((action: LoadCrIdRoles) =>
                fromPromise(this.changeRequestService.getRolesByCrId(action.crId, action.allowCrEdit))
                    .pipe(
                        map((existingCrRequest: IExistingCrRequest) => new LoadCrIdRolesSuccess(action.crId, existingCrRequest)),
                        catchError((error) => of(new LoadCrIdRolesFail(action.crId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                    )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(ChangeRequestService) private changeRequestService: ChangeRequestService
    ) { }
}
