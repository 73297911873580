import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { IOneProfileCompanyCodeAttrV2 } from "../../components/tiles/type-ahead-companycode/type-ahead-companycode-contracts";
  
/* Checks the value of the dropdown value, returns validation error if value = select */
export function CompanyCodeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: ValidationErrors} | null => {
        const value: IOneProfileCompanyCodeAttrV2 = control.value;
        if (value && !value.companyCode) {
            return { "Invalid CompanyCode": { value: control.value } };
        } else {
            return null;
        }      
    };
}