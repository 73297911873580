import * as $ from "jquery";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "dm-pagination",
    templateUrl: "./dmpagination.html",
    styleUrls: ["dmpagination.scss"]
})
export class PaginationComponent implements OnInit {
    @Input() public portfolioItemsDisp: number;
    @Input() public currentPage: number;
    @Input() public portfolioDataLengthAfterFilter: number;
    @Output() public currentPageChanged: EventEmitter<number> = new EventEmitter();
    public pageChangeInformation: string;

    public ngOnInit(): void {
        if (!this.portfolioItemsDisp) {
            this.portfolioItemsDisp = 6;
        }
    }
    /**
     * Get the number of pages based on the length filter and number of items displayed
     */
    public numberOfPages(): number {
        return Math.ceil(this.portfolioDataLengthAfterFilter / this.portfolioItemsDisp);
    }

    /**
     * Move to next page of results
     * @param event
     */
    public nextPage(event: MouseEvent): void {
        if (this.currentPage < this.numberOfPages()) {
            this.currentPage = Number(this.currentPage) + 1;
            this.currentPageChanged.emit(this.currentPage);
            this.pageChangeInformation = "Next button";
        }
        if (this.currentPage >= (this.portfolioDataLengthAfterFilter / this.portfolioItemsDisp)) {
            setTimeout(() => {
                $(event.target).closest(".paginationSection").find(".dmpageprev").focus();    
            });
        }
    }

    
    /**
     * Move to previous page of results
     * @param event
     */
    public prevPage(event: MouseEvent): void {
        if (this.currentPage > 1) {
            this.currentPage = Number(this.currentPage) - 1;
            this.currentPageChanged.emit(this.currentPage);
            this.pageChangeInformation = "Previous button";
           
        }
        if (this.currentPage === 1) {
            setTimeout(() => {
                $(event.target).closest(".paginationSection").find(".dmpagenext").focus();
            });
        }
    }

    
    public buttonType(): string {
        return this.pageChangeInformation;
    }

    /**
     * Reset back to page current page 1 in instances of impossible paging
     */
    public pageNumberChange(): void {
        if (this.currentPage <= 0 || isNaN(this.currentPage) || (this.currentPage > this.numberOfPages())) {
            this.currentPage = 1;
            this.currentPageChanged.emit(this.currentPage);
        }
    }

}
