<div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-9">
                <h1>Unauthorized</h1>
                <p>
                    You are not authorized to view this page. If you believe you should have access, contact your
                    administrator for assistance.
                </p>
            </div>
        </div>
    </div>
</div>