import { Component, forwardRef, Inject, Input, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { AccessibilityConstants, Components, FinancialType, SourceConstants } from "../../../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IModal } from "../../../../modals/dm-modal-v2/dm-modal-v2.component";
import { IApprovedFinancialsResponseV2, ICrAttachment, IProjectApprovedFinancial, IRiskReserveRequest, ITaskApprovedFinancial, ITaskRRDetailsRequest } from "../../../../../common/services/contracts/changerequest.contract";
import { ChangeRequestService } from "../../../../../common/services/change-request.service";
import { IAuditResponse, IRiskReserveAuditValues } from "../../../../../common/services/contracts/audit.contracts";
import { DomSanitizer } from "@angular/platform-browser";
import moment from "moment";
import { AADGraphService } from "../../../../../common/services/aad-graphapi.service";
import { OneProfileService } from "../../../../../common/services/one-profile.service";
import { AuditService } from "../../../../../common/services/audit.service";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { IFinancialDetailsV2State } from "../../../../../store/financial-details-v2/financial-details-v2.reducer";
import { getEntireFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.selector";
import { IState } from "../../../../../store/reducers";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { combineLatest as observableCombineLatest } from "rxjs";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { DmError } from "../../../../../common/error.constants";
import { v4 as uuid } from "uuid";
import { NotificationType } from "../../../../../common/services/contracts/notification-bar.contracts";
import { DmNotificationService } from "../../../../../common/services/dm-notification.service";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";
import { StateService } from "@uirouter/angular";
import { IDmTab } from "../../../../../common/services/contracts/common.contracts";
@Component({
    selector: "dm-edit-risk-reserve-modal",
    templateUrl: "./edit-risk-reserve-modal.html",
    styleUrls: ["./edit-risk-reserve-modal.scss"]
})
export class EditRiskReserveModalComponent extends DmModalAbstract {
    @Input() public wbsId: string;
    @Input() public currency: string;
    @Input() public isProjectContext: boolean;
    @Output() public onStatusModalClose: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild("uploadRRAttachmentFileInput", { static: false }) public uploadRRAttachmentFileInput: ElementRef;
    public modalContent: IModal;
    public isLoadingRiskReserveDetails: boolean;
    public tabsContent: IDmTab[];
    public isEditClicked: boolean;
    public showAudit: boolean;
    public showRiskReserve: boolean;
    public auditHistoryData: any;
    public riskReserveDetailsList: IApprovedFinancialsResponseV2;
    public isServerError: boolean;
    public uploadedFileName: string;
    public uploadedFileObj: any;
    public showFileUploadValidationMessage: boolean = false;
    public showFileUploadApiValidationMessage: boolean = false;
    public uploadApiValidationErrorMessages: string[];
    public warningMessage: string;
    public hasEditPermissions: boolean;
    public loadingText: string;
    public isSubmitBtnDisabled: boolean = true;
    public showFileUploadSuccessMessage: boolean = false;
    public noTransactionsText: string;
    public showNoRiskReserveDetailsMessage: boolean;
    public accessibilityConstants = AccessibilityConstants;
    private approvedFinancialsResponse: IApprovedFinancialsResponseV2;
    private readonly FXP_CONSTANTS = FxpConstants;
    private originalProjectApprovedFinancials: IProjectApprovedFinancial[];
    private updatedProjectApprovedFinancials: IProjectApprovedFinancial[];
    private uploadedFileAttachmentResponse: ICrAttachment;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ChangeRequestService) public changeRequestService: ChangeRequestService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(AuditService) private auditService: AuditService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(activeModal, dmLogger, Components.EditRiskReserveModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title : "Edit Risk Reserve Details",
            subTitle : `${this.wbsId}` 
        };

        this.tabsContent = [
            {
                displayName: "Risk Reserve",
                id: "RISKRESERVE",
                isActive: true
            },
            {
                displayName: "Change History",
                id: "AUDIT",
                isActive: false
            }
        ];

        this.loadingText = "Loading Risk Reserve Details";
        this.noTransactionsText = "No risk reserve details found";
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.wbsId));
        const financialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.wbsId));

        observableCombineLatest(
            financialDetails$,
            engagementDetails$,
            (
                financialDetails: IFinancialDetailsV2State,
                engagementDetails: IEngagementDetailsState,
            ) => {
                return ({
                    financialDetails,
                    engagementDetails,
                });
            }
        ).pipe(untilDestroyed(this))
            .subscribe(({
                financialDetails,
                engagementDetails,
            }) => {
                this.refreshOnItemInvalidation(financialDetails, engagementDetails);
                this.setLoadersBasedOnItemState(financialDetails, engagementDetails);
                this.setErrorsBasedOnItemState(financialDetails, engagementDetails);
                this.showRiskReserve = true;
                if (engagementDetails.loaded) {
                    // Get Risk Reserve Approved financials data
                    this.isLoadingRiskReserveDetails = true;
                    const engagementDetailsList: IEngagementDetailsV2 = engagementDetails.engagementDetails;
                    let filteredProjectDetails: IProjectDetailsV2;
                    if (engagementDetailsList && engagementDetailsList.projects && engagementDetailsList.projects.length) {
                        filteredProjectDetails = engagementDetailsList.projects.filter((proj) => proj.id === this.wbsId)[0];
                    }        
                    this.hasEditPermissions = this.isProjectContext ? filteredProjectDetails && filteredProjectDetails.canEditProject : engagementDetailsList.canEditEnagagement;
                    this.changeRequestService.getApprovedFinancialsV2(this.wbsId).then((response: IApprovedFinancialsResponseV2) => {    
                        this.approvedFinancialsResponse = response;             
                        this.riskReserveDetailsList = { engagementId: response.engagementId, projects: response.projects.filter((m) => m.version === FinancialType.CurrentFinancialPlan)};
                        this.riskReserveDetailsList.projects = this.riskReserveDetailsList.projects.filter((project) => project.tasks && project.tasks.length > 0);
                        this.showNoRiskReserveDetailsMessage = this.riskReserveDetailsList.projects && this.riskReserveDetailsList.projects.length <= 0 ? true : false;                      
                        this.originalProjectApprovedFinancials = response.projects;
                        this.isLoadingRiskReserveDetails = false;                        
                        for (const projectRR of this.riskReserveDetailsList.projects) {
                            projectRR.name = this.getWbsName(projectRR.wbsId, engagementDetailsList);
                            projectRR.isExpanded = true;
                            projectRR.tasks = projectRR.tasks.filter((m) => m.version === +FinancialType.CurrentFinancialPlan);
                            for (const taskRR of projectRR.tasks) {                              
                                taskRR.name = this.getWbsName(taskRR.wbsId, engagementDetailsList);
                            }
                        }    
                    }).catch(() => {
                        this.isLoadingRiskReserveDetails = false;
                    });                              
                }   
                if (engagementDetails.error) {
                    this.isServerError = true;
                }
            });
    }

    /**
     * File upload on button click
     */
    public uploadFileBtnClick(): void {
        this.uploadRRAttachmentFileInput.nativeElement.click();
        this.showFileUploadApiValidationMessage = false;
    }

    /**
     * To get the uploaded excel file from user upload.
     * @param fileInput
     */
    public onFileUpload(fileInput: Event): void {
        this.uploadedFileName = "";
        const fileObject = (fileInput.target as HTMLInputElement).files[0];
        this.uploadedFileObj = fileObject;
        this.uploadedFileName = fileObject.name;
        this.uploadFileAttachment();
    }

    /**
     * Uploads Risk reserve file attachment
     *
     * @memberof EditRiskReserveModalComponent
     */
    public uploadFileAttachment(): void {
        const formData = new FormData();
        formData.append("file", this.uploadedFileObj);
        if (this.uploadedFileObj && formData){
            this.changeRequestService.uploadRRFileAttachment(formData).then((response: ICrAttachment) => {
                this.uploadedFileAttachmentResponse = response;
                this.showFileUploadSuccessMessage = true;
                this.isLoadingRiskReserveDetails = false;
                this.isSubmitBtnDisabled = false;
            }).catch((error: any) => {
                this.showFileUploadApiValidationMessage = true;
                this.warningMessage = DmError.InternalEngagement.FileUpload;
                this.uploadApiValidationErrorMessages = [];
                if (error && error.data && Array.isArray(error.data.innerErrors) && Array.isArray(error.data.innerErrors[0].messages)) {
                    this.warningMessage = DmError.InternalEngagement.MismatchOfUploadedFile;
                }
                this.logError(SourceConstants.Method.UploadFileAttachment, error, this.warningMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isLoadingRiskReserveDetails = false;
            });
        }
    }

    /**
     * Closes modal and emits an event to notify notification component of closure.
     *
     * @memberof EditRiskReserveModalComponent
     */
    public closeStatusModal(): void {
        this.onStatusModalClose.emit();
        this.closeModal();
    }

    /**
     * Toggles tab btw risk reserve details and change log i.e audit.
     *
     * @param {IDmTab} activeTab
     * @memberof EditRiskReserveModalComponent
     */
    public toggleTab(activeTab: IDmTab): void {
        for (const tab of this.tabsContent) {
            tab.isActive = false;
        }
        activeTab.isActive = true;
        if (activeTab.id === "AUDIT") {
            this.showAudit = true;
            this.showRiskReserve = false;
            this.isLoadingRiskReserveDetails = true;
            this.loadingText = "Loading Audit History";
            // Get Risk Reserve Audit Data
            let riskReserveAuditData: IAuditResponse[];
            this.auditService.getAuditHistory(this.wbsId, "RiskReserve").then((response) => {
                this.isLoadingRiskReserveDetails = false;
                riskReserveAuditData = response.riskReserveAuditEntries;
                const auditViewModelList = this.mapToAuditViewModel(riskReserveAuditData);
                this.auditHistoryData = this.groupEntriesByDate(auditViewModelList);
            }).catch(() => {
                this.isLoadingRiskReserveDetails = false;
            });
        } else if (activeTab.id === "RISKRESERVE") {
            this.showRiskReserve = true;
            this.showAudit = false;
        }
    }

    /**
     * Updates Risk reserve details
     *
     * @memberof EditRiskReserveModalComponent
     */
    public updateRRDetails(): void {  
        const tasksRRPayload: ITaskRRDetailsRequest[] = [];
        this.updatedProjectApprovedFinancials = this.riskReserveDetailsList.projects;
        for (const updatedProject of this.updatedProjectApprovedFinancials) {
            for (const originalProject of this.originalProjectApprovedFinancials) {
                updatedProject.tasks = updatedProject.tasks.filter((x) => x.version === +FinancialType.CurrentFinancialPlan);
                originalProject.tasks = originalProject.tasks.filter((x) => x.version === +FinancialType.CurrentFinancialPlan);
                for (const updatedTask of updatedProject.tasks) {
                    for (const originalTask of originalProject.tasks) {
                        if ((originalTask.totalRiskReserve !== updatedTask.totalRiskReserve) && (originalTask.wbsId === updatedTask.wbsId) && !tasksRRPayload.filter((taskRR) => taskRR.wbsId === updatedTask.wbsId).length) { 
                            tasksRRPayload.push({wbsId: updatedTask.wbsId, totalRiskReserve: updatedTask.totalRiskReserve, previousRiskReserve: originalTask.totalRiskReserve, projectId: updatedTask.parentId});
                        }
                    }
                }
            }
        }
        
        if (tasksRRPayload.length === 0) {
            this.isSubmitBtnDisabled = true;
            return;
        } else {
            this.isSubmitBtnDisabled = false;
        }

        this.loadingText = "Updating Risk Reserve Details";
        this.isLoadingRiskReserveDetails = true;
        const riskReserveRequestPayload: IRiskReserveRequest = {
            taskRRDetails : tasksRRPayload,
        };
        if (this.uploadedFileAttachmentResponse) {
            riskReserveRequestPayload.attachment = this.uploadedFileAttachmentResponse;
        }
       
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        const orchestrationId: string = uuid();
        this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.RiskReserve, loggedInUserData.BusinessPartnerId, this.wbsId, orchestrationId)
            .then(() => {
                this.projectServiceFunction.orchestrateRiskReserveUpdate(loggedInUserData.alias , this.wbsId, orchestrationId, riskReserveRequestPayload).then(() => {
                    this.fxpMessageService.addMessage("Risk Reserve change has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                    // Displays the new notification in the notification bar
                    this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.wbsId, orchestrationId, NotificationType.RiskReserve);
                    this.isLoadingRiskReserveDetails = false;
                    this.closeModal();
                }).catch(() => {
                    this.fxpMessageService.addMessage("There is an error submitting risk reserve change. Please try again", this.FXP_CONSTANTS.messageType.error, false);
                    this.isLoadingRiskReserveDetails = false;
                    this.closeModal();
                });
            }).catch(() => {
                this.fxpMessageService.addMessage("There is an error submitting risk reserve change. Please try again", this.FXP_CONSTANTS.messageType.error, false);
                this.isLoadingRiskReserveDetails = false;
                this.closeModal();
            });
    }

    /**
     * TODO: Enables Editable fields 
     *
     * @memberof EditRiskReserveModalComponent
     */
    public editRRDetails(): void {
        this.isEditClicked = true;
        if (this.riskReserveDetailsList.projects.length && this.riskReserveDetailsList.projects[0].tasks.length) {
            if (!this.riskReserveDetailsList.projects[0].isExpanded) {
                this.riskReserveDetailsList.projects[0].isExpanded = true;
            }
            const nextFocusableId = this.riskReserveDetailsList.projects[0].tasks[0].wbsId + "-total-rr-amount";
            this.sharedFunctionsService.focus(nextFocusableId, true);
        }
    }

    /**
     * Change handler for l3 i.e task level input changes
     *
     * @param {ITaskRiskReserveDetails} taskDetails
     * @param {number} newValue
     * @memberof EditRiskReserveModalComponent
     */
    public onInputChange(taskDetails: ITaskApprovedFinancial): void {
        const cfpRiskReserveProjectDetails = this.riskReserveDetailsList.projects;
        const filteredCFPProjectRRDetails = cfpRiskReserveProjectDetails.filter((m) => m.wbsId === taskDetails.parentId)[0];

        const cbRiskReserveDetailsList = this.approvedFinancialsResponse.projects.filter((m) => m.version === FinancialType.ContractBaseline);
        const filteredCBProjectRRDetails = cbRiskReserveDetailsList.filter((m) => m.wbsId === taskDetails.parentId)[0];
        
        let cummulativeTasksRR = 0;
        for (const item of filteredCFPProjectRRDetails.tasks) {
            cummulativeTasksRR = cummulativeTasksRR + item.totalRiskReserve;
        }

        const lowerBoundErrorMessage = "Sum of all Tasks RR amount cannot be less than consumed RR";
        const upperBoundErrorMessage = `Sum of all Tasks RR amount cannot be more than Total Contract Baseline RR - ${this.currency} ${filteredCBProjectRRDetails.totalRiskReserve}`;
        const validValueErrorMessage = "Entered value should be more than zero";
        taskDetails.errorMessage = "";
        if (cummulativeTasksRR < filteredCFPProjectRRDetails.consumedRiskReserve) {
            taskDetails.errorMessage = lowerBoundErrorMessage;
        }
        if (cummulativeTasksRR > filteredCBProjectRRDetails.totalRiskReserve) {
            taskDetails.errorMessage = upperBoundErrorMessage;
        }
        if (taskDetails.totalRiskReserve <= 0) {
            taskDetails.errorMessage = validValueErrorMessage;
        }
        if (this.uploadedFileAttachmentResponse) {
            this.isSubmitBtnDisabled = false;
        }
    }

    /**
     * Expand and collapse projects
     *
     * @param {*} item
     * @memberof EditRiskReserveModalComponent
     */
    public expandCollapse(projectRiskReserveDetails: IProjectApprovedFinancial): void {
        if (projectRiskReserveDetails) {
            projectRiskReserveDetails.isExpanded = !projectRiskReserveDetails.isExpanded;
        }
    }

    /**
     * Move the focus to element for accessibility of nav bars
     * @param id
     */
    public focusForNavBarArrowKey(id: string, focusDirection: string): void {
        this.sharedFunctionsService.moveFocusForNavBarArrowKey(this.tabsContent, id, focusDirection);
    }

    /**
     * Get wbs name for any wbs id i.e passed
     *
     * @private
     * @param {string} wbsId
     * @param {IEngagementDetailsV2} engagementDetails
     * @return {*}  {string}
     * @memberof EditRiskReserveModalComponent
     */
    private getWbsName(wbsId: string, engagementDetails: IEngagementDetailsV2): string {
        for (const project of engagementDetails.projects) {
            if (wbsId === project.id){
                return project.name;
            }
            for (const service of project.services) {
                if (wbsId === service.id){
                    return service.name;
                }
                for (const task of service.tasks) {
                    if (wbsId === task.id){
                        return task.name;
                    }
                }
            }
        }
    }

    /**
     * Maps audit view model
     *
     * @private
     * @param {IAuditResponse[]} riskReserveAuditData
     * @return {*}  {*}
     * @memberof EditRiskReserveModalComponent
     */
    private mapToAuditViewModel(riskReserveAuditData: IAuditResponse[]): any {
        const auditData = riskReserveAuditData.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
        const auditViewModelList = [];
        const engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService); 
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let n = 0; n < auditData.length; n++) {
            if (auditData[n] && auditData[n].audit) {
                const auditViewModel: any = {};
                this.oneProfileService.getProfile(auditData[n].createdBy).then((response) => {
                    if (response && response.DisplayName) {
                        auditViewModel.status = `<b>${response.DisplayName}</b> (${response.Alias}) has changed Total Risk Reserve for ${(auditData[n].audit as IRiskReserveAuditValues).wbsWorkPackageId}.`;
                    }
                });
                this.aadGraphService.getResourceThumbnailPicture(auditData[n].createdBy).then((imageData) => {
                    if (imageData) {
                        auditViewModel.userImage = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + imageData);
                    }
                });
                auditViewModel.engagementId = engagementId;
                auditViewModel.date = moment(auditData[n].createdOn).format("DD-MMM-YYYY");
                auditViewModel.time = moment(auditData[n].createdOn).format("hh:mm:ss A");
                auditViewModel.createdOn = moment(auditData[n].createdOn);
                auditViewModel.type = "RiskReserve";
                auditViewModel.rrAudit = (auditData[n].audit as IRiskReserveAuditValues);
                auditViewModel.rrAudit.currentRrAmount = (auditData[n].audit as IRiskReserveAuditValues).currentRrAmount;
                // auditViewModel.rrAudit.previousRrAmount = auditData[n + 1] && auditData[n + 1].audit ? (auditData[n + 1].audit as IRiskReserveAuditValues).currentRrAmount : "-";
                auditViewModel.rrAudit.previousRrAmount = (auditData[n].audit as IRiskReserveAuditValues).previousRrAmount; 
                auditViewModel.title = "Total RR Amount";
                auditViewModel.unit = "USD";
                // auditViewModel.rrAudit.change = auditData[n + 1] && auditData[n + 1].audit  ? ((+(auditData[n].audit as IRiskReserveAuditValues).currentRrAmount - +(auditData[n + 1].audit as IRiskReserveAuditValues).currentRrAmount) / (+(auditData[n].audit as IRiskReserveAuditValues).currentRrAmount)) : 0 ;
                auditViewModel.rrAudit.change = ((+(auditData[n].audit as IRiskReserveAuditValues).currentRrAmount) - (+(auditData[n].audit as IRiskReserveAuditValues).previousRrAmount)) / (+(auditData[n].audit as IRiskReserveAuditValues).currentRrAmount) ;
                auditViewModelList.push(auditViewModel);
            }
        }
        return auditViewModelList;
    }

    /**
     * Group entries by date
     *
     * @private
     * @param {*} auditViewModelList
     * @return {*}  {*}
     * @memberof PlanForecastAuditHistoryComponent
     */
    private groupEntriesByDate(auditViewModelList): any {
        // this gives an object with dates as keys
        const auditGroups = auditViewModelList.reduce((groups, auditItem) => {
            const date = auditItem.date;
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(auditItem);
            return groups;
        }, {});

        // Add it to array with date as key
        const groupArrays = Object.keys(auditGroups).map((date) => {
            return {
                date,
                auditItemsPerDate: auditGroups[date]
            };
        });
        return groupArrays;
    }
}

