import { INotification } from "../../common/services/contracts/notification-bar.contracts";
import { NotificationSubscriptionActions, NotificiationSubscriptionActionTypes } from "./notification-subscriptions.action";

export interface INotificationState {
    notifications: INotification[];
}

export const initialState: INotificationState = {
    notifications: null
};

export function reducer(state = {}, action: NotificationSubscriptionActions): { [userId: string]: INotificationState } {
    switch (action.type) {
        case NotificiationSubscriptionActionTypes.LOAD_NOTIFICATIONS: {
            const newStateForLoadAction = {};
            newStateForLoadAction[action.userId] = {
                ...initialState,
                notifications: action.notifications
            };
            return newStateForLoadAction;
        }

        case NotificiationSubscriptionActionTypes.ADD_NOTIFICATION: {
            const newStateForAddAction = { ...state };
            // If there is already existing notification list, add it to the list.
            if (newStateForAddAction && Object.keys(newStateForAddAction).length > 0) {
                const existingNotifications = newStateForAddAction[action.userId].notifications;
                const newNotificationList = [...existingNotifications];
                newNotificationList.unshift(action.notification);
                newStateForAddAction[action.userId].notifications = newNotificationList;
            } else {
                // If there is no existing notification list, create the list with this current notification.
                newStateForAddAction[action.userId] = {
                    ...initialState,
                    notifications: [action.notification]
                };
            }
            return newStateForAddAction;
        }

        case NotificiationSubscriptionActionTypes.REMOVE_NOTIFICATION: {
            const newStateForRemoveAction = { ...state };
            // If there is already existing notification list, remove it from the list.
            if (newStateForRemoveAction && Object.keys(newStateForRemoveAction).length > 0) {
                const existingNotifications = newStateForRemoveAction[action.userId].notifications;
                const newNotificationList = existingNotifications.filter((item) => item.id !== action.notificationId);
                newStateForRemoveAction[action.userId].notifications = newNotificationList;
            }
            return newStateForRemoveAction;
        }
    }
    return state;
}