
import { Component, Inject, Input, forwardRef } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { Components, LogEventConstants, SourceConstants, FinancialType, TMContractType } from "../../../../common/application.constants";
import { RouteName } from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { IApprovedFinancialsResponseV2 } from "../../../../common/services/contracts/changerequest.contract";
import { ChangeRequestService } from "../../../../common/services/change-request.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { IAdditionalFinancialSummary, IEntityFinancials, IEntityFinancialSummary } from "../../../../components/financial-mgmt/financial.model";
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IEngagementDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { combineLatest as observableCombineLatest } from "rxjs";
import { ProjectService } from "../../../../common/services/project.service";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { LoadFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.action";
import { DeviceFactoryProvider } from "@fxp/fxpservices";

const CREATED_STATUS_CODE = "CRTD";
export interface IRiskReserve {
    consumed: number;
    approved: number;
    grossCostOverRun: number;
    total: number;
    remainingCfpRr: number;
    remainingCbRr: number;
}

@Component({
    selector: "dm-summary-financials-breakdown",
    templateUrl: "./summary-financials-breakdown.html",
    styleUrls: ["./summary-financials-breakdown.scss"]
})
export class SummaryFinancialsBreakdownComponent extends DmComponentAbstract {
    @Input() public isTwoThirdTile: boolean = true;
    public currentConsumed: number = 0;
    public currencyType: string;
    public RouteName = RouteName;
    public tileContent: ITile;
    public isProjectContext: boolean = false;
    public financialsRouteName: string;
    public riskReserve: IRiskReserve;
    public consumedAmtPercentage: number = 0;
    public financialsBreakdownData: Array<{
        title: string;
        cost: number;
    }> = [];
    public deliveryCost: number = 0;
    public budgetCost: number = 0;
    public hoursCharge: number = 0;
    public subconFFCost: number = 0;
    public resourcesCost: number = 0;
    public unitsCost: number = 0;
    public expenses: number = 0;
    public remainingRrDiff: number = 0;
    public isAdditionalCostExpanded: boolean = false;
    public isCostSavingsExpanded: boolean = false;
    public approvedFinancialsResponse: IApprovedFinancialsResponseV2;
    public engagementId: string;
    public currentFinancialPlanDetails: IEntityFinancialSummary;
    public contractBaselineDetails: IEntityFinancialSummary;
    public currentFinancialPlanAdditinalSummaryDetails: IAdditionalFinancialSummary;
    public deliveryBaselineAdditinalSummaryDetails: IAdditionalFinancialSummary;
    public contractBaselineAdditinalSummaryDetails: IAdditionalFinancialSummary;
    public additionalCost: number = 0;
    public costSavings: number = 0;
    public isServerError: boolean;
    public loadingText: string;
    public hasRiskReserve: boolean = false;
    public hasCostSavings: boolean = false;
    public noFinancialBreakdownDetailsText: string = "This Engagement is not yet Released and Activated.";
    public hideFinancialBreakdownDetails: boolean;
    private projectLaborCost: number = 0;
    private projectLabourHours: number = 0;
    private avgCostRate: number = 0;
    private currentFinancialPlanHours: number = 0;
    private contractBaselineHours: number = 0;
    private selectedProjectId: string;
    private queuedProjects: Array<{
        projectId: string;
        isDataLoaded?: boolean;
    }> = [];

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ChangeRequestService) public changeRequestService: ChangeRequestService,
        @Inject(Store) private store: Store<IState>,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(forwardRef(() => DeviceFactoryProvider))
        public deviceFactory: DeviceFactoryProvider,
    ) {
        super(dmLogger, Components.SummaryFinancialsBreakdown);
    }

    public ngOnInit(): void {
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.selectedProjectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = this.selectedProjectId ? true : false;
        this.loadingText = "Loading Financials Breakdown Details";
        this.tileContent = {
            title: "Financials Breakdown"
        };
        this.avgCostRate = 0;
        this.riskReserve = {
            consumed: 0,
            approved: 0,
            grossCostOverRun: 0,
            total: 0,
            remainingCbRr: 0,
            remainingCfpRr: 0
        };

        if (this.isProjectContext) {
            const projectDetails$ = this.store.select(getEntireProjectDetails(this.selectedProjectId));
            const projectFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.selectedProjectId));
            observableCombineLatest(
                projectDetails$,
                projectFinancialDetails$,
                (
                    projectDetailsState: IProjectDetailsState,
                    projectFinancialDetailsState: IFinancialDetailsV2State
                ) => ({
                    projectDetailsState,
                    projectFinancialDetailsState,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    projectDetailsState,
                    projectFinancialDetailsState,
                }) => {
                    if (projectDetailsState.loaded && projectFinancialDetailsState.loaded) {
                        const projectDetailsResonse = projectDetailsState.projectDetails.projectFullDetails;
                        if (projectDetailsResonse) {
                            this.hasRiskReserve = projectDetailsResonse.contractType !== TMContractType;
                            this.hideFinancialBreakdownDetails = projectDetailsResonse.statusCode === CREATED_STATUS_CODE;
                            this.currencyType = projectDetailsResonse.currency;
                        }
                        const projectFinancialDetailsResponse: IEntityFinancials = projectFinancialDetailsState.financialDetails;
                        if (projectFinancialDetailsResponse) {
                            this.deliveryCostDetails(projectFinancialDetailsResponse);
                            this.projectLaborCost = 0;
                            this.projectLabourHours = 0;
                            if (projectFinancialDetailsResponse.additionalFinancialSummary) {
                                if (projectFinancialDetailsResponse.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "1").length) {
                                    const projectContractBaselineAdditinalSummaryDetails = projectFinancialDetailsResponse.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "1")[0]; // "1" is "Contract Baseline"
                                    this.projectLaborCost += projectContractBaselineAdditinalSummaryDetails.laborCost;
                                    this.projectLabourHours += projectContractBaselineAdditinalSummaryDetails.labor;
                                }
                            }
                            if (this.projectLabourHours !== 0) {
                                this.avgCostRate = this.projectLaborCost / this.projectLabourHours;
                            }
                            this.hoursCharge = (this.currentFinancialPlanHours - this.contractBaselineHours) * this.avgCostRate;
                            const contractBaselineLaborCost = this.contractBaselineAdditinalSummaryDetails ? this.contractBaselineAdditinalSummaryDetails.laborCost : 0;
                            const currentFinancialPlanLaborCost = this.currentFinancialPlanAdditinalSummaryDetails ? this.currentFinancialPlanAdditinalSummaryDetails.laborCost : 0;
                            this.resourcesCost = (currentFinancialPlanLaborCost - this.hoursCharge) - contractBaselineLaborCost;
                            this.populateBreakdownData();
                        }
                    }

                    this.riskReserveDetails(this.selectedProjectId);
                    this.refreshOnItemInvalidation(projectDetailsState, projectFinancialDetailsState);
                    this.setErrorsBasedOnItemState(projectDetailsState, projectFinancialDetailsState);
                    this.setLoadersBasedOnItemState(projectDetailsState, projectFinancialDetailsState);
                    if ((projectDetailsState.loaded && projectDetailsState.error) || (projectFinancialDetailsState.loaded && projectFinancialDetailsState.error)) {
                        this.isServerError = true;
                    }
                });
        } else {
            const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
            const engagementfinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.engagementId));
            observableCombineLatest(
                engagementDetails$,
                engagementfinancialDetails$,
                (
                    engagementDetails: IEngagementDetailsState,
                    engagementFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    engagementDetails,
                    engagementFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    engagementDetails,
                    engagementFinancialDetails,
                }) => {
                    if (engagementDetails.loaded && engagementFinancialDetails.loaded) {
                        const engagementDetailsResponse: IEngagementDetailsV2 = engagementDetails.engagementDetails;
                        const engagementFinancialDetailsResponse: IEntityFinancials = engagementFinancialDetails.financialDetails;
                        if (engagementFinancialDetailsResponse) {
                            this.deliveryCostDetails(engagementFinancialDetailsResponse);
                            if (engagementFinancialDetailsResponse.financialSummary && engagementFinancialDetailsResponse.financialSummary.length) {
                                const filteredCFP = engagementFinancialDetailsResponse.financialSummary.filter((m) => m.versionKey === FinancialType.CurrentFinancialPlan);
                                this.riskReserve.grossCostOverRun = filteredCFP && filteredCFP.length && filteredCFP[0].netCostOverrun;
                            }
                        }
                        this.riskReserveDetails(this.engagementId);
                        if (engagementDetailsResponse) {
                            const contractType = this.sharedFunctionsService.getContractType(engagementDetailsResponse.projects);
                            this.currencyType = engagementDetailsResponse.currency;
                            this.queuedProjects = [];
                            this.projectLaborCost = 0;
                            this.projectLabourHours = 0;
                            this.hideFinancialBreakdownDetails = engagementDetailsResponse.statusCode === CREATED_STATUS_CODE;
                            for (const project of engagementDetailsResponse.projects) {
                                this.queuedProjects.push({ projectId: project.id, isDataLoaded: false });
                            }
                            const projectsTobeDispatched = this.queuedProjects.filter((project) => !project.isDataLoaded).map((val) => val.projectId);
                            this.initiateProjectFinancesLoad(projectsTobeDispatched);
                            this.hasRiskReserve = contractType !== TMContractType;
                        }
                    }
                    this.refreshOnItemInvalidation(engagementDetails, engagementFinancialDetails);
                    this.setErrorsBasedOnItemState(engagementDetails, engagementFinancialDetails);
                    this.setLoadersBasedOnItemState(engagementDetails, engagementFinancialDetails);
                    if ((engagementDetails.loaded && engagementDetails.error) || (engagementFinancialDetails.loaded && engagementFinancialDetails.error)) {
                        this.isServerError = true;
                    }
                });
        }
        this.financialsRouteName = this.isProjectContext ? RouteName.ProjectFinancials : RouteName.EngagementFinancials;
    }

    /**
     * Routes the user to the financials tab within the engagement detail component.
     * Logs info for application insights telemetry.
     */
    public logFinancialsClick(): void {
        const className = this.isProjectContext ? SourceConstants.Component.ProjectSummaryV2Page : SourceConstants.Component.EngagementSummaryV2Page;
        this.dmLogger.logEvent(className, SourceConstants.Method.LogFinancialsClick, LogEventConstants.FinancialsDetailsLinkClicked);
    }

    /**
     *  expand/collapsing sections
     */
    public expandCollapseBreakdown(type: string): void {
        if (type === "Additional Cost") {
            this.isAdditionalCostExpanded = !this.isAdditionalCostExpanded;
        }
        if (type === "Cost Savings") {
            this.isCostSavingsExpanded = !this.isCostSavingsExpanded;
        }
    }

    /**
     * Check whether the hours charge, subconFF cost, resource cost, units cost and 
     * expenses values are positive or negative.
     * If the value is positive add it to the additional cost
     * else add it to the cost savings.
     */
    private addToAdditionalCostOrCostSavings(value: number): void {
        if (value > 0) {
            this.additionalCost += value;
        } else {
            this.costSavings += value;
        }
    }

    /**
     * calcualate risk reserve details
     */
    private riskReserveDetails(wbsId: string): void {
        this.changeRequestService.getApprovedFinancialsV2(wbsId).then((response: IApprovedFinancialsResponseV2) => {
            this.approvedFinancialsResponse = response;
            if (this.approvedFinancialsResponse && this.approvedFinancialsResponse.projects && this.approvedFinancialsResponse.projects.length) {
                const currentFinancialPlanApprovedFinancials = this.changeRequestService.getProjectsApprovedFinancialsV2ByVersion(response.projects, FinancialType.CurrentFinancialPlan);
                if (currentFinancialPlanApprovedFinancials && currentFinancialPlanApprovedFinancials.length) {
                    this.riskReserve.consumed = 0;
                    this.riskReserve.remainingCfpRr = 0;
                    this.riskReserve.remainingCbRr = 0;
                    this.riskReserve.approved = 0;
                    for (const projectReserve of currentFinancialPlanApprovedFinancials) {
                        this.riskReserve.consumed += projectReserve.consumedRiskReserve;
                        // this.riskReserve.grossCostOverRun += projectReserve.costOverrun;
                        this.riskReserve.approved += projectReserve.approvedRiskReserve;
                        this.riskReserve.remainingCfpRr += projectReserve.remainingRiskReserve;
                    }
                }

                const contractBaselineApprovedFinancials = this.changeRequestService.getProjectsApprovedFinancialsV2ByVersion(response.projects, FinancialType.ContractBaseline);
                if (contractBaselineApprovedFinancials && contractBaselineApprovedFinancials.length) {
                    this.riskReserve.total = 0;
                    for (const projectReserve of contractBaselineApprovedFinancials) {
                        this.riskReserve.total += projectReserve.totalRiskReserve;
                        this.riskReserve.remainingCbRr += projectReserve.remainingRiskReserve;
                    }
                }
                if (this.riskReserve && this.riskReserve.total && this.riskReserve.total !== 0) {
                    this.consumedAmtPercentage = (this.riskReserve.consumed / this.riskReserve.total) * 100;
                }
                this.remainingRrDiff = this.riskReserve.remainingCfpRr - this.riskReserve.remainingCbRr;
                this.populateBreakdownData();
            }
        }).catch((error) => {
            if (error.status === 404) {
                Promise.resolve([]);
            }
        });
    }

    /**
     * calcualate delivery cost details
     */
    private deliveryCostDetails(response: IEntityFinancials): void {
        if (response) {
            if (response.financialSummary) {
                if (response.financialSummary.filter((financialSummary) => financialSummary.versionKey === "3").length) {
                    this.currentFinancialPlanDetails = response.financialSummary.filter((financialSummary) => financialSummary.versionKey === "3")[0]; // "3" is "Current Financial Plan"
                    this.deliveryCost = this.currentFinancialPlanDetails.cost;
                    this.currentFinancialPlanHours = this.currentFinancialPlanDetails.labor;
                }
                if (response.financialSummary.filter((financialSummary) => financialSummary.versionKey === "1").length) {
                    this.contractBaselineDetails = response.financialSummary.filter((financialSummary) => financialSummary.versionKey === "1")[0]; // "1" is "Contract Baseline"
                    this.budgetCost = this.contractBaselineDetails.cost;
                    this.contractBaselineHours = this.contractBaselineDetails.labor;
                }
            }
            if (response.additionalFinancialSummary) {
                if (response.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "3").length) {
                    this.currentFinancialPlanAdditinalSummaryDetails = response.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "3")[0]; // "3" is "Current Financial Plan"
                }
                if (response.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "2").length) {
                    this.deliveryBaselineAdditinalSummaryDetails = response.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "2")[0]; // "2" is "Delivery Baseline"
                }
                if (response.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "1").length) {
                    this.contractBaselineAdditinalSummaryDetails = response.additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "1")[0]; // "1" is "Contract Baseline"
                }
                const currentFinancialPlanSubconFFCost = this.currentFinancialPlanAdditinalSummaryDetails ? this.currentFinancialPlanAdditinalSummaryDetails.subconFFCost : 0;
                const currentFinancialPlanUnitsCost = this.currentFinancialPlanAdditinalSummaryDetails ? this.currentFinancialPlanAdditinalSummaryDetails.unitsCost : 0;
                const currentFinancialPlanExpenseMaterialCost = this.currentFinancialPlanAdditinalSummaryDetails ? this.currentFinancialPlanAdditinalSummaryDetails.expenseMaterialCost : 0;
                const contractBaselineSubconFFCost = this.contractBaselineAdditinalSummaryDetails ? this.contractBaselineAdditinalSummaryDetails.subconFFCost : 0;
                const contractBaselineUnitsCost = this.contractBaselineAdditinalSummaryDetails ? this.contractBaselineAdditinalSummaryDetails.unitsCost : 0;
                const contractBaselineExpenseMaterialCost = this.contractBaselineAdditinalSummaryDetails ? this.contractBaselineAdditinalSummaryDetails.expenseMaterialCost : 0;

                this.subconFFCost = currentFinancialPlanSubconFFCost - contractBaselineSubconFFCost;
                this.unitsCost = currentFinancialPlanUnitsCost - contractBaselineUnitsCost;
                this.expenses = currentFinancialPlanExpenseMaterialCost - contractBaselineExpenseMaterialCost;
            }
        }
    }

    /**
     * Loads all project finances based on list of project Id
     * @param projectIds 
     */
    private initiateProjectFinancesLoad(projectIds: string[]): void {
        let costRates = [];
        projectIds.forEach((projectId) => {
            const projectFinancial$ = this.store.select(getEntireFinancialDetailsV2(projectId));
            projectFinancial$.pipe(untilDestroyed(this))
                .subscribe((projectFinancialDetailsResponse: IFinancialDetailsV2State) => {
                    if (!projectFinancialDetailsResponse.loaded && !projectFinancialDetailsResponse.loading) {
                        this.store.dispatch(new LoadFinancialDetailsV2(projectId));
                    }
                    if (projectFinancialDetailsResponse.loaded) {
                        const additionalFinancialSummary = projectFinancialDetailsResponse.financialDetails.additionalFinancialSummary;
                        if (additionalFinancialSummary) {
                            if (additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "1").length) {
                                const projectContractBaselineAdditinalSummaryDetails = additionalFinancialSummary.filter((financialSummary) => financialSummary.versionKey === "1")[0]; // "1" is "Contract Baseline"
                                if (projectContractBaselineAdditinalSummaryDetails) {
                                    this.projectLaborCost = projectContractBaselineAdditinalSummaryDetails.laborCost;
                                    this.projectLabourHours = projectContractBaselineAdditinalSummaryDetails.labor;
                                }
                                const projectCostRate = this.projectLabourHours !== 0 ? this.projectLaborCost / this.projectLabourHours : null;
                                if (!costRates.filter((costRate) => costRate.projectId === projectId).length) {
                                    costRates.push({
                                        projectId,
                                        projectLaborCost: projectContractBaselineAdditinalSummaryDetails.laborCost,
                                        projectLabourHours: projectContractBaselineAdditinalSummaryDetails.labor,
                                        costRate: projectCostRate
                                    });
                                }
                                if (costRates.length === projectIds.length) {
                                    costRates = costRates.filter((costRate) => costRate.costRate !== null);
                                    let totalProjectLaborCost = 0;
                                    let totalProjectLaborHours = 0;
                                    if (costRates && costRates.length) {
                                        for (const rate of costRates) {
                                            totalProjectLaborCost += rate.projectLaborCost;
                                            totalProjectLaborHours += rate.projectLabourHours;
                                        }
                                        this.avgCostRate = totalProjectLaborHours ? totalProjectLaborCost / totalProjectLaborHours : 0;
                                    }
                                    this.hoursCharge = (this.currentFinancialPlanHours - this.contractBaselineHours) * this.avgCostRate;
                                    const contractBaselineLaborCost = this.contractBaselineAdditinalSummaryDetails ? this.contractBaselineAdditinalSummaryDetails.laborCost : 0;
                                    const currentFinancialPlanLaborCost = this.currentFinancialPlanAdditinalSummaryDetails ? this.currentFinancialPlanAdditinalSummaryDetails.laborCost : 0;
                                    this.resourcesCost = (currentFinancialPlanLaborCost - this.hoursCharge) - contractBaselineLaborCost;
                                    this.populateBreakdownData();
                                }
                            }
                        }
                    }
                    this.refreshOnItemInvalidation(projectFinancialDetailsResponse);
                    this.setLoadersBasedOnItemState(projectFinancialDetailsResponse);
                });
        });
    }

    /**
     * Populate breakdown data after calulating additional cost and cost savings 
     */
    private populateBreakdownData(): void {
        this.additionalCost = 0;
        this.costSavings = 0;
        this.financialsBreakdownData = [];
        if (this.hoursCharge <= 0 || this.resourcesCost <= 0 || this.subconFFCost <= 0 || this.unitsCost <= 0 || this.expenses <= 0 || this.remainingRrDiff <= 0) {
            this.hasCostSavings = true;
            this.isCostSavingsExpanded = true;
        }
        if (this.hoursCharge > 0 || this.resourcesCost > 0 || this.subconFFCost > 0 || this.unitsCost > 0 || this.expenses > 0 || this.remainingRrDiff > 0) {
            this.isAdditionalCostExpanded = true;
        }
        this.addToAdditionalCostOrCostSavings(this.hoursCharge);
        this.addToAdditionalCostOrCostSavings(this.resourcesCost);
        this.addToAdditionalCostOrCostSavings(this.subconFFCost);
        this.addToAdditionalCostOrCostSavings(this.unitsCost);
        this.addToAdditionalCostOrCostSavings(this.expenses);
        this.addToAdditionalCostOrCostSavings(this.remainingRrDiff);
        this.financialsBreakdownData.push(
            {
                title: "Contract Baseline Cost",
                cost: this.budgetCost
            },
            {
                title: "Additional Cost",
                cost: this.additionalCost
            },
            {
                title: "Cost Savings",
                cost: -this.costSavings
            },
            {
                title: "CFP Planned Cost",
                cost: this.deliveryCost
            });
    }
}
