<div class="roledetailsdialogcontent">
    <div class="modal-header reject-popUp-noBorder">
        <button type="button" id="closeRejectButton" class="close set-focus" data-dismiss="modal" aria-label="Delegation Details Dialog Close"
            (click)="closeModal()">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Delegation Details</h4>
    </div>
    <div class="modal-body">
        <h5>Delegated {{delegatedToOrFrom}} {{delegationDetails.delegationFullName}}</h5>
        <section class="delegation-details-section">
            <h6>Alias</h6>
            <p>{{delegationDetails.delegationToFrom}}</p>
        </section>
        <section class="delegation-details-section">
            <h6>Start/End Date</h6>
            <p>
                <span>{{delegationDetails.delegationStartDate | dmDisplayDateOrDashPipe}}</span> to
                <span>{{delegationDetails.delegationEndDate  | dmDisplayDateOrDashPipe}}</span>
            </p>
        </section>
        <section class="delegation-details-section">
            <h6>Status</h6>
            <p>{{delegationDetails.delegationStatus}}</p>
        </section>
    </div>
    <div class="modal-footer reject-popUp-noBorder">
        <button type="button" class="fxp-btn fxp-btn--primary" data-dismiss="modal"
            (click)="closeModal()">Close</button>
    </div>
</div>