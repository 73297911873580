import { ILoadableState } from "../reducers";

import * as fromWbsStructures from "./wbs-structures.action";
import { IWbsStructure, IProject } from "../../common/services/contracts/wbsStructures.contracts";

export interface IWbsStructuresState extends ILoadableState {
    wbsStructures: IWbsStructure | IProject;
}

export const initialState: IWbsStructuresState = {
    wbsStructures: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromWbsStructures.WbsStructuresActions): { [wbsId: string]: IWbsStructuresState } {
    switch (action.type) {
        case fromWbsStructures.WbsStructuresActionTypes.LOAD_WBS_STRUCTURES: {
            const newState = {...state};
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromWbsStructures.WbsStructuresActionTypes.LOAD_WBS_STRUCTURES_SUCCESS: {
            const newState = {...state};
            newState[action.wbsId] = {
                ...initialState,
                wbsStructures: action.wbsStructures,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromWbsStructures.WbsStructuresActionTypes.LOAD_WBS_STRUCTURES_FAIL: {
            const newState = {...state};
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromWbsStructures.WbsStructuresActionTypes.INVALIDATE_WBS_STRUCTURES: {
            const newState = {...state};
            newState[action.wbsId] = initialState;
            return newState;
        }
    }

    return state;
}