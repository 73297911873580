import { Injectable, Inject } from "@angular/core";
import { ConfigManagerService } from "./configmanager.service";
import { IFinancialProjectTypeName, IFinancialRoles } from "./contracts/projectservice-functions.contract";
import { IRoleDetail } from "./contracts/add-roles.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class ResourceRolesService extends DmServiceAbstract {

    private configTypesWithRoles: IFinancialProjectTypeName[] = [];

    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ResourceRoleService );
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Determines whether the given role is considered an auto commit role for the given engagement type code.
     *
     * @param {IFinancialRoles} selectedRole
     * @param {number} engagementTypeCode
     * @returns {boolean}
     * @memberof ResourceRolesService
     */
    public isRoleAutoCommit(selectedRole: IFinancialRoles | IRoleDetail, engagementTypeCode: string | number): boolean {
        const projectTypeNames: IFinancialProjectTypeName[] = this.configTypesWithRoles.filter((type: IFinancialProjectTypeName) => type.EngagementCreationCode === engagementTypeCode || type.EngagementTypeCode.toLowerCase() === engagementTypeCode.toString().toLowerCase());
        const autoCommitRoles: IFinancialRoles[] = this.getAutoCommitRoles(projectTypeNames);
        return autoCommitRoles.filter((role: IFinancialRoles) => (role.rolePartNumber ? role.rolePartNumber.trim() : "") === (selectedRole.rolePartNumber ? selectedRole.rolePartNumber.trim() : "")).length > 0;
    }

    /**
     * Returns an array containing auto commit roles for a given engagement type.
     *
     * @param {IFinancialProjectTypeName[]} projectTypeNames
     * @returns {IFinancialRoles[]}
     * @memberof ResourceRolesService
     */
    private getAutoCommitRoles(projectTypeNames: IFinancialProjectTypeName[]): IFinancialRoles[] {
        if (projectTypeNames && projectTypeNames.length && projectTypeNames[0].RolesValues) {
            return projectTypeNames[0].RolesValues;
        } else {
            return [];
        }
    }

    /**
     * Initialize the resource roles service with any necessary info from configuration.
     */
    private initializeConfig(): void {
        this.configTypesWithRoles = this.configManagerService.getValue<IFinancialProjectTypeName[]>("rolesAssociationWithType");
    }
}