import { NgModule } from "@angular/core";
import { CostConsumedComponent } from "./cost-consumed.component";
import { CommonModule } from "@angular/common";
import { CceacModule } from "./cceac/cceac.module";

@NgModule({
    declarations: [
        CostConsumedComponent
    ],
    imports: [
        CceacModule,
        CommonModule,
    ],
    entryComponents: [
        CostConsumedComponent
    ],
    exports: [
        CostConsumedComponent
    ]
})
export class CostConsumedModule { }
