import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { InternalEngagementDetailComponent } from "./internal-engagement-detail.component";

@NgModule({
    declarations: [
        InternalEngagementDetailComponent,
    ],
    imports: [
        CommonModule,
        UIRouterModule
    ],
    providers: [],
    entryComponents: [
        InternalEngagementDetailComponent,
    ],
    exports: [
        InternalEngagementDetailComponent,
    ]
})
export class InternalEngagementDetailModule { }
