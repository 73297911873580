import { Action } from "@ngrx/store";
import { IExistingCrRequest } from "../../common/services/contracts/changerequest.contract";


// 1. action types
export enum CrIdRolesActionTypes {
    LOAD_CRID_ROLES = "[CrIdRoles] Load",
    LOAD_CRID_ROLES_SUCCESS = "[CrIdRoles] Load Success",
    LOAD_CRID_ROLES_FAIL = "[CrIdRoles] Load Fail",
    INVALIDATE_CRID_ROLES = "[CrIdRoles] Invalidate"
}

// 2. action creators
export class LoadCrIdRoles implements Action {
    public readonly type = CrIdRolesActionTypes.LOAD_CRID_ROLES;
    public constructor(public crId: string, public allowCrEdit: boolean) { } // todo: demands default false
}

export class LoadCrIdRolesSuccess implements Action {
    public readonly type = CrIdRolesActionTypes.LOAD_CRID_ROLES_SUCCESS;
    public constructor(public crId: string, public crRequest: IExistingCrRequest) { }
}

export class LoadCrIdRolesFail implements Action {
    public readonly type = CrIdRolesActionTypes.LOAD_CRID_ROLES_FAIL;
    public constructor(public crId: string, public errorMessage: string) { }
}

export class InvalidateCrIdRoles implements Action {
    public readonly type = CrIdRolesActionTypes.INVALIDATE_CRID_ROLES;
    public constructor(public crId: string) { }
}

// 3. export the union creators
export type CrIdRolesActions =
    | LoadCrIdRoles
    | LoadCrIdRolesSuccess
    | LoadCrIdRolesFail
    | InvalidateCrIdRoles;