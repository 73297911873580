import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ICapAmountDetails } from "../../../../financial.model";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { Components } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-view-cap-amounts",
    templateUrl: "./view-cap-amounts.html",
    styleUrls: ["./view-cap-amounts.scss"]
})
export class ViewCapAmountsModalComponent extends DmModalAbstract {

    @Input() public capAmountDetails: ICapAmountDetails;
    @Input() public isAmountExceeds: boolean;
    @Input() public configuredAmtPercentage: number;
    @Input() public currency: string;
    public totalApprovedLaborAmount: number = 0;
    public totalApprovedExpenseAmount: number = 0;
    public totalApprovedTotalAmount: number = 0;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.CapAmounts);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus("closeCapAmounts", true);
        if (this.capAmountDetails) {
            if (this.capAmountDetails.laborCap && this.capAmountDetails.laborCap > 0) {
                this.totalApprovedLaborAmount = this.addIfNumbers(this.capAmountDetails.unbilledLaborCap, this.capAmountDetails.billedLaborCap);
            } else if (this.capAmountDetails.expenseCap && this.capAmountDetails.expenseCap > 0) {
                this.totalApprovedExpenseAmount = this.addIfNumbers(this.capAmountDetails.unbilledExpenseCap, this.capAmountDetails.billedExpenseCap);
            } else if (this.capAmountDetails.totalContractCap && this.capAmountDetails.totalContractCap > 0) {
                this.totalApprovedTotalAmount = this.addIfNumbers(this.capAmountDetails.totalUnbilled, this.capAmountDetails.totalBilledContractCap);
            }
        }
    }

    /**
     * Adds two numbers. If any of the numbers are undefined, the number will not be added. 
     * Returns zero if one or both of the numbers are not valid.
     * @param a 
     * @param b 
     */
    private addIfNumbers(a: number, b: number): number {
        let ans: number = 0;
        if (a) {
            ans += a;
        }
        if (b) {
            ans += b;
        }
        return ans;
    }

}
