import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { FinancialChangeRequestModule } from "../financial-change-request/financial-change-request.module";
import { FinancialPlanApprovalsComponent } from "./financial-plan-approvals.component";
import { FormsModule } from "@angular/forms";
import { InlineSurveyModule } from "../shared/inline-survey/inline-survey.module";
import { NgModule } from "@angular/core";
import { UIRouterModule } from "@uirouter/angular";
import { ChangeRequestService } from "../../common/services/change-request.service";

@NgModule({
    declarations: [
        FinancialPlanApprovalsComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        FormsModule,
        InlineSurveyModule,
        FinancialChangeRequestModule,
        UIRouterModule.forChild()
    ],
    providers: [
        ChangeRequestService
    ],
    entryComponents: [
        FinancialPlanApprovalsComponent
    ],
    exports: [
        FinancialPlanApprovalsComponent
    ]
})
export class FinancialPlanApprovalModule { }
