import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ClinSlinModalComponent } from "./clin-slin-modal.component";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        ClinSlinModalComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        ClinSlinModalComponent,
    ],
    exports: [
        ClinSlinModalComponent,
    ]
})
export class ClinSlinModalModule { }
