import { Component, EventEmitter, Input, OnInit, Output, Inject, ViewChild, ElementRef, Renderer2, AfterViewInit, forwardRef } from "@angular/core";
import { debounceTime, distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { IOneProfileSerResAttr, ISelectedUserAttributes } from "./type-ahead-contracts";
import { Observable, Subject, from } from "rxjs";
import { DataService } from "../../../common/services/data.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { ContextStorageService } from "../../../common/services/contextstorage.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { merge } from "rxjs/observable/merge";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { LocalStorageKey, SourceConstants, LogEventConstants, ComponentPrefix } from "../../../common/application.constants";
import { DomSanitizer } from "@angular/platform-browser";
import { AADGraphService } from "../../../common/services/aad-graphapi.service";
import { IResourceImage } from "../../../common/services/contracts/resource-profile.contracts";
import { ErrorSeverityLevel } from "@fxp/fxpservices";


@Component({
    selector: "dm-type-ahead",
    templateUrl: "./type-ahead.html",
    styleUrls: ["./type-ahead.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DmTypeAheadComponent),
            multi: true
        }
    ]
})
export class DmTypeAheadComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    @Input() public btnSearchAriaLabelText: string; // Search users button
    @Input() public disabled: boolean;
    @Input() public noResults: string;
    @Input() public isRequired: boolean;
    @Input() public typeAheadPlaceholder: string;
    @Input() public typeAheadId: string;
    @Input() public typeAheadLabelText: string;
    @Input() public typeAheadUserValidationRequiredMessage: string;
    @Input() public selectedUser: ISelectedUserAttributes;
    @Input() public fteOnly: boolean;
    @Input() public fteAndContingentStaff: boolean = false;
    @Input() public ignoredExistingManagers: ISelectedUserAttributes[];
    @Input() public isMultiselect: boolean;
    @Input() public selectedUsers: ISelectedUserAttributes[];
    @Input() public modelValue: ISelectedUserAttributes;
    @Input() public btnCancelAriaLabelText: string; // Clear the input box
    @Input() public typeaheadMinLength: number;
    @Input() public isMandatory: boolean = true; // to add required property to the input field if it is mandatory
    @Input() public isTypeAheadV2: boolean = false;
    @Input() public multiSelectedUsersWidth: string = "";
    @Input() public showTypeAheadLabel: boolean = true;
    @Output() public onUserCleared: EventEmitter<any> = new EventEmitter();
    @Output() public selectedUserUpdated: EventEmitter<any> = new EventEmitter();
    @Output() public multiSelectedUsersUpdated: EventEmitter<any> = new EventEmitter();
    @Output() public multiSelectedRemovedUser: EventEmitter<any> = new EventEmitter();
    @ViewChild("typeAheadSearch", { static: false }) public searchInputText: ElementRef;

    public errorMessage: string;
    public searching: boolean;

    public onChange: (...args: any[]) => void;
    public onTouched: (...args: any[]) => void;
    @Input()
    public get value(): ISelectedUserAttributes {
        return this.modelValue;
    }
    public set value(val: ISelectedUserAttributes) {
        this.modelValue = val;
    }
    public focus$ = new Subject<string>();
    public selectedUsersArray: IOneProfileSerResAttr[] = [];  
      
    private resourceImages: IResourceImage[] = [];
    private ignoredBPIDs: number[];
    private isUserCleared: boolean = false;
    private readonly DASH_REGEX = /^\s*(([abvt]-)|(partners~)).*/; /* Regex that matches any non-FTE aliases */
    private maxLimitForUsersInLocalStorage: number;

    public constructor(
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Renderer2) private renderer: Renderer2,
        @Inject(DMLoggerService) private dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(ContextStorageService) private contextStorageService: ContextStorageService,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer
    ) { }

    public ngOnInit(): void {
        this.getUserImage();
        if (!this.typeAheadPlaceholder) {
            this.typeAheadPlaceholder = "Start typing user alias";
        }
        if (this.isMultiselect) {
            this.modelValue = undefined;
        }
        this.getStorageItems();
        this.configManagerService.initialize()
            .then(() => {
                this.maxLimitForUsersInLocalStorage = this.configManagerService.getValue<number>("maxLimitForUsersInLocalStorage");
            });
    }

    public ngAfterViewInit(): void {
        if (this.searchInputText !== undefined) {
            this.renderer.removeAttribute(this.searchInputText.nativeElement, "aria-multiline");
        }
    }

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * This is a basic setter that the forms API is going to use
     *
     * @param {*} value
     * @memberof DmTypeAheadComponent
     */
    public writeValue(value: ISelectedUserAttributes): void {
        if (value !== null && value !== undefined) {
            this.modelValue = value;
        }
    }

    /**
     *  Clear the input field.
     */
    public clearText(): void {
        this.modelValue = undefined;
        this.selectedUser = undefined;
        this.sharedFunctionsService.focus(this.typeAheadId, true);
        this.onUserCleared.emit(this.isUserCleared);
        if (!this.isMultiselect) {
            this.selectedUserUpdated.emit(this.selectedUser);
        }
        this.validateInput();
        this.dmLogger.logEvent(SourceConstants.Component.TypeAheadComponent, SourceConstants.Method.ClearText, LogEventConstants.ClearTextClick);
    }

    /**
     * Removes the given user item from the selected users list
     * @param user
     */
    public removeFromSelectedUsers(user: ISelectedUserAttributes, index: number): void {
        if (this.selectedUsers && (this.selectedUsers.length - 1) > index) {
            this.sharedFunctionsService.focus("selectedUsers-" + (index + 1), false);
        } else if (this.selectedUsers && (this.selectedUsers.length - 1) > 0 && (this.selectedUsers.length - 1) <= index) {
            this.sharedFunctionsService.focus("selectedUsers-" + (index - 1), false);
        } else {
            this.sharedFunctionsService.focus(this.typeAheadId, false);
        }
        this.selectedUsers = this.selectedUsers.filter((selectedUser: ISelectedUserAttributes) => selectedUser.userName !== user.userName);
        this.multiSelectedUsersUpdated.emit(this.selectedUsers);
        if (user) {
            this.multiSelectedRemovedUser.emit(user);
        }
        this.dmLogger.logEvent(SourceConstants.Component.TypeAheadComponent, SourceConstants.Method.RemoveFromSelectedUsers, LogEventConstants.RemoveMultiSelectUser);
    }

    /**
     * Search list on typing input
     * @param text$
     */
    public search = (text$: Observable<string>): unknown => {
        const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$).pipe(
            switchMap((term) => ((term === "") ? from(this.getStorageItems())
                : this.getUserList(term))
            ),
            tap(() => this.searching = false)
        );
    };

    /**
     * Logs an event when user clicks on search
     */
    public logSearchClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.TypeAheadComponent, SourceConstants.Method.LogSearchClick, LogEventConstants.SearchTypeAheadClick);
    }

    /**
     * Formats the API response so the object is limited to view only a string off one of the attributes.
     * This is the name INSIDE the typeahead input box.
     * Called when a user's name is selected for the typeahead or when the typeahead is initially populated.
     * @param user
     */
    public formatter = (user: { DisplayName: string; userName: string }): string => {
        return user.DisplayName ? user.DisplayName : user.userName;
        /* IOneProfileSerResAttr has attr DisplayName if the user is being selected from an API response.
        ISelectedUserAttributes has attr userName, which was previously populated from DisplayName from the API response,
        and is used when a selected user already exists. */
    };

    /**
     * When a user is selected, this will be activated. Formats the user to match our existing contracts
     * and emits the event indicating the user(s) have been updated.
     * @param item
     */
    public onUserSelect(item: { item: IOneProfileSerResAttr; preventDefault(): any }): void {
        const selectedItem = item.item;
        if (!selectedItem.BusinessPartnerId) {
            /* Not all users being returned from One Profile have BPIDs, this is a catch scenario for dev environment */
            return;
        }
        if (!this.isMultiselect) {

            this.selectedUser = {
                userAlias: selectedItem.Alias,
                userName: selectedItem.DisplayName, /* Display Name is Preferred First Name  + Last Name*/
                bpId: selectedItem.BusinessPartnerId,
                emailName: selectedItem.EmailName,
                firstName: selectedItem.PreferredFirstName ? selectedItem.PreferredFirstName : selectedItem.FirstName,
                lastName: selectedItem.LastName,
                fullName: selectedItem.FullName, /* Full Name is provided as-is from One Profile's source, and may not be preferred or match display name.*/
                image: "",
                workCity: selectedItem.WorkLocation ? selectedItem.WorkLocation.City : undefined,
                workState: selectedItem.WorkLocation ? selectedItem.WorkLocation.State : undefined,
                workCountryName: selectedItem.WorkLocation ? selectedItem.WorkLocation.CountryName : undefined,
                workCountryCode: selectedItem.WorkLocation ? selectedItem.WorkLocation.CountryCode : undefined
            };
            this.getUserImage();
            this.selectedUserUpdated.emit(this.selectedUser);
        } else {
            item.preventDefault();
            this.modelValue = undefined;
            /* The formatter blanks out the display name in the input box once a user has been selected
            since you can select multiple users here.  */
            this.formatter = (): string => undefined;

            if (this.selectedUsers) {
                const filter = this.selectedUsers.filter((selectedUser: ISelectedUserAttributes) => Number(selectedUser.bpId) === Number(selectedItem.BusinessPartnerId));
                if (!filter.length) {
                    this.selectedUsers.push(
                        {
                            userAlias: selectedItem.Alias,
                            userName: selectedItem.DisplayName, /* Display Name is Preferred First Name  + Last Name */
                            bpId: selectedItem.BusinessPartnerId,
                            emailName: selectedItem.EmailName,
                            firstName: selectedItem.PreferredFirstName ? selectedItem.PreferredFirstName : selectedItem.FirstName,
                            lastName: selectedItem.LastName,
                            fullName: selectedItem.FullName, /* Full Name is provided as-is from One Profile's source, and may not be preferred or match display name.*/
                            image: "",
                            workCity: selectedItem.WorkLocation ? selectedItem.WorkLocation.City : undefined,
                            workState: selectedItem.WorkLocation ? selectedItem.WorkLocation.State : undefined,
                            workCountryName: selectedItem.WorkLocation ? selectedItem.WorkLocation.CountryName : undefined,
                            workCountryCode: selectedItem.WorkLocation ? selectedItem.WorkLocation.CountryCode : undefined
                        }); 
                    this.getUserImage();
                    this.selectedUserUpdated.emit(selectedItem);
                    this.multiSelectedUsersUpdated.emit(this.selectedUsers);
                }
            }
        }
        this.addToLocalStorage(selectedItem);
        this.validateInput();
    }

    /**
     * Validating input.
     * Called on every input, including inputs less than 3 chars
     */
    public validateInput(): void {
        this.errorMessage = this.getErrorMessage(this.modelValue ? this.modelValue.userName : "");
    }

    /**
     * Adds the class to the dropdown menu to add the content "Recent Selections" at the bottom of menu on showing last selected items list.
     * Removes the class to the dropdown menu to remove the content "Recent Selections" when something is typed and searched.
     */
    public onFocus(value: string): void {
        const dropdownItemFirstChildElement = document.getElementsByClassName("dropdown-item")[0];
        if (dropdownItemFirstChildElement) {
            const dropdownElement: HTMLElement = dropdownItemFirstChildElement.parentElement;
            if (dropdownElement && dropdownElement.classList.toString().indexOf("dropdown-menu-selected-items") !== -1) {
                dropdownElement.classList.remove("dropdown-menu-selected-items");
            }
            if (dropdownElement && !value) {
                dropdownElement.classList.add("dropdown-menu-selected-items");
            }
        }
    }

    /**
     * Return error message
     * @param value
     */
    private getErrorMessage(value: string): string {
        if (!value) {
            if (this.isRequired && !this.selectedUser) {
                return this.typeAheadUserValidationRequiredMessage;
            }
        }
        if (this.fteOnly && value && this.DASH_REGEX.test(value)) {
            return "Only FTEs can fill this role";
        }

        return "";
    }

    /**
     * Sets error message when there are no results for the given search text.
     * @param searchText
     */
    private setNoResults(searchText: string): void {
        this.errorMessage = "No results found for '" + searchText + "'";
    }

    /**
     * Set has results text
     */
    private setHasResults(): void {
        this.errorMessage = "";
    }

    /**
     * Getting users list based on input vlaue
     * @param searchValue
     */
    private async getUserList(searchValue: string): Promise<IOneProfileSerResAttr[]> {
        this.searching = true;
        if (this.selectedUser) {
            this.selectedUser = undefined;
        }
        if (!this.getErrorMessage(searchValue)) {
            /* Builds a list of BPIDs for One Profile to filter out */
            this.ignoredBPIDs = [];
            if (this.isMultiselect && this.selectedUsers) {
                /* Filter out all users who have already been selected from the results */
                for (const user of this.selectedUsers) {
                    if (user && user.bpId) {
                        this.ignoredBPIDs.push(Number(user.bpId));
                    }
                }
            }
            /* Grabs the BPID of out-of-scope managers that were passed in through component binding, remove them from search results as well. */
            if (this.ignoredExistingManagers && this.ignoredExistingManagers.length) {
                for (const user of this.ignoredExistingManagers) {
                    if (user && user.bpId) {
                        this.ignoredBPIDs.push(Number(user.bpId));
                    }
                }
            }
            if (searchValue && searchValue.length !== 4) {
                await this.sharedFunctionsService.delayExecution(500);
            }
            return this.oneProfileService.profileSearch(searchValue, this.fteOnly, this.ignoredBPIDs)
                .then((response: IOneProfileSerResAttr[]) => {
                    this.setHasResults();
                    response = response.filter((profile) => profile.Alias != null);
                    return response;
                })
                .catch((error) => {
                    this.searching = false;
                    this.setNoResults(searchValue);
                    this.dmLogger.logError(ComponentPrefix + "Type-Ahead", SourceConstants.Method.GetUserList, error, this.getErrorMessage(searchValue), null, undefined, DataService.getCorrelationIdFromError(error), ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return [];
                });
        } else {
            this.searching = false;
        }
    }

    /**
     * Retrieve and return the items stored in local storage. In case of an error, return an empty array.
     */
    private getStorageItems(): Promise<IOneProfileSerResAttr[]> {
        return this.contextStorageService.readContent(LocalStorageKey.TypeAheadSelectedUsers)
            .then((data) => {
                return JSON.parse(data);
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.dmLogger.logError(ComponentPrefix + "Type-Ahead", SourceConstants.Method.GetStorageItems, error, errorMessage, null, undefined, DataService.getCorrelationIdFromError(error), ErrorSeverityLevel && ErrorSeverityLevel.High);
                return [];
            });
    }

    /**
     * Add selected user in the fxpContext
     * @param selectedUser
     */
    private addToLocalStorage(selectedUser: IOneProfileSerResAttr): void {
        const isSelectedUserInLocalStorage = this.selectedUsersArray && this.selectedUsersArray.some((typeaheadSearchItem) => typeaheadSearchItem.Alias.toLowerCase() === selectedUser.Alias.toLowerCase());
        if (!isSelectedUserInLocalStorage) {
            this.selectedUsersArray.unshift(selectedUser);
        }
        if (this.selectedUsersArray && this.selectedUsersArray.length > this.maxLimitForUsersInLocalStorage) {
            this.selectedUsersArray.pop();
        }
        this.contextStorageService.saveContent(LocalStorageKey.TypeAheadSelectedUsers, JSON.stringify(this.selectedUsersArray));
    }

    /**
     * gets image for the selected user from the graph api
     * @param selectedUser
     */
    private getUserImage(): void {
        if (this.selectedUser) {
            this.aadGraphService.getResourceThumbnailPicture(this.selectedUser.userAlias)
                .then((resourcePhotoResponse) => {
                    if (resourcePhotoResponse) {
                        this.selectedUser.image = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + resourcePhotoResponse);
                    }
                });
        }
        if (this.selectedUsers && this.selectedUsers.length) {
            for (const selectedUser of this.selectedUsers) {
                this.aadGraphService.getResourceThumbnailPicture(selectedUser.userAlias)
                    .then((resourcePhotoResponse) => {
                        if (resourcePhotoResponse) {
                            this.resourceImages.push({
                                resourceAlias: selectedUser.userAlias,
                                resourceImage: resourcePhotoResponse
                            });
                            selectedUser.image = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + resourcePhotoResponse);
                        }
                    });
            }
        }
    }

}
