<section id="edit-engagement-modal">
    <div role="dialog" aria-labelledby="ebsEditEngagementHeading" aria-modal="true">
        <div role="document">
            <form #addRoleModalForm>
                <div class="popUpModalDialog editEngagementForm">
                    <div class="modal-header modal-noborder">
                        <button type="button" [attr.aria-label]="(roletype ? 'Edit Role':'Add Role') +' Dialog Close'"
                            class="close set-focus" id="accessibilityConstants.ClosePopUp"
                            (keydown)="moveFocusPrev($event, 'accessibilityConstants.Submit');"
                            (click)="closeModal()"><span aria-hidden="true" class="icon icon-close"></span></button>
                        <h4 class="modal-title font-subtitle" id="ebsEditEngagementHeading">
                            {{roletype ? 'Edit Role':'Add Role'}}
                        </h4>
                    </div>
                    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [errorText]="errorText"
                        [showLoading]="true" *ngIf="isRoleDetailsLoading">
                    </dm-loading>
                    <div class="modal-body" *ngIf="!isRoleDetailsLoading">
                        <section class="modal-body-container">
                            <section class="input__label p-b-6">
                                <label class="input__label--text input__label--d-b font-caption-alt" for="roleType">Role
                                    <span class="required-mark" aria-hidden="true">*</span>
                                    <dm-tooltip *ngIf="isSpecialRoleToAutoCommit" role="tooltip"
                                        class="dm-tooltipinline" [dmPlacement]="'right'"
                                        [ngbTooltipContent]="autoCommitRoleToolTipText"
                                        [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="AutoCommit Role">
                                    </dm-tooltip>
                                </label>
                                <section class="input__select--icon">
                                    <select id="roleType" name="roleType" class="input__select input__select--big"
                                        [(ngModel)]="roleType" required (ngModelChange)="validateToSpecialRole()">
                                        <option value="undefined">Select</option>
                                        <option *ngFor="let role of roleValues | orderBy : 'roleName'" [ngValue]="role">
                                            {{role.roleName}}
                                        </option>
                                    </select>
                                    <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                                </section>
                                <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                    aria-live="polite" *ngIf="roleDetailForEdit?.errorDetails?.Role">
                                    {{roleDetailForEdit?.errorDetails?.Role}}
                                </span>
                            </section>
                            <section
                                *ngIf="internalEngagementDetails && internalEngagementDetails.projects && internalEngagementDetails.projects.length > 0"
                                class="input__label p-b-6">
                                <label class="input__label--text input__label--d-b font-caption-alt" for="roleType">
                                    EBS ID
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <section class="input__select--icon">
                                    <select id="task" name="task" class="input__select input__select--big" aria-label="select task"
                                        [(ngModel)]="selectedTaskId" required>
                                        <option *ngFor="let task of laborTaskDetails" [ngValue]="task.taskId">
                                            {{task.taskId}} - {{task.taskName}}
                                        </option>
                                    </select>
                                    <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                                </section>
                            </section>
                            <section class="col-md-12 col-sm-12 pad-l-r-0 p-b-12 modal__input--calendar">
                                <section class="col-md-6 col-sm-6 editCal rightpad pull-left pad-l-r-0">
                                    <label class="font-caption-alt" for="startDate">
                                        Start Date
                                    </label>
                                    <p class="input-group">
                                        <dm-datepicker *ngIf="!isRoleDateRestrictionDisabled" [modelDate]="engStartDate"
                                            [placeholder]="'dd-mm-yyyy'" [(ngModel)]="engStartDate"
                                            [minDate]="engStartDate" [maxDate]="engEndDate" name="engStartDate"
                                            ariaLabelButton="Role Start date" ariaLabelCalendar="Start date"
                                            (onDateChange)="isEntityStartDateValid($event)"
                                            previosFousingElementId="roleType">
                                        </dm-datepicker>
                                        <dm-datepicker *ngIf="isRoleDateRestrictionDisabled" [modelDate]="engStartDate"
                                            [placeholder]="'dd-mm-yyyy'" [(ngModel)]="engStartDate" name="engStartDate"
                                            ariaLabelButton="Role Start date" ariaLabelCalendar="Start date"
                                            (onDateChange)="isEntityStartDateValid($event)"
                                            previosFousingElementId="roleType">
                                        </dm-datepicker>
                                    </p>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isStartDateRequired">
                                        {{startDateValMsg}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isAfterRoleEndDate">
                                        {{startDateValMsg}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="!isStartDateValid">
                                        {{startDateValMsg}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite"
                                        *ngIf="roleDetailForEdit?.errorDetails?.StartDate">
                                        {{roleDetailForEdit?.errorDetails?.StartDate}}
                                    </span>
                                </section>
                                <section class="col-md-6 col-sm-6 editCal pull-right l-16">
                                    <label class="font-caption-alt" for="endDate">
                                        End Date
                                    </label>
                                    <p class="input-group">
                                        <dm-datepicker *ngIf="!isRoleDateRestrictionDisabled" [modelDate]="engEndDate"
                                            [placeholder]="'dd-mm-yyyy'" ariaLabelButton="Role End date"
                                            [minDate]="engStartDate" [maxDate]="engEndDate" name="engEndDate"
                                            ariaLabelCalendar="End date" (onDateChange)="isEntityEndDateValid($event)"
                                            previosFousingElementId="calenderBtn_Role Start date"></dm-datepicker>
                                        <dm-datepicker *ngIf="isRoleDateRestrictionDisabled" [modelDate]="engEndDate"
                                            [placeholder]="'dd-mm-yyyy'" ariaLabelButton="Role End date"
                                            name="engEndDate" ariaLabelCalendar="End date"
                                            (onDateChange)="isEntityEndDateValid($event)"
                                            previosFousingElementId="calenderBtn_Role Start date"></dm-datepicker>
                                    </p>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isEndDateRequired">
                                        {{endDateValMsg}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="isBeforeRoleStartDate">
                                        {{endDateValMsg}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite" *ngIf="!isEndDateValid">
                                        {{endDateValMsg}}
                                    </span>
                                    <span role="alert" class="font-caption-alt inp-validation errorMsg"
                                        aria-atomic="true" aria-live="polite"
                                        *ngIf="roleDetailForEdit?.errorDetails?.EndDate">
                                        {{roleDetailForEdit?.errorDetails?.EndDate}}
                                    </span>
                                </section>
                                <div class="clearfix"></div>
                            </section>

                            <section class="col-md-12 col-sm-12 pad-l-r-0 p-b-12 modal__input--text">
                                <label class="font-caption-alt" for="laborHours">Requested Labor <span
                                        class="font-label">(hours)</span>
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <input class="col-md-12 form-control font-caption-alt input--order" type="text"
                                    id="laborHours" (keypress)="isRequestedHourInteger($event)" name="laborHours"
                                    placeholder="Requested Labor (hours)" [(ngModel)]="laborHours" required aria-invalid="false"
                                    (ngModelChange)="validateNBUEHours()"
                                    [ngClass]="{'inp-error-border' : addRoleModalForm.laborHours.$invalid && !addRoleModalForm.laborHours.$pristine}" />
                                <span role="alert" class="sr-only" aria-live="polite"
                                    *ngIf="addRoleModalForm.laborHours.$invalid && !addRoleModalForm.laborHours.$pristine">{{laborHoursValMsg}}</span>
                                <span role="alert" class="font-caption-alt inp-validation" aria-live="polite"
                                    *ngIf="laborHours && !isNBUEHoursValid">
                                    {{laborHoursValMsg}}
                                </span>
                                <span role="alert"
                                    class="font-caption-alt inp-validation col-sm-6 col-md-6 col-xs-5 p-t-4"
                                    aria-live="polite"
                                    [hidden]="!addRoleModalForm.laborHours.$invalid || addRoleModalForm.laborHours.$pristine">{{laborHoursValMsg}}</span>

                            </section>
                            <section class="col-md-12 col-sm-12 pad-l-r-0 input__label pull-left p-b-6" *ngIf="false" >
                                <label class="input__label--text input__label--d-b font-caption-alt"
                                    for="roleDomainName">Domain
                                    <span class="required-mark" aria-hidden="true">*</span>
                                </label>
                                <section class="input__select--icon">
                                    <select id="roleDomainName" name="roleDomainName"
                                        class="input__select input__select--big" [(ngModel)]="domain"
                                        (change)="onDomainChange()" required>
                                        <option value="">Select Solution Area</option>
                                        <option *ngFor="let domain of internalEngagementDomains" [ngValue]="domain">
                                            {{domain.domainValueName}}
                                        </option>
                                    </select>
                                    <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                                </section>
                            </section>
                            <section class="input__label pull-left p-b-6">
                                <label class="font-caption-alt" for="taSkills">Skills
                                    <span class="font-label">
                                        (6 maximum)
                                    </span>
                                    <span class="required-mark"  *ngIf="isSkillRequired" aria-hidden="true">*</span>
                                </label>
                                <dm-type-ahead-skills [typeAheadId]="'taSkills'"
                                    [btnSearchAriaLabelText]="taSkillsSearchAriaLblTxt"
                                    [typeAheadLabelText]="taSkillsLabelText"
                                    [btnCancelAriaLabelText]="taSkillsCancelAriaLblTxt"
                                    [typeAheadUserValidationRequiredMessage]="taSkillsRequiredMsg"
                                    [selectedSkills]="taRoleSkills" [isRequired]="isSkillRequired" [isMultiselect]="true"
                                    [maxSkillsAllowToSelect]="6" [fteOnly]="false" [modelValue]="taSkillsNoResultsModel"
                                    [domain]="domain" (skillValidityUpdated)="updateSkillsValidity($event)">
                                </dm-type-ahead-skills>
                                <span role="alert" class="font-caption-alt inp-validation errorMsg" aria-atomic="true"
                                    aria-live="polite" *ngIf="isDomainSkillInvalid && domainSkillValidationMessage">
                                    {{domainSkillValidationMessage}}
                                </span>
                                <span role="alert" class="font-caption-alt inp-validation errorMsg warning-color"
                                    aria-atomic="true" aria-live="polite"
                                    *ngIf="roleDetailForEdit?.errorDetails?.SkillsDomain">
                                    Warning: {{roleDetailForEdit?.errorDetails?.SkillsDomain}}
                                </span>
                            </section>
                            <section class="col-md-12 col-sm-12 pad-l-r-0 p-b-6">
                                <section class="col-md-8 col-sm-8 pull-left pad-l-r-0 p-b-6">
                                    <label class="font-caption-alt" for="additionalDetails">Additional Details & Travel
                                        Notes (optional)</label>
                                    <textarea class="form-control input__textarea" name="additionalDetails"
                                        [(ngModel)]="additionalDetails" aria-label="Additional Details multiline"
                                        placeholder="Provide additional details & travel notes"></textarea>
                                </section>
                                <section class="col-md-4 col-sm-4 pull-right p-b-6 l-44">
                                    <fieldset>
                                        <legend class="font-caption-alt" for="Delevery Type">Delivery Type</legend>
                                        <input type="radio" class="input__radio--select" name="deliveryType"
                                            value="Remote" aria-label="Remote" [(ngModel)]="deliveryType" /><span
                                            class="p-l-5 font-caption-alt">Remote</span><br />
                                        <input type="radio" class="input__radio--select" name="deliveryType"
                                            value="Onsite" aria-label="Onsite" [(ngModel)]="deliveryType"><span
                                            class="p-l-5 font-caption-alt">Onsite</span>
                                    </fieldset>
                                </section>
                            </section>
                            <section class="col-md-12 col-sm-12 pad-l-r-0 p-b-6 modal__input--text">
                                <label class="font-caption-alt" for="resourceBillable">Billable
                                </label>
                                <label class="switch">
                                    <input type="checkbox" id="resourceBillable" name="resourceBillable"
                                        (change)="onResourceBillableToggle()" [checked]="isResourceBillable"
                                        [disabled]="isResourceBillableFieldDisabled"
                                        [attr.aria-label]="(isResourceBillable ? 'resource status set to billable' : 'resource status set to non billable')">
                                    <span class="slider round"></span>
                                </label>
                            </section>
                            <section *ngIf="showResourceScheduleField"
                                class="col-md-12 col-sm-12 pad-l-r-0 p-b-6 modal__input--text">
                                <label class="font-caption-alt" for="resourceScheduleToggle">Resource Calendar Show as
                                    Free
                                </label>
                                <label class="switch">
                                    <input type="checkbox" id="resourceScheduleToggle" name="resourceScheduleToggle"
                                        (change)="onScheduleToggle()" [checked]="isResourceScheduleFree"
                                        [attr.aria-label]="(isResourceScheduleFree ? 'resource calendar becomes free' : 'resource calendar becomes occupied')">
                                    <span class="slider round"></span>
                                </label>
                            </section>
                            <section class="modal__input--text">
                                <label class="font-caption-alt" for="ResourceReq">Requested Resource
                                    <span *ngIf="!isSpecialRoleToAutoCommit && !isResourceScheduleFree && !isSrOutFederalSupportEngagement"
                                        class="font-label">
                                        (optional)
                                    </span>
                                </label>
                                <dm-type-ahead [typeAheadId]="'ResourceReq'"
                                    [btnSearchAriaLabelText]="taSearchAriaLblTxt"
                                    [btnCancelAriaLabelText]="taCancelAriaLblTxt"
                                    [typeAheadUserValidationRequiredMessage]="requiredMsg" [noResults]="noResults"
                                    [disabled]="false" [selectedUser]="taRequestedResource"
                                    (selectedUserUpdated)="selectedUserUpdated($event)" [isRequired]="true"
                                    [isMultiselect]="false" [fteAndContingentStaff]="true" [typeaheadMinLength]="3"
                                    [ignoredExistingManagers]="true" [modelValue]="taRequestedResource"
                                    (onUserCleared)="onUserCleared()">
                                </dm-type-ahead>
                                <span role="alert" class="font-caption-alt inp-validation" aria-live="polite"
                                    *ngIf="isRequestedResourceEmpty">{{reqResourceValMsg}}</span>
                                <span role="alert" class="font-caption-alt inp-validation" aria-live="polite"
                                    *ngIf="isRequestedResourceAccountAligned">{{reqResourceAccountAlignedValMsg}}</span>
                                <span role="alert" class="font-caption-alt inp-validation" aria-live="polite"
                                    *ngIf="isResourceTrainingExpired && selectedUser">
                                    {{addRoleErrorMessages.SelectedResourceCannotBeAssigned}}
                                </span>
                            </section>
                        </section>
                    </div>
                </div>
                <div class="modal-footer" *ngIf="!isRoleDetailsLoading">
                    <button type="button" class="fxp-btn fxp-btn--secondary"
                        id="accessibilityConstants.Cancel" (click)="closeModal()"
                        (keydown)="moveFocusNext($event, 'accessibilityConstants.Submit');">
                        Cancel
                    </button>
                    <button class="fxp-btn fxp-btn--primary" id="accessibilityConstants.Submit"
                        type="button" [disabled]="isSaveButtonDisabled()" (click)="saveRoleDetails()"
                        (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp');">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</section>