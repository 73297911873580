import { Component, forwardRef, Inject, Input, Output, EventEmitter } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { Components, PlanForecastAuditThreshold } from "../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { IAuditEntriesApiResponse, IPlanForecastAuditValues, IAuditResponse } from "../../../common/services/contracts/audit.contracts";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { AADGraphService } from "../../../common/services/aad-graphapi.service";
import { DomSanitizer } from "@angular/platform-browser";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { AuditService } from "../../../common/services/audit.service";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsApiV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { Store } from "@ngrx/store";
import { IState } from "../../../store/reducers";
import { untilDestroyed } from "ngx-take-until-destroy";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";

@Component({
    selector: "dm-plan-forecast-audit-history-modal",
    templateUrl: "./plan-forecast-audit-history-modal.html",
    styleUrls: ["./plan-forecast-audit-history-modal.scss"]
})
export class PlanForecastAuditHistoryComponent extends DmModalAbstract {
    @Input() public selectedPlanContext: string;
    @Output() public onStatusModalClose: EventEmitter<void> = new EventEmitter<void>();
    public auditHistoryData: any;
    public currencySymbol: string;
    public isServerError: boolean;
    public errorText: string;
    public headerTitle: string;
    public wbsId: string;
    private isProjectContext: boolean;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(AuditService) private auditService: AuditService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(activeModal, dmLogger, Components.PlanForecastAuditHistory);
    }

    public ngOnInit(): void {

        const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.isProjectContext = projectId ? true : false;

        this.wbsId = this.isProjectContext ? projectId : engagementId;

        this.storeDispatchService
            .requireEngagementDetails(this.wbsId, true)
            .load();

        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.wbsId));

        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails) => {
            if (engagementDetails.loaded) {
                const engagementDetailsResult: IEngagementDetailsApiV2 = engagementDetails.engagementDetails;
                this.currencySymbol = engagementDetailsResult.currency;
                if (this.isProjectContext) {
                    // Pass only project id to get audit entries of project in project context.
                    this.setPlanForecastAuditData([projectId], this.selectedPlanContext);
                } else {
                    const wbsIds: string[] = [];
                    // Get all project ids and push into a list along with engagement id to get audit entries of engagement and projects under it.
                    engagementDetailsResult.projects.forEach((project) => wbsIds.push(project.id));
                    wbsIds.push(engagementId);
                    this.setPlanForecastAuditData(wbsIds, this.selectedPlanContext);
                }
            }

            this.refreshOnItemInvalidation(engagementDetails);
            this.setErrorsBasedOnItemState(engagementDetails);
            this.setLoadersBasedOnItemState(engagementDetails);
            if ((engagementDetails.loaded && engagementDetails.error)) {
                this.isServerError = true;
            }
        });

        switch (this.selectedPlanContext) {
            case "CurrentFinancialPlan": {
                this.headerTitle = "Current Financial Plan";
            }
            case "DeliveryBaseline": {
                this.headerTitle = "Delivery Baseline";
            }
            case "Forecast": {
                this.headerTitle = "Forecast";
            }
            default: {
                this.headerTitle = "Forecast";
            }
        }
    }

    /**
     * Closes modal and emits an event to notify notification component of closure.
     *
     * @memberof PlanForecastAuditHistoryComponent
     */
    public closeStatusModal(): void {
        this.onStatusModalClose.emit();
        this.closeModal();
    }

    /**
     * Set Plan and Forecast audit data
     *
     * @private
     * @param {string} wbsIdList
     * @param {string} selectedTab
     * @memberof PlanForecastAuditHistoryComponent
     */
    private setPlanForecastAuditData(wbsIdList: string[], selectedTab: string): void {
        this.auditService.getAuditHistoryForWbsList(wbsIdList, "Forecast").then((response: IAuditEntriesApiResponse) => {
            this.isComponentLoading = false;
            if (selectedTab === "Forecast") {
                const etcAuditData = this.getAuditDataByVersion(response.forecastAuditEntries, "Forecast");
                const etcAuditViewModelList = this.mapToAuditViewModel(etcAuditData);

                const eacAuditData = this.getAuditDataByVersion(response.forecastAuditEntries, "EAC");
                const eacAuditViewModelList = this.mapToAuditViewModel(eacAuditData);

                const temp = [...eacAuditViewModelList, ...etcAuditViewModelList];
                const sortedTemp = temp.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
                const array = [];
                for (let t = 0; t < sortedTemp.length; t++) {
                    if (sortedTemp[t + 1]) {
                        if (Math.abs(sortedTemp[t + 1].createdOn.diff(sortedTemp[t].createdOn)) < PlanForecastAuditThreshold) {
                            sortedTemp[t].items = [
                                {
                                    version: sortedTemp[t].items[0].version,
                                    values: sortedTemp[t].items[0].values,
                                    priority: sortedTemp[t].items[0].version === "Forecast" ? 1 : 2
                                },
                                {
                                    version: sortedTemp[t + 1].items[0].version,
                                    values: sortedTemp[t + 1].items[0].values,
                                    priority: sortedTemp[t + 1].items[0].version === "Forecast" ? 1 : 2
                                }
                            ];
                            this.sharedFunctionsService.sortListByPropertyBasedOnOrder("priority", false, sortedTemp[t].items);
                            array.push(sortedTemp[t]);
                        }
                    }
                }
                this.auditHistoryData = this.groupEntriesByDate(array);
            } else {
                const auditDataByVersion = this.getAuditDataByVersion(response.forecastAuditEntries, selectedTab);
                const auditViewModelList = this.mapToAuditViewModel(auditDataByVersion);
                this.auditHistoryData = this.groupEntriesByDate(auditViewModelList);
            }
        }).catch((error) => {
            this.isComponentLoading = false;
            this.isServerError = true;
            this.errorText = error && error.data && error.data.InnerErrors && error.data.InnerErrors.length > 0 && error.data.InnerErrors[0].Messages;
        });
    }

    /**
     * Initialies audit data based on forecast entries and context
     *
     * @param {*} forecastAuditEntries
     * @param {*} currentContext
     * @memberof PlanForecastAuditHistoryComponent
     */
    private getAuditDataByVersion(forecastAuditEntries: IAuditResponse[], currentContext: string): any {
        const filteredAuditData = [];
        for (const auditItem of forecastAuditEntries) {
            if ((auditItem.audit as IPlanForecastAuditValues).version === currentContext) {
                filteredAuditData.push(auditItem);
            }
        }
        return filteredAuditData;
    }

    /**
     * Maps audit view model from audit api response 
     *
     * @private
     * @param {IAuditResponse[]} auditData
     * @memberof PlanForecastAuditHistoryComponent
     */
    private mapToAuditViewModel(auditDataByVersion: IAuditResponse[]): any {
        const auditData = auditDataByVersion.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
        const auditViewModelList = [];
        for (let n = 0; n < auditData.length; n++) {
            if (auditData[n] && auditData[n].audit) {
                const auditViewModel: any = {};
                this.oneProfileService.getProfile(auditData[n].createdBy).then((response) => {
                    if (response && response.DisplayName) {
                        auditViewModel.status = `Updated by <b>${response.DisplayName}</b> (${response.Alias}) on ${auditData[n].wbsId}`;
                    }
                });
                this.aadGraphService.getResourceThumbnailPicture(auditData[n].createdBy).then((imageData) => {
                    if (imageData) {
                        auditViewModel.userImage = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + imageData);
                    }
                });
                auditViewModel.date = moment(auditData[n].createdOn).format("DD-MMM-YYYY");
                auditViewModel.time = moment(auditData[n].createdOn).format("hh:mm:ss A");
                auditViewModel.createdOn = moment(auditData[n].createdOn);
                auditViewModel.type = auditData[n].auditType;
                auditViewModel.version = (auditData[n].audit as IPlanForecastAuditValues).version;
                auditViewModel.items = [this.mapAuditValues((auditData[n].audit as IPlanForecastAuditValues), auditData[n + 1] && (auditData[n + 1].audit as IPlanForecastAuditValues))];
                auditViewModelList.push(auditViewModel);
            }
        }
        return auditViewModelList;
    }

    /**
     * Group entries by date
     *
     * @private
     * @param {*} auditViewModelList
     * @return {*}  {*}
     * @memberof PlanForecastAuditHistoryComponent
     */
    private groupEntriesByDate(auditViewModelList): any {
        // this gives an object with dates as keys
        const auditGroups = auditViewModelList.reduce((groups, auditItem) => {
            const date = auditItem.date;
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(auditItem);
            return groups;
        }, {});

        // Add it to array with date as key
        const groupArrays = Object.keys(auditGroups).map((date) => {
            return {
                date,
                auditItemsPerDate: auditGroups[date]
            };
        });
        return groupArrays;
    }

    /**
     * Maps audit values by calculating variances.
     *
     * @private
     * @param {IPlanForecastAuditValues} currAuditItem
     * @param {IPlanForecastAuditValues} prevAuditItem
     * @return {*} 
     * @memberof PlanForecastAuditHistoryComponent
     */
    private mapAuditValues(currAuditItem: IPlanForecastAuditValues, prevAuditItem: IPlanForecastAuditValues) {
        return {
            version: currAuditItem.version,
            values: [{
                title: "Revenue",
                unit: this.currencySymbol,
                change: prevAuditItem ? (currAuditItem.revenue - prevAuditItem.revenue) / currAuditItem.revenue : 0,
                currValue: currAuditItem && currAuditItem.revenue,
                prevValue: prevAuditItem && prevAuditItem.revenue ? prevAuditItem && prevAuditItem.revenue : currAuditItem && currAuditItem.revenue,
                version: currAuditItem.version
            },
            {
                title: "Cost",
                unit: this.currencySymbol,
                change: prevAuditItem ? (currAuditItem.cost - prevAuditItem.cost) / currAuditItem.cost : 0,
                currValue: currAuditItem && currAuditItem.cost,
                prevValue: prevAuditItem && prevAuditItem.cost ? prevAuditItem && prevAuditItem.cost : currAuditItem && currAuditItem.cost,
                version: currAuditItem.version
            },
            {
                title: "Labor",
                unit: "hours",
                change: prevAuditItem ? (currAuditItem.labor - prevAuditItem.labor) / currAuditItem.labor : 0,
                currValue: currAuditItem && currAuditItem.labor,
                prevValue: prevAuditItem && prevAuditItem.labor ? prevAuditItem && prevAuditItem.labor : currAuditItem && currAuditItem.labor,
                version: currAuditItem.version
            },
            {
                title: "Margin",
                unit: "",
                change: prevAuditItem ? (currAuditItem.margin - prevAuditItem.margin) / currAuditItem.margin : 0,
                currValue: currAuditItem && currAuditItem.margin,
                prevValue: prevAuditItem && prevAuditItem.margin ? prevAuditItem && prevAuditItem.margin : currAuditItem && currAuditItem.margin,
                version: currAuditItem.version
            }]
        };
    }
}

