import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, Inject, forwardRef, Injector } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { Components, RouteName, FinancialType, BaseLineType, ComponentFailureMessages, NoDataText, SourceConstants, AccessibilityConstants } from "../../common/application.constants";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FinancialService } from "../../common/services/financial.service";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireProjectDetails } from "../../store/project-details/project-details.selector";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { IEntityFinancialSummary, IFinancialPlanV2 } from "../financial-mgmt/financial.model";
import { IFinancialDetailsV2State } from "../../store/financial-details-v2/financial-details-v2.reducer";
import { IProjectDetailsState } from "../../store/project-details/project-details.reducer";
import { IState } from "../../store/reducers";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ViewWorkBookModalComponent } from "./view-workbook-modal/view-workbook-modal.component";
import { DmError } from "../../common/error.constants";
import { ChangeRequestService } from "../../common/services/change-request.service";
import { AmendmentType, IChangeRequest } from "../../common/services/contracts/changerequest.contract";
import { AmendmentsService } from "../../common/services/amendments.service";

@Component({
    selector: "dm-amendments",
    templateUrl: "./amendments.html",
    styleUrls: ["./amendments.scss"]
})
export class AmendmentsComponent extends DmComponentAbstract {

    public currentPage: number; // for pagination that is not yet implemented
    public currentBaseLineDetails: IEntityFinancialSummary;
    public currentBaseLinePlanDetails: IFinancialPlanV2;
    public crDetailsList: IChangeRequest[] = [];
    public noOfAmendmentsPerPage: number = 3;
    public currency: string;
    public amendmentDetailsRoute: string;
    public misalignedAmendmentDetailsRoute: string = RouteName.EngagementMisalignedAmendmentDetails;
    public noAmendments = NoDataText.NoAmendments;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.Amendments;
    public accessibilityConstants = AccessibilityConstants;
    private engagementId: string;
    private inProgressState: string = "In Progress";

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector,
        @Inject(ChangeRequestService) private changeRequestService: ChangeRequestService,
        @Inject(AmendmentsService) private amendmentsService: AmendmentsService
    ) {
        super(dmLogger, Components.FinancialAmendments);
    }

    public ngOnInit(): void {
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.errorText = ComponentFailureMessages.EngagementAmendmentComponent;
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const isProjectContext: boolean = projectId ? true : false;
        this.amendmentDetailsRoute = isProjectContext ? RouteName.ProjectAmendmentDetails : RouteName.EngagementAmendmentDetails;
        this.misalignedAmendmentDetailsRoute = isProjectContext ? RouteName.ProjectMisalignedAmendmentDetails : RouteName.EngagementMisalignedAmendmentDetails ;

        if (!isProjectContext) {
            this.loadCRDetails(this.engagementId);
            const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
            const engagementFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.engagementId));

            observableCombineLatest(
                engagementDetails$,
                engagementFinancialDetails$,
                (
                    engagementDetails: IEngagementDetailsState,
                    engagementFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    engagementDetails,
                    engagementFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    engagementDetails,
                    engagementFinancialDetails,
                }) => {
                    if (engagementDetails.loaded) {
                        this.currency = engagementDetails.engagementDetails.currency;
                    }
                    if (engagementFinancialDetails.loaded) {
                        this.currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetails.financialDetails, FinancialType.CurrentFinancialPlan);
                        this.currentBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.CurrentFinancialPlan, engagementFinancialDetails.financialDetails);
                    }
                    if (engagementDetails.error || engagementFinancialDetails.error) {
                        this.isServerError = true;
                    }
                });
        } else {
            const projectDetails$ = this.store.select(getEntireProjectDetails(projectId));
            const projectFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(projectId));
            observableCombineLatest(
                projectDetails$,
                projectFinancialDetails$,
                (
                    projectDetails: IProjectDetailsState,
                    projectFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    projectDetails,
                    projectFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    projectDetails,
                    projectFinancialDetails,
                }) => {
                    if (projectDetails.loaded) {
                        this.currency = projectDetails.projectDetails.engagementFullDetails.currency;
                        this.loadCRDetails(this.engagementId);
                    }
                    if (projectFinancialDetails.loaded) {
                        this.currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetails.financialDetails, FinancialType.CurrentFinancialPlan);
                        this.currentBaseLinePlanDetails = this.financialService.getFinancialPlanSingleForV2(BaseLineType.CurrentFinancialPlan, projectFinancialDetails.financialDetails);
                    }
                    if (projectDetails.error || projectFinancialDetails.error) {
                        this.isServerError = true;
                    }
                });
        }
    }

    /**
     * Checks if an amendment is misaligned by checking the attributes on the object, and also checking if misalignments are enabled for the env.
     * If the feature is not enabled for the env, will return false regardless (all amendments are considered correct).
     * Otherwise will check for the misalignment attribute and if the status of it is Initiated, then will return true.
     * Returns false otherwise. 
     * @param {IChangeRequest} amendment
     * @returns {boolean} Returns boolean if the amendment is misaligned (true) or not/other (false)
     * @memberof AmendmentsComponent
     */
    public isAmendmentMisaligned(amendment: IChangeRequest): boolean {
        return this.amendmentsService.isAmendmentMisaligned(amendment);
    }

    /**
     * Checks if Amendment misalignment resolution is in progress
     * @param amendment amendment
     * @returns boolean
     */
    public isAmendmentMisalignmentResoulutionInProgress(amendment: IChangeRequest): boolean {
        return this.amendmentsService.isAmendmentMisalignmentResolutionInProgress(amendment);
    }

    // TODO Show Amendments Data
    // for mobile view
    public showAmendmentsData(): void {
        // angular.element(".dm-hidden-placeholder").show();
        // angular.element("#tab-navigation-mobile").fadeIn();
        // angular.element("#cost-consume").fadeIn();
        // angular.element("#estimate-complete").fadeIn();
        // angular.element(".dm-financial-tabpanel").fadeIn();
        // angular.element(".dm-amendments-tabpanel").fadeOut();
        // angular.element("#engagement-breakdown").fadeIn();
        // angular.element("#financial-desktop").addClass("dm-tile");
        // angular.element(".dm-finci-plan").fadeIn();
        // angular.element(".dm-financial-card").fadeIn();
        // angular.element("dm-drop-down").fadeIn();
        // this.focus("viewamendemtnsbtn", true);
    }

    /**
     * Opens the modal that directs user on how to open the CPM/PICM work book with AO in Excel.
     * @param workbookUrl
     * @param popUpTitle
     */
    public openViewWorkbookModal(workbookUrl: string): void {
        const modalRef: NgbModalRef = this.modalService.open(ViewWorkBookModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal newsnapshotModal in",
            injector: this.injector
        });
        modalRef.componentInstance.contextId = this.engagementId;

        modalRef.result.then((openWorkBook: boolean) => {
            if (openWorkBook) {
                window.open(workbookUrl, "_blank");
            }
        });
    }

    /**
     * Calls the amendment service and loads the CR details
     * @param engagementId
     * @param projectId
     * @param isProjectContext
     * @param projectFullDetails
     */
    private loadCRDetails(engagementId: string): void {
        this.isComponentLoading = true;

        // SAP is no longer accepting project ID and only will take engagment ID, no way to filter to project level
        this.changeRequestService.getAmendmentsByWbsIdV2(engagementId, AmendmentType.Contractual)
            .then((response: IChangeRequest[]) => {
                this.crDetailsList = response;
                this.isComponentLoading = false;
            }).catch((error) => {
                this.errorText = error;
                this.showLoading = false;
                this.isComponentLoading = true;
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.LoadCRDetails, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }

}
