import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";

import { SplitPlannedModalComponent } from "./split-planned-modal.component";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { RuleEngineService } from "../../../staffing-command-bar/staffing-command-bar-common/services/rule-engine.service";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";

@NgModule({
    declarations: [
        SplitPlannedModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        ProjectServiceFunctions,
        RuleEngineService,
        StaffingActionService,
    ],
    entryComponents: [
        SplitPlannedModalComponent
    ],
    exports: [SplitPlannedModalComponent]
})
export class SplitPlannedModalModule { }




