import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FcrRolesFormControlComponent } from "./fcr-roles-form-control.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ProjectService } from "../../../common/services/project.service";
import { ChangeRequestDemandService } from "../../../common/services/change-request-demand.service";

@NgModule({
    declarations: [
        FcrRolesFormControlComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    providers: [
        ChangeRequestDemandService,
        ConfigManagerService,
        SharedFunctionsService,
        ProjectService,
    ],
    entryComponents: [
        FcrRolesFormControlComponent
    ],
    exports: [
        FcrRolesFormControlComponent
    ]
})
export class FcrRolesFormControlModule { }
