import { Injectable, Inject, forwardRef } from "@angular/core";
import { FxpContext } from "@fxp/fxpservices";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { LocalStorageApplicationName, LocalStorageKey, Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";

export interface IFxpContextResponse {
    IsError: boolean;
    Result: any;
    Error: any;
}

@Injectable()
export class ContextStorageService extends DmServiceAbstract {

    /**
     * Creates an instance of FxpContextServiceHelper
     *
     * @param {FxpContext} fxpContext   
     * @memberof FxpContextServiceHelper
     */
    public constructor(
        @Inject(forwardRef(() => FxpContext)) private fxpContext: FxpContext,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ){
        super(dmLogger, Services.ContextStorageService );
    }

    /**
     * Saves the key values to fxp context pouch db
     * Key name should not contain the application name as this helper already takes care of prefixing
     * @param key 
     * @param value
     */
    public saveContent(key: LocalStorageKey, value: string): Promise<any> {
        return this.fxpContext.saveContext(key, value, LocalStorageApplicationName);
    }

    /**
     * Gets the value from fxp context pouch db
     * @param key 
     * @param value
     */
    public readContent(key: LocalStorageKey): Promise<any> {
        return this.fxpContext.readContext(key, LocalStorageApplicationName).then((data: IFxpContextResponse) => {
            if (data.IsError) {
                return Promise.reject(data.Error);
            } else {
                return Promise.resolve(data.Result);
            }
        });
    }


    /**
     * Deletes the key from fxp context pouch db
     * @param key 
     * @param value
     */
    public deleteContent(key: LocalStorageKey): Promise<any> {
        return this.fxpContext.deleteContext(key, LocalStorageApplicationName);
    }
}
