import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WbsEngagementEditModalComponent } from "./wbs-engagement-edit.component";
import { WBSService } from "../../../../../common/services/wbs.service";
import { MyPortfolioService } from "../../../../../common/services/my-portfolio.service";
import { WbsResourceRequestsModule } from "../../wbs-resource-requests/wbs-resource-requests.module";

@NgModule({
    declarations: [
        WbsEngagementEditModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        WbsResourceRequestsModule,
        NgbModule,
    ],
    providers: [
        ConfigManagerService,
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        DMNotificationService,
        EngagementDetailService,
        MyPortfolioService,
        SharedFunctionsService,
        WBSService
    ],
    entryComponents: [
        WbsEngagementEditModalComponent,
    ],
    exports: [
        WbsEngagementEditModalComponent,
    ]
})
export class WbsEngagementEditModalModule { }