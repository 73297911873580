import * as fromNpcActuals from "./npc-actuals.action";
import { ILoadableState } from "../reducers";
import { INpcProjectActuals } from "../../common/services/contracts/npc.contract";

export interface INpcActualsState extends ILoadableState {
    npcActuals: INpcProjectActuals[];
}

export const initialState: INpcActualsState = {
    npcActuals: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromNpcActuals.NpcActualsAction): { [wbsId: string]: INpcActualsState } {
    switch (action.type) {
        case fromNpcActuals.NpcActualsActionTypes
            .LOAD_NPC_ACTUALS: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromNpcActuals.NpcActualsActionTypes
            .LOAD_NPC_ACTUALS_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                npcActuals: action.npcActuals,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromNpcActuals.NpcActualsActionTypes
            .UPDATE_NPC_ACTUALS_LOCAL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                npcActuals: action.npcActuals,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromNpcActuals.NpcActualsActionTypes
            .LOAD_NPC_ACTUALS_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromNpcActuals.NpcActualsActionTypes
            .INVALIDATE_NPC_ACTUALS: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}