import { Component, Inject, Input } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AADGraphService } from "../../../common/services/aad-graphapi.service";
import { Components, AuditEventNames, SourceConstants } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IAuditResponse, IWbsAuditValues } from "../../../common/services/contracts/audit.contracts";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import moment from "moment";


@Component({
    selector: "activity-feed-item",
    templateUrl: "./activity-feed-item.html",
    styleUrls: ["./activity-feed-item.scss"]
})
export class ActivityFeedItem extends DmComponentAbstract {
    @Input() public auditFeedItem: IAuditResponse;
    @Input() public isLastActivity: boolean = false;
    public eventName: string;
    public eventDescription: string;
    public eventTime: string;
    public createdBy: string;
    public userImage: SafeResourceUrl;
    public wbsId: string;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
    ) {
        super(dmLogger, Components.KeyActionsV2);
    }

    public ngOnInit(): void {
        const engagementId = this.sharedFunctionService.getSelectedEngagementId(this.stateService);
        const projectId = this.sharedFunctionService.getSelectedProjectId(this.stateService);
        const isProjectContext = projectId ? true : false;
        this.wbsId = isProjectContext ? projectId : engagementId;

        if (this.auditFeedItem) {
            this.eventTime = moment(this.auditFeedItem.createdOn).local().format("DD-MMM-YYYY hh:mm A");
            const auditDetail: IWbsAuditValues = (this.auditFeedItem.audit as IWbsAuditValues);
            this.eventName = AuditEventNames[auditDetail.eventType];
            this.setEventDescription(auditDetail);
            this.setCreatedBy();
        }
    }

    /**
     * get created by user alias and image for the alias.
     */
    private setCreatedBy(): void {
        this.createdBy = this.auditFeedItem.createdBy;
        if (this.createdBy && this.createdBy !== "Z-ES_PMS") {
            this.aadGraphService.getResourceThumbnailPicture(this.createdBy).then((imageData) => {
                if (imageData) {
                    this.userImage = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64," + imageData);
                }
            }).catch((error) => {
                this.logError(SourceConstants.Method.SetCreatedBy, error);
                Promise.resolve();
            });
        }
    }

    /**
     * sets event description for audit
     */
    private setEventDescription(auditDetail: IWbsAuditValues): void {
        switch (auditDetail.eventType.toString()) {
            case "5":
                this.eventDescription = `Engagement status changed from ${auditDetail.previousValue.toLowerCase()} to ${auditDetail.currentValue.toLowerCase()}.`;
                break;
            case "4":
                this.eventDescription = `Engagement ${this.wbsId} has been released.`;
                break;
            case "3":
                this.eventDescription = "Additional Primary Project Manager added/removed to engagement.";
                break;
            case "6":
                this.eventDescription = `Engagement ${this.wbsId} is created.`;
                break;
            case "2":
                this.eventDescription = `Primary Project Manager changed from ${auditDetail.previousValue.toLowerCase()} to ${auditDetail.currentValue.toLowerCase()}.`;
                break;
            case "7":
                this.eventDescription = `Engagement name is changed from ${auditDetail.previousValue.toLowerCase()} to ${auditDetail.currentValue.toLowerCase()}.`;
                break;
            case "8":
                this.eventDescription = `Engagement description is changed from ${auditDetail.previousValue.toLowerCase()} to ${auditDetail.currentValue.toLowerCase()}`;
                break;
            case "10":
                this.eventDescription = `Additional Project Manager ${auditDetail.currentValue.toLowerCase()} added to engagement.`;
                break;
            case "11":
                this.eventDescription = `Additional Project Manager ${auditDetail.previousValue.toLowerCase()} removed from engagement.`;
                break;
            default:
                break;
        }
    }
}