import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { EngagementSummaryProjectV2Component } from "./engagement-summary-project-v2.component";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmUserProfileModule } from "../../../shared/user-name/user-name.module";
import { FeedbackModalService } from "../../../../components/tiles/feedback-modal/feedback-modal.service";

@NgModule({
    declarations: [
        EngagementSummaryProjectV2Component
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule,
        DmUserProfileModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        EngagementDetailService,
        SharedFunctionsService,
        FeedbackModalService
    ],
    entryComponents: [
        EngagementSummaryProjectV2Component
    ],
    exports: [
        EngagementSummaryProjectV2Component
    ]
})
export class EngagementSummaryProjectV2Module { }
