import { Injectable, Inject, forwardRef } from "@angular/core";
import { UserInfoService, FxpEventBroadCastService, ErrorSeverityLevel } from "@fxp/fxpservices";
import moment from "moment";
import { BroadcastEvent, Services, SourceConstants } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DMLoggerService } from "./dmlogger.service";
import { EngagementDetailService } from "./engagement-detail.service";
import { FinancialService } from "./financial.service";
import { IDelegationDetailsV2, IDelegationEntity } from "./contracts/delegation.v2.service.contracts";
import { IEngagement } from "./contracts/financial.service.contracts";
import { IEngagementDetails, /* IAdditionalPJM, IAdditionalPPJM, */ IProjectDetails, IEngagementListObjectV2, IProjectListObjectV2, IContractType } from "./contracts/project.service.contracts";
import { IEngagementFinancial, IProjectFinancial, IFinancialDetail, IFinancial } from "../../components/financial-mgmt/financial.model";
import { IEngagementList, IProjectList, IDelegationPortFolioDetails, IInternalEngagementCreationCode, IEngagementFinancialsList, IProjectFinancialsList, IInternalEngagementFinancialsList, IEngagementFinancialsListV2, IProjectFinancialsListV2 /* IPinnedEntities, */ } from "./contracts/portfolio.model";
import { ProjectService } from "./project.service";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { DataService } from "./data.service";
import { Store } from "@ngrx/store";
import { IState } from "../../store/reducers";
import { LoadMyPortfolioEngagements } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.action";
import { LoadUserPreference } from "../../store/userspreferences/userpreference.action";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";

@Injectable()
export class MyPortfolioService extends DmServiceAbstract {
    private currentUserBPID: number;
    private hidePinnedEntities: boolean;
    private lockedUserStatus: string;
    private typeOfContracts: IContractType[];
    private internalEngagementCreationCodes: IInternalEngagementCreationCode[];
    private prepaymentCode: string;
    private readonly DELEGATED_INWARD = "delegatedInward"; // todo change these to enum
    private readonly DELEGATED_OUTWARD = "delegatedOutward";

    public constructor(
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(Store) private store: Store<IState>,
    ) {
        super(dmLogger, Services.MyPortfolioService );
        this.currentUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        /* Hide Pinned Entities is always true for right now, 5/2019 */
        this.hidePinnedEntities = this.configurationService.isFeatureEnabled("hidePinnedEntitiesInPortfolio");
        this.lockedUserStatus = this.configurationService.getValue<string>("lockedUserStatusCode");
        this.typeOfContracts = this.configurationService.getValue<IContractType[]>("projEngContractType");
        this.prepaymentCode = this.configurationService.getValue<string>("prepaymentUserStatusCode");
        this.internalEngagementCreationCodes = this.configurationService.getValue<IInternalEngagementCreationCode[]>("internalEngagementCreationCodes");
    }

    /**
     * Refreshes My Portfolio Engagement List by dispatching an event to the store, telling it to
     * get data but NOT pull from the cache.
     * Use this function whenever you would update engagement data and it needs to be reflected back to my portfolio or the drop down list.
     * (Such as updating a name or PJM on manage wbs page.)
     */
    public refreshMyPortfolioEngagementList(): void {
        this.store.dispatch(new LoadMyPortfolioEngagements(false));
        this.store.dispatch(new LoadUserPreference());
        this.fxpBroadcastService.emit(BroadcastEvent.PortFolioRefresh, {});
    }

    /**
     * Creates the engagement list that is used as the view model for the My Portfolio page and
     * the Drop Down navigation component. This is the piece of data that is stored in the My Portfolio slice of the store.
     *
     * @param {IEngagementListObjectV2[]} engagementDetails
     * @param {IEngagementListObjectV2[]} pinnedProjectWithEngagementList
     * @returns {IEngagementList[]}
     * @memberof MyPortfolioService
     */
    public populateEngagmentViewModelForPortfolioV2(engagementDetails: IEngagementListObjectV2[], pinnedProjectWithEngagementList: IEngagementListObjectV2[], activeDelegatedByList: IDelegationDetailsV2[], activeDelegatedToList: IDelegationDetailsV2[]): IEngagementList[] {
        let engagementList: IEngagementList[] = [];
        if (engagementDetails) {
            for (const e of engagementDetails) {
                if (e.statusCode && e.statusCode === "CLSD") {
                    continue;
                }
                const engagementListItem: IEngagementList = this.convertEngagementListAPIObjectToEngagementList(e, activeDelegatedByList, activeDelegatedToList);
                if (engagementListItem) {
                    engagementList.push(engagementListItem);
                }
            }
        }
        if (pinnedProjectWithEngagementList && pinnedProjectWithEngagementList.length) {
            const pinnedWithoutDupes: IEngagementList[] = this.removeDupeEngagements(engagementList, pinnedProjectWithEngagementList, activeDelegatedByList, activeDelegatedToList);
            engagementList = engagementList.concat(pinnedWithoutDupes);
        }
        this.sharedFunctionsService.sortEngagementListByEngagementId(engagementList);
        return engagementList;
    }

    /**
     * Gets financial data for engagements and their projects from the Project Services API
     * and filters it based on assignment.
     *
     * @private
     * @returns {Promise<any>}
     * @memberof GridDataComponent
     */
    public getFinancialsDataForMyPortfolioEngagementList(engagementList: IEngagementList[]): Promise<Array<IEngagementFinancialsList | IInternalEngagementFinancialsList>> {
        const engagementFinancialList: Array<IEngagementFinancialsList | IInternalEngagementFinancialsList> = [];
        const promiseArray: Array<Promise<void>> = this.createEngagementFinancialDataPromiseArray(engagementList, engagementFinancialList);
        return Promise.all(promiseArray).then(() => {
            this.sharedFunctionsService.sortEngagementListByEngagementId(engagementFinancialList);
            return engagementFinancialList;
        }).catch((serviceError) => { // Format the error
            const correlationId: string = " Correlation ID: " + DataService.getCorrelationIdFromError(serviceError);
            if (serviceError.data) {
                serviceError = serviceError.data;
                if (serviceError.innerErrors && serviceError.innerErrors.length && serviceError.innerErrors[0].messages && serviceError.innerErrors[0].messages.length) {
                    this.logError(SourceConstants.Method.GetFinancialsDataForMyPortfolioEngagementList, serviceError, serviceError.innerErrors[0].messages[0], ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return Promise.reject(serviceError.innerErrors[0].messages[0] + correlationId);
                }
                if (serviceError.message) {
                    this.logError(SourceConstants.Method.GetFinancialsDataForMyPortfolioEngagementList, serviceError, serviceError.message, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return Promise.reject(serviceError.message + correlationId);
                }
            }
            this.logError(SourceConstants.Method.GetFinancialsDataForMyPortfolioEngagementList, serviceError, "Unable to retrieve Engagement Financial List.", ErrorSeverityLevel && ErrorSeverityLevel.High);
            return Promise.reject("Unable to retrieve Engagement Financial List." + correlationId);
        });
    }


    /**
     * Divide list of engagements that we bind to call financial API in bulk.
     * Currently, by default, we are binding 5 engagements per API. And we only need 5 engagement financial data per page.
     *
     * @private
     * @returns {Promise<void[]>}
     * @memberof GridDataComponent
     */
    public getEngagementFinancialDataV2(engagementList: IEngagementList[], engagementFinancialList: IEngagementFinancialsListV2[], maxEngagementNumPerAPI: number): Promise<void[]> {
        if (!engagementList || engagementList.length <= 0 || maxEngagementNumPerAPI < 1) {
            return;
        }

        const promiseArray: Array<Promise<void>> = [];
        const limit: number = Math.ceil(engagementList.length / maxEngagementNumPerAPI);
        for (let index = 0; index < limit; index++) {
            const adjustedEngagementList = engagementList.slice(index * maxEngagementNumPerAPI, index * maxEngagementNumPerAPI + maxEngagementNumPerAPI);
            promiseArray.push(this.getEngagementFinancialArrayV2(adjustedEngagementList, engagementFinancialList));
        }
        return Promise.all(promiseArray);
    }

    /**
     * Get financial data and build the view modal.
     *
     *
     * @private
     * @returns {Promise<void>}
     * @memberof GridDataComponent
     */
    private getEngagementFinancialArrayV2(engagementList: IEngagementList[], engagementFinancialList: IEngagementFinancialsListV2[]): Promise<void> {
        const engagementIds: IEngagement[] = engagementList.map((e) => ({ id: e.engagementId }));
        return this.projectService.getCustomerEngagementFinancialsListV2(engagementIds)
            .then((response: IEngagementFinancialsListV2[]) => {
                response.forEach((engagementFinancial: IEngagementFinancialsListV2) => {
                    const matchingEng: IEngagementList = engagementList.filter((engagement) => engagement.engagementId === engagementFinancial.id)[0];
                    engagementFinancial.isConfidential = matchingEng.isConfidential;
                    engagementFinancial.isAssigned = matchingEng.assigned;
                    engagementFinancial.isPublicSector = matchingEng.isPublicSector;
                    engagementFinancial.isUsPubSec = matchingEng.isUsPubSec;
                    engagementFinancial.isInternal = false;
                    engagementFinancial.primaryDomain = matchingEng.primaryDomain;
                    engagementFinancial.projectFinancials = this.getProjectFinancialDataV2(engagementFinancial.projectFinancials, matchingEng);
                    engagementFinancialList.push(engagementFinancial);
                });
            })
            .catch((serviceError) => { /* Format the error */
                const correlationId: string = " Correlation ID: " + DataService.getCorrelationIdFromError(serviceError);
                if (serviceError.data) {
                    serviceError = serviceError.data;
                    if (serviceError.innerErrors && serviceError.innerErrors.length && serviceError.innerErrors[0].messages && serviceError.innerErrors[0].messages.length) {
                        this.logError(SourceConstants.Method.GetEngagementFinancialArrayV2, serviceError, serviceError.innerErrors[0].messages[0], ErrorSeverityLevel && ErrorSeverityLevel.High);
                        return Promise.reject(serviceError.innerErrors[0].messages[0] + correlationId);
                    }
                    if (serviceError.message) {
                        this.logError(SourceConstants.Method.GetEngagementFinancialArrayV2, serviceError, serviceError.message, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        return Promise.reject(serviceError.message + correlationId);
                    }
                }
                const errorMessage = this.sharedFunctionsService.getErrorMessage(serviceError, "Unable to retrieve Engagement Financial List.");
                this.logError(SourceConstants.Method.GetEngagementFinancialArrayV2, serviceError, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.reject("Unable to retrieve Engagement Financial List." + correlationId);
            });
    }


    /**
     * build project financial data with given engagement list and response from financial API
     *
     *
     * @private
     * @returns {IProjectFinancialsListV2[]}
     * @memberof GridDataComponent
     */
    private getProjectFinancialDataV2(projectFinancials: IProjectFinancialsListV2[], engagement: IEngagementList): IProjectFinancialsListV2[] {
        if (projectFinancials) {
            for (const pf of projectFinancials) {
                const projectInfo = engagement.projects.filter((project) => project.projectId === pf.id)[0];
                pf.isConfidential = engagement.isConfidential;
                pf.isPinned = projectInfo.isPinned;
                pf.isAssigned = projectInfo.assigned;
                pf.isPublicSector = projectInfo.isPublicSector;
                pf.isUsPubSec = engagement.isUsPubSec;
                pf.primaryDomain = projectInfo.primaryDomain;
            }
        }
        return projectFinancials;
    }

    /**
     * Converts the API Engagement List Object to the view model contract of IEngagementList. Parses and combines
     * a couple different attributes for the view, and also adds some other attributes for easier processing.
     *
     * @private
     * @param {IEngagementListObjectV2} e
     * @returns {IEngagementList}
     * @memberof MyPortfolioService
     */
    private convertEngagementListAPIObjectToEngagementList(e: IEngagementListObjectV2, activeDelegatedByList: IDelegationDetailsV2[], activeDelegatedToList: IDelegationDetailsV2[]): IEngagementList {
        if (!e) {
            return undefined;
        }
        const type: string = this.sharedFunctionsService.isEngagementInternal(e) ? "internal" : "engagement";
        const projects: IProjectList[] = this.getProjectDetailsV2(e, null, activeDelegatedByList, activeDelegatedToList); /* We set projects up here because we want the contract func to only consume filtered projects */
        const typeOfContract: string = this.sharedFunctionsService.getContractType(projects);
        return {
            engagementId: e.id,
            engagementName: e.name,
            ebsState: (e.userStatusCode === this.lockedUserStatus) ? e.statusDescription + " - " + e.userStatusDescription : e.currentStatus,
            primaryDomain: e.primaryDomain,
            startDate: new Date(e.startDate),
            endDate: new Date(e.endDate),
            typeOfContract,
            typeColorCode: this.engagementDetailService.getTypeColorCode(this.typeOfContracts, typeOfContract),
            pPjMAlias: e.pjmAlias,
            pPjMName: e.pjmName,
            pPjmBpid: e.pjmBpid,
            projectTypeCode: this.getProjectTypeDescription(e),
            customerName: e.customerName,
            delegationAllocationType: this.getDelegationAllocationType(e),
            type,
            // assigned: this.isAssignedEngagementV2(e),
            isConfidential: e.isConfidential,
            delegationDetails: this.getDelegationDetails(e.id, activeDelegatedByList, activeDelegatedToList),
            projects,
            // hasAssociatedEngagements: e.hasAssociatedEngagements, // don't need this
            isPublicSector: e.isPublicSector,
            isUsPubSec: this.sharedFunctionsService.verifyIsUsPubSec(e.publicSectorCode),
            delegationData: this.sharedFunctionsService.setDelegationInformation(e.pjmBpid, e.delegatedPjmBpid, e.pjmName, e.delegatedPjmName)
            // isPinned: (pinnedProjectWithEngagementList && pinnedProjectWithEngagementList.filter((x) => x.engagementId === e.engagementId).length) ? true : false
        };
    }

    /**
     * Builds the IProjectList array for projects from the given engagement list object from the API.
     *
     * @private
     * @param {IEngagementListObjectV2} engagementDetails
     * @param {string} pinnedProjectId
     * @returns {IProjectList[]}
     * @memberof MyPortfolioService
     */
    private getProjectDetailsV2(engagementDetails: IEngagementListObjectV2, pinnedProjectId: string, activeDelegatedByList: IDelegationDetailsV2[], activeDelegatedToList: IDelegationDetailsV2[]): IProjectList[] {
        const myPortfolioProjectList: IProjectList[] = [];
        if (engagementDetails.projects) {
            for (const p of engagementDetails.projects) {
                if (p && !this.isProjectPrepayment(p) && p.statusCode !== "CLSD") { /* Filter out projects that are prepayment status */

                    myPortfolioProjectList.push({
                        projectName: p.name,
                        ebsState: (p.userStatusCode && p.userStatusCode === this.lockedUserStatus) ? p.statusDescription + " - " + p.userStatusDescription : p.currentStatus,
                        primaryDomain: p.projectDomain, // should this be primary domain?
                        startDate: new Date(p.startDate),
                        endDate: new Date(p.endDate),
                        typeOfContract: p.contractType,
                        engagementName: engagementDetails.name,
                        daysDiffProjectStartCurrentDate: (moment(p.startDate).diff(moment(new Date()), "days") < 0) ? (moment(new Date()).diff(moment(p.startDate), "days"))
                            : (moment(p.startDate).diff(moment(new Date()), "days")),
                        delegationAllocationType: this.getDelegationAllocationType(p),
                        projectId: p.id,
                        isAssigned: this.isAssignedProjectV2(p), // to be used for sorting in Staffing screen
                        engagementId: p.engagementId,
                        pjMAlias: p.pjmAlias,
                        pjMName: p.pjmName,
                        pjMBpid: p.pjmBpid,
                        type: "Project",
                        delegationDetails: this.getDelegationDetails(p.id, activeDelegatedByList, activeDelegatedToList),
                        customerName: engagementDetails.customerName,
                        isConfidential: engagementDetails.isConfidential,
                        typeColorCode: this.engagementDetailService.getTypeColorCode(this.typeOfContracts, p.contractType),
                        isPinned: (pinnedProjectId === p.id),
                        isPublicSector: p.isPublicSector,

                        isUsPubSec: this.sharedFunctionsService.verifyIsUsPubSec(engagementDetails.publicSectorCode),
                        isMarkedForDeletion: p.userStatusCode && p.userStatusCode.toUpperCase().includes("MDL") ? true : false,
                        delegationData: this.sharedFunctionsService.setDelegationInformation(p.pjmBpid, p.delegatedPjmBpid, p.pjmName, p.delegatedPjmName)
                        
                    });
                }
            }
        }
        return myPortfolioProjectList;
    }

    /**
     * To Check if the project is assigned or not     *
     * @private
     * @param {IProjectListObjectV2} p
     * @returns {boolean}
     * @memberof MyPortfolioService
     */
    private isAssignedProjectV2(p: IProjectListObjectV2): boolean {
        return (Number(p.pjmBpid) === this.currentUserBPID || Number(p.delegatedPjmBpid) === this.currentUserBPID);
        /// Additional pjms will be included once they api returns data
    }

    /**
     * Is the given project of a prepayment status?
     * Returns true if it is prepayment. False otherwise.
     * We use this typically to check for prepayment projects in order to filter them out of what we show to the user.
     *
     * Check prepayment by checking if the status code contains the configured prepayment type.
     *
     * @private
     * @param {IProjectListObjectV2} project
     * @returns {boolean}
     * @memberof MyPortfolioService
     */
    private isProjectPrepayment(project: IProjectListObjectV2): boolean {
        if (project && project.userStatusCode) {
            const type: string = project.userStatusCode.toLowerCase();
            if (type.indexOf(this.prepaymentCode.toLowerCase()) > -1) {
                return true;
            }
        }
        return false;
    }

    /**
     * Builds an array of promises for getting engagement financial data based on the given engagement list.
     * Array is broken into sets of 5 promises at a time.
     *
     * @private
     * @param {IEngagementList[]} list
     * @returns {Array<Promise<any>>}
     * @memberof GridDataComponent
     */
    private createEngagementFinancialDataPromiseArray(engagementList: IEngagementList[], engagementFinancialList: Array<IEngagementFinancialsList | IInternalEngagementFinancialsList>): Array<Promise<void>> {
        if (!engagementList || !engagementList.length) {
            return [];
        }

        const matchingEngagementList: IEngagementList[] = engagementList.filter((e: IEngagementList) => this.hasMatchingEngagementId(engagementFinancialList, e));
        const promiseArray: Array<Promise<void>> = matchingEngagementList.map((engagement: IEngagementList) => {
            return this.getEngagementFinancialData(engagement.engagementId, engagementList, engagementFinancialList);
        });

        return promiseArray;
    }

    /**
     * Returns if the given financial list contains the given engagement Id or not.
     * Returns false if the list DOES have a matching engagement Id. Returns true if it does not have it.
     *
     * @private
     * @param {any[]} eFinancialList
     * @param {IEngagementList} e
     * @returns {boolean}
     * @memberof GridDataComponent
     */
    private hasMatchingEngagementId(eFinancialList: Array<IEngagementFinancialsList | IInternalEngagementFinancialsList>, e: IEngagementList): boolean {
        // todo improve this function to be clearer
        if (!eFinancialList) {
            return false;
        }
        return (eFinancialList.filter((l: IEngagementFinancialsList) => l.engagementId === e.engagementId).length === 0);
    }

    /**
     * Gets the engagement financial details for the given list of engagements from the Project Services API
     *
     * @private
     * @param {IEngagementList[]} engagements
     * @returns {Promise<any>}
     * @memberof GridDataComponent
     */
    private getEngagementFinancialData(engagementId: string, engagementList: IEngagementList[], engagementFinancialList: Array<IEngagementFinancialsList | IInternalEngagementFinancialsList>): Promise<void> {
        if (!engagementId) {
            return Promise.resolve();
        }

        /* This promise modifies the engagementFinancialList reference and does not return anything.
        Errors are caught at a higher level. */
        return this.projectService.getFinancialsByEntityId(engagementId, false, false)
            .then((response: IEngagementFinancial) => {
                if (response.engagementFinancials) {
                    for (const ef of response.engagementFinancials) {
                        const financialDetails: IFinancialDetail[] = ef.financialDetails;
                        const deliveryBaseLineDetails: IFinancial = this.financialService.getFinancialForBaselineType("Delivery", financialDetails);
                        const matchingEng: IEngagementList = engagementList.filter((pf) => pf.engagementId === ef.engagementId)[0];
                        if (engagementFinancialList.filter((e) => e.engagementId === ef.engagementId).length === 0 && matchingEng) {
                            const currentBaseLineDetails = this.financialService.getFinancialForBaselineType("Current", financialDetails);

                            if (!this.sharedFunctionsService.isEngagementInternal(matchingEng)) { /* Internal vs Customer engagements have different object types, so we split them here */
                                if (deliveryBaseLineDetails) {
                                    const eacDetails = this.financialService.getFinancialForBaselineType("EAC", financialDetails);
                                    /* Type IEngagementFinancialsList*/
                                    engagementFinancialList.push({
                                        engagementName: (matchingEng ? matchingEng.engagementName : ""),
                                        currency: deliveryBaseLineDetails.currency,
                                        EACMargin: eacDetails ? eacDetails.marginInPercentage : null,
                                        PlannedMargin: currentBaseLineDetails ? currentBaseLineDetails.marginInPercentage : deliveryBaseLineDetails.marginInPercentage,
                                        EACCost: eacDetails ? eacDetails.cost : null,
                                        PlannedCost: currentBaseLineDetails ? currentBaseLineDetails.cost : deliveryBaseLineDetails.cost,
                                        EACRevenue: eacDetails ? eacDetails.revenue : null,
                                        PlannedRevenue: currentBaseLineDetails ? currentBaseLineDetails.revenue : deliveryBaseLineDetails.revenue,
                                        EACHours: eacDetails ? eacDetails.hours : null,
                                        PlannedHours: currentBaseLineDetails ? currentBaseLineDetails.hours : deliveryBaseLineDetails.hours,
                                        engagementId: ef.engagementId,
                                        isConfidential: ef.isConfidentialDeal,
                                        projects: this.getFinancialProjects(ef.projectsFinancials, ef.isConfidentialDeal, matchingEng, engagementList),
                                        primaryDomain: matchingEng ? matchingEng.primaryDomain : "",
                                        isAssigned: matchingEng ? matchingEng.assigned : false,
                                        isPublicSector: matchingEng.isPublicSector,
                                        isUsPubSec: matchingEng.isUsPubSec,
                                        isInternal: false /* Only used for splitting up based on component that is consuming this */
                                    });
                                }
                            } else {
                                const currency: string = financialDetails.filter((c) => c.financials && c.financials[0].currency)[0].financials[0].currency;
                                const actualsCurrent: IFinancial = this.financialService.getFinancialForBaselineType("ActualsCurrent", financialDetails);

                                /* Type IInternalEngagementFinancialsList */
                                engagementFinancialList.push({
                                    engagementName: matchingEng ? matchingEng.engagementName : "",
                                    engagementId: ef.engagementId,
                                    currency,
                                    plannedHours: currentBaseLineDetails ? currentBaseLineDetails.hours : undefined,
                                    actualHours: actualsCurrent ? actualsCurrent.hours : undefined,
                                    plannedCost: currentBaseLineDetails ? currentBaseLineDetails.cost : undefined,
                                    actualCost: actualsCurrent ? actualsCurrent.cost : undefined,
                                    isConfidential: ef.isConfidentialDeal,
                                    primaryDomain: matchingEng ? matchingEng.primaryDomain : "",
                                    hasAssociatedEngagements: matchingEng.hasAssociatedEngagements,
                                    isPinned: matchingEng.isPinned,
                                    isInternal: true /* Only used for splitting up based on component that is consuming this */
                                });
                            }
                        }
                    }
                }
            });

    }

    /**
     * Creates an array of Project Financial List objects based on the given list of Project Financials
     *
     * @private
     * @param {IProjectFinancial[]} projects
     * @param {boolean} isConfidential
     * @returns {IProjectFinancialsList[]}
     * @memberof GridDataComponent
     */
    private getFinancialProjects(projects: IProjectFinancial[], isConfidential: boolean, engagement: IEngagementList, engagementList: IEngagementList[]): IProjectFinancialsList[] {
        const financialProjects: IProjectFinancialsList[] = [];
        if (projects) {
            for (const pf of projects) {
                if (pf.financialDetails) {
                    const projectInfo = this.engagementDetailService.getProjectFromEngagementList(pf.projectId, engagementList);
                    if (projectInfo) { /* There have been cases where financials API returns projects that don't exist from the engagement list API */
                        const financialDetails = pf.financialDetails as IFinancialDetail[];
                        const deliveryBaseLineDetails = this.financialService.getFinancialForBaselineType("Delivery", financialDetails);
                        if (deliveryBaseLineDetails) {
                            const currentBaseLineDetails = this.financialService.getFinancialForBaselineType("Current", financialDetails);
                            const eacDetails = this.financialService.getFinancialForBaselineType("EAC", financialDetails);
                            financialProjects.push({
                                projectName: projectInfo.projectName,
                                currency: deliveryBaseLineDetails.currency,
                                EACMargin: eacDetails ? eacDetails.marginInPercentage : null,
                                PlannedMargin: currentBaseLineDetails ? currentBaseLineDetails.marginInPercentage : deliveryBaseLineDetails.marginInPercentage,
                                EACCost: eacDetails ? eacDetails.cost : null,
                                PlannedCost: currentBaseLineDetails ? currentBaseLineDetails.cost : deliveryBaseLineDetails.cost,
                                EACRevenue: eacDetails ? eacDetails.revenue : null,
                                PlannedRevenue: currentBaseLineDetails ? currentBaseLineDetails.revenue : deliveryBaseLineDetails.revenue,
                                EACHours: eacDetails ? eacDetails.hours : null,
                                PlannedHours: currentBaseLineDetails ? currentBaseLineDetails.hours : deliveryBaseLineDetails.hours,
                                projectId: pf.projectId,
                                isConfidential,
                                isPinned: projectInfo.isPinned,
                                primaryDomain: projectInfo.primaryDomain,
                                isAssigned: projectInfo.assigned,
                                isPublicSector: projectInfo.isPublicSector,
                                isUsPubSec: engagement.isUsPubSec                               
                            });
                        } else {
                            financialProjects.push({
                                projectName: projectInfo.projectName,
                                projectId: pf.projectId,
                                isConfidential,
                                isPinned: projectInfo.isPinned,
                                primaryDomain: projectInfo.primaryDomain,
                                isAssigned: projectInfo.assigned,
                                isPublicSector: projectInfo.isPublicSector,
                                isUsPubSec: engagement.isUsPubSec                                
                            });
                        }
                    }
                }
            }
        }
        return financialProjects;
    }

    /**
     * Creates a DelegationPortfolioDetails object with information from delegations assigned to the user with the matching ID
     * @param entityId
     */
    private getDelegationDetails(entityId: string, activeDelegatedByList: IDelegationDetailsV2[], activeDelegatedToList: IDelegationDetailsV2[]): IDelegationPortFolioDetails {
        const delegationDetails: IDelegationPortFolioDetails = {}; // todo change this to undefined instead of an empty object
        if (activeDelegatedByList) {
            for (const db of activeDelegatedByList) {
                if (db.delegatedEntities && db.delegatedEntities.filter((e: IDelegationEntity) => e.objectId === entityId).length) {
                    if (Number(db.delegatedBy.id) === this.currentUserBPID) {
                        delegationDetails.delegationToFrom = db.delegatedTo.alias;
                        delegationDetails.delegationStatus = db.status.name;
                        delegationDetails.delegationStartDate = new Date(db.period.startsOn.localTime);
                        delegationDetails.delegationEndDate = new Date(db.period.endsOn.localTime);
                        delegationDetails.delegationFullName = db.delegatedTo.displayName;
                    }
                }
            }
        }

        if (activeDelegatedToList) {
            for (const dt of activeDelegatedToList) {
                if (dt.delegatedEntities && dt.delegatedEntities.filter((e) => e.objectId === entityId).length) {
                    if (Number(dt.delegatedTo.id) === this.currentUserBPID) {
                        delegationDetails.delegationToFrom = dt.delegatedBy.alias;
                        delegationDetails.delegationStatus = dt.status.name;
                        delegationDetails.delegationStartDate = new Date(dt.period.startsOn.localTime);
                        delegationDetails.delegationEndDate = new Date(dt.period.endsOn.localTime);
                        delegationDetails.delegationFullName = dt.delegatedBy.displayName;
                    }
                }
            }
        }
        return delegationDetails;
    }

    /**
     * Get delegation type of an entity (delegated inward or outward)
     * todo consider using enums or a constants dict
     * @param entityId
     */
    private getDelegationAllocationType(entityDetails: IEngagementDetails | IEngagementListObjectV2 | IProjectDetails | IProjectListObjectV2): string {
        let delegatedBpid: string;
        if ((entityDetails as IEngagementListObjectV2).delegatedPjmBpid) { // New My Portfolio API object (V2)
            delegatedBpid = (entityDetails as IEngagementListObjectV2).delegatedPjmBpid;
        } else if ((entityDetails as IEngagementDetails).delegatedPPjMbpId) { // Old My Portfolio API object
            delegatedBpid = (entityDetails as IEngagementDetails).delegatedPPjMbpId;
        } else if ((entityDetails as IProjectDetails).delegatedPjMbpId) { // Project
            delegatedBpid = (entityDetails as IProjectDetails).delegatedPjMbpId;
        } else if ((entityDetails as IProjectListObjectV2).delegatedPjmBpid) { // New V2 Project API
            delegatedBpid = (entityDetails as IProjectListObjectV2).delegatedPjmBpid;
        }
        if (delegatedBpid && Number(delegatedBpid) === this.currentUserBPID) {
            return this.DELEGATED_INWARD;
        } else if (delegatedBpid) {
            return this.DELEGATED_OUTWARD;
        }
        return undefined;
    }

    /**
     *
     * Used to get the Project Type Description based on engagement Creation Code
     * @private
     * @param {IEngagementDetails} engagement
     * @returns {string}
     * @memberof DropDownComponent
     */
    private getProjectTypeDescription(engagement: IEngagementDetails | IEngagementListObjectV2): string {
        if (!engagement || !engagement.projects || engagement.projects.length <= 0 || !engagement.projects[0]) {
            return undefined;
        }
        const projectTypeDescription = this.internalEngagementCreationCodes.filter((i) => i.engagementCreationCode.toString() === engagement.projects[0].projectTypeCode);
        if (projectTypeDescription && projectTypeDescription.length > 0) {
            return projectTypeDescription[0].typeDescription;
        }
        return (engagement as IEngagementDetails).projectTypeCode || (engagement as IEngagementListObjectV2).engagementTypeCode;
    }

    /**
     * Removes any potential duplicates from the pinned engagement list so that they are not returned multiple times.
     * Used in instances of a user pinning their own Engagement or Project. Also used on first instances of page being instantiated,
     * @param engagementList
     * @param pinnedProjectWithEngagementList
     */
    private removeDupeEngagements(engagementList: IEngagementList[], pinnedProjectWithEngagementList: IEngagementListObjectV2[], activeDelegatedByList: IDelegationDetailsV2[], activeDelegatedToList: IDelegationDetailsV2[]): IEngagementList[] {
        const currEngagementIds: string[] = engagementList.map((x: IEngagementList) => x.engagementId.toUpperCase());
        const pinnedEngagementIds: string[] = pinnedProjectWithEngagementList.map((x: IEngagementListObjectV2) => x.id.toUpperCase());
        const nonDupeArray: IEngagementList[] = [];
        for (const engId of pinnedEngagementIds) {
            if (currEngagementIds.indexOf(engId) < 0) { // if the pinned engagement is not in the engagement list, then we can add it
                const match: IEngagementListObjectV2[] = pinnedProjectWithEngagementList.filter((x: IEngagementListObjectV2) => x.id === engId);
                if (match.length) {
                    const engagementListItem: IEngagementList = this.convertEngagementListAPIObjectToEngagementList(match[0], activeDelegatedByList, activeDelegatedToList);
                    if (engagementListItem) {
                        nonDupeArray.push(engagementListItem);
                    }
                }
            }
        }
        return nonDupeArray;
    }

    // /**
    //  * Is the logged in user assigned a project, either as the PJM or as an APJM
    //  * @param pjMBpid
    //  * @param additionalPJMs
    //  */
    // private isAssignedProjectV2(pjMBpid: string, additionalPJMs: IAdditionalPJM[]): boolean {
    //     return (
    //         this.sharedFunctionsService.isBPIDCurrentUser(pjMBpid) ||
    //         (additionalPJMs &&
    //             additionalPJMs.filter((a) => a.pjMbpId && Number(a.pjMbpId) === this.currentUserBPID).length > 0)
    //     );
    // }

    // /**
    //  * Is the logged in user assigned an engagement, either as the PPJM or as an APPJM
    //  * @param pPjMBpid
    //  * @param additionalPPJMs
    //  */
    // private isAssignedEngagementV2(pPjMBpid: string, additionalPPJMs: IAdditionalPPJM[]): boolean {
    //     return (
    //         this.sharedFunctionsService.isBPIDCurrentUser(pPjMBpid) ||
    //         (
    //             additionalPPJMs &&
    //             additionalPPJMs.filter((a) => a.pPjMBpId && Number(a.pPjMBpId) === this.currentUserBPID).length > 0
    //         )
    //     );
    // }

    // /**
    //  * Is the engagement or any of its projects assigned or delegated to the current user
    //  * @param engagementDetails
    //  */
    // private isEntityAssignedOrDelegated(engagementDetails: IEngagementDetails): boolean {
    //     if (this.isAssignedEngagement(engagementDetails.pPjMbpId, engagementDetails.additionalPPJMs) ||
    //            this.sharedFunctionsService.isBPIDCurrentUser(engagementDetails.delegatedPPjMbpId) ){
    //         return true;
    //     }
    //     for (const project of engagementDetails.projects) {
    //         if (this.isAssignedProject(project.pjMbpId, project.additionalPJMs) ||
    //             this.isEntityDelegatedToCurrUser(project.delegatedPjMbpId)) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    // /**
    //  * Is the logged in user assigned a project, either as the PJM or as an APJM
    //  * @param pjMBpid
    //  * @param additionalPJMs
    //  */
    // private isAssignedProject(pjMBpid: string, additionalPJMs: IAdditionalPJM[]): boolean {
    //     return (this.sharedFunctionsService.isBPIDCurrentUser(pjMBpid) ||
    //         (additionalPJMs &&
    //             additionalPJMs.filter((a) => a.pjMbpId && Number(a.pjMbpId) === this.currentUserBPID).length > 0));
    // }

    // /**
    //  * Is the logged in user assigned an engagement, either as the PPJM or as an APPJM
    //  * @param pPjMBpid
    //  * @param additionalPPJMs
    //  */
    // private isAssignedEngagement(pPjMBpid: string, additionalPPJMs: IAdditionalPPJM[]): boolean {
    //     return (
    //         this.sharedFunctionsService.isBPIDCurrentUser(pPjMBpid) ||
    //         (
    //             additionalPPJMs &&
    //             additionalPPJMs.filter((a) => a.pPjMBpId && Number(a.pPjMBpId) === this.currentUserBPID).length > 0
    //         )
    //     );
    // }

    // public getMyComboPromise(loadFromCache): Promise<{ engagementDetails: IEngagementDetails[], pinnedEntities: IEngagementList[] }> {
    //     let engagementDetails: IEngagementDetails[];
    //     let pinnedEntities: IPinnedEntities;

    //     /* Grab all engagements assigned to the logged in user. */
    //     const engagementPromise: Promise<void> = this.projectService.getEngagementListForLoggedInUserWithCache(loadFromCache)
    //         .then((response) => {
    //             engagementDetails = response.data;
    //             let isResponseRecievedFromCache: boolean = false;
    //             if (response.headers && response.headers(["fromcache"])) {
    //                 isResponseRecievedFromCache = (response.headers(["fromcache"]).toLowerCase() === "true") ? true : false;
    //             }
    //                 {
    //                     userAlias: this.fxpUserInfoService.getCurrentUser(),
    //                     isTriggeredFromRefresh: !loadFromCache,
    //                     isResponseRecievedFromCache
    //                 });
    //         }).catch((error) => { /* Only catch 404 errors, since they indicate the user has no engagements, which is a valid scenario. */
    //             if (error.errorcode === 404) {
    //                 engagementDetails = [];
    //                 Promise.resolve();
    //             }
    //         });

    //     /* Grab all active delegations for the user. */
    //     const activeDelegationPromise = this.sharedFunctionsService.getAllActiveDelegationList().then((response: IDelegationDetails[]) => {
    //         this.activeDelegations = response;
    //         return;
    //     }).catch(() => {
    //         /* In case of failed API call, set delegations as undefined instead of blocking the entire promise chain */
    //         // todo, create a message here to indicate that while there was a failure in loading engagements, the failure was due to delegations API
    //         this.activeDelegations = undefined;
    //         return Promise.resolve();
    //     });

    //     /* Grab all pinned engagements for the user (will only be called if we are not hiding pinned entities.) */
    //     const pinnedEntitiesPromise: Promise<void> = this.sharedFunctionsService.getPinnedEntitiesFromUserPreferences()
    //         .then((response: IPinnedEntities) => {
    //             pinnedEntities = response;
    //         }).catch(() => {
    //             pinnedEntities = undefined;
    //             return Promise.resolve();
    //         });

    //     const promiseArray = this.hidePinnedEntities ? [engagementPromise, activeDelegationPromise] : [activeDelegationPromise, engagementPromise, pinnedEntitiesPromise];
    //     return Promise.all(promiseArray).then(() => {
    //         /* If we are not hiding pinned entities, then we need to get the engagement details for any pinned
    //         projects since you can pin both engagements and projects separately, but pinning one will give you access the whole entity.*/
    //         if (!this.hidePinnedEntities && pinnedEntities) {
    //             return this.getPinnedProjects(pinnedEntities).then((response: IEngagementList[]) => {
    //                 return response;
    //             }).catch(() => {

    //                 /* In case of failed API call, set pinned engagements as undefined instead of blocking the entire promise chain */
    //                 // todo, create a message here to indicate that while there was a failure in loading engagements, the failure was due to pinned API
    //                 return Promise.resolve(undefined);
    //             });
    //         }

    //         /* If there are no pinned entities or we are hiding pinned entities, then just resolve with an empty array. */
    //         return Promise.resolve([]);
    //     }).then((pinnedProjectsWithEngagementList: IEngagementList[]) => {
    //         return {
    //             engagementDetails,
    //             pinnedEntities: pinnedProjectsWithEngagementList
    //         };
    //     });
    // }

    // /**
    //  * Gets a list of project list items to be passed to the My Portfolio grid.
    //  * @param engagementDetails
    //  * @param pinnedProjectId
    //  */
    // private getProjectDetails(engagementDetails: IEngagementDetails, pinnedProjectId: string): IProjectList[] {
    //     const myPortfolioProjectList: IProjectList[] = [];
    //     if (engagementDetails.projects) {
    //         for (const p of engagementDetails.projects) {
    //             myPortfolioProjectList.push({
    //                 projectName: p.projectName,
    //                 ebsState: (p.userStatusCode && p.userStatusCode === this.lockedUserStatus) ? p.statusDescription + " - " + p.userStatusDescription : p.statusDescription,
    //                 primaryDomain: p.domain,
    //                 startDate: p.startDate,
    //                 endDate: p.endDate,
    //                 typeOfContract: p.typeOfContract,
    //                 pPjMAlias: p.pPjMAlias,
    //                 engagementName: engagementDetails.name,
    //                 delegationAllocationType: this.getDelegationAllocationType(p),
    //                 projectId: p.projectId,
    //                 assigned: this.isAssignedProject(p.pjMbpId, p.additionalPJMs),
    //                 engagementId: engagementDetails.engagementId,
    //                 pjMAlias: engagementDetails.pPjMAlias,
    //                 pjMName: p.pjMName,
    //                 type: "Project",
    //                 delegationDetails: this.getDelegationDetails(p.projectId, this.activeDelegations),
    //                 customerName: (engagementDetails.customerDetail) ? engagementDetails.customerDetail.customerName : undefined,
    //                 isConfidential: engagementDetails.isConfidentialDeal,
    //                 typeColorCode: this.engagementDetailService.getTypeColorCode(this.typeOfContracts, p.contractType),
    //                 isPinned: (pinnedProjectId === p.projectId),
    //                 isPublicSector: p.isPublicSector,
    //                 isUsPubSec: this.sharedFunctionsService.verifyIsUsPubSec(engagementDetails.pubSecCode),
    //                 delegationData: this.sharedFunctionsService.setDelegationInformation(p.pjMbpId, p.delegatedPjMbpId, p.pjMName, p.delegatedPjMName)
    //             });
    //         }
    //     }
    //     return myPortfolioProjectList;
    // }

    // /**
    //  * Builds the view model for myportfolio based on the engagementdetails and pinnedlist pulled out to a seperate method so this can be called earlier
    //  * @param engagementDetails
    //  * @param pinnedProjectWithEngagementList
    //  */
    // public populateEngagmentViewModelForPortfolio(engagementDetails: IEngagementDetails[], pinnedProjectWithEngagementList: IEngagementList[]): IEngagementList[] {
    //     let engagementList: IEngagementList[] = [];
    //     if (engagementDetails) {
    //         for (const e of engagementDetails) {
    //             if (this.isEntityAssignedOrDelegated(e)) {
    //                 const type: string = this.sharedFunctionsService.isEngagementInternal(e) ? "internal" : "engagement";
    //                 const typeOfContract: string = this.sharedFunctionsService.getContractType(e.projects);
    //                 engagementList.push({
    //                     engagementId: e.engagementId,
    //                     engagementName: e.name,
    //                     ebsState: (e.userStatusCode && e.userStatusCode === this.lockedUserStatus) ? e.stateDescription + " - " + e.userStatusDescription : e.stateDescription,
    //                     primaryDomain: e.primaryDomain,
    //                     startDate: e.startDate,
    //                     endDate: e.endDate,
    //                     typeOfContract,
    //                     typeColorCode: this.engagementDetailService.getTypeColorCode(this.typeOfContracts, typeOfContract),
    //                     pPjMAlias: e.pPjMAlias,
    //                     pPjMName: e.pPjMName,
    //                     projectTypeCode: this.getProjectTypeDescription(e),
    //                     customerName: (e.customerDetail) ? e.customerDetail.customerName : undefined,
    //                     delegationAllocationType: this.getDelegationAllocationType(e),
    //                     assigned: this.isAssignedEngagement(e.pPjMbpId, e.additionalPPJMs),
    //                     type,
    //                     isConfidential: e.isConfidentialDeal,
    //                     delegationDetails: this.getDelegationDetails(e.engagementId, this.activeDelegations),
    //                     projects: this.getProjectDetails(e, null),
    //                     hasAssociatedEngagements: e.hasAssociatedEngagements,
    //                     isPublicSector: e.isPublicSector,
    //                     isUsPubSec: this.sharedFunctionsService.verifyIsUsPubSec(e.pubSecCode),
    //                     delegationData: this.sharedFunctionsService.setDelegationInformation(e.pPjMbpId, e.delegatedPPjMbpId, e.pPjMName, e.delegatedPPjMName)
    //                     // isPinned: (pinnedProjectWithEngagementList && pinnedProjectWithEngagementList.filter((x) => x.engagementId === e.engagementId).length) ? true : false
    //                 });
    //             }
    //         }
    //     }
    //     if (pinnedProjectWithEngagementList && pinnedProjectWithEngagementList.length) {
    //         const pinnedWithoutDupes: IEngagementList[] = this.removeDupeEngagements(engagementList, pinnedProjectWithEngagementList);
    //         engagementList = engagementList.concat(pinnedWithoutDupes);
    //     }
    //     this.sharedFunctionsService.sortEngagementListByEngagementId(engagementList);
    //     return engagementList;
    // }

    // /**
    //  * Get any pinned projects from the user's preferences
    //  */
    // private getPinnedProjects(pinnedEntities: IPinnedEntities): Promise<IEngagementList[]> {
    //     const promiseArray = [];
    //     const engagementDetailsForPinnedProject: IEngagementList[] = [];
    //     if (pinnedEntities && pinnedEntities.projectId) {
    //         for (const p of pinnedEntities.projectId) {
    //             promiseArray.push(
    //                 this.getEngagementDetailsForPinnedProject(p).then((response: IEngagementList) => {
    //                     if (response) {
    //                         engagementDetailsForPinnedProject.push(response);
    //                     }
    //                 })
    //             );
    //         }
    //     }
    //     return Promise.all(promiseArray).then(() => {
    //         return engagementDetailsForPinnedProject;
    //     });
    // }


    // /* This function has not been updated to accomodate the NGRX changes, since pinning functionality is currently turned off.
    // If you are turning pin functionality back on, this function must be upgraded. */
    // /**
    //  * Gets engagement details for a pinned project
    //  * @param projectId
    //  */
    // private getEngagementDetailsForPinnedProject(projectId: string): Promise<IEngagementList> { // todo this func is still WIP
    //     const engagementId = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
    //     /* If the engagement isn't in the cached list yet, or if the cache list doesn't exist yet, grab it from the PMS API */
    //     if (true // todo this needs to be checking the existing store?
    //         /*(!this.cachedEngagementList) ||
    //         (this.cachedEngagementList && this.cachedEngagementList.filter((item) => item.engagementId === engagementId).length === 0)*/
    //     ) {
    //         return this.projectService.getEngagementDetailsByEngagementId(engagementId, true, false, false, false).then((engagementDetail) => {
    //             const typeOfContract = this.sharedFunctionsService.getContractType(engagementDetail.projects);
    //             return {
    //                 assigned: this.isAssignedEngagement(engagementDetail.pPjMbpId, engagementDetail.additionalPPJMs),
    //                 customerName: engagementDetail.customerDetail.customerName,
    //                 ebsState: (engagementDetail.userStatusCode && engagementDetail.userStatusCode === this.lockedUserStatus) ? engagementDetail.stateDescription + " - " + engagementDetail.userStatusDescription : engagementDetail.stateDescription,
    //                 endDate: engagementDetail.endDate,
    //                 engagementId: engagementDetail.engagementId,
    //                 engagementName: engagementDetail.name,
    //                 isConfidential: engagementDetail.isConfidentialDeal,
    //                 isPublicSector: engagementDetail.isPublicSector,
    //                 pPjMAlias: engagementDetail.pPjMAlias,
    //                 pPjMName: engagementDetail.pPjMName,
    //                 primaryDomain: engagementDetail.primaryDomain,
    //                 projects: this.getProjectDetailsV2(engagementDetail, projectId),
    //                 projectTypeCode: engagementDetail.projectTypeCode,
    //                 startDate: engagementDetail.startDate,
    //                 type: "Engagement",
    //                 typeColorCode: this.engagementDetailService.getTypeColorCode(this.typeOfContract, typeOfContract),
    //                 typeOfContract,
    //                 isUsPubSec: this.sharedFunctionsService.verifyIsUsPubSec(engagementDetail.pubSecCode)
    //             };
    //         }).catch(() => {
    //             /* Failure getting the Engagement for the pinned entity from the PMS API */
    //             return Promise.resolve(undefined);
    //         });
    //     }
    //     /* Engagement already existed in the list. */
    //     // return Promise.resolve(undefined);
    // }
}
