import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IDomainDataResult } from "./contracts/dmgrm.contracts";
import { IEngagementDetails } from "./contracts/project.service.contracts";
import { IGRMRoleCreationObject, IGRMAddRoleObject } from "../../components/new-internal-engagement/internal-engagement-grm.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";
import { IAddResourceToGRMProject, IEditDraftResourceRequests, IRecconcileAPIResponse } from "./contracts/staffing.service.contract";
import { IBulkMaintenanceStatusResponse, IResourceRequestProjectIdSearchOutput, IResourceRequestSearchInput, IResourceRequestSearchOutput } from "./contracts/bulk-maintenance.service.contract";
import { IAutoBulkMaintenanceStatusRequest, IAutoBulkMaintenanceStatusResponse } from "./contracts/auto-bulk-maintenance.service.contract";

@Injectable()
export class DmGrmService extends DmServiceAbstract {
    private grmBaseUri: string;
    private grmSubscriptionKey: string;
    private grmV2BaseUrl: string;
    private grmV3BaseUrl: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.GRMService );
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Gets the skills Grace Period from GRM API, shows how many days depricating skills are going to be available before they are removed
     */
    public getSkillsGracePeriod(): Promise<IDomainDataResult> {
        const inputParameter = {
            DomainDataLists: ["GlobalParameters"]
        };
        return this.dataService.postData(`${this.grmBaseUri}/v1/Domain/GetDomainData`, this.grmSubscriptionKey, APIConstants.GetSkillGracePeriodAPI, inputParameter);
    }

    /**
     * Creates an internal engagement with the given GRM input
     * @param inputData
     */
    public createInternalEngagement(inputData: IGRMRoleCreationObject): Promise<any> {
        return this.dataService.postData(`${this.grmBaseUri}/v1/Request/Project`, this.grmSubscriptionKey, APIConstants.GetProjectAssignmentsForUserAPI, inputData);
    }

    /**
     * Adds a role to an internal engagement
     * @param inputData
     * @param grmProjectId
     */
    public addRoleToInternalEngagement(inputData: IGRMAddRoleObject, grmProjectId: string): Promise<any> {
        return this.dataService.postData(`${this.grmBaseUri}/v1/Request/Project/${grmProjectId}/Resources?isDraft=false`, this.grmSubscriptionKey, APIConstants.AddResourceToGRMProjectAPI, inputData);
    }

    /**
     * Adds a resources to grm project
     * @param inputData
     * @param grmProjectId
     */
    public addResourcesToGRMProject(inputData: IAddResourceToGRMProject, grmProjectId: string): Promise<any> {
        return this.dataService.postData(`${this.grmBaseUri}/v1/Request/Project/${grmProjectId}/Resources?isDraft=false`, this.grmSubscriptionKey, APIConstants.AddResourceToGRMProjectAPI, inputData);
    }

    /**
     * Edits draft Resource request
     * @param inputData
     */
    public editDraftResourceRequests(inputData: IEditDraftResourceRequests): Promise<any> {
        return this.dataService.patchData(`${this.grmBaseUri}/v1/Request/Resources/Draft`, this.grmSubscriptionKey, APIConstants.AddResourceToGRMProjectAPI, inputData);
    }


    /**
     * Reconciles the customer delivery project via GRM API with the WBS ID and Package ID
     * @param wbsL1Id
     * @param packageId
     */
    public reconcileCustomerDeliverProject(wbsL1Id: string, packageId: string, pmsApiResponse: IEngagementDetails): Promise<IRecconcileAPIResponse> {
        return this.dataService.postData(`${this.grmBaseUri}/v2/Reconcile/Delivery/${wbsL1Id}/Deal/${packageId}`, this.grmSubscriptionKey, APIConstants.ReconcileResourceRequests, pmsApiResponse);
    }

    /**
     * Gets the Status of Bulk Maintance Process
     * @param referenceId
     */
    public getBulkMaintenanceStatus(referenceId: string): Promise<IBulkMaintenanceStatusResponse> {
        return this.dataService.getData(`${this.grmV2BaseUrl}integrations/api/Async/getBatchStatusById?batchId=${referenceId}`, this.grmSubscriptionKey, APIConstants.GetBulkMaintenanceStatus);
    }

    /**
     * Gets the Status of Bulk Maintance Process
     * @param referenceId
     */
    public getAutoBulkMaintenanceStatus(input: IAutoBulkMaintenanceStatusRequest): Promise<IAutoBulkMaintenanceStatusResponse> {
        const additionalOptions = {
            "x-sr-tenant": "Delivery"
        };
        return this.dataService.postData(`${this.grmV3BaseUrl}api/AsyncProcessor/status`, this.grmSubscriptionKey, APIConstants.GetBulkMaintenanceStatus, input, false, additionalOptions);
    }

    /**
     * Search Resource Requests
     * @param searchInput
     */
    public searchResourceRequests(searchInput: IResourceRequestSearchInput): Promise<IResourceRequestSearchOutput | IResourceRequestProjectIdSearchOutput> {
        return this.dataService.postData(`${this.grmV2BaseUrl}read/api/Search`, this.grmSubscriptionKey,  APIConstants.SearchResourceRequests, searchInput);
    }

    /**
     * Initialize the project service with various URIs and Subscription Keys
     */
    public initializeConfig(): void {
        this.grmBaseUri = this.configManagerService.getValue<string>("grmbaseURL");
        this.grmV2BaseUrl = this.configManagerService.getValue<string>("grmV2baseURL");
        this.grmV3BaseUrl = this.configManagerService.getValue<string>("grmV3baseURL");
        this.grmSubscriptionKey = this.configManagerService.getValue<string>("grmSubscriptionKey");
    }
}
