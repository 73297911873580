import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CceacModule } from "../../../../financial-mgmt/tiles/cost-consumed/cceac/cceac.module";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { LaborConsumptionComponent } from "./labor-consumption.component";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { ProjectService } from "../../../../../common/services/project.service";

@NgModule({
    declarations: [
        LaborConsumptionComponent,
    ],
    imports: [
        CceacModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
        ProjectService
    ],
    entryComponents: [
        LaborConsumptionComponent,
    ],
    exports: [
        LaborConsumptionComponent,
    ]
})
export class LaborConsumptionModule { }
