
import { switchMap, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Effect, Actions } from "@ngrx/effects";
import { of, from as fromPromise } from "rxjs";

import * as WbsStructuresActions from "./wbs-structures.action";
import { IWbsStructure, IProject } from "./../../common/services/contracts/wbsStructures.contracts";
import { ProjectServiceV2 } from "../../common/services/project.v2.service";

@Injectable()
export class WbsStructuresEffects {

    @Effect()
    public loadWbsDemands$ = this.actions$
        .ofType(WbsStructuresActions.WbsStructuresActionTypes.LOAD_WBS_STRUCTURES).pipe(
            switchMap((action: WbsStructuresActions.LoadWbsStructures) =>
                fromPromise(this.projectServiceV2.getWbsStructures(action.wbsId, true, true))
                    .pipe(
                        map((response: IWbsStructure | IProject) => new WbsStructuresActions.LoadWbsStructuresSuccess(action.wbsId, response)),
                        catchError((error) => of(new WbsStructuresActions.LoadWbsStructuresFail(action.wbsId, error)))
                    )
            ));

    public constructor(
        private actions$: Actions,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
    ) { }
}
