import { Component, Input, Inject } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SourceConstants, LogEventConstants } from "../../../common/application.constants";

@Component({
    selector: "dm-tooltip",
    templateUrl: "./dm-tooltip.html",
    styleUrls: ["./dm-tooltip.scss"]
})
export class DmTooltipComponent {
    @Input() public dmPlacement: any;
    @Input() public ngbTooltipContent: any;
    @Input() public dmIcon: any;
    @Input() public ariaLabelToolTip: string;
    @Input() public dmTriggers: string = "manual";

    public constructor(
        @Inject(DMLoggerService) private dmLogger: DMLoggerService
    ) { }

    /**
     * Logs an event when user clicks on a tool tip
     */
    public logClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ToolTipComponent, SourceConstants.Method.LogClick, LogEventConstants.ToolTipClick);
    }
}
