import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "dm-slider",
    templateUrl: "./dm-slider.html",
    styleUrls: ["./dm-slider.scss"]
})
export class DmSliderComponent {
   
    @Input() public checkedLabel: string;
    @Input() public unCheckedLabel: string;
    @Input() public isToggleChecked: boolean;
    @Input() public popoverText: string;
    @Input() public isLabelClickable: boolean;
    @Input() public isDisabled: boolean;
    @Input() public showLabel: boolean = true;
    @Input() public sliderId: string = "";
    @Input() public labelInformation: string = "";

    @Output() public onToggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public toggleChange(): void {
        this.isToggleChecked = !this.isToggleChecked;
        this.onToggleChange.emit(this.isToggleChecked);
    }
}
