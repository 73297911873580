import { ILoadableState } from "../reducers";
import { UnitRolesActionTypes, UnitRolesActions } from "./unit-roles.action";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";

// slice of unitRoles state
export interface IUnitRolesState extends ILoadableState {
    unitRoles: IFinancialRoles[];
}

export const initialState: IUnitRolesState = {
    unitRoles: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = initialState, action: UnitRolesActions): IUnitRolesState {
    switch (action.type) {
        case UnitRolesActionTypes.LOAD_UNIT_ROLES: {
            return {
                ...initialState,
                loading: true
            };
        }

        case UnitRolesActionTypes.LOAD_UNIT_ROLES_SUCCESS: {
            return {
                ...initialState,
                unitRoles: action.unitRoles,
                loaded: true,
                loading: false,
            };
        }

        case UnitRolesActionTypes.LOAD_UNIT_ROLES_FAIL: {
            return {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
        }

        case UnitRolesActionTypes.INVALIDATE_UNIT_ROLES: {
            return initialState;
        }
    }

    return state;
}