<section class="int-eng-bulk-upload-modal" (keydown.Escape)="focus('bulkUploadDropdown')">
  <label class="sr-only">Upload File</label>
  <section class="modal-header">
    <button type="button" class="close" (click)="closeModal(); focus('bulkUploadDropdown')" id="accessibilityConstants.ClosePopUp"
      (keydown)="moveFocusPrev($event, 'accessibilityConstants.Submit')" aria-label="Bulk Upload File Dialog Close">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
    <h4 class="modal-title font-subtitle pull-left" aria-hidden="true">
      Upload File
    </h4>
  </section>
  <dm-loading [loadingText]="'Loading'" [isCustomText]="true" [errorText]="'Uploading Bulk internal engagement file'"
    [showLoading]="showLoading" *ngIf="isComponentLoading"></dm-loading>
  <section class="modal-body" *ngIf="!isComponentLoading">
    <form #bulkIntEngUpload>
      <section class="section__wrapper display-grid">
        <p class="font-caption-alt int-eng-bulk-upload-modal__header-text">Please select your template. Be sure you are
          using template <strong>{{uploadTemplateVersion}}.
          </strong></p>
        <section class="dm-p-x-imp-0 dm-p-t-20">
          <input class="font-caption-alt input__text" tabindex="-1" [disabled]="uploadedFileObj" type="text" readonly
            id="bulkUploadIntEngagementInput" name="bulkUploadIntEngagementInput" [(ngModel)]="uploadedFileName"
            placeholder="Upload file">
          <button type="button" (click)="uploadFileBtnClick()" class="fxp-btn fxp-btn--primary" id="bulkUploadIntEngagementBrowse">
            Browse
          </button>
          <input class="fxp-btn fxp-btn--primary hide-element" #bulkInternalEngagementFileUpload
            id="bulkUploadFileBtn" name="uploadFileBtn" (change)="onBulkUploadFileChange($event)" type="file"
            accept=".xlsx" />
          <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
            *ngIf="showFileUploadValidationMessage">
            Only Excel files can be uploaded.
          </p>
          <p>
            <span role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true" aria-live="polite"
              *ngIf="showFileUploadApiValidationMessage">
              {{warningMessage}}
            </span>
            <span class="icon icon-exclamation icon-rounded dm-error max-width: 50%"
              *ngIf="showFileUploadApiValidationMessage">
            </span>
          </p>
        </section>
      </section>
      <section class="modal-footer footer-button">
        <button type="button" class="fxp-btn fxp-btn--secondary" id="accessibilityConstants.Cancel"
          (click)="closeModal(); focus('bulkUploadDropdown')">Cancel</button>
        <button type="button" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.Submit" [disabled]="!uploadedFileName?.length" (click)="uploadBulkIntEngExcel()"
          (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseBulkUploadIntEngagementModal')">
          Upload
        </button>
      </section>
    </form>
  </section>
</section>