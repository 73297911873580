import { Injectable, Inject } from "@angular/core";
import { DataService } from "./data.service";
import { ConfigManagerService } from "./configmanager.service";
import { APIConstants, Services } from "../application.constants";
import { IBulkUploadTemplateUrlLinksResponse, IBulkUploadIntEngUploadResponse, IBulkIntEngDetailsResponse, IBulkUploadIntEngCreationObject, IBulkUploadSummaryStatusResponse } from "./contracts/bulk-upload-int-engagement.service.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class BulkUploadInternalEngagementService extends DmServiceAbstract{
    private projectServiceBaseUriv1: string;
    private projectServiceBaseUriv2: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.BulkUploadInternalEngagementService);
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Gets bulk upload excel template links
     *
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public getBulkUploadExcelTemplateLinks(): Promise<IBulkUploadTemplateUrlLinksResponse[]> {
        const url = `${this.projectServiceBaseUriv1}/internal/bulk`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.BulkUploadInternalEngagementTemplateLinks);
    }

    /**
     * Uploads the bulk upload excel file to blob
     *
     * @param {FormData} input
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public uploadBulkUploadIntEngExcel(input: FormData): Promise<IBulkUploadIntEngUploadResponse> {
        const url = `${this.projectServiceBaseUriv2}/internal/bulk/create/engagement/`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.UploadFiletoBlob, input, true, undefined, [400]);
    }

    /**
     * Gets the internal engagement response from the excel user has uploaded by reference id and engagement id.
     *
     * @param {string} referenceId
     * @param {string} engagementId
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public getUploadedInternalEngagement(referenceId: string, engagementId: string): Promise<IBulkIntEngDetailsResponse> {
        const url = `${this.projectServiceBaseUriv2}/internal/bulk/create/${referenceId}/engagement/${engagementId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetUploadedInternalEngagement);
    }

    /**
     * update edited internal engagement response back to server on bulk upload screen.
     *
     * @param {string} referenceId
     * @param {string} engagementId
     * @param {*} payload
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public updateUploadedInternalEngagement(referenceId: string, engagementId: string, payload: IBulkIntEngDetailsResponse): Promise<any> {
        const url = `${this.projectServiceBaseUriv2}/internal/bulk/create/${referenceId}/engagement/${engagementId}`;
        return this.dataService.patchData(url, this.subscriptionKey, APIConstants.UploadFiletoBlob, payload);
    }

    /**
     * Gets all the bulk upload engagements initiated by user.
     *
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public getAllBulkIntEngUploads(date?: string): Promise<any> {
        date = date ? date : "";
        const url = `${this.projectServiceBaseUriv2}/internal/bulk?fromDate=${date}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetAllBulkIntEngUploads);
    }

    /**
     * Gets the status of internal engagements created through bulk upload process by reference id
     *
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public getAllBulkIntEngUploadStatus(referenceId: string): Promise<IBulkUploadSummaryStatusResponse> {
        const url = `${this.projectServiceBaseUriv2}/internal/bulk/create/${referenceId}?shouldIncludeDiscarded=true`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetAllBulkIntEngUploadStatus);
    }

    /**
     * Update engagement status
     *
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public updateEngagementStatus(referenceId: string, payload: IBulkUploadIntEngCreationObject): Promise<any> {
        const url = `${this.projectServiceBaseUriv2}/internal/bulk/create/${referenceId}/engagement`;
        return this.dataService.patchData(url, this.subscriptionKey, APIConstants.BulkCreateStatusChange, payload);
    }

    /**
     * Initialize the service with URIs and subscription keys.
     */
    private initializeConfig(): void {
        this.projectServiceBaseUriv2 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.projectServiceBaseUriv1 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v1.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }
}
