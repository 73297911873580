import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FilterModalModule } from "./project-filter-modal/project-filter-modal.module";
import { ProjectFilterComponent } from "./project-filter.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { CommonComponentsModule } from "../../entry/common-components.module";

@NgModule({
    declarations: [
        ProjectFilterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        FilterModalModule,
        CommonComponentsModule
    ],
    providers: [
        SharedFunctionsService
    ],
    entryComponents: [
        ProjectFilterComponent
    ],
    exports: [
        ProjectFilterComponent
    ]
})
export class ProjectFilterModule {
}