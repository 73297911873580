import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { UnapprovedLaborComponent } from "./unapproved-labor-modal.component";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        UnapprovedLaborComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        NgbModule,
        FormsModule
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        UnapprovedLaborComponent
    ],
    exports: [
        UnapprovedLaborComponent
    ]
})
export class UnapprovedLaborModalModule { }
