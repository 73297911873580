import * as fromChangeRequest from "./engagement-change-requests-v2.action";
import { IChangeRequestsState } from "../change-requests/change-requests.reducer";

export const initialState: IChangeRequestsState = {
    changeRequests: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromChangeRequest.ChangeRequestActions): { [engagementId: string]: IChangeRequestsState } {
    switch (action.type) {
        case fromChangeRequest.ChangeRequestV2ActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTSV2: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }

        case fromChangeRequest.ChangeRequestV2ActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTSV2_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                changeRequests: action.changeRequests,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromChangeRequest.ChangeRequestV2ActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTSV2_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromChangeRequest.ChangeRequestV2ActionTypes.INVALIDATE_ENGAGEMENT_CHANGE_REQUESTSV2: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}