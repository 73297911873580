<div class="risk-reserve-modal-content">
    <label class="sr-only">Risk Reserve Amounts</label>
    <div class="modal-header modal-noborder">
        <button type="button" class="close set-focus" aria-label="Risk Reserve Dialog Close" (click)="closeModal()"
            id="accessibilityConstants.ClosePopUp"
            (keydown)="moveFocusPrev($event, 'accessibilityConstants.CloseButton')">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle">Risk Reserve Amounts</h4>
        <a href="https://aka.ms/pjm-job-aid/risk-reserve" target="_blank" role="link" class="dm-link"
            title="Learn more about Risk Reserve" style="font-size: smaller;" aria-label="open link in new tab">
            <span aria-hidden="true" class="icon icon-education"></span>
            Learn more
        </a>
    </div>
    <dm-loading [loadingText]="'Approved Amount'" [errorText]="errorText" [showLoading]="showLoading"
        *ngIf="isComponentLoading"></dm-loading>
    <div class="modal-body font-caption-alt" *ngIf="riskReserveDetails && !isComponentLoading">
        <!--Sanity check for risk reserve details. This should never be undefined.-->
        <table width="100%" role="presentation">
            <thead>
                <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Amount ({{currency}})</th>
                </tr>
            </thead>
        </table>
        <div id="approvedRRCaption" class="m-t-12 risk-reserve-caption">
            Approved Risk Reserve
        </div>
        <table width="100%" aria-labelledby="approvedRRCaption">
            <tbody>
                <tr>
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Cost Amount (RRCA)
                    </th>
                    <th [textContent]="riskReserveDetails[0].bidCostAmount | number : '1.2-2'">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                    </th>
                </tr>
                <tr class="border-duoble">
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Approved Drawdown
                    </th>
                    <th [textContent]="riskReserveApprovedAmount | number : '1.2-2'">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                    </th>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Remaining Drawdown
                    </th>
                    <th
                        [textContent]="(riskReserveDetails[0].bidCostAmount - riskReserveApprovedAmount) | number :'1.2-2' ">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                    </th>
                </tr>
            </tfoot>
        </table>
        <div id="staffedRRCaption" class="risk-reserve-caption">
            Staffed Risk Reserve
        </div>
        <table width="100%" aria-labelledby="staffedRRCaption">
            <tbody>
                <tr>
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Staffed Cost
                    </th>
                    <th [ngClass]="{'pad-right0': riskReserveDetails[0].staffedCost > riskReserveApprovedAmount}">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                        <span [textContent]="riskReserveDetails[0].staffedCost | number : '1.2-2'"></span>

                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            *ngIf="riskReserveDetails[0].staffedCost > riskReserveApprovedAmount"
                            [ngbTooltipContent]="'RR Staffed Cost is greater than RR Approved Drawdown.'"
                            [dmIcon]="'tile__icon--info icon icon-warning'"
                            ariaLabelToolTip="RR Staffed Cost warning Information">
                        </dm-tooltip>
                        <b class="dm-tile__icon--circle icon icon-circle"
                            *ngIf="riskReserveDetails[0].staffedCost > riskReserveApprovedAmount"></b>
                    </th>
                </tr>
                <tr>
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Unstaffed Cost
                    </th>
                    <th [textContent]="riskReserveDetails[0].unStaffedCost | number : '1.2-2' ">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                    </th>
                </tr>
            </tbody>
        </table>
        <div id="consumedRRCaption" class="risk-reserve-caption">
            Consumed Risk Reserve
        </div>
        <table width="100%" aria-labelledby="consumedRRCaption">
            <tbody>
                <tr>
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Consumed Cost
                    </th>
                    <th [ngClass]="{'pad-right0': riskReserveConsumedAmount > riskReserveApprovedAmount}">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                        <span [textContent]="riskReserveConsumedAmount | number : '1.2-2'"></span>

                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            *ngIf="riskReserveConsumedAmount > riskReserveApprovedAmount"
                            [ngbTooltipContent]="'RR Consumed Cost is greater than RR Approved Drawdown.'"
                            [dmIcon]="'tile__icon--info icon icon-warning'"
                            ariaLabelToolTip="RR Consumed Cost warning Information">
                        </dm-tooltip>
                    </th>
                </tr>
                <tr class="border-duoble">
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Estimate to Complete (ETC)
                    </th>
                    <th [textContent]="riskReserveDetails[0].etcCost | number : '1.2-2'">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                    </th>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th>
                        <span class="sr-only" scope="col">Type</span>
                        RR Estimate at Complete (EAC)
                    </th>
                    <th [ngClass]="{'pad-right0': riskReserveDetails[0].eacCost > riskReserveDetails[0].bidCostAmount}">
                        <span class="sr-only" scope="col">Amount ({{currency}})</span>
                        <span [textContent]="riskReserveDetails[0].eacCost | number : '1.2-2'"></span>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            *ngIf="riskReserveDetails[0].eacCost > riskReserveDetails[0].bidCostAmount"
                            [ngbTooltipContent]="'RR EAC is greater than RR Cost Amount.'"
                            [dmIcon]="'tile__icon--info icon icon-warning'"
                            ariaLabelToolTip="RR Estimate at Complete (EAC) warning Information">
                        </dm-tooltip>
                    </th>
                </tr>
            </tfoot>
        </table>
    </div>
</div>