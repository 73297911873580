import { Inject, Component, Input, Output, EventEmitter, SecurityContext } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { Components } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ISelectedUserAttributes } from "../../tiles/type-ahead/type-ahead-contracts";
import { IPersonDetails } from "../../manage-ebs-v2/tiles/entity-team-structure/entity-team-structure.component";
import { AADGraphService } from "../../../common/services/aad-graphapi.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "dm-user-name",
    templateUrl: "./user-name.html",
    styleUrls: ["./user-name.scss"]
})
export class DmUserProfileComponent extends DmComponentAbstract {

    @Input() public name: string;
    @Input() public alias: string;
    @Input() public showEllipses: boolean = false;
    @Input() public showUserNameAndAlias: boolean = false;
    @Input() public shouldDisplayChatIcon: boolean = false;
    @Input() public role: string;
    @Input() public showPersonCard: boolean = false;
    @Input() public showPersonCardWithViewAvatar: boolean = false;
    @Input() public personCardLabel: string = "";
    @Output() public onNameClick: EventEmitter<boolean> = new EventEmitter();
    public isLoggedInUserPJM: boolean;
    public personDetails: IPersonDetails;
    public personImage: string;

    private loggedInUserInfo: ISelectedUserAttributes;
    private emailPostFix: string = "@microsoft.com";

    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,        
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,        
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer   ) {
        super(dmLogger, Components.UserName);
    }

    public ngOnInit(): void {
        this.loggedInUserInfo = this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr();
        this.isLoggedInUserPJM = this.validateUserWithLoggedInUser();
        this.getPersonDetails();       
    }

    /**
     *  emits true when an employee name is clicked.
     */
    public logEmployeeClick(): void {
        this.onNameClick.emit(true);
    }

    /**
     *  check whether user is logged in user
     */
    private validateUserWithLoggedInUser(): boolean {
        if (this.loggedInUserInfo) {
            if (this.loggedInUserInfo.userAlias && this.alias) {
                return this.alias.toLowerCase() === this.loggedInUserInfo.userAlias.toLowerCase(); 
            } else if (this.loggedInUserInfo.fullName && this.name) {
                return this.name.toLowerCase() === this.loggedInUserInfo.fullName.toLowerCase(); 
            }
        }
        return true;
    }

    /**
     *  get person details to show the person card for the user on hover on the name
     */
    private getPersonDetails(): void {
        this.personDetails = {displayName: this.name,
            mail: this.alias ? this.alias + this.emailPostFix : "",
            jobTitle: this.role,
            alias: this.alias
        };
        if (!this.isLoggedInUserPJM && this.alias) {
            this.personDetails.userPrincipalName = this.alias + this.emailPostFix;
        }
        if (this.alias) {
            this.aadGraphService.getResourceThumbnailPicture(this.alias)
                .then((resourcePhotoResponse) => {
                    if (resourcePhotoResponse) {
                        this.personImage = this.domSanitizer.sanitize(SecurityContext.URL, "data:image/jpg;base64," + resourcePhotoResponse);
                    }
                });
        }
    }
}