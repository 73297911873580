import { FxpHttpService } from "@fxp/fxpservices";
import { forwardRef, Inject, Injectable } from "@angular/core";
import "rxjs/Rx"; // todo: need to import submodule instead
import "rxjs/add/operator/map"; // todo: need to import submodule instead

import { APIConstants, CacheKeys, CacheStorageOptions, Services } from "../application.constants";
import { CacheService } from "./cache.service";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { ICityDetails, ICountryDetails, IMasterSkillsLevels, IOneProfileBulkAPIResponse, IOneProfileTrainingAPIResponse, IRoleSkills, IStateDetails, IOneProfileAPIResponse, IRoleDomainSkill, ILocation, ISkillDetails, ISubconOnboardingSnapshot} from "./contracts/one-profile.contracts";
import { IOneProfileSerResAttr } from "../../components/tiles/type-ahead/type-ahead-contracts";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { Observable } from "rxjs/Rx";
import { from } from "rxjs";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";
import { ICustomerEngagementDomain } from "./contracts/add-roles.contracts";
@Injectable()
export class OneProfileService extends DmServiceAbstract {

    private roleSkills: IRoleDomainSkill[] = [];
    private oneProfileBaseUri: string;
    private oneProfileCoreBaseUri: string;
    private searchCompanyCodeCanceler: Promise<{}>;
    private takeCitiesLimit: string;
    private showOPLevel2Skills: boolean;

    public constructor(
        @Inject(forwardRef(() => FxpHttpService)) private fxpHttpService: FxpHttpService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.OneProfileService );
        this.configManagerService.initialize().then(() => {
            this.oneProfileBaseUri = this.configManagerService.getValue<string>("oneProfileBaseUri");
            this.oneProfileCoreBaseUri = this.configManagerService.getValue<string>("oneProfileCoreBaseUri");
            this.takeCitiesLimit = this.configManagerService.getValue<string>("takeCitiesLimit");
            this.showOPLevel2Skills = this.configManagerService.isFeatureEnabled("showOPLevel2Skills");
        });
    }

    /**
     * Gets a profile from One Profile based on the given alias.
     *
     * @param {string} alias
     * @returns {Promise<IOneProfileBulkAPIResponse[]>}
     * @memberof OneProfileService
     */
    public getProfile(alias: string): Promise<IOneProfileAPIResponse> {
        const url = this.oneProfileBaseUri + `/api/v2.0/profile/${alias}`;
        return this.dataService.getData(url, "", APIConstants.BulkUserBasicDetails);
    }

    /**
     * Gets a list of profiles from One Profile based on the given list of aliases.
     *
     * @param {string[]} aliasArray
     * @returns {Promise<IOneProfileBulkAPIResponse[]>}
     * @memberof OneProfileService
     */
    public getBulkProfiles(aliasArray: string[]): Promise<IOneProfileBulkAPIResponse[]> {
        const inputObject = {
            Alias: aliasArray
        };
        const url = this.oneProfileBaseUri + "/api/v2.0/profile/getbulkprofile";
        return this.dataService.postData(url, "", APIConstants.BulkUserBasicDetails, inputObject);
    }

    /**
     * A getter for the Role Skills list to be accessed in other components such as Type Ahead Skills.
     * Just returns the role skills we have already retrieved from the API--does not call the API.
     *
     * @returns {IRoleDomainSkill[]}
     * @memberof OneProfileService
     */
    public getRoleSkills(): IRoleDomainSkill[] {
        return this.roleSkills;
    }

    /**
     * Search skills based on api, from the api
     *
     * @returns {IRoleDomainSkill[]}
     * @memberof OneProfileService
     */
    public getSkillsFromApiBasedOnSearchText(searchText: string, orgType: string):  Promise<ISkillDetails[]> {
        const url = this.oneProfileBaseUri + `/api/v2.0/master/skills?take=20&gracePeriod=1&statusfilter=New,Retiring,Active&searchText=${searchText}&orgtype=${orgType}`;
        return this.dataService.getData(url, "", APIConstants.BulkUserBasicDetails);
    }


    /**
     * Search user skills with a given grace period from One Profile API.
     * Gets Product Skills, Technical skills and Functional skills based on the category ID (requires separate API calls.)
     * @param gracePeriod
     */
    public searchUserSkills(gracePeriod: number): Promise<IRoleSkills[]> {
        /* URL for Product Skills based on categoryId 448198*/
        const orgType = this.showOPLevel2Skills ? "MCS" : "Consulting";
        const productUrl = this.oneProfileBaseUri + `/api/v2.0/master/skillshierarchy?categoryid=448198&OrgType=${orgType}&Statusfilter=New,Active,Retiring&Graceperiod=` + gracePeriod.toString();
        /* URL for Functional Skills based on categoryId 448199*/
        const functionalUrl = this.oneProfileBaseUri + `/api/v2.0/master/skillshierarchy?categoryid=448199&OrgType=${orgType}&Statusfilter=New,Active,Retiring&Graceperiod=` + gracePeriod.toString();
        /* URL for Technical Skills based on categoryId 448191*/
        const technicalUrl = this.oneProfileBaseUri + `/api/v2.0/master/skillshierarchy?categoryid=448191&OrgType=${orgType}&Statusfilter=New,Active,Retiring&Graceperiod=` + gracePeriod.toString();
        
        const headers = {
            "Content-Type": "application/json",
            "retryEnabled": false
        };

        let skillsList: IMasterSkillsLevels[] = [];
        const productSkillsPromise = this.cacheService.get(CacheKeys.ProductSkills.KeyName,
            () => this.fxpHttpService.get(productUrl, headers), CacheKeys.ProductSkills.Duration, CacheStorageOptions.LocalStorage)
            .then((response) => {
                if (response.data) {
                    skillsList = skillsList.concat(response.data);
                }
            });
        const functionalSkillsPromise = this.cacheService.get(CacheKeys.FunctionalSkills.KeyName,
            () => this.fxpHttpService.get(functionalUrl, headers), CacheKeys.FunctionalSkills.Duration, CacheStorageOptions.LocalStorage)
            .then((response) => {
                if (response.data) {
                    skillsList = skillsList.concat(response.data);
                }
            });
        const technicalSkillsPromise = this.cacheService.get(CacheKeys.TechnicalSkills.KeyName,
            () => this.fxpHttpService.get(technicalUrl, headers), CacheKeys.TechnicalSkills.Duration, CacheStorageOptions.LocalStorage)
            .then((response) => {
                if (response.data) {
                    skillsList = skillsList.concat(response.data);
                }
            });

        return Promise.all([productSkillsPromise, functionalSkillsPromise, technicalSkillsPromise])
            .then(() => {
                this.roleSkills = []; // resets the role skills list to get a fresh call of the data.
                skillsList.forEach((level1: IMasterSkillsLevels) => {
                    if (level1 && level1.MasterSkillLevels && level1.MasterSkillLevels.length) {
                        level1.MasterSkillLevels.forEach((level2: IMasterSkillsLevels) => {
                            if (this.showOPLevel2Skills) {
                                const roleSkill: IRoleDomainSkill = {
                                    JobSkillId: level2.JobSkillId,
                                    JobSkillValue: level2.LevelValue,
                                    Domains: level2.DimensionStr && level2.DimensionStr.length > 0 ? this.sharedFunctionsService.getDomainDetailsFromDimensions(level2.DimensionStr) : this.sharedFunctionsService.getDomainFromSkill(level2)
                                };
                                if (level2.SkillStatus === "Retiring") {
                                    roleSkill.JobSkillValue = roleSkill.JobSkillValue + " (" + level2.SkillStatus + ")";
                                }
                                this.roleSkills.push(roleSkill);
                            } else {
                                if (level2 && level2.MasterSkillLevels && level2.MasterSkillLevels.length) {
                                    level2.MasterSkillLevels.forEach((level3: IMasterSkillsLevels) => {
                                        const roleSkill: IRoleDomainSkill = {
                                            JobSkillId: level3.JobSkillId,
                                            JobSkillValue: level3.LevelValue,
                                            Domains: level3.DimensionStr && level3.DimensionStr.length > 0 ? this.sharedFunctionsService.getDomainDetailsFromDimensions(level3.DimensionStr) : this.sharedFunctionsService.getDomainFromSkill(level3)
                                        };
                                        if (level3.SkillStatus === "Retiring") {
                                            roleSkill.JobSkillValue = roleSkill.JobSkillValue + " (" + level3.SkillStatus + ")";
                                        }
                                        this.roleSkills.push(roleSkill);
                                    });
                                }
                            }
                        });
                    }
                });
                return Promise.resolve(this.roleSkills);
            });
    }

    /**
     * Gets the list if countries from the One Profile API
     */
    public getCountries(): Promise<ICountryDetails[]> {
        const url = this.oneProfileBaseUri + "/api/v1/master/country";
        return this.dataService.getData(url, "", APIConstants.GetCountries);
    }

    /**
     * Gets a list of states based on the country code from the One Profile API
     * @param countryCode
     */
    public getStatesBasedOnCountryCode(countryCode: string): Promise<IStateDetails[]> {
        // todo change API url back to v2.0 for this function
        const url = this.oneProfileBaseUri + "/api/v1/master/" + countryCode + "/states";
        return this.dataService.getData(url, "", APIConstants.GetStatesBasedOnCountry);
    }

    /**
     * Gets a list of cities based on the given country code and state name from the One Profile API
     * @param countryCode
     * @param stateName
     */
    public getCitiesBasedOnCountryCodeStateName(countryCode: string, stateName: string): Promise<ICityDetails[]> {
        const url = `${this.oneProfileBaseUri}/api/v1/master/citylist?country=${countryCode}&state=${stateName}`;
        return this.dataService.getData(url, "", APIConstants.GetCitiesBasedOnStateCountry);
    }

    /**
     * Gets a list of cities based on the country, state or city search text from the One Profile API
     * @param searchText
     */
    public getCitiesBasedOnSearchText(searchText: string): Promise<ILocation[]> {
        const url = `${this.oneProfileBaseUri}/api/v2.0/master/cities?searchtext=${searchText}&take=${this.takeCitiesLimit}`;
        return this.dataService.getData(url, "", APIConstants.GetCitiesBasedOnStateCountry);
    }

    /**
     * Gets a list of resource training details based on the given resource alias
     * @param resourceAlias
     */
    public getResourceTrainingDetails(resourceAlias: string): Promise<IOneProfileTrainingAPIResponse[]> {
        const url = `${this.oneProfileBaseUri}/api/v2.0/profile/${resourceAlias}/training`;
        return this.dataService.getData(url, "", APIConstants.GetTrainingDetails);
    }

    /**
     * Calls the One Profile API and searches for profiles that matchthe given search values.
     * Will return FTEs only if the FTE only flag is  true.
     * Will filter out any ignored BPIDs.
     * @param searchValue
     */
    public profileSearch(searchValue: string, fteOnly: boolean, ignoreBpid: number[], fteAndContingentStaff: boolean = false): Promise<IOneProfileSerResAttr[]> {

        let url = this.oneProfileBaseUri + "/api/v2.0";
        url += "/profile/searchprofile?searchText=" + searchValue;
        if (fteAndContingentStaff) {
            url += "&resourceCategoryTypes=0,1";
        } else if (fteOnly) { // For the V2 API
            url += "&resourceCategoryTypes=0";
        }

        return this.dataService.getData(url, "", "").then((response: IOneProfileSerResAttr[]) => {
            let userList: IOneProfileSerResAttr[] = [];
            if (response) {
                userList = response.filter((x) => x.BusinessPartnerId && ignoreBpid.indexOf(Number(x.BusinessPartnerId)) < 0);
                userList = userList.slice(0, 8);
            }
            return userList;

        });
    }

    /**
     * Search Company Codes List with Observable
     * @param searchValue
     */
    public searchCompanyCodesList(searchValue: string): Observable<any> {

        if (this.searchCompanyCodeCanceler) {
            Promise.resolve(this.searchCompanyCodeCanceler);
            this.searchCompanyCodeCanceler = undefined;
        }
        const url = `${this.oneProfileBaseUri}/api/v2.0/master/companycode?searchtext=${searchValue}`;
        return from(this.dataService.getData(url, "", APIConstants.SearchCompanyCodes));
    }

    /**
     * Gets a list of customer engagement domains
     */
    public getCustomerEngagementDomains(): Promise<ICustomerEngagementDomain[]> {
        const url = !this.showOPLevel2Skills ? `${this.oneProfileBaseUri}/api/v2.0/master/servicesdeliveryorganization` : `${this.oneProfileBaseUri}/api/v2.0/master/servicesdeliveryorganization?orgType=MCS`;
        return this.dataService.getData(url, "", APIConstants.GetCustomerEngagementDomains);
    }

    /**
     * Gets a profile from One Profile based on the given bpId.
     *
     * @param {string} bpId
     * @returns {Promise<IOneProfileAPIResponse>}
     * @memberof OneProfileService
     */
    public getProfileBasedOnBpid(bpId: string): Promise<IOneProfileAPIResponse> {
        const url = this.oneProfileBaseUri + `/api/v2.0/profile/${bpId}/basic`;
        return this.dataService.getData(url, "", APIConstants.GetUserDetailsFromBpId);
    }

    public getSubconOnboardingStatus(userAlias: string): Promise<ISubconOnboardingSnapshot[]> {
        const url = this.oneProfileCoreBaseUri + `/api/subcononboarding/pjmsnapshot/${userAlias}`;
        return this.dataService.getData(url, "", APIConstants.GetSubconOnboardingStatusFromAlias, [403]);
    }
}
