import { IChangeRequestRoleDetail } from "./../amendments.contract";

import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, Inject, forwardRef, Injector } from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceFactoryProvider, ErrorSeverityLevel } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { AmendmentsService } from "../../../common/services/amendments.service";
import { Components, RouteName, ComponentFailureMessages, SourceConstants, AccessibilityConstants } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../store/financial-details-v2/financial-details-v2.selector";
import { ICRDetailsList, IAmendmentPackage, IAmendmentV2, IAmendmentPackageDetails } from "../amendments.contract";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IFinancialDetailsV2State } from "../../../store/financial-details-v2/financial-details-v2.reducer";
import { IFinancialPlanV2, IEntityFinancialSummary } from "../../financial-mgmt/financial.model";
import { IState } from "../../../store/reducers";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ViewWorkBookModalComponent } from "../view-workbook-modal/view-workbook-modal.component";

@Component({
    selector: "dm-amendment-details",
    templateUrl: "./amendment-details.html",
    styleUrls: ["./amendment-details.scss"]
})
export class AmendmentDetailsComponent extends DmComponentAbstract {

    public currentBaseLineDetails: IFinancialPlanV2;
    public currentFinancialDetails: IEntityFinancialSummary;
    public currentBaseLinePlanDetailsUpdatedDate: Date; /* for easier access to the view */
    public currentBaseLinePlanDetailsStatusDescription: string;
    public crDetails: ICRDetailsList;
    public projectsWithAmendments: IAmendmentPackage[];
    public currency: string;
    public resourcesType: string;
    public backToFinancialsRouteName: string;
    public backToFinancialsRouteParams: { [key: string]: string };
    public loadingAmendmentsText: string;
    public accessibilityConstants = AccessibilityConstants;
    private entityFullDetails: IEngagementDetailsV2;
    private engagementDescription: string;
    private isProjectContext: boolean = false;
    private engagementId: string;
    private contractTypesList: string[] = [];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(AmendmentsService) private amendmentsService: AmendmentsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.AmendmentDetails);
    }

    public ngOnInit(): void {
        const currentCrNumber: number = Number(this.stateService.params.amendmentId);
        this.loadingAmendmentsText = "Loading Amendments Details for CR " + currentCrNumber;
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.errorText = ComponentFailureMessages.EngagementAmendmentComponent;
        this.isProjectContext = projectId ? true : false;
        const entityId: string = projectId ? projectId : this.engagementId;
        this.backToFinancialsRouteName = this.isProjectContext ? RouteName.ProjectFinancials : RouteName.EngagementFinancials;
        this.backToFinancialsRouteParams = this.isProjectContext ? { projectId } : { engagementId: this.engagementId };

        const engagementDetails$ = this.store.select(getEntireEngagementDetails(entityId));
        const financialDetails$ = this.store.select(getEntireFinancialDetailsV2(entityId));
        observableCombineLatest(
            engagementDetails$,
            financialDetails$,
            (
                engagementDetails: IEngagementDetailsState,
                financialDetails: IFinancialDetailsV2State
            ) => ({
                engagementDetails,
                financialDetails
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                engagementDetails,
                financialDetails,
            }) => {
                if (engagementDetails.loaded && financialDetails.loaded) {
                    this.entityFullDetails = engagementDetails.engagementDetails;
                    this.engagementDescription = engagementDetails.engagementDetails.description;

                    this.currentFinancialDetails = financialDetails.financialDetails.financialSummary.filter((financialSummary) => financialSummary.versionKey === "3")[0]; // "3" is "Current Financial Plan"
                    this.currency = this.currentFinancialDetails ? this.currentFinancialDetails.planCurrency : undefined;
                    const currentBaselineDetails = financialDetails.financialDetails.financialPlanVersions.filter((financialPlanVersion) => financialPlanVersion.baseLineType === "3"); // "3" is "Current Financial Plan"
                    if (currentBaselineDetails[0] && currentBaselineDetails[0].financialPlans && currentBaselineDetails[0].financialPlans[0]) {
                        this.currentBaseLineDetails = currentBaselineDetails[0].financialPlans[0];
                        this.currentBaseLinePlanDetailsUpdatedDate = currentBaselineDetails[0].financialPlans[0].changedOn;
                        this.currentBaseLinePlanDetailsStatusDescription = currentBaselineDetails[0].financialPlans[0].statusDescription;
                    }

                    this.getCrDetailsList(this.engagementId)
                        .then((crDetailsList: ICRDetailsList[]) => {
                            /* Loading will have been turned off automatically (necessary for refresh functionality), so we must turn it back on. */
                            this.isComponentLoading = true;
                            return this.loadAmendmentDetails(this.engagementId, currentCrNumber, crDetailsList);
                        }).then(() => {
                            this.isComponentLoading = false;
                        }).catch((error: any) => {
                            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                            this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                            if (error.status === 404) { /* 404 is not an error in this instance, so turn off loading */
                                this.isComponentLoading = false;
                            } else {
                                this.showLoading = false;
                                this.isComponentLoading = true;
                            }
                        });
                }

                /* This is used for refresh functionality, but otherwise does not toggle the loaders like typical in other components */
                this.refreshOnItemInvalidation(engagementDetails, financialDetails);
                this.setLoadersBasedOnItemState(engagementDetails, financialDetails);
                this.setErrorsBasedOnItemState(engagementDetails, financialDetails);
            });
    }

    /**
     * View work book details in modal popup
     * @param workbookUrl
     * @param popUpTitle
     */
    public openViewWorkbookModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(ViewWorkBookModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal newsnapshotModal in",
            injector: this.injector
        });
        modalRef.componentInstance.contextId = this.engagementId;
    }

    /**
     * Load Data Model for Amendment Details for CR Selected
     */
    private loadAmendmentDetails(engagementId: string, currentCrNumber: number, crDetailsList: ICRDetailsList[]): Promise<void> {
        this.projectsWithAmendments = [];
        this.crDetails = crDetailsList.filter((details: ICRDetailsList) => details.crNumber === currentCrNumber)[0];
        return this.amendmentsService.getAmendmentByEngagementIDAndCRNumberV2(currentCrNumber.toString(), engagementId)
            .then((response: IAmendmentV2) => {
                const amendmentDetails: IChangeRequestRoleDetail[] = response.changeRequestRoleDetails;
                for (const project of this.entityFullDetails.projects) {
                    this.setAmendmentPackagesForProject(project, currentCrNumber, amendmentDetails);
                }
            });
    }

    // todo this could be combined to be more robust with the existing crdetailslist getter in amendment services
    /**
     * Gets the list of CR Detail List items based on the engagement context. Calls the PMS API through the amendments service.
     *
     * @private
     * @param {string} engagementId
     * @returns {Promise<ICRDetailsList[]>}
     * @memberof AmendmentDetailsComponent
     */
    private getCrDetailsList(engagementId: string): Promise<ICRDetailsList[]> {
        return this.amendmentsService.getCRDetailsList(engagementId);
    }

    /**
     * Get Amendment Packages For Project
     * @param project
     */
    private setAmendmentPackagesForProject(project: IProjectDetailsV2, currentCrNumber: number, amendmentDetails: IChangeRequestRoleDetail[]): void {
        for (const service of project.services) {
            for (const wbsl3 of service.tasks) {
                const extendedAmendmentInfoArray = amendmentDetails.filter((details) => details.taskId === wbsl3.id);
                if (extendedAmendmentInfoArray && extendedAmendmentInfoArray.length) { /* Found a match between the project's services and the amendment details */
                    for (const extendedAmendmentInfo of extendedAmendmentInfoArray) {
                        const existingProjectFilterArray: IAmendmentPackage[] = this.projectsWithAmendments.filter((p) => p.projectId === project.id);
                        let existingProject: IAmendmentPackage = existingProjectFilterArray[0];
                        if (!existingProject) {
                            const projectInfo: IAmendmentPackage = {
                                projectId: project.id,
                                projectName: project.name,
                                projectDescription: project.description,
                                typeOfContract: project.contractType,
                                startDate: project.startDate,
                                endDate: project.endDate,
                                riskReserve: undefined,
                                amendments: []
                            };
                            existingProject = projectInfo;
                            this.projectsWithAmendments.push(existingProject);
                        }

                        let type: string;
                        if (extendedAmendmentInfo.resourceType === "0ACT") {
                            type = "Labor";
                        } else if (extendedAmendmentInfo.resourceType === "ZRRV") {
                            type = "Risk Reserve";
                        } else if (extendedAmendmentInfo.resourceType === "0EXP") {
                            type = "Expenses";
                        } else if (extendedAmendmentInfo.resourceType === "0MAT") {
                            type = "Material";
                        } else if (extendedAmendmentInfo.resourceType === "ZEXS") {
                            type = "Subcontractor FF";
                        } else if (extendedAmendmentInfo.resourceType === "ZUNI") {
                            type = "Unit";
                        }

                        this.contractTypesList.push(type);
                        const amendmentPackageDetails: IAmendmentPackageDetails = {
                            crNumber: currentCrNumber,
                            engagementId: project.engagementId,
                            engagementDescription: this.engagementDescription,
                            projectId: project.id,
                            projectName: project.name,
                            projectDescription: project.description,
                            wbsl3Id: wbsl3.id,
                            wbsl3Description: wbsl3.description,
                            type,
                            roleId: extendedAmendmentInfo.role,
                            roleDescription: extendedAmendmentInfo.roleDescription,
                            plannedLaborHours: extendedAmendmentInfo.requestedQuantity,
                            billRate: extendedAmendmentInfo.billRate,
                            costRate: extendedAmendmentInfo.costRate,
                            plannedCost: extendedAmendmentInfo.planCost,
                            riskReserve: 0,
                            plannedRevenue: extendedAmendmentInfo.planRevenue,
                            startDate: wbsl3.startDate,
                            endDate: wbsl3.endDate,
                        };
                        existingProject.amendments.push(amendmentPackageDetails);

                    }
                }
            }
        }

        // Push contract typein to a list and check if amendment contains units only or labor only or mixed (both unit and labor)
        // based resources and set resourcesType
        if (this.stringExistsInArray(this.contractTypesList, "Unit") && this.stringExistsInArray(this.contractTypesList, "Labor")) {
            this.resourcesType = "Mixed";
        } else if (this.stringExistsInArray(this.contractTypesList, "Unit") && !this.stringExistsInArray(this.contractTypesList, "Labor")) {
            this.resourcesType = "Unit";
        } else if (!this.stringExistsInArray(this.contractTypesList, "Unit") && this.stringExistsInArray(this.contractTypesList, "Labor")) {
            this.resourcesType = "Labor";
        }
    }

    /**
     * Check if a string exists in an array
     *
     * @private
     * @param {*} list
     * @param {*} matchString
     * @returns
     * @memberof AmendmentDetailsComponent
     */
    private stringExistsInArray(array, matchString) {
        return (array.indexOf(matchString) > -1);
    }
}

