<section class="dm-finaci-snap col-xs-12 dm-p-x-imp-0">
    <section class="dm-snapshot" *ngIf="!isServerError">
        <section class="action__trigger pull-right">
            <section class="action__trigger">
                <a id="new-snapshots" aria-label="Take New Snapshot" tabindex="0"
                    class="font-caption-alt action__trigger--copy-state set-focus dm-link" (click)="openNewSnapshotModal()" (keypress)="openNewSnapshotModal()">
                    <i class="icon icon-copyLegacy"></i>Take New Snapshot
                </a>
            </section>
        </section>
        <table class="dm-s-header dm-table__header pd-t-12" *ngIf="!isComponentLoading">
            <caption class="sr-only">Current Financial Plan</caption>
            <tr>
                <th scope="rowgroup" rowspan="2" class="dm-s-white dm-s-min-250">
                    <p>Current Financial Plan
                        <span *ngIf="currentBaseLinePlanDetails">
                            ({{currentBaseLinePlanDetails.statusDescription}})
                        </span>
                    </p>
                </th>
                <th scope="col" class="dm-s-min-46">
                    <p class="dm-s-th-heading">Margin</p>
                </th>
                <th scope="col" class="dm-s-min-172">
                    <p class="dm-s-th-heading">Cost
                        <span *ngIf="currentBaseLineDetails"> ({{currency}})</span>
                    </p>
                </th>
                <th scope="col" class="dm-s-min-172">
                    <p class="dm-s-th-heading">Revenue
                        <span *ngIf="currentBaseLineDetails"> ({{currency}})</span>
                    </p>
                </th>
                <th scope="col" class="dm-s-min-172">
                    <p class="dm-s-th-heading"> Labor (hours)</p>
                </th>
                <th scope="col" class="dm-s-min-172">
                    <p class="dm-s-th-heading"> Risk Reserve
                        <span *ngIf="currentBaseLineDetails"> ({{currency}})</span>
                    </p>
                </th>
            </tr>
            <tr>
                <td class="dm-s-min-46">
                    <p>
                        {{currentBaseLineDetails.margin?(currentBaseLineDetails.margin | number : '1.0-2') + '%':'-'}}
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p class="pull-left">
                        {{currentBaseLineDetails.cost | dmDisplayCurrencyOrDash: currency}}
                        <span *ngIf="!currentBaseLineDetails.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p>
                        {{currentBaseLineDetails.revenue | dmDisplayCurrencyOrDash: currency}}
                        <span *ngIf="!currentBaseLineDetails.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p>
                        {{currentBaseLineDetails.labor ? (currentBaseLineDetails.labor | number : '1.0-2') : '-'}}
                    </p>
                </td>
                <td class="dm-s-min-172">
                    <p>
                        {{currentBaseLineDetails.riskReserveAmount | dmDisplayCurrencyOrDash: currency}}
                        <span *ngIf="!currentBaseLineDetails.riskReserveAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </p>
                </td>
            </tr>
        </table>
        <dm-loading class="snapshot-loading" [loadingText]="'Snapshots'" *ngIf="isComponentLoading"
            [showLoading]="showLoading"></dm-loading>
        <section class="dm-s-results pd-t-12" *ngIf="!isComponentLoading">            
            <div id="snapShotCaption" class="sr-only">Snapshots</div>
            <table class="dm-table__header dm-tile--plan-table dm-tile__snap_shot_details b-b-none" aria-labelledby="snapShotCaption" *ngIf="snapShotPlanDetails.length > 0">
                <thead>
                    <tr class="dm-tile--b-b-03">
                        <th scope="col" class="font-caption-alt dm-p-l-imp-0">
                            Snapshot Name
                        </th>
                        <th scope="col" class="font-caption-alt">Timestamp (PST)</th>
                        <th scope="col" class="font-caption-alt">Rename Snapshot</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="font-caption-alt dm-tile--b-b-015"
                        *ngFor="let snapShot of snapShotPlanDetails | orderBy: 'changedOn' : true | slice:slicedItemsNumber:gridItemsDisplay">
                        <th scope="row">
                            <a *ngIf="snapShot.planWorkBookLink" [href]="snapShot.planWorkBookLink" target="_blank"
                                title="Click to view the Snapshot in a new window" class="font-caption-alt dm-link" aria-label="open link in new tab">
                                {{snapShot.versionDescription}}
                            </a>
                            <span *ngIf="!snapShot.planWorkBookLink">
                                {{snapShot.versionDescription}}
                            </span>
                        </th>
                        <td [attr.aria-label]="snapShot.changedOn | date:'dd MMMM yyyy h:mm a'">
                            {{snapShot.changedOn | date:'dd-MMM-yyyy h:mm a'}}
                        </td>
                        <td>
                            <button class="snapshot-edit-btn set-focus" type="button"
                                [title]="'Edit'+ snapShot.versionDescription"
                                [attr.aria-label]="'Edit '+snapShot.versionDescription"
                                (click)="renameSnapShot(snapShot)">
                                <i class="icon icon-editLegacy"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <dm-no-data *ngIf="!snapShotPlanDetails.length" [noDataText]="noSnapshots"></dm-no-data>
            <dm-pagination *ngIf="snapShotPlanDetails.length > 5" [portfolioItemsDisp]="numberOfSnapshotsToDisplay"
                [currentPage]="currentPageOfSnapshots" [portfolioDataLengthAfterFilter]="snapShotPlanDetails.length"
                (currentPageChanged)="currentPageChangedHandler($event)">
            </dm-pagination>
        </section>
    </section>
         <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</section>