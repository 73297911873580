<section class="dm-m-10">
  <dm-no-data *ngIf="auditList?.length === 0" [noDataText]="'No Audit History Records Found'" showImage="true">
  </dm-no-data>
  <div class="timeline">
    <div class="timeline__group" *ngFor="let item of auditList | slice:slicedItemsNumber:gridItemsDisplay;">
      <span class="timeline__date">{{item.date}}</span>
      <div class="timeline__box dm-m-t-10"
        *ngFor="let audit of item.auditItemsPerDate">
        <div class="timeline__time">{{audit.time}}</div>
        <div class="timeline__dot"></div>
        <div class="timeline__content">
          <div class="timeline__status">
            <div *ngIf="audit?.userImage">
              <img [src]="audit?.userImage" class="timeline__contact-image" />
            </div>
            <span class="dm-p-x-10" [innerHTML]="audit.status"></span>
          </div>
          <div class="minion-audit-section" *ngIf="audit.type === 'Forecast'">
            <div class="minion-audit-section-row"
              *ngFor="let entry of audit.items | filter: selectedFilter ? {version : selectedFilter.id} : undefined;">
              <span class="tag etcTag" *ngIf="entry?.version === 'Forecast'">ETC</span>
              <span class="tag eacTag" *ngIf="entry?.version === 'EAC'">EAC</span>
              <span *ngIf="entry?.version !== 'EAC' && entry?.version !== 'Forecast'"></span>
              <div class="minion-audit" *ngFor="let item of entry?.values">
                <div class="minion-audit__title">{{item?.title}} <span *ngIf="item?.unit">({{item?.unit}})</span></div>
                <div class="minion-audit__indicator">
                  <span *ngIf="item?.change"
                    [ngClass]="{'arrow-up': Math.sign(item?.change) == 1, 'arrow-down': Math.sign(item?.change) == -1}">
                  </span>
                  <span class="dm-p-x-3" *ngIf="item?.change"
                    [ngClass]="{'text-color-up': Math.sign(item?.change) == 1, 'text-color-down': Math.sign(item?.change) == -1}">
                    {{Math.abs(item?.change) | percent: '1.2-2'}}
                  </span>
                </div>
                <div class="minion-audit__values pull-right">
                  <span *ngIf="item?.currValue" class="minion-audit__currentValue">{{item?.currValue |
                    dmDisplayDashIfEmptyOrNull | number: '1.2-2'}}</span>
                  <span *ngIf="item?.prevValue" class="minion-audit__previousValue">{{item?.prevValue |
                    dmDisplayDashIfEmptyOrNull | number: '1.2-2'}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="risk-reserve-section" *ngIf="audit.type === 'RiskReserve'">
            <div class="minion-audit">
              <div class="minion-audit__title">{{audit?.title}} <span *ngIf="audit?.unit">({{audit?.unit}})</span></div>
              <div class="minion-audit__indicator">
                <span *ngIf="audit?.rrAudit?.change"
                  [ngClass]="{'arrow-up': Math.sign(audit?.rrAudit?.change) == 1, 'arrow-down': Math.sign(audit?.rrAudit?.change) == -1}">
                </span>
                <span class="dm-p-x-3" *ngIf="audit?.rrAudit?.change"
                  [ngClass]="{'text-color-up': Math.sign(audit?.rrAudit?.change) == 1, 'text-color-down': Math.sign(audit?.rrAudit?.change) == -1}">
                  {{Math.abs(audit?.rrAudit?.change) | percent: '1.2-2'}}
                </span>
              </div>
              <div class="minion-audit__values pull-right">
                <span *ngIf="audit?.rrAudit?.currentRrAmount"
                  class="minion-audit__currentValue">{{audit?.rrAudit?.currentRrAmount |
                  dmDisplayDashIfEmptyOrNull |
                  number: '1.2-2'}}</span>
                <span *ngIf="audit?.rrAudit?.previousRrAmount"
                  class="minion-audit__previousValue">{{audit?.rrAudit?.previousRrAmount |
                  dmDisplayDashIfEmptyOrNull |
                  number: '1.2-2'}}</span>
              </div>
            </div>
            <div class="dm-flex-align-center">
              <span>Approval Attachment</span>
              <span tabindex="0" [title]="'Download Risk Reserve change justification attachment'"
                class="icon-full icon-download cursor-pointer dm-link"
                (click)="downloadRRAttachment(audit?.rrAudit?.fileId, audit?.engagementId)"
                (keyup.enter)="downloadRRAttachment(audit?.rrAudit?.fileId, audit?.engagementId)"
                (keyup.Space)="downloadRRAttachment(audit?.rrAudit?.fileId, audit?.engagementId)">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 dm-pagination">
    <dm-pagination-v2 *ngIf="isPaginationRequired" [(pageSize)]="activityFeedItemsDisp"
      [totalItemCount]="auditList.length" [currentPageNumber]="currentPage"
      (pageChanged)="currentPageChangedHandler($event)" (pageSizeChanged)="itemPerPageChangedHandler($event)"
      [itemsPerPage]="itemsPerPage">
    </dm-pagination-v2>
  </div>
</section>