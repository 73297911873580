<section class="dm-bulk-int-eng-status-modal display-inline-block">
  <section class="modal-header">
    <button type="button" class="close set-focus" (click)="closeStatusModal()" id="closeBulkIntEngStatusPopUp"
      [title]="'Bulk Create Internal Engagement Status Dialog close'">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
    <h4 class="modal-title font-subtitle pull-left">
      Bulk Upload Summary
    </h4>
  </section>
  <section class="modal-body dm-bulk-int-eng-status-modal__body">
    <section class="dm-bulk-int-eng-status-modal__grid" role="grid">
      <!-- CSS grid, Add grid column width in scss if you add a new field to grid -->
      <div role="row"
        class="dm-bulk-int-eng-status-modal__grid-header-row dm-bulk-int-eng-status-modal__grid-row dm-p-imp-0">
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-header-cell dm-p-0"></div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-header-cell">Engagement Name</div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-header-cell">Type</div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-header-cell">Start Date</div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-header-cell">End Date</div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-header-cell">Status</div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-header-cell"></div>
      </div>

      <div role="row" *ngFor="let intEngStatusDetail of intEngStatusDetails"
        class="dm-bulk-int-eng-status-modal__grid-row">
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-body-cell dm-p-imp-0"
          [ngClass]="{'status-error': intEngStatusDetail?.status === 'CreationFailed'|| intEngStatusDetail?.status === 'Discarded', 'status-success' : intEngStatusDetail?.status === 'CreationSuccessful', 'status-progress': intEngStatusDetail?.status === 'QueuedForCreation'|| intEngStatusDetail?.status === 'CreationInProgress' }">
        </div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-body-cell">
          <strong>Internal: <span *ngIf="intEngStatusDetail?.name && !intEngStatusDetail?.engagementId">
              {{intEngStatusDetail?.name | dmDisplayDashIfEmptyOrNull}}</span>
            <a *ngIf="intEngStatusDetail?.link && intEngStatusDetail?.engagementId" href="{{intEngStatusDetail?.link}}"
              (click)="activeModal.close()" class="dm-link">
              {{intEngStatusDetail?.name | dmDisplayDashIfEmptyOrNull}}
            </a>
          </strong> <br />
          <span *ngIf="intEngStatusDetail?.engagementId">EBS: {{intEngStatusDetail?.engagementId}}</span>
        </div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-body-cell">
          {{ intEngStatusDetail?.requestType | dmDisplayDashIfEmptyOrNull}}</div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-body-cell">
          {{intEngStatusDetail?.startDate | date : 'dd-MMM-yyyy'}}</div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-body-cell">
          {{intEngStatusDetail?.endDate | date : 'dd-MMM-yyyy'}}
        </div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-body-cell display-flex">
          <span class="dm-icon-holder" *ngIf="displayStatusIcon">
            <dm-tooltip role="tooltip" class="tooltip-status-icon-margin" [dmPlacement]="'right'"
              *ngIf="intEngStatusDetail?.status === 'CreationFailed'" [ngbTooltipContent]="creationErrorDetailsPopup"
              [dmIcon]="'icon icon-exclamation icon-rounded dm-error'" ariaLabelToolTip="Creation error details">
            </dm-tooltip>
            <ng-template #creationErrorDetailsPopup>
              <div id="creationErrorDetailsPopover" class="type-dialogBox">
                <ul>
                  <!--TODO: errors will be moved to components and mapped there-->
                  <li *ngIf="intEngStatusDetail?.errorDetails?.grmApiError">
                    {{intEngStatusDetail?.errorDetails?.grmApiError}}</li>
                  <li *ngIf="intEngStatusDetail?.errorDetails?.psApiError">
                    {{intEngStatusDetail?.errorDetails?.psApiError}}</li>
                </ul>
              </div>
            </ng-template>

            <span *ngIf="intEngStatusDetail?.status === 'CreationSuccessful'">
              <i class="icon icon-CheckMark icon-rounded dm-success"></i>
            </span>
            <span *ngIf="intEngStatusDetail?.status === 'ValidationInProgress'
               || intEngStatusDetail?.status === 'CreationInProgress'">
              <i class="icon record-progress-icon"></i>
            </span>
            <span *ngIf="intEngStatusDetail?.status === 'QueuedForCreation'">
              <i class="icon icon-timer dm-queued"></i>
            </span>
            <span *ngIf="intEngStatusDetail?.status === 'Discarded'">
              <i class="icon icon-close icon-rounded dm-error"></i>              
            </span>
          </span>
          <span class="dm-p-x-10-y-5" [ngClass]="{'dm-p-l-imp-0': !displayStatusIcon}">{{BulkIntEngStatusForDisplay[intEngStatusDetail?.status] | dmDisplayDashIfEmptyOrNull}}</span>
        </div>
        <div role="gridcell" class="dm-bulk-int-eng-status-modal__grid-body-cell">
          <span *ngIf="intEngStatusDetail?.status === 'CreationFailed'" style="cursor:pointer" class="font-caption dm-p-x-10" (click)="onRetry(intEngStatusDetail?.id)">Retry <i
              class="icon icon-refresh"></i></span>
        </div>
      </div>
    </section>
  </section>
</section>