import { Component, Inject, Input } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { AuditService } from "../../../common/services/audit.service";
import { IAuditResponse } from "../../../common/services/contracts/audit.contracts";

@Component({
    selector: "activity-feed",
    templateUrl: "./activity-feed.html",
    styleUrls: ["./activity-feed.scss"]
})
export class ActivityFeed extends DmComponentAbstract {
    @Input() public wbsAuditEntries: IAuditResponse[];
    public isProjectContext: boolean = false;
    public selectedEngagementId: string;
    public itemsPerPage: number[] = [5, 10, 15];
    public isPaginationRequired: boolean = false;
    public slicedItemsNumber: number = 0;
    public gridItemsDisplay: number = 5;
    public activityFeedItemsDisp: number = 5;
    public currentPage: number = 1;


    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(AuditService) private auditService: AuditService
    ) {
        super(dmLogger, Components.KeyActionsV2);
    }

    public ngOnInit(): void {
        this.isPaginationRequired = this.sharedFunctionService.isPaginationRequired(this.wbsAuditEntries);
    }

    /**
     * Changes the current page, grid items to display and sliced number on changing the page
     *
     * @param {number} currentPageValue
     */
    public currentPageChangedHandler(currentPageValue: number): void {
        const totalLength = this.wbsAuditEntries.length;
        const lastPage = Math.ceil(totalLength / this.activityFeedItemsDisp);
        if (currentPageValue === 1) {
            this.slicedItemsNumber = 0;
            this.gridItemsDisplay = this.activityFeedItemsDisp;
        } else if (this.currentPage < currentPageValue && currentPageValue !== lastPage) {
            this.slicedItemsNumber = this.gridItemsDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay + this.activityFeedItemsDisp;
        } else if (currentPageValue === lastPage) {
            this.slicedItemsNumber = (lastPage - 1) * this.activityFeedItemsDisp;
            this.gridItemsDisplay = totalLength;
        } else {
            let displayValue = this.activityFeedItemsDisp;
            if ((this.gridItemsDisplay - this.slicedItemsNumber) < this.activityFeedItemsDisp) {
                displayValue = this.gridItemsDisplay - this.slicedItemsNumber;
            }
            this.slicedItemsNumber = this.slicedItemsNumber - this.activityFeedItemsDisp;
            this.gridItemsDisplay = this.gridItemsDisplay - displayValue;
        }
        this.currentPage = currentPageValue;
    }
    
    /**
     * Fetches the item and assigning it to grid
     */
    public itemPerPageChangedHandler(itemCount: string): void {
        this.gridItemsDisplay = Number(itemCount);
        this.activityFeedItemsDisp = this.gridItemsDisplay;
    }
}