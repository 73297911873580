import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EngagementValidationThreshholdComponent } from "./engagement-validation-threshhold.component";
import { ActivateEngagementInitialTemplateComponent } from "./activate-engagement-initial-template.component";
import { ConfigManagerService } from "../../../../../../common/services/configmanager.service";
import { CommonComponentsModule } from "../../../../../../components/entry/common-components.module";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        EngagementValidationThreshholdComponent,
        ActivateEngagementInitialTemplateComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule
    ],
    providers: [
        ConfigManagerService,
        SharedFunctionsService
    ],
    entryComponents: [
        EngagementValidationThreshholdComponent,
        ActivateEngagementInitialTemplateComponent
    ],
    exports: [
        EngagementValidationThreshholdComponent,
        ActivateEngagementInitialTemplateComponent
    ],
})
export class ActivateEngagementTemplatesModule { }
