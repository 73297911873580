import * as fromEngagementResourceDetails from "./engagement-resource-details.action";
import { IResourceDetailsResponse } from "../../common/services/contracts/changerequest.contract";
import { ILoadableState } from "../reducers";

export interface IEngagementResourceDetailsState extends ILoadableState {
    resourceDetails: IResourceDetailsResponse;
}

export const initialState: IEngagementResourceDetailsState = {
    resourceDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromEngagementResourceDetails.EngagementResourceDetailsActions): { [engagementId: string]: IEngagementResourceDetailsState } {
    switch (action.type) {
        case fromEngagementResourceDetails.EngagementResourceDetailsActionTypes.LOAD_ENGAGEMENT_RESOURCE_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }

        case fromEngagementResourceDetails.EngagementResourceDetailsActionTypes.LOAD_ENGAGEMENT_RESOURCE_DETAILS_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                resourceDetails: action.resourceDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromEngagementResourceDetails.EngagementResourceDetailsActionTypes.LOAD_ENGAGEMENT_RESOURCE_DETAILS_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromEngagementResourceDetails.EngagementResourceDetailsActionTypes.INVALIDATE_ENGAGEMENT_RESOURCE_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}