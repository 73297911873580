import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { BulkUploadInternalEngagementService } from "../../../common/services/bulk-upload-internal-engagement.service";
import { IntEngBulkUploadModalComponent } from "./internal-engagement-bulk-upload-modal.component";

@NgModule({
    declarations: [
        IntEngBulkUploadModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService,
        BulkUploadInternalEngagementService
    ],
    entryComponents: [
        IntEngBulkUploadModalComponent
    ],
    exports: [IntEngBulkUploadModalComponent]
})
export class IntEngBulkUploadModalModule { }
