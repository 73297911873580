import { Injectable, Inject } from "@angular/core";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { ICrApproverRuleEngineRequest, ICrApprover, ICrResponse, IChangeRequest, IExistingCrRequest, FvrStatus, ICreateChangeRequest, ICrAttachment, ITphRrDetailsData, ITphRrSummary, IEngagementCfpCostData, AmendmentType, IApprovedFinancialsResponseV2, IProjectApprovedFinancial, IResourceDetailsResponse, ResourceTypeCode } from "./contracts/changerequest.contract";
import { APIConstants, BaseLineType, Services } from "../application.constants";
import { IChangeRequestResponse, IFinancialChangeRequestRoleDetail, IPendingChangeRequest } from "./contracts/changerequestv2.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class ChangeRequestService extends DmServiceAbstract {
    private projectServiceBaseUriv2: string;
    private projectServiceBaseUriv21: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ChangeRequestService);
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Initialize the change request service with the URIs and Subscription Keys
     */
    public initializeConfig(): void {
        this.projectServiceBaseUriv2 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.projectServiceBaseUriv21 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.1";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }

    /**
     * Determines the list of Change Request approver roles based on rule engine and variance request input.
     *
     * @param {ICrApproverRuleEngineRequest} varianceRequest
     * @returns {Promise<ICrApprover[]>}
     * @memberof ProjectServiceV2
     */
    public getCrApproversFromRuleEngine(varianceRequest: ICrApproverRuleEngineRequest): Promise<ICrApprover[]> {
        const url = `${this.projectServiceBaseUriv2}/financials/changerequest/approvers`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetFinancialChangeRequestApproverRoles, varianceRequest);
    }

    /**
     * Create a new financial change request.
     *
     * @param {ICrRequest} data
     * @returns {Promise<ICrResponse>}
     * @memberof ChangeRequestService
     */
    public createFinancialChangeRequestV2(data: ICreateChangeRequest): Promise<ICrResponse> {
        const url = `${this.projectServiceBaseUriv2}/engagement/${data.changeRequestHeader.engagementId}/financials/changerequest`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.CreateFinancialChangeRequest, data);
    }

    /**
     * Updates the status of an existing financial change request.
     *
     * @param {string} crId
     * @param {string} engagementId
     * @returns {Promise<string>}
     * @memberof ChangeRequestService
     */
    public updateFinancialChangeRequestStatus(crId: string, engagementId: string, status: FvrStatus): Promise<string> {
        const url = `${this.projectServiceBaseUriv2}/engagement/${engagementId}/financials/changerequest/${crId}/status`;
        const data = { status };

        return this.dataService.patchData(url, this.subscriptionKey, APIConstants.UpdateFinancialChangeRequestStatus, data);
    }

    /**
     * Gets the approved financial risk reserve data for the given engagement.
     *
     * @param {string} engagementId
     * @returns {Promise<string>}
     * @memberof ChangeRequestService
     */
    public getApprovedFinancialsV2(engagementId: string): Promise<IApprovedFinancialsResponseV2> {
        const url = `${this.projectServiceBaseUriv2}/engagement/${engagementId}/financials/approvedv2`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetApprovedFinancials);
    }

    /**
     * Gets the projects approved financial data for a given financial version. Applicable versions
     * are Contract Baseline (denoted by 1), Delivery Baseline (denoted by 2), and Current Financial
     * Plan (denoted by 3).
     *
     * @param {IProjectApprovedFinancial[]} approvedFinancials
     * @param {string} financialVersion
     * @returns {IProjectApprovedFinancial[]}
     * @memberof ChangeRequestService
     */
    public getProjectsApprovedFinancialsV2ByVersion(approvedFinancials: IProjectApprovedFinancial[], financialVersion: string): IProjectApprovedFinancial[] {
        if (approvedFinancials && approvedFinancials.length) {
            return approvedFinancials.filter((data) => data.version && data.version === financialVersion);
        } else {
            return [];
        }
    }

    /**
     * Get list of amendments by WBS ID. Supports only engagement ID. If no CR Type filter is given this
     * API will return both contractual and non contractual amendments.
     * @param wbsId Wbs ID
     * @param crType Amendment type (Contractual or Non Contractual)
     */
    public getAmendmentsByWbsIdV2(wbsId: string, crType?: AmendmentType): Promise<IChangeRequest[]> {
        let url = `${this.projectServiceBaseUriv21}/engagement/${wbsId}/financials/changerequests`;
        if (crType) {
            url = `${url}?crType=${crType}`;
        }

        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetAmendmentsList);
    }

    /**
     * get request roles of crId
     *
     * @param crId Change RequestID
     */
    public getRolesByCrId(crId: string, includeBillRate: boolean = true): Promise<IExistingCrRequest> {
        return this.dataService.getData(`${this.projectServiceBaseUriv2}/engagement/financials/changerequest/${crId}/roles?includeBillRate=${includeBillRate ? "true" : "false"}`,
            this.subscriptionKey,
            APIConstants.GetCostRateByTaskId
        );
    }


    /**
     * Gets the Change Request details for a given engagement and change request number.
     *
     * @param {string} engagementId
     * @param {string} changeRequestNumber
     * @returns {Promise<IChangeRequestResponse>}
     * @memberof ChangeRequestService
     */
    public getChangeRequestSummaryByEngagementIdV2(engagementId: string, changeRequestNumber: number): Promise<IChangeRequestResponse> {
        const url = `${this.projectServiceBaseUriv2}/engagement/${engagementId}/financials/changerequest/${changeRequestNumber}/summary?includeChangeRequestDetails=true`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetChangeRequestFinancialApi);
    }

    /**
     * Gets FCR attachment link that can be downloaded by user
     *
     * @param {number} crId
     * @param {string} id
     * @returns {string}
     * @memberof ChangeRequestService
     */
    public getFcrAttachmentLink(crId: number, attachmentId: string, engagementId: string): Promise<string> {
        const url = `${this.projectServiceBaseUriv2}/engagement/${engagementId}/financials/changerequest/${crId}/documents?id=${attachmentId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetFCRAttachmentLink);
    }

    /**
     * Upload attachment for the non-contractual change request approval.
     *
     * @param {FormData} input
     * @returns {Promise<ICrAttachment>}
     * @memberof ChangeRequestService
     */
    public uploadFcrAttachment(input: FormData): Promise<ICrAttachment> {
        const url = `${this.projectServiceBaseUriv2}/engagement/financials/attachment`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.UploadFcrFileAttachment, input, true);
    }

    /**
     * Get TPH RR summary data required for new fcr requests.
     *
     * @param {ITphRrDetailsData} tphRrData
     * @returns {ITphRrSummary}
     * @memberof ChangeRequestService
     */
    public getTphRrSummary(tphRrData: ITphRrDetailsData): ITphRrSummary {
        const hoursImpactPercentDecimal = tphRrData.hoursImpact ? (tphRrData.hoursImpact / tphRrData.deliveryBaselineHours) : undefined;
        const cbCostExcludingRr = tphRrData.contractBaselineCost ? tphRrData.contractBaselineCost - tphRrData.totalRiskReserve : undefined;
        const newPlannedCost = tphRrData.costImpact ? (tphRrData.deliveryBaselineCost - tphRrData.totalDeliveryBaselineRiskReserve) + tphRrData.costImpact : undefined;
        const grossCostOverrun = (newPlannedCost - cbCostExcludingRr) < 0 ? 0 : (newPlannedCost - cbCostExcludingRr);
        const grossCostOverrunPercentDecimal = tphRrData.totalRiskReserve ? (grossCostOverrun / tphRrData.totalRiskReserve) : undefined;
        const previouslyApprovedCost = tphRrData.deliveryBaselineCost ? tphRrData.deliveryBaselineCost - tphRrData.totalDeliveryBaselineRiskReserve : undefined;
        const additionalApprovalNeeded = tphRrData.costImpact ? newPlannedCost - previouslyApprovedCost : undefined;
        const tphSummary = {
            ...tphRrData,
            hoursImpactPercentDecimal,
            newPlannedCost,
            cbCostExcludingRr,
            grossCostOverrun,
            grossCostOverrunPercentDecimal,
            previouslyApprovedCost,
            additionalApprovalNeeded
        };
        return tphSummary;
    }

    /**
     * Calculate the existing and revised CFP cost as well as the reconciled labor hours for a given 
     * engagement from change request summary.
     *
     * @param {IFinancialChangeRequestRoleDetail[]} crRoleRequests
     * @returns {IEngagementCfpCostData}
     * @memberof ChangeRequestService
     */
    public getCfpCostDataFromCrSummary(crRoleRequests: IFinancialChangeRequestRoleDetail[]): IEngagementCfpCostData {
        let totalExistingCfpCost: number = 0;
        let totalRevisedCfpCost: number = 0;
        let totalReconciledLaborHours: number = 0;

        for (const role of crRoleRequests) {
            // identify if labor item was an autopopulated reconciliation between CFP and DB
            if (role.resourceType === ResourceTypeCode.Labor && role.requestedQuantity === 0 && (role.quantity !== role.dbHour)) {
                totalReconciledLaborHours += role.quantity - role.dbHour;
            }

            if (role.resourceType === ResourceTypeCode.Labor || role.resourceType === ResourceTypeCode.Unit || (role.resourceType === ResourceTypeCode.SubconFF && role.laborUnits === "H")) {
                if (role.quantity === 0 && role.requestedQuantity < 0 && role.dbHour) {  // db only demand scenario which has no impact on CFP
                    continue;
                } else {
                    // existingCfpCost field needed to be added for labor since SAP does not return existing CFP cost prior to potential blended cost rate change in get FCR API
                    // prior to blended cost rate concept, old CRs did not have this field, so for old CRs fall back to deriving existing cost from cost rate and existing quantity
                    totalExistingCfpCost += role.existingCfpCost ? role.existingCfpCost : (role.costRate * role.quantity);
                    totalRevisedCfpCost += (role.costRate * (role.quantity + role.requestedQuantity));
                }
            }

            if (role.resourceType === ResourceTypeCode.Expense || (role.resourceType === ResourceTypeCode.SubconFF && role.laborUnits === "%")) {
                totalExistingCfpCost += role.planCost;
                totalRevisedCfpCost += (role.planCost + role.requestedCost);
            }
        }

        return { totalExistingCfpCost, totalRevisedCfpCost, totalReconciledLaborHours };
    }

    /**
     * Upload Risk reserve file attachment
     *
     * @param {FormData} input
     * @return {*}  {Promise<any>}
     * @memberof ChangeRequestService
     */
    public uploadRRFileAttachment(input: FormData): Promise<any> {
        const url = `${this.projectServiceBaseUriv2}/wbs/financials/riskReserve/attachment`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.UploadRRFileToBlob, input, true, undefined, [400]);
    }

    /**
     *
     *
     * @param {string} id
     * @return {*}  {Promise<any>}
     * @memberof ChangeRequestService
     */
    public getRRFileAttachment(wbsId: string, fileId: string): Promise<string> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/financials/riskReserve/document?id=${fileId}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetApprovedFinancials);
    }

    /**
     * Get pending change request list for the given engagement ID.
     *
     * @param {string} engagementId
     * @returns {Promise<IPendingChangeRequest[]>}
     * @memberof ChangeRequestService
     */
    public getPendingCrListByEngagementId(engagementId: string): Promise<IPendingChangeRequest[]> {
        const url = `${this.projectServiceBaseUriv21}/engagement/${engagementId}/financials/changerequests/pending`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetPendingChangeRequests);
    }

    /**
     * Retrive Resource type details of given WBS (Engagement/Project) i.e. units, subcon ff, expenses.
     * If provided, will add parameter for plan version (2 for Delivery Baseline, 3 for CFP).
     *
     * @param {string} engagementId
     * @returns {Promise<any[]>}
     * @memberof ChangeRequestService
     */
    public getResourceDetailsByEngagementId(engagementId: string, planVersion?: BaseLineType): Promise<IResourceDetailsResponse> {
        let url = `${this.projectServiceBaseUriv2}/wbs/${engagementId}/resource`;
        if (planVersion) {
            url = `${url}?version=${planVersion.toString()}`;
        }

        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetResourceTypeDetails);
    }
}
