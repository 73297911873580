import { Inject, Input, Component, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BulkCreateIntEngagementStatusModalComponent } from "../../internal-engagement/bulk-create-internal-engagement/bulk-create-int-engagement-status-modal/bulk-create-int-engagement-status-modal.component";
import { IState } from "../../../store/reducers";
import { getBulkIntEngStatusNotificationsByReferenceId } from "../../../store/bulk-internal-engagement-status-notifications/bulk-internal-engagement-status-notifications.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IBulkCreateNotificationState } from "../../../store/bulk-internal-engagement-status-notifications/bulk-internal-engagement-status-notifications.reducer";
import { BulkUploadInternalEngagementService } from "../../../common/services/bulk-upload-internal-engagement.service";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { UserInfoService } from "@fxp/fxpservices";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import moment from "moment";
import { LoadMyPortfolioEngagements } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.action";

@Component({
    selector: "dm-bulk-create-notification",
    templateUrl: "./bulk-create-notification.html",
    styleUrls: ["./bulk-create-notification.scss"]
})
export class BulkCreateNotificationComponent extends DmComponentAbstract {
    @Input() public subscriptionId: string;
    @Input() public entityId: string;
    @Input() public createdOn: string;
    public totalCount: number = 0;
    public processedCount: number = 0;
    public shouldDisplayCloseOption: boolean = true;
    public isNotificationExpanded: boolean = false;
    public timeAgo: string;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(BulkUploadInternalEngagementService) private bulkUploadInternalEngagementService: BulkUploadInternalEngagementService,
        @Inject(Store) private store: Store<IState>,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.BulkCreateNotification);
    }

    public ngOnInit(): void {
        if (this.createdOn) {
            const stillUtc = moment.utc(this.createdOn).toDate();
            this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
        }

        this.notificationService.subscribeToBulkInternalEngagementStatus({ GroupName: this.subscriptionId });

        // Promise for the configuration initializer is resolved over here as it is not done inside bulkUploadInternalEngagementService due to timing issue
        this.configManagerService.initialize().then(() => {
            this.bulkUploadInternalEngagementService.getAllBulkIntEngUploadStatus(this.entityId).then((response) => {
                if (response) {
                    const processsedEngagements = response.engagements.filter((engagement) => engagement.status === "CreationFailed" || engagement.status === "CreationSuccessful");
                    const totaldEngagements = response.engagements.filter((engagement) => engagement.status !== "Discarded");

                    this.totalCount = totaldEngagements.length;
                    this.processedCount = (processsedEngagements) ? processsedEngagements.length : 0;
                    this.shouldDisplayCloseOption = this.totalCount === this.processedCount;
                }
            });
        });

        const notifications$ = this.store.select(getBulkIntEngStatusNotificationsByReferenceId(this.entityId));

        notifications$.pipe(untilDestroyed(this)).subscribe((data: IBulkCreateNotificationState) => {
            if (data) {
                const processsedEngagements = data.Engagements.filter((engagement) => engagement.Status === "CreationFailed" || engagement.Status === "CreationSuccessful");
                const totaldEngagements = data.Engagements.filter((engagement) => engagement.Status !== "Discarded");

                // Once completed we should refresh my portfolio engagements
                if (this.processedCount !== processsedEngagements.length && totaldEngagements.length === processsedEngagements.length) {
                    this.store.dispatch(new LoadMyPortfolioEngagements(false));
                }

                this.totalCount = totaldEngagements.length;
                this.processedCount = (processsedEngagements) ? processsedEngagements.length : 0;
                this.shouldDisplayCloseOption = this.totalCount === this.processedCount;
            }
        });
    }

    public closeNotification(): void {
        this.shouldDisplayCloseOption = false;
        const loggedInUserAlias = this.fxpUserInfoService.getCurrentUserData().alias;
        this.notificationService.deleteNotificationSubscription(loggedInUserAlias, this.subscriptionId);
    }


    /**
     * Opens bulk upload internal engagement upload modal
     *
     * @memberof BulkCreateNotificationComponent
     */
    public openBulkCreateIntEngagementStatusModal(): void {
        this.isNotificationExpanded = true;
        const modalRef: NgbModalRef = this.modalService.open(BulkCreateIntEngagementStatusModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal bulk-create-int-eng-status-popup-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.bulkUploadReferenceId = this.entityId;
        modalRef.componentInstance.onStatusModalClose.pipe(untilDestroyed(this)).subscribe(() => this.isNotificationExpanded = false);
    }
}
