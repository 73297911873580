import { Action } from "@ngrx/store";
import { IExistingCrRequest } from "../../common/services/contracts/changerequest.contract";

// 1. action types
export enum WbsIdRolesActionTypes {
    LOAD_WBS_ID_ROLES = "[WbsIdRoles] Load",
    LOAD_WBS_ID_ROLES_SUCCESS = "[WbsIdRoles] Load Success",
    LOAD_WBS_ID_ROLES_FAIL = "[WbsIdRoles] Load Fail",
    INVALIDATE_WBS_ID_ROLES = "[WbsIdRoles] Invalidate"
}

// 2. action creators
export class LoadWbsIdRoles implements Action {
    public readonly type = WbsIdRolesActionTypes.LOAD_WBS_ID_ROLES;
    public constructor(public engagementId: string, public demands = true) { } // todo: demands default false
}

export class LoadWbsIdRolesSuccess implements Action {
    public readonly type = WbsIdRolesActionTypes.LOAD_WBS_ID_ROLES_SUCCESS;
    public constructor(public engagementId: string, public existingCrRequest: IExistingCrRequest) { }
}

export class LoadWbsIdRolesFail implements Action {
    public readonly type = WbsIdRolesActionTypes.LOAD_WBS_ID_ROLES_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateWbsIdRoles implements Action {
    public readonly type = WbsIdRolesActionTypes.INVALIDATE_WBS_ID_ROLES;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type WbsIdRolesActions =
    | LoadWbsIdRoles
    | LoadWbsIdRolesSuccess
    | LoadWbsIdRolesFail
    | InvalidateWbsIdRoles;