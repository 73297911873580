<dm-tile class="col-md-12 col-sm-12 col-lg-12 dm-p-10" [tileHeaderContent]="tileContent"
    [tileMinHeight]="'calc(100vh - 125px)'">
    <span class="hide-element" id="contacts">Contacts</span>
    <dm-loading loader id="contact-details-loader" [loadingText]="loadingText" [showLoading]="showLoading"
        [isCustomText]="true" *ngIf="isComponentLoading"></dm-loading>
    <a additionalActionsRight class="dm-contact-details__rightLink dm-link" id="backToSummaryLink"
        [uiSref]="isProjectContext ? RouteName?.ProjectSummaryV2 : RouteName?.EngagementSummaryV2">
        <span class="icon icon-chevron-left"></span>
        <span>Back to summary</span>
    </a>
    <ng-container *ngIf="!isComponentLoading && !isServerError">
    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-10 display-flex-imp dm-contact-details__toolbar">
        <button id="addCsatContactBtn" tabindex="0" class="dm-contact-details__action dm-link" aria-label="Add CSAT Contact"
            role="button" [ngClass]="{'hide-element': !hasEditPermissions || !isAddCsatValid}"
            (click)="updateCSATContactModal(getModeEnum.add)">
            <span class="icon icon-AddLegacy"></span>
            <span>Add</span>
        </button> 
        <button id="updateContactLanguageBtn" tabindex="1" class="dm-contact-details__action dm-contact-details__rightLink dm-link" aria-label="Update Contact Language"
        role="button" [ngClass]="{'hide-element': !hasEditPermissions}"
        (click)="updateContactLanguageModal()">
        <span class="icon icon-edit"></span>
        <span>Request Survey Language Change For Contacts</span>
    </button>
    </section>
    <div role="grid" class="dm-contact-details__grid" 
        aria-labelledby="contacts">
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row" class="dm-grid-view-table__row dm-contact-details__row">
                <div role="columnheader" class="dm-grid-view-table__headerCell">Name </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Type of Contact </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Email </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Phone Number</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Language</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell">Action</div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-contact-details__row"
                *ngFor="let csatContact of csatContacts">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{csatContact.contact?.name | dmDisplayDashIfEmptyOrNull}}
                    <span *ngIf="!csatContact.contact?.name" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span class="contact-role">{{csatContact.type?.description}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <div *ngIf="csatContact.contact">
                        <a *ngIf="csatContact.contact.email && csatContact.contact.email !== ''"
                            [title]="csatContact.contact?.email" class="dm-link"
                            [href]="'mailto:' + csatContact.contact?.email">{{csatContact.contact?.email}}</a>
                        <span *ngIf="!csatContact.contact.email || csatContact.contact.email === ''"
                            aria-label="blank">-</span>
                    </div>
                    <span class="sr-only" *ngIf="!(csatContact.contact?.email)">Email not found</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell email-cell">
                    <div *ngIf="csatContact.contact">
                        <a class="dm-link" *ngIf="csatContact.contact.telephone && csatContact.contact.telephone !== ''"
                            [title]="csatContact.contact?.telephone"
                            [href]="'tel:' + csatContact.contact?.telephone">{{csatContact.contact?.telephone}}</a>
                        <span *ngIf="!csatContact.contact.telephone || csatContact.contact.telephone === ''"
                            aria-label="blank">-</span>
                    </div>
                    <span class="sr-only" *ngIf="!(csatContact.contact?.telephone)">Phone number not found</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span class="contact-role">{{csatContact.contact?.language?.languageDescription}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell"
                    [attr.aria-label]="hasEditPermissions ? '' : 'blank'">
                    <button tabindex="0" class="dm-contact-details__action dm-link" role="button"
                        aria-label="Update CSAT Contact. Press tab to navigate to delete button" [ngClass]="{'hide-element': !hasEditPermissions}"
                        (click)="updateCSATContactModal(getModeEnum.edit, csatContact)">
                        <span class="icon icon-edit"></span>
                        <span> Update</span>
                    </button>
                    <button  *ngIf="isDeleteCSATFeatureEnabled" tabindex="0" class="dm-contact-details__action dm-link" role="button"
                        aria-label="Delete CSAT Contact" [ngClass]="{'hide-element': !hasEditPermissions}"
                        (click)="deleteCsatContact(csatContact)">
                        <span class="icon icon-delete"></span>
                        <span> Delete</span>
                    </button>
                    <span class="sr-only" *ngIf="!hasEditPermissions">No Actions</span>
                </div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-contact-details__row"
                *ngFor="let contact of contactsWithNonCsatRoles">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    {{contact.name | dmDisplayDashIfEmptyOrNull}}
                    <span *ngIf="!contact.name" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <ng-container *ngFor="let role of contact.roles">
                        <span class="contact-role">{{role?.contactType}}</span> <br />
                    </ng-container>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <a *ngIf="contact.email && contact.email !== ''" [title]="contact?.email" class="dm-link"
                        [href]="'mailto:' + contact?.email">{{contact.email}}</a>
                    <span *ngIf="!contact.email || contact.email === ''" aria-label="blank">-</span>
                    <span class="sr-only" *ngIf="!contact?.email">Email not found</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell email-cell">
                    <a *ngIf="contact.telephone && contact.telephone !== ''" [title]="contact?.telephone"
                        class="dm-link" [href]="'tel:' + contact?.telephone">{{contact.telephone}}</a>
                    <span *ngIf="!contact.telephone || contact.telephone === ''">-</span>
                    <span class="sr-only" *ngIf="!contact?.telephone">Phone number not found</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <span class="contact-role">{{contact?.language?.languageDescription}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-link" aria-label="blank">
                    <span class="sr-only">No Actions</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>
