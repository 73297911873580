export namespace DmError {

    /**
     * Internal Engagement Error Messages
     */
    export namespace InternalEngagement {
        export const ErrorsInResourceRequest: string = "SAP Project has been successfully created but there were errors in submitting Resource Request in GRM. System will try to re-process the request after some time.";
        export const MinimumCharacterLength: string = "Enter minimum 3 characters.";
        export const EngagementNameMaximumCharacterLength: string = "Engagement Name cannot be more than 40 characters.";
        export const EngagementNameIsRequired: string = "Engagement Name is Required.";
        export const EngagementStartDateIsRequired: string = "Engagement Start Date is required.";
        export const EngagementEndDateIsRequired: string = "Engagement End Date is required.";
        export const EngagementDescriptionMaximumCharacterLength: string = "Engagement description cannot be more than 255 characters.";
        export const EngagementTypeIsRequired: string = "Engagement Type is required.";
        export const EngagementDomainIsRequired: string = "Engagement Domain is required.";
        export const EngagementApprovalIdIsRequired: string = "Engagement Approval Id is Required.";
        export const CustomerNameIsRequired: string = "Customer Name is required.";
        export const InternalEngagementISDUserMessage: string = "ISD Users - Please use <a href='https://aka.ms/InternalNBUERequest' target='_blank'> https://aka.ms/InternalNBUERequest</a> to request for internal delivery NBUE or unit based engagement creation. Presales ECIF and SR Out can be created directly here.";
        export const SelectCountry: string = "Select Country";
        export const SelectState: string = "Select State";
        export const SelectCity: string = "Select City";
        export const CompanyCodeIsRequired: string = "Company code is required.";
        export const PrimaryProjectManagerIsRequired: string = "Primary project manager is required.";
        export const UploadedPPJMIsInvalid: string = "Uploaded PPJM is invalid.";
        export const UploadedAdditionalPPJMsIsInvalid: string = "One or more uploaded additional PPJMs is invalid.";
        export const EndDateCannotBeLessThanStartDate: string = "end Date cannot be less than start date";
        export const FailedToRetrieveCountriesList: string = "Failed to retrieve countries list from One Profile Service.";
        export const ErrorOccuredInGettingStatesData: string = "Error occured in getting states data";
        export const CustomerRequired: string = "Customer required, select a customer.";
        export const TPIDRequired: string = "TPID required, select a customer with a TPID";
        export const CostCenterOrInternalOrderMustBeInTheCompanyCode: string = "Cost Center/ Internal Order should be in the Company Code of an Engagement.";
        export const InternalOrderMustBeInTheCompanyCode: string = "Cost Center/ Internal Order should be in the Company Code of an Engagement.";
        export const ProjectNameMaximumCharacterLength: string = "Project Name cannot be more than 40 characters.";
        export const ProjectNameIsRequired: string = "Project Name is required.";
        export const ProjectStartDateIsRequired: string = "Project Start Date is required.";
        export const ProjectEndDateIsRequired: string = "Project End Date is required.";
        export const ProjectDomainIsRequired: string = "Solution area is required.";
        export const ProjectDescriptionMaximumCharacterLength: string = "Project description cannot be more than 255 characters.";
        export const EnterValidNBUEHours: string = "Enter Valid NBUE Hours";
        export const UploadOnlyOutlookMessageFileOrPdfFile: string = "Upload only Outlook message file/Pdf file";
        export const EnterRequiredSROutCostCenterOrInternalOrderValue: string = "Enter required SR Out Cost Center or Internal Order value.";
        export const EnterRequiredInternalOrderValue: string = "Enter required Internal Order value.";
        export const EnterRequiredSRCrossChargeValue: string = "Enter required SR cross charge value.";
        export const EnterRequiredECIFAmountValue: string = "Enter the ECIF amount in USD.";
        export const CompanyCodeIsRequiredForValidation: string = "Company Code is required for Cost Center/ Internal Order validation.";
        export const CompanyCodeIsRequiredForInternalOrderValidation: string = "Company Code is required for Internal Order validation.";
        export const EnterAValidCostCenterOrInternalOrder: string = "Enter a valid Cost Center/ Internal Order.";
        export const EnterAValidInternalOrder: string = "Enter a valid Internal Order.";
        export const ErrorWhileValidatingCostCenterOrInternalOrder: string = "Error while validating Cost Center/Internal Order.";
        export const ErrorWhileValidatingInternalOrder: string = "Error while validating Internal Order.";
        export const StartDateCannotBeGreaterThanEndDate: string = "Start Date cannot be greater than End Date";
        export const ProjectStartDateCannotBeEarlierThanEngagement: string = "Project Start Date cannot be earlier than Engagement Start Date.";
        export const ProjectEndDateCannotBeGreaterThanEngagement: string = "Project End Date cannot be greater than Engagement End Date";
        export const OneOrMoreSkillsAreInvalid: string = "One or more skills are invalid";
        export const ProvidedResourceAliasIsNotAnAreaAccountAlignedResource: string = "Provided Resource alias is not an Area Account aligned Resource. Please provide a valid area account aligned resource.";
        export const RequestorMustBeInTheApprovedSecurityGroups: string = "You are not part of a security group. Requestor must be part of one of the approved security groups to free resource calendar.";
        export const WWTKTrainingDateForResourceHasExpired: string = "WWTK Training date for resource has expired.";
        export const FileUpload: string = "Error occured in file upload. Please try again.";
        export const MismatchOfUploadedFile: string = "There is a mismatch in the schema of the uploaded file. Please re-upload the data with latest template.";
        export namespace AddRole {
            export const StartDateIsRequired: string = "Start date is required";
            export const RoleStartDateCannotBeAfterOrLaterThanRoleEndDate: string = "Role start date cannot be after or later than Role end date";
            export const EndDateIsRequired: string = "End date is required";
            export const RoleEndDateCannotBeEarlierThanRoleStartDate: string = "Role end date cannot be earlier than role start date";
            export const RequestedLaborIsRequired: string = "Requested Labor is required";
            export const DomainAlignedCannotBeAssignedToAccountAlignedRoles: string = "Domain Aligned resources can not be assigned to Account Aligned Roles";
            export const FailedToRetrieveTrainingDetails: string = "Failed to retrieve training details of requested user.";
            export const ProvideAResourceNameThatIsToBeAutoCommitted: string = "You have not provided a resource name for a role that is to be Auto Committed. Please edit your request and add the named resource.";
            export const ProvideAResourceNameThatResourceScheduleFree: string = "You have not provided a resource name for a role that has Resource schedule free. Please edit your request and add the named resource.";
            export const ProvideAResourceNameSROutFederalSupport: string = "You have not provided a resource name for SR-Out Federal Support Engagement. Please edit your request and add the named resource.";
            export const AnErrorOccurredWhileCreatingTheGRMProject: string = "An error occurred while creating the GRM Project. We'll try to re-process this again.";
            export const ProjectdoesnotExistInResourceManagement: string = "Project does not exist in Resource Management.";
            export const NoProjectsFoundInResourceManagement: string = "No projects found in Resource Management for this engagement.";
            export const AnErrorOccurredWhileAddingResourceRequest: string = "An error occurred while adding resource request in SAP.";
            export const ErrorOccuredWhileReadingDemandIdForRoleId: string = "Error occured while reading DemandId for role id";
            export const FailedToRetrieveUsersSkills: string = "Failed to retrieve users skills one profile Service with grace period  -";
            export const FailedToRetrieveFinancialRoles: string = "Failed to retrieve financial roles from financial role Service.";
            export const RequestedHoursExceedsApprovedNBUEHours: string = "Requested hours exceeds Approved NBUE Hours -";
            export const StartDateShouldBeLaterThanTheProjectStartDate: string = "Start Date should be later than the Project Start Date:";
            export const EndDateShouldBeEarlierThanTheProjectEndDate: string = "End Date should be earlier than the Project End Date:";
            export const SelectedResourceCannotBeAssigned: string = "Selected resource cannot be assigned as WWTK training has expired";
        }
    }
    /**
     * Manage Links Error Messages
     */
    export namespace UserPreferenceLinks {
        export const TheEntryHasAnInvalidURLOrName: string = "The entry has an invalid URL or Name.";
        export const OneOfTheEntriesHasAnInvalidURLOrName: string = "One of the entries has an invalid URL or Name.";
        export const UnableToSaveLinks: string = "Unable to save links";
        export const EnterTheTitle: string = "Enter the title";
        export const EnterValidUrl: string = "Enter Valid Url";
        export const UrlMustBeginWithHttpOrHttps: string = "Url must begin with 'http://' or 'https://'";
    }

    /**
     * EBS State Change Error Messages
     */
    export namespace EbsRequestChange {
        export const ErrorOccuredInGettingRequestedEBSState: string = "Error occured in getting requested EBS state";
        export const ThereArePendingLaborOrExpenses: string = "There are Pending Labor/Expenses associated with this engagement. Please close them and try again.";
        export const UnableToRetrievePendingLaborOrExpensesDetails: string = "Unable to retrieve Pending Labor/Expenses details, Please try later";
        export const SelectedProjectDoesNotHavePJMDetails: string = "Selected project does not have PJM details to retrieve Pending Labor/Expenses";
        export const ThereArePendingLaborOrExpensesAssociatedWithProject: string = "There are Pending Labor/Expenses associated with this project. Please close them and try again.";
        export const ErrorOccuredInGettingProgressRequest: string = "Error occured in getting requested WBS status change in progress requests";
        export const ErrorOccuredInGettingPJCMasterData: string = "Error occured while getting PJC master data.";
    }

    /**
     * EBS Structure and Edit EBS Error Messages
     */
    export namespace EbsStructure {
        export const EndDateCannotBeEarlierThanStartDate: string = "End date cannot be earlier than Start date.";
        export const DescriptionRequired: string = "Description is required";
        export const ProjectNameIsRequired: string = "Project Name is required.";
        export const MinimumCharacterLength: string = "Enter minimum 3 characters";
        export const StartDateRequired: string = "Start date is required.";
        export const EndDateIsRequired: string = "End date is required.";
        export const NBUEHoursAreRequired: string = "NBUE Hours are required.";
        export const ProjectStartDateMustBeSameOrLaterThanEngagement: string = "Project Start date must be the same as or later than the Engagement Start date";
        export const ProjectEndDateMustBeSameOrEarlierThanEngagement: string = "Project End date must be the same as or earlier than the Engagement End date";
        export const CannotChangeTheDateDueToResourcingConflict: string = "Cannot change the date due to resourcing conflict";
        export const ProjectEndDateSameOrLaterThanActualsPostedDate: string = "Project End date must be the same as or later than the Actuals posted date";
        export const ProjectStartDateSameOrEarlierThanActualsDate: string = "Project start date must be the same as or earlier than the Actuals posted date";
        export const ProjectEndDateCannotBeEarlierOrSameAsService: string = "Project End date cannot be earlier than or the same as the Service End date";
        export const EitherApplyOrManuallyChangeTheDateAtServiceLevel: string = "Either apply the date changes to all options or manually change the date at the Service level";
        export const ProjectStartDateMustSameOrEarlierThanService: string = "Project Start date must the same as or earlier than the Service Start date";
        export const EngagementNameRequired: string = "Engagement Name is required.";
        export const EngagementStartDateMustBeEarlierOrSameAsProject: string = "Engagement Start date must be earlier than or the same as the Project Start date";
        export const EitherApplyOrManuallyChangeDateAtProjectlevel: string = "Either apply the date changes to all options or manually change the date at the Project level.";
        export const EngagementEndDateCannotBeEarlierOrSameAsProject: string = "Engagement End date cannot be earlier than or the same as the Project End date";
        export const ProjectStartDateMustBeEalierOrSameAsActualDate: string = "Project Start Date must be ealier than or same as the Actual Posted Date";
        export const ProjectEndDateCannotBeLaterThanIOEndDate: string = "Project end date cannot be later than IO end date";
        export const OnlyFteCanFill: string = "Only FTEs can fill this role.";

        export namespace EditEbsStructure {
            export const TaskNameRequired: string = "Task Name is required";
            export const DescriptionIsRequired: string = "Description is required";
            export const StartDateIsRequired: string = "Start date is required.";
            export const TaskStartDateShouldBeWithinServiceStartDate: string = "Task start date should be within Service start date.";
            export const EndDateRequired: string = "End date is required.";
            export const EndDateCannotBeEarlierThanTheStartDate: string = "End date cannot be earlier than the start date.";
            export const TaskEndDateMustBeBeforeTheServiceEndDate: string = "Task end date must be before the Service end date.";
            export const TaskStartDateMustBeAfterTheServiceStartDate: string = "Task start date should be after the Service start date.";
            export const TaskEndDateMustBeAfterTheServiceEndDate: string = "Task end date must be after the Service end date.";
            export const ServiceStartDateCannotBeEarlier: string = "Service start date cannot be earlier than or the same as the Project start date";
            export const ServiceStartDateEarlierthanTaskStartDate: string = "Service start date should be earlier than or the same as the Task start date";
            export const ServiceEndDateEarlierthanProjectEndDate: string = "Service end date should be earlier than or the same as the Project end date";
            export const ServiceEndDateEarlierthanTaskEndDate: string = "Service end date cannot be earlier than or the same as the Task end date";
            export const ServiceEndDateCannotBeGreaterIoEndDate: string = "Service end date cannot be greater io end date";
            export const CannotChangeDateDueToResourcingConflict: string = "Cannot change date due to resourcing conflict";
            export const ServiceStartDateEalierOrSameAsActualPosted: string = "Service Start Date must be ealier than or same as the Actual Posted Date";
            export const ServiceEndDateCannotBeLaterThanActualsEndDate: string = "Service end date cannot be later than actuals end date";
            export const TaskStartDateCannotBeEarlierOrSameAsService: string = "Task start date cannot be earlier than or the same as the Service start date";
            export const TaskStartDateMustBeEalierOrSameAsActualPosted: string = "Task Start Date must be ealier than or same as the Actual Posted Date";
            export const TaskEndDateMustBeEarlierOrSameAsService: string = "Task end date should be earlier than or the same as the Service end date";
            export const TaskEndDateCannotBeLaterThanActualsPosted: string = "Task end date cannot be later than actuals posted date";
            export const EitherUseCascadeUpdateOrUpdateAtTask: string = "Either use Cascade update or update at the task level.";
            export const NameMaxLength: string = "/40 characters remaining";
            export const DescriptionMaxLength: string = "/255 characters remaining";
            export const EngagementNameIsRequired: string = "Engagement Name is Required.";
            export const NameMinimumCharacterLength: string = "Enter minimum 3 characters";
            export const TaskDescriptionMaxLength: string = "/250 characters remaining";
        }
        export namespace EditServiceProductFulfillmentDates {
            export const ServicePlannedStartDateIsRequired: string = "Planned Start Date is required.";
            export const ServicePlannedStartDateMustBeFutureDate: string = "Planned Start Date should be a current or a future date.";
            export const ServiceActualStartDateMustBePastDate: string = "Actual Start Date should be a current or past date.";
            export const ServiceActualStartDateCannotBeEarlierThanEngagement: string = "Actual Start Date cannot be earlier than Engagement Start Date.";
        }
    }

    /**
     * Staffing Error Messages
     */
    export namespace Staffing {
        export const AssignmentStartDatelessThanEndDate: string = "Assignment Start date should be less than end date";
        export const AssignmentDataMissing: string = "Assignment data missing";
        export const ResourceManagementBusinessDaysToStaff: string = "Resource management needs 10 business days to staff a request. Your request start date has been adjusted to align with this timing. Please review the request start and end dates prior to submitting.";
        export const MultipleDemandsHasBeenSelected: string = "Multiple Demands has been selected";
        export const MultipleResourcesHasBeenSelected: string = "Multiple Resources has been selected";
        export const SplitInformation: string = "New row added to the table to split role further";
        export const ReasonRequired: string = "Reason is required";
        export const CommentsAreRequired: string = "Comments are required";
        export const ErrorWhileSuspending: string = "Error while suspending";
        export const ErrorWhileUnsuspending: string = "Error while unsuspending";
        export const SuspendActionIsRequired: string = "Suspend Action is required";
        export const SuspendReasonIsRequired: string = "Suspend Reason is required";
        export const ActionisRequired: string = "Action is required";
        export const RequestedHoursCannotBeZero: string = "Requested Hours cannot be zero";
        export const FundedHoursRemaining: string = "Based on funded hours remaining, you can request upto";
        export const CommitOperationOnMultipleAssignments: string = "Commit operation can't be performed on multiple assignments. Please select a single assignment";
        export const UnableToRetrieveCommittedHours: string = "Unable to retrieve committed hours for validation";
        export const UnableToRetrieveFundedHours: string = "Unable to retrieve funded hours for validation";
        export const ErrorWhileSubmittingRequest: string = "Error while submitting request";
        export const RoleMaintenancePerformanceMessage: string = "Role maintenance cannot be performed because one of the selected roles is an auto-commit role.";
        export const ResourceRequestIsNotInAStateForRoleMaintenance: string = "This Resource Request is Not in a state for Role Maintenance";
        export const ErrorWhileCancelling: string = "Error while cancelling";
        export const TruncateOperationsPerformanceMessaage: string = "Truncate operations can't be performed on multiple assignments. Please select a single assignment";
        export const NotAbleToLoadTruncateDetails: string = "Sorry! Not able to load truncate details";
        export const AssignmentDataIsIncomplete: string = "Assignment data missing or assignment data is incomplete";
        export const RequestDateIsRequired: string = "Request end date and schedule end date is required.";
        export const ScheduleStartDateRequired: string = "Schedule start date is required.";
        export const ResourceHasLoggedHoursInChronos: string = "Resource has logged hours in Chronos and can only be truncated from ";
        export const ScheduleEndDateAndCommentRequired: string = "Scheduled End Date & Comment is required";
        export const ScheduleEndDateRequired: string = "Scheduled End Date is required";
        export const ErrorWhileTruncating: string = "Error while truncating";
        export const RejectionReasonRequired: string = "Rejection Reason is required";
        export const NoDraftResourceExists: string = "No draft resource requests exist for the selected Project";
        export const StartDateIsRequired: string = "Start date is required.";
        export const EndDateRequired: string = "End date is required.";
        export const EndDateCannotBeEarlierThanTheStartDate: string = "End date cannot be earlier than the start date.";
        export const RequestHoursExceedPlannedHoursOfDemand: string = "Requested hours cannot exceed planned hours of the demand.";
        export const ResourceRequestRequired: string = "Resource Request is required.";
        export const InvalidRequestedHoursEntered: string = "Invalid Requested Hours entered.";
        export const DeliveryLocationRequired: string = "Delivery Location is required.";
        export const RequestedResourceRequired: string = "Please provide valid requested resource name.";
        export const RequestedResourcesMustBeOfSameProject: string = "Please select resource requests of same project for Bulk Maintenance.";
        export const InvalidResourceRequestState: string = "Selected resource requests are not valid for Bulk Maintenance.";
        export const SendEmailNotificationAboutProjectsSuccess: string = "Successfully triggered email notification.";
        export const SendEmailNotificationAboutProjectsFailure: string = "Failed to triggered email notification. Please try later.";
    }

    export namespace CreateCsatContact {
        export const ContactFirstNameRequired: string = "Contact First Name is required.";
        export const ContactLastNameRequired: string = "Contact Last Name is required.";
        export const ContactEmailRequired: string = "Contact Email is required.";
        export const EmailIsInvalid: string = "Email is invalid.";
        export const EnterValidPhoneNumber: string = "Please enter Phone Number.";
        export const EnterValidExtension: string = "Please, Enter valid Extension.";
    }

    export namespace Snapshot {
        export const SnapshotNameRequired: string = "Snapshot Name is required.";
    }

    export namespace InlineSurvey {
        export const SelectRatingBeforeSubmitting: string = "Please select rating before submitting.";
        export const EnterFeedbackBeforeSubmitting: string = "Please enter feedback before submitting.";
        export const Max1000CharactersAllowed: string = "Max 1000 characters allowed.";
        export const EnterValidEmail: string = "Please enter a valid email address.";
    }

    export namespace FinancialChangeRequest {
        export const TitleIsRequired: string = "Title is required";
        export const ReasonIsRequired: string = "Reason is required";
        export const ExecutiveSummaryRequired: string = "Executive Summary is required";
        export const BusinessJustificationRequired: string = "Business Justification is required";
        export const ThereAreNoApproversInVirtuoso: string = "There are no approvers in Virtuoso for this engagement.";
        export const ApproverNotPartOfTeam: string = "The following required approvers are not part of Virtuoso project team: ";
        export const PleaseAddUserInVirtuoso: string = "Please add user to project team in Virtuoso and refresh approvers.";
        export const PleaseAddAndRefreshApproversForRolesInVirtuoso: string = "Please add and refresh approvers for these roles in Virtuoso.";
        export const PleaseEnsureEngagementDataIsCapturedInVirtuoso: string = "There are no approvers in Virtuoso for this engagement. Please ensure engagement data is captured in Virtuoso and add approvers for this engagement in Virtuoso to submit a change request. ";
        export const NoApprovalStatusFoundInVirtuoso: string = "No approval status found in Virtuoso.";
        export const ErrorRetrievingApprovalStatusFromVirtuoso: string = "Error retrieving approval status from Virtuoso.";
        export const InvalidCostErrorMessage: string = "Additional cost cannot be more than 999999999.99";
        export const AdditionalTimeErrorMessage: string = "Sum of Existing and Additional hours must be greater than or equal to 0.";
        export const AdditionalHoursQuantityAllowed: string = "Hours can't be more than 999999999";
        export const AdditionalHoursCantBeLessThanStaffedHours: string = "Sum of Existing and Additional hours can't be less than Staffed hours";
        export const AdditionalUnitsQuantityAllowed: string = "Units can't be more than 999999999";
        export const AdditionalUnitsCantBeLessThanActuals: string = "Sum of Existing and Additional units can't be less than Actuals posted";
        export const PlannedCostCantBeLessThanPOPosted: string = "Planned cost cannot be less than total PO posted.";
        export const CannotRetrieveCostRate: string = "Cannot Retrieve Cost Rate.";
        export const RolesNotSavedErrorMessage: string = "All roles must be saved before submitting the Change Request.";
        export const ExecutiveSummaryMaxLength: string = "Executive Summary can not exceed 250 characters.";
        export const ExecutiveSummaryTotalCharactersRemaining: string = "/250 characters remaining";
        export const BusinessJustificationMaxLength: string = "Business Justification can not exceed 255 characters.";
        export const BusinessJustificationTotalCharactersRemaining: string = "/255 characters remaining";
        export const CommentsMaxLength: string = "Comments can not exceed 255 characters.";
        export const CommentsTotalCharactersRemaining: string = "/255 characters remaining";
        export const DemandDataCorrupted: string = "One or more demands in CFP contain corrupted data. Please raise a support ticket and request the data to be corrected before raising a Financial Change Request.";
        export const MissingPpjm: string = "The engagement team structure contains corrupted data. The Primary Project Manager is missing and is a required team member and approver. Please create a support ticket to have SAP team address the data issue in order to create a Financial Change Request.";
        export const PendingCrInProject: string = "There is a pending Change Request against this Project. Please select a different Project to continue.";
    }

    export namespace AdvancedSearch {
        export const MinimumCharacterLength: string = "Enter at least 3 characters";
        export const EnterAProjectName: string = "Enter a Project Name, valid Project ID, or valid Engagement ID";
        export const ProjectNamesMaximumCharacterLength: string = "Project names have a maximum of 40 characters, please shorten your input by";
        export const EngagementNamesMaximumCharacterLength: string = "Engagement names have a maximum of 38 characters, please shorten your input";
        export const EnterValidProjectID: string = "Enter valid Project ID or Engagement ID";
        export const CustomerNamesMaximumCharacterLength: string = "Customer names have a maximum of 23 characters, please shorten your input by";
    }

    export namespace KeyIndicator {
        export const NoUnapprovedlabor: string = "No unapproved labor for given assignment ID";
    }

    export namespace KeyLinks {
        export const NoNBUEDocuments: string = "No NBUE documents were found for this engagement.";
        export const ProblemFetchingNBUEdocuments: string = "There was a problem fetching NBUE documents. Please refresh the page to try again.";
    }

    export namespace NpcComponent {
        export const consumedQuantityGreaterThanPlannedQuantity: string = "Consumed Quantity cannot be greater than planned quantity.";
    }

    export namespace AmendmentDetailsComponent {
        export const FailureInAmendment: string = "There are some failures in amendment, please correct change request details. You can add, remove, or recreate with different demand Id.";
    }

    /**
     * Text constants to display when server errors occurs
     */
    export namespace ServerErrorMessages {
        export const UnableToRetrive: string = "Unable to retrieve # Details";
        export const EngagementDetails: string = UnableToRetrive.replace("#", "Engagement");
        export const ProjectDetails: string = UnableToRetrive.replace("#", "Project");
        export const SummaryFinancials: string = UnableToRetrive.replace("#", "Financials");
        export const CustomerInvoices: string = UnableToRetrive.replace("#", "Invoices");
        export const MilestoneDetails: string = UnableToRetrive.replace("#", "Milestone");
        export const Units: string = UnableToRetrive.replace("#", "Units");
        export const Npc: string = UnableToRetrive.replace("#", "Non-procured Materials");
        export const ManageSuppliers: string = UnableToRetrive.replace("#", "Supplier Purchase");
        export const WbsProject: string = UnableToRetrive.replace("#", "Engagement Breakdown Structure");
        export const StaffingDetails: string = UnableToRetrive.replace("#", "Staffing");
        export const FinancialSummary: string = UnableToRetrive.replace("#", "Engagement");
        export const FcrDetails: string = UnableToRetrive.replace("#", "Financial Summary");
        export const FinancialPlan: string = UnableToRetrive.replace("#", "Financial Plans");
        export const KeyIndicators: string = UnableToRetrive.replace("#", " Key Indicators");
        export const KeyActions: string = UnableToRetrive.replace("#", "Key Actions");
        export const Services: string = UnableToRetrive.replace("#", "Services");
        export const ManageInternalEngagement: string = UnableToRetrive.replace("#", "Internal Engagement");
        export const InternalEngagementStructure: string = UnableToRetrive.replace("#", "Internal Engagement Breakdown Structure");
        export const CustomerDelivery: string = UnableToRetrive.replace("#", "Customer Delivery Engagement");
        export const CostConsumption: string = UnableToRetrive.replace("#", "Cost Consumption");
        export const LaborConsumption: string = UnableToRetrive.replace("#", "Labor Consumption");
        export const RecentlyViewed: string = UnableToRetrive.replace("#", "Recently Viewed");
        export const Contact: string = UnableToRetrive.replace("#", "Contact");
        export const PortfolioStatus: string = UnableToRetrive.replace("#", "Portfolio Status");
        export const FinancialForecast: string = UnableToRetrive.replace("#", "Financial Forecast");
        export const Snapshots: string = UnableToRetrive.replace("#", "Snapshots");
        export const Amendments: string = UnableToRetrive.replace("#", "Amendments");
    }

    export namespace SyncEACToPlanMessages {
        export const SyncEACToPlanSubmitSuccess: string = "Sync EAC to plan request has been submitted successfully!. Please check the notification bar below to track this request.";
        export const SyncEACToPlanSubmitFailure: string = "An error has occured while submitting Sync EAC to plan request. Please try again.";
        export const GetSyncEACToPlanDemandDataFailure: string = "An error has occured while getting demand data needed for FCR creation. Please load the dialog again.";
        export const GetSyncEACData: string = "An error has occured while loading data needed for dialog. Please load the dialog again.";
        export const SendUserActionDataFailure = "An error has ocurred while sending user response to orchestration. Please try again.";
    }
    export namespace MilestoneDateErrorMessages {
        export const MilestonePlannedCompletionDateCantBeLessThanContractSignatureDate: string = "Planned completion date cannot be less than contract signature date.";
        export const MilestoneActualCompletionDateCantBeLessThanContractSignatureDate: string = "Actual completion date cannot be less than contract signature date.";
        export const MilestoneActualCompletionDateCantBeGreaterThanCurrentDate: string = "Actual completion date cannot be greater than present date.";
    }

    export namespace HttpConstants {
        export const BAD_REQUEST: number = 400;
        export const UNAUTHORIZED: number = 401;
        export const FORBIDDEN: number = 403;
        export const NOT_FOUND: number = 404;
        export const NOT_ACCEPTABLE: number = 406;
        export const INTERNAL_SERVER_ERROR: number = 500;
    }

}
