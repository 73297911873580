import { Component, Inject, Input } from "@angular/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { RoleFullName } from "../../../common/application.constants";
import { IPersonDetails } from "../../manage-ebs-v2/tiles/entity-team-structure/entity-team-structure.component";

@Component({
    selector: "dm-person-card",
    templateUrl: "./person-card.html",
    styleUrls: ["./person-card.scss"]
})

export class PersonCardComponent {
    @Input() public personDetails: IPersonDetails;
    @Input() public personImage: string;
    @Input() public canChat: boolean = true;
    @Input() public avatarOnly: boolean = false;
    @Input() public nameOnly: boolean = false;
    @Input() public personCardLabel: string;
    public personCardId: string;
    public roleFullName = RoleFullName;

    public constructor(
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) { }

    public ngOnInit(): void {
        const cardTriggerType = this.nameOnly ? "name" : "avatar";
        if (this.personDetails) {
            if (this.personCardLabel) {
                this.personCardId = this.personCardLabel + "-" + this.personDetails.jobTitle + "-" + this.personDetails.alias + "-" + cardTriggerType;
            } else {
                this.personCardId = this.personDetails.jobTitle + "-" + this.personDetails.alias + "-" + cardTriggerType;
            }
        }
    }

    /**
     * closes the person card when hover out of card or the avatar triggering it
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public closePersonCardDropdown(event: any, personCardDropdown: NgbDropdown): void {
        if (event && event.toElement) {
            const elementId = event.toElement.id;
            if (elementId && elementId.indexOf("person-card-dropdown") > -1) {
                personCardDropdown.open();
            } else {
                personCardDropdown.close();
            }
        }
    }

    /**
     * Move focus inside person card accessible elements
     */
    public moveFocus(id: string): void {
        const dropdownId = id + this.personCardId;
        this.sharedFunctionsService.focus(dropdownId, true);
    }
}

