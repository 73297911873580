
import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, forwardRef, Inject, AfterContentChecked } from "@angular/core";
import { ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { Components, LogEventConstants, NotificationEvent, SourceConstants, ComponentFailureMessages, GoodsReceiptValidationMessages, BusinessTask } from "../../common/application.constants";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { DMNotificationService, NotificationModel } from "../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { getEntireManageSuppliers } from "../../store/manage-suppliers/manage-suppliers.selector";
import { IChangedProperties } from "../../common/services/contracts/dmnotification.service.contract";
import { IContractType } from "../../common/services/contracts/project.service.contracts";
import { IEngagementDetailsApiV2, IProjectDetailsApiV2, ITeamDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { INotificationMessages, IOnSave } from "../../common/services/contracts/financial.service.contracts";
import { ILineItem, IPurchaseOrder, IWbsElement } from "../../common/services/contracts/po.service.contracts";
import { IManageSuppliersState } from "../../store/manage-suppliers/manage-suppliers.reducer";
import { IPoLineitem, IPoList, IPOListWbsElement } from "./manage-suppliers-table-data/lineitems.contract";
import { IPurchaseOrder as ISavePurchaseOrder, ISaveGR, ISaveGRResponse } from "../../common/services/contracts/save-gr.service.contracts";
import { IState } from "../../store/reducers";
import { PurchaseOrderService } from "../../common/services/po.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { DataService } from "../../common/services/data.service";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../common/error.constants";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";

/**
 * ManageSuppliersComponent
 *
 * @export
 * @class ManageSuppliersComponent
 */
@Component({
    selector: "dm-manage-suppliers",
    templateUrl: "./manage-suppliers.html",
    styleUrls: ["./manage-suppliers.scss"]
})
export class ManageSuppliersComponent extends DmComponentAbstract implements AfterContentChecked {
    public isFilterCheckEnable: boolean = true;
    public purchaseOrderList: IPurchaseOrder[];
    public purchaseOrdersTableData: IPoList[];
    public selectedPo: string = "";
    public isUpdatingLineItems: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.ManageSuppliers;
    private FXP_CONSTANTS = FxpConstants;
    private changedProperties: IChangedProperties[] = [];
    private contractType: IContractType[] = [];
    private engagementDetails$: any;
    private engagementDetails: IEngagementDetailsApiV2;
    private engagementId: string;
    private failureMessages: IOnSave;
    private haveMountedComponents: boolean = false;
    private isProjectContext: boolean;
    private notificationMessage: INotificationMessages;
    private userBpId: string;
    private projectId: string;
    private successMessages: IOnSave;
    private graceAmountForPoValidation: number;

    public constructor(
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(PurchaseOrderService) private purchaseOrderService: PurchaseOrderService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Components.ManageSuppliers, [{ component: Components.ManageSuppliersFilter, isCritical: true }]);
    }

    public ngOnInit(): void {
        this.isProjectContext = false;
        this.tileContent = {
            title: "Supplier Purchase Orders",
            link: { name: "Learn more", url: "https://aka.ms/pjm-job-aid/manage-suppliers", tooltipText: "Learn more about managing suppliers", icon: "icon-education" }
        };

        this.configManagerService.initialize().then(() => {
            this.errorText = ComponentFailureMessages.PurchaseOrdersComponent;
            this.contractType = this.configManagerService.getValue<IContractType[]>("poContractTypes");
            this.notificationMessage = this.configManagerService.getValue<any>("Notification");
            this.successMessages = this.configManagerService.getValue<any>("SuccessMessages").GoodsReceipt;
            this.failureMessages = this.configManagerService.getValue<any>("FailureMessages").GoodsReceipt;
            this.userBpId = this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId;
            this.graceAmountForPoValidation = this.configManagerService.getValue<number>("graceAmountForPoValidation");
            const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
            if (projectId) {
                this.isProjectContext = true;
            }

            this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);

            this.engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
            const manageSuppliers$ = this.store.select(getEntireManageSuppliers(this.engagementId));
            this.storeDispatchService
                .requireEngagementDetails(this.engagementId, true)
                .requireManageSuppliers(this.engagementId, true)
                .load();

            observableCombineLatest(
                this.engagementDetails$,
                manageSuppliers$,
                (
                    engagementDetails: IEngagementDetailsState,
                    manageSuppliers: IManageSuppliersState,
                ) => ({
                    engagementDetails,
                    manageSuppliers
                })
            ).pipe(untilDestroyed(this)).subscribe(({
                engagementDetails,
                manageSuppliers
            }) => {

                if (manageSuppliers.loaded && engagementDetails.loaded) {
                    /* Assigning draft or readonly status relies on the engagement object */
                    this.engagementDetails = engagementDetails.engagementDetails;
                    this.purchaseOrdersTableData = this.getPurchaseOrdersTableData(manageSuppliers.manageSuppliers);
                }

                this.refreshOnItemInvalidation(engagementDetails, manageSuppliers);
                this.setErrorsBasedOnItemState(engagementDetails, manageSuppliers);
                this.setLoadersBasedOnItemState(engagementDetails, manageSuppliers);
                if (engagementDetails.error || manageSuppliers.error) {
                    this.isServerError = true;
                }
            });
        });
    }

    public ngAfterContentChecked(): void {
        if (!this.haveMountedComponents && this.purchaseOrdersTableData) {
            this.addNewChildComponent(Components.ManageSuppliersTableData);
            this.haveMountedComponents = true;
        }
    }

    /**
     * update the value of the filter from subscribing to the filter change event
     *
     * @param {boolean} isFilterCheckEnable
     * @memberof ManageSuppliersComponent
     */
    public onFilterChange(isFilterCheckEnable: boolean): void {
        this.isFilterCheckEnable = isFilterCheckEnable;
    }

    /**
     * update the value of the selectedPo from subscribing to the selectedPo change event
     *
     * @param {string} selectedPo
     * @memberof ManageSuppliersComponent
     */
    public onSelectedPoChange(selectedPo: string): void {
        this.selectedPo = selectedPo;
    }

    /**
     * Invoked when save button is cliked, it will iterate each lineitem to check  (1) if value has changed and (2) validate input data
     * if those two conditions are satisfied then it will make a put request to save the GR
     *
     * @param {IPoList[]} poList
     * @memberof ManageSuppliersComponent
     */
    public saveProjectDetails(poList: IPoList[]): void { // todo: refactor to simplify
        this.startLoader();
        this.startBusinessProcessTelemetry(BusinessTask.UpdateGoodsReceipt);
        let isValid: boolean = true;
        const purchaseOrders: ISavePurchaseOrder[] = [];
        const grData: ISaveGR = { bpId: this.userBpId, purchaseOrders };

        if (poList) {
            for (const po of poList) {
                if (po.lineitems) {
                    for (const lineItem of po.lineitems) {

                        if (lineItem.isValueChanged &&
                            lineItem.deliveredMode === "draft" &&
                            (!this.validateBusinessRules(lineItem))
                        ) {
                            isValid = false;
                        } else if (lineItem.postedQuantity) {
                            this.changedProperties.push({
                                name: `Delivered Value for PO(${po.purchaseorderno})`,
                                oldValue: lineItem.postedQuantity.toString(),
                                newValue: lineItem.postedQuantity.toString()
                            });
                        }
                        this.pushToSavePurchaseOrdersEntity(purchaseOrders, lineItem, po.purchaseorderno);
                    }
                }
            }
        }

        if (isValid) {
            this.isUpdatingLineItems = true;
            this.purchaseOrderService.saveGR(grData)
                .then((response: ISaveGRResponse) => {
                    if (response.success) {
                        this.fxpMessageService.addMessage(this.successMessages.OnSave, this.FXP_CONSTANTS.messageType.success);
                        if (!this.sharedFunctionsService.disableEmailAlertsNotifications()) {
                            const currentUser = this.fxpUserInfoService.getCurrentUser();
                            this.createLogAndSendNotification(this.engagementId, currentUser, this.changedProperties);
                        }
                        this.dmLogger.logEvent(SourceConstants.Component.ManageSuppliersPage, SourceConstants.Method.SaveProjectDetails, LogEventConstants.ManageSuppliersSaveGoodsReceiptSuccess, { key: true });
                        this.engagementDetailService.invalidateStoreOnRefresh(this.engagementId);

                    } else {
                        let message = "Error on save: ";
                        response.purchaseOrders.forEach((po: any) => {
                            message += po.error.message;
                        });
                        this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.error);
                        this.isUpdatingLineItems = false;
                        this.endLoader();
                        this.endBusinessProcessTelemetry(BusinessTask.UpdateGoodsReceipt);
                    }
                })
                .catch((error) => {
                    this.isUpdatingLineItems = false;
                    const correlationIDText = DataService.getCorrelationIdFromError(error);
                    this.logError(SourceConstants.Method.SaveProjectDetails, error, this.errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    const errorText: string = this.errorText += correlationIDText;
                    this.showLoadingError(errorText);
                    this.endLoader();
                });
        } else {
            this.endLoader();
            this.fxpMessageService.addMessage(this.failureMessages.OnSave, this.FXP_CONSTANTS.messageType.error);
        }
    }

    /**
     * Toggles visibility of the loading/error messages and displays an error.
     */
    private showLoadingError(errorText?: string): void {
        if (errorText) {
            this.errorText = errorText;
        } else {
            const engagementInvoiceError = ComponentFailureMessages.EngagementInvoiceComponent;
            const projectInvoiceError = ComponentFailureMessages.ProjectInvoiceComponent;
            this.errorText = ((this.isProjectContext) ? projectInvoiceError : engagementInvoiceError) + errorText;
        }
    }

    /**
     * Creates the purchase order list model for the table data component.
     * @param purchaseOrders
     */
    private getPurchaseOrdersTableData(purchaseOrders: IPurchaseOrder[]): IPoList[] {
        const poList: IPoList[] = [];
        if (purchaseOrders && purchaseOrders.length) {
            for (const purchaseOrder of purchaseOrders) {
                poList.push({
                    lineitems: this.populateLineItems(purchaseOrder),
                    poConsumed: this.isProjectContext ? purchaseOrder.poConsumption : undefined,
                    poSubTotal: purchaseOrder.subTotalCost,
                    poTotalAmount: purchaseOrder.poTotalAmount,
                    purchaseorderno: purchaseOrder.poNumber,
                    purchaseordertitle: purchaseOrder.description,
                    vendor: purchaseOrder.vendorName,
                });
            }
        }
        return poList;
    }

    /**
     * Create purchase order line items from the given purchase order.
     *
     * @private
     * @param {IPurchaseOrder} po
     * @returns {IPoLineitem[]}
     * @memberof ManageSuppliersComponent
     */
    private populateLineItems(po: IPurchaseOrder): IPoLineitem[] {
        const poLineItems: IPoLineitem[] = [];
        if (po.lineItems && po.lineItems.length) {
            po.lineItems.forEach((lineItem: ILineItem) => {
                poLineItems.push({
                    // linkItem: lineItem.wbsElements.length > 1 ? true : false,  assuming not required
                    amountInvoiced: lineItem.amountInvoiced,
                    amountRemaining: lineItem.amountRemaining,
                    costComplete: lineItem.costComplete,
                    currency: lineItem.currency,
                    delivered: lineItem.deliveredQuantity,
                    /* property used to show input text field; deliver quantity, if.
                    1. lineItem project BpID is same as logged in PjM
                    2. delivered Quantity is different from invoiced quantity. */
                    deliveredMode: this.getReadonlyOrDraftStatus(lineItem),
                    deliveredText: this.getDeliveredText(lineItem),
                    deliveryDueDate: lineItem.deliveryDueDate,
                    description: lineItem.description,
                    goodsreceipt: (lineItem.type === "MAT" || lineItem.type === "FFS"),
                    id: lineItem.lineItemNumber,
                    invoicedQuantity: lineItem.invoicedQuantity,
                    itemRate: lineItem.itemRate,
                    lineItem: lineItem.lineItemNumber,
                    lineItemAmount: lineItem.orderedAmount,
                    lineItemShowWbs: false,
                    longDesc: lineItem.longDescription,
                    orderedAmount: lineItem.orderedAmount,
                    parkAmount: lineItem.parkAmount,
                    parkQuantity: lineItem.parkQuantity,
                    quantity: this.getLineItemQuantity(lineItem),
                    shortDesc: lineItem.description,
                    showFullDesc: true,
                    showMoreLessLink: true,
                    showValidationMsg: false,
                    type: lineItem.type,
                    typeColorCode: this.engagementDetailService.getTypeColorCode(this.contractType, lineItem.type),
                    uom: lineItem.unitOfMeasure,
                    validationMsg: "",
                    wbsElements: this.populateWBSElements(lineItem.wbsElements),
                    postedQuantity: 0
                });
            });
        }
        return poLineItems;
    }

    /**
     * get Line item quantity if type is T&M and return quantity value
     *
     * @private
     * @param {ILineItem} lineItem
     * @returns {*}
     * @memberof ManageSuppliersComponent
     */
    private getLineItemQuantity(lineItem: ILineItem): string {
        let quantity: string;
        if (lineItem.type === "T&M" && lineItem.wbsElements && lineItem.wbsElements.length) {
            let wbsOrderedQuantity = 0;
            lineItem.wbsElements.forEach((wbsElement: IWbsElement) => {
                wbsOrderedQuantity = wbsElement.orderedQuantityL3 + wbsOrderedQuantity;
            });
            quantity = `${wbsOrderedQuantity}/${lineItem.quantity}`;
        } else {
            quantity = lineItem.quantity.toString();
        }
        return quantity;
    }

    /**
     * get status of lineitem
     *
     * @private
     * @param {ILineItem} lineItem
     * @returns {string}
     * @memberof ManageSuppliersComponent
     */
    private getReadonlyOrDraftStatus(lineItem: ILineItem): string {
        if (lineItem.wbsElements) {
            for (const wbsElement of lineItem.wbsElements) {
                /* If any of the wbs elements in the line item are drafts, set the line item mode to draft */
                if (this.isWbsElementDraftMode(lineItem, wbsElement)) {
                    return "draft";
                }
            }
        }
        return "readonly";
    }

    /**
     * getting delivery mode and retrun type
     *
     * @private
     * @param {ILineItem} lineItem
     * @param {IWbsElement} wbs
     * @returns {string}
     * @memberof ManageSuppliersComponent
     */
    private isWbsElementDraftMode(lineItem: ILineItem, wbs: IWbsElement): boolean { // refactor to simplify
        if (!lineItem || !wbs) {
            return false;
        }
        const lineItemProjectId: string = wbs.wbsL1;
        const pjmBpId: string = wbs.wbsL1_Pjm_BpID;
        const projectData: IProjectDetailsApiV2 = this.engagementDetails.projects.filter((project: IProjectDetailsApiV2) => project.id === lineItemProjectId)[0];
        if (
            projectData &&
            (lineItem.type === "MAT" || lineItem.type === "FFS") &&
            this.configManagerService.getValue<string>("enableType2Check") === "true"
        ) {
            if (lineItem.quantity > 0 &&
                (lineItem.quantity !== lineItem.invoicedQuantity || lineItem.quantity !== lineItem.invoicedQuantity + lineItem.parkQuantity)
            ) {
                const delegatedPjmObj: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("DPJM", projectData);
                const addlPjm: ITeamDetailsV2[] = this.sharedFunctionsService.getPjmInfoL1("ADPJM", projectData);
                /* Only the L1 PJM, L1 Delegated PJM, or L1 Additional PJM can edit the quantity (these people would have created the PO)*/
                /* Not even the L0 PJM can edit the quantity */
                if (
                    Number(pjmBpId) === Number(this.userBpId) ||
                    (delegatedPjmObj && delegatedPjmObj[0] && Number(this.userBpId) === Number(delegatedPjmObj[0].bpid)) ||
                    (addlPjm && addlPjm.filter((x: ITeamDetailsV2) => Number(x.bpid) === Number(this.userBpId)).length > 0)
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * get delivered text
     *
     * @private
     * @param {ILineItem} lineItem 
     * @returns {*}
     * @memberof ManageSuppliersComponent
     */
    private getDeliveredText(lineItem: ILineItem): number {
        return lineItem.type === "EXP" ? lineItem.costComplete : lineItem.deliveredQuantity;
    }

    /**
     * return PO list WBS elements for populating
     *
     * @private
     * @param {IWbsElement[]} wbsElements
     * @returns {IPOListWbsElement[]}
     * @memberof ManageSuppliersComponent
     */
    private populateWBSElements(wbsElements: IWbsElement[]): IPOListWbsElement[] {
        const poListWbsElements: IPOListWbsElement[] = [];
        if (wbsElements && wbsElements.length) {
            wbsElements.forEach((wbsElement: IWbsElement) => {
                poListWbsElements.push({
                    wbsL1_Pjm_BpID: wbsElement.wbsL1_Pjm_BpID,
                    wbsL1: wbsElement.wbsL1,
                    wbsL2: wbsElement.wbsL2,
                    wbsL3: wbsElement.wbsL3,
                    l1Description: wbsElement.l1Description,
                    l2Description: wbsElement.l2Description,
                    l3Description: wbsElement.l3Description
                });
            });
        }

        return poListWbsElements;
    }

    private pushToSavePurchaseOrdersEntity(purchaseOrders: ISavePurchaseOrder[], item: IPoLineitem, poNumber: string): void {
        // Push an item to array only if there is a change of delivered amount value.
        if (item.postedQuantity && item.postedQuantity !== 0) {
            purchaseOrders.push({
                poNumber,
                lineItem: item.lineItem,
                quantity: Math.abs(item.postedQuantity),
                // If the difference of delivered amount is negative set value to X.
                reversalFlag: Math.sign(item.postedQuantity) > 0 ? "" : "X",
                wbsId: this.engagementId
            });
        }
    }

    private validateBusinessRules(lineItem: IPoLineitem): boolean {
        const deliveredValue = (lineItem.postedQuantity + +lineItem.deliveredText) * lineItem.itemRate;
        let isValid = true;

        // validation 1 : If delivered is greater than ordered amount.
        if (deliveredValue > (lineItem.orderedAmount + this.graceAmountForPoValidation)) {
            lineItem.validationMsg = GoodsReceiptValidationMessages.DeliveredAmtGreaterThanOrderedAmt;
            isValid = false;
        }

        // validation 2 : If delivered is less than invoiced amount.
        if (deliveredValue - lineItem.amountInvoiced !== 0 && deliveredValue <= lineItem.amountInvoiced + lineItem.parkAmount) {
            // "Delivered 0 should be greater than the total of submitted (1) and Invoiced (2) for line item.";
            lineItem.validationMsg = GoodsReceiptValidationMessages.DeliveredAmtLessThanInvoicedAmt;
            if (lineItem.type === "FFS") {
                const invoicedAmountPercentage = ((lineItem.amountInvoiced / lineItem.orderedAmount) * 100);
                const parkAmountPerventage = ((lineItem.parkAmount / lineItem.orderedAmount) * 100);

                lineItem.validationMsg = lineItem.validationMsg.replace("0", "%");
                lineItem.validationMsg = lineItem.validationMsg.replace("1", parkAmountPerventage.toFixed(2).toString() + "%");
                lineItem.validationMsg = lineItem.validationMsg.replace("2", invoicedAmountPercentage.toFixed(2).toString() + "%");
            } else {
                lineItem.validationMsg = lineItem.validationMsg.replace("0", "value");
                lineItem.validationMsg = lineItem.validationMsg.replace("1", lineItem.parkQuantity.toFixed(2).toString());
                lineItem.validationMsg = lineItem.validationMsg.replace("2", lineItem.invoicedQuantity.toFixed(2).toString());
            }
            isValid = false;
        }

        lineItem.showValidationMsg = !isValid;
        return isValid;
    }

    private createLogAndSendNotification(engagementId: string, userAlias: string, changedProperties: IChangedProperties[]): void {
        this.engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails) => {
            if (engagementDetails.loaded) {
                const esxpNotification = this.notificationMessage.SuccessSaveGoodRecieptNotification;
                const notification = new NotificationModel();
                notification.engagementId = engagementId;
                notification.engagementName = engagementDetails.engagementDetails.name;
                notification.eventName = NotificationEvent.EngagementUpdated;
                notification.sendTo = this.sharedFunctionsService.getListofPjM(engagementDetails.engagementDetails);
                notification.modifiedBy = userAlias;
                notification.modifiedDate = new Date();
                notification.changedProperties = changedProperties;
                this.notificationService.sendNotification(notification, false, esxpNotification);
            }
        });
    }
}
