<div class="dmloader">
    <div id="{{'dmLoaderContainer' + loadingText}}" class="dm-loader-container" *ngIf="showLoading">
        <div class="spinner" role="progressbar" *ngIf="isCircularLoader" aria-label="spinner">
            <div *ngFor="let page of [0,1,2,3,4,5,6,7]; let i = index"
                [ngStyle]="{'animation':'opacity-60-25-'+i+'-9 1s linear infinite', 'transform':'rotate('+(40 * i)+'deg)' }">
            </div>
        </div>
        <div class="spinner-horizontal" role="progressbar" *ngIf="!isCircularLoader">
            <div *ngFor="let page of [0,1,2]; let i = index"
                [ngStyle]="{'animation':'opacity-60-25-'+i+'-9 1s linear infinite'}">
            </div>
        </div>
        <div class="font-subtitle" role="alert" [attr.aria-atomic]="true" aria-live="assertive">
            <!-- Added a flag isCustomText for components that require custom loading text other than loading -->
            <span *ngIf="!isCustomText">Loading </span>
            <span>{{loadingText}}</span>
            <span>...</span>
        </div>
    </div>
    <div class="dm-loader-msg" *ngIf="!showLoading && errorText">
        <p class="error-msg font-caption-alt">
            <i class="icon icon-error"></i>{{errorText}}
        </p>
    </div>
</div>