import { Component, forwardRef, Inject, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import moment from "moment";

import { Components, CacheKeys, CacheStorageOptions, AccessibilityConstants, SourceConstants } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { CacheService } from "../../../../common/services/cache.service";
import { DataService } from "../../../../common/services/data.service";
import { DmGrmService } from "../../../../common/services/dmgrm.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IAddRoleSapInputObject, IDomainValues, IRoleDetail, IRoleDetails, ITaskDetails, RoleDeliveryType } from "../../../../common/services/contracts/add-roles.contracts";
import { ICreateInternalEngagementOutput, InternalEngagementType } from "../../../../components/new-internal-engagement/new-internal-engagement.contracts";
import { IDemandResourceRequest, IResourceRequest, ISchedulePattern, ISkill } from "../../../../components/new-internal-engagement/internal-engagement-grm.contracts";
import { IFinancialProjectTypeName, IFinancialRoles, IUserMembership, ICheckUserMembershipResponse } from "../../../../common/services/contracts/projectservice-functions.contract";
import { InvalidateResourceRequests } from "../../../../store/resource-requests/resource-requests.action";
import { IOneProfileTrainingAPIResponse, IRoleSkills, IOneProfileAPIResponse, IRoleDomainSkill } from "../../../../common/services/contracts/one-profile.contracts";
import { IProjectRequest } from "../../../../common/services/contracts/staffing.service.contract";
import { ISelectedUserAttributes } from "../../../tiles/type-ahead/type-ahead-contracts";
import { IState } from "../../../../store/reducers";
import { OneProfileService } from "../../../../common/services/one-profile.service";
import { ProjectService } from "../../../../common/services/project.service";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmTypeAheadComponent } from "../../../tiles/type-ahead/type-ahead.component";
import { IWbsStructure, IServiceStructure, ITaskStructure } from "../../../../common/services/contracts/wbsStructures.contracts";
import { InvalidateInternalFinancialDetails } from "../../../../store/internal-financial-details/internal-financial-details.action";
import { ResourceRolesService } from "../../../../common/services/resource-roles.service";
import { InvalidateEngagementDetails } from "../../../../store/engagement-details/engagement-details.action";
import { DmError } from "../../../../common/error.constants";
import { IEngagementDetailsV2, IServiceDetailsV2, ITaskDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { InvalidateWbsStructures } from "../../../../store/wbs-structures/wbs-structures.action";

const DATE_FORMAT: string = "YYYY-MM-DD";
const DATE_FORMAT2: string = "T00:00:00.000Z";
const DATE_FORMAT_MM_DD_YYYY: string = "MM-DD-YYYY";
const unitBasedCreationCode: number = 97;
const AREAACCOUNTALIGNED_DOMAIN = "Area Account Aligned";
const OTHER_DOMAIN = "Other";

/* These items are here for reference 
const unitBasedTypeCode: string = "Z000006"; 
const unitBasedTypeCode: number = 97; // original
const msInternalTypeCode: number = 96;
const srOutCorpHQTypeCode: number = 95;
const srOutOtherBGTypeCode: number = 98; */

@Component({
    selector: "dm-add-role-modal",
    templateUrl: "./add-role-modal.html",
    styleUrls: ["./add-role-modal.scss"]
})
export class AddRoleModalComponent extends DmModalAbstract {
    @ViewChild(DmTypeAheadComponent, { static: false }) public resourceTypeahead: DmTypeAheadComponent;
    @Input() public roleDetailForEdit: IRoleDetail;
    @Input() public addedRoleDetails: IRoleDetail[];
    @Input() public engStartDate: Date;
    @Input() public engEndDate: Date;
    @Input() public projectDomain: string;
    @Input() public engType: string;
    @Input() public engTypeCode: number;
    @Input() public engCreationCode: string;
    @Input() public roletype: string;  // different from roleType
    @Input() public selectedCompanyCode: string; // missing from the input
    @Input() public isAddRoleCalledFromStaffing: boolean;
    @Input() public isRoleDateRestrictionDisabled: boolean = false;
    @Input() public grmProjectDetails: IProjectRequest[];
    @Output() public onRoleEditCompleted = new EventEmitter<number>();

    public internalEngagementDetails: IEngagementDetailsV2; /* Will only be populated if calling from the staffing tab */
    public noResults: boolean = false;
    public requiredMsg: string;
    public taCancelAriaLblTxt: string;
    public taRequestedResource: ISelectedUserAttributes;
    public taSearchAriaLblTxt: string;
    public accessibilityConstants = AccessibilityConstants;
    // Skills
    public taRoleSkills: IRoleDomainSkill[] = [];
    public taSkillsSearchAriaLblTxt: string;
    public taSkillsLabelText: string;
    public taSkillsCancelAriaLblTxt: string;
    public taSkillsRequiredMsg: string;
    public taSkillsNoResultsModel: IRoleSkills[] = [];
    public isAfterRoleEndDate: boolean = false;
    public isBeforeRoleStartDate: boolean = false;
    public isEndDateRequired: boolean = false;
    public isRequestedResourceAccountAligned: boolean = false;
    public isRequestedResourceEmpty: boolean = false;
    public isSpecialRoleToAutoCommit: boolean;
    public isStartDateRequired: boolean = false;
    public additionalDetails: string = "";
    public deliveryType: string = "Remote";
    public domain: IDomainValues;
    public endDateValMsg: string = "";
    public internalEngagementDomains: IDomainValues[] = [];
    public isResourceTrainingExpired: boolean;
    public isRoleDetailsLoading: boolean;
    public laborHours: number;
    public laborHoursValMsg: string = "";
    public loadingText: string;
    public reqResourceValMsg: string = "";
    public reqResourceAccountAlignedValMsg: string = "";
    public roleType: IFinancialRoles;
    public roleValues: IFinancialRoles[] = [];
    public selectedUser: ISelectedUserAttributes;
    public startDateValMsg: string = "";
    public autoCommitRoleToolTipText: string = "AutoCommit Role";
    public selectedTaskId: string;
    public laborTaskDetails: ITaskDetails[] = [];
    public isStartDateValid: boolean;
    public isEndDateValid: boolean;
    public isNBUEHoursValid: boolean;
    public showResourceScheduleField: boolean;
    public isResourceScheduleFree: boolean = true;
    public isResourceBillable: boolean = true;
    public isResourceBillableFieldDisabled: boolean = false;
    public isSrOutFederalSupportEngagement: boolean = false;
    public addRoleErrorMessages = DmError.InternalEngagement.AddRole;
    public isDomainSkillInvalid: boolean = false;
    public isSkillRequired: boolean = true;
    private FXP_CONSTANTS = FxpConstants;
    private financialRoles: IFinancialRoles[] = [];
    private roleDetails: IRoleDetails;
    private startDate: Date;
    private endDate: Date;
    private isValidateSpecialRole: boolean;
    private newRoleDetails: IRoleDetail;
    private roleSequence: number;
    private availableRoleDetails: IRoleDetail[] = [];
    private configTypesWithRoles: IFinancialProjectTypeName[];
    private defaultGracePeriod: number;
    private autoCommitRoleValues = [];
    private clearedRolesCompanyCodes: string[] = [];
    private wwtkTrainingsList: string[] = [];
    private isResourceScheduleFlowEnabled: boolean;
    private saossoGroupId: string;
    private isFedEngType: boolean = false;
    private federalOnlyRoles: string[] = [];
    private restrictedRoles: string[] = [];

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DmGrmService) private grmService: DmGrmService,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(ResourceRolesService) private resourceRolesService: ResourceRolesService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StateService) private stateService: StateService,
        @Inject(CacheService) private cacheService: CacheService
    ) {
        super(activeModal, dmLogger, Components.AddRole);
        this.taSkillsCancelAriaLblTxt = "Cancel Skills Search Text";
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.taSkillsRequiredMsg = "At least one skill should be selected";
        this.taSearchAriaLblTxt = "Search Resource by name or alias";
        this.taCancelAriaLblTxt = "Remove Requested Resource.";
        this.loadingText = "Loading Details";
        this.isRoleDetailsLoading = true;
        this.roleValues = [];
        this.roleDetails = {
            roleDetailForEdit: this.roleDetailForEdit,
            addedRoleDetails: this.addedRoleDetails,
            engStartDate: this.engStartDate,
            engEndDate: this.engEndDate,
            projectDomain: this.projectDomain,
            engType: this.engType,
            selectedCompanyCode: this.selectedCompanyCode
        };
        this.availableRoleDetails = this.roleDetails.addedRoleDetails;
        const fedEngagementTypes: string[] = [InternalEngagementType.srOutFedConsulting, InternalEngagementType.srOutFedSupport];
        this.isFedEngType = fedEngagementTypes.indexOf(this.engType) > -1;

        this.configManagerService.initialize()
            .then(() => {
                this.defaultGracePeriod = this.configManagerService.getValue<number>("defaultGracePeriodForSkills");
                this.clearedRolesCompanyCodes = this.configManagerService.getValue<string[]>("clearedRolesCompanyCodes");
                this.autoCommitRoleValues = this.configManagerService.getValue<any>("roleValueList");
                this.configTypesWithRoles = this.configManagerService.getValue<IFinancialProjectTypeName[]>("rolesAssociationWithType");
                this.internalEngagementDomains = this.configManagerService.getValue<IDomainValues[]>("internalEngagementDomains");
                this.wwtkTrainingsList = this.configManagerService.getValue<string[]>("WWTKTrainingCodes");
                this.isResourceScheduleFlowEnabled = this.configManagerService.isFeatureEnabled("enableResourceScheduleFlow");
                this.saossoGroupId = this.configManagerService.getValue<string>("SAOSSTGroupId");
                this.federalOnlyRoles = this.configManagerService.getValue<string[]>("federalOnlyRoles");
                this.restrictedRoles = this.configManagerService.getValue<string[]>("restrictedRoles");
                if (this.isAddRoleCalledFromStaffing) {
                    // const engId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
                    // todo turn on once v2 has been reimplemented
                    // this.getInternalEngagementDetailsFromStore(engId);
                }
                if (!this.isResourceScheduleFlowEnabled) {
                    this.showResourceScheduleField = false;
                    this.isResourceScheduleFree = false;
                }
                this.isSrOutFederalSupportEngagement = this.sharedFunctionsService.isSrOutFederalSupportEngagement(this.engTypeCode.toString());
                const promises = this.isResourceScheduleFlowEnabled ? [this.getSkillsandGracePeriod(), this.getFinancialRoles(), this.verifyIsUserPartOfSSOSSTGroup()]
                    : [this.getSkillsandGracePeriod(), this.getFinancialRoles()];
                Promise.all(promises).then(() => {
                    this.isValidateSpecialRole = false;
                    if (this.configTypesWithRoles.filter((type: IFinancialProjectTypeName) => type.EngagementCreationCode === this.engTypeCode.toString() || type.EngagementTypeCode.toLowerCase() === this.engTypeCode.toString().toLowerCase()).length) {
                        this.isValidateSpecialRole = true;
                    }
                    this.enableResourceBillableField();
                    this.availableRoleDetails = this.roleDetails.addedRoleDetails;
                    if (this.internalEngagementDetails) {
                        this.laborTaskDetails = this.getLaborTasksForInternalEngagement(this.internalEngagementDetails);
                    }
                    if (this.showResourceScheduleField && this.roleDetailForEdit && this.roleDetailForEdit.isResourceScheduleFree !== undefined) {
                        this.isResourceScheduleFree = this.roleDetailForEdit.isResourceScheduleFree;
                    }
                    // if (this.roleDetailForEdit && this.roleDetailForEdit.roleDomainName === AREAACCOUNTALIGNED_DOMAIN) {
                    //     this.isSkillRequired = false;
                    // }
                    this.validateToSpecialRole();
                    this.isRoleDetailsLoading = false;
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isRoleDetailsLoading = false;
                });
            });
    }

    /**
     * Move focus to element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * On Toggle of ResourceSchedule 
     */
    public onScheduleToggle(): void {
        this.isResourceScheduleFree = !this.isResourceScheduleFree;
        this.validateIsResourceRequired();
    }

    /**
     * On Toggle of Resource Billability 
     */
    public onResourceBillableToggle(): void {
        this.isResourceBillable = !this.isResourceBillable;
    }
    /**
     * Validate start date
     *
     * @param {Date} date
     * @returns {boolean}
     * @memberof AddRoleModalComponent
     */
    public isEntityStartDateValid(date: Date): boolean {
        if (this.roleDetailForEdit && this.roleDetailForEdit.errorDetails && this.roleDetailForEdit.errorDetails.StartDate) {
            delete this.roleDetailForEdit.errorDetails.StartDate;
        }
        this.isStartDateRequired = false;
        this.isAfterRoleEndDate = false;
        this.startDateValMsg = "";
        this.startDate = date;

        if (!this.startDate) {
            this.isStartDateRequired = true;
            this.startDateValMsg = DmError.InternalEngagement.AddRole.StartDateIsRequired;
            return false;
        }

        if (this.endDate && moment(this.startDate).isAfter(this.endDate)) {
            this.isAfterRoleEndDate = true;
            this.startDateValMsg = DmError.InternalEngagement.AddRole.RoleStartDateCannotBeAfterOrLaterThanRoleEndDate + moment(this.endDate).format("DD-MMM-YYYY");
            return false;
        }
        return true;
    }

    /**
     * Validate end date
     *
     * @param {Date} date
     * @returns {boolean}
     * @memberof AddRoleModalComponent
     */
    public isEntityEndDateValid(date: Date): boolean {
        if (this.roleDetailForEdit && this.roleDetailForEdit.errorDetails && this.roleDetailForEdit.errorDetails.EndDate) {
            delete this.roleDetailForEdit.errorDetails.EndDate;
        }
        this.isEndDateRequired = false;
        this.isBeforeRoleStartDate = false;
        this.endDateValMsg = "";
        this.endDate = date;

        if (!this.endDate) {
            this.isEndDateRequired = true;
            this.endDateValMsg = DmError.InternalEngagement.AddRole.EndDateIsRequired;
            return false;
        }

        if (this.startDate && moment(this.endDate).isBefore(this.startDate)) {
            this.endDateValMsg = DmError.InternalEngagement.AddRole.RoleEndDateCannotBeEarlierThanRoleStartDate + moment(this.startDate).format("DD-MMM-YYYY").toString();
            this.isBeforeRoleStartDate = true;
            return false;
        }

        return true;
    }

    /**
     * Update skill validity value.
     *
     * @param {boolean} value
     * @memberof AddRoleModalComponent
     */
    public updateSkillsValidity(value: boolean): void {
        this.isDomainSkillInvalid = value;
    }

    /**
     * Determines if the save button is disabled or not by validating the necessary fields in the modal.
     */
    public isSaveButtonDisabled(): boolean {
        this.validateNBUEHours();
        if (this.isRequestedResourceEmpty && this.selectedUser) {
            this.isRequestedResourceEmpty = false;
            this.reqResourceValMsg = "";
        }
        return (
            !this.roleType ||
            (this.internalEngagementDetails && this.internalEngagementDetails.projects.length > 1 ? !this.selectedTaskId : false) ||
            !this.laborHours ||
            !(this.domain && this.domain.domainValueName) ||
            (this.isSkillRequired ? !this.taRoleSkills.length : false) ||
            !this.startDate ||
            !this.endDate ||
            this.isRequestedResourceAccountAligned ||
            this.isDomainSkillInvalid
        );
    }

    /**
     * Validating special role
     */
    public validateToSpecialRole(): void {
        this.reqResourceValMsg = "";
        this.reqResourceAccountAlignedValMsg = "";
        if (this.roleDetailForEdit && this.roleDetailForEdit.errorDetails && this.roleDetailForEdit.errorDetails.Role) {
            delete this.roleDetailForEdit.errorDetails.Role;
            if (this.roleDetailForEdit.errorDetails["Role,PartNumber"]) {
                delete this.roleDetailForEdit.errorDetails["Role,PartNumber"];
            }
        }
        this.isSpecialRoleToAutoCommit = false;
        this.laborHoursValMsg = DmError.InternalEngagement.AddRole.RequestedLaborIsRequired;
        if (this.isValidateSpecialRole && this.resourceRolesService.isRoleAutoCommit(this.roleType, this.engTypeCode)) {
            this.isSpecialRoleToAutoCommit = true;
        }
        this.validateIsResourceAccountAligned();
        // this.validateIsResourceRequired();
    }

    public isResourceDatesValid(): boolean {
        this.isStartDateValid = true;
        this.isEndDateValid = true;
        if (this.internalEngagementDetails && this.internalEngagementDetails.projects && this.internalEngagementDetails.projects.length > 0) {
            for (const project of this.internalEngagementDetails.projects) {
                if (project.services && project.services[0] && project.services[0].tasks && project.services[0].tasks.length
                    && project.services[0].tasks.filter((wbsl3: ITaskDetailsV2) => wbsl3.id === this.selectedTaskId).length) {

                    if (moment(this.startDate).isBefore(project.startDate, "day")) {
                        this.isStartDateValid = false;
                        this.startDateValMsg = DmError.InternalEngagement.AddRole.StartDateShouldBeLaterThanTheProjectStartDate + moment(project.startDate).format(DATE_FORMAT_MM_DD_YYYY).toString();
                        return false;
                    } else if (moment(this.endDate).isAfter(project.endDate, "day")) {
                        this.isEndDateValid = false;
                        this.endDateValMsg = DmError.InternalEngagement.AddRole.EndDateShouldBeEarlierThanTheProjectEndDate + moment(project.endDate).format(DATE_FORMAT_MM_DD_YYYY).toString();
                        return false;
                    }
                }
            }
        }
        return true;
    }

    /**
     * Validate the domain of the requested resource. If the domain of the requested resource is
     * "Area Account Aligned", the request should qualify for auto commit and this function
     * will return true, otherwise return false.
     *
     * @returns {boolean}
     * @memberof AddRoleModalComponent
     */
    public validateIsResourceAccountAligned(): boolean {
        return true; // todo: confirom with HK
        this.isRequestedResourceAccountAligned = false;
        const selectedResource: ISelectedUserAttributes = this.selectedUser ? this.selectedUser : this.taRequestedResource;

        if (selectedResource && selectedResource.userAlias && this.isSpecialRoleToAutoCommit) {
            const cacheKey = CacheKeys.UserProfile.KeyName + `${selectedResource.userAlias}`;
            this.cacheService.get(cacheKey, () => this.oneProfileService.getProfile(selectedResource.userAlias), CacheKeys.UserProfile.Duration).then((profile: IOneProfileAPIResponse) => {
                if (profile && profile.SDODomain &&
                    (profile.SDODomain.toLowerCase() === AREAACCOUNTALIGNED_DOMAIN.toLowerCase()
                        || profile.SDODomain.toLowerCase() === OTHER_DOMAIN.toLowerCase()) /* Reorg changed many domains to 'other', this is fix recommended by business as of Sept 2021 */
                ) {
                    return true;
                } else {
                    this.isRequestedResourceAccountAligned = true;
                    this.reqResourceAccountAlignedValMsg = DmError.InternalEngagement.AddRole.DomainAlignedCannotBeAssignedToAccountAlignedRoles;
                    return false;
                }
            });
        } else {
            return true;
        }
    }

    /**
     * selectedUserUpdate
     * @param value
     */
    public selectedUserUpdated(value: ISelectedUserAttributes): void {
        this.selectedUser = value;
        this.validateIsResourceAccountAligned();
        this.validateIsResourceRequired();
        this.isResourceTrainingExpired = false;
    }

    /**
     * On domain change, validate if resource is account aligned.
     *
     * @param {*} value
     * @memberof AddRoleModalComponent
     */
    public onDomainChange(): void {
        // this.validateIsResourceAccountAligned();
        // this.isSkillRequired = true;
        // if (this.domain.domainValueName.toLowerCase() === AREAACCOUNTALIGNED_DOMAIN.toLowerCase()) {
        //     // this.isSkillRequired = false; // todo: Need to verify with HK
        //     // this.financialRoles = this.filterOutFedRoles(this.financialRoles);
        //     // this.roleValues = this.financialRoles.filter((role) => this.resourceRolesService.isRoleAutoCommit(role, this.engTypeCode));
        // } else {
        //     this.filterRoleValues();
        // }
    }

    /**
     * On User Cleared
     */
    public onUserCleared(): void {
        this.selectedUser = null;
        this.isResourceTrainingExpired = false;
    }

    /**
     * Save roledetails on clicking save button
     */
    public saveRoleDetails(): void {
        this.isResourceTrainingExpired = false;
        const isResourceRequiredValidation = this.validateIsResourceRequired();
        if (!this.isResourceDatesValid()) {
            return;
        }
        /// Check if Resource required
        if (!isResourceRequiredValidation && this.isResourceScheduleFree && this.isResourceScheduleFlowEnabled) {
            return;
        } else if (!isResourceRequiredValidation && this.sharedFunctionsService.isSrOutFederalSupportEngagement(this.engTypeCode.toString())) {
            return;
        } else if ((isResourceRequiredValidation && !this.selectedUser) || !this.isSpecialRoleToAutoCommit) {
            this.addToRoleDetailsObject();
        } else if (isResourceRequiredValidation && this.selectedUser) {
            this.loadingText = "Validating Resource Training Details";
            this.isRoleDetailsLoading = true;
            this.oneProfileService.getResourceTrainingDetails(this.selectedUser.userAlias)
                .then((response: IOneProfileTrainingAPIResponse[]) => {
                    this.isRoleDetailsLoading = false;
                    /// Filter the completed trainings and verify if training completed is a valid WWTK training and completed in past 365 days
                    // const filteredTrainings = response.filter((object: IOneProfileTrainingAPIResponse) => object.Status === "Completed" && this.wwtkTrainingsList.indexOf(object.CourseId) > -1 && this.isTrainingCompletionDateValid(object.CompletionDate));
                    // if (filteredTrainings && filteredTrainings.length) {
                    this.addToRoleDetailsObject();
                    // } else {
                    //     // this.isResourceTrainingExpired = true;
                    //     this.sharedFunctionsService.delayExecution(5000).then(() => {
                    //         this.sharedFunctionsService.focus("ResourceReq", true);
                    //     });
                    // }
                }).catch((error) => {
                    this.logError(SourceConstants.Method.SaveRoleDetails, error, DmError.InternalEngagement.AddRole.FailedToRetrieveTrainingDetails, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.fxpMessageService.addMessage(DmError.InternalEngagement.AddRole.FailedToRetrieveTrainingDetails + "Correlation ID:" + DataService.getCorrelationIdFromError(error), this.FXP_CONSTANTS.messageType.error);
                    this.isRoleDetailsLoading = false;
                });
        }
    }

    /**
     * Restrict non decimal field entry for Requested Hours. Note : This can be deleted once the Roles is moved to Reactive forms, where we can use a built in pattern match
     */
    public isRequestedHourInteger(event: KeyboardEvent): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode >= 48 && charCode <= 57) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Validate NBUE Hours if greater than project NBUE hours.
     * Shows a warning on the UI if the hours requested is more than nbue approved hours
     */
    public validateNBUEHours(): void {
        this.isNBUEHoursValid = true;
        if (this.laborHours && this.internalEngagementDetails && this.selectedTaskId && this.internalEngagementDetails.projects && this.internalEngagementDetails.projects.length) {
            for (const project of this.internalEngagementDetails.projects) {
                if (project.services && project.services[0] && project.services[0].tasks && project.services[0].tasks.length
                    && project.services[0].tasks.filter((wbsl3: ITaskDetailsV2) => wbsl3.id === this.selectedTaskId).length) {
                    if (project.totalHours && project.totalHours > 0 && this.laborHours > project.totalHours) {
                        this.isNBUEHoursValid = false;
                        this.laborHoursValMsg = DmError.InternalEngagement.AddRole.RequestedHoursExceedsApprovedNBUEHours + project.totalHours;
                        break;
                    }
                }
            }
        }
    }

    /**
     * Checks if a named resource is required or not. Some roles require resources while some do not.
     * Returns true if a resource is required, false otherwise.
     */
    private validateIsResourceRequired(): boolean {
        this.isRequestedResourceEmpty = false;
        if (!this.selectedUser && this.isSpecialRoleToAutoCommit) {
            this.isRequestedResourceEmpty = true;
            this.reqResourceValMsg = DmError.InternalEngagement.AddRole.ProvideAResourceNameThatIsToBeAutoCommitted;
            return false;
        } else if ((!this.selectedUser || (this.roleDetailForEdit && this.roleDetailForEdit.errorDetails && this.roleDetailForEdit.errorDetails.RequestedResourceBpid)) && this.isResourceScheduleFree) {
            this.isRequestedResourceEmpty = true;
            this.reqResourceValMsg = DmError.InternalEngagement.AddRole.ProvideAResourceNameThatResourceScheduleFree;
            return false;
        } else if (!this.selectedUser && this.sharedFunctionsService.isSrOutFederalSupportEngagement(this.engTypeCode.toString())) {
            this.isRequestedResourceEmpty = true;
            this.reqResourceValMsg = DmError.InternalEngagement.AddRole.ProvideAResourceNameSROutFederalSupport;
            return false;
        } else {
            return true;
        }
    }

    /**
     * Add role to role details object
     */
    private addToRoleDetailsObject(): void {
        if (this.roleDetails.roleDetailForEdit) {
            this.roleDetails.roleDetailForEdit.deliveryType = this.deliveryType;
            this.roleDetails.roleDetailForEdit.isClearedRole = this.roleType.isCleared;
            this.roleDetails.roleDetailForEdit.isSpecialRole = this.isSpecialRoleToAutoCommit;
            this.roleDetails.roleDetailForEdit.roleAdditionalDetails = this.additionalDetails;
            this.roleDetails.roleDetailForEdit.roleDomainName = this.domain.domainValueName;
            this.roleDetails.roleDetailForEdit.roleEndDate = moment(this.endDate).format(DATE_FORMAT + DATE_FORMAT2);
            this.roleDetails.roleDetailForEdit.roleName = this.roleType.roleName;
            this.roleDetails.roleDetailForEdit.rolePartNumber = this.roleType.rolePartNumber;
            this.roleDetails.roleDetailForEdit.roleRequestedHours = Number(this.laborHours);
            this.roleDetails.roleDetailForEdit.roleSequence = this.roleSequence;
            this.roleDetails.roleDetailForEdit.roleSkills = this.taRoleSkills;
            this.roleDetails.roleDetailForEdit.roleStartDate = moment(this.startDate).format(DATE_FORMAT + DATE_FORMAT2);
            this.roleDetails.roleDetailForEdit.isResourceScheduleFree = this.isResourceScheduleFree;
            this.roleDetails.roleDetailForEdit.isResourceBillable = this.isResourceBillable;
            if (this.selectedUser || (this.resourceTypeahead.value && this.resourceTypeahead.value.userAlias)) {
                this.roleDetails.roleDetailForEdit.roleRequestedResource = this.selectedUser ? this.selectedUser : this.roleDetails.roleDetailForEdit.roleRequestedResource;

                if (this.selectedUser && this.selectedUser.workCountryCode && this.selectedUser.workState && this.selectedUser.workCity) {
                    this.roleDetails.roleDetailForEdit.deliveryCountryCode = this.deliveryType === RoleDeliveryType.Remote ? this.selectedUser.workCountryCode : this.roleDetails.roleDetailForEdit.deliveryCountryCode;
                    this.roleDetails.roleDetailForEdit.deliveryState = this.deliveryType === RoleDeliveryType.Remote ? this.selectedUser.workState : this.roleDetails.roleDetailForEdit.deliveryState;
                    this.roleDetails.roleDetailForEdit.deliveryCity = this.deliveryType === RoleDeliveryType.Remote ? this.selectedUser.workCity : this.roleDetails.roleDetailForEdit.deliveryCity;
                }
            } else {
                this.resourceTypeahead.clearText();
                this.roleDetails.roleDetailForEdit.roleRequestedResource = this.selectedUser;
            }

            this.onRoleEditCompleted.emit(this.roleDetails.roleDetailForEdit.roleSequence);
            this.activeModal.close();
        } else {

            let deliveryCountryCode: string;
            let deliveryState: string;
            let deliveryCity: string;

            if (this.deliveryType === RoleDeliveryType.Remote && this.selectedUser && this.selectedUser.workCountryCode && this.selectedUser.workState && this.selectedUser.workCity) {
                deliveryCountryCode = this.selectedUser.workCountryCode;
                deliveryState = this.selectedUser.workState;
                deliveryCity = this.selectedUser.workCity;
            }

            this.newRoleDetails = {
                deliveryType: this.deliveryType,
                isClearedRole: this.roleType.isCleared,
                isSpecialRole: this.isSpecialRoleToAutoCommit,
                roleAdditionalDetails: this.additionalDetails,
                roleDomainName: this.domain.domainValueName,
                roleEndDate: moment(this.endDate).format(DATE_FORMAT + DATE_FORMAT2),
                roleName: this.roleType.roleName,
                rolePartNumber: this.roleType.rolePartNumber,
                roleRequestedHours: Number(this.laborHours),
                roleRequestedResource: this.selectedUser,
                roleSequence: (this.availableRoleDetails && this.availableRoleDetails.length) ? this.availableRoleDetails[this.availableRoleDetails.length - 1].roleSequence + 1 : 0,
                roleSkills:  this.taRoleSkills,
                roleStartDate: moment(this.startDate).format(DATE_FORMAT + DATE_FORMAT2),
                errorDetails: (this.newRoleDetails && (Object.keys(this.newRoleDetails.errorDetails).length > 0)) ? this.newRoleDetails.errorDetails : undefined,
                isResourceScheduleFree: this.isResourceScheduleFree,
                isResourceBillable: this.isResourceBillable,
                deliveryCountryCode,
                deliveryState,
                deliveryCity,
            };
            this.availableRoleDetails.push(this.newRoleDetails);
            if (this.isAddRoleCalledFromStaffing) {
                if (this.grmProjectDetails && this.grmProjectDetails.length) {
                    this.loadingText = "Adding Role to Engagement";
                    this.isRoleDetailsLoading = true;
                    const addRoleSapInput: IAddRoleSapInputObject = {
                        roleData: [{
                            role: this.newRoleDetails.rolePartNumber,
                            startDate: moment(this.newRoleDetails.roleStartDate).format(DATE_FORMAT) + DATE_FORMAT2,
                            endDate: moment(this.newRoleDetails.roleEndDate).format(DATE_FORMAT) + DATE_FORMAT2,
                            requestedHours: this.newRoleDetails.roleRequestedHours
                        }],
                        engagementDetails: {
                            engagementId: this.internalEngagementDetails.id,
                            taskId: this.selectedTaskId
                        }
                    };
                    this.projectService.addRoleToInternalEngagement(addRoleSapInput)
                        .then((internalEngagementOutput: ICreateInternalEngagementOutput) => {
                            this.fxpMessageService.addMessage("Successfully Added Role for SAP Engagement " + internalEngagementOutput.contractData.engagementId, this.FXP_CONSTANTS.messageType.success);
                            const resourceRequests: IResourceRequest[] = this.getResourceRequests();
                            resourceRequests.forEach((role) => {
                                role.DemandResourceRequest = this.getDemandResourceRequestForRole(internalEngagementOutput, role);
                            });
                            const inputRoleEngagement = {
                                ResourceRequests: resourceRequests
                            };
                            const grmProjectId = this.getGrmProjectId(this.grmProjectDetails, this.selectedTaskId);
                            if (grmProjectId) {
                                this.grmService.addRoleToInternalEngagement(inputRoleEngagement, grmProjectId)
                                    .then(() => {
                                        this.fxpMessageService.addMessage("Successfully Added Resource request for GRM Project " + internalEngagementOutput.contractData.engagementId, this.FXP_CONSTANTS.messageType.success);
                                        this.invalidateData();
                                        this.isRoleDetailsLoading = false;
                                        this.activeModal.close();
                                    }).catch((error) => {
                                        this.logError(SourceConstants.Method.AddToRoleDetailsObject, error, DmError.InternalEngagement.AddRole.AnErrorOccurredWhileCreatingTheGRMProject, ErrorSeverityLevel && ErrorSeverityLevel.High);
                                        this.fxpMessageService.addMessage(DmError.InternalEngagement.AddRole.AnErrorOccurredWhileCreatingTheGRMProject, this.FXP_CONSTANTS.messageType.warning);
                                        this.invalidateData();
                                        this.isRoleDetailsLoading = false;
                                        this.activeModal.close();
                                    });
                            } else {
                                this.fxpMessageService.addMessage(DmError.InternalEngagement.AddRole.ProjectdoesnotExistInResourceManagement, this.FXP_CONSTANTS.messageType.error);
                            }
                        }).catch((error) => {
                            this.logError(SourceConstants.Method.AddToRoleDetailsObject, error, DmError.InternalEngagement.AddRole.AnErrorOccurredWhileAddingResourceRequest, ErrorSeverityLevel && ErrorSeverityLevel.High);
                            this.fxpMessageService.addMessage(DmError.InternalEngagement.AddRole.AnErrorOccurredWhileAddingResourceRequest, this.FXP_CONSTANTS.messageType.error);
                            this.invalidateData();
                            this.isRoleDetailsLoading = false;
                            this.activeModal.close();
                        });
                } else {
                    this.fxpMessageService.addMessage(DmError.InternalEngagement.AddRole.NoProjectsFoundInResourceManagement, this.FXP_CONSTANTS.messageType.error);
                }
            } else {
                this.activeModal.close();
            }

        }
    }
    /**
     * Get the GRM Project Id based on the selectedTask
     *
     * @memberof AddRoleModalComponent
     */
    private getGrmProjectId(grmProjectDetails: IProjectRequest[], selectedTaskId: string): string {
        if (grmProjectDetails && grmProjectDetails.length && this.internalEngagementDetails && this.internalEngagementDetails.projects && this.internalEngagementDetails.projects.length) {
            for (const project of this.internalEngagementDetails.projects) {
                if (project.services && project.services[0] && project.services[0].tasks && project.services[0].tasks.length
                    && project.services[0].tasks.filter((wbsl3: ITaskDetailsV2) => wbsl3.id === selectedTaskId).length
                    && grmProjectDetails.filter((grmProject: IProjectRequest) => grmProject.DemandSourceId === project.id).length) {
                    return this.grmProjectDetails.filter((grmProject: IProjectRequest) => grmProject.DemandSourceId === project.id)[0].ProjectId.toString();
                }
            }
        }
        return undefined;
    }

    /**
     * Get latest training completion date
     * @param trainingDetails
     */
    private getLatestTrainingCompletionDate(trainingDetails: IOneProfileTrainingAPIResponse[]): string {
        let latestTrainingCompletionDate = "";
        if (trainingDetails && trainingDetails.length) {
            this.sortTrainingDetailsByDate(trainingDetails);
            latestTrainingCompletionDate = trainingDetails[0].CompletionDate;
        }
        return latestTrainingCompletionDate;
    }

    /**
     * Validating training completion date
     * Checks if the training was done in the past 365 days
     * @param trainingCompletionDate
     */
    private isTrainingCompletionDateValid(trainingCompletionDate: string): boolean {
        let isTrainingCompletionValid = false;
        const dateToCheck = new Date(trainingCompletionDate);
        dateToCheck.setDate(dateToCheck.getDate() + 365);
        if (moment(new Date()).isSameOrBefore(dateToCheck)) {
            isTrainingCompletionValid = true;
        }

        return isTrainingCompletionValid;
    }

    /**
     * Sort training details by date
     * @param trainingDetails
     */
    private sortTrainingDetailsByDate(trainingDetails: IOneProfileTrainingAPIResponse[]): void {
        trainingDetails.sort((a: IOneProfileTrainingAPIResponse, b: IOneProfileTrainingAPIResponse) => {
            const dateA = new Date(a.CompletionDate);
            const dateB = new Date(b.CompletionDate);
            return dateA < dateB ? 1 : -1;
        });

    }

    private showOnlyClearedRoles(): boolean {
        return this.roleDetails && this.roleDetails.selectedCompanyCode && this.clearedRolesCompanyCodes.indexOf(this.roleDetails.selectedCompanyCode.toString()) > -1;
    }

    /**
     * Builds the resource request
     *
     * @private
     * @param IRoleDetail
     * @returns ISchedulePattern[]
     * @memberof StaffingCommandBarComponent
     */
    private getDemandResourceRequestForRole(response: ICreateInternalEngagementOutput, resourceReq: IResourceRequest): IDemandResourceRequest {
        let tempDemandResReq: IDemandResourceRequest;
        try {
            tempDemandResReq = {
                ServiceName: response.contractData.serviceName,
                PoNumber: null,
                PoLineItem: null,
                DemandId: response.roleData.filter((roleDetail) => roleDetail.role.toLowerCase() === resourceReq.RolePartNumber.toLowerCase())[0].grmDemandId,
                SapWorkPackageId: this.selectedTaskId,
                SupplierCompanyId: null,
                PoLineItemDescription: null,
                PoLineItemHours: null,
                IsBillable: (this.engTypeCode && (this.engTypeCode.toString() === "Z000007") || (this.engTypeCode.toString() === "Z000008")) || this.isResourceBillable,
                WBSL0ID: response.contractData.engagementId,
                IncomingSchedulePattern: null,
                AdditionalFields: {
                    EngagementL0Description: this.internalEngagementDetails.name,
                    TaskL3Description: response.contractData.taskName,
                    AssignmentDescription: null,
                    SupplierCompanyName: null,
                    PoType: null,
                    PoLineItemType: null,
                    PoDescription: null,
                }
            };
        } catch (error) {
            this.logError(SourceConstants.Method.GetDemandResourceRequestForRole, error, DmError.InternalEngagement.AddRole.ErrorOccuredWhileReadingDemandIdForRoleId, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.fxpMessageService.addMessage(DmError.InternalEngagement.AddRole.ErrorOccuredWhileReadingDemandIdForRoleId + resourceReq.RolePartNumber, this.FXP_CONSTANTS.messageType.error);
            this.isRoleDetailsLoading = false;
            this.activeModal.close();
        }
        return tempDemandResReq;
    }

    /**
     * Returns Labor Task Id for Internal Engagement
     *
     * @private
     * @param IEngagementDetails
     * @returns {string}
     * @memberof StaffingCommandBarComponent
     */
    private getLaborTasksForInternalEngagement(internalEngagementDetails: IEngagementDetailsV2 | IWbsStructure): ITaskDetails[] {
        const taskDetails: ITaskDetails[] = [];
        if (internalEngagementDetails && internalEngagementDetails.projects) {
            const laborTypeCode: string = this.configManagerService.getValue<string>("LaborTypeCode");
            for (const project of internalEngagementDetails.projects) {

                /* Check for tasks on either IEngDetails if it comes from Add Roles Component or IWbsStructure if it somces from Staffing Command Bar */
                if (project.services && project.services[0]) {
                    if ((project.services[0] as IServiceStructure).tasks) {
                        const tasks: ITaskStructure[] = (project.services[0] as IServiceStructure).tasks;
                        const filteredTaskDetails = tasks.filter((wbsl3) => wbsl3.workPackageType /* .wbsl3Type*/ === laborTypeCode);
                        for (const task of filteredTaskDetails) {
                            taskDetails.push({
                                taskId: task.id,
                                taskName: task.name
                            });
                        }
                    } else if ((project.services[0] as IServiceDetailsV2).tasks) {
                        const tasks: ITaskDetailsV2[] = (project.services[0] as IServiceDetailsV2).tasks;
                        const filteredTaskDetails = tasks.filter((wbsl3: ITaskDetailsV2) => wbsl3.workPackageType === laborTypeCode);
                        for (const task of filteredTaskDetails) {
                            taskDetails.push({
                                taskId: task.id,
                                taskName: task.name
                            });
                        }
                    }
                }
            }
        }

        /* Set default placeholder task to the first task in the list */
        this.selectedTaskId = taskDetails[0].taskId;
        return taskDetails;
    }

    /**
     * Returns Schedule patterns based on the roleDetail input
     *
     * @private
     * @param IRoleDetail
     * @returns ISchedulePattern[]
     * @memberof StaffingCommandBarComponent
     */
    private getRoleSchedulePattern(role: IRoleDetail): ISchedulePattern[] {
        if (!role) {
            return [];
        }
        const tempSkillsSchedulePatternList: ISchedulePattern[] = [];
        const day1 = moment(role.roleStartDate);
        const day2 = moment(role.roleEndDate);
        const eomValue = moment("" + (day2.month() + 1).toString() + "/" + day2.daysInMonth().toString() + "/" + day2.year().toString() + "");
        const somValue = moment("" + (day1.month() + 1).toString() + "/01/" + day1.year().toString() + "");
        const totalMonthsDiff = eomValue.diff(somValue, "months") + 1;
        const hours = Number(Number(role.roleRequestedHours / totalMonthsDiff).toFixed(2));
        let remaining = 0;
        if (hours * totalMonthsDiff !== role.roleRequestedHours) {
            remaining = Number(role.roleRequestedHours - (hours * totalMonthsDiff));
        }

        for (let i = 0; i < totalMonthsDiff; i++) {
            if (i > 0) {
                somValue.add(1, "month");
                eomValue.add(1, "month");
            }
            const tempSkillSchedulePattern: ISchedulePattern = {
                StartDate: somValue.format(DATE_FORMAT) + DATE_FORMAT2,
                EndDate: moment("" + (somValue.month() + 1).toString() + "/" + somValue.daysInMonth().toString() + "/" + somValue.year().toString() + "").format(DATE_FORMAT) + DATE_FORMAT2,
                ScheduleDuration: hours
            };
            tempSkillsSchedulePatternList.push(tempSkillSchedulePattern);
        }

        if (tempSkillsSchedulePatternList && tempSkillsSchedulePatternList.length) {
            tempSkillsSchedulePatternList[0].StartDate = day1.format(DATE_FORMAT) + DATE_FORMAT2;
            tempSkillsSchedulePatternList[tempSkillsSchedulePatternList.length - 1].EndDate = day2.format(DATE_FORMAT) + DATE_FORMAT2;
            if (remaining) {
                tempSkillsSchedulePatternList[tempSkillsSchedulePatternList.length - 1].ScheduleDuration = Number(Number(tempSkillsSchedulePatternList[tempSkillsSchedulePatternList.length - 1].ScheduleDuration + remaining).toFixed(2));
            }
        }
        return tempSkillsSchedulePatternList;
    }

    /**
     * Builds the resource request
     *
     * @private
     * @param IRoleDetail
     * @returns ISchedulePattern[]
     * @memberof StaffingCommandBarComponent
     */
    private getResourceRequests(): IResourceRequest[] {
        const tempResourceRequestList: IResourceRequest[] = [];
        if (this.availableRoleDetails) {
            this.availableRoleDetails.forEach((newRole: IRoleDetail) => {
                if (newRole) {
                    let deliveryCountryCode: string;
                    let deliveryCountryName: string;
                    let deliveryState: string;
                    let deliveryCity: string;

                    // Only give resource work location if resource is remote and has all data for work country, state, and city
                    if (this.grmProjectDetails && this.grmProjectDetails.length && newRole.deliveryCountryCode && newRole.deliveryState && newRole.deliveryCity) {
                        deliveryCountryCode = this.selectedUser && newRole.deliveryType === RoleDeliveryType.Remote ? newRole.deliveryCountryCode : this.grmProjectDetails[0].CustomerCountryCode;
                        deliveryState = this.selectedUser && newRole.deliveryType === RoleDeliveryType.Remote ? newRole.deliveryState : this.grmProjectDetails[0].CustomerState;
                        deliveryCity = this.selectedUser && newRole.deliveryType === RoleDeliveryType.Remote ? newRole.deliveryCity : this.grmProjectDetails[0].CustomerCity;
                    } else {
                        deliveryCountryCode = this.grmProjectDetails[0].CustomerCountryCode;
                        deliveryState = this.grmProjectDetails[0].CustomerState;
                        deliveryCity = this.grmProjectDetails[0].CustomerCity;
                    }

                    const resourceReq: IResourceRequest = {
                        DeliveryTypeEnum: this.deliveryType,
                        IsPreApprovedProposal: true,
                        RequestedStartDate: moment(newRole.roleStartDate).format(DATE_FORMAT) + DATE_FORMAT2,
                        RequestedEndDate: moment(newRole.roleEndDate).format(DATE_FORMAT) + DATE_FORMAT2,
                        RequestedDuration: newRole.roleRequestedHours,
                        DurationUnitEnum: "Hours",
                        SchedulePatternUnitEnum: "Monthly",
                        Role: newRole.roleName,
                        DeliveryCountryCode: deliveryCountryCode,
                        DeliveryCity: deliveryCity,
                        IsContiguous: false,
                        RequestedResource: newRole.roleRequestedResource ? newRole.roleRequestedResource.userAlias : "",
                        AssignedResourceBPID: (newRole.roleRequestedResource && newRole.roleRequestedResource.bpId) ? newRole.roleRequestedResource.bpId : undefined,
                        Skills: this.getRoleSkills(newRole),
                        SchedulePatterns: this.getRoleSchedulePattern(newRole),
                        TestRequest: false,
                        RequestDomain: newRole.roleDomainName,
                        RolePartNumber: newRole.rolePartNumber,
                        IsAutoCommit: this.autoCommitRoleValues.filter((role) => role.rolePartNumber.trim() === newRole.rolePartNumber.trim()).length > 0 ? true : false,
                        Accreditations: [],
                        Certifications: [],
                        DeliveryCountryName: deliveryCountryName,
                        DeliveryState: deliveryState,
                        DeliveryPostalCode: this.grmProjectDetails ? this.grmProjectDetails[0].CustomerPostalCode : undefined,
                        IsClearanceRequired: this.sharedFunctionsService.isClearedRole(newRole.rolePartNumber, this.projectServiceFunctions.rolesValues),
                        IsScheduleFree: this.isResourceScheduleFree
                    };
                    tempResourceRequestList.push(resourceReq);
                }
            });
        }
        return tempResourceRequestList;
    }

    /**
     * Builds the resource request
     *
     * @private
     * @param IRoleDetail
     * @returns ISchedulePattern[]
     * @memberof StaffingCommandBarComponent
     */
    private getRoleSkills(role: IRoleDetail): ISkill[] {
        const tempSkillsList: ISkill[] = [];
        const grmTenantNameSkillOrgType = this.configManagerService.getValue<string>("GrmTenantNameSkillOrgType");
        if (role && role.roleSkills) {
            role.roleSkills.forEach((skill, key) => {
                const tempSkill: ISkill = {
                    OneProfileJobSkillId: skill.JobSkillId,
                    OneProfileSkillName: skill.JobSkillValue,
                    ProficiencyLevel: 3,
                    IsPrimary: key === 0,
                    TenantName: grmTenantNameSkillOrgType
                };
                tempSkillsList.push(tempSkill);
            });
        }
        return tempSkillsList;
    }

    /**
     * Invalidates Data so that parent component gets refreshed.     *
     * @private
     * @memberof AddRoleModalComponent
     */
    private invalidateData(): void {
        this.store.dispatch(new InvalidateWbsStructures(this.internalEngagementDetails.id));
        this.store.dispatch(new InvalidateEngagementDetails(this.internalEngagementDetails.id));
        this.store.dispatch(new InvalidateInternalFinancialDetails(this.internalEngagementDetails.id));
        this.store.dispatch(new InvalidateResourceRequests(this.internalEngagementDetails.id));
    }

    /**
     * Purpose of this promise is to get the skills and grace period, resolving in both then and catch cases as main objective is to get the api calls done.
     */
    private getSkillsandGracePeriod(): Promise<any> {
        return this.cacheService.get(
            CacheKeys.SkillsGracePeriod.KeyName,
            () => this.grmService.getSkillsGracePeriod(),
            CacheKeys.SkillsGracePeriod.Duration, CacheStorageOptions.LocalStorage)
            .then((response) => {
                if (response && response.DomainDataResult && response.DomainDataResult.GlobalParameters && response.DomainDataResult.GlobalParameters.length) {
                    const sillDuration = response.DomainDataResult.GlobalParameters.filter((param) => param.Parameter === "GrmSkillGraceDuration");
                    if (sillDuration && sillDuration.length) {
                        const gracePeriod = sillDuration[0].Value;
                        return this.oneProfileService.searchUserSkills(gracePeriod).catch((error) => {
                            const errorMessage: string = DmError.InternalEngagement.AddRole.FailedToRetrieveUsersSkills + response + ". ";
                            this.logError(SourceConstants.Method.GetSkillsandGracePeriod, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                            this.fxpMessageService.addMessage(errorMessage +
                                "Correlation ID: " + DataService.getCorrelationIdFromError(error), this.FXP_CONSTANTS.messageType.error);
                        });
                    }
                }
            })
            .catch((error) => {
                const errorMessage: string = "Failed to retrieve grace period for skills with grm service.";
                this.logError(SourceConstants.Method.GetSkillsandGracePeriod, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.fxpMessageService.addMessage(errorMessage + "Correlation ID: " + DataService.getCorrelationIdFromError(error), this.FXP_CONSTANTS.messageType.error);
                return this.oneProfileService.searchUserSkills(this.defaultGracePeriod)
                    .catch((err2) => {
                        const errorMessageTwo: string = DmError.InternalEngagement.AddRole.FailedToRetrieveUsersSkills + this.defaultGracePeriod.toString() + ". ";
                        this.logError(SourceConstants.Method.GetSkillsandGracePeriod, err2, errorMessageTwo, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        this.fxpMessageService.addMessage(errorMessageTwo + "Correlation ID: " + DataService.getCorrelationIdFromError(err2), this.FXP_CONSTANTS.messageType.error);
                    });
            });
    }

    /**
     * Purpose of this promise is to get the Financial Roles, resolving in both then and catch cases as main objective is to get the api calls done.
     */
    private getFinancialRoles(): Promise<any> {
        const domainValue = {
            domainValueName: AREAACCOUNTALIGNED_DOMAIN,
            mddDomainValueId: "450247"
        };
        return this.cacheService.get(CacheKeys.FinancialRoles.KeyName,
            () => this.projectServiceFunctions.getFinancialRoles(), CacheKeys.FinancialRoles.Duration, CacheStorageOptions.LocalStorage)
            .then((roles: IFinancialRoles[]) => {
                this.financialRoles = roles;
                this.filterRoleValues();
                if (this.roleDetails.roleDetailForEdit) {
                    this.additionalDetails = this.roleDetails.roleDetailForEdit.roleAdditionalDetails;
                    this.deliveryType = this.roleDetails.roleDetailForEdit.deliveryType;
                    this.domain = domainValue;
                    this.endDate = this.roleDetails.roleDetailForEdit.roleEndDate.toDate();
                    this.laborHours = this.roleDetails.roleDetailForEdit.roleRequestedHours;
                    this.roleSequence = this.roleDetails.roleDetailForEdit.roleSequence;
                    this.roleType = this.roleValues.filter((role: IFinancialRoles) => role.rolePartNumber === this.roleDetails.roleDetailForEdit.rolePartNumber)[0];
                    this.startDate = this.roleDetails.roleDetailForEdit.roleStartDate.toDate();
                    this.taRequestedResource = (this.roleDetails.roleDetailForEdit.roleRequestedResource && this.roleDetails.roleDetailForEdit.roleRequestedResource.bpId) ? this.roleDetails.roleDetailForEdit.roleRequestedResource : undefined;
                    this.selectedUser = (this.roleDetails.roleDetailForEdit.roleRequestedResource && this.roleDetails.roleDetailForEdit.roleRequestedResource.bpId) ? this.roleDetails.roleDetailForEdit.roleRequestedResource : undefined;
                    this.taRoleSkills = this.roleDetails.roleDetailForEdit.roleSkills;
                    this.engStartDate = this.startDate;
                    this.engEndDate = this.endDate;
                } else {
                    // Date object counts months from 0 to 11. January is 0. December is 11.
                    this.startDate = this.roleDetails.engStartDate ? this.roleDetails.engStartDate : undefined;
                    this.endDate = this.roleDetails.engEndDate ? this.roleDetails.engEndDate : undefined;
                    this.domain = domainValue;
                    // if (this.domain && this.domain.domainValueName === AREAACCOUNTALIGNED_DOMAIN) {
                    //     this.isSkillRequired = false;
                    // }

                }
                // if (this.domain && this.domain.domainValueName === AREAACCOUNTALIGNED_DOMAIN) {
                //     this.roleValues = this.filterOutFedRoles(this.roleValues);
                //     this.roleValues = this.financialRoles.filter((role) => this.resourceRolesService.isRoleAutoCommit(role, this.engTypeCode));
                // }
                return Promise.resolve();
            })
            .catch((error) => {
                this.logError(SourceConstants.Method.GetFinancialRoles, error, DmError.InternalEngagement.AddRole.FailedToRetrieveFinancialRoles, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.fxpMessageService.addMessage(DmError.InternalEngagement.AddRole.FailedToRetrieveFinancialRoles + "Correlation ID: " + DataService.getCorrelationIdFromError(error), this.FXP_CONSTANTS.messageType.error);
            });
    }

    private verifyIsUserPartOfSSOSSTGroup(): Promise<any> {
        this.showResourceScheduleField = false;
        this.isResourceScheduleFree = false;
        const apiInput: IUserMembership[] = [];
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        apiInput.push({
            alias: userAlias,
            groupIds: [this.saossoGroupId]
        });
        const cacheKey = CacheKeys.UserMembership.KeyName + JSON.stringify(apiInput);
        return this.cacheService.get(cacheKey, () => this.projectServiceFunctions.checkUserGroupMemberShip(apiInput), CacheKeys.UserMembership.Duration)
            .then((result: ICheckUserMembershipResponse[]) => {
                if (result.length > 0 && result[0].GroupMemberships && result[0].GroupMemberships.length > 0 && result[0].GroupMemberships.filter((group) => group.IsMember).length > 0) {
                    this.showResourceScheduleField = true;
                    this.isResourceScheduleFree = true;
                }
                return Promise.resolve();
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.VerifyIsUserPartOfSSOSSTGroup, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                Promise.resolve();
            });
    }

    /**
     * Enable Resource Billable based on engagement type.
     * SR-Out and ECIF Presales Show Billable and Disable.
     * Unit Based Resource field Enabled.
     * Other types Disabled and set to false.
     */
    private enableResourceBillableField(): void {
        if (this.engTypeCode === unitBasedCreationCode || this.engTypeCode.toString() === unitBasedCreationCode.toString() || this.engCreationCode === unitBasedCreationCode.toString()) {
            this.isResourceBillableFieldDisabled = false;
            this.isResourceBillable = ((this.roleDetailForEdit && this.roleDetailForEdit.isResourceBillable) || this.roleDetailForEdit === undefined);
        } else if (this.checkIfSrOutEngagement(this.engTypeCode) || this.sharedFunctionsService.isECIFPreSalesEngagementBasedOnCreationCode(this.engTypeCode.toString()) || this.sharedFunctionsService.isECIFPreSalesEngagementBasedOnTypeCode(this.engTypeCode.toString())) {
            this.isResourceBillableFieldDisabled = true;
            this.isResourceBillable = true;
        } else {
            this.isResourceBillableFieldDisabled = true;
            this.isResourceBillable = false;
        }
    }

    /**
     * Purpose of this to check if its a Sr-out type engagement
     */
    private checkIfSrOutEngagement(engTypeCode: number): boolean {
        return this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(engTypeCode.toString()) || this.sharedFunctionsService.isSrOutEngagementBasedOnTypeCode(engTypeCode.toString());
    }

    /**
     *  Checks for cleared roles and restricted roles and filters them from api response.
     * Also filters out federal roles as needed.
     */
    private filterRoleValues(): void {
        if (this.financialRoles && this.financialRoles.length) {
            this.roleValues = this.showOnlyClearedRoles()
                ? this.financialRoles
                : this.financialRoles.filter((roleObject: IFinancialRoles) => roleObject.isCleared !== true);
        }
        if (this.restrictedRoles && this.restrictedRoles.length) {
            this.roleValues = this.roleValues.filter((role: IFinancialRoles) => this.restrictedRoles.indexOf(role.roleName) < 0);
        }
        this.roleValues = this.filterOutFedRoles(this.roleValues);
    }

    /**
     * Filters out roles that are only designated for federal engagement types.
     * If engagement is of federal type, then nothing will be filtered.
     *
     * @memberof AddRoleModalComponent
     * @param roleValues the list of roles to be filtered
     * @return list of roles with the federal roles filtered out, if applicable
     */
    private filterOutFedRoles(roleValues: IFinancialRoles[] ): IFinancialRoles[] {
        if (!roleValues || this.isFedEngType) {
            return roleValues;
        }
        if (!this.isFedEngType){
            return roleValues.filter((x: IFinancialRoles) => this.federalOnlyRoles.indexOf(x.roleName) < 0);
        }
    }
}
