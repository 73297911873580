import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalV2Module } from "../../modals/dm-modal-v2/dm-modal-v2.module";
import { SyncEACToPlanNotificationComponentV2 } from "./sync-eac-to-plan-notification-v2.component";
import { OpenSynEACToPlanThresholdModule } from "../open-sync-eac-to-plan-threshold-dialog/open-sync-eac-to-plan-threshold-dialog.module";

@NgModule({
    declarations: [
        SyncEACToPlanNotificationComponentV2
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        DmModalV2Module,
        OpenSynEACToPlanThresholdModule
    ],
    providers: [
        DMLoggerService,
    ],
    exports: [
        SyncEACToPlanNotificationComponentV2
    ],
    entryComponents: [
        SyncEACToPlanNotificationComponentV2
    ]
})
export class SyncEACToPlanNotificationV2Module { }