import { Component, forwardRef, Inject, Input, Output, EventEmitter } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DeviceFactoryProvider, ErrorSeverityLevel } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { AccessibilityConstants, Components, PurchaseOrderType, SourceConstants } from "../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";
import moment from "moment";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IPOMonthlyTranasctions, IPOTransactionDetail } from "../../../common/services/contracts/poList.model.contract";
import { PurchaseOrderService } from "../../../common/services/po.service";
import { IPoLineitem, IPoList } from "../manage-suppliers-table-data/lineitems.contract";

@Component({
    selector: "dm-manage-suppliers-transaction-modal",
    templateUrl: "./manage-suppliers-transaction-modal.html",
    styleUrls: ["./manage-suppliers-transaction-modal.scss"]
})
export class ManageSuppliersTransactionModalComponent extends DmModalAbstract {
    @Input() public poDetails: IPoList;
    @Input() public poLineItemDetails: IPoLineitem;
    @Output() public onStatusModalClose: EventEmitter<void> = new EventEmitter<void>();
    public isLoadingPOTransactionDetails: boolean = true;
    public modalContent: IModal;
    public poTransactionDetails: any;
    public noTransactionsText: string = "There are no transactions available for this demand";
    public showOnlyInvoiced: boolean;
    public showOnlyBillable: boolean;
    public PurchaseOrderType = PurchaseOrderType;
    public accessibilityConstants = AccessibilityConstants;
    
    private poTransactionsApiResponse: IPOTransactionDetail[];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ProjectService) public projectService: ProjectService,
        @Inject(PurchaseOrderService) private purchaseOrderService: PurchaseOrderService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.Actuals);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Purchase Order Transaction Details",
            subTitle: `Supplier PO # ${this.poDetails.purchaseorderno}`
        };

        this.purchaseOrderService.getPOTransactionDetails(this.poDetails.purchaseorderno, this.poLineItemDetails.lineItem).then((response: IPOTransactionDetail[]) => {
            const sortedResponse = response.sort((a, b) => new Date(b.goodsReceiptPostDate).getTime() - new Date(a.goodsReceiptPostDate).getTime());
            this.poTransactionsApiResponse = sortedResponse;
            this.poTransactionDetails = this.mapPOTransactionsViewModel(sortedResponse);
            this.isLoadingPOTransactionDetails = false;
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "Unable to get the updates and saves po changes.");
            this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.isLoadingPOTransactionDetails = false;
        });
    }

    /**
     * Filters billable status transactions
     * TODO: needs to be refactored later
     * @param {boolean} showOnlyBillable
     * @memberof ManageSuppliersTransactionModalComponent
     */
    public filterBillableStatus(selectedValue: string): void {
        const posTransactions = this.poTransactionsApiResponse;
        let filteredBillableList: IPOTransactionDetail[];
        if (posTransactions && posTransactions.length) {
            if (selectedValue === "billable") {
                filteredBillableList = this.poTransactionsApiResponse.filter((m) => m.isBillable);
            } else if (selectedValue === "nonBillable") {
                filteredBillableList = this.poTransactionsApiResponse.filter((m) => !m.isBillable);
            }
            this.poTransactionDetails = selectedValue !== "all" ? this.mapPOTransactionsViewModel(filteredBillableList) : this.mapPOTransactionsViewModel(this.poTransactionsApiResponse);
        }
    }

    /**
     * Filters invoiced status transactions
     * TODO: needs to be refactored later
     * @param {boolean} showOnlyBillable
     * @memberof ManageSuppliersTransactionModalComponent
     */
    public filterInvoicedStatus(selectedValue: string): void {
        const posTransactions = this.poTransactionsApiResponse;
        let filteredInvoicedList: IPOTransactionDetail[];
        if (posTransactions && posTransactions.length) {
            if (selectedValue === "invoiced") {
                filteredInvoicedList = this.poTransactionsApiResponse.filter((m) => m.invoiceStatus);
            } else if (selectedValue === "notInvoiced") {
                filteredInvoicedList = this.poTransactionsApiResponse.filter((m) => !m.invoiceStatus);
            } else if (selectedValue === "partiallyInvoiced") {
                filteredInvoicedList = this.poTransactionsApiResponse.filter((m) => m.partialInvoiceStatus);
            }
            this.poTransactionDetails = selectedValue !== "all" ? this.mapPOTransactionsViewModel(filteredInvoicedList) : this.mapPOTransactionsViewModel(this.poTransactionsApiResponse);
        }
    }


    /**
     * Filters partial invoiced status
     *
     * @param {string} selectedValue
     * @memberof ManageSuppliersTransactionModalComponent
     */
    public filterPartialInvoicedStatus(selectedValue: string): void {
        const posTransactions = this.poTransactionsApiResponse;
        let filteredInvoicedList: IPOTransactionDetail[];
        if (posTransactions && posTransactions.length) {
            if (selectedValue === "partialInvoiced") {
                filteredInvoicedList = this.poTransactionsApiResponse.filter((m) => m.partialInvoiceStatus);
            } else if (selectedValue === "notPartialInvoiced") {
                filteredInvoicedList = this.poTransactionsApiResponse.filter((m) => !m.partialInvoiceStatus);
            }
            this.poTransactionDetails = selectedValue !== "all" ? this.mapPOTransactionsViewModel(filteredInvoicedList) : this.mapPOTransactionsViewModel(this.poTransactionsApiResponse);
        }
    }


    /**
     * Expand and collapse monthly group
     *
     * @param {IPOMonthlyTranasctions} item
     * @memberof ManageSuppliersTransactionModalComponent
     */
    public expandCollapse(item: IPOMonthlyTranasctions): void {
        item.isExpanded = !item.isExpanded;
    }

    /**
     * Closes modal and emits an event to notify notification component of closure.
     *
     * @memberof ManageSuppliersTransactionModalComponent
     */
    public closeStatusModal(): void {
        this.onStatusModalClose.emit();
        this.closeModal();
    }

    /**
     * Map transactions response to view model
     *
     * @private
     * @param {IPOTransactions[]} poTransactionsDetails
     * @return {*}  {IPOMonthlyTranasctions[]}
     * @memberof ManageSuppliersTransactionModalComponent
     */
    private mapPOTransactionsViewModel(poTransactionsDetails: IPOTransactionDetail[]): IPOMonthlyTranasctions[] {
        const poTransactions: IPOTransactionDetail[] = [];
        if (poTransactionsDetails && poTransactionsDetails.length) {
            for (const response of poTransactionsDetails) {
                const poTransaction: IPOTransactionDetail = {
                    poNumber: response.poNumber,
                    poLineItemId: response.poLineItemId,
                    isBillable: response.isBillable,
                    goodsReceiptPostDate: response.goodsReceiptPostDate,
                    goodsReceiptDocumentNumber: response.goodsReceiptDocumentNumber,
                    goodsReceiptQuantity: response.goodsReceiptQuantity,
                    goodsReceiptAmount: response.goodsReceiptAmount,
                    invoiceStatus: response.invoiceStatus,
                    invoicedQuantity: response.invoicedQuantity,
                    invoicedAmount: response.invoicedAmount,
                    partialInvoiceStatus: response.partialInvoiceStatus,
                    remainingInvoicedQuantity: response.remainingInvoicedQuantity,
                    remainingInvoicedAmount: response.remainingInvoicedAmount,
                    businessPartnerId: response.businessPartnerId,
                    resourceName: response.resourceName
                };
                poTransactions.push(poTransaction);
            }
        }
        const poMonthlyTransactions = this.groupEntriesByMonth(poTransactions);
        return poMonthlyTransactions;
    }


    /**
      * Groups transactions by month,year
      *
      * @private
      * @param {IPOTransactionDetail[]} poTransactions
      * @return {*}  {IPOMonthlyTranasctions[]}
      * @memberof ActualsTransactionModalComponent
      */
    private groupEntriesByMonth(poTransactions: IPOTransactionDetail[]): IPOMonthlyTranasctions[] {
        // this gives an object with dates as keys
        const reducedPOTransactions = poTransactions.reduce((groups, item) => {
            const monthYear = moment(item.goodsReceiptPostDate).format("MMM-YY");
            if (!groups[monthYear]) {
                groups[monthYear] = [];
            }
            groups[monthYear].push(item);
            return groups;
        }, {});

        // Add it to array with date as key
        const groupArrays = Object.keys(reducedPOTransactions).map((monthYear) => {
            return {
                monthYear,
                isExpanded: true,
                poTransactions: reducedPOTransactions[monthYear]
            };
        });
        return groupArrays;
    }
}

