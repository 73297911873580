import { Component, Inject, forwardRef } from "@angular/core";
import { DeviceFactoryProvider, FxpRouteService } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IContractType } from "../../../../common/services/contracts/project.service.contracts";
import {
    IEngagementDetailsApiV2,
    ITeamDetailsV2,
    IEngagementDetailsV2,
    IProjectDetailsV2,
} from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { IProjectListViewModel } from "../../../../common/services/contracts/project-list-model.contract";
import { IState } from "../../../../store/reducers";
import {
    RouteName,
    SourceConstants,
    Components,
    TooltipText,
    NoDataText,
    LogEventConstants,
    InlineSurveyDataList,
    FeedBackEntity,
    AccessibilityConstants,
} from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../common/error.constants";
import { FeedbackModalService } from "../../../../components/tiles/feedback-modal/feedback-modal.service";

@Component({
    selector: "dm-engagement-summary-project-v2",
    templateUrl: "./engagement-summary-project-v2.html",
    styleUrls: ["./engagement-summary-project-v2.scss"],
})
export class EngagementSummaryProjectV2Component extends DmComponentAbstract {
    public isConfidentialDeal: boolean = true;
    public isUsPubsec: boolean;
    public hasUnitBasedDemands: boolean;
    public isPublicSector: boolean;
    public projectList: IProjectListViewModel[] = [];
    public noProjectFoundText: string;
    public isPubSecEnabled: boolean;
    public RouteName =
    RouteName; /* Set without a type because we can't add type to the namespace */
    public wbsTooltipText: string = TooltipText.EBSState;
    public contractTypeList: IContractType[];
    public recRevTypeList: IContractType[];
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.ProjectDetails;
    public accessibilityConstants = AccessibilityConstants;
    public engagementDetails: IEngagementDetailsState;
    public areMXDRChangesEnabled = false;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider))
        public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ConfigManagerService)
        private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService)
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService)
        private storeDispatchService: StoreDispatchService,
        @Inject(EngagementDetailService)
        private engagementDetailService: EngagementDetailService,
        @Inject(forwardRef(() => FxpRouteService))
        private fxpRouteService: FxpRouteService,
        @Inject(FeedbackModalService)
        private feedbackModalService: FeedbackModalService
    ) {
        super(dmLogger, Components.EngagementSummaryProjects);
    }

    public ngOnInit(): void {
        this.configManagerService.initialize().then(() => {
            this.isPubSecEnabled =
                this.configManagerService.isFeatureEnabled("Pubsec");
            this.areMXDRChangesEnabled = this.configManagerService.isFeatureEnabled("enableMXDRChanges");
            this.noProjectFoundText = NoDataText.NoProjects;
            this.contractTypeList = this.configManagerService.getValue<
            IContractType[]
            >("projEngContractType");
            this.recRevTypeList =
                this.configManagerService.getValue<IContractType[]>(
                    "projEngRecRevType"
                );
            this.tileContent = {
                title: "Projects",
                itemsCount: 0,
            };
            const selectedId: string =
                this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
            const engagementDetails$ = this.store.select(
                getEntireEngagementDetails(selectedId)
            );

            this.storeDispatchService
                .requireEngagementDetails(selectedId, true)
                .load();

            engagementDetails$
                .pipe(untilDestroyed(this))
                .subscribe((engagementDetails: IEngagementDetailsState) => {
                    this.engagementDetails = engagementDetails;
                    /* Listen for invalidation that indicates an internal refresh. This will refresh the data and manage telemetry if the event is detected. 
You no longer need to listen for the refresh event off of FXP Broadcast Service*/
                    this.refreshOnItemInvalidation(engagementDetails);

                    /* Sets the loaders if any of the provided items are in the loading state. This will also turn off any loaders once the items are ready. 
You don't need to set any loaders except for rare edge cases.
Make sure that the UI is using the variable called isComponentLoading. */
                    this.setLoadersBasedOnItemState(engagementDetails);

                    /* Sets the error messages into the existing dm-loader component if any of the provided items have an error. This will toggle the loaders in the
way that displays the errors. You don't need to set any errors or loaders except for rare edge cases. 
Make sure that the UI is using the variables called showLoading and errorText. */
                    this.setErrorsBasedOnItemState(engagementDetails);

                    if (engagementDetails.loaded) {
                        const engagementFullDetails: IEngagementDetailsV2 = {
                            ...engagementDetails.engagementDetails,
                        };
                        engagementFullDetails.projects =
                            engagementDetails.engagementDetails.projects.filter(
                                (project: IProjectDetailsV2) =>
                                    !this.sharedFunctionsService.isProjectReadOnly(project)
                            );
                        this.isConfidentialDeal = engagementFullDetails.isConfidential;
                        this.hasUnitBasedDemands =
                            engagementFullDetails.hasUnitBasedDemands;
                        this.isUsPubsec = this.sharedFunctionsService.verifyIsUsPubSec(
                            engagementFullDetails.publicSectorCode
                        );
                        this.isPublicSector = engagementFullDetails.isPublicSector;
                        this.projectList = this.loadProjectList(engagementFullDetails);
                        this.tileContent.itemsCount = this.projectList.length;
                    }
                    if (engagementDetails.error) {
                        this.isServerError = true;
                    }
                });
        });
    }

    /**
* Logs an event when employee link is clicked
* @param property
*/
    public logEmployeeClick(): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.EngagementSummaryV2Page,
            SourceConstants.Method.LogEmployeeClick,
            LogEventConstants.EmployeeLinkClick
        );
    }

    /**
* Logs an event when the project link is clicked
*/
    public logProjectClick(): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.EngagementSummaryV2Page,
            SourceConstants.Method.LogProjectClick,
            LogEventConstants.ProjectNavigation
        );
    }

    /**
* Opens summary v2 survey modal
*/
    public openSummaryV2FeedbackModal(): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.EngagementSummaryV2Page,
            SourceConstants.Method.OpenSummaryV2FeedbackModal,
            LogEventConstants.SummaryV2FeedbackClick
        );
        this.feedbackModalService.openFeedbackModal(
            InlineSurveyDataList.SummaryV2Survey,
            FeedBackEntity.SummaryV2,
            SourceConstants.Component.EngagementSummaryV2Page
        );
    }

    /**
* Loads the project list by parsing the engagementDetails object and creating a new project list view model object array.
*/
    private loadProjectList(
        engagementDetails: IEngagementDetailsApiV2
    ): IProjectListViewModel[] {
        const projectList: IProjectListViewModel[] = [];
        if (engagementDetails && engagementDetails.projects) {
            const relationshipManagerObj: ITeamDetailsV2[] =
                this.sharedFunctionsService.getPjmInfoL0("RELMAN", engagementDetails);
            let relationshipManagerAlias: string;
            let relationshipManagerName: string;
            if (relationshipManagerObj && relationshipManagerObj[0]) {
                relationshipManagerAlias = relationshipManagerObj[0].alias;
                relationshipManagerName = relationshipManagerObj[0].name;
            }
            const L0PjmObj: ITeamDetailsV2[] =
                this.sharedFunctionsService.getPjmInfoL0("PPJM", engagementDetails);
            let pPjMAlias: string = L0PjmObj[0].alias;
            let pPjMName: string = L0PjmObj[0].name;
            if (L0PjmObj && L0PjmObj[0]) {
                pPjMAlias = L0PjmObj[0].alias;
                pPjMName = L0PjmObj[0].name;
            }

            for (const project of engagementDetails.projects) {
                let pjMAlias: string;
                let pjMName: string;
                const L1PjmObj: ITeamDetailsV2[] =
                    this.sharedFunctionsService.getPjmInfoL1("PJM", project);
                if (L1PjmObj && L1PjmObj[0]) {
                    pjMAlias = L1PjmObj[0].alias;
                    pjMName = L1PjmObj[0].name;
                }

                projectList.push({
                    name: project.name,
                    domain: project.primaryDomain,
                    description: project.description,
                    status: project.statusDescription,
                    contractType: project.contractType,
                    actualStartDate: project.startDate,
                    actualEndDate: project.endDate,
                    hasUnitBasedDemands: project.hasUnitBasedDemands,
                    originalStartDate: project.originalStartDate,
                    originalEndDate: project.originalEndDate,
                    projectId: project.id,
                    pjMAlias,
                    pjMName,
                    pPjMAlias,
                    pPjMName,
                    relationshipManagerAlias,
                    relationshipManagerName,                    
                    typeColorCode: this.engagementDetailService.getTypeColorCode(
                        this.contractTypeList,
                        project.contractType
                    ),
                    recRevType: this.sharedFunctionsService.getRecRevType(project.contractType, project.userStatusCode, project.projectTypeCode),
                    currentStatus: project.currentStatus
                    

                });
            }
        }
        return projectList;
    }
}
