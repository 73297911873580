import { Component, Input, Inject, forwardRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FxpRouteService } from "@fxp/fxpservices";
import { IProjectDetails } from "../../../common/services/contracts/project.service.contracts";
import { IProjectDetailsPopup } from "../../../common/services/contracts/staffing-details.contract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { Components, AccessibilityConstants } from "../../../common/application.constants";
import { IProject, IWbsStructure } from "../../../common/services/contracts/wbsStructures.contracts";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IResourceRequestResponse } from "../../../common/services/contracts/staffing.service.contract";

@Component({
    selector: "resource-management-modal",
    templateUrl: "./resource-management-modal.html",
    styleUrls: ["./resource-management-modal.scss"],
})
export class ResourceManagementModalComponent extends DmModalAbstract {
    @Input() public projectDetails: IProject[] | IProjectDetails[]; // type depends on if calling v1 (IProjectDetails) or v2 (IProject)
    @Input() public grmProjectDetails: IResourceRequestResponse;
    @Input() public engagementStructureDetails: IWbsStructure;
    public selectedProjectDetails: IProjectDetailsPopup;
    public accessibilityConstants = AccessibilityConstants;
    public isUsPubSecDeal: boolean = false;

    public constructor(
        @Inject(forwardRef(() => FxpRouteService)) public fxpRouteService: FxpRouteService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.ResourceManagement);
    }

    public ngOnInit(): void {
        this.isUsPubSecDeal = this.engagementStructureDetails && this.engagementStructureDetails.isPublicSector && this.engagementStructureDetails.publicSectorCode === "001";
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }
}
