import { IRoleDomainSkill } from "./one-profile.contracts";
import { ISelectedUserAttributes } from "../../../components/tiles/type-ahead/type-ahead-contracts";

export interface IRoleValues {
    roleName: string;
    rolePartNumber: string;
}
export interface IDomainValues {
    domainValueName: string;
    mddDomainValueId: string;
}

export interface IProjectChargeTypes {
    chargeAccountNumber: string;
    chargeAccountName: string;
}

export interface ICustomerEngagementDomain {
    SDODomainId: number;
    ServiceDeliveryOrganizationName: string;
    IsActive: boolean;
    OrgType: string;
}

export interface IRoleDetail {
    deliveryType: string;
    JobSkillValue?: string;
    isClearedRole?: boolean;
    isSpecialRole?: boolean;
    roleAccordionFlag?: any;
    roleAdditionalDetails?: string;
    roleDomainName: string;
    roleDomainId?: string;
    roleEndDate: string;
    roleName: string;
    rolePartNumber: string;
    roleRequestedHours: number;
    roleRequestedResource?: ISelectedUserAttributes;
    roleSequence: number;
    roleSkills?: IRoleDomainSkill[];
    roleStartDate: string;
    errorDetails?: any;
    temporaryId?: string;
    isRoleFromExcelUpload?: boolean;
    isResourceScheduleFree?: boolean;
    isResourceBillable: boolean;
    deliveryCity?: string;
    deliveryState?: string;
    deliveryCountryCode?: string;
}

export interface IRoleSapInputObject {
    role: string;
    startDate: string;
    endDate: string;
    requestedHours: number;
}

export interface IEngagementSapRoleInput {
    engagementId: string;
    taskId: string;
}

export interface IAddRoleSapInputObject {
    roleData: IRoleSapInputObject[];
    engagementDetails: IEngagementSapRoleInput;
}

export interface IRoleDetails { // dupe name
    addedRoleDetails: IRoleDetail[];
    engEndDate: Date;
    engStartDate: Date;
    engType: string;
    projectDomain: string;
    roleDetailForEdit: IRoleDetail;
    selectedCompanyCode: string;
}

export interface ITaskDetails {
    taskId: string;
    taskName: string;
}

export enum RoleDeliveryType {
    Remote = "Remote",
    Onsite = "Onsite"
}
