<div class="col-md-12 col-sm-12 col-lg-12 dm-p-b-40">
    <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'calc(100vh - 125px)'" [tileHeight]="'100%'"
        [isV2Tile]="true" [showHeader]="false">
        <dm-loading loader [isCustomText]="true" [loadingText]="loadingAmendmentsText" [showLoading]="showLoading"
            *ngIf="isComponentLoading && !isServerError">
        </dm-loading>
        <ng-container *ngIf="!isComponentLoading && !isServerError">
            <div class="amendment-info dm-p-12 dm-m-b-12">
                <div class="dm-detail__value amendment-title dm-p-b-5">
                    Amendment ID: {{crNumber ? 'CR '+crNumber : '-'}}
                </div>
                <div class="display-flex">
                    <div class="dm-p-r-imp-12">
                        <span class="dm-detail__label dm-p-r-5">Status</span>
                        <span class="dm-detail__value">{{status | dmDisplayDashIfEmptyOrNull}}</span>
                    </div>
                    <div class="dm-p-r-imp-12">
                        <span class="dm-detail__label dm-p-r-5">Description</span>
                        <span class="dm-detail__value">{{crDescription | dmDisplayDashIfEmptyOrNull}}</span>
                    </div>
                    <div class="dm-p-r-imp-12">
                        <span class="dm-detail__label dm-p-r-5">Revenue</span>
                        <span class="dm-detail__value">{{currentFinancialDetails.revenue |
                            dmDisplayCurrencyOrDash}}</span>
                    </div>
                    <div class="dm-p-r-imp-12">
                        <span class="dm-detail__label dm-p-r-5">Cost</span>
                        <span class="dm-detail__value">{{currentFinancialDetails.cost | dmDisplayCurrencyOrDash}}</span>
                    </div>
                    <div class="dm-p-r-imp-12">
                        <span class="dm-detail__label dm-p-r-5">Labor (hours)</span>
                        <span class="dm-detail__value">{{currentFinancialDetails.labor ? (currentFinancialDetails.labor
                            | number : '1.0-2'): '-'}}</span>
                    </div>
                    <div class="dm-p-r-imp-12">
                        <span class="dm-detail__label dm-p-r-5">Currency</span>
                        <span class="dm-detail__value">{{currency | dmDisplayDashIfEmptyOrNull}}</span>
                    </div>                    
                </div>
            </div>
            <a [uiSref]="backToFinancialsRouteName" [uiParams]="backToFinancialsRouteParams"
                title="Back to all Amendments" class="dm-link" role="link">
                <span class="icon icon-Back dm-p-r-5"></span>
                Back to all Amendments
            </a>
            <div class="display-flex dm-p-l-20 dm-m-t-imp-30">
                <button class="dm-transparent-btn dm-p-r-imp-12" [disabled]="!selectedAmendmentPair"
                    [ngClass]="{'checked-btn dm-p-5': selectedAmendmentPair}" (click)="openChangeExistingDemandModal()">
                    <span class="icon-full icon-rows-child dm-p-r-5"></span>
                    <span>Change existing demand</span>
                </button>
                <button class="dm-transparent-btn dm-p-r-imp-12" [ngClass]="{'dm-p-5': selectedAmendmentPair}" [disabled]="!isValidNewDemandScenario()"
                    (click)="changePairToNewDemand()">
                    <span class="icon icon-associate dm-p-r-5"></span>
                    <span>Convert to new demand</span>
                </button>
                <button class="dm-transparent-btn dm-p-r-imp-12" [ngClass]="{'checked-btn dm-p-5': selectedAmendmentPair}" [disabled]="!selectedAmendmentPair || newDemandSet || disableSplitLineItem()"
                    (click)="splitDemand()">
                    <span class="icon-full icon-split dm-p-r-5"></span>
                    <span>Split demand</span>
                </button>
                <button class="dm-transparent-btn dm-p-r-imp-12" [ngClass]="{'dm-p-5': selectedAmendmentPair}" [disabled]="!isSplittedLineItem()"
                (click)="DeleteSplittedDemand()">
                    <span class="icon icon-delete dm-p-r-5"></span>
                    <span>Delete</span>
                </button>
            </div>
            <div class="dm-p-5 message dm-flex-align-center" *ngIf="showNotificationBanner">
                <span class="icon icon-CheckMark icon-rounded"></span>
                <span>{{notificationBannerMessage}}</span>
                <span class="icon icon-close margin-left-auto" (click)="showNotificationBanner=false"></span>
            </div>
            <div role="grid" class=" dm-p-l-20">
                <div role="rowgroup" class="dm-grid-view-table amendment-details-table">
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="columnheader"
                            class="dm-grid-view-table__headerCell text-align-left dm-p-x-0-y-imp-12">
                            <span class="icon set-focus dm-p-r-10" role="button" tabindex="0"
                                (click)="expandAllProjects()" (keyup.enter)="expandAllProjects()"
                                (keyup.Space)="expandAllProjects()"
                                [title]="isEbsExpanded ? 'Collapse expanded project' : 'Expand collapsed project'"
                                [ngClass]="{'icon-ChevronDown': isEbsExpanded, 'icon-chevron-right': !isEbsExpanded}"
                                [attr.aria-expanded]="isEbsExpanded"></span>
                            EBS
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-12">
                            Originator
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-12">
                            Role
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-12">
                            Demand Id
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            <span>
                                Planned/Staffed Labor (hr)
                                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                                    [dmTriggers]="'hover'" [ngbTooltipContent]="' CR line items will show the requested change of hours from CompassOne amendment against the CFP Planned hours.'"
                                    [dmIcon]="'icon icon-info'"
                                    ariaLabelToolTip="' CR line items will show the requested change of hours from CompassOne amendment against the CFP Planned hours.'">
                                </dm-tooltip>
                            </span>
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            Billing Role Id
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            Resource Location
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            Cost Rate ({{currency}}/hr)
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            Add'l Cost ({{currency}})
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            Bill Rate ({{currency}}/hr)
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            Add'l Revenue ({{currency}})
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell dm-p-x-0-y-imp-12">
                            Actions
                        </div>
                    </div>
                </div>
                <div class="overflow-content">
                    <div role="rowgroup" class="dm-grid-view-table amendment-details-table">
                        <div class="dm-grid-view-table__body">
                            <div *ngFor="let project of misalignedAmendmentsObject" class="dm-m-b-12">
                                <div role="row" class="dm-grid-view-table__row">
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12 project-details dm-flex-align-center">
                                        <span class="icon set-focus dm-p-r-10" role="button" tabindex="0"
                                            (click)="expandCollapseProjects(project)"
                                            (keyup.enter)="expandCollapseProjects(project)"
                                            (keyup.Space)="expandCollapseProjects(project)"
                                            [title]="project.isExpand ? 'Collapse expanded project' : 'Expand collapsed project'"
                                            [ngClass]="{'icon-ChevronDown': project.isExpand, 'icon-chevron-right': !project.isExpand}"
                                            [attr.aria-expanded]="project.isExpand"></span>
                                        <div>
                                            <div>
                                                <span class="dm-detail__value dm-m-r-4">Project {{project.projectName}}</span>
                                                <span class="icon icon-close icon-rounded dm-error" *ngIf="!areProjectErrorsResolved(project)"></span>
                                                <span style="font-weight: 400;color: red;" *ngIf="!areSAPDemandsPresentOnSplit(project)">The demand(s) from SAP should be present.</span>
                                                <span style="font-weight: 400;color: red;" *ngIf="IsDuplicateDemandPresent(project)">Two or more rows have same demand.</span>
                                                <span class="icon icon-check icon-rounded dm-success icon-size-7" *ngIf="areProjectErrorsResolved(project) && project.hasBeenSaved" title="The changes have been validated and saved and is ready for submission"></span>
                                            </div>
                                            <div>
                                                ID: {{project.projectId}}
                                            </div>
                                        </div>
                                    </div>
                                    <div role="gridcell"
                                        class="dm-p-12 dm-flex-align-center">
                                        <button type="button" class="dm-btn--primary dm-btn dm-m-imp-0" title="Save"
                                            [ngClass]="{'disabled-class': saveButtonDisabled(project)}"
                                            [disabled]="saveButtonDisabled(project)"
                                            (click)="saveMisalignedAmendmentChangesClicked(project)">
                                            <span class="dm-btn--text">Save</span>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="project.isExpand">
                                    <div class="amendments">
                                        <div *ngFor="let pair of project.pairedDemands" class="amendments-grid">
                                            <dm-misaligned-amendment-lineitems 
                                                [pairId]="pair.pairId" [projectType]="project.projectType" 
                                                [originalAmendment]="pair.originalAmendment" [misalignedAmendment]="pair.misalignedAmendment" 
                                                [taskIds]="project.availableTaskIds" [existingDemands]="allDemandDetails"
                                                [engagementSubmitted]="engagementLevelSubmitted" [hasEditAccess]="userHasEditAccess(project)" 
                                                [isSplittedLineItem]="pair.splittedLineItem"
                                                [isSplitOptionDisabled]="project.isSplitDemandDisabled"
                                                (amendmentPairSelected)="amendmentPairSelected($event)"
                                                (newExistingDemandAssigned)="onNewExistingDemandAssigned($event)"
                                                (onChange)="onLineitemChange($event)"
                                                (splitDemand)="splitDemand($event)"
                                                (deleteSplittedDemand)="DeleteSplittedDemand($event)"
                                                (changeExistingDemand)="openChangeExistingDemandModal($event)">                                            
                                            </dm-misaligned-amendment-lineitems>
                                        </div>
                                    </div>
                                    <div role="row" class="dm-grid-view-table__row total dm-m-b-5">
                                        <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell dm-p-12 total-grid text-align-right">
                                                CompassOne Original Totals:
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell dm-p-12 dm-p-l-imp-12 planned-labor-total-grid">
                                                {{calculatePlannedLabor(project, true) | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}} hrs
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12 additional-cost-total-grid">
                                                {{calculateAddtlCost(project, true) | currency: currency :
                                                    'symbol-narrow'}}
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell dm-p-12 dm-p-l-imp-12 additional-rev-total-grid">
                                                {{calculateAddtlRevenue(project, true) | currency: currency :
                                                    'symbol-narrow'}}
                                            </div>

                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell dm-p-12 total-grid text-align-right">
                                            Updated Totals:
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell dm-p-12 dm-p-l-imp-12 planned-labor-total-grid">
                                            {{calculatePlannedLabor(project, false) | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}} hrs
                                            <div *ngIf="IsTotalNotMatching(project)" style="display:inline-block;" class="error-message-section error-msg">
                                                <span class="dm-m-r-4 icon icon-close error-message-section__message"></span>
                                                <span style="font-weight: 400;">Totals should match</span>
                                            </div>
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12 additional-cost-total-grid">
                                            {{calculateAddtlCost(project, false) | currency: currency :
                                                'symbol-narrow'}}
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell dm-p-12 dm-p-l-imp-12 additional-rev-total-grid">
                                            {{calculateAddtlRevenue(project, false) | currency: currency :
                                                'symbol-narrow'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-btn dm-m-b-40">
                <button type="button" class="dm-btn--primary dm-btn dm-m-imp-0" title="Submit amendment details"
                    [ngClass]="{'disabled-class': submitButtonDisabled()}" (click)="submitEngagementLevelUpdates()"
                    [disabled]="submitButtonDisabled()">
                    <span class="dm-btn--text">Submit Changes</span>
                </button>
                <button type="button" class="dm-btn" title="Cancel amendment update" (click)="goBackToAllAmendments()">
                    <span class="dm-btn--text">Cancel</span>
                </button>
            </div>
        </ng-container>
    </dm-tile>
</div>