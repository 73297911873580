<section class="split-planned-dailog">
    <label class="sr-only">Split Request</label>
    <section class="modal-header">
        <button type="button" class="close set-focus" (click)="closeModal()"
            (keydown)="moveFocusPrev($event, 'accessibilityConstants.SplitPlannedSubmitRequest')"
            id="accessibilityConstants.ClosePopUp" aria-label="Split Request Dialog close">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            Split Request
        </h4>
    </section>
    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [errorText]="errorSummaryText"
        [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>
    <section *ngIf="!isLoading" class="modal-body">
        <form #splitPlanned>
            <section class="content-wrapper">
                <h5 class="sub-heading font-caption-alt">Planned Resource</h5>
                <section class="planned-resource">
                    <section class="row planned-resource__content">
                        <section class="col font-caption-alt width-20">
                            <label>Item Type-ID</label>
                            <p>{{demandDetails.demandId}}</p>
                        </section>
                        <section class="col font-caption-alt  width-25">
                            <label>Role</label>
                            <p>{{demandDetails.roleDescription}}</p>
                        </section>
                        <section class="col font-caption-alt width-25">
                            <label>Planned Hours / Days</label>
                            <p>{{demandDetails.plannedDuration}} Hours</p>
                        </section>
                        <section class="col font-caption-alt width-15">
                            <label>Start Date</label>
                            <p>{{demandDetails.startDate | dmDisplayDateOrDashPipe}}</p>
                        </section>
                        <section class="col font-caption-alt width-15">
                            <label>End Date</label>
                            <p>{{demandDetails.endDate | dmDisplayDateOrDashPipe}}</p>
                        </section>
                    </section>
                </section>

                <section class="split-resource">
                    <h5 id="splitResourceHeading" class="sub-heading font-caption-alt">Spilt Resource Request</h5>
                    <section class="split-resource__content">
                        <table class="font-caption-alt" width="100%" aria-labelledby="splitResourceHeading">
                            <thead>
                                <tr class="dm-tile__table__thead">
                                    <th scope="col" class="text-left">Request ID</th>
                                    <th scope="col" class="text-left">Role</th>
                                    <th scope="col" class="text-left">Hours</th>
                                    <th scope="col" class="text-left">Start Date</th>
                                    <th scope="col" class="text-left">End Date</th>
                                    <th scope="col" class="text-left" aria-label="Delete Resource"></th>
                                </tr>
                            </thead>
                            <tbody class="grey_background">
                                <tr class="dm-tile__table__tr"
                                    *ngFor="let splitRole of roleDetails; let i = index;trackBy:splitRole?.id">
                                    <th scope="row" class="font-caption-alt text-left sr-id">
                                        <p [hidden]="!roleDetails[i].isAdded" class="input-group">
                                            <input type="text" id="assignmentid{{i}}" [ngClass]="'disabled'"
                                                name="assignmentid{{i}}"
                                                [attr.aria-label]="'Role '+roleDetails[i].assignmentId"
                                                [(ngModel)]="roleDetails[i].assignmentId" [readonly]="true"
                                                class="form-control font-caption-alt" tabindex="-1" />
                                        </p>
                                    </th>
                                    <td class="text-left text-capitilize sr-role">
                                        <div class="input-group">
                                            <section class="input__select--icon">
                                                <select id="role{{roleDetails[i].id}}" name="role{{roleDetails[i].id}}"
                                                    [attr.aria-label]="'Role Name' +roleDetails[i].roleDescription.roleName"
                                                    class="form-control font-caption-alt input__select input__select--big border-enable"
                                                    [ngModel]="roleDetails[i].roleDescription.roleName"
                                                    (ngModelChange)="onChangeRole($event, i)"
                                                    [disabled]="!roleDetails[i].isAdded"
                                                    [ngClass]="{'disabled':!roleDetails[i].isAdded}">
                                                    <option *ngFor="let role of roleValues | orderBy : 'roleName'"
                                                        [ngValue]="role.roleName">
                                                        {{role.roleName}}
                                                    </option>
                                                </select>
                                                <span class="icon icon-chevron-down pull-right"
                                                    aria-hidden="true"></span>
                                            </section>
                                        </div>
                                    </td>
                                    <td class="text-left sr-hour">
                                        <p class="input-group">
                                            <input type="number" id="hours{{roleDetails[i].id}}"
                                                name="hours{{roleDetails[i].id}}"
                                                [attr.aria-label]="'Hours '+roleDetails[i].requestedDuration"
                                                (ngModelChange)="onHoursEntered()"
                                                [(ngModel)]="roleDetails[i].requestedDuration"
                                                class="form-control font-caption-alt border-enable"
                                                [readonly]="!roleDetails[i].isAdded" 
                                                [disabled]="!roleDetails[i].isAdded" 
                                                [ngClass]="{'disabled':!roleDetails[i].isAdded}" placeholder="0" />
                                        </p>
                                    </td>
                                    <td class="text-left sr-date-wrapper">
                                        <dm-datepicker [modelDate]="roleDetails[i].startDate"
                                            [disabled]="!roleDetails[i].isAdded" [minDate]="demandDetails.startDate"
                                            [maxDate]="demandDetails.endDate"
                                            (onDateChange)="onStartDateChange($event, roleDetails[i])"
                                            ariaLabelButton="roleDetails[i].startDate{{i}}"
                                            ariaLabelCalendar="Start date{{i}}" placeholder="dd-mmm-yyyy"
                                            previosFousingElementId="hours{{roleDetails[i].id}}"></dm-datepicker>
                                    </td>
                                    <td class="text-left sr-date-wrapper">
                                        <dm-datepicker [disabled]="!roleDetails[i].isAdded"
                                            [modelDate]="roleDetails[i].endDate" [minDate]="demandDetails.startDate"
                                            [maxDate]="demandDetails.endDate"
                                            (onDateChange)="onEndDateChange($event, roleDetails[i])"
                                            ariaLabelButton="roleDetails[i].endDate{{i}}"
                                            ariaLabelCalendar="End date{{i}}" placeholder="dd-mmm-yyyy"
                                            previosFousingElementId="calenderBtn_roleDetails[i].startDate{{i}}">
                                        </dm-datepicker>
                                    </td>
                                    <td *ngIf="roleDetails[i].showDateValidationMessage" class="text-left sr-role">
                                        <span class="required-mark-8"
                                            aria-hidden="true">{{splitPlannedErrorMessages.AssignmentStartDatelessThanEndDate}}</span>
                                    </td>
                                </tr>
                                <!--New Roles to be added-->
                                <tr class="dm-tile__table__tr"
                                    *ngFor="let splitRole of newRoleDetails; let i = index;trackBy:splitRole?.id">
                                    <th scope="row" class="font-caption-alt text-left sr-id">
                                        <p class="input-group">
                                            <input type="text" id="newassignmentid{{i}}"
                                                [ngClass]="{'disabled':!newRoleDetails[i].isAdded}"
                                                [attr.aria-label]="'New Role' + i + newRoleDetails[i].assignmentId"
                                                name="newRoleDetails{{i}}" [(ngModel)]="newRoleDetails[i].assignmentId"
                                                [readonly]="true" class="form-control font-caption-alt" tabindex="-1" />
                                        </p>
                                    </th>
                                    <td class="text-left text-capitilize sr-role">
                                        <div class="input-group">
                                            <section class="input__select--icon">
                                                <select id="newrole{{newRoleDetails[i].id}}"
                                                    name="newrole{{newRoleDetails[i].id}}"
                                                    class="form-control font-caption-alt input__select input__select--big border-enable"
                                                    [ngModel]="newRoleDetails[i].roleDescription.roleName"
                                                    [attr.aria-label]="'New Role Name'+ i + newRoleDetails[i].roleDescription.roleName "
                                                    (ngModelChange)="onChangeRoleForNewRoles($event, i)"
                                                    [disabled]="!newRoleDetails[i].isAdded"
                                                    [ngClass]="{'disabled':!newRoleDetails[i].isAdded}">
                                                    <option *ngFor="let role of roleValues | orderBy : 'roleName'"
                                                        [ngValue]="role.roleName">
                                                        {{role.roleName}}
                                                    </option>
                                                </select>
                                                <span class="icon icon-chevron-down pull-right"
                                                    aria-hidden="true"></span>
                                            </section>
                                        </div>
                                    </td>
                                    <td class="text-left sr-hour">
                                        <p class="input-group">
                                            <input type="number" id="newhours{{newRoleDetails[i].id}}"
                                                name="newhours{{newRoleDetails[i].id}}"
                                                title="newhours{{newRoleDetails[i].id}}"
                                                (ngModelChange)="onHoursEntered()"
                                                [(ngModel)]="newRoleDetails[i].requestedDuration"
                                                class="form-control font-caption-alt border-enable"
                                                [readonly]="!newRoleDetails[i].isAdded" 
                                                [disabled]="!newRoleDetails[i].isAdded"
                                                [ngClass]="{'disabled':!newRoleDetails[i].isAdded}" />
                                        </p>
                                    </td>
                                    <td class="text-left sr-date-wrapper">
                                        <dm-datepicker [modelDate]="newRoleDetails[i].startDate"
                                            [disabled]="!newRoleDetails[i].isAdded" [minDate]="demandDetails.startDate"
                                            [maxDate]="demandDetails.endDate"
                                            [attr.aria-label]="'New Start Date '+i+ newRoleDetails[i].startDate"
                                            (onDateChange)="onStartDateChange($event, newRoleDetails[i])"
                                            ariaLabelButton="New Start date {{i}}"
                                            ariaLabelCalendar="New Start date {{i}}" placeholder="dd-mmm-yyyy"
                                            previosFousingElementId="newhours{{newRoleDetails[i].id}}"></dm-datepicker>
                                    </td>
                                    <td class="text-left sr-date-wrapper">
                                        <dm-datepicker [disabled]="!newRoleDetails[i].isAdded"
                                            [modelDate]="newRoleDetails[i].endDate" [minDate]="demandDetails.startDate"
                                            [maxDate]="demandDetails.endDate"
                                            [attr.aria-label]="'New End Date '+i+ newRoleDetails[i].endDate"
                                            (onDateChange)="onEndDateChange($event, newRoleDetails[i])"
                                            ariaLabelButton="New End date {{i}}" ariaLabelCalendar="New End date {{i}}"
                                            placeholder="dd-mmm-yyyy"
                                            previosFousingElementId="calenderBtn_New Start date {{i}}"></dm-datepicker>
                                    </td>
                                    <td *ngIf="newRoleDetails[i].showDateValidationMessage" class="text-left sr-role">
                                        <span class="required-mark-8"
                                            aria-hidden="true">{{splitPlannedErrorMessages.AssignmentStartDatelessThanEndDate}}</span>
                                    </td>
                                    <td class="sr-delete" *ngIf="newRoleDetails[i].isAdded">
                                        <button class="split-btn" title="Delete New Role {{i+1}}"
                                            [attr.aria-label]="'Delete New Role'+i"
                                            (click)="deleteRow(newRoleDetails[i].id)">
                                            <i class="icon icon-delete"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr role="presentation" class="total-hours font-caption-alt">
                                    <td colspan="2" class="text-right">
                                        <span class="font-bold pad-r-6">Total Hours: </span>
                                    </td>
                                    <td colspan="3">
                                        <span>{{totalHours}}</span>
                                        <span *ngIf="demandDetails.plannedDuration < totalHours" class="required-mark"
                                            role="alert" aria-atomic="true" aria-live="polite">
                                            <i class="icon-requestor icon-fielderror fielderror-icon">
                                            </i> Planned Hours Exceeded
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </section>
                <section class="still-split">
                    <span class="wrapper font-caption-alt">
                        <button (click)="addSplitRow(); logSplitFurther()" class="split-btn">
                            <i class="icon grphic-icons-font-split"></i> Split Further
                        </button>
                    </span>
                </section>
            </section>
            <section class="modal-footer footer-button">
                <button type="button" class="fxp-btn fxp-btn--secondary" (click)="closeModal()"
                    id="cancelRequest">Cancel</button>
                <button type="button" class="fxp-btn fxp-btn--primary"
                    id="accessibilityConstants.SplitPlannedSubmitRequest" [disabled]="isSubmitDisabled"
                    (click)="submitForSplit()" (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp')">
                    Submit
                </button>
                <label id="lblMessage" class="input__label" for="dm-id-suspendRequest">
                    <span class="required-mark" aria-hidden="true">{{lblMessage}}</span>
                    <span role="alert" *ngIf="splitInformation" class="sr-only">{{splitInformation}}</span>
                </label>
            </section>
        </form>
    </section>
</section>