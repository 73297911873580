<!--================================= original amendment ===================================-->
<div role="row" class="dm-grid-view-table__row demand-info demand-info-failed demand-info-new"
    *ngIf="originalAmendment">
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12 display-flex-imp">
        <div class="dm-p-l-imp-24">
            <div title="{{originalAmendment.taskDescription}}">
                {{originalAmendment.taskDescription}}
            </div>
            <div>
                {{originalAmendment.taskId | dmDisplayDashIfEmptyOrNull}}
                <span *ngIf="!originalAmendment.taskId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <div>{{originalAmendment.originator}}</div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <div>
            <span>
                {{originalAmendment.role.roleName | dmDisplayDashIfEmptyOrNull}}
                <span *ngIf="!originalAmendment.role.roleName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </span>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <div>
            {{originalAmendment.demandId | dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!originalAmendment.demandId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-12 dm-p-l-imp-12">
        <span>
            {{originalAmendment.plannedLabor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}/{{originalAmendment.staffedLabour | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!originalAmendment.plannedLabor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <span>
            {{originalAmendment.billingRoleId | dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!originalAmendment.billingRoleId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <span *ngIf="originalAmendment.resourceLocation">{{formatResourceLocation(originalAmendment.resourceLocation.companyCode,
            originalAmendment.resourceLocation.companyName) | dmDisplayDashIfEmptyOrNull}}</span>
        <span *ngIf="!originalAmendment.resourceLocation"
            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-12">
        {{originalAmendment.costRate | dmDisplayCurrencyOrDash}}
        <span *ngIf="!originalAmendment.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <!-- {{ originalAmendment.addtlCost | dmDisplayCurrencyOrDash}} -->
        -
        <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        {{originalAmendment.billRate | dmDisplayCurrencyOrDash}}
        <span *ngIf="!originalAmendment.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        <!-- Blank for FF -->
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-12">
        <!-- {{ originalAmendment.addtlRevenue | dmDisplayCurrencyOrDash}} -->
        -
        <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-12">
        <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
</div>
<!--================================= misaligned amendment ===================================-->
<div role="row" class="dm-grid-view-table__row demand-info demand-info-editable demand-info-edited"
    *ngIf="misalignedAmendment">
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12 display-flex-imp">
        <div>
            <div class="display-flex dm-p-l-5">
                <input name="demand" class="dm-m-t-imp-2 checkbox" (click)="misalignedAmendmentRadioSelected()"
                    aria-label="select the demand" id="demand" type="radio" [disabled]="editDisabled" />
            </div>
        </div>
        <div class="full-width">
            <div>
                <dm-dropdown-menu [id]="'dm-resources-ddl'" [showErrorBorder]="showSelectedTaskIdError()"
                    [ariaLabel]="'Task ID'" [options]="formattedTaskIds" [(value)]="selectedTaskId" [labels]="'key'"
                    (change)="onSelectedTaskIdChange()" [isDropDownV2]="true" [isDisabled]="editDisabled">
                </dm-dropdown-menu>
            </div>
            <div class="error-message-section error-msg" *ngIf="showSelectedTaskIdError()">
                <span class="dm-m-r-4 icon icon-close error-message-section__message">
                </span>
                <span>Task Id needs to match</span>
            </div>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <div>{{misalignedAmendment.originator}}</div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-t-8">
        <div>
            <dm-dropdown-menu [id]="'dm-resources-ddl'" [showErrorBorder]="showSelectedRoleError()"
                [ariaLabel]="'Resource Role'" [options]="formattedRoles | orderBy : 'roleName'" [(value)]="selectedRole" [labels]="'roleName'"
                (change)="onSelectedRoleChange(selectedRole)" [isDropDownV2]="true" [isDisabled]="editDisabled">
            </dm-dropdown-menu>
            <div class="error-message-section error-msg" *ngIf="showSelectedRoleError()">
                <span class="dm-m-r-4 icon icon-close error-message-section__message"></span>
                <span>Role needs to match</span>
            </div>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        <div>
            {{misalignedAmendment.demandId | dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!misalignedAmendment.demandId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-t-10">
        <input id="{{misalignedAmendment.taskId}}-planned-labor" min="0" type="number"
            class="demand-info--plannedLaborHours dm-p-l-5" [(ngModel)]="plannedLaborHours" aria-label="enter planned labor"
            (change)="onPlannedLaborChange()" [disabled]="editDisabled" />
        <div class="error-message-section error-msg" *ngIf="validatePlannedLaborWithCFP()">
            <span class="dm-m-r-4 icon icon-close error-message-section__message">
            </span>
            <span>{{plannedLaborErrorMessage}}</span>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-t-8">
        <ng-container>
            <dm-dropdown-menu [id]="'dm-resources-ddl'" [ariaLabel]="'Billing Role Id'"
                [options]="formattedBillingRoleIds" [(value)]="selectedBillingRole" [labels]="'id'"
                (change)="onSelectedBillingRoleIdChange()" [showErrorBorder]="showSelectedBillingRoleIdError()"
                [isDropDownV2]="true" [isDisabled]="editDisabled">
            </dm-dropdown-menu>
            <div class="error-message-section error-msg" *ngIf="showSelectedBillingRoleIdError()">
                <span class="dm-m-r-4 icon icon-close error-message-section__message">
                </span>
                <span>Billing Role Id needs to match</span>
            </div>
        </ng-container>        
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-t-8">
        <dm-dropdown-menu [id]="'dm-resources-ddl'" [ariaLabel]="'Resource Location'"
            [showErrorBorder]="showSelectedLocationError()" [options]="formattedLocationIds"
            [(value)]="selectedLocation" [labels]="'key'" (change)="onSelectedLocationChange()" [isDropDownV2]="true"
            [isDisabled]="editDisabled">
        </dm-dropdown-menu>
        <div class="error-message-section error-msg" *ngIf="showSelectedLocationError()">
            <span class="dm-m-r-4 icon icon-close error-message-section__message">
            </span>
            <span>Location needs to match</span>
        </div>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-12">
        {{misalignedAmendment.costRate | dmDisplayCurrencyOrDash}}
        <span *ngIf="!misalignedAmendment.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        {{ misalignedAmendment.addtlCost | dmDisplayCurrencyOrDash}}
        <span *ngIf="!misalignedAmendment.addtlCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-0-y-imp-12">
        {{misalignedAmendment.billRate | dmDisplayCurrencyOrDash}}
        <span *ngIf="!misalignedAmendment.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
        <!-- Blank for FF -->
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-12 dm-p-r-imp-0">
        {{ misalignedAmendment.addtlRevenue | dmDisplayCurrencyOrDash}}
        <span *ngIf="!misalignedAmendment.addtlRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
    </div>
    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-12 dm-p-r-imp-0 actions">
        <ng-container *ngIf="!editDisabled">
            <!--Do not show actions if project has been saved. These icons don't have the ability to be disabled-->            
            <span class="icon-full icon-rows-child dm-m-r-12 cursor-pointer" (click)="openChangeExistingDemandModal()"
                title="Associate this amendment to a different existing demand"></span>
            <span *ngIf="isValidNewDemandScenario()" class="icon icon-associate dm-m-r-12 cursor-pointer" (click)="createNewDemand()"
                title="Convert this amendment to a new demand"></span>
            <span class="icon-full icon-split dm-m-r-12 cursor-pointer" (click)="splitDemandFn()" *ngIf="!newDemandSet && !isSplitOptionDisabled"
                title="Split this demand"></span>
            <span class="icon-full icon-delete dm-m-r-12 cursor-pointer" (click)="deleteSplitDemandFn()" *ngIf="IsSplittedLineItem()"
                title="Delete"></span>
            <span class="icon icon-error icon-red" *ngIf="isAmendmentOrphaned()" title="This amendment is an orphan. You must associate this amendment with an existing demand or create a new demand."></span>
        </ng-container>
    </div>
</div>