import * as fromFinancialDetails from "./financial-details-v2.action";
import { IEntityFinancials } from "../../components/financial-mgmt/financial.model";
import { ILoadableState } from "../reducers";

export interface IFinancialDetailsV2State extends ILoadableState {
    financialDetails: IEntityFinancials;
}

export const initialState: IFinancialDetailsV2State = {
    financialDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromFinancialDetails.FinancialDetailsActionsV2): { [engagementId: string]: IFinancialDetailsV2State } {
    switch (action.type) {
        case fromFinancialDetails.FinancialDetailsV2ActionTypes.LOAD_FINANCIAL_DETAILS_V2: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromFinancialDetails.FinancialDetailsV2ActionTypes.LOAD_MANUAL_FINANCIAL_DETAILS_V2: {
            const newState = {...state};
            newState[action.engagementId] = { ...initialState, loading: true };
            return newState;
        }

        case fromFinancialDetails.FinancialDetailsV2ActionTypes.LOAD_FINANCIAL_DETAILS_V2_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                financialDetails: action.financialDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromFinancialDetails.FinancialDetailsV2ActionTypes.LOAD_FINANCIAL_DETAILS_V2_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromFinancialDetails.FinancialDetailsV2ActionTypes.INVALIDATE_FINANCIAL_V2_DETAILS: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}