<div class="cceac--template">
    <div>
        <p class="font-subtitle">{{percentageConsumed | number: '1.2-2'}}%</p>
    </div>
    <div class="cceac--graph">
        <div class="cceac" *ngIf="values">
            <span class="sr-only">graph</span>
            <div class="cceac--eacvalue" [hidden]="values.eac===undefined">
                <div class="cceac--consumed"></div>
            </div>
            <div class="cceac--currentFinancialPlan" [hidden]="values.currentFinancialPlan===undefined">
                <p class="magnifying-glass"></p>
            </div>
            <div class="cceac--deliveryBaseline" [hidden]="values.deliveryBaseline===undefined">
                <p class="diamond-narrow"></p>
            </div>
            <div class="cceac--contractBaseline" [hidden]="values.contractBaseline===undefined">
                <p class="talkbubble"></p>
            </div>
        </div>
    </div>    
    <div>
        <div class="col-md-5 col-xs-5 dm-p-imp-0">
          <p>
            <span class="cceac--reference cceac--reference--consumed"></span> Consumed
          </p>
        </div>
        <div class="col-md-7 col-xs-7 dm-p-imp-0">
          <p>
            <span class="cceac--reference cceac--reference--eac"></span> Estimate at Complete
          </p>
        </div>
      </div>
    <ul class="cceac--reference-list">
        <li>
          <div>
            <p class="magnifying-glass cceac--reference-list-item"></p>
            <p class="cceac--reference-list-text">Current Financial Plan</p>
          </div>
        </li>
        <li>
          <div>
            <p class="diamond-narrow cceac--reference-list-item"></p>
            <p class="cceac--reference-list-text">Delivery Baseline</p>
          </div>
        </li>
        <li>
          <div>
            <p class="talkbubble cceac--reference-list-item"></p>
            <p class="cceac--reference-list-text">Contract Baseline</p>
          </div>
        </li>
      </ul>
</div>