import { Component, Input, Inject, forwardRef } from "@angular/core";
import { UserProfileService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components, AccessibilityConstants } from "../../../common/application.constants";
import { IReturnedResourceRequest } from "../../../common/services/contracts/staffing.service.contract";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-returned-resource-modal",
    templateUrl: "./returned-resource-modal.html",
    styleUrls: ["./returned-resource-modal.scss"],
})
export class ReturnedResourceRequestComponent extends DmModalAbstract {

    @Input() public returnedResourceRequestList: IReturnedResourceRequest[];
    public noDatafoundText: string = "No details found";
    public AccessibilityConstants = AccessibilityConstants;


    public constructor(
        @Inject(forwardRef(() => UserProfileService)) public userProfileService: UserProfileService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
    ) {
        super(activeModal, dmLogger, Components.ReturnedResourceRequest);
    }

    public ngOnInit(): void {
        // Currently nothing to do here.
    }
}
