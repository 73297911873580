import { Component, Input, Inject, EventEmitter, Output, Injector, forwardRef } from "@angular/core";
import { NgbModal, NgbModalRef, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { Components, SourceConstants, LogEventConstants } from "../../common/application.constants";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { GlobalStore, IGlobalStore } from "redux-micro-frontend";

/*  Modal Popups */
import { AddRoleModalComponent } from "../new-internal-engagement/add-roles/modals/add-role-modal.component";
import { SuspendModalComponent } from "./staffing-command-bar-modals/suspend-modal/suspend-modal.component";
import { UnsuspendModalComponent } from "./staffing-command-bar-modals/unsuspend-modal/unsuspend-modal.component";
import { CancelModalComponent } from "./staffing-command-bar-modals/cancel-modal/cancel-modal.component";
import { TruncateModalComponent } from "./staffing-command-bar-modals/truncate-modal/truncate-modal.component";
import { CopyResourceModalComponent } from "./staffing-command-bar-modals/copyresource-modal/copyresource-modal.component";
import { RmModalComponent } from "./staffing-command-bar-modals/rm-modal/rm-modal.component";
import { SplitPlannedModalComponent } from "./staffing-command-bar-modals/split-planned-modal/split-planned-modal.component";
import { RoleRemovalService } from "./staffing-command-bar-modals/role-removal-service/role-removal.service";
import { SharedFunctionsService } from "./../../common/services/sharedfunctions.service";

/*  Contracts */
import { IAssignment, ISuspendData, IUnSuspendData } from "./staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { IProjectRequest, IResourceRequestForGRMInput } from "../../common/services/contracts/staffing.service.contract";
import { IStaffingPlannedRequestModel } from "../../common/services/contracts/staffing-filter.contracts";
import { ResourceStaffingService } from "../../common/services/resource-staffing.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IState } from "../../store/reducers";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../store/engagement-details/engagement-details.reducer";
import { IEngagementDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { LoadEngagementDetails } from "../../store/engagement-details/engagement-details.action";
import { FxpMessageService, FxpRouteService } from "@fxp/fxpservices";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmError } from "../../common/error.constants";

declare let require: any;
const validStatusForBulkMaintenance: string[] = ["Assigned", "Committed", "Complete"];
const validSubStatusForBulkMaintenance: string = "Ready";
@Component({
    selector: "staffing-command-bar",
    templateUrl: "./staffing-command-bar.html",
    styleUrls: ["./staffing-command-bar.scss"]
})
export class StaffingCommandBarComponent extends DmComponentAbstract {
    @Input() public selectedDemandList: ISuspendData[] | IUnSuspendData[] | any;
    @Input() public grmProjectDetails: IProjectRequest;
    @Input() public isStaffingScreenLoading: boolean;
    @Output() public isStaffingScreenLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public emitFilterCriteria: EventEmitter<IStaffingPlannedRequestModel> = new EventEmitter<IStaffingPlannedRequestModel>();
    @Output() public searchedText: EventEmitter<string> = new EventEmitter<string>();
    @Input() public isStaffingSearchResultsFound: boolean[] = [];
    
    public isInternalEngagement: boolean; // only need this to show the add role button on the modal

    // Enable while using V2
    public isBulkMaintenanceEnabled: boolean = false;
    public isResetFilterLinkVisible: boolean;
    public cmdbarSearch: string;
    public filterStartDate: Date;
    public filterEndDate: Date;
    public statusSelected: string[];
    public statusList = require("../../common/services/JSON/resourcesStatus.json").resourcesStatus;
    public dropdownSettings = {};
    public multiSelectStatusModel: any;
    public resourceRequestIds = ["555652", "555651"];
    private wbsId: string;
    private internalEngagementDetails: IEngagementDetailsV2;
    private globalStore: IGlobalStore;
    
    public constructor(
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) private stateService: StateService,
        @Inject(RoleRemovalService) private roleRemovalService: RoleRemovalService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Injector) private injector: Injector,
        @Inject(ResourceStaffingService) private resourceStaffingService: ResourceStaffingService,
        @Inject(Store) private store: Store<IState>,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService
    ) {
        super(dmLogger, Components.StaffingCommandBar);
    }

    public ngOnInit(): void {
        this.wbsId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);

        this.isBulkMaintenanceEnabled = this.configManagerService.isFeatureEnabled("enableBulkMaintenance");

        this.globalStore = GlobalStore.Get();
        if (!this.wbsId) {
            this.wbsId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
            /* In the case of an internal engagement, details are needed to use add role functionality.
            This will get the engagement from the store and then set info if it's internal or not. */
            this.getInternalEngagementDetailsFromStore(this.wbsId);
        }
        this.endLoader();


        this.filterStartDate = new Date();
        this.filterEndDate = new Date();

        // settings for multiselect drop-down
        this.dropdownSettings = {
            singleSelection: false,
            textField: "status",
            selectAllText: "All",
            unSelectAllText: "All",
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false
        };
    }

    /**
     * Checks if the given action is in the configured command bar actions list.
     *
     * @param {string} action
     * @returns {boolean}
     * @memberof RequestsGridComponent
     */
    public commandBarActionsContains(action: string): boolean {
        return this.resourceStaffingService.commandBarActionsContains(action);
    }


    /**
     * Sets Status model on selection of all the options in multi-select drop-down
     */
    public onSelectAll(item: string[]): void {
        this.setSelectedStatusModel(item);
    }

    /**
     * Sets Status model on deselection of all the options in multi-select drop-down
     */
    public onDeSelectAll(): void {
        this.statusSelected = [];
        this.setSelectedStatusModel(this.statusSelected);
    }

    /**
     * Sets Status model on selection of an option in multi-select drop-down
     */
    public onUserSelect(item: string): void {
        this.setSelectedStatusModel(item);
    }

    /**
     * Sets Status model on deselection of an option in multi-select drop-down
     */
    public onUserDeSelect(item: string): void {
        this.setSelectedStatusModel(item);
    }

    /**
     * Used to set the status model in multi-select drop-down
     */
    public setSelectedStatusModel(statusData: string | string[]): void {
        if (statusData && statusData.length > 1) {
            this.multiSelectStatusModel = statusData[0] + " + " + (statusData.length - 1) + " Selected";
        } else if (statusData.length === 1) {
            this.multiSelectStatusModel = statusData[0];
        } else if (statusData.length === 0) {
            this.multiSelectStatusModel = "";
        }
    }

    /**
     * Used to emit the text entered in the search text box
     */
    public searchTasks(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.SearchTasks, LogEventConstants.SearchStaffing);
        this.searchedText.emit(this.cmdbarSearch);
    }

    /**
     * Opens up modal PopUp for Add role
     *
     * @memberof StaffingCommandBarComponent
     */
    public loadAddRolePopup(): void {
        const modalRef: NgbModalRef = this.modalService.open(AddRoleModalComponent, {
            backdrop: "static",
            windowClass: "manage-wbs-modal edit-engagement add-role-int-eng",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.roleDetailForEdit = undefined;
        modalRef.componentInstance.addedRoleDetails = [];
        modalRef.componentInstance.isAddRoleCalledFromStaffing = true;
        modalRef.componentInstance.grmProjectDetails = this.grmProjectDetails;

        // todo temporarilly added these back until we upgrade to v2, then the details will be called in the modal component itself and won't need to be passed in here.
        modalRef.componentInstance.projectDomain = this.internalEngagementDetails.primaryDomain;
        modalRef.componentInstance.engType = this.internalEngagementDetails.engagementTypeDescription;
        modalRef.componentInstance.engTypeCode = this.internalEngagementDetails.engagementTypeCode;
        modalRef.componentInstance.engCreationCode = (this.internalEngagementDetails.projects && this.internalEngagementDetails.projects.length) ? this.internalEngagementDetails.projects[0].projectTypeCode : undefined;
        modalRef.componentInstance.selectedCompanyCode = this.internalEngagementDetails.companyCode;
        modalRef.componentInstance.internalEngagementDetails = this.internalEngagementDetails;
        modalRef.componentInstance.engStartDate = this.internalEngagementDetails.startDate;
        modalRef.componentInstance.engEndDate = this.internalEngagementDetails.endDate;
    }

    /**
     * Opens up modal PopUp for Suspend
     *
     * @memberof StaffingCommandBarComponent
     */
    public loadSuspendPopup(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadSuspendPopup, LogEventConstants.SuspendRoleCommandBar);
        const modalRef: NgbModalRef = this.modalService.open(SuspendModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.selectedDemandList;
        modalRef.componentInstance.wbsId = this.wbsId;
    }

    /**
     * Opens up modal PopUp for UnSuspend
     *
     * @memberof StaffingCommandBarComponent
     */
    public loadUnsuspendPopup(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadUnsuspendPopup, LogEventConstants.UnsuspendRoleCommandBar);
        const modalRef: NgbModalRef = this.modalService.open(UnsuspendModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.selectedDemandList;
        modalRef.componentInstance.wbsId = this.wbsId;
    }

    /**
     * Opens up modal PopUp for Request Role Maintenance
     *
     * @memberof StaffingCommandBarComponent
     */
    public LoadRquestRoleMaintenancePopup(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadRquestRoleMaintenancePopup, LogEventConstants.RequestMaintenanceCommandBar);
        const modalRef: NgbModalRef = this.modalService.open(RmModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.selectedDemandList;
        modalRef.componentInstance.wbsId = this.wbsId;
    }

    /**
     *
     * Opens up modal PopUp for cancel ResourceRequest
     * @memberof StaffingCommandBarComponent
     */
    public loadCancelPopup(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LoadCancelPopup, LogEventConstants.CancelRoleCommandBar);
        const modalRef: NgbModalRef = this.modalService.open(CancelModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.selectedDemandList;
        modalRef.componentInstance.wbsId = this.wbsId;
    }

    /**
     * Opens up modal PopUp for Remove Role
     *
     * @memberof StaffingCommandBarComponent
     */
    public removeRole(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.RemoveRole, LogEventConstants.RemoveRoleCommandBar);
        this.roleRemovalService.submitRemoveRole(this.selectedDemandList);
    }

    /**
     * Open truncate modal popup
     */
    public truncatePopup(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.TruncatePopup, LogEventConstants.TruncateRoleCommandBar);
        const modalRef: NgbModalRef = this.modalService.open(TruncateModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.selectedDemandList;
        modalRef.componentInstance.wbsId = this.wbsId;
        if (this.selectedDemandList && this.selectedDemandList.length > 0) {
            this.selectedDemandList.forEach((demand: IResourceRequestForGRMInput) => {
                if (demand.demandDetails) {
                    modalRef.componentInstance.requestEndDate = demand.demandDetails.endDate;
                }
            });
        }
    }

    /**
     * Open CopyRequest modal popup
     */
    public copyRequest(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.CopyRequest, LogEventConstants.CopyResourceRequestCommandBar);
        const modalRef: NgbModalRef = this.modalService.open(CopyResourceModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "manage-wbs-modal copy-request-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.selectedDemandList;
        modalRef.componentInstance.wbsId = this.wbsId;
    }

    /**
     * Logs Learn More Click to Azure App Insights
     */
    public logLearnMore(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.LogLearnMore, LogEventConstants.LearnMoreStaffing);
    }

    /**
     * Open Split planned modal popup
     */
    public splitPlannedPopup(): void { // ISplitData
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.SplitPlannedPopup, LogEventConstants.SpiltRoleCommandBar);
        const modalRef: NgbModalRef = this.modalService.open(SplitPlannedModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active splitPlanned",
            injector: this.injector
        });
        modalRef.componentInstance.assignmentList = this.selectedDemandList;
        modalRef.componentInstance.wbsId = this.wbsId;
    }

    /**
     * Used to set the filtered start date in the filter
     */
    public onStartDateChange(startDate: Date): void {
        this.filterStartDate = startDate;
    }

    /**
     * Used to set the filtered end date in the filter
     */
    public onEndDateChange(endDate: Date): void {
        this.filterEndDate = endDate;
    }

    /**
     * Used to set the entire filter criteria seleted in the filter and emit the object containing the filtered criteria
     */
    public applyFilter(filterDropdown: NgbDropdown): void {
        const filterCriteria = {
            statusSelected: this.statusSelected,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate
        };
        this.emitFilterCriteria.emit(filterCriteria);
        filterDropdown.close();
        this.resetFilter();
    }

    /**
     * Used to reset the entire filter
     */
    public resetFilter(): void {
        this.statusSelected = [];
        this.multiSelectStatusModel = "";
        this.filterStartDate = new Date();
        this.filterEndDate = new Date();
    }

    /**
     * Navigate to bulk maintenance react app
     *
     * @memberof StaffingCommandBarComponent
     */
    public navigateToBulkMaintenance(): void {  
        if (!this.selectedDemandList || this.selectedDemandList.length <= 0) {
            this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataMissing, "error", false);
            return;
        }      
        const bulkMaintenanceRouteName = "engagementBulkMaintenanceRequests";
        // Get the projectids of resources selected, they must be from same project
        const projectIds = Array.from(new Set(this.selectedDemandList.map((item: IAssignment) => item.projectDetails.id)));
        if (projectIds.length > 1) {
            this.fxpMessageService.addMessage(DmError.Staffing.RequestedResourcesMustBeOfSameProject, "error", true);
        } else {
            const isSelectedResourceRequestsValidState: boolean = this.checkStatusOfResourceRequests(this.selectedDemandList);
            if (isSelectedResourceRequestsValidState) {
                const selectedResourceIds = (this.selectedDemandList as IAssignment[]).map((c) => c.assignmentId);
                if (selectedResourceIds.length > 0) {
                    this.resourceRequestIds = [];
                    for (const id of selectedResourceIds) {
                        this.resourceRequestIds.push(id.toString());
                    }
                }
                this.fxpRouteService.navigatetoSpecificState(bulkMaintenanceRouteName, {
                    wbsId: this.wbsId,
                    resourceRequestIds: this.resourceRequestIds,
                    projectId: (this.selectedDemandList as IAssignment[])[0].projectDetails.id
                });
            } else {
                this.fxpMessageService.addMessage(DmError.Staffing.InvalidResourceRequestState, "error", true);
            }
        }
    }
    
    /**
     * Only Status of Assigned/Committed are valid for bulk maintenance  
     *
     * @param {string} selectedEngagementId
     * @memberof StaffingCommandBarComponent
     */
    private checkStatusOfResourceRequests(selectedDemandList: IAssignment[]): boolean {
        for (const assigmnentSelected of selectedDemandList) {
            if (validStatusForBulkMaintenance.indexOf(assigmnentSelected.assignmentStatus) < 0 || validSubStatusForBulkMaintenance.toLowerCase() !== assigmnentSelected.assignmentSubStatus.toLowerCase()) {
                return false;
            }
        }
        return true;
    }

    /**
     * Gets engagement details from the store based on the engagement ID. If the result is an internal engagement,
     * then the internal engagement details var is set, otherwise no other action is taken.
     * This info is used for the add role functionality; customer facing engagements don't need details in the staffing command bar.
     *
     * @param {string} selectedEngagementId
     * @memberof StaffingCommandBarComponent
     */
    private getInternalEngagementDetailsFromStore(selectedEngagementId: string) {
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(selectedEngagementId));
        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
            if (engagementDetails && !engagementDetails.loaded && !engagementDetails.loading) {
                this.store.dispatch(new LoadEngagementDetails(this.wbsId));
            }
            if (engagementDetails.loaded) {
                this.isInternalEngagement = this.sharedFunctionsService.isEngagementInternal(engagementDetails.engagementDetails);
                if (this.isInternalEngagement) {
                    this.internalEngagementDetails = engagementDetails.engagementDetails;
                }
                
                if (this.isBulkMaintenanceEnabled && !this.isInternalEngagement) {
                    // dispatch loaded engagement details to global store (consumed by react app)
                    const engagementDetailsAction = {
                        type: "LOAD_ENGAGEMENT_DETAILS_SUCCESS",
                        payload: {
                            engagementId: engagementDetails.engagementDetails.id,
                            engagementDetails: engagementDetails.engagementDetails
                        }
                    };
                    this.globalStore.DispatchAction("SampleApplication", engagementDetailsAction);  // Current react app name in FXP is SampleApplication
                }
            }
        });
    }
}


