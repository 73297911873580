<section class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
    <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'calc(100vh - 125px)'" [hasNonOverflowContent]="true">
        <section additionalActionsRight *ngIf="!isComponentLoading && !isServerError">         
            <button
                *ngIf="!isProjectContext && !isInternal && engagementStructureDetails && engagementStructureDetails.projects.length > 0"
                type="button" class="dm-button-text pull-right dm-tile--p-b-20"
                (click)="selectResourceManagementDetails()">
                <!--Select which project from the engagement you want to open in GRM-->
                {{viewProjectInGrmLinkText}}
            </button>
            <div *ngIf="isProjectContext && projectStructureDetails">
                <a *ngIf="isInternal  || showUSPubSecRMActions" 
                    [href]="'#/Delivery/compassPackage/'+ projectStructureDetails.compassOnePackageId+'/sapPackage/'+filteredProjectId"                
                    (click)="logGrmClick()" class="dm-link dm-link--big-m pull-right dm-tile--p-b-20">
                    {{viewProjectInGrmLinkText}}
                </a>    
                <a *ngIf="!isInternal && !showUSPubSecRMActions"                
                    [href]="'#/workbench/Requestor?SapProjectId='+filteredProjectId+'&C1PackageId='+ projectStructureDetails.compassOnePackageId"
                    (click)="logGrmClick()" class="dm-link dm-link--big-m pull-right dm-tile--p-b-20">
                    {{viewProjectInGrmLinkText}}
                </a>
            </div>
            <div *ngIf="isInternal && grmRequestList && grmRequestList.ProjectRequests && grmRequestList.ProjectRequests.length > 0">
                <a *ngIf="grmRequestList.ProjectRequests.length === 1 && grmRequestList.ProjectRequests[0].ProjectId && engagementStructureDetails.projects.length === 1"
                    [href]="'#/project/'+grmRequestList.ProjectRequests[0].ProjectId+'/false/Requestor'"
                    (click)="logGrmClick()" class="dm-link dm-link--big-m pull-right dm-tile--p-b-20">
                    {{viewProjectInGrmLinkText}}
                </a>
                <button *ngIf="engagementStructureDetails.projects.length > 1" type="button"
                    class="dm-button-text-right dm-tile--p-b-20"
                    (click)="selectResourceManagementDetails()">
                    {{viewProjectInGrmLinkText}}
                </button>
            </div>  
            <div>                       
                <a *ngIf="!isProjectContext && engagementStructureDetails && engagementStructureDetails.projects.length > 0"
                    [href]="subconOnboardingLink + selectedEngagementId"
                    (click)="logSubconClick()" class="dm-link dm-link--big-m pull-right dm-tile--p-b-20">
                    {{viewSubconLinkText}}
                </a>                
                <a *ngIf="isProjectContext && projectStructureDetails"
                    [href]="subconOnboardingLink + selectedEngagementId+'?packageId='+filteredProjectId"
                    (click)="logSubconClick()" class="dm-link dm-link--big-m pull-right dm-tile--p-b-20">
                    {{viewSubconLinkText}}
                </a>              
            </div>

            <div>
                <button type="button" class="dm-button-text pull-right dm-tile--p-b-20" [disabled] = "isEmailOverViewDisabled"
                    [ngClass]="{'dm-link-after-click':isEmailOverViewClicked}"
                    (click)="sendEmailOverviewOfrProjects()">
                    Email My Book of Business
                </button>
                <span class="input__select--icon spin-icon-position" [ngClass]="{'spin-icon':isEmailOverViewDisabled}">                    
                </span>
            </div>
        </section>
        <dm-loading loader [loadingText]="'Staffing Details'" [showLoading]="showLoading"
            *ngIf="isComponentLoading && !isServerError"></dm-loading>
        <section *ngIf="!isComponentLoading && !isServerError">
            <section *ngIf="staffingViewModel.length > 0">
                <dm-project-filter class="financial-filter"
                    *ngIf="(filterData && filterData.length > 0) || (projectData && projectData.length > 0)"
                    [sourceData]="projectData" [sourceDataV2]="filterData" [selectedProjectId]="filteredProjectId"
                    [selectedServiceId]="filteredServiceId" [selectedTaskId]="filteredTaskId"
                    (updateSelectedProjectId)="filteredProjectIdChanged($event)"
                    (updateSelectedServiceId)="filteredServiceIdChanged($event)"
                    (updateSelectedTaskId)="filteredTaskIdChanged($event)"></dm-project-filter>
            </section>
            <section *ngIf="staffingViewModel.length > 0" class="sticky-cmd-bar" [ngStyle]="{'top': setTopPositionForStickyStaffingCommandBar()}">
                <staffing-command-bar [selectedDemandList]="selectedDemandList"
                    *ngIf="showStaffingCommandBar && ((filterData && filterData.length > 0) || (projectData && projectData.length > 0))"
                    [grmProjectDetails]="(grmRequestList && grmRequestList.ProjectRequests && grmRequestList.ProjectRequests[0] ) ? grmRequestList.ProjectRequests : null"
                    [(isStaffingScreenLoading)]="isLoading" (emitFilterCriteria)="filterStaffingTasks($event)"
                    (searchedText)="searchByText($event)" [isStaffingSearchResultsFound]="isStaffingSearchResultsFound">
                </staffing-command-bar>
            </section>
            <section>
                <dm-requests-grid [typeOfRequests]="'Planned Requests'"
                    [rolesRequests]="staffingTasks" [rolesViewModelRequests]="staffingViewModel"
                    [selectedProjectId]="selectedProjectId" [selectedServiceId]="filteredServiceId"
                    [isInternalEngagement]="isInternal" [selectedTaskId]="filteredTaskId"
                    [projectData]="filterData" (onDemandSelected)="onDemandSelected($event)"
                    [grmProjectDetails]="(grmRequestList && grmRequestList.ProjectRequests && grmRequestList.ProjectRequests.length > 0) ? grmRequestList.ProjectRequests : null"
                    (onResourceAcceptDecline)="onResourceAcceptDecline($event)" [isLoading]="showLoadingForRequestGrid"
                    [searchText]="searchText" [wbsCurrency]="currency" [showNoDemandsMessage]="showNoDemandsMessage"
                    [showUSPubSecRMActions]="showUSPubSecRMActions" [isProjectMarkedForDeletion]="isProjectMarkedForDeletion">
                </dm-requests-grid>
            </section>
        </section>
        <section class="staffing-error">
            <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
        </section>
    </dm-tile>
</section>