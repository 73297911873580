import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IClinSlinDisplayObject, IClinSlinProjectDetails } from "../../../../../../common/services/contracts/clinSlinModal.contracts";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { Components, AccessibilityConstants } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";

@Component({
    selector: "app-clin-slin-modal",
    templateUrl: "./clin-slin-modal.component.html",
    styleUrls: ["./clin-slin-modal.component.scss"],
})

export class ClinSlinModalComponent extends DmModalAbstract {
    @Input() public clinSlinFundingInformation: IClinSlinDisplayObject[];
    @Input() public currency: string;
    @Input() public nonClinSlinDemandModel: IClinSlinProjectDetails[];
    public clinSlinFundingData: IClinSlinDisplayObject[];

    public isLoading: boolean = true; // todo remove these, they're already declared in parent component
    public showLoading: boolean = true;
    public errorText: string = "Unable to load CLIN/SLIN information";
    public noClinSlinAssociated: string = "No CLIN/SLIN data found";
    public accessibilityConstants = AccessibilityConstants;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.ClinSlin);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.isLoading = false;
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseClinModal);
        }
    }
}
