import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { HoursSummaryComponent } from "./hours-summary.component";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [
        HoursSummaryComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        HoursSummaryComponent
    ],
    exports: [
        HoursSummaryComponent
    ]
})
export class HoursSummaryModule { }
