import { Environment } from "@fxp/fxpservices";
import { IPjMAppSettings } from "../interface/IPjMAppSettings";

import { localHost } from "../configs/appSettings.localhost";
import { servicesInt } from "../configs/appSettings.servicesInt";
import { auroraSit } from "../configs/appSettings.auroraSit";
import { uat } from "../configs/appSettings.uat";
import { prod } from "../configs/appSettings.prod";

export const appSettings = (): IPjMAppSettings => {
    let appSetting: IPjMAppSettings;
    switch (window["FxpAppSettings"].EnvironmentName) {
        case Environment.Dev:
            appSetting = localHost; /* Pointing to SIT/MSG data but using local blob instead of CDN over the cloud.
      This is called Dev instead of SIT since our configuration is unique compared to standard usage of FXP.
      This is different from servicesInt due to the data source, and different from auroraSIT due to the blob source.*/
            break;
        case Environment.Sit:
            appSetting = servicesInt; /* This is our dev that points to MS1, but PJM team rarely uses it.
      The CDN in this config points to the cloud for PJM blobs.
      This environment is deployed to the servicesInt website. */
            break;
        case Environment.AuroraSit: /* This is our special AuroraSIT, since we also align with FXP
    and need a servicesINT that is also referred to as SIT; The CDN points to the cloud for PJM blobs.
    This is the environment that is deployed on the auroraSIT website.
    points to MSG */
            appSetting = auroraSit;
            break;
        case Environment.Uat: /* Pointing to MST */
            appSetting = uat;
            break;
        case Environment.Prod: /* Pointing to MSP */
            appSetting = prod;
            break;
        default:
            appSetting = localHost;  /* Always default to our local host when running locally */
            break;
    }

    return appSetting;
};
