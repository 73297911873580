import { Action } from "@ngrx/store";
import { IFinancialSummaryDetails } from "../../common/services/contracts/changerequest.contract";

// 1. action types
export enum CrScreenDetailsActionTypes {
    LOAD_CR_SCREEN_DETAILS = "[CrScreenDetails] Load",
    LOAD_CR_SCREEN_DETAILS_SUCCESS = "[CrScreenDetails] Load Success",
    LOAD_CR_SCREEN_DETAILS_FAIL = "[CrScreenDetails] Load Fail",
    INVALIDATE_CR_SCREEN_DETAILS = "[CrScreenDetails] Invalidate"
}

// 2. action creators
export class LoadCrScreenDetails implements Action {
    public readonly type = CrScreenDetailsActionTypes.LOAD_CR_SCREEN_DETAILS;
    public constructor(public engagementId: string, public demands = true) { } // todo: demands default false
}

export class LoadCrScreenDetailsSuccess implements Action {
    public readonly type = CrScreenDetailsActionTypes.LOAD_CR_SCREEN_DETAILS_SUCCESS;
    public constructor(public engagementId: string, public financialSummaryDetails: IFinancialSummaryDetails[]) { }
}

export class LoadCrScreenDetailsFail implements Action {
    public readonly type = CrScreenDetailsActionTypes.LOAD_CR_SCREEN_DETAILS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateCrScreenDetails implements Action {
    public readonly type = CrScreenDetailsActionTypes.INVALIDATE_CR_SCREEN_DETAILS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type CrScreenDetailsActions =
    | LoadCrScreenDetails
    | LoadCrScreenDetailsSuccess
    | LoadCrScreenDetailsFail
    | InvalidateCrScreenDetails;