<section class="col-md-12 col-sm-12 col-lg-12 dm-tile__minimum-height">
  <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'calc(100vh - 125px)'">
    <div *ngIf="!isComponentLoading && !isServerError" class="milestones-section" [ngClass]="!milestoneData || !milestoneData.length || !showMilestonesData ? 
      'dm-no-data-section' : 'milestones-section'">
      <dm-no-data *ngIf="!milestoneData || !milestoneData.length || !showMilestonesData" [showImage]="true"
        [noDataText]="noMilestonesText"></dm-no-data>
      <div id="milestone" *ngIf="showMilestonesData && milestoneData && milestoneData.length">
        <span class="sr-only" id="billingMilestones">Billing Milestones</span>
        <table class="milestones-list" aria-labelledby="billingMilestones">
          <thead>
            <tr class="font-caption-alt milestones-top-header__2 no-border line-height-14">
              <th class="col-wdth-25 p-r-12 p-t-30 p-l-12" scope="col">
                Description
              </th>
              <th class="col-wdth-8 p-r-12 p-t-30" scope="col">
                Currency
              </th>
              <th class="col-wdth-10 p-t-30" scope="col">
                Billing Value
              </th>
              <th class="col-wdth-10 p-r-12 p-t-30 p-l-16" scope="col">
                Due Date
              </th>
              <th class="col-wdth-10 p-r-12 p-t-30" scope="col">
                Confirmed Date
              </th>
              <!-- <th class="col-wdth-88 p-r-12" scope="col">Customer<br />Acceptance Form</th> -->
              <th class="col-wdth-10 p-r-12 p-t-30" scope="col">
                Status
              </th>
              <th class="col-wdth-10 p-r-12 p-t-30" scope="col">
                Confirmed By
              </th>
              <th class="col-wdth-12 p-t-30" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let milestones of milestoneData; let tableIndex = index">
              <tr>
                <td colspan="8" class="milestones-list__header milestones-list-caption font-caption-alt">
                  <div class="breadcrum">
                    <span>{{milestones.monthName}}</span>
                  </div>
                </td>
              </tr>
              <tr class="font-caption-alt"
              *ngFor="let milestone of milestones.milestone | orderBy: 'dueDate'; let milestoneIndex = index">
              <td class="col-wdth-25 p-r-12 p-l-12" scope="row">
                <span class="col-wdth-25 p-r-12 p-t-30 p-l-14 sr-only" scope="col">
                  Description
                </span>
                <span class="sr-only">{{milestones.monthName}}</span>
                {{milestone.description ? (milestone.description | slice: 0:40) : '--'}}
                <span class="wrapper">
                  <span class="text" *ngIf="milestone.dateDescription === 'ZJPM'">JPM</span>
                  <span class="text" *ngIf="milestone.dateDescription === 'ZVLE'">VL/VA</span>
                </span>
              </td>
              <td class="col-wdth-8 p-r-12">
                {{milestone.currency}}
              </td>
              <td class="col-wdth-10">
                <span class="col-wdth-10 p-t-30 sr-only" scope="col">
                  Billing Value
                </span>
                {{milestone.amount | dmDisplayCurrencyOrDash: milestone.currency}}
                <span *ngIf="!milestone.amount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td class="col-wdth-10">
                <div *ngIf="hideMileStoneActions(milestone.dateDescription)">
                  <span class="col-wdth-10 p-r-12 p-t-30 p-l-16 sr-only" scope="col">
                    Due Date
                  </span>
                  <span [ngClass]="{'display-none-imp': checkDate(milestone) || !milestone.confirmedDate}"
                    *ngIf="milestone.dueDate && milestone.status !== 'Confirmed'">
                    <span class="icon icon-RadioBullet"></span>
                  </span>
                  <span [ngClass]="{'red-legend-text' : !checkDate(milestone) && milestone.status !== 'Confirmed'}">
                    {{milestone.dueDate | dmDisplayDateOrDashPipe}}
                    <span *ngIf="!milestone.dueDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                  </span>
                </div>
                <div *ngIf="!hideMileStoneActions(milestone.dateDescription)">
                  <span class="col-wdth-10 p-r-12 p-t-30 p-l-16 sr-only" scope="col">
                    Due Date
                  </span>
                  <p>--</p>
                </div>
              </td>
              <td class="col-wdth-10 p-r-12 p-l-5">
                <p>
                  <span class="col-wdth-10 p-r-12 p-t-30 sr-only" scope="col">
                    Confirmed Date
                  </span>
                  {{ (milestone.confirmedDate && milestone.confirmedBy) ? (milestone.confirmedDate |
                  dmDisplayDateOrDashPipe) : '-'}}
                  <span *ngIf="!milestone.confirmedDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </p>
              </td>
              <td class="col-wdth-10 p-r-12  p-l-5">
                <p>
                  <span class="col-wdth-10 p-r-12 p-t-30 sr-only" scope="col">
                    Status
                  </span>
                  {{milestone.status | dmDisplayDashIfEmptyOrNull}}
                  <span *ngIf="!milestone.status" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </p>
              </td>
              <td class="col-wdth-10 p-r-12 p-l-5">
                <span class="col-wdth-10 p-r-12 p-t-30 sr-only" scope="col">
                  Confirmed By
                </span>
                <dm-user-name [name]="milestone.confirmedBy" [alias]="milestone.confirmedBy"
                  (onNameClick)="logEmployeeClick()" scope="col">
                </dm-user-name>
              </td>
              <td class="col-wdth-12">
                <div *ngIf="hideMileStoneActionsBasedOnSelections(milestone.dateDescription)">
                  <button class="dm-link milestones-button text-left"
                    [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}" [disabled]="!isLoggedInUserPpjmOrApjm"
                    *ngIf="milestone.status === 'Submitted' && milestone.dateDescription !== 'ZMNG'" (click)="openConfirmationModal(milestone, false, false, engagementContractSignatureDate)"
                    id="undoConfirmation_{{tableIndex}}_{{milestoneIndex}}">
                    <span class="icon icon-undo"></span>
                    <span class="col-wdth-10 p-t-30 sr-only" scope="col">
                      Actions
                    </span>
                    Unconfirm milestone
                  </button>
                  <button class="dm-link milestones-button" [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                    [disabled]="!isLoggedInUserPpjmOrApjm" *ngIf="milestone.status === 'Pending' && milestone.dateDescription !== 'ZMNG'"
                    (click)="openConfirmationModal(milestone, true, false, engagementContractSignatureDate)"
                    id="confirmMilestone_{{tableIndex}}_{{milestoneIndex}}">
                    <span class="col-wdth-10 p-t-30 sr-only" scope="col">
                      Actions
                    </span>
                    Confirm milestone
                  </button>
                  <button class="dm-link milestones-button" [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                    [disabled]="!isLoggedInUserPpjmOrApjm" *ngIf="milestone.dateDescription === 'ZMNG' && milestone.status !== 'Confirmed'"
                    (click)="openConfirmationModal(milestone, true, true, engagementContractSignatureDate)"
                    id="confirmMilestone_{{tableIndex}}_{{milestoneIndex}}">
                    <span class="col-wdth-10 p-t-30 sr-only" scope="col">
                      Actions
                    </span>
                    Manage milestone
                  </button>
                </div>
              </td>
            </tr>
            </ng-container>
          </tbody>
        </table>
       
      </div>
    </div>
    <dm-loading loader [loadingText]="'Milestones Details'" [showLoading]="showLoading"
      *ngIf="isComponentLoading  && !isServerError"></dm-loading>
    <section *ngIf="isServerError">
      <dm-service-error [ngbTooltipContent]="errorText"></dm-service-error>
    </section>
  </dm-tile>
</section>

<!--====================================Small Screen View =====================================-->
<section class="dm-milestones" *ngIf="isSmallScreenView">
  <section class="col-md-12 col-sm-12 col-xs-12 pad-12 pad-t-b-12 pad0 p-t-0-b-12--mobile">
    <h3 class="billing-header pull-left">BILLING MILESTONES</h3>
  </section>
  <dm-loading [ngClass]="isTabletView?'loader-tab':'loader-mob'" [loadingText]="'Milestones Details'"
    [errorText]="errorText" [showLoading]="showLoading" *ngIf="isComponentLoading"></dm-loading>

  <ng-container *ngIf="!isComponentLoading">
    <section *ngIf=" !milestoneData || !milestoneData.length || !showMilestonesData">
      <section id="nomilestone" class="nomilestones-tile">
        <p>
          {{noMilestonesText}}
        </p>
      </section>
    </section>

    <ng-container *ngIf="showMilestonesData && milestoneData && milestoneData.length">
      <section class="milestones-list col-md-12 col-sm-12 col-xs-12 pad0 font-caption-alt"
        *ngFor="let milestones of milestoneData">
        <section class="font-caption-alt breadcrum pad-12 m-b-12">
          <span>
            {{milestones.monthName| dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!milestones.monthName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </span>
        </section>
        <section class="col-xs-12 col-sm-12 pad0">

          <section class="dm-tile" *ngFor="let milestone of milestones.milestone | orderBy: 'dueDate'">
            <section class="col-xs-12 col-sm-12 pad0">
              <!-- Tablet code -->
              <section class="col-sm-5 pad0 col-xs-12 m-b-12" *ngIf="isLoggedInUserPpjmOrApjm && !isMobileView">
                <p class="font-caption-alt">
                  {{milestone.description ? (milestone.description | slice: 0:40) : '-'}}
                </p>
                <div *ngIf="hideMileStoneActionsBasedOnSelections(milestone.dateDescription)">
                  <button class="milestones-button dm-link m-t-8 m-t-8--t-5"
                    [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                    (click)="openConfirmationModal(milestone, false, false)" [disabled]="!isLoggedInUserPpjmOrApjm"
                    *ngIf="milestone.status === 'Submitted'">
                    <span class="icon icon-undo" *ngIf="isUnconfirmFeatureEnabled"></span>
                    Unconfirm milestone
                  </button>
                  <button class="milestones-button dm-link m-t-8 m-t-8--t-5"
                    [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                    (click)="openConfirmationModal(milestone, true, false)" [disabled]="!isLoggedInUserPpjmOrApjm"
                    *ngIf="milestone.status === 'Pending'">
                    Confirm milestone
                  </button>
                  <button class="milestones-button dm-link m-t-8 m-t-8--t-5"
                    [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                    (click)="openConfirmationModal(milestone, true, true)" [disabled]="!isLoggedInUserPpjmOrApjm"
                    *ngIf="milestone.dateDescription === 'ZMNG'">
                    Manage milestone
                  </button>
                </div>
              </section>
              <section class="col-sm-7 pad-l-r-0 m-b-12" *ngIf="isTabletView">
                <section class="col-xs-6 col-sm-4 pad-r-0">
                  <h6 class="font-label col--b-06 m-b-5">Status</h6>
                  <p class="font-caption-alt">
                    <span>
                      {{milestone.status | dmDisplayDashIfEmptyOrNull}}
                      <span *ngIf="!milestone.status" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </span>
                  </p>
                </section>
                <section class="col-xs-6 pad0 col-sm-4">
                  <h6 class="font-label col--b-06 m-b-5 p-l-16">Due Date</h6>
                  <p class="font-caption-alt">
                    <span *ngIf="milestone.dueDate"
                      [ngClass]="{'display-none-imp': milestone.dueDate > milestone.confirmedDate || !milestone.confirmedDate}">
                      <span class="icon icon-RadioBullet"></span>
                    </span>
                    <span [ngClass]="{'red-legend-text' : milestone.dueDate <= milestone.confirmedDate}">
                      {{milestone.dueDate | dmDisplayDateOrDashPipe}}
                      <span *ngIf="!milestone.dueDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </span>
                  </p>
                </section>
                <section class="col-xs-6 pad0 col-sm-4">
                  <h6 class="font-label col--b-06 m-b-5">Confirmed Date</h6>
                  <p class="font-caption-alt">
                    {{milestone.confirmedDate | dmDisplayDateOrDashPipe}}
                    <span *ngIf="!milestone.confirmedDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                  </p>
                </section>
              </section>
              <section class="col-xs-12 col-sm-12 pad0 pad-t-12 b-t-15" *ngIf="isTabletView">
                <section class="col-sm-5 pad0">
                  <section class="col-xs-6 col-sm-4 pad0">
                    <h6 class="font-label col--b-06 m-b-5">Currency</h6>
                    <p class="font-caption-alt">
                      <span>
                        {{milestone.currency | dmDisplayDashIfEmptyOrNull}}
                        <span *ngIf="!milestone.currency" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                      </span>
                    </p>
                  </section>
                  <section class="col-xs-6 pad0 col-sm-4 pad0 text-right">
                    <h6 class="font-label col--b-06 m-b-5">Billing Value</h6>
                    <p class="font-caption-alt">
                      {{milestone.billingValue | dmDisplayCurrencyOrDash: milestone.currency}}
                      <span *ngIf="!milestone.billingValue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </p>
                  </section>
                </section>
                <section class="col-sm-7 pad0">
                  <section class="col-xs-6 col-sm-4 pad0 p-l-16">
                    <h6 class="font-label col--b-06 m-b-5">Confirmed By</h6>
                    <dm-user-name [name]="milestone.confirmedBy" [alias]="milestone.confirmedBy">
                    </dm-user-name>
                  </section>
                </section>
              </section>

              <!-- ===========================Mobile view========================================= -->

              <section class="col-xs-12 pad0" *ngIf="isMobileView">
                <section class="col-xs-12 pad0 m-b-12" *ngIf="isLoggedInUserPpjmOrApjm">
                  <p class="font-caption-alt">
                    {{milestone.description ? (milestone.description | slice: 0:40) : '-'}}
                  </p>
                  <div *ngIf="hideMileStoneActionsBasedOnSelections(milestone.dateDescription)">
                    <button class="milestones-button dm-link m-t-8 m-t-8--t-5"
                      [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                      (click)="openConfirmationModal(milestone, false, false)" [disabled]="!isLoggedInUserPpjmOrApjm"
                      *ngIf="milestone.status === 'Submitted'">
                      <span class="icon icon-undo" *ngIf="isUnconfirmFeatureEnabled"></span>
                      Unconfirm milestone
                    </button>
                    <button class="milestones-button dm-link m-t-8 m-t-8--t-5"
                      [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                      (click)="openConfirmationModal(milestone, true, false)" [disabled]="!isLoggedInUserPpjmOrApjm"
                      *ngIf="milestone.status === 'Pending'">
                      Confirm milestone
                    </button>
                    <button class="milestones-button dm-link m-t-8 m-t-8--t-5"
                      [ngClass]="{'disabled-class': !isLoggedInUserPpjmOrApjm}"
                      (click)="openConfirmationModal(milestone, true, true)" [disabled]="!isLoggedInUserPpjmOrApjm"
                      *ngIf="milestone.dateDescription === 'ZMNG'">
                      Manage milestone
                    </button>
                  </div>
                </section>
                <section class="pad0 col-xs-12 m-b-12">
                  <section class="col-xs-6 pad0">
                    <h6 class="font-label col--b-06 m-b-5 p-l-16">Due Date</h6>
                    <p class="font-caption-alt">
                      <span *ngIf="milestone.dueDate"
                        [ngClass]="{'display-none-imp': milestone.dueDate > milestone.confirmedDate || !milestone.confirmedDate}">
                        <span class="icon icon-RadioBullet"></span>
                      </span>
                      <span [ngClass]="{'red-legend-text' : milestone.dueDate <= milestone.confirmedDate}">
                        {{milestone.dueDate | dmDisplayDateOrDashPipe}}
                        <span *ngIf="!milestone.dueDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                      </span>
                    </p>
                  </section>
                  <section class="col-xs-6 pad0">
                    <h6 class="font-label col--b-06 m-b-5">Confirmed Date</h6>
                    <p class="font-caption-alt">
                      {{milestone.confirmedDate | dmDisplayDateOrDashPipe}}
                    </p>
                  </section>
                </section>
                <section class="pad-l-r-0 col-xs-12 p-b-24 b-b-15">
                  <section class="col-xs-6 pad-r-0 p-l-16">
                    <h6 class="font-label col--b-06 m-b-5">Billing Value</h6>
                    <p class="font-caption-alt">
                      {{milestone.billingValue | dmDisplayCurrencyOrDash: milestone.currency}}
                    </p>
                  </section>
                  <section class="col-xs-6 pad0">
                    <h6 class="font-label col--b-06 m-b-5">Currecy</h6>
                    <p class="font-caption-alt">
                      <span>
                        {{milestone.currency | dmDisplayDashIfEmptyOrNull}}
                      </span>
                    </p>
                  </section>
                </section>
                <section class="col-xs-6 pad-r-0 p-l-16 m-b-12 m-t-12">
                  <h6 class="font-label col--b-06 m-b-5">Status</h6>
                  <p class="font-caption-alt">
                    <span>
                      {{milestone.status | dmDisplayDashIfEmptyOrNull}}
                    </span>
                  </p>
                </section>
                <section class="col-xs-6 pad0 m-b-12 m-t-12">
                  <h6 class="font-label col--b-06 m-b-5">Confirmed By</h6>
                  <dm-user-name [name]="milestone.confirmedBy" [alias]="milestone.confirmedBy">
                  </dm-user-name>
                </section>
              </section>

            </section>
          </section>
        </section>
      </section>
    </ng-container>
  </ng-container>
</section>