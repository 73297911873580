<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'190px'" [tileHeight]="'190px'" class="labor-cost-consumpltion">
    <dm-loading loader [loadingText]="'Cost Consumption'" [showLoading]="showLoading"
        *ngIf="isComponentLoading && !isServerError"></dm-loading>
    <section class="col-md-12 col-sm-12" *ngIf="!isComponentLoading"
        [ngClass]="{'pad-12-50':!deviceFactory.isMobile()}">
        <dm-cceac *ngIf="cceacValues" [values]="cceacValues" [isInternalEngagement]="true"
            class="col-md-12 col-sm-12 cceac-directive">
        </dm-cceac>
    </section>
    <section class="col-md-12 col-sm-12 col-xs-12 labor-cost-consumpltion--reference"
        [ngClass]="{'pad-12-50':!deviceFactory.isMobile()}" *ngIf="!isComponentLoading && !isServerError">
        <section class="col-md-6 col-sm-6 col-xs-6 no-pad">
            <h4 class="tile-title">
                Actual Cost<br /> ({{currencyCode | dmDisplayDashIfEmptyOrNull}})
            </h4>
            <p class="font-caption-alt">
                <span class="cceac--reference--consumed"></span>
                <span class="font-subtitle">{{actualCost | dmDisplayCurrencyOrDash: currencyCode}}</span>
            </p>
        </section>
        <section class="col-md-6 col-sm-6 col-xs-6 no-pad">
            <h4 class="tile-title">
                Planned Cost<br /> ({{currencyCode | dmDisplayDashIfEmptyOrNull}})
            </h4>
            <p class="font-caption-alt">
                <span class="cceac--reference--eac"></span>
                <span class="font-subtitle">{{plannedCost  | dmDisplayCurrencyOrDash: currencyCode}}</span>
            </p>
        </section>
    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>