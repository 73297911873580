import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { ContactsService } from "../../common/services/contacts.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { NgModule } from "@angular/core";
import { ProjectSummaryV2Component } from "./project-summary-v2.component";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { UIRouterModule } from "@uirouter/angular";
import { EntitySummaryDetailsModule } from "../engagement-summary-v2/tiles/entity-summary-details/entity-summary-details.module";
import { InvoiceSummaryModule } from "../invoices/invoice-summary/invoice-summary.module";
import { DmContactsTileV2Module } from "../tiles/dm-contacts-tile-v2/dm-contacts-tile-v2.module";
import { KeyLinksV2Module } from "../tiles/key-links-v2/key-links-v2.module";
import { PurchaseOrderV2Module } from "../tiles/purchase-orders-v2/purchase-orders-v2.module";
import { ProjectSummaryFinancialsV2Module } from "./tiles/project-summary-financials-v2/project-summary-financials-v2.module";
import { ProjectSummaryServiceV2Module } from "./tiles/project-summary-service-v2/project-summary-service-v2.module";
import { SummaryFinancialsBreakdownModule } from "../engagement-summary-v2/tiles/summary-financials-breakdown/summary-financials-breakdown.module";

@NgModule({
    declarations: [
        ProjectSummaryV2Component,
    ],
    imports: [
        UIRouterModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,        
        EntitySummaryDetailsModule,
        ProjectSummaryFinancialsV2Module,
        DmContactsTileV2Module,
        ProjectSummaryServiceV2Module,
        InvoiceSummaryModule,
        PurchaseOrderV2Module,
        KeyLinksV2Module,
        SummaryFinancialsBreakdownModule 
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        ContactsService,
        SharedFunctionsService
    ],
    entryComponents: [
        ProjectSummaryV2Component,
    ],
    exports: [
        ProjectSummaryV2Component,
    ]
})
export class ProjectSummaryV2Module { }
