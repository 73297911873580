import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ManageSuppliersFilterComponent } from "./manage-suppliers-filter.component";

@NgModule({
    declarations: [
        ManageSuppliersFilterComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
    ],
    providers: [
        DMLoggerService
    ],
    exports: [
        ManageSuppliersFilterComponent,
    ],
    entryComponents: [
        ManageSuppliersFilterComponent,
    ]
})
export class ManageSuppliersFilterModule { }
