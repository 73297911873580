import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";

import { CopyResourceModalComponent } from "./copyresource-modal.component";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { OneProfileService } from "../../../../common/services/one-profile.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { ProjectServiceV2 } from "../../../../common/services/project.v2.service";
import { CacheService } from "../../../../common/services/cache.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DmSliderModule } from "../../../../components/tiles/dm-slider/dm-slider.module";
import { TypeAheadSkillsModule } from "../../../../components/tiles/type-ahead-skills/type-ahead-skills.module";
import { TypeAheadSkillsV2Module } from "../../../../components/tiles/type-ahead-skills-v2/type-ahead-skills-v2.module";
import { TypeAheadCityV2Module } from "../../../../components/tiles/type-ahead-city-v2/type-ahead-city-v2.module";

@NgModule({
    declarations: [
        CopyResourceModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        DmSliderModule,
        TypeAheadSkillsModule,
        TypeAheadCityV2Module,
        TypeAheadSkillsV2Module
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        DmDisplayDateOrDashPipe,
        RuleEngineService,
        StaffingActionService,
        OneProfileService,
        ProjectServiceV2,
        StaffingService,
        CacheService,
        ProjectServiceFunctions,
        StoreDispatchService
    ],
    entryComponents: [
        CopyResourceModalComponent
    ],
    exports: [CopyResourceModalComponent]
})
export class CopyResourceModalModule { }




