import * as fromMilestones from "./milestones.reducer";
import { IState } from "../reducers";
import { IBillingMilestoneModel } from "../../common/services/contracts/dmmilestone.service.contract";

// state selector
export const getEntireMilestones = (engagementId: string) => (store: IState): fromMilestones.IMilestonesState => store.milestones[engagementId] ? store.milestones[engagementId] : fromMilestones.initialState;
export const getMilestones = (engagementId: string) => (store: IState): IBillingMilestoneModel[] => getEntireMilestones(engagementId)(store).milestones;
export const getMilestonesLoading = (engagementId: string) => (store: IState): boolean => getEntireMilestones(engagementId)(store).loading;
export const getMilestonesLoaded = (engagementId: string) => (store: IState): boolean => getEntireMilestones(engagementId)(store).loaded;
export const getMilestonesError = (engagementId: string) => (store: IState): string => getEntireMilestones(engagementId)(store).error;
