<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'200px'" [tileHeight]="'100%'" [isV2Tile]="true">
  <a additionalActionsRight class="dm-link" [attr.aria-label]="this.tileContent?.title +' tile section ' + 'Go to Invoices' + 'Navigate to Customer Invoices' " [uiSref]="invoicesRouteName" title="Navigate to Customer Invoices"
    (click)="logInvoiceClick()" *ngIf="!isComponentLoading">
    Go to Invoices
  </a>
  <section *ngIf="!isComponentLoading">
    <section *ngIf="!isInvoicesProgressBarLoading && invoiceList?.length > 0">
      <section class="dm-invoices-progress">
        <section>
          <dm-circular-progress [class]="['col-md-12 col-sm-12 p-0']" [percent]="invoicedAmtPercentage"
            [subtitle]="'Invoiced'" [radius]="80" [space]="-15" [outerStrokeWidth]="15" [innerStrokeWidth]="15"
            [outerStrokeColor]="'#0078D6'" [innerStrokeColor]="'#909090'" [titleFontWeight]="600"
            [subtitleFontSize]="14" [subtitleFontWeight]="400" [valueFontSize]="28" [subtitleDistance]="25">
          </dm-circular-progress>
          <section class="dm-invoices-progress-info">
            <section class="dm-invoices-progress-info__item dm-flex-align-center display-flex-imp">
              <section class="dm-invoices-progress-info__legend dm-invoices-progress-info__legend--completed">
              </section>
              <section class="dm-invoices-progress-info__description">
                <span class="dm-invoices-progress-info__label">Invoiced</span>
                <span class="dm-p-x-10">{{totalInvoicedAmount | currency: currencySymbol}}</span>
              </section>
            </section>
            <section class="dm-invoices-progress-info__item dm-flex-align-center display-flex-imp">
              <section class="dm-invoices-progress-info__legend dm-invoices-progress-info__legend--total">
              </section>
              <section class="dm-invoices-progress-info__description">
                <span class="dm-invoices-progress-info__label">Remaining</span>
                <span class="dm-p-x-10">{{totalContractAmount - totalInvoicedAmount | currency: currencySymbol}}</span>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0" *ngIf="!invoiceList || invoiceList?.length===0">
      <dm-no-data [noDataText]="noInvoicesText" showImage="true">
      </dm-no-data>
    </section>
    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0 dm-m-b-10" *ngIf="isServerError">
      <dm-service-error [ngbTooltipContent]="errorText"></dm-service-error>
    </section>
  </section>
  <dm-loading loader [loadingText]="'Invoices'" [showLoading]="showLoading"
    *ngIf="isComponentLoading && !isServerError"></dm-loading>
</dm-tile>