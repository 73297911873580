import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components } from "../../../common/application.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IDelegationPortFolioDetails } from "../../../common/services/contracts/portfolio.model.contract";

@Component({
    templateUrl: "./delegation-details-modal.html"
})
export class DelegationDetailsModalComponent extends DmModalAbstract {
    @Input() public delegationDetails: IDelegationPortFolioDetails;
    @Input() public delegationType: string;
    public delegatedToOrFrom: string;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.Delegations);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus("closeRejectButton", true);
        if (this.delegationType === "delegatedInward") {
            this.delegatedToOrFrom = "from";
        } else {
            if (this.delegationType === "delegatedOutward") {
                this.delegatedToOrFrom = "to";
            }
        }
    }
}
