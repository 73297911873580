import { Actions, Effect } from "@ngrx/effects";
import { Injectable, Inject } from "@angular/core";

import { DataService } from "../../common/services/data.service";
import { from as fromPromise, of } from "rxjs";
import { ProjectService } from "../../common/services/project.service";
import { switchMap, catchError, map } from "rxjs/operators";
import * as npcActualsActions from "./npc-actuals.action";

@Injectable()
export class NpcActualsEffect {

    @Effect()
    public loadNpcActuals$ = this.actions$
        .ofType(npcActualsActions.NpcActualsActionTypes.LOAD_NPC_ACTUALS).pipe(
            switchMap((action: npcActualsActions.LoadNpcActuals) =>
                fromPromise(this.projectService.getNpcActuals(action.wbsId))
                    .pipe(
                        map((npcActuals: any) => new npcActualsActions.LoadNpcActualsSuccess(action.wbsId, npcActuals)),
                        catchError((error) => {
                            /* API returns 404 as valid response, if API has no actuals, so return an empty array as a success */
                            if (error && error.status === 404){
                                return  of(new npcActualsActions.LoadNpcActualsSuccess(action.wbsId, []));
                            }
                            return of(new npcActualsActions.LoadNpcActualsFail(action.wbsId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error)));
                        })
                    )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService
    ) { }
}