import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IDmTab } from "../../../common/services/contracts/common.contracts";

@Component({
    selector: "forecast-navigation-bar",
    templateUrl: "./forecast-navigation-bar.html",
    styleUrls: ["./forecast-navigation-bar.scss"]
})

export class ForecastNavigationBarComponent extends DmComponentAbstract {
    @Output() public isLaborTabSelected = new EventEmitter<boolean>();
    public tabsContent: IDmTab[];
    public showLaborDetails: boolean = true;    

    public constructor(
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) protected dmLogger: DMLoggerService) {
        super(dmLogger, Components.PlanForecast);
    }

    public ngOnInit(): void {
        this.tabsContent = [
            {
                displayName: "Labor",
                id: "LABOR",
                isActive: true
            },
            {
                displayName: "Subcon FF",
                id: "SUBCONFF",
                isActive: false
            },
            {
                displayName: "Expenses & Material",
                id: "EXPENSESANDMATERIAL",
                isActive: false
            },
            {
                displayName: "Units",
                id: "UNITS",
                isActive: false
            }
        ];
        this.isLaborTabSelected.emit(true);
    }

    /**
     * Toggles tab for forecast/etc.
     */
    public toggleTab(activeTab: IDmTab): void {
        for (const tab of this.tabsContent) {
            tab.isActive = false;
        }
        activeTab.isActive = true;
        this.showLaborDetails = activeTab.id === "LABOR";    
        this.isLaborTabSelected.emit(this.showLaborDetails);    
    }

    /**
     * Move the focus to element for accessibility of nav bars
     * @param id
     */
    public focusForNavBarArrowKey(id: string, focusDirection: string): void {
        this.sharedFunctionsService.moveFocusForNavBarArrowKey(this.tabsContent, id, focusDirection);
    }
}
