import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { ProjectSummaryFinancialsV2Component } from "./project-summary-financials-v2.component";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { CceacV2Module } from "../../../../components/financial-mgmt/tiles/cost-consumed/cceac-v2/cceac-v2.module";

@NgModule({
    declarations: [
        ProjectSummaryFinancialsV2Component,
    ],
    imports: [
        CceacV2Module,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        FinancialService,
        SharedFunctionsService,
    ],
    entryComponents: [
        ProjectSummaryFinancialsV2Component,
    ],
    exports: [
        ProjectSummaryFinancialsV2Component,
    ]
})
export class ProjectSummaryFinancialsV2Module { }
