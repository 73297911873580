import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { NewSnapshotModalComponent } from "./new-snapshot.component";
import { ProjectService } from "../../../../../../common/services/project.service";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";


@NgModule({
    declarations: [
        NewSnapshotModalComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        DMLoggerService,
        ProjectService,
        SharedFunctionsService,
    ],
    entryComponents: [
        NewSnapshotModalComponent
    ],
    exports: [
        NewSnapshotModalComponent
    ]
})
export class NewSnapshotModalModule { }
