import { Action } from "@ngrx/store";
import { IEngagementFinancialsListV2 } from "../../common/services/contracts/portfolio.model";


// 1. action types
export enum CustomerFinancialOverviewActionTypes {
    LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS = "[CustomerFinancialOverview] Load",
    LOAD_MANUAL_CUSTOMER_FINANCIALOVERVIEW_DETAILS_V2 = "[CustomerFinancialOverview] Load Manual",
    LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS_SUCCESS = "[CustomerFinancialOverview] Load Success",
    LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS_FAIL = "[CustomerFinancialOverview] Load Fail",
    INVALIDATE_CUSTOMER_FINANCIALOVERVIEW_DETAILS = "[CustomerFinancialOverview] Invalidate"
}

// 2. action creators
export class LoadCustomerFinancialOverview implements Action {
    public readonly type = CustomerFinancialOverviewActionTypes.LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS;
    public constructor(public engagementId: string) { }
}

export class LoadManualCustomerFinancialOverview implements Action {
    public readonly type = CustomerFinancialOverviewActionTypes.LOAD_MANUAL_CUSTOMER_FINANCIALOVERVIEW_DETAILS_V2;
    public constructor(public engagementId: string) { }
}

export class LoadCustomerFinancialOverviewSuccess implements Action {
    public readonly type = CustomerFinancialOverviewActionTypes.LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS_SUCCESS;
    public constructor(public engagementId: string, public financialDetails: IEngagementFinancialsListV2[]) { }
}

export class LoadCustomerFinancialOverviewFail implements Action {
    public readonly type = CustomerFinancialOverviewActionTypes.LOAD_CUSTOMER_FINANCIALOVERVIEW_DETAILS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateCustomerFinancialOverview implements Action {
    public readonly type = CustomerFinancialOverviewActionTypes.INVALIDATE_CUSTOMER_FINANCIALOVERVIEW_DETAILS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type CustomerFinancialOverviewActions =
    | LoadCustomerFinancialOverview
    | LoadManualCustomerFinancialOverview
    | LoadCustomerFinancialOverviewSuccess
    | LoadCustomerFinancialOverviewFail
    | InvalidateCustomerFinancialOverview;