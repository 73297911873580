import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DmTypeAheadCityV2Component } from "./type-ahead-city-v2.component";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        DmTypeAheadCityV2Component
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        OneProfileService
    ],
    entryComponents: [
        DmTypeAheadCityV2Component
    ],
    exports: [
        DmTypeAheadCityV2Component
    ],
})
export class TypeAheadCityV2Module { }
