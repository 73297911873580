import { Action } from "@ngrx/store";
import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";

// 1. action types
export enum ChangeRequestActionTypes {
    LOAD_ENGAGEMENT_CHANGE_REQUESTS = "[EngagementChangeRequests] Load",
    LOAD_ENGAGEMENT_CHANGE_REQUESTS_SUCCESS = "[EngagementChangeRequests] Load Success",
    LOAD_ENGAGEMENT_CHANGE_REQUESTS_FAIL = "[EngagementChangeRequests] Load Fail",
    INVALIDATE_ENGAGEMENT_CHANGE_REQUESTS = "[EngagementChangeRequests] Invalidate"
}

// 2. action creators
export class LoadEngagementChangeRequests implements Action {
    public readonly type = ChangeRequestActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTS;
    public constructor(public engagementId: string) { }
}
export class LoadEngagementChangeRequestsSuccess implements Action {
    public readonly type = ChangeRequestActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTS_SUCCESS;
    public constructor(public engagementId: string, public changeRequests: IChangeRequest[]) { }
}

export class LoadEngagementChangeRequestsFail implements Action {
    public readonly type = ChangeRequestActionTypes.LOAD_ENGAGEMENT_CHANGE_REQUESTS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateEngagementChangeRequests implements Action {
    public readonly type = ChangeRequestActionTypes.INVALIDATE_ENGAGEMENT_CHANGE_REQUESTS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type ChangeRequestActions =
    | LoadEngagementChangeRequests
    | LoadEngagementChangeRequestsSuccess
    | LoadEngagementChangeRequestsFail
    | InvalidateEngagementChangeRequests;