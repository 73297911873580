import { Component, Input, Inject } from "@angular/core";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { AccessibilityConstants, Components, ExpenseBillableCode } from "../../../../common/application.constants";
import { IFinancialChangeRequestDetails } from "../../../../common/services/contracts/changerequestv2.contract";


@Component({
    selector: "dm-fcr-expense-view-details",
    templateUrl: "./fcr-expense-view-details.html",
    styleUrls: ["./fcr-expense-view-details.scss"]
})
export class FcrExpenseViewDetailsComponent extends DmComponentAbstract {
    @Input() public planCurrency: string;
    @Input() public fcrExpenseViewDetails: IFinancialChangeRequestDetails;
    public ExpenseBillableCode = ExpenseBillableCode;
    public accessibilityConstants = AccessibilityConstants;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(dmLogger, Components.FcrDetails);
    }

    public ngOnInit(): void {
        this.isComponentLoading = false;
    }
}