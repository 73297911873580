import { BulkIntEngStatus } from "./bulk-upload-int-engagement.service.contracts";

export interface INotification {
    userId: string;
    id: string;
    type: NotificationType;
    entityId: string;
    createdBy: string;
    createdOn: string;
    entityDescription?: string;
}

export enum NotificationType {
    ReleaseAndActivate = "ReleaseAndActivate",
    DateChange = "DateChange",
    BulkUpload = "BulkUpload",
    RiskReserve = "RiskReserve",
    BulkMaintenance = "BulkMaintenance",
    ProjectClsoure = "ProjectClosureStatusChange",
    SyncEACStatusChange = "SyncEACStatusChange",
    CreateMaintenance = "CreateMaintenance"
}

export interface IBulkCreationEngagement {
    EngagementId: string;
    Status: BulkIntEngStatus;
    UpdatedOn: string;
}

export interface IOperation {
    timestamp: number;
    operationId: string;
    wbsId: string;
}

export class SignalRConnectionInfo {
    public url: string;
    public accessToken: string;
}