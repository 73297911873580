import { Component, EventEmitter, forwardRef, Inject, Input, Output } from "@angular/core";
import { FxpConstants, FxpMessageService, FxpRouteService, UserInfoService } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components, EntityType, RouteName } from "../../../common/application.constants";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { SyncEacLog } from "../../../common/services/contracts/sync-eac-log.contract";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { SyncEACUserAction } from "../../../common/services/contracts/sync-eac-user-action";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IState } from "../../../store/reducers";
import { InvalidateDemands } from "../../..//store/demands/demands.action";
import { InvalidateDBDemands } from "../../..//store/db-demands/demands.action";
import { IDemandDiff } from "../../../common/services/contracts/changerequestv2.contract";
import { FcrDemandData } from "../../../common/services/contracts/fcr-demand-data.contract";
import { DmError } from "../../../common/error.constants";

@Component({
    selector: "dm-open-sync-eac-to-plan-threshold-dialog",
    templateUrl: "./open-sync-eac-to-plan-threshold-dialog.component.html",
    styleUrls: ["./open-sync-eac-to-plan-threshold-dialog.component.scss"]
})

export class OpenSyncEACToPlanThresholdDialogComponent extends DmModalAbstract {

    @Input() public orchestrationId: string;
    @Output() public onClose = new EventEmitter();
    public modalContent: IModal;
    public syncEACInformation: SyncEacLog;
    public demandDiffData: IDemandDiff[] = [];
    public loadingComponentData = false;
    public wbsId: string;
    private loggedInUserData: any;
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(Store) private store: Store<IState>,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(UserInfoService) private userInfoService: UserInfoService,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
    ) {
        super(activeModal, dmLogger, Components.OpenSyncEACToPlanThresholdDialogComponent);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Threshold Exceeded"
        };
    }


    public loadModalData(orchestrationId: string): void {
        this.loadingComponentData = true;
        this.notificationService.getSyncEACData(orchestrationId)
            .then((response: SyncEacLog) => {
                if (response) {
                    this.syncEACInformation = response;
                    this.loadingComponentData = false;

                    if (this.syncEACInformation.Fee_Type === "FIF") {
                        this.notificationService.getEACDemandDetails(this.syncEACInformation.ProjectId)
                            .then((demandData: FcrDemandData[]) => {
                                this.demandDiffData = demandData.map((demand: FcrDemandData) => {
                                    return {
                                        id: demand.TaskId,
                                        grmDemandId: demand.GrmDemandId,
                                        role: `${demand.ResourceType}/${demand.ActivityType}`,
                                        resourceLocation: demand.ResourceLocation.toString(),
                                        billingRoleId: demand.BillingRoleId,
                                        quantity: demand.Quantity,
                                        cost: demand.Cost,
                                        revenue: demand.Revenue,
                                        uom: demand.UoM
                                    };
                                });
                            })
                            .catch((error: any) => {
                                if (error) {
                                    this.fxpMessageService.addMessage(DmError.SyncEACToPlanMessages.GetSyncEACToPlanDemandDataFailure, this.FXP_CONSTANTS.messageType.error, false);
                                }
                            });
                    }
                }
            })
            .catch((error: any) => {
                this.loadingComponentData = false;
                if (error) {
                    this.fxpMessageService.addMessage(DmError.SyncEACToPlanMessages.GetSyncEACData, this.FXP_CONSTANTS.messageType.error, false);
                }
            });
    }

    public closeModal(): void {
        this.activeModal.close();
    }

    public handleFIFThresholdCancel(): void {
        if (this.syncEACInformation) {
            this.loggedInUserData = this.userInfoService.getCurrentUserData();
            const userActionData: SyncEACUserAction = {
                OrchestrationId: this.syncEACInformation.OrchestrationID,
                Override: false,
                CHANGEDBY: this.loggedInUserData.alias,
                CHANGEDON: new Date().toISOString()
            };
            this.notificationService.sendEACUserActionDetails(userActionData)
                .then((response: any) => {
                    if (response) {
                        this.activeModal.close();
                    }
                })
                .catch((error: any) => {
                    if (error) {
                        this.fxpMessageService.addMessage(DmError.SyncEACToPlanMessages.SendUserActionDataFailure, this.FXP_CONSTANTS.messageType.error, false);
                    }
                });
        }
    }

    public handleTNMThresholdCancel(): void {
        if (this.syncEACInformation) {
            this.loggedInUserData = this.userInfoService.getCurrentUserData();
            const userActionData: SyncEACUserAction = {
                OrchestrationId: this.syncEACInformation.OrchestrationID,
                Override: false,
                CHANGEDBY: this.loggedInUserData.alias,
                CHANGEDON: new Date().toISOString()
            };
            this.notificationService.sendEACUserActionDetails(userActionData)
                .then((response: any) => {
                    if (response) {
                        this.activeModal.close();
                    }
                })
                .catch((error: any) => {
                    if (error) {
                        this.fxpMessageService.addMessage(DmError.SyncEACToPlanMessages.SendUserActionDataFailure, this.FXP_CONSTANTS.messageType.error, false);
                    }
                });
        }
    }

    public handleFCRClick(): void {
        if (this.syncEACInformation) {
            // Send an update to mark status as success
            this.loggedInUserData = this.userInfoService.getCurrentUserData();
            const userActionData: SyncEACUserAction = {
                OrchestrationId: this.syncEACInformation.OrchestrationID,
                Override: true,
                CHANGEDBY: this.loggedInUserData.alias,
                CHANGEDON: new Date().toISOString()
            };

            this.notificationService.sendEACUserActionDetails(userActionData)
                .then(() => {
                    // Redirect user to Create FCR screen
                    const projectsThatFailedThresholdCheck: string[] = [this.syncEACInformation.ProjectId];

                    const demandDiffData: IDemandDiff[] = this.demandDiffData;

                    this.store.dispatch(new InvalidateDemands(this.sharedFunctionsService.getCurrentEntityId()));
                    this.store.dispatch(new InvalidateDBDemands(this.sharedFunctionsService.getCurrentEntityId()));

                    if (this.sharedFunctionsService.getCurrentEntityContext() === EntityType.Project) {
                        this.fxpRouteService.navigatetoSpecificState(RouteName.ProjectFinancialChangeRequestForm,
                            {
                                projectsThatFailedThresholdCheck,
                                demandDiffData,
                                initializedFromPlanAndForecast: false,
                                initializedFromNewForecastExperience: true,
                                projectId: this.sharedFunctionsService.getCurrentEntityId()
                            },
                            { reload: RouteName.ProjectFinancialChangeRequestForm });
                    }
                    else {
                        this.fxpRouteService.navigatetoSpecificState(RouteName.EngagementFinancialChangeRequestForm,
                            {
                                projectsThatFailedThresholdCheck,
                                demandDiffData,
                                initializedFromPlanAndForecast: false,
                                initializedFromNewForecastExperience: true,
                                engagementId: this.sharedFunctionsService.getCurrentEntityId()
                            },
                            { reload: RouteName.EngagementFinancialChangeRequestForm });
                    }

                    this.activeModal.close();
                })
                .catch(() => {
                    this.fxpMessageService.addMessage(DmError.SyncEACToPlanMessages.SendUserActionDataFailure, this.FXP_CONSTANTS.messageType.error, false);
                });
        }
    }

    public handleContinueToSync(): void {
        this.activeModal.close();

        if (this.syncEACInformation) {
            this.loggedInUserData = this.userInfoService.getCurrentUserData();
            const userActionData: SyncEACUserAction = {
                OrchestrationId: this.syncEACInformation.OrchestrationID,
                Override: true,
                CHANGEDBY: this.loggedInUserData.alias,
                CHANGEDON: new Date().toISOString()
            };
            this.notificationService.sendEACUserActionDetails(userActionData);
        }
    }

    public getStatusColorClass(status: string): string {
        if (status && status.toLocaleUpperCase() === "PASSED") {
            return "text-primary";
        }
        else if (status && status.toLocaleUpperCase() === "FAILED") {
            return "text-danger";
        }
        else if (status && status.toLocaleUpperCase() === "WARNING") {
            return "text-warning";
        }
    }

    public getStatusIconClass(status: string): string {
        if (status && status.toLocaleUpperCase() === "PASSED") {
            return "icon-CheckMark";
        }
        else if (status && status.toLocaleUpperCase() === "FAILED") {
            return "icon-warning dm-warning";
        }
        else if (status && status.toLocaleUpperCase() === "WARNING") {
            return "icon-info-triangle";
        }
    }
}