import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { AmendmentDetailsComponent } from "./amendment-details.component";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ViewWorkbookModalModule } from "../view-workbook-modal/view-workbook-modal.module";

@NgModule({
    declarations: [
        AmendmentDetailsComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        NgbModule,
        UIRouterModule,
        ViewWorkbookModalModule,
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
    ],
    entryComponents: [
        AmendmentDetailsComponent,
    ],
    exports: [
        AmendmentDetailsComponent,
    ]
})
export class AmendmentDetailsModule { }
