/* eslint-disable no-console */
import { Injectable, Inject, forwardRef } from "@angular/core";
import { ComponentType, FeatureUsageEvent, FxpLoggerService, FxpTelemActionStatus, SystemEvent } from "@fxp/fxpservices";
import { IComponentLoadScenarioV2, IChildComponentLoadScenario, IBusinesTask } from "./contracts/dmlogger.contracts";
import { LogEventConstants, IComponent, ComponentPrefix, IChildComponent, SourceConstants} from "../application.constants";
import { v4 as uuid } from "uuid";

@Injectable()
export class DMLoggerService {
    private static sourcePrefix: string = "PJM.UI.";
    private static propertyPrefix: string = "PJM.";
    private static pageLoadGuidProperty: string = "Page Load GUID";
    private activeLoadingComponentScenarios: {
        [key: string]: IComponentLoadScenarioV2;
    };
    private activeBusinessTasks: {
        [key: string]: IBusinesTask;
    };
    private topLevelPage: string;
    private pageLoadGuid: string;
    private feature: FeatureUsageEvent;

    public constructor(
        @Inject(forwardRef(() => FxpLoggerService)) private fxpLoggerService: FxpLoggerService,
    ) {    }

    /**
     * Renews correlation id for every feature.
     * Used for manual logging.
     * In case of automatic logging, correlation id is renewed by fxp.
     */
    public renewCorrelationId(): void {
        this.fxpLoggerService.renewCorrelationId();
    }

    /**
     * Renews sub correlation id for every sub feature.
     */
    public renewSubCorrelationId(): void {
        this.fxpLoggerService.renewSubCorrelationId();
    }

    /**
     * Generates a version 4 GUID: a globally unique identifier.
     * @returns {string} A GUID string in the format 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.
     */
    /* eslint-disable no-bitwise */
    public generateGuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0;
            const v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    /* eslint-disable no-bitwise */

    /**
     * Starts tracking FeatureUsageEvent.
     * Starts counting the time and stops the same when the endFeatureUsageEvent object is
     * invoked to report the duration user has spent in completing the feature.
     */
    public startFeatureUsageEvent(featureName: string, actionType: string, actionName: string, eventType: string): FeatureUsageEvent {
        const feature = new FeatureUsageEvent(DMLoggerService.sourcePrefix + featureName, actionType, actionName, eventType, ComponentType.Web);
        this.fxpLoggerService.startFeatureUsageEvent(feature);
        return feature;
    }

    /**
     * Completes tracking FeatureUsageEvent.
     * Logs an entry in cutomEvents of application insight with details like
     * duration which is the time spent by user between startFeatureUsageEvent and endFeatureUsageEvent,
     * Task or feature success status using ExperienceResult.
     */
    public endFeatureUsageEvent(className: string, methodName: string, feature: FeatureUsageEvent, experienceResult: boolean): void {
        feature.setActionStatus(experienceResult ? FxpTelemActionStatus.Succeeded : FxpTelemActionStatus.Failed);
        this.fxpLoggerService.endFeatureUsageEvent(DMLoggerService.sourcePrefix + className + "." + methodName, feature);
    }

    /**
     * Log FeatureUsageEvent in application insights which is needed for Heart Metric calculation
     * No need to call this method if we are logging using startFeatureUsageEvent and endFeatureUsageEvent,
     */
    public logFeatureUsageEvent(className: string, methodName: string, eventData: string, properties?: {}, measurements?: {}, transactionId?: string): void {
        const propertyBag = this.fxpLoggerService.createPropertyBag();
        if (!properties) {
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        } else {
            for (const property in properties) {
                propertyBag.addToBag(property, properties[property]);
            }
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        }
        this.fxpLoggerService.logFeatureUsageEvent(DMLoggerService.sourcePrefix + className + "." + methodName, eventData, propertyBag, measurements, transactionId);
    }

    /**
     * Logs the event to Azure Application Insights/Kustos Telemetry for our dashboards
     * Ammends "PJM.UI." to the front of every source.
     * @param source page the event originated from
     * @param eventName event that is being logged
     * @param properties specific properties about the event
     */
    public logSystemEvent(className: string, methodName: string, eventName: string, properties?: {}, measurements?: {}, transactionId?: string): void {
        const propertyBag = this.fxpLoggerService.createPropertyBag();
        if (!properties) {
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        } else {
            for (const property in properties) {
                propertyBag.addToBag(property, properties[property]);
            }
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        }
        const eventData = new SystemEvent(DMLoggerService.sourcePrefix + eventName, ComponentType.Web, ""); 
        this.fxpLoggerService.logSystemEvent(
            DMLoggerService.sourcePrefix + className + "." + methodName, 
            eventData, 
            propertyBag,
            measurements,
            transactionId
        );
    }

    /**
     * Logs the event to Azure Application Insights/Kustos Telemetry for our dashboards
     * Ammends "PJM.UI." to the front of every source.
     * @param source page the event originated from
     * @param eventName event that is being logged
     * @param properties specific properties about the event
     */
    public logUserAction(className: string, methodName: string, eventName: string, message: string, properties?: {}, measurements?: {}, transactionId?: string): void {
        const propertyBag = this.fxpLoggerService.createPropertyBag();
        if (!properties) {
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        } else {
            for (const property in properties) {
                propertyBag.addToBag(property, properties[property]);
            }
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        }
        this.fxpLoggerService.logUserAction(
            DMLoggerService.sourcePrefix + className + "." + methodName,
            DMLoggerService.sourcePrefix + eventName,
            message,
            propertyBag,
            measurements,
            transactionId
        );
    }

    /**
     * Logs the event to Azure Application Insights/Kustos Telemetry for our dashboards
     * Ammends "PJM.UI." to the front of every source.
     * @param source page the event originated from
     * @param eventName event that is being logged
     * @param properties specific properties about the event
     */
    public logEvent(className: string, methodName: string, eventName: string, properties?: {}, measurements?: {}, transactionId?: string): void {
        const propertyBag = this.fxpLoggerService.createPropertyBag();
        if (!properties) {
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        } else {
            for (const property in properties) {
                propertyBag.addToBag(DMLoggerService.propertyPrefix + property, properties[property]);
            }
            propertyBag.addToBag(DMLoggerService.pageLoadGuidProperty, this.pageLoadGuid);
        }
        this.fxpLoggerService.logEvent(
            DMLoggerService.sourcePrefix + className + "." + methodName,
            DMLoggerService.sourcePrefix + eventName,
            propertyBag,
            measurements,
            transactionId
        );
    }

    /**
     * Logs an error to FXP's Azure Application Insights/Kustos.
     * Ammends "PJM." to the front of every logged exception.
     * 
     * @param source event the error originated from
     * @param error error object thrown
     * @param errorCode message/ data for the error
     * @param stackTrace stackTrace of errors obtained from a loop
     * @param transactionID unique ID given to identify a transaction
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public logError(className: string, methodName: string, error: any, errorCode: string, stackTrace?: string, measurements?: {}, transactionID?: string, errorSeverity?: any): void {
        const propertyBag = this.fxpLoggerService.createPropertyBag();
        propertyBag.addToBag(
            DMLoggerService.pageLoadGuidProperty,
            this.pageLoadGuid
        );
        const errorString: string = JSON.stringify(error);
        this.fxpLoggerService.logError(
            DMLoggerService.sourcePrefix + className + "." + methodName,
            errorString,
            errorCode,
            stackTrace,
            propertyBag,
            measurements,
            transactionID,
            errorSeverity
        );
    }

    /**
     * Starts the component loading process by adding the given component
     * to the active load scenario collection. If the component has any children, those
     * children are attached to it and will be loaded into the active scenarios as
     * they are called by their own initializations.
     * @param componentName Name of the component that is being initialized.
     * @param children List of children components (by name).
     */
    public startComponentLoadTelemetryTimer(
        component: IComponent,
        children?: IChildComponent[]
    ): void {
        const componentName: string = component.userFriendlyName;
        if (!this.activeLoadingComponentScenarios) {
            this.activeLoadingComponentScenarios = {};
        }
        if (this.activeLoadingComponentScenarios[componentName] && this.activeLoadingComponentScenarios[componentName].pageLoadGuid === this.pageLoadGuid) {
            /* Error occurred, cannot start an active load scenario for a component that is already runniung. */
            return;
        }
        if (component.isParentPage) {
            this.topLevelPage = componentName;
            this.pageLoadGuid = uuid();
        }

        let componentDependencyMap: { [key: string]: IChildComponentLoadScenario };
        if (children) {
            componentDependencyMap = {};
            for (const child of children) {
                /* Add children components to the component dependency map, instantiated all as false, they have not been completed. */
                componentDependencyMap[child.component.userFriendlyName] = {
                    isCritical: child.isCritical,
                    isLoaded: false
                };
            }
        }
        const newlyActiveComponent: IComponentLoadScenarioV2 = {
            startTime: Date.now(),
            duration: undefined,
            usabilityDuration: undefined,
            componentName,
            children: componentDependencyMap /* Will be undefined if no children were provided */,
            componentLoadGuid: this.getComponentLoadGuid(componentName),
            isComponentUsable: false,
            pageLoadGuid: this.pageLoadGuid
        };
        this.fxpLoggerService.startTrackPerformance(
            ComponentPrefix + component.userFriendlyName
        );
        this.logEvent(
            ComponentPrefix + componentName,
            SourceConstants.Method.StartComponentLoadTelemetryTimer,
            LogEventConstants.PageLoadStarted,
            {
                PageLoadGuid: newlyActiveComponent.pageLoadGuid,
                IsTopLevelPage: component.isParentPage,
                ComponentLoadGuid: newlyActiveComponent.componentLoadGuid,
                StartTime: newlyActiveComponent.startTime
            }
        );

        this.activeLoadingComponentScenarios[componentName] = newlyActiveComponent;

        if (this.topLevelPage === componentName) {
            console.log(
                `PJM Loading Telemetry PAGE:\t\t"${componentName} Component starting now!`
            );
        } else {
            console.log(
                `PJM Loading Telemetry:\t\t"${componentName} Component starting now!`
            );
        }
    }

    /**
     * The given component has completed loading, and will log telemetry to Application Insights about its load time.
     * If the component is a child of any other active loading scenarios, those scenarios will be evaluated based on this
     * component's completion.
     * @param componentName Name of the component that has just completed loading.
     */
    public endComponentLoadTelemetryTimer(componentName: string): void {
        if (
            !this.activeLoadingComponentScenarios ||
            !this.activeLoadingComponentScenarios[componentName]
        ) {
            /* Error occurred, cannot add a new child to a component that isn't active in the load scenario. */
            return;
        }
        const endTime: number = Date.now();
        const totalTime: number = endTime - this.activeLoadingComponentScenarios[componentName].startTime;
        this.activeLoadingComponentScenarios[componentName].duration = totalTime;

        // Logic for marking components which dont have children as usable when they are done loading
        if (this.activeLoadingComponentScenarios[componentName].usabilityDuration === undefined) {
            this.activeLoadingComponentScenarios[componentName].isComponentUsable = true;
            this.activeLoadingComponentScenarios[componentName].usabilityDuration = totalTime;
        }
        let isComponentTopLevelPage: boolean = false;
        if (this.topLevelPage === componentName) {
            isComponentTopLevelPage = true;
            this.topLevelPage = undefined; /* Finished loading the whole page, remove top level page */
            console.log(
                "PJM Loading Telemetry PAGE \t\t" +
                componentName +
                " PAGE just finished: =========================================" +
                totalTime +
                "ms \n Guid: " +
                this.pageLoadGuid +
                "\n ComponentLoadGuid: " +
                this.activeLoadingComponentScenarios[componentName].componentLoadGuid +
                "\n ComponentUsabilityDuration: " +
                this.activeLoadingComponentScenarios[componentName].usabilityDuration
            );
        } else {
            console.log(
                "PJM Loading Telemetry \t\t" +
                componentName +
                " Component just finished: =========================================" +
                totalTime +
                "ms \n Guid: " +
                this.pageLoadGuid +
                "\n ComponentLoadGuid: " +
                this.activeLoadingComponentScenarios[componentName].componentLoadGuid +
                "\n ComponentUsabilityDuration: " +
                this.activeLoadingComponentScenarios[componentName].usabilityDuration
            );
        }
        this.fxpLoggerService.stopTrackPerformance(ComponentPrefix + componentName);
        this.logEvent(
            ComponentPrefix + componentName,
            SourceConstants.Method.EndComponentLoadTelemetryTimer,
            LogEventConstants.PageLoadCompleted,
            {
                StartTime: this.activeLoadingComponentScenarios[componentName].startTime,
                ComponentLoadTime: totalTime,
                PageLoadGuid: this.pageLoadGuid,
                IsTopLevelPage: isComponentTopLevelPage,
                ComponentLoadGuid: this.activeLoadingComponentScenarios[componentName].componentLoadGuid,
                ComponentUsabilityTime: this.activeLoadingComponentScenarios[componentName].usabilityDuration
            }
        );
        delete this.activeLoadingComponentScenarios[componentName]; /* Remove the component from the collection of actively running scenarios. */
        this.updateActiveLoadingComponents(componentName); /* Check if the component that just completed is a child of any other active scenarios. */
    }

    /**
     * Start tracking business process
     * @param taskName business process
     */
    public startBusinessTaskTelemetryTimer(taskName: string): void {
        if (!this.activeBusinessTasks) {
            this.activeBusinessTasks = {};
        }
        this.activeBusinessTasks[taskName] = {
            name: taskName,
            startTime: Date.now(),
            endTime: undefined,
            duration: undefined
        };
        this.logEvent(
            taskName,
            SourceConstants.Method.StartBusinessTaskTelemetryTimer,
            LogEventConstants.BusinessTaskStarted,
            {
                PageLoadGuid: this.pageLoadGuid
            }
        );
    }

    /**
     * End tracking business process
     * @param taskName business proces
     */
    public endBusinessTaskTelemetryTimer(taskName: string): void {
        if (!this.activeBusinessTasks || !this.activeBusinessTasks[taskName]) {
            return;
        }

        const endTime: number = Date.now();
        const totalTime: number = endTime - this.activeBusinessTasks[taskName].startTime;
        this.logEvent(
            taskName,
            SourceConstants.Method.EndBusinessTaskTelemetryTimer,
            LogEventConstants.BusinessTaskCompleted,
            {
                Duration: totalTime,
                PageLoadGuid: this.pageLoadGuid
            }
        );
        this.activeBusinessTasks[taskName].endTime = endTime;
        this.activeBusinessTasks[taskName].duration = totalTime;
    }

    /**
     * Called when a new component is going to be loaded onto the UI that was not guaranteed
     * to be there from the initial page load. For example, if a component is dependent upon other
     * API responses and will not be rendered until the response returns; or if a component
     * is rendered only in Desktop view, and the user may be in Mobile view at the time of page start.
     * @param parentComponentName The parent/source component name.
     * @param childComponent The child component.
     */
    public addNewChildComponentToLoadingTelemetry(
        parentComponent: IComponent,
        childComponent: IChildComponent
    ): void {
        const parentComponentName: string = parentComponent.userFriendlyName;
        if (
            !this.activeLoadingComponentScenarios ||
            !this.activeLoadingComponentScenarios[parentComponentName]
        ) {
            /* Error occurred, cannot add a new child to a component that isn't active in the load scenario. */
            return;
        }
        if (
            !this.activeLoadingComponentScenarios[parentComponentName].children
        ) {
            this.activeLoadingComponentScenarios[
                parentComponentName
            ].children = {};
        }
        this.activeLoadingComponentScenarios[parentComponentName].children[
            childComponent.component.userFriendlyName
        ] = {
            isLoaded: false,
            isCritical: childComponent.isCritical
        };
        console.log(
            "(PJM Loading Telemetry:  I've just added this component to the list of children: Parent:" +
            parentComponentName +
            " Component Child: " +
            childComponent.component.userFriendlyName +
            " )"
        );
    }

    /**
     * Removes the given component from the child list, regardless of its completed state or not.
     * This will be called in instances of page size change, when information is being removed from
     * the page due to the page size shrinking/expanding in relation to responsive page design.
     * Very unlikely to be called.
     * @param parentComponentName
     * @param childComponentName
     */
    public removeChildComponentFromLoadingTelemetry(
        parentComponent: IComponent,
        childComponentName: string
    ): void {
        const parentComponentName: string = parentComponent.userFriendlyName;
        let removeFromActive: boolean = true;
        for (const activeScenario in this.activeLoadingComponentScenarios) {
            if (
                this.activeLoadingComponentScenarios[activeScenario].componentName === parentComponentName &&
                this.activeLoadingComponentScenarios[activeScenario].children &&
                this.activeLoadingComponentScenarios[activeScenario].children.length
            ) {
                delete this.activeLoadingComponentScenarios[activeScenario].children[childComponentName]; /* Remove the child from the given parent. */
            } else {
                if (
                    this.activeLoadingComponentScenarios[activeScenario].children &&
                    this.activeLoadingComponentScenarios[activeScenario].children[childComponentName]
                ) {
                    /* Child exists as one of another active scenario's children, so it cannot be removed safely from the active scenarios */
                    /* This is a highly unlikely scenario */
                    removeFromActive = false;
                }
            }
        }
        if (
            removeFromActive &&
            this.activeLoadingComponentScenarios[childComponentName]
        ) {
            /* If actively loading, remove the child from the load scenarios */
            delete this.activeLoadingComponentScenarios[childComponentName];
        }
    }

    /**
     * Updates all current component loading scenarios to inform them
     * of the newly completed component (by component name), in case this component
     * is a child of any other active scenarios.
     * If the updated completion of this component results in the completion of another component,
     * this will kick start the cycle again until all components that are dependant on each other are
     * completed and in the right state.
     * @param newlyCompletedComponent The component name that just completed.
     */
    private updateActiveLoadingComponents(newlyCompletedComponent: string): void {
        if (!this.activeLoadingComponentScenarios) {
            return;
        }
        for (const activeScenario in this.activeLoadingComponentScenarios) {
            if (this.activeLoadingComponentScenarios[activeScenario].children) {
                let completed: boolean = true;
                let isUsable: boolean = true;
                for (const child in this.activeLoadingComponentScenarios[
                    activeScenario
                ].children) {

                    /* Check all children of the active scenarios */
                    if (newlyCompletedComponent === child) {
                        this.activeLoadingComponentScenarios[activeScenario]
                            .children[child].isLoaded = true; /* If matching children exists with the component that just finished, set the child to completed */
                    }
                    if (
                        !this.activeLoadingComponentScenarios[activeScenario].children[child].isLoaded
                    ) {
                        if (this.activeLoadingComponentScenarios[activeScenario].children[child].isCritical) {
                            /* If at least one critical child is not finished yet, set the isUsable flag to false. */
                            isUsable = false;
                        }
                        /* If at least one child is not finished yet, set the complete flag to false. */
                        completed = false;
                    }
                }
                // Check if component already marked as Usable and logging is called
                if (isUsable && !this.activeLoadingComponentScenarios[activeScenario].isComponentUsable) {
                    this.setComponentAsUsable(activeScenario);
                }
                if (completed) {
                    /* If all children are complete, end the active scenario component that had the children */
                    this.endComponentLoadTelemetryTimer(activeScenario);
                }
            }
        }
    }

    /**
     * Set component(active loading scenario) as usable
     * This is for components whose all critical child components have loaded
     * @param componentName component to be marked usable
     */
    private setComponentAsUsable(componentName: string): void {
        if (
            !this.activeLoadingComponentScenarios ||
            !this.activeLoadingComponentScenarios[componentName] || this.activeLoadingComponentScenarios[componentName].isComponentUsable
        ) {
            /* If activeLoadingComponentScenarios is empty or doesnt contain the current scenario or the current scenario has already been marked as completed.
            This can already be completed if a non ritical component ends up loading after all the critical components, then the logic will say the component is already marked as Usable
            and we need to ignore that*/
            return;
        }
        this.activeLoadingComponentScenarios[componentName].isComponentUsable = true;
        const endTime: number = Date.now();
        const totalTime: number = endTime - this.activeLoadingComponentScenarios[componentName].startTime;
        this.activeLoadingComponentScenarios[componentName].usabilityDuration = totalTime;
        let isComponentTopLevelPage: boolean = false;
        if (this.topLevelPage === componentName) {
            isComponentTopLevelPage = true;
            console.log(
                "PJM Loading Telemetry \t\t" +
                componentName +
                " Component just became usable: =========================================" +
                totalTime +
                "ms \n Guid: " +
                this.pageLoadGuid +
                "\n ComponentLoadGuid: " +
                this.activeLoadingComponentScenarios[componentName].componentLoadGuid);
        }
        this.logEvent(
            ComponentPrefix + componentName,
            SourceConstants.Method.SetComponentAsUsable,
            LogEventConstants.PageUsable,
            {
                ComponentLoadTime: totalTime,
                PageLoadGuid: this.pageLoadGuid,
                IsTopLevelPage: isComponentTopLevelPage,
                ComponentLoadGuid: this.activeLoadingComponentScenarios[componentName].componentLoadGuid,
                ComponentUsabilityTime: this.activeLoadingComponentScenarios[componentName].usabilityDuration
            }
        );
    }

    /**
     * If the component is a child of an existing component
     * this method will return the ComponentLoadGuid of the parent component 
     * else it will generate a new UUID and return as the component load guid
     */
    private getComponentLoadGuid(newlyLoadedComponent: string): string {
        if (!this.activeLoadingComponentScenarios) {
            return uuid();
        }

        for (const activeScenario in this.activeLoadingComponentScenarios) {
            if (this.activeLoadingComponentScenarios[activeScenario].pageLoadGuid === this.pageLoadGuid && this.activeLoadingComponentScenarios[activeScenario].children) {
                for (const child in this.activeLoadingComponentScenarios[activeScenario].children) {
                    if (newlyLoadedComponent === child) {
                        return this.activeLoadingComponentScenarios[activeScenario].componentLoadGuid;
                    }
                }
            }
        }

        return uuid();
    }

}
