<section class="col-md-12 col-sm-12 col-lg-12">
    <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'calc(100vh - 125px)'" [hasNonOverflowContent]="true">
        <dm-loading loader [loadingText]="isUpdatingLineItems ? 'Updating Line Items' : 'Loading Line Items'"
            [isCustomText]="true" [showLoading]="showLoading" *ngIf="isComponentLoading">
        </dm-loading>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0"
            *ngIf="!isComponentLoading && purchaseOrdersTableData && purchaseOrdersTableData.length">
            <dm-manage-suppliers-filter [isFilterCheckEnable]="isFilterCheckEnable"
                [poServiceResponse]="purchaseOrderList" (filterChange)="onFilterChange($event)"
                (selectedPoChange)="onSelectedPoChange($event)">
            </dm-manage-suppliers-filter>
        </section>
        <section class="dm-goods-receipt" [ngClass]="{'dm-p-x-0-y-imp-12': purchaseOrdersTableData && purchaseOrdersTableData.length}">
            <section class="row" *ngIf="!isComponentLoading">
                <section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0">
                    <dm-manage-suppliers-table-data *ngIf="purchaseOrdersTableData" [rows]="purchaseOrdersTableData"
                        [isFilterCheckEnable]="isFilterCheckEnable" [selectedPo]="selectedPo"
                        (onSaveProject)="saveProjectDetails($event)"></dm-manage-suppliers-table-data>
                </section>
            </section>
        </section>
        <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
    </dm-tile>
</section>