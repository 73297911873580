<div class="dm-snapshot-modal">
    <label id="snapShotDetails" class="sr-only">Take new snapshot</label>

    <form #newSnapshot>
        <div class="modal-header">
            <h4 class="modal-title font-subtitle pull-left" id="headingModalLabel">Take new snapshot</h4>
            <button type="button" title="close" class="close set-focus" aria-label="Take New Snapshot Dialog Close"
                (click)="closeModal()" id="accessibilityConstants.CloseUpdateButton"
                (keydown)="moveFocusPrev($event, 'accessibilityConstants.ContinuePopupButton')">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </div>
        <dm-loading [loadingText]="'Creating Snapshot: ' + newSnapshotName" *ngIf="isComponentLoading"
            [isCustomText]="true" [showLoading]="showLoading"></dm-loading>
        <div class="modal-body" *ngIf="!isComponentLoading">
            <p>
                Taking a snapshot will create a read-only version of the financial workbook in its current state
                for reference at a later date. This action cannot be undone.
            </p>
            <div class="dm-snapcheckbox">
                <div class="input__checkbox">
                    <input type="checkbox" aria-labelledby="dmSapNameText" [(ngModel)]="isDefaultSnapshotName"
                        class="input__checkbox--focus" id="dmSapName" name="dmSapName" [checked]="isDefaultSnapshotName"
                        (change)="resetFields()">
                </div>
                <section id="dmSapNameText" class="font-caption-alt input__checkbox--text">
                    <label for="dmSapName" class="input__label--text">Use Engagement/SAP Name</label>
                </section>
                <div class="clearfix"></div>
                <section class="snapshotName">
                    <div class="snapinput__text" *ngIf="!isDefaultSnapshotName">
                        <label class="input__label--text" for="snapfield">
                            Snapshot Name
                        </label>
                        <section class="input_label_rel">
                            <input type="text" id="snapfield" required="true" name="dmsnapnameopt" maxlength="100"
                                [(ngModel)]="defaultSnapshotName" aria-invalid="false" aria-labelledby="textMsg"
                                [ngClass]="{'input_error': !defaultSnapshotName,'dmsearchfield_txt': newSnapshotName, 'resetActiveState':activestate, 'resetInActiveState':!activestate }"
                                (blur)="resetfocused = false" autofocus="true" />
                            <label id="textMsg" class="sr-only" aria-hidden="true">
                                Snapshot Name required {{100 - newSnapshotName?.length}} of 100 characters remaining
                            </label>
                            <button type="button" class="no-btn close-icon" aria-label="snapshot name clear"
                                *ngIf="defaultSnapshotName" (blur)="resetfocused = false" (click)="clearText()">
                                <i class="icon icon-cancel"></i>
                            </button>
                            <div class="row">
                                <p class="remaining-char font-caption col-sm-6 col-md-6 col-xs-7">
                                    ({{100 - defaultSnapshotName?.length}} /100 characters remaining)
                                </p>
                            </div>
                        </section>
                        <span role="alert" aria-atomic="true" aria-live="polite" *ngIf="!defaultSnapshotName"
                            class="font-caption-alt input_error_msg errorMsg">
                            {{dmSnapshotErrorMessage.SnapshotNameRequired}}
                        </span>
                    </div>
                </section>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="!isComponentLoading">
            <button type="button" id="accessibilityConstants.Cancel" class="fxp-btn fxp-btn--secondary"
                data-dismiss="modal" (click)="closeModal()"
                (keydown)="moveFocusNext($event, 'accessibilityConstants.ContinuePopupButton')">Cancel</button>
            <button type="button" id="accessibilityConstants.ContinuePopupButton" class="fxp-btn fxp-btn--primary"
                data-dismiss="modal" (click)="copyFinancialPlan()"
                (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseUpdateButton')"
                [disabled]="!(defaultSnapshotName || isDefaultSnapshotName)">Continue</button>
        </div>
    </form>
</div>