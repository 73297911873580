import { Action } from "@ngrx/store";
import { IProjectEngagementDetails } from "../../common/services/contracts/cache.contracts";

// 1. action types
export enum ProjectDetailsActionTypes {
    LOAD_PROJECT_DETAILS = "[ProjectDetails] Load",
    LOAD_PROJECT_DETAILS_SUCCESS = "[ProjectDetails] Load Success",
    LOAD_PROJECT_DETAILS_FAIL = "[ProjectDetails] Load Fail",
    INVALIDATE_PROJECT_DETAILS = "[ProjectDetails] Invalidate"
}

// 2. action creators
export class LoadProjectDetails implements Action {
    public readonly type = ProjectDetailsActionTypes.LOAD_PROJECT_DETAILS;
    public constructor(public projectId: string) { }
}
export class LoadProjectDetailsSuccess implements Action {
    public readonly type = ProjectDetailsActionTypes.LOAD_PROJECT_DETAILS_SUCCESS;
    public constructor(public projectId: string, public projectDetails: IProjectEngagementDetails) { }
}

export class LoadProjectDetailsFail implements Action {
    public readonly type = ProjectDetailsActionTypes.LOAD_PROJECT_DETAILS_FAIL;
    public constructor(public projectId: string, public errorMessage: string) { }
}

export class InvalidateProjectDetails implements Action {
    public readonly type = ProjectDetailsActionTypes.INVALIDATE_PROJECT_DETAILS;
    public constructor(public projectId: string) { }
}


// 3. export the union creators
export type ProjectDetailsActions =
    | LoadProjectDetails
    | LoadProjectDetailsSuccess
    | LoadProjectDetailsFail
    | InvalidateProjectDetails;


