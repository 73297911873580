export interface ITecoPrevalidation {
    validation: string;
    details: string;
    status: TecoPrevalidationStatus;
    comment: string;
}

export enum TecoPrevalidationStatus {
    Evaluating = "Evaluating",
    Passed = "Passed",
    Failed = "Failed",
    ApprovedWithComment = "ApprovedWithComment",
    Invalid = "Invalid"
}

export interface IProjectFinancial { 
    projectId: string;    
    cfpLabor: number;
    actualLabor: number; 
}

export interface IProjectLaborConsumptionViewModel {
    status: TecoPrevalidationStatus;
    failedProjects: IProjectFinancial[];
    passedProjects: IProjectFinancial[];
    projectFinancials: IProjectFinancial[];
}

export interface IPurchaseOrderViewModel {
    poNumber: string;
    totalAmount: number;
    consumedAmount: number;
    invoicedAmount: number;
}

export interface IEngagementPoccValidationViewModel extends IEngagementPoccValidation, ITecoPrevalidationViewModel {
    
}

export interface IUnearnedBalanceValidationViewModel extends IUnearnedBalanceValidation, ITecoPrevalidationViewModel {
    
}

export interface IRevenueLtdValidationViewModel extends IRevenueLtdValidation, ITecoPrevalidationViewModel {
    
}

export interface ICostHoursValidationViewModel extends ICostHoursValidation, ITecoPrevalidationViewModel {
    
}

export interface ISubconValidationViewModel extends ISubconValidation, ITecoPrevalidationViewModel {
    purchaseOrders: IPurchaseOrderViewModel[];
}

export interface IUnbilledBalanceValidationViewModel extends IUnbilledBalanceValidation, ITecoPrevalidationViewModel {
    
}


export interface ITecoPrevalidationViewModel extends ITecoValidation {
    isActive: boolean;    
    isEditMode: boolean;
}


export interface ITecoValidationResult {
    engagementPoccValidationResult: IEngagementPoccValidation;
    unearnedBalanceValidationResult: IUnearnedBalanceValidation;
    revenueLtdValidationResult: IRevenueLtdValidation;
    costHoursValidationResult: ICostHoursValidation;
    subconValidationResult: ISubconValidation;
    unbilledBalanceValidationResult: IUnbilledBalanceValidation;
    engagementEcifConsumedValidationResult: ITecoValidation;
    engagementTimeEnpenseSubmittedValidationResult: ITecoValidation;
}
export interface ITecoValidation {    
    status: TecoPrevalidationStatus;
    comment: string;
    isMandatory: boolean;
    title: string;
}

export interface IEngagementPoccValidation extends ITecoValidation {
    pocc: number;
}

export interface IUnearnedBalanceValidation extends ITecoValidation {
    invoicedRevenue: number;
    recognizedRevenue: number;    
}

export interface IRevenueLtdValidation extends ITecoValidation {
    actualRevenue: number;
    plannedRevenue: number;    
}

export interface ICostHoursValidation extends ITecoValidation {
    actualHours: number;
    plannedHours: number;
}

export interface ISubconValidation extends ITecoValidation {
    ffPO: number;
    gr: number;
    invoiced: number;    
}

export interface IUnbilledBalanceValidation extends ITecoValidation {
    contractualRevenue: number;
    recognizedRevenue: number;
}

export interface IValidationResultEmmiter {
    isValid: boolean;
    result: ITecoValidationResult;
}

export interface ITecoPrevalidationConfiguration {
    recognizedRevenueVsContractRevenueThresholdPercentage: number;
    recognizedRevenueVsInvoiceRevenueThresholdPercentage: number;
    costHoursActualsVsPlannedThresholdPercentage: number;
    poccThresholdPercentage: number;
}





