import { Component, EventEmitter, forwardRef, Inject, Input, Output } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { DMNotificationService } from "../../../../../common/services/dmnotification.service";
import { INotificationMessages } from "../../../../../common/services/contracts/financial.service.contracts";
import { IResponseMessage, IWbsAddTaskDetails } from "../../../../../common/services/contracts/wbs.service.contracts";
import { LogEventConstants, SourceConstants, Components, WBSResponseMessages, AccessibilityConstants } from "../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../../common/services/wbs.service";
import { IProjectDetailsV2, IServiceDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { DmError } from "../../../../../common/error.constants";

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    selector: "dm-wbs-project-service-task-add",
    templateUrl: "./wbs-project-service-task-add.html"
})
export class WbsAddTaskModalComponent extends DmModalAbstract {
    @Input() public selectedService: IServiceDetailsV2;
    @Input() public projectContextData: IProjectDetailsV2[];
    @Input() public isInternal: boolean = false;
    @Output() public onTaskCreated = new EventEmitter<boolean>();
    public wbsL3Name: string = "";
    public wbsl3Description: string = "";
    public taskStartDate: Date;
    public taskEndDate: Date;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public isBeforeServiceStartDate: boolean = false;
    public isAfterServiceEndDate: boolean = false;
    public isDateCompare: boolean = false;
    public minDate: Date;
    public maxDate: Date;
    public accessibilityConstants = AccessibilityConstants;
    public addTaskErrorMessages = DmError.EbsStructure.EditEbsStructure;
    private notificationMessage: INotificationMessages;
    private isModelValid: boolean = false;
    private tempStartDate: Date;
    private tempEndDate: Date;
    private serviceResponseMessages: IResponseMessage;
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsTaskService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
    ) {
        super(activeModal, dmLogger, Components.ManageWBSAddTask);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus("closeRejectButton", true);
        this.configurationService.initialize().then(() => {
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
        });
        this.serviceResponseMessages = WBSResponseMessages.TaskAdded;
        this.minDate = this.selectedService.minDate;
        this.maxDate = this.selectedService.maxDate;
        this.taskStartDate = this.selectedService.startDate;
        this.taskEndDate = this.selectedService.endDate;
        this.setLoadersBasedOnItemState();
    }

    /**
     * Checks if the task's start date is valid when compared to the start and end dates.
     */
    public isTaskStartDateValid(updatedDate: Date): boolean {
        if (!this.isModelValid) {
            this.taskStartDate = updatedDate;
            this.isStartDateRequired = false;
            if (!this.taskStartDate) {
                this.isStartDateRequired = true;
                return false;
            }
            this.isBeforeServiceStartDate = false;

            if (moment(this.taskStartDate).isBefore(this.selectedService.startDate)) {
                this.isBeforeServiceStartDate = true;
                return false;
            }
            return this.isEndDateGreaterThanStartDate(this.taskStartDate, this.taskEndDate);
        } else {
            this.taskStartDate = this.tempStartDate;
            this.taskEndDate = this.tempEndDate;
            return true;
        }
    }

    /**
     * Checks if the task's end date is valid when compared to the start and dates.
     */
    public isTaskEndDateValid(updatedDate: Date): boolean {
        if (!this.isModelValid) {
            this.taskEndDate = updatedDate;
            this.isEndDateRequired = false;
            if (!this.taskEndDate) {
                this.isEndDateRequired = true;
            }
            this.isAfterServiceEndDate = false;

            if (moment(this.taskEndDate).isAfter(this.selectedService.endDate)) {
                this.isAfterServiceEndDate = true;
                return false;
            }

            return this.isEndDateGreaterThanStartDate(this.taskStartDate, this.taskEndDate);
        } else {
            this.taskStartDate = this.tempStartDate;
            this.taskEndDate = this.tempEndDate;
            return true;
        }
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseRejectButton);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(invalid: boolean): boolean {
        const dates: boolean = this.isStartDateRequired ||
            this.isBeforeServiceStartDate ||
            this.isAfterServiceEndDate ||
            this.isEndDateRequired ||
            this.isDateCompare;
        return invalid || dates;
    }

    /**
     * Adds the newly created task to the service by calling the API and refreshing the cache.
     */
    public addNewTask(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.AddNewTask, LogEventConstants.ManageWBSTaskAddSaveClick);
        const taskData: IWbsAddTaskDetails = {
            name: this.wbsL3Name,
            status: this.selectedService.statusCode,
            description: this.wbsl3Description,
            startDate: moment(this.taskStartDate).format(DATE_FORMAT),
            endDate: moment(this.taskEndDate).format(DATE_FORMAT)
        };
        this.isComponentLoading = true;
        this.isModelValid = true;
        this.tempStartDate = this.taskStartDate;
        this.tempEndDate = this.taskEndDate;

        this.wbsTaskService.addTaskDetails(taskData, this.selectedService.projectId, this.selectedService.id)
            .then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.AddNewTask, LogEventConstants.EBSAddTask);
                if (!this.sharedFunctionsService.disableEmailAlertsNotifications()) {
                    let esxpNotification = this.notificationMessage.TaskAddedNotification;
                    esxpNotification = esxpNotification.replace("#", taskData.name);
                    const sendTo: string[] = this.sharedFunctionsService.getListofPjmProjectContextV2(this.projectContextData);
                    this.notificationService.sendESXPNotification(esxpNotification, sendTo);
                }
                this.onTaskCreated.emit(true);
                let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                successMessage = successMessage.replace("#", taskData.name);
                this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                this.closeModal();
            })
            .catch((error) => {
                /* Error for debugging */
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", taskData.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.AddNewTask, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
            });
    }

    /**
     * Is the given end date later than the given start date? True if the end date is later/greater than the start date.
     * False if the end date is earlier/less than the start date.
     */
    private isEndDateGreaterThanStartDate(startDate: Date, endDate: Date): boolean {
        if (startDate && endDate) {
            this.isDateCompare = false;
            if (moment(endDate).isBefore(startDate)) {
                this.isDateCompare = true;
                return false;
            }
        }
        return true;
    }
}


