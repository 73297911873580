import * as fromEngagementChangeRequests from "./engagement-change-requests-v2.reducer";
import { IState } from "../reducers";
import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";
import { IChangeRequestsState } from "../change-requests/change-requests.reducer";

export const getEngagementChangeRequestsV2State = (engagementId: string) => (store: IState): IChangeRequestsState => store.engagementChangeRequestsV2[engagementId] ? store.engagementChangeRequestsV2[engagementId] : fromEngagementChangeRequests.initialState;
export const getEngagementChangeRequestsV2 = (engagementId: string) => (store: IState): IChangeRequest[] => getEngagementChangeRequestsV2State(engagementId)(store).changeRequests;
export const getEngagementChangeRequestsV2Loading = (engagementId: string) => (store: IState): boolean => getEngagementChangeRequestsV2State(engagementId)(store).loading;
export const getEngagementChangeRequestsV2Loaded = (engagementId: string) => (store: IState): boolean => getEngagementChangeRequestsV2State(engagementId)(store).loaded;
export const getEngagementChangeRequestsV2Error = (engagementId: string) => (store: IState): string => getEngagementChangeRequestsV2State(engagementId)(store).error;
