import { Component, Input } from "@angular/core";

@Component({
    selector: "dm-inner-tile",
    templateUrl: "./inner-tile.html",
    styleUrls: ["./inner-tile.scss"]
})
export class DmInnerTileComponent {
    @Input() public data: {title: string; cost: number};
    @Input() public isTwoThirdTile: boolean = false;
}