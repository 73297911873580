<section class="returned-resource-dailog">
    <section class="modal-header">
        <h4 id="returnedRequestHeading" class="modal-title font-subtitle pull-left">
            Returned Resource Request
        </h4>
        <button type="button" id="AccessibilityConstants.CloseReturnModal"
            aria-label="AccessibilityConstants.CloseReturnModal"
            class="close set-focus"
            (click)="closeModal()">
            <span aria-hidden="true" class="icon icon-close"></span></button>
    </section>


    <section class="modal-body font-caption-alt">
        <table width="100%" class="returned-request-table" aria-labelledby="returnedRequestHeading">
            <thead class="returned-request-table--thead">
                <tr>
                    <th scope="col" class="text-left font-caption-alt">Request ID</th>
                    <th scope="col" class="text-left font-caption-alt">Role</th>
                    <th scope="col" class="text-left font-caption-alt">Requested Duration</th>
                    <th scope="col" class="text-left font-caption-alt">Requested Start Date </th>
                    <th scope="col" class="text-left font-caption-alt">Requested End Date </th>
                    <th scope="col" class="text-left font-caption-alt">Return Reason Code </th>
                    <th scope="col" class="text-left font-caption-alt">Returned By </th>
                </tr>
            </thead>
            <tbody class="returned-request-table--tbody" *ngFor="let request of returnedResourceRequestList;">
                <tr class="table-data">
                    <td class="text-left font-caption-alt">
                        {{request.ResourceRequestId | dmDisplayDashIfEmptyOrNull}}
                        <span *ngIf="!request.ResourceRequestId" class="sr-only">{{AccessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </td>
                    <td class="text-left font-caption-alt">
                        {{request.Role | dmDisplayDashIfEmptyOrNull}}
                        <span *ngIf="!request.Role" class="sr-only">{{AccessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </td>
                    <td class="text-left font-caption-alt">
                        {{request.RequestedDuration}} {{request.DurationUnit}}
                    </td>
                    <td class="text-left font-caption-alt">
                        {{request.RequestedStartDate | dmDisplayDateOrDashPipe}}
                        <span *ngIf="!request.RequestedStartDate" class="sr-only">{{AccessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </td>
                    <td class="text-left font-caption-alt">
                        {{request.RequestedEndDate | dmDisplayDateOrDashPipe}}
                        <span *ngIf="!request.RequestedEndDate" class="sr-only">{{AccessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </td>
                    <td class="text-left font-caption-alt">
                        {{request.ReturnReasonCode | dmDisplayDashIfEmptyOrNull}}
                        <span *ngIf="!request.ReturnReasonCode" class="sr-only">{{AccessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </td>
                    <td class="text-left font-caption-alt">
                        {{request.ReturnedBy | dmDisplayDashIfEmptyOrNull}}
                        <span *ngIf="!request.ReturnedBy" class="sr-only">{{AccessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                    </td>
                    <td>
                        <a [href]="'#/project/' + request.ProjectId + '/resource/' + request.ResourceRequestId + '/false/requestor'"
                            class="pull-left"
                            id="{{AccessibilityConstants.EditReturnedRequest}}-{{request?.ResourceRequestId}}"
                            (click)="closeModal()" aria-label="EditRequest">
                            <span class="icon icon-editLegacy"></span>
                        </a>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="returnedResourceRequestList && returnedResourceRequestList.length == 0">
                <tr>
                    <td colspan="5" class="text-center">
                        <p class="font-title">
                            {{ noDatafoundText }}
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</section>