import * as fromActuals from "./actuals.action";
import { ILoadableState } from "../reducers";
import { IUnitsViewModel } from "../../common/services/contracts/actuals.contracts";

export interface IActualsState extends ILoadableState {
    units: IUnitsViewModel[];
}

export const initialState: IActualsState = {
    units: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromActuals.ActualsAction): { [wbsId: string]: IActualsState } {
    switch (action.type) {
        case fromActuals.ActualsActionTypes
            .LOAD_ACTUALS: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromActuals.ActualsActionTypes
            .LOAD_ACTUALS_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                units: action.units,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromActuals.ActualsActionTypes
            .UPDATE_ACTUALS_LOCAL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                units: action.units,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromActuals.ActualsActionTypes
            .LOAD_ACTUALS_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromActuals.ActualsActionTypes
            .INVALIDATE_ACTUALS: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}