import { MisalignmentValidationErrorTypeEnum, MisalignmentVarianceTypeEnum } from "../../../components/amendments/amendments.contract";
import { IBillingMilestoneModel } from "./dmmilestone.service.contract";
import { IExpenseDetails } from "./expense-details.service.contracts";

export interface IMonthlySchedule {
    year: number;
    jan: number;
    feb: number;
    mar: number;
    apr: number;
    may: number;
    jun: number;
    jul: number;
    aug: number;
    sep: number;
    oct: number;
    nov: number;
    dec: number;
}

export interface IWeeklySchedule {
    year: number;
    week1: number;
    week2: number;
    week3: number;
    week4: number;
    week5: number;
    week6: number;
    week7: number;
    week8: number;
    week9: number;
    week10: number;
    week11: number;
    week12: number;
    week13: number;
    week14: number;
    week15: number;
    week16: number;
    week17: number;
    week18: number;
    week19: number;
    week20: number;
    week21: number;
    week22: number;
    week23: number;
    week24: number;
    week25: number;
    week26: number;
    week27: number;
    week28: number;
    week29: number;
    week30: number;
    week31: number;
    week32: number;
    week33: number;
    week34: number;
    week35: number;
    week36: number;
    week37: number;
    week38: number;
    week39: number;
    week40: number;
    week41: number;
    week42: number;
    week43: number;
    week44: number;
    week45: number;
    week46: number;
    week47: number;
    week48: number;
    week49: number;
    week50: number;
    week51: number;
    week52: number;
    week53: number;
    week54: number;
}

export interface IResourceDetail {
    type: string;
    resources: IResource[];
}

export interface IResource {
    resourceRequestId: string;
    demandId: string;
    resourceBPId: string;
    resourceAlias: string;
    isBillable: boolean;
    contractType: string;
    deliveryCompanyCode: string;
    deliveryLocDescription: string;
    deliveryLocCountryCode: string;
    resourceCompanyCode: string;
    resourceLocDescription: string;
    resourceLocCountryCode: string;
    poNumber: string;
    poDescription: string;
    poLineItemNumber: string;
    plannedCostRate: number;
    plannedCost: number;
    staffedCostRate: number;
    staffedCost: number;
    resourceType: string;
    resourceTypeDescription: string;
    rolePartNumber: string;
    rolePartNumberDescription: string;
    billRate: number;
    wbsl3StatusDescription?: string;
    monthlySchedules?: IMonthlySchedule[];
    weeklySchedules?: IWeeklySchedule[];
}

export interface IDemandDetail {
    engagementId: string;
    projectId: string;
    demandId: string;
    demandSourceRequestId?: any;
    resourceItemId: string;
    demandSourceServiceId?: any;
    serviceId: string;
    wbsl3Id: string;
    wbsl3StatusCode: string;
    wbsl3StatusDescription: string;
    domain: string;
    domainKey: string;
    domainText: string;
    schedulePattern: string;
    schedulePatternDescription: string;
    resourceDetails: IResourceDetail[];
}

export interface IWbsL3s {
    billable?: string;
    engagementId: string;
    projectId: string;
    serviceId: string;
    wbsl3Id: string;
    wbsl3Description: string;
    wbsl3Type: string;
    wbsL3Name: string;
    description: string;
    startDate: Date;
    endDate: Date;
    statusCode: string;
    statusDescription: string;
    demandDetails: IDemandDetail[];
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    expenseDetails?: IExpenseDetails[];
    entityType?: string;
    userStatusCode?: string;
    userStatusDescription?: string;
    costObject?: string;
    costObjectType?: string;
    chargeType?: string;
    chargeTypeValue?: string;
    userRequestedEbsState?: string;
}

export interface IService {
    actualEndDate?: Date;
    actualStartDate?: Date;
    billable?: string;
    compassOneServiceId: string;
    domain: string;
    endDate: Date;
    engagementId: string;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    projectId: string;
    resultAnalysisKey?: string;
    serviceDescription: string;
    serviceId: string;
    serviceName: string;
    serviceType: string;
    startDate: Date;
    statusCode: string;
    statusDescription: string;
    userStatusCode?: string;
    userStatusDescription?: string;
    userRequestedEbsState?: string;
    wbsL3s?: IWbsL3s[];
}

export interface IWbsEditServiceDetails {
    status?: string;
    startDate?: string;
    endDate?: string;
}

export interface ICustomerDetail {
    customerId: string;
    customerCountry: string;
    customerState: string;
    customerCity: string;
    customerPostalCode: string;
    customerName: string;
}

export interface IContractDetail {
    opportunityId: string;
    contractId: string;
    contractCountry: string;
    startDate: Date;
    endDate: Date;
}

export interface IProjectList { // duplicate name
    companyCode: string;
    compassOnePackageId: string;
    contractDetail: IContractDetail;
    costCenter: string;
    customerDetail: ICustomerDetail;
    dealId: string;
    demandSourceId?: any;
    domain: string;
    endDate: Date;
    engagementId: string;
    isPublicSector: boolean;
    lastUpdatedDate: Date;
    orginalPackageEndDate: Date;
    orginalPackageStartDate: Date;
    originalEndDate: Date;
    originalStartDate: Date;
    pjMAlias: string;
    pjMbpId: string;
    pjMName: string;
    pPjMAlias: string;
    pPjMbpId: string;
    pPjMName: string;
    projectDescription: string;
    projectId: string;
    projectName: string;
    services?: any[];
    startDate: Date;
    statusCode: string;
    statusDescription: string;
    typeOfContract: string;
}

export interface IEngagementDetails {
    /* Optional attributes here indicate that they were not part of an original API response,
    and when this contract is eventually separated from the API contract, these values will be moved. */
    additionalPPJMs: IAdditionalPPJM[];
    billable?: string;
    canEditEnagagement?: boolean;
    canRequestEbsStateChange?: boolean;
    canViewChronosLabor?: boolean;
    canViewEBS?: boolean;
    canViewEngagement?: boolean;
    canViewFinance?: boolean;
    canViewInvoice?: boolean;
    canViewPurchaseOrder?: boolean;
    canViewStaffing?: boolean;
    companyCode: string;
    companyCodeArea?: string;
    companyName: string;
    contractDetail: IContractDetail;
    costCenter: string;
    createdGroup?: string;
    currency: string;
    customerDetail: ICustomerDetail;
    dealId: string;
    delegatedPPjMAlias: string;
    delegatedPPjMbpId: string;
    delegatedPPjMName: string;
    delegationType?: string;
    description: string;
    endDate: Date;
    engagementId: string;
    entityType?: string;
    hasAssociatedEngagements?: boolean; // only applicable for customer facing engagements
    isConfidentialDeal: boolean;
    isInternalEngagment?: boolean; // todo fix spelling of 'engagement'
    isPinned?: boolean;
    isPublicSector: boolean;
    isUsPubSec?: boolean;
    /* isUsPubSec is not included in EngagementDetails straight from the API.
    Instead you must call the verifyIsUsPubSec method from SharedFunctions and pass in the pubSecCode. */
    lastUpdatedBy?: string;
    lastUpdatedDate: Date;
    name: string;
    nbueApprovalDocumentLink?: string;
    opportunityId: string;
    pjMAlias?: string;
    pjMName?: string;
    plant?: string;
    pPjMAlias: string;
    pPjMbpId: string;
    pPjMName: string;
    primaryDomain: string;
    primaryProduct?: any;
    profitCenter?: string;
    projects?: IProjectDetails[];
    projectTypeCode: string;
    projectTypeDescription: string;
    pubSecCode: string;
    relationshipManagerAlias?: string;
    relationshipManagerName?: string;
    srCrossCharge?: string;
    srCrossChargeType?: string;
    startDate: Date;
    stateCode: string;
    stateDescription: string;
    topParentId?: string;
    userRequestedEbsState?: string;
    userStatusCode: string;
    userStatusDescription: string;
}

export interface IAdditionalPPJM {
    engagementId: string;
    pPjMAlias: string;
    pPjMBpId: string;
    pPjMName: string;
}

export interface IAdditionalPJM {
    projectId: string;
    pjMAlias: string;
    pjMbpId: string;
    pjMName: string;
}

export interface IProjectDetails {
    additionalPJMs?: IAdditionalPJM[];
    additionalPPJMs?: IAdditionalPPJM[];
    billable?: string;
    canEditProject?: boolean;
    canEditPurchaseOrder?: boolean;
    canRequestEbsStateChange?: boolean;
    canViewChronosLabor?: boolean;
    canViewEBS?: boolean;
    canViewFinance?: boolean;
    canViewInvoice?: boolean;
    canViewProject?: boolean;
    canViewPurchaseOrder?: boolean;
    canViewStaffing?: boolean;
    companyCode?: string;
    companyCodeArea?: string;
    companyName?: string;
    compassOnePackageId?: string;
    contractDetail?: IContractDetail;
    costCenter?: string;
    currency?: string;
    customerDetail?: ICustomerDetail;
    dealId?: string;
    delegatedPjMAlias?: string;
    delegatedPjMbpId?: string;
    delegatedPjMName?: string;
    delegatedPPjMAlias?: string;
    delegatedPPjMbpId?: string;
    delegatedPPjMName?: string;
    delegationType?: string;
    demandSourceId?: string;
    domain: string;
    endDate: Date;
    engagementEndDate?: Date;
    engagementId: string;
    engagementName: string;
    engagementStartDate?: Date;
    entityType?: string;
    ioEndDate?: Date;
    isConfidentialDeal: boolean;
    hasUnitBasedDemands?: boolean;
    isPinned?: boolean;
    isPublicSector: boolean;
    isUsPubSec: boolean;
    isMarkedForDeletion?: boolean;
    lastUpdatedBy?: string;
    lastUpdatedDate?: Date;
    nbueApprovedHours?: number;
    orginalPackageEndDate?: Date;
    orginalPackageStartDate?: Date;
    originalEndDate?: Date;
    originalStartDate?: Date;
    pjMAlias?: string;
    pjMbpId?: string;
    pjMName?: string;
    plant?: string;
    pPjMAlias?: string;
    pPjMbpId?: string;
    pPjMName?: string;
    primaryProduct?: string;
    profitCenter?: string;
    projectDescription: string;
    projectId: string;
    projectName: string;
    projectTypeCode?: string;
    projectTypeDescription?: string;
    services?: IService[];
    startDate: Date;
    statusCode?: string;
    statusDescription?: string;
    totalTasksCount?: number;
    typeOfContract?: string;
    typeColorCode?: string;
    userStatusCode?: string;
    userStatusDescription?: string;
    userRequestedEbsState?: string;
    pubSecCode?: string; /// Optional as internal engagements dont have this
    relationshipManagerAlias?: string;
    relationshipManagerName?: string;
}

// Object Named as per the API Spec provided by Project Service
// http://aurorapm2appservicefarm-site-test-poc.azurewebsites.net/swagger/ui/index.html#!/Project/Returns_Project_List_for_given_EngagementID
export interface IProjectHeader {
    services: IService[];
    projectName: string;
    projectId: string;
    engagementId: string;
    projectDescription: string;
    domain: string;
    startDate: Date;
    endDate: Date;
    originalStartDate: Date;
    originalEndDate: Date;
    isPublicSector: boolean;
    pjMbpId: string;
    pjMAlias: string;
    pjMName: string;
    pPjMbpId: string;
    pPjMAlias: string;
    pPjMName: string;
    dealId: string;
    typeOfContract: string;
    statusCode: string;
    statusDescription: string;
    lastUpdatedDate: Date;
    compassOnePackageId: string;
    orginalPackageStartDate: Date;
    orginalPackageEndDate: Date;
    costCenter: string;
    companyCode: string;
    demandSourceId: string;
    customerDetail?: ICustomerDetail;
    contractDetail?: IContractDetail;
}

export interface ICopyFinancialPlan {
    engagementId: string;
    newVersionId: string;
    baseLineType: string;
}

export interface ISearchResultsObject {
    engagementDetails: IEngagementSearchResult[];
    confidentialEngagementsCount: number;
}

export interface IEngagementSearchResult {
    additionalPpjMs?: IAdditionalPPJM[];
    companyCode?: string;
    contractType?: string;
    currency?: string;
    customerCountry?: string;
    customerID: number;
    customerName: string;
    delegatedPPjMAlias?: any;
    delegatedPPjMName?: any;
    delegatedPPjMbpId?: any;
    delegationAllocationType?: string;
    delegationDetails?: IDelegationObject;
    description: string;
    endDate: string;
    engagementId: string;
    geo?: string;
    hasAssociatedEngagements?: boolean;
    internalEngagmentType?: string;
    isAssigned?: boolean;
    isConfidentialDeal: boolean;
    isExternalDomain?: boolean;
    isInternalEngagment?: boolean; // todo fix spelling of 'engagement'
    isPinned?: boolean;
    isPublicSector: boolean;
    isUsPubSec: boolean;
    name: string;
    opportunityID: string;
    pPjMAlias: string;
    pPjMName: string;
    pPjMbpId: string;
    primaryDomain: string;
    projectDetails: IProjectSearchResult[];
    projectTypeCode?: string;
    projectTypeDescription?: string;
    pubSecCode: string;
    showLinkToUser?: boolean;
    startDate: string;
    stateCode: string;
    stateDescription: string;
    typeColorCode?: ITypeColorCode;
    userStatusCode?: any;
    userStatusDescription?: string;
}

export interface IProjectSearchResult {
    additionalPjMs?: IAdditionalPJM[];
    companyCode?: string;
    contractType: string;
    currency?: string;
    delegatedPjMAlias?: any;
    delegatedPjMName?: any;
    delegatedPjMbpId?: any;
    delegationAllocationType?: string;
    delegationDetails?: IDelegationObject;
    description: string;
    domain: string;
    endDate: string;
    engagementId: string;
    engagementName?: string;
    geo?: string;
    isAssigned?: boolean;
    isConfidentialDeal: boolean;
    isExternalDomain?: boolean;
    isPinned?: boolean;
    isPublicSector: boolean;
    isUsPubSec: boolean;
    isMarkedForDeletion?: boolean;
    lastUpdatedName?: string;
    lastUpdateddate: string;
    name: string;
    pjMAlias: string;
    pjMName: string;
    pjMbpId: string;
    projectId: string;
    projectTypeCode?: string;
    projectTypeDescription?: string;
    showLinkToUser?: boolean;
    startDate: string;
    stateCode: string;
    stateDescription: string;
    typeColorCode?: ITypeColorCode;
    userStatusCode: string;
    userStatusDescription: string;    
}

export interface IEngagementSearchInputObject {
    engagementId?: string;
    projectId?: string;
    engagementName?: string;
    projectName?: string;
    customerName?: string;
    ppjmOrPjmBpid?: string;
    opportunityId?: string;
    hasAssociatedEngagements?: boolean;
}

// todo: duplicate of IDelegationPortFolioDetails in portfolio.model.ts ?
export interface IDelegationObject {
    delegationStatus?: string;
    delegationStartDate?: Date;
    delegationEndDate?: Date;
    delegationToFrom?: string;
    delegationFullName?: string;
}

export interface ITypeColorCode {
    "background-color"?: string;
    color?: string;
}

export interface IContractType {
    typeShortForm: string;
    typeFullForm: string;
    typeColorCode: ITypeColorCode;
}

export interface IExcludedPeriod {
    period: number;
    year: number;
}

export interface IForecastVersion {
    id: string;
    description: string;
    versionType: string;
    createdBy: string;
    createdOn: Date;
    updatedBy: string;
    updatedOn: Date;
    status: string;
}

export interface IVersionStatus {
    contractBaselineStatus: string;
    deliveryBaselineStatus: string;
    currentFinancialPlanStatus: string;
}

export interface IPlanForecastDataParameters {
    period: {
        planId: string;
        variant: string;
        version: string;
        startDate: Date;
        endDate: Date;
        startPeriod: string;
        endPeriod: string;
        forecastPeriod: string;
    };
    excludedPeriods: IExcludedPeriod[];
    extension: [
        { key: string; value: string }
    ];
    versions: IForecastVersion[];
    financialPlanUpdates: IFinancialPlanUpdate[];
}

export interface IPlanForecastPeriodObject {
    endDate: Date;
    endPeriod: string;
    forecastPeriod: string;
    planId: string;
    startDate: Date;
    startPeriod: string;
    variant: string;
    version: string;
}

export interface IFinancialPlanUpdate {
    savedOnUtc: Date;
    planVersion: string;
    savedby: string;
    savedView: string;
    savedByUserName: string;
}

export interface IPlanForecastExcludedPeriodsObject {
    excludedYear: number;
    planId?: any;
}

/// CLINSLIN Api Response
export interface IFundingDetail {
    wbsId: string;
    currency: string;
    fundingId: string;
    totalFunding: string;
    unitOfMeasurement: string;
    rolePartNumber: string;
    resourceItemId: string;
    billRate: string;
    clinSlinId: string;
    priority: number;
    validFrom: Date;
    validTo: Date;
    fundedAmount: string;
    fundedQuantity: number;
    billedAmount: string;
    billedQuantity: number;
    unBilledAmount: string;
    unBilledQuantity: number;
    clinSlinFundingId: string;
}

export interface IClinSlinApiResponse {
    fundingDetails: IFundingDetail[];
}

/// Activate Engagement Response Interface

export interface IActivateEngagementResponse {
    entityId: string;
    isReleased: boolean;
    isFinilized: boolean;
    isCreated: boolean;
    isActivated: boolean;
    validationResultset: IValidationResultset[];
}

export interface IValidationResultset {
    projectId: string;
    projectDescription: string;
    marginVarianceValue: string;
    marginVarianceThreshold: string;
    marginVarianceResult: boolean;
    revenueVarianceValue: string;
    revenueVarianceThreshold: string;
    revenueVarianceResult: boolean;
    costVarianceValue: string;
    costVarianceThreshold: string;
    costVarianceResult: boolean;
}

/// Cost Rate API Input and Output
export interface ICostRateInput {
    resourceBpid: string;
    activityType?: string;
    rolePartNumber?: string;
    projectId: string;
    dateFrom?: string;
    dateTo?: string;
    currency?: string;
}

/// Cost Rate API Output
export interface IResourceCostRate {
    resourceBpid: string;
    fiscalYear: number;
    period01: number;
    period02: number;
    period03: number;
    period04: number;
    period05: number;
    period06: number;
    period07: number;
    period08: number;
    period09: number;
    period10: number;
    period11: number;
    period12: number;
}

export interface ICostRateResourceDetail {
    resourceBpid: string;
    resourceType: string;
    activityType: string;
    rolePartNumber: string;
    currency: string;
    resourceCostRates: IResourceCostRate[];
}

export interface ICostRateApiOutput {
    costRateResourceDetails: ICostRateResourceDetail[];
    errors?: [{
        id: string;
        isSuccess: boolean;
        messages: string[];
    }];
}

export interface IWbsDetails {
    wbsId: string;
    properties: IProperties[];
}

export interface IProperties {
    [property: string]: string;
}

/**
 * WBS List for the logged in user. This is the contract from the API. Do not edit this contract.
 */
export interface IWBSListV2 {
    engagements: IEngagementListObjectV2[]; /* This gives us the engagements and the projects assigned. Use this attribute. */
    projects: IProjectListObjectV2[]; /* Should always be empty. This is not used by our application: it gets ONLY the projects assigned to the user, rather than the engagement-project structure*/
}

/**
 * Engagement List for the logged in user. This is the contract from the API. Do not edit this contract.
 */
export interface IEngagementListObjectV2 {
    companyCode: string;
    companyName: string;
    customerId: string;
    customerName: string;
    delegatedPjmAlias: string;
    delegatedPjmBpid: string;
    delegatedPjmName: string;
    description: string;
    endDate: string;
    engagementTypeCode: string;
    engagementTypeDescription: string;
    id: string;
    isConfidential: boolean;
    isPublicSector: boolean;
    isSensitive: boolean; /* This is not used in PJM */
    lastUpdatedDate: string;
    name: string;
    pjmAlias: string;
    pjmBpid: string;
    pjmName: string;
    primaryDomain: string;
    publicSectorCode: string;
    startDate: string;
    statusCode: string;
    statusDescription: string;
    userStatusCode: string;
    userStatusDescription: string;
    projects: IProjectListObjectV2[];
    currentStatusCode: string;
    currentStatus: string;

}

/**
 * Project List for the logged in user. This is the contract from the API. Do not edit this contract.
 */
export interface IProjectListObjectV2 {
    companyCode: string;
    companyName: string;
    contractType: string;
    costCenter: string;
    customerId: string;
    customerName: string;
    delegatedPjmAlias: string;
    delegatedPjmBpid: string;
    delegatedPjmName: string;
    description: string;
    endDate: string;
    engagementId: string;
    engagementName: string;
    id: string;
    isPublicSector: boolean;
    lastUpdatedDate: string;
    name: string;
    pjmAlias: string;
    pjmBpid: string;
    pjmName: string;
    projectDomain: string;
    projectTypeCode: string;
    projectTypeDescription: string;
    startDate: string;
    statusCode: string;
    statusDescription: string;
    userStatusCode: string;
    userStatusDescription: string;    
    currentStatusCode: string;
    currentStatus: string;
}

/// Validate Engagement Response Interface

export interface IValidateEngagementResponse {
    engagementId: string;
    engagementStatus: string;
    deliveryBaselineStatus: string;
    projectThresholdValues: IProjectThresholdValues[];
}

export interface IProjectThresholdValues {
    projectId: string;
    projectDescription: string;
    amendmentThresholds: IAmendmentThresholdValues[];
    marginPercentage: string;
    marginThresholdRange: string;
    isMarginValid: boolean;
    revenueMargin: string;
    revenueThresholdRange: string;
    isRevenueValid: boolean;
    costMargin: string;
    costThresholdRange: string;
    isCostValid: boolean;
    contractType?: string;
    typeColorCode?: ITypeColorCode;
}

export interface IAmendmentThresholdValues {
    amendmentPercentage: string;
    amendmentThresholdRange: string;
    amendmentVarianceType: MisalignmentVarianceTypeEnum;
    amendmentErrorType: MisalignmentValidationErrorTypeEnum;
}

/**
 * Contract for Export to Excel from Plan & Forecast
 */
export interface IExportToExcelResult {
    /* keeping all the props as 'any' since it's a complex contract and we're not doing any processing on the PJM UX. 
    Just sending the data to Project Service */
    summary: any[];
    labor: any;
    subCon: any;
    expenseAndMaterials: any;
    unit: any;
    forecastDataDumpData: any;
    poccRevenue: any;
    isProjectContext: boolean;
    /* what type of report data this contains, it matches with the ReportType in Project Service.
    We can't change the name financialPlan to reportType because Lumira exports it with this name. */
    financialPlan: ReportType;
    isOnlyUnits: boolean;
    isContainUnits: boolean;
    reportFlags: IReportFlags;
    /* Milestones only used for Fixed Fee export reports. */
    milestones?: IBillingMilestoneModel[]; /* Temporary addition due to auth issues on the back-end. Will remove once we can call the milestones API from the function app on the back end.*/
    reportGenerationLevel?: SapDataLevel;  // Represents the level at which the desired report should be generated (currently applicable for Financial Analysis)
}

/* Correspond with  ReportType in Project Service API codebase*/
export enum ReportType {
    Forecast = "Forecast",
    ContractBaseline = "ContractBaseline",
    DeliveryBaseline = "DeliveryBaseline",
    CurrentFinancialPlan = "CurrentFinancialPlan",
    TimeAndMaterial = "TimeAndMaterial",
    FixedFee = "FixedFee",
    Financials = "Financials",
    CostAndBillRates = "CostAndBillRates"
}

export enum SapDataLevel {
    // Reprensents Engagement or L0
    Engagement,

    // Reprensents Project or L1
    Project,

    // Reprensents Service or L2
    Service,

    // Reprensents Task or L3
    Task,

    // Reprensents Demand or L4
    Demand,
}

export interface IReportFlags {
    financialPlanReport: boolean;
    tmStatusReport: boolean;
    ffStatusReport: boolean;
    forecastDataDumpReport: boolean;
}