<section class="fcr-subcon-form">
    <dm-loading [loadingText]="loadingMessage" [isCustomText]="true" [showLoading]="!fcrSubconFormControlData"
        *ngIf="!fcrSubconFormControlData">
    </dm-loading>
    <ng-container *ngIf="fcrSubconFormControlDataError && !fcrSubconFormControlData">
        <dm-service-error class="server-error" *ngIf="fcrSubconFormControlDataError"
            [dataText]="fcrSubconFormControlDataError" [showReloadMessage]="false"
            [ngbTooltipContent]="fcrSubconFormControlDataError">
        </dm-service-error>
    </ng-container>
    <ng-container *ngIf="fcrSubconFormControlData">
        <section class="justify-space-between">
            <section *ngIf="isEditable" class="dm-btn-holder dm-p-y-10">
                <button type="button" class="fxp-btn fxp-btn--primary dm-btn dm-btn--big" id="addNewSubcon"
                    (click)="addNewSubcon()">
                    <i class="icon icon-AddLegacy"></i>
                    <span>Adjusting SubconFF</span>
                </button>
            </section>
            <section class="pull-right display-grid">
                <div class="font-caption-alt" style="font-style: italic;">Auto Populated Role<span
                        class="color-legend auto-populated-role"></span>
                </div>
                <div class="font-caption-alt" style="font-style: italic;">Manually Added Role<span
                        class="color-legend manually-added-role"></span>
                </div>
                <div class="font-caption-alt" style="font-style: italic;">Plan & Forecast Edited Role<span
                        class="color-legend pnf-edit-role"></span>
                </div>
            </section>
        </section>
        <div *ngIf="isEditable">
            <span style="font-style: italic;">
                <ng-container *ngIf="getExpiredProjects().size > 0">
                    <span class="required-mark" aria-hidden="true">*</span>
                    FCRs cannot be raised for the projects with past end dates, in case you want to extend the end date
                    <span (click)="onEditProjectEndDate()"
                        style="cursor: pointer; text-decoration: underline; color: blue;">
                        click here
                    </span>
                    .
                </ng-container>
            </span>
        </div>
        <div class="fcr-subcon-form-table__grid" role="grid">
            <div id="requestedSubconFFCaption" class="sr-only">Requested subcon FF</div>
            <div role="row" class="fcr-subcon-form-table__grid-row" aria-labelledby="requestedSubconFFCaption">
                <div role="gridcell" class="fcr-subcon-form-table__grid-identifier-cell"></div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell">EBS Description / ID
                    <span class="required-mark"  aria-hidden="true">*</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell">Role
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell">
                    Type of Contract
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell">
                    Existing Cost ({{fcrSubconFormControlData?.planCurrency}})
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell text-align--right">
                    Planned Cost ({{fcrSubconFormControlData?.planCurrency}})
                    <span>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'" [ngbTooltipContent]="'Enter planned cost'"
                            [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Enter planned cost'">
                        </dm-tooltip>
                        <span class="required-mark dm-p-l-5" aria-hidden="true">*</span>
                    </span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell">
                    DB Subcon FF Cost
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="' Existing Delivery Baseline (DB) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="' Existing Delivery Baseline (DB) Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell">
                    CFP SubconFF Cost
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan (CFP) Cost'" [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan (CFP) Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell">
                    Additional SubconFF Cost ({{fcrSubconFormControlData?.planCurrency}})
                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'"
                        [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Revised Current Financial Plan Cost - Existing Delivery Baseline Cost'">
                    </dm-tooltip>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-header-cell" *ngIf="isEditable">
                    Actions
                </div>
            </div>
            <ng-container *ngFor="let subconLine of value; trackBy: trackByFn; let i = index"
                class="ver-al-top fcr-subcon-form-table">
                <div role="row" *ngIf="subconLine" class="fcr-subcon-form-table__grid-row text-size"
                    [ngClass]="{'bg-clr':subconLine.editModeEnabled}">
                    <div role="gridcell" class="fcr-subcon-form-table__grid-identifier-cell">
                        <section class="identifier-highlight"
                            [ngClass]="{ 'auto-populated-role': subconLine?.isAutoPopulated && !subconLine?.isPnfEdit, 'manually-added-role': !subconLine?.isAutoPopulated, 'pnf-edit-role': subconLine?.isAutoPopulated && subconLine?.isPnfEdit }">
                        </section>
                    </div>
                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <section *ngIf="!subconLine?.editModeEnabled">
                            <ng-container *ngIf="subconLine.currentState?.assignedTask">
                                <span class="font-weight-600">{{subconLine.currentState?.assignedTask?.projectName + '
                                    - '
                                    +
                                    subconLine.currentState?.assignedTask?.serviceName}}</span>
                                <br>
                                <span class="color-07"
                                    [textContent]="subconLine.currentState?.assignedTask?.wbsl3Id"></span>
                            </ng-container>
                        </section>
                        <section class="w-100" *ngIf="subconLine.isNewRequest && subconLine.editModeEnabled">
                            <!-- <select id="addNewEBSId_{{i}}" class="input__select font-caption-alt"
                                aria-label="Add EBS ID" [ngModel]="subconLine?.currentState.assignedTask"
                                (ngModelChange)="onSelectedTaskChange($event, subconLine, fcrSubconFormControlData.engagementDetails)"
                                [name]="'assignedTask-' + subconLine.uuid" required>
                                <option *ngFor="let item of fcrSubconFormControlData.existingTasks"
                                    [ngClass]="{'disabled-color': item.isCrPendingInProject}" [ngValue]="item">
                                    {{item.wbsl3Id}} | {{item.projectName}} - {{item.serviceName}}
                                </option>
                            </select> -->

                            <select id="adjustEBSId_{{i}}" class="input__select font-caption-alt"
                                aria-label="Add EBS ID" [ngModel]="subconLine?.currentState.existingResource"
                                (ngModelChange)="onExistingSubconChange($event, subconLine, fcrSubconFormControlData.engagementDetails)"
                                [name]="'assignedTask-' + subconLine.uuid" required>
                                <option *ngFor="let item of fcrSubconFormControlData.existingResources"
                                    [ngClass]="{'disabled-color': item.isCrPendingInProject || !validateResourceProjectEndDate(item.structureId)}"
                                    [disabled]="!validateResourceProjectEndDate(item.structureId)"
                                    [title]="!validateResourceProjectEndDate(item.structureId) ? 'This project has an expired end date' : ''"
                                    [ngValue]="item">
                                    <div>
                                        Task: {{item.structureId}} | Cost: {{item.plannedCost}}
                                    </div>
                                </option>
                            </select>
                        </section>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite" *ngIf="subconLine.currentState.isCrPendingInProject">
                            {{fcrErrorMessages.PendingCrInProject}}
                        </p>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <section [textContent]="'SubconFF'">
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <span *ngIf="subconLine.currentState.assignedTask" class="dm-badge dm-badge-v2 dm-contract-type"
                            [ngClass]="subconLine.currentState.assignedTask && subconLine.currentState.assignedTask?.isFixedFeeProject ? 'contract-type-ff' : 'contract-type-tm'">{{
                            subconLine.currentState.assignedTask?.isFixedFeeProject ? "FF" : "T&amp;M"}}</span>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <section *ngIf="subconLine.currentState.existingResource"
                            [textContent]="subconLine?.currentState?.existingResource?.existingCost || 0">
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <section *ngIf="!subconLine.editModeEnabled" class="text-align-right"
                            [textContent]="subconLine?.currentState.newPlannedCost">
                        </section>
                        <section
                            *ngIf="subconLine.editModeEnabled && (subconLine.currentState.assignedTask || subconLine.currentState.existingResource)">
                            <input type="text" id="plannedCost_{{i}}" aria-label="Enter Planned Cost"
                                class="dm-input font-caption-alt text-align-right" required dmLimitNumeric
                                wholeNumberLength="9" [(ngModel)]="subconLine.currentState.newPlannedCost"
                                [name]="'plannedCost_-' + subconLine.uuid"
                                (ngModelChange)="onPlannedCostChange(subconLine)">

                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite" *ngIf="subconLine?.currentState?.isPlannedCostLessThanTotalPo">
                                {{fcrErrorMessages?.PlannedCostCantBeLessThanPOPosted}}.
                            </p>
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <section *ngIf="subconLine.currentState.existingResource">
                            {{subconLine.currentState.existingResource?.dbCost | number : '1.2-2'}}
                            {{subconLine.currentState.existingResource?.dbCost ?
                            fcrSubconFormControlData?.planCurrency
                            : ""}}
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <section>
                            {{subconLine.isDbOnlyDemand ? (0 | number : '1.2-2') :
                            (subconLine.currentState.existingResource?.cfpCost + subconLine.currentState.newPlannedCost
                            |
                            number :
                            '1.2-2')}} {{fcrSubconFormControlData?.planCurrency}}
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <section>
                            {{(subconLine.isDbOnlyDemand ? 0 :
                            ((subconLine.currentState.existingResource?.cfpCost +
                            subconLine.currentState.newPlannedCost) - subconLine.currentState.existingResource?.dbCost)
                            || 0) | number : '1.2-2'}}
                        </section>
                    </div>

                    <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                        <div class="dm-btn-holder" *ngIf="!subconLine.editModeEnabled && isEditable">
                            <button *ngIf="!subconLine.isDbOnlyDemand" type="button" id="editBtn_{{i}}"
                                aria-label="Edit" class="dm-transparent-btn dm-accent-color"
                                (click)="onEditClick(subconLine, i)">
                                <i class="icon icon-editLegacy"></i>
                                <span>Edit</span>
                            </button>
                            <dm-tooltip *ngIf="subconLine.isDbOnlyDemand" role="tooltip" class="dm-tooltipinline"
                                [dmPlacement]="'left'" [dmTriggers]="'hover'"
                                [ngbTooltipContent]="'This autopopulated demand cannot be edited. This demand was autopopulated to reconcile DB and CFP for a demand which exists on Delivery Baseline but does not exist on Current Financial Plan. This will remove the demand in Delivery Baseline.'"
                                [dmIcon]="'icon icon-info'" ariaLabelToolTip="' Delivery Baseline (DB) Demand'">
                            </dm-tooltip>
                            <button type="button" id="deleteBtn_{{i}}" aria-label="Delete"
                                class="dm-transparent-btn dm-accent-color"
                                [disabled]="subconLine.isPnfEdit || subconLine.isAutoPopulated"
                                (click)="deleteRequest($event, i);">
                                <i class="icon icon-delete"></i>
                                <span>Delete</span>
                            </button>
                        </div>
                        <div class="dm-btn-holder" *ngIf="(subconLine.editModeEnabled) && isEditable">
                            <button type="button" id="saveBtn_{{i}}" aria-label="Save"
                                class="dm-transparent-btn dm-accent-color" [disabled]="!subconLine.isSaveable"
                                (click)="saveRequest(i);">
                                <i class="icon icon-save"></i>
                                <span>Save<span class="required-field">*</span></span>
                            </button>
                            <button type="button" id="cancelBtn_{{i}}" aria-label="Cancel"
                                class="dm-transparent-btn dm-accent-color"
                                (click)="subconLine.editModeEnabled=false; discardChanges($event, i)">
                                <i class="icon icon-close"></i>
                                <span>Cancel</span>
                            </button>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite" *ngIf="!subconLine.currentState.isContractTypeValid">
                                {{invalidContractTypeMessage}}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div role="row" class="fcr-subcon-form-table__grid-row" aria-labelledby="requestedSubconCaption">
                <div role="gridcell" class="fcr-subcon-form-table__grid-identifier-cell"></div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                    <span class="font-weight-600">Totals</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell text-align--right">
                    <span class="font-weight-600">{{totalPlannedSubconCost | number : '1.2-2'}}</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell">
                    <span class="font-weight-600">{{totalAdditionalSubconCost | number : '1.2-2'}}</span>
                </div>
                <div role="gridcell" class="fcr-subcon-form-table__grid-body-cell" *ngIf="isEditable">
                    <span class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <span class="sr-only" tabindex="-1" role="alert" *ngIf="statusMessage">{{statusMessage}}</span>
</section>