import { Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class InternalEngagementService extends DmServiceAbstract {
    public updatedNbueSource = new Subject<number>();
    public updatedNbue$ = this.updatedNbueSource.asObservable();

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService){
        super(dmLogger, Services.InternalEngagementService );
    }

    public updateNbue(nbueUpdated: number): void {
        this.updatedNbueSource.next(nbueUpdated);
    }
}
