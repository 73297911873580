import { Inject, Injectable } from "@angular/core";
import { getCurrencySymbol } from "@angular/common";
import { IFinancialDetail, IEngagementFinancial, IProjectFinancial, IFinancialPlan, IProjectFinancialPlans, IFinancial, IFinancialPlanDetail, IEntityFinancials, IEntityFinancialSummary, IEntityFinancialPlanVersion, IFinancialPlanV2 } from "../../components/financial-mgmt/financial.model";
import { IFinancialViewModel, ICceac } from "../../components/project-summary-v2/tiles/project-summary-financials-v2/project.financials.model";
import { FinancialType, BaseLineType, Services } from "../application.constants";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class FinancialService extends DmServiceAbstract {

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(dmLogger, Services.FinancialService);
    }

    /**
     * Gets the consumed cost as a percentage (0 to 100, not 0 to 1)
     * @param details
     */
    public getConsumedPercentage(details: IFinancialDetail[]): number {
        if (this.financialObjExists(details)) {
            return details[0].financials[0].amountConsumedInPercentage;
        }
        return undefined;
    }

    /**
     * Gets the consumed cost as a percentage (0 to 100, not 0 to 1)
     * @param details
     */
    public getConsumedPercentageForV2(eacDetails: IEntityFinancialSummary[], actualDetails: IEntityFinancialSummary[]): number {
        if (this.financialObjExistsForV2(eacDetails) && this.financialObjExistsForV2(actualDetails)) {
            return (actualDetails[0].cost / eacDetails[0].cost) * 100;
        }
        return 0;
    }


    /**
     * Gets the financial object for the given financials detail array and the specific baseline type. Adds the new financial object to the given
     * financial list.
     * @param details
     * @param baseLine
     */
    public addFinancialsToFinancialsViewModelList(financialsList: IFinancialViewModel[], details: IFinancialDetail[], baseLine: string): void {
        if (!financialsList) {
            financialsList = [];
        }
        if (this.financialObjExists(details)) {
            const financialsObj = details[0].financials[0];
            financialsList.push({
                title: this.getTitle(baseLine),
                margin: financialsObj.marginInPercentage,
                effort: financialsObj.hours,
                cost: financialsObj.cost,
                revenue: financialsObj.revenue
            });
        }
    }

    /**
     * Gets the financial object for the given financials detail array and the specific baseline type. Adds the new financial object to the given
     * financial list.
     * @param details
     * @param baseLine
     */
    public addFinancialsToFinancialsViewModelListForV2(financialsList: IFinancialViewModel[], details: IEntityFinancialSummary[], baseLine: string): void {
        if (!financialsList) {
            financialsList = [];
        }
        if (this.financialObjExistsForV2(details)) {
            const financialsObj = details[0];
            financialsList.push({
                title: this.getTitle(baseLine),
                margin: financialsObj.margin,
                effort: financialsObj.labor,
                cost: financialsObj.cost,
                revenue: financialsObj.revenue,
                unit: financialsObj.unit
            });
        }
    }

    /**
     * Gets the currency symbol for the given currency type. Returns undefined if currency type is missing.
     * @param currencyType
     */
    public getCurrencySymbol(currencyType: string): string {
        return currencyType ? getCurrencySymbol(currencyType, "narrow") : undefined;
    }

    /**
     * Gets the Cceac Values from the financial details by filtering by baseLineType and assigning it to the
     * correct cceac value property. (consumed, eac, delivery, etc)
     * @param financialDetailsArray
     */
    public getCceacValues(financialDetailsArray: IFinancialDetail[]): ICceac {
        return {
            consumed: this.getConsumedPercentage(financialDetailsArray.filter((f) => f.baseLineType === "EAC")),
            eac: this.getCost(financialDetailsArray.filter((f) => f.baseLineType === "EAC")),
            currentFinancialPlan: this.getCost(financialDetailsArray.filter((f) => f.baseLineType === "Current")),
            deliveryBaseline: this.getCost(financialDetailsArray.filter((f) => f.baseLineType === "Delivery")),
            contractBaseline: this.getCost(financialDetailsArray.filter((f) => f.baseLineType === "Bid"))
        };
    }

    /**
     * Gets the Cceac Values from the financial V2 details by filtering by version key and assigning it to the
     * correct cceac value property. (consumed, eac, delivery, etc)
     * @param financialDetailsArray
     */
    public getCceacValuesForV2(financialDetailsArray: IEntityFinancialSummary[]): ICceac {
        return {
            consumed: this.getConsumedPercentageForV2(financialDetailsArray.filter((f) => f.versionKey === FinancialType.EAC), financialDetailsArray.filter((f) => f.versionKey === FinancialType.ACTUALS)),
            eac: this.getCostForV2(financialDetailsArray.filter((f) => f.versionKey === FinancialType.EAC)),
            currentFinancialPlan: this.getCostForV2(financialDetailsArray.filter((f) => f.versionKey === FinancialType.CurrentFinancialPlan)),
            deliveryBaseline: this.getCostForV2(financialDetailsArray.filter((f) => f.versionKey === FinancialType.DeliveryBaseline)),
            contractBaseline: this.getCostForV2(financialDetailsArray.filter((f) => f.versionKey === FinancialType.ContractBaseline))
        };
    }

    /**
     * Gets the cost from the given financial details array.
     */
    public getCost(details: IFinancialDetail[]): number {
        if (this.financialObjExists(details)) {
            return details[0].financials[0].cost;
        }
        return undefined;
    }

    /**
     * Gets the cost from the given financial details array.
     */
    public getCostForV2(details: IEntityFinancialSummary[]): number {
        if (this.financialObjExistsForV2(details)) {
            return details[0].cost;
        }
        return undefined;
    }



    /**
     * Gets the isBaselineActive boolean by checking filters on the given project financial details.
     * @param projectFinancialDetails
     */
    public getIsBaselineActive(projectFinancialDetails: IFinancialDetail[]): boolean {
        return (
            projectFinancialDetails.filter((f) => f.baseLineType === "EAC").length &&
            projectFinancialDetails.filter((f) => f.baseLineType === "EAC")[0] &&
            projectFinancialDetails.filter((f) => f.baseLineType === "EAC")[0].financials
        ) ? true : false;
    }

    /**
     * Gets the isBaselineActive boolean by checking filters on the given project financial details.
     * @param projectFinancialDetails
     */
    public getIsBaselineActiveForV2(projectFinancialDetails: IEntityFinancialSummary[]): boolean {
        return (
            projectFinancialDetails.filter((f) => f.versionKey === FinancialType.EAC).length &&
            projectFinancialDetails.filter((f) => f.versionKey === FinancialType.EAC)[0]
        ) ? true : false;
    }

    /**
     * Gets the currency type (USD, CAD, etc.) from the given list of financial details.
     * Returns undefined if there is no currency listed anywhere in the financial details.
     */
    public getCurrencyType(details: IFinancialDetail[]): string {
        if (details && details.length) {
            for (const detail of details) {
                if (detail && detail.financials && detail.financials.length) {
                    const financialObjList = detail.financials;
                    const currencyList = financialObjList.filter((x) => x.currency);
                    if (currencyList.length && currencyList[0].currency) {
                        return currencyList[0].currency;
                    }
                }
            }
        }

        return undefined;
    }

    /**
     * Gets the currency type (USD, CAD, etc.) from the given list of financial Summary details.
     * Returns undefined if there is no currency listed anywhere in the financial details.
     */
    public getCurrencyTypeForV2(details: IEntityFinancialSummary[]): string {
        const contractFinDetails = details.filter((cdetails) => cdetails.versionKey === FinancialType.ContractBaseline);
        if (contractFinDetails && contractFinDetails.length) {
            return contractFinDetails[0].planCurrency;
        }
        return undefined;
    }

    /**
     * Checks if the frequently used financial object within the details array exists or not.
     * @param details
     */
    public financialObjExists(details: IFinancialDetail[]): boolean {
        return (details && details.length && details[0].financials && details[0].financials.length) ? true : false;
    }

    /**
     * Checks if the frequently used financial object within the details array exists or not for V2 Object
     * @param details
     */
    public financialObjExistsForV2(details: IEntityFinancialSummary[]): boolean {
        return (details && details.length) ? true : false;
    }

    /**
     * Checks if the Entity financial exists and also checks if any values of entity financial like labor , margin, revenue exists
     * @param details
     */
    public IsEntityFinancialDetailsExists(entityFinancial: IEntityFinancialSummary[]): boolean {
        if (!entityFinancial || entityFinancial.length === 0 || !entityFinancial[0]) {
            return false;
        } else if (entityFinancial[0].cost === 0) {
            return false;
        }
        return true;
    }

    /**
     * Gets the list of financial details from the engagement financial object or the project financial plan for the given baseline type.
     * @param financialObject
     * @param type
     */
    public getFinancialDetailForBaselineType(financialObject: IEngagementFinancial | IProjectFinancialPlans | IProjectFinancial, type: string): IFinancialDetail[] {
        if (this.financialDetailsExistOnFinancial(financialObject)) {
            if ((financialObject as IProjectFinancialPlans | IProjectFinancial).projectId) {
                return (financialObject as IProjectFinancialPlans | IProjectFinancial).financialDetails.filter((c) => c.baseLineType === type);
            } else {
                return (financialObject as IEngagementFinancial).engagementFinancials[0].financialDetails.filter((c) => c.baseLineType === type);
            }
        }
        return undefined;
    }

    /**
     * Gets the list of financial details from the financial v2 object
     * @param financialObject
     * @param type
     */
    public getFinancialDetailForBaselineTypeForV2Response(financialObject: IEntityFinancials, type: FinancialType): IEntityFinancialSummary[] {
        if (financialObject && financialObject.financialSummary && financialObject.financialSummary.length) {
            return financialObject.financialSummary.filter((finObject) => finObject.versionKey === type);
        }
        return undefined;
    }


    /**
     * Checks if the financialDetails object exists on the engagement or project financial (or financial plan) objects.
     * Returns true if the financialDetails exists and has any values, returns false otherwise (including if the length of the financialDetails array is 0).
     * @param entityFinancial
     */
    public financialDetailsExistOnFinancial(entityFinancial: IEngagementFinancial | IProjectFinancial | IProjectFinancialPlans): boolean {
        if (entityFinancial) {
            if ((entityFinancial as IProjectFinancialPlans | IProjectFinancial).projectId) {
                const projectFinancial: IProjectFinancial | IProjectFinancialPlans = (entityFinancial as IProjectFinancialPlans | IProjectFinancial);
                if (projectFinancial.financialDetails && projectFinancial.financialDetails.length) {
                    return true;
                }
            } else {
                const engagementFinancial: IEngagementFinancial = (entityFinancial as IEngagementFinancial);
                if (engagementFinancial.engagementFinancials && engagementFinancial.engagementFinancials.length && engagementFinancial.engagementFinancials[0].financialDetails) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Get Financial Plan from the given engagement or project financial details based on the baseline type.
     * @param baseLineType
     */
    public getFinancialPlanSingle(baseLineType: string, entityDetails: IEngagementFinancial | IProjectFinancial | IProjectFinancialPlans): IFinancialPlan {
        if (this.financialDetailsExistOnFinancial(entityDetails)) {
            const details: IFinancialDetail[] = this.getFinancialDetailForBaselineType(entityDetails, baseLineType);
            if (this.financialObjExists(details)) {
                const versionId = details[0].financials[0].versionID;
                if ((entityDetails as IProjectFinancial | IProjectFinancialPlans).projectId) {
                    return (entityDetails as IProjectFinancial | IProjectFinancialPlans).financialPlanDetails.filter((p) => p.baseLineType === baseLineType)[0].financialPlans.filter((v) => v.versionId === versionId)[0];
                } else {
                    return (entityDetails as IEngagementFinancial).engagementFinancials[0].financialPlanDetails.filter((p) => p.baseLineType === baseLineType)[0].financialPlans.filter((v) => v.versionId === versionId)[0];
                }
            }
        }
        return undefined;
    }

    /**
     * Get Financial Plan from the given engagement or project financial details based on the baseline type.
     * @param baseLineType
     */
    public getFinancialPlanSingleForV2(baseLineType: BaseLineType, entityDetails: IEntityFinancials): IFinancialPlanV2 {
        if (entityDetails && entityDetails.financialPlanVersions && entityDetails.financialPlanVersions.length) {
            const baseLineDetails = entityDetails.financialPlanVersions.filter((planVersion) => planVersion.baseLineType === baseLineType)[0];
            if (baseLineDetails && baseLineDetails.financialPlans) {
                return baseLineDetails.financialPlans[0];
            }
        }
        return undefined;
    }

    /**
     * Gets the IFinancial object out of the IFinancialDetails array for the given baseline type.
     *
     * @param {string} baselineType
     * @param {IFinancialDetail[]} financialDetails
     * @returns {IFinancial}
     * @memberof FinancialService
     */
    public getFinancialForBaselineType(baselineType: string, financialDetails: IFinancialDetail[]): IFinancial {
        return this.getFinancialObjectFromFinancialDetail(financialDetails.filter((c) => c.baseLineType === baselineType));
    }

    /**
     * Gets IFinancial details for the given baseline type.
     * Also updates the last updated date on the financials.
     * @param entityFinancial
     * @param type
     */
    public getFinancialDetailsFromParent(entityFinancial: IEngagementFinancial | IProjectFinancialPlans | IProjectFinancial, type: string): IFinancial {
        const financialDetails: IFinancialDetail[] = this.getFinancialDetailForBaselineType(entityFinancial, type);
        if (this.financialObjExists(financialDetails)) {
            financialDetails[0].financials[0].lastUpdatedDate = new Date();
            return financialDetails[0].financials[0];
        }
        return undefined;
    }

    /**
     * Gets IEntityFinancialSummary details for the given baseline type.
     * @param entityFinancial
     * @param type
     */
    public getFinancialDetailsFromParentForV2Object(entityFinancial: IEntityFinancials, type: FinancialType): IEntityFinancialSummary {
        if (entityFinancial && entityFinancial.financialSummary && entityFinancial.financialSummary.length) {
            const finDetails: IEntityFinancialSummary[] = entityFinancial.financialSummary.filter((finObject) => finObject.versionKey === type);
            return finDetails[0];
        }
        return undefined;
    }

    /**
     * Determines if forecast link can be shown & key indicators can be set to zero.
     * @param entityFinancial
     * @param type
     */
    public IsEACETCFinancialsExists(eacFinancial: IEntityFinancialSummary, etcFinancial: IEntityFinancialSummary): boolean {
        if (eacFinancial || etcFinancial) {
            return true;
        }
        // TODO: will be removed later
        // } else if (eacFinancial && etcFinancial && eacFinancial.margin === 0 && eacFinancial.labor === 0 && eacFinancial.revenue === 0
        //     && etcFinancial.margin === 0 && etcFinancial.labor === 0 && etcFinancial.revenue === 0) {
        //     return false;
        // }
        return false;
    }

    /**
     * Determines if forecast link can be shown & key indicators can be set to zero.
     * @param entityFinancial
     * @param type
     */
    public IsEACETCFinancialsExistsToShowForecast(eacFinancial: IEntityFinancialSummary, etcFinancial: IEntityFinancialSummary): boolean {       
        if (eacFinancial && etcFinancial && eacFinancial.cost === 0) {
            return false;
        }
        if (!eacFinancial || !etcFinancial) {
            return false;
        }
        return true;
    }

    /**
     * Get SnapShot Details based on the financial Plans
     * @param financialPlanDetails
     */
    public getSortedSnapShoDetails(financialPlanDetails: IFinancialPlanDetail[]): IFinancialPlan[] {
        if (financialPlanDetails.filter((x) => x.baseLineType === "Snapshot")[0] &&
            financialPlanDetails.filter((x) => x.baseLineType === "Snapshot")[0].financialPlans) {
            const snapShotDetails = financialPlanDetails.filter((x) => x.baseLineType === "Snapshot")[0].financialPlans;
            if (snapShotDetails.length > 1) {
                this.sortSnapshotDetails(snapShotDetails);
            }
            return snapShotDetails;
        } else {
            return [];
        }
    }

    /**
     * Get SnapShot Details based on the financial Plans from V2 api
     * @param financialPlanDetails
     */
    public getSortedSnapShoDetailsForV2(financialPlanDetails: IEntityFinancialPlanVersion[]): IFinancialPlanV2[] {
        if (financialPlanDetails.filter((x) => x.baseLineType === "Snapshot") &&
            financialPlanDetails.filter((x) => x.baseLineType === "Snapshot")[0] &&
            financialPlanDetails.filter((x) => x.baseLineType === "Snapshot")[0].financialPlans) {
            const snapShotDetails = financialPlanDetails.filter((x) => x.baseLineType === "Snapshot")[0].financialPlans;
            if (snapShotDetails.length > 1) {
                this.sortSnapshotDetailsForV2(snapShotDetails);
            }
            return snapShotDetails;
        } else {
            return [];
        }
    }

    /**
     * Verifies if the the current financial plan status is active
     * @param currentFinancialPlanDetails
     */
    public verifyIfCurrentFinancialIsActive(currentFinancialPlanDetails: IEntityFinancialPlanVersion): boolean {
        return (
            currentFinancialPlanDetails &&
            currentFinancialPlanDetails.financialPlans && currentFinancialPlanDetails.financialPlans.length &&
            currentFinancialPlanDetails.financialPlans[0].statusDescription.toLowerCase() === "active"
        );
    }

    /**
     * Verifies if the the Delivery financial plan status is finalized
     * @param currentFinancialPlanDetails
     */
    public verifyIfDeliveryBaselineIsFinalized(delBaselineFinancialPlanDetails: IEntityFinancialPlanVersion): boolean {
        return (
            delBaselineFinancialPlanDetails &&
            delBaselineFinancialPlanDetails.financialPlans && delBaselineFinancialPlanDetails.financialPlans.length &&
            delBaselineFinancialPlanDetails.financialPlans[0].statusDescription.toLowerCase() === "finalized"
        );
    }


    /**
     * Gets the financial details out of the given FinancialDetail object array. Returns undefined
     * if it can't be retrieved.
     *
     * @private
     * @param {IFinancialDetail[]} details
     * @returns {IFinancial}
     * @memberof GridDataComponent
     */
    private getFinancialObjectFromFinancialDetail(details: IFinancialDetail[]): IFinancial {
        if (details && details.length > 0 && details[0].financials && details[0].financials.length > 0) {
            return details[0].financials[0];
        }
        return undefined;
    }

    /**
     * Gets the title for the given base line type.
     * @param baseLineType
     */
    private getTitle(baseLineType: string): string { // todo update this to use enum
        if (baseLineType === "Current") {
            return "Current Financial Plan";
        }
        if (baseLineType === "Delivery") {
            return "Delivery Baseline";
        }
        if (baseLineType === "Bid") {
            return "Contract Baseline";
        }
        if (baseLineType === "EAC") {
            return "Estimate at Complete";
        }
        return baseLineType;
    }

    /**
     * Sort Snapshot Details based on the updatedDate column with Descending order
     * @param snapShotPlanDetails
     */
    private sortSnapshotDetails(snapShotPlanDetails: IFinancialPlan[]) {
        snapShotPlanDetails.sort((left, right): number => {
            if (right.updatedDate > left.updatedDate) {
                return 1;
            }
            if (right.updatedDate < left.updatedDate) {
                return -1;
            }
            return 0;
        });
    }

    /**
     * Sort Snapshot Details V2 based on the changed date column with Descending order
     * @param snapShotPlanDetails
     */
    private sortSnapshotDetailsForV2(snapShotPlanDetails: IFinancialPlanV2[]) {
        snapShotPlanDetails.sort((left, right): number => {
            if (right.changedOn > left.changedOn) {
                return 1;
            }
            if (right.changedOn < left.changedOn) {
                return -1;
            }
            return 0;
        });
    }


}
