<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'280px'" [tileHeight]="'280px'" [hasNonOverflowContent]="true">
    <dm-loading loader [loadingText]="'Engagement Summary Details'" [showLoading]="showLoading"
        *ngIf="isComponentLoading && !isServerError">
    </dm-loading>
    <ng-container *ngIf="engagementDetails && !isComponentLoading && !isServerError">
            <dm-summary-progress-bar class="ebs-state-progress" [progressBarData]="engagementDetails.ebsStateProgress">
            </dm-summary-progress-bar>
            <section class="dm-p-t-10">
                <section class="col-md-6 col-sm-6 col-xs-12 dm-p-imp-0 dm-tile--p-r-20 dm-tile__firstSection">
                    <section class="dm-project-data clearfix">
                        <div class="dm-project-data__item dm-tile--m-b-24 col-md-12 dm-p-imp-0">
                            <h4 class="dm-project-data__title">Name</h4>
                            <p class="font-caption-alt">
                                <span>
                                    {{engagementDetails.name | dmDisplayDashIfEmptyOrNull}}
                                </span>
    
                                <ng-container *ngIf="engagementDetails.hasAssociatedEngagements">
                                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4"
                                        *ngIf=" (isInternalEngagement && isPreQualOrPreSales) || !isInternalEngagement"
                                        [dmPlacement]="'right'" [ngbTooltipContent]="hasAssociatedEngagementTemplateText"
                                        [dmIcon]="'tile__icon--info icon icon-link link-posig'"
                                        ariaLabelToolTip="more Information">
                                    </dm-tooltip>
                                </ng-container>
                                <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4 confidentialicon"
                                    *ngIf="engagementDetails.isConfidentialDeal" [dmPlacement]="'right'"
                                    [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                    [dmIcon]="'tile__icon--info confidentialkey'"
                                    ariaLabelToolTip="confidentiality information">
                                </dm-tooltip>
                                <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec"
                                    *ngIf="!engagementDetails.isUsPubSec && isPubSecEnabled && engagementDetails.isPublicSector"
                                    [dmPlacement]="'right'" [ngbTooltipContent]="'Pub Sec'" [dmIcon]="'pubsec_icon'"
                                    ariaLabelToolTip="pub sec Information">
                                </dm-tooltip>
                                <span tabindex="0" *ngIf="engagementDetails.isUsPubSec" class="uspubsec"
                                    title="US Pubsec"></span>
                                <span tabindex="0" *ngIf="engagementDetails.hasUnitBasedDemands" class="units-icon"
                                    title="Units"></span>
                            </p>
                        </div>
    
                        <div class="dm-project-data__item dm-tile--m-b-24 col-md-12 dm-p-imp-0">
                            <div class="dm-project-data__item col-md-9 dm-p-imp-0">
                                <h4 class="dm-project-data__title">Customer</h4>
                                <p class="font-caption-alt">{{engagementDetails.customerName | dmDisplayDashIfEmptyOrNull}}
                                </p>
                            </div>
                            <div class="dm-project-data__item col-md-3 dm-p-imp-0" *ngIf="engagementDetails.topParentId">
                                <h4 class="dm-project-data__title">TPID</h4>
                                <p class="font-caption-alt">{{engagementDetails.topParentId}}</p>
                            </div>
                        </div>
                    </section>
                    <section class="dm-project-data">
                        <div class="dm-project-data__item dm-tile--m-b-24">
                            <h4 class="dm-project-data__title">Description</h4>
                            <p class="font-caption-alt"> {{engagementDetails.description | dmDisplayDashIfEmptyOrNull}}</p>
                        </div>
                    </section>
                    <!--
                                projectTypeCode; see setProjectTypeCodeValue() in the component
                                       91 indicates project is type of "Pre-Qualification",
                                       92 indicates project is type of "Pre-Sales",
                                       93 indicates project is type of "Demand Gen-Generic",
                                       94 indicates project is type of "Demand Gen-Account",
                                       95 indicates project is type of "SR Out Corp HQ",
                                       96 indicates project is type of "MS-Internal",
                                       97 indicates project is type of "Unit-Based"
                                       98 indicates project is type of "SR Out Other BG"
                            -->
                    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0"
                        *ngIf="isInternalEngagement && engagementDetails.projectTypeCode">
                        <section *ngIf="isPreQualOrPreSales"
                            class="col-md-5 col-sm-5 col-xs-12 dm-project-data__item dm-p-imp-0 dm-tile--m-b-24">
                            <h4 class="dm-project-data__title">
                                {{ engagementDetails.projectTypeCode === "92" ? "Opportunity ID" : "Lead ID" }}
                            </h4>
                            <p class="font-caption-alt">
                                {{engagementDetails.opportunityId | dmDisplayDashIfEmptyOrNull}}
                            </p>
                        </section>
                        <section class="col-md-7 col-sm-7 col-xs-12 dm-project-data__item dm-p-imp-0 dm-tile--m-b-24"
                            *ngIf="isPreQualOrPreSales">
                            <h4 class="dm-project-data__title">
                                Cost Center / Internal Order #
                            </h4>
                            <p class="font-caption-alt">
                                {{engagementDetails.costCenter | dmDisplayDashIfEmptyOrNull}}
                            </p>
                        </section>
                    </section>
                </section>
                <section class="col-md-4 col-sm-4 col-xs-12 dm-tile--p-l-20--mobile0 dm-tile__middleSection">
                    <section class="dm-project-data">
                        <div class="dm-project-data__item dm-tile--m-b-24">
                            <h4 class="dm-project-data__title">
                                {{!isInternalEngagement? "Primary Domain" : ""}} Project Manager
                            </h4>
                            <dm-user-name [name]="engagementDetails.pPjMName" [alias]="engagementDetails.pPjMAlias"
                                (onNameClick)="logEmployeeClick()" [shouldDisplayChatIcon]="true"></dm-user-name>
                        </div>
                        <div class="dm-project-data__item dm-tile--m-b-24 dm-project-data__hidePrimaryDomain">
                            <div *ngIf="false">
                                <h4 class="dm-project-data__title">Primary Domain</h4>
                                <p class="font-caption-alt">
                                    {{engagementDetails.primaryDomain | dmDisplayDashIfEmptyOrNull}}
                                </p>
                            </div>
                        </div>
                        <div class="dm-project-data__item dm-tile--m-b-24">
                            <h4 class="dm-project-data__title dm-project-data__title--tooltip">
                                <span class="dm-project-data__title--tooltip--opacity06">
                                    Type {{!isInternalEngagement ? "of Contract" : ""}}
                                </span>
                                <dm-tooltip *ngIf="deviceFactory.isDesktop()" role="tooltip"
                                    class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'right'"
                                    [ngbTooltipContent]="tooltipContent" [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="Type Information">
                                </dm-tooltip>
                                <dm-tooltip *ngIf="deviceFactory.isTablet()" role="tooltip"
                                    class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'bottom-right'"
                                    [ngbTooltipContent]="tooltipContent" [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="Type Information">
                                </dm-tooltip>
                                <dm-tooltip *ngIf="deviceFactory.isMobile()" role="tooltip"
                                    class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="'bottom-left'"
                                    [ngbTooltipContent]="tooltipContent" [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="Type Information">
                                </dm-tooltip>
                            </h4>
                            <p class="font-caption-alt">
                                {{engagementDetails.typeOfContract | dmDisplayDashIfEmptyOrNull}}
                            </p>
                        </div>
                    </section>
                </section>
                <section class="col-md-2 col-sm-2 col-xs-12 col-xs-12 dm-tile--p-l-20--mobile0 dm-tile__lastSection">
                    <section class="dm-project-data">
                        <div class="dm-project-data__item dm-tile--m-b-24">
                            <h4 class="dm-project-data__title dm-project-data__title--tooltip">
                                <span class="dm-project-data__title--tooltip--opacity06">EBS State</span>
                                <dm-tooltip *ngIf="deviceFactory.isDesktop()" role="tooltip"
                                    class="dm-tooltipinline dm-tile--m-r-4" [dmPlacement]="toolTipPlacement"
                                    [ngbTooltipContent]="ebsStateTooltipText" [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="EBS State Information">
                                </dm-tooltip>
                            </h4>
                            <p class="font-caption-alt">
                                {{engagementDetails.status | dmDisplayDashIfEmptyOrNull}}
                            </p>
                        </div>
                        <div class="dm-project-data__item dm-tile--m-b-24" *ngIf="!deviceFactory.isMobile()">
                            <h4 class="dm-project-data__title">Start Date</h4>
                            <p class="font-caption-alt">
                                {{engagementDetails.startDate | dmDisplayDateOrDashPipe}}
                            </p>
                        </div>
                        <div class="dm-project-data__item dm-tile--m-b-24" *ngIf="!deviceFactory.isMobile()">
                            <h4 class="dm-project-data__title">End Date</h4>
                            <p class="font-caption-alt">
                                {{engagementDetails.endDate | dmDisplayDateOrDashPipe}}
                            </p>
                        </div>
                        <div class="dm-project-data__item dm-tile--m-b-24" *ngIf="deviceFactory.isMobile()">
                            <h4 class="dm-project-data__title">Duration</h4>
                            <p class="font-caption-alt">
                                {{engagementDetails.startDate | dmDisplayDateOrDashPipe}} -
                                {{engagementDetails.endDate | dmDisplayDateOrDashPipe}}
                            </p>
                        </div>
                    </section>
                </section>
            </section>
    </ng-container>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="toolTipErrorMessage"></dm-service-error>
</dm-tile>