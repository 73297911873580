<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'200px'" [hasNonOverflowContent]="true">   
    <div>
        <div>            
            <div>
                <label class="font-caption" for="contactFirstName">
                    <span>IO End Date Extended To:</span>
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>

                <dm-datepicker [modelDate]="newIoEndDate"
                    [placeholder]="'dd-mm-yyyy'" [(ngModel)]="newIoEndDate" [minDate]="currentEndDate"
                    name="newIoEndDate" ariaLabelButton="IO End Date"
                    ariaLabelCalendar="End Date">
                </dm-datepicker>                
            </div>

        </div>
    </div>    
    <div additionalFooterButtons>
        <button type="button" id="submitExtension" class="fxp-btn fxp-btn--primary dm-btn" (click)="sendNotification()">
            Submit
        </button>
    </div>
</dm-modal-v2>