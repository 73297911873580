import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { InternalEngagementFinancialComponent } from "./internal-engagement-financial.component";
import { InternalEngCostConsumptionModule } from "./tiles/internal-engagement-cost-consumption/internal-engagement-cost-consumption.module";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { WbsInternalEngagementModule } from "../manage-internal-engagement/wbs-internal-engagement.module";
import { InternalEngagementBreakdownStructureModule } from "./tiles/internal-engagement-breakdown-structure/internal-engagement-breakdown-structure.module";

@NgModule({
    declarations: [
        InternalEngagementFinancialComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        InternalEngCostConsumptionModule,
        WbsInternalEngagementModule,
        InternalEngagementBreakdownStructureModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        InternalEngagementFinancialComponent,
    ],
    exports: [
        InternalEngagementFinancialComponent,
    ]
})
export class InternalEngagementFinancialModule { }
