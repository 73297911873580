import { Component, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { Components } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";

@Component({
    templateUrl: "./create-teams-group.html",
    styleUrls: ["./create-teams-group.scss"]
})
export class CreateTeamsGroupModalComponent extends DmModalAbstract {
    public modalContent: IModal;
    public isCreatingTeamGroup: boolean;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(activeModal, dmLogger, Components.CreateTeamsGroup);
    }



    public ngOnInit(): void {
        this.modalContent = {
            title: "Create Teams Group"
        };
    }

    /*
     * Create Teams Group 
     */
    public createTeam(): void {
        this.isCreatingTeamGroup = true;
    }
}
