<section class="modal__input--text dm-type-ahead dm-tpid">
  <ng-template #rt let-r="result" let-t="term">
    <div class="typeahead-list-item">
      <span class="dm-display-block">
        <span class="typeahead-list-item--customer-name">
          <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
        </span>
      </span>
      <br>
      <span>TPID: </span>
      <span class="typeahead-list-item--user-name">
        <ngb-highlight [result]="r.topParentId" [term]="t"></ngb-highlight>
      </span>
      <span class="typeahead-list-item--user-type dm-display-block pull-right">
        <span *ngIf="r.region">{{r.region}},</span>
        <span>{{r.country}}</span>
      </span>
    </div>
  </ng-template>

  <label class="font-caption-alt" [for]="typeAheadId" *ngIf="typeAheadLabelText">{{typeAheadLabelText}}</label>

  <section class="input__label PMTypehead pos-rel p-b-0 no-mar-tablet">
    <input type="text" #typeAheadSearchTpid class="form-control font-caption-alt" [id]="typeAheadId"
      placeholder="Start typing Customer Name/TPID" [(ngModel)]="value" [ngbTypeahead]="search" [resultTemplate]="rt"
      [required]="isRequired?isRequired:false" [attr.aria-required]="isRequired?isRequired:false"
      [attr.autocomplete]="'disabled'" (ngModelChange)="validateInput()" minlength="3" [disabled]="isDisabled"
      [attr.aria-label]="typeAheadLabelText" [name]="typeAheadId" [inputFormatter]="formatter"
      (selectItem)="onCustomerSelect($event)" role="combobox" [readonly]="selectedCustomer" />
    <button title="Search" [attr.aria-label]="btnSearchAriaLabelText" class="input__icon input__icon__search-btn"
      type="button" *ngIf="!searching && !value">
      <i class="icon icon__ele dm-icon-search"></i>
    </button>
    <button [title]="searching ? 'Searching..' : 'Clear'" class="input__icon search__icon input__icon__cancel-btn"
      [attr.aria-label]="btnCancelAriaLabelText" (click)="clearText()" *ngIf="value" type="button"
      [disabled]="isDisabled" [attr.aria-hidden]="isDisabled">
      <i [ngClass]="searching ? 'glyphicon glyphicon-refresh glyphicon-spin' : 'icon icon__ele icon-cancel'"></i>
    </button>
  </section>
  <div *ngIf="errorMessage && !searching" class="p-t-6">
    <div role="alert" aria-live="assertive" aria-atomic="true" class="font-caption-alt inp-validation">
      <i class="icon icon-RemoveLegacy"></i> {{errorMessage}}
    </div>
  </div>
</section>