import { Component, Inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";


@Component({
    templateUrl: "./cancel-or-continue-modal.html",
    styleUrls: ["./cancel-or-continue-modal.scss"]
})
export class CancelOrContinueModal extends DmModalAbstract {
    @Input() public continueFunction: () => void;
    @Input() public modalMessage: string;

    public modalContent: IModal;


    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(activeModal, dmLogger, Components.CancelOrContinueModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Do you want to continue?",
            subTitle: "The details entered in this FCR form will be erased while changing the project dates. Do you wish to continue?"
        };
    }

    public onContinue(): void {
        this.continueFunction();
        this.closeModal();
    }

    public onCancel(): void {
        this.closeModal();
    }

}