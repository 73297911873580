import { forwardRef, Inject, Component, Injector } from "@angular/core";
import { combineLatest as observableCombineLatest } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { getMyPortfolioEngagementListState } from "../../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IEngagementList, IDelegationPortFolioDetails } from "../../../../common/services/contracts/portfolio.model";
import { IMyPortfolioEngagementListState } from "../../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { IState } from "../../../../store/reducers";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { TooltipText, RouteName, Components, LogEventName, SourceConstants, LogEventConstants, DestinationPage, SortColumnName, EngagementType, ItemCount, AccessibilityConstants } from "../../../../common/application.constants";
import { untilDestroyed } from "ngx-take-until-destroy";
import { DelegationDetailsModalComponent } from "../../../tiles/delegation-details-modal/delegation-details-modal.component";
import { InternalEngagementFinancialModalComponent } from "../internal-engagement-financial-modal/internal-engagement-financial-modal.component";
import { IUserPreference } from "../../../../common/services/contracts/userpreference.contract";
import { UpdateUserPreference } from "../../../../store/userspreferences/userpreference.action";
import { UserPreferenceService } from "../../../../common/services/userpreferences.service";
import { getEntireUserPreference } from "../../../../store/userspreferences/userpreference.selector";
import { IUserPreferenceState } from "../../../../store/userspreferences/userpreference.reducer";

@Component({
    selector: "dm-internal-engagement-overview-tab-v2",
    templateUrl: "internal-engagement-overview-tab-v2.html",
    styleUrls: ["./internal-engagement-overview-tab-v2.scss"]
})
export class InternalEngagementOverviewTabV2Component extends DmComponentAbstract {
    public userPreference: IUserPreference;
    public engagementList: IEngagementList[];
    public currentPage: number = 1;
    public ebsStateTooltipText: string = TooltipText.EBSState;
    public gridItemsDisplay: number = 5;
    public hidePinnedColumn: boolean;
    public isSortASC: boolean = false;
    public portfolioItemsDisp: number = 5;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public slicedItemsNumber: number = 0;
    public sortState: string;
    public isMobileView: boolean;
    public isDesktopView: boolean;
    public isTabletView: boolean;
    public showFinancialsTab: boolean;
    public sortOrder: string;
    public tableCaption: string = "Internal Engagements Overview Details";
    public loadingText = "Internal Engagements";
    public columnHeaderTitleorAriaLabel: string = "";
    public itemsPerPage: number[] = [5, 10, 15];
    public isPaginationRequired: boolean = false;
    public LogEventName = LogEventName;
    public accessibilityConstants = AccessibilityConstants;


    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) private deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StateService) private stateService: StateService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Injector) private injector: Injector,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
    ) {
        super(dmLogger, Components.PortfolioInternalEngagementOverviewTab);
    }

    public ngOnInit(): void {
        this.isMobileView = this.deviceFactory.isMobile();
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isTabletView = this.deviceFactory.isTablet();
        this.configmanagerService.initialize().then(() => {
            this.hidePinnedColumn = this.configmanagerService.isFeatureEnabled("hidePinnedEntitiesInPortfolio");
            this.showFinancialsTab = this.configmanagerService.isFeatureEnabled("showFinancialsTabPortfolio");

            this.storeDispatchService
                .requireMyPortfolioEngagementList(this.stateService.params.loadFromCache, true)
                .load();

            const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
            const userPreference$ = this.store.select((getEntireUserPreference()));

            observableCombineLatest(
                myPortfolioEngagementList$,
                userPreference$,
                (
                    engagementList: IMyPortfolioEngagementListState,
                    userPreferenceState: IUserPreferenceState,
                ) => ({
                    engagementList,
                    userPreferenceState,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    engagementList,
                    userPreferenceState,
                }) => {
                    if (engagementList.loaded && userPreferenceState.loaded) {
                        this.userPreference = userPreferenceState.userPreference;
                        this.engagementList = engagementList.engagementList.filter((x: IEngagementList) => x.type.toLowerCase() === "internal");
                        this.isPaginationRequired = this.paginationRequired();
                        this.portfolioItemsDisp = Number(this.userPreference.internalEngagements.pageSize);
                        this.currentPageChangedHandler(this.currentPage);
                    }
                    this.setLoadersBasedOnItemState(engagementList);
                    this.setErrorsBasedOnItemState(engagementList);
                });
        });
    }

    /**
     * Changes the current page, grid items to display and sliced number on changing the page
     *
     * @param {number} currentPageValue
     * @memberof GridDataComponent
     */
    public currentPageChangedHandler(currentPageValue: number): void {// todo consider moving this into a shared function.
        const totalLength = this.engagementList.length;
        const lastPage = Math.ceil(totalLength / this.portfolioItemsDisp);
        if (currentPageValue === 1) {
            this.slicedItemsNumber = 0;
            this.gridItemsDisplay = this.portfolioItemsDisp;
        } else if (this.currentPage < currentPageValue && currentPageValue !== lastPage) {
            this.slicedItemsNumber = this.gridItemsDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay + this.portfolioItemsDisp;
        } else if (currentPageValue === lastPage) {
            this.slicedItemsNumber = (lastPage - 1) * this.portfolioItemsDisp;
            this.gridItemsDisplay = totalLength;
        } else {
            let displayValue = this.portfolioItemsDisp;
            if ((this.gridItemsDisplay - this.slicedItemsNumber) < this.portfolioItemsDisp) {
                displayValue = this.gridItemsDisplay - this.slicedItemsNumber;
            }
            this.slicedItemsNumber = this.slicedItemsNumber - this.portfolioItemsDisp;
            this.gridItemsDisplay = this.gridItemsDisplay - displayValue;
        }
        this.currentPage = currentPageValue;
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.CurrentPageChangedHandler, LogEventConstants.PortfolioPaginationPageChanged);
    }

    /**
     * Fetches the item and assigning it to grid
     *
     */
    public itemPerPageChangedHandler(itemCount: string): void {
        const propertyBag = {};
        propertyBag[ItemCount] = itemCount;
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.ItemPerPageChangedHandler, LogEventName.PortfolioInternalEngagementsPaginationSet, propertyBag);
        this.gridItemsDisplay = Number(itemCount);
        if (this.userPreference) {
            this.userPreference.internalEngagements.pageSize = itemCount;
            this.saveUserPreference(this.userPreference);
        }
    }

    /**
     *
     * Opens Delegation Modal
     * @param {*} delegationDetails
     * @param {*} delegatedType
     * @memberof InternalEngagementOverviewComponent
     */
    public openDelegationModal(delegationDetails: IDelegationPortFolioDetails, delegatedType: string): void {
        const modalInstance = this.modalService.open(DelegationDetailsModalComponent, {
            injector: this.injector
        });
        modalInstance.componentInstance.delegationDetails = delegationDetails;
        modalInstance.componentInstance.delegationType = delegatedType;
    }

    /**
     * Sort the Engagements based on the column selected and order designated
     * First click on the button will make it sort in ascending order. Consecutive second click will make it sort in descending order
     * @param {string} selectedColumn
     * @public
     * @memberof GridDataComponent
     */
    public sortData(selectedColumn: string): void {
        if (!this.engagementList || !selectedColumn) {
            return;
        }

        // Determine sort in ascending order or descending order
        this.isSortASC = (this.sortState === selectedColumn) ? !this.isSortASC : true;
        this.sortState = selectedColumn;
        this.sortOrder = this.isSortASC ? "Ascending" : "Descending";
        this.tableCaption = "Internal Engagements Overview Details sorted by " + this.sortState + " in " + this.sortOrder + " order.";

        // sort the engagement array with ascending order
        this.engagementList = this.engagementList.sort(this.sharedFunctionsService.sortEntitiesByColumnName(this.getFieldNameForEngagement(selectedColumn)));

        // reverse the array if descending order is desired
        if (!this.isSortASC) {
            this.engagementList = this.engagementList.reverse();
        }

        if (this.sortState) {
            this.columnHeaderTitleorAriaLabel = this.sortState + " sorted in " + this.sortOrder + " order";
        }
        const propertyBag = {};
        propertyBag[SortColumnName] = selectedColumn;
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.SortData, LogEventConstants.SortEngagementsList, propertyBag);
    }

    /**
     * Close Dropdown On Tab of Last Element
     *
     */
    public closeDropdownOnTab(id: string): void {
        document.getElementById(id).classList.remove("open");
    }

    /**
     * Make dropdowns list items accessibile with Keyboard Up/Down Arrow Keys for both Engagement and Project
     * @param type 
     * @param Id 
     * @param action 
     */
    public moveFocus(id: string, action: string): void {
        const dropdownId = "dm-grid-engagement-options" + id + action;
        this.sharedFunctionsService.focus(dropdownId, true);
    }

    /**
     * Sets the title or aria-label for drop down
     */
    public setTitleOrAriaLabelForDropDown(dropdownType: string, id: string): string {
        return dropdownType + " Dropdown " + id;
    }

    /**
     * Sets the title or aria-label for financials overview
     */
    public setTitleOrAriaLabelForFinancialOverview(engagementId: string): string {
        return "Financial Overview for Internal Engagement " + engagementId;
    }
    /**
     * Logs event when the dropdown menu for a specfic 
     * engagement item on the internal engagement list is used
     */
    public logMoreOptionsClick(): void {
        const propertyBag = {};
        propertyBag[EngagementType] = "Internal";
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogMoreOptionsClick, LogEventConstants.SpecificEngagementMoreOptionsClick, propertyBag);
    }


    /**
     * Sets the title or aria-label for customer engagement grid column headers
     */
    public setTitleOrAriaLabelForColumnHeader(columnName: string): string {
        if (this.sortState && (this.sortState.toLowerCase() === columnName.toLowerCase())) {
            return this.columnHeaderTitleorAriaLabel;
        } else {
            return "Sort by " + columnName;
        }
    }
    /**
     * Logs event when user navigates to internal engagement using the list
     * @param destination internal engagement page the link takes the user to 
     */
    public logIntEngagementItemClick(destination: string): void {
        const propertyBag = {};
        propertyBag[DestinationPage] = destination;
        propertyBag[EngagementType] = "Internal";
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LogIntEngagementItemClick, LogEventConstants.EngagementNavigation, propertyBag);
    }

    /**
     * Open Fiancials Modal Overview
     *
     */
    public loadFinancialOverview(engagementId: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.PortfolioPage, SourceConstants.Method.LoadFinancialOverview, LogEventName.PortfolioInternalFinancialSummary);
        const newModalInstance = this.modalService.open(InternalEngagementFinancialModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal in active internal-engagement-financials-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        newModalInstance.componentInstance.engagementId = engagementId;
    }
    
    /**
     * Get the sorting field variable for engagements by the label name of click action
     *
     * @param {string} selectedColumn
     * @memberof GridDataComponent
     */
    private getFieldNameForEngagement(selectedColumn: string): string {
        switch (selectedColumn) {
            case "Name":
                return "engagementName";
            case "EBS State":
                return "ebsState";
            case "Customer":
                return "customerName";
            case "Start Date":
                return "startDate";
            case "End Date":
                return "endDate";
            case "Type":
                return "projectTypeCode";
            case "Domain Project Manager":
                return "pPjMName";
        }
    }

    /**
     * validating to call pagination component
     *
     */
    private paginationRequired(): boolean {
        if (this.engagementList && this.engagementList.length > this.itemsPerPage[0]) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Saves UsersPreference     
     */
    private saveUserPreference(userPreference: IUserPreference) {
        this.userPreferenceService.saveUserPreference(userPreference).then((response: IUserPreference) => {
            this.store.dispatch(new UpdateUserPreference(response));
        });
    }
}
