import { Action } from "@ngrx/store";
import { IContactsResponse } from "../../common/services/contracts/contacts.contracts";

// 1. action types
export enum ContactsActionTypes {
    LOAD_CONTACTS = "[Contacts] Load",
    LOAD_CONTACTS_SUCCESS = "[Contacts] Load Success",
    LOAD_CONTACTS_FAIL = "[Contacts] Load Fail",
    INVALIDATE_CONTACTS = "[Contacts] Invalidate"
}

// 2. action creators
export class LoadContacts implements Action {
    public readonly type = ContactsActionTypes.LOAD_CONTACTS;
    public constructor(public wbsId: string) { }
}
export class LoadContactsSuccess implements Action {
    public readonly type = ContactsActionTypes.LOAD_CONTACTS_SUCCESS;
    public constructor(public wbsId: string, public contactsResponse: IContactsResponse) { }
}

export class LoadContactsFail implements Action {
    public readonly type = ContactsActionTypes.LOAD_CONTACTS_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateContacts implements Action {
    public readonly type = ContactsActionTypes.INVALIDATE_CONTACTS;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type ContactsAction =
    | LoadContacts
    | LoadContactsSuccess
    | LoadContactsFail
    | InvalidateContacts;