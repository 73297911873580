import { CircularProgressModule } from "../../../components/tiles/circular-progress/circular-progress.module";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgModule } from "@angular/core";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { InvoiceSummaryComponent } from "./invoice-summary.component";
import { UIRouterModule } from "@uirouter/angular";

@NgModule({
    declarations: [
        InvoiceSummaryComponent
    ],
    imports: [
        CircularProgressModule,
        CommonComponentsModule,
        CommonModule,
        UIRouterModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
        DMAuthorizationService
    ],
    exports: [
        InvoiceSummaryComponent,
    ],
    entryComponents: [
        InvoiceSummaryComponent,
    ]
})
export class InvoiceSummaryModule { }
