import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sum",
    pure: false
})
export class SumPipe implements PipeTransform {
    public transform(items: any[], attr: string): any {
        return items.reduce((a, b) => a + b[attr], 0);
    }
}