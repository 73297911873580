import { Action } from "@ngrx/store";
import { INotification } from "../../common/services/contracts/notification-bar.contracts";

export enum NotificiationSubscriptionActionTypes {
    LOAD_NOTIFICATIONS = "[NotificationSubscriptions] Load",
    ADD_NOTIFICATION = "[NotificationSubscriptions] Add",
    REMOVE_NOTIFICATION = "[NotificationSubscriptions] Remove",
}

export class LoadNotifications implements Action {
    public readonly type = NotificiationSubscriptionActionTypes.LOAD_NOTIFICATIONS;
    public constructor(public userId: string, public notifications: INotification[]) { }
}

export class AddNotification implements Action {
    public readonly type = NotificiationSubscriptionActionTypes.ADD_NOTIFICATION;
    public constructor(public userId: string, public notification: INotification) { }
}

export class RemoveNotification implements Action {
    public readonly type = NotificiationSubscriptionActionTypes.REMOVE_NOTIFICATION;
    public constructor(public userId: string, public notificationId: string) { }
}

export type NotificationSubscriptionActions = LoadNotifications | AddNotification | RemoveNotification;