import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CommonComponentsModule } from "../entry/common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { MilestonesComponent } from "./milestones.component";
import { MilestonesModalModule } from "./milestone-modal/milestones-modal.module";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { DmUserProfileModule } from "../shared/user-name/user-name.module";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";

@NgModule({
    declarations: [
        MilestonesComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        MilestonesModalModule,
        NgbModule,
        DmUserProfileModule
    ],
    providers: [
        ConfigManagerService,
        DMAuthorizationService,
        DMLoggerService,
        SharedFunctionsService,
        StoreDispatchService
    ],
    exports: [
        MilestonesComponent,
    ],
    entryComponents: [
        MilestonesComponent,
    ]
})
export class MilestonesModule { }
