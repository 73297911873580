<dm-tile [tileHeaderContent]="tileContent" class="labor-consumpltion" [tileMinHeight]="'200px'" [tileHeight]="'200px'">
  <dm-loading loader [loadingText]="'Labor Consumption'" [showLoading]="showLoading"
    *ngIf="isComponentLoading && !isServerError"></dm-loading>
  <section *ngIf="!isComponentLoading && !isServerError">
    <dm-cceac *ngIf="cceacValues" [values]="cceacValues" [isInternalEngagement]="true"
      class="cceac-directive col-md-12 col-sm-6 col-xs-12"></dm-cceac>
  </section>
  <section class="col-md-12 col-sm-6 col-xs-12 labor-consumpltion--reference"
    *ngIf="!isComponentLoading && !isServerError">
    <section class="col-md-4 col-sm-4 col-xs-4">
      <h4 class="tile-title">
        Actual Labor<br /> (hours)
      </h4>
      <p>
        <span class="cceac--reference--consumed"></span>
        <span class="font-subtitle">{{actualLabor | dmDisplayDashIfEmptyOrNull}}</span>
      </p>
    </section>
    <section class="col-md-4 col-sm-4 col-xs-4">
      <h4 class="tile-title">
        Planned Labor<br /> (hours)
      </h4>
      <p>
        <span class="cceac--reference--eac"></span>
        <span class="font-subtitle">{{plannedLabor | dmDisplayDashIfEmptyOrNull}}</span>
      </p>
    </section>
    <section *ngIf="isNbueApprovedHourVisible" class="col-md-4 col-sm-4 col-xs-4">
      <h4 class="tile-title">
        Approved NBUE<br /> (hours)
      </h4>
      <p>
        <span class="diamond-narrow cceac--reference-list-item"></span>
        <span class="font-subtitle">{{approvedNBUEHours | dmDisplayDashIfEmptyOrNull}}</span>
      </p>
    </section>
  </section>
  <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>
