import { Component, Inject, Injector } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import {
    IEngagementDetailsV2,
    IProjectDetailsV2,
} from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IState } from "../../../../store/reducers";
import {
    Components,
    NoDataText,
    SourceConstants,
    LogEventConstants,
    RouteName,
} from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { EbsV2RequestStatusChangeModalComponent } from "../../modals/request-status-change/request-status-change.component";
import { EbsEditEngagementDetailsModalComponent } from "../../modals/wbs-engagement-edit/edit-engagement-details.component";
import { IDateProgress } from "../../../../components/tiles/dm-date-progress-bar/dm-date-progress-bar.contracts";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { IProjectDetailsViewModel } from "../../../../components/project-detail/project.model";
import { ProjectDetailService } from "../../../../common/services/project-detail.service";
import { EbsEditProjectDetailsModalComponent } from "../../modals/wbs-project-edit/edit-project-details.component";
import { InvalidateEngagementDetails } from "../../../../store/engagement-details/engagement-details.action";

@Component({
    selector: "dm-manage-ebs-entity-details",
    templateUrl: "./manage-ebs-entity-details.html",
    styleUrls: ["./manage-ebs-entity-details.scss"],
})
export class ManageEbsEntityDetailsComponent extends DmComponentAbstract {
    public engagementData: IEngagementDetailsV2;
    public tileContent: ITile;
    public isServerError: boolean;
    public engagementDateDetails: IDateProgress;
    public tileMinHeight: string = "365px";
    public selectedProjectData: IProjectDetailsViewModel;
    public projectDateDetails: IDateProgress;
    public projectData: IProjectDetailsV2;
    public RouteName = RouteName;
    public isProjectContext: boolean = false;
    public loadingText: string;
    public confidentialText: string;
    public pubSecText: string;
    public isPubSecEnabled: boolean;
    public componentinputs: string ;
    public showRatableIcon = false;
    public areMXDRChangesEnabled = false;
    public recoInput = "";
    public isProjectClsoureFeatureEnabled: boolean = false;
    private inputs: {} = {};    
    private selectedProjectId: string;


    public constructor(
        @Inject(SharedFunctionsService)
        private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService)
        private configurationService: ConfigManagerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector,
        @Inject(StoreDispatchService)
        private storeDispatchService: StoreDispatchService,
        @Inject(ProjectDetailService)
        private projectDetailService: ProjectDetailService
    ) {
        super(dmLogger, Components.ManageEbsEngagementDetails);
    }

    public ngOnInit(): void {
        this.selectedProjectId = this.sharedFunctionsService.getSelectedProjectId(
            this.stateService
        );
        this.isPubSecEnabled = this.configurationService.isFeatureEnabled("Pubsec");
        this.areMXDRChangesEnabled = this.configurationService.isFeatureEnabled("enableMXDRChanges");
        this.isProjectContext = this.selectedProjectId ? true : false;
        const confidentialEntityText = this.isProjectContext
            ? "Project"
            : "Engagement";
        this.confidentialText = `This ${confidentialEntityText} is marked as confidential.`;
        this.pubSecText = "Pub Sec";
        this.tileContent = {
            title: !this.isProjectContext ? "Engagement Details" : "Project Details",
            iconTitle: "icon-full icon-list",
        };
        this.loadingText = !this.isProjectContext
            ? "Engagement Details"
            : "Project Details";
        this.isProjectClsoureFeatureEnabled = this.configurationService.isFeatureEnabled("enableProjectClosureFeature");
        this.configurationService.initialize().then(() => {
            this.errorText = NoDataText.NoEBSDataText;
            const wbsId: string = this.isProjectContext
                ? this.selectedProjectId
                : this.sharedFunctionsService.getSelectedEngagementId(
                    this.stateService
                );

            this.store.dispatch(new InvalidateEngagementDetails(wbsId)); 

            this.storeDispatchService.requireEngagementDetails(wbsId, true).load();
            const engagementDetails$ = this.store.select(
                getEntireEngagementDetails(wbsId)
            );

            engagementDetails$
                .pipe(untilDestroyed(this))
                .subscribe((engagementDetails: IEngagementDetailsState) => {
                    if (engagementDetails.loaded) {
                        this.engagementData = engagementDetails.engagementDetails;
                        const projectsData = engagementDetails.engagementDetails.projects;
                        this.engagementData.ebsStateProgress = this.isProjectClsoureFeatureEnabled
                            ? this.sharedFunctionsService.getProgressForEntity(this.engagementData.currentStatus)
                            : this.sharedFunctionsService.getProgressForEntityBeforePJC(this.engagementData.statusDescription);
                        this.engagementDateDetails = {
                            startDate: this.engagementData.startDate,
                            endDate: this.engagementData.endDate,
                        };

                        this.showRatableIcon = this.sharedFunctionsService.showRatableIconForEngagement(this.engagementData.projects);
                        if (this.isProjectContext) {
                            this.projectData = projectsData.filter(
                                (project) => project.id === this.selectedProjectId
                            )[0];
                            if (this.projectData) {
                                this.selectedProjectData =
                                    this.projectDetailService.getProjectDetails(this.projectData);
                                this.selectedProjectData.ebsStateProgress = this.isProjectClsoureFeatureEnabled
                                    ? this.sharedFunctionsService.getProgressForEntity(this.selectedProjectData.currentStatus)
                                    : this.sharedFunctionsService.getProgressForEntityBeforePJC(this.selectedProjectData.status);
                                this.projectDateDetails = {
                                    startDate: this.selectedProjectData.actualStartDate,
                                    endDate: this.selectedProjectData.actualEndDate,
                                };
                            }
                        }
                    }

                    this.refreshOnItemInvalidation(engagementDetails);
                    this.setLoadersBasedOnItemState(engagementDetails);
                    this.setErrorsBasedOnItemState(engagementDetails);
                    if (engagementDetails.error) {
                        this.isServerError = true;
                    }
                });
        });
        this.inputs["earlyLoad"] = true;
        this.componentinputs = JSON.stringify(this.inputs); 
    }

    /**
   *  Opens request status change modal
   */
    public openStatusChangeModal(): void {
        
        const modalRef = this.modalService.open(
            EbsV2RequestStatusChangeModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in request-status-change-modal",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        if (this.isProjectContext) {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenStatusChangeModal,
                LogEventConstants.ManageEBSProjectRequestStatusChangeLinkClicked
            );
            modalRef.componentInstance.selectedProject = this.projectData;
            modalRef.componentInstance.engagementDetailsV2 = this.engagementData;
            modalRef.result.then(() => {
                this.dmLogger.logEvent(
                    SourceConstants.Component.ManageEBSPage,
                    SourceConstants.Method.OpenStatusChangeModal,
                    LogEventConstants.ManageEBSProjectRequestStatusChangeModalClosed
                );
            });
        } else {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenStatusChangeModal,
                LogEventConstants.ManageEBSEngagementRequestStatusChangeLinkClicked
            );
            modalRef.componentInstance.selectedEngagement = this.engagementData;
            modalRef.result.then(() => {
                this.dmLogger.logEvent(
                    SourceConstants.Component.ManageEBSPage,
                    SourceConstants.Method.OpenStatusChangeModal,
                    LogEventConstants.ManageEBSEngagementRequestStatusChangeModalClosed
                );
            });
        }
        
    }

    /**
   *  Opens edit engagement details modal
   */
    public openEditEngagementDetailsModal(): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.OpenEditEngagementDetailsModal,
            LogEventConstants.ManageEBSEngagementEditLinkClicked
        );
        const modalRef = this.modalService.open(
            EbsEditEngagementDetailsModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        modalRef.componentInstance.selectedEngagement = this.engagementData;
        modalRef.result.then(() => {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenEditEngagementDetailsModal,
                LogEventConstants.ManageEBSEditEngagementDetailsModalClosed
            );
        });
    }

    /**
   * Opens the modal that allows users to edit the given project.
   * @param selectedProject
   */
    public openEditProjectModal(): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.OpenEditProjectModal,
            LogEventConstants.ManageEBSProjectEditLink
        );
        const modalRef = this.modalService.open(
            EbsEditProjectDetailsModalComponent,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }
        );
        modalRef.componentInstance.selectedProject = this.projectData;
        modalRef.componentInstance.engagementDetails = this.engagementData;
        modalRef.result.then(() => {
            this.dmLogger.logEvent(
                SourceConstants.Component.ManageEBSPage,
                SourceConstants.Method.OpenEditProjectModal,
                LogEventConstants.ManageEBSEditProjectDetailsModalClosed
            );
        });
    }

    /**
   * Logs an event when an action dropdown is clicked
   * @param link
   */
    public logClick(logEventConstant: string): void {
        this.dmLogger.logEvent(
            SourceConstants.Component.ManageEBSPage,
            SourceConstants.Method.LogClick,
            logEventConstant
        );
    }

    /**
   * Copy entity name to the clipboard
   */
    public copyEntityName(): void {
        this.sharedFunctionsService.copyToClipboard("entityName");
    }
}
