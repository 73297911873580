<section class="dm-delivery-manager dm-delivery-manager-app">
    <section class="dm-reports">
        <section class="row dm-reports-tile display-flex dm-m-imp-0">
            <section class="col-md-4 col-sm-6 col-xs-12 display-inline-flex-imp dm-p-x-6-y-imp-0"
                *ngFor="let report of reports">
                <section class="report-tile">
                    <section class="dm-tile dm-m-b-12 pull-left report-tile-section">
                        <h2 class="font-subtitle-alt report-tile-title">{{report.title}}</h2>
                        <p class="font-caption-alt report-description" [innerHTML]="report.description">
                        </p>
                        <div *ngIf="report.linkType === 'download'" class="font-caption-alt report-link-type">
                            <a [href]="report.linkUrl" [attr.aria-label]="report.linkText + ' for ' + report.title">
                                <i class="grm-actions-icon icon-ExportImage dm-m-r-4" aria-hidden="true"></i>
                                <span aria-hidden="true">{{report.linkText}}</span>                               
                            </a>
                        </div>
                        <div *ngIf="report.linkType === 'link'" class="font-caption-alt report-link-type">
                            <a [href]="report.linkUrl" target="_blank" [attr.aria-label]="report.linkText + ' for ' + report.title + 'open link in new tab'">
                                <i class="icon icon-FourBarsLegacy dm-m-r-4" aria-hidden="true"></i>
                                <span aria-hidden="true">{{report.linkText}}</span>
                            </a>
                        </div>
                    </section>
                </section>
            </section>
        </section>
    </section>
</section>