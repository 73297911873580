<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'350px'" [tileHeight]="'100%'" [isV2Tile]="true">
  <dm-loading loader *ngIf="isComponentLoading && !isServerError" [showLoading]="showLoading"
    [loadingText]="'Projects'"></dm-loading>
  <caption class="sr-only" id="projectsTable">
    Projects
  </caption>
  <div *ngIf="!isComponentLoading && !isServerError && projectList.length"
    [ngClass]="{'dm-overflow': deviceFactory.isSmallScreen()}" role="grid" aria-labelledby="projectsTable">
    <div role="rowgroup" class="dm-grid-view-table">
      <div role="row"
        [ngClass]="{'dm-grid-view-table-v2__row' : areMXDRChangesEnabled, 'dm-grid-view-table__row': !areMXDRChangesEnabled}">
        <div role="gridcell" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8">
          Name
        </div>
        <div role="gridcell"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8 dm-p-l-imp-0">
          Contract Type
          <ng-template #profileDescContactPopup>
            <div class="type-dialogBox">
              <h4 class="type-dialogBox--header font-caption-alt text-left dm-p-x-5-y-10">
                Contract Types
              </h4>
              <ul class="type-dialogBox__ul col-md-12 no-pad font-caption-alt">
                <li *ngFor="let data of contractTypeList"
                  class="type-dialogBox__ul--li col-md-6 dm-p-l-imp-0 dm-m-t-imp-6">
                  <div class="col-md-4 dm-p-l-imp-0">
                    <span class="dm-badge dm-badge-v2 dm-contract-type display-inline-block-imp"
                      [ngStyle]="data.typeColorCode">{{data.typeShortForm}}</span>
                  </div>
                  <div class="col-md-8 text-left v-a-m fullformLbl">
                    <span>{{data.typeFullForm}}</span>
                  </div>
                </li>
              </ul>
            </div>
          </ng-template>
          <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-t-1" [dmPlacement]="'bottom'"
            [ngbTooltipContent]="profileDescContactPopup" [dmIcon]="'tile__icon--info icon icon-info'"
            ariaLabelToolTip="Contract Type Information. press space to view tooltip">
          </dm-tooltip>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
        <div *ngIf="areMXDRChangesEnabled" role="gridcell"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8">
          Rev Rec
          <ng-template #RecRevTypePopup>
            <div class="type-dialogBox">
              <h4 class="type-dialogBox--header font-caption-alt text-left dm-p-x-5-y-10">
                Rec Rev
              </h4>
              <ul class="type-dialogBox__ul col-md-12 no-pad font-caption-alt">
                <li *ngFor="let data of recRevTypeList"
                  class="type-dialogBox__ul--li col-md-6 dm-p-l-imp-0 dm-m-t-imp-6">
                  <div class="col-md-4 dm-p-l-imp-0">
                    {{data.typeShortForm}}
                  </div>
                  <div class="col-md-8 text-left v-a-m fullformLbl">
                    <span>{{data.typeFullForm}}</span>
                  </div>
                </li>
              </ul>
            </div>
          </ng-template>
          <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type" [dmPlacement]="'bottom'"
            [ngbTooltipContent]="RecRevTypePopup" [dmIcon]="'tile__icon--info icon icon-info'"
            ariaLabelToolTip="Rev Rec Information. press space to view tooltip">
          </dm-tooltip>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
        <div role="gridcell" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8">
          Start Date
        </div>
        <div role="gridcell" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8">
          End Date
        </div>
        <div role="gridcell" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8">
          EBS State
          <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4 dm-m-t-1" [dmPlacement]="'bottom-right'"
            [ngbTooltipContent]="wbsTooltipText" [dmIcon]="'tile__icon--info icon icon-info'"
            ariaLabelToolTip=" EBS State Information. press space to view tooltip">
          </dm-tooltip>
          <span class="sr-only">press tab to navigate to tooltip button</span>
        </div>
      </div>
      <div class="overflow-content">
        <div role="row"
          [ngClass]="{'dm-grid-view-table-v2__row' : areMXDRChangesEnabled, 'dm-grid-view-table__row': !areMXDRChangesEnabled}"
          *ngFor="let project of projectList">
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8">
            <div class="display-grid name-section" aria-label="column header Name.">
              <span class="sr-only">Name</span>
              <span class="display-flex">
                <a *ngIf="project.name" [uiSref]="RouteName.ProjectSummaryV2"
                  [uiParams]="{projectId: project.projectId}" class="dm-m-r-4 name"
                  id="{{ 'dm-grid-project-' + project.projectId }}" (click)="logProjectClick()"
                  [attr.aria-label]="'Project' + project.name">
                  Project: {{project.name}}
                </a>
                <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4 confidentialicon" *ngIf="isConfidentialDeal"
                  [dmPlacement]="'right'" [ngbTooltipContent]="'This Project is marked as confidential.'"
                  [dmIcon]="'tile__icon--info confidentialkey'" ariaLabelToolTip="confidentiality information">
                </dm-tooltip>
                <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4"
                  *ngIf="!isUsPubsec && isPublicSector && isPubSecEnabled" [dmPlacement]="'right'"
                  [ngbTooltipContent]="'Pub Sec'" [dmIcon]="'pubsec_icon'" ariaLabelToolTip="pub sec Information">
                </dm-tooltip>
                <span *ngIf="isUsPubsec" class="uspubsec dm-m-r-4" title="US Pubsec">
                </span>
                <span *ngIf="project?.hasUnitBasedDemands" class="units-icon dm-m-r-4" title="Units"></span>
              </span>
              <p>Solution Area: {{project.domain}}</p>
              <p>EBS: {{project.projectId}}</p>
              <p>
                Domain Project Manager:
                <dm-user-name *ngIf="project.pjMName" [name]="project.pjMName" [alias]="project.pjMAlias"
                  [role]="'Project Manager'" [showPersonCard]="true" [personCardLabel]="project.projectId">
                </dm-user-name>
              </p>
            </div>
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8 dm-p-l-imp-0">
            <span class="sr-only">Contract Type</span>
            <span *ngIf="project.contractType" class="dm-badge dm-badge-v2 dm-contract-type display-inline-block-imp"
              [ngStyle]="project.typeColorCode">{{project.contractType}}</span>
          </div>
          <div role="gridcell" *ngIf="areMXDRChangesEnabled"
            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8 dm-p-x-12-y-8">
            <span class="sr-only">Rev Rec</span>
            {{project.recRevType}}
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
            <span class="sr-only">Start Date</span>
            {{project.actualStartDate | dmDisplayDateOrDashPipe}}
            <span *ngIf="!project.actualStartDate"
              class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
            <span class="sr-only">End Date</span>
            {{project.actualEndDate | dmDisplayDateOrDashPipe}}
            <span *ngIf="!project.actualEndDate"
              class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
            <span class="sr-only">EBS State</span>
            {{project.currentStatus | dmDisplayDashIfEmptyOrNull}}
            <span *ngIf="!project.currentStatus"
              class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="col-md-12 col-sm-12 col-xs-12" *ngIf="!isComponentLoading && !projectList?.length">
    <dm-no-data [noDataText]="noProjectFoundText" [showImage]="true"></dm-no-data>
  </section>
  <dm-service-error *ngIf="!isComponentLoading && isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>