import { Component, EventEmitter, forwardRef, Inject, Input, OnChanges, Output } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";

import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { Components, LogEventConstants, SourceConstants } from "../../../common/application.constants";
import { IPurchaseOrder } from "../../../common/services/contracts/po.service.contracts";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IPoDropDownList } from "../manage-suppliers-table-data/lineitems.contract";

/**
 * ManageSuppliersFilterComponent
 *
 * @export
 * @class ManageSuppliersFilterComponent
 * @implements {OnChanges}
 */
@Component({
    selector: "dm-manage-suppliers-filter",
    templateUrl: "./manage-suppliers-filter.html",
    styleUrls: ["./manage-suppliers-filter.scss"]
})
export class ManageSuppliersFilterComponent extends DmComponentAbstract implements OnChanges {
    @Input() public isFilterCheckEnable: boolean;
    @Input() public poServiceResponse: IPurchaseOrder[];
    @Output() public readonly selectedPoChange = new EventEmitter<string>();
    @Output() public readonly filterChange = new EventEmitter<boolean>();
    public poDropDownList: IPoDropDownList[];
    public selectedPo: string;

    /**
     * Creates an instance of ManageSuppliersFilterComponent.
     * @param {DeviceFactoryProvider} deviceFactory
     * @param {DMLoggerService} logger
     * @memberof ManageSuppliersFilterComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Components.ManageSuppliersFilter);
    }

    public ngOnInit(): void {
        this.selectedPo = "";
        this.endComponentLoad();
    }

    public ngOnChanges(): void {
        this.poDropDownList = [];
        if (this.poServiceResponse && this.poServiceResponse.length) {
            this.poServiceResponse.forEach((po: IPurchaseOrder) => {
                this.poDropDownList.push({
                    poNumber: po.poNumber,
                    poTitle: po.description
                });
            });
        }
    }

    /**
     * get value from filter checkbox
     *
     * @memberof ManageSuppliersFilterComponent
     */
    public onFilterChange(): void {
        this.filterChange.emit(this.isFilterCheckEnable);
        const propertyBag = { checked: this.isFilterCheckEnable };
        this.dmLogger.logEvent(SourceConstants.Component.ManageSuppliersPage, SourceConstants.Method.OnFilterChange, LogEventConstants.ManageSuppliersShowOrHideGoodReceiptsBasedOnAction, propertyBag);
    }

    /**
     * get selected item when PO changed
     *
     * @memberof ManageSuppliersFilterComponent
     */
    public onSelectedPoChange(): void {
        this.selectedPoChange.emit(this.selectedPo);
    }
}
