import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../../common/application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { ICampusSiteDetailsApiResponse } from "./contracts/campus.contract";
import { DMLoggerService } from "./dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";

@Injectable()
export class CampusService extends DmServiceAbstract{
    private campusServiceUri: string;
    private campusApiKey: string;    

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) { 
        super(dmLogger, Services.CampusService);
    }

    /**
     * Calls the Concur API to get the pending expense reports based on the query string
     * @param queryString
     */
    public getCampusUrlBasedOnEngagementId(engagementId: string): Promise<ICampusSiteDetailsApiResponse> {
        this.campusServiceUri = this.configManagerService.getValue<string>("campusServiceBaseUri") + "api/ProjectSite/GetProjectDetailsByAuroraID?AuroraID=" + engagementId;
        // Ignore 403 error from Campus API as Campus returns 403 if a site is not configured
        this.campusApiKey = this.configManagerService.getValue<string>("campusServiceSubscriptionKey");
        return this.dataService.getData(this.campusServiceUri,
            "",
            APIConstants.GetCampusUrlBasedOnEngagementId,
            [403],
            false ,
            false,
            false,
            "json",
            false,
            {"api-key": this.campusApiKey});
    }
}
