import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AddRolesModule } from "./add-roles/add-roles.module";
import { CommonComponentsModule } from "../entry/common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FaultControlService } from "../../common/services/faultcontrol.service";
import { OneProfileService } from "../../common/services/one-profile.service";
import { ProjectService } from "../../common/services/project.service";
import { ProjectServiceFunctions } from "../../common/services/projectservice-functions.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { TypeAheadCompanyCodeModule } from "../tiles/type-ahead-companycode/type-ahead-company-code.module";
import { TypeAheadTpidModule } from "../tiles/type-ahead-tpid/type-ahead-tpid.module";
import { AddInternalEngagementProjectModule } from "./add-internal-engagement-project/add-internal-engagement-project.module";
import { NewInternalEngagementService } from "../../common/services/new-internal-engagement-service";
import { NewInternalEngagementComponentV2 } from "./new-internal-engagement-v2.component";
import { TypeAheadCityModule } from "../tiles/type-ahead-city/type-ahead-city.module";
import { WBSService } from "../../common/services/wbs.service";

@NgModule({
    declarations: [
        NewInternalEngagementComponentV2,
    ],
    imports: [
        AddRolesModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        FormsModule,
        TypeAheadCompanyCodeModule,
        AddInternalEngagementProjectModule,
        TypeAheadCityModule,
        TypeAheadTpidModule,
        ReactiveFormsModule,
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        FaultControlService,
        OneProfileService,
        ProjectService,
        NewInternalEngagementService,
        ProjectServiceFunctions,
        SharedFunctionsService,
        WBSService
    ],
    entryComponents: [
        NewInternalEngagementComponentV2
    ],
    exports: [
        NewInternalEngagementComponentV2
    ],
})
export class NewInternalEngagementModuleV2 { }
