import { Injectable, Inject } from "@angular/core";
import { DataService } from "./data.service";
import { ConfigManagerService } from "./configmanager.service";
import { APIConstants, Services } from "../application.constants";
import { FormGroup } from "@angular/forms";
import { INewInternalEngagementInput , IInternalEngagementApprovalDetails, IInternalEngagementApprovalRequest, IInternalEngagementSearchApprovalRequest} from "../../components/new-internal-engagement/new-internal-engagement.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class NewInternalEngagementService extends DmServiceAbstract {
    private projectServiceBaseUriv2: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.NewInternalEngagementService );
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Creates new internal engagement
     *
     * @param {FormData} input
     * @returns {Promise<any>}
     * @memberof BulkUploadInternalEngagementService
     */
    public createNewInternalEngagementV2(engagementDetails: INewInternalEngagementInput): Promise<any> {
        const url = `${this.projectServiceBaseUriv2}/internal/engagement`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.CreateInternalEngagement, engagementDetails, false);
    }

    /**
     * Checks all form controls and mark them as touched.
     *
     * @private
     * @param {FormGroup} formGroup
     * @memberof NewInternalEngagementComponentV2
     */
    public markFormGroupTouched(formGroup: FormGroup): void {
        Object.values(formGroup.controls).forEach((control: any) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    /**
     * Search the approval details for ISD Users
     *
     * @param {string} engagementId
     * @param {INewInternalEngagementProjectInput} projectDetails
     * @returns {Promise<any>}
     * @memberof ProjectServiceV2
     */
    public searchApprovalDetailsForInternalEngagement(searchApprovalRequest: IInternalEngagementSearchApprovalRequest): Promise<IInternalEngagementApprovalDetails> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/internal/approval/search`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.InternalEngagementPostApprovalDetails, searchApprovalRequest);
    }

    /**
     * Search the approval details for ISD Users
     *
     * @param {string} approvalId
     * @param {INewInternalEngagementProjectInput} projectDetails
     * @returns {Promise<any>}
     * @memberof ProjectServiceV2
     */
    public updateApprovalDetailsForInternalEngagement(approvalId: string, updateApprovalRequest: IInternalEngagementApprovalRequest): Promise<IInternalEngagementApprovalDetails> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/internal/approval/` + approvalId;
        return this.dataService.patchData(url, this.subscriptionKey, APIConstants.InternalEngagementPostApprovalDetails, updateApprovalRequest);
    }

    /**
     * Post the approval details for ISD Users
     *
     * @param {string} engagementId
     * @param {INewInternalEngagementProjectInput} projectDetails
     * @returns {Promise<any>}
     * @memberof ProjectServiceV2
     */
    public postApprovalDetails(approvalDetails: IInternalEngagementApprovalDetails): Promise<IInternalEngagementApprovalDetails> {
        this.initializeConfig();
        const url = `${this.projectServiceBaseUriv2}/internal/postapprovaldetails`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.InternalEngagementPostApprovalDetails, approvalDetails);
    }

    /**
     * Initialize the service with URIs and subscription keys.
     */
    private initializeConfig(): void {
        this.projectServiceBaseUriv2 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }
}
