<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'320px'"
    [isLoading]="isUpdatingEngagement || viewResourceEnable">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true"
        *ngIf="isUpdatingEngagement"></dm-loading>
    <div *ngIf="!isUpdatingEngagement && !viewResourceEnable">
        <!-- Form section -->
        <form [formGroup]="editEngagementDetailsForm">
            <div class="dm-form-entity"
                [ngClass]="{'dm-m-b-imp-20': (engagementName?.dirty || engagementName?.touched) && (!engagementName.value.length || (engagementName.value && engagementName.value.length < 3))}">
                <label class="dm-form-entity__label" for="engagementName">
                    Name
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <input class="font-caption-alt dm-input" type="text" formControlName="engagementName" maxlength="40"
                    [ngClass]="{'error-border': (engagementName?.dirty || engagementName?.touched) && engagementName.invalid}"
                    id="engagementName" name="engagementName" aria-label="Engagement name" placeholder="Enter Name">
                <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
                    {{40 - engagementName.value.length}}{{editEngagementErrorMessages.NameMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite"
                    *ngIf="(engagementName?.dirty || engagementName?.touched) && !engagementName.value.length">
                    {{editEngagementErrorMessages.EngagementNameIsRequired}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-live="polite"
                    *ngIf="engagementName.value && engagementName.value.length < 3">
                    {{editEngagementErrorMessages.NameMinimumCharacterLength}}
                </span>
            </div>
            <div class="dm-form-entity">
                <label class="dm-form-entity__label" for="engagementDescription">
                    Description
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <textarea name="engagementDescription" placeholder="Enter Description"
                    [ngClass]="{'error-border' : engagementDescription.touched && engagementDescription.invalid}"
                    id="description" formControlName="engagementDescription" aria-label="engagement Description"
                    maxlength="255" required></textarea>
                <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
                    {{255 - engagementDescription.value.length}}{{editEngagementErrorMessages.DescriptionMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite"
                    *ngIf="(engagementDescription?.dirty || engagementDescription?.touched) && !engagementDescription.value.length">
                    {{editEngagementErrorMessages.DescriptionIsRequired}}
                </span>
            </div>

            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="startDate">
                        Start Date
                    </label>
                    <dm-datepicker id="startDate" name="engagementStartDate" formControlName="engagementStartDate"
                        [disabled]="disableEBSStartDateEndDateUpdates" [modelDate]="engagementStartDate.value"
                        [minDate]="minStartDate" [maxDate]="maxStartDate" ariaLabelButton="Start date"
                        [placeholder]="'Select a date...'" ariaLabelCalendar="Start date"
                        (onDateChange)="onStartDateChange($event)" previosFousingElementId="description"
                        [showErrorBorder]="isStartDateRequired || isAfterChildProjectStartDate" [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isStartDateRequired">
                        {{editEngagementErrorMessages.StartDateIsRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterChildProjectStartDate">
                        {{getStartDateMustBeEarlierThanProjectMessage()}} </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showActualsStartDateConflictMessage">
                        Engagement Start Date must be earlier than or same as the Actual Posted Date
                        {{selectedEngagement.minDate | dmDisplayDateOrDashPipe}}.
                    </span>
                </div>
                <div class="col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="newEndDate">
                        End Date
                    </label>
                    <dm-datepicker id="endDate" name="engagementEndDate" [minDate]="minEndDate" [maxDate]="maxEndDate"
                        [modelDate]="engagementEndDate.value" [disabled]="disableEBSStartDateEndDateUpdates"
                        formControlName="engagementEndDate" [placeholder]="'Select a date...'"
                        ariaLabelButton="End date" ariaLabelCalendar="End date" (onDateChange)="onEndDateChange($event)"
                        previosFousingElementId="calenderBtn_Start date"
                        [showErrorBorder]="isEndDateRequired || showEndDateGreaterThanStartDateMessage || isBeforeChildProjectEndDate"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isEndDateRequired">
                        {{editEngagementErrorMessages.EndDateRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showEndDateGreaterThanStartDateMessage">
                        {{editEngagementErrorMessages.EndDateCannotBeEarlierThanTheStartDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showActualsEndDateConflictMessage">
                        Engagement End date should be later than actuals posted date
                        {{selectedEngagement.maxDate | dmDisplayDateOrDashPipe}}.
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeChildProjectEndDate">
                        {{getEndDateCannotBeEarlierThanProjectMessage()}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showActualsEndDateConflictMessage">
                        {{getEndDateMustBeAfterActualsMessage()}}
                    </span>
                </div>
                <div class="spinner dm-p-x-5" *ngIf="isResourceRequestsLoading">
                    <dm-refresh *ngIf="isResourceRequestsLoading" [showSpinning]="isResourceRequestsLoading"
                        [enableRefresh]="false" [loadingText]="'Loading resource request details'">
                    </dm-refresh>
                </div>
            </div>
            <section class="font-label dm-p-b-12 dm-p-t-4" role="alert" aria-atomic="true" aria-live="polite"
                *ngIf="showConflictingResources">
                <span class="font-caption-alt inp-validation">
                    Cannot change date due to resourcing conflict ({{noOfConflictResources}}
                    {{noOfConflictResources > 1 ? "Resources" : "Resource"}}).
                </span>
                <button class="dm-link" type="button" (click)="toggleResources()" aria-label="View resource dialogue.">
                    View resources
                </button>
            </section>
            <section class="col-md-12 col-sm-12 radio__section" *ngIf="showCascadeRadioButtons">
                <section class="col-md-12 col-sm-12 dm-p-imp-0 clearfix modal__input--radio">
                    <div class="dm-p-y-10">
                        Apply date changes to
                    </div>
                    <section class="dm-p-y-10 dm-p-x-imp-0">
                        <section class="col-md-6 col-sm-6 dm-p-imp-0">
                            <label for="checkEngagement" focus="true" class="dm-inline-flex-align-center">
                                <span class="input-radio input-radio--focus" *ngIf="!disableEngagementOnlyOption">
                                    <input id="checkEngagement" type="radio" name="updateLevel"
                                        formControlName="updateLevel" (change)="onUpdateLevelChange(updateLevel.value)"
                                        [value]="EnumUpdateLevel.engagementLevel">
                                    <span class="slider round" aria-hidden="true"></span>
                                </span>
                                <input id="checkEngagement" type="radio" name="updateLevel" *ngIf="disableEngagementOnlyOption"
                                    formControlName="updateLevel" (change)="onUpdateLevelChange(updateLevel.value)"
                                    disabled [value]="EnumUpdateLevel.engagementLevel">
                                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                                    Engagement Only
                                </span>
                            </label>
                        </section>
                        <section class="col-md-6 col-sm-6 dm-p-imp-0">
                            <label for="checkAll" focus="true" class="dm-inline-flex-align-center">
                                <span class="input-radio input-radio--focus">
                                    <input id="checkAll" type="radio" name="updateLevel" formControlName="updateLevel"
                                        (change)="onUpdateLevelChange(updateLevel.value)"
                                        [value]="EnumUpdateLevel.allLowerLevels">
                                    <span class="slider round" aria-hidden="true"></span>
                                </span>
                                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                                    Engagement, Projects,
                                    <br>
                                    Services and Tasks
                                </span>
                            </label>
                        </section>
                        <div class="clearfix"></div>
                    </section>
                </section>
            </section>
        </form>
    </div>
    <div infoBar
        *ngIf="!isUpdatingEngagement && !viewResourceEnable && (!isNamePristine || !isDescriptionPristine || !isStartDatePristine || !isEndDatePristine || isDisabledPubSecCode)">
        <div class="dm-modal-v2-change-information-section" *ngIf="isDisabledPubSecCode" role="alert" aria-live="polite"
            aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                Start and End Date cannot be changed for {{pubSecCountry}} Pubsec Engagements.
            </span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isNamePristine" role="alert" aria-live="polite"
            aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>{{entityType}} name has been changed from '{{selectedEngagement.name}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isDescriptionPristine" role="alert"
            aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>{{entityType}} description has been changed from '{{selectedEngagement.description}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isStartDatePristine" role="alert" aria-live="polite"
            aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>{{entityType}} start date has been changed from
                '{{selectedEngagement.startDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isEndDatePristine" role="alert" aria-live="polite"
            aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>{{entityType}} end date has been changed from
                '{{selectedEngagement.endDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
    </div>
    <section class="resource-details-list" *ngIf="viewResourceEnable">
        <div class="popUpModalDialog editEngagementForm">
            <section class="modal-header modal-noborder">
                <a class="dm-link font-caption-alt cursor-pointer" type="button" (click)="toggleResources()">
                    <i class="icon icon-Back"></i>
                    <span class="dm-p-r-10"> Return to Edit Engagement</span>
                </a>
            </section>
            <div class="modal-body resources-section">
                <dm-wbs-resource-requests [startDate]="engagementStartDate.value" [endDate]="engagementEndDate.value"
                    [wbsId]="selectedEngagement.id">
                </dm-wbs-resource-requests>
            </div>
        </div>
    </section>
    <div additionalFooterButtons *ngIf="!viewResourceEnable">
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" *ngIf="!isUpdatingEngagement"
            [disabled]="saveButtonDisabled(!editEngagementDetailsForm.valid)" (click)="updateEngagementDetails()">
            Submit
        </button>
    </div>
</dm-modal-v2>