import { takeWhile, withLatestFrom } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Store, Action } from "@ngrx/store";

import { getActualsLoaded, getEntireActuals } from "../../store/actuals/actuals.selector";
import { getChangeRequests, getChangeRequestsLoaded } from "../../store/change-requests/change-requests.selector";
import { getContactsLoaded, getEntireContacts } from "../../store/contacts/contacts.selector";
import { getCrIdRolesState, getCrIdRolesLoaded } from "../../store/cr-id-roles/cr-id-roles.selector";
import { getCrScreenDetailsState, getCrScreenDetailsLoaded } from "../../store/cr-screen-details/cr-screen-details.selector";
import { getDemandsLoaded, getDemandsState } from "../../store/demands/demands.selector";
import { getEngagementChangeRequests, getEngagementChangeRequestsLoaded } from "../../store/engagement-change-requests/engagement-change-requests.selector";
import { getEntireEngagementDetails, getEngagementDetailsLoaded } from "../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2, getFinancialDetailsV2Loaded } from "../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireInternalFinancialDetails, getInternalFinancialDetailsLoaded } from "../../store/internal-financial-details/intenal-financial-details.selector";
import { getEntireInvoices, getInvoicesLoaded } from "../../store/invoices/invoices.selector";
import { getEntireManageSuppliers, getManageSuppliersLoaded } from "../../store/manage-suppliers/manage-suppliers.selector";
import { getEntireMilestones, getMilestonesLoaded } from "../../store/milestones/milestones.selector";
import { getEntireNpcActuals, getNpcActualsLoaded } from "../../store/npc-actuals/npc-actuals.selector";
import { getEntireProjectDetails, getProjectDetailsLoaded } from "../../store/project-details/project-details.selector";
import { getEntireResourceRequestsProjectContextStateObject, getResourceRequestsProjectContextLoaded } from "../../store/resource-requests-project-context/resource-requests-project-context.selector";
import { getEntireResourceRequestsStateObject, getResourceRequestsLoaded } from "../../store/resource-requests/resource-requests.selector";
import { getFinancialRolesState, getFinancialRolesLoaded } from "../../store/financial-roles/financial-roles.selector";
import { getInitialWbsStructures, getWbsStructuresLoaded } from "../../store/wbs-structures/wbs-structures.selector";
import { getMyPortfolioEngagementListState, getMyPortfolioEngagementListLoaded } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { getMyPortfolioFinancialsListLoaded, getMyPortfolioFinancialsListState } from "../../store/my-portfolio/my-portfolio-financials-list/my-portfolio-financials-list.selector";
import { getPlanForecastParamsState, getPlanForecastParamsLoaded } from "../../store/plan-forecast-params/plan-forecast-params.selector";
import { getWbsIdRolesState, getWbsIdRolesLoaded } from "../../store/wbs-id-roles/wbs-id-roles.selector";
import { IState } from "../../store/reducers";
import { LoadActuals, InvalidateActuals } from "../../store/actuals/actuals.action";
import { LoadChangeRequests, InvalidateChangeRequests } from "../../store/change-requests/change-requests.action";
import { LoadContacts, InvalidateContacts } from "../../store/contacts/contacts.action";
import { LoadCrIdRoles, InvalidateCrIdRoles } from "../../store/cr-id-roles/cr-id-roles.action";
import { LoadCrScreenDetails, InvalidateCrScreenDetails } from "../../store/cr-screen-details/cr-screen-details.action";
import { LoadDemands, InvalidateDemands } from "./../../store/demands/demands.action";
import { LoadDemandsWithSchedules, InvalidateDemandsWithSchedules } from "./../../store/demands-withschedules/demands-withschedules.action";
import { LoadEngagementChangeRequests, InvalidateEngagementChangeRequests } from "../../store/engagement-change-requests/engagement-change-requests.action";
import { LoadEngagementDetails, InvalidateEngagementDetails } from "../../store/engagement-details/engagement-details.action";
import { LoadFinancialDetailsV2, InvalidateFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.action";
import { LoadFinancialRoles, InvalidateFinancialRoles } from "../../store/financial-roles/financial-roles.action";
import { LoadInternalFinancialDetails, InvalidateInternalFinancialDetails } from "../../store/internal-financial-details/internal-financial-details.action";
import { LoadInvoices, InvalidateInvoices } from "../../store/invoices/invoices.action";
import { LoadManageSuppliers, InvalidateManageSuppliers } from "../../store/manage-suppliers/manage-suppliers.action";
import { LoadMilestones, InvalidateMilestones } from "../../store/milestones/milestones.action";
import { LoadMyPortfolioEngagements, InvalidateMyPortfolioEngagements } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.action";
import { LoadMyPortfolioFinancialsList, InvalidateMyPortfolioFinancialsList } from "../../store/my-portfolio/my-portfolio-financials-list/my-portfolio-financials-list.action";
import { LoadNpcActuals, InvalidateNpcActuals } from "../../store/npc-actuals/npc-actuals.action";
import { LoadPlanForecastParams, InvalidatePlanForecastParams } from "../../store/plan-forecast-params/plan-forecast-params.action";
import { LoadProjectDetails, InvalidateProjectDetails } from "../../store/project-details/project-details.action";
import { LoadResourceRequests, InvalidateResourceRequests } from "../../store/resource-requests/resource-requests.action";
import { LoadResourceRequestsProjectContext, InvalidateResourceRequestsProjectContext } from "../../store/resource-requests-project-context/resource-requests-project-context.action";
import { LoadWbsIdRoles, InvalidateWbsIdRoles } from "../../store/wbs-id-roles/wbs-id-roles.action";
import { LoadWbsStructures, InvalidateWbsStructures } from "../../store/wbs-structures/wbs-structures.action";
import { Subscription } from "rxjs";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";
import { getDemandsWithSchedulesLoaded, getDemandsWithSchedulesState } from "../../store/demands-withschedules/demands-withschedules.selector";
import { getEngagementForecastRecommendationsLoaded, getEngagementForecastRecommendationsState } from "../../store/engagement-forecast-recommendations/engagement-forecast-recommendations.selector";
import { InvalidateEngagementForecastRecommendations, LoadEngagementForecastRecommendations } from "../../store/engagement-forecast-recommendations/engagement-forecast-recommendations.action";
import { getProjectForecastRecommendationsLoaded, getProjectForecastRecommendationsState } from "../../store/project-forecast-recommendations/project-forecast-recommendations.selector";
import { InvalidateProjectForecastRecommendations, LoadProjectForecastRecommendations } from "../../store/project-forecast-recommendations/project-forecast-recommendations.action";
import { InvalidateEngagementChangeRequestsV2, LoadEngagementChangeRequestsV2 } from "../../store/engagement-change-requests-v2/engagement-change-requests-v2.action";
import { getEngagementChangeRequestsV2, getEngagementChangeRequestsV2Loaded } from "../../store/engagement-change-requests-v2/engagement-change-requests-v2.selector";
import { getUnitRolesLoaded, getUnitRolesState } from "../../store/unit-roles/unit-roles.selector";
import { LoadUnitRoles, InvalidateUnitRoles } from "../../store/unit-roles/unit-roles.action";
import { getEngagementResourceDetails, getEngagementResourceDetailsLoaded } from "../../store/engagement-resource-details/engagement-resource-details.selector";
import { InvalidateEngagementResourceDetails, LoadEngagementResourceDetails } from "../../store/engagement-resource-details/engagement-resource-details.action";
import { InvalidateWbsForecastDetails, LoadWbsForecast } from "../../store/wbs-forecast-details/wbs-forecast.action";
import { getWbsForecastDetailsLoadedState, getWbsForecastState } from "../../store/wbs-forecast-details/wbs-forecast.selector";


class ApiNode {
    public nodeId: string;
    public dependsOn: string[];
    public selector: (store: IState) => void;
    public selectorLoaded: (store: IState) => void;
    public action: Action;
    public invalidate: Action;
    public subscriptions: Subscription[];
    public setup: boolean;

    public constructor(
        nodeId: string,
        dependsOn: string[],
        selectorEntire: (store: IState) => void,
        selectorLoaded: (store: IState) => void,
        dispatchAction: Action,
        invalidateAction: Action,
    ) {
        this.nodeId = nodeId;
        this.dependsOn = dependsOn;
        this.selector = selectorEntire;
        this.selectorLoaded = selectorLoaded;
        this.action = dispatchAction;
        this.invalidate = invalidateAction;
        this.subscriptions = [];
        this.setup = false;
    }

    public unsubscribe(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

@Injectable()
export class StoreDispatchService extends DmServiceAbstract {

    private static ACTUALS: string = "ACTUALS";
    private static CHANGE_REQUESTS: string = "CHANGE_REQUESTS";
    private static CONTACTS: string = "CONTACTS";
    private static CR_ID_ROLES: string = "CR_ID_ROLES";
    private static CR_SCREEN_DETAILS: string = "CR_SCREEN_DETAILS";
    private static ENGAGEMENT_CHANGE_REQUESTS: string = "ENGAGEMENT_CHANGE_REQUESTS";
    private static ENGAGEMENT_CHANGE_REQUESTSV2: string = "ENGAGEMENT_CHANGE_REQUESTSV2";
    private static ENGAGEMENT_DETAILS: string = "ENGAGEMENT_DETAILS";
    private static ENGAGEMENT_FINANCIALS_LIST: string = "ENGAGEMENT_FINANCIALS_LIST";
    private static ENGAGEMENT_FORECAST_RECOMMENDATIONS: string = "ENGAGEMENT_FORECAST_RECOMMENDATIONS";
    private static ENGAGEMENT_RESOURCE_DETAILS: string = "ENGAGEMENT_RESOURCE_DETAILS";
    private static ENGAGEMENT_LIST: string = "ENGAGEMENT_LIST";
    private static ENGAGEMENT_STAFFING_DETAILS: string = "ENGAGEMENT_STAFFING_DETAILS";
    private static FINANCIAL_DETAILS_V2: string = "FINANCIAL_DETAILS_V2";
    private static FINANCIAL_ROLES: string = "FINANCIAL_ROLES";
    private static UNIT_ROLES: string = "UNIT_ROLES";
    private static INTERNAL_FINANCIAL_DETAILS: string = "INTERNAL_FINANCIAL_DETAILS";
    private static INVOICES: string = "INVOICES";
    private static MANAGE_SUPPLIERS: string = "MANAGESUPPLIERS";
    private static MILESTONES: string = "MILESTONES";
    private static NPC_ACTUALS: string = "NPC_ACTUALS";
    private static PLAN_FORECAST_PARAMS: string = "PLAN_FORECAST_PARAMS";
    private static PROJECT_DETAILS: string = "PROJECT_DETAILS";
    private static PROJECT_FORECAST_RECOMMENDATIONS: string = "PROJECT_FORECAST_RECOMMENDATIONS";
    private static PROJECT_STAFFING_DETAILS: string = "PROJECT_STAFFING_DETAILS";
    private static WBS_DEMANDS_WITHOUTSCHEDULES: string = "WBS_DEMANDS_WITHOUTSCHEDULES";
    private static WBS_DEMANDS_WITHSCHEDULES: string = "WBS_DEMANDS_WITHSCHEDULES";
    private static WBS_ID_ROLES: string = "WBS_ID_ROLES";
    private static WBS_STRUCTURES: string = "WBS_STRUCTURES";
    private static WBS_FORECASTDETAILS: string = "WBS_FORECASTDETAILS";


    private primaryApis: string[] = [];
    private secondaryApis: string[] = [];

    private registered = {};

    public constructor(
        @Inject(Store) private store: Store<IState>,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.StoreDispatchService);
    }

    public requireActuals(wbsId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireActuals(wbsId, requiredImmediately); return this;
    }

    public requireContacts(wbsId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireContacts(wbsId, requiredImmediately); return this;
    }

    public requireChangeRequests(requiredImmediately: boolean): StoreDispatchService {
        this._requireChangeRequests(requiredImmediately); return this;
    }

    public requireEngagementChangeRequests(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireEngagementChangeRequests(engagementId, requiredImmediately); return this;
    }

    public requireEngagementChangeRequestsV2(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireEngagementChangeRequestsV2(engagementId, requiredImmediately); return this;
    }

    public requireMyPortfolioEngagementList(loadFromCache: boolean, requiredImmediately: boolean): StoreDispatchService {
        this._requireMyPortfolioEngagementList(loadFromCache, requiredImmediately); return this;
    }

    public requireMyPortfolioEngagementFinancialsList(requireImmediately: boolean): StoreDispatchService {
        this._requireMyPortfolioEngagementFinancialsList(requireImmediately); return this;
    }

    public requireManageSuppliers(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireManageSuppliers(engagementId, requiredImmediately); return this;
    }

    public requireEngagementDetails(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireEngagementDetails(engagementId, requiredImmediately); return this;
    }

    public requireEngagementResourceDetails(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireEngagementResourceDetails(engagementId, requiredImmediately); return this;
    }

    public requireFinancialDetailsV2(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireFinancialDetailsV2(engagementId, requiredImmediately); return this;
    }

    public requireInvoices(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireInvoices(engagementId, requiredImmediately); return this;
    }

    public requireInternalFinancialDetails(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireInternalFinancialDetails(engagementId, requiredImmediately); return this;
    }

    public requireMilestones(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireMilestones(engagementId, requiredImmediately); return this;
    }

    public requireEngagementForecastRecommendations(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireEngagementForecastRecommendations(engagementId, requiredImmediately); return this;
    }

    public requireProjectForecastRecommendations(projectId: string, requireImmediately: boolean): StoreDispatchService {
        this._requireProjectForecastRecommendations(projectId, requireImmediately); return this;
    }

    public requireNpcActuals(wbsId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireNpcActuals(wbsId, requiredImmediately); return this;
    }

    public requireEngagementStaffingDetails(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireEngagementStaffingDetails(engagementId, requiredImmediately); return this;
    }

    public requireProjectStaffingDetails(projectId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireProjectStaffingDetails(projectId, requiredImmediately); return this;
    }

    public requireProjectDetails(projectId: string, requireImmediately: boolean): StoreDispatchService {
        this._requireProjectDetails(projectId, requireImmediately); return this;
    }

    public requireCrIdRoles(crId: string, allowCrEdit: boolean, requiredImmediately: boolean): StoreDispatchService {
        this._requireCrIdRoles(crId, allowCrEdit, requiredImmediately); return this;
    }

    public requireCrScreenDetails(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireCrScreenDetails(engagementId, requiredImmediately); return this;
    }

    public requireFinancialRoles(requiredImmediately: boolean): StoreDispatchService {
        this._requireFinancialRoles(requiredImmediately); return this;
    }

    public requireUnitRoles(requiredImmediately: boolean): StoreDispatchService {
        this._requireUnitRoles(requiredImmediately); return this;
    }

    public requireWbsIdRoles(engagementId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requireWbsIdRoles(engagementId, requiredImmediately); return this;
    }

    public requireWbsDemandsV2WithoutSchedules(wbsId: string, requireImmediately: boolean): StoreDispatchService {
        this._requireWbsDemandsWithoutSchedulesV2(wbsId, requireImmediately); return this;
    }

    public requireWbsDemandsV2WithSchedules(wbsId: string, requireImmediately: boolean): StoreDispatchService {
        this._requireWbsDemandsWithSchedulesV2(wbsId, requireImmediately); return this;
    }

    public requirePlanForecastParams(wbsId: string, requiredImmediately: boolean): StoreDispatchService {
        this._requirePlanForecastParams(wbsId, requiredImmediately); return this;
    }

    public requireWbsStructures(wbsId: string, requireImmediately: boolean): StoreDispatchService {
        this._requireWbsStructures(wbsId, requireImmediately); return this;
    }

    public requireWbsForecastDetails(wbsId: string, requireImmediately: boolean): StoreDispatchService {
        this._requireWbsForecastDetails(wbsId, requireImmediately); return this;
    }

    /**
     * load all apis that have been registered to the api dependancy graph.
     * It is done in two steps, first the primary apis, and then the secondary apis.
     */
    public load(): void {
        const primaryApis = this.primaryApis;
        const secondaryApis = this.secondaryApis;
        this.primaryApis = [];
        this.secondaryApis = [];
        const apiLoaded = [];

        for (const nodeId of primaryApis) {
            if (!this.registered[nodeId].setup) {
                apiLoaded.push(this.loadAPI(nodeId));
            }
        }

        // wait for all primary apis to load and then load secondary APIs
        Promise.all(apiLoaded).then(() => {
            for (const nodeId of secondaryApis) {
                if (!this.registered[nodeId].setup) {
                    this.loadAPI(nodeId);
                }
            }
        });
    }

    /**
     * Destroy the api node graph
     */
    public tearDown(): void {
        for (const apiName in this.registered) {
            this.registered[apiName].unsubscribe();
            delete this.registered[apiName];
        }
    }

    /**
     * define subscriptions to load api with `nodeId`
     * @param nodeId the name of the api to create subscriptions for (should be defined in the this.registered variables)
     */
    private loadAPI(nodeId: string): Promise<void> {
        for (const dependancyName of this.registered[nodeId].dependsOn) {
            if (!(this.registered[dependancyName].setup)) {
                this.loadAPI(dependancyName);
            }
        }

        // if dependency changes then reload
        for (const dependancyName of this.registered[nodeId].dependsOn) {
            this.registered[nodeId].subscriptions.push(
                this.store.select(this.registered[dependancyName].selectorLoaded).pipe(withLatestFrom(this.store))
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .subscribe(([dependancyLoaded, store]) => {
                        const dependenciesReady: boolean = this.registered[nodeId].dependsOn.every(
                            (dependancy) => this.registered[dependancy].selectorLoaded(store)
                        );
                        if (dependenciesReady) {
                            // if all dependencies are now loaded then reload the API
                            this.store.dispatch(this.registered[nodeId].action);
                        } else if (this.registered[nodeId].selectorLoaded(store)) {
                            // if a dependency unloads and the API is currently loaded then invalidate it
                            this.store.dispatch(this.registered[nodeId].invalidate);
                        }
                    })
            );
        }

        // if api is unloaded then load provided dependencies are loaded
        this.registered[nodeId].subscriptions.push(this.store.select(this.registered[nodeId].selectorLoaded).pipe(withLatestFrom(this.store)).subscribe(([apiLoaded, store]) => {
            const dependanciesReady: boolean = this.registered[nodeId].dependsOn.every((dependancyName) => this.registered[dependancyName].selectorLoaded(store));
            if (dependanciesReady && !apiLoaded && !this.registered[nodeId].selector(store).loading) {
                this.store.dispatch(this.registered[nodeId].action);
            }
        }));

        // this flag indicates that observers have been set up for this node
        this.registered[nodeId].setup = true;

        // return promise which completes when node is loaded
        return this.store.select(this.registered[nodeId].selectorLoaded).pipe(takeWhile((loaded) => !loaded)).toPromise();
    }

    /**
     * Add the My Portfolio Engagement List object to the dependency graph. This is independent of any engagement Id.
     *
     * @private
     * @param {boolean} requiredImmediately
     * @returns {string}
     * @memberof StoreDispatchService
     */
    private _requireMyPortfolioEngagementList(loadFromCache: boolean, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_LIST;
        if (!(id in this.registered)) {
            const engagementList: ApiNode = new ApiNode(
                id, [],
                getMyPortfolioEngagementListState,
                getMyPortfolioEngagementListLoaded,
                new LoadMyPortfolioEngagements(loadFromCache),
                new InvalidateMyPortfolioEngagements(),
            );
            this.registered[id] = engagementList;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * Add the My Portfolio Financials List object to the dependency graph. This is independent of any engagement Id.
     * This is the financial list that corresponds with the engagement list on My Portfolio for the overview financial tabs.
     *
     * @private
     * @param {boolean} requiredImmediately
     * @returns {string}
     * @memberof StoreDispatchService
     */
    private _requireMyPortfolioEngagementFinancialsList(requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_FINANCIALS_LIST;
        const engagementListId = this._requireMyPortfolioEngagementList(true, requiredImmediately);
        if (!(id in this.registered)) {
            const engagementFinancialsList: ApiNode = new ApiNode(
                id, [engagementListId],
                getMyPortfolioFinancialsListState,
                getMyPortfolioFinancialsListLoaded,
                new LoadMyPortfolioFinancialsList(),
                new InvalidateMyPortfolioFinancialsList()
            );

            this.registered[id] = engagementFinancialsList;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }


    /**
     * add engagementDetails into the dependancy graph.
     */
    private _requireEngagementDetails(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_DETAILS + "_" + engagementId;
        if (!(id in this.registered)) {
            const engagementDetails: ApiNode = new ApiNode(
                id, [],
                getEntireEngagementDetails(engagementId),
                getEngagementDetailsLoaded(engagementId),
                new LoadEngagementDetails(engagementId),
                new InvalidateEngagementDetails(engagementId),
            );
            this.registered[id] = engagementDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }


    private _requireActuals(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ACTUALS + "_" + wbsId;
        if (!(id in this.registered)) {
            const actuals: ApiNode = new ApiNode(
                id, [],
                getEntireActuals(wbsId),
                getActualsLoaded(wbsId),
                new LoadActuals(wbsId),
                new InvalidateActuals(wbsId),
            );
            this.registered[id] = actuals;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add contacts into the dependancy graph. 
     *
     * @private
     * @param {string} wbsId
     * @param {boolean} requiredImmediately
     * @returns {string}
     * @memberof StoreDispatchService
     */
    private _requireContacts(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.CONTACTS + "_" + wbsId;
        if (!(id in this.registered)) {
            const contacts: ApiNode = new ApiNode(
                id, [],
                getEntireContacts(wbsId),
                getContactsLoaded(wbsId),
                new LoadContacts(wbsId),
                new InvalidateContacts(wbsId),
            );
            this.registered[id] = contacts;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }   

    /**
     * add financialDetailsV2 into the dependancy graph.
     */
    private _requireFinancialDetailsV2(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.FINANCIAL_DETAILS_V2 + "_" + engagementId;
        if (!(id in this.registered)) {
            const financialDetailsV2: ApiNode = new ApiNode(
                id, [],
                getEntireFinancialDetailsV2(engagementId),
                getFinancialDetailsV2Loaded(engagementId),
                new LoadFinancialDetailsV2(engagementId),
                new InvalidateFinancialDetailsV2(engagementId),
            );
            this.registered[id] = financialDetailsV2;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add invoices into the dependancy graph.
     */
    private _requireInvoices(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.INVOICES + "_" + engagementId;
        const engagementDetailsId = this._requireEngagementDetails(engagementId, requiredImmediately);
        if (!(id in this.registered)) {
            const invoices: ApiNode = new ApiNode(
                id, [engagementDetailsId],
                getEntireInvoices(engagementId),
                getInvoicesLoaded(engagementId),
                new LoadInvoices(engagementId),
                new InvalidateInvoices(engagementId),
            );
            this.registered[id] = invoices;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add financialDetailsV2 into the dependancy graph.
     */
    private _requireInternalFinancialDetails(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.INTERNAL_FINANCIAL_DETAILS + "_" + engagementId;
        if (!(id in this.registered)) {
            const internalFinancialDetailsV2: ApiNode = new ApiNode(
                id, [],
                getEntireInternalFinancialDetails(engagementId),
                getInternalFinancialDetailsLoaded(engagementId),
                new LoadInternalFinancialDetails(engagementId),
                new InvalidateInternalFinancialDetails(engagementId),
            );
            this.registered[id] = internalFinancialDetailsV2;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add milestones into the dependancy graph.
     */
    private _requireMilestones(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.MILESTONES + "_" + engagementId;
        if (!(id in this.registered)) {
            const milestones: ApiNode = new ApiNode(
                id, [],
                getEntireMilestones(engagementId),
                getMilestonesLoaded(engagementId),
                new LoadMilestones(engagementId),
                new InvalidateMilestones(engagementId)
            );
            this.registered[id] = milestones;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * Adds engagement ML Forecast Recommendations into the dependancy graph.
     * @param engagementId 
     * @param requiredImmediately 
     */
    private _requireEngagementForecastRecommendations(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_FORECAST_RECOMMENDATIONS + "_" + engagementId;
        if (!(id in this.registered)) {
            const forecastRecommendations: ApiNode = new ApiNode(
                id, [],
                getEngagementForecastRecommendationsState(engagementId),
                getEngagementForecastRecommendationsLoaded(engagementId),
                new LoadEngagementForecastRecommendations(engagementId),
                new InvalidateEngagementForecastRecommendations(engagementId)
            );
            this.registered[id] = forecastRecommendations;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * Adds project ML Forecast Recommendations into the dependancy graph.
     * @param projectId 
     * @param requiredImmediately 
     */
    private _requireProjectForecastRecommendations(projectId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.PROJECT_FORECAST_RECOMMENDATIONS + "_" + projectId;
        if (!(id in this.registered)) {
            const forecastRecommendations: ApiNode = new ApiNode(
                id, [],
                getProjectForecastRecommendationsState(projectId),
                getProjectForecastRecommendationsLoaded(projectId),
                new LoadProjectForecastRecommendations(projectId),
                new InvalidateProjectForecastRecommendations(projectId)
            );
            this.registered[id] = forecastRecommendations;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * Adds NPC Actuals/non-procured materials to the dependency graph
     * @param wbsId 
     * @param requiredImmediately 
     */
    private _requireNpcActuals(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.NPC_ACTUALS + "_" + wbsId;
        if (!(id in this.registered)) {
            const actuals: ApiNode = new ApiNode(
                id, [],
                getEntireNpcActuals(wbsId),
                getNpcActualsLoaded(wbsId),
                new LoadNpcActuals(wbsId),
                new InvalidateNpcActuals(wbsId),
            );
            this.registered[id] = actuals;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add manageSuppliers into the dependancy graph.
     */
    private _requireManageSuppliers(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.MANAGE_SUPPLIERS + "_" + engagementId;
        if (!(id in this.registered)) {
            const manageSuppliers: ApiNode = new ApiNode(
                id, [],
                getEntireManageSuppliers(engagementId),
                getManageSuppliersLoaded(engagementId),
                new LoadManageSuppliers(engagementId),
                new InvalidateManageSuppliers(engagementId),
            );
            this.registered[id] = manageSuppliers;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add engagement staffing details to the dependancy graph.
     */
    private _requireEngagementStaffingDetails(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_STAFFING_DETAILS + "_" + engagementId;
        const engagementDetailsId = this._requireEngagementDetails(engagementId, requiredImmediately);
        if (!(id in this.registered)) {
            const staffingDetails: ApiNode = new ApiNode(
                id,
                [engagementDetailsId],
                getEntireResourceRequestsStateObject(engagementId),
                getResourceRequestsLoaded(engagementId),
                new LoadResourceRequests(engagementId),
                new InvalidateResourceRequests(engagementId),
            );
            this.registered[id] = staffingDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add engagement staffing details to the dependancy graph.
     */
    private _requireProjectStaffingDetails(projectId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.PROJECT_STAFFING_DETAILS + "_" + projectId;
        if (!(id in this.registered)) {
            const staffingDetails: ApiNode = new ApiNode(
                id,
                [],
                getEntireResourceRequestsProjectContextStateObject(projectId),
                getResourceRequestsProjectContextLoaded(projectId),
                new LoadResourceRequestsProjectContext(projectId),
                new InvalidateResourceRequestsProjectContext(projectId),
            );
            this.registered[id] = staffingDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add projectDetails into the dependancy graph.
     * This calls the same base as requireEngagementDetails, but it filters out the project object and returns it as type 
     * IProjectEngagementDetails
     * Needs to be updated to not call the API and instead check the store first and then fire the store call
     */
    private _requireProjectDetails(projectId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.PROJECT_DETAILS + "_" + projectId;
        if (!(id in this.registered)) {
            const projectDetails: ApiNode = new ApiNode(
                id, [],
                getEntireProjectDetails(projectId),
                getProjectDetailsLoaded(projectId),
                new LoadProjectDetails(projectId),
                new InvalidateProjectDetails(projectId),
            );
            this.registered[id] = projectDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add change requests to the dependancy graph
     */
    private _requireChangeRequests(requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.CHANGE_REQUESTS;
        if (!(id in this.registered)) {
            const changeRequests: ApiNode = new ApiNode(
                id, [],
                getChangeRequests,
                getChangeRequestsLoaded,
                new LoadChangeRequests(),
                new InvalidateChangeRequests(),
            );
            this.registered[id] = changeRequests;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add engagement change requests to the dependancy graph
     */
    private _requireEngagementChangeRequests(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_CHANGE_REQUESTS + "_" + engagementId;
        if (!(id in this.registered)) {
            const engagementChangeRequests: ApiNode = new ApiNode(
                id, [],
                getEngagementChangeRequests(engagementId),
                getEngagementChangeRequestsLoaded(engagementId),
                new LoadEngagementChangeRequests(engagementId),
                new InvalidateEngagementChangeRequests(engagementId),
            );
            this.registered[id] = engagementChangeRequests;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add engagement change requests to the dependancy graph
     */
    private _requireEngagementChangeRequestsV2(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_CHANGE_REQUESTSV2 + "_" + engagementId;
        if (!(id in this.registered)) {
            const engagementChangeRequests: ApiNode = new ApiNode(
                id, [],
                getEngagementChangeRequestsV2(engagementId),
                getEngagementChangeRequestsV2Loaded(engagementId),
                new LoadEngagementChangeRequestsV2(engagementId),
                new InvalidateEngagementChangeRequestsV2(engagementId),
            );
            this.registered[id] = engagementChangeRequests;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * Adds engagement resource details into the dependancy graph.
     * @param engagementId 
     * @param requiredImmediately 
     */
    private _requireEngagementResourceDetails(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.ENGAGEMENT_RESOURCE_DETAILS + "_" + engagementId;
        if (!(id in this.registered)) {
            const engagementResourceDetails: ApiNode = new ApiNode(
                id, [],
                getEngagementResourceDetails(engagementId),
                getEngagementResourceDetailsLoaded(engagementId),
                new LoadEngagementResourceDetails(engagementId),
                new InvalidateEngagementResourceDetails(engagementId)
            );
            this.registered[id] = engagementResourceDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add crid roles  to dependancy graph
     */
    private _requireCrIdRoles(crId: string, allowCrEdit: boolean, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.CR_ID_ROLES + "_" + crId;
        if (!(id in this.registered)) {
            const crIdRoles: ApiNode = new ApiNode(
                id, [],
                getCrIdRolesState(crId),
                getCrIdRolesLoaded(crId),
                new LoadCrIdRoles(crId, allowCrEdit),
                new InvalidateCrIdRoles(crId)
            );
            this.registered[id] = crIdRoles;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add cr screen details to dependancy graph
     */
    private _requireCrScreenDetails(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.CR_SCREEN_DETAILS + "_" + engagementId;
        if (!(id in this.registered)) {
            const crScreenDetails: ApiNode = new ApiNode(
                id, [],
                getCrScreenDetailsState(engagementId),
                getCrScreenDetailsLoaded(engagementId),
                new LoadCrScreenDetails(engagementId),
                new InvalidateCrScreenDetails(engagementId)
            );
            this.registered[id] = crScreenDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * Add Unit Roles to dependancy graph
     */
    private _requireUnitRoles(requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.UNIT_ROLES;
        if (!(id in this.registered)) {
            const unitRoles: ApiNode = new ApiNode(
                id, [],
                getUnitRolesState,
                getUnitRolesLoaded,
                new LoadUnitRoles(),
                new InvalidateUnitRoles()
            );
            this.registered[id] = unitRoles;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * Add Financial Roles to dependancy graph
     */
    private _requireFinancialRoles(requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.FINANCIAL_ROLES;
        if (!(id in this.registered)) {
            const financialRoles: ApiNode = new ApiNode(
                id, [],
                getFinancialRolesState,
                getFinancialRolesLoaded,
                new LoadFinancialRoles(),
                new InvalidateFinancialRoles()
            );
            this.registered[id] = financialRoles;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add wbsId roles to dependancy graph
     */
    private _requireWbsIdRoles(engagementId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.WBS_ID_ROLES + "_" + engagementId;
        if (!(id in this.registered)) {
            const wbsIdRoles: ApiNode = new ApiNode(
                id, [],
                getWbsIdRolesState(engagementId),
                getWbsIdRolesLoaded(engagementId),
                new LoadWbsIdRoles(engagementId),
                new InvalidateWbsIdRoles(engagementId)
            );
            this.registered[id] = wbsIdRoles;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

    /**
     * add demand details with out schedules assocaited with a WBS_ID (L0 or L1) to the dependency graph
     *
     * @private
     * @param {string} wbsId
     * @param {boolean} requiredImmediately
     * @returns {string}
     * @memberof StoreDispatchService
     */
    private _requireWbsDemandsWithoutSchedulesV2(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.WBS_DEMANDS_WITHOUTSCHEDULES + "_" + wbsId;

        if (!(id in this.registered)) {
            const wbsDetails: ApiNode = new ApiNode(
                id, [],
                getDemandsState(wbsId),
                getDemandsLoaded(wbsId),
                new LoadDemands(wbsId),
                new InvalidateDemands(wbsId),
            );
            this.registered[id] = wbsDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }

        return id;
    }

    /**
     * add demand details with schedules assocaited with a WBS_ID (L0 or L1) to the dependency graph
     *
     * @private
     * @param {string} wbsId
     * @param {boolean} requiredImmediately
     * @returns {string}
     * @memberof StoreDispatchService
     */
    private _requireWbsDemandsWithSchedulesV2(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.WBS_DEMANDS_WITHSCHEDULES + "_" + wbsId;

        if (!(id in this.registered)) {
            const wbsDetails: ApiNode = new ApiNode(
                id, [],
                getDemandsWithSchedulesState(wbsId),
                getDemandsWithSchedulesLoaded(wbsId),
                new LoadDemandsWithSchedules(wbsId),
                new InvalidateDemandsWithSchedules(wbsId),
            );
            this.registered[id] = wbsDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }

        return id;
    }

    private _requireWbsStructures(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.WBS_STRUCTURES + "_" + wbsId;

        if (!(id in this.registered)) {
            const wbsStructures: ApiNode = new ApiNode(
                id, [],
                getInitialWbsStructures(wbsId),
                getWbsStructuresLoaded(wbsId),
                new LoadWbsStructures(wbsId),
                new InvalidateWbsStructures(wbsId)
            );
            this.registered[id] = wbsStructures;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }

        return id;
    }


    private _requireWbsForecastDetails(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.WBS_FORECASTDETAILS + "_" + wbsId;

        if (!(id in this.registered)) {
            const wbsForecastDetails: ApiNode = new ApiNode(
                id, [],
                getWbsForecastState(wbsId),
                getWbsForecastDetailsLoadedState(wbsId),
                new LoadWbsForecast(wbsId),
                new InvalidateWbsForecastDetails(wbsId)
            );
            this.registered[id] = wbsForecastDetails;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }

        return id;
    }    

    /**
     * add plan forecast params to dependancy graph
     */
    private _requirePlanForecastParams(wbsId: string, requiredImmediately: boolean): string {
        const id: string = StoreDispatchService.PLAN_FORECAST_PARAMS + "_" + wbsId;
        if (!(id in this.registered)) {
            const planForecastParams: ApiNode = new ApiNode(
                id, [],
                getPlanForecastParamsState(wbsId),
                getPlanForecastParamsLoaded(wbsId),
                new LoadPlanForecastParams(wbsId),
                new InvalidatePlanForecastParams(wbsId)
            );
            this.registered[id] = planForecastParams;
        }
        if (requiredImmediately) {
            if (!(id in this.primaryApis)) {
                this.primaryApis.push(id);
            }
        } else {
            if (!(id in this.secondaryApis)) {
                this.secondaryApis.push(id);
            }
        }
        return id;
    }

}