import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Components, EntityType, LogEventConstants, NoDataText, RoleFullName, SourceConstants } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { ITile } from "../../../tiles/dm-tile/dm-tile.component";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { StateService } from "@uirouter/angular";
import { CreateTeamsGroupModalComponent } from "../../modals/create-teams-group/create-teams-group.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ManageEbsService } from "../../../../common/services/manage-ebs.service";
import { ITeamContacts } from "../../../../common/services/contracts/team-contacts.contracts";
import { ISimpleContact } from "../../../../components/shared/contacts/contacts.component";
import { EditTeamStructureModalComponent } from "../../modals/edit-team-structure/edit-team-structure.component";
import { TeamStructureModalComponent } from "../../../../components/shared/team-structure/team-structure.component";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { ISelectedUserAttributes } from "../../../../components/tiles/type-ahead/type-ahead-contracts";
import { EditProjectTeamStructureModalComponent } from "../../modals/edit-project-team-structure/edit-project-team-structure.component";
import { FxpRouteService } from "@fxp/fxpservices";
import { FeedbackModalService } from "../../../../components/tiles/feedback-modal/feedback-modal.service";

export interface IPersonDetails {
    displayName: string;
    mail?: string;
    userPrincipalName?: string;
    personImage?: string;
    jobTitle?: string;
    alias?: string;
}

@Component({
    selector: "dm-entity-team-structure",
    templateUrl: "./entity-team-structure.html",
    styleUrls: ["./entity-team-structure.scss"]
})

export class EntityTeamStructureComponent extends DmComponentAbstract {
    public engagementDataV2: IEngagementDetailsV2;
    public isInternalEngagement: boolean;
    public requestStateChangeText: string;
    public isProjectContext: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public teamContact: ITeamContacts[] = [];
    public engagementContacts: ITeamContacts;
    public primaryPpjmContact: ISimpleContact;
    public additionalPpjmContacts: ISimpleContact[] = [];
    public additionalPpjmSlicedContacts: ISimpleContact[] = [];
    public primaryDmmContact: ISimpleContact;
    public relmanContact: ISimpleContact;
    public additionalPpjmPersonDetails: IPersonDetails[] = [];
    public personDetails: IPersonDetails[] = [];
    public ppjmTeamData = { data: { role: "Primary Domain Project Manager" } };
    public dmmTeamData = { data: { role: "Primary Domain Manager" } };
    public relmanTeamData = { data: { role: "Relationship Manager" } };
    public pjmTeamData = { data: { role: "Domain Project Manager" } };
    public tileMinHeight: string = "365px";
    public loggedInUserInfo: ISelectedUserAttributes;
    public primaryPjmContact: ISimpleContact;
    public additionalPjmContacts: ISimpleContact[] = [];
    public additionalPjmPersonDetails: IPersonDetails[] = [];
    public additionalPjmSlicedContacts: ISimpleContact[] = [];
    public projectFullDetails: IProjectDetailsV2;
    public LogEventConstants = LogEventConstants;
    public currentRouteName: string;
    public unitSubmitterContacts: ISimpleContact[] = [];
    public unitSubmitterPersonDetails: IPersonDetails[] = [];
    public unitSubmitterSlicedContacts: ISimpleContact[] = [];
    private selectedProjectId: string;
    private emailPostFix: string = "@microsoft.com";

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector,
        @Inject(ManageEbsService) private manageEbsService: ManageEbsService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
    ) {
        super(dmLogger, Components.ManageEbsEngagementTeamStructure);
    }

    public ngOnInit(): void {
        this.requestStateChangeText = "Requesting an EBS State change will notify the Assistant Services Controller (ASC) to review and change the EBS State. The requester will be notified when this activity is completed.";
        this.selectedProjectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = this.selectedProjectId ? true : false;
        this.loggedInUserInfo = this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr();
        this.tileContent = {
            title: "Team Structure",
            iconTitle: "icon-people"
        };

        this.configurationService.initialize()
            .then(() => {
                this.errorText = NoDataText.NoEBSDataText;
                const wbsId: string = this.isProjectContext ?
                    this.selectedProjectId :
                    this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
                this.storeDispatchService
                    .requireEngagementDetails(wbsId, true)
                    .load();
                const engagementDetails$ = this.store.select(getEntireEngagementDetails(wbsId));

                engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
                    if (engagementDetails.loaded) {
                        this.personDetails = [];
                        this.engagementDataV2 = engagementDetails.engagementDetails;
                        this.projectFullDetails = this.engagementDataV2.projects.filter((project) => project.id === this.selectedProjectId)[0];
                        // this.tileMinHeight = (this.engagementDataV2.canEditEnagagement || this.engagementDataV2.canRequestEbsStateChange) ? "456px" : "350px";
                        this.teamContact = this.manageEbsService.getTeamContacts(this.engagementDataV2);
                        this.engagementContacts = this.teamContact.filter((contact) => contact.entityType === EntityType.Engagement)[0];
                        this.primaryPpjmContact = this.engagementContacts.contacts.filter((teamMember) => teamMember.role === RoleFullName.PrimaryProjectManager)[0];
                        this.additionalPpjmContacts = this.engagementContacts.contacts.filter((teamMember) => teamMember.role === RoleFullName.AdditionalPrimaryProjectManager);
                        this.additionalPpjmSlicedContacts = this.additionalPpjmContacts.slice(0, 5);
                        this.primaryDmmContact = this.engagementContacts.contacts.filter((teamMember) => teamMember.role === RoleFullName.PrimaryDomainManager)[0];
                        this.relmanContact = this.engagementContacts.contacts.filter((teamMember) => teamMember.role === RoleFullName.RelationshipManager)[0];
                        if (this.primaryPpjmContact) {
                            this.ppjmTeamData = { data: this.primaryPpjmContact };
                            this.personDetails.push(this.getPersonDetails(this.primaryPpjmContact));
                        }
                        if (this.primaryDmmContact) {
                            this.dmmTeamData = { data: this.primaryDmmContact };
                            this.personDetails.push(this.getPersonDetails(this.primaryDmmContact));
                        }
                        if (this.relmanContact) {
                            this.relmanTeamData = { data: this.relmanContact };
                            this.personDetails.push(this.getPersonDetails(this.relmanContact));
                        }
                        if (this.additionalPpjmSlicedContacts && this.additionalPpjmSlicedContacts.length) {
                            for (const adPpjm of this.additionalPpjmSlicedContacts) {
                                this.personDetails.push(this.getPersonDetails(adPpjm));
                            }
                            this.additionalPpjmPersonDetails = this.personDetails.filter((adPpjmPerson) => adPpjmPerson.jobTitle === RoleFullName.AdditionalPrimaryProjectManager);
                        } else {
                            this.additionalPpjmPersonDetails = [];
                        }

                        if (this.isProjectContext) {
                            const projectContacts = this.teamContact.filter((contact) => (contact.entityType === EntityType.Project) && (contact.entityId === this.selectedProjectId))[0];
                            if (projectContacts) {
                                this.primaryPjmContact = projectContacts.contacts.filter((teamMember) => teamMember.role === RoleFullName.ProjectManager)[0];
                                this.additionalPjmContacts = projectContacts.contacts.filter((teamMember) => teamMember.role === RoleFullName.AdditionalProjectManager);
                                this.additionalPjmSlicedContacts = this.additionalPjmContacts.slice(0, 5);
                                this.unitSubmitterContacts = projectContacts.contacts.filter((teamMember) => teamMember.role === RoleFullName.UnitSubmitter);
                                this.unitSubmitterSlicedContacts = this.unitSubmitterContacts.slice(0, 5);
                                if (this.primaryPjmContact) {
                                    this.pjmTeamData = { data: this.primaryPjmContact };
                                    this.personDetails.push(this.getPersonDetails(this.primaryPjmContact));
                                }
                                if (this.additionalPjmSlicedContacts && this.additionalPjmSlicedContacts.length) {
                                    for (const adPjm of this.additionalPjmSlicedContacts) {
                                        this.personDetails.push(this.getPersonDetails(adPjm));
                                    }
                                    this.additionalPjmPersonDetails = this.personDetails.filter((adPjmPerson) => adPjmPerson.jobTitle === RoleFullName.AdditionalProjectManager);
                                } else {
                                    this.additionalPjmPersonDetails = [];
                                }
                                if (this.unitSubmitterSlicedContacts && this.unitSubmitterSlicedContacts.length) {
                                    for (const unitSubmitter of this.unitSubmitterSlicedContacts) {
                                        this.personDetails.push(this.getPersonDetails(unitSubmitter));
                                    }
                                    this.unitSubmitterPersonDetails = this.personDetails.filter((person) => person.jobTitle === RoleFullName.UnitSubmitter);
                                } else {
                                    this.unitSubmitterPersonDetails = [];
                                }
                            }
                        }
                    }

                    this.refreshOnItemInvalidation(engagementDetails);
                    this.setLoadersBasedOnItemState(engagementDetails);
                    this.setErrorsBasedOnItemState(engagementDetails);
                    if (engagementDetails.error) {
                        this.isServerError = true;
                    }
                });
            });

    }

    /**
     *  Opens team structure modal
     */
    public openTeamStructureModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.OpenTeamStructureModal, LogEventConstants.ManageEBSViewTeamStructureLinkClicked);
        const modalRef = this.modalService.open(TeamStructureModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 in active team-structure-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.teamContacts = this.manageEbsService.getTeamContacts(this.engagementDataV2);
        modalRef.result.then(() => {
            this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.OpenTeamStructureModal, LogEventConstants.ManageEBSViewTeamStructureModalClosed);
        });
    }

    /**
     *  Opens create team structure modal
     */
    public createTeamStructure(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.CreateTeamStructure, LogEventConstants.ManageEBSCreateTeamStructureLinkClicked);
        this.modalService.open(CreateTeamsGroupModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 create-team-group-modal in",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
    }

    /**
     *  Opens edit team structure modal
     */
    public editTeamStructure(): void {
        if (!this.isProjectContext) {
            this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.EditTeamStructure, LogEventConstants.ManageEBSEditTeamStructureLinkClicked);
            const modalRef = this.modalService.open(EditTeamStructureModalComponent, {
                backdrop: "static",
                windowClass: "dm-modal-v2 edit-team-structure-modal in",
                keyboard: true,
                centered: true,
                injector: this.injector
            });
            modalRef.componentInstance.selectedEngagement = this.engagementDataV2;
            modalRef.result.then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.EditTeamStructure, LogEventConstants.ManageEBSEditEngagementTeamStructureModalClosed);
            });
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.EditTeamStructure, LogEventConstants.ManageEBSEditProjectTeamStructureLinkClicked);
            const modalRef = this.modalService.open(EditProjectTeamStructureModalComponent, {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector
            });
            modalRef.componentInstance.selectedProject = this.projectFullDetails;
            modalRef.componentInstance.engagementDetails = this.engagementDataV2;
            modalRef.result.then(() => {
                this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.EditTeamStructure, LogEventConstants.ManageEBSEditProjectTeamStructureModalClosed);
            });
        }
    }

    /**
     * Logs the event for when a link is clicked
     */
    public linkClicked(logEventConstant: string): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.LinkClicked, logEventConstant);
    }

    /**
     *  get person details
     */
    private getPersonDetails(contact: ISimpleContact): IPersonDetails {
        const personDetails: IPersonDetails = {
            displayName: contact.name,
            alias: contact.alias
        };
        personDetails.mail = contact.alias + this.emailPostFix;
        if (this.loggedInUserInfo) {
            if (this.loggedInUserInfo.userAlias && contact.alias && contact.alias.toLowerCase() !== this.loggedInUserInfo.userAlias.toLowerCase()) {
                personDetails.userPrincipalName = contact.alias + this.emailPostFix;
            }
        }
        personDetails.jobTitle = contact.role;
        return personDetails;
    }
}