<section id="drop-down" *ngIf="!hideDropDown">
    <div class="btn-group drp-dwn" auto-close="outsideClick" (openChange)="toggleDropDown($event)" ngbDropdown
        #myDrop="ngbDropdown" [ngClass]="{'drp-dwn-width': showMyPortfolioTabs}">
        <!--="ngbDropdown"-->
        <button (keydown)="closeDropDownOnTab($event)" (keydown.shift.tab)="myDrop.close()" id="simple-btn-keyboard-nav"
            type="button" class="dd-title nxItem tabNav" ngbDropdownToggle [title]="placeholder" [attr.aria-label]="placeholder + ' ' + menuStatus">
            <span class="font-caption-alt selected-item">
                {{placeholder}}
            </span>
            <span class="icon icon-chevron-down pull-right"></span>
        </button>
        <!-- TO do set focus -->
        <!-- (click)="setFocus(event)" -->
        <div class="dropdown-menu" ngbDropdownMenu>
            <section class="dm-p-x-imp-0">
                <div class="dm-search dm-m-y-5">
                    <input class="font-caption-alt input__text dm-p-l-imp-35" autofocus type="text" id="Search" 
                        name="Search" [(ngModel)]="searchText" placeholder="Start typing to filter..." 
                        (ngModelChange)="searchEngagementOrProject()" minlength="3" aria-live="polite" />
                    <span role="button" tabindex="0" class="icon icon-cancel dm-search__cancel-icon dm-p-3 set-focus"
                        aria-label="Clear" (click)="clearText()" (keyup.enter)="clearText()"
                        (keyup.Space)="clearText()"></span>
                    <p role="status" class="sr-only" aria-atomic="true">
                        <span id="searchResultMsg"></span>
                    </p>
                </div>
            </section>  
            <dm-loading *ngIf="isComponentLoading" [showLoading]="showLoading" [errorText]="errorText">
            </dm-loading>

            <ng-container *ngIf="!isComponentLoading">
                <div>
                    <section id="accMenu" close-others="true">
                        <div class="clearfix" is-open="engagement.isMenuOpen"
                            [ngClass]="{'panel-open':engagement.isMenuOpen , 'border-bottom':engagement.type !== 'engagement'}"
                            *ngFor="let engagement of dropDownItems | orderBy: 'engagementId'; trackBy: trackDropDownItemsByEngagementId; let isLastEngagement = last">
                            <!-- For Customer Delivery Engagement-->
                            <section *ngIf="engagement.type === 'engagement'" class="clearfix mar-4">
                                <div class="secondary-dd-title">
                                    <button tabindex="0" [attr.aria-expanded]="engagement.isMenuOpen"
                                        class="secondary-dd-title--button pull-left w-70 nxItem set-focus"
                                        (click)="expandMenu(dropDownItems, engagement)"
                                        [attr.aria-label]="'button' + engagement.name + engagement.engagementId + 'press space to toggle the menu item then Navigate with Tab key'"
                                        (keydown)="closeDropDownOnTab($event);keyNavigationForAccordion(dropDownItems,engagement,$event)"
                                        (keydown.tab)="isLastEngagement ? engagement.isMenuOpen ? null : myDrop.close() : null">
                                        <span role="none"
                                            [ngClass]="{'icon-chevron-up': engagement.isMenuOpen, 'icon-chevron-down': !engagement.isMenuOpen}"
                                            class="icon pull-left dd-sub-left">
                                        </span>
                                        <span class="font-caption-alt label dd-sub-right">
                                            <span class="d-block font-caption"
                                                [innerHTML]="engagement.name | highlight : highlightText"
                                                role="none"></span>
                                            <span class="d-block title__color7"
                                                [innerHTML]="engagement.engagementId | highlight : highlightText"
                                                role="none">
                                            </span>
                                        </span>
                                    </button>
                                    <div class="spl-icon-wrapper">
                                        <div>
                                            <span *ngIf="engagement.delegationData"
                                                [title]="engagement.delegationData.delegationType + engagement.delegationData.delegationFullName">
                                                <dm-tooltip role="tooltip" class="dm-tooltipinline delegated"
                                                    *ngIf="engagement.delegationData.delegationType"
                                                    [dmPlacement]="'left'"
                                                    [ngbTooltipContent]="delegationTooltipEngagement"
                                                    [dmIcon]="'delegated_icon'"
                                                    ariaLabelToolTip="delegation Information">
                                                </dm-tooltip>
                                                <span class="outward"
                                                    *ngIf="engagement.delegationData.delegationType === delegationTypeText.DelegationOutward">
                                                    <i class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                </span>
                                                <span class="inward"
                                                    *ngIf="engagement.delegationData.delegationType === delegationTypeText.DelegationInward">
                                                    <i class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                </span>
                                            </span>

                                        </div>
                                        <div>
                                            <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec"
                                                *ngIf="!engagement.isUsPubSec && isPubSecEnabled && engagement.isPublicSector"
                                                [dmPlacement]="'bottom'" [ngbTooltipContent]="'Pub Sec'"
                                                [dmIcon]="'pubsec_icon'" ariaLabelToolTip="pub sec Information">
                                            </dm-tooltip>
                                            <span *ngIf="engagement.isUsPubsec" class="uspubsec"></span>
                                            <dm-tooltip role="tooltip"
                                                class="dm-tooltipinline dm-tile--m-r-4 confidentialicon"
                                                *ngIf="engagement.isConfidential" [dmPlacement]="'top-right'"
                                                [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                [dmIcon]="'tile__icon--info confidentialkey'"
                                                ariaLabelToolTip="confidentiality information">
                                            </dm-tooltip>
                                            <span *ngIf="engagement.isPinned" class="pinned-icon"
                                                [ngClass]="{'pin-align':!engagement.delegationType}">
                                                <i title="Pinned engagement" class="icon icon-Unpin icon-delegated"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <ng-template #delegationTooltipEngagement>
                                    <div class="color-black">
                                        <a href
                                            class="font-caption-alt dm-link">{{engagement.delegationData.delegationType
                                            + engagement.delegationData.delegationFullName}}</a>
                                    </div>
                                    <div class="color-black">
                                        <span>
                                            From:
                                        </span>
                                        <span
                                            [textContent]="engagement.delegationDetails.delegationStartDate | dmDisplayDateOrDashPipe"
                                            [attr.aria-label]="engagement.engagementStartDate | date : 'dd MMMM yyyy'"></span>
                                        <span> to </span>
                                        <span
                                            [textContent]="engagement.delegationDetails.delegationEndDate | dmDisplayDateOrDashPipe"
                                            [attr.aria-label]="engagement.engagementEndDate | date : 'dd MMMM yyyy'"></span>
                                    </div>
                                </ng-template>

                                <div *ngFor="let engagementProjectComboObject of engagement.projects; trackBy: trackEnagagementProjectComboById"
                                    class="dm-accordion-panel clearfix">
                                    <div class="dm_project_eng" *ngIf="engagement.isMenuOpen">
                                        <div *ngFor="let inlineEngagementObject of engagementProjectComboObject.eng; trackBy: trackEntityById"
                                            class="dd-submenu"
                                            [ngClass]="{'selected-state':(inlineEngagementObject.id === selectedState && placeholder !== 'My Portfolio')}">
                                            <div class="dd-submenu-panel">
                                                <div class="dd-submenu-panel--margin">
                                                    <a (keydown)="closeDropDownOnTab($event)"
                                                        aria-label="Engagement Summary link"
                                                        (click)="setValue(inlineEngagementObject); myDrop.close()"
                                                        [ngClass]="{'nxItem': engagement.isMenuOpen}"
                                                        class="font-caption-alt dm-tile--active-link" tabindex="0"
                                                        [uiSref]="RouteName.EngagementSummaryV2"
                                                        [uiParams]="{engagementId: inlineEngagementObject.id, eventName: LogEventName.NavigationFromGlobalNav}">
                                                        <span class="p-l-4">Engagement Summary</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dm_project_proj" *ngIf="engagement.isMenuOpen">
                                        <div *ngFor="let inlineProjectObject of engagementProjectComboObject.project; trackBy: trackEntityById; let lastProject = last"
                                            [ngClass]="{'selected-state':(inlineProjectObject.id === selectedState && placeholder !== 'My Portfolio')}"
                                            class="dd-submenu">
                                            <div class="dd-submenu--border">
                                                <div class="dd-submenu__wrapper">
                                                    <div class="dd-submenu-panel">
                                                        <a (keydown)="closeDropDownOnTab($event)"
                                                            (keydown.tab)="isLastEngagement && lastProject !== 'false'? myDrop.close() : null"
                                                            [attr.aria-label]="inlineProjectObject.name + 'link'"
                                                            (click)="setValue(inlineProjectObject); myDrop.close()"
                                                            [uiSref]="RouteName.ProjectSummaryV2"
                                                            [uiParams]="{projectId: inlineProjectObject.id, eventName: LogEventName.NavigationFromGlobalNav}"
                                                            [ngClass]="{'nxItem': engagement.isMenuOpen}"
                                                            class="font-caption-alt dm-tile--active-link" tabindex="0">
                                                            <span class="dd-submenu-panel--text-bind"
                                                                [innerHTML]="inlineProjectObject.name | highlight : highlightText"></span>
                                                        </a>
                                                        <span tabindex="-1"
                                                            class="font-caption-alt d-block text-opacity dd-submenu-panel--text-color"
                                                            [innerHTML]="inlineProjectObject.id | highlight : highlightText"></span>
                                                    </div>
                                                    <div class="spl-icon-wrapper">
                                                        <div>
                                                            <span *ngIf="inlineProjectObject.delegationData"
                                                                [title]="inlineProjectObject.delegationData.delegationType + inlineProjectObject.delegationData.delegationFullName">
                                                                <dm-tooltip role="tooltip"
                                                                    class="dm-tooltipinline delegated"
                                                                    [dmPlacement]="'left'"
                                                                    [ngbTooltipContent]="delegationTooltipProject"
                                                                    [dmIcon]="'delegated_icon'"
                                                                    ariaLabelToolTip="delegation Information">
                                                                </dm-tooltip>
                                                                <span class="inward"
                                                                    *ngIf="inlineProjectObject.delegationData.delegationType === delegationTypeText.DelegationInward">
                                                                    <i
                                                                        class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                                </span>
                                                                <span class="outward"
                                                                    *ngIf="inlineProjectObject.delegationData.delegationType === delegationTypeText.DelegationOutward">
                                                                    <i
                                                                        class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec"
                                                                *ngIf="!inlineProjectObject.isUsPubsec && isPubSecEnabled && inlineProjectObject.isPublicSector"
                                                                [dmPlacement]="'bottom'" [ngbTooltipContent]="'Pub Sec'"
                                                                [dmIcon]="'pubsec_icon'"
                                                                ariaLabelToolTip="pub sec Information">
                                                            </dm-tooltip>
                                                            <span *ngIf="inlineProjectObject.isUsPubSec"
                                                                class="uspubsec"></span>
                                                            <dm-tooltip role="tooltip"
                                                                class="dm-tooltipinline dm-tile--m-r-4"
                                                                *ngIf="inlineProjectObject?.isMarkedForDeletion"
                                                                [dmPlacement]="'top-right'"
                                                                [ngbTooltipContent]="'This Project has been marked for deletion in CompassOne'"
                                                                [dmIcon]="'icon-full icon-page-remove icon-marked-for-deletion dm-m-r-4 set-focus'"
                                                                ariaLabelToolTip="delete information">
                                                            </dm-tooltip>
                                                            <dm-tooltip role="tooltip"
                                                                class="dm-tooltipinline dm-tile--m-r-4 confidentialicon"
                                                                *ngIf="inlineProjectObject.isConfidentialEntity"
                                                                [dmPlacement]="'top-right'"
                                                                [ngbTooltipContent]="'This project is marked as confidential.'"
                                                                [dmIcon]="'tile__icon--info mr-r-8 confidentialkey'"
                                                                ariaLabelToolTip="confidentiality information">
                                                            </dm-tooltip>
                                                            <span class="pinned-icon"
                                                                *ngIf="inlineProjectObject.isPinned">
                                                                <i title="Pinned project"
                                                                    class="icon icon-Unpin icon-delegated"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-template #delegationTooltipProject>
                                                <div class="color-black">
                                                    <a href
                                                        class="font-caption-alt">{{inlineProjectObject.delegationData.delegationType
                                                        + inlineProjectObject.delegationData.delegationFullName}}</a>
                                                </div>
                                                <div class="color-black">
                                                    <span>From:</span>
                                                    <span
                                                        [textContent]="inlineProjectObject.delegationDetails.delegationStartDate | dmDisplayDateOrDashPipe"
                                                        [attr.aria-label]="inlineProjectObject.engagementStartDate | date : 'dd MMMM yyyy'"></span>
                                                    <span> to </span>
                                                    <span
                                                        [textContent]="inlineProjectObject.delegationDetails.delegationEndDate | dmDisplayDateOrDashPipe"
                                                        [attr.aria-label]="inlineProjectObject.engagementEndDate | date : 'dd MMMM yyyy'"></span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- For InternalEngagement-->
                            <div *ngIf="engagement.type !== 'engagement'" class="dm_project_proj">
                                <div class="dd-submenu"
                                    [ngClass]="{'selected-state':(engagement.engagementId === selectedState && placeholder !== 'My Portfolio')}">
                                    <div class="dd-submenu__wrapper internal secondary-dd-title">
                                        <div class="pd-6-4 pull-left internal--link w-70">
                                            <span class="font-caption-alt pull-left f-w-500 pd-t-2">Internal: </span>
                                            <a (keydown)="closeDropDownOnTab($event)"
                                                (keydown.tab)="isLastEngagement ? myDrop.close() : null"
                                                [uiSref]="RouteName.InternalEngagementSummary"
                                                [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.NavigationFromGlobalNav}"
                                                (click)="setValue(engagement); myDrop.close()"
                                                class="nxItem font-caption-alt dm-link" tabindex="0"
                                                [attr.aria-label]="'Internal '+ engagement.name + 'link'">
                                                <span aria-hidden="true" class="dd-submenu-panel--text-bind">
                                                    {{engagement.name}}</span>
                                            </a>
                                            <span role="none"
                                                class="font-caption-alt d-block text-opacity dd-submenu-panel--text-color"
                                                [innerHTML]="engagement.engagementId | highlight : highlightText"></span>
                                            <span class="sr-only">Press tab to navigate to link</span>
                                        </div>
                                        <div class="spl-icon-wrapper">
                                            <span>
                                                <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tile--m-r-4"
                                                    *ngIf="engagementDetails && engagement.hasAssociatedEngagements"
                                                    [dmPlacement]="'left'"
                                                    [ngbTooltipContent]="'This Engagement has associated customer engagements'"
                                                    [dmIcon]="'tile__icon--info icon icon-link link-posig'"
                                                    ariaLabelToolTip="Associated Engagements Information">
                                                </dm-tooltip>
                                                <span *ngIf="engagement.isPinned">
                                                    <i class="icon icon-pin"></i>
                                                    <span class="sr-only">Engagement {{engagement.name}} is
                                                        pinned.</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end of Internal Engagement-->
                        </div>
                    </section>
                </div>
            </ng-container>
        </div>
    </div>
</section>