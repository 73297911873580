import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../../../../../components/entry/common-components.module";
import { EngagementTecoPrevalidation } from "./engagement-teco-prevalidation.component";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmSliderModule } from "../../../../../components/tiles/dm-slider/dm-slider.module";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [
        EngagementTecoPrevalidation
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        DmSliderModule
    ],
    providers: [
        DMLoggerService,
    ],
    exports: [
        EngagementTecoPrevalidation
    ],
    entryComponents: [
        EngagementTecoPrevalidation
    ]
})
export class EngagementTecoPrevalidationModule { }