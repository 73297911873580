<button type="button" aria-label="Close" class="dm-filter--close" (click)="closeModal()">
    <span class="icon icon-close"></span>
</button>
<div class="modal-header p-b-0">
    <h2 class="font-subtitle">Edit filters</h2>
</div>
<form name="manageEbsFilter" #manageEbsFilter="ngForm">
    <div class="modal-body dm-filter--body">
        <section class="col-sm-12 pad0">
            <section class="section__wrapper">
                <label class="input__label">
                    <label class="input__label--text" for="dm-gr-projects-ddl"
                        [ngClass]="{'disabled-text' : isProjectContext}">Projects</label>
                    <label class="input__select--icon" [ngClass]="{'input--icon-disabled':isProjectContext}">
                        <select id="dm-grm-projects-ddl" class="input__select width-align" [(ngModel)]="selectedProject"
                            [disabled]="isProjectContext" [ngClass]="{'disabled-class': isProjectContext}">
                            <option [value]="placeholder">All</option>
                            <option [ngValue]="project" *ngFor="let project of items">{{project.displayName}}</option>
                        </select>
                    </label>
                </label>
            </section>
            <section class="section__wrapper">
                <label class="input__label">
                    <label class="input__label--text" for="dm-gr-services-ddl"
                        [ngClass]="{'disabled-text' : !selectedProject}">Service</label>
                    <label class="input__select--icon" [ngClass]="{'input--icon-disabled':!selectedProject}">
                        <select id="dm-grm-services-ddl" class="input__select width-align" [(ngModel)]="selectedService"
                            [ngClass]="{'disabled-class': !selectedProject}" [disabled]="!selectedProject">
                            <option [value]="selectedProject">All</option>
                            <option [ngValue]="service" *ngFor="let service of selectedProject.children">
                                {{service.displayName}}</option>
                        </select>
                    </label>
                </label>
            </section>
            <section class="section__wrapper">
                <label class="input__label">
                    <label class="input__label--text" for="dm-gr-tasks-ddl"
                        [ngClass]="{'disabled-text' : !selectedService}">Task</label>
                    <label class="input__select--icon" [ngClass]="{'input--icon-disabled':!selectedService}">
                        <select id="dm-grm-tasks-ddl" class="input__select width-align" [(ngModel)]="selectedTask"
                            [ngClass]="{'disabled-class': !selectedService}" [disabled]="!selectedService">
                            <option [value]="selectedService" [selected]="selectedTask">All</option>
                            <option [ngValue]="task" *ngFor="let task of selectedService.children">{{task.displayName}}
                            </option>
                        </select>
                    </label>
                </label>
            </section>
        </section>
    </div>
    <div class="modal-footer dm-filter-footer">
        <button class="fxp-btn fxp-btn--primary" (click)="applyFilterChanges()"
            [disabled]="manageEbsFilter.invalid">Apply</button>
        <button class="fxp-btn fxp-btn--secondary" (click)="closeModal()">Cancel</button>
    </div>
</form>