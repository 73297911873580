import { Component, Inject, forwardRef } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";

import { Components, TooltipText, NoDataText, RouteName, SourceConstants, InlineSurveyDataList, FeedBackEntity, LogEventConstants, AccessibilityConstants } from "../../../../common/application.constants";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { IProjectDetailsViewModel } from "../../../project-detail/project.model";
import { IState } from "../../../../store/reducers";
import { ProjectDetailService } from "../../../../common/services/project-detail.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { IProjectDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../common/error.constants";
import { FeedbackModalService } from "../../../../components/tiles/feedback-modal/feedback-modal.service";

@Component({
    selector: "dm-project-summary-service-v2",
    templateUrl: "./project-summary-service-v2.html",
    styleUrls: ["./project-summary-service-v2.scss"]
})
export class ProjectSummaryServiceV2Component extends DmComponentAbstract {
    public projectDetails: IProjectDetailsViewModel;
    public noServiceFoundText: string;
    public wbsTooltipText: string = TooltipText.EBSState;
    public isMobileView: boolean;
    public isDesktopView: boolean;
    public isTabletView: boolean;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.Services;
    public isSummaryV2: boolean = true;
    public isProjectContext: boolean = false;
    public RouteName = RouteName;
    public accessibilityConstants = AccessibilityConstants;
    
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(ProjectDetailService) private projectDetailService: ProjectDetailService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
    ) {
        super(dmLogger, Components.ProjectSummaryServices);
    }

    public ngOnInit(): void {
        this.isMobileView = this.deviceFactory.isMobile();
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isTabletView = this.deviceFactory.isTablet();
        this.noServiceFoundText = NoDataText.NoServices;
        this.tileContent = {
            title: "Services",
            itemsCount: 0
        };

        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);

        this.storeDispatchService.requireProjectDetails(projectId, true).load();
        this.store.select(getEntireProjectDetails(projectId)).subscribe((projectDetailsState: IProjectDetailsState) => {
            this.refreshOnItemInvalidation(projectDetailsState);
            this.setLoadersBasedOnItemState(projectDetailsState);
            this.setErrorsBasedOnItemState(projectDetailsState);
            if (projectDetailsState.loaded) {
                const projectFullDetails: IProjectDetailsV2 = projectDetailsState.projectDetails.projectFullDetails;
                this.projectDetails = this.projectDetailService.getProjectDetails(projectFullDetails);
                this.tileContent.itemsCount = this.projectDetails && this.projectDetails.services && this.projectDetails.services.length;
            }
            if (projectDetailsState.error) {
                this.isServerError = true;
            }
        });
    }

    /**
     * Opens summary v2 survey modal
     */
    public openSummaryV2FeedbackModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ProjectSummaryV2Page, SourceConstants.Method.OpenSummaryV2FeedbackModal, LogEventConstants.SummaryV2FeedbackClick);
        this.feedbackModalService.openFeedbackModal(InlineSurveyDataList.SummaryV2Survey, FeedBackEntity.SummaryV2, SourceConstants.Component.ProjectSummaryV2Page);
    }
}