<section class="col-md-12 col-sm-12 col-xs-12 tile--no-pad" *ngIf="!deviceFactory.isMobile()">
    <section class="col-sm-12 tile--no-pad" [ngClass]="deviceFactory.isDesktop() ? 'col-md-10 ' : 'col-md-12'">
        <section class="col-md-4 col-sm-4 section__wrapper">
            <section class="input__label">
                <label class="input__label--text font-caption-alt" for="dm-wbs-projects-ddl"
                    [ngClass]="{'disabled-text': isProjectContext}"><b>Project</b></label>
                <section class="input__select--icon" [ngClass]="{'input--icon-disabled':isProjectContext}">
                    <dm-dropdown-menu [id]="'dm-wbs-projects-ddl-v1'" [ariaLabel]="'Projects'"
                        [(value)]="selectedProject" [options]="items" [labels]="'displayName'"
                        [isDisabled]="isProjectContext" (change)="onSelectedProjectChanged(selectedProject)">
                        <option [value]="placeholder">All projects</option>
                    </dm-dropdown-menu>
                </section>
            </section>
        </section>
        <section class="col-md-4 col-sm-4 section__wrapper">
            <section class="input__label">
                <label class="input__label--text font-caption-alt" for="dm-wbs-services-ddl"
                    [ngClass]="{'disabled-text': isProjectContext ? !isProjectContext : isDisabled(selectedProject)}"><b>Service</b></label>
                <section class="input__select--icon"
                    [ngClass]="{'input--icon-disabled':isProjectContext ? !isProjectContext :  isDisabled(selectedProject)}">
                    <dm-dropdown-menu [id]="'dm-wbs-services-ddl-v1'" [ariaLabel]="'Services'"
                        [(value)]="selectedService" [options]="selectedProject.children" [labels]="'displayName'"
                        [isDisabled]="isProjectContext ? !isProjectContext : isDisabled(selectedProject)"
                        (change)="onSelectedServiceChanged(selectedService)">
                        <option [value]="placeholder">All</option>
                    </dm-dropdown-menu>
                </section>
            </section>
        </section>
        <section class="col-md-4 col-sm-4 section__wrapper">
            <section class="input__label">
                <label class="input__label--text font-caption-alt" for="dm-wbs-tasks-ddl"
                    [ngClass]="{'disabled-text': isDisabled(selectedService)}"><b>Task</b></label>
                <section class="input__select--icon" [ngClass]="{'input--icon-disabled':isDisabled(selectedService)}">
                    <dm-dropdown-menu [id]="'dm-wbs-tasks-ddl'" [ariaLabel]="'Tasks'" [(value)]="selectedTask"
                        [options]="selectedService.children" [labels]="'displayName'"
                        [isDisabled]="isDisabled(selectedService)" (change)="onSelectedTaskChange(selectedTask)">
                        <option [value]="placeholder">All</option>
                    </dm-dropdown-menu>
                </section>
            </section>
        </section>
    </section>
</section>
<section class="col-sm-12 col-xs-12 dm-filters" *ngIf="deviceFactory.isMobile()" filters-scroll
    fab-filter="#dmebs_filter" fab-button="#wbs-fabButton">
    <section class="tile-header dm-filters--p-t-10 dm-filters--pad-r-l-0-mobile">
        <section class="dm-filters--w--76 dm-filters--pad0-mobile pull-left">
            <div class="current-view font-label">Currently viewing</div>
            <div class="ddl-text dm-filters--m-b-12 font-label">
                <div [innerHTML]="selectedProject.displayName ? selectedProject.displayName : 'All Projects'"></div>
                <div [innerHTML]="selectedService.displayName ? selectedService.displayName : 'All Services'"></div>
                <div [innerHTML]="selectedTask.displayName ? selectedTask.displayName : 'All Tasks'"></div>
            </div>
        </section>
        <section class="dm-filters--w-76 pull-right">
            <div>
                <button class="edit-filter font-label" id="dmebs_filter" (click)="openEditFiltersModal()">
                    <span class="icon icon-filter"></span>
                    Edit Filters
                </button>
            </div>
        </section>
    </section>
    <button id="wbs-fabButton" class="fabButton" aria-label="edit filters" (click)="openEditFiltersModal()">
        <div class="innerdiv">
            <span class="icon icon-filter"></span>
        </div>
    </button>
</section>