<section class="col-sm-10 col-md-10 col-xs-10 text-center dm-p-t-10 release-activate-modal-section">
    <div *ngIf="isOrchestrationTriggered">
        <ng-container>
            <h4 class="font-title">
                This Engagement has been submitted for Activation.
            </h4>
        </ng-container>
    </div>
    <div *ngIf="!isOrchestrationTriggered">
        <h4 class="font-title"
            *ngIf="!haveCurrentBaselineDetails && isUserEngagementPjm && !showSentForActivationMessage && !isProjectContext">
            This Engagement is not yet Released and Activated.
        </h4>
        <h4 class="font-title"
            *ngIf="isUserEngagementPjm && showSentForActivationMessage && !(canEditFinancialsOfEntity && showInitiateForecastLink && !isProjectContext)">
            This Engagement has been submitted for Activation.
        </h4>
        <ng-container *ngIf="canEditFinancialsOfEntity && showInitiateForecastLink && !isProjectContext">
            <h4 class="font-title">
                This Engagement has been Activated!
            </h4>
            <p class="font-caption-alt">
                This Engagement has been Released and Activated. This Engagement is now ready for you to initiate the
                forecast.
            </p>
        </ng-container>
        <ng-container *ngIf="canEditFinancialsOfEntity && showInitiateForecastLink && isProjectContext">
            <h4 class="font-title">
                This Project has been Activated!
            </h4>
            <p class="font-caption-alt">
                Corresponding Engagement is now ready for you to initiate the forecast.
            </p>
        </ng-container>
        <p class="font-caption-alt" *ngIf="!haveCurrentBaselineDetails && isUserEngagementPjm && !isProjectContext">
            Please review and complete any changes to the Delivery Baseline in the Plan & Forecast tab, then return
            here to Finalize the Delivery Baseline, Activate the Current Financial Plan, and Release the
            {{isProjectContext ? "Project" : "Engagement"}} for Staffing.
            <ng-template [ngTemplateOutlet]="learnMoreTemplate"></ng-template>
        </p>
        <ng-container *ngIf="isUserAdditionalTeammate && !showInitiateForecastLink">
            <h4 class="font-title">
                This Engagement has not been activated.
            </h4>
            <p class="font-caption-alt">
                Please contact the Primary PJM
                <dm-user-name [name]="primaryPjmName" [alias]="primaryPjmAlias"></dm-user-name>to Release and Activate this Engagement.
                <ng-template [ngTemplateOutlet]="learnMoreTemplate"></ng-template>
            </p>
        </ng-container>
        <ng-container *ngIf="isUserAdditionalTeammate && !showInitiateForecastLink && isProjectContext">
            <h4 class="font-title">
                This Project has not been activated.
            </h4>
            <p class="font-caption-alt">
                Please contact the Primary PJM
                <dm-user-name [name]="primaryPjmName" [alias]="primaryPjmAlias"></dm-user-name>
                the corresponding Engagement to which this Project belongs.
                <ng-template [ngTemplateOutlet]="learnMoreTemplate"></ng-template>
            </p>
        </ng-container>
        <ng-container *ngIf="isUserEngagementPjm && isProjectContext && !showInitiateForecastLink">
            <h4 class="font-title">
                This Project has not been activated.
            </h4>
            <p class="font-caption-alt">
                Please Release and Activate the corresponding Engagement to which this
                Project belongs.
                <ng-template [ngTemplateOutlet]="learnMoreTemplate"></ng-template>
            </p>
        </ng-container>
        <ng-container
            *ngIf="!isUserEngagementPjm && !isUserAdditionalTeammate && isProjectContext && !showInitiateForecastLink">
            <h4 class="font-title">
                This Project has not been activated.
            </h4>
            <p class="font-caption-alt">
                Please contact the Primary PJM
                <dm-user-name [name]="primaryPjmName" [alias]="primaryPjmAlias"></dm-user-name>to Release and Activate
                the corresponding Engagement to which this Project belongs.
                <ng-template [ngTemplateOutlet]="learnMoreTemplate"></ng-template>
            </p>
        </ng-container>
        <ng-container
            *ngIf="!isUserEngagementPjm && !isUserAdditionalTeammate && !showInitiateForecastLink">
            <h4 class="font-title">
                This Engagement has not been activated.
            </h4>
            <p class="font-caption-alt">
                Please contact the Primary PJM
                <dm-user-name [name]="primaryPjmName" [alias]="primaryPjmAlias"></dm-user-name>to Release and Activate this Engagement
                <ng-template [ngTemplateOutlet]="learnMoreTemplate"></ng-template>
            </p>
        </ng-container>
        <ng-container *ngIf="!isProjectContext && !haveCurrentBaselineDetails && isUserEngagementPjm">
            <p class="font-caption-alt dm-tile--m-b-12">
                <a [uiSref]="RouteName.EngagementPlanForecast" class="dm-link">
                    Go to Plan & Forecast
                </a>
            </p>
            <p class="font-caption-alt" *ngIf="!showSentForActivationMessage">
                <button class="fxp-btn fxp-btn--primary" (click)="activateFinancialPlan()">
                    <span class="icon icon-CheckMark"></span>
                    Release & Activate
                </button>
            </p>
        </ng-container>
        <p class="font-caption-alt" *ngIf="canEditFinancialsOfEntity && showInitiateForecastLink">
            <button class="fxp-btn fxp-btn--primary" (click)="navigateToForecast()">
                <span class="icon icon-ImportAllMirrored"></span>
                Initiate Forecast
            </button>
        </p>
        <ng-container *ngIf="!canEditFinancialsOfEntity && showInitiateForecastLink">
            <h4 class="font-title">
                This Engagement has been Activated!
            </h4>
            <p class="font-caption-alt">
                Please contact the Primary PJM 
                <dm-user-name [name]="primaryPjmName" [alias]="primaryPjmAlias"></dm-user-name>to
                Initiate
                the Forecast.
            </p>
        </ng-container>
    </div>

    <ng-template #learnMoreTemplate>
        <a href="https://aka.ms/pjm-job-aid/release-activate" target="_blank" role="link"
            title="Learn more about Release and Activate" class="dm-link" aria-label="open link in new tab">
            <span aria-hidden="true"></span>
            Learn more
        </a>
    </ng-template>

</section>