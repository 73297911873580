import { Component, Input, Inject, forwardRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components, LogEventConstants, SourceConstants, NotificationEvent } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { DMNotificationService, NotificationModel } from "../../../../common/services/dmnotification.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { INotificationMessages, ISuccessMessages, IOnSuccess, IFailureMessages, IOnFailure } from "../../../../common/services/contracts/financial.service.contracts";
import { UserInfoService, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";
import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { DmError } from "../../../../common/error.constants";


/**
 * This component is responsible for initiating a new CSAT contact request when the desired contact is not available.
 * This is different from adding an existing contact as a CSAT contact to an engagement. (Done in update-csat-contact.)
 *
 * @export
 * @class CreateCSATContactModalComponent
 * @extends {DmModalAbstract}
 */
@Component({
    selector: "create-csat-contact",
    templateUrl: "./create-csat-contact.html",
    styleUrls: ["./create-csat-contact.scss"]
})
export class CreateCSATContactModalComponent extends DmModalAbstract {
    @Input() public engagementId: string;
    public createCSATContactForm: FormGroup;
    public dmCsatContactErrorMessages = DmError.CreateCsatContact;

    /**
     * Form group value getters
     *
     * @readonly
     * @type {AbstractControl}
     * @memberof CreateCSATContactModalComponent
     */
    public get contactFirstName(): AbstractControl {
        return this.createCSATContactForm.get("contactFirstName");
    }
    public get contactLastName(): AbstractControl {
        return this.createCSATContactForm.get("contactLastName");
    }
    public get contactEmail(): AbstractControl {
        return this.createCSATContactForm.get("contactEmail");
    }
    public get phoneNumber(): AbstractControl {
        return this.createCSATContactForm.get("phoneNumber");
    }
    public get phoneExtension(): AbstractControl {
        return this.createCSATContactForm.get("phoneExtension");
    }

    private contactFormResponse: any;
    private notificationMessage: INotificationMessages;
    private createCsatContactSendTo: string[];
    private FXP_CONSTANTS = FxpConstants;
    private notificationSuccessMessages: IOnSuccess;
    private notificationFailureMessages: IOnFailure;

    public constructor(
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(Store) private store: Store<IState>,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
    ) {
        super(activeModal, dmLogger, Components.CreateCSATContactModal);
    }

    public ngOnInit(): void {
        this.configurationService.initialize().then(() => {
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
            this.createCsatContactSendTo = this.configurationService.getValue<string[]>("createCsatContactSendTo");
            this.notificationSuccessMessages = this.configurationService.getValue<ISuccessMessages>("SuccessMessages").CsatContact;
            this.notificationFailureMessages = this.configurationService.getValue<IFailureMessages>("FailureMessages").CsatContact;
        });
        this.sharedFunctionsService.focus("closeCreateButton", true);
        this.initializeContactForm();
    }

    /**
     * Gets contact form data values
     *
     * @memberof CreateCSATContactModalComponent
     */
    public getContactFormData(): void {
        if (this.createCSATContactForm.valid) {
            this.contactFormResponse = {
                firstName: this.contactFirstName.value,
                lastName: this.contactLastName.value,
                email: this.contactEmail.value,
                phoneNumber: this.phoneNumber.value,
                phoneExtension: this.phoneExtension.value,
            };

            this.sendCsatNotification();
        }
    }

    /**
     * Initializes contact form data
     *
     * @private
     * @memberof CreateCSATContactModalComponent
     */
    private initializeContactForm(): void {
        this.createCSATContactForm = this.fb.group({
            contactFirstName: ["", Validators.required],
            contactLastName: ["", Validators.required],
            contactEmail: ["", [Validators.required, Validators.email]],
            phoneNumber: ["", [Validators.pattern("^[0-9.+()\\- ]*$"), Validators.maxLength]],
            phoneExtension: ["", [Validators.pattern("^[0-9.+()\\- ]*$")]]
        });
    }

    /**
     *  creates the change properties and triggers the method to create telemetry log and send email notifications to the
     *  relevant people.
     */
    private sendCsatNotification(): void {
        const changedProperties: IChangedProperties[] = [];
        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "First Name",
                "",
                this.contactFormResponse.firstName
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "Last Name",
                "",
                this.contactFormResponse.lastName
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "Email",
                "",
                this.contactFormResponse.email
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "Phone",
                "",
                this.contactFormResponse.phoneNumber
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "Extension",
                "",
                this.contactFormResponse.phoneExtension
            )
        );

        this.createLogAndSendNotification(changedProperties);
        this.closeModal();
    }

    /**
     *  Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     *  relevant people.
     * @private
     * @param {IChangedProperties[]} changedProperties
     * @memberof CreateCSATContactModalComponent
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.CreateContactModal, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.SubmittedCreateCSATContactForm, propertyBag);

        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
            if (engagementDetails.loaded) {
                const esxpNotification = this.notificationMessage.CsatContactNotification;

                const notification = new NotificationModel();
                notification.engagementId = this.engagementId;
                notification.engagementName = engagementDetails.engagementDetails.name;
                notification.attributes = {
                    FirstName: this.contactFormResponse.firstName,
                    LastName: this.contactFormResponse.lastName,
                    Email: this.contactFormResponse.email,
                    CustomerName: engagementDetails.engagementDetails.customerName,
                    CustomerId: engagementDetails.engagementDetails.customerId,
                    PhoneNo: this.contactFormResponse.phoneNumber
                };
                notification.eventName = NotificationEvent.AddNewCSATContact;
                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(this.createCsatContactSendTo);
                /* Add requester to the CC list, story #8111081  */
                const cc: string[] = this.sharedFunctionsService.getListofPjmV2(engagementDetails.engagementDetails).concat(userAlias);
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(cc);
                notification.modifiedBy = userAlias;
                notification.modifiedDate = new Date();
                this.notificationService.sendNotification(notification, false, esxpNotification);
                this.fxpMessageService.addMessage(this.notificationSuccessMessages.OnSuccess, this.FXP_CONSTANTS.messageType.success);
            } else {
                this.fxpMessageService.addMessage(this.notificationFailureMessages.OnFailure, this.FXP_CONSTANTS.messageType.error);
            }
        });
    }

    /**
     * Pushes the given value to the given array if the value evaluates as truthy.
     * (If the value is not null or undefined, false, or the number 0.)
     * Modifies the given array by reference, and does not need to return.
     */
    private pushToArrayIfTrue(array: IChangedProperties[], value: IChangedProperties): void {
        if (value) {
            array.push(value);
        }
    }

    /**
     * If the given objects are different, creates and returns a changed property object.
     * Returns undefined if the objects have not changed.
     * @param attributeName
     * @param originalValue
     * @param updatedValue
     */
    private createChangedPropertyObject(attributeName: string, originalValue: string, updatedValue: string): IChangedProperties {
        if (originalValue !== updatedValue) {
            return {
                name: attributeName,
                oldValue: originalValue,
                newValue: updatedValue
            };
        }
        return undefined;
    }

    /**
     * Creates a log string from the given changed properties: combines all the changed attribute names into a single, comma seperated string.
     * @param changedProperties
     */
    private createLogStringFromChangedProperties(changedProperties: IChangedProperties[]): string {
        let logString: string = "";
        changedProperties.forEach((property, index) => {
            logString += property.name;
            if (index !== changedProperties.length - 1) {
                logString += ",";
            }
        });
        return logString;
    }

}
