export interface ICreateInternalEngContractDetails {
    engagementName: string;
    engagementDesc: string;
    requestType: string;
    opportunityId: string;
    primaryDomain: string;
    engStartDate: string; // This is a string instead of a date in order to control timezones and ignore minutes for API
    engEndDate: string; // This is a string instead of a date in order to control timezones and ignore minutes for API
    pjmBpid: string;
    costCenter?: string;
    documentName?: string; // todo look at making this mandatory
    totalHours?: number;
    customerId?: string;
    customerName?: string;
    customerCountry?: string;
    customerState?: string;
    customerCity?: string;
    companyCode?: string;
    isConfidential?: boolean;
    /// New fields for SROut
    costObject?: string;
    costObjectType?: string;
    chargeType?: string;
    chargeTypeValue?: string;
    tpid?: string;
}

export interface ICreateInternalEngRoleData {
    role: string;
    startDate: string; // This is a string instead of a date in order to control timezones and ignore minutes for API
    endDate: string; // This is a string instead of a date in order to control timezones and ignore minutes for API
    requestedHours: number;
    isBillable: boolean;
}

export interface ICreateInternalEngagementObject {
    contractDetails: ICreateInternalEngContractDetails;
    roleData: ICreateInternalEngRoleData[];
    additionalPjmList: string[];
}

export interface ICreateInternalEngagementOutput {
    contractData: ICreateInternalEngagementContractDataOutput;
    roleData?: ICreateInternalEngagementRoleDataOutput[];
}

export interface ICreateInternalEngagementRoleDataOutput {
    grmDemandId: string;
    role: string;
    startDate: string; // This is a string instead of a date in order to control timezones and ignore minutes for API
    endDate: string; // This is a string instead of a date in order to control timezones and ignore minutes for API
    requestedHours: number;
    isRoleUsed?: boolean;
}

export interface ICreateInternalEngagementContractDataOutput {
    engagementId: string;
    engagementName: string;
    projectId: string;
    projectDesc: string;
    serviceId: string;
    serviceName: string;
    taskId: string;
    taskName: string;
    geoCode: string;
    companyCode: string;
}

export interface ICostCenterValidationOutput {
    costObjectId: string;
    costObjectDesc: string;
    companyCode: string;
    companyDescription: string;
    countryCode: string;
    countryDescription: string;
}

export interface IInternalEngagementDomainTypes {
    domainValueName: string;
    mddDomainValueId: string;
}


export interface IInternalEngagementChargeType {
    chargeType?: string;
}

export enum InternalEngagementType {
    demandGeneric = "Demand Gen-Generic",
    sharedResource = "Shared Resource",
    msInternal = "MS-Internal",
    unitBased = "Unit-Based",
    demandAccount = "Demand Gen-Account",
    srOutCorpHQ = "SR Out Corp HQ",
    srOutOtherBG = "SR Out Other BG",
    srOutFedConsulting = "SR Out US Fed - Consulting",
    srOutFedSupport = "SR Out US Fed - Support",
    ecifPreSales = "Presales ECIF",
    preSalesAccount = "Presales Account",
    preSalesGeneric = "Presales Generic"
}

export enum InternalEngagementTypeCodes {
    demandGeneric = 93,
    msInternal = 96,
    unitBased = 97,
    demandAccount = 94,
    srOutCorpHQ = 95,
    srOutOtherBG = 98
}

export interface IInternalEngagementTeamInput {
    bpid: string;
    role: string;
}


export interface INewInternalEngagementInput {
    name: string;
    description: string;
    primaryDomain: string;
    customerName: string;
    customerId: string;
    requestType: number;
    startDate: string;
    endDate: string;
    teams: IInternalEngagementTeamInput[];
    companyCode: number;
    topParentId: string;
    isConfidential: boolean;
    customerCountry: string;
    customerState: string;
    customerCity: string;
    projects: INewInternalEngagementProjectInput[];
    createdGroup: string;
}

export interface INewInternalEngagementProjectInput {
    id: number;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    domain: string;
    nbueApprovedHours: string;
    teams: IInternalEngagementTeamInput[];
    roles: ICreateInternalEngRoleData[];
    documentName: string;
    costObject: string;
    costObjectType: string;
    chargeType: string;
    chargeTypeValue: string;
    chargeAccountCode: string;
}

export interface INewCreateInternalEngagementOutput {
    id: string;
    name: string;
    geoCode: string;
    companyCode: string;
    projects: INewCreateInternalEngagementProjectOutput[];
}

export interface INewCreateInternalEngagementProjectOutput extends INewInternalWbsOutput {
    temporaryId: string;
    services: INewCreateInternalEngagementServiceOutput[];
}

export interface INewCreateInternalEngagementServiceOutput extends INewInternalWbsOutput {
    tasks: INewCreateInternalEngagementTaskOutput[];
}

export interface INewCreateInternalEngagementTaskOutput extends INewInternalWbsOutput {
    roles: ICreateInternalEngagementRoleDataOutput[];
}

export interface INewInternalWbsOutput {
    id: string;
    name: string;
}

export interface IInternalEngagementApprovalDetails {
    id: number;
    approvalId: number;
    engagementId: string;
    engagementType: string;
    nbueHours: number;
    primarySolutionArea: string;
    investmentCategory: string;
    requestor: string;
    approver: string;
    isUsed: boolean;
    createdDate: string;
    updatedDate: string;
}

export interface IInternalEngagementApprovalRequest {
    engagementId: string;
    isUsed: boolean;
}

export interface IInternalEngagementSearchApprovalRequest {
    approvalId: string;
}