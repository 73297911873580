<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'330px'" [tileHeight]="'100%'" [isV2Tile]="true">
  <a additionalActionsRight [uiSref]="RouteName.ProjectFinancials" (click)="logEvent()"
    title="Navigate to Project Financials" class="dm-link" *ngIf="!isComponentLoading" role="link">
    Go to Financials
  </a>
  <dm-loading loader *ngIf="isComponentLoading && !isServerError" [loadingText]="'Financials Details'"
    [showLoading]="showLoading"></dm-loading>
  <div *ngIf="!isComponentLoading && !isServerError && financialsData">
    <div *ngIf="isBaseLineActive" class="col-md-4 col-sm-4 col-lg-4">
      <div class="dm-detail__label">Cost Consumed of Estimate at Complete (EAC)</div>
      <div class="dm-m-y-20">        
        <dm-cceac-v2 [values]="cceacValues" [isInternalEngagement]="false"></dm-cceac-v2>
      </div>
    </div>
    <div [ngClass]="isBaseLineActive ? 'col-md-8 col-sm-8 col-lg-8 dm-p-imp-0' : 'col-md-12 col-sm-12 col-lg-12 dm-m-t-imp-30'" *ngIf="financialsData.length">
      <caption class="sr-only">{{tableCaption}}</caption>
      <div role="rowgroup" class="dm-grid-view-table">
        <div role="row" class="dm-grid-view-table__row" [ngClass]="{'grid-columns-forecast-not-initiated': !isUnitBasedContract && !isBaseLineActive, 'grid-columns-unit-based-and-forecast-not-initiated': isUnitBasedContract && !isBaseLineActive, 'grid-columns-forecast-initiated': !isUnitBasedContract && isBaseLineActive, 'grid-columns-unit-based-and-forecast-initiated': isUnitBasedContract && isBaseLineActive}">
          <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 justify-flex-end dm-p-x-12-y-8 text-right">
            Margin (%)
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 justify-flex-end dm-p-x-12-y-8 text-right">
            Cost ({{currencyType}})
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 justify-flex-end dm-p-x-12-y-8 text-right">
            Revenue ({{currencyType}})
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 justify-flex-end dm-p-x-12-y-8 text-right">
            Labor (hours)
          </div>
          <div role="columnheader" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 justify-flex-end dm-p-x-12-y-8 text-right"
            *ngIf="isUnitBasedContract">
            Units (EA)
          </div>
        </div>
      </div>
      <div role="rowgroup">
        <div class="dm-grid-view-table">
          <div class="dm-grid-view-table__body">
            <div role="row" class="dm-grid-view-table__row" [ngClass]="{'grid-columns-forecast-not-initiated': !isUnitBasedContract && !isBaseLineActive, 'grid-columns-unit-based-and-forecast-not-initiated': isUnitBasedContract && !isBaseLineActive, 'grid-columns-forecast-initiated': !isUnitBasedContract && isBaseLineActive, 'grid-columns-unit-based-and-forecast-initiated': isUnitBasedContract && isBaseLineActive}"
              *ngFor="let data of financialsData | slice: 0:4; let index = index">
              <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8 text-left">
                <p>
                  <span>{{data.title}}</span>
                  <dm-tooltip role="tooltip" *ngIf="data.title === 'Estimate at Complete'" class="dm-tooltipinline"
                    [dmPlacement]="'right'" [ngbTooltipContent]="eacText" [dmIcon]="'tile__icon--info icon icon-info'"
                    ariaLabelToolTip="Estimate at Complete Information">
                  </dm-tooltip>
                </p>
                <ng-template #eacText>
                  <p>
                    The financials shown are of last valuation date. Please <b>Valuate & Save</b>
                    for latest financials on the forecast workbook.
                  </p>
                </ng-template>
              </div>
              <div role="gridcell" class="break-word dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8 text-right">
                <p>{{(data.margin) ? data.margin + '%' : '-'}}</p>
              </div>
              <div role="gridcell" class="break-word dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8 text-right" [ngClass]="{'dm-p-r-imp-4': isBaseLineActive && (data.title === 'Delivery Baseline' || data.title === 'Current Financial Plan' || data.title === 'Contract Baseline')}">
                <p class="display-inline-block">
                  {{ data.cost | dmDisplayCurrencyOrDash: currencyType }}
                </p>
                <p class="display-inline-block diamond-narrow"
                  *ngIf="isBaseLineActive && data.title === 'Delivery Baseline'"></p>
                <p class="display-inline-block talkbubble" *ngIf="isBaseLineActive && data.title === 'Contract Baseline'">
                </p>
                <p class="display-inline-block magnifying-glass"
                  *ngIf="isBaseLineActive && data.title === 'Current Financial Plan'"></p>
              </div>
              <div role="gridcell" class="break-word dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8 text-right">
                <p>
                  {{ data.revenue | dmDisplayCurrencyOrDash: currencyType }}
                </p>
              </div>
              <div role="gridcell" class="break-word dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8 text-right">
                <p>{{data.effort | number}}</p>
              </div>
              <div role="gridcell" class="break-word dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8 text-right" *ngIf="isUnitBasedContract">
                <p>{{data.unit | number}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="col-md-12 col-sm-12 col-xs-12" *ngIf="!financialsData?.length">
      <dm-no-data [noDataText]="noFinancialText" [showImage]="true">
      </dm-no-data>
    </section>
  </div>
  <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>