
import { switchMap, catchError, map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { ProjectService } from "../../common/services/project.service";
import * as actualsActions from "./actuals.action";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class ActualsEffect {

    @Effect()
    public loadActuals$ = this.actions$
        .ofType(actualsActions.ActualsActionTypes.LOAD_ACTUALS).pipe(
            switchMap((action: actualsActions.LoadActuals) =>
                fromPromise(this.projectService.getActualsData(action.wbsId))
                    .pipe(
                        map((units: any) => new actualsActions.LoadActualsSuccess(action.wbsId, units)),
                        catchError((error) => of(new actualsActions.LoadActualsFail(action.wbsId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error)))
                        )
                    )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService
    ) { }
}