import { AfterContentChecked, Component, forwardRef, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Components, KeyAction } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsApiV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { IState } from "../../../store/reducers";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IEngagementSearchInputObject, ISearchResultsObject, IEngagementSearchResult } from "../../../common/services/contracts/project.service.contracts";

@Component({
    selector: "dm-internal-engagement-summary",
    templateUrl: "./internal-engagement-summary.html",
    styleUrls: ["./internal-engagement-summary.scss"]
})
export class InternalEngagementSummaryComponent extends DmComponentAbstract implements AfterContentChecked {
    public associatedEngagementList: IEngagementSearchResult[] = [];
    public engagementDetails: IEngagementDetailsApiV2;    
    public errorText: string;
    public contextTile: string = "InternalEngagementDetail";
    public listOfKeyActions: string[] = [];
    private haveMountedComponents: boolean = false;
    private haveCustEngMounted: boolean = false;    

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(ProjectService) private projectService: ProjectService,
    ) {
        super(dmLogger, Components.InternalEngagementSummary);
    }

    public ngOnInit(): void {
        this.configurationService.initialize()
            .then(() => {
                const engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
                const engagementDetails$ = this.store.select(getEntireEngagementDetails(engagementId));
                engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
                    if (engagementDetails.loaded) {
                        this.associatedEngagementList = [];
                        this.engagementDetails = this.setEngagementInfoAndCrossCharge(engagementDetails.engagementDetails);
                        /* Check for any associated Engagements and Load the tile */
                        const searchInput: IEngagementSearchInputObject = {
                            opportunityId: this.engagementDetails.opportunityId,
                            hasAssociatedEngagements: true
                        };
                        if (searchInput && searchInput.opportunityId && searchInput.opportunityId.length > 0) {
                            this.projectService.searchEngagements(searchInput).then((response: ISearchResultsObject) => {
                                this.associatedEngagementList = this.filterCustomerDeliveryEngagements(response);
                            });
                        }
                    }
                    this.refreshOnItemInvalidation(engagementDetails);
                    this.setLoadersBasedOnItemState(engagementDetails);
                    this.setErrorsBasedOnItemState(engagementDetails);
                });

            });
        this.listOfKeyActions.push(KeyAction.PendingExpenseReportsBasedOnEntityId, KeyAction.PendingLaborBasedOnAssignments, KeyAction.EngagementStartDateLessContractStartDate);
    }

    public ngAfterContentChecked(): void {
        if (this.engagementDetails) {
            if (!this.haveMountedComponents) {
                this.addNewChildComponent(Components.EngagementSummaryDetails);
                this.addNewChildComponent(Components.KeyActions);
                this.addNewChildComponent(Components.LaborConsumption);
                this.addNewChildComponent(Components.KeyProjectLinks);
                this.haveMountedComponents = true;
            }
            if (!this.haveCustEngMounted && this.engagementDetails.hasAssociatedEngagements) {
                this.addNewChildComponent(Components.CustomerEngagement);
                this.haveCustEngMounted = true;
            }
        }
    }

    /**
     *  Load engagement details
     * @param engFullDetails
     */
    private setEngagementInfoAndCrossCharge(engFullDetailsResponse: IEngagementDetailsApiV2): IEngagementDetailsApiV2 {
        const engFullDetails: IEngagementDetailsApiV2 = engFullDetailsResponse;
        engFullDetails.dealId = engFullDetails.dealId ? engFullDetails.dealId : "";
        return engFullDetails;
    }

    /**
     * Filters the given search response and verifies if each engagement is internal or not.
     * Creates a list of the customer engagements found in the search response.
     * @param searchResponse
     */
    private filterCustomerDeliveryEngagements(searchResponse: ISearchResultsObject): IEngagementSearchResult[] {
        const internalEngagementList: IEngagementSearchResult[] = [];
        if (searchResponse && searchResponse.engagementDetails) {
            for (const engagement of searchResponse.engagementDetails) {
                if (!this.sharedFunctionsService.isEngagementInternal(engagement)) {
                    internalEngagementList.push(engagement);
                }
            }
        }
        return internalEngagementList;
    }
}
