<div class="risk-reserve-modal-content">
    <label class="sr-only">T&M Cap Amounts</label>
    <div class="modal-header modal-noborder">
        <button type="button" class="close set-focus" aria-label="View Cap Amounts Dialog Close" id="closeCapAmounts" (click)="closeModal()">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle" aria-hidden="true">T&M Cap Amounts ({{currency}})</h4>
    </div>
    <div class="modal-body font-caption-alt" *ngIf="capAmountDetails">
        <!--Sanity check for cap amount details. This should never be undefined.-->
        <table width="100%" role="presentation">
            <thead>
                <tr>
                    <th scope="col" class="width-40per">Type</th>
                    <th scope="col" class="width-20per text-right">Labor</th>
                    <th scope="col" class="width-20per text-right">Expense</th>
                    <th scope="col" class="width-20per text-right">Contract</th>
                </tr>
            </thead>
        </table>
        <table width="100%">
            <caption class="m-t-12">
                Cap Amount
            </caption>
            <thead class="sr-only">
                <tr>
                    <th scope="col" class="width-40per">Type</th>
                    <th scope="col" class="width-20per text-right">Labor</th>
                    <th scope="col" class="width-20per text-right">Expense</th>
                    <th scope="col" class="width-20per text-right">Contract</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="width-40per">Cap</td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.laborCap? (capAmountDetails.laborCap | number : '1.2-2') : '-'"></td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.expenseCap? (capAmountDetails.expenseCap | number : '1.2-2') : '-'"></td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.totalContractCap? (capAmountDetails.totalContractCap | number : '1.2-2') : '-'"></td>
                </tr>
            </tbody>
        </table>
        <table width="100%">
            <caption>
                Approved Amount
            </caption>
            <thead class="sr-only">
                <tr>
                    <th scope="col" class="width-40per">Type</th>
                    <th scope="col" class="width-20per text-right">Labor</th>
                    <th scope="col" class="width-20per text-right">Expense</th>
                    <th scope="col" class="width-20per text-right">Contract</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="width-40per">Billed Amount</td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.billedLaborCap? (capAmountDetails.billedLaborCap | number : '1.2-2') : '-'"></td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.billedExpenseCap? (capAmountDetails.billedExpenseCap | number : '1.2-2') : '-'"></td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.totalBilledContractCap? (capAmountDetails.totalBilledContractCap | number : '1.2-2') : '-'"></td>
                </tr>
                <tr class="border-duoble">
                    <td class="width-40per">Unbillable Billed Amount</td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.unbilledLaborCap? (capAmountDetails.unbilledLaborCap | number : '1.2-2') : '-'"></td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.unbilledExpenseCap? (capAmountDetails.unbilledExpenseCap | number : '1.2-2') : '-'"></td>
                    <td class="width-20per text-right" [textContent]="capAmountDetails.totalUnbilled? (capAmountDetails.totalUnbilled | number : '1.2-2') : '-'"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="width-40per">Total Approved Amounts</td>
                    <td class="width-20per text-right" [ngClass]="{'pad-right0' :totalApprovedLaborAmount && isAmountExceeds}">
                        <span [textContent]="totalApprovedLaborAmount? (totalApprovedLaborAmount | number : '1.2-2') : '-'"></span>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" *ngIf="totalApprovedLaborAmount && isAmountExceeds"
                            [ngbTooltipContent]="'Total Approved Amount exceeds ' + configuredAmtPercentage + '% of Labor CAP Amount.'"
                            [dmIcon]="'tile__icon--info icon icon-warning'" ariaLabelToolTip="Total Approved Amounts warning Information">
                        </dm-tooltip>
                    </td>
                    <td class="width-20per text-right">
                        <span [textContent]="totalApprovedExpenseAmount? (totalApprovedExpenseAmount | number : '1.2-2') : '-'"></span>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" *ngIf="totalApprovedExpenseAmount && isAmountExceeds"
                            [ngbTooltipContent]="'Total Approved Amount exceeds ' + configuredAmtPercentage + '% of Expense CAP Amount.'"
                            [dmIcon]="'tile__icon--info icon icon-warning'" ariaLabelToolTip="Total Approved Amounts warning Information">
                        </dm-tooltip>
                    </td>
                    <td class="width-20per text-right" [ngClass]="{'pad-right0' : totalApprovedTotalAmount && isAmountExceeds}">
                        <span [textContent]="totalApprovedTotalAmount? (totalApprovedTotalAmount | number : '1.2-2') : '-'"></span>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" *ngIf="totalApprovedTotalAmount && isAmountExceeds"
                            [ngbTooltipContent]="'Total Approved Amount exceeds ' + configuredAmtPercentage + '% of Total CAP Amount.'"
                            [dmIcon]="'tile__icon--info icon icon-warning'" ariaLabelToolTip= "Total Approved Amounts warning Information">
                        </dm-tooltip>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" value="Continue" class="fxp-btn fxp-btn--primary" (click)="closeModal()">
            Close
        </button>
    </div>
</div>