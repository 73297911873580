import { Action } from "@ngrx/store";
import { IBillingMilestoneModel } from "../../common/services/contracts/dmmilestone.service.contract";

// import any interface you need here
// for the payload response on the success action

// 1. action types
export enum MilestonesActionTypes {
    LOAD_MILESTONES = "[Milestones] Load",
    LOAD_MANUAL_MILESTONES = "[Milestones] Load Manual",
    LOAD_MILESTONES_SUCCESS = "[Milestones] Load Success",
    LOAD_MILESTONES_FAIL = "[Milestones] Load Fail",
    INVALIDATE_MILESTONES = "[Milestones] Invalidate"
}

// 2. action creators
export class LoadMilestones implements Action {
    public readonly type = MilestonesActionTypes.LOAD_MILESTONES;
    public constructor(public engagementId: string) { }
}

export class LoadManualMilestones implements Action {
    public readonly type = MilestonesActionTypes.LOAD_MANUAL_MILESTONES;
    public constructor(public engagementId: string) { }
}

export class LoadMilestonesSuccess implements Action {
    public readonly type = MilestonesActionTypes.LOAD_MILESTONES_SUCCESS;
    // public constructor(public payload: milestones[]) { }
    public constructor(public engagementId: string, public milestones: IBillingMilestoneModel[]) { }
}

export class LoadMilestonesFail implements Action {
    public readonly type = MilestonesActionTypes.LOAD_MILESTONES_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateMilestones implements Action {
    public readonly type = MilestonesActionTypes.INVALIDATE_MILESTONES;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type MilestonesActions =
    | LoadMilestones
    | LoadManualMilestones
    | LoadMilestonesSuccess
    | LoadMilestonesFail
    | InvalidateMilestones;