<section class="modal__input--text dm-type-ahead">
  <!--==================Results from Type Ahead Response=================-->
  <ng-template #rt let-r="result" let-t="term">
    <a class="typeahead-list-item">
      <span class="dm-display-block">
        <span class="typeahead-list-item--user-name">
          <!--Shows the user's display name-->
          <ngb-highlight [result]="r.DisplayName" [term]="t"></ngb-highlight>
        </span>
        <!--Shows the user's alias-->
        <span>(</span>
        <span class="typeahead-list-item--user-name">
          <ngb-highlight [result]="r.Alias" [term]="t"></ngb-highlight>
        </span>
        <span>)</span>
      </span>
      <!-- Shows the user's title-->
      <span class="typeahead-list-item--user-type dm-display-block">
        <ngb-highlight [result]="r.AddressBookTitleDesc" [term]="t"></ngb-highlight>
      </span>
    </a>
  </ng-template>

  <!--======================Type Ahead Input Field ======================-->
  <label class="font-caption-alt" [for]="typeAheadId" *ngIf="typeAheadLabelText && showTypeAheadLabel">
    {{typeAheadLabelText}}
    <span class="required-mark" *ngIf="isMandatory" aria-hidden="true">*</span>
  </label>
  <section class="input__label PMTypehead pos-rel p-b-0 no-mar-tablet">
    <section class="userPhoto userPhoto--dashed-border display-inline-flex-imp input-user-photo"
      *ngIf="!isMultiselect && isTypeAheadV2 && selectedUser?.userName && !selectedUser?.image">
      <span class="icon icon-user icon--big"></span>
    </section>
    <img [src]="selectedUser.image" alt="user profile image"
      *ngIf="isTypeAheadV2 && selectedUser?.userName && selectedUser?.image" class="userPhoto input-user-photo">
    <input type="text" #typeAheadSearch class="form-control font-caption-alt"
      [ngClass]="{'dm-p-l-imp-35': isTypeAheadV2 && !isMultiselect && selectedUser?.userName, 'input-user': isTypeAheadV2, 'disabled-class': disabled}"
      [id]="typeAheadId" [(ngModel)]="value" [ngbTypeahead]="search" [resultTemplate]="rt" autocomplete="off"
      spellcheck="false" minlength="3" [disabled]="disabled" [attr.aria-label]="typeAheadLabelText + 'edit'" [name]="typeAheadId"
      [inputFormatter]="formatter" (selectItem)="onUserSelect($event)"
      [placeholder]="typeAheadPlaceholder" (keyup)="validateInput(); onFocus($event.target.value)"
      [readonly]="selectedUser || (modelValue && modelValue.userAlias)" [required]="isRequired ? isRequired : false"
      [attr.aria-required]="isRequired ? isRequired : false"
      (focus)="focus$.next($event.target.value); onFocus($event.target.value)" (blur)="searching = false">
    <!-- search -->
    <button title="Search" *ngIf="!searching && !value" [attr.aria-label]="btnSearchAriaLabelText"
      class="input__icon input__icon__search-btn" (click)="logSearchClick()" type="button">
      <i class="icon icon__ele dm-icon-search"></i>
    </button>
    <!-- cancel -->
    <button *ngIf="searching" class="input__icon search__icon input__icon__cancel-btn">
      <i title="Searching..." class="glyphicon glyphicon-refresh glyphicon-spin"></i>
    </button>
    <button title="Clear" class="input__icon search__icon input__icon__cancel-btn"
      id="'clearResource'-{{btnCancelAriaLabelText}}" [attr.aria-label]="btnCancelAriaLabelText" (click)="clearText()"
      *ngIf="value && !searching" type="button" [disabled]="disabled">
      <i class="icon icon__ele icon-cancel"></i>
    </button>
  </section>
  <div *ngIf="errorMessage && !searching " role="alert" aria-live="assertive" aria-atomic="true"
    class="font-caption-alt inp-validation">
    <i class="icon icon-RemoveLegacy"></i>
    {{errorMessage}}
  </div>
  <div *ngIf="searching">
    <div role="alert" aria-live="assertive" aria-atomic="true" class="sr-only">
      Please wait while the results are being loaded
    </div>
  </div>
  <!--=================Multiselect List of Users=======================-->
  <div class="multi-select-array font-caption-alt" *ngIf="isMultiselect"
    [ngClass]="{'multi-select-array-v2': isTypeAheadV2}"
    [ngStyle]="{'width': isTypeAheadV2 ? multiSelectedUsersWidth : ''}">
    <button class="multi-select-array--group solid-border set-focus" type="button"
      [ngClass]="{'multi-select-array--group-v2': isTypeAheadV2}" (click)="removeFromSelectedUsers(user,i)"
      *ngFor="let user of selectedUsers; let i = index" [disabled]="disabled"
      [attr.aria-label]="'select user '+ user.userName + 'delete'" id="{{'selectedUsers-'+i}}">
      <span class="multi-select-array--element" [title]="user.userAlias">
        <section class="userPhoto userPhoto--dashed-border display-inline-flex-imp"
          *ngIf="isTypeAheadV2 && !user?.image">
          <span class="icon icon-user icon--big"></span>
        </section>
        <img [src]="user.image" *ngIf="isTypeAheadV2 && user?.image" class="userPhoto">
        {{user.userName}}
      </span>
      <i class="icon icon-close"></i>
    </button>
  </div>
</section>