<svg xmlns="http://www.w3.org/2000/svg" *ngIf="svg" [attr.viewBox]="svg.viewBox" preserveAspectRatio="xMidYMid meet"
    [attr.height]="svg.height" [attr.width]="svg.width" [attr.class]="options.class">
    <circle [attr.cx]="svg.circle.cx" [attr.cy]="svg.circle.cy" [attr.r]="svg.circle.r" [attr.fill]="svg.circle.fill"
        [attr.stroke]="svg.circle.stroke" [attr.stroke-width]="svg.circle.strokeWidth" />
    <ng-container *ngIf="+options.percent!==0">
        <path [attr.d]="svg.path.d" [attr.stroke]="svg.path.stroke" [attr.stroke-width]="svg.path.strokeWidth"
            stroke-line-cap="butt" [attr.fill]="svg.path.fill" />
    </ng-container>
    <text alignment-baseline="baseline" [attr.x]="svg.circle.cx"
        [attr.y]="svg.subtitleLineTwo ? svg.circle.r : svg.circle.cy" text-anchor="middle">
        <ng-container>
            <tspan [attr.font-size]="valueFontSize" [attr.font-weight]="titleFontWeight" fill="#000" *ngIf="!showValue"
                [innerHTML]="svg.value">
            </tspan>
            <tspan [attr.font-size]="valueFontSize" [attr.font-weight]="titleFontWeight" fill="#000" *ngIf="showValue">
                {{value | numberSuffix: 1}}
            </tspan>
            <title>{{value | number}}</title>
            <tspan [attr.font-size]="valueFontSize - 5" [attr.font-weight]="titleFontWeight" fill="#444444">
                {{svg.units}}
            </tspan>
        </ng-container>
    </text>
    <!---Adjust dy if subtitle has to be positioned-->
    <text alignment-baseline="baseline" [attr.x]="svg.circle.cx"
        [attr.y]="svg.subtitleLineTwo ? svg.circle.r : svg.circle.cy" [attr.font-size]="svg.subtitleFontSize"
        [attr.font-weight]="subtitleFontWeight" text-anchor="middle">
        <ng-container>
            <tspan fill="#000" [attr.dy]="subtitleDistance">{{svg.subtitle}}
            </tspan>
            <tspan fill="#000" [attr.dy]="13" [attr.x]="svg.circle.cx">{{svg.subtitleLineTwo}}
            </tspan>
        </ng-container>
    </text>
</svg>