import { Injectable, Inject, SecurityContext } from "@angular/core";
import moment from "moment";
import { IEngagementDetails } from "./contracts/project.service.contracts";
import { IEngagementFinancialPlans } from "../../components/financial-mgmt/financial.model";
import { IWbsEngagementModel } from "./contracts/wbs-engagement.contracts";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { RoleShortName, RoleFullName, EntityType, RouteName, Services } from "../application.constants";
import { IEngagementDetailsV2, ITeamDetailsV2 } from "./contracts/wbs-details-v2.contracts";
import { ITeamContacts } from "./contracts/team-contacts.contracts";
import { AADGraphService } from "./aad-graphapi.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ISimpleContact } from "../../components/shared/contacts/contacts.component";
import { INavigationListItemAttribute } from "../../components/navigation/navigationlist.model";
import { NavigationService } from "./navigation.service";
import { StateService } from "@uirouter/angular";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class ManageEbsService extends DmServiceAbstract {
    public constructor(
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(AADGraphService) private aadGraphService: AADGraphService,
        @Inject(DomSanitizer) private domSanitizer: DomSanitizer,
        @Inject(NavigationService) private navigationService: NavigationService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ManageEBSService );
    }
    /**
     * Creates a WBS model with the given engagementDetails object. Applies actual min/max dates from financial plan if applicable.
     * Returns undefined if engagement details are missing.
     * @param engagementDetails Engagement full details from the PMS API
     * @param engagementFinancialPlan The financial plan for the given engagement, from the PMS API
     * @param projectId The project Id for the selected project if the user is in the Project Context. Will be undefined otherwise.
     */
    public createWBSModel(engagementDetails: IEngagementDetails, engagementFinancialPlan: IEngagementFinancialPlans, projectId?: string): IWbsEngagementModel {
        if (!engagementDetails) {
            return undefined;
        }
        const childEntitiesMinMaxDates = this.getChildMinStartMaxEndDate(engagementDetails);
        const wbsModel: IWbsEngagementModel = {
            name: engagementDetails.name,
            description: engagementDetails.description,
            domainprojectmanager: engagementDetails.pPjMName,
            domainprojectmanagerbpid: engagementDetails.pPjMbpId,
            domainprojectmanagerAlias: engagementDetails.pPjMAlias,
            deliverystate: engagementDetails.stateDescription,
            stateCode: engagementDetails.stateCode,
            startDate: engagementDetails.startDate,
            endDate: engagementDetails.endDate,
            entityId: engagementDetails.engagementId,
            lastUpdatedDate: new Date(),
            lastUpdatedBy: engagementDetails.lastUpdatedBy,
            engagementStartDate: engagementDetails.startDate,
            engagementEndDate: engagementDetails.endDate,
            childEntitiesMinStartDate: childEntitiesMinMaxDates ? childEntitiesMinMaxDates.minStartDate : undefined,
            childEntitiesMaxEndDate: childEntitiesMinMaxDates ? childEntitiesMinMaxDates.maxEndDate : undefined,
            canEditEnagagement: engagementDetails.canEditEnagagement,
            canRequestEbsStateChange: engagementDetails.canRequestEbsStateChange,
            additionalPPJMs: engagementDetails.additionalPPJMs,
            isInternal: this.sharedFunctionsService.isEngagementInternal(engagementDetails),
            pubSecCode: engagementDetails.pubSecCode,
            projectId, /* Expected to be undefined in non-project contexts */
            userRequestedEbsState: engagementDetails.userRequestedEbsState
        };
        if (!projectId && engagementFinancialPlan) { /* Only set dates for engagement context */
            wbsModel.minActualsStartDate = engagementFinancialPlan.minDate;
            wbsModel.maxActualsEndDate = engagementFinancialPlan.maxDate;
        }
        return wbsModel;
    }

    /**
     * return the team contact list
     */
    public getTeamContacts(entityData: IEngagementDetailsV2): ITeamContacts[] {
        const teamContacts: ITeamContacts[] = [];
        teamContacts.push({
            entityId: entityData.id,
            entityName: entityData.name,
            entityType: EntityType.Engagement,
            isInternalEntity: entityData.isInternalEngagment,
            contacts: this.getContactListForTeam(entityData.teamStructure)
        });

        for (const project of entityData.projects) {
            teamContacts.push({
                entityId: project.id,
                entityName: project.name,
                entityType: EntityType.Project,
                isInternalEntity: entityData.isInternalEngagment,
                contacts: this.getContactListForTeam(project.teamStructure)
            });
        }
        for (const teamContact of teamContacts) {
            this.aadGraphService.loadUserPhotosByAlias(teamContact.contacts.map((contact) => contact.alias))
                .subscribe((photoResponses) => {
                    for (const photoResponse of photoResponses) {
                        if (photoResponse && photoResponse.status === 200) {
                            const contactInfo = teamContact.contacts.filter((contact) => contact.alias.toLowerCase() === photoResponse.id.toLowerCase());
                            if (photoResponse.body) {
                                for (const contact of contactInfo) {                                    
                                    contact.image = this.domSanitizer.sanitize(SecurityContext.URL, "data:image/jpg;base64," + photoResponse.body);
                                }
                            }
                        }
                    }
                });
        }
        return teamContacts;
    }

    /**
     * returns the current ebs tab engagaement/project to reload the data after updation
     */
    public getCurrentEbsTabToRefreshContents(state: StateService): INavigationListItemAttribute {
        let attributes: INavigationListItemAttribute[] = [];
        let currentTab: INavigationListItemAttribute;
        const currentState = state.current.name;
        const routeName = currentState.substring(currentState.lastIndexOf(".") + 1);
        if (state.current.name.startsWith(RouteName.EngagementDetails)) {
            attributes = this.navigationService.getEngagementTabs(state);
        } else if (state.current.name.startsWith(RouteName.ProjectDetails)) {
            attributes = this.navigationService.getProjectTabs(state);
        }
        if (attributes.length) {
            const filteredAttributes = attributes.filter((item: INavigationListItemAttribute) => item.routeName === routeName);
            if (filteredAttributes.length) {
                currentTab = filteredAttributes[0];
            }
        }
        return currentTab;
    }

    /**
     * return the simple contact list
     */
    private getContactListForTeam(teamStructure: ITeamDetailsV2[]): ISimpleContact[] {
        const teamContactList: ISimpleContact[] = [];
        for (const team of teamStructure) {
            const splitName = team.name.split(" ");
            teamContactList.push({
                alias: team.alias,
                firstName: splitName[0],
                lastName: splitName[splitName.length - 1],
                role: this.getTeamRoleFullName(team.role),
                name: team.name,
                image: ""
            });
        }
        const indexOfProjectManager = teamContactList.findIndex((team) => (team.role === RoleFullName.PrimaryProjectManager || team.role === RoleFullName.ProjectManager));
        if (indexOfProjectManager > -1) {
            teamContactList.unshift(teamContactList.splice(indexOfProjectManager, 1)[0]);
        }
        return teamContactList;
    }

    /**
     * return the full name for the role
     */
    private getTeamRoleFullName(role: string): string {
        if (role === RoleShortName.PPJM) {
            return RoleFullName.PrimaryProjectManager;
        } else if (role === RoleShortName.ADPPJM) {
            return RoleFullName.AdditionalPrimaryProjectManager;
        } else if (role === RoleShortName.PJM) {
            return RoleFullName.ProjectManager;
        } else if (role === RoleShortName.ADPJM) {
            return RoleFullName.AdditionalProjectManager;
        } else if (role === RoleShortName.DMM) {
            return RoleFullName.PrimaryDomainManager;
        } else if (role === RoleShortName.RELMAN) {
            return RoleFullName.RelationshipManager;
        } else if (role === RoleShortName.ADMM) {
            return RoleFullName.AdditionalDomainManager;
        } else if (role === RoleShortName.UnitSubmitter) {
            return RoleFullName.UnitSubmitter;
        } else {
            return role;
        }
    }
    
    /**
     * Gets the minimum start date from the given engagement details and its projects, and the maximum end date.
     * @param engDetails
     */
    private getChildMinStartMaxEndDate(engDetails: IEngagementDetails): { minStartDate: Date; maxEndDate: Date } {
        if (engDetails && engDetails.projects && engDetails.projects[0]) {
            let firstProjectChildStartDate: moment.Moment = moment(engDetails.projects[0].startDate);
            let lastProjectChildEndDate: moment.Moment = moment(engDetails.projects[0].endDate);
            engDetails.projects.forEach((project) => {
                const serStartDate = moment(project.startDate);
                if (serStartDate.isBefore(firstProjectChildStartDate)) {
                    firstProjectChildStartDate = serStartDate;
                }
                const serEndDate = moment(project.endDate);
                if (serEndDate.isAfter(lastProjectChildEndDate)) {
                    lastProjectChildEndDate = serEndDate;
                }
            });
            return {
                minStartDate: firstProjectChildStartDate.toDate(),
                maxEndDate: lastProjectChildEndDate.toDate()
            };
        }
        return undefined;
    }
}
