import * as fromForecastRecommendations from "./project-forecast-recommendations.action";
import { IMlForecastRecommendationsState } from "../engagement-forecast-recommendations/engagement-forecast-recommendations.reducer";

export const initialState: IMlForecastRecommendationsState = {
    forecastRecommendations: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromForecastRecommendations.ProjectForecastRecommendationActions): { [projectId: string]: IMlForecastRecommendationsState } {
    switch (action.type) {
        case fromForecastRecommendations.ProjectForecastRecommendationActionTypes.LOAD_PROJECT_FORECAST_RECOMMENDATIONS: {
            const newState = {...state};
            newState[action.projectId] = {...initialState, loading: true };
            return newState;
        }

        case fromForecastRecommendations.ProjectForecastRecommendationActionTypes.LOAD_PROJECT_FORECAST_RECOMMENDATIONS_SUCCESS: {
            const newState = {...state};
            newState[action.projectId] = {
                ...initialState,
                forecastRecommendations: action.forecastRecommendations,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromForecastRecommendations.ProjectForecastRecommendationActionTypes.LOAD_PROJECT_FORECAST_RECOMMENDATIONS_FAIL: {
            const newState = {...state};
            newState[action.projectId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromForecastRecommendations.ProjectForecastRecommendationActionTypes.INVALIDATE_PROJECT_FORECAST_RECOMMENDATIONS: {
            const newState = {...state};
            newState[action.projectId] = initialState;
            return newState;
        }
    }

    return state;
}