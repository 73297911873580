<section class="modal__input--text dm-type-ahead">
  <ng-template #rt let-r="result" let-t="term">
    <a class="typeahead-list-item">
      <span class="dm-display-block">
        <span class="typeahead-list-item--user-name">
          <ngb-highlight [result]="r.CompanyCode" [term]="t"></ngb-highlight>
        </span>
        <span>(</span>
        <span class="typeahead-list-item--user-name">
          <ngb-highlight [result]="r.CompanyName" [term]="t"></ngb-highlight>
        </span>
        <span>)</span>
      </span>
      <span class="typeahead-list-item--user-type dm-display-block">
        <ngb-highlight [result]="r.CountryCode" [term]="t"></ngb-highlight>
      </span>
    </a>
  </ng-template>

  <label class="font-caption-alt" [for]="typeAheadId" *ngIf="typeAheadLabelText">{{typeAheadLabelText}}</label>

  <section class="input__label PMTypehead pos-rel p-b-0 no-mar-tablet">
    <input type="text" #typeAheadSearchCompanyCode class="form-control font-caption-alt" [id]="typeAheadId"
      placeholder="Start typing Company Code/Number" [(ngModel)]="value" [ngbTypeahead]="search"
      [resultTemplate]="rt" [required]="isRequired?isRequired:false" [attr.aria-required]="isRequired?isRequired:false"
      autocomplete="off" (ngModelChange)="validateInput()" minlength="3" [disabled]="isDisabled"
      [attr.aria-label]="typeAheadLabelText" [name]="typeAheadId" [inputFormatter]="formatter"
      (selectItem)="onUserSelect($event)" role="combobox" />

    <button title="Search" tabindex="-1" [attr.aria-label]="btnSearchAriaLabelText"
      class="input__icon input__icon__search-btn" type="button" *ngIf="!searching && !value">
      <i class="icon icon__ele dm-icon-search"></i>
    </button>
    <button [title]="searching ? 'Searching..' : 'Clear'" class="input__icon search__icon input__icon__cancel-btn"
      [attr.aria-label]="btnCancelAriaLabelText" (click)="clearText()" *ngIf="value" type="button"
      [disabled]="isDisabled">
      <i [ngClass]="searching ? 'glyphicon glyphicon-refresh glyphicon-spin' : 'icon icon__ele icon-cancel'"></i>
    </button>
  </section>
  <div [style.visibility]="errorMessage && !searching ? 'visible' : 'hidden'" class="p-t-6">
    <div role="alert" aria-live="assertive" aria-atomic="true" class="font-caption-alt inp-validation">
      <i class="icon icon-RemoveLegacy"></i> {{errorMessage}}
    </div>
  </div>
</section>