import { Component, Inject, forwardRef } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { StateService } from "@uirouter/angular";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-project-financial",
    templateUrl: "./project-financial.html",
    styleUrls: ["./project-financial.scss"]
})
export class ProjectFinancialComponent extends DmComponentAbstract {

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,     
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(dmLogger, Components.ProjectFinancials);
    }


    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public ngOnInit(): void {
    }

}
