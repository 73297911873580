import { Component, Inject, Injector } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";

import { CampusService } from "../../../common/services/campus.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { ICampusSiteDetailsApiResponse } from "../../../common/services/contracts/campus.contract";
import { IEngagementDetailsApiV2, IProjectDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { IProjectManagementKeyLinksViewModel } from "./key-links.model";
import { IState } from "../../../store/reducers";
import { LogEventConstants, SourceConstants, Components, ErrorText } from "../../../common/application.constants";
import { ProjectServiceV2 } from "../../../common/services/project.v2.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { UserpreferencelinksMgmtModalComponent } from "./user-preference-links-mgmt-modal/user-preference-links-mgmt-modal.component";
import { VirtuosoService } from "../../../common/services/virtuoso.service";
import { IWbsSettings, ICustomLink } from "../../../common/services/contracts/project.service.v2.contracts";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { ITile } from "../dm-tile/dm-tile.component";
import { DmError } from "../../../common/error.constants";
import { ErrorSeverityLevel } from "@fxp/fxpservices";

@Component({
    selector: "dm-key-links",
    templateUrl: "./key-links.html",
    styleUrls: ["./key-links.scss"]
})
export class KeyLinksComponent extends DmComponentAbstract {
    public isInternalEngagement: boolean;
    public showNBUELinks: boolean;
    public projectManagementKeyLinks: IProjectManagementKeyLinksViewModel[] = [];
    public safeLimitRoute: string;
    public safeLimitRouteParams: { [key: string]: string };
    public userPreferenceLinks: ICustomLink[];
    public projectNBUELinks: IProjectManagementKeyLinksViewModel[] = [];
    public nbueError: boolean = false;
    public hasEditPermissions: boolean;
    public tileContent: ITile;
    public keyLinksErrorMessage = DmError.KeyLinks;
    private wbsId: string;
    private projectIdsToNames: Array<{ id: string; name: string }> = [];

    /**
     * @param  {ConfigManagerService} privateconfigManagerService
     * @param  {DMLoggerService} privatelogger
     * @param  {VirtuosoService} privatevirtuosoSvc
     */
    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(VirtuosoService) private virtuosoService: VirtuosoService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(CampusService) private campusService: CampusService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.KeyProjectLinks);
    }

    /**
     * lifecycle hook - called whenever the initialization of the directive/component call loadProjectLinks
     * @returns void
     */
    public ngOnInit(): void {
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const isEngagementContext: boolean = projectId ? false : true;
        this.wbsId = isEngagementContext ? engagementId : projectId; /* Used for the modal */
        this.configManagerService.initialize().then(() => {
            this.loadUserPreferenceLinks(this.wbsId);
            const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.wbsId));
            engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
                if (engagementDetails.loaded) {
                    const engagementDetailsResponse: IEngagementDetailsApiV2 = engagementDetails.engagementDetails;
                    this.projectIdsToNames = engagementDetailsResponse.projects.map((x: IProjectDetailsV2) => { return { id: x.id, name: x.name }; });
                    // Checks if user is in team structure and allows edit permissions else read only.

                    if ((this.dmAuthorizationService.isUserInEngagementLevelTeam(engagementDetailsResponse) || this.dmAuthorizationService.isUserInProjectLevelTeam(engagementDetailsResponse.projects.filter((proj) => proj.id === projectId)[0]))) {
                        this.hasEditPermissions = true;
                    } else {
                        this.hasEditPermissions = false;
                    }

                    this.isInternalEngagement = this.sharedFunctionsService.isEngagementInternal(engagementDetailsResponse);
                    const projectTypeCode = engagementDetailsResponse.projects[0].projectTypeCode;
                    this.showNBUELinks = this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(projectTypeCode) || this.sharedFunctionsService.isECIFPreSalesEngagementBasedOnCreationCode(projectTypeCode);
                    this.loadProjectLinks(engagementId, this.isInternalEngagement, engagementDetailsResponse.companyCode);
                }
                if (engagementDetails.error) {
                    /* in case of error retreiving futher info, set the default links; 
                     however, these default links may be incorrect if the engagement is internal (we would not know if it was internal or not since the API failed.) */
                    this.projectManagementKeyLinks = this.configManagerService.getValue<IProjectManagementKeyLinksViewModel[]>("projectManagementKeyLinks");
                }
            });
        });
        this.tileContent = {
            title: "Key Project Links"
        };
        this.endLoader();
    }

    /**
     * Calls DMLoggerService to log the event to Azure Application Insights/Kustos Telemetry for dashboards
     * @param  {string} link
     * @returns void
     */
    public logEvent(link: string): void {
        const propertyBag = {};
        propertyBag[LogEventConstants.LinkClick] = link;
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.LogEvent, LogEventConstants.EngagementsProjectStandardLinkClicked, propertyBag);
    }

    /**
     * Opens up modal PopUp for links mgmt 
     */
    public openUserPreferenceLinksModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.OpenUserPreferenceLinksModal, LogEventConstants.CreateCustomLink);
        const modalRef: NgbModalRef = this.modalService.open(UserpreferencelinksMgmtModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal popup-modal in active",
            injector: this.injector
        });
        modalRef.componentInstance.userPreferenceLinks = Object.assign([], this.userPreferenceLinks);
        modalRef.componentInstance.wbsId = this.wbsId;
        modalRef.result.then((reloadData: boolean) => {
            if (reloadData) {
                this.loadUserPreferenceLinks(this.wbsId);
            }
        });
    }

    /**
     * Converts the internal project ID to a UI-friendly string to link the NBUE approval doc.
     * Will show the project name, and if there is none, then show the project ID.
     *
     * @param {string} internalProjId
     * @returns {string}
     * @memberof KeyLinksComponent
     */
    public getNbueDocName(internalProjId: string): string {
        const matchingObj = this.projectIdsToNames.filter((x) => x.id === internalProjId)[0];
        if (matchingObj && matchingObj.name) { /* Double check that name exists and isn't blank */
            return matchingObj.name + " Approval";
        }
        return internalProjId + " Approval"; /* Fall back to display the Project ID instead */
    }

    /**
     * loadProjectLinks sets loading to true, check if isInternalEngagement exists if it does it gets the internalProjectManagementKeyLinks and set loading to false
     * else if engagementId exists, it gets the projectManagementKeyLinks and invoke the virtuoso serviceto to get the virtoso link id and finally set loading to false
     * @returns void
     */
    private loadProjectLinks(engagementId: string, isInternalEngagement: boolean, companyCode: string): void {
        if (isInternalEngagement) {
            this.projectManagementKeyLinks = this.configManagerService.getValue<IProjectManagementKeyLinksViewModel[]>("internalProjectManagementKeyLinks");
            if (!this.showNBUELinks) {
                this.getProjectNBUELinks(engagementId, companyCode).then((response: IProjectManagementKeyLinksViewModel[]) => {
                    this.nbueError = false; /* Set for sanity */
                    this.projectNBUELinks = response;
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    /* Set the source to internal engagement summary page since this is restricted to internal engagements. */
                    this.dmLogger.logEvent(SourceConstants.Component.InternalEngagementSummaryPage, SourceConstants.Method.LoadProjectLinks, error, { internalEngagementId: engagementId, companyCode });
                    this.logError(SourceConstants.Method.LoadProjectLinks, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.nbueError = true;
                });
            }
        } else if (engagementId) {
            this.projectManagementKeyLinks = this.configManagerService.getValue<IProjectManagementKeyLinksViewModel[]>("projectManagementKeyLinks");
            const virtuosoLink = this.configManagerService.getValue<string>("virtuosoUrlLink");
            this.setVirtuosoLink(engagementId, virtuosoLink)
                .then(() => {
                    return this.setCampusUrl(engagementId);
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.LoadProjectLinks, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    /* Project Management Key Links is manupulated in the functions above, so if it gets changed and then fails, we need to reset it here. */
                    this.projectManagementKeyLinks = this.configManagerService.getValue<IProjectManagementKeyLinksViewModel[]>("projectManagementKeyLinks");
                });
        }
    }

    /**
     * Using the given internal engagement ID and company code, fetches a list of nbue doc links for projects of an internal engagement.
     * Does not work for customer facing engagements.
     *
     * @private
     * @param {*} engagement
     * @returns {any[]}
     * @memberof KeyLinksComponent
     */
    private getProjectNBUELinks(internalEngagementId: string, companyCode: string): Promise<IProjectManagementKeyLinksViewModel[]> {
        if (!internalEngagementId) {
            /* Throw an error that will bubble out to the UI. */
            Promise.reject("Internal Engagement ID is undefined or blank.");
        }
        if (!companyCode) {
            /* Throw an error that will bubble out to the UI. */
            Promise.reject("Company Code is undefined or blank.");
        }
        return this.projectServiceV2.getInternalEngagementNBUEDocLinks(internalEngagementId, companyCode);
    }

    /**
     * Sets the virtuoso link with the given engagement or project information, as retrieved from the Virtuoso Service.
     */
    private setVirtuosoLink(engagementId: string, virtuosoLink: string): Promise<void> {
        return this.virtuosoService.getVirtuosoLinkId(engagementId).then((response: string) => {
            if (this.projectManagementKeyLinks && this.projectManagementKeyLinks.length && response) {
                const redirectVirtuosoLink = virtuosoLink + "/ProjectDetail?ProjectId=" + response;
                const riskIssuesLink = virtuosoLink + "/RiskIssue?ProjectId=" + response;
                this.projectManagementKeyLinks.filter((link) => link.name === "Virtuoso")[0].link = redirectVirtuosoLink;
                this.projectManagementKeyLinks.filter((link) => link.name === "Virtuoso Risk and Issues")[0].link = riskIssuesLink;
            } else {
                this.projectManagementKeyLinks.filter((link) => link.name === "Virtuoso")[0].link = virtuosoLink;
                this.projectManagementKeyLinks.filter((link) => link.name === "Virtuoso Risk and Issues")[0].link = virtuosoLink;
            }
        }).catch((error) => {
            this.errorText = ErrorText.virtuosoLinkIdErrorText;
            this.logError(SourceConstants.Method.SetVirtuosoLink, error, this.errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
            return Promise.resolve();
        });
    }

    /**
     * Sets the Campus Url based on the given engagement Id, with info retrieved from the Campus Service.
     * @param engagementId 
     */
    private setCampusUrl(engagementId: string): Promise<void> {
        return this.campusService.getCampusUrlBasedOnEngagementId(engagementId)
            .then((response: ICampusSiteDetailsApiResponse) => {
                if (response && response.SiteURL) {
                    const filteredLinks = this.projectManagementKeyLinks.filter((link: IProjectManagementKeyLinksViewModel) => link.name === "Campus");
                    if (filteredLinks.length) {
                        filteredLinks[0].link = response.SiteURL;
                    }
                }
            }).catch((error) => {
                /* In case of error, keep going. */
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.SetCampusUrl, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }


    /**
     * Retrieves the UserPreference Links from the Api  and sets properties for the model
     */
    private loadUserPreferenceLinks(wbsId: string): void {
        this.projectServiceV2.getWbsSettings(wbsId)
            .then((response: IWbsSettings) => {
                this.userPreferenceLinks = (response.customLinks && response.customLinks.length) ? response.customLinks : [];
                this.userPreferenceLinks.forEach((link, index) => link.id = index + 1);
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.LoadUserPreferenceLinks, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
    }
}