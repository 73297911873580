import { Action } from "@ngrx/store";
import { IWeeklyForecastRecommendation } from "../../common/services/contracts/forecast-recommendation.contracts";

// 1. action types
export enum ForecastRecommendationActionTypes {
    LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS = "[EngagementForecastRecommendations] Load",
    LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS_SUCCESS = "[EngagementForecastRecommendations] Load Success",
    LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS_FAIL = "[EngagementForecastRecommendations] Load Fail",
    INVALIDATE_ENGAGEMENT_FORECAST_RECOMMENDATIONS = "[EngagementForecastRecommendations] Invalidate"
}

// 2. action creators
export class LoadEngagementForecastRecommendations implements Action {
    public readonly type = ForecastRecommendationActionTypes.LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS;
    public constructor(public engagementId: string) { }
}
export class LoadEngagementForecastRecommendationsSuccess implements Action {
    public readonly type = ForecastRecommendationActionTypes.LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS_SUCCESS;
    public constructor(public engagementId: string, public forecastRecommendations: IWeeklyForecastRecommendation[]) { }
}

export class LoadEngagementForecastRecommendationsFail implements Action {
    public readonly type = ForecastRecommendationActionTypes.LOAD_ENGAGEMENT_FORECAST_RECOMMENDATIONS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateEngagementForecastRecommendations implements Action {
    public readonly type = ForecastRecommendationActionTypes.INVALIDATE_ENGAGEMENT_FORECAST_RECOMMENDATIONS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type ForecastRecommendationsActions =
    | LoadEngagementForecastRecommendations
    | LoadEngagementForecastRecommendationsSuccess
    | LoadEngagementForecastRecommendationsFail
    | InvalidateEngagementForecastRecommendations;