<section *ngIf="operation">
    <div class="process-notifications">
        <div class="process-notifications__expanded">
            <div
                [ngClass]="operation.isItemExpanded ? 'process-notifications__item process-notifications__item--expanded': 'process-notifications__item process-notifications__item--collapsed'">
                <ng-container *ngIf="operation.Done">
                    <div class="process-notifications__item-header">
                        <div class="notification-bar__item-title"
                            [attr.title]="operation.WbsName + ' (' + timeAgo + ')'">
                            {{ operation.WbsName }} </div>
                        <div class="pull-right">
                            <button aria-label="Expand" class="process-notifications__item-btn" title="expand"
                                type="button" (click)="toggleProcessNotificationItem()">
                                <span class="icon"
                                    [ngClass]="{'icon-chevron-up': !operation.isItemExpanded, 'icon-chevron-down': operation.isItemExpanded}"></span>
                            </button>
                            <button *ngIf="showCloseOption" aria-label="Close" class="process-notifications__item-btn"
                                title="close" (click)="closeNotification()" type="button">
                                <span aria-hidden="true" class="icon icon-close"></span>
                            </button>
                        </div>
                    </div>
                    <div class="process-notifications__item-content--collapsed">
                        <span class="icon icon-check icon-rounded-outline icon-success"></span>
                        <span class="process-notifications__item-content-text"> {{ operation.Task }} process
                            completed</span>
                    </div>
                    <div class="process-notifications__item-content--expanded" *ngIf="operation.isItemExpanded">
                        <div class="process-notifications__item-content-list">
                            <ul>
                                <li *ngFor="let step of operation.Steps let index = index" [ngClass]="step-done">
                                    <div>
                                        <span>{{(index + 1)}}</span>
                                        <span class="dm-p-l-10">{{ step.StepName }}</span>
                                        <div class="process-notifications__item-step-icon-holder pull-right">
                                            <span
                                                class="icon icon-check icon-rounded-outline pull-right icon-success"></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!operation.Done">
                    <div class="process-notifications__item-header">
                        <div class="notification-bar__item-title" name="operation.WbsName"
                            [attr.title]="operation.WbsName + ' (' + timeAgo + ')'">
                            {{ operation.WbsName }} </div>
                        <div class="pull-right">
                            <button *ngIf="operation.Error" aria-label="Restart" class="process-notifications__item-btn"
                                title="restart" type="button" (click)="restartOperation()">
                                <span class="icon icon-refresh"></span>
                            </button>
                            <button aria-label="Expand" class="process-notifications__item-btn" title="expand"
                                type="button" (click)="toggleProcessNotificationItem()">
                                <span class="icon"
                                    [ngClass]="{'icon-chevron-up': !operation.isItemExpanded, 'icon-chevron-down': operation.isItemExpanded}"></span>
                            </button>
                        </div>
                    </div>
                    <div class="process-notifications__item-content--collapsed dm-m-t-5"
                        *ngIf="!operation.isItemExpanded">
                        <div class="pull-left display-grid">
                            <div class="process-notifications__item-content-text">
                                <span *ngIf="operation.Error"
                                    class="icon icon-dm-error icon-rounded-dm-error pull-left"></span>
                                <span class="dm-p-l-10">
                                    {{ operation.Steps[operation.CurrentStep - 1]?.StepName }}</span>
                            </div>
                            <div *ngIf="!operation.Error" class="process-notifications__progress-bar">
                                <ngb-progressbar [value]="operation?.CurrentStep - 1" [max]="operation?.Steps?.length">
                                </ngb-progressbar>
                            </div>
                        </div>
                        <div class="pull-right">
                            <span
                                class="process-notifications__steps-progress process-notifications__steps-progress--pull-right"
                                (click)="operation.isItemExpanded = true">Details</span>
                        </div>
                    </div>
                    <div class="process-notifications__item-content--expanded" *ngIf="operation.isItemExpanded">
                        <div class="process-notifications__item-content-status">
                            <div class="pull-left dm-p-x-5 process-notifications__item-content--progress-bar">
                                <div class="process-notifications__item-content-text">
                                    <span *ngIf="operation.Error"
                                        class="icon icon-dm-error icon-rounded-dm-error pull-left"></span>
                                    <span class="dm-p-l-6">
                                        {{ operation.Steps[operation.CurrentStep - 1]?.StepName }}</span>
                                </div>
                                <div *ngIf="!operation.Error" class="process-notifications__progress-bar">
                                    <ngb-progressbar [value]="operation?.CurrentStep - 1" [max]="operation?.Steps?.length">
                                    </ngb-progressbar>
                                </div>
                            </div>
                            <div class="pull-right">
                                <span class="process-notifications__steps-progress">
                                    Step {{ operation?.CurrentStep }} of {{operation?.Steps?.length}}</span>
                            </div>
                        </div>
                        <div class="process-notifications__item-content-list">
                            <ul>
                                <li *ngFor="let step of operation.Steps; let index = index"
                                    [ngClass]="(step.StepStatus === 'TaskCompleted') || (step.StepStatus === 'TaskFailed') ? 'step-done' : 'step-todo'">
                                    <div>
                                        <span>{{index + 1}}</span>
                                        <span class="dm-p-l-10">{{ step.StepName }}</span>
                                        <div class="process-notifications__item-step-icon-holder pull-right">
                                            <span *ngIf="step.StepStatus === 'TaskCompleted'"
                                                class="icon icon-check icon-rounded-outline pull-right icon-success"></span>
                                            <span *ngIf="step.StepStatus === 'TaskFailed'" [attr.title]="step.Message"
                                                class="icon icon-dm-error icon-rounded-dm-error pull-right"></span>
                                            <span *ngIf="step.StepStatus === 'TaskScheduled'"
                                                class="icon icon-clock pull-right"></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>