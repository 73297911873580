import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services, SourceConstants } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";
import { IPurchaseOrder } from "./contracts/po.service.contracts";
import { ISaveGR, ISaveGRResponse } from "./contracts/save-gr.service.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { ErrorSeverityLevel } from "@fxp/fxpservices";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { IPOTransactionDetail } from "./contracts/poList.model.contract";

@Injectable()
export class PurchaseOrderService extends DmServiceAbstract {
    private poServiceBaseUri: string;
    private poServicePsBaseUri: string;
    private subscriptionKey: string;
    private psPoSubscriptionKey: string;
    private isNewPOEnabled: boolean;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(dmLogger, Services.PurchaseOrderService);
        this.configManagerService.initialize().then(() => {
            this.poServiceBaseUri = this.configManagerService.getValue<string>("poServiceBaseUri");
            this.poServicePsBaseUri = this.configManagerService.getValue<string>("poServicePsBaseUri");
            this.subscriptionKey = this.configManagerService.getValue<string>("poServiceSubscriptionKey");
            this.psPoSubscriptionKey = this.configManagerService.getValue<string>("poServicePsSubscriptionKey");
            this.isNewPOEnabled = this.configManagerService.isFeatureEnabled("useNewPoEndPoint");
        });
    }


    /**
     * Gets list of Purchase Order detailed objects by Wbs ID from a Get request to the Purchase Order API (depth set to true)
     * @param wbsId
     */
    public getPurchaseOrderDetailsByWbsId(wbsId: string): Promise<IPurchaseOrder[]> {
        let url = `${this.poServiceBaseUri}/api/PurchaseOrderStatus/GetPoListByWbsOrBpId?wbsId=${wbsId}&depth=true&bpId=&poNumber=`;
        let subsKey = this.subscriptionKey;
        if (!this.isNewPOEnabled) {
            url = `${this.poServicePsBaseUri}/PurchaseOrders?wbsId=${wbsId}&depth=true`;
            subsKey = this.psPoSubscriptionKey;
        }
        return (this.dataService.getData(url, subsKey, APIConstants.PoListAPI) as Promise<IPurchaseOrder[]>)
            .catch((error) => {
                const correlationIdString: string = " Correlation ID: " + DataService.getCorrelationIdFromError(error);
                if (error.data) {
                    error = error.data;
                    if (error.InnerError && error.InnerError.InnerError && Object.keys(error.InnerError.InnerError).length > 0) {
                        this.logError(SourceConstants.Method.GetPurchaseOrderDetailsByWbsId, error, error.InnerError.InnerError, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        return Promise.reject(error.InnerError.InnerError + correlationIdString);
                    }
                    if (error.Message) {
                        this.logError(SourceConstants.Method.GetPurchaseOrderDetailsByWbsId, error, error.Message, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        return Promise.reject(error.Message + correlationIdString);
                    }
                }
                this.logError(SourceConstants.Method.GetPurchaseOrderDetailsByWbsId, error, "Unable to retrieve Purchase Orders.", ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.reject("Unable to retrieve Purchase Orders." + correlationIdString);
            });
    }

    public getPOTransactionDetails(poNumber: string, poItemId?: number): Promise<IPOTransactionDetail[]> {
        const url = `${this.poServicePsBaseUri}/PurchaseOrders/${poNumber}/LineItem/${poItemId}`;
        return this.dataService.getData(url, this.psPoSubscriptionKey, APIConstants.GetActuals);
    }

    /**
     * Saves a Goods Receipt by sending the data as Put request to the Purchase Order API
     * @param grData
     */
    public saveGR(grData: ISaveGR): Promise<ISaveGRResponse> {
        const url = `${this.poServicePsBaseUri}/PurchaseOrders`;
        return this.dataService.putData(url, this.psPoSubscriptionKey, APIConstants.PoSaveGrAPI, grData);
    }
}
