import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IWbsDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import * as WbsDetailsActionsV2 from "./wbs-details.action";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";

@Injectable()
export class WbsDetailsV2Effects {

    @Effect()
    public loadWbsDetails$ = this.actions$
        .ofType(WbsDetailsActionsV2.WbsDetailsV2ActionTypes.LOAD_WBS_DETAILS)
        .pipe(
            switchMap((action: WbsDetailsActionsV2.LoadWbsDetails) => fromPromise(
                this.engagementDetailService.getWbsFullDetailsV2(action.wbsId, action.includeEngagements, action.includeProjects, action.includeServices, action.includeTasks, action.includeTeams))
                .pipe(
                    map((response: IWbsDetailsV2) => new WbsDetailsActionsV2.LoadWbsDetailsSuccess(action.wbsId, response)),
                    catchError((error) => of(new WbsDetailsActionsV2.LoadWbsDetailsFail(action.wbsId, error)))
                )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
    ) { }
}
