export interface ICircularProgressOptions {
    animationDuration?: number;
    class?: string;
    innerStrokeColor: string;
    innerStrokeWidth: number;
    outerStrokeColor: string;
    outerStrokeWidth: number;
    percent: number;
    radius: number;
    space: number;
    subtitle: string;
    subtitleFontSize: number;
    subtitleLineTwo?: string;
    toFixed?: number;
    units?: string;
    value?: string;
}


// Default Options for Circular Progress Bar
export class CircleProgressDefaultOptions implements ICircularProgressOptions {
    public animationDuration: number = 300;
    public class: string = "";
    public innerStrokeColor: string = "#C7E596";
    public innerStrokeWidth: number = 4;
    public outerStrokeColor: string = "#78C000";
    public outerStrokeWidth: number = 8;
    public percent: number = 0;
    public radius: number = 50;
    public space: number = -4;
    public subtitle: string = "";
    public subtitleFontSize: number = 14;
    public subtitleLineTwo: string = "";
    public toFixed: number = 0;
    public units: string = "%";
    public value: string = "0";
}