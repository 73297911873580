import { combineLatest as observableCombineLatest } from "rxjs";
import { StateService } from "@uirouter/angular";
import { Component, EventEmitter, forwardRef, Inject, Output } from "@angular/core";
import { RouteName, Components, FinancialType } from "../../../../../common/application.constants";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.selector";
import { IFinancialDetailsV2State } from "../../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IEntityFinancials, IEntityFinancialSummary } from "../../../../financial-mgmt/financial.model";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { Store } from "@ngrx/store";
import { StoreDispatchService } from "../../../../../common/services/store-dispatch.service";
import { FinancialService } from "../../../../../common/services/financial.service";
import { IState } from "../../../../../store/reducers";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { ICceac } from "../../../../project-summary-v2/tiles/project-summary-financials-v2/project.financials.model";
import { ICostHoursValidationViewModel, IEngagementPoccValidationViewModel, IProjectFinancial, IProjectLaborConsumptionViewModel, IRevenueLtdValidationViewModel, ISubconValidationViewModel, ITecoPrevalidation, ITecoPrevalidationConfiguration, ITecoPrevalidationViewModel, ITecoValidationResult, IUnbilledBalanceValidationViewModel, IUnearnedBalanceValidationViewModel, IValidationResultEmmiter, TecoPrevalidationStatus } from "./engagement-teco-prevalidation.contract";
import { FxpContext } from "@fxp/fxpservices";
import { getEntireInvoices } from "../../../../../store/invoices/invoices.selector";
import { IInvoicesState } from "../../../../../store/invoices/invoices.reducer";
import { IInvoiceItemModel } from "../../../../invoices/invoice-table-data/invoice-table-data.contract";
import { getEntireManageSuppliers } from "../../../../../store/manage-suppliers/manage-suppliers.selector";
import { IManageSuppliersState } from "../../../../../store/manage-suppliers/manage-suppliers.reducer";
import { IPurchaseOrder } from "../../../../../common/services/contracts/po.service.contracts";
import { LoadFinancialDetailsV2 } from "../../../../../store/financial-details-v2/financial-details-v2.action";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";



@Component({
    selector: "dm-engagement-teco-prevalidation",
    templateUrl: "./engagement-teco-prevalidation.html",
    styleUrls: ["./engagement-teco-prevalidation.scss"]
})
export class EngagementTecoPrevalidation extends DmComponentAbstract {

    @Output() public isValidEvent = new EventEmitter<IValidationResultEmmiter>();

    public isServerError: boolean = false;
    public cceacValues: ICceac;
    public isInternalEngagement: boolean = false;
    public isProjectTypeFF: boolean;
    public isProjectECIF: boolean = false;
    public POCCPercentage: number;
    public revenueAmountRecognized: number;
    public RouteName = RouteName;
    public isEngagementFF: boolean = false;
    public planCurrency: string;
    public tecoValidationsList: ITecoPrevalidation[];
    public engagementPOCCTooltipText: string;
    public projectLaborConsumptionViewModel: IProjectLaborConsumptionViewModel;
    public ffTypeProjects: IProjectDetailsV2[];
    public tmTypeProjects: IProjectDetailsV2[];
    public engagementPoccValidationViewModel: IEngagementPoccValidationViewModel;
    public unearnedBalanceViewModel: IUnearnedBalanceValidationViewModel;
    public unbilledBalanceViewModel: IUnbilledBalanceValidationViewModel;
    public revenueLtdValidationViewModel: IRevenueLtdValidationViewModel;
    public engagementECIFconsumedValidation: ITecoPrevalidationViewModel;
    public engagementTEsubmittedValidation: ITecoPrevalidationViewModel;
    public costHoursValidationViewModel: ICostHoursValidationViewModel;
    public subconValidationViewModel: ISubconValidationViewModel;
    public activeValidations: ITecoPrevalidationViewModel[];
    public poccValidationInfo: string = "Please provide reason to proceed if POCC < 95% in the comments section";
    public unearnedBalanceValidationInfo: string = "For FF engagement if [Invoiced Revenue > Rec Revenue] – not a hard stop as we can true up revenue to the full value of invoiced). For FF engagement if [Invoiced Revenue <Rec Revenue] – Please work with your ADE for invoicing";
    public revenueLtdValidationInfo: string = "Please provide information in the comment where delivery is completed or not?";
    public costHoursValidationInfo: string = "Please true up your planned hours equal to actuals in your CFP. You can do this in the Plan Forecast page sync Forecacst to Plan which will Sync Actuals with Forecast to CFP.";
    public subConValidationTitle: string = "Subcon";
    public costHoursValidationTitle: string = "Cost Hours LTD Planned vs Actuals";
    public ecifValidationTitle: string = "Is ECIF consumed and recognized?";
    public timeExpenseValidationTitle: string = "Is all T&E submitted and approved?";
    public recognizedRevVscontracrRevValidationTitle: string = "Recognized Revenue > 95 % of Contracted Revenue";
    public recognizedRevVsInvoicedRevValidationTitle: string = "Recognized Revenue = +/- 98% Invoiced Revenue";
    public revenueLTDActualVsPlannedValidationTitle: string = "Revenue - LTD Actuals vs Planned";
    public poccValidationTitle: string = "Engagement POC% >= 95%";


    private queuedProjects: Array<{ projectId: string; isDataLoaded?: boolean }> = [];
    private engagementId: string;
    private isProjectTypeTM: boolean;
    private tecoPrevalidationConfiguration: ITecoPrevalidationConfiguration;

    public constructor(
        @Inject(forwardRef(() => FxpContext)) private fxpContext: FxpContext,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(FinancialService) private financialService: FinancialService) {
        super(dmLogger, Components.EngagementTecoPrevalidation);
        this.tecoPrevalidationConfiguration = this.configurationService.getValue<ITecoPrevalidationConfiguration>("tecoPrevalidations");
        this.recognizedRevVscontracrRevValidationTitle = `Recognized Revenue >= ${this.tecoPrevalidationConfiguration.recognizedRevenueVsContractRevenueThresholdPercentage}% of Contracted Revenue`;
        this.recognizedRevVsInvoicedRevValidationTitle = `Recognized Revenue = +/- ${this.tecoPrevalidationConfiguration.recognizedRevenueVsInvoiceRevenueThresholdPercentage}% Invoiced Revenue`;
        this.costHoursValidationTitle = `Actuals hours >= ${this.tecoPrevalidationConfiguration.costHoursActualsVsPlannedThresholdPercentage}% of CFP hours`;
        this.poccValidationTitle = `Engagement POC% >= ${this.tecoPrevalidationConfiguration.poccThresholdPercentage}`;
        this.projectLaborConsumptionViewModel = {
            projectFinancials: [],
            failedProjects: [],
            passedProjects: [],
            status: TecoPrevalidationStatus.Evaluating
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public ngOnInit(): void {
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.storeDispatchService
            .requireEngagementDetails(this.engagementId, true)
            .requireFinancialDetailsV2(this.engagementId, true)
            .requireInvoices(this.engagementId, true)
            .requireManageSuppliers(this.engagementId, true)
            .load();
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        const financialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.engagementId));
        const invoices$ = this.store.select(
            getEntireInvoices(this.engagementId));
        const manageSuppliers$ = this.store.select(getEntireManageSuppliers(this.engagementId));

        observableCombineLatest(
            financialDetails$,
            engagementDetails$,
            invoices$,
            manageSuppliers$,
            (
                financialDetails: IFinancialDetailsV2State,
                engagementDetails: IEngagementDetailsState,
                invoices: IInvoicesState,
                manageSuppliers: IManageSuppliersState

            ) => {
                return ({
                    financialDetails,
                    engagementDetails,
                    invoices,
                    manageSuppliers,
                });
            }
        ).pipe(untilDestroyed(this))
            .subscribe(({
                financialDetails,
                engagementDetails,
                invoices,
                manageSuppliers
            }) => {
                this.setLoadersBasedOnItemState(financialDetails, engagementDetails, invoices, manageSuppliers);
                this.setErrorsBasedOnItemState(financialDetails, engagementDetails, invoices, manageSuppliers);
                if (engagementDetails.error || manageSuppliers.error || financialDetails.error || invoices.error) {
                    this.isServerError = true;
                }

                if (financialDetails.loaded && engagementDetails.loaded && invoices.loaded && manageSuppliers.loaded) {
                    engagementDetails.engagementDetails.projects.forEach(
                        (project) => {
                            this.queuedProjects.push({ projectId: project.id, isDataLoaded: false });
                        }
                    );
                    const projectsTobeDispatched = this.queuedProjects.filter((project) => !project.isDataLoaded).map((val) => val.projectId);
                    this.initialteProjectFinancesLoad(projectsTobeDispatched);
                    if (financialDetails && financialDetails.financialDetails && financialDetails.financialDetails.financialSummary
                        && financialDetails.financialDetails.financialSummary.length > 0 && financialDetails.financialDetails.financialSummary[0]) {
                        this.planCurrency = financialDetails.financialDetails.financialSummary[0].planCurrency;
                    }

                    this.initiateValidations(engagementDetails.engagementDetails, (financialDetails as IFinancialDetailsV2State).financialDetails, invoices.invoices, manageSuppliers.manageSuppliers);
                }

            });
    }

    /**
     * Decides wether to show the comment icon based on the validation
     * @param validationObjectViewModel ValidationObjectViewModel
     * returns boolean
     */
    public shouldShowCommentIcon(validationObjectViewModel: ITecoPrevalidationViewModel): boolean {
        return !validationObjectViewModel.isEditMode && !validationObjectViewModel.comment;
    }

    /**
     * Decides wether to show the comment input text area based on the validation
     * @param validationObjectViewModel ValidationObjectViewModel
     * returns boolean
     */
    public shouldShowCommentInput(validationObjectViewModel: ITecoPrevalidationViewModel): boolean {
        return validationObjectViewModel.isEditMode;
    }

    /**
     * Decides wether to show the comment paragraph based on the validation
     * @param validationObjectViewModel ValidationObjectViewModel
     * returns boolean
     */
    public shouldShowCommentText(validationObjectViewModel: ITecoPrevalidationViewModel): boolean {
        return !validationObjectViewModel.isEditMode && validationObjectViewModel.comment && validationObjectViewModel.comment.trim().length > 0;
    }

    /**
     * Action method on clicking the edit comment icon
     * @param validationObjectViewModel ValidationObjectViewModel     
     */
    public onClickEditComment(validationObjectViewModel: ITecoPrevalidationViewModel): void {
        validationObjectViewModel.isEditMode = true;
    }

    /**
     * Action method on clicking OK in Commments
     * @param validationObjectViewModel ValidationObjectViewModel     
     */
    public onClickOkComment(validationObjectViewModel: ITecoPrevalidationViewModel): void {
        validationObjectViewModel.isEditMode = false;
        validationObjectViewModel.comment = validationObjectViewModel.comment.trim();
        if (validationObjectViewModel.comment.length > 0) {
            /** 
             * Check if the validation is mandatory and the status is in failed or if the status is invalid that pass through regardless of if validation is mandatory.
             * The invalid status is used to identify zero checks which can bypass validation
            */
            if ((!validationObjectViewModel.isMandatory && validationObjectViewModel.status === TecoPrevalidationStatus.Failed) || validationObjectViewModel.status === TecoPrevalidationStatus.Invalid) {
                validationObjectViewModel.status = TecoPrevalidationStatus.ApprovedWithComment;
            }
            this.validateAndEmmitState();
        }
    }
    /**
     * Action method on clicking Close in Commments
     * @param validationObjectViewModel ValidationObjectViewModel     
     */
    public onClickCloseComment(validationObjectViewModel: ITecoPrevalidationViewModel): void {
        validationObjectViewModel.isEditMode = false;
        this.validateAndEmmitState();
    }

    /**
     * Action method on toggleing slider
     * @param validationObjectViewModel ValidationObjectViewModel     
     */
    public validationToggled(validationObjectViewModel: ITecoPrevalidationViewModel): void {

        if (validationObjectViewModel.status === TecoPrevalidationStatus.Passed) {
            validationObjectViewModel.status = TecoPrevalidationStatus.Failed;
        }
        else {
            validationObjectViewModel.status = TecoPrevalidationStatus.Passed;
        }

        this.validateAndEmmitState();
    }

    /**
     * Gets all project labor consumption         
     */
    public getAllLoadedProjectLaborConsumption(): IProjectFinancial[] {
        const results = this.projectLaborConsumptionViewModel.failedProjects;
        return results;
    }

    /**
     * Validaties all validation and emits the state to the parent component
     */
    private validateAndEmmitState(): void {
        let outcome = true;
        if (this.isProjectTypeFF) {
            outcome = outcome && (this.engagementPoccValidationViewModel.status === TecoPrevalidationStatus.Passed || this.engagementPoccValidationViewModel.status === TecoPrevalidationStatus.ApprovedWithComment);
        }
        if (this.isProjectTypeTM) {
            outcome = outcome && (this.revenueLtdValidationViewModel.status === TecoPrevalidationStatus.Passed || this.revenueLtdValidationViewModel.status === TecoPrevalidationStatus.ApprovedWithComment);
        }
        outcome = outcome && (this.unearnedBalanceViewModel.status === TecoPrevalidationStatus.Passed || this.unearnedBalanceViewModel.status === TecoPrevalidationStatus.ApprovedWithComment)
            && (this.unbilledBalanceViewModel.status === TecoPrevalidationStatus.Passed || this.unbilledBalanceViewModel.status === TecoPrevalidationStatus.ApprovedWithComment)
            && (this.subconValidationViewModel.status === TecoPrevalidationStatus.Passed || this.subconValidationViewModel.status === TecoPrevalidationStatus.ApprovedWithComment)
            && (this.costHoursValidationViewModel.status === TecoPrevalidationStatus.Passed || this.costHoursValidationViewModel.status === TecoPrevalidationStatus.ApprovedWithComment);

        outcome = outcome && this.engagementTEsubmittedValidation.status === TecoPrevalidationStatus.Passed;

        const resultObject: ITecoValidationResult = {
            engagementPoccValidationResult: this.engagementPoccValidationViewModel,
            unearnedBalanceValidationResult: this.unearnedBalanceViewModel,
            revenueLtdValidationResult: this.revenueLtdValidationViewModel,
            costHoursValidationResult: this.costHoursValidationViewModel,
            subconValidationResult: this.subconValidationViewModel,
            unbilledBalanceValidationResult: this.unbilledBalanceViewModel,
            engagementEcifConsumedValidationResult: this.engagementECIFconsumedValidation,
            engagementTimeEnpenseSubmittedValidationResult: this.engagementTEsubmittedValidation
        };

        const result: IValidationResultEmmiter = {
            isValid: outcome,
            result: resultObject
        };

        this.isValidEvent.emit(result);
    }

    /**
     * Initiates the TECO validations
     * @param engagementDetails EngagementDetails
     * @param engagementFinancials FinancialDetails
     * @param invoices Invoices
     * @param purchaseOrders PurchaseOrders
     */
    private initiateValidations(engagementDetails: IEngagementDetailsV2, engagementFinancials: IEntityFinancials, invoices: IInvoiceItemModel[], purchaseOrders: IPurchaseOrder[]) {

        const ffTypeProjects = engagementDetails.projects.filter((p) => p.contractType === "FF");
        const tmTypeProjects = engagementDetails.projects.filter((p) => p.contractType !== "FF");
        const currentBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.CurrentFinancialPlan);
        const actualDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ACTUALS);
        const actualDetails_current = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ActualsCurrent);
        const contractBaseLineDetails = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancials, FinancialType.ContractBaseline);

        this.isProjectTypeFF = ffTypeProjects.length > 0;
        if (this.isProjectTypeFF) {
            this.initiateEngagementPoccValidation(currentBaseLineDetails, actualDetails);
        }
        this.initiateUnearnedBalanceValidation(invoices, actualDetails);

        this.inititateUnbilledBalanceValidation(actualDetails, contractBaseLineDetails);
        this.isProjectTypeTM = tmTypeProjects.length > 0;

        if (this.isProjectTypeTM) {
            this.initiateRevenueLtdValidation(currentBaseLineDetails, actualDetails);
        }

        this.initiateSubconValidation(purchaseOrders);

        this.initiateCostHoursValidation(currentBaseLineDetails, actualDetails_current);

        this.initiateEngagementECIFConsumedValidation(engagementDetails.projects);

        this.initiateEngagementTESubmittedValidation();

    }

    /**
     * Checks all Projects and returns true if any one is ECIF
     * @param projects List of Projects
     */
    private isAnyProjectECIF(projects: IProjectDetailsV2[]): boolean {
        let isProjectECIF = false;

        projects.forEach((project) => {
            if (project) {
                isProjectECIF = isProjectECIF || this.sharedFunctionsService.isProjectECIF(project.userStatusCode);
            }
        });
        return isProjectECIF;
    }

    /**
     * Initiates the subcon validations
     * @param purchaseOrders 
     */
    private initiateSubconValidation(purchaseOrders: IPurchaseOrder[]) {
        this.subconValidationViewModel = {
            ffPO: 0,
            gr: 0,
            invoiced: 0,
            purchaseOrders: [],
            isActive: true,
            isMandatory: false,
            isEditMode: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Evaluating,
            title: this.subConValidationTitle
        };

        let ffPO = 0;
        let gr = 0;
        let invoiced = 0;
        let poInvoiced = 0;

        if (purchaseOrders && purchaseOrders.length > 0) {
            for (const po of purchaseOrders) {
                ffPO += po.poTotalAmount;
                gr += po.poTotalGrAmount;
                poInvoiced = 0;
                if (po.lineItems && po.lineItems.length > 0) {
                    for (const lineItem of po.lineItems) {
                        invoiced += lineItem.amountInvoiced;
                        poInvoiced += lineItem.amountInvoiced;
                    }
                }
                this.subconValidationViewModel.purchaseOrders.push({
                    poNumber: po.poNumber,
                    totalAmount: po.poTotalAmount,
                    consumedAmount: po.poTotalGrAmount,
                    invoicedAmount: poInvoiced
                });
            }
        }

        this.subconValidationViewModel.ffPO = ffPO;
        this.subconValidationViewModel.gr = gr;
        this.subconValidationViewModel.invoiced = invoiced;

        if (ffPO === 0 && invoiced === 0) {
            this.subconValidationViewModel.status = TecoPrevalidationStatus.Invalid;
        }
        else if (ffPO === gr && ffPO === invoiced) {
            this.subconValidationViewModel.status = TecoPrevalidationStatus.Passed;
        }
        else {
            this.subconValidationViewModel.status = TecoPrevalidationStatus.Failed;
        }
    }

    /**
     * Initiates the cost hours validation
     * @param currentBaseLineDetails 
     * @param actualDetails 
     */
    private initiateCostHoursValidation(currentBaseLineDetails: IEntityFinancialSummary, actualDetails_current: IEntityFinancialSummary): void {
        this.costHoursValidationViewModel = {
            actualHours: actualDetails_current ? actualDetails_current.labor : 0,
            plannedHours: currentBaseLineDetails ? currentBaseLineDetails.labor : 0,
            isActive: true,
            isMandatory: true,
            isEditMode: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Evaluating,
            title: this.costHoursValidationTitle
        };
        if (this.costHoursValidationViewModel.plannedHours === 0 && this.costHoursValidationViewModel.actualHours === 0) {
            this.costHoursValidationViewModel.status = TecoPrevalidationStatus.Invalid;
        }
        else if (this.isHoursWithinThreshold(this.costHoursValidationViewModel.plannedHours, this.costHoursValidationViewModel.actualHours)) {
            this.costHoursValidationViewModel.status = TecoPrevalidationStatus.Passed;
        }
        else {
            this.costHoursValidationViewModel.status = TecoPrevalidationStatus.Failed;
        }
    }

    /**
     * 
     * @param projects Initiates the ECIF cosumed validation
     */
    private initiateEngagementECIFConsumedValidation(projects: IProjectDetailsV2[]): void {
        this.engagementECIFconsumedValidation = {
            isActive: true,
            isMandatory: this.isAnyProjectECIF(projects),
            isEditMode: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Failed,
            title: this.ecifValidationTitle
        };
    }

    /**
     * INitiates the T&E Submitted validation
     */
    private initiateEngagementTESubmittedValidation(): void {
        this.engagementTEsubmittedValidation = {
            isActive: true,
            isMandatory: true,
            isEditMode: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Failed,
            title: this.timeExpenseValidationTitle
        };
    }

    /**
     * Initiates Revenue LTD validation
     * @param currentBaseLineDetails 
     * @param actualDetails 
     */
    private initiateRevenueLtdValidation(currentBaseLineDetails: IEntityFinancialSummary, actualDetails: IEntityFinancialSummary): void {
        this.revenueLtdValidationViewModel = {
            actualRevenue: actualDetails ? actualDetails.revenue : 0,
            plannedRevenue: currentBaseLineDetails ? currentBaseLineDetails.revenue : 0,
            isActive: true,
            isMandatory: false,
            isEditMode: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Evaluating,
            title: this.revenueLTDActualVsPlannedValidationTitle
        };
        if (this.revenueLtdValidationViewModel.plannedRevenue === 0 && this.revenueLtdValidationViewModel.actualRevenue === 0) {
            this.revenueLtdValidationViewModel.status = TecoPrevalidationStatus.Invalid;
        }
        else if (this.revenueLtdValidationViewModel.plannedRevenue > this.revenueLtdValidationViewModel.actualRevenue) {
            this.revenueLtdValidationViewModel.status = TecoPrevalidationStatus.Failed;
        }
        else {
            this.revenueLtdValidationViewModel.status = TecoPrevalidationStatus.Passed;
        }
    }

    /**
     * Initiates unbilled balance validation     
     * @param actualDetails 
     * @param contractBaseLineDetails 
     */
    private inititateUnbilledBalanceValidation(actualDetails: IEntityFinancialSummary, contractBaseLineDetails: IEntityFinancialSummary): void {        
        this.unbilledBalanceViewModel = {
            contractualRevenue: this.getTotalContractRevenue(contractBaseLineDetails),
            recognizedRevenue: this.getRecognisedRevenue(actualDetails),
            isActive: true,
            isMandatory: false,
            isEditMode: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Evaluating,
            title: this.recognizedRevVscontracrRevValidationTitle
        };
        if (this.unbilledBalanceViewModel.contractualRevenue === 0 && this.unbilledBalanceViewModel.recognizedRevenue === 0) {
            this.unbilledBalanceViewModel.status = TecoPrevalidationStatus.Invalid;
        }
        else if (this.isRecognizedRevenueWithinThresholdOfContractRevenue(this.unbilledBalanceViewModel.recognizedRevenue, this.unbilledBalanceViewModel.contractualRevenue)) {
            this.unbilledBalanceViewModel.status = TecoPrevalidationStatus.Passed;
        }        
        else {
            this.unbilledBalanceViewModel.status = TecoPrevalidationStatus.Failed;
        }

    }

    /**
     * Initiates unearned balance validation
     * @param invoices       
     * @param actualDetails 
     */
    private initiateUnearnedBalanceValidation(invoices: IInvoiceItemModel[], actualDetails: IEntityFinancialSummary): void {        
        this.unearnedBalanceViewModel = {
            invoicedRevenue: this.getTotalInvoicedAmount(invoices),
            recognizedRevenue: this.getRecognisedRevenue(actualDetails),
            isActive: true,
            isMandatory: false,
            isEditMode: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Evaluating,
            title: this.recognizedRevVsInvoicedRevValidationTitle
        };
        if (this.unearnedBalanceViewModel.invoicedRevenue === 0 && this.unearnedBalanceViewModel.recognizedRevenue === 0) {
            this.unearnedBalanceViewModel.status = TecoPrevalidationStatus.Invalid;
            
        }
        else if (this.isRecognizedRevenueWithinThresholdOfInvoicedRevenue(this.unearnedBalanceViewModel.recognizedRevenue, this.unearnedBalanceViewModel.invoicedRevenue)) {
            this.unearnedBalanceViewModel.status = TecoPrevalidationStatus.Passed;
        }        
        else {
            this.unearnedBalanceViewModel.status = TecoPrevalidationStatus.Failed;
        }
    }

    /**
     * Initiates Engagement POCC validation
     * @param currentBaseLineDetails 
     * @param actualDetails 
     */
    private initiateEngagementPoccValidation(currentBaseLineDetails: IEntityFinancialSummary, actualDetails: IEntityFinancialSummary): void {
        this.engagementPoccValidationViewModel = {
            isActive: true,
            pocc: this.getPOCC(currentBaseLineDetails, actualDetails),
            isMandatory: false,
            comment: undefined,
            status: TecoPrevalidationStatus.Evaluating,
            isEditMode: false,
            title: this.poccValidationTitle
        };
        if (this.engagementPoccValidationViewModel.pocc >= this.tecoPrevalidationConfiguration.poccThresholdPercentage) {
            this.engagementPoccValidationViewModel.status = TecoPrevalidationStatus.Passed;
        }
        else {
            this.engagementPoccValidationViewModel.status = TecoPrevalidationStatus.Failed;

        }

    }

    /**
     * Loads all project finances based on ist of project Id
     * @param projectIds 
     */
    private initialteProjectFinancesLoad(projectIds: string[]): void {
        projectIds.forEach((projectId) => {
            const projectFinancial$ = this.store.select(getEntireFinancialDetailsV2(projectId));
            projectFinancial$.pipe(untilDestroyed(this))
                .subscribe((projectFinancial: IFinancialDetailsV2State) => {
                    if (!projectFinancial.loaded && !projectFinancial.loading) {
                        this.store.dispatch(new LoadFinancialDetailsV2(projectId));
                    }
                    if (projectFinancial.loaded) {
                        const cfp = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancial.financialDetails, FinancialType.CurrentFinancialPlan);
                        const actuals_current = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancial.financialDetails, FinancialType.ActualsCurrent);
                        this.addToProjectFinancialsViewModel({
                            projectId: projectFinancial.financialDetails.wbsId,
                            cfpLabor: cfp ? cfp.labor : 0,
                            actualLabor: actuals_current ? actuals_current.labor : 0
                        });
                    }
                });
        });
    }

    /**
     * Add to the ProjectFinancialViewModel
     * @param projectFinancial 
     */
    private addToProjectFinancialsViewModel(projectFinancial: IProjectFinancial): void {

        if (projectFinancial.actualLabor === projectFinancial.cfpLabor && projectFinancial.actualLabor > 0) {
            if (this.projectLaborConsumptionViewModel.passedProjects.filter((s) => s.projectId === projectFinancial.projectId).length === 0) {
                this.projectLaborConsumptionViewModel.passedProjects.push(projectFinancial);
            }
        }
        else {
            if (this.projectLaborConsumptionViewModel.failedProjects.filter((s) => s.projectId === projectFinancial.projectId).length === 0) {
                this.projectLaborConsumptionViewModel.failedProjects.push(projectFinancial);
            }
        }


    }

    /**
     * Calculate Recognized Revenue
     * @param actualDetails 
     * @param pocc 
     */
    private getRecognisedRevenue(actualDetails): number {
        const recognizedRevenue: number = actualDetails ? actualDetails.revenue : 0;
        return recognizedRevenue;
    }

    /**
     * Calculate POCC percentage
     * @param currentBaseLineDetails 
     * @param actualDetails 
     */
    private getPOCC(currentBaseLineDetails: IEntityFinancialSummary, actualDetails: IEntityFinancialSummary): number {
        let pocc = 0;        
        if (actualDetails && actualDetails.cost && currentBaseLineDetails && currentBaseLineDetails.cost) {
            pocc = Math.round(actualDetails.cost / currentBaseLineDetails.cost * 10000) / 100;
            pocc = pocc > 100 ? 100 : pocc;
        }
        return pocc > 100 ? 100 : pocc;
    }

    /**
     * Get total Invoice amount
     * @param invoiceList 
     */
    private getTotalInvoicedAmount(invoiceList: IInvoiceItemModel[]): number {
        let totalInvoicedAmount: number = 0;
        if (invoiceList && invoiceList.length) {
            // Calculates the total invoiced amount
            invoiceList.forEach((invoice: IInvoiceItemModel) => {
                totalInvoicedAmount += invoice.invoiceTotal;
            });
        }
        return totalInvoicedAmount;
    }

    /**
     * Get total contract revenue
     * @param contractBaseLineFinancials      
     */
    private getTotalContractRevenue(
        contractBaseLineFinancials: IEntityFinancialSummary
    ): number {        
        /** Get total revenue from contract baseline financial type always */
        const totalContractAmount = contractBaseLineFinancials ? contractBaseLineFinancials.revenue : 0;
        return totalContractAmount;
    }

    /**
     * Compare if planned and actual hours are within threshold. This threshold is calculated as floor of Max value should not be greater than ceil of min value by a factor of 1 hrs
     * @param plannedHours Planned Hours from CFP
     * @param actualHours ActualHours from actuals current
     */
    private isHoursWithinThreshold(plannedHours: number, actualHours: number): boolean {
        const hourDifferenceAbs = Math.abs(actualHours - plannedHours);
        const hourDifferencePercentage = (hourDifferenceAbs / plannedHours) * 100;
        if (hourDifferencePercentage <= (100 - this.tecoPrevalidationConfiguration.costHoursActualsVsPlannedThresholdPercentage)) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Calcultes if the Recognized Rev is in passable threshold of the Contract Rev
     * @param recognizedRevenue Recognized revenue
     * @param contractRevenue Contract Revenue
     */
    private isRecognizedRevenueWithinThresholdOfContractRevenue(recognizedRevenue: number, contractRevenue: number): boolean {
        const recognizedRevenuePercentage = (recognizedRevenue / contractRevenue) * 100;
        if (recognizedRevenuePercentage >= this.tecoPrevalidationConfiguration.recognizedRevenueVsContractRevenueThresholdPercentage) {
            return true;
        }
        else {
            return false;
        }
    }

    private isRecognizedRevenueWithinThresholdOfInvoicedRevenue(recognizedRevenue: number, invoicedRevenue: number): boolean {
        const revenueDifferenceAbs = Math.abs(invoicedRevenue - recognizedRevenue);
        const revenueDifferencePercentage = (revenueDifferenceAbs / invoicedRevenue) * 100;
        if (revenueDifferencePercentage <= (100 - this.tecoPrevalidationConfiguration.recognizedRevenueVsInvoiceRevenueThresholdPercentage)) {
            return true;
        }
        else {
            return false;
        }
    }
}
