import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { BulkCreateIntEngagementStatusModalComponent } from "./bulk-create-int-engagement-status-modal.component";
import { BulkUploadInternalEngagementService } from "../../../../common/services/bulk-upload-internal-engagement.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";


@NgModule({
    declarations: [
        BulkCreateIntEngagementStatusModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        UIRouterModule.forChild(),
    ],
    providers: [
        BulkUploadInternalEngagementService,
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        BulkCreateIntEngagementStatusModalComponent,
    ],
    exports: [
        BulkCreateIntEngagementStatusModalComponent,
    ]
})
export class BulkCreateIntEngagementStatusModalModule { }
