import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { EngagementSummaryDetailsComponent } from "./engagement-summary-details.component";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { DmUserProfileModule } from "../../../../../components/shared/user-name/user-name.module";

@NgModule({
    declarations: [
        EngagementSummaryDetailsComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        DmUserProfileModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        EngagementDetailService,
        SharedFunctionsService
    ],
    entryComponents: [
        EngagementSummaryDetailsComponent,
    ],
    exports: [
        EngagementSummaryDetailsComponent,
    ]
})
export class EngagementSummaryDetailsModule { }
