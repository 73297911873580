import { Component, Inject, forwardRef, ElementRef, ViewChild, TemplateRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, AbstractControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";
import { FxpMessageService, UserInfoService, FxpConstants, ErrorSeverityLevel, FeatureUsageEvent, FxpTelemActionType, FxpTelemEventName, FxpRouteService } from "@fxp/fxpservices";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { BusinessTask, Components, FcrTabs, Feature, FinancialType, LogEventConstants, RoleShortName, RouteName, SourceConstants } from "../../common/application.constants";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { IVirtuosoUser, IRiskAndIssuesResponse, IRiskIssue, IVirtuosoApprovalStatus, IApprovalLevel, VirtuosoRole, VirtuosoApprovalStatus, VirtuosoChangeRequestStatus, IVirtuosoEngagementApproversV2, IVirtuosoApprovalStatusV2, IApprovalLevelV2 } from "../../common/services/contracts/virtuoso.contracts";
import { ICrApprover, Persona, ICrApproverRuleEngineRequest, CrRole, IEngagementFinancialPlanSummary, IReasonCode, IBillRate, IChangeRequestHeader, ICreateChangeRequest, ICrResponse, ICrAttachment, ICrResource, FvrStatus, ITask, ITphRrSummary, IApprovedFinancialsResponseV2, IProjectApprovedFinancial, IExistingDemand, IEngagementCfpCostData, ICrExpenseOutput, ICrDemandOutput, IResourceDetailsResponse, ICrUnitOutput, ICrSubconOutput, ResourceType, ResourceTypeCode } from "../../common/services/contracts/changerequest.contract";
import { ProjectService } from "../../common/services/project.service";
import { ChangeRequestService } from "../../common/services/change-request.service";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";
import { IState } from "../../store/reducers";
import { VirtuosoService } from "../../common/services/virtuoso.service";
import { combineLatest as observableCombineLatest } from "rxjs";
import { getFinancialRolesState } from "../../store/financial-roles/financial-roles.selector";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ContractType, IEngagementDetailsApiV2, ITeamDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";
import { IFcrRolesFormControlData } from "./fcr-roles-form-control/fcr-roles-form-control.component";
import { InvalidateChangeRequests } from "../../store/change-requests/change-requests.action";
import { IHoursSummary } from "../../common/services/contracts/hours-summary.contracts";
import { FinancialPlanService } from "../..//common/services/financial-plan.service";
import { IEngagementRiskReserveData, IFinancialPlanResponse } from "../../common/services/contracts/financial-plan.contracts";
import { ICostSummary } from "../../common/services/contracts/cost-summary.contracts";
import { IChangeRequestResponse, IFinancialChangeRequestDetails, IDemandDiff, IFinancialChangeRequestRoleDetail, IFcrExpensesFormControlData, IFcrUnitsFormControlData, IFcrSubconFormControlData, IFcrTotals } from "../../common/services/contracts/changerequestv2.contract";
import { getDemandsState } from "../../store/demands/demands.selector";
import { getDbDemandsState } from "../../store/db-demands/demands.selector";
import { LoadDBDemands } from "../../store/db-demands/demands.action";
import { IDemandsState } from "../../store/db-demands/demands.reducer";
import { ChangeRequestDemandService } from "../../common/services/change-request-demand.service";
import { ProgressBarStatus } from "../tiles/dm-progress-bar/dm-progress-bar.contracts";
import { IFinancialCrApprover } from "../../common/services/contracts/changerequestv2.contract";
import { AADGraphService } from "../../common/services/aad-graphapi.service";
import { DmError } from "../../common/error.constants";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { InvalidateEngagementChangeRequests } from "../../store/engagement-change-requests/engagement-change-requests.action";
import { InvalidateEngagementChangeRequestsV2 } from "../../store/engagement-change-requests-v2/engagement-change-requests-v2.action";
import { IDmTab } from "../../common/services/contracts/common.contracts";
import { getUnitRolesState } from "../../store/unit-roles/unit-roles.selector";
import { ProjectServiceV2 } from "../../common/services/project.v2.service";
import { IActualsState } from "../../store/actuals/actuals.reducer";
import { getEntireActuals } from "../../store/actuals/actuals.selector";

@Component({
    selector: "dm-financial-change-request",
    templateUrl: "./financial-change-request.html",
    styleUrls: ["./financial-change-request.scss"]
})
export class FinancialChangeRequestComponent extends DmComponentAbstract {
    @ViewChild("fcrFileUploadField", { static: false }) public fcrFileUploadField: ElementRef;
    @ViewChild("roleAutopopulatedTemplate", { static: true }) public roleAutopopulatedTemplate: TemplateRef<any>;

    public changeRequestId: number;
    public tabsContent: IDmTab[] = [];
    public engagementId: string;
    public RouteName = RouteName;
    public isProjectContext: boolean;
    public allowCrCancellation: boolean = false;
    public approvedRiskReserve: number = 0;
    public approvedFinancialsResponse: IProjectApprovedFinancial[] = [];
    public crApprovers: ICrApprover[] = [];
    public crInformers: ICrApprover[] = [];
    public engagementDetails: IEngagementDetailsApiV2;
    public existingTasks: ITask[] = [];
    public fcrFinancialSummary: IEngagementFinancialPlanSummary;
    public fcrFinancialSummaryError: string = "";
    public fcrForm: FormGroup;
    public fcrRolesFormControlData: IFcrRolesFormControlData;
    public fcrRolesFormControlDataError: string = "";
    public fcrExpensesFormControlData: IFcrExpensesFormControlData;
    public fcrExpensesFormControlDataError: string = "";
    public fcrSubconFormControlData: IFcrSubconFormControlData;
    public fcrSubconFormControlDataError: string = "";
    public fcrUnitsFormControlData: IFcrUnitsFormControlData;
    public fcrUnitsFormControlDataError: string = "";
    public fcrAttachmentLink: string;
    public fileFormatsAllowed: string;
    public FFBillRates: IBillRate[];
    public fvrContractType: ContractType;
    public hideSummary: boolean = false;
    public isComponentLoading: boolean = false;
    public isNewFcrRequest: boolean = false;
    public isFixedFee: boolean = false;
    public isVirtuosoApproverRoleMissing: boolean = false;
    public loadingMessage: string;
    public noApproversErrorMessage: string;
    public nonBill: IBillRate;
    public reasonOptions: IReasonCode[] = [];
    public resourceLocationAndRoleEditFeatureFlag: boolean;
    public resourceTypeDetailsData: IResourceDetailsResponse;
    public roleValuesFF: IFinancialRoles[] = [];
    public roleValuesTM: IFinancialRoles[] = [];
    public showFinancialSummarySection: boolean = false;
    public showCostSummarySection: boolean = false;
    public showHoursSummarySection: boolean = false;
    public showFvrDetailsSection: boolean = true;
    public showFvrRolesSection: boolean = true;
    public showRiskReserveDetailsSection: boolean = false;
    public subconCostRates: number[] = [];
    public crApproversStatusData: IFinancialCrApprover[];
    public crApproversStatusError: string;
    public showNoApproversError: boolean = false;
    public showNoPpjmError: boolean = false;
    public showVirtuosoStatusDate: boolean = false;
    public TAndMBillRates: IBillRate[];
    public totalAdditionalCost: number = 0;
    public totalAdditionalHours: number;
    public totalAdditionalRevenue: number;
    public totalDbRiskReserve: number = 0;
    public totalRevisedCfpCost: number = 0;
    public totalExistingHours: number = 0;
    public totalRiskReserve: number = 0;
    public tphRrSummary: ITphRrSummary = {
        approvedRiskReserve: 0,
        contractBaselineCost: 0,
        costImpact: 0,
        deliveryBaselineCost: 0,
        deliveryBaselineHours: 0,
        hoursImpact: 0,
        totalDeliveryBaselineRiskReserve: 0,
        totalRiskReserve: 0,
        planCurrency: ""
    };
    public uploadedFcrFileObject: any;
    public virtuosoCurrentStatusDate: Date;
    public virtuosoIssues: IRiskIssue[] = [];
    public virtuosoLink: string = "";
    public virtuosoProjectId: string = "";
    public virtuosoRisks: IRiskIssue[] = [];
    public virtuosoUsers: IVirtuosoUser[] = [];
    public populateRoles: ICrResource[] = null;
    public populateRoles2: ICrResource[] = null;
    public populateRoles3: ICrResource[] = null;
    public populateRoles4: ICrResource[] = null;
    public projectsWithPendingChangeRequests: string[] = [];
    public pendingCrMessage: string = "";

    public hoursSummary: IHoursSummary[] = [];
    public costSummary: ICostSummary[] = [];
    public financialPlanResponse: IFinancialPlanResponse;
    public fcrDetails: IFinancialChangeRequestDetails;
    public dmFcrErrorMessages = DmError.FinancialChangeRequest;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.KeyActions;
    public tileContent: ITile;
    public showLabor: boolean = true;
    public showUnits: boolean;
    public showExpenses: boolean;
    public showSubconFF: boolean;
    public ResourceType = ResourceType;
    public fcrFormControlsTotalValue: ICrResource[] = [];
    private fcrTotals: IFcrTotals;
    private feature: FeatureUsageEvent;
    private experienceResult: boolean;

    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(ProjectService) public projectService: ProjectService,
        @Inject(ChangeRequestService) public changeRequestService: ChangeRequestService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(Store) private store: Store<IState>,
        @Inject(VirtuosoService) private virtuosoService: VirtuosoService,
        @Inject(FinancialPlanService) private financialPlanService: FinancialPlanService,
        @Inject(StateService) public stateService: StateService,
        @Inject(ChangeRequestDemandService) private crDemandService: ChangeRequestDemandService,
        @Inject(AADGraphService) private graphService: AADGraphService,
        @Inject(ProjectServiceV2) private projectServiceV2: ProjectServiceV2,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
    ) {
        super(dmLogger, Components.FinancialChangeRequest);
    }

    /**
     * Helper methods to access form controls.
     *
     * @readonly
     * @type {FormArray}
     * @memberof FinancialChangeRequestComponent
     */
    public get approversFormArray(): FormArray {
        return this.fcrForm.get("fcrDetails.approvers") as FormArray;
    }
    public get requestedRolesControl(): AbstractControl {
        return this.fcrForm.get("fcrRoles.requestedRoles") as AbstractControl;
    }
    public get requestedExpensesControl(): AbstractControl {
        return this.fcrForm.get("fcrExpenses.requestedExpenses") as AbstractControl;
    }
    public get requestedSubconControl(): AbstractControl {
        return this.fcrForm.get("fcrSubcon.requestedSubcon") as AbstractControl;
    }
    public get requestedUnitsControl(): AbstractControl {
        return this.fcrForm.get("fcrUnits.requestedUnits") as AbstractControl;
    }
    public get titleControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.title") as AbstractControl;
    }
    public get reasonControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.reason") as AbstractControl;
    }
    public get executiveSummaryControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.executiveSummary") as AbstractControl;
    }
    public get businessJustificationControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.businessJustification") as AbstractControl;
    }
    public get risksControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.risks") as AbstractControl;
    }
    public get issuesControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.issues") as AbstractControl;
    }
    public get attachmentFileNameControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.attachmentFileName") as AbstractControl;
    }
    public get attachmentObjectControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.attachmentObject") as AbstractControl;
    }
    public get commentsControl(): AbstractControl {
        return this.fcrForm.get("fcrDetails.comments") as AbstractControl;
    }

    public ngOnInit(): void {
        this.configurationService.initialize().then(() => {
            this.virtuosoLink = this.configurationService.getValue<string>("virtuosoUrlLink");
            this.reasonOptions = this.configurationService.getValue<IReasonCode[]>("fcrReasonCodes");
            this.resourceLocationAndRoleEditFeatureFlag = this.configurationService.isFeatureEnabled("enableFcrResourceLocationAndRoleEdit");
            this.graphService.isUserPartOfSecurityGroup(this.sharedFunctionsService.pjmEngineeringSecurityGroupId, this.fxpUserInfoService.getCurrentUser().toLowerCase()).then((isCurrentUserPartOfSecurityGroup) => {
                this.resourceLocationAndRoleEditFeatureFlag = isCurrentUserPartOfSecurityGroup;
            });
        });

        if (this.stateService.params) {
            const params = this.stateService.params;
            this.changeRequestId = params.crId ? this.stateService.params.crId : undefined;
            if (this.changeRequestId) {
                this.startBusinessProcessTelemetry(BusinessTask.ViewChangeRequest);
            }
        }

        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.isProjectContext = projectId ? true : false;

        // Based on changeRequestId we determine if it is a view FCR request or create new FCR request
        this.isNewFcrRequest = !this.changeRequestId;
        this.tileContent = {
            title: "Financial Change Request - " + (this.isNewFcrRequest ? "Create" : this.changeRequestId)
        };
        if (this.isNewFcrRequest) {
            this.dmLogger.renewCorrelationId();
            this.experienceResult = false;
            this.feature = this.dmLogger.startFeatureUsageEvent(Feature.CreateFinancialChangeRequest, FxpTelemActionType.User, Feature.CreateFinancialChangeRequest, FxpTelemEventName.ButtonClick);
        }
        // Gets engagement details and sets contract type.
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        this.storeDispatchService
            .requireEngagementDetails(this.engagementId, true);

        if (!this.isNewFcrRequest) {
            engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetailsState) => {
                if (engagementDetailsState.loaded) {
                    this.engagementDetails = engagementDetailsState.engagementDetails;
                    const contractType: string = this.sharedFunctionsService.getContractType(this.engagementDetails.projects);
                    this.isFixedFee = contractType === ContractType.FixedFee;
                    this.initializeFCRViewDetails();
                    this.endBusinessProcessTelemetry(BusinessTask.ViewChangeRequest);
                }
                if (engagementDetailsState.error) {
                    this.isServerError = true;
                }
            });
        }

        if (this.isNewFcrRequest) {
            // Initializes FCR form
            this.initializeFcrForm();
            // Subscribe to user input changes in requested roles section to recalculate metrics in other sections.
            // this.subscribeToRequestedRolesChanges();
            // Gets virtuoso project id to build link and get risks and issues.
            this.getVirtuosoLink(this.engagementId);
            this.fileFormatsAllowed = this.sharedFunctionsService.supportedUploadFileFormats.join(",");

            // TODO: make this part of ngrx store
            this.changeRequestService.getResourceDetailsByEngagementId(this.engagementId).then((response) => {
                this.resourceTypeDetailsData = response;
            });

            this.changeRequestService.getPendingCrListByEngagementId(this.engagementId).then((response) => {
                if (response && response.length) {
                    this.projectsWithPendingChangeRequests = response.map((pendingCr) => pendingCr.projectId);
                }
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                if (error.status === 404) {
                    Promise.resolve([]);
                }
            });

            const cfpDemands$ = this.store.select<IDemandsState>(getDemandsState(this.engagementId));
            const financialRoles$ = this.store.select(getFinancialRolesState);
            const dbDemands$ = this.store.select<IDemandsState>(getDbDemandsState(this.engagementId));
            const unitRoles$ = this.store.select(getUnitRolesState);

            const unitDetails$ = this.store.select<IActualsState>(getEntireActuals(this.engagementId));

            this.storeDispatchService
                .requireWbsDemandsV2WithoutSchedules(this.engagementId, true)
                .requireFinancialRoles(true)
                .requireUnitRoles(true)
                .requireActuals(this.engagementId, true);

            observableCombineLatest([cfpDemands$, dbDemands$, engagementDetails$, financialRoles$, unitRoles$, unitDetails$])
                .pipe(untilDestroyed(this))
                .subscribe(([cfpDemands, dbDemands, engagementDetailsState, financialRolesState, unitRolesState, unitDetailsStates]) => {
                    if (engagementDetailsState.loaded) {
                        this.engagementDetails = engagementDetailsState.engagementDetails;
                        const contractType: string = this.sharedFunctionsService.getContractType(this.engagementDetails.projects);
                        this.isFixedFee = contractType === ContractType.FixedFee;
                    }
                    if (engagementDetailsState.loaded && financialRolesState.loaded && cfpDemands.loaded && dbDemands.loaded && unitRolesState.loaded) {
                        this.getVirtuosoApproverDataV2(this.engagementId);

                        // this.subconCostRates = this.crDemandService.getSubconCostRates(cfpDemands.wbsDemands);
                        this.subconCostRates = this.crDemandService.getSubconCostRates(dbDemands.wbsDemands);

                        // Gets cost, hour summary from financial plans by wbs id i.e required for create fcr.
                        this.projectService.getFinancialPlansByWbsId(this.engagementId).then((response) => {
                            this.financialPlanResponse = response;

                            // Get engagement Financial Plan Summary details
                            this.fcrFinancialSummary = this.financialPlanResponse.engagementFinancialPlan && this.financialPlanResponse.engagementFinancialPlan.engagementPlanDetails ? this.financialPlanService.getFinancialPlanSummary(this.financialPlanResponse.engagementFinancialPlan.engagementPlanDetails) : null;

                            // Fill TPH section details from financial summary details and call getTphRrSummary to get Risk reserve details
                            const tphRrData = {
                                ...this.tphRrSummary,
                                contractBaselineCost: this.fcrFinancialSummary.finalizedContractBaseline.cost,
                                costImpact: this.totalAdditionalCost,
                                deliveryBaselineCost: this.fcrFinancialSummary.finalizedDeliveryBaseline.cost,
                                deliveryBaselineHours: this.fcrFinancialSummary.finalizedDeliveryBaseline.hours,
                                hoursImpact: this.totalAdditionalHours,
                                planCurrency: this.fcrFinancialSummary.planCurrency
                            };

                            // Get TPH section details
                            this.tphRrSummary = this.changeRequestService.getTphRrSummary(tphRrData);

                            // call approved financials api
                            this.changeRequestService.getApprovedFinancialsV2(this.engagementId).then((engagementReserveV2: IApprovedFinancialsResponseV2) => {
                                if (engagementReserveV2.projects && engagementReserveV2.projects.length) {
                                    this.totalRiskReserve = 0;
                                    this.totalDbRiskReserve = 0;
                                    this.approvedRiskReserve = 0;
                                    this.costSummary = [];
                                    this.hoursSummary = [];

                                    // Get projects approved financials by current financial plan type.
                                    this.approvedFinancialsResponse = this.changeRequestService.getProjectsApprovedFinancialsV2ByVersion(engagementReserveV2.projects, FinancialType.CurrentFinancialPlan);
                                    const approvedContractBaseLineFinancialsResponse = this.changeRequestService.getProjectsApprovedFinancialsV2ByVersion(engagementReserveV2.projects, FinancialType.ContractBaseline);

                                    // // Calculate sum of all projects total RR and approved RR to get engagement level total RR and approved RR.
                                    // for (const projectReserve of this.approvedFinancialsResponse) {
                                    //     this.totalRiskReserve += projectReserve.totalRiskReserve;
                                    //     this.approvedRiskReserve += projectReserve.approvedRiskReserve;
                                    // }
                                    // Calculate RR from Contract Baseline 
                                    for (const projectReserve of approvedContractBaseLineFinancialsResponse) {
                                        this.totalRiskReserve += projectReserve.totalRiskReserve;
                                        this.approvedRiskReserve += projectReserve.approvedRiskReserve;
                                    }

                                    for (const projectFinancials of response.projects) {
                                        const projectApprovedFinancials = this.approvedFinancialsResponse.filter((finData) => finData.wbsId && finData.wbsId === projectFinancials.projectId);

                                        const projectDeliveryBaseLineDetails = this.financialPlanService.getProjectFinancialPlansByType(projectFinancials.projectPlanDetails, FinancialType.DeliveryBaseline);
                                        this.totalDbRiskReserve = this.totalDbRiskReserve + projectDeliveryBaseLineDetails.riskReserveAmount;
                                        // this.hoursSummary.push(this.financialPlanService.getProjectHoursSummary(projectFinancials, 0));
                                        // this.costSummary.push(this.financialPlanService.getProjectCostSummary(projectFinancials, 0, projectApprovedFinancials));
                                    }

                                    this.tphRrSummary = {
                                        ...this.tphRrSummary,
                                        approvedRiskReserve: this.approvedRiskReserve,
                                        totalDeliveryBaselineRiskReserve: this.totalDbRiskReserve,
                                        totalRiskReserve: this.totalRiskReserve
                                    };
                                }
                            }).catch((error) => {
                                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                                if (error.status === 404) {
                                    Promise.resolve([]);
                                }
                            }).then(() => {
                                // Get labor related form data by passing dbDemands,cfpDemands,engagmentDetails,financial roles response.
                                const roleFormControlData: IFcrRolesFormControlData = this.crDemandService.getFcrFormData(cfpDemands.wbsDemands, dbDemands.wbsDemands, engagementDetailsState.engagementDetails, financialRolesState.financialRoles);

                                this.fcrExpensesFormControlData = this.crDemandService.getExpensesFormData(this.resourceTypeDetailsData.expenseDetails, engagementDetailsState.engagementDetails, this.projectsWithPendingChangeRequests);
                                this.fcrSubconFormControlData = this.crDemandService.getSubconFormData(this.resourceTypeDetailsData.subconFFDetails, engagementDetailsState.engagementDetails, this.projectsWithPendingChangeRequests);
                                if (unitDetailsStates.loaded) {
                                    const filteredUnitRoles = unitRolesState.unitRoles.filter((unitRole) =>
                                        this.resourceTypeDetailsData.unitDetails && this.resourceTypeDetailsData.unitDetails.filter((unitData) => unitData.roleId.toUpperCase() === unitRole.activityCode.toUpperCase()).length);
                                    this.fcrUnitsFormControlData = this.crDemandService.getUnitsFormData(this.resourceTypeDetailsData.unitDetails, engagementDetailsState.engagementDetails, filteredUnitRoles, this.projectsWithPendingChangeRequests, unitDetailsStates.units);
                                }

                                this.determineTabs();

                                this.populateRoleFormControlData(roleFormControlData, financialRolesState.financialRoles);

                                if (this.stateService.params && this.stateService.params.projectsThatFailedThresholdCheck && this.stateService.params.projectsThatFailedThresholdCheck.length) {
                                    // do not consider projects with pending CR for autopopulation
                                    const projectsThatFailedThresholdCheck: string[] = this.stateService.params.projectsThatFailedThresholdCheck.filter((failedProjId) => !this.projectsWithPendingChangeRequests.includes(failedProjId));
                                    // let autopopLineItems: ICrResource[] = [];  TODO: combine all roles to this array
                                    this.populateRoles = [...this.crDemandService.compareDemandsBetweenDbAndCfp(dbDemands.wbsDemands, roleFormControlData, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck)];
                                    this.populateRoles2 = [...this.crDemandService.compareSubconDemandsBetweenDbAndCfp(this.resourceTypeDetailsData.subconFFDetails, this.fcrSubconFormControlData, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck)];
                                    this.populateRoles3 = [...this.crDemandService.compareExpenseDemandsBetweenDbAndCfp(this.resourceTypeDetailsData.expenseDetails, this.fcrExpensesFormControlData, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck)];
                                    this.populateRoles4 = [...this.crDemandService.compareUnitDemandsBetweenDbAndCfp(this.resourceTypeDetailsData.unitDetails, this.fcrUnitsFormControlData, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck)];

                                    if (this.stateService.params.demandDiffData && this.stateService.params.demandDiffData.length) {
                                        const demandDiffData: IDemandDiff[] = this.stateService.params.demandDiffData;

                                        // Gets unsaved edit demands from plan and forecast with use of demand data from plan and forecast, projects that failed threshold.
                                        const rolesWithEdits = this.crDemandService.determineDemandDiff(demandDiffData, roleFormControlData, financialRolesState.financialRoles, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck);
                                        const subconsWithEdits = this.crDemandService.determineSubconDiffs(demandDiffData, this.fcrSubconFormControlData, financialRolesState.financialRoles, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck);
                                        const expensesWithEdits = this.crDemandService.determineExpenseDiffs(demandDiffData, this.fcrExpensesFormControlData, financialRolesState.financialRoles, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck);
                                        const unitsWithEdits = this.crDemandService.determineUnitsDemandDiff(demandDiffData, this.fcrUnitsFormControlData, this.fcrUnitsFormControlData.roles, engagementDetailsState.engagementDetails, projectsThatFailedThresholdCheck);

                                        // Removing any duplicates btw unsaved edit demands from plan and forecast and autoPopulateRoles(out of sync changes bw cfp and db)
                                        this.populateRoles = [...this.crDemandService.removeLaborAutopopulateDuplicateData(this.populateRoles as ICrDemandOutput[], rolesWithEdits)];  // TODO: will have this take in ICrResource
                                        this.populateRoles2 = [...this.crDemandService.removeAutopopulateDuplicateData(this.populateRoles2 as ICrSubconOutput[], subconsWithEdits)];
                                        this.populateRoles3 = [...this.crDemandService.removeAutopopulateDuplicateData(this.populateRoles3 as ICrExpenseOutput[], expensesWithEdits)];
                                        this.populateRoles4 = [...this.crDemandService.removeUnitAutopopulateDuplicateData(this.populateRoles4 as ICrUnitOutput[], unitsWithEdits)];
                                    }

                                    if ((this.populateRoles && this.populateRoles.length) || (this.populateRoles2 && this.populateRoles2.length) || (this.populateRoles3 && this.populateRoles3.length) || (this.populateRoles4 && this.populateRoles4.length)) {
                                        // Gets contract type
                                        this.determineContractTypeForAutopopulatedRoles([...this.populateRoles, ...this.populateRoles2, ...this.populateRoles3, ...this.populateRoles4]);

                                        // Recalculate all fields in all sections based on auto populated roles.
                                        this.fcrFormControlsTotalValue = [...this.populateRoles, ...this.populateRoles2, ...this.populateRoles3, ...this.populateRoles4];
                                        this.recalculate([...this.populateRoles, ...this.populateRoles2, ...this.populateRoles3, ...this.populateRoles4]);
                                        this.reCalculateCostSummary([...this.populateRoles, ...this.populateRoles2, ...this.populateRoles3, ...this.populateRoles4] as any);
                                        this.validateTotalAdditionalCost();

                                        // determine approvers for populated roles
                                        this.determineApproversV2();
                                    }

                                    this.pendingCrMessage = `${this.sharedFunctionsService.getWordPluralWithS("Project", this.projectsWithPendingChangeRequests.length, false)} ${this.sharedFunctionsService.createCommaSeparatedString(this.projectsWithPendingChangeRequests, true)}`;
                                    // Show the popup
                                    this.modalService.open(this.roleAutopopulatedTemplate, {
                                        backdrop: "static",
                                        centered: true,
                                        keyboard: true,
                                        windowClass: "dm-modal in active fcr-autopopulated-modal"
                                    });
                                }

                                this.subscribeToRequestedRolesChanges();
                            });
                        }).catch((error) => {
                            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                            this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                            this.fcrFinancialSummaryError = error;
                            this.fcrRolesFormControlDataError = error;
                        });
                    }
                    if (cfpDemands.error || dbDemands.error || engagementDetailsState.error || financialRolesState.error) {
                        this.isServerError = true;
                    }
                });
        }
        this.storeDispatchService.load();
        this.store.dispatch(new LoadDBDemands(this.engagementId));
    }

    /**
     * Toggles btw resource types
     *
     * @param {IDmTab} activeTab
     * @memberof FinancialChangeRequestComponent
     */
    public toggleTab(activeTab: IDmTab): void {
        for (const tab of this.tabsContent) {
            tab.isActive = false;
        }
        activeTab.isActive = true;
        switch (activeTab.id) {
            case ResourceType.Labor: {
                this.showLabor = true;
                this.showUnits = false;
                this.showExpenses = false;
                this.showSubconFF = false;
                break;
            }
            case ResourceType.Unit: {
                this.showLabor = false;
                this.showUnits = true;
                this.showExpenses = false;
                this.showSubconFF = false;
                break;
            }
            case ResourceType.Expense: {
                this.showLabor = false;
                this.showUnits = false;
                this.showExpenses = true;
                this.showSubconFF = false;
                break;
            }
            case ResourceType.SubconFF: {
                this.showLabor = false;
                this.showUnits = false;
                this.showExpenses = false;
                this.showSubconFF = true;
                break;
            }
        }
    }

    /**
     * Navigates to previous screen which can be the financials page or
     * plan and forecast page.
     *
     * @memberof FinancialChangeRequestComponent
     */
    public navigateToPreviousScreen(): void {
        if (this.isNewFcrRequest) {
            this.dmLogger.endFeatureUsageEvent(SourceConstants.Component.FinancialChangeRequest, SourceConstants.Method.SubmitFcr, this.feature, this.experienceResult);
        }

        if (this.stateService.params.initializedFromNewForecastExperience) {
            if (this.isProjectContext) {
                this.fxpRouteService.navigatetoSpecificState(RouteName.NewProjectForecast,
                    {
                        wbsId: this.sharedFunctionsService.getCurrentEntityId()
                    },
                    { reload: RouteName.NewProjectForecast }
                );
            }
            else {
                this.fxpRouteService.navigatetoSpecificState(RouteName.NewEngagementForecast,
                    {
                        wbsId: this.sharedFunctionsService.getCurrentEntityId()
                    },
                    { reload: RouteName.NewEngagementForecast }
                );
            }
        }
        else {
            if (this.stateService.params.initializedFromPlanAndForecast) {
                this.stateService.go(this.isProjectContext ? RouteName.ProjectPlanForecast : RouteName.EngagementPlanForecast);
            } else {
                this.stateService.go(this.isProjectContext ? RouteName.ProjectFinancials : RouteName.EngagementFinancials);
            }
        }
    }

    /**
     * Submit a Financial Change Request to be saved and processed.
     *
     * @memberof FinancialChangeRequestComponent
     */
    public submitFcr(): void {
        // Validate all roles have been saved before submission
        const unsavedRoles = [
            ...this.requestedRolesControl.value.filter((roleRequest: ICrResource) => roleRequest.editModeEnabled === true),
            ...this.requestedExpensesControl.value.filter((roleRequest: ICrResource) => roleRequest.editModeEnabled === true),
            ...this.requestedSubconControl.value.filter((roleRequest: ICrResource) => roleRequest.editModeEnabled === true),
            ...this.requestedUnitsControl.value.filter((roleRequest: ICrResource) => roleRequest.editModeEnabled === true)
        ];

        if (unsavedRoles.length > 0) {
            this.requestedRolesControl.setErrors({ allRolesNotSaved: true });
            return;
        } else {
            this.requestedRolesControl.setErrors(null);
        }

        this.isComponentLoading = true;

        const laborRequestDetails = this.crDemandService.getFcrLaborRequestDetails(this.fcrFinancialSummary, this.requestedRolesControl, this.fcrRolesFormControlData);
        const subconRequestDetails = this.crDemandService.getFcrSubconRequestDetails(this.fcrFinancialSummary, this.requestedSubconControl);
        const expenseRequestDetails = this.crDemandService.getFcrExpenseRequestDetails(this.fcrFinancialSummary, this.requestedExpensesControl);
        const unitRequestDetails = this.crDemandService.getFcrUnitRequestDetails(this.fcrFinancialSummary, this.requestedUnitsControl);
        const crRoleRequestDetails = [...laborRequestDetails.resourceRequestDetails, ...unitRequestDetails.resourceRequestDetails, ...subconRequestDetails.resourceRequestDetails, ...expenseRequestDetails.resourceRequestDetails];

        const hours = laborRequestDetails.totalAdditionalHours;
        const cost = laborRequestDetails.totalAdditionalCost + subconRequestDetails.totalAdditionalCost + expenseRequestDetails.totalAdditionalCost + unitRequestDetails.totalAdditionalCost;

        const crDetails: IChangeRequestHeader = {
            crDescription: this.titleControl.value.trim(),
            structuralElement: this.engagementId,
            engagementId: this.engagementId,
            isFixedFee: this.fvrContractType === ContractType.FixedFee,
            requestorAlias: this.fxpUserInfoService.getCurrentUser(),
            requestedHours: parseFloat(hours.toFixed(2)),
            requestedCost: parseFloat(cost.toFixed(2)),
            planCurrency: this.fcrFinancialSummary.planCurrency,
            executiveSummary: this.executiveSummaryControl.value.trim(),
            businessJustification: this.executiveSummaryControl.value.trim(),
            reason: this.reasonControl.value,
            risks: [],
            issues: [],
            comments: this.commentsControl.value
        };

        this.financialPlanResponse = this.financialPlanService.addApprovedFinancialDataToFinancialPlan(this.financialPlanResponse, this.approvedFinancialsResponse);

        const createChangeRequestPayload: ICreateChangeRequest = {
            changeRequestDetailList: crRoleRequestDetails,
            changeRequestHeader: crDetails,
            changeRequestApprovers: this.getChangeRequestApproversV2(),
            attachment: this.attachmentObjectControl.value as ICrAttachment,
            financialPlanDetails: this.financialPlanResponse as IFinancialPlanResponse
        };

        this.loadingMessage = "Creating Financial Change Request";
        this.changeRequestService.createFinancialChangeRequestV2(createChangeRequestPayload).then((crResponse: ICrResponse) => {
            this.experienceResult = true;
            this.dmLogger.logEvent(SourceConstants.Component.FinancialChangeRequest, SourceConstants.Method.SubmitFcr, LogEventConstants.FinancialChangeRequestCreated);
            this.store.dispatch(new InvalidateEngagementChangeRequestsV2(this.engagementId));
            this.store.dispatch(new InvalidateChangeRequests());
            this.fxpMessageService.addMessage("Created Financial Change Request : " + crResponse.changeRequestNumber, this.FXP_CONSTANTS.messageType.success, false);
            this.isComponentLoading = false;
            this.navigateToPreviousScreen();
        }).catch((error) => {
            this.isComponentLoading = false;
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.SubmitFcr, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        });
    }

    /**
     * Update the contract type based on the roles being requested.
     *
     * @param {ContractType} type
     * @memberof FinancialChangeRequestComponent
     */
    public updateRequestedContractType(type: ContractType): void {
        this.fvrContractType = type;
        this.isFixedFee = type === ContractType.FixedFee;
    }

    /**
     * Gets the uploaded NBUE file documentation object
     *
     * @param {Event} fileInput
     */
    public uploadFcrFileBtnClick(fileInput: Event): void {
        const fileObject = (fileInput.target as HTMLInputElement).files[0];
        this.uploadedFcrFileObject = fileObject;

        const formData = new FormData();
        formData.append("file", this.uploadedFcrFileObject);

        this.attachmentFileNameControl.setValue("Uploading...");

        this.changeRequestService.uploadFcrAttachment(this.uploadedFcrFileObject ? formData : null).then((response: ICrAttachment) => {
            this.attachmentFileNameControl.setValue(response.fileName);
            this.attachmentObjectControl.setValue(response);
            document.getElementById("uploadedFileMsg").innerHTML = response.fileName + "file uploaded successfully";
        });
    }

    /**
     * Triggers click event containing file object
     *
     */
    public uploadFcrFileBtnClicked(): void {
        this.fcrFileUploadField.nativeElement.click();
        if (this.attachmentFileNameControl.value) {
            document.getElementById("uploadedFileMsg").innerHTML = this.attachmentFileNameControl.value[0] + "file uploaded successfully";
        }
    }

    /**
     * Determine approvers based on role requests save or delete
     *
     * @memberof FinancialChangeRequestComponent
     */
    public roleRequestSavedOrDeleted(): void {
        this.showCostSummarySection = true;
        this.showFinancialSummarySection = true;
        this.showHoursSummarySection = true;
        this.showRiskReserveDetailsSection = true;
        this.fcrFormControlsTotalValue = [
            ...this.requestedRolesControl.value,
            ...this.requestedExpensesControl.value,
            ...this.requestedSubconControl.value,
            ...this.requestedUnitsControl.value
        ];

        if (this.requestedRolesControl.value.length > 0 || this.requestedExpensesControl.value.length > 0 || this.requestedSubconControl.value.length > 0 || this.requestedUnitsControl.value.length > 0) {
            this.determineApproversV2();
        } else {
            this.approversFormArray.clear();
            this.crApprovers = [];
            this.crInformers = [];
            this.fvrContractType = undefined;  // reset fvr contract type
        }
    }

    /**
     * Allows to cancel cr if user is allowed
     *
     * @memberof FinancialChangeRequestComponent
     */
    public cancelCr(): void {
        this.isComponentLoading = true;
        this.loadingMessage = "Canceling Financial Change Request";
        this.changeRequestService.updateFinancialChangeRequestStatus(this.changeRequestId.toString(), this.engagementId, FvrStatus.Cancelled).then(() => {
            this.store.dispatch(new InvalidateEngagementChangeRequests(this.engagementId));
            this.store.dispatch(new InvalidateChangeRequests());
            const message = "Canceled Financial Change Request : " + this.changeRequestId;

            this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.success, false);
            this.stateService.go(this.isProjectContext ? RouteName.ProjectFinancials : RouteName.EngagementFinancials);
        }).catch((error) => {
            const message = "Error while cancelling Financial Change Request : " + this.changeRequestId;
            this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.error, false);

            this.isComponentLoading = false;
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.CancelCr, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
        });
    }

    /**
     * Get Virtuoso Approver data for a given engagement ID. This is updated V2 call which returns
     * level heirarchy and only one user/approver per role/level.
     *
     * @param {string} engagementId
     * @memberof LaborRequestModalComponent
     */
    public getVirtuosoApproverDataV2(engagementId: string, refreshFvrApprovers: boolean = false): void {
        this.virtuosoService.getVirtuosoApproversV2(engagementId).then((virtuosoUsers: IVirtuosoEngagementApproversV2) => {
            this.virtuosoUsers = [...this.virtuosoService.getApproversFromVirtuosoResponseV2(virtuosoUsers)];

            // check here if there are any virtuoso users with a missing role
            this.isVirtuosoApproverRoleMissing = this.virtuosoUsers.filter((user) => !user.roleName).length > 0;

            // add lead project manager from SAP data
            if (this.engagementDetails) {
                const engagementPjm = this.engagementDetails.teamStructure.filter((teamMember: ITeamDetailsV2) => teamMember.role === "PPJM");

                if (!engagementPjm.length) {
                    this.showNoPpjmError = true;
                } else {
                    this.showNoPpjmError = false;
                }

                const updatedApprovers = this.virtuosoUsers.map((user: IVirtuosoUser) => {
                    if (user.roleName === Persona.LeadProjectManager && engagementPjm.length) {
                        return { ...user, UserAlias: engagementPjm[0].alias, UserName: engagementPjm[0].name };
                    } else {
                        return user;
                    }
                });

                this.virtuosoUsers = [...updatedApprovers];
            }

            if (refreshFvrApprovers) {
                this.determineApproversV2();
            }
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.GetVirtuosoApproverDataV2, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            if (error.status === 404) {
                this.noApproversErrorMessage = DmError.FinancialChangeRequest.PleaseEnsureEngagementDataIsCapturedInVirtuoso;
                this.showNoApproversError = true;
                Promise.resolve([]);
            }
        });
    }
    /**
     * Will render the List of approvvers based in contractType, costImpact, grossCostOverrun and totalAdditionalCost
     * @param listofApprovers ICrApprover[]
     */

    private renderApprovers(listofApprovers: ICrApprover[]): void {
        if (this.fvrContractType === ContractType.FixedFee) {
            if (this.tphRrSummary.costImpact > 0 && this.tphRrSummary.grossCostOverrun > 0) {
                this.crApprovers = listofApprovers;
            } else {
                this.crApprovers = listofApprovers.filter((approver) => approver.persona === Persona.LeadProjectManager);
            }
        } else if (this.fvrContractType === ContractType.TimeAndMaterial) {
            if (this.totalAdditionalCost > 0) {
                this.crApprovers = listofApprovers;
            } else {
                this.crApprovers = listofApprovers.filter((approver) => approver.persona === Persona.LeadProjectManager);
            }
        }
    }
    /**
     * Determine approvers based on current role additions/adjustments by making call
     * to approver rule engine API.
     *
     * @param {IVirtuosoUser[]} currentApprovers
     * @memberof FinancialChangeRequestComponent
     */
    private determineApproversV2(): void {
        if (this.fvrContractType) {
            const invalidApprovers: string[] = [];

            const varianceRequest: ICrApproverRuleEngineRequest = {
                engagementType: this.projectService.getEngagementTypeFromUserStatusCode(this.engagementDetails),
                isFixedFee: this.fvrContractType === ContractType.FixedFee ? true : false,
                requestedHours: this.totalAdditionalHours,
                contractBaseLineHours: this.fcrFinancialSummary.finalizedContractBaseline.hours,
                deliveryBaseLineHours: this.fcrFinancialSummary.finalizedDeliveryBaseline.hours,
                requestedCost: this.totalAdditionalCost,
                contractBaseLineCost: this.fcrFinancialSummary.finalizedContractBaseline.cost,
                deliveryBaseLineCost: this.fcrFinancialSummary.finalizedDeliveryBaseline.cost,
                totalRiskReserveAmount: this.totalRiskReserve,
                remainingRiskReserveAmount: this.totalDbRiskReserve,
                engagementDomain: this.engagementDetails.primaryDomain
            };

            this.changeRequestService.getCrApproversFromRuleEngine(varianceRequest).then((response: ICrApprover[]) => {
                if (response) {
                    // populate informers
                    const informers: ICrApprover[] = response.filter((informer: ICrApprover) => informer.role === CrRole.Informer);
                    this.crInformers = this.populateVirtuosoInformers(informers);

                    let approvers: ICrApprover[];
                    if (!this.isVirtuosoApproverRoleMissing) {
                        approvers = response.filter((approver: ICrApprover) => approver.role === CrRole.Approver && this.virtuosoUsers.some((vUser) => vUser.roleName === approver.persona));
                    } else {
                        approvers = response.filter((approver: ICrApprover) => approver.role === CrRole.Approver);
                    }

                    // Sort approvers array by level
                    approvers.sort((a, b) => {
                        if (a.level && b.level) {
                            return +a.level - +b.level;
                        }
                        return 0;
                    });
                    this.approversFormArray.clear();

                    // populate approver options for dropdown, new changes allow for only 1
                    for (const approver of approvers) {
                        const filteredApprover = this.virtuosoUsers.filter((virtuosoApprover: IVirtuosoUser) => virtuosoApprover.roleName === approver.persona)[0];

                        // If there is a persona that already has a selected approver, preserve the approver
                        if (filteredApprover && filteredApprover.roleName && filteredApprover.UserAlias) {
                            approver.approverList = [filteredApprover];
                            this.approversFormArray.push(new FormControl(filteredApprover, Validators.required));
                        } else {
                            const isFcrFixedFee: boolean = this.fvrContractType === ContractType.FixedFee;
                            const invalidApprover = this.virtuosoUsers.filter((virtuosoApprover: IVirtuosoUser) => {
                                // if fcr type is T&M (not FixedFee) level 1 approver (lead PJM) should be ignored
                                if (isFcrFixedFee) {
                                    return virtuosoApprover.level === approver.level;
                                } else {
                                    return virtuosoApprover.level === (approver.level + 1);
                                }
                            })[0];
                            invalidApprovers.push(`${invalidApprover && invalidApprover.UserName ? invalidApprover.UserName : ""} (${approver && approver.persona ? approver.persona : ""})`);
                        }
                    }

                    // only show the approvers error for special case for null role approver (not part of Virtuoso project team)
                    if (invalidApprovers.length) {
                        this.noApproversErrorMessage = DmError.FinancialChangeRequest.ApproverNotPartOfTeam + ` ${invalidApprovers.join(", ")}. ` + DmError.FinancialChangeRequest.PleaseAddUserInVirtuoso;
                        this.showNoApproversError = true;
                        const listofApprovers: ICrApprover[] = approvers.filter((approver) => approver.approverList && approver.approverList.length);
                        this.renderApprovers(listofApprovers);
                    } else {
                        this.showNoApproversError = false;
                        this.renderApprovers(approvers);
                    }

                    // additional handling where Virtuoso returns invalid hierarchy with only Lead PJM should stop and show error
                    if (this.approversFormArray.length < 1) {
                        this.noApproversErrorMessage = DmError.FinancialChangeRequest.ThereAreNoApproversInVirtuoso;
                        this.showNoApproversError = true;
                    }
                }
            });
        }
    }

    /**
     * Get project ID from Virtuoso to build link
     *
     * @param {string} engagementId
     * @memberof LaborRequestModalComponent
     */
    private getVirtuosoLink(engagementId: string): void {
        this.virtuosoService.getVirtuosoLinkId(engagementId).then((response: string) => {
            this.virtuosoProjectId = response;
            this.virtuosoLink = `${this.virtuosoLink}/ProjectTeam?ProjectId=${response}`;
            this.getVirtuosoRisksAndIssues(response);
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.GetVirtuosoLink, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            if (error.status === 404) {
                Promise.resolve();
            }
        });
    }

    /**
     * Get risks and issues for a given Virtuoso project ID.
     *
     * @param {string} virtuosoProjectId
     * @memberof FinancialChangeRequestComponent
     */
    private getVirtuosoRisksAndIssues(virtuosoProjectId: string): void {
        this.virtuosoService.getEngagementRisksIssues(virtuosoProjectId).then((response: IRiskAndIssuesResponse) => {
            this.virtuosoRisks = response.risks.data.map((data: IRiskIssue) => {
                return { id: data.id, title: data.title };
            });
            this.virtuosoIssues = response.issues.data.map((data: IRiskIssue) => {
                return { id: data.id, title: data.title };
            });
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.GetVirtuosoRisksAndIssues, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            if (error.status === 404) {
                Promise.resolve();
            }
        });
    }

    /**
     * Subscribe to value changes on requested roles form control.
     *
     * @memberof FinancialChangeRequestComponent
     */
    private subscribeToRequestedRolesChanges(): void {
        this.requestedRolesControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val: ICrDemandOutput[]) => {
            const valueChanges = [...val, ...this.requestedExpensesControl && this.requestedExpensesControl.value, ...this.requestedUnitsControl && this.requestedUnitsControl.value, ...this.requestedSubconControl && this.requestedSubconControl.value];
            this.recalculate(valueChanges);
            this.reCalculateCostSummary(valueChanges);
            this.validateTotalAdditionalCost();
        });
        this.requestedSubconControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val: ICrSubconOutput[]) => {
            const valueChanges = [...val, ...this.requestedExpensesControl && this.requestedExpensesControl.value, ...this.requestedRolesControl && this.requestedRolesControl.value, ...this.requestedUnitsControl && this.requestedUnitsControl.value];
            this.recalculate(valueChanges);
            this.reCalculateCostSummary(valueChanges);
            this.validateTotalAdditionalCost();
        });
        this.requestedExpensesControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val: ICrExpenseOutput[]) => {
            const valueChanges = [...val, ...this.requestedUnitsControl && this.requestedUnitsControl.value, ...this.requestedRolesControl && this.requestedRolesControl.value, ...this.requestedSubconControl && this.requestedSubconControl.value];
            this.recalculate(valueChanges);
            this.reCalculateCostSummary(valueChanges);
            this.validateTotalAdditionalCost();
        });
        this.requestedUnitsControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val: ICrSubconOutput[]) => {
            const valueChanges = [...val, ...this.requestedExpensesControl && this.requestedExpensesControl.value, ...this.requestedRolesControl && this.requestedRolesControl.value, ...this.requestedSubconControl && this.requestedSubconControl.value];
            this.recalculate(valueChanges);
            this.reCalculateCostSummary(valueChanges);
            this.validateTotalAdditionalCost();
        });
    }

    /**
     * Validates total additional cost number of digits
     *
     * @private
     * @memberof FinancialChangeRequestComponent
     */
    private validateTotalAdditionalCost(): void {
        if (!this.sharedFunctionsService.validateCrNumberValue(this.totalAdditionalCost)) {
            this.requestedRolesControl.setErrors({ additionalCostMaxValue: true });
        } else {
            this.requestedRolesControl.setErrors(null);
        }
    }

    private reCalculateCostSummary(resourceRequests: ICrResource[]) {
        for (const project of this.financialPlanResponse.projects) {
            if (project) {
                const filteredRows: ICrResource[] = [];
                const filteredResourceRequests = resourceRequests.filter((m) => m.currentState && m.currentState.assignedTask && m.currentState.assignedTask.projectId === project.projectId);
                if (filteredResourceRequests && filteredResourceRequests.length) {
                    for (const row of filteredResourceRequests) {
                        let cummAdditionalCost = 0;
                        let cummAdditionalHours = 0;
                        if (row && row.currentState && row.currentState.assignedTask && row.currentState.assignedTask.projectId) {
                            filteredRows.push(row);
                            filteredRows.forEach((m: ICrUnitOutput | ICrExpenseOutput | ICrSubconOutput | ICrDemandOutput) => {
                                if (m && m.currentState) {
                                    if (m.resourceType === ResourceType.Labor) {
                                        const laborRequest = m as ICrDemandOutput;
                                        const existingHours = laborRequest.currentState.existingDemand ? laborRequest.currentState.existingDemand.planHours : 0;
                                        const cfpCost = m.isDbOnlyDemand ? 0 : laborRequest.currentState.blendedCostRate * (existingHours + +laborRequest.currentState.hours);
                                        const additionalCost = cfpCost - (laborRequest.currentState.existingDemand && laborRequest.currentState.existingDemand.dbCost ? laborRequest.currentState.existingDemand.dbCost : 0);

                                        if (laborRequest.isAutoPopulated && !laborRequest.isPnfEdit) { // any hours difference in autopopulated roles for reconciliation should be part of hours impact for approval
                                            cummAdditionalHours = cummAdditionalHours + (laborRequest.currentState.existingHours - (laborRequest.currentState.existingDemand ? laborRequest.currentState.existingDemand.dbHours : 0));
                                        } else {
                                            cummAdditionalHours = cummAdditionalHours + laborRequest.currentState.hours;
                                        }

                                        cummAdditionalCost = cummAdditionalCost + additionalCost;
                                    }
                                    else if (m.resourceType === ResourceType.Unit) {
                                        const unitRequest = m as ICrUnitOutput;
                                        const existingUnits = unitRequest.currentState.existingResource ? +unitRequest.currentState.existingResource.plannedHours : 0;
                                        const cfpCost = unitRequest.isDbOnlyDemand ? 0 : +unitRequest.currentState.costRate * (existingUnits + +unitRequest.currentState.newUnits);
                                        const additionalCost = cfpCost - (unitRequest.currentState.existingResource && unitRequest.currentState.existingResource.dbCost ? unitRequest.currentState.existingResource.dbCost : 0);
                                        cummAdditionalCost = cummAdditionalCost + additionalCost;
                                    }
                                    else if (m.resourceType === ResourceType.Expense) {
                                        const expenseRequest = m as ICrExpenseOutput;
                                        const existingCost = expenseRequest.currentState.existingResource ? +expenseRequest.currentState.existingResource.plannedCost : 0;
                                        const cfpCost = expenseRequest.isDbOnlyDemand ? 0 : existingCost + +expenseRequest.currentState.newPlannedCost;
                                        const additionalCost = cfpCost - (expenseRequest.currentState.existingResource && expenseRequest.currentState.existingResource.dbCost ? +expenseRequest.currentState.existingResource.dbCost : 0);
                                        cummAdditionalCost = cummAdditionalCost + additionalCost;
                                    }
                                    else if (m.resourceType === ResourceType.SubconFF) {
                                        const subconRequest = m as ICrSubconOutput;
                                        const existingCost = subconRequest.currentState.existingResource ? +subconRequest.currentState.existingResource.existingCost : 0;
                                        const cfpCost = subconRequest.isDbOnlyDemand ? 0 : existingCost + +subconRequest.currentState.newPlannedCost;
                                        const additionalCost = cfpCost - (subconRequest.currentState.existingResource && subconRequest.currentState.existingResource.dbCost ? +subconRequest.currentState.existingResource.dbCost : 0);
                                        cummAdditionalCost = cummAdditionalCost + additionalCost;
                                    }
                                }
                            });

                            const changedHourSummaryItem = this.financialPlanService.getProjectHoursSummary(project, cummAdditionalHours);
                            this.sharedFunctionsService.addOrReplaceItem(changedHourSummaryItem, this.hoursSummary, "projectId");
                            const changedCostSummaryItem = this.financialPlanService.getProjectCostSummary(project, cummAdditionalCost, this.approvedFinancialsResponse);
                            this.sharedFunctionsService.addOrReplaceItem(changedCostSummaryItem, this.costSummary, "projectId");
                        }

                    }
                }
                else {

                    const projectIds = resourceRequests.map((r) => r.currentState && r.currentState.assignedTask && r.currentState.assignedTask.projectId);
                    this.costSummary = this.costSummary.filter((c) => projectIds.indexOf(c.projectId) > -1).slice();
                    this.hoursSummary = this.hoursSummary.filter((h) => projectIds.indexOf(h.projectId) > -1).slice();
                }

            }
        }
    }

    /**
     * Recalculates cost and hour summary based on requested hours, cost
     *
     * @param {IStateItem[]} roles
     * @memberof FinancialChangeRequestComponent
     */
    private reCalculateCostHourSummary(roles: ICrDemandOutput[]): void {
        for (const project of this.financialPlanResponse.projects) {
            if (project) {
                const filteredRows: ICrDemandOutput[] = [];
                const filteredRoles = roles.filter((m) => m.currentState && m.currentState.assignedTask && m.currentState.assignedTask.projectId === project.projectId);
                if (filteredRoles && filteredRoles.length) {
                    for (const row of filteredRoles) {
                        let cummAdditionalHours = 0;
                        let cummAdditionalCost = 0;
                        if (row && row.currentState && row.currentState.assignedTask && row.currentState.assignedTask.projectId) {
                            if (project.projectId === row.currentState.assignedTask.projectId) {
                                filteredRows.push(row);
                                filteredRows.forEach((m) => {
                                    if (m && m.currentState) {
                                        const existingHours = m.currentState.existingDemand ? m.currentState.existingDemand.planHours : 0;
                                        const cfpCost = m.isDbOnlyDemand ? 0 : m.currentState.blendedCostRate * (existingHours + +m.currentState.hours);
                                        const additionalCost = cfpCost - (m.currentState.existingDemand && m.currentState.existingDemand.dbCost ? m.currentState.existingDemand.dbCost : 0);

                                        cummAdditionalHours = cummAdditionalHours + m.currentState.hours;
                                        cummAdditionalCost = cummAdditionalCost + additionalCost;
                                    }
                                });
                                const changedHourSummaryItem = this.financialPlanService.getProjectHoursSummary(project, cummAdditionalHours);
                                this.sharedFunctionsService.addOrReplaceItem(changedHourSummaryItem, this.hoursSummary, "projectId");
                                const changedCostSummaryItem = this.financialPlanService.getProjectCostSummary(project, cummAdditionalCost, this.approvedFinancialsResponse);
                                this.sharedFunctionsService.addOrReplaceItem(changedCostSummaryItem, this.costSummary, "projectId");
                            }
                        }
                    }
                } else {
                    const cummulativeAdditionalCost = 0;
                    const changedCostSummaryItem = this.financialPlanService.getProjectCostSummary(project, cummulativeAdditionalCost, this.approvedFinancialsResponse);
                    this.sharedFunctionsService.addOrReplaceItem(changedCostSummaryItem, this.costSummary, "projectId");
                }
            }
        }
    }

    /**
     * Initializes fcr form fields with validations etc.
     *
     * @memberof FinancialChangeRequestComponent
     */
    private initializeFcrForm(): void {
        this.fcrForm = this.fb.group({
            fcrDetails: this.fb.group({
                title: ["", [Validators.required]],
                reason: ["", [Validators.required]],
                executiveSummary: ["", [Validators.required, Validators.maxLength(250)]],
                businessJustification: ["", []],
                risks: [[]],
                issues: [[]],
                attachmentObject: [],
                attachmentFileName: [],
                comments: ["", [Validators.maxLength(255)]],
                approvers: this.fb.array([], [Validators.required])
            }),
            fcrRoles: this.fb.group({
                requestedRoles: [[], [Validators.required]],
            }),
            fcrExpenses: this.fb.group({
                requestedExpenses: [[]]
            }),
            fcrSubcon: this.fb.group({
                requestedSubcon: [[]]
            }),
            fcrUnits: this.fb.group({
                requestedUnits: [[]]
            }),
        });
    }

    /**
     * Populate role form control component data
     *
     * @private
     * @param {IFcrRolesFormControlData} roleFormControlData
     * @param {IFinancialRoles[]} financialRoles
     * @memberof FinancialChangeRequestComponent
     */
    private populateRoleFormControlData(roleFormControlData: IFcrRolesFormControlData, financialRoles: IFinancialRoles[]): void {
        if (this.engagementDetails.publicSectorCode === "001") {
            roleFormControlData.roleValuesTM = this.projectService.getFinancialRolesFromExistingRole(roleFormControlData.existingResources);
            roleFormControlData.roleValuesFF = this.sharedFunctionsService.filterFinancialRoles(financialRoles, true, true);
        } else if (this.engagementDetails.publicSectorCode === "003") {
            const filteredRoles: IFinancialRoles[] = this.sharedFunctionsService.filterFinancialRoles(financialRoles, true, true);
            roleFormControlData.roleValuesTM = filteredRoles;
            roleFormControlData.roleValuesFF = filteredRoles;
        } else {
            const filteredRoles: IFinancialRoles[] = this.sharedFunctionsService.filterFinancialRoles(financialRoles, false, true);
            roleFormControlData.roleValuesTM = filteredRoles;
            roleFormControlData.roleValuesFF = filteredRoles;
        }

        if (roleFormControlData && roleFormControlData.error) {
            this.fcrRolesFormControlDataError = roleFormControlData.error;
            this.fcrRolesFormControlData = null;
        } else {
            roleFormControlData.projectsWithPendingCr = this.projectsWithPendingChangeRequests;
            roleFormControlData.existingTasks = this.crDemandService.identifyTasksWithPendingCr(roleFormControlData.existingTasks, roleFormControlData.projectsWithPendingCr);
            roleFormControlData.existingResources = this.identifyDemandsWithPendingCr(roleFormControlData.existingResources, this.engagementDetails, roleFormControlData.projectsWithPendingCr);
            this.fcrRolesFormControlData = roleFormControlData;
        }
    }

    /**
     * Caclulates Fcr totals
     *
     * @private
     * @param {ICrResource[]} resourceRequests
     * @memberof FinancialChangeRequestComponent
     */
    private getFcrTotals(resourceRequests: ICrResource[]) {
        const expenseRequests = resourceRequests.filter((t) => t.resourceType === ResourceType.Expense);
        const expenseRequestsCost = expenseRequests && expenseRequests.length && expenseRequests.map((m: ICrExpenseOutput) => (m.currentState.newPlannedCost + (m.currentState.existingResource ? m.currentState.existingCost : 0)) - (m.currentState.existingResource ? m.currentState.existingResource.dbCost : 0));
        const totalExpenseCost = expenseRequestsCost && expenseRequestsCost.reduce((a, b) => a + b, 0);

        const unitRequests = resourceRequests.filter((t) => t.resourceType === ResourceType.Unit);

        const unitRequestsCost = unitRequests && unitRequests.length && unitRequests.map((m: ICrUnitOutput) => ((+m.currentState.newUnits + (m.currentState.existingResource ? +m.currentState.existingResource.existingUnits : 0)) * +m.currentState.costRate) - (m.currentState.existingResource ? m.currentState.existingResource.dbCost : 0));
        const totalUnitCost = unitRequestsCost && unitRequestsCost.reduce((a, b) => a + b, 0);

        const subconRequests = resourceRequests.filter((t) => t.resourceType === ResourceType.SubconFF);
        const subconRequestsCost = subconRequests && subconRequests.length && subconRequests.map((m: ICrSubconOutput) => (m.currentState.newPlannedCost + (m.currentState.existingResource ? m.currentState.existingCost : 0)) - (m.currentState.existingResource ? m.currentState.existingResource.dbCost : 0));
        const totalSubconCost = subconRequestsCost && subconRequestsCost.reduce((a, b) => a + b, 0);

        const laborRequests = resourceRequests.filter((t) => t.resourceType === ResourceType.Labor);
        const laborRequestsCost = laborRequests && laborRequests.length && laborRequests.map((m: ICrDemandOutput) => (((m.currentState.hours + (m.currentState.existingDemand ? m.currentState.existingDemand.planHours : 0)) * m.currentState.blendedCostRate) - (m.currentState.existingDemand ? m.currentState.existingDemand.dbCost : 0)));
        const totalLaborCost = laborRequestsCost && laborRequestsCost.reduce((a, b) => a + b, 0);

        this.fcrTotals = {
            expense: totalExpenseCost,
            labor: totalLaborCost,
            unit: totalUnitCost,
            subcon: totalSubconCost,
        };
    }
    /**
     * Recalculate relevant values given a change in the state
     */
    private recalculate(updatedValue: ICrResource[]): void {
        let totalExistingHours: number = 0;
        let totalAdditionalHours: number = 0;
        let totalExistingCost: number = 0;
        let totalRevisedCost: number = 0;
        let totalAdditionalCost: number = 0;
        let totalAdditionalRevenue: number = 0;
        let totalReconciledHours: number = 0;  // total number of hours which existed already on CFP which are not part of DB

        this.getFcrTotals(updatedValue);
        updatedValue.forEach((item: ICrDemandOutput | ICrExpenseOutput | ICrSubconOutput | ICrUnitOutput) => {
            if (item.resourceType === ResourceType.Labor) {
                const laborRequest = item as ICrDemandOutput;
                if (!isNaN(laborRequest.currentState.hours)) {
                    laborRequest.currentState.hours = +laborRequest.currentState.hours;
                    const existingHours = laborRequest.currentState.existingDemand ? laborRequest.currentState.existingDemand.planHours : 0;
                    const existingCfpCost = laborRequest.currentState.existingDemand ? laborRequest.currentState.existingDemand.planCost : 0;  // existing cost before any blended cost rate change
                    const cfpCost = laborRequest.isDbOnlyDemand ? 0 : laborRequest.currentState.blendedCostRate * (existingHours + +laborRequest.currentState.hours);
                    const additionalCost = cfpCost - (laborRequest.currentState.existingDemand && laborRequest.currentState.existingDemand.dbCost ? laborRequest.currentState.existingDemand.dbCost : 0);

                    totalExistingHours += existingHours;
                    if (item.isAutoPopulated && !item.isPnfEdit) {  // any hours difference in autopopulated roles for reconciliation should be part of hours impact for approval
                        totalAdditionalHours += (existingHours - (laborRequest.currentState.existingDemand ? +laborRequest.currentState.existingDemand.dbHours : 0));
                        totalReconciledHours += (existingHours - (laborRequest.currentState.existingDemand ? +laborRequest.currentState.existingDemand.dbHours : 0));
                    } else {
                        totalAdditionalHours += laborRequest.currentState.hours;
                    }
                    totalExistingCost += existingCfpCost;
                    totalRevisedCost += cfpCost;
                    totalAdditionalCost += additionalCost;
                    totalAdditionalRevenue += this.crDemandService.getBillRate(laborRequest) * laborRequest.currentState.hours;
                }
            }
            else if (item.resourceType === ResourceType.Unit) {
                const unitRequest = item as ICrUnitOutput;
                if (!isNaN(unitRequest.currentState.newUnits)) {
                    unitRequest.currentState.newUnits = +unitRequest.currentState.newUnits;
                    const existingUnits = unitRequest.currentState.existingResource ? +unitRequest.currentState.existingResource.plannedHours : 0;
                    const existingUnitCfpCost = unitRequest.currentState.existingResource ? +unitRequest.currentState.existingResource.plannedCost : 0;  // existing cost before any blended cost rate change
                    const cfpCost = unitRequest.isDbOnlyDemand ? 0 : unitRequest.currentState.costRate * (existingUnits + +unitRequest.currentState.newUnits);
                    const additionalCost = cfpCost - (unitRequest.currentState.existingResource && unitRequest.currentState.existingResource.dbCost ? unitRequest.currentState.existingResource.dbCost : 0);

                    totalExistingCost += existingUnitCfpCost;
                    totalRevisedCost += cfpCost;
                    totalAdditionalCost += additionalCost;
                    totalAdditionalRevenue += (unitRequest.currentState.billingInfo ? unitRequest.currentState.billingInfo.billRate : 0) * unitRequest.currentState.newUnits;
                }
            }
            else if (item.resourceType === ResourceType.Expense) {
                const expenseRequest = item as ICrExpenseOutput;
                if (!isNaN(expenseRequest.currentState.newPlannedCost)) {
                    const cfpCost = expenseRequest.isDbOnlyDemand ? 0 : +expenseRequest.currentState.newPlannedCost + (expenseRequest.currentState.existingResource ? +expenseRequest.currentState.existingResource.plannedCost : 0);
                    const additionalCost = cfpCost - (expenseRequest.currentState.existingResource && expenseRequest.currentState.existingResource.dbCost ? +expenseRequest.currentState.existingResource.dbCost : 0);
                    totalExistingCost += (expenseRequest.currentState.existingResource ? +expenseRequest.currentState.existingResource.plannedCost : 0);
                    totalRevisedCost += cfpCost;
                    totalAdditionalCost += additionalCost;
                    // totalAdditionalRevenue += (unitRequest.currentState.billingInfo ? unitRequest.currentState.billingInfo.billRate : 0) * unitRequest.currentState.newUnits;
                }
            }
            else if (item.resourceType === ResourceType.SubconFF) {
                const subconRequest = item as ICrSubconOutput;
                if (!isNaN(subconRequest.currentState.newPlannedCost)) {
                    const cfpCost = subconRequest.isDbOnlyDemand ? 0 : +subconRequest.currentState.newPlannedCost + (subconRequest.currentState.existingResource ? +subconRequest.currentState.existingResource.existingCost : 0);
                    const additionalCost = cfpCost - (subconRequest.currentState.existingResource && subconRequest.currentState.existingResource.dbCost ? +subconRequest.currentState.existingResource.dbCost : 0);
                    totalExistingCost += (subconRequest.currentState.existingResource ? +subconRequest.currentState.existingResource.existingCost : 0);
                    totalRevisedCost += cfpCost;
                    totalAdditionalCost += additionalCost;
                    // totalAdditionalRevenue += (unitRequest.currentState.billingInfo ? unitRequest.currentState.billingInfo.billRate : 0) * unitRequest.currentState.newUnits;
                }
            }
        });
        const projectIds = updatedValue.map((r) => r.currentState && r.currentState.assignedTask && r.currentState.assignedTask.projectId);
        if (projectIds && projectIds.length) {
            this.costSummary = this.costSummary.filter((c) => projectIds.indexOf(c.projectId) > -1).slice();
            this.hoursSummary = this.hoursSummary.filter((h) => projectIds.indexOf(h.projectId) > -1).slice();
        }       
        this.totalExistingHours = totalExistingHours;
        this.totalRevisedCfpCost = totalRevisedCost;
        this.totalAdditionalHours = totalAdditionalHours;
        this.totalAdditionalCost = totalAdditionalCost;
        this.totalAdditionalRevenue = totalAdditionalRevenue;
        this.fcrFinancialSummary = this.financialPlanService.getRevisedFinancialPlanSumamry(this.fcrFinancialSummary, this.totalAdditionalCost, this.totalAdditionalHours, this.totalAdditionalRevenue, this.totalRevisedCfpCost, totalExistingCost, totalReconciledHours);
        const tphRrData = {
            ...this.tphRrSummary,
            contractBaselineCost: this.fcrFinancialSummary.finalizedContractBaseline.cost,
            costImpact: this.totalAdditionalCost,
            deliveryBaselineCost: this.fcrFinancialSummary.finalizedDeliveryBaseline.cost,
            deliveryBaselineHours: this.fcrFinancialSummary.finalizedDeliveryBaseline.hours,
            hoursImpact: this.totalAdditionalHours
        };
        this.tphRrSummary = this.changeRequestService.getTphRrSummary(tphRrData);
        // return { totalExistingHours, totalAdditionalHours, totalCost, totalAdditionalCost, totalRevenue, totalAdditionalRevenue };
    }

    /**
     * Get array of informers from rule engine output.
     *
     * @private
     * @param {ICrApprover[]} ruleEngineInformers
     * @returns {ICrApprover[]}
     * @memberof LaborRequestModalComponent
     */
    private populateVirtuosoInformers(ruleEngineInformers: ICrApprover[]): ICrApprover[] {
        const filteredVirtuosoInformers: IVirtuosoUser[] = this.virtuosoUsers.filter((informer: IVirtuosoUser) =>
            ruleEngineInformers.some((person: ICrApprover) => person.persona === informer.roleName));
        return filteredVirtuosoInformers.map((crInformer: IVirtuosoUser) => {
            return {
                persona: crInformer.roleName,
                alias: crInformer.UserAlias,
                role: CrRole.Informer,
                level: 0
            };
        });
    }

    /**
     * Get selected values from approversFormArray to populate selected approver and get informers
     * for create FVR payload.
     *
     * @private
     * @returns {ICrApprover[]}
     * @memberof LaborRequestModalComponent
     */
    private getChangeRequestApproversV2(): ICrApprover[] {
        return [...this.crApprovers.map((crApprover: ICrApprover) => {
            const changeRequestApprover: ICrApprover = { ...crApprover };
            delete changeRequestApprover.approverList; // only used for UI

            const filteredApprover = this.approversFormArray.value.filter((item: IVirtuosoUser) => item.roleName === changeRequestApprover.persona);
            if (filteredApprover && filteredApprover.length) {
                changeRequestApprover.alias = filteredApprover[0].UserAlias;
            }
            return changeRequestApprover;
        }), ...this.crInformers];
    }

    /**
     * Determine contract type of FCR from autopopulated roles.
     *
     * @private
     * @param {ICrResource[]} autopopulatedLineItems
     * @memberof FcrRolesFormControlComponent
     */
    private determineContractTypeForAutopopulatedRoles(autopopulatedLineItems: ICrResource[]): void {
        for (const lineItem of autopopulatedLineItems) {
            const laborContractType: ContractType = lineItem.currentState.assignedTask.isFixedFeeProject ? ContractType.FixedFee : ContractType.TimeAndMaterial;

            if (!this.fvrContractType) {
                this.fvrContractType = laborContractType;
                this.isFixedFee = laborContractType === ContractType.FixedFee;
            }
        }
    }

    /**
     * Initializes FCR view details by getting data and enabling all sections.
     *
     * @private
     * @memberof FinancialChangeRequestComponent
     */
    private initializeFCRViewDetails(): void {
        this.showCostSummarySection = true;
        this.showFinancialSummarySection = true;
        this.showHoursSummarySection = true;
        this.showRiskReserveDetailsSection = true;

        // Gets all FCR data(financial summary, cost, hour summary) for view part
        this.changeRequestService.getChangeRequestSummaryByEngagementIdV2(this.engagementId, this.changeRequestId).then(
            (response: IChangeRequestResponse) => {
                if (response) {
                    let fcrTaskIds: string[] = [];
                    if (response.changeRequestDetails && response.changeRequestDetails.changeRequestRoleDetails && response.changeRequestDetails.changeRequestRoleDetails) {
                        const changeRequestRoleDetails = response.changeRequestDetails.changeRequestRoleDetails;
                        if (changeRequestRoleDetails && changeRequestRoleDetails.changeRequestDetails && changeRequestRoleDetails.changeRequestDetails.length > 0) {
                            fcrTaskIds = changeRequestRoleDetails.changeRequestDetails.map((s) => s.taskId && s.taskId.substring(0, 16));
                        }
                    }
                    this.isFixedFee = response.changeRequestDetails.isFixedFee;
                    // Sets few props from taskId like projectName,serviceName and isFixedFeeProject
                    const roles: IFinancialChangeRequestRoleDetail[] = response.changeRequestDetails.changeRequestRoleDetails.changeRequestDetails;

                    if (response.changeRequestDetails.financialPlanDetails) {
                        // Gets most of TPH section details
                        const varianceResponse = response.engagementVarianceSummary;

                        // Gets financial details which are used to get risk reserve details i.e total risk reserve and approved risk reserve.
                        const financialPlanResponse: IFinancialPlanResponse = response.changeRequestDetails.financialPlanDetails;
                        const engagementRiskReserveAmounts: IEngagementRiskReserveData = this.financialPlanService.getEngagementRiskReserveFromFinancialPlan(financialPlanResponse);

                        // Gets and sets Financial summary
                        const originalFcrFinancialSummary = financialPlanResponse.engagementFinancialPlan && financialPlanResponse.engagementFinancialPlan.engagementPlanDetails ? this.financialPlanService.getFinancialPlanSummary(financialPlanResponse.engagementFinancialPlan.engagementPlanDetails) : null;

                        // TODO: Send proper total additional revenuw to calculate revised revenue as we don't have data to derive/calculate it.
                        const engagementCfpCostData: IEngagementCfpCostData = this.changeRequestService.getCfpCostDataFromCrSummary(roles);

                        // Get revised Financial summary details
                        this.fcrFinancialSummary = this.financialPlanService.getRevisedFinancialPlanSumamry(originalFcrFinancialSummary, response.changeRequestDetails.requestedCost, response.changeRequestDetails.requestedHours + engagementCfpCostData.totalReconciledLaborHours, 0, engagementCfpCostData.totalRevisedCfpCost, engagementCfpCostData.totalExistingCfpCost, engagementCfpCostData.totalReconciledLaborHours);

                        // Set TPH RR data
                        this.tphRrSummary = {
                            hoursImpactPercentDecimal: varianceResponse.hoursVariancePercentage / 100,
                            newPlannedCost: varianceResponse.newPlannedCost,
                            cbCostExcludingRr: varianceResponse.contractBaseLineCostExcludingRR,
                            grossCostOverrun: varianceResponse.grossCostOverRun,
                            grossCostOverrunPercentDecimal: varianceResponse.grossCostOverRunPercentage / 100,
                            previouslyApprovedCost: varianceResponse.previouslyApprovedCost,
                            additionalApprovalNeeded: varianceResponse.additionalApprovalNeeded,
                            costImpact: response.changeRequestDetails.requestedCost,
                            hoursImpact: varianceResponse.hoursImpact,
                            totalRiskReserve: engagementRiskReserveAmounts.totalRiskReserve,
                            approvedRiskReserve: engagementRiskReserveAmounts.approvedRiskReserve
                        };

                        // Set cost summary and hour summary data for all projects
                        if (varianceResponse.projectCostSummary) {
                            this.costSummary = [];
                            for (const projectFinancials of varianceResponse.projectCostSummary) {
                                if (fcrTaskIds.indexOf(projectFinancials.projectId.substring(0, 16)) > -1) {
                                    this.costSummary.push({
                                        approvalsNeeded: projectFinancials.approvalsNeeded,
                                        budgetCostExcludingRiskReserve: projectFinancials.budgetCostExcludingRiskReserve,
                                        budgetCostIncludingRiskReserve: projectFinancials.budgetCostIncludingRiskReserve,
                                        grossCostOverRun: projectFinancials.grossCostOverRun,
                                        netCost: projectFinancials.newPlannedCost - projectFinancials.budgetCostIncludingRiskReserve,
                                        newPlannedCost: projectFinancials.newPlannedCost,
                                        oldPlannedCost: projectFinancials.oldPlannedCost,
                                        projectId: projectFinancials.projectId,
                                        remainingUnassignedRiskReserve: projectFinancials.remainingUnassignedRiskReserve
                                    });
                                }
                            }
                        }

                        if (varianceResponse.projectHoursSummary) {
                            this.hoursSummary = [];
                            for (const projectFinancials of varianceResponse.projectHoursSummary) {
                                if (fcrTaskIds.indexOf(projectFinancials.projectId.substring(0, 16)) > -1) {
                                    this.hoursSummary.push({
                                        approvalsNeeded: projectFinancials.approvalsNeeded,
                                        budgetHours: projectFinancials.budgetHours,
                                        hoursVariance: projectFinancials.hoursVariance,
                                        newHoursVariance: projectFinancials.newHoursVariance,
                                        oldHoursVariance: projectFinancials.oldHoursVariance,
                                        projectId: projectFinancials.projectId
                                    });
                                }
                            }
                        }
                    }

                    for (const role of roles) {
                        // Gets project name, service name and isFixedFee
                        const wbsDetails = this.projectServiceV2.extractWbsDetailsFromTaskId(role.taskId, this.engagementDetails);
                        role.projectName = wbsDetails.projectName;
                        role.serviceName = wbsDetails.serviceName;
                        role.isFixedFeeProject = wbsDetails.isFixedFeeProject;

                        if (role.resourceType === ResourceTypeCode.Labor || role.resourceType === ResourceTypeCode.Unit || (role.resourceType === ResourceTypeCode.SubconFF && role.laborUnits === "H")) {
                            role.cfpCost = (role.quantity + role.requestedQuantity) * role.costRate; // revisedCFP cost

                            // Additional Cost calculation
                            if (role.quantity === 0 && role.requestedQuantity < 0 && role.dbHour) {
                                role.additionalCost = ((role.quantity + role.requestedQuantity) * role.costRate);
                            } else {
                                role.additionalCost = ((role.quantity + role.requestedQuantity) * role.costRate) - role.dbCost; // cfp cost - db cost
                            }
                        }

                        if (role.resourceType === ResourceTypeCode.Expense || (role.resourceType === ResourceTypeCode.SubconFF && role.laborUnits === "%")) {
                            role.cfpCost = role.requestedCost + role.planCost; // revisedCFP cost
                            // Additional Cost calculation
                            role.additionalCost = role.cfpCost - role.dbCost;
                        }
                    }
                    // Set back the revised details from roles we did above back to changeRequestDetails to bind them to DOM.
                    response.changeRequestDetails.changeRequestRoleDetails.changeRequestDetails = roles;

                    // Sets FCR details data that has been requested for CR (includes role details and approvers section).
                    this.fcrDetails = response.changeRequestDetails;

                    // Get approvers list
                    this.getChangeRequestApprovalStatusV2(this.changeRequestId.toString());

                    let pPjmAlias = "";
                    if (this.engagementDetails && this.engagementDetails.teamStructure && this.engagementDetails.teamStructure.filter((t) => t.role === RoleShortName.PPJM).length > 0) {
                        pPjmAlias = this.engagementDetails.teamStructure.filter((t) => t.role === RoleShortName.PPJM)[0].alias;
                    }

                    // Check CR is allowed for cancellation.
                    this.allowCrCancellation = this.checkIfCrCancellationIsAllowed(this.fcrDetails.createdBy, this.fcrDetails.status, pPjmAlias);

                    // Get FCR Attachment link
                    if (this.changeRequestId && this.fcrDetails && this.fcrDetails.attachment && this.fcrDetails.attachment.id) {
                        this.changeRequestService.getFcrAttachmentLink(this.changeRequestId, this.fcrDetails.attachment.id, this.fcrDetails.engagementId).then((attachmentResponse) => {
                            this.fcrAttachmentLink = attachmentResponse;
                        });
                    }
                }
            }
        );
    }

    /**
     * Get Virtuoso approval status for a given change request ID.
     *
     * @private
     * @param {string} changeRequestId
     * @memberof LaborRequestModalComponent
     */
    private getChangeRequestApprovalStatus(changeRequestId: string): void {
        this.virtuosoService.getBulkChangeRequestApprovalStatus([changeRequestId]).then((crStatus: IVirtuosoApprovalStatus[]) => {
            const crApprovers: IFinancialCrApprover[] = [];
            if (crStatus[0]) {
                crStatus[0].ApprovalLevels.map((level: IApprovalLevel) => {
                    const progressBarStatus: ProgressBarStatus = this.virtuosoService.getProgressStatusForVirtuosoStatus(level.ApprovalStatus);
                    const currentVirtuosoRole: VirtuosoRole = this.virtuosoService.determineCurrentLevel(crStatus[0].Status);

                    if (level.ApprovalStatus === VirtuosoApprovalStatus.Rejected) {
                        this.virtuosoCurrentStatusDate = level.ApprovalDate;
                    }

                    crApprovers.push({
                        name: level.UserName,
                        role: level.RoleName,
                        status: level.RoleName === currentVirtuosoRole ? ProgressBarStatus.InProgress : progressBarStatus,
                        updatedOn: level.ApprovalDate ? this.sharedFunctionsService.transformDate(level.ApprovalDate, "dd-MMM-yyyy") : null
                    });

                    return {
                        content: `${level.UserName ? level.UserName : level.UserAlias} (${this.virtuosoService.mapPersonaToVirtuosoRole(level.RoleName)}) ${level.ApprovalDate ? `on ${this.sharedFunctionsService.transformDate(level.ApprovalDate, "dd-MMM-yyyy")}` : ""}`,
                        status: level.RoleName === currentVirtuosoRole ? ProgressBarStatus.InProgress : progressBarStatus
                    };
                });

                // TODO: Set Status properly to approvers section
                this.crApproversStatusData = crApprovers;

                if (!this.virtuosoCurrentStatusDate && crStatus[0].ApprovalLevels.length) {
                    this.virtuosoCurrentStatusDate = crStatus[0].ApprovalLevels[crStatus[0].ApprovalLevels.length - 1].ApprovalDate;
                }

                if (crStatus[0].Status === VirtuosoChangeRequestStatus.Approved || crStatus[0].Status === VirtuosoChangeRequestStatus.Rejected) {
                    this.showVirtuosoStatusDate = true;
                }
            }
        }).catch((error) => {
            if (error && error.status === 404) {
                this.crApproversStatusError = DmError.FinancialChangeRequest.NoApprovalStatusFoundInVirtuoso;
                this.logError(SourceConstants.Method.GetChangeRequestApprovalStatus, error, this.crApproversStatusError, ErrorSeverityLevel && ErrorSeverityLevel.Medium);
                Promise.resolve([]);
            } else {
                this.crApproversStatusError = DmError.FinancialChangeRequest.ErrorRetrievingApprovalStatusFromVirtuoso;   // TODO: check with virtuoso team on error contract
                this.logError(SourceConstants.Method.GetChangeRequestApprovalStatus, error, this.crApproversStatusError, ErrorSeverityLevel && ErrorSeverityLevel.High);
                Promise.reject(error);
            }
        });
    }

    /**
     * Get Virtuoso approval status for a given change request ID.
     *
     * @private
     * @param {string} changeRequestId
     * @memberof LaborRequestModalComponent
     */
    private getChangeRequestApprovalStatusV2(changeRequestId: string): void {
        this.virtuosoService.getBulkChangeRequestApprovalStatusV2([changeRequestId]).then((crStatus: IVirtuosoApprovalStatusV2[]) => {
            const crApprovers: IFinancialCrApprover[] = [];
            if (crStatus[0]) {
                crStatus[0].ApprovalLevels.map((level: IApprovalLevelV2) => {
                    const progressBarStatus: ProgressBarStatus = this.virtuosoService.getProgressStatusForVirtuosoStatus(level.ApprovalStatus);
                    const currentPendingLevel = this.virtuosoService.determineCurrentLevelV2(crStatus[0].Status);
                    const delegatedApproverName: string = level.ApprovedByUserName && level.AssignedToUserName && (level.ApprovedByUserName.toLowerCase() !== level.AssignedToUserName.toLowerCase()) ? level.ApprovedByUserName : null;

                    if (level.ApprovalStatus === VirtuosoApprovalStatus.Rejected) {
                        this.virtuosoCurrentStatusDate = level.ApprovalDate;
                    }

                    crApprovers.push({
                        name: level.AssignedToUserName,
                        role: level.RoleName,
                        status: level.Level === currentPendingLevel ? ProgressBarStatus.InProgress : progressBarStatus,
                        updatedOn: level.ApprovalDate ? this.sharedFunctionsService.transformDate(level.ApprovalDate, "dd-MMM-yyyy") : null,
                        delegatedApprover: delegatedApproverName
                    });

                    return {
                        content: `${level.AssignedToUserName ? level.AssignedToUserName : level.AssignedToUserAlias} (${this.virtuosoService.mapPersonaToVirtuosoRole(level.RoleName)}) ${level.ApprovalDate ? `on ${this.sharedFunctionsService.transformDate(level.ApprovalDate, "dd-MMM-yyyy")}` : ""}`,
                        status: level.Level === currentPendingLevel ? ProgressBarStatus.InProgress : progressBarStatus
                    };
                });

                // TODO: Set Status properly to approvers section
                this.crApproversStatusData = crApprovers;

                if (!this.virtuosoCurrentStatusDate && crStatus[0].ApprovalLevels.length) {
                    this.virtuosoCurrentStatusDate = crStatus[0].ApprovalLevels[crStatus[0].ApprovalLevels.length - 1].ApprovalDate;
                }

                if (crStatus[0].Status === VirtuosoChangeRequestStatus.Approved || crStatus[0].Status === VirtuosoChangeRequestStatus.Rejected) {
                    this.showVirtuosoStatusDate = true;
                }
            }
        }).catch((error) => {
            if (error && error.status === 404) {
                this.crApproversStatusError = DmError.FinancialChangeRequest.NoApprovalStatusFoundInVirtuoso;
                this.logError(SourceConstants.Method.GetChangeRequestApprovalStatusV2, error, this.crApproversStatusError, ErrorSeverityLevel && ErrorSeverityLevel.Medium);
                Promise.resolve([]);
            } else {
                this.crApproversStatusError = DmError.FinancialChangeRequest.ErrorRetrievingApprovalStatusFromVirtuoso;   // TODO: check with virtuoso team on error contract
                this.logError(SourceConstants.Method.GetChangeRequestApprovalStatusV2, error, this.crApproversStatusError, ErrorSeverityLevel && ErrorSeverityLevel.High);
                Promise.reject(error);
            }
        });
    }

    /**
     * Allow CR Cancellation if the current user is same requested user and CR is not in "Approved" or "Closed" or "Rejected" or "Canceled"
     */
    private checkIfCrCancellationIsAllowed(crCreatorAlias: string, crStatusDescription: string, pPjmAlias: string): boolean {
        if (crCreatorAlias && crStatusDescription) {
            const crStatus = crStatusDescription.toLowerCase();
            return (crCreatorAlias.toLowerCase() === this.fxpUserInfoService.getCurrentUser().toLowerCase() || pPjmAlias.toLowerCase() === this.fxpUserInfoService.getCurrentUser().toLowerCase()) &&
                (crStatus.indexOf("approved") === -1) &&
                (crStatus.indexOf("rejected") === -1) &&
                (crStatus !== "closed") &&
                (crStatus !== "canceled") && (crStatus !== "cancelled");
        }
    }

    /**
     * Determine which tabs to show in fcr resource requests
     *
     * @private
     * @memberof FinancialChangeRequestComponent
     */
    private determineTabs() {
        this.tabsContent = [];
        if (this.engagementDetails && this.engagementDetails.hasUnitBasedDemands) {
            this.tabsContent.push(FcrTabs.Unit);
        }
        if (this.fcrSubconFormControlData && this.fcrSubconFormControlData.existingResources && this.fcrSubconFormControlData.existingResources.length) {
            this.tabsContent.push(FcrTabs.SubconFF);
        }
        this.tabsContent = [FcrTabs.Labor, ...this.tabsContent, FcrTabs.Expense];
        this.sharedFunctionsService.sortListByPropertyBasedOnOrder("order", false, this.tabsContent);
        const defaultTab = this.tabsContent && this.tabsContent.length ? this.tabsContent[0] : undefined;
        this.toggleTab(defaultTab);
    }

    /**
     * Identify demands which are part of a project with pending CR. This is used purely
     * for styling purposes to clearly distiguish demands which have a pending CR in project.
     *
     * @private
     * @param {IExistingDemand[]} demandData
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {string[]} projectsWithPendingCr
     * @returns {IExistingDemand[]}
     * @memberof FinancialChangeRequestComponent
     */
    private identifyDemandsWithPendingCr(demandData: IExistingDemand[], engagementDetails: IEngagementDetailsApiV2, projectsWithPendingCr: string[]): IExistingDemand[] {
        return demandData.map((demand) => {
            const projectInfo = this.projectServiceV2.extractWbsDetailsFromTaskId(demand.taskId, engagementDetails);
            demand.isCrPendingInProject = projectsWithPendingCr.includes(projectInfo.projectId);
            return demand;
        });
    }
}