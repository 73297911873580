import { ActualsComponent } from "./actuals.component";
import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProjectFilterModule } from "../tiles/project-filter/project-filter.module";
import { ProjectService } from "../../common/services/project.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { UIRouterModule } from "@uirouter/angular";
import { ActualsTransactionModalModule } from "./actuals-transaction-modal/actuals-transaction-modal.module";
import { ActualsNotesModalModule } from "./actuals-notes-modal/actuals-notes-modal.module";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";

@NgModule({
    declarations: [
        ActualsComponent,
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        ProjectFilterModule,
        UIRouterModule,
        ActualsTransactionModalModule,
        ActualsNotesModalModule,
        NgbModule
    ],
    providers: [
        DMLoggerService,
        ProjectService,
        SharedFunctionsService,
        StoreDispatchService
    ],
    entryComponents: [
        ActualsComponent
    ],
    exports: [
        ActualsComponent
    ]
})
export class ActualsModule { }
