import { Action } from "@ngrx/store";
import { INpcProjectActuals } from "../../common/services/contracts/npc.contract";

// 1. action types
export enum NpcActualsActionTypes {
    LOAD_NPC_ACTUALS = "[NPC Actuals] Load",
    LOAD_NPC_ACTUALS_SUCCESS = "[NPC Actuals] Load Success",
    UPDATE_NPC_ACTUALS_LOCAL = "[NPC Actuals] Update Local",
    LOAD_NPC_ACTUALS_FAIL = "[NPC Actuals] Load Fail",
    INVALIDATE_NPC_ACTUALS = "[NPC Actuals] Invalidate"
}

// 2. action creators
export class LoadNpcActuals implements Action {
    public readonly type = NpcActualsActionTypes.LOAD_NPC_ACTUALS;
    public constructor(public wbsId: string) { }
}
export class LoadNpcActualsSuccess implements Action {
    public readonly type = NpcActualsActionTypes.LOAD_NPC_ACTUALS_SUCCESS;
    public constructor(public wbsId: string, public npcActuals: INpcProjectActuals[]) { }
}

export class UpdateNpcActualsLocal implements Action {
    public readonly type = NpcActualsActionTypes.UPDATE_NPC_ACTUALS_LOCAL;
    public constructor(public wbsId: string, public npcActuals: INpcProjectActuals[]) { }
}

export class LoadNpcActualsFail implements Action {
    public readonly type = NpcActualsActionTypes.LOAD_NPC_ACTUALS_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateNpcActuals implements Action {
    public readonly type = NpcActualsActionTypes.INVALIDATE_NPC_ACTUALS;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type NpcActualsAction =
    | LoadNpcActuals
    | LoadNpcActualsSuccess
    | UpdateNpcActualsLocal
    | LoadNpcActualsFail
    | InvalidateNpcActuals;