import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../common/services/engagement-detail.service";
import { PurchaseOrderV2Component } from "./purchase-orders-v2.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        PurchaseOrderV2Component
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule,
        NgbModule
    ],
    providers: [
        DMLoggerService,
        EngagementDetailService,
        SharedFunctionsService
    ],
    entryComponents: [
        PurchaseOrderV2Component
    ],
    exports: [
        PurchaseOrderV2Component
    ]
})
export class PurchaseOrderV2Module {
}
