<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'350px'" [tileHeight]="'100%'" [isV2Tile]="true">
  <div additionalActionsRight class="display-flex-imp">
    <a *ngIf="!isComponentLoading" [uiSref]="RouteName.EngagementSummaryV2"
      [uiParams]="{engagementId: projectDetails.engagementId}">
      View Engagement Summary
    </a>
  </div>
  <dm-loading loader *ngIf="isComponentLoading && !isServerError" [showLoading]="showLoading"
    [loadingText]="'Services'"></dm-loading>
  <div *ngIf="!isComponentLoading && !isServerError && projectDetails.services && projectDetails.services.length">
    <caption class="sr-only">Services</caption>
    <div role="rowgroup" class="dm-grid-view-table">
      <div role="row" class="dm-grid-view-table__row">
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
          Name
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
          Type
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
          Start Date
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
          End Date
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
          EBS State
          <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4" [dmPlacement]="'bottom-right'"
            [ngbTooltipContent]="wbsTooltipText" [dmIcon]="'tile__icon--info icon icon-info'"
            ariaLabelToolTip="EBS State Information">
          </dm-tooltip>
        </div>
      </div>
    </div>
    <div role="rowgroup" class="overflow-content">
      <div class="dm-grid-view-table">
        <div class="dm-grid-view-table__body">
          <div role="row" class="dm-grid-view-table__row" *ngFor="let service of projectDetails.services">
            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
              {{service.serviceName | dmDisplayDashIfEmptyOrNull}}
              <span *ngIf="!service.serviceName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
              {{service.serviceType | dmDisplayDashIfEmptyOrNull}}
              <span *ngIf="!service.serviceType" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
              {{service.startDate | dmDisplayDateOrDashPipe}}
              <span *ngIf="!service.startDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
              {{service.endDate | dmDisplayDateOrDashPipe}}
              <span *ngIf="!service.endDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
              {{service.currentStatus | dmDisplayDashIfEmptyOrNull}}
              <span *ngIf="!service.currentStatus" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="!isComponentLoading && projectDetails.services && projectDetails.services.length===0">
    <dm-no-data [noDataText]="noServiceFoundText" [showImage]="true"></dm-no-data>
  </section>
  <dm-service-error *ngIf="!isComponentLoading && isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>