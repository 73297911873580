import { Action } from "@ngrx/store";
import { IWbsDemand } from "./../../common/services/contracts/project.service.v2.contracts";

// 1. action types
export enum DemandsActionTypes {
    LOAD_DB_DEMANDS = "[DB Demands] Load",
    LOAD_DB_DEMANDS_SUCCESS = "[DB Demands] Load Success",
    LOAD_DB_DEMANDS_FAIL = "[DB Demands] Load Fail",
    INVALIDATE_DB_DEMANDS = "[DB Demands] Invalidate"
}

// 2. action creators
export class LoadDBDemands implements Action {
    public readonly type = DemandsActionTypes.LOAD_DB_DEMANDS;
    public constructor(public wbsId: string) { }
}

export class LoadDBDemandsSuccess implements Action {
    public readonly type = DemandsActionTypes.LOAD_DB_DEMANDS_SUCCESS;
    public constructor(public wbsId: string, public wbsDemands: IWbsDemand) { }
}

export class LoadDBDemandsFail implements Action {
    public readonly type = DemandsActionTypes.LOAD_DB_DEMANDS_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateDBDemands implements Action {
    public readonly type = DemandsActionTypes.INVALIDATE_DB_DEMANDS;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type DemandsActions =
    | LoadDBDemands
    | LoadDBDemandsSuccess
    | LoadDBDemandsFail
    | InvalidateDBDemands;