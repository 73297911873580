import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { CustomerEngagementFinancialsModal } from "./customer-engagement-financials-modal.component";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { MyPortfolioService } from "../../../../../../common/services/my-portfolio.service";

@NgModule({
    declarations: [
        CustomerEngagementFinancialsModal
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        UIRouterModule,
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
        MyPortfolioService
    ],
    entryComponents: [
        CustomerEngagementFinancialsModal
    ],
    exports: [
        CustomerEngagementFinancialsModal
    ],
})
export class CustomerEngagementFinancialsModalModule { }
