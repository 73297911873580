import { Action } from "@ngrx/store";
import { IResourceRequestResponse } from "../../common/services/contracts/staffing.service.contract";


// 1. action types
export enum ResourceRequestsActionTypes {
    LOAD_RESOURCE_REQUESTS = "[ResourceRequests] Load",
    LOAD_RESOURCE_REQUESTS_SUCCESS = "[ResourceRequests] Load Success",
    LOAD_RESOURCE_REQUESTS_FAIL = "[ResourceRequests] Load Fail",
    INVALIDATE_RESOURCE_REQUESTS = "[ResourceRequests] Invalidate"
}

// 2. action creators
export class LoadResourceRequests implements Action {
    public readonly type = ResourceRequestsActionTypes.LOAD_RESOURCE_REQUESTS;
    public constructor(public engagementId: string) { }
}
export class LoadResourceRequestsSuccess implements Action {
    public readonly type = ResourceRequestsActionTypes.LOAD_RESOURCE_REQUESTS_SUCCESS;
    public constructor(public engagementId: string, public grmSearchApiResponse: IResourceRequestResponse) { }
}

export class LoadResourceRequestsFail implements Action {
    public readonly type = ResourceRequestsActionTypes.LOAD_RESOURCE_REQUESTS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateResourceRequests implements Action {
    public readonly type = ResourceRequestsActionTypes.INVALIDATE_RESOURCE_REQUESTS;
    public constructor(public engagementId: string) { }
}


// 3. export the union creators
export type ResourceRequestsActions =
    | LoadResourceRequests
    | LoadResourceRequestsFail
    | LoadResourceRequestsSuccess
    | InvalidateResourceRequests;



