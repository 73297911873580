import { ILoadableState } from "../reducers";
import { FinancialRolesActionTypes, FinancialRolesActions } from "./financial-roles.action";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";

// slice of financialRoles state
export interface IFinancialRolesState extends ILoadableState {
    financialRoles: IFinancialRoles[];
}

export const initialState: IFinancialRolesState = {
    financialRoles: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = initialState, action: FinancialRolesActions): IFinancialRolesState {
    switch (action.type) {
        case FinancialRolesActionTypes.LOAD_FINANCIAL_ROLES: {
            return {
                ...initialState,
                loading: true
            };
        }
      
        case FinancialRolesActionTypes.LOAD_FINANCIAL_ROLES_SUCCESS: {
            return {
                ...initialState,
                financialRoles: action.financialRoles,
                loaded: true,
                loading: false,
            };
        }

        case FinancialRolesActionTypes.LOAD_FINANCIAL_ROLES_FAIL: {
            return {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
        }

        case FinancialRolesActionTypes.INVALIDATE_FINANCIAL_ROLES: {
            return initialState;
        }
    }

    return state;
}