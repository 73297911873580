import { Component, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "dm-activate-engagement-initial-template",
    templateUrl: "./activate-engagement-initial-template.html",
    styleUrls: ["./activate-engagement-initial-template.scss"]
})
export class ActivateEngagementInitialTemplateComponent {
    @Output() private cancelClicked = new EventEmitter<void>();
    @Output() private submitClicked = new EventEmitter<void>();

    public cancel(): void {
        // todo add telemetry here for cancellation
        this.cancelClicked.emit();
    }

    public submit(): void {
        // todo add telemetry here for submit to release and activate
        this.submitClicked.emit();
    }
}