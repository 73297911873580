/* eslint-disable no-console */
import { Inject, Input, Component, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IState } from "../../../store/reducers";
import { StateService } from "@uirouter/angular";
import { untilDestroyed } from "ngx-take-until-destroy";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { FxpSignalRService, UserInfoService } from "@fxp/fxpservices";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import moment from "moment";
import { ISignalRConfig } from "../../../common/services/contracts/projectservice-functions.contract";
import { DmGrmService } from "../../../common/services/dmgrm.service";
import { IBulkMaintenanceStatusResponse } from "../../../common/services/contracts/bulk-maintenance.service.contract";
import { BulkMaintenanceStatusModalComponent } from "../bulk-maintenance-status-modal/bulk-maintenance-status-modal.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { InvalidateWbsStructures } from "../../../store/wbs-structures/wbs-structures.action";
import { INotification } from "../../../common/services/contracts/notification-bar.contracts";
const GRMTenantName = "GRM";

@Component({
    selector: "bulk-maintenance-notification",
    templateUrl: "./bulk-maintenance-notification.html",
    styleUrls: ["./bulk-maintenance-notification.scss"]
})

export class BulkMaintenanceComponent extends DmComponentAbstract {
    @Input() public subscriptionId: string;
    @Input() public entityId: string;
    @Input() public createdOn: string;
    @Input() public notificationDetail: INotification;
    public totalCount: number = 0;
    public processedCount: number = 0;
    public shouldDisplayCloseOption: boolean = true;
    public isNotificationExpanded: boolean = false;
    public timeAgo: string;
    public bulkMaintenanceResponse: IBulkMaintenanceStatusResponse;
    public responseSubmitted: boolean = false;
    private unsubscribeFunction: any;
    private bulkMaintenanceEventName: string;


    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(Store) private store: Store<IState>,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Injector) private injector: Injector,
        @Inject(FxpSignalRService) private fxpSignalRService: FxpSignalRService,
        @Inject(DmGrmService) private grmService: DmGrmService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
    ) {
        super(dmLogger, Components.BulkCreateNotification);
    }

    public ngOnInit(): void {
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
            this.startConnectionToGRM();
            this.grmService.getBulkMaintenanceStatus(this.subscriptionId).then((response) => {
                if (response) {
                    this.bulkMaintenanceResponse = response;
                    const processsedEngagements = response.bulkProcessItems.filter((engagement) => engagement.status === "Failed" || engagement.status === "Success");
                    const totaldEngagements = response.bulkProcessItems.filter((engagement) => engagement.status !== "Discarded");
                    if (response && response.updatedDate) {
                        const stillUtc = moment.utc(response.updatedDate).toDate();
                        this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                    }
                    this.totalCount = totaldEngagements.length;
                    this.processedCount = (processsedEngagements) ? processsedEngagements.length : 0;
                    this.shouldDisplayCloseOption = (this.totalCount !== 0 && this.processedCount !== 0 && this.totalCount === this.processedCount);
                    this.responseSubmitted = this.isSubmittedResponse(this.bulkMaintenanceResponse.status);
                }
            });

        });
    }

    /**
     * Subscribe to GRM SignalR
     */
    public startConnectionToGRM(): void {
        this.unsubscribeFunction = this.fxpSignalRService.subscribe(this.bulkMaintenanceEventName, GRMTenantName, this.onMessageFromGRMBulkMaintenance.bind(this));
    }

    public closeNotification(): void {
        this.shouldDisplayCloseOption = false;
        const loggedInUserAlias = this.fxpUserInfoService.getCurrentUserData().alias;
        this.notificationService.deleteNotificationSubscription(loggedInUserAlias, this.subscriptionId);
    }

    /**
     * Opens bulk upload internal engagement upload modal
     *
     * @memberof BulkCreateNotificationComponent
    */
    public openBulkCreateIntEngagementStatusModal(): void {
        this.isNotificationExpanded = true;
        const modalRef: NgbModalRef = this.modalService.open(BulkMaintenanceStatusModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal-v2 bulk-maintenance-status-popup-modal in",
            injector: this.injector
        });
        modalRef.componentInstance.bulkMaintenanceResponse = this.bulkMaintenanceResponse;
        modalRef.componentInstance.notificationDetail = this.notificationDetail;
        modalRef.result.then(() => {
            this.isNotificationExpanded = false;
        });
        modalRef.componentInstance.onStatusModalClose.pipe(untilDestroyed(this)).subscribe(() => this.isNotificationExpanded = false);
    }

    /**
     * Check whether the response is submitted
     * @param status
     */
    private isSubmittedResponse = (status: string) => {
        if (status === "Submitted") {
            return true;
        }
        return false;
    };

    /**
    * Handle when the response is recieved from SignalR
    * @param response
    */
    private onMessageFromGRMBulkMaintenance = (response: string) => {
        if (response) {
            console.log(response);
            const convertedResponse: IBulkMaintenanceStatusResponse = JSON.parse(response);
            if (convertedResponse.batchId === this.subscriptionId) {
                this.bulkMaintenanceResponse = convertedResponse;
                const processsedEngagements = convertedResponse.bulkProcessItems.filter((engagement) => engagement.status === "Failed" || engagement.status === "Success");
                const totaldEngagements = convertedResponse.bulkProcessItems.filter((engagement) => engagement.status !== "Discarded");
                if (processsedEngagements.length === totaldEngagements.length) {
                    const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
                    this.store.dispatch(new InvalidateWbsStructures(engagementId));
                }
                if (convertedResponse && convertedResponse.updatedDate) {
                    const stillUtc = moment.utc(convertedResponse.updatedDate).toDate();
                    this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                }
                this.totalCount = totaldEngagements.length;
                this.processedCount = (processsedEngagements) ? processsedEngagements.length : 0;
                this.shouldDisplayCloseOption = (this.totalCount !== 0 && this.processedCount !== 0 && this.totalCount === this.processedCount);
                this.responseSubmitted = this.isSubmittedResponse(this.bulkMaintenanceResponse.status);
            }
        }
    };

    private initializeConfig(): void {
        const signalRConfiguration = this.configManagerService.getValue<ISignalRConfig[]>("SignalRConfiguration");
        const grmConfiguration = signalRConfiguration.filter((config) => config.TenantName === GRMTenantName);
        if (grmConfiguration && grmConfiguration.length && grmConfiguration[0].Events && grmConfiguration[0].Events.length) {
            this.bulkMaintenanceEventName = grmConfiguration[0].Events.map((eventinfo) => eventinfo.BulkMaintenanceEventName)[0];
        }
    }
}