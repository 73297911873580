<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'220px'" [showFooter]="false"
  [hasNonOverflowContent]="true" [isLoading]="!searchResponse">
  <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="!searchResponse">
  </dm-loading>
  <section class="dm-bulk-maintenance-status-modal__grid" role="grid" *ngIf="searchResponse">
    <div role="rowgroup" class="dm-grid-view-table">
      <div role="row" class="dm-grid-view-table__row">
        <div role="gridcell" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8">
          Resource
        </div>
        <div role="gridcell" class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-8">
          Status
        </div>
      </div>
      <div role="row" class="dm-grid-view-table__row" *ngFor="let item of searchResponse">
        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8"
          [ngClass]="{'status-error': item?.Status !== 'InProgress' && !item?.IsSuccess, 'status-success' : item?.Status !== 'InProgress' && item?.IsSuccess, 'status-progress': item?.Status === 'InProgress'}">
          <span class="sr-only">Resource</span>
          <div class="display-flex">
            <div>
              <div class="dm-detail__value dm-p-b-5">{{item?.AssignedResourceName}}</div>
              <div class="font-caption-alt dm-p-b-5">{{item?.Role}}</div>
              <div class="font-caption-alt">Resource Id: {{item?.ResourceRequestId}}</div>
            </div>
          </div>
        </div>
        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-8">
          <span class="sr-only">Status</span>
          <div *ngIf="item?.Status == 'InProgress'" class="dm-flex-align-center">
            <span>
              <i class="icon record-progress-icon"></i>
            </span>
            <span class="dm-p-l-5">{{BulkMaintenanceStatus[item?.Status]}}</span>
          </div>
          <div *ngIf="item?.Status !== 'InProgress'">
            <span *ngIf="item?.IsSuccess">
              <i class="icon icon-CheckMark icon-rounded dm-success"></i>
            </span>
            <div class="custom-tooltip icon icon-exclamation icon-rounded dm-error" *ngIf="!item?.IsSuccess"
              [attr.aria-label]="'Failed. Hover or click on the icon to see the failure message'" role="button"
              tabindex="0">
              <div class="custom-tooltiptext font-caption-alt">
                <span id="creationErrorDetailsPopoverMsg" *ngIf="item?.ResourceRequestErrorMessage">
                  {{item?.ResourceRequestErrorMessage}}</span>
                <button class="dm-transparent-btn dm-p-3 dm-entity__btn-copy-to-clipboard" tabindex="0"
                  (click)="copyMessage()" title="Copy to clipboard" (keyup.enter)="copyMessage()">
                  <i class="icon icon-full icon-copy cursor-pointer"></i>
                </button>
              </div>
            </div>
            <span class="dm-p-l-5">{{BulkMaintenanceStatus[item?.Status]}}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</dm-modal-v2>