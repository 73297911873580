import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ResourceRejectionModalComponent } from "./resource-rejection-modal.component";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { StaffingService } from "../../../common/services/staffing.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        ResourceRejectionModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [

        DMLoggerService,
        StaffingService,
        ConfigManagerService,
        SharedFunctionsService
    ],
    entryComponents: [
        ResourceRejectionModalComponent
    ],
    exports: [
        ResourceRejectionModalComponent
    ]
})

export class ResourcerejectionModalModule { }
