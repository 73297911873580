import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DmTypeAheadTpidComponent } from "./type-ahead-tpid.component";
import { CustomerService } from "../../../common/services/customer.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        DmTypeAheadTpidComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        CustomerService
    ],
    entryComponents: [
        DmTypeAheadTpidComponent
    ],
    exports: [
        DmTypeAheadTpidComponent
    ],
})
export class TypeAheadTpidModule { }
