<div class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0">
    <div role="tabpanel" class="tab-pane active" id="overview">
        <dm-loading loader *ngIf="isComponentLoading" [loadingText]="loadingText"
            [showLoading]="showLoading"></dm-loading>
        <section class="col-md-12 col-sm-12 col-xs-12 entity-info dm-p-imp-0">
            <ng-container *ngIf="!isComponentLoading">
                <section>
                    <caption id="customerEngagementTable" class="sr-only">{{tableCaption}}</caption>
                    <div role="grid" aria-labelledby="customerEngagementTable">
                        <div role="rowgroup" class="dm-grid-view-table">
                            <div role="row" class="dm-grid-view-table__row">
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button *ngIf="viewby === 'all'" type="button" id="toggleEngagements" class="acc-btn"
                                        [ngClass]="{'pad-3': !showEngagementsExpanded}" [title]="showEngagementsExpanded ? 'Collapse Engagements' : 'Expand Engagements'"
                                        [attr.aria-expanded]="!showEngagementsExpanded" (click)="expandCollapseEngagements()">
                                        <span class="icon checkmark-left" [ngClass]="showEngagementsExpanded ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                                    </button>
                                    <span class="sr-only"> press tab to navigate to name button</span>
                                    <button class="text-left dm-transparent-btn" (click)="sortData('Name')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Name')">
                                        <span class="header-title">
                                            <span>Name</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState ==='Name'" [title]="sortOrder"></i>
                                    </button>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8 ebs-state-pad">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('EBS State')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('EBS State')">
                                        <span class="header-title">
                                            <span>EBS State</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState==='EBS State'" [title]="sortOrder"></i>
                                    </button>

                                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                                        [ngbTooltipContent]="ebsStateText" [dmIcon]="'tile__icon--info icon icon-info'"
                                        ariaLabelToolTip="EBS State Information">
                                    </dm-tooltip>
                                    <span class="sr-only">press tab to navigate to tooltip button</span>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('Customer')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Customer')">
                                        <span class="header-title">
                                            <span>Customer</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState==='Customer'" [title]="sortOrder"></i>
                                    </button>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('Start Date')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Start Date')">
                                        <span class="header-title">
                                            <span>Start Date</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState==='Start Date'" [title]="sortOrder"></i>
                                    </button>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('End Date')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('End Date')">
                                        <span class="header-title">
                                            <span>End Date</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState==='End Date'" [title]="sortOrder"></i>
                                    </button>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('Contract Type')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Contract Type')">
                                        <span class="header-title">
                                            <span>Contract</span>
                                            <br /><span>Type</span>
                                        </span>
                                    </button>
                                    <i class="icon l-h-25 color-black"
                                        [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                        *ngIf="sortState==='Contract Type'" [title]="sortOrder"></i>
                                    <ng-template #profileDescContactPopup>
                                        <div class="type-dialogBox">
                                            <h4 class="type-dialogBox--header font-caption-alt text-left dm-m-b-12">
                                                Contract Types
                                            </h4>
                                            <ul class="type-dialogBox__ul col-md-12 no-pad font-caption-alt">
                                                <li *ngFor="let data of typeOfContracts"
                                                    class="type-dialogBox__ul--li col-md-6 dm-p-l-imp-0 dm-m-t-imp-6">
                                                    <div class="col-md-4 dm-p-l-imp-0">
                                                        <span class="dm-badge dm-contract-type"
                                                            [ngStyle]="data.typeColorCode">{{data.typeShortForm}}</span>
                                                    </div>
                                                    <div class="col-md-8 text-left v-a-m fullformLbl">
                                                        <span>{{data.typeFullForm}}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>

                                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                                        [ngbTooltipContent]="profileDescContactPopup"
                                        [dmIcon]="'tile__icon--info icon icon-info'"
                                        ariaLabelToolTip="Contract Type Information">
                                    </dm-tooltip>
                                    <span class="sr-only">press tab to navigate to tooltip button</span>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn"
                                        (click)="sortData('Domain Project Manager')" type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Domain Project Manager')">
                                        <span class="header-title">
                                            <span>Domain Project Manager</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                        [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                        *ngIf="sortState==='Domain Project Manager'" [title]="sortOrder"></i>
                                    </button>
                                </div>
                                <div role="columnheader" *ngIf="!hidePinnedColumn"
                                    class="dm-grid-view-table__headerCell text-center dm-p-x-12-y-8">
                                    Pinned
                                </div>
                            </div>   
                        <!--==================Template for view by Projects Only =========================-->
                        <ng-template scope="row" role="rowheader" #projectsTemplate let-engagement="engagement"
                            let-project="project" let-j="j">
                            <div role="gridcell"
                                class="dm-grid-view-table__bodyCell text-left dm-p-l-imp-24 dm-p-x-12-y-8"
                                [ngClass]="{'dm-p-l-imp-50': viewby=='all' && !engagement.isExpand }">
                                <span class="sr-only">Name</span>
                                <span class="display-flex-imp">
                                    <!-- todo: isExternalLink doesn't exist in interface -->
                                    <b>
                                        Project:
                                        <a *ngIf="!project.isExternalLink" [uiSref]="RouteName.ProjectSummaryV2" class="dm-link"
                                            [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                            
                                            id="{{ 'dm-grid-project-' + project.projectId }}">
                                            {{project.projectName}}
                                        </a>         
                                        <span class="sr-only">press tab to navigate to link</span>                               
                                        <span *ngIf="project.isExternalLink">{{project.projectName}}</span>
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4 confidentialicon"
                                            *ngIf="project.isConfidential" [dmPlacement]="'right'"
                                            [ngbTooltipContent]="projConfidentialText"
                                            [dmIcon]="'tile__icon--info confidentialkey'"
                                            ariaLabelToolTip="confidentiality information">
                                        </dm-tooltip>
                                        <button class="inward" [title]="delegationTitleText.DelegationInward"
                                            *ngIf="isShowDelegation(project.delegationData, delegationTitleText.DelegationInward)"
                                            (click)="openDelegationModal(project.delegationDetails, 'delegatedInward')">
                                            <span class="delegated" aria-hidden="true">D</span>
                                            <span>
                                                <i [title]="delegationTitleorAriaLabel"
                                                    class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                <span class="sr-only">
                                                    {{project.projectName}} Project delegated to
                                                    me
                                                </span>
                                            </span>
                                        </button>
                                        <button class="outward" [title]="delegationTitleText.DelegationOutward"
                                            *ngIf="isShowDelegation(project.delegationData, delegationTitleText.DelegationOutward)"
                                            (click)="openDelegationModal(project.delegationDetails, 'delegatedOutward')">
                                            <span class="delegated" aria-hidden="true">D</span>
                                            <span>
                                                <i [title]="delegationTitleorAriaLabel"
                                                    class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                <span class="sr-only">
                                                    {{project.projectName}} Project delegated to
                                                    others
                                                </span>
                                            </span>
                                        </button>
                                        <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec"
                                            *ngIf="engagement && !engagement.isUsPubSec && isPubSecEnabled && project.isPublicSector"
                                            [dmPlacement]="'right'" [ngbTooltipContent]="pubSecText"
                                            [dmIcon]="'pubsec_icon'" ariaLabelToolTip="pub sec Information">
                                        </dm-tooltip>
                                        <span *ngIf="project.isUsPubSec" class="uspubsec uspubsec_project-desktop"
                                            title="US Pubsec"></span>
                                        <span tabindex="0" *ngIf="project?.isMarkedForDeletion" class="icon-full icon-page-remove icon-marked-for-deletion dm-m-r-4"
                                        title="This Project has been marked for deletion in CompassOne"></span>
                                    </b>
                                    <section class="dropdown" id="{{'project-' + project.projectId + '-dropdown-' + j}}">
                                        <button type="button" data-toggle="dropdown" class="dm-p-imp-0 set-focus"
                                            [title]="setTitleOrAriaLabelForDropDown('Project', project.projectId)"
                                            [attr.aria-label]="setTitleOrAriaLabelForDropDown('Project', project.projectId)"
                                            (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Financials')"
                                            (keydown.shift.tab)="closeDropdownOnTab('project-' + project.projectId + '-dropdown-' + j)">
                                            <span class="icon-full icon-more-vertical"></span>
                                        </button>
                                        <span class="sr-only">press tab to navigate to dropdown button</span>
                                        <div class="dropdown-menu dropdown-menu-right font-caption-alt dm-p-imp-0">
                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                [uiSref]="RouteName.ProjectFinancials"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.PlanForecast')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Financials' }}">
                                                Financials
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                [uiSref]="RouteName.ProjectPlanForecast"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                              
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Financials')"
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.ManageEBS')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.PlanForecast' }}">
                                                Plan & Forecast
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                [uiSref]="RouteName.ProjectManageEBS"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                               
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.PlanForecast')"
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Staffing')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.ManageEBS' }}">
                                                Manage EBS
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                [uiSref]="RouteName.ProjectStaffing"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                               
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.ManageEBS')"
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.ManagerSuppliers')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Staffing' }}">
                                                Staffing
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                [uiSref]="RouteName.ProjectManageSuppliers"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Staffing')"
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Milestones')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.ManagerSuppliers' }}">
                                                Manage Suppliers
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                [uiSref]="RouteName.ProjectMilestones"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.ManagerSuppliers')"
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Invoices')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Milestones' }}">
                                                Milestones
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                [uiSref]="RouteName.ProjectInvoices"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                               
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Milestones')"
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Units')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Invoices' }}">
                                                Invoices
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link" [uiSref]="RouteName.ProjectActuals"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Invoices')"
                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.NonProcuredMaterials')"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Units' }}">
                                                Units
                                            </a>
                                            <a class="dropdown-item dm-p-imp-12 dm-link" [uiSref]="RouteName.ProjectNonProcuredMaterials"
                                                [uiParams]="{projectId: project.projectId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"
                                                (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Units')"
                                                (keydown.Tab)="closeDropdownOnTab('project-' + project.projectId + '-dropdown-' + j)"
                                                id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.NonProcuredMaterials' }}">
                                                Non Procurement
                                            </a>
                                        </div>
                                    </section>
                                </span>
                                <span>
                                    EBS:
                                    <span [innerHTML]="project.projectId | highlight : highlightText"></span>
                                </span>
                                <br />                                                             
                                 <span>
                                    Solution Area:
                                    <span>{{project.primaryDomain | dmDisplayDashIfEmptyOrNull}}</span>
                                </span>
                                <span class="sr-only">press tab to navigate to tooltip button</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8 ebs-state-pad">
                                <span class="sr-only">EBS State</span>
                                {{project.ebsState  | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!project.ebsState" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                <span class="sr-only">Customer</span>
                                {{project.customerName  | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!project.customerName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                <span class="sr-only">Start Date</span>
                                <span>{{project.startDate | dmDisplayDateOrDashPipe}}</span>
                                <span *ngIf="!project.startDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                <span class="sr-only">End Date</span>
                                <span>{{project.endDate | dmDisplayDateOrDashPipe}}</span>
                                <span *ngIf="!project.endDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                <span class="sr-only">Contract Type</span>
                                <span class="dm-badge dm-contract-type"
                                    [ngStyle]="project.typeColorCode">{{project.typeOfContract | dmDisplayDashIfEmptyOrNull}}</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                <span class="sr-only">Domain Project Manager</span>
                                <dm-user-name [name]="project.pjMName" [alias]="project.pjMAlias"></dm-user-name>
                            </div>
                            <div role="gridcell" *ngIf="!hidePinnedColumn"
                                class="dm-grid-view-table__bodyCell text-center dm-p-x-12-y-8">
                                <span *ngIf="project.isPinned">
                                    <button type="button" (click)="unpinProject(project)" class="button-icon"
                                        title="Click to unpin">
                                        <i class="icon icon-pin"></i>
                                        <span class="sr-only">
                                            Project {{project.projectName}} is pinned click to
                                            unpin.
                                        </span>
                                    </button>
                                </span>
                            </div>
                        </ng-template>

                        <!--=================Viewby Engagements Only, or both Engagements and Projects ================-->
                        <ng-container *ngIf="viewby === 'all'|| viewby === 'engagements'">
                                    <div class="dm-grid-view-table__body full-width" role="row"
                                        *ngFor="let engagement of engagementList | slice:slicedItemsNumber:gridItemsDisplay; let i = index"
                                        [ngClass]="{'grey_background':engagement.overallStatus==='Closed'}">
                                        <div class="dm-grid-view-table__row">                                           
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                                <span class="sr-only">Name</span>
                                                <span>
                                                    <p class="display-flex-imp">
                                                        <span class="icon cursor-pointer set-focus" tabindex="0" *ngIf="viewby === 'all'" role="button"
                                                            (keyup.enter)="engagement.isExpand = !engagement.isExpand" (keyup.Space)="engagement.isExpand = !engagement.isExpand"
                                                            [title]="setTitleOrAriaLabelForExpandCollapseEngagement(!engagement.isExpand, engagement.engagementId)" [attr.aria-expanded]="engagement.isExpand"
                                                            (click)="engagement.isExpand = !engagement.isExpand; logExpandCollapseClick(engagement.isExpand)"
                                                            [ngClass]="{ 'icon-chevron-down' : engagement.isExpand, 'icon-chevron-up' : !engagement.isExpand }"></span>
                                                        <b>
                                                            Engagement:
                                                            <a *ngIf="!engagement.isExternalLink" class="dm-link"
                                                                [uiSref]="RouteName.EngagementSummaryV2"
                                                                (click)="logEngagementSelect(RouteName.EngagementSummaryV2)"
                                                                [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                               
                                                                id="{{ 'dm-grid-engagement-' + engagement.engagementId}}">
                                                                {{engagement.engagementName}}
                                                            </a>
                                                            <span class="sr-only">press tab to navigate to link</span>
                                                            <span
                                                                *ngIf="engagement.isExternalLink">{{engagement.engagementName}}</span>
                                                            <dm-tooltip role="tooltip"
                                                                class="dm-tooltipinline dm-m-r-4 confidentailicon confidentailicon-eng-desk"
                                                                *ngIf="engagement.isConfidential"
                                                                [dmPlacement]="'right'"
                                                                [ngbTooltipContent]="engConfidentialText"
                                                                [dmIcon]="'tile__icon--info confidentialkey'"
                                                                ariaLabelToolTip="confidentiality information">
                                                            </dm-tooltip>
                                                            <button class="inward"
                                                                [title]="delegationTitleText.DelegationInward"
                                                                *ngIf="isShowDelegation(engagement.delegationData, delegationTitleText.DelegationInward)"
                                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward')">
                                                                <span class="delegated" aria-hidden="true">D</span>
                                                                <span>
                                                                    <i [title]="delegationTitleorAriaLabel"
                                                                        class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                                    <span class="sr-only">
                                                                        {{engagement.engagementName}} delegated to
                                                                        others
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button class="outward"
                                                                [title]="delegationTitleText.DelegationOutward"
                                                                *ngIf="isShowDelegation(engagement.delegationData, delegationTitleText.DelegationOutward)"
                                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward')">
                                                                <span class="delegated" aria-hidden="true">D</span>
                                                                <span>
                                                                    <i [title]="delegationTitleorAriaLabel"
                                                                        class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                                    <span class="sr-only">
                                                                        {{engagement.engagementName}} delegated to
                                                                        others
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec"
                                                                *ngIf="isPubSecEnabled && engagement.isPublicSector"
                                                                [dmPlacement]="'right'" [ngbTooltipContent]="pubSecText"
                                                                [dmIcon]="'pubsec_icon'"
                                                                ariaLabelToolTip="pub sec Information">
                                                            </dm-tooltip>
                                                            <span *ngIf="engagement.isUsPubSec"
                                                                class="uspubsec uspubsec_eng-proj-desktop"
                                                                title="US Pubsec"></span>
                                                        </b>
                                                        <span class="dropdown" id="{{'engagement-' + engagement.engagementId + '-dropdown-' + i}}">
                                                            <button type="button" data-toggle="dropdown" class="dm-p-imp-0 set-focus"
                                                                [title]="setTitleOrAriaLabelForDropDown('Engagement', engagement.engagementId)"
                                                                [attr.aria-label]="setTitleOrAriaLabelForDropDown('Engagement', engagement.engagementId)"
                                                                (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Financials')"
                                                                (click) = "logMoreOptionsClick()"
                                                                (keydown.shift.tab)="closeDropdownOnTab('engagement-' + engagement.engagementId + '-dropdown-' + i)">
                                                                <span class="icon-full icon-more-vertical"></span>
                                                            </button>
                                                            <span class="sr-only">press tab to navigate to dropdown button</span>
                                                            <div
                                                                class="dropdown-menu dropdown-menu-right font-caption-alt dm-p-imp-0">
                                                                <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                    [uiSref]="RouteName.EngagementFinancials"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                                  
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.PlanForecast')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Financials' }}">
                                                                    Financials
                                                                </a>                                                                
                                                                <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                    [uiSref]="RouteName.EngagementPlanForecast"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                                 
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Financials')"
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageEBS')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.PlanForecast' }}">
                                                                    Plan & Forecast
                                                                </a>
                                                                <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                    [uiSref]="RouteName.EngagementManageEBS"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                                   
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.PlanForecast')"
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Staffing')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.ManageEBS' }}">
                                                                    Manage EBS
                                                                </a>
                                                                <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                    [uiSref]="RouteName.EngagementStaffing"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                                  
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageEBS')"
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageSuppliers')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Staffing' }}">
                                                                    Staffing
                                                                </a>
                                                                <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                    [uiSref]="RouteName.EngagementManageSuppliers"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                                   
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Staffing')"
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Milestones')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.ManageSuppliers' }}">
                                                                    Manage Suppliers
                                                                </a>
                                                                <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                    [uiSref]="RouteName.EngagementMilestones"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                                   
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageSuppliers')"
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Invoices')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Milestones' }}">
                                                                    Milestones
                                                                </a>
                                                                <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                    [uiSref]="RouteName.EngagementInvoices"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"                                                               
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId  , ' accessibilityConstants.Units')"
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Milestones')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Invoices'}}">
                                                                    Invoices
                                                                </a>
                                                                <a class="dropdown-item dm-p-imp-12 dm-link" [uiSref]="RouteName.EngagementActuals"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId  , ' accessibilityConstants.Invoices')"
                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId  , ' accessibilityConstants.NonProcuredMaterials')"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Units' }}">
                                                                    Units
                                                                </a>
                                                                <a class="dropdown-item dm-p-imp-12 dm-link" [uiSref]="RouteName.EngagementNonProcuredMaterials"
                                                                    [uiParams]="{engagementId: engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromCustomerEngagementsTile}"
                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Units')"
                                                                    (keydown.Tab)="closeDropdownOnTab('engagement-' + engagement.engagementId + '-dropdown-' + i)"
                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.NonProcuredMaterials' }}">
                                                                    Non Procurement
                                                                </a>
                                                            </div>
                                                        </span>
                                                    </p>
                                                    <p [ngClass]="{'dm-p-l-20': viewby === 'all'}">
                                                        EBS:
                                                        <span
                                                            [innerHTML]="engagement.engagementId | highlight : highlightText"></span>
                                                    </p>
                                                    <p [ngClass]="{'dm-p-l-20': viewby === 'all'}">
                                                        <!-- Domain:
                                                        <span>{{engagement.primaryDomain | dmDisplayDashIfEmptyOrNull}}</span> -->
                                                    </p>
                                                    <button class="dm-link dm-p-y-0" [ngClass]="{'dm-p-l-20': viewby === 'all'}"
                                                        [title]="setTitleOrAriaLabelForFinancialOverview(engagement.engagementId)"
                                                        (click)="loadFinancialOverview(engagement)">
                                                        Financials Overview
                                                    </button>
                                                    <span class="sr-only">press tab to navigate to tooltip button</span>
                                                </span>
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8 ebs-state-pad">
                                                <span class="sr-only">EBS State</span>
                                                {{engagement.ebsState | dmDisplayDashIfEmptyOrNull}}
                                                <span *ngIf="!engagement.ebsState" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                                <span class="sr-only">Customer</span>
                                                {{engagement.customerName | dmDisplayDashIfEmptyOrNull}}
                                                <span *ngIf="!engagement.customerName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                                <span class="sr-only">Start Date</span>
                                                <span>{{engagement.startDate | dmDisplayDateOrDashPipe}}</span>
                                                <span *ngIf="!engagement.startDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                                <span class="sr-only">End Date</span>
                                                <span>{{engagement.endDate | dmDisplayDateOrDashPipe}}</span>
                                                <span *ngIf="!engagement.endDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                                <span class="sr-only">Contract Type</span>
                                                <span class="dm-badge dm-contract-type"
                                                    [ngStyle]="engagement.typeColorCode">{{engagement.typeOfContract | dmDisplayDashIfEmptyOrNull}}</span>
                                            </div>
                                            <div role="gridcell"
                                                class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                                <span class="sr-only">Domain Project Manager</span>
                                                <dm-user-name [name]="engagement.pPjMName" [alias]="engagement.pPjMAlias"></dm-user-name>
                                            </div>
                                        </div>

                                        <!--===================== Displays projects alongside engagements when Engagement and Project view selected ===================-->
                                        <ng-container *ngIf="viewby === 'all' && !engagement.isExpand">
                                            <div role="row" class="dm-grid-view-table__row"
                                                *ngFor="let project of engagement.projects; let j = index">
                                                <ng-container *ngIf="!engagement.isExpand">
                                                    <ng-container
                                                        *ngTemplateOutlet="projectsTemplate; context: {'engagement': engagement, 'project': project, 'j': j}">
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                        </ng-container>

                        <!--====================================Displays projects only for Project view ===================-->
                        <ng-container *ngIf="viewby === 'projects'">                                
                                    <div class="dm-grid-view-table__body full-width">
                                        <div role="row" class="dm-grid-view-table__row"
                                            *ngFor="let project of projectList | slice:slicedItemsNumber:gridItemsDisplay; let j = index">
                                            <ng-container
                                                *ngTemplateOutlet="projectsTemplate; context: {'engagement': undefined, 'project': project, 'j': j}">
                                            </ng-container>
                                        </div>
                                    </div>
                        </ng-container>                   
                        </div>
                    </div>
                    <section class="col-md-12 dm-pagination">
                        <ng-container *ngIf="viewby === 'all'|| viewby === 'engagements'">
                            <dm-pagination-v2 *ngIf="isPaginationRequired" [pageSize]="portfolioItemsDisp"
                                [totalItemCount]="engagementList.length" [currentPageNumber]="currentPage"
                                (pageChanged)="currentPageChangedHandler($event)"
                                (pageSizeChanged)="itemPerPageChangedHandler($event)" [itemsPerPage]="itemsPerPage">
                            </dm-pagination-v2>
                        </ng-container>
                        <ng-container *ngIf="viewby === 'projects'">
                            <dm-pagination-v2 *ngIf="isPaginationRequired" [(pageSize)]="portfolioItemsDisp"
                                [totalItemCount]="projectList.length" [currentPageNumber]="currentPage"
                                (pageChanged)="currentPageChangedHandler($event)"
                                (pageSizeChanged)="itemPerPageChangedHandler($event)" [itemsPerPage]="itemsPerPage">
                            </dm-pagination-v2>
                        </ng-container>
                    </section>
                </section>
                <dm-no-data *ngIf="!engagementList.length" [noDataText]="noEngagemmentsOrProjects"></dm-no-data>
            </ng-container>
        </section>
    </div>
</div>