import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IWeek } from "./dm-schedule.contracts";

@Component({
    selector: "dm-schedule",
    templateUrl: "./dm-schedule.html",
    styleUrls: ["./dm-schedule.scss"]
})
export class DmScheduleComponent implements OnChanges {
    @Input() public weeklyScheduleData: IWeek[] = [];
    @Output() public onHourChange: EventEmitter<IWeek> = new EventEmitter<IWeek>();
    public slicedWeeks: IWeek[] = [];
    public showPreviousBtn: boolean = false;
    public showNextBtn: boolean = false;
    private startIndex: number;
    private endIndex: number;
    private maxWeeksDisplay: number;

    public ngOnInit(): void {
        this.initializeData();
        this.getSlicedWeek();     
    }

    /**
     * Initializes data
     */
    public initializeData(): void {
        this.maxWeeksDisplay = 6;
        this.startIndex = 0;
        this.endIndex = (this.startIndex + this.maxWeeksDisplay) < this.weeklyScheduleData.length ? (this.startIndex + this.maxWeeksDisplay) : this.weeklyScheduleData.length;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public ngOnChanges(changes: SimpleChanges): void { 
        this.initializeData();
        this.getSlicedWeek();
    } 

    /*
     * triggers on next button click and shows the next 6 weeks schedule
    */
    public nextWeeks(): void {
        this.startIndex = this.endIndex;
        this.endIndex = (this.startIndex + this.maxWeeksDisplay) < this.weeklyScheduleData.length ? (this.startIndex + this.maxWeeksDisplay) : this.weeklyScheduleData.length;
        this.getSlicedWeek();
        this.showPreviousBtn = true;
    }

    /*
     * triggers on previous button click and shows the previous 6 weeks schedule
    */
    public prevWeeks(): void {
        this.endIndex = this.startIndex;
        this.startIndex = (this.endIndex - this.maxWeeksDisplay) < 0 ? 0 : this.endIndex - this.maxWeeksDisplay;
        this.getSlicedWeek();
        this.showNextBtn = true;
    }

    
    /*
     * triggers on change of hour
    */
    public hourChange(changedWeek: IWeek): void {
        this.onHourChange.emit(changedWeek);
    }

    /*
     * prepare the weeks details to show on UI    
    */
    private getSlicedWeek(): void {
        this.slicedWeeks = this.weeklyScheduleData.slice(this.startIndex, this.endIndex);
        this.showPreviousBtn = this.startIndex !== 0;
        this.showNextBtn = this.endIndex !== this.weeklyScheduleData.length;
    }
}