<div class="financials-modal">
    <div id="financialOverviewCaption" class="sr-only" [attr.aria-label]="ariaLabelForFinancialModal">Financials Summary
    </div>
    <div class="modal-header modal-noborder clear-fix p-b-0">
        <h4 class="modal-title font-subtitle pull-left" id="financialsSummaryHeading">
            Financial Summary
        </h4>
        <button type="button" [attr.aria-label]="ariaLabelForCloseBtn" class="close set-focus" title="Close"
            id="closeFinancialsSummaryButton" (click)="closeModal()">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
    </div>
    <dm-loading [loadingText]="loadingText" [errorText]="errorText" [showLoading]="showLoading"
        *ngIf="isComponentLoading">
    </dm-loading>
    <div class="modal-body" *ngIf="!isComponentLoading">
        <div role="grid">
            <caption class="sr-only">{{tableCaption}}</caption>
            <div role="rowgroup" class="dm-grid-view-table">
                <div role="row" class="dm-grid-view-table__row">
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            Name
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            Currency
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            EAC
                            <br />Margin %
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            Planned
                            <br />Margin %
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            EAC
                            <br />Cost
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            Planned Cost
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            EAC Revenue
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            Planned Revenue
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            EAC
                            <br />Hours
                        </span>
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span class="header-title">
                            Planned
                            <br />Hours
                        </span>
                    </div>
                </div>
            </div>
            <div role="rowgroup">
                <div class="dm-grid-view-table">
                    <div class="dm-grid-view-table__body"
                        *ngFor="let engagement of engagementFinancialList; let i = index">
                        <div role="row" class="dm-grid-view-table__row">
                            <ng-container scope="row" role="rowheader">
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    <p class="font-caption-alt">
                                        <b>
                                            Engagement:
                                            <a [uiSref]="RouteName.EngagementSummaryV2" class="dm-link"
                                                [uiParams]="{engagementId: engagement.id}"
                                                [id]="setIdForEntityName('engagement', i)" (click)="closeModal()">
                                                {{engagement.name}}
                                            </a>
                                        </b>
                                    </p>
                                    <p>
                                        EBS:
                                        <span>{{engagement.id}}</span>
                                    </p>
                                    <!-- <p>
                                        Domain:
                                        <span>{{engagement.primaryDomain | dmDisplayDashIfEmptyOrNull}}</span>
                                    </p> -->
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{engagement.plannedCurrency}}
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">                                    
                                    {{engagement.eacMargin ? (engagement.eacMargin | number : '1.0-2') + '%':'-'}}
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">                                    
                                    {{engagement.margin ? (engagement.margin | number : '1.0-2') + '%':'-'}}
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{engagement.eacCost | dmDisplayCurrencyOrDash: engagement.plannedCurrency}}
                                    <span *ngIf="!engagement.eacCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{engagement.plannedCost | dmDisplayCurrencyOrDash: engagement.plannedCurrency}}
                                    <span *ngIf="!engagement.plannedCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">
                                    {{engagement.eacRevenue | dmDisplayCurrencyOrDash: engagement.plannedCurrency}}
                                    <span *ngIf="!engagement.eacRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">
                                    {{engagement.plannedRevenue | dmDisplayCurrencyOrDash: engagement.plannedCurrency}}
                                    <span *ngIf="!engagement.plannedRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{engagement.eacHours > 0 ? ((engagement.eacHours | number: '1.0-2')+ ' hrs'): '-'}}
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{engagement.labor > 0 ? ((engagement.labor| number: '1.0-2') + ' hrs'): '-'}}
                                </div>
                            </ng-container>
                        </div>
                        <div role="row" class="dm-grid-view-table__row"
                            *ngFor="let project of engagement.projectFinancials; let j = index"
                            [ngClass]="{'grey_background':project.overallStatus==='Closed'}">
                            <ng-container scope="row" role="rowheader">
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-l-imp-24 dm-p-x-12-y-8">
                                    <p class="font-caption-alt pd-l-12">
                                        <b>
                                            Project:
                                            <a [uiSref]="RouteName.ProjectSummaryV2" [uiParams]="{projectId: project.id}" class="dm-link"
                                                [id]="setIdForEntityName('project', project.id)" (click)="closeModal()">
                                                {{project.name}}
                                            </a>
                                        </b>
                                    </p>
                                    <p class="pd-l-12">
                                        EBS:
                                        <span>{{project.id}}</span>
                                    </p>
                                    <!-- <p class="pd-l-12">
                                        Domain:
                                         <span>{{engagement.primaryDomain | dmDisplayDashIfEmptyOrNull}}</span>
                                    </p> -->
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{project.plannedCurrency}}
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">
                                    {{project.eacMargin ? (project.eacMargin | number : '1.0-2') + '%':'-'}}                                   
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">
                                    {{project.margin ? (project.margin | number : '1.0-2') + '%':'-'}}                                   
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{project.eacCost | dmDisplayCurrencyOrDash: project.plannedCurrency }}
                                    <span *ngIf="!project.eacCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{project.plannedCost | dmDisplayCurrencyOrDash: project.plannedCurrency}}
                                    <span *ngIf="!project.plannedCost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">
                                    {{project.eacRevenue | dmDisplayCurrencyOrDash: project.plannedCurrency}}
                                    <span *ngIf="!project.eacRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize grey-cell dm-p-x-12-y-8">
                                    {{project.plannedRevenue | dmDisplayCurrencyOrDash: project.plannedCurrency }}
                                    <span *ngIf="!project.plannedRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{project.eacHours > 0 ? ((project.eacHours | number: '1.0-2') + ' hrs'): '-'}}
                                </div>
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                    {{project.labor > 0 ? ((project.labor | number:'1.0-2') + ' hrs'): '-'}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>