<section id="financial-summary-table" class="dm-fcr-details">
    <dm-loading [loadingText]="loadingMessage" [errorText]="fcrDetailsError" [isCustomText]="true"
        [showLoading]="!fcrDetails && !fcrDetailsError" *ngIf="!fcrDetails">
    </dm-loading>

    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0" *ngIf="fcrDetails">
        <section class="dm-fcr-details-form-data dm-p-t-10">
            <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0">
                <section class="col-md-6 col-sm-6 col-xs-12 dm-p-x-imp-0">
                    <section class="col-md-6 col-sm-6">
                        <h4 class="dm-fcr-details-item__title">Engagement ID</h4>
                        <p class="font-caption-alt">
                            <span [textContent]="fcrDetails?.engagementId | dmDisplayDashIfEmptyOrNull"></span>
                        </p>
                    </section>
                </section>
                <section class="col-md-6 col-sm-6">
                    <h4 class="dm-fcr-details-item__title">Requested By</h4>
                    <p class="font-caption-alt">
                        <span [textContent]="fcrDetails?.createdBy | dmDisplayDashIfEmptyOrNull"></span>
                        on
                        <span [textContent]="fcrDetails?.createdOn | date:'dd-MMM-yyyy'"></span>
                    </p>
                </section>
            </section>
            <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0">
                <section class="col-md-6 col-sm-6 col-xs-12">
                    <h4 class="dm-fcr-details-item__title">Title</h4>
                    <p class="font-caption-alt" [textContent]="fcrDetails?.description | dmDisplayDashIfEmptyOrNull">
                    </p>
                </section>
                <section class="col-md-6 col-sm-6 col-xs-12">
                    <h4 class="dm-fcr-details-item__title">Approval Status</h4>
                    <p class="font-caption-alt" [textContent]="fcrDetails?.status | dmDisplayDashIfEmptyOrNull"></p>
                </section>
            </section>
            <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0">
                <section class="col-md-6 col-sm-6 col-xs-12">
                    <h4 class="dm-fcr-details-item__title">Executive Summary</h4>
                    <p class="font-caption-alt">
                        <span [textContent]="fcrDetails?.executiveSummary | dmDisplayDashIfEmptyOrNull"></span>
                    </p>
                </section>
                <section class="col-md-6 col-sm-6 col-xs-12">
                    <h4 class="dm-fcr-details-item__title">Business Justification</h4>
                    <p class="font-caption-alt">
                        <span [textContent]="fcrDetails?.businessJustification | dmDisplayDashIfEmptyOrNull"></span>
                    </p>
                </section>
            </section>
            <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0" *ngIf="fcrDetails?.risks && (fcrDetails?.risks.length || fcrDetails?.issues.length)">
                <section class=" col-md-6 col-sm-6 col-xs-12">
                    <h4 class="dm-fcr-details-item__title">Risks</h4>
                    <p class="font-caption-alt">
                        <ng-container *ngFor="let risk of fcrDetails?.risks; let isLast=last">
                            <span>{{risk?.title | dmDisplayDashIfEmptyOrNull}}{{isLast ? '' : ', '}}</span>
                        </ng-container>
                    </p>
                </section>
                <section class="col-md-6 col-sm-6 col-xs-12" *ngIf="fcrDetails?.issues">
                    <h4 class=" dm-fcr-details-item__title">Issues</h4>
                    <p class="font-caption-alt">
                        <ng-container *ngFor="let issue of fcrDetails?.issues; let isLast=last">
                            <span>{{issue?.title | dmDisplayDashIfEmptyOrNull}}{{isLast ? '' : ', '}}</span>
                        </ng-container>
                    </p>
                </section>
            </section>

            <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0" *ngIf="fcrDetails?.reason">
                <section class="col-md-6 col-sm-6 col-xs-12">
                    <h4 class="dm-fcr-details-item__title">Reason</h4>
                    <p class="font-caption-alt">
                        <span [textContent]="fcrDetails?.reason?.text | dmDisplayDashIfEmptyOrNull"></span>
                    </p>
                </section>
                <section class="col-md-6 col-sm-6 col-xs-12" *ngIf="fcrDetails?.comments">
                    <h4 class=" dm-fcr-details-item__title">Comments</h4>
                    <p class="font-caption-alt">
                        <span [textContent]="fcrDetails?.comments | dmDisplayDashIfEmptyOrNull"></span>
                    </p>
                </section>
            </section>
            <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0" *ngIf="fcrDetails?.reason">
                <section class="col-md-6 col-sm-6 col-xs-12">
                    <h4 class="dm-fcr-details-item__title">Attachment</h4>
                    <p class="font-caption-alt">
                        <a class="dm-link" *ngIf="fcrAttachmentLink" [href]="fcrAttachmentLink"
                            [textContent]="fcrDetails?.attachment?.fileName | dmDisplayDashIfEmptyOrNull"></a>
                        <span *ngIf="!fcrAttachmentLink">{{fcrDetails?.attachment?.fileName |
                            dmDisplayDashIfEmptyOrNull}}</span>
                    </p>
                </section>

            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-p-l-15 dm-p-b-10">
            <div class="display-flex dm-p-b-10">
                <h4 class="dm-fcr-details-item__title">Approvers/Status </h4><span
                    class="dm-badge font-caption-alt dm-approval-status-badge"
                    [ngClass]="getApprovedStatusClass(fcrDetails?.status)">{{fcrDetails?.status |
                    dmDisplayDashIfEmptyOrNull}}</span>
            </div>
            <dm-financial-cr-approvers *ngIf="!approversStatusError" [FinancialCrApproversList]="approversStatusData"
                [FinancialCrApproversListError]="approversStatusError"></dm-financial-cr-approvers>
            <p *ngIf="approversStatusError" role="alert"
                class="font-caption-alt error-msg col-sm-6 col-md-6 col-xs-5 p-t-4 dm-p-l-imp-0" aria-live="polite">
                {{approversStatusError}}
            </p>
        </section>

        <toggle-section *ngIf="fcrDetails?.changeRequestRoleDetails?.changeRequestDetails" [title]="'Role Details'">
            <div rightSideContent class="dm-p-x-10">
                <span>Total Additional Cost
                    ({{planCurrency}})</span>
                <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'" [dmTriggers]="'hover'"
                    [ngbTooltipContent]="totalsContent" [dmIcon]="'totals-info-icon icon icon-info'"
                    ariaLabelToolTip="Status Information">
                </dm-tooltip> :
                <span>{{totalAdditionalCost | number : '1.2-2'}}</span>
            </div>

            <div class="dm-nav__tabs dm-p-t-10-l-10">
                <div *ngFor="let tab of tabsContent">
                    <span [id]="tab.id" class="set-focus" [ngClass]="{'dm-nav__active-tab' : tab?.isActive}"
                        tabindex="0" (click)="toggleTab(tab)" (keyup.enter)="toggleTab(tab)"
                        (keyup.Space)="toggleTab(tab)" (keyup.arrowright)="focusForNavBarArrowKey(tab.id, 'right')"
                        (keyup.arrowleft)="focusForNavBarArrowKey(tab.id, 'left')">{{tab?.displayName}}
                        <span class="dm-m-imp-0"
                            *ngIf="tab.id === ResourceType?.Labor">({{fcrLaborViewDetails?.length}})</span>
                        <span class="dm-m-imp-0"
                            *ngIf="tab.id === ResourceType?.Unit">({{fcrUnitsViewDetails?.length}})</span>
                        <span class="dm-m-imp-0"
                            *ngIf="tab.id === ResourceType?.SubconFF">({{fcrSubconViewDetails?.length}})</span>
                        <span class="dm-m-imp-0"
                            *ngIf="tab.id === ResourceType?.Expense">({{fcrExpenseViewDetails?.length}})</span>
                    </span>
                </div>
            </div>
            <section class="dm-p-imp-20" [style.display]="showLabor ? 'block' : 'none'">
                <dm-fcr-labor-view-details [fcrLaborViewDetails]="fcrLaborViewDetails"
                    [planCurrency]="fcrDetails?.planCurrency">
                </dm-fcr-labor-view-details>
            </section>
            <section class="dm-p-imp-20" [style.display]="showUnits ? 'block' : 'none'">
                <dm-fcr-units-view-details [fcrUnitsViewDetails]="fcrUnitsViewDetails"
                    [planCurrency]="fcrDetails?.planCurrency">
                </dm-fcr-units-view-details>
            </section>
            <section class="dm-p-imp-20" [style.display]="showSubconFF ? 'block' : 'none'">
                <dm-fcr-subcon-view-details [fcrSubconViewDetails]="fcrSubconViewDetails"
                    [planCurrency]="fcrDetails?.planCurrency">
                </dm-fcr-subcon-view-details>
            </section>
            <section class="dm-p-imp-20" [style.display]="showExpenses ? 'block' : 'none'">
                <dm-fcr-expense-view-details [fcrExpenseViewDetails]="fcrExpenseViewDetails"
                    [planCurrency]="fcrDetails?.planCurrency">
                </dm-fcr-expense-view-details>
            </section>
        </toggle-section>
    </section>
</section>

<ng-template #totalsContent>
    <div class="fcr-totals-section">
        <div class="dm-font-weight-500 justify-space-between">
            <span>Total Additional Cost ({{fcrDetails?.planCurrency}}) </span>
            <span>{{totalAdditionalCost | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Labor</span>
            <span>{{fcrTotals?.labor | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Subcon</span>
            <span>{{fcrTotals?.subcon | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Expense</span>
            <span>{{fcrTotals?.expense | number : '1.2-2'}}</span>
        </div>
        <div class="justify-space-between">
            <span>Units</span>
            <span>{{fcrTotals?.unit | number : '1.2-2'}}</span>
        </div>
    </div>
</ng-template>