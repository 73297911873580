import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { ProjectDetailService } from "../../common/services/project-detail.service";
import * as projectDetailsActions from "./project-details.action";

@Injectable()
export class ProjectDetailsEffect {

    @Effect()
    public loadProjectDetails$ = this.actions$
        .ofType(projectDetailsActions.ProjectDetailsActionTypes.LOAD_PROJECT_DETAILS)
        .pipe(
            switchMap((action: projectDetailsActions.LoadProjectDetails) => fromPromise(
                this.projectDetailService.getProjectFullDetails(action.projectId)
            )
                .pipe(
                    switchMap((projectEngDetails) => [
                        new projectDetailsActions.LoadProjectDetailsSuccess(action.projectId, projectEngDetails),
                    ]),
                    catchError((error) => of(new projectDetailsActions.LoadProjectDetailsFail(action.projectId, error)))
                )
            ));

    public constructor(
        private actions$: Actions,
        @Inject(ProjectDetailService) private projectDetailService: ProjectDetailService
    ) { }
}
