import { Injectable, Inject, forwardRef } from "@angular/core";
import { ErrorSeverityLevel, FxpConstants, FxpMessageService } from "@fxp/fxpservices";
import { DecimalPipe } from "@angular/common";

import { AmendmentType, IChangeRequest } from "./contracts/changerequest.contract";
import { APIConstants, Services, SourceConstants } from "../application.constants";
import { ChangeRequestService } from "../../common/services/change-request.service";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { ICRDetailsList, IAmendmentV2, IChangeRequestRoleDetail, IMisalignedAmendment, IMisalignedAmendmentProjectViewModel, IMisalignedAmendmentPairedDemandsViewModel, IMisalignmentValidationConfiguration, MisalignmentValidationErrorTypeEnum, MisalignmentStatus, IMisalignedProject, IMisalignedAmendmentViewModel, ICompanyCode, IMisalignmentNotification, MisalignmentVarianceTypeEnum } from "../../components/amendments/amendments.contract";
import { IDemandDetails, IResourceData } from "./contracts/project.service.v2.contracts";
import { ContractType, IEngagementDetailsV2, IProjectDetailsV2, ITaskDetailsV2 } from "./contracts/wbs-details-v2.contracts";
import { ProjectService } from "./project.service";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { IFinancialRoles } from "./contracts/projectservice-functions.contract";
import { v4 as uuid } from "uuid";
import { IAmendmentThresholdValues, IProjectThresholdValues } from "./contracts/project.service.contracts";

@Injectable()
export class AmendmentsService extends DmServiceAbstract {

    private visitedEntities: { [entityId: string]: ICRDetailsList[] } = {};
    private misalignmentValidationConfiguration: IMisalignmentValidationConfiguration;
    private misalignedAmendmentsEnabled: boolean = false;
    private projectServiceBaseUriV2: string;
    private projectServiceBaseUriV21: string;
    private subscriptionKey: string;
    private readonly FXP_CONSTANTS = FxpConstants;
    private isAnyMisaligned: boolean;
    private companyCodeList: ICompanyCode[] = [];
    private nonBillResourceItemId: string = "0000000000";
    private nonBillValue: string = "NON-BILL";


    public constructor(
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(ChangeRequestService) private changeRequestService: ChangeRequestService,
        @Inject(DataService) private dataService: DataService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(DecimalPipe) private numberPipe: DecimalPipe,
    ) {
        super(dmLogger, Services.Amendments);
        this.misalignmentValidationConfiguration = this.configurationService.getValue<IMisalignmentValidationConfiguration>("contractualMisalignmentValidations");
        this.misalignedAmendmentsEnabled = this.configurationService.isFeatureEnabled("MisalignedAmendments");
        this.projectServiceBaseUriV2 = this.configurationService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.projectServiceBaseUriV21 = this.configurationService.getValue<string>("projectServiceBaseUri") + "v2.1";
        this.subscriptionKey = this.configurationService.getValue<string>("projectServiceSubscriptionKey");
        this.companyCodeList = this.configurationService.getValue<ICompanyCode[]>("companyCodeList");
    }

    /**
     * Get CR/Amendments details list by calling the appropriate APIs. All calls are made in engagement context as there is no filtering on SAP side
     * at project level in new APIs by design.
     *
     * @param {string} engagementId
     * @returns {Promise<ICRDetailsList[]>}
     * @memberof AmendmentsService
     */
    public getCRDetailsList(engagementId: string): Promise<ICRDetailsList[]> {
        if (this.visitedEntities[engagementId]) {
            return Promise.resolve(this.visitedEntities[engagementId]);
        }
        let crDetails: IChangeRequest[];

        const crDetailsPromise: Promise<void> = this.changeRequestService.getAmendmentsByWbsIdV2(engagementId, AmendmentType.Contractual)
            .then((response: IChangeRequest[]) => {
                crDetails = response;
                this.isAnyMisaligned = crDetails.some((r) => this.isAmendmentMisaligned(r)); // If any misaligned set globally misaligned
            });

        return Promise.all([crDetailsPromise])
            .then(() => {
                /* This will return empty array in case of any errors */
                return this.buildCrDetailsList(crDetails);
            })
            .then((response: ICRDetailsList[]) => {
                this.visitedEntities[engagementId] = response;
                return response;
            })
            .catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.GetCRDetailsList, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return undefined;
            });
    }

    /**
     * Gets the count of the amendments in the CRDetailsList.
     * Either gets the count from the visited entities object, or calls the API itself to get the list and takes the length;
     */
    public getCountOfAmendments(engagementId: string): Promise<number> {
        if (this.visitedEntities[engagementId]) {
            return Promise.resolve(this.visitedEntities[engagementId].length);
        }
        return this.getCRDetailsList(engagementId).then((response: ICRDetailsList[]) => {
            return response.length;
        });
    }

    /**
     * Gets an individual Change Request/Amendment from an Engagement based on its Engagement ID and CR Number from the Project Management API
     * @param engId
     * @param crNumber
     */
    public getAmendmentByEngagementIDAndCRNumberV2(crNumber: string, engagementId: string): Promise<IAmendmentV2> {
        const url = `${this.projectServiceBaseUriV21}/engagement/${engagementId}/financials/changerequests/${crNumber}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.AmendmentDetailsByEngagementIdAndCrNumber);
    }

    /**
     * Gets the misaligned amendments related to an engagement ID
     *
     * @returns {Promise<IMisalignedAmendment>} the misaligned amendments for the given engagement ID from the Project Service API
     * @memberof AmendmentsService
     */
    public getMisalignedAmendments(engagementId: string, changeRequestId: string): Promise<IMisalignedAmendment> {
        const url: string = `${this.projectServiceBaseUriV2}/amendment/contractual/engagement/${engagementId}/changeRequestId/${changeRequestId}/misalignment`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetMisalignedAmendments);
    }

    /**
     * Gets the active misaligned amendments for a list of engagementIds
     *
     * @returns {Promise<IMisalignedAmendment>} the misaligned amendments for the given engagement ID from the Project Service API
     * @memberof AmendmentsService
     */
    public getActiveMisalignedAmendments(engagementIds: string[]): Promise<IMisalignmentNotification[]> {
        const url: string = `${this.projectServiceBaseUriV2}/amendment/contractual/misalignment/notifications`;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.GetActiveMisalignedAmendments, engagementIds);

    }

    /**
     * Puts the updated misaligned amendments to the backend. Amendments are submitted at a project level such that users can update multiple
     * amendments under the same project.
     *
     * @param {string} engagementId the engagement Id the project and amendments are under
     * @param {string} changeRequestId the change request ID the amendments relate to 
     * @param {IMisalignedProject[]} updatedMisalignedProjectsWithAmendments the list of projects with newly updated engagements (can be one or more projects)
     * @returns {Promise<void>} Returns a promise of 204 no content on success
     * @memberof AmendmentsService
     */
    public putMisalignedAmendmentUpdates(engagementId: string, changeRequestId: string, updatedMisalignedProjectsWithAmendments: IMisalignedProject[]): Promise<void> {
        const url: string = `${this.projectServiceBaseUriV2}/amendment/contractual/engagement/${engagementId}/changeRequestId/${changeRequestId}/misalignment/projects`;
        return this.dataService.putData(url, this.subscriptionKey, APIConstants.PutMisalignedAmendmentUpdates, updatedMisalignedProjectsWithAmendments);
    }

    /**
     * Posts the updated misaligned amendments to SAP, first by validating the engagement information with SAP on the back end, 
     * and if successful, will commit the changes. If the validation fails, the changes will not be commited. 
     *
     * @param {string} engagementId
     * @param {string} changeRequestId
     * @returns {Promise<any>}
     * @memberof AmendmentsService
     */
    public postMisalignedEngagement(engagementId: string, changeRequestId: string): Promise<void> {
        return this.postValidateMisalignedEngagement(engagementId, changeRequestId).then(() => {
            return this.postCommitMisalignedEngagement(engagementId, changeRequestId);
        });
    }

    /**
     * Creates the misaligned amendments viewmodel object array. Each item includes the project level, the demands associated with it, and then the misaligned amendments matching those demands.
     *
     * @memberof AmendmentsService
     */
    public getMisalignedAmendmentsViewModel(misalignedAmendments: IMisalignedAmendment, demands: IDemandDetails[], engagementDetails: IEngagementDetailsV2, allFinancialRoles: IFinancialRoles[]): IMisalignedAmendmentProjectViewModel[] {
        if (!misalignedAmendments) {
            return undefined;
        }
        const crNumber: string = misalignedAmendments.id;
        const viewModelArray: IMisalignedAmendmentProjectViewModel[] = [];
        for (const project of misalignedAmendments.misalignedProjects) {
            if (project && project.misalignedLineItems) {
                const projectId: string = project.projectId;
                let viewModel: IMisalignedAmendmentProjectViewModel = viewModelArray.filter((x) => x.projectId === projectId)[0];
                if (!viewModel) { /* If project level does not exist yet, create it */
                    const projectDetails: IProjectDetailsV2 = engagementDetails.projects.filter((x) => x.id === projectId)[0];
                    if (!projectDetails) {
                        throw new Error(`Unable to match misaligned projects with the engagement, project ID ${projectId} is not part of engagement ${engagementDetails.id}`);
                    }

                    const taskIds: string[] = this.projectService.getTaskIdListFromProject(projectDetails);
                    let projectType: ContractType;
                    if (projectDetails.contractType === ContractType.FixedFee) {
                        projectType = ContractType.FixedFee;
                    }
                    else if (projectDetails.contractType === ContractType.TimeAndMaterial) {
                        projectType = ContractType.TimeAndMaterial;
                    }
                    viewModel = {
                        isExpand: true,
                        hasBeenSaved: project.misalignmentStatus === MisalignmentStatus.Submitted,
                        projectId,
                        projectType,
                        availableTaskIds: taskIds,
                        projectName: projectDetails.name,
                        pairedDemands: [],
                        originalPlanCostTotal: project.originalPlanCostTotal,
                        originalPlanRevenueTotal: project.originalPlanRevenueTotal,
                        originalRequestedQuantityTotal: project.originalRequestedQuantityTotal,
                        isSplitDemandDisabled: false
                    };
                }
                const pairedDemands: IMisalignedAmendmentPairedDemandsViewModel[] = viewModel.pairedDemands;
                for (const role of project.misalignedLineItems) {
                    const filteredDemand = demands.filter((demand) => demand.demandId === role.demandId)[0];
                    if (filteredDemand && filteredDemand.planned) {// not sure which demand item to pull yet, so just took the first one that isnt an array
                        const demandId: string = filteredDemand.demandId;
                        let pair: IMisalignedAmendmentPairedDemandsViewModel = pairedDemands.filter((x) => x.originalAmendment && x.originalAmendment.demandId === demandId)[0];
                        if (!pair) { /* If a pair for this demandId doesn't exist yet, create it and add the role to it*/

                            pair = {
                                pairId: uuid(),
                                originalAmendment: this.getMisalignedAmendmentViewModelLineItems(crNumber, filteredDemand.planned, engagementDetails, allFinancialRoles, false, filteredDemand.staffed),
                                misalignedAmendment: this.getMisalignedAmendmentViewModelLineItems(crNumber, role, engagementDetails, allFinancialRoles, true, null),
                                errorsResolved: false,
                                splittedLineItem: role.isSplittedLineItem ? role.isSplittedLineItem : false
                            };
                            pairedDemands.push(pair);
                        } else {
                            this.logError(SourceConstants.Method.GetMisalignedAmendmentsViewModel, `A duplicate demand ID (${demandId}) was found for this project (${project.projectId}). Duplicate Demand IDs are disallowed when pairing misaligned amendments`, "400");
                        }
                    }
                    else { /* No matching demand ID exists, so the misaligned amendment is an orphan with no paired original Amendment/demand*/
                        const pair: IMisalignedAmendmentPairedDemandsViewModel = {
                            pairId: uuid(),
                            originalAmendment: undefined, /* Left blank because no matching demand*/
                            misalignedAmendment: this.getMisalignedAmendmentViewModelLineItems(crNumber, role, engagementDetails, allFinancialRoles, true, null),
                            errorsResolved: false,
                            splittedLineItem: role.isSplittedLineItem ? role.isSplittedLineItem : false
                        };
                        pairedDemands.push(pair);
                    }
                }
                viewModelArray.push(viewModel);
            }
        }

        return viewModelArray;
    }

    /**
     * Checks if an amendment is misaligned by checking the attributes on the object, and also checking if misalignments are enabled for the env.
     * If the feature is not enabled for the env, will return false regardless (all amendments are considered correct).
     * Otherwise will check for the misalignment attribute and if the status of it is Initiated, then will return true.
     * Returns false otherwise. 
     * @param {IChangeRequest} amendment
     * @returns {boolean} Returns boolean if the amendment is misaligned (true) or not/other (false)
     * @memberof AmendmentsComponent
     */
    public isAmendmentMisaligned(amendment: IChangeRequest): boolean {
        if (!this.misalignedAmendmentsEnabled) {
            return false;
        }
        if (amendment && amendment.misalignment && (amendment.misalignment.misalignmentStatus === MisalignmentStatus.Initiated)) {
            return true;
        }
        return false;
    }

    /**
     * Checks if an amendment is misaligned submitted by checking the attributes on the object, and also checking if misalignments are enabled for the env.
     * If the feature is not enabled for the env, will return false regardless (all amendments are considered correct).
     * Otherwise will check for the misalignment attribute and if the status of it is Initiated, then will return true.
     * Returns false otherwise. 
     * @param {IChangeRequest} amendment
     * @returns {boolean} Returns boolean if the amendment is misaligned (true) or not/other (false)
     * @memberof AmendmentsComponent
     */
    public isAmendmentMisalignedSubmitted(amendment: IChangeRequest): boolean {
        if (!this.misalignedAmendmentsEnabled) {
            return false;
        }
        if (amendment && amendment.misalignment && amendment.misalignment.misalignmentStatus === MisalignmentStatus.Submitted) {
            return true;
        }
        return false;
    }


    /**
     * Checks if an amendment is misaligned Initiated/submitted by checking the attributes on the object, and also checking if misalignments are enabled for the env.
     * If the feature is not enabled for the env, will return false regardless (all amendments are considered correct).
     * Otherwise will check for the misalignment attribute and if the status of it is Initiated, then will return true.
     * Returns false otherwise. 
     * @param {IChangeRequest} amendment
     * @returns {boolean} Returns boolean if the amendment is misaligned (true) or not/other (false)
     * @memberof AmendmentsComponent
     */
    public isAmendmentMisalignmentResolutionInProgress(amendment: IChangeRequest): boolean {
        if (!this.misalignedAmendmentsEnabled) {
            return false;
        }
        if (amendment && amendment.misalignment && (amendment.misalignment.misalignmentStatus === MisalignmentStatus.Initiated || amendment.misalignment.misalignmentStatus === MisalignmentStatus.Submitted)) {
            return true;
        }
        return false;
    }

    /**
     * Checks if any Amendment is misaligned, and also checking if misalignments are enabled for the env.
     * If the feature is not enabled for the env, will return false regardless (all amendments are considered correct).
     */
    public isAnyAmendmentMisaligned(): boolean {
        if (!this.misalignedAmendmentsEnabled) {
            return false;
        }
        return this.isAnyMisaligned;
    }

    /**
     * Gets the misalignment validation error array if any exist for the given update project object. If none exist, return array will be empty.
     * Runs the check for both FF and T&M.
     *
     * @param {IMisalignedAmendmentProjectViewModel} project
     * @returns {IProjectThresholdValues[]} A list of validation errors for the project and its objects being updated. Will return empty if no errors.
     * @memberof AmendmentsService
     */
    public getMisalignmentValidationErrors(project: IMisalignedAmendmentProjectViewModel): IProjectThresholdValues {
        if (!project) {
            return undefined;
        }
        let amendmentThresholds: IAmendmentThresholdValues[] = [];
        if (project.projectType === ContractType.FixedFee) {
            amendmentThresholds = this.getMisalignmentValidationResultForFF(project);
        }
        else if (project.projectType === ContractType.TimeAndMaterial) {
            amendmentThresholds = this.getMisalignmentValidationResultForTM(project);
        }

        return {
            projectId: project.projectId,
            projectDescription: project.projectName,
            amendmentThresholds,
            marginPercentage: undefined,
            marginThresholdRange: undefined,
            isMarginValid: undefined,
            revenueMargin: undefined,
            revenueThresholdRange: undefined,
            isRevenueValid: undefined,
            costMargin: undefined,
            costThresholdRange: undefined,
            isCostValid: undefined,
            contractType: project.projectType
        };
    }

    /**
     * Builds the misaligned amendment view model line item object. Used to
     * standardize the attributes for a misaligned amendment and the original demand/amendment
     * that it is paired with. Attributes for this are shown on the UI as lineitem values.
     *
     * @private
     * @param {string} crNumber
     * @param {(IChangeRequestRoleDetail | IResourceData)} amendment
     * @param {IChangeRequestRoleDetail} amendmentRoleDetails
     * @param {boolean} isMisalignedAmendment
     * @returns {IMisalignedAmendmentViewModel}
     * @memberof AmendmentsService
     */
    public getMisalignedAmendmentViewModelLineItems(crNumber: string, amendment: IChangeRequestRoleDetail | IResourceData, engagementDetails: IEngagementDetailsV2, allFinancialRoles: IFinancialRoles[], isMisalignedAmendment: boolean, staffedDetails: IResourceData[]): IMisalignedAmendmentViewModel {
        if (!amendment) {
            return undefined;
        }
        let amendmentViewModel: IMisalignedAmendmentViewModel;
        if (!isMisalignedAmendment) {
            const originalAmendment: IResourceData = amendment as IResourceData;
            const addtlRevenue: number = originalAmendment.plannedRevenue;
            const addtlCost: number = originalAmendment.plannedCost;
            const financialRole = allFinancialRoles.filter((r) => r.rolePartNumber === originalAmendment.roleId)[0];
            const resourceLocation = this.companyCodeList.filter((c) => c.companyCode === originalAmendment.resourceLocation)[0];
            const taskId: string = originalAmendment.structureId;
            const task: ITaskDetailsV2 = this.projectService.getTaskByTaskIdFromEngagement(engagementDetails, taskId);
            const staffedLbr: number = staffedDetails && staffedDetails.filter((x) => x.staffedIndicator === true).map((s) => s.staffedHours).reduce((accumulator, currentValue) => accumulator + currentValue);

            amendmentViewModel = {
                projectId: undefined, /* Set below */
                crNumber,
                taskDescription: task ? task.name : undefined,
                taskId,
                originator: "CFP",
                role: financialRole,
                demandId: originalAmendment.demandId,
                plannedLabor: originalAmendment.plannedHours,
                billingRoleId: originalAmendment.resourceItemId === this.nonBillResourceItemId ? this.nonBillValue : originalAmendment.resourceItemId,
                resourceLocation,
                costRate: originalAmendment.plannedCostRate,
                addtlCost,
                billRate: originalAmendment.billRate,
                addtlRevenue,
                staffedLabour: staffedLbr
            };
        }
        else {
            const misalignedAmendment: IChangeRequestRoleDetail = amendment as IChangeRequestRoleDetail;
            misalignedAmendment.resourceLocationKey = misalignedAmendment.resourceLocationKey.startsWith("000000") ? misalignedAmendment.resourceLocationKey.slice(6, misalignedAmendment.resourceLocationKey.length) : misalignedAmendment.resourceLocationKey;
            const costRate: number = misalignedAmendment.costRate ? misalignedAmendment.costRate : 0;
            const billRate: number = misalignedAmendment.billRate ? misalignedAmendment.billRate : 0;
            const plannedLabor = misalignedAmendment.requestedQuantity ? misalignedAmendment.requestedQuantity : 0;
            const addtlRevenue: number = misalignedAmendment.planRevenue;
            const addtlCost: number = misalignedAmendment.planCost;
            const financialRole = allFinancialRoles.filter((r) => r.activityCode === misalignedAmendment.role)[0];
            const resourceLocation = this.companyCodeList.filter((c) => c.companyCode === misalignedAmendment.resourceLocationKey)[0];
            const demandId: string = misalignedAmendment.demandId ? misalignedAmendment.demandId : "New";
            amendmentViewModel = {
                projectId: undefined, /* Set below*/
                crNumber,
                taskDescription: misalignedAmendment.taskDescription,
                taskId: misalignedAmendment.taskId,
                originator: "CompassOne",
                role: financialRole,
                demandId,
                plannedLabor,
                billingRoleId: misalignedAmendment.billingRoleId === this.nonBillResourceItemId ? this.nonBillValue : misalignedAmendment.billingRoleId,
                resourceLocation,
                costRate,
                addtlCost,
                billRate,
                addtlRevenue,
                originalMisalignedAmendmentData: misalignedAmendment, /* Used as a reference for processing on the API */
                staffedLabour: 0
            };
        }
        amendmentViewModel.projectId = this.projectService.getProjectIdFromTaskId(amendmentViewModel.taskId);
        return amendmentViewModel;
    }

    /**
     * Converts the given misaligned amendment viewmodel object back into the normal amendment object (IChangeRequestRoleDetail)
     * in order to attach it to the save updated misaligned amendment API. Much of the data will be undefined since it was stripped out.
     *
     * @param {IMisalignedAmendmentViewModel} misalignedAmendment
     * @returns {IChangeRequestRoleDetail} The amendment viewmodel's data converted back into a CR object
     * @memberof AmendmentsService
     */
    public getUpdatedMisalignedAmendment(misalignedAmendmentViewModel: IMisalignedAmendmentViewModel, isSplittedLineItem?: boolean): IChangeRequestRoleDetail {
        if (!misalignedAmendmentViewModel.originalMisalignedAmendmentData) {
            const message: string = `Unable to get the updated misaligned amendment data for ${misalignedAmendmentViewModel.crNumber} is missing its original amendment information.`;
            this.logError(SourceConstants.Method.GetUpdatedMisalignedAmendment, message, "400");
            this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.error);
            return undefined;
        }
        /* For newly created demands, the viewmodel will show "New" but the API expects an empty demandId */
        const demandId: string = (misalignedAmendmentViewModel.demandId && misalignedAmendmentViewModel.demandId.toLowerCase() === "new") ? undefined : misalignedAmendmentViewModel.demandId;
        return {
            amendmentType: misalignedAmendmentViewModel.originalMisalignedAmendmentData.amendmentType,
            billingRoleId: misalignedAmendmentViewModel.billingRoleId, // updated
            billRate: misalignedAmendmentViewModel.billRate, // updated
            costRate: misalignedAmendmentViewModel.costRate, // updated
            crNumber: misalignedAmendmentViewModel.crNumber, // updated
            deliveryLocationKey: misalignedAmendmentViewModel.originalMisalignedAmendmentData.deliveryLocationKey,
            demandId, // updated
            domainKey: misalignedAmendmentViewModel.originalMisalignedAmendmentData.domainKey,
            laborUnits: misalignedAmendmentViewModel.originalMisalignedAmendmentData.laborUnits,
            planCost: misalignedAmendmentViewModel.addtlCost, // updated
            planCurrency: misalignedAmendmentViewModel.originalMisalignedAmendmentData.planCurrency,
            planRevenue: misalignedAmendmentViewModel.addtlRevenue, // updated
            planVersionId: misalignedAmendmentViewModel.originalMisalignedAmendmentData.planVersionId,
            primaryDomain: misalignedAmendmentViewModel.originalMisalignedAmendmentData.primaryDomain,
            quantity: misalignedAmendmentViewModel.originalMisalignedAmendmentData.quantity,
            requestedQuantity: misalignedAmendmentViewModel.plannedLabor, // updated
            resourceLocationKey: misalignedAmendmentViewModel.resourceLocation.companyCode, // updated
            resourceType: misalignedAmendmentViewModel.originalMisalignedAmendmentData.resourceType,
            role: misalignedAmendmentViewModel.role.activityCode, // updated
            roleDescription: misalignedAmendmentViewModel.role.roleName, // updated
            structuralElement: misalignedAmendmentViewModel.originalMisalignedAmendmentData.structuralElement,
            taskDescription: misalignedAmendmentViewModel.taskDescription, // updated
            taskId: misalignedAmendmentViewModel.taskId, // updated
            isSplittedLineItem
        };
    }

    /**
     * Checks if the amendment is assigned to a newly created demand, as opposed to an existing demand.
     *
     * @param {(IMisalignedAmendmentPairedDemandsViewModel | IMisalignedAmendmentViewModel | IChangeRequestRoleDetail)} item
     * @returns {boolean}
     * @memberof AmendmentsService
     */
    public isAmendmentAssignedToNewlyCreatedDemand(item: IMisalignedAmendmentPairedDemandsViewModel | IMisalignedAmendmentViewModel | IChangeRequestRoleDetail): boolean {
        if (!item) {
            return false;
        }
        if ((item as IMisalignedAmendmentPairedDemandsViewModel).misalignedAmendment) {
            const amendment: IMisalignedAmendmentViewModel = (item as IMisalignedAmendmentPairedDemandsViewModel).misalignedAmendment;
            return this.isAmendmentDemandIdNewOrUndefined(amendment);
        }
        if ((item as IMisalignedAmendmentViewModel | IChangeRequestRoleDetail).crNumber) {
            const amendment: IMisalignedAmendmentViewModel | IChangeRequestRoleDetail = item as IMisalignedAmendmentViewModel | IChangeRequestRoleDetail;
            return this.isAmendmentDemandIdNewOrUndefined(amendment);
        }
        return false;
    }

    /**
     * Checks if the amendment is an orphan (came from misalignment API without an original demand)
     *
     * @param {IMisalignedAmendmentViewModel} misalignedAmendment
     * @param {IMisalignedAmendmentViewModel} originalDemand
     * @returns {boolean}
     * @memberof AmendmentsService
     */
    public isAmendmentOrphaned(misalignedAmendment: IMisalignedAmendmentViewModel, originalDemand: IMisalignedAmendmentViewModel): boolean {
        if (!misalignedAmendment) {
            return false;
        }
        if (!originalDemand && !this.isAmendmentDemandIdNewOrUndefined(misalignedAmendment)) {
            return true;
        }
        return false;
    }

    /** 
     * Gets the validation result for Contractual Misalignment for FF project
     * @returns List of IContractualMisalignmentValidationResult.Empty if no validation errors
     */
    private getMisalignmentValidationResultForFF(project: IMisalignedAmendmentProjectViewModel): IAmendmentThresholdValues[] {
        const originalAdditionalCost: number = project.originalPlanCostTotal ? project.originalPlanCostTotal : 0;
        let finalAdditionalCost: number = 0;
        if (project.pairedDemands.filter((d) => d.splittedLineItem).length > 0) {
            return undefined;
        }
        for (const pair of project.pairedDemands) {            
            finalAdditionalCost += pair.misalignedAmendment.addtlCost;
        }
        const isZero: boolean = originalAdditionalCost === 0;
        const diff: number = isZero ? 0 : (finalAdditionalCost - originalAdditionalCost) / originalAdditionalCost * 100;
        if (Math.abs(diff) > this.misalignmentValidationConfiguration.additionalCostThresholdFF) {
            return [
                {
                    amendmentPercentage: this.numberPipe.transform(diff, "1.0-2") + "%",
                    amendmentThresholdRange: `-${this.numberPipe.transform(this.misalignmentValidationConfiguration.additionalCostThresholdFF, "1.0-2")}% to ${this.numberPipe.transform(this.misalignmentValidationConfiguration.additionalCostThresholdFF, "1.0-2")}%`,
                    amendmentVarianceType: MisalignmentVarianceTypeEnum.Cost,
                    amendmentErrorType: MisalignmentValidationErrorTypeEnum.Error,
                }
            ];
        }

        return undefined;
    }

    /**
     * Gets the validation result for Contractual Misalignment for TM project
     */
    private getMisalignmentValidationResultForTM(project: IMisalignedAmendmentProjectViewModel): IAmendmentThresholdValues[] {

        const originalPlannedHours: number = project.originalRequestedQuantityTotal ? project.originalRequestedQuantityTotal : 0;
        let finalPlannedHours: number = 0;
        const originalAdditionalRevenue: number = project.originalPlanRevenueTotal ? project.originalPlanRevenueTotal : 0;
        let finalAdditionalRevenue: number = 0;
        if (project.pairedDemands.filter((d) => d.splittedLineItem).length > 0) {
            return undefined;
        }
        for (const pair of project.pairedDemands) {       
            finalPlannedHours += pair.misalignedAmendment.plannedLabor;            
            finalAdditionalRevenue += pair.misalignedAmendment.addtlRevenue;
        }

        const amendmentThresholds: IAmendmentThresholdValues[] = [];
        let isZero: boolean = originalPlannedHours === 0;
        const hourDiff: number = isZero ? 0 : (finalPlannedHours - originalPlannedHours) / originalPlannedHours * 100;
        if (Math.abs(hourDiff) > this.misalignmentValidationConfiguration.hoursThresholdTM) {
            amendmentThresholds.push({
                amendmentPercentage: this.numberPipe.transform(hourDiff, "1.0-2") + "%",
                amendmentThresholdRange: `-${this.numberPipe.transform(this.misalignmentValidationConfiguration.hoursThresholdTM, "1.0-2")}% to ${this.numberPipe.transform(this.misalignmentValidationConfiguration.hoursThresholdTM, "1.0-2")}%`,
                amendmentVarianceType: MisalignmentVarianceTypeEnum.Hour,
                amendmentErrorType: MisalignmentValidationErrorTypeEnum.Warning
            });
        }
        isZero = originalAdditionalRevenue === 0;
        const revenueDiff: number = isZero ? 0 : (finalAdditionalRevenue - originalAdditionalRevenue) / originalAdditionalRevenue * 100;
        if (Math.abs(revenueDiff) > this.misalignmentValidationConfiguration.additionalRevenueThresholdTM) {
            amendmentThresholds.push({
                amendmentPercentage: this.numberPipe.transform(revenueDiff, "1.0-2") + "%",
                amendmentThresholdRange: `-${this.numberPipe.transform(this.misalignmentValidationConfiguration.additionalRevenueThresholdTM, "1.0-2")}% to ${this.numberPipe.transform(this.misalignmentValidationConfiguration.additionalRevenueThresholdTM, "1.0-2")}%`,
                amendmentVarianceType: MisalignmentVarianceTypeEnum.Revenue,
                amendmentErrorType: MisalignmentValidationErrorTypeEnum.Error
            });
        }
        if (amendmentThresholds.length) {
            return amendmentThresholds;
        }
        return undefined;
    }

    /**
     * Checks if the demand Id for the given demand/amendment item is either "New" or undefined
     *
     * @private
     * @param {(IMisalignedAmendmentViewModel | IChangeRequestRoleDetail)} item
     * @returns {boolean}
     * @memberof AmendmentsService
     */
    private isAmendmentDemandIdNewOrUndefined(item: IMisalignedAmendmentViewModel | IChangeRequestRoleDetail): boolean {
        if (item) {
            if (item.demandId && item.demandId.toLowerCase() === "new") {
                return true;
            }
            if (!item.demandId) {
                return true;
            }
        }
        return false;
    }

    /**
     * Builds the CR Details List based on some API responses.
     * TODO: in the future, rework this functionality with work from the SAP/PMS API to filter at project level if applicable.
     *
     * @private
     * @param {ICRDetails[]} crDetails value retreived from CR List by engagement API
     * @param {string} engagementId
     * @returns {Promise<ICRDetailsList[]>}
     * @memberof AmendmentsService
     */
    private buildCrDetailsList(crDetails: IChangeRequest[]): ICRDetailsList[] {
        const crDetailsList: ICRDetailsList[] = [];
        if (crDetails) {
            for (const detailCR of crDetails) {
                const data: ICRDetailsList = this.getCrDetailsListForFinancialDetails(detailCR);
                if (data) {
                    crDetailsList.push(data);
                }
            }
        }
        return crDetailsList;
    }

    /**
     * Builds a cr details list item from the given financial details and cr details
     * @param financialDetails
     * @param crDetails
     */
    private getCrDetailsListForFinancialDetails(crDetail: IChangeRequest): ICRDetailsList {
        if (!crDetail) {
            return undefined;
        }

        return {
            crNumber: Number(crDetail.crNumber),
            plannedCostChange: crDetail.requestedCost,
            plannedRevenueChange: crDetail.revenueChange,
            labourHoursChange: crDetail.requestedHours,
            riskReserveChange: crDetail.riskReserveChange,
            crDescription: crDetail.crDescription,
            timeStamp: crDetail.createdOn,
            lastUpdatedBy: crDetail.lastChangedBy,
            status: crDetail.statusDescription,
        };
    }

    /**
     * Validates the changes on the amendments for the engagement ID with SAP on the back-end. This does not commit the changes.
     * After validation, if successful, then you must call the commit misaligned engagement function.
     *
     * @private
     * @param {string} engagementId
     * @param {string} changeRequestId
     * @returns {Promise<void>} Returns 204 no content on successful commit
     * @memberof AmendmentsService
     */
    private postValidateMisalignedEngagement(engagementId: string, changeRequestId: string): Promise<void> {
        const url: string = `${this.projectServiceBaseUriV2}/amendment/contractual/engagement/${engagementId}/changeRequestId/${changeRequestId}/misalignment`;
        const validationObject = { mode: "Validate" };
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.PostValidateMisalignment, validationObject);
    }

    /**
     * Commits the changes on the amendments for the engagement ID to SAP.
     * This should only be called after a successful validation from SAP on the backend.
     *
     * @private
     * @param {string} engagementId
     * @param {string} changeRequestId
     * @returns {Promise<any>} Returns 204 no content on successful commit
     * @memberof AmendmentsService
     */
    private postCommitMisalignedEngagement(engagementId: string, changeRequestId: string): Promise<void> {
        const url: string = `${this.projectServiceBaseUriV2}/amendment/contractual/engagement/${engagementId}/changeRequestId/${changeRequestId}/misalignment`;
        const validationObject = { mode: "Commit" };
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.PostCommitMisalignment, validationObject);
    }

}
