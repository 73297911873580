import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { WhatsNewModal } from "./whats-new-modal.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SlideViewModule } from "../slide-view/slide-view.module";

@NgModule({
    declarations: [
        WhatsNewModal
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        UIRouterModule,
        SlideViewModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        WhatsNewModal
    ],
    exports: [
        WhatsNewModal
    ],
})
export class WhatsNewModalModule { }
