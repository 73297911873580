import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { EntityTeamStructureModule } from "./tiles/entity-team-structure/entity-team-structure.module";
import { EngagementBreakdownStructureModule } from "./tiles/engagement-breakdown-structure/engagement-breakdown-structure.module";
import { ManageEbsEntityDetailsModule } from "./tiles/manage-ebs-entity-details/manage-ebs-entity-details.module";
import { ManageEbsV2Component } from "./manage-ebs-v2.component";
import { PersonCardModule } from "../shared/person-card/person-card.module";

@NgModule({
    declarations: [
        ManageEbsV2Component
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        ManageEbsEntityDetailsModule,
        EntityTeamStructureModule,
        EngagementBreakdownStructureModule,
        PersonCardModule
    ],
    providers: [
        DMLoggerService,
    ],
    exports: [
        ManageEbsV2Component
    ],
    entryComponents: [
        ManageEbsV2Component
    ]
})
export class ManageEbsV2Module { }