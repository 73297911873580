<section class="dm-p-x-12-y-imp-0">
    <section class="row">
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-lg-4 col-md-4 col-sm-4 dm-p-imp-0 dm-m-r-15">
                <dm-entity-summary-details></dm-entity-summary-details>
            </section>
            <section class="col-lg-8 col-md-8 col-sm-8 dm-p-imp-0">
                <dm-engagement-summary-project-v2></dm-engagement-summary-project-v2>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-lg-4 col-md-4 col-sm-4 dm-p-imp-0 dm-m-r-15">
                <dm-contacts-v2></dm-contacts-v2>
            </section>
            <section class="col-lg-8 col-md-8 col-sm-8 dm-p-imp-0">
                <dm-engagement-summary-financials-v2></dm-engagement-summary-financials-v2>
            </section>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-lg-4 col-md-4 col-sm-4 dm-p-imp-0 dm-m-r-15">
                <dm-invoice-summary></dm-invoice-summary>
            </section>
            <section class="col-lg-8 col-md-8 col-sm-8 dm-p-imp-0">
                <dm-summary-financials-breakdown></dm-summary-financials-breakdown>
            </section>

        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-40">
            <section class="col-lg-4 col-md-4 col-sm-4 dm-p-imp-0 dm-m-r-15">
                <dm-key-links-v2></dm-key-links-v2>
            </section>
            <section class="col-lg-4 col-md-4 col-sm-4 dm-p-imp-0 dm-m-r-15">
                <dm-key-actions-v2 *ngIf="listOfKeyActions.length > 0" [listOfActions]="listOfKeyActions">
                </dm-key-actions-v2>
            </section>
            <section class="col-lg-8 col-md-8 col-sm-8 dm-p-imp-0">
                <dm-purchase-orders-v2></dm-purchase-orders-v2>
            </section>
        </section>
    </section>
</section>
