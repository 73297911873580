import { Component, Input, Inject } from "@angular/core";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components } from "../../../common/application.constants";
import { IFinancialCrApprover } from "../../../common/services/contracts/changerequestv2.contract";
import { ProgressBarStatus } from "../../tiles/dm-progress-bar/dm-progress-bar.contracts";

@Component({
    selector: "dm-financial-cr-approvers",
    templateUrl: "./financial-cr-approvers.html",
    styleUrls: ["./financial-cr-approvers.scss"]
})
export class FinancialCrApproversComponent extends DmComponentAbstract {
    @Input() public FinancialCrApproversList: IFinancialCrApprover[] = [];
    @Input() public FinancialCrApproversListError: string;
    public ProgressBarStatus = ProgressBarStatus;
    public loadingMessage: string = "Loading Financial CR Approvers...";
    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(dmLogger, Components.HoursSummary);
    }

    public ngOnInit(): void {
        // this.isComponentLoading = false;  
    }

    /**
     *  Get class by progress bar status
     *
     * @param {string} status
     * @returns
     * @memberof DmProgressBarComponent
     */
    public getClassByStatus(status: string): string {
        switch (status) {
            case ProgressBarStatus.Completed: return "completed";
            case ProgressBarStatus.Failed: return "failed";
            case ProgressBarStatus.NotStarted: return "not-started";
            case ProgressBarStatus.InProgress: return "in-progress";
            case ProgressBarStatus.Warning: return "warning";
        }
    }

    /**
     *  Get Icon by progress bar status
     *
     * @param {string} status
     * @returns
     * @memberof DmProgressBarComponent
     */
    public getIconByStatus(status: string): string {
        switch (status) {
            case ProgressBarStatus.Completed: return "icon-check";
            case ProgressBarStatus.Failed: return "icon-close";
            case ProgressBarStatus.NotStarted: return "icon-lock";
            case ProgressBarStatus.InProgress: return "icon-timer";
            case ProgressBarStatus.Warning: return "icon-warning";
        }
    }

}
