import { Component, Inject } from "@angular/core";
import { Components, FeedBackEntity, InlineSurveyDataList, RouteName, SourceConstants } from "../../common/application.constants";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IUserPreferenceState } from "../../store/userspreferences/userpreference.reducer";
import { getEntireUserPreference } from "../../store/userspreferences/userpreference.selector";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { IState } from "../../store/reducers";
import { FeedbackModalService } from "../tiles/feedback-modal/feedback-modal.service";
import { StateService } from "@uirouter/angular";

@Component({
    selector: "dm-manage-ebs-v2",
    templateUrl: "./manage-ebs-v2.html",
    styleUrls: ["./manage-ebs-v2.scss"]
})
export class ManageEbsV2Component extends DmComponentAbstract {
    public currentRouteName: string;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
        @Inject(StateService) private stateService: StateService
    ) {
        super(dmLogger, Components.ManageEbsEngagementDetails, [
            { component: Components.ManageEbsEngagementTeamStructure, isCritical: true },
            { component: Components.ManageEbsEngagementBreakdownStructure, isCritical: true }
        ]);
    }

    public ngOnInit(): void {
        const userPreference$ = this.store.select((getEntireUserPreference()));
        userPreference$
            .pipe(untilDestroyed(this))
            .subscribe((userPreferenceState: IUserPreferenceState) => {
                this.currentRouteName = this.stateService.current.name;
                if (userPreferenceState.loaded && this.currentRouteName.toLowerCase() === (RouteName.EngagementManageEBS.toLowerCase() || RouteName.ProjectManageEBS.toLowerCase())) {
                    let isManageEBSV2FeedbackSubmitted: boolean = false;
                    const feedbackDetails =
                        userPreferenceState.userPreference.feedbackList;
                    if (
                        feedbackDetails &&
                        feedbackDetails.length > 0 &&
                        feedbackDetails.filter(
                            (obj) => obj.key === FeedBackEntity.ManageEBS
                        ).length > 0
                    ) {
                        isManageEBSV2FeedbackSubmitted = feedbackDetails.filter(
                            (obj) => obj.key === FeedBackEntity.ManageEBS
                        )[0].value;
                    }
                    if (
                        !isManageEBSV2FeedbackSubmitted &&
                        this.sharedFunctionsService.shouldLaunchFeedbackModal(
                            FeedBackEntity.ManageEBS
                        )
                    ) {
                        this.feedbackModalService.openFeedbackModal(
                            InlineSurveyDataList.ManageEBSV2Survey,
                            FeedBackEntity.ManageEBS,
                            SourceConstants.Component.ManageEBSPage
                        );
                    }
                }
            });
    }

}
