import { Injectable, Inject } from "@angular/core";
import moment from "moment";
import { CacheStorageOptions, LocalStorageKey, Services, SourceConstants } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { ContextStorageService } from "./contextstorage.service";
import { DMLoggerService } from "../services/dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { ErrorSeverityLevel } from "@fxp/fxpservices";

/**
 * Contains the cached value of the Promise
 *
 * @interface ICacheContent
 */
interface ICacheContent {
    expiry: number;
    value: any;
    version?: number;
}

/**
 * Cache Service is an promised based in-memory cache
 * Keeps track of in-flight promise and sets a default expiry for cached values
 *
 * @export
 * @class CacheService
 */
@Injectable()
export class CacheService extends DmServiceAbstract {
    private readonly DEFAULT_MAX_AGE: number = 3000;
    private cache: Map<string, ICacheContent> = new Map<string, ICacheContent>();
    private localStorageCacheVersion: number;


    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ContextStorageService) private contextStorageService: ContextStorageService,
    ) {
        super(dmLogger, Services.CacheService);
        this.configManagerService.initialize().then(() => {
            this.localStorageCacheVersion = this.configManagerService.getValue<number>("LocalStorageCacheVersion");
        });
    }

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightPromise and return the source Promise.
     *
     * @param {string} key
     * @param {Promise<any>} [fallback]
     * @param {number} [maxAge]
     * @returns {(Promise<any>)}
     * @memberof CacheService
     */
    public get<T>(key: string, fallback?: () => Promise<T>, maxAgeInSec?: number, cacheStorageOptions: CacheStorageOptions = CacheStorageOptions.InMemory): Promise<T> {

        if (maxAgeInSec !== undefined && maxAgeInSec !== null) {
            maxAgeInSec = this.DEFAULT_MAX_AGE;
        }
        if (cacheStorageOptions === CacheStorageOptions.LocalStorage) {
            return this.contextStorageService.readContent(key as LocalStorageKey).then((data) => {
                const content: ICacheContent = JSON.parse(data);
                if (content.version === this.localStorageCacheVersion && content.expiry > Number(moment.utc().toDate())) {
                    return content.value;
                } else {
                    return this.getDataFromApi(key, fallback, maxAgeInSec);
                }
            }).catch((error) => {
                this.logError(SourceConstants.Method.Get, error, "Unable to Read Content for Key " + key, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return this.getDataFromApi(key, fallback, maxAgeInSec);
            });
        }
        if (this.hasValidCachedKey(key)) {
            return this.cache.get(key).value as Promise<T>;
        } else if (fallback && typeof fallback === "function") {
            const promise = fallback();
            this.set(key, promise, maxAgeInSec);
            return promise;
        } else {
            return Promise.reject("Requested key: " + key + " is not available in Cache");
        }
    }

    /**
     * Clears cache entries if keys contain match phrase provided
     * You can provide a array of matchphrases to filter keys that need to be cleared.
     * @param {string} matchPhrase
     * @memberof CacheService
     */
    public clearMatchedCachedKeys(matchPhrases: string[]): void {
        const keys = Array.from(this.cache.keys());
        for (const key of keys) {
            for (const matchPhrase of matchPhrases) {
                if (key.includes(matchPhrase)) {
                    this.cache.delete(key);
                }
            }
        }
    }

    /**
     * Checks if the key exists and it has not expired.
     *
     * @private
     * @param {string} key
     * @returns {boolean}
     * @memberof CacheService
     */
    private hasValidCachedKey(key: string): boolean {
        if (this.cache.has(key)) {
            if (this.cache.get(key).expiry < Number(moment.utc().toDate())) {
                this.cache.delete(key);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    /**
     * Gets the data from API passed as Parameter.     *
     * @private       
     * @param {string} key
     * @param {Promise<any>} [fallback]
     * @param {number} [maxAge]
     * @memberof CacheService
     */
    private getDataFromApi<T>(key: string, fallback: () => Promise<T>, maxAgeInSec: number): Promise<any> {
        const promise = fallback();
        return promise.then((data) => {
            this.set(key, data, maxAgeInSec, true);
            return Promise.resolve(data);
        });
    }

    /**
     * Sets the value with key in the cache
     * Notifies Promise of the new value
     *
     * @private
     * @param {string} key
     * @param {*} value
     * @param {number} [maxAge=this.DEFAULT_MAX_AGE]
     * @memberof CacheService
     */
    private set(key: string, value: any, maxAgeInSec: number = this.DEFAULT_MAX_AGE, isStoredToLocalStorage: boolean = false): void {
        const expiry = Number(moment.utc().toDate()) + (maxAgeInSec * 1000);
        if (isStoredToLocalStorage) {
            const cacheData: ICacheContent = { expiry, value, version: this.localStorageCacheVersion };
            this.contextStorageService.saveContent(key as LocalStorageKey, JSON.stringify(cacheData));
        } else {
            this.cache.set(key, { value, expiry });
        }
    }
}
