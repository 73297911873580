import { IProgressBar } from "../../../components/tiles/dm-progress-bar/dm-progress-bar.contracts";
import { ITypeColorCode } from "./project.service.contracts";

export interface IWbsDetailsV2 {
    engagementData: IEngagementDetailsApiV2;
    projectData: IProjectDetailsApiV2;
    servicesData: IServiceDetailsApiV2;
    tasksData: ITaskDetailsApiV2;
}

export interface IWbsDetailsV2Model {
    engagementData: IEngagementDetailsV2;
    projectData: IProjectDetailsV2;
    servicesData: IServiceDetailsV2;
    tasksData: ITaskDetailsV2;
}

/* Includes all the attributes that are additionally required and added in UI and
  also extends the fields that are retrieved from API. */
export interface IEngagementDetailsV2 extends IEngagementDetailsApiV2, IEngagementTeamManagersInfo {
    billable?: string;
    canEditEnagagement?: boolean;
    canRequestEbsStateChange?: boolean;
    canViewChronosLabor?: boolean;
    canViewEBS?: boolean;
    canViewEngagement?: boolean;
    canViewFinance?: boolean;
    canViewInvoice?: boolean;
    canViewPurchaseOrder?: boolean;
    canViewStaffing?: boolean;
    childEntitiesMinStartDate?: Date;
    childEntitiesMaxEndDate?: Date;
    delegationType?: string;
    entityType?: string;
    isInternalEngagment?: boolean; // todo fix spelling of 'engagement'
    isPinned?: boolean;
    isUsPubSec?: boolean;
    relationshipManagerAlias?: string;
    relationshipManagerName?: string;
    srCrossCharge?: string;
    srCrossChargeType?: string;
    ebsStateProgress?: IProgressBar[];
    typeOfContract?: string;
}

export interface IEngagementTeamManagersInfo {
    pPjm?: ITeamDetailsV2;
    relManager?: ITeamDetailsV2;
    adPPjm?: ITeamDetailsV2[];
    delPPjm?: ITeamDetailsV2;
    dmmPPjm?: ITeamDetailsV2;
}

// All the enagagement data fields that are retrieved from wbs details(project details earlier) v2 api.
export interface IEngagementDetailsApiV2 {
    companyCode: string;
    companyCodeArea: string;
    companyName: string;
    contractEndDate: Date;
    contractId: string;
    contractStartDate: Date;
    costCenter: string;
    createdGroup?: string;
    currency: string;
    customerCity: string;
    customerCountry: string;
    customerId: string;
    customerIndustry: string;
    customerName: string;
    customerPostalCode: string;
    customerState: string;
    dealId: string;
    description: string;
    endDate: Date;
    engagementTypeCode: string;
    engagementTypeDescription: string;
    hasAssociatedEngagements: boolean;
    hasUnitBasedDemands: boolean;
    hasNpcActuals: boolean;
    id: string;
    isConfidential: boolean;
    isPublicSector: boolean;
    isSensitive: boolean; /* This is not used in PJM */
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    maxDate: Date;
    minDate: Date;
    name: string;
    nbueApprovalDocumentLink: string;
    opportunityId: string;
    plant: string;
    primaryDomain: string;
    profitCenter: string;
    projects: IProjectDetailsV2[];
    publicSectorCode: string;
    serviceDeliveryOrganizationDescription: string;
    serviceDeliveryOrganizationKey: string;
    signatureDate: Date;
    startDate: Date;
    statusCode: string;
    statusDescription: string;
    teamStructure: ITeamDetailsV2[];
    topParentId?: string; /* TPID */
    userRequestedEbsState: string;
    userStatusCode: string;
    userStatusDescription: string;
    currentStatus: string;
    currentStatusCode: string;
}

/* Includes all the attributes that are additionally required and added in UI and
  also extends the fields that are retrieved from API. */
export interface IProjectDetailsV2 extends IProjectDetailsApiV2, IProjectTeamManagersInfo {
    billable?: string;
    canEditPurchaseOrder?: boolean;
    canEditProject?: boolean;
    canRequestEbsStateChange?: boolean;
    canViewChronosLabor?: boolean;
    canViewEBS?: boolean;
    canViewFinance?: boolean;
    canViewInvoice?: boolean;
    canViewProject?: boolean;
    canViewPurchaseOrder?: boolean;
    canViewStaffing?: boolean;
    delegationType?: string;
    demandSourceId?: string;
    engagementEndDate?: Date;
    engagementStartDate?: Date;
    entityType?: string;
    hasUnitBasedDemands?: boolean;
    isPinned?: boolean;
    isUsPubSec?: boolean;
    isConfidentialDeal?: boolean; // change to isConfidential
    orginalPackageEndDate?: Date;
    orginalPackageStartDate?: Date;
    totalTasksCount?: number;
    typeOfContract?: string;
    typeColorCode?: ITypeColorCode;
    pubSecCode?: string; /// Optional as internal engagements dont have this
    showBadge?: boolean; /// Used in Manage EBS, Break Down structure.
    badgeText?: string; /// Used in Manage EBS, Break Down structure.
    showPjmField?: boolean;
    isExpand?: boolean;
    isMarkedForDeletion?: boolean; // Indicates if project is marked for deletion in CompassOne
    recRevType?: string;
}

export interface IProjectTeamManagersInfo {
    pjm?: ITeamDetailsV2;
    adPjm?: ITeamDetailsV2[];
    delPjm?: ITeamDetailsV2;
    pPjm?: ITeamDetailsV2;
    delPPjm?: ITeamDetailsV2;
    dmmPjm?: ITeamDetailsV2;
    uSubmitter?: ITeamDetailsV2[];
}

export interface IProjectDetailsApiV2 {
    bifAmount: number;
    businessScenario: string;
    businessScenarioDescription: string;
    companyCode: string;
    companyCodeArea: string;
    companyName: string;
    compassOnePackageId: string;
    contractCountry: string;
    contractEndDate: Date;
    contractId: string;
    contractStartDate: Date;
    contractType: string;
    costCenter: string;
    currency: string;
    customerCity: string;
    customerCountry: string;
    customerId: string;
    customerName: string;
    customerPostalCode: string;
    customerState: string;
    dealId: string;
    description: string;
    endDate: Date;
    engagementId: string;
    id: string;
    ioDate: Date;
    isExpenseBillable: boolean;
    isPublicSector: boolean;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    maxDate: Date;
    minDate: Date;
    name: string;
    opportunityId: string;
    originalEndDate: Date;
    originalStartDate: Date;
    plant: string;
    primaryDomain: string;
    primaryProductCode: string;
    primaryProductDescription: string;
    profitCenter: string;
    projectTypeCode: string;
    projectTypeDescription: string;
    serviceDeliveryOrganizationDescription: string;
    serviceDeliveryOrganizationKey: string;
    services: IServiceDetailsV2[];
    startDate: Date;
    statusCode: string;
    statusDescription: string;
    teamStructure: ITeamDetailsV2[];
    totalHours: number;
    userRequestedEbsState: string;
    userStatusCode: string;
    userStatusDescription: string;
    // added for view as optional fields need to be populated from non expense service tasks
    srCostCenter?: string;
    srCrossChargeType?: string;
    srCrossChargeAmount?: string;
    ecifChargeAccount?: string;
    hasNpcActuals: boolean;    
    currentStatus: string;
    currentStatusCode: string;
}

/* Includes all the attributes that are additionally required and added in UI and
  also extends the fields that are retrieved from API. */
export interface IServiceDetailsV2 extends IServiceDetailsApiV2 {
    billable?: string;
    serviceType?: string;
    isExpand?: boolean;
    showBadge?: boolean; /// Used in Manage EBS, Break Down structure.
    badgeText?: string; /// Used in Manage EBS, Break Down structure.
}
export interface IServiceDetailsApiV2 {
    actualEndDate: Date;
    actualStartDate: Date;
    compassOneServiceId: string;
    description: string;
    endDate: Date;
    id: string;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    maxDate: Date;
    minDate: Date;
    name: string;
    primaryDomain: string;
    projectId: string;
    resultAnalysisKey: string;
    startDate: Date;
    statusCode: string;
    statusDescription: string;
    tasks: ITaskDetailsV2[];
    userRequestedEbsState: string;
    userStatusCode: string;
    userStatusDescription: string;
    hasNpcActuals: boolean;
    currentStatus: string;
    currentStatusCode: string;
}

/* Includes all the attributes that are additionally required and added in UI and
  also extends the fields that are retrieved from API. */
export interface ITaskDetailsV2 extends ITaskDetailsApiV2 {
    billable?: string;
    entityType?: string;
}

export interface ITaskDetailsApiV2 {
    chargeAccount: string;
    chargeType: string;
    chargeTypeValue: string;
    costObject: string;
    costObjectType: string;
    description: string;
    endDate: Date;
    id: string;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    maxDate: Date;
    minDate: Date;
    name: string;
    serviceId: string;
    startDate: Date;
    statusCode: string;
    statusDescription: string;
    userRequestedEbsState: string;
    userStatusCode: string;
    userStatusDescription: string;
    workPackageDescription: string;
    workPackageType: string;
    currentStatus: string;
    currentStatusCode: string;
}

export interface ITeamDetailsV2 {
    name: string;
    bpid: string;
    alias: string;
    role: string;
}

export enum ContractType {
    FixedFee = "FF",
    TimeAndMaterial = "T&M",
    Mixed = "Mixed",
}
