<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'320px'" [isLoading]="!resourceDetails">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true"
        *ngIf="!resourceDetails"></dm-loading>
    <div *ngIf="resourceDetails">
        <div class="display-flex dm-m-b-20">
            <div class="dm-m-r-15">
                <dm-person-card [personDetails]="resourceDetails.personDetails"
                    [personImage]="resourceDetails.resourcePhoto" [avatarOnly]="true"
                    *ngIf="resourceDetails.alias">
                  </dm-person-card>
                <div class="userPhoto" *ngIf="!resourceDetails.alias">
                    <span class="icon icon-user"></span>
                </div>
            </div>
            <div class="text-align-left">
                <div class="dm-detail__value">
                    <span *ngIf="resourceDetails.fullName">{{resourceDetails?.fullName}}</span> 
                    <span *ngIf="!resourceDetails.fullName">Not Staffed</span>                    
                </div>
                <div class="font-caption-alt">{{resourceDetails?.plannedRole}}</div>
            </div>
        </div>
        <div class="resource-details-grid dm-m-b-20">
            <div class="dm-detail">
                <span class="dm-detail__label">Planned Role</span>
                <span class="dm-detail__value">{{resourceDetails?.plannedRole | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
            <div class="dm-detail">
                <span class="dm-detail__label">Resource Type</span>
                <span class="dm-detail__value">{{resourceDetails?.resourceType | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
            <div class="dm-detail">
                <span class="dm-detail__label">GRM Demand ID</span>
                <span class="dm-detail__value">{{resourceDetails?.grmDemandId | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
            <div class="dm-detail">
                <span class="dm-detail__label">Business Partner ID</span>
                <span class="dm-detail__value">{{resourceDetails?.businessPartnerId | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
            <div class="dm-detail">
                <span class="dm-detail__label">Domain</span>
                <span class="dm-detail__value">{{resourceDetails?.domain | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
            <div class="dm-detail">
                <span class="dm-detail__label">Billing Role ID</span>
                <span class="dm-detail__value">{{resourceDetails?.domain | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
            <div class="dm-detail">
                <span class="dm-detail__label">Delivery Location</span>
                <span class="dm-detail__value">{{resourceDetails?.deliveryLocation | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
            <div class="dm-detail">
                <span class="dm-detail__label">Resource Location</span>
                <span class="dm-detail__value">{{resourceDetails?.resourceLocation | dmDisplayDashIfEmptyOrNull }}</span>
            </div>
        </div>
        <div class="dm-flex-align-center dm-m-b-20">
            <button type="button" class="dm-btn" title="View Cost Rate Report">
                <span>View Cost Rate Report</span>
            </button>
            <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom-left'" [ngbTooltipContent]="'Cost Rate Report Information'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Cost Rate Report Information">
            </dm-tooltip>
            <button type="button" class="dm-btn margin-left-auto dm-m-r-12" title="View Cost Rate Report">
                <span>View Actual Rate Report</span>
            </button>
        </div>       
        <div role="grid">
            <div role="rowgroup" class="dm-grid-view-table">
                <div role="row" class="dm-grid-view-table__row dm-grid-view-table__row-header">
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8">
                        Category
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8">
                        Planned
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                            [ngbTooltipContent]="'Planned Information'" [dmIcon]="'tile__icon--info icon icon-info'"
                            ariaLabelToolTip="Planned Information">
                        </dm-tooltip>
                    </div>
                    <div role="columnheader"
                        class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8 justify-content-flex-end dm-p-r-imp-4">
                        Staffed <br/>(from GRM)
                    </div>
                    <div role="columnheader"
                        class="dm-grid-view-table__headerCell justify-content-center text-align-right dm-p-x-12-y-8">
                        Actuals
                    </div>
                    <div role="columnheader"
                        class="dm-grid-view-table__headerCell justify-content-center text-align-right dm-p-x-12-y-8">
                        ETC
                    </div>
                    <div role="columnheader"
                        class="dm-grid-view-table__headerCell justify-content-center text-align-right dm-p-x-12-y-8">
                        EAC
                    </div>
                </div>
            </div>
            <div role="rowgroup" class="dm-grid-view-table">
                <div class="dm-grid-view-table__body">
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-y-imp-15">
                            Labor (hours)
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{plannedFinancials?.laborHours | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!plannedFinancials?.laborHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{staffedFinancials?.laborHours | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!staffedFinancials?.laborHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{actualFinancials?.laborHours | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!actualFinancials?.laborHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{etcFinancials?.laborHours | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!etcFinancials?.laborHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{eacFinancials?.laborHours | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!eacFinancials?.laborHours" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                    </div>
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-y-imp-15">
                            Cost Rate
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{plannedFinancials?.costRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!plannedFinancials?.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{staffedFinancials?.costRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!staffedFinancials?.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{actualFinancials?.costRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!actualFinancials?.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{etcFinancials?.costRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!etcFinancials?.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{eacFinancials?.costRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!eacFinancials?.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                    </div>
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-y-imp-15">
                            Cost ({{currency}})
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{plannedFinancials?.cost | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!plannedFinancials?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{staffedFinancials?.cost | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!staffedFinancials?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{actualFinancials?.cost | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!actualFinancials?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{etcFinancials?.cost | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!etcFinancials?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{eacFinancials?.cost | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!eacFinancials?.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                    </div>
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-y-imp-15">
                            Bill Rate
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{plannedFinancials?.billRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!plannedFinancials?.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{staffedFinancials?.billRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!staffedFinancials?.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{actualFinancials?.billRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!actualFinancials?.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{etcFinancials?.billRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!etcFinancials?.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{eacFinancials?.billRate | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!eacFinancials?.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                    </div>
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-y-imp-15">
                            Revenue ({{currency}})
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{plannedFinancials?.revenue | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!plannedFinancials?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{staffedFinancials?.revenue | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!staffedFinancials?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{actualFinancials?.revenue | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!actualFinancials?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{etcFinancials?.revenue | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!etcFinancials?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-r-5 dm-p-y-imp-15">
                            {{eacFinancials?.revenue | dmDisplayDashIfEmptyOrNull: currency}}
                            <span *ngIf="!eacFinancials?.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</dm-modal-v2>