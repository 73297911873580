import { Injectable, Inject } from "@angular/core";
import { DataService } from "./data.service";
import { ConfigManagerService } from "./configmanager.service";
import { ICustomer } from "./contracts/customer.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class CustomerService extends DmServiceAbstract {

    private customerServiceBaseUrl: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.CustomerService);
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Calls the Customer Search API which gets the customer details
     * based on name.
     *
     * @param {string} searchValue
     * @returns {Promise<ICustomer[]>}
     * @memberof CustomerService
     */
    public searchCustomerByName(searchValue: string): Promise<ICustomer[]> {
        const url = this.customerServiceBaseUrl + `/search?name=${searchValue}`;
        const payload = [];

        return this.dataService.postData(url, this.subscriptionKey, "Get the customer details based on Name/TPID", payload);
    }

    /**
     * Calls the Customer Search API which gets the customer details
     * based on TPID.
     *
     * @param {string} searchValue
     * @returns {Promise<ICustomer[]>}
     * @memberof CustomerService
     */
    public searchCustomerByTpid(searchValue: string): Promise<ICustomer[]> {
        const url = this.customerServiceBaseUrl + `/search?id=${searchValue}`;
        const payload = [];

        return this.dataService.postData(url, this.subscriptionKey, "Get the customer details based on Name/TPID", payload);
    }

    /**
     * Initialize the customer service with URIs and subscription keys.
     */
    private initializeConfig(): void {
        this.customerServiceBaseUrl = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v1.0/customer";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
    }
}
