import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../entry/common-components.module";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { PlanForecastV2Component } from "./plan-forecast-v2.component";
import { PlanForecastDetailsModule } from "./tiles/plan-forecast-details.module";

@NgModule({
    declarations: [
        PlanForecastV2Component
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        PlanForecastDetailsModule
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        PlanForecastV2Component
    ],
    exports: [
        PlanForecastV2Component
    ]
})
export class PlanForecastV2Module { }
