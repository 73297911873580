import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AmendmentsModule } from "../../../amendments/amendments.module";
import { AmendmentsService } from "../../../../common/services/amendments.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialNavComponent } from "./financial-nav.component";
import { FinancialPlanModule } from "../financial-plan/financial-plan.module";
import { FinancialService } from "../../../../common/services/financial.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { SnapshotsModule } from "../snapshots/snapshots.module";
import { CommonComponentsModule } from "../../../entry/common-components.module";
@NgModule({
    declarations: [
        FinancialNavComponent
    ],
    imports: [
        AmendmentsModule,
        CommonModule,
        FinancialPlanModule,
        SnapshotsModule,
        CommonComponentsModule
    ],
    providers: [
        AmendmentsService,
        DMLoggerService,
        FinancialService,
        SharedFunctionsService
    ],
    entryComponents: [
        FinancialNavComponent
    ],
    exports: [
        FinancialNavComponent
    ]
})
export class FinancialNavModule { }
