<div id="activity-feed" *ngIf="wbsAuditEntries.length > 0">
  <li *ngFor="let auditEntry of wbsAuditEntries | slice:slicedItemsNumber:gridItemsDisplay; let lastActivity = last">
    <activity-feed-item [auditFeedItem]="auditEntry" [isLastActivity]="lastActivity"></activity-feed-item>
  </li>
  <div class="col-md-12 dm-pagination">
    <dm-pagination-v2 *ngIf="isPaginationRequired" [(pageSize)]="activityFeedItemsDisp"
      [totalItemCount]="wbsAuditEntries.length" [currentPageNumber]="currentPage"
      (pageChanged)="currentPageChangedHandler($event)" (pageSizeChanged)="itemPerPageChangedHandler($event)"
      [itemsPerPage]="itemsPerPage">
    </dm-pagination-v2>
  </div>
</div>