import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { ReportsComponent } from "./reports.component";

@NgModule({
    declarations: [
        ReportsComponent
    ],
    imports: [CommonModule],
    providers: [
        ConfigManagerService,
    ],
    entryComponents: [ReportsComponent],
    exports: [ReportsComponent]
})
export class ReportsModule { }
