import { Action } from "@ngrx/store";

import { IResourceRequestResponse } from "../../common/services/contracts/staffing.service.contract";

// 1. action types
export enum ResourceRequestsProjectContextActionTypes {
    LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT = "[Resource Requests Project Context] Load",
    LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT_SUCCESS = "[Resource Requests Project Context] Load Success",
    LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT_FAIL = "[Resource Requests Project Context] Load Fail",
    INVALIDATE_RESOURCEREQUESTS_PROJECT_CONTEXT = "[Resource Requests Project Contextw] Invalidate"
}
// 2. action creators
export class LoadResourceRequestsProjectContext implements Action {
    public readonly type = ResourceRequestsProjectContextActionTypes.LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT;
    public constructor(public projectId: string) { }
}
export class LoadResourceRequestsProjectContextSuccess implements Action {
    public readonly type = ResourceRequestsProjectContextActionTypes.LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT_SUCCESS;
    public constructor(public projectId: string, public grmSearchApiResponse: IResourceRequestResponse) { }
}
export class LoadResourceRequestsProjectContextFail implements Action {
    public readonly type = ResourceRequestsProjectContextActionTypes.LOAD_RESOURCEREQUESTS_PROJECT_CONTEXT_FAIL;
    public constructor(public projectId: string, public errorMessage: string) { }
}

export class InvalidateResourceRequestsProjectContext implements Action {
    public readonly type = ResourceRequestsProjectContextActionTypes.INVALIDATE_RESOURCEREQUESTS_PROJECT_CONTEXT;
    public constructor(public projectId: string) { }
}
// 3. export the union creators
export type ResourceRequestProjectContextActions =
    | LoadResourceRequestsProjectContext
    | LoadResourceRequestsProjectContextSuccess
    | LoadResourceRequestsProjectContextFail
    | InvalidateResourceRequestsProjectContext;