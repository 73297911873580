import { ContractType } from "../../common/services/contracts/wbs-details-v2.contracts";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";

export interface ICRDetails {
    crNumber: number;
    crDescription: string;
    createdOn: Date;
    status: string;
    lastUpdatedBy: string;
    picM_WB_LINK: string;
}

export interface IAmendmentV2 {
    id: string;
    description: string;
    engagementId: string;
    status: string;
    requestedHours: number;
    requestedCost: number;
    planCurrency: string;
    createdBy: string;
    updatedBy: string;
    createdOn: Date;
    updatedOn: Date;
    changeRequestRoleDetails: IChangeRequestRoleDetail[];
}

export interface IChangeRequestRoleDetail {
    taskId: string;
    taskDescription: string;
    structuralElement: string;
    planVersionId: string;
    billingRoleId: string;
    domainKey: string;
    primaryDomain: string;
    resourceType: string;
    resourceTypeDescription?: any;
    role: string;
    roleDescription: string;
    demandId: string;
    crNumber: string;
    planCurrency: string;
    laborUnits: string;
    quantity: number;
    requestedQuantity: number;
    costRate: number;
    planCost: number;
    planRevenue: number;
    billRate: number;
    resourceLocationKey: string;
    deliveryLocationKey: string;
    amendmentType: string;
    isSplittedLineItem: boolean;
}
export interface ICRFinancialDetails {
    crNumber: number;
    plannedCostChange: number;
    plannedRevenueChange: number;
    labourHoursChange: number;
    riskReserveChange: number;
}

export interface ICRDetailsList {
    crNumber: number;
    plannedCostChange: number;
    plannedRevenueChange: number;
    labourHoursChange: number;
    riskReserveChange: number;
    crDescription?: string;
    timeStamp?: Date;
    status?: string;
    lastUpdatedBy?: string;
}

export interface IAmendmentPackage {
    projectId: string;
    projectName: string;
    projectDescription: string;
    typeOfContract: string;
    startDate: Date;
    endDate: Date;
    riskReserve: number;
    amendments: IAmendmentPackageDetails[];
    isExpand?: boolean;
}

export interface IAmendmentPackageDetails {
    crNumber: number;
    engagementId: string;
    engagementDescription: string;
    projectId: string;
    projectName: string;
    projectDescription: string;
    wbsl3Id: string;
    wbsl3Description: string;
    type: string;
    roleId: string;
    roleDescription: string;
    plannedLaborHours: number;
    billRate: number;
    costRate: number;
    plannedCost: number;
    riskReserve: number;
    plannedRevenue: number;
    startDate: Date;
    endDate: Date;
    demandId?: string;
    isSelected?: boolean;
}

export interface IMisalignedAmendment {
    id: string; /* The Change Request ID */
    description: string;
    engagementId: string;
    requestedHours: number;
    requestedCost: number;
    planCurrency: string;
    createdBy: string;
    updatedBy: string;
    createdOn: Date;
    updatedOn: Date;
    misalignmentStatus: MisalignmentStatus;
    misalignedProjects: IMisalignedProject[];
}

export interface IMisalignmentNotification {
    engagementId: string;
    processedChangeRequestId: string;
    pendingChangeRequestId: string;
    createdOn: Date;
}

export interface IMisalignedProject {
    projectId: string;
    misalignmentStatus: MisalignmentStatus; /* Set to status Submitted when Saved */
    originalPlanCostTotal: number;
    originalPlanRevenueTotal: number;
    originalRequestedQuantityTotal: number;
    misalignedLineItems: IChangeRequestRoleDetail[];
}

export enum MisalignmentStatus {
    Initiated = "Initiated",
    Resolved = "Resolved",
    Submitted = "Submitted"
}

export interface IMisalignedAmendmentProjectViewModel {
    projectId: string;
    isExpand: boolean;
    hasBeenSaved: boolean; /* If the project has already been saved, we disallow editing because the project will be ready for submit */
    projectName: string;
    projectType: ContractType; /* Used for toggling FF/T&M features*/
    availableTaskIds: string[];
    pairedDemands: IMisalignedAmendmentPairedDemandsViewModel[];
    originalPlanCostTotal: number;
    originalPlanRevenueTotal: number;
    originalRequestedQuantityTotal: number;
    isSplitDemandDisabled: boolean;
}

export interface IMisalignedAmendmentPairedDemandsViewModel {
    pairId: string; /* Serves as a unique identifier for the pair since all other identifiers can be changed */
    misalignedAmendment: IMisalignedAmendmentViewModel;
    originalAmendment: IMisalignedAmendmentViewModel;
    errorsResolved: boolean;
    splittedLineItem: boolean;
}

export interface IMisalignedAmendmentViewModel {
    projectId: string;
    addtlCost: number;
    addtlRevenue: number;
    billingRoleId: string;
    billRate: number;
    costRate: number;
    crNumber: string;
    demandId: string;
    originator: string;
    plannedLabor: number;
    resourceLocation: ICompanyCode;
    role: IFinancialRoles;
    taskDescription: string;
    taskId: string;
    /* Used for referencing values needed when the misalignment is sent back to Cosmos.
    Only needed for misaligned amendments, not original demands.
    Not referenced in the UI. All unique identifiers for misaligned amendments can be changed, so this way
    a reference can still be had.*/
    originalMisalignedAmendmentData?: IChangeRequestRoleDetail;
    staffedLabour: number;
}

export enum MisalignmentValidationErrorTypeEnum {
    Warning = "Warning",
    Error = "Error"
}

export enum MisalignmentVarianceTypeEnum {
    Cost = "Cost Variance",
    Revenue = "Revenue Variance",
    Hour = "Hour Variance"
}

export interface IMisalignmentValidationConfiguration {
    additionalCostThresholdFF: number;
    hoursThresholdTM: number;
    additionalRevenueThresholdTM: number;
}

/* Callback object from the individual misaligned lineitems component to the parent component.
Shares the error status and the amendment that has been recently updated */
export interface IMisalignedAmendmentCallbackObject {
    projectId: string;
    pairId: string;
    newDemandCreated: boolean;
    existingDemandAssigned: boolean;
    misalignedAmendment: IMisalignedAmendmentViewModel;
    errorsResolved: boolean;
    splittedLineItem: boolean;
}

export interface ICompanyCode {
    companyCode: string;
    companyName?: string;
}

