<!--The content below is only a placeholder and can be replaced.-->
<div class="title">
    <h1>
        Welcome {{ UserName }}!
    </h1>

    <br />
    <br />
    <h1>
        Your current BPID: {{currBpID}}
    </h1>
</div>