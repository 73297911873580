import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CceacV2Module } from "../../../financial-mgmt/tiles/cost-consumed/cceac-v2/cceac-v2.module";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { SummaryFinancialsBreakdownComponent } from "./summary-financials-breakdown.component";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmInnerTileModule } from "../../../../components/tiles/inner-tile/inner-tile.module";
import { CircularProgressModule } from "../../../../components/tiles/circular-progress/circular-progress.module";

@NgModule({
    declarations: [
        SummaryFinancialsBreakdownComponent,
    ],
    imports: [
        CceacV2Module,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        UIRouterModule,
        DmInnerTileModule,
        CircularProgressModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        SummaryFinancialsBreakdownComponent,
    ],
    exports: [
        SummaryFinancialsBreakdownComponent,
    ]
})
export class SummaryFinancialsBreakdownModule { }

