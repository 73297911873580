import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalV2Module } from "../../../../modals/dm-modal-v2/dm-modal-v2.module";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { ValidationFailedModalComponent } from "./validation-failed-modal.component";
import { ActivateEngagementTemplatesModule } from "../../../../../components/financial-mgmt/tiles/financial-plan/activate-engagement-modal/activate-engagement-templates/activate-engagement-templates.module";

@NgModule({
    declarations: [
        ValidationFailedModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        DmModalV2Module,
        ActivateEngagementTemplatesModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        ValidationFailedModalComponent,
    ],
    exports: [
        ValidationFailedModalComponent,
    ]
})
export class ValidationFailedModalComponentModule { }