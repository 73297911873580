import { ICostSummary } from "./cost-summary.contracts";
import { IBillRate, ICrApprover, ICrDemandOutput, IExistingExpense, IExistingSubcon, IExistingUnit, ITask } from "./changerequest.contract";
import { IFinancialPlanResponse } from "./financial-plan.contracts";
import { IHoursSummary } from "./hours-summary.contracts";
import { IRiskIssue } from "./virtuoso.contracts";
import { IFinancialRoles } from "./projectservice-functions.contract";
import { IEngagementDetailsApiV2 } from "./wbs-details-v2.contracts";

export interface IChangeRequestV2 {
    engagementName: string;
    engagementId: string;
    requestedBy: string;
    requestedOn: string;
    description: string;
    approvalStatus: string;
    executiveSummary: string;
    businessJustification: string;
    reason: string;
    risks: string;
    issues: string;
    comments: string;
    attachment: ICrAttachment;
}

export interface IChangeRequestResponse {
    changeRequestDetails: IFinancialChangeRequestDetails;
    engagementVarianceSummary: IEngagementVarianceSummary;
}

export interface IFinancialChangeRequestDetails {
    id: string;
    description: string;
    engagementId: string;
    engagementName?: string; // Needs to be added missing from api response
    isFixedFee: boolean;
    status: string;
    changeRequestApprovers: ICrApprover[];
    risks: IRiskIssue[];
    issues: IRiskIssue[];
    reason: ICrReason;
    comments: string;
    attachment: ICrAttachment;
    requestedHours: number;
    requestedCost: number;
    planCurrency: string;
    executiveSummary: string;
    businessJustification: string;
    createdBy: string;
    updatedBy: string;
    createdOn: string;
    updatedOn: string;
    changeRequestRoleDetails: IFinancialChangeRequestRoleDetails;
    financialPlanDetails: IFinancialPlanResponse;
}

export interface IFinancialChangeRequestRoleDetails {
    changeRequestDetails: IFinancialChangeRequestRoleDetail[];
    billRates: IBillRate[];
}

export interface IFinancialChangeRequestRoleDetail {
    engagementId: string;
    engagementDescription: string;
    structuralElement: string;
    planVersionId: string;
    billingRoleId: string;
    domainKey: string;
    primaryDomain: string;
    resourceType: string;
    resourceTypeDescription: string;
    role: string;
    roleDescription: string;
    demandId: string;
    crNumber: string;
    planCurrency: string;
    laborUnits: string;
    quantity: number;
    requestedQuantity: number;
    costRate: number;
    planCost: number;
    planRevenue: number;
    billRate: number;
    resourceLocationKey: string;
    deliveryLocationKey: string;
    taskId?: string;
    dbCost?: number;
    dbHour?: number;
    existingCfpCost?: number;  // this field should be populated to capture existing cost prior to any blended cost rate changes since SAP does not store in all scenarios for FCR
    existingRoleDescription?: string;
    // All derived fields are calculated from fields that are returned from api/engagement details.
    projectName?: string; // derived field
    serviceName?: string; // derived field
    isFixedFeeProject?: boolean; // derived field
    cfpCost?: number; // derived field
    additionalCost?: number; // Derived field Calculated from db cost and cfp cost
    requestedCost?: number;
}

export interface IEngagementVarianceSummary {
    engagementId: string;
    deliveryBaseLineHours: number;
    hoursImpact: number;
    hoursVariancePercentage: number;
    contractBaseLineCostExcludingRR: number;
    newPlannedCost: number;
    grossCostOverRun: number;
    grossCostOverRunPercentage: number;
    previouslyApprovedCost: number;
    additionalApprovalNeeded: number;
    contractHoursPercentage: number;
    contractHoursCumulativePercentage: number;
    projectCostSummary: ICostSummary[];
    projectHoursSummary: IHoursSummary[];
}

export interface IRiskOrIssue {
    id: string;
    text: string;
}

export interface ICrReason {
    id: string;
    text: string;
}

export interface ICrAttachment {
    id: string;
    fileName: string;
}

// Added all the fvr status codes from SAP and generated by PJM into one.
export enum FvrApproversStatus {
    FVRApproved = "FVR Approved",
    Canceled = "Canceled",
    Pending = "Pending Approval",
    FVRRejected = "FVR Rejected",
    Created = "Created",
    PendingApproval = "PendingApproval",
    Updated = "Updated",
    Approved = "Approved",
    Rejected = "Rejected",
    SentBack = "SentBack",
    Cancelled = "Cancelled",
    Closed = "Closed"
}

export interface IFinancialCrApprover {
    role: string;
    status: string;
    name: string;
    updatedOn: string;
    delegatedApprover?: string;
}

export interface IDemandDiff {
    id: string;
    grmDemandId: string;
    role: string;
    resourceLocation: string;
    billingRoleId: string;
    quantity: number;
    cost: number;
    revenue?: number;
    uom?: string;
}

export interface IPendingChangeRequest {
    crId: string;
    crType: string;
    planId: string;
    projectId: string;
}

export interface IBillablityType {
    displayName: string;
    code: string;
}

// FCR form contracts -- resource types 
export interface IFcrUnitsFormControlData {
    billRates: IBillRate[];
    engagementDetails: IEngagementDetailsApiV2;
    existingTasks: ITask[];
    existingResources?: IExistingUnit[];
    FFBillRates?: IBillRate[];
    nonBill?: IBillRate;
    planCurrency?: string;
    roles?: IFinancialRoles[];
    TAndMBillRates?: IBillRate[];
    existingDbOnlyResources?: IExistingUnit[];
    projectsWithPendingCr?: string[];
    error?: string;
}

export interface IFcrUnitsOutput extends ICrDemandOutput { // Should this extend ICrResource instead now?
    unitRole?: string;
}

export interface IFcrExpensesFormControlData {
    existingTasks: ITask[];
    existingResources: IExistingExpense[];
    planCurrency?: string;
    billableTypes?: IBillablityType[];
    engagementDetails: IEngagementDetailsApiV2;
    existingDbOnlyResources?: IExistingExpense[];
    projectsWithPendingCr?: string[];
}

export interface IFcrSubconFormControlData {
    existingTasks: ITask[];
    existingResources: IExistingSubcon[];
    planCurrency?: string;
    engagementDetails: IEngagementDetailsApiV2;
    existingDbOnlyResources?: IExistingSubcon[];
    projectsWithPendingCr?: string[];
}

export interface IFcrTotals {
    expense?: number;
    subcon?: number;
    unit?: number;
    labor?: number;
}