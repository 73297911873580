<button type="button" aria-label="Close" class="dm-filter--close" (click)="closeModal()">
    <span class="icon icon-close"></span>
</button>
<div class="modal-header p-b-24">
    <h2 class="font-subtitle m-b-0">Edit filters</h2>
</div>
<form name="filter" #filter="ngForm">
    <div class="modal-body dm-filter--body">
        <section class="col-sm-12 pad0">
            <section class="section__wrapper p-b-8">
                <label class="input__label">
                    <label class="input__label--text" for="dm-gr-projects-ddl">
                        EBS State<span aria-hidden="true">:</span>
                    </label>
                    <label class="input__select--icon">
                        <select id="dm-grm-projects-ddl" name="status" class="input__select width-align" [(ngModel)]="statusSelected">
                            <option value="">All</option>
                            <option *ngFor="let status of statusList" [value]="status">{{status}}</option>
                        </select>
                    </label>
                </label>
            </section>
            <section class="section__wrapper p-b-8">
                <section class="input__label">
                    <label class="input__label--text" for="dm-gr-tasks-ddl" [ngClass]="{'disabled-text' :  isResetFilterLinkVisible}">
                        Duration Start<span aria-hidden="true">:</span>
                    </label>
                    <section class="dm-tile--no-pad">
                        <p class="input-group">
                            <dm-datepicker
                                [modelDate]="filterStartDate"
                                ariaLabelButton="Start date input field"
                                ariaLabelCalendar="Start date"
                                previosFousingElementId="dm-grm-projects-ddl"
                            ></dm-datepicker>
                        </p>
                    </section>
                </section>
            </section>
            <section class="section__wrapper">
                <section class="input__label">
                    <label class="input__label--text" for="dm-gr-tasks-ddl" [ngClass]="{'disabled-text' : isResetFilterLinkVisible}">
                        Duration End<span aria-hidden="true">:</span>
                    </label>
                    <section class="dm-tile--no-pad">
                        <p class="input-group">
                            <dm-datepicker
                                [modelDate]="filterEndDate"
                                ariaLabelButton="End date input field"
                                ariaLabelCalendar="End date"
                                previosFousingElementId="calenderBtn_Start date input field"
                            ></dm-datepicker>
                        </p>
                    </section>
                </section>
            </section>
        </section>
    </div>
    <div class="modal-footer dm-filter-footer">
        <button class="fxp-btn fxp-btn--primary" (click)="applyFilter()" [disabled]="filter.pristine || filter.invalid || applyFiltersButtonDisabled()">
            Apply filters
        </button>
        <button class="fxp-btn fxp-btn--secondary" (click)="closeModal()">Cancel</button>
    </div>
</form>