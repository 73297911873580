import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { LogEventConstants, SourceConstants, Components, AccessibilityConstants } from "../../../../../../common/application.constants";
import { ProjectService } from "../../../../../../common/services/project.service";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { DmError } from "../../../../../../common/error.constants";
import { ErrorSeverityLevel } from "@fxp/fxpservices";

@Component({
    selector: "dm-new-snapshot",
    templateUrl: "./new-snapshot.html",
    styleUrls: ["./new-snapshot.scss"]
})
export class NewSnapshotModalComponent extends DmModalAbstract {

    @Input() public snapshotName: string;
    @Input() public engagementId: string; /* The snapshot API only accepts engagement IDs (no project IDs) */
    @Input() public engagementName: string;
    @Input() public lastCreatedSnapshotVersionId: string;
    public newSnapshotName: string;
    public isDefaultSnapshotName: boolean = true;
    public defaultSnapshotName: string;
    public resetfocused: boolean;
    public accessibilityConstants = AccessibilityConstants;
    public dmSnapshotErrorMessage = DmError.Snapshot;

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
    ) {
        super(activeModal, dmLogger, Components.TakeNewSnapshot);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.isComponentLoading = false;
        this.defaultSnapshotName = "Snapshot " + this.lastCreatedSnapshotVersionId;
    }

    /**
     * Reset fields
     */
    public resetFields(): void {
        if (!this.isDefaultSnapshotName) {
            this.defaultSnapshotName = "Snapshot " + this.lastCreatedSnapshotVersionId;
            this.resetfocused = false;
        }
    }

    /**
     * Clear filed value on clicking clear icon
     */
    public clearText(): void {
        this.defaultSnapshotName = "";
        const element = window.document.getElementById("snapfield");
        if (document.getElementById("snapfield")) {
            element.focus();
        }
    }

    /**
     * Creates the snapshot by copying the financial plan. Sends a POST call to PMS API to create the new snapshot.
     */
    public copyFinancialPlan(): void {
        this.isComponentLoading = true;
        const snapshotName = !this.isDefaultSnapshotName ? this.defaultSnapshotName : (this.engagementName + " Snapshot " + this.lastCreatedSnapshotVersionId);
        this.newSnapshotName = snapshotName ? '"' + snapshotName + '"' : "";
        let isSuccess: boolean = false;
        this.projectService.copyFinancialPlan(this.engagementId, snapshotName).then(() => {
            this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.CopyFinancialPlan, LogEventConstants.FinancialsTakeSnapshot);
            isSuccess = true;
            const newSnapshotMsg = {
                isSuccess,
                snapshotName: this.snapshotName
            };
            this.sharedFunctionsService.getSnapshotCountUpdated().emit();
            this.activeModal.close(newSnapshotMsg);
        }).catch((error) => {
            isSuccess = false;
            const newSnapshotMsg = {
                isSuccess,
                snapshotName: this.snapshotName
            };
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.CopyFinancialPlan, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.activeModal.close(newSnapshotMsg);
        });
    }

    /**
     * Move focus to next element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

}
