import { Injectable } from "@angular/core";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";

@Injectable()
export class DecoratorService extends DmServiceAbstract {
    private static service: DMLoggerService | undefined = undefined;
    public constructor(service: DMLoggerService) {
        super(service, Services.DecoratorService );
        DecoratorService.service = service;
    }
    
    public static getService(): DMLoggerService {
        if (!DecoratorService.service) {
            throw new Error("DecoratorService not initialized");
        }
        return DecoratorService.service;
    }
}