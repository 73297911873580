import { Inject, Injectable } from "@angular/core";
import { appSettings } from "../../../environments/common/appsettingsGenerator";
import { IDistributionListObject } from "./contracts/wbs.service.contracts";
import { IFeatureFlag } from "../../../environments/interface/IPjMAppSettings";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";

declare let require: any;

@Injectable()
export class ConfigManagerService extends DmServiceAbstract {

    private configData: any;

    /**
     * Cached Promise
     */
    private configPromise: Promise<any>;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService){
        super(dmLogger, Services.ConfigManagerService );
    }

    /**
     * Initializes configuration data, either from the Confit SDK or locally via config JSON files
     */
    public initialize(): Promise<any> {
        // todo change this so that we are reading from appSettings() from FXP.
        // need to bring in all parts of config manager into the new app settings files.

        if (!this.configPromise) {
            this.configData = require("./configurations/dmConfiguration_UAT.json").DMConfiguration;
            this.configPromise = Promise.resolve(this.configData);
        }

        return this.configPromise;

    }

    /**
     * Gets stored config data value for the key name in the config
     * @param keyName
     */
    public getValue<T>(keyName: string): T {
        return this.configData[keyName];
    }

    /**
     * Gets the distribution list from the SG config file. Can return the confidential SGs or the non-confidential SG's based on the confidential boolean.
     */
    public getDistributionList(confidential: boolean): IDistributionListObject[] {
        if (confidential) {
            return require("./configurations/security-group-configuration.json").ProjectControllerConfidentialDistributionLists;
        }
        return require("./configurations/security-group-configuration.json").ProjectControllerCompanyDistributionLists;
    }


    /**
     * Checks if a specific feature flag is enabled for the quarterly release
     * @param featureName name of the feature in the features list in the JSON
     */
    public isFeatureEnabled(featureName: string): boolean {
        /* You must use appSettings directly since it is a constant value--wrapping it in application.ts will cause circular errors  */
        const featureConfiguration: IFeatureFlag = appSettings().featureFlags.filter((feature) => feature.name === featureName)[0];
        if (featureConfiguration) {
            return featureConfiguration.enabled;
        }
        return true;
    }
}
