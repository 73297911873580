import { Component, Input, Inject } from "@angular/core";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components, FcrTabs } from "../../../common/application.constants";
import { IFinancialChangeRequestDetails, IFinancialChangeRequestRoleDetail, FvrApproversStatus, IFcrTotals } from "../../../common/services/contracts/changerequestv2.contract";
import { IProgressBar } from "../../tiles/dm-progress-bar/dm-progress-bar.contracts";
import { IDmTab } from "../../../common/services/contracts/common.contracts";
import { ResourceType, ResourceTypeCode } from "../../../common/services/contracts/changerequest.contract";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-fcr-details",
    templateUrl: "./fcr-details.html",
    styleUrls: ["./fcr-details.scss"]
})
export class FcrDetailsComponent extends DmComponentAbstract {

    @Input() public fcrDetails: IFinancialChangeRequestDetails;
    @Input() public fcrDetailsError: string;
    @Input() public crId: number;
    @Input() public fcrAttachmentLink: string;
    @Input() public approversStatusData: IProgressBar[];
    @Input() public approversStatusError: string;
    @Input() public engagementDomain: string;
    public fcrLaborViewDetails: IFinancialChangeRequestRoleDetail[];
    public fcrUnitsViewDetails: IFinancialChangeRequestRoleDetail[];
    public fcrExpenseViewDetails: IFinancialChangeRequestRoleDetail[];
    public fcrSubconViewDetails: IFinancialChangeRequestRoleDetail[];
    public ResourceType: typeof ResourceType = ResourceType;
    public tabsContent: IDmTab[] = [];
    public showLabor: boolean;
    public showUnits: boolean;
    public showExpenses: boolean;
    public showSubconFF: boolean;
    public planCurrency: string;
    public loadingMessage: string = "Loading Change Request details";
    public fcrRolesData: IFinancialChangeRequestRoleDetail[] = [];
    public totalAdditionalCost: number = 0;
    public fcrTotals: IFcrTotals;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService) {
        super(dmLogger, Components.FcrDetails);
    }

    public ngOnInit(): void {
        this.isComponentLoading = false;
    }

    public ngOnChanges(): void {
        this.planCurrency = this.fcrDetails ? this.fcrDetails.planCurrency : "";
        if (this.fcrDetails && this.fcrDetails.changeRequestRoleDetails && this.fcrDetails.changeRequestRoleDetails.changeRequestDetails && this.fcrDetails.changeRequestRoleDetails.changeRequestDetails.length) {
            const fcrRowDetails = this.fcrDetails.changeRequestRoleDetails.changeRequestDetails;
            this.tabsContent = [];
            this.fcrLaborViewDetails = fcrRowDetails.filter((t) => t.resourceType === ResourceTypeCode.Labor || (t.resourceType === ResourceTypeCode.SubconFF && t.laborUnits === "H"));
            // TODO: refactor this code later
            const laborDetailsCost = this.fcrLaborViewDetails.length && this.fcrLaborViewDetails.map((x) => x.additionalCost);
            const totalLaborCost = laborDetailsCost && laborDetailsCost.reduce((a, b) => a + b, 0);

            this.fcrUnitsViewDetails = fcrRowDetails.filter((t) => t.resourceType === ResourceTypeCode.Unit);
            const unitDetailsCost = this.fcrUnitsViewDetails.length && this.fcrUnitsViewDetails.map((x) => x.additionalCost);
            const totalUnitCost = unitDetailsCost && unitDetailsCost.reduce((a, b) => a + b, 0);

            this.fcrExpenseViewDetails = fcrRowDetails.filter((t) => t.resourceType === ResourceTypeCode.Expense);
            const expenseDetailsCost = this.fcrExpenseViewDetails.length && this.fcrExpenseViewDetails.map((x) => x.additionalCost);
            const totalExpenseCost = expenseDetailsCost && expenseDetailsCost.reduce((a, b) => a + b, 0);

            this.fcrSubconViewDetails = fcrRowDetails.filter((t) => t.resourceType === ResourceTypeCode.SubconFF && t.laborUnits === "%");
            const subconDetailsCost = this.fcrSubconViewDetails.length && this.fcrSubconViewDetails.map((x) => x.additionalCost);
            const totalSubconCost = subconDetailsCost && subconDetailsCost.reduce((a, b) => a + b, 0);

            this.totalAdditionalCost = totalLaborCost + totalUnitCost + totalExpenseCost + totalSubconCost;

            if (this.fcrLaborViewDetails && this.fcrLaborViewDetails.length) {
                this.tabsContent.push(FcrTabs.Labor);
            }
            if (this.fcrUnitsViewDetails && this.fcrUnitsViewDetails.length) {
                this.tabsContent.push(FcrTabs.Unit);
            }
            if (this.fcrSubconViewDetails && this.fcrSubconViewDetails.length) {
                this.tabsContent.push(FcrTabs.SubconFF);
            }
            if (this.fcrExpenseViewDetails && this.fcrExpenseViewDetails.length) {
                this.tabsContent.push(FcrTabs.Expense);
            }
            this.sharedFunctionsService.sortListByPropertyBasedOnOrder("order", false, this.tabsContent);
            // Set first tab to active to display on load
            const defaultTab = this.tabsContent && this.tabsContent.length ? this.tabsContent[0] : undefined;
            if (defaultTab) {
                this.toggleTab(defaultTab);
            }
            this.fcrTotals = {
                labor: totalLaborCost,
                unit: totalUnitCost,
                expense: totalExpenseCost,
                subcon: totalSubconCost
            };
        }
    }

    /**
     * Gets approved status css class name to set color appropriately.
     *
     * @param {string} status
     * @returns {string}
     * @memberof FcrDetailsComponent
     */
    public getApprovedStatusClass(status: string): string {
        switch (status) {
            case FvrApproversStatus.Approved ||
                FvrApproversStatus.FVRApproved ||
                FvrApproversStatus.Closed:
                return "completed";
            case FvrApproversStatus.Canceled ||
                FvrApproversStatus.Cancelled ||
                FvrApproversStatus.Rejected ||
                FvrApproversStatus.FVRRejected ||
                FvrApproversStatus.SentBack:
                return "failed";
            case FvrApproversStatus.Created ||
                FvrApproversStatus.Updated ||
                FvrApproversStatus.Pending:
                return "in-progress";
        }
    }


    /**
     * Toggles btw resource types to view fcr details
     *
     * @param {IDmTab} activeTab
     * @memberof FcrDetailsComponent
     */
    public toggleTab(activeTab: IDmTab): void {
        for (const tab of this.tabsContent) {
            tab.isActive = false;
        }
        activeTab.isActive = true;
        switch (activeTab.id) {
            case ResourceType.Labor: {
                this.showLabor = true;
                this.showUnits = false;
                this.showExpenses = false;
                this.showSubconFF = false;
                break;
            }
            case ResourceType.Unit: {
                this.showLabor = false;
                this.showUnits = true;
                this.showExpenses = false;
                this.showSubconFF = false;
                break;
            }
            case ResourceType.Expense: {
                this.showLabor = false;
                this.showUnits = false;
                this.showExpenses = true;
                this.showSubconFF = false;
                break;
            }
            case ResourceType.SubconFF: {
                this.showLabor = false;
                this.showUnits = false;
                this.showExpenses = false;
                this.showSubconFF = true;
                break;
            }
        }
    }
}