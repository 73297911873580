<div class="dm-dropdown__select--icon">
    <select [name]="name" [attr.id]="id" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy"
        [disabled]="isDisabled" [ngClass]="{'disabled-class': isDisabled, 'dm-dropdown__select-v2': isDropDownV2, 'error-border': showErrorBorder}" [(ngModel)]="value"
        class="dm-dropdown__select text-capitilize set-focus" (focus)="focusWasChanged()" [required]="isRequired">
        <ng-content select="option"></ng-content>
        <option class="text-capitilize" *ngFor="let option of options" [ngValue]="option" [disabled]="disabledoptions ? disabledoptions.includes(option.value) : false">
            {{option[labels]}}
        </option>
    </select>
    <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
</div>