import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { ViewCapAmountsModalComponent } from "./view-cap-amounts.component";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        ViewCapAmountsModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        ViewCapAmountsModalComponent
    ],
    exports: [
        ViewCapAmountsModalComponent
    ]
})
export class ViewCapAmountsModalModule { }
