import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { WbsProjectStateModalComponent } from "./wbs-project-ebs-state.component";
import { WBSService } from "../../../../../common/services/wbs.service";
import { MyPortfolioService } from "../../../../../common/services/my-portfolio.service";
import { CosmicServiceFunctions } from "../../../../../common/services/cosmic-function.service";
import { ProjectServiceV2 } from "../../../../../common/services/project.v2.service";

@NgModule({
    declarations: [
        WbsProjectStateModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
    ],
    providers: [
        ConfigManagerService,
        CosmicServiceFunctions,
        DMLoggerService,
        DMNotificationService,
        SharedFunctionsService,
        EngagementDetailService,
        ProjectServiceV2,
        WBSService,
        MyPortfolioService
    ],
    entryComponents: [
        WbsProjectStateModalComponent,
    ],
    exports: [
        WbsProjectStateModalComponent,
    ]
})
export class WbsProjectStateModalModule { }
