<div class="plan-labor-details">
  <div [ngClass]="showForecastDetails ? 'selection-div': 'dm-m-t-imp-30'" *ngIf="forecastDetails">
    <div class="display-flex justify-content-flex-end dm-m-t-12">
      <div class="dm-m-r-15">
        <input type="checkbox" class="cost-checkbox" id="costs" name="costs" [ngModel]="showCosts"
          (change)="showCostDetails()">
        <label for="costs" class="font-caption-alt">Show Costs</label>
      </div>
      <div class="dm-m-r-15">
        <input type="checkbox" class="cost-checkbox" id="revenue" name="revenue" [ngModel]="showRevenue"
          (change)="showRevenueDetails()">
        <label for="revenue" class="font-caption-alt">Show Revenue</label>
      </div>
      <div>
        <input type="checkbox" class="cost-checkbox" id="recommendations" name="recommendations"
          [ngModel]="showRecommendations" (change)="showForecastRecommendations()">
        <label for="revenue" class="font-caption-alt">Show Forecast Recommendations</label>
      </div>
    </div>
  </div>
  <div class="controls-bar display-flex" *ngIf="showForecastDetails && forecastDetails">
    <button type="button" class="dm-btn" *ngIf="!isForcastEditable" title="Edit Forecast" (click)="editForecast()">
      <span class="dm-btn--icon icon icon-editLegacy"></span>
      <span class="dm-btn--text">Edit Forecast</span>
    </button>
    <button type="button" class="dm-btn dm-m-r-imp-12" [ngClass]="{'disabled-class': !changedForecastDetails?.length}"
      *ngIf="isForcastEditable" title="Save Forecast" [disabled]="!changedForecastDetails?.length"
      (click)="saveForecast()">
      <span class="dm-btn--icon icon icon-save"></span>
      <span class="dm-btn--text">Save</span>
    </button>
    <button type="button" class="dm-btn dm-m-r-imp-12" *ngIf="isForcastEditable" title="Revert Forecast"
      [disabled]="!changedForecastDetails?.length" [ngClass]="{'disabled-class': !changedForecastDetails?.length}"
      (click)="revertForecast()">
      <span class="dm-btn--icon icon icon-refresh"></span>
      <span class="dm-btn--text">Revert</span>
    </button>
    <button type="button" class="dm-btn dm-m-r-imp-12" *ngIf="isForcastEditable" title="Stop Editing"
      (click)="stopEditingForecast()">
      <span class="dm-btn--icon icon icon-refresh"></span>
      <span class="dm-btn--text">Stop Editing</span>
    </button>
  </div>
  <div role="grid" *ngIf="forecastDetails">
    <div role="rowgroup" class="dm-grid-view-table sticky-header" [ngStyle]="{'top': setTopPositionForStickyHeader()}">
      <div class="dm-p-b-10 project-selection dm-flex-align-center">
        Project
        <ng-multiselect-dropdown tabindex="0" [placeholder]="'Select projects'" [data]="filterDataSource"
          class="font-caption-alt dm-m-l-8" id="labor-projects-multiselect-dropdown" name="labor-projects-multiselect-dropdown"
          [settings]="dropdownSettings" [(ngModel)]="selectedItems" (onDeSelect)="onProjectsDeSelect($event)" (onSelect)="onProjectsSelect()"
          (onSelectAll)="onProjectsSelectAll($event)" (onDeSelectAll)="onProjectsDeSelectAll()">
        </ng-multiselect-dropdown>
      </div>
      <div role="row" class="dm-grid-view-table__row dm-grid-view-table__row-header"
        [ngStyle]="getGridTemplateColumnForLaborGrid()">
        <div role="columnheader"
          class="dm-grid-view-table__headerCell text-align-right dm-p-t-8 dm-p-b-8 dm-p-l-5 dm-p-r-imp-0">
          <span type="button" tabindex="0" id="toggleDemands" class="acc-btn" (keyup.enter)="expandCollapseAll()"
            (keyup.Space)="expandCollapseAll()" (click)="expandCollapseAll()"
            [title]="showCosts && showRevenue ? 'Collapse Demands' : 'Expand Demands'"
            [attr.aria-expanded]="showCosts && showRevenue">
            <span class="icon" [ngClass]="showCosts && showRevenue ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
          </span>
          EBS
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8 dm-border-right-none">
          Resource
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8">
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8">
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8">
        </div>
        <div role="columnheader"
          class="dm-grid-view-table__headerCell text-align-right dm-p-x-12-y-8 justify-content-flex-end">
          <span *ngIf="showForecastDetails">Hours (EAC/Planned)</span>
          <span *ngIf="showDbDetails">Hours (Planned/ <br />Proposed)</span>
          <span *ngIf="showCfpDetails">Hours (Planned/ <br />Staffed)</span>
        </div>
        <div role="columnheader" class="dm-grid-view-table__headerCell display-grid"
          [ngClass]="{'dm-m-t-20': !showForecastDetails}">
          <div class="schedule-week-title">
            <div class="display-flex margin-left-auto">
              <div class="dm-flex-align-center justify-content-center margin-auto">
                <button class="dm-link navigation-btn dm-no-wrap" *ngIf="showPreviousBtn" (click)="prevWeeks()">
                  <span class="icon-full icon-chevron-left"></span>
                  Prev
                </button>
                <div class="dm-flex-align-center dm-plan-schedule-grid">
                  <div class="dm-m-l-imp-20 dm-plan-schedule-grid-datepicker">
                    <dm-datepicker id="startDate" name="requestStartDate" [minDate]="minStartDate"
                      [maxDate]="maxEndDate" [modelDate]="weeklySchedules[startIndex]?.startDate"
                      ariaLabelButton="Start date" placeholder="dd-mmm-yyyy" ariaLabelCalendar="Start date"
                      [showErrorBorder]="false" [isDatePickerV2]="true" (onDateChange)="onStartDateChange($event)">
                    </dm-datepicker>
                  </div>
                  <div class="dm-m-r-12 dm-no-wrap dm-plan-schedule-grid-endDate">
                    <span class="icon icon-DashKey"></span>
                    {{weeklySchedules[endIndex - 1].endDate | date : 'dd-MMM'}}
                  </div>
                </div>
                <button class="dm-link dm-m-r-12 navigation-btn dm-no-wrap" *ngIf="showNextBtn" (click)="nextWeeks()">
                  Next
                  <span class="icon-full icon-chevron-right"></span>
                </button>
              </div>
              <div class="dm-flex-align-center">
                <span class="dm-detail__label dm-m-r-4 dm-no-wrap dm-plan-schedule-grid-weeks">No. of Weeks</span>
                <dm-dropdown-menu [id]="'dm-weeks-change-ddl'" [ariaLabel]="'Weeks Change'" [isDropDownV2]="true"
                  class="dm-p-t-10" [(value)]="selectedNumberOfWeeks" [options]="weekDisplayListOptions"
                  [labels]="'value'" (change)="onWeeksChange(selectedNumberOfWeeks.value)">
                </dm-dropdown-menu>
              </div>
            </div>
          </div>
          <div class="schedule-week-grid" [ngStyle]="getGridTemplateColumn()">
            <ng-container *ngFor="let week of weeklySchedules | slice:startIndex:endIndex; let index = index">
              <div
                class="text-align-center text-uppercase grid-align-center schedule-week-grid-wrapper schedule-week-grid-previous dm-p-10"
                *ngIf="((startIndex < currentWeekIndex && index === 0))" [ngStyle]="getGridColumn('previous', index)">
                Prev
                Forecast/Actuals</div>
              <div
                class="text-align-center text-uppercase grid-align-center schedule-week-grid-wrapper schedule-week-grid-current dm-p-10"
                *ngIf="startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index === comparisonIndex">
                Current</div>
              <div
                class="text-align-center text-uppercase grid-align-center schedule-week-grid-wrapper schedule-week-grid-forecast dm-p-10"
                *ngIf="(startIndex <= currentWeekIndex && endIndex > currentWeekIndex && index > 0 && index - 1 === comparisonIndex) || (startIndex > currentWeekIndex && index === 0)"
                [ngStyle]="getGridColumn('forecast', index)">
                Forecast</div>
              <div *ngIf="index === endIndex - startIndex - 1 && endIndex - startIndex < maxWeeksDisplay"
                [ngStyle]="getGridColumn('', index)"></div>
            </ng-container>
            <ng-container *ngFor="let week of weeklySchedules | slice:startIndex:endIndex; let index = index">
              <div class="text-align-center dm-p-b-8 dm-p-t-8 schedule-week-grid-wrapper">
                <span class="schedule-week">Week {{week.weekNumber}}</span>
                <div class="schedule-week-start">
                  {{week.startDate | date : 'dd-MMM'}}
                </div>
                <div class="schedule-week-start">
                  {{week.endDate | date : 'dd-MMM' }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div role="columnheader" *ngIf="showForecastDetails"
          class="dm-grid-view-table__headerCell text-align--right dm-p-x-12-y-8 actuals-header">
          Actuals
          <br />
          (Year to
          Date)
        </div>
        <div role="columnheader" *ngIf="showForecastDetails"
          class="dm-grid-view-table__headerCell text-align--right dm-p-x-12-y-8 etc-header"
          [ngClass]="{'active-column-header': showForecastDetails}">
          Remaining
          <br />
          ETC
        </div>
        <div role="columnheader" *ngIf="showForecastDetails"
          class="dm-grid-view-table__headerCell text-align--right dm-p-x-12-y-8 eac-header">
          EAC
        </div>
      </div>
    </div>
    <div role="rowgroup" class="dm-grid-view-table dm-resource-details-table">
      <div class="dm-grid-view-table__body" *ngFor="let project of forecastDetails.projects">
        <div role="row" class="dm-grid-view-table__row" [ngStyle]="getGridTemplateColumnForLaborGrid()">
          <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 name-grid">
            <div class="display-flex">
              <span class="dm-p-r-6">
                <span class="icon set-focus" tabindex="0" (click)="expandCollapseProject(project)" role="button"
                  (keyup.enter)="expandCollapseProject(project)" (keyup.Space)="expandCollapseProject(project)"
                  [title]="project.isExpanded ? 'Collapse expanded project' : 'Expand collapsed project'"
                  [ngClass]="{'icon-ChevronDown': project.isExpanded, 'icon-chevron-right': !project.isExpanded}"></span>
              </span>
              <div class="dm-detail__value dm-m-r-4">Project {{project.name}}</div>
            </div>
            <div class="dm-p-l-20">
              EBS: {{project.projectId}}
            </div>
          </div>
        </div>
        <div *ngIf="project.isExpanded">
          <ng-container *ngFor="let demand of project.demand; let demandIndex = index; trackBy: trackByFunction">
            <div role="row" class="dm-grid-view-table__row entity-info" [ngStyle]="getGridTemplateColumnForLaborGrid()"
              *ngIf="(showDbDetails && demand.planVersionType  === 'DeliveryBaseline') || (showCfpDetails && demand.planVersionType  === 'CurrentFinancialPlan') || (showForecastDetails && demand.planVersionType  === 'Forecast')">
              <span
                class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 dm-border-right-none dm-p-r-imp-0">
                <span class="icon set-focus" tabindex="0" (click)="expandCollapseDemand(demand)" role="button"
                  (keyup.enter)="expandCollapseDemand(demand)" (keyup.Space)="expandCollapseDemand(demand)"
                  [title]="demand.isExpanded ? 'Collapse expanded demand' : 'Expand collapsed demand'"
                  [ngClass]="{'icon-ChevronDown': demand.isExpanded, 'icon-chevron-right': !demand.isExpanded}"></span>
              </span>
              <div role="gridcell"
                class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 dm-border-right-none">
                <div>
                  <dm-person-card [personDetails]="demand?.resources[0]?.personDetails"
                    [personImage]="demand?.resources[0]?.resourcePhoto" [avatarOnly]="true"
                    *ngIf="demand?.resources && demand?.resources.length && demand?.resources[0]?.alias">
                  </dm-person-card>
                  <div class="userPhoto userPhoto--dashed-border"
                    *ngIf="!demand?.resources || !demand?.resources.length || (demand?.resources && demand?.resources.length && !demand?.resources[0]?.alias)">
                    <span class="icon icon-user"></span>
                  </div>
                </div>
              </div>
              <div role="gridcell"
                class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 dm-border-right-none">
                <!--="ngbDropdown"-->
                <div #demandDetailsDropdown="ngbDropdown" (mouseover)="demandDetailsDropdown.open()" ngbDropdown
                  (mouseleave)="closeDemandDetailsDropdown($event, demandDetailsDropdown)"
                  (keydown.shift.tab)="demandDetailsDropdown.close()" placement="top-left"
                  [id]="'demand-details-dropdown-' + demand.demandId">
                  <div class="text-align-left" ngbDropdownToggle>
                    <div class="dm-detail__value">
                      <span *ngIf="demand?.resources && demand?.resources.length && demand?.resources[0]?.alias"
                        (click)="bulkUpdateSchedules(demand)">{{demand?.resources[0]?.resourceName}}</span>
                      <span
                        *ngIf="!demand?.resources || !demand?.resources.length || (demand?.resources && demand?.resources.length && !demand?.resources[0]?.alias)">Not
                        Staffed</span>
                    </div>
                    <div class="font-caption-alt">{{demand.roleDescription}}</div>
                  </div>
                  <section [id]="'demand-details-dropdown-menu-' + demand.demandId" class="col-sm-12 dropdown-menu"
                    aria-label="notifications" ngbDropdownMenu role="menu"
                    (mouseleave)="closeDemandDetailsDropdown($event, demandDetailsDropdown)">
                    <ng-container [ngTemplateOutlet]="demandDetailsPopup"></ng-container>
                  </section>
                </div>
                <ng-template #demandDetailsPopup>
                  <div class="demand-details dm-p-20">
                    <ul class="text-align-left">
                      <li class="dm-p-b-5">
                        Task Id: {{demand.wbsL3Id}}
                      </li>
                      <li class="dm-p-b-5">
                        Planned Role: {{demand.roleDescription}}
                      </li>
                      <li class="dm-p-b-5">
                        Demand Id: {{demand.sapDemandId}}
                      </li>
                      <li class="dm-p-b-5">
                        Billing Role Id: {{demand.billingRoleId}}
                      </li>
                      <li>
                        Resource Location: {{demand.resourceLocationDescription}}
                      </li>
                    </ul>
                  </div>
                </ng-template>
              </div>
              <div role="gridcell"
                class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 selectable-dropdown dm-p-l-imp-0">
                <div class="dm-transparent-btn margin-left-auto dropdown dm-p-t-5" type="button"
                  title="Resource Details">
                  <span class="dm-tile--more-circle icon icon-moreLegacy cursor-pointer" data-toggle="dropdown"></span>
                  <div class="dropdown-menu dropdown-menu-right font-caption-alt dm-p-imp-0">
                    <button class="dropdown-item dm-p-imp-12 dm-transparent-btn" id="item-2"
                      [ngClass]="{'disabled-class': !isForcastEditable}" (click)="bulkUpdateSchedules(demand)"
                      [disabled]="!isForcastEditable">
                      Bulk Forecast
                    </button>
                    <button class="dropdown-item dm-p-imp-12 dm-transparent-btn"
                      (click)="loadResourceDetails(demand?.resources[0], demand)">
                      Resource Details
                    </button>
                    <button class="dropdown-item dm-p-imp-12 dm-transparent-btn" id="item-3"
                      [ngClass]="{'disabled-class': !isForcastEditable || project.hasSchedulesCopied}"
                      (click)="getSchedules(demand, project)"
                      [disabled]="!isForcastEditable || project.hasSchedulesCopied">
                      Copy row values
                    </button>
                    <button class="dropdown-item dm-p-imp-12 dm-transparent-btn" id="item-3"
                      [ngClass]="{'disabled-class': !isForcastEditable || !project.hasSchedulesCopied || (project.hasSchedulesCopied && demand.hasSchedulesCopied)}"
                      (click)="copySchedules(demand, project)"
                      [disabled]="!isForcastEditable || !project.hasSchedulesCopied || (project.hasSchedulesCopied && demand.hasSchedulesCopied)">
                      Paste row values
                    </button>
                    <button class="dropdown-item dm-p-imp-12 dm-transparent-btn"
                      *ngIf="isForcastEditable && demand.hasForecastRecommendations && showRecommendations" id="item-4"
                      (click)="applyForecastRecommendations(demand)">
                      Apply Forecast Recommendations
                    </button>
                  </div>
                </div>
              </div>
              <div role="gridcell"
                class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 progress-grid dm-border-right-none">
                <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                  <ng-container *ngIf="demandDetail.id === demand.demandId">
                    <ngb-progressbar class="dm-p-t-4" type="success" [max]="100"
                      [value]="demandDetail.successPercentage" *ngIf="demandDetail.successPercentage === 100">
                    </ngb-progressbar>
                    <ngb-progressbar class="dm-p-t-4" type="warning" [max]="100"
                      [value]="demandDetail.successPercentage" *ngIf="demandDetail.successPercentage < 100">
                    </ngb-progressbar>
                    <ngb-progressbar class="dm-p-t-4" type="danger" [max]="100" [value]="demandDetail.successPercentage"
                      *ngIf="demandDetail.successPercentage > 100">
                    </ngb-progressbar>
                  </ng-container>
                </ng-container>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 dm-p-r-10 comparison-value">
                <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                  <ng-container *ngIf="demandDetail.id === demand.demandId">
                    <span *ngIf="showForecastDetails">
                      <span class="eac-planned-value"
                        [ngClass]="{'changed-value': demandDetail?.eac?.isEdited}">{{demandDetail.eac.hours}}</span>
                      / {{demandDetail.planned.hours}}
                      <br />
                      (hrs)
                    </span>
                    <span *ngIf="!showForecastDetails">{{demandDetail.planned.hours}} hrs
                    </span>
                  </ng-container>
                </ng-container>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell">
                <div class="schedule-week-grid schedule-week-grid-value" *ngIf="showForecastDetails"
                  [ngStyle]="getGridTemplateColumn()">
                  <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                    <ng-container *ngIf="demandDetail.id === demand.demandId">
                      <ng-container [ngTemplateOutlet]="demandsTemplate"
                        [ngTemplateOutletContext]="{schedule: demandDetail.eac.schedules, demandId: demandDetail.id, isActuals: false}">
                      </ng-container>
                      <ng-container [ngTemplateOutlet]="demandsTemplate"
                        [ngTemplateOutletContext]="{schedule: demandDetail.actuals.schedules, demandId: demandDetail.id, isActuals: true}">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="schedule-week-grid schedule-week-grid-value" *ngIf="!showForecastDetails"
                  [ngStyle]="getGridTemplateColumn()">
                  <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                    <ng-container *ngIf="demandDetail.id === demand.demandId">
                      <ng-container [ngTemplateOutlet]="demandsTemplate"
                        [ngTemplateOutletContext]="{schedule: demandDetail.planned.schedules, demandId: demandDetail.id, isActuals: false}">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
                <ng-template #demandsTemplate let-schedule="schedule" let-demandId="demandId" let-isActuals="isActuals">
                  <div *ngFor="let week of schedule | slice:startIndex:endIndex; let index = index; let last = last;trackBy: trackByFunction"
                    class="text-align-right dm-p-t-8 schedule-week-grid-wrapper" [ngClass]="{'schedule-week-grid-current': (startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index === comparisonIndex),
                  'schedule-week-grid-previous': ((startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index < comparisonIndex) || endIndex < currentWeekIndex),
                'dm-border-right-none': last}">
                    <ng-container
                      *ngIf="!isForcastEditable || isActuals || ((startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index < comparisonIndex) || endIndex < currentWeekIndex)">
                      <span *ngIf="week.hours" [ngClass]="{'italic-font': isActuals}">{{week.hours}}</span>
                      <span *ngIf="!week.hours || week.hours === 0"></span>
                    </ng-container>
                    <ng-container
                      *ngIf="isForcastEditable && !isActuals && ((startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && (index > comparisonIndex || index === comparisonIndex)) || startIndex > currentWeekIndex)">
                      <input id="{{week.scheduleId}}-hour" min="0" type="number" appTwoDigitDecimalPositiveNumber
                        class="schedule-week-hours-input text-align-right dm-p-2"
                        [ngClass]="{'schedule-week-hours-input-touched': week?.isEdited}" [(ngModel)]="week.hours"
                        (ngModelChange)="onHourChange(week, demandId)" aria-label="enter hours"
                        #scheduleHour="ngModel" />
                      <div *ngIf="showRecommendations" class="schedule-week-hours-recommendation">
                        {{week.recommendedHours}}</div>
                    </ng-container>
                  </div>
                </ng-template>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell actuals-grid text-align-right"
                *ngIf="showForecastDetails">
                <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                  <ng-container *ngIf="demandDetail.id === demand.demandId">
                    <span *ngIf="demandDetail?.actuals?.hours > 0">{{demandDetail?.actuals?.hours}}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-y-imp-15"
                *ngIf="showForecastDetails">
                <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                  <ng-container *ngIf="demandDetail.id === demand.demandId">
                    <span *ngIf="demandDetail?.eac?.hours !== demandDetail?.actuals?.hours" class="etc-value"
                      [ngClass]="{'changed-value': demandDetail?.eac?.isEdited}">{{demandDetail?.eac.hours -
                      demandDetail?.actuals?.hours}}</span>
                  </ng-container>
                </ng-container>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right" *ngIf="showForecastDetails">
                <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                  <ng-container *ngIf="demandDetail.id === demand.demandId">
                    <span class="eac-value"
                      [ngClass]="{'changed-value': demandDetail?.eac?.isEdited}">{{demandDetail?.eac.hours}}</span>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div role="row" class="dm-grid-view-table__row" [ngStyle]="getGridTemplateColumnForLaborGrid()"
              *ngIf="((showDbDetails && demand.planVersionType  === 'DeliveryBaseline') || (showCfpDetails && demand.planVersionType  === 'CurrentFinancialPlan') || (showForecastDetails && demand.planVersionType  === 'Forecast')) && demand.isExpanded && demand.showCosts">
              <ng-container>
                <div role="gridcell"
                  class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 cost-revenue-cell">
                  Cost ({{forecastDetails?.currency}})
                </div>
                <div role="gridcell"
                  class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 progress-grid dm-border-right-none dm-p-r-imp-0">
                  <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                    <ng-container *ngIf="demandDetail.id === demand.demandId">
                      <ngb-progressbar class="dm-p-t-4" type="success" [max]="100"
                        [value]="getCostsProgress(demandDetail)" *ngIf="getCostsProgress(demandDetail) === 100">
                      </ngb-progressbar>
                      <ngb-progressbar class="dm-p-t-4" type="warning" [max]="100"
                        [value]="getCostsProgress(demandDetail)" *ngIf="getCostsProgress(demandDetail) < 100">
                      </ngb-progressbar>
                      <ngb-progressbar class="dm-p-t-4" type="danger" [max]="100"
                        [value]="getCostsProgress(demandDetail)" *ngIf="getCostsProgress(demandDetail) > 100">
                      </ngb-progressbar>
                    </ng-container>
                  </ng-container>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-t-8 dm-p-r-10 comparison-value">
                  <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                    <ng-container *ngIf="demandDetail.id === demand.demandId">
                      <span *ngIf="showForecastDetails">
                        <span class="eac-planned-value">
                          <span [ngClass]="{'changed-value': demandDetail?.eac?.isEdited}">{{demandDetail?.eac.cost |
                            number: '1.2-2'}}</span>
                        </span>
                        / {{demandDetail?.planned.cost| number: '1.2-2'}}
                      </span>
                      <span *ngIf="!showForecastDetails">
                        {{demandDetail?.planned.cost| number: '1.2-2'}}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  <div class="schedule-week-grid schedule-week-grid-value" *ngIf="showForecastDetails"
                    [ngStyle]="getGridTemplateColumn()">
                    <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                      <ng-container *ngIf="demandDetail.id === demand.demandId">
                        <ng-container [ngTemplateOutlet]="hoursTemplate"
                          [ngTemplateOutletContext]="{schedule: demandDetail.eac.schedules}">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <div class="schedule-week-grid schedule-week-grid-value" *ngIf="!showForecastDetails"
                    [ngStyle]="getGridTemplateColumn()">
                    <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                      <ng-container *ngIf="demandDetail.id === demand.demandId">
                        <ng-container [ngTemplateOutlet]="hoursTemplate"
                          [ngTemplateOutletContext]="{schedule: demandDetail.planned.schedules}">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <ng-template #hoursTemplate let-schedule="schedule">
                    <div *ngFor="let week of schedule | slice:startIndex:endIndex; let index = index; let last = last;trackBy: trackByFunction"
                      class="text-align-right dm-p-t-8 schedule-week-grid-wrapper" [ngClass]="{'schedule-week-grid-current': (startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index === comparisonIndex),
                      'schedule-week-grid-previous': ((startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index < comparisonIndex) || endIndex < currentWeekIndex),
                      'dm-border-right-none': last}">
                      <ng-container>
                        <span [ngClass]="{'changed-value': week?.isEdited}">{{week.hours * week.costRate | number:
                          '1.2-2'}}</span>
                      </ng-container>
                    </div>
                  </ng-template>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--center dm-p-y-imp-15"
                  *ngIf="showForecastDetails">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--center dm-p-y-imp-15"
                  *ngIf="showForecastDetails">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--center dm-p-y-imp-15"
                  *ngIf="showForecastDetails">
                </div>
              </ng-container>
            </div>
            <div role="row" class="dm-grid-view-table__row" [ngStyle]="getGridTemplateColumnForLaborGrid()"
              *ngIf="project.billingType !== 'FF' && ((showDbDetails && demand.planVersionType  === 'DeliveryBaseline') || (showCfpDetails && demand.planVersionType  === 'CurrentFinancialPlan') || (showForecastDetails && demand.planVersionType  === 'Forecast')) && demand.isExpanded && demand.showRevenue">
              <ng-container>
                <div role="gridcell"
                  class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 cost-revenue-cell">
                  Revenue ({{forecastDetails?.currency}})
                </div>
                <div role="gridcell"
                  class="dm-grid-view-table__bodyCell text-align-right dm-p-x-12-y-8 progress-grid dm-border-right-none dm-p-r-imp-0">
                  <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                    <ng-container *ngIf="demandDetail.id === demand.demandId">
                      <ngb-progressbar class="dm-p-t-4" type="success" [max]="100"
                        [value]="getRevenueProgress(demandDetail, demand.billRate)"
                        *ngIf="getRevenueProgress(demandDetail, demand.billRate) === 100">
                      </ngb-progressbar>
                      <ngb-progressbar class="dm-p-t-4" type="warning" [max]="100"
                        [value]="getRevenueProgress(demandDetail, demand.billRate)"
                        *ngIf="getRevenueProgress(demandDetail, demand.billRate) < 100">
                      </ngb-progressbar>
                      <ngb-progressbar class="dm-p-t-4" type="danger" [max]="100"
                        [value]="getRevenueProgress(demandDetail, demand.billRate)"
                        *ngIf="getRevenueProgress(demandDetail, demand.billRate) > 100">
                      </ngb-progressbar>
                    </ng-container>
                  </ng-container>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align-right dm-p-t-8 dm-p-r-10 comparison-value">
                  <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                    <ng-container *ngIf="demandDetail.id === demand.demandId">
                      <span *ngIf="showForecastDetails">
                        <span class="eac-planned-value" [ngClass]="{'changed-value': demandDetail?.eac?.isEdited}">
                          {{demandDetail?.eac.hours * demand.billRate | number: '1.2-2'}}
                        </span>
                        / {{demandDetail?.planned.hours * demand.billRate | number: '1.2-2'}}
                      </span>
                      <span *ngIf="!showForecastDetails">
                        {{demandDetail?.planned.hours * demand.billRate | number: '1.2-2'}}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  <div class="schedule-week-grid schedule-week-grid-value" *ngIf="showForecastDetails"
                    [ngStyle]="getGridTemplateColumn()">
                    <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                      <ng-container *ngIf="demandDetail.id === demand.demandId">
                        <ng-container [ngTemplateOutlet]="hoursTemplate"
                          [ngTemplateOutletContext]="{schedule: demandDetail.eac.schedules}">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <div class="schedule-week-grid schedule-week-grid-value" *ngIf="!showForecastDetails"
                    [ngStyle]="getGridTemplateColumn()">
                    <ng-container *ngFor="let demandDetail of demandDetails; let demandIndex = index; trackBy: trackByFunction">
                      <ng-container *ngIf="demandDetail.id === demand.demandId">
                        <ng-container [ngTemplateOutlet]="hoursTemplate"
                          [ngTemplateOutletContext]="{schedule: demandDetail.planned.schedules}">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <ng-template #hoursTemplate let-schedule="schedule">
                    <div *ngFor="let week of schedule | slice:startIndex:endIndex; let index = index; let last = last;trackBy: trackByFunction"
                      class="text-align-right dm-p-t-8 schedule-week-grid-wrapper" [ngClass]="{'schedule-week-grid-current': (startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index === comparisonIndex),
                      'schedule-week-grid-previous': ((startIndex <= currentWeekIndex && endIndex >= currentWeekIndex && index < comparisonIndex) || endIndex < currentWeekIndex),
                      'dm-border-right-none': last}">
                      <ng-container>
                        <span [ngClass]="{'changed-value': week?.isEdited}">{{week.hours * demand.billRate | number:
                          '1.2-2'}}</span>
                      </ng-container>
                    </div>
                  </ng-template>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--center dm-p-y-imp-15"
                  *ngIf="showForecastDetails">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--center dm-p-y-imp-15"
                  *ngIf="showForecastDetails">
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--center dm-p-y-imp-15"
                  *ngIf="showForecastDetails">
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>