<section class="my-delegations--tile__header col-md-12 col-sm-12 col-xs-12 paginationSection">

    <button aria-label="Previous button to traverse previous records" class="dmpageprev paginationBtns font-caption-alt" [disabled]="currentPage === 1"
        (click)="prevPage($event)" >
        <span class="icon icon-chevron-left"></span>&nbsp;Prev
    </button>

    <input type="number" min="1" max="numberOfPages()" class="paginationTxt font-caption-alt" [(ngModel)]="currentPage" (blur)="pageNumberChange()"
        [attr.aria-label]="'Page '+currentPage+' of '+numberOfPages() + buttonType()"  aria-live="assertive" />

    <span class="font-caption-alt">of {{numberOfPages()}}</span>
    <button aria-label="Next button to traverse further records" class="dmpagenext paginationBtns font-caption-alt" [disabled]="currentPage >= portfolioDataLengthAfterFilter/portfolioItemsDisp"
        (click)="nextPage($event)">
        Next&nbsp;
        <span class="icon icon-chevron-right"></span>
    </button>

</section>