
import { Component, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";

import { Components } from "../../../../../common/application.constants";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { IProjectDetailsApiV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { IState } from "../../../../../store/reducers";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ProjectService } from "../../../../../common/services/project.service";
import { IInternalEngagementFinancialsListV2 } from "../../../../../common/services/contracts/portfolio.model";
import { ITile } from "../../../../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../../common/error.constants";
/// Except SR_out and Unit based all other types have NBUE hours
const nbueHoursDisplayableProjectCodes: string[] = ["91", "92", "93", "94", "96"];
@Component({
    selector: "dm-labor-consumption",
    templateUrl: "./labor-consumption.html",
    styleUrls: ["./labor-consumption.scss"]
})
export class LaborConsumptionComponent extends DmComponentAbstract {
    // @Input() public engagementId: string; /* Engagement ID for the component we are loading Labor Consumption for. We do a regex check prior to calling the API. */
    public cceacValues: any; // todo, this uses ICceac but more params need to be optional before we can use it
    public actualLabor: number;
    public plannedLabor: number;
    public errorText: string;
    public approvedNBUEHours: number;
    public isNbueApprovedHourVisible: boolean = false;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.LaborConsumption;
    public constructor(
    @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(ProjectService) private projectService: ProjectService
    ) {
        super(dmLogger, Components.LaborConsumption);
    }

    public ngOnInit(): void {
        const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(engagementId));
        engagementDetails$.pipe(untilDestroyed(this))
            .subscribe((engagementDetails: IEngagementDetailsState) => {
                if (engagementDetails.loaded) {
                    this.isNbueApprovedHourVisible = false;
                    if (engagementDetails.engagementDetails.projects && engagementDetails.engagementDetails.projects.length) {
                        const projects: IProjectDetailsApiV2[] = engagementDetails.engagementDetails.projects.filter((project) => nbueHoursDisplayableProjectCodes.indexOf(project.projectTypeCode) > -1);
                        if (projects.length > 0) {
                            this.isNbueApprovedHourVisible = true;
                            this.approvedNBUEHours = projects.reduce((a, b) => a + b.totalHours, 0);
                        }
                    }
                    this.projectService.getInternalEngagementFinancialsListV2([engagementId]).then((financialDetails: IInternalEngagementFinancialsListV2[]) => {
                        const filteredFinancialDetails = financialDetails.filter((fin) => fin.id = engagementId)[0];
                        if (filteredFinancialDetails) {
                            this.actualLabor = filteredFinancialDetails.actualHours ? filteredFinancialDetails.actualHours : undefined;
                            this.plannedLabor = filteredFinancialDetails.labor ? filteredFinancialDetails.labor : undefined;
                            this.cceacValues = {
                                consumed: this.actualLabor,
                                eac: this.plannedLabor,
                                deliveryBaseline: this.approvedNBUEHours
                            };
                        }
                    });
                    this.refreshOnItemInvalidation(engagementDetails);
                    this.setLoadersBasedOnItemState(engagementDetails);
                    this.setErrorsBasedOnItemState(engagementDetails);
                    if (engagementDetails.error) {
                        this.isServerError = true;
                    }
                }
            });
        this.tileContent = {
            title: "Labor Consumption"
        };
    }
}
