import { forwardRef, Inject, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { FxpEventBroadCastService, DeviceFactoryProvider, FxpRouteService, UserInfoService } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { INavigationListItemAttribute } from "../navigationlist.model";
import { RouteName, Components, FxpPartnerApplicationName, FeatureNames, V2ViewName, V2ViewsForToggle, LogEventName, SourceConstants } from "../../../common/application.constants";
import { NavigationService } from "../../../common/services/navigation.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IState } from "../../../store/reducers";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { IUserPreferenceState } from "../../..//store/userspreferences/userpreference.reducer";
import { getEntireUserPreference } from "../../../store/userspreferences/userpreference.selector";
import { IUserPreference } from "../../../common/services/contracts/userpreference.contract";
import { IProjectDetailsV2, IEngagementDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IProjectDetailsState } from "../../../store/project-details/project-details.reducer";
import { getEntireProjectDetails } from "../../../store/project-details/project-details.selector";

@Component({
    selector: "dm-tab-navigation",
    templateUrl: "./tab-navigation.html",
    styleUrls: ["./tab-navigation.scss"]
})
export class TabNavigationComponent extends DmComponentAbstract {
    public currentTab: INavigationListItemAttribute;
    public tabObjects: INavigationListItemAttribute[] = [];
    public projectDetails: IProjectDetailsV2;
    public isProjectContext: boolean;
    public engagementDetails: IEngagementDetailsV2;
    public isInternalEngagement: boolean;
    private userPreference: IUserPreference;
    private isForecastEnabled: boolean;
    private isPlanEnabled: boolean;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(NavigationService) private navigationService: NavigationService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(dmLogger, Components.TabNav);
    }

    /* Upon page load, page will call this function to change the navigation bar highlight to according page */
    public ngOnInit(): void {
        const wbsId = this.stateService.params.engagementId === undefined ? this.stateService.params.projectId : this.stateService.params.engagementId;
        if (this.sharedFunctionsService.getSelectedProjectId(this.stateService)) {
            this.isProjectContext = true;
        }
        this.isForecastEnabled = false;
        this.isPlanEnabled = false;
        const isInternalEngagementId = this.sharedFunctionsService.isEngagementIdInternal(wbsId) ? true : false;
        if (!isInternalEngagementId) {
            // Show/Hide new forecast experience tab
            this.navigationService.isNewForecastEnabled(wbsId)
                .then((forecastInflightResponse) => {
                    this.isForecastEnabled = forecastInflightResponse && forecastInflightResponse.isInForecastFlight ? true : false;

                    if (this.isForecastEnabled) {
                        this.setTabObjects(this.stateService.current.name, wbsId);
                        this.currentTab = this.setCurrentTab(this.stateService.current.name.substring(this.stateService.current.name.lastIndexOf(".") + 1), this.stateService.params);
                        this.sharedFunctionsService.focus(this.currentTab ? this.currentTab.id : "", true);
                    }
                    else {
                        this.setTabObjects(this.stateService.current.name, wbsId);
                        this.currentTab = this.setCurrentTab(this.stateService.current.name.substring(this.stateService.current.name.lastIndexOf(".") + 1), this.stateService.params);
                        this.sharedFunctionsService.focus(this.currentTab ? this.currentTab.id : "", true);
                    }
                })
                .catch(() => {
                    this.setTabObjects(this.stateService.current.name, wbsId);
                });

            this.navigationService.isNewPlanEnabled(wbsId)
                .then((planFlightingResponse) => {
                    this.isPlanEnabled = planFlightingResponse && planFlightingResponse.isInPlanFlight ? true : false;

                    if (this.isPlanEnabled) {
                        this.setTabObjects(this.stateService.current.name, wbsId);
                        this.currentTab = this.setCurrentTab(this.stateService.current.name.substring(this.stateService.current.name.lastIndexOf(".") + 1), this.stateService.params);
                        this.sharedFunctionsService.focus(this.currentTab ? this.currentTab.id : "", true);
                    }
                    else {
                        this.setTabObjects(this.stateService.current.name, wbsId);
                        this.currentTab = this.setCurrentTab(this.stateService.current.name.substring(this.stateService.current.name.lastIndexOf(".") + 1), this.stateService.params);
                        this.sharedFunctionsService.focus(this.currentTab ? this.currentTab.id : "", true);
                    }
                })
                .catch(() => {
                    this.setTabObjects(this.stateService.current.name, wbsId);
                });
        } else {
            this.setTabObjects(this.stateService.current.name, wbsId);
        }
        this.currentTab = this.setCurrentTab(this.stateService.current.name.substring(this.stateService.current.name.lastIndexOf(".") + 1), this.stateService.params);
        this.sharedFunctionsService.focus(this.currentTab ? this.currentTab.id : "", true);

        this.subscribe(
            this.fxpBroadcastService.On("$stateChangeStart", this.uiRouterStateChanged.bind(this))
        );
        this.endComponentLoad();
    }


    /**
     * Navigates to the new route based on the given attribute (tab)
     * @param attribute
     * @param $event
     */
    public navigateToNewRoute(attribute: INavigationListItemAttribute, $event: MouseEvent): void {
        $event.preventDefault();
        this.currentTab = attribute;

        let isProjectContext: boolean = false;
        if (this.sharedFunctionsService.getSelectedProjectId(this.stateService)) {
            isProjectContext = true;
        }

        const userPreferences$ = this.store.select((getEntireUserPreference()));
        userPreferences$.pipe(untilDestroyed(this)).subscribe((userPreferenceState: IUserPreferenceState) => {
            if (userPreferenceState.loaded && userPreferenceState.userPreference !== null) {
                this.userPreference = userPreferenceState.userPreference;
                const v2ViewDisplayPreference = this.userPreference.v2ViewDisplayPreference;
                let v2ViewName = "";
                if (v2ViewDisplayPreference) {
                    for (const v2View of V2ViewsForToggle) {
                        if (this.currentTab && this.currentTab.routeName.startsWith(v2View.toLowerCase())) {
                            v2ViewName = !isProjectContext ? V2ViewName.Engagement[v2View].toLowerCase() : V2ViewName.Project[v2View].toLowerCase();
                            if (v2ViewDisplayPreference[v2ViewName] === false) {
                                const filteredTabObjct = this.tabObjects.filter((tabObject) => tabObject.routeName === v2View.toLowerCase());
                                if (filteredTabObjct && filteredTabObjct.length) {
                                    this.currentTab = filteredTabObjct[0];
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        });

        if (this.stateService.current.name.startsWith(RouteName.InternalEngagementDetails)) {
            this.fxpRouteService.navigatetoSpecificState(RouteName.InternalEngagementDetails + "." + this.currentTab.routeName);
        } else if (isProjectContext) {
            if (RouteName.ProjectDetails + "." + this.currentTab.routeName === RouteName.ProjectForecast) {
                this.fxpRouteService.navigatetoSpecificState("projectForecast", {
                    wbsId: this.stateService.params.projectId
                });
            } else if (RouteName.ProjectDetails + "." + this.currentTab.routeName === RouteName.ProjectPlanForecastV2) {
                this.fxpRouteService.navigatetoSpecificState("projectPlanForecastV2", {
                    wbsId: this.stateService.params.projectId
                });
            } else {
                this.fxpRouteService.navigatetoSpecificState(RouteName.ProjectDetails + "." + this.currentTab.routeName);
            }
        } else {
            if (RouteName.EngagementDetails + "." + this.currentTab.routeName === RouteName.EngagementForecast) {
                this.fxpRouteService.navigatetoSpecificState("engagementForecast", {
                    wbsId: this.stateService.params.engagementId
                });
            } else if (RouteName.EngagementDetails + "." + this.currentTab.routeName === RouteName.EngagementPlanForecastV2) {
                this.fxpRouteService.navigatetoSpecificState("engagementPlanForecastV2", {
                    wbsId: this.stateService.params.engagementId
                });
            } else {
                this.fxpRouteService.navigatetoSpecificState(RouteName.EngagementDetails + "." + this.currentTab.routeName);
            }
        }
    }

    /**
     * Move the focus to element for accessibility tooling
     * @param id
     */
    public focus(id: string, focusDirection: string): void {
        let focusId;
        if (this.tabObjects) {
            for (let i = 0; i < this.tabObjects.length; i++) {
                if (this.tabObjects[i].id === id) {
                    if (focusDirection === "right" && this.tabObjects[i + 1].id) {
                        focusId = this.tabObjects[i + 1].id;
                    } else if (focusDirection === "left" && i > 0 && this.tabObjects[i - 1].id) {
                        focusId = this.tabObjects[i - 1].id;
                    } else {
                        focusId = this.tabObjects[i].id;
                    }

                }
            }
        }
        this.sharedFunctionsService.focus(focusId, true);
    }

    /**
    * Navigate to home page i.e portfolio page
    *
    * @memberof NavigationComponent
    */
    public navigateToHomePage(): void {
        this.dmLogger.logEvent(SourceConstants.Component.NavigationComponent, SourceConstants.Method.NavigateToHomePage, LogEventName.HomePageIconClick);
        this.fxpRouteService.navigatetoSpecificState(RouteName.Portfolio);
    }

    /**
     * Gets the collection of tab objects based on the given route name. Also sets the selected entity type for logging.
     * Activated on page load and on route change.
     */
    private getTabObjects(routeName: string): INavigationListItemAttribute[] {
        let tabObjects: INavigationListItemAttribute[] = [];
        if (routeName.startsWith(RouteName.InternalEngagementDetails)) {
            tabObjects = this.navigationService.getInternalEngagementTabs(this.stateService);
        } else if (routeName.startsWith(RouteName.EngagementDetails)) {
            tabObjects = this.navigationService.getEngagementTabs(this.stateService, this.isForecastEnabled, this.isPlanEnabled);
        } else if (routeName.startsWith(RouteName.ProjectDetails)) {
            tabObjects = this.navigationService.getProjectTabs(this.stateService, this.isForecastEnabled, this.isPlanEnabled);
        }
        return tabObjects;
    }

    /**
     * Display tab based on tab id
     *
     * @private
     * @param {string} tabId
     * @param {boolean} displayTab
     * @memberof TabNavigationComponent
     */
    private showTab(tabId: string, displayTab: boolean): void {
        if (this.tabObjects && this.tabObjects.length) {
            const index = this.tabObjects.findIndex((x) => x.id === tabId);
            if (index >= 0) {
                this.tabObjects[index].showTab = displayTab;
            }
        }
    }

    /**
     * sets tabs based on conditions.
     *
     * @private
     * @param {string} routeName
     * @param {string} wbsId
     * @memberof TabNavigationComponent
     */
    private setTabObjects(routeName: string, wbsId: string) {
        this.tabObjects = this.getTabObjects(routeName);
        if (!this.isProjectContext) {
            const engagementDetails$ = this.store.select(
                getEntireEngagementDetails(wbsId)
            );
            engagementDetails$
                .pipe(untilDestroyed(this))
                .subscribe((engagementDetails: IEngagementDetailsState) => {
                    /* Listen for invalidation that indicates an internal refresh. This will refresh the data and manage telemetry if the event is detected.
                    You no longer need to listen for the refresh event off of FXP Broadcast Service*/
                    this.refreshOnItemInvalidation(engagementDetails);
                    if (engagementDetails.loaded) {
                        this.engagementDetails = engagementDetails.engagementDetails;
                        this.isInternalEngagement = this.sharedFunctionsService.isEngagementInternal(this.engagementDetails);
                        const contractType = this.sharedFunctionsService.getContractType(this.engagementDetails.projects);
                        this.engagementDetails.typeOfContract = contractType;
                        let hasUnitBasedDemands: boolean;
                        let hasNpcActuals: boolean;
                        if (wbsId.length > 12) {
                            const project = engagementDetails.engagementDetails.projects.find((p) => p.id === wbsId);
                            hasUnitBasedDemands = project.hasUnitBasedDemands;
                            hasNpcActuals = project.hasNpcActuals;
                            if (hasUnitBasedDemands) {
                                this.showTab("project-actuals", true);
                            }
                            if (hasNpcActuals) {
                                this.showTab("project-npc", true);
                            }
                        } else {
                            hasUnitBasedDemands = engagementDetails.engagementDetails.hasUnitBasedDemands;
                            hasNpcActuals = engagementDetails.engagementDetails.hasNpcActuals;
                            if (hasUnitBasedDemands) {
                                this.showTab("engagement-actuals", true);
                            }
                            if (hasNpcActuals) {
                                this.showTab("engagement-npc", true);
                            }
                        }
                    }
                });
        } else {
            this.store.select(getEntireProjectDetails(wbsId)).subscribe((projectDetailsState: IProjectDetailsState) => {
                this.refreshOnItemInvalidation(projectDetailsState);
                this.setLoadersBasedOnItemState(projectDetailsState);
                this.setErrorsBasedOnItemState(projectDetailsState);
                if (projectDetailsState.loaded) {
                    this.projectDetails = projectDetailsState.projectDetails.projectFullDetails;
                    if (this.projectDetails) {
                        if (this.projectDetails.hasUnitBasedDemands) {
                            this.showTab("project-actuals", true);
                        }
                        if (this.projectDetails.hasNpcActuals) {
                            this.showTab("project-npc", true);
                        }
                    }
                }
            });
        }
    }

    /**
     * When the state on the UI Router changes, this method will be called.
     * Handles getting the tab objects in case of route change between different entities
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private uiRouterStateChanged(event: any, toState: any, toParams, fromState: any, fromParams): void {
        let wbsId: string;
        if (toParams.engagementId === undefined) {
            wbsId = toParams.projectId;
            this.isProjectContext = true;
        } else {
            wbsId = toParams.engagementId;
            this.isProjectContext = false;
        }
        const splitName: string[] = toState.name.split(".");
        let tabName: string = "";
        if (splitName.length > 0) {
            tabName = splitName[splitName.length - 1];
        }
        const isInternalEngagementId = wbsId && this.sharedFunctionsService.isEngagementIdInternal(wbsId) ? true : false;
        const validWbsId = this.sharedFunctionsService.validEngagementOrProjectId(wbsId);
        if (!isInternalEngagementId && wbsId && wbsId.length > 0 && validWbsId) {
            this.navigationService.isNewForecastEnabled(wbsId).then((isNewForecastEnabled) => {
                this.isForecastEnabled = isNewForecastEnabled && isNewForecastEnabled.isInForecastFlight ? true : false;
                if (this.isForecastEnabled) {
                    this.navigationService.isNewPlanEnabled(wbsId).then((isNewPlanEnabled) => {
                        this.isPlanEnabled = isNewPlanEnabled && isNewPlanEnabled.isInPlanFlight ? true : false;
                        this.setTabObjects(toState.name, wbsId);
                        this.currentTab = this.setCurrentTab(tabName, toParams);
                    });
                } else {
                    this.setTabObjects(toState.name, wbsId);
                    this.currentTab = this.setCurrentTab(tabName, toParams);
                }
            }).catch(() => {
                this.setTabObjects(toState.name, wbsId);
                this.currentTab = this.setCurrentTab(tabName, toParams);
            });
        } else {
            this.setTabObjects(toState.name, wbsId);
            this.currentTab = this.setCurrentTab(tabName, toParams);
        }
    }

    /**
     * tabName is what we can extract from state service about which tab page we are on
     * this function sets currentTab to actual whole tab name based on tabName
     */
    private setCurrentTab(tabName: string, toParams: any): INavigationListItemAttribute {
        if (!this.tabObjects) {
            return undefined;
        }

        for (const item of this.tabObjects) {
            if (item.routeName === tabName) {
                if (item.loadData) {
                    item.loadData(toParams);
                }
                return item;
            }
        }
        return undefined;
    }
}
