import { Component, Input, Inject, forwardRef } from "@angular/core";
import { UserProfileService, FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Components, SourceConstants } from "../../../common/application.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { ISurveyData } from "../../shared/inline-survey/inline-survey.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";


@Component({
    selector: "dm-feedback-modal",
    templateUrl: "./feedback-modal.html",
    styleUrls: ["./feedback-modal.scss"],
})
export class FeedbackModalComponent extends DmModalAbstract {

    @Input() public feedbackData: ISurveyData;
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(forwardRef(() => UserProfileService)) public userProfileService: UserProfileService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FxpMessageService) private fxpMessageService: FxpMessageService,
    ) {
        super(activeModal, dmLogger, Components.ReturnedResourceRequest);
    }

    public ngOnInit(): void {
        this.isComponentLoading = false;
    }


    /**
     * Invoked on inline survey form response.
     *
     * @param {Promise<any>} response
     * @memberof NavigationComponent
     */
    public onSubmitFeedback(response: Promise<any>): void {   
        this.isComponentLoading = true;    
        response.then(() => {
            this.isComponentLoading = false;
            this.fxpMessageService.addMessage("Feedback submitted successfully", this.FXP_CONSTANTS.messageType.success);
            this.activeModal.close();
        }).catch((error) => {
            this.isComponentLoading = false;
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.fxpMessageService.addMessage("Error occured", this.FXP_CONSTANTS.messageType.error);
            this.logError(SourceConstants.Method.OnSubmitFeedback, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.activeModal.close();
        });
    }

    /**
     * Close Feedback Modal.
     *    
     * @memberof NavigationComponent
     */
    public closeFeedbackModal(): void {
        this.activeModal.close();
    }

    /**
     * Move focus to next element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, "closeUpdateButton");
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, "closeUpdateButton");
        }
    }

}
