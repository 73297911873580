import { IServicesFinancial } from "../../../components/financial-mgmt/financial.model";
export interface IWbsEditEngagementDetails {
    name?: string;
    status?: string;
    pPjMbpId?: string;
    additionalPPJMs?: IWbsEditAPPJMDetails[];
    description?: string;
    startDate?: string;
    endDate?: string;
    shouldCascadeUpdate?: boolean;
}

export interface IWbsEditEngagementDetailsV2 {
    name?: string;
    status?: string;
    description?: string;
    startDate?: string;
    endDate?: string;
    shouldCascadeUpdate?: boolean;
    teamStructure?: ITeamStructureForEditWBS[];
}

export interface IWbsEditProjectDetails {
    name?: string;
    status?: string;
    pjMbpId?: string;
    additionalPJMs?: IWbsEditAPJMDetails[];
    description?: string;
    startDate?: string;
    endDate?: string;
    shouldCascadeUpdate?: boolean;
    nbuehours?: number;
}

export interface IWbsEditProjectDetailsV2 {
    name?: string;
    status?: string;
    description?: string;
    startDate?: string;
    endDate?: string;
    shouldCascadeUpdate?: boolean;
    nbuehours?: number;
    bifAmount?: number;
    teamStructure?: ITeamStructureForEditWBS[];
}

export interface ITeamStructureForEditWBS {
    role?: string; // In case of Engagement
    projectRole?: string;
    bpId: string;
    isDelete?: boolean; // Optional Field
}

export interface IWbsEditServiceDetails {
    status?: string;
    startDate?: string;
    endDate?: string;
    shouldCascadeUpdate?: boolean;
}

export interface IWbsEditTaskDetails {
    name?: string;
    status?: string;
    description?: string;
    startDate?: string;
    endDate?: string;
}

export interface IWbsAddTaskDetails {
    name: string;
    status: string;
    description: string;
    startDate: string;
    endDate: string;
}

export interface IWbsEditAPPJMDetails {
    pPjMbpId: string;
    isDelete: boolean;
}

export interface IWbsEditAPJMDetails {
    pjMbpId: string;
    isDelete: boolean;
}

export interface IDeliveryState {
    state: string;
    displayText: string;
    action: string;
    values: IDeliveryStateValue[];
}
export interface IWbsState {
    value: string;
    displayText: string;
    action: string;
    disableStatus: string[];
}

export interface IDeliveryStateValue {
    displayText: string;
    value: string;
}

export interface IResponseMessage {
    OnSaveSuccess: string;
    OnSavePartialSuccess?: string;
    OnSaveFailure: string;
}

export interface IDistributionListObject {
    CompanyCode: number;
    SecurityGroup: string;
}

export interface ISharedProjectFinancialInfoModel {
    minDate: Date;
    maxDate: Date;
    serviceFinancials: IServicesFinancial[];
}

export interface IProjectFinancialWbsModel {
    [projectId: string]: ISharedProjectFinancialInfoModel;
}

export interface IDateValidationRequest {
    startDate?: string;
    endDate?: string;
    cascadeDown?: boolean;
}

export interface ISapResponse {
    allowedStartDate: string;
    allowedEndDate: string;
    wbsId: string;
}

export interface IDbResponse {
    isChangeAllowed: boolean;
    userValidationRequired: boolean;
    statusMessage: string[];
    wbsId: string;
}

export interface IDateValidationResult {
    sapResponse: ISapResponse;
    dbResponse: IDbResponse;
}


export interface IWbsOrchestrationRequest {
    RequestedBy?: string;
    WbsId: string;
    WbsName: string;
    OrchestrationId: string;
    Description?: string;
    StartDate?: string;
    EndDate?: string;
    Level?: WbsLevel;
    ShouldCascade?: boolean;
    TeamStructure?: ITeamStructureForEditWBS[];
}

export interface IExchangeRate {
    exchangeRate: number;
    type: string;
}

export class EngagementBillStatusDetails {
    public packageBillStatus: PackageBillStatus[];
    public engagementBillStatus: EnagagementBillStatus;
}

export class EnagagementBillStatus {
    public engagementId: string;
    public totalPlanned: number;
    public totalBilled: number;
    public totalPrePeriodic: number;
    public totalConsumed: number;
    public creditBalance: number;
    public openDmr: number;
    public plannedVlea: number;
    public plannedJpm: number;
}

export class PackageBillStatus {
    public packageId: string;
    public totalPlanned: number;
    public totalBilled: number;
    public plannedVlea: number;
    public plannedJpm: number;
}

export class WbsETMDetails {
    public isETM: boolean;
    public runFFSeq: boolean;
}

export enum WbsLevel {
    Engagement = 1,
    Project = 2,
    Service = 3,
    Task = 4
}

export class DecoDetails {
    public isETM: boolean;
    public runFFSeq: boolean;
    public errors?: string[];
}

export interface IServiceProductFulfillmentDate {
    serviceId?: string;
    serviceName: string;
    serviceDescription: string;
    solutionCapability: string;
    contractStartDate?: Date;
    contractEndDate?: Date;
    plannedStartDate?: Date;
    plannedEndDate?: Date;
    actualStartDate?: Date;
    actualEndDate?: Date;
    termDuration?: number;
    termDurationUom?: string;
    termType?: string;
}

export interface IServiceProductFulfillmentDateUpdateRequest {
    serviceId?: string;
    contractStartDate?: string;
    contractEndDate?: string;
    plannedStartDate?: string;
    plannedEndDate?: string;
    actualStartDate?: string;
    actualEndDate?: string;
}
