import { UIRouterModule } from "@uirouter/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgModule, Injector, InjectionToken } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

/* NgRx */
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
// import the reducers from the store dir

import { ActualsModule } from "../actuals/actuals.module";
import { AdvancedSearchModule } from "../advanced-search/advanced-search.module";
import { AmendmentDetailsModule } from "../amendments/amendment-details/amendment-details.module";
import { BulkUploadInternalEngagementService } from "../../common/services/bulk-upload-internal-engagement.service";
import { CacheService } from "../../common/services/cache.service";
import { CceacModule } from "../financial-mgmt/tiles/cost-consumed/cceac/cceac.module";
import { CommonComponentsModule } from "./common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { ContextStorageService } from "../../common/services/contextstorage.service";
import { CostConsumedModule } from "../financial-mgmt/tiles/cost-consumed/cost-consumed.module";
import { CustomerService } from "../../common/services/customer.service";
import { DataService } from "../../common/services/data.service";
import { DecoratorService } from "../../common/services/decorator.service";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DmGrmService } from "../../common/services/dmgrm.service";
import { DmNotificationBarModule } from "../notification-bar/notification-bar.module";
import { DmNotificationService } from "../../common/services/dm-notification.service";
import { effects } from "../../store/effects";
import { EngagementDetailModule } from "../engagement-detail/engagement-detail.module";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { EngagementFinancialModule } from "../financial-mgmt/engagement-financial/engagement-financial.module";
import { EntryComponent } from "../entry/entry.component";
import { FaultControlService } from "../../common/services/faultcontrol.service";
import { FinancialPlanApprovalModule } from "../financial-plan-approvals/financial-plan-approvals.module";
import { InternalEngagementDetailModule } from "../internal-engagement/internal-engagement-detail/internal-engagement-detail.module";
import { InternalEngagementFinancialModule } from "../internal-engagement/internal-engagement-financial/internal-engagement-financial.module";
import { InternalEngagementSummaryModule } from "../internal-engagement/internal-engagement-summary/internal-engagement-summary.module";
import { InvoicesModule } from "../invoices/invoices.module";
import { ManageEbsV2Module } from "../manage-ebs-v2/manage-ebs-v2.module";
import { ManageSuppliersModule } from "../manage-suppliers/manage-suppliers.module";
import { MilestonesModule } from "../milestones/milestones.module";
import { MyPortfolioModule } from "../my-portfolio/my-portfolio.module";
import { MyPortfolioService } from "../../common/services/my-portfolio.service";
import { NavigationModule } from "../navigation/navigation.module";
import { NewInternalEngagementService } from "../../common/services/new-internal-engagement-service";
import { NgbDateMomentParserFormatter } from "../tiles/datepicker/ngb-date-parser-formatter";
import { NonProcuredMaterialsModule } from "../non-procured-materials/non-procured-materials.module";
import { OneProfileService } from "../../common/services/one-profile.service";
import { ProjectDetailModule } from "../project-detail/project-detail.module";
import { ProjectFilterModule } from "../tiles/project-filter/project-filter.module";
import { ProjectFinancialModule } from "../financial-mgmt/project-financial/project-financial.module";
import { ProjectLinksModule } from "../tiles/key-links/key-links.module";
import { ProjectService } from "../../common/services/project.service";
import { ProjectServiceV2 } from "../../common/services/project.v2.service";
import { PurchaseOrderService } from "../../common/services/po.service";
import { reducers } from "../../store/reducers";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { StaffingModule } from "../../components/staffing/staffing.module";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";
import { UnauthorizedModule } from "../unauthorized/unauthorized.module";
import { UserPreferenceService } from "../../common/services/userpreferences.service";
import { EngagementSummaryV2Module } from "../engagement-summary-v2/engagement-summary-v2.module";
import { ProjectSummaryV2Module } from "../project-summary-v2/project-summary-v2.module";
import { WbsDetailsModule } from "../wbs-details/wbs-details.module";
import { BulkMaintenanceStatusModalModule } from "../notification-bar/bulk-maintenance-status-modal/bulk-maintenance-status-modal.module";
import { SyncEACToPlanSubmitModule } from "../shared/sync-eac-to-plan-submit/sync-eac-to-plan-submit.module";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { StateService } from "@uirouter/angular";
import { SyncEACToPlanNotificationV2Module } from "../notification-bar/sync-eac-to-plan-notification-V2/sync-eac-to-plan-notification-v2.module";
import { SyncEACToPlanNotificationWrapperModule } from "../notification-bar/sync-eac-to-plan-notification-wrapper/sync-eac-to-plan-notification-wrapper.module";


export const DATE_FORMAT_DDMMYYYY: InjectionToken<string> = new InjectionToken<string>("DD-MMM-YYYY");

@NgModule({
    declarations: [
        EntryComponent, // This should be the only component declared here, all other components should be inside their imported module
    ],
    imports: [
        AdvancedSearchModule,
        AmendmentDetailsModule,
        CceacModule,
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        CostConsumedModule,
        EngagementDetailModule,
        EngagementFinancialModule,
        FinancialPlanApprovalModule,
        FormsModule,
        HttpClientModule,
        InternalEngagementFinancialModule,
        InternalEngagementDetailModule,
        InternalEngagementSummaryModule,
        InvoicesModule,
        ActualsModule,
        ManageSuppliersModule,
        MilestonesModule,
        NavigationModule,
        NonProcuredMaterialsModule,
        DmNotificationBarModule,
        ProjectDetailModule,
        ProjectFilterModule,
        ProjectFinancialModule,
        ProjectLinksModule,
        ReactiveFormsModule,
        StaffingModule,
        UIRouterModule,
        UnauthorizedModule,
        MyPortfolioModule,
        ManageEbsV2Module,
        EngagementSummaryV2Module,
        ProjectSummaryV2Module,
        BulkMaintenanceStatusModalModule,
        WbsDetailsModule,
        SyncEACToPlanSubmitModule,
        SyncEACToPlanNotificationV2Module,
        SyncEACToPlanNotificationWrapperModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({}),
        EffectsModule.forRoot(effects)
    ],
    providers: [
        {
            provide: NgbDateParserFormatter,
            useValue: new NgbDateMomentParserFormatter("DD-MMM-YYYY"),
        },
        ConfigManagerService,
        CacheService,
        CustomerService,
        ContextStorageService,
        DataService,
        DecoratorService,
        DMAuthorizationService,
        DmGrmService,
        EngagementDetailService,
        FaultControlService,
        MyPortfolioService,
        OneProfileService,
        ProjectService,
        ProjectServiceV2,
        PurchaseOrderService,
        SharedFunctionsService,
        StoreDispatchService,
        DmNotificationService,
        BulkUploadInternalEngagementService,
        NewInternalEngagementService,
        UserPreferenceService,
        DMLoggerService,
        StateService
    ],
    entryComponents: [
        EntryComponent
    ],
    exports: [], // no exports necessary because nobody is consuming Entry module--it is the entry point.
})
export class EntryModule {
    public static injector: Injector;

    public constructor(private injector: Injector) {
        EntryModule.injector = injector;
    }
}
