import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";

import { catchError, map, switchMap } from "rxjs/operators";
import { UserPreferenceService } from "../../common/services/userpreferences.service";
import { from as fromPromise, of } from "rxjs";
import * as userPreferenceActions from "./userpreference.action";
import { IUserPreference } from "../../common/services/contracts/userpreference.contract";

@Injectable()
export class UserPreferenceEffects {

    @Effect()
    public loadUserPreference$ = this.actions$
        .ofType(userPreferenceActions.UserPreferenceActionTypes.LOAD_USERPREFERENCE)
        .pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap((action: userPreferenceActions.LoadUserPreference) => fromPromise(
                this.userPreferenceService.getUserPreference()
            )
                .pipe(
                    map((userpreference: IUserPreference) => new userPreferenceActions.LoadUserPreferenceSuccess(userpreference)),
                    catchError((error) => of(new userPreferenceActions.LoadUserPreferenceFail(error)))
                )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService
    ) { }
}
