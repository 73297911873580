import { IBillablityType } from "./changerequestv2.contract";
import { IFinancialPlanResponse } from "./financial-plan.contracts";
import { IFinancialRoles } from "./projectservice-functions.contract";
import { IProgressBar } from "../../../components/tiles/dm-progress-bar/dm-progress-bar.contracts";
import { IRiskIssue, IVirtuosoUser, VirtuosoChangeRequestStatus } from "./virtuoso.contracts";
import { MisalignmentStatus } from "../../../components/amendments/amendments.contract";
import { Moment } from "moment";

export interface IApprover {
    approverAlias: string;
    approverName: string;
    approverStatusDescription: string;
    approverComments: string;
}

export interface IChangeRequest {
    crNumber: string;
    crDescription: string;
    createdOn: Date;
    structuralElement: string;
    engagementId: string;
    engagementDescription: string;
    statusDescription: string;
    misalignment: IMisalignment; /* If no misalignments, will be null */
    lastApproverAlias: string;
    requestorAlias: string;
    requestorName: string;
    requestedHours: number;
    requestedCost: number;
    planCurrency: string;
    executiveSummary: string;
    businessJustification: string;
    lastChangedBy?: string;
    lastChangedOn?: Date;
    comments: string;
    approvers: IApprover[];
    showProgressBar?: boolean;  // for UI, flag to determine whether progress bar or status should be shown
    approvalProgress?: IProgressBar[];
    virtuosoStatus?: VirtuosoChangeRequestStatus;
    uiStatusDescription?: string;
    riskReserveChange?: number;
    revenueChange?: number;
    picmWorkbookLink?: string;
}

export interface IMisalignment {
    misalignedChangeRequestId: string;
    misalignmentStatus: MisalignmentStatus;
}

export interface IFinancialSummaryDetails {
    planId: string;
    planVersionId: string;
    planVersionDescription: string;
    labor: number;
    laborUnits: string;
    marginInPercentage: number;
    cost: number;
    revenue: number;
    planCurrency: string;
}

export interface IPlanDetailsRequest {
    planId: string;
    planVersionId: string;
    structureElement: string;
    billingRoleId: string;
    domainKey: string;
    resourceType: string;
    roleId: string;
    demandId?: string;
    planCurrency?: string;
    laborUnits: string;
    quantity: number;
    requestedQuantity: number;
    costRate: number;
    planCost: number;
    planRenevue: number;
    billRate: number;
    resourceLocationKey: string;
    deliveryLocationKey: string;
}

export interface IApproverDetail {
    approverAlias: string;
    approverName: string;
    approverStatusDescription: string;
    approverComments: string;
}

export interface ICrRequest {
    planDetailsRequests: IPlanDetailsRequest[];
    crDetails: ICrDetails;
    changeRequestApprovers: ICrApprover[];
}

export interface IExistingDemand {
    taskDescription: string;
    taskId: string;
    billingRoleId: string;
    domainKey: string;

    resourceType: string;
    resourceTypeDescription: string;
    roleActivityCode: string;
    roleDescription: string;
    demandId: string;
    planCurrency: string;
    laborUnits: string;
    planHours: number;
    planCostRate: number;
    planCost: number;
    planRevenue: number;
    billRate: number;
    resourceLocationKey: string;
    deliveryLocationKey: string;
    isDisplayed?: boolean; // a flag to indicate whether or not this existing resource is currently shown in the table
    rolePartNumber?: string;
    staffedHours?: number;
    staffedCost?: number;
    dbHours?: number;
    dbCostRate?: number;
    dbCost?: number;
    isCrPendingInProject?: boolean;
    isDemandNonBillable: boolean;
}
export interface IBillRate {
    planId?: string;
    billingRoleId: string;
    description: string;
    validFrom: Date;
    validTo: Date;
    isTMBillRate: boolean;
    billRate: number;
    billRateCurrency: string;
}

export interface ICrDetails {
    crDescription: string;
    structuralElement: string;
    engagementId: string;
    isFixedFee: boolean;
    requestorAlias: string;
    requestedHours: number;
    requestedCost: number;
    planCurrency?: string;
    executiveSummary: string;
    businessJustification: string;
}


export interface ICostRateInfo {
    resourceType: string;
    activityType: string;
    rolePartNumber: string;
    currency: string;
    costRate: number;
    costRatePeriod: ICostRatePeriod[];
}

export interface ICostRatePeriod {
    startDate: Date;
    endDate: Date;
    costRate: number;
}

export interface ICostRateSchedule {
    startDate: Moment;
    endDate: Moment;
    costRate: number;
    duration: number;
}

export interface IBlendedCostRequest {
    startDate: Date;
    endDate: Date;
    existingHours: number;
    currentFinancialPlanCost: number; // cfpCost * (existing)  TODO: db cost check with Praseeth
    additionalHours: number;
    costPeriodList: ICostRatePeriod[];
    staffedHours: number;
    staffedCost: number;
    isCurrentDateStartDate: boolean;
}

export interface IExistingCrRequest {
    changeRequestDetails: IExistingDemand[];
    billRates: IBillRate[];
}

export interface ICrApproverRuleEngineRequest {
    engagementDomain: string;
    engagementType: string;
    isFixedFee: boolean;
    requestedHours: number;
    contractBaseLineHours: number;
    deliveryBaseLineHours: number;
    requestedCost: number;
    contractBaseLineCost: number;
    deliveryBaseLineCost: number;
    totalRiskReserveAmount: number;
    remainingRiskReserveAmount: number;
}

export interface ICrApprover {
    persona: Persona;
    alias: string;
    role?: CrRole;
    level?: number;
    approverList?: IVirtuosoUser[];
    fullName?: string;
}

export interface ICrApproverResponse {
    id: string;
    description: string;
    engagementId: string;
    changeRequestApprovers: ICrApprover[];
    createdBy: Date;
    createdOn: Date;
}

export interface IDmmApprover {
    fullName: string;
    alias: string;
    persona?: Persona;
}

export interface IApprovedFinancialsResponse {
    engagementId: string;
    approvedFinancials: IApprovedFinancial[];
}

export interface IApprovedFinancial {
    totalRiskReserve: number;
    pendingRiskReserve: number;
    approvedRiskReserve: number;
    pendingNbue: number;
    approvedNbue: number;
    wbsId: string;
}

export interface IApprovedFinancialsResponseV2 {
    engagementId: string;
    projects: IProjectApprovedFinancial[];
}

export interface IProjectApprovedFinancial {
    totalRiskReserve: number;
    pendingRiskReserve: number;
    approvedRiskReserve: number;
    consumedRiskReserve: number;
    remainingRiskReserve: number;
    pendingNbue: number;
    approvedNbue: number;
    costOverrun: number;
    wbsId: string;
    version: string;
    tasks: ITaskApprovedFinancial[];
    isExpanded?: boolean;
    name?: string;
}

export interface ITaskApprovedFinancial {
    totalRiskReserve: number;
    parentId: string;
    wbsId: string;
    version: number;
    name?: string;
    errorMessage?: string;
}

export enum Persona {
    AccountDeliveryExecutive = "Account Delivery Executive",
    DeliverySpl = "Delivery SPL",
    DomainLeader = "Domain Leader",
    DomainManager = "Domain Manager",
    LeadProjectManager = "Lead Project Manager",
    ProjectManager = "Project Manager",
    SqaLead = "SQA Lead",
    TimezoneLeader = "Timezone Leader",
    DeliveryDirector = "Delivery Director",
    DeliveryManagementExecutive = "Delivery Management Executive",
    ConsultingPracticeLeader = "Consulting Practice Leader",
    AreaDeliveryLeader = "Area Delivery Leader",
    RegionalDeliveryLeader = "Regional Delivery Leader"
}

export enum CrRole {
    Approver = "Approver",
    Informer = "Informer"
}

export enum FvrStatus {
    Approved = "Approved",
    Cancelled = "Cancelled",
    Created = "Created",
    Rejected = "Rejected",
    SentBack = "SentBack",
    Updated = "Updated",
}

export enum SapFvrStatus {
    Approved = "FVR Approved",
    Canceled = "Canceled",
    Pending = "Pending Approval",
    Rejected = "FVR Rejected"
}

export enum CrEngagmentType {
    DT1 = "DT1",
    DT2 = "DT2",
    DT3 = "DT3",
    DT4 = "DT4",
    DT5 = "DT5"
}

export interface ICrResponse {
    changeRequestNumber: string;
}

export interface IEngagementFinancialPlanSummary {
    finalizedContractBaseline: ISummaryRow;
    finalizedDeliveryBaseline: ISummaryRow;
    revisedDeliveryBaseline: ISummaryRow;
    currentFinanicalPlan: ISummaryRow;
    revisedFinancialPlan: ISummaryRow;
    planId: string;
    planCurrency: string;
    laborUnits: string;
}

export interface ISummaryRow {
    margin: number;
    hours: number;
    cost: number;
    revenue: number;
    status?: string;
}

export interface IReasonCode {
    id: string;
    text: string;
}

export interface ICrAttachment {
    id: string;
    fileName: string;
}

export interface ICreateChangeRequest {
    changeRequestDetailList: IChangeRequestDetails[];
    changeRequestHeader: IChangeRequestHeader;
    changeRequestApprovers: ICrApprover[];
    financialPlanDetails?: ICrFinancialPlanDetails | IFinancialPlanResponse;
    attachment?: ICrAttachment;
}

export interface IChangeRequestDetails {
    planId: string;
    planVersionId: string;
    structureElement: string;
    billingRoleId: string;
    resourceType: string;
    roleId: string;
    roleDescription: string;
    demandId: string;
    planCurrency: string;
    laborUnits: string;
    quantity: number;
    requestedQuantity: number;
    costRate: number;
    planCost: number;
    planRevenue: number;
    billRate: number;
    resourceLocationKey: string;
    dbCost: number;
    dbHour?: number;
    existingRoleDescription?: string;
    requestedCost?: number;
    cfpCost?: number;
    existingCfpCost?: number;  // this field should be populated to capture existing cost prior to any blended cost rate changes since SAP does not store in all scenarios for FCR
}

export interface IChangeRequestHeader {
    crDescription: string;
    structuralElement: string;
    engagementId: string;
    isFixedFee: boolean;
    requestorAlias: string;
    requestedHours: number;
    requestedCost: number;
    planCurrency: string;
    executiveSummary: string;
    businessJustification: string;
    risks: IRiskIssue[];
    issues: IRiskIssue[];
    reason: IReasonCode;
    comments: string;
}

export interface IChangeRequestResourcePayloadDetails {
    resourceRequestDetails: IChangeRequestDetails[];
    totalAdditionalCost?: number;
    totalAdditionalHours?: number;
}

export interface ICrFinancialPlanDetails {
    engagement: ICrEngagementFinancials;
    projects: ICrProjectFinancials[];
}

export interface ICrEngagementFinancials {
    engagementId: string;
    planDetails: ICrEngagementPlanDetails[];
}

export interface ICrProjectFinancials {
    projectId: string;
    projectPlanDetails: ICrProjectPlanDetails[];
}

export interface ICrPlanDetails {
    planId: string;
    planVersionId: string;
    planVersionDescription: string;
    labor: number;
    laborUnits: string;
    cost: number;
    planCurrency: string;
    riskReserveAmount?: number; // optional for TM or required for all?
}

export interface ICrEngagementPlanDetails extends ICrPlanDetails {
    marginInPercentage: number;
    revenue: number;
}

export interface ICrProjectPlanDetails extends ICrPlanDetails {
    unit: number;
    unitUnits: number;
}

export interface ITphRrSummary extends ITphRrDetailsData {
    hoursImpactPercentDecimal?: number;
    cbCostExcludingRr?: number;
    newPlannedCost?: number;
    grossCostOverrun?: number;
    grossCostOverrunPercentDecimal?: number;
    previouslyApprovedCost?: number;
    additionalApprovalNeeded?: number;
}

export interface ITphRrDetailsData {
    approvedRiskReserve?: number;
    contractBaselineCost?: number;
    costImpact?: number;
    deliveryBaselineCost?: number;
    deliveryBaselineHours?: number;
    hoursImpact?: number;
    totalDeliveryBaselineRiskReserve?: number;
    totalRiskReserve?: number;
    planCurrency?: string;
}

export interface ICrResource {
    uuid: string;
    resourceType: ResourceType;
    isNewRequest: boolean;
    editModeEnabled: boolean;
    isSaveable: boolean;
    currentState: ICrResourceViewModel;
    savedState: ICrResourceViewModel;
    isAutoPopulated?: boolean;
    isDbOnlyDemand?: boolean;
    isPnfEdit?: boolean;
}

export interface ICrDemandOutput extends ICrResource {
    currentState: ICrDemandEditViewModel;
    savedState: ICrDemandEditViewModel;
    isSubconRole?: boolean;
    isRoleOrResourceLocationChanged?: boolean;
}

export interface ICrUnitOutput extends ICrResource {
    currentState: ICrUnitViewModel;
    savedState: ICrUnitViewModel;
    isRoleOrResourceLocationChanged?: boolean;
}

export interface ICrUnitViewModel extends ICrResourceViewModel {
    billingInfo?: IBillRate;
    existingResource?: IExistingUnit;
    role?: IFinancialRoles;
    costRate?: number;
    roleValues?: IFinancialRoles[];
    newUnits: number;
    isCostRateResolved?: boolean;

    costPeriodList?: ICostRatePeriod[];
    apiCostRate?: number;
    isNewUnitsLessThanActuals?: boolean;
    isAdditionalUnitsQuantityAllowed?: boolean;
}

export interface IExistingUnit extends ICrCostResource {
    taskDescription?: string;
    taskId?: string;
    plannedCost: number;
    plannedRevenue: number;
    plannedHours: number;  // TODO: need to update on API side if we want to get rid of this field
    plannedCostRate: number;

    structureId: string;
    version: number;
    resourceItemId: string;
    demandId: string;
    roleId: string;
    roleDescription: string;
    resourceType: string;
    resourceTypeDescription: string;
    resourceLocation: string;
    resourceLocationDescription: string;
    resourceLocationCc: string;
    billRate: number;
    uoM: string;

    existingUnits?: number;
    isDisplayed?: boolean;
    cfpCost?: number;
    dbUnits?: number;
    dbCostRate?: number;
    dbCost?: number;
    roleInfo?: IFinancialRoles;
    isCrPendingInProject?: boolean;
    exisitingActuals?: number;
}
export interface ICrSubconOutput extends ICrResource {
    currentState: ICrSubconViewModel;
    savedState: ICrSubconViewModel;
}

export interface ICrSubconViewModel extends ICrResourceViewModel {
    newPlannedCost: number;
    additionalCost?: number;
    existingResource?: IExistingSubcon;
    existingCost?: number;

    isPlannedCostLessThanTotalPo?: boolean;
}

export interface IExistingSubcon extends ICrCostResource {
    taskDescription?: string;
    taskId?: string;
    plannedCost: number;
    plannedRevenue: number;
    existingCost?: number;

    structureId: string;
    version?: number;
    roleId: string;
    roleDescription: string;
    poNumber: string;
    poDescription: string;
    poLineItem: string;
    resourceType: string;
    resourceTypeDescription: string;
    uoM: string;

    isDisplayed?: boolean;
    dbCost?: number;
    cfpCost?: number;
    totalPOAmount?: number;
}

export interface ICrExpenseOutput extends ICrResource {
    currentState: ICrExpenseViewModel;
    savedState: ICrExpenseViewModel;
}

export interface ICrExpenseViewModel extends ICrResourceViewModel {
    newPlannedCost: number;
    billableType?: IBillablityType;
    additionalCost?: number;
    existingResource?: IExistingExpense;
    existingCost?: number;
}

export interface IExistingExpense extends ICrCostResource {
    taskDescription?: string;
    taskId?: string;
    billableTypeId?: string;
    billableTypeDescription?: string;
    plannedCost: number;
    plannedRevenue: number;

    structureId: string;
    version: number;
    roleId: string;
    roleDescription: string;
    resourceType: string;
    resourceTypeDescription: string;

    isDisplayed?: boolean;
    dbCost?: number;
    cfpCost?: number;
}

export interface ICrResourceViewModel {
    isContractTypeValid?: boolean;
    isProjectPFPOrPFF?: boolean;  // TODO: Ask Deepak about this
    isCrPendingInProject?: boolean;
    assignedTask?: ITask;
    existingResource?: ICrCostResource;
}

export interface ICrCostResource {
    plannedCost: number;
    structureId: string;
    plannedHours?: number;
    plannedCostRate?: number;
    isCrPendingInProject?: boolean;
    isFixedFeeProject?: boolean;
}

export interface ICrDemandEditViewModel extends ICrResourceViewModel {
    existingHours?: number;
    isCostRateResolved?: boolean;
    isAdditionalTimeValid?: boolean;
    isNewHoursLessThanStaffedHours?: boolean;
    isAdditionalHoursQuantityAllowed?: boolean;
    roleValues?: IFinancialRoles[]; // labor (can be applicable for units, consider generalizing and renaming to IPhoenixRole base class) potentially consider moving
    costPeriodList?: ICostRatePeriod[];
    apiCostRate?: number;

    hours: number;
    resourceLocationKey: string;
    blendedCostRate?: number;
    billingInfo?: IBillRate;
    existingDemand?: IExistingDemand;
    role?: IFinancialRoles;
}

export interface ICrDemandChangeEvent {
    projectCostChange: number;
    hoursChange: number;
    projectId: string;
}

export interface ITask {
    wbsl3Id: string;
    wbsl3Name: string;
    projectId: string;
    projectName: string;
    serviceName: string;
    isFixedFeeProject: boolean;
    resourceLocation?: string;
    roleDescription?: string;
    plannedHours?: number;
    projectStartDate?: Date;
    projectEndDate?: Date;
    isCrPendingInProject?: boolean;
}

export interface IEngagementCfpCostData {
    totalRevisedCfpCost: number;
    totalExistingCfpCost: number;
    totalReconciledLaborHours: number;
}

export enum AmendmentType {
    Contractual = "Contractual",
    NonContractual = "NonContractual"
}

export enum ResourceType {
    Labor = "Labor",
    Unit = "Unit",
    Expense = "Expense",
    SubconFF = "SubconFF"
}

export enum ResourceTypeCode {
    Labor = "0ACT",
    Unit = "ZUNI",
    Expense = "0EXP",
    SubconFF = "ZEXS"
}

export interface IRiskReserveRequest {
    taskRRDetails: ITaskRRDetailsRequest[];
    attachment?: ICrAttachment;
}

export interface ITaskRRDetailsRequest {
    wbsId: string;
    totalRiskReserve: number;
    projectId?: string;
    previousRiskReserve: number;
}

export interface IRiskReserveAuditPayload {
    WbsWorkPackageId: string;
    CurrentRrAmount: number;
    PreviousRrAmount: number;
    FileId: string;
    CreatedByAlias: string;
}

export interface IRiskReserveOrchestrationRequest {
    WbsId: string;
    RequestedBy: string;
    OrchestrationId: string;
    UpdateTaskRRDetailsRequest: IRiskReserveRequest;
}

export interface IResourceDetailsResponse {
    unitDetails: IExistingUnit[];
    expenseDetails: IExistingExpense[];
    subconFFDetails: IExistingSubcon[];
}