import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { InternalEngagementOverviewTabV2Component } from "./internal-engagement-overview-tab-v2.component";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DelegationDetailsModalModule } from "../../../tiles/delegation-details-modal/delegation-details-modal.module";
import { InternalEngagementFinancialModalModule } from "../internal-engagement-financial-modal/internal-engagement-financial-modal.module";
import { DmUserProfileModule } from "../../../../components/shared/user-name/user-name.module";

@NgModule({
    declarations: [
        InternalEngagementOverviewTabV2Component
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        DelegationDetailsModalModule,
        NgbModule,
        UIRouterModule,
        InternalEngagementFinancialModalModule,
        DmUserProfileModule
    ],
    providers: [
        DMLoggerService,
        ConfigManagerService,
        SharedFunctionsService
    ],
    entryComponents: [
        InternalEngagementOverviewTabV2Component
    ],
    exports: [
        InternalEngagementOverviewTabV2Component
    ]
})

export class InternalEngagementOverviewTabV2Module { }
