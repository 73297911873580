import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Component, forwardRef, Inject } from "@angular/core";
import { Components } from "../../../../../common/application.constants";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../../store/engagement-details/engagement-details.reducer";
import { IState } from "../../../../../store/reducers";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ProjectService } from "../../../../../common/services/project.service";
import { IInternalEngagementFinancialsListV2 } from "../../../../../common/services/contracts/portfolio.model";
import { ITile } from "../../../../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../../../common/error.constants";

@Component({
    selector: "dm-internal-eng-cost-consumption",
    templateUrl: "./internal-engagement-cost-consumption.html",
    styleUrls: ["./internal-engagement-cost-consumption.scss"]
})
export class InternalEngagementCostConsumptionComponent extends DmComponentAbstract {
    public currencyCode: string;
    public actualCost: number = 0;
    public cceacValues: any;
    public plannedCost: number = 0;
    public tileContent: ITile;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.CostConsumption;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(ProjectService) private projectService: ProjectService
    ) {
        super(dmLogger, Components.IntEngCostConsumption, [{ component: Components.Cceac, isCritical: true }]);
    }

    public ngOnInit(): void {
        const selectedInternalEngagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.tileContent = {
            title: "Cost Consumption"
        };
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(selectedInternalEngagementId));

        engagementDetails$.pipe(untilDestroyed(this))
            .subscribe((engagementDetails: IEngagementDetailsState) => {
                if (engagementDetails.loaded) {
                    this.currencyCode = engagementDetails.engagementDetails.currency;
                    this.projectService.getInternalEngagementFinancialsListV2([selectedInternalEngagementId]).then((financialDetails: IInternalEngagementFinancialsListV2[]) => {
                        if (financialDetails && financialDetails.length) {
                            const filteredFinancialDetails = financialDetails.filter((fin) => fin.id = selectedInternalEngagementId)[0];
                            if (filteredFinancialDetails) {
                                this.actualCost = filteredFinancialDetails.actualCost ? filteredFinancialDetails.actualCost : undefined;
                                this.plannedCost = filteredFinancialDetails.plannedCost ? filteredFinancialDetails.plannedCost : undefined;
                                this.cceacValues = {
                                    consumed: this.actualCost,
                                    eac: this.plannedCost
                                };
                            }
                        }
                        this.currencyCode = engagementDetails.engagementDetails.currency;
                    });
                }
                this.refreshOnItemInvalidation(engagementDetails);
                this.setLoadersBasedOnItemState(engagementDetails);
                this.setErrorsBasedOnItemState(engagementDetails);
                if (engagementDetails.error) {
                    this.isServerError = true;
                }
            });
    }
}

