<section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0">
    <section class="col-md-3 col-sm-3 section__wrapper">
        <section class="input__label">
            <label class="input__label--text font-caption-alt" for="dm-wbs-projects-ddl">
                Project
            </label>
            <section class="input__select--icon" [ngClass]="{'input--icon-disabled':isProjectContext}">
                <dm-dropdown-menu [id]="'dm-ebs-projects-ddl'" [isDropDownV2]="true" [ariaLabel]="'Projects'"
                    [(value)]="selectedProject" [options]="items" [labels]="'displayName'"
                    [isDisabled]="isProjectContext" (change)="onSelectedProjectChanged(selectedProject)">
                    <option [value]="placeholder">All projects</option>
                </dm-dropdown-menu>
            </section>
        </section>
    </section>
    <section class="col-md-3 col-sm-3 section__wrapper">
        <section class="input__label">
            <label class="input__label--text font-caption-alt" for="dm-wbs-services-ddl">
                Service
            </label>
            <section class="input__select--icon"
                [ngClass]="{'input--icon-disabled':isProjectContext ? !isProjectContext :  isDisabled(selectedProject)}">
                <dm-dropdown-menu [id]="'dm-ebs-services-ddl'" [isDropDownV2]="true" [ariaLabel]="'Services'"
                    [(value)]="selectedService" [options]="selectedProject.children" [labels]="'displayName'"
                    [isDisabled]="isProjectContext ? !isProjectContext : isDisabled(selectedProject)"
                    (change)="onSelectedServiceChanged(selectedService)">
                    <option [value]="placeholder">All</option>
                </dm-dropdown-menu>
            </section>
        </section>
    </section>
    <section class="col-md-3 col-sm-3 section__wrapper">
        <section class="input__label">
            <label class="input__label--text font-caption-alt" for="dm-wbs-tasks-ddl">
                Task
            </label>
            <section class="input__select--icon" [ngClass]="{'input--icon-disabled':isDisabled(selectedService)}">
                <dm-dropdown-menu [id]="'dm-ebs-tasks-ddl'" [isDropDownV2]="true" [ariaLabel]="'Tasks'"
                    [(value)]="selectedTask" [options]="selectedService.children" [labels]="'displayName'"
                    [isDisabled]="isDisabled(selectedService)" (change)="onSelectedTaskChange(selectedTask)">
                    <option [value]="placeholder">All</option>
                </dm-dropdown-menu>
            </section>
        </section>
    </section>
</section>