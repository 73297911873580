import { Component, Input, Inject, forwardRef, OnDestroy, SimpleChanges, OnChanges, Injector } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Components, TooltipText, SourceConstants, LogEventConstants, NoDataText, LogEventName } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { IEngagementDetailsV2, ITeamDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IState } from "../../../store/reducers";
import { IWbsEngagementModel } from "../../../common/services/contracts/wbs-engagement.contracts";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { WbsEngagementEditModalComponent } from "../../manage-wbs/tiles/wbs-engagement/wbs-engagement-edit/wbs-engagement-edit.component";
import { WbsEngagementStateModalComponent } from "../../manage-wbs/tiles/wbs-engagement/wbs-engagement-ebs-state/engagement-request-state-change-modal.component";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { TeamStructureModalComponent } from "../../shared/team-structure/team-structure.component";
import { ManageEbsService } from "../../../common/services/manage-ebs.service";
import { ITile } from "../../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../common/error.constants";

@Component({
    selector: "dm-wbs-internal-engagement",
    templateUrl: "./wbs-internal-engagement.html",
    styleUrls: ["./wbs-internal-engagement.scss"]
})
export class WbsInternalEngagementComponent extends DmComponentAbstract implements OnDestroy, OnChanges {
    @Input() public engagementId: string;
    public engagementData: IWbsEngagementModel;
    public errorText: string;
    public showLastUpdated: boolean;
    public tooltipText: string = TooltipText.EBSState;
    public engagementDetails: IEngagementDetailsV2;
    public tileContent: ITile;    
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.ManageInternalEngagement;
    public constructor(
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMAuthorizationService) private dmAuthorizationservice: DMAuthorizationService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(Injector) private injector: Injector,        
        @Inject(ManageEbsService) private manageEbsService: ManageEbsService
    ) {
        super(dmLogger, Components.ManageWBSInternalEngagement);
    }
    public ngOnInit(): void {
        this.configurationService.initialize()
            .then(() => {
                this.showLastUpdated = this.configurationService.isFeatureEnabled("ShowLastUpdatedColumn");
                this.errorText = NoDataText.NoEBSDataText;
            });
        this.tileContent = {
            title: "Manage Internal Engagements"
        };
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (propName === "engagementId") { /* Awaiting engagementId to become available since it is passed in instead of derrived here. */
                this.callStoreForEngagementDetails();
            }
        }
    }

    /**
     * Converts simple words to have an S at the end if the counter has a value of 0 or greater than 1.
     * @param word
     * @param count
     */
    public getWordPluralWithS(word: string, count: number): string {
        return this.sharedFunctionsService.getWordPluralWithS(word, count, false);
    }

    /**
     * Creates a comma-separated list from a given array.
     * If the array is empty or undefined, then an empty string is returned.
     * If only one item exists, it will be printed. If two items exist, they will be printed with the word "and" in between.
     * If 3 or mote items exist, they will be properly formatted into a string.
     * The attribute param can index into lists of objects and create a list with the given attribute.
     *
     * @param {any[]} arr
     * @param {string} attribute
     * @returns {string}
     */
    public createCommaSeparatedString(arr: ITeamDetailsV2[], attribute: string): string {
        return this.sharedFunctionsService.createCommaSeparatedString(arr, true, attribute);
    }

    /**
     * Opens the Request for EBS State Change modal (in a seperate component)
     */
    public openRequestModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(WbsEngagementStateModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal ebs-state-engagement",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.selectedEngagement = this.engagementDetails;
    }

    /**
     * Opens the Edit Engagement modal (in a seperate component)
     */
    public openEditModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.OpenEditModal, LogEventConstants.ManageWBSEngagementEditLink);
        const modalRef: NgbModalRef = this.modalService.open(WbsEngagementEditModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal edit-engagement",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.selectedEngagement = this.engagementDetails;
    }

    /**
     *  Opens team structure modal
     */
    public openTeamStructureModal(): void {
        this.dmLogger.logEvent(SourceConstants.Component.TeamStructureModal, SourceConstants.Method.OpenTeamStructureModal, LogEventName.TeamStructureIconClick);
        const modalRef: NgbModalRef = this.modalService.open(TeamStructureModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal in active team-structure-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.teamContacts = this.manageEbsService.getTeamContacts(this.engagementDetails);
    }

    /**
     * Calls the store to get engagement information to run the component.
     */
    private callStoreForEngagementDetails(): void {
        if (!this.engagementId) {
            return;
        }
        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));
        engagementDetails$.pipe(untilDestroyed(this))
            .subscribe((engagementDetails: IEngagementDetailsState) => {
                if (engagementDetails.loaded) {
                    this.engagementDetails = engagementDetails.engagementDetails;
                    this.engagementDetails.isInternalEngagment = true;
                    this.engagementDetails.canEditEnagagement = this.dmAuthorizationservice.isUserInEngagementLevelTeam(this.engagementDetails);
                    this.engagementDetails.canRequestEbsStateChange = this.dmAuthorizationservice.isUserInEngagementLevelTeam(this.engagementDetails);
                }
                this.refreshOnItemInvalidation(engagementDetails);
                this.setLoadersBasedOnItemState(engagementDetails);
                this.setErrorsBasedOnItemState(engagementDetails);
                if (engagementDetails.error) {
                    this.isServerError = true;
                }
            });
    }
}