import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, NotificationEvent, TooltipText, WBSResponseMessages, AccessibilityConstants, NONFTE_REGEX } from "../../../../../common/application.constants";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../../common/abstraction/dm-modal.abstract";
import { DMNotificationService, NotificationModel } from "../../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { EnumUpdateLevel } from "../../../../../common/services/contracts/wbs-engagement.contracts";
import { IChangedProperties } from "../../../../../common/services/contracts/dmnotification.service.contract";
import { IEngagementDetailsV2, ITeamDetailsV2 } from "../../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IWbsEditEngagementDetailsV2, ITeamStructureForEditWBS, IDateValidationRequest, WbsLevel } from "../../../../../common/services/contracts/wbs.service.contracts";
import { IScheduledDates, IResourceRequestResponse, IProjectRequest } from "../../../../../common/services/contracts/staffing.service.contract";
import { ISelectedUserAttributes } from "../../../../tiles/type-ahead/type-ahead-contracts";
import { IStaffingTaskModel } from "../../../../../common/services/contracts/staffing-details.contract";
import { MyPortfolioService } from "../../../../../common/services/my-portfolio.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { StaffingService, IDemandSourceObject } from "../../../../../common/services/staffing.service";
import { WBSService } from "../../../../../common/services/wbs.service";
import moment from "moment";
import { DmNotificationService } from "../../../../../common/services/dm-notification.service";
import { NotificationType } from "../../../../../common/services/contracts/notification-bar.contracts";
import { v4 as uuid } from "uuid";
import { ProjectServiceFunctions } from "../../../../../common/services/projectservice-functions.service";
import { DmError } from "../../../../../common/error.constants";
import { AuditService } from "../../../../../common/services/audit.service";
import { IWbsAuditPayload, WbsAuditType } from "../../../../../common/services/contracts/audit.contracts";

@Component({
    selector: "dm-wbs-edit-engagement",
    templateUrl: "./wbs-engagement-edit.html",
    styleUrls: ["./wbs-engagement-edit.scss"]
})
export class WbsEngagementEditModalComponent extends DmModalAbstract {
    @Input() public selectedEngagement: IEngagementDetailsV2;
    public updatedEngagement: IEngagementDetailsV2; // engagement to be updated
    public isStartDatePopup: boolean = false;
    public isEndDatePopup: boolean = false;
    public isPMRequired: boolean = false;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public showEndDateGreaterThanStartDateMessage: boolean = false;
    public typeAheadId: string;
    public taSearchAriaLblTxt: string;
    public typeAheadLabelText: string;
    public taCancelAriaLblTxt: string;
    public taRequiredMsg: string;
    public isAfterChildProjectStartDate: boolean = false;
    public isBeforeChildProjectEndDate: boolean = false;
    public selectedUser: ISelectedUserAttributes;
    public additionalTypeAheadId: string;
    public additionalSearchAriaLblTxt: string;
    public additionalTypeAheadLabelText: string;
    public additionalCancelAriaLblTxt: string;
    public multiSelectedUsers: ISelectedUserAttributes[];
    public selectedUsers: ISelectedUserAttributes[];
    public disablePPJMUpdate: boolean = false;
    public disableAPPJMUpdate: boolean = false;
    public showActualsStartDateConflictMessage: boolean = false;
    public showActualsEndDateConflictMessage: boolean = false;
    public loadingText: string;
    public updateLevel: EnumUpdateLevel;
    public showCascadeRadioButtons: boolean;
    public disableEngagementOnlyOption: boolean;
    public disableEBSStartDateEndDateUpdates: boolean = false;
    public viewResourceEnable: boolean = false;
    public isDisabledPubSecCode: boolean = false;
    public pubSecCountry: string;
    public showConflictingResources: boolean;
    public noOfConflictResources: number;
    public EnumUpdateLevel: typeof EnumUpdateLevel = EnumUpdateLevel;
    public filteredResourceView: IStaffingTaskModel[] = [];
    public ebsTooltipText: string = TooltipText.EBSState;
    public minDate: Date;
    public maxStartDate: Date;
    public minStartDate: Date;
    public minEndDate: Date;
    public maxEndDate: Date;
    public isUpdateActive: boolean = false;
    public isResourceRequestsLoading: boolean;
    public accessibilityConstants = AccessibilityConstants;
    public editEngagementErrorMessages = DmError.EbsStructure;
    public descriptionRequired: string = "Description";
    public showNonfteValidationMessage: boolean;
    private conflictResourceRequestStatus: string[];

    private serviceResponseMessages: IResponseMessage;
    private notificationMessage: {
        EngagementNotification: string;
        ProjectNotification: string;
        TaskAddedNotification: string;
        SuccessSaveGoodRecieptNotification: string;
        ErrorSaveGoodRecieptNotification: string;
    };
    private isModelValid: boolean = false;
    private tempStartDate: Date;
    private tempEndDate: Date;
    private originalAdditionalPPJMBpIds: number[];
    private updatedAdditionalPPJMBpIds: number[];
    private APPJMsChanged: boolean = false; // can probably be moved inside the func
    private originalAPPJMNames: string[] = [];
    private addedAPPJMNames: string[] = [];
    private deletedAPPJMNames: string[] = [];
    private disableDateChangeForPubSecCodes: object;
    private readonly FXP_CONSTANTS = FxpConstants;
    private resourceRequests: IProjectRequest[] = [];
    private isEngagementMinDateValidationEnabled: boolean;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsEngService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(DmDisplayDateOrDashPipe) private dmDisplayDateOrDashPipe: DmDisplayDateOrDashPipe,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(AuditService) private auditService: AuditService
    ) {
        super(activeModal, dmLogger, Components.ManageWBSEditEngagement);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.configurationService.initialize().then(() => {
            this.serviceResponseMessages = WBSResponseMessages.Engagement;
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
            this.disableEBSStartDateEndDateUpdates = this.configurationService.getValue<boolean>("disableEBSStartDateEndDateUpdates");
            this.disableDateChangeForPubSecCodes = this.configurationService.getValue<object>("disableDateChangeForPubSecCodes");
            this.conflictResourceRequestStatus = this.configurationService.getValue<string[]>("conflictResourceStatus");
            this.isEngagementMinDateValidationEnabled = this.configurationService.isFeatureEnabled("enableEngagementMinDateValidation");
           
            /* Disabling ability to edit start and end dates based on the engagement's pubsec country code. */
            if (Object.keys(this.disableDateChangeForPubSecCodes).indexOf(this.selectedEngagement.publicSectorCode) > -1) {
                this.pubSecCountry = this.disableDateChangeForPubSecCodes[this.selectedEngagement.publicSectorCode];
                this.isDisabledPubSecCode = true;
                this.disableEBSStartDateEndDateUpdates = true;
                this.isResourceRequestsLoading = false;
            }

            this.updatedEngagement = { ...this.selectedEngagement };
            this.isComponentLoading = false;
            this.setTypeAheadText();

            // Domain PPJM (single user)
            if (this.updatedEngagement.pPjm) {
                this.selectedUser = {
                    userAlias: this.updatedEngagement.pPjm.alias,
                    userName: this.updatedEngagement.pPjm.name,
                    bpId: this.updatedEngagement.pPjm.bpid,
                    emailName: "",
                    firstName: "",
                    lastName: "",
                    fullName: this.updatedEngagement.pPjm.alias
                };
            }

            // Additional PPJM (multiple users)
            this.selectedUsers = [];
            this.originalAdditionalPPJMBpIds = [];
            const adPpjmList = this.updatedEngagement.adPPjm;
            if (adPpjmList && adPpjmList.length) {
                for (const singleAdPpjm of adPpjmList) {
                    if (this.originalAdditionalPPJMBpIds.indexOf(Number(singleAdPpjm.bpid)) < 0) {
                        this.originalAdditionalPPJMBpIds.push(Number(singleAdPpjm.bpid));
                        this.originalAPPJMNames.push(singleAdPpjm.name);
                    }
                    // Create new object for the user in order to match object types used in typeahead component
                    const newAPPJM: ISelectedUserAttributes = {
                        userAlias: singleAdPpjm.alias,
                        userName: singleAdPpjm.name,
                        bpId: singleAdPpjm.bpid,
                        emailName: "",
                        firstName: "",
                        lastName: "",
                        fullName: singleAdPpjm.name
                    };
                    this.selectedUsers.push(newAPPJM);
                }
            }
            this.multiSelectedUsers = this.selectedUsers;
            this.setDisableManagerChange();

            this.showCascadeRadioButtons = false;
            this.showConflictingResources = false;
            this.noOfConflictResources = 0;
            this.updateLevel = EnumUpdateLevel.engagementLevel;
            /* Used for the datepicker to limit the dates that can be selected */
            if (this.selectedEngagement.isInternalEngagment) {
                this.minStartDate = this.selectedEngagement.startDate ? this.getMinDate() : undefined;
            } else if (this.isEngagementMinDateValidationEnabled) {
                this.minStartDate = this.selectedEngagement.signatureDate;
            }

            // TODO -- Get these resource request details from store
            const engagementDetails = this.selectedEngagement;
            if (!this.disableEBSStartDateEndDateUpdates && !this.isDisabledPubSecCode) {
                this.disableEBSStartDateEndDateUpdates = true;
                this.isResourceRequestsLoading = true;
                const demandSourceObjects: IDemandSourceObject[] = [];
                if (engagementDetails && engagementDetails.projects) {
                    for (const project of this.selectedEngagement.projects) {
                        const demandSourceObject: IDemandSourceObject = {
                            projectId: project.id,
                            compassOnePackageId: project.compassOnePackageId
                        };
                        demandSourceObjects.push(demandSourceObject);
                    }

                    this.staffingService.getGRMRequestsProjectIdV2(demandSourceObjects).then((resRequests) => {
                        this.resourceRequests = resRequests.ProjectRequests;
                        this.getResourceConflictStartEndDates(resRequests).then(() => {
                            /* We call this API but don't actually do anything with the response */
                            this.isResourceRequestsLoading = false;
                            this.disableEBSStartDateEndDateUpdates = false;
                        });
                    }).catch((error) => {
                        const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                        this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        this.isResourceRequestsLoading = false;
                    });
                }
            }
            this.endComponentLoad();
        });
    }

    /**
     * Updates the selected user list with a callback from the typeahead.
     * @param users
     */
    public onAdditionalManagersUpdate(users: ISelectedUserAttributes[]): void {
        this.selectedUsers = users;
    }

    /**
     * Creates the message that the engagement start date must be earlier than or the same as the project
     * start date. Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeEarlierThanProjectMessage(): string {
        return DmError.EbsStructure.EngagementStartDateMustBeEarlierOrSameAsProject + ` (${this.dmDisplayDateOrDashPipe.transform(this.updatedEngagement.childEntitiesMinStartDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeDateAtProjectlevel;
    }

    /**
     * Creates the message that the engagement end date cannot be earlier than or the same as the Project
     * end date. Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateCannotBeEarlierThanProjectMessage(): string {
        return DmError.EbsStructure.EngagementEndDateCannotBeEarlierOrSameAsProject + ` (${this.dmDisplayDateOrDashPipe.transform(this.updatedEngagement.childEntitiesMaxEndDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeDateAtProjectlevel;
    }

    /**
     * Selected User from typeahead
     *
     * @param {ISelectedUserAttributes} selectedUser
     * @memberof WbsEngagementEditModalComponent
     */
    public onSelectUser(selectedUser: ISelectedUserAttributes): void {
        this.selectedUser = selectedUser;
    }

    /**
     * Disable save button on user clear
     *
     * @param {boolean} isClearPjM
     * @memberof WbsEngagementEditModalComponent
     */
    public onUserCleared(isClearPjM: boolean): void {
        this.selectedUser = undefined;
        this.saveButtonDisabled(isClearPjM);
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalid: boolean): boolean {
        // TODO: should be removed later as this needs to reactive form and these boolean flags should be not be used for validations.
        if (NONFTE_REGEX.test(this.selectedUser && this.selectedUser.userAlias)) {
            this.showNonfteValidationMessage = true;
            return true;
        }
        this.showNonfteValidationMessage = false;
        const isNamePristine = this.updatedEngagement.name === this.selectedEngagement.name;
        const isDescriptionPristine = this.updatedEngagement.description === this.selectedEngagement.description;
        const isStartDatePristine = moment(this.selectedEngagement.startDate).isSame(this.updatedEngagement.startDate);
        const isEndDatePristine = moment(this.selectedEngagement.endDate).isSame(this.updatedEngagement.endDate);

        let aliasIsMissing: boolean = true;
        let PPJMPristine: boolean = true;
        if (this.selectedUser) {
            PPJMPristine = Number(this.selectedUser.bpId) === Number(this.updatedEngagement.pPjm.bpid);
            aliasIsMissing = !this.selectedUser.userAlias;
        }
        let APPJMPristine: boolean = true;
        this.updatedAdditionalPPJMBpIds = [];
        for (const singleAPPJM of this.selectedUsers) {
            if (this.updatedAdditionalPPJMBpIds.indexOf((Number(singleAPPJM.bpId))) < 0) {
                this.updatedAdditionalPPJMBpIds.push(Number(singleAPPJM.bpId));
            }
        }
        if (this.originalAdditionalPPJMBpIds.sort().join(",") !== this.updatedAdditionalPPJMBpIds.sort().join(",")) {
            APPJMPristine = false;
        }

        const dates: boolean = this.isStartDateRequired || this.isEndDateRequired || this.showEndDateGreaterThanStartDateMessage;
        /* If we are showing the option to cascade, then it does not matter if the dates are before/after the child start/end dates.
        But if we are not showing the option to cascade, then these dates should block the ability to save. */

        const aqrDateValidations: boolean = this.showActualsStartDateConflictMessage || this.showConflictingResources || this.showActualsEndDateConflictMessage;


        const isFormPristineNew: boolean = isNamePristine && isDescriptionPristine && isStartDatePristine && isEndDatePristine && APPJMPristine && PPJMPristine;

        return formInvalid || dates || isFormPristineNew || aliasIsMissing || aqrDateValidations || this.isUpdateActive || (this.updatedEngagement.name && this.updatedEngagement.name.length < 3 && !isNamePristine);
    }

    /**
     * Updates the Engagement Details by calling the API, sending any notifications, etc.
     */
    public updateEngagementDetails(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.UpdateEngagementDetails, LogEventConstants.ManageWBSEngagementEditSaveClick);
        if (!this.selectedUser) {
            return;
        }

        this.loadingText = "Updating Engagement Details";
        this.isModelValid = true;
        this.isUpdateActive = true;
        this.tempStartDate = this.updatedEngagement.startDate;
        this.tempEndDate = this.updatedEngagement.endDate;
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        const modifiedPJMList: ITeamStructureForEditWBS[] = [];
        // Add newly selected additional managers
        for (const singleAPPJM of this.selectedUsers) {
            if (singleAPPJM.bpId && this.originalAdditionalPPJMBpIds.indexOf(Number(singleAPPJM.bpId)) < 0) {
                modifiedPJMList.push({
                    role: "ADPPJM",
                    bpId: singleAPPJM.bpId,
                    isDelete: false // New APJM added, set isDelete to False
                });
                this.addedAPPJMNames.push(singleAPPJM.userName);
                this.APPJMsChanged = true;
            }
        }

        // Delete removed additional managers
        for (const origBpId of this.originalAdditionalPPJMBpIds) {
            if (this.updatedAdditionalPPJMBpIds.indexOf(origBpId) < 0) {
                modifiedPJMList.push({
                    role: "ADPPJM",
                    bpId: origBpId.toString(),
                    isDelete: true // An original APJM is no longer selected, set isDelete to True
                });
                if (this.updatedEngagement.adPPjm) {
                    const appjm = this.updatedEngagement.adPPjm.filter((x: ITeamDetailsV2) => Number(x.bpid) === origBpId)[0];
                    if (appjm) {
                        this.deletedAPPJMNames.push(appjm.name);
                        this.APPJMsChanged = true;
                    }
                }
            }
        }

        const keysToCheck: { name: string; statusCode: string; pPjm: string; description: string } = {
            name: "name",
            statusCode: "statusCode",
            pPjm: "pPjMbpId",
            description: "description"
        };

        const editEngagementDataRequest: IWbsEditEngagementDetailsV2 = {};
        let editEngagementHasProperty = false;
        let editEngagementHasDate: boolean = false;
        const keysConfirmedChanged: string[] = [];

        for (const key in keysToCheck) {
            if (key === "pPjm") {
                if (this.selectedEngagement[key].bpid !== this.selectedUser.bpId) {
                    modifiedPJMList.push({
                        role: "PPJM",
                        bpId: this.selectedUser.bpId
                    });
                    keysConfirmedChanged.push(key);
                    editEngagementHasProperty = true;
                }
            }
            if (this.selectedEngagement[key] !== this.updatedEngagement[key]) {
                editEngagementDataRequest[keysToCheck[key]] = this.updatedEngagement[key];
                keysConfirmedChanged.push(key);
                editEngagementHasProperty = true;
            }
        }

        const dateKeysToCheck: { startDate: string; endDate: string } = {
            startDate: "startDate",
            endDate: "endDate"
        };

        for (const key in dateKeysToCheck) {
            if (!moment(this.selectedEngagement[key] as Date).isSame(this.updatedEngagement[key] as Date, "day")) {
                editEngagementDataRequest[dateKeysToCheck[key]] = moment(this.updatedEngagement[key] as Date).format("YYYY-MM-DD");
                editEngagementDataRequest.shouldCascadeUpdate = this.updateLevel !== EnumUpdateLevel.engagementLevel;
                keysConfirmedChanged.push(key);
                editEngagementHasDate = true;
            }
        }

        /* Cannot use the keysToCheck array for additional managers due to inability to check array equality */
        if (this.APPJMsChanged) {
            editEngagementHasProperty = true;
            keysConfirmedChanged.push("Additional Primary Project Manager(s)");
        }
        if (modifiedPJMList.length > 0) {
            editEngagementDataRequest.teamStructure = [];
            editEngagementDataRequest.teamStructure = modifiedPJMList;
        }

        // Sends a request if any field has been modified
        // Run disconnected date change process for customer engagements where date is updated
        if (editEngagementHasDate && !this.selectedEngagement.isInternalEngagment) {
            this.isComponentLoading = true;
            const dateValidationRequest: IDateValidationRequest = {
                startDate: editEngagementDataRequest.startDate ? editEngagementDataRequest.startDate : moment(this.updatedEngagement.startDate).format("YYYY-MM-DD"),
                endDate: editEngagementDataRequest.endDate ? editEngagementDataRequest.endDate : moment(this.updatedEngagement.endDate).format("YYYY-MM-DD"),
                cascadeDown: editEngagementDataRequest.shouldCascadeUpdate
            };

            this.wbsEngService.validateWbsDates(dateValidationRequest, this.selectedEngagement.id).then((response: any) => {
                if (response.status === 200) {
                    // we are good to create notification and call orchestrator
                    const orchestrationId: string = uuid();
                    editEngagementDataRequest.startDate = dateValidationRequest.startDate;
                    editEngagementDataRequest.endDate = dateValidationRequest.endDate;

                    this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.DateChange, loggedInUserData.BusinessPartnerId, this.updatedEngagement.id, orchestrationId).then(() => {
                        this.projectServiceFunction.orchestrateDateChange(editEngagementDataRequest, this.updatedEngagement.id, this.updatedEngagement.name, orchestrationId, WbsLevel.Engagement, loggedInUserData.alias).then(() => {
                            this.fxpMessageService.addMessage("Date Change has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                            // Displays the new notification in the notification bar
                            this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.updatedEngagement.id, orchestrationId, NotificationType.DateChange);
                            this.isComponentLoading = false;
                            this.closeModal();
                        });
                    });
                }
            }).catch((error) => {
                // add error message to failure message?
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.updatedEngagement.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.UpdateEngagementDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
                this.isUpdateActive = false;
            });
        } else if ((editEngagementHasProperty && !editEngagementHasDate) || (editEngagementHasDate && this.selectedEngagement.isInternalEngagment)) {
            this.isComponentLoading = true;
            this.wbsEngService.updateEngagementDetailsV2(editEngagementDataRequest, this.selectedEngagement.id)
                .then((response: any) => {
                    if ((editEngagementDataRequest.teamStructure && editEngagementDataRequest.teamStructure.length) || editEngagementDataRequest.shouldCascadeUpdate) {
                        /* only refresh if there is a change to pjms, or cascade date change */
                        this.engagementDetailService.invalidateStoreOnRefresh(this.updatedEngagement.id);
                        this.myPortfolioService.refreshMyPortfolioEngagementList();
                    }
                    this.logEngagementUpdateToAudit();
                    const changedProperties: IChangedProperties[] = [];
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Engagement Name", this.selectedEngagement.name, this.updatedEngagement.name));
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Delivery State", this.selectedEngagement.statusCode, this.updatedEngagement.statusCode));
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Start Date", moment(this.selectedEngagement.startDate).format("YYYY-MM-DD"), moment(this.updatedEngagement.startDate).format("YYYY-MM-DD")));
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("End Date", moment(this.selectedEngagement.endDate).format("YYYY-MM-DD"), moment(this.updatedEngagement.endDate).format("YYYY-MM-DD")));
                    if (editEngagementDataRequest.teamStructure && editEngagementDataRequest.teamStructure.length > 0 && editEngagementDataRequest.teamStructure.filter((team) => team.role === "PPJM").length > 0) {
                        this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Primary Domain Project Manager", this.selectedEngagement.pPjm.name, this.selectedUser.userName));
                    }

                    const oldDescription = this.selectedEngagement.description ? this.selectedEngagement.description : "No previous description provided";
                    this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Engagement Description", oldDescription, this.updatedEngagement.description));

                    if (editEngagementDataRequest.teamStructure && editEngagementDataRequest.teamStructure.length > 0 && editEngagementDataRequest.teamStructure.filter((team) => team.role === "ADPPJM").length > 0) {
                        let updateMessage: string = "";
                        let origMessage: string = "";
                        for (const name of this.originalAPPJMNames) {
                            origMessage += name + ", ";
                            if (this.deletedAPPJMNames.indexOf(name) < 0) {
                                updateMessage += name + ", ";
                            }
                        }
                        for (const name of this.addedAPPJMNames) {
                            updateMessage += name + ", ";
                        }
                        updateMessage = !updateMessage ? "No Additional Primary Domain Project Managers" : updateMessage.slice(0, -2);
                        origMessage = !origMessage ? "No previous Additional Primary Domain Project Managers" : origMessage.slice(0, -2);
                        this.pushToArrayIfTrue(changedProperties, this.createChangedPropertyObject("Additional Primary Domain Project Managers", origMessage, updateMessage));
                    }

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotificationsUpdateEBS(editEngagementDataRequest)) {
                        this.createLogAndSendNotification(changedProperties);
                    }
                    if (response && response.status && response.status === 206) {
                        let partialSucessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                        partialSucessMessage = partialSucessMessage.replace("#", this.updatedEngagement.name);
                        this.fxpMessageService.addMessage(partialSucessMessage, this.FXP_CONSTANTS.messageType.warning);
                    } else {
                        let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                        successMessage = successMessage.replace("#", this.updatedEngagement.name);
                        let updatedDetails: string = "";
                        for (let i = 0; i < keysConfirmedChanged.length; i++) {
                            updatedDetails += keysConfirmedChanged[i];
                            if (i !== keysConfirmedChanged.length - 1) {
                                updatedDetails += ", ";
                            }
                        }
                        successMessage = successMessage.replace("*", updatedDetails); // CodeQL [SM02383] Need to replace the single occurrence of *.
                        this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                    }
                    this.closeModal();
                })
                .catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.updatedEngagement.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.UpdateEngagementDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isComponentLoading = false;
                    this.isUpdateActive = false;
                });
        } else {
            this.closeModal();
        }
    }

    /**
     * Toggles if the view resource is enabled
     */
    public toggleResources(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.ToggleResources, this.viewResourceEnable ? LogEventConstants.EBSEditToggleResourceCollapse : LogEventConstants.EBSEditToggleResourceExpand);
        this.viewResourceEnable = !this.viewResourceEnable;
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     * @memberof WbsEngagementEditModalComponent
     */
    public onStartDateChange(startDate: Date): void {
        this.updatedEngagement.startDate = startDate;
        this.showConflictingResources = false;
        this.isEntityStartDateValid();
    }

    /**
     * Checks validity of the start date by checking with GRM API
     */
    public isEntityStartDateValid(): boolean {
        if (this.isModelValid) {
            // todo can we get rid of tempStartDate & tempEndDate ?
            this.updatedEngagement.startDate = this.tempStartDate;
            this.updatedEngagement.endDate = this.tempEndDate;
            return true;
        }
        if (this.maxStartDate && (moment(this.updatedEngagement.startDate) > moment(this.maxStartDate))) {
            return false;
        }
        this.updateLevel = EnumUpdateLevel.engagementLevel;
        this.isStartDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableEngagementOnlyOption = false;
        if (!this.updatedEngagement.startDate) {
            this.isStartDateRequired = true;
            return false;
        }
        this.showEndDateGreaterThanStartDateMessage = false;
        if (this.updatedEngagement.startDate && this.updatedEngagement.endDate && moment(this.updatedEngagement.endDate).isBefore(this.updatedEngagement.startDate)) {
            this.showEndDateGreaterThanStartDateMessage = true;
            return false;
        }

        if (!this.validateLowerLevelConflicts(this.updatedEngagement.startDate, this.updatedEngagement.endDate)) {
            return false;
        }

        if (!this.areDatesValid(this.updatedEngagement.startDate, this.updatedEngagement.endDate)) {
            return false;
        }
        let returnValue: boolean = true;
        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.updatedEngagement.startDate, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.updatedEngagement.endDate) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.updatedEngagement.endDate, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }

        this.showActualsStartDateConflictMessage = false;

        if (this.updatedEngagement.minDate && moment(this.updatedEngagement.startDate).isAfter(this.updatedEngagement.minDate)) {
            this.showActualsStartDateConflictMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return false;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedEngagement.startDate, this.updatedEngagement.startDate) || this.haveDatesChanged(this.selectedEngagement.endDate, this.updatedEngagement.endDate);
        return true;
    }

    /**
     * Capturing emitted end date value
     *
     * @param {Date} endDate
     * @memberof WbsEngagementEditModalComponent
     */
    public onEndDateChange(endDate: Date): void {
        this.updatedEngagement.endDate = endDate;
        this.showConflictingResources = false;
        this.isEntityEndDateValid();
    }

    /**
     * Checks validity of the end date by checking with GRM API
     */
    public isEntityEndDateValid(): boolean {
        if (this.isModelValid) {
            this.updatedEngagement.startDate = this.tempStartDate;
            this.updatedEngagement.endDate = this.tempEndDate;
            return true;
        }
        if (this.minEndDate && (moment(this.updatedEngagement.endDate) < moment(this.minEndDate))) {
            return false;
        }
        this.updateLevel = EnumUpdateLevel.engagementLevel;
        this.isEndDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableEngagementOnlyOption = false;
        if (!this.updatedEngagement.endDate) {
            this.isEndDateRequired = true;
            return false;
        }

        this.showEndDateGreaterThanStartDateMessage = false;
        if (this.updatedEngagement.startDate && this.updatedEngagement.endDate && moment(this.updatedEngagement.endDate).isBefore(this.updatedEngagement.startDate)) {
            this.showEndDateGreaterThanStartDateMessage = true;
            return false;
        }
        if (!this.validateLowerLevelConflicts(this.updatedEngagement.startDate, this.updatedEngagement.endDate)) {
            return false;
        }

        if (!this.areDatesValid(this.updatedEngagement.startDate, this.updatedEngagement.endDate)) {
            return false;
        }
        let returnValue = true;

        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.updatedEngagement.endDate, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.updatedEngagement.startDate) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.updatedEngagement.startDate, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }

        this.showActualsEndDateConflictMessage = false;
        // TODO - Get enddate from staffing
        if (this.updatedEngagement.maxDate && moment(this.updatedEngagement.endDate).isBefore(this.updatedEngagement.maxDate)) {
            this.showActualsEndDateConflictMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return false;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedEngagement.startDate, this.updatedEngagement.startDate) || this.haveDatesChanged(this.selectedEngagement.endDate, this.updatedEngagement.endDate);
        return true;

    }

    /**
     * Hides the error messages related to updating different date levels.
     */
    public hideErrorMessages(): void {
        if (this.updateLevel === EnumUpdateLevel.allLowerLevels) {
            this.isBeforeChildProjectEndDate = false;
            this.isAfterChildProjectStartDate = false;
        }
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Gets the resource conflict start and end dates from staffing data.
     *
     * @param {IResourceRequestResponse} grmStaffingResponse
     * @returns {Promise<IScheduledDates>}
     * @memberof WbsEngagementEditModalComponent
     */
    private getResourceConflictStartEndDates(grmStaffingResponse: IResourceRequestResponse): Promise<IScheduledDates> {
        const scheduledStartDates = [];
        const scheduledEndDates = [];

        if (grmStaffingResponse && grmStaffingResponse.ProjectRequests && grmStaffingResponse.ProjectRequests.length > 0) {
            for (const projectRequest of grmStaffingResponse.ProjectRequests) {
                if (projectRequest.ResourceRequests && projectRequest.ResourceRequests.length > 0) {
                    const filteredResourceRequests = projectRequest.ResourceRequests.filter((resRequests) =>
                        this.conflictResourceRequestStatus.indexOf(resRequests.ResourceRequestStatusEnum) >= 0);
                    if (filteredResourceRequests.length > 0) {
                        this.noOfConflictResources = filteredResourceRequests.length;
                        for (const request of filteredResourceRequests) {
                            scheduledStartDates.push(moment(request.ScheduledStartDate));
                            scheduledEndDates.push(moment(request.ScheduledEndDate));
                        }
                    }
                }
            }
        }
        if (scheduledStartDates.length && scheduledEndDates.length) {
            const scheduledDates = {
                scheduledStartDate: moment.min(scheduledStartDates).toDate(),
                scheduledEndDate: moment.max(scheduledEndDates).toDate()
            };
            return Promise.resolve(scheduledDates);
        } else {
            return Promise.resolve(undefined);
        }
    }

    /**
     * Sets the disabled flag for editing the manager and additional manager fields based on
     * the logged in user and the users within the team.
     */
    private setDisableManagerChange(): void {
        const currentUser: number = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        const pPjmBpid = (this.updatedEngagement && this.updatedEngagement.pPjm && this.updatedEngagement.pPjm.bpid) ? Number(this.updatedEngagement.pPjm.bpid) : undefined;
        const delPPjmBpid = (this.updatedEngagement && this.updatedEngagement.delPPjm && this.updatedEngagement.delPPjm.bpid) ? Number(this.updatedEngagement.delPPjm.bpid) : undefined;
        const dmmBpid = (this.updatedEngagement && this.updatedEngagement.dmmPPjm && this.updatedEngagement.dmmPPjm.bpid) ? Number(this.updatedEngagement.dmmPPjm.bpid) : undefined;
       
        /* If Current user is a PPJM, then user can update the PPJM, APPJM values else disable update */
        if (pPjmBpid && currentUser === pPjmBpid) {
            this.disableAPPJMUpdate = false;
            this.disablePPJMUpdate = false;
        } else if (delPPjmBpid && currentUser === delPPjmBpid) {
            /* If Current user is a DelPPJM, then user can update the APPJM values */
            this.disablePPJMUpdate = true;
            this.disableAPPJMUpdate = false;
        } else if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.indexOf(currentUser) > -1) {
            /* If Current user is an APPJM, then user can update the APPJM values but not the PPJM */
            this.disablePPJMUpdate = true;
            this.disableAPPJMUpdate = false;
        } else if (dmmBpid && currentUser === dmmBpid) {
            /* If Current user is a DMM, then user can update the PPJM, APPJM values*/
            this.disablePPJMUpdate = false;
            this.disableAPPJMUpdate = false;
        } else {
            /* If Current user is not an PPJM or APPJM or delegated PPJM, then disable update is set to true */
            this.disableAPPJMUpdate = true;
            this.disablePPJMUpdate = true;
        }
    }

    /**
     * Checks the validity of the given start and end dates in relation to each other and lower level conflicts.
     * Returns false if the dates are not valid, true if they otherwise are valid.
     * @param startDate
     * @param endDate
     */
    private areDatesValid(startDate: Date, endDate: Date): boolean {
        return !moment(this.updatedEngagement.endDate).isBefore(this.updatedEngagement.startDate)
            && this.validateLowerLevelConflicts(startDate, endDate);
    }

    /**
     * If the given objects are different, creates and returns a changed property object.
     * Returns undefined if the objects have not changed.
     * @param attributeName
     * @param originalValue
     * @param updatedValue
     */
    private createChangedPropertyObject(attributeName: string, originalValue: string, updatedValue: string): IChangedProperties {
        if (originalValue !== updatedValue) {
            return {
                name: attributeName,
                oldValue: originalValue,
                newValue: updatedValue
            };
        }
        return undefined;
    }

    /**
     * Creates a log string from the given changed properties: combines all the changed attribute names into a single, comma seperated string.
     * @param changedProperties
     */
    private createLogStringFromChangedProperties(changedProperties: IChangedProperties[]): string {
        let logString: string = "";
        changedProperties.forEach((property, index) => {
            logString += property.name;
            if (index !== changedProperties.length - 1) {
                logString += ",";
            }
        });
        return logString;
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageWBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.ManageWBSEngagementEdit, propertyBag);

        const notification = new NotificationModel();
        notification.engagementId = this.updatedEngagement.id;
        notification.engagementName = this.updatedEngagement.name;
        notification.eventName = NotificationEvent.EngagementUpdated;
        let sendTo: string[];
        if (this.APPJMsChanged) { // Notify PJM, PPJM, Additional PJM, and PPJM of change
            const listOfPJM: string[] = this.sharedFunctionsService.getListofPjmV2(this.updatedEngagement);
            const listOfAPJM: string[] = this.sharedFunctionsService.getListofAdPjmV2(this.updatedEngagement);
            listOfPJM.forEach((pjm: string) => {
                const index = listOfAPJM.indexOf(pjm);
                if (index >= 0) {
                    listOfAPJM.splice(index, 1);
                }
            });
            sendTo = listOfPJM.concat(listOfAPJM)
                .concat(userAlias);

        } else { // Notify PJM and PPJM only of change
            sendTo = this.sharedFunctionsService.getListofPjmV2(this.updatedEngagement)
                .concat(userAlias);
        }
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;
        let esxpNotification = this.notificationMessage.EngagementNotification;
        esxpNotification = esxpNotification.replace("#", this.updatedEngagement.name);
        this.notificationService.sendNotification(notification, false, esxpNotification);
    }

    /**
     * Compares the given start and end dates. Returns true if the dates have changed.
     *
     * @param originalDate
     * @param newDate
     */
    private haveDatesChanged(originalDate: Date, newDate: Date): boolean {
        return !moment(originalDate).isSame(newDate);
    }

    /**
     * Validates any lower level conflicts by comparing dates. Returns true if valid, false if invalid
     * @param startDate
     * @param endDate
     */
    private validateLowerLevelConflicts(startDate: Date, endDate: Date): boolean {
        let isValid = true;
        this.isAfterChildProjectStartDate = false;
        this.isBeforeChildProjectEndDate = false;
        if (this.updateLevel === EnumUpdateLevel.engagementLevel) {
            if (this.updatedEngagement.childEntitiesMinStartDate && moment(startDate).isAfter(this.updatedEngagement.childEntitiesMinStartDate)) {
                this.isAfterChildProjectStartDate = true;
                isValid = false;
            }
            if (this.updatedEngagement.childEntitiesMaxEndDate && moment(endDate).isBefore(this.updatedEngagement.childEntitiesMaxEndDate)) {
                this.isBeforeChildProjectEndDate = true;
                isValid = false;
            }
        }
        if (this.isBeforeChildProjectEndDate || this.isAfterChildProjectStartDate) {
            /* If the cascade options are available and the user has an error with their dates, continue to show the error,
            but set the cascade option to all levels and allow them to save the engagement. Disable engagement only option so
            the user cannot ignore the error. */
            this.isBeforeChildProjectEndDate = false;
            this.isAfterChildProjectStartDate = false;
            this.showCascadeRadioButtons = true;
            this.disableEngagementOnlyOption = true;
            this.updateLevel = EnumUpdateLevel.allLowerLevels;
            isValid = true;
        }
        return isValid;
    }

    /**
     * Populates all the type ahead text values
     */
    private setTypeAheadText(): void {
        this.typeAheadId = "engTypeAheadForPrimaryDomainManager";
        this.taSearchAriaLblTxt = "Search manager by name or alias";
        this.additionalTypeAheadId = "engTypeAheadForAdditionalManager";
        if (this.selectedEngagement.isInternalEngagment) {
            this.typeAheadLabelText = "Project Manager";
            this.taCancelAriaLblTxt = "Remove Project Manager";
            this.taRequiredMsg = "Project Manager is required";
            this.additionalSearchAriaLblTxt = "Additional Project Manager - Optional";
            this.additionalTypeAheadLabelText = "Additional Project Manager - Optional";
            this.additionalCancelAriaLblTxt = "Remove Additional Project Manager name or alias";
        } else {
            this.typeAheadLabelText = "Primary Domain Project Manager";
            this.taCancelAriaLblTxt = "Remove Primary Domain Project Manager";
            this.taRequiredMsg = "Primary Domain Project Manager is required";
            this.additionalSearchAriaLblTxt = "Search Additional Primary Domain Project Manager - Optional";
            this.additionalTypeAheadLabelText = "Additional Primary Domain Project Manager - Optional";
            this.additionalCancelAriaLblTxt = "Remove Additional Primary Domain Project Manager name or alias";
        }
    }

    /**
     * Pushes the given value to the given array if the value evaluates as truthy.
     * (If the value is not null or undefined, false, or the number 0.)
     * Modifies the given array by reference, and does not need to return.
     */
    private pushToArrayIfTrue(array: IChangedProperties[], value: IChangedProperties): void {
        if (value) {
            array.push(value);
        }
    }

    /**
     * Determines earliest date user could choose for Engagement Start Date
     * User should be able to change Engagement Start Date to start date of current FY
     * If Engagement Start Date is earlier than current FY start date, then earliest date will remian as Engagement Start Date
     */
    private getMinDate(): Date {
        const currentDate = new Date();
        let currentFY: number = currentDate.getFullYear();

        if (currentDate.getMonth() < 7) {
            currentFY--;
        }

        const currentFYStartDate = new Date(currentFY, 6, 1); // setting July 1st as starting date of FY

        return this.selectedEngagement.startDate < currentFYStartDate ? this.selectedEngagement.startDate : currentFYStartDate;
    }

    /**
     * Logs Engagementdetails Update to Audit    
     */
    private logEngagementUpdateToAudit(): void {
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        if (this.selectedEngagement.name !== this.updatedEngagement.name) {            
            const logDetails: IWbsAuditPayload = {
                currentValue: this.updatedEngagement.name,
                previousValue: this.selectedEngagement.name,
                eventType: WbsAuditType.EngagementNameChange,
                createdByAlias: loggedInUserData.alias
            };
            this.auditService.postWbsAuditItem(this.updatedEngagement.id, logDetails);
        }
        if (this.selectedEngagement.description !== this.updatedEngagement.description) {            
            const logDetails: IWbsAuditPayload = {
                currentValue: this.updatedEngagement.description,
                previousValue: this.selectedEngagement.description,
                eventType: WbsAuditType.EngagementDescriptionChange,
                createdByAlias: loggedInUserData.alias
            };
            this.auditService.postWbsAuditItem(this.updatedEngagement.id, logDetails);
        }
    }
}
