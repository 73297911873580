import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { ProjectFilterV2Module } from "../../../tiles/project-filter-v2/project-filter-v2.module";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmUserProfileModule } from "../../../../components/shared/user-name/user-name.module";
import { EngagementBreakdownStructureComponent } from "./engagement-breakdown-structure.component";
import { UIRouterModule } from "@uirouter/angular";
import { AddTaskModalModule } from "../../modals/add-task/add-task.module";
import { EbsEditProjectDetailsModalModule } from "../../modals/wbs-project-edit/edit-project-details.module";
import { EbsEditServiceDetailsModalModule } from "../../modals/wbs-service-edit/edit-service-details.module";
import { EbsEditTaskDetailsModalModule } from "../../modals/wbs-task-edit/edit-task-details.module";
import { EditProjectTeamStructureModalModule } from "../../modals/edit-project-team-structure/edit-project-team-structure.module";

@NgModule({
    declarations: [
        EngagementBreakdownStructureComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        ProjectFilterV2Module,
        DmUserProfileModule,
        UIRouterModule,
        AddTaskModalModule,
        EbsEditProjectDetailsModalModule,
        EbsEditServiceDetailsModalModule,
        EbsEditTaskDetailsModalModule,
        EditProjectTeamStructureModalModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
    ],
    entryComponents: [
        EngagementBreakdownStructureComponent,
    ],
    exports: [
        EngagementBreakdownStructureComponent,
    ]
})
export class EngagementBreakdownStructureModule { }
