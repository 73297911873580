<section class="col-md-12 col-sm-12 col-xs-12">
    <section class="col-md-7 col-sm-7 col-xs-7 paginationSection dm-p-t-5">
        <button class="dm-transparent-btn  font-caption-alt" (click)="prevPage($event, true)"
            [disabled]="disableFirstPage()" [title]="titleForNavigateToFirstPage"  [attr.aria-label]="titleForNavigateToFirstPage">
            <span class="icon icon-chevronRewind icon-section dm-p-2"></span>&nbsp;
        </button>
        <button class="dm-transparent-btn  font-caption-alt" [disabled]="disableFirstPage()"
            (click)="prevPage($event, false)" [title]="titleForNavigateToPreviousPage" [attr.aria-label]="titleForNavigateToPreviousPage">
            <span class="icon icon-chevronLeft dm-p-2"></span>&nbsp;
        </button>
        <span class="font-caption-alt dm-p-8">
            Page
            <input type="number" min="1" max="numberOfPages()" pattern="[0-9]"
                (keypress)="isRequiredIntegerValue($event)" (change)="changePageOnKeyUpdown()"
                (keyup.enter)="changePageOnKeyUpdown()"
                class="paginationTxt text-center" [(ngModel)]="currentPageNumber" (blur)="pageNumberChange()"
                [attr.aria-label]="setArialLabelOnPageChange()" aria-live="polite"/>
            of {{numberOfPages()}}
        </span>
        <button class="dm-transparent-btn  font-caption-alt" [disabled]="disableLastPage()"
            (click)="nextPage($event, false)" [title]="titleForNavigateToNextPage" [attr.aria-label]="titleForNavigateToNextPage">
            &nbsp;
            <span class="icon icon-chevronRight dm-p-2"></span>
        </button>
        <button class="dm-transparent-btn  font-caption-alt" (click)="nextPage($event, true)"
            [disabled]="disableLastPage()" [title]="titleForNavigateToLastPage" [attr.aria-label]="titleForNavigateToLastPage">
            <span class="icon icon-chevronFastForward icon-section dm-p-4"></span>
        </button>&nbsp;
            <select (change)="itemSelectedPerPage($event)" [ngModel]="itemPerPageValue" [attr.aria-label]="ariaLabelForItemsPerPageBox" class="font-caption-alt item-section">
                <option *ngFor="let itemcount of itemsPerPage" [value]="itemcount">{{itemcount}}</option>
            </select>
            <span class="font-caption-alt dm-p-5">Items per page</span>
    </section>
    <section class="col-md-5 col-sm-5 col-xs-5">
        <span class="font-caption-alt pull-right dm-m-x-2-y-10">{{displayItems}}
        </span>
    </section>
</section>