import { Action } from "@ngrx/store";
import { IForecast } from "./../../components/financial-mgmt/financial.model";

// 1. action types
export enum WbsDbActionTypes {
    LOAD_WbsDb = "[Wbsdb] Load",
    LOAD_WbsDb_SUCCESS = "[Wbsdb] Load Success",
    LOAD_WbsDb_FAIL = "[Wbsdb] Load Fail",
    INVALIDATE_WbsDb = "[Wbsdb] Invalidate"
}

// 2. action creators
export class LoadWbsDb implements Action {
    public readonly type = WbsDbActionTypes.LOAD_WbsDb;
    public constructor(public wbsId: string) { }
}

export class LoadWbsDbSuccess implements Action {
    public readonly type = WbsDbActionTypes.LOAD_WbsDb_SUCCESS;
    public constructor(public wbsId: string, public wbsDbDetails: IForecast) { }
}

export class LoadWbsDbFail implements Action {
    public readonly type = WbsDbActionTypes.LOAD_WbsDb_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateWbsDbDetails implements Action {
    public readonly type = WbsDbActionTypes.INVALIDATE_WbsDb;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type WbsDbActions =
    | LoadWbsDb
    | LoadWbsDbSuccess
    | LoadWbsDbFail
    | InvalidateWbsDbDetails;