import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { v4 as uuid } from "uuid";
import { Components, AccessibilityConstants, LogEventConstants, SourceConstants } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IApiResponseMessage, IAssignment, IReasonCodeProblemType } from "../../staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { IEngineRuleVerificationResponse } from "../../staffing-command-bar-common/services/contracts/rule-engine.service.contract";
import { IState } from "../../../../store/reducers";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StaffingActionConstants } from "../../staffing-command-bar-common/services/staffing-action.constants";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { DmError } from "../../../../common/error.constants";

declare let require;

@Component({
    selector: "scb-unsuspend-modal",
    templateUrl: "./unsuspend-modal.component.html",
    styleUrls: ["./unsuspend-modal.component.scss"]
})
export class UnsuspendModalComponent extends DmModalAbstract {
    @Input() public assignmentList: IAssignment[];
    @Input() public wbsId: string;
    @Input() public resourceId: string;

    public unsuspendComment = "";
    public unsuspendReason: IReasonCodeProblemType[] = [];
    public selectedReason: "select";
    public lblMessage = "";
    public isLoading: boolean = false;
    public showLoading: boolean = true;
    public loadingText: string = "Loading";
    public errorSummaryText: string;
    public isSubmitBtnDisabled: boolean = true;
    private validationResults: IEngineRuleVerificationResponse;

    public constructor(
        @Inject(StaffingActionService) private staffingActionService: StaffingActionService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(FxpMessageService) private fxpMessageService: FxpMessageService,
        @Inject(RuleEngineService) private ruleEngineService: RuleEngineService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService
    ) {
        super(activeModal, dmLogger, Components.StaffingCommandBarUnsuspendModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.selectedReason = "select";
        this.isLoading = true;
        this.validationResults = {
            assignmentIds: [],
            validationMessage: "",
            isActionable: false
        };

        if (!this.assignmentList || this.assignmentList.length <= 0) {
            this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataMissing, "error", false, uuid());
            this.closeModal();
        } else {
            const rulePath = require("../../staffing-command-bar-common/Rules/unSuspendRules.json");
            this.ruleEngineService.addRules(rulePath);
            this.ruleEngineService.runRules(this.assignmentList)
                .then((result: IEngineRuleVerificationResponse) => {
                    this.validationResults = result;
                    if (!this.validationResults.isActionable) {
                        this.isLoading = false;
                        this.fxpMessageService.addMessage(result.validationMessage, "error", true);
                        return Promise.reject(undefined);
                    }
                    return Promise.resolve();
                }).then(() => {
                    return this.loadUnSuspendReasonCode();
                }).then(() => {
                    this.isLoading = false;
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.closeModal();
                });
        }
    }

    /**
     * Close the Modal Dialog and nullfy the assingment List
     *
     * @private
     * @memberof UnsuspendModalComponent
     */
    public closeModal(): void {
        this.assignmentList = [];
        super.closeModal();
        const dropDownId = "Role Actions Dropdown " + this.resourceId;
        this.sharedFunctionsService.focus(dropDownId, true);
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.Cancel);
        }
    }

    /**
     * Submit request to unsuspend request for assignment
     */
    public submit(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.SubmitUnsuspendRoleRequest);
        let responseMessage: IApiResponseMessage;
        if (!this.selectedReason || this.selectedReason.toLowerCase() === "select") {
            this.lblMessage = DmError.Staffing.ReasonRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-unsuspend-reason-ddl", true);
            });
            return;
        }
        if (this.selectedReason.toLowerCase() === "other" && !this.unsuspendComment) {
            this.lblMessage = DmError.Staffing.CommentsAreRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-unsuspend-reason", true);
            });
            return;
        }
        this.isLoading = true;
        this.showLoading = true;
        this.loadingText = "Unsuspending";
        this.lblMessage = null;

        const response = this.staffingActionService.actionOnAssignments("UnSuspendRequest", this.validationResults.assignmentIds, this.selectedReason, this.unsuspendComment);
        response
            .then((r) => {
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.UnsuspendRoleRequestSuccess);

                responseMessage = this.staffingActionService.onSuccessAPICall(StaffingActionConstants.ActionUnSuspend, r);
                this.isLoading = false;
                this.showLoading = true;
                const projectIds = this.assignmentList.map((assignment) => assignment.projectIdSelected);
                this.staffingService.invalidateProjectStaffingData(projectIds);
                this.staffingActionService.displayMessages(responseMessage, this.validationResults.validationMessage);
                this.closeModal();
            })
            .catch((error) => {
                this.staffingActionService.onErrorAPICall(StaffingActionConstants.ActionUnSuspend, error);
                this.errorSummaryText = DmError.Staffing.ErrorWhileUnsuspending;
                this.logError(SourceConstants.Method.Submit, error, this.errorSummaryText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.showLoading = false;
                this.closeModal();
            });
    }

    /**
     * submit button is disabled until reason code is selected
     */
    public onReasonCodeChange(): void {
        if (!this.selectedReason || (this.selectedReason && this.selectedReason.toLowerCase() === "select")) {
            this.isSubmitBtnDisabled = true;
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OnReasonCodeChange, LogEventConstants.UnsuspendRoleReasonSelected, {reason: this.selectedReason});
            this.isSubmitBtnDisabled = false;
        }
    }

    /**
     * load reason code list for un-suspend action
     */
    private loadUnSuspendReasonCode(): Promise<IReasonCodeProblemType[]> {
        return this.staffingActionService
            .getUnSuspendReasons()
            .then((r) => this.unsuspendReason = r);
    }
}
