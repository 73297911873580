import { Inject, Injectable } from "@angular/core";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { IRoleDetail } from "./contracts/add-roles.contracts";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class AddRolesService extends DmServiceAbstract {
    public engStartDate: Date;
    public engEndDate: Date;
    public projectDomain: string;
    public engType: string;
    public approvedNbueHours: number;
    public newRoles: IRoleDetail[];
    public updatedNewRoles: IRoleDetail[];
    public selectedCompanyCode: string;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(dmLogger, Services.AddRolesService);
    }

    /**
     * Updating Engagement Start date
     * @param input
     */
    public updatingEngStartDate(input: Date): Date {
        return this.engStartDate = input;
    }

    /**
     * Updating Engagement End date
     * @param input
     */
    public updatingengEndDate(input: Date): Date {
        return this.engEndDate = input;
    }

    /**
     * Updating Project Domain
     * @param input
     */
    public updatingprojectDomain(input: string): string {
        return this.projectDomain = input;
    }

    /**
     * Updating Project Engagement type
     * @param input
     */
    public updatingengType(input: string): string {
        return this.engType = input;
    }

    /**
     * Updating Approved NbueHours
     * @param input
     */
    public updatingapprovedNbueHours(input: number): number {
        return this.approvedNbueHours = input;
    }

    /**
     * Updating New Roles
     * @param input
     */
    public updatingnewRoles(input: IRoleDetail[]): IRoleDetail[] {
        return this.newRoles = input;
    }

    /**
     * Get New Roles
     * @param input
     */
    public getNewRoles(input: IRoleDetail[]): IRoleDetail[] {
        return this.updatedNewRoles = input;
    }

    /**
     * Update Selected Company Code
     */
    public updateSelectedCompanyCode(input: string): string {
        return this.selectedCompanyCode = input;
    }
}
