import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DmModalV2Module } from "../../../modals/dm-modal-v2/dm-modal-v2.module";
import { BulkUpdateSchedulesModalComponent } from "./bulkupdate-schedules.component";

@NgModule({
    declarations: [
        BulkUpdateSchedulesModalComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,
        DmModalV2Module,
        FormsModule
    ],
    providers: [
    ],
    entryComponents: [
        BulkUpdateSchedulesModalComponent,
    ],
    exports: [
        BulkUpdateSchedulesModalComponent,
    ]
})
export class BulkUpdateSchedulesModalModule { }