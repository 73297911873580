<section class="activation-failed-dailog">
    <label id="activationFailedLabel" class="sr-only">Activation failed</label>
    <section class="modal-body">
        <section class="section-wrapper font-caption-alt dm-p-b-12">
            <p>
                {{validationMessage}}
            </p>
        </section>
        <section class="modal-data">
            <div role="grid">
                <div role="rowgroup" class="dm-grid-view-table">
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                            Comparison
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                            Threshold
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                            Value
                        </div>
                        <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                            Status
                        </div>
                    </div>
                </div>
                <div role="rowgroup" class="dm-grid-view-table">
                    <div class="dm-grid-view-table__body" *ngFor="let project of validationResultSet">
                        <div role="row" class="dm-grid-view-table__row project-wrapper">
                            <div class="name-grid text-capitilize dm-p-12" role="gridcell">
                                <span>Project</span>:
                                <span>{{project.projectDescription}}</span>
                                <span class="contract-type dm-m-l-8" *ngIf="project.contractType"
                                    [ngClass]="(project.contractType.toLowerCase()==='ff')? 'dm-p-x-24-y-5': 'dm-p-x-14-y-5'"
                                    [ngStyle]="project.typeColorCode">{{project.contractType}}</span>
                            </div>                          
                        </div>
                        <div role="row" class="dm-grid-view-table__row"
                            *ngFor="let amendment of project.amendmentThresholds">
                            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize">
                                {{amendment.amendmentVarianceType}}
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                {{amendment.amendmentThresholdRange | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!amendment.amendmentThresholdRange" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                {{amendment.amendmentPercentage | dmDisplayDashIfEmptyOrNull }}
                                <span *ngIf="!amendment.amendmentPercentage" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </div>
                            <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-danger text-capitilize">
                                <i class="icon dm-m-r-4"
                                    [ngClass]="{'icon-warning dm-warning' :  amendment.amendmentErrorType === MisalignmentValidationErrorTypeEnum.Warning , 'icon-close' :  amendment.amendmentErrorType === MisalignmentValidationErrorTypeEnum.Error }"></i>
                                {{amendment.amendmentErrorType }}
                            </div>
                        </div>
                        <ng-container *ngIf=" !project.amendmentThresholds || !project.amendmentThresholds.length">
                            <ng-container *ngIf="project.contractType.toLowerCase()==='ff'">
                                <div role="row" class="dm-grid-view-table__row">
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize">
                                        TPH Variance
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        {{project.marginThresholdRange | dmDisplayDashIfEmptyOrNull}}
                                        <span *ngIf="!project.marginThresholdRange" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        {{project.marginPercentage | dmDisplayDashIfEmptyOrNull }}
                                        <span *ngIf="!project.marginPercentage" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize p-l-7"
                                        [ngClass]="{ 'text-primary' : project.isMarginValid , 'text-danger' : !project.isMarginValid }">
                                        <i class="icon dm-m-r-4"
                                            [ngClass]="{'icon-CheckMark' :  project.isMarginValid , 'icon-close' :  !project.isMarginValid }"></i>
                                        {{project.isMarginValid ? 'Passed': 'Failed' }}
                                    </div>
                                </div>
                                <div role="row" class="dm-grid-view-table__row">
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize">
                                        Cost Variance
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        {{project.costThresholdRange | dmDisplayDashIfEmptyOrNull}}
                                        <span *ngIf="!project.costThresholdRange" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        {{project.costMargin | dmDisplayDashIfEmptyOrNull}}
                                        <span *ngIf="!project.costMargin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize p-l-7"
                                        [ngClass]="{ 'text-primary' : project.isCostValid , 'text-danger' : !project.isCostValid }">
                                        <i class="icon dm-m-r-4"
                                            [ngClass]="{'icon-CheckMark' :  project.isCostValid , 'icon-close' :  !project.isCostValid }"></i>
                                        {{project.isCostValid ? 'Passed' : 'Failed' }}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="project.contractType.toLowerCase()==='t&m'">
                                <div role="row" class="dm-grid-view-table__row">
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-left text-capitilize">
                                        Revenue Variance
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        {{project.revenueThresholdRange | dmDisplayDashIfEmptyOrNull }}
                                        <span *ngIf="!project.revenueThresholdRange" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        {{project.revenueMargin | dmDisplayDashIfEmptyOrNull }}
                                        <span *ngIf="!project.revenueMargin" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize p-l-7"
                                        [ngClass]="{ 'text-primary' : project.isRevenueValid , 'text-danger' : !project.isRevenueValid }">
                                        <i class="icon dm-m-r-4"
                                            [ngClass]="{'icon-CheckMark' :  project.isRevenueValid , 'icon-warning dm-warning' :  !project.isRevenueValid }"></i>
                                        {{project.isRevenueValid ? 'Passed' :'Warning'}}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>
    </section>
    <section class="modal-footer" [ngClass]="{'dm-p-b-0': isValidationFailedModal}">
        <button type="button" class="fxp-btn fxp-btn--secondary" data-dismiss="modal" id="sendToAsc"
            (click)="emailPJC()" *ngIf="showEmailPJC">
            Send to PJC
        </button>
        <button type="button" class="fxp-btn fxp-btn--secondary" data-dismiss="modal" id="continueRequest"
            (click)="continueRequest()" *ngIf="showContinueRequest">
            {{saveButtonText}}
        </button>
        <button type="button" class="fxp-btn fxp-btn--primary" data-dismiss="modal" id="closeRequest"
            (click)="cancelButtonClick()">
            {{cancelButtonText}}
        </button>
    </section>
</section>