import { Inject, forwardRef } from "@angular/core";
import { NotificationService } from "@fxp/fxpservices";
import { Injectable } from "@angular/core";
import { APIConstants, NotificationEvent, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IChangedProperties, INotificationPostData } from "./contracts/dmnotification.service.contract";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";
import { ITecoValidationResult } from "../../components/manage-ebs-v2/modals/request-status-change/engagement-teco-prevalidation/engagement-teco-prevalidation.contract";
import { IDecoNotificationContract } from "./contracts/deco-notification.contract";
import { IRecoNotificationContract } from "./contracts/reco-notification.contract";

@Injectable()
export class DMNotificationService extends DmServiceAbstract {
    private readonly projectServiceBaseUri: string;
    private readonly projectServiceSubscriptionKey: string;

    public constructor(
        @Inject(forwardRef(() => NotificationService)) private fxpNotification: NotificationService,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.NotifcationService);
        this.projectServiceBaseUri = this.configmanagerService.getValue<string>("projectServiceBaseUri") + "v1.0";
        this.projectServiceSubscriptionKey = this.configmanagerService.getValue<string>("projectServiceSubscriptionKey");
    }

    /**
     * Sends an email notification with information from the data parameter
     * @param data 
     * @param skipEsxpNotification 
     * @param esxpMessage 
     */
    public sendNotification(data: NotificationModel | NotificationModelsForProject, skipEsxpNotification?: boolean, esxpMessage?: string): Promise<any> {
        const postData = data.toNotificationApiModel();
        if (!skipEsxpNotification) {
            this.sendESXPNotification(esxpMessage, data.sendTo);
        }
        return this.dataService.postData(this.projectServiceBaseUri + "/notification",
            this.projectServiceSubscriptionKey,
            APIConstants.NotificationsAPI,
            postData);
    }

    /**
     * Converts NotificationModel to API Notification Input Model for using across other services
     * @param data    
     */
    public convertToApiModel(data: NotificationModel | NotificationModelsForProject): INotificationPostData {
        const postData = data.toNotificationApiModel();
        return postData;
    }

    /**
     * Sends an ESXP notification to users on the platform with a given message
     * @param message 
     * @param sendTo 
     */
    public sendESXPNotification(message: string, sendTo: string[]): any { // cannot return type Promise<boolean> due to incompatibility with fxpNotification.publishNotifications return type
        if (Array.isArray(sendTo)) {
            const usersEmail = sendTo.map((alias) => alias + "@microsoft.com").join(",");
            return this.fxpNotification.publishNotifications([{
                Status: "UnRead",
                IsGroup: false,
                Type: "PullOnly",
                From: "ProjectManagement",
                To: usersEmail,
                Subject: message,
                Content: message
            }]
            ).then(() => {
                return Promise.resolve(true);
            }, () => {
                return Promise.reject(false);
            });
        }
        return Promise.reject(false);
    }
}

export class NotificationModel {
    public engagementId: string;
    public engagementName: string;
    public eventName: NotificationEvent;
    public sendTo: string[];
    public cc: string[];
    public modifiedBy: string;
    public modifiedDate: Date;
    public changedProperties?: IChangedProperties[];
    public attributes: object = {};
    public subject?: string;
    public tecoValidationResult?: ITecoValidationResult;
    public decoValidationResult?: IDecoNotificationContract;
    public recoValidationResult?: IRecoNotificationContract;

    /**
     * To create notification API model
     */
    public toNotificationApiModel(): INotificationPostData {

        let subject = this.subject;
        let projectNotificationModel: NotificationModelsForProject;

        if (this instanceof NotificationModelsForProject) {
            projectNotificationModel = this;
        }

        if (!subject) {
            switch (this.eventName) {
                case NotificationEvent.EngagementUpdated:
                    subject = `Engagement [${this.engagementId} : ${this.engagementName}] Updated`;
                    break;
                case NotificationEvent.ProjectUpdated:
                    subject = `Project [${projectNotificationModel.projectId} : ${projectNotificationModel.projectName}] Updated`;
                    break;

                case NotificationEvent.ServiceUpdated:
                    subject = `Service [${this.attributes["ServiceId"]} : ${this.attributes["ServiceName"]}] Updated`;
                    break;

                case NotificationEvent.TaskUpdated:
                    subject = `Task [${this.attributes["TaskId"]} : ${this.attributes["TaskName"]}] Updated`;
                    break;

                case NotificationEvent.ReadyForActivation:
                    if (projectNotificationModel) {
                        subject = `Project [${projectNotificationModel.projectId} : ${projectNotificationModel.projectName}] is ready for Activation`;
                    } else {
                        subject = `Engagement [${this.engagementId} : ${this.engagementName}] is ready for Activation`;
                    }
                    break;

                case NotificationEvent.BaseLineFinalized:
                    if (projectNotificationModel) {
                        subject = `Baseline Finalized for Project [${projectNotificationModel.projectId} : ${projectNotificationModel.projectName}]`;
                    } else {
                        subject = `Baseline Finalized for Engagement [${this.engagementId} : ${this.engagementName}]`;
                    }
                    break;
                case NotificationEvent.EngagementActivationFailed:
                    if (projectNotificationModel) {
                        subject = `Project [${projectNotificationModel.projectId} : ${projectNotificationModel.projectName}] Activation Failed through automation`;
                    } else {
                        subject = `Engagement [${this.engagementId} : ${this.engagementName}] Activation Failed through automation`;
                    }
                    break;
                case NotificationEvent.EngagementActivationFailedWithRules:
                    if (projectNotificationModel) {
                        subject = `Project [${projectNotificationModel.projectId} : ${projectNotificationModel.projectName}] has been submitted by the Project Manager for manual Release and Activation.`;
                    } else {
                        subject = `Engagement [${this.engagementId} : ${this.engagementName}] has been submitted by the Project Manager for manual Release and Activation.`;
                    }
                    break;
                case NotificationEvent.AddNewCSATContact:
                    subject = "Create Customer Contact Request";
                    break;
                case NotificationEvent.UpdateCSATContactLanguage:
                    subject = "Update Customer Contact Language Request";
                    break;
                default:
                    break;
            }
        }

        const apiModel: INotificationPostData = {
            event: this.eventName,
            engagementId: this.engagementId,
            engagementName: this.engagementName,
            sendNotificationTo: this.sendTo,
            ccList: this.cc,
            subject: subject || this.eventName as string,
            changedProperties: this.changedProperties,
            attributes: this.attributes,
            createdBy: this.modifiedBy,
            createdOnUtc: this.modifiedDate.toUTCString(),
            tecoValidationResult: this.tecoValidationResult,
            decoValidationResult: this.decoValidationResult,
            recoValidationResult: this.recoValidationResult
        };
        return apiModel;
    }

}



export class NotificationModelsForProject extends NotificationModel {
    public projectId: string;
    public projectName: string;

    public constructor() {
        super();
    }

    /**
     * To create notification API model
     */
    public toNotificationApiModel(): INotificationPostData {
        const apiModel = super.toNotificationApiModel();
        apiModel.projectId = this.projectId;
        apiModel.projectName = this.projectName;

        return apiModel;
    }

}


