import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { IPurchaseOrder } from "../../common/services/contracts/po.service.contracts";
import * as manageSupplierActions from "./manage-suppliers.action";
import { PurchaseOrderService } from "../../common/services/po.service";

@Injectable()
export class ManageSuppliersEffect {

    @Effect()
    public loadManageSuppliers$ = this.actions$
        .ofType(manageSupplierActions.ManageSuppliersActionTypes.LOAD_MANAGE_SUPPLIERS)
        .pipe(
            switchMap((action: manageSupplierActions.LoadManageSuppliers) =>
                fromPromise(
                    this.purchaseOrderService.getPurchaseOrderDetailsByWbsId(action.engagementId)
                )
                    .pipe(
                        map((purchaseOrders: IPurchaseOrder[]) => new manageSupplierActions.LoadManageSuppliersSuccess(action.engagementId, purchaseOrders)),
                        catchError((error) => of(new manageSupplierActions.LoadManageSuppliersFail(action.engagementId, error)))
                    )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(PurchaseOrderService) private purchaseOrderService: PurchaseOrderService,
    ) { }
}