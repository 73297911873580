import { Action } from "@ngrx/store";
import { IBulkCreateNotificationState } from "./bulk-internal-engagement-status-notifications.reducer";

export enum BulkIntEngStatusNotificationsActionTypes {
    UPDATE_BULK_INT_ENG_STATUS = "[BulkIntEngStatusNotifications] Update",
}

export class UpdateBulkIntEngStatusNotifications implements Action {
    public readonly type = BulkIntEngStatusNotificationsActionTypes.UPDATE_BULK_INT_ENG_STATUS;
    public constructor(public statusNotification: IBulkCreateNotificationState) { }
}

// 3. export the union creators
export type BulkIntEngStatusNotificationsActions = UpdateBulkIntEngStatusNotifications;
