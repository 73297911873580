import { Component, forwardRef, Inject, Input, Output, EventEmitter, SimpleChanges, Injector } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { ContractType, IEngagementDetailsApiV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IFinancialRoles } from "../../../common/services/contracts/projectservice-functions.contract";
import { IBillRate, ICrDemandChangeEvent, ICrDemandOutput, IBlendedCostRequest, IExistingDemand, ITask, ICrResource } from "../../../common/services/contracts/changerequest.contract";
import { FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { ProjectService } from "../../../common/services/project.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { ChangeRequestDemandService } from "../../../common/services/change-request-demand.service";
import { DataService } from "../../../common/services/data.service";
import { AccessibilityConstants, ComponentPrefix, RouteName, SourceConstants, TMContractType } from "../../../common/application.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DmError } from "../../../common/error.constants";
import { StateService } from "@uirouter/angular";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CancelOrContinueModal } from "../modals/cancel-or-continue-modal/cancel-or-continue-modal.component";

@Component({
    selector: "dm-fcr-roles-form-control",
    templateUrl: "./fcr-roles-form-control.html",
    styleUrls: ["./fcr-roles-form-control.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FcrRolesFormControlComponent),
            multi: true
        }
    ]
})
export class FcrRolesFormControlComponent implements ControlValueAccessor {
    @Input() public get value(): ICrDemandOutput[] {
        return this.state;
    }
    public set value(val: ICrDemandOutput[]) {
        this.state = [...val];
        this.propagateChange(this.state);
    }

    @Input() public fvrContractType: ContractType;
    @Input() public fcrFormControlsTotalValue: ICrResource[];
    @Input() public formControlData: IFcrRolesFormControlData;
    @Input() public formControlDataError: string;
    @Input() public resourceLocationAndRoleEditFeatureFlag: boolean;
    @Input() public engagementDetails: IEngagementDetailsApiV2;
    @Input() public subconCostRates: number[] = [];

    @Input() public populateRoles: ICrDemandOutput[] = null;

    @Output() public contractTypeChange = new EventEmitter<ContractType>();
    @Output() public demandChangeEvent = new EventEmitter<ICrDemandChangeEvent>();
    @Output() public roleRequestSaveDeleteEvent = new EventEmitter<ICrDemandOutput>();
    public additionalCost: number = 0;
    public additionalHours: number = 0;
    public additionalRevenue: number = 0;
    public companyCodeList: ICompanyCode[] = [];
    public existingHours: number = 0;
    public existingResources: IExistingDemand[] = [];
    public invalidContractTypeMessage: string;
    public isEditable: boolean = true;
    public isPubSecEngagement: boolean = false;
    public loadingMessage: string = "Loading Role Details...";
    public statusMessage: string;
    public title: string;
    public totalCost: number = 0;
    public fcrRolesErrorMessages = DmError.FinancialChangeRequest;
    public accessibilityConstants = AccessibilityConstants;

    private state: ICrDemandOutput[] = [];

    public constructor(
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(ChangeRequestDemandService) public crDemandService: ChangeRequestDemandService,
        @Inject(forwardRef(() => FxpMessageService)) public fxpMessageService: FxpMessageService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) private dmLoggerService: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) public stateService: StateService,
        @Inject(Injector) private injector: Injector,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
    ) { }

    public ngOnInit(): void {
        this.configurationService.initialize().then(() => {
            this.companyCodeList = this.configurationService.getValue<ICompanyCode[]>("companyCodeList");
        });
    }

    public ngOnChanges(allInputChanges: SimpleChanges): void {

        /* This's the recommeded way to access SimpleChanges */
        if (allInputChanges["populateRoles"] && Array.isArray(allInputChanges["populateRoles"].currentValue)) {
            // calculate blended rate for autopop edits
            const autopopulatedLineItems = this.calculateBlendedRateForEdits([...allInputChanges["populateRoles"].currentValue]);

            this.state = autopopulatedLineItems;
            this.recalculate();
        }

        if (allInputChanges["fvrContractType"] && allInputChanges["fvrContractType"].currentValue) {
            this.fvrContractType = allInputChanges["fvrContractType"].currentValue;
        }

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateChange = (...args: any[]): void => { return; };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateTouch = (...args: any[]): void => { return; };

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: (...args: any[]) => void): void {
        this.propagateChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     * 
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: (...args: any[]) => void): void {
        this.propagateTouch = fn;
    }


    /**
     * Writes a new value to the element.
     * 
     * Part of ControlValueAccessor interface.
     */
    public writeValue(value: ICrDemandOutput[]): void {
        if (value !== undefined) {
            this.value = [...value];
        }
    }

    /**
     * Set contract type error message based on request contract type.
     *
     * @param {boolean} isFixedFee
     * @memberof FcrRolesFormControlComponent
     */
    public setContractTypeErrorMessage(isFixedFee: boolean): void {
        this.invalidContractTypeMessage = `Cannot add a ${isFixedFee ? "Fixed Fee" : "Time and Material"} task to the FCR with an existing ${isFixedFee ? "Time and Material" : "Fixed Fee"} task.`;
    }

    /**
     * remove a labor request from the labor request table at index
     */
    public deleteRole(event: KeyboardEvent, index: number): void {
        if (this.state[index].currentState.existingDemand) {
            // we need to update the currently displayed existing resources
            this.state[index].currentState.existingDemand.isDisplayed = false;
        }

        const isDeletedRoleFixedFee: boolean = this.state[index].currentState.assignedTask ? this.state[index].currentState.assignedTask.isFixedFeeProject : undefined;
        const deletedContractType: ContractType = isDeletedRoleFixedFee ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        const deletedRowLineItem: ICrDemandOutput = this.state[index];
        this.state.splice(index, 1);
        this.value = this.state;
        this.recalculate();

        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        if (!this.state.length && numberOfSavedRequests === 1) {
            this.fvrContractType = undefined;
        } else if ((isDeletedRoleFixedFee !== undefined && this.fvrContractType === deletedContractType) && !this.fcrFormControlsTotalValue.some((resourceRequest: ICrResource) => resourceRequest.savedState && resourceRequest.savedState.assignedTask && resourceRequest.savedState.assignedTask.isFixedFeeProject === isDeletedRoleFixedFee)) {
            this.fvrContractType = undefined;
        }

        // Only emit delete if there was a saved state
        if (deletedRowLineItem.savedState) {
            // Only emit contract type on delete if we are clearing the overall request type
            if (!this.fvrContractType) {
                this.emitContractTypeChange(undefined);
            }
            this.emitRoleRequestSaveOrDelete(this.state[index]);
        }

        if (this.state.length > 0 && index !== this.state.length && this.state[index]) {
            if (this.state[index].savedState) {
                this.sharedFunctionsService.focus("editBtn_" + index, true);
            } else {
                this.state[index].currentState.existingDemand ?
                    this.sharedFunctionsService.focus("adjustExistingRole_" + index, true) :
                    this.sharedFunctionsService.focus("addNewEBSId_" + index, true);
            }
        } else {
            // this.isSubmittable() ? this.sharedFunctionsService.focus("submit", true) : this.sharedFunctionsService.focus("closeUpdateButton", true);
        }
    }

    /**
     * When user wants to save the current state, copy the current state into the saved state.
     */
    public saveRequest(index: number): void {
        const fvrContractType = this.fvrContractType;
        this.validateCrDemandOutput(this.state[index], this.fvrContractType, index);
        const numberOfSavedRequests = this.fcrFormControlsTotalValue.filter((lineItem) => lineItem.savedState).length;
        const savedLaborRequests = this.state.filter((m) => m.savedState).length;
        const currentLaborRequests = this.state.filter((m) => m.currentState).length;
        const rowToSave = this.state.filter((m) => this.state[index].uuid === m.uuid)[0];
        let isValidOverRide: boolean = false;
        if ((savedLaborRequests === 1 && currentLaborRequests === 1 && numberOfSavedRequests === 1) || (savedLaborRequests === 1 && rowToSave.savedState && numberOfSavedRequests === 1)) {
            this.state[index].currentState.isContractTypeValid = true;
            isValidOverRide = true;
        } else if (this.fvrContractType && !this.crDemandService.validateFvrContractType(this.state[index], fvrContractType)) {
            this.state[index].currentState.isContractTypeValid = false;
            const isRequestFixedFee: boolean = this.state[index].currentState.assignedTask.isFixedFeeProject;
            this.setContractTypeErrorMessage(isRequestFixedFee);
        } else {
            this.state[index].currentState.isContractTypeValid = true;
            isValidOverRide = true;
        }
        if (this.state[index].currentState.isAdditionalTimeValid && this.state[index].currentState.isAdditionalHoursQuantityAllowed && this.state[index].currentState.isContractTypeValid && !this.state[index].currentState.isNewHoursLessThanStaffedHours) {
            this.state[index].editModeEnabled = false;
            this.state[index].savedState = this.crDemandService.cloneCurrentState(this.state[index]);
            this.emitContractTypeChange(this.state[index], isValidOverRide);
            this.sharedFunctionsService.focus("editBtn_" + index, true);
            this.emitRoleRequestSaveOrDelete(this.state[index]);
            this.value = this.state;
            const rowNumber = index + 1;
            document.getElementById("saveRoleMsg").setAttribute("aria-label", "Requested labor row" + rowNumber + "has been saved successfully edit button");
        }
    }

    /**
     * revert to previously saved state
     */
    public discardChanges(event: KeyboardEvent, index: number): void {
        if (this.state[index] && this.state[index].savedState) {
            if (this.state[index].currentState.existingDemand) {
                this.state[index].currentState.existingDemand.isDisplayed = false;
                this.state[index].savedState.existingDemand.isDisplayed = true;
            }
            this.state[index].currentState = this.crDemandService.cloneSavedState(this.state[index]);
            this.recalculate();
        }
        if (this.state[index] && !this.state[index].savedState) {
            this.deleteRole(event, index);
        }
        if (document.getElementById("editBtn_" + index)) {
            this.sharedFunctionsService.focus("editBtn_" + index, true);
        } else {
            this.sharedFunctionsService.focus("addNewRole", true);
        }
    }

    /**
    * Validates Project End Date
    * 
    * @param {ITask} task
    */
    public validateProjectEndDate(task: ITask): boolean {
        if (!task || !task.projectEndDate) {
            return false;
        }
        const projectEndDate = new Date(task.projectEndDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
    * Validates End Date of Projects having existing demands
    * 
    * @param {ITask} task
    */
    public validateResourceProjectEndDate(taskId: string): boolean {
        if (!taskId) {
            return false;
        }
        const projectId = this.projectService.getProjectIdFromTaskId(taskId);
        const projectDetails = this.projectService.getProjectFromWbsId(projectId, this.engagementDetails);
        if (!projectDetails || !projectDetails.endDate) {
            return false;
        }
        const projectEndDate = new Date(projectDetails.endDate);
        const currentDate = new Date();

        if (projectEndDate < currentDate) {
            return false;
        }
        return true;
    }

    /**
     * Redirects users to Engagement's Manage EBS screen
     */
    public redirectToEngagementManageEBS(): void {
        if (this.engagementDetails) {
            const engagementId = this.engagementDetails.id;
            this.stateService.go(RouteName.EngagementManageEBS, { engagementId }, { reload: RouteName.EngagementManageEBS });
        }
    }

    /**
     * Retrieves expired projects
     */
    public getExpiredProjects(): Set<string> {
        const filteredTasks = this.formControlData && this.formControlData.existingTasks && this.formControlData.existingTasks.filter((task) => {
            const projectEndDate = new Date(task.projectEndDate);
            const currentDate = new Date();

            return projectEndDate < currentDate;

        });

        const expiredProjects = new Set<string>();
        if (filteredTasks) {
            filteredTasks.forEach((task) => {
                expiredProjects.add(task.projectId);
            });
        }

        return expiredProjects;
    }

    /**
     * Proceed with project's end date change.
     */
    public onEditProjectEndDate(): void {
        // First shows up a modal to confirm the date change action from user.
        const cancelOrContinueModalRef: NgbModalRef = this.modalService.open(
            CancelOrContinueModal,
            {
                backdrop: "static",
                windowClass: "dm-modal-v2 in",
                keyboard: true,
                centered: true,
                injector: this.injector,
            }

        );

        cancelOrContinueModalRef.componentInstance.modalMessage = "The details entered in this FCR form will be erased while changing the project dates. Do you wish to continue?";
        cancelOrContinueModalRef.componentInstance.continueFunction = () => {
            this.redirectToEngagementManageEBS();
        };
    }

    /**
     * Add new Role
     *
     * @memberof LaborRequestModalComponent
     */
    public addNewRole(): void {
        const newRole = this.crDemandService.getAddRoleInitialState();
        this.state.push(newRole);
        document.getElementById("addRoleMsg").innerHTML = this.state.length + "role rows has been added successfully in Requested Labor table";
    }

    /**
     * Add a new existing resource into the resource table
     */
    public adjustExisting(): void {
        const newRole = this.crDemandService.getAdjustRoleInitialState();
        this.state.push(newRole);
        document.getElementById("addRoleMsg").innerHTML = this.state.length + "role rows has been added successfully in Requested Labor table";
    }

    /**
     * used for angular optimization of rendering this.state list (trackBy)
     */
    public trackByFn(index: number, item: ICrDemandOutput): string {
        return item.uuid;
    }

    /**
     * Validates a labor request before saving.
     *
     * @param {ICrDemandOutput} request
     * @param {ContractType} fvrContractType
     * @param {number} index
     * @memberof ChangeRequestDemandService
     */
    public validateCrDemandOutput(request: ICrDemandOutput, fvrContractType: ContractType, index: number): void {

        const existingHours = request.currentState.existingDemand && request.currentState.existingDemand.planHours ? request.currentState.existingDemand.planHours : 0;

        // Additional hours should not bring existing hours below 0
        if (existingHours + request.currentState.hours < 0) {
            request.currentState.isAdditionalTimeValid = false;
            this.sharedFunctionsService.focus("addAdditionalHours_" + index, true);
        } else {
            request.currentState.isAdditionalTimeValid = true;
        }

        // Additional hours should not bring existing hours below staffed hours if present
        if (request.currentState.existingDemand && request.currentState.existingDemand.staffedHours &&
            (existingHours + request.currentState.hours) < request.currentState.existingDemand.staffedHours
        ) {
            request.currentState.isNewHoursLessThanStaffedHours = true;
            this.sharedFunctionsService.focus("addAdditionalHours_" + index, true);
        } else {
            request.currentState.isNewHoursLessThanStaffedHours = false;
        }

        if (!this.sharedFunctionsService.validateCrNumberValue(request.currentState.hours)) {
            request.currentState.isAdditionalHoursQuantityAllowed = false;
            this.sharedFunctionsService.focus("addAdditionalHours_" + index, true);
        } else {
            request.currentState.isAdditionalHoursQuantityAllowed = true;
        }
    }

    /**
     * Retrieves the list of existing resources to display in the dropdown. should be the currently undisplayed existing resources
     * plus the existing resource for the current row.
     */
    public getUndisplayedExistingResourcesPlusCurrent(currentExistingResource: IExistingDemand): IExistingDemand[] {
        let existingResourcesToDisplay: IExistingDemand[];

        if (this.formControlData && this.formControlData.existingResources) {
            existingResourcesToDisplay = this.getFilteredDemands(this.formControlData.existingResources);
        } else {
            existingResourcesToDisplay = [];
        }

        if (currentExistingResource) {
            existingResourcesToDisplay.push(currentExistingResource);
        }
        return existingResourcesToDisplay.sort((a: IExistingDemand, b: IExistingDemand) => {
            return parseInt(a.demandId, 10) - parseInt(b.demandId, 10);
        });
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        const length: number = this.state.length;
        let defaultId: string = "adjustExisting";
        if (length > 0 && this.state[length - 1]) {
            defaultId = this.state[length - 1].savedState ? "deleteBtn_" + (length - 1) : "cancelBtn_" + (length - 1);
        }
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, defaultId);
        }
    }

    /**
     * Sets the focus on the first editable element after edit button is clicked.
     */
    public moveFocusToEditableElement(index: string, isExistingResource: boolean): void {
        const focusingId: string = isExistingResource ? "adjustExistingRole_" + index : "addNewEBSId_" + index;
        this.sharedFunctionsService.focus(focusingId, true);
    }


    /**
     * Recalculate relevant values given a change in the state.
     */
    public recalculate(): void {
        let totalExistingHours: number = 0;
        let totalAdditionalHours: number = 0;
        let totalCost: number = 0;
        let totalAdditionalCost: number = 0;
        this.state.forEach((item: ICrDemandOutput) => {
            if (!isNaN(item.currentState.hours)) {
                item.currentState.hours = +item.currentState.hours;
                const existingHours = item.currentState.existingDemand ? item.currentState.existingDemand.planHours : 0;
                const cfpCost = item.isDbOnlyDemand ? 0 : item.currentState.blendedCostRate * (existingHours + +item.currentState.hours);
                const additionalCost = cfpCost - (item.currentState.existingDemand && item.currentState.existingDemand.dbCost ? item.currentState.existingDemand.dbCost : 0);
                totalExistingHours += existingHours;
                totalAdditionalHours += item.currentState.hours;
                totalCost += cfpCost;
                totalAdditionalCost += additionalCost;
            }
        });
        this.existingHours = totalExistingHours;
        this.totalCost = totalCost;
        this.additionalHours = totalAdditionalHours;
        this.additionalCost = totalAdditionalCost;
        this.value = this.state;
    }

    /**
     * Update role request details on change of existing demand.
     *
     * @param {IExistingDemand} existingDemand
     * @param {ICrDemandOutput} rowLineItem
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {IBillRate[]} billRates
     * @param {IFinancialRoles[]} [ffRoles]
     * @param {IFinancialRoles[]} [tmRoles]
     * @memberof FcrRolesFormControlComponent
     */
    public onExistingDemandChange(existingDemand: IExistingDemand, rowLineItem: ICrDemandOutput, engagementDetails: IEngagementDetailsApiV2, billRates: IBillRate[], ffRoles?: IFinancialRoles[], tmRoles?: IFinancialRoles[]): void {
        this.crDemandService.setExistingLaborResource(existingDemand, rowLineItem, engagementDetails, this.formControlData.existingTasks, billRates, ffRoles, tmRoles);
        rowLineItem.currentState.isCrPendingInProject = this.crDemandService.isCrPendingInProject(rowLineItem.currentState.assignedTask.projectId, this.formControlData.projectsWithPendingCr);

        if (!rowLineItem.currentState.isCrPendingInProject) {
            this.retrieveCostRateData(rowLineItem);
        } else {
            rowLineItem.isSaveable = this.crDemandService.isRoleRequestSaveable(rowLineItem);
        }
    }

    /**
     * Update role request details on change of resource location.
     *
     * @param {string} resourceLocation
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public onResourceLocationChange(resourceLocation: string, rowLineItem: ICrDemandOutput): void {
        this.crDemandService.setResourceLocation(resourceLocation, rowLineItem);
        this.retrieveCostRateData(rowLineItem);
    }

    /**
     * Update role request details on change of role.
     *
     * @param {IFinancialRoles} role
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public onRoleChange(role: IFinancialRoles, rowLineItem: ICrDemandOutput): void {
        this.crDemandService.setRole(role, rowLineItem);

        // If role is subcon, do not get blended cost rate and instead choose from dropdown
        if (!rowLineItem.isSubconRole) {
            this.retrieveCostRateData(rowLineItem);
        }
    }

    /**
     * Update role request details on change of task.
     *
     * @param {ITask} task
     * @param {ICrDemandOutput} rowLineItem
     * @param {IEngagementDetailsApiV2} engagementDetails
     * @param {IBillRate} nonBill
     * @param {IFinancialRoles[]} ffRoles
     * @param {IFinancialRoles[]} tmRoles
     * @memberof FcrRolesFormControlComponent
     */
    public onSelectedTaskChange(task: ITask, rowLineItem: ICrDemandOutput, engagementDetails: IEngagementDetailsApiV2, nonBill: IBillRate, ffRoles: IFinancialRoles[], tmRoles: IFinancialRoles[]): void {
        rowLineItem.currentState.isCrPendingInProject = this.crDemandService.isCrPendingInProject(task.projectId, this.formControlData.projectsWithPendingCr);

        if (!rowLineItem.currentState.isCrPendingInProject) {
            this.crDemandService.setAssignedTask(rowLineItem, task, engagementDetails, nonBill, ffRoles, tmRoles);
            this.retrieveCostRateData(rowLineItem);
        } else {
            rowLineItem.isSaveable = this.crDemandService.isRoleRequestSaveable(rowLineItem);
        }
    }

    /**
     * Update role request details on change of hours.
     *
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public onAdditionalHoursChange(rowLineItem: ICrDemandOutput, index: number): void {
        // subcon role should use plan cost rate as blended rate calculation is not supported
        if (rowLineItem.isSubconRole) {
            const subconCostRate = rowLineItem.isNewRequest ? rowLineItem.currentState.blendedCostRate : rowLineItem.currentState.existingDemand.planCostRate;
            rowLineItem.currentState.blendedCostRate = subconCostRate ? parseFloat(subconCostRate.toFixed(3)) : 0;
        } else if (rowLineItem.currentState.costPeriodList && rowLineItem.currentState.costPeriodList.length > 0) {
            this.calculateBlendedCostRate(rowLineItem);
        }

        if (rowLineItem.currentState.blendedCostRate >= 0) {
            this.recalculate();
            this.validateCrDemandOutput(rowLineItem, this.fvrContractType, index);
            rowLineItem.isSaveable = this.crDemandService.isRoleRequestSaveable(rowLineItem);
        }
    }

    /**
     * Update role request details and validity on change of billing info.
     *
     * @param {IBillRate} billingInfo
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public onBillingRoleChange(billingInfo: IBillRate, rowLineItem: ICrDemandOutput): void {
        rowLineItem.currentState.billingInfo = billingInfo;
        rowLineItem.isSaveable = this.crDemandService.isRoleRequestSaveable(rowLineItem);
    }

    /**
     * Update role request details on change of subcon cost rate
     *
     * @param {number} costRate
     * @param {ICrDemandOutput} rowLineItem
     * @param {number} index
     * @memberof FcrRolesFormControlComponent
     */
    public onSubconCostRateChange(costRate: number, rowLineItem: ICrDemandOutput, index: number): void {
        if (!isNaN(costRate)) {
            // this.showCustomCostRateInput = false;
            rowLineItem.currentState.blendedCostRate = costRate;
            this.recalculate();
            this.validateCrDemandOutput(rowLineItem, this.fvrContractType, index);
            rowLineItem.isSaveable = this.crDemandService.isRoleRequestSaveable(rowLineItem);
        }
    }

    public onEditClick(event: KeyboardEvent, index: string): void {
        this.state[index].editModeEnabled = true;
        if (!this.state[index].currentState.costPeriodList || this.state[index].currentState.costPeriodList.length < 1) {
            this.retrieveCostRateData(this.state[index]);
        }

        this.moveFocusToEditableElement(index, !!this.state[index].currentState.existingDemand);
    }

    /**
     * Emits a change on edit of role, resource location, or additional hours.
     *
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public emitDemandChange(rowLineItem: ICrDemandOutput): void {
        const existingHours: number = rowLineItem.currentState.existingDemand ? rowLineItem.currentState.existingDemand.planHours : 0;
        const existingCost: number = rowLineItem.currentState.blendedCostRate * existingHours; // TODO: Check if blended rate should be used here
        const newCost: number = rowLineItem.currentState.blendedCostRate * (existingHours + rowLineItem.currentState.hours); // TODO: Check if blended rate should be used here

        this.demandChangeEvent.emit({
            hoursChange: (rowLineItem.currentState.hours + existingHours) - existingHours,
            projectCostChange: newCost - existingCost,
            projectId: rowLineItem.currentState.assignedTask.projectId
        });
    }

    /**
     * Emit when the contract type of role requests has changed.
     *
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public emitContractTypeChange(rowLineItem: ICrDemandOutput, isValidOverRide?: boolean): void {
        let laborContractType: ContractType;
        if (rowLineItem) {
            laborContractType = rowLineItem.currentState.assignedTask.isFixedFeeProject ? ContractType.FixedFee : ContractType.TimeAndMaterial;
        }

        if (!this.fvrContractType || isValidOverRide) {
            this.contractTypeChange.emit(laborContractType);
        }
    }

    /**
     * Emit an event when a role request is successfully saved or deleted.
     *
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    public emitRoleRequestSaveOrDelete(rowLineItem: ICrDemandOutput): void {
        this.roleRequestSaveDeleteEvent.emit(rowLineItem);
    }

    /**
     * Retrieve cost rate data and calculate blended cost rate for given role request.
     *
     * @private
     * @param {ICrDemandOutput} rowLineItem
     * @memberof FcrRolesFormControlComponent
     */
    private retrieveCostRateData(rowLineItem: ICrDemandOutput, isAutoPop?: boolean): void {
        const resourceLocation = rowLineItem.currentState.resourceLocationKey ? rowLineItem.currentState.resourceLocationKey : this.crDemandService.getShortenedResourceLocation(rowLineItem.currentState.existingDemand.resourceLocationKey);

        if ((rowLineItem.currentState.role || rowLineItem.currentState.existingDemand) && rowLineItem.currentState.assignedTask && resourceLocation) {
            this.projectService.retrieveLaborCostRate(rowLineItem).then((response) => {

                rowLineItem.currentState.costPeriodList = response.costRatePeriod;
                rowLineItem.currentState.apiCostRate = response.costRate;

                // subcon role should use plan cost rate as blended rate calculation is not supported
                if (rowLineItem.isSubconRole) {
                    rowLineItem.currentState.blendedCostRate = rowLineItem.currentState.blendedCostRate ? parseFloat(rowLineItem.currentState.blendedCostRate.toFixed(3)) : parseFloat(rowLineItem.currentState.existingDemand.planCostRate.toFixed(3));
                } else {
                    this.calculateBlendedCostRate(rowLineItem);
                }

                rowLineItem.currentState.isCostRateResolved = true;
                this.recalculate();
                rowLineItem.isSaveable = this.crDemandService.isRoleRequestSaveable(rowLineItem);

                if (isAutoPop) {
                    rowLineItem.savedState = this.crDemandService.cloneCurrentState(rowLineItem);
                }
            }).catch((err) => {
                rowLineItem.currentState.isCostRateResolved = true;
                rowLineItem.isSaveable = false;
                const correlationID: string = DataService.getCorrelationIdFromError(err);
                this.fxpMessageService.addMessage(DmError.FinancialChangeRequest.CannotRetrieveCostRate + " CorrelationId: " + correlationID, FxpConstants.messageType.error);
                this.dmLoggerService.logError(ComponentPrefix + "Fcr Roles Form Control", SourceConstants.Method.RetrieveCostRateData, err, DmError.FinancialChangeRequest.CannotRetrieveCostRate, null, undefined, correlationID, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
        }
    }

    private calculateBlendedCostRate(rowLineItem: ICrDemandOutput): void {
        rowLineItem.currentState.isCostRateResolved = false;
        const currentDate = new Date();
        let existingHours = rowLineItem.currentState.existingDemand ? rowLineItem.currentState.existingDemand.planHours : 0;
        let isCurrentDateStartDate: boolean = false;
        const projectStartDate = new Date(rowLineItem.currentState.assignedTask.projectStartDate).getTime();
        const projectEndDate = new Date(rowLineItem.currentState.assignedTask.projectEndDate).getTime();

        // if project end date is in the past, take staffed (if available) or planned cost rate as blended cost rate
        if ((projectEndDate < currentDate.getTime())) {
            if (rowLineItem.currentState.existingDemand && rowLineItem.currentState.existingDemand.staffedHours && rowLineItem.currentState.existingDemand.staffedCost) {
                rowLineItem.currentState.blendedCostRate = parseFloat((rowLineItem.currentState.existingDemand.staffedCost / rowLineItem.currentState.existingDemand.staffedHours).toFixed(3));
            } else if (rowLineItem.currentState.existingDemand && rowLineItem.currentState.existingDemand.planCostRate && !rowLineItem.isRoleOrResourceLocationChanged) {
                rowLineItem.currentState.blendedCostRate = parseFloat(rowLineItem.currentState.existingDemand.planCostRate.toFixed(3));
            } else {
                rowLineItem.currentState.blendedCostRate = parseFloat(rowLineItem.currentState.apiCostRate.toFixed(3));
            }
        } else {
            let startDate: Date;
            if (projectStartDate < currentDate.getTime()) {
                startDate = currentDate;
                isCurrentDateStartDate = true;
            } else {
                startDate = rowLineItem.currentState.assignedTask.projectStartDate;
                isCurrentDateStartDate = false;
            }

            // Need to pass in unstaffed hours for blended cost request if there is staffed hours
            let staffedCost: number = 0;
            if (rowLineItem.currentState.existingDemand && rowLineItem.currentState.existingDemand.staffedHours) {
                existingHours = rowLineItem.currentState.existingDemand.planHours - rowLineItem.currentState.existingDemand.staffedHours;
                staffedCost = rowLineItem.currentState.existingDemand.staffedCost;
            }

            const blendedCostRequest: IBlendedCostRequest = {
                startDate,
                endDate: rowLineItem.currentState.assignedTask.projectEndDate,
                existingHours,
                currentFinancialPlanCost: rowLineItem.currentState.existingDemand ? (rowLineItem.currentState.existingDemand.planCostRate * rowLineItem.currentState.existingDemand.planHours) : 0, // Applicable only for Adjust Existing Role
                additionalHours: +rowLineItem.currentState.hours,
                costPeriodList: rowLineItem.currentState.costPeriodList,
                staffedHours: rowLineItem.currentState.existingDemand && rowLineItem.currentState.existingDemand.staffedHours ? rowLineItem.currentState.existingDemand.staffedHours : 0,
                staffedCost: staffedCost ? staffedCost : 0,
                isCurrentDateStartDate
            };

            rowLineItem.currentState.blendedCostRate = parseFloat(this.projectService.getBlendedCostRate(blendedCostRequest).toFixed(3));
        }

        rowLineItem.currentState.isCostRateResolved = true;
    }

    /**
     * Calculate the blended rate for any autopopulated plan and forecast edits.
     *
     * @private
     * @param {ICrDemandOutput[]} autopopulatedLineItems
     * @returns {void}
     * @memberof FcrRolesFormControlComponent
     */
    private calculateBlendedRateForEdits(autopopulatedLineItems: ICrDemandOutput[]): ICrDemandOutput[] {
        const autopopLineItemsWithRates = autopopulatedLineItems.map((lineItem: ICrDemandOutput) => {
            if (lineItem.isPnfEdit && !lineItem.isNewRequest) {
                this.retrieveCostRateData(lineItem, true);
                return lineItem;
            } else {
                return lineItem;
            }
        });

        return autopopLineItemsWithRates;
    }

    /**
     * Get the filtered Demands based on Contract type.
     *
     * @private
     * @param {IExistingDemand[]} fcrRolesControlDemands
     * @returns {IExistingDemand[]}
     * @memberof FcrRolesFormControlComponent
     */
    private getFilteredDemands(fcrRolesControlDemands: IExistingDemand[]): IExistingDemand[] {
        const filteredDemands: IExistingDemand[] = [];
        for (const demand of fcrRolesControlDemands) {
            if (!demand.isDisplayed && demand.demandId && demand.demandId !== "#") {
                const contractType: string = this.getContractType(demand.taskId);
                if (contractType && contractType === TMContractType && demand.isDemandNonBillable) {
                    filteredDemands.push(demand);
                } else if (contractType !== TMContractType) {
                    filteredDemands.push(demand);
                }

            }

        }

        return filteredDemands;
    }

    /**
     * Get the Contract type based on taskId
     *
     * @private
     * @param {string} taskId
     * @returns {string}
     * @memberof FcrRolesFormControlComponent
     */
    private getContractType(taskId: string): string {
        const projectDetails = this.engagementDetails.projects.filter((p) => p.id.substring(0, 16) === taskId.substring(0, 16));
        if (projectDetails.length > 0) {
            return projectDetails[0].contractType;
        }

        return "";
    }
}

interface ICompanyCode {
    companyCode: string;
    companyName?: string;
}

export interface IFcrRolesFormControlData {
    billRates: IBillRate[];
    engagementDetails: IEngagementDetailsApiV2;
    existingTasks: ITask[];
    existingResources: IExistingDemand[];
    FFBillRates: IBillRate[];
    nonBill: IBillRate;
    planCurrency?: string;
    roleValuesFF?: IFinancialRoles[];
    roleValuesTM?: IFinancialRoles[];
    TAndMBillRates: IBillRate[];
    existingDbOnlyResources?: IExistingDemand[];
    projectsWithPendingCr?: string[];
    error?: string;
}
