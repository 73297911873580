import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DmModalV2Module } from "../../../modals/dm-modal-v2/dm-modal-v2.module";
import { DecoPrevalidationModule } from "./deco-prevalidation/deco-prevalidation.module";
import { EngagementTecoPrevalidationModule } from "./engagement-teco-prevalidation/engagement-teco-prevalidation.module";
import { RecoPrevalidationModule } from "./reco-prevalidation/reco-prevalidation.module";
import { EbsV2RequestStatusChangeModalComponent } from "./request-status-change.component";

@NgModule({
    declarations: [
        EbsV2RequestStatusChangeModalComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        CommonComponentsModule,
        DmModalV2Module,
        EngagementTecoPrevalidationModule,
        DecoPrevalidationModule,
        RecoPrevalidationModule,
        FormsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
    ],
    entryComponents: [
        EbsV2RequestStatusChangeModalComponent,
    ],
    exports: [
        EbsV2RequestStatusChangeModalComponent,
    ]
})
export class EbsV2RequestStatusChangeModalModule { }