import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, NotificationEvent, WBSResponseMessages, AccessibilityConstants, NONFTE_REGEX } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService, NotificationModelsForProject } from "../../../../common/services/dmnotification.service";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IWbsEditProjectDetailsV2, ITeamStructureForEditWBS } from "../../../../common/services/contracts/wbs.service.contracts";
import { IProjectRequest } from "../../../../common/services/contracts/staffing.service.contract";
import { MyPortfolioService } from "../../../../common/services/my-portfolio.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../common/services/wbs.service";
import { DmError } from "../../../../common/error.constants";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, AbstractControl, Validators, FormBuilder } from "@angular/forms";
import { INotificationMessages } from "../../../../common/services/contracts/financial.service.contracts";
import { IOneProfileSerResAttr, ISelectedUserAttributes } from "../../../../components/tiles/type-ahead/type-ahead-contracts";
import { IManager } from "../edit-team-structure/edit-team-structure.component";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { StateService } from "@uirouter/angular";
import { INavigationListItemAttribute } from "../../../../components/navigation/navigationlist.model";
import { ManageEbsService } from "../../../../common/services/manage-ebs.service";

@Component({
    templateUrl: "./edit-project-team-structure.html",
    styleUrls: ["./edit-project-team-structure.scss"]
})
export class EditProjectTeamStructureModalComponent extends DmModalAbstract {
    @Input() public selectedProject: IProjectDetailsV2;
    @Input() public engagementDetails: IEngagementDetailsV2;
    public editProjectErrorMessages = DmError.EbsStructure;
    public typeAheadId: string;
    public taSearchAriaLblTxt: string;
    public typeAheadLabelText: string;
    public taCancelAriaLblTxt: string;
    public taRequiredMsg: string;
    public selectedUser: ISelectedUserAttributes;
    public additionalTypeAheadId: string;
    public additionalSearchAriaLblTxt: string;
    public additionalTypeAheadLabelText: string;
    public additionalCancelAriaLblTxt: string;
    public uSubmitterSearchAriaLblTxt: string;
    public uSubmitterTypeAheadLabelText: string;
    public uSubmitterCancelAriaLblTxt: string;
    public selectedUsers: ISelectedUserAttributes[];
    public selectedUnitSubmitters: ISelectedUserAttributes[] = [];
    public multiSelectedUsers: ISelectedUserAttributes[];
    public disablePJMUpdate: boolean;
    public disableAPJMUpdate: boolean;
    public disableUnitSubmitterUpdate: boolean;
    public showNonfteValidationMessage: boolean;
    public editProjectTeamDetailsForm: FormGroup;
    public isUpdateActive: boolean = false;
    public loadingText: string;

    public get pjm(): AbstractControl {
        return this.editProjectTeamDetailsForm.get("pjm");
    }
    public get adPjm(): AbstractControl {
        return this.editProjectTeamDetailsForm.get("adPjm");
    }
    public get uSubmitters(): AbstractControl {
        return this.editProjectTeamDetailsForm.get("uSubmitters");
    }

    public modalContent: IModal;
    public isUpdatingProject: boolean;
    public PJMPristine: boolean = true;
    public APJMPristine: boolean = true;    
    public isUnitSubmitterPristine: boolean = true;
    public deletedManagers: IManager[] = [];
    public addedManagers: IManager[] = [];
    public deletedUnitSubmitters: IManager[] = [];
    public addedUnitSubmitters: IManager[] = [];
    public pjmDeleted: boolean = false;
    public isComplexUnitsFeatureEnabled: boolean = false;


    private serviceResponseMessages: IResponseMessage;
    private notificationMessage: INotificationMessages;
    private readonly FXP_CONSTANTS = FxpConstants;
    private resourceRequests: IProjectRequest[] = [];

    private originalAdditionalPJMBpIds: number[];
    private originalUnitSubmittersBpIds: number[];
    private originalAdditionalPPJMBpIds: number[] = [];
    private updatedAdditionalPJMBpIds: number[];
    private updatedUnitSubmitterBpIds: number[];
    private APJMChanged: boolean = false;
    private IsUnitSubmitterChanged: boolean = false;
    private originalAPJMNames: string[] = [];
    private originalUnitSubmitterNames: string[] = [];
    private addedAPJMNames: string[] = [];
    private addedUnitSubmitterNames: string[] = [];
    private deletedAPJMNames: string[] = [];
    private deletedUnitSubmitterNames: string[] = [];
    private engagementFullDetailsV2: IEngagementDetailsV2;
    private currentTab: INavigationListItemAttribute;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(StateService) private stateService: StateService,
        @Inject(ManageEbsService) private manageEbsService: ManageEbsService) {
        super(activeModal, dmLogger, Components.ManageEbsEditProjectDetails);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.modalContent = {
            title: "Edit Project Team Details"
        };
        this.setTypeAheadText();
        this.serviceResponseMessages = WBSResponseMessages.Project;
        this.notificationMessage = this.configurationService.getValue<any>("Notification");
        this.isComplexUnitsFeatureEnabled = this.configurationService.isFeatureEnabled("enableComplexUnitsFeature");

        // Creating Primary Manager for UI (single user typehead)
        if (this.selectedProject && this.selectedProject.pjm) {
            this.selectedUser = {
                userAlias: this.selectedProject.pjm.alias,
                userName: this.selectedProject.pjm.name,
                bpId: this.selectedProject.pjm.bpid,
                emailName: "",
                firstName: "",
                lastName: "",
                fullName: this.selectedProject.pjm.alias
            };
        }

        // Creating Additional PJMs for UI (multiple users typeahead)
        this.selectedUsers = [];
        this.originalAdditionalPJMBpIds = [];
        this.originalUnitSubmittersBpIds = [];        
        if (this.selectedProject.adPjm) {
            for (const singleAPJM of this.selectedProject.adPjm) {
                if (this.originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpid)) < 0) {
                    this.originalAdditionalPJMBpIds.push(Number(singleAPJM.bpid));
                    this.originalAPJMNames.push(singleAPJM.name);                   
                }
                // Create new object for the user in order to match object types used in typeahead component
                const newAPJM: ISelectedUserAttributes = {
                    userAlias: singleAPJM.alias,
                    userName: singleAPJM.name,
                    bpId: singleAPJM.bpid,
                    emailName: "",
                    firstName: "",
                    lastName: "",
                    fullName: singleAPJM.alias
                };
                this.selectedUsers.push(newAPJM);
            }
        }
        if (this.selectedProject.uSubmitter) {
            for (const uSubmitter of this.selectedProject.uSubmitter) {
                if (this.originalUnitSubmittersBpIds.indexOf(Number(uSubmitter.bpid)) < 0) {
                    this.originalUnitSubmittersBpIds.push(Number(uSubmitter.bpid));
                    this.originalUnitSubmitterNames.push(uSubmitter.name);
                }
                // Create new object for the user in order to match object types used in typeahead component
                const unitSubmitter: ISelectedUserAttributes = {
                    userAlias: uSubmitter.alias,
                    userName: uSubmitter.name,
                    bpId: uSubmitter.bpid,
                    emailName: "",
                    firstName: "",
                    lastName: "",
                    fullName: uSubmitter.alias
                };
                this.selectedUnitSubmitters.push(unitSubmitter);
            }
        }

        this.multiSelectedUsers = this.selectedUsers;

        if (this.engagementDetails) {
            this.engagementFullDetailsV2 = this.engagementDetails;
            if (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.adPPjm && this.engagementFullDetailsV2.adPPjm.length) {
                for (const singleAPPJM of this.engagementFullDetailsV2.adPPjm) {
                    if (singleAPPJM && singleAPPJM.bpid) {
                        if (this.originalAdditionalPPJMBpIds.indexOf(Number(singleAPPJM.bpid)) < 0) {
                            this.originalAdditionalPPJMBpIds.push(Number(singleAPPJM.bpid));
                        }
                    }
                }
            }
        }

        this.setDisableManagerChange();
        this.initializeEditProjectDetailsForm();
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalid: boolean): boolean { // TODO: should be removed later as this needs to reactive form and these boolean flags should be not be used for validations.
        if (NONFTE_REGEX.test(this.selectedUser && this.selectedUser.userAlias)) {
            this.showNonfteValidationMessage = true;
            return true;
        }
        this.showNonfteValidationMessage = false;

        if (formInvalid) {
            return true;
        }

        this.PJMPristine = true;
        if (this.selectedUser) {
            this.PJMPristine = Number(this.selectedUser.bpId) === Number(this.selectedProject.pjm.bpid);
            this.pjmDeleted = !this.PJMPristine;
        }
        this.APJMPristine = true;
        this.isUnitSubmitterPristine = true;
        this.updatedAdditionalPJMBpIds = [];
        this.updatedUnitSubmitterBpIds = [];
        if (this.selectedUsers) {
            for (const singleAPJM of this.selectedUsers) {
                if (this.updatedAdditionalPJMBpIds.indexOf((Number(singleAPJM.bpId))) < 0) {
                    this.updatedAdditionalPJMBpIds.push(Number(singleAPJM.bpId));
                }
            }
        }
        if (this.selectedUnitSubmitters) {
            for (const uSubmitter of this.selectedUnitSubmitters) {
                if (this.updatedUnitSubmitterBpIds.indexOf((Number(uSubmitter.bpId))) < 0) {
                    this.updatedUnitSubmitterBpIds.push(Number(uSubmitter.bpId));
                }
            }
        }

        if (this.originalAdditionalPJMBpIds) {
            if ((this.originalAdditionalPJMBpIds.length
                && ((this.updatedAdditionalPJMBpIds.length && this.originalAdditionalPJMBpIds.sort().join(",") !== this.updatedAdditionalPJMBpIds.sort().join(","))
                    || (this.adPjm && this.adPjm.value && !this.adPjm.value.length)))
                || (!this.originalAdditionalPJMBpIds.length && this.updatedAdditionalPJMBpIds.length)) {
                this.APJMPristine = false;
            }
        }

        if (this.originalUnitSubmittersBpIds) {
            if ((this.originalUnitSubmittersBpIds.length
                && ((this.updatedUnitSubmitterBpIds.length && this.originalUnitSubmittersBpIds.sort().join(",") !== this.updatedUnitSubmitterBpIds.sort().join(","))
                    || (this.uSubmitters && this.uSubmitters.value && !this.uSubmitters.value.length)))
                || (!this.originalUnitSubmittersBpIds.length && this.updatedUnitSubmitterBpIds.length)) {
                this.isUnitSubmitterPristine = false;
            }
        }

        const isFormPristine: boolean = this.APJMPristine && this.PJMPristine && this.isUnitSubmitterPristine;

        return isFormPristine;
    }

    /**
     * Added managers
     */
    public onAdditionalManagerAdded(user: IOneProfileSerResAttr): void {
        // if added user is present in deleted list also then remove it from the deleted list
        if (this.deletedManagers && this.deletedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.BusinessPartnerId)) > -1) {
            this.deletedManagers.splice(this.deletedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.BusinessPartnerId)), 1);
        }
        if (this.adPjm.value) {
            this.addedManagers = [];
            for (const singleAPPJM of this.adPjm.value) {
                if (singleAPPJM.bpId) {
                    // if user not present in the original additional managers list then add to added managers list
                    if (this.originalAdditionalPJMBpIds.indexOf(Number(singleAPPJM.bpId)) < 0) {
                        this.addedManagers.push({
                            name: singleAPPJM.fullName,
                            bpId: singleAPPJM.bpId
                        });
                    }
                }
            }
        }
    }

    /**
     * Deleted managers
     */
    public onAdditionalManagerRemoved(user: ISelectedUserAttributes): void {
        // if delted user is present in added list also then remove it from the added list
        if (this.addedManagers && this.addedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.bpId)) > -1) {
            this.addedManagers.splice(this.addedManagers.findIndex((manager) => Number(manager.bpId) === Number(user.bpId)), 1);
        }
        if (this.originalAdditionalPJMBpIds && this.originalAdditionalPJMBpIds.length) {
            this.deletedManagers = [];
            for (const orignalAPPJM of this.originalAdditionalPJMBpIds) {
                if (orignalAPPJM) {
                    if (this.adPjm.value && this.adPjm.value.findIndex((adPjm) => Number(adPjm.bpId) === orignalAPPJM) < 0) {
                        if (this.selectedProject.adPjm) {
                            const apjm = this.selectedProject.adPjm.filter((x) => Number(x.bpid) === orignalAPPJM)[0];
                            if (apjm) {
                                this.deletedManagers.push({
                                    name: apjm.name,
                                    bpId: apjm.bpid
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Updates the Project Details by calling the API, sending any notifications, etc.
     */
    public updateProjectDetails(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.UpdateProjectDetails, LogEventConstants.ManageEBSEditProjectTeamStructureSubmitClicked);
        this.loadingText = "Updating Project Team Details";
        this.isUpdateActive = true;

        if (!this.pjm.value && !this.adPjm.value) {
            return;
        }

        const modifiedPJMList: ITeamStructureForEditWBS[] = [];
        let pjmChanged: boolean = false;
        if (this.pjm.value && this.pjm.value.bpId !== this.selectedProject.pjm.bpid) {
            modifiedPJMList.push({
                projectRole: "PJM",
                bpId: this.pjm.value.bpId
            });
            pjmChanged = true;
        }

        // if id is not present in new but it is present in original then manager is deleted
        if (this.originalAdditionalPJMBpIds && this.originalAdditionalPJMBpIds.length) {
            for (const orignalAPJM of this.originalAdditionalPJMBpIds) {
                if (orignalAPJM) {
                    if (this.adPjm.value && this.adPjm.value.findIndex((adPjm) => Number(adPjm.bpId) === orignalAPJM) < 0) {
                        modifiedPJMList.push({
                            projectRole: "ADPJM",
                            bpId: orignalAPJM.toString(),
                            isDelete: true // An original APJM is no longer selected, set isDelete to True
                        });
                        if (this.selectedProject.adPjm) {
                            const apjm = this.selectedProject.adPjm.filter((x) => Number(x.bpid) === orignalAPJM)[0];
                            if (apjm) {
                                this.deletedAPJMNames.push(apjm.name);
                                this.APJMChanged = true;
                            }
                        }
                    }
                }
            }
        }

        // if id is present in new but it is not present in original then manager is added
        if (this.adPjm.value) {
            for (const singleAPJM of this.adPjm.value) {
                if (singleAPJM.bpId) {
                    if (this.originalAdditionalPJMBpIds.indexOf(Number(singleAPJM.bpId)) < 0) {
                        modifiedPJMList.push({
                            projectRole: "ADPJM",
                            bpId: singleAPJM.bpId,
                            isDelete: false // New APJM added, set isDelete to False
                        });
                        this.addedAPJMNames.push(singleAPJM.userName);
                        this.APJMChanged = true;
                    }
                }
            }
        }

        // if id is not present in new but it is present in original then manager is deleted
        if (this.originalUnitSubmittersBpIds && this.originalUnitSubmittersBpIds.length) {
            for (const unitSubmitter of this.originalUnitSubmittersBpIds) {
                if (unitSubmitter) {
                    if (this.uSubmitters.value && this.uSubmitters.value.findIndex((u) => Number(u.bpId) === Number(unitSubmitter)) < 0) {
                        modifiedPJMList.push({
                            projectRole: "ZUNI",
                            bpId: unitSubmitter.toString(),
                            isDelete: true
                        });
                        if (this.selectedProject.uSubmitter) {
                            const unitSubmitterInfo = this.selectedProject.uSubmitter.filter((x) => Number(x.bpid) === Number(unitSubmitter))[0];
                            if (unitSubmitterInfo) {
                                this.deletedUnitSubmitterNames.push(unitSubmitterInfo.name);
                                this.IsUnitSubmitterChanged = true;
                            }
                        }
                    }
                }
            }
        }

        // if id is present in new but it is not present in original then manager is added
        if (this.uSubmitters.value) {
            for (const unitSubmitter of this.uSubmitters.value) {
                if (unitSubmitter.bpId) {
                    if (this.originalUnitSubmittersBpIds.indexOf(Number(unitSubmitter.bpId)) < 0) {
                        modifiedPJMList.push({
                            projectRole: "ZUNI",
                            bpId: unitSubmitter.bpId,
                            isDelete: false 
                        });
                        this.addedUnitSubmitterNames.push(unitSubmitter.userName);
                        this.IsUnitSubmitterChanged = true;
                    }
                }
            }
        }

        const editProjectDataRequest: IWbsEditProjectDetailsV2 = {};
        if (modifiedPJMList.length > 0) {
            editProjectDataRequest.teamStructure = [];
            editProjectDataRequest.teamStructure = modifiedPJMList;
        }
        if (editProjectDataRequest && pjmChanged || this.APJMChanged || this.IsUnitSubmitterChanged) {
            this.isUpdatingProject = true;
            this.wbsService.updateProjectDetailsV2(editProjectDataRequest, this.selectedProject.id)
                .then((response: any) => {
                    this.currentTab = this.manageEbsService.getCurrentEbsTabToRefreshContents(this.stateService);
                    if (this.currentTab && this.currentTab.refreshData) {
                        this.currentTab.refreshData();
                    }
                    this.myPortfolioService.refreshMyPortfolioEngagementList();

                    const changedProperties: IChangedProperties[] = [];
                    if (editProjectDataRequest.teamStructure && editProjectDataRequest.teamStructure.length > 0 && editProjectDataRequest.teamStructure.filter((team) => team.projectRole === "PJM").length > 0) {
                        let originalName: string = "None";
                        if (this.selectedProject.pjm) {
                            originalName = this.selectedProject.pjm.name;
                        }
                        this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Domain Project Manager", originalName, this.pjm.value.userName));
                    }

                    if (editProjectDataRequest.teamStructure && editProjectDataRequest.teamStructure.length > 0 && editProjectDataRequest.teamStructure.filter((team) => team.projectRole === "ADPJM").length > 0) {
                        let updateMessage: string = "";
                        let origMessage: string = "";
                        for (const name of this.originalAPJMNames) {
                            origMessage += name + ", ";
                            if (this.deletedAPJMNames.indexOf(name) < 0) {
                                updateMessage += name + ", ";
                            }
                        }
                        for (const name of this.addedAPJMNames) {
                            updateMessage += name + ", ";
                        }
                        updateMessage = !updateMessage ? "No Additional Domain Project Managers" : updateMessage.slice(0, -2); /* remove trailing comma and space */
                        origMessage = !origMessage ? "No previous Additional Domain Project Managers" : origMessage.slice(0, -2);
                        this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Additional Domain Project Managers", origMessage, updateMessage));
                    }

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotificationsUpdateEBS(editProjectDataRequest)) {
                        this.createLogAndSendNotification(changedProperties);
                    }
                    if (response && response.status && response.status === 206) {
                        let partialSucessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                        partialSucessMessage = partialSucessMessage.replace("#", this.selectedProject.name);
                        this.fxpMessageService.addMessage(partialSucessMessage, this.FXP_CONSTANTS.messageType.warning);
                    } else {
                        let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                        successMessage = successMessage.replace("#", this.selectedProject.name);
                        this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                    }
                    this.closeModal();
                })
                .catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.selectedProject.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.UpdateProjectDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUpdatingProject = false;
                    this.isUpdateActive = false;
                });
        }
    }

    /**
     * Updates the selected user list with a callback from the typeahead.
     * @param users
     */
    public onAdditionalManagersUpdate(users: ISelectedUserAttributes[]): void {
        this.selectedUsers = users;
        this.adPjm.setValue(users);
    }

    /**
     * Selected User from typeahead
     *
     * @param {ISelectedUserAttributes} selectedUser
     */
    public selectedUserUpdated(selectedUser: ISelectedUserAttributes): void {
        this.selectedUser = selectedUser;
        this.pjm.setValue(selectedUser);
    }

    /**
     * Disable save button on user clear
     *
     * @param {boolean} isClearPjM
     */
    public onUserCleared(isClearPjM: boolean): void {
        this.pjmDeleted = true;
        this.pjm.setValue({});
        this.selectedUser = undefined;
        this.saveButtonDisabled(isClearPjM);
    }

    /**
     * Added managers
     */
    public onUnitSubmitterAdded(user: IOneProfileSerResAttr): void {
        // if added user is present in deleted list also then remove it from the deleted list
        if (this.deletedUnitSubmitters && this.deletedUnitSubmitters.findIndex((manager) => Number(manager.bpId) === Number(user.BusinessPartnerId)) > -1) {
            this.deletedUnitSubmitters.splice(this.deletedUnitSubmitters.findIndex((manager) => Number(manager.bpId) === Number(user.BusinessPartnerId)), 1);
        }
        if (this.uSubmitters.value) {
            this.addedUnitSubmitters = [];
            for (const unitSubmitter of this.uSubmitters.value) {
                if (unitSubmitter.bpId) {
                    // if user not present in the original additional managers list then add to added managers list
                    if (this.originalUnitSubmittersBpIds.indexOf(Number(unitSubmitter.bpId)) < 0) {
                        this.addedUnitSubmitters.push({
                            name: unitSubmitter.fullName,
                            bpId: unitSubmitter.bpId
                        });
                    }
                }
            }
        }
    }

    /**
     * Deleted managers
     */
    public onUnitSubmitterRemoved(user: ISelectedUserAttributes): void {
        // if delted user is present in added list also then remove it from the added list
        if (this.addedUnitSubmitters && this.addedUnitSubmitters.findIndex((manager) => Number(manager.bpId) === Number(user.bpId)) > -1) {
            this.addedUnitSubmitters.splice(this.addedUnitSubmitters.findIndex((manager) => Number(manager.bpId) === Number(user.bpId)), 1);
        }
        if (this.originalUnitSubmittersBpIds && this.originalUnitSubmittersBpIds.length) {
            this.deletedUnitSubmitters = [];
            for (const originalUSubmitter of this.originalUnitSubmittersBpIds) {
                if (originalUSubmitter) {
                    if (this.uSubmitters.value && this.uSubmitters.value.findIndex((u) => Number(u.bpId) === originalUSubmitter) < 0) {
                        if (this.selectedProject.uSubmitter) {
                            const unitSubmitter = this.selectedProject.uSubmitter.filter((x) => Number(x.bpid) === originalUSubmitter)[0];
                            if (unitSubmitter) {
                                this.deletedUnitSubmitters.push({
                                    name: unitSubmitter.name,
                                    bpId: unitSubmitter.bpid
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Updates the selected user list with a callback from the typeahead.
     * @param users
     */
    public onUnitSubmitterUpdate(users: ISelectedUserAttributes[]): void {
        this.selectedUnitSubmitters = users;
        this.uSubmitters.setValue(users);
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.wbsService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.ManageEBSProjectTeamStructureEdit, propertyBag);

        const notification = new NotificationModelsForProject();
        notification.engagementId = this.selectedProject.engagementId;
        notification.engagementName = this.engagementDetails ? this.engagementDetails.name : this.selectedProject.name;
        notification.eventName = NotificationEvent.ProjectUpdated;
        notification.projectId = this.selectedProject.id;
        notification.projectName = this.selectedProject.name;
        notification.eventName = NotificationEvent.ProjectUpdated;
        let sendTo = this.sharedFunctionsService.getListofPjmV2(this.engagementDetails).concat(userAlias);
        if (this.engagementDetails) {
            if (this.APJMChanged) { // Notify PJM, Additional PJM of the project where data has changed with data from engagement level
                const listOfPJMEngagementOnly: string[] = this.sharedFunctionsService.getListofPjmEngagementOnlyV2(this.engagementDetails);
                const listOfPJMProjectOnly: string[] = this.sharedFunctionsService.getListofPjmProjectContextV2(this.engagementDetails.projects.filter((project) => project.id === this.selectedProject.id), true);
                listOfPJMEngagementOnly.forEach((pjm: string) => {
                    const index = listOfPJMProjectOnly.indexOf(pjm);
                    if (index >= 0) {
                        listOfPJMProjectOnly.splice(index, 1);
                    }
                });
                sendTo = listOfPJMEngagementOnly.concat(listOfPJMProjectOnly).concat(userAlias);
            } else { // Notify PJM and PPJM only of change
                sendTo = this.sharedFunctionsService.getListofPjmV2(this.engagementDetails).concat(userAlias);
            }
        }

        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.modifiedBy = userAlias;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;
        let esxpNotification = this.notificationMessage.ProjectNotification;
        esxpNotification = esxpNotification.replace("#", this.selectedProject.name);
        this.notificationService.sendNotification(notification, false, esxpNotification);
    }

    /**
     * Initializes edit project form data
     */
    private initializeEditProjectDetailsForm(): void {
        this.editProjectTeamDetailsForm = this.fb.group({
            pjm: [this.selectedUser ? this.selectedUser : this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr(), [Validators.required]],
            adPjm: [this.selectedUsers ? this.selectedUsers : []],
            uSubmitters: [this.selectedUnitSubmitters ? this.selectedUnitSubmitters : []],
        });
    }

    /**
     * Populates all the type ahead text values
     */
    private setTypeAheadText(): void {
        this.typeAheadId = "projTypeAheadForManager";
        this.taSearchAriaLblTxt = "Search manager by name or alias";
        this.typeAheadLabelText = "Domain Project Manager";
        this.taCancelAriaLblTxt = "Remove Domain Project Manager";
        this.taRequiredMsg = "Domain Project Manager is required";
        this.additionalTypeAheadId = "projTypeAheadForAdditionalManager";
        this.additionalSearchAriaLblTxt = "Search Additional Domain Project Manager - Optional";
        this.additionalTypeAheadLabelText = "Additional Domain Project Manager - Optional";
        this.additionalCancelAriaLblTxt = "Remove Additional Domain Project Manager name or alias";
        this.uSubmitterSearchAriaLblTxt = "Search Unit Submitter - Optional";
        this.uSubmitterTypeAheadLabelText = "Unit Submitter - Optional";
        this.uSubmitterCancelAriaLblTxt = "Remove Unit Submitter name or alias";
    }

    /**
     * Sets the disabled flag for editing the manager and additional manager fields based on
     * the logged in user and the users within the team.
     */
    private setDisableManagerChange(): void {
        const currentUser: number = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        const pjmBpid: number = (this.selectedProject && this.selectedProject.pjm && this.selectedProject.pjm.bpid) ? Number(this.selectedProject.pjm.bpid) : undefined;
        const delPjmBpid: number = (this.selectedProject && this.selectedProject.delPjm && this.selectedProject.delPjm.bpid) ? Number(this.selectedProject.delPjm.bpid) : undefined;
        const pPjmBpid: number = (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.pPjm && this.engagementFullDetailsV2.pPjm.bpid) ? Number(this.engagementFullDetailsV2.pPjm.bpid) : undefined;
        const delPPjmBpid: number = (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.delPPjm && this.engagementFullDetailsV2.delPPjm.bpid) ? Number(this.engagementFullDetailsV2.delPPjm.bpid) : undefined;
        const dmmPPjmBpid: number = (this.engagementFullDetailsV2 && this.engagementFullDetailsV2.dmmPPjm && this.engagementFullDetailsV2.dmmPPjm.bpid) ? Number(this.engagementFullDetailsV2.dmmPPjm.bpid) : undefined;
        const dmmPjmBpid: number = (this.selectedProject && this.selectedProject.dmmPjm && this.selectedProject.dmmPjm.bpid) ? Number(this.selectedProject.dmmPjm.bpid) : undefined;

        /* If current user is a PPJM, then user can edit PJM, APJM values else disable */
        if (pPjmBpid && currentUser === pPjmBpid) {
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (delPPjmBpid && currentUser === delPPjmBpid) {
            /* If current user is a delegated PPJM, then user can edit PJM, APJM values else disable */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (this.originalAdditionalPPJMBpIds && this.originalAdditionalPPJMBpIds.indexOf(currentUser) > -1) {
            /* If current user is an APPJM, then user can edit PJM, APJM else disable */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (pjmBpid && currentUser === pjmBpid) {
            /* If current user is a PJM, then user can edit PJM, APJM values */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (delPjmBpid && currentUser === delPjmBpid) {
            /* If current user is a delegated PJM, then user can edit PJM, APJM values */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (this.originalAdditionalPJMBpIds && this.originalAdditionalPJMBpIds.indexOf(currentUser) > -1) {
            /* If current user is a APJM, then user can edit APJM value else disable */
            this.disablePJMUpdate = true;
            this.disableAPJMUpdate = false;
        } else if (dmmPPjmBpid && currentUser === dmmPPjmBpid) {
            /* If current user is a DMM at engagement level, then user can edit PJM, APJM values */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else if (dmmPjmBpid && currentUser === dmmPjmBpid) {
            /* If current user is a DMM at project level, then user can edit PJM, APJM values */
            this.disableAPJMUpdate = false;
            this.disablePJMUpdate = false;
        } else {
            /* If current user is not a PJM, APJM, delegated PJM, PPJM, APPJM, delegated PPJM then disable flag is set to true */
            this.disablePJMUpdate = true;
            this.disableAPJMUpdate = true;
        }
    }
}
