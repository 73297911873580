import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { InternalEngagementFinancialModalComponent } from "./internal-engagement-financial-modal.component";


@NgModule({
    declarations: [
        InternalEngagementFinancialModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule        
    ],
    providers: [
        DMLoggerService,
    ],
    entryComponents: [
        InternalEngagementFinancialModalComponent
    ],
    exports: [
        InternalEngagementFinancialModalComponent
    ]
})

export class InternalEngagementFinancialModalModule { }
