import * as fromProjectDetails from "./project-details.action";
import { IProjectEngagementDetails } from "../../common/services/contracts/cache.contracts";
import { ILoadableState } from "../reducers";

// todo: rename to just project
export interface IProjectDetailsState extends ILoadableState {
    projectDetails: IProjectEngagementDetails;
}

export const initialState: IProjectDetailsState = {
    projectDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromProjectDetails.ProjectDetailsActions): { [projectId: string]: IProjectDetailsState } {
    switch (action.type) {
        case fromProjectDetails.ProjectDetailsActionTypes.LOAD_PROJECT_DETAILS: {
            const newState = {...state};
            newState[action.projectId] = { ...initialState, loading: true };
            return newState;
        }

        case fromProjectDetails.ProjectDetailsActionTypes.LOAD_PROJECT_DETAILS_SUCCESS: {
            const newState = {...state};
            newState[action.projectId] = {
                ...initialState,
                projectDetails: action.projectDetails,
                loaded: true,
                loading: false
            };
            return newState;
        }

        case fromProjectDetails.ProjectDetailsActionTypes.LOAD_PROJECT_DETAILS_FAIL: {
            const newState = {...state};
            newState[action.projectId] = {
                ...initialState,
                error: action.errorMessage
            };
            return newState;
        }

        case fromProjectDetails.ProjectDetailsActionTypes.INVALIDATE_PROJECT_DETAILS: {
            const newState = {...state};
            newState[action.projectId] = initialState;
            return newState;
        }
    }
    return state;
}