<section class="userlinks-mgmt-dailog">
    <label id="userPreferenceLinksLabel" class="sr-only">Userlinks Management</label>
    <section class="modal-header">
        <button type="button" class="close set-focus" (click)="closeModalPopUp()" id="accessibilityConstants.ClosePopUp" aria-label="Manage Links Dialog close"
            (keydown)="moveFocusPrev($event, 'accessibilityConstants.SaveUserPreferenceLinks')">
            <span aria-hidden="true" class="icon icon-close" role="closePopUp"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            Manage Links
        </h4>
    </section>
    <dm-loading [loadingText]="'Saving Details'" [isCustomText]="true" [errorText]="errorText" [showLoading]="true"
        *ngIf="isUserlinksLoading">
    </dm-loading>
    <section *ngIf="!isUserlinksLoading" class="modal-body">
        <form name="userpreferenceLinksMgmt" #userpreferenceLinksMgmt="ngForm">
            <section class="split-resource">
                <section class="split-resource__content">
                    <table class="font-caption-alt" width="100%" aria-labelledby="userPreferenceLinksLabel">
                        <thead>
                            <tr class="dm-tile__table__thead">
                                <th scope="col" class="text-left">Title</th>
                                <th scope="col" class="text-left">Url</th>
                                <th scope="col" class="text-left" aria-label="Delete"></th>
                            </tr>
                        </thead>
                        <tbody class="grey_background">                           
                            <tr class="dm-tile__table__tr" *ngFor="let link of userPreferenceLinks; let i = index">
                                <ng-container >
                                    <td class="text-left text-capitilize sr-role">
                                        <p class="input-group">
                                            <input type="text" id="userPreferencLinksName{{i}}"
                                                name="userPreferencLinksName{{i}}" [(ngModel)]="link.name"
                                                #userLinkTitle="ngModel"
                                                 placeholder="Enter the name of link"
                                                class="form-control font-caption-alt"
                                                aria-label="User Preference Link Name " required />
                                        </p>
                                        <span role="alert"
                                            *ngIf="userLinkTitle.errors && (userLinkTitle.dirty || userLinkTitle.touched)"
                                            class="font-caption-alt required-mark">
                                            {{userPreferenceLinksErrorMessages.EnterTheTitle}}
                                        </span>
                                    </td>
                                    <td class="text-left sr-hour">
                                        <p class="input-group">
                                            <input type="url" id="userPreferencLinksUrl{{i}}"
                                                [pattern]="urlValidationPattern" name="userPreferencLinksUrl{{i}}"
                                                [(ngModel)]="link.url"  #userLinkUrl="ngModel"
                                                (blur)="isUrlFieldInFocus[i]=true" class="form-control font-caption-alt"
                                                 placeholder="http://microsoft.com"
                                                aria-label="User Preference Link Url" required />
                                        </p>
                                        <div *ngIf="(isUrlFieldInFocus[i] && userLinkUrl && userLinkUrl.errors && (userLinkTitle.dirty || userLinkTitle.touched))"
                                            class="font-caption-alt required-mark">
                                            <span *ngIf="userLinkUrl.errors?.required" role="alert">
                                                {{userPreferenceLinksErrorMessages.EnterValidUrl}}
                                            </span>
                                            <span role="alert"
                                                *ngIf="(userLinkUrl.errors?.pattern && !userLinkUrl.errors?.required)">
                                                {{userPreferenceLinksErrorMessages.UrlMustBeginWithHttpOrHttps}}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="sr-delete">
                                        <button type="button" [title]="link.name ? ('Delete ' + link.name) : 'Delete'" [attr.aria-label]="link.name ? ('Delete ' + link.name) : 'Delete'"
                                            (click)="setStatusMessage('Delete',link);deleteUserPreferenceLinks(link)" class="split-btn">
                                            <span class="icon icon-delete m-t-8"></span>
                                        </button>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </section> 
                <section class="still-add">
                    <span class="wrapper font-caption-alt">
                        <button id="addLinkBtn" *ngIf="!isAddLinksDisabled" (click)="addLinkEmptyRows()"
                            class="split-btn">
                            <i class="icon icon-AddLegacy"></i> Add Links
                        </button>
                    </span>
                </section>
                <section class="modal-footer footer-button">
                    <button type="button" class="fxp-btn fxp-btn--secondary" (click)="closeModalPopUp()" (focus)="setStatusMessage('Reset')"
                        (keydown)="moveFocusNext($event, 'accessibilityConstants.SaveUserPreferenceLinks')" id="accessibilityConstants.Cancel">Cancel</button>
                    <button type="button" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.SaveUserPreferenceLinks"
                        (click)="saveUserPreferenceLinks()" (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp')"
                        [disabled]="!userpreferenceLinksMgmt.form.valid">
                        Submit
                    </button>
                    <label id="lblValidationMessage" class="input__label">
                        <span *ngIf="showValidationMessage" class="required-mark"
                            aria-hidden="true">{{validationMessageText}}</span>
                    </label>                       
                    <span class="sr-only" tabindex="-1" role="alert" *ngIf="statusMessage">{{statusMessage}}</span>                 
                </section>
            </section>
        </form>
    </section>
</section>