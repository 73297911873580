import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DropDownComponent } from "./drop-down.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        DropDownComponent        
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
        NgbModule,
        FormsModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
    ],
    entryComponents: [
        DropDownComponent
    ],
    exports: [
        DropDownComponent
    ]
})
export class DropDownModule { }
