import { Injectable, Inject } from "@angular/core";
import moment from "moment";

import { APIConstants, Services } from "../application.constants";
import { IChangedProperties } from "./contracts/dmnotification.service.contract";
import { ConfigManagerService } from "./configmanager.service";
import {
    IDeliveryState,
    IWbsAddTaskDetails,
    IWbsEditEngagementDetails,
    IWbsEditEngagementDetailsV2,
    IWbsEditProjectDetails,
    IWbsEditProjectDetailsV2,
    IWbsEditServiceDetails,
    IWbsEditTaskDetails,
    IDateValidationRequest,
    IDateValidationResult,
    IExchangeRate,
    EngagementBillStatusDetails,
    WbsETMDetails,
    IServiceProductFulfillmentDate,
    IServiceProductFulfillmentDateUpdateRequest
} from "./contracts/wbs.service.contracts";
import { DataService } from "./data.service";
import { Store } from "@ngrx/store";
import { IState } from "../../store/reducers";
import { UpdateLocallyEngagementDetails, UpdateLocallyAddTaskDetails, InvalidateEngagementDetails, LoadEngagementDetails } from "../../store/engagement-details/engagement-details.action";
import { UpdateLocallyMyPortfolioEngagements } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.action";
import { SharedFunctionsService } from "./sharedfunctions.service";
import { ITaskDetailsV2 } from "./contracts/wbs-details-v2.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class WBSService extends DmServiceAbstract {
    private wbsSubscriptionKey: string;
    private wbsServiceBaseUri: string;
    private wbsServiceV2BaseUri: string;
    private deliveryStates: IDeliveryState[];

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.VirtuosoService);
        const projectServiceBaseUri = this.configManagerService.getValue<string>("projectServiceBaseUri");
        this.wbsSubscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        this.deliveryStates = this.configManagerService.getValue<IDeliveryState[]>("deliveryStateListCustomerFacing");
        this.wbsServiceBaseUri = projectServiceBaseUri + "v1.0";
        this.wbsServiceV2BaseUri = projectServiceBaseUri + "v2.0";
    }

    /**
     * Updates engagement details with the new data via Patch request to the Project Management API
     * @param updateEngagementDetails
     * @param engagementId
     */
    public updateEngagementDetails(updateEngagementDetails: IWbsEditEngagementDetails, engagementId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceBaseUri}/Engagements/${engagementId}`,
            this.wbsSubscriptionKey, APIConstants.WbsEngPatchRequest, updateEngagementDetails)
            .then((response: { data: { isSuccess: boolean; errors: string } }) => {
                if (response && response.data && response.data.isSuccess) {
                    /* if operation is successful make changes to store locally without refreshing entire store */
                    this.store.dispatch(new UpdateLocallyEngagementDetails(engagementId, updateEngagementDetails));
                    this.store.dispatch(new UpdateLocallyMyPortfolioEngagements(engagementId, updateEngagementDetails));
                }
                return response;
            });
    }

    /**
     * Updates engagement details with the new data via Patch request to the Project Management V2 API
     * @param updateEngagementDetails
     * @param engagementId
     */
    public updateEngagementDetailsV2(updateEngagementDetails: IWbsEditEngagementDetailsV2, engagementId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceV2BaseUri}/wbs/engagement/${engagementId}`,
            this.wbsSubscriptionKey, APIConstants.WbsEngPatchRequest, updateEngagementDetails)
            .then((response: { data: { isSuccess: boolean; errors: string } }) => {
                if (response && response.data && response.data.isSuccess) {
                    /* if operation is successful make changes to store locally without refreshing entire store */
                    this.store.dispatch(new UpdateLocallyEngagementDetails(engagementId, updateEngagementDetails));
                    this.store.dispatch(new UpdateLocallyMyPortfolioEngagements(engagementId, updateEngagementDetails));
                }
                return response;
            });
    }

    /**
     * Updates the status of the given enagement Id via Patch request to the Project Management API
     * @param engagementId
     * @param engagementDetails
     */
    public updateStatus(engagementId: string, status: string): Promise<any> {
        const statusAction = { systemStatus: status };
        return this.dataService.patchData(`${this.wbsServiceV2BaseUri}/wbs/${engagementId}/status`,
            this.wbsSubscriptionKey, APIConstants.WbsEngStatusPatch, statusAction);
    }

    /**
     * Updates project details with the new data via Patch request to the Project Management API
     * @param projectDetails
     * @param projectId
     */
    public updateProjectDetails(projectDetails: IWbsEditProjectDetails, projectId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceBaseUri}/Projects/${projectId}`,
            this.wbsSubscriptionKey, APIConstants.WbsProjPatchRequest, projectDetails);
    }

    /**
     * Updates project details with the new data via Patch request to the Project Management API
     * @param projectDetails
     * @param projectId
     */
    public updateProjectDetailsV2(projectDetails: IWbsEditProjectDetailsV2, projectId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceV2BaseUri}/wbs/project/${projectId}`,
            this.wbsSubscriptionKey, APIConstants.WbsProjPatchRequest, projectDetails);
    }

    /**
     * Updates service details for a project with the new data via Patch request to the Project Management API
     * @param serviceDetails
     * @param projectId
     * @param serviceId
     */
    public updateServiceDetails(serviceDetails: IWbsEditServiceDetails, projectId: string, serviceId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceBaseUri}/Projects/${projectId}/Services/${serviceId}`,
            this.wbsSubscriptionKey, APIConstants.WbsServicePatchRequest, serviceDetails);
    }

    /**
     * Updates service details for a project with the new data via Patch request to the Project Management API
     * @param serviceDetails
     * @param serviceId
     */
    public updateServiceDetailsV2(serviceDetails: IWbsEditServiceDetails, serviceId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceV2BaseUri}/wbs/service/${serviceId}`,
            this.wbsSubscriptionKey, APIConstants.WbsServicePatchRequest, serviceDetails);
    }

    /**
     * Updates task details for a project/service via Patch request to the Project Management API
     * @param taskDetails
     * @param projectId
     * @param serviceId
     * @param taskId
     */
    public updateTaskDetails(taskDetails: IWbsEditTaskDetails, projectId: string, serviceId: string, taskId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceBaseUri}/Projects/${projectId}/Services/${serviceId}/Tasks/${taskId}`,
            this.wbsSubscriptionKey, APIConstants.WbsTaskPatchRequest, taskDetails);
    }

    /**
     * Updates task details via Patch request to the Project Management V2 API
     * @param taskDetails
     * @param taskId
     */
    public updateTaskDetailsV2(taskDetails: IWbsEditTaskDetails, taskId: string): Promise<any> {
        return this.dataService.patchData(`${this.wbsServiceV2BaseUri}/wbs/task/${taskId}`,
            this.wbsSubscriptionKey, APIConstants.WbsTaskPatchRequest, taskDetails);
    }

    /**
     * Adds a new Task for the project/service via Post request to the Project Management API
     * @param taskDetails
     * @param projectId
     * @param serviceId
     */
    public addTaskDetails(taskDetails: IWbsAddTaskDetails, projectId: string, serviceId: string): Promise<any> {
        return this.dataService.postData(`${this.wbsServiceBaseUri}/Projects/${projectId}/Services/${serviceId}/Tasks`, /* still using v1 */
            this.wbsSubscriptionKey, APIConstants.WbsTaskPostRequest, taskDetails)
            .then((response) => {
                /* if operation is successful make changes to store locally without refreshing entire store */
                const engId = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
                const currState = this.deliveryStates.filter((stateObj: IDeliveryState) => stateObj.state.toLowerCase() === taskDetails.status.toLowerCase())[0];

                /* Without refreshing the page, we want an "instant" result of adding the task.
                    As a result, once we know it has been added successfully from the API, we mock API response as if it was
                    returned as part of the engagement object and then pass it into the state to upadte locally.
                    We need to use this large object in order to be type compatible between  IWbsAddTaskDetails and ITaskDetailsV2*/
                const mockAPIResponse: ITaskDetailsV2 = {
                    chargeAccount: undefined,
                    chargeType: undefined,
                    chargeTypeValue: undefined,
                    costObject: undefined,
                    costObjectType: undefined,
                    description: taskDetails.description,
                    endDate: new Date(taskDetails.endDate),
                    id: response as string,
                    lastUpdatedBy: undefined,
                    lastUpdatedDate: undefined,
                    maxDate: undefined,
                    minDate: undefined,
                    name: taskDetails.name,
                    serviceId,
                    startDate: new Date(taskDetails.startDate),
                    statusCode: taskDetails.status,
                    statusDescription: currState ? currState.displayText : undefined,
                    userRequestedEbsState: undefined,
                    userStatusCode: undefined,
                    userStatusDescription: undefined,
                    workPackageDescription: undefined,
                    workPackageType: undefined,
                    currentStatus: undefined,
                    currentStatusCode: undefined
                };

                this.store.dispatch(new UpdateLocallyAddTaskDetails(engId, projectId, serviceId, mockAPIResponse));
                this.store.dispatch(new InvalidateEngagementDetails(engId));
                this.store.dispatch(new LoadEngagementDetails(engId));
                return response;
            });
    }

    /**
     * Validate Start and End Dates before updating an engagement/project/service/task.
     *
     * @param {string} wbsId
     * @param {IDateValidationRequest} dateValidationRequest
     * @returns
     * @memberof WBSService
     */
    public validateWbsDates(dateValidationRequest: IDateValidationRequest, wbsId: string): Promise<IDateValidationResult> {
        const validateDateUrl: string = `${this.wbsServiceV2BaseUri}/wbs/${wbsId}/validate/date`;
        return this.dataService.postData(validateDateUrl, this.wbsSubscriptionKey, APIConstants.WbsValidateDate, dateValidationRequest);
    }

    /**
     * get Exchange rate for provided from and to currencies
     *
     * @param {string} fromCurrency
     * @param {string} toCurrency
     * @param {string} engagementStartDate
     * @returns
     * @memberof WBSService
     */
    public getExchangeRate(fromCurrency: string, toCurrency: string, engagementStartDate: string): Promise<IExchangeRate> {
        const getExchangeUrl: string = `${this.wbsServiceV2BaseUri}/wbs/exchangeRate?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}&engagementStartDate=${engagementStartDate}`;
        return this.dataService.getData(getExchangeUrl, this.wbsSubscriptionKey, APIConstants.WbsValidateDate);
    }

    /**
     * Get engagement billing details of passed wbsId
     * @param {string} wbsId
     * @returns {EngagementBillStatusDetails}
     * @memberof WBSService
     */
    public GetEngagementBillStatusDetails(wbsId: string): Promise<EngagementBillStatusDetails> {
        const url: string = `${this.wbsServiceV2BaseUri}/wbs/wbsBillStatusDetails?wbsId=${wbsId}`;
        return this.dataService.getData(url, this.wbsSubscriptionKey, APIConstants.WbsGetEngagementBillStatusDetails);
    }

    /**
     * Get engagement billing details of passed wbsId
     * @param {string} wbsId
     * @returns {WbsETMDetails}
     * @memberof WBSService
     */
    public GetEngagementETMAndRunFFSeqDetails(wbsId: string): Promise<WbsETMDetails> {
        const url: string = `${this.wbsServiceV2BaseUri}/wbs/wbsRecoETMDetails?wbsId=${wbsId}`;
        return this.dataService.getData(url, this.wbsSubscriptionKey, APIConstants.WbsGetEngagementETMAndRunFFSeqDetails);
    }

    /**
     * Compares the given start and end dates. Returns true if the dates have changed.
     * Note that the original date is a Date object, and the second date is a MomentInputObject. Todo
     * update this function if we ever improve the date type problem.
     * @param originalDate
     * @param newDate
     */
    public haveDatesChanged(originalDate: Date, newDate: Date): boolean {
        return !moment(originalDate).isSame(newDate);
    }

    /**
     * Pushes the given value to the given array if the value evaluates as truthy.
     * (If the value is not null or undefined, false, or the number 0.)
     * Modifies the given array by reference, and does not need to return.
     * @param array
     * @param value
     */
    public pushToArrayIfTrue(array: IChangedProperties[], value: IChangedProperties): void {
        if (value) {
            array.push(value);
        }
    }

    /**
     * If the given objects are different, creates and returns a changed property object.
     * Returns undefined if the objects have not changed.
     * @param attributeName
     * @param originalValue
     * @param updatedValue
     */
    public createChangedPropertyObject(attributeName: string, originalValue: string, updatedValue: string): IChangedProperties {
        if (originalValue !== updatedValue) {
            return {
                name: attributeName,
                oldValue: originalValue,
                newValue: updatedValue
            };
        }
        return undefined;
    }


    /**
     * Creates a log string from the given changed properties: combines all the changed attribute names into a single, comma seperated string.
     * @param changedProperties
     */
    public createLogStringFromChangedProperties(changedProperties: IChangedProperties[]): string {
        let logString: string = "";
        changedProperties.forEach((property, index) => {
            logString += property.name;
            if (index !== changedProperties.length - 1) {
                logString += ",";
            }
        });
        return logString;
    }
    
    /**
     * Gets Service fulfillment dates
     * @param taskDetails
     * @param taskId
     */
    public getServiceProductFulfillmentDate(serviceId: string): Promise<IServiceProductFulfillmentDate> {
        const getServiceProductFulfilmentDateUrl: string = `${this.wbsServiceV2BaseUri}/wbs/${serviceId}/servicedetails`;
        return this.dataService.getData(getServiceProductFulfilmentDateUrl, this.wbsSubscriptionKey, APIConstants.GetServiceProductFulfilmentDates);
    }
    
    /**
     * Update Service fulfillment dates
     * @param taskDetails
     * @param taskId
     */
    public updateServiceProductFulfillmentDate(serviceProductFulfillment: IServiceProductFulfillmentDateUpdateRequest, serviceId: string): Promise<any> {
        const getServiceProductFulfilmentDateUrl: string = `${this.wbsServiceV2BaseUri}/wbs/${serviceId}/servicedetails`;
        return this.dataService.postData(getServiceProductFulfilmentDateUrl, this.wbsSubscriptionKey, APIConstants.UpdateServiceProductFulfillmentDates, serviceProductFulfillment);
    }
}
