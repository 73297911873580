import { OnInit, OnDestroy } from "@angular/core";
import { ILoadableState } from "../../store/reducers";
import { IComponent, IChildComponent, ComponentPrefix } from "../application.constants";
import { DMLoggerService } from "../services/dmlogger.service";
import { DataService } from "../services/data.service";

/* Since this is an abstract component, it does not need the @Component annotation. */
export abstract class DmComponentAbstract implements OnInit, OnDestroy {
    public isComponentLoading: boolean = false;
    public showLoading: boolean = true;
    public errorText: string = "";
    private subscriptions: any[] = []; // todo change any[] to ()=>void
    private isInit: boolean = true;

    public constructor(
        protected dmLogger: DMLoggerService,
        protected component: IComponent,
        protected children?: IChildComponent[]
    ) {
        this.dmLogger = dmLogger;
        this.component = component;
        this.startLoader();
    }

    public abstract ngOnInit(): void;

    /**
     * Garbage collects any active subscriptions when the
     * page is destroyed on the Angular lifecycle hook.
     */
    public ngOnDestroy(): void {
        /* Unsubscribes from all events we subscribed to */
        this.subscriptions.forEach(
            (unsubscribe) => unsubscribe && unsubscribe()
        );
    }

    /**
     * Subscribes to the given event. By subscribing in this way, the DmComponentAbstract
     * will automatically handle the destroy event on page close to avoid memory leaks.
     * @param {*} events
     * @memberof DmComponentAbstract
     */
    public subscribe(...events: any[]): void {
        this.subscriptions.push(...events);
    }

    /**
     * Starts the loader if the component is not currently loading.
     * This will also start the telemetry timer for component load.
     * (Still a WIP)
     * @memberof DmComponentAbstract
     */
    public startLoader(): void {
        if (this.isInit) {
            this.isInit = false;
        }
        if (!this.isComponentLoading) {
            this.startComponentLoad();
            this.isComponentLoading = true;
        }
    }

    /**
     * Ends the loader for the component if the component is currently loading.
     * Ends the component load telemetry that was tracking the load time.
     * (Still a WIP)
     * @memberof DmComponentAbstract
     */
    public endLoader(): void {
        if (this.isComponentLoading) {
            this.isComponentLoading = false;
            this.endComponentLoad();
        }
    }

    /**
     * Sets the loaders for the component in the UI based on the state of the given items.
     * If the items are loading, then the loaders will be started/continue running.
     * If the items are not loading, the loaders will end.
     *
     * @param {...ILoadableState[]} items
     * @memberof DmComponentAbstract
     */
    public setLoadersBasedOnItemState(...items: ILoadableState[]): void {
        if (this.isItemLoading(...items)) {
            this.startLoader();
        } else {
            this.endLoader();
        }
    }

    /**
     * Sets the loaders for the component in the UI based on the state of the given items.
     * If the items are loading, then the loaders will be started/continue running.
     * If the items are not loading, the loaders will end.
     * This WILL NOT track performance
     *
     * @param {...ILoadableState[]} items
     * @memberof DmComponentAbstract
     */
    public setLoadersBasedOnItemStateCosmetic(
        ...items: ILoadableState[]
    ): void {
        if (this.isItemLoading(...items)) {
            this.isComponentLoading = true;
        } else {
            this.isComponentLoading = false;
        }
    }

    /**
     * Sets the error message for the component based on the state of the given items.
     * Error message will be retrieved from item.error.
     * This function will then use the existing dmLoader to set the error message in the UI.
     *
     * @param {...ILoadableState[]} items
     * @returns {void}
     * @memberof DmComponentAbstract
     */
    public setErrorsBasedOnItemState(...items: ILoadableState[]): void {
        if (!items || !items.length) {
            return;
        }
        let error: string = "";
        for (const item of items) {
            if (!item) {
                return;
            }
            error = error || item.error;
        }
        if (error) {
            this.errorText = error;
            this.isComponentLoading = true;
            this.showLoading = false;
        }
    }

    /**
     * Takes in a collection of loadableStates and checks if they are currently
     * loading in order to set the loading flags.
     *
     * @param {...ILoadableState[]} items
     * @returns {boolean}
     * @memberof DmComponentAbstract
     */
    public isItemLoading(...items: ILoadableState[]): boolean {
        if (!items || !items.length) {
            return false;
        }
        for (const item of items) {
            if (!item) {
                return true;
            }
            if (item.loading || (!item.loaded && !item.error)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Given a list of ILoadableState items, determine if the items have been invalided.
     * If the items have been invalidated, call the internal refresh functionality in order to
     * start logging loading telemetry.
     * The boolean return is in question to "are the items invalidated?" Currently not in use but as an easy reference.
     * Return true if items are all invalid.
     * Return false if not all items are invalid; do not take other action if returning false;
     *
     * @param {...ILoadableState[]} items
     * @returns {boolean}
     * @memberof DmComponentAbstract
     */
    public refreshOnItemInvalidation(...items: ILoadableState[]): boolean {
        if (this.isInit || !items || !items.length) {
            return false;
        }
        for (const item of items) {
            if (!item) {
                return false;
            }
            if (item.loaded || item.loading || item.error) {
                // If any of the properties are truthy, then the item still has data/is in progress,
                // and therefore has not been invalidated, so we return false;
                return false;
            }
        }
        this.refresh();
        return true;
    }

    /**
     * Logs a component error to the dm logger, which pushes it to the FXP telemetry App Insights exceptions table.
     *
     * @param {*} error
     * @param {*} [errorCode]
     * @memberof DmComponentAbstract
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public logError(methodName: string, error: any, errorCode?: any, errorSeverity?: any): void {
        this.dmLogger.logError(ComponentPrefix + this.component.userFriendlyName, methodName, error, errorCode, undefined, undefined, DataService.getCorrelationIdFromError(error), errorSeverity);
    } 

    /**
     * Start Business process track telemetry 
     * @param taskName Business process task name
     */
    public startBusinessProcessTelemetry(taskName: string): void {
        this.dmLogger.startBusinessTaskTelemetryTimer(taskName);
    }

    /**
     * End Business process track telemetry 
     * @param taskName Business process task name
     */
    public endBusinessProcessTelemetry(taskName: string): void {
        this.dmLogger.endBusinessTaskTelemetryTimer(taskName);
    }

    /**
     * Starts the timer for the component loading.
     */
    protected startComponentLoad(): void {
        this.dmLogger.startComponentLoadTelemetryTimer(
            this.component,
            this.children
        );
    }

    /**
     * Adds a new child component to the current component. The child
     * component was not available when the children were first defined.
     * @param childComponentName
     */
    protected addNewChildComponent(childComponent: IComponent, isCritical? : boolean): void {
        this.dmLogger.addNewChildComponentToLoadingTelemetry(
            this.component,
            {
                component: childComponent,
                isCritical
            }
        );
    }

    /**
     * Ends the timer for the component loading, logs telemetry.
     */
    protected endComponentLoad(): void {
        this.dmLogger.endComponentLoadTelemetryTimer(
            this.component.userFriendlyName
        );
    }

    /**
     * Removes an existing child component from the current component. The child
     * component was initially going to be loaded but is now being cancelled.
     * @param childComponentName
     */
    protected removeChildComponent(childComponent: IComponent): void {
        this.dmLogger.removeChildComponentFromLoadingTelemetry(
            this.component,
            childComponent.userFriendlyName
        );
    }

    /**
     * Called when the internal refresh is activated. Activates the loaders and telemetry, toggling the
     * 'endSelf' boolean so that the component is responsible for its own endLoader.
     *
     * @private
     * @memberof DmComponentAbstract
     */
    private refresh(): void {
        this.startLoader();
    }
}
