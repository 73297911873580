<dm-tile [tileHeaderContent]="tileContent" [hasNonOverflowContent]="true">
  <section class="dm-p-imp-0 financial-nav" id="financial-desktop">
    <div *ngIf="!isMobileView" class="financial-nav-items">
      <ul class="nav nav-tabs" role="tablist" *ngIf="showAmendmentSnapshotTabs">
        <li role="presentation" class="font-caption-alt item"
          [ngClass]="{'active':isSelectedTab(TabNames.FinancialForecast)}">
          <button type="button" id="financialForecastBtn" role="tab"
            [attr.aria-selected]="isSelectedTab(TabNames.FinancialForecast)" data-toggle="tab"
            (click)="setTab(TabNames.FinancialForecast)" (keyup.arrowright)="focus('snapshotBtn')">
            Financial Forecast
          </button>
        </li>
        <li *ngIf="isDesktopView" role="presentation" class="font-caption-alt item"
          [ngClass]="{'active':isSelectedTab(TabNames.Snapshot)}">
          <button type="button" id="snapshotBtn" role="tab"
            [attr.aria-selected]="isSelectedTab(TabNames.Snapshot)" data-toggle="tab"
            (click)="setTab(TabNames.Snapshot)" (keyup.arrowright)="focus('amendmentstab')"
            (keyup.arrowleft)="focus('financialForecastBtn')">
            Snapshots ({{snapShotCount }})
          </button>
        </li>
        <li role="presentation" class="font-caption-alt item" [ngClass]="{'active':isSelectedTab(TabNames.Amendments)}">
          <button id="amendmentstab" type="button" role="tab"
            [attr.aria-selected]="isSelectedTab(TabNames.Amendments)" data-toggle="tab"
            (click)="setTab(TabNames.Amendments)" (keyup.arrowleft)="focus('snapshotBtn')">
            <span *ngIf="hasMisalignedAmendments" class="icon icon-close icon-rounded dm-error"></span> <!--todo set this value-->
            Amendments ({{countOfAmendments }})
            <span *ngIf="isAnyAmendmentMisaligned()"
              class="icon icon-close icon-rounded dm-error" title="There is a misalignment in one/more Change Requests">
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div role="tabpanel" class="tabfade" *ngIf="isSelectedTab(TabNames.FinancialForecast)">
        <dm-financial-plan class="dm-tile__componentflex"></dm-financial-plan>
      </div>
      <div role="tabpanel" class="tabfade" *ngIf="isDesktopView && isSelectedTab(TabNames.Snapshot)">
        <dm-snapshot></dm-snapshot>
      </div>
      <div role="tabpanel" class="tabfade dm-amendments-tabpanel"
        *ngIf="showAmendmentSnapshotTabs && isSelectedTab(TabNames.Amendments)">
        <dm-amendments></dm-amendments>
      </div>
    </div>
  </section>
</dm-tile>