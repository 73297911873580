import { Component, forwardRef, Inject, Injector } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpEventBroadCastService, FxpMessageService, SettingsServiceProvider, UserInfoService } from "@fxp/fxpservices";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";
import { AdvancedSearchFilterModalComponent } from "./modals/filter-modal/advanced-search-filter-modal.component";
import { Components, LogEventConstants, RouteName, SourceConstants, UserPreferenceConstants, TooltipText, SearchAttribute, AccessibilityConstants } from "../../common/application.constants";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DelegationDetailsModalComponent } from "../tiles/delegation-details-modal/delegation-details-modal.component";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { DataService } from "../../common/services/data.service";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { IContractType, IDelegationObject, IEngagementSearchInputObject, IEngagementSearchResult, IProjectSearchResult, ISearchResultsObject } from "../../common/services/contracts/project.service.contracts";
import { IDelegationDetailsV2, IDelegationEntity } from "../../common/services/contracts/delegation.v2.service.contracts";
import { IPinnedEntities, IEngagementList } from "../../common/services/contracts/portfolio.model";
import { ISelectedUserAttributes } from "../tiles/type-ahead/type-ahead-contracts";
import { IState } from "../../store/reducers";
import { ProjectService } from "../../common/services/project.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import moment from "moment";
import { getMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.selector";
import { IMyPortfolioEngagementListState } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.reducer";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../common/error.constants";

declare const Fxp: any;

@Component({
    selector: "dm-advanced-search",
    templateUrl: "./advanced-search.html",
    styleUrls: ["./advanced-search.scss"]
})
export class AdvancedSearchComponent extends DmComponentAbstract {
    
    public statusList: string[] = ["Released", "Created", "Closed", "Technically Completed"];
    public quickSearch: string = "";
    public isAdvancedSearch: boolean = false;
    public typeAheadLabelText: string = "";
    public typeAheadId: string = "pjmName";
    public searchAriaLabel: string = "Search";
    public cancelAriaLabel: string = "Cancel";
    public requiredMsg: string = "";
    public noResults: string = "No Results";
    public selectedUser: ISelectedUserAttributes;
    public isStartDatePopup: boolean = false;
    public isEndDatePopup: boolean = false;
    public isShowResults: boolean = false;
    public typeOfResources: IContractType[];
    public isShowSearchResults: boolean = false;
    public projectName: string = "";
    public proEngId: string = "";
    public opportunityId: string = "";
    public engagementName: string = "";
    public customerName: string = "";
    public statusSelected: string = "";
    public filterStartDate: Date;
    public filterEndDate: Date;
    public isShowFilterResults: boolean = true;
    public errorSearchResultsText: string = "Error text";
    public showLoadingSearch: boolean = false;
    public isLoadingSearch: boolean = false;
    public showLoadingAdvancedSearch: boolean = false;
    public portfolioItemsDisp: number = 5;
    public currentPage: number = 1;
    public fromAdvSer: boolean = false;
    public advancedSearchResult: boolean = true;
    public unfilteredSearchResults: ISearchResultsObject;
    public searchResultsViewModel: ISearchResultsObject = { engagementDetails: [], confidentialEngagementsCount: 0 };
    public isPubSecEnabled: boolean;
    public isQuickSearchEnabled: boolean = true;
    public slicedItemsNumber: number = 0;
    public gridItemsDisplay: number = 5;
    public portfolioDataLengthAfterFilter: number = 0;
    public searchText: string = "SEARCH RESULTS";
    public showQuickSearchValidationMsg: boolean = false;
    public projectCount: number;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public tooltipText: string = TooltipText.EBSState;
    public initialStartDate: Date;
    public initialEndDate: Date;
    public initialStatus: string = "";
    public tableCaption: string = "Search Results";
    public showBackToQuickSearch: boolean = true;
    public tileContent: ITile;    
    public advancedSearchErrorMessages = DmError.AdvancedSearch;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.KeyActions;
    public accessibilityConstants = AccessibilityConstants;
    private minDatePlaceholder: Date;
    private maxDatePlaceholder: Date;
    private pinnedObjects: IPinnedEntities = { engagementId: [], projectId: [] };
    private delegationList: IDelegationDetailsV2[];
    private FXP_CONSTANTS = FxpConstants;
    private currentUserBusinessDomain: string;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => SettingsServiceProvider)) private settingsService: SettingsServiceProvider,
        @Inject(forwardRef(() => FxpEventBroadCastService)) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(ConfigManagerService) private configmanagerService: ConfigManagerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMAuthorizationService) private dmAuthService: DMAuthorizationService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector
    ) {
        super(dmLogger, Components.AdvancedSearch);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus("projectName", true);
        this.currentUserBusinessDomain = this.fxpUserInfoService.getCurrentUserData().businessDomain;
        this.tileContent = {
            title: "Search"
        };
        this.configmanagerService.initialize()
            .then(() => {
                this.typeOfResources = this.configmanagerService.getValue<IContractType[]>("projEngContractType");
                this.isPubSecEnabled = this.configmanagerService.isFeatureEnabled("Pubsec");
            });
        this.setLoadersBasedOnItemState();
        if (this.stateService.current.name.startsWith(RouteName.Search) && this.stateService.params && this.stateService.params.searchText && this.stateService.params.searchAttribute) {
            this.isAdvancedSearch = true;
            this.showBackToQuickSearch = false;
            switch (this.stateService.params.searchAttribute) {
                case SearchAttribute.EngagementName:
                    this.engagementName = this.stateService.params.searchText;
                    break;
                case SearchAttribute.EngagementId:
                    this.proEngId = this.stateService.params.searchText;
                    break;
                case SearchAttribute.ProjectId:
                    this.proEngId = this.stateService.params.searchText;
                    break;
                default:
                    break;
            }
            this.advancedSearchList();
        } else if (this.stateService.current.name.startsWith(RouteName.Search) && this.stateService.params && this.stateService.params.isAdvancedSearch) {
            this.isAdvancedSearch = false;
            this.showBackToQuickSearch = false;
            this.showHideAdvancedSearch();
        }
    }

    /**
     * Changes the current page, grid items to display and sliced number on changing the page
     * @param currentPage
     */
    public currentPageChangedHandler(currentPage: number): void {
        if (this.currentPage < currentPage) {
            this.slicedItemsNumber = this.gridItemsDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay + this.portfolioItemsDisp;
        } else {
            this.slicedItemsNumber = this.slicedItemsNumber - this.portfolioItemsDisp;
            this.gridItemsDisplay = this.gridItemsDisplay - this.portfolioItemsDisp;
        }
        this.currentPage = currentPage;
    }

    /**
     * Resets the Quick Search, removing the search text and the search results.
     */
    public clearText(): void {
        this.quickSearch = "";
        this.isShowResults = false;
        this.showQuickSearchValidationMsg = false;
        this.sharedFunctionsService.focus("quickSearch", false);
    }

    /**
     * Clears the given text field of search input
     */
    public clearTextField(filed: string): void {
        switch (filed) {
            case "projectName": {
                this.projectName = "";
                this.sharedFunctionsService.focus(filed, false);
                break;
            }
            case "engagementName": {
                this.engagementName = "";
                this.sharedFunctionsService.focus(filed, false);
                break;
            }
            case "proEngId": {
                this.proEngId = "";
                this.sharedFunctionsService.focus(filed, false);
                break;
            }
            case "opportunityId": {
                this.opportunityId = "";
                this.sharedFunctionsService.focus(filed, false);
                break;
            }
            case "customerName": {
                this.customerName = "";
                this.sharedFunctionsService.focus(filed, false);
                break;
            }
            default:
                break;
        }
    }

    /**
     * Toggles advanced search and quick search
     */
    public showHideAdvancedSearch(): void {
        if (!this.isAdvancedSearch) {
            this.isAdvancedSearch = true;
            this.quickSearch = "";
            this.showQuickSearchValidationMsg = false;
            this.sharedFunctionsService.focus("projectName", true);
        } else {
            this.isAdvancedSearch = false;
            this.sharedFunctionsService.focus("advancedSearch", true);
        }
    }

    /**
     * Opens the date picker for the start date filter selection
     */
    public startDateOpen(): void {
        this.isStartDatePopup = true;
        this.isEndDatePopup = false;
    }

    /**
     * Opens the date picker for the end date filter selection
     */
    public endDateOpen(): void {
        this.isEndDatePopup = true;
        this.isStartDatePopup = false;
    }

    /**
     * Activates the Quick Search and checks to get search results
     */
    public quickSearchApply(): void {
        this.isLoadingSearch = true;
        this.unfilteredSearchResults = { engagementDetails: [], confidentialEngagementsCount: 0 };
        this.searchResultsViewModel = { engagementDetails: [], confidentialEngagementsCount: 0 };
        this.resetSearch();
        this.statusSelected = "";
        this.showQuickSearchValidationMsg = false;
        this.isShowSearchResults = false;
        const quickSearchTrimmed = this.quickSearch ? this.quickSearch.trim() : "";
        if (quickSearchTrimmed.length >= 3) {
            this.isShowResults = true;
            const searchInput: IEngagementSearchInputObject = {};
            if (this.isInputEngagementId(quickSearchTrimmed)) {
                searchInput.engagementId = quickSearchTrimmed;
            } else if (this.isInputProjectId(quickSearchTrimmed)) {
                searchInput.projectId = quickSearchTrimmed;
            } else { // All other valid inputs are project names
                searchInput.projectName = quickSearchTrimmed;
            }
            if (!this.displayDataFromPortFolio(searchInput)) {
                this.populateSearchResults(searchInput);
            }
        } else {
            this.isLoadingSearch = false;
            this.showQuickSearchValidationMsg = true;
            this.isShowResults = false;
        }
        this.tableCaption = "Search Results";
    }

    /**
     * Called when a user activates the advanced search. Takes the given input, determines which
     * search inputs to send, and then populates the search results.
     */
    public advancedSearchList(): void {
        this.unfilteredSearchResults = { engagementDetails: [], confidentialEngagementsCount: 0 };
        this.searchResultsViewModel = { engagementDetails: [], confidentialEngagementsCount: 0 };
        this.searchText = "SEARCH RESULTS";
        this.statusSelected = "";
        this.isLoadingSearch = true;

        this.advancedSearchResult = false;
        this.fromAdvSer = true;
        this.currentPage = 1;

        const searchInput: IEngagementSearchInputObject = {
            engagementId: undefined,
            projectName: this.projectName || undefined,
            projectId: undefined,
            ppjmOrPjmBpid: (this.selectedUser && this.selectedUser.userName && this.selectedUser.bpId) ? this.selectedUser.bpId : undefined,
            customerName: this.customerName || undefined,
            opportunityId: this.opportunityId || undefined,
            engagementName: this.engagementName || undefined
        };

        const proEngIdTrimmed = this.proEngId ? this.proEngId.trim() : "";
        if (proEngIdTrimmed.length >= 3) {
            this.isShowResults = true;
            if (this.isInputEngagementId(proEngIdTrimmed)) {
                searchInput.engagementId = proEngIdTrimmed;
            } else if (this.isInputProjectId(proEngIdTrimmed)) {
                searchInput.projectId = proEngIdTrimmed;
            } else {
                this.isShowResults = false;
                return;
            }
        }
        if (!this.displayDataFromPortFolio(searchInput)) {
            this.populateSearchResults(searchInput);
        }
        this.tableCaption = "Search Results";
    }

    /**
     * Sets the selected user of the search component to be the user retreived from the type ahead callback.
     * @param user
     */
    public setSelectedUser(user: ISelectedUserAttributes): void {
        this.selectedUser = user;
    }
    /*
    * Reset all search fields used in Advanced Search
    */
    public resetSearch(): void {
        this.engagementName = "";
        this.projectName = "";
        this.selectedUser = undefined;
        this.customerName = "";
        this.proEngId = "";
        this.opportunityId = "";
        this.sharedFunctionsService.focus("projectName", false);
    }

    /**
     * Should the apply filters button be disabled?
     * Returns true if the button should be disabled (if the filter is missing the start or end date.)
     * Returns false if the button should be enabled.
     */
    public applyFiltersButtonDisabled(): boolean {
        return !this.filterStartDate || !this.filterEndDate;
    }

    /**
     * Activates the filters on the desktop and tablet views. Filters the search results based on the input to the filters on the template.
     */
    public applyFilter(): void {
        if (this.applyFiltersButtonDisabled()) {
            return;
        }
        this.searchText = "FILTERED RESULTS";
        this.showLoadingSearch = true;
        this.isLoadingSearch = true;
        this.searchResultsViewModel = Object.assign({}, this.unfilteredSearchResults);
        this.filterSearchResults(this.statusSelected, this.filterStartDate, this.filterEndDate);
        this.resetCurrentPage();
        this.showLoadingSearch = false;
        this.isLoadingSearch = false;
        this.setTableCaption();
    }

    /**
     * Resets the filters and clears out all values
     */
    public resetFilter(): void {
        this.searchText = "SEARCH RESULTS";
        this.showLoadingSearch = true;
        this.isLoadingSearch = true;
        this.statusSelected = "";
        this.filterStartDate = this.minDatePlaceholder;
        this.filterEndDate = this.maxDatePlaceholder;
        this.initialStartDate = this.minDatePlaceholder;
        this.initialEndDate = this.maxDatePlaceholder;
        this.initialStatus = "";
        this.searchResultsViewModel = Object.assign({}, this.unfilteredSearchResults);
        this.projectCount = this.getProjectCount(this.searchResultsViewModel);
        // this.setUserPreferences();
        this.resetCurrentPage();
        this.showLoadingSearch = false;
        this.isLoadingSearch = false;
        this.isShowFilterResults = true;
        this.sharedFunctionsService.focus("dm-invoice-services-ddl", false);
    }

    /**
     *  Open delegation modal for more details
     */
    public openDelegationModal(delegation: IDelegationObject, delegatedType: string): void {
        const modalRef = this.modalService.open(DelegationDetailsModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });

        modalRef.componentInstance.delegationDetails = delegation;
        modalRef.componentInstance.delegationType = delegatedType;
    }

    /**
     * Highlights the searched text in the responses.
     * This returns an HTML tag and MUST be used inside an [innerHTML] binding.
     * Returning inside a [textContent] binding or via {{interpolation}} will print the actual tag
     * instead of printing it as the representative HTML.
     * @param haystack
     * @param needle
     */
    public highlightText(haystack: string, needle: string): string {
        if (!needle) {
            return haystack;
        }
        if (needle && haystack) {
            needle = needle.replace(/[-[]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            return haystack.replace(new RegExp(needle, "gi"), (match) => {
                return "<span class='highlightText'>" + match + "</span>";
            });
        }
    }

    /**
     * Opens the filter modal for selecting filters on a modal view. Closing the modal and applying the filters will return
     * the newly selected filters to thsi component. Closing the modal without applying the filters will not impact this component.
     */
    public openFilterModal(): void {
        const modalRef: NgbModalRef = this.modalService.open(AdvancedSearchFilterModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal dm-advanced-search-filter in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.statusSelected = this.statusSelected;
        modalRef.componentInstance.filterStartDate = this.filterStartDate;
        modalRef.componentInstance.filterEndDate = this.filterEndDate;

        /* On modal close with values, set the filters.*/
        modalRef.result
            .then((resolveObject: { statusSelected: string; filterStartDate: Date; filterEndDate: Date }) => {
                this.statusSelected = resolveObject.statusSelected;
                this.filterStartDate = resolveObject.filterStartDate;
                this.filterEndDate = resolveObject.filterEndDate;
                this.filterSearchResults(resolveObject.statusSelected, resolveObject.filterStartDate, resolveObject.filterEndDate);
            })
            .catch(() => {
                /* Captures the modal dismiss, do nothing. */
            });
    }

    /**
     * Pin an entity, either a project or an internal engagement
     * @param entity
     * @param entityType
     */
    public pinEntity(entity: IEngagementSearchResult | IProjectSearchResult, entityType: string): void {
        if (entityType === "project") {
            this.pinnedObjects.projectId.push((entity as IProjectSearchResult).projectId);
        } else {
            this.pinnedObjects.engagementId.push(entity.engagementId);
        }
        const strUserPreferences = JSON.stringify(this.pinnedObjects);

        this.settingsService.saveSettings(
            Fxp.Common.SettingsType.User,
            this.fxpUserInfoService.getCurrentUser(),
            UserPreferenceConstants.MyPortfolioProjects,
            strUserPreferences
        )
            .then(() => {
                this.fxpBroadcastService.broadCast("dm.New_Entity_Pin", {});
                if (entityType === "project") {
                    this.fxpMessageService.addMessage("Project added to favorite list.", this.FXP_CONSTANTS.messageType.success);
                    this.dmLogger.logEvent(SourceConstants.Component.SearchPage, SourceConstants.Method.PinEntity, LogEventConstants.PinProject);
                } else {
                    this.fxpMessageService.addMessage("Engagement added to favorite list.", this.FXP_CONSTANTS.messageType.success);
                    this.dmLogger.logEvent(SourceConstants.Component.SearchPage, SourceConstants.Method.PinEntity, LogEventConstants.PinEngagement);
                }
                this.updateEntityPinning(entity, entityType, true);
            });
    }

    /**
     * Unpin any pinned entity
     * @param entity
     * @param entityType
     */
    public unpinEntity(entity: IEngagementSearchResult | IProjectSearchResult, entityType: string): void {
        if (entityType === "project") {
            this.pinnedObjects.projectId.splice(this.pinnedObjects.projectId.indexOf((entity as IProjectSearchResult).projectId), 1);
        } else {
            this.pinnedObjects.engagementId.splice(this.pinnedObjects.engagementId.indexOf(entity.engagementId), 1);
        }
        const strUserPreferences = JSON.stringify(this.pinnedObjects);
        this.settingsService.saveSettings(
            Fxp.Common.SettingsType.User,
            this.fxpUserInfoService.getCurrentUser(),
            UserPreferenceConstants.MyPortfolioProjects,
            strUserPreferences
        ).then(() => {
            this.fxpMessageService.addMessage(entityType + " removed from favorite list.", this.FXP_CONSTANTS.messageType.success);
            this.fxpBroadcastService.broadCast("dm.New_Entity_Pin", {});
            if (entityType === "project") {
                this.dmLogger.logEvent(SourceConstants.Component.SearchPage, SourceConstants.Method.UnpinEntity, LogEventConstants.UnpinProject);
            } else {
                this.dmLogger.logEvent(SourceConstants.Component.SearchPage, SourceConstants.Method.UnpinEntity, LogEventConstants.UnpinEngagement);
            }
            this.updateEntityPinning(entity, entityType, false);
        });
    }

    /**
     * Checks if the search button should be disabled based on form input.
     * If the data is invalid, the search button should be disabled and the function will return true.
     * Function will return false if the search button should not be disabled (is enabled).
     */
    public searchButtonDisabled(): boolean {
        if (this.selectedUser && this.selectedUser.userName) {
            if (!this.projectName && !this.engagementName && !this.selectedUser.userAlias &&
                !this.customerName && !this.opportunityId && !this.proEngId.trim()) {
                return true;
            } else if (!this.selectedUser.userAlias) {
                return true;
            } else if (!this.validateProjEngId()) {
                return true;
            } else {
                return false;
            }
        } else {
            if (!this.projectName && !this.engagementName &&
                !this.customerName && !this.opportunityId && !this.proEngId.trim() &&
                (!this.selectedUser || (this.selectedUser && !this.selectedUser.userAlias))
            ) {
                return true;
            } else if (!this.validateProjEngId()) {
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     * Toggles visibility to go back to viewing the advanced search results. Used in mobile view.
     */
    public backSearchAdvanceResult(): void {
        this.advancedSearchResult = true;
        this.isShowResults = false;
    }

    /**
     * Checks the validity of the quicksearch input; is the Project/Engagement ID valid or not
     */
    public validateProjEngId(): boolean {
        const proEngIdTrimmed = this.proEngId ? this.proEngId.trim() : "";
        if (proEngIdTrimmed.length >= 3) {
            return this.isInputEngagementId(proEngIdTrimmed) || this.isInputProjectId(proEngIdTrimmed);
        }
        return true;
    }

    /**
     * Set the start date to user picked date
     */
    public setStartDate(newDate: Date): void {
        this.filterStartDate = newDate;
    }

    /**
     * Set the end date to user picked date
     */
    public setEndDate(newDate: Date): void {
        this.filterEndDate = newDate;
    }

    /**
     * Returns the word with +s at the end if the count is greater than 1
     * @param word
     * @param count
     * @param caps
     */
    public getWordPluralWithS(word: string, count: number, caps: boolean): string {
        return this.sharedService.getWordPluralWithS(word, count, caps);
    }

    /**
     * Reset current page and updates slicedItemNubmer and gridItemsDisplay
     */
    public resetCurrentPage(): void {
        this.currentPage = 1;
        this.slicedItemsNumber = 0;
        this.gridItemsDisplay = 5;
    }

    /**
     * Close Engagement/Project Dropdown On Tab of Last Element
     *
     */
    public closeDropdownOnTab(id: string): void {
        document.getElementById(id).classList.remove("open");
    }

    /**
     * Make dropdowns list items accessibile with Keyboard Up/Down Arrow Keys for both Engagement and Project
     * @param type 
     * @param Id 
     * @param action 
     */
    public moveFocus(type: string, id: string, action: string): void {
        const dropdownId = "dm-grid-" + type + "-moreoptions" + id + action;
        this.sharedFunctionsService.focus(dropdownId, true);
    }

    /**
     * Is the given input a Project ID?
     * Checks for 1 C or I, then a period, then 10 digits, another period, and then 6 digits
     * @param input
     */
    private isInputProjectId(input: string): boolean {
        const projIdPattern = /^[ciCI]\.[0-9]{10}\.[0-9]{6}$/;
        return projIdPattern.test(input);
    }

    /**
     * Is the given input an Engagement ID?
     * Checks for 1 C or I, then period, then 10 digits
     * @param input
     */
    private isInputEngagementId(input: string): boolean {
        const engIdPattern = /^[ciCI]\.[0-9]{10}$/;
        return engIdPattern.test(input);
    }

    /**
     * Calls the search API and gets the search results.
     * @param input
     */
    private populateSearchResults(input: IEngagementSearchInputObject): void {
        this.isShowResults = true;
        this.isShowFilterResults = true;
        this.showLoadingSearch = true;

        let currentMinDate = new Date();
        let currentMaxDate = new Date();

        if (this.deviceFactory.isMobile()) {
            this.isAdvancedSearch = false;
        }

        this.projectService.searchEngagements(input)
            .then((response: ISearchResultsObject) => {
                this.logSearchEventForDmLogger(input);
                this.unfilteredSearchResults = response;
                this.searchResultsViewModel = { ...this.unfilteredSearchResults };
                this.isShowSearchResults = true;
                if (this.unfilteredSearchResults.engagementDetails && this.unfilteredSearchResults.engagementDetails.length) {
                    const getPinnedProjectsPromise: Promise<void> = this.sharedService.getPinnedEntitiesFromUserPreferences()
                        .then((pinnedResponse: IPinnedEntities) => {
                            this.pinnedObjects = pinnedResponse;
                        }).catch((error) => {
                            /* Error occured in checking the pinned objects from FXP settings, do nothing. */
                            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "Unable to get the updates and saves unit changes.");
                            this.logError(SourceConstants.Method.PopulateSearchResults, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                        });
                    const getActiveDelegatedByPromise: Promise<void> = this.sharedService.getAllActiveDelegatedByListV2()
                        .then((delegationsResponse) => {
                            this.delegationList = delegationsResponse;
                        });
                    const getActiveDelegatedToPromise: Promise<void> = this.sharedService.getAllActiveDelegatedByListV2()
                        .then((delegationsResponse) => {
                            for (const d of delegationsResponse) {
                                this.delegationList.push(d);
                            }
                        });
                    return Promise.all([getPinnedProjectsPromise, getActiveDelegatedByPromise, getActiveDelegatedToPromise]);

                } else {
                    this.showLoadingSearch = false;
                    this.isLoadingSearch = false;
                    this.isShowSearchResults = false;
                }
            })
            .then(() => {
                this.projectCount = 0;
                for (const engagement of this.searchResultsViewModel.engagementDetails) {
                    currentMinDate = moment(engagement.startDate).isBefore(currentMinDate) ? new Date(engagement.startDate) : currentMinDate;
                    currentMaxDate = moment(engagement.endDate).isAfter(currentMaxDate) ? new Date(engagement.endDate) : currentMaxDate;

                    engagement.contractType = this.sharedService.getContractType(engagement.projectDetails);
                    engagement.delegationAllocationType = this.getDelegationAllocationType(engagement.engagementId);
                    engagement.delegationDetails = this.getDelegationDetails(engagement.engagementId);
                    engagement.isAssigned = this.verifyIsEntityAssigned(engagement);
                    engagement.isPinned = (!engagement.isAssigned && this.pinnedObjects.engagementId.indexOf(engagement.engagementId) > -1)
                        ? true
                        : false;
                    engagement.typeColorCode = this.engagementDetailService.getTypeColorCode(this.typeOfResources, engagement.contractType);
                    engagement.isExternalDomain = this.verifyDomainIsExternal(engagement.primaryDomain);
                    engagement.isInternalEngagment = this.sharedFunctionsService.isEngagementInternal(engagement);
                    engagement.internalEngagmentType = engagement.isInternalEngagment
                        ? this.sharedFunctionsService.getInternalEngagementType(engagement.projectTypeCode)
                        : undefined;
                    engagement.isUsPubSec = this.sharedFunctionsService.verifyIsUsPubSec(engagement.pubSecCode);
                    if (!engagement.isInternalEngagment) {
                        this.projectCount = engagement.projectDetails.length + this.projectCount;
                        for (const project of engagement.projectDetails) {
                            project.delegationAllocationType = this.getDelegationAllocationType(project.projectId);
                            project.delegationDetails = this.getDelegationDetails(project.projectId);
                            project.isAssigned = this.verifyIsEntityAssigned(engagement);
                            project.isPinned = (!project.isAssigned && this.pinnedObjects.projectId.indexOf(project.projectId) > -1)
                                ? true
                                : false;
                            project.typeColorCode = this.engagementDetailService.getTypeColorCode(this.typeOfResources, project.contractType);
                            project.isExternalDomain = this.verifyDomainIsExternal(project.domain);
                            project.isConfidentialDeal = engagement.isConfidentialDeal;
                            project.isPublicSector = engagement.isPublicSector;
                            project.isUsPubSec = engagement.isUsPubSec;
                            project.isMarkedForDeletion = project.userStatusCode && project.userStatusCode.toUpperCase().includes("MDL") ? true : false;
                            /* All projects will have the same pub sec flag as their parents;
                            on all other APIs, projects have their own flag.
                            If search API is updated, change this to use the project level instead of engagement. */
                            currentMinDate = moment(project.startDate).isBefore(currentMinDate) ? new Date(project.startDate) : currentMinDate;
                            currentMaxDate = moment(project.endDate).isAfter(currentMaxDate) ? new Date(project.endDate) : currentMaxDate;
                        }
                    } else {
                        engagement.projectDetails = [];
                    }
                }
                this.minDatePlaceholder = currentMinDate;
                this.maxDatePlaceholder = currentMaxDate;
                this.filterStartDate = currentMinDate;
                this.filterEndDate = currentMaxDate;
                this.initialStartDate = currentMinDate;
                this.initialEndDate = currentMaxDate;
                this.initialStatus = "";
                this.showLoadingSearch = false;
                this.isLoadingSearch = false;
                this.isShowResults = true;
            })
            .catch((error) => {
                /* Error occured in the Search API */
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.dmLogger.logError(this.component.userFriendlyName, "PopulateSearchResults", error, errorMessage, null, undefined, DataService.getCorrelationIdFromError(error), ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.showLoadingSearch = false;
                this.isLoadingSearch = false;
                this.isShowSearchResults = false;
                this.isShowResults = true;
            });
    }

    /**
     * Logs the input type for telemetry.
     */
    private logSearchEventForDmLogger(input: IEngagementSearchInputObject): void {
        const propertyBag = {};
        const searchParameters: string[] = [];
        if (input.engagementId) {
            searchParameters.push(LogEventConstants.SearchEngagementIdValue);
        }
        if (input.projectId) {
            searchParameters.push(LogEventConstants.SearchProjectIdValue);
        }
        if (input.projectName) {
            searchParameters.push(LogEventConstants.SearchProjectNameValue);
        }
        if (input.opportunityId) {
            searchParameters.push(LogEventConstants.SearchOpportunityIdValue);
        }
        if (input.ppjmOrPjmBpid) {
            searchParameters.push(LogEventConstants.SearchPPJMorPJMName);
        }
        if (input.engagementName) {
            searchParameters.push(LogEventConstants.SearchEngagementNameValue);
        }
        if (input.customerName) {
            searchParameters.push(LogEventConstants.SearchCustomerNameValue);
        }
        propertyBag[LogEventConstants.SearchParameters] = searchParameters;
        const isQuickSearch: boolean = Object.keys(input).length === 1;
        const eventName: string = (isQuickSearch ? LogEventConstants.SearchQuickSearch : LogEventConstants.SearchAdvancedSearch);
        this.dmLogger.logEvent(SourceConstants.Component.SearchPage, SourceConstants.Method.LogSearchEventForDmLogger, eventName, propertyBag);
    }

    /**
     * Gets delegation detail information for the given entity ID.
     * @param entityId
     */
    private getDelegationDetails(entityId: string): IDelegationObject {
        const delegationDetails: IDelegationObject = {};
        const currentUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        const currentUserAlias = this.fxpUserInfoService.getCurrentUser();
        if (this.delegationList && this.delegationList.length > 0) {
            this.delegationList.filter((d: IDelegationDetailsV2) => (Number(d.delegatedTo.id) ===
                currentUserBPID && d.delegatedEntities.filter((e: IDelegationEntity) => e.objectId === entityId).length > 0));
            this.delegationList.forEach((d: IDelegationDetailsV2) => {
                if (Number(d.delegatedTo.id) === currentUserBPID &&
                    d.delegatedEntities.filter((e: IDelegationEntity) => e.objectId === entityId).length > 0) {
                    delegationDetails.delegationToFrom = d.delegatedBy.alias;
                    delegationDetails.delegationStatus = d.status.name;
                    delegationDetails.delegationStartDate = new Date(d.period.startsOn.localTime);
                    delegationDetails.delegationEndDate = new Date(d.period.endsOn.localTime);
                    delegationDetails.delegationFullName = d.delegatedBy.displayName;
                } else if (d.delegatedBy.alias === currentUserAlias &&
                    d.delegatedEntities.filter((e: IDelegationEntity) => e.objectId === entityId).length > 0) {
                    delegationDetails.delegationToFrom = d.delegatedTo.alias;
                    delegationDetails.delegationStatus = d.status.name;
                    delegationDetails.delegationStartDate = new Date(d.period.startsOn.localTime);
                    delegationDetails.delegationEndDate = new Date(d.period.endsOn.localTime);
                    delegationDetails.delegationFullName = d.delegatedTo.displayName;
                }
            });
        }
        return delegationDetails;
    }

    /**
     * Gets delegation allocation type for the given entity ID
     * @param entityId
     */
    private getDelegationAllocationType(entityId: string): string {
        let allocationType: string;
        const currentUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        const currentUserAlias = this.fxpUserInfoService.getCurrentUser();
        if (this.delegationList && this.delegationList.length > 0) {
            this.delegationList.forEach((d: IDelegationDetailsV2) => {
                if (Number(d.delegatedTo.id) === currentUserBPID &&
                    d.delegatedEntities.filter((e: IDelegationEntity) => e.objectId === entityId).length > 0) {
                    allocationType = "delegatedInward";
                } else if (d.delegatedBy.alias === currentUserAlias &&
                    d.delegatedEntities.filter((e: IDelegationEntity) => e.objectId === entityId).length > 0) {
                    allocationType = "delegatedOutward";
                }
            });
        }
        return allocationType;
    }

    /**
     * Toggles the pin for the given entity based on if it should be shown or not.
     */
    private updateEntityPinning(entity: any, entityType: string, showPin: boolean): void {
        if (entityType === "project") {
            const engagementId = entity.projectId.split(".", 2).join(".");
            const filteredEngagement = this.searchResultsViewModel.engagementDetails.filter((engagement: IEngagementSearchResult) =>
                engagement.engagementId === engagementId);
            const filteredProject = filteredEngagement[0].projectDetails.filter((project: IProjectSearchResult) => project.projectId === entity.projectId);
            if (filteredProject.length) {
                filteredProject[0].isPinned = showPin;
            }
        } else {
            const filteredEngagement = this.searchResultsViewModel.engagementDetails.filter((engagement: IEngagementSearchResult) =>
                engagement.engagementId === entity.engagementId);
            if (filteredEngagement.length) {
                filteredEngagement[0].isPinned = showPin;
            }
        }
    }

    /**
     * Gets the project count for the given search results object.
     * Does not count projects that are part of internal engagements.
     * @param searchResults
     */
    private getProjectCount(searchResults: ISearchResultsObject): number {
        if (!searchResults) {
            return 0;
        }
        if (!searchResults.engagementDetails || !searchResults.engagementDetails.length) {
            return 0;
        }
        let count: number = 0;
        for (const engagement of searchResults.engagementDetails) {
            if (!engagement.isInternalEngagment && engagement.projectDetails) {
                count += engagement.projectDetails.length;
            }
        }
        return count;
    }

    /**
     * Filters the search result based on the given status and dates.
     * @param selectedStatus
     * @param selectedStartDate
     * @param selectedEndDate
     */
    private filterSearchResults(selectedStatus: string, selectedStartDate: Date, selectedEndDate: Date): void {
        const engagementsList: IEngagementSearchResult[] = [];
        this.projectCount = 0;
        if (selectedStatus) {
            this.searchResultsViewModel.engagementDetails.forEach((engagement: IEngagementSearchResult) => {
                const projectList: IProjectSearchResult[] = engagement.projectDetails;
                if (
                    (engagement.stateDescription.toLowerCase() === selectedStatus.toLowerCase()
                        && this.isDateBetween(new Date(engagement.startDate), selectedStartDate, selectedEndDate)
                        && this.isDateBetween(new Date(engagement.endDate), selectedStartDate, selectedEndDate))
                    || (projectList.filter((project: IProjectSearchResult) =>
                        project.stateDescription.toLowerCase() === selectedStatus.toLowerCase()
                        && this.isDateBetween(new Date(project.startDate), selectedStartDate, selectedEndDate)
                        && this.isDateBetween(new Date(project.endDate), selectedStartDate, selectedEndDate)).length)
                ) {
                    engagementsList.push(engagement);
                    if (engagement && !engagement.isInternalEngagment && engagement.projectDetails) {
                        this.projectCount = engagement.projectDetails.length + this.projectCount;
                    }
                }
            });
        } else {
            this.searchResultsViewModel.engagementDetails.forEach((engagement: IEngagementSearchResult) => {
                const projectList: IProjectSearchResult[] = engagement.projectDetails;
                if (
                    (this.isDateBetween(new Date(engagement.startDate), selectedStartDate, selectedEndDate)
                        && this.isDateBetween(new Date(engagement.endDate), selectedStartDate, selectedEndDate))

                    || (projectList.filter((project: IProjectSearchResult) =>
                        this.isDateBetween(new Date(project.startDate), selectedStartDate, selectedEndDate)
                        && this.isDateBetween(new Date(project.endDate), selectedStartDate, selectedEndDate)).length)
                ) {
                    engagementsList.push(engagement);
                    if (!engagement.isInternalEngagment && engagement && engagement.projectDetails) {
                        this.projectCount = engagement.projectDetails.length + this.projectCount;
                    }
                }
            });
        }
        this.searchResultsViewModel.engagementDetails = engagementsList;
        this.isShowFilterResults = engagementsList.length > 0;
    }

    /**
     * Checks if the given date is between the other two dates--inclusive.
     * @param date
     * @param earlyDate
     * @param laterDate
     */
    private isDateBetween(date: Date, earlyDate: Date, laterDate: Date): boolean {
        return moment(date).isBetween(earlyDate, laterDate, null, "[]");
    }

    /**
     * Checks if the given domain is external to the current user or not.
     */
    private verifyDomainIsExternal(domainName: string): boolean {
        const userDomain = this.currentUserBusinessDomain;
        return !(domainName && userDomain && (domainName.toLowerCase() === userDomain.toLowerCase()));
    }

    /**
     * Verify if the given engagement or any of its projects is assigned to the logged in user.
     * Returns true if the engagement or any of its projects are assigned, returns false otherwise.
     */
    private verifyIsEntityAssigned(engagementDetails: IEngagementSearchResult): boolean {
        let isAssigned: boolean = this.dmAuthService.isUserInEngagementLevelTeam(engagementDetails);
        if (!isAssigned) {
            for (const project of engagementDetails.projectDetails) {
                isAssigned = this.dmAuthService.isUserInProjectLevelTeam(project);
                if (isAssigned) {
                    return true;
                }
            }
        }
        return isAssigned;
    }

    /**
     * If the user tries to search for Project ID, Engagement ID, Project Name, Engagement Name we try to retrieve the data from Portfolio if present and
     * display the data from the Portfolio.
     * Some of the fields are populated undefined as not required in results.
     * Exact search is performed.
     * @param searchInput
     */
    private displayDataFromPortFolio(searchInput: IEngagementSearchInputObject): boolean {
        let isDataAvailableInPortFolio: boolean = false;
        const myPortfolioEngagementList$ = this.store.select(getMyPortfolioEngagementListState);
        let currentMinDate = new Date();
        let currentMaxDate = new Date();
        myPortfolioEngagementList$.pipe(untilDestroyed(this)).subscribe((engagementList: IMyPortfolioEngagementListState) => {
            if (engagementList.loaded) {
                let portfolioEngagementDetails: IEngagementList[] = Object.assign([], engagementList.engagementList);
                const filterFunctions: Array<{ predicateFn: (engObj: IEngagementList) => any }> = [];
                for (const key in searchInput) {
                    if (searchInput[key]) {
                        switch (key) {
                            case "engagementId":
                                filterFunctions.push({
                                    predicateFn: (engagement) => engagement.engagementId.indexOf(searchInput[key]) > -1
                                });
                                break;
                            case "engagementName":
                                filterFunctions.push({
                                    predicateFn: (engagement) => engagement.engagementName.toLowerCase() === searchInput[key].toLowerCase()
                                });
                                break;
                            case "projectId":
                                filterFunctions.push({
                                    predicateFn: (engagement) => engagement.projects.filter((project) => project.projectId.indexOf(searchInput[key]) > -1).length > 0
                                });
                                break;
                            case "projectName":
                                filterFunctions.push({
                                    predicateFn: (engagement) => engagement.projects.filter((project) => project.projectName.toLowerCase() === searchInput[key].toLowerCase()).length > 0
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
                if (filterFunctions.length > 0) {
                    portfolioEngagementDetails = portfolioEngagementDetails.filter((item) => filterFunctions.every((filterFunction) => filterFunction.predicateFn(item)));
                    if (portfolioEngagementDetails.length > 0) {
                        isDataAvailableInPortFolio = true;
                        this.projectCount = 0;
                        for (const engagementDetail of portfolioEngagementDetails) {
                            currentMinDate = moment(engagementDetail.startDate).isBefore(currentMinDate) ? new Date(engagementDetail.startDate) : currentMinDate;
                            currentMaxDate = moment(engagementDetail.endDate).isAfter(currentMaxDate) ? new Date(engagementDetail.endDate) : currentMaxDate;
                            this.searchResultsViewModel.engagementDetails.push({
                                additionalPpjMs: [],
                                companyCode: undefined,
                                contractType: engagementDetail.typeOfContract,
                                currency: undefined,
                                customerCountry: undefined,
                                customerID: undefined,
                                customerName: engagementDetail.customerName,
                                description: engagementDetail.engagementName,
                                endDate: String(engagementDetail.endDate),
                                engagementId: engagementDetail.engagementId,
                                hasAssociatedEngagements: engagementDetail.hasAssociatedEngagements,
                                internalEngagmentType: engagementDetail.typeOfContract,
                                isAssigned: true,
                                isConfidentialDeal: engagementDetail.isConfidential,
                                isExternalDomain: false,
                                isInternalEngagment: (engagementDetail.type.toLowerCase() === "internal"),
                                isPinned: engagementDetail.isPinned,
                                isPublicSector: engagementDetail.isPublicSector,
                                isUsPubSec: engagementDetail.isUsPubSec,
                                name: engagementDetail.engagementName,
                                opportunityID: undefined,
                                pPjMAlias: engagementDetail.pPjMAlias,
                                pPjMName: engagementDetail.pPjMName,
                                pPjMbpId: engagementDetail.pPjmBpid,
                                primaryDomain: engagementDetail.primaryDomain,
                                projectDetails: (engagementDetail.type.toLowerCase() === "internal") ? [] : this.loadProjectsForMyPortFolioData(engagementDetail),
                                pubSecCode: undefined,
                                startDate: String(engagementDetail.startDate),
                                stateCode: engagementDetail.ebsState,
                                stateDescription: engagementDetail.ebsState,
                                typeColorCode: engagementDetail.typeColorCode,
                                userStatusCode: engagementDetail.ebsState,
                            });
                        }
                        this.isShowResults = true;
                        this.isShowFilterResults = true;
                        this.isShowSearchResults = true;
                        this.isLoadingSearch = false;
                        this.unfilteredSearchResults = { ...this.searchResultsViewModel };
                        this.minDatePlaceholder = currentMinDate;
                        this.maxDatePlaceholder = currentMaxDate;
                        this.filterStartDate = currentMinDate;
                        this.filterEndDate = currentMaxDate;
                        this.initialStartDate = currentMinDate;
                        this.initialEndDate = currentMaxDate;
                        this.initialStatus = "";
                        return;
                    } else {
                        isDataAvailableInPortFolio = false;
                        return;
                    }
                } else {
                    isDataAvailableInPortFolio = false;
                    return;
                }
            } else if (!engagementList.loaded) {
                return false;
            }
            if (engagementList.error) {
                this.isServerError = true;
            }
        });
        return isDataAvailableInPortFolio;
    }

    /**
     * Add the projects data and populate them to the engagement model.
     * @param engagementDetail
     */
    private loadProjectsForMyPortFolioData(engagementDetail: IEngagementList): IProjectSearchResult[] {
        const projDetails: IProjectSearchResult[] = [];
        this.projectCount = engagementDetail.projects.length + this.projectCount;
        for (const projectDetail of engagementDetail.projects) {
            projDetails.push({
                contractType: projectDetail.typeOfContract,
                description: projectDetail.projectName,
                domain: projectDetail.primaryDomain,
                endDate: String(projectDetail.endDate),
                engagementId: engagementDetail.engagementId,
                engagementName: engagementDetail.engagementName,
                isAssigned: true,
                isConfidentialDeal: engagementDetail.isConfidential,
                isExternalDomain: false,
                isPinned: projectDetail.isPinned,
                isPublicSector: engagementDetail.isPublicSector,
                isUsPubSec: engagementDetail.isUsPubSec,
                isMarkedForDeletion : projectDetail.isMarkedForDeletion,
                lastUpdateddate: undefined,
                name: projectDetail.projectName,
                pjMAlias: projectDetail.pjMAlias,
                pjMName: projectDetail.pjMName,
                pjMbpId: projectDetail.pjMBpid,
                projectId: projectDetail.projectId,
                projectTypeCode: projectDetail.ebsState,
                startDate: String(projectDetail.startDate),
                stateCode: projectDetail.ebsState,
                stateDescription: projectDetail.ebsState,
                typeColorCode: projectDetail.typeColorCode,
                userStatusCode: projectDetail.ebsState,
                userStatusDescription: projectDetail.ebsState                                
            });
        }
        return projDetails;
    }


    /**
     * To set Table Caption based on filters applied
     */
    private setTableCaption(): string {
        if (!((this.statusSelected === this.initialStatus) && (this.filterStartDate.getTime() === this.initialStartDate.getTime()) && (this.filterEndDate.getTime() === this.initialEndDate.getTime()))) {
            this.tableCaption = "Filter Results with ";
            if (this.statusSelected !== this.initialStatus) {
                this.initialStatus = this.statusSelected;
                this.tableCaption += "Status " + this.statusSelected;
            }
            if (this.filterStartDate.getTime() !== this.initialStartDate.getTime()) {
                this.initialStartDate = this.filterStartDate;
                this.tableCaption += " Start Date" + moment(this.filterStartDate).format("DD-MMM-YYYY");
            }
            if (this.filterEndDate.getTime() !== this.initialEndDate.getTime()) {
                this.initialEndDate = this.filterEndDate;
                this.tableCaption += " End Date" + moment(this.filterEndDate).format("DD-MMM-YYYY");
            }
        }
        return this.tableCaption;
    }

    // private setUserPreferences() {
    //     this.loadProjectsFromUserPreferences().then(() => {
    //         this.projectCount = 0;
    //         this.searchResultsViewModel.engagementDetails.forEach(engagement => {
    //             this.projectCount = engagement.projectDetails.length + this.projectCount;
    //             engagement.isPinned = this.pinnedObjects.engagementId.indexOf(engagement.engagementId) > -1 ? true : false;
    //             engagement.projectDetails.forEach(project => {
    //                 project.isPinned = this.pinnedObjects.projectId.indexOf(project.projectId) > -1 ? true : false;
    //             });
    //         });
    //     });
    // }
}
