import { Action } from "@ngrx/store";
import { IForecast } from "./../../components/financial-mgmt/financial.model";

// 1. action types
export enum WbsForecastActionTypes {
    LOAD_WbsForecast = "[Wbsforecast] Load",
    LOAD_WbsForecast_SUCCESS = "[Wbsforecast] Load Success",
    LOAD_WbsForecast_FAIL = "[Wbsforecast] Load Fail",
    INVALIDATE_WbsForecast = "[Wbsforecast] Invalidate"
}

// 2. action creators
export class LoadWbsForecast implements Action {
    public readonly type = WbsForecastActionTypes.LOAD_WbsForecast;
    public constructor(public wbsId: string) { }
}

export class LoadWbsForecastSuccess implements Action {
    public readonly type = WbsForecastActionTypes.LOAD_WbsForecast_SUCCESS;
    public constructor(public wbsId: string, public wbsForecastDetails: IForecast) { }
}

export class LoadWbsForecastFail implements Action {
    public readonly type = WbsForecastActionTypes.LOAD_WbsForecast_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateWbsForecastDetails implements Action {
    public readonly type = WbsForecastActionTypes.INVALIDATE_WbsForecast;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type WbsForecastActions =
    | LoadWbsForecast
    | LoadWbsForecastSuccess
    | LoadWbsForecastFail
    | InvalidateWbsForecastDetails;