import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { FcrDetailsComponent } from "./fcr-details.component";
import { FinancialCrApproversModule } from "../financial-cr-approvers/financial-cr-approvers.module";
import { NgModule } from "@angular/core";
import { ToggleSectionModule } from "../../shared/toggle-section/toggle-section.module";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { FcrLaborViewDetailsComponent } from "./fcr-labor-view-details/fcr-labor-view-details.component";
import { FcrExpenseViewDetailsComponent } from "./fcr-expense-view-details/fcr-expense-view-details.component";
import { FcrSubconViewDetailsComponent } from "./fcr-subcon-view-details/fcr-subcon-view-details.component";
import { FcrUnitsViewDetailsComponent } from "./fcr-units-view-details/fcr-units-view-details.component";

@NgModule({
    declarations: [
        FcrDetailsComponent,
        FcrLaborViewDetailsComponent,
        FcrExpenseViewDetailsComponent,
        FcrSubconViewDetailsComponent,
        FcrUnitsViewDetailsComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        ToggleSectionModule,
        FinancialCrApproversModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        FcrDetailsComponent
    ],
    exports: [
        FcrDetailsComponent,
        FcrLaborViewDetailsComponent,
        FcrExpenseViewDetailsComponent,
        FcrSubconViewDetailsComponent,
        FcrUnitsViewDetailsComponent
    ]
})
export class FcrDetailsModule { }
