import { Action } from "@ngrx/store";
import { IForecast } from "./../../components/financial-mgmt/financial.model";

// 1. action types
export enum WbsCfpActionTypes {
    LOAD_WbsCfp = "[Wbscfp] Load",
    LOAD_WbsCfp_SUCCESS = "[Wbscfp] Load Success",
    LOAD_WbsCfp_FAIL = "[Wbscfp] Load Fail",
    INVALIDATE_WbsCfp = "[Wbscfp] Invalidate"
}

// 2. action creators
export class LoadWbsCfp implements Action {
    public readonly type = WbsCfpActionTypes.LOAD_WbsCfp;
    public constructor(public wbsId: string) { }
}

export class LoadWbsCfpSuccess implements Action {
    public readonly type = WbsCfpActionTypes.LOAD_WbsCfp_SUCCESS;
    public constructor(public wbsId: string, public wbsCfpDetails: IForecast) { }
}

export class LoadWbsCfpFail implements Action {
    public readonly type = WbsCfpActionTypes.LOAD_WbsCfp_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateWbsCfpDetails implements Action {
    public readonly type = WbsCfpActionTypes.INVALIDATE_WbsCfp;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type WbsCfpActions =
    | LoadWbsCfp
    | LoadWbsCfpSuccess
    | LoadWbsCfpFail
    | InvalidateWbsCfpDetails;