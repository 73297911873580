import * as fromWbsDetailsV2 from "./wbs-details.action";
import { IWbsDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { ILoadableState } from "../reducers";

export interface IWbsDetailsV2State extends ILoadableState {
    wbsDetails: IWbsDetailsV2;
}

export const initialState: IWbsDetailsV2State = {
    wbsDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromWbsDetailsV2.WbsDetailsActionsV2): { [wbsId: string]: IWbsDetailsV2State } {
    switch (action.type) {
        case fromWbsDetailsV2.WbsDetailsV2ActionTypes
            .LOAD_WBS_DETAILS: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromWbsDetailsV2.WbsDetailsV2ActionTypes
            .LOAD_WBS_DETAILS_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                wbsDetails: action.wbsDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromWbsDetailsV2.WbsDetailsV2ActionTypes
            .LOAD_WBS_DETAILS_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromWbsDetailsV2.WbsDetailsV2ActionTypes
            .INVALIDATE_WBS_DETAILS: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}