import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { UIRouterModule } from "@uirouter/angular";
import { FeedbackModalService } from "../../../../components/tiles/feedback-modal/feedback-modal.service";
import { EntitySummaryDetailsComponent } from "./entity-summary-details.component";
import { DmUserProfileModule } from "../../../../components/shared/user-name/user-name.module";
import { AssociatedInternalEngagementsModalModule } from "../../../../components/internal-engagement/internal-associated-engagements-v2/internal-associated-engagements-v2.module";

@NgModule({
    declarations: [
        EntitySummaryDetailsComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule,    
        UIRouterModule,
        DmUserProfileModule,
        AssociatedInternalEngagementsModalModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
        SharedFunctionsService,
        FeedbackModalService
    ],
    entryComponents: [
        EntitySummaryDetailsComponent,
    ],
    exports: [
        EntitySummaryDetailsComponent,
    ]
})
export class EntitySummaryDetailsModule { }