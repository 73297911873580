import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, Inject, forwardRef, Injector } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceFactoryProvider, FxpRouteService, FxpMessageService, FxpConstants } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { AmendmentsService } from "../../../common/services/amendments.service";
import { ChangeExistingDemandModalComponent } from "./modals/change-existing-demand/change-existing-demand.component";
import { Components, RouteName, ComponentFailureMessages, SourceConstants } from "../../../common/application.constants";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getDemandsState } from "../../../store/demands/demands.selector";
import { getEntireEngagementDetails } from "../../../store/engagement-details/engagement-details.selector";
import { getEntireFinancialDetailsV2 } from "../../../store/financial-details-v2/financial-details-v2.selector";
import { getFinancialRolesState } from "../../../store/financial-roles/financial-roles.selector";
import { IAmendmentPackage, IMisalignedAmendment } from "../amendments.contract";
import { IMisalignedAmendmentCallbackObject, IMisalignedAmendmentPairedDemandsViewModel, IMisalignedAmendmentProjectViewModel, IMisalignedAmendmentViewModel, IMisalignedProject, MisalignmentStatus, MisalignmentValidationErrorTypeEnum } from "../amendments.contract";
import { IDemandDetails } from "../../../common/services/contracts/project.service.v2.contracts";
import { IDemandsState } from "../../../store/demands/demands.reducer";
import { IEngagementDetailsState } from "../../../store/engagement-details/engagement-details.reducer";
import { IEngagementDetailsV2, IProjectDetailsV2 } from "../../../common/services/contracts/wbs-details-v2.contracts";
import { IEntityFinancialSummary } from "../../financial-mgmt/financial.model";
import { IFinancialDetailsV2State } from "../../../store/financial-details-v2/financial-details-v2.reducer";
import { IFinancialRoles } from "../../../common/services/contracts/projectservice-functions.contract";
import { IFinancialRolesState } from "../../../store/financial-roles/financial-roles.reducer";
import { IState } from "../../../store/reducers";
import { ITile } from "../../tiles/dm-tile/dm-tile.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ValidationFailedModalComponent } from "./modals/validation-failed-modal/validation-failed-modal.component";
import { ProjectService } from "../../../common/services/project.service";
import { IAmendmentThresholdValues, IProjectThresholdValues } from "../../../common/services/contracts/project.service.contracts";
import { v4 as uuid } from "uuid";


@Component({
    selector: "dm-misaligned-amendment-details",
    templateUrl: "./misaligned-amendment-details.html",
    styleUrls: ["./misaligned-amendment-details.scss"]
})
export class MisalignedAmendmentDetailsComponent extends DmComponentAbstract {

    public currentFinancialDetails: IEntityFinancialSummary;
    public status: string;
    public crDescription: string;
    public lastUpdatedTimeStamp: Date;
    public crNumber: number;
    public currency: string;
    public backToFinancialsRouteName: string;
    public backToFinancialsRouteParams: { [key: string]: string };
    public loadingAmendmentsText: string;
    public tileContent: ITile;
    public isServerError: boolean;
    public isEbsExpanded: boolean = false;
    public allDemandDetails: IDemandDetails[];
    public misalignedAmendmentsObject: IMisalignedAmendmentProjectViewModel[] = [];
    public engagementLevelSubmitted: boolean = false;
    public selectedAmendmentPair: IMisalignedAmendmentPairedDemandsViewModel;
    public showNotificationBanner: boolean = false;
    public notificationBannerMessage: string;
    public newDemandSet: boolean = false;
    private entityFullDetails: IEngagementDetailsV2;
    private engagementId: string;
    private allFinancialRoles: IFinancialRoles[];
    private demandsFromSAP: Map<string, string[]>;
    private readonly FXP_CONSTANTS = FxpConstants;
    private readonly NEW_DEMAND_MESSAGE = "A new demand has been created.";
    private readonly SAVE_PROJECT_MESSAGE = "Changes to the project have been saved.";
    private readonly SUBMIT_PROJECT_MESSAGE = "All changes have been submitted.";

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(StateService) private stateService: StateService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(AmendmentsService) private amendmentsService: AmendmentsService,
        @Inject(Store) private store: Store<IState>,
        @Inject(Injector) private injector: Injector,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(DMAuthorizationService) private dmAuthService: DMAuthorizationService
    ) {
        super(dmLogger, Components.MisalignedAmendmentDetails);
    }

    public ngOnInit(): void {

        this.tileContent = {
            title: "Amendment"
        };
        this.crNumber = Number(this.stateService.params.amendmentId);
        this.loadingAmendmentsText = "Loading Misaligned Amendment Details for CR " + this.crNumber;
        this.errorText = ComponentFailureMessages.EngagementAmendmentComponent;
        this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.errorText = ComponentFailureMessages.EngagementAmendmentComponent;
        const isProjectContext: boolean = projectId ? true : false;
        const entityId: string = isProjectContext ? projectId : this.engagementId;
        this.backToFinancialsRouteName = isProjectContext ? RouteName.ProjectFinancials : RouteName.EngagementFinancials;
        this.backToFinancialsRouteParams = isProjectContext ? { projectId } : { engagementId: this.engagementId };

        this.storeDispatchService
            .requireEngagementDetails(this.engagementId, true)
            .requireWbsDemandsV2WithoutSchedules(this.engagementId, true)
            .requireFinancialDetailsV2(this.engagementId, true)
            .requireFinancialRoles(true)
            .load();


        const engagementDetails$ = this.store.select<IEngagementDetailsState>(getEntireEngagementDetails(entityId));
        const financialDetails$ = this.store.select<IFinancialDetailsV2State>(getEntireFinancialDetailsV2(entityId));
        const wbsDemandDetails$ = this.store.select<IDemandsState>(getDemandsState(this.engagementId));
        const financialRoles$ = this.store.select<IFinancialRolesState>(getFinancialRolesState);
        observableCombineLatest([
            engagementDetails$,
            financialDetails$,
            wbsDemandDetails$,
            financialRoles$
        ]
        ).pipe(untilDestroyed(this))
            .subscribe(([
                engagementDetails,
                financialDetails,
                wbsDemandDetails,
                financialRoles
            ]) => {
                if (engagementDetails.loaded && financialDetails.loaded && wbsDemandDetails.loaded && financialRoles.loaded) {
                    this.allFinancialRoles = financialRoles.financialRoles;
                    this.entityFullDetails = engagementDetails.engagementDetails;
                    this.allDemandDetails = wbsDemandDetails.wbsDemands.details;
                    this.currentFinancialDetails = financialDetails.financialDetails.financialSummary.filter((financialSummary) => financialSummary.versionKey === "3")[0]; // "3" is "Current Financial Plan"
                    this.currency = this.currentFinancialDetails ? this.currentFinancialDetails.planCurrency : undefined;
                    this.loadMisalignedAmendmentDetails(this.engagementId, this.crNumber.toString());
                }

                if (this.refreshOnItemInvalidation(engagementDetails, financialDetails, wbsDemandDetails)) {
                    this.loadMisalignedAmendmentDetails(this.engagementId, this.crNumber.toString());
                }
                this.setLoadersBasedOnItemState(engagementDetails, financialDetails, wbsDemandDetails, financialRoles);
                this.setErrorsBasedOnItemState(engagementDetails, financialDetails, wbsDemandDetails, financialRoles);
            });
    }

    /**
     * Does the currently logged in user have edit access for the given project?
     * Users who are PPJM/APPJM/DPPJM on L0 engagement level have access to all projects.
     * Users who are PJM/APJM/DPJM on L1 project level have acces only to their projects.
     *
     * @param {IMisalignedAmendmentProjectViewModel} project
     * @returns {boolean}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public userHasEditAccess(project: IMisalignedAmendmentProjectViewModel): boolean {
        /* Disabled if engagement level submitted */
        if (this.engagementLevelSubmitted) {
            return false;
        }
        if (this.dmAuthService.isUserInEngagementLevelTeam(this.entityFullDetails)) {
            return true;
        }
        if (!project || !project.projectId) {
            return false;
        }
        const projectDetails: IProjectDetailsV2 = this.entityFullDetails.projects.filter((x) => x.id === project.projectId)[0];
        if (this.dmAuthService.isUserInProjectLevelTeamV2(projectDetails)) {
            return true;
        }
        return false;
    }

    /**
     * Does the currently logged in user have submit access for the given project?
     * Users who are PPJM/APPJM/DPPJM on L0 engagement level/L1 project level have access to submit the changes
     *
     * @returns {boolean}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public userHasSubmitAccess(): boolean {
        /* Disabled if engagement level submitted */
        if (this.engagementLevelSubmitted) {
            return false;
        }
        if (this.dmAuthService.isUserInEngagementLevelTeam(this.entityFullDetails)) {
            return true;
        }        
        return false;
    }

    /**
     *  Manually expand/collapsing the project and resetting the expand/collapse icon on top the table
     */
    public expandCollapseProjects(project: IAmendmentPackage): void {
        // todo log
        project.isExpand = !project.isExpand;
        this.isEbsExpanded = this.misalignedAmendmentsObject.filter((x) => !x.isExpand).length ? false : true; /* If any of the projects are not expanded, then ebs is not expanded */
    }

    /**
     * On expand collapse projects
     */
    public expandAllProjects(): void {
        // todo log
        this.isEbsExpanded = !this.isEbsExpanded;
        for (const project of this.misalignedAmendmentsObject) {
            project.isExpand = this.isEbsExpanded;
        }
    }

    /**
     * Task/amendment has been selected, set the selectedAmendment to the given amendment.
     */
    public amendmentPairSelected(callbackObj: IMisalignedAmendmentCallbackObject): void {
        if (!callbackObj) {
            return;
        }
        const pairId: string = callbackObj.pairId;
        const projectId: string = callbackObj.projectId;
        this.selectedAmendmentPair = this.getAmendmentPairFromPairId(pairId, projectId);
        if (this.amendmentsService.isAmendmentAssignedToNewlyCreatedDemand(this.selectedAmendmentPair)) {
            this.newDemandSet = true;
        } else {
            this.newDemandSet = false;
        }
    }

    /**
     * Is the selected amendment pair valid to have a new demand created for it? Returns true if valid to have new demand, false otherwise.
     * The amendment selected must not already be marked as new AND the planned labor for it must be > 0
     * @param selectedAmendmentPair selectedAmendmentPair
     * @returns boolean
     */
    public isValidNewDemandScenario(): boolean {
        if (this.newDemandSet) {
            return false;
        }
        if (this.selectedAmendmentPair && this.selectedAmendmentPair.splittedLineItem) {
            return false;
        }

        if (this.selectedAmendmentPair && this.selectedAmendmentPair.misalignedAmendment && this.selectedAmendmentPair.misalignedAmendment.plannedLabor) {
            return this.selectedAmendmentPair.misalignedAmendment.plannedLabor > 0;
        }
        return false;
    }

    /**
     * Triggered on change callback for the lineitem.
     * Includes the updated misaligned amendment, the pairId and projectId to identify it.
     * Updates the pair view model with any changes in error status (such as resolved) and the new updated misaligned amendment.
     */
    public onLineitemChange(callbackObj: IMisalignedAmendmentCallbackObject): void {
        if (!callbackObj) {
            return;
        }
        const pairId: string = callbackObj.pairId;
        const projectId: string = callbackObj.projectId;
        const errorsResolved: boolean = callbackObj.errorsResolved;
        const newDemandCreated: boolean = callbackObj.newDemandCreated;
        const misalignedAmendment: IMisalignedAmendmentViewModel = callbackObj.misalignedAmendment;
        const pair = this.getAmendmentPairFromPairId(pairId, projectId);
        pair.errorsResolved = errorsResolved;
        pair.misalignedAmendment = misalignedAmendment;
        // if the change that was made is not related to demand, then do not show the change pair to new demand modal
        if (newDemandCreated) {
            this.changePairToNewDemand(pair);
        }
    }

    /**
     * open modal pop up to Change Existing Demand
     */
    public openChangeExistingDemandModal(callbackObj: IMisalignedAmendmentCallbackObject): void {
        if (!callbackObj)
        {
            callbackObj = {
                pairId: this.selectedAmendmentPair.pairId,
                projectId: this.selectedAmendmentPair.misalignedAmendment.projectId,

                newDemandCreated: false,
                existingDemandAssigned: true,
                splittedLineItem: this.selectedAmendmentPair.splittedLineItem,

                misalignedAmendment: this.selectedAmendmentPair.misalignedAmendment,
                errorsResolved: this.selectedAmendmentPair.errorsResolved
            };
        }
        let originalAmendment = this.misalignedAmendmentsObject.filter((x) => x.projectId === callbackObj.projectId)[0].pairedDemands.filter((z) => z.pairId === callbackObj.pairId)[0].originalAmendment;
        
        // todo log
        const modalRef = this.modalService.open(ChangeExistingDemandModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 change-existing-demand-modal in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        
        
        let filteredDemands = this.allDemandDetails.filter((d) => this.projectService.getProjectIdFromTaskId(d.structureId) === callbackObj.misalignedAmendment.projectId); // Only get the demands for the project;

        if (!this.amendmentsService.isAmendmentOrphaned(callbackObj.misalignedAmendment, originalAmendment))
        {
            if (!this.newDemandSet) {
                const demandsAssigned = this.misalignedAmendmentsObject.filter((x) => x.projectId === callbackObj.misalignedAmendment.projectId)[0].pairedDemands.map((y) => y.originalAmendment.demandId);
                filteredDemands = filteredDemands.filter( (x) => !demandsAssigned.includes(x.demandId));
                if (callbackObj.splittedLineItem) {
                    filteredDemands = this.allDemandDetails.filter( (x) => (x.planned !== null) && (x.planned.resourceItemId === originalAmendment.billingRoleId) && !(demandsAssigned.includes(x.demandId)));
                }
            }
        }
        modalRef.componentInstance.existingDemands = filteredDemands;
        modalRef.componentInstance.demand = callbackObj.misalignedAmendment;
        modalRef.result.then((newlySelectedDemandId: string) => {

            if (!newlySelectedDemandId) {
                /* No newly selected ID indicates user closed modal with cancel, no action needed */
                return;
            }

            callbackObj.misalignedAmendment.demandId = newlySelectedDemandId;
            this.onNewExistingDemandAssigned(callbackObj);
            const project = this.misalignedAmendmentsObject.filter((x) => x.projectId === callbackObj.projectId)[0];
            originalAmendment = project.pairedDemands.filter((z) => z.pairId === callbackObj.pairId)[0].originalAmendment;
            if (callbackObj.splittedLineItem) {
                if (!project.availableTaskIds.includes(originalAmendment.taskId)) {
                    project.availableTaskIds.push(originalAmendment.taskId);
                    callbackObj.misalignedAmendment.taskDescription = originalAmendment.taskDescription;
                    callbackObj.misalignedAmendment.projectId = originalAmendment.projectId;
                }
               
                callbackObj.misalignedAmendment.costRate = originalAmendment.costRate;
                callbackObj.misalignedAmendment.billRate = originalAmendment.billRate;
                this.recalculateAdditionalCost();
                this.recalculateAdditionalRevenue();
            }

        });
    }

    

    /**
     * Updates the demand attached to the misaligned amendment when the amendment is moved to a different demand.
     * This function is either called when the update existing demand modal is closed in this component or as a callback from a lineitem component
     *
     * @param {IMisalignedAmendmentCallbackObject} callbackObj
     * @returns {void}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public onNewExistingDemandAssigned(callbackObj: IMisalignedAmendmentCallbackObject): void {
        if (!callbackObj) {
            return;
        }
        this.onLineitemChange(callbackObj);
        const pairId: string = callbackObj.pairId;
        const projectId: string = callbackObj.projectId;
        const misalignedAmendment: IMisalignedAmendmentViewModel = callbackObj.misalignedAmendment;
        const newlySelectedDemandId: string = misalignedAmendment.demandId;
        const crNumber: string = misalignedAmendment.crNumber;
        const newlySelectedDemand: IDemandDetails = this.allDemandDetails.filter((x) => x.demandId === newlySelectedDemandId)[0];
        if (!newlySelectedDemand) {
            const message: string = `Unable to assign CR ${crNumber} to Demand ${newlySelectedDemandId} because a Demand with that Demand ID was not included in the Demand List`;
            this.logError(SourceConstants.Method.AssignAmendmentToExistingDemand, message, 400);
            this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.error);
            return;
        }
        const pair = this.getAmendmentPairFromPairId(pairId, projectId);
        pair.originalAmendment = this.amendmentsService.getMisalignedAmendmentViewModelLineItems(this.crNumber.toString(), newlySelectedDemand.planned, this.entityFullDetails, this.allFinancialRoles, false, newlySelectedDemand.staffed);
        this.newDemandSet = false;
    }

    /**
     * open modal pop up to Validate if demands available for split
     */
    public disableSplitLineItem(callbackObj: IMisalignedAmendmentCallbackObject): boolean {
        if (!callbackObj)
        {
            callbackObj = {
                pairId: this.selectedAmendmentPair.pairId,
                projectId: this.selectedAmendmentPair.misalignedAmendment.projectId,

                newDemandCreated: false,
                existingDemandAssigned: true,
                splittedLineItem: this.selectedAmendmentPair.splittedLineItem,

                misalignedAmendment: this.selectedAmendmentPair.misalignedAmendment,
                errorsResolved: this.selectedAmendmentPair.errorsResolved
            };
        }
        const originalprojectId = (this.selectedAmendmentPair.misalignedAmendment && this.selectedAmendmentPair.misalignedAmendment.originalMisalignedAmendmentData) ? this.selectedAmendmentPair.misalignedAmendment.originalMisalignedAmendmentData.taskId.substring(0, 16) + "000" : "";
        let originalAmendment;
        if (this.misalignedAmendmentsObject.filter((x) => x.projectId === callbackObj.projectId).length > 0 ) {
            originalAmendment = this.misalignedAmendmentsObject.filter((x) => x.projectId === callbackObj.projectId)[0].pairedDemands.filter((z) => z.pairId === callbackObj.pairId)[0].originalAmendment;
        } else {
            originalAmendment = this.misalignedAmendmentsObject.filter((x) => x.projectId === originalprojectId)[0].pairedDemands.filter((z) => z.pairId === callbackObj.pairId)[0].originalAmendment;
        }     
       
        
        let filteredDemands = this.allDemandDetails.filter((d) => this.projectService.getProjectIdFromTaskId(d.structureId) === originalprojectId); // Only get the demands for the project;
        if (!this.newDemandSet) {
            const demandsAssigned = this.misalignedAmendmentsObject.filter((x) => x.projectId === originalprojectId)[0].pairedDemands.map((y) => y.originalAmendment.demandId);
            filteredDemands = filteredDemands.filter( (x) => !demandsAssigned.includes(x.demandId));
            if (callbackObj.splittedLineItem) {
                filteredDemands = this.allDemandDetails.filter( (x) => (x.planned !== null) && (x.planned.resourceItemId === originalAmendment.billingRoleId) && !(demandsAssigned.includes(x.demandId)));
            }
        }
        
        if (!filteredDemands || filteredDemands.length === 0) {
            this.misalignedAmendmentsObject.filter((x) => x.projectId === originalprojectId)[0].isSplitDemandDisabled = true;
            return true;
        }
        this.misalignedAmendmentsObject.filter((x) => x.projectId === originalprojectId)[0].isSplitDemandDisabled = false;
        return false;
    }

    /**
     * Navigate back to the page with all the amendments.
     *
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public goBackToAllAmendments(): void {
        // log click
        this.fxpRouteService.navigatetoSpecificState(this.backToFinancialsRouteName, this.backToFinancialsRouteParams);
    }

    /**
     * Saves the misaligned amendment changes. Tied to the save button.
     * First validates the data being saved. In case of errors or warning will show a modal to inform the user.
     * Otherwise will convert the data to the type the API needs and then saves it.
     * 
     * @returns {Promise<void>} 204 no content on success
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public saveMisalignedAmendmentChangesClicked(project: IMisalignedAmendmentProjectViewModel): Promise<void> {
        // temporarily not disabling the save logic 4/16/2021
        // project.hasBeenSaved = true; /* Set to true while processing in order to disable the save button */
        const errorObj: IProjectThresholdValues = this.amendmentsService.getMisalignmentValidationErrors(project);
        if (errorObj && errorObj.amendmentThresholds && errorObj.amendmentThresholds.length) {
            const amendmentThresholds: IAmendmentThresholdValues[] = errorObj.amendmentThresholds;
            return this.openValidationConfirmationModal(errorObj).then((isSaveAndContinue) => {
                if (isSaveAndContinue) {
                    if (!amendmentThresholds.some((e) => e.amendmentErrorType === MisalignmentValidationErrorTypeEnum.Error)) {
                        return this.saveMisalignedAmendmentChanges(project); /* Toggling project.hasBeenSave done inside this func */
                    } else {
                        // temporarily not disabling the save logic 4/16/2021
                        // project.hasBeenSaved = false;
                    }
                }
            });
        } else {
            return this.saveMisalignedAmendmentChanges(project);
        }
    }

    /**
     * Checks if there are any errors associated with the project (via their lineitems).
     * Will return true if all errors are resolved for the project, false otherwise.
     *
     * @param {IMisalignedAmendmentProjectViewModel} project
     * @returns {boolean}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public areProjectErrorsResolved(project: IMisalignedAmendmentProjectViewModel): boolean {
        if (!project || !project.pairedDemands || !project.pairedDemands.length) {
            return true;
        }
        for (const pair of project.pairedDemands) {
            if (!pair.errorsResolved) {
                return false;
            }
        }
        if (this.IsTotalNotMatching(project) || this.IsDuplicateDemandPresent(project)) {
            return false;
        }
        if (!this.areSAPDemandsPresentOnSplit(project)) {
            return false;
        }
        return true;
    }

    /**
     * Checks if the save button (one per project) is disabled (true) or enabled (false).
     * Does this by checking if the errors are resolved for the project. If errors are resolved, then save button is enabled (false), otherwise it is disabled (true).
     * In case project does not exist, return disabled/true.
     *
     * @param {IMisalignedAmendmentProjectViewModel} project
     * @returns {boolean} true if button should be disabled, false if button should not be disabled (should be enabled)
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public saveButtonDisabled(project: IMisalignedAmendmentProjectViewModel): boolean {
        if (!project || !project.pairedDemands || !project.pairedDemands.length) {
            return true;
        }
        if (!this.userHasEditAccess(project)) {
            return true;
        }
        return !this.areProjectErrorsResolved(project);
    }

    /**
     * Checks if the submit button (for the entire CR) is disabled (true) or enabled (false).
     * Does this first by checking on the status of each save button, if theyre disabled or not.
     * If all save buttons are disabled, it also checks if the project has been saved or not. If project has not yet been saved, then submit is not enabled.
     *
     * @returns {boolean} true if button should be disabled, false if button should not be disabled (should be enabled)
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public submitButtonDisabled(): boolean {
        if (!this.userHasSubmitAccess) {
            return true;
        }
        if (this.engagementLevelSubmitted) {
            return true;
        }
        for (const proj of this.misalignedAmendmentsObject) {
            if (!proj.hasBeenSaved) { /* If the project has no errors (like in case of a newly created demand) but has not been saved, the submit button will be disabled */
                return true;
            }
        }
        return false;
    }

    /**
     * Submits the CR ID to be processed by SAP.
     * For the engagement level, not the project level.
     * All projects need to be saved for this to succeed.
     *
     * @returns {Promise<void>} 204 no content on success
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public submitEngagementLevelUpdates(): Promise<void> {
        // log click
        this.engagementLevelSubmitted = true;
        return this.amendmentsService.postMisalignedEngagement(this.engagementId, this.crNumber.toString())
            .then(() => {
                this.setNotificationBanner(this.SUBMIT_PROJECT_MESSAGE);
            })
            .catch((error) => {
                this.engagementLevelSubmitted = false;
                const message: string = "Unable to submit changes for the engagement: " + error;
                this.logError(SourceConstants.Method.SubmitEngagementLevelUpdates, message);
                this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.error);
            });
    }

    /**
     * Checks if pair has been changed to a demand Id of new. This indicates the
     * demand is being newly created. Show the green notification banner saying a new demand has been created.
     * Sets the original demand to undefined.
     *
     * @param {IMisalignedAmendmentPairedDemandsViewModel} pair
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public changePairToNewDemand(pair: IMisalignedAmendmentPairedDemandsViewModel): void {
        if (!pair) {
            pair = this.selectedAmendmentPair;
            pair.misalignedAmendment.demandId = "New";
        }
        if (pair && this.amendmentsService.isAmendmentAssignedToNewlyCreatedDemand(pair.misalignedAmendment)) {
            pair.errorsResolved = true;
            pair.originalAmendment = undefined;
            this.newDemandSet = true;
            this.setNotificationBanner(this.NEW_DEMAND_MESSAGE);
        }
    }

    public splitDemand(callbackObj: IMisalignedAmendmentCallbackObject): void{
        let pairId: string;
        let projectId: string;
        if (callbackObj) {
            pairId = callbackObj.pairId;
            projectId = callbackObj.projectId;
        }
        else{
            pairId = this.selectedAmendmentPair.pairId;
            projectId = this.selectedAmendmentPair.originalAmendment.projectId;
        }
        
        const project = this.misalignedAmendmentsObject.filter((x) => x.projectId === projectId)[0];
        const pairToClone = project.pairedDemands.filter((y) => y.pairId === pairId)[0];
        const pair: IMisalignedAmendmentPairedDemandsViewModel = {
            pairId: uuid(),
            originalAmendment: JSON.parse(JSON.stringify(pairToClone.originalAmendment)) as IMisalignedAmendmentViewModel,                                
            misalignedAmendment: JSON.parse(JSON.stringify(pairToClone.misalignedAmendment)) as IMisalignedAmendmentViewModel,
            errorsResolved: false,
            splittedLineItem: true
        };
        project.pairedDemands.push(pair);
        return;
    }

    public isSplittedLineItem(): boolean {
        if (this.selectedAmendmentPair && this.selectedAmendmentPair.splittedLineItem) {
            return true;
        }
        return false;
    }

    public DeleteSplittedDemand(callbackObj: IMisalignedAmendmentCallbackObject): void {
        let pairId: string;
        let projectId: string;
        if (callbackObj) {
            pairId = callbackObj.pairId;
            projectId = callbackObj.projectId;
        }
        else{
            pairId = this.selectedAmendmentPair.pairId;
            projectId = this.selectedAmendmentPair.originalAmendment.projectId;
        }
        const project = this.misalignedAmendmentsObject.filter((x) => x.projectId === projectId)[0];
        project.pairedDemands = project.pairedDemands.filter((x) => x.pairId !== pairId);
        this.selectedAmendmentPair = undefined;
        return;
    }
    

    /**
     * Calculates the planned labor of all the misaligned amendment lineitems in the projects.
     * Does not include original demands.
     *
     * @returns {number}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public calculatePlannedLabor(project: IMisalignedAmendmentProjectViewModel, useOriginal: boolean): number {
        if (!project || !project.pairedDemands) {
            return undefined;
        }        
        if (useOriginal) {
            return project.originalRequestedQuantityTotal ? project.originalRequestedQuantityTotal : 0;
        }
        let ans: number = 0;
        for (const pair of project.pairedDemands) {
            if (pair && pair.misalignedAmendment) {
                ans += pair.misalignedAmendment.plannedLabor;
            }
        }
        return ans;
    }

    /**
    * Calculates the additional cost of all the misaligned amendment lineitems in the projects.
     * Does not include original demands.
     *
     * @returns {number}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public calculateAddtlCost(project: IMisalignedAmendmentProjectViewModel, useOriginal: boolean): number {
        if (!project || !project.pairedDemands) {
            return undefined;
        }
        if (useOriginal) {
            return project.originalPlanCostTotal ? project.originalPlanCostTotal : 0;
        }
        let ans: number = 0;
        for (const pair of project.pairedDemands) {
            if (pair && pair.misalignedAmendment) {
                ans += pair.misalignedAmendment.addtlCost;
            }
        }
        return ans;
    }

    /**
     * Calculates the additional revenue of all the misaligned amendment lineitems in the projects.
     * Does not include original demands.
     *
     * @returns {number}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    public calculateAddtlRevenue(project: IMisalignedAmendmentProjectViewModel, useOriginal: boolean): number {
        if (!project || !project.pairedDemands) {
            return undefined;
        }
        if (useOriginal) {
            return project.originalPlanRevenueTotal ? project.originalPlanRevenueTotal : 0;
        }
        let ans: number = 0;
        for (const pair of project.pairedDemands) {
            if (pair && pair.misalignedAmendment) {
                ans += pair.misalignedAmendment.addtlRevenue;
            }
        }
        return ans;
    }

    public IsTotalNotMatching(project: IMisalignedAmendmentProjectViewModel): boolean {
        if (!project || !project.pairedDemands) {
            return false;
        }        
        const containSplittedLineItem = project.pairedDemands.some((x) => x.splittedLineItem === true);
        if (containSplittedLineItem)
        {
            const originalquantity = project.originalRequestedQuantityTotal;
            const requestedquantity = this.calculatePlannedLabor(project, false);
            if (originalquantity !== requestedquantity) {
                return true;
            }
        }
        return false;
    }

    public areSAPDemandsPresentOnSplit(project: IMisalignedAmendmentProjectViewModel): boolean {
        if (!project || !project.pairedDemands) {
            return true;
        }
        const containSplittedLineItem = project.pairedDemands.some((x) => x.splittedLineItem === true);
        if (containSplittedLineItem) {
            const sourceDemands = this.demandsFromSAP.get(project.projectId);
            const currentDemands = project.pairedDemands.map((x) => x.misalignedAmendment.demandId);
            const areDemandsPresent = sourceDemands.every((z) => currentDemands.includes(z));
            return areDemandsPresent;
        }        
        return true;
    }

    public IsDuplicateDemandPresent(project: IMisalignedAmendmentProjectViewModel): boolean {
        if (!project || !project.pairedDemands) {
            return false;
        }
        const containSplittedLineItem = project.pairedDemands.some((x) => x.splittedLineItem === true);
        if (containSplittedLineItem) {
            const currentDemands = project.pairedDemands.map((x) => x.misalignedAmendment.demandId);
            if (!currentDemands.every( (e, i, a) => a.indexOf(e) === i)) {
                return true;
            }
        }        
        return false;
    }

    /**
     * Recalculate cost
     */
    private recalculateAdditionalCost(): void {
        if (this.selectedAmendmentPair.misalignedAmendment.plannedLabor === undefined || !this.selectedAmendmentPair.misalignedAmendment.costRate === undefined) { /* 0 is a valid value, so check only for undefined */
            this.selectedAmendmentPair.misalignedAmendment.addtlCost = undefined;
        }
        this.selectedAmendmentPair.misalignedAmendment.addtlCost = this.selectedAmendmentPair.misalignedAmendment.plannedLabor * this.selectedAmendmentPair.misalignedAmendment.costRate;
    }

    /**
     * Recalculate Revenue
     */
    private recalculateAdditionalRevenue(): void {
        if (this.selectedAmendmentPair.misalignedAmendment.plannedLabor === undefined || !this.selectedAmendmentPair.misalignedAmendment.billRate === undefined) { /* 0 is a valid value, so check only for undefined */
            this.selectedAmendmentPair.misalignedAmendment.addtlRevenue = undefined;
        }
        this.selectedAmendmentPair.misalignedAmendment.addtlRevenue = this.selectedAmendmentPair.misalignedAmendment.plannedLabor * this.selectedAmendmentPair.misalignedAmendment.billRate;
    }

    /**
     * Sets the green notification banner with the given message.
     */
    private setNotificationBanner(message: string): void {
        this.notificationBannerMessage = message;
        this.showNotificationBanner = true;
        setTimeout(() => { this.showNotificationBanner = false; }, 5000);        
    }

    /**
     * Saves the given project with its included misaligned amendments.
     * First converts the amendment viewmodel objects back into the expected object for the API, then calls the API.
     *
     * @private
     * @param {IMisalignedAmendmentProjectViewModel} project
     * @returns {Promise<void>}
     * @memberof MisalignedAmendmentDetailsComponent
     */
    private saveMisalignedAmendmentChanges(project: IMisalignedAmendmentProjectViewModel): Promise<void> {
        const misalignedProject: IMisalignedProject = {
            projectId: project.projectId,
            misalignmentStatus: MisalignmentStatus.Submitted,
            misalignedLineItems: [],
            originalPlanCostTotal: project.originalPlanCostTotal,
            originalPlanRevenueTotal: project.originalPlanRevenueTotal,
            originalRequestedQuantityTotal: project.originalRequestedQuantityTotal
        };
        for (const pair of project.pairedDemands) {
            misalignedProject.misalignedLineItems.push(
                this.amendmentsService.getUpdatedMisalignedAmendment(pair.misalignedAmendment, pair.splittedLineItem)
            );
        }
        return this.amendmentsService.putMisalignedAmendmentUpdates(this.engagementId, this.crNumber.toString(), [misalignedProject])
            .then(() => {
                this.setNotificationBanner(this.SAVE_PROJECT_MESSAGE);
                project.hasBeenSaved = true;
            }).catch((error) => {
                const message: string = "Unable to save changes to the project: " + error;
                this.logError(SourceConstants.Method.SaveMisalignedAmendmentChanges, message);
                this.fxpMessageService.addMessage(message, this.FXP_CONSTANTS.messageType.error);
                // temporarily not disabling the save logic 4/16/2021
                // project.hasBeenSaved = false; /* Save failed, set the hasBeenSaved to indicate the save did not complete */
            });
    }

    /**
     * Gets the project from the misaligned amendments object (the viewmodel built from the API) based on the given project ID.
     * If no project with that ID is found, we throw an error.
     *
     * @private
     * @param {string} projectId
     * @returns {IMisalignedAmendmentProjectViewModel} The project with the matching project Id, esle throws an error
     * @memberof MisalignedAmendmentDetailsComponent
     */
    private getProjectFromMisalignedAmendmentsObject(projectId: string): IMisalignedAmendmentProjectViewModel {
        if (!projectId) {
            return undefined;
        }
        const project = this.misalignedAmendmentsObject.filter((x) => x.projectId === projectId)[0];
        if (!project) {
            // throw an error
            return undefined;
        }
        return project;
    }

    /**
     * Gets the pair with the given pair Id from the specified project.
     * If no pair with the pair Id is found, we throw an error.
     *
     * @private
     * @param {string} pairId
     * @param {string} projectId
     * @returns {IMisalignedAmendmentPairedDemandsViewModel} The pair under the project with the matching pair  Id, else throws an error
     * @memberof MisalignedAmendmentDetailsComponent
     */
    private getAmendmentPairFromPairId(pairId: string, projectId: string): IMisalignedAmendmentPairedDemandsViewModel {
        const project = this.getProjectFromMisalignedAmendmentsObject(projectId);
        const pair = project.pairedDemands.filter((x) => x.pairId === pairId)[0];
        if (!pair) {
            // throw an error
            return undefined;
        }
        return pair;
    }

    /**
     * Opens validation confirmation model in case of validation error/warning when user tries to save.
     * If user gets a warning and choses to continue, this will save the warning project.
     */
    private openValidationConfirmationModal(validationResults: IProjectThresholdValues): Promise<boolean> {
        // todo log
        const modalRef = this.modalService.open(ValidationFailedModalComponent, {
            backdrop: "static",
            windowClass: "dm-modal-v2 in active",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.validationResults = validationResults;
        return modalRef.result;
    }

    /**
     * Loads the misaligned amendment details for the given engagement ID,
     * Formats the details into the viewmodel with the corresponding demand data.
     *
     * @private
     * @param {string} engagementId
     * @memberof AmendmentDetailsV2Component
     */
    private loadMisalignedAmendmentDetails(engagementId: string, changeRequestId: string): void {
        this.amendmentsService.getMisalignedAmendments(engagementId, changeRequestId)
            .then((misalignedAmendments: IMisalignedAmendment) => {
                this.misalignedAmendmentsObject = this.amendmentsService.getMisalignedAmendmentsViewModel(misalignedAmendments, this.allDemandDetails, this.entityFullDetails, this.allFinancialRoles);
                this.status = misalignedAmendments.misalignmentStatus === MisalignmentStatus.Initiated ? "In Progress" : misalignedAmendments.misalignmentStatus;
                this.crDescription = misalignedAmendments.description;
                this.lastUpdatedTimeStamp = misalignedAmendments.updatedOn;
                this.engagementLevelSubmitted = misalignedAmendments.misalignmentStatus === MisalignmentStatus.Submitted || misalignedAmendments.misalignmentStatus === MisalignmentStatus.Resolved;
                this.demandsFromSAP = new Map<string, string[]>();
                this.misalignedAmendmentsObject.map((x) => this.demandsFromSAP.set(x.projectId, x.pairedDemands.map((y) => y.originalAmendment.demandId)));
            });
    }

}

