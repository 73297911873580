import { Component, Inject } from "@angular/core";
import { ConfigManagerService } from "../../common/services/configmanager.service";

@Component({
    selector: "app-myapp-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"]
})
export class ReportsComponent {

    public reports: any;

    public constructor(@Inject(ConfigManagerService) private configmanagerService: ConfigManagerService) {
        this.configmanagerService.initialize().then(() => {
            this.reports = this.configmanagerService.getValue<any[]>("reports");
        });

    }

}
