import { Component, Input, Inject, Injector, forwardRef } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Components, LogEventConstants, SourceConstants, AccessibilityConstants, NotificationEvent } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { IContact, IContactsResponse, ICsatContact, IUpdateCsatContactRequest, ICsatContactLanguage} from "../../../../common/services/contracts/contacts.contracts";
import { ContactsService } from "../../../../common/services/contacts.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { FxpMessageService, FxpConstants, ErrorSeverityLevel, UserInfoService} from "@fxp/fxpservices";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { DMNotificationService, NotificationModel } from "../../../../common/services/dmnotification.service";
import { INotificationMessages, ISuccessMessages, IOnSuccess, IFailureMessages, IOnFailure } from "../../../../common/services/contracts/financial.service.contracts";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { getEntireEngagementDetails } from "../../../../store/engagement-details/engagement-details.selector";
import { IEngagementDetailsState } from "../../../../store/engagement-details/engagement-details.reducer";

/**
 * Updates and adds existing csat contacts to the engagement.
 * To create a new csat contact that does not yet exist for an engagement, use the 'create-csat-contact' component.
 *
 * @export
 * @class UpdateContactLanguageModalComponent
 * @extends {DmModalAbstract}
 */
@Component({
    selector: "update-contact-language",
    templateUrl: "./update-contact-language.html",
    styleUrls: ["./update-contact-language.scss"]
})
export class UpdateContactLanguageModalComponent extends DmModalAbstract {

    @Input() public wbsId: string;
    @Input() public csatContactToBeEdited: ICsatContact;
    public selectedContact: IContact;
    public selectedLanguage: ICsatContactLanguage;
    public selectedContactsList: ICsatContact[];
    public contactDetailsList: IContactsResponse;
    public filteredContactDetailList: IContact[];
    public languages: ICsatContactLanguage[];
    public loadingText: string;
    public accessibilityConstants = AccessibilityConstants;
    public contactLanguageModalTitle: string;
    public contactLanguageModalInfo: string;
    public contactLanguageModalExtraInfo: string;
    public noContactAvailableInfo: string;
    public noContactAvailable: boolean = false;
    private FXP_CONSTANTS = FxpConstants;
    private contactFormResponse: any;
    private notificationMessage: INotificationMessages;
    private createCsatContactSendTo: string[];
    private notificationSuccessMessages: IOnSuccess;
    private notificationFailureMessages: IOnFailure;
    private validCsatContactLanguages: ICsatContactLanguage[];
    private disableSubmit: boolean;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(ContactsService) private contactsService: ContactsService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(Injector) private injector: Injector,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(activeModal, dmLogger, Components.UpdateContactLanguageModal);
    }

    public ngOnInit(): void {
        this.loadingText = `Getting Contacts for ${this.wbsId}`;
        this.validCsatContactLanguages = this.configurationService.getValue<ICsatContactLanguage[]>("csatContactLanguages");
        this.filteredContactDetailList = [];
        this.isComponentLoading = true;
        this.contactsService.getContactsByWbsId(this.wbsId, true).then((contactsResponse: IContactsResponse) => {
            this.isComponentLoading = false;
            this.contactDetailsList = contactsResponse;
            for (const contact of contactsResponse.engagementContacts) {
                let isPrimaryBillContact; 
                if (contact.roles && contact.roles.length) {
                    isPrimaryBillContact = contact.roles.filter((r) => r.contactTypeCode === "Z0");
                    if (!(isPrimaryBillContact && isPrimaryBillContact.length > 0)) {
                        const csatContactLanguageList = this.validCsatContactLanguages.filter((x) => x.languageCode === contact.languageCode);
                        let csatContactLanguage;
                        if (csatContactLanguageList && csatContactLanguageList.length > 0) {
                            csatContactLanguage = csatContactLanguageList[0];
                            contact.language = csatContactLanguage;
                        }
                        this.filteredContactDetailList.push(contact);
                    }
                }
            }
            if (this.filteredContactDetailList === null || (this.filteredContactDetailList && this.filteredContactDetailList.length < 1)){
                this.noContactAvailable = true;
            } else {
                this.noContactAvailable = false;
            }
        });

        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.validCsatContactLanguages.sort((left, right) => left.languageDescription > right.languageDescription ? 1 : -1);
        this.languages = this.validCsatContactLanguages;
        this.selectedContactsList = [];
        this.contactLanguageModalTitle = "Survey Language Change Request";
        this.contactLanguageModalInfo = "Choose the CSAT contact and a language to request a change.";
        this.contactLanguageModalExtraInfo = "(The survey language change is not available for the Primary Billing contact)";
        this.noContactAvailableInfo = "No CSAT contacts available for selection. Please add a non-primary billing contact.";
            
        this.configurationService.initialize().then(() => {
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
            this.createCsatContactSendTo = this.configurationService.getValue<string[]>("createCsatContactSendTo");
            this.notificationSuccessMessages = this.configurationService.getValue<ISuccessMessages>("SuccessMessages").CsatContactLanguage;
            this.notificationFailureMessages = this.configurationService.getValue<IFailureMessages>("FailureMessages").CsatContactLanguage;
        });
    }

    /**
     * Both language selection and contact selection is mandatory for submit button to be enabled.
     *
     * @returns {boolean} True if disabled, false if enabled
     * @memberof UpdateContactLanguageModalComponent
     */
    public shouldDisableLanguageChangeSelection(): boolean {
        this.disableSubmit = true;
        if (this.selectedLanguage && this.selectedContact){
            this.disableSubmit = false;
        }
        return this.disableSubmit;
    }

    /**
     * Selects the CSAT contact
     *
     * @param {IContact} contact
     * @memberof UpdateContactLanguageModalComponent
     */
    public contactSelected(contact: IContact): void {
        this.selectedContact = contact;
    }

    /**
     * Selects the CSAT contact
     *
     * @param {IContact} contact
     * @memberof UpdateContactLanguageModalComponent
     */
    public languageSelected(language: ICsatContactLanguage): void {
        this.selectedLanguage = language;
    }

    /**
     * Updates CSAT contact of the engagement.
     *
     * @memberof UpdateContactLanguageModalComponent
     */
    public updateContactLanguage(): void {
        this.isComponentLoading = true;
        this.loadingText = "Updating CSAT Contact Language";
        if (this.selectedContactsList && this.selectedContactsList.length) {
            const updateCsatContactRequest: IUpdateCsatContactRequest = {
                updateCsatContact: []
            };
            this.selectedContactsList.forEach((c) => {
                if (c.contact.roles && c.contact.roles.length) {
                    updateCsatContactRequest.updateCsatContact.push({
                        newCsatCustomerId: c.contact.roles[0].customerId,
                        existingCsatCustomerId: this.csatContactToBeEdited != null ? this.csatContactToBeEdited.contact.roles[0].customerId : null,
                        csatContactTypeCode: c.type.code
                    });
                }
            });
        }
        if (this.selectedContact && this.selectedLanguage) {
            this.contactFormResponse = {
                name: this.selectedContact.name,
                email: this.selectedContact.email,
                phoneNumber: this.selectedContact.telephone,
                customerId: this.selectedContact.roles[0].customerId,
                existingLanguage: this.selectedContact.language.languageDescription + " (" + this.selectedContact.languageCode + ")",
                newLanguage: this.selectedLanguage.languageDescription + " (" + this.selectedLanguage.languageCode + ")",
            };
            this.sendCsatNotification();
        }
    }

    /**
     * Move focus to Next element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.UpdateCSATContactLanguage);
        }
    }

    /**
     * Remove the contact from the selected list
     * @param contact contact to be removed     
     */
    public removeFromSelectedContacts(contact: ICsatContact): void {
        this.selectedContactsList = this.selectedContactsList.filter((c) => c !== contact);
        this.filteredContactDetailList = this.getFilteredContactList();
    }

    /**
     * Returns a filtered contact list
     * @returns IContact[]
     */
    private getFilteredContactList(): IContact[] {
        // Following contains the logic for implementing a filtered contact list. Disabling this for now, but keeping it as it may be required in the future        
        // this.contactDetailsList.customerContacts.forEach((contact) => {
        //     if (!this.selectedContactsList.some((x) => x.contact === contact)) {
        //         filteredContactList.push(contact);
        //     }
        // });
        const filteredContactList = this.filteredContactDetailList;
        return filteredContactList;
    }

    

    /**
     *  creates the change properties and triggers the method to create telemetry log and send email notifications to the
     *  relevant people.
     */
    private sendCsatNotification(): void {
        const changedProperties: IChangedProperties[] = [];
        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "Name",
                "",
                this.contactFormResponse.name
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "Email",
                "",
                this.contactFormResponse.email
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "Phone",
                "",
                this.contactFormResponse.telephone
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "CustomerId",
                "",
                this.contactFormResponse.customerId
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "ExistingLanguage",
                "",
                this.contactFormResponse.existingLanguage
            )
        );

        this.pushToArrayIfTrue(
            changedProperties,
            this.createChangedPropertyObject(
                "NewLanguage",
                "",
                this.contactFormResponse.newLanguage
            )
        );

        this.createLogAndSendNotification(changedProperties);
        this.closeModal();
    }

    /**
     *  Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     *  relevant people.
     * @private
     * @param {IChangedProperties[]} changedProperties
     * @memberof CreateCSATContactModalComponent
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.CreateContactModal, SourceConstants.Method.CreateLogAndSendNotification, LogEventConstants.SubmittedCreateCSATContactForm, propertyBag);

        const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.wbsId));
        engagementDetails$.pipe(untilDestroyed(this)).subscribe((engagementDetails: IEngagementDetailsState) => {
            if (engagementDetails.loaded) {
                const esxpNotification = this.notificationMessage.CsatContactNotification;

                const notification = new NotificationModel();
                notification.engagementId = this.wbsId;
                notification.engagementName = engagementDetails.engagementDetails.name;
                notification.attributes = {
                    FullName: this.contactFormResponse.name,
                    Email: this.contactFormResponse.email,
                    CustomerId: this.contactFormResponse.customerId,
                    PhoneNo: this.contactFormResponse.phoneNumber,
                    ExistingLanguage: this.contactFormResponse.existingLanguage,
                    NewLanguage: this.contactFormResponse.newLanguage
                };
                notification.eventName = NotificationEvent.UpdateCSATContactLanguage;
                notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(this.createCsatContactSendTo);
                /* Add requester to the CC list, story #8111081  */
                const cc: string[] = this.sharedFunctionsService.getListofPjmV2(engagementDetails.engagementDetails).concat(userAlias);
                notification.cc = this.sharedFunctionsService.getArrayWithoutDupes(cc);
                notification.modifiedBy = userAlias;
                notification.modifiedDate = new Date();
                this.notificationService.sendNotification(notification, false, esxpNotification);
                this.fxpMessageService.addMessage(this.notificationSuccessMessages.OnSuccess, this.FXP_CONSTANTS.messageType.success);
            } else {
                this.fxpMessageService.addMessage(this.notificationFailureMessages.OnFailure, this.FXP_CONSTANTS.messageType.error);
            }
        });
    }

    /**
     * Pushes the given value to the given array if the value evaluates as truthy.
     * (If the value is not null or undefined, false, or the number 0.)
     * Modifies the given array by reference, and does not need to return.
     */
    private pushToArrayIfTrue(array: IChangedProperties[], value: IChangedProperties): void {
        if (value) {
            array.push(value);
        }
    }

    /**
     * If the given objects are different, creates and returns a changed property object.
     * Returns undefined if the objects have not changed.
     * @param attributeName
     * @param originalValue
     * @param updatedValue
     */
    private createChangedPropertyObject(attributeName: string, originalValue: string, updatedValue: string): IChangedProperties {
        if (originalValue !== updatedValue) {
            return {
                name: attributeName,
                oldValue: originalValue,
                newValue: updatedValue
            };
        }
        return undefined;
    }

    /**
     * Creates a log string from the given changed properties: combines all the changed attribute names into a single, comma seperated string.
     * @param changedProperties
     */
    private createLogStringFromChangedProperties(changedProperties: IChangedProperties[]): string {
        let logString: string = "";
        changedProperties.forEach((property, index) => {
            logString += property.name;
            if (index !== changedProperties.length - 1) {
                logString += ",";
            }
        });
        return logString;
    }

}
