<div class="dm-hidden-placeholder"></div>
<div class="tab-content dm-tab-content">  
    <ui-view name="summary" [hidden]="!uiRouter.stateService.is(routeName.ProjectSummary)"></ui-view>  
    <ui-view name="financials" [hidden]="!uiRouter.stateService.is(routeName.ProjectFinancials)"></ui-view>
    <ui-view name="ebs" [hidden]="!uiRouter.stateService.is(routeName.ProjectManageEBS)"></ui-view>
    <ui-view name="staffing" [hidden]="!uiRouter.stateService.is(routeName.ProjectStaffing)"></ui-view>
    <ui-view name="manageSuppliers" [hidden]="!uiRouter.stateService.is(routeName.ProjectManageSuppliers)"></ui-view>
    <ui-view name="milestones" [hidden]="!uiRouter.stateService.is(routeName.ProjectMilestones)"></ui-view>
    <ui-view name="invoices" [hidden]="!uiRouter.stateService.is(routeName.ProjectInvoices)"></ui-view>
    <ui-view name="changeRequest" [hidden]="!uiRouter.stateService.is(routeName.ProjectFinancialChangeRequest)">
    </ui-view>
    <ui-view name="changeRequestForm" [hidden]="!uiRouter.stateService.is(routeName.ProjectFinancialChangeRequestForm)">
    </ui-view>
    <ui-view name="amendmentDetails" [hidden]="!uiRouter.stateService.is(routeName.ProjectAmendmentDetails)"></ui-view>
    <ui-view name="units" [hidden]="!uiRouter.stateService.is(routeName.ProjectActuals)"></ui-view>
    <ui-view name="nonProcuredMaterials" [hidden]="!uiRouter.stateService.is(routeName.ProjectNonProcuredMaterials)">
    </ui-view>
    <ui-view name="contactDetails" [hidden]="!uiRouter.stateService.is(routeName.ProjectContactDetails)"></ui-view>
    <ui-view name="summaryv2" [hidden]="!uiRouter.stateService.is(routeName.ProjectSummaryV2)"></ui-view>
    <ui-view name="wbsdetails" [hidden]="!uiRouter.stateService.is(routeName.ProjectWbsDetails)"></ui-view>
    <ui-view name="misalignedAmendmentDetails" [hidden]="!uiRouter.stateService.is(routeName.ProjectMisalignedAmendmentDetails)"></ui-view>
    <!-- plan&forecast tab must be inside a div rather than a ui-view since it is the one tab that should start loading immediately
when user clicks into the project -->
    <div [style.display]="uiRouter.stateService.includes(routeName.ProjectPlanForecast) ? 'block' : 'none'">
        <dm-plan-forecast></dm-plan-forecast>
    </div>
</div>