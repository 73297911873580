import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services } from "../../common/application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { IConcurPendingReportsResponse } from "./contracts/concur.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class ConcurService extends DmServiceAbstract {
    private concurServiceUri: string;
    private subscriptionKey: string;

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ConcurService );
    }

    /**
     * Calls the Concur API to get the pending expense reports based on the query string
     * @param queryString 
     */
    public getPendingReportsforApproval(queryString: string): Promise<IConcurPendingReportsResponse[]> {
        this.concurServiceUri = this.configManagerService.getValue<string>("concurServiceBaseUri") + "/expenseReports/pendingapproval?" + queryString;
        this.subscriptionKey = this.configManagerService.getValue<string>("salesOrderServiceSubscriptionKey");
        return this.dataService.getData(this.concurServiceUri, this.subscriptionKey, APIConstants.ConcurGetPendingApproval, [401, 500, 502]);
    }

}