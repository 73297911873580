import { ErrorSeverityLevel, FxpLoggerService, UserInfoService } from "@fxp/fxpservices";
import { Injectable, Inject } from "@angular/core";
import { APIConstants, Services, SourceConstants } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";
import { IFaultEventData, ICallbackDetails, IGetFaultsResponse, IReprocessResponse } from "./contracts/faultcontrol.contracts";
import { IGrmProjectCreateFunctionRequest } from "../../components/new-internal-engagement/internal-engagement-grm.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { SharedFunctionsService } from "./sharedfunctions.service";
// import { IGRMRoleCreationObject, IGRMAddRoleObject } from "components/new-internal-engagement/internal-engagement-grm.contracts";

@Injectable()
export class FaultControlService extends DmServiceAbstract {

    private readonly faultApiUri: string;
    private readonly fxpApplicationId: string;

    public constructor(
        @Inject(FxpLoggerService) private fxpLoggerService: FxpLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
    ) {
        super(dmLogger, Services.FaultControlService );
        this.faultApiUri = configManagerService.getValue<string>("faultControlApiUri");
        this.fxpApplicationId = configManagerService.getValue<string>("fxpAzureAdAppId");
    }

    /**
     * Log an error fault that came from trying to create an internal project on the GRM API
     * @param errorResponse 
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public logGrmCreateInternalProjectFault(errorResponse: any): void {
        if (!errorResponse) {
            throw Error("errorResponse cannot be null");
        }

        const callbackInfo: ICallbackDetails = this.createCallBackDetails(errorResponse);
        const grmRequest = errorResponse.config.data as IGrmProjectCreateFunctionRequest;
        const fault: IFaultEventData = {
            AppAction: "Create Internal Project in GRM",
            BusinessProcessName: "FaultEvent",
            CallbackDetails: JSON.stringify(callbackInfo),
            ComponentType: "Web",
            EventOccurrenceTime: new Date().toISOString(),
            EventType: "BusinessProcessEvent",
            FailureMetadata: JSON.stringify({
                EngagementId: (grmRequest.engagementInfo) ? grmRequest.engagementInfo.id : "",
                OpportunityId: "",
                Domain: (grmRequest.projectDetail) ? (grmRequest.projectDetail.projectDomain) : "",
                CustomerName: (grmRequest.engagementInfo) ? grmRequest.engagementInfo.customerName : "",
            }),
            FriendlyMessage: "Internal Project Creation Failed",
            InitiatedBy: (grmRequest.projectDetail) ? grmRequest.projectDetail.creatorAlias : "",
            IsShownToUser: true,
            MsitPartB: true,
            Notes: JSON.stringify(errorResponse.data),

            // FXP App ID : SIT
            OriginatingSystemId: this.fxpApplicationId,
            PlaybackType: "ReSubmit",
            TemplateType: "Internal.BusinessProcessEvent",
            XCV: callbackInfo.Headers["X-CorrelationId"]
        };

        const propertyBag = this.fxpLoggerService.createPropertyBag();
        for (const property in fault) {
            propertyBag.addToBag(property, fault[property]);
        }

        // Log the error in AppInsights
        const errorMessage = this.sharedFunctionsService.getErrorMessage(errorResponse, "");
        this.logError(SourceConstants.Method.LogGrmCreateInternalProjectFault, "FaultControl", errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
    }

    /**
     * Log a role fault for the GRM Add Role API call
     * @param errorResponse 
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public logGrmAddRoleFault(errorResponse: any): void {
        if (!errorResponse) {
            throw Error("errorResponse cannot be null");
        }
        const callbackInfo: ICallbackDetails = this.createCallBackDetails(errorResponse);
        const grmRequest = errorResponse.config.data as any;

        const fault: IFaultEventData = {
            AppAction: "Add Role to Internal Project in GRM",
            BusinessProcessName: "FaultEvent",
            CallbackDetails: JSON.stringify(callbackInfo),
            ComponentType: "Web",
            EventOccurrenceTime: new Date().toISOString(),
            EventType: "BusinessProcessEvent",
            FailureMetadata: JSON.stringify({
                EngagementId: grmRequest.ResourceRequests[0].DemandResourceRequest.WBSL0ID,
                Role: grmRequest.ResourceRequests[0].Role,
                DemandId: grmRequest.ResourceRequests[0].DemandResourceRequest.DemandId,
                SapWorkPackageId: grmRequest.ResourceRequests[0].DemandResourceRequest.SapWorkPackageId
            }),
            FriendlyMessage: "Adding Role to Internal Project Failed",
            InitiatedBy: this.fxpUserInfoService.getCurrentUserData().alias,
            IsShownToUser: true,
            MsitPartB: true,
            Notes: JSON.stringify(errorResponse.data),

            // FXP App ID : SIT
            OriginatingSystemId: this.fxpApplicationId,
            PlaybackType: "ReSubmit",
            TemplateType: "Internal.BusinessProcessEvent",
            XCV: callbackInfo.Headers["X-CorrelationId"]
        };

        const propertyBag = this.fxpLoggerService.createPropertyBag();
        for (const property in fault) {
            propertyBag.addToBag(property, fault[property]);
        }

        // Log the error in AppInsights
        const errorMessage = this.sharedFunctionsService.getErrorMessage(errorResponse, "");
        this.logError(SourceConstants.Method.LogGrmAddRoleFault, "FaultControl",  errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
    }

    /**
     * Get faults for given Engagement ID
     * @param engagementId 
     */
    public getFaults(engagementId: string): Promise<IGetFaultsResponse> {
        const url = `${this.faultApiUri}/Getfaults?query.top=300&query.order=DESC&query.applicationId=${this.fxpApplicationId}&query.metadatafilter=EngagementId eq "${engagementId}"`;
        return this.dataService.getData<IGetFaultsResponse>(url, "", APIConstants.GetFaultsApi);
    }

    /**
     * Reprocess a specific fault given its Fault ID
     * @param faultId 
     */
    public reprocessFault(faultId: string): Promise<IReprocessResponse> {
        const url = `${this.faultApiUri}/Playback?faultId=${faultId}&action=re-process`;
        return this.dataService.postData<IReprocessResponse>(url, "", APIConstants.ReprocessFaultApi, null);
    }

    /**
     * Creates callback details from an error response from GRM API
     * @param errorResponse 
     */
    private createCallBackDetails(errorResponse: any): ICallbackDetails { 
        const callback: ICallbackDetails = {
            TargetSystemId: this.configManagerService.getValue<string>("grmAzureAdAppId"),
            ContentType: "application/json",
            URL: errorResponse.config.url,
            Headers: errorResponse.config.headers,
            HttpMethod: { Method: "POST" },
            PayLoad: JSON.stringify(errorResponse.config.data)
        };

        /* Delete headers which are not needed to be logged. */
        delete callback.Headers["Authorization"];
        delete callback.Headers["Accept"];
        delete callback.Headers["Content-Type"];
        return callback;
    }
}