import { Component, EventEmitter, forwardRef, Inject, Input, Output } from "@angular/core";
import { FxpConstants, FxpEventBroadCastService, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { INotification, NotificationType } from "../../../common/services/contracts/notification-bar.contracts";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { SyncEACdetailsUI } from "../../../common/services/contracts/sync-eac-details-ui.contract";
import { BroadcastEvent, LogEventConstants, SourceConstants } from "../../../common/application.constants";
import { DmError } from "../../../common/error.constants";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-sync-eac-to-plan-submit",
    templateUrl: "./sync-eac-to-plan-submit.component.html",
    styleUrls: ["./sync-eac-to-plan-submit.component.scss"]
})

export class SyncEACToPlanSubmitComponent {

    @Input() public syncEACPlanData: SyncEACdetailsUI;
    @Input() public selectedEntityId: string;
    @Output() public closeSyncEACToPlanPanel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public refreshForecastInformation: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public submitClicked: EventEmitter<any> = new EventEmitter<any>();

    public isSubmitInProgress = false;
    public isSubmitFailed = false;
    public errorMessage = "";
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(FxpEventBroadCastService) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(DMLoggerService) private dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionService: SharedFunctionsService,
    ) { }

    public ngOnInit(): void {
        this.fxpBroadcastService.On(BroadcastEvent.SyncEACToPlanCompleted, (event, args) => {
            this.refreshForecastInformation.emit(true);
        });
    }

    public handleSyncEACToPlanSubmit(): void {
        /* eslint-disable object-shorthand */
        const PjmXCV = this.dmLogger.generateGuid();
        const logproperties = {
            orchestrationId: this.syncEACPlanData.GUID,
            Status: "Success",
            selectedEntityId: this.selectedEntityId,
            WbsId: this.selectedEntityId,
            EntityType: this.sharedFunctionService.getEntityType(this.selectedEntityId),
            PjmXCV: PjmXCV
        };
        /* eslint-disable object-shorthand */
        this.dmLogger.logEvent(SourceConstants.Component.SyncEACToPlanSubmitComponent, SourceConstants.Method.HandleSyncEACToPlanSubmit
            , LogEventConstants.BPE.SyncEACToPlanClicked, logproperties);

        if (this.syncEACPlanData && this.selectedEntityId) {
            this.resetAllFlags();
            const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

            this.notificationService.submitEACToPlanRequest(this.syncEACPlanData)
                .then((response: any) => {
                    if (response) {
                        /* eslint-disable object-shorthand */
                        const properties = {
                            orchestrationId: this.syncEACPlanData.GUID,
                            Status: "Success",
                            WbsId: this.selectedEntityId,
                            EntityType: this.sharedFunctionService.getEntityType(this.selectedEntityId),
                            PjmXCV: PjmXCV,
                        };
                        /* eslint-disable object-shorthand */
                        this.dmLogger.logEvent(SourceConstants.Component.SyncEACToPlanSubmitComponent, SourceConstants.Method.HandleSyncEACToPlanSubmit
                            , LogEventConstants.BPE.SubmitSyncEACToPlanRequest, properties);
                        const notificationEntry: INotification = {
                            userId: loggedInUserData.BusinessPartnerId,
                            id: this.syncEACPlanData.GUID,
                            type: NotificationType.SyncEACStatusChange,
                            entityId: this.selectedEntityId,
                            createdBy: loggedInUserData.BusinessPartnerId,
                            createdOn: new Date().toISOString()
                        };

                        this.notificationService.createNotificationSubscriptionEntry(NotificationType.SyncEACStatusChange, loggedInUserData.BusinessPartnerId, this.selectedEntityId, this.syncEACPlanData.GUID)
                            .then(() => {

                                this.fxpBroadcastService.emit(BroadcastEvent.SyncToPlanSubmitted, { newNotification: notificationEntry });
                                this.isSubmitInProgress = false;
                                this.isSubmitFailed = false;
                                this.errorMessage = "";
                                this.fxpMessageService.addMessage(DmError.SyncEACToPlanMessages.SyncEACToPlanSubmitSuccess, this.FXP_CONSTANTS.messageType.success, false);
                                this.submitClicked.emit({ orchestrationId: this.syncEACPlanData.GUID, orchestrationInProgress: true });
                                this.closeSyncEACToPlanPanel.emit(true);
                            });
                    }
                }).catch((error: any) => {
                    /* eslint-disable object-shorthand */
                    const properties = {
                        orchestrationId: this.syncEACPlanData.GUID,
                        Status: "Failure",
                        selectedEntityId: this.selectedEntityId,
                        WbsId: this.selectedEntityId,
                        EntityType: this.sharedFunctionService.getEntityType(this.selectedEntityId),
                        error: error,
                        PjmXCV: PjmXCV
                    };
                    /* eslint-disable object-shorthand */
                    this.dmLogger.logEvent(SourceConstants.Component.SyncEACToPlanSubmitComponent, SourceConstants.Method.HandleSyncEACToPlanSubmit
                        , LogEventConstants.BPE.SubmitSyncEACToPlanRequest, properties);
                    this.isSubmitInProgress = false;
                    if (error && error.data && parseInt(error.status, 10) === DmError.HttpConstants.NOT_ACCEPTABLE) {
                        this.errorMessage = error.data;
                        this.isSubmitFailed = true;
                    }
                    else {
                        this.errorMessage = DmError.SyncEACToPlanMessages.SyncEACToPlanSubmitFailure;
                        this.fxpMessageService.addMessage(DmError.SyncEACToPlanMessages.SyncEACToPlanSubmitFailure, this.FXP_CONSTANTS.messageType.error, false);
                        this.closeSyncEACToPlanPanel.emit(true);
                    }
                });
        }
    }

    public getFormattedErrorText(): string {
        if (this.errorMessage) {
            const errorMessageSplitArray = this.errorMessage.split(" ");

            if (errorMessageSplitArray && errorMessageSplitArray.length) {
                for (const message of errorMessageSplitArray) {
                    if (message && message.startsWith("ProjectId")) {
                        const syncToPlanErrorArray = message.split(",");

                        if (syncToPlanErrorArray && syncToPlanErrorArray.length && syncToPlanErrorArray.length === 3) {
                            const projectArray = syncToPlanErrorArray[0].split(":");
                            const taskArray = syncToPlanErrorArray[1].split(":");
                            const demandArray = syncToPlanErrorArray[2].split(":");
                            if (demandArray && demandArray.length && demandArray[1]) {
                                // Demand in progress
                                return `Sync EAC to Plan is currently in progress for the demand ${demandArray[1]}. Please wait until the request is completed.`;
                            }
                            else if (taskArray && taskArray.length && taskArray[1]) {
                                // Task in progress
                                return `Sync EAC to Plan is currently in progress for the task ${taskArray[1]}. Please wait until the request is completed.`;
                            }
                            else {
                                // Project in progress
                                return `Sync EAC to Plan is currently in progress for the engagement ${projectArray[1]}. Please wait until the request is completed.`;
                            }
                        }
                    }
                }
            }
        }

        return "";
    }

    private resetAllFlags(): void {
        this.isSubmitInProgress = false;
        this.isSubmitFailed = false;
        this.errorMessage = "";
    }
}