<section *ngIf="notificationList && notificationList.length" id="notification-v2-bar">
  <ng-container *ngFor="let notification of notificationList">
    <ng-container *ngIf="notification.type===notificationType.SyncEACStatusChange">
      <dm-sync-eac-to-plan-notification-v2 [subscriptionId]="notification.id">
      </dm-sync-eac-to-plan-notification-v2>
    </ng-container>
  </ng-container>
</section>






















<!-- <section *ngIf="notificationList.length" id="notification-v2-bar" >
  <div class="notifications-bar__expanded" [ngClass]="{'display-none-imp': !showNotifications}">
    <ng-container *ngFor="let notification of notificationList">
      <div class="notifications-bar__item">
        <ng-container *ngIf="notification.type===notificationType.ReleaseAndActivate">
          <dm-release-activate-notification [subscriptionId]="notification.id" [entityId]="notification.entityId">
          </dm-release-activate-notification>
        </ng-container>
        <ng-container *ngIf="notification.type===notificationType.ProjectClsoure">
          <dm-wbs-status-change-notification [subscriptionId]="notification.id" [entityId]="notification.entityId">
          </dm-wbs-status-change-notification>
        </ng-container>
        <ng-container *ngIf="notification.type===notificationType.SyncEACStatusChange">
          <dm-sync-eac-to-plan-notification [subscriptionId]="notification.id">
          </dm-sync-eac-to-plan-notification>
        </ng-container>
        <ng-container *ngIf="notification.type===notificationType.BulkUpload">
          <dm-bulk-create-notification [subscriptionId]="notification.id" [entityId]="notification.entityId"
            [createdOn]="notification.createdOn">
          </dm-bulk-create-notification>
        </ng-container>
        <ng-container *ngIf="notification.type===notificationType.DateChange">
          <dm-date-change-notification [subscriptionId]="notification.id" [entityId]="notification.entityId">
          </dm-date-change-notification>
        </ng-container>
        <ng-container *ngIf="notification.type===notificationType.RiskReserve">
          <dm-risk-reserve-notification [subscriptionId]="notification.id" [entityId]="notification.entityId">
          </dm-risk-reserve-notification>
        </ng-container>
        <ng-container *ngIf="notification.type===notificationType.BulkMaintenance">
          <bulk-maintenance-notification [notificationDetail]="notification" [subscriptionId]="notification.id"
            [entityId]="notification.entityId">
          </bulk-maintenance-notification>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="notifications-bar__collapsed" *ngIf="!showNotifications">
    <div class="notifications-bar__collapsed-text" *ngIf="notificationList.length > 0">{{ notificationList.length }}
      <span *ngIf="notificationList.length === 1"> Notification </span>
      <span *ngIf="notificationList.length > 1"> Notifications </span> |
    </div>
  </div>
  <div class="notifications-bar__toggle">
    <div *ngIf="!showNotifications" (click)="toggleNotificationsBar()">
      Show all
    </div>
    <div *ngIf="showNotifications" (click)="toggleNotificationsBar()" class="notifications-bar__toggle-fixed">
      Hide
    </div>
  </div>
</section> -->