import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../entry/common-components.module";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { RecentlyViewedComponent } from "./recently-viewed-component";

@NgModule({
    declarations: [
        RecentlyViewedComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule
    ],
    providers: [
        ConfigManagerService,
        DMLoggerService,
    ],
    entryComponents: [
        RecentlyViewedComponent
    ],
    exports: [
        RecentlyViewedComponent
    ],
})
export class RecentlyViewedModule { }
