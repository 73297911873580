import { IState } from "../reducers";
import { IBulkCreateNotificationState } from "./bulk-internal-engagement-status-notifications.reducer";

export const getBulkIntEngStatusNotifications = (store: IState): { [referenceId: string]: IBulkCreateNotificationState } => store.bulkIntEngStatusNotifications;

export const getBulkIntEngStatusNotificationsByReferenceId = (referenceId: string) => (store: IState): IBulkCreateNotificationState => {
    if (store.bulkIntEngStatusNotifications && Object.keys(store.bulkIntEngStatusNotifications).length > 0) {
        return store.bulkIntEngStatusNotifications[referenceId];
    } else {
        return null;
    }
};