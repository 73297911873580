import { Component, forwardRef, Inject, Input, SimpleChanges } from "@angular/core";
import { DeviceFactoryProvider, FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { StateService } from "@uirouter/angular";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { getEntireInvoices } from "../../../store/invoices/invoices.selector";
import { IInvoiceItemModel, IProjectDataToFilter } from "./invoice-table-data.contract";
import { IInvoicesState } from "../../../store/invoices/invoices.reducer";
import { InvoiceService } from "../../../common/services/invoices.service";
import { IState } from "../../../store/reducers";
import { RouteName, Components, ComponentFailureMessages, SourceConstants, LogEventConstants, SortColumnName, NoDataText, AccessibilityConstants } from "../../../common/application.constants";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../../common/error.constants";

export enum SortOptions {
    DocumentNumber = "invoiceNumber",
    DocumentType = "documentType",
    InvoiceTotal = "invoiceTotal",
    Currency = "currency",
    Status = "status",
    PeriodStart = "startDate",
    PeriodEnd = "endDate",
    PaidDate = "paidDate",
    Timestamp = "timestamp",
    Project = "project"
}

@Component({
    selector: "dm-invoice-table-data",
    templateUrl: "./invoice-table-data.html",
    styleUrls: ["./invoice-table-data.scss"],
})
export class InvoiceTableDataComponent extends DmComponentAbstract {

    @Input() public selectedProjectId: string; /* Used to filter the invoices based on context & selection */
    @Input() public selectedStatus: string; /* Used to filter the invoices based on status, ie Parked, Approved, Paid, etc... */
    @Input() public invoiceNumber: string; /*  Used to filter the invoices based on the selected invoice number*/
    @Input() public showCaption: boolean;
    @Input() public limitInvoices: number;
    @Input() public sortVal: SortOptions;
    @Input() public isCurrentUserPartOfTeamStructure: boolean; // Allow downloadpdf only for users part of the team

    public invoiceList: IInvoiceItemModel[];
    public sortAscy: boolean = false;
    public loadingText: string = "Loading Invoices";
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public SortOptions = SortOptions;
    public sortOrder: string;
    public tableCaption: string = "Customer Invoices";
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.CustomerInvoices;
    public tileContent: ITile;
    public noInvoicesText = NoDataText.NoInvoices;
    public accessibilityConstants = AccessibilityConstants;

    private readonly FXP_CONSTANTS = FxpConstants;
    private invoiceListCopy: IInvoiceItemModel[];

    /**
     * Creates an instance of InvoiceTableDataComponent
     * @param {DeviceFactoryProvider} deviceFactory
     * @param {InvoiceService} invoiceService
     * @param {SharedFunctionsService} sharedFunctionsService
     * @param {ConfigManagerService} configurationService
     * @param {StateService} stateService
     * @param {EngagementDetailService} engagementDetailService
     * @memberof InvoiceTableDataComponent
     */
    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(InvoiceService) private invoiceService: InvoiceService,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(StateService) private stateService: StateService
    ) {
        super(dmLogger, Components.CustomerInvoicesTableData);
    }

    public ngOnInit(): void {
        this.errorText = ComponentFailureMessages.EngagementInvoiceDataComponent;
        this.tileContent = {
            title: !this.showCaption ? "Customer Invoices" : ""
        };
        const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        const invoices$ = this.store.select(getEntireInvoices(engagementId));

        invoices$.pipe(untilDestroyed(this)).subscribe((invoices: IInvoicesState) => {
            this.refreshOnItemInvalidation(invoices);
            this.setErrorsBasedOnItemState(invoices);
            this.setLoadersBasedOnItemState(invoices);
            if (invoices.loaded) {
                const invoiceList: IInvoiceItemModel[] = invoices.invoices;
                /* From the list of invoices, if the selection filter is set to a selectedProjectId, 
                then search the list for the items where the selected project ID is inside the project list. 
                Otherwise, if no project Id is selected, just return the whole invoice list.*/
                this.invoiceList = this.selectedProjectId ?
                    invoiceList.filter((invoice: IInvoiceItemModel) =>
                        invoice.project.filter((project: IProjectDataToFilter) => project.projectId === this.selectedProjectId).length
                    )
                    : invoiceList;
                this.invoiceListCopy = [...this.invoiceList];
                this.sortOrder = this.sortAscy ? "Ascending" : "Descending";
                this.tableCaption = "Customer Invoices sorted by " + this.sortVal + " in descending order.";
                this.sharedFunctionsService.sortListByPropertyBasedOnOrder(this.sortVal, this.sortAscy, this.invoiceList);
            }
            if (invoices.error) {
                this.isServerError = true;
            }
        });

    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (propName === "selectedProjectId" && changes[propName].previousValue) {
                if (changes[propName].previousValue.toLowerCase() !== changes[propName].currentValue.toLowerCase()) {
                    this.invoiceList = this.selectedProjectId ?
                        this.invoiceListCopy.filter((invoice: IInvoiceItemModel) =>
                            invoice.project.filter((project: IProjectDataToFilter) => project.projectId === this.selectedProjectId).length)
                        : this.invoiceListCopy;
                }
            }
        }
    }

    /**
     * Sorting invoice list with column name
     *
     * @param {string} sortName
     * @memberof InvoiceTableDataComponent
     */
    public invoiceSort(sortName: SortOptions): void {
        this.sortAscy = (this.sortVal === sortName) ? !this.sortAscy : true;
        this.sortVal = sortName;
        this.sortOrder = this.sortAscy ? "Ascending" : "Descending";
        this.tableCaption = "Customer Invoices sorted by " + this.sortVal + " in " + this.sortOrder + " order.";
        this.sharedFunctionsService.sortListByPropertyBasedOnOrder(this.sortVal, this.sortAscy, this.invoiceList);
        const propertyBag = {};
        propertyBag[SortColumnName] = sortName.toString();
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.InvoiceSort, LogEventConstants.SortInvoiceList, propertyBag);
    }
    /**
     * Logs an event when Go to Invoices Link is Clicked
     */
    public logInvoiceClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.LogInvoiceClick, LogEventConstants.InvoiceNavigationClick);
    }

    /**
     * Logs an event when Document Number for a List Item
     * is clicked
     */
    public logDocListItemClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.LogDocListItemClick, LogEventConstants.InvoiceDocumentNumberClick);
    }

    /**
     * Logs an event when Go to Project Link on a List Item is Clicked
     */
    public logProjectClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.LogProjectClick, LogEventConstants.ProjectNavigation);
    }
    /**
     * Logs an event when user clicks on more details icon for a list item
     */
    public logMoreInfoClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.EngagementSummaryPage, SourceConstants.Method.LogMoreInfoClick, LogEventConstants.InvoiceMoreInfoClick);
    }


    /**
     * Get invoice pdf number
     *
     * @private
     * @param {number} invoiceNumber
     * @returns {*}
     * @memberof InvoiceTableDataComponent
     */
    public getInvoicePDF(invoiceNumber: number): void {
        this.loadingText = "Loading PDF for " + invoiceNumber.toString();
        this.isComponentLoading = true;

        this.invoiceService.getInvoicesPDFByDocumentID(invoiceNumber)
            .then((response) => {
                const newBlob = new Blob([response], { type: "application/pdf" });

                /* IE doesn't allow using a blob object directly as link href
                instead it is necessary to use msSaveOrOpenBlob */
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    const filename = response.name + ".pdf";
                    window.navigator.msSaveOrOpenBlob(newBlob, filename);
                    this.isComponentLoading = false;
                    return;
                }

                /* For other browsers:
                Create a link pointing to the ObjectURL containing the blob. */
                const data = window.URL.createObjectURL(newBlob);
                const link = document.createElement("a");
                link.href = data;
                link.download = invoiceNumber.toString() + ".pdf";
                link.click();
                this.isComponentLoading = false;
            })
            .catch((error: any) => {
                let errorText: string; 
                if (error.status === 404) {
                    errorText = this.configurationService.getValue<any>("CustomerInvoices").NoPDFforInvoice + invoiceNumber.toString();
                    this.fxpMessageService.addMessage(errorText, this.FXP_CONSTANTS.messageType.warning);
                } else {
                    errorText = this.configurationService.getValue<any>("CustomerInvoices").RetrievePdfApiFailure + invoiceNumber.toString();
                    this.fxpMessageService.addMessage(errorText, this.FXP_CONSTANTS.messageType.error);
                }
                this.logError(SourceConstants.Method.GetInvoicePDF, error, errorText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isComponentLoading = false;
            });
    }
}
