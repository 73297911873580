import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { Components, LogEventConstants, OperationName, ResourceName, RouteName, SourceConstants, TooltipText, NoDataText } from "../../../../../common/application.constants";
import { ConfigManagerService } from "../../../../../common/services/configmanager.service";
import { DMAuthorizationService } from "../../../../../common/services/dmauthorization.service";
import { DmComponentAbstract } from "../../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../../../common/services/engagement-detail.service";
import { IEngagementDetails, IEngagementSearchResult, IProjectDetails, IProjectSearchResult, IContractType } from "../../../../../common/services/contracts/project.service.contracts";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { ITile } from "../../../../../components/tiles/dm-tile/dm-tile.component";

@Component({
    selector: "dm-customer-engagement",
    templateUrl: "./customer-engagement.html",
    styleUrls: ["./customer-engagement.scss"]
})
export class CustomerEngagementComponent extends DmComponentAbstract {
    @Input() public associatedCustomerEngagementList: IEngagementSearchResult[];
    public customerEngagementDetails: IEngagementSearchResult[];
    public isLoading: boolean = false;
    public selectedProjects: IProjectSearchResult[];
    public noAssociatedEngagementFoundText: string;
    public currentPage: number = 1;
    public gridItemsDisplay: number = 5;
    public portfolioItemsDisp: number = 5;
    public slicedItemsNumber: number = 0;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public tooltipText: string = TooltipText.EBSState;
    public tileContent: ITile;
    private contractTypeColors: IContractType[];
    private projectDetailCache: Array<{ id: string; project: IProjectDetails }> = [];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(DMLoggerService) private dmLoggerService: DMLoggerService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService
    ) {
        super(dmLoggerService, Components.CustomerEngagement);
    }

    public ngOnInit(): void {
        this.configurationService.initialize()
            .then(() => {
                this.noAssociatedEngagementFoundText = NoDataText.NoAssociatedCustomerEngagements;
                this.contractTypeColors = this.configurationService.getValue<IContractType[]>("projEngContractType");
            });

        this.getCustomerEngagementDetails();
        this.tileContent = {
            title: "Associated Customer Delivey Engagements"
        };
    }

    /**
     * Changes the current page, grid items to display and sliced number on changing the page
     * @param currentPage
     */
    public currentPageChangedHandler(currentPage: number): void {
        if (this.currentPage < currentPage) {
            this.slicedItemsNumber = this.gridItemsDisplay;
            this.gridItemsDisplay = this.gridItemsDisplay + this.portfolioItemsDisp;
        } else {
            this.slicedItemsNumber = this.slicedItemsNumber - this.portfolioItemsDisp;
            this.gridItemsDisplay = this.gridItemsDisplay - this.portfolioItemsDisp;
        }
        this.currentPage = currentPage;
    }

    /**
     * Get customer engagement details on basis of search criteria.
     */
    private getCustomerEngagementDetails(): void {
        this.isLoading = true;
        this.dmLoggerService.logEvent(SourceConstants.Component.EngagementDetailsPage, SourceConstants.Method.GetCustomerEngagementDetails, LogEventConstants.AssociatedCustomerEngagements);
        this.customerEngagementDetails = [];
        for (const e of this.associatedCustomerEngagementList) {
            if (!this.sharedFunctionsService.isEngagementInternal(e)) {
                e.contractType = this.sharedFunctionsService.getContractType(e.projectDetails);
                e.typeColorCode = this.engagementDetailService.getTypeColorCode(this.contractTypeColors, e.contractType);
                e.showLinkToUser = this.isUserAuthorizedToView(e, null);
                for (const project of e.projectDetails) {
                    project.typeColorCode = this.engagementDetailService.getTypeColorCode(this.contractTypeColors, project.contractType);
                    project.showLinkToUser = this.isUserAuthorizedToView(null, project);
                }
                this.customerEngagementDetails.push(e);
            }
        }
        this.isLoading = false;
    }


    /**
     * To check is user is authorized to view the section.
     */
    private isUserAuthorizedToView(engagementDetail: IEngagementSearchResult, projectDetail: IProjectSearchResult): boolean {
        if (engagementDetail) {
            const currEngagement: IEngagementDetails = this.convertEngagementSearchResultToEngagementDetails(engagementDetail);
            return this.dmAuthorizationService.hasPermission(ResourceName.SAP_Engagement, OperationName.view, currEngagement, null);
        }
        if (projectDetail) {
            let currProject: IProjectDetails = this.cachedProject(projectDetail.projectId);
            if (!currProject) {
                currProject = this.convertProjectSearchResultToProjectDetails(projectDetail);
            }
            return this.dmAuthorizationService.hasPermission(ResourceName.SAP_Project, OperationName.view, null, currProject);
        }
    }

    /**
     * Get engagement details from engagement search result.
     */
    private convertEngagementSearchResultToEngagementDetails(engagementSearchResult: IEngagementSearchResult): IEngagementDetails {
        const projectDetails: IProjectDetails[] = [];
        for (const p of engagementSearchResult.projectDetails) {
            let project: IProjectDetails = this.cachedProject(p.projectId);
            if (project) {
                projectDetails.push(project);
            } else {
                project = this.convertProjectSearchResultToProjectDetails(p);
                this.projectDetailCache.push({ id: project.projectId, project });
                projectDetails.push(project);
            }
        }
        return {
            name: engagementSearchResult.name,
            currency: engagementSearchResult.currency,
            projects: projectDetails,
            description: engagementSearchResult.description,
            engagementId: engagementSearchResult.engagementId,
            primaryDomain: engagementSearchResult.primaryDomain,
            startDate: undefined,
            endDate: undefined,
            pPjMAlias: engagementSearchResult.pPjMAlias,
            pPjMbpId: engagementSearchResult.pPjMbpId,
            pPjMName: engagementSearchResult.pPjMName,
            pubSecCode: engagementSearchResult.pubSecCode,
            delegatedPPjMAlias: engagementSearchResult.delegatedPPjMAlias,
            delegatedPPjMbpId: engagementSearchResult.delegatedPPjMbpId,
            additionalPPJMs: engagementSearchResult.additionalPpjMs,
            isPublicSector: engagementSearchResult.isPublicSector,
            isUsPubSec: engagementSearchResult.isUsPubSec,
            companyCode: undefined, /* These engagement items come off the API but are not used here, so they are defaulted to undefined */
            costCenter: undefined,
            dealId: undefined,
            lastUpdatedDate: undefined,
            projectTypeCode: undefined,
            projectTypeDescription: undefined,
            opportunityId: undefined,
            stateCode: undefined,
            stateDescription: undefined,
            userStatusCode: undefined,
            userStatusDescription: undefined,
            companyName: undefined,
            contractDetail: undefined,
            delegatedPPjMName: undefined,
            customerDetail: undefined,
            isConfidentialDeal: undefined
        };
    }

    /**
     * Return project details of bases of search criteria
     */
    private convertProjectSearchResultToProjectDetails(projectSearchResult: IProjectSearchResult): IProjectDetails {
        return {
            projectName: projectSearchResult.name,
            projectId: projectSearchResult.projectId,
            engagementId: projectSearchResult.engagementId,
            engagementName: projectSearchResult.engagementName,
            projectDescription: projectSearchResult.description,
            domain: projectSearchResult.domain,
            startDate: undefined,
            endDate: undefined,
            pjMbpId: projectSearchResult.pjMbpId,
            pjMAlias: projectSearchResult.pjMAlias,
            pjMName: projectSearchResult.pjMName,
            delegatedPjMAlias: projectSearchResult.delegatedPjMAlias,
            delegatedPjMbpId: projectSearchResult.delegatedPjMbpId,
            delegatedPjMName: projectSearchResult.delegatedPjMName,
            additionalPJMs: projectSearchResult.additionalPjMs,
            isPublicSector: projectSearchResult.isPublicSector,
            isConfidentialDeal: projectSearchResult.isConfidentialDeal,
            isUsPubSec: projectSearchResult.isUsPubSec,
            isMarkedForDeletion: projectSearchResult.isMarkedForDeletion
        };
    }

    /**
     * return if project is cached.
     */
    private cachedProject(id: string): IProjectDetails {
        const projectCached = this.projectDetailCache.filter((x) => x.id === id).length;
        if (projectCached) {
            return projectCached[0];
        }
        return undefined;
    }
}
