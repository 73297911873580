import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { PlanForecastParamsActions, PlanForecastParamsActionTypes, LoadPlanForecastParamsSuccess, LoadPlanForecastParamsFail } from "./plan-forecast-params.action";
import { ProjectService } from "../../common/services/project.service";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class PlanForecastParamsEffect {

    @Effect()
    public loadPlanForecastParams$ = this.actions$
        .ofType(PlanForecastParamsActionTypes.LOAD_PLAN_FORECAST_PARAMS)
        .pipe(
            switchMap((action: PlanForecastParamsActions) =>
                fromPromise(this.projectService.getPlanForecastParamsForEngagementOrProject(action.wbsId))
                    .pipe(
                        map((planForecastParams) => new LoadPlanForecastParamsSuccess(action.wbsId, planForecastParams)),
                        catchError((error) => of(new LoadPlanForecastParamsFail(action.wbsId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                    )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService,
    ) { }
}
