import { Component, Input, Inject, forwardRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { v4 as uuid } from "uuid";

import { Components, AccessibilityConstants, LogEventConstants, SourceConstants } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IApiResponseMessage, IAssignment, IReasonCodeProblemType, IActionResponse } from "../../staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { IEngineRuleVerificationResponse, IRule } from "../../staffing-command-bar-common/services/contracts/rule-engine.service.contract";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { StaffingActionConstants } from "../../staffing-command-bar-common/services/staffing-action.constants";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";

import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { DmError } from "../../../../common/error.constants";

declare let require: any;

@Component({
    selector: "scb-suspend-modal",
    templateUrl: "./suspend-modal.component.html",
    styleUrls: ["./suspend-modal.component.scss"],
})

export class SuspendModalComponent extends DmModalAbstract {
    @Input() public assignmentList: IAssignment[];
    @Input() public wbsId: string;
    @Input() public resourceId: string;

    public suspendReason: IReasonCodeProblemType[] = [];
    public suspendComment = "";
    public lblMessage = "";
    public selectedReason = "select";
    public isLoading: boolean = false;
    public showLoading: boolean = true;
    public loadingText: string = "Loading";
    public errorSummaryText: string;
    public isSubmitBtnDisabled: boolean = true;
    public accessibilityConstants = AccessibilityConstants;
    public suspendErrorMessages = DmError.Staffing;
    private validationResults: IEngineRuleVerificationResponse;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(StaffingActionService) private staffingActionService: StaffingActionService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(RuleEngineService) private ruleEngineService: RuleEngineService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService
    ) {
        super(activeModal, dmLogger, Components.StaffingCommandBarSuspendModal);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.configManagerService.initialize();
        this.isLoading = true;
        this.validationResults = {
            assignmentIds: [],
            validationMessage: "",
            isActionable: false
        };
        // Check for Input data
        if (!this.assignmentList || this.assignmentList.length <= 0) {
            this.fxpMessageService.addMessage(DmError.Staffing.AssignmentDataMissing, "error", false, uuid());
            this.closeModal();
        } else {
            const rulePath: IRule[] = require("../../staffing-command-bar-common/Rules/suspendRules.json");
            this.ruleEngineService.addRules(rulePath);
            this.ruleEngineService.runRules(this.assignmentList)
                .then((result: IEngineRuleVerificationResponse) => {
                    this.validationResults = result;
                    if (!this.validationResults.isActionable) {
                        return Promise.reject(result);
                    }
                    // Load the reason code
                    return this.loadSuspendReasonCode();
                }).then((suspendReasonCodeResponse: IReasonCodeProblemType[]) => {
                    this.suspendReason = suspendReasonCodeResponse;
                    this.isLoading = false;
                }).catch((result: IEngineRuleVerificationResponse) => {
                    this.isLoading = false;
                    this.fxpMessageService.addMessage(result.validationMessage, "error", true);
                    this.closeModal();
                });
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CancelRequest);
        }
    }

    /**
     * Close the Modal Dialog and nullfy the assingment List
     *
     * @private
     * @memberof SuspendModalComponent
     */
    public closeModal(): void {
        this.assignmentList = [];
        super.closeModal();
        const dropDownId = "Role Actions Dropdown " + this.resourceId;
        this.sharedFunctionsService.focus(dropDownId, true);
    }

    /**
     * Submit request to suspend request for assignment
     */
    public submit(): void {
        this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.SubmitSuspendRequest);
        let responseMessage: IApiResponseMessage;
        if (!this.selectedReason || this.selectedReason.toLowerCase() === "select") {
            this.lblMessage = DmError.Staffing.ReasonRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-suspend-reason-ddl", true);
            });
            return;
        }
        if (this.selectedReason.toLowerCase() === "other" && !this.suspendComment) {
            this.lblMessage = DmError.Staffing.CommentsAreRequired;
            this.sharedFunctionsService.delayExecution(2000).then(() => {
                this.sharedFunctionsService.focus("dm-suspend-reason", true);
            });
            return;
        }
        this.isLoading = true;
        this.showLoading = true;
        this.loadingText = "Suspending";
        this.lblMessage = "";

        this.staffingActionService.actionOnAssignments("SuspendRequest", this.validationResults.assignmentIds, this.selectedReason, this.suspendComment)
            .then((r: IActionResponse) => {
                
                this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.Submit, LogEventConstants.SuspendRequestSuccess);
                responseMessage = this.staffingActionService.onSuccessAPICall(StaffingActionConstants.ActionSuspend, r);
                this.isLoading = false;
                this.showLoading = true;
                const projectIds = this.assignmentList.map((assignment) => assignment.projectIdSelected);
                this.staffingService.invalidateProjectStaffingData(projectIds);
                this.staffingActionService.displayMessages(responseMessage, this.validationResults.validationMessage);
                this.closeModal();
            })
            .catch((error) => {
                this.staffingActionService.onErrorAPICall(StaffingActionConstants.ActionSuspend, error);
                this.errorSummaryText = DmError.Staffing.ErrorWhileSuspending;
                this.logError(SourceConstants.Method.Submit, error, this.errorSummaryText, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.showLoading = false;
                this.closeModal();
            });
    }

    /**
     * submit button is disabled until reason code is selected
     */
    public onReasonCodeChange(): void {
        if (!this.selectedReason || (this.selectedReason && this.selectedReason.toLowerCase() === "select")) {
            this.isSubmitBtnDisabled = true;
        } else {
            this.dmLogger.logEvent(SourceConstants.Component.StaffingPage, SourceConstants.Method.OnReasonCodeChange, LogEventConstants.SuspendReasonSelected, {reasonCode: this.selectedReason});
            this.isSubmitBtnDisabled = false;
        }
    }

    /**
     * load reason code list for suspend action
     */
    private loadSuspendReasonCode(): Promise<IReasonCodeProblemType[]> {
        return this.staffingActionService.getSuspendReasons();
    }
}
