import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService } from "../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { WBSService } from "../../../../common/services/wbs.service";
import { MyPortfolioService } from "../../../../common/services/my-portfolio.service";
import { EntityTeamStructureComponent } from "./entity-team-structure.component";
import { CreateTeamsGroupModalModule } from "../../modals/create-teams-group/create-teams-group.module";
import { AADGraphService } from "../../../../common/services/aad-graphapi.service";
import { ManageEbsService } from "../../../../common/services/manage-ebs.service";
import { EditTeamStructureModalModule } from "../../modals/edit-team-structure/edit-team-structure.module";
import { TeamStructureModalModule } from "../../../../components/shared/team-structure/team-structure.module";
import { EditProjectTeamStructureModalModule } from "../../modals/edit-project-team-structure/edit-project-team-structure.module";
import { PersonCardModule } from "../../../../components/shared/person-card/person-card.module";
import { FeedbackModalService } from "../../../../components/tiles/feedback-modal/feedback-modal.service";

@NgModule({
    declarations: [
        EntityTeamStructureComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        CreateTeamsGroupModalModule,
        EditTeamStructureModalModule,
        TeamStructureModalModule,
        EditProjectTeamStructureModalModule,
        PersonCardModule
    ],
    providers: [
        ConfigManagerService,
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        DMNotificationService,
        EngagementDetailService,
        MyPortfolioService,
        SharedFunctionsService,
        WBSService,
        AADGraphService,
        ManageEbsService,
        FeedbackModalService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
        EntityTeamStructureComponent,
    ],
    exports: [
        EntityTeamStructureComponent,
    ]
})
export class EntityTeamStructureModule { }