import { forwardRef, Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AdalLoginHelperService, FxpConstants, FxpHttpService, FxpLoggerService, FxpMessageService, ErrorSeverityLevel, UserInfoService } from "@fxp/fxpservices";
import { ConfigManagerService } from "./configmanager.service";
import { appSettings } from "../../../environments/common/appsettingsGenerator";
import { IPartnerEndpoint } from "../../../environments/interface/IPjMAppSettings";
import { APIConstants, APIPrefix, LogEventConstants, SourceConstants } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";

declare const $: any;
@Injectable()
export class DataService {

    private lastDisplayedErrorMessage: string;
    private lastCalledFailedAPI: string;
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(forwardRef(() => FxpHttpService)) private fxpHttpService: FxpHttpService,
        @Inject(forwardRef(() => AdalLoginHelperService)) private fxpAdalHelper: AdalLoginHelperService,
        @Inject(forwardRef(() => FxpLoggerService)) private fxpLoggerService: FxpLoggerService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(DMLoggerService) private dmLogger: DMLoggerService,
        @Inject(FxpMessageService) private fxpMessageService: FxpMessageService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(HttpClient) private http: HttpClient
    ) { }

    /**
     * retrieves the correlation id contained in error message returned by
     * postData<T>, getData<T>, patchData<T>, putData<T>, deleteData<T>.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static getCorrelationIdFromError(serviceError: any): string {
        if (serviceError && serviceError.config && serviceError.config.headers && serviceError.config.headers["X-CorrelationId"]) {
            return serviceError.config.headers["X-CorrelationId"] as string;
        } else {
            return "none";
        }
    }

    /**
     * Makes a Post REST call to the given API with the given data; returns the response data from the API or an error.
     * @param url
     * @param subscriptionKey
     * @param data
     * @param apiName
     */
    public async postData<T>(url: string, subscriptionKey: string, apiName: string, data: object, isFileUpload: boolean = false, additionalOptions?: { [key: string]: string }, suppressErrorCode?: number[]): Promise<T> {
        const correlationId = $.correlator.renewCorrelationId();
        this.logAPIEvent(apiName, LogEventConstants.APICalled);
        this.fxpLoggerService.startTrackPerformance(apiName);
        let headers;
        if (additionalOptions) {
            headers = {
                "Ocp-Apim-Subscription-Key": subscriptionKey,
                "Content-Type": undefined,
                ...additionalOptions
            };
        } else {
            headers = {
                "Ocp-Apim-Subscription-Key": subscriptionKey,
                "Content-Type": undefined,
            };
        }
        if (!isFileUpload) {
            headers["Content-Type"] = "application/json";
        }
        try {
            const angularPromise = await this.fxpHttpService.post(url, data, headers);
            this.logAPIEvent(apiName, LogEventConstants.APISuccess);
            return angularPromise.data;
        } catch (serviceError) {
            this.logAPIError(apiName, SourceConstants.Method.PostData, serviceError, (suppressErrorCode || "").toString(), correlationId, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.showServiceError(apiName, serviceError, correlationId, suppressErrorCode);
            return Promise.reject(serviceError);
        } finally {
            this.fxpLoggerService.stopTrackPerformance(apiName, "DM.DMUI", null, null, correlationId);
        }
    }

    /**
     * Makes a Get REST call to the given API and returns the data it receives or an error.
     * @param url
     * @param subscriptionKey
     * @param apiName
     * @param suppressErrorCode
     * @param retryEnabled
     * @param isMessgeIdRequired
     * @param isFinancialRolesApi
     */
    public getData<T>(
        url: string,
        subscriptionKey: string,
        apiName: string,
        suppressErrorCode?: number[],
        retryEnabled: boolean = true,
        isMessageIdRequired: boolean = false,
        isFinancialRolesApi: boolean = false,
        responseType: string = "json",
        isEntireResponseObjectRequired: boolean = false,
        additionalOptions?: { [key: string]: string }
    ): Promise<T> {
        const correlationId: string = $.correlator.renewCorrelationId();
        this.logAPIEvent(apiName, LogEventConstants.APICalled);
        this.fxpLoggerService.startTrackPerformance(apiName);
        let options;
        if (additionalOptions) {
            options = {
                "Ocp-Apim-Subscription-Key": subscriptionKey,
                retryEnabled,
                ...additionalOptions
            };
        } else {
            options = {
                "Ocp-Apim-Subscription-Key": subscriptionKey,
                retryEnabled,
            };
        }

        if (apiName === APIConstants.DelegatedByDetailsAPI || apiName === APIConstants.DelegatedToDetailsAPI) {
            options["x-ms-tenant"] = appSettings().delegationTenant;
        }

        // Labor Management API requires x-core-correlation-request-id header which is the same as correlation ID
        if (apiName === APIConstants.LaborManagementApprovalsApi) {
            options["x-core-correlation-request-id"] = correlationId;
        }

        if (responseType === "json") {
            /* tslint:disable:no-string-literal */
            options["responseType"] = "json" as const;
        } else {
            options["responseType"] = "blob" as const;
        }
        return new Promise<T>((resolve, reject) => {
            if (isMessageIdRequired) {
                options["MessageId"] = correlationId;
            } else if (isFinancialRolesApi) {
                const apiVersion = this.configManagerService.getValue<string>("financialRoleApiVersion");
                options["api-version"] = apiVersion;
            }

            this.fxpHttpService.get(url, options)
                .then((data) => {
                    this.fxpLoggerService.stopTrackPerformance(apiName, "DM.DMUI", null, null, correlationId);
                    this.logAPIEvent(apiName, LogEventConstants.APISuccess);
                    if (isEntireResponseObjectRequired) {
                        return resolve(data);
                    } else {
                        return resolve(data.data);
                    }
                })
                .catch((error) => {
                    this.showServiceError(apiName, error, correlationId, suppressErrorCode);
                    this.logAPIError(apiName, SourceConstants.Method.GetData, error, (suppressErrorCode || "").toString(), correlationId, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.fxpLoggerService.stopTrackPerformance(apiName, "DM.DMUI", null, null, correlationId);
                    return reject(error);
                });
        });
    }

    /**
     * Makes a Get REST call to the given API and returns the data it receives or an error specifically for retrieving images /blob content
     * @param url
     * @param subscriptionKey
     * @param apiName
     */
    public  getBlobData(url: string, subscriptionKey: string, apiName: string): Promise<any> {
        const correlationId = $.correlator.renewCorrelationId();
        this.logAPIEvent(apiName, LogEventConstants.APICalled);

        return this.createRequestHeader(url).then((token: string) => {
            const accessToken = `Bearer ${token}`;
            return this.http.get(url, {
                headers: {
                    "Ocp-Apim-Subscription-Key": subscriptionKey,
                    "X-CorrelationId": correlationId,
                    "Authorization" : accessToken
                },
                responseType: "arraybuffer" as const
            }).toPromise().then((response) => {
                this.logAPIEvent(apiName, LogEventConstants.APISuccess);
                return Promise.resolve(response);
            }).catch((error) => {
                this.logAPIError(apiName, SourceConstants.Method.GetBlobData, error, undefined, correlationId, ErrorSeverityLevel && ErrorSeverityLevel.High);
                return Promise.reject(error);
            });
        });
    }

    /**
     * Makes a Patch rest call to the given API with the given data. Returns the result or an error.
     * @param url
     * @param subscriptionKey
     * @param apiName
     * @param data
     */
    public async patchData<T>(url: string, subscriptionKey: string, apiName: string, data: object, surpressErrorCode?: number[], suppressDuplicateErrorMesage?: boolean): Promise<T> {
        const correlationId: string = $.correlator.renewCorrelationId();
        this.fxpLoggerService.startTrackPerformance(apiName);
        this.logAPIEvent(apiName, LogEventConstants.APICalled);

        const headers = {
            "Ocp-Apim-Subscription-Key": subscriptionKey
        };
        try {
            const angularPromise = await this.fxpHttpService.patch(url, data, headers);
            this.logAPIEvent(apiName, LogEventConstants.APISuccess);
            return angularPromise;
        } catch (serviceError) {
            this.logAPIError(apiName, SourceConstants.Method.PatchData, serviceError, (surpressErrorCode || "").toString(), correlationId, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.showServiceError(apiName, serviceError, correlationId, surpressErrorCode, suppressDuplicateErrorMesage);
            return Promise.reject(serviceError);
        } finally {
            this.fxpLoggerService.stopTrackPerformance(apiName, "DM.DMUI", null, null, correlationId);
        }
    }

    /**
     * Makes a Put rest call to the given API with the given data.
     * @param url
     * @param subscriptionKey
     * @param apiName
     * @param data
     */
    public async putData<T>(url: string, subscriptionKey: string, apiName: string, data: object): Promise<T> {
        this.fxpLoggerService.startTrackPerformance(apiName);
        const correlationId: string = $.correlator.renewCorrelationId();
        this.logAPIEvent(apiName, LogEventConstants.APICalled);
        const headers = { "Ocp-Apim-Subscription-Key": subscriptionKey };
        try {
            const angularPromise = await this.fxpHttpService.put(url, data, headers);
            this.logAPIEvent(apiName, LogEventConstants.APISuccess);
            return angularPromise.data;
        } catch (serviceError) {
            this.logAPIError(apiName, SourceConstants.Method.PutData, serviceError, undefined, correlationId, ErrorSeverityLevel && ErrorSeverityLevel.High);
            this.showServiceError(apiName, serviceError, correlationId);
            return Promise.reject(serviceError.data);
        } finally {
            this.fxpLoggerService.stopTrackPerformance(apiName, "DM.DMUI", null, null, correlationId);
        }
    }

    /**
     * Makes a delete rest call to the given API with the given data.
     * We use the default HTTP delete service instead of FXP's http service since FXP's delete does not allow us to pass a body.
     * Todo: passing a body in the delete function is typically not recommended, since all params should be passable through the query params.
     * Come back and change this API so that we can pass everything in the query params.
     * @param url
     * @param subscriptionKey
     * @param apiName
     * @param data
     */
    public deleteData(url: string, subscriptionKey: string, data?: object): Promise<any> {
        const correlationId = $.correlator.renewCorrelationId();
        const apiName = "Delete"; // todo update this per API call
        this.logAPIEvent(apiName, LogEventConstants.APICalled);
        const headers = {
            "Ocp-Apim-Subscription-Key": subscriptionKey,
            "Content-Type": "application/json",
            "X-CorrelationId": correlationId
        };
        if (this.fxpUserInfoService.isActingOnBehalfOf()) {
            headers["X-ActonBehalfMode"] = "true";
            headers["X-OnBehalfOfUser"] = this.fxpUserInfoService.getCurrentUser() + "@microsoft.com";
        }

        return this.retrieveToken(url)
            .then((token: string) => {
                headers["Authorization"] = `Bearer ${token}`;
                return this.http.request("DELETE", url,
                    {
                        headers,
                        body: data
                    }
                ).toPromise().then((response) => {
                    this.logAPIEvent(apiName, LogEventConstants.APISuccess);
                    return Promise.resolve(response);
                }).catch((error) => {
                    this.logAPIError(apiName, SourceConstants.Method.DeleteData, error, undefined, correlationId, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return Promise.reject(error);
                }); /* Cast the response to a promise since http defaults to an observable. */
            });

    }

    /**
     * Displays a service error to the user using the Fxp Logger and Fxp Message service. (Red bar across top of screen.)
     * @param apiName
     * @param serviceError
     */
    private showServiceError(apiName: string, serviceError, correlationId: string, suppressErrorCode?: number[], suppressDuplicateErrorMesage?: boolean): void {
        let serviceErrorMessage = "Unknown error";
        if (serviceError.data) {
            serviceErrorMessage = serviceError.statusText;
            if (serviceError.data.Message || serviceError.data.message) {
                serviceErrorMessage = serviceError.data.Message || serviceError.data.message;
            }
            if (serviceError.data.ErrorMessage) {
                serviceErrorMessage = serviceError.data.ErrorMessage;
            }
            if (Array.isArray(serviceError.data) && serviceError.data.length) {
                serviceErrorMessage = "";
                for (const errorMessage of serviceError.data) {
                    serviceErrorMessage = errorMessage.error + serviceErrorMessage;
                }
            }
            if (serviceError.data.InnerErrors || serviceError.data.innerErrors) {
                const innerErrors = serviceError.data.InnerErrors || serviceError.data.innerErrors;
                for (const innerError of innerErrors) {
                    if (innerError.Messages || innerError.messages) {
                        const innerErrorMessage = innerError.Messages || innerError.messages;
                        serviceErrorMessage = "";
                        for (const message of innerErrorMessage) {
                            serviceErrorMessage = message + ". " + serviceErrorMessage;
                        }
                    }
                }
            }
            if ((serviceError.data.length && serviceError.data.length > 200) || (serviceErrorMessage && serviceErrorMessage.length && serviceErrorMessage.length > 200)) {
                serviceErrorMessage = " Service Failed" + " (" + serviceError.status + " " + serviceError.statusText + ") ";
            }
        }
        if (serviceError.status && !(serviceError.status === 404 || serviceError.status === -1 || (suppressErrorCode && suppressErrorCode.indexOf(Number(serviceError.status)) > -1))) {

            // try to get the correlation id from XHR Config Header. The variable(correlationId) that's passed-in almost always has the wrong value
            if (serviceError.config && serviceError.config.headers && serviceError.config.headers["X-CorrelationId"]) {
                correlationId = serviceError.config.headers["X-CorrelationId"] as string;
            }


            /* Todo this is a workaround for needing to silence multiple duplicate error messages we show on the DOM. Some APIs are called in bulk
            and if one fails, they all fail the same. This is to limit the display to show only one error. Ideally, FXP would allow us to view an 'active alert' list and
            we could toggle our displayed messages based on the existing active message. */
            if (!suppressDuplicateErrorMesage || (this.lastDisplayedErrorMessage !== serviceErrorMessage && this.lastCalledFailedAPI !== apiName)) {
                this.fxpMessageService.addMessage("API: " + apiName + " " + serviceErrorMessage, this.FXP_CONSTANTS.messageType.error, true, correlationId);
                this.lastDisplayedErrorMessage = serviceErrorMessage;
                this.lastCalledFailedAPI = apiName;
            }
        }
    }

    /**
     * Converts a service error into an error stack string
     * @param serviceError
     */
    private getErrorStack(serviceError: any): string {
        const errorStack = {
            error: null,
            api: ""
        };

        if (serviceError && serviceError.data) {
            /* Ignore "ArrayBuffer" response from Graph Api */
            if (typeof (serviceError.data) === "string" || (serviceError.data.toString() === "[object Object]")) {
                errorStack.error = serviceError.data;
            }
            if (serviceError.config) {
                errorStack.api = serviceError.config.method + " " + serviceError.config.url;
            }
        }
        return JSON.stringify(errorStack);
    }

    /**
     * Callback method for debugging; catches errors on the token callback
     * todo consider deleting this
     * @param error
     * @param token
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private tokenCallback(error: any, token: string): void {
        /* only for debugging */
    }

    /**
     * Retrieves the token for the given URL as an FXP partner endpoint.
     * Returned as a promise so we can better handle catching errors.
     * @param url
     */
    private async  retrieveToken(url: string): Promise<string> {
        const partnerEndPointArray: IPartnerEndpoint[] = appSettings().partnerEndpoints; // todo this needs to be moved into config manager
        if (!partnerEndPointArray) {
            return Promise.reject("Unable to get any partner endpoints from the configuration.");
        }
        const filteredPartnerEndPoints = partnerEndPointArray.filter((obj: IPartnerEndpoint) => url.indexOf(obj.Endpoint) > -1);

        if (filteredPartnerEndPoints.length > 1) {
            return Promise.reject("There are multiple partner endpoints for the given URL. Please check the configuration for this environment.");
        }
        if (!filteredPartnerEndPoints.length) {
            return Promise.reject("The partner endpoint does not exist in the configuration.");
        }

        const partnerEndPoint: IPartnerEndpoint = filteredPartnerEndPoints[0];
        let returnToken: string;
        let returnError;
        /* This function is typed as a void even though it should be a promise/observable, so we need to
        set variables rather than return the function itself. */
        await this.fxpAdalHelper.acquireTokenAsPromise(partnerEndPoint.Endpoint).then((token: string) => {
            if (token) {
                returnToken = token;
            }
        });
        if (returnToken) {
            return Promise.resolve(returnToken);
        }
        return Promise.reject(returnError);
    }

    /**
     * Logs errors that happened with an API. Sends error's to FXP's Application Insights in the exceptions table.
     * Prefixes all names with "API".
     *
     * @private
     * @param {string} apiName
     * @param {*} error
     * @param {string} errorCode
     * @param {string} correlationId
     * @memberof DataService
     */
    private logAPIError(apiName: string, methodName: string, error: any, errorCode: string, correlationId: string, errorSeverity?: any): void {
        this.logAPIEvent(apiName, LogEventConstants.APIFailure);
        this.dmLogger.logError(APIPrefix + apiName, methodName, error, errorCode, this.getErrorStack(error), undefined, correlationId, errorSeverity);
        // DataService.getCorrelationIdFromError(error)
    }

    /**
     * Logs events for an API. Sends the event to FXP's Application Insights in the custom events table.
     * Prefixes the source with "API";
     *
     * @private
     * @param {string} apiName
     * @param {string} event
     * @param {{}} [properties]
     * @memberof DataService
     */
    private logAPIEvent(apiName: string, event: string, properties?: {}): void {
        this.dmLogger.logEvent(APIPrefix + apiName, SourceConstants.Method.LogAPIEvent, event, properties);
    }

    private createRequestHeader(endPoint: string): Promise<string> {
        let accessToken =  this.fxpAdalHelper.getCachedToken(endPoint);
        if (!accessToken) {
            accessToken =  new Promise((resolve, reject) => {
                this.fxpAdalHelper.acquireToken(endPoint, (error, token) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(token);
                    }
                });
            });
        }
        return  accessToken;
    }
}