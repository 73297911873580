<section class="dm-delivery-manager">
    <section class="dm-tab-navigation entity-info" id="portfolio-tab-navigation"
        [ngStyle]="{'height': setHeightForPortfolioTabNavigation()}">
        <section class="dm-secondory-nav" id="secondary-naviagtion">
            <section class="display-flex-imp" *ngIf="showMyPortfolioTabs">
                <span class="icon icon-home dm-p-8 set-focus" title="My Portfolio Home Page" tabindex="0" role="link"
                    (keyup.enter)="navigateToHomePage()" (keyup.Space)="navigateToHomePage()"
                    (click)="navigateToHomePage()"></span>
                <section class="dm-dropdown-wrapper dm-portfolio-nav-dropdown">
                    <dm-drop-down [hideDropDown]="hideDrop" [isDropdownLoading]="isDropDownLoading"
                        [showMyPortfolioTabs]="showMyPortfolioTabs"></dm-drop-down>
                </section>
            </section>
            <section *ngIf="!showMyPortfolioTabs" class="dm-navigation-header-section w-100">
                <dm-tab-navigation *ngIf="showTabNavigationHeader">
                </dm-tab-navigation>
                <section class="dm-header-bar-controls-section">
                    <dm-nav-bar-notification *ngIf="shouldShowNavBarNotifications()"
                        class="dm-notification-btn" (keyup.enter)="toggleNotifications($event)"
                        (keyup.Space)="toggleNotifications($event)"
                        (keyup.arrowright)="focus('accessibilityConstants.WhatsNew')">
                    </dm-nav-bar-notification>
                    <button tabindex="0" title="What's New" class="dm-transparent-btn" (click)="openWhatsNewModal()"
                        (keyup.arrowright)="focus('accessibilityConstants.Refresh')"
                        (keyup.arrowleft)="shouldShowNavBarNotifications() ? focus('accessibilityConstants.NavBarNotification') : focus('accessibilityConstants.PortfolioFeedback')">
                        <span class="icon-full icon-whats-new"></span>
                    </button>
                    <button tabindex="0" class="refresh-btn dm-transparent-btn"
                        (keyup.arrowleft)="focus('accessibilityConstants.WhatsNew')" (click)="refreshEntity()"
                        title="Refresh">
                        <span class="icon icon-refresh"></span>
                        <span role="status" class="sr-only" aria-atomic="true">
                            <span id="loadingComplete"></span>
                        </span>
                    </button>
                </section>
            </section>
            <section *ngIf="showMyPortfolioTabs" class="dm-secondory-nav__right">
                <section>
                    <ng-template [ngTemplateOutlet]="globalSearchTemplate"></ng-template>
                </section>
                <button tabindex="0" title="Portfolio Feedback Survey" id="accessibilityConstants.PortfolioFeedback"
                    class="dm-transparent-btn" (click)="openPortfolioFeedbackModal()"
                    (keyup.arrowright)="focus('accessibilityConstants.WhatsNew')">
                    <span class="icon-full icon-feedback"></span>
                </button>
            </section>
            <section *ngIf="showMyPortfolioTabs" class="display-flex-imp dm-m-r-8"
                [ngClass]="{'action-icons': !showMyPortfolioTabs && toggleNavTabs && deviceFactory.isSmallScreen()}">
                <dm-nav-bar-notification id="accessibilityConstants.NavBarNotification"
                    *ngIf="!showMyPortfolioTabs && shouldShowNavBarNotifications()" class="dm-m-r-imp-12"
                    (keyup.enter)="toggleNotifications($event)" (keyup.Space)="toggleNotifications($event)"
                    (keyup.arrowright)="focus('accessibilityConstants.WhatsNew')"></dm-nav-bar-notification>
                <button tabindex="0" title="What's New" id="accessibilityConstants.WhatsNew" class="dm-transparent-btn"
                    (click)="openWhatsNewModal()" (keyup.arrowright)="focus('accessibilityConstants.Refresh')"
                    (keyup.arrowleft)="!showMyPortfolioTabs && shouldShowNavBarNotifications() ? focus('accessibilityConstants.NavBarNotification') : focus('accessibilityConstants.PortfolioFeedback')">
                    <span class="icon-full icon-whats-new"></span>
                </button>
                <button tabindex="0" id="accessibilityConstants.Refresh" class="refresh-btn dm-transparent-btn"
                    (keyup.arrowleft)="focus('accessibilityConstants.WhatsNew')"
                    (click)="showMyPortfolioTabs ? refreshMyPortfolio() : refreshEntity()" title="Refresh">
                    <span class="icon icon-refresh"></span>
                    <span role="status" class="sr-only" aria-atomic="true">
                        <span id="loadingCompletes"></span>
                    </span>
                </button>
            </section>
        </section>
    </section>
    <!-- <dm-tab-navigation *ngIf="!showMyPortfolioTabs && toggleNavTabs && deviceFactory.isSmallScreen()"></dm-tab-navigation> -->
    <ng-template #globalSearchTemplate>
        <div role="search" class="dropdown dm-global-search margin-left-auto" id="globalSearchTab"
            [ngClass]="{'dm-global-search--expanded': !toggleNavTabs, 'dm-global-search-position': !showMyPortfolioTabs && deviceFactory.isSmallScreen()}">
            <input [ngClass]="{'input__text': showMyPortfolioTabs}" autofocus type="text"
                id="globalSearch" name="globalSearch" [(ngModel)]="searchText" data-toggle="dropdown"
                (focus)="togglingNavTabs(false)" (clickOutside)="togglingNavTabs(true)" placeholder="Search"
                (keydown.shift.tab)="togglingNavTabs(true)" minlength="3" aria-label="Search in Domain"
                (keyup.enter)="globalSearch()" />
            <span *ngIf="!toggleNavTabs" class="dm-global-search__label">Search in Domain</span>
            <span class="icon icon__ele icon-search dm-global-search__search-icon set-focus" title="Search"
                (click)="globalSearch()" (keyup.enter)="globalSearch()" (keyup.Space)="globalSearch()"></span>
            <span role="button" tabindex="0" class="icon icon__ele icon-cancel dm-global-search__cancel-icon set-focus"
                aria-label="Clear" (click)="searchText = ''" (keyup.enter)="searchText = ''"
                (keyup.Space)="searchText = ''"></span>
            <div class="search-history-dropdown dropdown-menu"
                [ngClass]="!toggleNavTabs ? 'display-inline-block-imp' : 'display-none-imp'"
                *ngIf="selectedGlobalSearchItems.length">
                <div class="display-flex dm-p-10">
                    <span class="search-history-dropdown__search-history-text">Search History</span>
                    <span class="search-history-dropdown__spacer"></span>
                    <span tabindex="0" class="search-history-dropdown__link" role="link"
                        (click)="navigateToAdvancedSearch()" (keyup.enter)="navigateToAdvancedSearch()">Advanced
                        Search</span>
                </div>
                <div class="search-history-dropdown__item" tabindex="0"
                    *ngFor="let selectedGlobalSearchItem of selectedGlobalSearchItems"
                    (click)="searchText = selectedGlobalSearchItem; globalSearch(); searchHistoryUsageLogger(selectedGlobalSearchItem)"
                    (keyup.enter)="searchText = selectedGlobalSearchItem; globalSearch()"
                    (keydown.tab)="onTabbingItemInGlobalSearchDropDown(selectedGlobalSearchItem)">
                    <span class="icon-full icon-history dm-p-x-10"></span>
                    <span class="text-bottom">{{selectedGlobalSearchItem}}</span>
                </div>
            </div>
        </div>
    </ng-template>
</section>