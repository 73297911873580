import { Injectable, Inject } from "@angular/core";
import moment from "moment";
import { ConfigManagerService } from "./configmanager.service";
import { IKeyActionsModel } from "./contracts/wwtk.service.contracts";
import { IGoodsReceiptsKeyActionConfigValues } from "./contracts/manage-suppliers.service.contracts";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { Services } from "../application.constants";
import { DMLoggerService } from "./dmlogger.service";


@Injectable()
export class ManageSuppliersService extends DmServiceAbstract {
    private goodsReceiptConfigValues: IGoodsReceiptsKeyActionConfigValues;
    public constructor(
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) { 
        super(dmLogger, Services.ManageSupplierService );
    }

    /**
     * Gets the due days for the goods receipt and formats the object with the given invoices link to pass back to the view.
     * @param invoicesLink 
     */
    public getGoodsReceiptDueDays(invoicesLink: string = "#"): IKeyActionsModel {
        this.goodsReceiptConfigValues = this.configManagerService.getValue<IGoodsReceiptsKeyActionConfigValues>("goodsReceiptKeyActions");
        const result: IKeyActionsModel = { description: "Receipts Due", due: "0", link: invoicesLink };

        switch (this.goodsReceiptConfigValues.goodsReceiptDueDuration.toLowerCase()) {
            case "weekly":
                result.due = this.getDueDaysForWeekly(this.goodsReceiptConfigValues.goodsReceiptDueDay).toString();
                break;
            case "every other week":
                result.due = this.getDueDaysForByWeekly(this.goodsReceiptConfigValues.goodsReceiptDueDay, this.goodsReceiptConfigValues.goodsReceiptPattern).toString();
                break;
            case "monthly":
                result.due = this.getDueDaysForMonthly(this.goodsReceiptConfigValues.goodsReceiptDueDateOption1, this.goodsReceiptConfigValues.goodsReceiptDueDateOption2).toString();
                break;
            default:
        }
        if (result.due === "0") {
            result.due = this.goodsReceiptConfigValues.goodsReceiptDueTodayCaption;
        }
        return result;
    }

    /**
     * Get good receipts due days for weekly evaluation.
     */
    private getDueDaysForWeekly(goodsReceiptDueDay: string): number {
        let evaluationDay = moment().day(goodsReceiptDueDay).day();
        let currentDay = moment().utc().day();
        // Starting week from monday so assigning sunday as 7th day.
        if (currentDay === 0) {
            currentDay = 7;
        }
        if (evaluationDay === 0) {
            evaluationDay = 7;
        }
        if (evaluationDay >= currentDay) {
            return evaluationDay - currentDay;
        } else {
            const nextEvaluationDate = moment().utc().day(evaluationDay + 7);
            return nextEvaluationDate.diff(moment().utc(), "days");
        }
    }

    /**
     * Get good receipts due days for every alternate week evaluation.
     */
    private getDueDaysForByWeekly(goodsReceiptDueDay: string, goodsReceiptPattern: string, ): number {
        let evaluationDay = moment().day(goodsReceiptDueDay).day();
        let currentDay = moment().utc().day();
        const currentWeek = moment().utc().week();
        let totalDays = 0;
        // Starting week from monday so assigning sunday as 7th day.
        if (currentDay === 0) {
            currentDay = 7;
        }
        if (evaluationDay === 0) {
            evaluationDay = 7;
        }
        // odd represent odd weeks of year like first and third week and even represents second and fourth week.
        // Verifying current week is even or odd? applying logic respectively.
        // if current week is even week, remaining days in week adding to next evaluation day.
        if ((goodsReceiptPattern.toLowerCase() === "odd" && currentWeek % 2 === 0) ||
            (goodsReceiptPattern.toLowerCase() === "even" && currentWeek % 2 !== 0)) {
            totalDays = 7 - currentDay + evaluationDay;
        }

        if (totalDays === 0) {
            // (currentDay > evaluationDay) True:  if current week is odd week, evaluation day is passed then remaining days in week plus next whole week(even) plus adding next evaluation day
            // (currentDay > evaluationDay) False: if current week is odd week, evaluation day is not passed then getting difference between current day and evaluation day.
            totalDays = (currentDay > evaluationDay) ? ((7 - currentDay) + 7 + evaluationDay) : (evaluationDay - currentDay);
        }

        return totalDays;
    }

    /**
     * Get good receipts due days for monthly evaluation for two or one perticular date.
     */
    private getDueDaysForMonthly(goodsReceiptDueDateOption1: number, goodsReceiptDueDateOption2: number): number {
        let totalDays = 0;
        const todayUTCDate = moment().utc();
        const totalDaysOfMonths = todayUTCDate.daysInMonth();

        // Total month days are 28 then assigning last day as due date when second due date is not present.
        if (goodsReceiptDueDateOption1 > 0 && totalDaysOfMonths < goodsReceiptDueDateOption1 && goodsReceiptDueDateOption2 === 0) {
            goodsReceiptDueDateOption1 = totalDaysOfMonths;
        }

        // Total month days are 28 then assigning last day as second due date.
        if (goodsReceiptDueDateOption2 > 0 && totalDaysOfMonths < goodsReceiptDueDateOption2) {
            goodsReceiptDueDateOption2 = totalDaysOfMonths;
        }

        if (goodsReceiptDueDateOption1 === todayUTCDate.date() || goodsReceiptDueDateOption2 === todayUTCDate.date()) {
            return 0;
        }

        // Get the total days difference between first monthly date and actual UTC date.
        if (goodsReceiptDueDateOption1 > 0) {
            if (todayUTCDate.date() <= goodsReceiptDueDateOption1) {
                totalDays = goodsReceiptDueDateOption1 - todayUTCDate.date();
            } else if (goodsReceiptDueDateOption2 === 0) {
                // if current date is greater then due date then get the difference from current date
                // to till next month due date.
                const nextMonthTotalDays = moment().utc().add(1, "months").daysInMonth();
                if (goodsReceiptDueDateOption1 > nextMonthTotalDays) {
                    goodsReceiptDueDateOption1 = nextMonthTotalDays;
                }
                totalDays = (totalDaysOfMonths - todayUTCDate.date()) + goodsReceiptDueDateOption1;
            }
        }
        // Get the total days difference between second monthly date and actual UTC date.
        if (goodsReceiptDueDateOption2 > 0 && totalDays === 0) {

            if (todayUTCDate.date() <= goodsReceiptDueDateOption2) {
                totalDays = goodsReceiptDueDateOption2 - todayUTCDate.date();
            } else {
                totalDays = (totalDaysOfMonths - todayUTCDate.date()) + goodsReceiptDueDateOption1;
            }
        }
        return totalDays;
    }
}
