import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";

import { catchError, map, switchMap } from "rxjs/operators";
import { from as fromPromise, of } from "rxjs";
import { ProjectService } from "../../common/services/project.service";
import { IFinancialSummaryDetails } from "../../common/services/contracts/changerequest.contract";
import { CrScreenDetailsActions, LoadCrScreenDetailsFail, LoadCrScreenDetailsSuccess } from "./cr-screen-details.action";
import { CrScreenDetailsActionTypes } from "./cr-screen-details.action";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class CrScreenDetailsEffects {

    @Effect()
    public loadCrScreenDetails$ = this.actions$
        .ofType(CrScreenDetailsActionTypes.LOAD_CR_SCREEN_DETAILS)
        .pipe(
            switchMap((action: CrScreenDetailsActions) => fromPromise(
                this.projectService.getCRSummaryScreenPlanDetails(action.engagementId))
                .pipe(
                    map((financialSummaryDetails: IFinancialSummaryDetails[]) => new LoadCrScreenDetailsSuccess(action.engagementId, financialSummaryDetails)),
                    catchError((error) => of(new LoadCrScreenDetailsFail(action.engagementId, error.data.InnerErrors[0].Messages[0] + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService
    ) { }
}
