import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { FinancialSummaryComponent } from "./financial-summary.component";
import { FinancialSummaryDetailsModule } from "./financial-summary-details/financial-summary-details.module";
import { ReleaseAndActivateSummaryModule } from "./release-and-activate-summary/release-and-activate-summary.module";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        FinancialSummaryComponent,
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FinancialSummaryDetailsModule,
        ReleaseAndActivateSummaryModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
        FinancialService,
    ],
    entryComponents: [
        FinancialSummaryComponent,
    ],
    exports: [
        FinancialSummaryComponent,
    ]
})
export class FinancialSummaryModule { }
