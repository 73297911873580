import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ProjectFilterV2Component } from "./project-filter-v2.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { CommonComponentsModule } from "../../entry/common-components.module";

@NgModule({
    declarations: [
        ProjectFilterV2Component
    ],
    imports: [
        CommonModule,
        FormsModule,
        CommonComponentsModule
    ],
    providers: [
        SharedFunctionsService
    ],
    entryComponents: [
        ProjectFilterV2Component
    ],
    exports: [
        ProjectFilterV2Component
    ]
})
export class ProjectFilterV2Module {
}