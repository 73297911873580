import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";

import { catchError, map, switchMap } from "rxjs/operators";
import { EngagementDetailService } from "../../common/services/engagement-detail.service";
import { from as fromPromise, of } from "rxjs";
import { IWbsDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import * as engagementDetailsActions from "./engagement-details.action";

@Injectable()
export class EngagementDetailsEffects {

    @Effect()
    public loadEngagementDetails$ = this.actions$
        .ofType(engagementDetailsActions.EngagementDetailsActionTypes.LOAD_ENGAGEMENT_DETAILS)
        .pipe(
            switchMap((action: engagementDetailsActions.LoadEngagementDetails) => fromPromise(
                this.engagementDetailService.getWbsFullDetailsV2(action.engagementId, true, true, true, true, true)
            )
                .pipe(
                    map((wbsDetails: IWbsDetailsV2) => new engagementDetailsActions.LoadEngagementDetailsSuccess(action.engagementId, wbsDetails.engagementData)),
                    catchError((error) => of(new engagementDetailsActions.LoadEngagementDetailsFail(action.engagementId, error)))
                )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService
    ) { }
}
