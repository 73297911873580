<article class="dm-am-details-tile dm-p-x-imp-0">
    <section class="font-caption-alt p-b-12 pull-right" *ngIf="!deviceFactory.isMobile()">
        <a [uiSref]="backToFinancialsRouteName" [uiParams]="backToFinancialsRouteParams" class="financial-link dm-link">
            Back to Financials
        </a>
    </section>
    <!--Todo turn this back on for mobile view-->
    <!--section class="font-caption-alt p-b-12 dm-tile--p-t-6" *ngIf="deviceFactory.isMobile()">
            <button id="backtofinancial" class="financial-link dm-link"
                (click)="backToFinancials()" aria-label="financial details navigation item">
                // back to financials function has been removed since we can navigate via route directly now, see above
                <span class="icon icon-chevron-left"></span>
                <span>Amendments</span>
            </button>
        </section-->

    <!--========================================Non-Mobile Financial Comparison Data =====================================-->
    <section class="col-md-12 col-sm-12 dm-tile" *ngIf="!deviceFactory.isMobile()">
        <section class="dm-tile--no-pad text-left ">
            <h3 class="dm-tile__header col-md-12 col-sm-12 dm-tile--no-pad">
                FINANCIAL COMPARISON DATA
                <span class="dm-tile__content dm-tile__restrictline dm-tile--semi-bold text-capitilize">
                    <span class="amendments-last-updated">
                        (Last updated {{currentBaseLinePlanDetailsUpdatedDate | date: 'dd-MMM-yyyy hh:mm a'}} PT)
                    </span>
                </span>
            </h3>
        </section>
        <section class="dm-tile--no-pad text-left col-md-12 col-sm-12 dm-tile--p-t-12" *ngIf="currentBaseLineDetails">
            <section class="dm-a-w-28 amendments-title dm-tile--p-l-6 font-caption-alt">
                <p>Current Financial Plan
                    <span
                        *ngIf="currentBaseLinePlanDetailsStatusDescription">({{currentBaseLinePlanDetailsStatusDescription}})
                    </span>
                </p>
            </section>
            <section class="dm-a-w-4 amendments-title">
                <p class="dm-tile--opacity06">Margin</p>
                <p class="font-caption-alt">
                    {{currentFinancialDetails.margin ? (currentFinancialDetails.margin | number : '1.0-2') + '%':'-'}}
                </p>
            </section>
            <section class="dm-a-w-17 right-align amendments-title">
                <p class="dm-tile--opacity06">Cost
                    <span> ({{currency}})</span>
                </p>
                <p class="font-caption-alt">
                    {{currentFinancialDetails.cost  | dmDisplayCurrencyOrDash: currency}}
                </p>
            </section>
            <section class="dm-a-w-17 right-align amendments-title">
                <p class="dm-tile--opacity06">Revenue
                    <span> ({{currency}})</span>
                </p>
                <p class="font-caption-alt">
                    {{currentFinancialDetails.revenue  | dmDisplayCurrencyOrDash: currency}}
                </p>
            </section>
            <section class="dm-a-w-17 right-align dm-tile--p-r-16 amendments-title">
                <p class="dm-tile--opacity06"> Labor
                    <span>(hours)</span>
                </p>
                <p class="font-caption-alt">
                    {{currentFinancialDetails.labor ? (currentFinancialDetails.labor | number : '1.0-2'): '-'}}
                </p>
            </section>
            <section class="dm-a-w-17 right-align dm-tile--p-r-18 amendments-title">
                <p class="dm-tile--opacity06"> Risk Reserve
                    <span> ({{currency}}) </span>
                </p>
                <p class="font-caption-alt">
                    {{currentFinancialDetails.riskReserveAmount  | dmDisplayCurrencyOrDash: currency}}
                </p>
            </section>
        </section>
    </section>

    <!--==============================Mobile Financial Comparison Data =====================================-->
    <section class="dm-tile col-xs-12 dm-tile--p-l-12 dm-tile--p-r-12" *ngIf="deviceFactory.isMobile()">
        <section class="dm-tile--no-pad text-left ">
            <h3 class="dm-tile__header dm-tile--opacity06 dm-tile--p-b-12">
                FINANCIAL COMPARISON DATA
            </h3>
            <section class="amendments-title font-caption-alt dm-tile--p-b-12">
                <p>Current Financial Plan
                    <span *ngIf="currentBaseLinePlanDetailsStatusDescription">
                        ({{currentBaseLinePlanDetailsStatusDescription}})
                    </span>
                </p>
                <p>
                    <span
                        class="dm-tile__content dm-tile__restrictline dm-tile--semi-bold text-capitilize dm-tile--opacity06">
                        <span>
                            Last updated {{currentBaseLinePlanDetailsUpdatedDate | date: 'dd-MMM-yyyy hh:mm a'}}
                            PT
                        </span>
                    </span>
                </p>
            </section>
        </section>

        <section class="dm-tile__financial-data">
            <table class="dm-table__header dm-tile--plan-table font-caption-alt dm-tile--b-b-015"
                *ngIf="currentBaseLineDetails">
                <thead>
                </thead>
                <tbody>
                    <tr class="dm-tile--b-b-015 dm-tile--b-t-015">
                        <th scope="row">Margin</th>
                        <td class="text-right">
                            {{currentBaseLineDetails.marginInPercentage?(currentBaseLineDetails.marginInPercentage | number : '1.0-2') + '%':'-'}}
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row"> Cost
                            <span *ngIf="currentBaseLineDetails"> ({{currency}})</span>
                        </th>
                        <td class="text-right">
                            {{currentBaseLineDetails.cost  | dmDisplayCurrencyOrDash: currency}}
                            <span *ngIf="!currentBaseLineDetails.cost" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row"> Revenue
                            <span *ngIf="currentBaseLineDetails"> ({{currency}})</span>
                        </th>
                        <td class="text-right">
                            {{currentBaseLineDetails.revenue  | dmDisplayCurrencyOrDash: currency}}
                            <span *ngIf="!currentBaseLineDetails.revenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row">Labor
                            <span>(hours)</span>
                        </th>
                        <td class="text-right">
                            {{currentBaseLineDetails.hours ? (currentBaseLineDetails.hours | number : '1.0-2'): '-'}}
                        </td>
                    </tr>
                    <tr class="dm-tile--b-b-015">
                        <th scope="row">Risk Reserve
                            <span *ngIf="currentBaseLineDetails"> ({{currency}}) </span>
                        </th>
                        <td class="text-right">
                            {{currentBaseLineDetails.riskReserve  | dmDisplayCurrencyOrDash: currency}}
                            <span *ngIf="!currentBaseLineDetails.riskReserve" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </section>

    <!--=========================================== Desktop View ==========================================================-->
    <section class="col-md-12 col-sm-12 dm-tile dm-am-details-table loading-amendments"
        *ngIf="deviceFactory.isDesktop()">
        <dm-loading [loadingText]="loadingAmendmentsText" [isCustomText]="true" *ngIf="isComponentLoading"
            [showLoading]="showLoading"></dm-loading>
        <ng-container *ngIf="!isComponentLoading">

            <section class="dm-tile--no-pad text-left " *ngIf="crDetails">
                <h3 class="dm-tile__header">
                    AMENDMENT DETAILS
                    <span class="dm-tile__content dm-tile__restrictline dm-tile--semi-bold text-capitilize">
                        <span class="amendments-last-updated">
                            Last updated {{crDetails.timeStamp | date: 'dd-MMM-yyyy hh:mm a'}} PT
                        </span>
                    </span>
                    <button (click)="openViewWorkbookModal()" target="_blank" aria-label="open link in new tab"
                        class="font-caption-alt pull-right action__trigger--view-state view-work set-focus">
                        <i class="icon icon-ViewLegacy"></i> View Workbook
                    </button>
                </h3>
            </section>
            <section *ngIf="crDetails">
                <table class="amendments-header">
                    <caption class="sr-only">Amendment Details</caption>
                    <tr>
                        <th scope="col" class="amendments-white dm-a-w-27">
                            <p class="amendments-heading">Amendment ID</p>
                        </th>
                        <th scope="col" class="desc-head-border">
                            <p class="amendments-heading">Status</p>
                        </th>
                        <th scope="col" class="desc-head-border">
                            <p class="amendments-heading">Description</p>
                        </th>
                    </tr>
                    <tr>
                        <td class="amendments-white aendments-w-30 font-caption-alt">
                            <p>
                                {{crDetails.crNumber ?'CR '+crDetails.crNumber :'-'}}
                            </p>
                        </td>
                        <td class="desc-border font-caption-alt dm-a-w-150">
                            <p>
                                {{crDetails.status | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!crDetails.status" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </p>
                        </td>
                        <td class="desc-border font-caption-alt">
                            <p>
                                {{crDetails.crDescription | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!crDetails.crDescription" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </p>
                        </td>
                    </tr>
                </table>
                <section class="table-responsive">
                    <table class="amendments-list" role="presentation">
                        <caption class="sr-only">Amendment Details</caption>
                        <thead>
                            <tr class="font-caption-alt amendments-top-header amendments-top-header__2">
                                <th scope="col" class="dm-a-w-8" valign="bottom">Type</th>
                                <th scope="col" class="dm-a-w-22" valign="bottom">
                                    <span>Task ID/</span>
                                    <p>
                                        <span>EBS Assignment</span>
                                    </p>
                                </th>
                                <th scope="col" class="dm-a-w-13" valign="bottom">
                                    <span>Role ID/</span>
                                    <p>
                                        <span>Role Description</span>
                                    </p>
                                </th>
                                <th scope="col" class="dm-a-w-13 right-align dm-tile--p-r-16" valign="bottom">
                                    <span *ngIf="resourcesType === 'Labor'"><span>Planned Labor Change</span>
                                        <p>
                                            <span>(hours)</span>
                                        </p>
                                    </span>
                                    <span *ngIf="resourcesType === 'Unit'"><span>Planned Unit Change</span>
                                        <p>
                                            <span>(EA)</span>
                                        </p>
                                    </span>
                                    <span *ngIf="resourcesType === 'Mixed'">Planned Change</span>
                                </th>
                                <th scope="col" class="dm-a-w-8 right-align dm-tile--p-r-16" valign="bottom">
                                    <span *ngIf="resourcesType === 'Labor'"><span>Bill Rate</span>
                                        <p>
                                            <span>({{currency}}/hour)</span>
                                        </p>
                                    </span>
                                    <span *ngIf="resourcesType === 'Unit'"><span>Bill Rate</span>
                                        <p>
                                            <span>({{currency}}/EA)</span>
                                        </p>
                                    </span>
                                    <span *ngIf="resourcesType === 'Mixed'"><span>Bill Rate</span>
                                        <p>
                                            <span>({{currency}})</span>
                                        </p>
                                    </span>
                                </th>
                                <th scope="col" class="dm-a-w-8 right-align dm-tile--p-r-16" valign="bottom">
                                    <span *ngIf="resourcesType === 'Labor'"><span>Cost Rate</span>
                                        <p>
                                            <span>({{currency}}/hour)</span>
                                        </p>
                                    </span>
                                    <span *ngIf="resourcesType === 'Unit'"><span>Cost Rate</span>
                                        <p>
                                            <span>({{currency}}/EA)</span>
                                        </p>
                                    </span>
                                    <span *ngIf="resourcesType === 'Mixed'"><span>Bill Rate</span>
                                        <p>
                                            <span>({{currency}})</span>
                                        </p>
                                    </span>
                                </th>
                                <th scope="col" class="dm-a-w-10 right-align dm-tile--p-r-16" valign="bottom">
                                    <span>Planned Revenue Change</span>
                                    <p>
                                        <span>({{currency}})</span>
                                    </p>
                                </th>
                                <th scope="col" class="dm-a-w-9 right-align dm-tile--p-r-16" valign="bottom">
                                    <span>Planned Cost Change</span>
                                    <p>
                                        <span>({{currency}})</span>
                                    </p>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div>
                        <table class="font-caption-alt amendments-list" *ngFor="let project of projectsWithAmendments">
                            <caption class="font-caption-alt amendments-list__header">
                                <div class="breadcrum">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <span>
                                                {{project.projectName | dmDisplayDashIfEmptyOrNull}}
                                            </span>
                                        </div>
                                        <div class="col-md-7 text-right project-header-not-bold">
                                            <span>
                                                <span>Type: </span>
                                                <span [ngStyle]="{'background-color':project.typeOfContractColor}"
                                                    class="dm-tile--pd2-4">
                                                    {{project.typeOfContract | dmDisplayDashIfEmptyOrNull}}
                                                </span>
                                            </span>
                                            <span class="dm-tile--p-l-16">
                                                <span>Duration: </span>
                                                {{project.startDate | dmDisplayDateOrDashPipe}} -
                                                {{project.endDate | dmDisplayDateOrDashPipe}}
                                            </span>
                                            <span class="dm-tile--p-l-16"
                                                [ngClass]="{'text-danger':project.riskReserve<=0}">
                                                <span class="dm-tile__icon--circle icon icon-circle"
                                                    *ngIf="project.riskReserve<=0"></span>
                                                <span>Risk Reserve ({{currency}}): </span>
                                                <span>
                                                    {{project.riskReserve | dmDisplayCurrencyOrDash}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </caption>
                            <thead>
                                <tr class="font-caption-alt amendments-top-header amendments-top-header__2 sr-only">
                                    <th scope="col" class="dm-a-w-8" valign="bottom">Type</th>
                                    <th scope="col" class="dm-a-w-22">
                                        <span>Task ID/</span>
                                        <p>
                                            <span>EBS Assignment</span>
                                        </p>
                                    </th>
                                    <th scope="col" class="dm-a-w-13">
                                        <span>Role ID/</span>
                                        <p>
                                            <span>Role Description</span>
                                        </p>
                                    </th>
                                    <th scope="col" class="dm-a-w-13 right-align dm-tile--p-r-16">
                                        <span>Planned Labor Change</span>
                                        <p>
                                            <span>(hours)</span>
                                        </p>
                                    </th>
                                    <th scope="col" class="dm-a-w-8 right-align dm-tile--p-r-16">
                                        <span>Bill Rate</span>
                                        <p>
                                            <span>({{currency}}/hours)</span>
                                        </p>
                                    </th>
                                    <th scope="col" class="dm-a-w-8 right-align dm-tile--p-r-16">
                                        <span>Cost Rate</span>
                                        <p>
                                            <span>({{currency}}/hours)</span>
                                        </p>
                                    </th>
                                    <th scope="col" class="dm-a-w-10 right-align dm-tile--p-r-16">
                                        <span>Planned Revenue</span>
                                        <p>
                                            <span>({{currency}})</span>
                                        </p>
                                    </th>
                                    <th scope="col" class="dm-a-w-9 right-align dm-tile--p-r-16">
                                        <span>Planned Cost Change</span>
                                        <p>
                                            <span>({{currency}})</span>
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="font-caption-alt valign-top" *ngFor="let amendment of project.amendments">
                                    <th scope="row" class="dm-a-w-8">{{amendment.type}}</th>
                                    <td class="dm-a-w-22">
                                        <span>
                                            {{amendment.wbsl3Id | dmDisplayDashIfEmptyOrNull}}
                                            <span *ngIf="!amendment.wbsl3Id" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </span>
                                    </td>
                                    <td class="dm-a-w-13">
                                        <span>
                                            {{amendment.roleId | dmDisplayDashIfEmptyOrNull}}
                                        </span>
                                        <p>
                                            <span>
                                                {{amendment.roleDescription | dmDisplayDashIfEmptyOrNull}}
                                            </span>
                                        </p>
                                        <span *ngIf="!amendment.roleId && !amendment.roleDescription" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </td>
                                    <td class="dm-a-w-13 right-align dm-tile--p-r-16 text-nowrap"
                                        [ngClass]="(amendment.plannedLaborHours<0)?'text-danger':'text-space'">
                                        {{amendment.plannedLaborHours>0 ? "+" : ""}}
                                        {{amendment.plannedLaborHours ? (amendment.plannedLaborHours | number: '1.0-2') :'-'}}
                                        <b class="dm-tile__icon--circle icon icon-circle"
                                            *ngIf="amendment.plannedLaborHours<0"></b>
                                    </td>
                                    <td class="dm-a-w-8 right-align dm-tile--p-r-16 text-nowrap">
                                        <span>
                                            {{amendment.billRate  | dmDisplayCurrencyOrDash}}
                                            <span *ngIf="!amendment.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </span>
                                    </td>
                                    <td class="dm-a-w-8 right-align dm-tile--p-r-16 text-nowrap">
                                        <span>
                                            {{amendment.costRate  | dmDisplayCurrencyOrDash}}
                                            <span *ngIf="!amendment.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </span>
                                    </td>
                                    <td class="dm-a-w-10 right-align dm-tile--p-r-16"
                                        [ngClass]="(amendment.plannedRevenue<0)?'text-danger text-nowrap':'text-space'">
                                        {{amendment.plannedRevenue > 0 ? "+" : ""}}
                                        {{amendment.plannedRevenue | dmDisplayCurrencyOrDash}}
                                        <b class="dm-tile__icon--circle icon icon-circle"
                                            *ngIf="amendment.plannedRevenue<0"></b>
                                    </td>
                                    <td class="dm-a-w-9 right-align dm-tile--p-r-16 text-nowrap">
                                        {{amendment.plannedCost>0? "+": ""}}
                                        {{amendment.plannedCost  | dmDisplayCurrencyOrDash}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </section>
            <section class="col-md-12 col-sm-12 font-title text-center c-7 pd-20"
                *ngIf="projectsWithAmendments && !projectsWithAmendments.length">
                No Amendment details found
            </section>
        </ng-container>
    </section>

    <!--=========================================== Small Screen View ==========================================================-->
    <section id="dm-amendment-details-mob">
        <section class="col-sm-12 dm-tile--no-pad clearfix loading-amendments" *ngIf="deviceFactory.isSmallScreen()">
            <dm-loading [loadingText]="loadingAmendmentsText" [isCustomText]="true" *ngIf="isComponentLoading"
                [showLoading]="showLoading"></dm-loading>
            <ng-container *ngIf="!isComponentLoading">
                <section class="col-sm-12 dm-tile--no-pad am-details-header d-inlineblock-mobile pad-12-mobile"
                    *ngIf="crDetails">
                    <h4 class="am-details-header">
                        <span class="am-details-header am-details-header--heading">AMENDMENT DETAILS</span>
                        <span class="am-details-header--bold d-block-mobile">
                            <span class="amendments-last-updated">
                                Last updated {{crDetails.timeStamp | date: 'dd-MMM-yyyy hh:mm a'}} PT
                            </span>
                        </span>
                    </h4>

                    <p class="am-details-header--bold pd-t-12">
                        <span>Amendment ID
                            <span aria-hidden="true">:</span>
                        </span>
                        <span>
                            {{crDetails.crNumber?'CR '+crDetails.crNumber:'-'}}
                        </span>
                    </p>
                    <p class="am-details-header">
                        <span>Description
                            <span aria-hidden="true">:</span>
                        </span>
                        <span>
                            {{crDetails.crDescription | dmDisplayDashIfEmptyOrNull}}
                        </span>
                    </p>
                    <p class="am-details-header pd-b-12">
                        <span>Status
                            <span aria-hidden="true">:</span>
                        </span>
                        <span>
                            {{crDetails.status | dmDisplayDashIfEmptyOrNull}}
                        </span>

                        <dm-tooltip role="tooltip" class="dm-tooltipinline amedmentsTooltip-mobile"
                            [dmPlacement]="'left'" [ngbTooltipContent]="'amedmentsTooltip-tab'"
                            [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Status Information">
                        </dm-tooltip>

                    </p>
                    <div id="amedmentsTooltip-tab" class="amendments-tooltip">
                        <p>
                            <span>Approved: Signed (Internal or External)</span>
                        </p>
                        <p class="pd-t-b-2">
                            <span>In-Process: Action needed by user</span>
                        </p>
                        <p>
                            <span>New: Newly created CR/Amendment</span>
                        </p>
                    </div>
                </section>
                <section class="col-sm-12 dm-tile--no-pad">
                    <section *ngFor="let project of projectsWithAmendments">
                        <section class="amendments-list__header pull-left mr-b-12">
                            <section class="col-md-6 col-sm-6 col-xs-6">
                                <span>{{project.projectName | dmDisplayDashIfEmptyOrNull}}</span>
                            </section>
                            <section class="col-sm-6 col-md-6 col-xs-6 pull-right">
                                <dm-tooltip role="tooltip"
                                    class="dm-tooltipinline amedmentsTooltip-mobile pull-right" [dmPlacement]="'left'"
                                    [ngbTooltipContent]="'amendments-details-risk-type'"
                                    [dmIcon]="'tile__icon--moreLegacy icon icon-moreLegacy'" ariaLabelToolTip="more Information">
                                </dm-tooltip>
                            </section>
                            <div id="amendments-details-risk-type">
                                <div class="project-header-not-bold">
                                    <section>
                                        <h5 class="project-header-not-bold--header">Type: </h5>
                                        <p class="project-header-not-bold--item">
                                            <span class="project-header-not-bold--item--type"
                                                [ngStyle]="{'background-color':project.typeOfContractColor}">
                                                {{project.typeOfContract | dmDisplayDashIfEmptyOrNull}}
                                            </span>
                                        </p>
                                    </section>
                                    <section>
                                        <h5 class="project-header-not-bold--header">Duration: </h5>
                                        <p class="project-header-not-bold--item">
                                            {{project.startDate | dmDisplayDateOrDashPipe}} -
                                            {{project.endDate | dmDisplayDateOrDashPipe}}
                                        </p>
                                    </section>
                                    <section>
                                        <h5 class="project-header-not-bold--header">
                                            Risk Reserve ({{currency}}) Change:
                                        </h5>
                                        <p class="project-header-not-bold--item"
                                            [ngClass]="{'text-danger':project.riskReserve<=0}">
                                            <span>
                                                {{project.riskReserve | dmDisplayCurrencyOrDash}}
                                            </span>
                                        </p>
                                    </section>
                                </div>
                            </div>
                        </section>

                        <!--================================Tablet view ===============================-->
                        <section *ngIf="deviceFactory.isTablet()">
                            <section class="amendments-list col-sm-12 dm-tile dm-tile--p-l-12 dm-tile--p-r-24"
                                *ngFor="let amendment of project.amendments">
                                <section class="col-sm-12 dm-tile--no-pad">
                                    <section class="col-sm-3 dm-tile--no-pad">
                                        <h4 class="dm-tile--opacity06">
                                            Task ID
                                            <span aria-hidden="true">: </span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>{{amendment.wbsl3Id | dmDisplayDashIfEmptyOrNull}}</span>
                                        </p>
                                    </section>
                                    <section class="col-sm-7 dm-tile--no-pad">
                                        <h4 class="dm-tile--opacity06">
                                            EBS Assignment
                                            <span aria-hidden="true">: </span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4"
                                            *ngIf="(amendment.engagementDescription || amendment.projectDescription || amendment.wbsl3Description)">
                                            <span>
                                                {{amendment.engagementDescription | dmDisplayDashIfEmptyOrNull}} /
                                                {{amendment.projectDescription | dmDisplayDashIfEmptyOrNull}} /
                                                {{amendment.wbsl3Description | dmDisplayDashIfEmptyOrNull}}
                                            </span>
                                        </p>
                                    </section>
                                    <section class="col-sm-2">
                                        <h4 class="dm-tile--opacity06">
                                            Type
                                            <span aria-hidden="true">: </span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>{{amendment.type | dmDisplayDashIfEmptyOrNull}}</span>
                                        </p>
                                    </section>
                                </section>
                                <section class="col-sm-12 dm-tile--no-pad dm-tile--mr-t-20 am-role-section">
                                    <section class="col-sm-3 dm-tile--no-pad">
                                        <h4 class="dm-tile--opacity06">
                                            Role ID
                                            <span aria-hidden="true">: </span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>{{amendment.roleId | dmDisplayDashIfEmptyOrNull}}</span>
                                        </p>
                                    </section>
                                    <section class="col-sm-9 dm-tile--no-pad">
                                        <h4 class="dm-tile--opacity06">
                                            Role Description
                                            <span aria-hidden="true">: </span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>{{amendment.roleDescription | dmDisplayDashIfEmptyOrNull}}</span>
                                        </p>
                                    </section>
                                </section>
                                <section class="col-sm-12 dm-tile--no-pad  dm-tile--mr-t-20">
                                    <section class="col-sm-2 dm-tile--no-pad text-right width-20per">
                                        <h4 class="dm-tile--opacity06 dm-tile--p-r-16">
                                            Planned Labor
                                            <span class="d-block">Change (hours)</span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4"
                                            [ngClass]="(amendment.plannedLaborHours<0)?'text-danger text-nowrap':'text-space dm-tile--p-r-16'">
                                            {{amendment.plannedLaborHours>0 ? "+" : ""}}
                                            {{amendment.plannedLaborHours | dmDisplayDashIfEmptyOrNull}}
                                            <i class="dm-tile__icon--circle icon icon-circle"
                                                *ngIf="amendment.plannedLaborHours<0"></i>
                                        </p>
                                    </section>

                                    <section class="col-sm-2 dm-tile--no-pad text-right width-20per">
                                        <h4 class="dm-tile--opacity06 dm-tile--p-r-16">
                                            Bill Rate
                                            <span class="d-block">({{currency}}/hour)</span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4 text-space dm-tile--p-r-16">
                                            <span>{{amendment.billRate  | dmDisplayCurrencyOrDash}}</span>
                                        </p>
                                    </section>

                                    <section class="col-sm-2 dm-tile--no-pad text-right width-20per">
                                        <h4 class="dm-tile--opacity06 dm-tile--p-r-16">
                                            Cost Rate
                                            <span class="d-block">({{currency}}/hour)</span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4 text-space dm-tile--p-r-16">
                                            <span>{{amendment.costRate   | dmDisplayCurrencyOrDash}}</span>
                                        </p>
                                    </section>

                                    <section class="col-sm-2 dm-tile--no-pad text-right width-20per">
                                        <h4 class="dm-tile--opacity06 dm-tile--p-r-16">
                                            Planned Revenue
                                            <span class="d-block">Change ({{currency}})</span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4"
                                            [ngClass]="(amendment.plannedRevenue<0)?'text-danger text-nowrap':'text-space dm-tile--p-r-16'">
                                            {{amendment.plannedRevenue>0 ? "+" : ""}}
                                            {{amendment.plannedRevenue | dmDisplayCurrencyOrDash}}
                                            <i class="dm-tile__icon--circle icon icon-circle"
                                                *ngIf="amendment.plannedRevenue<0"></i>
                                        </p>
                                    </section>

                                    <section class="col-sm-2 dm-tile--no-pad text-right width-20per">
                                        <h4 class="dm-tile--opacity06 dm-tile--p-r-16">
                                            Planned Cost
                                            <span class="d-block">Change ({{currency}})</span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4 text-space dm-tile--p-r-16">
                                            {{amendment.plannedCost>0 ? "+" : ""}}
                                            {{amendment.plannedCost  | dmDisplayCurrencyOrDash}}
                                        </p>
                                    </section>
                                </section>
                            </section>
                        </section>

                        <section *ngIf="deviceFactory.isMobile()">
                            <section class="amendments-list col-xs-12 dm-tile dm-tile--p-l-12 dm-tile--p-r-12"
                                *ngFor="let amendment of project.amendments">
                                <section class="col-xs-12 dm-tile--no-pad dm-tile--p-b-12">
                                    <section class="col-xs-8 dm-tile--no-pad">
                                        <h4 class="dm-tile--opacity06">
                                            Task ID
                                            <span aria-hidden="true">:</span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>{{amendment.wbsl3Id | dmDisplayDashIfEmptyOrNull}}</span>
                                        </p>
                                    </section>
                                    <section class="col-xs-4 dm-tile--no-pad">
                                        <h4 class="dm-tile--opacity06">
                                            Type
                                            <span aria-hidden="true">:</span>
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>{{amendment.type | dmDisplayDashIfEmptyOrNull}}</span>
                                        </p>
                                    </section>
                                </section>
                                <section class="col-xs-12 dm-tile--no-pad dm-tile--p-b-12 dm-tile--b-b-015">
                                    <h4 class="dm-tile--opacity06">
                                        EBS Assignment
                                        <span aria-hidden="true">:</span>
                                    </h4>
                                    <p class="font-caption-alt dm-tile--p-t-4"
                                        *ngIf="(amendment.engagementDescription || amendment.projectDescription || amendment.wbsl3Description)">
                                        <span>
                                            <span>{{amendment.engagementDescription | dmDisplayDashIfEmptyOrNull}} /
                                            </span>
                                            <span>{{amendment.projectDescription | dmDisplayDashIfEmptyOrNull}} /
                                            </span>
                                            <span class="nowrap-mobile">
                                                {{amendment.wbsl3Description | dmDisplayDashIfEmptyOrNull}}
                                            </span>
                                        </span>
                                    </p>
                                </section>
                                <section class="col-xs-12 dm-tile--no-pad dm-tile--p-b-12 dm-tile--p-t-12">
                                    <h4 class="dm-tile--opacity06">
                                        Role ID
                                        <span aria-hidden="true">:</span>
                                    </h4>
                                    <p class="font-caption-alt dm-tile--p-t-4">
                                        <span>{{amendment.roleId | dmDisplayDashIfEmptyOrNull}}</span>
                                    </p>
                                </section>
                                <section
                                    class="col-xs-12 dm-tile--no-pad dm-tile--p-b-12 dm-tile--p-t-12 dm-tile--b-b-015">
                                    <h4 class="dm-tile--opacity06">
                                        Role Description
                                        <span aria-hidden="true">:</span>
                                    </h4>
                                    <p class="font-caption-alt dm-tile--p-t-4">
                                        <span>{{amendment.roleDescription| dmDisplayDashIfEmptyOrNull}}</span>
                                    </p>
                                </section>
                                <section class="col-xs-12 dm-tile--no-pad  dm-tile--p-t-12">
                                    <section class="col-xs-6 dm-tile--no-pad text-right dm-tile--p-r-16"
                                        [ngClass]="(amendment.plannedLaborHours<0)?'text-danger text-nowrap':'text-space'">
                                        <h4 class="dm-tile--opacity06">
                                            Planned Labor Change (hours)
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            {{amendment.plannedLaborHours > 0 ? "+": ""}}
                                            {{amendment.plannedLaborHours | dmDisplayDashIfEmptyOrNull}}
                                            <b class="dm-tile__icon--circle icon icon-circle"
                                                *ngIf="amendment.plannedLaborHours<0"></b>
                                        </p>
                                    </section>
                                    <section class="col-xs-6 dm-tile--no-pad text-right dm-tile--p-r-16 text-space">
                                        <h4 class="dm-tile--opacity06">
                                            Bill Rate ({{currency}}/hour)
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>{{amendment.billRate  | dmDisplayCurrencyOrDash}}</span>
                                        </p>
                                    </section>
                                </section>
                                <section class="col-xs-12 dm-tile--no-pad  dm-tile--mr-t-20">
                                    <section class="col-xs-6 dm-tile--no-pad text-right dm-tile--p-r-16"
                                        [ngClass]="(amendment.plannedRevenue<0)?'text-danger text-nowrap':'text-space'">
                                        <h4 class="dm-tile--opacity06">
                                            Planned Revenue Change ({{currency}})
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            {{amendment.plannedRevenue>0 ? "+" : ""}}
                                            {{amendment.plannedRevenue  | dmDisplayCurrencyOrDash}}
                                            <b class="dm-tile__icon--circle icon icon-circle"
                                                *ngIf="amendment.plannedRevenue<0"></b>
                                        </p>
                                    </section>
                                    <section class="col-xs-6 dm-tile--no-pad text-right dm-tile--p-r-16 text-space">
                                        <h4 class="dm-tile--opacity06">
                                            Cost Rate ({{currency}}/hour)
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            <span>
                                                {{amendment.costRate  | dmDisplayCurrencyOrDash}}
                                            </span>
                                        </p>
                                    </section>

                                </section>
                                <section class="col-xs-12 dm-tile--no-pad  dm-tile--mr-t-20">
                                    <section class="col-xs-6 dm-tile--no-pad text-right dm-tile--p-r-16 text-space">
                                        <h4 class="dm-tile--opacity06">
                                            Planned Cost Change ({{currency}})
                                        </h4>
                                        <p class="font-caption-alt dm-tile--p-t-4">
                                            {{amendment.plannedCost>0 ? "+" : ""}}
                                            {{amendment.plannedCost  | dmDisplayCurrencyOrDash}}
                                        </p>
                                    </section>
                                </section>
                            </section>
                        </section>

                    </section>
                    <section class="col-md-12 col-sm-12 font-title text-center pd-20 dm-tile c-7"
                        *ngIf="projectsWithAmendments && !projectsWithAmendments.length">
                        No Amendment details found
                    </section>
                </section>
            </ng-container>
        </section>
    </section>
</article>
