import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { ICircularProgressOptions, CircleProgressDefaultOptions } from "../../../common/services/contracts/circularprogressbar.contracts";

// Options for Circular Progress Bar
export class CircleProgressOptions implements ICircularProgressOptions {
    public animationDuration?: number;
    public class?: string;
    public innerStrokeColor: string;
    public innerStrokeWidth: number;
    public outerStrokeColor: string;
    public outerStrokeWidth: number;
    public percent: number;
    public radius: number;
    public space: number;
    public subtitle: string;
    public subtitleLineTwo?: string;
    public subtitleFontSize: number;
    public toFixed?: number;
    public units?: string;
    public value?: string;
}

@Component({
    selector: "dm-circular-progress",
    templateUrl: "./circular-progress.component.html",
    styleUrls: ["./circular-progress.component.scss"]
})
export class CircularProgressComponent implements OnChanges, OnDestroy {
    @Input("options") public templateOptions: CircleProgressOptions;

    // Displays the value of filled percentage
    @Input() public value: string;
    // Animation duration
    @Input() public animationDuration: number;
    // Additional CSS class input for styling
    @Input() public class: string;
    // To display the decimal points of percentage default is 0.
    @Input() public toFixed: number;
    // To display units . Default is "%".
    @Input() public units: string;

    // Required input fields for circular progress bar
    // Input for color of inner stroke i.e path
    @Input() public innerStrokeColor: string;
    // Input for width of inner strok
    @Input() public innerStrokeWidth: number;
    // Input for color of outer stroke
    @Input() public outerStrokeColor: string;
    // Input for width of outer stroke
    @Input() public outerStrokeWidth: number;
    // Input for percentage of filled path.
    @Input() public percent: number;
    // Input radius of circle
    @Input() public radius: number;
    // Always give a negative value of outerStrokeWidth to align with the circle.
    @Input() public space: number;
    // Displays subtitle
    @Input() public subtitle: string;
    // Sub title font size
    @Input() public subtitleFontSize: number;
    // Value font size
    @Input() public valueFontSize: number;
    // Distance between title and sub title
    @Input() public subtitleDistance: number;
    // Sub title font weight
    @Input() public subtitleFontWeight: number = 400;    
    // End of required options.

    @Input() public showValue: boolean = false;
    @Input() public titleFontWeight: number = 400;   
    @Input() public subtitleLineTwo: string; 

    public options: CircleProgressOptions = new CircleProgressOptions();
    public svg: any;

    private lastPercent: number = 0;
    private timerSubscription: Subscription;
    private defaultOptions: CircleProgressDefaultOptions = new CircleProgressDefaultOptions();

    public constructor() {
        Object.assign(this.options, this.defaultOptions);
    }

    /**
     * Calls render method if there are any changes in options.
     */
    public ngOnChanges(): void {
        this.render();
    }

    public ngOnDestroy(): void {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    /* Method to render circular progress component */
    private render = (): void => {
        this.applyOptions();
        if (this.options.animationDuration > 0) {
            this.animate(this.lastPercent, this.options.percent);
        } else {
            this.draw(this.options.percent);
        }
        this.lastPercent = this.options.percent;
    };

    /**
     * Converts polar cordinates to cartesian co-ordinates
     * @param {number} centerX
     * @param {number} centerY
     * @param {number} radius
     * @param {number} angleInDegrees
     * @returns {{ x: number, y: number }}
     */
    private polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number): { x: number; y: number } => {
        const angleInRadius = angleInDegrees * Math.PI / 180;
        const x = centerX + Math.sin(angleInRadius) * radius;
        const y = centerY - Math.cos(angleInRadius) * radius;
        return { x, y };
    };

    /**
     * Method draws a svg for circular progress bar
     * @param {number} percent
     */
    private draw = (percent: number): void => {
        percent = (percent === undefined) ? this.defaultOptions.percent : Math.abs(percent);
        // circle percent shouldn't be greater than 100%.
        const circlePercent = (percent > 100) ? 100 : percent;
        // determine box size
        const boxSize = this.options.radius * 2 + this.options.outerStrokeWidth * 2;

        // the centre of the circle
        const centre = { x: boxSize / 2, y: boxSize / 2 };
        // the start point of the arc
        const startPoint = { x: centre.x, y: centre.y - this.options.radius };
        // get the end point of the arc
        const endPoint = this.polarToCartesian(centre.x, centre.y, this.options.radius, 360 * (circlePercent) / 100);  // ####################
        // We'll get an end point with the same [x, y] as the start point when percent is 100%, so move x a little bit.
        if (circlePercent === 100) {
            endPoint.x = endPoint.x - 0.01;
        }
        // largeArcFlag and sweepFlag
        let largeArcFlag: number;
        let sweepFlag: number;
        if (circlePercent > 50) {
            [largeArcFlag, sweepFlag] = [1, 1];
        } else {
            [largeArcFlag, sweepFlag] = [0, 1];
        }

        this.svg = {
            viewBox: `0 0 ${boxSize} ${boxSize}`,
            width: boxSize,
            height: boxSize,
            backgroundCircle: {
                cx: centre.x,
                cy: centre.y,
                r: this.options.radius + this.options.outerStrokeWidth / 2,
            },
            path: {
                // A rx ry x-axis-rotation large-arc-flag sweep-flag x y
                d: `M ${startPoint.x} ${startPoint.y}
      A ${this.options.radius} ${this.options.radius} 0 ${largeArcFlag} ${sweepFlag} ${endPoint.x} ${endPoint.y}`,
                stroke: this.options.outerStrokeColor,
                strokeWidth: this.options.outerStrokeWidth,
                fill: "none"
            },
            circle: {
                cx: centre.x,
                cy: centre.y,
                r: this.options.radius - this.options.space - this.options.outerStrokeWidth / 2 - this.options.innerStrokeWidth / 2,
                fill: "none",
                stroke: this.options.innerStrokeColor,
                strokeWidth: this.options.innerStrokeWidth,
            },
            value: this.options.percent.toFixed(this.toFixed),
            units: this.options.units,
            subtitle: this.options.subtitle,
            subtitleFontSize: this.options.subtitleFontSize,
            subtitleLineTwo: this.options.subtitleLineTwo
        };
    };

    /**
     *
     * @returns {{step:number,interval:number}}
     * @private
     * @memberof CircularProgressComponent
     */
    private getAnimationParameters = (previousPercent: number, currentPercent: number): { step: number; interval: number } => {
        const MIN_INTERVAL: number = 5;
        let times: number;
        let step: number;
        let interval: number;
        const fromPercent = previousPercent < 0 ? 0 : previousPercent;
        const toPercent = currentPercent < 0 ? 0 : Math.min(currentPercent, 100);
        const delta = Math.abs(Math.round(toPercent - fromPercent));

        if (delta >= 100) {
            // we will finish animation in 100 times
            times = 100;
            // show title animation even if the arc is full, we also need to finish it in 100 times.
            step = Math.round(delta / times);
        } else {
            // we will finish in as many times as the number of percent.
            times = delta;
            step = 1;
        }
        // Get the interval of timer
        interval = Math.round(this.options.animationDuration / times);
        // Readjust all values if the interval of timer is extremely small.
        if (interval < MIN_INTERVAL) {
            interval = MIN_INTERVAL;
            times = this.options.animationDuration / interval;
            if (delta > 100) {
                step = Math.round(100 / times);
            } else {
                step = Math.round(delta / times);
            }
        }
        // step must be greater than 0.
        if (step < 1) {
            step = 1;
        }
        return { step, interval };
    };

    /**
     * Animates the progress bar based on change in percent.
     * @param {number} previousPercent
     * @param {number} currentPercent
     */
    private animate = (previousPercent: number, currentPercent: number): void => {
        if (this.timerSubscription && !this.timerSubscription.closed) {
            this.timerSubscription.unsubscribe();
        }
        const fromPercent = previousPercent;
        const toPercent = currentPercent;
        const { step: step, interval: interval } = this.getAnimationParameters(fromPercent, toPercent);
        let count = fromPercent;
        if (fromPercent < toPercent) {
            this.timerSubscription = timer(0, interval).subscribe(() => {
                count += step;
                if (count <= toPercent) {
                    if (count >= 100) {
                        this.draw(toPercent);
                        this.timerSubscription.unsubscribe();
                    } else {
                        this.draw(count);
                    }
                } else {
                    this.draw(toPercent);
                    this.timerSubscription.unsubscribe();
                }
            });
        } else {
            this.timerSubscription = timer(0, interval).subscribe(() => {
                count -= step;
                if (count >= toPercent) {
                    if (toPercent >= 100) {
                        this.draw(toPercent);
                        this.timerSubscription.unsubscribe();
                    } else {
                        this.draw(count);
                    }
                } else {
                    this.draw(toPercent);
                    this.timerSubscription.unsubscribe();
                }
            });
        }
    };

    /**
     * Apply all the options passed to progress bar
     *
     * @private
     * @memberof CircularProgressComponent
     */
    private applyOptions = (): void => {
        for (const name of Object.keys(this.options)) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.hasOwnProperty(name) && this[name] !== undefined) {
                this.options[name] = this[name];
            } else if (this.templateOptions && this.templateOptions[name] !== undefined) {
                this.options[name] = this.templateOptions[name];
            }
        }
        // make sure key options valid
        this.options.radius = Math.abs(+this.options.radius);
        this.options.space = +this.options.space;
        this.options.percent = +this.options.percent > 0 ? +this.options.percent : 0;
        this.options.animationDuration = Math.abs(this.options.animationDuration);
        this.options.outerStrokeWidth = Math.abs(+this.options.outerStrokeWidth);
        this.options.innerStrokeWidth = Math.abs(+this.options.innerStrokeWidth);
        this.options.subtitleFontSize = Math.abs(+this.options.subtitleFontSize);
    };
}
