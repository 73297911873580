<section class="dm-cost-summary">
    <dm-loading [loadingText]="loadingMessage" [isCustomText]="true"
        [showLoading]="!costSummaryData && !costSummaryError" *ngIf="!costSummaryData"></dm-loading>
    <div role="grid" *ngIf="costSummaryData && costSummaryData.length" class="dm-cost-summary__grid">
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row" class="dm-grid-view-table__row dm-cost-summary__row">
                <div role="columnheader" class="dm-grid-view-table__headerCell">Project Code</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">
                    <span>New Planned <br /> Cost(A)
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'top'" [dmTriggers]="'hover'"
                            [ngbTooltipContent]="'(Delivery Baseline Cost - Delivery Baseline RR) + Cost Impact'"
                            [dmIcon]="'icon icon-info'"
                            ariaLabelToolTip="(Delivery Baseline Cost - Delivery Baseline RR) + Cost Impact">
                        </dm-tooltip>
                    </span>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">
                    <span>Old Planned <br /> Cost(B)
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'top'" [dmTriggers]="'hover'"
                            [ngbTooltipContent]="'Delivery Baseline Cost - Delivery Baseline RR'"
                            [dmIcon]="'icon icon-info'"
                            ariaLabelToolTip="'Delivery Baseline Cost - Delivery Baseline RR'">
                        </dm-tooltip>
                    </span>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Approvals Needed
                    <br />(C = A - B)</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Contract Baseline
                    <br />
                    Cost Excl. RR (D)</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">
                    <span>RR Usage + Overrun
                        <br /> (E = A - D)
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'top'" [dmTriggers]="'hover'"
                            [ngbTooltipContent]="'Any Negative Number is Savings Beyond RR'"
                            [dmIcon]="'icon icon-info'"
                            ariaLabelToolTip="'Any Negative Number is Savings Beyond RR'">
                        </dm-tooltip>
                    </span>
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Contract Baseline
                    <br />
                    Cost Incl. RR (F)</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">
                    <span>Net Cost Overrun
                        <br /> (G = A - F)
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'"
                            [ngbTooltipContent]="'New Planned Cost - Contract Baseline Cost Incl. RR'"
                            [dmIcon]="'icon icon-info'"
                            ariaLabelToolTip="'New Planned Cost - Contract Baseline Cost Incl. RR'">
                        </dm-tooltip>
                    </span></div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">
                    <span>Remaining
                        <br />Unassigned RR
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                            [dmTriggers]="'hover'" [ngbTooltipContent]="'Total Current Financial Plan RR - Approved RR'"
                            [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Total Current Financial Plan RR - Approved RR'">
                        </dm-tooltip>
                    </span>
                </div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-cost-summary__row"
                *ngFor="let costSummaryItem of costSummaryData">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">{{costSummaryItem.projectId}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.newPlannedCost | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.oldPlannedCost | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.approvalsNeeded | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.budgetCostExcludingRiskReserve | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.grossCostOverRun | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.budgetCostIncludingRiskReserve | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.netCost | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{costSummaryItem?.remainingUnassignedRiskReserve > 0 ? (costSummaryItem?.remainingUnassignedRiskReserve | number : '1.2-2') : (0 | number : '1.2-2')}}
                </div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-cost-summary__row">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong class="font-caption-alt">TOTALS</strong></div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{costSummaryData | sum: 'newPlannedCost' | number : '1.2-2'}}</strong></div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{costSummaryData | sum: 'oldPlannedCost' | number : '1.2-2'}}</strong> </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong
                        class="dm-badge dm-badge--approvals">{{costSummaryData | sum: 'approvalsNeeded' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{costSummaryData | sum: 'budgetCostExcludingRiskReserve' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong
                        class="dm-badge dm-badge--gross-overrun">{{costSummaryData | sum: 'grossCostOverRun' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{costSummaryData | sum: 'budgetCostIncludingRiskReserve' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{costSummaryData | sum: 'netCost' | number : '1.2-2'}}</strong></div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{costSummaryData | sum: 'remainingUnassignedRiskReserve' | number : '1.2-2'}}</strong>
                </div>
            </div>
        </div>
    </div>
</section>