<div id="{{'activity-feed-notification' + eventName}}" class="activity-feed-notification">
   <div class="display-flex dm-p-20">
      <div class="dm-m-r-15" [ngClass]="{'image-section': !isLastActivity}">
         <img [src]="userImage" [alt]="createdBy + ' image'" *ngIf="userImage" class="image-size">
         <div class="userPhoto image-size" *ngIf="!userImage">
            <span class="icon icon-user" *ngIf="createdBy !== 'Z-ES_PMS'"></span>
            <span class="icon-full icon-system" *ngIf="createdBy === 'Z-ES_PMS'"></span>
         </div>
      </div>
      <div>
         <div class="dm-detail__value dm-p-b-5">{{eventName}}</div>
         <div class="font-caption-alt dm-p-b-5">{{eventDescription}}</div>
         <div class="font-caption-alt">{{eventTime}}</div>
      </div>
   </div>
</div>