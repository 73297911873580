<section class="dm-hours-summary">
    <dm-loading [loadingText]="loadingMessage" [errorText]="hoursSummaryError" [isCustomText]="true"
        [showLoading]="!hoursSummaryData && !hoursSummaryError" *ngIf="!hoursSummaryData"></dm-loading>

    <div role="grid" *ngIf="hoursSummaryData && hoursSummaryData.length" class="dm-hours-summary__grid">
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row" class="dm-grid-view-table__row dm-hours-summary__row">
                <div role="columnheader" class="dm-grid-view-table__headerCell">Project Code</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">New TPH<br />(A)</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Old TPH <br />(B)
                </div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Approvals Needed
                    <br />(C = A - B)</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Contract Baseline
                    Hours (D)</div>
                <div role="columnheader" class="dm-grid-view-table__headerCell text-align--right">Hours Variance <br />
                    (E = A - D)</div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-hours-summary__row"
                *ngFor="let hoursSummaryItem of hoursSummaryData">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">{{hoursSummaryItem?.projectId}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{hoursSummaryItem?.newHoursVariance | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{hoursSummaryItem?.oldHoursVariance | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{hoursSummaryItem?.approvalsNeeded | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{hoursSummaryItem?.budgetHours | number : '1.2-2'}}</div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    {{hoursSummaryItem?.hoursVariance | number : '1.2-2'}}</div>
            </div>
            <div role="row" class="dm-grid-view-table__row dm-hours-summary__row">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                    <strong class="font-caption-alt text-uppercase">TOTALS</strong></div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{hoursSummaryData | sum: 'newHoursVariance'  | number : '1.2-2'}}</strong></div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{hoursSummaryData | sum: 'oldHoursVariance' | number : '1.2-2'}}</strong> </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong
                        class="dm-badge dm-badge--approvals">{{hoursSummaryData | sum: 'approvalsNeeded' | number : '1.2-2'}}</strong>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong>{{hoursSummaryData | sum: 'budgetHours' | number : '1.2-2'}}</strong></div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                    <strong
                        class="dm-badge dm-badge--gross-overrun">{{hoursSummaryData | sum: 'hoursVariance' | number : '1.2-2'}}</strong>
                </div>
            </div>
        </div>
    </div>
</section>