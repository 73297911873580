<section class="unsuspend-dailog">
    <label class="sr-only">Unsuspend Request</label>
    <section class="modal-header">
        <button type="button" class="close set-focus" (click)="closeModal()" 
            (keydown)="moveFocusPrev($event, 'accessibilityConstants.UnsuspendRequest')" id="accessibilityConstants.ClosePopUp" aria-label="Unsuspend Request Dialog close">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            Unsuspend Request
        </h4>
    </section>
    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [errorText]="errorSummaryText"
        [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>
    <section class="modal-body" *ngIf="!isLoading">
        <form #unsuspendRequest>
            <section class="section__wrapper">
                <section class="input__label">
                    <label class="input__label--text" for="dm-unsuspend-reason-ddl">Reason Code
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <section class="input__select--icon">
                        <select id="dm-unsuspend-reason-ddl" [(ngModel)]="selectedReason" name="reasonType" required
                            class="input__select input__select--big font-caption-alt" (change)="onReasonCodeChange()">
                            <option [value]="'select'">Select reason code</option>
                            <option *ngFor="let reason of unsuspendReason" [value]="reason.Name">
                                {{reason.Name}}
                            </option>
                        </select>
                        <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                    </section>
                </section>
                <label class="input__label--text" for="dm-unsuspend-reason">Comments<span class="required-mark"
                        aria-hidden="true" *ngIf="selectedReason && selectedReason.toLowerCase()==='other'">
                        *</span></label>
                <textarea id="dm-unsuspend-reason" [(ngModel)]="unsuspendComment" name="rejectionComments" class="input_text_edge"
                    placeholder="  Comments" *ngIf="!(selectedReason && selectedReason.toLowerCase()==='other')"></textarea>
                    <textarea id="dm-unsuspend-reason" [(ngModel)]="unsuspendComment" name="rejectionComments" required class="input_text_edge"
                    placeholder="  Comments" *ngIf="(selectedReason && selectedReason.toLowerCase()==='other')"></textarea>
            </section>
            <section class="modal-footer footer-button">
                <button type="button" class="fxp-btn fxp-btn--secondary" id="Cancel" (click)="closeModal()">Cancel</button>
                <button type="button" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.UnsuspendRequest" (click)="submit()" (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp')" [disabled]="isSubmitBtnDisabled">
                    Submit
                </button>
                <label id="lblMessage" class="input__label" for="dm-id-suspendRequest">
                    <span class="required-mark" role="alert" aria-atomic="true" aria-live="polite">{{lblMessage}}</span>
                </label>
            </section>
        </form>
    </section>
</section>