<div class="dm-progress-bar" [ngStyle]="{'width':progressBarWidth}">
  <ng-container *ngFor="let step of progressBar; let index = index">
    <div class="step" [ngStyle]="{'width': setStepWidth(step)}"
      [ngClass]="{'step-min-width': !isEndDateToday && step.type === DateType.Today, 'no-min-width': isStartDateToday}">
      <span class="date-text" aria-hidden="true">
        {{step.stepDate}}
      </span>
    </div>
  </ng-container>
</div>
<span class="sr-only">
  <span>Progress bar</span>
  <span *ngFor="let step of progressBar">
    <label>{{step.stepDate}} ''</label>
  </span>
</span>