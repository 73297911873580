import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { CommonComponentsModule } from "../../../../../components/entry/common-components.module";
import { DmSliderModule } from "../../../../../components/tiles/dm-slider/dm-slider.module";
import { DecoPrevalidation } from "./deco-prevalidation.component";


@NgModule({
    declarations: [
        DecoPrevalidation
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        DmSliderModule
    ],
    providers: [
        DMLoggerService,
    ],
    exports: [
        DecoPrevalidation
    ],
    entryComponents: [
        DecoPrevalidation
    ]
})
export class DecoPrevalidationModule { }