import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { RiskReserveModalComponent } from "./risk-reserve.component";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        RiskReserveModalComponent
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        RiskReserveModalComponent
    ],
    exports: [
        RiskReserveModalComponent
    ]
})
export class RiskReserveModalModule { }
