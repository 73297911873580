
<dm-loading loader *ngIf="loadingComponentData" [loadingText]="'threshold details'" [showLoading]="true">
</dm-loading>
<div *ngIf="syncEACInformation && syncEACInformation.Fee_Type === 'FIF'">
    <section *ngIf="syncEACInformation" class="open-threshold-dailog">
        <div class="dm-modal__close-btn">
            <button type="button" id="closeModalv2" [title]="Close" class="close set-focus" (click)="closeModal()">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </div>
        <div class="open-threshold-dailog__header modal-header modal-noborder" *ngIf="!isLoading">
            <div class="display-grid dm-p-t-20">
                <h3 class="modal-title">
                    Threshold Exceeded
                </h3>
            </div>
        </div>
        <section class="section-wrapper font-caption-alt dm-p-b-12">
            <p>
                Changes to Forecast for Fixed Fee projects cannot be synced to CFP if impacts to TPH and/or Cost exceed
                established thresholds. Please return and modify your changes or initiate a Financial Change Request.<a
                    href="https://aka.ms/pjm-job-aid/fvr" target="_blank" aria-label="open link in new tab"
                    class="dm-link dm-flex-horizontal-align-center dm-p-l-5" role="link" title="Learn more">
                    Learn More</a>
            </p>
        </section>
        <section class="modal-body">
            <section class="modal-data">
                <section role="grid">
                    <div role="rowgroup" class="dm-grid-view-table">
                        <div role="row" class="dm-grid-view-table__headerRow">
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">

                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">

                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                CFP Variance to Delivery Baseline
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                EAC Variance to Delivery Baseline
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" class="dm-grid-view-table">
                        <div role="row" class="dm-grid-view-table__row">
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Comparison
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Threshold
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Value
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Status
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Value
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Status
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" class="dm-grid-view-table">
                        <div class="dm-grid-view-table__body">
                            <div role="row" class="dm-grid-view-table__labelRow project-wrapper">
                                <div class="name-grid text-capitilize dm-p-12" role="gridcell">
                                    <span>Tailored Consulting </span>
                                    <span class="contract-type dm-m-l-8 dm-p-x-10-y-5"
                                        style="background-color: rgb(0, 125, 104); color: white;">FF</span>
                                </div>
                            </div>
                            <div role="row" class="dm-grid-view-table__row">
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize">
                                    TPH Variance
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.TPH_Variance_Threshold}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.TPH_CFP_Variance_To_DB}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8"
                                    [ngClass]="getStatusColorClass(syncEACInformation.TPH_Status)">
                                    <i class="icon dm-m-r-4"
                                        [ngClass]="getStatusIconClass(syncEACInformation.TPH_Status)"></i>
                                    {{syncEACInformation.TPH_Status }}
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.TPH_EAC_Variance_To_DB}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8"
                                    [ngClass]="getStatusColorClass(syncEACInformation.TPH_EAC_Variance_To_DB_Status)">
                                    <i class="icon dm-m-r-4"
                                        [ngClass]="getStatusIconClass(syncEACInformation.TPH_EAC_Variance_To_DB_Status)"></i>
                                    {{ syncEACInformation.TPH_EAC_Variance_To_DB_Status }}
                                </div>
                            </div>
                            <div role="row" class="dm-grid-view-table__row">
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize">
                                    Cost Variance
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.Cost_Variance_Threshold}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.Cost_CFP_Variance_To_DB}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8"
                                    [ngClass]="getStatusColorClass(syncEACInformation.Cost_Status)">
                                    <i class="icon dm-m-r-4"
                                        [ngClass]="getStatusIconClass(syncEACInformation.Cost_Status)"></i>
                                    {{ syncEACInformation.Cost_Status }}
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.Cost_EAC_Variance_To_DB}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8"
                                    [ngClass]="getStatusColorClass(syncEACInformation.Cost_EAC_Variance_To_DB_Status)">
                                    <i class="icon dm-m-r-4"
                                        [ngClass]="getStatusIconClass(syncEACInformation.Cost_EAC_Variance_To_DB_Status)"></i>
                                    {{ syncEACInformation.Cost_EAC_Variance_To_DB_Status }}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </section>

        <div class="open-threshold-dailog__footer modal-footer">
            <button type="button" [attr.aria-label]="Cancel" id="cancel" class="fxp-btn fxp-btn--secondary dm-btn"
                (click)="handleFIFThresholdCancel()" *ngIf="!isLoading">
                Cancel
            </button>
            <button type="button" [attr.aria-label]="ContinueToSync" id="continuetosync"
                class="fxp-btn fxp-btn--primary dm-btn" (click)="handleFCRClick()">
                Initiate Financial Change Request
            </button>
        </div>
    </section>
</div>

<div *ngIf="syncEACInformation && syncEACInformation.Fee_Type === 'TNM'">

    <section *ngIf="syncEACInformation" class="open-threshold-dailog">
        <div class="dm-modal__close-btn">
            <button type="button" id="closeModalv2" [title]="Close" class="close set-focus"
                (click)="closeModal()">
                <span aria-hidden="true" class="icon icon-close"></span>
            </button>
        </div>
        <div class="open-threshold-dailog__header modal-header modal-noborder" *ngIf="!isLoading">
            <div class="display-grid dm-p-t-20">
                <h3 class="modal-title">
                    Threshold Exceeded
                </h3>
            </div>
        </div>
        <section class="section-wrapper font-caption-alt dm-p-8">
            <p>
                Changes to Forecast for T&M projects can be synced to CFP even if impact to Revenue exceed established
                thresholds. A Warning will be generated to indicate impact to T&M planned revenue. Please check the
                Contract and initiate an amendment in CompassOne if necessary. Refer to <a
                    href="https://aka.ms/pjm-job-aid/add-amendment" target="_blank" aria-label="open link in new tab"
                    class="dm-link dm-flex-horizontal-align-center dm-p-l-5" role="link"
                    title="Learn more add amendment">
                    Add Amendment</a> job aid.
            </p>
        </section>
        <section class="modal-body">
            <section class="modal-data">
                <section role="grid">
                    <div role="rowgroup" class="dm-grid-view-table">
                        <div role="row" class="dm-grid-view-table__headerRow">
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">

                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">

                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                CFP Variance to Delivery Baseline
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                EAC Variance to Delivery Baseline
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" class="dm-grid-view-table">
                        <div role="row" class="dm-grid-view-table__row">
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Comparison
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Threshold
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Value
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Status
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Value
                            </div>
                            <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                Status
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" class="dm-grid-view-table">
                        <div class="dm-grid-view-table__body">
                            <div role="row" class="dm-grid-view-table__labelRow project-wrapper">
                                <div class="name-grid text-capitilize dm-p-12" role="gridcell">
                                    <span>Tailored Consulting </span>
                                    <span class="contract-type dm-m-l-8 dm-p-x-10-y-5"
                                        style="background-color: rgb(26, 48, 135); color: white;">T&M</span>
                                </div>
                            </div>
                            <div role="row" class="dm-grid-view-table__row">
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 text-capitilize">
                                    Revenue Variance
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.Revenue_Variance_Threshold}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.CFP_Variance_To_DB}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8"
                                    [ngClass]="getStatusColorClass(syncEACInformation.Revenue_Status)">
                                    <i class="icon dm-m-r-4"
                                        [ngClass]="getStatusIconClass(syncEACInformation.Revenue_Status)"></i>
                                    {{ syncEACInformation.Revenue_Status }}
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span>{{syncEACInformation.EAC_Variance_To_DB}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8"
                                    [ngClass]="getStatusColorClass(syncEACInformation.EAC_Variance_To_DB_Status)">
                                    <i class="icon dm-m-r-4"
                                        [ngClass]="getStatusIconClass(syncEACInformation.EAC_Variance_To_DB_Status)"></i>
                                    {{ syncEACInformation.EAC_Variance_To_DB_Status }}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </section>
        <div class="open-threshold-dailog__footer modal-footer">
            <button type="button" [attr.aria-label]="Cancel" id="cancel" class="fxp-btn fxp-btn--secondary dm-btn"
                (click)="handleTNMThresholdCancel()" *ngIf="!isLoading">
                Cancel
            </button>
            <button type="button" [attr.aria-label]="ContinueToSync" id="continuetosync"
                class="fxp-btn fxp-btn--primary dm-btn" (click)="handleContinueToSync()">
                Continue To Sync
            </button>
        </div>
    </section>
</div>