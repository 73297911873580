import { Inject, Injectable } from "@angular/core";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { APIConstants, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { IWeeklyForecastRecommendation } from "./contracts/forecast-recommendation.contracts";
import { DataService } from "./data.service";
import { DMLoggerService } from "./dmlogger.service";
import { SharedFunctionsService } from "./sharedfunctions.service";

@Injectable()
export class ForecastRecommendationService extends DmServiceAbstract {
    private forecastRecommendationBaseUri: string;

    public constructor( @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DataService) private dataService: DataService) {
        super(dmLogger, Services.ForecastRecommendationService );
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    /**
     * Initialize the change request service with the URIs and Subscription Keys
     */
    public initializeConfig(): void {
        this.forecastRecommendationBaseUri = this.configManagerService.getValue<string>("forecastRecommendationBaseUri");
    }

    /**
     * Gets forecast recommendation data by a given engagement and project ID.
     *
     * @param {string} engagementId
     * @param {string} projectId
     * @returns {Promise<IWeeklyForecastRecommendation[]>}
     * @memberof ForecastRecommendationService
     */
    public getForecastRecommendationsByProjectId(projectId: string, engagementId?: string): Promise<IWeeklyForecastRecommendation[]> {
        if (!engagementId) {
            engagementId = this.sharedFunctionsService.getEngagementIdFromProjectId(projectId);
        }

        const url = `${this.forecastRecommendationBaseUri}/PJMForecastEngagementProject?engagementId=${engagementId}&projectId=${projectId}`;
        return this.dataService.getData(url, undefined, APIConstants.GetProjectForecastRecommendations);
    }

    /**
     * Gets forecast recommendation data by a given engagement ID.
     *
     * @param {string} engagementId
     * @returns {Promise<IWeeklyForecastRecommendation[]>}
     * @memberof ForecastRecommendationService
     */
    public getForecastRecommendationsByEngagementId(engagementId: string): Promise<IWeeklyForecastRecommendation[]> {
        const url = `${this.forecastRecommendationBaseUri}/PJMForecastEngagement?engagementId=${engagementId}`;
        return this.dataService.getData(url, "", APIConstants.GetEngagementForecastRecommendations);
    }

}