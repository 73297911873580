import { Injectable } from "@angular/core";
import { Engine, Fact } from "json-rules-engine";
import { IAssignment } from "./contracts/staffing-action.service.contract";
import { IEngineRuleVerificationResponse, IRule } from "./contracts/rule-engine.service.contract";

@Injectable()
export class RuleEngineService {
    private engine: Engine;
    private rules: IRule[] = [];

    /**
     * Adding rule to the engine
     *
     * @param {IRule[]} ruleList
     * @memberof RuleEngineService
     */
    public addRules(ruleList: IRule[]): void {
        this.rules = ruleList;
        this.engine = new Engine();
        this.rules.forEach((element) => {
            this.engine.addRule(element.value);
        });
    }

    /**
     * Run rules against given Input
     *
     * @param {IAssignment[]} assignmentList
     * @returns {Promise<IEngineRuleVerificationResponse>}
     * @memberof RuleEngineService
     */
    public async runRules(assignmentList: IAssignment[]): Promise<IEngineRuleVerificationResponse> {
        let nonValidAssignmentCount = 0;
        let inputValidationMessage = "";

        const response: IEngineRuleVerificationResponse = {
            assignmentIds: [],
            validationMessage: "",
            isActionable: false
        };

        const total = assignmentList.length;
        const promiseArray = [];      
        assignmentList.forEach((item) => {
            this.addFacts(item);
            promiseArray.push(new Promise((resolve) => {
                this.engine
                    .run()
                    .then((results) => {
                        if (results.length) {
                            nonValidAssignmentCount++;
                            if (inputValidationMessage.length === 0) {
                                inputValidationMessage = "[" + item.assignmentId.toString() + "] assignment " + results.map((result) => result.params.message);
                            } else {
                                inputValidationMessage = inputValidationMessage + "; " + "[" + item.assignmentId.toString() + "] assignment " + results.map((result) => result.params.message);
                            }
                            resolve(inputValidationMessage);
                        } else {
                            resolve(response.assignmentIds.push(item.assignmentId));
                        }
                    });
            })
            );
        });

        await Promise.all(promiseArray);
        response.validationMessage = inputValidationMessage;
        response.isActionable = true;
        if (nonValidAssignmentCount > 0) {
            response.isActionable = false;
            this.engine = null;
        }
        this.engine = null;
        return response;
    }

    /**
     * Add facts to the engine
     * @param item 
     */
    private addFacts(item: IAssignment): void {
        this.rules.forEach((i) => {
            const facts = new Fact(i.name, item);
            this.engine.addFact(facts);
        });
    }
}
