import { Component, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { Components, FinancialType } from "../../common/application.constants";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { IEntityFinancials } from "../financial-mgmt/financial.model";
import { LoadFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.action";
import { FinancialService } from "../../common/services/financial.service";
import { IState } from "../../store/reducers";
import { getEntireFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../tiles/dm-tile/dm-tile.component";

const PLAN_CATEGORIES = ["margin", "labor", "cost", "revenue"];
export interface IPlan {
    category: string;
    contractBaseline: number;
    deliveryBaseline: number;
    currentFinancialPlan: number;
    actuals: number;
    forecast: number;
    eac: number;
    eacVarianceToDeliveryBaseline: number;
}

@Component({
    selector: "dm-plan-forecast-v2",
    templateUrl: "./plan-forecast-v2.html",
    styleUrls: ["./plan-forecast-v2.scss"]
})
export class PlanForecastV2Component extends DmComponentAbstract {
    public planDetails: IPlan[] = [];
    public wbsId: string;
    public planCurrency: string;
    public loadingText: string;
    public tileContent: ITile;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FinancialService) private financialService: FinancialService
    ) {
        super(dmLogger, Components.PlanForecastV2, [
            { component: Components.PlanForecastDetails, isCritical: true }
        ]);
    }

    public ngOnInit(): void {
        this.tileContent = {
            title: "Plan & Forecast"
        };
        this.loadingText = "Plan and Forecast Details";
        this.wbsId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        this.getPlanDetails();
    }

    /**
     * prepare plans to show on the grid.
     */
    public getPlanDetails(): void {
        const entireFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.wbsId));
        entireFinancialDetails$.pipe(untilDestroyed(this))
            .subscribe((financialDetailsState) => {
                if (!financialDetailsState.loaded && !financialDetailsState.loading) {
                    this.store.dispatch(new LoadFinancialDetailsV2(this.wbsId));
                }
                if (financialDetailsState.loaded) {
                    const financialDetailsResponse: IEntityFinancials = financialDetailsState.financialDetails;
                    if (financialDetailsResponse && financialDetailsResponse.financialSummary) {
                        const contractBaseline = this.financialService.getFinancialDetailsFromParentForV2Object(financialDetailsResponse, FinancialType.ContractBaseline);
                        const deliveryBaseline = this.financialService.getFinancialDetailsFromParentForV2Object(financialDetailsResponse, FinancialType.DeliveryBaseline);
                        const currentFinancialPlan = this.financialService.getFinancialDetailsFromParentForV2Object(financialDetailsResponse, FinancialType.CurrentFinancialPlan);
                        this.planCurrency = contractBaseline.planCurrency;
                        this.planDetails = this.planDetails.length ? this.planDetails : [];
                        if (!this.planDetails.length) {
                            for (const category of PLAN_CATEGORIES) {
                                this.planDetails.push({
                                    category,
                                    contractBaseline: contractBaseline ? contractBaseline[category] : null,
                                    deliveryBaseline: deliveryBaseline ? deliveryBaseline[category] : null,
                                    currentFinancialPlan: currentFinancialPlan ? currentFinancialPlan[category] : null,
                                    actuals: null,
                                    forecast: null,
                                    eac: null,
                                    eacVarianceToDeliveryBaseline: null
                                });
                            }
                        }
                    }
                }
                this.refreshOnItemInvalidation(financialDetailsState);
                this.setLoadersBasedOnItemState(financialDetailsState);
                this.setErrorsBasedOnItemState(financialDetailsState);
            });
    }

}
