import { APIConstants, ContactType, ContactSortOrderPriority, Services } from "../application.constants";
import { ConfigManagerService } from "./configmanager.service";
import { Injectable, Inject } from "@angular/core";
import { IContactsResponse, IContact, IContactRole, IUpdateCsatContactRequest, ICsatContactType, IDeleteCsatContactRequest, ICsatContactLanguage} from "./contracts/contacts.contracts";
import { DataService } from "./data.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { DMLoggerService } from "./dmlogger.service";

@Injectable()
export class ContactsService extends DmServiceAbstract {
    private projectServiceBaseUriv2: string;
    private subscriptionKey: string;
    private validCsatContactTypes: ICsatContactType[];
    private validCsatContactLanguages: ICsatContactLanguage[];

    public constructor(
        @Inject(DataService) private dataService: DataService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(dmLogger, Services.ContactService );
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
        });
    }

    public initializeConfig(): void {
        this.projectServiceBaseUriv2 = this.configManagerService.getValue<string>("projectServiceBaseUri") + "v2.0";
        this.subscriptionKey = this.configManagerService.getValue<string>("projectServiceSubscriptionKey");
        this.validCsatContactTypes = this.configManagerService.getValue<ICsatContactType[]>("csatContactTypes");
        this.validCsatContactLanguages = this.configManagerService.getValue<ICsatContactLanguage[]>("csatContactLanguages");
    }

    /**
     * Gets contacts by engagement id as of now project id is not supported.
     *
     * @param {string} wbsId
     * @param {boolean} includeCustomerContacts
     * @returns {Promise<IContactsResponse>}
     * @memberof ContactsService
     */
    public getContactsByWbsId(wbsId: string, includeCustomerContacts: boolean): Promise<IContactsResponse> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/contacts?includeCustomerContacts=${includeCustomerContacts}`;
        return this.dataService.getData(url, this.subscriptionKey, APIConstants.GetContactsByWbsId);
    }

    /**
     * Updates CSAT contact for the sales order i.e engagement.
     *
     * @param {string} wbsId
     * @param {string} newCSATCustId
     * @returns {Promise<any>}
     * @memberof ContactsService
     */
    public updateCSATContact(wbsId: string, updateCsatContactRequest: IUpdateCsatContactRequest): Promise<any> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/contacts/csat`;
        const payload = updateCsatContactRequest;
        return this.dataService.postData(url, this.subscriptionKey, APIConstants.UpdateCSATContact, payload);
    }

    /**
     * Deletes CSAT contact for the sales order i.e engagement.
     *
     * @param {string} wbsId
     * @param {IDeleteCsatContactRequest} existingCsarContactRequest
     * @returns {Promise<any>}
     * @memberof ContactsService
     */
    public deleteCSATContact(wbsId: string, existingCsarContactRequest: IDeleteCsatContactRequest): Promise<any> {
        const url = `${this.projectServiceBaseUriv2}/wbs/${wbsId}/contacts/csat`;
        const payload = existingCsarContactRequest;
        return this.dataService.deleteData(url, this.subscriptionKey, payload);
    }


    /**
     * updates contact type CSAT, primary bill, invoice recipient priority and remove unnecessary characters.
     *  Priority Order: CSAT - 2 , Primary Bill Contact - 1, Invoice recipient - 0 .
     * @param {IContact[]} engagementContacts
     * @memberof ContactsService
     */
    public updateContactsTypeAndPriority(engagementContacts: IContact[]): IContact[] {
        for (const contact of engagementContacts) {
            if (contact && contact.roles && contact.roles.length) {
                contact.priority = -1;                
                contact.roles.forEach((r) => {
                    if (this.isCsatRole(r)) {
                        r.contactType = this.validCsatContactTypes.filter((v) => v.code === r.contactTypeCode)[0].description;
                        contact.priority = ContactSortOrderPriority.CSATContact;    
                    }
                    else if (r.contactType === ContactType.PrimaryBillContact) {
                        contact.priority = contact.priority ? Math.max(contact.priority, ContactSortOrderPriority.PrimaryBillContact) : ContactSortOrderPriority.PrimaryBillContact;
                    }
                    else if (r.contactType === ContactType.InvoiceRecipient) {
                        r.contactType = r.contactType.slice(0, -1); // Strip off excess + from string
                        contact.priority = contact.priority ? Math.max(contact.priority, ContactSortOrderPriority.InvoiceRecipient) : ContactSortOrderPriority.InvoiceRecipient;
                    }
                });
            }
        }
        return engagementContacts;
    }

    /**
     * Check if contact contains any CSAT role
     * @param role role
     * @returns 
     */
    public isCsatContact(contact: IContact): boolean {
        return contact.roles.some((r) => this.isCsatRole(r));
    }

    /**
     * Check if role in contact is CSAT role
     * @param role role
     * @returns 
     */
    public isCsatRole(role: IContactRole): boolean {
        return this.validCsatContactTypes.some((r) => r.code === role.contactTypeCode);
    }
}