<section class="dm-order internal-engagement-financial">
    <section class="row dm-m-b-12">
        <section id="dm-internal-financial-tile"
            class="col-md-12 col-sm-12 col-xs-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-md-6 col-sm-6 col-lg-6 dm-p-imp-0 dm-m-r-15">
                    <dm-wbs-internal-engagement [engagementId]="engagementId">
                    </dm-wbs-internal-engagement>
            </section>
            <section class="col-md-6 col-sm-6 col-lg-6 dm-p-imp-0">
                    <dm-internal-eng-cost-consumption>
                    </dm-internal-eng-cost-consumption>
            </section>
        </section>
        <section class="col-md-12 col-xs-12 col-sm-12 dm-m-b-12">
            <dm-internal-eng-breakdown-structure></dm-internal-eng-breakdown-structure>
        </section>
    </section>
</section>