
export interface IProjectClosureRequest{
    wbsId: string;
    wbsType: string;
    stateFrom: string;
    stateTo: string;
    wbsStartDate: Date;
    wbsEndDate: Date;
    isEtm: boolean;
    runFFSeq: boolean;
    ruleResults?: IRuleResult[];
}

export interface IWbsETMDetails{
    isETM: boolean;
    runFFSeq: boolean;
}

export interface IRule {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    facts: [];
}

export interface IRuleResponse {
    isRulePassed: RuleResultType;
    ruleId: number;
    ruleGroup: RuleGroup;
    ruleName: string;
    ruleType: string;
    ruleDescription: string;
    action: Action;
    isActive: boolean;
    isMandatory: boolean;
    displayAttributes: DisplayAttribute[];
    showMetricsInDetail: boolean;
    evaluatedInEarlyTermination: boolean;
    metricsInDetail: DetailedMetrics[];

}

export interface IRuleMasterData {
    ruleId: number;
    ruleGroup: RuleGroup;
    ruleName: string;
    ruleType: string;
    ruleDescription: string;
    action: Action;
    isActive: boolean;
    isMandatory: boolean;
    displayAttributes: RuleMasterDataDisplayAttribute[];
    showMetricsInDetail: boolean;
    evaluateInEarlyTermination: boolean;
    metricsInDetail: RuleMasterDataDetailedMetrics[];
    propertyName: string;
}

export interface RuleMasterDataDisplayAttribute {
    displayName: string;
    modelAttributeName: string;
}
export interface RuleMasterDataDetailedMetrics {
    modelAttributeName: string;
    displayName: string;
}

export interface IRuleResult extends IRuleResponse {
    isDown: boolean;
    isChecked: boolean;
    isTextBoxVisible?: boolean;
    isInputIconVisible?: boolean;
}

export interface DisplayAttribute {
    displayName: string;
    value: string;
}

export interface DetailedMetrics {
    displayName: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    value: Object[];
}

export interface Action {
    elementType: ElementType;
    elementDisplayMessage: string;
    dropDownValue: string;
    elementValueSelected: string;
    elementValue: string;
}

export enum RuleGroup {
    DECO = "Delivery Complete",
    RECO = "Ready to Close"
}

export enum ElementType {
    CHECKBOX = "CheckBox",
    LABEL = "Label",
    NOT_APPLICABLE = "Not Applicable",
    SELECTION = "Selection",
    DROPDOWN = "DROPDOWN"
}

export enum RuleResultType {
    Passed = 0,
    Failed = 1,
    Error = 2
}