<section class="dm-p-x-12-y-imp-0">
    <section class="row">
        <section class="col-md-12 col-sm-12 col-lg-12 display-inline-flex-imp dm-p-r-imp-30 dm-m-b-12">
            <section class="col-md-6 col-sm-6 col-lg-6 dm-p-imp-0 dm-m-r-15">
                <dm-financial-summary></dm-financial-summary>
            </section>
            <section class="col-md-6 col-sm-6 col-lg-6 dm-p-imp-0">
                <dm-key-indicators></dm-key-indicators>
            </section>
        </section>       
        <section class="col-md-12 col-sm-12 col-xs-12 dm-m-b-12">
            <dm-financial-nav></dm-financial-nav>
        </section>
        <section class="col-md-12 col-sm-12 col-xs-12 dm-m-b-12">
            <dm-financial-plan-approvals
                [isChild]="true"
               
                class="funding-requests"
            >
            </dm-financial-plan-approvals>
        </section>
    </section>
</section>