<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'150px'" [hasNonOverflowContent]="true"
    [isLoading]="isCreatingTeamGroup">
    <dm-loading loader [showLoading]="true" *ngIf="isCreatingTeamGroup">
    </dm-loading>
    <div *ngIf="!isCreatingTeamGroup">
        Are you sure you want to create teams group with team structure?
    </div>
    <div additionalFooterButtons *ngIf="!isCreatingTeamGroup">
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" (click)="createTeam()">
            Create
        </button>
    </div>
</dm-modal-v2>