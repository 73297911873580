export interface IDateProgress {
    startDate: Date;
    endDate: Date;
    today?: Date;
}

export interface IDate {
    type: DateType;
    stepDate: string;
}

export enum DateType {
    StartDate = "Start Date",
    EndDate = "End Date",
    Today = "Today",
}