<section class="risk-reserve-section">
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Hours Impact
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'Hours Impact due to current changes'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Hours Impact due to current changes'">
            </dm-tooltip>
            <span class="font-caption-italic">
                due to current changes
            </span>
        </section>
        <p class="font-caption-alt">
            {{ tphRrSummary?.hoursImpact | dmDisplayDashIfEmptyOrNull}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Hours Impact %
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'(Requested Hours / Delivery Baseline Hours) * 100'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'(Requested Hours / Delivery Baseline Hours) * 100'">
            </dm-tooltip>
            <span class="font-caption-italic">
                variance % from old TPH
            </span>
        </section>
        <p class="font-caption-alt">
            {{tphRrSummary?.hoursImpactPercentDecimal  | percent: '1.2-2' | dmDisplayDashIfEmptyOrNull}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Previously Approved Cost
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'Delivery Baseline Cost - Delivery Baseline RR'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Delivery Baseline Cost - Delivery Baseline RR'">
            </dm-tooltip>
            <span class="font-caption-italic">
                Beyond Contract Baseline Cost excl. RR
            </span>
        </section>
        <p class="font-caption-alt">
            {{tphRrSummary?.previouslyApprovedCost | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Gross Cost Over-run
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'New Planned Cost - Contract Baseline Cost excl. RR'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'New Planned Cost - Contract Baseline Cost excl. RR'">
            </dm-tooltip>
            <span class="font-caption-italic">
                New Planned Cost - Contract Baseline Cost excl. RR
            </span>
        </section>
        <p class="font-caption-alt">
            {{tphRrSummary?.grossCostOverrun  | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Additional Approval Needed
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'left'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'New Planned Cost - Previously Approved Cost'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'New Planned Cost - Previously Approved Cost'">
            </dm-tooltip>
            <span class="font-caption-italic">
                refer below info
            </span>
        </section>
        <p class="font-caption-alt">
            {{tphRrSummary?.additionalApprovalNeeded  | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Cost Impact
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'Cost Impact due to current changes'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Cost Impact due to current changes'">
            </dm-tooltip>
            <span class="font-caption-italic">
                due to current changes
            </span>
        </section>
        <p class="font-caption-alt">
            {{ tphRrSummary?.costImpact | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Contract Baseline Cost
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'Contract Baseline Cost - Contract Baseline RR'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Contract Baseline Cost - Contract Baseline RR'">
            </dm-tooltip>
            <span class="font-caption-italic">
                excluding RR
            </span>
        </section>
        <p class="font-caption-alt">
            {{tphRrSummary?.cbCostExcludingRr | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            New Planned Cost
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'"
                [ngbTooltipContent]="'(Delivery Baseline Cost - Delivery Baseline RR) + Cost Impact'"
                [dmIcon]="'icon icon-info'"
                ariaLabelToolTip="'(Delivery Baseline Cost - Delivery Baseline RR) + Cost Impact'">
            </dm-tooltip>
            <span class="font-caption-italic">
                for all codes
            </span>
        </section>
        <p class="font-caption-alt">
            {{tphRrSummary?.newPlannedCost  | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Gross Cost Over-run %
        </h4>
        <section>
            <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'right'"
                [dmTriggers]="'hover'" [ngbTooltipContent]="'(Gross Cost Over-run / Contract Baseline RR) * 100'"
                [dmIcon]="'icon icon-info'" ariaLabelToolTip="'(Gross Cost Over-run / Contract Baseline RR) * 100'">
            </dm-tooltip>
            <span class="font-caption-italic">
                % of RR
            </span>
        </section>
        <p class="font-caption-alt">
            {{tphRrSummary?.grossCostOverrunPercentDecimal | percent: '1.2-2' | dmDisplayDashIfEmptyOrNull }}
        </p>
    </section>
    <section class="risk-reserve-section__item">
        <h4 class="dm-project-data__title risk-reserve-section__item-content">
            Risk Reserve
        </h4>
        <section class="rr-amounts-section">
            <section>
                <section>
                    <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'left'"
                        [dmTriggers]="'hover'" [ngbTooltipContent]="'Total Contract Baseline Risk Reserve Amount'"
                        [dmIcon]="'icon icon-info'" ariaLabelToolTip="'Total Contract Baseline Risk Reserve Amount'">
                    </dm-tooltip>
                    <span class="font-caption-italic">
                        Total RR
                    </span>
                </section>
                <p class="font-caption-alt">
                    {{tphRrSummary?.totalRiskReserve  | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
                </p>
            </section>
            <section>
                <section>
                    <dm-tooltip role="tooltip" class="custom-tooltip dm-tooltipinline" [dmPlacement]="'left'"
                        [dmTriggers]="'hover'"
                        [ngbTooltipContent]="'Risk Reserve Amount approved via Financial Change Request'"
                        [dmIcon]="'icon icon-info'"
                        ariaLabelToolTip="'Risk Reserve Amount approved via Financial Change Request'">
                    </dm-tooltip>
                    <span class="font-caption-italic">
                        Approved RR
                    </span>
                </section>
                <p class="font-caption-alt">
                    {{tphRrSummary?.approvedRiskReserve  | dmDisplayCurrencyOrDash: tphRrSummary?.planCurrency}}
                </p>
            </section>
        </section>
    </section>
</section>