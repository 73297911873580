import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { EngagementDetailComponent } from "./engagement-detail.component";
import { PlanForecastModule } from "../plan-forecast/planForecast.module";
import { DmSliderModule } from "../tiles/dm-slider/dm-slider.module";

@NgModule({
    declarations: [
        EngagementDetailComponent
    ],
    imports: [
        CommonModule,
        UIRouterModule,
        PlanForecastModule,
        DmSliderModule
    ],
    providers: [],
    entryComponents: [
        EngagementDetailComponent
    ],
    exports: [
        EngagementDetailComponent
    ]
})
export class EngagementDetailModule { }
