import { Action } from "@ngrx/store";
import { IWbsStructure, IProject } from "./../../common/services/contracts/wbsStructures.contracts";

export enum WbsStructuresActionTypes {
    LOAD_WBS_STRUCTURES = "[WbsStructures] Load",
    LOAD_WBS_STRUCTURES_SUCCESS = "[WbsStructures] Load Success",
    LOAD_WBS_STRUCTURES_FAIL = "[WbsStructures] Load Fail",
    INVALIDATE_WBS_STRUCTURES = "[WbsStructures] Invalidate"
}

export class LoadWbsStructures implements Action {
    public readonly type = WbsStructuresActionTypes.LOAD_WBS_STRUCTURES;
    public constructor(public wbsId: string) { }
}

export class LoadWbsStructuresSuccess implements Action {
    public readonly type = WbsStructuresActionTypes.LOAD_WBS_STRUCTURES_SUCCESS;
    public constructor(public wbsId: string, public wbsStructures: IWbsStructure | IProject) { }
}

export class LoadWbsStructuresFail implements Action {
    public readonly type = WbsStructuresActionTypes.LOAD_WBS_STRUCTURES_FAIL;
    public constructor(public wbsId: string, public errorMessage: string) { }
}

export class InvalidateWbsStructures implements Action {
    public readonly type = WbsStructuresActionTypes.INVALIDATE_WBS_STRUCTURES;
    public constructor(public wbsId: string) { }
}

// 3. export the union creators
export type WbsStructuresActions =
    | LoadWbsStructures
    | LoadWbsStructuresSuccess
    | LoadWbsStructuresFail
    | InvalidateWbsStructures;