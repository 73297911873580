import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { CreateTeamsGroupModalComponent } from "./create-teams-group.component";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalV2Module } from "../../../modals/dm-modal-v2/dm-modal-v2.module";

@NgModule({
    declarations: [
        CreateTeamsGroupModalComponent,
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        DmModalV2Module
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        CreateTeamsGroupModalComponent,
    ],
    exports: [
        CreateTeamsGroupModalComponent,
    ]
})
export class CreateTeamsGroupModalModule { }