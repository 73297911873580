import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FilterModel } from "../project-filter.model";
import { Components } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";

@Component({
    templateUrl: "./project-filter-modal.html",
    styleUrls: ["../project-filter.scss"]
})
export class FilterModalComponent extends DmModalAbstract {
    @Input() public selectedProject: FilterModel;
    @Input() public selectedService: FilterModel;
    @Input() public selectedTask: FilterModel;
    @Input() public items: FilterModel[];
    @Input() public isProjectContext: boolean;
    public placeholder: FilterModel = new FilterModel(undefined, undefined);

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService
    ) {
        super(activeModal, dmLogger, Components.ProjectFilterModal);
    }

    public ngOnInit(): void {
        this.endComponentLoad();
    }

    public applyFilterChanges(): void {
        // todo
    }

    public onSelectedProjectChanged(): void {
        this.selectedService = this.placeholder;
        this.selectedTask = this.placeholder;
    }

    public onSelectedServiceChanged(): void {
        this.selectedTask = this.placeholder;
    }

}
