import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ChangeRequestService } from "../../common/services/change-request.service";
import { IResourceDetailsResponse } from "../../common/services/contracts/changerequest.contract";
import * as engagementResourceDetailsActions from "./engagement-resource-details.action";

@Injectable()
export class EngagementResourceDetailsEffects {

    @Effect()
    public loadResourceDetails$ = this.actions$
        .ofType(engagementResourceDetailsActions.EngagementResourceDetailsActionTypes.LOAD_ENGAGEMENT_RESOURCE_DETAILS)
        .pipe(
            switchMap((action: engagementResourceDetailsActions.LoadEngagementResourceDetails) => fromPromise(
                this.changeRequestService.getResourceDetailsByEngagementId(action.engagementId))
                .pipe(
                    map((response: IResourceDetailsResponse) => new engagementResourceDetailsActions.LoadEngagementResourceDetailsSuccess(action.engagementId, response)),
                    catchError((error) => of(new engagementResourceDetailsActions.LoadEngagementResourceDetailsFail(action.engagementId, error)))
                )
            ));

    public constructor(
        private actions$: Actions,
        @Inject(ChangeRequestService) public changeRequestService: ChangeRequestService,
    ) { }
}