import { Component, Input, Inject, Injector, forwardRef } from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Components, LogEventConstants, SourceConstants, DmFxpBannerMessages, AccessibilityConstants } from "../../../../common/application.constants";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { IContact, IContactsResponse, ICsatContact, ICsatContactType, IUpdateCsatContactRequest } from "../../../../common/services/contracts/contacts.contracts";
import { ContactsService } from "../../../../common/services/contacts.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { InvalidateContacts } from "../../../../store/contacts/contacts.action";
import { CreateCSATContactModalComponent } from "../create-csat-contact/create-csat-contact.component";
import { FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { CsatContactMode } from "./update-csat-contact.contract";

/**
 * Updates and adds existing csat contacts to the engagement.
 * To create a new csat contact that does not yet exist for an engagement, use the 'create-csat-contact' component.
 *
 * @export
 * @class UpdateCSATContactModalComponent
 * @extends {DmModalAbstract}
 */
@Component({
    selector: "update-csat-contact",
    templateUrl: "./update-csat-contact.html",
    styleUrls: ["./update-csat-contact.scss"]
})
export class UpdateCSATContactModalComponent extends DmModalAbstract {

    @Input() public wbsId: string;
    @Input() public allowedCsatContactTypes: ICsatContactType[];
    @Input() public mode: CsatContactMode;
    @Input() public csatContactToBeEdited: ICsatContact;
    public selectedContact: IContact;
    public selectedContactsList: ICsatContact[];
    public selectedContactType: ICsatContactType;
    public contactDetailsList: IContactsResponse;
    public filteredContactDetailList: IContact[];
    public filteredEngagementContactDetailList: IContact[];
    public tempFilteredContactDetailList: IContact[];
    public filteredCsatContactTypeList: ICsatContactType[];
    public loadingText: string;
    public accessibilityConstants = AccessibilityConstants;
    public csatContactModalTitle: string;
    public csatContactModlaInfo: string;
    public sameContactTypeContactAlreadySelectedErrorMessage: string;
    public sameContactTypeContactAlreadySelected: boolean;
    public Executive_sp = ["V!", "V(", "V)"];
    public BDM = ["V#", "V-", "V<"];
    public Customer_proj_mgr = ["V$", "V@", "V^", "V&", "V*"];
    public Tech_dm = ["VG", "V>", "V+"];
    private FXP_CONSTANTS = FxpConstants;   
    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) public activeModal: NgbActiveModal,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService,
        @Inject(ContactsService) private contactsService: ContactsService,
        @Inject(Injector) private injector: Injector,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(Store) private store: Store<IState>
    ) {
        super(activeModal, dmLogger, Components.UpdateCSATContactModal);
    }

    public ngOnInit(): void {
        this.loadingText = `Getting Contacts for ${this.wbsId}`;
        this.selectedContactsList = [];
        this.isComponentLoading = true;
        this.sameContactTypeContactAlreadySelected = false;
        this.contactsService.getContactsByWbsId(this.wbsId, true).then((contactsResponse: IContactsResponse) => {
            this.isComponentLoading = false;
            this.contactDetailsList = contactsResponse;
            if (this.mode === CsatContactMode.add) {
                this.filteredContactDetailList = contactsResponse.customerContacts;
                this.filteredEngagementContactDetailList = contactsResponse.engagementContacts;
                this.tempFilteredContactDetailList = contactsResponse.customerContacts;
            }
            else {
                this.filteredContactDetailList = contactsResponse.customerContacts.filter((c) => c.roles[0].customerId !== this.csatContactToBeEdited.contact.roles[0].customerId);
                this.filteredEngagementContactDetailList = contactsResponse.engagementContacts;
                this.tempFilteredContactDetailList = contactsResponse.customerContacts;

                if ( this.Executive_sp.includes(this.csatContactToBeEdited.contact.roles[0].contactTypeCode))
                {            
                    this.uniqueContactList(this.csatContactToBeEdited.contact.roles[0].contactTypeCode, this.Executive_sp);                
                }
                else if ( this.BDM.includes(this.csatContactToBeEdited.contact.roles[0].contactTypeCode))
                {            
                    this.uniqueContactList(this.csatContactToBeEdited.contact.roles[0].contactTypeCode, this.BDM);                
                }
                else if ( this.Customer_proj_mgr.includes(this.csatContactToBeEdited.contact.roles[0].contactTypeCode))
                {            
                    this.uniqueContactList(this.csatContactToBeEdited.contact.roles[0].contactTypeCode, this.Customer_proj_mgr);                
                }        
                else if ( this.Tech_dm.includes(this.csatContactToBeEdited.contact.roles[0].contactTypeCode))
                {            
                    this.uniqueContactList(this.csatContactToBeEdited.contact.roles[0].contactTypeCode, this.Tech_dm);                
                }
            }
            this.filteredContactDetailList = this.filteredContactDetailList.sort((left, right) => left.name > right.name ? 1 : -1);
            this.filteredEngagementContactDetailList = this.filteredEngagementContactDetailList.sort((left, right) => left.name > right.name ? 1 : -1);
            this.tempFilteredContactDetailList = this.tempFilteredContactDetailList.sort((left, right) => left.name > right.name ? 1 : -1);
        });
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.selectedContactsList = [];
        this.filteredCsatContactTypeList = this.allowedCsatContactTypes;
        // if (this.filteredCsatContactTypeList.length === 1) {
        //    this.selectedContactType = this.filteredCsatContactTypeList[0];
        // }
        if (this.mode === CsatContactMode.add) {
            this.csatContactModalTitle = "Add CSAT Contact";
            this.csatContactModlaInfo = "Only CSAT contacts can be added. You can add maximum of 3 Business Decision Makers, 3 Executive Sponsors, 5 Customer Proj Mgrs and 3 Tech Decision Makers.";
        }
        else {
            this.csatContactModalTitle = "Update CSAT Contact";
            this.csatContactModlaInfo = "Only CSAT contact can be updated.";
            this.selectedContactType = this.csatContactToBeEdited.type;
        }
        this.sameContactTypeContactAlreadySelectedErrorMessage = "This customer contact is already associated with the selected customer contact type. Please select a different one.";
    }

    /**
     * Should the contact selection be disabled. This is based on the selected contact type.
     *
     * @returns {boolean} True if disabled, false if enabled
     * @memberof UpdateCSATContactModalComponent
     */
    public shouldDisableContactSelection(): boolean {
        return this.selectedContactType ? false : true;
    }

    /**
     * Selects the CSAT contact
     *
     * @param {IContact} contact
     * @memberof UpdateCSATContactModalComponent
     */
    public contactSelected(contact: IContact): void {
        this.sameContactTypeContactAlreadySelected = false;
        this.selectedContact = contact;
        if (this.mode === CsatContactMode.edit) {
            this.selectedContactsList = [{ contact, type: this.selectedContactType }];
            this.filteredContactDetailList = this.filteredContactDetailList.filter((c) => c.roles[0].customerId !== contact.roles[0].customerId);
        }
        else {                
            if (this.selectedContactsList.length === 0)
            {
                this.selectedContactsList.push({ contact, type: this.selectedContactType });
            }
            else
            {            
                const cId = contact.roles[0].customerId;
                        
                if (this.Customer_proj_mgr.includes(this.selectedContactType.code) && this.selectedContactsList.filter((x) => x.contact.roles[0].customerId === cId && this.Customer_proj_mgr.includes(x.type.code)).length === 0)
                {
                    this.selectedContactsList.push({ contact, type: this.selectedContactType });
                }
                else if (this.BDM.includes(this.selectedContactType.code) && this.selectedContactsList.filter((x) => x.contact.roles[0].customerId === cId && this.BDM.includes(x.type.code)).length === 0)
                {
                    this.selectedContactsList.push({ contact, type: this.selectedContactType });
                }
                else if (this.Executive_sp.includes(this.selectedContactType.code) && this.selectedContactsList.filter((x) => x.contact.roles[0].customerId === cId && this.Executive_sp.includes(x.type.code)).length === 0)
                {
                    this.selectedContactsList.push({ contact, type: this.selectedContactType });
                }
                else if (this.Tech_dm.includes(this.selectedContactType.code) && this.selectedContactsList.filter((x) => x.contact.roles[0].customerId === cId && this.Tech_dm.includes(x.type.code)).length === 0)
                {
                    this.selectedContactsList.push({ contact, type: this.selectedContactType });
                }
                else 
                {
                    this.sameContactTypeContactAlreadySelected = true;
                }
            }

            this.selectedContactType = null;
            this.selectedContact = null;
            this.filteredCsatContactTypeList = this.getFilteredCsatContactTypeList();
        }
    }

    /**
     * Selects the given contact type.
     *
     * @param {ICsatContactType} contactType
     * @memberof UpdateCSATContactModalComponent
     */
    public contactTypeSelected(contactType: ICsatContactType): void {        
        this.filteredContactDetailList = this.tempFilteredContactDetailList;
        this.selectedContactType = contactType;       
        if ( this.Executive_sp.includes(contactType.code))
        {            
            this.uniqueContactList(contactType.code, this.Executive_sp);                
        }
        else if ( this.BDM.includes(contactType.code))
        {            
            this.uniqueContactList(contactType.code, this.BDM);                
        }
        else if ( this.Customer_proj_mgr.includes(contactType.code))
        {            
            this.uniqueContactList(contactType.code, this.Customer_proj_mgr);                
        }        
        else if ( this.Tech_dm.includes(contactType.code))
        {            
            this.uniqueContactList(contactType.code, this.Tech_dm);                
        }
        
    }
    
    /**
     * Unique the contact from the filered list
     * @param code for check existing in contact list
     * @groupArray for check  contact weather in other contact type  of same group
     */
    public uniqueContactList(code: string, groupArray: string[]): void {       
        const dataList = this.filteredEngagementContactDetailList;

        for (const dtList of dataList) {
            for (const roleList of dtList.roles)
            {
                if (groupArray.includes(roleList.contactTypeCode.toString())) {
                    this.filteredContactDetailList = this.filteredContactDetailList.filter((c) => c.roles[0].customerId !== roleList.customerId);                                   
                }
            }                
        }               
    }



    /**
     * Updates CSAT contact of the engagement.
     *
     * @memberof UpdateCSATContactModalComponent
     */
    public updateCSATContact(): void {
        this.isComponentLoading = true;
        this.loadingText = "Updating CSAT Contact";
        if (this.selectedContactsList && this.selectedContactsList.length) {
            const updateCsatContactRequest: IUpdateCsatContactRequest = {
                updateCsatContact: []
            };
            this.selectedContactsList.forEach((c) => {
                if (c.contact.roles && c.contact.roles.length) {
                    updateCsatContactRequest.updateCsatContact.push({
                        newCsatCustomerId: c.contact.roles[0].customerId,
                        existingCsatCustomerId: this.csatContactToBeEdited != null ? this.csatContactToBeEdited.contact.roles[0].customerId : null,
                        csatContactTypeCode: c.type.code
                    });
                }
            });

            this.contactsService.updateCSATContact(this.wbsId, updateCsatContactRequest).then(() => {
                this.isComponentLoading = false;
                this.store.dispatch(new InvalidateContacts(this.wbsId));
                this.fxpMessageService.addMessage(DmFxpBannerMessages.UpdateCSATContactSuccess, this.FXP_CONSTANTS.messageType.success, false);
                this.closeModal();
            }).catch((error) => {
                this.fxpMessageService.addMessage(DmFxpBannerMessages.UpdateCSATContactFailure, this.FXP_CONSTANTS.messageType.error, false);
                this.logError(SourceConstants.Method.UpdateCSATContact, error, DmFxpBannerMessages.UpdateCSATContactFailure, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.closeModal();
            });
        }
    }

    /**
     * Getter for the CSAT enum for UI
     */
    public get getModeEnum(): typeof CsatContactMode {
        return CsatContactMode;
    }

    /**
     * Opens the Create new contact modal
     */
    public openInitiateNewCSATContactRequestModal(): void {
        this.closeModal(); /* close update modal to open the initiate request modal */
        this.dmLogger.logEvent(SourceConstants.Component.ContactsPage, SourceConstants.Method.OpenInitiateNewCSATContactRequestModal, LogEventConstants.CreateCSATContactModal);
        const modalRef: NgbModalRef = this.modalService.open(CreateCSATContactModalComponent, {
            backdrop: "static",
            windowClass: "in active manage-wbs-modal create-contacts-modal",
            keyboard: true,
            centered: true,
            injector: this.injector
        });
        modalRef.componentInstance.engagementId = this.wbsId;
        modalRef.result.then(() => {
            this.sharedFunctionsService.focus("addCsatContactBtn", true);
        });
    }

    /**
     * Move focus to Next element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseUpdateButton);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.UpdateCSAT);
        }
    }

    /**
     * Remove the contact from the selected list
     * @param contact contact to be removed     
     */
    public removeFromSelectedContacts(contact: ICsatContact): void {
        this.selectedContactsList = this.selectedContactsList.filter((c) => c !== contact);
        this.filteredContactDetailList = this.getFilteredContactList();
        this.filteredCsatContactTypeList = this.getFilteredCsatContactTypeList();
    }

    /**
     * Returns if CSAT contact type selection should be disabled
     * @returns boolean
     */
    public shouldDisableContactTypeSelection(): boolean {
        return this.mode === CsatContactMode.edit || this.filteredCsatContactTypeList.length < 1;
    }

    /**
     * Returns a filtered contact list
     * @returns IContact[]
     */
    private getFilteredContactList(): IContact[] {
        // Following contains the logic for implementing a filtered contact list. Disabling this for now, but keeping it as it may be required in the future        
        // this.contactDetailsList.customerContacts.forEach((contact) => {
        //     if (!this.selectedContactsList.some((x) => x.contact === contact)) {
        //         filteredContactList.push(contact);
        //     }
        // });
        this.filteredContactDetailList.sort((left, right) => left.name > right.name ? 1 : -1);
        const filteredContactList = this.filteredContactDetailList.sort((left, right) => left.name > right.name ? 1 : -1);
        return filteredContactList;
    }

    /**
     * Gets filtered ICOntactType to display in the UI
     * @returns IContactType[]
     */
    private getFilteredCsatContactTypeList(): ICsatContactType[] {
        const filteredCsatContactTypeList: ICsatContactType[] = [];
        this.allowedCsatContactTypes.forEach((contactType) => {
            if (!this.selectedContactsList.some((x) => x.type.code === contactType.code)) {
                filteredCsatContactTypeList.push(contactType);
            }
        });
        this.filteredCsatContactTypeList.sort((left, right) => left.description > right.description ? 1 : -1);
        return filteredCsatContactTypeList;
    }
}
