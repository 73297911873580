import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { EngagementDetailService } from "../../../common/services/engagement-detail.service";
import { NavigationService } from "../../../common/services/navigation.service";
import { ProjectDetailService } from "../../../common/services/project-detail.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { TabNavigationComponent } from "./tab-navigation.component";

@NgModule({
    declarations: [
        TabNavigationComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
    ],
    providers: [
        DMLoggerService,
        EngagementDetailService,
        NavigationService,
        ProjectDetailService,
        SharedFunctionsService
    ],
    entryComponents: [
        TabNavigationComponent
    ],
    exports: [
        TabNavigationComponent
    ]
})
export class TabNavigationModule { }
