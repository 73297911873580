
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";

import { CommonComponentsModule } from "../entry/common-components.module";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { NonProcuredMaterialsComponent } from "./non-procured-materials.component";
import { NpcActualsService } from "../../common/services/npcActuals.service";
import { ProjectFilterModule } from "../tiles/project-filter/project-filter.module";
import { ProjectService } from "../../common/services/project.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";

@NgModule({
    declarations: [
        NonProcuredMaterialsComponent,
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        ProjectFilterModule,
        UIRouterModule,
        NgbModule
    ],
    providers: [
        DMAuthorizationService,
        DMLoggerService,
        NpcActualsService,
        ProjectService,
        SharedFunctionsService,
        StoreDispatchService
    ],
    entryComponents: [
        NonProcuredMaterialsComponent
    ],
    exports: [
        NonProcuredMaterialsComponent
    ]
})
export class NonProcuredMaterialsModule { }
