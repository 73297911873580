/* eslint-disable no-console */
import { Inject, Input, Component, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { BulkMaintenanceStatus, Components } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IState } from "../../../store/reducers";
import { StateService } from "@uirouter/angular";
import { untilDestroyed } from "ngx-take-until-destroy";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { FxpSignalRService, UserInfoService } from "@fxp/fxpservices";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { ISignalRConfig } from "../../../common/services/contracts/projectservice-functions.contract";
import { DmGrmService } from "../../../common/services/dmgrm.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { INotification } from "../../../common/services/contracts/notification-bar.contracts";
import { IAutoBulkMaintenanceSignalRResponse, IAutoBulkMaintenanceStatusRequest, IAutoBulkMaintenanceStatusResponse } from "../../../common/services/contracts/auto-bulk-maintenance.service.contract";
import { AutoBulkMaintenanceStatusModalComponent } from "../auto-bulk-maintenance-status-modal/auto-bulk-maintenance-status-modal.component";
const GRMTenantName = "GRM";

@Component({
    selector: "auto-bulk-maintenance-notification",
    templateUrl: "./auto-bulk-maintenance-notification.html",
    styleUrls: ["./bulk-maintenance-notification.scss"]
})

export class AutoBulkMaintenanceComponent extends DmComponentAbstract {
    @Input() public subscriptionId: string;
    @Input() public entityId: string;
    @Input() public createdOn: string;
    @Input() public notificationDetail: INotification;
    public totalCount: number = 0;
    public processedCount: number = 0;
    public shouldDisplayCloseOption: boolean = true;
    public isbulkRequestInProgress: boolean = true;
    public isNotificationExpanded: boolean = false;
    public timeAgo: string;
    public bulkMaintenanceResponse: IAutoBulkMaintenanceStatusResponse;
    public responseSubmitted: boolean = false;
    private unsubscribeFunction: any;
    private autoBulkMaintenanceEventName: string;


    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(Store) private store: Store<IState>,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(Injector) private injector: Injector,
        @Inject(FxpSignalRService) private fxpSignalRService: FxpSignalRService,
        @Inject(DmGrmService) private grmService: DmGrmService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
    ) {
        super(dmLogger, Components.BulkCreateNotification);
    }

    public ngOnInit(): void {
        this.configManagerService.initialize().then(() => {
            this.initializeConfig();
            this.startConnectionToGRM();
            const inputPayload: IAutoBulkMaintenanceStatusRequest = {
                batchId: this.subscriptionId
            };
            this.grmService.getAutoBulkMaintenanceStatus(inputPayload).then((response) => {
                this.bulkMaintenanceResponse = response;
                this.bulkMaintenanceResponse.serviceResponse = this.bulkMaintenanceResponse.serviceResponse.map((resource) => 
                    ((resource.status === "Completed" && resource.validationErrors === "") ? { ...resource, status: BulkMaintenanceStatus.Success } : resource));
                const processsedResources = this.bulkMaintenanceResponse.serviceResponse.filter((resource) => resource.status === BulkMaintenanceStatus.Success || resource.status === BulkMaintenanceStatus.Failed);
                this.totalCount = this.bulkMaintenanceResponse.serviceResponse.length;
                this.processedCount = (processsedResources) ? processsedResources.length : 0;
                this.shouldDisplayCloseOption = (this.totalCount !== 0 && this.processedCount !== 0 && this.totalCount === this.processedCount);
                this.isbulkRequestInProgress = this.totalCount !== this.processedCount;


            });
            

        });
    }

    /**
     * Subscribe to GRM SignalR
     */
    public startConnectionToGRM(): void {
        this.unsubscribeFunction = this.fxpSignalRService.subscribe(this.autoBulkMaintenanceEventName, GRMTenantName, this.onMessageFromGRMBulkMaintenance.bind(this));
    }

    public closeNotification(): void {
        this.shouldDisplayCloseOption = false;
        const loggedInUserAlias = this.fxpUserInfoService.getCurrentUserData().alias;
        this.notificationService.deleteNotificationSubscription(loggedInUserAlias, this.subscriptionId);
    }

    /**
     * Opens bulk upload internal engagement upload modal
     *
     * @memberof BulkCreateNotificationComponent
    */
    public openBulkCreateIntEngagementStatusModal(): void {
        this.isNotificationExpanded = true;
        const modalRef: NgbModalRef = this.modalService.open(AutoBulkMaintenanceStatusModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal-v2 bulk-maintenance-status-popup-modal in",
            injector: this.injector
        });
        if (this.bulkMaintenanceResponse && this.bulkMaintenanceResponse.serviceResponse.length > 0){
            const failedresources = this.bulkMaintenanceResponse.serviceResponse.filter((resource) => resource.status === BulkMaintenanceStatus.Failed && (resource.validationErrors === null || resource.validationErrors === ""));

            if (failedresources && failedresources.length > 0 && failedresources.find){
                const inputPayload: IAutoBulkMaintenanceStatusRequest = {
                    batchId: this.subscriptionId
                };

                this.grmService.getAutoBulkMaintenanceStatus(inputPayload).then((response) => {
                    this.bulkMaintenanceResponse = response;
                    this.bulkMaintenanceResponse.serviceResponse = this.bulkMaintenanceResponse.serviceResponse.map((resource) =>
                        ((resource.status === "Completed" && resource.validationErrors === "") ? { ...resource, status: BulkMaintenanceStatus.Success } : resource));

                    const processsedResources = this.bulkMaintenanceResponse.serviceResponse.filter((resource) => resource.status === BulkMaintenanceStatus.Success || resource.status === BulkMaintenanceStatus.Failed);
                    this.totalCount = this.bulkMaintenanceResponse.serviceResponse.length;
                    this.processedCount = (processsedResources) ? processsedResources.length : 0;
                    this.shouldDisplayCloseOption = (this.totalCount !== 0 && this.processedCount !== 0 && this.totalCount === this.processedCount);
                    this.isbulkRequestInProgress = this.totalCount !== this.processedCount;

                    modalRef.componentInstance.bulkMaintenanceResponse = this.bulkMaintenanceResponse;
                    modalRef.componentInstance.notificationDetail = this.notificationDetail;
                    modalRef.result.then(() => {
                        this.isNotificationExpanded = false;
                    });
                    modalRef.componentInstance.onStatusModalClose.pipe(untilDestroyed(this)).subscribe(() => this.isNotificationExpanded = false);


                });

            }
            else{
                modalRef.componentInstance.bulkMaintenanceResponse = this.bulkMaintenanceResponse;
                modalRef.componentInstance.notificationDetail = this.notificationDetail;
                modalRef.result.then(() => {
                    this.isNotificationExpanded = false;
                });
                modalRef.componentInstance.onStatusModalClose.pipe(untilDestroyed(this)).subscribe(() => this.isNotificationExpanded = false);
            }
        }
        
        
    }


    /**
    * Handle when the response is recieved from SignalR
    * @param response
    */
    private onMessageFromGRMBulkMaintenance = (response: string) => {
        if (response) {
            console.log(response);
            const convertedResponse: IAutoBulkMaintenanceSignalRResponse = JSON.parse(response);
            if (convertedResponse.BatchId === this.subscriptionId) {
                if (this.bulkMaintenanceResponse && this.bulkMaintenanceResponse.serviceResponse.length > 0){
                    this.bulkMaintenanceResponse.serviceResponse.find((resource) => resource.eventId === convertedResponse.EventId).status = convertedResponse.Status;
                    const processsedResources = this.bulkMaintenanceResponse.serviceResponse.filter((resource) => resource.status === "Completed" || resource.status === "Success" || resource.status === "Failed");
                    this.totalCount = this.bulkMaintenanceResponse.serviceResponse.length;
                    this.processedCount = (processsedResources) ? processsedResources.length : 0;

                    this.shouldDisplayCloseOption = (this.totalCount !== 0 && this.processedCount !== 0 && this.totalCount === this.processedCount);

                    this.isbulkRequestInProgress = this.totalCount !== this.processedCount;

                }
                
            }
        }
    };

    private initializeConfig(): void {
        const signalRConfiguration = this.configManagerService.getValue<ISignalRConfig[]>("SignalRConfiguration");
        const grmConfiguration = signalRConfiguration.filter((config) => config.TenantName === GRMTenantName);
        if (grmConfiguration && grmConfiguration.length && grmConfiguration[0].Events && grmConfiguration[0].Events.length) {
            this.autoBulkMaintenanceEventName = grmConfiguration[0].Events.map((eventinfo) => eventinfo.AutoBulkMaintenanceEventName)[0];
        }
    }
}