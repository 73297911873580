/* effects take an action, do some work & dispatch a new action */

import { ActualsEffect } from "../actuals/actuals.effect";
import { ChangeRequestEffect } from "../change-requests/change-requests.effect";
import { ClinslinEffect } from "../clinslin/clinslin.effect";
import { ContactsEffect } from "../contacts/contacts.effect";
import { CrIdRolesEffects } from "../cr-id-roles/cr-id-roles.effect";
import { CrScreenDetailsEffects } from "../cr-screen-details/cr-screen-details.effect";
import { CustomerFinancialsOverviewEffect } from "../customer-financial-overview/customer-financial-overview.effect";
import { DbDemandsV2Effects } from "../db-demands/demands.effect";
import { DemandsV2Effects } from "../demands/demands.effect";
import { DemandsWithSchedulesV2Effects } from "../demands-withschedules/demands-withschedules.effect";
import { EngagementChangeRequestsEffect } from "../engagement-change-requests/engagement-change-requests.effect";
import { EngagementDetailsEffects } from "../engagement-details/engagement-details.effect";
import { FinancialDetailsV2Effect } from "../financial-details-v2/financial-details-v2.effect";
import { FinancialRolesEffects } from "../financial-roles/financial-roles.effect";
import { InternalFinancialDetailsEffect } from "../internal-financial-details/internal-financial-details.effect";
import { InvoicesEffect } from "../invoices/invoices.effect";
import { ManageSuppliersEffect } from "../manage-suppliers/manage-suppliers.effect";
import { MilestonesEffect } from "../milestones/milestones.effect";
import { MyPortfolioEffect } from "../my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.effect";
import { MyPortfolioFinancialsEffect } from "../my-portfolio/my-portfolio-financials-list/my-portfolio-financials-list.effect";
import { NpcActualsEffect } from "../npc-actuals/npc-actuals.effect";
import { PlanForecastParamsEffect } from "../plan-forecast-params/plan-forecast-params.effect";
import { ProjectDetailsEffect } from "../project-details/project-details.effect";
import { ResourceRequestsEffects } from "../resource-requests/resource-requests.effect";
import { ResourceRequestsProjectContextEffects } from "../resource-requests-project-context/resource-requests-project-context.effect";
import { UserPreferenceEffects } from "../userspreferences/userpreference.effect";
import { WbsDetailsV2Effects } from "../wbs-details/wbs-details.effect";
import { WbsIdRolesEffects } from "../wbs-id-roles/wbs-id-roles.effect";
import { WbsStructuresEffects } from "../wbs-structures/wbs-structures.effects";
import { EngagementForecastRecommendationsEffects } from "../engagement-forecast-recommendations/engagement-forecast-recommendations.effect";
import { ProjectForecastRecommendationsEffects } from "../project-forecast-recommendations/project-forecast-recommendations.effect";
import { EngagementChangeRequestsV2Effect } from "../engagement-change-requests-v2/engagement-change-requests-v2.effect";
import { UnitRolesEffects } from "../unit-roles/unit-roles.effect";
import { WbsForecastEffect } from "../wbs-forecast-details/wbs-forecast.effect";
import { EngagementResourceDetailsEffects } from "../engagement-resource-details/engagement-resource-details.effect";
import { WbsCfpEffect } from "../wbs-cfp-details/wbs-cfp.effect";
import { WbsDbEffect } from "../wbs-db-details/wbs-db.effect";

export const effects: any[] = [
    ActualsEffect,
    ChangeRequestEffect,
    ClinslinEffect,
    ContactsEffect,
    CrIdRolesEffects,
    CrScreenDetailsEffects,
    CustomerFinancialsOverviewEffect,
    DbDemandsV2Effects,
    DemandsV2Effects,
    DemandsWithSchedulesV2Effects,
    EngagementChangeRequestsEffect,
    EngagementChangeRequestsV2Effect,
    EngagementDetailsEffects,   
    EngagementForecastRecommendationsEffects,
    EngagementResourceDetailsEffects,
    FinancialDetailsV2Effect,
    FinancialRolesEffects,
    InternalFinancialDetailsEffect,
    InvoicesEffect,
    ManageSuppliersEffect,
    MilestonesEffect,
    MyPortfolioEffect,
    MyPortfolioFinancialsEffect,
    NpcActualsEffect,
    PlanForecastParamsEffect,
    ProjectDetailsEffect,
    ProjectForecastRecommendationsEffects,
    ResourceRequestsEffects,
    ResourceRequestsProjectContextEffects,
    UserPreferenceEffects,
    UnitRolesEffects,
    WbsDetailsV2Effects,
    WbsIdRolesEffects,
    WbsStructuresEffects,
    WbsForecastEffect,
    WbsCfpEffect,
    WbsDbEffect
];
