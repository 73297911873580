import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { EngagementFinancialPlanSummaryComponent } from "./engagement-financial-plan-summary.component";


@NgModule({
    declarations: [
        EngagementFinancialPlanSummaryComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        NgbModule
    ],
    providers: [],
    entryComponents: [
        EngagementFinancialPlanSummaryComponent
    ],
    exports: [
        EngagementFinancialPlanSummaryComponent
    ]
})
export class EngagementFinancialPlanSummaryModule { }
