<div>
    <span class="success-msg msg-font" *ngIf="isSyncInPorogress">{{getInProgressText()}}</span>
    <span class="error-msg msg-font" *ngIf="isThresholdBreached">
        {{getVaildationFailedText()}} <button class="dm-link" role="link" aria-label="open threshold dialog"
            (click)="openThresholdDialog()">
            <span>Click here</span>
        </button> to proceed
    </span>
    <span class="success-msg msg-font" *ngIf="isSyncEACCompleted">
        {{SYNCEAC_COMPLETED_MESSAGE}}
    </span>
</div>