<section>
    <dm-loading [loadingText]="'Loading Resources'" [isCustomText]="true" [errorText]="" [showLoading]="true"
        *ngIf="isResourcesLoading"></dm-loading>
    <section *ngIf="!isResourcesLoading">
        <section class="dm-p-imp-0" [ngClass]="{'commands-bar__disabled': disableCommands ? true : false}">
            <div class="commands-bar__icon-wrapper"
                [ngClass]="{'commands-bar__tab-active': showTruncate ? true : false}" (click)="toggleTabs('truncate')">
                <button class="font-caption-alt commands-bar__icon-btn" tabindex="0" [disabled]="disableCommands">
                    <i class="icon icon-pictureTruncate"></i>Truncate
                </button>
            </div>
            <div class="commands-bar__icon-wrapper"
                [ngClass]="{'commands-bar__tab-active': showRequestRoleMaintenance ? true : false}"
                (click)="toggleTabs('role-maintenance')">
                <button class="font-caption-alt commands-bar__icon-btn" tabindex="0" [disabled]="disableCommands">
                    <i class="icon icon-Repair"></i>Request Role Maintenance
                </button>
            </div>
            <div class="commands-bar__content-section">
                <div class="commands-bar__truncate dm-p-y-imp-10" *ngIf="showTruncate">
                    <scb-truncate-modal [wbsId]="wbsId" [assignmentList]="selectedDemandList" [isModalComponent]="false"
                        (hideTabComponent)="hideCommandActionTabs($event)">
                    </scb-truncate-modal>
                </div>
                <div class="commands-bar__role-maintenance dm-p-y-imp-10" *ngIf="showRequestRoleMaintenance">
                    <scb-rrm-modal [wbsId]="wbsId" [assignmentList]="selectedDemandList" [isModalComponent]="false"
                        (hideTabComponent)="hideCommandActionTabs($event)">
                    </scb-rrm-modal>
                </div>
            </div>
        </section>

        <section class="header__wrapper" *ngIf="!deviceFactory.isMobile()">
            <div scope="col" class="header__resource">Resource</div>
            <div scope="col" class="header__duration text-right">Duration</div>
            <div scope="col" class="header__status">Status</div>
        </section>
        <div class="resource-details-list__wrapper">
            <section class="font-caption-alt" *ngFor="let rolesRequests of rolesViewModelRequests">
                <ng-container *ngFor="let task of rolesRequests.taskModelDetails">
                    <section class="caption">
                        <div>
                            <span class="caption__text caption__text--40">{{task.taskProjectName}}</span>
                            <span class="caption__text"> / </span>
                            <span class="caption__text caption__text--30">{{task.taskServiceName}}</span>
                            <span class="caption__text"> / </span>
                            <span class="caption__text caption__text--20">{{task.taskName}}</span>
                        </div>
                        <div>
                            EBS: <span>{{task.taskId}}</span>
                        </div>
                    </section>

                    <section class="border-solid m-b-12" *ngIf="task.demands">
                        <div *ngFor="let demand of task.demands">
                            <div class="list-header">
                                {{demand.demandRole}}
                            </div>
                            <section class="resources-grid__row border-solid--bottom"
                                *ngFor="let resource of demand.resources"
                                [ngClass]="{'selected-row' : selectRole.checked }">
                                <div [hidden]="!resource.enableResourceActions"
                                    class="resources-grid__column resources__checkbox">
                                    <div class="input__checkbox ">
                                        <input type="checkbox" [attr.aria-label]="'Select Role'+resource.resourceId"
                                            class="input__checkbox--focus" #selectRole value="None"
                                            [id]="resource.resourceId" [name]="resource.resourceId"
                                            (change)="onDemandChange($event, task.taskProjectId, demand.demandId, resource.resourceId, demand, task)">
                                        <label [for]="resource.resourceId"></label>
                                    </div>
                                </div>
                                <div class="resources-grid__column">
                                    <p> {{resource.resourceName}}</p>
                                    <dm-user-name [name]="resource.resourceAlias" [alias]="resource.resourceAlias"></dm-user-name>
                                </div>
                                <div class="resources-grid__column" *ngIf="!deviceFactory.isMobile()">
                                    <p>{{resource.resourceStartDate | dmDisplayDateOrDashPipe}}</p>
                                    <p>{{resource.resourceEndDate | dmDisplayDateOrDashPipe}}</p>
                                </div>
                                <div class="resources-grid__column" [ngClass]="{'pull-right':deviceFactory.isMobile()}">
                                    <p>{{resource.resourceStatus}}</p>
                                </div>
                                <div class="date__resource resources-grid__column" *ngIf="deviceFactory.isMobile()">
                                    <h5 class="date__resource--title">Start Date</h5>
                                    <p>{{resource.resourceStartDate | dmDisplayDateOrDashPipe}}</p>
                                </div>
                                <div class="date__resource resources-grid__column" *ngIf="deviceFactory.isMobile()">
                                    <h5 class="date__resource--title">End Date</h5>
                                    <p>{{resource.resourceEndDate | dmDisplayDateOrDashPipe}}</p>
                                </div>
                            </section>
                        </div>
                    </section>
                </ng-container>
            </section>
        </div>
    </section>
</section>