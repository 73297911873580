import * as fromMilestones from "./milestones.action";
import { IBillingMilestoneModel } from "../../common/services/contracts/dmmilestone.service.contract";
import { ILoadableState } from "../reducers";

// slice of milestones state
export interface IMilestonesState extends ILoadableState {
    milestones: IBillingMilestoneModel[];
}

export const initialState: IMilestonesState = {
    milestones: [],
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromMilestones.MilestonesActions): { [engagementId: string]: IMilestonesState } {
    switch (action.type) {
        case fromMilestones.MilestonesActionTypes.LOAD_MILESTONES: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }

        case fromMilestones.MilestonesActionTypes.LOAD_MANUAL_MILESTONES: {
            const newState = {...state};
            newState[action.engagementId] = {...initialState, loading: true };
            return newState;
        }
      
        case fromMilestones.MilestonesActionTypes.LOAD_MILESTONES_SUCCESS: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                milestones: action.milestones,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromMilestones.MilestonesActionTypes.LOAD_MILESTONES_FAIL: {
            const newState = {...state};
            newState[action.engagementId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromMilestones.MilestonesActionTypes.INVALIDATE_MILESTONES: {
            const newState = {...state};
            newState[action.engagementId] = initialState;
            return newState;
        }
    }

    return state;
}