<div [ngClass]="showPersonCard ? 'display-inline-block': 'display-inline'">
    <p class="font-caption-alt" *ngIf="alias"
        [ngClass]="showEllipses ? 'dm-tile--pjm_ellipses display-inline-block': 'display-inline'">
        <dm-person-card [personDetails]="personDetails" [personImage]="personImage" [avatarOnly]="true" *ngIf="showPersonCardWithViewAvatar"></dm-person-card>
        <a class="font-caption-alt dm-link" *ngIf="!showUserNameAndAlias && !showPersonCard"
            [href]="'#/profile/' + alias +'/basic'" target="_blank" role="link" (click)="logEmployeeClick()" aria-label="open link in new tab">
            {{name | dmDisplayDashIfEmptyOrNull}}
        </a>
        <a class="font-caption-alt dm-link" *ngIf="showUserNameAndAlias && !showPersonCard"
            [href]="'#/profile/' + alias +'/basic'" target="_blank" role="link" (click)="logEmployeeClick()" aria-label="open link in new tab">
            {{name | dmDisplayDashIfEmptyOrNull}} ({{alias}})
        </a>
        <dm-person-card [personDetails]="personDetails" [personImage]="personImage" [nameOnly]="true" *ngIf="showPersonCard && !showPersonCardWithViewAvatar" [personCardLabel]="personCardLabel"></dm-person-card>
    </p>
    <mgt-person [personDetails]="personDetails" [personImage]="personImage" view="avatar" avatar-size="large"
        *ngIf="!alias && !showPersonCard && showPersonCardWithViewAvatar">
    </mgt-person>
    <p class="font-caption-alt" *ngIf="!alias && !showPersonCard && !showPersonCardWithViewAvatar"
        [ngClass]="showEllipses ? 'dm-tile--pjm_ellipses display-inline-block': 'display-inline'">
        {{name | dmDisplayDashIfEmptyOrNull}}
    </p>
    <span class="dm-m-l-4" *ngIf="shouldDisplayChatIcon && !isLoggedInUserPJM && !showPersonCard">
        <a class="chat-link" [href]="'https://teams.microsoft.com/l/chat/0/0?users='+alias+'@microsoft.com'"
            target="_blank" title="Chat" aria-label="open link in new tab">
            <span class="icon-full icon-chat"></span>
        </a>
    </span>
</div>