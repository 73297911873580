import { Inject, Component } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { Components, CacheKeys, WhatsNewTag } from "../../../common/application.constants";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { UserPreferenceService } from "../../../common/services/userpreferences.service";
import { IRelease, IFeature, ISlide } from "../../../common/services/contracts/whats-new.contracts";
import { CacheService } from "../../../common/services/cache.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "whats-new",
    templateUrl: "./whats-new-modal.html",
    styleUrls: ["./whats-new-modal.scss"]
})
export class WhatsNewModal extends DmModalAbstract {

    public showSlideView: boolean;
    public whatsNewList: IRelease;
    public slideListForFeature: ISlide[];
    public titleForBacktoSummaryView: string;
    public featureTitleForSlideView: string;
    public whatsNewTag = WhatsNewTag;
    public hideSlideName: boolean;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.WhatsNew);
    }

    public ngOnInit(): void {
        this.showSlideView = false;
        this.titleForBacktoSummaryView = "Navigate Back to Features Summary View";
        const releaseId: string = this.configManagerService.getValue<string>("whatsNewReleaseId");
        const cacheKey = CacheKeys.WhatsNewReleaseDetails.KeyName + releaseId;
        this.cacheService.get(cacheKey, () => this.userPreferenceService.getReleaseDetails(releaseId), CacheKeys.WhatsNewReleaseDetails.Duration).then((details: IRelease) => {
            this.whatsNewList = details;
        });
    }

    /**
     * Sets the title or aria-label for know more link
     */
    public setTitleOrAriaLabelForKnowMore(featureName: string): string {
        return "Know more about " + featureName;
    }

    /**
     * Switch to slide view for the feature
     */
    public switchToFeatureSlideView(feature: IFeature): void {
        this.showSlideView = true;
        if (this.whatsNewList && (this.whatsNewList.id === "OMR_2023" || this.whatsNewList.id === "NQR_2023"
            || this.whatsNewList.id === "FQR_2023"
            || this.whatsNewList.id === "MMR_2023")) {
            this.showSlideView = true;
            const unitsManagementName = "Unit Management: Units Submissions & Approval Workflow in ESXP";
            const RARName = "View the Ratable Accounting Revenue (RAR) Schedule by Period";
            const projectClosureName = "Project Closure Process: Addition of Two New States";
            const industrySolutionDominaRetirement = "Industry Solution Domain Retirement";

            if (feature.name.toLocaleLowerCase() === unitsManagementName.toLocaleLowerCase() ||
                feature.name.toLocaleLowerCase() === RARName.toLocaleLowerCase() ||
                feature.name.toLocaleLowerCase() === industrySolutionDominaRetirement.toLocaleLowerCase() ||
                this.whatsNewList.id === "MMR_2023") {
                this.slideListForFeature = [];
                feature.slides.forEach((slide) => {
                    const slideFeature: ISlide = {
                        name: slide.name,
                        description: null,
                        descriptionAboveMedia: slide.description,
                        media: slide.media
                    };
                    this.slideListForFeature.push(slideFeature);
                });
            } else if (feature.name.toLocaleLowerCase() === projectClosureName.toLocaleLowerCase()) {
                this.hideSlideName = true;
                this.slideListForFeature = [];
                feature.slides.forEach((slide) => {
                    const slideFeature: ISlide = {
                        name: null,
                        description: null,
                        nameAboveMedia: slide.name ? slide.name : feature.name,
                        media: slide.media
                    };
                    this.slideListForFeature.push(slideFeature);
                });
            } else {
                this.slideListForFeature = feature.slides;
            }
        } else {
            this.slideListForFeature = feature.slides;
        }
        this.featureTitleForSlideView = feature.name;
        this.sharedFunctionsService.focus("closeWhatsNewButton", true);
    }

    /**
     * Switch to summary view for the feature
     */
    public switchToFeatureSummaryView(): void {
        this.showSlideView = false;
        this.sharedFunctionsService.focus("closeWhatsNewButton", true);
    }

    /**
     * Move focus to specified id based on the event
     */
    public moveFocus(event: KeyboardEvent, id: string): void {
        if (this.showSlideView) {
            this.sharedFunctionsService.moveFocus(event, id, id);
        } else {
            const featuresLength = this.whatsNewList.features.length - 1;
            const focusableId = "more-slide-view-" + featuresLength;
            this.sharedFunctionsService.moveFocus(event, focusableId, focusableId);
        }
    }
}
