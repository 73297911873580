import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UIRouterModule } from "@uirouter/angular";

import { CampusService } from "../../../common/services/campus.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { DataService } from "../../../common/services/data.service";
import { DMAuthorizationService } from "../../../common/services/dmauthorization.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ProjectService } from "../../../common/services/project.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { KeyLinksComponent } from "./key-links.component";
import { UserpreferencelinksMgmtModalModule } from "./user-preference-links-mgmt-modal/user-preference-links-mgmt-modal.module";
import { ProjectServiceV2 } from "../../../common/services/project.v2.service";

@NgModule({
    declarations: [
        KeyLinksComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
        UserpreferencelinksMgmtModalModule
    ],
    providers: [
        CampusService,
        ConfigManagerService,
        DataService,
        DMAuthorizationService,
        DMLoggerService,
        ProjectService,
        SharedFunctionsService,
        ProjectServiceV2
    ],
    entryComponents: [KeyLinksComponent],
    exports: [KeyLinksComponent]
})
export class ProjectLinksModule { }



