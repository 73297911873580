<dm-modal-v2 class="unitTransactionsModal" [modalHeaderContent]="modalContent" [modalMinHeight]="'430px'"
  [hasNonOverflowContent]="true">
  <form [formGroup]="actualNotesForm">
    <div class="dm-form-entity">
      <label class="dm-form-entity__label" for="actualsNote">
        Note
        <span class="required-mark" aria-hidden="true">*</span>
      </label>
      <textarea name="actualsNote" placeholder="Enter Note" maxlength="40"
        [ngClass]="{'error-border' : actualNotes.touched && actualNotes.invalid}" id="description"
        formControlName="actualNotes" aria-label="enter unit note" required></textarea>
      <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
        {{40 - actualNotes.value.length}}/40 characters remaining.
      </span>
      <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true" aria-live="polite"
        *ngIf="(actualNotes?.dirty || actualNotes?.touched) && !actualNotes.value.length">
        Notes is required.
      </span>
    </div>
  </form>
  <div additionalFooterButtons>
    <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" [disabled]="actualNotesForm.invalid"
      (click)="submitNotes()">
      Submit
    </button>
  </div>
</dm-modal-v2>