<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'350px'" [isLoading]="isAddingTask">
    <dm-loading loader [showLoading]="true" [isCustomText]="true" *ngIf="isAddingTask" [loadingText]="loadingText">
    </dm-loading>
    <div *ngIf="!isAddingTask">
        <!-- Form section -->
        <form [formGroup]="addTaskDetailsForm">
            <div class="dm-form-entity" [ngClass]="{'dm-m-b-imp-20': (taskName?.dirty || taskName?.touched) && !taskName.value.length}">
                <label class="dm-form-entity__label" for="taskName">
                    Name
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <input class="font-caption-alt dm-input" type="text" formControlName="taskName" maxlength="40"
                    [ngClass]="{'error-border': (taskName?.dirty || taskName?.touched) && taskName.invalid}"
                    id="taskName" name="taskName" aria-label="task name" placeholder="Enter Name">
                <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
                    {{40 - taskName.value.length}}{{addTaskErrorMessages.NameMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite" *ngIf="(taskName?.dirty || taskName?.touched) && !taskName.value.length">
                    {{addTaskErrorMessages.TaskNameRequired}}
                </span>
            </div>
            <div class="dm-form-entity">
                <label class="dm-form-entity__label" for="taskDescription">
                    Description
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <textarea name="taskDescription" placeholder="Enter Description" aria-invalid="false"
                    [ngClass]="{'error-border' : taskDescription.touched && taskDescription.invalid}"
                    id="taskDescription" formControlName="taskDescription" aria-label="task Description" maxlength="255"
                    required></textarea>
                <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
                    {{255 - taskDescription.value.length}}{{addTaskErrorMessages.DescriptionMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite"
                    *ngIf="(taskDescription?.dirty || taskDescription?.touched) && !taskDescription.value.length">
                    {{addTaskErrorMessages.DescriptionIsRequired}}
                </span>
            </div>
            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="taskStartDate">
                        Start Date
                    </label>
                    <dm-datepicker id="startDate" name="taskStartDate" formControlName="taskStartDate"
                        [minDate]="minDate" [maxDate]="maxDate" [modelDate]="taskStartDate.value"
                        ariaLabelButton="Start date" [placeholder]="'Select a date...'" ariaLabelCalendar="Start date"
                        (onDateChange)="isTaskStartDateValid($event)" previosFousingElementId="taskDescription"
                        [showErrorBorder]="isStartDateRequired || isBeforeServiceStartDate" [isDatePickerV2]="true">
                    </dm-datepicker>
                    <p role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite">
                        <span *ngIf="taskStartDate?.errors?.required || isStartDateRequired">
                            {{addTaskErrorMessages.StartDateIsRequired}}
                        </span>
                    </p>
                    <p role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeServiceStartDate">
                        {{addTaskErrorMessages.TaskStartDateShouldBeWithinServiceStartDate}}
                    </p>
                </div>
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="taskEndDate">
                        End Date
                    </label>
                    <dm-datepicker id="endDate" name="taskEndDate" [minDate]="minDate" [maxDate]="maxDate"
                        [modelDate]="taskEndDate.value" formControlName="taskEndDate" [placeholder]="'Select a date...'"
                        ariaLabelButton="End date" ariaLabelCalendar="End date"
                        (onDateChange)="isTaskEndDateValid($event)" previosFousingElementId="calenderBtn_Start date"
                        [showErrorBorder]="isEndDateRequired || isDateCompare || isAfterServiceEndDate"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <p role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite">
                        <span *ngIf="taskEndDate?.errors?.required || isEndDateRequired">
                            {{addTaskErrorMessages.EndDateRequired}}
                        </span>
                    </p>
                    <p role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isEndDateBeforeStartDate">
                        {{addTaskErrorMessages.EndDateCannotBeEarlierThanTheStartDate}}
                    </p>
                    <p role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterServiceEndDate">
                        {{addTaskErrorMessages.TaskEndDateMustBeBeforeTheServiceEndDate}}
                    </p>
                </div>
            </div>
        </form>
        <div class="dm-info-msg--primary">
            To be able to view this task in the Current Financial Plan (under the Plan and Forecast tab) you must add a
            role
            in the CFP associated to the EBS item.
        </div>
    </div>
    <div additionalFooterButtons>
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" (click)="addNewTask()"
            *ngIf="!isAddingTask" [disabled]="saveButtonDisabled(!addTaskDetailsForm.valid)">
            Save
        </button>
    </div>
</dm-modal-v2>