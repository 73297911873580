<section class="truncate-dailog">
    <label class="sr-only">Truncate Request</label>
    <section class="modal-header" *ngIf="isModalComponent">
        <button type="button" class="close set-focus" (click)="closeModal()" id="accessibilityConstants.ClosePopUp"
            (keydown)="moveFocusPrev($event, 'accessibilityConstants.TruncateRequest')" aria-label="Truncate Request Dialog close">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            Truncate Request
        </h4>
    </section>
    <dm-loading [loadingText]="loadingText" [isCustomText]="true" [errorText]="errorSummaryText"
        [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>
    <section class="modal-body" *ngIf="!isLoading">
        <form #truncateRequest>
            <section class="section__wrapper display-grid">
                <p class="font-caption-alt" *ngIf="!isModalComponent">Truncating a resource will reduce the amount of
                    time they work on a project, effective immediately.
                </p>
                <section class="input__label">
                    <label class="input__label--text" for="dm-truncate-reason-ddl">
                        Reason Code
                        <span class="required-mark" aria-hidden="true">
                            *
                        </span>
                    </label>
                    <section class="input__select--icon">
                        <select id="dm-truncate-reason-ddl" [(ngModel)]="selectedTruncateReason" required
                            name="reasonType" class="input__select input__select--big font-caption-alt">
                            <option [value]="'select'">Select reason code</option>
                            <option *ngFor="let reason of truncateReasons" [value]="reason.Name">
                                {{reason.Name}}
                            </option>
                        </select>
                        <!-- todo add this (change)="loadReasonForSelectedAction()" -->
                        <!-- <span class="sr-only" role="alert" aria-atomic="true" aria-live="polite" *ngIf=="truncateRequest.reasonType.$touched && truncateRequest.reasonType.$invalid">Suspend Reason is required</span> -->
                        <span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite"
                            *ngIf="truncateRequest.reasonType.touched && truncateRequest.reasonType.invalid">
                            {{truncateErrorMessages.SuspendReasonIsRequired}}
                        </span>
                    </section>
                </section>


                <section class="truncate-enddate">
                    <label class="input__label--text" for="dm-truncate-end-date">
                        Scheduled End Date
                        <span class="required-mark" aria-hidden="true">
                            *
                        </span>
                    </label>
                    <p class="input-group" *ngIf="isMinMaxTruncateDateLoaded">
                        <dm-datepicker [minDate]="truncateMinDate" [maxDate]="truncateMaxDate" [isMandatory]="true"
                            [modelDate]="truncateDate" [disabled]="false" (onDateChange)="setTruncateDate($event)"
                            ariaLabelButton="End date" ariaLabelCalendar="End date" placeholder="Select date" previosFousingElementId="dm-truncate-reason-ddl">
                        </dm-datepicker>
                    </p>
                </section>
                <label class="input__label--text" for="dm-truncate-reason">
                    Comments
                    <span class="required-mark" aria-hidden="true"
                        *ngIf="selectedTruncateReason && selectedTruncateReason.toLowerCase()==='other'">
                        *
                    </span>
                </label>
                <textarea id="dm-truncate-reason" [(ngModel)]="truncateComment" name="rejectionComments"  *ngIf="selectedTruncateReason && selectedTruncateReason.toLowerCase()==='other'"
                    class="input_text_edge" placeholder="Comments"></textarea>
                <textarea id="dm-truncate-reason" [(ngModel)]="truncateComment" name="rejectionComments"  *ngIf="!(selectedTruncateReason && selectedTruncateReason.toLowerCase()==='other')" 
                    required class="input_text_edge" placeholder="Comments"></textarea>
                <!-- [(ngModel)]="suspendComment"<span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite" *ngIf="truncateRequest.commentsValidation">Comments are required</span> -->
                <label id="lblMessage" class="error-msg" for="dm-id-truncateRequest">
                    <span class="required-mark" role="alert" aria-atomic="true" aria-live="polite">
                        {{lblMessage}}
                    </span>
                </label>
            </section>
            <section class="modal-footer footer-button" [ngClass]="{'tab-footer': !isModalComponent}">
                <button type="button" class="fxp-btn fxp-btn--secondary" id="cancelRequest" (click)="closeModal()">
                    Cancel
                </button>
                <button type="button" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.TruncateRequest" (click)="submit()"
                    (keydown)="moveFocusNext($event, 'accessibilityConstants.ClosePopUp')">
                    Truncate
                </button>
            </section>
        </form>
    </section>
</section>