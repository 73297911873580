import { Action } from "@ngrx/store";
import { IResourceDetailsResponse } from "../../common/services/contracts/changerequest.contract";

// 1. action types
export enum EngagementResourceDetailsActionTypes {
    LOAD_ENGAGEMENT_RESOURCE_DETAILS = "[EngagementResourceDetails] Load",
    LOAD_ENGAGEMENT_RESOURCE_DETAILS_SUCCESS = "[EngagementResourceDetails] Load Success",
    LOAD_ENGAGEMENT_RESOURCE_DETAILS_FAIL = "[EngagementResourceDetails] Load Fail",
    INVALIDATE_ENGAGEMENT_RESOURCE_DETAILS = "[EngagementResourceDetails] Invalidate"
}

// 2. action creators
export class LoadEngagementResourceDetails implements Action {
    public readonly type = EngagementResourceDetailsActionTypes.LOAD_ENGAGEMENT_RESOURCE_DETAILS;
    public constructor(public engagementId: string) { }
}
export class LoadEngagementResourceDetailsSuccess implements Action {
    public readonly type = EngagementResourceDetailsActionTypes.LOAD_ENGAGEMENT_RESOURCE_DETAILS_SUCCESS;
    public constructor(public engagementId: string, public resourceDetails: IResourceDetailsResponse) { }
}

export class LoadEngagementResourceDetailsFail implements Action {
    public readonly type = EngagementResourceDetailsActionTypes.LOAD_ENGAGEMENT_RESOURCE_DETAILS_FAIL;
    public constructor(public engagementId: string, public errorMessage: string) { }
}

export class InvalidateEngagementResourceDetails implements Action {
    public readonly type = EngagementResourceDetailsActionTypes.INVALIDATE_ENGAGEMENT_RESOURCE_DETAILS;
    public constructor(public engagementId: string) { }
}

// 3. export the union creators
export type EngagementResourceDetailsActions =
    | LoadEngagementResourceDetails
    | LoadEngagementResourceDetailsSuccess
    | LoadEngagementResourceDetailsFail
    | InvalidateEngagementResourceDetails;