import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { ContactsService } from "../../../common/services/contacts.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DataService } from "../../../common/services/data.service";
import { DmContactDetailsComponent } from "./dm-contact-details.component";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { UpdateCSATContactModalModule } from "./update-csat-contact/update-csat-contact.module";
import { UpdateContactLanguageModalModule } from "./update-contact-language/update-contact-language.module";
import { DeleteCsatContactModalModule } from "./delete-csat-contact/delete-csat-contact.module";

@NgModule({
    declarations: [
        DmContactDetailsComponent
    ],
    imports: [
        UIRouterModule,
        CommonModule,
        CommonComponentsModule,
        NgbModule,
        UpdateCSATContactModalModule,
        UpdateContactLanguageModalModule,
        DeleteCsatContactModalModule
    ],
    providers: [
        DMLoggerService,
        ConfigManagerService,
        ContactsService,
        DataService
    ],
    entryComponents: [
        DmContactDetailsComponent
    ],
    exports: [
        DmContactDetailsComponent
    ]
})
export class DmContactDetailsModule { }
