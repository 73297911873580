import * as fromUserPreferences from "./userpreference.action";
import { ILoadableState } from "../reducers";
import { IUserPreference } from "../../common/services/contracts/userpreference.contract";


export interface IUserPreferenceState extends ILoadableState {
    userPreference: IUserPreference;
}

export const initialState: IUserPreferenceState = {
    userPreference: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = initialState, action: fromUserPreferences.UserPreferenceAction): IUserPreferenceState  {
    switch (action.type) {
        case fromUserPreferences.UserPreferenceActionTypes
            .LOAD_USERPREFERENCE: {
            let newState = {...state};
            newState = { ...initialState, loading: true };
            return newState;                
        }
        case fromUserPreferences.UserPreferenceActionTypes
            .UPDATE_USERPREFERENCE: {
            let newState = {...state};
            newState = {
                ...initialState,
                userPreference: action.userPreferenceApiResponse,
                loaded: true,
                loading: false,
            };
            return newState;
        }
        case fromUserPreferences.UserPreferenceActionTypes
            .LOAD_USERPREFERENCE_SUCCESS: {
            let newState = {...state};
            newState = {
                ...initialState,
                userPreference: action.userPreferenceApiResponse,
                loaded: true,
                loading: false,
            };
            return newState;
        }
        case fromUserPreferences.UserPreferenceActionTypes
            .LOAD_USERPREFERENCE_FAIL: {
            let newState = {...state};
            newState = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }
        case fromUserPreferences.UserPreferenceActionTypes
            .INVALIDATE_USERPREFERENCE: {
            let newState = {...state};
            newState = initialState;
            return newState;
        }
    }
    return state;
}