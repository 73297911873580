
import { combineLatest as observableCombineLatest } from "rxjs";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { Component, forwardRef, Inject, AfterContentChecked } from "@angular/core";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { IEntityFinancials } from "../../../financial-mgmt/financial.model";
import { IFinancialViewModel } from "./project.financials.model";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { IState, ILoadableState } from "../../../../store/reducers";
import { LogEventConstants, SourceConstants, Components, FinancialType, NoDataText } from "../../../../common/application.constants";
import { RouteName } from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IProjectDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";

@Component({
    selector: "dm-project-summary-financials-v2",
    templateUrl: "./project-summary-financials-v2.html",
    styleUrls: ["./project-summary-financials-v2.scss"]
})
export class ProjectSummaryFinancialsV2Component extends DmComponentAbstract implements AfterContentChecked {
    public financialsData: IFinancialViewModel[] = [];
    public currencyType: string;
    public cceacValues: any;
    public isBaseLineActive: boolean;
    public isFinancialEmpty: boolean;
    public errorText: string;
    public noFinancialText: string;
    public projectId: string;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public isDesktopView: boolean;
    public isMobileView: boolean;
    public isTabletView: boolean;
    public isSmallScreenView: boolean;
    public projectFullDetails: IProjectDetailsV2;
    public isUnitBasedContract: boolean = false;
    public tileContent: ITile;    
    public isServerError: boolean;
    private componentsMounted: boolean = false;
    private projectFinancialDetails: IEntityFinancials;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) private deviceFactory: DeviceFactoryProvider,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService
    ) {
        super(dmLogger, Components.ProjectSummaryFinancials);
    }

    public ngOnInit(): void {
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isMobileView = this.deviceFactory.isMobile();
        this.isSmallScreenView = this.deviceFactory.isSmallScreen();
        this.isTabletView = this.deviceFactory.isTablet();
        this.noFinancialText = NoDataText.NoFinancialDataLoadedText;
        this.projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        this.tileContent = {
            title: "Financials"
        };
        const entireFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.projectId));
        const entireProjectDetails$ = this.store.select(getEntireProjectDetails(this.projectId));
        observableCombineLatest(
            entireFinancialDetails$,
            entireProjectDetails$,
            (
                projectFinancialDetailsState: IFinancialDetailsV2State,
                projectDetailsState: IProjectDetailsState,
            ) => ({
                projectFinancialDetailsState,
                projectDetailsState,
            })
        ).pipe(untilDestroyed(this))
            .subscribe(({
                projectFinancialDetailsState,
                projectDetailsState,
            }) => {
                this.checkLoadingStatus(projectFinancialDetailsState, projectDetailsState);
                if (projectDetailsState.loaded && projectFinancialDetailsState.loaded && projectFinancialDetailsState.financialDetails) {
                    this.projectFullDetails = projectDetailsState.projectDetails.projectFullDetails;
                    this.isUnitBasedContract = this.projectFullDetails.hasUnitBasedDemands;
                    this.financialsData = [];
                    if (projectFinancialDetailsState.financialDetails) { // todo flatten the project financial details object so we can use the built in logic with it
                        this.projectFinancialDetails = projectFinancialDetailsState.financialDetails;
                        if (this.projectFinancialDetails && this.projectFinancialDetails.financialSummary) {
                            this.isBaseLineActive = this.financialService.getIsBaselineActiveForV2(this.projectFinancialDetails.financialSummary);
                            this.cceacValues = this.financialService.getCceacValuesForV2(this.projectFinancialDetails.financialSummary);
                            this.financialService.addFinancialsToFinancialsViewModelListForV2(this.financialsData, this.projectFinancialDetails.financialSummary.filter((c) => c.versionKey === FinancialType.EAC), "EAC");
                            this.financialService.addFinancialsToFinancialsViewModelListForV2(this.financialsData, this.projectFinancialDetails.financialSummary.filter((c) => c.versionKey === FinancialType.CurrentFinancialPlan), "Current");
                            this.financialService.addFinancialsToFinancialsViewModelListForV2(this.financialsData, this.projectFinancialDetails.financialSummary.filter((c) => c.versionKey === FinancialType.DeliveryBaseline), "Delivery");
                            this.financialService.addFinancialsToFinancialsViewModelListForV2(this.financialsData, this.projectFinancialDetails.financialSummary.filter((c) => c.versionKey === FinancialType.ContractBaseline), "Bid");
                            this.currencyType = this.financialService.getCurrencyTypeForV2(this.projectFinancialDetails.financialSummary);
                            this.isFinancialEmpty = false;
                        } else {
                            this.isFinancialEmpty = true;
                        }
                    }
                }
                if (projectDetailsState.error || projectFinancialDetailsState.error) {
                    this.isServerError = true;
                }
            });
    }

    public ngAfterContentChecked(): void {
        if (!this.componentsMounted && (this.isDesktopView || this.isSmallScreenView) && this.isBaseLineActive && !this.isFinancialEmpty) {
            this.addNewChildComponent(Components.Cceac);
            this.componentsMounted = true;
        }
    }

    /**
     * Logs that the project financials link has been clicked.
     */
    public logEvent(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ProjectSummaryV2Page, SourceConstants.Method.LogEvent, LogEventConstants.ProjectFinancialsLink);
    }

    /**
     * Manages the loading state and errors of the given loadable state items.
     *
     * @private
     * @param {...ILoadableState[]} items
     * @memberof FinancialPlanComponent
     */
    private checkLoadingStatus(...items: ILoadableState[]): void {
        this.refreshOnItemInvalidation(...items);
        this.setLoadersBasedOnItemState(...items);
        this.setErrorsBasedOnItemState(...items);
    }

}
