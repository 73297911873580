import { Inject, forwardRef, Injectable } from "@angular/core";
import { ErrorSeverityLevel, FxpMessageService } from "@fxp/fxpservices";
import { v4 as uuid } from "uuid";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { IApiResponseMessage, IAssignment, IActionResponse } from "../../staffing-command-bar-common/services/contracts/staffing-action.service.contract";
import { IEngineRuleVerificationResponse, IRule } from "../../staffing-command-bar-common/services/contracts/rule-engine.service.contract";
import { IResourceRequestForGRMInput } from "../../../../common/services/contracts/staffing.service.contract";
import { RuleEngineService } from "../../staffing-command-bar-common/services/rule-engine.service";
import { StaffingActionConstants } from "../../staffing-command-bar-common/services/staffing-action.constants";
import { StaffingActionService } from "../../staffing-command-bar-common/services/staffing-action.service";
import { StaffingService } from "../../../../common/services/staffing.service";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { Store } from "@ngrx/store";
import { IState } from "../../../../store/reducers";
import { DmServiceAbstract } from "../../../../common/abstraction/dm-service.abstract";
import { Services, SourceConstants } from "../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";

declare let require: any;

@Injectable()
export class RoleRemovalService extends DmServiceAbstract{

    private validationResults: IEngineRuleVerificationResponse;

    public constructor(
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(StaffingActionService) private staffingActionService: StaffingActionService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(RuleEngineService) private ruleEngineService: RuleEngineService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(dmLogger, Services.RoleRemoval);
        this.configManagerService.initialize();
    }

    /**
     * Submit request to suspend request for assignment
     */
    public async submitRemoveRole(assignmentList: IResourceRequestForGRMInput[]): Promise<void> {
        if (!assignmentList || assignmentList.length <= 0) {
            this.fxpMessageService.addMessage("Assignment data missing or not Selected", "error", false, uuid());
        } else if (this.checkIfDraftResourcesRemoved(assignmentList)) {
            if (assignmentList.length === 1) {
                this.fxpMessageService.addMessage("One of the draft request selected is the only request for a demand which cannot be removed. Please contact your resource manager for further assistance.", "error", false, uuid());
            } else {
                this.fxpMessageService.addMessage("Cannot remove all resource requests from the demand. Please leave at least one resource request on the demand.", "error", false, uuid());
            }
        } else {
            let responseMessage: IApiResponseMessage;
            await this.runValidationRules(assignmentList)
                .then(() => {
                    const assignmentIds: number[] = [];
                    assignmentList.forEach((element) => {
                        assignmentIds.push(element.assignmentId);
                    });
                    this.staffingActionService.removeRole(assignmentIds)
                        .then((r: IActionResponse) => {
                            responseMessage = this.staffingActionService.onSuccessAPICall(StaffingActionConstants.ActionRoleRemoval, r);
                            this.staffingActionService.displayMessages(responseMessage, this.validationResults.validationMessage);
                            const projectIds = assignmentList.map((assignment) => assignment.projectIdSelected);
                            this.staffingService.invalidateProjectStaffingData(projectIds);
                        })
                        .catch((e) => {
                            const errorMessage = this.sharedFunctionsService.getErrorMessage(e, "");
                            this.logError(SourceConstants.Method.SubmitRemoveRole, e, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                            this.staffingActionService.onErrorAPICall(StaffingActionConstants.ActionRoleRemoval, e);
                        });
                });
        }
    }

    /**
     * Run validation rules against given input
     *
     * @private
     * @returns {Promise<any>}
     * @memberof RoleRemovalComponent
     */
    private runValidationRules(assignmentList: IAssignment[]): Promise<any> {
        const rulePath: IRule[] = require("../../staffing-command-bar-common/Rules/removeRoleRules.json");
        this.ruleEngineService.addRules(rulePath);
        return this.ruleEngineService.runRules(assignmentList)
            .then((result) => {
                this.validationResults = result;
                if (!this.validationResults.isActionable) {
                    this.fxpMessageService.addMessage(result.validationMessage, "error", true);
                    return Promise.reject(false);
                } else {
                    return Promise.resolve(true);
                }
            });
    }

    /**
     * Run validation rules against the input to check if we are removing all the drafts.
     * You cannot remove a draft item if it is the only item for the demand. You also cannot delete
     * all draft items if that would leave the demand without any items below it.
     * 
     * You can delete a draft item as long as there are remaining items below the demand (does not matter if
     * the remaining items are draft or some other state.)
     *
     * @private
     * @returns {boolean}
     * @memberof RoleRemovalComponent
     */
    private checkIfDraftResourcesRemoved(assignmentList: IResourceRequestForGRMInput[]): boolean {
        if (assignmentList
            && assignmentList.length === 1
            && assignmentList[0].resourcesUnderDemand
            && assignmentList[0].resourcesUnderDemand.length === 1 // if the assignment is the only resource under this demand
            && assignmentList[0].resourcesUnderDemand[0].ResourceRequestStatusEnum === "Draft"
        ) {
            return true;
        }
        let isDraftResourcesRemoved = false;
        if (assignmentList && assignmentList.length) {
            assignmentList.forEach((assignment) => {
                if (assignment.resourcesUnderDemand
                    && assignment.resourcesUnderDemand.length === 1 // if the assignment is the only resource under this demand
                    && assignment.resourcesUnderDemand[0].ResourceRequestStatusEnum === "Draft") {
                    isDraftResourcesRemoved = true;
                }

                /// Check if the all resources for the demand are selected
                if (!isDraftResourcesRemoved
                    && assignment.resourcesUnderDemand
                    // check if all the resources for this demand are Draft state
                    && assignment.resourcesUnderDemand.length === assignment.resourcesUnderDemand.filter((resource) => resource.ResourceRequestStatusEnum === "Draft").length
                    && this.checkIfAllResourcesSelected(assignment, assignmentList)
                ) {
                    isDraftResourcesRemoved = true;
                }
            });
        }
        return isDraftResourcesRemoved;
    }

    /**
     * Check if all the resources for a demand are selected. 
     *
     * @private
     * @returns {boolean}
     * @memberof RoleRemovalComponent
     */
    private checkIfAllResourcesSelected(assignment: IResourceRequestForGRMInput, assignmentList: IResourceRequestForGRMInput[]): boolean {
        if (!assignment || !assignmentList) {
            return false;
        }
        const resourceIdsFromAssignment: number[] = assignment.resourcesUnderDemand.map((value) => value.ResourceRequestId);
        const resourceIdsFromAssignmentArray: number[] = assignmentList.map((value) => value.assignmentId);
        if ( // check if the two arrays have exactly the same values
            resourceIdsFromAssignment.length === resourceIdsFromAssignmentArray.length &&
            resourceIdsFromAssignment.every((id) => resourceIdsFromAssignmentArray.indexOf(id) > -1)
        ) {
            return true;
        }
        return false;
    }
}
