import { CircularProgressModule } from "../../components/tiles/circular-progress/circular-progress.module";
import { CommonComponentsModule } from "../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { InvoiceFilterModule } from "./invoice-filter/invoice-filter.module";
import { InvoiceTableDataModule } from "./invoice-table-data/invoice-table-data.module";
import { InvoicesComponent } from "./invoices.component";
import { NgModule } from "@angular/core";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";

@NgModule({
    declarations: [
        InvoicesComponent
    ],
    imports: [
        CircularProgressModule,
        CommonComponentsModule,
        CommonModule,
        InvoiceFilterModule,
        InvoiceTableDataModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService,
        DMAuthorizationService
    ],
    exports: [
        InvoicesComponent,
    ],
    entryComponents: [
        InvoicesComponent,
    ]
})
export class InvoicesModule { }
