import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DmSliderComponent } from "./dm-slider.component";

@NgModule({
    declarations: [
        DmSliderComponent       
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule
    ],
    providers: [],
    entryComponents: [
        DmSliderComponent
    ],
    exports: [
        DmSliderComponent
    ]
})

export class DmSliderModule {}
