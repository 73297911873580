
export class FilterModel {
    public displayName: string;

    public constructor(
        public name: string,
        public id: string,
        public children: FilterModel[] = []
    ) {
        if (name && id) {
            this.displayName = id.charAt(0) + "..." + id.substring(id.lastIndexOf(".") + 1) + " " + name;
        } else if (name === "All Projects") {
            this.displayName = "All Projects";
        } else {
            this.displayName = undefined;
        }
    }
}

export interface ISelectedFilter {
    wbsL1: string;
    wbsL2: string;
    wbsL3: string;
}
