import { Component, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IRiskReserveDetail } from "../../../../financial.model";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { Components, ErrorText, AccessibilityConstants } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { IProjectApprovedFinancial } from "../../../../../../common/services/contracts/changerequest.contract";

@Component({
    selector: "dm-risk-reserve",
    templateUrl: "./risk-reserve.html",
    styleUrls: ["./risk-reserve.scss"]
})
export class RiskReserveModalComponent extends DmModalAbstract {

    @Input() public riskReserveDetails: IRiskReserveDetail;
    @Input() public approvedFinancialData: IProjectApprovedFinancial[];
    @Input() public currency: string;
    @Input() public engagementId: string;
    @Input() public projectId?: string;

    public riskReserveApprovedAmount: number = 0;
    public riskReserveConsumedAmount: number = 0;
    public errorText: string;
    public accessibilityConstants = AccessibilityConstants;

    public constructor(@Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) public sharedFunctionsService: SharedFunctionsService
    ) {
        super(activeModal, dmLogger, Components.RiskReserve);
    }

    public ngOnInit(): void {
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.isComponentLoading = true;
        const isProjectContext = this.projectId ? true : false;
        const wbsId: string = isProjectContext ? this.projectId : this.engagementId;

        if (!this.riskReserveDetails || !wbsId) {
            /* Sanity check; modal should never be opened without riskReserveDetails or engagementID */
            this.errorText = ErrorText.riskReserveAmountErrorText;
            this.isComponentLoading = false;
            return;
        }

        if (this.approvedFinancialData && this.approvedFinancialData.length) {
            if (isProjectContext) {
                const filteredApprovedFinancialData = this.approvedFinancialData.filter((m) => m.wbsId === this.projectId);
                const filteredProjectApprovedFinancialData = filteredApprovedFinancialData && filteredApprovedFinancialData.length ? filteredApprovedFinancialData[0] : undefined;
                this.riskReserveApprovedAmount = filteredProjectApprovedFinancialData.approvedRiskReserve;
                this.riskReserveConsumedAmount = filteredProjectApprovedFinancialData.consumedRiskReserve;
            } else {
                for (const projectReserve of this.approvedFinancialData) {
                    this.riskReserveApprovedAmount += projectReserve.approvedRiskReserve;
                    this.riskReserveConsumedAmount += projectReserve.consumedRiskReserve;
                }
            }
        }

        this.isComponentLoading = false;
    }

    /**
     * Move focus to Next element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Move focus to previous element for accessibility tooling
     * @param event 
     * @param id 
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseButton);
        }
    }

}
