import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { from as fromPromise, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";


import { IEntityFinancials } from "../../components/financial-mgmt/financial.model";
import { ProjectService } from "../../common/services/project.service";
import * as financialDetailsActions from "./financial-details-v2.action";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class FinancialDetailsV2Effect {

    @Effect()
    public loadFinancialDetails$ = this.actions$
        .ofType(financialDetailsActions.FinancialDetailsV2ActionTypes.LOAD_FINANCIAL_DETAILS_V2)
        .pipe(
            mergeMap((action: financialDetailsActions.LoadFinancialDetailsV2) => fromPromise(
                this.projectService.getFinancialsByEntityIdV2(action.engagementId, true, true, true)
            )
                .pipe(
                    map((response: IEntityFinancials) => {
                        response.wbsId = action.engagementId;
                        return new financialDetailsActions.LoadFinancialDetailsSuccessV2(action.engagementId, response);
                    }),
                    catchError((error) => of(new financialDetailsActions.LoadFinancialDetailsFailV2(action.engagementId, error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                )
            ));
    public constructor(
        private actions$: Actions,
        @Inject(ProjectService) private projectService: ProjectService,
    ) { }
} 