<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'320px'" [isLoading]="false">
    <div class="dm-m-t-20">
        <div class="dm-detail__value dm-m-b-5">
            CompassOne role
        </div>
        <div role="grid">
            <div role="rowgroup" class="dm-grid-view-table">
                <div role="row" class="dm-grid-view-table__row dm-m-b-10">
                    <div role="columnheader" class="dm-grid-view-table__headerCell">
                        Role
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell">
                        Demand ID
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell">
                        Task
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell">
                        Planned Labor
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell">
                        Billing Role ID
                    </div>
                    <div role="columnheader" class="dm-grid-view-table__headerCell">
                        Resource Location
                    </div>
                </div>
            </div>
            <div role="rowgroup" class="dm-grid-view-table">
                <div class="dm-grid-view-table__body">
                    <div role="row" class="dm-grid-view-table__row">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell">
                            {{demand.role.roleName | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!demand.role.roleName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell">
                           {{demand.demandId | dmDisplayDashIfEmptyOrNull}}
                           <span *ngIf="!demand.demandId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell">
                            <div>{{demand.taskDescription}}</div>
                            <div>
                                ID: {{demand.taskId | dmDisplayDashIfEmptyOrNull}}
                                <span *ngIf="!demand.taskId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            </div>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell">
                            {{demand.plannedLabor | number: '1.0-2' | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!demand.plannedLabor" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell">
                            {{demand.billingRoleId | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!demand.billingRoleId" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell location" [title]="demand.resourceLocation.companyCode + ' - ' + demand.resourceLocation.companyName">
                            {{demand.resourceLocation.companyCode}} - {{demand.resourceLocation.companyName}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="dm-detail__value dm-m-y-20">
                Existing CFP demand
            </div>            
            <label for="role">
                Role | Demand ID | Task
            </label>
            <dm-dropdown-menu [id]="'dm-resources-ddl'" [ariaLabel]="'Resource Role'"
                [options]="formattedExistingDemands" [(value)]="selectedExistingDemand" [labels]="'value'"
                [isDropDownV2]="true">
                <option [value]="undefined">Select role</option>
            </dm-dropdown-menu>
        </div>
    </div>
    <div additionalFooterButtonsLeft class="dm-m-r-12">
        <button type="button" id="change" class="fxp-btn fxp-btn--primary dm-btn" [disabled]="!selectedExistingDemand" (click)="changeAssignedDemandForAmendment()">
            Change
        </button>
    </div>
</dm-modal-v2>