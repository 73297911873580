import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { IntEngBulkUploadModalModule } from "../internal-engagement-bulk-upload-modal/internal-engagement-bulk-upload-modal.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BulkCreateIntEngagementStatusModalModule } from "../bulk-create-internal-engagement/bulk-create-int-engagement-status-modal/bulk-create-int-engagement-status-modal.module";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { InternalEngagementGridDataV2Component } from "./internal-engagement-grid-data-v2.component";
import { InternalEngagementOverviewTabV2Module } from "./internal-engagement-overview-tab-v2/internal-engagement-overview-tab-v2.module";
import { CommonComponentsModule } from "../../entry/common-components.module";

@NgModule({
    declarations: [
        InternalEngagementGridDataV2Component
    ],
    imports: [
        CommonModule,
        InternalEngagementOverviewTabV2Module,
        IntEngBulkUploadModalModule,
        UIRouterModule,
        BulkCreateIntEngagementStatusModalModule,
        NgbModule,
        CommonComponentsModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        InternalEngagementGridDataV2Component
    ],
    exports: [
        InternalEngagementGridDataV2Component
    ]
})

export class InternalEngagementGridDataV2Module { }
