<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'320px'"
    [isLoading]="isUpdatingTask || viewResourceEnable">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isUpdatingTask">
    </dm-loading>
    <div *ngIf="!isUpdatingTask && !viewResourceEnable">
        <!-- Form section -->
        <form [formGroup]="editTaskDetailsForm">
            <div class="dm-form-entity" [ngClass]="{'dm-m-b-imp-20': (taskName?.dirty || taskName?.touched) && !taskName.value.length}">
                <label class="dm-form-entity__label" for="taskName">
                    Name
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <input class="font-caption-alt dm-input" type="text" formControlName="taskName" maxlength="40"
                    [ngClass]="{'error-border': (taskName?.dirty || taskName?.touched) && taskName.invalid}"
                    id="taskName" name="taskName" aria-label="Task name" placeholder="Enter Name">
                <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
                    {{40 - taskName.value.length}}{{editTaskErrorMessages.NameMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite" *ngIf="(taskName?.dirty || taskName?.touched) && !taskName.value.length">
                    {{editTaskErrorMessages.TaskNameRequired}}
                </span>
            </div>
            <div class="dm-form-entity">
                <label class="dm-form-entity__label" for="taskDescription">
                    Description
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <textarea name="taskDescription" placeholder="Enter Description"
                    [ngClass]="{'error-border': taskDescription.invalid && taskDescription.touched}" id="description"
                    formControlName="taskDescription" aria-label="task Description" maxlength="250" required></textarea>
                <span class="font-caption-alt pull-right dm-m-t-4" aria-hidden="true">
                    {{250 - taskDescription.value.length}}{{editTaskErrorMessages.TaskDescriptionMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite"
                    *ngIf="(taskDescription?.dirty || taskDescription?.touched) && !taskDescription.value.length">
                    {{editTaskErrorMessages.DescriptionIsRequired}}
                </span>
            </div>

            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="startDate">
                        Start Date
                    </label>
                    <dm-datepicker id="startDate" name="taskStartDate" formControlName="taskStartDate"
                        [disabled]="disableEBSStartDateEndDateUpdates" [modelDate]="taskStartDate.value"
                        [minDate]="minDate" [maxDate]="maxDate" ariaLabelButton="Start date"
                        [placeholder]="'Select a date...'" ariaLabelCalendar="Start date"
                        (onDateChange)="onStartDateChange($event)" previosFousingElementId="description"
                        [showErrorBorder]="isStartDateRequired || isBeforeServiceStartDate" [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isStartDateRequired">
                        {{editTaskErrorMessages.StartDateIsRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeServiceStartDate">
                        {{editTaskErrorMessages.TaskStartDateCannotBeEarlierOrSameAsService}} {{serviceStartDate
                            | dmDisplayDateOrDashPipe}}. </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showActualsStartDateConflictMessage">
                        {{editTaskErrorMessages.TaskStartDateMustBeEalierOrSameAsActualPosted}}
                        {{selectedTask.minDate | dmDisplayDateOrDashPipe}}.
                    </span>
                </div>
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="newEndDate">
                        End Date
                    </label>
                    <dm-datepicker id="endDate" name="taskEndDate" [minDate]="minDate" [maxDate]="maxDate"
                        [modelDate]="taskEndDate.value" [disabled]="disableEBSStartDateEndDateUpdates"
                        formControlName="taskEndDate" [placeholder]="'Select a date...'" ariaLabelButton="End date"
                        ariaLabelCalendar="End date" (onDateChange)="onEndDateChange($event)"
                        previosFousingElementId="calenderBtn_Start date"
                        [showErrorBorder]="isEndDateRequired || isAfterServiceEndDate || !isEndDateGreaterThanStartDate(taskStartDate.value, taskEndDate.value)"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isEndDateRequired">
                        {{editTaskErrorMessages.EndDateRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="!isEndDateGreaterThanStartDate(taskStartDate.value, taskEndDate.value)">
                        {{editTaskErrorMessages.EndDateCannotBeEarlierThanTheStartDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterServiceEndDate">
                        {{editTaskErrorMessages.TaskEndDateMustBeEarlierOrSameAsService}} {{serviceEndDate |
                            dmDisplayDateOrDashPipe}}.
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showActualsEndDateConflictMessage">
                        {{editTaskErrorMessages.TaskEndDateCannotBeLaterThanActualsPosted}} {{selectedTask.maxDate |
                            dmDisplayDateOrDashPipe}}.
                    </span>
                </div>
            </div>
            <section class="font-label dm-p-t-4" role="alert" aria-atomic="true" aria-live="polite"
                *ngIf="showConflictingResources">
                <span class="font-caption-alt inp-validation">
                    {{editTaskErrorMessages.CannotChangeDateDueToResourcingConflict}} ({{noOfConflictResources}}
                    {{getWordPluralWithS("Resource", noOfConflictResources, false)}}).
                </span>
                <button class="set-focus dm-link" type="button" (click)="toggleResources()"
                    aria-label="View resource dialogue.">
                    View resources
                </button>
            </section>
        </form>
    </div>
    <div infoBar
        *ngIf="!isUpdatingTask && !viewResourceEnable && (!isNamePristine || !isDescriptionPristine || !isStartDatePristine || !isEndDatePristine)">
        <div class="dm-modal-v2-change-information-section" *ngIf="!isNamePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Task name has been changed from '{{selectedTask.name}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isDescriptionPristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Task description has been changed from '{{selectedTask.description}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isStartDatePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Task start date has been changed from
                '{{selectedTask.startDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isEndDatePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Task end date has been changed from
                '{{selectedTask.endDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
    </div>
    <div additionalFooterButtons *ngIf="!viewResourceEnable">
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" *ngIf="!isUpdatingTask"
            [disabled]="saveButtonDisabled(!editTaskDetailsForm.valid)" (click)="updateTaskDetails()">
            Submit
        </button>
    </div>
    <section class="resource-details-list" *ngIf="viewResourceEnable">

        <div class="popUpModalDialog editEngagementForm">
            <section class="modal-header modal-noborder">
                <a href class="dm-link font-caption-alt" type="button" (click)="toggleResources()">
                    <i class="icon icon-Back"></i>
                    <span class="dm-p-r-10"> Return to Edit Task</span>
                </a>
            </section>

            <div class="modal-body">
                <section class="header__wrapper">
                    <div scope="col" class="header__resource">Resource</div>
                    <div scope="col" class="header__duration text-right">Duration</div>
                    <div scope="col" class="header__status">Status</div>
                </section>
                <div class="resource-details-list__wrapper">
                    <section class="font-caption-alt" *ngFor="let tasks of filteredResourceView">
                        <section class="caption">
                            <div>
                                <span class="caption__text caption__text--40"
                                    [textContent]="tasks.taskProjectName"></span>
                                <span class="caption__text"> / </span>
                                <span class="caption__text caption__text--30"
                                    [textContent]="tasks.taskServiceName"></span>
                                <span class="caption__text"> / </span>
                                <span class="caption__text caption__text--20" [textContent]="tasks.taskName"></span>
                            </div>
                            <div>
                                EBS: <span [textContent]="tasks.taskId"></span>
                            </div>
                        </section>

                        <section class="border-solid m-b-12" *ngIf="tasks.demands.length">
                            <div *ngFor="let demand of tasks.demands">
                                <div class="list-header">
                                    {{demand.demandRole}}
                                </div>
                                <section class="border-solid--bottom" *ngFor="let value of demand.resources">
                                    <div class="header__resource">
                                        <p [textContent]="value.resourceName"></p>
                                        <dm-user-name [name]="value.resourceAlias" [alias]="value.resourceAlias"
                                            (onNameClick)="logEmployeeClick()"></dm-user-name>
                                    </div>
                                    <div class="header__duration text-right" *ngIf="!deviceFactory.isMobile()">
                                        <p [textContent]="value.resourceStartDate | dmDisplayDateOrDashPipe"></p>
                                        <p [textContent]="value.resourceEndDate | dmDisplayDateOrDashPipe"></p>
                                    </div>
                                    <div class="header__status" [ngClass]="{'pull-right':deviceFactory.isMobile()}">
                                        <p [textContent]="value.resourceStatus"></p>
                                    </div>
                                    <div class="date__resource" *ngIf="deviceFactory.isMobile()">
                                        <h5 class="date__resource--title">Start Date</h5>
                                        <p [textContent]="value.resourceStartDate | dmDisplayDateOrDashPipe"></p>
                                    </div>
                                    <div class="date__resource" *ngIf="deviceFactory.isMobile()">
                                        <h5 class="date__resource--title">End Date</h5>
                                        <p [textContent]="value.resourceEndDate | dmDisplayDateOrDashPipe"></p>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </div>
    </section>
</dm-modal-v2>