<section class="suspend-dailog">
    <label class="sr-only">Reject Resource</label>
    <section class="modal-header">
        <button type="button" class="close set-focus" (click)="closeModal()" id="closeSuspendPopUp"
            aria-label="Reject Resource Dialog close">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
        <h4 class="modal-title font-subtitle pull-left">
            Reject Resource
        </h4>
    </section>
    <dm-loading [loadingText]="'Processing Details'" [isCustomText]="true" [showLoading]="true" *ngIf="isLoading">
    </dm-loading>
    <section class="modal-body" *ngIf="!isLoading">
        <form #rejectRequest>
            <section class="section__wrapper">
                <section class="input__label">
                    <label class="input__label--text" for="dm-suspend-reason-ddl">Reason Code
                        <span class="required-mark" aria-hidden="true">*</span>
                    </label>
                    <section class="input__select--icon">
                        <select id="dm-reject-reason-ddl" [(ngModel)]="selectedReason"
                            (ngModelChange)="onReasonChange()" name="reasonType"
                            class="input__select input__select--big">
                            <option [value]="'select'">Select reason code</option>
                            <option *ngFor="let reason of rejectionReasons" [value]="reason">
                                {{reason}}
                            </option>
                        </select>
                        <span class="font-caption-alt inp-validation" role="alert" aria-atomic="true" aria-live="polite"
                            *ngIf="rejectRequest.reasonType.touched && rejectRequest.reasonType.invalid">{{resourceRejectionErrors.RejectionReasonRequired}}</span>
                    </section>
                </section>
                <label class="input__label--text" for="dm-reject-reason">Comments
                    <span class="required-mark" aria-hidden="true"
                        *ngIf="selectedReason && selectedReason.toLowerCase()=='other'">*</span>
                </label>
                <textarea id="dm-reject-reason" [(ngModel)]="rejectionComment" name="rejectionComments"
                    placeholder="Comments"></textarea>
            </section>
            <section class="modal-footer footer-button">
                <button type="button" class="fxp-btn fxp-btn--secondary" (click)="closeModal()">Cancel</button>
                <button type="button" class="fxp-btn fxp-btn--primary" id="rejectRequest" (click)="submit()"
                    [disabled]="isSubmitButtonDisabled">
                    Submit
                </button>
                <label id="lblMessage" class="input__label" for="dm-id-rejectRequest">
                    <span class="required-mark" aria-hidden="true">{{lblMessage}}</span>
                </label>
            </section>
        </form>
    </section>
</section>