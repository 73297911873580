
import { combineLatest as observableCombineLatest } from "rxjs";
import { Component, Inject, forwardRef } from "@angular/core";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";

import { AmendmentsService } from "../../../../common/services/amendments.service";
import { Components, LogEventConstants, SourceConstants, FinancialType } from "../../../../common/application.constants";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { FinancialService } from "../../../../common/services/financial.service";
import { IEntityFinancials } from "../../financial.model";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";

/// Imports from Store
import { getEntireFinancialDetailsV2 } from "../../../../store/financial-details-v2/financial-details-v2.selector";
import { getEntireProjectDetails } from "../../../../store/project-details/project-details.selector";
import { IFinancialDetailsV2State } from "../../../../store/financial-details-v2/financial-details-v2.reducer";
import { IProjectDetailsState } from "../../../../store/project-details/project-details.reducer";
import { IState } from "../../../../store/reducers";
import { StoreDispatchService } from "../../../../common/services/store-dispatch.service";
import { ITile } from "../../../../components/tiles/dm-tile/dm-tile.component";

enum TabNames {
    FinancialForecast,
    Snapshot,
    Amendments
}
@Component({
    selector: "dm-financial-nav",
    templateUrl: "./financial-nav.html",
    styleUrls: ["./financial-nav.scss"]
})
export class FinancialNavComponent extends DmComponentAbstract {

    public countOfAmendments: number = 0; // currently retrieved by calling the CRDetails list API even though we don't use the values here, todo optimize this
    public hasMisalignedAmendments: boolean = false;
    public currentPage: number;
    public showAmendmentSnapshotTabs: boolean = false;
    public snapShotCount: number = 0;
    public TabNames = TabNames;
    public isDesktopView: boolean;
    public isMobileView: boolean;
    public tileContent: ITile;
    private activeTab: TabNames = TabNames.FinancialForecast;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) private deviceFactory: DeviceFactoryProvider,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(AmendmentsService) private amendmentsService: AmendmentsService,
        @Inject(StateService) private stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService
    ) {
        super(dmLogger, Components.FinancialNavigation, [
            { component: Components.FinancialSnapshotDetails, isCritical: true },
            { component: Components.FinancialPlan, isCritical: true }
        ]);
    }

    public ngOnInit(): void {
        this.isDesktopView = this.deviceFactory.isDesktop();
        this.isMobileView = this.deviceFactory.isMobile();
        this.tileContent = {
            title: "Financial Plans"
        };
        const projectId: string = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
        const engagementId: string = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
        if (projectId) {
            this.storeDispatchService
                .requireProjectDetails(projectId, true)
                .requireFinancialDetailsV2(projectId, true)
                .load();
            const projectDetails$ = this.store.select(getEntireProjectDetails(projectId));
            const projectFinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(projectId));
            observableCombineLatest(
                projectDetails$,
                projectFinancialDetails$,
                (
                    projectDetails: IProjectDetailsState,
                    projectFinancialDetails: IFinancialDetailsV2State,
                ) => ({
                    projectDetails,
                    projectFinancialDetails,
                })
            ).pipe(untilDestroyed(this))
                .subscribe(({
                    projectDetails,
                    projectFinancialDetails,
                }) => {
                    if (projectDetails.loaded && projectFinancialDetails.loaded) {
                        this.snapShotCount = 0;
                        this.countOfAmendments = 0;
                        this.showAmendmentSnapshotTabs = false;
                        const projectFinancialDetailsResponse: IEntityFinancials = projectFinancialDetails.financialDetails;
                        if (projectFinancialDetailsResponse && projectFinancialDetailsResponse.financialPlanVersions) {
                            this.snapShotCount = this.financialService.getSortedSnapShoDetailsForV2(projectFinancialDetailsResponse.financialPlanVersions).length;
                            const currentFinancials = this.financialService.getFinancialDetailsFromParentForV2Object(projectFinancialDetailsResponse, FinancialType.CurrentFinancialPlan);
                            if (currentFinancials) {
                                this.showAmendmentSnapshotTabs = true;
                                this.getCountOfAmendments(engagementId).then((response: number) => {
                                    this.countOfAmendments = response;
                                });
                            }
                        }
                    }
                    this.endComponentLoad();
                });
        } else {
            this.storeDispatchService
                .requireFinancialDetailsV2(engagementId, true)
                .load();
            const engagementfinancialDetails$ = this.store.select(getEntireFinancialDetailsV2(engagementId));
            engagementfinancialDetails$.pipe(untilDestroyed(this)).subscribe((engagementFinancialDetails: IFinancialDetailsV2State) => {
                if (engagementFinancialDetails.loaded) {
                    this.snapShotCount = 0;
                    this.countOfAmendments = 0;
                    this.showAmendmentSnapshotTabs = false;
                    const engagementFinancialDetailsResponse: IEntityFinancials = engagementFinancialDetails.financialDetails;
                    if (engagementFinancialDetailsResponse && engagementFinancialDetailsResponse.financialSummary && engagementFinancialDetailsResponse.financialPlanVersions) {
                        this.snapShotCount = this.financialService.getSortedSnapShoDetailsForV2(engagementFinancialDetailsResponse.financialPlanVersions).length;
                        const currentFinancials = this.financialService.getFinancialDetailsFromParentForV2Object(engagementFinancialDetailsResponse, FinancialType.CurrentFinancialPlan);
                        if (currentFinancials) {
                            this.showAmendmentSnapshotTabs = true;
                            this.getCountOfAmendments(engagementId).then((response: number) => {
                                this.countOfAmendments = response;
                            });
                        }
                    }
                    this.endComponentLoad();
                }
            });
        }
    }

    /**
     * Set the active tab to the given tab name (used for on click)
     * @param newTab
     */
    public setTab(newTab: TabNames): void {
        this.currentPage = 1;
        if (newTab === this.activeTab) {
            return;
        }
        const propertyBag = {};
        propertyBag[LogEventConstants.FinancialsPlanTabName] = TabNames[newTab];
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.SetTab, LogEventConstants.FinancialsPlanTab, propertyBag);
        this.mountAndUnmountChildComponents(this.activeTab, newTab);
        this.activeTab = newTab;
    }

    /**
     * Check if the selected tab is the current tab the user is on/active tab
     * @param selectedTab
     */
    public isSelectedTab(selectedTab: TabNames): boolean {
        return this.activeTab === selectedTab;
    }

    /**
     * Gets the count of amendments that will appear on the amendments tab. Will call the amendment APIs if the information has not
     * already been retrieved for this entity. Returns a number, the number could be zero.
     *
     * @param {string} engagementId
     * @returns {Promise<number>}
     * @memberof FinancialNavComponent
     */
    public getCountOfAmendments(engagementId: string): Promise<number> {
        return this.amendmentsService.getCountOfAmendments(engagementId);
    }

    /**
     * Check if any amendment is misaligned
     */
    public isAnyAmendmentMisaligned(): boolean {
        return this.amendmentsService.isAnyAmendmentMisaligned();
    }

    /**
     * Move the focus to element for accessibility tooling
     * @param id
     */
    public focus(id: string): void {
        this.sharedFunctionsService.focus(id, true);
    }

    /**
     * Mounts and unmounts children components for telemetry tracking of page loads based on tab change.
     */
    private mountAndUnmountChildComponents(oldTab: TabNames, newTab: TabNames): void {
        /* Unmount the current component from the list of Component children */
        // should we add a default just in case?
        switch (oldTab) {
            case TabNames.FinancialForecast:
                this.removeChildComponent(Components.FinancialPlan);
                break;
            case TabNames.Snapshot:
                this.removeChildComponent(Components.FinancialSnapshots);
                break;
            case TabNames.Amendments:
                this.removeChildComponent(Components.FinancialAmendments);
                break;
        }
        /* Mount the newly selected component based on the tab selected */
        switch (newTab) {
            case TabNames.FinancialForecast:
                this.addNewChildComponent(Components.FinancialPlan);
                break;
            case TabNames.Snapshot:
                if (this.deviceFactory.isDesktop()) {
                    this.addNewChildComponent(Components.FinancialSnapshots);
                }
                break;
            case TabNames.Amendments:
                this.addNewChildComponent(Components.FinancialAmendments);
                break;
        }
    }
}
