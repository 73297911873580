import { NgModule } from "@angular/core";
import { UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { FinancialSummaryDetailsComponent } from "./financial-summary-details.component";
import { CceacModule } from "../../cost-consumed/cceac/cceac.module";
import { CommonComponentsModule } from "../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../common/services/sharedfunctions.service";
import { FinancialService } from "../../../../../common/services/financial.service";
import { CircularProgressModule } from "../../../../tiles/circular-progress/circular-progress.module";

@NgModule({
    declarations: [
        FinancialSummaryDetailsComponent,
    ],
    imports: [
        CceacModule,
        CircularProgressModule,
        CommonComponentsModule,
        CommonModule,
        UIRouterModule
    ],
    providers: [
        DMLoggerService,
        FinancialService,
        SharedFunctionsService
    ],
    entryComponents: [
        FinancialSummaryDetailsComponent,
    ],
    exports: [
        FinancialSummaryDetailsComponent,
    ]
})
export class FinancialSummaryDetailsModule { }
