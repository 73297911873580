<section class="col-md-12 col-sm-12 col-lg-12">
  <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'calc(100vh - 125px)'">
    <dm-loading loader [loadingText]="loadingText" [showLoading]="showLoading" [isCustomText]="true"
      *ngIf="isComponentLoading && !isServerError"></dm-loading>
    <section *ngIf="!isComponentLoading && !isServerError">
      <section class="col-lg-4 col-md-6 col-sm-6 display-inline-flex-imp dm-p-x-imp-10"
        *ngIf="!unitsViewModelList && unitsViewModelList?.length">
        <section class="col-md-3 col-sm-3 dm-actuals__filter-section-label dm-p-x-imp-0">
          <label class="input-label dm-p-t-5"
            [ngClass]="{'disabled-text': isProjectContext || !unitsViewModelList || !unitsViewModelList?.length}">
            Filter By Project
          </label>
        </section>
        <section class="col-md-9 col-sm-9 dm-actuals__filter-section-dropdown input-dropdown dm-p-x-imp-0">
          <select aria-label="select project to filter" class="dm-p-x-imp-0" [(ngModel)]="selectedProject"
            [ngClass]="{'disabled-class': isProjectContext || !unitsViewModelList || !unitsViewModelList?.length}"
            [disabled]="isProjectContext">
            <option [ngValue]="project" *ngFor="let project of filterDataSource">
              {{project.displayName}}
            </option>
          </select>
          <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
        </section>
      </section>
      <div class="dm-no-data-section" *ngIf="!unitsViewModelList || !unitsViewModelList?.length">
        <dm-no-data *ngIf="!isUnitsBasedEngagement" [noDataText]="noActualsPostedText" [showImage]="true"></dm-no-data>
        <dm-no-data *ngIf="isUnitsBasedEngagement" [noDataText]="noActualsForUnitsEngagement" [showImage]="true">
        </dm-no-data>
      </div>
      <span class="sr-only" id="unitsTable">Units</span>
      <div role="grid" aria-labelledby="unitsTable" *ngIf="unitsViewModelList && unitsViewModelList?.length"
        class="dm-actuals__grid">
        <div role="rowgroup" class="actualsTable">
          <div role="row" class="actualsTable__row">
            <div role="gridcell" class="actualsTable__headerCell text-align--left">
              Line <br />Item
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--left">
              Unit Code / Name
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              Planned <br />Quantity
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              Unit Cost<br /> Rate ({{currencySymbol}})
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              Total Planned <br />Unit Cost ({{currencySymbol}})
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              Delivered <br />Quantity
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              Total Delivered <br /> Cost ({{currencySymbol}})
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right" id="dm-tooltip-actualQuantity">
              <dm-tooltip [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="'Actual delivered quantity for this period'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Actual Quantity">
              </dm-tooltip>
              Actual <br />Quantity
              <span class="sr-only">press tab to navigate to tooltip button</span>
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right display-block-imp">
              <span class="display-inline-block text-align--right">
                Delivered <br /> Effective Date
              </span>
              <dm-tooltip [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="'Use the Calendar symbol to enter Default Date for all Rows'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Use the Calendar symbol to enter Default Date for all Rows">
              </dm-tooltip>
              <div class="default-date-dropdown" ngbDropdown #setDefaultDateDropdown="ngbDropdown">
                <span class="icon icon-calendar cursor-pointer set-focus" id="dropdownBasic1" ngbDropdownToggle aria-haspopup="true" aria-expanded="true" role="button" aria-label="date picker" tabindex="0" (keyup.Escape)="setDefaultDateDropdown.close($event)" (keyup.enter)="setDefaultDateDropdown.toggle($event)"></span>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <div class="default-datepicker-title">Set Default Date for all rows</div>
                  <ngb-datepicker id="{{'datePicker_' + ariaLabelButton}}" [(ngModel)]="selectedDefaultDate"
                    [minDate]="ngbMinDate" [maxDate]="ngbMaxDate" [disabled]="disabled" navigation="select"
                    class="clearfix datepicker-main">
                  </ngb-datepicker>
                  <div class="display-flex dm-p-t-10">
                    <button type="button" class="dm-btn fxp-btn fxp-btn--primary" (click)="setDateToToday()"
                      (keydown)="setDateToToday($event)" [disabled]="disabled">
                      Today
                    </button>
                    <button class="dm-btn fxp-btn fxp-btn--primary dm-m-l-imp-20"
                      (keyup.enter)="setDefaultDate(selectedDefaultDate); setDefaultDateDropdown.close();"
                      (click)="setDefaultDate(selectedDefaultDate); setDefaultDateDropdown.close();">Set
                      Date</button>
                  </div>
                </div>
              </div>
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              % Completed
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              <dm-tooltip [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="'Invoiced Quantity is applicable for only T&M projects'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Invoiced Quantity is applicable for only T&M projects"></dm-tooltip>
              Invoiced <br />Quantity
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              <dm-tooltip [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="'Bill rate is applicable for only T&M projects'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Bill rate is applicable for only T&M projects"></dm-tooltip>
              Bill Rate ({{currencySymbol}})
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              <dm-tooltip [dmPlacement]="'bottom-left'"
                [ngbTooltipContent]="'Invoiced Amount is applicable for only T&M projects'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Invoiced Amount is applicable for only T&M projects"></dm-tooltip>
              Invoiced <br />Amount ({{currencySymbol}}) <br />
            </div>
            <div role="gridcell" class="actualsTable__headerCell text-align--right">
              Transaction History
            </div>
          </div>
          <div *ngIf="unitsViewModelList && unitsViewModelList?.length" class="actualsTable__dataGrid">
            <div
              *ngFor="let project of unitsViewModelList | filter: selectedProject ? {projectId : selectedProject?.id} : undefined; let projectIndex = index;  trackBy: trackByFunction">
              <div role="row" class="actualsTable__row actualsTable__projectDetailsRow">
                <div role="gridcell">
                  <span class="dm-p-r-15 pointer">
                    <button id="{{'projectCollapse' + project.projectId}}" type="button"
                      title="Collapse Project Details Section" aria-label="Project Details Section Expanded"
                      class="split-btn" (click)="toggleUnitProject(project, 'projectExpand')"
                      *ngIf="project?.isProjectExpanded">
                      <span class="icon icon-chevron-up"></span>
                    </button>
                    <button id="{{'projectExpand' + project.projectId}}" type="button"
                      title="Expand Project Details Section" aria-label="Project Details Section Collapsed"
                      class="split-btn" (click)="toggleUnitProject(project, 'projectCollapse')"
                      *ngIf="!project?.isProjectExpanded">
                      <span class="icon icon-chevron-down"></span>
                    </button>
                  </span>
                  <span>{{project?.projectName}}</span> | <span>{{project?.projectId}}</span>
                </div>
                <div role="gridcell" class="sr-only" *ngFor="let cell of arrayForRepeatingElement">
                  {{project?.projectName}} | {{project?.projectId}}
                </div>
              </div>
              <div *ngIf="project?.isProjectExpanded">
                <div *ngFor="let task of project?.tasks; let taskIndex = index; trackBy: trackByFunction">
                  <div role="row" class="actualsTable__taskDetailsRow actualsTable__row">
                    <div role="gridcell" class="dm-p-l-20">
                      <span class="dm-p-r-15 pointer">
                        <button id="{{'taskCollapse' + task.taskId}}" type="button"
                          title="Collapse task Details Section" aria-label="Task Details Section Expanded"
                          class="split-btn" (click)="toggleUnitTask(task, 'taskExpand')" *ngIf="task?.isTaskExpanded">
                          <span class="icon icon-chevron-up"></span>
                        </button>
                        <button id="{{'taskExpand' + task.taskId}}" type="button" title="Expand task Details Section"
                          aria-label="Task Details Section Collapsed" class="split-btn"
                          (click)="toggleUnitTask(task, 'taskCollapse')" *ngIf="!task?.isTaskExpanded">
                          <span class="icon icon-chevron-down"></span>
                        </button>
                      </span>
                      <span>EBS: {{task?.taskId}}</span> | <span> Task: {{task?.taskName}}</span>
                    </div>
                    <div role="gridcell" class="sr-only" *ngFor="let cell of arrayForRepeatingElement">
                      EBS: {{task?.taskId}} | Task: {{task?.taskName}}
                    </div>
                  </div>
                  <div class="actualsTable__body">
                    <ng-container *ngFor="let unit of task?.demands; let unitIndex = index; trackBy: trackByFunction">
                      <div *ngIf="task.isTaskExpanded" scope="row" role="row" class="actualsTable__row"
                        [ngClass]="{'demand-loading': unit.isDemandUpdateLoading}">
                        <div role="gridcell" class="actualsTable__bodyCell" [ngClass]="{'demand-error': unit.isDemandUpdateStatus === 'Error', 'demand-success' : unit.isDemandUpdateStatus === 'Success' }">
                          <span class="sr-only">Line item</span>
                          {{unitIndex + 1}}
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--left">
                          <span class="sr-only">unit code / Name</span>
                          USC: {{unit?.unitActivityTypeCode}} <br />
                          {{unit?.unitActivityTypeName}}{{!unit?.isBillable ? ' (Non-Billable)': ''}}
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Planned Quantity</span>
                          {{unit?.plannedQuantity | number:"1.2-2" }}
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Unit cost rate(EUR)</span>
                          {{unit?.unitCostRate | dmDisplayCurrencyOrDash: currencySymbol}}
                          <span *ngIf="!unit?.unitCostRate"
                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Total planned unit cost(EUR)</span>
                          {{unit?.totalPlannedCostRate | dmDisplayCurrencyOrDash: currencySymbol }}
                          <span *ngIf="!unit?.totalPlannedCostRate"
                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Delivered quantity</span>
                          {{unit?.deliveredQuantity | number:"1.2-2"}}
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Total delivered cost(EUR)</span>
                          {{unit?.totalActualCost | dmDisplayCurrencyOrDash: currencySymbol}}
                          <span *ngIf="!unit?.totalActualCost"
                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Actual Quantity</span>
                          <ng-container *ngIf="hasEditPermissions && !project.newInd"><input
                              id="{{project.projectId}}-{{task.taskId}}-consumed-quantity-{{unitIndex}}" min="0"
                              max="unit.plannedQuantity" type="number"
                              class="actualsTable__consumedQuantityInput text-align--right"
                              [(ngModel)]="unit.postingQuantity" (ngModelChange)="onInputChange(unit, $event)"
                              aria-label="enter actual quantity" />
                            <div role="alert" *ngIf="unit.isConsumedQuantityExceeded" class="error-msg w-675">
                              * Actual Quantity cannot be greater than planned quantity.
                              Please enter correct value.
                            </div>
                            <div role="alert" *ngIf="unit.isLessThanDeliveredQuantity" class="error-msg w-675">
                              * Actual Quantity cannot be less than delivered quantity.
                              Please enter correct value.
                            </div>
                          </ng-container>
                          <span *ngIf="!hasEditPermissions || project.newInd">{{unit?.deliveredQuantity | number:"1.2-2"}}</span>
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right dm-p-x-imp-0">
                          <span class="sr-only">Delivered Effective date</span>
                          <div class="actuals-datepicker text-align--right">
                            <dm-datepicker *ngIf="hasEditPermissions && !project.newInd" [minDate]="minDate" [maxDate]="maxDate"
                              [placeholder]="'dd-mm-yyyy'" [modelDate]="unit?.consumedDate"
                              ariaLabelButton="{{project?.projectId}}-{{task?.taskId}}-Start-date-{{unitIndex}}"
                              ariaLabelCalendar="{{project?.projectId}}-{{task?.taskId}}-Start-date-{{unitIndex}}"
                              (onDateChange)="onUnitDateChange(unit, $event)"
                              previosFousingElementId="{{project?.projectId}}-{{task?.taskId}}-consumed-quantity-{{unitIndex}}">
                            </dm-datepicker>
                            <span *ngIf="!hasEditPermissions || project.newInd">{{unit?.consumedDate | date:'dd-MMM-yyyy'}}</span>
                          </div>
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">% Completed</span>
                          <div class="display-grid">
                            <div>
                              {{unit?.consumedQuantityPercentage | number:'1.2-2'}} %
                            </div>
                            <div class="progress">
                              <div class="progress-bar bg-success" role="progressbar"
                                [ngStyle]="{'width': unit?.consumedQuantityPercentage + '%'}"
                                [attr.aria-valuenow]="unit?.consumedQuantityPercentage" aria-valuemin="0"
                                aria-valuemax="100" aria-label="Consumed Unit Percentage"></div>
                            </div>
                          </div>
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Invoiced Quantity</span>
                          {{unit?.totalInvoicedQuantity | number:"1.2-2" }}
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Bill Rate(EUR)</span>
                          {{unit?.billRate | dmDisplayCurrencyOrDash: currencySymbol}}
                          <span *ngIf="!unit?.billRate"
                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="actualsTable__bodyCell text-align--right">
                          <span class="sr-only">Invoiced Amount(EUR)</span>
                          {{unit?.totalInvoicedAmount | dmDisplayCurrencyOrDash: currencySymbol}}
                          <span *ngIf="!unit?.totalInvoicedAmount"
                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell"
                          class="actualsTable__bodyCell actions-holder icon-holder text-align--right">
                          <span class="sr-only">Transaction History</span>
                          <div class="dm-p-x-5">
                            <span *ngIf="unit.isDemandUpdateLoading"
                              class="glyphicon glyphicon-refresh glyphicon-spin"></span>
                            <dm-tooltip role="tooltip" class="dm-tooltipinline"
                              *ngIf="(!unit?.isDemandUpdateLoading && unit?.isDemandUpdateStatus === 'Error')"
                              [dmPlacement]="'left'" [ngbTooltipContent]="unit?.demandUpdateMessage"
                              [dmIcon]="'icon icon-warning'" ariaLabelToolTip="not updated successfully">
                            </dm-tooltip>
                            <dm-tooltip role="tooltip" class="dm-tooltipinline"
                              *ngIf="(!unit?.isDemandUpdateLoading && unit?.isDemandUpdateStatus === 'Success')"
                              [dmPlacement]="'left'" [ngbTooltipContent]="unit.demandUpdateMessage"
                              [dmIcon]="'icon icon-check icon-rounded-outline'" ariaLabelToolTip="updated successfully">
                            </dm-tooltip>
                          </div>
                          <!-- <div class="dm-p-x-5">
                            <a [title]="'Add notes'" class="icon-full icon-notes cursor-pointer"
                              (click)="openNotesModal(unit, task)"></a>
                          </div> -->
                          <div class="dm-p-x-5">
                            <button type="button" tabindex="0" role="button" class="transactions-icon-button set-focus" [title]="'View Unit Transaction Details'" (click)="openUnitTransactionsModal(unit, task)" (keyup)="handleKeyup($event)">
                              <a class="icon-full icon-more-vertical cursor-pointer"></a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section *ngIf="unitsViewModelList && unitsViewModelList.length" class="dm-actuals__footer">
        <button class="dm-p-x-12-y-imp-0 fxp-btn fxp-btn--primary" (click)="onSaveChangesClick()"
          [disabled]="this.actualsSaveBtnDisabled">
          <span>Save Changes</span>
        </button>
      </section>
    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
  </dm-tile>
</section>