export interface IAuditEntriesApiResponse { 
    forecastAuditEntries: IAuditResponse[];
    wbsAuditEntries: IAuditResponse[];
    riskReserveAuditEntries: IAuditResponse[];
}

export enum FinancialView { 
    ContractBaseline = "ContractBaseline",
    DeliveryBaseline = "DeliveryBaseline",
    CurrentFinancialPlan = "CurrentFinancialPlan",
    Actuals = "Actuals",
    ETC = "ETC",
    EAC = "EAC",
    ActualsCurrent = "ActualsCurrent",
    Forecast = "Forecast"
}


export enum WbsAuditType { 
    EngagementNameChange = "EngagementNameChange",
    EngagementDescriptionChange = "EngagementDescriptionChange",
    EngagementStatusChange = "EngagementStatusChange",
    EngagementAdditionalPJMAdded = "EngagementAdditionalPJMAdded",
    EngagementAdditionalPJMRemoved = "EngagementAdditionalPJMRemoved",
    EngagementPPJMUpdate = "EngagementPPJMUpdate"
}

export interface IAuditResponse { 
    id: string;
    wbsId: string;
    auditType: string;
    createdOn: string;
    createdBy: string;
    audit: IPlanForecastAuditValues | IRiskReserveAuditValues | IWbsAuditValues;
}

export interface IRiskReserveAuditValues {
    wbsWorkPackageId: string;
    currentRrAmount: string;
    previousRrAmount: string;
    fileId: string;
}

export interface IPlanForecastAuditValues { 
    version: string;
    margin: number;
    labor: number;
    cost: number;
    revenue: number;
    savedView: string;
}

export interface IPlanForecastAuditPayload { 
    version: string;
    margin: number;
    labor: number;
    cost: number;
    revenue: number;
    savedView: string;
    createdByAlias: string;
}

export interface IWbsAuditValues { 
    currentValue: string;
    previousValue: string;
    eventType: string;    
}

export interface IWbsAuditPayload { 
    currentValue: string;
    previousValue: string;
    eventType: WbsAuditType;
    createdByAlias: string;
}

export interface IPlanForecastSavedDataResponse {
    DealType: string;
    EventDate: string;
    Id: string;
    PlanVersion: string;
    SavedViews: string;
    actualCost: string;
    actualRevenue: string;
    cost: string;
    createdBy: string;
    eacCost: string;
    eacRevenue: string;
    eacMargin: string;
    eacLabor: string;
    labor: string;
    margin: string;
    revenue: string;
}