import { Component, EventEmitter, Input, Output, Inject, ViewChild, ElementRef, Renderer2, OnChanges, SimpleChanges, forwardRef } from "@angular/core";
import { debounceTime, distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { OneProfileService } from "../../../common/services/one-profile.service";
import { ICityDetails } from "../../../common/services/contracts/one-profile.contracts";
import { ComponentPrefix, SourceConstants } from "../../../common/application.constants";
import { DataService } from "../../../common/services/data.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { ErrorSeverityLevel } from "@fxp/fxpservices";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@Component({
    selector: "dm-type-ahead-city",
    templateUrl: "./type-ahead-city.html",
    styleUrls: ["./type-ahead-city.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DmTypeAheadCityComponent),
            multi: true
        }
    ]
})
export class DmTypeAheadCityComponent implements OnChanges, ControlValueAccessor {
    @Input() public btnSearchAriaLabelText: string;
    @Input() public btnCancelAriaLabelText: string;
    @Input() public typeAheadId: string;
    @Input() public typeAheadLabelText: string;
    @Input() public typeAheadCityValidationRequiredMessage: string;
    @Input() public selectedCountryCode: string;
    @Input() public selectedStateName: string;
    @Input() public selectedCity: string;
    @Input() public modelValue: string;
    @Input() public noResults: string;
    @Input() public isDisabled: boolean;
    @Input() public isRequired: boolean;
    @Input() public typeAheadPlaceholder: string;
    @Input() public typeaheadMinLength: number;
    @Output() public selectedCityUpdated: EventEmitter<any> = new EventEmitter();
    @ViewChild("typeAheadSearchCity", { static: false }) public searchInputText: ElementRef;
    public searchFailed: boolean;
    public searching: boolean;
    public errorMessage: string;
    public loadingCustomers: string;
    public onChange: (...args: any[]) => void;
    public onTouched: (...args: any[]) => void;
    @Input()
    public get value(): string {
        return this.modelValue;
    }
    public set value(val: string) {
        this.modelValue = val;
        this.onChange(val);
        this.onTouched();
    }

    private selectedCityProperty: string = "selectedCity";

    public constructor(
        @Inject(OneProfileService) protected oneProfileService: OneProfileService,
        @Inject(Renderer2) private renderer: Renderer2,
        @Inject(DMLoggerService) private dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) { }

    /**
     * If selectedCity value changes, then update typeahead model value.
     *
     * @param {SimpleChanges} changes
     * @memberof DmTypeAheadCityComponent
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes[this.selectedCityProperty]) {
            this.modelValue = changes[this.selectedCityProperty].currentValue;
        }
    }

    /**
     * Registers a callback function that should be called when
     * the control's value changes in the UI.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when
     * the control receives a blur event.
     *
     * Part of ControlValueAccessor interface.
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }


    /**
     * This is a basic setter that the forms API is going to use
     *
     * @param {*} value
     * @memberof DmTypeAheadCityComponent
     */
    public writeValue(value: string): void {
        if (value !== null && value !== undefined) {
            this.modelValue = value;
        }
    }

    public ngAfterViewInit(): void {
        if (this.searchInputText) {
            this.renderer.removeAttribute(this.searchInputText.nativeElement, "aria-multiline");
        }
    }

    /**
     * To clearing the input filed on clicking clear icon
     */
    public clearText(): void {
        this.modelValue = null;
        this.pullFocusToElement(this.typeAheadId, true);
        this.validateInput();
        this.selectedCityUpdated.emit(this.modelValue);
    }

    /**
     * Validating input
     */
    public validateInput(): void { // Called on every input, including inputs less than 3 chars
        this.errorMessage = this.getErrorMessage(this.modelValue ? this.modelValue : "");
    }

    /**
     * Search list on typing input
     * @param text$
     */
    public search = (text$: Observable<string>): Observable<any> =>
        text$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term) => term.length < 3 ? []
                : this.getCityList(term)
            ),
            tap(() => this.searching = false)
        );

    /**
     * getCitiesList
     * @param searchValue
     */
    public getCityList(searchValue: string): Promise<ICityDetails[]> {
        this.searching = true;
        this.setHasResults();
        if (this.selectedCity) {
            this.selectedCity = "";
        }
        if (!this.getErrorMessage(searchValue)) {
            return this.oneProfileService.getCitiesBasedOnCountryCodeStateName(this.selectedCountryCode, this.selectedStateName)
                .then((cityDetails: ICityDetails[]) => {
                    this.searchFailed = false;
                    cityDetails = cityDetails.filter((city) => city.CityName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1).slice(0, 10);
                    cityDetails.length > 0 ? this.setHasResults() : this.setNoResults(searchValue);
                    return cityDetails;
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.searchFailed = true;
                    this.searching = false;
                    this.setNoResults(searchValue);
                    this.dmLogger.logError(ComponentPrefix + "Type-Ahead-City", SourceConstants.Method.GetCityList, error, errorMessage, null, undefined, DataService.getCorrelationIdFromError(error), ErrorSeverityLevel && ErrorSeverityLevel.High);
                    return [];
                });
        }
    }


    /**
     * Selecting item on customer select
     * @param item
     */
    public onCitySelect(item: {item: ICityDetails}): void {
        const selectitem = item.item as ICityDetails;
        this.modelValue = selectitem.CityName;
        this.selectedCityUpdated.emit(this.modelValue);
    }

    /**
     * Formatting customer input on selection
     * @param x
     */
    public formatter = (x: ICityDetails): string => {
        if (!x.CityName || x.CityName.toLowerCase() === "select") {
            return "";
        } else {
            return x.CityName;
        }
    };

    /**
     * Get and Return error message
     * @param value
     */
    private getErrorMessage(value: string): string {
        if (!value) {
            return this.typeAheadCityValidationRequiredMessage;
        }
        return "";
    }

    /**
     * Set no results text
     * @param searchText
     */
    private setNoResults(searchText: string): void {
        this.errorMessage = "No results found for '" + searchText + "'";
    }

    /**
     * Set has results text
     */
    private setHasResults(): void {
        this.errorMessage = "";
    }

    /**
     * Pull focus to search list on data load
     * @param id
     * @param isTimeOut
     */
    private pullFocusToElement(id: string, isTimeOut: boolean): void {
        if (isTimeOut) {
            setTimeout(() => {
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.focus();
                }
            });
        } else {
            const element = window.document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    }

}
