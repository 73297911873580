import { Component, Input, Inject } from "@angular/core";
import { DmComponentAbstract } from "../../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { AccessibilityConstants, Components } from "../../../../common/application.constants";
import { IFinancialChangeRequestDetails } from "../../../../common/services/contracts/changerequestv2.contract";

@Component({
    selector: "dm-fcr-labor-view-details",
    templateUrl: "./fcr-labor-view-details.html",
    styleUrls: ["./fcr-labor-view-details.scss"]
})
export class FcrLaborViewDetailsComponent extends DmComponentAbstract {
    @Input() public planCurrency: string;
    @Input() public fcrLaborViewDetails: IFinancialChangeRequestDetails;
    public accessibilityConstants = AccessibilityConstants;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService) {
        super(dmLogger, Components.FcrDetails);
    }

    public ngOnInit(): void {
        this.isComponentLoading = false;
    }
}