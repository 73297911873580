<section class="financials-modal">
  <label class="sr-only" aria-label="Financial Overview"></label>
  <section class="modal-header">
    <button type="button" aria-label="Financials Summary Dialog Close" class="close set-focus" (click)="closeModal()"
      id="closeBulkIntEngStatusPopUp" [title]="'Financials Summary Dialog Close'">
      <span aria-hidden="true" class="icon icon-close"></span>
    </button>
    <h4 class="modal-title font-subtitle pull-left">
      Financial Summary
    </h4>
  </section>
  <dm-loading [loadingText]="loadingText" [errorText]="errorText" [showLoading]="showLoading"
    *ngIf="isComponentLoading">
  </dm-loading>
  <section class="modal-body" *ngIf="!isComponentLoading">
    <ng-container *ngFor="let engagement of engagementFinancialList; let index = index;">
      <section class="col-sm-12 col-md-12 dm-p-x-0 dm-financial-summary-details-section">
        <div class="engagement-details-info dm-p-10">
          <div class="display-grid pull-left">
            <div class="font-caption-alt">
              <b>
                Engagement:
                <a [uiSref]="RouteName.InternalEngagementSummary" [uiParams]="{engagementId: engagement.id}"
                  [id]="setIdForEntityName('engagement', index)" (click)="closeModal()" class="dm-link">
                  {{engagement.name}}
                </a>
              </b>
            </div>
            <div class="font-caption-alt">
              EBS:
              <span>{{engagement.id}}</span>
            </div>
          </div>
          <div class="display-flex-imp font-caption-alt pull-right">
            <span class="icon-full icon-currency"></span>
            <div class="dm-p-x-10">Currency</div>
            <span><b>{{engagement.plannedCurrency}}</b></span>
          </div>
        </div>

        <section class="col-sm-6 left-section">
          <div class="dm-financial-summary-details-row font-caption-alt">
            <div><b>Planned Labor(Hours)</b></div>
            <div class="justify-right">{{engagement.labor | dmDisplayDashIfEmptyOrNull}}</div>
          </div>
          <div class="dm-financial-summary-details-row font-caption-alt">
            <div><b>Actual Cost</b></div>
            <div class="justify-right"> {{engagement.actualCost | dmDisplayCurrencyOrDash : engagement.plannedCurrency}}
            </div>
          </div>
        </section>

        <section class="col-sm-6 right-section">
          <div class="dm-financial-summary-details-row font-caption-alt">
            <div><b>Planned Cost</b></div>
            <div class="justify-right">{{engagement.plannedCost | dmDisplayCurrencyOrDash: engagement.plannedCurrency}}
            </div>
          </div>
          <div class="dm-financial-summary-details-row font-caption-alt">
            <div><b>Actual Labor(Hours)</b></div>
            <div class="justify-right">{{engagement.actualHours | dmDisplayDashIfEmptyOrNull}}</div>
          </div>
        </section>
      </section>
    </ng-container>
  </section>
</section>