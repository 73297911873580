import { CommonComponentsModule } from "../../entry/common-components.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PlanForecastDetailsComponent } from "./plan-forecast-details.component";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { LaborDetailsModule } from "../forecast-details/labor-details/labor-details.module";
import { ForecastNavigationBarModule } from "../forecast-navigation-bar/forecast-navigation-bar.module";

@NgModule({
    declarations: [
        PlanForecastDetailsComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        ForecastNavigationBarModule,
        LaborDetailsModule
    ],
    providers: [
        DMLoggerService
    ],
    entryComponents: [
        PlanForecastDetailsComponent
    ],
    exports: [
        PlanForecastDetailsComponent
    ]
})
export class PlanForecastDetailsModule { }
