<div class="interstital-experience">
    <label class="sr-only">{{popUpMessage}}</label>
    <div class="modal-header modal-noborder">
        <h4 class="modal-title font-subtitle pull-left">{{popUpMessage}}</h4>
        <button type="button" title="Close" class="close set-focus" aria-label="View Workbook Dialog Close" (click)="closeInterstitialModal()" id="accessibilityConstants.CloseUpdateButton" (keydown)="moveFocusPrev($event, 'accessibilityConstants.Continue')">
            <span aria-hidden="true" class="icon icon-close"></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="font-body unplanned-description">
            Steps required to access the financial workbooks:
        </div>
        
        <table cellpadding="5" class="interstitial-noinvoices">
            <tr>
                <td class="dm-financial-table-td-paddingtop">1.</td>
                <td class="text dm-financial-table-td dm-financial-table-td-paddingtop">SAP Access – request SAP access through
                        <button type="button" role="link"
                                class="dm-link dm-tile--p-b-20 interstitial-noinvoices--button oper-req-btn set-focus"
                                (click)="showOpsRequest()">
                                Operations Requests
                        </button>
                        Please select request type "SAP User Account Request"
                </td>
            </tr>
            <tr>
                <td class="dm-financial-table-td-paddingtop" valign="top">2.</td>
                <td class="text dm-financial-table-td dm-financial-table-td-paddingtop">Install required software components – <a class="dm-link" [href]="installerLink" target="_blank" aria-label="open link in new tab">Required components check and installer</a></td>
            </tr>
        </table>
        
        <div class="dm-financial-edit-popup">
            <span class="text">Step-by-step instructions to manually install the required software components are located <a class="dm-link" [href]="stepsLink" target="_blank" aria-label="open link in new tab">here.</a></span>    
                <br/>    
                <br/>  
            <span class="text"><a class="dm-link" [href]="fullStepsLink" target="_blank" aria-label="open link in new tab">Full step by step instructions</a> are located in the ESXP Help</span>      
        </div>
        <br/>
        <section *ngIf="isNewAOInstallerAvail" class="interstitial-warning">
            <section class="row">
                <section class="col-md-1">
                    <p class="font-title"><span class="icon icon-warning"></span></p>
                </section>
                <section class="col-md-11">
                    <p>
                        A new version of SAP Analysis for office is available as of {{updatedAOVersionNotificationFrom}}. Please re-run the <a class="dm-link" [href]="installerLink" target="_blank" aria-label="open link in new tab">Required components check and installer</a>
                    </p>
                </section>
            </section>
        </section>
    </div>
    <div class="modal-footer">
        <button type="button" value="cancel" id="accessibilityConstants.Cancel" class="fxp-btn fxp-btn--secondary" (click)="closeInterstitialModal()">
            Cancel
        </button>
        <button type="button" value="Continue" class="fxp-btn fxp-btn--primary" id="accessibilityConstants.Continue" (click)="continue()" (keydown)="moveFocusNext($event, 'accessibilityConstants.CloseUpdateButton')">
            Continue
        </button>
    </div>
</div>