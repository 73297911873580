import { Component, forwardRef, Inject, Input, Output, EventEmitter } from "@angular/core";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DeviceFactoryProvider } from "@fxp/fxpservices";
import { DmModalAbstract } from "../../../common/abstraction/dm-modal.abstract";
import { AccessibilityConstants, BulkMaintenanceStatus, Components, RouteName } from "../../../common/application.constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IBulkMaintenanceStatus, IBulkMaintenanceStatusResponse, IBulkRoleMaintenanceDocument, IResourceRequestSearchInput, IResourceRequestSearchOutput } from "../../../common/services/contracts/bulk-maintenance.service.contract";
import { IModal } from "../../modals/dm-modal-v2/dm-modal-v2.component";
import { DmGrmService } from "../../../common/services/dmgrm.service";
import { INotification } from "../../../common/services/contracts/notification-bar.contracts";

@Component({
    selector: "dm-bulk-maintenance-status-modal",
    templateUrl: "./bulk-maintenance-status-modal.html",
    styleUrls: ["./bulk-maintenance-status-modal.scss"]
})
export class BulkMaintenanceStatusModalComponent extends DmModalAbstract {
    @Input() public bulkMaintenanceResponse: IBulkMaintenanceStatusResponse;
    @Input() public notificationDetail: INotification;
    @Output() public onStatusModalClose: EventEmitter<void> = new EventEmitter<void>();
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */
    public accessibilityConstants = AccessibilityConstants;
    public searchResponse: IBulkRoleMaintenanceDocument[];
    public modalContent: IModal;
    public loadingText: string;
    public BulkMaintenanceStatus = BulkMaintenanceStatus;
    private resourceRequestIds: string[];

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DmGrmService) private grmService: DmGrmService,
    ) {
        super(activeModal, dmLogger, Components.BulkCreateInternalEngagementStatusModal);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "Bulk Role Maintenance Status Summary",
            subTitle: "EBS: " + this.notificationDetail.entityId + "  |  " + this.notificationDetail.entityDescription
        };
        this.loadingText = "Loading Role Maintnance Summary";
        if (this.bulkMaintenanceResponse && this.bulkMaintenanceResponse.bulkProcessItems && this.bulkMaintenanceResponse.bulkProcessItems.length > 0) {
            this.resourceRequestIds = this.bulkMaintenanceResponse.bulkProcessItems.map((r) => r.entityId);
        }
        const filterString: string =
            "search.in(ResourceRequestId," +
            "'" +
            this.resourceRequestIds.join(",").toString() +
            "',',')";
        const searchInputPayload: IResourceRequestSearchInput = {
            select: [
                "ResourceRequestId",
                "AssignedResourceName",
                "Role"
            ],
            filter: filterString
        };
        this.grmService.searchResourceRequests(searchInputPayload)
            .then((response: IResourceRequestSearchOutput) => {
                if (response && response.documents && response.documents.length) {
                    this.searchResponse = response.documents;
                    for (const resource of this.searchResponse) {
                        const resourceStatus = this.bulkMaintenanceResponse.bulkProcessItems.filter((r) => r.entityId === resource.ResourceRequestId);
                        if (resourceStatus.length > 0) {
                            resource.Status = resourceStatus[0].status;
                            const statusDetails: IBulkMaintenanceStatus = JSON.parse(resourceStatus[0].response);
                            if (statusDetails) {
                                resource.IsSuccess = statusDetails.IsSuccess;
                                resource.ResourceRequestErrorMessage = statusDetails.ErrorMessage;
                            }
                        }
                    }
                }
            });
        this.setLoadersBasedOnItemState();
    }

    /**
     * Copy failure message to the clipboard
     */
    public copyMessage(): void {
        this.sharedFunctionsService.copyToClipboard("creationErrorDetailsPopoverMsg");
    }
}

