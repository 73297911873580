import { Inject, Input, Component } from "@angular/core";
import { UserInfoService, FxpEventBroadCastService } from "@fxp/fxpservices";
import { Store } from "@ngrx/store";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { Components, BroadcastEvent } from "../../../common/application.constants";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { IState } from "../../../store/reducers";
import { getWriteOperationNotificationByInstanceId } from "../../../store/write-operation-notifications/write-operation-notifications.selector";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IProcessNotification } from "../../../common/services/contracts/process-notifications.contracts";
import { InvalidateFinancialDetailsV2 } from "../../../store/financial-details-v2/financial-details-v2.action";
import { InvalidateEngagementDetails } from "../../../store/engagement-details/engagement-details.action";
import { ProjectServiceFunctions } from "../../../common/services/projectservice-functions.service";
import { InvalidateWbsStructures } from "../../../store/wbs-structures/wbs-structures.action";
import { NotificationType } from "../../../common/services/contracts/notification-bar.contracts";
import { UpdateWriteOperationNotifications } from "../../../store/write-operation-notifications/write-operation-notifications.action";
import { LoadMyPortfolioEngagements } from "../../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.action";
import { RemoveNotification } from "../../../store/notification-subscriptions/notification-subscriptions.action";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import moment from "moment";
import { AuditService } from "../../../common/services/audit.service";
import { IWbsAuditPayload, WbsAuditType } from "../../../common/services/contracts/audit.contracts";

const CREATED_STATUS = "CREATED";
const RELEASED_STATUS = "RELEASED";
@Component({
    selector: "dm-release-activate-notification",
    templateUrl: "./release-activate-notification.html",
    styleUrls: ["./release-activate-notification.scss"]
})
export class ReleaseActivateNotificationComponent extends DmComponentAbstract {
    @Input() public subscriptionId: string;
    @Input() public entityId: string;

    public operation: IProcessNotification;
    public timeAgo: string;
    public showCloseOption: boolean;
    private loggedInUserData: any;

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(Store) private store: Store<IState>,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(FxpEventBroadCastService) private fxpBroadCastService: FxpEventBroadCastService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(AuditService) private auditService: AuditService
    ) {
        super(dmLogger, Components.ReleaseAndActivateNotification);
    }

    public ngOnInit(): void {
        this.loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        this.notificationService.subscribeToBulkInternalEngagementStatus({ GroupName: this.subscriptionId });

        // make get call for status
        this.configManagerService.initialize().then(() => {
            this.notificationService.getNotificationStatus(this.subscriptionId, NotificationType.ReleaseAndActivate).then((notification: IProcessNotification) => {
                if (notification) {
                    this.showCloseOption = true;
                    this.store.dispatch(new UpdateWriteOperationNotifications(notification));
                    this.refreshReleaseAndActivateDone(notification, this.operation);
                    const oldOperationsObject = this.operation;
                    this.operation = notification;

                    if (this.operation && this.operation.LastUpdateTime) {
                        const stillUtc = moment.utc(this.operation.LastUpdateTime).toDate();
                        this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                    }

                    if (!oldOperationsObject || !oldOperationsObject.isItemExpanded || (!oldOperationsObject.Done && this.operation.Done)) {
                        this.operation.isItemExpanded = false;
                    } else {
                        this.operation.isItemExpanded = oldOperationsObject.isItemExpanded;
                    }
                } else {
                    this.store.dispatch(new RemoveNotification(this.loggedInUserData.alias, this.subscriptionId));
                }
            });
        });

        const notifications$ = this.store.select(getWriteOperationNotificationByInstanceId(this.subscriptionId));
        notifications$.pipe(untilDestroyed(this)).subscribe((releaseAndActivateNotification: IProcessNotification) => {
            if (releaseAndActivateNotification) {
                this.showCloseOption = true;
                this.refreshReleaseAndActivateDone(releaseAndActivateNotification, this.operation);
                const oldOperationsObject = this.operation;
                this.operation = releaseAndActivateNotification;

                if (this.operation && this.operation.LastUpdateTime) {
                    const stillUtc = moment.utc(this.operation.LastUpdateTime).toDate();
                    this.timeAgo = moment(stillUtc).local().startOf("minute").fromNow();
                }

                if (!oldOperationsObject || !oldOperationsObject.isItemExpanded || (!oldOperationsObject.Done && this.operation.Done)) {
                    this.operation.isItemExpanded = false;
                } else {
                    this.operation.isItemExpanded = oldOperationsObject.isItemExpanded;
                }
            }
        });
    }

    /**
     * Close and remove notification subscription for the current user.
     *
     * @memberof ReleaseActivateNotificationComponent
     */
    public closeNotification(): void {
        this.showCloseOption = false;
        const loggedInUserAlias = this.fxpUserInfoService.getCurrentUserData().alias;
        this.notificationService.deleteNotificationSubscription(loggedInUserAlias, this.subscriptionId);
    }

    /**
     * Toggle whether notification is collapsed or expanded.
     *
     * @memberof ReleaseActivateNotificationComponent
     */
    public toggleProcessNotificationItem(): void {
        this.operation.isItemExpanded = !this.operation.isItemExpanded;
    }

    /**
     * Restart the orchestration function associated with operationId
     */
    public restartOperation(): void {
        this.projectServiceFunction.orchestrateReleaseAndActivate(
            this.loggedInUserData.alias,
            this.loggedInUserData.BusinessPartnerId,
            this.operation.WbsId,
            this.operation.WbsName,
            this.subscriptionId).then((data: string) => data);
    }

    private refreshReleaseAndActivateDone(newOperation: IProcessNotification, oldOperation: IProcessNotification): void {
        if (newOperation && oldOperation && newOperation.InstanceId === oldOperation.InstanceId) {
            if (newOperation.Done && !oldOperation.Done) {
                this.store.dispatch(new InvalidateFinancialDetailsV2(newOperation.WbsId));
                this.store.dispatch(new InvalidateEngagementDetails(newOperation.WbsId));
                this.store.dispatch(new InvalidateWbsStructures(newOperation.WbsId));
                this.store.dispatch(new LoadMyPortfolioEngagements(false));
                this.fxpBroadCastService.emit(BroadcastEvent.RefreshPlanAndForeCast, { column: "CurrentFinancialPlan" });
            }
            if (newOperation.Error && !oldOperation.Error) {
                this.fxpBroadCastService.emit(BroadcastEvent.ShowReleaseAndActivate, { engagementId: newOperation.WbsId });
            }
            if (!newOperation.Done && newOperation.Attempt === 3 && oldOperation.Attempt === 2) {
                /// if its a third attempt for retry send an email notification     
                this.fxpBroadCastService.emit(BroadcastEvent.SendFailureNotification, { engagementId: newOperation.WbsId });
            }
        }
    }

    /**
     * Logs Engagement status to Audit    
     */
    private logReleaseActivateToAudit(wbsId: string): void {
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        const logDetails: IWbsAuditPayload = {
            currentValue: CREATED_STATUS,
            previousValue: RELEASED_STATUS,
            eventType: WbsAuditType.EngagementStatusChange,
            createdByAlias: loggedInUserData.alias
        };
        this.auditService.postWbsAuditItem(wbsId, logDetails);
    }
}
