import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { NavBarNotificationComponent } from "./nav-bar-notification.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { KeyActionsV2Module } from "../key-actions-v2/key-actions-v2.module";
import { ActivityFeedModule } from "../activity-feed/activity-feed.module";


@NgModule({
    declarations: [
        NavBarNotificationComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        UIRouterModule,
        NgbModule,
        KeyActionsV2Module,
        ActivityFeedModule
    ],
    providers: [        
        SharedFunctionsService,
        DMLoggerService
    ],
    entryComponents: [
        NavBarNotificationComponent
    ],
    exports: [
        NavBarNotificationComponent
    ]
})
export class NavBarNotificationModule { }
