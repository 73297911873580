import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { SyncEACToPlanSubmitComponent } from "./sync-eac-to-plan-submit.component";

@NgModule({
    declarations: [
        SyncEACToPlanSubmitComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule
    ],
    providers: [],
    entryComponents: [
        SyncEACToPlanSubmitComponent
    ],
    exports: [
        SyncEACToPlanSubmitComponent
    ],
})
export class SyncEACToPlanSubmitModule { }
