<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'200px'" [tileHeight]="'200px'">
    <dm-loading loader *ngIf="isComponentLoading" [loadingText]="'Links'" [showLoading]="true">
    </dm-loading>
    <div *ngIf="!isComponentLoading" class="key-links">   
        <section class="col-lg-6 col-md-6 col-sm-12 col-xs-12 dm-p-l-imp-0">
            <h4 class="font-caption-alt dm-tile--m-b-12">Standard Links</h4>
            <ul class="dm-m-0">
                <li class="col-md-4 col-sm-4 col-sm-4 dm-p-l-imp-0 dm-p-b-12 dm-tile--p-r-10 dm-tile--p-b-16-mobile dm-tile--m-b-0-mobile"
                    *ngFor="let pl of projectManagementKeyLinks">
                    <a [href]="pl.link" target="_blank" class="dm-link font-caption-alt" aria-label="open link in new tab"
                        *ngIf="pl.name !== 'SAFE Limits'" (click)="logEvent(pl.name)">{{pl.name}}</a>
                </li>
            </ul>
        </section>
        <section class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h4 class="font-caption-alt dm-tile--m-b-12">Custom Links
                <button *ngIf="hasEditPermissions" class="action-trigger action-trigger--edit-state" (click)="openUserPreferenceLinksModal()">
                    <span class="icon icon-editLegacy" role="button" aria-label="Edit custom links."></span>
                </button>
            </h4>
            <ul class="dm-m-0">
                <li class="col-md-4 col-sm-4 col-sm-4 dm-p-l-imp-0 dm-tile--p-r-10 dm-p-b-12 dm-tile--p-b-16-mobile dm-tile--m-b-0-mobile"
                    *ngFor="let pl of userPreferenceLinks">
                    <a [href]="pl.url" target="_blank" class="dm-link font-caption-alt" aria-label="open link in new tab"
                        (click)="logEvent(pl.name)">{{pl.name}}
                    </a>
                </li>
            </ul>
        </section>
        <section class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="isInternalEngagement && !this.showNBUELinks">
            <h4 class="font-caption-alt dm-tile--m-b-12">Project NBUE Documents</h4>
            <ul class="dm-m-0">
                <li class="col-md-4 col-sm-4 col-sm-4 dm-p-l-imp-0 dm-tile--p-r-10 dm-p-b-12 dm-tile--p-b-16-mobile dm-tile--m-b-0-mobile"
                    *ngFor="let nbueLink of projectNBUELinks">
                    <a [href]="nbueLink.link" target="_blank" class="dm-link font-caption-alt" aria-label="open link in new tab"
                        (click)="logEvent(nbueLink.name)">
                        {{getNbueDocName(nbueLink.name)}}
                    </a>
                </li>
                <li *ngIf="!projectNBUELinks.length && !nbueError"
                    class="dm-p-l-imp-0 dm-tile--p-r-10 dm-p-b-12 dm-tile--p-b-16-mobile dm-tile--m-b-0-mobile">
                    <span class="font-caption-alt">
                        {{keyLinksErrorMessage.NoNBUEDocuments}}
                    </span>
                </li>
                <li *ngIf="nbueError"
                    class="dm-p-l-imp-0 dm-tile--p-r-10 dm-p-b-12 dm-tile--p-b-16-mobile dm-tile--m-b-0-mobile">
                    <span role="alert" sria-atomic="true" aria-live="polite" class="font-caption-alt error-msg">
                        <i class="icon icon-error"></i>{{keyLinksErrorMessage.ProblemFetchingNBUEdocuments}}
                    </span>
                </li>
            </ul>
        </section>
    </div>
</dm-tile>