
import { combineLatest as observableCombineLatest, Observable } from "rxjs";
import { Component, Inject, forwardRef, Input, Injector } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FxpMessageService, FxpConstants, ErrorSeverityLevel } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { Store } from "@ngrx/store";
import { Components, RouteName, FinancialType, InlineSurveyDataList, FeedBackEntity, SourceConstants, LogEventConstants, FilterValue, NoDataText } from "../../common/application.constants";
import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FinancialService } from "../../common/services/financial.service";
import { getChangeRequestsState } from "../../store/change-requests/change-requests.selector";
import { getEntireFinancialDetailsV2 } from "../../store/financial-details-v2/financial-details-v2.selector";
import { IChangeRequest } from "../../common/services/contracts/changerequest.contract";
import { IChangeRequestsState } from "../../store/change-requests/change-requests.reducer";
import { IEntityFinancialSummary } from "../financial-mgmt/financial.model";
import { IFinancialDetailsV2State } from "../../store/financial-details-v2/financial-details-v2.reducer";
import { IState, ILoadableState } from "../../store/reducers";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { StoreDispatchService } from "../../common/services/store-dispatch.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ISurveyData } from "../shared/inline-survey/inline-survey.component";
import { FeedbackModalService } from "./../tiles/feedback-modal/feedback-modal.service";
import { OneProfileService } from "../../common/services/one-profile.service";
import { IOneProfileAPIResponse } from "../../common/services/contracts/one-profile.contracts";
import { getEntireEngagementDetails } from "../../store/engagement-details/engagement-details.selector";
import { DMAuthorizationService } from "../../common/services/dmauthorization.service";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { DmError } from "../../common/error.constants";
import { getEngagementChangeRequestsV2State } from "../../store/engagement-change-requests-v2/engagement-change-requests-v2.selector";
import { ChangeRequestService } from "../../common/services/change-request.service";
import { IEngagementDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";

@Component({
    selector: "dm-financial-plan-approvals",
    templateUrl: "./financial-plan-approvals.html",
    styleUrls: ["./financial-plan-approvals.scss"]
})
export class FinancialPlanApprovalsComponent extends DmComponentAbstract {
    /**
     * This is used to decide whether this component is loading as a child component
     *
     * @type {boolean}
     * @memberof FinancialPlanApprovalsComponent
     */
    @Input() public isChild: boolean = false;
    public engagementId: string;
    public projectId: string;
    public enableCrCreation: boolean = false;
    public RouteName = RouteName; /* Set without a type because we can't add type to the namespace */

    public activeTab: string = "PlannedLabor";
    public filterBy: string = "";
    public loadingText: string = "Loading Financial Change Requests";
    public engagementDetails: IEngagementDetailsV2;
    public filterByOptions: string[] = [];
    public filteredApprovals: IChangeRequest[] = [];
    public fvrSurveyModalRef: NgbModalRef;
    public surveyData: ISurveyData;
    public isProjectContext: boolean;
    public isServerError: boolean;
    public toolTipErrorMessage = DmError.ServerErrorMessages.FcrDetails;
    public noFCRText = NoDataText.NoFinancialChangeRequests;
    public tileTitle: string;
    public tileContent: ITile;
    public projectsWithPendingChangeRequests: string[] = [];

    private approvals: IChangeRequest[] = [];
    private currentFinancialDetails: IEntityFinancialSummary;
    private readonly FXP_CONSTANTS = FxpConstants;

    public constructor(
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) private stateService: StateService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(Store) private store: Store<IState>,
        @Inject(StoreDispatchService) private storeDispatchService: StoreDispatchService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(FinancialService) private financialService: FinancialService,
        @Inject(FeedbackModalService) private feedbackModalService: FeedbackModalService,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(Injector) private injector: Injector,
        @Inject(DMAuthorizationService) private dmAuthorizationService: DMAuthorizationService,
        @Inject(ChangeRequestService) private changeRequestService: ChangeRequestService
    ) {
        super(dmLogger, Components.FinancialsApprovals);
    }

    public ngOnInit(): void {
        this.configManagerService.initialize().then(() => {
            this.projectId = this.sharedFunctionsService.getSelectedProjectId(this.stateService);
            this.engagementId = this.sharedFunctionsService.getSelectedEngagementId(this.stateService);
            let financialDetails$: Observable<IFinancialDetailsV2State>;
            this.isProjectContext = !!this.projectId;
            this.tileTitle = this.isChild ? "Financial Change Requests" : "My Approvals";
            this.tileContent = {
                title: this.tileTitle,
                link: { name: "Learn more", url: "https://aka.ms/pjm-job-aid/request-nbue", icon: "icon-education", tooltipText: "Learn more about requesting 'Non-Billable Utilization Eligible' NBUE Hours" }
            };
            const observables$: Array<Observable<ILoadableState>> = [];

            let changeRequests$: Observable<IChangeRequestsState>;
            if (this.engagementId) {
                this.storeDispatchService.requireEngagementChangeRequestsV2(this.engagementId, true).load();
                changeRequests$ = this.store.select(getEngagementChangeRequestsV2State(this.engagementId));
            } else {
                this.storeDispatchService.requireChangeRequests(true).load();
                changeRequests$ = this.store.select(getChangeRequestsState);
            }
            observables$.push(changeRequests$);

            changeRequests$.pipe(untilDestroyed(this)).subscribe((changeRequestsState: IChangeRequestsState) => {
                if (changeRequestsState.loaded && changeRequestsState.changeRequests) {
                    const changeRequests = changeRequestsState.changeRequests;
                    this.approvals = [];
                    this.filteredApprovals = [];
                    this.filterBy = "";
                    for (const changeRequest of changeRequests) {
                        if (changeRequest.statusDescription) {
                            changeRequest.showProgressBar = changeRequest.statusDescription.toLowerCase() !== "canceled" && changeRequest.statusDescription.toLowerCase() !== "fvr approved" && changeRequest.statusDescription.toLowerCase() !== "fvr rejected";
                            changeRequest.uiStatusDescription = changeRequest.statusDescription.toUpperCase().indexOf("FVR ") !== -1 ? changeRequest.statusDescription.replace("FVR ", "") : changeRequest.statusDescription;
                        }
                        if (!changeRequest.requestorName && changeRequest.requestorAlias) {
                            this.oneProfileService.getProfile(changeRequest.requestorAlias).then((response: IOneProfileAPIResponse) => {
                                changeRequest.requestorName = response.DisplayName;
                            }).catch((error) => {
                                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                                this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                                if (error.status === 404) {
                                    Promise.resolve();
                                }
                            });
                        }
                        this.approvals.push(changeRequest);

                        if (this.filterByOptions.indexOf(changeRequest.statusDescription) === -1) {
                            this.filterByOptions.push(changeRequest.statusDescription);
                        }

                    }
                    // Sort the list by Date, ties should consider CR ID in descending order
                    this.approvals.sort((a, b) => {
                        if (a.createdOn && b.createdOn) {
                            if (a.createdOn === b.createdOn) {
                                return +b.crNumber - +a.crNumber;
                            } else {
                                return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
                            }
                        }
                        return 0;
                    });

                    this.filteredApprovals = this.approvals;
                }
            });

            if (this.engagementId) {
                if (this.isProjectContext) {
                    financialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.projectId));
                } else {
                    financialDetails$ = this.store.select(getEntireFinancialDetailsV2(this.engagementId));
                }
                const engagementDetails$ = this.store.select(getEntireEngagementDetails(this.engagementId));

                this.storeDispatchService.requireEngagementDetails(this.engagementId, true);

                observables$.push(financialDetails$, engagementDetails$);

                financialDetails$.pipe(untilDestroyed(this)).subscribe((financialDetails: IFinancialDetailsV2State) => {
                    if (financialDetails.loaded) {
                        this.currentFinancialDetails = this.financialService.getFinancialDetailsFromParentForV2Object(financialDetails.financialDetails, FinancialType.CurrentFinancialPlan);
                    }
                });
                observableCombineLatest(changeRequests$, financialDetails$, engagementDetails$)
                    .pipe(untilDestroyed(this))
                    .subscribe(([changeRequestsState, financialDetailsState, engagementState]) => {
                        if (financialDetailsState.loaded && changeRequestsState.loaded && engagementState.loaded) {
                            this.engagementDetails = engagementState.engagementDetails;
                            let isCurrentUserPartOftheTeam = this.dmAuthorizationService.isUserInEngagementLevelTeam(engagementState.engagementDetails);

                            if (!isCurrentUserPartOftheTeam) { // Check if user is part of any Project
                                for (const projectId of engagementState.engagementDetails.projects) {
                                    if (this.dmAuthorizationService.isUserInProjectLevelTeam(projectId)) {
                                        isCurrentUserPartOftheTeam = true;
                                        break;
                                    }
                                }
                            }

                            if (isCurrentUserPartOftheTeam) {
                                this.checkIfNewCrCanBeCreated();
                            }
                        }
                        if (changeRequestsState.error || financialDetailsState.error || engagementState.error) {
                            this.isServerError = true;
                        }
                    });
            }

            observableCombineLatest(observables$).pipe(untilDestroyed(this)).subscribe((observables: ILoadableState[]) => {
                this.refreshOnItemInvalidation(...observables);
                this.setLoadersBasedOnItemState(...observables);
                this.setErrorsBasedOnItemState(...observables);
            });
        });
    }

    /**
     * Changes the active tab to the newly selected tab; changes the view accordingly
     *
     * @param {string} newTab
     * @memberof GridDataComponent
     */
    public setTab(newTab: string): void {
        this.activeTab = newTab;
    }

    /**
     * Opens FVR survey feedback modal
     *
     * @memberof FinancialPlanApprovalsComponent
     */
    public openFvrFeedbackModal(): void {
        this.feedbackModalService.openFeedbackModal(InlineSurveyDataList.FvrSurvey, FeedBackEntity.FinancialPlanApprovals, SourceConstants.Component.FinancialPage);
    }

    /**
     * Returns true/false, is the page already on the currently selected (active) tab? Or has the user selected a new tab?
     *
     * @param {string} selectedTab
     * @returns {boolean}
     * @memberof GridDataComponent
     */
    public isSetTab(selectedTab: string): boolean {
        return this.activeTab === selectedTab;
    }

    /**
     * Filter by change
     * @memberof FinancialPlanApprovalsComponent
     */
    public onFilterChange(selectedValue: string): void {

        if (selectedValue === "") {
            this.filteredApprovals = this.approvals;
        } else {
            const propertyBag = {};
            propertyBag[FilterValue] = selectedValue;
            this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OnFilterChange, LogEventConstants.FilterByStatusClick, propertyBag);
            this.filteredApprovals = this.approvals.filter((a) => a.statusDescription === selectedValue);
        }
    }

    /**
     * Navigate to Change Request form.
     * @param cr 
     */
    public openFcrForm(): void {
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.OpenFcrForm, LogEventConstants.NewFinChangeRequestClick);
        this.stateService.go(this.isProjectContext ? RouteName.ProjectFinancialChangeRequestForm : RouteName.EngagementFinancialChangeRequestForm, {}, { reload: this.isProjectContext ? RouteName.ProjectFinancialChangeRequestForm : RouteName.EngagementFinancialChangeRequestForm });
    }
    /**
     * Logs an event when user clicks on the Learn more Link 
     * for Request NBUE Hours
     */
    public logLearnMoreNBUEClick(): void {
        this.dmLogger.logEvent(SourceConstants.Component.FinancialPage, SourceConstants.Method.LogLearnMoreNBUEClick, LogEventConstants.LearnMoreNBUEClick);
    }

    /**
     * Check if any new CRs can be created
     * @param changeRequests 
     */
    private checkIfNewCrCanBeCreated(): void {
        this.changeRequestService.getPendingCrListByEngagementId(this.engagementId).then((response) => {
            if (response && response.length) {
                this.projectsWithPendingChangeRequests = response.map((pendingCr) => pendingCr.projectId);
            }
        }).catch((error) => {
            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
            this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            if (error.status === 404) {
                Promise.resolve([]);
            }
        }).then(() => {
            const isThereProjectWithoutPendingCr = this.projectsWithPendingChangeRequests.length < this.engagementDetails.projects.length;

            if (isThereProjectWithoutPendingCr) {
                this.enableCrCreation = this.currentFinancialDetails ? true : false;
            } else {
                this.enableCrCreation = false;
            }
        });
    }
}