/* tslint:disable:no-empty */

import { Action } from "@ngrx/store";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";

// 1. action types
export enum FinancialRolesActionTypes {
    LOAD_FINANCIAL_ROLES = "[FinancialRoles] Load",
    LOAD_FINANCIAL_ROLES_SUCCESS = "[FinancialRoles] Load Success",
    LOAD_FINANCIAL_ROLES_FAIL = "[FinancialRoles] Load Fail",
    INVALIDATE_FINANCIAL_ROLES = "[FinancialRoles] Invalidate"
}

// 2. action creators
export class LoadFinancialRoles implements Action {
    public readonly type = FinancialRolesActionTypes.LOAD_FINANCIAL_ROLES;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() { } // todo: demands default false
}

export class LoadFinancialRolesSuccess implements Action {
    public readonly type = FinancialRolesActionTypes.LOAD_FINANCIAL_ROLES_SUCCESS;
    public constructor(public financialRoles: IFinancialRoles[]) { }
}

export class LoadFinancialRolesFail implements Action {
    public readonly type = FinancialRolesActionTypes.LOAD_FINANCIAL_ROLES_FAIL;
    public constructor(public errorMessage: string) { }
}

export class InvalidateFinancialRoles implements Action {
    public readonly type = FinancialRolesActionTypes.INVALIDATE_FINANCIAL_ROLES;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() { }
}

// 3. export the union creators
export type FinancialRolesActions =
    | LoadFinancialRoles
    | LoadFinancialRolesSuccess
    | LoadFinancialRolesFail
    | InvalidateFinancialRoles;