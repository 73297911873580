import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { DelegationDetailsModalComponent } from "./delegation-details-modal.component";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { ProjectServiceFunctions } from "../../../common/services/projectservice-functions.service";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";

@NgModule({
    declarations: [
        DelegationDetailsModalComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        NgbModule,
    ],
    providers: [
        DMLoggerService,
        ConfigManagerService,
        ProjectServiceFunctions,
        SharedFunctionsService
    ],
    entryComponents: [
        DelegationDetailsModalComponent
    ],
    exports: [
        DelegationDetailsModalComponent
    ],
})
export class DelegationDetailsModalModule { }
