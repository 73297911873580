import { Injectable, Inject } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";

import { catchError, map, switchMap } from "rxjs/operators";
import { from as fromPromise, of } from "rxjs";
import { LoadUnitRolesSuccess, LoadUnitRolesFail, LoadUnitRoles, UnitRolesActionTypes } from "./unit-roles.action";
import { ProjectServiceFunctions } from "../../common/services/projectservice-functions.service";
import { IFinancialRoles } from "../../common/services/contracts/projectservice-functions.contract";
import { DataService } from "../../common/services/data.service";

@Injectable()
export class UnitRolesEffects {

    @Effect()
    public loadUnitRoles$ = this.actions$
        .ofType(UnitRolesActionTypes.LOAD_UNIT_ROLES)
        .pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap((action: LoadUnitRoles) => fromPromise(this.projectServiceFunctions
                .getUnitRoles())
                .pipe(
                    map((unitRoles: IFinancialRoles[]) => new LoadUnitRolesSuccess(unitRoles)),
                    catchError((error) => of(new LoadUnitRolesFail(error.data.InnerErrors[0].Messages + " CorrelationId: " + DataService.getCorrelationIdFromError(error))))
                )
            )
        );

    public constructor(
        private actions$: Actions,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions
    ) { }
}
