import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CommonComponentsModule } from "../../../entry/common-components.module";
import { CustomerEngagementGridDataComponent } from "./customer-engagement-grid-data.component";
import { CustomerEngagementGridDataOverviewTabModule } from "./customer-engagement-grid-data-tabs/overview-tab/customer-engagement-grid-data-overview-tab.module";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";

@NgModule({
    declarations: [
        CustomerEngagementGridDataComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        CustomerEngagementGridDataOverviewTabModule
    ],
    providers: [
        DMLoggerService,
    ],
    entryComponents: [
        CustomerEngagementGridDataComponent,
    ],
    exports: [
        CustomerEngagementGridDataComponent,
    ],
})
export class CustomerEngagementGridDataModule { }
