import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UIRouterModule } from "@uirouter/angular";
import { CommonComponentsModule } from "../../entry/common-components.module";
import { DmDisplayDateOrDashPipe } from "../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { BulkMaintenanceStatusModalComponent } from "./bulk-maintenance-status-modal.component";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { DmModalV2Module } from "../../modals/dm-modal-v2/dm-modal-v2.module";
import { AutoBulkMaintenanceStatusModalComponent } from "../auto-bulk-maintenance-status-modal/auto-bulk-maintenance-status-modal.component";

@NgModule({
    declarations: [
        BulkMaintenanceStatusModalComponent,
        AutoBulkMaintenanceStatusModalComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        FormsModule,
        NgbModule,
        UIRouterModule.forChild(),        
        DmModalV2Module
    ],
    providers: [        
        DmDisplayDateOrDashPipe,
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        BulkMaintenanceStatusModalComponent,
        AutoBulkMaintenanceStatusModalComponent
    ],
    exports: [
        BulkMaintenanceStatusModalComponent,
        AutoBulkMaintenanceStatusModalComponent
    ]
})
export class BulkMaintenanceStatusModalModule { }
