import { IAdditionalPPJM } from "./project.service.contracts";

export interface IWbsEngagementModel {
    additionalPPJMs: IAdditionalPPJM[];
    canEditEnagagement: boolean;
    canRequestEbsStateChange: boolean;
    childEntitiesMaxEndDate?: Date;
    childEntitiesMinStartDate?: Date;
    deliverystate: string;
    description: string;
    domainprojectmanager: string;
    domainprojectmanagerAlias: string;
    domainprojectmanagerbpid: string;
    endDate: Date;
    engagementEndDate?: Date;
    engagementStartDate?: Date;
    entityId: string;
    isInternal: boolean;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    maxActualsEndDate?: Date;
    minActualsStartDate?: Date;
    name: string;
    projectId?: string;
    startDate: Date;
    stateCode: string;
    pubSecCode: string;
    userRequestedEbsState?: string;
}

export enum EnumUpdateLevel {
    engagementLevel = "engagementLevelUpdateOnly",
    allLowerLevels = "allLowerLevelsUpdate",
    projectLevel = "projectLevelUpdateOnly",
    serviceLevel = "serviceLevelUpdateOnly"
}

export interface IWbsResponseMessages {
    OnSaveSuccess: string;
    OnSavePartialSuccess: string;
    OnSaveFailure: string;
}
