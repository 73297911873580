import { ILoadableState } from "../reducers";
import * as fromWbsForecastDetails from "./wbs-forecast.action";
import { IForecast } from "./../../components/financial-mgmt/financial.model";

export interface IWbsForecastState extends ILoadableState {
    wbsForecastDetails: IForecast;
}

export const initialState: IWbsForecastState = {
    wbsForecastDetails: null,
    loaded: false,
    loading: false,
    error: ""
};

export function reducer(state = {}, action: fromWbsForecastDetails.WbsForecastActions): { [wbsId: string]: IWbsForecastState } {
    switch (action.type) {
        case fromWbsForecastDetails.WbsForecastActionTypes.LOAD_WbsForecast: {
            const newState = { ...state };
            newState[action.wbsId] = { ...initialState, loading: true };
            return newState;
        }

        case fromWbsForecastDetails.WbsForecastActionTypes.LOAD_WbsForecast_SUCCESS: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                wbsForecastDetails: action.wbsForecastDetails,
                loaded: true,
                loading: false,
            };
            return newState;
        }

        case fromWbsForecastDetails.WbsForecastActionTypes.LOAD_WbsForecast_FAIL: {
            const newState = { ...state };
            newState[action.wbsId] = {
                ...initialState,
                loaded: false,
                loading: false,
                error: action.errorMessage
            };
            return newState;
        }

        case fromWbsForecastDetails.WbsForecastActionTypes.INVALIDATE_WbsForecast: {
            const newState = { ...state };
            newState[action.wbsId] = initialState;
            return newState;
        }

    }

    return state;
}