import { Inject, Component, OnDestroy, OnInit } from "@angular/core";
import { DmComponentAbstract } from "../../../common/abstraction/dm-component.abstract";
import { DMLoggerService } from "../../../common/services/dmlogger.service";
import { BroadcastEvent, Components, SourceConstants } from "../../../common/application.constants";
import {
    INotification,
    NotificationType
} from "../../../common/services/contracts/notification-bar.contracts";
import { DmNotificationService } from "../../../common/services/dm-notification.service";
import { ConfigManagerService } from "../../../common/services/configmanager.service";
import { UserPreferenceService } from "../../../common/services/userpreferences.service";
import { Store } from "@ngrx/store";
import { ErrorSeverityLevel, FxpEventBroadCastService, UserInfoService } from "@fxp/fxpservices";
import { SharedFunctionsService } from "../../../common/services/sharedfunctions.service";
import { IState } from "../../../store/reducers";
import { StateService } from "@uirouter/angular";
import moment from "moment";
import { getNotificationSubscriptions } from "../../../store/notification-subscriptions/notification-subscriptions.selector";
import { UpdateUserPreference } from "../../../store/userspreferences/userpreference.action";
import { DataService } from "../../../common/services/data.service";
import { LoadNotifications } from "../../../store/notification-subscriptions/notification-subscriptions.action";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
    selector: "dm-sync-eac-to-plan-notification-wrapper",
    templateUrl: "./sync-eac-to-plan-notification-wrapper.html"
})

export class SyncEACToPlanNotificationWrapperComponent extends DmComponentAbstract implements OnInit, OnDestroy {
    public notificationType = NotificationType;
    public showNotifications: boolean = false;
    public notificationList: INotification[] = [];

    public constructor(@Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(StateService) public stateService: StateService,
        @Inject(Store) private store: Store<IState>,
        @Inject(UserInfoService) private fxpUserInfoService: UserInfoService,
        @Inject(DmNotificationService) private notificationService: DmNotificationService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(UserPreferenceService) private userPreferenceService: UserPreferenceService,
        @Inject(FxpEventBroadCastService) private fxpBroadcastService: FxpEventBroadCastService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService
    ) {
        super(dmLogger, Components.NotificationBar);
    }

    public ngOnInit(): void {
        this.fxpBroadcastService.On(BroadcastEvent.SyncToPlanSubmitted, (event, args) => {
            if (args && args.newNotification) {
                this.notificationList = [args.newNotification].concat(this.notificationList);
            }
        });

        this.loadNotificationdata();
    }

    public loadNotificationdata(): void {
        const notificationPeriod = this.configManagerService.getValue<number>("notificationPeriod");
        const formDate = moment().utc().subtract(notificationPeriod, "days").format("L");
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();

        const notifications$ = this.store.select(getNotificationSubscriptions(loggedInUserData.alias));
        this.notificationService.startConnection();
        this.userPreferenceService.getUserPreference()
            .then((data) => {
                this.store.dispatch(new UpdateUserPreference(data));
            }).catch((error) => {
                if (error && error.status === 404) {
                    const userPreference = this.userPreferenceService.getDefaultUserPeference();
                    this.userPreferenceService.saveUserPreference(userPreference).then((response) => {
                        this.store.dispatch(new UpdateUserPreference(response));
                    });
                }
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.dmLogger.logError("Component.Entry", SourceConstants.Method.NgOnInit, error, errorMessage, null, undefined, DataService.getCorrelationIdFromError(error), ErrorSeverityLevel && ErrorSeverityLevel.High);
                Promise.resolve(this.userPreferenceService.getDefaultUserPeference());
            });

        this.notificationService.getAllNotificationSubscriptions(formDate)
            .then((response) => {
                const sortedNotfications = response.sort((a, b) => +new Date(b.createdOn) - +new Date(a.createdOn));
                this.store.dispatch(new LoadNotifications(loggedInUserData.alias, sortedNotfications));
            });

        notifications$
            .pipe(untilDestroyed(this))
            .subscribe((notificationSubscriptions: INotification[]) => {
                if (notificationSubscriptions) {
                    this.notificationList = notificationSubscriptions;
                }
            });
    }

    public ngOnDestroy(): void {
        this.notificationService.stopConnection();
    }

    public toggleNotificationsBar(): void {
        this.showNotifications = !this.showNotifications;
    }
}
